import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';

import { uploadEvent } from 'src/store/reducers/lifecycle/lifecycleEventReducer';
import { postLifecycleEvent } from 'src/services/apis/postLifecycleApi';

export interface Event {
    EV_FK: string;
    EV_FKID: string;
    EV_CD_Code: string;
    EV_Context: string;
    EV_Source: string;
}

function* saveEvent({ payload }: { payload: Event; type: any }) {
    try {
        const results: AxiosResponse = yield call(postLifecycleEvent, payload);
        yield put({ type: uploadEvent, payload: results });
    } catch (e) {}
}

function* lifecycleSaga() {
    yield takeEvery('lifecycleEvent/uploadEvent', saveEvent);
}

// Export the saga (data-saga)
export default lifecycleSaga;
