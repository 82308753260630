import React from 'react';
// import './style.css';
import '../../assets/scss/components/_breadcrumbs.scss';
import { Link } from 'react-router-dom';
interface ContainerProps {
    data: any;
    component?: any;
}
const Index: React.FC<ContainerProps> = (props) => {
    // const navigate = useNavigate();

    return (
        <>
            <div className="breadcrumbs-holder">
                <ul className="breadcrumbs">
                    {props.data?.map((element: any, index: any) => {
                        return (
                            <li className="breadcrumb-list-item" key={`containerBread${index}`}>
                                {element.url ? (
                                    <Link to={element.url} className="breadcrumb-item">
                                        {element.title}
                                    </Link>
                                ) : (
                                    <span className="breadcrumb-item">{element.title}</span>
                                )}
                            </li>
                        );
                    })}
                </ul>
                {props.component}
            </div>
        </>
    );
};

export default Index;
