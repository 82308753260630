import { createSlice } from '@reduxjs/toolkit';

export interface SettingsValues {
    threshold: string | number;
    isLoading: boolean;
    updateSuccess: boolean;
    updateError: boolean;
}

const initialState: SettingsValues = {
    threshold: 0.0,
    isLoading: false,
    updateSuccess: false,
    updateError: false,
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        fetchThresholdValue: (state, action) => {
            state.isLoading = true;
            state.threshold = action.payload?.threshold;
            state.isLoading = false;
        },
        updateThresholdValue: (state, action) => {
            if (action.payload?.thresholdValue) {
                state.threshold = action.payload?.thresholdValue;
            }
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        setUpdateSuccess: (state, action) => {
            state.updateSuccess = action.payload;
        },
        setUpdateError: (state, action) => {
            state.updateError = action.payload;
        },
    },
});

export const { fetchThresholdValue, isLoadingFn, setUpdateSuccess, setUpdateError, updateThresholdValue } =
    settingsSlice.actions;
export default settingsSlice.reducer;
