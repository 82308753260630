import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { RootState } from 'src/store/reducers';

import '../todo-grid-view.scss';

const Index: React.FC<any> = ({ path }) => {
    const navigate = useNavigate();
    const { todoItem, isLoading } = useSelector((state: RootState) => state.dashboardTodo);

    return (
        <>
            {!todoItem?.lclListingDtos?.length && !isLoading && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No recent activity from the last 30 Days</p>
                </div>
            )}
            <div className="view-list-grid draft-receipt">
                {todoItem?.lclListingDtos?.map((bp: any) => (
                    <div className={`row-wrapper `}>
                        <div className="list-grid lcl-option-grid">
                            <>
                                <div className="grid-child-item col-Receipt">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Confirmation Group Name</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {bp?.confirmationGroupName}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-Receipt">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">PO number</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {bp?.poNumber ?? '-'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-client">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Coloader Name</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">{bp?.coLoaderName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-vendor">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Goods Ready Date</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {bp?.goodsReadyDate
                                                        ? moment(bp?.goodsReadyDate).format('DD-MM-YYYY')
                                                        : '-'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-vendor">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Destination</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {bp?.destinationPort}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                    <div className="status-badge">
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">{bp?.cgType}</span>
                                            </div>
                                        </div>
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">
                                                    {bp?.poLines} PO{bp?.poLines > 1 ? 's' : ''}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">{bp?.cbm} cbm</span>
                                            </div>
                                        </div>
                                    </div>
                                    <button
                                        title="View Booking"
                                        className="app-btn app-btn-secondary grid-btn"
                                        onClick={() => navigate(path(bp.poId, bp.confirmationGroupId))}
                                    >
                                        <span className="button-text footer-button-text">View Confirmation Group</span>
                                    </button>
                                </div>
                            </>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
export default Index;
