import { createSlice } from '@reduxjs/toolkit';
import { clientBasicDetails, getClientDetails } from '../../services/apis/clientApi';

export interface Warehouse {
    WH_PK: string;
    WH_Code: string;
    WH_Name: string;
    WH_OR_CompanyCode: string;
    WH_OR_OwnerCode: string;
    WH_OR_OperatorCode: string;
    WH_AddressLine1: string;
    WH_AddressLine2: string;
    WH_AddressLine3: string;
    WH_City: string;
    WH_State: string;
    WH_CD_CountryCode: string;
    WH_PostalCode: string;
    WH_IsIntegrated: boolean;
    WH_ZZ_ScaleVersion: number;
    WH_CreatedDateTime: Date;
    WH_ModifiedDateTime: Date;
    WH_DeletedDateTime: Date;
    WH_US_ModifiedByUser: string;
    WH_ClosestPort: string;
}

export const warehouseReducer = createSlice({
    name: 'whWarehouse',
    initialState: {
        warehouses: [] as Warehouse[],
    },
    reducers: {
        fetchWhWarehouse: (state, action) => {
            state.warehouses = action.payload.data;
        },
    },
});

export const { fetchWhWarehouse } = warehouseReducer.actions;
export default warehouseReducer.reducer;
