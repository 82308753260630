/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// import PurchaseOrderHeader from '../PurchaseOrderHeader';
// import ConfirmBookingModal from './ConfirmBookingModal';
import { useAppDispatch, useAppSelector } from 'src/hooks';

import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import RateHeader from '../Header';
import RateTab from '../Tab';
import './index.scss';

// import { Formik } from 'formik';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import EventsModal from './EventsModal';
import { getSingleEvent } from 'src/services/apis/eventsApi';
import { Position, error } from 'src/utils/toast';
import { getRateByPK } from 'src/services/apis/qmsApi';
import QuoteHeader from '../Header';
import QuoteTab from '../Tab';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { fetchQuoteHeader } from 'src/store/reducers/qmsReducer';

const Index: React.FC = () => {
    const { id } = useParams();
    const headerData = useAppSelector(selectHeader);
    const dispatch = useAppDispatch();

    const [modalView, setModalView] = useState(false);
    const [eventsData, setEventsData] = useState([]);
    const { quoteHeader } = useSelector((state: RootState) => state.qmsData);

    // useEffect(() => {
    //     if (id && quoteHeader?.length === 0) {
    //         dispatch(fetchQuoteHeader({ payload: { key: id } }));
    //     }
    // }, [id]);

    const navData = [
        {
            title: 'Quote Input',
            selected: 0,
            path: id ? `/qms/quote/${id}` : '/qms/quote',
        },
    ];

    //@ts-ignore
    window.eventUp = (value: string) => {
        getSingleEvent(value)
            .then((res: any) => {
                setModalView(true);
                setEventsData(res);
            })
            .catch((err: any) => {
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            });
    };

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={'Quotes'}
                        firstUrl={'/qms/quote'}
                        secondName={'Quote Input'}
                        secondUrl={'#'}
                        backUrl={'/qms/quote'}
                    />
                    <QuoteHeader />
                    <QuoteTab navData={navData} />
                </div>
                <div className="lifecycle-grid">
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'lifecycle'} content={'purchase-order-booking-events'} />
                    </div>
                </div>
                {modalView ? <EventsModal eventsData={eventsData} setModalView={setModalView} /> : null}
            </div>
        </>
    );
};
export default Index;
