/* eslint-disable no-undefined */
import React, { useEffect, useRef, useState } from 'react';
import './ao-basic-modal.scss';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CancelModal from './cancelModal';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { createAo, getAgent, saveAgentData } from '../../../store/reducers/allocationOrder/basicDetails';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { clearUnlocoList, searchUnlocoList } from '../../../store/reducers/createVendorReducer';
import { reactSelectStyle } from '../../../utils/constants';
import { useUserProfile } from 'src/hooks/useUserProfile';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';

interface BioProps {
    displayFn: any;
    display: boolean;
    data: any;
    edit: boolean;
    editData?: any;
    id?: string;
    setisEdit: any;
    addressVal: any;
}
interface requiredForm {
    aoId: string;
    ordertype: string;
    shippingmode: string;
    includesAUPortDestination: string;
    fclservicetype: string;
    // freightterms: string;
    incoTerm: string;
    releaseType: string;
    paymentterms: string;
    originPort: string;
    destinationPort: string;
    isMultiAddress: boolean;
    isAu: boolean;
    destinationAddress: string;
    destinationAddressId: string;
    agentCode: string;
    agentName: string;
    storeId: string;
    batchId: string;
    erpOrderno: string;
}
// const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
const Index: React.FC<BioProps> = (props) => {
    const { data, agents, storeId } = useSelector((state: RootState) => state.allocationOrder);
    const { unLocoList, isLoading } = useSelector((state: RootState) => state.vendorDetails);
    const scrollElement = useRef<any>(null);
    const [isChecked, setisChecked] = useState(false);
    const [isFieldMandatory, setIsFieldMandatory] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    const [portCode, setportCode] = useState('');
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [ports, setPorts] = useState<any>([]);
    const [unlocoLoading, setunlocoLoading] = useState(false);
    const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState('');
    const [touched, settouched] = useState(false);
    const [scroll, setScroll] = useState(false);
    const initialValues: requiredForm = {
        aoId: props.id as any,
        ordertype: props.editData.ordertype?.orderTypeName || 'Allocation order',
        shippingmode: props.editData.shippingmode?.shippingModeName || 'Sea Freight',
        includesAUPortDestination: props.editData?.includesAUPortDestination ? 'Yes' : 'No',
        fclservicetype: props.editData.fclservicetype?.fclServiceTypeName || '',
        // freightterms: props.editData.freightterms?.freightTermsCode || '',
        incoTerm: props.editData.incoTerm?.incoTermCode || '',
        releaseType: props.editData.releaseType?.releaseTypeName || '',

        paymentterms: props.editData.paymentterms?.paymentTermsName || '',
        originPort:
            (props.editData.originPort !== '' &&
                props.editData.originPortName &&
                JSON.stringify({
                    value: props.editData.originPort,
                    label: `${props.editData.originPortName}(${props.editData.originPort})`,
                })) ||
            '',
        destinationPort:
            (!isChecked &&
                props.editData.destinationPort &&
                JSON.stringify({
                    value: props.editData.destinationPort,
                    label: `${props.editData.destinationPortName}(${props.editData.destinationPort})`,
                })) ||
            '',
        isAu: (!isChecked && props.editData.destinationPort?.startsWith('AU')) ?? false,

        isMultiAddress: props.editData.isMultiAddress || false,
        destinationAddress: (!isChecked && props.editData.destinationAddress) || '',
        destinationAddressId: (!isChecked && props.editData.destinationAddressId) || '',
        agentName: props?.editData?.agentName || '',
        agentCode: props?.editData?.agentCode || '',
        storeId: props?.editData?.storeId || '',
        batchId: props?.editData?.batchId || '',
        erpOrderno: props?.editData?.erpOrderno || '',
    };

    const CommercialDetailFormSchema = Yup.object().shape({
        ordertype: Yup.string().required('Order type is required'),
        shippingmode: Yup.string().required('Shipping mode is required'),
        originPort: Yup.string().required('Origin is required').nullable(),
        // fclservicetype: Yup.string().required('Service type is required'),
        // freightterms: Yup.string().required('Freight term is required'),
        // incoTerm: Yup.string().required('Incoterm FOB is required'),
        // releaseType: Yup.string().required('Release type is required'),
        // paymentterms: Yup.string().required('Payment term is required'),
        storeId: Yup.string().required('Store ID is required'),
        batchId: Yup.string().required('Batch ID is required'),
        erpOrderno: Yup.string().required('Order Number is required'),
    });
    const AllValidationFormSchema = Yup.object().shape({
        ordertype: Yup.string().required('Order type is required'),
        shippingmode: Yup.string().required('Shipping mode is required'),
        originPort: Yup.string().required('Origin is required').nullable(),
        fclservicetype: Yup.string().required('Service type is required'),
        incoTerm: Yup.string().required('Incoterm FOB is required'),
        releaseType: Yup.string().required('Release type is required'),
        paymentterms: Yup.string().required('Payment term is required'),
        storeId: Yup.string().required('Store ID is required'),
        batchId: Yup.string().required('Batch ID is required'),
        erpOrderno: Yup.string().required('Order Number is required'),
        destinationPort: Yup.string().when('includesAUPortDestination', {
            is: (val: string) => val?.toLowerCase() === 'no' || !val,
            then: Yup.string().required('Destination is required').nullable(),
        }),
        destinationAddressId: Yup.string().when('includesAUPortDestination', {
            is: (val: string) => val?.toLowerCase() === 'no' || !val,

            then: Yup.string().required('Destination Address is required').nullable(),
        }),
        agentCode: Yup.string().required('Agent is required'),
    });

    const validationSchema = Yup.object().shape({
        destinationPort: Yup.string().required('Destination is required').nullable(),
        destinationAddressId: Yup.string().required('Destination address is required').nullable(),
        //agents: Yup.string().required('Agent is required'),
    });
    const agentValidationSchema = Yup.object().shape({
        agentCode: Yup.string().required('Agent is required'),
    }); /* eslint no-var: off */
    const { profileType } = useUserProfile();
    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">No agents assigned for the Origin Port</p>
            </div>
        );
    };

    // const checkValidation = () => {
    //     // let flag = true;
    //     const formikshort = formik.values;
    //     // formikshort.freightterms &&

    //     if (
    //         !formikshort.ordertype ||
    //         !formikshort.shippingmode ||
    //         !formikshort.originPort ||
    //         (!formikshort.storeId && !formikshort.batchId) ||
    //         !formikshort.erpOrderno
    //     ) {
    //         return true;
    //     }

    //     if (isChecked && formikshort.includesAUPortDestination === 'Yes') {
    //         if (
    //             !formikshort.fclservicetype ||
    //             !formikshort.releaseType ||
    //             !formikshort.paymentterms ||
    //             !formikshort.incoTerm
    //         ) {
    //             return true;
    //         }
    //     } else {
    //         if (profileType === 'forwarder' && !(formikshort.agentCode && formikshort.agentName)) {
    //             return true;
    //         }
    //     }

    //     if (!isChecked) {
    //         if (!formikshort.destinationPort || !formikshort.destinationAddress) {
    //             return true;
    //         }
    //         // && formik.values.isAu
    //         if (
    //             formik.values.isAu &&
    //             (!formikshort.fclservicetype ||
    //                 !formikshort.releaseType ||
    //                 !formikshort.paymentterms ||
    //                 !formikshort.incoTerm)
    //         ) {
    //             return true;
    //         }
    //     } else {
    //         if (!(formikshort.agentCode && formikshort.agentName)) {
    //             // profileType === 'forwarder' &&
    //             return true;
    //         }
    //     }

    //     if (
    //         !isChecked &&
    //         formikshort.originPort &&
    //         formikshort.destinationPort &&
    //         formikshort.originPort === formikshort.destinationPort
    //     ) {
    //         return true;
    //     }

    //     // if (
    //     //     formikshort.ordertype &&
    //     //     formikshort.shippingmode &&
    //     //     formikshort.originPort &&
    //     //     formikshort.storeId &&
    //     //     formikshort.batchId &&
    //     //     formikshort.erpOrderno
    //     // ) {
    //     //     if (isChecked && formikshort.includesAUPortDestination) {
    //     //         flag = false;
    //     //     }

    //     //     if (
    //     //         !isChecked &&
    //     //         formikshort.fclservicetype &&
    //     //         formikshort.releaseType &&
    //     //         formikshort.paymentterms &&
    //     //         formikshort.incoTerm
    //     //         // (shippingMode === 'air' ? formikshort.releaseType : formikshort.paymentterms) &&
    //     //     ) {
    //     //         flag = false;
    //     //     }

    //     //     if (profileType === 'forwarder' && !(formikshort.agentCode && formikshort.agentName)) {
    //     //         flag = true;
    //     //     }
    //     // }

    //     // if (
    //     //     !isChecked &&
    //     //     formikshort.originPort &&
    //     //     formikshort.destinationPort &&
    //     //     formikshort.originPort === formikshort.destinationPort
    //     // ) {
    //     //     flag = true;
    //     // }

    //     // if (!isChecked && (!formikshort.destinationPort || !formikshort.destinationAddress)) {
    //     //     return true;
    //     // }
    //     // return flag;
    //     if (!isChecked && formik.values.isAu && (!formikshort.destinationPort || !formikshort.destinationAddress)) {
    //         return true;
    //     }
    //     return false;
    // };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema:
            profileType === 'forwarder'
                ? isFieldMandatory
                    ? AllValidationFormSchema
                    : !isChecked
                    ? CommercialDetailFormSchema.concat(validationSchema).concat(agentValidationSchema)
                    : CommercialDetailFormSchema.concat(agentValidationSchema)
                : !isChecked
                ? CommercialDetailFormSchema.concat(validationSchema)
                : CommercialDetailFormSchema,
        onSubmit: (values: any) => {
            if (formik.values.originPort === formik.values.destinationPort) {
                if (scrollElement.current) {
                    scrollElement.current.scrollTop = document.getElementById('scroll1')?.offsetTop;
                }
                return;
            }
            var data = {};
            if (values.originPort.startsWith('{')) {
                const origin = JSON.parse(values.originPort).label.toString().split('(');
                values.originPortName = origin[0];
                values.originPort = origin[1].toString().split(')')[0];
            } else {
                values.originPortName = props.editData.originPortName;
                values.originPort = props.editData.originPort;
            }
            if (!isChecked) {
                if (values.destinationPort.startsWith('{')) {
                    const destination = JSON.parse(values.destinationPort).label.toString().split('(');
                    values.destinationPortName = destination[0];
                    values.destinationPort = destination[1].toString().split(')')[0];
                } else {
                    values.destinationPortName = props.editData.destinationPortName;
                    values.destinationPort = props.editData.destinationPort;
                }
                data = { ...values, isMultiAddress: isChecked, inboundPo: false, includesAUPortDestination: null };
            } else {
                data = {
                    ...values,
                    isMultiAddress: isChecked,
                    destinationAddress: null,
                    destinationAddressId: null,
                    destinationPort: null,
                    destinationPortName: null,
                    inboundPo: false,
                    ...(values.includesAUPortDestination
                        ? { includesAUPortDestination: values.includesAUPortDestination === 'Yes' }
                        : {}),
                };
            }
            dispatch(createAo({ payload: data, id: props.id }));

            formik.resetForm();
            props.displayFn(false);
        },
    });
    const scrollUp = () => {
        let targetDiv;
        if (scrollElement.current) {
            if (formik.errors.originPort || formik.errors.destinationPort || formik.errors.destinationAddressId) {
                targetDiv = document.getElementById('scroll1');
            } else if (
                formik.errors.fclservicetype ||
                formik.errors.incoTerm ||
                formik.errors.releaseType ||
                formik.errors.paymentterms
            ) {
                targetDiv = document.getElementById('scroll2');
            } else if (
                formik.errors.agentCode ||
                formik.errors.storeId ||
                formik.errors.batchId ||
                formik.errors.erpOrderno
            ) {
                targetDiv = document.getElementById('scroll3');
            }
            if (targetDiv) {
                scrollElement.current.scrollTop = targetDiv.offsetTop;
            } else {
                scrollElement.current.scrollTop = 0;
            }
        }

        // scrollElement.current.scrollTop = 0;
    };
    useEffect(() => {
        var code = portCode ? portCode : props.editData.originPort;
        var mode = 'SEA';
        if (portCode || props.editData.originPort) {
            dispatch(getAgent({ code, mode }));
        }
        if (!formik.values?.originPort?.toLowerCase()?.includes(props?.editData?.originPort?.toLowerCase())) {
            formik.setFieldValue('agentName', '');
            formik.setFieldValue('agentCode', '');
        }
        if (!formik.values?.originPort || formik.values?.originPort == 'null') {
            formik.setFieldValue('agentName', '');
            formik.setFieldValue('agentCode', '');
        }
    }, [formik.values.originPort, props.editData.originPort]);
    // useEffect(() => {
    //     if (agents?.length && formik.values?.originPort != 'null') {
    //         const agentItem: any = agents?.filter((res: any) => res?.extraField === true);
    //         if (agentItem?.length) {
    //             formik.setFieldValue('agentName', agentItem[0].label);
    //             formik.setFieldValue('agentCode', agentItem[0].value);
    //         } else {
    //             formik.setFieldValue('agentName', '');
    //             formik.setFieldValue('agentCode', '');
    //         }
    //     }
    // }, [agents]);

    useEffect(() => {
        let flag = false;
        if (!props?.editData?.agentName || touched) {
            agents?.forEach((x: any) => {
                if (x.extraField && !flag) {
                    flag = true;
                    formik.setFieldValue('agentName', x.label);
                    formik.setFieldValue('agentCode', x.value);
                }
            });
        }
    }, [agents]);

    useEffect(() => {
        if (props.editData.isMultiAddress) {
            setisChecked(props.editData.isMultiAddress);
        }
    }, [props.editData.isMultiAddress]);
    const onConfirm = () => {
        props.displayFn(false);
        props.setisEdit(false);
        dispatch(saveAgentData([]));
    };
    const onCancel = () => {
        setCancelModal(false);
    };
    // useEffect(() => {
    //     setTimeout(() => {
    //         formik.validateForm();
    //     }, 100);
    // }, [formik.values]);

    // const checkErrorStatus = () => {
    //     const formikshort = formik.values;
    //     if (
    //         formikshort.originPort &&
    //         formikshort.destinationPort &&
    //         formikshort.originPort === formikshort.destinationPort
    //     ) {
    //         return true;
    //     }

    //     if (!isChecked && !formikshort.destinationPort) {
    //         return true;
    //     }
    //     return formik.errors && formik.errors.constructor === Object && Object.keys(formik.errors).length > 0;
    // };

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            dispatch(searchUnlocoList({ searchTerm, service: 'ord' }));
        }
        setPorts([]);
    }, [searchTerm]);

    useEffect(() => {
        dispatch(clearUnlocoList());
    }, []);

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    portCountry: option.portCountry,
                };
            });
            setPorts(portList);
        }
    }, [unLocoList]);
    useEffect(() => {
        if (isLoading && searchTerm?.length > 2) {
            setunlocoLoading(true);
        } else {
            setunlocoLoading(false);
        }
    }, [isLoading, searchTerm]);

    useBrowserBackCheck(formik.dirty, () => setCancelModal(true));
    const mandatoryFields = !isChecked
        ? formik.values.destinationPort
            ? formik.values.isAu
            : true
        : formik.values.includesAUPortDestination === 'Yes' ?? false;

    useEffect(() => {
        setIsFieldMandatory(mandatoryFields);
    }, [isChecked, formik.values]);
    useEffect(() => {
        if (!formik.isValid) {
            scrollUp();
        }
    }, [scroll, formik.isValid]);

    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };
    return (
        <div className="app-modal purchase-detail-modal">
            <div className="modal-content-holder medium-popup ">
                <div className="modal-header">
                    <div className="title">Required Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            !formik.dirty ? props.displayFn(false) : setCancelModal(true);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content" ref={scrollElement}>
                    <div className="modal-main-content">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="modal-form layout-holder one-column"
                            id="basicForm"
                        >
                            {/* <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Order Type<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <SearchableSelectBox
                                        data={(data as any)?.orderType}
                                        placeholder="Select Order Type"
                                        formik={formik}
                                        fieldName="ordertype"
                                    />
                                    {formik.errors.ordertype ? (
                                        <div className="error-text">{formik.errors.ordertype}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Shipping Mode<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={(data as any)?.shippingmode}
                                    placeholder="Select Shipping Mode"
                                    formik={formik}
                                    fieldName="shippingmode"
                                />
                                {formik.errors.shippingmode ? (
                                    <div className="error-text">{formik.errors.shippingmode}</div>
                                ) : null}
                            </div> */}
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Order Type<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Allocation Order"
                                        value={formik.values.ordertype}
                                        type="text"
                                        className="modal-input input-text read-only"
                                        id="ordertype"
                                        name="ordertype"
                                        readOnly
                                    ></input>
                                </div>
                            </div>
                            <div className=" modal-input-holder" id="scroll1">
                                <label className="modal-input-label">
                                    Shipping Mode<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Land"
                                        value={formik.values.shippingmode}
                                        type="text"
                                        className="modal-input input-text read-only"
                                        id="shippingmode"
                                        name="shippingmode"
                                        readOnly
                                    ></input>
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Origin<span className="mandatory-field-text">*</span>
                                </label>
                                {/* <SearchableSelectBox
                                    data={(data as any)?.portlist}
                                    placeholder="Enter Origin to select"
                                    formik={formik}
                                    fieldName="originPort"
                                    extraFieldFn={setportCode}
                                /> */}
                                <Select
                                    options={ports}
                                    isClearable
                                    placeholder="Enter Origin to select"
                                    onChange={(option: any) => {
                                        // eslint-disable-next-line no-undefined
                                        formik.setFieldError('originPort', undefined);
                                        formik.setFieldValue(
                                            'originPort',
                                            option
                                                ? JSON.stringify({ value: option.value, label: option.label })
                                                : option
                                        );
                                        setportCode(option?.value);
                                    }}
                                    onInputChange={(text: string) => {
                                        setCurrentSelectedDropdown('originPort');
                                        setSearchTerm(text);
                                        settouched(true);
                                    }}
                                    isLoading={unlocoLoading && currentSelectedDropdown === 'originPort'}
                                    noOptionsMessage={({ inputValue }) =>
                                        inputValue?.length < 3
                                            ? 'Enter at least 3 characters to search'
                                            : 'No results found'
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    id={'originPort'}
                                    name={'originPort'}
                                    // defaultInputValue={initialValues.originPort}
                                    value={
                                        formik.values['originPort']?.startsWith('{') &&
                                        JSON.parse(formik.values['originPort'])
                                    }
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        ClearIndicator,
                                    }}
                                    styles={reactSelectStyle}
                                />
                                {formik.errors.originPort && typeof formik.errors.originPort === 'string' ? (
                                    <div className="error-text">{formik.errors.originPort}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Destination{!isChecked && <span className="mandatory-field-text">*</span>}
                                </label>
                                {/* <SearchableSelectBox
                                    data={(poData as any)?.portlist}
                                    placeholder={isChecked ? 'Multi Destination' : 'Enter Destination to select'}
                                    formik={formik}
                                    fieldName="destinationPort"
                                    isDisabled={isChecked}
                                /> */}
                                <Select
                                    options={ports}
                                    isClearable
                                    isDisabled={isChecked}
                                    placeholder={isChecked ? 'Multi Destination' : 'Enter Destination to select'}
                                    onChange={(option: any) => {
                                        // eslint-disable-next-line no-undefined
                                        formik.setFieldError('destinationPort', undefined);
                                        formik.setFieldValue(
                                            'destinationPort',
                                            option
                                                ? JSON.stringify({
                                                      value: option.value,
                                                      label: option.label,
                                                  })
                                                : option
                                        );
                                        formik.setFieldValue('isAu', (option && option?.portCountry === 'AU') ?? true);
                                        setportCode(option?.value);
                                    }}
                                    onInputChange={(text: string) => {
                                        setCurrentSelectedDropdown('destinationPort');
                                        setSearchTerm(text);
                                    }}
                                    isLoading={unlocoLoading && currentSelectedDropdown === 'destinationPort'}
                                    noOptionsMessage={({ inputValue }) =>
                                        inputValue?.length < 3
                                            ? 'Enter at least 3 characters to search'
                                            : 'No results found'
                                    }
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    id={'destinationPort'}
                                    name={'destinationPort'}
                                    // defaultInputValue={initialValues.destinationPort}
                                    value={
                                        !isChecked &&
                                        formik.values['destinationPort']?.startsWith('{') &&
                                        JSON.parse(formik.values['destinationPort'])
                                    }
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        ClearIndicator,
                                    }}
                                    styles={reactSelectStyle}
                                />
                                {formik.values?.originPort &&
                                formik.values?.destinationPort &&
                                formik.values.originPort === formik.values.destinationPort ? (
                                    <div className="error-text">Orgin and Destination ports cannot be same</div>
                                ) : null}
                                {!isChecked &&
                                formik.errors.destinationPort &&
                                typeof formik.errors.destinationPort === 'string' ? (
                                    <div className="error-text">{formik.errors.destinationPort}</div>
                                ) : null}

                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={isChecked}
                                            onChange={(e) => {
                                                setisChecked(e.currentTarget.checked);
                                                formik.setFieldValue('isMultiAddress', e.currentTarget.checked);
                                                formik.setFieldValue('destinationAddressId', '');
                                                formik.setFieldValue('destinationPort', '');
                                                formik.setFieldValue(
                                                    'includesAUPortDestination',
                                                    e.currentTarget.checked ? 'No' : ''
                                                );
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Multi destination</div>
                                    </label>
                                </div>
                            </div>
                            {isChecked && (
                                <div className=" modal-input-holder">
                                    <label className="modal-input-label">
                                        Includes AU Port Destination<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        {/* data set should be --- yes and no */}
                                        {/* destination address dropdown menu items are outside this block, need to set its position dynamically */}
                                        <SearchableSelectBox
                                            data={[
                                                { value: 'Yes', label: 'Yes' },
                                                { value: 'No', label: 'No' },
                                            ]}
                                            isClearable={false}
                                            placeholder="Select"
                                            formik={formik}
                                            fieldName="includesAUPortDestination"
                                            showDropdownIndicator
                                        />
                                        {/* {!isChecked && formik.errors.destinationAddressId ? (
                                        <div className="error-text">{formik.errors.destinationAddressId}</div>
                                    ) : null} */}
                                    </div>
                                </div>
                            )}
                            <div className=" modal-input-holder" id="scroll2">
                                <label className="modal-input-label">
                                    Destination Address{!isChecked && <span className="mandatory-field-text">*</span>}
                                </label>
                                <div className="modal-input-layout-item auto-dp-height">
                                    {/* destination address dropdown menu items are outside this block, need to set its position dynamically */}
                                    <SearchableSelectBox
                                        data={props.addressVal}
                                        placeholder={isChecked ? 'Multi Destination' : 'Select Destination Address'}
                                        formik={formik}
                                        fieldName="destinationAddressId"
                                        extraField="destinationAddress"
                                        isDisabled={isChecked}
                                    />
                                    {!isChecked &&
                                    formik.errors.destinationAddressId &&
                                    typeof formik.errors.destinationAddressId === 'string' ? (
                                        <div className="error-text">{formik.errors.destinationAddressId}</div>
                                    ) : null}
                                </div>
                            </div>
                            {/* {data1?.userProfileRole[0]?.up_PR?.pr_ProfileCode === 'FORWARDER' ||
                                (data1?.userProfileRole[0]?.up_PR?.pr_ProfileCode === 'CLIENT' && ( */}
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Service Type {mandatoryFields && <span className="mandatory-field-text">*</span>}
                                </label>
                                <SearchableSelectBox
                                    data={(data as any)?.fclservicetype}
                                    placeholder="Select Service Type"
                                    formik={formik}
                                    fieldName="fclservicetype"
                                />
                                {mandatoryFields &&
                                formik.errors.fclservicetype &&
                                typeof formik.errors.fclservicetype === 'string' ? (
                                    <div className="error-text">{formik.errors.fclservicetype}</div>
                                ) : null}
                            </div>
                            {/* <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Freight Term<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={(data as any)?.freightterms}
                                    placeholder="Select FCL Freight Term"
                                    formik={formik}
                                    fieldName="freightterms"
                                />
                                {formik.errors.freightterms ? (
                                    <div className="error-text">{formik.errors.freightterms}</div>
                                ) : null}
                            </div> */}
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Incoterm {mandatoryFields && <span className="mandatory-field-text">*</span>}
                                </label>
                                <div className="modal-input-layout-item auto-dp-height">
                                    <SearchableSelectBox
                                        data={(data as any)?.incoTerm}
                                        placeholder="Select Incoterm FOB"
                                        formik={formik}
                                        fieldName="incoTerm"
                                    />
                                </div>
                                {mandatoryFields &&
                                formik.errors.incoTerm &&
                                typeof formik.errors.incoTerm === 'string' ? (
                                    <div className="error-text">{formik.errors.incoTerm}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Release Type {mandatoryFields && <span className="mandatory-field-text">*</span>}
                                </label>
                                <SearchableSelectBox
                                    data={(data as any)?.releaseType}
                                    placeholder="Select Release Type"
                                    formik={formik}
                                    fieldName="releaseType"
                                />
                                {mandatoryFields &&
                                formik.errors.releaseType &&
                                typeof formik.errors.releaseType === 'string' ? (
                                    <div className="error-text">{formik.errors.releaseType}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Payment Term {mandatoryFields && <span className="mandatory-field-text">*</span>}
                                </label>
                                <SearchableSelectBox
                                    data={(data as any)?.paymentterms}
                                    placeholder="Select Payment Term"
                                    formik={formik}
                                    fieldName="paymentterms"
                                />
                                {mandatoryFields &&
                                formik.errors.paymentterms &&
                                typeof formik.errors.paymentterms === 'string' ? (
                                    <div className="error-text">{formik.errors.paymentterms}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Agent<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={
                                        formik.values?.originPort != 'null'
                                            ? agents?.filter((res: any) => res?.extraField === true)
                                            : []
                                    }
                                    placeholder="Select agent"
                                    formik={formik}
                                    fieldName="agentCode"
                                    extraField="agentName"
                                    isClearable={false}
                                    NoMessageComponent={NoMessageComponent}
                                />
                                {formik.errors.agentCode && typeof formik.errors.agentCode === 'string' ? (
                                    <div className="error-text">{formik.errors.agentCode}</div>
                                ) : null}
                            </div>
                            {/* ))} */}
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Store ID<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={storeId}
                                    placeholder="Select Store ID"
                                    formik={formik}
                                    fieldName="storeId"
                                />
                                {formik.errors.storeId && typeof formik.errors.storeId === 'string' ? (
                                    <div className="error-text">{formik.errors.storeId}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Batch ID<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Batch ID"
                                        value={formik.values.batchId}
                                        onChange={(event) => {
                                            formik.setFieldError(event.target.name, undefined);
                                            formik.handleChange(event);
                                        }}
                                        onKeyPress={(event) => {
                                            if (/[^a-zA-Z0-9 ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        autoComplete="off"
                                        maxLength={50}
                                        type="text"
                                        className="modal-input input-text"
                                        id="batchId"
                                        name="batchId"
                                    ></input>
                                    {formik.errors.batchId && typeof formik.errors.batchId === 'string' ? (
                                        <div className="error-text">{formik.errors.batchId}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className=" modal-input-holder" id="scroll3">
                                <label className="modal-input-label">
                                    Order Number<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Order Number"
                                        value={formik.values.erpOrderno}
                                        onChange={(event) => {
                                            formik.setFieldError(event.target.name, undefined);
                                            formik.handleChange(event);
                                        }}
                                        onKeyPress={(event) => {
                                            if (/[^a-zA-Z0-9 ]/.test(event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        autoComplete="off"
                                        maxLength={50}
                                        type="text"
                                        className="modal-input input-text"
                                        id="erpOrderno"
                                        name="erpOrderno"
                                    ></input>
                                    {formik.errors.erpOrderno && typeof formik.errors.erpOrderno === 'string' ? (
                                        <div className="error-text">{formik.errors.erpOrderno}</div>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                        onClick={() => {
                            !formik.dirty && (props.edit ? isChecked == props.editData.isMultiAddress : true)
                                ? props.displayFn(false)
                                : setCancelModal(true);
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <form
                        id="basicForm"
                        onSubmit={formik.handleSubmit}
                        style={{ width: 'unset' }}
                        className="modal-form layout-holder one-column"
                    >
                        <button
                            className="app-btn app-btn-primary modal-btn footer-btn "
                            type="submit"
                            title="Save"
                            id="basicForm"
                            onClick={() => setScroll(!scroll)}
                            // disabled={
                            //     !props.edit
                            //         ? checkValidation()
                            //         : (!formik.dirty && isChecked == props.editData.isMultiAddress) || checkValidation()
                            // }
                        >
                            <span className="button-text footer-button-text">Save</span>
                        </button>
                    </form>
                </div>
            </div>
            {cancelModal && <CancelModal onConfirm={onConfirm} onCancel={onCancel} />}
        </div>
    );
};
export default Index;
