import { createSlice } from '@reduxjs/toolkit';

interface AutoApprovalData {
    autoApproveNAC: boolean;
    autoApproveFAK: boolean;
    autoApproveETD: boolean;
    autoApproveAIR: boolean;
    buyerApprove: boolean;
    authorisationType: 'SOP' | 'CONTRACT' | 'EMAIL' | null;
    fileName: string | null;
    savedFileName: string | null;
    savedFileId: string | null;
    savedFileSizeInKb: number | null;
}

export interface AutoApprovalLog {
    createdByUser: string;
    userName: string;
    createdDate: string;
    fileName: null | string;
    newAuthorisationType: string;
    newAutoApproveETD: boolean;
    newAutoApproveFAK: boolean;
    newAutoApproveNAC: boolean;
    newAutoApproveAIR: boolean;
    oldAuthorisationType: string;
    oldAutoApproveETD: boolean;
    oldAutoApproveFAK: boolean;
    oldAutoApproveNAC: boolean;
    oldAutoApproveAIR: boolean;
    savedFileId: null | string;
    savedFileName: null | string;
    savedFileSizeInKb: null | string | number;
}

interface AutoApprovalState {
    autoApprovalData: AutoApprovalData;
    isUpdateError: boolean;
    isLoading: boolean;
    isUpdateSuccess: boolean;
    isError: boolean;
    autoApprovalHistory: AutoApprovalLog[];
    isHistoryLoading: boolean;
}

const initialState: AutoApprovalState = {
    autoApprovalData: {
        autoApproveNAC: false,
        autoApproveFAK: false,
        autoApproveETD: false,
        autoApproveAIR: false,
        buyerApprove: false,
        authorisationType: null,
        fileName: null,
        savedFileName: null,
        savedFileId: null,
        savedFileSizeInKb: 0,
    },
    isUpdateError: false,
    isUpdateSuccess: false,
    isLoading: true,
    isError: false,
    autoApprovalHistory: [],
    isHistoryLoading: false,
};

export const autoApprovalSlice = createSlice({
    name: 'autoApproval',
    initialState,
    reducers: {
        saveAutoApprovalData(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        saveAutoApprovalSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.isUpdateSuccess = action.payload;
        },
        saveAutoApprovalFailure(state, _action) {
            state.isLoading = false;
            state.isUpdateError = true;
        },
        fetchAutoApprovalData: (state, action) => {
            state.isError = false;
            state.autoApprovalData = action.payload;
        },
        uploadAttachment(_state, _action) {
            // console.log(action);
        },
        saveUploadedAttachment(state, action) {
            state.isLoading = false;
            state.autoApprovalData.fileName = action.payload.fileName;
            state.autoApprovalData.savedFileName = action.payload.savedFileName;
            state.autoApprovalData.savedFileId = action.payload.savedFileId;
            state.autoApprovalData.savedFileSizeInKb = action.payload.savedFileSizeInKb;
        },
        removeAttachment(state, _action) {
            state.autoApprovalData.fileName = null;
            state.autoApprovalData.savedFileName = null;
            state.autoApprovalData.savedFileId = null;
            state.autoApprovalData.savedFileSizeInKb = null;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        resetFeedbackStatuses: (state, _action) => {
            state.isUpdateSuccess = false;
            state.isUpdateError = false;
        },
        fetchAutoApprovalHistory: (state, _action) => {
            state.isHistoryLoading = true;
        },
        fetchAutoApprovalHistorySuccess: (state, action) => {
            state.autoApprovalHistory = action.payload;
            state.isHistoryLoading = false;
        },
        fetchAutoApprovalHistoryError: (state, _action) => {
            state.isHistoryLoading = false;
        },
        resetHistory: (state, _action) => {
            state.autoApprovalHistory = [];
        },
    },
});

export const {
    isLoadingFn,
    saveAutoApprovalData,
    saveAutoApprovalSuccess,
    saveUploadedAttachment,
    fetchAutoApprovalData,
    saveAutoApprovalFailure,
    uploadAttachment,
    removeAttachment,
    resetFeedbackStatuses,
    fetchAutoApprovalHistory,
    fetchAutoApprovalHistorySuccess,
    fetchAutoApprovalHistoryError,
    resetHistory,
} = autoApprovalSlice.actions;

export default autoApprovalSlice.reducer;
