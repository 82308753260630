import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    addContainerNac,
    addFakDetails,
    addRefNumberNac,
    deleteContainerNac,
    editContainerNac,
    editFakDetails,
    fetchContractDetails,
    fetchDeliveryAndContainerModes,
    fetchNacAdditionalDetails,
    isLoadingFn,
} from '../../reducers/aobookingProposal/additionalDetailsNacReducer';
import {
    addContainer,
    addFak,
    addRefNo,
    deleteContainer,
    editContainer,
    getAdditionalDetailsNac,
    getContractDetails,
    getDeliveryAndContainerModes,
    updateAdditionalDetailsFak,
} from '../../../services/apis/aobookingProposal/additionalDetailsNacApi';

function* getAdditionDetails({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any> = yield call(getAdditionalDetailsNac, payload);
        payload['data'] = data;
        yield put({ type: fetchNacAdditionalDetails, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getContractDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(getContractDetails, payload);
        payload['contractDetails'] = data;
        yield put({ type: fetchContractDetails, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getDeliveryAndContainerModesNac({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(getDeliveryAndContainerModes, payload);
        payload['data'] = data;
        yield put({ type: fetchDeliveryAndContainerModes, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addContainerData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<string> = yield call(addContainer, payload);
        payload['data'] = data;
        yield put({ type: addContainerNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* editContainerData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const info: AxiosResponse<string> = yield call(editContainer, payload);
        payload['info'] = info;
        yield put({ type: editContainerNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* deleteContainerData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<string> = yield call(deleteContainer, payload);
        payload['data'] = data;
        yield put({ type: deleteContainerNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addRefNumberData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(addRefNo, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: addRefNumberNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addFakData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(addFak, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: addFakDetails, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* editFakData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(updateAdditionalDetailsFak, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: editFakDetails, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* nacAdditionalDetailsSaga() {
    yield takeEvery('additionalDetailsNacao/fetchNacAdditionalDetails', getAdditionDetails);
    yield takeEvery('additionalDetailsNacao/fetchDeliveryAndContainerModes', getDeliveryAndContainerModesNac);
    yield takeEvery('additionalDetailsNacao/addContainerNac', addContainerData);
    yield takeEvery('additionalDetailsNacao/addRefNumberNac', addRefNumberData);
    yield takeEvery('additionalDetailsNacao/addFakDetails', addFakData);
    yield takeEvery('additionalDetailsNacao/editContainerNac', editContainerData);
    yield takeEvery('additionalDetailsNacao/deleteContainerNac', deleteContainerData);
    yield takeEvery('additionalDetailsNacao/fetchContractDetails', getContractDetailsData);
    yield takeEvery('additionalDetailsNacao/editFakDetails', editFakData);
}

export default nacAdditionalDetailsSaga;
