import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { del, get, patch, post } from '../../HttpMethods';
import axios from 'axios';

export const getAirBPOptions = async (ZO_BP: string, ZO_PK: string) => {
    try {
        const response = await get(`cix/air/bkg/${ZO_BP}/option/${ZO_PK}`, null, externalBaseUrl);
        return response?.data?.[0];
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addAirBPOptions = async (payload: any) => {
    try {
        const response = await post('cix/air/bkg/option', payload, null, false, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const editAirBPOptions = async (payload: any) => {
    try {
        const response = await patch('cix/air/bkg/option', payload, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const deleteAirBPOptions = async (ZO_PK: string, ZO_BP: string, reason: string) => {
    try {
        const response = await del(`cix/air/bkg/${ZO_BP}/option/${ZO_PK}`, { reason }, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const submitAirBPOptions = async (ZO_BP: string, type: string, ZO_PK?: string, reason?: string) => {
    let response;
    try {
        if (type) {
            response = await patch(`cix/air/bkg/clientSubmit`, { ZO_PK, ZO_BP, type, reason }, externalBaseUrl);
        } else {
            response = await patch(`cix/air/bkg/optionSubmit/${ZO_BP}`, null, externalBaseUrl);
        }
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
