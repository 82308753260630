import React from 'react';
import BSTooltip from 'src/components/common/BSTooltip';
import SearchField from 'src/pages/Tracking/LifecycleSearch/SearchField';
import './lifecycleSearch.scss';
const Index: React.FC = () => {
    return (
        <div className="lcs-modules">
            <div className="lcs-wrapper">
                <div className="search-icon-holder">
                    <svg className="svg-icon">
                        <use xlinkHref="#lifeCylceSearch">
                            <title>Lifecycle Search</title>
                        </use>
                    </svg>
                </div>
                <h5 className="lcs-title">Lifecycle Search</h5>
                <p className="lcs-text">
                    Search for PO, Bookings, Agents and{' '}
                    <BSTooltip
                        id="sd"
                        title={
                            <>
                                <div>Carrier</div>
                                <div>Client</div>
                                <div>House Bill</div>
                                <div>Master Bill</div>
                                <div>Port</div>
                                <div>SKU</div>
                                <div>Vendor</div>
                                <div>Vessel</div>
                                <div>Warehouse</div>
                            </>
                        }
                        position="right"
                        tooltipClassList="tooltip-right"
                    >
                        <a href="#" className="lcs-more">
                            More
                        </a>
                    </BSTooltip>
                </p>
                <SearchField />
            </div>
        </div>
    );
};
export default Index;
