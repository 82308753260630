/* eslint-disable no-undefined */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import '../../pages/PO/Events/index.scss';
import 'src/assets/scss/components/_modal.scss';
import 'src/assets/scss/components/_grid.scss';
import '../../pages/Profiles/Clients/ProductMaster/product-master-modal.scss';
import { deleteSavedGridLayouts, updateSavedGridLayouts } from 'src/services/apis/SavedGridLayout';
import { error, success, Position } from 'src/utils/toast';

type NoteProps = {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    layoutsData: any;
    setLayoutsData: any;
    getData: any;
    layoutKeyword: string;
};

const Index: React.FC<NoteProps> = ({ setModalView, layoutsData, setLayoutsData, getData, layoutKeyword }) => {
    const [loading, setIsLoading] = useState(false);
    const [editIndex, setEditIndex] = useState<any>(null);

    const onClose = () => {
        setModalView(false);
        window.location.reload();
    };

    const submitSavedGridLayouts = (data: any) => {
        setIsLoading(true);
        updateSavedGridLayouts(data?.UD_PK, {
            UD_UserDefinedText1: data?.UD_UserDefinedText1,
            UD_UserDefinedText2: data?.UD_UserDefinedText2 ? 'quicklink' : '',
        })
            .then((res: any) => {
                success('Updated successfully', Position.TOP_RIGHT);
                getData(layoutKeyword);
                setEditIndex(null);
            })
            .catch((err: any) => {
                console.log('error', err);
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            })
            .finally(() => setIsLoading(false));
    };

    const removeSavedGridLayouts = (id: string) => {
        setIsLoading(true);
        deleteSavedGridLayouts(id)
            .then((res: any) => {
                success('Deleted successfully', Position.TOP_RIGHT);
                getData(layoutKeyword);
                setEditIndex(null);
            })
            .catch((err: any) => {
                console.log('error', err);
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div className="app-modal small-popup notes-modal">
            <div className="modal-content-holder sop-content-holder large-popup">
                <div className="modal-header">
                    <h2 className="title">My Saved Queries</h2>
                    <button onClick={() => onClose()} className="app-btn modal-close-btn" title="Close">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className={`app-loader ${loading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="modal-section card">
                        <div className="modal-main-content details-box">
                            <div className="grid-container">
                                {layoutsData?.length === 0 && (
                                    <div className="grid-no-content ">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No records found</p>
                                    </div>
                                )}
                                <div className="grid-scroll-content">
                                    <table className="grid-table">
                                        <thead>
                                            <tr className="grid-sticky-row">
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span>Name</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span>Quick Link</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span>Actions</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {layoutsData?.map((res: any, index: number) =>
                                                index !== editIndex ? (
                                                    <tr key={index} className="has-child row-expanded">
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">
                                                                    {res?.UD_UserDefinedText1}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">
                                                                    {res?.UD_UserDefinedText2 ? 'Yes' : 'No'}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="Remove"
                                                                    type="button"
                                                                    onClick={() => removeSavedGridLayouts(res?.UD_PK)}
                                                                >
                                                                    <svg className="svg-icon cancel-icon ">
                                                                        <use xlinkHref="#cancelIcon"></use>
                                                                        <title>Remove</title>
                                                                    </svg>
                                                                </button>

                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="Edit"
                                                                    onClick={() => {
                                                                        setEditIndex(index);
                                                                    }}
                                                                    disabled={editIndex !== null && index !== editIndex}
                                                                    type="button"
                                                                >
                                                                    <svg className="svg-icon save-icon ">
                                                                        <use xlinkHref="#editIcon"></use>
                                                                        <title>Edit</title>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr key={index} className="has-child row-expanded">
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <div className="modal-grid-input-holder">
                                                                    <input
                                                                        placeholder="Enter value"
                                                                        type="text"
                                                                        className="modal-grid-input input-text"
                                                                        name="UD_UserDefinedText1"
                                                                        style={{ width: '50%' }}
                                                                        value={res?.UD_UserDefinedText1}
                                                                        onChange={(e) => {
                                                                            const newArr = [...layoutsData];
                                                                            newArr[index].UD_UserDefinedText1 =
                                                                                e.target.value;
                                                                            setLayoutsData(newArr);
                                                                        }}
                                                                    ></input>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <div className="checkbox-item">
                                                                    <label
                                                                        style={{ display: 'flex' }}
                                                                        className="app-check-wrapper"
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            onChange={(e) => {
                                                                                const newArr = [...layoutsData];
                                                                                newArr[index].UD_UserDefinedText2 =
                                                                                    e.target.checked;
                                                                                setLayoutsData(newArr);
                                                                            }}
                                                                            checked={res?.UD_UserDefinedText2}
                                                                        ></input>
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use href="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="Cancel"
                                                                    type="button"
                                                                    onClick={() => {
                                                                        getData(layoutKeyword);
                                                                        setEditIndex(null);
                                                                    }}
                                                                >
                                                                    <svg className="svg-icon cancel-icon ">
                                                                        <use xlinkHref="#closetableIcon"></use>
                                                                        <title>Remove</title>
                                                                    </svg>
                                                                </button>

                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="Save"
                                                                    onClick={() => {
                                                                        submitSavedGridLayouts(res);
                                                                    }}
                                                                    disabled={editIndex !== null && index !== editIndex}
                                                                    type="button"
                                                                >
                                                                    <svg className="svg-icon save-icon ">
                                                                        <use xlinkHref="#saveIcon"></use>
                                                                        <title>Save</title>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => onClose()}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Close"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Close</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
