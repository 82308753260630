import React, { useState } from 'react';
import ShowForPermissions from '../../../ShowForPermissions';
import '../../BookingProposal/ListGrid/list-grid.scss';
// import SailingViewModal from '../SelectedSailingSchelude/SailingScheduleViewModal';
import SailingDetailModal from '../BookingSailingSchelude/SailingScheduleDetailModal';
import { useUserProfile } from 'src/hooks/useUserProfile';

interface Props {
    data: any;
    dates: any;
    rangeSelectFn: any;
    selectedValue: any;
    bpStatus: any;
    selectedRange: any;
    setselectedColumn: any;
    valueSelected: any;
}
const Index: React.FC<Props> = ({ data, selectedValue, bpStatus, setselectedColumn, selectedRange, valueSelected }) => {
    const [display, setdisplay] = useState(false);
    const [dataAll, setdata] = useState({});
    const { profileType } = useUserProfile();

    return (
        <div className="view-list-grid air-flight-view-list-grid">
            {data.length === 0 && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No Sailing Schedules Available</p>
                </div>
            )}
            <div className="list-grid">
                {data.map((res: any) => (
                    <>
                        <div className="grid-child-item col-grid-checkbox-item">
                            <ShowForPermissions type="ao" permission="create">
                                {bpStatus !== 'Cancelled' &&
                                    bpStatus !== 'Withdrawn' &&
                                    bpStatus !== 'Pending_Reassignment' &&
                                    (profileType === 'forwarder' || profileType === 'agent') && (
                                        <div className="grid-child-data">
                                            <div className="checkbox-item">
                                                <label
                                                    className="app-check-wrapper"
                                                    onClick={() => {
                                                        selectedValue(res);
                                                        setselectedColumn(selectedRange.startDate);
                                                    }}
                                                >
                                                    <input
                                                        type="radio"
                                                        className="checkbox-input"
                                                        name="grid-radio-group"
                                                        checked={
                                                            res?.sailingId === valueSelected?.sailingId ? true : false
                                                        }
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon notification-circle-icon">
                                                            <use href="#circleIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <div className="grid-child-item col-name">
                            <div className="grid-child-data list-view-items">
                                <div className="ship-logo">
                                    <svg className="svg-icon aeroplane-icon">
                                        <use href="#aeroplaneIcon">
                                            <title>Aeroplane</title>
                                        </use>
                                    </svg>
                                </div>
                                <div className="content-list-wrapper">
                                    <div className="content-heading-holder">
                                        <span className="tab-heading">Alaska Airlines</span>
                                    </div>
                                    <div className="content-detail">
                                        <span>A12333, +1</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-child-item col-destination">
                            <div className="grid-child-data">
                                <div className="des-progress-bar">
                                    <div className="destination begin-journey">
                                        <span className="point1">ETD</span>
                                        <div className="date-time">
                                            23-02-2022,<span className="time-field">14.00</span>
                                        </div>
                                        <span className="point2">CNYTN</span>
                                    </div>
                                    <div className="destination center-journey">
                                        <span className="center-point">1 Stop</span>
                                        <div className="point1">
                                            22h<span className="time-field">30m</span>
                                        </div>
                                    </div>
                                    <div className="destination end-journey">
                                        <span className="point1">ETA</span>
                                        <div className="date-time">
                                            23-02-2022,<span className="time-field">14.00</span>
                                        </div>
                                        <span className="point2">AUYKL</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-child-item col-cost1">
                            <div className="grid-child-data list-view-items">
                                <div className="content-list-wrapper">
                                    <div className="content-heading-holder">
                                        <span className="tab-lite-heading">Total Cost</span>
                                    </div>
                                    <div className="content-detail">
                                        <span className="sub-content">3700 USD </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-child-item col-cost2">
                            <div className="grid-child-data list-view-items">
                                <div className="content-list-wrapper">
                                    <div className="content-heading-holder">
                                        <span className="tab-lite-heading">Quote</span>
                                    </div>
                                    <div className="content-detail">
                                        <a href="#" className="buy-link">
                                            AGS Quote 453223.pdf
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid-child-item col-action">
                            <a
                                className="more-link app-btn-secondary app-btn action-btn"
                                href="javascript:void(0)"
                                onClick={() => {
                                    setdata(res);
                                    setdisplay(true);
                                }}
                                title="View"
                            >
                                <svg className="svg-icon eye-icon ">
                                    <use xlinkHref="#eyeIcon">
                                        <title>View</title>
                                    </use>
                                </svg>
                            </a>
                        </div>
                    </>
                ))}
            </div>
            {display && <SailingDetailModal displayFunc={setdisplay} data={dataAll} />}
            {/*<SailingDetailModal displayFunc={setdisplay} data={dataAll} />*/}
        </div>
    );
};
export default Index;
