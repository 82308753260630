import React from 'react';
import './lifecycleBooking.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import SearchField from '../SearchField';
import LifecycleBookingWrapper from './LifecycleBookingWrapper';
import { useSelector } from 'react-redux';
// import './tracking.scss';

const Index: React.FC = () => {
    const { type } = useParams() as any;
    const location = useLocation() as any;

    // const location = useLocation() as any;
    const { isLoading } = useSelector((state: any) => state.lifeCycleSearch);

    const { name } = location?.state || '';
    // const typeOfData = () => {
    //     let name = '';
    //     //let route = '';
    //     if (type === 'bookings' || type === 'house-bill' || type === 'master-bill') {
    //         name = 'Booking';
    //     } else if (type === 'clients' || type === 'agents' || type === 'vendors') {
    //         name = 'Profile';
    //     } else if (type === 'sku') {
    //         name = 'SKU';
    //     } else {
    //         name = 'PO';
    //     }
    //     return { name };
    // };

    const searchKeywordFormat = (type: string) => {
        let keyvalue = type;
        //Remove the last 's' from type
        if (type.charAt(type.length - 1) === 's') {
            keyvalue = type.slice(0, -1);
        }
        if (keyvalue === 'sku') {
            keyvalue = 'SKU';
        }
        // Find the next word after the hyphen and capitalize its first character
        const stringWithCapitals = keyvalue.replace(/-([a-zA-Z])/g, function (match, nextChar) {
            return ' ' + nextChar.toUpperCase();
        });
        //Change the hyphen to space
        const stringWithSpaces = stringWithCapitals.replace(/-/g, ' ');
        //Make the first key capital
        keyvalue = stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
        return keyvalue;
    };

    return (
        <div className="main-wrapper container tracking-module">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="main-header-content-holder large-header no-background">
                <div className="breadcrumbs-holder no-bg">
                    <ul className="breadcrumbs">
                        <Link to="/tracking" className="dropdown-arrow" state={{ currentTab: 'Lifecycle Search' }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>Lifecycle search</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link
                                className="breadcrumb-item"
                                to={'/tracking'}
                                state={{ currentTab: 'Lifecycle Search' }}
                            >
                                Lifecycle search
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a className="breadcrumb-item">
                                {searchKeywordFormat(type)} : {name}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="lcs-header-holder">
                    <SearchField />

                    {/* <div className="head-btn-holder">
                        {(type !== 'ports' || type === 'vessels' || type !== 'carriers') && (
                            <button className="app-btn app-btn-secondary" title={`Go to ${typeOfData().name}`}>
                                <span className="button-text">Go to {typeOfData().name}</span>
                            </button>
                        )}
                        <button className="app-btn export-btn icon-button  app-btn-secondary">
                            <svg className="svg-icon export-icon">
                                <use xlinkHref="#exportIcon"></use>
                            </svg>
                            <span className="button-text">Export</span>
                            <span className="dropdown-arrow">
                                <svg className="svg-icon arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>dropdown</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div> */}
                </div>
            </div>
            <div className="lifecycle-booking-module">
                <LifecycleBookingWrapper type={type} />
            </div>
        </div>
    );
};
export default Index;
