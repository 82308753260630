/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface BpQuotationList {
    name: string;
    version: string;
    id: string;
}
export interface Currency {
    id: string;
    name: string;
    code: string;
}

export interface BpEnrichBooking {
    bookingRefNum: string;
    soNumber: string;
    houseBillNo: string;
    originCartageCompany: string;
}

const defaultBpEnrichBooking = {
    bookingRefNum: '',
    soNumber: '',
    houseBillNo: '',
    originCartageCompany: '',
};

export interface BpQuotation {
    cd_PK: string;
    cd_activedate: string;
    cd_author: string;
    cd_clientacceptancedate: string;
    cd_clientacceptedby: string;
    cd_clientsignatory: string;
    cd_doclocation: string;
    cd_docname: string;
    cd_doctype: string;
    cd_enddate: string;
    cd_forwardersignatory: string;
    cd_iteration: string;
    cd_renewal: string;
    cd_reviewedby: string;
    cd_services: string;
    cd_startdate: string;
    cd_status: string;
    cd_version: string;
    fileName: string;
    savedFileSizeInKb: number;
}

interface AdditionalDetailsState {
    isLoading: boolean;
    quotationList: BpQuotationList[];
    enrichBooking: BpEnrichBooking;
    quotation: BpQuotation | undefined;
    currency: Currency[];
    additionalDetailsSuccess: any;
    additionalDetailsError: any;
    isError?: boolean;
    gateInDateFailed?: boolean;
}

const initialState: AdditionalDetailsState = {
    isLoading: true,
    quotationList: [],
    enrichBooking: defaultBpEnrichBooking,
    quotation: undefined,
    currency: [],
    additionalDetailsSuccess: undefined,
    additionalDetailsError: undefined,
    isError: false,
    gateInDateFailed: false,
};

export const additionalDetailsSlice = createSlice({
    name: 'additionalDetailsao',
    initialState,
    reducers: {
        fetchAllQuotation: (state, _action) => {
            state.isLoading = true;
        },
        fetchAllQuotationSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.quotationList = action.payload?.data?.info;
        },
        fetchAllQuotationFailed(state, action) {
            state.isLoading = false;
            // state.isError = true;
            state.additionalDetailsError = action.payload;
        },
        fetchSingleQuotation: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchSingleQuotationSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.quotation = action.payload?.data?.info;
        },
        fetchSingleQuotationFailed(state, action) {
            // state.isLoading = false;
            // state.isError = true;
            state.additionalDetailsError = action.payload;
        },
        updateQuotation(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateQuotationSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        updateQuotationFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        approveBp(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        approveBpSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        gateInDateValidation: (state, action) => {
            state.gateInDateFailed = action.payload;
        },
        approveBpFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        rejectBp(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        rejectBpSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        rejectBpFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        assignToAgent(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        assignToAgentSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        assignToAgentFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        fetchCurrency: (state, _action) => {
            state.isError = false;
        },
        fetchCurrencySuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.currency = action.payload?.data?.info;
        },
        fetchCurrencyFailure(state, action) {
            state.isError = true;
            state.additionalDetailsError = action.payload;
        },
        updateViewStatus(state, _action) {
            // state.isLoading = true;
            state.isError = false;
        },
        updateViewStatusSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        updateViewStatusFailure(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        updateModalStatus(state, _action) {
            // state.isLoading = true;
            state.isError = false;
        },
        updateModalStatusSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        updateModalStatusFailure(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        fetchEnrichBooking: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchEnrichBookingSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.enrichBooking = action.payload?.data?.info;
        },
        fetchEnrichBookingFailure(state, action) {
            // state.isLoading = false;
            // state.isError = true;
            state.additionalDetailsError = action.payload;
        },
        updateEnrichBooking(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateEnrichBookingSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.additionalDetailsSuccess = action.payload;
            state.additionalDetailsError = {};
        },
        updateEnrichBookingFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.additionalDetailsSuccess = {};
            state.additionalDetailsError = action.payload;
        },
        clearFailure: (state, _action) => {
            state.additionalDetailsError = undefined;
            state.additionalDetailsSuccess = undefined;
            state.isError = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const {
    fetchAllQuotation,
    fetchAllQuotationSuccess,
    fetchAllQuotationFailed,
    fetchSingleQuotation,
    fetchSingleQuotationSuccess,
    fetchSingleQuotationFailed,
    updateQuotation,
    updateQuotationSuccess,
    updateQuotationFailure,
    approveBp,
    approveBpSuccess,
    approveBpFailure,
    rejectBp,
    rejectBpSuccess,
    rejectBpFailure,
    assignToAgent,
    assignToAgentSuccess,
    assignToAgentFailure,
    fetchCurrency,
    fetchCurrencySuccess,
    fetchCurrencyFailure,
    updateViewStatus,
    updateViewStatusSuccess,
    updateViewStatusFailure,
    updateModalStatus,
    updateModalStatusSuccess,
    updateModalStatusFailure,
    fetchEnrichBooking,
    fetchEnrichBookingSuccess,
    fetchEnrichBookingFailure,
    updateEnrichBooking,
    updateEnrichBookingSuccess,
    updateEnrichBookingFailure,
    clearFailure,
    isLoadingFn,
    gateInDateValidation,
} = additionalDetailsSlice.actions;

export const selectAdditionalDetailsLoading = (state: RootState) => state.additionalDetailsData.isLoading;
export const selectQuotation = (state: RootState) => state.additionalDetailsData.quotation;
export const selectQuotationList = (state: RootState) => state.additionalDetailsData.quotationList;
export const selectCurrency = (state: RootState) => state.additionalDetailsData.currency;
export const selectEnrichBooking = (state: RootState) => state.additionalDetailsData.enrichBooking;
export const selectAdditionalDetailsSuccess = (state: RootState) =>
    state.additionalDetailsData.additionalDetailsSuccess;
export const selectAdditionalDetailsError = (state: RootState) => state.additionalDetailsData.additionalDetailsError;
export const selectAdditionalDetailsIsError = (state: RootState) => state.additionalDetailsData.isError;
export const selectgateInDateValidation = (state: RootState) => state.additionalDetailsData.gateInDateFailed;

export default additionalDetailsSlice.reducer;
