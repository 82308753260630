import React from 'react';
import '../../BookingProposal/BookingAdditionalInfo/reject-booking-proposal.scss';
interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    reason: string;
    setReason: any;
    onSubmit: any;
    type: string;
}

const Index: React.FC<ModalProps> = ({ setModalView, reason, setReason, onSubmit, type }) => {
    return (
        <div className="app-modal">
            <div className="modal-content-holder medium-popup reject-booking-modal">
                <div className="modal-header">
                    <div className="title">Change {type}</div>
                    <button
                        onClick={() => {
                            setModalView(false);
                            setReason('');
                        }}
                        className="app-btn modal-close-btn"
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <form id="productForm" action="" className="modal-form">
                            <div className=" modal-input-holder">
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder={`Enter the reason to Change ${type}`}
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                        }}
                                        maxLength={250}
                                    ></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="modal-footer">
                    <button
                        onClick={() => {
                            setModalView(false);
                            setReason('');
                        }}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Submit"
                        disabled={reason?.trim()?.length ? false : true}
                        onClick={onSubmit}
                    >
                        <span className="button-text footer-button-text">Submit</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
