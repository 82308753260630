export const hubLinkStatus = {
    Waiting: {
        tooltipText: 'Waiting',
        styleClass: 'not-attempted-link',
    },
    InProgress: {
        tooltipText: 'In Progress',
        styleClass: 'attempt-establish',
    },
    Completed: {
        tooltipText: 'Completed',
        styleClass: 'link-established',
    },
    Error: {
        tooltipText: 'Error',
        styleClass: 'error-link',
    },
} as any;

export const bpAttachmentStatus = {
    1: {
        styleClass: 'icon-attachment',
        tooltipText: 'Missing attachments',
    },
    2: {
        styleClass: 'icon-uploaded',
        tooltipText: 'Attachments are uploaded',
    },
} as any;
