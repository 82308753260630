import { createSlice } from '@reduxjs/toolkit';
// import { newData, posAtOriginCountryData } from 'src/pages/Dashboard/Map/demoData';
import { arrayCheck } from 'src/utils/arrayMatchCheck';

export interface MoreClusterData {
    type:
        | 'bookingsAtOriginCountry'
        | 'bookingsAtOriginPort'
        | 'bookingsIntransitArray'
        | 'bookingsAtDestinationPort'
        | 'posAtOriginCountry';
    lat: number;
    lon: number;
}

export type ClusterData =
    | (BookingsAtOriginCountry & MoreClusterData)
    | (BookingsAtOriginPort & MoreClusterData)
    | (BookingsIntransit & MoreClusterData)
    | (BookingsAtDestinationPort & MoreClusterData)
    | (POsAtOriginCountry & MoreClusterData)
    | null;

export type Coordinates = [number, number];
export interface EtaEtdCount {
    etdIn7DaysCount: number;
    etdDelayedCount: number;
    etaIn7DaysCount: number;
    etaDelayedCount: number;
}
export interface Destination {
    destinationPortName: string;
    destinationPortCode: string;
    destinationPortCoordinates: Coordinates;
    shippingMode: 'SEA' | 'AIR';
}
export interface BookingPosStats {
    posAtOriginCountry: any;
    bookingsAtOriginCountry: any;
    bookingsAtOriginPort: any;
    bookingsIntransitArray: any;
    bookingsAtDestinationPort: any;
}

export interface MapState extends BookingPosStats {
    hoveredTileKey: string;
    isLoading: boolean;
    isLoadingSideBar: boolean;
    sideBarView: boolean;
    sideBarVewTiles: boolean;
    sideBarValue: string;
    singleMapData: ClusterData;
    sideBarData: any;
    clickedTileKey: string;
    locationValue: boolean;
    payloadValues: any;
}

export interface BookingsAtDestinationPort {
    destinationPortName: string;
    destinationPortCode: string;
    destinationPortCoordinates: Coordinates;
    bookingCount: number;
    poCount: number;
    totalTeu: number;
    countryName: string;
    countryCode: string;
}

export interface POCountryData {
    countryName: string;
    countryCode: string;
    countryCoordinates: Coordinates;
    poCount: number;
}

export interface POsAtOriginCountry extends POCountryData {
    vendorCount: number;
}
export interface BookingsAtOriginCountry extends POCountryData, EtaEtdCount {
    bookingCount: number;
}

export interface BookingsAtOriginPort extends EtaEtdCount {
    originPortName: string;
    originPortCode: string;
    originPortCoordinates: Coordinates;
    poCount: number;
    bookingCount: number;
    totalTeu: number;
    destinations: Destination[];
    countryName: string;
    countryCode: string;
}

export interface BookingsIntransit
    extends Omit<BookingsAtOriginPort, 'etdIn7DaysCount' | 'etdDelayedCount' | 'destinations'> {
    destinationPortName: string;
    destinationPortCode: string;
    destinationPortCoordinates: Coordinates;
    noOfStops: number;
    vesselName: string;
    voyageName: string;
    speed: string;
    routeTravelled: Array<[number, number]>;
    lastUpdate: Date;
    ata: Date;
    atd: Date;
    eta: Date;
    etd: Date;
    nextStopName: string;
    nextStopETA: Date;
    nextStopCoordinates: Coordinates;
    shippingMode: 'SEA' | 'AIR';
}

const initialState: MapState = {
    bookingsAtOriginCountry: [],
    bookingsAtOriginPort: [],
    bookingsIntransitArray: [],
    bookingsAtDestinationPort: [],
    hoveredTileKey: '',
    isLoading: true,
    isLoadingSideBar: false,
    posAtOriginCountry: [],
    sideBarView: false,
    sideBarVewTiles: false,
    sideBarValue: '',
    singleMapData: null,
    sideBarData: [],
    clickedTileKey: '',
    locationValue: false,
    payloadValues: {},
};

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        isLoadingFn(_state, _action) {
            _state.isLoading = _action.payload;
        },
        isLoadingFnSideBar(_state, _action) {
            _state.isLoadingSideBar = _action.payload;
        },
        fetchMapData(_state, _action) {
            if (_action.payload?.success) {
                //comparing every data for same latitude and longitude to update it
                //updating posAtorgnCounty WRT bookingsAtOriginCountry
                const posAtOrgnUpdated = [..._action.payload?.posAtOriginCountry];
                posAtOrgnUpdated.forEach((item: any, i: any) => {
                    if (
                        arrayCheck(
                            _action.payload?.bookingsAtOriginCountry, //data to compare
                            item.countryCoordinates, //value to compare
                            'countryCoordinates' //key word to compare on the coming array
                        )
                    ) {
                        //updating lattitude by 0.07 to be seen in the map
                        posAtOrgnUpdated[i].countryCoordinates[1] = posAtOrgnUpdated[i].countryCoordinates[1] + 0.07;
                    }
                });
                //updating posAtorgnCounty WRT bookingsAtDestinationPort
                posAtOrgnUpdated.forEach((item: any, i: any) => {
                    if (
                        arrayCheck(
                            _action.payload?.bookingsAtDestinationPort,
                            item.countryCoordinates,
                            'destinationPortCoordinates'
                        )
                    ) {
                        posAtOrgnUpdated[i].countryCoordinates[1] = posAtOrgnUpdated[i].countryCoordinates[1] + 0.07;
                    }
                });
                //updating posAtorgnCounty WRT bookingsAtOriginPort
                posAtOrgnUpdated.forEach((item: any, i: any) => {
                    if (
                        arrayCheck(
                            _action.payload?.bookingsAtOriginPort,
                            item.countryCoordinates,
                            'originPortCoordinates'
                        )
                    ) {
                        posAtOrgnUpdated[i].countryCoordinates[1] = posAtOrgnUpdated[i].countryCoordinates[1] + 0.07;
                    }
                });
                const bookingsAtDestinationPortUpdated = [..._action.payload?.bookingsAtDestinationPort];
                //updating bookingsAtDestinationPort WRT bookingsAtOriginCountry
                bookingsAtDestinationPortUpdated.forEach((item: any, i: any) => {
                    if (
                        arrayCheck(
                            _action.payload?.bookingsAtOriginCountry,
                            item.destinationPortCoordinates,
                            'countryCoordinates'
                        )
                    ) {
                        //updating lattitude by 0.07 to be seen in the map
                        bookingsAtDestinationPortUpdated[i].countryCoordinates[1] =
                            bookingsAtDestinationPortUpdated[i].countryCoordinates[1] + 0.07;
                    }
                });
                //updating bookingsAtDestinationPort WRT bookingsAtOriginPort
                bookingsAtDestinationPortUpdated.forEach((item: any, i: any) => {
                    if (
                        arrayCheck(
                            _action.payload?.bookingsAtOriginPort,
                            item.destinationPortCoordinates,
                            'originPortCoordinates'
                        )
                    ) {
                        //updating lattitude by 0.07 to be seen in the map
                        bookingsAtDestinationPortUpdated[i].countryCoordinates[1] =
                            bookingsAtDestinationPortUpdated[i].countryCoordinates[1] + 0.07;
                    }
                });

                const bookingsAtOriginCountryUpdated = [..._action.payload?.bookingsAtOriginCountry];
                //updating bookingsAtOriginCountry WRT bookingsAtOriginPort
                bookingsAtOriginCountryUpdated.forEach((item: any, i: any) => {
                    if (
                        arrayCheck(
                            _action.payload?.bookingsAtOriginPort,
                            item.countryCoordinates,
                            'originPortCoordinates'
                        )
                    ) {
                        //updating lattitude by 0.07 to be seen in the map
                        bookingsAtOriginCountryUpdated[i].countryCoordinates[1] =
                            bookingsAtOriginCountryUpdated[i].countryCoordinates[1] + 0.07;
                    }
                });
                _state.posAtOriginCountry = posAtOrgnUpdated;
                _state.bookingsAtDestinationPort = bookingsAtDestinationPortUpdated;
                _state.bookingsAtOriginCountry = bookingsAtOriginCountryUpdated;
                _state.bookingsAtOriginPort = _action.payload?.bookingsAtOriginPort;
                _state.bookingsIntransitArray = _action.payload?.bookingsIntransit;
            }
        },
        fetchAllMapData(_state, _action) {
            //console.log(_action);
        },
        fetchPurchaseOrderMap(_state, _action) {
            _state.sideBarData = _action.payload;
        },
        fetchBookingsInOriginMap(_state, _action) {
            _state.sideBarData = _action.payload;
        },
        fetchBookingsInTransitMap(_state, _action) {
            _state.sideBarData = _action.payload;
        },
        fetchBookingAtPortMap(_state, _action) {
            _state.sideBarData = _action.payload;
        },
        showSidebar(_state, _action) {
            _state.sideBarView = _action.payload;
        },
        showSidebarForTile(_state, _action) {
            _state.sideBarVewTiles = _action.payload;
        },
        sideBarType(_state, _action) {
            _state.sideBarValue = _action.payload;
        },
        updateHoveredTileKey(_state, _action) {
            _state.hoveredTileKey = _action.payload;
        },
        updateClickedTileKey(_state, _action) {
            _state.clickedTileKey = _action.payload;
        },
        saveSingleMapData(_state, _action) {
            if (_action.payload) {
                _state.singleMapData = _action.payload;
            }
        },
        clearSingleMapData(_state, _action) {
            _state.singleMapData = null;
        },
        fetchSidebarData(_state, _action) {
            _state.sideBarData = _action.payload;
        },
        selectedLocation(_state, _action) {
            _state.locationValue = _action.payload;
        },
        savePayloadValues(_state, _action) {
            _state.payloadValues = _action.payload;
        },
    },
});

export const {
    fetchMapData,
    showSidebar,
    sideBarType,
    updateHoveredTileKey,
    saveSingleMapData,
    clearSingleMapData,
    fetchSidebarData,
    fetchBookingAtPortMap,
    fetchBookingsInTransitMap,
    fetchBookingsInOriginMap,
    fetchPurchaseOrderMap,
    fetchAllMapData,
    updateClickedTileKey,
    isLoadingFn,
    isLoadingFnSideBar,
    selectedLocation,
    savePayloadValues,
    showSidebarForTile,
} = mapSlice.actions;

export default mapSlice.reducer;
