import React from 'react';
// import './style.css';
import '../../assets/scss/components/_modal.scss';

interface BioProps {
    onConfirm: any;
    onCancel: any;
    name: any;
}

const Index: React.FC<BioProps> = (props) => {
    return (
        <>
            <div className="app-modal container">
                <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2 className="title"> Delete Role</h2>
                        <button className="app-btn modal-close-btn" onClick={() => props.onCancel()}>
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use xlinkHref="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="modal-content">
                        <p>
                            This role will be deleted.
                            <br /> Are you sure you want to delete {props.name}?
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="app-btn app-btn-secondary modal-btn footer-btn"
                            onClick={() => props.onCancel(false)}
                        >
                            <span className="button-text vendor-button-text">No</span>
                        </button>
                        <button
                            style={{ marginLeft: '8px' }}
                            className="app-btn app-btn-primary modal-btn footer-btn"
                            onClick={() => props.onConfirm()}
                        >
                            <span className="button-text vendor-button-text">Yes</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
