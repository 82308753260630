import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, post } from '../HttpMethods';
import axios from 'axios';

export const getSingleEvent = async (id: string) => {
    try {
        const response = await get('/cix/scr/lifecycle/event/' + id, null, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
