/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
type Props = {
    type: string;
    title: string;
    keyName: string;
    quoteData: any;
    setQuoteData: any;
    codeExists?: any;
    checkIfQuoteExists?: any;
    saveTriggered: boolean;
};

const QuoteItemDropdownCard: React.FC<Props> = ({
    title,
    keyName,
    quoteData,
    setQuoteData,
    codeExists,
    checkIfQuoteExists,
    saveTriggered,
    type,
}) => {
    const { Id } = useParams();
    const [code, setCode] = useState('');
    const [description, setDescription] = useState('');
    const [definition, setDefinition] = useState('');

    useEffect(() => {
        if (quoteData?.itemCode) {
            setCode(quoteData?.itemCode);
            setDescription(quoteData?.itemDescription);
            setDefinition(quoteData?.itemDefinition);
        }
    }, [quoteData?.itemCode]);

    return (
        <>
            <div className="detail-box-common">
                <h6 className="details-box-heading-2">
                    Code <span className="mandatory-field-text">*</span>
                </h6>
                <div className="details-box-detail">
                    <div className="details-box-detail-single">
                        <input
                            type="text"
                            maxLength={type === 'quote' ? 3 : 5}
                            disabled={Id === 'add' ? false : true}
                            onChange={(e) => {
                                setCode(e.target.value?.toUpperCase());
                                setQuoteData({ ...quoteData, itemCode: e.target.value?.toUpperCase() });
                            }}
                            onBlur={() => {
                                if (checkIfQuoteExists) {
                                    checkIfQuoteExists(code);
                                }
                            }}
                            className={`input-text details-box-detail-text-box ${codeExists ? 'error-border' : ''}`}
                            value={code}
                            placeholder="Enter code"
                        />
                    </div>
                </div>
                {saveTriggered && !code && <div className="error-text">This field is required</div>}
                {codeExists && <div className="error-text">Code already exist</div>}
            </div>
            <div className="detail-box-common">
                <h6 className="details-box-heading-2">
                    Internal Description <span className="mandatory-field-text">*</span>
                </h6>
                <div className="details-box-detail">
                    <div className="details-box-detail-single">
                        <input
                            type="text"
                            onChange={(e) => {
                                setDescription(e.target.value);
                                setQuoteData({ ...quoteData, itemDescription: e.target.value });
                            }}
                            className="input-text details-box-detail-text-box"
                            value={description}
                            maxLength={50}
                            placeholder="Enter an internal description"
                        />
                    </div>
                </div>
                {saveTriggered && !description && <div className="error-text">This field is required</div>}
            </div>
            {type === 'quote' && (
                <div className="detail-box-common">
                    <h6 className="details-box-heading-2">
                        Definition <span className="mandatory-field-text">*</span>
                    </h6>
                    <div className="details-box-detail">
                        <div className="details-box-detail-single">
                            <input
                                type="text"
                                onChange={(e) => {
                                    setDefinition(e.target.value);
                                    setQuoteData({ ...quoteData, itemDefinition: e.target.value });
                                }}
                                className="input-text details-box-detail-text-box"
                                value={definition}
                                placeholder="Enter a definition"
                            />
                        </div>
                    </div>
                    {saveTriggered && !definition && <div className="error-text">This field is required</div>}
                </div>
            )}
        </>
    );
};

export default QuoteItemDropdownCard;
