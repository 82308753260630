import React from 'react';
import { RootState } from 'src/store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import InsightViewHeaderExpanded from '../InsightViewHeaderExpanded';
import { makeApiCall } from 'src/pages/Dashboard/Insights/makeApiCall';
// import ActiveOrders from 'src/pages/Dashboard/InsightsExpanded/ActiveOrders';
// import ActiveBookings from 'src/pages/Dashboard/InsightsExpanded/ActiveBookings';
import { FilterList, FilterModeList } from 'src/pages/Dashboard/InsightsExpanded/enums';
import {
    // setInsightsForwarded,
    setInsightsMainPage,
    setInsightsMode,
    setInsightsSelectedType,
    // setInsightsSelectedTypeFilter,
} from 'src/store/reducers/dashboard/insightsReducer';
import ActiveOrderMain from 'src/pages/Dashboard/InsightsExpanded/ActiveOrders/ActiveOderMain';
import ActiveOrderDetail from 'src/pages/Dashboard/InsightsExpanded/ActiveOrders/ActiveOrderDetail';
import ActiveBookingMain from 'src/pages/Dashboard/InsightsExpanded/ActiveOrders/ActiveBookingMain';
import ActiveBookingDetail from 'src/pages/Dashboard/InsightsExpanded/ActiveOrders/ActiveBookingDetail';
const Index = () => {
    const dispatch = useDispatch();

    //========================= Redux values =======================================//
    const {
        dashboardActiveOrders,
        dashboardActiveBookings,
        insightsMode,
        insightsDestination,
        insightsDurationRange,
        insightsMainPage,
    } = useSelector((state: RootState) => state.dashboardInsights);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);
    const dashboardActiveOrdersRedux = React.useMemo(() => {
        if (dashboardActiveOrders) {
            return dashboardActiveOrders;
        }
    }, [dashboardActiveOrders]);

    const dashboardBookingsRedux = React.useMemo(() => {
        if (dashboardActiveBookings) {
            return dashboardActiveBookings;
        }
    }, [dashboardActiveBookings]);

    //=========================================================================//

    //===================================== Permission Check ===============================//
    const isActiveOrders =
        insightsMainPage === 'Active Orders' || insightsMainPage === 'Active Order Details' || insightsMainPage === ''
            ? true
            : false;

    const isActiveBookings =
        insightsMainPage === 'Active Bookings' || insightsMainPage === 'Active Bookings Details' ? true : false;

    const isToShowActiveBookings = React.useMemo(() => {
        let show = true;
        if (dashboardBookingsRedux) {
            if (dashboardBookingsRedux['total']?.count < 1) {
                show = false;
            }
        } else {
            show = true;
        }
        return show;
    }, [dashboardBookingsRedux]);

    const isToShowActiveOrders = React.useMemo(() => {
        let show = true;
        if (dashboardActiveOrdersRedux) {
            if (dashboardActiveOrdersRedux['total']?.count < 1) {
                show = false;
            }
        } else {
            show = true;
        }
        return show;
    }, [dashboardActiveOrdersRedux]);

    //======================================================================================//

    //===================== object empty or not check fn=====================//
    const isEmpty = (obj: any) => {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return true;
    };
    //========================================================================//

    //=================== Initial API call =========================//
    React.useEffect(() => {
        if (isEmpty(dashboardActiveOrdersRedux)) {
            makeApiCall(
                dispatch,
                [],
                FilterList.Days,
                [],
                'activeOrder',
                '',
                '',
                selectedSearchItem,
                selectedSearchItem?.type
            );
        }
        if (isEmpty(dashboardBookingsRedux)) {
            makeApiCall(
                dispatch,
                [],
                FilterList.Days,
                [],
                'activeBooking',
                '',
                '',
                selectedSearchItem,
                selectedSearchItem?.type
            );
        }
    }, []);
    //===============================================================//

    return (
        <div className="insight-tab-wrapper">
            <div className="left-tab-block">
                <div className="vertical-tab">
                    {isToShowActiveOrders && (
                        <a
                            className={`tab-item ${isActiveOrders ? 'active' : ''}`}
                            onClick={() => {
                                dispatch(setInsightsSelectedType(''));
                                dispatch(setInsightsMainPage(''));
                                let currentArray: string[] = insightsMode;
                                if (
                                    currentArray?.includes(FilterModeList.Fcl) ||
                                    currentArray?.includes(FilterModeList.Lcl) ||
                                    currentArray?.includes(FilterModeList.Bcn)
                                ) {
                                    currentArray = insightsMode?.filter(
                                        (val: string) =>
                                            val !== FilterModeList.Fcl &&
                                            val !== FilterModeList.Lcl &&
                                            val !== FilterModeList.Bcn
                                    );
                                    currentArray.push(FilterModeList.Sea);
                                    dispatch(setInsightsMode(currentArray));
                                } else {
                                    currentArray = insightsMode?.filter(
                                        (val: string) =>
                                            val !== FilterModeList.Fcl &&
                                            val !== FilterModeList.Lcl &&
                                            val !== FilterModeList.Bcn
                                    );
                                    dispatch(setInsightsMode(currentArray));
                                }
                                makeApiCall(
                                    dispatch,
                                    currentArray,
                                    insightsDurationRange,
                                    insightsDestination,
                                    'activeOrder',
                                    '',
                                    '',
                                    selectedSearchItem,
                                    selectedSearchItem?.type
                                );
                            }}
                        >
                            Active Orders
                        </a>
                    )}
                    {isToShowActiveBookings && (
                        <a
                            className={`tab-item ${isActiveBookings ? 'active' : ''}`}
                            onClick={() => {
                                dispatch(setInsightsSelectedType(''));
                                dispatch(setInsightsMainPage('Active Bookings'));
                                let currentArray: string[] = insightsMode;
                                if (currentArray?.includes(FilterModeList.Sea)) {
                                    currentArray = insightsMode?.filter((val: string) => val !== FilterModeList.Sea);
                                    currentArray.push(FilterModeList.Fcl);
                                    currentArray.push(FilterModeList.Lcl);
                                    currentArray.push(FilterModeList.Bcn);
                                    dispatch(setInsightsMode(currentArray));
                                } else {
                                    currentArray = insightsMode?.filter((val: string) => val !== FilterModeList.Sea);
                                    dispatch(setInsightsMode(currentArray));
                                }
                                makeApiCall(
                                    dispatch,
                                    currentArray,
                                    insightsDurationRange,
                                    insightsDestination,
                                    'activeBooking',
                                    '',
                                    '',
                                    selectedSearchItem,
                                    selectedSearchItem?.type
                                );
                            }}
                        >
                            Active Bookings
                        </a>
                    )}
                </div>
            </div>
            <div className="tab-content-area customscrollbar">
                <div className="tab-content-header">
                    <div className="header-title-bar">
                        {isActiveOrders
                            ? isToShowActiveOrders && (
                                  <InsightViewHeaderExpanded
                                      tabName={'Orders'}
                                      count={dashboardActiveOrders['total']?.count || 0}
                                  />
                              )
                            : isToShowActiveBookings && (
                                  <InsightViewHeaderExpanded
                                      tabName={'Bookings'}
                                      count={dashboardActiveBookings['total']?.count || 0}
                                  />
                              )}
                    </div>
                </div>
                {(insightsMainPage === 'Active Orders' || insightsMainPage === '') && isToShowActiveOrders && (
                    <ActiveOrderMain />
                )}
                {insightsMainPage === 'Active Order Details' && isToShowActiveOrders && <ActiveOrderDetail />}
                {insightsMainPage === 'Active Bookings' && isToShowActiveBookings && <ActiveBookingMain />}
                {insightsMainPage === 'Active Bookings Details' && isToShowActiveBookings && <ActiveBookingDetail />}
            </div>
        </div>
    );
};
export default Index;
