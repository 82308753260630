import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchDashboardActiveBookingsSuccess,
    fetchDashboardActiveOrdersExpanded,
    fetchDashboardActiveOrdersErrorExpanded,
    fetchDashboardActiveOrdersSuccessExpanded,
    fetchDashboardActiveOrders,
    fetchDashboardActiveOrdersError,
    fetchDashboardActiveOrdersSuccess,
    fetchDashboardActiveBookingsError,
    fetchDashboardActiveBookings,
    fetchDashboardActiveBookingsExpanded,
    fetchDashboardActiveBookingsSuccessExpanded,
    fetchDashboardActiveBookingsErrorExpanded,
} from 'src/store/reducers/dashboard/insightsReducer';

import {
    dashboardActiveBookingsService,
    dashboardActiveBookingsServiceExpanded,
    dashboardActiveOrdersService,
    dashboardActiveOrdersServiceExpanded,
} from 'src/services/apis/dashboard/quickLinksApi';
import { setFavDataResultToGroupObject } from 'src/store/reducers/dashboard/insightsFavoritesReducer';

function* fetchDashboardActiveOrdersData({
    payload,
}: {
    payload: {
        mode: any;
        destinationType: any;
        dateFilterType: string;
        startDate: string;
        endDate: string;
        agent: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        client: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        sl: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        currentFilterValue?: string;
        aggregateKey?: string;
    };
}) {
    try {
        const { aggregateKey, currentFilterValue } = payload;
        delete payload.aggregateKey;
        delete payload.currentFilterValue;
        const res: AxiosResponse = yield call(dashboardActiveOrdersService, { payload });
        if (aggregateKey) {
            yield put({
                type: setFavDataResultToGroupObject,
                payload: { res, aggregateKey, currentFilterValue },
            });
        } else {
            yield put({ type: fetchDashboardActiveOrdersSuccess, payload: res });
        }
    } catch (error) {
        yield put({ type: fetchDashboardActiveOrdersError, payload: error });
    }
}
function* fetchDashboardActiveOrdersDataExpanded({
    payload,
}: {
    payload: {
        mode: string;
        destinationType: string;
        dateFilterType: string;
        startDate: string;
        endDate: string;
        agent: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        client: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        sl: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        profileName: string;
        loggedInUserName: string;
        currentFilterValue?: string;
        aggregateKey?: string;
    };
}) {
    try {
        const { aggregateKey, currentFilterValue } = payload;
        delete payload.aggregateKey;
        delete payload.currentFilterValue;
        const res: AxiosResponse = yield call(dashboardActiveOrdersServiceExpanded, { payload });
        if (aggregateKey) {
            yield put({
                type: setFavDataResultToGroupObject,
                payload: { res, aggregateKey, currentFilterValue },
            });
        } else {
            yield put({ type: fetchDashboardActiveOrdersSuccessExpanded, payload: res });
        }
    } catch (error) {
        yield put({ type: fetchDashboardActiveOrdersErrorExpanded, payload: error });
    }
}
function* fetchDashboardActiveBookingsData({
    payload,
}: {
    payload: {
        mode: string;
        destinationType: string;
        dateFilterType: string;
        startDate: string;
        endDate: string;
        agent: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        client: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        sl: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        profile: any;
        currentFilterValue?: string;
        aggregateKey?: string;
    };
}) {
    try {
        const { aggregateKey, currentFilterValue } = payload;
        delete payload.aggregateKey;
        delete payload.currentFilterValue;
        const res: AxiosResponse = yield call(dashboardActiveBookingsService, { payload });
        if (aggregateKey) {
            yield put({
                type: setFavDataResultToGroupObject,
                payload: { res, aggregateKey, currentFilterValue },
            });
        } else {
            yield put({ type: fetchDashboardActiveBookingsSuccess, payload: res });
        }
    } catch (error) {
        yield put({ type: fetchDashboardActiveBookingsError, payload: error });
    }
}

function* fetchDashboardActiveBookingsDataExpanded({
    payload,
}: {
    payload: {
        mode: string;
        destinationType: string;
        dateFilterType: string;
        startDate: string;
        endDate: string;
        agent: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        client: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        sl: Array<{
            orgCode: string;
            relationship: string;
            orgName: string;
            orgId: string;
            profilePK: string;
            userOrg: boolean;
        }>;
        profileName: string;
        loggedInUserName: string;
        currentFilterValue?: string;
        aggregateKey?: string;
    };
}) {
    try {
        const { aggregateKey, currentFilterValue } = payload;
        delete payload.aggregateKey;
        delete payload.currentFilterValue;
        const res: AxiosResponse = yield call(dashboardActiveBookingsServiceExpanded, { payload });
        if (aggregateKey) {
            yield put({
                type: setFavDataResultToGroupObject,
                payload: { res, aggregateKey, currentFilterValue },
            });
        } else {
            yield put({ type: fetchDashboardActiveBookingsSuccessExpanded, payload: res });
        }
    } catch (error) {
        yield put({ type: fetchDashboardActiveBookingsErrorExpanded, payload: error });
    }
}

function* quickLinksSaga() {
    yield takeEvery(fetchDashboardActiveOrders, fetchDashboardActiveOrdersData);
    yield takeEvery(fetchDashboardActiveOrdersExpanded, fetchDashboardActiveOrdersDataExpanded);
    yield takeEvery(fetchDashboardActiveBookings, fetchDashboardActiveBookingsData);
    yield takeEvery(fetchDashboardActiveBookingsExpanded, fetchDashboardActiveBookingsDataExpanded);
}

export default quickLinksSaga;
