import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import './settings.scss';
import { validateFractionInput } from 'src/utils/validateFractionInput';
import { error, Position, success } from 'src/utils/toast';
import UnsavedModal from 'src/components/common/UnsaveModal';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    fetchThresholdValue,
    setUpdateSuccess,
    setUpdateError,
    updateThresholdValue,
} from 'src/store/reducers/settings';
import { usePrompt } from 'src/hooks/usePrompt';
import ShowForPermissions from 'src/ShowForPermissions';
import { checkPermissionsWrap } from 'src/utils/checkPermission';

const validationSchema = Yup.object().shape({
    threshold: Yup.number().required('Threshold is required').max(100, 'Threshold value cannot exceed 100 %'),
});

const Index: React.FC = () => {
    const [showUnSaveModal, setShowUnSaveModal] = useState(false);
    const [callBackFn, setCallBackFn] = useState<any>();

    const { threshold, updateSuccess, updateError } = useSelector((state: RootState) => state.settings);
    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const dispatch = useDispatch();
    const { userName, profileType } = useUserProfile();

    useEffect(() => {
        dispatch(fetchThresholdValue({ userName }));
    }, []);

    useEffect(() => {
        if (updateSuccess) {
            success(`Settings has been updated successfully`, Position.TOP_RIGHT);
            dispatch({ type: setUpdateSuccess, payload: false });
        }
    }, [updateSuccess, dispatch]);

    const formik = useFormik({
        initialValues: { threshold },
        enableReinitialize: true,
        validateOnChange: true,
        validateOnBlur: true,
        validationSchema,
        onSubmit: (values) => {
            dispatch(updateThresholdValue({ userName, threshold: values.threshold }));
        },
    });

    const { dirty, errors, handleChange, handleSubmit, isValid, setFieldValue, values } = formik;
    useEffect(() => {
        if (updateError) {
            error(`Failed to update settings`, Position.TOP_RIGHT);

            dispatch({ type: setUpdateError, payload: false });
        }
    }, [updateError, dispatch]);
    usePrompt(
        'You have unsaved changes in this page. Are you sure to leave  without saving ?',
        dirty,
        setShowUnSaveModal,
        setCallBackFn
    );
    const handleDiscard = () => {
        setShowUnSaveModal(false);
    };
    const onClickDiscard = () => {
        if (dirty) {
            setFieldValue('threshold', threshold);
        }
    };
    const onConfirmDiscard = () => {
        setShowUnSaveModal(false);
        callBackFn.retry();
    };

    return (
        <>
            <div className="main-wrapper">
                <div className="settings-wrapper">
                    <h1 className="pageholder-heading">Settings</h1>
                    <div className="layout-wrapper">
                        <div className="layout-content">
                            <div className="content-name">LCL Configuration</div>
                            <div className="content-desc">
                                Enter the threshold value of CBM below which the system will initiate LCL workflow
                            </div>
                        </div>
                        <div className="layout-field">
                            <form id="settings-form" onSubmit={handleSubmit}>
                                <div className="layout-field-text-box">
                                    <div
                                        className="modal-grid-input-holder"
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        {/* "with-error-border" is the class for red-border */}
                                        <input
                                            placeholder=""
                                            id="threshold"
                                            name="threshold"
                                            type="text"
                                            className={`modal-grid-input input-text ${
                                                errors.threshold ? 'with-error-border' : ''
                                            }`}
                                            onChange={handleChange}
                                            disabled={
                                                !checkPermissionsWrap(permissions, 'edit', 'settings') ||
                                                profileType !== 'forwarder'
                                            }
                                            value={values.threshold}
                                            onKeyPress={(event) => {
                                                //Restrict the input of invalid fraction numbers
                                                if (
                                                    !validateFractionInput({
                                                        inputKey: event.key,
                                                        currentValue:
                                                            typeof values.threshold === 'string'
                                                                ? Number(values.threshold)
                                                                : values.threshold,
                                                    }) ||
                                                    String(values.threshold).length > 5
                                                ) {
                                                    event.preventDefault();
                                                }
                                            }}
                                        ></input>
                                        {errors.threshold && (
                                            <span className="inline-grid-input">
                                                <svg className="svg-icon error-icon">
                                                    <use xlinkHref="#errorIcon"></use>
                                                </svg>
                                            </span>
                                        )}
                                    </div>
                                    <div className="unit-holder">
                                        <span>% of TEU</span>
                                    </div>
                                </div>
                                {/* Controlling visibility of error text to avoid jumping of the elements */}
                                <div className={`error-text ${!errors.threshold ? 'hidden' : ''}`}>
                                    {formik.values.threshold ? 'Threshold must be a valid number' : errors.threshold}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <ShowForPermissions permission="edit" type="settings">
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        {dirty && (
                            <button
                                className="app-btn app-btn-secondary footer-btn"
                                title="Discard"
                                onClick={onClickDiscard}
                            >
                                <span className="button-text footer-button-text">Discard</span>
                            </button>
                        )}
                        {profileType === 'forwarder' && (
                            <button
                                className="app-btn app-btn-primary footer-btn"
                                title="Save Changes"
                                disabled={!dirty || !isValid || !(values.threshold !== threshold)}
                                type="submit"
                                form="settings-form"
                            >
                                <span className="button-text footer-button-text">Save Changes</span>
                            </button>
                        )}
                    </div>
                </div>
            </ShowForPermissions>
            {showUnSaveModal && (
                <UnsavedModal
                    description="You have unsaved changes in this page. Are you sure to leave  without saving ?"
                    handleDiscard={handleDiscard}
                    handleClose={() => setShowUnSaveModal(false)}
                    handleSubmit={onConfirmDiscard}
                    title="Unsaved changes"
                />
            )}
        </>
    );
};
export default Index;
