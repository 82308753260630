export const arrayCheck = (passedArray = [], array2: any, label: any) => {
    let returnVal = 0;
    passedArray.forEach((item: any) => {
        if (item[label].every((val: any, index: any) => val === array2[index])) {
            returnVal++;
        }
    });
    if (returnVal === 0) {
        return false;
    } else {
        return true;
    }
};
