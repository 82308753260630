import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { fetchHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import { fetchConfirmationGroup, rejectAssignToAgent } from 'src/store/reducers/purchaseOrder/containerModePoReducer';
import './container-group.scss';

const AssignToAgent = (props: any) => {
    const { id, group_id } = useParams();
    const [rejectionReason, setrejectionReason] = useState('');
    const dispatch = useDispatch();
    const { profileType, userName } = useUserProfile();

    const assignToAgent = () => {
        const payload = {
            id: group_id,
            payload: {
                reason: rejectionReason,
                userName: userName,
            },
        };
        dispatch(rejectAssignToAgent(payload));
        setTimeout(() => {
            dispatch(
                fetchHeader({
                    poId: id,
                    profile: profileType,
                    cgId: group_id,
                })
            );
            dispatch(fetchConfirmationGroup({ confirmationGroupId: group_id }));
            props.onClose(false);
        }, 1000);
    };

    useEffect(() => {
        if (props.reasonData) {
            setrejectionReason(props.reasonData);
        }
    }, [props.reasonData]);

    return (
        <div className="app-modal container-reject-modal">
            <div className="modal-content-holder medium-popup">
                <div className="modal-header">
                    <div className="title">Assign to Agent</div>
                    <button
                        className="app-btn modal-close-btn"
                        type="button"
                        onClick={() => {
                            setrejectionReason('');
                            props.onClose(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <label className="modal-input-label">Reason</label>
                    <div className="modal-input-field-holder">
                        <textarea
                            name="reason"
                            className="input-text"
                            placeholder="Enter the reason to reconsider"
                            maxLength={250}
                            onChange={(e) => setrejectionReason(e.target.value)}
                            defaultValue={rejectionReason}
                        ></textarea>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn footer-btn-container"
                        title="Back"
                        type="button"
                        onClick={() => props.onClose(false)}
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn footer-btn-container"
                        type="submit"
                        title="Assign to Agent"
                        onClick={() => assignToAgent()}
                    >
                        <span className="button-text footer-button-text">Assign to Agent</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default AssignToAgent;
