import React, { useEffect, useState } from 'react';
import '../../BookingProposal/BookingAddedGrid/booking-added-grid.scss';
import PoReview from './PurchaseOrderDetails';
// import CancelBookingModal from './CancelBookingModal';
//import ProductDetailModal from './../../PO/ProductDetails/ProductDetailsModal';
import { useSelector } from 'react-redux';
// import moment from 'moment';
// import LinkedBookingModal from '../BookingAddedGrid/LinkedBookingModal';
// import { selectSku } from '../../../store/reducers/purchaseOrder/productPoReducer';
// import { useAppSelector } from '../../../hooks';
// import { fetchAddedPoData, getSingleData } from '../../../store/reducers/aobookingProposal/addedPoReducer';
import { RootState } from '../../../store/reducers';
import ProductDetailModal from './ProductDetailsModal';
import ShowForPermissions from '../../../ShowForPermissions';
// import { useUserProfile } from '../../../hooks/useUserProfile';
/* eslint no-var: off */

interface BioProps {
    bpID?: any;
    setaddedgrid: any;
    setSelectedItemsAdded: any;
    selectedItemsAdded: any;
    addPoList: any;
    showConditionStatus: any;
    searchAddedTerm: string;
}
const Index: React.FC<BioProps> = ({
    // bpID,
    setaddedgrid,
    setSelectedItemsAdded,
    selectedItemsAdded,
    addPoList,
    showConditionStatus,
    searchAddedTerm,
}) => {
    // const sku = useAppSelector(selectSku);
    const { prouctsList } = useSelector((state: RootState) => state.addedPos);
    // const dispatch = useDispatch();
    // const [modalView, setModalView] = useState(true);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    const [_productsAll, setproductsAll] = useState([]);
    const [VendorCode, setVendorCode] = useState('');
    // const [poLine, setpoLine] = useState(0);
    var poLineNum = 0;
    var poStatus = true;

    const selectedIds = (boid: string, bsid: any, cgIds: any, index: any, type: boolean) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === index) {
                    var filterAr: any = [...selectedAr[i].subPolineIds, ...bsid];
                    flag = true;
                    filterAr = filterAr.filter(function (item: any, pos: any) {
                        return filterAr.indexOf(item) == pos;
                    });
                    selectedAr[i].subPolineIds = [...filterAr];
                }
            }
            if (!flag) {
                var newObj = {
                    id: boid,
                    isFullPo: false,
                    subPolineIds: [...bsid],
                    cgIds: [...cgIds],
                };
                const uniqueArray = newObj.cgIds.reduce((accumulator: any, currentValue: any) => {
                    if (!accumulator.includes(currentValue)) {
                        accumulator.push(currentValue);
                    }
                    return accumulator;
                }, []);
                newObj.cgIds = uniqueArray;
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boid) {
                    var delAr: any[] = [...selectedAr[i].subPolineIds];
                    for (var j = 0; j < delAr.length; j++) {
                        for (var k = 0; k < bsid.length; k++) {
                            if (delAr[j] === bsid[k]) {
                                delAr.splice(j, 1);
                                selectedAr[i].subPolineIds.splice(j, 1);
                                if (selectedAr[i].subPolineIds.length === 0) {
                                    selectedAr.splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
    };

    const selectAll = (type: boolean) => {
        var selectallar: any[] = [];
        var newObj = {};
        if (type) {
            addPoList?.forEach((element: any) => {
                element.bookingProposalSubPOLineList.forEach((element2: any) => {
                    var singleVal: any = [];
                    element2?.bookingProposalSubPOLineList?.forEach((x: any) => {
                        newObj = {
                            id: element.purchaseOrderId?.poId,
                            isFullPo: false,
                            subPolineIds: singleVal,
                            cgIds: [(element2 as any).cgId],
                        };
                        var val = (x as any).bsId;
                        singleVal.push(val);
                    });
                    selectallar.push(newObj);
                });
            });
            setSelectedItemsAdded(selectallar);
        } else {
            setSelectedItemsAdded([]);
        }
    };
    const getAllIds = (val: any) => {
        var newArray: any[] = [];
        val.forEach((element: any) => {
            newArray.push(element.bsId);
        });
        return newArray;
    };

    const checkSelected = (boid: any, val: any) => {
        var flag = false;
        selectedItemsAdded.forEach((element: any) => {
            if (boid === element.id) {
                element.subPolineIds.forEach((element2: any) => {
                    if (element2 === val) {
                        flag = true;
                    }
                });
            }
        });

        return flag;
    };
    const allSelected = () => {
        var totalAll = 0;
        var totalSelected = 0;
        var flag = false;
        addPoList?.forEach((element: any) => {
            element?.bookingProposalSubPOLineList?.forEach((element1: any) => {
                element1?.bookingProposalSubPOLineList?.forEach((x: any) => {
                    if (x.length !== 0) {
                        totalAll++;
                    }
                });
            });
        });
        selectedItemsAdded?.forEach((element: any) => {
            element.subPolineIds?.forEach((_element1: any) => {
                totalSelected++;
            });
        });
        if (totalAll === totalSelected && totalAll !== 0) {
            flag = true;
        }
        return flag;
    };
    const singleRowChecked = (id: any, length: any) => {
        var flag = false;
        selectedItemsAdded?.forEach((element: any) => {
            if (element.subPolineIds.length === length && element.subPolineIds.includes(id)) {
                flag = true;
            }
        });
        return flag;
    };
    const selectSingle = (boId: any, bsid: any, index: any, type: boolean) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boId) {
                    flag = true;
                    selectedAr[i].subPolineIds = [...selectedAr[i].subPolineIds, bsid];
                }
            }
            if (!flag) {
                var newObj = {
                    id: boId,
                    isFullPo: false,
                    subPolineIds: [bsid],
                };
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boId) {
                    var delAr: any[] = [...selectedAr[i].subPolineIds];
                    for (var j = 0; j < delAr.length; j++) {
                        if (delAr[j] === bsid) {
                            delAr.splice(j, 1);
                            selectedAr[i].subPolineIds.splice(j, 1);
                            if (selectedAr[i].subPolineIds.length === 0) {
                                selectedAr.splice(i, 1);
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
    };
    /* eslint-disable no-undefined */

    useEffect(() => {
        setproductsAll(prouctsList);
    }, [prouctsList]);

    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let isAgent = false;
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
        }
    }

    return (
        <div className="grid-section grid-added-booking product-details-wrapper">
            <div className="grid-holder">
                <div className="grid-container">
                    {addPoList?.length === 0 &&
                        (searchAddedTerm?.length > 0 ? (
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No matching records found</p>
                            </div>
                        ) : (
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No AO lines added yet</p>
                                {isAgent && (
                                    <a
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => setaddedgrid(false)}
                                        className="add-content-link"
                                    >
                                        Show Available AOs
                                    </a>
                                )}
                            </div>
                        ))}
                    <div className="grid-scroll-content">
                        <table className="grid-table">
                            <thead>
                                <tr className="grid-sticky-row">
                                    <th className="grid-cell-header col-number grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data">
                                            <span className="header-title">AO NUMBER</span>
                                        </div>
                                    </th>
                                    {showConditionStatus && (
                                        <ShowForPermissions permission="edit" type="bp">
                                            <th className="grid-cell-header col-checkbox sticky-left-aligned  grid-sticky-column">
                                                <div className="grid-cell-data centre-align">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox-input"
                                                            checked={allSelected()}
                                                            onChange={(e) => selectAll(e.currentTarget.checked)}
                                                        />
                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </th>
                                        </ShowForPermissions>
                                    )}
                                    <th className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CLIENT</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-destination">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESTINATION</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">BLP</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">SKU</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESCRIPTION</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-allocation">
                                        <div className="grid-cell-data">
                                            <span className="header-title">
                                                ALLOCATION <br />
                                                QTY
                                            </span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-customer">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CUSTOMER ORDER REFERENCE NO.</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-customer">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CUSTOMER ORDER LINE NO.</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-origin">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ORIGIN</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-destination2">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESTINATION ADDRESS</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-cbm">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CBM</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-weight">
                                        <div className="grid-cell-data">
                                            <span className="header-title">WEIGHT (KG)</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            {addPoList?.map((res: any, _i: any) => (
                                <tbody className="grouped-splited-row">
                                    {res?.bookingProposalSubPOLineList?.map((val0: any, _i02: any) => {
                                        return (
                                            <>
                                                {val0?.bookingProposalSubPOLineList?.map((val: any, i2: any) => {
                                                    if (poLineNum === val?.poLineNumber && i2 !== 0) {
                                                        poStatus = false;
                                                    } else {
                                                        poStatus = true;
                                                    }
                                                    poLineNum = val?.poLineNumber;
                                                    return (
                                                        <tr>
                                                            <td className="grid-cell col-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                                <div className="grid-cell-data">
                                                                    <div className="checkbox-data-wrapper">
                                                                        <ShowForPermissions permission="edit" type="bp">
                                                                            {showConditionStatus && (
                                                                                <label className="app-check-wrapper">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        checked={singleRowChecked(
                                                                                            val?.bsId,
                                                                                            val0
                                                                                                ?.bookingProposalSubPOLineList
                                                                                                .length
                                                                                        )}
                                                                                        className="checkbox-input"
                                                                                        onChange={(e) => {
                                                                                            selectedIds(
                                                                                                res?.purchaseOrderId
                                                                                                    ?.poId,
                                                                                                getAllIds(
                                                                                                    val0?.bookingProposalSubPOLineList
                                                                                                ),
                                                                                                val0.bookingProposalSubPOLineList.map(
                                                                                                    (item: any) =>
                                                                                                        item.cgId
                                                                                                ),
                                                                                                val0?.cgId,
                                                                                                e.currentTarget.checked
                                                                                            );
                                                                                        }}
                                                                                    />
                                                                                    {i2 === 0 && (
                                                                                        <div className="checkmark">
                                                                                            <svg className="svg-icon tick-icon">
                                                                                                <use xlinkHref="#tickIcon">
                                                                                                    <title>
                                                                                                        check mark
                                                                                                    </title>
                                                                                                </use>
                                                                                            </svg>
                                                                                        </div>
                                                                                    )}
                                                                                </label>
                                                                            )}
                                                                        </ShowForPermissions>
                                                                        {/*<div className="grid-cell-data">{res?.poNumber}</div>*/}
                                                                        {i2 === 0 && (
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                //className="booking-proposal-link"
                                                                                style={{
                                                                                    paddingLeft: '0',
                                                                                    marginLeft: '28px',
                                                                                    textDecoration: 'none',
                                                                                    color: 'unset',
                                                                                }}
                                                                            >
                                                                                {res?.poNumber}
                                                                            </a>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            {showConditionStatus && (
                                                                <ShowForPermissions permission="edit" type="bp">
                                                                    <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                                                        <div className="grid-cell-data centre-align">
                                                                            <label className="app-check-wrapper">
                                                                                <input
                                                                                    checked={checkSelected(
                                                                                        res?.purchaseOrderId?.poId,
                                                                                        val?.bsId
                                                                                    )}
                                                                                    type="checkbox"
                                                                                    className="checkbox-input"
                                                                                    onChange={(e) =>
                                                                                        selectSingle(
                                                                                            res?.purchaseOrderId?.poId,
                                                                                            val?.bsId,
                                                                                            i2,
                                                                                            e.currentTarget.checked
                                                                                        )
                                                                                    }
                                                                                />
                                                                                <div className="checkmark">
                                                                                    <svg className="svg-icon tick-icon">
                                                                                        <use xlinkHref="#tickIcon">
                                                                                            <title>check mark</title>
                                                                                        </use>
                                                                                    </svg>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                    </td>
                                                                </ShowForPermissions>
                                                            )}

                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {poStatus && res?.purchaseOrderId?.clientName}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {poStatus &&
                                                                        res?.purchaseOrderId?.destinationPortName}
                                                                    {poStatus &&
                                                                        '(' +
                                                                            res?.purchaseOrderId?.destinationPort +
                                                                            ')'}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {poStatus && res?.purchaseOrderId?.blpAgentName}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    <a
                                                                        onClick={() => {
                                                                            setVendorCode(
                                                                                res?.purchaseOrderId?.clientCode
                                                                            );
                                                                            setModalViewproduct({
                                                                                status: true,
                                                                                value: res?.purchaseOrderId?.clientCode,
                                                                                id: val?.skuId,
                                                                            });
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {val?.sku}
                                                                    </a>
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">{val?.description}</div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {res?.purchaseOrderId?.purchaseOrderProducts[0]
                                                                        ?.allocatedQty
                                                                        ? res?.purchaseOrderId?.purchaseOrderProducts[0]
                                                                              ?.allocatedQty
                                                                        : '-'}
                                                                </div>
                                                            </td>

                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {
                                                                        res?.purchaseOrderId?.purchaseOrderProducts[0]
                                                                            ?.customerOrderReferenceNo
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {
                                                                        res?.purchaseOrderId?.purchaseOrderProducts[0]
                                                                            ?.customerOrderLineNo
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {poStatus && res?.purchaseOrderId?.originPortName}
                                                                    {poStatus &&
                                                                        '(' + res?.purchaseOrderId?.originPort + ')'}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {res?.purchaseOrderId?.destinationAddress}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">{val?.cbm}</div>
                                                            </td>
                                                            <td className="grid-cell col-po-cbm">
                                                                <div className="grid-cell-data">{val?.weight}</div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                            </>
                                        );
                                    })}
                                </tbody>
                            ))}
                        </table>
                    </div>
                    {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}{' '}
                    {ModalViewproduct.status && (
                        <ProductDetailModal
                            display={ModalViewproduct}
                            displayFunction={setModalViewproduct}
                            id={VendorCode}
                            data={prouctsList}
                        />
                    )}
                </div>
                {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}{' '}
            </div>
        </div>
    );
};
export default Index;
