import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import HeaderTabs from '../HeaderTabs';
import ContractModal from './ContractModal';
// import './style.css';
import '../../../../assets/scss/components/_grid.scss';
import '../../../../assets/scss/components/_textfield.scss';
import '../../../../assets/scss/components/_buttons.scss';
import '../../../../assets/scss/components/_dropdown.scss';
import '../../../../assets/scss/components/_toast.scss';
import '../../../../assets/scss/components/_status.scss';
import '../../../../assets/scss/components/_check-radio.scss';
import './/contract-details.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    ClientDocumentsDtoListContract,
    fetchContractList,
    selectContractColumnStatus,
    selectContractCurrentPage,
    fetchAuthorDetails,
    // selectContractIsError,
    // selectContractLoading,
    selectContractUser,
    selectContractotalNoOfItems,
    selectContractotalNoOfPages,
    selectContractLoading,
    clearContract,
    searchContract,
} from '../../../../store/reducers/contractReducer';
import { useParams } from 'react-router-dom';
import { contractTableHeaders, paginationLimit } from '../../../../utils/constants';
import moment from 'moment';
// import DropDown from '../../../../components/common/DropDown';
// import DropDown2 from '../../../../components/common/DropDown/contactDrop';
import { Link } from 'react-router-dom';
import BackButton from '../../../../components/common/BackButton';
import CancelModal from './cancelModal';
import { useMeasure } from 'react-use';
import Pagination from '../../../../components/Pagination/Pagination';
import { downloadFile, getSingleContractData } from '../../../../services/apis/contractApi';
import ShowForPermissions from '../../../../ShowForPermissions';
import SpecialFilter from 'src/components/common/SpecialFilter';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useSelector } from 'react-redux';
import { Position, error } from 'src/utils/toast';

const Index: React.FC = () => {
    const { id } = useParams();
    const [modalView, setModalView] = useState(false);
    const [contractData, setContractData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isFromView, setIsFromView] = useState(false);
    const [mode, setMode] = useState('add');
    const dispatch = useAppDispatch();
    const contract = useAppSelector(selectContractUser);
    // const isError = useAppSelector(selectContractIsError);
    const isLoading = useAppSelector(selectContractLoading);
    const [selectedHeader, setSelectedHeader] = useState(contractTableHeaders.Status);
    const currentPage = useAppSelector(selectContractCurrentPage);
    const columnStatus = useAppSelector(selectContractColumnStatus);
    // const perPageCount = useAppSelector(selectContractPerPageCount);
    const totalNoOfPages = useAppSelector(selectContractotalNoOfPages);
    const totalNoOfItems = useAppSelector(selectContractotalNoOfItems);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [toggleDropdown2, setToggleDropdown2] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [selectedFilter2, setselectedFilter2] = useState<any | null>(null);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [_toastStat, settoastStat] = useState(false);
    const [_toastMsg, settoastMsg] = useState('Contract added');
    const [perPageCount, setPerPageCount] = useState(10);
    const [displayCancel, setDisplayCancel] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const [ref, { width }] = useMeasure<HTMLTableCellElement>();
    const [search, setSearch] = useState('');
    const firstUpdate = useRef(true);

    const payload = {
        id,
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        statusFilter: selectedFilter,
        filterType: selectedFilter2,
        columnOrder: !currentOrder,
        totalNoOfPages: totalNoOfPages,
        totalNoOfItems: totalNoOfItems,
    };

    useEffect(() => {
        // dispatch(fetchContractList({ ...payload, currentPage: 0, firstLoad: true }));
        dispatch(fetchAuthorDetails([]));
    }, [dispatch]);

    useEffect(() => {
        dispatch(clearContract([]));
    }, []);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalNoOfPages; i++) {
            pageNumbers.push(i);
        }

        setPagenumbers(pageNumbers);
    }, [totalNoOfPages, dispatch]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        setTimeout(() => {
            setToggleDropdown(true);
        }, 100);
        setselectedFilter(data);
        if (search?.trim()?.length > 1) {
            dispatch(
                searchContract({
                    ...payload,
                    keyword: search,
                    currentPage: 0,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: data,
                    columnOrder: !currentOrder,
                    filterType: selectedFilter2,
                })
            );
        } else {
            dispatch(
                fetchContractList({
                    ...payload,
                    currentPage: 0,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: data,
                    columnOrder: !currentOrder,
                    filterType: selectedFilter2,
                })
            );
        }
    };
    const closeToggle2 = (data: string) => {
        setToggleDropdown2(false);
        setTimeout(() => {
            setToggleDropdown2(true);
        }, 100);
        setselectedFilter2(data);
        if (search?.trim()?.length > 1) {
            dispatch(
                searchContract({
                    ...payload,
                    keyword: search,
                    currentPage: 0,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: selectedFilter,
                    columnOrder: !currentOrder,
                    filterType: data,
                })
            );
        } else {
            dispatch(
                fetchContractList({
                    ...payload,
                    currentPage: 0,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: selectedFilter,
                    columnOrder: !currentOrder,
                    filterType: data,
                })
            );
        }
    };

    const [currentPageNumber, setCurrentPageNumber] = useState<null | number>(null);
    useEffect(() => {
        if (currentPageNumber) {
            dispatch(
                fetchContractList({
                    ...payload,
                    currentPage: currentPageNumber - 1,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    statusFilter: selectedFilter,
                    columnOrder: !currentOrder,
                    filterType: selectedFilter2,
                })
            );
        }
    }, [currentPageNumber]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (search?.trim()?.length > 2) {
            dispatch(searchContract({ ...payload, keyword: search }));
        } else if (search?.trim()?.length === 0) {
            // dispatch(fetchContractList({ ...payload, currentPage: 0, firstLoad: true }));
            dispatch(fetchAuthorDetails([]));
        }
    }, [search]);

    const { roleName } = useUserProfile();
    const { permissions } = useSelector((state: any) => state.permissionData) as any;
    const checkPermission = (permission: any, type: any) => {
        let flag = true;
        if (roleName?.toLowerCase() === 'it admin') {
            return false;
        }
        for (let i = 0; i < permissions?.length; i++) {
            if (
                (permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                    permissions[i].access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes(type.toLowerCase())) ||
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('alladmin')
            ) {
                flag = false;
            }
        }
        return flag;
    };

    //@ts-ignore
    window.attachmentAction = (type: string, id: string, fileName: string) => {
        if (!checkPermission('download', 'commercial')) {
            downloadFile(id, fileName);
        } else {
            error('Download permission is not available', Position.TOP_RIGHT);
        }
    };
    //@ts-ignore
    window.viewContract = async (type: string, CD_PK: string) => {
        const res: any = await getSingleContractData({ CD_CL: id, CD_PK });
        setContractData(res);
        setIsEdit(true);
        setModalView(true);
    };

    return (
        <>
            {/* {toastStat && (
                <div
                    className="toast-holder"
                    onClick={() => {
                        settoastStat(false);
                    }}
                >
                    <svg className="svg-icon checkmark-icon">
                        <use xlinkHref="#checkmarkIcon">
                            <title>Vendors linked</title>
                        </use>
                    </svg>
                    <div className="toast-message">{toastMsg}</div>
                    <svg
                        className="svg-icon close-icon"
                        onClick={() => {
                            settoastStat(false);
                        }}
                    >
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </div>
            )} */}
            <div
                onClick={() => {
                    setPageCountDisplay(false);
                    setToggleDropdown(false);
                    setToggleDropdown2(false);
                }}
                className="main-wrapper container"
            >
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton />

                    <HeaderTabs />
                </div>
                <div className="grid-section contract-grid loader-container grid-p-0">
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div className="grid-holder">
                        <div className="grid-header grid-mr-20">
                            <div className="search-entry-holder">
                                {/* <input
                                    placeholder="Search Contract Details"
                                    type="text"
                                    className="search-input input-text"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                ></input> */}
                                {/* <div
                                    className={`search-toolip ${
                                        search.length > 0 && search.length < 3 ? 'tooltip-show' : ''
                                    }`}
                                >
                                    Enter atleast 3 characters to search
                                </div>
                                <svg className="svg-icon search-icon">
                                    <use xlinkHref="#searchIcon"></use>
                                </svg>
                                <button
                                    className="app-btn text-close-btn"
                                    onClick={() => {
                                        if (search?.trim()?.length) {
                                            setSearch('');
                                        }
                                    }}
                                >
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button> */}
                            </div>
                            <div className="item-count-status-holder">
                                {/* <div
                                    className="grid-item-count-status status-ui success-status"
                                    style={{ maxWidth: '250px' }}
                                >
                                    <span className="count">{contract?.info?.totalActiveDocuments}</span>
                                    <span className="status-text">
                                        Active{' '}
                                        {contract?.info?.totalActiveDocuments &&
                                        contract?.info?.totalActiveDocuments === 1
                                            ? 'Contract / Quotation'
                                            : 'Contracts / Quotations'}
                                    </span>
                                </div> */}
                            </div>
                            <ShowForPermissions permission="edit" type="client">
                                <ShowForPermissions permission="upload" type="commercial">
                                    <button
                                        onClick={() => {
                                            if (buttonClicked) {
                                                setModalView(true);
                                            } else {
                                                setTimeout(() => {
                                                    setModalView(true);
                                                }, 700);
                                                setButtonClicked(true);
                                            }
                                            setIsEdit(false);
                                            setMode('add');
                                            setContractData({});
                                        }}
                                        className="app-btn app-btn-secondary icon-button grid-top-btn"
                                        title="Add Contract"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Add Contract</span>
                                    </button>
                                </ShowForPermissions>
                            </ShowForPermissions>
                        </div>
                        <ContractModal
                            display={modalView}
                            displayFunction={setModalView}
                            isEdit={isEdit}
                            setIsEdit={setIsEdit}
                            data={contractData}
                            setData={setContractData}
                            isFromView={isFromView}
                            setIsFromView={setIsFromView}
                            mode={mode}
                            setMode={setMode}
                            settoastMsg={settoastMsg}
                            settoastStat={settoastStat}
                            selectedHeader={selectedHeader}
                            selectedFilter={selectedFilter}
                            perPageCount={perPageCount}
                            setDisplayCancel={setDisplayCancel}
                        />
                        <CancelModal
                            displayFunction={setModalView}
                            mode={mode}
                            displayCancel={displayCancel}
                            setDisplayCancel={setDisplayCancel}
                        />
                        <LayoutWithColumns style={'tab'} content={'client-contract'} />
                        {/* <div
                            className="grid-container"
                            onClick={() => setToggleDropdown2(false)}
                            onScroll={() => {
                                setToggleDropdown(false);
                                setToggleDropdown2(false);
                            }}
                        >
                            {!contract?.info?.clientDocumentsDtoList?.length && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    {search?.trim()?.length > 1 ? (
                                        <p className="no-content-message">No records found</p>
                                    ) : (
                                        <>
                                            <p className="no-content-message">
                                                {selectedFilter === null && selectedFilter2 === null
                                                    ? 'No Contract added to this profile'
                                                    : 'Filter does not return any results'}
                                            </p>
                                            <ShowForPermissions permission="edit" type="client">
                                                {!selectedFilter && !selectedFilter2 && (
                                                    <a
                                                        className="add-content-link"
                                                        href="javascript:void(0)"
                                                        onClick={() => {
                                                            if (buttonClicked) {
                                                                setModalView(true);
                                                            } else {
                                                                setTimeout(() => {
                                                                    setModalView(true);
                                                                }, 700);
                                                                setButtonClicked(true);
                                                            }
                                                            setIsEdit(false);
                                                            setMode('add');
                                                        }}
                                                    >
                                                        Add Contract
                                                    </a>
                                                )}
                                            </ShowForPermissions>
                                        </>
                                    )}
                                </div>
                            )}
                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <thead>
                                        <tr className=" grid-sticky-row">
                                            <th
                                                ref={ref}
                                                className="grid-cell-header grid-sticky-column sticky-left-aligned col-name"
                                            >
                                                <div className="grid-cell-data">
                                                    <span className="header-title">Name</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.Name);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Name,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Name,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.Name
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th
                                                style={{ left: width + 1 }}
                                                className="grid-cell-header grid-sticky-column sticky-left-aligned col-version"
                                            >
                                                <div className="grid-cell-data">
                                                    <span className="header-title">VERSION</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.Version);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Version,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Version,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.Version
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th style={{ left: width + 1 }} className="grid-cell-header col-type">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">TYPE</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.Type);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Type,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Type,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.Type
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            toggleDropdown2 || selectedFilter2 !== null
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                setToggleDropdown2(!toggleDropdown2);
                                                                e.stopPropagation();
                                                            }}
                                                            className="app-btn filter-menu-button"
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                        {toggleDropdown2 && (
                                                            <SpecialFilter
                                                                closeToggle={closeToggle2}
                                                                currentFilter={selectedFilter2}
                                                                dataList={
                                                                    contract?.info?.distinctColumnValues?.cd_doctype
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-author">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">AUTHOR</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.Author);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Author,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Author,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.Author
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-signatory">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">Forwarder Signatory</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.ForwarderSignatory);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus:
                                                                            contractTableHeaders.ForwarderSignatory,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus:
                                                                            contractTableHeaders.ForwarderSignatory,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.ForwarderSignatory
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-client">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">Client signatory</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.ClientSignatory);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus:
                                                                            contractTableHeaders.ClientSignatory,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus:
                                                                            contractTableHeaders.ClientSignatory,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.ClientSignatory
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-renewal">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">RENEWAL</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.Renewal);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Renewal,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Renewal,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.Renewal
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-status">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">STATUS</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.Status);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Status,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.Status,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.Status
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            toggleDropdown || selectedFilter !== null
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                setToggleDropdown(!toggleDropdown);
                                                                e.stopPropagation();
                                                            }}
                                                            className="app-btn filter-menu-button"
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                        {toggleDropdown && (
                                                            <SpecialFilter
                                                                closeToggle={closeToggle}
                                                                currentFilter={selectedFilter}
                                                                dataList={
                                                                    (contract as any)?.info?.distinctColumnValues[
                                                                        'cd_status'
                                                                    ]
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-start-date">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">START DATE</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.StartDate);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.StartDate,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.StartDate,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.StartDate
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-end-date">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">END DATE</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(contractTableHeaders.EndDate);
                                                            if (search?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContract({
                                                                        ...payload,
                                                                        keyword: search,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.EndDate,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        columnStatus: contractTableHeaders.EndDate,
                                                                        columnOrder: currentOrder,
                                                                        statusFilter: selectedFilter,
                                                                        filterType: selectedFilter2,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === contractTableHeaders.EndDate
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>

                                            <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-action">
                                                <div className="grid-cell-data header-title">ACTIONS</div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {contract?.info?.clientDocumentsDtoList.map(
                                            (detail: ClientDocumentsDtoListContract, index: number) => (
                                                <tr key={index} className="has-child row-expanded">
                                                    <td
                                                        style={{ wordBreak: 'break-all' }}
                                                        className="grid-cell grid-sticky-column sticky-left-aligned no-border"
                                                    >
                                                        <div className="grid-cell-data">{detail.cd_docname}</div>
                                                    </td>
                                                    <td
                                                        style={{ left: width + 1 }}
                                                        className="grid-cell grid-sticky-column sticky-left-aligned"
                                                    >
                                                        <div className="grid-cell-data">{detail.cd_version}</div>
                                                    </td>
                                                    <td
                                                        style={{ left: width + 1, textTransform: 'capitalize' }}
                                                        className="grid-cell"
                                                    >
                                                        <div className="grid-cell-data">
                                                            {detail.cd_doctype.toLowerCase()}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{detail.cd_author}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {(detail as any).cd_forwardersignatory}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {detail.cd_clientsignatory}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{detail.cd_renewal}</div>
                                                    </td>
                                                    <td className="grid-cell ">
                                                        {detail.cd_status === 'Expired' ? (
                                                            <div className="grid-cell-data status-ui warning-status">
                                                                Expired
                                                            </div>
                                                        ) : (
                                                            <div className="grid-cell-data status-ui success-status">
                                                                Active
                                                            </div>
                                                        )}
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {moment(detail.cd_startdate).format('DD-MM-YYYY')}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {moment(detail.cd_enddate).format('DD-MM-YYYY')}
                                                        </div>
                                                    </td>

                                                    <td className="grid-cell centre-align grid-sticky-column sticky-right-aligned">
                                                        <div className="grid-cell-data ">
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="Download File"
                                                                onClick={() => {
                                                                    if (detail.fileName) {
                                                                        downloadFile(detail.cd_PK, detail.fileName);
                                                                    }
                                                                }}
                                                            >
                                                                <svg className="svg-icon eye-icon ">
                                                                    <use xlinkHref="#downloadIcon"></use>
                                                                    <title>Download</title>
                                                                </svg>
                                                            </button>
                                                            <button
                                                                onClick={() => {
                                                                    setIsEdit(true);
                                                                    setModalView(true);
                                                                    setContractData(detail);
                                                                }}
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="View Details"
                                                            >
                                                                <svg className="svg-icon eye-icon ">
                                                                    <use xlinkHref="#eyeIcon"></use>
                                                                    <title>View</title>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {totalNoOfItems > 0 ? (
                            <div className="grid-footer ">
                                <div className="grid-footer-left">
                                    <div className="footer-text">Showing</div>
                                    <div className="row-per-page">
                                        <div
                                            className={
                                                'dropdown-wrap pagination-dropdown menu-up ' +
                                                (PageCount ? 'dropdown-open' : '')
                                            }
                                        >
                                            <button
                                                className="menu-btn app-btn"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPageCountDisplay(!PageCount);
                                                }}
                                            >
                                                <span className="btn-text">
                                                    {' '}
                                                    {contract?.info?.clientDocumentsDtoList &&
                                                    perPageCount > contract?.info?.clientDocumentsDtoList.length
                                                        ? contract?.info?.clientDocumentsDtoList.length
                                                        : perPageCount}
                                                </span>
                                                <span className="dropdown-arrow">
                                                    <svg className="svg-icon arrow-icon">
                                                        <use xlinkHref="#downArrow">
                                                            <title>dropdown</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                            </button>
                                            {PageCount && (
                                                <div className="dropdown-menu">
                                                    {paginationLimit.map((res: any, index: number) => (
                                                        <a
                                                            key={index}
                                                            onClick={(e) => {
                                                                setPageCountDisplay(false);
                                                                e.stopPropagation();
                                                                setPerPageCount(res);
                                                                if (search?.trim()?.length > 1) {
                                                                    dispatch(
                                                                        searchContract({
                                                                            ...payload,
                                                                            keyword: search,
                                                                            currentPage: 0,
                                                                            perPageCount: res,
                                                                            columnStatus: selectedHeader,
                                                                            statusFilter: selectedFilter,
                                                                            columnOrder: !currentOrder,
                                                                        })
                                                                    );
                                                                } else {
                                                                    dispatch(
                                                                        fetchContractList({
                                                                            ...payload,
                                                                            currentPage: 0,
                                                                            perPageCount: res,
                                                                            columnStatus: selectedHeader,
                                                                            statusFilter: selectedFilter,
                                                                            columnOrder: !currentOrder,
                                                                        })
                                                                    );
                                                                }
                                                            }}
                                                            className="menu-item app-btn"
                                                        >
                                                            {res}
                                                        </a>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="footer-text">
                                        <span>of</span>
                                        <span className="grid-total-row-count">
                                            {totalNoOfItems ? totalNoOfItems : 0}
                                        </span>
                                        <span>{totalNoOfItems > 1 ? 'entries' : 'entry'}</span>
                                    </div>
                                </div>
                                <div className="grid-footer-right">
                                    <div className="grid-pagination">
                                        <Pagination
                                            currentPage={currentPage + 1}
                                            totalCount={totalNoOfItems}
                                            pageSize={perPageCount}
                                            onPageChange={(page: number) => setCurrentPageNumber(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null} */}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                        to={`/profile/clients/${id}/sop_details`}
                    >
                        {/* <button className="app-btn app-btn-secondary footer-btn" title="Previous"> */}
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <Link
                        className="app-btn app-btn-primary footer-btn"
                        title="Next"
                        to={`/profile/clients/${id}/vendor_details`}
                    >
                        {/* <button className="app-btn app-btn-primary footer-btn" title="Next"> */}
                        <span className="button-text footer-button-text">Next</span>
                        {/* </button> */}
                    </Link>
                </div>
                {/* toast message */}
                {/* <div className="toast-holder">
                    <svg className="svg-icon checkmark-icon">
                        <use xlinkHref="#checkmarkIcon">
                            <title>updated</title>
                        </use>
                    </svg>
                    <div className="toast-message">Ward circles ,87654.98 has been updated</div>
                    <svg className="svg-icon close-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </div> */}
            </div>
        </>
    );
};

export default Index;
