import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery, takeLatest, debounce } from 'redux-saga/effects';

// Import all api's
import {
    unlinkVendor,
    linkVendorApi,
    vendorData,
    getListVendor,
    getVendorData,
    searchClientVendors,
    searchClientVendors2,
} from '../../services/apis/vendorApi';
import {
    actionVendorsData,
    saveFetchedData,
    isLoadingfn,
    onUnlinking,
    linkingToast,
    searchClientVendorsData,
    searchClientVendorsData2,
} from '../reducers/vendorReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingfn, payload: true });
        const vendors: AxiosResponse<vendorData[]> = yield call(getListVendor, payload);
        yield put({ type: saveFetchedData, payload: vendors });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}

function* getActiveVendors({ payload }: { payload: any; type: any }) {
    const vendors: AxiosResponse<vendorData[]> = yield call(getVendorData, payload);
    //payload = vendors;
    yield put({ type: actionVendorsData, payload: vendors });
}

function* unlinkVendorval({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        const res: AxiosResponse<vendorData[]> = yield call(unlinkVendor, payload);
        if ((res as any).success) {
            yield put({ type: onUnlinking, payload: { status: true, message: res } });
        } else {
            yield put({ type: onUnlinking, payload: { status: false, message: res } });
        }
        const vendors: AxiosResponse<vendorData[]> = yield call(getListVendor, payload);
        yield put({ type: saveFetchedData, payload: vendors });
    } catch (err) {
        yield put({ type: isLoadingfn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingfn, payload: false });
    }
    //payload['headerTab'] = header;
    //yield put({ type: fetchHeaderData, payload: payload });
}
function* linkVendorVal({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        yield call(linkVendorApi, payload);
        yield put({ type: linkingToast, payload: true });
        const vendors: AxiosResponse<vendorData[]> = yield call(getListVendor, payload);
        yield put({ type: saveFetchedData, payload: vendors });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingfn, payload: false });
    }
}

function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        const vendors: AxiosResponse<vendorData> = yield call(searchClientVendors, payload);
        yield put({ type: searchClientVendorsData, payload: vendors });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}
function* getSearchData2({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        const vendors: AxiosResponse<vendorData> = yield call(searchClientVendors2, payload);
        yield put({ type: searchClientVendorsData2, payload: vendors });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}

function* vendorSaga() {
    yield takeEvery('vendors/fetchData', getListData);
    yield takeLatest('vendors/unlinkVendor', unlinkVendorval);
    yield takeLatest('vendors/linkingVendors', linkVendorVal);
    yield debounce(1000, 'vendors/activeVendors', getActiveVendors);
    yield takeLatest('vendors/searchClientVendorsData2', getSearchData2);
    yield takeLatest('vendors/searchClientVendorsData', getSearchData);
}

// Export the saga (data-saga)
export default vendorSaga;
