/* eslint-disable no-undefined */
import React, { useCallback, useEffect, useState } from 'react';
import './container-details.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    BpContainerDetail,
    updateBasicDetail,
    selectContainerAllocationIsError,
    selectContainerAllocationSuccess,
    selectContainerAllocationError,
    fetchContainerDetail,
    clearFailure,
    selectContainerType,
    selectDeliveryMode,
    selectContainerMode,
    selectPacking,
    fetchContainerOnlyBasicDetail,
    PackingList,
    updateDate,
    fetchDeliveryMode,
    fetchContainerMode,
    fetchContainerType,
    selectContainerDateSuccess,
    selectContainerDateError,
    fetchPacking,
} from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { error, Position, success } from '../../../../utils/toast';
// import { useNavigate } from 'react-router-dom';
import DatePicker from '../../../../components/common/DatePicker';
import moment from 'moment';
import { TimePicker as TimePickerPack } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { decimalNumberValidation } from 'src/utils';
import { validateFractionInput } from 'src/utils/validateFractionInput';
import { fetchDeliveryAndContainerModes } from 'src/store/reducers/bookingProposal/additionalDetailsNacReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { resetBpToBookingProposal } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { fetchBpHeaderData } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    containerData: BpContainerDetail;
    bpId: any;
    allocationId: any;
    containerDetailsId: any;
    setValidation?: any;
    validation?: any;
    setHasUpdateCompleted: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    hasUpdateCompleted: boolean;
}

interface BasicDetails {
    containerType: string;
    deliveryMode: string;
    containerMode: string;
    containerNumber: string;
    packing: string[];
    vgm: string;
    eir: string;
    sealNumber: string;
    collectionDate: string;
    dropOffDate: string;
    pickUpDate: string;
    gateInDate: string;
    gateInTime: string;
}

const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    containerData,
    bpId,
    allocationId,
    containerDetailsId,
    setCancelModalView,
    setValidation,
    setHasUpdateCompleted,
    hasUpdateCompleted,
    // validation,
}) => {
    //const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const { id } = useParams();
    const { profileType } = useUserProfile();
    const [containerDropDownOpen, setContainerDropDownOpen] = useState<boolean>(false);
    const [deliveryDropDownOpen, setDeliveryDropDownOpen] = useState<boolean>(false);
    const [containerModeDropDownOpen, setContainerModeDropDownOpen] = useState<boolean>(false);
    const [packingDropDownOpen, setPackingDropDownOpen] = useState<boolean>(false);
    const [newFormData, setNewFormData] = useState({} as any);
    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectContainerAllocationIsError);
    const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    const containerDateSuccess = useAppSelector(selectContainerDateSuccess);
    const ContainerDateError = useAppSelector(selectContainerDateError);
    const packing = useAppSelector(selectPacking);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { containerTypes, containerModes, deliveryModes } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    const [_containerModesModified, setContainerModesModified] = useState<any[]>([]);
    const [deliveryModesModified, setDeliveryModesModified] = useState<any[]>([]);
    const [containerTypesModified, setContainerTypesModified] = useState<any[]>([]);

    const getContainerId = (): string => {
        let typeId = '';
        if (containerTypes) {
            containerTypes.forEach((c: any) => {
                if (`${c.description} (${c.code})` === formik.values?.containerType) {
                    typeId = c.containerTypeId;
                }
            });
        }
        return typeId;
    };

    // const getDeliveryModeId = (): string => {
    //     let modeId = '';
    //     if (deliveryModes) {
    //         deliveryModes.forEach((c: any) => {
    //             if (c.deliveryMode === containerData?.deliveryMode) modeId = c.deliveryModeId;
    //         });
    //     }
    //     return modeId;
    // };

    const initialValues: BasicDetails = {
        containerType: containerData?.containerType || '',
        deliveryMode: containerData?.deliveryMode || '',
        containerMode: 'BCN',
        containerNumber: containerData?.containerNumber || '',
        packing: containerData?.packing || ['LOS'],
        vgm: containerData?.vgm || '',
        eir: containerData?.eir || '',
        sealNumber: containerData?.sealNumber || '',
        collectionDate: containerData?.collectionDate ? moment(containerData?.collectionDate).format('YYYY-MM-DD') : '',
        dropOffDate: containerData?.dropOffDate ? moment(containerData?.dropOffDate).format('YYYY-MM-DD') : '',
        pickUpDate: containerData?.pickUpDate ? moment(containerData?.pickUpDate).format('YYYY-MM-DD') : '',
        gateInDate: containerData?.gateInDate ? moment.utc(containerData?.gateInDate).local().format('YYYY-MM-DD') : '',
        gateInTime: containerData?.gateInDate ? moment.utc(containerData?.gateInDate).local().format('HH:mm') : '',
    };

    const BasicDetailsSchema = Yup.object().shape({
        containerType: Yup.string().required('Container type is required'),
        deliveryMode: Yup.string().required('Delivery mode required'),
        containerMode: Yup.string().required('Container mode is required'),
        containerNumber: Yup.string()
            .required('Container number is required')
            .matches(/^[a-zA-Z]{4}\d{7}$/, 'Container number must start with 4 characters followed by 7 numbers'),
        packing: Yup.array().min(1, 'Packing is required'),
        // vgm: Yup.string()
        //     .required('VGM is required')
        //     .test('equalszero', 'VGM must be greater than Zero', (value) => {
        //         if (value) {
        //             const vgm = parseInt(value);
        //             return vgm === 0 ? false : true;
        //         }
        //         return true;
        //     }),
        // sealNumber: Yup.string().required('Seal Number is required'),
        // collectionDate: Yup.date().required('Collection Date from Depot is required'),
        // dropOffDate: Yup.date().required('Drop Off Date is required'),
        // pickUpDate: Yup.date().required('Pick Up Date is required'),
        // gateInDate: Yup.date().required('Gate In Date is required'),
    });

    const showError = (field: keyof typeof initialValues) => (
        <>{formik.errors[field] ? <div className="error-text">{formik.errors[field]}</div> : null}</>
    );

    useEffect(() => {
        const containerModeData = containerModes?.map((option: any) => {
            return { value: option.containerMode, label: option.containerMode };
        });
        setContainerModesModified(containerModeData ? containerModeData : []);

        const containeTypeData = containerTypes?.map((option: any) => {
            return {
                ...option,
                value: `${option.description} (${option.code})`,
                label: `${option.description} (${option.code})`,
            };
        });
        setContainerTypesModified(containeTypeData ? containeTypeData : []);

        const deliveyModeData = deliveryModes?.map((option: any) => {
            return { value: option.deliveryMode, label: option.deliveryMode };
        });
        setDeliveryModesModified(deliveyModeData ? deliveyModeData : []);

        // formik.setFieldValue('containerType', getContainerId());
        // formik.setFieldValue('deliveryMode', getDeliveryModeId());
    }, [containerModes]);

    useEffect(() => {
        if (isError && ContainerAllocationError?.data?.path?.includes('basic')) {
            error(ContainerAllocationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (containerAllocationSuccess?.status === 200) {
            dispatch(
                updateDate({
                    ...newFormData,
                    cdId: allocationId,
                    containerDetailsId: containerDetailsId ? containerDetailsId : null,
                    mode: containerData?.collectionDate ? 'edit' : 'add',
                    bpId,
                })
            );
        }
    }, [containerAllocationSuccess, ContainerAllocationError]);

    const formik = useFormik({
        initialValues,
        validationSchema: BasicDetailsSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // validateOnMount: modalView,
        onSubmit: (values) => {
            setValidation(true);
            if (formik.isSubmitting) {
                let formData: any = { ...values };
                if (values.gateInDate) {
                    const dateTime = values.gateInDate + ' ' + values.gateInTime + ':00';
                    formData = {
                        ...values,
                        gateInDate: moment(dateTime, 'YYYY-MM-DD HH:mm:ss').utc().format('YYYY-MM-DD HH:mm:ss'),
                    };
                    delete formData.gateInTime;
                }
                setNewFormData(formData);
                // setIsLoading(true);
                dispatch(
                    updateBasicDetail({
                        ...values,
                        vgm: values.vgm,
                        cdId: allocationId,
                        containerDetailsId: containerDetailsId ? containerDetailsId : null,
                        containerId: getContainerId() ? getContainerId() : null,
                        mode: containerData?.containerNumber ? 'edit' : 'add',
                        bpId,
                    })
                );
            }
        },
    });

    useEffect(() => {
        if (modalView) {
            setValidation(false);
            dispatch(fetchPacking({}));
            dispatch(fetchDeliveryAndContainerModes({ bpType: 'fcl', coloaderCode: headerData?.coLoaderCode }));
        }
    }, [modalView]);

    useEffect(() => {
        setIsLoading(false);
        if (isError && ContainerDateError?.data?.path?.includes('dates')) {
            error(ContainerDateError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (containerDateSuccess?.status === 200 || containerDateSuccess?.status === 201) {
            if (
                containerData?.containerType !== newFormData.containerType &&
                headerData.bpStatus !== 'Draft_Booking_Proposal' &&
                headerData.bpStatus !== 'Awaiting_Forwarder_Approval'
            ) {
                resetBpToBookingProposal({ bpId, reason: 'Edit Container', flag: true }).then((res: any) => {
                    dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                    setModalView(false);
                });
            } else {
                setModalView(false);
            }
            if (containerDateSuccess?.data?.type === 'date') {
                success(
                    `Basic details have been ${containerDateSuccess?.data?.mode === 'add' ? 'added' : 'updated'}`,
                    Position.TOP_RIGHT
                );
                if (containerDateSuccess?.data?.info?.containerDetailsId) {
                    dispatch(
                        fetchContainerDetail({
                            containerDetailsId: containerDateSuccess?.data?.info?.containerDetailsId,
                        })
                    );
                } else {
                    dispatch(fetchContainerOnlyBasicDetail({ containerId: allocationId }));
                }
                // setModalView(false);
                formik.resetForm();
                dispatch(clearFailure([]));

                setHasUpdateCompleted(!hasUpdateCompleted);
            }
        }
    }, [isError, containerDateSuccess, ContainerDateError]);

    useEffect(() => {
        if (!modalView) {
            formik.resetForm();
        }
    }, [modalView]);
    const changeDropDown = (field: string) => {
        switch (field) {
            case 'containerType':
                setContainerDropDownOpen((prev) => !prev);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'deliveryMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen((prev) => !prev);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'containerMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen((prev) => !prev);
                setPackingDropDownOpen(false);
                break;
            case 'packing':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen((prev) => !prev);
                break;
            default:
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
        }
    };

    const packingSelection = (res: PackingList) => {
        formik.setFieldError('packing', undefined);
        if (formik.values.packing.includes(res.packingCode)) {
            const packing = formik.values.packing.filter((el: string) => el !== res.packingCode);
            formik.setFieldValue('packing', packing);
        } else {
            const packing = [...formik.values.packing, res.packingCode];
            formik.setFieldValue('packing', packing);
        }
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div
                        onClick={(e) => {
                            changeDropDown('');
                            e.stopPropagation();
                        }}
                        className="modal-content-holder basic-detail-content-holder large-popup"
                    >
                        <div className="modal-header">
                            <div className="title">Container Details</div>
                            <button
                                type="button"
                                className="app-btn modal-close-btn"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content bd-modal-main-content">
                                {headerData.bpStatus !== 'Draft_Booking_Proposal' &&
                                    headerData.bpStatus !== 'Awaiting_Forwarder_Approval' && (
                                        <div className="warning-text">
                                            <div className="error-text">
                                                WARNING: Changing the container type will result in the Booking Proposal
                                                status being reset to 'Draft Booking Proposal'
                                            </div>
                                            <br />
                                        </div>
                                    )}
                                <form
                                    id="basic-details"
                                    action=""
                                    onSubmit={formik.handleSubmit}
                                    // className="modal-form layout-holder one-column"
                                >
                                    <div className="modal-form layout-holder two-column container-modal-all">
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Container Type
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder grey-option-box">
                                                <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder">
                                                    <SearchableSelectBox
                                                        data={
                                                            containerTypesModified?.length ? containerTypesModified : []
                                                        }
                                                        placeholder="Enter container type"
                                                        formik={formik}
                                                        fieldName="containerType"
                                                        customStyle={''}
                                                        menuPosition="fixed"
                                                        menuPortalTarget={document.getElementById('outside_div')}
                                                        closeMenuOnScroll={true}
                                                    />
                                                    {formik.errors.containerType ? (
                                                        <div className="error-text">{formik.errors.containerType}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Delivery Mode
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder grey-option-box">
                                                <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder delivery-mode-dropdown">
                                                    <SearchableSelectBox
                                                        data={
                                                            deliveryModesModified?.length ? deliveryModesModified : []
                                                        }
                                                        placeholder="Enter delivery mode"
                                                        formik={formik}
                                                        fieldName="deliveryMode"
                                                        customStyle={''}
                                                        menuPosition="fixed"
                                                        menuPortalTarget={document.getElementById('outside_div')}
                                                        closeMenuOnScroll={true}
                                                    />
                                                    {formik.errors.deliveryMode ? (
                                                        <div className="error-text">{formik.errors.deliveryMode}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Container Mode
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder grey-option-box">BCN</div>
                                        </div> */}
                                        <div className="modal-input-holder">
                                            <label className="modal-input-label">
                                                Container Number
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter Container Number"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="containerNumber"
                                                    name="containerNumber"
                                                    onChange={(event) => {
                                                        // event.target.value
                                                        //     ? formik.setFieldError(event.target.name, undefined)
                                                        //     : formik.setFieldError(event.target.name, 'Field is Required');
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9A-Za-z]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    value={formik.values.containerNumber}
                                                />
                                                {showError('containerNumber')}
                                            </div>
                                        </div>

                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Packing
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder grey-option-box modal-input-layout-item">
                                                <div
                                                    className={`dropdown-wrap menu-down modal-dropdown ${
                                                        packingDropDownOpen ? 'dropdown-open' : ''
                                                    } `}
                                                >
                                                    <button
                                                        type="button"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            changeDropDown('packing');
                                                        }}
                                                        className="menu-btn app-btn select-modal"
                                                    >
                                                        {formik.values.packing?.length ? (
                                                            <span className="button-text selected-text">
                                                                {formik.values.packing.length} selected
                                                            </span>
                                                        ) : (
                                                            <>
                                                                <span className="placeholder-text">Select Packing</span>
                                                                <span className="button-text"></span>
                                                            </>
                                                        )}
                                                        <span className="dropdown-arrow">
                                                            <svg className="svg-icon arrow-icon">
                                                                <use xlinkHref="#downArrow">
                                                                    <title>dropdown</title>
                                                                </use>
                                                            </svg>
                                                        </span>
                                                    </button>

                                                    <div
                                                        className={
                                                            packingDropDownOpen
                                                                ? 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                                                : 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down modal-dropdown'
                                                        }
                                                        style={{
                                                            width: '425px',
                                                        }}
                                                    >
                                                        <div className="dropdown-menu ">
                                                            {packing.map((res: PackingList) => (
                                                                <a
                                                                    onClick={(e) => {
                                                                        packingSelection(res);
                                                                        e.preventDefault();
                                                                    }}
                                                                    key={res.packingId}
                                                                    className="menu-item app-btn"
                                                                >
                                                                    <div
                                                                        onClick={(e) => {
                                                                            packingSelection(res);
                                                                            e.preventDefault();
                                                                        }}
                                                                        className="checkbox-item"
                                                                    >
                                                                        <label className="app-check-wrapper">
                                                                            <input
                                                                                checked={
                                                                                    formik.values.packing.includes(
                                                                                        res.packingCode
                                                                                    )
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                                type="checkbox"
                                                                                className="checkbox-input"
                                                                            ></input>
                                                                            <div className="checkmark">
                                                                                <svg className="svg-icon tick-icon">
                                                                                    <use href="#tickIcon">
                                                                                        <title>check mark</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                            <div className="checkbox-label">
                                                                                {res.packingName}
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="suggestion-container">
                                                    {formik.values.packing.map((res: string, index: number) => (
                                                        <div key={index} className="content-card">
                                                            <span className="main-content">
                                                                {
                                                                    packing?.find(
                                                                        (el: PackingList) => el.packingCode === res
                                                                    )?.packingName
                                                                }
                                                            </span>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    const packing = formik.values.packing.filter(
                                                                        (el: string) => el !== res
                                                                    );
                                                                    formik.setFieldValue('packing', packing);
                                                                }}
                                                                className="app-btn close-btn"
                                                                title="Remove"
                                                            >
                                                                <span>
                                                                    <svg className="svg-icon suggestion-delete-icon">
                                                                        <use href="#deleteIcon">
                                                                            <title>Remove</title>
                                                                        </use>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                            {showError('packing')}
                                        </div>
                                        <div className="modal-input-holder">
                                            <label className="modal-input-label">
                                                VGM (in kg)
                                                {/* <span className="mandatory-field-text">*</span> */}
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter VGM"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="vgm"
                                                    name="vgm"
                                                    onChange={(event: any) => {
                                                        // event.target.value
                                                        //     ? formik.setFieldError(event.target.name, undefined)
                                                        //     : formik.setFieldError(event.target.name, 'Field is Required');
                                                        // if (
                                                        //     // event.target.value <= 1000000 &&
                                                        //     decimalNumberValidation(
                                                        //         parseFloat(event.target.value),
                                                        //         7,
                                                        //         2
                                                        //     )
                                                        // ) {
                                                        const split = event.target.value?.split('.');
                                                        if (split?.[0]?.length > 14 || split?.[1]?.length > 5) {
                                                            return false;
                                                        } else {
                                                            formik.handleChange(event);
                                                        }
                                                        // }
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (
                                                            !validateFractionInput({
                                                                inputKey: event.key,
                                                                currentValue: +formik.values.vgm,
                                                            })
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        e.target.value !== '' &&
                                                            parseFloat(e.target.value) >= 0 &&
                                                            formik.validateField('vgm');
                                                    }}
                                                    value={formik.values.vgm}
                                                />
                                                {/* {formik?.values?.vgm && formik?.errors?.vgm && (
                                                <div className="error-text">{formik.errors.vgm}</div>
                                            )}
                                            {(formik?.values?.eir || formik?.values?.sealNumber) &&
                                                formik?.values?.vgm?.length === 0 &&
                                                !validation && <div className="error-text">{formik.errors.vgm}</div>} */}
                                                {showError('vgm')}{' '}
                                            </div>

                                            {/* {vgmError && (
                                            <span
                                                className="page-heading__caption"
                                                style={{ marginLeft: '0', color: '#fd1010' }}
                                            >
                                                VGM field is required
                                            </span>
                                        )} */}
                                        </div>
                                        <div className="modal-input-holder">
                                            <label className="modal-input-label">
                                                EIR
                                                {/* <span className="mandatory-field-text">*</span> */}
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter EIR"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="eir"
                                                    name="eir"
                                                    onChange={(event) => {
                                                        // event.target.value
                                                        //     ? formik.setFieldError(event.target.name, undefined)
                                                        //     : formik.setFieldError(event.target.name, 'Field is Required');
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    value={formik.values.eir}
                                                />
                                                {showError('eir')}
                                            </div>
                                        </div>
                                        <div className="modal-input-holder">
                                            <label className="modal-input-label">
                                                Seal No.
                                                {/* <span className="mandatory-field-text">*</span> */}
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter Seal No"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="sealNumber"
                                                    name="sealNumber"
                                                    onChange={(event) => {
                                                        // event.target.value
                                                        //     ? formik.setFieldError(event.target.name, undefined)
                                                        //     : formik.setFieldError(event.target.name, 'Field is Required');
                                                        formik.setFieldError(event.target.name, undefined);
                                                        formik.handleChange(event);
                                                    }}
                                                    onKeyPress={(event) => {
                                                        if (!/[0-9A-Za-z]/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    value={formik.values.sealNumber}
                                                />
                                                {showError('sealNumber')}
                                            </div>
                                        </div>
                                        <div className="modal-input-holder empty-div"></div>
                                        <div className="title-sub">Dates</div>
                                        <div className="modal-input-holder"></div>
                                        <div className="modal-input-holder">
                                            <label className="modal-input-label">Collection Date from Depot</label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={new Date()}
                                                    formik={formik}
                                                    fieldName="collectionDate"
                                                    portalId="date-portal"
                                                />
                                                {showError('collectionDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">Drop Off Date at Vendor</label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={new Date()}
                                                    formik={formik}
                                                    fieldName="dropOffDate"
                                                    portalId="date-portal"
                                                />
                                                {showError('dropOffDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">Pick Up Date from Vendor</label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={new Date()}
                                                    formik={formik}
                                                    fieldName="pickUpDate"
                                                    portalId="date-portal"
                                                />
                                                {showError('pickUpDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Gate in Date and Time
                                                {/* <span className="mandatory-field-text">*</span> */}
                                            </label>
                                            <div className="modal-input-layout-item calendar date-time">
                                                <DatePicker
                                                    // minDate={new Date()}
                                                    formik={formik}
                                                    fieldName="gateInDate"
                                                    portalId="date-portal"
                                                />
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DemoContainer components={['TimePicker']}>
                                                        <TimePickerPack
                                                            onChange={(value: any) => {
                                                                formik.setFieldValue(
                                                                    'gateInTime',
                                                                    moment(value.$d).format('HH:mm')
                                                                );
                                                            }}
                                                            views={['hours', 'minutes']}
                                                            format="HH:mm"
                                                            ampm={false}
                                                            timeSteps={{ minutes: 1 }}
                                                            value={dayjs('0000-00-00T' + formik.values.gateInTime)}
                                                        />
                                                    </DemoContainer>
                                                </LocalizationProvider>
                                            </div>
                                            <div style={{ display: 'flex' }}>
                                                {showError('gateInDate')}
                                                <span
                                                    style={{
                                                        marginLeft: 'auto',
                                                        marginTop: '2px',
                                                        paddingRight: '26px',
                                                    }}
                                                >
                                                    {showError('gateInTime')}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn text-close-btn"
                                type="reset"
                                form="basic-details"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="submit"
                                form="basic-details"
                                onClick={() => setValidation(true)}
                                // disabled={Object.keys(formik.errors).length ? true : false}
                            >
                                <span className="button-text vendor-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
