import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'lodash';
import {
    clearLifeCycleSearch,
    onLifeCycleSearch,
    setSelectedData,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import { fetchSelectedSailing } from 'src/store/reducers/bookingProposal/sailingReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
// import { composeSortDescriptors } from '@progress/kendo-data-query';

const Index: React.FC = () => {
    const [searchDropDown, setSearchDropDown] = useState(false); //false
    const inputRef = useRef<HTMLDivElement>(null);
    const [searchKeyword, setSearchKeyword] = useState<string>('');
    const dispatch = useDispatch();
    const { profileType, userName } = useUserProfile();
    const { isSearching: searching, searchList } = useSelector((state: any) => state.lifeCycleSearch);
    const location = useLocation() as any;

    const { name } = location?.state || '';
    const { type } = useParams() as any;
    useOutsideAlerter(inputRef, setSearchDropDown);

    const searchKeywordFormat = (type: string) => {
        let keyvalue = type;
        //Remove the last 's' from type
        if (type.charAt(type.length - 1) === 's') {
            keyvalue = type.slice(0, -1);
        }
        if (keyvalue === 'sku') {
            keyvalue = 'SKU';
        }
        // Find the next word after the hyphen and capitalize its first character
        const stringWithCapitals = keyvalue.replace(/-([a-zA-Z])/g, function (match, nextChar) {
            return ' ' + nextChar.toUpperCase();
        });
        //Change the hyphen to space
        const stringWithSpaces = stringWithCapitals.replace(/-/g, ' ');
        //Make the first key capital
        keyvalue = stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);
        return keyvalue;
    };

    useEffect(() => {
        if (name && type) {
            const searchText = `${searchKeywordFormat(type)} : ${name}`;
            setSearchKeyword(searchText);
        }
    }, [name, type]);

    const navigate = useNavigate();

    const handleSearch = (event: any) => {
        const { value: searchTerm } = event.target;
        setSearchDropDown(true);
        setSearchKeyword(searchTerm);
        dispatch(clearLifeCycleSearch());
        //dispatch(clearsailingUpdated(null));
        //dispatch(clearsailingBpUpdated(null));
        const bounced = debounce(() => {
            dispatch(
                onLifeCycleSearch({
                    userName,
                    profile: profileType,
                    keyword: searchTerm,
                    isForwarder: profileType === 'forwarder',
                })
            );
        }, 500);
        if (searchTerm?.length >= 3) {
            bounced();
        }
    };

    const listIsEmpty =
        !searchList?.purchaseOrders?.length &&
        !searchList?.bookings?.length &&
        !searchList?.client?.length &&
        !searchList?.agent?.length &&
        !searchList?.sku?.length &&
        !searchList?.vendor?.length &&
        !searchList?.port?.length &&
        !searchList?.vessels?.length &&
        !searchList?.carrier?.length &&
        !searchList?.warehouse?.length &&
        !searchList?.masterBill?.length &&
        !searchList?.houseBill?.length;

    // const handleFocusOut = () => {
    //     setTimeout(() => {
    //         setSearchKeyword('');
    //         //         // setSearchDropDown(false);
    //         //         // dispatch(clearLifeCycleSearch());
    //     }, 200);
    // };

    const handleFocusIn = () => {
        //dispatch(clearsailingUpdated(null));
        //dispatch(clearsailingBpUpdated(null));
        // setSearchKeyword('');
        setSearchDropDown(true);
    };
    const handleLifeCycleSearchItemClick = (
        id: string,
        searchType: string,
        name: string,
        type?: string,
        bpType?: string
    ) => {
        dispatch(setSelectedData({ id, searchType, name, type }));
        navigate(`/tracking/lifecycle_booking/${searchType}/${id}`, {
            state: {
                name,
                type,
                id,
                bpType,
            },
        });
        setSearchDropDown(false);
    };

    return (
        <div className="search-entry-holder" ref={inputRef}>
            <input
                placeholder="Search"
                type="text"
                value={searchKeyword}
                className="search-input input-text"
                onChange={handleSearch}
                onFocus={handleFocusIn}
                // onBlur={handleFocusOut}
            ></input>
            <svg className="svg-icon search-icon">
                <use href="#searchIcon">
                    <title>Search</title>
                </use>
            </svg>
            {searchKeyword && (
                <button
                    className="app-btn text-close-btn"
                    onClick={() => {
                        setSearchKeyword('');
                        dispatch(clearLifeCycleSearch());
                    }}
                >
                    <svg className="svg-icon text-close-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </button>
            )}
            {searchDropDown && searchKeyword ? (
                <div className="lcs-dropdown customscrollbar">
                    {searching && (
                        <div className="app-loader loading ">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    {listIsEmpty && !searching && (
                        <div className="no-data-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">
                                {searchKeyword.length < 3
                                    ? 'Enter at least 3 characters to search'
                                    : 'No Matching Records Found'}
                            </p>
                        </div>
                    )}

                    {searchList && !searching && searchDropDown && (
                        <div className="lcs-list-wrapper">
                            {searchList?.purchaseOrders?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Purchase Orders</h4>
                                    {searchList?.purchaseOrders?.map((order: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={order?.id}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(
                                                    order?.poId,
                                                    'purchase-orders',
                                                    order?.poNumber,
                                                    order?.type
                                                );
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{order?.poNumber}</h5>
                                            <span className="lcs-client-name">{order?.clientName}</span>
                                            {order?.vendorName && (
                                                <span className="lcs-vendor-name">{order?.vendorName}</span>
                                            )}
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.bookings?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Bookings</h4>
                                    {searchList?.bookings?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.id}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(
                                                    item?.bpId,
                                                    'bookings',
                                                    item?.bpNumber,
                                                    item?.type
                                                );
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.bpNumber}</h5>
                                            <span className="lcs-client-name">{item?.clientNames}</span>
                                            <span className="lcs-vendor-name">
                                                {item?.originPort} to {item?.destinationPort}
                                            </span>
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.client?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Clients</h4>
                                    {searchList?.client.map((client: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={client?.id}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(client?.id, 'clients', client?.name);
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{client?.name}</h5>
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.agent?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Agents</h4>
                                    {searchList?.agent.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.id}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(
                                                    item?.id,
                                                    'agents',
                                                    item?.name,
                                                    item?.code
                                                );
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.name}</h5>
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.vendor?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Vendors</h4>
                                    {searchList?.vendor?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.id}
                                            onClick={() => {
                                                handleLifeCycleSearchItemClick(item?.id, 'vendors', item?.name);
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.name}</h5>
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.sku?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">SKU</h4>
                                    {searchList?.sku?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.skuId}
                                            onClick={() =>
                                                handleLifeCycleSearchItemClick(
                                                    item?.skuId,
                                                    'sku',
                                                    item?.skuName,
                                                    item?.clientCode
                                                )
                                            }
                                        >
                                            <h5 className="lcs-items-number">{item?.skuName}</h5>
                                            <span className="lcs-client-name">{item?.description}</span>
                                            {item?.clientName && (
                                                <span className="lcs-vendor-name">{item?.clientName}</span>
                                            )}
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.port?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Ports</h4>
                                    {searchList?.port?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.portId}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(
                                                    item?.portId,
                                                    'ports',
                                                    item?.portCode + '(' + item?.country + ')'
                                                );
                                            }}
                                        >
                                            <h5 className="lcs-items-number">
                                                {item?.portCode}({item?.country})
                                                {/* {item?.portName}({item?.portCode}) */}
                                            </h5>
                                            <span className="lcs-client-name">{item?.countryname}</span>
                                        </a>
                                    ))}
                                </div>
                            ) : null}

                            {searchList?.vessels?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Vessels</h4>
                                    {searchList?.vessels?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.vessel}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(
                                                    item?.id,
                                                    'vessels',
                                                    item?.vessel,
                                                    item?.type
                                                );
                                                dispatch(fetchSelectedSailing(item?.id));
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.vessel}</h5>
                                            <span className="lcs-client-name">{item?.carrierName}</span>
                                        </a>
                                    ))}
                                </div>
                            ) : null}

                            {searchList?.carrier?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Carriers</h4>
                                    {searchList?.carrier?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.id}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(item?.id, 'carriers', item?.name);
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.name}</h5>
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.warehouse?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Warehouse</h4>
                                    {searchList?.warehouse?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.id}
                                            onClick={() => {
                                                handleLifeCycleSearchItemClick(
                                                    item?.code,
                                                    'warehouse',
                                                    item?.name,
                                                    item?.code
                                                );
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.name}</h5>
                                        </a>
                                    ))}
                                </div>
                            ) : null}

                            {searchList?.masterBill?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">Master Bill</h4>
                                    {searchList?.masterBill.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.number}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(
                                                    item?.number,
                                                    'master-bill',
                                                    item?.number,
                                                    'master-bill'
                                                );
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.number}</h5>
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                            {searchList?.houseBill?.length ? (
                                <div className="lcs-dropdown-items">
                                    <h4 className="lcs-items-title">House Bill</h4>
                                    {searchList?.houseBill?.map((item: any) => (
                                        <a
                                            className="lcs-sublist hand-icon"
                                            key={item?.number}
                                            onClick={(_event: any) => {
                                                handleLifeCycleSearchItemClick(
                                                    item?.id,
                                                    'house-bill',
                                                    item?.number,
                                                    item?.type,
                                                    item?.bpType
                                                );
                                            }}
                                        >
                                            <h5 className="lcs-items-number">{item?.number}</h5>
                                        </a>
                                    ))}
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            ) : (
                ''
            )}
        </div>
    );
};
export default Index;
