import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    isLoadingFn,
    sailingUpdated,
    saveExistingSchedules,
    saveSelectedSchedules,
    getCarrierList,
    getVesselList,
    getContractList,
    getAllContractList,
} from '../../reducers/bookingProposalAir/addSchedule';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    buyContract,
    existingAirSchedulesApi,
    newScheduleApis,
    selectedSchedulesAir,
    carrierListAirApi,
    selectedSchedulesMBL,
    vesselListAirApi,
    contractListApi,
    allContractListApi,
} from 'src/services/apis/bookingProposalAir/addSchedule';
import { isLoadingFn2, viewSelectedSailing } from 'src/store/reducers/bookingProposal/sailingReducer';

function* getExisitngAirSchedule({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const exisitngSchedule: AxiosResponse<any> = yield call(existingAirSchedulesApi, payload);
        yield put({ type: saveExistingSchedules, payload: exisitngSchedule });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getSelectedAirSchedules({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const selectedSchedule: AxiosResponse<any> = yield call(selectedSchedulesAir, payload);
        yield put({ type: saveSelectedSchedules, payload: (selectedSchedule as any)?.airFreightScheduleSelected });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getSelectedSchedulesMBL({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const selectedSchedule: AxiosResponse<any> = yield call(selectedSchedulesMBL, payload);
        if ((selectedSchedule as any)?.bpType === 'AIR') {
            //console.log(selectedSchedule, 'selectedScheduleselectedSchedule');
            const selectedSchedule1: AxiosResponse<any> = yield call(
                selectedSchedulesAir,
                (selectedSchedule as any)?.sailingScheduleLists[0]?.bookingProposalId
            );
            yield put({ type: saveSelectedSchedules, payload: (selectedSchedule1 as any)?.airFreightScheduleSelected });
        } else {
            yield put({ type: viewSelectedSailing, payload: selectedSchedule });
        }
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getCarrierListDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const carrierList: AxiosResponse<any> = yield call(carrierListAirApi, payload);
        yield put({ type: getCarrierList, payload: carrierList });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getContractListDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const contractList: AxiosResponse<any> = yield call(contractListApi, payload);
        yield put({ type: getContractList, payload: contractList });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getAllContractListDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const contractList: AxiosResponse<any> = yield call(allContractListApi, payload);
        yield put({ type: getAllContractList, payload: contractList });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getVesselListDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const vesselList: AxiosResponse<any> = yield call(vesselListAirApi, payload);
        yield put({ type: getVesselList, payload: vesselList });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* createNewSchedule({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put({ type: isLoadingFn2, payload: true });
        const response: AxiosResponse<any> = yield call(newScheduleApis, payload);

        yield put({
            type: sailingUpdated,
            payload: { status: true, data: response },
        });
        yield put(showLoading());
    } catch (error) {
        yield put({ type: sailingUpdated, payload: { status: false, data: error } });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
        yield put({ type: isLoadingFn2, payload: false });
    }
}
function* buyNewQuote({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield call(buyContract, payload);
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* availablePoListSaga() {
    yield takeEvery('addedSchedulesAir/listExistingCall', getExisitngAirSchedule);
    yield takeEvery('addedSchedulesAir/addSchedule', createNewSchedule);
    yield takeEvery('addedSchedulesAir/buyQuote', buyNewQuote);
    yield takeEvery('addedSchedulesAir/getSelectedSchedules', getSelectedAirSchedules);
    yield takeEvery('addedSchedulesAir/getSelectedSchedulesMBL', getSelectedSchedulesMBL);
    yield takeEvery('addedSchedulesAir/getCarrierList', getCarrierListDetails);
    yield takeEvery('addedSchedulesAir/getVesselList', getVesselListDetails);
    yield takeEvery('addedSchedulesAir/getContractList', getContractListDetails);
    yield takeEvery('addedSchedulesAir/getAllContractList', getAllContractListDetails);
}

export default availablePoListSaga;
