import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import './compilance-modal.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    updateComplianceCheck,
    selectReceiptHeaderIsError,
    selectReceiptHeaderSuccess,
    selectReceiptHeaderError,
    clearFailure,
    fetchReceiptHeaderList,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { error, success, Position } from '../../../../utils/toast';
interface ComplianceModalProps {
    complianceModalVisibility: boolean;
    setComplianceModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
}
import ImageView from './ImageView';
import { useParams } from 'react-router-dom';
import { fetchPolineReceipt } from '../../../../store/reducers/receipt/poLine';
import { getFailedReasonImage } from '../../../../services/apis/receipts/receiptHeaderApi';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import imageLoader from '../../../../assets/images/image-loader.svg';
const Index: React.FC<ComplianceModalProps> = ({ complianceModalVisibility, setComplianceModalVisibility, data }) => {
    const { id } = useParams();
    const { userName, fullName } = useUserProfile();
    const [isLoading, setIsLoading] = useState(false);
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [complianceCheckStatus, setComplianceCheckStatus] = useState('');
    const [reason, setReason] = useState('');
    const [complianceImages, setComplianceImages] = useState<any>([]);
    const [files, setFiles] = useState([] as any);
    const [imageURLS, setImageURLs] = useState([]);
    const [removedImages, setRemovedImages] = useState<any>([]);
    const [images, setImages] = useState([] as any);

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectReceiptHeaderIsError);
    const receiptHeaderSuccess = useAppSelector(selectReceiptHeaderSuccess);
    const receiptHeaderError = useAppSelector(selectReceiptHeaderError);

    const onSubmit = () => {
        setIsLoading(true);
        const formData = new FormData();
        const uploadData = {
            complianceCheckStatus,
            complianceCheckFailReason: complianceCheckStatus === 'failed' ? reason : '',
            receiptSubPoLineId: data?.rsId,
            docType: 'COMPLIANCE_CHECK_IMAGE',
            fullName,
            userName,
            removedImages,
        };
        formData.append('document', new Blob([JSON.stringify(uploadData)], { type: 'application/json' }));
        if (complianceCheckStatus === 'pass') {
            formData.append('files', '');
        } else {
            for (let index = 0; index < files.length; index++) {
                formData.append('files', files[index]);
            }
            // for (const file of files) {
            //     formData.append('files', file);
            // }
        }

        dispatch(updateComplianceCheck({ formData, receiptId: id }));
    };

    const generateUrl = (base64Image: string, fileName: string) => {
        const [extension] = fileName.split('.').reverse();
        return `data:image/${extension};base64,${base64Image}`;
    };

    const getImage = (complianceImageList: any) => {
        if (complianceImageList?.length) {
            setComplianceImages(complianceImageList);
            getFailedReasonImage({
                receiptSubPoLineId: data?.rsId,
                type: 'COMPLIANCE_CHECK_IMAGE',
            })
                .then((res: any) => {
                    const urls = res?.data;
                    const images = JSON.parse(JSON.stringify(complianceImageList));
                    const imageWithUrl = images?.map((response: any) => ({
                        ...response,
                        fileUrl: generateUrl(
                            urls?.find((elem: any) => elem?.fileId === response?.fileId)?.base64,
                            response?.fileName
                        ),
                    }));
                    setComplianceImages(imageWithUrl);
                })
                .catch((err: any) => console.log(err, 'err'));
        }
    };

    const resetData = () => {
        setImages([]);
        setRemovedImages([]);
        setImageURLs([]);
        setFiles([]);
        setComplianceImages([]);
        setReason('');
        setComplianceCheckStatus('');
        setCurrentImageIndex(0);
        const fileElement = document.getElementById('complianceImage');
        if (fileElement instanceof HTMLInputElement) {
            fileElement.value = '';
        }
    };

    useEffect(() => {
        if (complianceModalVisibility) {
            setComplianceCheckStatus(data?.complianceCheckStatus);
            setReason(data?.complianceCheckFailReason ? data?.complianceCheckFailReason : '');
            getImage(data?.complianceImages);
        } else {
            resetData();
        }
    }, [complianceModalVisibility]);

    useEffect(() => {
        if (isError && receiptHeaderError?.config?.url?.includes('compliance-check')) {
            error(receiptHeaderError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (receiptHeaderSuccess?.status === 200 || receiptHeaderSuccess?.status === 201) {
            if (receiptHeaderSuccess?.data?.type === 'updateCompliance') {
                success('Compliance check updated successfully', Position.TOP_RIGHT);
                setComplianceModalVisibility(false);
                dispatch(clearFailure([]));
                dispatch(fetchPolineReceipt(id));
                dispatch(
                    fetchReceiptHeaderList({
                        id,
                    })
                );
            }
        }
        setIsLoading(false);
    }, [isError, receiptHeaderSuccess, receiptHeaderError]);

    useEffect(() => {
        if (images.length < 1) {
            return;
        }
        const newImageUrls = images.map((image: any) => ({
            fileUrl: URL.createObjectURL(image),
            fileName: image.name,
            fileSize: image.size,
        }));
        setImageURLs(newImageUrls);
    }, [images]);

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            setFiles((prev: any) => [...prev, ...e.target.files]);
            // setFiles((prev: any) => e.target.files);
            setImages((prev: any) => [...prev, ...e.target.files]);
        }
    };

    const removeFile = (currentIndex: number) => {
        const newFileList = Array.from(files);
        newFileList.splice(currentIndex, 1);
        const imagesData = images?.filter((_: any, index: number) => index !== currentIndex);
        setFiles(newFileList);
        setImages(imagesData);
        setImageURLs(imagesData);
    };
    const removeExistingFile = (currentIndex: number) => {
        const images = complianceImages?.filter((_: any, index: number) => index !== currentIndex);
        setComplianceImages(images);
        const removed = [...removedImages, complianceImages[currentIndex].fileId];
        setRemovedImages(removed);
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };

    const checkSubmit = () => {
        if (complianceCheckStatus === 'pass' || (complianceCheckStatus === 'failed' && reason && imageURLS?.length)) {
            return false;
        } else {
            return true;
        }
    };

    return (
        <>
            {complianceModalVisibility ? (
                <div>
                    <div className="app-modal">
                        <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                        <div
                            className="modal-content-holder medium-popup compilance-modal"
                            onClick={(e) => e.stopPropagation()}
                        >
                            <div className="modal-header">
                                <div className="title">Compliance Check</div>
                                <button
                                    onClick={() => setComplianceModalVisibility(false)}
                                    className="app-btn modal-close-btn"
                                >
                                    <span>
                                        <svg className="svg-icon modal-header-close-icon">
                                            <use href="#closeIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div className="modal-content">
                                <div className="modal-main-content">
                                    <form id="productForm" action="" className="modal-form">
                                        <div className="layout-holder">
                                            <div className="header-checkbox-wrapper">
                                                <h2 className="header-title">
                                                    Compliance Check Status
                                                    <span className="mandatory-field-text">*</span>
                                                </h2>
                                                <div className="checkbox-wrapper-modal">
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="radio"
                                                                className="checkbox-input"
                                                                name="group"
                                                                checked={complianceCheckStatus === 'pass'}
                                                                onChange={() => setComplianceCheckStatus('pass')}
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon notification-circle-icon">
                                                                    <use href="#circleIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">Pass</div>
                                                        </label>
                                                    </div>
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="radio"
                                                                className="checkbox-input"
                                                                name="group"
                                                                checked={complianceCheckStatus === 'failed'}
                                                                onChange={() => setComplianceCheckStatus('failed')}
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon notification-circle-icon">
                                                                    <use href="#circleIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">Failed</div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            {complianceCheckStatus === 'failed' && (
                                                <>
                                                    <div
                                                        className=" modal-input-holder"
                                                        style={{ paddingBottom: '8px' }}
                                                    >
                                                        <label htmlFor="name" className="modal-input-label">
                                                            Reason<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div className="modal-input-field-holder">
                                                            <textarea
                                                                name="reason"
                                                                className="input-text"
                                                                placeholder="Enter Reason"
                                                                value={reason}
                                                                onChange={(e) => setReason(e.target.value)}
                                                                maxLength={250}
                                                            ></textarea>
                                                        </div>
                                                    </div>
                                                    <div className="layout-item">
                                                        <h6 className="header-title">
                                                            Images<span className="mandatory-field-text">*</span>
                                                        </h6>
                                                        <div className="description-type-1">
                                                            <div className=" modal-input-holder">
                                                                <div className="modal-input-layout-item">
                                                                    <div className="browse-btn-wrapper">
                                                                        <input
                                                                            type={'file'}
                                                                            multiple
                                                                            id={'complianceImage'}
                                                                            name={'complianceImage'}
                                                                            accept={'.png,.jpeg,.jpg'}
                                                                            className="browse-input"
                                                                            onClick={(e) => {
                                                                                (e.target as HTMLInputElement).value =
                                                                                    '';
                                                                            }}
                                                                            onChange={(e) => {
                                                                                fileUpload(e);
                                                                            }}
                                                                        ></input>
                                                                        <button
                                                                            className="app-btn app-btn-secondary icon-button browse-btn"
                                                                            title="Upload file"
                                                                            type="button"
                                                                        >
                                                                            <svg className="svg-icon plus-icon">
                                                                                <use xlinkHref="#plusIcon">
                                                                                    <title>Upload Image</title>
                                                                                </use>
                                                                            </svg>
                                                                            <span className="button-text upload-btn">
                                                                                Upload Image
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    <div className="heading-type-1 file-size">
                                                                        <p>( Upload JPEG, PNG file less than 25 mb )</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {complianceImages?.map((res: any, index: number) => (
                                                                <div key={index} className="attachment-items-holder">
                                                                    <div className="attachment-icon-holder">
                                                                        <div className="image-holder">
                                                                            <a href="#">
                                                                                <img
                                                                                    onClick={() => {
                                                                                        setCurrentImageIndex(index);
                                                                                        setShowImageViewer(true);
                                                                                    }}
                                                                                    className="logo-icon"
                                                                                    src={
                                                                                        res?.fileUrl
                                                                                            ? res?.fileUrl
                                                                                            : imageLoader
                                                                                    }
                                                                                    alt="Image"
                                                                                />
                                                                            </a>
                                                                        </div>
                                                                        <div className="filename-folder">
                                                                            <div
                                                                                className="description-type-1 file-uploaded"
                                                                                title="File"
                                                                            >
                                                                                {res?.fileName?.length > 20
                                                                                    ? `${res?.fileName?.slice(
                                                                                          0,
                                                                                          20
                                                                                      )}...`
                                                                                    : res?.fileName}
                                                                            </div>
                                                                            <div className="heading-type-1">
                                                                                {getFileSizeOfUploadedFile(
                                                                                    res?.fileSize
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <a
                                                                        onClick={() => removeExistingFile(index)}
                                                                        className="file-remove-link read-all-link"
                                                                        title="Remove"
                                                                    >
                                                                        Remove
                                                                    </a>
                                                                </div>
                                                            ))}
                                                            {imageURLS?.length > 0 &&
                                                                imageURLS?.map((res: any, index: number) => (
                                                                    <div
                                                                        key={index}
                                                                        className="attachment-items-holder"
                                                                    >
                                                                        <div className="attachment-icon-holder">
                                                                            <div className="image-holder">
                                                                                <a href="#">
                                                                                    <img
                                                                                        onClick={() => {
                                                                                            setCurrentImageIndex(index);
                                                                                            setShowImageViewer(true);
                                                                                        }}
                                                                                        className="logo-icon"
                                                                                        src={res?.fileUrl}
                                                                                        alt="Image"
                                                                                    />
                                                                                </a>
                                                                            </div>
                                                                            <div className="filename-folder">
                                                                                <div
                                                                                    className="description-type-1 file-uploaded"
                                                                                    title="File"
                                                                                >
                                                                                    {res?.fileName?.length > 20
                                                                                        ? `${res?.fileName?.slice(
                                                                                              0,
                                                                                              20
                                                                                          )}...`
                                                                                        : res?.fileName}
                                                                                </div>
                                                                                <div className="heading-type-1">
                                                                                    {getFileSize(res?.fileSize)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <a
                                                                            onClick={() => removeFile(index)}
                                                                            className="file-remove-link read-all-link"
                                                                            title="Remove"
                                                                        >
                                                                            Remove
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => setComplianceModalVisibility(false)}
                                    className="app-btn app-btn-secondary modal-btn footer-btn"
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <button
                                    onClick={() => onSubmit()}
                                    disabled={checkSubmit()}
                                    className="app-btn app-btn-primary modal-btn footer-btn"
                                >
                                    <span className="button-text vendor-button-text">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ImageView
                        imageViewerModalVisibility={showImageViewer}
                        setImageViewerModalVisibility={setShowImageViewer}
                        originalData={[...complianceImages, ...imageURLS]}
                        currentImageIndex={currentImageIndex}
                        setCurrentImageIndex={setCurrentImageIndex}
                    />
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
