import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { success, Position, error } from 'src/utils/toast';
import {
    deleteBookingProposal,
    resetDeleteResult,
} from 'src/store/reducers/bookingProposal/bookingProposalListReducer';
import { RootState } from 'src/store/reducers';
interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    bookingNumber: any;
    setDeleteResponse: (value: string) => void;
}

const Index: React.FC<ModalProps> = ({ setModalView, bookingNumber, setDeleteResponse }) => {
    const { id } = useParams();
    const { deleteApiResponse } = useSelector((state: RootState) => state.bookingProposalList);

    const dispatch = useDispatch();
    const [reason, setReason] = useState('');
    const [confirmValue, setConfirmValue] = useState('');

    const bookingDeletion = () => {
        const fromBcn = location.pathname.includes('/bcnBookingList') ? true : false;
        dispatch(deleteBookingProposal({ data: { reason: reason }, bpPk: id, fromBcn }));
    };

    useEffect(() => {
        if (deleteApiResponse?.data?.length > 0) {
            if (deleteApiResponse.data[0]?.error?.status && deleteApiResponse.data[0]?.error?.status !== 200) {
                error(deleteApiResponse.data[0].stack, Position.TOP_RIGHT);
                dispatch(resetDeleteResult({}));
                setModalView(false);
            } else if (deleteApiResponse?.status === 200) {
                setDeleteResponse(deleteApiResponse.message || 'Y');
                setModalView(false);
                dispatch(resetDeleteResult({}));
            }
        }
    }, [deleteApiResponse]);

    return (
        <div className="app-modal">
            <div className="modal-content-holder large-popup reject-booking-modal">
                <div className="modal-header">
                    <div className="title">Delete Booking Proposal</div>
                    <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="layout-holder two-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Booking Number</h6>
                                <div className="description-type-1">{bookingNumber}</div>
                            </div>
                        </div>
                        <div className="layout-holder one-column">
                            <div className="warning-text-holder">
                                <div className="warning-icon-text-wrapper prompt-warning-wrapper">
                                    Warning: You are about to delete a Booking Proposal, Please confirm the reason for
                                    this action
                                </div>
                            </div>
                        </div>

                        <div className=" modal-input-holder">
                            <label htmlFor="name" className="modal-input-label">
                                Reason<span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-field-holder">
                                <textarea
                                    style={{ width: '100%', height: '10vh' }}
                                    name="reason"
                                    className="input-text"
                                    placeholder="Enter the reason"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    maxLength={250}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer prompts-footer-modal">
                    <div className="po-input-footer">
                        <span style={{ marginRight: '5px' }} className="po-input-footer-confirm">
                            Confirm Booking Number
                        </span>
                        <input
                            placeholder="Booking Number"
                            type="text"
                            value={confirmValue}
                            className="search-input input-text"
                            onChange={(e) => setConfirmValue(e.target.value)}
                        />
                    </div>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Submit"
                        disabled={!(confirmValue === bookingNumber && reason?.length > 0)}
                        onClick={() => {
                            bookingDeletion();
                        }}
                    >
                        OK
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
