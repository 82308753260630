import moment from 'moment';

const baseURL = process.env.REACT_APP_BASE_URL;

export const trackingPerformanceMatrixList = [
    {
        title: 'Customer Service',
        id: 'customer-service',
        name: 'customer-service',
        columns: [
            {
                field: 'name',
                title: 'User',
            },
            {
                field: 'clients',
                title: 'Clients',
                width: '120px',
            },
            {
                field: 'pos',
                title: 'POs',
            },
            {
                field: 'bps',
                title: 'BPs',
            },
            {
                field: 'teu',
                title: 'TEU',
            },
            {
                field: 'avgApprovalTime',
                title: 'Average Approval Time (Hrs)',
                width: '250px',
            },
            {
                field: 'kpi',
                title: 'KPI Achievement',
                width: '180px',
            },
            {
                field: 'quotes',
                title: 'Quotes',
                width: '120px',
            },
            {
                field: 'sops',
                title: 'SOP',
            },
        ],
        fields: {
            name: { type: 'string' },
            clients: { type: 'string' },
            pos: { type: 'number' },
            bps: { type: 'number' },
            teu: { type: 'number' },
            avgApprovalTime: { type: 'number' },
            kpi: { type: 'number' },
            quotes: { type: 'number' },
            sops: { type: 'number' },
        },
        url: `${baseURL}/api/bkg/tracking/pm`,
        fileName: 'customer-service',
        server: 'internal',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        title: 'Customs',
        id: 'customs',
        name: 'customs',
        columns: [
            {
                field: 'name',
                title: 'Name',
            },
            {
                field: 'clients',
                title: 'Clients',
            },
            {
                field: 'pos',
                title: 'POs',
            },
            {
                field: 'bps',
                title: 'BPs',
            },
            {
                field: 'teu',
                title: 'TEU',
            },
            {
                field: 'avgApprovalTime',
                title: 'Average Approval Time',
                width: '200px',
            },
            {
                field: 'kpi',
                title: 'KPI',
            },
            {
                field: 'quotes',
                title: 'Quotes',
            },
            {
                field: 'sops',
                title: 'SOPs',
            },
        ],
        fields: {
            name: { type: 'string' },
            clients: { type: 'string' },
            pos: { type: 'number' },
            bps: { type: 'number' },
            teu: { type: 'number' },
            avgApprovalTime: { type: 'number' },
            kpi: { type: 'number' },
            quotes: { type: 'number' },
            sops: { type: 'number' },
        },
        url: `${baseURL}/api/bkg/tracking/pm`,
        fileName: 'customs',
        server: 'internal',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        title: 'Operations',
        id: 'operations',
        name: 'operations',
        columns: [
            {
                field: 'name',
                title: 'Name',
            },
            {
                field: 'clients',
                title: 'Clients',
            },
            {
                field: 'pos',
                title: 'POs',
            },
            {
                field: 'bps',
                title: 'BPs',
            },
            {
                field: 'teu',
                title: 'TEU',
            },
            {
                field: 'avgApprovalTime',
                title: 'Average Approval Time',
                width: '200px',
            },
            {
                field: 'kpi',
                title: 'KPI',
            },
            {
                field: 'quotes',
                title: 'Quotes',
            },
            {
                field: 'sops',
                title: 'SOPs',
            },
        ],
        fields: {
            name: { type: 'string' },
            clients: { type: 'string' },
            pos: { type: 'number' },
            bps: { type: 'number' },
            teu: { type: 'number' },
            avgApprovalTime: { type: 'number' },
            kpi: { type: 'number' },
            quotes: { type: 'number' },
            sops: { type: 'number' },
        },
        url: `${baseURL}/api/bkg/tracking/pm`,
        fileName: 'operations',
        server: 'internal',
        method: 'POST',
        contentType: 'application/json',
    },
];

export const filterValues = [
    {
        label: moment().format('YYYY'),
        value: 'first',
        startDate: moment().startOf('year').format('YYYY-MM-DD'),
        endDate: moment().endOf('year').format('YYYY-MM-DD'),
    },
    {
        label: moment().subtract(1, 'year').format('YYYY'),
        value: 'second',
        startDate: moment().subtract(1, 'year').startOf('year').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'year').endOf('year').format('YYYY-MM-DD'),
    },
    {
        label: moment().subtract(2, 'year').format('YYYY'),
        value: 'third',
        startDate: moment().subtract(2, 'year').startOf('year').format('YYYY-MM-DD'),
        endDate: moment().subtract(2, 'year').endOf('year').format('YYYY-MM-DD'),
    },
];

export const trackingPerformanceMatrixPermissions = ['executive', 'cs team manager'];
