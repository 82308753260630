import { get } from '../HttpMethods';

export const getLayouts = async (payload: any) => {
    try {
        const res = await get(
            `/api/mgt/user-layout/layout?userName=${payload.username}&viewName=${payload.view}`,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};
