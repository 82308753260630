import React, { useState } from 'react';
import './po-basic-modal.scss';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import { useFormik } from 'formik';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import CancelModal from './cancelModal';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { additionalPo } from '../../../store/reducers/purchase_order/basicDetails';
import { useDispatch } from 'react-redux';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
/* eslint-disable no-undefined */

interface BioProps {
    displayFn: any;
    display: boolean;
    data1: any;
    data2: any;
    id?: string;
    edit: boolean;
    editData?: any;
    setisEdit: any;
}
interface additionalForm {
    poId: string;
    promRefNo: string;
    pickGroup: string;
    brandName: string;
    currency: string;
    buyerUserName: string;
    impVendorCode: string;
    impShipmentRefNo: string;
    distribOrderNo: string;
    buyerUserId: string;
    currencyCode: string;
    department: string;
    subCategory: string;
}
/* eslint no-var: off */

const Index: React.FC<BioProps> = (props) => {
    var editDatas = [];
    editDatas = props.editData;
    const { data } = useSelector((state: RootState) => state.purchaseOrder);
    const [checkBox, setcheckBox] = useState({
        promo: props.editData.promRefNo !== null && props.edit ? true : false,
        pickGroup: props.editData.pickGroup !== null && props.edit ? true : false,
        brand: props.editData.brandName !== null && props.edit ? true : false,
        currency: props.editData.currency && props.edit ? true : false,
        buyerUserName: props.editData.buyerUserName !== null && props.edit ? true : false,
        vendorCode: props.editData.impVendorCode !== null && props.edit ? true : false,
        shipmentRef: props.editData.impShipmentRefNo !== null && props.edit ? true : false,
        distributer: props.editData.distribOrderNo !== null && props.edit ? true : false,
        department: props.editData.department !== null && props.edit ? true : false,
        subCategory: props.editData.subCategory !== null && props.edit ? true : false,
    });
    const [cancelModal, setCancelModal] = useState(false);
    const [isSaving, setisSaving] = useState(false);
    const [_editingFlag, seteditingFlag] = useState(false);
    const dispatch = useDispatch();
    const initialValues: additionalForm = {
        poId: props.id as any,
        promRefNo: (editDatas.promRefNo && checkBox.promo && editDatas.promRefNo) || null,
        pickGroup: (editDatas.pickGroup && checkBox.pickGroup && editDatas.pickGroup) || null,
        brandName: (editDatas.brandName && checkBox.brand && editDatas.brandName) || null,
        currency: (editDatas.currency?.currencyName && checkBox.currency && editDatas.currency?.currencyName) || null,
        buyerUserName: (editDatas.buyerUserName && checkBox.buyerUserName && editDatas.buyerUserName) || null,
        buyerUserId: (editDatas.buyerUserId && checkBox.buyerUserName && editDatas.buyerUserId) || null,
        impVendorCode: (editDatas.impVendorCode && checkBox.vendorCode && editDatas.impVendorCode) || null,
        impShipmentRefNo: (editDatas.impShipmentRefNo && checkBox.shipmentRef && editDatas.impShipmentRefNo) || null,
        distribOrderNo: (editDatas.distribOrderNo && checkBox.distributer && editDatas.distribOrderNo) || null,
        department: (editDatas.department && checkBox.department && editDatas.department) || null,
        subCategory: (editDatas.subCategory && checkBox.subCategory && editDatas.subCategory) || null,
        currencyCode:
            (editDatas.currency?.currencycode && checkBox.currency && editDatas.currency?.currencycode) || null,
    };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        //validationSchema: BasicAdditionSchema,
        onSubmit: (_values) => {
            validation();
        },
    });
    const onConfirm = () => {
        props.displayFn(false);
        props.setisEdit(false);
    };
    const onCancel = () => {
        setCancelModal(false);
    };
    const messages = {
        promRefNo: 'Promotional ref no is required',
        pickGroup: 'Pick group is required',
        brandName: 'Brand name is required',
        currency: 'Order currency is required',
        buyerUserName: 'Buyer’s name is required',
        impVendorCode: 'Importer’s vendor code is required',
        impShipmentRefNo: 'Importer’s shipment ref no is required',
        distribOrderNo: 'Distribution order no is required',
        department: 'Department is required',
        subCategory: 'Sub Category is required',
    };
    const validation = () => {
        setisSaving(false);
        if (
            (formik.values.promRefNo === '' && checkBox.promo === true) ||
            (formik.values.pickGroup === '' && checkBox.pickGroup === true) ||
            (formik.values.brandName === '' && checkBox.brand === true) ||
            (formik.values.currency === '' && checkBox.currency === true) ||
            (formik.values.buyerUserName === '' && checkBox.buyerUserName === true) ||
            (formik.values.impVendorCode === '' && checkBox.vendorCode === true) ||
            (formik.values.impShipmentRefNo === '' && checkBox.shipmentRef === true) ||
            (formik.values.distribOrderNo === '' && checkBox.distributer === true) ||
            (formik.values.department === '' && checkBox.department === true) ||
            (formik.values.subCategory === '' && checkBox.subCategory === true)
        ) {
            setisSaving(true);
        } else {
            dispatch(additionalPo({ payload: formik.values, id: props.id }));
            formik.resetForm();
            props.displayFn(false);
            //props.setisEdit(false);
        }
    };
    const enableSave = () => {
        var flag = false;
        if (
            (!formik.values.promRefNo && checkBox.promo === true) ||
            (!formik.values.pickGroup && checkBox.pickGroup === true) ||
            (!formik.values.department && checkBox.department === true) ||
            (!formik.values.subCategory && checkBox.subCategory === true) ||
            (!formik.values.brandName && checkBox.brand === true) ||
            (!formik.values.currency && checkBox.currency === true) ||
            (!formik.values.buyerUserName && checkBox.buyerUserName === true) ||
            (!formik.values.impVendorCode && checkBox.vendorCode === true) ||
            (!formik.values.impShipmentRefNo && checkBox.shipmentRef === true) ||
            (!formik.values.distribOrderNo && checkBox.distributer === true) ||
            (formik.values.distribOrderNo && props.editData.distributer) ||
            (checkBox.promo === false &&
                checkBox.pickGroup === false &&
                checkBox.department === false &&
                checkBox.subCategory === false &&
                checkBox.promo === false &&
                checkBox.brand === false &&
                checkBox.currency === false &&
                checkBox.buyerUserName === false &&
                checkBox.vendorCode === false &&
                checkBox.shipmentRef === false &&
                checkBox.distributer === false &&
                !props.edit) ||
            (props.edit &&
                !formik.dirty &&
                (props.editData.promRefNo ? formik.values.promRefNo === props.editData.promRefNo : true) &&
                (props.editData.pickGroup ? formik.values.pickGroup === props.editData.pickGroup : true) &&
                (props.editData.department ? formik.values.department === props.editData.department : true) &&
                (props.editData.subCategory ? formik.values.subCategory === props.editData.subCategory : true) &&
                (props.editData.brandName ? formik.values.brandName === props.editData.brandName : true) &&
                (props.editData?.currency?.currencyName
                    ? formik.values.currency === props.editData?.currency?.currencyName
                    : true) &&
                (props.editData.buyerUserName ? formik.values.buyerUserName === props.editData.buyerUserName : true) &&
                (props.editData.impVendorCode ? formik.values.impVendorCode === props.editData.impVendorCode : true) &&
                (props.editData.impShipmentRefNo
                    ? formik.values.impShipmentRefNo === props.editData.impShipmentRefNo
                    : true) &&
                (props.editData.distribOrderNo ? formik.values.distribOrderNo === props.editData.distribOrderNo : true))
        ) {
            flag = true;
        } else {
            flag = false;
        }
        return flag;
    };

    useBrowserBackCheck(formik.dirty, () => setCancelModal(true));

    return (
        <div className="app-modal purchase-detail-modal">
            <div className="modal-content-holder medium-popup ">
                <div className="modal-header">
                    <div className="title">Additional Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            !formik.dirty ? props.displayFn(false) : setCancelModal(true);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="modal-form layout-holder one-column"
                            id="basicForm"
                        >
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            checked={checkBox.promo}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.promo);
                                                setcheckBox({ ...checkBox, promo: e.currentTarget.checked });
                                                formik.values.promRefNo = '';
                                            }}
                                            className="checkbox-input"
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Promotional Ref No.</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Promotional Ref No."
                                            type="text"
                                            className="modal-input input-text"
                                            disabled={!checkBox.promo}
                                            name="promRefNo"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.promRefNo}
                                        ></input>
                                        {isSaving && checkBox.promo && formik.values.promRefNo === '' ? (
                                            <div className="error-text">{messages.promRefNo}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={checkBox.pickGroup}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.pickGroup);
                                                setcheckBox({ ...checkBox, pickGroup: e.currentTarget.checked });
                                                formik.values.pickGroup = '';
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Pick Group</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Pick Group"
                                            type="text"
                                            className="modal-input input-text"
                                            disabled={!checkBox.pickGroup}
                                            name="pickGroup"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.pickGroup}
                                        ></input>
                                        {isSaving && checkBox.pickGroup && formik.values.pickGroup === '' ? (
                                            <div className="error-text">{messages.pickGroup}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={checkBox.brand}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.brand);
                                                setcheckBox({ ...checkBox, brand: e.currentTarget.checked });
                                                formik.values.brandName = '';
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Brand Name</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Brand Name"
                                            type="text"
                                            className="modal-input input-text"
                                            disabled={!checkBox.brand}
                                            name="brandName"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.brandName}
                                        ></input>
                                        {isSaving && checkBox.brand && formik.values.brandName === '' ? (
                                            <div className="error-text">{messages.brandName}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={checkBox.currency}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.currency);
                                                setcheckBox({ ...checkBox, currency: e.currentTarget.checked });
                                                formik.values.currency = '';
                                                formik.setFieldValue(formik.values.currency, '');
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Order Currency</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={(data as any).currency}
                                            placeholder="Select Order Currency"
                                            formik={formik}
                                            fieldName="currency"
                                            isDisabled={!checkBox.currency}
                                            extraField="currencyCode"
                                        />
                                        {isSaving && checkBox.currency && formik.values.currency === '' ? (
                                            <div className="error-text">{messages.currency}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={checkBox.buyerUserName}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.buyerUserName);
                                                setcheckBox({ ...checkBox, buyerUserName: e.currentTarget.checked });
                                                formik.values.buyerUserName = '';
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Buyer</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={props.data1}
                                            placeholder="Select Buyer"
                                            formik={formik}
                                            fieldName="buyerUserId"
                                            extraField="buyerUserName"
                                            isDisabled={!checkBox.buyerUserName}
                                        />
                                        {isSaving && checkBox.buyerUserName && formik.values.buyerUserName === '' ? (
                                            <div className="error-text">{messages.buyerUserName}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={checkBox.vendorCode}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.vendorCode);
                                                setcheckBox({ ...checkBox, vendorCode: e.currentTarget.checked });
                                                formik.values.impVendorCode = '';
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Importer’s Vendor Code</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Importer’s Vendor Code"
                                            type="text"
                                            className="modal-input input-text"
                                            name="impVendorCode"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.impVendorCode}
                                            disabled={!checkBox.vendorCode}
                                        ></input>
                                        {isSaving && checkBox.vendorCode && formik.values.impVendorCode === '' ? (
                                            <div className="error-text">{messages.impVendorCode}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={checkBox.shipmentRef}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.shipmentRef);
                                                setcheckBox({ ...checkBox, shipmentRef: e.currentTarget.checked });
                                                formik.values.impShipmentRefNo = '';
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Importer’s Shipment Ref No.</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Importer’s Shipment Ref No."
                                            type="text"
                                            className="modal-input input-text"
                                            name="impShipmentRefNo"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.impShipmentRefNo}
                                            disabled={!checkBox.shipmentRef}
                                        ></input>
                                        {isSaving && checkBox.shipmentRef && formik.values.impShipmentRefNo === '' ? (
                                            <div className="error-text">{messages.impShipmentRefNo}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            checked={checkBox.distributer}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.distributer);
                                                setcheckBox({ ...checkBox, distributer: e.currentTarget.checked });
                                                formik.values.distribOrderNo = '';
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Distribution Order No.</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Distribution Order No."
                                            type="text"
                                            className="modal-input input-text"
                                            name="distribOrderNo"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.distribOrderNo}
                                            disabled={!checkBox.distributer}
                                        ></input>
                                        {isSaving && checkBox.distributer && formik.values.distribOrderNo === '' ? (
                                            <div className="error-text">{messages.distribOrderNo}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            checked={checkBox.department}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.department);
                                                setcheckBox({ ...checkBox, department: e.currentTarget.checked });
                                                formik.values.department = '';
                                            }}
                                            className="checkbox-input"
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Department</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Department"
                                            type="text"
                                            className="modal-input input-text"
                                            disabled={!checkBox.department}
                                            name="department"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.department}
                                        ></input>
                                        {isSaving && checkBox.department && formik.values.department === '' ? (
                                            <div className="error-text">{messages.department}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder checkbox-layout-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            checked={checkBox.subCategory}
                                            onChange={(e) => {
                                                setisSaving(false);
                                                seteditingFlag(checkBox.subCategory);
                                                setcheckBox({ ...checkBox, subCategory: e.currentTarget.checked });
                                                formik.values.subCategory = '';
                                            }}
                                            className="checkbox-input"
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Sub Category</div>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Sub Category"
                                            type="text"
                                            className="modal-input input-text"
                                            disabled={!checkBox.subCategory}
                                            name="subCategory"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.handleChange(event);
                                            }}
                                            value={formik.values.subCategory}
                                        ></input>
                                        {isSaving && checkBox.subCategory && formik.values.subCategory === '' ? (
                                            <div className="error-text">{messages.subCategory}</div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                        onClick={() => {
                            !formik.dirty ? props.displayFn(false) : setCancelModal(true);
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <form
                        id="basicForm"
                        onSubmit={formik.handleSubmit}
                        style={{ width: 'unset' }}
                        className="modal-form layout-holder one-column"
                    >
                        <button
                            className="app-btn app-btn-primary modal-btn footer-btn "
                            type="submit"
                            title="Save"
                            id="basicForm"
                            disabled={enableSave()}
                        >
                            <span className="button-text footer-button-text">Save</span>
                        </button>
                    </form>
                </div>
            </div>
            {cancelModal && <CancelModal onConfirm={onConfirm} onCancel={onCancel} />}
        </div>
    );
};
export default Index;
