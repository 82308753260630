/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import './contract-modal.scss';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_accordation.scss';
import '../../../../assets/scss/components/_modal.scss';
import { error, Position, success } from '../../../../utils/toast';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import Select from 'react-select';
import {
    createContractDetails,
    fetchContractDetailsList,
    selectContractDetailCurrentPage,
    selectContractDetailIsError,
    // selectContractDetailPerPageCount,
    // selectContractDetailUser,
    selectContractDetailError,
    updateContractDetails,
    selectContractDetailSuccess,
    clearFailure,
    selectContractDetailAuthor,
    // selectContractDetailAgentUsers,
    selectContractDetailIncoTerms,
    // selectContractDetailPort,
    selectContractDetailAllClient,
    selectContractDetailForwarderUser,
    selectContractDetailAgentUser,
} from '../../../../store/reducers/profiles/coloader/ContractDetailsReducer';
import {
    fileTypesInfoText,
    fileUploadInfoText,
    FILE_SIZE,
    // reactSelectStyle,
    SUPPORTED_FORMATS,
} from '../../../../utils/constants';
import DatePicker from '../../../../components/common/DatePicker';
import SearchableSelectBox from '../../../../components/common/SearchableSelectBox';
import CustomSearchableSelectBox from '../../../../components/common/SearchableSelectBox/custom';
import { downloadShippingLineContract } from '../../../../services/apis/profiles/coloader/contractDetailsApi';
import ShowForPermissions from '../../../../ShowForPermissions';
import { searchUnlocoList } from '../../../../store/reducers/createVendorReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import BSTooltip from 'src/components/common/BSTooltip';
interface ModalProps {
    display: boolean;
    isEdit: boolean;
    isFromView: boolean;
    setIsEdit: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setIsFromView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
    mode: string;
    setMode: any;
    setData: any;
    selectedHeader: any;
    selectedFilter: any;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    perPageCount: number;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setAddModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setRemoveModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setSelectedClient: any;
    setDeletedClient: any;
    allClientsData: any;
    setAllClientsData: any;
    primaryClientsData: any;
    setPrimaryClientsData: any;
    secondaryClientsData: any;
    setSecondaryClientsData: any;
    setFilterUsed: any;
    filterUsed: any;
    clientData: any;
}

interface ContractDetailForm {
    sd_docname: string;
    sd_version: string;
    sd_author: string;

    sl_isForwarder: boolean;
    allClients: boolean;
    sl_agent: string;
    sl_agentUser: string;
    ForwarderUser: string;

    sd_forwardersignatory: string;
    sd_shippingLineSignatory: string;
    sd_startdate: string;
    sd_enddate: string;
    sd_renewal: string;
    sd_status: string;
    sd_managedBy: any;
    sd_managedByName: string;
    totalTeuAllocated: string;
    portPairList: any;
    validClients: any;
    so_originPort: string;
    so_destinationPort: string;
    so_incoTerm: string;
    so_originPortPk: string;
    so_destinationPortPk: string;
    so_incoTermPk: string;
    so_teu: string;
    portPairsStatus: boolean;
    validClientsStatus: boolean;
    sd_file?: object | null;
    so_originPortCode?: string;
    so_destinationPortCode?: string;
}
const Index: React.FC<ModalProps> = (props) => {
    const { id } = useParams();
    // const navigate = useNavigate();
    // const [disable, setdisable] = useState(false);
    const [editPortPair, setEditPortPair] = useState(false);
    const [editPortPairIndex, setEditPortPairIndex] = useState(0);
    const [showPortPairError, setShowPortPairError] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showRenewal, setShowRenewal] = useState(false);
    const [showManagedBy, setShowManagedBy] = useState(false);
    const [portPairList, setPortPairList] = useState<any>([]);
    const [customStyle, setCustomStyle] = useState('initial');
    const [totalTeuAllocated, setTotalTeuAllocated] = useState(0);
    const [headerAccordion, setHeaderAccordion] = useState(true);
    const [headerFilled, setHeaderFilled] = useState(false);
    const [portAccordion, setPortAccordion] = useState(false);
    const [clientAccordion, setClientAccordion] = useState(false);
    const [attachmentAccordion, setAttachmentAccordion] = useState(false);
    // const [allClientsData, setAllClientsData] = useState<any>([]);
    // const [primaryClientsData, setPrimaryClientsData] = useState<any>([]);
    // const [secondaryClientsData, setSecondaryClientsData] = useState<any>([]);
    const [ports, setPorts] = useState<any>([]);
    const [destPorts, setDestPorts] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchDisplay, setSearchDisplay] = useState('');
    const { unLocoList } = useSelector((state: RootState) => state.vendorDetails);
    const { coloaderHeader } = useSelector((state: RootState) => state.coloader);
    const initialValues: ContractDetailForm = {
        sd_docname: props.data.sd_docname || '',
        sd_version: props.data.sd_version || '',
        sd_author: props.data.sd_author || '',

        sl_isForwarder: props.data.sl_isForwarder ?? true,
        allClients: props.data.allClients || false,
        ForwarderUser: props.data.ForwarderUser || '',
        sl_agentUser: props.data.sl_agentUser || '',
        sl_agent: props.data.sl_agent || '',

        sd_forwardersignatory: props.data.sd_forwardersignatory || '',
        sd_shippingLineSignatory: props.data.sd_shippingLineSignatory || '',
        sd_startdate: props.data.sd_startdate ? moment(props.data.sd_startdate).format('YYYY-MM-DD') : '',
        sd_enddate: props.data.sd_enddate ? moment(props.data.sd_enddate).format('YYYY-MM-DD') : '',
        sd_renewal: props.data.sd_renewal || '',
        sd_status: props.data.sd_status || '',
        sd_managedBy: props.data.sd_managedBy || 'Agent',
        sd_managedByName: props.data.sd_managedByName || '',
        totalTeuAllocated: props.data.totalTeuAllocated || 0,
        portPairList: [],
        validClients: {
            primaryClients: [],
            secondaryClients: [],
        },
        so_originPort: '',
        so_destinationPort: '',
        so_incoTerm: '',
        so_originPortPk: '',
        so_destinationPortPk: '',
        so_incoTermPk: '',
        so_teu: '',
        portPairsStatus: false,
        validClientsStatus: false,
        sd_file: null,
    };

    const validationForAddOrEdit = () => {
        const validation =
            props.mode === 'edit'
                ? Yup.mixed().notRequired()
                : Yup.mixed()
                      .required('File is required')
                      .test({
                          name: 'fileSize',
                          exclusive: false,
                          params: {},
                          message: 'Maximum file size is 25mb',
                          test: function (value) {
                              return value && value.size <= FILE_SIZE;
                          },
                      })
                      .test({
                          name: 'fileType',
                          exclusive: false,
                          params: {},
                          message: 'File type is not supported',
                          test: function (value) {
                              return value && SUPPORTED_FORMATS.includes(value.type);
                          },
                      });

        return validation;
    };

    const ContractDetailFormSchema = Yup.object().shape({
        sd_docname: Yup.string().required('Name is required'),
        sd_version: Yup.string().required('Version is required'),
        sd_author: Yup.string()
            .required('Author is required')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'Author and Forwarder Signatory cannot be same',
                test: function (value) {
                    return value !== this.parent.sd_forwardersignatory;
                },
            }),
        sl_agent: Yup.string().when('sl_isForwarder', {
            is: false,
            then: Yup.string()
                .required('Agent is required')
                .test({
                    name: 'same',
                    exclusive: false,
                    params: {},
                    message: 'Author name and Forwarder name cannot be same',
                    test: function (value) {
                        return value !== this.parent.sd_author;
                    },
                }),
        }),
        sl_agentUser: Yup.string().when('sl_isForwarder', {
            is: false,
            then: Yup.string().required('Agent user is required'),
        }),
        ForwarderUser: Yup.string().when('sl_isForwarder', {
            is: true,
            then: Yup.string().required('Forwarder user is required'),
        }),
        sd_forwardersignatory: Yup.string().required('Forwarder signatory is required'),
        sd_shippingLineSignatory: Yup.string().required('Coloader signatory is required'),
        sd_startdate: Yup.date().required('Start date is required'),
        sd_enddate: Yup.date()
            .required('End date is required')
            .min(Yup.ref('sd_startdate'), 'End date must be greater than start date')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End date must be greater than start date',
                test: function (value) {
                    const activeDate = moment(this.parent.sd_startdate).format('YYYY-MM-DD');
                    const sd_enddate = moment(value).format('YYYY-MM-DD');
                    return !moment(activeDate).isSame(moment(sd_enddate));
                },
            }),
        sd_renewal: Yup.string().required('Renewal is required'),
        // sd_managedByName: Yup.string().required('Managed by username is required'),
        sd_file: validationForAddOrEdit(),
    });

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectContractDetailIsError);
    // const contract = useAppSelector(selectContractDetailUser);
    const contractError = useAppSelector(selectContractDetailError);
    const contractSuccess = useAppSelector(selectContractDetailSuccess);

    const currentPage = useAppSelector(selectContractDetailCurrentPage);
    // const perPageCount = useAppSelector(selectContractDetailPerPageCount);

    const contractAuthor = useAppSelector(selectContractDetailAuthor);
    const contractForwarderUser = useAppSelector(selectContractDetailForwarderUser);
    const contractAgentUser = useAppSelector(selectContractDetailAgentUser);

    const contractAgents = contractAgentUser?.data?.map((ag: any) => ({
        label: ag.organisation.orgName,
        value: ag.organisation.orgName,
    }));

    const contractForwarderSignatory = useAppSelector(selectContractDetailAuthor);
    // const contractAgetnUsers = useAppSelector(selectContractDetailAgentUsers);
    const contractIncoTerms = useAppSelector(selectContractDetailIncoTerms);
    // const contractPort = useAppSelector(selectContractDetailPort);
    const allClient = useAppSelector(selectContractDetailAllClient);

    // const [selectedFilter, setselectedFilter] = useState<any | null>(null);

    const payload = {
        id,
        currentPage: currentPage,
        perPageCount: props.perPageCount,
        columnStatus: props.selectedHeader,
        statusFilter: props.selectedFilter,
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ContractDetailFormSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting && !props.isFromView) {
                setIsLoading(true);
                if (props.mode === 'add') {
                    const formData = new FormData();
                    values.portPairList = portPairList.map((res: any) => ({
                        originPort: JSON.parse(res.so_originPortPk)?.value,
                        destinationPort: JSON.parse(res.so_destinationPortPk)?.value,
                        incoTerm: res.so_incoTermPk,
                        teuAllocated: res.so_teu,
                    }));
                    values.validClients = {
                        primaryClients: props.primaryClientsData.map((res: any) => res.value),
                        secondaryClients: props.secondaryClientsData.map((res: any) => res.value),
                    };
                    const data = { ...values, sl_pk: id, or_PK: coloaderHeader?.or_PK };
                    delete data['sd_file'];
                    formData.append('document', new Blob([JSON.stringify(data)], { type: 'application/json' }));
                    formData.append('file', file, file.name);
                    dispatch(createContractDetails(formData));
                } else {
                    values.portPairList = portPairList.map((res: any) => ({
                        originPort: res.so_originPortPk.startsWith('{')
                            ? JSON.parse(res.so_originPortPk)?.value
                            : res.so_originPortPk,
                        destinationPort: res.so_destinationPortPk.startsWith('{')
                            ? JSON.parse(res.so_destinationPortPk)?.value
                            : res.so_destinationPortPk,
                        incoTerm: res.so_incoTermPk,
                        teuAllocated: res.so_teu,
                    }));

                    values.validClients = {
                        primaryClients: props.primaryClientsData.map((res: any) => res.value),
                        secondaryClients: props.secondaryClientsData.map((res: any) => res.value),
                    };
                    dispatch(updateContractDetails({ ...values, ad_PK: props.data.sd_sdpk, sl_pk: id }));
                }
            }
            props.setIsFromView(false);
        },
    });
    useEffect(() => {
        if (
            formik.values.sd_docname !== '' &&
            formik.values.sd_version !== '' &&
            formik.values.sd_author !== undefined &&
            formik.values.sd_author !== '' &&
            formik.values.sd_forwardersignatory !== undefined &&
            formik.values.sd_forwardersignatory !== '' &&
            formik.values.sd_shippingLineSignatory !== '' &&
            formik.values.sd_startdate !== '' &&
            formik.values.sd_enddate !== '' &&
            formik.values.sd_renewal !== '' &&
            ((formik.values.sl_isForwarder && formik.values.ForwarderUser) ||
                (!formik.values.sl_isForwarder && formik.values.sl_agentUser && formik.values.sl_agent))
        ) {
            setHeaderFilled(true);
        } else {
            setHeaderFilled(false);
        }
    }, [formik.values]);

    useEffect(() => {
        if (searchTerm?.trim().length > 2 || searchDisplay === 'Origin' || searchDisplay === 'Destination') {
            dispatch(searchUnlocoList({ searchTerm, service: 'mgt' }));
        }
    }, [searchTerm]);

    useEffect(() => {
        const portList = unLocoList?.map((option: any) => {
            return { value: option.portId, label: `${option.portName}(${option.portCode})` };
        });

        if (searchDisplay === 'Origin' && searchTerm.length > 0) {
            setPorts(portList);
        } else if (searchDisplay === 'Destination' && searchTerm.length > 0) {
            setDestPorts(portList);
        } else if (searchDisplay === 'Displayed' || searchTerm.length < 1) {
            setPorts([]);
            setDestPorts([]);
        }
    }, [unLocoList]);

    useEffect(() => {
        const headerTabs = [
            'sd_docname',
            'sd_version',
            'sd_author',
            'sd_forwardersignatory',
            'sd_shippingLineSignatory',
            'sd_startdate',
            'sd_enddate',
            'sd_renewal',
            'sd_status',
            'sd_managedByName',
        ];

        const hiddenItems = ['sd_author', 'sd_forwardersignatory', 'sd_renewal', 'sd_managedByName'];
        const nearestItem = ['sd_author_div', 'sd_forwardersignatory_div', 'sd_renewal_div', 'sd_managedByName_div'];
        if (!formik.isSubmitting) {
            return;
        }

        if (Object.keys(formik.errors).length > 0) {
            const fieldName = Object.keys(formik.errors)[0];
            const index = hiddenItems.indexOf(fieldName);
            const indexHeader = headerTabs.indexOf(fieldName);
            if (index !== -1) {
                document?.getElementById(nearestItem[index])?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else if (fieldName === 'sd_file') {
                setCustomStyle('auto');
                setHeaderAccordion(false);
                setPortAccordion(false);
                setClientAccordion(false);
                setAttachmentAccordion(true);
            } else {
                document?.getElementsByName(fieldName)[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
            if (indexHeader !== -1) {
                setCustomStyle('initial');
                setHeaderAccordion(true);
                setPortAccordion(false);
                setClientAccordion(false);
                setAttachmentAccordion(false);
            }
        }
    }, [formik]);

    useEffect(() => {
        setIsLoading(false);
        if (isError) {
            // if (contractError?.status != 404) {
            error(contractError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            if (!portPairList.length) {
                setCustomStyle('auto');
                setHeaderAccordion(false);
                setPortAccordion(true);
                setClientAccordion(false);
                setAttachmentAccordion(false);
            } else if (!props.primaryClientsData.length) {
                setCustomStyle('auto');
                setHeaderAccordion(false);
                setPortAccordion(false);
                setClientAccordion(true);
                setAttachmentAccordion(false);
            } else {
                setCustomStyle('initial');
                setHeaderAccordion(true);
                setPortAccordion(false);
                setClientAccordion(false);
                setAttachmentAccordion(false);
            }
            // }
        } else if (contractSuccess?.status === 200 || contractSuccess?.status === 201) {
            success(
                props.mode === 'add'
                    ? `${contractSuccess?.data?.contractName}, ${contractSuccess?.data?.version} has been added`
                    : `${contractSuccess?.data?.contractName}, ${contractSuccess?.data?.version} has been updated`,
                Position.TOP_RIGHT
            );
            dispatch(fetchContractDetailsList(payload));
            props.displayFunction(false);
            formik.resetForm();
            removeFile();
            dispatch(clearFailure([]));
        }
    }, [isError, contractSuccess, contractError]);

    const resetAll = () => {
        setPortPairList([]);
        props.setAllClientsData([]);
        props.setPrimaryClientsData([]);
        props.setSecondaryClientsData([]);
        const clientArray: any = [];
        for (let i = 0; i < allClient?.data?.info?.length; i++) {
            clientArray.push({
                value: allClient?.data?.info[i].clientId,
                label: allClient?.data?.info[i].orgName,
            });
            if (allClient?.data?.info[i].childClients.length) {
                for (let j = 0; j < allClient?.data?.info[i].childClients.length; j++) {
                    clientArray.push({
                        value: allClient?.data?.info[i].childClients[j].clientId,
                        label: allClient?.data?.info[i].childClients[j].orgName,
                    });
                }
            }
        }
        props.setAllClientsData(clientArray);
    };

    useEffect(() => {
        if (!props.isEdit) {
            setCustomStyle('initial');
            formik.resetForm();
            resetAll();
            removeFile();
            setEditPortPair(false);
        }
    }, [props.display]);

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            formik.setFieldValue('sd_file', e.target.files[0]);
            setFile(e.target.files[0]);
        }
    };

    const removeFile = () => {
        setFile(null);
        formik.setFieldValue('sd_file', null);
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };

    const [distanceForManagedBy, setDistanceForManagedBy] = useState(0);
    const [widthForManagedBy, setWidthForManagedBy] = useState(0);
    const resizeFilter = () => {
        const el_modal_body = document.getElementById('modal_body');
        let modalBodyDistance = 0;
        if (el_modal_body?.getBoundingClientRect().top) {
            modalBodyDistance = el_modal_body?.getBoundingClientRect().top - 40;
        }

        const el_ad_managed_by = document.getElementById('ad_managed_by_div');
        const el_managed_by_dropdown_div = document.getElementById('managed_by_dropdown_div');

        if (el_managed_by_dropdown_div?.getBoundingClientRect().width) {
            setWidthForManagedBy(el_managed_by_dropdown_div?.getBoundingClientRect().width);
        }
        if (el_ad_managed_by?.getBoundingClientRect().top) {
            setDistanceForManagedBy(el_ad_managed_by?.getBoundingClientRect().top - modalBodyDistance);
        }
    };

    useEffect(() => {
        resizeFilter();
        setHeaderAccordion(true);
        setPortAccordion(false);
        setClientAccordion(false);
        setAttachmentAccordion(false);
    }, [props.display]);

    useEffect(() => {
        if (props?.data?.coLoaderPortList) {
            const portArray = props?.data?.coLoaderPortList?.map((res: any) => ({
                so_originPort: res.so_originPort,
                so_destinationPort: res.so_destinationPort,
                so_incoTerm: contractIncoTerms?.data.find((element: any) => element.value === res.so_incoTerm)?.label,
                so_originPortPk: res.so_originPortId,
                so_destinationPortPk: res.so_destinationPortId,
                so_incoTermPk: res.so_incoTerm,
                so_teu: res.so_teu,
                so_originPortCode: res.so_originPortCode,
                so_destinationPortCode: res.so_destinationPortCode,
            }));
            setPortPairList(portArray);
        }
    }, [props.display, props.data.coLoaderPortList]);

    useEffect(() => {
        if (props?.data?.coLoaderClientList) {
            const primaryClient = props?.data?.coLoaderClientList
                ?.filter((item: any) => {
                    return item.isPrimaryClient === 1;
                })
                .map((res: any) => ({
                    label: res.clientName,
                    value: res.clientId,
                }));
            const secondaryClient = props?.data?.coLoaderClientList
                ?.filter((item: any) => {
                    return item.isPrimaryClient === 0;
                })
                .map((res: any) => ({
                    label: res.clientName,
                    value: res.clientId,
                }));

            props.setPrimaryClientsData(primaryClient);
            props.setSecondaryClientsData(secondaryClient);
        }
    }, [props.display, props.data.coLoaderClientList]);
    const portPairSave = () => {
        formik.setFieldValue('portPairsStatus', true);
        if (
            formik.values.so_originPort &&
            formik.values.so_destinationPort &&
            formik.values.so_incoTerm &&
            formik.values.so_destinationPortCode !== formik.values.so_originPortCode &&
            parseInt(formik.values.so_teu, 10) !== 0
        ) {
            setPortPairList(
                portPairList.map((res: any, i: number) => {
                    if (editPortPairIndex === i) {
                        return {
                            so_originPort: formik.values.so_originPort,
                            so_destinationPort: formik.values.so_destinationPort,
                            so_incoTerm: formik.values.so_incoTerm,
                            so_originPortPk: formik.values.so_originPortPk,
                            so_destinationPortPk: formik.values.so_destinationPortPk,
                            so_incoTermPk: formik.values.so_incoTermPk,
                            so_teu: formik.values.so_teu || '',
                            so_originPortCode: formik.values.so_originPortCode,
                            so_destinationPortCode: formik.values.so_destinationPortCode,
                        };
                    } else {
                        return res;
                    }
                })
            );
            formik.setFieldValue('so_originPort', '');
            formik.setFieldValue('so_destinationPort', '');
            formik.setFieldValue('so_incoTerm', '');
            formik.setFieldValue('so_originPortPk', '');
            formik.setFieldValue('so_destinationPortPk', '');
            formik.setFieldValue('so_incoTermPk', '');
            formik.setFieldValue('so_teu', '');
            setEditPortPair(false);
            setShowPortPairError(false);
        } else if (formik.values.so_destinationPortCode === formik.values.so_originPortCode) {
            formik.setFieldError('so_originPort', 'Origin and Destination cannot be same');
            setShowPortPairError(true);
        } else {
            setShowPortPairError(true);
        }
    };

    useEffect(() => {
        if (formik.values.so_originPort !== '' && formik.values.so_destinationPort !== '') {
            if (formik.values.so_destinationPortCode !== formik.values.so_originPortCode) {
                formik.setFieldError('so_originPort', '');
            } else {
                formik.setFieldError('so_originPort', 'Origin and Destination cannot be same');
            }
        }
    }, [formik.values]);
    // console.log(formik.values, 'formik');

    const portPairClear = (index: number) => {
        if (!editPortPair || index === editPortPairIndex) {
            formik.setFieldValue('portPairsStatus', true);
            setEditPortPair(false);
            if (index === editPortPairIndex) {
                formik.setFieldError('so_originPort', undefined);
                formik.setFieldError('so_destinationPort', undefined);
                formik.setFieldValue('so_originPort', '');
                formik.setFieldValue('so_destinationPort', '');
                formik.setFieldValue('so_incoTerm', '');
                formik.setFieldValue('so_originPortPk', '');
                formik.setFieldValue('so_originPortCode', '');
                formik.setFieldValue('so_destinationPortPk', '');
                formik.setFieldValue('so_destinationPortCode', '');
                formik.setFieldValue('so_incoTermPk', '');
                formik.setFieldValue('so_teu', '');
            }
            setPortPairList(portPairList.filter((item: any, i: number) => i !== index));
        }
    };
    const portPairEdit = (index: number, res: any) => {
        if (!editPortPair) {
            formik.setFieldValue('so_originPort', res.so_originPort);
            formik.setFieldValue('so_originPortPk', res.so_originPortPk);
            formik.setFieldValue('so_originPortCode', res.so_originPortCode);
            formik.setFieldValue('so_destinationPort', res.so_destinationPort);
            formik.setFieldValue('so_destinationPortCode', res.so_destinationPortCode);
            formik.setFieldValue('so_destinationPortPk', res.so_destinationPortPk);
            formik.setFieldValue('so_incoTerm', res.so_incoTerm);
            formik.setFieldValue('so_incoTermPk', res.so_incoTermPk);
            formik.setFieldValue('so_teu', res.so_teu);
            setEditPortPair(true);
            setEditPortPairIndex(index);
        }
    };

    useEffect(() => {
        let totalTeuAllocated = 0;
        const startDate = moment(formik.values.sd_startdate, 'YYYY-MM-DD');
        const endDate = moment(formik.values.sd_enddate, 'YYYY-MM-DD');
        const days = endDate.diff(startDate, 'days');
        for (let index = 0; index < portPairList.length; index++) {
            const { so_teu }: { so_teu: number } = portPairList[index];
            if (so_teu && days > 0) {
                totalTeuAllocated += (days / 7) * so_teu;
            }
        }
        // formik.setFieldValue('totalTeuAllocated', Math.round(totalTeuAllocated));
        setTotalTeuAllocated(Math.round(totalTeuAllocated));
    }, [portPairList, formik.values.totalTeuAllocated, formik.values.sd_startdate, formik.values.sd_enddate]);

    useEffect(() => {
        const clientArray: any = [];
        if (props.mode === 'add') {
            for (let i = 0; i < allClient?.data?.info?.length; i++) {
                clientArray.push({
                    value: allClient?.data?.info[i].clientId,
                    label: allClient?.data?.info[i].orgName,
                });
                if (allClient?.data?.info[i].childClients.length) {
                    for (let j = 0; j < allClient?.data?.info[i].childClients.length; j++) {
                        clientArray.push({
                            value: allClient?.data?.info[i].childClients[j].clientId,
                            label: allClient?.data?.info[i].childClients[j].orgName,
                        });
                    }
                }
            }
        } else {
            for (let i = 0; i < allClient?.data?.info?.length; i++) {
                const clientExists = props?.data?.coLoaderClientList.some(
                    (client: any) => client.clientId === allClient?.data?.info[i].clientId
                );
                if (!clientExists) {
                    clientArray.push({
                        value: allClient?.data?.info[i].clientId,
                        label: allClient?.data?.info[i].orgName,
                    });
                }
                if (allClient?.data?.info[i].childClients.length) {
                    for (let j = 0; j < allClient?.data?.info[i].childClients.length; j++) {
                        const childExists = props?.data?.coLoaderClientList.some(
                            (client: any) => client.clientId === allClient?.data?.info[i].childClients[j].clientId
                        );
                        if (!childExists) {
                            clientArray.push({
                                value: allClient?.data?.info[i].childClients[j].clientId,
                                label: allClient?.data?.info[i].childClients[j].orgName,
                            });
                        }
                    }
                }
            }
        }
        props.setAllClientsData(clientArray);
    }, [allClient, props.display, props.mode]);

    const contractAgentUsers =
        contractAgentUser?.data
            ?.find((ag: any) => ag.organisation.orgName === formik.values.sl_agent)
            ?.users?.map((au: any) => ({
                label: au.name,
                value: au.name,
            })) || [];

    const submitActive =
        !!(formik.values.sd_file || props.data.fileName) &&
        headerFilled &&
        portPairList?.length > 0 &&
        !!portPairList?.[0]?.so_originPort &&
        !editPortPair &&
        (props.primaryClientsData?.length > 0 || formik.values.allClients);
    return (
        <>
            {props.display ? (
                <div
                    onClick={() => {
                        setShowRenewal(false);
                        setShowManagedBy(false);
                    }}
                    className="app-modal large-popup"
                >
                    <div id="modal_body" className="modal-content-holder co-loader-content-holder large-popup">
                        <div className="modal-header">
                            {props.isFromView === false && props.isEdit === true ? (
                                <div className="title">Contract Details</div>
                            ) : (
                                <div className="title">{props.mode === 'add' ? 'Add' : 'Edit'} Contract</div>
                            )}
                            <button
                                onClick={() => {
                                    // props.displayFunction(false);
                                    props.setIsFromView(false);
                                    formik.dirty && !props.isEdit
                                        ? props.setDisplayCancel(true)
                                        : props.displayFunction(false);
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div
                            onScroll={() => {
                                setShowManagedBy(false);
                                resizeFilter();
                            }}
                            className="modal-content"
                        >
                            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                            <form id="my-form" action="" onSubmit={formik.handleSubmit}>
                                <div className="accordion-wrapper">
                                    <div className="accordion-section section-header">
                                        <input
                                            checked={headerAccordion}
                                            type="checkbox"
                                            className="accordion-radio"
                                            id="ac1"
                                            name="shipping-accordion-group"
                                            onChange={() => {
                                                setHeaderAccordion((prev: boolean) => !prev);
                                                setPortAccordion(false);
                                                setClientAccordion(false);
                                                setAttachmentAccordion(false);
                                                headerAccordion ? setCustomStyle('auto') : setCustomStyle('initial');
                                            }}
                                        ></input>
                                        <label className="accordion-title" htmlFor="ac1">
                                            <div className="label-header">
                                                <svg className="svg-icon accordion-arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>

                                                <span className="label-title">Header Information</span>
                                                {(headerFilled || props.isEdit) && (
                                                    <span>
                                                        <svg className="svg-icon badge-icon">
                                                            <use xlinkHref="#badgeIcon">
                                                                <title>Completed</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                )}
                                            </div>
                                        </label>
                                        <div
                                            style={{ overflow: customStyle === 'initial' ? 'initial' : 'auto' }}
                                            className="accordion-content"
                                        >
                                            <div className="modal-main-content-header">
                                                {/* ADD-CONTRACT-VIEW-HEADER-INFORMATION */}
                                                {props.isEdit ? (
                                                    <div className="details-box custom">
                                                        <div className="card-detail-col">
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">Name</h6>
                                                                <div className="details-box-content">
                                                                    {formik.values.sd_docname}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">Version</h6>
                                                                <div className="details-box-content">
                                                                    {formik.values.sd_version}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">Author</h6>
                                                                <div className="details-box-content">
                                                                    {formik.values.sd_author}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">
                                                                    Forwarder Signatory
                                                                </h6>
                                                                <div className="details-box-content">
                                                                    {formik.values.sd_forwardersignatory}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">
                                                                    Coloader Signatory
                                                                </h6>
                                                                <div className="details-box-content">
                                                                    {formik.values.sd_shippingLineSignatory}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">Start Date</h6>
                                                                <div className="details-box-content">
                                                                    {moment(props.data.sd_startdate).format(
                                                                        'DD-MM-YYYY'
                                                                    )}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">End Date</h6>
                                                                <div className="details-box-content">
                                                                    {moment(props.data.sd_enddate).format('DD-MM-YYYY')}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">Status</h6>
                                                                <div className="details-box-content">
                                                                    {props.data.sd_status}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">Renewal</h6>
                                                                <div className="details-box-content">
                                                                    {props.data.sd_renewal}
                                                                </div>
                                                            </div>
                                                            <div className="details-col box-content-holder">
                                                                <h6 className="details-box-heading">Managed by</h6>
                                                                <div className="details-box-content">
                                                                    <span>
                                                                        {props.data.sl_isForwarder
                                                                            ? 'Forwarder'
                                                                            : 'Agent'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {!props.data.sl_isForwarder && (
                                                                <>
                                                                    <div className="details-col box-content-holder">
                                                                        <h6 className="details-box-heading">
                                                                            Agent Name
                                                                        </h6>
                                                                        <div className="details-box-content">
                                                                            {props.data.sl_agent}
                                                                        </div>
                                                                    </div>
                                                                    <div className="details-col box-content-holder">
                                                                        <h6 className="details-box-heading">
                                                                            Agent User
                                                                        </h6>
                                                                        <div className="details-box-content">
                                                                            {props.data.sl_agentUser}
                                                                        </div>
                                                                    </div>
                                                                </>
                                                            )}
                                                            {props.data.sl_isForwarder && (
                                                                <div className="details-col box-content-holder">
                                                                    <h6 className="details-box-heading">
                                                                        Forwarder User
                                                                    </h6>
                                                                    <div className="details-box-content">
                                                                        {props.data.ForwarderUser}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="input-form-wrapper">
                                                        <div className="modal-form layout-holder  two-column">
                                                            <div className="modal-input-holder layout-item">
                                                                <label className="modal-input-label">
                                                                    Name<span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-layout-item">
                                                                    <input
                                                                        placeholder="Enter Contract Name"
                                                                        type="text"
                                                                        className="modal-input input-text"
                                                                        id="sd_docname"
                                                                        name="sd_docname"
                                                                        onChange={(event) => {
                                                                            formik.setFieldError(
                                                                                event.target.name,
                                                                                undefined
                                                                            );
                                                                            formik.handleChange(event);
                                                                        }}
                                                                        value={formik.values.sd_docname}
                                                                    ></input>
                                                                    {!props.isFromView && formik.errors.sd_docname ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.sd_docname}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className=" modal-input-holder layout-item">
                                                                <label className="modal-input-label">
                                                                    Version
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-layout-item">
                                                                    <input
                                                                        placeholder="Enter Version Name"
                                                                        type="text"
                                                                        className="modal-input input-text"
                                                                        id="sd_version"
                                                                        name="sd_version"
                                                                        onChange={(event) => {
                                                                            formik.setFieldError(
                                                                                event.target.name,
                                                                                undefined
                                                                            );
                                                                            formik.handleChange(event);
                                                                        }}
                                                                        value={formik.values.sd_version}
                                                                    ></input>
                                                                    {!props.isFromView && formik.errors.sd_version ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.sd_version}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className="modal-input-holder layout-item">
                                                                <label htmlFor="name" className="modal-input-label">
                                                                    Author
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-layout-item">
                                                                    <SearchableSelectBox
                                                                        data={
                                                                            contractAuthor?.data?.length > 0 &&
                                                                            contractAuthor?.data
                                                                        }
                                                                        placeholder="Enter Author Name to Select"
                                                                        formik={formik}
                                                                        fieldName="sd_author"
                                                                    />
                                                                    {!props.isFromView && formik.errors.sd_author ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.sd_author}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className=" modal-input-holder layout-item ">
                                                                <label htmlFor="name" className="modal-input-label">
                                                                    Forwarder Signatory
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-layout-item">
                                                                    <SearchableSelectBox
                                                                        data={
                                                                            contractForwarderSignatory?.data?.length >
                                                                                0 && contractForwarderSignatory?.data
                                                                        }
                                                                        placeholder="Enter Forwarder Signatory Name to Select"
                                                                        formik={formik}
                                                                        fieldName="sd_forwardersignatory"
                                                                    />
                                                                    {!props.isFromView &&
                                                                    formik.errors.sd_forwardersignatory ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.sd_forwardersignatory}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className=" modal-input-holder layout-item">
                                                                <label htmlFor="name" className="modal-input-label">
                                                                    Coloader Signatory
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-layout-item">
                                                                    <input
                                                                        placeholder="Enter Coloader Signatory name"
                                                                        type="text"
                                                                        className="modal-input input-text"
                                                                        id="sd_shippingLineSignatory"
                                                                        name="sd_shippingLineSignatory"
                                                                        onChange={(event) => {
                                                                            formik.setFieldError(
                                                                                event.target.name,
                                                                                undefined
                                                                            );
                                                                            formik.handleChange(event);
                                                                        }}
                                                                        value={formik.values.sd_shippingLineSignatory}
                                                                    ></input>
                                                                    {!props.isFromView &&
                                                                    formik.errors.sd_shippingLineSignatory ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.sd_shippingLineSignatory}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className=" modal-input-holder layout-item">
                                                                <label className="modal-input-label">
                                                                    Start Date
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>

                                                                <div className="modal-input-layout-item date-input-holder">
                                                                    <DatePicker
                                                                        formik={formik}
                                                                        fieldName="sd_startdate"
                                                                        portalId="date-portal"
                                                                        maxDate={moment(formik.values.sd_enddate)
                                                                            .subtract(1, 'day')
                                                                            .toDate()}
                                                                    />

                                                                    {!props.isFromView && formik.errors.sd_startdate ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.sd_startdate}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className=" modal-input-holder layout-item">
                                                                <label className="modal-input-label">
                                                                    End Date
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>

                                                                <div className="modal-input-layout-item date-input-holder">
                                                                    <DatePicker
                                                                        disabled={props.data.sd_status === 'Expired'}
                                                                        formik={formik}
                                                                        minDate={moment(formik.values.sd_startdate)
                                                                            .add(1, 'day')
                                                                            .toDate()}
                                                                        fieldName="sd_enddate"
                                                                        portalId="date-portal"
                                                                    />

                                                                    {!props.isFromView && formik.errors.sd_enddate ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.sd_enddate}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                            <div className=" modal-input-holder layout-item">
                                                                <label className="modal-input-label">
                                                                    Renewal Type
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div
                                                                    className={
                                                                        showRenewal
                                                                            ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                            : 'dropdown-wrap  menu-down modal-dropdown'
                                                                    }
                                                                >
                                                                    <button
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setShowRenewal((prev) => !prev);
                                                                            setShowManagedBy(false);
                                                                        }}
                                                                        type="button"
                                                                        className="menu-btn app-btn select-modal"
                                                                    >
                                                                        <span className="btn-text">
                                                                            {formik.values.sd_renewal}
                                                                        </span>
                                                                        <span className="placeholder-text">
                                                                            Select Renewal Type
                                                                        </span>
                                                                        <span className="dropdown-arrow">
                                                                            <svg className="svg-icon arrow-icon">
                                                                                <use xlinkHref="#downArrow">
                                                                                    <title>dropdown</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                    </button>

                                                                    <div className="dropdown-menu">
                                                                        {/* <a
                                                                            onClick={() => {
                                                                                formik.setFieldError(
                                                                                    'sd_renewal',
                                                                                    undefined
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    'sd_renewal',
                                                                                    'Auto'
                                                                                );
                                                                            }}
                                                                            className="menu-item app-btn"
                                                                        >
                                                                            <span className="main-content">Auto</span>
                                                                        </a> */}
                                                                        <a
                                                                            onClick={() => {
                                                                                formik.setFieldError(
                                                                                    'sd_renewal',
                                                                                    undefined
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    'sd_renewal',
                                                                                    'Manual'
                                                                                );
                                                                            }}
                                                                            className="menu-item app-btn"
                                                                        >
                                                                            <span className="main-content">Manual</span>
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                                {!props.isFromView && formik.errors.sd_renewal ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.sd_renewal}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div className="modal-input-holder layout-item">
                                                                <label htmlFor="name" className="modal-input-label">
                                                                    Managed By
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>
                                                                {/* added  a new field for coloader */}
                                                                <div className="filter-type-holder">
                                                                    <div className="checkbox-item">
                                                                        <label
                                                                            className="app-check-wrapper"
                                                                            onClick={() => {
                                                                                formik.setFieldValue(
                                                                                    'sl_isForwarder',
                                                                                    true
                                                                                );
                                                                                formik.setFieldValue('sl_agent', '');
                                                                                formik.setFieldValue(
                                                                                    'sl_agentUser',
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    'ForwarderUser',
                                                                                    ''
                                                                                );
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                className="checkbox-input"
                                                                                name="field-group"
                                                                                checked={formik.values.sl_isForwarder}
                                                                            ></input>
                                                                            <div className="checkmark">
                                                                                <svg className="svg-icon notification-circle-icon">
                                                                                    <use href="#circleIcon">
                                                                                        <title>check mark</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                            <div className="checkbox-label">
                                                                                Forwarder
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                    <div className="checkbox-item">
                                                                        <label
                                                                            className="app-check-wrapper"
                                                                            onClick={() => {
                                                                                formik.setFieldValue(
                                                                                    'sl_isForwarder',
                                                                                    false
                                                                                );
                                                                                formik.setFieldValue('sl_agent', '');
                                                                                formik.setFieldValue(
                                                                                    'sl_agentUser',
                                                                                    ''
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    'ForwarderUser',
                                                                                    ''
                                                                                );
                                                                            }}
                                                                        >
                                                                            <input
                                                                                type="radio"
                                                                                className="checkbox-input"
                                                                                name="field-group"
                                                                                checked={!formik.values.sl_isForwarder}
                                                                            ></input>
                                                                            <div className="checkmark">
                                                                                <svg className="svg-icon notification-circle-icon">
                                                                                    <use href="#circleIcon">
                                                                                        <title>check mark</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                            <div className="checkbox-label">Agent</div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className=" modal-input-holder layout-item">
                                                                <label className="modal-input-label"></label>
                                                                <div className="modal-input-layout-item"></div>
                                                            </div>
                                                            {!formik.values.sl_isForwarder && (
                                                                <div className=" modal-input-holder layout-item">
                                                                    <label className="modal-input-label">
                                                                        Agent
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </label>
                                                                    <div className="modal-input-layout-item">
                                                                        <SearchableSelectBox
                                                                            data={
                                                                                contractAgents?.length > 0 &&
                                                                                contractAgents
                                                                            }
                                                                            placeholder="Enter Agent Name to Select"
                                                                            formik={formik}
                                                                            fieldName="sl_agent"
                                                                        />
                                                                        {!props.isFromView && formik.errors.sl_agent ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.sl_agent}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {!formik.values.sl_isForwarder && (
                                                                <div className=" modal-input-holder layout-item">
                                                                    <label className="modal-input-label">
                                                                        Agent User
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </label>
                                                                    <div className="modal-input-layout-item">
                                                                        <SearchableSelectBox
                                                                            data={contractAgentUsers}
                                                                            placeholder="Enter Agent User Name to Select"
                                                                            formik={formik}
                                                                            fieldName="sl_agentUser"
                                                                        />
                                                                        {!props.isFromView &&
                                                                        formik.errors.sl_agentUser ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.sl_agentUser}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            )}
                                                            {formik.values.sl_isForwarder && (
                                                                <div className=" modal-input-holder layout-item">
                                                                    <label className="modal-input-label">
                                                                        Forwarder User
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </label>
                                                                    <div className="modal-input-layout-item">
                                                                        <SearchableSelectBox
                                                                            data={
                                                                                contractForwarderUser?.data?.length > 0
                                                                                    ? contractForwarderUser?.data
                                                                                    : []
                                                                            }
                                                                            placeholder="Enter Forwarder User Name to Select"
                                                                            formik={formik}
                                                                            fieldName="ForwarderUser"
                                                                        />
                                                                        {!props.isFromView &&
                                                                        formik.errors.ForwarderUser ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.ForwarderUser}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => setCustomStyle('auto')}
                                        className="accordion-section section-port"
                                    >
                                        <input
                                            checked={portAccordion}
                                            type="checkbox"
                                            className="accordion-radio"
                                            id="ac2"
                                            name="shipping-accordion-group"
                                            onChange={() => {
                                                setHeaderAccordion(false);
                                                if (headerFilled || props.isEdit) {
                                                    setPortAccordion((prev: boolean) => !prev);
                                                } else {
                                                    setPortAccordion(false);
                                                }
                                                setClientAccordion(false);
                                                setAttachmentAccordion(false);
                                            }}
                                        ></input>
                                        <label className="accordion-title" htmlFor="ac2">
                                            <div className="label-header">
                                                {headerFilled || props.isEdit ? (
                                                    <svg className="svg-icon accordion-arrow-icon">
                                                        <use xlinkHref="#downArrow">
                                                            <title>dropdown</title>
                                                        </use>
                                                    </svg>
                                                ) : (
                                                    <BSTooltip title=" Complete header information to add Port details">
                                                        <svg className="svg-icon accordion-cross-icon">
                                                            <use xlinkHref="#closecircleIcon">
                                                                <title>arrow</title>
                                                            </use>
                                                        </svg>
                                                    </BSTooltip>
                                                )}

                                                <span className="label-title">Port Pairs</span>
                                                {portPairList?.length > 0 &&
                                                    portPairList[0].so_originPort &&
                                                    !editPortPair && (
                                                        <svg className="svg-icon badge-icon">
                                                            <use xlinkHref="#badgeIcon">
                                                                <title>Completed</title>
                                                            </use>
                                                        </svg>
                                                    )}
                                            </div>
                                        </label>
                                        <div className="accordion-content">
                                            {/* SECTION-PORT-VIEW-OPTION */}
                                            {props.isEdit ? (
                                                <div className="grid-section modal-grid port-grid">
                                                    <div className="grid-holder">
                                                        <div className="grid-header">
                                                            <div className="content-heading-holder">
                                                                <span className="content-heading">
                                                                    Total TEU Allocated
                                                                </span>
                                                                <span className=" modal-toast">
                                                                    <div className="toast-message ">
                                                                        {totalTeuAllocated}
                                                                    </div>
                                                                </span>
                                                            </div>

                                                            {/* <button
                                                                className="app-btn app-btn-secondary icon-button  add-product-btn"
                                                                title="Add Pack"
                                                            >
                                                                <svg className="svg-icon add-btn-icon">
                                                                    <use xlinkHref="#plusIcon"></use>
                                                                </svg>
                                                                <span className="button-text footer-button-text">
                                                                    Add Port Pair
                                                                </span>
                                                            </button> */}
                                                        </div>
                                                        <div id="productForm" className="modal-form">
                                                            <div className="grid-container">
                                                                {!portPairList.length && (
                                                                    <div className="grid-no-content ">
                                                                        <svg className="svg-icon grid-no-content-icon ">
                                                                            <use xlinkHref="#gridNoContentIcon"></use>
                                                                        </svg>
                                                                        <p className="no-content-message">
                                                                            No Port Pair Added
                                                                        </p>
                                                                    </div>
                                                                )}
                                                                <div className="grid-scroll-content">
                                                                    <table className="grid-table">
                                                                        <thead>
                                                                            <tr className="grid-sticky-row">
                                                                                <th className="grid-cell-header grid-sticky-column sticky-left-aligned col-origin-port">
                                                                                    <div className="grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            Origin
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="grid-cell-header col-destination-port">
                                                                                    <div className=" grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            DESTINATION
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="grid-cell-header  col-inco-team">
                                                                                    <div className=" grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            INCO TERM
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="grid-cell-header  col-teu-allocation">
                                                                                    <div className=" grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            TEU ALLOCATED/WEEK
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {portPairList.map(
                                                                                (res: any, index: number) => (
                                                                                    <tr
                                                                                        key={index}
                                                                                        className="has-child row-expanded"
                                                                                    >
                                                                                        <td className="grid-cell  grid-sticky-column td-origin">
                                                                                            <div className="grid-cell-data">
                                                                                                {res.so_originPort &&
                                                                                                    `${res.so_originPort}(${res.so_originPortCode})`}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="grid-cell td-destination">
                                                                                            <div className="grid-cell-data">
                                                                                                {res.so_destinationPort &&
                                                                                                    `${res.so_destinationPort}(${res.so_destinationPortCode})`}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="grid-cell td-inco">
                                                                                            <div className="grid-cell-data">
                                                                                                {res.so_incoTerm}
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="grid-cell td-teu">
                                                                                            <div className="grid-cell-data">
                                                                                                {res.so_teu}
                                                                                            </div>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            )}

                                                                            {/* <tr className="has-child row-expanded">
                                                                                <td className="grid-cell  grid-sticky-column  td-orgin">
                                                                                    <div className="grid-cell-data">
                                                                                        CNYTN
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell td-description">
                                                                                    <div className="grid-cell-data">
                                                                                        AUMEL
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell td-inco">
                                                                                    <div className="grid-cell-data">
                                                                                        CIF
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell td-teu">
                                                                                    <div className="grid-cell-data">
                                                                                        7
                                                                                    </div>
                                                                                </td>
                                                                            </tr> */}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="grid-section modal-grid port-grid">
                                                    <div className="grid-holder">
                                                        <div className="grid-header">
                                                            <div className="content-heading-holder">
                                                                <span className="content-heading">
                                                                    Total TEU Allocated
                                                                </span>
                                                                <span className=" modal-toast">
                                                                    <div className="toast-message ">
                                                                        {totalTeuAllocated}
                                                                    </div>
                                                                </span>
                                                            </div>

                                                            <button
                                                                className="app-btn app-btn-secondary icon-button  add-product-btn"
                                                                title="Add Port Pair"
                                                                type="button"
                                                                onClick={() => {
                                                                    if (!editPortPair) {
                                                                        setPortPairList((prev: any) => [
                                                                            ...prev,
                                                                            {
                                                                                so_originPort: '',
                                                                                so_destinationPort: '',
                                                                                so_incoTerm: '',
                                                                                so_originPortPk: '',
                                                                                so_destinationPortPk: '',
                                                                                so_incoTermPk: '',
                                                                                so_teu: '',
                                                                            },
                                                                        ]);
                                                                        setEditPortPair(true);
                                                                        setEditPortPairIndex(portPairList.length);
                                                                        setShowPortPairError(false);
                                                                    }
                                                                }}
                                                            >
                                                                <svg className="svg-icon add-btn-icon">
                                                                    <use xlinkHref="#plusIcon"></use>
                                                                </svg>
                                                                <span className="button-text footer-button-text">
                                                                    Add Port Pair
                                                                </span>
                                                            </button>
                                                        </div>
                                                        <div id="productForm" className="modal-form">
                                                            <div className="grid-container">
                                                                {/* content when No data found in grid*/}
                                                                {!portPairList.length && (
                                                                    <div className="grid-no-content ">
                                                                        <svg className="svg-icon grid-no-content-icon ">
                                                                            <use xlinkHref="#gridNoContentIcon"></use>
                                                                        </svg>
                                                                        <p className="no-content-message">
                                                                            No Port Pair Added
                                                                        </p>
                                                                    </div>
                                                                )}
                                                                <div className="grid-scroll-content">
                                                                    <table className="grid-table">
                                                                        <thead>
                                                                            <tr className="grid-sticky-row">
                                                                                <th className="grid-cell-header grid-sticky-column sticky-left-aligned col-origin-port">
                                                                                    <div className="grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            Origin
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="grid-cell-header col-destination-port">
                                                                                    <div className=" grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            DESTINATION
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="grid-cell-header  col-inco-team">
                                                                                    <div className=" grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            INCO TERM
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="grid-cell-header  col-teu-allocation">
                                                                                    <div className=" grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            TEU ALLOCATED/WEEK
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                                <th className="grid-cell-header col-port-action">
                                                                                    <div className=" grid-cell-data">
                                                                                        <span className="header-title">
                                                                                            Action
                                                                                        </span>
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            <>
                                                                                {portPairList?.length > 0 &&
                                                                                    portPairList.map(
                                                                                        (res: any, index: number) =>
                                                                                            editPortPair &&
                                                                                            editPortPairIndex ===
                                                                                                index ? (
                                                                                                <tr
                                                                                                    key={index}
                                                                                                    className="has-child row-expanded align-top"
                                                                                                >
                                                                                                    <td className="grid-cell grid-sticky-column  td-orgin">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <div className="modal-input-layout-item">
                                                                                                                {/* <SearchableSelectBox
                                                                                                                    data={
                                                                                                                        contractPort
                                                                                                                            ?.data
                                                                                                                            ?.length >
                                                                                                                            0 &&
                                                                                                                        contractPort?.data
                                                                                                                    }
                                                                                                                    placeholder="Enter Port to select"
                                                                                                                    formik={
                                                                                                                        formik
                                                                                                                    }
                                                                                                                    extraField="so_originPort"
                                                                                                                    fieldName="so_originPortPk"
                                                                                                                    menuPosition="fixed"
                                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                                        'outside_div'
                                                                                                                    )}
                                                                                                                    closeMenuOnScroll={
                                                                                                                        true
                                                                                                                    }
                                                                                                                /> */}

                                                                                                                <Select
                                                                                                                    options={
                                                                                                                        ports
                                                                                                                    }
                                                                                                                    isClearable
                                                                                                                    placeholder="Enter Port to select"
                                                                                                                    onChange={(
                                                                                                                        option: any
                                                                                                                    ) => {
                                                                                                                        if (
                                                                                                                            option ===
                                                                                                                                null ||
                                                                                                                            searchDisplay ===
                                                                                                                                'Displayed' ||
                                                                                                                            searchDisplay ===
                                                                                                                                'Destination'
                                                                                                                        ) {
                                                                                                                            setDestPorts(
                                                                                                                                []
                                                                                                                            );
                                                                                                                            setPorts(
                                                                                                                                []
                                                                                                                            );
                                                                                                                            formik.setFieldError(
                                                                                                                                'so_originPortPk',
                                                                                                                                undefined
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_originPortPk',
                                                                                                                                undefined
                                                                                                                            );

                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_originPort',
                                                                                                                                undefined
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_originPortCode',
                                                                                                                                undefined
                                                                                                                            );
                                                                                                                        }
                                                                                                                        // eslint-disable-next-line no-undefined
                                                                                                                        else {
                                                                                                                            formik.setFieldError(
                                                                                                                                'so_originPortPk',
                                                                                                                                undefined
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_originPortPk',
                                                                                                                                JSON.stringify(
                                                                                                                                    option
                                                                                                                                )
                                                                                                                            );

                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_originPort',
                                                                                                                                option.label
                                                                                                                                    .toString()
                                                                                                                                    .split(
                                                                                                                                        '('
                                                                                                                                    )[0]
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_originPortCode',
                                                                                                                                option.label
                                                                                                                                    .toString()
                                                                                                                                    .split(
                                                                                                                                        '('
                                                                                                                                    )[1]
                                                                                                                                    .toString()
                                                                                                                                    .split(
                                                                                                                                        ')'
                                                                                                                                    )[0]
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    onFocus={() => {
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    onMenuOpen={() => {
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    onMenuClose={() => {
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        );
                                                                                                                        setPorts(
                                                                                                                            []
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    onBlur={() =>
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        )
                                                                                                                    }
                                                                                                                    onInputChange={(
                                                                                                                        text: string
                                                                                                                    ) => {
                                                                                                                        setSearchDisplay(
                                                                                                                            'Origin'
                                                                                                                        );
                                                                                                                        setSearchTerm(
                                                                                                                            text
                                                                                                                        );
                                                                                                                        setDestPorts(
                                                                                                                            []
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    noOptionsMessage={({
                                                                                                                        inputValue,
                                                                                                                    }) =>
                                                                                                                        inputValue?.length <
                                                                                                                        3
                                                                                                                            ? 'Enter at least 3 characters to search'
                                                                                                                            : 'No results found'
                                                                                                                    }
                                                                                                                    // noOptionsMessage={({
                                                                                                                    //     inputValue,
                                                                                                                    // }) =>
                                                                                                                    //     !inputValue
                                                                                                                    //         ? 'No results'
                                                                                                                    //         : ''
                                                                                                                    // }
                                                                                                                    onKeyDown={(
                                                                                                                        e
                                                                                                                    ) => {
                                                                                                                        if (
                                                                                                                            e.key ===
                                                                                                                            'Enter'
                                                                                                                        ) {
                                                                                                                            e.preventDefault();
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    id={
                                                                                                                        'so_originPortPk'
                                                                                                                    }
                                                                                                                    name={
                                                                                                                        'so_originPortPk'
                                                                                                                    }
                                                                                                                    // defaultInputValue={
                                                                                                                    //     res?.so_originPort &&
                                                                                                                    //     `${res?.so_originPort}(${res?.so_originPortCode})`
                                                                                                                    // }
                                                                                                                    // value={
                                                                                                                    //     formik.values[
                                                                                                                    //         'so_originPortPk'
                                                                                                                    //     ]?.startsWith(
                                                                                                                    //         '{'
                                                                                                                    //     ) &&
                                                                                                                    //     JSON.parse(
                                                                                                                    //         formik
                                                                                                                    //             .values[
                                                                                                                    //             'so_originPortPk'
                                                                                                                    //         ]
                                                                                                                    //     )
                                                                                                                    // }
                                                                                                                    value={
                                                                                                                        formik
                                                                                                                            .values
                                                                                                                            .so_originPort && {
                                                                                                                            label: `${formik.values['so_originPort']}(${formik.values['so_originPortCode']})`,

                                                                                                                            value: formik
                                                                                                                                .values[
                                                                                                                                'so_originPortPk'
                                                                                                                            ],
                                                                                                                        }
                                                                                                                    }
                                                                                                                    components={{
                                                                                                                        DropdownIndicator:
                                                                                                                            () =>
                                                                                                                                null,
                                                                                                                        IndicatorSeparator:
                                                                                                                            () =>
                                                                                                                                null,
                                                                                                                    }}
                                                                                                                />
                                                                                                                {showPortPairError &&
                                                                                                                !formik
                                                                                                                    .values
                                                                                                                    .so_originPort ? (
                                                                                                                    <div className="error-text">
                                                                                                                        This
                                                                                                                        field
                                                                                                                        is
                                                                                                                        required
                                                                                                                    </div>
                                                                                                                ) : formik
                                                                                                                      .errors
                                                                                                                      .so_originPort ? (
                                                                                                                    <div className="error-text">
                                                                                                                        {
                                                                                                                            formik
                                                                                                                                .errors
                                                                                                                                .so_originPort
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                ) : (
                                                                                                                    <>

                                                                                                                    </>
                                                                                                                )}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell td-description">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <div className="modal-input-layout-item">
                                                                                                                {/* <SearchableSelectBox
                                                                                                                    data={
                                                                                                                        contractPort
                                                                                                                            ?.data
                                                                                                                            ?.length >
                                                                                                                            0 &&
                                                                                                                        contractPort?.data
                                                                                                                    }
                                                                                                                    placeholder="Enter Port to select"
                                                                                                                    formik={
                                                                                                                        formik
                                                                                                                    }
                                                                                                                    extraField="so_destinationPort"
                                                                                                                    fieldName="so_destinationPortPk"
                                                                                                                    menuPosition="fixed"
                                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                                        'outside_div'
                                                                                                                    )}
                                                                                                                    closeMenuOnScroll={
                                                                                                                        true
                                                                                                                    }
                                                                                                                /> */}
                                                                                                                <Select
                                                                                                                    options={
                                                                                                                        destPorts
                                                                                                                    }
                                                                                                                    isClearable
                                                                                                                    placeholder="Enter Port to select"
                                                                                                                    onFocus={() =>
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        )
                                                                                                                    }
                                                                                                                    onBlur={() =>
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        )
                                                                                                                    }
                                                                                                                    onMenuOpen={() => {
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    onMenuClose={() => {
                                                                                                                        setSearchDisplay(
                                                                                                                            'Displayed'
                                                                                                                        );
                                                                                                                        setDestPorts(
                                                                                                                            []
                                                                                                                        );
                                                                                                                        // formik.setFieldValue(
                                                                                                                        //     'so_destinationPort',
                                                                                                                        //     ''
                                                                                                                        // );
                                                                                                                    }}
                                                                                                                    onChange={(
                                                                                                                        option: any
                                                                                                                    ) => {
                                                                                                                        if (
                                                                                                                            option ===
                                                                                                                                null ||
                                                                                                                            searchDisplay ===
                                                                                                                                'Displayed' ||
                                                                                                                            searchDisplay ===
                                                                                                                                'Origin'
                                                                                                                        ) {
                                                                                                                            setDestPorts(
                                                                                                                                []
                                                                                                                            );
                                                                                                                            setPorts(
                                                                                                                                []
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_destinationPortPk',
                                                                                                                                null
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_destinationPortCode',
                                                                                                                                null
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_destinationPort',
                                                                                                                                null
                                                                                                                            );
                                                                                                                        } else {
                                                                                                                            // eslint-disable-next-line no-undefined
                                                                                                                            formik.setFieldError(
                                                                                                                                'so_destinationPortPk',
                                                                                                                                undefined
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_destinationPortPk',
                                                                                                                                JSON.stringify(
                                                                                                                                    option
                                                                                                                                )
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_destinationPortCode',
                                                                                                                                option.label
                                                                                                                                    .toString()
                                                                                                                                    .split(
                                                                                                                                        '('
                                                                                                                                    )[1]
                                                                                                                                    .toString()
                                                                                                                                    .split(
                                                                                                                                        ')'
                                                                                                                                    )[0]
                                                                                                                            );
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_destinationPort',
                                                                                                                                option.label
                                                                                                                                    .toString()
                                                                                                                                    .split(
                                                                                                                                        '('
                                                                                                                                    )[0]
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    onInputChange={(
                                                                                                                        text: string
                                                                                                                    ) => {
                                                                                                                        setSearchDisplay(
                                                                                                                            'Destination'
                                                                                                                        );
                                                                                                                        setPorts(
                                                                                                                            []
                                                                                                                        );
                                                                                                                        setSearchTerm(
                                                                                                                            text
                                                                                                                        );
                                                                                                                    }}
                                                                                                                    // noOptionsMessage={({
                                                                                                                    //     inputValue,
                                                                                                                    // }) =>
                                                                                                                    //     !inputValue
                                                                                                                    //         ? 'No results'
                                                                                                                    //         : ''
                                                                                                                    // }
                                                                                                                    noOptionsMessage={({
                                                                                                                        inputValue,
                                                                                                                    }) =>
                                                                                                                        inputValue?.length <
                                                                                                                        3
                                                                                                                            ? 'Enter at least 3 characters to search'
                                                                                                                            : 'No results found'
                                                                                                                    }
                                                                                                                    onKeyDown={(
                                                                                                                        e
                                                                                                                    ) => {
                                                                                                                        if (
                                                                                                                            e.key ===
                                                                                                                            'Enter'
                                                                                                                        ) {
                                                                                                                            e.preventDefault();
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    id={
                                                                                                                        'so_destinationPortPk'
                                                                                                                    }
                                                                                                                    name={
                                                                                                                        'so_destinationPortPk'
                                                                                                                    }
                                                                                                                    // defaultInputValue={
                                                                                                                    //     res?.so_destinationPort &&
                                                                                                                    //     `${res?.so_destinationPort}(${res?.so_destinationPortCode})`
                                                                                                                    // }
                                                                                                                    value={
                                                                                                                        formik
                                                                                                                            .values
                                                                                                                            .so_destinationPort && {
                                                                                                                            label: `${formik.values['so_destinationPort']}(${formik.values['so_destinationPortCode']})`,

                                                                                                                            value: formik
                                                                                                                                .values[
                                                                                                                                'so_destinationPortPk'
                                                                                                                            ],
                                                                                                                        }
                                                                                                                    }
                                                                                                                    components={{
                                                                                                                        DropdownIndicator:
                                                                                                                            () =>
                                                                                                                                null,
                                                                                                                        IndicatorSeparator:
                                                                                                                            () =>
                                                                                                                                null,
                                                                                                                    }}
                                                                                                                />
                                                                                                                {showPortPairError &&
                                                                                                                !formik
                                                                                                                    .values
                                                                                                                    .so_destinationPort ? (
                                                                                                                    <div className="error-text">
                                                                                                                        This
                                                                                                                        field
                                                                                                                        is
                                                                                                                        required
                                                                                                                    </div>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell td-inco">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <div className=" modal-input-holder">
                                                                                                                <div className="modal-input-layout-item auto-dp-height">
                                                                                                                    <SearchableSelectBox
                                                                                                                        data={
                                                                                                                            contractIncoTerms
                                                                                                                                ?.data
                                                                                                                                ?.length >
                                                                                                                                0 &&
                                                                                                                            contractIncoTerms?.data
                                                                                                                        }
                                                                                                                        placeholder="Enter Incoterm to select"
                                                                                                                        formik={
                                                                                                                            formik
                                                                                                                        }
                                                                                                                        extraField="so_incoTerm"
                                                                                                                        fieldName="so_incoTermPk"
                                                                                                                        menuPosition="fixed"
                                                                                                                        menuPortalTarget={document.getElementById(
                                                                                                                            'outside_div'
                                                                                                                        )}
                                                                                                                        closeMenuOnScroll={
                                                                                                                            true
                                                                                                                        }
                                                                                                                    />
                                                                                                                    {showPortPairError &&
                                                                                                                    !formik
                                                                                                                        .values
                                                                                                                        .so_incoTerm ? (
                                                                                                                        <div className="error-text">
                                                                                                                            This
                                                                                                                            field
                                                                                                                            is
                                                                                                                            required
                                                                                                                        </div>
                                                                                                                    ) : null}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell td-teu">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <div className="modal-input-holder modal-input-layout-item">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    placeholder="Enter value"
                                                                                                                    className="modal-input input-text"
                                                                                                                    id="so_teu"
                                                                                                                    name="so_teu"
                                                                                                                    onChange={(
                                                                                                                        event
                                                                                                                    ) => {
                                                                                                                        // formik.setFieldError(
                                                                                                                        //     event
                                                                                                                        //         .target
                                                                                                                        //         .name,
                                                                                                                        //     undefined
                                                                                                                        // );
                                                                                                                        formik.handleChange(
                                                                                                                            event
                                                                                                                        );
                                                                                                                        if (
                                                                                                                            event
                                                                                                                                .target
                                                                                                                                .value !=
                                                                                                                            ''
                                                                                                                        ) {
                                                                                                                            formik.setFieldValue(
                                                                                                                                'so_teu',
                                                                                                                                +event
                                                                                                                                    .target
                                                                                                                                    .value
                                                                                                                            );
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    value={
                                                                                                                        formik
                                                                                                                            .values
                                                                                                                            .so_teu
                                                                                                                    }
                                                                                                                    onKeyPress={(
                                                                                                                        event
                                                                                                                    ) => {
                                                                                                                        if (
                                                                                                                            !/[0-9]/.test(
                                                                                                                                event.key
                                                                                                                            )
                                                                                                                        ) {
                                                                                                                            event.preventDefault();
                                                                                                                        }
                                                                                                                    }}
                                                                                                                ></input>
                                                                                                                {parseInt(
                                                                                                                    formik
                                                                                                                        .values
                                                                                                                        .so_teu,
                                                                                                                    10
                                                                                                                ) ===
                                                                                                                0 ? (
                                                                                                                    <div className="error-text">
                                                                                                                        TEU
                                                                                                                        value
                                                                                                                        should
                                                                                                                        be
                                                                                                                        greater
                                                                                                                        than
                                                                                                                        0
                                                                                                                    </div>
                                                                                                                ) : null}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell grid-sticky-column  td-actions">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <button
                                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                                title="Save"
                                                                                                                type="button"
                                                                                                                onClick={() => {
                                                                                                                    portPairSave();
                                                                                                                    setSearchDisplay(
                                                                                                                        'Displayed'
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                <svg className="svg-icon save-icon">
                                                                                                                    <use xlinkHref="#saveIcon"></use>
                                                                                                                    <title>
                                                                                                                        Save
                                                                                                                    </title>
                                                                                                                </svg>
                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                                title="Remove"
                                                                                                                type="button"
                                                                                                                onClick={() =>
                                                                                                                    portPairClear(
                                                                                                                        index
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <svg className="svg-icon cancel-icon ">
                                                                                                                    <use xlinkHref="#cancelIcon"></use>
                                                                                                                    <title>
                                                                                                                        Remove
                                                                                                                    </title>
                                                                                                                </svg>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            ) : (
                                                                                                <tr
                                                                                                    key={index}
                                                                                                    className="has-child row-expanded"
                                                                                                >
                                                                                                    <td className="grid-cell  grid-sticky-column td-origin">
                                                                                                        <div className="grid-cell-data">
                                                                                                            {res?.so_originPort &&
                                                                                                                `${res?.so_originPort}(${res?.so_originPortCode})`}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell td-destination">
                                                                                                        <div className="grid-cell-data">
                                                                                                            {res?.so_destinationPort &&
                                                                                                                `${res?.so_destinationPort}(${res?.so_destinationPortCode})`}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell td-inco">
                                                                                                        <div className="grid-cell-data">
                                                                                                            {
                                                                                                                res?.so_incoTerm
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell td-teu">
                                                                                                        <div className="grid-cell-data">
                                                                                                            {
                                                                                                                res?.so_teu
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell grid-sticky-column td-actions">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <button
                                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                                title="Edit"
                                                                                                                type="button"
                                                                                                                onClick={() => {
                                                                                                                    portPairEdit(
                                                                                                                        index,
                                                                                                                        res
                                                                                                                    );
                                                                                                                }}
                                                                                                            >
                                                                                                                <svg className="svg-icon edit-icon ">
                                                                                                                    <use xlinkHref="#editIcon"></use>
                                                                                                                    <title>
                                                                                                                        Edit
                                                                                                                    </title>
                                                                                                                </svg>
                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                                title="Remove"
                                                                                                                type="button"
                                                                                                                onClick={() =>
                                                                                                                    portPairClear(
                                                                                                                        index
                                                                                                                    )
                                                                                                                }
                                                                                                            >
                                                                                                                <svg className="svg-icon cancel-icon ">
                                                                                                                    <use xlinkHref="#cancelIcon"></use>
                                                                                                                    <title>
                                                                                                                        Remove
                                                                                                                    </title>
                                                                                                                </svg>
                                                                                                            </button>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            )
                                                                                    )}

                                                                                {/* <tr className="has-child row-expanded">
                                                                            <td className="grid-cell  grid-sticky-column  td-orgin">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-input-layout-item">
                                                                                        <SearchableSelectBox
                                                                                            data={
                                                                                                contractPort.data
                                                                                                    ?.length > 0 &&
                                                                                                contractPort?.data
                                                                                            }
                                                                                            placeholder="Select One"
                                                                                            formik={formik}
                                                                                            fieldName="so_originPort"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-description">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-input-layout-item">
                                                                                        <SearchableSelectBox
                                                                                            data={
                                                                                                contractPort.data
                                                                                                    ?.length > 0 &&
                                                                                                contractPort?.data
                                                                                            }
                                                                                            placeholder="Select One"
                                                                                            formik={formik}
                                                                                            fieldName="so_destinationPort"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-inco">
                                                                                <div className="grid-cell-data">
                                                                                    <div className=" modal-input-holder">
                                                                                        <div className="modal-input-layout-item">
                                                                                            <SearchableSelectBox
                                                                                                data={
                                                                                                    contractPort.data
                                                                                                        ?.length > 0 &&
                                                                                                    contractPort?.data
                                                                                                }
                                                                                                placeholder="Select One"
                                                                                                formik={formik}
                                                                                                fieldName="so_incoTerm"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-teu">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-input-holder modal-input-layout-item">
                                                                                        <input
                                                                                            type="text"
                                                                                            placeholder="Enter TEU Allocated/Week"
                                                                                            className="modal-input input-text"
                                                                                            id="so_teu"
                                                                                            name="so_teu"
                                                                                            onChange={(event) => {
                                                                                                formik.setFieldError(
                                                                                                    event.target.name,
                                                                                                    undefined
                                                                                                );
                                                                                                formik.handleChange(
                                                                                                    event
                                                                                                );
                                                                                            }}
                                                                                            value={formik.values.so_teu}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell grid-sticky-column  td-actions">
                                                                                <div className="grid-cell-data">
                                                                                    <button
                                                                                        className="action-btn app-btn app-btn-secondary "
                                                                                        title="Save"
                                                                                        type="button"
                                                                                    >
                                                                                        <svg className="svg-icon save-iocn ">
                                                                                            <use xlinkHref="#saveIcon"></use>
                                                                                            <title>Edit</title>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <button
                                                                                        className="action-btn app-btn app-btn-secondary "
                                                                                        title="Remove"
                                                                                        type="button"
                                                                                    >
                                                                                        <svg className="svg-icon cancel-icon ">
                                                                                            <use xlinkHref="#cancelIcon"></use>
                                                                                            <title>Remove</title>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </td>
                                                                        </tr>  */}
                                                                            </>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => setCustomStyle('auto')}
                                        className="accordion-section section-valid"
                                    >
                                        <input
                                            checked={clientAccordion}
                                            type="checkbox"
                                            className="accordion-radio"
                                            id="ac3"
                                            name="shipping-accordion-group"
                                            onChange={() => {
                                                setHeaderAccordion(false);
                                                setPortAccordion(false);
                                                setClientAccordion((prev: boolean) => !prev);
                                                setAttachmentAccordion(false);
                                            }}
                                        ></input>
                                        <label className="accordion-title" htmlFor="ac3">
                                            <div className="label-header">
                                                <svg className="svg-icon accordion-arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>

                                                <span className="label-title">Valid Clients</span>
                                                {(props.primaryClientsData?.length > 0 || formik.values.allClients) && (
                                                    <svg className="svg-icon badge-icon">
                                                        <use xlinkHref="#badgeIcon">
                                                            <title>Completed</title>
                                                        </use>
                                                    </svg>
                                                )}
                                            </div>
                                        </label>
                                        <div className="accordion-content">
                                            {/* it is added for coloader either this text or search will display */}
                                            {/* <p className="valid-contact-content">Contract valid for all Forwarder Clients</p> */}
                                            <div className="modal-main-content details-box">
                                                {formik.values.allClients}
                                                <div className="checkbox-item">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox-input"
                                                            checked={formik.values.allClients}
                                                            onChange={(e: any) => {
                                                                formik.setFieldValue('allClients', e.target.checked);
                                                                props.setPrimaryClientsData([]);
                                                                props.setSecondaryClientsData([]);
                                                            }}
                                                            disabled={props.isEdit}
                                                        ></input>
                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use href="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="checkbox-label">All Clients</div>
                                                    </label>
                                                </div>
                                                {!formik.values.allClients && (
                                                    <div className="input-form-wrapper">
                                                        <div className="modal-col-1">
                                                            <div className="modal-main-content">
                                                                <div className="dropdown-wrap  menu-down dropdown-open text-dropdown vendor-suggestion-menu">
                                                                    {!props.isEdit && (
                                                                        <div className="search-entry-holder modal-search">
                                                                            {/* <input
                                                                        placeholder="Enter Client Name to add"
                                                                        type="text"
                                                                        className="search-input input-text with-border"
                                                                    ></input> */}
                                                                            <CustomSearchableSelectBox
                                                                                placeholder="Enter Client Name to add"
                                                                                originalData={allClient?.data?.info}
                                                                                allClientsData={
                                                                                    props.allClientsData?.length > 0 &&
                                                                                    props.allClientsData
                                                                                }
                                                                                setAllClientsData={
                                                                                    props.setAllClientsData
                                                                                }
                                                                                clientsData={props.primaryClientsData}
                                                                                setClientsData={
                                                                                    props.setPrimaryClientsData
                                                                                }
                                                                                fieldName="primaryClient"
                                                                                setAddModalVisibility={
                                                                                    props.setAddModalVisibility
                                                                                }
                                                                                setSelectedClient={
                                                                                    props.setSelectedClient
                                                                                }
                                                                                setFilterUsed={props.setFilterUsed}
                                                                                formik={formik}
                                                                                filterUsed={props.filterUsed}
                                                                                setSecondaryClientsData={
                                                                                    props.setSecondaryClientsData
                                                                                }
                                                                                clientData={props.clientData}
                                                                                setPrimaryClientsData={
                                                                                    props.setPrimaryClientsData
                                                                                }
                                                                            />
                                                                            {/* <button className="app-btn text-close-btn">
                                                                        <svg className="svg-icon modal-header-close-icon-2">
                                                                        <use xlinkHref="#closeIcon">
                                                                        <title>Close</title>
                                                                        </use>
                                                                        </svg>
                                                                    </button> */}
                                                                        </div>
                                                                    )}
                                                                    {/* suggestion container */}
                                                                    {/* <div className="dropdown-menu">
                                                        <a className="menu-item app-btn">
                                                            <span className="highlight-content">Cole Kreiger</span>
                                                        </a>
                                                        <a className="menu-item app-btn">
                                                            <span className="highlight-content">Muller and Sons</span>
                                                        </a>
                                                        <a className="menu-item app-btn">
                                                            <span className="highlight-content">Murphy LLC</span>
                                                        </a>
                                                    </div> */}
                                                                    {/* no data match found */}
                                                                    {!props.primaryClientsData.length && (
                                                                        <div className="no-data-content">
                                                                            <svg className="svg-icon grid-no-content-icon ">
                                                                                <use xlinkHref="#gridNoContentIcon"></use>
                                                                            </svg>
                                                                            <p className="no-content-message">
                                                                                No Primary Clients Added
                                                                            </p>
                                                                        </div>
                                                                    )}

                                                                    <div className="content-heading-holder">
                                                                        <span className="content-heading">
                                                                            Primary Clients
                                                                        </span>
                                                                        <span className=" modal-toast">
                                                                            <div className="toast-message ">
                                                                                {props.primaryClientsData?.length}
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                {/* selected container */}
                                                                {/* selected container view option of primary client */}
                                                                {props.isEdit ? (
                                                                    <div className="suggestion-container">
                                                                        {props.primaryClientsData?.map(
                                                                            (res: any, index: number) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="content-card"
                                                                                >
                                                                                    <span className="main-content">
                                                                                        {res.label}
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        {/* <div className="content-card">
                                                                        <span className="main-content">
                                                                            Hane - Tillman
                                                                        </span>
                                                                    </div>
                                                                    <div className="content-card">
                                                                        <span className="main-content">Kohler LLC</span>
                                                                    </div>
                                                                    <div className="content-card">
                                                                        <span className="main-content">
                                                                            Kautzer, Hegmann and Dooley
                                                                        </span>
                                                                    </div> */}
                                                                    </div>
                                                                ) : (
                                                                    <div className="suggestion-container">
                                                                        {props.primaryClientsData?.map(
                                                                            (res: any, index: number) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="content-card"
                                                                                >
                                                                                    <span className="main-content">
                                                                                        {res.label}
                                                                                    </span>
                                                                                    <button
                                                                                        className="app-btn delete-button"
                                                                                        title="Delete"
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            formik.setFieldValue(
                                                                                                'portPairsStatus',
                                                                                                true
                                                                                            );
                                                                                            const data =
                                                                                                allClient?.data?.info.filter(
                                                                                                    (item: any) =>
                                                                                                        item.clientId ===
                                                                                                        res.value
                                                                                                );

                                                                                            if (
                                                                                                data.length &&
                                                                                                data[0].relationship ===
                                                                                                    'Parent' &&
                                                                                                data[0].childClients
                                                                                                    .length
                                                                                            ) {
                                                                                                props.setDeletedClient(
                                                                                                    data[0]
                                                                                                );
                                                                                                props.setRemoveModalVisibility(
                                                                                                    true
                                                                                                );
                                                                                                props.setFilterUsed(
                                                                                                    'primaryClient'
                                                                                                );
                                                                                            } else {
                                                                                                props.setPrimaryClientsData(
                                                                                                    props.primaryClientsData.filter(
                                                                                                        (item: any) =>
                                                                                                            item.value !==
                                                                                                            res.value
                                                                                                    )
                                                                                                );
                                                                                                props.setAllClientsData(
                                                                                                    (prev: any) => [
                                                                                                        ...prev,
                                                                                                        res,
                                                                                                    ]
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <svg className="svg-icon delete-icon">
                                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        )}

                                                                        {/* <div className="content-card">
                                                                    <span className="main-content">Kohler LLC</span>
                                                                    <button
                                                                        className="app-btn delete-button"
                                                                        title="Delete"
                                                                    >
                                                                        <svg className="svg-icon delete-icon">
                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div className="content-card">
                                                                    <span className="main-content">
                                                                        Kautzer, Hegmann and Dooley
                                                                    </span>
                                                                    <button
                                                                        className="app-btn delete-button"
                                                                        title="Delete"
                                                                    >
                                                                        <svg className="svg-icon delete-icon">
                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                </div> */}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>

                                                        <div className="modal-col-2">
                                                            <div className="modal-main-content">
                                                                {/* suggestion container */}
                                                                <div className="dropdown-wrap  menu-down dropdown-open text-dropdown vendor-suggestion-menu">
                                                                    {!props.isEdit && (
                                                                        <div className="search-entry-holder modal-search">
                                                                            {/* <input
                                                                        placeholder="Enter Client Name to add"
                                                                        type="text"
                                                                        className="search-input input-text with-border"
                                                                    ></input>
                                                                    <button className="app-btn text-close-btn">
                                                                        <svg className="svg-icon modal-header-close-icon-2">
                                                                            <use xlinkHref="#closeIcon">
                                                                                <title>Close</title>
                                                                            </use>
                                                                        </svg>
                                                                    </button> */}
                                                                            <CustomSearchableSelectBox
                                                                                placeholder="Enter Client Name to add"
                                                                                originalData={allClient?.data?.info}
                                                                                allClientsData={
                                                                                    props.allClientsData?.length > 0 &&
                                                                                    props.allClientsData
                                                                                }
                                                                                setAllClientsData={
                                                                                    props.setAllClientsData
                                                                                }
                                                                                clientsData={props.secondaryClientsData}
                                                                                setClientsData={
                                                                                    props.setSecondaryClientsData
                                                                                }
                                                                                fieldName="secondaryClient"
                                                                                setAddModalVisibility={
                                                                                    props.setAddModalVisibility
                                                                                }
                                                                                setSelectedClient={
                                                                                    props.setSelectedClient
                                                                                }
                                                                                setFilterUsed={props.setFilterUsed}
                                                                                formik={formik}
                                                                                filterUsed={props.filterUsed}
                                                                                setSecondaryClientsData={
                                                                                    props.setSecondaryClientsData
                                                                                }
                                                                                clientData={props.clientData}
                                                                                setPrimaryClientsData={
                                                                                    props.setPrimaryClientsData
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}

                                                                    {!props.secondaryClientsData.length && (
                                                                        <div className="no-data-content">
                                                                            <svg className="svg-icon grid-no-content-icon ">
                                                                                <use xlinkHref="#gridNoContentIcon"></use>
                                                                            </svg>
                                                                            <p className="no-content-message">
                                                                                No Secondary Clients Added
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    <div className="content-heading-holder">
                                                                        <span className="content-heading">
                                                                            Secondary Clients
                                                                        </span>
                                                                        <span className=" modal-toast">
                                                                            <div className="toast-message ">
                                                                                {props.secondaryClientsData?.length}
                                                                            </div>
                                                                        </span>
                                                                    </div>
                                                                    {/* <div className="dropdown-menu">
                                                        <a className="menu-item app-btn">
                                                            <span className="highlight-content">Cole Kreiger</span>
                                                        </a>
                                                        <a className="menu-item app-btn">
                                                            <span className="highlight-content">Muller and Sons</span>
                                                        </a>
                                                        <a className="menu-item app-btn">
                                                            <span className="highlight-content">Murphy LLC</span>
                                                        </a>
                                                    </div> */}
                                                                </div>

                                                                {/* selected container */}
                                                                {/* selected container view option */}
                                                                {props.isEdit ? (
                                                                    <div className="suggestion-container">
                                                                        {props.secondaryClientsData?.map(
                                                                            (res: any, index: number) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="content-card"
                                                                                >
                                                                                    <span className="main-content">
                                                                                        {res.label}
                                                                                    </span>
                                                                                </div>
                                                                            )
                                                                        )}

                                                                        {/* <div className="content-card">
                                                                        <span className="main-content">
                                                                            Carroll Group
                                                                        </span>
                                                                    </div>
                                                                    <div className="content-card">
                                                                        <span className="main-content">
                                                                            Lockman LLC
                                                                        </span>
                                                                    </div> */}
                                                                    </div>
                                                                ) : (
                                                                    <div className="suggestion-container">
                                                                        {props.secondaryClientsData?.map(
                                                                            (res: any, index: number) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="content-card"
                                                                                >
                                                                                    <span className="main-content">
                                                                                        {res.label}
                                                                                    </span>
                                                                                    <button
                                                                                        className="app-btn delete-button"
                                                                                        title="Delete"
                                                                                        type="button"
                                                                                        onClick={() => {
                                                                                            formik.setFieldValue(
                                                                                                'portPairsStatus',
                                                                                                true
                                                                                            );
                                                                                            const data =
                                                                                                allClient?.data?.info.filter(
                                                                                                    (item: any) =>
                                                                                                        item.clientId ===
                                                                                                        res.value
                                                                                                );

                                                                                            if (
                                                                                                data.length &&
                                                                                                data[0].relationship ===
                                                                                                    'Parent' &&
                                                                                                data[0].childClients
                                                                                                    .length
                                                                                            ) {
                                                                                                props.setDeletedClient(
                                                                                                    data[0]
                                                                                                );
                                                                                                props.setRemoveModalVisibility(
                                                                                                    true
                                                                                                );
                                                                                                props.setFilterUsed(
                                                                                                    'secondaryClient'
                                                                                                );
                                                                                            } else {
                                                                                                props.setSecondaryClientsData(
                                                                                                    props.secondaryClientsData.filter(
                                                                                                        (item: any) =>
                                                                                                            item.value !==
                                                                                                            res.value
                                                                                                    )
                                                                                                );
                                                                                                props.setAllClientsData(
                                                                                                    (prev: any) => [
                                                                                                        ...prev,
                                                                                                        res,
                                                                                                    ]
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <svg className="svg-icon delete-icon">
                                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            )
                                                                        )}
                                                                        {/* <div className="content-card">
                                                                    <span className="main-content">Carroll Group</span>
                                                                    <button
                                                                        className="app-btn delete-button"
                                                                        title="Delete"
                                                                    >
                                                                        <svg className="svg-icon delete-icon">
                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                                <div className="content-card">
                                                                    <span className="main-content">Lockman LLC</span>
                                                                    <button
                                                                        className="app-btn delete-button"
                                                                        title="Delete"
                                                                    >
                                                                        <svg className="svg-icon delete-icon">
                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                </div> */}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => setCustomStyle('auto')}
                                        className="accordion-section section-attachment"
                                    >
                                        <input
                                            checked={attachmentAccordion}
                                            type="checkbox"
                                            className="accordion-radio"
                                            id="ac4"
                                            name="shipping-accordion-group"
                                            value="attachment"
                                            onChange={() => {
                                                setHeaderAccordion(false);
                                                setPortAccordion(false);
                                                setClientAccordion(false);
                                                setAttachmentAccordion((prev: boolean) => !prev);
                                            }}
                                        ></input>
                                        <label className="accordion-title" htmlFor="ac4">
                                            <div className="label-header">
                                                <svg className="svg-icon accordion-arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>

                                                <span className="label-title">Attachment</span>
                                                {(formik.values.sd_file || props.data.fileName) && (
                                                    <svg className="svg-icon badge-icon">
                                                        <use xlinkHref="#badgeIcon">
                                                            <title>Completed</title>
                                                        </use>
                                                    </svg>
                                                )}
                                            </div>
                                        </label>
                                        <div className="accordion-content">
                                            {props.isEdit ? (
                                                <div className="modal-input-layout-item">
                                                    <div className="content-with-icon  attachment-holder  view-file">
                                                        <button
                                                            onClick={() => {
                                                                if (props.data.fileName) {
                                                                    downloadShippingLineContract(
                                                                        props.data.sd_sdpk,
                                                                        props.data.fileName
                                                                    );
                                                                }
                                                            }}
                                                            type="button"
                                                            className="app-btn "
                                                        >
                                                            <svg className="svg-icon file-attachment-icon ">
                                                                <use xlinkHref="#fileAttachmentIcon"></use>
                                                                {/* <title>Attach file</title> */}
                                                            </svg>
                                                        </button>
                                                        <div>
                                                            {/* file-uploaded class to higlight data with blue color */}
                                                            <a
                                                                href="javascript:void(0)"
                                                                onClick={() => {
                                                                    if (props.data.fileName) {
                                                                        downloadShippingLineContract(
                                                                            props.data.sd_sdpk,
                                                                            props.data.fileName
                                                                        );
                                                                    }
                                                                }}
                                                                className="description-type-1 file-uploaded"
                                                                title="File"
                                                            >
                                                                {props.data.fileName?.length > 20
                                                                    ? `${props.data.fileName?.slice(0, 20)}...`
                                                                    : props.data.fileName}
                                                            </a>
                                                            {props.data.savedFileSizeInKb ? (
                                                                <div className="heading-type-1">
                                                                    {getFileSizeOfUploadedFile(
                                                                        props.data.savedFileSizeInKb
                                                                    )}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="modal-input-layout-item">
                                                    {props.mode === 'add' ? (
                                                        <>
                                                            <div className="browse-btn-wrapper">
                                                                <input
                                                                    type={'file'}
                                                                    id={'sd_file'}
                                                                    name={'sd_file'}
                                                                    accept={
                                                                        '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                    }
                                                                    className="browse-input"
                                                                    onClick={(e) => {
                                                                        (e.target as HTMLInputElement).value = '';
                                                                    }}
                                                                    onChange={(e) => {
                                                                        formik.setFieldError(e.target.name, undefined);
                                                                        fileUpload(e);
                                                                    }}
                                                                ></input>
                                                                <button
                                                                    className="app-btn app-btn-secondary icon-button browse-btn"
                                                                    title="Upload file"
                                                                >
                                                                    <svg className="svg-icon plus-icon">
                                                                        <use xlinkHref="#plusIcon">
                                                                            <title>Upload File</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="button-text upload-btn">
                                                                        Upload File
                                                                    </span>
                                                                </button>
                                                                {!props.isFromView && formik.errors.sd_file ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.sd_file}
                                                                    </div>
                                                                ) : null}
                                                            </div>

                                                            <div className="heading-type-1 file-size">
                                                                <p>{fileUploadInfoText}</p>
                                                                <p
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: fileTypesInfoText,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </>
                                                    ) : null}

                                                    {file && props.mode === 'add' ? (
                                                        <div className="content-with-icon  attachment-holder">
                                                            <button
                                                                type="button"
                                                                className="app-btn "
                                                                title="Attach file"
                                                            >
                                                                <svg className="svg-icon file-attachment-icon ">
                                                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                                                    <title>Attach file</title>
                                                                </svg>
                                                            </button>
                                                            <div>
                                                                {/* file-uploaded class to higlight data with blue color */}
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    className="description-type-1 file-uploaded"
                                                                    title="File"
                                                                >
                                                                    {file?.name?.length > 20
                                                                        ? `${file?.name?.slice(0, 20)}...`
                                                                        : file?.name}
                                                                </a>
                                                                <div className="heading-type-1">
                                                                    {getFileSize(file?.size)}
                                                                </div>
                                                            </div>
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="file-remove-link"
                                                                title="Remove"
                                                                onClick={() => removeFile()}
                                                            >
                                                                Remove
                                                            </a>
                                                        </div>
                                                    ) : null}
                                                    {props.mode === 'edit' && props.data.fileName ? (
                                                        <div className="content-with-icon  attachment-holder  view-file">
                                                            <button
                                                                onClick={() => {
                                                                    if (props.data.fileName) {
                                                                        downloadShippingLineContract(
                                                                            props.data.sd_sdpk,
                                                                            props.data.fileName
                                                                        );
                                                                    }
                                                                }}
                                                                type="button"
                                                                className="app-btn "
                                                            >
                                                                <svg className="svg-icon file-attachment-icon ">
                                                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                                                    {/* <title>Attach file</title> */}
                                                                </svg>
                                                            </button>
                                                            <div>
                                                                {/* file-uploaded class to higlight data with blue color */}
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => {
                                                                        if (props.data.fileName) {
                                                                            downloadShippingLineContract(
                                                                                props.data.sd_sdpk,
                                                                                props.data.fileName
                                                                            );
                                                                        }
                                                                    }}
                                                                    className="description-type-1 file-uploaded"
                                                                    title="File"
                                                                >
                                                                    {props.data.fileName?.length > 20
                                                                        ? `${props.data.fileName?.slice(0, 20)}...`
                                                                        : props.data.fileName}
                                                                </a>
                                                                {props.data.savedFileSizeInKb ? (
                                                                    <div className="heading-type-1">
                                                                        {getFileSizeOfUploadedFile(
                                                                            props.data.savedFileSizeInKb
                                                                        )}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* outside dropdown items , need to set top and left for this dynamically according to the component*/}
                        <div
                            className={
                                showManagedBy
                                    ? 'dropdown-wrap manage-by-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                    : 'dropdown-wrap manage-by-dropdown-wrap outside-menu menu-down modal-dropdown'
                            }
                            style={{ top: `${distanceForManagedBy}px`, width: `${widthForManagedBy}px` }}
                        >
                            <div className="dropdown-menu">
                                <a
                                    onClick={() => {
                                        formik.setFieldValue('sd_managedBy', 'Agent');
                                        formik.values.sd_managedBy !== 'Agent' &&
                                            formik.setFieldValue('sd_managedByName', 'false');
                                    }}
                                    className="menu-item app-btn"
                                >
                                    <span className="main-content">Agent</span>
                                </a>
                                <a
                                    onClick={() => {
                                        formik.setFieldValue('sd_managedBy', 'Forwarder');
                                        formik.values.sd_managedBy !== 'Forwarder' &&
                                            formik.setFieldValue('sd_managedByName', 'false');
                                    }}
                                    className="menu-item app-btn"
                                >
                                    <span className="main-content">Forwarder</span>
                                </a>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => {
                                    props.setIsFromView(false);
                                    formik.dirty && !props.isEdit
                                        ? props.setDisplayCancel(true)
                                        : props.displayFunction(false);
                                }}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <ShowForPermissions permission="edit" type="coloader">
                                {props.isEdit ? (
                                    <button
                                        className="app-btn app-btn-primary modal-btn footer-btn 1"
                                        type="button"
                                        // form="my-form"
                                        disabled={!submitActive}
                                        onClick={() => {
                                            props.setIsEdit(false);
                                            props.setIsFromView(true);
                                            props.setMode('edit');
                                        }}
                                    >
                                        <span className="button-text vendor-button-text">Edit</span>
                                    </button>
                                ) : (
                                    <button
                                        className={`app-btn app-btn-primary modal-btn footer-btn add-contract-footer-btn ${
                                            props.mode === 'add' ? 'large-btn' : ''
                                        }`}
                                        type="submit"
                                        form="my-form"
                                        onClick={() => {
                                            props.setIsFromView(false);
                                        }}
                                        // disabled={
                                        //     props.mode === 'edit' ? !submitActive || !formik.dirty : !submitActive
                                        // }
                                        // disabled={!formik.dirty || editPortPair}
                                    >
                                        <span className="button-text vendor-button-text">
                                            {props.mode === 'add' ? 'Add Contract' : 'Save'}
                                            {formik.dirty}
                                        </span>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
