import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';

// Import all api's
import {
    addVendorIntegrationDetails,
    getVendorIntegrationDetails,
    submitVendorIntegrationDetailsAndActivate,
} from '../../services/apis/createVendorApi';
import {
    addVendorIntegrationData,
    fetchVendorIntegrationData,
    submitAndActivate,
    isLoadingFn,
} from '../reducers/vendorIntegrationReducer';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* saveIntegrationData({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse<any> = yield call(addVendorIntegrationDetails, payload);
        payload.responseOnSuccess(response);
        payload['integrationData'] = response;
        yield put({ type: addVendorIntegrationData, payload: payload });
    } catch (err) {
        console.log('err', err);
    }
}

function* getVendorIntegrationData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse<any> = yield call(getVendorIntegrationDetails, payload);
        payload['integrationData'] = response;
        yield put({ type: fetchVendorIntegrationData, payload: payload });
    } catch {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* submitIntegration({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse<any> = yield call(submitVendorIntegrationDetailsAndActivate, payload);
        payload.responseOnSuccess(response);
        payload['response'] = response;
        yield put({ type: submitAndActivate, payload: payload });
    } catch (err) {
        console.log('err', err);
    }
}

function* vendorIntegrationDetailsSaga() {
    yield takeEvery('vendorIntegrationDetails/addVendorIntegrationData', saveIntegrationData);
    yield takeEvery('vendorIntegrationDetails/fetchVendorIntegrationData', getVendorIntegrationData);
    yield takeEvery('vendorIntegrationDetails/submitAndActivate', submitIntegration);
}

// Export the saga (data-saga)
export default vendorIntegrationDetailsSaga;
