import React from 'react';
// import BookingProposalHeader from '../BookingProposalHeader';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../POSelection.scss';
import BookingEnrichmentAdditionalDetail from '../BookingEnrichmentAdditionalDetail';
const navData = [
    {
        title: 'PO Selection',
        selected: 0,
    },
    {
        title: 'Sailing Schedules',
        selected: 0,
    },
    {
        title: 'Additional Details',
        selected: 1,
    },
    {
        title: 'Container Allocation',
        selected: 0,
    },
    {
        title: 'Attachments ',
        selected: 0,
    },
];
const summaryStats = [
    {
        title: 'Total CBM',
        value: '132',
    },
    {
        title: 'Total Kgs',
        value: '5000',
    },
    {
        title: 'Estimated TEU',
        value: '4',
    },
];

const Index: React.FC = () => {
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header additional-detail-enrichment-wrapper">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/purchase_order'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'/purchase_order/basic_details'}
                    summaryStats={summaryStats}
                    backUrl={'/purchase_order/basic_details'}
                />
                <div className="pageheading-holder">
                    <div className="back-btn-holder">
                        <Link to="/purchase_order">
                            <button className="back-btn app-btn">
                                <svg className="svg-icon back-icon">
                                    <use href="#previousArrowIcon">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </button>
                        </Link>
                        <h2 className="page-heading">AB12332</h2>
                    </div>
                    {/* class: waiting-pill used for draft state
                    class: success-pill used for sucess state */}
                    <div className="header-container-view">
                        {/* <span className="btn-pills container-pill waiting-pill">
                            <span className="pill-text">Draft Booking</span>
                        </span> */}
                        <span className="btn-pills container-pill success-pill">
                            <span className="pill-text">Ready for Booking</span>
                        </span>
                    </div>
                </div>
                {/* <div className="header-sidebar-holder">
                    <button className="sidebar-info-btn animate-btn">
                        <svg className="svg-icon info-icon">
                            <use xlinkHref="#infoIcon">
                                <title>Information</title>
                            </use>
                        </svg>
                    </button>
                </div> */}
                {/* {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )} */}
                <BookingProcessTab navData={navData} />
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper bo-additional-detail">
                <div className="main-wrapper">
                    <BookingEnrichmentAdditionalDetail></BookingEnrichmentAdditionalDetail>
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    <Link title="Cancel" className="app-btn app-btn-secondary footer-btn" to={'/'}>
                        <span className="button-text footer-button-text">Cancel Booking</span>
                        {/* </button> */}
                    </Link>
                    <Link title="Previous" className="app-btn app-btn-secondary footer-btn" to={'/'}>
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <Link title="Next" className="app-btn app-btn-primary footer-btn" to={'/'}>
                        <span className="button-text footer-button-text">Next</span>
                        {/* </button> */}
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Index;
