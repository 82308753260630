import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    fetchSOPList,
    fetchSOPListFailed,
    fetchSOPListSuccess,
    createSopDetails,
    createSopDetailsSuccess,
    createSopDetailsFailure,
    updateSopDetailsSuccess,
    updateSopDetailsFailure,
    fetchAuthorDetails,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    fetchReviewedByDetails,
    fetchReviewedByDetailsSuccess,
    fetchReviewedByDetailsFailure,
    updateSopDetails,
    isLoadingFn,
    searchSOPData,
} from '../reducers/sopReducer';

import {
    createSopData,
    fetchSopAuthor,
    fetchSOPListData,
    fetchSopReviewedBy,
    updateSopData,
    searchSOP,
} from '../../services/apis/sopApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// eslint-disable-next-line
function* checkUserStatus({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchSOPListData, payload);
        yield put({ type: fetchSOPListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchSOPListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* createSop({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createSopData, payload);
        yield put({ type: createSopDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: createSopDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateSop({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateSopData, payload);
        yield put({ type: updateSopDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateSopDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* authorSop({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchSopAuthor);
        yield put({ type: fetchAuthorDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAuthorDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* reviewedBySop({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchSopReviewedBy);
        yield put({ type: fetchReviewedByDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchReviewedByDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const vendors: AxiosResponse = yield call(searchSOP, payload);
        yield put({ type: searchSOPData, payload: vendors });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* authSaga() {
    yield takeEvery(fetchSOPList, checkUserStatus);
    yield takeLatest(createSopDetails, createSop);
    yield takeLatest(updateSopDetails, updateSop);
    yield takeLatest(fetchAuthorDetails, authorSop);
    yield takeLatest(fetchReviewedByDetails, reviewedBySop);
    yield takeLatest(searchSOPData, getSearchData);
}

export default authSaga;
