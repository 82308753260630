import React, { useEffect } from 'react';
import './po-review.scss';
import '../po-tab.scss';
import './purchase-basic-detail.scss';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchBasicDetails } from '../../../store/reducers/purchase_order/basicDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import moment from 'moment';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from '../../../ShowForPermissions';
import { selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import { useAppSelector } from '../../../hooks';
import BLPInboundPoTabs from '../BLPInboundPoTabs';
import BLPInboundPONextButton from '../BLPInboundPONextButton';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { basicDetails } = useSelector((state: RootState) => state.purchaseOrder);
    const headerData = useAppSelector(selectHeader);

    useEffect(() => {
        dispatch(fetchBasicDetails(id));
    }, []);

    const showCreate = () => {
        var flag = false;
        if (
            val.promRefNo ||
            val.pickGroup ||
            val.brandName ||
            val.currency ||
            val.buyerUserName ||
            val.impVendorCode ||
            val.impShipmentRefNo ||
            val.distribOrderNo
        ) {
            flag = true;
        }
        return flag;
    };
    /* eslint no-var: off */
    // const checkDisabled = () => {
    //     var flag = true;
    //     if (val?.blpAgentName !== null && val?.orderDate !== null && val?.purchaseOrderProducts?.length !== 0) {
    //         flag = false;
    //     }
    //     return flag;
    // };
    const val = basicDetails as any;
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName="Inbound PO"
                    firstUrl="/inbound"
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New Inbound PO' : 'Inbound PO Details'}
                    secondUrl={`/inbound/${id}/po_review`}
                />
                <PurchaseOrderHeader id={id}></PurchaseOrderHeader>
                <BLPInboundPoTabs />
            </div>
            <div className="content-section main-wrapper preview-detail-wrapper purchase-basic-detail-wrapper">
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Required Details</h6>
                            <ShowForPermissions permission="edit" type="inbound">
                                {headerData?.poStatus === 'Draft' && (
                                    <button
                                        onClick={() => navigate(`/inbound/${id}/basic_details`)}
                                        className="action-btn app-btn app-btn-secondary"
                                        title="Edit Details"
                                    >
                                        <svg className="svg-icon edit-icon" style={{ width: '100%' }}>
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.ordertype ? val?.ordertype?.orderTypeName : 'BLP - Inbound Bulk PO'}
                                {/* {val?.ordertype ? val?.ordertype?.orderTypeName : '-'} */}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Shipping Mode<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.shippingmode ? val?.shippingmode?.shippingModeName : '-'}
                            </div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                FCL Service Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.fclservicetype ? val?.fclservicetype?.fclServiceTypeName : '-'}
                            </div>
                        </div> */}
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Freight Term<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.freightterms ? val?.freightterms?.freightTermsCode : '-'}
                            </div>
                        </div> */}
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Incoterm<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.incoTerm ? val?.incoTerm?.incoTermName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Release Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.releaseType?.releaseTypeName ? val?.releaseType?.releaseTypeName : '-'}
                            </div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Payment Term<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.paymentterms ? val?.paymentterms?.paymentTermsName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Origin<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.originPort ? val?.originPort : '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationPort
                                    ? val?.destinationPort
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination Address<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationAddress
                                    ? val.destinationAddress
                                    : '-'}
                            </div>
                        </div>
                        {val?.agentName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Agent<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{val?.agentName ? val?.agentName : '-'}</div>
                            </div>
                        )} */}
                        {/* BLP Content fields are added for sprint9-ui for BLP INBOUND PO*/}
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                BLP<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.blpAgentName ? val?.blpAgentName : '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                BLP Address<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.blpAddress ? val?.blpAddress : '-'}</div>
                        </div>
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Additional Details</h6>
                            <ShowForPermissions permission="edit" type="inbound">
                                {headerData?.poStatus === 'Draft' && (
                                    <button
                                        onClick={() => navigate(`/inbound/${id}/basic_details`)}
                                        className="action-btn app-btn app-btn-secondary"
                                        title="Edit Details"
                                    >
                                        <svg className="svg-icon edit-icon">
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                    </div>
                    {!showCreate() && (
                        <div className="no-data-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No additional details added</p>
                        </div>
                    )}
                    <div className="card-detail-col">
                        {val?.promRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Promotional Ref No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.promRefNo ? val?.promRefNo : '-'}</div>
                            </div>
                        )}
                        {val?.brandName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Brand Name
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.brandName ? val?.brandName : '-'}</div>
                            </div>
                        )}
                        {val?.impVendorCode && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Vendor Code
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impVendorCode ? val?.impVendorCode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.pickGroup && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Pick Group
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.pickGroup ? val?.pickGroup : '-'}</div>
                            </div>
                        )}
                        {val?.currency && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Order Currency
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.currency ? val?.currency?.currencycode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.buyerUserName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Buyer
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.buyerUserName ? val?.buyerUserName : '-'}
                                </div>
                            </div>
                        )}
                        {val?.impShipmentRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Shipment Ref No .{/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impShipmentRefNo ? val?.impShipmentRefNo : '-'}
                                </div>
                            </div>
                        )}
                        {val?.distribOrderNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Distribution Order No
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.distribOrderNo ? val?.distribOrderNo : '-'}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>
                            <ShowForPermissions permission="edit" type="inbound">
                                {headerData?.poStatus === 'Draft' && (
                                    <button
                                        onClick={() => navigate(`/inbound/${id}/dates`)}
                                        className="action-btn app-btn app-btn-secondary"
                                        title="Edit Details"
                                    >
                                        <svg className="svg-icon edit-icon">
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                    </div>
                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Order Date</h6>
                            <div className="details-box-content">
                                {val?.orderDate ? moment(val?.orderDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Start Shipping Window</h6>
                            <div className="details-box-content">
                                {val?.startShipWindowDate ? moment(val?.startShipWindowDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">End Shipping Window</h6>
                            <div className="details-box-content">
                                {val?.endShipWindowDate ? moment(val?.endShipWindowDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        {!val?.isMultiAddress && (
                            <>
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">Goods Ready Date</h6>
                                    <div className="details-box-content">
                                        {val?.goodsReadyDate ? moment(val?.goodsReadyDate).format('DD-MM-YYYY') : '-'}
                                    </div>
                                </div>
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">Projected ETA into Warehouse</h6>
                                    <div className="details-box-content">
                                        {val?.projectedEtaDate
                                            ? moment(val?.projectedEtaDate).format('DD-MM-YYYY')
                                            : '-'}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <div className="card grid-section view-entry-field-grid modal-grid preview-grid purchase-basic-detail-wrapper">
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="content-heading-holder card-head-btn-edit-holder">
                                <span className="content-heading">Product Details</span>
                                <ShowForPermissions permission="edit" type="inbound">
                                    {headerData?.poStatus === 'Draft' && (
                                        <button
                                            onClick={() => navigate(`/inbound/${id}/product_details`)}
                                            className="action-btn app-btn app-btn-secondary"
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                                </ShowForPermissions>
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <thead>
                                        <tr className="grid-sticky-row">
                                            <th className="grid-cell-header col-po-line">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">PO LINE NO.</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header  col-sku">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">sku</span>
                                                </div>
                                            </th>

                                            <th className="grid-cell-header  col-description">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">DESCRIPTION</span>
                                                </div>
                                            </th>
                                            {/* <th className="grid-cell-header  col-country-origin">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">COUNTRY OF ORIGIN</span>
                                                </div>
                                            </th> */}
                                            {/* {val?.isMultiAddress && ( */}
                                            <>
                                                {/* <th className="grid-cell-header col-destination-port">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">DESTINATION</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-destination-address">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">DESTINATION ADDRESS</span>
                                                    </div>
                                                </th> */}
                                                <th className="grid-cell-header col-goods-ready-date">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">GOODS READY DATE</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-whouse">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">pROJ. ETA INTO WHOUSE</span>
                                                    </div>
                                                </th>
                                            </>
                                            {/* )} */}
                                            <th className="grid-cell-header col-quantity">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">QUANTITY</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-cbm">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">CBM</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-currency">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">CURRENCY</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-unit">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">UNIT COST</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-net">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">NET COST</span>
                                                </div>
                                            </th>
                                            {/* sprint9-ui - BLP INBOUND PO additional table column added */}
                                            <th className="grid-cell-header col-converted">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">CONVERTED QTY & uom</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-barcode">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">BARCODES</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {val?.purchaseOrderProducts &&
                                            val?.purchaseOrderProducts.map((res: any, i: any) => (
                                                <tr className="has-child row-expanded">
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{i + 1}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.sku}</div>
                                                    </td>

                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.description}</div>
                                                    </td>
                                                    {/* <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.countryName}</div>
                                                    </td> */}
                                                    {/* {val?.isMultiAddress && ( */}
                                                    <>
                                                        {/* <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {res?.destinationPort}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {res?.destinationAddress}
                                                                </div>
                                                            </td> */}
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {res?.goodsReadyDate &&
                                                                    moment(res?.goodsReadyDate).format('DD-MM-YYYY')}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {res?.projWhouseETA &&
                                                                    moment(res?.projWhouseETA).format('DD-MM-YYYY')}
                                                            </div>
                                                        </td>
                                                    </>
                                                    {/* )} */}
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.qty}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.cbm}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {res?.currency?.currencycode}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.unitCost}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.netCost}</div>
                                                    </td>
                                                    {/* BLP INBOUND PO table data added*/}
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {res?.convertedQty} {res?.uom}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div style={{ whiteSpace: 'pre' }} className="grid-cell-data">
                                                            {res?.barcodes?.replace(/,/g, '    ')}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BLPInboundPONextButton page="po_review" />
        </div>
    );
};
export default Index;
