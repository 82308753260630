import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchData } from '../reducers/data-reducer';

// Import all api's
import { getAllData, IData } from '../../services/apis/data-api';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    const user: AxiosResponse<IData[]> = yield call(getAllData, payload);
    yield put({ type: fetchData, payload: user });
}
function* dataSaga() {
    yield takeEvery('user8s/fetchData', getListData);
}

// Export the saga (data-saga)
export default dataSaga;
