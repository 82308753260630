import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'inventoryAdjstmnt',
    initialState: {
        adjstData: {},
        dataLoad: false,
        adjstSuccess: false,
        enqLog: {},
    },
    reducers: {
        fetchAdjstMent: (_state, _action) => {
            // console.log(action);
        },
        saveAdjstData: (state, action) => {
            state.adjstData = action.payload;
        },
        dataLoader: (state, action) => {
            state.dataLoad = action.payload;
        },
        confirmData: (_state, _action) => {
            // console.log(action);
        },
        successMessage: (state, action) => {
            state.adjstSuccess = action.payload;
        },
        fetchEnquiryLog: (_state, _action) => {
            // console.log(action);
        },
        saveEnquiryLog: (state, action) => {
            state.enqLog = action.payload;
        },
    },
});

export const {
    saveAdjstData,
    fetchAdjstMent,
    dataLoader,
    confirmData,
    successMessage,
    saveEnquiryLog,
    fetchEnquiryLog,
} = dataSlice.actions;
export default dataSlice.reducer;
