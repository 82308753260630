/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import '../DatePicker/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch } from 'react-redux';
// import { filterValues } from 'src/utils/kendoGrid/insights';
// import BSTooltip from '../BSTooltip';
import { hubLinkStatus } from '../ProfileListing/constants';

interface filterProps {
    dataList: any[];
    isLoading: boolean;
    position: any;
    setFilter: any;
    filter: any;
    filterKey: string;
    setselectedDropDown: (data: string) => void;
    headerArray?: any[];
    selectedDate?: {
        selectedDate?: Date | null;
        handleDateSelection: (key: string, dateValue: Date | null) => void;
    };
    isGlobalStateFilter?: boolean;
    resetPage?: any;
    isFilterRightAligned?: boolean;
}

const SearchableFilter = ({
    dataList,
    isLoading,
    position,
    setFilter,
    filter,
    filterKey,
    setselectedDropDown,
    headerArray,
    selectedDate,
    isGlobalStateFilter,
    resetPage,
    isFilterRightAligned,
}: filterProps) => {
    const [searchText, setSearchText] = useState('');
    const filterData1 = filter[filterKey];
    const filterData2 = dataList?.some((element) => filterData1?.includes(element));
    let filterData = dataList ? [...dataList] : [];
    if (!filterData2 && filterData1) {
        filterData = [...filterData, ...filterData1];
    }

    const [searchResultArray, setSearchResultArray] = useState<string[]>(dataList ? filterData : []);
    const dispatch = useDispatch();

    useEffect(() => {
        setSearchText('');
        setSearchResultArray(dataList ? filterData : []);
    }, [filterKey]);

    const clearFilter = (value: string) => {
        const filterObj = JSON.parse(JSON.stringify(filter));
        if (value === 'all') {
            delete filterObj[filterKey];
        } else {
            filterObj[filterKey] = filterObj[filterKey]?.filter((e: any) => e !== value);
            if (!filterObj[filterKey]?.length) {
                delete filterObj[filterKey];
            }
        }
        // delete filterObj[filterKey];
        isGlobalStateFilter ? dispatch(setFilter(filterObj)) : setFilter(filterObj);
    };
    const convertDateAndSearch = (data: any, search: string) => {
        return data
            ?.map((a: any) => (moment(a).format('DD-MM-YYYY').includes(search.toLocaleLowerCase()) ? a : false))
            .filter(Boolean) as any[];
    };
    const dateColumns = [
        'plannedCustClearanceDate',
        'actualCustClearanceDate',
        'plannedGoodsRecvdDate',
        'actualGoodsRecvdDate',
        'plannedEta',
        'plannedEtd',
        'createdDate',
        'createdDateTime',
        'goodsReadyDate',
    ];

    const onSearch = (e: any, filterKey: string) => {
        setSearchText(e.target.value);
        if (e.target.value?.trim().length) {
            if (dateColumns?.includes(filterKey)) {
                const searchResult = convertDateAndSearch(dataList, e.target.value?.trim());
                setSearchResultArray(searchResult);
            } else {
                if (filterKey === 'carrierFlights') {
                    const searchResult = dataList
                        ?.map((a: any) =>
                            JSON.parse(a)
                                ?.carrierFlight.toLocaleLowerCase()
                                .includes(e.target.value?.trim().toLocaleLowerCase())
                                ? a
                                : false
                        )
                        .filter(Boolean) as any[];
                    setSearchResultArray(searchResult);
                } else {
                    const searchResult = dataList
                        ?.map((a) =>
                            a.toLocaleLowerCase().includes(e.target.value?.trim().toLocaleLowerCase()) ? a : false
                        )
                        .filter(Boolean) as any[];
                    setSearchResultArray(searchResult);
                }
            }
        } else {
            setSearchResultArray(dataList ? filterData : []);
        }
    };

    const flatten = (arr: any): any[] => {
        let ret: any[] = [];
        for (let i = 0; i < arr.length; i++) {
            if (Array.isArray(arr[i].secondRowDetails)) {
                ret = ret.concat(flatten(arr[i].secondRowDetails));
            } else {
                ret.push(arr[i]);
            }
        }

        return ret;
    };

    const getisChecked = (filterValue: any, currentValue: any, filterKey: string) => {
        if (filterKey === 'carrierFlights') {
            // return JSON.stringify(filterValue) === currentValue;
            const currentData = JSON.parse(currentValue);
            return filterValue?.some((res: any) => res.carrierFlight === currentData.carrierFlight);
        }
        // return filterValue === currentValue;
        return filterValue?.includes(currentValue);
    };

    const getValue = (value: any) => {
        if (!value) {
            return null;
        }
        const finalHeaderArray = flatten(headerArray);
        if (finalHeaderArray?.find((x: any) => x.key === filterKey)?.isDate) {
            const fullDate = value.split(' ')[0];
            const date = fullDate.split('-');
            return `${date[2]}-${date[1]}-${date[0]}`;
        } else {
            if (
                finalHeaderArray?.find((x: any) => x.key === filterKey)?.subKey &&
                value &&
                Object.keys(JSON.parse(value)).length
            ) {
                return JSON.parse(value)?.carrierFlight;
            }

            return value;
        }
    };

    const valueForHubLink = (value: string) => {
        return (
            <div className="grid-cell-data">
                <span
                    className={`status ${hubLinkStatus[value]?.styleClass}`}
                    style={{ marginLeft: '0px', top: '1.5px', marginRight: '5px' }}
                ></span>
                {hubLinkStatus[value]?.tooltipText}
            </div>
        );
    };

    const checkDateIsSame = (firstDate: any, secondDate: any) => {
        return firstDate && secondDate ? moment(firstDate).isSame(secondDate, 'day') : false;
    };

    const getFilterAsArray = (value: any) => {
        if (filter[filterKey]) {
            return [
                ...filter[filterKey],
                filterKey === 'carrierFlights' && Object.keys(JSON.parse(value)).length ? JSON.parse(value) : value,
            ];
        }
        return [filterKey === 'carrierFlights' && Object.keys(JSON.parse(value)).length ? JSON.parse(value) : value];
    };
    return (
        <div
            style={
                {
                    // left: position.left,
                    '--left': `${position.left}`,
                    top: position.top,
                    width: `${headerArray?.find((x: any) => x.key === filterKey)?.dateFilter ? 'auto' : ''}`,
                    maxHeight: `calc(300vh - ${position.top})`,
                    padding: `${
                        headerArray?.find((x: any) => x.key === filterKey)?.dateFilter ? '10px' : '16px 0px 12px 0px'
                    }`,
                } as React.CSSProperties
            }
            data-container="body"
            className={`filter-menu searchable-dropdown-common ${isFilterRightAligned ? 'right-align' : ''}`}
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            {headerArray?.find((x: any) => x.key === filterKey)?.dateFilter ? (
                <div className="date-filter">
                    <DatePicker
                        selected={
                            // searchResultArray?.length && checkDateIsSame(searchResultArray[0], filter[filterKey])
                            //     ? new Date(searchResultArray[0])
                            //     : null
                            selectedDate?.selectedDate ? new Date(selectedDate.selectedDate) : null
                        }
                        onChange={(_date: Date) => {
                            // setFilter({ ...filter, [filterKey]: moment(date).format('YYYY-MM-DD') });
                            // setselectedDropDown('');
                        }}
                        onSelect={(date: Date) => {
                            if (checkDateIsSame(moment(date).format('YYYY-MM-DD'), filter[filterKey])) {
                                clearFilter('all');
                                selectedDate?.handleDateSelection(filterKey, null);
                            } else {
                                isGlobalStateFilter
                                    ? dispatch(setFilter({ ...filter, [filterKey]: moment(date).format('YYYY-MM-DD') }))
                                    : setFilter({ ...filter, [filterKey]: moment(date).format('YYYY-MM-DD') });
                                selectedDate?.handleDateSelection(filterKey, new Date(date));
                            }
                            setselectedDropDown('');
                        }}
                        disabledKeyboardNavigation={true}
                        // includeDates={searchResultArray.map((res) => new Date(moment(res).format('YYYY-MM-DD')))}
                        inline
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                    />
                </div>
            ) : (
                <>
                    <div className="search-entry-holder">
                        <input
                            placeholder="Search"
                            type="text"
                            className="search-input input-text grid-search-field"
                            value={searchText}
                            onChange={(e) => onSearch(e, filterKey)}
                        ></input>
                        <svg className="svg-icon search-icon">
                            <use xlinkHref="#searchIcon"></use>
                        </svg>
                        <button
                            onClick={() => {
                                setSearchText('');
                                setSearchResultArray(dataList ? filterData : []);
                            }}
                            className="app-btn text-close-btn"
                        >
                            <svg className="svg-icon text-close-icon">
                                <use xlinkHref="#closeIcon"></use>
                            </svg>
                        </button>
                    </div>
                    <div className="checkbox-wrapper">
                        {searchResultArray?.map(
                            (value: string, index: number) =>
                                value &&
                                value.length && (
                                    <div key={index} className="checkbox-item">
                                        <label className="app-check-wrapper">
                                            <input
                                                checked={getisChecked(filter[filterKey], value, filterKey)}
                                                defaultChecked={false}
                                                type="checkbox"
                                                key={Math.random()}
                                                className="checkbox-input"
                                                onChange={(e) => {
                                                    if (
                                                        filter[filterKey]?.length === 1 &&
                                                        searchResultArray.length > 0 &&
                                                        e.target.checked === false
                                                    ) {
                                                        setselectedDropDown('');
                                                    }
                                                    if (e.target.checked) {
                                                        isGlobalStateFilter
                                                            ? dispatch(
                                                                  setFilter({
                                                                      ...filter,
                                                                      [filterKey]:
                                                                          //   filterKey === 'carrierFlights' &&
                                                                          //   Object.keys(JSON.parse(value)).length
                                                                          //       ? JSON.parse(value)
                                                                          //       : value,
                                                                          getFilterAsArray(value),
                                                                  })
                                                              )
                                                            : setFilter({
                                                                  ...filter,
                                                                  [filterKey]:
                                                                      //   filterKey === 'carrierFlights' &&
                                                                      //   Object.keys(JSON.parse(value)).length
                                                                      //       ? JSON.parse(value)
                                                                      //       : value,
                                                                      getFilterAsArray(value),
                                                              });
                                                        // setselectedDropDown('');
                                                        if (resetPage) {
                                                            dispatch(resetPage({}));
                                                        }
                                                    } else {
                                                        // setselectedDropDown('');
                                                        clearFilter(value);
                                                    }
                                                }}
                                            />
                                            <div className="checkmark">
                                                <svg className="svg-icon tick-icon">
                                                    <use xlinkHref="#tickIcon">
                                                        <title>check mark</title>
                                                    </use>
                                                </svg>
                                            </div>
                                            <div className="checkbox-label">
                                                {filterKey === 'hubLink' ? valueForHubLink(value) : getValue(value)}
                                            </div>
                                        </label>
                                    </div>
                                )
                        )}
                        {searchResultArray?.length === 0 && !isLoading && (
                            <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No Matches Found</p>
                            </div>
                        )}
                    </div>
                </>
            )}
            {(dataList?.length > 0 || headerArray?.find((x: any) => x.key === filterKey)?.dateFilter) && (
                <div className="clear-btn">
                    <button
                        onClick={() => {
                            clearFilter('all');
                            // setselectedDropDown('');
                            headerArray?.find((x: any) => x.key === filterKey)?.dateFilter &&
                                selectedDate?.handleDateSelection(filterKey, null);
                        }}
                        className="app-btn"
                    >
                        <span className="button-text">Clear All</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default SearchableFilter;
