import React, { useState } from 'react';
import '../../BookingProposal/BookingSailingSchelude/booking-sailing-schelude.scss';
// import ListGrid from '../ListGrid';
import { useNavigate, useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
// import SailingScheduleDetailModal from './SailingScheduleDetailModal';

interface Props {
    shippingDates: any;
    isLoading: boolean;
    headerData?: any;
}

/* eslint no-var: off */

const Index: React.FC<Props> = ({ isLoading, headerData }) => {
    const [_DropDown, setDropDown] = useState(false);

    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const { id } = useParams();
    const navigate = useNavigate();

    const checkPermission = (permission: any, type: any) => {
        var flag = false;
        for (var i = 0; i < permissions?.length; i++) {
            if (
                ((permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                    (permissions[i] as any).access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes(type.toLowerCase())) ||
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('alladmin')
            ) {
                flag = true;
            }
        }
        return flag;
    };

    if (!isLoading) {
        return (
            <div onClick={() => setDropDown(false)} className="lcl-sailing-container">
                <div className="booking-sailing-schelude-container air-booking-sailing-schelude">
                    {/* ADD SCHEDULE FOR AN AGENT */}

                    <div className="air-sailing-btns-holder">
                        <div
                            className="lcl-sailing-add-btn-holder add-schelude-btn"
                            onClick={() => {
                                if (checkPermission('edit', 'bp')) {
                                    navigate(`/air_booking_list/${id}/flight_schedule`, {
                                        state: { bpNumber: headerData?.bpNumber },
                                    });
                                }
                            }}
                        >
                            <button
                                className={`app-btn add-btn-lcl ${!checkPermission('edit', 'bp') ? 'disabled' : ''}`}
                            >
                                <div className="icon-content-wrapper">
                                    <div className="btn-icon-holder">
                                        <svg className="svg-icon calendar-icon">
                                            <use xlinkHref="#calendarIcon">
                                                <title>Calendar</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                        className="btn-content-holder"
                                    >
                                        <h2 className="heading1">Add Flight Schedule</h2>
                                        {/* <p className="btn-heading-content">Add Flight Schedule</p> */}
                                    </div>
                                </div>

                                <div className="btn-add-holder">
                                    <svg className="svg-icon plus-icon">
                                        <use xlinkHref="#plusIcon">
                                            <title>Add</title>
                                        </use>
                                    </svg>
                                </div>
                            </button>
                        </div>
                    </div>
                    {/* APPROVAL SCHELUDE FOR AN AGENT */}
                    {/*<div className="air-booking-flight-approval-grid">
                        <div className="selection-heading-holder">
                            <div>
                                <h2 className="selection-heading">Flight Schedules</h2>
                                <p className="selection-content">
                                    Add Sell Quote for the schedules for client approval
                                </p>
                            </div>
                        </div>
                        <ListGrid
                            data={sailingDataValues}
                            dates={dates}
                            rangeSelectFn={rangeSelectFn}
                            selectedValue={selectedValue}
                            bpStatus={bpStatus}
                            selectedRange={selectedRange}
                            setselectedColumn={setselectedColumn}
                            valueSelected={valueSelected}
                        ></ListGrid>
                    </div>*/}
                </div>
                {/*<SailingScheduleDetailModal></SailingScheduleDetailModal>*/}
            </div>
        );
    } else {
        return <div></div>;
    }
};
export default Index;
