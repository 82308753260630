import axios from 'axios';
import { get } from '../../../HttpMethods';

export const fetchAgentPortDetailsListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusFilterColumn: string;
    columnOrder: boolean;
    id: string;
}) => {
    try {
        const data = await get(
            `api/mgt/agents/portdetails/filter?agentId=${payload.id}&limit=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus || ''}&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterby=${payload.statusFilter}`
                    : ''
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterByColumn=${payload.statusFilterColumn}`
                    : ''
            }`,
            null
        );

        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchAgentPortDetailsColumnListData = async (payload: { columnName: string; id: string }) => {
    try {
        const data = await get(
            `api/mgt/agents/portdetails/distinct?columnName=${payload.columnName}&agentId=${payload.id}`,
            null
        );
        return { ...data, columnName: payload.columnName };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const searchAgentPortDetails = async (payload: {
    keyword: string;
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusFilterColumn: string;
    columnOrder: boolean;
    id: string;
}) => {
    try {
        const res = await get(
            `api/mgt/search/agentports?agentId=${payload.id}&keyword=${payload.keyword}&limit=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortby=${payload.columnStatus || ''}&sortorder=${
                payload.columnOrder ? 'ASC' : 'DESC'
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterby=${payload.statusFilter}`
                    : ''
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterByColumn=${payload.statusFilterColumn}`
                    : ''
            }`,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};
