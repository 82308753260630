import fileDownload from 'js-file-download';
import { del, get, patch, post, put } from '../HttpMethods';

export interface IData {
    id: number;
    name: string;
    email: string;
}
export interface vendorData {
    cl_pk: string;
    ve_pk: any;
}
export interface ProductType {
    pm_clientid: string;
    pm_sku: string;
    pm_purchasecode: string;
    pm_description: string;
    pm_vendorid: any;
    fumigation: boolean;
    pm_type?: 'product' | 'commodity';
}
export interface SopForm extends ProductType {
    pm_packets: [
        {
            pp_quantity: string;
            pp_conversionquantity: number;
            pp_length: number;
            pp_width: number;
            pp_height: number;
            pp_weight: number;
            pp_barcode: number;
            pp_dimensionuom: string;
            pp_weightuom: string;
        }
    ];
}

// name: string;
// url: string;
export interface CommodityType extends ProductType {
    pm_dangerousgood: boolean;
}

// Get All Datas
export const getVendorData = async () => {
    try {
        const res = await get('api/mgt/vendors?ve_status=ACTIVE', null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const getListProduct = async (payload: {
    currentPage: any;
    perPageCount: any;
    columnOrder: any;
    columnStatus: any;
    statusFilter: string;
    id: string;
}) => {
    try {
        const res = await get(
            `api/mgt/clients/products?UUID=${payload?.id}&limit=${payload?.perPageCount}&sortby=${
                payload?.columnStatus
            }&page=${payload?.currentPage}&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }`,
            null
        );
        return res;
    } catch (err) {
        return [];
    }
};
export const statusChange = async (payload: { pid: string; status: string }) => {
    try {
        const res = await patch(`api/mgt/clients/product/changestate/${payload.pid}/${payload.status}`, null);
        return { data: res };
    } catch (er: any) {
        console.log(er);
        return { data: { success: false, message: er?.response?.data?.message } };
    }
};

export const editProductapi = async (payload: any) => {
    try {
        // let result = {};
        if ((!payload.editRow || payload?.packetData?.length > 0) && payload?.packEdited) {
            payload.payload.forEach((element: any) => {
                let result = put(`/api/mgt/clients/packet/${element.pp_PK}`, element);
                return result;
            });
        }
        if (payload.editRow && payload.productEdited) {
            let result = await put(`/api/mgt/clients/product/${payload?.editID}`, payload.values);
            return result;
        }
        // return result;
    } catch (er) {
        return er;
    }
};
export const deletePacket = async (payload: any) => {
    try {
        let result = false;
        payload?.pp_pk?.forEach(async (element: any) => {
            const res = await del(`api/mgt/clients/packet?pp_pk=${element}&pm_pk=${payload.pm_pk}`, null);
            if (res?.success) {
                result = true;
            } else {
                result = false;
            }
        });
        return result;
    } catch (er) {
        return console.log(er);
    }
};

export const createProduct = async ({ payload }: { payload: CommodityType | SopForm }) => {
    try {
        const url = payload.pm_type === 'commodity' ? 'api/mgt/clients/commodity' : 'api/mgt/clients/product';
        delete payload.pm_type;
        const res = await post(url, payload, null);
        return res;
    } catch (er) {
        return er;
    }
};

export const exportProductFile = async (payload: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/mgt/clients/export/product?type=${payload.type}&clientId=${payload.profile} `,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = payload.type === 'excel' ? 'ProductMaster.xlsx' : 'ProductMaster.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        //return console.error(err, 'error message1231');
    }
};
export const createNewPacket = async (payload: any) => {
    try {
        let result = false;

        payload?.payload.forEach((element: any) => {
            const res = post('api/mgt/clients/packet', element, null);
            //return console.log(res, 'payload in api');
            if ((res as any)?.success) {
                result = true;
            } else {
                result = false;
            }
        });
        return result;
    } catch (er) {
        return console.log(er);
    }
};

export const checkSkunHsName = async (payload: any) => {
    try {
        const update = await get(
            `api/mgt/clients/skuhscode?type=${payload?.type}&value=${payload.value}&clientCode=${payload.clientCode}`,
            null
        );
        return update;
    } catch (er) {
        return console.log(er);
    }
};

export const searchProduct = async (payload: {
    currentPage: any;
    perPageCount: any;
    columnOrder: any;
    columnStatus: any;
    statusFilter: string;
    id: string;
    keyword: string;
}) => {
    try {
        const res = await get(
            `api/mgt/search/productmasterdetails?UUID=${payload?.id}&keyword=${payload?.keyword}&limit=${
                payload?.perPageCount
            }&sortby=${payload?.columnStatus}&page=${payload?.currentPage}&sortorder=${
                payload.columnOrder ? 'ASC' : 'DESC'
            }${payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''}`,
            null
        );
        console.log(res);
        return res;
    } catch (err) {
        return [];
    }
};
