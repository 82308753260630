import React, { useState } from 'react';
import BSTooltip from 'src/components/common/BSTooltip';
import '../TrendGraph/trend-graph.scss';
interface Props {
    data?: any;
    addedForCompare: any;
    setAddedForCompare?: any;
    getCount: any;
    filterValue: string;
}
const Index: React.FC<Props> = ({ data, addedForCompare, setAddedForCompare, getCount, filterValue }) => {
    const [search, setSearch] = useState('');

    const onSearchFn = () => {
        return data?.length
            ? data
                  ?.map((res: any) => ({
                      id: res.id,
                      agentName: Object.keys(res)[1],
                  }))
                  ?.filter((el: any) => {
                      return search ? el.agentName.toLocaleLowerCase().includes(search?.toLocaleLowerCase()) : true;
                  })
            : [];
    };

    const onSearch = onSearchFn();

    return (
        <>
            <div className="search-portion">
                <div className="badge-heading">
                    Top 20 Agents
                    <BSTooltip
                        id="view-8"
                        title={`Top 20 agents by total ${filterValue} utilization for last 12 months`}
                        className="grid-warning-icon"
                        position="left"
                        tooltipClassList="info-text"
                    >
                        <button className="app-btn inline-icon">
                            <span>
                                <svg className="svg-icon info-round-icon">
                                    <use xlinkHref="#infoIconRound"></use>
                                </svg>
                            </span>
                        </button>
                    </BSTooltip>
                </div>
                <div className="search-entry-holder">
                    <input
                        placeholder="Search"
                        type="text"
                        className="search-input input-text grid-search-field"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    ></input>
                    <svg className="svg-icon search-icon">
                        <use xlinkHref="#searchIcon"></use>
                    </svg>
                    <button className="app-btn text-close-btn" onClick={() => setSearch('')}>
                        <svg className="svg-icon text-close-icon">
                            <use xlinkHref="#closeIcon"></use>
                        </svg>
                    </button>
                </div>
                <div className="badge-agent-wrapper customscrollbar">
                    {onSearch?.map((res: any, index: number) => {
                        return (
                            <div key={res?.id}>
                                <div className="badge-btn">
                                    <div className="badge-sign count-no">
                                        #<span className="badge-sign-no">{index + 1}</span>
                                    </div>
                                    <div className="badge-name">{res?.agentName}</div>
                                    <div className="badge-num-icon">
                                        {getCount(res?.id, res?.agentName)}
                                        {/* <span className="icon-holder">
                                            <svg className="svg-icon container-icon ">
                                                <use xlinkHref="#containerIcon"></use>
                                            </svg>
                                        </span> */}
                                    </div>

                                    {!addedForCompare?.some((el: any) => el.id === res?.id) ? (
                                        <div className=" badge-sign app-btn">
                                            <button
                                                className={`app-btn inline-icon app-btn-secondary  ${
                                                    addedForCompare.length > 4 ? 'disabled' : ''
                                                }`}
                                                title={` ${
                                                    addedForCompare.length > 4
                                                        ? 'Upto 5 values can only be added to compare. Remove any existing values to replace'
                                                        : 'Add'
                                                }`}
                                                onClick={() =>
                                                    ` ${
                                                        addedForCompare.length > 4
                                                            ? null
                                                            : setAddedForCompare((prev: any) => [...prev, res])
                                                    }`
                                                }
                                            >
                                                <svg className="svg-icon plus-icon">
                                                    <use xlinkHref="#plusIcon">
                                                        <title>Upload File</title>
                                                    </use>
                                                </svg>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="badge-sign sm">
                                            <span>Added</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};
export default Index;
