import { useState } from 'react';
import { Tooltip } from 'reactstrap';
function BSTooltip(props: any) {
    const { position = 'top', id, classList = '', tooltipClassList = '', delay = 0 } = props;
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggle = () => setTooltipOpen(!tooltipOpen);
    return (
        <span className={`${classList}`}>
            <span id={'tooltip-' + id}>{props.children}</span>
            <Tooltip
                placement={position}
                style={props.title?.length > 30 ? { width: '', textAlign: 'center' } : {}}
                isOpen={tooltipOpen}
                target={`tooltip-${id}`}
                toggle={toggle}
                className={`${tooltipClassList}`}
                delay={delay}
            >
                {props.title}
            </Tooltip>
        </span>
    );
}
export default BSTooltip;
