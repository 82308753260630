import { get, post } from '../../HttpMethods';

export interface SplitLineData {
    splitLineId: string;
    splitLineStatus: string;
    confirmedQty: number;
    confirmedGoodsdate: string;
    confirmedCbm: number;
    confirmedWeight: number;
    createdByUser: string;
    createdDateTime: string;
    modifiedByUser: string;
    modifiedDateTime: string;
    manuallyEntered: boolean;
}

export interface PoLineData {
    poProductId: string;
    sku: string;
    description: string;
    originPort: string;
    destinationPort: string;
    splitLines: SplitLineData[];
}

export interface listPoLineResponse {
    splitLineDataResList: PoLineData[];
}

export const getAvailablePoLines = async (payload: { cgId: string }) => {
    try {
        const res = await get(`api/bkg/bp/confirmationgroups/polines?cgId=${payload.cgId}&type=fcl`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const searchAvailablePoLines = async (payload: { cgId: string; keyword: string }) => {
    try {
        const res = await post(
            `api/ord/search/availablepolines?keyword=${payload.keyword}&cgId=${payload.cgId}&type=fcl`,
            null,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
