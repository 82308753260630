/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import '../PurchaseOrder/reassign.scss';
import RejectReassignmentModal from './RejectReassignmentModal';
// import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchReassignApprovalList,
    fetchReassignApprovalDistinctList,
    selectReassignApproval,
    selectDistinctApproval,
    selectReassignApprovalLoading,
    ReassignApprovalDetails,
} from 'src/store/reducers/reassignAgent/approvalReducer';
import { getOrganisationData } from 'src/utils';
import { useUserProfile } from 'src/hooks/useUserProfile';
import moment from 'moment';
import ColumnFilter from '../Filter';
import Header from '../Header';
import CancelModal from 'src/components/common/CancelModal';
// import ReassignModal from '../ReassignModal';
import { reassignAgentApprovalDetails } from 'src/utils/tableStructure/reassignAgentApproval';
import TableToolTip from 'src/components/common/TableToolTip';
import ApproveReassignmentModal from './ApproveReassignmentModal';
const Index: React.FC = () => {
    // const { type } = useParams();
    const dispatch = useAppDispatch();
    const { userName, profileType } = useUserProfile();
    const organisationData = getOrganisationData();

    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>({});
    const [sortColumn, setSortColumn] = useState('bpNumber');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [filterColumn, setFilterColumn] = useState('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [selected, setSelected] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const [displayApproveModal, setDisplayApproveModal] = useState(false);
    const [rowExpand, setRowExpand] = useState(false);
    const [rowLine, setRowLine] = useState(null);
    const [columnExpand, setColumnExpand] = useState(null);

    const payload = {
        body: {
            ...organisationData,
        },
        sortColumn,
        sortOrder,
        userName,
        profileType,
    };

    useEffect(() => {
        dispatch(fetchReassignApprovalDistinctList({ ...payload }));
    }, []);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        setSearchName(keyword);
    };

    const filterDistance = (columnName: string) => {
        const el = document.getElementById(columnName);
        const distanceLeft = el?.getBoundingClientRect().left ?? 0;
        const distanceRight = el?.getBoundingClientRect().right ?? 0;
        if (window.innerWidth - distanceRight > 280) {
            setSetPosition(distanceRight - 60);
        } else {
            setSetPosition(distanceLeft - 195);
        }
    };

    const filterByColumn = (e: any, columnName: string) => {
        setFilterColumn(columnName);
        filterDistance(columnName);
        setSearchName('');
        filter(e);
        e.stopPropagation();
        setToggleDropdown((prev) => !prev);
    };

    const searchOrFilter = (newPayload: any, searchKey?: string) => {
        const bpSearch = searchKey !== undefined ? searchKey : searchTerm;
        if (bpSearch?.trim()?.length > 1) {
            const finalPayload = { ...newPayload };
            finalPayload.body = { ...finalPayload.body, search: bpSearch };
            dispatch(fetchReassignApprovalList({ ...finalPayload }));
        } else {
            dispatch(fetchReassignApprovalList({ ...newPayload }));
        }
    };

    const sortByColumn = (columnName: string) => {
        const newOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newOrder);
        setSortColumn(columnName);
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
        }
        searchOrFilter({ ...payload, sortColumn: columnName, sortOrder: newOrder });
    };

    const onSearch = (searchKey: string) => {
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
        }
        if (searchKey.length > 2 || !searchKey.length) {
            searchOrFilter(payload, searchKey);
        }
        setSearchTerm(searchKey);
    };

    const onApprovalSelection = (bpDetail: ReassignApprovalDetails) => {
        const bpSelected = JSON.parse(JSON.stringify(selected));
        const alreadyExists = bpSelected.findIndex(
            (res: any) =>
                res.bpNumber === bpDetail.bpNumber && JSON.stringify(bpDetail.poNumber) == JSON.stringify(res.poNumber)
        );
        if (alreadyExists >= 0) {
            const filtered = bpSelected.filter((res: any) =>
                res.bpNumber
                    ? res.bpNumber !== bpDetail.bpNumber &&
                      JSON.stringify(res.poNumber) !== JSON.stringify(bpDetail.poNumber)
                    : JSON.stringify(res.poNumber) !== JSON.stringify(bpDetail.poNumber)
            );
            setSelected(filtered);
        } else {
            const filtered = [...bpSelected, bpDetail];
            setSelected(filtered);
        }
    };

    const filterData = () => {
        if (filterColumn === 'goodsReadyDate' || filterColumn === 'plannedEtd' || filterColumn === 'plannedEta') {
            return distinctBp[filterColumn]?.length
                ? distinctBp[filterColumn].map((res: string) => moment(res).format('DD-MM-YYYY'))
                : [];
        }
        return distinctBp[filterColumn];
    };

    useEffect(() => {
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
            searchOrFilter(payload);
        } else {
            searchOrFilter(payload);
        }
    }, [statusFilterSelected]);

    const reassignApproval = useAppSelector(selectReassignApproval);
    const distinctBp = useAppSelector(selectDistinctApproval);
    const isLoading = useAppSelector(selectReassignApprovalLoading);

    const selectAllStatus = (property: string) => {
        if (property === 'disabled') {
            return false;
        }
        if (!reassignApproval?.length) {
            return false;
        }
        return reassignApproval.every((selectedItem: ReassignApprovalDetails) =>
            selected.some(
                (select: ReassignApprovalDetails) =>
                    selectedItem.bpNumber === select.bpNumber &&
                    JSON.stringify(selectedItem.poNumber) == JSON.stringify(select.poNumber)
            )
        );
    };

    const selectAllOnChange = (checkStatus: boolean) => {
        // const filtered = reassignApproval.filter(
        //     (e: any) => e.agentName === selected[0].agentName && e.originPort === selected[0].originPort
        // );
        if (checkStatus) {
            setSelected(reassignApproval);
        } else {
            setSelected([]);
        }
    };
    return (
        <>
            <div className="main-wrapper container reassign-grid-wrapper">
                <div className="main-content-holder no-main-grid-border purchase-grid">
                    <h2 className="page-heading">Agent Reassignment Approval</h2>
                    {isLoading && (
                        <div className="app-loader loading" style={{ zIndex: 101 }}>
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    {(reassignApproval === null || reassignApproval?.length) === 0 && searchTerm === '' ? (
                        <div className="grid-no-content empty-page-no-content">
                            <div className="icon-no-notification">
                                <svg className="svg-icon no-pending-file-icon">
                                    <use xlinkHref="#noPendingFileIcon">
                                        <title>Notification</title>
                                    </use>
                                </svg>
                            </div>
                            <h2 className="no-content-message">No Pending Approvals</h2>
                            <p className="no-content-message-detail">
                                No POs/Bookings are pending reassignment approval
                            </p>
                        </div>
                    ) : (
                        <>
                            <div className="grid-section reassign-grid ">
                                <div className="grid-holder">
                                    <div className="grid-header">
                                        <div className="search-field-purchase-grid">
                                            <div className="search-entry-holder">
                                                <input
                                                    placeholder="Search PO, Bookings"
                                                    type="text"
                                                    className="search-input input-text"
                                                    value={searchTerm ?? ''}
                                                    onChange={(e) => onSearch(e.target.value)}
                                                ></input>
                                                <div
                                                    className={`search-toolip ${
                                                        searchTerm?.length > 0 && searchTerm?.length < 3
                                                            ? 'tooltip-show'
                                                            : ''
                                                    }`}
                                                >
                                                    Enter atleast 3 characters to search
                                                </div>
                                                <svg className="svg-icon search-icon">
                                                    <use href="#searchIcon"></use>
                                                </svg>
                                                <button onClick={() => onSearch('')} className="app-btn text-close-btn">
                                                    <svg className="svg-icon text-close-icon">
                                                        <use xlinkHref="#closeIcon"></use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-container">
                                        {(reassignApproval === null || reassignApproval?.length) === 0 && (
                                            <div className="grid-no-content ">
                                                <svg className="svg-icon grid-no-content-icon ">
                                                    <use xlinkHref="#gridNoContentIcon"></use>
                                                </svg>
                                                <p className="no-content-message">No records found</p>
                                            </div>
                                        )}
                                        <div className="grid-scroll-content">
                                            <table className="grid-table">
                                                <Header
                                                    content={reassignAgentApprovalDetails}
                                                    filterFunction={filterByColumn}
                                                    sortFunction={sortByColumn}
                                                    sortColumn={sortColumn}
                                                    sortOrder={sortOrder}
                                                    filterColumn={filterColumn}
                                                    toggleDropdown={toggleDropdown}
                                                    selectedFilterItem={statusFilterSelected}
                                                    selectAllStatus={selectAllStatus}
                                                    selectAllOnChange={selectAllOnChange}
                                                />

                                                <tbody className="has-child row-expanded">
                                                    {reassignApproval?.map(
                                                        (res: ReassignApprovalDetails, index: number) => (
                                                            <tr key={index}>
                                                                <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                                                    <div className="grid-cell-data centre-align">
                                                                        <label className="app-check-wrapper">
                                                                            <input
                                                                                type="checkbox"
                                                                                checked={selected?.some(
                                                                                    (e: any) =>
                                                                                        e.bpNumber === res.bpNumber &&
                                                                                        JSON.stringify(e.poNumber) ==
                                                                                            JSON.stringify(res.poNumber)
                                                                                )}
                                                                                onChange={() =>
                                                                                    onApprovalSelection(res)
                                                                                }
                                                                                className="checkbox-input"
                                                                            />
                                                                            <div className="checkmark">
                                                                                <svg className="svg-icon tick-icon">
                                                                                    <use xlinkHref="#tickIcon">
                                                                                        <title>check mark</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    className={`grid-cell col-po-number grid-sticky-column sticky-left-aligned po-number-grid ${
                                                                        res.poNumber?.length > 1 &&
                                                                        rowExpand &&
                                                                        rowLine === index
                                                                            ? 'showing-menu'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    <div className="grid-cell-data">
                                                                        <TableToolTip
                                                                            data={
                                                                                res.poNumber?.length ? res.poNumber : []
                                                                            }
                                                                            index={index}
                                                                            settableRowExp={setRowExpand}
                                                                            rowexpand={rowExpand}
                                                                            rowLine={rowLine}
                                                                            setRowLine={setRowLine}
                                                                            itemId="poNumber"
                                                                            columnExpand={columnExpand}
                                                                            setColumnExpand={setColumnExpand}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell col-booking-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                                    <div className="grid-cell-data">
                                                                        {res.bpNumber ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.currentAgentName ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.newAgentName ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.clientName?.length > 0 ? (
                                                                            <TableToolTip
                                                                                data={
                                                                                    res.clientName?.length
                                                                                        ? res.clientName
                                                                                        : []
                                                                                }
                                                                                index={index}
                                                                                settableRowExp={setRowExpand}
                                                                                rowexpand={rowExpand}
                                                                                rowLine={rowLine}
                                                                                setRowLine={setRowLine}
                                                                                itemId="clientName"
                                                                                columnExpand={columnExpand}
                                                                                setColumnExpand={setColumnExpand}
                                                                            />
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.dateRequested ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.originPort ?? '-'}
                                                                    </div>
                                                                </td>

                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.destinationPort ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.destinationType ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.mode ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        <TableToolTip
                                                                            data={
                                                                                res.vendorName?.length
                                                                                    ? res.vendorName
                                                                                    : []
                                                                            }
                                                                            index={index}
                                                                            settableRowExp={setRowExpand}
                                                                            rowexpand={rowExpand}
                                                                            rowLine={rowLine}
                                                                            setRowLine={setRowLine}
                                                                            itemId="vendorName"
                                                                            columnExpand={columnExpand}
                                                                            setColumnExpand={setColumnExpand}
                                                                        />
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.etd ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.eta ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.releaseType?.length > 0 ? (
                                                                            <TableToolTip
                                                                                data={
                                                                                    res.releaseType?.length
                                                                                        ? res.releaseType
                                                                                        : []
                                                                                }
                                                                                index={index}
                                                                                settableRowExp={setRowExpand}
                                                                                rowexpand={rowExpand}
                                                                                rowLine={rowLine}
                                                                                setRowLine={setRowLine}
                                                                                itemId="releaseType"
                                                                                columnExpand={columnExpand}
                                                                                setColumnExpand={setColumnExpand}
                                                                            />
                                                                        ) : (
                                                                            '-'
                                                                        )}
                                                                    </div>
                                                                </td>

                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.carrierFlights ?? '-'}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res.submittedBy ?? '-'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    {toggleDropdown && (
                                        <ColumnFilter
                                            setPosition={setPosition}
                                            searchName={searchName}
                                            setSearchName={setSearchName}
                                            filter={filter}
                                            data={filterData()}
                                            statusFilterSelected={statusFilterSelected}
                                            setStatusFilterSelected={setStatusFilterSelected}
                                            isLoading={isLoading}
                                            setToggleDropdown={setToggleDropdown}
                                            columnName={filterColumn}
                                            currentColumnName={filterColumn}
                                            setCurrentColumnName={setFilterColumn}
                                        />
                                    )}
                                </div>
                            </div>

                            <div className="footer-bootom-row">
                                <div className="footer-button-holder ">
                                    <div className="tab-with-proposal">
                                        <div className="tab-grid-select-holder">
                                            {selected?.length > 0 && (
                                                <span className="btn-pills info-pill">
                                                    <div className="pill-text">{selected?.length} Selected</div>
                                                    <button
                                                        onClick={() => setSelected([])}
                                                        className="pill-action"
                                                        type="button"
                                                    >
                                                        <svg className="svg-icon close-icon">
                                                            <use xlinkHref="#closeIcon"></use>
                                                        </svg>
                                                    </button>
                                                </span>
                                            )}
                                            <button
                                                disabled={selected?.length ? false : true}
                                                className="app-btn app-btn-secondary footer-btn"
                                                title="Reject"
                                                onClick={() => setShowModal(true)}
                                            >
                                                <span className="button-text footer-button-text">Reject</span>
                                            </button>
                                            <button
                                                disabled={selected?.length ? false : true}
                                                className="app-btn app-btn-primary footer-btn"
                                                title="Approve"
                                                onClick={() => {
                                                    setDisplayApproveModal(true);
                                                }}
                                            >
                                                <span className="button-text footer-button-text">Approve</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {showModal && (
                    <RejectReassignmentModal
                        setSelected={setSelected}
                        payload={payload}
                        data={selected}
                        setShowModal={setShowModal}
                        setDisplayCancel={setDisplayCancel}
                    />
                )}
                {displayApproveModal && (
                    <ApproveReassignmentModal
                        setSelected={setSelected}
                        payload={payload}
                        data={selected}
                        setDisplayApproveModal={setDisplayApproveModal}
                    />
                )}
                <CancelModal
                    displayFunction={setShowModal}
                    mode="edit"
                    header="Reassign"
                    cancelModalVisibility={displayCancel}
                    setCancelModalVisibility={setDisplayCancel}
                />
            </div>
        </>
    );
};
export default Index;
