import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'receiptPoLine',
    initialState: {
        isLoading: true,
        poLine: [],
        inboundPos: [],
        assignedSuccess: false,
        removeSuccess: false,
    },
    reducers: {
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        fetchPolineReceipt: (_state, _action) => {
            // console.log(action);
        },
        fetchAllPoLines: (_state, _action) => {
            // console.log(action);
        },
        saveAllPoLines: (state, action) => {
            state.inboundPos = action.payload;
        },
        getASsignedPos: (state, action) => {
            state.poLine = action.payload;
        },
        assingPoLines: (_state, _action) => {
            // console.log(action);
        },
        successAssigning: (state, action) => {
            state.assignedSuccess = action.payload;
        },
        removePoFromReceipt: (_state, _action) => {
            // console.log(action);
        },
        removeSuccessFn: (state, action) => {
            state.removeSuccess = action.payload;
        },
    },
});

export const {
    fetchPolineReceipt,
    isLoadingFn,
    getASsignedPos,
    fetchAllPoLines,
    saveAllPoLines,
    assingPoLines,
    successAssigning,
    removePoFromReceipt,
    removeSuccessFn,
} = dataSlice.actions;
export default dataSlice.reducer;
