import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchProductPoConfirmationList,
    fetchProductPoConfirmationListFailure,
    fetchProductPoConfirmationListSuccess,
    confirmPoSuccess,
    confirmPoFailure,
    submitPoSuccess,
    submitPoFailure,
    confirmPo,
    submitPo,
    removeProductPoConfirmationSuccess,
    removeProductPoConfirmationFailure,
    removeProductPoConfirmation,
    confirmAllPo,
    confirmAllPoSuccess,
    confirmAllPoFailure,
    changePoLineStatus,
    editPoLineStatus,
    editPoLineStatusSuccess,
    editPoLineStatusFailure,
    changePoLineStatusSuccess,
    changePoLineStatusFailure,
    submitPoBlpSuccess,
    submitPoBlpFailure,
    submitPoBlp,
} from '../../reducers/purchaseOrder/productPoConfirmationReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchProductPoConfirmationListDetailsApi,
    confirmPoDetailsApi,
    submitPoDetailsApi,
    removeProductPoConfirmationDetailsApi,
    confirmAllPoDetailsApi,
    changePoLineStatusApi,
    editPoLineStatusApi,
    submitPoBlpDetailsApi,
} from '../../../services/apis/purchaseOrder/productPoConfirmationApi';

// eslint-disable-next-line
function* getProductPoConfirmationDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchProductPoConfirmationListDetailsApi, payload);
        yield put({ type: fetchProductPoConfirmationListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchProductPoConfirmationListFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* confirmPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(confirmPoDetailsApi, payload);
        yield put({ type: confirmPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: confirmPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* confirmAllPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(confirmAllPoDetailsApi, payload);
        yield put({ type: confirmAllPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: confirmAllPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* submitPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(submitPoDetailsApi, payload);
        yield put({ type: submitPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: submitPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* submitPoBlpDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(submitPoBlpDetailsApi, payload);
        yield put({ type: submitPoBlpSuccess, payload: response });
    } catch (error) {
        yield put({ type: submitPoBlpFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* changePoLineStatusData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(changePoLineStatusApi, payload);
        yield put({ type: changePoLineStatusSuccess, payload: response });
    } catch (error) {
        yield put({ type: changePoLineStatusFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* editPoLineStatusData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(editPoLineStatusApi, payload);
        yield put({ type: editPoLineStatusSuccess, payload: response });
    } catch (error) {
        yield put({ type: editPoLineStatusFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* removeProductPoConfirmationDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(removeProductPoConfirmationDetailsApi, payload);
        yield put({ type: removeProductPoConfirmationSuccess, payload: response });
    } catch (error) {
        yield put({ type: removeProductPoConfirmationFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* productPoConfirmationSaga() {
    yield takeEvery(fetchProductPoConfirmationList, getProductPoConfirmationDetails);
    yield takeEvery(confirmPo, confirmPoDetailsData);
    yield takeEvery(confirmAllPo, confirmAllPoDetailsData);
    yield takeEvery(submitPo, submitPoDetailsData);
    yield takeEvery(submitPoBlp, submitPoBlpDetailsData);
    yield takeEvery(changePoLineStatus, changePoLineStatusData);
    yield takeEvery(editPoLineStatus, editPoLineStatusData);
    yield takeEvery(removeProductPoConfirmation, removeProductPoConfirmationDetailsData);
}

export default productPoConfirmationSaga;
