import React from 'react';
// import BookingProposalHeader from '../../BookingProposalHeader';
import { Link } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../../BookingProposal/POSelection.scss';
import '../../../BookingProposal/BookingChangeVessel/ManualVesselEntry/manual-vessel-entry.scss';
// import { useFormik } from 'formik';
import { useLottie } from 'lottie-react';
import containerShip from '../../../../assets/animation/containerShip.json';
import '../../../BookingProposal/BookingChangeVessel/SelectedFromSchedule/selected-from-schedule.scss';
// import BookingSailingSchelude from '../../BookingSailingSchelude';
const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: containerShip,
    rendererSettings: {
        className: 'lottie-svg-class',
        id: 'lottie-svg-id',
    },
};
const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];

const Index: React.FC = () => {
    const { View } = useLottie(defaultOptions);
    return (
        <>
            <div className="main-wrapper container selected-change-vessel-wrapper">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={'#'}
                        thirdName={'Sailing Schedule'}
                        thirdUrl={'#'}
                        fourthName={'Change Vessel'}
                        fourthUrl={'#'}
                        summaryStats={summaryStats}
                        backUrl={'/purchase_order'}
                    />
                    <div className="pageheading-holder">
                        <div className="back-btn-holder">
                            <Link to="/purchase_order">
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </Link>
                            <h2 className="page-heading">Select from Schedules</h2>
                        </div>
                    </div>
                    {/* {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )} */}
                </div>
                {/* DATA GRID SECTION */}
                <div className="content-section main-wrapper change-selected-vessel-detail">
                    <div className="main-wrapper">
                        <div className="no-data-content checking-data-content">
                            {View}
                            <p className="no-content-message">Checking for available contracts</p>
                        </div>
                        {/* <BookingSailingSchelude></BookingSailingSchelude> */}
                    </div>
                </div>
                <div className="footer-bootom-row backdrop-active">
                    <div className="footer-button-holder ">
                        <>
                            <a title="Cancel Booking" className="app-btn app-btn-secondary footer-btn">
                                <span className="button-text footer-button-text">Cancel Booking</span>
                            </a>
                            <Link title="Previous" className="app-btn app-btn-secondary footer-btn" to={''}>
                                <span className="button-text footer-button-text">Previous</span>
                            </Link>
                            <a title="Next" className="app-btn app-btn-secondary footer-btn">
                                <span className="button-text footer-button-text">Next</span>
                            </a>
                            <a title="Done" className="app-btn app-btn-secondary footer-btn">
                                <span className="button-text footer-button-text">Done</span>
                            </a>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
