import { getClientCode, sleep } from 'src/utils';
import { get, patch, post } from '../../HttpMethods';
import { convertObjectToUrlParams } from 'src/utils/dataUtil';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import '../../../assets/scss/components/_modal.scss';
import axios from 'axios';
import moment from 'moment';

export const getBpHeaderData = async (payload: { bpId: string; profileType: string }) => {
    try {
        let clientCode = null;
        if (payload.profileType === 'client') {
            clientCode = await getClientCode(payload.profileType);
        }
        const res = await get(
            `api/bkg/bp/header/${payload.bpId}/${payload.profileType}${clientCode ? `?clientCode=${clientCode}` : ''}`,
            null
        );
        return res.info;
    } catch (err) {
        return err;
    }
};

export const getLinkedBookingsApi = async (payload: { bpNumber: string; type: string }) => {
    try {
        if (payload.type === 'PAR') {
            const res = await get(`cix/scr/forwarding/booking/${payload.bpNumber}`, null, externalBaseUrl);
            return res.data;
        } else {
            const res = await get(
                `cix/scr/forwarding/booking/?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B1%5D%5Bfield%5D=BP_Number&filter%5Bfilters%5D%5B1%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B1%5D%5Bvalue%5D=${payload.bpNumber}`,
                null,
                externalBaseUrl
            );
            return res.data;
        }
    } catch (err) {
        return err;
    }
};

export const overrideShipmentDetails = async (payload: { bpId: string; reason: string; data: any }) => {
    try {
        const res = await patch(
            `/cix/scr/forwarding/booking/${payload.bpId}/additionaldetails/override/shipment`,
            payload.data,
            externalBaseUrl
        );
        return res.data;
    } catch (err) {
        return err;
    }
};

export const getOverrideShipmentDetails = async (payload: { bpId: string }) => {
    try {
        const res = await get(
            `/cix/scr/forwarding/booking/${payload.bpId}/additionaldetails/override/shipment`,
            null,
            externalBaseUrl
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const sendBCNChildToCWApi = async (bpId: string, changeBcnType?: string) => {
    try {
        let query = '';
        if (changeBcnType) {
            query = `&type=${changeBcnType}`;
        }
        await post(`api/bkg/bp/sendBCNChildtoCW?bpPk=${bpId}${query}`, null, null);
        return true;
    } catch (error) {
        return error;
    }
};

export const getAgentBcnParentBpData = async (payload: { clientCode: string; destinationPort: string }) => {
    try {
        const res = await get(
            `/cix/scr/forwarding/booking/?attributes=BP_PK,BP_Number,BP_DestinationPort,BP_OriginPort,PO_StartShipWindowDate,PO_EndShipWindowDate,BP_StatusCode&filter[logic]=and&filter[filters][4][field]=BP_DestinationPort&filter[filters][4][operator]=eq&filter[filters][4][value]=${payload.destinationPort}&filter[filters][3][field]=BP_CD_BCNType&filter[filters][3][operator]=eq&filter[filters][3][value]=PAR&filter[filters][2][field]=BP_ClientCode&filter[filters][2][operator]=eq&filter[filters][2][value]=${payload?.clientCode}&filter[filters][1][field]=BP_StatusCode&filter[filters][1][operator]=lt&filter[filters][1][value]=180&take=50&skip=0&page=1&pageSize=100`,
            null,
            externalBaseUrl
        );
        return res.data?.map((res: any) => {
            var originPort = 'N/A';
            var destPort = 'N/A';
            var startShipWindow = 'N/A';
            var endShipWindow = 'N/A';
            try {
                originPort = res.BP_OriginPort?.split('(')?.pop()?.replace(')', '');
            } catch (e) {}
            try {
                destPort = res.BP_DestinationPort?.split('(')?.pop()?.replace(')', '');
            } catch (e) {}
            try {
                startShipWindow = moment(res.PO_StartShipWindowDate).format('DD-MMM');
            } catch (e) {}
            try {
                endShipWindow = moment(res.PO_EndShipWindowDate).format('DD-MMM');
            } catch (e) {}

            return {
                label: `${res?.BP_Number} (${originPort} -> ${destPort}) (SSW:${startShipWindow}/ESW:${endShipWindow})`,
                value: res?.BP_PK,
            };
        });
    } catch (err) {
        return err;
    }
};
export const changeBcnParentBpData = async (payload: any) => {
    try {
        const res = await patch(`/cix/bkg/${payload.bpId}`, { ...payload?.body }, externalBaseUrl);
        await sendBCNChildToCWApi(payload.bpId, 'DBP');
        return { message: res?.message, type: 'changeType' };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const changeBcnTeuData = async (payload: any) => {
    try {
        const res = await patch(`/cix/bkg/${payload.bpId}`, { BP_TotTEU: payload?.estimatedTeu }, externalBaseUrl);
        return { message: res?.message, type: 'estimatedTeu' };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const updateResetBcnBackData = async (payload: any) => {
    try {
        const res = await patch(`/cix/bkg/${payload.bpId}`, { BP_CD_Status: 'ACO' }, externalBaseUrl);
        return { message: res?.message, type: 'resetBp' };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const resetBpToBookingProposal = async (payload: any) => {
    try {
        const res = await post(
            '/api/bkg/bp/resetbookingstatus?bpId=' + payload?.bpId,
            { reason: payload?.reason, containerAdded: payload?.flag },
            null,
            false,
            null
        );
        if (payload?.fromBcn) {
            await propagateBcnData(payload?.bpId, true);
        }
        return { message: res?.message, type: 'resetBp', status: res?.success };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const propagateBcnData = async (
    bpId: string | undefined,
    sendBCNChildToCW?: boolean,
    changeBcnType?: string
) => {
    try {
        await sleep(3000);
        const res = await patch(`/cix/bkg/${bpId}?action=propagate`, null, externalBaseUrl);
        if (sendBCNChildToCW) {
            await sendBCNChildToCWApi(bpId ?? '', changeBcnType);
        }
        return { message: res?.message, type: 'resetBp' };
    } catch (error) {
        console.log('error message: ', error);
    }
};
export const getAgentIntegrationData = async (payload: { agentCode: string }) => {
    try {
        const res = await get(
            `api/bkg/agents/integration?agentCode=${payload.agentCode}&integration=Goods Issued`,
            null
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const rejectionReasonsAPI = async (payload: any) => {
    try {
        const res = await get('api/bkg/bp/rejectionhistory', payload);
        return res;
    } catch (err) {
        return err;
    }
};

export const cancelBookingProposal = async (payload: {
    bpId: string;
    name: string;
    userName: string;
    reason: string;
    cancelDate: string;
}) => {
    try {
        const res = await patch(
            `api/bkg/bp/cancel/${payload.bpId}?name=${payload.name}&userName=${payload.userName}&reason=${
                payload.reason
            }
            ${payload.cancelDate ? '&cancelDate=' + payload.cancelDate : ''}`,
            null
        );
        return res;
    } catch (err: any) {
        throw err.response;
    }
};
export const updateAutoApprovalFailedViewStatus = async (payload: { bpId: string; profileType: string }) => {
    try {
        const res = await patch(
            `api/bkg/bp/closemodal/${payload.bpId}/${payload.profileType}/autoapprovalfailed`,
            null
        );
        return res;
    } catch (err: any) {
        throw err.response;
    }
};

export const updateBookingHeader = async (payload: { bpId: string; keyValuePairs: { [key: string]: string } }) => {
    try {
        const encodedUrlParams = convertObjectToUrlParams(payload.keyValuePairs);

        const res = await patch(`api/bkg/bp/bpHeader/${payload.bpId}?${encodedUrlParams}`, null);
        return res;
    } catch (err: any) {
        throw err.response;
    }
};

export const getUnreadBpNotes = async (bpId?: string) => {
    try {
        const response = await get(
            `cix/scr/utility/notes/${bpId}?take=10&skip=0&page=1&pageSize=10`,
            null,
            externalBaseUrl
        );
        const filtered = response?.data?.filter((res: any) => res?.NT_V_IsRead === false);
        return filtered?.length ? true : false;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
