/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import '../PurchaseOrderHeader/purchase-order-header.scss';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import '../BasicDetail/purchase-basic-detail.scss';
import '../po-tab.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    // fetchClient,
    fetchVendor,
    selectAddPoLoading,
    // selectClientDetails,
    selectVendorDetails,
    savePo,
    // selectAddPo,
    selectAddPoIsError,
    selectAddPoError,
    selectAddPoSuccess,
    clearFailure,
} from '../../../store/reducers/purchaseOrder/addPoReducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { error, Position, success } from '../../../utils/toast';
import { Link, useNavigate } from 'react-router-dom';
// import { useUserProfile } from '../../../hooks/useUserProfile';
import { getOrganisationData } from 'src/utils';
import { useUserProfile } from 'src/hooks/useUserProfile';

interface AddPoForm {
    poNumber: string;
    clientCode: string;
    clientName: string;
    vendorCode: string;
    vendorName: string;
    createdUser: string;
    createdUserId: string;
}

const Index: React.FC = () => {
    const organisationData = getOrganisationData();
    const { profileType } = useUserProfile();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const clientDetails = organisationData?.poClients?.length
        ? organisationData?.poClients.map((res: any) => ({ value: res.orgCode, label: res.orgName, id: res.id }))
        : [];
    const vendorDetails = useAppSelector(selectVendorDetails);
    const isLoading = useAppSelector(selectAddPoLoading);
    const isError = useAppSelector(selectAddPoIsError);
    // const addPo = useAppSelector(selectAddPo);
    const addPoSuccess = useAppSelector(selectAddPoSuccess);
    const addPoError = useAppSelector(selectAddPoError);
    // const { profileType } = useUserProfile();
    const [disableVendor, setDisableVendor] = useState(false);
    const [clientUid, setClientUid] = useState('');
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userName = '';
    let userFullName = '';
    let clientEmailId = '';
    if (userData) {
        const { user } = JSON.parse(userData);
        const { roles } = JSON.parse(userData);
        userName = user?.us_UserName;
        userFullName = user?.us_FirstName + ' ' + user?.us_LastName;
        if (roleIndex) {
            clientEmailId = roles[roleIndex].profile?.toLowerCase() === 'client' ? user?.us_Email : null;
        }
    }

    // useEffect(() => {
    //     dispatch(fetchClient({ userName, profile: profileType }));
    // }, [dispatch]);

    const initialValues: AddPoForm = {
        poNumber: '',
        clientCode: '',
        clientName: '',
        vendorCode: '',
        vendorName: '',
        createdUser: userFullName,
        createdUserId: userName,
    };

    const AddPoFormSchema = Yup.object().shape({
        poNumber: Yup.string().required('PO Number is required'),
        clientCode: Yup.string().required('Client Code is required'),
        vendorCode: Yup.string().required('Vendor Code is required'),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddPoFormSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                dispatch(
                    savePo({
                        ...values,
                        poNumber: values.poNumber?.trim(),
                        clientId: clientUid,
                        clientEmailId,
                        type: 'IBPO',
                        profileType,
                    })
                );
            }
        },
    });

    useEffect(() => {
        if (isError) {
            error(addPoError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            document?.getElementById('name_div')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (addPoSuccess?.status === 200 || addPoSuccess?.status === 201) {
            success(`Inbound Purchase Order ${addPoSuccess?.data?.poNumber} created successfully`, Position.TOP_RIGHT);
            localStorage.setItem('poNumber', addPoSuccess?.data?.poNumber);
            localStorage.setItem('poStatus', 'Draft');
            setTimeout(() => {
                formik.resetForm();
                navigate(`/inbound/${addPoSuccess?.data?.poId}/basic_details`);
            }, 2000);
            dispatch(clearFailure([]));
        }
    }, [isError, addPoSuccess, addPoError]);

    useEffect(() => {
        formik.setFieldValue('vendorCode', '');
        formik.setFieldValue('vendorName', '');
        if (formik.values.clientCode) {
            const uid = clientDetails?.find((element: any) => element.value === formik.values.clientCode);
            dispatch(fetchVendor({ clientCode: formik.values.clientCode }));
            setDisableVendor(false);
            setClientUid(uid.id);
            localStorage.setItem('clientName', uid.label);
        } else {
            setDisableVendor(true);
        }
    }, [formik.values.clientCode]);

    useEffect(() => {
        if (!formik.values.vendorCode) {
            formik.setFieldValue('vendorCode', '');
            formik.setFieldValue('vendorName', '');
        }
    }, [formik.values.vendorCode]);

    useEffect(() => {
        if (!vendorDetails?.data) {
            formik.setFieldValue('vendorCode', '');
            formik.setFieldValue('vendorName', '');
        }
    }, [vendorDetails]);

    useEffect(() => {
        if (clientDetails?.length === 1 && !formik.values.clientCode) {
            formik.setFieldValue('clientCode', clientDetails[0].value);
            formik.setFieldValue('clientName', clientDetails[0].label);
        }
    }, [clientDetails]);

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">
                    No matches found.
                    <br /> Vendors linked to the client profile <br />
                    will only be available for selection
                </p>
                <br />
                <Link className="redirection-link" to={`/profile/clients/${clientUid}/vendor_details`}>
                    Go to Vendor Details
                </Link>
            </div>
        );
    };

    const clientStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            fontFamily: 'Poppins',
            fontSize: '1.4rem',
            backgroundColor: '#FFF',
            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #cccccc'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
            }),
        }),
    };
    const vendorStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            minHeight: '34px',
            height: '34px',
            fontFamily: 'Poppins',
            fontSize: '1.4rem',
            backgroundColor: disableVendor ? '#e2e3e7' : '#FFF',
            color: disableVendor ? '#737373' : 'hsl(0, 0%, 20%)',

            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #cccccc'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
            }),
        }),
        noOptionsMessage: (base: any) => ({ ...base, minHeight: '210px' }),
    };

    return (
        <>
            <div className="main-wrapper container">
                <div className="main-header-content-holder large-header">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <Link to="/inbound" className="dropdown-arrow">
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>User details</title>
                                    </use>
                                </svg>
                            </Link>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/inbound">
                                    Inbound PO
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <a href="javascript:void(0)" className="breadcrumb-item">
                                    Add New Inbound PO
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="purchase-order-header-fields">
                        <div className="pageheading-holder">
                            <div className="back-btn-holder">
                                {/* <Link to="/inbound">
                                    <button className="back-btn app-btn">
                                        <svg className="svg-icon back-icon">
                                            <use href="#previousArrowIcon">
                                                <title>Back</title>
                                            </use>
                                        </svg>
                                    </button>
                                </Link> */}
                                <h2 className="page-heading">Add New Inbound PO</h2>
                            </div>
                        </div>
                        {isLoading && (
                            <div className="app-loader loading">
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                        )}
                        <div className="field-entries-added">
                            <div className="entry-fields-holder">
                                <form id="my-form" onSubmit={formik.handleSubmit} className="modal-form form-fields">
                                    <div className="modal-input-holder layout-item">
                                        <label className="modal-input-label">Inbound PO Number</label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Inbound PO Number"
                                                type="text"
                                                className="modal-input input-text"
                                                id="poNumber"
                                                name="poNumber"
                                                onChange={(event) => {
                                                    formik.setFieldError(event.target.name, undefined);
                                                    formik.handleChange(event);
                                                }}
                                                value={formik.values.poNumber}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">Client Name</label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                data={clientDetails?.length ? clientDetails : []}
                                                placeholder="Enter Client Name to select"
                                                formik={formik}
                                                fieldName="clientCode"
                                                extraField="clientName"
                                                isClearable={clientDetails?.length === 1 ? false : true}
                                                customStyle={clientStyle}
                                            />
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder  layout-item">
                                        <label className="modal-input-label">Vendor Name</label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                data={vendorDetails?.data ? vendorDetails?.data : []}
                                                placeholder="Enter Vendor Name to select"
                                                formik={formik}
                                                fieldName="vendorCode"
                                                extraField="vendorName"
                                                isDisabled={disableVendor}
                                                NoMessageComponent={NoMessageComponent}
                                                customStyle={vendorStyle}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-submit-btn">
                                        <button
                                            disabled={
                                                formik.values.clientCode === '' ||
                                                formik.values.vendorCode === '' ||
                                                formik.values.poNumber === ''
                                            }
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                            title="Submit"
                                            form="my-form"
                                            type="submit"
                                        >
                                            <span className="button-text footer-button-text">Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
