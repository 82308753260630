import React from 'react';
import { Helmet } from 'react-helmet-async';

interface GridLayoutProps {
    columns: string;
    fields: string;
    url: string;
    fileName: string;
    method: string;
    contentType: string;
    body: unknown;
    server?: string;
    sortOrder?: string;
}
const GridLayout = ({
    columns,
    fields,
    url,
    fileName,
    method,
    contentType,
    body,
    server,
    sortOrder,
}: GridLayoutProps) => {
    const token = `Bearer ${localStorage.getItem('token') ?? ''}`;
    if (!url) {
        return null;
    }

    return (
        <Helmet>
            <script>
                {`$(document).ready(function () {
                    let dateFilter = '';
                    let search = '';
                    let errorMsg = '';
                    const removeExistingColumn = (currFilters, filter, filterCondition) => {
                        if(filterCondition === 'All' && currFilters) {
                            const copy = JSON.parse(JSON.stringify(currFilters));
                            let removedFilter;
                            if(copy?.label) {
                                removedFilter = copy?.filters?.filter((res) => res?.field !== 'month' && res?.field !== 'year' && !res?.label && !res?.filtersLabel);
                            } else {
                                removedFilter = copy?.filters?.filter((res) => !res?.label);
                            }
                            if(removedFilter?.length) {
                                copy.filters = removedFilter;
                                return copy;
                            } else {
                                return {};
                            }
                        }
                        if(!currFilters) {
                            return {
                                filters : [filter],
                                logic: 'and'
                            }
                        } else if(currFilters?.label === filter?.label) {
                            return currFilters;
                        } else {
                            const copy = JSON.parse(JSON.stringify(currFilters));
                            if(copy?.label === 'first') {
                                const filtered = copy?.filters?.filter((res) => res?.field !== 'month' && res?.field !== 'year');
                                copy.filters = [...filtered, filter];
                                delete copy.label;
                                return copy;
                            } else if(copy?.label === 'second' || copy?.label === 'third') {
                                delete copy.label;
                                return filter;
                            }
                            if(copy.logic === 'or') {
                                return {
                                    filters : [currFilters, filter],
                                    logic: 'and'
                                }
                            } else {
                                const index = copy?.filters?.findIndex((res) => res?.label)
                                if(index >= 0) {
                                    copy.filters[index] = filter;
                                } else {
                                    copy.filters.push(filter)
                                }
                                return copy;
                            }
                        }
                        
                    }

                    $('#grid').kendoGrid({
                        dataSource: {
                            serverPaging: true,
                            serverSorting: true,
                            serverFiltering: true,
                            transport: {
                                read: {
                                    url: '${url}',
                                    headers: {
                                        Authorization: '${token}'
                                    },
                                    type:'${method}',
                                    dataType: 'json',
                                    contentType: '${contentType}',
                                },
                                parameterMap: function(options) {
                                    if(${server === 'external'}) {
                                        return options;
                                    } else {
                                        if(options.filter) {
                                            options.kendoFilter =  {...options.filter};
                                            delete options.filter;
                                        }
                                        if(dateFilter) {
                                            options['dateFilter'] =  dateFilter;   
                                        }
                                        if(search) {
                                            options['search'] =  search;   
                                        }
                                        return JSON.stringify({...options, ...${body} });
                                    }
                                }
                            },
                            schema: {
                                total: function (response) {
                                    return response.total;
                                },
                                data: function (response) {   
                                    if(response?.check === false) {
                                        errorMsg = response.message;
                                    } else {
                                        errorMsg = '';
                                    }
                                    return response.data;
                                },
                                model: {
                                    fields: ${fields},
                                },
                            },
                            pageSize: 25,
                            sort: ${sortOrder},
                        },
                        groupable: true,
                        sortable: {
                            allowUnsort: false
                        },
                        filterable: true,
                        pageable: {
                            refresh: false,
                            pageSizes: [10,25,50,100,250,500],
                            buttonCount: 5,
                        },
                        columns: ${columns},
                        noRecords: true,
                        messages: {
                            noRecords: "No records found"
                        },
                        dataBound: function (e) {                       
                            if($('#grid').data('kendoGrid').items().length === 0) {
                                if(errorMsg) {
                                    $('.k-grid-norecords-template').text(errorMsg);
                                    $('.k-grid-norecords-template').css({'width':'25em'});
                                }
                                $('.export-dropdown-btn').prop('disabled', true);
                            } else {
                                $('.export-dropdown-btn').prop('disabled', false);
                            }
                        },
                    });

                    $('.filter-btn').click(function () {
                        let grid = $('#grid').data('kendoGrid');
                        let filter = {};
                        const filterCondition = $(this).data('value');
                        if (filterCondition == 'first') {
                            const currentDate = new Date();
                            filter = {
                                logic: 'and',
                                label: 'first',
                                filters: [
                                    {
                                        field: 'month',
                                        operator: 'eq',
                                        value: currentDate.getMonth() + 1,
                                    },
                                    {
                                        field: 'year',
                                        operator: 'eq',
                                        value: currentDate.getFullYear(),
                                    },
                                ],
                            };
                        } else if (filterCondition === 'second') {
                            const startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth() - 1,
                                new Date().getDate()
                            );
                            const endDate = new Date();
                            filter = {
                                logic: 'or',
                                label: 'second',
                                filters: [
                                    {
                                        logic: 'and',
                                        filtersLabel: 'second',
                                        filters: [
                                            {
                                                field: 'month',
                                                operator: 'gte',
                                                value: startDate.getMonth(),
                                            },
                                            {
                                                field: 'year',
                                                operator: 'eq',
                                                value: startDate.getFullYear(),
                                            },
                                        ],
                                    },
                                    {
                                        logic: 'and',
                                        filtersLabel: 'second',
                                        filters: [
                                            {
                                                field: 'month',
                                                operator: 'lte',
                                                value: endDate.getMonth() + 1,
                                            },
                                            {
                                                field: 'year',
                                                operator: 'eq',
                                                value: endDate.getFullYear(),
                                            },
                                        ],
                                    },
                                ],
                            };
                        } else if (filterCondition === 'third') {
                            const startDate = new Date(
                                new Date().getFullYear(),
                                new Date().getMonth() - 12,
                                new Date().getDate()
                            );
                            const endDate = new Date();
                            filter = {
                                logic: 'or',
                                label: 'third',
                                filters: [
                                    {
                                        logic: 'and',
                                        filtersLabel: 'third',
                                        filters: [
                                            {
                                                field: 'month',
                                                operator: 'gte',
                                                value: startDate.getMonth(),
                                            },
                                            {
                                                field: 'year',
                                                operator: 'eq',
                                                value: startDate.getFullYear(),
                                            },
                                        ],
                                    },
                                    {
                                        logic: 'and',
                                        filtersLabel: 'third',
                                        filters: [
                                            {
                                                field: 'month',
                                                operator: 'lte',
                                                value: endDate.getMonth() + 1,
                                            },
                                            {
                                                field: 'year',
                                                operator: 'eq',
                                                value: endDate.getFullYear(),
                                            },
                                        ],
                                    },
                                ],
                            };
                        }

                        let currFilters = grid.dataSource.filter();
                        if(${server === 'external'}) {
                            if(filterCondition === 'All') {
                                grid.dataSource.filter(removeExistingColumn(currFilters, {}, filterCondition));
                            } else {
                                grid.dataSource.filter(removeExistingColumn(currFilters, filter, filterCondition));
                            }
                        } else {
                            if(filterCondition === 'All') {
                                dateFilter = '';
                            } else {
                                dateFilter = {
                                    startDate : $(this).data('startdate'),
                                    endDate : $(this).data('enddate')
                                };
                            }
                            grid.dataSource.page(1);
                            grid.dataSource.skip(0);
                        }
                    });
                    $('#pdf-export').click(function () {
                        // const name = '${fileName}' + '_' + String(Date.now());
                        const name = '${fileName}';
                        const grid = $('#grid').getKendoGrid();
                        grid.options.pdf.fileName = name;
                        grid.options.pdf.allPages = true;
                        grid.saveAsPDF();
                    });
                    $('#excel-export').click(function () {
                        // const name = '${fileName}' + '_' + String(Date.now());
                        const name = '${fileName}';
                        const grid = $('#grid').getKendoGrid();
                        grid.options.excel.fileName = name;
                        grid.options.excel.allPages = true;
                        grid.saveAsExcel();
                    });
                    $('input#search-field-kendo').on('input',function(e){
                        const grid = $('#grid').data('kendoGrid');
                        if($(this).val()?.length === 0 || $(this).val()?.length >= 3) {
                            search = $(this).val();
                            grid.dataSource.page(1);
                            grid.dataSource.skip(0);
                        }
                    });
                    $('#clear-btn-kendo').on('click',function(e){
                        const grid = $('#grid').data('kendoGrid');
                        search = '';
                        grid.dataSource.page(1);
                        grid.dataSource.skip(0);
                    });
                });
                `}
            </script>
        </Helmet>
    );
};

export default GridLayout;
