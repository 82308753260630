import axios from 'axios';
import { post } from '../../HttpMethods';
import moment from 'moment';

export const fetchReassignBpListDetailsApi = async (payload: {
    profileType: string;
    sortColumn: string;
    sortOrder: string;
    body: any;
}) => {
    try {
        if (payload.body?.bpStatus?.length) {
            if (payload.body?.bpStatus?.length === 2) {
                delete payload.body.bpStatus;
            } else {
                payload.body.bpStatus = payload.body.bpStatus[0];
            }
        }
        const body = {
            ...payload.body,
            plannedEta: payload?.body?.plannedEta?.map(
                (res: string) => moment(res, 'DD-MM-YYYY').format('YYYY-MM-DD') + ' 00:00:00.0'
            ),
            plannedEtd: payload?.body?.plannedEtd?.map(
                (res: string) => moment(res, 'DD-MM-YYYY').format('YYYY-MM-DD') + ' 00:00:00.0'
            ),
            goodsReadyDate: payload?.body?.goodsReadyDate?.map(
                (res: string) => moment(res, 'DD-MM-YYYY').format('YYYY-MM-DD') + ' 00:00:00.0'
            ),
        };
        const data = await post(
            `api/bkg/bp/list/${payload.profileType}?sortColumn=${payload.sortColumn}&sortOrder=${payload.sortOrder}`,
            { ...body, paginationRequired: false },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchDistinctBpListDetailsApi = async (payload: { profileType: string; body: any }) => {
    try {
        const data = await post(
            `api/bkg/bp/distinct/${payload.profileType}`,
            { ...payload.body, paginationRequired: false },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateAgentBpDetailsApi = async (payload: any) => {
    try {
        const data = await post('api/bkg/bp/reassign', payload, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
