import { del, get, patch, post, put } from '../HttpMethods';
import fileDownload from 'js-file-download';
import { getOrganisationData } from 'src/utils';
import { useUserProfile } from 'src/hooks/useUserProfile';
/* eslint-disable no-undefined */
const { profileType } = useUserProfile();

export interface clientData {
    cl_PK: string;
    cl_Status: string;
    cl_Relationship: string;
    childClients: clientData[];
    cl_Org: {
        or_Orgname: string;
        or_Status: string;
        or_ParentOrgCode: string;
        or_Orgcode: string;
        or_Orgtype: string;
    };
    // name: string;
    // url: string;
}
export interface clientDataFull {
    clientDetailsResponseList: [
        {
            cl_PK: string;
            cl_Status: string;
            cl_Relationship: string;
            childClients: clientData[];
            cl_Org: {
                or_Orgname: string;
                or_Status: string;
                or_ParentOrgCode: string;
                or_Orgcode: string;
                or_Orgtype: string;
            };
        }
    ];
    totalNoOfPAges: number;
    clientDetailsNotAvailable: [];

    // name: string;
    // url: string;
}

// Get All Datas
export const getRolesData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusValue: any;
    statusValueRoles: any;
    columnOrder: boolean;
    type: string;
    data: any;
}) => {
    try {
        const res = await post(
            `/api/mgt/roles/list?profile=${payload.type}&sortColumn=${payload.columnStatus}&sortOrder=${
                payload.columnOrder ? 'DESC' : 'ASC'
            }&size=${payload.perPageCount}&page=${payload.currentPage}${
                payload.statusValue !== undefined && payload.statusValue !== null
                    ? `&filterProfileStatus=${payload.statusValue}`
                    : ''
            }${
                payload.statusValueRoles !== undefined && payload.statusValueRoles !== null
                    ? `&filterRoles=${payload.statusValueRoles}`
                    : ''
            }`,
            payload.data,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const exportClientList = async (type: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/clients/export?type=${type}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ClientProfiles.xlsx' : 'ClientProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
export const viewDataColumn = async (payload: { profile: string; data: any }) => {
    try {
        const res = await post(
            `api/mgt/roles/distinct?columnName=roleName&profile=${payload.profile}`,
            payload.data,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const viewAllRoles = async (_payload: any) => {
    try {
        const res = await get('api/mgt/roles/privileges', _payload);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const listRoleDetails = async (payload: any) => {
    try {
        const res = await get(`api/mgt/roles?roleId=${payload.roleId}&profile=${payload.type}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const createRole = async (payload: any) => {
    try {
        const res = await post('api/mgt/roles ', payload, null);
        return res;
    } catch (err) {
        return err;
    }
};
export const editRole = async (payload: any) => {
    try {
        const res = await put(`api/mgt/roles/${payload.id}`, payload);
        return res;
    } catch (err) {
        return err;
    }
};

export const deleteRole = async (payload: any) => {
    try {
        const res = await del(`api/mgt/roles/${payload}`, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const changeStatusRoles = async (payload: any) => {
    try {
        const res = await patch(`api/mgt/roles/status/${payload.id}/${payload?.currentStat}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const listAllVendors = async (payload: any) => {
    try {
        const res = await post(`api/mgt/vendors/list/${payload.profileType}`, payload.data, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const listAllShipping = async (payload: any) => {
    try {
        const res = await post(`api/mgt/shippinglines/list/${payload.profileType}`, payload.data, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const listAllAgents = async (payload: any) => {
    try {
        const res = await post(`api/mgt/agents/list/${payload.profileType}`, payload.data, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
// export const listAllClients = async (payload: any) => {
//     try {
//         const res = await get('api/mgt/clients/clientlisting', null);
//         return res;
//     } catch (err) {
//         return console.error(err);
//     }
// };

export const changeRoleOfUser = async (payload: any) => {
    try {
        const res = await patch(`api/mgt/user/role?userId=${payload.userId}&roleId=${payload.roleId}`, payload.payload);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const resendActivation = async (payload: any) => {
    try {
        const res = await post(`api/mgt/user/resendactivationemail/${payload?.userId}`, payload?.payload, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getHeaderDataRoles = async (payload: any) => {
    try {
        const res = await post(`api/mgt/roles/rolecount?profile=${payload.profileType}`, payload.data, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const linkClient = async (payload: any) => {
    try {
        payload.clients.forEach((element: any) => {
            const res = post(
                `/api/mgt/user/addorganisation?username=${payload.userName}&organisationId=${element}&roleId=${payload.roleId}`,
                payload?.payload,
                null
            );
            return res;
        });
    } catch (err) {
        return console.error(err);
    }
};
export const removeClient = async (payload: any) => {
    try {
        payload.clients.forEach((element: any) => {
            const res = patch(
                `/api/mgt/user/organisation?username=${payload.userName}&organisationId=${element}&roleId=${payload.roleId}`,
                null
            );
            return res;
        });
    } catch (err) {
        return console.error(err);
    }
};
export const searchRoles = async (payload: {
    keyword: string;
    perPageCount: number;
    currentPage: number;
    type: any;
    columnStatus: any;
    columnOrder: any;
    statusFilter: any;
    statusValue: any;
    statusValueRoles: any;
}) => {
    const listOfUserOrganisations = getOrganisationData();
    try {
        if (payload.type === 'Forwarder') {
            const res = await get(
                `api/mgt/search/${payload.type === 'shipping' ? 'shippingline' : payload.type}roles?keyword=${
                    payload.keyword
                }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}&size=${
                    payload.perPageCount
                }&page=${payload.currentPage}${
                    payload.statusValue !== undefined && payload.statusValue !== null
                        ? `&filterProfileStatus=${payload.statusValue}`
                        : ''
                }${
                    payload.statusValueRoles !== undefined && payload.statusValueRoles !== null
                        ? `&filterRoles=${payload.statusValueRoles}`
                        : ''
                }`,
                null,
                null,
                true
            );
            return res;
        } else {
            const res = await post(
                `api/mgt/search/${payload.type === 'shipping' ? 'shippingline' : payload.type}roles?profile=${
                    payload.type
                }&keyword=${payload.keyword}&sortColumn=${payload.columnStatus}&sortOrder=${
                    payload.columnOrder ? 'DESC' : 'ASC'
                }&size=${payload.perPageCount}&page=${payload.currentPage}${
                    payload.statusValue !== undefined && payload.statusValue !== null
                        ? `&filterProfileStatus=${payload.statusValue}`
                        : ''
                }${
                    payload.statusValueRoles !== undefined && payload.statusValueRoles !== null
                        ? `&filterRoles=${payload.statusValueRoles}`
                        : ''
                }`,
                { ...listOfUserOrganisations, isForwarder: profileType === 'forwarder' ? true : false },
                null,
                true
            );
            return res;
        }
    } catch (err) {
        return console.error(err);
    }
};
