import './cancel-booking.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DatePicker from '../../../components/common/DatePicker';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { cancelBooking } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useParams } from 'react-router-dom';
import { error, Position, success } from '../../../utils/toast';
import { setDisabledFilterData, setFilterDispatch } from '../../../store/reducers/bookingProposal/availablePoReducer';

interface CancelBookingProps {
    setShowBookingCancelModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
const Index = ({ setShowBookingCancelModal }: CancelBookingProps): ReactElement => {
    const { bpNumber, clientName, newBp, cancelError, cancelSuccess } = useSelector(
        (state: RootState) => state.bpHeaderData
    );
    // const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const ModalSchema = Yup.object().shape({
        cancellationDate: Yup.date().max(new Date(), 'Cancellation date cannot be future dates'),
        reason: Yup.string().required('Reason is required'),
    });
    const userDetails = JSON.parse(localStorage.getItem('user') || '')?.user;
    const userName = userDetails?.us_UserName;
    const name = (userDetails?.us_FirstName || '') + ' ' + (userDetails?.us_LastName || '');

    const initialValues: any = {
        cancellationDate: '',
        reason: '',
    };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ModalSchema,
        onSubmit: (values) => {
            const payload = {
                bpId: id,
                name,
                userName,
                reason: values.reason,
                cancelDate: values.cancellationDate ? values.cancellationDate + ' 00:00:00' : null,
            };
            dispatch(cancelBooking(payload));
        },
    });

    useEffect(() => {
        if (cancelSuccess) {
            dispatch(
                setDisabledFilterData({
                    clientName: null,
                    originPort: null,
                    destinationPort: null,
                    vendorName: null,
                    isHeaderOnlyPo: null,
                })
            );
            dispatch(setFilterDispatch({}));
            setShowBookingCancelModal(false);
            success(`Booking ${bpNumber} has been cancelled`, Position.TOP_RIGHT);
            // localStorage.setItem('bpId', newBp.bpId);
            // navigate(`/booking_list/${newBp.bpId}/POSelection`);
            // localStorage.setItem('bpNumber', newBp.bpNumber);
            // setTimeout(() => {
            //     success(
            //         'New Booking Proposal has been created using PO selections from Linked Booking',
            //         Position.TOP_RIGHT
            //     );
            // }, 2000);
            setTimeout(() => {
                window.location.href = '/booking_list';
            }, 2000);
        }
    }, [cancelSuccess]);

    useEffect(() => {
        if (cancelError) {
            error('Something went wrong', Position.TOP_RIGHT);
        }
    }, [cancelError]);

    return (
        <div className="app-modal">
            <div className="modal-content-holder medium-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Cancel Booking</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowBookingCancelModal(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">
                                Once cancelled, a new Booking Proposal will be created with the same PO Selection
                                information.
                            </div>
                        </div>

                        <div className="layout-holder two-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Booking No.</h6>
                                <div className="description-type-1">{bpNumber}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Client Name</h6>
                                <div className="description-type-1">{clientName}</div>
                            </div>
                        </div>
                        <form id="cancel-form" action="" className="modal-form" onSubmit={formik.handleSubmit}>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">Cancellation Date</label>
                                <div className="modal-input-layout-item calendar">
                                    <DatePicker formik={formik} fieldName="cancellationDate" maxDate={new Date()} />
                                </div>
                                {formik.errors.cancellationDate ? (
                                    <div className="error-text">{formik.errors.cancellationDate as any}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder="Enter the reason to Cancel Booking"
                                        value={formik.values.reason}
                                        onChange={(e) => {
                                            // eslint-disable-next-line no-undefined
                                            formik.setFieldError('reason', undefined);
                                            formik.setFieldValue('reason', e.target.value);
                                        }}
                                    ></textarea>
                                </div>
                                {formik.errors.reason ? (
                                    <div className="error-text">{formik.errors.reason as any}</div>
                                ) : null}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowBookingCancelModal(false);
                        }}
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className={`app-btn app-btn-primary modal-btn footer-btn ${
                            formik.values.reason ? '' : 'disabled'
                        }`}
                        type="submit"
                        form="cancel-form"
                        title="Confirm Cancellation"
                    >
                        <span className="button-text footer-button-text">Confirm Cancellation</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
