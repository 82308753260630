import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import './agent-list.scss';
import './index.scss';

import GridLayout from './grid';

const Index: React.FC = () => {
    return (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Quote Section</h2>
                <div className="grid-section grid-p-0">
                    <div className="grid-holder">
                        <HelmetProvider>
                            <div className="insight-agent-list-data-trend">
                                <div className={`container-fluid`}>
                                    <div className="k-kendo-row one-column quote-section-column">
                                        <div className="k-kendo-col-2 custom-kendo-grid quote-section">
                                            <div id={'grid'}></div>
                                        </div>
                                    </div>
                                </div>
                                <GridLayout />
                            </div>
                        </HelmetProvider>
                    </div>
                </div>
            </div>
        </div>

        // <div className="main-wrapper container insight-agent-list">
        //     <div className="main-header-content-holder ">
        //         <div className="pageheading-holder">
        //             <div className="header-wrapper">
        //                 <h2 className="page-heading">Quote Section</h2>
        //             </div>
        //         </div>
        //     </div>

        //     <div className="main-content-holder">

        //     </div>
        // </div>
    );
};
export default Index;
