import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface countItemProps {
    data: {
        total: number;
        active: number;
        inactive: number;
        draft?: number;
        submitted?: number;
    };
    title: string;
    setCurrent: any;
    position: any;
    isFromVendor?: boolean;
}
const CountBox: React.FC<countItemProps> = ({ data, title, setCurrent, position, isFromVendor }) => {
    const location = useLocation();
    return (
        <Link
            to={`/${position}`}
            className={`box-nav ${location.pathname.includes(position) ? 'active' : ''}`}
            onClick={() => setCurrent(title)}
        >
            <div className="box-nav-left">
                <div className="box-title">{title}</div>
                <div className="box-count">{data?.total}</div>
            </div>
            <div className="box-nav-right">
                <div className="box-section-active">
                    <div className="box-count"> {isFromVendor ? data?.submitted : data?.active}</div>
                    <div className="box-title">{isFromVendor ? 'Submitted' : 'Active'}</div>
                </div>
                <div className="box-section-inactive">
                    <div className="box-count"> {isFromVendor ? data?.draft : data?.inactive}</div>
                    <div className="box-title">{isFromVendor ? 'Draft' : 'Inactive'}</div>
                </div>
            </div>
        </Link>
    );
};

export default CountBox;
