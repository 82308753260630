/* eslint-disable no-undefined */
import React, { useEffect } from 'react';
import './allOrdersTab.scss';
import { RootState } from 'src/store/reducers';
import { useNavigate } from 'react-router-dom';

import { BookingType } from 'src/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    setPoFilter as setFilter,
    setPoSelectedDropDown as setSelectedDropDown,
    setInboundPoSelectedDropDown,
    setInboundPoFilter,
} from 'src/store/reducers/purchaseOrder/poListReducer';
import BSTooltip from 'src/components/common/BSTooltip';
import LineGraph from 'src/components/common/LineGraph';
import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    setFilter as setAoFilter,
    setSelectedDropDown as setSelectedAoDropdown,
} from 'src/store/reducers/allocationOrder/allocationOrderListReducer';
import {
    fetchFavourites,
    resetAddSuccessFlag,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/dashboard/insightsFavoritesReducer';
import {
    setFilter as setSeaListFilter,
    setSelectedDropDown as setSelectedBookingDropDown,
    setBookingList,
} from 'src/store/reducers/bookingProposal/bookingProposalListReducer';
import { FilterDestinationTypeList, FilterModeList } from 'src/pages/Dashboard/Insights/enums';
import {
    setSelectedDropDown as setSelectedBookingAirDropDown,
    setFilter as setAirListFilter,
} from 'src/store/reducers/bookingProposalAir/listReducer';
import {
    setSelectedDropDown as setSelectedBookingAoDropDown,
    setFilter as setAoListFilter,
} from 'src/store/reducers/aobookingProposal/bookingProposalListReducer';
import {
    setInsightsSelectedTypeFilter,
    setSelectedTypeFilterIndex,
} from 'src/store/reducers/dashboard/insightsReducer';
import { addOrRemoveFromFavs, checkIfAddedToFavs, typeOfFav } from 'src/pages/Dashboard/Insights/common';
import { getStatusTitle } from 'src/pages/Dashboard/Insights/getStatusTitle';
import { clearDashboardInsightsFilters } from 'src/pages/Dashboard/Insights/clearDashboardInsightsFilters';

interface Props {
    dashboardExpandedReduxVal: any;
    isLoading: boolean;
    displayComponentRef: any;
    page: string;
}
enum Type {
    ao = 'AO',
    itpo = 'ITPO',
    ibpo = 'IBPO',
    bpo = 'po',
    po = 'po',
    all = 'ALL',
}

//Faheem
const Index: React.FC<Props> = ({ dashboardExpandedReduxVal, isLoading, displayComponentRef, page }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        // isDasboardActiveBookingsLoading,
        insightsMode,
        insightsDestination,
        // insightsDurationRange,
        insightsSelectedType,
        insightsSelectedTypeFilter,
        insightsSelectedTypeFilterIndex,
    } = useSelector((state: RootState) => state.dashboardInsights);
    const { poFilter: filter } = useSelector((state: RootState) => state.purchaseOrderList);
    const { filter: aoFilter } = useSelector((state: RootState) => state.allocationOrderList);
    const { inboundPoFilter } = useSelector((state: RootState) => state.purchaseOrderList);
    const { profileType, userName } = useUserProfile();
    const {
        favourites,
        isAddSuccess,
        isRemoveSuccess,
        isLoading: favLoading,
    } = useSelector((state: RootState) => state.favourites);
    const currentFiltersForPo = {
        isSea: insightsMode?.includes(FilterModeList.Sea) ? true : false,
        isAir: insightsMode?.includes(FilterModeList.Air) ? true : false,
        isLand: insightsMode?.includes(FilterModeList.Land) ? true : false,
        isInternational: insightsDestination?.includes(FilterDestinationTypeList.International) ? true : false,
        isTriangle: insightsDestination?.includes(FilterDestinationTypeList.Triangle) ? true : false,
    } as any;
    const currentFiltersForBp = {
        isFcl: insightsMode?.includes(FilterModeList.Fcl) ? true : false,
        isLcl: insightsMode?.includes(FilterModeList.Lcl) ? true : false,
        isBcn: insightsMode?.includes(FilterModeList.Bcn) ? true : false,
        isAir: insightsMode?.includes(FilterModeList.Air) ? true : false,
        isInternational: insightsDestination?.includes(FilterDestinationTypeList.International) ? true : false,
        isTriangle: insightsDestination?.includes(FilterDestinationTypeList.Triangle) ? true : false,
    };
    const title =
        insightsSelectedType === Type.itpo
            ? 'International POs'
            : insightsSelectedType === Type.ibpo
            ? 'Inbound POs'
            : insightsSelectedType === Type.ao
            ? 'Allocation Orders'
            : '';
    const bookingTitle =
        insightsSelectedType === Type.po ? 'PO Bookings' : insightsSelectedType === Type.ao ? 'AO Bookings' : '';
    const { filter: aoListFilter } = useSelector((state: RootState) => state.aoBookingProposalList);
    const { filter: airListFilter } = useSelector((state: RootState) => state.bookingProposalAirList);
    const { filter: seaListFilter } = useSelector((state: RootState) => state.bookingProposalList);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);

    const plotGraphPoints = (type: string) => [
        [
            '0',
            dashboardExpandedReduxVal[type]?.key1?.count,
            dashboardExpandedReduxVal[type]?.key1?.dateFrom,
            dashboardExpandedReduxVal[type]?.key1?.dateTo,
            dashboardExpandedReduxVal[type]?.key1?.pointName,
        ],
        [
            '1',
            dashboardExpandedReduxVal[type]?.key2?.count,
            dashboardExpandedReduxVal[type]?.key2?.dateFrom,
            dashboardExpandedReduxVal[type]?.key2?.dateTo,
            dashboardExpandedReduxVal[type]?.key2?.pointName,
        ],
        [
            '2',
            dashboardExpandedReduxVal[type]?.key3?.count,
            dashboardExpandedReduxVal[type]?.key3?.dateFrom,
            dashboardExpandedReduxVal[type]?.key3?.dateTo,
            dashboardExpandedReduxVal[type]?.key3?.pointName,
        ],
        [
            '3',
            dashboardExpandedReduxVal[type]?.key4?.count,
            dashboardExpandedReduxVal[type]?.key4?.dateFrom,
            dashboardExpandedReduxVal[type]?.key4?.dateTo,
            dashboardExpandedReduxVal[type]?.key4?.pointName,
        ],
        [
            '4',
            dashboardExpandedReduxVal[type]?.key5?.count,
            dashboardExpandedReduxVal[type]?.key5?.dateFrom,
            dashboardExpandedReduxVal[type]?.key5?.dateTo,
            dashboardExpandedReduxVal[type]?.key5?.pointName,
        ],
        [
            '5',
            dashboardExpandedReduxVal[type]?.key6?.count,
            dashboardExpandedReduxVal[type]?.key6?.dateFrom,
            dashboardExpandedReduxVal[type]?.key6?.dateTo,
            dashboardExpandedReduxVal[type]?.key6?.pointName,
        ],
        [
            '6',
            dashboardExpandedReduxVal[type]?.key7?.count,
            dashboardExpandedReduxVal[type]?.key7?.dateFrom,
            dashboardExpandedReduxVal[type]?.key7?.dateTo,
            dashboardExpandedReduxVal[type]?.key7?.pointName,
        ],
        [
            '7',
            dashboardExpandedReduxVal[type]?.key8?.count,
            dashboardExpandedReduxVal[type]?.key8?.dateFrom,
            dashboardExpandedReduxVal[type]?.key8?.dateTo,
            dashboardExpandedReduxVal[type]?.key8?.pointName,
        ],
        [
            '8',
            dashboardExpandedReduxVal[type]?.key9?.count,
            dashboardExpandedReduxVal[type]?.key9?.dateFrom,
            dashboardExpandedReduxVal[type]?.key9?.dateTo,
            dashboardExpandedReduxVal[type]?.key9?.pointName,
        ],
        [
            '9',
            dashboardExpandedReduxVal[type]?.key10?.count,
            dashboardExpandedReduxVal[type]?.key10?.dateFrom,
            dashboardExpandedReduxVal[type]?.key10?.dateTo,
            dashboardExpandedReduxVal[type]?.key10?.pointName,
        ],
        [
            '10',
            dashboardExpandedReduxVal[type]?.key11?.count,
            dashboardExpandedReduxVal[type]?.key11?.dateFrom,
            dashboardExpandedReduxVal[type]?.key11?.dateTo,
            dashboardExpandedReduxVal[type]?.key11?.pointName,
        ],
        [
            '11',
            dashboardExpandedReduxVal[type]?.key12?.count,
            dashboardExpandedReduxVal[type]?.key12?.dateFrom,
            dashboardExpandedReduxVal[type]?.key12?.dateTo,
            dashboardExpandedReduxVal[type]?.key12?.pointName,
        ],
    ];

    const handleItemClick = (value: any, index: number, key: string) => {
        displayComponentRef?.current.changeValue(value);
        dispatch(setSelectedTypeFilterIndex(index));
        dispatch(setInsightsSelectedTypeFilter(key === 'total' ? '' : key));
    };
    //=== object empty or not check fn======//
    const isEmpty = (obj: any) => {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    };
    //======================================//

    const organizationProfileData = () => {
        if (selectedSearchItem?.type === 'Clients') {
            return {
                clientName: [selectedSearchItem?.name],
            };
        } else if (selectedSearchItem?.type === 'Agents') {
            return {
                agentName: [selectedSearchItem?.name],
            };
        } else if (selectedSearchItem?.type === 'Vendors') {
            return {
                vendorName: [selectedSearchItem?.name],
            };
        }
    };

    const setNavigationAndFilter = (key: string, count: any) => {
        if (insightsSelectedType === Type?.itpo) {
            dispatch(
                setFilter({
                    ...filter,
                    shippingmode:
                        insightsMode?.length > 0
                            ? insightsMode?.map((val: string) => {
                                  if (val === 'sea') {
                                      return 'Sea';
                                  }
                                  if (val === 'air') {
                                      return 'Air';
                                  }
                              })
                            : undefined,
                    destinationType:
                        insightsDestination && insightsDestination?.length && insightsDestination?.length > 0
                            ? insightsDestination
                            : undefined,
                    poId: selectedSearchItem ? selectedSearchItem?.poId : undefined,
                    poStatus: getStatusTitle(key) !== 'Total' ? getStatusTitle(key) : 'Active',
                    ...organizationProfileData(),
                })
            );
            dispatch(
                setSelectedDropDown({
                    text: getStatusTitle(key) !== 'Total' ? getStatusTitle(key) : 'Active POs',
                    count: count,
                })
            );
            navigate('/purchase_order', { state: { reload: true } });
        } else if (insightsSelectedType === Type.ibpo) {
            dispatch(
                setInboundPoFilter({
                    ...inboundPoFilter,
                    poStatus: getStatusTitle(key) !== 'Total' ? getStatusTitle(key) : 'Active',
                    poId: selectedSearchItem ? selectedSearchItem?.poId : undefined,
                    ...organizationProfileData(),
                })
            );
            dispatch(
                setInboundPoSelectedDropDown({
                    text: getStatusTitle(key) !== 'Total' ? getStatusTitle(key) : 'Active Inbound POs',
                    count: count,
                })
            );
            navigate('/inbound', { state: { reload: true } });
        } else if (insightsSelectedType === Type?.ao) {
            if (page === 'orders') {
                dispatch(
                    setAoFilter({
                        ...aoFilter,
                        destinationType:
                            insightsDestination && insightsDestination?.length && insightsDestination?.length > 0
                                ? insightsDestination
                                : undefined,
                        poId: selectedSearchItem ? selectedSearchItem?.poId : undefined,
                        aoStatus:
                            getStatusTitle(key) !== 'Total'
                                ? key === 'readyForBooking'
                                    ? 'Ready for Booking'
                                    : getStatusTitle(key)
                                : 'Active',
                        ...organizationProfileData(),
                    })
                );
                dispatch(
                    setSelectedAoDropdown({
                        text:
                            getStatusTitle(key) !== 'Total'
                                ? key === 'readyForBooking'
                                    ? 'Ready for Booking'
                                    : getStatusTitle(key)
                                : 'Active AOs',
                        count: count,
                    })
                );
                navigate('/allocation_orders', { state: { reload: true } });
            } else if (page === 'bookings') {
                navigate('/booking_list', { state: { reload: true } });
                dispatch(setBookingList(BookingType?.aoBooking));
                dispatch(
                    setAoListFilter({
                        ...aoListFilter,
                        destinationType:
                            insightsDestination &&
                            insightsDestination?.length &&
                            insightsDestination?.length > 0 &&
                            insightsDestination.includes(FilterDestinationTypeList?.International)
                                ? [FilterDestinationTypeList?.International]
                                : undefined,
                        bpStatus: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active',
                        bpType: ['AO'],
                        bpId: selectedSearchItem ? selectedSearchItem?.bpId : undefined,
                        poId: selectedSearchItem ? selectedSearchItem?.poId : undefined,
                        clientNames: selectedSearchItem?.type === 'Clients' ? [selectedSearchItem?.name] : undefined,
                        agentName: selectedSearchItem?.type === 'Agents' ? [selectedSearchItem?.name] : undefined,
                        vendorName: selectedSearchItem?.type === 'Vendors' ? [selectedSearchItem?.name] : undefined,
                    })
                );
                dispatch(
                    setSelectedBookingAoDropDown({
                        text: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active BPs',
                        count: count,
                    })
                );
            }
        } else if (insightsSelectedType === Type?.po) {
            const bpTypeFilters = insightsMode
                ?.map((val: string) => {
                    if (val === FilterModeList?.Fcl) {
                        return 'FCL';
                    }
                    if (val === FilterModeList?.Lcl) {
                        return 'LCL';
                    }
                    if (val === FilterModeList?.Bcn) {
                        return 'BCN';
                    }
                    if (val === FilterModeList?.Air) {
                        return 'Air';
                    }
                    return val;
                })
                ?.filter((value: string) => {
                    return value && value !== 'Air';
                });
            // selectedSearchItem?.mode === 'SEA'
            //     ? bpTypeFilters.push('Sea')
            //     : selectedSearchItem?.mode === 'AIR'
            //     ? bpTypeFilters.push('Air')
            //     : null;
            if (selectedSearchItem?.mode === 'SEA') {
                bpTypeFilters.push('Sea');
            } else if (selectedSearchItem?.mode === 'AIR') {
                bpTypeFilters.push('Air');
            }

            //setting up filters for PO Bookings-Air
            dispatch(
                setAirListFilter({
                    ...airListFilter,
                    destinationType:
                        insightsDestination && insightsDestination?.length && insightsDestination?.length > 0
                            ? insightsDestination
                            : undefined,
                    bpType: ['Air'],
                    bpStatus: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active',
                    bpId: selectedSearchItem ? selectedSearchItem?.bpId : undefined,
                    poId: selectedSearchItem ? selectedSearchItem?.poId : undefined,
                    clientNames: selectedSearchItem?.type === 'Clients' ? [selectedSearchItem?.name] : undefined,
                    agentName: selectedSearchItem?.type === 'Agents' ? [selectedSearchItem?.name] : undefined,
                    vendorName: selectedSearchItem?.type === 'Vendors' ? [selectedSearchItem?.name] : undefined,
                })
            );
            dispatch(
                setSelectedBookingAirDropDown({
                    text: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active BPs',
                    count: count,
                })
            );

            //setting up filters for PO Bookings-Sea
            dispatch(
                setSeaListFilter({
                    ...seaListFilter,
                    destinationType:
                        insightsDestination && insightsDestination?.length && insightsDestination?.length > 0
                            ? insightsDestination
                            : undefined,
                    bpStatus: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active',
                    bpId: selectedSearchItem ? selectedSearchItem?.bpId : undefined,
                    poId: selectedSearchItem ? selectedSearchItem?.poId : undefined,
                    bpType: bpTypeFilters,
                    clientNames: selectedSearchItem?.type === 'Clients' ? [selectedSearchItem?.name] : undefined,
                    agentName: selectedSearchItem?.type === 'Agents' ? [selectedSearchItem?.name] : undefined,
                    vendorName: selectedSearchItem?.type === 'Vendors' ? [selectedSearchItem?.name] : undefined,
                })
            );
            dispatch(
                setSelectedBookingDropDown({
                    text: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active BPs',
                    count: count,
                })
            );

            if (insightsMode?.includes(FilterModeList?.Air)) {
                if (insightsMode?.length === 1) {
                    dispatch(setBookingList(BookingType?.poBookingAir));
                } else {
                    dispatch(setBookingList(BookingType?.poBooking));
                }
            } else {
                dispatch(
                    selectedSearchItem?.mode === 'AIR'
                        ? setBookingList(BookingType.poBookingAir)
                        : setBookingList(BookingType.poBooking)
                );
                dispatch(
                    selectedSearchItem?.mode === 'AIR'
                        ? setAirListFilter({
                              ...airListFilter,
                              destinationType:
                                  insightsDestination && insightsDestination?.length && insightsDestination?.length > 0
                                      ? insightsDestination
                                      : undefined,
                              bpType: ['Air'],
                              bpStatus: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active',
                              bpId: selectedSearchItem ? selectedSearchItem.bpId : undefined,
                              poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                              clientNames:
                                  selectedSearchItem?.type === 'Clients' ? [selectedSearchItem?.name] : undefined,
                              agentName: selectedSearchItem?.type === 'Agents' ? [selectedSearchItem?.name] : undefined,
                              vendorName:
                                  selectedSearchItem?.type === 'Vendors' ? [selectedSearchItem?.name] : undefined,
                          })
                        : setSeaListFilter({
                              ...seaListFilter,
                              bpType: bpTypeFilters,
                              destinationType:
                                  insightsDestination && insightsDestination?.length && insightsDestination?.length > 0
                                      ? insightsDestination
                                      : undefined,
                              bpStatus: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active',
                              bpId: selectedSearchItem ? selectedSearchItem?.bpId : undefined,
                              poId: selectedSearchItem ? selectedSearchItem?.poId : undefined,
                              clientNames:
                                  selectedSearchItem?.type === 'Clients' ? [selectedSearchItem?.name] : undefined,
                              agentName: selectedSearchItem?.type === 'Agents' ? [selectedSearchItem?.name] : undefined,
                              vendorName:
                                  selectedSearchItem?.type === 'Vendors' ? [selectedSearchItem?.name] : undefined,
                          })
                );
                dispatch(
                    selectedSearchItem?.mode === 'AIR'
                        ? setSelectedBookingAirDropDown({
                              text: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active BPs',
                              count: count,
                          })
                        : setSelectedBookingDropDown({
                              text: getStatusTitle(key) !== 'Total' ? getStatusTitle(key, 'bookings') : 'Active BPs',
                              count: count,
                          })
                );
            }
            navigate('/booking_list', { state: { reload: true } });
        } else {
            return;
        }
        clearDashboardInsightsFilters(dispatch);
    };

    const addOrRemoveFromFavourites = (key: string) => {
        if (page === 'orders') {
            if (insightsSelectedType === Type.itpo) {
                currentFiltersForPo.isItpo = true;
            } else if (insightsSelectedType === Type.ibpo) {
                currentFiltersForPo.isIbpo = true;
            } else if (insightsSelectedType === Type.ao) {
                currentFiltersForPo.isAo = true;
            }
        }
        addOrRemoveFromFavs(
            dispatch,
            page === 'orders' ? title : bookingTitle,
            favourites,
            page === 'orders' ? currentFiltersForPo : currentFiltersForBp,
            userName,
            page === 'orders' ? typeOfFav.activeOrder : typeOfFav.activeBookings,
            getStatusTitle(key)
        );
    };

    const checkIfAddedToFavourites = (key: string) => {
        return checkIfAddedToFavs(
            page === 'orders' ? title : bookingTitle,
            favourites,
            page === 'orders' ? currentFiltersForPo : currentFiltersForBp,
            page === 'orders' ? typeOfFav.activeOrder : typeOfFav.activeBookings,
            getStatusTitle(key)
        );
    };

    useEffect(() => {
        if (isAddSuccess) {
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetAddSuccessFlag({}));
        }
    }, [isAddSuccess]);

    useEffect(() => {
        if (isRemoveSuccess) {
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag({}));
        }
    }, [isRemoveSuccess]);

    React.useEffect(() => {
        if (insightsSelectedTypeFilter) {
            handleItemClick(
                dashboardExpandedReduxVal[insightsSelectedTypeFilter],
                insightsSelectedTypeFilterIndex,
                insightsSelectedTypeFilter
            );
        } else {
            if (dashboardExpandedReduxVal['total']) {
                handleItemClick(dashboardExpandedReduxVal['total'], 0, 'total');
            }
        }
    }, []);

    return !isLoading ? (
        !isEmpty(dashboardExpandedReduxVal) ? (
            <div className="customscrollbar">
                <div className="all-orders-tab">
                    <div className="">
                        {plotGraphPoints('total')?.length === 0 && (
                            <div className="no-data-wrapper">
                                <div className="no-data-content">
                                    <div className="no-data-circle">
                                        <svg className="svg-icon grid-no-content-icon">
                                            <use xlinkHref="#noFavIcon">
                                                <title>Favourites</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <p className="no-content-message">No Favourites added</p>
                                    <p className="no-content-message-caption">
                                        Insights that are added to favourites will be listed here
                                    </p>
                                </div>
                            </div>
                        )}
                        {Object.entries(dashboardExpandedReduxVal)
                            ?.filter(([filterKey, _filterVal]: any, _key) => {
                                if (insightsSelectedType === Type.itpo) {
                                    if (profileType === 'forwarder' || profileType === 'client') {
                                        return (
                                            filterKey !== 'inBoundInProgress' &&
                                            filterKey !== 'submitted' &&
                                            filterKey !== 'pendingWithForwarder'
                                        );
                                    } else if (profileType === 'agent') {
                                        return (
                                            filterKey !== 'draft' &&
                                            filterKey !== 'inBoundInProgress' &&
                                            filterKey !== 'submitted' &&
                                            filterKey !== 'pendingWithForwarder'
                                        );
                                    }
                                } else if (insightsSelectedType === Type?.ibpo) {
                                    return (
                                        filterKey !== 'awaitingEnrichment' &&
                                        filterKey !== 'readyForBooking' &&
                                        filterKey !== 'partiallyBooked' &&
                                        filterKey !== 'booked' &&
                                        filterKey !== 'pendingWithForwarder'
                                    );
                                } else if (insightsSelectedType === Type.ao) {
                                    if (profileType === 'forwarder' || profileType === 'client') {
                                        return (
                                            filterKey !== 'awaitingEnrichment' &&
                                            filterKey !== 'inBoundInProgress' &&
                                            filterKey !== 'submitted'
                                        );
                                    } else if (profileType === 'agent') {
                                        return (
                                            filterKey !== 'draft' &&
                                            filterKey !== 'awaitingEnrichment' &&
                                            filterKey !== 'inBoundInProgress' &&
                                            filterKey !== 'submitted'
                                        );
                                    }
                                } else {
                                    return filterKey;
                                }
                            })
                            ?.map(([key, value]: any, i) => {
                                return (
                                    <>
                                        <div
                                            className={`order-list-card ${
                                                insightsSelectedTypeFilterIndex === i ? 'active' : ''
                                            }`}
                                            key={`fav-wrapper-card__${i}`}
                                            onClick={() => handleItemClick(value, i, key)}
                                        >
                                            <a href="#" className="order-list-nav">
                                                <span className="order-list-nav-title">
                                                    {getStatusTitle(key) || ''}
                                                </span>
                                                <div className="order-list-nav-flex">
                                                    <span className="order-list-nav-count">{value?.count || 0}</span>
                                                    <div className="graph-holder">
                                                        {/* <LineGraph drawn={element.dataset} name="activeOrder" /> */}
                                                        <LineGraph
                                                            drawn={
                                                                dashboardExpandedReduxVal[key]
                                                                    ? plotGraphPoints(key)
                                                                    : []
                                                            }
                                                            name={`activeOrder1_${i}`}
                                                            tooltip={true}
                                                            lineDots={true}
                                                            gradientFill={false}
                                                            lineWidth={2}
                                                            pointSize={2}
                                                        />
                                                    </div>
                                                </div>
                                            </a>
                                            <div className="graph-filters">
                                                <BSTooltip
                                                    id={`favourites_${i}`}
                                                    title={
                                                        checkIfAddedToFavourites(key)
                                                            ? 'Remove from Favourites'
                                                            : 'Add to Favourites'
                                                    }
                                                    position="bottom"
                                                >
                                                    {/* Replace the class name "unselected-fav-icon" to "selected-fav-icon" on click */}
                                                    <button
                                                        className={`action-btn app-btn icon-only sm fav-icon-holder--star ${
                                                            checkIfAddedToFavourites(key)
                                                                ? 'selected-fav-icon'
                                                                : 'unselected-fav-icon'
                                                        }`}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            addOrRemoveFromFavourites(key);
                                                        }}
                                                        disabled={favLoading}
                                                    >
                                                        <svg className="svg-icon star-icon">
                                                            <use xlinkHref="#starIcon">
                                                                <title>Favourites</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </BSTooltip>
                                                <BSTooltip
                                                    id={`redirect_${i}`}
                                                    title={`Go to ${page === 'orders' ? 'Orders' : 'Bookings'} Page`}
                                                    position="bottom"
                                                >
                                                    <button
                                                        className="action-btn app-btn icon-only sm app-btn-secondary"
                                                        onClick={() => setNavigationAndFilter(key, value?.count)}
                                                        disabled={!value?.count}
                                                    >
                                                        <svg className="svg-icon">
                                                            <use xlinkHref="#redirectIcon">
                                                                <title>Redirect</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </BSTooltip>
                                            </div>
                                        </div>
                                    </>
                                );
                            })}
                    </div>
                </div>
            </div>
        ) : (
            <></>
        )
    ) : (
        <div className="app-loader inner-loading">
            <svg className="svg-icon loader-icon">
                <use xlinkHref="#loaderIcon">
                    <title>Loading</title>
                </use>
            </svg>
        </div>
    );
};
export default Index;
