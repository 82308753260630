import React, { useRef, useState } from 'react';
import './data-grid.scss';
import '../../assets/scss/components/_jquery-kendo-grid.scss';
import '../../assets/scss/components/library/kendo-bootstrap-main-min.css';
import GridLayout from './GridLayout';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
import { HelmetProvider } from 'react-helmet-async';
// import { useParams } from 'react-router-dom';

interface FilterValuesProps {
    label: string;
    value: string;
    startDate?: string;
    endDate?: string;
}

export interface GridItemType {
    title?: string;
    name: string;
    columns: {
        field: string;
        title: string;
    }[];
    fields: any;
    url: string;
    fileName: string;
    method: string;
    contentType: string;
    server?: string;
    sortOrder?: string;
}

interface GridProps {
    gridItem: GridItemType;
    filterValues: FilterValuesProps[];
    body?: unknown;
    hideFilter?: boolean;
    Btn?: any;
    type?: string;
}

const Index: React.FC<GridProps> = ({ gridItem, filterValues, body, hideFilter = true, Btn, type }) => {
    const [filterDropDownOpen, setFilterDropDownOpen] = useState(false);
    const [filterValue, setFilterValue] = useState('All');
    const [exportDropDownOpen, setExportDropDownOpen] = useState(false);
    const filterDropDownRef = useRef<HTMLDivElement>(null);
    const exportDropDownRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(filterDropDownRef, setFilterDropDownOpen);
    useOutsideAlerter(exportDropDownRef, setExportDropDownOpen);

    const props = {
        columns: JSON.stringify(gridItem.columns),
        fields: JSON.stringify(gridItem.fields),
        url: gridItem.url,
        fileName: gridItem.fileName,
        method: gridItem.method,
        contentType: gridItem.contentType,
        body: body ? JSON.stringify(body) : null,
        server: gridItem?.server,
        sortOrder: JSON.stringify(gridItem?.sortOrder),
    };
    // const { mblno } = useParams();
    return (
        <HelmetProvider>
            <div className="insight-agent-list-data-trend">
                <div className={`container-fluid ${type}`}>
                    <div className="row">
                        <div
                            ref={exportDropDownRef}
                            className={`dropdown-wrap ${
                                exportDropDownOpen ? 'dropdown-open' : ''
                            } download-dropdown export-dropdown`}
                        >
                            <button
                                onClick={(e) => {
                                    if (!e.currentTarget.disabled) {
                                        setExportDropDownOpen((prev) => !prev);
                                    }
                                }}
                                className="app-btn export-btn icon-button app-btn-secondary export-dropdown-btn"
                            >
                                <svg className="svg-icon export-icon">
                                    <use xlinkHref="#exportIcon"></use>
                                </svg>
                                <span className="button-text">Export</span>
                                <svg className="svg-icon arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>Expand row</title>
                                    </use>
                                </svg>
                            </button>
                            <div className="dropdown-menu">
                                <a
                                    onClick={() => setExportDropDownOpen(false)}
                                    className="menu-item app-btn"
                                    id="pdf-export"
                                >
                                    Export as PDF
                                </a>
                                <a
                                    onClick={() => setExportDropDownOpen(false)}
                                    className="menu-item app-btn"
                                    id="excel-export"
                                >
                                    Export as Excel
                                </a>
                            </div>
                        </div>
                        {!hideFilter && (
                            <div
                                ref={filterDropDownRef}
                                className={`dropdown-wrap right-align ${
                                    filterDropDownOpen ? 'dropdown-open' : ''
                                } kendo-dropdown`}
                            >
                                <button
                                    onClick={() => setFilterDropDownOpen((prev) => !prev)}
                                    className="app-btn app-btn-secondary blue-shade"
                                >
                                    <div className="purchase-dropdown-content">
                                        <div className="dropdown-content-heading">{filterValue}</div>
                                    </div>
                                    <svg className="svg-icon arrow-icon">
                                        <use href="#downArrow">
                                            <title>Expand row</title>
                                        </use>
                                    </svg>
                                </button>

                                <div
                                    style={{ visibility: filterDropDownOpen ? 'visible' : 'hidden' }}
                                    className="dropdown-menu"
                                >
                                    <a
                                        key="All"
                                        onClick={() => {
                                            setFilterDropDownOpen(false);
                                            setFilterValue('All');
                                        }}
                                        data-value="All"
                                        data-startdate=""
                                        data-enddate=""
                                        className="menu-item app-btn filter-btn"
                                    >
                                        All
                                    </a>
                                    {filterValues.map((res) => (
                                        <a
                                            key={res.value}
                                            onClick={() => {
                                                setFilterDropDownOpen(false);
                                                setFilterValue(res.label);
                                            }}
                                            data-value={res.value}
                                            data-startdate={res.startDate}
                                            data-enddate={res.endDate}
                                            className="menu-item app-btn filter-btn"
                                        >
                                            {res.label}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        )}
                        {Btn && (
                            <div className="dropdown-wrap right-align dropdown-open kendo-dropdown go-to-btn">
                                <Btn />
                            </div>
                        )}
                    </div>

                    <div className="k-kendo-row one-column">
                        {/* <div className="k-kendo-col-1">
                        <div className="data-name">Total TEU</div>
                        <div className="data-value" id="total"></div>

                        <h4 className="data-name">Agent with the most TEU</h4>
                        <div className="data-value" id="stat-topagent"></div>

                        <h4 className="data-name">Agent with the least TEU</h4>
                        <div className="data-value" id="stat-lowagent"></div>
                    </div> */}
                        <div className="k-kendo-col-2">
                            <div id="grid"></div>
                        </div>
                    </div>
                </div>

                <GridLayout {...props} />
            </div>
        </HelmetProvider>
    );
};
export default Index;
