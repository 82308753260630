import axios from 'axios';
import { post } from '../HttpMethods';
import moment from 'moment';

export const fetchAttachmentHistoryListDetailsApi = async (payload: {
    sortColumn: string;
    sortOrder: string;
    body: any;
    bookingId: string;
}) => {
    try {
        const copiedPayload = JSON.parse(JSON.stringify(payload));
        if (copiedPayload?.body?.dateAndTime) {
            copiedPayload.body.dateAndTime = copiedPayload?.body?.dateAndTime?.map((res: string) =>
                moment(res, 'DD-MM-YYYY').format('YYYY-MM-DD')
            );
        }
        const data = await post(
            `api/bkg/bp/attachments/history/${payload.bookingId}?sortColumn=${payload.sortColumn}&sortOrder=${payload.sortOrder}`,
            { ...copiedPayload.body },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const fetchAttachmentHistoryDistinctListDetailsApi = async (payload: { bookingId: string; body: any }) => {
    try {
        const data = await post(`api/bkg/bp/attachments/history/distinct/${payload.bookingId}`, {}, null);
        if (data?.info?.dateAndTime?.length) {
            const copied = JSON.parse(JSON.stringify(data));
            const result = data?.info?.dateAndTime.map((res: string) => moment.utc(res).local().format('DD-MM-YYYY'));
            copied.info.dateAndTime = result.filter((element: any, index: number) => {
                return result?.indexOf(element) === index;
            });
            return { data: copied };
        } else {
            return { data };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};
