import { post } from '../../HttpMethods';

export const getAvailablePoLinesAir = async (payload: {
    poId: string;
    destinationPort: string;
    goodsReadyDate: string;
}) => {
    try {
        const res = await post('api/bkg/bp/air/polines', payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const searchAvailablePoLinesAir = async (payload: { cgId: string; keyword: string }) => {
    try {
        const res = await post(`api/ord/search/availableairpolines?keyword=${payload.keyword}`, null, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
