/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import '../../../BookingProposal/ContainerAllocations/ContainerDetails/container-details.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import moment from 'moment';
import { PackingList } from 'src/store/reducers/bookingProposal/containerAllocationReducer';
import { clearFailure, updateShippingDetail } from 'src/store/reducers/bookingProposalAir/shippingDetailsReducer';
import { useAppDispatch } from '../../../../hooks';
import { error, Position, success } from '../../../../utils/toast';
// import { useNavigate } from 'react-router-dom';
import DatePicker from '../../../../components/common/DatePicker';
import { decimalNumberValidation } from 'src/utils';
import { validateFractionInput } from 'src/utils/validateFractionInput';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    bpId?: string;
    shipmentId?: string;
    shippingDetailsData: any;
}

interface BasicDetails {
    containerType: string;
    deliveryMode: string;
    packing: string;
    vgm: string;
    dropOffDate: string;
    pickUpDate: string;
}
const Index: React.FC<ModalProps> = ({ modalView, setModalView, bpId, setCancelModalView, shippingDetailsData }) => {
    // const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [collectionDateFromDepot, setCollectionDateFromDepot] = useState(new Date());
    const [_containerDropDownOpen, setContainerDropDownOpen] = useState<boolean>(false);
    const [_deliveryDropDownOpen, setDeliveryDropDownOpen] = useState<boolean>(false);
    const [_containerModeDropDownOpen, setContainerModeDropDownOpen] = useState<boolean>(false);
    const [packingDropDownOpen, setPackingDropDownOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const { shippingDetails, isError, packingList, shippingDetailsUpdateError, shippingDetailsUpdateSuccess } =
        useSelector((state: RootState) => state.shippingDetailsAir);
    const initialValues: BasicDetails = {
        containerType: shippingDetails?.containerType || 'Loose',
        deliveryMode: shippingDetails?.deliveryMode || 'Loose',
        packing: shippingDetails?.packing || '',
        vgm: shippingDetails?.vgm || '',
        dropOffDate: shippingDetails?.dropOffDate ? moment(shippingDetails?.dropOffDate).format('YYYY-MM-DD') : '',
        pickUpDate: shippingDetails?.pickUpDate ? moment(shippingDetails?.pickUpDate).format('YYYY-MM-DD') : '',
    };

    const BasicDetailsSchema = Yup.object().shape({
        containerType: Yup.string(),
        // .required('Container Type is required'),
        deliveryMode: Yup.string(),
        // .required('Delivery Mode is required'),
        packing: Yup.string(),
        // .required('Packing is required'),
        vgm: Yup.string()
            // .required('VGM is required')
            .test('equalszero', 'VGM must be greater than Zero', (value) => {
                if (value) {
                    const vgm = parseInt(value);
                    return vgm === 0 ? false : true;
                }
                return true;
            }),
        // .test('lesser', 'VGM must be lesser than 1000000', (value) => {
        //     if (value) {
        //         const vgm = parseInt(value);
        //         return vgm > 1000000 ? false : true;
        //     }
        //     return true;
        // }),
        pickUpDate: Yup.date(),
        // .required('Pick Up Date from vendor is required'),
        dropOffDate: Yup.date()
            // .required('Drop Off Date at Depot is required')
            .min(Yup.ref('pickUpDate'), 'Drop Off Date at BLP must be greater than Pick Up Date from vendor')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'Drop Off Date at BLP must be greater than Pick Up Date from vendor',
                test: function (value) {
                    if (!this.parent.pickUpDate) {
                        return true;
                    }
                    const pickUpDate = moment(this.parent.pickUpDate).format('YYYY-MM-DD');
                    const dropOffDate = moment(value).format('YYYY-MM-DD');
                    return !moment(pickUpDate).isSame(moment(dropOffDate));
                },
            }),
        //.min(Yup.ref('dropOffDate'), 'Pick Up Date from vendor must be greater than Drop Off Date at BLP'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: BasicDetailsSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // validateOnMount: modalView,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                setIsLoading(true);
                dispatch(
                    updateShippingDetail({
                        ...values,
                        vgm: parseFloat(values.vgm),
                        bpId,
                    })
                );
            }
        },
    });

    useEffect(() => {
        setIsLoading(false);
        if (isError && shippingDetailsUpdateError) {
            error('Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (shippingDetailsUpdateSuccess) {
            success(`Shipment details have been updated`, Position.TOP_RIGHT);
            setModalView(false);
            formik.resetForm();
            dispatch(clearFailure([]));
        }
    }, [isError, shippingDetailsUpdateSuccess, shippingDetailsUpdateError]);

    useEffect(() => {
        if (!modalView) {
            formik.resetForm();
        }
    }, [modalView]);

    const changeDropDown = (field: string) => {
        switch (field) {
            case 'containerType':
                setContainerDropDownOpen((prev) => !prev);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'deliveryMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen((prev) => !prev);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'containerMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen((prev) => !prev);
                setPackingDropDownOpen(false);
                break;
            case 'packing':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen((prev) => !prev);
                break;
            default:
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
        }
    };

    const packingSelection = (res: PackingList) => {
        formik.setFieldError('packing', undefined);
        formik.setFieldValue('packing', res.packingName);
        // if (formik.values.packing.includes(res.packingCode)) {
        //     const packing = formik.values.packing.filter((el: string) => el !== res.packingCode);
        //     formik.setFieldValue('packing', packing);
        // } else {
        //     const packing = [...formik.values.packing, res.packingCode];
        //     formik.setFieldValue('packing', packing);
        // }
    };
    const showError = (field: keyof typeof initialValues) => (
        <>
            {formik.errors[field] &&
            (formik.errors[field]?.includes('must be') || formik.errors[field]?.includes("can't be")) ? (
                <div className="error-text">{formik.errors[field]}</div>
            ) : null}
        </>
    );
    const showErrorValidation = (field: keyof typeof initialValues) => (
        <>{formik.errors[field] ? <div className="error-text">{formik.errors[field]}</div> : null}</>
    );

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div
                        onClick={(e) => {
                            changeDropDown('');
                            e.stopPropagation();
                        }}
                        className="modal-content-holder basic-detail-content-holder medium-popup"
                    >
                        <div className="modal-header">
                            <div className="title">Shipment Details</div>
                            <button
                                type="button"
                                className="app-btn modal-close-btn"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content bd-modal-main-content">
                                <form
                                    id="basic-details"
                                    action=""
                                    onSubmit={formik.handleSubmit}
                                    className="modal-form layout-holder one-column"
                                >
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Container Type
                                            {/* <span className="mandatory-field-text">*</span> */}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Container Type"
                                                type="text"
                                                className="modal-input input-text"
                                                id="containerNumber"
                                                name="containerNumber"
                                                onChange={(event) => {
                                                    // event.target.value
                                                    //     ? formik.setFieldError(event.target.name, undefined)
                                                    //     : formik.setFieldError(event.target.name, 'Field is Required');
                                                    formik.handleChange(event);
                                                }}
                                                value={shippingDetailsData?.containerType}
                                                readOnly
                                            />
                                        </div>
                                        {showErrorValidation('containerType')}
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Delivery Mode
                                            {/* <span className="mandatory-field-text">*</span> */}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Delivery Mode"
                                                type="text"
                                                className="modal-input input-text"
                                                id="containerNumber"
                                                name="containerNumber"
                                                onChange={(event) => {
                                                    event.target.value
                                                        ? formik.setFieldError(event.target.name, undefined)
                                                        : formik.setFieldError(event.target.name, 'Field is Required');
                                                    formik.handleChange(event);
                                                }}
                                                value={shippingDetailsData?.deliveryMode}
                                                readOnly
                                            />
                                        </div>
                                        {showErrorValidation('deliveryMode')}
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Packing
                                            {/* <span className="mandatory-field-text">*</span> */}
                                        </label>
                                        {/* <div className="modal-input-field-holder grey-option-box modal-input-layout-item">
                                            <div
                                                className={`dropdown-wrap menu-down modal-dropdown ${
                                                    packingDropDownOpen ? 'dropdown-open' : ''
                                                } `}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        changeDropDown('packing');
                                                    }}
                                                    className="menu-btn app-btn modal-input select-modal"
                                                >
                                                    {formik.values.packing ? (
                                                        <span className="button-text selected-text">
                                                            {formik.values.packing}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">Select Packing</span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>

                                                <div
                                                    className={
                                                        packingDropDownOpen
                                                            ? 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                                            : 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down modal-dropdown'
                                                    }
                                                    style={{
                                                        width: '425px',
                                                    }}
                                                >
                                                    <div className="dropdown-menu ">
                                                        {packingList.map((res: PackingList) => (
                                                            <a
                                                                onClick={(e) => {
                                                                    packingSelection(res);
                                                                    e.preventDefault();
                                                                }}
                                                                key={res.packingId}
                                                                className="menu-item app-btn"
                                                            >
                                                                {res.packingName}
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter packing"
                                                type="text"
                                                className="modal-input input-text"
                                                id="packing"
                                                name="packing"
                                                onChange={(event) => {
                                                    event.target.value
                                                        ? formik.setFieldError(event.target.name, undefined)
                                                        : formik.setFieldError(event.target.name, 'Field is Required');
                                                    formik.handleChange(event);
                                                }}
                                                value={shippingDetailsData?.packing}
                                                readOnly
                                            />
                                        </div>
                                        {showErrorValidation('packing')}
                                    </div>
                                    <div className="modal-input-holder">
                                        <label className="modal-input-label">
                                            VGM (in kg)
                                            {/* <span className="mandatory-field-text">*</span> */}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter VGM"
                                                type="text"
                                                className="modal-input input-text"
                                                id="vgm"
                                                name="vgm"
                                                onChange={(event: any) => {
                                                    // event.target.value
                                                    //     ? formik.setFieldError(event.target.name, undefined)
                                                    //     : formik.setFieldError(event.target.name, 'Field is Required');

                                                    if (
                                                        // event.target.value <= 1000000 &&
                                                        decimalNumberValidation(parseFloat(event.target.value), 7, 2)
                                                    ) {
                                                        const split = event.target.value?.split('.');
                                                        if (split?.[0]?.length > 7) {
                                                            return false;
                                                        } else {
                                                            formik.handleChange(event);
                                                        }
                                                    }
                                                }}
                                                onKeyPress={(event) => {
                                                    if (
                                                        !validateFractionInput({
                                                            inputKey: event.key,
                                                            currentValue: +formik.values.vgm,
                                                        })
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    e.target.value !== '' &&
                                                        parseFloat(e.target.value) >= 0 &&
                                                        formik.validateField('vgm');
                                                }}
                                                value={formik.values.vgm}
                                            />
                                            {/* {formik?.values?.vgm && formik?.errors?.vgm && (
                                                <div className="error-text">{formik.errors.vgm}</div>
                                            )} */}
                                        </div>
                                        {showErrorValidation('vgm')}
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Pickup Date from Vendor
                                            {/* <span className="mandatory-field-text">*</span> */}
                                        </label>
                                        <div className="modal-input-layout-item calendar">
                                            <DatePicker
                                                minDate={
                                                    formik.values.dropOffDate
                                                        ? new Date(
                                                              new Date(formik.values.dropOffDate).setDate(
                                                                  new Date(formik.values.dropOffDate).getDate() - 1
                                                              )
                                                          )
                                                        : new Date()
                                                }
                                                formik={formik}
                                                fieldName="pickUpDate"
                                                portalId="date-portal"
                                                position="top-start"
                                                // disabled={!formik.values.dropOffDate}
                                            />
                                            {showError('pickUpDate')}
                                        </div>
                                        {showErrorValidation('pickUpDate')}
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Drop Off Date at Depot
                                            {/* <span className="mandatory-field-text">*</span> */}
                                        </label>
                                        <div className="modal-input-layout-item calendar">
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    minDate={
                                                        formik.values.pickUpDate
                                                            ? new Date(
                                                                  new Date(formik.values.pickUpDate).setDate(
                                                                      new Date(formik.values.pickUpDate).getDate() + 1
                                                                  )
                                                              )
                                                            : new Date()
                                                    }
                                                    disabled={!formik.values.pickUpDate}
                                                    formik={formik}
                                                    fieldName="dropOffDate"
                                                    portalId="date-portal"
                                                    position="top-start"
                                                />
                                                {showError('dropOffDate')}
                                            </div>
                                        </div>
                                        {showErrorValidation('pickUpDate')}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn text-close-btn"
                                type="reset"
                                form="basic-details"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="submit"
                                form="basic-details"
                                // disabled={Object.keys(formik.errors).length ? true : false}
                            >
                                <span className="button-text vendor-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
