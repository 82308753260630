/* eslint-disable no-undefined */
import { ReactElement } from 'react';
import Select, { components, MenuPosition, MenuPlacement, InputActionMeta } from 'react-select';
import { reactSelectStyle, reactSelectTheme } from '../../../utils/constants';
import './style.css';
const REACT_SELECT_WRAPPER = process.env.NODE_ENV === 'development' ? 'MenuList' : 'css-';
interface DataProps {
    value: string;
    label: string;
    extraField?: string;
}
interface SelectProps {
    data: DataProps[];
    placeholder: string;
    stateValue: any;
    setStateValue: any;
    fieldName: string;
    index?: number;
    extraField?: any;
    extraFieldFn?: any;
    isDisabled?: boolean;
    isClearable?: boolean;
    NoMessageComponent?: any;
    customStyle?: any;
    menuPortalTarget?: any;
    menuPosition?: MenuPosition;
    closeMenuOnScroll?: boolean;
    menuPlacement?: MenuPlacement;
    className?: string;
    onInputChangeFn?: any;
    overrideValue?: any;
    onFocusFn?: any;
    onClear?: any;
    isLoading?: any;
}

const Index = ({
    data,
    placeholder,
    fieldName,
    index,
    extraFieldFn,
    isDisabled,
    isClearable,
    NoMessageComponent,
    customStyle,
    stateValue,
    setStateValue,
    menuPortalTarget,
    menuPosition,
    closeMenuOnScroll,
    menuPlacement,
    onInputChangeFn,
    className = 'react-select',
    overrideValue,
    onFocusFn,
    onClear,
    isLoading,
}: SelectProps): ReactElement => {
    const NoOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props}>
                {NoMessageComponent ? (
                    <NoMessageComponent {...props} />
                ) : (
                    <div className="no-data-content">
                        <svg className="svg-icon grid-no-content-icon">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">No Matching Records Found</p>
                    </div>
                )}
            </components.NoOptionsMessage>
        );
    };
    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };

    return (
        <Select
            options={data}
            isClearable={isClearable === false ? false : true}
            placeholder={placeholder}
            menuPosition={menuPosition ? menuPosition : undefined}
            menuPortalTarget={menuPortalTarget ? menuPortalTarget : undefined}
            onChange={(option: any, { action }) => {
                if (action === 'clear') {
                    onClear && onClear();
                }
                if (index != undefined) {
                    setStateValue(option, index, fieldName);
                } else {
                    setStateValue(option?.value);
                }
                extraFieldFn && extraFieldFn(option?.value);
            }}
            value={
                overrideValue ??
                data?.filter((option: any) => {
                    if (option.value === stateValue) {
                        return {
                            label: stateValue,
                            value: stateValue,
                        };
                    }
                })
            }
            onInputChange={(text: string, actionMeta: InputActionMeta) => {
                if (onInputChangeFn) {
                    onInputChangeFn(text, actionMeta);
                }
            }}
            onFocus={() => {
                if (onFocusFn) {
                    onFocusFn();
                }
            }}
            menuPlacement={menuPlacement ? menuPlacement : 'auto'}
            name={fieldName}
            closeMenuOnScroll={(event: any) => {
                if (closeMenuOnScroll) {
                    return event?.target?.classList?.value.includes(REACT_SELECT_WRAPPER) ? false : true;
                } else {
                    return false;
                }
            }}
            isDisabled={isDisabled === true ? true : false}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                NoOptionsMessage,
                ClearIndicator,
            }}
            styles={customStyle ? { ...reactSelectStyle, ...customStyle } : reactSelectStyle}
            theme={reactSelectTheme}
            className={className}
            classNamePrefix={className}
            isLoading={isLoading}
        />
    );
};

export default Index;
