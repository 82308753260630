import axios from 'axios';
import { get } from '../../HttpMethods';
import fileDownload from 'js-file-download';

export const fetchAdditionalDataLcl = async (payload: { bpId: string }) => {
    try {
        const data = await get(`api/bkg/bp/additionaldetails/lcl/${payload.bpId}`, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const downloadLclQuotationFile = async (cgId: string, fileName: string, fileId: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/bkg/bp/download/sellquote/${cgId}/${fileId}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
