export const reassignAgentApprovalDetails = [
    {
        columnName: 'PO NUMBER',
        name: 'poNumber',
        headerClass: 'grid-cell-header col-po-number grid-sticky-column sticky-left-aligned',
        hasSort: true,
        sortName: 'poNumber',
        hasFilter: true,
        filterName: 'poNumber',
    },
    {
        columnName: 'BOOKING NUMBER',
        name: 'bpNumber',
        headerClass: 'grid-cell-header col-booking-number grid-sticky-column sticky-left-aligned',
        hasSort: true,
        sortName: 'bpNumber',
        hasFilter: true,
        filterName: 'bpNumber',
    },
    {
        columnName: 'CURRENT AGENT',
        name: 'currentAgentName',
        headerClass: 'grid-cell-header col-current-agent',
        hasSort: true,
        sortName: 'currentAgentName',
        hasFilter: true,
        filterName: 'currentAgentName',
    },
    {
        columnName: 'NEW AGENT',
        name: 'newAgentName',
        headerClass: 'grid-cell-header col-po-number-air',
        hasSort: true,
        sortName: 'newAgentName',
        hasFilter: true,
        filterName: 'newAgentName',
    },
    {
        columnName: 'CLIENT',
        name: 'clientName',
        headerClass: 'grid-cell-header col-client',
        hasSort: true,
        sortName: 'clientName',
        hasFilter: true,
        filterName: 'clientName',
    },
    {
        columnName: 'DATE REQUESTED',
        name: 'dateRequested',
        headerClass: 'grid-cell-header col-date-requested',
        hasSort: true,
        sortName: 'dateRequested',
        hasFilter: true,
        filterName: 'dateRequested',
    },
    {
        columnName: 'ORIGIN',
        name: 'originPort',
        headerClass: 'grid-cell-header col-origin',
        hasSort: true,
        sortName: 'originPort',
        hasFilter: true,
        filterName: 'originPort',
    },
    {
        columnName: 'DESTINATION',
        name: 'destinationPort',
        headerClass: 'grid-cell-header col-destination',
        hasSort: true,
        sortName: 'destinationPort',
        hasFilter: true,
        filterName: 'destinationPort',
    },
    {
        columnName: 'DESTINATION TYPE',
        name: 'destinationType',
        headerClass: 'grid-cell-header col-destination-type',
        hasSort: true,
        sortName: 'destinationType',
        hasFilter: true,
        filterName: 'destinationType',
    },
    {
        columnName: 'MODE',
        name: 'mode',
        headerClass: 'grid-cell-header col-mode',
        hasSort: true,
        sortName: 'mode',
        hasFilter: true,
        filterName: 'mode',
    },
    {
        columnName: 'VENDOR',
        name: 'vendorName',
        headerClass: 'grid-cell-header col-vendor',
        hasSort: true,
        sortName: 'vendorName',
        hasFilter: true,
        filterName: 'vendorName',
    },
    {
        columnName: 'ETD',
        name: 'etd',
        headerClass: 'grid-cell-header col-etd',
        hasSort: true,
        sortName: 'etd',
        hasFilter: true,
        filterName: 'etd',
    },
    {
        columnName: 'ETA',
        name: 'eta',
        headerClass: 'grid-cell-header col-eta',
        hasSort: true,
        sortName: 'eta',
        hasFilter: true,
        filterName: 'eta',
    },
    {
        columnName: 'RELEASE TYPE',
        name: 'releaseType',
        headerClass: 'grid-cell-header col-incoterm',
        hasSort: true,
        sortName: 'releaseType',
        hasFilter: true,
        filterName: 'releaseType',
    },
    {
        columnName: 'CARRIER - FLIGHT',
        name: 'carrierFlights',
        headerClass: 'grid-cell-header col-carrier-flight',
        hasSort: true,
        sortName: 'carrierFlights',
        hasFilter: true,
        filterName: 'carrierFlights',
    },
    {
        columnName: 'SUBMITTED BY',
        name: 'submittedBy',
        headerClass: 'grid-cell-header col-submitted-by',
        hasSort: true,
        sortName: 'submittedBy',
        hasFilter: true,
        filterName: 'submittedBy',
    },
];
