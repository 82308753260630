import React, { useEffect, useState } from 'react';
import '../PurchaseOrderHeader/purchase-order-header.scss';
import './confirmation-header.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { Link, useParams } from 'react-router-dom';
import { fetchCancelReason, fetchHeader, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import RejectOptions from './RejectOptions';
import { ConfirmationGroups } from 'src/store/reducers/purchaseOrder/containerModePoReducer';
import moment from 'moment';
import { poContainerStatusPillList } from '../ContainerModeSelection/PoStatusList';
import { useUserProfile } from 'src/hooks/useUserProfile';
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../store/reducers';
interface HeaderProps {
    id?: string;
    data: ConfirmationGroups;
}

const Index: React.FC<HeaderProps> = ({ id, data }) => {
    const dispatch = useAppDispatch();
    const { group_id } = useParams();
    const { profileType } = useUserProfile();
    const headerData = useAppSelector(selectHeader);
    // const cancelledReason = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi
    // donec quis proin enim massa ipsum. Scelerisque proin vulputate sed convallis lacinia venenatis malesuada sit mattis. Accumsan, tristique leo ultricies commodo velit in id odio.`;
    const [showReasonModal, setShowReasonModal] = useState(false);

    const onClose = () => {
        setShowReasonModal(false);
    };

    useEffect(() => {
        dispatch(
            fetchHeader({
                poId: id,
                profile: profileType,
                cgId: group_id,
            })
        );
    }, [id]);

    useEffect(() => {
        if (headerData?.poStatus === 'Cancelled') {
            dispatch(fetchCancelReason({ poId: id }));
        }
    }, [headerData]);

    return (
        <div className="purchase-order-header-fields -confirmation-group">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    <Link to={`/purchase_order/${id}/container_mode_selection`}>
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link>
                    <h2 className="page-heading">{data?.confirmationGroupName}</h2>
                    {data?.selectedGroup ? (
                        <div
                            className={`modal-toast ${
                                data?.selectedGroup?.toLowerCase() === 'lcl' ? 'light-blue ' : 'dark-blue'
                            } group-tag`}
                        >
                            {data?.selectedGroup?.toUpperCase()}
                        </div>
                    ) : (
                        <>{data?.type === 'FCL' && <div className="modal-toast dark-blue group-tag">FCL</div>}</>
                    )}
                    <div className="po-cancel-wrapper">
                        <div className="item-count-status-holder">{poContainerStatusPillList(data?.status)}</div>
                        {data?.status?.toLowerCase().includes('reject') && profileType !== 'client' && (
                            <a
                                className="add-content-link"
                                href="javascript:void(0)"
                                onClick={() => setShowReasonModal(true)}
                            >
                                View Reason
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">PO Number</h6>
                        <div className="details-box-content">{headerData?.poNumber}</div>
                    </div>
                    <div className="details-col box-content-holder client-name-col">
                        <h6 className="details-box-heading">Client Name</h6>
                        <div className="details-box-content">{headerData?.clientName}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Vendor Name</h6>
                        <div className="details-box-content">{headerData?.vendorName}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Destination</h6>
                        {/* <div className="details-box-content">{data?.destinationPortName} </div> */}

                        <div className="details-box-content">{`${data?.destinationPortName} ${
                            data?.destinationPort ? `(${data?.destinationPort})` : ''
                        }`}</div>
                    </div>
                </div>
                <div className="card-detail-col col-info-2">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Order Lines</h6>
                        <div className="details-box-content">
                            {data?.poLines ? data?.poLines : headerData?.orderLines || 0}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Goods Ready Date</h6>
                        <div className="details-box-content">
                            {data?.goodsReadyDate ? moment(data?.goodsReadyDate).format('DD-MM-YYYY') : ''}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">
                            {data?.cbm >= 0 ? Math.ceil(data?.cbm) : Math.ceil(headerData?.totalCbms) ?? 0}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">
                            {data?.weight >= 0 ? Math.ceil(data?.weight) : Math.ceil(headerData?.totalKgs) || 0}
                        </div>
                    </div>
                </div>
            </div>
            {showReasonModal ? (
                <RejectOptions onClose={onClose} reasonData={headerData?.reason} viewReason={true}></RejectOptions>
            ) : null}
        </div>
    );
};
export default Index;
