import React from 'react';
import { ShipmentTabProps } from '.';
import { HelmetProvider } from 'react-helmet-async';
import GridLayout from 'src/components/KendoGridJquery/GridLayoutWithLocalData';
import { shipmentGridDetails } from './columns';
import { useTimeout } from 'react-use';

const Containers: React.FC<ShipmentTabProps> = ({ data }) => {
    const [isReady] = useTimeout(100);

    const getData = () => {
        return data?.containerCollection?.container?.map((res) => {
            return {
                containerNumber: res?.containerNumber,
                type: res?.containerType?.code,
                category: res?.containerType?.category?.code,
                isoCode: res?.containerType?.isoCode,
                teu: '', // TODO
                deliveryMode: res?.deliveryMode,
                seal: res?.seal,
                goodsWeight: res?.goodsWeight,
                grossWeight: res?.grossWeight,
                weightUnit: res?.weightUnit?.code,
            };
        });
    };
    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-content-holder">
                {isReady() ? (
                    <HelmetProvider>
                        <div className="insight-agent-list-data-trend">
                            <div className="container-fluid shipment-details">
                                <div className="k-kendo-row one-column">
                                    <div className="k-kendo-col-2 custom-kendo-grid">
                                        <div id="grid"></div>
                                    </div>
                                </div>
                            </div>

                            <GridLayout
                                data={getData()}
                                columns={shipmentGridDetails['containers'].columns}
                                fileName={shipmentGridDetails['containers'].fileName}
                                fields={shipmentGridDetails['containers'].fields}
                            />
                        </div>
                    </HelmetProvider>
                ) : null}
            </div>
        </div>
    );
};

export default Containers;
