import React, { useEffect } from 'react';
import '../../BookingProposal/BookingAdditionalInfo/rejected-booking-proposal.scss';
import { useAppDispatch } from '../../../hooks';
import { fetchRejectionReason } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    bpId: any;
    profileType: string;
    type: string;
}
const RejectReasonHistory: React.FC<ModalProps> = ({ modalView, setModalView, bpId, profileType, type }) => {
    const { rejectionReason, bpStatus } = useSelector((state: RootState) => state.bpHeaderData) as any;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (bpId !== 'POSelection') dispatch(fetchRejectionReason({ bpId: bpId, profile: profileType }));
    }, [bpId]);

    const getData = () => {
        return type === 'options' ? rejectionReason?.options : rejectionReason?.normal;
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder rejected-booking-modal">
                        <div className="modal-header">
                            <div className="title">
                                {type === 'options' ? 'Options Rejection History' : 'Reject Reason History'}
                            </div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    {getData()?.length === 0 ? (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No data found</p>
                                        </div>
                                    ) : null}
                                    {getData().map((res: any) => (
                                        <div className="layout-item count">
                                            <div className="layout-header">
                                                <div className="titleWrapper">
                                                    <h6 className="title">{res?.rejectedByFullName}</h6>
                                                    <span className="subtitle">rejected this booking proposal</span>
                                                </div>
                                                <h6 className="heading-type-2">
                                                    {moment
                                                        .utc(res?.createdDateTime)
                                                        .local()
                                                        .format('DD-MM-YYYY HH:mm')}
                                                </h6>
                                            </div>
                                            <h6 className="heading-type-2">Rejection Reason</h6>
                                            <div className="description-type-1">{res?.rejectedReason}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default RejectReasonHistory;
