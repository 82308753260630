import React, { ReactElement, useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
// import { NonceProvider } from 'react-select';
import { quickLinks } from 'src/utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    fetchQuickLinks,
    resetErrorFlags,
    resetSuccessFlag,
    saveQuickLinksData,
} from 'src/store/reducers/dashboard/quickLinksReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import { error, Position, success } from 'src/utils/toast';
import ClickAwayListener from 'react-click-away-listener';
import { updateSavedGridLayouts } from 'src/services/apis/SavedGridLayout';

interface BioProps {
    modalHandle: any;
    modalOpen: boolean;
    data: any;
    savedLayout: any;
    getQuickLinksSavedLayoutData: any;
    setDisplayCancel: any;
}

export function formatString(input: string): string {
    return input
        .split('~')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' > ');
}

const Index: React.FC<BioProps> = ({
    data,
    savedLayout,
    getQuickLinksSavedLayoutData,
    modalHandle,
    modalOpen,
    setDisplayCancel,
}): ReactElement => {
    const {
        isSaveError,
        error: errorMessageObject,
        isSaveSuccess,
        quickLinks: quickLinksData,
    } = useSelector((state: RootState) => state.quickLinks);
    const dispatch = useDispatch();
    const { userName } = useUserProfile();
    const [dataList, setDataList] = useState<any>([]);
    const [selected, setSelected] = useState<any>([]);
    const [showSaveButton, setShowSaveButton] = useState(false);
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        // change background colour if dragging

        backgroundColor: isDragging ? '#F0F1FF' : 'transparent',
        // styles we need to apply on draggables
        boxShadow: isDragging ? '2px 2px 6px 0px rgba(0, 0, 0, 0.25)' : 'none',
        borderRadius: isDragging ? '4px' : '0px',
        padding: '10px 24px',
        margin: '0px 0px',
        // borderTop: isDragging ? 'none' : '1px solid $grey5',
        ...draggableStyle,
    });
    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    useEffect(() => {
        // Show Success message on Save;
        if (isSaveSuccess) {
            dispatch(fetchQuickLinks({ userName }));
            success('Quick Links Updated', Position.TOP_RIGHT);
            dispatch(resetSuccessFlag({}));
            modalHandle(false);
        }
    }, [isSaveSuccess]);

    useEffect(() => {
        //Show error incase save failure
        if (isSaveError) {
            error(errorMessageObject?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetErrorFlags({}));
        }
    }, [isSaveError]);

    const setDefaultData = () => {
        const userPreferenceDataArray = JSON.parse(JSON.stringify(quickLinksData)) ?? [];

        const localDataArray = JSON.parse(JSON.stringify(data));
        let finalArray: any = [];
        if (!Array.isArray(userPreferenceDataArray)) {
            for (const element in userPreferenceDataArray) {
                if (userPreferenceDataArray) {
                    const filterData = localDataArray?.filter((x: any) => x.link === userPreferenceDataArray[element]);
                    if (filterData) {
                        finalArray = [...finalArray, ...filterData];
                    }
                }
            }
        } else {
            for (const element of userPreferenceDataArray) {
                const filterData = localDataArray?.filter((x: any) => x.link === element.link);
                if (filterData) {
                    finalArray = [...finalArray, ...filterData];
                }
            }
        }

        for (const element of data) {
            const index = finalArray.findIndex((x: any) => x.link === element?.link);
            if (index === -1) {
                finalArray?.push(element);
            }
        }
        for (let index = 0; index < finalArray?.length; index++) {
            finalArray[index] = { ...finalArray[index], id: (index + 1).toString() };
        }

        setDataList(finalArray);
        if (quickLinksData?.length) {
            let updatedData: any = [];
            for (let index = 0; index < data.length; index++) {
                const foundObj = quickLinksData?.find((res: any) => res.link === data[index].link);
                if (foundObj) {
                    const dataObj = { ...data[index], id: foundObj.id };
                    updatedData = [...updatedData, dataObj];
                }
            }
            setSelected(updatedData);
        }
    };

    useEffect(() => {
        //Show saved quick links on top of the list then the unsaved items.
        setDefaultData();
    }, [quickLinksData]);

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }
        const resultData = reorder(dataList, result.source.index, result.destination.index);
        const updatedData: any = [...resultData];
        const updatedLinkArray = updatedData.map(({ link }: any) => link);
        selected.sort(
            (itemA: any, itemB: any) => updatedLinkArray.indexOf(itemA.link) - updatedLinkArray.indexOf(itemB.link)
        );
        const orderedSelected = selected.map((item: any, idx: number) => ({ ...item, id: idx + 1 }));
        setSelected(orderedSelected);
        setDataList(updatedData);
    }

    const onSelectAll = () => {
        setSelected(JSON.parse(JSON.stringify(dataList)));
        setShowSaveButton(true);
    };

    const onUnselectAll = () => {
        setSelected([]);
        setShowSaveButton(true);
    };

    const reset = () => {
        setDefaultData();
        setShowSaveButton(false);
    };

    const shouldHideSaveButton = () => {
        let orderMappingObj: any = {};
        //generate mapping object with link as key and its order 'id's as value
        for (const item of selected) {
            orderMappingObj = { ...orderMappingObj, [item.link]: item.id };
        }
        //compare array lengths and check if order 'id's in both arrays are the same
        return (
            quickLinksData?.length === selected?.length &&
            quickLinksData?.every(
                (item) => orderMappingObj[item.link] && parseInt(orderMappingObj[item.link]) === item.id
            )
        );
    };

    useEffect(() => {
        // setShowSaveButton(!shouldHideSaveButton());
    }, [selected.length, dataList]);

    //Selected items from settings list
    const selectLinks = (checked: boolean, element: any) => {
        const currentSelectedData = JSON.parse(JSON.stringify(selected));
        if (checked) {
            currentSelectedData.push(element);
        } else {
            const index = currentSelectedData.findIndex((x: any) => x.link === element.link);
            if (index > -1) {
                currentSelectedData.splice(index, 1);
            }
        }
        setShowSaveButton(true);
        setSelected(currentSelectedData);
    };

    //Save the updated data
    const onSave = () => {
        let orderedArray = [];
        for (const ql of selected) {
            const index = dataList?.findIndex((x: any) => x.link === ql.link);
            orderedArray.push({ name: ql.name, link: ql.link, id: index + 1 });
        }
        orderedArray?.sort((a: any, b: any) => a.id - b.id);
        orderedArray = orderedArray?.map((x: any, idx: number) => ({ ...x, id: idx + 1 }));
        dispatch(saveQuickLinksData({ quickLinks: JSON.stringify(orderedArray), userName }));
    };

    useEffect(() => {
        if (!modalOpen) {
            const result = data.map((res: any, index: number) => ({ ...res, id: index }));
            setDataList(result);
            setSelected(quickLinksData);
            modalHandle(false);
            setShowSaveButton(false);
        }
    }, [modalOpen]);

    const checkedStatus = (element: any) => {
        return selected?.find((x: any) => x.link === element.link);
    };

    const checkedStatusSavedLayout = (element: any) => {
        return savedLayout?.find((x: any) => x.UD_PK === element.UD_PK)?.UD_UserDefinedText2 === 'quicklink';
    };

    const updateSavedLayout = (checked: boolean, element: any) => {
        updateSavedGridLayouts(element?.UD_PK, {
            // UD_UserDefinedText1: data?.UD_UserDefinedText1,
            UD_UserDefinedText2: checked ? 'quicklink' : '',
        })
            .then((res: any) => {
                success('Quick Link Grid Layouts updated successfully', Position.TOP_RIGHT, 'grid-layout', 1000);
                getQuickLinksSavedLayoutData();
                dispatch(fetchQuickLinks({ userName }));
            })
            .catch((err: any) => {
                console.log('error', err);
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            });
    };

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    modalOpen ? (showSaveButton ? setDisplayCancel(true) : modalHandle(false)) : modalHandle(false);
                }}
            >
                <div className={`side-bar lg quick-list-modal ${modalOpen ? 'active' : ''}`}>
                    <button
                        className="app-btn text-close-btn"
                        onClick={() => {
                            showSaveButton ? setDisplayCancel(true) : modalHandle(false);
                        }}
                    >
                        <svg className="svg-icon text-close-icon">
                            <use href="#closeIcon"></use>
                        </svg>
                    </button>
                    <h2 className="page-heading">Quick Links</h2>
                    <div className="sidebar-reset-wrapper">
                        <p className="page-caption-ql">Select the quick links to show in Dashboard</p>
                        <div className="reset-link">
                            {dataList.length !== selected.length ? (
                                <a
                                    href="javascript:void(0)"
                                    className="clear-filter-link"
                                    title="Select all"
                                    onClick={() => {
                                        onSelectAll();
                                    }}
                                >
                                    Select all
                                </a>
                            ) : null}
                            {selected.length >= 1 ? (
                                <a
                                    href="javascript:void(0)"
                                    className="clear-filter-link"
                                    title="Unselect all"
                                    onClick={() => {
                                        onUnselectAll();
                                    }}
                                >
                                    Unselect all
                                </a>
                            ) : null}
                            {showSaveButton ? (
                                <a
                                    href="javascript:void(0)"
                                    className="clear-filter-link"
                                    title="Reset"
                                    onClick={() => {
                                        reset();
                                    }}
                                >
                                    Reset
                                </a>
                            ) : null}
                        </div>
                    </div>
                    <div className="page-body details-box custom quick-list drag-sidepanel">
                        <div className="card-detail-col col-1">
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-1">
                                    {(provided, _snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <div className="checkbox-item">
                                                {dataList?.map((element: any, index: any) => {
                                                    return (
                                                        <Draggable
                                                            draggableId={element.id?.toString()}
                                                            index={index}
                                                            key={element.id?.toString()}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    // eslint-disable-next-line prettier/prettier
                                                                    className={`drag-list-item ${
                                                                        snapshot.isDragging && 'dragging-component'
                                                                        // eslint-disable-next-line prettier/prettier
                                                                    }`}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <label
                                                                        className="app-check-wrapper"
                                                                        key={`checkboslist__${index}`}
                                                                    >
                                                                        <input
                                                                            key={Math.random()}
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            checked={checkedStatus(element)}
                                                                            onChange={(e) => {
                                                                                selectLinks(e.target.checked, element);
                                                                            }}
                                                                        />
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use xlinkHref="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="checkbox-label">
                                                                            {
                                                                                (quickLinks as any)[element.link]
                                                                                    .titleType
                                                                            }
                                                                            {(quickLinks as any)[element.link].title}
                                                                        </div>
                                                                    </label>
                                                                    <a
                                                                        {...provided.dragHandleProps}
                                                                        className="drag-handle"
                                                                    >
                                                                        <svg className="svg-icon drag-icon">
                                                                            <use href="#dragIcon"></use>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                        <div className="card-detail-col col-1 saved-grid">
                            <h2 className="page-heading">My Saved Grid Layouts</h2>
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-1">
                                    {(provided, _snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            <div className="checkbox-item">
                                                {savedLayout?.map((element: any, index: any) => {
                                                    return (
                                                        <Draggable
                                                            draggableId={element.UD_PK?.toString()}
                                                            index={index}
                                                            key={element.UD_PK?.toString()}
                                                        >
                                                            {(provided, snapshot) => (
                                                                <div
                                                                    // eslint-disable-next-line prettier/prettier
                                                                    className={`drag-list-item ${
                                                                        snapshot.isDragging && 'dragging-component'
                                                                        // eslint-disable-next-line prettier/prettier
                                                                    }`}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <label
                                                                        className="app-check-wrapper"
                                                                        key={`checkboslist__${index}`}
                                                                    >
                                                                        <input
                                                                            key={Math.random()}
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            checked={checkedStatusSavedLayout(element)}
                                                                            onChange={(e) => {
                                                                                updateSavedLayout(
                                                                                    e.target.checked,
                                                                                    element
                                                                                );
                                                                            }}
                                                                        />
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use xlinkHref="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="checkbox-label">
                                                                            {formatString(element.UD_DataKey)} :{' '}
                                                                            {element.UD_UserDefinedText1}
                                                                        </div>
                                                                    </label>
                                                                    {/* <a
                                                                        {...provided.dragHandleProps}
                                                                        className="drag-handle"
                                                                    >
                                                                        <svg className="svg-icon drag-icon">
                                                                            <use href="#dragIcon"></use>
                                                                        </svg>
                                                                    </a> */}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    );
                                                })}
                                                {provided.placeholder}
                                            </div>
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>

                    <div className="side-page-footer">
                        <button
                            className="app-btn app-btn-secondary "
                            title="Cancel"
                            onClick={() => {
                                showSaveButton ? setDisplayCancel(true) : modalHandle(false);
                            }}
                        >
                            <span className="button-text footer-button-text">Cancel</span>
                        </button>
                        <button
                            disabled={showSaveButton ? false : true}
                            className="app-btn app-btn-primary"
                            title="Save"
                            onClick={() => onSave()}
                        >
                            <span className="button-text footer-button-text">Save</span>
                        </button>
                    </div>
                </div>
            </ClickAwayListener>
            <div className="sidebar-backdrop"></div>
        </>
    );
};
export default Index;
