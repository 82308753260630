import axios from 'axios';
import { get, post } from '../../HttpMethods';
import { getOrganisationData } from 'src/utils';
import moment from 'moment';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const fetchWorkTrendsListDetailsApi = async (_payload: {
    filter: string;
    profile: string;
    userName: string;
    userIds: Array<string>;
}) => {
    try {
        const listOfUserOrganisations = getOrganisationData();
        const payloadValues = {
            ...listOfUserOrganisations,
            profile: _payload.profile,
            loggedInUserName: _payload.userName,
            userIds: _payload?.userIds,
        };
        let dataORD = [];
        let dataMGT = [];
        let dataBKG = [];

        //implementing try catch to ensure this works properly when any of the services are down
        try {
            dataORD = await post(
                `api/ord/db/work-trends?filter=${_payload.filter.toLowerCase()}&startDate=${moment().format(
                    'YYYY-MM-DD'
                )}`,
                payloadValues,
                null
            );
        } catch (er) {
            dataORD = [];
            console.log(er);
        }
        try {
            dataMGT = await post(
                `api/mgt/dashboard/work-trends?filter=${_payload.filter.toLowerCase()}&startDate=${moment().format(
                    'YYYY-MM-DD'
                )}`,
                payloadValues,
                null
            );
        } catch (er) {
            dataMGT = [];
            console.log(er);
        }
        try {
            dataBKG = await post(
                `api/bkg/db/work-trends?filter=${_payload.filter.toLowerCase()}&startDate=${moment().format(
                    'YYYY-MM-DD'
                )}`,
                payloadValues,
                null
            );
        } catch (er) {
            dataBKG = [];
            console.log(er);
        }

        const originalArray = [...dataORD, ...dataMGT, ...dataBKG]; //combining all micro services data
        const combinedArray = originalArray.reduce((acc, current) => {
            const existingItem = acc.find((item: any) => item.label === current.label);
            if (existingItem) {
                // Update existing item by summing yAxis and combining activities array
                existingItem.yAxis += current.yAxis;
                const filteredArr: [] = checkDuplicatesActivities(existingItem.activities, current.activities);
                //to combine all the activities and sum the value of duplicates
                existingItem.activities = [...filteredArr];
            } else {
                // Add a new item to the accumulator
                acc.push({ ...current });
            }
            return acc;
        }, []);
        return { data: combinedArray };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
const checkDuplicatesActivities = (array1: [], array2: []) => {
    const combArr = [...array1, ...array2];
    const combinedArray = combArr.reduce((acc: any, current: any) => {
        const existingItem = acc.find((item: any) => item.key === current.key);
        if (existingItem) {
            existingItem.value += current.value;
        } else {
            acc.push({ ...current });
        }
        return acc;
    }, []);
    return combinedArray;
};
export const fetchUsersListDetailsApi = async (payload: any) => {
    try {
        // const listOfUserOrganisations = getOrganisationData();
        // const data = await post(
        //     'api/mgt/dashboard/users',
        //     { ...listOfUserOrganisations, loggedInUserName: payload },
        //     null
        // );
        // return { data };
        let url = '';
        if (payload?.search) {
            url = `/cix/scr/admin/config/user/lst/US_FirstName/US_LastName?filter[filters][1][field]=US_FirstName&filter[filters][1][operator]=contains&filter[filters][1][value]=${payload?.search}`;
        } else {
            url = 'cix/scr/admin/config/user/lst/US_FirstName/US_LastName';
        }
        const data = await get(url, {}, externalBaseUrl);
        const mapped = data?.data?.map((res: any) => ({
            fullName: res?.US_LastName ? res?.US_FirstName + ' ' + res?.US_LastName : res?.US_FirstName,
            userId: res?.US_UserName,
        }));
        return { data: mapped };
        /* const data = [
            {
                fullName: 'Robin Mcbride',
                userId: '2f422fe2-167c-4ff6-9a08-fbf4eae60484',
            },
            {
                fullName: 'Ellena Henry',
                userId: '151a2eca-2f6d-42b4-8930-6a7a0c5adaed9',
            },
            {
                fullName: 'Nevaeh Rowland',
                userId: '151a2eca-2f6d-42b4-8930',
            },
            {
                fullName: 'Sandra Cobb',
                userId: '151a221da-2f6d-42b4-8930-6a7a0cada',
            },
            {
                fullName: 'Todd Ferrell',
                userId: '151a43ca-2f6d-42b4-8930-6a7a0cadae',
            },
            {
                fullName: 'Daniyal Moyer',
                userId: '1fga2eca-2f6d-42b4-8930-6a7a0cadaed',
            },
            {
                fullName: 'User 7',
                userId: '151862eca-2f6d-42b4-8930',
            },
            {
                fullName: 'User 8',
                userId: '151a2eca-8-42b4-8930-6a7a0cada',
            },
            {
                fullName: 'User 9',
                userId: '851a2eca-2f6d-42b4-8930-6a7a0cadae',
            },
            {
                fullName: 'User 10',
                userId: '154a2eca-2f6d-42b4-8930-6a7a0cadaed',
            },
        ]; */
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchActivityDetailsApi = async (requestBody: any) => {
    try {
        requestBody.page = requestBody.currentPage;
        requestBody.limit = requestBody.perPageCount;
        const payload = JSON.parse(JSON.stringify(requestBody));
        delete payload.currentPage;
        delete payload.activityData;
        delete payload.perPageCount;
        let data;
        if (requestBody?.activityData?.service === 'MGT') {
            data = await post('/api/mgt/dashboard/activities-listing', payload, null);
        } else if (requestBody?.activityData?.service === 'ORD') {
            data = await post('/api/ord/db/activities-listing', payload, null);
        } else if (requestBody?.activityData?.service === 'BKG') {
            data = await post('api/bkg/db/activities-listing', payload, null);
        }
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
