/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface ConfirmationGroups {
    confirmationGroupId: string;
    confirmationGroupName: string;
    type: string;
    status: string;
    poLines: number;
    cbm: number;
    weight: number;
    isUpdatedByForwarder: boolean;
    isSubmittedbyForwarder: boolean;
    goodsReadyDate: string;
    destinationPortName: string;
    destinationPort: string;
    coLoader: string;
    coloaderCode: string;
    coloaderId: string;
    lclBuyCost: string;
    lclBuyCurrency: string;
    lclBuyQuoteName: string;
    lclSavedFileName: string;
    lclSavedFileId: string;
    lclSavedFileSizeInKb: string;
    fclBuyCost: string;
    fclBuyCurrency: string;
    fclBuyQuoteName: string;
    fclSavedFileName: string;
    fclSavedFileId: string;
    fclSavedFileSizeInKb: string;
    lclSellCost: string;
    lclSellCurrency: string;
    lclSellQuoteName: string;
    lclSellSavedFileName: string;
    lclSellSavedFileId: string;
    lclSellSavedFileSizeInKb: string;
    fclSellCost: string;
    fclSellCurrency: string;
    fclSellQuoteName: string;
    fclSellSavedFileName: string;
    fclSellSavedFileId: string;
    fclSellSavedFileSizeInKb: string;
    selectedGroup: string;
}

const initialConfirmationGroup = {
    confirmationGroupId: '',
    confirmationGroupName: '',
    type: '',
    status: '',
    poLines: 0,
    cbm: 0,
    weight: 0,
    goodsReadyDate: '',
    destinationPortName: '',
    destinationPort: '',
    coLoader: '',
    coloaderCode: '',
    coloaderId: '',
    lclBuyCost: '',
    lclBuyCurrency: '',
    lclBuyQuoteName: '',
    lclSavedFileName: '',
    lclSavedFileId: '',
    lclSavedFileSizeInKb: '',
    fclBuyCost: '',
    fclBuyCurrency: '',
    fclBuyQuoteName: '',
    fclSavedFileName: '',
    fclSavedFileId: '',
    fclSavedFileSizeInKb: '',
    lclSellCost: '',
    lclSellCurrency: '',
    lclSellQuoteName: '',
    lclSellSavedFileName: '',
    lclSellSavedFileId: '',
    lclSellSavedFileSizeInKb: '',
    fclSellCost: '0',
    fclSellCurrency: '',
    fclSellQuoteName: '',
    fclSellSavedFileName: '',
    fclSellSavedFileId: '',
    fclSellSavedFileSizeInKb: '',
    selectedGroup: '',
    isUpdatedByForwarder: false,
    isSubmittedbyForwarder: false,
};
export interface CoLoader {
    coLoader: string;
    coLoaderCode: string;
    coLoaderId: string;
}

export interface ContainerModePoDataDetails {
    confirmationGroupId: string;
    confirmationGroupName: string;
    type: string;
    poLines: number;
    cbm: number;
    weight: number;
    totalCGQty: number;
    teu: number;
    scheduledDeliverydate: string;
    totalCartons: number;
    goodsReadyDate: string;
    destinationPort: string;
    status: string;
    coLoader: string;
    coLoaderCode: string;
    coLoaderId: string;
    bpForCGO: {
        bpPk: string;
        bpNumber: string;
    }[];
    isEditable: boolean;
}

interface ContainerModePoState {
    isLoading: boolean;
    containerModePo: ContainerModePoDataDetails[];
    confirmationGroup: ConfirmationGroups;
    coLoader: CoLoader[];
    containerModePoSuccess: any;
    containerModePoError: any;
    updateConfirmationGroupSuccess: any;
    updateConfirmationGroupError: any;
    isUpdateConfirmationGroupError: any;
    isError?: boolean;
    rejectionSuccess: boolean;
    isDiscardSuccess: boolean;
    isDiscardError: boolean;
    discardError: any;
}

const initialState: ContainerModePoState = {
    isLoading: true,
    containerModePo: [],
    confirmationGroup: initialConfirmationGroup,
    coLoader: [],
    containerModePoSuccess: undefined,
    containerModePoError: undefined,
    updateConfirmationGroupSuccess: undefined,
    updateConfirmationGroupError: undefined,
    isUpdateConfirmationGroupError: false,
    isError: false,
    rejectionSuccess: false,
    isDiscardSuccess: false,
    isDiscardError: false,
    discardError: {},
};

export const containerModePoSlice = createSlice({
    name: 'containerModePo',
    initialState,
    reducers: {
        fetchContainerModePoList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchContainerModePoListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerModePo = action.payload?.data?.info;
            state.confirmationGroup = { ...initialConfirmationGroup };
        },
        fetchContainerModePoListFailure(state, action) {
            state.isLoading = false;
            // state.isError = true;
            state.containerModePo = action.payload;
        },
        fetchConfirmationGroup: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchConfirmationGroupSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.confirmationGroup = { ...initialConfirmationGroup, ...action.payload?.data?.info };
        },
        fetchConfirmationGroupFailure(state, action) {
            state.isLoading = false;
            // state.isError = true;
            state.confirmationGroup = action.payload;
        },
        fetchCoLoader: (state, _action) => {
            // state.isLoading = true;
            state.isError = false;
        },
        fetchCoLoaderSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.coLoader = action.payload?.data;
        },
        fetchCoLoaderFailure(state, action) {
            // state.isLoading = false;
            // state.isError = true;
            state.coLoader = action.payload?.data?.info;
        },
        saveLcl(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        saveLclSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerModePoSuccess = action.payload;
            state.containerModePoError = {};
        },
        saveLclFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerModePoSuccess = {};
            state.containerModePoError = action.payload;
        },
        updateConfirmationGroup(state, _action) {
            state.isLoading = true;
            state.isUpdateConfirmationGroupError = false;
        },
        updateConfirmationGroupSuccess(state, action) {
            state.isLoading = false;
            state.isUpdateConfirmationGroupError = false;
            state.updateConfirmationGroupSuccess = action.payload;
            state.updateConfirmationGroupError = {};
        },
        updateConfirmationGroupFailure(state, action) {
            state.isLoading = false;
            state.isUpdateConfirmationGroupError = true;
            state.updateConfirmationGroupSuccess = {};
            state.updateConfirmationGroupError = action.payload;
        },
        removeConfirmationGroup(state, _action) {
            state.isLoading = true;
            state.isUpdateConfirmationGroupError = false;
        },
        removeConfirmationGroupSuccess(state, action) {
            state.isLoading = false;
            state.isUpdateConfirmationGroupError = false;
            state.updateConfirmationGroupSuccess = action.payload;
            state.updateConfirmationGroupError = {};
        },
        removeConfirmationGroupFailure(state, action) {
            state.isLoading = false;
            state.isUpdateConfirmationGroupError = true;
            state.updateConfirmationGroupSuccess = {};
            state.updateConfirmationGroupError = action.payload;
        },
        releaseConfirmationGroup(state, _action) {
            state.isLoading = true;
            state.isUpdateConfirmationGroupError = false;
        },
        releaseConfirmationGroupSuccess(state, action) {
            state.isLoading = false;
            state.isUpdateConfirmationGroupError = false;
            state.updateConfirmationGroupSuccess = action.payload;
            state.updateConfirmationGroupError = {};
        },
        releaseConfirmationGroupFailure(state, action) {
            state.isLoading = false;
            state.isUpdateConfirmationGroupError = true;
            state.updateConfirmationGroupSuccess = {};
            state.updateConfirmationGroupError = action.payload;
        },
        saveFcl(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        saveFclSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerModePoSuccess = action.payload;
            state.containerModePoError = {};
        },
        saveFclFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerModePoSuccess = {};
            state.containerModePoError = action.payload;
        },
        submitToClient(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        submitToClientSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerModePoSuccess = action.payload;
            state.containerModePoError = {};
        },
        submitToClientFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerModePoSuccess = {};
            state.containerModePoError = action.payload;
        },
        approveGroup(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        approveGroupSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerModePoSuccess = action.payload;
            state.containerModePoError = {};
        },
        approveGroupFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerModePoSuccess = {};
            state.containerModePoError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.containerModePoError = undefined;
            state.containerModePoSuccess = undefined;
            state.isError = false;
            state.updateConfirmationGroupError = undefined;
            state.updateConfirmationGroupSuccess = undefined;
            state.isUpdateConfirmationGroupError = false;
        },
        rejectContainerFunc: (_state, _action) => {
            // console.log(action);
        },
        rejectedSuccess: (state, action) => {
            state.rejectionSuccess = action.payload;
        },
        rejectAssignToAgent: (_state, _action) => {
            // console.log(action);
        },
        rejectViewedStatus: (_state, _action) => {
            // console.log(action);
        },
        discardConfirmationGroup: (_state, _action) => {
            // console.log(action);
        },
        discardConfirmationGroupSuccess: (state, _action) => {
            state.isDiscardSuccess = true;
        },
        discardConfirmationGroupError: (state, action) => {
            state.isDiscardError = true;
            state.discardError = action.payload;
        },
        resetDiscardFlags: (state, _action) => {
            state.isDiscardError = false;
            state.isDiscardSuccess = false;
            state.discardError = {};
        },
    },
});

export const {
    fetchContainerModePoList,
    fetchContainerModePoListSuccess,
    fetchContainerModePoListFailure,
    fetchConfirmationGroup,
    fetchConfirmationGroupSuccess,
    fetchConfirmationGroupFailure,
    fetchCoLoader,
    fetchCoLoaderSuccess,
    fetchCoLoaderFailure,
    saveLcl,
    saveLclSuccess,
    saveLclFailure,
    saveFcl,
    saveFclSuccess,
    saveFclFailure,
    updateConfirmationGroup,
    updateConfirmationGroupSuccess,
    updateConfirmationGroupFailure,
    removeConfirmationGroup,
    removeConfirmationGroupSuccess,
    removeConfirmationGroupFailure,
    releaseConfirmationGroup,
    releaseConfirmationGroupSuccess,
    releaseConfirmationGroupFailure,
    submitToClient,
    submitToClientSuccess,
    submitToClientFailure,
    approveGroup,
    approveGroupSuccess,
    approveGroupFailure,
    isLoadingFn,
    clearFailure,
    rejectedSuccess,
    rejectContainerFunc,
    rejectViewedStatus,
    rejectAssignToAgent,
    discardConfirmationGroup,
    discardConfirmationGroupSuccess,
    discardConfirmationGroupError,
    resetDiscardFlags,
} = containerModePoSlice.actions;

export const selectContainerModePoLoading = (state: RootState) => state.containerModePoData.isLoading;
export const selectContainerModePo = (state: RootState) => state.containerModePoData.containerModePo;
export const selectContainerModePoSuccess = (state: RootState) => state.containerModePoData.containerModePoSuccess;
export const selectContainerModePoError = (state: RootState) => state.containerModePoData.containerModePoError;
export const selectContainerModePoIsError = (state: RootState) => state.containerModePoData.isError;
export const selectUpdateConfirmationGroupSuccess = (state: RootState) =>
    state.containerModePoData.updateConfirmationGroupSuccess;
export const selectUpdateConfirmationGroupError = (state: RootState) =>
    state.containerModePoData.updateConfirmationGroupError;
export const selectUpdateConfirmationGroupIsError = (state: RootState) =>
    state.containerModePoData.isUpdateConfirmationGroupError;
export const selectConfirmationGroup = (state: RootState) => state.containerModePoData.confirmationGroup;
export const confirmationGroupRejectionStat = (state: RootState) => state.containerModePoData.rejectionSuccess;
export const selectCoLoader = (state: RootState) => state.containerModePoData.coLoader;

export default containerModePoSlice.reducer;
