import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchContainerModePoList,
    fetchContainerModePoListFailure,
    fetchContainerModePoListSuccess,
    fetchConfirmationGroup,
    fetchConfirmationGroupFailure,
    fetchConfirmationGroupSuccess,
    fetchCoLoader,
    fetchCoLoaderFailure,
    fetchCoLoaderSuccess,
    saveLcl,
    saveLclSuccess,
    saveLclFailure,
    saveFclSuccess,
    saveFclFailure,
    saveFcl,
    updateConfirmationGroupSuccess,
    updateConfirmationGroupFailure,
    updateConfirmationGroup,
    removeConfirmationGroupSuccess,
    removeConfirmationGroupFailure,
    removeConfirmationGroup,
    releaseConfirmationGroupSuccess,
    releaseConfirmationGroupFailure,
    releaseConfirmationGroup,
    approveGroup,
    approveGroupSuccess,
    approveGroupFailure,
    submitToClient,
    submitToClientSuccess,
    submitToClientFailure,
    rejectContainerFunc,
    rejectedSuccess,
    rejectViewedStatus,
    rejectAssignToAgent,
    discardConfirmationGroup,
    discardConfirmationGroupSuccess,
    discardConfirmationGroupError,
} from '../../reducers/purchaseOrder/containerModePoReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchContainerModePoListDetailsApi,
    fetchConfirmationGroupDetailsApi,
    fetchCoLoaderDetailsApi,
    saveLclDetailsApi,
    saveFclDetailsApi,
    updateConfirmationGroupDetailsApi,
    removeConfirmationGroupDetailsApi,
    releaseConfirmationGroupDetailsApi,
    submitToClientApi,
    approveGroupApi,
    discardConfirmationGroupApi,
} from '../../../services/apis/purchaseOrder/containerModePoApi';
import {
    rejectAssignToAgentapi,
    rejectContainerapi,
    updateViewedStatusapi,
} from 'src/services/apis/purchaseOrder/cofirmationPoApi';

// eslint-disable-next-line
function* getContainerModePoDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchContainerModePoListDetailsApi, payload);
        yield put({ type: fetchContainerModePoListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContainerModePoListFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getConfirmationGroup({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchConfirmationGroupDetailsApi, payload);
        yield put({ type: fetchConfirmationGroupSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchConfirmationGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getCoLoader({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchCoLoaderDetailsApi, payload);
        yield put({ type: fetchCoLoaderSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchCoLoaderFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* saveLclDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(saveLclDetailsApi, payload);
        yield put({ type: saveLclSuccess, payload: response });
    } catch (error) {
        yield put({ type: saveLclFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* saveFclDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(saveFclDetailsApi, payload);
        yield put({ type: saveFclSuccess, payload: response });
    } catch (error) {
        yield put({ type: saveFclFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateConfirmationGroupData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateConfirmationGroupDetailsApi, payload);
        yield put({ type: updateConfirmationGroupSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateConfirmationGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* removeConfirmationGroupData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(removeConfirmationGroupDetailsApi, payload);
        yield put({ type: removeConfirmationGroupSuccess, payload: response });
    } catch (error) {
        yield put({ type: removeConfirmationGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* releaseConfirmationGroupData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(releaseConfirmationGroupDetailsApi, payload);
        yield put({ type: releaseConfirmationGroupSuccess, payload: response });
    } catch (error) {
        yield put({ type: releaseConfirmationGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* submitToClientData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(submitToClientApi, payload);
        yield put({ type: submitToClientSuccess, payload: response });
    } catch (error) {
        yield put({ type: submitToClientFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* approveGroupData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(approveGroupApi, payload);
        yield put({ type: approveGroupSuccess, payload: response });
    } catch (error) {
        yield put({ type: approveGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* containerRejection({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield call(rejectContainerapi, payload);
        yield put({ type: rejectedSuccess, payload: true });
    } catch (error) {
        //yield put({ type: approveGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* containerRejectionAssignAgent({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield call(rejectAssignToAgentapi, payload);
        yield put({ type: rejectedSuccess, payload: true });
    } catch (error) {
        //yield put({ type: approveGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* containerRejectionViewedBy({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield call(updateViewedStatusapi, payload);
        yield put({ type: rejectedSuccess, payload: true });
    } catch (error) {
        //yield put({ type: approveGroupFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* discardCg({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(discardConfirmationGroupApi, payload);
        yield put({ type: discardConfirmationGroupSuccess, payload: response });
    } catch (error) {
        yield put({ type: discardConfirmationGroupError, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* ContainerModePoSaga() {
    yield takeEvery(fetchContainerModePoList, getContainerModePoDetails);
    yield takeEvery(fetchConfirmationGroup, getConfirmationGroup);
    yield takeEvery(fetchCoLoader, getCoLoader);
    yield takeEvery(saveLcl, saveLclDetailsData);
    yield takeEvery(saveFcl, saveFclDetailsData);
    yield takeEvery(updateConfirmationGroup, updateConfirmationGroupData);
    yield takeEvery(removeConfirmationGroup, removeConfirmationGroupData);
    yield takeEvery(releaseConfirmationGroup, releaseConfirmationGroupData);
    yield takeEvery(submitToClient, submitToClientData);
    yield takeEvery(approveGroup, approveGroupData);
    yield takeEvery(rejectContainerFunc, containerRejection);
    yield takeEvery(rejectViewedStatus, containerRejectionViewedBy);
    yield takeEvery(rejectAssignToAgent, containerRejectionAssignAgent);
    yield takeEvery(discardConfirmationGroup, discardCg);
}

export default ContainerModePoSaga;
