import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchOverrideShipments } from '../../reducers/bookingProposal/overrideAdditionalReducer';

import { getOverrideShipmentDetails } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';

function* getOverrideShipmentInfo({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getOverrideShipmentDetails, payload);
        yield put({ type: fetchOverrideShipments, payload: response });
    } catch (error) {}
}

function* overrideAdditionalSaga() {
    yield takeEvery('overrideShipments/fetchOverrideShipments', getOverrideShipmentInfo);
}

export default overrideAdditionalSaga;
