import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import PoList from './poList';
import { selectAllPo, addToContainer } from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
import './container-details.scss';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
interface PoDetailsProps {
    handle?: any;
    containerDetailsId?: any;
    isLoading: boolean;
    setLoader: any;
    setPoDetails: any;
    isHeaderOnlyBp?: boolean;
    poDetails: boolean;
    pillClicked?: boolean;
    setPillClicked?: any;
}

const PoDetailsModal: React.FC<PoDetailsProps> = ({
    handle,
    containerDetailsId,
    isLoading,
    setLoader,
    setPoDetails,
    isHeaderOnlyBp,
    poDetails,
    pillClicked,
    setPillClicked,
}) => {
    const { id } = useParams();
    const { userName } = useUserProfile();
    const [selectedItemsAdded, setSelectedItemsAdded] = useState<any[]>([]);
    const [allPoData, setAllPoData] = useState<any>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [showConditionStatus, setShowConditionStatus] = useState(true);
    const [search, setSearch] = useState('');
    const dispatch = useAppDispatch();
    const allPo = useAppSelector(selectAllPo);

    useEffect(() => {
        if (allPo) {
            const copy = JSON.parse(JSON.stringify(allPo.bookingProposalPoList));
            for (let i = 0; i < copy.length; i++) {
                for (let j = 0; j < copy[i].bookingProposalSubPOLineList.length; j++) {
                    copy[i].bookingProposalSubPOLineList[j]['addedQty'] =
                        typeof copy[i].bookingProposalSubPOLineList[j].remainingQty === null
                            ? copy[i].bookingProposalSubPOLineList[j].qty
                            : copy[i].bookingProposalSubPOLineList[j].addedQty;
                }
            }
            setAllPoData(copy);
        }
    }, [allPo]);

    const getCount = () => {
        let total = 0;
        selectedItemsAdded.forEach((element: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            element.subPolineIds.forEach((element2: any) => {
                total++;
            });
        });
        return total;
    };

    const addPoToContainer = () => {
        setLoader(true);
        const bsId: any[] = [];
        for (let i = 0; i < selectedItemsAdded.length; i++) {
            const poIndex = allPoData.findIndex(
                (res: any) => res.poId === selectedItemsAdded[i].id && res.boId === selectedItemsAdded[i].boId
            );
            if (poIndex >= 0) {
                for (let j = 0; j < selectedItemsAdded[i].subPolineIds.length; j++) {
                    const subPoIndex = allPoData[poIndex].bookingProposalSubPOLineList.findIndex(
                        (res: any) => res.bsId === selectedItemsAdded[i].subPolineIds[j]
                    );
                    if (subPoIndex >= 0) {
                        bsId.push({
                            bsId: allPoData[poIndex].bookingProposalSubPOLineList[subPoIndex].bsId,
                            remainingQty: allPoData[poIndex].bookingProposalSubPOLineList[subPoIndex].remainingQty,
                            allocatedQty: allPoData[poIndex].bookingProposalSubPOLineList[subPoIndex].addedQty,
                            cbm: allPoData[poIndex].bookingProposalSubPOLineList[subPoIndex].cbm,
                            weight: allPoData[poIndex].bookingProposalSubPOLineList[subPoIndex].weight,
                        });
                    }
                }
            }
        }
        dispatch(addToContainer({ containerDetailsId, userName, skusWithQty: bsId, bpId: id }));
        setSelectedItemsAdded([]);
        propagateBcnData(id);
    };

    const searchFn = () => {
        let bookingProposalSubPOLineList;
        let searchResult: any = [];
        if (search.trim().length > 2) {
            searchResult = allPoData?.filter(
                (el: any) => el.poNumber && el.poNumber.toLocaleLowerCase().includes(search.toLocaleLowerCase())
            );
            if (!searchResult?.length) {
                for (let index = 0; index < allPoData.length; index++) {
                    bookingProposalSubPOLineList = allPoData[index]?.bookingProposalSubPOLineList?.filter(
                        (e: any) => e.sku && e.sku.toLocaleLowerCase().includes(search.toLocaleLowerCase())
                    );
                    if (bookingProposalSubPOLineList?.length) {
                        const newData = JSON.parse(JSON.stringify(allPoData[index]));
                        newData['bookingProposalSubPOLineList'] = bookingProposalSubPOLineList;
                        searchResult = [...searchResult, newData];
                    }
                }
            }

            return searchResult;
            // setAllPoData(searchResult);
        } else {
            return allPoData;
            // setAllPoData(allPo.bookingProposalPoList);
        }
    };

    const onSearch = searchFn();

    return (
        <div className="app-modal">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder po-details-content-holder large-popup stretchable-vw">
                <div className="modal-header">
                    <div className="title">Add PO Lines</div>
                    <button
                        type="reset"
                        form="basic-details"
                        className="app-btn modal-close-btn"
                        onClick={() => handle(false)}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content po-modal-main-content">
                        <div className="search-field-purchase-grid">
                            <div className="search-entry-holder">
                                <input
                                    placeholder="Search by PO Number, SKU"
                                    type="text"
                                    className="search-input input-text search-field-container"
                                    value={search}
                                    onChange={(e) => setSearch(e.target.value)}
                                ></input>
                                <div
                                    className={`search-toolip ${
                                        search?.length > 0 && search?.length < 3 ? 'tooltip-show' : ''
                                    }`}
                                >
                                    Enter atleast 3 characters to search
                                </div>
                                <svg className="svg-icon search-icon">
                                    <use href="#searchIcon"></use>
                                </svg>
                                <button
                                    onClick={() => {
                                        setSearch('');
                                        // setAllPoData(allPo.bookingProposalPoList);
                                    }}
                                    className="app-btn text-close-btn"
                                >
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <PoList
                            bpID={id}
                            addPoList={id ? allPoData : []}
                            originalData={allPo.bookingProposalPoList ? allPo.bookingProposalPoList : []}
                            selectedItemsAdded={selectedItemsAdded}
                            setSelectedItemsAdded={setSelectedItemsAdded}
                            showConditionStatus={showConditionStatus}
                            containerDetailsId={containerDetailsId}
                            setPoDetails={setPoDetails}
                            listingPage={false}
                            isHeaderOnlyBp={isHeaderOnlyBp}
                            setAllPoData={setAllPoData}
                            poDetails={poDetails}
                            pillClicked={pillClicked}
                            setPillClicked={setPillClicked}
                            onSearch={onSearch}
                        />
                    </div>
                </div>
                <div className="modal-footer -modal-footer-with-pill">
                    {selectedItemsAdded.length > 0 && (
                        <span className="btn-pills info-pill">
                            <span className="pill-text">{`${getCount()} Selected`}</span>
                            <button
                                className="pill-action"
                                onClick={() => {
                                    setSelectedItemsAdded([]);
                                    setPillClicked(true);
                                }}
                            >
                                <svg className="svg-icon close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </span>
                    )}
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="basic-details"
                        onClick={() => {
                            addPoToContainer();
                        }}
                        disabled={selectedItemsAdded.length ? false : true}
                    >
                        <span className="button-text vendor-button-text">Add to Container</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
//better to use bookingaddedgrid component here
const PoSampleCode = () => {
    return (
        <div className="grid-section grid-added-booking product-details-wrapper">
            <div className="grid-holder">
                <div className="grid-container">
                    {/* <div className="grid-no-content ">
                        <svg className="svg-icon grid-no-content-icon ">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">No PO lines added yet</p>
                        <a style={{ cursor: 'pointer' }} className="add-content-link">
                            Show Available POs
                        </a>
                    </div> */}
                    <div className="grid-scroll-content">
                        <table className="grid-table">
                            <thead>
                                <tr className="grid-sticky-row">
                                    <th className="grid-cell-header col-number grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data">
                                            <span className="header-title">PO NUMBER</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data centre-align">
                                            <label className="app-check-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <div className="checkmark">
                                                    <svg className="svg-icon tick-icon">
                                                        <use xlinkHref="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                            </label>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-line">
                                        <div className="grid-cell-data">
                                            <span className="header-title">PO LINE No.</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">SKU</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESCRIPTION</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-qty">
                                        <div className="grid-cell-data">
                                            <span className="header-title">QTY</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-grd">
                                        <div className="grid-cell-data">
                                            <span className="header-title">GOODS READY DATE</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-cbm">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CBM</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-weight">
                                        <div className="grid-cell-data">
                                            <span className="header-title">WEIGHT (KG)</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-code">
                                        <div className="grid-cell-data">
                                            <span className="header-title">HS CODE</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-vendor">
                                        <div className="grid-cell-data">
                                            <span className="header-title">VENDOR</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="grouped-splited-row">
                                <tr>
                                    <td className="grid-cell col-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                        <div className="grid-cell-data">
                                            <div className="checkbox-data-wrapper">
                                                <label className="app-check-wrapper">
                                                    <input type="checkbox" className="checkbox-input" />
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use xlinkHref="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                </label>
                                                <a href="#" className="booking-proposal-link">
                                                    PO123ASD
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data centre-align">
                                            <label className="app-check-wrapper">
                                                <input type="checkbox" className="checkbox-input" />
                                                <div className="checkmark">
                                                    <svg className="svg-icon tick-icon">
                                                        <use xlinkHref="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                            </label>
                                        </div>
                                    </td>
                                    <td className="grid-cell col-po-line">
                                        <div className="grid-cell-data">1</div>
                                    </td>
                                    <td className="grid-cell col-po-sku">
                                        <div className="grid-cell-data">
                                            <a>PPOPP</a>
                                        </div>
                                    </td>
                                    <td className="grid-cell col-po-desc">
                                        <div className="grid-cell-data">Lorem Ipsum</div>
                                    </td>
                                    <td className="grid-cell col-po-qty">
                                        <div className="grid-cell-data">23</div>
                                    </td>
                                    <td className="grid-cell col-po-grd">
                                        <div className="grid-cell-data">30-08-2022</div>
                                    </td>
                                    <td className="grid-cell col-po-cbm">
                                        <div className="grid-cell-data">207</div>
                                    </td>
                                    <td className="grid-cell col-po-weight">
                                        <div className="grid-cell-data">161</div>
                                    </td>
                                    <td className="grid-cell col-po-code">
                                        <div className="grid-cell-data"></div>
                                    </td>
                                    <td className="grid-cell col-po-added-vendor">
                                        <div className="grid-cell-data">vendor1</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
export { PoDetailsModal, PoSampleCode };
