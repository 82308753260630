import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getDateDifference } from 'src/utils';
import './flight-schelude.scss';
import QuoteDetailModal from './QuoteDetailModal';
import RemoveScheduleModal from './RemoveScheduleModal';
import { fetchCurrency } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { useAppSelector } from 'src/hooks';
import {
    selectFlightScheduleError,
    selectFlightScheduleIsError,
    selectFlightScheduleLoading,
    selectFlightScheduleSuccess,
    clearFailure,
    updateSellQuote,
} from 'src/store/reducers/bookingProposalAir/flightScheduleReducer';
import { error, Position, success } from 'src/utils/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { getSelectedSchedules } from 'src/store/reducers/bookingProposalAir/addSchedule';
import { downloadFlightScheduleSell } from 'src/services/apis/bookingProposalAir/flightScheduleApi';
import { useUserProfile } from 'src/hooks/useUserProfile';
import ShowForPermissions from 'src/ShowForPermissions';
import RemoveSchedule from '../SelectedSailingSchelude/RemoveSchedule';
import { getQuoteUoms } from 'src/services/apis/quoteAPi';
// import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
interface Props {
    data: any;
    setData: any;
    setviewModal: any;
    headerData: any;
}
const Index: React.FC<Props> = ({ data, setData, setviewModal, headerData }: Props) => {
    const dispatch = useDispatch();
    const { profileType } = useUserProfile();
    const navigate = useNavigate();
    const { id } = useParams();
    const [loader, setLoader] = useState(false);
    const isError = useAppSelector(selectFlightScheduleIsError);
    const flightScheduleConfirmationSuccess = useAppSelector(selectFlightScheduleSuccess);
    const flightScheduleConfirmationError = useAppSelector(selectFlightScheduleError);
    const isLoading = useAppSelector(selectFlightScheduleLoading);
    const [showQuoteDetail, setShowQuoteDetail] = useState(false);
    const [showRemoveDetail, setShowRemoveDetail] = useState(false);
    const [singleData, setSingleData] = useState<any>({});
    const [uomData, setUomData] = useState([]);

    const getQuoteUomsData = () => {
        getQuoteUoms()
            .then((res: any) => {
                setUomData(res);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };
    useEffect(() => {
        dispatch(fetchCurrency({}));
        getQuoteUomsData();
    }, []);

    const [selectedToDeleteId, setSelectedToDeleteId] = useState<string | null>(null);
    function handleCloseDeleteModal(updateList = false) {
        if (updateList) {
            dispatch(getSelectedSchedules(id));
        }
        setSelectedToDeleteId(null);
    }

    useEffect(() => {
        if (isError) {
            error(flightScheduleConfirmationError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            setLoader(false);
        } else if (
            flightScheduleConfirmationSuccess?.status === 200 ||
            flightScheduleConfirmationSuccess?.status === 201
        ) {
            if (flightScheduleConfirmationSuccess?.data?.mode === 'addSellQuote') {
                success('Quote details added successfully', Position.TOP_RIGHT);
            } else if (flightScheduleConfirmationSuccess?.data?.mode === 'editSellQuote') {
                success('Quote details updated successfully', Position.TOP_RIGHT);
            } else if (flightScheduleConfirmationSuccess?.data?.mode === 'removeSellQuote') {
                success('Schedule removed successfully', Position.TOP_RIGHT);
            } else if (flightScheduleConfirmationSuccess?.data?.mode === 'undoSellQuote') {
                success('Schedule restored successfully', Position.TOP_RIGHT);
            } else if (flightScheduleConfirmationSuccess?.data?.mode === 'selectSchedule') {
                success('Selected schedule has been approved', Position.TOP_RIGHT);
            }
            setLoader(false);
            setShowQuoteDetail(false);
            dispatch(clearFailure([]));
            dispatch(getSelectedSchedules(id));
        }
    }, [isError, flightScheduleConfirmationSuccess, flightScheduleConfirmationError]);

    const getActiveDisabledStatus = (isSelected: any) => {
        if (
            headerData.bpStatus === 'Ready_for_Booking' ||
            headerData.bpStatus === 'Booked' ||
            headerData.bpStatus === 'Draft_Booking'
        ) {
            // if (isSelected) {
            return 'active';
            // } else {
            //     return 'disabled';
            // }
        }
    };

    const combineDateTime = (date: string, time: string) => {
        return moment(date).format('YYYY-MM-DD') + ' ' + time;
    };

    return (
        <>
            <div className="selection-schelude-wrapper air-flight-schelude-forwarder-view">
                <div className="selection-schelude-header-wrapper">
                    <div className="selection-heading-holder">
                        <div>
                            <h2 className="selection-heading">Flight Schedules</h2>
                            <p className="selection-content">Add Sell Quote for the schedules for client approval</p>
                        </div>
                    </div>
                    {/* <ShowForPermissions type="bp" permission="create">
                        {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                            headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action' ||
                            headerData.bpStatus === 'Draft_Booking_Proposal') &&
                            profileType === 'forwarder' && (
                                <button
                                    className="app-btn export-btn icon-button  app-btn-secondary"
                                    onClick={() => {
                                        navigate(`/air_booking_list/${id}/flight_schedule`, {
                                            state: { bpNumber: headerData?.bpNumber },
                                        });
                                    }}
                                    title="Add Schedule"
                                >
                                    <svg className="svg-icon add-btn-icon">
                                        <use xlinkHref="#plusIcon">
                                            <title>Add</title>
                                        </use>
                                    </svg>
                                    <span className="button-text">Add Schedule</span>
                                </button>
                            )}
                    </ShowForPermissions> */}
                </div>
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="view-list-grid air-flight-grid">
                    {data?.map((res: any, index: number) => {
                        const startDateTime = combineDateTime(res?.originEtdDate, res?.originEtdTime);
                        const endDateTime = combineDateTime(res?.destEtaDate, res?.destEtaTime);
                        const dateDifference = getDateDifference(startDateTime, endDateTime);
                        return (
                            <div key={index} className="row-wrapper">
                                {/* active and disable are the classes */}
                                <div className={`list-grid ${getActiveDisabledStatus(res?.isSelected)}`}>
                                    <div className="grid-child-item col-name">
                                        <div className="grid-child-data list-view-items">
                                            <div className="ship-logo">
                                                <svg className="svg-icon aeroplane-icon">
                                                    <use href="#aeroplaneIcon">
                                                        <title>Aeroplane</title>
                                                    </use>
                                                </svg>
                                            </div>
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-heading">{res?.carrier}</span>
                                                </div>
                                                <div className="content-detail">
                                                    {res?.flightNo}
                                                    {res?.bookingProposalAirSchedule?.length > 0 && (
                                                        <>
                                                            ,<span> +{res?.bookingProposalAirSchedule?.length}</span>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-destination">
                                        <div className="grid-child-data">
                                            <div className="des-progress-bar">
                                                <div className="destination begin-journey">
                                                    <span className="point1">ETD</span>
                                                    {/*<div className="date-time">
                                                        {moment
                                                            .utc(
                                                                moment(res?.originEtdDate).format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    res?.originEtdTime
                                                            )
                                                            .local()
                                                            .format('DD-MM-YYYY')}
                                                        ,
                                                        <span className="time-field">
                                                            {moment
                                                                .utc(
                                                                    moment(res?.originEtdDate).format('YYYY-MM-DD') +
                                                                        ' ' +
                                                                        res?.originEtdTime,
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                )
                                                                .local()
                                                                .format('HH:mm')}
                                                        </span>
                                                    </div>*/}
                                                    <div className="date-time">
                                                        {moment(
                                                            moment(res?.originEtdDate).format('YYYY-MM-DD') +
                                                                ' ' +
                                                                res?.originEtdTime
                                                        ).format('DD-MM-YYYY')}
                                                        ,
                                                        <span className="time-field">
                                                            {moment(
                                                                moment(res?.originEtdDate).format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    res?.originEtdTime,
                                                                'YYYY-MM-DD HH:mm:ss'
                                                            ).format('HH:mm')}
                                                        </span>
                                                    </div>
                                                    <span className="point2">{res?.originAirPortName}</span>
                                                </div>
                                                <div className="destination center-journey">
                                                    {res?.bookingProposalAirSchedule?.length === 1 ? (
                                                        <span className="center-point">
                                                            {res?.bookingProposalAirSchedule?.length} Stop
                                                        </span>
                                                    ) : (
                                                        <span className="center-point">
                                                            {res?.bookingProposalAirSchedule?.length} Stops
                                                        </span>
                                                    )}
                                                    <div className="point1">
                                                        {dateDifference?.days > 0 ? `${dateDifference?.days}d` : ''}
                                                        {dateDifference?.hour > 0 ? (
                                                            <span className="time-field">
                                                                {` ${dateDifference?.hour}h`}
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                        {dateDifference?.minute > 0 ? (
                                                            <span className="time-field">
                                                                {` ${dateDifference?.minute}m`}
                                                            </span>
                                                        ) : (
                                                            ''
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="destination end-journey">
                                                    <span className="point1">ETA</span>
                                                    {/*<div className="date-time">
                                                        {moment
                                                            .utc(
                                                                moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    res?.destEtaTime
                                                            )
                                                            .local()
                                                            .format('DD-MM-YYYY')}
                                                        ,
                                                        <span className="time-field">
                                                            {moment
                                                                .utc(
                                                                    moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                        ' ' +
                                                                        res?.destEtaTime,
                                                                    'YYYY-MM-DD HH:mm:ss'
                                                                )
                                                                .local()
                                                                .format('HH:mm')}
                                                        </span>
                                                    </div>*/}
                                                    <div className="date-time">
                                                        {moment(
                                                            moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                ' ' +
                                                                res?.destEtaTime
                                                        ).format('DD-MM-YYYY')}
                                                        ,
                                                        <span className="time-field">
                                                            {moment(
                                                                moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    res?.destEtaTime,
                                                                'YYYY-MM-DD HH:mm:ss'
                                                            ).format('HH:mm')}
                                                        </span>
                                                    </div>
                                                    <span className="point2">{res?.destinationPort}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-direction">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        Payment Terms<span className="mandatory-field-text">*</span>
                                                    </span>
                                                </div>
                                                <div className="content-detail">
                                                    {res?.paymentTermCode === null ? (
                                                        <span
                                                            style={{ fontSize: '16px' }}
                                                            className="sub-content sell-cost-color"
                                                        >
                                                            --
                                                        </span>
                                                    ) : (
                                                        <span className="sub-content">{res?.paymentTermCode}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-direction">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        Sell Cost
                                                        {headerData?.bpStatus !== 'Rejected_by_Forwarder' &&
                                                            headerData?.bpStatus !==
                                                                'Rejected_by_Client_Agent_to_Action' &&
                                                            headerData?.bpStatus !== 'Draft_Booking_Proposal' && (
                                                                <span className="mandatory-field-text">*</span>
                                                            )}
                                                    </span>
                                                </div>
                                                <div className="content-detail">
                                                    {res?.sellCost === null ? (
                                                        <span
                                                            style={{ fontSize: '16px' }}
                                                            className="sub-content sell-cost-color"
                                                        >
                                                            --
                                                        </span>
                                                    ) : (
                                                        <span className="sub-content">
                                                            {res?.sellCost} {res?.sellCurrency}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-direction">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        UOM
                                                        {headerData?.bpStatus !== 'Rejected_by_Forwarder' &&
                                                            headerData?.bpStatus !==
                                                                'Rejected_by_Client_Agent_to_Action' &&
                                                            headerData?.bpStatus !== 'Draft_Booking_Proposal' && (
                                                                <span className="mandatory-field-text">*</span>
                                                            )}
                                                    </span>
                                                </div>
                                                <div className="content-detail">
                                                    {res?.sellUomCode === null ? (
                                                        <span
                                                            style={{ fontSize: '16px' }}
                                                            className="sub-content sell-cost-color"
                                                        >
                                                            --
                                                        </span>
                                                    ) : (
                                                        <span className="sub-content">{res?.sellUomCode}</span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-quote">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        Sell Quote
                                                        {headerData?.bpStatus !== 'Rejected_by_Forwarder' &&
                                                            headerData?.bpStatus !==
                                                                'Rejected_by_Client_Agent_to_Action' &&
                                                            headerData?.bpStatus !== 'Draft_Booking_Proposal' && (
                                                                <span className="mandatory-field-text">*</span>
                                                            )}
                                                    </span>
                                                </div>
                                                {res?.sellQuoteName === null ? (
                                                    <span className="sub-content sell-quote-color">--</span>
                                                ) : (
                                                    <div className="content-detail">
                                                        <a
                                                            onClick={() =>
                                                                downloadFlightScheduleSell(
                                                                    res?.sellSavedFileId,
                                                                    'SELL',
                                                                    res?.baId,
                                                                    res?.sellQuoteName
                                                                )
                                                            }
                                                            className="buy-link"
                                                        >
                                                            {res?.sellQuoteName}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {headerData?.bpStatus === 'Draft_Booking_Proposal' ||
                                    (headerData?.bpStatus === 'Rejected_by_Forwarder' &&
                                        headerData?.isForwarderSubmit) ||
                                    (headerData?.bpStatus === 'Rejected_by_Client_Forwarder_to_Action' &&
                                        headerData?.isForwarderSubmit) ? (
                                        <div
                                            className={`grid-child-item col-action ${getActiveDisabledStatus(
                                                res?.isSelected
                                            )}`}
                                            style={{ textAlign: 'center', alignItems: 'center' }}
                                        >
                                            <ShowForPermissions type="bp" permission="create">
                                                {res?.isRemoved || res?.isRemovedByAgent ? (
                                                    <>
                                                        <div className="item-count-status-holder">
                                                            <div className="grid-item-count-status status-ui error-status">
                                                                <span className="status-text">
                                                                    {res?.isRemoved
                                                                        ? 'Removed By Forwarder'
                                                                        : 'Removed'}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                            headerData.bpStatus ===
                                                                'Rejected_by_Client_Agent_to_Action') &&
                                                            res?.isRemovedByAgent && (
                                                                <a
                                                                    className="more-link app-btn-secondary app-btn action-btn"
                                                                    onClick={() => {
                                                                        dispatch(
                                                                            updateSellQuote({
                                                                                airFreightId: res?.baId,
                                                                                type: 'undo',
                                                                                profile: profileType,
                                                                                mode: 'undoBuyQuote',
                                                                            })
                                                                        );
                                                                    }}
                                                                    title="Undo"
                                                                >
                                                                    <svg className="svg-icon reset-icon">
                                                                        <use xlinkHref="#resetIcon">
                                                                            <title>Reset</title>
                                                                        </use>
                                                                    </svg>
                                                                </a>
                                                            )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {(headerData?.bpStatus === 'Rejected_by_Forwarder' ||
                                                            headerData?.bpStatus ===
                                                                'Rejected_by_Client_Agent_to_Action' ||
                                                            headerData?.bpStatus ===
                                                                'Rejected_by_Client_Forwarder_to_Action' ||
                                                            headerData?.bpStatus === 'Draft_Booking_Proposal') && (
                                                            <>
                                                                <a
                                                                    className="more-link app-btn-secondary app-btn action-btn"
                                                                    onClick={() =>
                                                                        navigate(
                                                                            `/air_booking_list/${id}/flight_schedule`,
                                                                            {
                                                                                state: {
                                                                                    data: res,
                                                                                    edit: true,
                                                                                    bpNumber: headerData?.bpNumber,
                                                                                },
                                                                            }
                                                                        )
                                                                    }
                                                                    title="Edit"
                                                                >
                                                                    <svg className="svg-icon save-icon">
                                                                        <use xlinkHref="#editIcon">
                                                                            <title>Edit</title>
                                                                        </use>
                                                                    </svg>
                                                                </a>
                                                                {/* <a
                                                                    className="more-link app-btn-secondary app-btn action-btn"
                                                                    href="javascript:void(0)"
                                                                    title="Remove"
                                                                    onClick={() => setSelectedToDeleteId(res?.baId)}
                                                                >
                                                                    <svg className="svg-icon eye-icon ">
                                                                        <use xlinkHref="#closecircleIcon">
                                                                            <title>Remove</title>
                                                                        </use>
                                                                    </svg>
                                                                </a> */}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </ShowForPermissions>
                                            {(headerData.bpStatus === 'Ready_for_Booking' ||
                                                headerData.bpStatus === 'Booked' ||
                                                headerData.bpStatus === 'Draft_Booking') &&
                                                res?.isSelected && (
                                                    <div className="item-count-status-holder">
                                                        <div className="grid-item-count-status status-ui success-status">
                                                            <span className="status-text">Selected</span>
                                                        </div>
                                                    </div>
                                                )}
                                            <a
                                                className="more-link app-btn-secondary app-btn action-btn"
                                                href="javascript:void(0)"
                                                onClick={() => {
                                                    setData(res);
                                                    setviewModal(true);
                                                }}
                                                title="View"
                                            >
                                                <svg className="svg-icon eye-icon ">
                                                    <use xlinkHref="#eyeIcon">
                                                        <title>View</title>
                                                    </use>
                                                </svg>
                                            </a>
                                        </div>
                                    ) : (
                                        <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                            {res?.isRemoved || res?.isRemovedByAgent ? (
                                                <>
                                                    <div className="item-count-status-holder">
                                                        <div className="grid-item-count-status status-ui error-status">
                                                            <span className="status-text">
                                                                {res?.isRemovedByAgent ? 'Removed By Agent' : 'Removed'}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {(headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                                        headerData.bpStatus ===
                                                            'Rejected_by_Client_Forwarder_to_Action') &&
                                                        res?.isRemoved && (
                                                            <a
                                                                className="more-link app-btn-secondary app-btn action-btn"
                                                                onClick={() => {
                                                                    dispatch(
                                                                        updateSellQuote({
                                                                            airFreightId: res?.baId,
                                                                            type: 'undo',
                                                                            profile: profileType,
                                                                            mode: 'undoSellQuote',
                                                                        })
                                                                    );
                                                                }}
                                                                title="Undo"
                                                            >
                                                                <svg className="svg-icon reset-icon">
                                                                    <use xlinkHref="#resetIcon">
                                                                        <title>Reset</title>
                                                                    </use>
                                                                </svg>
                                                            </a>
                                                        )}
                                                    <a
                                                        className="more-link app-btn-secondary app-btn action-btn"
                                                        onClick={() => {
                                                            setData(res);
                                                            setviewModal(true);
                                                        }}
                                                        title="View"
                                                    >
                                                        <svg className="svg-icon eye-icon ">
                                                            <use xlinkHref="#eyeIcon">
                                                                <title>View</title>
                                                            </use>
                                                        </svg>
                                                    </a>
                                                </>
                                            ) : (
                                                <>
                                                    {headerData.bpStatus === 'Awaiting_Forwarder_Approval' && (
                                                        <>
                                                            {res?.sellCost === null ? (
                                                                <button
                                                                    onClick={() => {
                                                                        setSingleData(res);
                                                                        setShowQuoteDetail(true);
                                                                    }}
                                                                    title="Add Sell Quote"
                                                                    className="sailing-btn app-btn app-btn-primary  grid-btn"
                                                                >
                                                                    <svg className="svg-icon add-btn-icon">
                                                                        <use xlinkHref="#plusIcon">
                                                                            <title>Add</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="button-text footer-button-text">
                                                                        Add Sell Quote
                                                                    </span>
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    onClick={() => {
                                                                        setSingleData(res);
                                                                        setShowQuoteDetail(true);
                                                                    }}
                                                                    title="Edit Sell Quote"
                                                                    className="sailing-btn app-btn app-btn-secondary  grid-btn"
                                                                >
                                                                    {' '}
                                                                    <svg className="svg-icon add-btn-icon">
                                                                        <use xlinkHref="#editIcon">
                                                                            <title>Edit</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="button-text footer-button-text">
                                                                        Edit Sell Quote
                                                                    </span>
                                                                </button>
                                                            )}
                                                            {/* <a
                                                                className="more-link app-btn-primary  app-btn action-btn"
                                                                onClick={() => {
                                                                    setSingleData(res);
                                                                    setShowRemoveDetail(true);
                                                                }}
                                                                title="Remove"
                                                            >
                                                                <svg className="svg-icon eye-icon ">
                                                                    <use xlinkHref="#deleteIcon">
                                                                        <title>Remove</title>
                                                                    </use>
                                                                </svg>
                                                            </a> */}
                                                        </>
                                                    )}
                                                    {(headerData.bpStatus === 'Ready_for_Booking' ||
                                                        headerData.bpStatus === 'Booked' ||
                                                        headerData.bpStatus === 'Draft_Booking') &&
                                                        res?.isSelected && (
                                                            <div className="item-count-status-holder">
                                                                <div className="grid-item-count-status status-ui success-status">
                                                                    <span className="status-text">Selected</span>
                                                                </div>
                                                            </div>
                                                        )}
                                                    {(headerData.bpStatus === 'Ready_for_Booking' ||
                                                        headerData.bpStatus ===
                                                            'Rejected_by_Client_Forwarder_to_Action' ||
                                                        headerData.bpStatus === 'Draft_Booking') && (
                                                        <a
                                                            className="more-link app-btn-secondary app-btn action-btn"
                                                            onClick={() =>
                                                                navigate(`/air_booking_list/${id}/flight_schedule`, {
                                                                    state: {
                                                                        data: res,
                                                                        edit: true,
                                                                        bpNumber: headerData?.bpNumber,
                                                                    },
                                                                })
                                                            }
                                                            title="Edit"
                                                        >
                                                            <svg className="svg-icon save-icon">
                                                                <use xlinkHref="#editIcon">
                                                                    <title>Edit</title>
                                                                </use>
                                                            </svg>
                                                        </a>
                                                    )}
                                                    <a
                                                        className="more-link app-btn-secondary app-btn action-btn"
                                                        onClick={() => {
                                                            setData(res);
                                                            setviewModal(true);
                                                        }}
                                                        title="View"
                                                    >
                                                        <svg className="svg-icon eye-icon ">
                                                            <use xlinkHref="#eyeIcon">
                                                                <title>View</title>
                                                            </use>
                                                        </svg>
                                                    </a>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className={`list-grid ${getActiveDisabledStatus(res?.isSelected)}`}>
                                    <div className="grid-child-item span-2"></div>
                                    <div className="grid-child-item col-sell"></div>
                                    <div className="grid-child-item col-sell">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        Buy Cost
                                                        {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                            headerData.bpStatus ===
                                                                'Rejected_by_Client_Agent_to_Action' ||
                                                            headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                            <span className="mandatory-field-text">*</span>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="content-detail">
                                                    {res?.buyCost === null ? (
                                                        <span className="sub-content sell-cost-color">--</span>
                                                    ) : (
                                                        <span className="sub-content sell-cost-color">
                                                            {res?.buyCost} {res?.buyCurrency}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-sell">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        UOM
                                                        {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                            headerData.bpStatus ===
                                                                'Rejected_by_Client_Agent_to_Action' ||
                                                            headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                            <span className="mandatory-field-text">*</span>
                                                        )}
                                                    </span>
                                                </div>
                                                <div className="content-detail">
                                                    {res?.buyUomCode === null ? (
                                                        <span className="sub-content sell-cost-color">--</span>
                                                    ) : (
                                                        <span className="sub-content sell-cost-color">
                                                            {res?.buyUomCode}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-quote">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        Buy Quote
                                                        {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                            headerData.bpStatus ===
                                                                'Rejected_by_Client_Agent_to_Action' ||
                                                            headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                            <span className="mandatory-field-text">*</span>
                                                        )}
                                                    </span>
                                                </div>
                                                {res?.buyQuoteName === null ? (
                                                    <span className="sub-content sell-quote-color">--</span>
                                                ) : (
                                                    <div className="content-detail">
                                                        <a
                                                            onClick={() =>
                                                                downloadFlightScheduleSell(
                                                                    res?.buySavedFileId,
                                                                    'BUY',
                                                                    res?.baId,
                                                                    res?.buyQuoteName
                                                                )
                                                            }
                                                            className="buy-link"
                                                        >
                                                            {res?.buyQuoteName}
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {/* <div className="destination-agent-field">
                    <div className="selection-heading">Destination Agent</div>
                    <div className="layout-holder one-column">
                        <div className=" modal-input-holder layout-item">
                            <label className="modal-input-label">
                                Destination Agent
                                <span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-layout-item">
                                <SearchableSelectBox
                                    data={[]}
                                    placeholder="Enter Destination Agent to select"
                                    formik={{
                                        setFieldValue: '',
                                        setFieldError: '',
                                        values: 'Leanne Graham',
                                    }}
                                    fieldName="header"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Destination Agent</h6>
                        <div className="details-box-content">Remenka Priscilli</div>
                    </div>
                </div> */}

                {showQuoteDetail && (
                    <QuoteDetailModal
                        setShowQuoteDetail={setShowQuoteDetail}
                        data={singleData}
                        loader={loader}
                        setLoader={setLoader}
                        uomData={uomData}
                    />
                )}
                {showRemoveDetail && (
                    <RemoveScheduleModal displayFunction={setShowRemoveDetail} airFreightId={singleData?.baId} />
                )}
                {selectedToDeleteId && <RemoveSchedule handleClose={handleCloseDeleteModal} id={selectedToDeleteId} />}
            </div>
        </>
    );
};
export default Index;
