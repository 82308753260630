import { get, post } from 'src/services/HttpMethods';
import fileDownload from 'js-file-download';

export interface ColoaderListResponse {
    content: Content[];
    pageable: Pageable;
    last: boolean;
    totalElements: number;
    totalPages: number;
    number: number;
    size: number;
    sort: Sort;
    first: boolean;
    numberOfElements: number;
    empty: boolean;
}

export interface Content {
    name: string;
    code: string;
    status: string;
    organisationId: string;
    shippingLineId: string;
}

export interface Pageable {
    sort: Sort;
    offset: number;
    pageNumber: number;
    pageSize: number;
    paged: boolean;
    unpaged: boolean;
}

export interface Sort {
    empty: boolean;
    sorted: boolean;
    unsorted: boolean;
}
export interface ColoaderListPayload {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string | null;
    columnOrder: boolean;
}
export interface SearchPayload {
    keyword: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
}

//TODO: update the interface definition when api is ready
export interface ColoaderSearchData {
    content: Content[];
    totalPages: number;
    totalElements: number;
}

// Get All Datas
export const getColoaderData = async (payload: ColoaderListPayload) => {
    try {
        const res = await post(
            `api/mgt/coloader/list?limit=${payload.perPageCount}&page=${payload.currentPage}&sortColumn=${
                payload.columnStatus
            }&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter ? `&filterValue=${payload.statusFilter}` : ''
            }`,
            null,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};
//TODO: Update and test export api onces the backend completed
export const exportColoaderList = async (type: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/coloader/export?type=${type}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ColoaderProfiles.xlsx' : 'ColoaderProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
export const exportColoaderListProfiles = async (type: string, organisationData: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        const selectedFilter = organisationData?.selectedFilter;
        const searchTerm = organisationData?.searchTerm;
        const columnOrder = organisationData?.sortPayload['columnOrder'];
        const columnStatus = organisationData?.sortPayload['columnStatus'];
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/mgt/coloader/export?type=${type}${
                selectedFilter ? '&filterby=' + selectedFilter : ''
            }${searchTerm ? '&keyword=' + searchTerm : ''}&sortColumn=${columnStatus}&sortOrder=${columnOrder} `,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ColoaderProfiles.xlsx' : 'ColoaderProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const searchColoader = async (payload: SearchPayload) => {
    try {
        const res = await get(
            `api/mgt/search/coloaders?keyword=${payload.keyword}&size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter !== null ? `&filterValue=${payload.statusFilter}` : ''
            }`,
            null,
            null,
            true
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const searchCoLoaderHeader = async (payload: any) => {
    try {
        const data = await get(`api/mgt/coloader/header?coloaderId=${payload}`, null);
        return data;
    } catch (error) {
        return error;
    }
};
