/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { AccountManager, Agents } from './purchaseOrderReducer';

export interface ReassignBpDetails {
    bpId: string;
    bpNumber: string;
    totTEU: number;
    bpType: string;
    transitPortCount: number;
    bpStatus: string;
    clientName: string;
    carrierFlight: string;
    blpAgentName: string;
    agentName: string;
    originPort: string;
    originPortName: string;
    originPortCode: string;
    destinationPort: string;
    destinationPortName: string;
    destinationPortCode: string;
    destinationType: string;
    eta: string;
    etd: string;
    plannedEta: string;
    plannedEtd: string;
    vesselVoyage: string;
    poNumber: string[];
    vendorName: string[];
    goodsReadyDate: string[];
    createdBy: string;
    createdDateTime: string;
    carrierFlights: {
        carrier: string;
        carrierFlight: string;
        flightNumber: string;
    }[];
    clientNames: string[];
    releaseType: string[];
    bookingProposalPODtos: string;
    isMultipleSchedule: boolean;
    totalSchedulesCount: number;
    notificationIds: string;
    isViewed: boolean;
}

interface ReassignBpState {
    isLoading: boolean;
    reassignBp: ReassignBpDetails[];
    distinctBp: any;
    agents: Agents[];
    accountManager: AccountManager[];
    reassignBpSuccess: any;
    reassignBpError: any;
    isError?: boolean;
}

const initialState: ReassignBpState = {
    isLoading: false,
    reassignBp: [],
    distinctBp: {},
    accountManager: [],
    agents: [],
    reassignBpSuccess: undefined,
    reassignBpError: undefined,
    isError: false,
};

export const reassignBpSlice = createSlice({
    name: 'reassignBp',
    initialState,
    reducers: {
        fetchReassignBpList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchReassignBpListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.reassignBp = action.payload?.data?.info?.bpListingDtos ?? [];
        },
        fetchReassignBpListFailure(state, action) {
            state.isLoading = false;
            state.reassignBp = action.payload;
        },
        fetchDistinctBpList: (state, _action) => {
            state.isError = false;
        },
        fetchDistinctBpListSuccess(state, action) {
            state.isError = false;
            state.distinctBp = action.payload?.data?.info;
        },
        fetchDistinctBpListFailure(state, action) {
            state.distinctBp = action.payload;
        },
        fetchAgentList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchAgentListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.agents = action.payload?.data?.info;
        },
        fetchAgentListFailure: (state, action) => {
            state.isLoading = false;
            state.agents = action.payload;
        },
        fetchAccountManagerList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchAccountManagerListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.agents = action.payload?.data?.info;
        },
        fetchAccountManagerListFailure: (state, action) => {
            state.isLoading = false;
            state.agents = action.payload;
        },
        updateAgentBp: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        updateAgentBpSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.reassignBpSuccess = action.payload;
            state.reassignBpError = {};
        },
        updateAgentBpFailure: (state, action) => {
            state.isLoading = false;
            state.reassignBpSuccess = {};
            state.reassignBpError = action.payload;
        },
        clearFailureBp: (state, _action) => {
            state.reassignBpError = undefined;
            state.reassignBpSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchReassignBpList,
    fetchReassignBpListSuccess,
    fetchReassignBpListFailure,
    fetchDistinctBpList,
    fetchDistinctBpListSuccess,
    fetchDistinctBpListFailure,
    fetchAgentList,
    fetchAgentListSuccess,
    fetchAgentListFailure,
    updateAgentBp,
    updateAgentBpSuccess,
    updateAgentBpFailure,
    clearFailureBp,
} = reassignBpSlice.actions;

export const selectReassignBpLoading = (state: RootState) => state.reassignBpData.isLoading;
export const selectReassignBp = (state: RootState) => state.reassignBpData.reassignBp;
export const selectDistinctBp = (state: RootState) => state.reassignBpData.distinctBp;
export const selectAgents = (state: RootState) => state.reassignBpData.agents;
export const selectReassignBpSuccess = (state: RootState) => state.reassignBpData.reassignBpSuccess;
export const selectReassignBpError = (state: RootState) => state.reassignBpData.reassignBpError;
export const selectReassignBpIsError = (state: RootState) => state.reassignBpData.isError;

export default reassignBpSlice.reducer;
