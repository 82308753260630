import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

const PortName = (name: string) => {
    const iconMarkup = renderToStaticMarkup(<div className="port-name">{name}</div>);
    const customMarkerIcon = divIcon({
        html: iconMarkup,
    });
    return customMarkerIcon;
};

export default PortName;
