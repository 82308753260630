import { get, patch } from '../../../HttpMethods';

export const profilesHeaderApi = async (payload: any) => {
    try {
        const data = await get(`api/mgt/clients/header?clientId=${payload}`, null);
        return data;
    } catch (error) {
        return error;
    }
};

export const clientProfileApproval = async (payload: any) => {
    try {
        const data = await patch(`api/mgt/clients/viewed/autoapproval/${payload}`, null);
        return data;
    } catch (error) {
        return error;
    }
};
