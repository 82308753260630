import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import SailingScheduleDetailModal from './SailingScheduleDetailModal';
import SailingScheduleViewModal from './SailingScheduleViewModal';

interface btnProp {
    Btn?: any;
}

const Index: React.FC<btnProp> = ({ Btn }) => {
    const { selectedSailing } = useSelector((state: RootState) => state.sailingRed);
    const { selectedSchedules } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const [displayModal, setdisplayModal] = useState(false);
    const data = (selectedSailing as any)?.sailingScheduleLists?.[0];
    const dataAir = (selectedSchedules as any)[0];
    // const { isLoading, selectedData } = useSelector((state: any) => state.lifeCycleSearch);
    return (
        <>
            {Btn && <Btn />}
            {data?.originPortName || dataAir?.originAirPortName ? (
                <div className="lcs-schedule-tab customscrollbar">
                    <div className="schedule-card card">
                        <div className="schedule-card-head">
                            <div className="schedule-card-head-left">
                                <span className="schedule-card-icon">
                                    <svg className="svg-icon">
                                        {!data ? (
                                            <use xlinkHref="#shippingModeAir">
                                                <title>Air</title>
                                            </use>
                                        ) : (
                                            <use xlinkHref="#shippingModeSea">
                                                <title>Sea</title>
                                            </use>
                                        )}
                                    </svg>
                                    <span className="schedule-card-title">
                                        {data?.shippingline ? data?.shippingline : dataAir?.carrier}
                                    </span>
                                </span>
                                <div>{data?.vesselVoyage ? data?.vesselVoyage : dataAir?.flightNo}</div>
                            </div>
                            {/*{type === 'vessels' && (
                                <div className="schedule-card-head-right">
                                    <div className="status-badge">
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">{data?.bookingsCount} Booking</span>
                                            </div>
                                        </div>
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">{data?.poCount} POs</span>
                                            </div>
                                        </div>
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">{data?.teu} TEU</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}*/}
                        </div>
                        <div className="schedule-grid">
                            <div className="schedule-grid-left">
                                <div className="schedule-start-point">
                                    <span className="schedule-card-title">
                                        {data?.originPortCode ? data?.originPortName : dataAir?.originAirPortName}
                                    </span>
                                    <div className="schedule-date-time">
                                        <span>ETD</span>
                                        {data?.plannedETD
                                            ? moment(data?.plannedETD).format('DD-MM-YYYY')
                                            : moment(dataAir?.originEtdDate).format('DD-MM-YYYY')}
                                    </div>
                                    <div className="schedule-date-time">
                                        <span>ATD</span>
                                        {data?.atd ? moment(data?.atd).format('DD-MM-YYYY, DD:mm') : '-'}
                                    </div>
                                </div>
                                {data && (
                                    <div className="schedule-stops-indication">
                                        <span className="indication-text">
                                            {data?.sailingScheduleTransitPorts?.length === 0
                                                ? 'Direct'
                                                : data?.sailingScheduleTransitPorts?.length}
                                            {data?.sailingScheduleTransitPorts?.length > 0 && ' Stop'}
                                            {data?.sailingScheduleTransitPorts?.length <= 1 ? '' : 's'}
                                        </span>
                                    </div>
                                )}
                                {!data && dataAir && (
                                    <div className="schedule-stops-indication">
                                        <span className="indication-text">
                                            {dataAir?.bookingProposalAirSchedule?.length}
                                            {dataAir?.bookingProposalAirSchedule?.length === 1 ? ' Stop' : ' Stops'}
                                        </span>
                                    </div>
                                )}
                                <div className="schedule-end-point">
                                    <span className="schedule-card-title">
                                        {data?.destinationPortCode
                                            ? data?.destinationPortName
                                            : dataAir?.destinationPort}
                                        {/* {data?.destinationPortName} ({data?.destinationPortCode}) */}
                                    </span>
                                    <div className="schedule-date-time">
                                        <span>ETA</span>
                                        {data?.plannedETA
                                            ? moment(data?.plannedETA).format('DD-MM-YYYY')
                                            : moment(dataAir?.destEtaDate).format('DD-MM-YYYY')}
                                    </div>
                                    <div className="schedule-date-time">
                                        <span>ATA</span>
                                        {data?.ata ? moment(data?.ata).format('DD-MM-YYYY, DD:mm') : '-'}
                                    </div>
                                </div>
                            </div>
                            {/*<div className="schedule-grid-right">
                                <div className="cm-key-pair-items">
                                    <div className="cm-key-pair-title">Next Stop</div>
                                    {!data && dataAir && (
                                        <div className="cm-key-pair-value">
                                            {dataAir?.scheduleType === 'Direct'
                                                ? dataAir?.destinationPort
                                                : dataAir?.nextStop}
                                            <br />
                                            {dataAir?.scheduleType === 'Direct'
                                                ? moment(dataAir?.destEtaDate)?.format('DD-MM-YYYY') +
                                                  ' ' +
                                                  moment(dataAir?.destEtaTime, 'HH:mm:ss').format('hh:mm')
                                                : '-'}
                                        </div>
                                    )}
                                    {data && (
                                        <div className="cm-key-pair-value">
                                            {data?.serviceType === 'Direct'
                                                ? data?.destinationPortName
                                                : data?.sailingScheduleTransitPorts[0]?.portName
                                                ? data?.sailingScheduleTransitPorts[0]?.portName +
                                                  '(' +
                                                  data?.sailingScheduleTransitPorts[0]?.portCode +
                                                  ')'
                                                : ''}
                                            <br />
                                            {data?.nextStopTime
                                                ? moment(data?.nextStopTime).format('DD-MM-YYYY, DD:mm')
                                                : '-'}
                                        </div>
                                    )}
                                </div>
                                {data?.speed && (
                                    <div className="cm-key-pair-items">
                                        <div className="cm-key-pair-title">Speed</div>
                                        <div className="cm-key-pair-value">{data?.speed ? data?.speed : '-'} km/hr</div>
                                    </div>
                                )}
                            </div>*/}
                            <button
                                className="action-btn app-btn icon-only app-btn-secondary view-more-btn"
                                onClick={() => setdisplayModal(true)}
                            >
                                <svg className="svg-icon">
                                    <use xlinkHref="#eyeIcon">
                                        <title>View</title>
                                    </use>
                                </svg>
                            </button>
                        </div>
                        {/*<div className="schedule-card-end">
                            <div className="schedule-card-head-right card-inline">
                                Last Updated
                                <span className="date-time">
                                    {dataAir?.lastUpdated
                                        ? moment(dataAir?.lastUpdated).format('DD-MM-YYYY, DD:mm')
                                        : ''}
                                    {data?.modifiedDate ? moment(data?.modifiedDate).format('DD-MM-YYYY, DD:mm') : 's'}
                                </span>
                            </div>
                        </div>*/}
                    </div>
                </div>
            ) : (
                <div className="grid-no-content ">
                    <svg className="svg-icon grid-no-content-icon">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">{'No records found'}</p>
                </div>
            )}
            {displayModal && !data?.isManuallyEntered ? (
                <SailingScheduleDetailModal data={data ? data : dataAir} displayFunc={setdisplayModal} />
            ) : (
                displayModal && <SailingScheduleViewModal data={data ? data : dataAir} displayFunc={setdisplayModal} />
            )}
        </>
    );
};
export default Index;
