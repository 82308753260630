/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-undefined */
import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import Breadcrumbs from 'src/components/Breadcrumbs/purchaseOrder';
import 'semantic-ui-css/semantic.min.css';
import PackingConfirmModel from './packingConfirmModel';
import { fetchBasicDetails } from 'src/store/reducers/purchase_order/basicDetails';
import { fetchDatesPoList } from 'src/store/reducers/purchaseOrder/datesPoReducer';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import './vendorPortal.css';
import { Button, Card, Segment } from 'semantic-ui-react';
import {
    fetchPackingStatus,
    fetchSsccData,
    resetSavingResult,
    resetDeleteResult,
    fetchSsrsDaReport,
    resetSsrsDa,
} from 'src/store/reducers/purchaseOrder/vendorPortalReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import ZaPackingModel from './zaPackingModel';
import PackingPagePlaceholder from './placeholder';
import ContainerCardGroup from './containerCardGroup';
import PackingGrid from './packingGrid';
import SsccGrid from './ssccGrid';
import FinaliseModel from './finaliseConfirmModel';
import ResetModel from './resetConfirmModel';
import { useUserProfile } from 'src/hooks/useUserProfile';

/**
 * VP3T
 * @version 2024/04/26
 * @author Hdeng
 */
const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const headerData = useAppSelector(selectHeader);
    const [firstSSCC, setFirstSscc] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [selectedCount, setSelectedCount] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [packData, setPackData] = useState({});
    const [isFinalised, setIsFinalised] = useState(false);
    const [packSuccess, setPackSuccess] = useState(false);
    const [unpackButtonText, setUnpackButtonText] = useState('Unpack');
    const [selectedContainerId, setSelectedContainerId] = useState([] as string[]);
    const [currentPackSelection, setCurrentPackSelection] = useState('');
    const [showZaPopup, setShowZaPopup] = useState(false);
    const [isPackCompleted, setIsPackCompleted] = useState(true);
    const [zaPackingStatus, setZaPackingStatus] = useState([] as any);
    const { packingStatus, ssccData, deleteResult, ssrsReport } = useSelector(
        (state: RootState) => state.vendorPortalData
    );
    const [dataState, setDataState] = React.useState<any[]>(
        ssccData?.length > 0 ? ssccData.map((dataItem: any) => Object.assign({ selected: false }, dataItem)) : []
    );
    const [selectedState, setSelectedState] = React.useState<{
        [id: string]: boolean | number[];
    }>({});
    const [isPackingSummaryLoading, setIsPackingSummaryLoading] = useState(false);
    const [showFinalisePopup, setShowFinalisePopup] = useState(false);
    const [showResetPopup, setShowResetPopup] = useState(false);
    const [poStatusChanged, setPoStatusChanged] = useState(false);

    // header breadcrumb config
    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';
    const { profileType, roleName } = useUserProfile();

    useEffect(() => {
        // get PO header details
        dispatch(fetchBasicDetails(id));
        dispatch(
            fetchDatesPoList({
                id,
            })
        );
        // Get current container packing status
        dispatch(fetchPackingStatus({ poPk: id }));
    }, []);

    useEffect(() => {
        dispatch(fetchPackingStatus({ poPk: id }));
    }, [poStatusChanged]);

    useEffect(() => {
        // sscc grid, initialise data state
        if (ssccData?.length > 0)
            setDataState(ssccData.map((dataItem: any) => Object.assign({ selected: false }, dataItem)));
    }, [ssccData]);

    useEffect(() => {
        if (ssrsReport !== 'NA' && !ssrsReport.poNumber) {
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${ssrsReport}`;
            const downloadLink = document.createElement('a');
            const fileName = `JUSTGROUP_PACKING_DA_REPORT.xlsx`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            dispatch(resetSsrsDa('NA'));
            setIsPackingSummaryLoading(false);
        }
    }, [ssrsReport]);

    useEffect(() => {
        // If user clicked on 'Packed' in the card
        if (currentPackSelection && packingStatus?.length > 0) displayPackedSSCC();
        // Set ZA additional array [size:X, packed:X, colour:X]
        if (packingStatus?.length > 0) {
            // check if Packing is already finalised
            setIsFinalised(packingStatus[0].finalised === 'Y');
            const zaObject = packingStatus.find((p) => {
                return p.packType === 'ZA';
            });
            if (zaObject?.additional.length > 0) {
                const zaArray = [] as any;
                zaObject?.additional.forEach((a: any) => {
                    zaArray.push({ ...a, packed: 0, inEdit: true });
                });
                setZaPackingStatus(zaArray);
            }

            // ZA need to fall into the range of 95%-105% (+/- 5%)
            // Ratio has to be packed 100%
            const ratioPackArray = packingStatus.filter((p) => p.packType !== 'ZA');
            const zaArray = packingStatus.filter((p) => p.packType === 'ZA');
            let isZaPassHurdle = false;
            let isRatioPassHurdle = false;
            if (zaArray?.length > 0) {
                for (let i = 0; i < zaArray.length; i++) {
                    if (zaArray[i].progress < 95 || zaArray[i].progress > 105) break;
                    if (i === zaArray.length - 1) isZaPassHurdle = true;
                }
            }

            if (ratioPackArray?.length > 0) {
                for (let i = 0; i < ratioPackArray.length; i++) {
                    if (ratioPackArray[i].progress !== 100) break;
                    if (i === ratioPackArray.length - 1) isRatioPassHurdle = true;
                }
            }

            // if (isZaPassHurdle && isRatioPassHurdle) setIsPackCompleted(true);
            if (isRatioPassHurdle) setIsPackCompleted(true);
            else setIsPackCompleted(false);
        }
    }, [packingStatus]);

    useEffect(() => {
        // if Pack action completed, refresh data in the screen
        if (packSuccess) {
            dispatch(fetchPackingStatus({ poPk: id }));
            dispatch(fetchSsccData({ prefix: 39327806, sequence: firstSSCC, quantity: quantity }));
            dispatch(resetSavingResult({}));
            setPackSuccess(false);
        }
        // untick all the ticked boxes in the sscc grid
        setSelectedState({});
    }, [packSuccess]);

    useEffect(() => {
        // after unpack, refresh data in the screen
        if (deleteResult?.length > 0) {
            dispatch(fetchPackingStatus({ poPk: id }));
            if (firstSSCC > 0 && quantity > 0)
                // prefix is hard coded as per requested by JG, this might need to be changed in the future
                dispatch(fetchSsccData({ prefix: 39327806, sequence: firstSSCC, quantity: quantity }));
            dispatch(resetDeleteResult({}));
            if (currentPackSelection) displayPackedSSCC();
        } else {
            setUnpackButtonText('Unpack');
        }
        // after unpack, untick all the ticked boxes in sscc grid
        setSelectedState({});
    }, [deleteResult]);

    useEffect(() => {
        // When user tick boxes in the sscc grid, update container array and ticked number
        if (selectedState) {
            const containers = [];
            let count = 0;
            for (const [key, value] of Object.entries(selectedState)) {
                if (value) {
                    count++;
                    containers.push(key);
                }
            }
            setSelectedCount(count);
            setSelectedContainerId(containers);
        }
    }, [selectedState]);

    const displayPackedSSCC = (containerType?: string) => {
        // when user click on 'Packed' button inside the card
        // reset the input box to 0
        setFirstSscc(0);
        setQuantity(0);

        // check which container user selected
        if (containerType) setCurrentPackSelection(containerType);
        // prepare grid data
        let newSsccList = packingStatus
            .find((e) => e.packType === (containerType ? containerType : currentPackSelection))
            .containers.map((element: any) => {
                return {
                    id: element.container_id,
                    prefix: element.prefix,
                    serial: element.sequence,
                    checkDigit: element.checkDigit,
                    poNumber: element.legacy_po_id,
                    inUse: true,
                };
            });
        // set grid data and display in the UI
        // if there is no packed data, display empty grid
        if (newSsccList?.length > 0) {
            newSsccList = newSsccList.filter(
                (value: any, index: number, self: any) => index === self.findIndex((t: any) => t.id === value.id)
            );
            setDataState(newSsccList.map((dataItem: any) => Object.assign({ selected: false }, dataItem)));
        } else setDataState([]);
    };

    const generateSscc = () => {
        // button click - generate SSCC from CIX API
        if (firstSSCC >= 100000000 && firstSSCC <= 999999999 && quantity > 0 && quantity <= 100) {
            dispatch(fetchSsccData({ prefix: 39327806, sequence: firstSSCC, quantity: quantity }));
        }
    };

    const downloadReport = (type: string) => {
        setIsPackingSummaryLoading(true);
        dispatch(fetchSsrsDaReport({ poNumber: packingStatus[0].purchaseOrderNumber, type: type }));
    };

    return (
        <div className="main-wrapper container packing-details-wrapper">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={`${poName}`}
                    firstUrl={`${poUrl}`}
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New PO' : 'PO Details'}
                    secondUrl={`/purchase_order/${id}/product_details`}
                />
                <PurchaseOrderHeader id={id} />
                <PODetailsHeaderTabs />
            </div>

            {packingStatus?.length > 0 ? (
                <div className="grid-section product-details-grid">
                    {isFinalised && (
                        <div className="mandatory-warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">This Purchase Order Packing has been Finalised</div>
                        </div>
                    )}

                    <ContainerCardGroup displayPackedSSCC={displayPackedSSCC} />
                    <Segment className="sscc_segment">
                        <form
                            id="my-form"
                            onSubmit={(e) => {
                                e.preventDefault();
                                generateSscc();
                                setCurrentPackSelection('');
                            }}
                            className="modal-form form-fields"
                        >
                            <div className="modal-input-holder layout-item">
                                <label className="modal-input-label">First SSCC</label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Fixed Length (9)"
                                        type="number"
                                        className="modal-input input-text"
                                        id="firstSSCC"
                                        name="firstSSCC"
                                        disabled={isFinalised}
                                        value={firstSSCC === 0 ? '' : firstSSCC}
                                        onChange={(e: any) => {
                                            const val = e.target.value;
                                            if (val && val > 0 && val.toString().length <= 9)
                                                setFirstSscc(e.target.value);
                                            if (!val) setFirstSscc(e.target.value);
                                        }}
                                    ></input>
                                </div>
                            </div>

                            <div className="modal-input-holder layout-item">
                                <label className="modal-input-label">Quantity</label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Max 100"
                                        type="number"
                                        className="modal-input input-text"
                                        id="quantity"
                                        name="quantity"
                                        disabled={isFinalised}
                                        value={quantity === 0 ? '' : quantity}
                                        onChange={(e: any) => {
                                            const val = e.target.value;
                                            if (val && val > 0 && val <= 100) setQuantity(e.target.value);
                                            if (!val) {
                                                setQuantity(e.target.value);
                                            }
                                        }}
                                    ></input>
                                </div>
                            </div>

                            <div className="form-submit-btn">
                                <Button
                                    primary
                                    disabled={
                                        !(firstSSCC?.toString()?.length === 9 && quantity > 0 && quantity <= 100) ||
                                        isFinalised
                                    }
                                    title="Submit"
                                    form="my-form"
                                    type="submit"
                                >
                                    Create
                                </Button>
                            </div>
                        </form>
                    </Segment>
                    <Card.Group itemsPerRow={3} stackable={true} doubling={true} className="card_group">
                        <SsccGrid
                            selectedContainerId={selectedContainerId}
                            selectedState={selectedState}
                            setSelectedState={setSelectedState}
                            dataState={dataState}
                            isFinalised={isFinalised}
                        />

                        <PackingGrid
                            selectedCount={selectedCount}
                            selectedState={selectedState}
                            setPackData={setPackData}
                            selectedContainerId={selectedContainerId}
                            dataState={dataState}
                            setShowZaPopup={setShowZaPopup}
                            setShowPopup={setShowPopup}
                        />
                    </Card.Group>
                </div>
            ) : packingStatus?.length === 0 ? (
                <div className="grid-section product-details-grid">
                    <div className="no-data-quick-link">
                        <div className="no-data-ql-icon">
                            <svg className="svg-icon no-data-icon">
                                <use xlinkHref="#unlinkIcon"></use>
                            </svg>
                        </div>
                        <p className="no-data-ql-text">No Packing Data</p>
                    </div>
                </div>
            ) : (
                <PackingPagePlaceholder />
            )}

            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    {isPackingSummaryLoading ? (
                        <Button color="blue" loading title="Download" className="footer-btn" onClick={() => {}}>
                            Loading
                        </Button>
                    ) : isFinalised ? (
                        <Button
                            color="blue"
                            title="Download"
                            className="footer-btn"
                            onClick={() => {
                                downloadReport('Final');
                            }}
                        >
                            <h2>Final DA</h2>
                        </Button>
                    ) : (
                        <Button
                            color="blue"
                            title="Download"
                            className="footer-btn"
                            onClick={() => {
                                downloadReport('Draft');
                            }}
                        >
                            <h2>Draft DA</h2>
                        </Button>
                    )}
                    {isFinalised ? (
                        <Button
                            negative
                            title="Finalise"
                            className="footer-btn"
                            disabled={!isFinalised || (profileType !== 'forwarder' && profileType !== 'client')}
                            onClick={() => {
                                setShowResetPopup(true);
                            }}
                        >
                            <h2>Reset</h2>
                        </Button>
                    ) : (
                        <Button
                            negative
                            title="Finalise"
                            className="footer-btn"
                            disabled={isFinalised || !isPackCompleted}
                            onClick={() => {
                                setShowFinalisePopup(true);
                            }}
                        >
                            <h2>Finalise PO</h2>
                        </Button>
                    )}
                </div>
            </div>

            {showPopup && (
                <PackingConfirmModel
                    setPackSuccess={setPackSuccess}
                    packingData={packData}
                    setModalView={setShowPopup}
                    modalView={showPopup}
                />
            )}
            {showZaPopup && (
                <ZaPackingModel
                    setPackSuccess={setPackSuccess}
                    packingData={packData}
                    setModalView={setShowZaPopup}
                    modalView={showZaPopup}
                    zaPackingArray={zaPackingStatus}
                />
            )}
            {showFinalisePopup && (
                <FinaliseModel
                    modalView={showFinalisePopup}
                    setModalView={setShowFinalisePopup}
                    poStatusChanged={poStatusChanged}
                    setPoStatusChanged={setPoStatusChanged}
                />
            )}
            {showResetPopup && (
                <ResetModel
                    modalView={showResetPopup}
                    setModalView={setShowResetPopup}
                    poStatusChanged={poStatusChanged}
                    setPoStatusChanged={setPoStatusChanged}
                />
            )}
        </div>
    );
};
export default Index;
