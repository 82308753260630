import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    addIntegrationData,
    fetchIntegrationData,
    isLoadingFn,
    submitAndActivate,
} from '../reducers/integrationReducer';

// Import all api's
import {
    addIntegrationDetails,
    getIntegrationDetails,
    submitIntegrationDetailsAndActivate,
} from '../../services/apis/integrationApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* putIntegrationData({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse<any> = yield call(addIntegrationDetails, payload);
        payload.responseOnSuccess(response);
        payload['response'] = response;
        yield put({ type: addIntegrationData, payload: payload });
    } catch (err) {
        console.log('err', err);
    }
}

function* getIntegrationData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse<any> = yield call(getIntegrationDetails, payload);
        payload['integrationData'] = response;
        yield put({ type: fetchIntegrationData, payload: payload });
    } catch {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* submitIntegration({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse<any> = yield call(submitIntegrationDetailsAndActivate, payload);
        payload.responseOnSuccess(response);
        payload['response'] = response;
        yield put({ type: submitAndActivate, payload: payload });
    } catch (err) {
        console.log('err', err);
    }
}

function* integrationDetailsSaga() {
    yield takeEvery('integrationDetails/addIntegrationData', putIntegrationData);
    yield takeEvery('integrationDetails/fetchIntegrationData', getIntegrationData);
    yield takeEvery('integrationDetails/submitAndActivate', submitIntegration);
}

// Export the saga (data-saga)
export default integrationDetailsSaga;
