/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';
import { agentPortDetailsTableHeaders } from '../../../../utils/constants';

export interface AgentPortDetailsDocumentsDtoList {
    portname: string;
    location: string;
    direction: string;
    air: string;
    sea: string;
    rail: string;
    road: string;
    portaddress: string;
}
export interface AgentPortDetailsDataDetails {
    agentPortDetailList: AgentPortDetailsDocumentsDtoList[];
    totalNoofItems: number;
    totalActiveProducts: number;
    totalActiveDocuments: number;
    totalNoOfPages: number;
}

interface AgentPortDetailsData {
    status: number;
    message: string;
    info: AgentPortDetailsDataDetails;
    data: any;
}

interface AgentPortDetailsColumnData {
    status: number;
    message: string;
    info: string[];
    columnName?: string;
    data: any;
}

interface AgentPortDetailsState {
    isLoading: boolean;
    portDetails?: AgentPortDetailsData;
    portDetailsColumn?: AgentPortDetailsColumnData;
    isError?: boolean;
    id?: number;
    currentPage: number;
    columnStatus: string;
    perPageCount: number;
    totalNoOfPages: number;
    totalNoOfItems: number;
}

const initialState: AgentPortDetailsState = {
    isLoading: true,
    portDetails: undefined,
    portDetailsColumn: undefined,
    isError: false,
    id: undefined,
    currentPage: 0,
    columnStatus: agentPortDetailsTableHeaders.PortName,
    perPageCount: 10,
    totalNoOfPages: 1,
    totalNoOfItems: 0,
};

export const agentPortDetailsSlice = createSlice({
    name: 'agentPortDetails',
    initialState,
    reducers: {
        fetchAgentPortDetailsList: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload?.firstLoad ? 0 : action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.id = action.payload.id;
            state.totalNoOfPages = 1;
            state.totalNoOfItems = 0;
        },
        fetchAgentPortDetailsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.portDetails = action.payload;
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
        fetchAgentPortDetailsListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.portDetails = action.payload;
        },
        fetchAgentPortDetailsColumnList: (state, _action) => {
            state.isLoading = true;
        },
        fetchAgentPortDetailsColumnListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.portDetailsColumn = action.payload;
        },
        fetchAgentPortDetailsColumnListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.portDetailsColumn = action.payload;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearAgentPortDetails: (state, _action) => {
            // eslint-disable-next-line no-undefined
            state.portDetails = undefined;
        },
        searchAgentPortDetailsData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
        },
        searchAgentPortDetailsDataSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.portDetails = action.payload;
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
        searchAgentPortDetailsDataFailure: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.portDetails = action.payload;
        },
    },
});

export const {
    fetchAgentPortDetailsList,
    fetchAgentPortDetailsListSuccess,
    fetchAgentPortDetailsListFailed,
    fetchAgentPortDetailsColumnList,
    fetchAgentPortDetailsColumnListSuccess,
    fetchAgentPortDetailsColumnListFailed,
    isLoadingFn,
    clearAgentPortDetails,
    searchAgentPortDetailsData,
    searchAgentPortDetailsDataSuccess,
    searchAgentPortDetailsDataFailure,
} = agentPortDetailsSlice.actions;

export const selectAgentPortDetailLoading = (state: RootState) => state.agentPortDetailsData.isLoading;
export const selectAgentPortDetailUser = (state: RootState) => state.agentPortDetailsData.portDetails;
export const selectAgentPortDetailColumnUser = (state: RootState) => state.agentPortDetailsData.portDetailsColumn;
export const selectAgentPortDetailIsError = (state: RootState) => state.agentPortDetailsData.isError;
export const selectAgentPortDetailCurrentPage = (state: RootState) => state.agentPortDetailsData.currentPage;
export const selectAgentPortDetailColumnStatus = (state: RootState) => state.agentPortDetailsData.columnStatus;
export const selectAgentPortDetailPerPageCount = (state: RootState) => state.agentPortDetailsData.perPageCount;
export const selectAgentPortDetailtotalNoOfPages = (state: RootState) => state.agentPortDetailsData.totalNoOfPages;
export const selectAgentPortDetailtotalNoOfItems = (state: RootState) => state.agentPortDetailsData.totalNoOfItems;

export default agentPortDetailsSlice.reducer;
