/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { sopTableHeaders } from '../../utils/constants';

export interface ClientDocumentsDtoList {
    cd_PK: string;
    cd_docname: string;
    cd_version: string;
    cd_author: string;
    cd_reviewedby: string;
    cd_clientsignatory: string;
    cd_activedate: string;
    cd_enddate: string;
    cd_clientacceptancedate: string;
    cd_status: string;
    fileName?: string;
}
export interface SOPDataDetails {
    clientDocumentsDtoList: ClientDocumentsDtoList[];
    totalNoofItems: number;
    totalActiveProducts: number;
    totalActiveDocuments: number;
    totalNoOfPages: number;
}

interface SopData {
    status: number;
    message: string;
    info: SOPDataDetails;
    data: any;
}

interface SopState {
    isLoading: boolean;
    sop?: SopData;
    sopSuccess: any;
    sopError: any;
    sopAuthor: any;
    sopReviewedBy: any;
    isError?: boolean;
    id?: number;
    currentPage: number;
    columnStatus: string;
    perPageCount: number;
    totalNoOfPages: number;
    totalNoOfItems: number;
}

const initialState: SopState = {
    isLoading: true,
    sop: undefined,
    sopSuccess: undefined,
    sopError: undefined,
    sopAuthor: undefined,
    sopReviewedBy: undefined,
    isError: false,
    id: undefined,
    currentPage: 0,
    columnStatus: sopTableHeaders.Status,
    perPageCount: 10,
    totalNoOfPages: 1,
    totalNoOfItems: 0,
};

export const sopSlice = createSlice({
    name: 'sop',
    initialState,
    reducers: {
        fetchSOPList: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload?.firstLoad ? 0 : action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.id = action.payload.id;
        },
        fetchSOPListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.sop = action.payload;
            state.sopSuccess = {};
            state.sopError = {};
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
        fetchSOPListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            // state.sop = action.payload;
            state.sopSuccess = {};
            state.sopError = action.payload;
        },
        createSopDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
            // state.sop = action.payload;
        },
        createSopDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.sopSuccess = action.payload;
            state.sopError = {};
        },
        createSopDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.sopSuccess = {};
            state.sopError = action.payload;
        },
        updateSopDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
            // state.sop = action.payload;
        },
        updateSopDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.sopSuccess = action.payload;
            state.sopError = {};
        },
        updateSopDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.sopSuccess = {};
            state.sopError = action.payload;
        },
        fetchAuthorDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAuthorDetailsSuccess(state, action) {
            state.isError = false;
            state.sopAuthor = action.payload;
        },
        fetchAuthorDetailsFailure(state, action) {
            state.isError = true;
            state.sopError = action.payload;
        },
        fetchReviewedByDetails: (state, _action) => {
            state.isError = false;
        },
        fetchReviewedByDetailsSuccess(state, action) {
            state.isError = false;
            state.sopReviewedBy = action.payload;
        },
        fetchReviewedByDetailsFailure(state, action) {
            state.isError = true;
            state.sopReviewedBy = action.payload;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearSops: (state, _action) => {
            state.sop = undefined;
        },
        clearFailure: (state, _action) => {
            state.sopError = undefined;
            state.isError = false;
        },
        searchSOPData(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.sop = action.payload;
            state.sopSuccess = {};
            state.sopError = {};
            if (action.payload?.columnStatus) {
                state.columnStatus = action.payload.columnStatus;
            }
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
    },
});

export const {
    fetchSOPList,
    fetchSOPListSuccess,
    fetchSOPListFailed,
    createSopDetails,
    createSopDetailsSuccess,
    createSopDetailsFailure,
    updateSopDetails,
    updateSopDetailsSuccess,
    updateSopDetailsFailure,
    fetchAuthorDetails,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    fetchReviewedByDetails,
    fetchReviewedByDetailsSuccess,
    fetchReviewedByDetailsFailure,
    isLoadingFn,
    clearSops,
    clearFailure,
    searchSOPData,
} = sopSlice.actions;

export const selectSopLoading = (state: RootState) => state.sopData.isLoading;
export const selectSopUser = (state: RootState) => state.sopData.sop;
export const selectSopSuccess = (state: RootState) => state.sopData.sopSuccess;
export const selectSopError = (state: RootState) => state.sopData.sopError;
export const selectSopAuthor = (state: RootState) => state.sopData.sopAuthor;
export const selectSopReviewedBy = (state: RootState) => state.sopData.sopReviewedBy;
export const selectSopIsError = (state: RootState) => state.sopData.isError;
export const selectSopCurrentPage = (state: RootState) => state.sopData.currentPage;
export const selectSopColumnStatus = (state: RootState) => state.sopData.columnStatus;
export const selectSopPerPageCount = (state: RootState) => state.sopData.perPageCount;
export const selectSoptotalNoOfPages = (state: RootState) => state.sopData.totalNoOfPages;
export const selectSoptotalNoOfItems = (state: RootState) => state.sopData.totalNoOfItems;

export default sopSlice.reducer;
