import { createSlice } from '@reduxjs/toolkit';
import { availablePoHeaders } from '../../../utils/constants';

export const dataSlice = createSlice({
    name: 'availablePosAir',
    initialState: {
        selectedItems: [],
        disabledFilterData: {
            clientName: null,
            originPort: null,
            destinationPort: null,
            isHeaderOnlyPo: null,
            vendorName: null,
        },
        filter: {
            poNumber: null,
            clientName: null,
            vendorName: null,
            originPort: null,
            destinationPort: null,
            createdDate: null,
            goodsReadyDate: null,
        },
        bpId: null,
        bpNumber: null,
        totalCBM: 0,
        totalKgs: 0,
        infoValues: {
            clientName: '',
            originPort: '',
            destinationPort: '',
            goodsReadyDates: [],
            vendorName: '',
        },
        availablePoList: [],
        filterValues: {},
        isLoading: false,
        columnStatus: availablePoHeaders.poNumber,
        error: false,
        success: false,
        poData: {},
        gateInDate: false,
    },
    reducers: {
        fetchAvailablePoDataAir: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload.columnStatus;
            state.availablePoList = action.payload.response?.availableAirPOsDtos;
            state.filterValues = action.payload.response?.distinctColumnValues;
            state.gateInDate = action.payload.gateInDateExpired;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        addOrSavePoDataAir: (state, action) => {
            if (action.payload?.data?.status == 500 || action.payload?.data?.status == 400) {
                state.error = true;
                state.errorMessage = action.payload?.data?.data?.message;
            } else if (action.payload?.data?.data?.length > 0) {
                const response = action.payload?.data?.data[0];
                state.bpNumber = response.BP_Number;
                state.totalCBM = response.BP_TotCBM;
                state.totalKgs = response.BP_TotKGS;
                state.success = true;
                localStorage.setItem('bpId', response.BP_PK);
                localStorage.setItem('bpNumber', response.BP_Number);
                state.bpId = response.BP_PK;
            }
        },
        getBpDataAir: (state, action) => {
            state.isLoading = true;
            state.bpNumber = action.payload?.data?.bpNumber;
            state.totalCBM = action.payload?.data?.totalCBM;
            state.totalKgs = action.payload?.data?.totalKgs;
            state.availablePoList = action.payload?.data?.purchaseOrders;
            const values = {
                clientName: action.payload?.data?.client || '',
                originPort: action.payload?.data?.originPort || '',
                destinationPort: action.payload?.data?.destinationPort || '',
                goodsReadyDates: action.payload?.data?.goodsReadyDate || '',
                vendorName: action.payload?.data?.vendorName || '',
            };
            state.infoValues = values;
            state.filterValues = action.payload?.data?.columnList;
            state.isLoading = false;
        },
        resetFlags: (state, _action) => {
            state.success = false;
            state.error = false;
        },
        poDataFetchAir: (_state, _action) => {
            // console.log(action);
        },
        savePoDataAir: (state, action) => {
            state.poData = action.payload;
        },
        setSelectedItems: (state, action) => {
            state.selectedItems = action.payload;
        },
        setDisabledFilterData: (state, action) => {
            state.disabledFilterData = action.payload;
        },
        setFilterDispatch: (state, action) => {
            state.filter = action.payload;
        },
        searchAvailablePoDataAir: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload.columnStatus;
        },
        searchAvailablePoDataSuccessAir: (state, action) => {
            state.availablePoList = action.payload.response?.availableAirPOsDtos;
            state.filterValues = action.payload.response?.distinctColumnValues;
            state.isLoading = false;
        },
    },
});

export const {
    fetchAvailablePoDataAir,
    isLoadingFn,
    addOrSavePoDataAir,
    getBpDataAir,
    resetFlags,
    poDataFetchAir,
    savePoDataAir,
    setSelectedItems,
    setDisabledFilterData,
    setFilterDispatch,
    searchAvailablePoDataAir,
    searchAvailablePoDataSuccessAir,
} = dataSlice.actions;
export default dataSlice.reducer;
