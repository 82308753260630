import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    ContainerModePoDataDetails,
    fetchContainerModePoList,
    selectContainerModePo,
    selectContainerModePoLoading,
    selectUpdateConfirmationGroupError,
    selectUpdateConfirmationGroupIsError,
    selectUpdateConfirmationGroupSuccess,
    clearFailure,
    updateConfirmationGroup,
} from 'src/store/reducers/purchaseOrder/containerModePoReducer';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import './container-mode.scss';
import { PoContainerStatus, poContainerStatusPillList } from './PoStatusList';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { fetchHeader, selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import { Position, error, success } from 'src/utils/toast';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import DeleteReason from './DeleteReason';
import ReleaseCG from './ReleaseCG';
import DatePicker from 'react-datepicker';
import '../../../components/common/DatePicker/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { fetchCoLoaderDetailsApi } from 'src/services/apis/purchaseOrder/containerModePoApi';
import { InputActionMeta } from 'react-select';
const Index = () => {
    const { id } = useParams();
    // const navigate = useNavigate();
    const { profileType } = useUserProfile();
    const dispatch = useAppDispatch();
    const containerMode = useAppSelector(selectContainerModePo);
    const isLoading = useAppSelector(selectContainerModePoLoading);
    const isError = useAppSelector(selectUpdateConfirmationGroupIsError);
    const updateConfirmationGroupSuccess = useAppSelector(selectUpdateConfirmationGroupSuccess);
    const updateConfirmationGroupError = useAppSelector(selectUpdateConfirmationGroupError);
    const headerData = useAppSelector(selectHeader);
    const [containerData, setContainerData] = useState<ContainerModePoDataDetails[]>([]);
    const [deleteModalView, setDeleteModalView] = useState(false);
    const [releaseModalView, setReleaseModalView] = useState(false);
    const [currentCG, setCurrentCG] = useState('');
    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const [coloaderData, setCoLoaderData] = useState<any>([]);

    const getColoader = (search: string) => {
        fetchCoLoaderDetailsApi({ clientCode: '', search })
            ?.then((res: any) => {
                const mapped = res?.data
                    ?.filter((val: any) => {
                        if (profileType === 'agent') {
                            if (val?.coLoaderCode !== process.env.REACT_APP_ORG_CODE) {
                                return val;
                            }
                        } else {
                            return val;
                        }
                    })
                    ?.map((val: any) => {
                        return {
                            label: val?.coLoader,
                            value: val?.coLoaderCode,
                            extraField: val?.coLoaderId,
                        };
                    });

                setCoLoaderData(mapped);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const filterdColoaderData = (data: any) => {
        const filtered = coloaderData?.find((res: any) => res?.value === data?.coLoaderCode);
        if (!filtered && data?.coLoader) {
            return [
                ...coloaderData,
                {
                    label: data?.coLoader,
                    value: data?.coLoaderCode,
                    extraField: data?.coLoaderId,
                },
            ];
        } else {
            return coloaderData;
        }
    };

    useEffect(() => {
        dispatch(fetchContainerModePoList({ poId: id, profileType }));
        // dispatch(
        //     fetchHeader({
        //         poId: id,
        //     })
        // );
        getColoader('');
    }, []);

    useEffect(() => {
        if (containerMode?.length) {
            setContainerData(containerMode);
        }
    }, [containerMode]);

    useEffect(() => {
        if (isError) {
            error(updateConfirmationGroupError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (updateConfirmationGroupSuccess?.status === 200) {
            success('Confirmation group updated successfully', Position.TOP_RIGHT);
            dispatch(fetchContainerModePoList({ poId: id, profileType }));
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
            dispatch(clearFailure([]));
        } else if (updateConfirmationGroupSuccess?.mode === 'DELETE') {
            success('Confirmation group deleted successfully', Position.TOP_RIGHT);
            dispatch(fetchContainerModePoList({ poId: id, profileType }));
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
            setDeleteModalView(false);
            dispatch(clearFailure([]));
            if (updateConfirmationGroupSuccess?.navigate) {
                window.location.href = `/purchase_order/${id}/basic_details`;
            }
        } else if (updateConfirmationGroupSuccess?.mode === 'RELEASE') {
            success('Confirmation group released successfully', Position.TOP_RIGHT);
            dispatch(fetchContainerModePoList({ poId: id, profileType }));
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
            setReleaseModalView(false);
            dispatch(clearFailure([]));
        }
    }, [isError, updateConfirmationGroupSuccess, updateConfirmationGroupError]);

    const onInputChange = (confirmationGroupId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        // if (e.target.name === 'teu' && +e.target.value > 99) {
        //     return false;
        // }
        const newArr = JSON.parse(JSON.stringify(containerData));
        const filtered = newArr.map((res: ContainerModePoDataDetails) => {
            if (res.confirmationGroupId === confirmationGroupId) {
                return {
                    ...res,
                    teu: e.target.name === 'cbm' ? +(+e.target.value / 28).toFixed(5) : res.teu,
                    [e.target.name]: e.target.value ? +e.target.value : e.target.value,
                };
            } else {
                return res;
            }
        });
        setContainerData(filtered);
    };

    const onColoaderChange = (confirmationGroupId: string, data: any) => {
        const newArr = JSON.parse(JSON.stringify(containerData));
        const filtered = newArr.map((res: ContainerModePoDataDetails) => {
            if (res.confirmationGroupId === confirmationGroupId) {
                return {
                    ...res,
                    coLoader: data?.label,
                    coLoaderCode: data?.value,
                    coLoaderId: data?.extraField,
                };
            } else {
                return res;
            }
        });
        setContainerData(filtered);
    };
    const onInputDateChange = (confirmationGroupId: string, value: string) => {
        const newArr = JSON.parse(JSON.stringify(containerData));
        const filtered = newArr.map((res: ContainerModePoDataDetails) => {
            if (res.confirmationGroupId === confirmationGroupId) {
                return {
                    ...res,
                    scheduledDeliverydate: value,
                };
            } else {
                return res;
            }
        });
        setContainerData(filtered);
    };

    const onReset = (confirmationGroupId: string) => {
        const newArr = JSON.parse(JSON.stringify(containerData));
        const filtered = containerMode.find(
            (res: ContainerModePoDataDetails) => res.confirmationGroupId === confirmationGroupId
        );
        const updated = newArr.map((res: ContainerModePoDataDetails) => {
            if (res.confirmationGroupId === confirmationGroupId) {
                return {
                    ...res,
                    cbm: filtered?.cbm,
                    weight: filtered?.weight,
                    totalCGQty: filtered?.totalCGQty,
                    totalCartons: filtered?.totalCartons,
                    teu: filtered?.teu,
                    scheduledDeliverydate: filtered?.scheduledDeliverydate,
                    coLoader: filtered?.coLoader,
                    coLoaderCode: filtered?.coLoaderCode,
                    coLoaderId: filtered?.coLoaderId,
                };
            } else {
                return res;
            }
        });
        setContainerData(updated);
    };

    const isLand = headerData?.shippingMode?.shippingModeCode === 'LND';

    const checkErrorStatus = (confirmationGroup: ContainerModePoDataDetails) => {
        const cgStatus = confirmationGroup.status?.toLowerCase();
        const newArr = JSON.parse(JSON.stringify(containerMode));
        const filtered = newArr.find(
            (res: ContainerModePoDataDetails) => res.confirmationGroupId === confirmationGroup.confirmationGroupId
        );
        if (filtered?.type === 'BCN') {
            const status =
                !filtered?.cbm ||
                !filtered?.weight ||
                !filtered?.totalCartons ||
                (isLand ? !filtered?.scheduledDeliverydate : !filtered?.teu);
            if (status) {
                return true;
            }
        } else if (filtered?.type === 'LCL' || filtered?.type === 'AIR') {
            const status = !filtered?.cbm || !filtered?.weight || !filtered?.totalCartons;
            if (status) {
                return true;
            }
        } else {
            const status =
                !filtered?.cbm ||
                !filtered?.weight ||
                !filtered?.totalCartons ||
                (isLand ? !filtered?.scheduledDeliverydate : !filtered?.teu);
            if (status) {
                return true;
            }
        }
        // if (
        //     profileType === 'agent' &&
        //     (cgStatus === PoContainerStatus.PendingActionForwarder ||
        //         cgStatus === PoContainerStatus.PendingSelectionClient ||
        //         cgStatus === PoContainerStatus.RejectedByClientWithForwarder)
        // ) {
        //     return true;
        // } else if (profileType === 'forwarder' && cgStatus === PoContainerStatus.RejectedByClientWithAgent) {
        //     return true;
        // } else
        if (
            profileType === 'client' &&
            (cgStatus === PoContainerStatus.OptionsToBeAdded ||
                cgStatus === PoContainerStatus.RejectedByClientWithAgent ||
                cgStatus === PoContainerStatus.PendingActionForwarder)
        ) {
            return true;
        }
        return false;
    };

    const checkPermission = (permission: any, type: any) => {
        let flag = false;
        for (let i = 0; i < permissions?.length; i++) {
            if (
                (permissions[i] as any)?.access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .slice(0, 2)
                    .includes(permission.toLowerCase()) &&
                (permissions[i] as any)?.access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .slice(0, 2)
                    .includes(type.toLowerCase())
            ) {
                flag = true;
            }
        }

        return flag;
    };

    const validations = (confirmationGroup: ContainerModePoDataDetails) => {
        if (confirmationGroup.type === 'BCN') {
            return (
                !confirmationGroup?.cbm ||
                !confirmationGroup?.weight ||
                !confirmationGroup?.totalCartons ||
                (isLand
                    ? !confirmationGroup?.scheduledDeliverydate
                    : !confirmationGroup?.teu || confirmationGroup?.teu <= 0)
            );
        } else if (confirmationGroup.type === 'LCL') {
            return (
                !confirmationGroup?.cbm ||
                !confirmationGroup?.weight ||
                !confirmationGroup?.totalCartons ||
                !confirmationGroup?.coLoader
            );
        } else if (confirmationGroup.type === 'AIR') {
            return !confirmationGroup?.cbm || !confirmationGroup?.weight || !confirmationGroup?.totalCartons;
        } else {
            return (
                !confirmationGroup?.cbm ||
                !confirmationGroup?.weight ||
                !confirmationGroup?.totalCartons ||
                (isLand
                    ? !confirmationGroup?.scheduledDeliverydate
                    : !confirmationGroup?.teu || confirmationGroup?.teu <= 0)
            );
        }
    };

    const key = isLand ? 'Scheduled Delivery Date' : 'TEU Estimate';
    const validationMessage = (type: string) => {
        if (type === 'BCN') {
            return `Total CBM, Total Packs${
                isLand ? 'and Total Weight' : ', Total Weight and TEU Estimate'
            } must be greater than zero`;
            // +
            // key +
            // ' must be between 0 and 99'
        } else if (type === 'FCL') {
            return `Total CBM, Total Packs${
                isLand ? 'and Total Weight' : ', Total Weight and TEU Estimate'
            } must be greater than zero`;
            // +
            // key +
            // ' must be between 1 and 99'
        } else {
            return `Total CBM, Total Packs and Total Weight must be greater than zero`;
        }
    };

    const CustomInput = React.forwardRef((props: any, ref: any) => {
        const format = props.format ? props.format : 'DD-MM-YYYY';
        const placeholder = format.toLowerCase();
        return (
            <div className="modal-input-field-holder date-input-holder">
                <input
                    style={{ width: '150px' }}
                    className={`modal-input input-text with-border date-input ${
                        props.disabled === false ? 'input-date-cursor' : ''
                    }`}
                    type="text"
                    name={props.fieldName}
                    readOnly={props.disabled ? false : true}
                    disabled={props.disabled ? true : false}
                    onClick={props.onClick}
                    placeholder={placeholder}
                    ref={ref}
                    value={props.value ? moment(props.value).format(format) : ''}
                />
                <svg style={{ top: '4px' }} className="svg-icon calendar-icon">
                    <use xlinkHref="#calendarIcon">
                        <title>Calendar</title>
                    </use>
                </svg>
            </div>
        );
    });

    const coloaderCondition = (confirmationGroup: ContainerModePoDataDetails) => {
        const cgStatus = confirmationGroup.status?.toLowerCase();
        return cgStatus === PoContainerStatus.InBookingProposal || cgStatus === PoContainerStatus.Booked ? true : false;
    };

    // const skipPOLine = headerData?.isHeaderOnlyPo;
    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';

    return (
        <div className="main-wrapper container product-details-confirmation ">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={`${poName}`}
                    firstUrl={`${poUrl}`}
                    secondName="PO Details"
                    secondUrl={`/purchase_order/${id}/product_details_confirmation`}
                />
                <PurchaseOrderHeader id={id} />
                <PODetailsHeaderTabs />
            </div>
            <div className="content-section main-wrapper ">
                {isLoading && (
                    <div style={{ zIndex: 101 }} className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="container-mode">
                    {containerData?.length === 0 && (
                        <div className="grid-no-content ">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">
                                Confirmation groups will be visible only after prior approvals
                            </p>
                        </div>
                    )}
                    {containerData?.map((res: ContainerModePoDataDetails) => (
                        <React.Fragment key={res?.confirmationGroupId}>
                            <div
                                title={validations(res) ? validationMessage(res?.type) : ''}
                                className={`container-mode-card ${validations(res) ? 'container-error-card' : ''}`}
                            >
                                {validations(res) ? (
                                    <div className="error-message-card">
                                        {res?.type === 'LCL' || res?.type === 'AIR'
                                            ? 'Total CBM, Total Packs and Total Weight Required'
                                            : 'Total CBM, Total Packs, Total Weight and ' + key + ' Required'}
                                    </div>
                                ) : null}
                                <div className="container-mode__header">
                                    <div className="container-mode__header_sub">
                                        {res?.type === 'LCL' ? (
                                            <div className="modal-toast light-blue">LCL</div>
                                        ) : res?.type === 'FCL' ? (
                                            <div className="modal-toast dark-blue">FCL</div>
                                        ) : res?.type === 'BCN' ? (
                                            <div className="modal-toast dark-info-blue">BCN</div>
                                        ) : res?.type === 'AIR' ? (
                                            <div className="modal-toast dark-info-blue">AIR</div>
                                        ) : res?.type === 'FTL' ? (
                                            <div className="modal-toast dark-blue">FTL</div>
                                        ) : res?.type === 'LTL' ? (
                                            <div className="modal-toast dark-blue">LTL</div>
                                        ) : (
                                            <div className="modal-toast dark-info-blue">-</div>
                                        )}
                                        {profileType !== 'vendor' &&
                                            headerData?.poStatus?.toLowerCase() !== 'cancelled' && (
                                                <button
                                                    className="action-btn app-btn app-btn-secondary"
                                                    title="Remove Confirmation Group"
                                                    type="button"
                                                    onClick={(e) => {
                                                        setDeleteModalView(true);
                                                        setCurrentCG(res?.confirmationGroupId);
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#deleteIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                        {res?.type === 'BCN' &&
                                            ['agent', 'forwarder'].includes(profileType) &&
                                            PoContainerStatus.ConfirmedForBooking?.toLowerCase() ===
                                                res?.status?.toLowerCase() &&
                                            !validations(res) &&
                                            headerData?.poStatus?.toLowerCase() !== 'cancelled' && (
                                                <button
                                                    className="action-btn app-btn app-btn-secondary"
                                                    title="Release Confirmation Group"
                                                    type="button"
                                                    onClick={(e) => {
                                                        setReleaseModalView(true);
                                                        setCurrentCG(res?.confirmationGroupId);
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <svg className="svg-icon export-icon">
                                                        <use xlinkHref="#exportIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                    </div>
                                    {poContainerStatusPillList(res?.status)}
                                </div>
                                <div className="container-mode__body">
                                    <div className="container-mode__body_title">
                                        <Link
                                            to={
                                                checkErrorStatus(res) && res?.type === 'LCL'
                                                    ? ''
                                                    : `/purchase_order/${id}/container_mode_selection/${res.confirmationGroupId}`
                                            }
                                            className="confirmation-title bp-link"
                                        >
                                            {res?.confirmationGroupName}
                                        </Link>
                                        <div style={{ display: 'grid' }}>
                                            {res?.bpForCGO?.map((val) => (
                                                <Link
                                                    to={`${
                                                        res?.type === 'BCN'
                                                            ? '/bcnBookingList'
                                                            : res?.type === 'LCL'
                                                            ? '/lclbooking_list'
                                                            : res?.type === 'AIR'
                                                            ? '/air_booking_list'
                                                            : '/booking_list'
                                                    }/${val?.bpPk}/POSelection`}
                                                    className="confirmation-title bp-link"
                                                >
                                                    {val?.bpNumber}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                    <div className="confirmation-grid">
                                        <div className="confirmation-card">
                                            <div className="layout-title">Goods Ready Date : </div>
                                            <div className="layout-details">
                                                {moment(res?.goodsReadyDate).format('DD-MM-YYYY')}
                                            </div>
                                        </div>
                                        {/* <div className="confirmation-card">
                                            <div className="layout-title">CBM :</div>
                                            <div className="layout-details">{res?.cbm}</div>
                                        </div> */}
                                        <div className="confirmation-card">
                                            <div className="layout-title">Destination :</div>
                                            <div className="layout-details">{res?.destinationPort}</div>
                                        </div>
                                        <div className="confirmation-card">
                                            <div className="layout-title">PO lines :</div>
                                            <div className="layout-details">{res?.poLines}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-mode__body container-mode__body__sub">
                                    <div className="confirmation-grid grid-rows-confirmation">
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="confirmation-card"
                                        >
                                            <div className="layout-title">Total CBM :</div>
                                            <div className="layout-details">
                                                <input
                                                    type="number"
                                                    className={`input-text ${
                                                        res?.isEditable ? 'with-border' : 'read-only'
                                                    }`}
                                                    readOnly={
                                                        !res?.isEditable ||
                                                        !checkPermission('enrich', 'po') ||
                                                        headerData?.poStatus?.toLowerCase() === 'cancelled'
                                                    }
                                                    value={res.cbm}
                                                    onChange={onInputChange(res.confirmationGroupId)}
                                                    onKeyDown={(e) =>
                                                        ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                                                    }
                                                    name="cbm"
                                                    min={1}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="confirmation-card"
                                        >
                                            <div className="layout-title">Total Packs :</div>
                                            <div className="layout-details">
                                                <input
                                                    type="number"
                                                    className={`input-text ${
                                                        res?.isEditable ? 'with-border' : 'read-only'
                                                    }`}
                                                    readOnly={
                                                        !res?.isEditable ||
                                                        !checkPermission('enrich', 'po') ||
                                                        headerData?.poStatus?.toLowerCase() === 'cancelled'
                                                    }
                                                    value={res.totalCartons == null ? '' : res.totalCartons}
                                                    onChange={onInputChange(res.confirmationGroupId)}
                                                    onKeyDown={(e) =>
                                                        ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                                                    }
                                                    name="totalCartons"
                                                    min={1}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="confirmation-card"
                                        >
                                            <div className="layout-title">Total Weight :</div>
                                            <div className="layout-details">
                                                <input
                                                    type="number"
                                                    className={`input-text ${
                                                        res?.isEditable ? 'with-border' : 'read-only'
                                                    }`}
                                                    readOnly={
                                                        !res?.isEditable ||
                                                        !checkPermission('enrich', 'po') ||
                                                        headerData?.poStatus?.toLowerCase() === 'cancelled'
                                                    }
                                                    value={res.weight}
                                                    onChange={onInputChange(res.confirmationGroupId)}
                                                    onKeyDown={(e) =>
                                                        ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                                                    }
                                                    name="weight"
                                                    min={1}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-mode__body container-mode__body__sub">
                                    <div className="confirmation-grid grid-rows-confirmation">
                                        <div
                                            onClick={(e) => {
                                                e.preventDefault();
                                            }}
                                            className="confirmation-card"
                                        >
                                            <div className="layout-title">Total Quantity :</div>
                                            <div className="layout-details">
                                                <input
                                                    type="number"
                                                    className={`input-text ${
                                                        res?.isEditable ? 'with-border' : 'read-only'
                                                    }`}
                                                    readOnly={
                                                        !res?.isEditable ||
                                                        !checkPermission('enrich', 'po') ||
                                                        headerData?.poStatus?.toLowerCase() === 'cancelled'
                                                    }
                                                    value={res.totalCGQty}
                                                    onChange={onInputChange(res.confirmationGroupId)}
                                                    onKeyDown={(e) =>
                                                        ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                                                    }
                                                    name="totalCGQty"
                                                    min={0}
                                                />
                                            </div>
                                        </div>
                                        {isLand && (
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                                className="confirmation-card"
                                            >
                                                <div className="layout-title">Scheduled Delivery Date :</div>
                                                <div className="layout-details">
                                                    <DatePicker
                                                        name="scheduledDeliverydate"
                                                        readOnly={
                                                            !res?.isEditable ||
                                                            !checkPermission('enrich', 'po') ||
                                                            headerData?.poStatus?.toLowerCase() === 'cancelled'
                                                        }
                                                        onChange={(value: any) =>
                                                            onInputDateChange(res.confirmationGroupId, value)
                                                        }
                                                        selected={
                                                            (res?.scheduledDeliverydate &&
                                                                new Date(res?.scheduledDeliverydate)) ||
                                                            null
                                                        }
                                                        peekNextMonth
                                                        showMonthDropdown
                                                        showYearDropdown
                                                        customInput={
                                                            <CustomInput
                                                                // format={format}
                                                                fieldName={'scheduledDeliverydate'}
                                                                // disabled={disabled}
                                                            />
                                                        }
                                                        dropdownMode="select"
                                                    />
                                                </div>
                                            </div>
                                        )}
                                        {res?.type !== 'LCL' && res?.type !== 'AIR' && !isLand && (
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                                className="confirmation-card"
                                            >
                                                <div className="layout-title">TEU Estimate :</div>
                                                <div className="layout-details">
                                                    <input
                                                        type="number"
                                                        // className={`input-text ${
                                                        //     res?.isEditable ? 'with-border' : 'read-only'
                                                        // }`}
                                                        // readOnly={!res?.isEditable || !checkPermission('enrich', 'po')}
                                                        readOnly={true}
                                                        className="input-text read-only"
                                                        value={res.teu}
                                                        onChange={onInputChange(res.confirmationGroupId)}
                                                        onKeyDown={(e) =>
                                                            ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
                                                        }
                                                        name="teu"
                                                        min={res.type === 'BCN' ? 0 : 1}
                                                        // max={99}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {res?.type === 'LCL' && (
                                    <div className="container-mode__body container-mode__body__sub">
                                        <div className="confirmation-grid grid-rows-confirmation">
                                            <div
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                                className="confirmation-card"
                                                style={{ width: '100%' }}
                                            >
                                                <div className="layout-title">Coloader :</div>
                                                <div className="modal-input-field-holder">
                                                    <SearchableSelectBox
                                                        data={filterdColoaderData(res)}
                                                        placeholder="Select Coloader"
                                                        setStateValue={(value: string) => {
                                                            const filtered = coloaderData?.find(
                                                                (res: any) => res?.value === value
                                                            );
                                                            onColoaderChange(res.confirmationGroupId, filtered);
                                                        }}
                                                        isDisabled={
                                                            !res?.isEditable ||
                                                            !checkPermission('enrich', 'po') ||
                                                            coloaderCondition(res) ||
                                                            headerData?.poStatus?.toLowerCase() === 'cancelled'
                                                        }
                                                        onInputChangeFn={(
                                                            value: string,
                                                            actionMeta: InputActionMeta
                                                        ) => {
                                                            if (value?.length > 2) {
                                                                getColoader(value);
                                                            }
                                                        }}
                                                        onFocusFn={() => {
                                                            if (!res?.coLoaderCode) {
                                                                getColoader('');
                                                            }
                                                        }}
                                                        onClear={() => getColoader('')}
                                                        stateValue={res?.coLoaderCode}
                                                        fieldName="coloader"
                                                        menuPortalTarget={document.getElementById('outside_div')}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {res?.isEditable && headerData?.poStatus?.toLowerCase() !== 'cancelled' && (
                                    <div className="container-mode__body__sub buttons">
                                        <button
                                            onClick={(e) => {
                                                onReset(res.confirmationGroupId);
                                                e.preventDefault();
                                            }}
                                            disabled={checkPermission('enrich', 'po') ? false : true}
                                            type="button"
                                            className="app-btn app-btn-secondary"
                                            title="Reset"
                                        >
                                            Reset
                                        </button>
                                        <button
                                            onClick={(e) => {
                                                dispatch(
                                                    updateConfirmationGroup({
                                                        confirmationGroupId: res.confirmationGroupId,
                                                        cbm: res.cbm,
                                                        weight: res.weight,
                                                        totalCGQty: res.totalCGQty,
                                                        totalCartons: res.totalCartons,
                                                        teu: res?.type === 'LCL' || res?.type === 'AIR' ? 0 : res.teu,
                                                        coLoader: res?.type === 'LCL' ? res?.coLoader : undefined,
                                                        coLoaderCode:
                                                            res?.type === 'LCL' ? res?.coLoaderCode : undefined,
                                                        coLoaderId: res?.type === 'LCL' ? res?.coLoaderId : undefined,
                                                        scheduledDeliverydate: res?.scheduledDeliverydate
                                                            ? moment(res?.scheduledDeliverydate).format('DD-MM-YYYY')
                                                            : res?.scheduledDeliverydate,
                                                    })
                                                );
                                                e.preventDefault();
                                            }}
                                            type="button"
                                            className="app-btn app-btn-primary"
                                            title={!validations(res) ? 'Save' : validationMessage(res?.type)}
                                            disabled={
                                                !validations(res) && checkPermission('enrich', 'po') ? false : true
                                            }
                                        >
                                            Save
                                        </button>
                                    </div>
                                )}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
                {deleteModalView ? (
                    <DeleteReason
                        setModalView={setDeleteModalView}
                        PO_PK={id ?? ''}
                        CG_PK={currentCG}
                        navigate={containerData?.length === 1 ? true : false}
                    />
                ) : null}
                {releaseModalView ? <ReleaseCG setModalView={setReleaseModalView} CG_PK={currentCG} /> : null}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/purchase_order/${id}/${
                            headerData?.isHeaderOnlyPo ? 'shipment_information' : 'product_details_confirmation'
                        }`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Index;
