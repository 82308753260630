import React, { useEffect, useState, useReducer } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../POSelection.scss';
import BookingAdditionalInfo from '../BookingAdditionalInfo';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    clearFailure,
    resetLoader,
    // fetchAllQuotation,
    selectAdditionalDetailsError,
    selectAdditionalDetailsIsError,
    selectAdditionalDetailsSuccess,
    // fetchSingleQuotation,
    fetchCurrency,
    approveBp,
    selectAdditionalDetailsLoading,
    // updateViewStatus,
    selectgateInDateValidation,
    selectEnrichBooking,
    fetchEnrichBooking,
    // selectHouseBillDetails,
} from '../../../store/reducers/bookingProposalLand/additionalDetailsReducer';
import { error, Position, success } from '../../../utils/toast';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { useSelector } from 'react-redux';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposalLand/bookingProposalHeaderReducer';
import { RootState } from '../../../store/reducers';
import {
    fetchDeliveryAndContainerModes,
    fetchNacAdditionalDetails,
} from '../../../store/reducers/bookingProposalLand/additionalDetailsNacReducer';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import ShowForPermissions from '../../../ShowForPermissions';
import UnavailableModel from '../SelectedSailingSchelude/UnavailableModel';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];

const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { profileType, userName } = useUserProfile();
    const [reason, setReason] = useState('');
    const [displayModal, setdisplayModal] = useState(false);
    const [rejectionModalView, setRejectionModalView] = useState(false);
    const [reasonModalView, setReasonModalView] = useState(false);
    const [addDetailsModalView, setAddDetailsModalView] = useState(false);
    const [rejectBpModalView, setRejectBpModalView] = useState(false);
    const [reapproveBpModalView, setReapproveBpModalView] = useState(false);
    const [contractDetailsModalView, setContractDetailsModalView] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [rejectedBpForwarderModalView, setRejectedBpForwarderModalView] = useState(false);
    const [reasonRejection, setReasonRejection] = useState('');
    const [assignAgentModalView, setAssignAgentModalView] = useState(false);
    const [isShipmentOverride, setIsShipmentOverride] = useState(false);
    // const houseBillData = useAppSelector(selectHouseBillDetails);

    const initialState = {
        sellQuotationName: '',
        sellQuotationVersion: '',
        sellQuotationId: '',
        sellTotOriginCost: '',
        sellCurrency: '',
    };

    const [additionalDetails, updateAdditionalDetails] = useReducer(
        (state: typeof initialState, updates: any) => ({ ...state, ...updates }),
        initialState
    );

    const { initialContainerValues } = useSelector((state: RootState) => state.additionalDetailsNac);

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectAdditionalDetailsIsError);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const gateinVal = useAppSelector(selectgateInDateValidation);
    const additionalDetailsError = useAppSelector(selectAdditionalDetailsError);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const bpStatus = headerData.bpStatus;
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);

    // const agentIntegrationStatus = headerData.agentIntegrationStatus;

    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: id ? `/land_booking_list/${id}/POSelection` : '/land_booking_list/POSelection',
            disabled: false,
        },
        {
            title: 'Transport Schedule',
            selected: 0,
            path: `/land_booking_list/${id}/transport-schedule`,
            //disabled: !(headerData?.tabValues as any)?.po,
            disabled: false,
        },
        {
            title: 'Goods Received',
            selected: 1,
            path: `/land_booking_list/${id}/goods-received`,
            //disabled: !(headerData?.tabValues as any)?.sailing,
            disabled: false,
        },
    ];

    const submitBp = () => {
        const fromForwarderDraft =
            (headerData.bpStatus === 'Draft_Booking_Proposal' || headerData.bpStatus === 'Rejected_by_Forwarder') &&
            profileType === 'forwarder';
        dispatch(approveBp({ bpId: id, userName, profile: profileType, fromForwarderDraft }));
    };

    const rejectBp = () => {
        setRejectionModalView(true);
    };

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/land_booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    summaryStats={summaryStats}
                    backUrl={'/land_booking_list'}
                    handleClickCancel={handleClickCancel}
                    setIsShipmentOverride={setIsShipmentOverride}
                />
                {/* <div className="pageheading-holder">
                    <div className="back-btn-holder">
                        <Link to="/land_booking_list">
                            <button className="back-btn app-btn">
                                <svg className="svg-icon back-icon">
                                    <use href="#previousArrowIcon">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </button>
                        </Link>
                        <h2 className="page-heading">{headerData.bpNumber}</h2>
                    </div>
                </div> */}
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                />
                <BookingProcessTab navData={navData} />
            </div>

            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper bo-additional-detail">
                <div className="main-wrapper"></div>
            </div>
            {showBookingSideBar && <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar} />}
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {(() => {
                        if (profileType === 'forwarder') {
                            return (
                                <>
                                    {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <ShowForPermissions permission="create" type="bp">
                                            <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                                <span className="button-text footer-button-text">Save as Draft</span>
                                            </a>
                                        </ShowForPermissions>
                                    )} */}
                                    <Link
                                        title="Previous"
                                        className="app-btn app-btn-secondary footer-btn"
                                        to={`/land_booking_list/${id}/SailingSelectionDetails`}
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </Link>

                                    <Link
                                        title="Next"
                                        className="app-btn app-btn-primary footer-btn"
                                        to={`/land_booking_list/${id}/container-allocation`}
                                    >
                                        <span className="button-text footer-button-text">Next</span>
                                    </Link>

                                    {/* {(headerData.bpStatus === 'Draft_Booking' ||
                                        headerData.bpStatus === 'Booked' ||
                                        headerData.bpStatus === 'Ready_for_Booking') && (
                                        <>
                                            {enrichBooking?.bookingRefNum ? (
                                                <Link
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    to={`/land_booking_list/${id}/container-allocation`}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </Link>
                                            ) : (
                                                <button
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    disabled={true}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </button>
                                            )}
                                        </>
                                    )} */}
                                </>
                            );
                        } else if (profileType === 'client') {
                            return (
                                <>
                                    {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                            <span className="button-text footer-button-text">Save as Draft</span>
                                        </a>
                                    )} */}
                                    <Link
                                        title="Previous"
                                        className="app-btn app-btn-secondary footer-btn"
                                        to={`/land_booking_list/${id}/SailingSelectionDetails`}
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </Link>
                                    <Link
                                        title="Next"
                                        className="app-btn app-btn-primary footer-btn"
                                        to={`/land_booking_list/${id}/container-allocation`}
                                    >
                                        <span className="button-text footer-button-text">Next</span>
                                    </Link>
                                    {headerData.bpStatus === 'Awaiting_Client_Approval' && (
                                        <ShowForPermissions permission="approve" type="bp">
                                            <a
                                                onClick={() => rejectBp()}
                                                title="Reject Proposal"
                                                className="app-btn app-btn-secondary footer-btn"
                                            >
                                                <span className="button-text footer-button-text">Reject Proposal</span>
                                            </a>
                                            <button
                                                onClick={() => submitBp()}
                                                title="Approve Proposal"
                                                disabled={headerData.gateInDateExpired ? true : false}
                                                className="app-btn app-btn-primary footer-btn"
                                            >
                                                <span className="button-text footer-button-text">Approve Proposal</span>
                                            </button>
                                        </ShowForPermissions>
                                    )}
                                    {/* {(headerData.bpStatus === 'Draft_Booking' ||
                                        headerData.bpStatus === 'Booked' ||
                                        headerData.bpStatus === 'Ready_for_Booking') && (
                                        <>
                                            {enrichBooking?.bookingRefNum ? (
                                                <Link
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    to={`/land_booking_list/${id}/container-allocation`}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </Link>
                                            ) : (
                                                <button
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    disabled={true}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </button>
                                            )}
                                        </>
                                    )} */}
                                </>
                            );
                        } else {
                            return (
                                <>
                                    {/* {profileType === 'agent' &&
                                        (bpStatus === 'Draft_Booking_Proposal' ||
                                            bpStatus === 'Rejected_by_Forwarder' ||
                                            bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                            // <>
                                            //     {bpStatus === 'Booked' || bpStatus === 'Cancelled' ? null : (
                                            <ShowForPermissions type="bp" permission="create">
                                                <button
                                                    title="Cancel Booking Proposal"
                                                    className="app-btn app-btn-secondary footer-btn"
                                                    onClick={() => {
                                                        setShowBookingCancelModal(true);
                                                    }}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        Cancel Booking Proposal
                                                    </span>
                                                </button>
                                            </ShowForPermissions>
                                            //     )}
                                            // </>
                                        )} */}
                                    {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <ShowForPermissions type="bp" permission="create">
                                            <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                                <span className="button-text footer-button-text">Save as Draft</span>
                                            </a>
                                        </ShowForPermissions>
                                    )} */}
                                    <Link
                                        title="Previous"
                                        className="app-btn app-btn-secondary footer-btn"
                                        to={`/land_booking_list/${id}/SailingSelectionDetails`}
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </Link>
                                    <Link
                                        title="Next"
                                        className="app-btn app-btn-primary footer-btn"
                                        to={`/land_booking_list/${id}/container-allocation`}
                                    >
                                        <span className="button-text footer-button-text">Next</span>
                                    </Link>

                                    {/* {profileType === 'agent' &&
                                        (bpStatus === 'Booked' ||
                                            bpStatus === 'Ready_for_Booking' ||
                                            bpStatus === 'Draft_Booking') && (
                                            <ShowForPermissions type="bp" permission="create">
                                                {agentIntegrationStatus !== true && (
                                                    <button
                                                        title="Cancel Booking"
                                                        className="app-btn app-btn-secondary footer-btn"
                                                        onClick={() => {
                                                            setShowBookingCancelModal(true);
                                                        }}
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Cancel Booking
                                                        </span>
                                                    </button>
                                                )}
                                            </ShowForPermissions>
                                        )} */}

                                    {/* {(headerData.bpStatus === 'Draft_Booking' ||
                                        headerData.bpStatus === 'Booked' ||
                                        headerData.bpStatus === 'Ready_for_Booking') && (
                                        <>
                                            {enrichBooking?.bookingRefNum ? (
                                                <Link
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    to={`/land_booking_list/${id}/container-allocation`}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </Link>
                                            ) : (
                                                <button
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    disabled={true}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </button>
                                            )}
                                        </>
                                    )} */}
                                </>
                            );
                        }
                    })()}
                </div>
            </div>
            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            {displayModal && <UnavailableModel setdisplayModal={setdisplayModal} />}
        </div>
    );
};
export default Index;
