import { createSlice } from '@reduxjs/toolkit';
import { vendortTableHeaders } from '../../utils/constants';

export const dataSlice = createSlice({
    name: 'vendorList',
    initialState: {
        vendors: [],
        isLoading: true,
        isLoading2: true,
        currentPage: 0,
        columnStatus: vendortTableHeaders.status,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        filterValues: {},
        itemsInList: 10,
        selectedFilter: null,
        vendorLoading: false,
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchVendorListData: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload?.columnStatus;
            state.currentPage = action.payload?.currentPage;
            state.vendors = action.payload.vendors?.vendorResponseLists;
            state.totalPages = action.payload.vendors?.totalNoOfPages;
            state.totalItems = action.payload.vendors?.totalNoOfItems;
            state.filterValues = action.payload.vendors?.distinctColumnValues;
            const num = action.payload.vendors?.vendorResponseLists?.reduce(
                (prev: number, current: any) => prev + current.childVendors?.length,
                0
            );

            state.itemsInList = action.payload.vendors?.vendorResponseLists?.length + (num || 0);
            state.isLoading = false;
            state.isLoading2 = true;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
            state.isLoading2 = action.payload;
        },
        isLoadingFnVendor: (state, action) => {
            state.vendorLoading = action.payload;
        },
        searchVendorData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload?.columnStatus;
            state.vendors = action.payload.vendors?.content;
            state.totalPages = action.payload.vendors?.totalPages;
            state.totalItems = action.payload.vendors?.totalElements;
            state.filterValues = action.payload.vendors?.distinctColumnValues;
            const num = action.payload.vendors?.content?.reduce(
                (prev: number, current: any) =>
                    current.childVendors?.length ? prev + current.childVendors?.length : prev,
                0
            );
            state.itemsInList = action.payload.vendors?.content?.length + (num || 0);
            state.isLoading = false;
            state.isLoading2 = false;
        },
        setselectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
});

export const {
    fetchVendorListData,
    setPageCount,
    isLoadingFn,
    setDefault,
    searchVendorData,
    setselectedFilter,
    setCurrentPage,
    isLoadingFnVendor,
} = dataSlice.actions;
export default dataSlice.reducer;
