import { useUserProfile } from 'src/hooks/useUserProfile';

const { isBlp, profileType } = useUserProfile();

export const checkPermissions = (permissions: any) => {
    /* eslint no-var: off */
    let flag = true;
    for (var i = 0; i < permissions?.length; i++) {
        if (
            ((permissions[i].access
                .toLowerCase()
                .split(/\s+|\./)
                .includes('vendor') ||
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('client') ||
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('shipping') ||
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('agent')) &&
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('view')) ||
            permissions[i].access === 'AllAdmin'
        ) {
            //return true;
            flag = false;
        } else {
        }
    }
    return flag;
};
export const checkPermissionsWrap = (permissions: any, permission: any, type: any) => {
    /* eslint no-var: off */
    let flag = false;
    for (var i = 0; i < permissions?.length; i++) {
        if (type === 'vendor') {
            if (localStorage.getItem('vendorView')) {
                flag = true;
            }
        }
        if (type === 'client') {
            if (localStorage.getItem('clientView')) {
                flag = true;
            }
        }
        if (
            type?.toLowerCase() === 'bp' ||
            type?.toLowerCase() === 'ao' ||
            type?.toLowerCase() === 'users' ||
            type?.toLowerCase() === 'roles' ||
            type?.toLowerCase() === 'inbound'
        ) {
            if (
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(type.toLowerCase())
            ) {
                flag = true;
            }
        }
        if (
            (permissions[i].access
                .toLowerCase()
                .split(/\s+|\./)
                .includes(permission.toLowerCase()) &&
                permissions[i].access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(type.toLowerCase())) ||
            permissions[i].access === 'AllAdmin'
        ) {
            //added condition as inbound po permission is over riding po permission
            if (type.toLowerCase() === 'po') {
                if (
                    !permissions[i].access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes('warehouse')
                ) {
                    if (permissions[i].access.toLowerCase().split(/\s+|\./).length === 2) {
                        flag = true;
                    } else {
                        flag = false;
                    }
                }
            } else if (type.toLowerCase() === 'receipt') {
                const blp = permissions.find((x: any) => x.permissionTypeName === 'BLP' && x.access === 'View Receipt');
                if (blp && ((profileType === 'agent' && isBlp) || profileType !== 'agent')) {
                    flag = true;
                } else {
                    flag = false;
                }
            } else if (profileType === 'client' || profileType === 'vendor') {
                if (permissions[i].access?.toLowerCase() === 'view vendor') {
                    flag = true;
                }
            } else {
                //return true;
                flag = true;
            }
        } else {
        }
    }
    return flag;
};

export const checkRoles = (layoutRoles: any) => {
    let profiles: any = [];
    if (localStorage.getItem('user')) {
        const userRoles = JSON.parse(localStorage.getItem('user') || '');
        userRoles?.roles?.forEach((item: any) => {
            profiles = [...profiles, item.profile?.toUpperCase()];
        });
    }

    const intersections = layoutRoles?.filter((e: any) => profiles.indexOf(e) !== -1);
    return intersections?.length > 0 ? true : false;
};
