import axios from 'axios';
// import moment from 'moment';
// import { getOrganisationData } from '../../../utils';
import { del, get, post, put } from '../../HttpMethods';
import fileDownload from 'js-file-download';

export const fetchProductAllocationListDetailsApi = async (payload: any) => {
    try {
        const sortColumnQuery = payload.sortColumn ? `&sortColumn=${payload.sortColumn}` : '';
        const sortOrderQuery =
            payload.sortColumn && typeof payload.sortOrder === 'boolean'
                ? `&sortOrder=${payload.sortOrder ? 'DESC' : 'ASC'}`
                : '';
        const data = await post(
            `api/ord/ao/product/list?aoId=${payload.aoId}${sortColumnQuery}${sortOrderQuery}`,
            {
                skuSearchKeyword: null,
                destinationPort: payload.destinationPort ? payload.destinationPort : null,
                filter: null,
            },
            null
        );
        const productListAllocationOrderList = data?.info?.productListAllocationOrderList?.map(
            (res: any, index: number) => ({
                ...res,
                currency: res.currency?.currencycode,
                editMode: false,
                indexValue: index,
                balanceQty: res.availableToOrder,
            })
        );
        data.info.productListAllocationOrderList = productListAllocationOrderList;
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchSkuListDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/clients/products?clientCode=${payload.clientCode}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchAllocationSkuListDetailsApi = async (payload: any) => {
    try {
        const data = await get(
            `api/ord/ao/skus?clientCode=${payload.clientCode}&blpCode=${payload.blpAgentCode}&allocationOrderId=${payload.allocationOrderId}`,
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createProductAllocationDetailsApi = async (payload: any) => {
    // const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    // const listOfUserOrganisations = getOrganisationData();
    // const indexVal = localStorage.getItem('roleIndex');
    // const roles = userData?.roles[indexVal as any];
    try {
        // for (const key in payload) {
        //     if (key === 'goodsReadyDate' || key === 'projWhouseETA') {
        //         payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
        //     }
        // }
        // const newPayload = { product: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/ao/product', payload, null);
        data.type = 'create';
        data.indexValue = payload?.indexValue;
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateProductAllocationDetailsApi = async (payload: any) => {
    // const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    // const listOfUserOrganisations = getOrganisationData();
    // const indexVal = localStorage.getItem('roleIndex');
    // const roles = userData?.roles[indexVal as any];
    // for (const key in payload) {
    //     if (key === 'goodsReadyDate' || key === 'projWhouseETA') {
    //         payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
    //     }
    // }
    try {
        // const newPayload = { product: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await put(`api/ord/ao/product?poProductId=${payload.poProductId}`, payload);
        data.type = 'update';
        data.indexValue = payload?.indexValue;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const removeProductAllocationDetailsApi = async (payload: any) => {
    try {
        const data = await del(
            `api/ord/ao/product/${payload.poProductId}?fullName=${payload.fullName}&userName=${payload.userName}`,
            null
        );
        data.type = 'delete';
        data.indexValue = payload?.indexValue;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchDistinctValuesApi = async (payload: any) => {
    try {
        const data = await post(
            `api/ord/ao/product/distinct?aoId=${payload.aoId}`,
            {
                skuSearchKeyword: null,
                destinationPort: payload.destinationPort ? payload.destinationPort : null,
                filter: null,
            },
            null
        );
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCurrencyDetailsApi = async (payload: any) => {
    try {
        let data = await get('api/bkg/bp/basicdetails/ordercurrency', null);
        let result: any;
        if (payload.currency) {
            data = data?.info.filter((res: any) => payload.currency === res.code);
            result = data?.map((res: any) => ({ value: res.code, label: res.code }));
        } else {
            result = data?.info.map((res: any) => ({ value: res.code, label: res.code }));
        }
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchPortDetailsApi = async (payload: any) => {
    try {
        const data = await get(`/api/${payload.service ?? 'mgt'}/search/unloco?search=${payload?.key}`, null);
        if (payload?.type === 'PO') {
            return { data, status: 200 };
        } else {
            const result = data?.map((res: any) => ({
                value: `${res.portName} (${res.portCode})`,
                label: `${res.portName} (${res.portCode})`,
                extraField: res.portId,
                country: res.portCountry,
            }));
            return { data: result, status: 200 };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchDestinationAddressDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/po/clientaddress?orgCode=${payload.orgCode}`, null);
        data?.info.map((res: any) => {
            let address = res.addressLine1;
            if (res.addressLine2) {
                address = address + ', ' + res.addressLine2;
            }
            if (res.addressLine3) {
                address = address + ', ' + res.addressLine3;
            }
            if (res.city) {
                address = address + ', ' + res.city;
            }

            if (res.state) {
                address = address + ', ' + res.state;
            }

            if (res.postcode) {
                address = address + ', ' + res.postcode;
            }
            return (res.fullAddress = address);
        });
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const exportAoProductAllocationList = async (type: string, filterDetails: any, _profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(`${process.env.REACT_APP_BASE_URL}/api/ord/ao/export/products?aoId=${filterDetails.aoId}&type=${type}`, {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                skuSearchKeyword: filterDetails.skuSearchKeyword,
                destinationPort: filterDetails.destinationPort,
                filter: filterDetails.filter,
                sortOrder: filterDetails?.sortOrder,
                sortColumn: filterDetails?.sortColumn,
            }),
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'AllocationOrdersProduct.xlsx' : 'AllocationOrdersProduct.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
