import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchPermissions } from '../store/reducers/permissionReducer';

const FetchPermission = () => {
    const dispatch = useDispatch();
    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;
    localStorage.setItem('user', JSON.stringify(data));
    useEffect(() => {
        if (username) {
            dispatch(fetchPermissions(username));
        }
    }, []);

    return <div></div>;
};

export default FetchPermission;
