import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { deleteDocument, getDocumentTypes, uploadDocument, uploadRateDocument } from 'src/services/apis/documentApi';
import { cixUploadDoc, fetchDocumentTypes, cixDeleteDoc, cixUploadRateDoc } from '../reducers/documentReducer';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* fetchDocumentType({ payload }: { payload: any; type: any }) {
    try {
        const response: any[] = yield call(getDocumentTypes, payload);
        let dropdownList = [] as any;
        if (response)
            dropdownList = response.map((r) => {
                return { label: r.CD_UserDefinedText4, value: r.CD_Key };
            });
        yield put({ type: fetchDocumentTypes, payload: dropdownList });
    } finally {
    }
}
//postDocumentType
function* postDocumentType({ payload }: { payload: any; type: any }) {
    try {
        const response: any[] = yield call(uploadDocument, payload);
        yield put({ type: cixUploadDoc, payload: response });
    } finally {
    }
}

//postRateDocType
function* postRateDocType({ payload }: { payload: any; type: any }) {
    try {
        const response: any[] = yield call(uploadRateDocument, payload);
        yield put({ type: cixUploadRateDoc, payload: response });
    } finally {
    }
}

//postDocumentType
function* removeDocument({ payload }: { payload: any; type: any }) {
    try {
        const response: any[] = yield call(deleteDocument, payload);
        yield put({ type: cixDeleteDoc, payload: response });
    } finally {
    }
}

function* documentSaga() {
    yield takeEvery('documents/fetchDocumentTypes', fetchDocumentType);
    // cixUploadDoc
    yield takeEvery('documents/cixUploadDoc', postDocumentType);
    yield takeEvery('documents/cixUploadRateDoc', postRateDocType);
    yield takeEvery('documents/cixDeleteDoc', removeDocument);
}
// Export the saga (data-saga)
export default documentSaga;
