import React, { useEffect } from 'react';
import moment from 'moment';
import '../../BookingProposal/ListGrid/list-grid.scss';
import { getDateDifference } from 'src/utils';
import { downloadFlightScheduleSell } from 'src/services/apis/bookingProposalAir/flightScheduleApi';
import { useAppSelector } from 'src/hooks';
import {
    selectFlightScheduleError,
    selectFlightScheduleIsError,
    selectFlightScheduleSuccess,
    clearFailure,
} from 'src/store/reducers/bookingProposalAir/flightScheduleReducer';
import { error, Position, success } from 'src/utils/toast';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../../hooks/useUserProfile';
interface Props {
    data: any;
    setData: any;
    setviewModal: any;
    selectedFlightSchedule: any;
    setSelectedFlightSchedule: any;
    headerData: any;
}
const Index: React.FC<Props> = ({
    data,
    setData,
    setviewModal,
    selectedFlightSchedule,
    setSelectedFlightSchedule,
    headerData,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const getActiveDisabledStatus = (isSelected: any) => {
        if (
            headerData.bpStatus === 'Ready_for_Booking' ||
            headerData.bpStatus === 'Booked' ||
            headerData.bpStatus === 'Draft_Booking'
        ) {
            // if (isSelected) {
            return 'active';
            // } else {
            //     return 'disabled';
            // }
        }
        return '';
    };
    const combineDateTime = (date: string, time: string) => {
        return moment(date).format('YYYY-MM-DD') + ' ' + time;
    };

    const isError = useAppSelector(selectFlightScheduleIsError);
    const flightScheduleConfirmationSuccess = useAppSelector(selectFlightScheduleSuccess);
    const flightScheduleConfirmationError = useAppSelector(selectFlightScheduleError);
    const { profileType } = useUserProfile();

    useEffect(() => {
        if (isError) {
            error(flightScheduleConfirmationError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (
            flightScheduleConfirmationSuccess?.status === 200 ||
            flightScheduleConfirmationSuccess?.status === 201
        ) {
            if (flightScheduleConfirmationSuccess?.data?.mode === 'selectSchedule') {
                success('Selected schedule has been approved', Position.TOP_RIGHT);
                setTimeout(() => navigate('/booking_list'), 1000);
            }
            dispatch(clearFailure([]));
        }
    }, [isError, flightScheduleConfirmationSuccess, flightScheduleConfirmationError]);

    return (
        <div className="lcl-sailing-container">
            <div className="booking-sailing-schelude-container air-booking-sailing-schelude">
                <div className="air-booking-flight-approval-grid">
                    <div className="selection-schelude-header-wrapper">
                        <div className="selection-heading-holder">
                            <h2 className="selection-heading">
                                Flight Schedules
                                {data.length !== 0 &&
                                    selectedFlightSchedule === '' &&
                                    profileType === 'client' &&
                                    headerData.bpStatus === 'Awaiting_Client_Approval' && (
                                        <span className="warning-text error-text">
                                            Please choose a schedule to proceed with approval
                                        </span>
                                    )}
                            </h2>
                            {/* <p className="selection-content">Add Sell Quote for the schedules for client approval</p> */}
                        </div>
                    </div>
                    <div className="view-list-grid air-flight-view-list-grid client-view">
                        {data.length === 0 && (
                            <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No Flight Schedules Available</p>
                            </div>
                        )}

                        {/* five-column-without-checkbox should take gridTemplateColumns: true ? '102px repeat(3, 1fr) 160px' */}
                        {/* six-column-with-checkbox should take grid-template-columns:45px repeat(4, 1fr) 160px; */}
                        <div
                            className={`list-grid ${
                                headerData.bpStatus === 'Awaiting_Client_Approval'
                                    ? 'six-column-with-checkbox'
                                    : 'five-column-without-checkbox'
                            }`}
                            style={{
                                gridTemplateColumns:
                                    headerData.bpStatus === 'Awaiting_Client_Approval'
                                        ? 'repeat(8, 1fr)'
                                        : 'repeat(7, 1fr)',
                            }}
                        >
                            {data
                                ?.filter?.((res: any) => res?.isRemoved !== true && res?.isRemovedByAgent !== true)
                                ?.map((res: any, index: number) => {
                                    const startDateTime = combineDateTime(res?.originEtdDate, res?.originEtdTime);
                                    const endDateTime = combineDateTime(res?.destEtaDate, res?.destEtaTime);
                                    const dateDifference = getDateDifference(startDateTime, endDateTime);
                                    return (
                                        <React.Fragment key={index}>
                                            {headerData.bpStatus === 'Awaiting_Client_Approval' && (
                                                <div
                                                    className={`grid-child-item col-grid-checkbox-item ${getActiveDisabledStatus(
                                                        res?.isSelected
                                                    )}`}
                                                >
                                                    <div className="grid-child-data">
                                                        <div className="checkbox-item">
                                                            <label
                                                                className="app-check-wrapper"
                                                                onClick={() => {
                                                                    // selectedValue(res);
                                                                    // setselectedColumn(selectedRange.startDate);
                                                                }}
                                                            >
                                                                <input
                                                                    type="radio"
                                                                    className="checkbox-input"
                                                                    name="grid-radio-group"
                                                                    checked={
                                                                        selectedFlightSchedule === res?.baId
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    onChange={() =>
                                                                        setSelectedFlightSchedule(res?.baId)
                                                                    }
                                                                ></input>
                                                                <div className="checkmark">
                                                                    <svg className="svg-icon notification-circle-icon">
                                                                        <use href="#circleIcon">
                                                                            <title>check mark</title>
                                                                        </use>
                                                                    </svg>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {/* disabled and active classes are used */}
                                            <div
                                                className={`grid-child-item col-name ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon aeroplane-icon">
                                                            <use href="#aeroplaneIcon">
                                                                <title>Aeroplane</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">{res?.carrier}</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            {res?.flightNo}
                                                            {res?.bookingProposalAirSchedule?.length > 0 && (
                                                                <>
                                                                    ,
                                                                    <span>
                                                                        {' '}
                                                                        +{res?.bookingProposalAirSchedule?.length}
                                                                    </span>
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-destination ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data">
                                                    <div className="des-progress-bar">
                                                        <div className="destination begin-journey">
                                                            <span className="point1">ETD</span>
                                                            {/*<div className="date-time">
                                                                {moment
                                                                    .utc(
                                                                        moment(res?.originEtdDate).format(
                                                                            'YYYY-MM-DD'
                                                                        ) +
                                                                            ' ' +
                                                                            res?.originEtdTime
                                                                    )
                                                                    .local()
                                                                    .format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {moment
                                                                        .utc(
                                                                            moment(res?.originEtdDate).format(
                                                                                'YYYY-MM-DD'
                                                                            ) +
                                                                                ' ' +
                                                                                res?.originEtdTime,
                                                                            'YYYY-MM-DD HH:mm:ss'
                                                                        )
                                                                        .local()
                                                                        .format('HH:mm')}
                                                                </span>
                                                            </div>*/}
                                                            <div className="date-time">
                                                                {moment(
                                                                    moment(res?.originEtdDate).format('YYYY-MM-DD') +
                                                                        ' ' +
                                                                        res?.originEtdTime
                                                                ).format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {moment(
                                                                        moment(res?.originEtdDate).format(
                                                                            'YYYY-MM-DD'
                                                                        ) +
                                                                            ' ' +
                                                                            res?.originEtdTime,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    ).format('HH:mm')}
                                                                </span>
                                                            </div>
                                                            <span className="point2">{res?.originAirPortName}</span>
                                                        </div>
                                                        <div className="destination center-journey">
                                                            {res?.bookingProposalAirSchedule?.length === 1 ? (
                                                                <span className="center-point">
                                                                    {res?.bookingProposalAirSchedule?.length} Stop
                                                                </span>
                                                            ) : (
                                                                <span className="center-point">
                                                                    {res?.bookingProposalAirSchedule?.length} Stops
                                                                </span>
                                                            )}
                                                            <div className="point1">
                                                                {dateDifference?.days > 0
                                                                    ? `${dateDifference?.days}d`
                                                                    : ''}
                                                                {dateDifference?.hour > 0 ? (
                                                                    <span className="time-field">
                                                                        {` ${dateDifference?.hour}h`}
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {dateDifference?.minute > 0 ? (
                                                                    <span className="time-field">
                                                                        {` ${dateDifference?.minute}m`}
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="destination end-journey">
                                                            <span className="point1">ETA</span>
                                                            {/*<div className="date-time">
                                                                {moment
                                                                    .utc(
                                                                        moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                            ' ' +
                                                                            res?.destEtaTime
                                                                    )
                                                                    .local()
                                                                    .format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {moment
                                                                        .utc(
                                                                            moment(res?.destEtaDate).format(
                                                                                'YYYY-MM-DD'
                                                                            ) +
                                                                                ' ' +
                                                                                res?.destEtaTime,
                                                                            'YYYY-MM-DD HH:mm:ss'
                                                                        )
                                                                        .local()
                                                                        .format('HH:mm')}
                                                                </span>
                                                            </div>*/}
                                                            <div className="date-time">
                                                                {moment(
                                                                    moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                        ' ' +
                                                                        res?.destEtaTime
                                                                ).format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {moment(
                                                                        moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                            ' ' +
                                                                            res?.destEtaTime,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    ).format('HH:mm')}
                                                                </span>
                                                            </div>
                                                            <span className="point2">{res?.destinationPort}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-cost1 ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Payment Terms</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="sub-content">{res?.paymentTermCode}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-cost1 ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Total Cost</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="sub-content">
                                                                {res?.sellCost} {res?.sellCurrency}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-cost1 ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                                style={{ borderLeftWidth: '0px', borderRightWidth: '0px' }}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">UOM</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="sub-content">{res?.sellUomCode}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={`grid-child-item col-cost2 ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                                style={{
                                                    borderLeftWidth: '0px',
                                                    borderRightWidth: '0px',
                                                }}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Quote</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            <a
                                                                onClick={() =>
                                                                    downloadFlightScheduleSell(
                                                                        res?.sellSavedFileId,
                                                                        'SELL',
                                                                        res?.baId,
                                                                        res?.sellQuoteName
                                                                    )
                                                                }
                                                                className="buy-link"
                                                            >
                                                                {res?.sellQuoteName}
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-action ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                                style={{
                                                    paddingRight: '15px',
                                                }}
                                            >
                                                {(headerData.bpStatus === 'Ready_for_Booking' ||
                                                    headerData.bpStatus === 'Booked' ||
                                                    headerData.bpStatus === 'Draft_Booking') &&
                                                    res?.isSelected && (
                                                        <div className="item-count-status-holder">
                                                            <div className="grid-item-count-status status-ui success-status">
                                                                <span className="status-text">Selected</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                <a
                                                    className="more-link app-btn-secondary app-btn action-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                        setData(res);
                                                        setviewModal(true);
                                                    }}
                                                    title="View"
                                                >
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#eyeIcon">
                                                            <title>View</title>
                                                        </use>
                                                    </svg>
                                                </a>
                                            </div>
                                        </React.Fragment>
                                    );
                                })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
