import './profile-type.scss';
import 'src/assets/scss/components/_buttons.scss';
import CountBox from 'src/components/CountBox';
import { useEffect, useState } from 'react';
import ShowForPermissions from 'src/ShowForPermissions';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useDispatch, useSelector } from 'react-redux';
// import { fetchCountData } from 'src/store/reducers/countReducer';
import { useNavigate } from 'react-router-dom';
import { getOrganisationData } from 'src/utils';
import { RootState } from 'src/store/reducers';
// import { checkForPermissionCommon } from 'src/ShowForPermissions/checkForPermissionCommon';
// import { useSelector } from 'react-redux';
// import { RootState } from 'src/store/reducers';

const Index = (newcount?: any) => {
    const [Current, setCurrent] = useState('Client');
    const [vendorVisible, setvendorVisible] = useState(false);
    const [clientVisible, setclientVisible] = useState(false);
    const [user, setUser] = useState<any>({});
    const { count } = useSelector((state: RootState) => state);

    // const [carrierVisible, setcarrierVisible] = useState(false);
    const { profileType } = useUserProfile();
    const dispatch = useDispatch();
    // const location = useLocation();
    const navigate = useNavigate();
    const listOfUserOrganisations = getOrganisationData();
    // const { permissions } = useSelector((state: RootState) => state.permissionData);

    const onChangeTabs = (value: any) => {
        setCurrent(value);
    };

    useEffect(() => {
        let userDetails = null;
        let payload = null;
        if (profileType === 'client') {
            userDetails = listOfUserOrganisations['client'][0];
            payload = { clientOrgCode: userDetails?.orgCode, cvId: userDetails?.profilePK };
        }
        if (profileType === 'vendor') {
            userDetails = listOfUserOrganisations['vendor'][0];
            payload = { vendorOrgCode: userDetails?.orgCode, vcId: userDetails?.profilePK };
        }
        if (profileType === 'agent') {
            userDetails = listOfUserOrganisations['agent'][0];
            payload = { agentOrgCode: userDetails?.orgCode };
        }
        if (profileType === 'shipping') {
            userDetails = listOfUserOrganisations['sl'][0];
            payload = { slOrgCode: userDetails?.orgCode };
        }
        // dispatch(fetchCountData({ payload }));
    }, []);

    useEffect(() => {
        if (user) {
            if (
                profileType === 'forwarder' ||
                profileType === 'vendor' ||
                (profileType === 'client' && count.vendor && !checkAllValuesAreZero(count.vendor))
            ) {
                setvendorVisible(true);
                localStorage.setItem('vendorView', 'true');
            }
            if (
                profileType === 'forwarder' ||
                profileType === 'client' ||
                (profileType === 'vendor' && count.client && !checkAllValuesAreZero(count.client))
            ) {
                localStorage.setItem('clientView', 'true');
                setclientVisible(true);
            }
        }
    }, [user]);
    useEffect(() => {
        switch (profileType) {
            case 'agent':
                navigate('/agent');
                break;
            case 'shipping':
                navigate('/shippingline');
                break;
        }
        if (
            user &&
            count.client &&
            count.vendor &&
            checkAllValuesAreZero(count.client) &&
            !checkAllValuesAreZero(count.vendor) &&
            profileType === 'vendor'
        ) {
            navigate('/vendor');
        }
    }, [profileType, user]);

    function checkAllValuesAreZero(obj: any): any {
        return Object.values(obj).every((value: any) => {
            if (typeof value === 'object') {
                return checkAllValuesAreZero(value);
            }
            return value === 0;
        });
    }

    return (
        <div className="box-section-holder">
            {/* List type of profiles in here */}
            <div className="profile-type-holder box-nav-holder">
                {clientVisible && (
                    <CountBox
                        data={count?.client}
                        title={'Client'}
                        //isActive={Current === 'Client' && true}
                        setCurrent={onChangeTabs}
                        position="profile"
                    ></CountBox>
                )}
                {vendorVisible && (
                    <CountBox
                        data={count?.vendor}
                        title={'Vendor'}
                        //isActive={Current === 'Vendor' && true}
                        setCurrent={onChangeTabs}
                        position="vendor"
                        isFromVendor={true}
                    ></CountBox>
                )}
                {(profileType === 'forwarder' || profileType === 'agent') && (
                    <ShowForPermissions permission="view" type="agent">
                        <CountBox
                            data={count?.agent}
                            title={'Agent'}
                            //isActive={Current === 'Agent' && true}
                            setCurrent={onChangeTabs}
                            position="agent"
                        ></CountBox>
                    </ShowForPermissions>
                )}
                {(profileType === 'forwarder' || profileType === 'shipping') && (
                    <ShowForPermissions permission="view" type="shipping">
                        <CountBox
                            data={count?.shipping}
                            title={'Carrier'}
                            //isActive={Current === 'Carrier' && true}
                            setCurrent={onChangeTabs}
                            position="shippingline"
                        ></CountBox>
                    </ShowForPermissions>
                )}
                {/* TODO:Change type to coloader after roles and permission update*/}
                {profileType === 'forwarder' && (
                    <ShowForPermissions permission="view" type="coloader">
                        <CountBox
                            //TODO:Replace the dummy value with original value
                            data={count?.coloader}
                            title={'Coloader'}
                            //isActive={Current === 'Coloader' && true}
                            setCurrent={onChangeTabs}
                            position="coloader"
                        ></CountBox>
                    </ShowForPermissions>
                )}
                {/* <a className="box-nav active" href="javascript:void(0)">
                    <div className="box-nav-left">
                        <div className="box-title">CoLoader</div>
                        <div className="box-count">100</div>
                    </div>
                    <div className="box-nav-right">
                        <div className="box-section-active">
                            <div className="box-count">9</div>
                            <div className="box-title">Active</div>
                        </div>
                        <div className="box-section-inactive">
                            <div className="box-count">1</div>
                            <div className="box-title">Inactive</div>
                        </div>
                    </div>
                </a> */}
                {/* <a className="box-nav active" href="javascript:void(0)">
                    <div className="box-nav-left">
                        <div className="box-title">Client</div>
                        <div className="box-count">{count?.total}</div>
                    </div>
                    <div className="box-nav-right">
                        <div className="box-section-active">
                            <div className="box-count">{count?.active}</div>
                            <div className="box-title">Active</div>
                        </div>
                        <div className="box-section-inactive">
                            <div className="box-count">{count?.inactive}</div>
                            <div className="box-title">Inactive</div>
                        </div>
                    </div>
                </a>
                <a className="box-nav" href="javascript:void(0)">
                    <div className="box-nav-left">
                        <div className="box-title">Vendor</div>
                        <div className="box-count">100</div>
                    </div>
                    <div className="box-nav-right">
                        <div className="box-section-active">
                            <div className="box-count">50</div>
                            <div className="box-title">Active</div>
                        </div>
                        <div className="box-section-inactive">
                            <div className="box-count">50</div>
                            <div className="box-title">Inactive</div>
                        </div>
                    </div>
                </a>
                <a className="box-nav" href="javascript:void(0)">
                    <div className="box-nav-left">
                        <div className="box-title">Agent</div>
                        <div className="box-count">100</div>
                    </div>
                    <div className="box-nav-right">
                        <div className="box-section-active">
                            <div className="box-count">50</div>
                            <div className="box-title">Active</div>
                        </div>
                        <div className="box-section-inactive">
                            <div className="box-count">50</div>
                            <div className="box-title">Inactive</div>
                        </div>
                    </div>
                </a>

                <a className="box-nav" href="javascript:void(0)">
                    <div className="box-nav-left">
                        <div className="box-title">Shipping line</div>
                        <div className="box-count">100</div>
                    </div>
                    <div className="box-nav-right">
                        <div className="box-section-active">
                            <div className="box-count">50</div>
                            <div className="box-title">Active</div>
                        </div>
                        <div className="box-section-inactive">
                            <div className="box-count">50</div>
                            <div className="box-title">Inactive</div>
                        </div>
                    </div>
                </a> */}
            </div>
        </div>
    );
};

export default Index;
