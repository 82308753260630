import moment from 'moment';

export const externalBaseUrl = process.env.REACT_APP_EXT_BASE_URL;
const baseURL = process.env.REACT_APP_BASE_URL;

export const trackingInsightsList = [
    {
        title: 'NAC Contract Utilisation by Forwarder Agent or Client',
        titleForTrend: 'NAC Contract Utilisation by Forwarder Agent or Client',
        id: 'nac-contract-utilisation-by-forwarder-agent-or-client',
        code: 'TTBPA',
        field1: 'FC_SendingForwarder',
        field2: 'TEU',
        graphFilters: ['TEU', 'BKG', 'SHP'],
        graphKeyToMap: 'FC_SendingForwarder',
        permission: ['Executive', 'Account Manager', 'CS Team Manager'],
        format: 'miniGrid',
        type: { name: '', subType: '' },
        detailPage: [''],
        sort: 0,
        name: 'nac-contract-utilisation-by-forwarder-agent-or-client',
        columns: [],
        fields: {},
        tileUrl: '',
        gridUrl: '',
        trendUrl: '',
        trendTooltipUnit: '',
        fileName: '',
        server: '',
        method: '',
        contentType: '',
        additionalTags: { first: '', second: '', color: '' },
    },
    {
        title: 'Agent List by TEU - SEA',
        titleForTrend: 'Agent List by ##TYPE## - SEA',
        id: 'top-20-by-teu-bookings-pos-agent',
        code: 'TTBPA',
        field1: 'FC_SendingForwarder',
        field2: 'TEU',
        graphFilters: ['TEU', 'BKG', 'SHP'],
        graphKeyToMap: 'FC_SendingForwarder',
        permission: ['Executive', 'Account Manager', 'CS Team Manager'],
        format: 'miniGrid',
        type: { name: 'Agents', subType: 'Top 3' },
        detailPage: ['dataGrid', 'trend', 'trendComparison'],
        sort: 1,
        name: 'top-20-by-teu-bookings-pos-agent',
        columns: [
            {
                field: 'ScoreRanking',
                title: 'Rank',
                sortable: false,
                filterable: false,
            },
            {
                field: 'FC_SendingForwarder',
                title: 'Agent Code',
                sortable: false,
                filterable: false,
            },
            {
                field: 'TEU',
                title: 'TEUs Shipped',
                sortable: false,
                filterable: false,
            },
            {
                field: 'SHP',
                title: 'Lead Shipments',
                sortable: false,
                filterable: false,
            },
            {
                field: 'BKG',
                title: 'Bookings',
                sortable: false,
                filterable: false,
            },
        ],
        fields: {
            ScoreRanking: { type: 'number' },
            TEU: { type: 'number' },
            SHP: { type: 'number' },
            BKG: { type: 'string' },
            FC_SendingForwarder: { type: 'string' },
        },
        tileUrl: '/cix/scr/trk/teu/etd/FC_SendingForwarder?c=##PROFILE##&take=3&skip=0&page=1',
        gridUrl: `${externalBaseUrl}/cix/scr/trk/teu/etd/FC_SendingForwarder?c=##PROFILE##`,
        trendUrl: '/cix/scr/trk/teu/etd/FC_SendingForwarder?c=##PROFILE##&take=20&skip=0&page=1',
        trendTooltipUnit: 'TEU',
        fileName: 'top-20-by-teu-bookings-pos-agent',
        server: 'external',
        method: 'GET',
        contentType: '',
        additionalTags: { first: 'CW1', second: 'FS', color: '#1b6cd9' },
    },
    {
        title: 'Client List by TEU & Booking',
        titleForTrend: 'Client List by ##TYPE## & Booking',
        id: 'client-list-by-teu-booking',
        field1: 'FS_Consignee',
        field2: 'TEU',
        graphFilters: ['TEU', 'BKG', 'SHP'],
        graphKeyToMap: 'FS_Consignee',
        code: 'CLTB',
        permission: ['Executive', 'Account Manager', 'CS Team Manager', 'Operator', 'Agent Manager'],
        format: 'miniGrid',
        type: { name: 'Consignee', subType: 'Top 3' },
        detailPage: ['dataGrid', 'trend', 'trendComparison'],
        sort: 2,
        name: 'client-list-by-teu-booking',
        columns: [
            {
                field: 'ScoreRanking',
                title: 'Rank',
                sortable: false,
                filterable: false,
            },
            {
                field: 'FS_Consignee',
                title: 'Consignee',
                sortable: false,
                filterable: false,
            },
            {
                field: 'TEU',
                title: 'TEUs Shipped',
                sortable: false,
                filterable: false,
            },
            {
                field: 'SHP',
                title: 'Lead Shipments',
                sortable: false,
                filterable: false,
            },
            {
                field: 'BKG',
                title: 'Bookings',
                sortable: false,
                filterable: false,
            },
        ],
        fields: {
            ScoreRanking: { type: 'number' },
            TEU: { type: 'number' },
            SHP: { type: 'number' },
            BKG: { type: 'string' },
            FS_Consignee: { type: 'string' },
        },
        tileUrl: `/cix/scr/trk/teu/etd/FS_Consignee?c=##PROFILE##&take=3&skip=0&page=1`,
        gridUrl: `${externalBaseUrl}/cix/scr/trk/teu/etd/FS_Consignee?c=##PROFILE##`,
        trendUrl: '/cix/scr/trk/teu/etd/FS_Consignee?c=##PROFILE##&take=20&skip=0&page=1',
        trendTooltipUnit: 'TEU',
        fileName: 'client-list-by-teu-booking',
        server: 'external',
        method: 'GET',
        contentType: '',
        additionalTags: { first: 'CW1', second: 'FS', color: '#1b6cd9' },
    },
    {
        title: 'Port Pair List by TEU - Booking',
        titleForTrend: 'Port Pair List by ##TYPE## - Booking',
        id: 'port-pair-list-by-teu-booking',
        field1: 'PortPair',
        field2: 'TEU',
        graphFilters: ['TEU', 'BKG', 'SHP'],
        graphKeyToMap: 'PortPair',
        code: 'PPLTB',
        permission: ['Executive', 'Account Manager', 'CS Team Manager', 'Operator', 'Agent Manager', 'Client Manager'],
        format: 'miniGrid',
        type: { name: 'Port Pair', subType: 'Top 3' },
        detailPage: ['dataGrid', 'trend', 'trendComparison'],
        sort: 3,
        name: 'port-pair-list-by-teu-booking',
        columns: [
            {
                field: 'ScoreRanking',
                title: 'Rank',
                sortable: false,
                filterable: false,
            },
            // {
            //     field: 'FC_PortOfLoading',
            //     title: 'Port Of Loading',
            //     sortable: false,
            //     filterable: false,
            // },
            // {
            //     field: 'FC_PortOfDischarge',
            //     title: 'Port Of Discharge',
            //     sortable: false,
            //     filterable: false,
            // },
            {
                field: 'PortPair',
                title: 'Port Pair',
                sortable: false,
                filterable: false,
            },
            {
                field: 'TEU',
                title: 'TEUs Shipped',
                sortable: false,
                filterable: false,
            },
            {
                field: 'SHP',
                title: 'Lead Shipments',
                sortable: false,
                filterable: false,
            },
            {
                field: 'BKG',
                title: 'Bookings',
                sortable: false,
                filterable: false,
            },
        ],
        fields: {
            ScoreRanking: { type: 'number' },
            // FC_PortOfLoading: { type: 'string' },
            // FC_PortOfDischarge: { type: 'string' },
            PortPair: { type: 'string' },
            TEU: { type: 'number' },
            SHP: { type: 'number' },
            BKG: { type: 'string' },
        },
        tileUrl: '/cix/scr/trk/teu/etd/PortPair?c=##PROFILE##&take=3&skip=0&page=1',
        gridUrl: `${externalBaseUrl}/cix/scr/trk/teu/etd/PortPair?c=##PROFILE##`,
        trendUrl: 'cix/scr/trk/teu/etd/PortPair?take=20&skip=0&page=1',
        fileName: 'port-pair-list-by-teu-booking',
        trendTooltipUnit: 'TEU',
        server: 'external',
        method: 'GET',
        contentType: '',
        additionalTags: { first: 'CW1', second: 'FS', color: '#1b6cd9' },
    },
    {
        title: 'NAC contract usage by Ratio - Shipping Line - Port Pair - Client',
        id: 'nac-contract-usage-by-ratio',
        code: 'NCUR',
        permission: ['Executive', 'Account Manager', 'CS Team Manager', 'Agent Manager', 'Client Manager'],
        format: 'ratio',
        type: { name: 'Agents', subType: '' },
        detailPage: ['dataGrid', 'trend'],
        tileUrl: `api/bkg/tracking/insights/##PROFILE##?code=NCUR`,
        trendUrl: 'api/bkg/tracking/insights/trend/NCUR',
        trendTooltipUnit: 'TEU',
        server: 'internal',
        sort: 4,
        name: 'nac-contract-usage-by-ratio',
        columns: [
            {
                field: 'agent',
                title: 'Agent',
                width: '150px',
            },
            {
                field: 'shippingLineName',
                title: 'Carrier Name',
                width: '180px',
            },
            {
                field: 'contractName',
                title: 'Contract Name',
                width: '180px',
            },
            {
                field: 'version',
                title: 'Version',
                width: '150px',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '180px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '180px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '150px',
            },
            {
                field: 'teuPerMonth',
                title: 'Teu Per Month',
                width: '180px',
            },
            {
                field: 'utilizedTeuPerMonth',
                title: 'Utilized Teu Per Month',
                width: '220px',
            },
            {
                field: 'ratio',
                title: 'Ratio',
                width: '150px',
            },
        ],
        fields: {
            agent: { type: 'string' },
            shippingLineName: { type: 'string' },
            contractName: { type: 'string' },
            version: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            client: { type: 'string' },
            teuPerMonth: { type: 'number' },
            utilizedTeuPerMonth: { type: 'number' },
            ratio: { type: 'number' },
        },
        gridUrl: `${baseURL}/api/bkg/tracking/insights/grid/nac/##PROFILE##`,
        fileName: 'nac-contract-usage-by-ratio',
        method: 'POST',
        contentType: 'application/json',
        additionalTags: { first: 'HUB', second: 'OR', color: '#c0454a' },
    },
    {
        title: 'PO Enrichment On-Time',
        id: 'po-enrichment-on-time',
        code: 'PEOT',
        permission: ['CS Team Manager', 'Operator', 'Agent Manager'],
        format: 'percentage',
        type: { name: 'Agents', subType: '30d prior to ETD' },
        detailPage: ['dataGrid'],
        server: 'internal',
        sort: 5,
        name: 'po-enrichment-on-time',
        columns: [
            {
                field: 'agent',
                title: 'Agent',
            },
            {
                field: 'client',
                title: 'Client',
            },
            {
                field: 'noOfPosEnriched',
                title: 'NO OF POs ENRICHED 30d PRIOR TO ETD',
                width: '350px',
                headerAttributes: {
                    style: 'text-transform: none',
                },
            },
            {
                field: 'totalPosEnriched',
                title: 'TOTAL POs ENRICHED',
                headerAttributes: {
                    style: 'text-transform: none',
                },
            },
            {
                field: 'percentage',
                title: 'Percentage',
            },
        ],
        fields: {
            agent: { type: 'string' },
            client: { type: 'string' },
            noOfPosEnriched: { type: 'number' },
            totalPosEnriched: { type: 'number' },
            percentage: { type: 'string' },
        },
        tileUrl: `api/ord/tracking/insights/##PROFILE##?code=PEOT`,
        gridUrl: `${baseURL}/api/ord/tracking/insights/grid/peot/##PROFILE##`,
        fileName: 'po-enrichment-on-time',
        method: 'POST',
        contentType: 'application/json',
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Open POs',
        id: 'open-pos',
        code: 'OP',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Agent Operator',
            'Client Manager',
            'Client Operator',
        ],
        format: 'count',
        type: { name: 'General', subType: '' },
        detailPage: ['dataGrid'],
        columns: [
            {
                field: 'client',
                title: 'Client',
            },
            {
                field: 'agent',
                title: 'Agent',
            },
            {
                field: 'openPoCount',
                title: 'Open Po Count',
            },
        ],
        fields: {
            client: { type: 'string' },
            agent: { type: 'string' },
            openPoCount: { type: 'number' },
        },

        tileUrl: `${baseURL}/api/ord/tracking/insights/grid/openpo/count`,
        gridUrl: `${baseURL}/api/ord/tracking/insights/grid/openpo/##PROFILE##`,
        server: 'internal',
        fileName: 'open-pos',
        method: 'POST',
        tileMethod: 'POST',
        contentType: 'application/json',
        sort: 6,
        additionalTags: { first: 'HUB', second: 'PO', color: '#6c45ba' },
    },
    {
        title: 'Pos ETD This month',
        id: 'pos-etd-this-month',
        graphFilters: ['TEU', 'BKG', 'SHP'],
        code: 'PETM',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Agent Operator',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'count',
        type: { name: 'General' },
        detailPage: ['trend'],
        tileUrl:
            '/cix/scr/forwarding/booking?filter[filters][2][field]=BP_PredictedETD&filter[filters][2][operator]=is&filter[filters][2][value]=this_month&$sum=BO_PoNumberCount',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/my/po/etd/thismonth?c=##PROFILE##`,
        trendUrl: '/cix/scr/trk/teu/etd/MONTH/YEAR?c=##PROFILE##&take=20&skip=0&page=1', // TODO
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 7,
        additionalTags: { first: 'HUB', second: 'BP', color: '#6c45ba' },
    },
    {
        title: 'Pos ETD next month',
        id: 'pos-etd-next-month',
        code: 'PENM',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'count',
        type: { name: 'General', subType: '' },
        detailPage: [],
        tileUrl:
            '/cix/scr/forwarding/booking?filter[filters][2][field]=BP_PredictedETD&filter[filters][2][operator]=is&filter[filters][2][value]=next_month&$sum=BO_PoNumberCount',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/my/po/etd/nextmonth?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 8,
        additionalTags: { first: 'HUB', second: 'BP', color: '#6c45ba' },
    },
    {
        title: 'Approved Bookings This MTD (month to Date)',
        id: 'approved-bookings-this-mtd',
        code: 'ABTM',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'count',
        type: { name: 'General', subType: '' },
        detailPage: ['trend'],
        tileUrl:
            '/cix/scr/trk/bkg?filter[logic]=and&filter[filters][1][field]=MM_CD_Code&filter[filters][1][operator]=eq&filter[filters][1][value]=APC&filter[filters][2][field]=MM_ActualDate&filter[filters][2][operator]=is&filter[filters][2][value]=this_month&$count',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/my/bk/apr/thismonth?c=##PROFILE##`,
        trendUrl: '/cix/gr/statistics/my/bk/apr/trend',
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 9,
        additionalTags: { first: 'HUB', second: 'BP', color: '#6c45ba' },
    },
    {
        title: 'Planned Bookings Next month',
        id: 'planned-bookings-next-month',
        code: 'PBNM',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'count',
        type: { name: 'General', subType: '' },
        detailPage: [],
        tileUrl:
            '/cix/scr/forwarding/booking?filter[filters][2][field]=BP_PredictedETD&filter[filters][2][operator]=is&filter[filters][2][value]=next_month&$count',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/booking/apf?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 10,
        additionalTags: { first: 'HUB', second: 'BP', color: '#6c45ba' },
    },
    {
        title: 'TEU This MTD',
        id: 'teu-this-mtd',
        code: 'TTM',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'teuCount',
                title: 'TEU Count',
            },
            {
                field: 'company',
                title: 'Company',
            },
            {
                field: 'bookings',
                title: 'Bookings',
            },
            {
                field: 'parentShipments',
                title: 'Parent Shipments',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            teuCount: { type: 'number' },
            company: { type: 'string' },
            bookings: { type: 'string' },
            parentShipments: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'count',
        type: { name: 'General', subType: '' },
        detailPage: ['trend'],
        tileUrl:
            '/cix/scr/forwarding/booking?filter[filters][2][field]=BP_PredictedETD&filter[filters][2][operator]=is&filter[filters][2][value]=this_month&$sum=BP_TotTEU',
        trendUrl: '/cix/gr/statistics/my/teu/atd/trend',
        trendTooltipUnit: 'TEU',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/my/teu/atd/thismonth?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 11,
        additionalTags: { first: 'HUB', second: 'BP', color: '#6c45ba' },
    },
    {
        title: 'Planned TEU Next month',
        id: 'planned-teu-next-month',
        code: 'PTNM',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Client Manager',
            'Client Operator',
        ],
        format: 'count',
        type: { name: 'General', subType: '' },
        detailPage: [],
        tileUrl:
            '/cix/scr/forwarding/booking?filter[filters][2][field]=BP_PredictedETD&filter[filters][2][operator]=is&filter[filters][2][value]=next_month&$sum=BP_TotTEU',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/my/teu/etd/nextmonth?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 12,
        additionalTags: { first: 'HUB', second: 'BP', color: '#6c45ba' },
    },
    {
        title: 'Departure on-time Performance',
        id: 'departure-on-time-performance',
        code: 'DOTP',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'percentage',
        type: { name: 'Carriers', subType: 'BP ETD Vs ATD' },
        detailPage: ['dataGrid', 'trend'],
        //tileUrl: '/cix/gr/statistics/carrier/all/atd/thismonth?c=##PROFILE##',
        tileUrl: '',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/carrier/all/atd/thismonth?c=##PROFILE##`,
        trendUrl: '/cix/gr/statistics/carrier/all/atd/trend',
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 13,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Arrival (POD) on-time Performance',
        id: 'arrival-pod-on-time-performance',
        code: 'AOTP',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'percentage',
        type: { name: 'Carriers', subType: 'BP ETA Vs ATA' },
        detailPage: ['dataGrid', 'trend'],
        // tileUrl: '/cix/gr/statistics/carrier/all/ata/thismonth?c=##PROFILE##',
        tileUrl: '',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/carrier/all/ata/thismonth?c=##PROFILE##`,
        trendUrl: '/cix/gr/statistics/carrier/all/ata/trend',
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 14,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Transit Time performance by Port Pair - Carrier',
        id: 'transit-time-performance-by-port-pair',
        code: 'TTPBPPC',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Agent Manager',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'portOfLoading',
                title: 'Port of Loading',
            },
            {
                field: 'portOfDischarge',
                title: 'Port of Discharge',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
            portOfLoading: { type: 'string' },
            portOfDischarge: { type: 'string' },
        },
        format: 'percentage',
        type: { name: 'Carriers', subType: 'BP Transit Vs Actual' },
        detailPage: ['dataGrid'],
        // tileUrl: '/cix/gr/statistics/carrier/all/transit/thismonth?c=##PROFILE##',
        tileUrl: '',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/carrier/all/transit/thismonth?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 15,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Shipment Delays',
        id: 'shipment-delays',
        code: 'SD',
        permission: [
            'Executive',
            'Account Manager',
            'CS Team Manager',
            'Operator',
            'Client Manager',
            'Client Operator',
        ],
        columns: [
            {
                field: 'bookingNumber',
                title: 'Booking Number',
                width: '150px',
            },
            {
                field: 'HBL',
                title: 'HBL',
            },
            {
                field: 'portOfLoading',
                title: 'Port of Loading',
                width: '150px',
            },
            {
                field: 'portOfDischarge',
                title: 'Port of Discharge',
                width: '160px',
            },
            {
                field: 'vessel',
                title: 'Vessel',
            },
            {
                field: 'voyage',
                title: 'Voyage',
            },
            {
                field: 'STD',
                title: 'STD',
                format: '{0: dd-MMM-yyyy HH:mm}',
            },
            {
                field: 'ETD',
                title: 'ETD',
                format: '{0: dd-MMM-yyyy HH:mm}',
            },
            {
                field: 'ATD',
                title: 'ATD',
                format: '{0: dd-MMM-yyyy HH:mm}',
            },
            {
                field: 'STA',
                title: 'STA',
                format: '{0: dd-MMM-yyyy HH:mm}',
            },
            {
                field: 'ETA',
                title: 'ETA',
                format: '{0: dd-MMM-yyyy HH:mm}',
            },
        ],
        fields: {
            bookingNumber: { type: 'string' },
            HBL: { type: 'string' },
            portOfLoading: { type: 'string' },
            portOfDischarge: { type: 'string' },
            vessel: { type: 'string' },
            voyage: { type: 'string' },
            STD: { type: 'date' },
            ETD: { type: 'date' },
            ATD: { type: 'date' },
            STA: { type: 'date' },
            ETA: { type: 'date' },
        },
        format: 'miniGrid',
        type: { name: 'Carriers', subType: '' },
        detailPage: ['dataGrid'],
        // tileUrl: '/cix/gr/statistics/carrier/all/delays/now?c=##PROFILE##',
        tileUrl: '',
        gridUrl: `${externalBaseUrl}/cix/gr/statistics/carrier/all/delays/now?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 16,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Top 20 by CBM - SEA',
        titleForTrend: 'Top 20 by ##TYPE## - SEA',
        id: 'top-20-by-cbm-bookings',
        code: 'TBCB',
        field1: 'FC_CarrierCode',
        field2: 'CBM',
        graphFilters: ['CBM', 'BKG', 'SHP'],
        graphKeyToMap: 'FC_CarrierCode',
        permission: ['Executive'],
        columns: [
            {
                field: 'ScoreRanking',
                title: 'Rank',
                sortable: false,
                filterable: false,
            },
            {
                field: 'FC_CarrierCode',
                title: 'Port Of Loading',
                sortable: false,
                filterable: false,
            },
            {
                field: 'CBM',
                title: 'CBM Shipped',
                sortable: false,
                filterable: false,
            },
            {
                field: 'SHP',
                title: 'Lead Shipments',
                sortable: false,
                filterable: false,
            },
            {
                field: 'BKG',
                title: 'Bookings',
                sortable: false,
                filterable: false,
            },
        ],
        fields: {
            ScoreRanking: { type: 'number' },
            FC_CarrierCode: { type: 'string' },
            TEU: { type: 'number' },
            SHP: { type: 'number' },
            BKG: { type: 'string' },
        },
        format: 'miniGrid',
        type: { name: 'Carriers', subType: '' },
        detailPage: ['dataGrid', 'trend', 'trendComparison'],
        trendUrl:
            '/cix/scr/trk/cbm/etd/FC_CarrierCode?c=##PROFILE##&take=20&skip=0&page=1&filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=FS_TransportMode&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=SEA',
        trendTooltipUnit: 'CBM',
        tileUrl:
            '/cix/scr/trk/cbm/etd/FC_CarrierCode?c=##PROFILE##&take=3&skip=0&page=1&filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=FS_TransportMode&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=SEA',
        gridUrl: `${externalBaseUrl}/cix/scr/trk/cbm/etd/FC_CarrierCode?c=##PROFILE##&filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=FS_TransportMode&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=SEA`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 17,
        additionalTags: { first: 'CW1', second: 'FS', color: '#1b6cd9' },
    },
    {
        title: 'Top 20 by TEU - Bookings',
        titleForTrend: 'Top 20 by ##TYPE## - Bookings',
        field1: 'FC_CarrierCode',
        field2: 'TEU',
        graphFilters: ['TEU', 'BKG', 'SHP'],
        graphKeyToMap: 'FC_CarrierCode',
        id: 'top-20-by-teu-bookings',
        code: 'TBTB',
        permission: ['Executive'],
        columns: [
            {
                field: 'ScoreRanking',
                title: 'Rank',
                sortable: false,
                filterable: false,
            },
            {
                field: 'FC_CarrierCode',
                title: 'Carrier Code',
                sortable: false,
                filterable: false,
            },
            {
                field: 'TEU',
                title: 'TEUs Shipped',
                sortable: false,
                filterable: false,
            },
            {
                field: 'SHP',
                title: 'Lead Shipments',
                sortable: false,
                filterable: false,
            },
            {
                field: 'BKG',
                title: 'Bookings',
                sortable: false,
                filterable: false,
            },
        ],
        fields: {
            ScoreRanking: { type: 'number' },
            TEU: { type: 'number' },
            SHP: { type: 'number' },
            BKG: { type: 'string' },
            FC_CarrierCode: { type: 'string' },
        },
        format: 'miniGrid',
        type: { name: 'Carriers', subType: '' },
        detailPage: ['dataGrid', 'trend', 'trendComparison'],
        tileUrl: '/cix/scr/trk/teu/etd/FC_CarrierCode?c=##PROFILE##&take=3&skip=0&page=1',
        trendUrl: '/cix/scr/trk/teu/etd/FC_CarrierCode?c=##PROFILE##&take=20&skip=0&page=1',
        trendTooltipUnit: 'TEU',
        gridUrl: `${externalBaseUrl}/cix/scr/trk/teu/etd/FC_CarrierCode?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 18,
        additionalTags: { first: 'CW1', second: 'FS', color: '#1b6cd9' },
    },
    {
        title: 'Top 20 by CBM - AIR',
        titleForTrend: 'Top 20 by ##TYPE## - AIR',
        id: 'top-20-by-cbm-bookings-air',
        field1: 'FC_CarrierCode',
        field2: 'CBM',
        graphFilters: ['CBM', 'BKG', 'SHP'],
        graphKeyToMap: 'FC_CarrierCode',
        code: 'TBCR',
        permission: ['Executive'],
        columns: [
            {
                field: 'ScoreRanking',
                title: 'Rank',
                sortable: false,
                filterable: false,
            },
            {
                field: 'FC_CarrierCode',
                title: 'Carrier Code',
                sortable: false,
                filterable: false,
            },
            {
                field: 'TEU',
                title: 'TEUs Shipped',
                sortable: false,
                filterable: false,
            },
            {
                field: 'SHP',
                title: 'Lead Shipments',
                sortable: false,
                filterable: false,
            },
            {
                field: 'BKG',
                title: 'Bookings',
                sortable: false,
                filterable: false,
            },
        ],
        fields: {
            ScoreRanking: { type: 'number' },
            FC_CarrierCode: { type: 'string' },
            TEU: { type: 'number' },
            SHP: { type: 'number' },
            BKG: { type: 'string' },
        },
        format: 'miniGrid',
        type: { name: 'Carriers', subType: '' },
        detailPage: ['dataGrid', 'trend', 'trendComparison'],
        tileUrl:
            'cix/scr/trk/cbm/etd/FC_CarrierCode?c=##PROFILE##&take=3&skip=0&page=1&filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=FS_TransportMode&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=AIR',
        trendUrl:
            'cix/scr/trk/cbm/etd/FC_CarrierCode?c=##PROFILE##&take=20&skip=0&page=1&filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=FS_TransportMode&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=AIR',
        trendTooltipUnit: 'CBM',
        gridUrl: `${externalBaseUrl}/cix/scr/trk/cbm/etd/FC_CarrierCode?c=##PROFILE##&filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=FS_TransportMode&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=AIR`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 18,
        additionalTags: { first: 'CW1', second: 'FS', color: '#1b6cd9' },
    },
    {
        title: 'Average time between PO submission and GR Date',
        id: 'average-time-between-po-submission-and-gr-date',
        code: 'ATBPSAGD',
        permission: ['Account Manager', 'CS Team Manager', 'Operator', 'Agent Manager', 'Client Manager'],
        format: 'miniGrid',
        type: { name: 'Clients', subType: '' },
        detailPage: ['dataGrid'],
        columns: [
            {
                field: 'client',
                title: 'Client',
            },
            {
                field: 'agent',
                title: 'Agent',
            },
            {
                field: 'averageTime',
                title: 'Average Time (In Hrs)',
                template: '#if(averageTime !== null){# #=averageTime# hrs #}#',
            },
        ],
        fields: {
            client: { type: 'string' },
            agent: { type: 'string' },
            averageTime: { type: 'number' },
        },
        tileUrl: `${baseURL}/api/ord/tracking/insights/grid/average/pogd/##PROFILE##`,
        gridUrl: `${baseURL}/api/ord/tracking/insights/grid/average/pogd/##PROFILE##`,
        fileName: 'average-time-between-po-submission-and-gr-date',
        server: 'internal',
        method: 'POST',
        tileMethod: 'POST',
        tileBody: {
            sort: [{ field: 'averageTime', dir: 'asc' }],
            kendoFilter: {
                logic: 'and',
                filters: [{ field: 'averageTime', operator: 'isnotnull', value: '' }],
            },
            take: 3,
            page: 1,
            pageSize: 20,
            skip: 0,
        },
        contentType: 'application/json',
        sort: 19,
        sortOrder: { field: 'averageTime', dir: 'asc' },
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'PO Quality',
        id: 'po-quality',
        code: 'PQ',
        permission: ['Executive', 'Account Manager', 'CS Team Manager', 'Operator', 'Client Manager'],
        format: 'miniGrid',
        type: { name: 'Clients', subType: '' },
        detailPage: ['dataGrid'],
        tileUrl: `${baseURL}/api/ord/tracking/insights/grid/po-quality/top-clients/##PROFILE##`,
        gridUrl: `${baseURL}/api/ord/tracking/insights/grid/po-quality/##PROFILE##`,
        server: 'internal',
        sort: 20,
        method: 'POST',
        tileMethod: 'GET',
        contentType: 'application/json',
        fileName: 'po-quality',
        // tileBody: {
        //     sort: [{ field: 'quality', dir: 'desc' }],
        //     take: 3,
        //     page: 1,
        //     pageSize: 20,
        //     skip: 0,
        // },
        columns: [
            {
                field: 'client',
                title: 'Client',
            },
            {
                field: 'type',
                title: 'Type',
            },
            {
                field: 'poNumber',
                title: 'PO Number',
            },
            {
                field: 'quality',
                title: 'Quality',
            },
        ],
        fields: {
            client: { type: 'string' },
            type: { type: 'string' },
            poNumber: { type: 'string' },
            quality: { type: 'string' },
        },
        additionalTags: { first: 'HUB', second: 'PO', color: '#6c45ba' },
    },
    {
        title: 'Top 20 by TEU - Consignee',
        titleForTrend: 'Top 20 by ##TYPE## - Consignee',
        id: 'top-20-by-teu-bookings-pos-client',
        code: 'TBTBPC',
        field1: 'FS_Consignee',
        field2: 'TEU',
        graphFilters: ['TEU', 'BKG', 'SHP'],
        graphKeyToMap: 'FS_Consignee',
        permission: ['Executive', 'Account Manager', 'CS Team Manager', 'Operator'],
        columns: [
            {
                field: 'ScoreRanking',
                title: 'Rank',
                sortable: false,
                filterable: false,
            },
            {
                field: 'FS_Consignee',
                title: 'Consignee',
                sortable: false,
                filterable: false,
            },
            {
                field: 'TEU',
                title: 'TEUs Shipped',
                sortable: false,
                filterable: false,
            },
            {
                field: 'SHP',
                title: 'Lead Shipments',
                sortable: false,
                filterable: false,
            },
            {
                field: 'BKG',
                title: 'Bookings',
                sortable: false,
                filterable: false,
            },
        ],
        fields: {
            ScoreRanking: { type: 'number' },
            TEU: { type: 'number' },
            SHP: { type: 'number' },
            BKG: { type: 'string' },
            FC_SendingForwarder: { type: 'string' },
        },
        format: 'miniGrid',
        type: { name: 'Consignee', subType: '' },
        detailPage: ['dataGrid', 'trend', 'trendComparison'],
        tileUrl: '/cix/scr/trk/teu/etd/FS_Consignee?c=##PROFILE##&take=3&skip=0',
        trendUrl: 'cix/scr/trk/teu/etd/FS_Consignee?c=##PROFILE##&take=20&skip=0',
        trendTooltipUnit: 'TEU',
        gridUrl: `${externalBaseUrl}/cix/scr/trk/teu/etd/FS_Consignee?c=##PROFILE##`,
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 21,
        additionalTags: { first: 'CW1', second: 'FS', color: '#1b6cd9' },
    },
    {
        title: 'Cartage booking made',
        id: 'cartage-booking-made',
        code: 'CBM',
        permission: ['Client Manager', 'Client Operator'],
        format: 'percentage',
        type: { name: 'IFC', subType: '5d prior to ETA' },
        detailPage: ['trend'],
        trendUrl: '',
        tileUrl: '',
        gridUrl: '',
        server: 'external',
        sort: 22,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Agent submitted Booking Proposal',
        id: 'agent-submitted-booking-proposal',
        code: 'ASBP',
        permission: ['CS Team Manager', 'Operator', 'Agent Manager'],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'ratio',
        type: { name: 'IFC', subType: '16d prior to ETD' },
        detailPage: ['trend'],
        tileUrl: '/cix/gr/statistics/booking/sub?c=##PROFILE##',
        gridUrl: '',
        trendUrl: '/cix/gr/statistics/booking/sub/trend?c=##PROFILE##',
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 23,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Booking Proposals approved by IFC',
        id: 'booking-proposals-approved-by-ifc',
        code: 'BPABI',
        permission: ['CS Team Manager', 'Operator', 'Agent Manager'],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'ratio',
        type: { name: 'IFC', subType: '14d prior to ETD' },
        detailPage: ['trend'],
        tileUrl: '/cix/gr/statistics/booking/apf?c=##PROFILE##',
        trendUrl: '/cix/gr/statistics/booking/apf/trend?c=##PROFILE##',
        gridUrl: '',
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 24,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Booking Proposals approved by client',
        id: 'booking-proposals-approved-by-client',
        code: 'BPABC',
        permission: ['Executive', 'Account Manager', 'CS Team Manager'],
        columns: [
            {
                field: 'rank',
                title: 'Rank',
            },
            {
                field: 'count',
                title: 'Count',
            },
            {
                field: 'month',
                title: 'Month',
            },
            {
                field: 'year',
                title: 'Year',
            },
        ],
        fields: {
            rank: { type: 'number' },
            count: { type: 'number' },
            month: { type: 'number' },
            year: { type: 'number' },
        },
        format: 'ratio',
        type: { name: 'IFC', subType: '13d prior to ETD' },
        detailPage: ['trend'],
        tileUrl: '/cix/gr/statistics/booking/apc?c=##PROFILE##',
        trendUrl: '/cix/gr/statistics/booking/apc/trend?c=##PROFILE##',
        gridUrl: '',
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 25,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Number of Clients with active NAC',
        id: 'number-of-clients-with-active-nac',
        code: 'NOCN',
        permission: ['Executive', 'Account Manager', 'CS Team Manager'],
        format: 'ratio',
        type: { name: 'IFC', subType: '', displayFormTitle: 'Active : Expired' },
        detailPage: ['trend'],
        tileUrl: 'api/mgt/tracking/insights/forwarder?code=NOCN',
        gridUrl: '',
        trendUrl: 'api/mgt/tracking/insights/trend/CNAC',
        trendTooltipUnit: 'Client',
        server: 'internal',
        sort: 26,
        additionalTags: { first: 'HUB', second: 'OR', color: '#c0454a' },
    },
    {
        title: 'Number of Clients with active FAK Quote',
        id: 'number-of-clients-with-active-fak-quote',
        code: 'NOCF',
        permission: ['Executive', 'Account Manager', 'CS Team Manager', 'Operator'],
        format: 'ratio',
        type: { name: 'IFC', subType: '100%', displayFormTitle: 'Active : Expired' },
        detailPage: ['trend'],
        tileUrl: 'api/mgt/tracking/insights/forwarder?code=NOCF',
        gridUrl: '',
        trendUrl: 'api/mgt/tracking/insights/trend/CFAK',
        trendTooltipUnit: 'Client',
        server: 'internal',
        sort: 27,
        additionalTags: { first: 'HUB', second: 'OR', color: '#c0454a' },
    },
    {
        title: 'Clients profile has SOP attached',
        id: 'clients-profile-has-sop-attached',
        code: 'CPSA',
        permission: ['Executive', 'Account Manager', 'CS Team Manager', 'Operator'],
        format: 'percentageWithXY',
        type: { name: 'IFC', subType: 'has active SOP' },
        detailPage: [],
        tileUrl: 'api/mgt/tracking/insights/forwarder?code=CPSA',
        gridUrl: '',
        server: 'internal',
        sort: 28,
        additionalTags: { first: 'HUB', second: 'OR', color: '#c0454a' },
    },
    {
        title: 'Customs Compiling Completed',
        id: 'customs-compiling-completed',
        code: 'CCC',
        permission: ['Client Manager', 'Client Operator'],
        format: 'percentage',
        type: { name: 'IFC', subType: '4d after ATD' },
        detailPage: ['trend'],
        trendUrl: '',
        tileUrl: '',
        gridUrl: '',
        server: 'external',
        method: 'GET',
        contentType: '',
        sort: 29,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Customs Lodgements submitted on-time',
        id: 'customs-lodgements-submitted-on-time',
        code: 'CLST',
        permission: [],
        format: 'percentage',
        type: { name: 'IFC', subType: 'Within 2d after compiling completion' },
        detailPage: [],
        tileUrl: '',
        gridUrl: '',
        server: 'external',
        sort: 30,
        additionalTags: { first: 'CW1', second: 'CD', color: '#65a2f6' },
    },
    {
        title: 'Consols ready for processing',
        id: 'consols-ready-for-processing',
        code: 'CRFP',
        format: 'ratio',
        type: { name: 'IFC', subType: '7d after ATD' },
        detailPage: ['trend'],
        trendUrl: '',
        tileUrl: '',
        gridUrl: '',
        server: 'external',
        sort: 31,
        additionalTags: { first: 'CW1', second: 'FC', color: '#4715f2' },
    },
    {
        title: 'To investigate financial process & standards',
        id: 'investigate-financial-process-standards',
        code: 'TIFPS',
        format: 'ratio',
        type: { name: 'IFC', subType: '7d after ATD' },
        detailPage: [],
        trendUrl: '',
        tileUrl: '',
        gridUrl: '',
        server: 'external',
        sort: 32,
        additionalTags: { first: 'HUB', second: 'AG', color: '#2f3c5e' },
    },
    {
        title: 'Margin between Sell and Buy of Approved bookings',
        id: 'margin-between-sell-and-buy-of-approved-bookings',
        code: 'MSBAB',
        permission: ['Executive', 'Account Manager', 'CS Team Manager'],
        format: 'percentage',
        type: { name: 'IFC', subType: '' },
        detailPage: ['trend'],
        trendUrl: '',
        tileUrl: '',
        gridUrl: '',
        server: 'external',
        sort: 33,
        additionalTags: { first: 'HUB', second: 'OR', color: '#c0454a' },
    },
    {
        title: 'Clients profile has contract attached',
        id: 'clients-profile-has-contract-attached',
        code: 'CPCA',
        permission: ['Executive', 'Account Manager', 'CS Team Manager'],
        format: 'percentageWithXY',
        type: { name: 'IFC', subType: 'has active contract' },
        detailPage: [],
        tileUrl: 'api/mgt/tracking/insights/forwarder?code=CPCA',
        gridUrl: '',
        server: 'internal',
        sort: 34,
        additionalTags: { first: 'HUB', second: 'OR', color: '#c0454a' },
    },
];

export const filterValues = [
    {
        label: 'This Month',
        value: 'first',
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    {
        label: 'Last 3 Months',
        value: 'second',
        startDate: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
    {
        label: 'Last 12 Months',
        value: 'third',
        startDate: moment().subtract(11, 'months').startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD'),
    },
];

export const profileCode: any = {
    forwarder: 'FWD',
    client: 'CLT',
    agent: 'AGT',
    'shipping line': 'SLN',
    coLoader: 'CLD',
    vendor: 'SUP',
};
