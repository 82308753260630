import React, { ReactElement, useState, useRef, useEffect } from 'react';
import { tableProperties } from '../../../utils/constants';
import DropDown from '../DropDown/index';
import '../../../assets/scss/components/_tab.scss';
import '../../../assets/scss/components/_grid.scss';
import '../../../assets/scss/components/_status.scss';
import '../../../assets/scss/components/_buttons.scss';
import '../../../assets/scss/components/_dropdown.scss';
import '../../../assets/scss/components/_check-radio.scss';
import '../../../assets/scss/components/_textfield.scss';
import { useNavigate } from 'react-router-dom';
import SearchableFilter from '../SearchableFilter';
import '../SearchableFilter/style.scss';
import TableToolTip from '../TableToolTip';
import { saveReceiptStatus } from '../../../store/reducers/receipts/receiptAttachment';
import { useDispatch } from 'react-redux';
import BSTooltip from '../BSTooltip';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import SpecialFilter from '../SpecialFilter';
import ClickAwayListener from 'react-click-away-listener';
import { resetHeader } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
// import tableFooter from '../Table/tableFooter';
import { clearHeader, fetchHeader } from 'src/store/reducers/allocationOrder/addAOReducer';
import {
    clearHeader as clearIbpoHeader,
    fetchHeader as fetchIbpoHeader,
    selectHeader,
    resetfetchHeader,
} from 'src/store/reducers/purchaseOrder/addPoReducer';

import { fetchBpHeaderData } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { checkForPermissionCommon } from 'src/ShowForPermissions/checkForPermissionCommon';
import { useAppSelector } from 'src/hooks';
import {
    fetchHeader as fetchPOHeader,
    selectHeader as selectPOHeader,
} from '../../../store/reducers/purchaseOrder/addPoReducer';
import { bpAttachmentStatus, hubLinkStatus } from './constants';

const tabTypeList = [
    'clients',
    'shippingLines',
    'agents',
    'vendors',
    'purchaseOrder',
    'bookingProposal',
    'inboundPO',
    'receipts',
    'inventories',
    'allocationOrder',
    'coloader',
    'aoBookings',
    'poBookingsAir',
] as const;

interface TableListProps {
    tabName: typeof tabTypeList[number];
    data: any;
    columnStatus: string;
    currentOrder: boolean;
    toggleDropdown: any;
    selectedFilter: any;
    closeToggle: (data: string) => void;
    expandId: any;
    setExpandIdOnClick: (id: string) => void;
    filterToggle: () => void;
    onSort: (sortKey: string) => void;
    filterDataLists?: any;
    setFilter?: (data: any) => void;
    filter?: any;
    setadjustmentModal?: any;
    adjustmentModal?: any;
    fetchFunction?: any;
    setShowSideBar?: any;
    setModalViewproduct?: any;
    setToggleDropdown?: any;
    isGlobalStateFilter?: any;
    clearSearchFilter?: any;
    specialFilterData?: any;
    noDataMessage?: string;
    isLoading?: boolean;
    isLoading2?: boolean;
    resetPage?: any;
}

const ProfileListing = ({
    tabName,
    data,
    columnStatus,
    currentOrder,
    toggleDropdown,
    selectedFilter,
    closeToggle,
    expandId,
    setExpandIdOnClick,
    filterToggle,
    onSort,
    filterDataLists,
    filter,
    setFilter,
    setadjustmentModal,
    adjustmentModal,
    fetchFunction,
    setShowSideBar,
    setModalViewproduct,
    setToggleDropdown,
    isGlobalStateFilter,
    clearSearchFilter,
    specialFilterData,
    noDataMessage,
    isLoading,
    isLoading2,
    resetPage,
}: TableListProps): ReactElement => {
    const navigate = useNavigate();
    const [rowexpand, settableRowExp] = useState(false);
    const [bpStatus, setBpStatus] = useState('');
    const [rowLine, setRowLine] = useState(null);
    const [columnExpand, setColumnExpand] = useState(null);
    const [selectedDropDown, setselectedDropDown] = useState('');
    const dispatch = useDispatch();
    const { isBlp } = useUserProfile();
    const headerData = useAppSelector(selectHeader);
    const poHeaderData = useAppSelector(selectPOHeader);
    const headerDataBP = useSelector((state: RootState) => state.bpHeaderData) as any;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const [filterMenuTop, setFilterMenuTop] = useState(0);
    const currentHeaderList: any = tableProperties[tabName].tableHeaderList;
    const [selectedDates, setSelectedDates] = useState<{ key: string; value: Date | null }[]>([]);
    // eslint-disable-next-line no-undefined
    const [selectedItemData, setSelectedItemData] = React.useState({
        type: '',
        value: '',
        status: '',
    });
    useOutsideAlerter(wrapperRef, setToggleDropdown as any);
    const { profileType } = useUserProfile();
    const getValidItemFor = (key: string, subKey: string | null | undefined, obj: any, isDate?: string) => {
        if (isDate) {
            if (Array.isArray(obj[key])) {
                return obj[key].map((data: string) => {
                    if (data) {
                        const fullDate = data.split('T')[0];
                        const date = fullDate.split('-');
                        return `${date[2]}-${date[1]}-${date[0]}`;
                    }
                    return '';
                });
            } else if (obj[key]) {
                const fullDate = obj[key].split('T')[0];
                const date = fullDate.split('-');
                return `${date[2]}-${date[1]}-${date[0]}`;
            }
        }

        return subKey ? obj[key][subKey] : obj[key];
    };

    const isEmpty = (obj: any) => {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    };

    const gridContainer = useRef<HTMLDivElement>(null);

    const getFilterDropDownPosition = (key: string) => {
        const secondaryRowFilter =
            key === 'plannedCustClearanceDate' ||
            key === 'actualCustClearanceDate' ||
            key === 'plannedGoodsRecvdDate' ||
            key === 'actualGoodsRecvdDate'
                ? true
                : false;
        const filterElement = document.querySelector<any>(`#${key} .filter-menu-button`);
        const containerScrollLeft = gridContainer.current ? gridContainer?.current?.scrollLeft : 0;
        const filterElementDistanceFromLeft = filterElement.getBoundingClientRect().left + containerScrollLeft - 40;
        return {
            left: `${filterElementDistanceFromLeft}px`,
            top: `${filterMenuTop + (secondaryRowFilter ? 60 : 26)}px`,
        };
    };

    const isFiltered = (key: string) => {
        if (key === 'bpType' && filter[key]?.length === 0) {
            return false;
        }
        return !!filter[key];
    };

    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let isAgent = false;
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     if (roleIndex) {
    //         isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
    //     }
    // }

    const { headerDetails } = useSelector((state: RootState) => state.addAoData);
    const { permissions } = useSelector((state: RootState) => state.permissionData);

    const makeNavigationForAo = (id: string | undefined) => {
        if (headerDetails) {
            if (
                headerDetails.bdTabComplete &&
                headerDetails.datesTabComplete &&
                headerDetails.validDate &&
                headerDetails.productTabComplete &&
                (headerDetails?.aoStatus === 'Draft' || headerDetails?.aoStatus === 'Pending_with_Forwarder') &&
                !checkForPermissionCommon(permissions, 'edit', 'ao')
            ) {
                navigate(`/allocation_orders/${id}/ao-preview`);
            } else if (
                headerDetails.bdTabComplete &&
                headerDetails.datesTabComplete &&
                headerDetails.validDate &&
                !checkForPermissionCommon(permissions, 'edit', 'ao')
            ) {
                navigate(`/allocation_orders/${id}/product-allocation`);
            } else if (
                headerDetails.bdTabComplete &&
                !headerDetails.datesTabComplete &&
                !headerDetails.validDate &&
                !checkForPermissionCommon(permissions, 'edit', 'ao')
            ) {
                navigate(`/allocation_orders/${id}/dates`);
            } else {
                navigate(`/allocation_orders/${id}/basic_details`);
            }
        }
    };

    const makeNavigationForIbpo = (id: string | undefined) => {
        if (headerData) {
            if (
                headerData.bdTabComplete &&
                headerData.datesTabComplete &&
                headerData.validDate &&
                headerData.productTabComplete
            ) {
                navigate(`/inbound/${id}/po_review`);
            } else if (
                headerData.bdTabComplete &&
                headerData.datesTabComplete &&
                headerData.validDate &&
                !headerData.productTabComplete
            ) {
                navigate(`/inbound/${id}/product_details`);
            } else if (headerData.bdTabComplete && !headerData.datesTabComplete && !headerData.validDate) {
                navigate(`/inbound/${id}/dates`);
            } else {
                navigate(`/inbound/${id}/basic_details`);
            }
        }
    };

    const makeNavigationForBp = (id: string | undefined) => {
        // const userData = localStorage.getItem('user');
        // const roleIndex = localStorage.getItem('roleIndex');
        const headerDataForBp = JSON?.parse(JSON?.stringify(headerDataBP));

        // let userType = '';
        // if (userData) {
        //     const { roles } = JSON.parse(userData);
        //     if (roleIndex) {
        //         userType = roles[roleIndex].profile?.toLowerCase();
        //     }
        // }

        if (selectedItemData?.type === 'LCL') {
            if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/lclbooking_list/${id}/attachments`);
            } else if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                !headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/lclbooking_list/${id}/container-allocation`);
            } else if (headerDataForBp?.tabValues?.po && headerDataForBp?.tabValues?.sailing) {
                navigate(`/lclbooking_list/${id}/additional-details`);
            } else if (headerDataForBp?.tabValues?.po && !headerDataForBp?.tabValues?.sailing) {
                navigate(`/lclbooking_list/${id}/SailingSelectionDetails`);
            } else {
                navigate(`/lclbooking_list/${id}/POSelection`);
            }
        } else if (selectedItemData?.type === 'BCN') {
            if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/bcnBookingList/${id}/attachments`);
            } else if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                !headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/bcnBookingList/${id}/container-allocation`);
            } else if (headerDataForBp?.tabValues?.po && headerDataForBp?.tabValues?.sailing) {
                navigate(`/bcnBookingList/${id}/additional-details`);
            } else if (headerDataForBp?.tabValues?.po && !headerDataForBp?.tabValues?.sailing) {
                navigate(`/bcnBookingList/${id}/SailingSelectionDetails`);
            } else {
                navigate(`/bcnBookingList/${id}/POSelection`);
            }
        } else {
            if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/booking_list/${id}/attachments`);
            } else if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                !headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/booking_list/${id}/container-allocation`);
            } else if (headerDataForBp?.tabValues?.po && headerDataForBp?.tabValues?.sailing) {
                navigate(`/booking_list/${id}/additional-details`);
            } else if (headerDataForBp?.tabValues?.po && !headerDataForBp?.tabValues?.sailing) {
                navigate(`/booking_list/${id}/SailingSelectionDetails`);
            } else {
                navigate(`/booking_list/${id}/POSelection`);
            }
        }
    };
    const makeNavigationForBpAirAo = (id: string | undefined) => {
        // const userData = localStorage.getItem('user');
        // const roleIndex = localStorage.getItem('roleIndex');
        const headerDataForBp = JSON?.parse(JSON?.stringify(headerDataBP));

        // let userType = '';
        // if (userData) {
        //     const { roles } = JSON.parse(userData);
        //     if (roleIndex) {
        //         userType = roles[roleIndex].profile?.toLowerCase();
        //     }
        // }

        if (selectedItemData.type === 'AIR') {
            if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.airSchedules &&
                headerDataForBp?.tabValues?.airBookingDetails &&
                headerDataForBp?.tabValues?.airShipmentDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/air_booking_list/${id}/attachments`);
            } else if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.airSchedules &&
                headerDataForBp?.tabValues?.airBookingDetails &&
                !headerDataForBp?.tabValues?.airShipmentDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/air_booking_list/${id}/shipment_details`);
            } else if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.airSchedules &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/air_booking_list/${id}/booking_details`);
            } else if (headerDataForBp?.tabValues?.po) {
                navigate(`/air_booking_list/${id}/FlightSelectionDetails`);
            } else {
                navigate(`/air_booking_list/${id}/POSelection`);
            }
        } else {
            if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/Aobooking_list/${id}/attachments`);
            } else if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                headerDataForBp?.tabValues?.additionalDetails &&
                !headerDataForBp?.tabValues?.containerDetails &&
                (bpStatus === 'Ready for Booking' || bpStatus === 'Draft Booking' || bpStatus === 'Booked')
            ) {
                navigate(`/Aobooking_list/${id}/container-allocation`);
            } else if (
                headerDataForBp?.tabValues?.po &&
                headerDataForBp?.tabValues?.sailing &&
                bpStatus !== 'Awaiting_Client_Approval'
            ) {
                navigate(`/Aobooking_list/${id}/additional-details`);
            } else if (headerDataForBp?.tabValues?.po && !headerDataForBp?.tabValues?.sailing) {
                navigate(`/Aobooking_list/${id}/SailingSelectionDetails`);
            } else {
                navigate(`/Aobooking_list/${id}/AoSelection`);
            }
        }
    };

    const makeNavigationForPo = (id: string | undefined) => {
        // const userData = localStorage.getItem('user');
        // const roleIndex = localStorage.getItem('roleIndex');
        // let userType = '';
        // if (userData) {
        // const { roles } = JSON.parse(userData);
        // if (roleIndex) {
        //     userType = roles[roleIndex].profile?.toLowerCase();
        // }
        // }

        if (poHeaderData) {
            if (
                poHeaderData?.poStatus === 'Draft' &&
                poHeaderData?.bdTabComplete &&
                poHeaderData?.datesTabComplete &&
                poHeaderData?.validDate &&
                poHeaderData?.productTabComplete
            ) {
                navigate(`/purchase_order/${id}/po_review`);
            } else if (
                poHeaderData?.poStatus !== 'Draft' &&
                poHeaderData?.bdTabComplete &&
                poHeaderData?.datesTabComplete &&
                poHeaderData?.validDate &&
                poHeaderData?.productTabComplete &&
                poHeaderData?.pconfirmationComplete &&
                !poHeaderData?.containerModeSelectionComplete &&
                poHeaderData?.shippingMode?.shippingModeCode === 'SEA'
            ) {
                navigate(`/purchase_order/${id}/container_mode_selection`);
            } else if (
                poHeaderData?.isHeaderOnlyPo &&
                poHeaderData?.poStatus !== 'Draft' &&
                poHeaderData?.bdTabComplete &&
                poHeaderData?.datesTabComplete &&
                poHeaderData?.validDate &&
                poHeaderData?.productTabComplete
            ) {
                navigate(`/purchase_order/${id}/shipment_information`);
            } else if (
                poHeaderData?.poStatus !== 'Draft' &&
                poHeaderData?.bdTabComplete &&
                poHeaderData?.datesTabComplete &&
                poHeaderData?.validDate &&
                poHeaderData?.productTabComplete
            ) {
                navigate(`/purchase_order/${id}/product_details_confirmation`);
            } else if (
                poHeaderData.bdTabComplete &&
                poHeaderData.datesTabComplete &&
                poHeaderData.validDate &&
                !poHeaderData.productTabComplete
            ) {
                navigate(`/purchase_order/${id}/product_details`);
            } else if (poHeaderData.bdTabComplete && !poHeaderData.datesTabComplete && !poHeaderData.validDate) {
                navigate(`/purchase_order/${id}/dates`);
            } else {
                navigate(`/purchase_order/${id}/basic_details`);
            }
        }
    };

    // const makeNavigationForPo = (id: string | undefined) => {
    //     const userData = localStorage.getItem('user');
    //     const roleIndex = localStorage.getItem('roleIndex');
    //     let userType = '';
    //     if (userData) {
    //         const { roles } = JSON.parse(userData);
    //         if (roleIndex) {
    //             userType = roles[roleIndex].profile?.toLowerCase();
    //         }
    //     }

    //     if (poHeaderData) {
    //         const productDetailsConfirmationTabCheck =
    //             poHeaderData?.poStatus.toLowerCase().includes('awaiting_enrichment') ||
    //             poHeaderData?.poStatus.toLowerCase().includes('ready') ||
    //             poHeaderData?.poStatus.toLowerCase().includes('pending_reassignment') ||
    //             poHeaderData?.poStatus.toLowerCase().includes('booked');
    //         if (
    //             !productDetailsConfirmationTabCheck &&
    //             poHeaderData.bdTabComplete &&
    //             poHeaderData.datesTabComplete &&
    //             poHeaderData.productTabComplete
    //         ) {
    //             navigate(`/purchase_order/${id}/po_review`);
    //         } else if (
    //             !productDetailsConfirmationTabCheck &&
    //             poHeaderData.bdTabComplete &&
    //             poHeaderData.datesTabComplete &&
    //             !poHeaderData.productTabComplete
    //         ) {
    //             navigate(`/purchase_order/${id}/product_details`);
    //         } else if (
    //             !productDetailsConfirmationTabCheck &&
    //             poHeaderData.bdTabComplete &&
    //             !poHeaderData.datesTabComplete
    //         ) {
    //             navigate(`/purchase_order/${id}/dates`);
    //         } else {
    //             navigate(`/purchase_order/${id}/basic_details`);
    //         }
    //     }
    // };

    React.useEffect(() => {
        if (selectedItemData && selectedItemData.type.length && selectedItemData.value.length) {
            if (selectedItemData.type === 'AO' && !isEmpty(headerDetails)) {
                makeNavigationForAo(selectedItemData.value);
            }
            if (selectedItemData.type === 'IBPO' && !isEmpty(headerData)) {
                makeNavigationForIbpo(selectedItemData.value);
            }
            if (selectedItemData.type === 'PO' && !isEmpty(poHeaderData) && poHeaderData?.poNumber) {
                makeNavigationForPo(selectedItemData.value);
            }
            if (
                (selectedItemData.type === 'BCN' ||
                    selectedItemData.type === 'LCL' ||
                    selectedItemData.type === 'FCL') &&
                !isEmpty(headerDataBP) &&
                headerDataBP?.bpNumber
            ) {
                makeNavigationForBp(selectedItemData.value);
            }
            if (
                (selectedItemData.type === 'aoBookings' || selectedItemData.type === 'AIR') &&
                !isEmpty(headerDataBP) &&
                headerDataBP?.bpNumber
            ) {
                makeNavigationForBpAirAo(selectedItemData.value);
            }
        }
    }, [selectedItemData, headerDetails, headerData, poHeaderData, headerDataBP, bpStatus]);

    // To handle multiple header with date filter
    useEffect(() => {
        const headersWithDate: { key: string; value: Date | null }[] = [];
        //TODO: update headerlist type appropriately
        currentHeaderList.forEach((header: any) => {
            if (header.dateFilter) {
                headersWithDate.push({ key: header.key, value: filter[header.key] || null });
            }
        });
        if (headersWithDate.length) {
            setSelectedDates(headersWithDate);
        } else if (selectedDates.length) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            selectedDates.forEach((item) => {
                if (filterObj[item.key]) {
                    delete filterObj[item.key];
                }
            });

            setFilter && setFilter(filterObj);
            setSelectedDates([]);
        }
    }, [currentHeaderList]);
    const handleDateSelection = (key: string, dateValue: Date | null) => {
        const dateList = [...selectedDates];
        const selectedItemIndex = dateList.findIndex((date) => date.key === key);

        if (selectedItemIndex >= 0) {
            dateList[selectedItemIndex] = { key, value: dateValue };
            setSelectedDates(dateList);
        }
    };
    const navigateToPage = (res: any) => {
        setBpStatus(res?.bpStatus);
        if (tabName === 'clients') {
            localStorage.setItem('clientStatus', res?.clientStatus);
            //added state as localstorage was not updating fast
            navigate(`/profile/clients/${res[tableProperties[tabName].navigationKey]}/basic_details`, {
                state: { status: res?.clientStatus, name: res?.orgName },
            });
            localStorage.setItem('clientName', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'vendors') {
            localStorage.setItem('vendorStatus', res?.vendorStatus);
            navigate(`/profile/vendor/${res[tableProperties[tabName].navigationKey]}/basic_detail`);
            localStorage.setItem('vendorName', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'agents') {
            localStorage.removeItem('isBlp');
            localStorage.setItem('agentStatus', res?.agentStatus);
            navigate(`/profile/agent/${res[tableProperties[tabName].navigationKey]}/basic_details`);
            localStorage.setItem('agentName', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'shippingLines') {
            localStorage.setItem('shippinglineStatus', res?.shippingLineStatus);
            navigate(`/profile/shippingline/${res[tableProperties[tabName].navigationKey]}/basic_details`);
            localStorage.setItem('shippinglineName', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'purchaseOrder') {
            dispatch(resetfetchHeader({}));

            // if (isAgent) {
            //     if (
            //         // (res.poStatus === 'Awaiting Enrichment' || res.poStatus === 'Ready For Booking') &&
            //         res.isHeaderOnlyPo
            //     ) {
            //         navigate(`${res.poId}/shipment_information`);
            //     } else {
            //         navigate(`${res.poId}/product_details_confirmation`);
            //     }
            // } else {

            dispatch(
                fetchPOHeader({
                    poId: res.poId,
                })
            );
            setSelectedItemData({
                type: 'PO',
                value: res.poId,
                status: res.poStatus,
            });
            // }
            localStorage.setItem('poStatus', res?.poStatus);
            localStorage.setItem('poNumber', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'bookingProposal') {
            dispatch(fetchBpHeaderData({ bpId: res.bpId, profileType }));
            setSelectedItemData({
                type: res.bpType,
                value: res.bpId,
                status: res.bpStatus,
            });
            localStorage.setItem('bpId', res[tableProperties[tabName].navigationKey]);
            localStorage.setItem('bpNumber', res[tableProperties[tabName].navigationName]);
            dispatch(resetHeader({}));
        } else if (tabName === 'poBookingsAir') {
            dispatch(resetHeader({}));
            dispatch(fetchBpHeaderData({ bpId: res.bpId, profileType }));
            setSelectedItemData({
                type: res.bpType,
                value: res.bpId,
                status: res.bpStatus,
            });
            // localStorage.setItem('bpId', res[tableProperties[tabName].navigationKey]);
            localStorage.setItem('bpNumber', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'aoBookings') {
            dispatch(resetHeader({}));
            dispatch(fetchBpHeaderData({ bpId: res.bpId, profileType }));
            setSelectedItemData({
                type: 'aoBookings',
                value: res.bpId,
                status: res.bpStatus,
            });
            // localStorage.setItem('bpId', res[tableProperties[tabName].navigationKey]);
            localStorage.setItem('bpNumber', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'inboundPO') {
            dispatch(clearIbpoHeader({}));
            dispatch(
                fetchIbpoHeader({
                    poId: res.poId,
                })
            );
            setSelectedItemData({
                type: 'IBPO',
                value: res.poId,
                status: res.poStatus,
            });
            localStorage.setItem('poStatus', res?.poStatus);
            localStorage.setItem('poNumber', res[tableProperties[tabName].navigationName]);
        } else if (tabName === 'receipts') {
            navigate(`/blp/receipt/${res.receiptId}/receipt-header`, { state: res?.createdUser });
            dispatch(saveReceiptStatus(res?.status));
            localStorage.setItem('receiptStatus', res?.status);
            localStorage.setItem('receiptNo', res?.receiptNo);
        } else {
            const path = (tableProperties[tabName] as any).navigationPath;
            const navId = (tableProperties[tabName] as any).navigationId;
            const navkeys = (tableProperties[tabName] as any).navigationKeys;
            if (path) {
                if (tabName === 'allocationOrder') {
                    dispatch(clearHeader({}));
                    dispatch(
                        fetchHeader({
                            aoId: res[navId],
                        })
                    );
                    setSelectedItemData({
                        type: 'AO',
                        value: res[navId],
                        status: '',
                    });
                } else {
                    navigate(path.replace(':id', res[navId]));
                    navkeys?.map((key: string) => {
                        localStorage.setItem(key, res[key]);
                    });
                }
            }
        }
    };

    const navigateToAttachment = (res: any) => {
        if (tabName === 'bookingProposal') {
            if (res.bpType === 'LCL') {
                navigate(`/lclbooking_list/${res.bpId}/attachments`);
            } else if (res.bpType === 'BCN') {
                navigate(`/bcnBookingList/${res.bpId}/attachments`);
            } else {
                navigate(`/booking_list/${res.bpId}/attachments`);
            }
        } else if (tabName === 'aoBookings') {
            navigate(`/Aobooking_list/${res.bpId}/attachments`);
        } else if (tabName === 'poBookingsAir') {
            navigate(`/air_booking_list/${res.bpId}/attachments`);
        }
    };

    const getFilterAligned = () => {
        const item = (tableProperties[tabName].tableHeaderList as any[])?.find(
            ({ key, isFilterRightAligned }: any) => key === selectedDropDown && isFilterRightAligned
        );
        if (item) {
            return true;
        } else {
            return false;
        }
    };
    const renderOptions = {
        STATUS: (res: any, item: any) => {
            return (
                <div className="grid-cell-data">
                    {tabName === 'vendors' ? (
                        <>
                            <div
                                className={
                                    getValidItemFor(item.key, item.subKey, res) === 'DRAFT'
                                        ? 'status-ui warning-status'
                                        : 'status-ui success-status'
                                }
                            >
                                {getValidItemFor(item.key, item.subKey, res) === 'DRAFT' ? 'Draft' : 'Submitted'}
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                className={
                                    getValidItemFor(item.key, item.subKey, res) !== 'INACTIVE'
                                        ? 'status-ui success-status'
                                        : 'status-ui error-status'
                                }
                            >
                                {getValidItemFor(item.key, item.subKey, res) === 'INACTIVE' ? 'Inactive' : 'Active'}
                            </div>
                        </>
                    )}
                </div>
            );
        },
        ACTIONS: (res: any, item: any, index: number) => {
            if (item.actionIcons && setadjustmentModal) {
                return (
                    <div className="grid-cell-data">
                        {res?.isIntegration !== true && isBlp && (
                            <button
                                className="action-btn app-btn app-btn-secondary"
                                title="Edit Inventory"
                                type="button"
                                onClick={() =>
                                    setadjustmentModal({ ...adjustmentModal, id: res?.inventoryId, name: res?.sku })
                                }
                            >
                                <svg className="svg-icon save-icon">
                                    <use xlinkHref="#editIcon"></use>
                                </svg>
                            </button>
                        )}

                        <button
                            className="action-btn app-btn app-btn-secondary"
                            title="View Log"
                            type="button"
                            onClick={() => {
                                dispatch(fetchFunction({ inventoryId: res.inventoryId }));
                                setShowSideBar && setShowSideBar(true);
                            }}
                        >
                            <svg className="svg-icon save-icon">
                                <use xlinkHref="#clockResetIcon"></use>
                            </svg>
                        </button>
                    </div>
                );
            } else if (
                item.actionWithAttachmentIcon &&
                res[item.key] &&
                (res?.bpStatus === 'Draft Booking' ||
                    res?.bpStatus === 'Booked' ||
                    res?.bpStatus === 'In transit' ||
                    res?.bpStatus === 'Delivered')
            ) {
                return (
                    <>
                        <a
                            onClick={() => {
                                if (isGlobalStateFilter) {
                                    clearSearchFilter();
                                }
                                navigateToPage(res);
                            }}
                            style={{ cursor: 'pointer' }}
                            className="view-nav grid-icon icon-nav "
                            title="View details"
                        >
                            <svg className="svg-icon eye-icon ">
                                <use xlinkHref="#eyeIcon"></use>
                            </svg>
                        </a>

                        <a
                            // onClick={() => {
                            //     if (isGlobalStateFilter) {
                            //         clearSearchFilter();
                            //     }
                            //     navigateToPage(res);
                            // }}
                            style={{ cursor: 'pointer' }}
                            className={`view-nav grid-icon ${bpAttachmentStatus[res[item.key]]?.styleClass}`}
                            onClick={() => {
                                navigateToAttachment(res);
                            }}
                        >
                            <BSTooltip
                                id={`${res[item.key]}_${index}`}
                                title={bpAttachmentStatus[res[item.key]]?.tooltipText}
                                position="left"
                                adaptivePosition="true"
                            >
                                <svg className="svg-icon">
                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                    <title>Attach file</title>
                                </svg>
                            </BSTooltip>
                        </a>
                    </>
                );
            } else {
                return (
                    <a
                        onClick={() => {
                            if (isGlobalStateFilter) {
                                clearSearchFilter();
                            }
                            navigateToPage(res);
                        }}
                        style={{ cursor: 'pointer' }}
                        className="view-nav grid-icon icon-nav "
                        title="View details"
                    >
                        <svg className="svg-icon eye-icon ">
                            <use xlinkHref="#eyeIcon"></use>
                        </svg>
                    </a>
                );
            }
        },
        'CARRIER- FLIGHT': (res: any, item: any, index: number) => {
            return (
                <div className="grid-cell-data">
                    <span className="client-id">
                        <TableToolTip
                            data={res[item.key]?.map((item: any) => {
                                return item['carrierFlight'];
                            })}
                            index={index}
                            settableRowExp={settableRowExp}
                            rowexpand={rowexpand}
                            rowLine={rowLine}
                            setRowLine={setRowLine}
                            itemId={item.key}
                            columnExpand={columnExpand}
                            setColumnExpand={setColumnExpand}
                        />
                    </span>
                </div>
            );
        },
        'Hub Link': (res: any, item: any, index: number) => {
            return res[item.key] ? (
                <div className="grid-cell-data">
                    <BSTooltip
                        id={`${item.key}_${index}`}
                        title={hubLinkStatus[res[item.key]]?.tooltipText}
                        position="bottom"
                    >
                        <span className={`status ${hubLinkStatus[res[item.key]]?.styleClass}`}></span>
                    </BSTooltip>
                </div>
            ) : null;
        },
    } as any;

    const renderData = (res: any, item: any, index: number, indx: number | string) => {
        return (
            <td className={`grid-cell ${item.bodyStyle}`} key={indx}>
                {(item.headerName === 'STATUS' && tableProperties[tabName].hasCustomStatus) ||
                item.headerName === 'ACTIONS' ||
                item.headerName === 'Hub Link' ||
                item.subKey ? (
                    renderOptions[item.headerName](res, item, index)
                ) : (
                    <div
                        className="grid-cell-data"
                        title={
                            item.tooltipName ? getValidItemFor(item.tooltipName, item.subKey, res, item.isDate) : null
                        }
                        style={item.tooltipName ? { cursor: 'pointer' } : {}}
                    >
                        {item.isExpandable && res[tableProperties[tabName].childKey]?.length > 0 ? (
                            <button
                                onClick={() => {
                                    expandId !== getValidItemFor(item.key, item.subKey, res)
                                        ? setExpandIdOnClick(getValidItemFor(item.key, item.subKey, res))
                                        : setExpandIdOnClick('');
                                }}
                                className="grid-expander app-btn"
                            >
                                <svg
                                    className="svg-icon arrow-icon"
                                    style={{
                                        transform:
                                            expandId !== getValidItemFor(item.key, item.subKey, res)
                                                ? 'rotate(0)'
                                                : 'rotate(90deg)',
                                    }}
                                >
                                    <use xlinkHref="#arrowIcon">
                                        <title>Expand row</title>
                                    </use>
                                </svg>
                            </button>
                        ) : null}
                        {item.isLink ? (
                            <a
                                className="add-content-link"
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                    setModalViewproduct({
                                        status: true,
                                        value: res?.clientCode,
                                        id: res?.skuId,
                                    })
                                }
                            >
                                {res[item.key]}
                            </a>
                        ) : (
                            <span className="client-id">
                                {item.hasToolTip
                                    ? getValidItemFor(item.key, item.subKey, res, item.isDate)?.length !== 0 && (
                                          <TableToolTip
                                              data={getValidItemFor(item.key, item.subKey, res, item.isDate)}
                                              index={index}
                                              settableRowExp={settableRowExp}
                                              rowexpand={rowexpand}
                                              rowLine={rowLine}
                                              setRowLine={setRowLine}
                                              itemId={item.key}
                                              columnExpand={columnExpand}
                                              setColumnExpand={setColumnExpand}
                                          />
                                      )
                                    : getValidItemFor(item.key, item.subKey, res, item.isDate)}
                            </span>
                        )}
                    </div>
                )}
            </td>
        );
    };

    return (
        <ClickAwayListener onClickAway={() => setselectedDropDown('')}>
            <div
                ref={gridContainer}
                className={`grid-container ${tableProperties[tabName].isMultiLevel ? 'multi-level' : ''}`}
                onClick={() => {
                    setselectedDropDown('');
                }}
                onScroll={() => {
                    const containerScrollTop = gridContainer.current ? gridContainer?.current?.scrollTop : 0;
                    setFilterMenuTop(containerScrollTop);
                    setselectedDropDown('');
                }}
            >
                {/* content when No data found in grid*/}
                {!isLoading && !isLoading2 && (!data || (data?.length === 0 && isLoading2 === false)) && (
                    <div className="grid-no-content ">
                        <svg className="svg-icon grid-no-content-icon">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">{noDataMessage ? noDataMessage : 'No records found'}</p>
                    </div>
                )}
                {isLoading2 && !isLoading && (
                    <div className="grid-no-content ">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-scroll-content">
                    <table className={`grid-table ${tableProperties[tabName].customGridStyle}`}>
                        <thead
                            className={`${tableProperties[tabName].headerRowCount > 1 ? 'multi-row-grid-header' : ''}`}
                        >
                            <tr className="grid-sticky-row">
                                {tableProperties[tabName].tableHeaderList.map((header: any, index: number) => (
                                    <th
                                        className={header.style}
                                        id={header.key}
                                        key={index}
                                        colSpan={header.secondRowDetails ? 2 : 1}
                                    >
                                        <div className="grid-cell-data">
                                            <span>{header.headerName}</span>
                                            {header.info ? (
                                                <BSTooltip
                                                    id={header.infoId}
                                                    title={header.info}
                                                    classList="action-btn-wrapper"
                                                >
                                                    <button className="app-btn grid">
                                                        <svg className="svg-icon error-icon">
                                                            <use xlinkHref="#infoIconRound">
                                                                <title>Warning</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </BSTooltip>
                                            ) : null}

                                            {header.sort ? (
                                                <button
                                                    onClick={() => {
                                                        onSort(header.sortKey);
                                                    }}
                                                    className="sort-btn app-btn"
                                                >
                                                    <svg
                                                        className={
                                                            columnStatus === header.sortKey
                                                                ? `svg-icon sort-icon ${
                                                                      currentOrder ? 'sort-asc' : 'sort-desc'
                                                                  }`
                                                                : 'svg-icon sort-icon'
                                                        }
                                                    >
                                                        {/* class to reverse order icon:  sort-desc  */}
                                                        <use xlinkHref="#sortIcon">
                                                            <title>sort</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                            {header.specialFilter ? (
                                                <div
                                                    className={
                                                        toggleDropdown || selectedFilter !== null
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                    ref={wrapperRef}
                                                >
                                                    <button
                                                        className="app-btn filter-menu-button"
                                                        onClick={(e) => {
                                                            filterToggle();
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                    {toggleDropdown && (
                                                        <SpecialFilter
                                                            closeToggle={closeToggle}
                                                            currentFilter={selectedFilter}
                                                            dataList={
                                                                specialFilterData && specialFilterData[header.filterKey]
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            ) : null}
                                            {header.filter && header.headerName === 'STATUS' ? (
                                                <div
                                                    className={
                                                        toggleDropdown || selectedFilter !== null
                                                            ? 'filter-menu-wrap open'
                                                            : 'filter-menu-wrap'
                                                    }
                                                    ref={wrapperRef}
                                                >
                                                    <button
                                                        className="app-btn filter-menu-button"
                                                        onClick={(e) => {
                                                            filterToggle();
                                                            e.stopPropagation();
                                                        }}
                                                    >
                                                        <svg className="svg-icon filter-icon">
                                                            <use xlinkHref="#filterIcon">
                                                                <title>filter</title>
                                                            </use>
                                                        </svg>
                                                        <span className="sr-only">header menu</span>
                                                    </button>
                                                    {toggleDropdown && (
                                                        <DropDown
                                                            currentFilter={selectedFilter}
                                                            closeToggle={closeToggle}
                                                            tabName={tabName}
                                                        />
                                                    )}
                                                </div>
                                            ) : header.filter ? (
                                                tabName === 'inventories' &&
                                                header.headerName === 'CLIENT' &&
                                                filter?.clientFilter !== 'All' ? null : (
                                                    <div
                                                        className={
                                                            selectedDropDown === header.key || isFiltered(header.key)
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            className="app-btn filter-menu-button"
                                                            onClick={(e) => {
                                                                setselectedDropDown(
                                                                    selectedDropDown === header.key ? '' : header.key
                                                                );
                                                                e.stopPropagation();
                                                            }}
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                    </div>
                                                )
                                            ) : null}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                            {tableProperties[tabName].headerRowCount > 1 ? (
                                <tr className="grid-sticky-row">
                                    {tableProperties[tabName].tableHeaderList.map((header: any, _index: number) => (
                                        <>
                                            {header.secondRowDetails ? (
                                                header.secondRowDetails.map((secHeader: any, secIndex: number) => (
                                                    <th className={secHeader.style} key={secIndex} id={secHeader.key}>
                                                        <div className="grid-cell-data">
                                                            <span>{secHeader.headerName}</span>
                                                            {secHeader.sort ? (
                                                                <button
                                                                    onClick={() => {
                                                                        onSort(secHeader.sortKey);
                                                                    }}
                                                                    className="sort-btn app-btn"
                                                                >
                                                                    <svg
                                                                        className={
                                                                            columnStatus === secHeader.sortKey
                                                                                ? `svg-icon sort-icon ${
                                                                                      currentOrder
                                                                                          ? 'sort-asc'
                                                                                          : 'sort-desc'
                                                                                  }`
                                                                                : 'svg-icon sort-icon'
                                                                        }
                                                                    >
                                                                        {/* class to reverse order icon:  sort-desc  */}
                                                                        <use xlinkHref="#sortIcon">
                                                                            <title>sort</title>
                                                                        </use>
                                                                    </svg>
                                                                </button>
                                                            ) : null}
                                                            {secHeader.filter ? (
                                                                <div
                                                                    className={
                                                                        selectedDropDown === secHeader.key ||
                                                                        isFiltered(secHeader.key)
                                                                            ? 'filter-menu-wrap open'
                                                                            : 'filter-menu-wrap'
                                                                    }
                                                                >
                                                                    <button
                                                                        className="app-btn filter-menu-button"
                                                                        onClick={(e) => {
                                                                            setselectedDropDown(
                                                                                selectedDropDown === secHeader.key
                                                                                    ? ''
                                                                                    : secHeader.key
                                                                            );
                                                                            e.stopPropagation();
                                                                        }}
                                                                    >
                                                                        <svg className="svg-icon filter-icon">
                                                                            <use xlinkHref="#filterIcon">
                                                                                <title>filter</title>
                                                                            </use>
                                                                        </svg>
                                                                        <span className="sr-only">header menu</span>
                                                                    </button>
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </th>
                                                ))
                                            ) : (
                                                <th
                                                    className={
                                                        header.secondRowStyle
                                                            ? header.secondRowStyle
                                                            : 'grid-cell-header'
                                                    }
                                                >
                                                    <div className="grid-cell-data">
                                                        <span></span>
                                                    </div>
                                                </th>
                                            )}
                                        </>
                                    ))}
                                </tr>
                            ) : null}
                        </thead>
                        <tbody>
                            {data?.map((res: any, index: number) => (
                                <>
                                    <tr className="has-child row-expanded" key={index}>
                                        {tableProperties[tabName].tableHeaderList.map((item: any, indx: number) => (
                                            <>
                                                {item.secondRowDetails
                                                    ? item.secondRowDetails.map((itemData: any, itemIndx: number) =>
                                                          renderData(res, itemData, index, `_${itemIndx}`)
                                                      )
                                                    : renderData(res, item, index, indx)}
                                            </>
                                        ))}
                                    </tr>

                                    {res[tableProperties[tabName].childKey]?.map((child: any, index: number) => (
                                        <tr
                                            className="child-l1"
                                            key={index}
                                            style={{
                                                display:
                                                    expandId ===
                                                    getValidItemFor(
                                                        tableProperties[tabName].childDisplayKey,
                                                        tableProperties[tabName].childDisplaySubKey,
                                                        res
                                                    )
                                                        ? ''
                                                        : 'none',
                                            }}
                                        >
                                            {tableProperties[tabName].tableHeaderList.map(
                                                (item: any, index: number) => (
                                                    <td className={`grid-cell ${item.bodyStyle}`} key={index}>
                                                        {item.headerName === 'STATUS' ||
                                                        item.headerName === 'ACTIONS' ? (
                                                            renderOptions[item.headerName](child, item)
                                                        ) : (
                                                            <div
                                                                className="grid-cell-data"
                                                                title={
                                                                    item.tooltipName
                                                                        ? getValidItemFor(
                                                                              item.tooltipName,
                                                                              item.subKey,
                                                                              res,
                                                                              item.isDate
                                                                          )
                                                                        : null
                                                                }
                                                                style={item.tooltipName ? { cursor: 'pointer' } : {}}
                                                            >
                                                                <span className="client-id">
                                                                    {getValidItemFor(
                                                                        item.key,
                                                                        item.subKey,
                                                                        child,
                                                                        item.isDate
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </td>
                                                )
                                            )}
                                        </tr>
                                    ))}
                                </>
                            ))}
                        </tbody>
                    </table>
                    <div
                        className={`${
                            tableProperties[tabName].headerRowCount > 1 ? 'multi-listing-tab' : ''
                        } search-filter-holder`}
                    >
                        {selectedDropDown && setFilter && filterDataLists && filterDataLists[selectedDropDown]?.length && (
                            <SearchableFilter
                                position={getFilterDropDownPosition(selectedDropDown)}
                                dataList={filterDataLists[selectedDropDown]}
                                isLoading={false}
                                filter={filter}
                                setFilter={setFilter}
                                filterKey={selectedDropDown}
                                setselectedDropDown={setselectedDropDown}
                                headerArray={tableProperties[tabName].tableHeaderList}
                                isGlobalStateFilter={isGlobalStateFilter}
                                resetPage={resetPage}
                                isFilterRightAligned={getFilterAligned()}
                                selectedDate={{
                                    selectedDate: selectedDates.find((selected) => selected.key === selectedDropDown)
                                        ?.value,
                                    handleDateSelection,
                                }}
                            />
                        )}
                    </div>
                </div>
            </div>
        </ClickAwayListener>
    );
};

export default ProfileListing;
