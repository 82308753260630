import { ReactElement, useEffect, useState } from 'react';

import '../BookingAddedGrid/cancel-booking-modal.scss';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { useParams } from 'react-router-dom';

interface ChangePortProps {
    setChangePortModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    portCode: any;
    setPortCode: any;
    updatePortCode: any;
    type: string;
}

const Index = ({
    setChangePortModalView,
    portCode,
    setPortCode,
    updatePortCode,
    type,
}: ChangePortProps): ReactElement => {
    const { id } = useParams();
    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div className="app-modal">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder small-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">{`Change ${type === 'origin' ? 'Origin' : 'Destination'} Port`}</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            setChangePortModalView(false);
                            setPortCode({});
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">
                                Changing the port pair will reset all the pre selected sailing schedules and additional
                                details(if any)
                                {window.location.pathname.includes('/bcnBookingList')
                                    ? '. All the child bookings will also be affected'
                                    : ''}
                            </div>
                        </div>

                        {/* <form id="productForm" action="" className="modal-form">
                            <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder="Enter the reason to Cancel Booking Proposal"
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                        }}
                                        required
                                    ></textarea>
                                </div>
                            </div>
                        </form> */}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                        onClick={() => {
                            setChangePortModalView(false);
                            setPortCode({});
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Submit"
                        onClick={() => {
                            setIsLoading(true);
                            updatePortCode(portCode?.label, type === 'origin' ? 'originPort' : 'destinationPort')
                                .then(async () => {
                                    if (window.location.pathname.includes('/bcnBookingList')) {
                                        await propagateBcnData(id);
                                    }
                                    setChangePortModalView(false);
                                    window.location.reload();
                                })
                                .finally(() => {
                                    setIsLoading(false);
                                });
                        }}
                    >
                        <span className="button-text footer-button-text">Submit</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
