import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    addContainerNac,
    addFakDetails,
    addFakOnError,
    addOrEditDestinationAgentError,
    addOrEditDestinationAgentSuccess,
    addRefNumberNac,
    deleteContainerNac,
    editContainerNac,
    editFakDetails,
    fetchContractDetails,
    fetchDeliveryAndContainerModes,
    fetchNacAdditionalDetails,
    fetchOvrContractDetails,
    isLoadingFn,
} from '../../reducers/bookingProposal/additionalDetailsNacReducer';
import {
    addContainer,
    addFak,
    addOrEditDestinationAgentApi,
    addRefNo,
    deleteContainer,
    editContainer,
    getAdditionalDetailsNac,
    getContractDetails,
    getDeliveryAndContainerModes,
    updateAdditionalDetailsFak,
} from '../../../services/apis/bookingProposal/additionalDetailsNacApi';

function* getAdditionDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put({ type: isLoadingFn, payload: true });
        const data: AxiosResponse<any> = yield call(getAdditionalDetailsNac, payload);
        payload['data'] = data;
        yield put({ type: fetchNacAdditionalDetails, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getContractDetailsData({ payload }: { payload: any; type: any }) {
    try {
        // yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(getContractDetails, payload);
        payload['contractDetails'] = data;
        yield put({ type: fetchContractDetails, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getOvrContractDetailsData({ payload }: { payload: any; type: any }) {
    try {
        // yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(getContractDetails, payload);
        payload['contractDetails'] = data;
        yield put({ type: fetchOvrContractDetails, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getDeliveryAndContainerModesNac({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(getDeliveryAndContainerModes, payload);
        payload['data'] = data;
        yield put({ type: fetchDeliveryAndContainerModes, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addContainerData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<string> = yield call(addContainer, payload);
        payload['response'] = data;
        yield put({ type: addContainerNac, payload });
    } catch (err) {
        payload['error'] = true;
        yield put({ type: addContainerNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* editContainerData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const info: AxiosResponse<string> = yield call(editContainer, payload);
        payload['info'] = info;
        yield put({ type: editContainerNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* deleteContainerData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<string> = yield call(deleteContainer, payload);
        payload['data'] = data;
        yield put({ type: deleteContainerNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addRefNumberData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(addRefNo, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: addRefNumberNac, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addFakData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(addFak, payload);
        const payloadCopy = JSON.parse(JSON.stringify(payload));
        payloadCopy['data'] = data ? data : 'error';
        yield put({ type: addFakDetails, payload: payloadCopy });
    } catch (err) {
        yield put({ type: addFakOnError, payload: err });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* editFakData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(updateAdditionalDetailsFak, payload);
        const payloadCopy = JSON.parse(JSON.stringify(payload));
        payloadCopy['data'] = data ? data : 'error';
        yield put({ type: editFakDetails, payload: payloadCopy });
    } catch (err) {
        yield put({ type: addFakOnError, payload: err });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addOrEditDestinationAgentData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(addOrEditDestinationAgentApi, payload);
        yield put({ type: addOrEditDestinationAgentSuccess, payload: data });
    } catch (err) {
        yield put({ type: addOrEditDestinationAgentError, payload: err });
        yield put(hideLoading());
    }
}

function* nacAdditionalDetailsSaga() {
    yield takeEvery('additionalDetailsNac/fetchNacAdditionalDetails', getAdditionDetails);
    yield takeEvery('additionalDetailsNac/fetchDeliveryAndContainerModes', getDeliveryAndContainerModesNac);
    yield takeEvery('additionalDetailsNac/addContainerNac', addContainerData);
    yield takeEvery('additionalDetailsNac/addRefNumberNac', addRefNumberData);
    yield takeEvery('additionalDetailsNac/addFakDetails', addFakData);
    yield takeEvery('additionalDetailsNac/editContainerNac', editContainerData);
    yield takeEvery('additionalDetailsNac/deleteContainerNac', deleteContainerData);
    yield takeEvery('additionalDetailsNac/fetchContractDetails', getContractDetailsData);
    yield takeEvery('additionalDetailsNac/fetchOvrContractDetails', getOvrContractDetailsData);
    yield takeEvery('additionalDetailsNac/editFakDetails', editFakData);
    yield takeEvery('additionalDetailsNac/addOrEditDestinationAgent', addOrEditDestinationAgentData);
}

export default nacAdditionalDetailsSaga;
