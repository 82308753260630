import React, { useState } from 'react';
import '../../../assets/scss/components/_modal.scss';
import './coloader-option-modal.scss';
const Index: React.FC<any> = ({ handleClose, data, onSave, selected, valueSelected }) => {
    const [selectedOption, setSelectedOption] = useState<any>(selected ?? null);
    const [selectedSailing, setselectedSailing] = useState(null);
    const [isEnabled, setIsEnabled] = useState<boolean>(false);
    function handleSelectOption(option: any) {
        setSelectedOption(option);
        setIsEnabled(true);
        setselectedSailing(data?.sailingId);
    }
    return (
        <div className="app-modal container option-modal">
            <div className="modal-content-holder medium-popup">
                <div className="modal-header">
                    <h2 className="title">Options</h2>
                    <button className="app-btn modal-close-btn" onClick={() => handleClose(null)}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Carrier</h6>
                        <div className="details-box-content">{data.shippingline}</div>
                    </div>
                    <div className="detail-options-wrapper">
                        <div className="option-heading">
                            {(data?.options?.nacOptions?.length ?? 0) + (data?.options?.fakOption === 'FAK' ? 1 : 0)}{' '}
                            Option
                            {(data?.options?.nacOptions?.length ?? 0) + (data?.options?.fakOption === 'FAK' ? 1 : 0) >
                                1 && 's'}{' '}
                            available
                        </div>
                        <div className="option-list">
                            {data?.options?.fakOption === 'FAK' && (
                                <div className="option-item">
                                    <div className="layout-checkbox">
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="radio"
                                                    className="checkbox-input"
                                                    name="group1"
                                                    value={'FAK'}
                                                    checked={
                                                        'FAK' === selectedOption &&
                                                        (selectedSailing === data?.sailingId ||
                                                            valueSelected?.sailingId === data?.sailingId)
                                                    }
                                                    onChange={() => handleSelectOption('FAK')}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#circleIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label layout-badge-count">
                                                    <div className="badge-status">
                                                        <div className="content-list-wrapper">
                                                            <div className="content-heading-holder">
                                                                <div className="status-ui confirmed-status contract-pill">
                                                                    <div className="status-nac-left">FAK</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {data?.options?.nacOptions?.map((opt: any) => {
                                // const details = opt?.contractsDtoList?.length;
                                // if (!details) {
                                //     return <></>;
                                // }
                                return (
                                    <div className="option-item" key={opt.contractId}>
                                        <div className="layout-checkbox">
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        type="radio"
                                                        className="checkbox-input"
                                                        name="group1"
                                                        value={opt.contractId}
                                                        checked={
                                                            opt.contractId === selectedOption?.contractId &&
                                                            opt.clientId === selectedOption?.clientId &&
                                                            (selectedSailing === data?.sailingId ||
                                                                valueSelected?.sailingId === data?.sailingId)
                                                        }
                                                        onChange={() => handleSelectOption(opt)}
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon notification-circle-icon">
                                                            <use href="#circleIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label layout-badge-count">
                                                        <div className="badge-status">
                                                            <div className="content-list-wrapper">
                                                                <div className="content-heading-holder">
                                                                    <span className="tab-heading">
                                                                        {opt.clientName}
                                                                    </span>
                                                                    <div className="status-ui nac-status nac-p">
                                                                        <div className="status-nac-left">NAC</div>
                                                                        <div
                                                                            className={`status-nac-right ${
                                                                                opt.isPrimaryClient
                                                                                    ? 'p-status'
                                                                                    : 's-status'
                                                                            }`}
                                                                        >
                                                                            {opt.isPrimaryClient ? 'P' : 'S'}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* <div className="content-count">
                                                                    {opt.teuBalance || 0}
                                                                </div> */}
                                                            </div>
                                                            <div className="content-list-wrapper">
                                                                <div className="box-content-holder">
                                                                    {opt.contractName}
                                                                    <span className="contract-count">
                                                                        {opt.contractVersion}
                                                                    </span>
                                                                </div>
                                                                <div className="box-content-holder">
                                                                    {/* TEU of {opt.teuTotal || 0} */}
                                                                    {opt.teuTotal || 0} TEU {opt.teuCommintmentUnit}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        onClick={() => handleClose(null)}
                    >
                        <span className="button-text vendor-button-text">Back</span>
                    </button>

                    <button
                        style={{ marginLeft: '8px' }}
                        className="app-btn app-btn-primary modal-btn footer-btn"
                        disabled={!isEnabled}
                        onClick={() => {
                            onSave(selectedOption);
                        }}
                    >
                        <span className="button-text vendor-button-text">Save</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
