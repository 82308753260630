import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchCountData } from '../reducers/countReducer';

// Import all api's
import { getCountData, countData } from '../../services/apis/countApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getCountValues({ payload }: { payload: any; type: any }) {
    const count: AxiosResponse<countData> = yield call(getCountData, payload?.payload);

    payload['count'] = count;
    yield put({ type: fetchCountData, payload: payload });
}

function* countSaga() {
    yield takeEvery('count/fetchCountData', getCountValues);
}

// Export the saga (data-saga)
export default countSaga;
