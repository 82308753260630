import React, { useEffect, useState } from 'react';
// import dummyData from './dummyData.json';
import RequiredDetailsModal from './RequiredDetailsModal';
import AdditionalDetailsModal from './AdditionalDetailsModal';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import './purchase-basic-detail.scss';
import '../po-tab.scss';
// import Select from 'react-select';
// import { reactSelectStyle } from '../../../utils/constants';
// import viewData from './viewData.json';
// import { orderType } from './requiredData';
// import { shippingmode } from './requiredData';
// import { fclservicetype } from './requiredData';
// import { freightterms } from './requiredData';
// import { portlist } from './requiredData';
// import { incotermfob } from './requiredData';
// import { paymentterms } from './requiredData';
// import { agentsList } from './requiredData';
// import { buyers } from './requiredData';
// import { currency } from './requiredData';
import {
    adSuccess,
    fetchBasicDetails,
    // getAddress,
    getBuyer,
    getDatasBlp,
    reqSuccess,
} from '../../../store/reducers/purchase_order/basicDetails';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { fetchHeader, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import { Position, success } from '../../../utils/toast';
// import { selectDatesPo } from '../../../store/reducers/purchaseOrder/datesPoReducer';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from '../../../ShowForPermissions';
import { fetchDatesPoList } from 'src/store/reducers/purchaseOrder/datesPoReducer';
import { fetchProductPoList } from 'src/store/reducers/purchaseOrder/productPoReducer';
import ShowMandatoryFields from 'src/components/common/ShowMandatoryFields';
import { InterfaceType } from 'src/utils/constants';
import BLPInboundPoTabs from '../BLPInboundPoTabs';
import BLPInboundPONextButton from '../BLPInboundPONextButton';

const Index: React.FC = () => {
    // const location = useLocation();
    // const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const [addDetails, setaddDetails] = useState(false);
    const [addRequired, setaddRequired] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const [isEditAdditional, setisEditAdditional] = useState(false);
    const [viaIntegration, setViaIntegration] = useState(false);
    const { loading } = useSelector((state: RootState) => state.vendorDat);
    const { basicDetails, addtionalSuccess, requiredSuccess, addressVal, buyerVal } = useSelector(
        (state: RootState) => state.purchaseOrder
    );
    // const datesPoData = useAppSelector(selectDatesPo);
    const headerData = useAppSelector(selectHeader);
    // const datesPoData = useAppSelector(selectDatesPo);
    // const products = useAppSelector(selectProductPo);
    const { id } = useParams();

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const data = {
        agentsList: [],
        paymentterms: [],
        incotermfob: [],
        // portlist: [],
        // freightterms: [],
        fclservicetype: [],
        shippingmode: [],
        orderType: [],
        addressVal: [],
    };
    useEffect(() => {
        dispatch(fetchBasicDetails(id));
        dispatch(
            getDatasBlp({
                orderType: {},
                shippingmode: {},
                fclservicetype: {},
                // freightterms: {},
                // portlist: {},
                incotermfob: {},
                paymentterms: {},
                agentsList: {},
                buyers: {},
                currency: [],
            })
        );
        dispatch(
            fetchDatesPoList({
                id,
            })
        );
        dispatch(fetchProductPoList({ poId: id }));
    }, []);
    useEffect(() => {
        if (headerData?.clientCode) {
            dispatch(
                getBuyer({
                    orgCode: headerData && headerData?.clientCode,
                })
            );
            setViaIntegration(headerData?.createdUser === InterfaceType.InterfacedPO);
        }
    }, [headerData?.clientCode]);

    useEffect(() => {
        if (addtionalSuccess) {
            success(`Additional details have been ${isEditAdditional ? 'updated' : 'added'}`, Position.TOP_RIGHT);
            dispatch(adSuccess(false));
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
        } else if (requiredSuccess) {
            success(`Required details have been ${isEdit ? 'updated' : 'added'}`, Position.TOP_RIGHT);
            dispatch(reqSuccess(false));
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
        }
    }, [addtionalSuccess, requiredSuccess]);

    const val = basicDetails as any;
    /* eslint no-var: off */
    const showCreate = () => {
        var flag = false;
        if (
            val.promRefNo ||
            val.pickGroup ||
            val.brandName ||
            val.currency ||
            val.buyerUserName ||
            val.impVendorCode ||
            val.impShipmentRefNo ||
            val.distribOrderNo
        ) {
            flag = true;
        }
        return flag;
    };
    const showEditRequired = () => {
        var flag = false;
        if (
            val.ordertype ||
            // val.shippingmode ||
            val.agentName ||
            val.destinationAddress ||
            val.destinationPort ||
            val.originPort ||
            val.paymentterms ||
            val.incotermfob ||
            // val.freightterms ||
            val.fclservicetype
        ) {
            flag = true;
        }
        return flag;
    };
    const condition = !val?.incoTerm || !val?.releaseType?.releaseTypeName || !val?.blpAgentName || !val?.blpAddress;
    // const datesCondition =
    //     !datesPoData?.data?.info?.orderDate ||
    //     !datesPoData?.data?.info?.startShipWindowDate ||
    //     !datesPoData?.data?.info?.endShipWindowDate ||
    //     (!datesPoData?.data?.info?.isMultiAddress && !datesPoData?.data?.info?.goodsReadyDate) ||
    //     (!datesPoData?.data?.info?.isMultiAddress && !datesPoData?.data?.info?.projectedEtaDate);

    const showMandatoryFieldsIcon = () => {
        if (!viaIntegration) {
            return false;
        }
        return condition;
    };

    // const nextButtonStatus = () => {
    //     if (!viaIntegration) {
    //         return !val?.incoTerm?.incoTermName ? true : false;
    //     }
    //     return condition ? true : false;
    // };

    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let userType = '';
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     if (roleIndex) {
    //         userType = roles[roleIndex].profile?.toLowerCase();
    //     }
    // }
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName="Inbound PO"
                    firstUrl="/inbound"
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New Inbound PO' : 'Inbound PO Details'}
                    secondUrl={`/inbound/${id}/basic_details`}
                />
                <PurchaseOrderHeader id={id}></PurchaseOrderHeader>
                {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <BLPInboundPoTabs />
            </div>
            <div className="content-section main-wrapper purchase-basic-detail-wrapper">
                {showMandatoryFieldsIcon() && (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missing. Please integrate again/ edit details to enter the mandatory fields
                            to proceed.
                        </div>
                    </div>
                )}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Required Details</h6>
                            <ShowForPermissions permission="edit" type="inbound">
                                {showEditRequired() && headerData?.poStatus === 'Draft' && (
                                    <button
                                        className="action-btn app-btn app-btn-secondary "
                                        title="Edit Details"
                                        onClick={() => {
                                            setaddDetails(true);
                                            setisEdit(true);
                                        }}
                                    >
                                        <svg className="svg-icon edit-icon" style={{ width: '100%' }}>
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="inbound">
                            <div className="btn-holder">
                                {!showEditRequired() && headerData?.poStatus === 'Draft' && (
                                    <button
                                        onClick={() => {
                                            setaddDetails(true);
                                            setisEdit(false);
                                        }}
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Details"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Details</span>
                                    </button>
                                )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.ordertype ? val?.ordertype?.orderTypeName : 'BLP - Inbound Bulk PO'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Shipping Mode<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.shippingmode ? val?.shippingmode?.shippingModeName : 'Land'}
                            </div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                FCL Service Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.fclservicetype ? val?.fclservicetype?.fclServiceTypeName : '-'}
                            </div>
                        </div> */}
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Freight Term<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.freightterms ? val?.freightterms?.freightTermsCode : '-'}
                            </div>
                        </div> */}
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Incoterm<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!val?.incoTerm}
                                name="Incoterm"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {val?.incoTerm ? val?.incoTerm?.incoTermName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Release type<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!val?.releaseType?.releaseTypeName}
                                name="Release type"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {val?.releaseType?.releaseTypeName
                                    ? val?.releaseType?.releaseTypeCode + ' (' + val?.releaseType?.releaseTypeName + ')'
                                    : '-'}
                            </div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Payment Term<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.paymentterms ? val?.paymentterms?.paymentTermsName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Origin<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.originPort ? val?.originPort : '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationPort
                                    ? val?.destinationPort
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination Address<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content" style={{ width: '80%' }}>
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationAddress
                                    ? val.destinationAddress
                                    : '-'}{' '}
                            </div>
                        </div> */}
                        {/* BLP Content fields are added for sprint9-ui for BLP INBOUND PO*/}
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                BLP<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!val?.blpAgentName}
                                name="BLP"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">{val?.blpAgentName ? val?.blpAgentName : '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                BLP Address<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!val?.blpAddress}
                                name="BLP Address"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">{val?.blpAddress ? val?.blpAddress : '-'}</div>
                        </div>
                        {/* {val?.agentName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Agent<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{val?.agentName ? val?.agentName : '-'}</div>
                            </div>
                        )} */}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Additional Details</h6>
                            <ShowForPermissions permission="edit" type="inbound">
                                {showCreate() && headerData?.poStatus === 'Draft' && (
                                    <button
                                        className="action-btn app-btn app-btn-secondary "
                                        title="Edit Details"
                                        onClick={() => {
                                            setaddRequired(true);
                                            setisEditAdditional(true);
                                        }}
                                    >
                                        <svg className="svg-icon edit-icon">
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="inbound">
                            <div className="btn-holder">
                                {!showCreate() && headerData?.poStatus === 'Draft' && (
                                    <button
                                        onClick={() => {
                                            setaddRequired(true);
                                            setisEditAdditional(false);
                                        }}
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Details"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Details</span>
                                    </button>
                                )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    {!showCreate() && (
                        <div className="no-data-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No additional details added</p>
                        </div>
                    )}
                    <div className="card-detail-col">
                        {val?.promRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Promotional Ref No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.promRefNo ? val?.promRefNo : '-'}</div>
                            </div>
                        )}
                        {val?.brandName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Brand Name
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.brandName ? val?.brandName : '-'}</div>
                            </div>
                        )}
                        {val?.impVendorCode && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Vendor Code
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impVendorCode ? val?.impVendorCode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.pickGroup && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Pick Group
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.pickGroup ? val?.pickGroup : '-'}</div>
                            </div>
                        )}
                        {val?.currency && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Order Currency
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.currency ? val?.currency?.currencycode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.buyerUserName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Buyer
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.buyerUserName ? val?.buyerUserName : '-'}
                                </div>
                            </div>
                        )}
                        {val?.impShipmentRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Shipment Ref No .{/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impShipmentRefNo ? val?.impShipmentRefNo : '-'}
                                </div>
                            </div>
                        )}
                        {val?.distribOrderNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Distribution Order No
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.distribOrderNo ? val?.distribOrderNo : '-'}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    {/*<Link
                        title="Next"
                        className="app-btn app-btn-primary footer-btn"
                        to={`/inbound/${id}/dates`}
                    >*/}
                    <BLPInboundPONextButton page="basic_details" />
                    {/*</Link>*/}
                </div>
            </div>
            {addDetails && (
                <RequiredDetailsModal
                    data={data}
                    displayFn={setaddDetails}
                    display={addDetails}
                    edit={isEdit}
                    editData={isEdit && val}
                    setisEdit={setisEdit}
                    id={id}
                    addressVal={addressVal}
                />
            )}
            {addRequired && (
                <AdditionalDetailsModal
                    data1={buyerVal}
                    data2={[]}
                    displayFn={setaddRequired}
                    display={addRequired}
                    edit={isEditAdditional}
                    editData={isEditAdditional && val}
                    setisEdit={setisEditAdditional}
                    id={id}
                />
            )}
            {/* <RequiredDetailsModal></RequiredDetailsModal> */}
            {/* <AdditionalDetailsModal></AdditionalDetailsModal> */}
            {/* <CancellationDetailModal></CancellationDetailModal> */}
            {/* <CancellationModal></CancellationModal> */}
        </div>
    );
};
export default Index;
