import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './lineGraph.scss';
import moment from 'moment';

const LineGraph = (props: any) => {
    const { drawn, tooltipTitle } = props;

    const xMax = drawn.length - 1;

    let yMax = 0;
    let yMin = null;
    const arrayLength = drawn.length;
    for (let i = 0; i < drawn.length; i++) {
        const y = drawn[i][1] * -1;
        if (-y > yMax) {
            yMax = -y;
        }
        if ((yMin && -y < yMin) || yMin === null) {
            yMin = -y;
        }
    }
    // To setup Y cordinates of Line Graph
    const yFractionAbsArray = [
        1, 2, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 10000, 20000, 50000,
        100000,
    ];
    const yAxisMin = yMin ? yMin : 0;
    const yAxisMax = yMax;
    const yDiff = yAxisMax - yAxisMin;
    const yCordsCount = Math.min(6, yDiff);
    const yFraction = yDiff / yCordsCount;
    let yFractionAbs = 1;
    for (let i = 0; yFraction >= yFractionAbsArray[i]; i++) {
        yFractionAbs = yFractionAbsArray[i + 1];
    }

    const yStartAbs = yFractionAbs === 0 ? 0 : yAxisMin - (yAxisMin % yFractionAbs);
    const yAxis = [];
    // console.log('Y yDiff:', yDiff);
    const yEndAbs = yFractionAbs === 0 ? 1 : yAxisMax - (yAxisMax % yFractionAbs) + yFractionAbs;
    for (let j = yStartAbs, i = 0; j <= yEndAbs; j += yFractionAbs, i++) {
        yAxis[i] = j;
    }
    if (props.graphAxis === true) {
        // console.log('Y Axis:', yAxis);
    }

    //================= convert date to mm/dd/yyyy format ========================//
    function convertDateFormat(date: Date | null | any) {
        if (date) {
            return moment(new Date(date)).format('DD-MM-YYYY');
        }
    }
    //===========================================================================//

    const TooltipGenerator = (props: any) => {
        const is_week = props.data[4]?.includes('Week') ? true : false;
        const is_day = props.data[4]?.includes('Day') ? true : false;
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const toggle = () => setTooltipOpen(!tooltipOpen);

        return (
            <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                target={props.name}
                toggle={toggle}
                delay={{ show: 300, hide: 0 }}
            >
                {(is_week
                    ? `${convertDateFormat(props.data[2])} -  ${convertDateFormat(props.data[3])}`
                    : is_day
                    ? convertDateFormat(props.data[2])
                    : props.data[4]) + ','}
                <br />
                {`${props.data[1]} ${tooltipTitle ? tooltipTitle : ''} `}
            </Tooltip>
        );
    };
    return (
        <div className={props.graphAxis ? 'line-graph-wrapper axisEnabled' : 'line-graph-wrapper'}>
            <svg className="line-graph">
                <defs>
                    {props.lineDots && (
                        <symbol id={`point_${props.name}`} className="point-symbol set-symbol">
                            <circle
                                cx="0"
                                cy="0"
                                r={props.pointSize * 3}
                                className="graph-point-area set-point-area"
                            ></circle>
                            <circle
                                cx="0"
                                cy="0"
                                r={props.pointSize}
                                className="graph-point-center set-point-center"
                                strokeWidth={props.lineWidth}
                            ></circle>
                        </symbol>
                    )}
                </defs>
                {yAxis.map((item: any, index: number) => (
                    <React.Fragment key={`yAxis${props.name}${index}`}>
                        {props.graphAxis && (
                            <text
                                className="y-axis-value"
                                key={index}
                                x={0}
                                y={(100 * (yEndAbs - item)) / (yEndAbs - yStartAbs) + '%'}
                            >
                                {item}
                            </text>
                        )}
                    </React.Fragment>
                ))}
                {drawn.map(
                    (item: any, index: number) =>
                        index < arrayLength - 1 && (
                            <>
                                <React.Fragment key={`line${props.name}${index}`}>
                                    <line
                                        className="graph-line"
                                        x1={(index / xMax) * 100 + '%'}
                                        y1={
                                            ((drawn[index][1] - yStartAbs) / (yEndAbs - yStartAbs)) * 100 * -1 +
                                            100 +
                                            '%'
                                        }
                                        x2={((index + 1) / xMax) * 100 + '%'}
                                        y2={
                                            ((drawn[index + 1][1] - yStartAbs) / (yEndAbs - yStartAbs)) * 100 * -1 +
                                            100 +
                                            '%'
                                        }
                                        stroke="black"
                                        strokeWidth={props.lineWidth}
                                    />
                                    ;
                                </React.Fragment>
                            </>
                        )
                )}
                {drawn.map((item: any, index: number) => (
                    <React.Fragment key={`tooltipgen${props.name}${index}`}>
                        {props.lineDots && (
                            <>
                                <use
                                    href={`#point_${props.name}`}
                                    className="graph-point"
                                    key={index}
                                    x={(index / xMax) * 100 + '%'}
                                    y={((drawn[index][1] - yStartAbs) / (yEndAbs - yStartAbs)) * 100 * -1 + 100 + '%'}
                                    transform-origin={`
                                ${(index / xMax) * 100 + '%'} 
                                ${((drawn[index][1] - yStartAbs) / (yEndAbs - yStartAbs)) * 100 * -1 + 100 + '%'}`}
                                    id={`tooltip__${index}${props.name}`}
                                ></use>
                                {props.graphAxis && (
                                    <text
                                        textAnchor="middle"
                                        className="x-axis-value"
                                        key={index}
                                        x={(index / xMax) * 100 + '%'}
                                        y="100%"
                                    >
                                        <tspan>{item[0]?.substr(0, item[0].lastIndexOf(' '))}</tspan>
                                        <tspan dy="1.2em" x={(index / xMax) * 100 + '%'}>
                                            {item[0]?.substr(
                                                item[0].lastIndexOf(' ') + 1,
                                                item[0].length - item[0].lastIndexOf(' ')
                                            )}
                                        </tspan>
                                    </text>
                                )}
                            </>
                        )}
                        {props.tooltip && <TooltipGenerator data={item} name={`tooltip__${index}${props.name}`} />}
                    </React.Fragment>
                ))}
            </svg>
        </div>
    );
};
export default LineGraph;
