import axios from 'axios';
import { ClientDocumentsDtoList } from '../../store/reducers/sopReducer';
import { get, post, put } from '../HttpMethods';
import { fileUploadForDocuments } from './bookingProposal/additionalDetailsApi';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const fetchSOPListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    id: string;
}) => {
    try {
        const data = await get(
            `api/mgt/clients/sop?UUID=${payload.id}&limit=${payload.perPageCount}&page=${payload.currentPage}&sortby=${
                payload.columnStatus || ''
            }&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }`,
            null
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createSopData = async (payload: ClientDocumentsDtoList) => {
    try {
        const data = await post('api/mgt/cd/sop', payload, null);
        const modifiedPayload: any = payload;
        const file = modifiedPayload?.get('file');
        if (file) {
            const document = modifiedPayload?.get('document');
            const text = await document.text();
            const or_PK = JSON.parse(text)?.or_PK;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', or_PK);
            formData.append('DO_FKID', 'OR');
            formData.append('DO_CD_DocumentType', 'SOP');
            await fileUploadForDocuments(formData);
        }
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateSopData = async (payload: ClientDocumentsDtoList) => {
    try {
        // const data = await put(`api/mgt/cd/sop/${payload.cd_PK}`, payload);
        const data = await post(`/cix/scr/admin/organisation/client/sop`, payload, null, false, externalBaseUrl);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchSopAuthor = async () => {
    try {
        const data = await get('api/mgt/ad/forwardersignatory', null);
        const result = data.map((res: any) => ({ value: res.name, label: res.name }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchSopReviewedBy = async () => {
    try {
        const data = await get('api/mgt/ad/forwardersignatory', null);
        const result = data.map((res: any) => ({ value: res.name, label: res.name }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const searchSOP = async (payload: {
    keyword: string;
    id: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    columnOrder: boolean;
    statusFilter: string;
}) => {
    try {
        const res = await get(
            `api/mgt/search/clientsop?UUID=${payload.id}&keyword=${payload.keyword}&limit=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortby=${payload.columnStatus}&sortorder=${
                payload.columnOrder ? 'ASC' : 'DESC'
            }${payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''}`,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getSingleSopData = async (payload: any) => {
    try {
        const data = await get(
            `/cix/scr/admin/organisation/client/${payload?.CD_CL}/sop/${payload?.CD_PK}`,
            null,
            externalBaseUrl
        );
        const res = data?.data?.[0];
        const mapped = {
            cd_docname: res?.CD_DocName,
            cd_doctype: res?.CD_DocType,
            cd_doclocation: res?.CD_DocLocation,
            cd_status: res?.CD_Status,
            cd_iteration: res?.CD_Iteration,
            cd_version: res?.CD_Version,
            cd_author: res?.CD_Author,
            cd_forwardersignatory: res?.CD_ForwarderSignatory,
            cd_startdate: res?.CD_StartDate,
            cd_enddate: res?.CD_EndDate,
            cd_clientacceptancedate: res?.CD_ClientAcceptanceDate,
            cd_clientsignatory: res?.CD_ClientSignatory,
            cd_renewal: res?.CD_Renewal,
            cd_services: res?.CD_Services,
            cd_reviewedby: res?.CD_ReviewedBy,
            cd_clientacceptedby: res?.CD_ClientAcceptedBy,
            cd_activedate: res?.CD_ActiveDate,
            fileName: res?.CD_FileName,
            savedFileSizeInKb: res?.savedFileSizeInKb,
            cd_PK: res?.CD_PK,
        };
        return mapped;
    } catch (err) {
        return console.error(err);
    }
};
