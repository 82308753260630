import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchAllQuotation,
    fetchAllQuotationFailed,
    fetchAllQuotationSuccess,
    fetchSingleQuotation,
    fetchSingleQuotationSuccess,
    fetchSingleQuotationFailed,
    updateQuotationSuccess,
    updateQuotationFailure,
    updateQuotation,
    approveBp,
    approveBpSuccess,
    approveBpFailure,
    rejectBp,
    rejectBpSuccess,
    rejectBpFailure,
    assignToAgent,
    assignToAgentSuccess,
    assignToAgentFailure,
    fetchCurrency,
    fetchCurrencySuccess,
    fetchCurrencyFailure,
    updateViewStatus,
    updateViewStatusSuccess,
    updateViewStatusFailure,
    updateModalStatus,
    updateModalStatusSuccess,
    updateModalStatusFailure,
    updateReassignAgentStatus,
    updateReassignAgentStatusSuccess,
    updateReassignAgentStatusFailure,
    gateInDateValidation,
    fetchEnrichBooking,
    fetchEnrichBookingSuccess,
    fetchEnrichBookingFailure,
    updateEnrichBooking,
    updateEnrichBookingSuccess,
    updateEnrichBookingFailure,
    updateBP,
    fetchHouseBills,
    fetchHouseBillSuccess,
    fetchHouseBillError,
    updateHouseBills,
    updateHouseBillSuccess,
    updateHouseBillError,
    updateHouseBillsCix,
} from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { isLoadingFn } from 'src/store/reducers/bookingProposal/availablePoReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchAllQuotationApi,
    fetchSingleQuotationApi,
    updateQuotationApi,
    approveBookingProposalApi,
    rejectBookingProposalApi,
    assignBpToAgentApi,
    fetchCurrencyDetailsApi,
    updateViewStatusAPi,
    updateModalStatusAPi,
    updateReassignAgentStatusAPi,
    fetchEnrichBookingApi,
    updateEnrichBookingAPi,
    submitBookingProposal,
    SubmitBPparams,
    fetchHouseBillsData,
    updateHouseBillsData,
    updateHouseBillsDataCix,
} from 'src/services/apis/bookingProposal/additionalDetailsApi';
import { getBpHeaderData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { fetchBpHeaderData } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';

function* getAllQuotation({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchAllQuotationApi, payload);
        yield put({ type: fetchAllQuotationSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAllQuotationFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getSingleQuotation({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(fetchSingleQuotationApi, payload);
        yield put({ type: fetchSingleQuotationSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchSingleQuotationFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* changeQuotation({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateQuotationApi, payload);
        yield put({ type: updateQuotationSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateQuotationFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* approveBookingProposal({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(approveBookingProposalApi, payload);
        yield put({ type: approveBpSuccess, payload: response });
    } catch (error) {
        console.log(error, 'response data');
        // if ((error as any)?.data?.status === 'NOT_ACCEPTABLE') {
        //     yield put({ type: gateInDateValidation, payload: true });
        // } else {
        yield put({ type: gateInDateValidation, payload: false });
        yield put({ type: approveBpFailure, payload: error });
        // }
    } finally {
        yield put(hideLoading());
    }
}

function* rejectBookingProposal({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(rejectBookingProposalApi, payload);
        yield put({ type: rejectBpSuccess, payload: response });
    } catch (error) {
        yield put({ type: rejectBpFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* assignBpToAgent({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(assignBpToAgentApi, payload);
        yield put({ type: assignToAgentSuccess, payload: response });
    } catch (error) {
        yield put({ type: assignToAgentFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* getCurrencyDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(fetchCurrencyDetailsApi, payload);
        yield put({ type: fetchCurrencySuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchCurrencyFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* changeViewStatus({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(updateViewStatusAPi, payload);
        yield put({ type: updateViewStatusSuccess, payload: response });
        const data: AxiosResponse<any> = yield call(getBpHeaderData, payload);
        payload['data'] = data;
        yield put({ type: fetchBpHeaderData, payload: payload });
    } catch (error) {
        yield put({ type: updateViewStatusFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* changeModalStatus({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        //yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse = yield call(updateModalStatusAPi, payload);
        yield put({ type: updateModalStatusSuccess, payload: response });
        const data: AxiosResponse<any> = yield call(getBpHeaderData, payload);
        payload['data'] = data;
        yield put({ type: fetchBpHeaderData, payload: payload });
    } catch (error) {
        yield put({ type: updateModalStatusFailure, payload: error });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* changeReassignAgentStatus({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        //yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse = yield call(updateReassignAgentStatusAPi, payload);
        yield put({ type: updateReassignAgentStatusSuccess, payload: response });
        const data: AxiosResponse<any> = yield call(getBpHeaderData, payload);
        payload['data'] = data;
        yield put({ type: fetchBpHeaderData, payload: payload });
    } catch (error) {
        yield put({ type: updateReassignAgentStatusFailure, payload: error });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getEnrichBooking({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchEnrichBookingApi, payload);
        yield put({ type: fetchEnrichBookingSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchEnrichBookingFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* changeEnrichBooking({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(updateEnrichBookingAPi, payload);
        yield put({ type: updateEnrichBookingSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateEnrichBookingFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* submitBP({ payload }: { payload: SubmitBPparams; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield call(submitBookingProposal, payload);
        yield put({ type: updateBP, payload: true });
    } catch (error) {
        yield put({ type: updateBP, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getHouseBill({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchHouseBillsData, payload);
        yield put({ type: fetchHouseBillSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchHouseBillError, payload: error });
    }
}

function* changeHouseBills({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateHouseBillsData, payload);
        yield put({ type: updateHouseBillSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateHouseBillError, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* changeHouseBillsCix({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateHouseBillsDataCix, payload);
        yield put({ type: updateHouseBillSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateHouseBillError, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* additionalDetailsSaga() {
    yield takeEvery(fetchAllQuotation, getAllQuotation);
    yield takeEvery(fetchSingleQuotation, getSingleQuotation);
    yield takeEvery(updateQuotation, changeQuotation);
    yield takeEvery(approveBp, approveBookingProposal);
    yield takeEvery(rejectBp, rejectBookingProposal);
    yield takeEvery(assignToAgent, assignBpToAgent);
    yield takeEvery(fetchCurrency, getCurrencyDetails);
    yield takeEvery(updateViewStatus, changeViewStatus);
    yield takeEvery(updateModalStatus, changeModalStatus);
    yield takeEvery(updateReassignAgentStatus, changeReassignAgentStatus);
    yield takeEvery(fetchEnrichBooking, getEnrichBooking);
    yield takeEvery(updateEnrichBooking, changeEnrichBooking);
    yield takeEvery(updateBP, submitBP);
    yield takeEvery(fetchHouseBills, getHouseBill);
    yield takeEvery(updateHouseBills, changeHouseBills);
    yield takeEvery(updateHouseBillsCix, changeHouseBillsCix);
}

export default additionalDetailsSaga;
