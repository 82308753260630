import React from 'react';
import { useAppDispatch } from 'src/hooks';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { removeProductAllocation } from 'src/store/reducers/allocationOrder/productAllocationReducer';
import '../../../assets/scss/components/_modal.scss';

interface RemoveSkuModalProps {
    removeSkuModalVisibility: boolean;
    setRemoveSkuModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    poProductId: string;
}

const Index: React.FC<RemoveSkuModalProps> = ({
    removeSkuModalVisibility,
    setRemoveSkuModalVisibility,
    poProductId,
}) => {
    const { userName, fullName } = useUserProfile();
    const dispatch = useAppDispatch();
    return (
        <>
            {removeSkuModalVisibility ? (
                <div className="app-modal container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Remove SKU</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => setRemoveSkuModalVisibility(false)}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>Are you sure you want to remove this SKU and all its details ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => setRemoveSkuModalVisibility(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                onClick={() => {
                                    dispatch(removeProductAllocation({ poProductId: poProductId, fullName, userName }));
                                    setRemoveSkuModalVisibility(false);
                                }}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
