/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface AddAoProp {
    status: number;
    message: string;
    info: any;
    data: any;
}

interface AddAoState {
    isLoading: boolean;
    aoDetails?: AddAoProp;
    clientDetails?: any;
    blpDetails?: any;
    headerDetails?: any;
    aoDetailsSuccess: any;
    aoDetailsError: any;
    isError?: boolean;
    id?: number;
    cancelledReason?: any;
}

const initialState: AddAoState = {
    isLoading: false,
    aoDetails: undefined,
    clientDetails: undefined,
    blpDetails: undefined,
    headerDetails: undefined,
    aoDetailsSuccess: undefined,
    aoDetailsError: undefined,
    isError: false,
    id: undefined,
    cancelledReason: undefined,
};

export const addAoSlice = createSlice({
    name: 'addAo',
    initialState,
    reducers: {
        fetchClient: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchClientSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.clientDetails = action.payload;
        },
        fetchClientFailed(state, _action) {
            state.isLoading = false;
            state.aoDetailsSuccess = {};
        },
        fetchBlp: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchBlpSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.blpDetails = action.payload;
        },
        fetchBlpFailed(state, _action) {
            state.isLoading = false;
            state.aoDetailsSuccess = {};
            state.blpDetails = [];
        },
        fetchHeader: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        clearHeader: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.headerDetails = action.payload;
        },
        fetchHeaderSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.headerDetails = action.payload?.data?.info;
        },
        fetchHeaderFailed(state, action) {
            state.isLoading = false;
            state.aoDetailsError = action.payload;
            state.headerDetails = {};
        },
        saveAo: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        saveAoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.aoDetailsSuccess = action.payload;
        },
        saveAoFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.aoDetailsError = action.payload;
        },
        clearFailure: (state, _action) => {
            state.aoDetailsError = undefined;
            state.aoDetailsSuccess = undefined;
            state.isError = false;
        },
        fetchCancelReason: (state, action) => {
            state.cancelledReason = action.payload?.cancelledReason;
        },
    },
});

export const {
    fetchClient,
    fetchClientSuccess,
    fetchClientFailed,
    fetchBlp,
    fetchBlpSuccess,
    fetchBlpFailed,
    fetchHeader,
    clearHeader,
    fetchHeaderSuccess,
    fetchHeaderFailed,
    saveAo,
    saveAoSuccess,
    saveAoFailed,
    clearFailure,
    fetchCancelReason,
} = addAoSlice.actions;

export const selectAddAoLoading = (state: RootState) => state.addAoData.isLoading;
export const selectAddAo = (state: RootState) => state.addAoData.aoDetails;
export const selectHeader = (state: RootState) => state.addAoData.headerDetails;
export const selectAddAoSuccess = (state: RootState) => state.addAoData.aoDetailsSuccess;
export const selectAddAoIsError = (state: RootState) => state.addAoData.isError;
export const selectAddAoError = (state: RootState) => state.addAoData.aoDetailsError;
export const selectClientDetails = (state: RootState) => state.addAoData.clientDetails;
export const selectBlpDetails = (state: RootState) => state.addAoData.blpDetails;

export default addAoSlice.reducer;
