import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchHistoryColumnListFailed,
    fetchHistoryColumnListSuccess,
    fetchHistoryList,
    fetchHistoryListFailed,
    fetchHistoryListSuccess,
    fetchHistoryColumnList,
    isLoadingFn,
} from '../reducers/historyReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { fetchHistoryListData } from '../../services/apis/historyApi';

// eslint-disable-next-line
function* getHistory({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchHistoryListData, payload);
        yield put({ type: fetchHistoryListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchHistoryListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getHistoryColumn(payload: any) {
    try {
        // yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        // const response: AxiosResponse = yield call(fetchHistoryColumnListData, payload);
        yield put({ type: fetchHistoryColumnListSuccess, payload: payload.payload });
    } catch (error) {
        yield put({ type: fetchHistoryColumnListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* historySaga() {
    yield takeEvery(fetchHistoryList, getHistory);
    yield takeEvery(fetchHistoryColumnList, getHistoryColumn);
}

export default historySaga;
