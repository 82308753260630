import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
    bookingTimeLineFetch,
    bookingTimeLineFetchMbl,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import '../lcsBookingTiimeline.scss';
import MaxLimitModal from '../MaxLimitModal';
import moment from 'moment';
import { getDateDifference } from '../../../../../utils';
import { useUserProfile } from 'src/hooks/useUserProfile';
// import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
// import { isLoading } from 'src/store/reducers/bookingProposalAir/existingFlightSchedulesReducer';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { RootState } from 'src/store/reducers';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { lifeCycleSearchCategories } from './Common/constants';

const Index: React.FC = () => {
    const { id, type } = useParams();
    const dispatch = useDispatch();
    const location = useLocation() as any;
    const { userName, profileType } = useUserProfile();
    const [showModal, setShowModal] = useState(false);
    const navigate: any = useNavigate();
    useEffect(() => {
        dispatch(bookingTimeLineFetch({ id: id, profile: profileType }));
        if (type === 'master-bill') {
            dispatch(bookingTimeLineFetchMbl({ mblnumber: location.state?.name }));
        }
    }, [id, profileType, type, location?.state?.name]);
    const { bookingTimeline, selectedData } = useSelector((state: any) => state.lifeCycleSearch);
    const { favList, removeSuccess, addSuccess, isLoading, error, isError } = useSelector(
        (state: RootState) => state.lifecyclesearchFav
    );
    //current status position by checking date null
    const getCurrentPosition = () => {
        let flag = false;
        let currentPosition = 0;
        bookingTimeline?.logs?.forEach((element: any, i: any) => {
            if (element?.date === null && !flag) {
                flag = true;
                currentPosition = i;
            }
        });
        return currentPosition - 1;
    };
    const includesCancel = !bookingTimeline?.logs?.some((el: any) => el?.action === 'BOOKING_CREATION');

    //checking date difference of current/etd with atd
    const getDateDifferenceFn = (atd: any) => {
        const atdValue = moment.utc(atd).local().format('YYYY-MM-DD');
        const today = moment().local().format('YYYY-MM-DD');
        const etdValue = moment.utc(bookingTimeline?.etd).local().format('YYYY-MM-DD');
        if (atd) {
            return getDateDifference(etdValue, atdValue).days;
        } else {
            return getDateDifference(etdValue, today).days;
        }
    };

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: type === 'house-bill' ? lifeCycleSearchCategories.hbl : lifeCycleSearchCategories.bp,
                    favourite: type === 'house-bill' ? 'House Bill Number' : 'Booking Number',
                    number: location.state?.name,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };
    const navigateHbl = () => {
        if (bookingTimeline?.bpType === 'FCL') {
            return navigate(`/booking_list/${bookingTimeline?.bpId}/additional-details`);
        } else if (bookingTimeline?.bpType === 'LCL') {
            return navigate(`/lclbooking_list/${bookingTimeline?.bpId}/additional-details`);
        } else if (bookingTimeline?.bpType === 'BCN') {
            return navigate(`/bcnBookingList/${bookingTimeline?.bpId}/additional-details`);
        } else if (bookingTimeline?.bpType === 'AIR') {
            return navigate(`/air_booking_list/${bookingTimeline?.bpId}/booking_details`);
        } else {
            return navigate(`/Aobooking_list/${bookingTimeline?.bpId}/additional-details`);
        }
    };
    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">
                    {type === 'house-bill' ? 'House Bill Number' : 'Booking Number'}
                </div>
                <div className="timeline-head-value link-com" onClick={() => navigateHbl()}>
                    {location.state?.name || '-'}
                </div>
                {/* <div className="timeline-head-value">{location.state?.name}</div> */}
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>

            <div className="lcs-timeline-wrapper">
                {bookingTimeline?.logs
                    ?.filter((res: any) => {
                        return includesCancel
                            ? res?.action !== 'Departed_From_Origin' &&
                                  res?.action !== 'InTransit' &&
                                  res?.action !== 'Arrival_at_Destination'
                            : true;
                    })
                    ?.map((res: any, i: any) => {
                        if (res?.text) {
                            return (
                                <div
                                    key={i}
                                    className={
                                        res?.text &&
                                        `lcs-timeline-item  ${
                                            getCurrentPosition() == i - 1 ? 'current' : res?.date ? ' completed' : ''
                                        }`
                                    }
                                >
                                    {res?.date && res?.text && (
                                        <span className="lcs-timeline-date">
                                            {moment.utc(res?.date).local().format('DD-MM-YYYY, HH:mm') ?? '-'}
                                        </span>
                                    )}
                                    {/* <h5 className="lcs-timeline-title">{res?.text === 'Cancelled' ? false : res?.text} </h5> */}

                                    <h5 className="lcs-timeline-title">{res?.text} </h5>
                                    {res?.action === 'Departed_From_Origin' ? (
                                        <>
                                            {bookingTimeline?.etd && (
                                                <div className="lcs-timeline-etd">
                                                    <span>ETD :</span>{' '}
                                                    {moment.utc(bookingTimeline?.etd).local().format('DD-MM-YYYY')}
                                                </div>
                                            )}
                                            <span className="lcs-timeline-status delayed">
                                                {getDateDifferenceFn(res.date) > 0 &&
                                                    ` Delayed ` + getDateDifferenceFn(res.date) + ` Days`}
                                            </span>
                                        </>
                                    ) : res?.action === 'InTransit' ? (
                                        <>
                                            <div className="lcs-timeline-etd">
                                                <span>Next Stop :</span> {bookingTimeline?.nextStop}
                                            </div>
                                            {bookingTimeline?.etd && (
                                                <div className="lcs-timeline-etd">
                                                    <span>ETD :</span>
                                                    {moment.utc(bookingTimeline?.etd).local().format('DD-MM-YYYY')}
                                                </div>
                                            )}
                                        </>
                                    ) : res?.action === 'Arrival_at_Destination' ? (
                                        <>
                                            {bookingTimeline?.eta && (
                                                <div className="lcs-timeline-etd">
                                                    <span>ETA :</span>{' '}
                                                    {bookingTimeline?.eta
                                                        ? moment.utc(bookingTimeline?.eta).local().format('DD-MM-YYYY')
                                                        : '-'}
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                            );
                        }
                    })}
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`${type === 'house-bill' ? 'House Bill Number' : 'Booking Number'} : ${location.state?.name}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
