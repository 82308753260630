import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'addedPosao',
    initialState: {
        isLoading: false,
        addPoList: [],
        prouctsList: [],
        removeStat: false,
        removeMessageval: '',
        gateInDate: false,
    },
    reducers: {
        fetchAddedPoData: (state, _action) => {
            state.isLoading = true;
            state.isLoading = false;
        },
        saveAddedData: (state, action) => {
            state.addPoList = action.payload?.bookingProposalPoList;
            state.gateInDate = action.payload.gateInDateExpired;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        removePo: (_state, _action) => {
            // console.log(action);
        },
        searchData: (_state, _action) => {
            // console.log(action);
        },
        getSingleData: (_state, _action) => {
            // console.log('first');
        },
        saveSingleData: (state, action) => {
            state.prouctsList = action.payload;
        },
        removeMessage: (state, action) => {
            state.removeMessageval = action.payload.message;
            state.removeStat = action.payload.status;
        },
    },
});

export const {
    fetchAddedPoData,
    isLoadingFn,
    saveAddedData,
    removePo,
    searchData,
    getSingleData,
    saveSingleData,
    removeMessage,
} = dataSlice.actions;
export default dataSlice.reducer;
