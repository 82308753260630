/* eslint-disable no-undefined */
import { ReactElement } from 'react';
import Select, { components } from 'react-select';
import { reactSelectStyle, reactSelectTheme } from '../../../utils/constants';
import './style.css';

interface SelectProps {
    placeholder: string;
    originalData: any;
    allClientsData: any;
    setAllClientsData: any;
    clientsData?: any;
    setClientsData: any;
    fieldName: string;
    setAddModalVisibility: any;
    setSelectedClient: any;
    setFilterUsed: any;
    formik: any;
    filterUsed?: any;
    clientData?: any;
    setSecondaryClientsData?: any;
    setPrimaryClientsData?: any;
}

const Index = ({
    placeholder,
    originalData,
    allClientsData,
    setAllClientsData,
    setClientsData,
    fieldName,
    setAddModalVisibility,
    setSelectedClient,
    setFilterUsed,
    formik,
}: SelectProps): ReactElement => {
    const NoOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props}>
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No {props?.options?.length ? ' Matching ' : ' '} Records Found</p>
                </div>
            </components.NoOptionsMessage>
        );
    };
    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };
    const updateData = (option: any) => {
        const childData = originalData.filter((item: any) => item.clientId === option.value);
        setFilterUsed(fieldName);
        formik.setFieldValue('validClientsStatus', true);
        if (childData?.length && childData[0].childClients.length) {
            setSelectedClient(childData[0]);
            setAddModalVisibility(true);
        } else {
            setClientsData((prev: any) => [...prev, option]);
            setAllClientsData(allClientsData.filter((item: any) => item.value !== option.value));
        }
    };

    return (
        <Select
            options={
                allClientsData.length
                    ? allClientsData.sort((a: any, b: any) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1))
                    : []
            }
            placeholder={placeholder}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
            onChange={(option: any) => {
                updateData(option);
            }}
            value={[]}
            isClearable={false}
            controlShouldRenderValue={false}
            name={fieldName}
            components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
                NoOptionsMessage,
                ClearIndicator,
            }}
            styles={reactSelectStyle}
            theme={reactSelectTheme}
        />
    );
};

export default Index;
