import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import '../../BookingProposal/ContainerAllocations/container-allocations.scss';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    selectContainerAllocationLoading,
    fetchContainers,
    resetFields,
} from 'src/store/reducers/bookingProposal/containerAllocationReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';
import ShowForPermissions from 'src/ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import ContainerDetails from './ShippingDetails';
import { NavData } from 'src/pages/PoBookingsAir/Wrapper';
import { error, Position, success } from 'src/utils/toast';
import { getUserDetails } from 'src/utils/getUserDetails';
import {
    fetchEnrichBooking,
    fetchHouseBills,
    selectEnrichBooking,
    selectHouseBillDetails,
    updateBP,
    clearFailure,
} from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { fetchShippingDetail } from 'src/store/reducers/bookingProposalAir/shippingDetailsReducer';

interface Props {
    navData: NavData[];
}
const Index: React.FC<Props> = ({ navData }) => {
    const { id } = useParams();
    const { profileType } = useUserProfile();
    const navigate = useNavigate();
    // const navData = [
    //     {
    //         title: 'PO Selection',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/PoSelection`,
    //     },
    //     //TODO update correct links
    //     {
    //         title: 'Flight Schedules',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/FlightSelectionDetails`,
    //     },
    //     {
    //         title: 'Booking Details',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/booking_details`,
    //     },
    //     {
    //         title: 'Shipment Details',
    //         selected: 1,
    //         path: `/air_booking_list/${id}/shipment_details`,
    //     },
    //     {
    //         title: 'Attachments',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/attachments`,
    //     },
    // ];
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);

    const dispatch = useAppDispatch();

    const isLoading = useAppSelector(selectContainerAllocationLoading);
    const headerData: any = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);

    useEffect(() => {
        dispatch(fetchContainers({ bpId: id }));
        dispatch(resetFields({}));
    }, []);
    const customNavData = navData.map((navItem) => {
        if (navItem.key === 'shipment_details') {
            return { ...navItem, selected: 1 };
        } else {
            return navItem;
        }
    });
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    useEffect(() => {
        if (id) {
            dispatch(fetchEnrichBooking({ bpId: id }));
            dispatch(fetchHouseBills({ bpId: id, profileType }));
        }
    }, [id]);
    useEffect(() => {
        if (id) {
            dispatch(fetchShippingDetail({ shipmentId: id }));
        }
    }, [id]);

    const houseBillData = useAppSelector(selectHouseBillDetails);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const [validation, setvalidation] = useState(false);
    const [nextBtn, setNextBtn] = useState(false);
    const { submitBPSuccess, submitBPError } = useSelector((state: RootState) => state.additionalDetailsData);
    const shippingDetails = useSelector((state: RootState) => ({
        shippingDetailsAir: state.shippingDetailsAir,
    }));

    useEffect(() => {
        if (submitBPSuccess) {
            success(`Booking details sent successfully`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            setTimeout(() => {
                navigate('/booking_list');
            }, 1000);
        }
        if (submitBPError) {
            error(`Something went wrong`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
    }, [submitBPSuccess, submitBPError]);

    useEffect(() => {
        if (headerData.bpStatus === 'Draft_Booking' && profileType !== 'client') {
            if (
                houseBillData &&
                enrichBooking &&
                shippingDetails &&
                shippingDetails?.shippingDetailsAir &&
                enrichBooking?.originCartageCompany &&
                houseBillData?.numbers?.length > 0 &&
                houseBillData?.numbers[0]?.vendors?.length > 0 &&
                houseBillData?.numbers[0]?.vendors[0]?.houseBillNumber &&
                enrichBooking?.soNumber &&
                // houseBillData?.masterBillNumber &&
                shippingDetails?.shippingDetailsAir?.shippingDetails?.containerType &&
                shippingDetails?.shippingDetailsAir?.shippingDetails?.deliveryMode &&
                shippingDetails?.shippingDetailsAir?.shippingDetails?.packing
                // &&
                // shippingDetails?.shippingDetailsAir?.shippingDetails?.vgm &&
                // shippingDetails?.shippingDetailsAir?.shippingDetails?.pickUpDate &&
                // shippingDetails?.shippingDetailsAir?.shippingDetails?.dropOffDate
            ) {
                setNextBtn(true);
            } else {
                setNextBtn(false);
            }
        }
    }, [headerData.bpStatus, enrichBooking, houseBillData, shippingDetails?.shippingDetailsAir?.shippingDetails]);

    const submitReadyForShipment = () => {
        const { userName } = getUserDetails();
        dispatch(updateBP({ bookingId: id, userName, profile: profileType }));
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                />
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <BookingProcessTab navData={customNavData} />
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper -container-allocation">
                <div className="main-wrapper">
                    <ContainerDetails></ContainerDetails>
                </div>
            </div>
            {showBookingSideBar && <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar} />}
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (headerData?.bpStatus === 'Booked' ||
                            headerData?.bpStatus === 'Ready_for_Booking' ||
                            headerData?.bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking</span>
                                </button>
                            </ShowForPermissions>
                        )} */}
                    {/* {profileType === 'agent' &&
                        (headerData?.bpStatus === 'Draft_Booking_Proposal' ||
                            headerData?.bpStatus === 'Rejected_by_Forwarder' ||
                            headerData?.bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                            <>
                                {headerData?.bpStatus === 'Booked' || headerData?.bpStatus === 'Cancelled' ? null : (
                                    <ShowForPermissions type="bp" permission="create">
                                        <button
                                            title="Cancel Booking Proposal"
                                            className="app-btn app-btn-secondary footer-btn"
                                            onClick={() => {
                                                setShowBookingCancelModal(true);
                                            }}
                                        >
                                            <span className="button-text footer-button-text">
                                                Cancel Booking Proposal
                                            </span>
                                        </button>
                                    </ShowForPermissions>
                                )}
                            </>
                        )} */}
                    <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/air_booking_list/${id}/booking_details`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    {/* <Link
                        title="Submit"
                        className="app-btn app-btn-primary footer-btn"
                        to={`/air_booking_list/${id}/attachments`}
                    >
                        <span className="button-text footer-button-text">Next</span>
                    </Link> */}
                    <ShowForPermissions type="bp" permission="create">
                        {headerData?.bpStatus === 'Draft_Booking' &&
                            (profileType === 'agent' || profileType === 'forwarder') && (
                                <a
                                    title="Ready for Shipment"
                                    className={`app-btn app-btn-primary footer-btn ${
                                        nextBtn && headerData?.tabValues?.containerDetails ? '' : 'disabled'
                                    }`}
                                    onClick={() => {
                                        if (nextBtn && headerData?.tabValues?.containerDetails) {
                                            submitReadyForShipment();
                                        }
                                    }}
                                >
                                    <span className="button-text footer-button-text">Ready for Shipment</span>
                                </a>
                            )}
                    </ShowForPermissions>
                </div>
            </div>
            {showBookingCancelModal &&
                (headerData?.bpStatus === 'Booked' ||
                    headerData?.bpStatus === 'Ready_for_Booking' ||
                    headerData?.bpStatus === 'Draft_Booking') && (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                )}
        </div>
    );
};
export default Index;
