import React, { useEffect, useState } from 'react';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DatePicker from '../../../components/common/DatePicker';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import {
    resetStatus,
    saveTransportSchedule,
    updateTransportSchedule,
} from 'src/store/reducers/bookingProposalLand/sailingReducer';

interface ModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
}

interface ScheduleData {
    etd: string;
    eta: string;
    transportCompany: string;
    id: string;
    idType: string;
}

const Index: React.FC<ModalProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [_validation, setValidation] = useState(false);
    const { id } = useParams();
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { transportSchedule, sailingUpdate } = useSelector((state: RootState) => state.landsailingbp);
    const dispatch = useDispatch();

    useEffect(() => {
        if (transportSchedule?.length > 0 && sailingUpdate) {
            dispatch(resetStatus({}));
            props.displayFunction(false);
        }
    }, [transportSchedule, sailingUpdate]);

    const ScheduleSchema = Yup.object().shape({
        //etd: Yup.date().required('ETD is required'),
        //eta: Yup.date().required('ETA is required').min(Yup.ref('etd'), 'ETA must be greater than ETD'),
    });

    const initialValues: ScheduleData = {
        etd: transportSchedule?.[0]?.TL_PEstimatedDeparture || '',
        eta: transportSchedule?.[0]?.TL_PEstimatedArrival || '',
        transportCompany: transportSchedule?.[0]?.TL_PCarrier || '',
        id: transportSchedule?.[0]?.TL_PVoyageFlightNo || '',
        idType: transportSchedule?.[0]?.TL_PCD_LegType || '',
    };

    const getPortCodeFromText = (text: string): string => {
        if (text.includes('(') && text.includes(')')) return text.split('(')[1].replace(')', '');
        else return text;
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: true,
        validationSchema: ScheduleSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                setIsLoading(true);
                const schedule = [
                    {
                        TL_LegOrder: 1,
                        TL_PPortOfDischarge: getPortCodeFromText(headerData.destinationPort),
                        TL_PPortOfLoading: getPortCodeFromText(headerData.originPort),
                        TL_PEstimatedArrival: values.eta,
                        TL_PEstimatedDeparture: values.etd,
                        TL_AEstimatedArrival: values.eta,
                        TL_AEstimatedDeparture: values.etd,
                        TL_PCarrier: values.transportCompany,
                        TL_PCD_TransportMode: 'LND',
                        TL_PVoyageFlightNo: values.id,
                        TL_AVoyageFlightNo: values.id,
                        TL_PCD_LegType: values.idType,
                    },
                ];
                const payload = {
                    schedule: schedule,
                    id: id,
                };
                dispatch(updateTransportSchedule(payload));
            }
        },
    });

    const showError = (field: keyof typeof initialValues) => {
        return <>{formik.errors[field] ? <div className="error-text">{formik.errors[field]}</div> : null}</>;
    };

    useBrowserBackCheck(formik.dirty, () => props.setDisplayCancel(true));
    return (
        <>
            {props.display ? (
                <>
                    <div className="app-modal purchase-detail-modal">
                        <div className="modal-content-holder medium-popup ">
                            <div className="modal-header">
                                <div className="title">Transport Schedule</div>
                                <button
                                    onClick={() => {
                                        {
                                            // props.displayFunction(false);
                                            // formik.resetForm();
                                            props.displayFunction(false);
                                        }
                                    }}
                                    className="app-btn modal-close-btn"
                                >
                                    <span>
                                        <svg className="svg-icon modal-header-close-icon">
                                            <use href="#closeIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div className="modal-content">
                                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                                    <svg className="svg-icon loader-icon">
                                        <use xlinkHref="#loaderIcon">
                                            <title>Loading</title>
                                        </use>
                                    </svg>
                                </div>
                                <div className="modal-main-content">
                                    <form
                                        id="my-form"
                                        action=""
                                        onSubmit={formik.handleSubmit}
                                        className="modal-form layout-holder one-column"
                                    >
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                ETD <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={new Date()}
                                                    formik={formik}
                                                    fieldName="etd"
                                                    portalId="date-portal"
                                                />
                                                {showError('etd')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                ETA <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    minDate={
                                                        formik.values.eta
                                                            ? (() => {
                                                                  const minDate = new Date(formik.values.etd);
                                                                  minDate.setDate(minDate.getDate() + 1);
                                                                  return minDate;
                                                              })()
                                                            : new Date()
                                                    }
                                                    formik={formik}
                                                    fieldName="eta"
                                                    portalId="date-portal"
                                                />
                                                {showError('eta')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">Transport Company</label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter Transport Company"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="transportCompany"
                                                    name="transportCompany"
                                                    maxLength={50}
                                                    value={formik.values.transportCompany}
                                                    onChange={(event) => {
                                                        formik.setFieldValue('transportCompany', event.target.value);
                                                    }}
                                                ></input>
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">ID Type</label>
                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                <SearchableSelectBox
                                                    data={[
                                                        { label: 'Container Number', value: 'CNT' },
                                                        { label: 'Truck License Plate', value: 'LPN' },
                                                        { label: 'Receipt Id', value: 'RCP' },
                                                        { label: 'Purchase Order Id', value: 'PON' },
                                                        { label: 'Consignment Number', value: 'COI' },
                                                        { label: 'House Bill Number', value: 'HBL' },
                                                        { label: 'Master Bill Number', value: 'MBL' },
                                                        { label: 'Air Waybill Number', value: 'AWB' },
                                                    ]}
                                                    placeholder="Select ID Type"
                                                    formik={formik}
                                                    fieldName="idType"
                                                    menuPosition="fixed"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                    closeMenuOnScroll={true}
                                                    isClearable={true}
                                                />
                                            </div>
                                        </div>
                                        {formik.values.idType && (
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">ID</label>
                                                <div className="modal-input-layout-item">
                                                    <input
                                                        placeholder="Enter Container Number"
                                                        type="text"
                                                        className="modal-input input-text"
                                                        id="id"
                                                        name="id"
                                                        maxLength={50}
                                                        value={formik.values.id}
                                                        onChange={(event) => {
                                                            formik.setFieldValue('id', event.target.value);
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        )}
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => {
                                        {
                                            props.displayFunction(false);
                                        }
                                    }}
                                    className="app-btn app-btn-secondary modal-btn footer-btn"
                                    title="Cancel"
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn "
                                    type="submit"
                                    form="my-form"
                                    title="Save"
                                >
                                    <span className="button-text footer-button-text">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
