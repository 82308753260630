import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'notifications',
    initialState: {
        notification: [],
        total: 0,
        isRead: 10,
        isLoading: false,
    },
    reducers: {
        fetchNotificationData: (state, _action) => {
            state.isLoading = true;
            state.isLoading = false;
        },
    },
});

export const { fetchNotificationData } = dataSlice.actions;
export default dataSlice.reducer;
