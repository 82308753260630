import React from 'react';
import { useAppSelector } from 'src/hooks';
import { Link, useLocation, useParams } from 'react-router-dom';
import { selectReceiptHeader } from 'src/store/reducers/receipts/receiptHeaderReducer';

interface Props {
    page: string;
}

const ReceiptDetailsNextButton: React.FC<Props> = ({ page }) => {
    const { id } = useParams();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const receiptHeader = useAppSelector(selectReceiptHeader);

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (receiptHeader?.receiptStatus === 'Ready to Receive') {
                return true;
            } else {
                if (receiptHeader && receiptHeader?.plannedCustomsClearance) {
                    if (param === 'receipt-header') {
                        return receiptHeader.rhtabComplete;
                    } else if (param === 'polines') {
                        return receiptHeader.polinesTabComplete;
                    } else if (param === 'documents') {
                        return receiptHeader.documentTabComplete;
                    } else {
                        return false;
                    }
                }
            }
        };
    }, [lastSegment, receiptHeader]);

    const navigation = (pageName: string) => {
        let toPage = '';
        if (pageName === 'receipt-header') {
            toPage = 'polines';
        } else if (pageName === 'polines') {
            toPage = 'documents';
        }
        return toPage;
    };

    return (
        <>
            <Link
                className={`app-btn app-btn-primary footer-btn ${isTabActive(page) ? '' : 'disabled'}`}
                title="Next"
                to={isTabActive(page) ? `/blp/receipt/${id}/${navigation(page)}` : '#'}
            >
                <span className="button-text footer-button-text">Next</span>
            </Link>
        </>
    );
};

export default ReceiptDetailsNextButton;
