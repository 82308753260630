export const checkForPermissionCommon = (permissions: any, permission: any, type: any) => {
    let flag = true;
    for (let i = 0; i < permissions?.length; i++) {
        if (
            ((permissions[i] as any).access
                .toLowerCase()
                .split(/\s+|\./)
                .includes(permission.toLowerCase()) &&
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(type.toLowerCase())) ||
            (permissions[i] as any).access
                .toLowerCase()
                .split(/\s+|\./)
                .includes('alladmin')
        ) {
            flag = false;
        }
    }
    return flag;
};
