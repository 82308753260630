import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchDatesAoList,
    fetchDatesAoListSuccess,
    isLoadingFn,
    createDatesAoSuccess,
    createDatesAoFailure,
    updateDatesAoSuccess,
    updateDatesAoFailure,
    createDatesAo,
    updateDatesAo,
} from '../../reducers/allocationOrder/datesAoReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchDatesAoListDetailsApi,
    createDatesAoDetailsApi,
    updateDatesAoDetailsApi,
} from '../../../services/apis/allocationOrder/datesAoApi';

// eslint-disable-next-line
function* getDatesAoDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchDatesAoListDetailsApi, payload);
        yield put({ type: fetchDatesAoListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchDatesPoListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* createDatesAoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createDatesAoDetailsApi, payload);
        yield put({ type: createDatesAoSuccess, payload: response });
    } catch (error) {
        yield put({ type: createDatesAoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateDatesAoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateDatesAoDetailsApi, payload);
        yield put({ type: updateDatesAoSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateDatesAoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* datesAoSaga() {
    yield takeEvery(fetchDatesAoList, getDatesAoDetails);
    yield takeEvery(createDatesAo, createDatesAoDetailsData);
    yield takeEvery(updateDatesAo, updateDatesAoDetailsData);
}

export default datesAoSaga;
