import moment from 'moment';
// import { loadavg } from 'os';
import { ReactElement } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { resetLogs } from '../../../store/reducers/inventory/inventoryListReducer';
import '../inventory-sidebar.scss';

interface SideBarProps {
    showSideBar: boolean;
    setShowSideBar: (value: boolean) => void;
}
const Index = ({ showSideBar, setShowSideBar }: SideBarProps): ReactElement => {
    const dispatch = useDispatch();
    const { logData }: any = useSelector((state: RootState) => state.inventoryList);

    const getComment = (log: any) => {
        if (log.adjHoldQty !== null) {
            return log.adjHoldCmnt;
        } else if (log.adjAvailableQty !== null) {
            return log.adjAvailableCmnt;
        } else if (log.availableToOrder !== null) {
            return null;
        }
    };

    const getHeadLine = (log: any) => {
        let headLine = '';
        let subHeader = '';
        if (log.action === 'UPDATE_QUANTITY_ADJUSTMENT') {
            if (log.discrepancyId) {
                headLine = 'Quantity adjustments made with Discrepancy';
                subHeader = log.discrepancyId;
            } else {
                headLine = 'Quantity adjustments made by';
                subHeader = log.logCreatedByUserName;
            }
        } else if (log.action === 'ADDED_AS_PART_OF_RECEIPT') {
            headLine = 'Quantity adjustments made based on';
            subHeader = log.receiptNumber;
        } else if (
            log.action === 'ADDED_AS_PART_OF_PRODUCT_ALLOCATION' ||
            log.action === 'UPDATED_AS_PART_OF_PRODUCT_ALLOCATION'
        ) {
            headLine = 'Quantity adjustments made based on Allocation Order';
            subHeader = log.allocationOrderNumber;
        }
        return (
            <h6 className="description-type-1">
                {headLine}
                <span className="desc-content">{subHeader}</span>.
            </h6>
        );
    };

    const getValidValues = (log: any) => {
        let heading = '';
        let value = 0;
        let adjValue = 0;
        let difference = 0;
        if (log.adjHoldQty !== null) {
            heading = 'On Hold Qty';
            value = log.onHoldQty;
            adjValue = log.adjHoldQty;
            difference = log.adjHoldQty - log.onHoldQty;
        } else if (log.adjAvailableQty !== null) {
            heading = 'Available Qty';
            value = log.availableQty;
            adjValue = log.adjAvailableQty;
            difference = log.adjAvailableQty - log.availableQty;
        } else if (log.availableToOrder !== null) {
            heading = 'Available to Order';
            value = log.oldAvailableToOrder;
            adjValue = log.availableToOrder;
            difference = log.availableToOrder - log.oldAvailableToOrder;
        }
        return (
            <div className="layout-item col-field-1">
                <h6 className="heading-type-1">{heading}</h6>
                <div className="description-type-1">
                    <div className="unit-process-wrapper">
                        <span className="first-unit">{value}</span>
                        <span className="unit-process-icon">
                            <svg className="svg-icon straight-arrow-pointer-icon">
                                <use href="#straightArrowPointerIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                        <span className="second-unit">{adjValue}</span>
                        {difference > 0 ? (
                            <span className="third-unit success">{`+${difference}`}</span>
                        ) : (
                            <span className="third-unit error">{difference}</span>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div className="advanced-sidebar">
            <div className={`side-bar ${showSideBar ? 'active' : ''}`}>
                <button
                    className="app-btn text-close-btn"
                    onClick={() => {
                        setShowSideBar(false);
                        dispatch(resetLogs({}));
                    }}
                >
                    <svg className="svg-icon text-close-icon">
                        <use href="#closeIcon"></use>
                    </svg>
                </button>
                <h2 className="page-heading">Quantity Adjustment Log</h2>
                <div className="details-content">
                    <div className="layout-main-holder">
                        <div className="layout-holder">
                            <div className="layout-item col1">
                                <h6 className="heading-type-1">SKU</h6>
                                <div className="description-type-1">{logData?.sku}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Description</h6>
                                <div className="description-type-1">{logData?.description}</div>
                            </div>
                        </div>
                    </div>
                    <div className="layout-content-holder">
                        {logData?.logs?.map((log: any, indx: number) => (
                            <div className="layout-item-content-wrapper" key={indx}>
                                <div className="layout-field-inline">
                                    {getHeadLine(log)}
                                    <div className="heading-type-1">
                                        {moment(log.logCreatedDate).format('DD-MM-YYYY')}
                                    </div>
                                </div>
                                <div className="accordion-wrapper">
                                    <div className="layout-holder">
                                        {getValidValues(log)}
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Stock On Hand</h6>
                                            <div className="heading-desc-1">{log.stockOnHand || 0}</div>
                                        </div>
                                    </div>
                                    {log.adjHoldCmnt || log.adjAvailableCmnt ? (
                                        <div className="accordion-section section-header">
                                            <input
                                                type="checkbox"
                                                className="accordion-radio"
                                                id={`ac_${indx}`}
                                                name="shipping-accordion-group"
                                            ></input>
                                            <label className="accordion-title" htmlFor={`ac_${indx}`}>
                                                <div className="label-header">
                                                    <span className="label-title">Comments</span>
                                                    <svg className="svg-icon accordion-arrow-icon">
                                                        <use xlinkHref="#downArrow">
                                                            <title>dropdown</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                            </label>
                                            <div className="accordion-content">
                                                <div className="modal-main-content-header">
                                                    <div className="main-content-holder">{getComment(log)}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="notification-end">
                        <div className="end-content">
                            <span className="end-contents-item">
                                {logData?.logs?.length
                                    ? 'That’s all Quantity Adjustments from last 60 days'
                                    : 'No Quantity Adjustment Logs'}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sidebar-backdrop"></div>
        </div>
    );
};
export default Index;
