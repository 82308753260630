import React, { ReactElement, useEffect, useState } from 'react';
import * as Yup from 'yup';
import './profile-modal.scss';
import '../../../assets/scss/components/_layout.scss';
import '../../../assets/scss/components/_modal.scss';
import Select from 'react-select';
import { profileModalFieldSettings, reactSelectStyle } from '../../../utils/constants';
import { address, vendorBasicDetails } from '../../../services/apis/createVendorApi';
import { useFormik } from 'formik';
import { useAppDispatch } from '../../../hooks';
import CancelModal from '../../common/CancelModal';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { searchUnlocoList } from '../../../store/reducers/createVendorReducer';
import ClickAwayListener from 'react-click-away-listener';
import { validateOrganisationCode } from 'src/services/apis/vendorApi';
import { fetchMgtCountry } from 'src/store/reducers/purchaseOrder/productPoReducer';
// import data from '../../../pages/Profiles/Vendors/BasicDetail/data.json';

interface List {
    parentList?: {
        value: string;
        label: string;
    }[];
    manufacturingCategoryList?: {
        value: string;
        label: string;
    }[];
    defaultIncoTermsList?: {
        value: string;
        label: string;
    }[];
    organisationCategoryList?: {
        value: string;
        label: string;
    }[];
    unLocoList?: {
        value: string;
        label: string;
    }[];
    countryList?: {
        value: string;
        label: string;
        extraField?: string;
    }[];
}

type payloadValues = vendorBasicDetails | address;

interface ProfileModalProps {
    tabName: 'vendor';
    modalName: 'profileInfo' | 'addressInfo';
    isEdit: boolean;
    initialValues: payloadValues;
    onSave: (data: any) => void;
    list: List;
    dismissModal: () => void;
}

const ProfileModal = ({
    tabName,
    modalName,
    isEdit,
    initialValues,
    onSave,
    list,
    dismissModal,
}: ProfileModalProps): ReactElement => {
    const [isLoading, _setIsLoading] = useState(false);
    const [isChild, setIsChild] = useState(false);
    const [validations, setValidations] = useState<any>({});
    const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const { vendorBasicDetails, isUnlocoLoading } = useSelector((state: RootState) => state.vendorDetails);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchText, setSearchText] = useState('');
    const [unlocoLoading, setUnlocoLoading] = useState(false);
    const [searchResultArray, setSearchResultArray] = useState<{ value: string; label: string }[]>(
        list.manufacturingCategoryList ? list.manufacturingCategoryList : []
    );
    const dispatch = useAppDispatch();
    useEffect(() => {
        const schemaObject = {} as any;
        for (const item of profileModalFieldSettings[tabName][modalName].content) {
            schemaObject[item.idName] = item.validation;
        }
        setValidations(schemaObject);
        const searchKey = formik.values['vn_origin_country' as keyof typeof formik.values]
            ? formik.values['vn_origin_country' as keyof typeof formik.values]
            : formik.values['country' as keyof typeof formik.values]
            ? formik.values['country' as keyof typeof formik.values]
            : null;
        dispatch(fetchMgtCountry({ search: searchKey }));
    }, []);

    useEffect(() => {
        if (isEdit && vendorBasicDetails?.is_Child && modalName === 'profileInfo') {
            setIsChild(vendorBasicDetails.is_Child);
        }
    }, [initialValues]);

    useEffect(() => {
        if (modalName === 'profileInfo') {
            formik.setFieldValue('is_Child', isChild);
        }
    }, [isChild]);
    useEffect(() => {
        if (isUnlocoLoading && searchTerm?.length > 2) {
            setUnlocoLoading(true);
        } else {
            setUnlocoLoading(false);
        }
    }, [isUnlocoLoading, searchTerm]);
    const formik = useFormik({
        initialValues,
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: Yup.object().shape(validations),
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                const payload = JSON.parse(JSON.stringify(values));
                if (!isChild) {
                    const hiddenFieldIds = (profileModalFieldSettings[tabName][modalName].content as any[])
                        .filter((i) => i.canBeHidden)
                        .map((i) => i.idName);
                    for (const id of hiddenFieldIds) {
                        payload[id as keyof typeof values] = null;
                    }
                }
                onSave(payload);
            }
        },
    });

    useEffect(() => {
        if (!formik.isSubmitting) {
            return;
        }
        if (Object.keys(formik.errors).length > 0) {
            const fieldName = Object.keys(formik.errors)[0];
            document?.getElementsByName(fieldName)[0]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [formik]);

    const onCheckboxClick = (checked: boolean, option: { value: string; label: string }, fieldName: string) => {
        if (checked) {
            const data = JSON.parse(JSON.stringify(formik.values[fieldName as keyof typeof formik.values] as string[]));
            data.push(option.label);
            formik.setFieldValue(fieldName, data);
        } else {
            const data = JSON.parse(JSON.stringify(formik.values[fieldName as keyof typeof formik.values] as string[]));
            const index = data.indexOf(option.label);
            if (index > -1) {
                data.splice(index, 1);
                formik.setFieldValue(fieldName, data);
            }
        }
    };

    const removeOnClick = (id: string, fieldName: string) => {
        const data = JSON.parse(JSON.stringify(formik.values[fieldName as keyof typeof formik.values] as string[]));
        const index = data.indexOf(id);
        if (index > -1) {
            data.splice(index, 1);
            formik.setFieldValue(fieldName, data);
        }
    };

    const onSearch = (e: any, listKey: string) => {
        setSearchText(e.target.value);
        if (e.target.value?.trim().length) {
            const searchResult = list[listKey as keyof List]
                ?.map((a) =>
                    a.label.toLocaleLowerCase().includes(e.target.value?.trim().toLocaleLowerCase()) ? a : false
                )
                .filter(Boolean) as any[];
            setSearchResultArray(searchResult);
        } else {
            setSearchResultArray(list.manufacturingCategoryList ? list.manufacturingCategoryList : []);
        }
    };

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            dispatch(searchUnlocoList({ searchTerm, service: 'mgt' }));
        }
    }, [searchTerm]);

    // const checkForMandatoryFieldsForProfile = () => {
    //     const modalValues = formik.values as any;
    //     if (isEdit) {
    //         return !formik.dirty;
    //     }
    //     if (modalValues['is_Child']) {
    //         if (
    //             !modalValues['vn_default_incoterms'] ||
    //             modalValues['vn_manufacturing_categories'].length === 0 ||
    //             !modalValues['vn_name'] ||
    //             !modalValues['vn_org_category'] ||
    //             !modalValues['vn_org_code'] ||
    //             !modalValues['vn_origin_country'] ||
    //             !modalValues['vn_parent'] ||
    //             !modalValues['vn_phone'] ||
    //             !modalValues['vn_unloco']
    //         ) {
    //             return true;
    //         }
    //     } else {
    //         if (
    //             !modalValues['vn_default_incoterms'] ||
    //             modalValues['vn_manufacturing_categories'].length === 0 ||
    //             !modalValues['vn_name'] ||
    //             !modalValues['vn_org_category'] ||
    //             !modalValues['vn_org_code'] ||
    //             !modalValues['vn_origin_country'] ||
    //             !modalValues['vn_phone'] ||
    //             !modalValues['vn_unloco']
    //         ) {
    //             return true;
    //         }
    //     }
    //     return false;
    // };

    // const checkForMandatoryFieldsForAddress = () => {
    //     const modalValues = formik.values as any;
    //     if (isEdit) {
    //         return !formik.dirty;
    //     }
    //     if (
    //         !modalValues['address1'] ||
    //         !modalValues['city'] ||
    //         !modalValues['country'] ||
    //         !modalValues['postCode'] ||
    //         !modalValues['state']
    //     ) {
    //         return true;
    //     }
    //     return false;
    // };
    const profileVal = ['vn_org_code', 'vn_phone', 'vn_website_url'];
    const onBlurFn = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        if (profileVal.includes(e.target.name) && e.target.value) {
            formik.validateField(e.target.name);
        }
        if (e.target.name === 'vn_org_code') {
            validateOrganisationCode(e.target.value).then((res: any) => {
                if (res?.toLowerCase() === 'existing') {
                    formik.setFieldError('vn_org_code', 'Organisation code already exist');
                }
            });
        }
    };

    const onSelectChange = (search: string) => {
        dispatch(fetchMgtCountry({ search }));
    };
    const renderOptions = {
        text: (item: any, index: number) => {
            return (
                <div className="modal-input-holder" key={index}>
                    <label className="modal-input-label">
                        {item.fieldName}
                        {item.isRequired ? <span className="mandatory-field-text">*</span> : null}
                    </label>
                    <div className="modal-input-layout-item">
                        <input
                            placeholder={item.placeholder}
                            disabled={isEdit && item.disabled ? true : false}
                            type="text"
                            name={item.idName}
                            className="modal-input input-text"
                            id={item.idName}
                            onBlur={(e) => {
                                onBlurFn(e);
                            }}
                            onChange={(event) => {
                                // eslint-disable-next-line no-undefined
                                formik.setFieldError(event.target.name, undefined);
                                formik.handleChange(event);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            value={
                                formik.values[item.idName as keyof typeof formik.values] as
                                    | string
                                    | number
                                    | readonly string[]
                                    | undefined
                            }
                        ></input>
                        {formik.errors[item.idName as keyof typeof formik.errors] ? (
                            <div className="error-text">{formik.errors[item.idName as keyof typeof formik.errors]}</div>
                        ) : null}
                    </div>
                </div>
            );
        },
        number: (item: any, index: number) => {
            return (
                <div className="modal-input-holder" key={index}>
                    <label className="modal-input-label">
                        {item.fieldName}
                        {item.isRequired ? <span className="mandatory-field-text">*</span> : null}
                    </label>
                    <div className="modal-input-layout-item">
                        <input
                            placeholder={item.placeholder}
                            name={item.idName}
                            type="text"
                            className="modal-input input-text"
                            id={item.idName}
                            onBlur={(e) => {
                                onBlurFn(e);
                            }}
                            onChange={(event) => {
                                // eslint-disable-next-line no-undefined
                                formik.setFieldError(event.target.name, undefined);
                                // formik.handleChange(event);
                                formik.setFieldValue('vn_phone', event.target.value?.replaceAll(/\s/g, ''));
                            }}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            value={
                                formik.values[item.idName as keyof typeof formik.values] as
                                    | string
                                    | number
                                    | readonly string[]
                                    | undefined
                            }
                        ></input>
                        {formik.errors[item.idName as keyof typeof formik.errors] ? (
                            <div className="error-text">{formik.errors[item.idName as keyof typeof formik.errors]}</div>
                        ) : null}
                    </div>
                </div>
            );
        },
        dropdown: (item: any, index: number) => {
            return (
                <div className="modal-input-holder" key={index}>
                    <label className="modal-input-label">
                        {item.fieldName}
                        {item.isRequired ? <span className="mandatory-field-text">*</span> : null}
                    </label>
                    <div className="modal-input-field-holder grey-option-box">
                        <select
                            name={item.idName}
                            id={item.idName}
                            value={
                                formik.values[item.idName as keyof typeof formik.values] as
                                    | string
                                    | number
                                    | readonly string[]
                                    | undefined
                            }
                            onChange={(e: any) => {
                                (formik.values[item.idName as keyof typeof formik.values] as
                                    | string
                                    | number
                                    | readonly string[]
                                    | undefined) === ''
                                    ? e.target.classList.add('select-placeholder')
                                    : e.target.classList.remove('select-placeholder');

                                // eslint-disable-next-line no-undefined
                                formik.setFieldError(item.idName, undefined);
                                if (item.idName === 'vn_origin_country') {
                                    const inputValue = e.target.value; // Replace 'desiredLabel' with the label you want to filter by
                                    const filteredList =
                                        list.countryList &&
                                        list.countryList.filter((e: any) => {
                                            return e.label === inputValue;
                                        });

                                    // const [firstTerm, secondTerm] = e?.target.value.split(',');
                                    // formik.setFieldValue('vn_origin_country', firstTerm);
                                    // formik.setFieldValue('vn_originCountryname', firstTerm);
                                    // formik.setFieldValue('vn_origin_country_code', secondTerm);
                                    formik.setFieldValue('vn_origin_country', e?.target.value);
                                    formik.setFieldValue('vn_originCountryname', e?.target.value);
                                    filteredList &&
                                        formik.setFieldValue('vn_origin_country_code', filteredList[0].value);
                                } else {
                                    formik.setFieldValue(item.idName, e?.target.value);
                                }
                            }}
                            className={`modal-input input-text with-border ${
                                !(formik.values[item.idName as keyof typeof formik.values] as
                                    | string
                                    | number
                                    | readonly string[]
                                    | undefined)
                                    ? 'select-placeholder'
                                    : ''
                            }`}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                        >
                            <>
                                <option value="" disabled selected hidden style={{ color: '#757575' }}>
                                    {item.placeholder}
                                </option>
                                {list[item.list as keyof List]?.map(
                                    (option: { label: string; value: string }, index: number) => (
                                        <option key={index} value={option.label}>
                                            {item.idName === 'vn_origin_country' || item.idName === 'country'
                                                ? `${option.label} (${option.value})`
                                                : option.label}
                                        </option>
                                    )
                                )}
                            </>
                        </select>
                        <span className="dropdown-arrow-custom">
                            <svg className="svg-icon dropdown-icon">
                                <use href="#downArrow"></use>
                            </svg>
                        </span>
                        {formik.errors[item.idName as keyof typeof formik.errors] ? (
                            <div className="error-text">{formik.errors[item.idName as keyof typeof formik.errors]}</div>
                        ) : null}
                    </div>
                </div>
            );
        },
        select: (item: any, index: number) => {
            if (item.canBeHidden && !isChild) {
                return null;
            }
            return (
                <div className="modal-input-holder" key={index}>
                    <label htmlFor="name" className="modal-input-label">
                        {item.fieldName}
                        {item.isRequired ? <span className="mandatory-field-text">*</span> : null}
                    </label>
                    <div className="modal-input-layout-item">
                        <Select
                            options={
                                item.idName === 'vn_unloco' && searchTerm?.length < 3
                                    ? []
                                    : list[item.list as keyof List]
                            }
                            // options={list[item.list as keyof List]}
                            isClearable
                            placeholder={
                                item.idName === 'vn_unloco' && list[item.list as keyof List]?.length
                                    ? 'Enter UNLOCO'
                                    : item.placeholder
                            }
                            onChange={(option: any) => {
                                // eslint-disable-next-line no-undefined
                                formik.setFieldError(item.idName, undefined);
                                formik.setFieldValue(item.idName, option?.value);
                            }}
                            onInputChange={(text: string) => {
                                if (item.searchable) {
                                    setSearchTerm(text);
                                }
                            }}
                            isLoading={unlocoLoading}
                            noOptionsMessage={({ inputValue }) =>
                                item.idName === 'vn_unloco' && inputValue?.length < 3
                                    ? 'Enter at least 3 characters to search'
                                    : 'No results found'
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            id={item.idName}
                            name={item.idName}
                            // defaultInputValue={
                            //     item.idName === 'vn_parent'
                            //         ? list[item.list as keyof typeof list]?.find(
                            //               (x) => x.label === formik.values[item.idName as keyof typeof formik.values]
                            //           )?.label
                            //         : formik.values[item.idName as keyof typeof formik.values]
                            // }
                            value={
                                list[item.list as keyof List]?.length
                                    ? list[item.list as keyof List]?.filter((option: any) => {
                                          if (
                                              option.value === formik.values[item.idName as keyof typeof formik.values]
                                          ) {
                                              return option;
                                          } else {
                                              return null;
                                          }
                                      })
                                    : formik.values[item.idName as keyof typeof formik.values]
                                    ? {
                                          value: formik.values[item.idName as keyof typeof formik.values],
                                          label: formik.values[item.idName as keyof typeof formik.values],
                                      }
                                    : null
                            }
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            styles={reactSelectStyle}
                        />
                        {formik.errors[item.idName as keyof typeof formik.errors] ? (
                            <div className="error-text">{formik.errors[item.idName as keyof typeof formik.errors]}</div>
                        ) : null}
                    </div>
                </div>
            );
        },
        selectCountry: (item: any, index: number) => {
            let prevValue = '';
            const getValue = () => {
                if (!formik.values[item.idName as keyof typeof formik.values]) {
                    return null;
                }
                const data = JSON.parse(JSON.stringify(list));
                if (item.idName === 'vn_origin_country') {
                    return {
                        value: formik.values[item.idName as keyof typeof formik.values],
                        label: `${formik.values['vn_origin_country' as keyof typeof formik.values]} (${
                            formik.values['vn_origin_country_code' as keyof typeof formik.values]
                        })`,
                    };
                } else {
                    const value = data[item.list as keyof List]?.find(
                        (res: any) =>
                            res.value === formik.values[item.idName as keyof typeof formik.values] ||
                            res.extraField === formik.values[item.idName as keyof typeof formik.values]
                    );
                    if (!value) {
                        return null;
                    }
                    return {
                        value: formik.values[item.idName as keyof typeof formik.values],
                        label: `${value?.extraField as any} (${value?.value as any})`,
                    };
                }
            };

            return (
                <div className="modal-input-holder" key={index}>
                    <label htmlFor="name" className="modal-input-label">
                        {item.fieldName}
                        {item.isRequired ? <span className="mandatory-field-text">*</span> : null}
                    </label>
                    <div className="modal-input-layout-item">
                        <Select
                            options={list[item.list as keyof List] as any}
                            placeholder={item.placeholder}
                            onChange={(option: any, { action }) => {
                                // eslint-disable-next-line no-undefined
                                if (item.idName === 'vn_origin_country') {
                                    formik.setFieldValue('vn_origin_country', option?.extraField);
                                    formik.setFieldValue('vn_originCountryname', option?.extraField);
                                    formik.setFieldValue('vn_origin_country_code', option?.value);
                                    formik.setFieldError(item.idName, undefined);
                                } else {
                                    formik.setFieldError(item.idName, undefined);
                                    formik.setFieldValue(item.idName, option?.value);
                                }
                                if (action === 'clear') {
                                    dispatch(fetchMgtCountry({}));
                                }
                            }}
                            onInputChange={(text: string, actionMeta) => {
                                prevValue = prevValue === 'set-value' ? '' : actionMeta.action;
                                if (text?.trim()?.length > 2) {
                                    onSelectChange(text);
                                } else if (
                                    actionMeta.action === 'menu-close' &&
                                    actionMeta.prevInputValue !== '' &&
                                    prevValue
                                ) {
                                    if (formik.values[item.idName as keyof typeof formik.values]) {
                                        const data = JSON.parse(JSON.stringify(list));
                                        const value = data[item.list as keyof List]?.find(
                                            (res: any) =>
                                                res.value ===
                                                    formik.values[item.idName as keyof typeof formik.values] ||
                                                res.extraField ===
                                                    formik.values[item.idName as keyof typeof formik.values]
                                        );
                                        const searchKey = formik.values[
                                            'vn_origin_country' as keyof typeof formik.values
                                        ]
                                            ? formik.values['vn_origin_country' as keyof typeof formik.values]
                                            : formik.values['country' as keyof typeof formik.values]
                                            ? value?.extraField
                                            : null;
                                        dispatch(fetchMgtCountry({ search: searchKey }));
                                    } else {
                                        dispatch(fetchMgtCountry({}));
                                    }
                                }
                            }}
                            isClearable={true}
                            noOptionsMessage={({ inputValue }) =>
                                inputValue?.length < 3 ? 'Enter at least 3 characters to search' : 'No results found'
                            }
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                    e.preventDefault();
                                }
                            }}
                            id={item.idName}
                            name={item.idName}
                            value={getValue()}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            styles={reactSelectStyle}
                        />
                        {formik.errors[item.idName as keyof typeof formik.errors] ? (
                            <div className="error-text">{formik.errors[item.idName as keyof typeof formik.errors]}</div>
                        ) : null}
                    </div>
                </div>
            );
        },
        multiSelect: (item: any, index: number) => {
            return (
                <div className=" modal-input-holder" key={index}>
                    <label htmlFor="name" className="modal-input-label">
                        {item.fieldName}
                        {item.isRequired ? <span className="mandatory-field-text">*</span> : null}
                    </label>
                    <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
                        <div className="modal-input-layout-item">
                            {/* Search suggestions dropdown */}
                            <div
                                className={`dropdown-wrap  menu-down ${
                                    showDropdown ? 'dropdown-open' : ''
                                } text-dropdown`}
                            >
                                <button
                                    type="button"
                                    name={item.idName}
                                    id={item.idName}
                                    className="menu-btn app-btn select-modal"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        setShowDropdown(!showDropdown);
                                    }}
                                >
                                    {(formik.values[item.idName as keyof typeof formik.values] as string[]).length ? (
                                        <span className="btn-text">
                                            {`${
                                                (formik.values[item.idName as keyof typeof formik.values] as string[])
                                                    .length
                                            } selected`}
                                        </span>
                                    ) : (
                                        <span className="btn-text"></span>
                                    )}
                                    <span className="placeholder-text">{item.placeholder}</span>
                                    <span className="dropdown-arrow">
                                        <svg className="svg-icon arrow-icon">
                                            <use xlinkHref="#downArrow"></use>
                                        </svg>
                                    </span>
                                </button>

                                <div className="dropdown-menu">
                                    <>
                                        <div className="search-entry-holder">
                                            <input
                                                placeholder="Search"
                                                type="text"
                                                value={searchText}
                                                className="search-input input-text"
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onChange={(e) => onSearch(e, item.list)}
                                            ></input>
                                            <svg className="svg-icon search-icon">
                                                <use xlinkHref="#searchIcon"></use>
                                            </svg>
                                            <button
                                                type="button"
                                                className="app-btn text-close-btn"
                                                onClick={() => {
                                                    setSearchText('');
                                                    setSearchResultArray(
                                                        list.manufacturingCategoryList
                                                            ? list.manufacturingCategoryList
                                                            : []
                                                    );
                                                }}
                                            >
                                                <span>
                                                    <svg className="svg-icon modal-header-close-icon-2">
                                                        <use xlinkHref="#closeIcon">
                                                            <title>Close</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                        {searchResultArray?.map(
                                            (option: { value: string; label: string }, index: number) => (
                                                <a className="menu-item app-btn" key={index}>
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox-input"
                                                                checked={(
                                                                    formik.values[
                                                                        item.idName as keyof typeof formik.values
                                                                    ] as string[]
                                                                ).includes(option.label)}
                                                                onClick={(event: any) => {
                                                                    onCheckboxClick(
                                                                        event.target.checked,
                                                                        option,
                                                                        item.idName
                                                                    );
                                                                    // eslint-disable-next-line no-undefined
                                                                    formik.setFieldError(item.idName, undefined);
                                                                }}
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon tick-icon">
                                                                    <use href="#tickIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">{option.label}</div>
                                                        </label>
                                                    </div>
                                                </a>
                                            )
                                        )}
                                    </>
                                </div>
                            </div>

                            {/* Search suggestions capsule */}
                            <div className="suggestion-container">
                                {(formik.values[item.idName as keyof typeof formik.values] as string[]).map(
                                    (selectedItem: string, index: number) => (
                                        <div className="content-card" key={index}>
                                            <span className="main-content">
                                                {
                                                    list[item.list as keyof typeof list]?.find(
                                                        (x) => x.label === selectedItem
                                                    )?.label
                                                }
                                            </span>
                                            <button
                                                className="app-btn close-btn"
                                                title="Close"
                                                type="button"
                                                onClick={() => {
                                                    removeOnClick(selectedItem, item.idName);
                                                }}
                                            >
                                                <span>
                                                    <svg className="svg-icon circle-close-icon">
                                                        <use href="#closeIcon">
                                                            <title>Close</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    )
                                )}
                            </div>
                            {formik.errors[item.idName as keyof typeof formik.errors] ? (
                                <div className="error-text">
                                    {formik.errors[item.idName as keyof typeof formik.errors]}
                                </div>
                            ) : null}
                        </div>
                    </ClickAwayListener>
                </div>
            );
        },
    } as any;

    return (
        <div className="app-modal">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder basic-detail-content-holder medium-popup">
                <div className="modal-header">
                    <div className="title">{profileModalFieldSettings[tabName][modalName].modalHeader}</div>
                    <button
                        type="reset"
                        form="profile-form"
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            formik.dirty ? setCancelModalVisibility(true) : dismissModal();
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    {modalName === 'profileInfo' && tabName === 'vendor' && !isEdit ? (
                        <div className="modal-checkbox-wrapper">
                            <div className="checkbox-item">
                                <label className="app-check-wrapper">
                                    <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        onChange={() => {
                                            setIsChild(false);
                                        }}
                                        checked={!isChild}
                                    ></input>
                                    <div className="checkmark">
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className="checkbox-label">Parent Vendor</div>
                                </label>
                            </div>
                            <div className="checkbox-item">
                                <label className="app-check-wrapper">
                                    <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        onChange={() => {
                                            setIsChild(true);
                                        }}
                                        checked={isChild}
                                    ></input>
                                    <div className="checkmark">
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className="checkbox-label">Child Vendor</div>
                                </label>
                            </div>
                        </div>
                    ) : null}

                    <div className="modal-main-content sop-modal-main-content">
                        <form
                            id="profile-form"
                            action=""
                            className="modal-form layout-holder one-column"
                            onSubmit={(e) => {
                                e.preventDefault();
                                formik.handleSubmit(e);
                            }}
                        >
                            {profileModalFieldSettings[tabName][modalName].content?.map((item: any, index: number) =>
                                renderOptions[item.fieldType](item, index)
                            )}
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn text-close-btn"
                        type="reset"
                        form="profile-form"
                        onClick={() => {
                            formik.dirty ? setCancelModalVisibility(true) : dismissModal();
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="profile-form"
                        // disabled={
                        //     modalName === 'profileInfo'
                        //         ? checkForMandatoryFieldsForProfile()
                        //         : checkForMandatoryFieldsForAddress()
                        // }
                    >
                        <span className="button-text vendor-button-text">Save</span>
                    </button>
                </div>
            </div>
            <CancelModal
                displayFunction={dismissModal}
                mode={'edit'}
                cancelModalVisibility={cancelModalVisibility}
                setCancelModalVisibility={setCancelModalVisibility}
                header={profileModalFieldSettings[tabName][modalName].modalHeader}
            />
        </div>
    );
};
export default ProfileModal;
