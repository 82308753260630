import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import './agent-list.scss';
import './index.scss';
import RateTab from '../Tab';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import RateHeader from '../Header';

import GridLayout from './grid';
import { useParams } from 'react-router-dom';

const Index: React.FC = () => {
    const { id } = useParams();

    const navData = [
        {
            title: 'Rate Input',
            selected: 0,
            path: id ? `/qms/rate/${id}` : '/qms/rate',
        },
    ];

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={'Rates'}
                        firstUrl={'/qms/rate'}
                        secondName={'Rate Input'}
                        secondUrl={'#'}
                        backUrl={'/qms/rate'}
                    />
                    <RateHeader></RateHeader>
                    <RateTab navData={navData} />
                </div>
                <div className="main-content-holder no-main-grid-border booking-grid">
                    <h2 className="page-heading">Rate Terms</h2>
                    <div className="grid-section grid-p-0">
                        <div className="grid-holder">
                            <HelmetProvider>
                                <div className="insight-agent-list-data-trend">
                                    <div className={`container-fluid`}>
                                        <div className="k-kendo-row one-column quote-term-column">
                                            <div className="k-kendo-col-2 custom-kendo-grid quote-term">
                                                <div id={'grid'}></div>
                                            </div>
                                        </div>
                                    </div>
                                    <GridLayout key={id} />
                                </div>
                            </HelmetProvider>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
