/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { ReactComponent as Arrow } from './arrow.svg';
import { debounce } from 'lodash';
import { getPortMatching } from 'src/services/apis/quoteAPi';
type Props = {
    title: string;
    keyName: string;
    quoteData: any;
    setQuoteData: any;
    quoteItemData: any;
    setQuoteItemData: any;
    loading: any;
    saveTriggered: boolean;
    isRequired: boolean;
    checkInvalidPort: any;
};

const QuoteItemPortCard: React.FC<Props> = ({
    title,
    keyName,
    quoteData,
    setQuoteData,
    quoteItemData,
    setQuoteItemData,
    loading,
    saveTriggered,
    checkInvalidPort,
    isRequired,
}) => {
    const [dropdownLoading, setDropdownLoading] = useState(false);
    const [currentSelected, setCurrentSelected] = useState('');

    const getQuoteItemDropDownData = () => {
        return quoteItemData;
    };

    useEffect(() => {
        if (quoteData?.[keyName]?.length === 0) {
            const copy = JSON.parse(JSON.stringify(quoteData));
            copy?.[keyName]?.push({ origin: { label: '', value: '' }, destination: { label: '', value: '' } });
            setQuoteData(copy);
        }
    }, [quoteData?.[keyName]]);

    const addItem = () => {
        const copy = JSON.parse(JSON.stringify(quoteData));
        copy?.[keyName]?.push({ origin: { label: '', value: '' }, destination: { label: '', value: '' } });
        setQuoteData(copy);
    };

    const saveItem = (value: string, index: number, type: string) => {
        const filtered = quoteItemData?.find((res: any) => res?.value === value);
        const copy = JSON.parse(JSON.stringify(quoteData));
        if (type === 'origin') {
            copy[keyName][index]['origin'] = filtered ? filtered : { label: '', value: '' };
        } else {
            copy[keyName][index]['destination'] = filtered ? filtered : { label: '', value: '' };
        }
        setQuoteData(copy);
    };

    const removeItem = (index: number) => {
        const copy = JSON.parse(JSON.stringify(quoteData));
        if (copy?.[keyName]?.length <= 1) {
            copy?.[keyName]?.splice(index, 1);
            copy?.[keyName]?.push({ origin: { label: '', value: '' }, destination: { label: '', value: '' } });
            setQuoteData(copy);
        } else {
            copy?.[keyName]?.splice(index, 1);
            setQuoteData(copy);
        }
    };

    const getData = debounce(async (value: string) => {
        setDropdownLoading(true);
        const provider = await getPortMatching(value);
        setQuoteItemData(provider);
        setDropdownLoading(false);
    }, 500);

    return (
        <div className="detail-box-common">
            <h6 className="details-box-heading-2">
                {title} {isRequired && <span className="mandatory-field-text">*</span>}
            </h6>
            {quoteData?.[keyName]
                ?.filter((res: any) => res?.isSaved !== false)
                ?.map((res: any, index: number) => (
                    <div
                        key={index + '_' + res?.origin?.value + '_' + res?.destination?.value}
                        className="details-box-detail"
                    >
                        <div className="details-box-detail-dropdown-port">
                            <SearchableSelectBox
                                data={getQuoteItemDropDownData()}
                                placeholder="Choose one item"
                                setStateValue={(value: string) => saveItem(value, index, 'origin')}
                                stateValue={res?.origin?.value}
                                fieldName={`${keyName}-originPort-${res?.origin?.value}-${index}`}
                                isClearable={true}
                                isLoading={
                                    loading[keyName] || (dropdownLoading && `originPort-${index}` === currentSelected)
                                }
                                menuPlacement={'top'}
                                overrideValue={
                                    res?.origin?.value ? { label: res?.origin?.value, value: res?.origin?.value } : null
                                }
                                onInputChangeFn={(value: string) => {
                                    if (value?.length > 2) {
                                        getData(value);
                                    }
                                }}
                                onFocusFn={() => {
                                    setCurrentSelected(`originPort-${index}`);
                                    if (getQuoteItemDropDownData()?.length < 1000) {
                                        getData('');
                                    }
                                }}
                            />
                        </div>
                        <div className="arrow">
                            <Arrow />
                        </div>
                        <div className="details-box-detail-dropdown-port">
                            <SearchableSelectBox
                                data={getQuoteItemDropDownData()}
                                placeholder="Choose one item"
                                setStateValue={(value: string) => saveItem(value, index, 'destination')}
                                stateValue={res?.destination?.value}
                                fieldName={`${keyName}-destinationPort-${res?.destination?.value}-${index}`}
                                isClearable={true}
                                isLoading={
                                    loading[keyName] ||
                                    (dropdownLoading && `destinationPort-${index}` === currentSelected)
                                }
                                menuPlacement={'top'}
                                overrideValue={
                                    res?.destination?.value
                                        ? { label: res?.destination?.value, value: res?.destination?.value }
                                        : null
                                }
                                onInputChangeFn={(value: string) => {
                                    if (value?.length > 2) {
                                        getData(value);
                                    }
                                }}
                                onFocusFn={() => {
                                    setCurrentSelected(`destinationPort-${index}`);
                                    if (getQuoteItemDropDownData()?.length < 1000) {
                                        getData('');
                                    }
                                }}
                            />
                        </div>
                        <div className="details-box-detail-btn">
                            <button
                                className="app-btn app-btn-secondary icon-button  add-product-btn"
                                title="Remove"
                                onClick={() => removeItem(index)}
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <use xlinkHref="#dashCheck"></use>
                                </svg>
                            </button>
                            <button
                                onClick={() => addItem()}
                                className="app-btn app-btn-secondary icon-button add-product-btn"
                                title="Add"
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <use xlinkHref="#plusIcon"></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                ))}

            {saveTriggered &&
                isRequired &&
                (!quoteData?.portMatching?.length ||
                    (quoteData?.portMatching[0]?.origin?.value === '' &&
                        quoteData?.portMatching[0]?.destination?.value === '')) && (
                    <div className="error-text">This field is required</div>
                )}
            {saveTriggered && checkInvalidPort() && (
                <div className="error-text">Both origin and destination port are needed</div>
            )}
        </div>
    );
};

export default QuoteItemPortCard;
