import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import { todoListFetching, clearListFetching, setTodoType } from 'src/store/reducers/dashboard/dashboardTodoReducer';
import {
    activityListCountFetch,
    activityListCountFetchBkg,
    // clearActivityList,
} from 'src/store/reducers/dashboard/dashboardActivityFeed';
import ActivityFeedModal from 'src/components/common/Modals/ActivityFeedModal';

import ActivityFeed from './ActivityFeed';
import TodoFeed from './TodoFeed';
import SearchHeader from '../Search/SearchHeader';

import '../dashboard.scss';
import './todo-activity-tab.scss';
import { quickLinks } from '../utils/dashboardActivityFeedConstants';
import { insightTabList } from '../Insights/enums';
import { searchItemFilter } from '../utils';
import {
    clearInsightDestination,
    clearInsightMode,
    setInsightsDurationRange,
} from 'src/store/reducers/dashboard/insightsReducer';
import { FilterList } from 'src/pages/Dashboard/Insights/enums';

const tabs = ['To Do', 'Activity Feed'];

const Index: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const { todoListTotalCount, todoListNewItemTotal, todoList, todoItem } = useSelector(
        (state: RootState) => state.dashboardTodo
    );
    const { activityListCount, activityListCountBkg, activityIsRead, activityIsReadBkg } = useSelector(
        (state: RootState) => state.dashboardActivity
    );
    const { selectedSearchItem, activitySettingsBoolean } = useSelector((state: RootState) => state.dashboarddata);
    const {
        insightTab,
        insightsMode,
        insightsDestination,
        insightsSelectedType,
        insightsMainPage,
        insightsSelectedTypeFilter,
        insightsForwarded,
        favInsightsMode,
        favInsightsDestination,
        favInsightsForwarded,
        favInsightsSelectedType,
        favInsightsSelectedTypeFilter,
    } = useSelector((state: RootState) => state.dashboardInsights);
    const userDetails =
        localStorage?.getItem('wholePerms') != null && JSON.parse(localStorage?.getItem('wholePerms') || '');

    const [activitySettings, setActivitySettings] = useState(false);
    const [tab, setTab] = useState(
        tabs[
            localStorage.getItem('currentTodoTab')
                ? parseInt(localStorage.getItem('currentTodoTab') as any)
                : (location as any)?.state?.selected
                ? (location as any)?.state?.selected
                : 0
        ]
    );
    const [current, setcurrent] = useState(0);
    const [activities, setActivities] = useState(false);
    const { profileType, userName, roleName } = useUserProfile();
    const [favoriteUpdated, setfavoriteUpdated] = useState(false);
    const isPurchaseOrderFn = () => {
        let flag = null;
        if (insightTab === 'All') {
            if (insightsForwarded === 'active_orders') {
                flag = true;
            } else {
                flag = false;
            }
        } else if (insightTab === 'Fav') {
            if (favInsightsForwarded === 'active_orders') {
                flag = true;
            } else {
                flag = false;
            }
        } else {
            flag = null;
        }
        return flag;
    };

    useEffect(() => {
        if (userName) {
            if (
                !selectedSearchItem ||
                selectedSearchItem?.poId ||
                selectedSearchItem?.bpId ||
                selectedSearchItem.type
            ) {
                if (insightTab === insightTabList.all) {
                    let seaAdded = false;
                    if (!todoList?.others) {
                        dispatch(
                            todoListFetching({
                                profileType,
                                roleName: userDetails?.accountManager ? 'Account Manager' : roleName,
                                userName,
                                search: {
                                    selectedSearchItem,
                                    poId: selectedSearchItem?.poId,
                                    bpId: selectedSearchItem?.bpId,
                                    ...searchItemFilter(selectedSearchItem),
                                    isMode: insightsMode?.length > 0 ? true : false,
                                    isBooking: insightsForwarded === 'active_bookings' ? true : false,
                                    mode: insightsMode
                                        ?.map((i) => {
                                            if (i?.toLowerCase() === 'air') {
                                                return 'AIR';
                                            }
                                            if (
                                                i?.toLowerCase() === 'fcl' ||
                                                i?.toLowerCase() === 'lcl' ||
                                                i?.toLowerCase() === 'bcn'
                                            ) {
                                                if (!seaAdded) {
                                                    seaAdded = true; // Set seaAdded to true after adding 'SEA'
                                                    return 'SEA';
                                                }
                                            }
                                            if (i?.toLowerCase() === 'land') {
                                                return 'LND';
                                            }
                                        })
                                        .filter(Boolean)
                                        .flat(),
                                    // mode: insightsMode
                                    //     .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                                    //     .map((i) => {
                                    //         if (i === 'land') {
                                    //             return 'LND';
                                    //         }
                                    //         return i.toUpperCase();
                                    //     }),
                                    destinationType: insightsDestination,
                                    isPurchaseOrder: insightsForwarded
                                        ? insightsForwarded === 'active_orders'
                                            ? true
                                            : false
                                        : null,
                                    isPoBooking:
                                        insightsMainPage?.toLowerCase()?.includes('booking') &&
                                        insightsSelectedType?.toLowerCase() === 'po'
                                            ? true
                                            : false,
                                    isAoBooking:
                                        insightsMainPage?.toLowerCase()?.includes('booking') &&
                                        insightsSelectedType?.toLowerCase() === 'ao'
                                            ? true
                                            : false,
                                    ...(insightsForwarded === 'active_bookings' && {
                                        bookingsType: insightsMode.map((i) => i !== 'sea' && i.toUpperCase()),
                                        bookingStatus: insightsSelectedTypeFilter ? [insightsSelectedTypeFilter] : [],
                                    }),
                                    ...(insightsForwarded === 'active_orders' && {
                                        purchaseOrderType: insightsSelectedType ? [insightsSelectedType] : [],
                                        purchaseOrderStatus: insightsSelectedTypeFilter
                                            ? [insightsSelectedTypeFilter]
                                            : [],
                                    }),
                                },
                            })
                        );
                    }
                } else {
                    let seaAdded = false;
                    if (!todoList?.others) {
                        dispatch(
                            todoListFetching({
                                profileType,
                                roleName: userDetails?.accountManager ? 'Account Manager' : roleName,
                                userName,
                                search: {
                                    selectedSearchItem,
                                    isMode: insightsMode?.length > 0 ? true : false,
                                    poId: selectedSearchItem?.poId,
                                    bpId: selectedSearchItem?.bpId,
                                    mode: insightsMode
                                        ?.map((i) => {
                                            if (i?.toLowerCase() === 'air') {
                                                return 'AIR';
                                            }
                                            if (
                                                i?.toLowerCase() === 'fcl' ||
                                                i?.toLowerCase() === 'lcl' ||
                                                i?.toLowerCase() === 'bcn'
                                            ) {
                                                if (!seaAdded) {
                                                    seaAdded = true; // Set seaAdded to true after adding 'SEA'
                                                    return 'SEA';
                                                }
                                            }
                                            if (i?.toLowerCase() === 'land') {
                                                return 'LND';
                                            }
                                        })
                                        .filter(Boolean)
                                        .flat(),
                                    // mode: favInsightsMode
                                    //     .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                                    //     .map((i) => {
                                    //         if (i === 'land') {
                                    //             return 'LND';
                                    //         }
                                    //         return i.toUpperCase();
                                    //     }),
                                    ...searchItemFilter(selectedSearchItem),
                                    destinationType: favInsightsDestination,
                                    isBooking: favInsightsForwarded === 'active_bookings' ? true : false,
                                    isPurchaseOrder: favInsightsForwarded
                                        ? favInsightsForwarded === 'active_orders'
                                            ? true
                                            : false
                                        : null,
                                    isPoBooking:
                                        (insightsMainPage?.toLowerCase()?.includes('booking') ||
                                            favInsightsForwarded?.toLowerCase() === 'active_bookings') &&
                                        favInsightsSelectedType?.toLowerCase() === 'po'
                                            ? true
                                            : false,

                                    isAoBooking:
                                        (insightsMainPage?.toLowerCase()?.includes('booking') &&
                                            insightsSelectedType?.toLowerCase() === 'ao]') ||
                                        (favInsightsForwarded?.toLowerCase()?.includes('booking') &&
                                            favInsightsSelectedType?.toLowerCase() === 'ao')
                                            ? true
                                            : false,
                                    ...(favInsightsForwarded === 'active_bookings' && {
                                        bookingsType: favInsightsMode.filter((i) => i !== 'sea' && i.toUpperCase()),
                                        bookingStatus: favInsightsSelectedTypeFilter
                                            ? [favInsightsSelectedTypeFilter]
                                            : [],
                                    }),
                                    ...(favInsightsForwarded === 'active_orders' && {
                                        purchaseOrderType: favInsightsSelectedType ? [favInsightsSelectedType] : [],
                                        purchaseOrderStatus: favInsightsSelectedTypeFilter
                                            ? [favInsightsSelectedTypeFilter]
                                            : [],
                                    }),
                                },
                            })
                        );
                    }
                    if (isPurchaseOrderFn()) {
                        // dispatch(
                        //     activityListCountFetch({
                        //         profileType,
                        //         userName,
                        //         search: {
                        //             selectedSearchItem,
                        //             poId: selectedSearchItem?.poId,
                        //             bpId: selectedSearchItem?.bpId,
                        //             mode: favInsightsMode
                        //                 .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                        //                 .map((i) => {
                        //                     if (i === 'land') {
                        //                         return 'LND';
                        //                     }
                        //                     return i.toUpperCase();
                        //                 }),
                        //             ...searchItemFilter(selectedSearchItem),
                        //             destinationType: favInsightsDestination,
                        //             isPurchaseOrder: favInsightsForwarded
                        //                 ? favInsightsForwarded === 'active_orders'
                        //                     ? true
                        //                     : false
                        //                 : null,
                        //             isPoBooking:
                        //                 insightsMainPage?.toLowerCase()?.includes('booking') &&
                        //                 insightsSelectedType?.toLowerCase() === 'po'
                        //                     ? true
                        //                     : false,
                        //             isAoBooking:
                        //                 insightsMainPage?.toLowerCase()?.includes('booking') &&
                        //                 insightsSelectedType?.toLowerCase() === 'ao'
                        //                     ? true
                        //                     : false,
                        //             ...(favInsightsForwarded === 'active_bookings' && {
                        //                 bookingsType: favInsightsMode.filter((i) => i !== 'sea' && i.toUpperCase()),
                        //                 bookingStatus: favInsightsSelectedTypeFilter
                        //                     ? [favInsightsSelectedTypeFilter]
                        //                     : [],
                        //             }),
                        //             ...(favInsightsForwarded === 'active_orders' && {
                        //                 purchaseOrderType: favInsightsSelectedType ? [favInsightsSelectedType] : [],
                        //                 purchaseOrderStatus: favInsightsSelectedTypeFilter
                        //                     ? [favInsightsSelectedTypeFilter]
                        //                     : [],
                        //             }),
                        //         },
                        //     })
                        // );
                    } else {
                        // dispatch(
                        //     activityListCountFetchBkg({
                        //         profileType,
                        //         userName,
                        //         search: {
                        //             selectedSearchItem,
                        //             poId: selectedSearchItem?.poId,
                        //             bpId: selectedSearchItem?.bpId,
                        //             mode: favInsightsMode
                        //                 .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                        //                 .map((i) => {
                        //                     if (i === 'land') {
                        //                         return 'LND';
                        //                     }
                        //                     return i.toUpperCase();
                        //                 }),
                        //             ...searchItemFilter(selectedSearchItem),
                        //             destinationType: favInsightsDestination,
                        //             isPurchaseOrder: favInsightsForwarded
                        //                 ? favInsightsForwarded === 'active_orders'
                        //                     ? true
                        //                     : false
                        //                 : null,
                        //             isPoBooking:
                        //                 insightsMainPage?.toLowerCase()?.includes('booking') &&
                        //                 insightsSelectedType?.toLowerCase() === 'po'
                        //                     ? true
                        //                     : false,
                        //             isAoBooking:
                        //                 insightsMainPage?.toLowerCase()?.includes('booking') &&
                        //                 insightsSelectedType?.toLowerCase() === 'ao'
                        //                     ? true
                        //                     : false,
                        //             ...(favInsightsForwarded === 'active_bookings' && {
                        //                 bookingsType: favInsightsMode.filter((i) => i !== 'sea' && i.toUpperCase()),
                        //                 bookingStatus: favInsightsSelectedTypeFilter
                        //                     ? [favInsightsSelectedTypeFilter]
                        //                     : [],
                        //             }),
                        //             ...(favInsightsForwarded === 'active_orders' && {
                        //                 purchaseOrderType: favInsightsSelectedType ? [favInsightsSelectedType] : [],
                        //                 purchaseOrderStatus: favInsightsSelectedTypeFilter
                        //                     ? [favInsightsSelectedTypeFilter]
                        //                     : [],
                        //             }),
                        //         },
                        //     })
                        // );
                        // dispatch(
                        //     activityListCountFetch({
                        //         profileType,
                        //         userName,
                        //         search: {
                        //             selectedSearchItem,
                        //             poId: selectedSearchItem?.poId,
                        //             bpId: selectedSearchItem?.bpId,
                        //             mode: favInsightsMode
                        //                 .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                        //                 .map((i) => {
                        //                     if (i === 'land') {
                        //                         return 'LND';
                        //                     }
                        //                     return i.toUpperCase();
                        //                 }),
                        //             ...searchItemFilter(selectedSearchItem),
                        //             destinationType: favInsightsDestination,
                        //             isPurchaseOrder: favInsightsForwarded
                        //                 ? favInsightsForwarded === 'active_orders'
                        //                     ? true
                        //                     : false
                        //                 : null,
                        //             isPoBooking:
                        //                 insightsMainPage?.toLowerCase()?.includes('booking') &&
                        //                 insightsSelectedType?.toLowerCase() === 'po'
                        //                     ? true
                        //                     : false,
                        //             isAoBooking:
                        //                 insightsMainPage?.toLowerCase()?.includes('booking') &&
                        //                 insightsSelectedType?.toLowerCase() === 'ao'
                        //                     ? true
                        //                     : false,
                        //             ...(favInsightsForwarded === 'active_bookings' && {
                        //                 bookingsType: favInsightsMode.filter((i) => i !== 'sea' && i.toUpperCase()),
                        //                 bookingStatus: favInsightsSelectedTypeFilter
                        //                     ? [favInsightsSelectedTypeFilter]
                        //                     : [],
                        //             }),
                        //             ...(favInsightsForwarded === 'active_orders' && {
                        //                 purchaseOrderType: favInsightsSelectedType ? [favInsightsSelectedType] : [],
                        //                 purchaseOrderStatus: favInsightsSelectedTypeFilter
                        //                     ? [favInsightsSelectedTypeFilter]
                        //                     : [],
                        //             }),
                        //         },
                        //     })
                        // );
                    }
                }
            } else {
                dispatch(clearListFetching());
            }
        }
    }, [
        selectedSearchItem,
        userName,
        insightsMode,
        insightsDestination,
        insightsForwarded,
        insightsSelectedType,
        insightsSelectedTypeFilter,
        favInsightsMode,
        favInsightsDestination,
        favInsightsForwarded,
        favInsightsSelectedType,
        favInsightsSelectedTypeFilter,
        insightTab,
        favoriteUpdated,
        tab,
        activitySettingsBoolean,
    ]);

    return (
        <div className="card main-wrapper container dashboard-wrapper todo-activity-tab">
            <SearchHeader />
            <div className="tab-with-action">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${tab === tabs[0] ? 'active' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setTab(tabs[0]);
                            setcurrent(0);
                            localStorage.setItem('currentTodoTab', '0');
                        }}
                    >
                        To Do
                        <span className={`count ${todoListNewItemTotal ? 'count-notification' : ''}`}>
                            {todoList?.totalCount?.todo ?? 0}
                        </span>
                    </a>
                    <a
                        className={`tab-nav ${tab === tabs[1] ? 'active' : ''}`}
                        onClick={(e) => {
                            e.stopPropagation();
                            setTab(tabs[1]);
                            setcurrent(1);
                            localStorage.setItem('currentTodoTab', '1');
                        }}
                    >
                        Activity Feed
                        {/* <span className={`count ${activityIsRead || activityIsReadBkg ? 'count-notification' : ''}`}> */}
                        <span className="count">
                            {/* {isPurchaseOrderFn() ? activityListCount : activityListCount + activityListCountBkg} */}
                            {todoList?.totalCount?.activity ?? 0}
                        </span>
                        {/* count-notification */}
                    </a>
                </div>
                <div className="header-btn-holder">
                    {/* {tab === tabs[1] && (
                        <button
                            className="view-nav grid-icon icon-nav"
                            onClick={(e) => {
                                e.stopPropagation();
                                setActivitySettings(true);
                            }}
                            title="Settings"
                        >
                            <svg className="svg-icon maxi-icon ">
                                <use xlinkHref="#settingIcon">
                                    <title>Settings</title>
                                </use>
                            </svg>
                        </button>
                    )} */}
                    <button
                        className="view-nav grid-icon icon-nav "
                        title="Collapse"
                        onClick={() => {
                            navigate('/', {
                                state: {
                                    selected: localStorage.getItem('currentTodoTab')
                                        ? parseInt(localStorage.getItem('currentTodoTab') as any)
                                        : (location as any)?.state?.selected
                                        ? (location as any)?.state?.selected
                                        : current,
                                },
                            });
                            dispatch(clearInsightMode());
                            dispatch(clearInsightDestination());
                            dispatch(setInsightsDurationRange(FilterList.Days));
                            dispatch(setTodoType({ type: null }));
                        }}
                    >
                        <svg className="svg-icon maxi-icon ">
                            <use xlinkHref="#minimaxIcon">
                                <title>Collapse</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            {/* {tab === tabs[0] && ( */}
            <TodoFeed setfavoriteUpdated={setfavoriteUpdated} favoriteUpdated={favoriteUpdated} tab={tab} />
            {/* )} */}
            {/* {tab === tabs[1] && <ActivityFeed tab={tab} activities={activities} />} */}
            <ActivityFeedModal
                activities={activities}
                setActivities={setActivities}
                modalHandle={setActivitySettings}
                data={quickLinks}
                modalOpen={activitySettings}
            />
        </div>
    );
};
export default Index;
