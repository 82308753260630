/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
// import ConfirmBookingModal from './ConfirmBookingModal';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchProductDetailsConfirmationList,
    fetchLclThreshold,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import Breadcrumbs from 'src/components/Breadcrumbs/purchaseOrder';
import './index.scss';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';

import { useUserProfile } from 'src/hooks/useUserProfile';
// import { Formik } from 'formik';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';

const Index: React.FC = () => {
    const { id } = useParams();
    const { userName, fullName } = useUserProfile();
    const dispatch = useAppDispatch();
    const headerData = useAppSelector(selectHeader);

    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;

    useEffect(() => {
        // dispatch(
        //     fetchProductDetailsConfirmationList({
        //         poId: id,
        //     })
        // );
        dispatch(fetchPermissions(username));
        dispatch(fetchLclThreshold({ userName }));
        // dispatch(fetchPort({ key: 'a', type: 'PO', service: 'ord' }));
    }, []);

    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={`${poName}`}
                        firstUrl={`${poUrl}`}
                        secondName="PO Details"
                        secondUrl={`/purchase_order/${id}/product_details_confirmation`}
                    />
                    <PurchaseOrderHeader id={id} />
                    <PODetailsHeaderTabs />
                </div>
                <div className="lifecycle-grid">
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'lifecycle'} content={'purchase-order-lifecycle'} />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
