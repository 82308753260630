import React, { useCallback, useEffect, useState } from 'react';
import './vendor-integration-details.scss';
import { vendorIntegrationColumnHeaders, vendorIntegrationRowHeaders } from '../../../../utils/constants';
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom';
import HeaderTabs from '../HeaderTabs';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { error, Position, success } from '../../../../utils/toast';
import {
    addVendorIntegrationData,
    fetchVendorIntegrationData,
    submitAndActivate,
} from '../../../../store/reducers/vendorIntegrationReducer';
import CancelModal from '../../Clients/IntegrationDetails/cancelModal';
import ShowForPermissions from '../../../../ShowForPermissions';
import { fetchPermissions } from '../../../../store/reducers/permissionReducer';
import { fetchHeaderDataVendors } from 'src/store/reducers/profiles/vendors';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const { integrationData, vn_pk, isLoading } = useSelector((state: RootState) => state.vendorIntegrationDetails);
    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState<boolean>(false);
    const [integrationDetails, setIntegrationDetails] = useState<any>({});
    const [dataCopy, setDataCopy] = useState<any>({});
    const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
    // const [isSubmitVisible, setIsSubmitVisible] = useState<boolean>(false);
    const [hasDataSaved, setHasDataSaved] = useState<boolean>(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;
    const { vendorName } = useSelector((state: RootState) => state.vendorDetails);
    const { vendorsHeader } = useSelector((state: RootState) => state.profilesVendors) as any;
    /* eslint no-var: off */

    useEffect(() => {
        const payload = { vn_pk: id, integrationData: [] };
        dispatch(fetchVendorIntegrationData(payload));
        dispatch(fetchPermissions(username));
    }, [dispatch, id]);

    // useEffect(() => {
    //     if (!integrationData?.length) {
    //         setShowSaveButton(true);
    //     }
    // }, []);

    useEffect(() => {
        dispatch(fetchHeaderDataVendors(id));
    }, [id, integrationData]);

    useEffect(() => {
        const dataObject: any = {};
        for (const item of integrationData) {
            if (dataObject[item.vi_integrationtype]) {
                dataObject[item.vi_integrationtype].push(item.vi_functiontype);
            } else {
                dataObject[item.vi_integrationtype] = [item.vi_functiontype];
            }
        }
        const data = Object.assign({}, dataObject);
        setIntegrationDetails(data);
        setDataCopy(JSON.parse(JSON.stringify(dataObject)));
    }, [integrationData]);

    const updateIntegrationDataOnCheckboxClick = (integrationType: string, integrationFunction: string, event: any) => {
        const dataObject = integrationDetails;
        if (event.target.checked) {
            if (dataObject[integrationType]) {
                dataObject[integrationType].push(integrationFunction);
            } else {
                dataObject[integrationType] = [integrationFunction];
            }
        } else {
            const index = dataObject[integrationType]?.indexOf(integrationFunction);
            if (index >= 0) {
                dataObject[integrationType].splice(index, 1);
            }
            if (dataObject[integrationType]?.length === 0) {
                delete dataObject[integrationType];
            }
        }
        setIntegrationDetails(dataObject);
        // setShowSaveButton(true);
        const isEmptyObjOrEqualObj = isAllEmptyOrDataSameAsSaved(integrationDetails);
        setShowSaveButton(!isEmptyObjOrEqualObj);
    };

    const isAllEmptyOrDataSameAsSaved = (data: any) => {
        // if (!Object.values(data).some((i: any) => i.length)) {
        //     return true;
        // }
        const copyOfData = dataCopy;
        const dataObject = data;
        if (JSON.stringify(Object.keys(dataObject).sort()) === JSON.stringify(Object.keys(copyOfData).sort())) {
            for (const key in dataObject) {
                if (Object.prototype.hasOwnProperty.call(dataObject, key)) {
                    dataObject[key]?.sort();
                    if (Object.prototype.hasOwnProperty.call(copyOfData, key)) {
                        copyOfData[key]?.sort();
                    }
                }
            }
            let isEqual = true;
            for (const key in copyOfData) {
                if (Object.prototype.hasOwnProperty.call(copyOfData, key)) {
                    if (dataObject[key] && JSON.stringify(dataObject[key]) !== JSON.stringify(copyOfData[key])) {
                        isEqual = false;
                    }
                }
            }
            return isEqual;
        }
        return false;
    };

    const saveIntegrationData = () => {
        const finalDataArray = [];
        if (integrationDetails) {
            for (const key in integrationDetails) {
                if (Object.prototype.hasOwnProperty.call(integrationDetails, key)) {
                    for (const item of integrationDetails[key]) {
                        if (key) {
                            const object = {
                                vi_integrationtype: key,
                                vi_functiontype: item,
                            };
                            finalDataArray.push(object);
                        }
                    }
                }
            }
        }
        if (finalDataArray.length === 0 && localStorage.getItem('vendorStatus') === 'SUBMITTED') {
            error('At least 1 integration type is required', Position.TOP_RIGHT);
            return;
        }
        const payload = {
            vn_pk: vn_pk,
            data: finalDataArray,
            responseOnSuccess: (res: any) => {
                if (res?.success === true) {
                    success('Integration details updated', Position.TOP_RIGHT);
                    const payload = { vn_pk: id, integrationData: [] };
                    dispatch(fetchVendorIntegrationData(payload));
                    dispatch(fetchHeaderDataVendors(id));
                } else if (res.response?.data?.message) {
                    error(res.response.data.message, Position.TOP_RIGHT);
                }
            },
        };
        dispatch(addVendorIntegrationData(payload));
        setShowSaveButton(false);
        setHasDataSaved(true);
    };

    const submit = () => {
        const payload = {
            vn_pk: vn_pk,
            responseOnSuccess: (res: any) => {
                if (res?.success === true) {
                    success('Vendor details submitted', Position.TOP_RIGHT);
                    setTimeout(() => navigate('/vendor'), 1000);
                } else if (res) {
                    error(res.response?.data?.message || res.message || 'Something went wrong', Position.TOP_RIGHT);
                }
            },
        };
        dispatch(submitAndActivate(payload));
        setHasDataSaved(false);
    };

    const resetIntegrationDetails = useCallback(() => {
        const obj = JSON.parse(JSON.stringify(dataCopy));
        setIntegrationDetails(obj);
    }, [dataCopy]);

    const onConfirm = useCallback(() => {
        setShowSaveButton(false);
        // setIsSubmitVisible(hasDataSaved);
        resetIntegrationDetails();
        setShowCancelConfirmationModal(false);
    }, [resetIntegrationDetails, hasDataSaved]);

    const onCancelModal = useCallback(() => {
        setShowCancelConfirmationModal(false);
    }, []);

    const checkPermission = (permission: any, type: any) => {
        var flag = true;
        for (var i = 0; i < permissions?.length; i++) {
            if (
                ((permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                    (permissions[i] as any).access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes(type.toLowerCase())) ||
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('alladmin')
            ) {
                flag = false;
            }
        }
        return flag;
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link className="dropdown-arrow" to="/vendor">
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/profile">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/vendor">
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="#" className="breadcrumb-item">
                                Vendor
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    <h2 className="page-heading">
                        {id ? (vendorsHeader?.vendorName ? vendorsHeader?.vendorName : vendorName) : 'Add New Vendor'}
                    </h2>
                </div>
                <div className="arrow-tab-container">
                    <div className="arrow-tab-container">
                        <HeaderTabs />
                    </div>
                </div>
            </div>
            <div className="grid-section integration-grid loader-container vendor-integration-grid grid-p-0-15">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-header">
                    Integration Details
                    <div className="grid-header-btn-holder"></div>
                    {showSaveButton ? (
                        <ShowForPermissions permission="create" type="vendor">
                            <div className="grid-header-btn-holder">
                                <button
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowCancelConfirmationModal(true);
                                    }}
                                    title="Cancel"
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <button
                                    className="app-btn app-btn-primary footer-btn "
                                    onClick={() => {
                                        saveIntegrationData();
                                    }}
                                    title="Save"
                                >
                                    <span className="button-text footer-button-text">Save</span>
                                </button>
                            </div>
                        </ShowForPermissions>
                    ) : null}
                </div>
                <div className="vertical-data-container">
                    <div className="vertical-heading-holder">
                        <div className="vertical-heading header-title">INTEGRATION TYPE</div>
                    </div>
                    {showCancelConfirmationModal ? (
                        <CancelModal onConfirm={onConfirm} onCancel={onCancelModal}></CancelModal>
                    ) : null}
                    <div className="grid-container">
                        <div className="grid-scroll-content">
                            <table className="grid-table empty-dashed">
                                <thead>
                                    <tr className="grid-sticky-row">
                                        <th colSpan={8} className="grid-cell-header centre-align">
                                            <div className="grid-cell-data">
                                                <span className="header-title">Function</span>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="grid-sticky-row">
                                        <th className="grid-cell-header">
                                            <div className="grid-cell-data">
                                                <span className="header-title"></span>
                                            </div>
                                        </th>
                                        {vendorIntegrationColumnHeaders.map((headerText: string, index: number) => (
                                            <th className="grid-cell-header centre-align" key={index}>
                                                <div className="grid-cell-data ">
                                                    <span className="header-title">{headerText}</span>
                                                </div>
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        /* integrationDetails && */
                                        vendorIntegrationRowHeaders.map((header: string, index: number) => (
                                            <tr key={index}>
                                                <td className="grid-cell move-left">
                                                    <div className="grid-cell-data">{header}</div>
                                                </td>
                                                {vendorIntegrationColumnHeaders.map((item: string, index: number) => (
                                                    <td className="grid-cell centre-align" key={index}>
                                                        <div className="grid-cell-data">
                                                            <div className="check-holder">
                                                                <label className="app-check-wrapper">
                                                                    <input
                                                                        key={Math.random()}
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        defaultChecked={integrationDetails[
                                                                            header
                                                                        ]?.includes(item)}
                                                                        onClick={(e: any) => {
                                                                            updateIntegrationDataOnCheckboxClick(
                                                                                header,
                                                                                item,
                                                                                e
                                                                            );
                                                                        }}
                                                                        /* eslint-disable no-undefined */
                                                                        disabled={
                                                                            checkPermission('edit', 'vendor') !==
                                                                                undefined &&
                                                                            checkPermission('edit', 'vendor')
                                                                        }
                                                                    />
                                                                    <div className="checkmark">
                                                                        <svg className="svg-icon tick-icon">
                                                                            <use xlinkHref="#tickIcon">
                                                                                <title>check mark</title>
                                                                            </use>
                                                                        </svg>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </td>
                                                ))}
                                            </tr>
                                        ))
                                    }
                                </tbody>
                                {/* integration detail view only */}
                                {/* <tbody>
                                    <tr>
                                        <td className="grid-cell move-left">
                                            <div className="grid-cell-data">None</div>
                                        </td>
                                        <td className="grid-cell centre-align">
                                            <div className="grid-cell-data">
                                                <div className="check-holder">
                                                    <div className="checkmark">-</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell centre-align">
                                            <div className="grid-cell-data">
                                                <div className="check-holder">
                                                    <div className="checkmark">-</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="grid-cell move-left">
                                            <div className="grid-cell-data">Type 1</div>
                                        </td>
                                        <td className="grid-cell centre-align">
                                            <div className="grid-cell-data">
                                                <div className="check-holder">
                                                    <div className="checkmark">-</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell centre-align">
                                            <div className="grid-cell-data">
                                                <div className="check-holder">
                                                    <div className="checkmark">-</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="grid-cell move-left">
                                            <div className="grid-cell-data">Type 2</div>
                                        </td>
                                        <td className="grid-cell centre-align">
                                            <div className="grid-cell-data">
                                                <div className="check-holder">
                                                    <div className="checkmark tick-value">
                                                        <svg className="svg-icon tick-icon">
                                                            <use xlinkHref="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell centre-align">
                                            <div className="grid-cell-data">
                                                <div className="check-holder">
                                                    <div className="checkmark novalue">-</div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody> */}
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <NavLink
                        title="Previous"
                        to={`/profile/vendor/${id}/client_details`}
                        className="app-btn app-btn-secondary footer-btn"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </NavLink>
                    {showSaveButton === false &&
                    vendorsHeader &&
                    vendorsHeader.bdTabComplete &&
                    vendorsHeader?.cdTabComplete &&
                    vendorsHeader?.idTabComplete &&
                    localStorage.getItem('vendorStatus') !== 'SUBMITTED' ? (
                        <ShowForPermissions permission="create" type="vendor">
                            <button
                                style={{ marginLeft: '8px' }}
                                className="app-btn app-btn-primary footer-btn"
                                title="Submit and Activate"
                                onClick={() => {
                                    submit();
                                }}
                            >
                                <span className="button-text footer-button-text">Submit and Activate</span>
                            </button>
                        </ShowForPermissions>
                    ) : null}
                    {localStorage.getItem('vendorStatus') === 'SUBMITTED' ? (
                        <button
                            style={{ marginLeft: '8px' }}
                            className="app-btn app-btn-primary footer-btn"
                            title="Back to Profile"
                            onClick={() => {
                                navigate('/profile');
                            }}
                        >
                            <span className="button-text footer-button-text">Back to Profile</span>
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
};
export default Index;
