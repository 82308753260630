import { createSlice } from '@reduxjs/toolkit';
import { availablePoHeaders } from '../../../utils/constants';

export const dataSlice = createSlice({
    name: 'availablePosLcl',
    initialState: {
        selectedItems: [],
        disabledFilterData: {
            coLoader: null,
            originPort: null,
            destinationPort: null,
            vendorName: null,
            clientName: null,
            isHeaderOnlyPo: null,
        },
        filter: {
            createdOn: null,
            goodsReadyDate: null,
            clientName: null,
            coLoader: null,
            originPort: null,
            destinationPort: null,
            vendor: null,
            search: null,
        },
        bpId: null,
        bpNumber: null,
        totalCBM: 0,
        totalKgs: 0,
        infoValues: {
            clientName: '',
            originPort: '',
            destinationPort: '',
            goodsReadyDates: [],
            vendorName: '',
        },
        availablePoList: [],
        filterValues: {},
        isLoading: false,
        isFetching: false,
        columnStatus: availablePoHeaders.poNumber,
        error: false,
        success: false,
        poData: {},
        gateInDate: false,
    },
    reducers: {
        fetchAvailablePoData: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload.columnStatus;
            state.availablePoList = action.payload.response?.availablePoLines;
            state.filterValues = action.payload.response?.distinctColumnValues;
            state.gateInDate = action.payload.gateInDateExpired;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
            state.isFetching = action.payload;
        },
        fetchAvailablePoFilterData: (state, action) => {
            state.isLoading = true;
            state.filterValues = action.payload?.filterData;
            state.isLoading = false;
        },
        addOrSavePoData: (state, action) => {
            if (action.payload?.data?.status == 500 || action.payload?.data?.status == 400) {
                state.error = true;
                state.errorMessage = action.payload?.data?.data?.message;
            } else if (action.payload?.data?.data?.length > 0) {
                const response = action.payload?.data?.data[0];
                state.bpNumber = response.BP_Number;
                state.totalCBM = response.BP_TotCBM;
                state.totalKgs = response.BP_TotKGS;
                state.success = true;
                localStorage.setItem('bpId', response.BP_PK);
                localStorage.setItem('bpNumber', response.BP_Number);
                state.bpId = response.BP_PK;
            }
        },
        getBpData: (state, action) => {
            state.isLoading = true;
            state.bpNumber = action.payload?.data?.bpNumber;
            state.totalCBM = action.payload?.data?.totalCBM;
            state.totalKgs = action.payload?.data?.totalKgs;
            state.availablePoList = action.payload?.data?.purchaseOrders;
            const values = {
                clientName: action.payload?.data?.client || '',
                originPort: action.payload?.data?.originPort || '',
                destinationPort: action.payload?.data?.destinationPort || '',
                goodsReadyDates: action.payload?.data?.goodsReadyDate || '',
                vendorName: action.payload?.data?.vendorName || '',
            };
            state.infoValues = values;
            state.filterValues = action.payload?.data?.columnList;
            state.isLoading = false;
        },
        resetFlags: (state, _action) => {
            state.success = false;
            state.error = false;
        },
        poDataFetch: (_state, _action) => {
            // console.log(action);
        },
        savePoDataAll: (state, action) => {
            state.poData = action.payload;
        },
        setSelectedItems: (state, action) => {
            state.selectedItems = action.payload;
        },
        setDisabledFilterData: (state, action) => {
            state.disabledFilterData = action.payload;
        },
        setFilterDispatch: (state, action) => {
            state.filter = action.payload;
        },
        searchAvailablePoData: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload.columnStatus;
        },
        searchAvailablePoDataSuccess: (state, action) => {
            state.availablePoList = action.payload.response?.availablePoLines;
            state.filterValues = action.payload.response?.distinctColumnValues;
            state.gateInDate = action.payload.gateInDateExpired;
            state.isLoading = false;
        },
        resetAllLclData: (state, _action) => {
            state.selectedItems = [];
            state.disabledFilterData = {
                coLoader: null,
                originPort: null,
                destinationPort: null,
                vendorName: null,
                clientName: null,
                isHeaderOnlyPo: null,
            };
            state.filter = {
                createdOn: null,
                goodsReadyDate: null,
                clientName: null,
                coLoader: null,
                originPort: null,
                destinationPort: null,
                vendor: null,
                search: null,
            };
            state.bpId = null;
            state.bpNumber = null;
            state.totalCBM = 0;
            state.totalKgs = 0;
            state.infoValues = {
                clientName: '',
                originPort: '',
                destinationPort: '',
                goodsReadyDates: [],
                vendorName: '',
            };
            state.availablePoList = [];
            state.filterValues = {};
            state.columnStatus = availablePoHeaders.poNumber;
            state.poData = {};
        },
    },
});

export const {
    fetchAvailablePoData,
    isLoadingFn,
    fetchAvailablePoFilterData,
    addOrSavePoData,
    getBpData,
    resetFlags,
    poDataFetch,
    savePoDataAll,
    setSelectedItems,
    setDisabledFilterData,
    setFilterDispatch,
    searchAvailablePoData,
    searchAvailablePoDataSuccess,
    resetAllLclData,
} = dataSlice.actions;
export default dataSlice.reducer;
