import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'dashboard',
    initialState: {
        isLoading: true,
        searchList: {} as any,
        searching: false,
        selectedSearchItem: null as any,
        searchError: null,
        activitySettingsBoolean: false as any,
    },
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        isSearching: (state) => {
            state.searching = true;
        },
        searchBegin: (state, _action) => {
            state.searching = true;
            state.searchList = {};
            state.searchError = null;
        },
        searchSuccess: (state, action) => {
            state.searching = false;
            state.searchList = action.payload;
            state.searchError = null;
        },
        searchError: (state, action) => {
            state.searching = false;
            state.searchList = {};
            state.searchError = action.payload;
        },
        clearSearchList: (state) => {
            state.searching = false;
            state.searchList = {};
            state.searchError = null;
        },
        selectedSearch: (state, action) => {
            state.selectedSearchItem = action.payload;
        },
        clearSelectedSearch: (state) => {
            state.selectedSearchItem = null;
        },
        setActivitySettingsMotion: (state, action) => {
            state.activitySettingsBoolean = action.payload;
        },
    },
});

export const {
    isSearching,
    searchBegin,
    searchSuccess,
    searchError,
    isLoading,
    selectedSearch,
    clearSearchList,
    clearSelectedSearch,
    setActivitySettingsMotion,
} = dataSlice.actions;
export default dataSlice.reducer;
