import React, { useEffect } from 'react';
import './map-sidebar.scss';
import '../../styles/map-sidepanel-common-style.scss';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    fetchAllMapData,
    saveSingleMapData,
    showSidebarForTile,
    sideBarType,
    updateClickedTileKey,
    updateHoveredTileKey,
} from 'src/store/reducers/dashboard/map';
import { BookingTransitSidebar } from '..';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const TileSideBar: React.FC = () => {
    const { sideBarVewTiles, sideBarValue, sideBarData, clickedTileKey, isLoadingSideBar } = useSelector(
        (state: any) => state.map
    ) as any;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const headFunction = () => {
        switch (clickedTileKey) {
            case 'transit':
                return 'Bookings In Transit';
            case 'destination':
                return 'Bookings at Destination';
            case 'etd':
                return 'Bookings with ETD in 7 Days';
            case 'eta':
                return 'Bookings with ETA in 7 Days';

            default:
                break;
        }
    };

    const bpRedirect = (type: string, id: any) => {
        switch (type.toLowerCase()) {
            case 'fcl':
                return navigate(`/booking_list/${id}/POSelection`);
            case 'bcn':
                return navigate(`/bcnBookingList/${id}/POSelection`);
            case 'lcl':
                return navigate(`/lclbooking_list/${id}/POSelection`);
            case 'ao':
                return navigate(`/Aobooking_list/${id}/AoSelection`);
            case 'air':
                return navigate(`/air_booking_list/${id}/POSelection`);
            default:
                return navigate('/');
        }
    };

    useEffect(() => {
        dispatch(fetchAllMapData({ tileType: clickedTileKey }));
    }, [clickedTileKey]);

    const filtered = () => {
        if (clickedTileKey === 'etd') {
            return sideBarData
                ?.filter(
                    (res: any) =>
                        !res?.atd &&
                        moment().isSameOrAfter(moment(res?.etd).subtract(7, 'days').format('YYYY-MM-DD'), 'day')
                )
                ?.sort((a: any, b: any) => {
                    if (!a?.atd && moment(a?.etd).isBefore(moment())) return -1;
                    else if (!b?.atd && moment(b?.etd).isBefore(moment())) return 1;
                    else return a - b;
                });
        } else if (clickedTileKey === 'destination') {
            return sideBarData?.filter((res: any) => res?.ata !== null);
        } else {
            return sideBarData;
        }
    };

    return (
        // {`map-sidebar ${expandMode ? 'open' : ''}`}
        sideBarValue === 'bookingsIntransitArray' ? (
            <BookingTransitSidebar />
        ) : (
            <div className={`map-sidebar ${sideBarVewTiles && clickedTileKey ? 'open' : ''}`}>
                {isLoadingSideBar ? (
                    <div className="graph-holder" style={{ zIndex: '1000', height: '100%' }}>
                        <div className="graph-scaler loading"></div>
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    </div>
                ) : (
                    <>
                        {/*{sideBarVewTiles && (*/}
                        <button
                            className="app-btn arrow-icon-holder"
                            onClick={() => {
                                dispatch(showSidebarForTile(!sideBarVewTiles));
                                //dispatch(saveSingleMapData({}));
                            }}
                            title="Collapse Panel"
                        >
                            <svg className="svg-icon dashboard-header-icon">
                                <use xlinkHref="#downArrow">
                                    <title>dropdown</title>
                                </use>
                            </svg>
                        </button>
                        {/*)}*/}
                        <div className="side-bar active">
                            <div className="sidebar-header">
                                <button
                                    onClick={() => {
                                        dispatch(showSidebarForTile(!sideBarVewTiles));
                                        dispatch(saveSingleMapData(null));
                                        dispatch(sideBarType(''));
                                        dispatch(updateHoveredTileKey(''));
                                        dispatch(updateClickedTileKey(null));
                                    }}
                                    className="app-btn text-close-btn"
                                    title="Close"
                                >
                                    <svg className="svg-icon text-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </button>
                                <h2 className="page-heading">{headFunction()}</h2>
                            </div>
                            <div className="badge-status">
                                {/*{sideBarData?.bookingsCount && (
                            <div className="count">{sideBarData?.bookingsCount} Bookings</div>
                        )}*/}
                                {/*{sideBarData?.poCount && <div className="count">{sideBarData?.poCount} POs</div>}*/}
                                {/*{sideBarData?.teuCount && <div className="count">{sideBarData?.teu} TEU</div>}*/}
                            </div>
                            <div className="main-content-wrapper">
                                {filtered &&
                                    filtered()?.map((res: any) => (
                                        <div className="detail-content-items">
                                            <div className="col-booking-status">
                                                <div className="booking-id">
                                                    <a
                                                        onClick={() => bpRedirect(res?.bpType, res?.bpId)}
                                                        className="booking-link"
                                                    >
                                                        {res?.bpNumber}
                                                    </a>{' '}
                                                </div>
                                                <div className="booking-badges">
                                                    {res?.bpType ? <div className="count">{res?.bpType}</div> : ''}
                                                    {/*{res?.poCount ? <div className="count">{res?.poCount} POs</div>:""}*/}
                                                    {res?.teu ? <div className="count">{res?.teu} TEU</div> : ''}
                                                    {clickedTileKey === 'etd' &&
                                                    !res?.atd &&
                                                    moment(res?.etd).isBefore(moment()) ? (
                                                        <div
                                                            className="count"
                                                            style={{ color: '#fd1010', borderColor: '#fd1010' }}
                                                        >
                                                            Delayed
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                            <div className="col-destination">
                                                <div className="des-progress-bar">
                                                    <div className="destination begin-journey">
                                                        <div className="point12">
                                                            <div className="point1" title={res?.originPort?.code}>
                                                                {res?.originPort?.code}
                                                            </div>
                                                        </div>
                                                        <div className="date-time">
                                                            <div
                                                                className="destination-name"
                                                                style={{ marginRight: '3px' }}
                                                            >
                                                                ETD
                                                            </div>
                                                            <div className="time-field">
                                                                {res?.etd
                                                                    ? moment
                                                                          .utc(res?.etd)
                                                                          .local()
                                                                          .format('DD-MM-YYYY, HH:mm')
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                        <div className="date-time">
                                                            <div
                                                                className="destination-name"
                                                                style={{ marginRight: '3px' }}
                                                            >
                                                                ATD
                                                            </div>
                                                            <div className="time-field">
                                                                {res?.atd
                                                                    ? moment
                                                                          .utc(res?.atd)
                                                                          .local()
                                                                          .format('DD-MM-YYYY, HH:mm')
                                                                    : '-'}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="destination center-journey">
                                                        <span className="center-point">{res?.noOfStops} Stop</span>
                                                    </div>
                                                    <div className="destination end-journey">
                                                        <div className="point12">
                                                            <div className="point1" title={res?.destinationPort?.code}>
                                                                {res?.destinationPort?.code}
                                                            </div>
                                                        </div>
                                                        <div className="date-time">
                                                            <div className="time-field">
                                                                {res?.eta
                                                                    ? moment
                                                                          .utc(res?.eta)
                                                                          .local()
                                                                          .format('DD-MM-YYYY, HH:mm')
                                                                    : '-'}
                                                            </div>
                                                            <div
                                                                className="destination-name"
                                                                style={{ marginLeft: '9px' }}
                                                            >
                                                                {' '}
                                                                ETA
                                                            </div>
                                                        </div>
                                                        <div className="date-time">
                                                            <div className="time-field">
                                                                {res?.ata
                                                                    ? moment
                                                                          .utc(res?.ata)
                                                                          .local()
                                                                          .format('DD-MM-YYYY, HH:mm')
                                                                    : '-'}
                                                            </div>
                                                            <div
                                                                className="destination-name"
                                                                style={{ marginLeft: '9px' }}
                                                            >
                                                                {' '}
                                                                ATA
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-name-content">
                                                <div className="col-content-detail">
                                                    <div className="content-name">Client</div>
                                                    <div className="content-detail" style={{ wordBreak: 'break-word' }}>
                                                        {res?.client[0]?.name}
                                                    </div>
                                                </div>
                                                <div className="col-content-detail">
                                                    <div className="content-name">Carrier</div>
                                                    <div className="content-detail">{res?.shippingLine?.name}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </>
                )}
                <div className="sidebar-backdrop"></div>
            </div>
        )
    );
};
export default TileSideBar;
