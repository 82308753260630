import { createSlice } from '@reduxjs/toolkit';
import { activeReceipts, activeStatus, receiptHeaders } from '../../../utils/constants';

export const dataSlice = createSlice({
    name: 'receiptList',
    initialState: {
        receipts: [],
        filterValues: [],
        isLoading: true,
        currentPage: 0,
        columnStatus: receiptHeaders.createdDate,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        statusCount: {},
        error: null,
        success: false,
        filter: { status: activeStatus },
        selectedDropDown: {
            text: activeReceipts,
            count: 0,
        },
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchReceiptData: (state, action) => {
            // state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.receipts = action.payload.receipts?.receiptListingDtos;
            state.totalPages = action.payload.receipts?.totalPages;
            state.totalItems = action.payload.receipts?.totalElements;
            // state.isLoading = false;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        fetchReceiptFilterData: (state, action) => {
            // state.isLoading = true;
            if (action.payload?.filterData) {
                state.filterValues = action.payload?.filterData;
            }
            // state.isLoading = false;
        },
        fetchReceiptStatusCount: (state, action) => {
            // state.isLoading = true;
            state.statusCount = action.payload?.count;
            // state.isLoading = false;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setSelectedDropDown: (state, action) => {
            state.selectedDropDown = action.payload;
        },
    },
});

export const {
    fetchReceiptData,
    setPageCount,
    isLoadingFn,
    setDefault,
    fetchReceiptFilterData,
    fetchReceiptStatusCount,
    setFilter,
    setSelectedDropDown,
} = dataSlice.actions;
export default dataSlice.reducer;
