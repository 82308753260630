import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';

const Index: React.FC = () => {
    const { singleMapData } = useSelector((state: any) => state.map);
    return (
        <div className="tooltip-dashboard-wrapper">
            <div className="dashboard-heading">
                <div className="main-heading">Bookings in Transit</div>
            </div>
            <div className="booking-item-content">
                <div className="booking-name-updates">
                    <div className="grid-child-data list-view-items">
                        <div className="ship-logo">
                            {singleMapData?.shippingMode?.toLowerCase() === 'sea' ? (
                                <svg className="svg-icon ship-icon">
                                    <use href="#shipfillIcon">
                                        <title>Ship</title>
                                    </use>
                                </svg>
                            ) : (
                                <svg className="svg-icon aeroplane-icon">
                                    <use href="#aeroplaneIcon">
                                        <title>Aeroplane</title>
                                    </use>
                                </svg>
                            )}
                        </div>
                        <div className="content-list-wrapper">
                            <div className="content-heading-holder">
                                <span className="tab-heading">{singleMapData?.vesselName}</span>
                            </div>
                            <div className="content-detail">{singleMapData?.voyageName}</div>
                        </div>
                    </div>
                    <div className="col-updates">
                        <div className="col-content-detail">
                            <div className="content-name">Last Updated</div>
                            <div className="content-detail">
                                {' '}
                                {moment.utc(singleMapData?.lastUpdate).local().format('DD-MM-YYYY, HH:mm')}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="booking-destination">
                    <div className="col-destination">
                        <div className="des-progress-bar">
                            <div className="destination begin-journey">
                                <div className="point12">
                                    <div className="point1" title={singleMapData?.originPortName}>
                                        {singleMapData?.originPortName}
                                    </div>
                                    <br />
                                    <div className="point-name">({singleMapData?.originPortCode})</div>
                                </div>
                                <div className="date-time">
                                    <div className="destination-name">ETD</div>
                                    <div className="time-field">
                                        {moment.utc(singleMapData?.etd).local().format('DD-MM-YYYY, HH:mm')}
                                    </div>
                                </div>
                                <div className="date-time">
                                    <div className="destination-name">ATD</div>
                                    <div className="time-field">
                                        {moment.utc(singleMapData?.atd).local().format('DD-MM-YYYY, HH:mm')}
                                    </div>
                                </div>
                            </div>
                            <div className="destination center-journey">
                                <span className="center-point">{singleMapData?.noOfStops} Stop</span>
                            </div>
                            <div className="destination end-journey">
                                <div className="point12">
                                    <div className="point1" title={singleMapData?.destinationPortName}>
                                        {singleMapData?.destinationPortName}
                                    </div>
                                    <br />
                                    <div className="point-name">({singleMapData?.destinationPortCode})</div>
                                </div>
                                <div className="date-time">
                                    <div className="time-field">
                                        {moment.utc(singleMapData?.eta).local().format('DD-MM-YYYY, HH:mm')}
                                    </div>
                                    <div className="destination-name">ETA</div>
                                </div>
                                <div className="date-time">
                                    <div className="time-field">
                                        {singleMapData?.ata
                                            ? moment.utc(singleMapData?.ata).local().format('DD-MM-YYYY, HH:mm')
                                            : '-'}
                                    </div>
                                    <div className="destination-name">ATA</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-name-content">
                        <div className="col-content-detail">
                            <div className="content-name">Next Stop</div>
                            <div className="content-detail">
                                {singleMapData?.nextStopName},{' '}
                                {moment.utc(singleMapData?.nextStopETA).local().format('DD-MM-YYYY, HH:mm')}
                            </div>
                        </div>
                        <div className="col-content-detail">
                            <div className="content-name">Speed</div>
                            <div className="content-detail">{singleMapData?.speed} Knots</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="count-badges">
                <div className="count">
                    {singleMapData?.bookingCount} Booking{singleMapData?.bookingCount > 1 ? 's' : ''}
                </div>
                <div className="count">
                    {singleMapData?.poCount} PO{singleMapData?.poCount > 1 ? 's' : ''}
                </div>
                <div className="count">{singleMapData?.totalTeu} TEU</div>
            </div>
            <div className="link-back-btn">
                <a href="/booking_list" className="booking-link">
                    Go to Bookings
                </a>
                <div className="back-icon">
                    <svg className="svg-icon straight-arrow-pointer-icon">
                        <use href="#straightArrowPointerIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Index;
