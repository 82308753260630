import React from 'react';
import '../../BookingAddedGrid/booking-added-grid.scss';
import ShowForPermissions from 'src/ShowForPermissions';
import moment from 'moment';
interface ContainerProps {
    action?: boolean;
    isAllSelected: () => boolean;
    selectAll: (value: boolean) => void;
    showConditionStatus: boolean;
    addPoList: any;
    singleRowChecked: (boId: string, poId: string, data: any[]) => boolean;
    setpoDisplay: ({ status, id }: { id: string; status: boolean }) => void;
    originalData: any;
    selectedIds: (boId: string, poId: string, pos: any[], index: number, isChecked: boolean) => void;
    getAllIds: (list: any[]) => any[];
    setVendorCode: (poId: string) => void;
    setModalViewproduct: ({ status, id }: { id: string; status: boolean; value: string }) => void;
    selectSingle: (
        boId: string,
        poId: string,
        bsId: string,
        index: number,
        subLineIndex: number,
        isChecked: boolean
    ) => void;
    checkSelected: (boId: string, poId: string, bsId: string) => boolean;
    poDetails: boolean;
    onQtyChange: any;
}
const Index: React.FC<ContainerProps> = ({
    addPoList,
    isAllSelected,
    selectAll,
    showConditionStatus,
    singleRowChecked,
    setpoDisplay,
    originalData,
    selectedIds,
    getAllIds,
    setVendorCode,
    setModalViewproduct,
    selectSingle,
    checkSelected,
    poDetails,
    onQtyChange,
}) => {
    return (
        <div className="grid-scroll-content">
            <table className="grid-table allocation-table">
                <thead>
                    <tr className="grid-sticky-row">
                        <th className="grid-cell-header col-number grid-sticky-column sticky-left-aligned">
                            <div className="grid-cell-data">
                                <span className="header-title">PO NUMBER</span>
                            </div>
                        </th>
                        {showConditionStatus && (
                            <ShowForPermissions permission="edit" type="bp">
                                <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                    <div className="grid-cell-data centre-align">
                                        <label className="app-check-wrapper">
                                            <input
                                                type="checkbox"
                                                className="checkbox-input"
                                                checked={isAllSelected()}
                                                onChange={(e) => selectAll(e.currentTarget.checked)}
                                            />
                                            <div className="checkmark">
                                                <svg className="svg-icon tick-icon">
                                                    <use xlinkHref="#tickIcon">
                                                        <title>check mark</title>
                                                    </use>
                                                </svg>
                                            </div>
                                        </label>
                                    </div>
                                </th>
                            </ShowForPermissions>
                        )}

                        <th className="grid-cell-header col-cbm">
                            <div className="grid-cell-data">
                                <span className="header-title">CBM</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-weight">
                            <div className="grid-cell-data">
                                <span className="header-title">WEIGHT (KG)</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-grd">
                            <div className="grid-cell-data">
                                <span className="header-title">GOODS READY DATE</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-line">
                            <div className="grid-cell-data">
                                <span className="header-title">DESTINATION</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-desc">
                            <div className="grid-cell-data">
                                <span className="header-title">DESTINATION ADDRESS</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-sku">
                            <div className="grid-cell-data">
                                <span className="header-title">SKU</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-qty">
                            <div className="grid-cell-data">
                                <span className="header-title">ORDER QTY</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-qty">
                            <div className="grid-cell-data">
                                <span className="header-title">ADDED QTY</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-qty">
                            <div className="grid-cell-data">
                                <span className="header-title">REMAINING QTY</span>
                            </div>
                        </th>
                        <th className="grid-cell-header col-code">
                            <div className="grid-cell-data">
                                <span className="header-title">HS CODE</span>
                            </div>
                        </th>
                        {/* {action && (
                            <th className="grid-cell-header col-action">
                                <div className="grid-cell-data">
                                    <span className="header-title">Action</span>
                                </div>
                            </th>
                        )} */}
                    </tr>
                </thead>

                {addPoList?.map((res: any, i: any) => (
                    <tbody key={i} className="grouped-splited-row">
                        {res?.bookingProposalSubPOLineList.map((val: any, i2: any) => {
                            return (
                                <tr key={i2}>
                                    <td className="grid-cell col-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                        <div className="grid-cell-data">
                                            <div className="checkbox-data-wrapper">
                                                <ShowForPermissions permission="edit" type="bp">
                                                    {showConditionStatus && (
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="checkbox"
                                                                checked={singleRowChecked(
                                                                    res?.boId,
                                                                    res?.poId,
                                                                    originalData?.filter(
                                                                        (e: any) => e.poId === res?.poId
                                                                    )
                                                                )}
                                                                className="checkbox-input"
                                                                onChange={(e) => {
                                                                    selectedIds(
                                                                        res?.boId,
                                                                        res?.poId,
                                                                        getAllIds(res?.bookingProposalSubPOLineList),
                                                                        i,
                                                                        e.currentTarget.checked
                                                                    );
                                                                }}
                                                            />
                                                            {i2 === 0 && (
                                                                <div className="checkmark">
                                                                    <svg className="svg-icon tick-icon">
                                                                        <use xlinkHref="#tickIcon">
                                                                            <title>check mark</title>
                                                                        </use>
                                                                    </svg>
                                                                </div>
                                                            )}
                                                        </label>
                                                    )}
                                                </ShowForPermissions>
                                                {i2 === 0 && (
                                                    <a
                                                        className="booking-proposal-link"
                                                        style={{
                                                            paddingLeft: '0',
                                                            marginLeft: showConditionStatus ? '28px' : '',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() =>
                                                            setpoDisplay({
                                                                status: true,
                                                                id: res?.poId,
                                                            })
                                                        }
                                                    >
                                                        {res?.poNumber}
                                                    </a>
                                                )}
                                            </div>
                                        </div>
                                    </td>
                                    {showConditionStatus && (
                                        <ShowForPermissions permission="edit" type="bp">
                                            <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data centre-align">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            checked={checkSelected(res?.boId, res?.poId, val?.bsId)}
                                                            type="checkbox"
                                                            className="checkbox-input"
                                                            onChange={(e) =>
                                                                selectSingle(
                                                                    res?.boId,
                                                                    res?.poId,
                                                                    val?.bsId,
                                                                    i,
                                                                    i2,
                                                                    e.currentTarget.checked
                                                                )
                                                            }
                                                        />
                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </td>
                                        </ShowForPermissions>
                                    )}

                                    <td className="grid-cell col-po-cbm">
                                        <div className="grid-cell-data">{val?.cbm}</div>
                                    </td>
                                    <td className="grid-cell col-po-weight">
                                        <div className="grid-cell-data">{val?.weight}</div>
                                    </td>
                                    <td className="grid-cell col-po-grd">
                                        <div className="grid-cell-data">
                                            {moment(val?.confirmedGoodsdate).format('DD-MM-YYYY')}
                                        </div>
                                    </td>
                                    <td className="grid-cell col-po-desc">
                                        <div className="grid-cell-data">{val?.destinationPortName || '-'}</div>
                                    </td>
                                    <td className="grid-cell col-po-desc">
                                        <div className="grid-cell-data">{val?.destinationAddress || '-'}</div>
                                    </td>
                                    <td className="grid-cell col-po-sku">
                                        <div className="grid-cell-data">
                                            {val?.sku ? (
                                                <a
                                                    onClick={() => {
                                                        setVendorCode(res?.clientCode);
                                                        setModalViewproduct({
                                                            status: true,
                                                            value: res?.clientCode,
                                                            id: val?.skuId,
                                                        });
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                >
                                                    {val.sku}
                                                </a>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </td>
                                    <td className="grid-cell col-po-qty">
                                        <div className="grid-cell-data">{val?.qty}</div>
                                    </td>
                                    {poDetails ? (
                                        <>
                                            <td className="grid-cell col-po-qty search-entry-holder">
                                                <div className="grid-cell-data">
                                                    <input
                                                        placeholder=""
                                                        type="text"
                                                        onKeyPress={(event) => {
                                                            if (!/[0-9]/.test(event.key)) {
                                                                event.preventDefault();
                                                            }
                                                        }}
                                                        className="search-input input-text input-qty-add"
                                                        name="confirmedQty"
                                                        value={val?.addedQty}
                                                        disabled={
                                                            checkSelected(res?.boId, res?.poId, val?.bsId)
                                                                ? false
                                                                : true
                                                        }
                                                        onChange={(e) => onQtyChange(e, i, i2)}
                                                    ></input>
                                                </div>
                                            </td>
                                            <td className="grid-cell col-po-qty search-entry-holder">
                                                <div className="grid-cell-data">
                                                    {(val?.remainingQty === null || val?.remainingQty === val?.qty) &&
                                                    checkSelected(res?.boId, res?.poId, val?.bsId) === false ? (
                                                        '-'
                                                    ) : (
                                                        <span
                                                            className={`${
                                                                val?.remainingQty < 0 ? 'status-remaining' : ''
                                                            }`}
                                                        >
                                                            {val?.remainingQty}
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                        </>
                                    ) : (
                                        <>
                                            <td className="grid-cell col-po-qty search-entry-holder">
                                                <div className="grid-cell-data">{val?.addedQty}</div>
                                            </td>
                                            <td className="grid-cell col-po-qty search-entry-holder">
                                                <div className="grid-cell-data">{val?.remainingQty}</div>
                                            </td>
                                        </>
                                    )}
                                    <td className="grid-cell col-po-code">
                                        <div className="grid-cell-data">{val?.hsCode}</div>
                                    </td>

                                    {/* {action && (
                                        <td className="grid-cell col-po-added-vendor">
                                            <div className="grid-cell-data">
                                                <button className="action-btn app-btn app-btn-secondary ">
                                                    <svg className="svg-icon  add-btn-icon">
                                                        <use xlinkHref="#deleteIcon"></use>
                                                    </svg>
                                                </button>
                                                <button className="action-btn app-btn app-btn-secondary ">
                                                    <svg className="svg-icon  add-btn-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    )} */}
                                </tr>
                            );
                        })}
                    </tbody>
                ))}
            </table>
        </div>
    );
};
export default Index;
