import { put, call, takeLatest } from 'redux-saga/effects';
import { AxiosResponse } from 'axios';
import {
    checkContractResponse,
    isLoadingFn,
    isLoadingFn2,
    sailingUpdated,
    saveFAKdata,
    savePortValues,
    saveSailingcount,
    saveSailingData,
    viewSelectedSailing,
} from '../../../reducers/aobookingProposal/sailingReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    contractCheck,
    fakData,
    getPorts,
    removeSailingData,
    sailingAddToBp,
    sailingCount,
    sailingData,
    selectedSalilingData,
} from '../../../../services/apis/aobookingProposal/sailingApis';

function* getCheckContract({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(contractCheck, payload);
        // const newpayload = { data: resultSailing?.contractsDtoList, status: resultSailing?.isContractPresent };
        yield put({ type: checkContractResponse, payload: resultSailing });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getCountSailing({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(sailingCount, payload);
        yield put({ type: saveSailingcount, payload: resultSailing });
    } finally {
        //yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getfakData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(fakData, payload);
        yield put({ type: saveFAKdata, payload: resultSailing });
    } finally {
        //yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getSailingData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(sailingData, payload);
        const result = (resultSailing as any).sailingScheduleLists;
        yield put({ type: saveSailingData, payload: result });
    } finally {
        //yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getSelectedSailing({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn2, payload: true });
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(selectedSalilingData, payload || []);
        const result = resultSailing;
        yield put({ type: viewSelectedSailing, payload: result });
    } finally {
        yield put({ type: isLoadingFn2, payload: false });
        yield put(hideLoading());
    }
}
function* getPortsAll({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn2, payload: true });
        yield put(showLoading());
        const ports: AxiosResponse<any> = yield call(getPorts, payload);
        // const result = ports;
        yield put({ type: savePortValues, payload: ports });
    } finally {
        yield put({ type: isLoadingFn2, payload: false });
        yield put(hideLoading());
    }
}
function* addSailingToList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn2, payload: true });
        yield put(showLoading());
        yield call(sailingAddToBp, payload);
    } finally {
        if (payload['sailingAddRequest']?.isManuallyEntered) {
            yield put({ type: sailingUpdated, payload: true });
        }
        yield put({ type: isLoadingFn2, payload: false });
        yield put(hideLoading());
    }
}
function* removeSailingFromList({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield call(removeSailingData, payload);
    } finally {
        yield put(hideLoading());
    }
}
function* availablePoListSaga() {
    yield takeLatest('sailingbpao/checkContractData', getCheckContract);
    yield takeLatest('sailingbpao/getSailingCounts', getCountSailing);
    yield takeLatest('sailingbpao/fetchSailingData', getSailingData);
    yield takeLatest('sailingbpao/addToBp', addSailingToList);
    yield takeLatest('sailingbpao/fetchSelectedSailing', getSelectedSailing);
    yield takeLatest('sailingbpao/removeSailing', removeSailingFromList);
    yield takeLatest('sailingbpao/getFakData', getfakData);
    yield takeLatest('sailingbpao/getPortValues', getPortsAll);
}

export default availablePoListSaga;
