const baseURL = process.env.REACT_APP_BASE_URL;
export const filterValues = [
    {
        label: 'JAN-2022',
        value: '1,2023',
    },
    {
        label: 'DEC-2022',
        value: '12,2022',
    },
    {
        label: 'NOV-2022',
        value: '11,2022',
    },
    {
        label: 'OCT-2022',
        value: '10,2022',
    },
    {
        label: 'Last 3 months',
        value: 'l3m',
    },
];

export const gridDetails = [
    {
        name: 'vessels',
        columns: [
            {
                field: 'bookingNumber',
                title: 'Booking NO',
                width: 130,
            },
            {
                field: 'type',
                title: 'Type',
                width: 100,
            },
            {
                field: 'mode',
                title: 'Mode',
                width: 100,
            },
            {
                field: 'status',
                title: 'Status',
                width: 150,
            },
            {
                field: 'origin',
                title: 'Orgin',
                width: 140,
            },
            {
                field: 'destination',
                title: 'Destination',
                width: 200,
            },
            {
                field: 'etd',
                title: 'ETD',
                width: 120,
                template: '#if(etd=== null){# - #}else{# #=etd# #}#',
            },
            {
                field: 'atd',
                title: 'ATD',
                width: 120,
                template: '#if(atd === null){# - #}else{# #=atd# #}#',
            },
            {
                field: 'eta',
                title: 'ETA',
                width: 120,
                template: '#if(eta === null){# - #}else{# #=eta# #}#',
            },
            {
                field: 'ata',
                title: 'ATA',
                width: 120,
                template: '#if(ata === null){# - #}else{# #=ata# #}#',
            },
        ],
        fields: {
            bookingNumber: { type: 'string' },
            type: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            etd: { type: 'string' },
            atd: { type: 'string' },
            eta: { type: 'string' },
            ata: { type: 'string' },
        },
        url: '',
        fileName: 'vessels-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'bookings',
        columns: [
            {
                field: 'poNumber',
                title: 'Po Line',
                width: 120,
            },
            {
                field: 'poLineNumber',
                title: 'Po Line No',
                width: 140,
            },
            {
                field: 'sku',
                title: 'SKU',
                width: 100,
            },
            {
                field: 'description',
                title: 'Description',
                width: 160,
            },
            {
                field: 'qty',
                title: 'QTY',
                width: 100,
            },
            {
                field: 'vendorName',
                title: 'Vendor Name',
                width: 160,
            },
            {
                field: 'goodsReadyDate',
                title: 'Goods Ready Date',
                template: '#if(goodsReadyDate === null){# - #}else{# #=goodsReadyDate# #}#',
                // template: "#= kendo.toString(goodsReadyDate,'dd-MM-yyyy') #",
                width: 200,
            },
            {
                field: 'cbm',
                title: 'CBM',
                width: 100,
            },
            {
                field: 'weight',
                title: 'Weight',
                width: 110,
            },
            {
                field: 'hsCode',
                title: 'HS Code',
                width: 130,
            },
        ],
        fields: {
            sku: { type: 'string' },
            poLineNumber: { type: 'string' },
            poNumber: { type: 'string' },
            hsCode: { type: 'string' },
            description: { type: 'string' },
            cbm: { type: 'double' },
            destination: { type: 'string' },
            qty: { type: 'double' },
            weight: { type: 'double' },
            goodsReadyDate: { type: 'string' },
        },
        url: `${baseURL}/api/ord/tracking/lifecycle/search/bookings/sku`,
        fileName: 'bookings-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'master-bill',
        columns: [
            {
                field: 'bpNo',
                title: 'Booking No',
                width: 180,
                template:
                    '#if (bpType==="FCL") {# <a class="link-com" href="/booking_list/#=bpId#/POSelection" >#=bpNo#</a> #} else if(bpType ==="LCL") {# <a class="link-com" href="/lclbooking_list/#=bpId#/POSelection" >#=bpNo#</a> #}else if(bpType ==="BCN") {# <a class="link-com" href="/bcnBookingList/#=bpId#/POSelection" >#=bpNo#</a> #} else if(bpType ==="AIR") {# <a class="link-com" href="/air_booking_list/#=bpId#/POSelection" >#=bpNo#</a> #} else {# <a class="link-com" href="/Aobooking_list/#=bpId#/AOSelection" >#=bpNo#</a> #}#',
            },
            {
                field: 'poNumber',
                title: 'Po No',
                width: 110,
            },
            {
                field: 'lineNumber',
                title: 'Line No',
                width: 140,
            },
            {
                field: 'sku',
                title: 'SKU',
                width: 100,
            },
            {
                field: 'description',
                title: 'Description',
                width: 150,
            },
            {
                field: 'qty',
                title: 'QTY',
                width: 100,
            },
            {
                field: 'vendor',
                title: 'Vendor',
                width: 120,
            },
            {
                field: 'client',
                title: 'Client',
                width: 110,
            },
            {
                field: 'goodsReadyDate',
                title: 'Goods Ready Date',
                // template: "#= kendo.toString(goodsReadyDate,'dd-MM-yyyy') #",
                template: '#if(goodsReadyDate === null){# - #}else{# #=goodsReadyDate# #}#',
                width: 180,
            },
            {
                field: 'cbm',
                title: 'CBM',
                width: 100,
            },
            {
                field: 'weight',
                title: 'Weight',
                width: 140,
            },
            {
                field: 'hsCode',
                title: 'HS Code',
                width: 130,
            },
        ],
        fields: {
            bpNo: { type: 'string' },
            poNumber: { type: 'string' },
            lineNumber: { type: 'string' },
            sku: { type: 'string' },
            description: { type: 'string' },
            qty: { type: 'double' },
            vendor: { type: 'string' },
            client: { type: 'string' },
            goodsReadyDate: { type: 'string' },
            cbm: { type: 'double' },
            hsCode: { type: 'string' },
            weight: { type: 'double' },
        },

        url: '',
        fileName: 'mbl-sku-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'mbl-bookings',
        columns: [
            {
                field: 'bookingNo',
                title: 'Booking No',
                width: 160,
                template:
                    '#if (mode==="FCL") {# <a class="link-com" href="/booking_list/#=bpId#/POSelection" >#=bookingNo#</a> #} else if(mode ==="LCL") {# <a class="link-com" href="/lclbooking_list/#=bpId#/POSelection" >#=bookingNo#</a> #}else if(mode ==="BCN") {# <a class="link-com" href="/bcnBookingList/#=bpId#/POSelection" >#=bookingNo#</a> #} else if(mode ==="AIR") {# <a class="link-com" href="/air_booking_list/#=bpId#/POSelection" >#=bookingNo#</a> #} else {# <a class="link-com" href="/Aobooking_list/#=bpId#/AOSelection" >#=bookingNo#</a> #}#',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: 100,
            },
            {
                field: 'type',
                title: 'Type',
                width: 105,
            },
            {
                field: 'status',
                title: 'Status',
                width: 130,
            },
            {
                field: 'origin',
                title: 'Origin',
                width: 140,
            },
            {
                field: 'destination',
                title: 'Destination',
                width: 140,
            },
            {
                field: 'etd',
                title: 'etd',
                template: '#if(etd=== null){# - #}else{# #=etd# #}#',
                // template: '#if(etd === null){# - #}else{# #= kendo.toString(etd,"dd-MM-yyyy")# #}#',
                width: 100,
            },
            {
                field: 'atd',
                title: 'atd',
                template: '#if(atd=== null){# - #}else{# #=atd# #}#',
                width: 100,
            },
            {
                field: 'eta',
                title: 'eta',
                template: '#if(eta=== null){# - #}else{# #=eta# #}#',
                width: 100,
            },
            {
                field: 'ata',
                title: 'ata',
                template: '#if(ata=== null){# - #}else{# #=ata# #}#',
                width: 100,
            },
        ],
        fields: {
            bookingNo: { type: 'string' },
            mode: { type: 'string' },
            type: { type: 'string' },
            status: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            etd: { type: 'string' },
            eta: { type: 'string' },
            atd: { type: 'string' },
            ata: { type: 'string' },
        },
        url: '',
        fileName: 'master-bill-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'house-bill',
        columns: [
            {
                field: 'poNumber',
                title: 'Po No',
                width: 110,
            },
            {
                field: 'poLineNumber',
                title: 'Line No',
                width: 120,
            },
            {
                field: 'sku',
                title: 'SKU',
                width: 100,
            },
            {
                field: 'description',
                title: 'Description',
                width: 160,
            },
            {
                field: 'qty',
                title: 'QTY',
                width: 100,
            },
            {
                field: 'vendorName',
                title: 'Vendor',
                width: 130,
            },
            {
                field: 'client',
                title: 'CLient',
                width: 100,
            },
            {
                field: 'goodsReadyDate',
                title: 'Goods Ready Date',
                template: '#if(goodsReadyDate === null){# - #}else{# #=goodsReadyDate# #}#',
                // template: "#= kendo.toString(goodsReadyDate,'dd-MM-yyyy') #",
                width: 190,
            },
            {
                field: 'cbm',
                title: 'CBM',
                width: 100,
            },
            {
                field: 'weight',
                title: 'Weight',
                width: 120,
            },
            {
                field: 'hsCode',
                title: 'HS Code',
                width: 130,
            },
            {
                field: 'bookingNo',
                title: 'Booking No',
                width: 150,
            },
        ],
        fields: {
            poNumber: { type: 'string' },
            poLineNumber: { type: 'string' },
            sku: { type: 'string' },
            description: { type: 'string' },
            qty: { type: 'double' },
            hsCode: { type: 'string' },
            vendorName: { type: 'string' },
            client: { type: 'string' },
            goodsReadyDate: { type: 'string' },
            cbm: { type: 'double' },
            weight: { type: 'double' },
            destination: { type: 'string' },
        },
        // url: `${baseURL}/api/ord/tracking/lifecycle/search/bookings/sku`,
        url: '',
        fileName: 'house-bill-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'portsDeparture',
        columns: [
            {
                field: 'vessel',
                title: 'Vessel',
                width: 130,
            },
            {
                field: 'voyage',
                title: 'Voyage',
                width: 110,
            },
            {
                field: 'clients',
                title: 'Clients',
                width: 110,
            },
            {
                field: 'teu',
                title: 'TEU',
                width: 80,
            },
            {
                field: 'carrier',
                title: 'Carrier',
                width: 150,
            },
            {
                field: 'etd',
                title: 'ETD',
                template: '#if(etd=== null){# - #}else{# #=etd# #}#',
                width: 120,
            },

            {
                field: 'atd',
                title: 'ATD',
                template: '#if(atd=== null){# - #}else{# #=atd# #}#',
                width: 120,
            },
            {
                field: 'nextStop',
                title: 'Next Stop',
                width: 180,
            },
            {
                field: 'eta',
                title: 'ETA',
                template: '#if(eta=== null){# - #}else{# #=eta# #}#',
                width: 120,
            },
        ],
        fields: {
            vessel: { type: 'string' },
            voyage: { type: 'string' },
            teu: { type: 'string' },
            carrier: { type: 'string' },
            clients: { type: 'string' },
            eta: { type: 'string' },
            etd: { type: 'string' },
            ata: { type: 'string' },
            nextStop: { type: 'string' },
        },
        url: '',
        fileName: 'ports-departure-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'portsArrival',
        columns: [
            {
                field: 'vessel',
                title: 'Vessel',
                width: 110,
            },
            {
                field: 'voyage',
                title: 'Voyage',
                width: 110,
            },
            {
                field: 'clients',
                title: 'Clients',
                width: 110,
            },
            {
                field: 'teu',
                title: 'TEU',
                width: 80,
            },
            {
                field: 'carrier',
                title: 'Carrier',
                width: 140,
            },
            {
                field: 'origin',
                title: 'Origin Port',
                width: 140,
            },

            {
                field: 'atd',
                title: 'ATD',
                template: '#if(atd=== null){# - #}else{# #=atd# #}#',
                width: 120,
            },

            {
                field: 'eta',
                title: 'ETA',
                template: '#if(eta=== null){# - #}else{# #=eta# #}#',
                width: 120,
            },
        ],
        fields: {
            vessel: { type: 'string' },
            voyage: { type: 'string' },
            teu: { type: 'string' },
            carrier: { type: 'string' },
            clients: { type: 'string' },
            eta: { type: 'string' },
            etd: { type: 'string' },
            ata: { type: 'string' },
            origin: { type: 'string' },
        },
        url: '',
        fileName: 'ports-arrival-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'purcahseOrders',
        columns: [
            {
                field: 'poNumber',
                title: 'PO Number',
                width: '160px',
                template:
                    '#if (poType==="AO") {# <a class="link-com" href="/allocation_orders/#=poId#/basic_details" >#=poNumber#</a> #} else if(poType==="ITPO") {# <a class="link-com" href="/purchase_order/#=poId#/basic_details" >#=poNumber#</a> #} else {# <a class="link-com" href="/inbound/#=poId#/basic_details" >#=poNumber#</a> #}#',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: '100px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '140px',
            },
            {
                field: 'orderDate',
                title: 'Order Date',
                width: '130px',
                template: '#if(orderDate=== null){# - #}else{# #=orderDate# #}#',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '150px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '150px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '140px',
            },
            {
                field: 'vendor',
                title: 'Vendor',
                width: '140px',
            },
            {
                field: 'destType',
                title: 'Dest Type',
                width: '160px',
            },
        ],
        fields: {
            poId: { type: 'string' },
            poType: { type: 'string' },
            poNumber: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            orderDate: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            client: { type: 'string' },
            vendor: { type: 'string' },
            destType: { type: 'string' },
        },
        url: '',
        fileName: 'warehouse-purchase-order-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'allocationOrders',
        columns: [
            {
                field: 'aoNumber',
                title: 'AO Number',
                template: '<a class="link-com" href="/allocation_orders/#=aoId#/basic_details" >#=aoNumber#</a>',
                width: '150px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '150px',
            },
            {
                field: 'orderDate',
                title: 'Order Date',
                // template: '#if(orderDate=== null){# - #}else{# #=orderDate# #}#',
                width: '160px',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '150px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '200px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '150px',
            },
            {
                field: 'agent',
                title: 'Agent',
                width: '150px',
            },
            {
                field: 'destType',
                title: 'Dest Type',
                width: '150px',
            },
            {
                field: 'bookingNumber',
                title: 'Booking No',
                width: '150px',
            },
        ],
        fields: {
            aoId: { type: 'string' },
            aoNumber: { type: 'string' },
            status: { type: 'string' },
            orderDate: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            client: { type: 'string' },
            agent: { type: 'string' },
            destType: { type: 'string' },
            bookingNumber: { type: 'string' },
        },
        url: '',
        fileName: 'warehouse-purchase-order-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'inventory',
        columns: [
            {
                field: 'sku',
                title: 'SKU',
                template: '<a class="link-com" href="/inventory?sku=#=sku#" >#=sku#</a>',
                width: '150px',
            },
            {
                field: 'description',
                title: 'Description',
                width: '150px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '150px',
            },
            {
                field: 'qtyScheduledForRcpt',
                title: 'QTY SCHEDULED FOR RECEIPT',
                width: '280px',
            },
            {
                field: 'availableQty',
                title: 'Available Qty',
                width: '170px',
            },
            {
                field: 'onHoldQty',
                title: 'On hold Qty',
                width: '170px',
            },
            {
                field: 'allocatedQty',
                title: 'Allocated Qty',
                width: '220px',
            },
            {
                field: 'stockOnHand',
                title: 'Stock On Hand',
                width: '200px',
            },
            {
                field: 'availableToOrder',
                title: 'Available To Order',
                width: '200px',
            },
        ],
        fields: {
            sku: { type: 'string' },
            description: { type: 'string' },
            client: { type: 'string' },
            qtyScheduledForRcpt: { type: 'float' },
            availableQty: { type: 'float' },
            onHoldQty: { type: 'float' },
            allocatedQty: { type: 'float' },
            stockOnHand: { type: 'float' },
            availableToOrder: { type: 'float' },
        },
        url: '',
        fileName: 'warehouse-purchase-order-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'receipt',
        columns: [
            {
                field: 'receiptID',
                title: 'Receipt ID',
                width: '120px',
                template: '<a class="link-com" href="/blp/receipt/#=receiptUUID#/receipt-header" >#=receiptID#</a>',
            },
            {
                field: 'status',
                title: 'Status',
                width: '120px',
            },
            {
                field: 'custClearanceDate',
                title: 'Customs Clearance Date',
                width: '230px',
                template: '#if(custClearanceDate === null){# - #}else{# #=custClearanceDate# #}#',
            },
            {
                field: 'goodsRecvdDate',
                title: 'Goods Received date',
                template: '#if(goodsRecvdDate === null){# - #}else{# #=goodsRecvdDate# #}#',
                width: '190px',
            },
            {
                field: 'vendor',
                title: 'Vendor',
                width: '130px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '120px',
            },
        ],
        fields: {
            receiptUUID: { type: 'string' },
            receiptID: { type: 'string' },
            status: { type: 'string' },
            custClearanceDate: { type: 'string' },
            goodsRecvdDate: { type: 'string' },
            vendor: { type: 'string' },
            client: { type: 'string' },
        },
        url: '',
        fileName: 'warehouse-purchase-order-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'sku-po',
        columns: [
            {
                field: 'poNumber',
                title: 'PO Number',
                width: '150px',
                template:
                    '#if (type==="AO") {# <a class="link-com" href="/allocation_orders/#=poId#/basic_details" >#=poNumber#</a> #} else if(type==="ITPO") {# <a class="link-com" href="/purchase_order/#=poId#/basic_details" >#=poNumber#</a> #} else {# <a class="link-com" href="/inbound/#=poId#/basic_details" >#=poNumber#</a> #}#',
            },
            {
                field: 'qty',
                title: 'QTY',
                width: '100px',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: '130px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '150px',
            },
            {
                field: 'orderDate',
                title: 'Order Date',
                width: '140px',
                template: '#if(orderDate === null){# - #}else{# #=orderDate# #}#',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '140px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '150px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '120px',
            },
            {
                field: 'agent',
                title: 'Agent',
                width: '120px',
            },
            {
                field: 'destType',
                title: 'Dest type',
                width: '150px',
            },
        ],
        fields: {
            poId: { type: 'string' },
            type: { type: 'string' },
            poNumber: { type: 'string' },
            qty: { type: 'number' },
            mode: { type: 'string' },
            status: { type: 'string' },
            orderDate: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            client: { type: 'string' },
            agent: { type: 'string' },
            destType: { type: 'string' },
        },
        url: '',
        fileName: 'sku-purchase-order-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'sku-bookings',
        columns: [
            {
                field: 'bpNumber',
                title: 'Booking No',
                width: '150px',
                template:
                    '#if (type==="FCL") {# <a class="link-com" href="/booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(type==="LCL") {# <a class="link-com" href="/lclbooking_list/#=bpId#/POSelection" >#=bpNumber#</a> #}else if(type==="BCN") {# <a class="link-com" href="/bcnBookingList/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(type==="AIR") {# <a class="link-com" href="/air_booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else {# <a class="link-com" href="/Aobooking_list/#=bpId#/AOSelection" >#=bpNumber#</a> #}#',
            },
            {
                field: 'qty',
                title: 'QTY',
                width: '100px',
            },

            {
                field: 'type',
                title: 'Type',
                width: '100px',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: '120px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '170px',
            },

            {
                field: 'origin',
                title: 'Origin',
                width: '130px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '150px',
            },
            {
                field: 'etd',
                title: 'ETD',
                width: '100px',
                template: '#if(etd === null){# - #}else{# #=etd# #}#',
            },
            {
                field: 'atd',
                title: 'ATD',
                width: '100px',
                template: '#if(atd === null){# - #}else{# #=atd# #}#',
            },
            {
                field: 'eta',
                title: 'ETA',
                width: '100px',
                template: '#if(eta === null){# - #}else{# #=eta# #}#',
            },
            {
                field: 'orderDate',
                title: 'ata',
                width: '100px',
                template: '#if(ata === null){# - #}else{# #=ata# #}#',
            },
        ],
        fields: {
            bpId: { type: 'string' },
            bpNumber: { type: 'string' },
            qty: { type: 'double' },
            type: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            etd: { type: 'string' },
            atd: { type: 'string' },
            eta: { type: 'string' },
            ata: { type: 'string' },
        },
        url: '',
        fileName: 'sku-bookings-data',
        method: 'POST',
        contentType: 'application/json',
    },

    {
        name: 'sku-inventory',
        columns: [
            {
                field: 'client',
                title: 'Client',
                width: '100px',
            },
            {
                field: 'qty',
                title: 'QTY SCHEDULED FOR RECEIPT',
                width: '240px',
            },

            {
                field: 'availableQty',
                title: 'Available QTY',
                width: '160px',
            },
            {
                field: 'onHoldQty',
                title: 'On Hold QTY',
                width: '150px',
            },
            {
                field: 'allocatedQty',
                title: 'ALLOCATED QTY',
                width: '180px',
            },

            {
                field: 'stockOnHand',
                title: 'Stock On Hand',
                width: '180px',
            },
            {
                field: 'availableToOrder',
                title: 'Available to Order',
                width: '200px',
            },
        ],
        fields: {
            invId: { type: 'string' },
            client: { type: 'string' },
            qty: { type: 'number' },
            availableQty: { type: 'number' },
            onHoldQty: { type: 'number' },
            allocatedQty: { type: 'number' },
            stockOnHand: { type: 'number' },
            availableToOrder: { type: 'number' },
        },
        url: '',
        fileName: 'sku-inventory-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'purchase-order',
        columns: [
            {
                field: 'linenumber',
                title: 'Line No',
                width: '150px',
            },
            {
                field: 'sku',
                title: 'SKU',
                width: '150px',
            },

            {
                field: 'description',
                title: 'Description',
                width: '150px',
            },
            {
                field: 'quantity',
                title: 'QTY',
                width: '150px',
            },

            {
                field: 'cbm',
                title: 'CBM',
                width: '150px',
            },
            {
                field: 'weight',
                title: 'Weight',
                width: '150px',
            },
            {
                field: 'confirmGroupNo',
                title: 'Confirmation Group No',
                width: '240px',
            },
            {
                field: 'confirmGroupStatus',
                title: 'Confirmation Group Status',
                width: '260px',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '170px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '170px',
            },
            {
                field: 'bookingNo',
                title: 'Booking No',
                width: '150px',
            },
        ],
        fields: {
            linenumber: { type: 'string' },
            sku: { type: 'string' },
            description: { type: 'string' },
            quantity: { type: 'number' },
            origin: { type: 'string' },
            weight: { type: 'number' },
            cbm: { type: 'number' },
            destination: { type: 'string' },
            confirmGroupNo: { type: 'string' },
            confirmGroupStatus: { type: 'string' },
            bookingNo: { type: 'string' },
        },
        url: '',
        fileName: 'purchase-order-data',
        method: 'POST',
        contentType: 'application/json',
    },
];

export const gridDetailsForTable = {
    clientBooking: {
        name: 'clientBooking',
        columns: [
            {
                field: 'bpNumber',
                title: 'Booking No',
                width: '150px',
                template:
                    '#if (mode==="FCL") {# <a class="link-com" href="/booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(mode==="LCL") {# <a class="link-com" href="/lclbooking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(mode==="BCN") {# <a class="link-com" href="/bcnBookingList/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(mode==="AIR") {# <a class="link-com" href="/air_booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else {# <a class="link-com" href="/Aobooking_list/#=bpId#/AOSelection" >#=bpNumber#</a> #}#',
            },
            {
                field: 'type',
                title: 'Type',
                width: '100px',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: '110px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '150px',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '150px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '150px',
            },
            {
                field: 'etd',
                title: 'ETD',
                width: '100px',
                template: '#if(etd === null){# - #}else{# #=etd# #}#',
            },
            {
                field: 'atd',
                title: 'ATD',
                width: '100px',
                template: '#if(atd === null){# - #}else{# #=atd# #}#',
            },
            {
                field: 'eta',
                title: 'ETA',
                width: '100px',
                template: '#if(eta === null){# - #}else{# #=eta# #}#',
            },
            {
                field: 'ata',
                title: 'ATA',
                width: '100px',
                template: '#if(ata === null){# - #}else{# #=ata# #}#',
            },
        ],
        fields: {
            bpNumber: { type: 'string' },
            type: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            eta: { type: 'string' },
            ata: { type: 'string' },
            etd: { type: 'string' },
            atd: { type: 'string' },
            bpId: { type: 'string' },
        },
        url: `${baseURL}/api/bkg/tracking/lifecycle/clientData/bpdetails`,
        fileName: 'client-booking-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    clientPurchaseOrder: {
        name: 'clientPurchaseOrder',
        columns: [
            {
                field: 'poNumber',
                title: 'PO Number',
                width: '135px',
                template:
                    '#if (poType==="AO") {# <a class="link-com" href="/allocation_orders/#=poId#/basic_details" >#=poNumber#</a> #} else if(poType==="ITPO") {# <a class="link-com" href="/purchase_order/#=poId#/basic_details" >#=poNumber#</a> #} else {# <a class="link-com" href="/inbound/#=poId#/basic_details" >#=poNumber#</a> #}#',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: '165px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '165px',
            },
            {
                field: 'orderDate',
                title: 'Order Date',
                width: '165px',
                template: '#if(orderDate === null){# - #}else{# #=orderDate# #}#',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '190px',
            },
            {
                field: 'destination',
                title: 'Destination	',
                width: '170px',
            },
            {
                field: 'vendorName',
                title: 'Vendor',
                width: '145px',
            },
            {
                field: 'agentName',
                title: 'Agent',
                width: '170px',
            },
            {
                field: 'destType',
                title: 'Destination Type',
                width: '180px',
            },
        ],
        fields: {
            poNumber: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            orderDate: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            vendorName: { type: 'string' },
            agentName: { type: 'string' },
            destType: { type: 'string' },
            poId: { type: 'string' },
            type: { type: 'string' },
        },
        url: `${baseURL}/api/ord/tracking/lifecycle/clientData/podetails`,
        fileName: 'client-purchase-orders-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    agentBooking: {
        name: 'agentBooking',
        columns: [
            {
                field: 'bookingNo',
                title: 'Booking No',
                width: '150px',
                template:
                    '#if (mode==="FCL") {# <a class="link-com" href="/booking_list/#=bpID#/POSelection" >#=bookingNo#</a> #} else if(mode==="LCL") {# <a class="link-com" href="/lclbooking_list/#=bpID#/POSelection" >#=bookingNo#</a> #}else if(mode==="BCN") {# <a class="link-com" href="/bcnBookingList/#=bpID#/POSelection" >#=bookingNo#</a> #} else if(mode==="AIR") {# <a class="link-com" href="/air_booking_list/#=bpID#/POSelection" >#=bookingNo#</a> #} else {# <a class="link-com" href="/Aobooking_list/#=bpID#/AOSelection" >#=bookingNo#</a> #}#',
            },
            {
                field: 'type',
                title: 'Type',
                width: '120px',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: '110px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '150px',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '160px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '160px',
            },
            {
                field: 'etd',
                title: 'ETD',
                width: '120px',
                template: '#if(etd === null){# - #}else{# #=etd# #}#',
            },
            {
                field: 'atd',
                title: 'ATD',
                width: '120px',
                template: '#if(atd === null){# - #}else{# #=atd# #}#',
            },
            {
                field: 'eta',
                title: 'ETA',
                width: '120px',
                template: '#if(eta === null){# - #}else{# #=eta# #}#',
            },
            {
                field: 'ata',
                title: 'ATA',
                width: '120px',
                template: '#if(ata === null){# - #}else{# #=ata# #}#',
            },
        ],
        fields: {
            sku: { type: 'string' },
            bookingNo: { type: 'string' },
            type: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            eta: { type: 'string' },
            ata: { type: 'string' },
            etd: { type: 'string' },
            atd: { type: 'string' },
            bpId: { type: 'string' },
        },
        url: ``,
        fileName: 'agent-booking-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    agentPortDetails: {
        name: 'agentPortDetails',
        columns: [
            {
                field: 'AP_PortName',
                title: 'Port Name',
                width: '160px',
            },
            {
                field: 'AP_Location',
                title: 'Location',
                width: '100px',
            },
            {
                field: 'AP_Direction',
                title: 'Direction',
                width: '100px',
            },
            {
                field: 'AP_AIR',
                title: 'Air',
                width: '100px',
            },
            {
                field: 'AP_Sea',
                title: 'Sea',
                width: '100px',
            },
            {
                field: 'AP_Rail',
                title: 'Rail',
                width: '100px',
            },
            {
                field: 'AP_Road',
                title: 'Road',
                width: '100px',
            },
            {
                field: 'AP_PortAddress',
                title: 'Port Address',
                width: '150px',
            },
        ],
        fields: {
            AP_PortName: { type: 'string' },
            AP_Location: { type: 'string' },
            AP_Direction: { type: 'string' },
            AP_AIR: { type: 'string' },
            AP_Sea: { type: 'string' },
            AP_Rail: { type: 'string' },
            AP_Road: { type: 'string' },
            AP_PortAddress: { type: 'string' },
        },
        url: ``,
        fileName: 'agent-port-details',
        method: 'GET',
        contentType: 'application/json',
        server: 'external',
    },
    agentPurchaseOrder: {
        name: 'agentPurchaseOrder',
        columns: [
            {
                field: 'poNumber',
                title: 'PO Number',
                width: '135px',
                template:
                    '#if (poType==="AO") {# <a class="link-com" href="/allocation_orders/#=poID#/basic_details" >#=poNumber#</a> #} else if(poType==="ITPO") {# <a class="link-com" href="/purchase_order/#=poID#/basic_details" >#=poNumber#</a> #} else {# <a class="link-com" href="/inbound/#=poID#/basic_details" >#=poNumber#</a> #}#',
            },
            {
                field: 'mode',
                title: 'Mode',
                width: '100px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '125px',
            },
            {
                field: 'orderDate',
                title: 'Order Date',
                width: '150px',
                template: '#if(orderDate === null){# - #}else{# #=orderDate# #}#',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '150px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '150px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '120px',
            },
            {
                field: 'vendor',
                title: 'Vendor',
                width: '120px',
            },
            {
                field: 'destType',
                title: 'Destination Type',
                width: '180px',
            },
        ],
        fields: {
            poNumber: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            orderDate: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            vendor: { type: 'string' },
            client: { type: 'string' },
            destType: { type: 'string' },
            poId: { type: 'string' },
            poType: { type: 'string' },
        },
        url: ``,
        fileName: 'agent-purchase-orders-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
};
