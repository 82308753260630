import React from 'react';
import '../../../assets/scss/components/_modal.scss';
import { useAppDispatch } from 'src/hooks';
import { releaseConfirmationGroup } from 'src/store/reducers/purchaseOrder/containerModePoReducer';

interface CancelProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    CG_PK: string;
}

const Index: React.FC<CancelProps> = ({ setModalView, CG_PK }) => {
    const dispatch = useAppDispatch();
    const releaseCg = () => {
        dispatch(releaseConfirmationGroup({ CG_PK }));
    };
    return (
        <div className="app-modal container">
            <div className="modal-content-holder">
                <div className="modal-header">
                    <h2 className="title">Release Confirmation Group</h2>
                    <button className="app-btn modal-close-btn" onClick={() => setModalView(false)}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <p>Are you sure you want to release this Confirmation Group?</p>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        onClick={() => setModalView(false)}
                    >
                        <span className="button-text vendor-button-text">No</span>
                    </button>
                    <button className="app-btn app-btn-primary modal-btn footer-btn" onClick={() => releaseCg()}>
                        <span className="button-text vendor-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
