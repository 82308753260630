import React from 'react';
// import './style.css';
import '../../../../assets/scss/components/_modal.scss';

interface BioProps {
    displayCancel: boolean;
    mode: string;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<BioProps> = (props) => {
    return (
        <>
            {props.displayCancel ? (
                <div className="app-modal container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">
                                {props.mode === 'add' ? 'Cancel SOP Addition' : 'Discard Changes'}
                            </h2>
                            <button className="app-btn modal-close-btn" onClick={() => props.setDisplayCancel(false)}>
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                {props.mode === 'add' ? (
                                    <span>
                                        The SOP will be discarded.
                                        <br /> Are you sure you want to cancel SOP Addition ?
                                    </span>
                                ) : (
                                    'Are you sure you want to discard all the changes made ?'
                                )}
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => props.setDisplayCancel(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                style={{ marginLeft: '8px' }}
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                onClick={() => {
                                    props.setDisplayCancel(false);
                                    props.displayFunction(false);
                                }}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
