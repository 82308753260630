/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { historyTableHeaders } from '../../utils/constants';

export interface HistoryDocumentsDtoList {
    name: string;
    ul_profileType: string;
    ul_profileName: string;
    modifiedByUser: string;
    ul_action: string;
    createdDateTime: string;
}
export interface HistoryDataDetails {
    userLogDtoList: HistoryDocumentsDtoList[];
    totalNoofItems: number;
    totalActiveProducts: number;
    totalActiveDocuments: number;
    totalNoOfPages: number;
    distinctColumnValues: HistoryDistinctColumnData | any;
}

interface HistoryData {
    status: number;
    message: string;
    info: HistoryDataDetails;
    data: any;
}

interface HistoryColumnData {
    status: number;
    message: string;
    info: string[];
    columnName?: string;
    data: any;
}
interface HistoryDistinctColumnData {
    createdDateTime: [];
    modifiedByUser: [];
    name: [];
    ul_action: [];
    ul_profileName: [];
    ul_profileType: [];
}

interface HistoryState {
    isLoading: boolean;
    history?: HistoryData;
    historyColumn?: HistoryColumnData | any;
    isError?: boolean;
    id?: number;
    currentPage: number;
    columnStatus: string;
    perPageCount: number;
    totalNoOfPages: number;
    totalNoOfItems: number;
}

const initialState: HistoryState = {
    isLoading: true,
    history: undefined,
    historyColumn: undefined,
    isError: false,
    id: undefined,
    currentPage: 0,
    columnStatus: historyTableHeaders.UserName,
    perPageCount: 10,
    totalNoOfPages: 1,
    totalNoOfItems: 0,
};

export const historySlice = createSlice({
    name: 'history',
    initialState,
    reducers: {
        fetchHistoryList: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload?.firstLoad ? 0 : action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.id = action.payload.id;
        },
        fetchHistoryListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.history = action.payload;
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
        fetchHistoryListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.history = action.payload;
        },
        fetchHistoryColumnList: (state, _action) => {
            state.isLoading = true;
        },
        fetchHistoryColumnListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.historyColumn = action.payload;
        },
        fetchHistoryColumnListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.historyColumn = action.payload;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearHistorys: (state, _action) => {
            // eslint-disable-next-line no-undefined
            state.history = undefined;
        },
    },
});

export const {
    fetchHistoryList,
    fetchHistoryListSuccess,
    fetchHistoryListFailed,
    fetchHistoryColumnList,
    fetchHistoryColumnListSuccess,
    fetchHistoryColumnListFailed,
    isLoadingFn,
    clearHistorys,
} = historySlice.actions;

export const selectHistoryLoading = (state: RootState) => state.historyData.isLoading;
export const selectHistoryUser = (state: RootState) => state.historyData.history;
export const selectHistoryColumnUser = (state: RootState) => state.historyData.historyColumn;
export const selectHistoryIsError = (state: RootState) => state.historyData.isError;
export const selectHistoryCurrentPage = (state: RootState) => state.historyData.currentPage;
export const selectHistoryColumnStatus = (state: RootState) => state.historyData.columnStatus;
export const selectHistoryPerPageCount = (state: RootState) => state.historyData.perPageCount;
export const selectHistorytotalNoOfPages = (state: RootState) => state.historyData.totalNoOfPages;
export const selectHistorytotalNoOfItems = (state: RootState) => state.historyData.totalNoOfItems;

export default historySlice.reducer;
