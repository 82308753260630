/* eslint-disable no-undefined */
import React, { useEffect, useState, Fragment } from 'react';
import '../../BookingProposal/BookingEnrichmentAdditionalDetail/booking-enrichment-additional.scss';
import '../../BookingProposal/BookingEnrichmentAdditionalDetail/house-bill.scss';
import BookingDetailModal from './BookingDetailModal';
// import CancelBookingModal from './CancelBookingModal';
import { useParams } from 'react-router-dom';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    fetchEnrichBooking,
    selectEnrichBooking,
    updateEnrichBooking,
    selectAdditionalDetailsIsError,
    selectAdditionalDetailsSuccess,
    selectAdditionalDetailsError,
    clearFailure,
    selectAdditionalDetailsLoading,
    selectHouseBillDetails,
    fetchHouseBills,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import CancelModal from '../../../components/common/CancelModal';
import { error, Position, success } from '../../../utils/toast';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import HouseAirwayModal from './HouseAirwayBillModal';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import ShowForPermissions from 'src/ShowForPermissions';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const [modalView, setModalView] = useState(false);
    const [cancelModalView, setCancelModalView] = useState(false);
    const [bookingRefNum, setBookingRefNum] = useState('');
    const [soNumber, setSoNumber] = useState('');
    const [houseBillNo, setHouseBillNo] = useState('');
    const [originCartageCompany, setOriginCartageCompany] = useState('');
    const { profileType } = useUserProfile();
    const houseBillData = useAppSelector(selectHouseBillDetails);
    const isError = useAppSelector(selectAdditionalDetailsIsError);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const additionalDetailsError = useAppSelector(selectAdditionalDetailsError);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);
    const [houseBilllModalView, setHouseBillModalView] = useState(false);

    useEffect(() => {
        dispatch(fetchEnrichBooking({ bpId: id }));
        dispatch(fetchHouseBills({ bpId: id, profileType }));
    }, []);

    const submit = () => {
        dispatch(
            updateEnrichBooking({
                bpId: id,
                bookingRefNum: bookingRefNum,
                soNumber: soNumber,
                houseBillNo: houseBillNo ? houseBillNo : undefined,
                originCartageCompany: originCartageCompany,
                mode: enrichBooking?.bookingRefNum ? 'update' : 'add',
            })
        );
    };

    useEffect(() => {
        if (enrichBooking) {
            setBookingRefNum(enrichBooking.bookingRefNum ? enrichBooking.bookingRefNum : '');
            setSoNumber(enrichBooking.soNumber ? enrichBooking.soNumber : '');
            setHouseBillNo(enrichBooking.houseBillNo ? enrichBooking.houseBillNo : '');
            setOriginCartageCompany(enrichBooking.originCartageCompany ? enrichBooking.originCartageCompany : '');
        }
    }, [modalView]);

    useEffect(() => {
        if (isError) {
            error(additionalDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (additionalDetailsSuccess?.status === 200 || additionalDetailsSuccess?.status === 201) {
            if (additionalDetailsSuccess?.data?.type === 'enrichBooking') {
                success(
                    `Booking Details ${
                        additionalDetailsSuccess?.data?.mode === 'add' ? 'added' : 'updated'
                    } successfully`,
                    Position.TOP_RIGHT
                );
                setModalView(false);
                dispatch(fetchEnrichBooking({ bpId: id }));
                dispatch(fetchBpHeaderData({ bpId: id, profileType }));
            }
        }
    }, [isError, additionalDetailsSuccess, additionalDetailsError]);

    return (
        <>
            <div className="booking-detail-wrapper">
                {/* <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Contract Details</h6>
                        </div>
                    </div>
                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Booking Contract No.</h6>
                            <div className="details-box-content">-</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Contract Type</h6>
                            <div className="details-box-content">
                                <div className="status-ui confirmed-status">FAK</div>
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Quotation (Buy)</h6>
                            <div className="details-box-content">
                                <a href="javascript:void(0)" target="_blank" className="details-box-content link-url">
                                    Maersk Quote 123223.pdf
                                </a>
                            </div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Total Origin Cost (Buy)</h6>
                            <div className="details-box-content">1400 USD</div>
                        </div>
                    </div>
                </div> */}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Booking Details</h6>
                            <ShowForPermissions type="bp" permission="edit">
                                {enrichBooking?.soNumber &&
                                    (profileType === 'agent' || profileType === 'forwarder') &&
                                    headerData?.bpStatus !== 'Booked' && (
                                        <button
                                            onClick={() => setModalView(true)}
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions type="bp" permission="edit">
                            {!enrichBooking?.soNumber &&
                                (profileType === 'agent' || profileType === 'forwarder') &&
                                headerData?.bpStatus !== 'Booked' && (
                                    <div className="btn-holder">
                                        <button
                                            onClick={() => setModalView(true)}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    </div>
                                )}
                        </ShowForPermissions>
                    </div>
                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Booking Quote Number<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {' '}
                                {enrichBooking?.bookingRefNum ? enrichBooking?.bookingRefNum : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                SO Number<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {enrichBooking?.soNumber ? enrichBooking?.soNumber : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Origin Cartage Company<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {enrichBooking?.originCartageCompany ? enrichBooking?.originCartageCompany : '-'}
                            </div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination Agent
                                <span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">-</div>
                        </div> */}
                    </div>
                </div>
                <div className="card details-box custom ">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Airway Bill Numbers</h6>
                            <ShowForPermissions type="bp" permission="edit">
                                {(profileType === 'agent' || profileType === 'forwarder') &&
                                    headerData?.bpStatus !== 'Booked' &&
                                    houseBillData?.numbers?.[0]?.vendors?.[0].houseBillNumber && (
                                        <button
                                            onClick={() => setHouseBillModalView(true)}
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon">
                                                    <title>Edit</title>
                                                </use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>

                        <ShowForPermissions type="bp" permission="edit">
                            {(profileType === 'agent' || profileType === 'forwarder') &&
                            headerData?.bpStatus !== 'Booked' &&
                            !houseBillData?.numbers?.[0]?.vendors?.[0].houseBillNumber ? (
                                <div className="btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Details"
                                        onClick={() => setHouseBillModalView(true)}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon">
                                                <title>Add</title>
                                            </use>
                                        </svg>
                                        <span className="button-text">Add Details</span>
                                    </button>
                                </div>
                            ) : null}
                        </ShowForPermissions>
                    </div>
                    {profileType !== 'client' && (
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Master Airway Bill No.</h6>
                                <div className="details-box-content">
                                    {houseBillData?.masterBillNumber ? houseBillData?.masterBillNumber : '-'}
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="card-head-holder add-btn-holder ">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">
                                House Airway Bill Number{houseBillData?.isHBLAutoGen ? ' (Auto Generated)' : ''}
                            </h6>
                            <span className="count">1 Vendor</span>
                        </div>
                    </div>
                    {houseBillData?.numbers?.map((item: any, index: number) => (
                        <Fragment key={index}>
                            {/* <div className="card-head-holder add-btn-holder -house-bill-wrapper">
                                <div
                                    className="card-head-btn-edit-holder"
                                    style={{ marginTop: '10px', marginBottom: '6px' }}
                                >
                                    <h6 className="details-box-heading-2">{item.client.name}</h6>
                                    <span className="count">{item.vendorCount}</span>
                                </div>
                            </div> */}
                            {/* <div className="card-detail-col">
                                {item.vendors?.map((vendor: any, indx: number) => (
                                    <div className="details-col box-content-holder" key={indx}>
                                        <h6 className="details-box-heading">
                                            {vendor.name}
                                            <span className="mandatory-field-text">*</span>
                                        </h6>
                                        <div className="details-box-content">{vendor.houseBillNumber}</div>
                                    </div>
                                ))}
                            </div> */}

                            <div className="card-detail-col">
                                {item.vendors.map((vendor: any, vendorIndex: number) => {
                                    return (
                                        <div className="details-col box-content-holder">
                                            <h6 className="details-box-heading">
                                                {vendorIndex + 1}. {vendor.name}
                                                <span className="mandatory-field-text">*</span>
                                            </h6>
                                            <div className="details-box-content">{vendor.houseBillNumber || '-'}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                    ))}
                </div>
            </div>
            <BookingDetailModal
                modalView={modalView}
                setModalView={setModalView}
                bookingRefNum={bookingRefNum}
                setBookingRefNum={setBookingRefNum}
                soNumber={soNumber}
                setSoNumber={setSoNumber}
                houseBillNo={houseBillNo}
                setHouseBillNo={setHouseBillNo}
                originCartageCompany={originCartageCompany}
                setOriginCartageCompany={setOriginCartageCompany}
                submit={submit}
                isLoading={isLoading}
                setCancelModalView={setCancelModalView}
                data={enrichBooking}
            />

            {/* <CancelBookingModal></CancelBookingModal> */}
            {houseBilllModalView ? (
                <HouseAirwayModal
                    setCancelModalView={setCancelModalView}
                    setHouseBillModalView={setHouseBillModalView}
                    houseBillData={houseBillData}
                ></HouseAirwayModal>
            ) : null}
            <CancelModal
                displayFunction={() => {
                    setModalView(false);
                    setHouseBillModalView(false);
                }}
                header="Booking Details"
                cancelModalVisibility={cancelModalView}
                setCancelModalVisibility={setCancelModalView}
            />
        </>
    );
};
export default Index;
