import React, { useCallback } from 'react';
import '@elfalem/leaflet-curve';
import { useLeafletContext } from '@react-leaflet/core';
import TrackLine from './TrackLine';
import { useMapClustering } from '../../hooks';

export type Coordinates = [number, number];

export interface Props {
    showTrackLine?: boolean;
    zoomlevel?: any;
}
const TrackLines: React.FC<Props> = ({ showTrackLine, zoomlevel }) => {
    const { trackLinesData } = useMapClustering();
    // Context type definition is incomplete in the library, so for the time being casting its type to 'any' for getting _layers property
    // TODO: Either define a custom type for context or check for the repo for the updates and remove 'any' from here if type has updated
    const context: any = useLeafletContext();
    const removeCurvedLines = useCallback(() => {
        if (context) {
            for (const layer in context.map._layers) {
                if (layer && context.map._layers[layer].options['name'] === 'dottedCurve') {
                    context.map.removeLayer(context.map._layers[layer]);
                }
            }
        }
    }, [context]);

    const renderTrackLines = useCallback(
        () =>
            trackLinesData.map((trackLine, index) => {
                return (
                    <TrackLine
                        key={`line${index}-${trackLine.originPoint[0]}`}
                        routePoints={trackLine.routePoints}
                        destinationPoint={trackLine.destinationPoint}
                        nextStop={trackLine.nextStop}
                        originPoint={trackLine.originPoint}
                        shippingMode={trackLine.shippingMode}
                        zoomlevel={zoomlevel}
                        // originPointName={trackLine.originPointName}
                        // destinationPointName={trackLine.destinationPointName}
                        // nextStopName={trackLine.nextStopName}
                        //TODO: Update show trackline value based on the icon selection.
                        //ie, bookings at booking at origin port selection
                    />
                );
            }),
        [trackLinesData]
    );

    if (showTrackLine) {
        return <>{renderTrackLines()}</>;
    } else {
        if (context) {
            removeCurvedLines();
        }

        return null;
    }
};
export default TrackLines;
