import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';

import '../BookingAdditionalInfo/reject-booking-proposal.scss';
import './PromptsModal.scss';
import { bpApproveAction } from 'src/services/apis/bookingProposal/addedPosApi';
import { buyerApprovalEventHistory } from 'src/services/apis/notesApi';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { success, Position, error } from 'src/utils/toast';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { RootState } from 'src/store/reducers';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { Button } from 'semantic-ui-react';
interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    currentPrompt: string;
    poNumber: string;
    cgId: string;
    poPk: string;
    bpActionComplete: Boolean;
    setBpActionComplete: Dispatch<SetStateAction<boolean>>;
    addOrSavePoData: any;
    coloaderData?: any;
    getColoader?: any;
}

const Index: React.FC<ModalProps> = ({
    setModalView,
    currentPrompt,
    poNumber,
    cgId,
    poPk,
    bpActionComplete,
    setBpActionComplete,
    addOrSavePoData,
    coloaderData,
    getColoader,
}) => {
    const { profileType } = useUserProfile();
    const { id } = useParams();
    const dispatch = useDispatch();
    const [reason, setReason] = useState('');
    const [confirmPo, setConfirmPo] = useState(poNumber);
    const [isProcessing, setIsProcessing] = useState(false);
    const [eventHistory, setEventHistory] = useState([]);
    const [eventHistoryLoading, setEventHistoryLoading] = useState(false);
    const location = useLocation();
    const [coloader, setColoader] = useState({ coloaderCode: '', coloaderName: '' });
    const { cgCount, bpType } = useSelector((state: RootState) => state.bpHeaderData);

    const postBuyerApproval = async () => {
        if (currentPrompt === 'Attach') {
            setIsProcessing(true);
            dispatch(
                addOrSavePoData({
                    cgIds: [cgId],
                    bpId: id === 'POSelection' || !id ? null : id,
                    reason,
                    bpType: bpType,
                })
            );
        } else {
            try {
                setIsProcessing(true);
                const res: any = await bpApproveAction(
                    currentPrompt,
                    reason,
                    cgId,
                    id === 'POSelection' || !id ? null : id,
                    coloader
                );
                setIsProcessing(false);
                setModalView(false);
                setBpActionComplete(!bpActionComplete);
                if (currentPrompt !== 'Approve' && currentPrompt !== 'Reject')
                    dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                success(res?.message, Position.TOP_RIGHT);
            } catch (err: any) {
                error(err?.response?.data?.message, Position.TOP_RIGHT);
            }
        }
    };

    useEffect(() => {
        readEventHistory();
    }, []);

    const readEventHistory = () => {
        setEventHistoryLoading(true);
        buyerApprovalEventHistory(cgId ?? '')
            .then((res: any) => {
                setEventHistory(res);
            })
            .catch((err: any) => console.log(err))
            .finally(() => {
                setEventHistoryLoading(false);
            });
    };

    const promptClassName = {
        ATB: 'hub-status-bg-blue',
        DTH: 'hub-status-bg-blue',
        REJ: 'hub-status-bg-amber',
        APR: 'hub-status-bg-green',
        CAN: 'hub-status-bg-red',
    } as any;

    const getPrompt = () => {
        if (currentPrompt === 'Cancel') {
            return {
                backgroundColor: '#ffebeb',
                borderColor: '#fd1010',
                message:
                    'Cancelling a PO is irreversable, The PO will be cancelled, removed from the booking and will not be reusable if you continue',
            };
        } else if (currentPrompt === 'Reject') {
            return {
                backgroundColor: '#fff2cc',
                borderColor: '#e8d18c',
                message:
                    "Rejecting a PO will update it's status on this booking. The PO will remain on the booking until it has been actioned",
            };
        } else if (currentPrompt === 'Remove') {
            return {
                backgroundColor: '#dae8fc',
                borderColor: '#94add2',
                message: 'Please provide a reason for removing this PO from the booking',
            };
        } else if (currentPrompt === 'Approve') {
            return {
                backgroundColor: '#dbfae8',
                borderColor: '#0e6232',
                message: 'Once a PO is buyer approved it cannot be changed',
            };
        } else if (currentPrompt === 'Attach') {
            return {
                backgroundColor: '#dbfae8',
                borderColor: '#0e6232',
                message: 'Please provide a reason for adding this PO to the booking',
            };
        }
    };

    const disableStatus = () => {
        if (location.pathname.includes('/lclbooking_list') && currentPrompt === 'Remove' && cgCount && cgCount > 1) {
            if (coloader?.coloaderCode && confirmPo === poNumber && reason?.trim()?.length) {
                return false;
            } else {
                return true;
            }
        } else if (currentPrompt !== 'Approve') {
            if (confirmPo === poNumber && reason?.trim()?.length) {
                return false;
            } else {
                return true;
            }
        } else {
            if (confirmPo === poNumber) {
                return false;
            } else {
                return true;
            }
        }
    };
    return (
        <div className="app-modal">
            <div className="modal-content-holder large-popup reject-booking-modal">
                <div className="modal-header">
                    <div className="title">{currentPrompt}</div>
                    <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        {currentPrompt !== 'History' && (
                            <>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">PO: </h6>
                                        <div className="description-type-1">{poNumber}</div>
                                    </div>
                                </div>
                                <div className="layout-holder one-column">
                                    <div
                                        className="warning-text-holder"
                                        style={{
                                            backgroundColor: getPrompt()?.backgroundColor,
                                            borderColor: getPrompt()?.borderColor,
                                        }}
                                    >
                                        <div className="warning-icon-text-wrapper prompt-warning-wrapper">
                                            {getPrompt()?.message}
                                        </div>
                                    </div>
                                </div>
                                {location.pathname.includes('/lclbooking_list') &&
                                currentPrompt === 'Remove' &&
                                cgCount &&
                                cgCount > 1 ? (
                                    <div className=" modal-input-holder">
                                        <label htmlFor="name" className="modal-input-label">
                                            Coloader<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder">
                                            <SearchableSelectBox
                                                data={coloaderData}
                                                placeholder="Select Coloader"
                                                setStateValue={(value: string) => {
                                                    const filtered = coloaderData?.find(
                                                        (res: any) => res?.value === value
                                                    );
                                                    setColoader({
                                                        coloaderCode: filtered?.value,
                                                        coloaderName: filtered?.label,
                                                    });
                                                }}
                                                onInputChangeFn={(value: string, actionMeta: any) => {
                                                    if (value?.length > 2) {
                                                        getColoader(value);
                                                    }
                                                }}
                                                onFocusFn={() => {
                                                    if (!coloader?.coloaderCode) {
                                                        getColoader('');
                                                    }
                                                }}
                                                onClear={() => getColoader('')}
                                                stateValue={coloader?.coloaderCode}
                                                fieldName="coloader"
                                                menuPortalTarget={document.getElementById('outside_div')}
                                            />
                                        </div>
                                    </div>
                                ) : null}
                                {currentPrompt !== 'Approve' && (
                                    <div className=" modal-input-holder">
                                        <label htmlFor="name" className="modal-input-label">
                                            Reason<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder">
                                            <textarea
                                                style={{ width: '100%', height: '10vh' }}
                                                name="reason"
                                                className="input-text"
                                                placeholder="Enter the reason"
                                                value={reason}
                                                onChange={(e) => setReason(e.target.value)}
                                                maxLength={250}
                                            ></textarea>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}

                        <div className="input-form-wrapper" style={{ flexDirection: 'column' }}>
                            {currentPrompt !== 'History' && (
                                <>
                                    <label htmlFor="name" className="modal-input-label">
                                        History
                                    </label>
                                    <br />
                                </>
                            )}
                            {eventHistoryLoading && (
                                <div>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <svg className="svg-icon loader-icon" style={{ width: '40px' }}>
                                            <use xlinkHref="#loaderIcon">
                                                <title>Loading</title>
                                            </use>
                                        </svg>
                                    </div>
                                </div>
                            )}
                            {eventHistory?.length > 0 && (
                                <div className="grid-container history-grid-prompt">
                                    <div className="grid-scroll-content">
                                        <table className="grid-table">
                                            <thead>
                                                <tr className="grid-sticky-row">
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Code</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Description</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Details</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>User</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Date</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {eventHistory?.map((res: any, index: number) => (
                                                    <tr
                                                        key={index}
                                                        className={`has-child row-expanded ${
                                                            promptClassName?.[res?.EV_CD_Code] ?? ''
                                                        }`}
                                                    >
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.EV_CD_Code}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.CD_Value}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {res?.EV_Context?.split('^Reason=')?.length > 1
                                                                    ? 'Reason: ' + res?.EV_Context.split('^Reason=')[1]
                                                                    : '-'}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.US_UserName}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {moment(res?.EV_CreatedDateTime).format('YYYY-MM-DD')}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                {currentPrompt !== 'History' && (
                    <div className="modal-footer prompts-footer-modal">
                        <div className="po-input-footer">
                            <span style={{ marginRight: '5px' }} className="po-input-footer-confirm">
                                Confirm PO Number
                            </span>
                            <input
                                placeholder="PO Number"
                                type="text"
                                value={confirmPo}
                                className="search-input input-text"
                                onChange={(e) => setConfirmPo(e.target.value)}
                            />
                        </div>

                        {isProcessing ? (
                            <Button primary loading className="app-btn app-btn-primary modal-btn footer-btn" disabled>
                                Loading
                            </Button>
                        ) : (
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Submit"
                                disabled={disableStatus()}
                                onClick={() => {
                                    postBuyerApproval();
                                }}
                            >
                                OK
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
export default Index;
