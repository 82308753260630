import React from 'react';
import '../../../assets/scss/components/_modal.scss';

interface UnsavedChangeModalProps {
    unsavedChangeModalVisibility: boolean;
    setUnsavedChangeModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    callBackFn: any;
}

const Index: React.FC<UnsavedChangeModalProps> = ({
    unsavedChangeModalVisibility,
    setUnsavedChangeModalVisibility,
    callBackFn,
}) => {
    return (
        <>
            {unsavedChangeModalVisibility ? (
                <div className="app-modal container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Unsaved Changes</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => setUnsavedChangeModalVisibility(false)}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>You have unsaved changes in this page. Are you sure to continue without saving ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => setUnsavedChangeModalVisibility(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                onClick={() => {
                                    setUnsavedChangeModalVisibility(false);
                                    callBackFn.retry();
                                }}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
