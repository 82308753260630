/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';
import { contractTableHeaders } from '../../utils/constants';

export interface ClientDocumentsDtoListContract {
    cd_PK: string;
    cd_docname: string;
    cd_version: string;
    cd_author: string;
    cd_forwardersignatory: string;
    cd_clientsignatory: string;
    cd_startdate: string;
    cd_enddate: string;
    cd_renewal: string;
    cd_services: string;
    cd_status: string;
    fileName?: string;
    cd_doctype: string;
}

export interface ContractDataDetails {
    clientDocumentsDtoList: ClientDocumentsDtoListContract[];
    distinctColumnValues: any;
    totalNoofItems: number;
    totalActiveProducts: number;
    totalActiveDocuments: number;
}

interface ContractData {
    status: number;
    message: string;
    info: ContractDataDetails;
    data: any;
}

interface ContractState {
    isLoading: boolean;
    contract?: ContractData;
    contractSuccess: any;
    contractError: any;
    contractAuthor: any;
    isError?: boolean;
    id?: number;
    currentPage: number;
    columnStatus: string;
    perPageCount: number;
    totalNoOfPages: number;
    totalNoOfItems: number;
    duplicatedScPk: string;
}

const initialState: ContractState = {
    isLoading: true,
    contract: undefined,
    contractSuccess: undefined,
    contractError: undefined,
    contractAuthor: undefined,
    isError: false,
    id: undefined,
    currentPage: 0,
    columnStatus: contractTableHeaders.Status,
    perPageCount: 10,
    totalNoOfPages: 1,
    totalNoOfItems: 0,
    duplicatedScPk: '',
};

export const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        duplicateContract: (state, action) => {
            state.duplicatedScPk = action.payload?.SC_PK;
        },
        fetchContractList: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload?.firstLoad ? 0 : action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.id = action.payload.id;
        },
        fetchContractListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.contract = action.payload;
            state.contractSuccess = {};
            state.contractError = {};
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
        fetchContractListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            // state.sop = action.payload;
            state.contractSuccess = {};
            state.contractError = action.payload;
        },
        createContractDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
            // state.contract = action.payload;
        },
        createContractDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.contractSuccess = action.payload;
            state.contractError = {};
        },
        createContractDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.contractSuccess = {};
            state.contractError = action.payload;
        },
        updateContractDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
            // state.contract = action.payload;
        },
        updateContractDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.contractSuccess = action.payload;
            state.contractError = {};
        },
        updateContractDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.contractSuccess = {};
            state.contractError = action.payload;
        },
        fetchAuthorDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAuthorDetailsSuccess(state, action) {
            state.isError = false;
            state.contractAuthor = action.payload;
        },
        fetchAuthorDetailsFailure(state, action) {
            state.isError = true;
            state.contractError = action.payload;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearContract: (state, _action) => {
            state.contract = undefined;
        },
        clearFailure: (state, _action) => {
            state.isError = false;
            state.contractError = undefined;
        },
        searchContract: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.contract = action.payload;
            state.contractSuccess = {};
            state.contractError = {};
            if (action.payload?.columnStatus) {
                state.columnStatus = action.payload.columnStatus;
            }
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
    },
});

export const {
    fetchContractList,
    fetchContractListSuccess,
    fetchContractListFailed,
    createContractDetails,
    createContractDetailsSuccess,
    createContractDetailsFailure,
    updateContractDetails,
    updateContractDetailsSuccess,
    updateContractDetailsFailure,
    fetchAuthorDetails,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    isLoadingFn,
    clearContract,
    clearFailure,
    searchContract,
    duplicateContract,
} = contractSlice.actions;

export const selectContractLoading = (state: RootState) => state.contractData.isLoading;
export const selectContractUser = (state: RootState) => state.contractData.contract;
export const selectContractSuccess = (state: RootState) => state.contractData.contractSuccess;
export const selectContractError = (state: RootState) => state.contractData.contractError;
export const selectContractAuthor = (state: RootState) => state.contractData.contractAuthor;
export const selectContractIsError = (state: RootState) => state.contractData.isError;
export const selectContractCurrentPage = (state: RootState) => state.contractData.currentPage;
export const selectContractColumnStatus = (state: RootState) => state.contractData.columnStatus;
export const selectContractPerPageCount = (state: RootState) => state.contractData.perPageCount;
export const selectContractotalNoOfPages = (state: RootState) => state.contractData.totalNoOfPages;
export const selectContractotalNoOfItems = (state: RootState) => state.contractData.totalNoOfItems;

export default contractSlice.reducer;
