import React, { useEffect, useState } from 'react';
import '../lcsBookingDetails.scss';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
const baseURL = process.env.REACT_APP_BASE_URL;
const Index: React.FC = () => {
    const location: any = useLocation();
    const [skuName, setSkuName] = useState<string>('');
    const { name } = location?.state || {};
    const { id } = useParams();

    useEffect(() => {
        if (name) {
            setSkuName(name);
        }
    }, [name]);
    const { selectedData } = useSelector((state: any) => state.lifeCycleSearch);

    const { userName } = useUserProfile();
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const [lcsTab, setLcsTab] = useState('poDetails');
    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };
    const purchaseOrderItem = gridDetails.filter((res: any) => res.name === 'sku-po')[0];

    const bookingsItem = gridDetails.filter((res: any) => res.name === 'sku-bookings')[0];

    const inventoryItem = gridDetails.filter((res: any) => res.name === 'sku-inventory')[0];

    useEffect(() => {
        purchaseOrderItem.url = `${baseURL}/api/ord/tracking/lifecycle/search/sku/po?sku=${
            selectedData?.name ?? skuName
        }`;

        bookingsItem.url = `${baseURL}/api/bkg/tracking/lifecycle/search/sku/bookings?sku=${
            selectedData?.name ?? skuName
        }`;
        inventoryItem.url = `${baseURL}/api/ord/tracking/lifecycle/search/sku/inventory?sku=${
            selectedData?.name ?? skuName
        }`;
    }, [lcsTab, selectedData, id, skuName]);

    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        // href="#"
                        className={`tab-nav ${lcsTab === 'poDetails' ? 'active' : ''}`}
                        onClick={() => setLcsView('poDetails')}
                    >
                        Purchase Orders
                    </a>
                    <a
                        // href="#"
                        className={`tab-nav ${lcsTab === 'bookings' ? 'active' : ''}`}
                        onClick={() => setLcsView('bookings')}
                    >
                        Bookings
                    </a>
                    <a
                        // href="#"
                        className={`tab-nav ${lcsTab === 'inventory' ? 'active' : ''}`}
                        onClick={() => setLcsView('inventory')}
                    >
                        Inventory
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content sku-detail">
                {lcsTab === 'poDetails' && name && (
                    <KendoGridJquery
                        gridItem={purchaseOrderItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'bookings' && (
                    <KendoGridJquery
                        gridItem={bookingsItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'inventory' && (
                    <KendoGridJquery
                        gridItem={inventoryItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
