import { ReactElement, useRef } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
import { useUserProfile } from '../../../hooks/useUserProfile';

interface ExportProps {
    exportList?: (type: string) => void;
    disabled: boolean;
    setShowExportDropDown: (value: boolean) => void;
    showExportDropDown: boolean;
    organisationData?: any;
    exportListWithBody?: (type: string, organisationData: any, profileType: string) => void;
    extraFilter?: any;
}

const ExportButton = ({
    exportList,
    disabled,
    showExportDropDown,
    setShowExportDropDown,
    organisationData,
    exportListWithBody,
    extraFilter,
}: ExportProps): ReactElement => {
    const { profileType } = useUserProfile();
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setShowExportDropDown as any);

    return (
        <div
            className={`dropdown-wrap ${showExportDropDown ? 'dropdown-open' : ''} download-dropdown normal-dropdown`}
            ref={wrapperRef}
        >
            <button
                className="app-btn export-btn icon-button  app-btn-secondary"
                onClick={(e) => {
                    e.stopPropagation();
                    setShowExportDropDown(!showExportDropDown);
                }}
                disabled={disabled}
            >
                <svg className="svg-icon export-icon">
                    <use xlinkHref="#exportIcon"></use>
                </svg>
                <span className="button-text">Export</span>
                <svg className="svg-icon arrow-icon">
                    <use xlinkHref="#downArrow">
                        <title>Expand row</title>
                    </use>
                </svg>
            </button>
            <div className="dropdown-menu">
                <a
                    className="menu-item app-btn"
                    onClick={() => {
                        setShowExportDropDown(false);
                        if (exportListWithBody && extraFilter) {
                            exportListWithBody('pdf', extraFilter, profileType);
                        } else if (exportListWithBody && organisationData && Object.keys(organisationData).length > 0) {
                            exportListWithBody('pdf', organisationData, profileType);
                        } else if (exportList) {
                            exportList('pdf');
                        }
                    }}
                >
                    Export as PDF
                </a>
                <a
                    className="menu-item app-btn"
                    onClick={() => {
                        setShowExportDropDown(false);
                        if (exportListWithBody && extraFilter) {
                            exportListWithBody('excel', extraFilter, profileType);
                        } else if (exportListWithBody && organisationData && Object.keys(organisationData).length > 0) {
                            exportListWithBody('excel', organisationData, profileType);
                        } else if (exportList) {
                            exportList('excel');
                        }
                    }}
                >
                    Export as Excel
                </a>
            </div>
        </div>
    );
};

export default ExportButton;
