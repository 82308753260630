import React, { useEffect, useState } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import HeaderTabs from '../HeaderTabs';
import '../BasicDetails/basic-details.scss';
import './product-master-edit.scss';
import '../../../../assets/scss/components/_tooltip.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { fetchBasicData, OrganisationContact } from '../../../../store/reducers/basicDetailsReducer';
import { useLocation } from 'react-router-dom';
import ToolTip from '../../../../components/ToolTip';
import { Address } from '../../../../services/apis/clientViewApi';
import ToolTipContentBox from '../../../../components/ToolTipBoxContent';
import { useParams, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackButton from '../../../../components/common/BackButton';
import BSTooltip from 'src/components/common/BSTooltip';
import Select from 'react-select';
import conversionQtyList from './conversionQtyList.json';
import { reactSelectStyle } from 'src/utils/constants';
import { validateFractionInput } from 'src/utils/validateFractionInput';
import {
    getItem,
    addItem,
    editItem,
    getPack,
    addPack,
    editPack,
    deletePack,
    getVendor,
    deleteVendor,
    addVendor,
    editVendor,
} from 'src/services/apis/productsEditApi';
import { fetchData } from '../../../../store/reducers/productReducer';
import { success, error, Position } from 'src/utils/toast';
import { decimalNumberValidation } from 'src/utils';
import { checkSkunHsName } from 'src/services/apis/productApi';
import { getVendorData } from 'src/services/apis/purchaseOrder/addPoReducerApi';
import { fetchHeaderDataClients } from 'src/store/reducers/profiles/clients';
import { useAppDispatch } from '../../../../hooks';

// import './style.css';

const Index: React.FC = () => {
    const { id, pmPk } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [mode, setMode] = useState(pmPk === 'add' ? 'add' : 'view');
    const [editIndexPack, setEditIndexPack] = useState<any>(null);
    const [editIndexVendor, setEditIndexVendor] = useState<any>(null);
    const [productHeader, setProductHeader] = useState<any>({
        PM_SKU: '',
        PM_Description: '',
        PM_PurchaseCode: '',
        PM_FumigationRequired: false,
        PM_CD_DGClassification: '',
    });
    const [productPacks, setProductPacks] = useState<any>([]);
    const [productVendors, setProductVendors] = useState<any>([]);
    const [allVendors, setAllVendors] = useState<any>([]);
    const [hsCodeValidation, sethsCodeValidation] = useState(false);
    const [currentSku, setCurrentSku] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const { clientCode } = useSelector((state: RootState) => state.productData);

    const getProductHeaderData = (productPk?: string) =>
        getItem(id, productPk ? productPk : pmPk)
            .then((res: any) => setProductHeader(res?.data))
            .catch((err: any) => console.log('error', err))
            .finally(() => setIsLoading(false));

    const getProductPackData = () =>
        getPack(id, pmPk === 'add' ? '' : pmPk)
            .then((res: any) => setProductPacks(res?.data))
            .catch((err: any) => console.log('error', err))
            .finally(() => setIsLoading(false));

    const getProductVendorData = () =>
        getVendor(id, pmPk === 'add' ? '' : pmPk)
            .then((res: any) => setProductVendors(res?.data))
            .catch((err: any) => console.log('error', err))
            .finally(() => setIsLoading(false));

    const getAllClientVendorData = () =>
        getVendorData({ fromAddPo: true, clientCode: id })
            .then((res: any) => setAllVendors(res))
            .catch((err: any) => console.log('error', err))
            .finally(() => setIsLoading(false));

    const deleteProductPack = (packId: string) => {
        setIsLoading(true);
        deletePack(id, pmPk, packId)
            .then((res: any) => {
                success('Pack Details deleted successfully', Position.TOP_RIGHT);
                getProductPackData();
            })
            .catch((err: any) => {
                console.log('error', err);
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            })
            .finally(() => setIsLoading(false));
    };

    const deleteProductVendor = (vendorId: string) => {
        setIsLoading(true);
        deleteVendor(id, pmPk, vendorId)
            .then((res: any) => {
                success('Vendor deleted successfully', Position.TOP_RIGHT);
                getProductVendorData();
            })
            .catch((err: any) => {
                console.log('error', err);
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            })
            .finally(() => setIsLoading(false));
    };

    const getInitialData = async () => {
        setIsLoading(true);
        await getProductHeaderData();
        await getProductPackData();
        await getProductVendorData();
        await getAllClientVendorData();
    };

    useEffect(() => {
        if (id && pmPk !== 'add') {
            getInitialData();
        }
        dispatch(
            fetchData({
                currentPage: 0,
                perPageCount: 10,
                columnOrder: true,
                columnStatus: 'pm_sku',
                statusFilter: 'pm_sku',
                id: id,
            })
        );
    }, [id, pmPk]);

    useEffect(() => {
        if (productHeader?.PM_SKU && !currentSku) {
            setCurrentSku(productHeader?.PM_SKU);
        }
    }, [productHeader]);

    const submitProductHeader = async () => {
        try {
            setIsLoading(true);
            let validationSku = false;
            if (currentSku && currentSku !== productHeader?.PM_SKU) {
                validationSku = await checkSkunHsName({
                    type: 'sku',
                    value: productHeader?.PM_SKU,
                    clientCode: clientCode,
                });
            }
            if (validationSku) {
                error('SKU already exists', Position.TOP_RIGHT);
                setIsLoading(false);
                return false;
            }
            if (mode === 'edit') {
                editItem(id, pmPk, productHeader)
                    .then((res: any) => {
                        success(`Product details for ${productHeader?.PM_SKU} have been updated`, Position.TOP_RIGHT);
                        getProductHeaderData();
                        setCurrentSku(productHeader?.PM_SKU);
                        setMode('view');
                    })
                    .catch((err: any) => {
                        console.log('error', err);
                        error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                    })
                    .finally(() => setIsLoading(false));
            } else {
                addItem(id, productHeader)
                    .then((res: any) => {
                        success(`${productHeader?.PM_SKU} has been added to Product Master`, Position.TOP_RIGHT);
                        getProductHeaderData(res?.data?.data?.[0]?.PM_PK);
                        setCurrentSku(productHeader?.PM_SKU);
                        setMode('view');
                        setTimeout(() => {
                            location.href = `/profile/clients/${id}/product_master/${res?.data?.data?.[0]?.PM_PK}`;
                        }, 1000);
                    })
                    .catch((err: any) => {
                        console.log('error', err);
                        error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                    })
                    .finally(() => setIsLoading(false));
            }
        } catch (err: any) {
            setIsLoading(false);
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
    };
    const submitProductPack = (data?: any) => {
        setIsLoading(true);
        if (data?.PP_PK) {
            editPack(id, pmPk, data?.PP_PK, data)
                .then((res: any) => {
                    success('Pack Details updated successfully', Position.TOP_RIGHT);
                    getProductPackData();
                    setEditIndexPack(null);
                })
                .catch((err: any) => {
                    console.log('error', err);
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                })
                .finally(() => setIsLoading(false));
        } else {
            addPack(id, pmPk, data)
                .then((res: any) => {
                    success('Pack Details created successfully', Position.TOP_RIGHT);
                    getProductPackData();
                    setEditIndexPack(null);
                })
                .catch((err: any) => {
                    console.log('error', err);
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const submitProductPackSaveStatus = (data: any) => {
        return (
            data?.PP_Quantity &&
            data?.PP_ConversionQuantity > 0 &&
            data?.PP_ConversionQuantity !== '' &&
            data?.PP_Length >= 0 &&
            data?.PP_Length !== '' &&
            data?.PP_Width >= 0 &&
            data?.PP_Width !== '' &&
            data?.PP_Height >= 0 &&
            data?.PP_Height !== '' &&
            data?.PP_CBM >= 0 &&
            data?.PP_CBM !== '' &&
            data?.PP_Weight !== '' &&
            data?.PP_Weight >= 0
        );
    };
    const submitProductHeaderSaveStatus = () => {
        return (
            productHeader?.PM_SKU &&
            productHeader?.PM_Description &&
            productHeader?.PM_PurchaseCode &&
            typeof productHeader?.PM_FumigationRequired === 'boolean' &&
            productHeader?.PM_CD_DGClassification
        );
    };

    const submitProductVendor = (data: any) => {
        setIsLoading(true);
        if (data?.VP_PK) {
            editVendor(id, pmPk, data?.VN_PK, { VP_SKU: data?.VP_SKU })
                .then((res: any) => {
                    success('Vendor Details updated successfully', Position.TOP_RIGHT);
                    getProductVendorData();
                    setEditIndexVendor(null);
                })
                .catch((err: any) => {
                    console.log('error', err);
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                })
                .finally(() => setIsLoading(false));
        } else {
            addVendor(id, pmPk, data?.VN_PK, { VP_SKU: data?.VP_SKU })
                .then((res: any) => {
                    success('Vendor Details created successfully', Position.TOP_RIGHT);
                    getProductVendorData();
                    setEditIndexVendor(null);
                })
                .catch((err: any) => {
                    console.log('error', err);
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                })
                .finally(() => setIsLoading(false));
        }
    };

    const createNewPacket = () => {
        const packetCopy = JSON.parse(JSON.stringify(productPacks));
        const newData = {
            PP_Quantity: productPacks?.length === 0 ? 'Each' : '',
            PP_ConversionQuantity: productPacks?.length === 0 ? 1 : '',
            PP_Length: '',
            PP_Width: '',
            PP_Height: '',
            PP_CBM: '',
            PP_Weight: '',
            PP_BarCode: '',
        };
        setProductPacks([...packetCopy, newData]);
        setEditIndexPack(packetCopy?.length);
    };

    const createNewVendor = () => {
        const vendorCopy = JSON.parse(JSON.stringify(productVendors));
        const newData = {
            VP_SKU: '',
            VN_PK: '',
        };
        setProductVendors([...vendorCopy, newData]);
        setEditIndexVendor(vendorCopy?.length);
    };

    const filterSelectionEdit = () => {
        const conversionQtyListCopy: any = JSON.parse(JSON.stringify(conversionQtyList));
        const productPacksCopy: any = JSON.parse(JSON.stringify(productPacks));
        let updated = [];

        if (productPacks?.length) {
            for (let i = 0; i < conversionQtyListCopy?.length; i++) {
                if (!productPacksCopy?.some((res: any) => res?.PP_Quantity === conversionQtyListCopy[i].value)) {
                    updated.push(conversionQtyListCopy[i]);
                }
            }
            if (editIndexPack && typeof productPacksCopy[editIndexPack]?.PP_PK === 'string') {
                return [
                    {
                        value: productPacksCopy[editIndexPack]?.PP_Quantity,
                        label: productPacksCopy[editIndexPack]?.PP_Quantity,
                    },
                    ...updated,
                ];
            }
            return updated;
        } else {
            return conversionQtyListCopy;
        }
    };

    const validationFnOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        decimalNumberValidation(parseFloat(e.target.value), 11, 5) && e.target.value?.length < 18;

    const valiadtionFnOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.currentTarget.value?.length > 16) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <>
            <div className="main-wrapper container product-edit">
                {/* <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton clientName={'test'} /> */}
                {/* <HeaderTabs disabled={showMandatoryFieldsIcon()} /> */}
                {/* </div> */}

                <div className={`content-section ${mode !== 'view' ? 'edit' : ''} main-wrapper basic-detail-wrapper`}>
                    <div className="card details-box custom">
                        {isLoading && (
                            <div className="app-loader loading" style={{ zIndex: '9999' }}>
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                        )}
                        {/* <h6 className="details-box-heading-2">Profile Information</h6> */}
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    SKU<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {mode !== 'view' ? (
                                        <input
                                            placeholder="Enter SKU"
                                            type="text"
                                            className="modal-input input-text with-border"
                                            onChange={(event) => {
                                                const value = event.target.value;
                                                setProductHeader((prev: any) => ({
                                                    ...prev,
                                                    PM_SKU: value.replace(/\s+/g, ''),
                                                }));
                                            }}
                                            value={productHeader?.PM_SKU}
                                        />
                                    ) : (
                                        productHeader?.PM_SKU
                                    )}
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Description<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {mode !== 'view' ? (
                                        <textarea
                                            placeholder="Enter Description"
                                            className="input-text text-area"
                                            onChange={(e) =>
                                                setProductHeader((prev: any) => ({
                                                    ...prev,
                                                    PM_Description: e.target.value,
                                                }))
                                            }
                                            maxLength={150}
                                            rows={5}
                                            value={productHeader?.PM_Description}
                                        />
                                    ) : (
                                        productHeader?.PM_Description
                                    )}
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Code<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {mode !== 'view' ? (
                                        <input
                                            placeholder="Enter Code"
                                            type="text"
                                            className="modal-input input-text with-border"
                                            onChange={(e) =>
                                                setProductHeader((prev: any) => ({
                                                    ...prev,
                                                    PM_PurchaseCode: e.target.value,
                                                }))
                                            }
                                            value={productHeader?.PM_PurchaseCode}
                                        />
                                    ) : (
                                        productHeader?.PM_PurchaseCode
                                    )}
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Fumigation Required</h6>
                                <div className="details-box-content">
                                    {mode !== 'view' ? (
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    onChange={(e) =>
                                                        setProductHeader((prev: any) => ({
                                                            ...prev,
                                                            PM_FumigationRequired: e.target.checked,
                                                        }))
                                                    }
                                                    checked={productHeader?.PM_FumigationRequired}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon tick-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                            </label>
                                        </div>
                                    ) : productHeader?.PM_FumigationRequired ? (
                                        'Yes'
                                    ) : (
                                        'No'
                                    )}
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Dangerous Goods Classification<span className="mandatory-field-text">*</span>
                                </h6>

                                {mode !== 'view' ? (
                                    <>
                                        <div className="details-box-content">
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                        onChange={(e) =>
                                                            setProductHeader((prev: any) => ({
                                                                ...prev,
                                                                PM_CD_DGClassification: e.target.checked ? 'NON' : '',
                                                            }))
                                                        }
                                                        checked={productHeader?.PM_CD_DGClassification === 'NON'}
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">
                                                        NON (Non-hazardous, Non-dangerous)
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="details-box-content">
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                        onChange={(e) =>
                                                            setProductHeader((prev: any) => ({
                                                                ...prev,
                                                                PM_CD_DGClassification: e.target.checked ? 'NOZ' : '',
                                                            }))
                                                        }
                                                        checked={productHeader?.PM_CD_DGClassification === 'NOZ'}
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">
                                                        NOZ (Non-hazardous, Dangerous good)
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="details-box-content">
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                        onChange={(e) =>
                                                            setProductHeader((prev: any) => ({
                                                                ...prev,
                                                                PM_CD_DGClassification: e.target.checked ? 'HAZ' : '',
                                                            }))
                                                        }
                                                        checked={productHeader?.PM_CD_DGClassification === 'HAZ'}
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">
                                                        HAZ (Hazardous, Dangerous good)
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className="details-box-content">
                                        {(() => {
                                            if (productHeader?.PM_CD_DGClassification === 'NON') {
                                                return 'NON (Non-hazardous, Non-dangerous)';
                                            } else if (productHeader?.PM_CD_DGClassification === 'NOZ') {
                                                return 'NOZ (Non-hazardous, Dangerous good)';
                                            } else if (productHeader?.PM_CD_DGClassification === 'HAZ') {
                                                return 'HAZ (Hazardous, Dangerous good)';
                                            } else {
                                                return '-';
                                            }
                                        })()}
                                    </div>
                                )}
                            </div>
                        </div>
                        {mode !== 'view' && (
                            <button
                                title="Save"
                                disabled={submitProductHeaderSaveStatus() ? false : true}
                                className="app-btn app-btn-primary"
                                onClick={() => submitProductHeader()}
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        )}
                    </div>
                </div>
                {mode !== 'add' && (
                    <>
                        <div className="card-detail-col">
                            <div className="modal-section model-section-grid">
                                <div className="grid-section modal-grid" style={{ borderTop: 'none' }}>
                                    <div className="grid-holder">
                                        <div className="grid-header">
                                            <h2 className="">Pack Details</h2>
                                            {mode !== 'view' && (
                                                <button
                                                    className="app-btn app-btn-secondary icon-button  add-product-btn"
                                                    title="Add Pack"
                                                    onClick={() => createNewPacket()}
                                                    disabled={editIndexPack !== null}
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#plusIcon"></use>
                                                    </svg>
                                                    <span className="button-text footer-button-text">Add Pack</span>
                                                </button>
                                            )}
                                        </div>

                                        <div className="grid-container">
                                            {productPacks?.length === 0 && (
                                                <div className="grid-no-content ">
                                                    <svg className="svg-icon grid-no-content-icon ">
                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                    </svg>
                                                    <p className="no-content-message">No records found</p>
                                                </div>
                                            )}
                                            <div className="grid-scroll-content">
                                                <table className="grid-table">
                                                    <thead>
                                                        <tr className="grid-sticky-row">
                                                            <th
                                                                className="grid-cell-header grid-sticky-column sticky-left-aligned col-quantity-uom"
                                                                style={{ zIndex: '1000' }}
                                                            >
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        Quantity UOM
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header  col-conversion-qty">
                                                                <div className=" grid-cell-data">
                                                                    <span className="header-title">
                                                                        CONVERSION QUANTITY
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-length">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        Length(m)
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-width">
                                                                <div className=" grid-cell-data">
                                                                    <span className="header-title">
                                                                        width(m)
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-height">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        height(m)
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>

                                                            <th className="grid-cell-header col-cbm">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        CBM
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-weight">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        weight(kg)
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th
                                                                className={`grid-cell-header grid-sticky-column ${
                                                                    mode === 'view' ? 'sticky-right-aligned' : ''
                                                                } col-barcode`}
                                                            >
                                                                <div className=" grid-cell-data">
                                                                    <span className="header-title">barcode</span>
                                                                </div>
                                                            </th>
                                                            {mode !== 'view' && (
                                                                <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-actions">
                                                                    <div
                                                                        style={{ width: '100px' }}
                                                                        className="grid-cell-data header-title"
                                                                    >
                                                                        ACTIONS
                                                                    </div>
                                                                </th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    {mode === 'view' ? (
                                                        <tbody>
                                                            {productPacks?.map((res: any) => (
                                                                <tr className="has-child row-expanded">
                                                                    <td
                                                                        style={{
                                                                            position: true ? 'initial' : 'sticky',
                                                                        }}
                                                                        className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                    >
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_Quantity}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell td-conversion-qty">
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_ConversionQuantity}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell td-length">
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_Length}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell th-width">
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_Width}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell td-height">
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_Height}
                                                                        </div>
                                                                    </td>

                                                                    <td className="grid-cell td-cbm ">
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_CBM}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell td-weight ">
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_Weight}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell grid-sticky-column sticky-right-aligned td-barcode">
                                                                        <div className="grid-cell-data">
                                                                            {res?.PP_BarCode}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            {productPacks?.map((res: any, index: any) =>
                                                                index !== editIndexPack ? (
                                                                    <tr key={index} className="has-child row-expanded">
                                                                        <td
                                                                            style={{
                                                                                position: 'sticky',
                                                                            }}
                                                                            className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                        >
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_Quantity}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-conversion-qty">
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_ConversionQuantity}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-length">
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_Length}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell th-width">
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_Width}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-height">
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_Height}
                                                                            </div>
                                                                        </td>

                                                                        <td className="grid-cell td-cbm ">
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_CBM}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-weight ">
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_Weight}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell grid-sticky-column td-barcode">
                                                                            <div className="grid-cell-data">
                                                                                {res?.PP_BarCode}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                            <div className="grid-cell-data">
                                                                                <button
                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                    title="Remove"
                                                                                    type="button"
                                                                                    disabled={
                                                                                        res.PP_Quantity === 'Each' ||
                                                                                        (editIndexPack !== null &&
                                                                                            index !== editIndexPack)
                                                                                    }
                                                                                    onClick={() =>
                                                                                        deleteProductPack(res?.PP_PK)
                                                                                    }
                                                                                >
                                                                                    <svg className="svg-icon cancel-icon ">
                                                                                        <use xlinkHref="#cancelIcon"></use>
                                                                                        <title>Remove</title>
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                    title="Edit"
                                                                                    onClick={() => {
                                                                                        setEditIndexPack(index);
                                                                                    }}
                                                                                    disabled={
                                                                                        editIndexPack !== null &&
                                                                                        index !== editIndexPack
                                                                                    }
                                                                                    type="button"
                                                                                >
                                                                                    <svg className="svg-icon save-icon ">
                                                                                        <use xlinkHref="#editIcon"></use>
                                                                                        <title>Edit</title>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr
                                                                        key={index}
                                                                        className="has-child row-expanded align-top"
                                                                    >
                                                                        <td
                                                                            className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                            style={{
                                                                                position: 'sticky',
                                                                            }}
                                                                        >
                                                                            <div className="grid-cell-data">
                                                                                <div
                                                                                    className={`dropdown-wrap menu-down dropdown-open modal-dropdown ${
                                                                                        res?.PP_Quantity === 'Each'
                                                                                            ? 'disabled-select'
                                                                                            : ''
                                                                                    }`}
                                                                                >
                                                                                    <Select
                                                                                        // menuPlacement="bottom"
                                                                                        menuShouldScrollIntoView={false}
                                                                                        isDisabled={
                                                                                            res?.PP_Quantity === 'Each'
                                                                                        }
                                                                                        menuPortalTarget={document.getElementById(
                                                                                            'outside_div'
                                                                                        )}
                                                                                        options={filterSelectionEdit()}
                                                                                        isClearable={false}
                                                                                        placeholder="Select"
                                                                                        menuPosition="fixed"
                                                                                        onChange={(option: any) => {
                                                                                            var newArr = [
                                                                                                ...productPacks,
                                                                                            ];
                                                                                            newArr[index].PP_Quantity =
                                                                                                option.value;
                                                                                            setProductPacks(newArr);
                                                                                        }}
                                                                                        styles={reactSelectStyle}
                                                                                        value={conversionQtyList.filter(
                                                                                            (option: any) => {
                                                                                                if (
                                                                                                    option.value ===
                                                                                                    res.PP_Quantity
                                                                                                ) {
                                                                                                    return {
                                                                                                        label: res.PP_Quantity,
                                                                                                        value: res.PP_Quantity,
                                                                                                    };
                                                                                                }
                                                                                            }
                                                                                        )}
                                                                                        components={{
                                                                                            DropdownIndicator: () =>
                                                                                                null,
                                                                                            IndicatorSeparator: () =>
                                                                                                null,
                                                                                        }}
                                                                                    />

                                                                                    {/* {res?.pp_quantity === '' &&
                                                                                            isSaving && (
                                                                                                <p className="error_message_product">
                                                                                                    This field is
                                                                                                    required
                                                                                                </p>
                                                                                            )} */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell  td-conversion-qty">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="number"
                                                                                        className="modal-grid-input input-text"
                                                                                        name="PP_ConversionQuantity"
                                                                                        min="1"
                                                                                        disabled={
                                                                                            res?.PP_Quantity === 'Each'
                                                                                        }
                                                                                        value={
                                                                                            res?.PP_ConversionQuantity
                                                                                        }
                                                                                        onKeyPress={(e) => {
                                                                                            valiadtionFnOnKeyPress(e);
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            if (
                                                                                                validationFnOnChange(
                                                                                                    e
                                                                                                ) &&
                                                                                                parseFloat(
                                                                                                    e.target.value
                                                                                                ) !== 0
                                                                                            ) {
                                                                                                const newArr = [
                                                                                                    ...productPacks,
                                                                                                ];
                                                                                                newArr[
                                                                                                    index
                                                                                                ].PP_ConversionQuantity =
                                                                                                    parseFloat(
                                                                                                        e.target.value
                                                                                                    );
                                                                                                setProductPacks(newArr);
                                                                                            } else {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                return false;
                                                                                            }
                                                                                        }}
                                                                                    ></input>
                                                                                    {/* {
                                                                                            // res?.pp_conversionquantity ===
                                                                                            // 0 ||
                                                                                            Number.isNaN(
                                                                                                res.pp_conversionquantity
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        } */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-length">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="PP_Length"
                                                                                        value={res?.PP_Length}
                                                                                        onKeyPress={(e) => {
                                                                                            valiadtionFnOnKeyPress(e);
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            if (
                                                                                                validationFnOnChange(e)
                                                                                            ) {
                                                                                                const newArr = [
                                                                                                    ...productPacks,
                                                                                                ];
                                                                                                newArr[
                                                                                                    index
                                                                                                ].PP_Length = parseFloat(
                                                                                                    e.target.value
                                                                                                );
                                                                                                setProductPacks(newArr);
                                                                                            } else {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                return false;
                                                                                            }
                                                                                        }}
                                                                                        className="modal-grid-input input-text"
                                                                                    ></input>
                                                                                    {/* {
                                                                                            // res?.pp_length ===
                                                                                            // 0 ||
                                                                                            Number.isNaN(
                                                                                                res.pp_length
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        } */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell th-width">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="PP_Width"
                                                                                        value={res?.PP_Width}
                                                                                        onKeyPress={(e) => {
                                                                                            valiadtionFnOnKeyPress(e);
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            if (
                                                                                                validationFnOnChange(e)
                                                                                            ) {
                                                                                                const newArr = [
                                                                                                    ...productPacks,
                                                                                                ];
                                                                                                newArr[index].PP_Width =
                                                                                                    parseFloat(
                                                                                                        e.target.value
                                                                                                    );
                                                                                                setProductPacks(newArr);
                                                                                            } else {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                return false;
                                                                                            }
                                                                                        }}
                                                                                        className="modal-grid-input input-text"
                                                                                    ></input>
                                                                                    {/* {
                                                                                            // res?.pp_width === 0 ||
                                                                                            Number.isNaN(
                                                                                                res.pp_width
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        } */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-height">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        name="PP_Height"
                                                                                        value={res?.PP_Height}
                                                                                        onKeyPress={(e) => {
                                                                                            valiadtionFnOnKeyPress(e);
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            if (
                                                                                                validationFnOnChange(e)
                                                                                            ) {
                                                                                                const newArr = [
                                                                                                    ...productPacks,
                                                                                                ];
                                                                                                newArr[
                                                                                                    index
                                                                                                ].PP_Height = parseFloat(
                                                                                                    e.target.value
                                                                                                );
                                                                                                setProductPacks(newArr);
                                                                                            } else {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                return false;
                                                                                            }
                                                                                        }}
                                                                                        className="modal-grid-input input-text"
                                                                                    ></input>
                                                                                    {/* {
                                                                                            // res?.pp_height ===
                                                                                            // 0 ||
                                                                                            Number.isNaN(
                                                                                                res.pp_height
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        } */}
                                                                                </div>
                                                                            </div>
                                                                        </td>

                                                                        <td className="grid-cell td-cbm ">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        className="modal-grid-input input-text"
                                                                                        name="PP_CBM"
                                                                                        value={res?.PP_CBM}
                                                                                        onChange={(e) => {
                                                                                            if (
                                                                                                validationFnOnChange(e)
                                                                                            ) {
                                                                                                const newArr = [
                                                                                                    ...productPacks,
                                                                                                ];
                                                                                                newArr[index].PP_CBM =
                                                                                                    parseFloat(
                                                                                                        e.target.value
                                                                                                    );
                                                                                                setProductPacks(newArr);
                                                                                            } else {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                return false;
                                                                                            }
                                                                                        }}
                                                                                        onKeyPress={(event) => {
                                                                                            valiadtionFnOnKeyPress(
                                                                                                event
                                                                                            );
                                                                                            if (
                                                                                                !validateFractionInput({
                                                                                                    inputKey: event.key,
                                                                                                    currentValue:
                                                                                                        productPacks[
                                                                                                            index
                                                                                                        ].PP_CBM,
                                                                                                })
                                                                                            ) {
                                                                                                event.preventDefault();
                                                                                            }
                                                                                        }}
                                                                                    ></input>
                                                                                    {/* {
                                                                                            // res?.pp_cbm === 0 ||
                                                                                            Number.isNaN(res.pp_cbm) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        } */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-weight">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="number"
                                                                                        min="0"
                                                                                        className="modal-grid-input input-text"
                                                                                        name="pp_weight"
                                                                                        value={res?.PP_Weight}
                                                                                        onKeyPress={(e) => {
                                                                                            valiadtionFnOnKeyPress(e);
                                                                                        }}
                                                                                        onChange={(e) => {
                                                                                            if (
                                                                                                validationFnOnChange(e)
                                                                                            ) {
                                                                                                const newArr = [
                                                                                                    ...productPacks,
                                                                                                ];
                                                                                                newArr[
                                                                                                    index
                                                                                                ].PP_Weight = parseFloat(
                                                                                                    e.target.value
                                                                                                );
                                                                                                setProductPacks(newArr);
                                                                                            } else {
                                                                                                e.preventDefault();
                                                                                                e.stopPropagation();
                                                                                                return false;
                                                                                            }
                                                                                        }}
                                                                                    ></input>
                                                                                    {/* {
                                                                                            // res?.pp_weight === 0 ||
                                                                                            Number.isNaN(
                                                                                                res.pp_weight
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        } */}
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell  td-barcode">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="text"
                                                                                        className="modal-grid-input input-text"
                                                                                        name="PP_BarCode"
                                                                                        value={res?.PP_BarCode || ''}
                                                                                        onChange={(e) => {
                                                                                            const value =
                                                                                                e.target.value;
                                                                                            const regex =
                                                                                                /^[a-zA-Z0-9 ]+$/; //this will admit letters, numbers and dashes
                                                                                            if (
                                                                                                value.match(regex) ||
                                                                                                value === ''
                                                                                            ) {
                                                                                                var newArr = [
                                                                                                    ...productPacks,
                                                                                                ];
                                                                                                newArr[
                                                                                                    index
                                                                                                ].PP_BarCode =
                                                                                                    e.target.value;
                                                                                                setProductPacks(newArr);
                                                                                            }
                                                                                        }}
                                                                                        maxLength={150}
                                                                                    ></input>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                            <div className="grid-cell-data">
                                                                                <div className="grid-cell-data">
                                                                                    <button
                                                                                        className="action-btn app-btn app-btn-secondary "
                                                                                        title="Cancel"
                                                                                        type="button"
                                                                                        // disabled={
                                                                                        //     res.PP_Quantity === 'Each'
                                                                                        // }
                                                                                        onClick={() => {
                                                                                            if (res?.PP_PK) {
                                                                                                getProductPackData();
                                                                                            } else {
                                                                                                var newAr = [
                                                                                                    ...productPacks,
                                                                                                ];

                                                                                                newAr.splice(index, 1);
                                                                                                setProductPacks(newAr);
                                                                                            }
                                                                                            setEditIndexPack(null);
                                                                                        }}
                                                                                    >
                                                                                        <svg className="svg-icon cancel-icon ">
                                                                                            <use xlinkHref="#closetableIcon"></use>
                                                                                            <title>Remove</title>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <button
                                                                                        className="action-btn app-btn app-btn-secondary "
                                                                                        title="Save"
                                                                                        onClick={() =>
                                                                                            submitProductPack(res)
                                                                                        }
                                                                                        disabled={
                                                                                            submitProductPackSaveStatus(
                                                                                                res
                                                                                            )
                                                                                                ? false
                                                                                                : true
                                                                                        }
                                                                                        type="button"
                                                                                    >
                                                                                        <svg className="svg-icon save-icon ">
                                                                                            <use xlinkHref="#saveIcon"></use>
                                                                                            <title>Save</title>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-detail-col">
                            <div className="modal-section model-section-grid">
                                <div className="grid-section modal-grid" style={{ borderTop: 'none' }}>
                                    <div className="grid-holder">
                                        <div className="grid-header">
                                            <h2 className="">Vendor Details</h2>
                                            {mode !== 'view' && (
                                                <button
                                                    className="app-btn app-btn-secondary icon-button  add-product-btn"
                                                    title="Add Vendor"
                                                    onClick={() => createNewVendor()}
                                                    disabled={editIndexVendor !== null}
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#plusIcon"></use>
                                                    </svg>
                                                    <span className="button-text footer-button-text">Add Vendor</span>
                                                </button>
                                            )}
                                        </div>

                                        <div className="grid-container">
                                            {productVendors?.length === 0 && (
                                                <div className="grid-no-content ">
                                                    <svg className="svg-icon grid-no-content-icon ">
                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                    </svg>
                                                    <p className="no-content-message">No records found</p>
                                                </div>
                                            )}
                                            <div className="grid-scroll-content">
                                                <table className="grid-table">
                                                    <thead>
                                                        <tr className="grid-sticky-row">
                                                            <th className="grid-cell-header  col-conversion-qty">
                                                                <div className=" grid-cell-data">
                                                                    <span className="header-title">
                                                                        Vendor
                                                                        <span className="mandatory-field-text">*</span>
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-length">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">Vendor SKU</span>
                                                                </div>
                                                            </th>
                                                            {mode !== 'view' && (
                                                                <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-actions">
                                                                    <div
                                                                        style={{ width: '100px' }}
                                                                        className="grid-cell-data header-title"
                                                                    >
                                                                        ACTIONS
                                                                    </div>
                                                                </th>
                                                            )}
                                                        </tr>
                                                    </thead>
                                                    {mode === 'view' ? (
                                                        <tbody>
                                                            {productVendors?.map((res: any) => (
                                                                <tr className="has-child row-expanded">
                                                                    <td className="grid-cell td-conversion-qty">
                                                                        <div className="grid-cell-data">
                                                                            {res?.VN_VendorName}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell td-length">
                                                                        <div className="grid-cell-data">
                                                                            {res?.VP_SKU}
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    ) : (
                                                        <tbody>
                                                            {productVendors?.map((res: any, index: any) =>
                                                                index !== editIndexVendor ? (
                                                                    <tr key={index} className="has-child row-expanded">
                                                                        <td className="grid-cell td-conversion-qty">
                                                                            <div className="grid-cell-data">
                                                                                {res?.VN_VendorName}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell td-length">
                                                                            <div className="grid-cell-data">
                                                                                {res?.VP_SKU}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                            <div className="grid-cell-data">
                                                                                <button
                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                    title="Remove"
                                                                                    type="button"
                                                                                    disabled={
                                                                                        editIndexVendor !== null &&
                                                                                        index !== editIndexVendor
                                                                                    }
                                                                                    onClick={() =>
                                                                                        deleteProductVendor(res?.VN_PK)
                                                                                    }
                                                                                >
                                                                                    <svg className="svg-icon cancel-icon ">
                                                                                        <use xlinkHref="#cancelIcon"></use>
                                                                                        <title>Remove</title>
                                                                                    </svg>
                                                                                </button>
                                                                                <button
                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                    title="Edit"
                                                                                    onClick={() => {
                                                                                        setEditIndexVendor(index);
                                                                                    }}
                                                                                    disabled={
                                                                                        editIndexVendor !== null &&
                                                                                        index !== editIndexVendor
                                                                                    }
                                                                                    type="button"
                                                                                >
                                                                                    <svg className="svg-icon save-icon ">
                                                                                        <use xlinkHref="#editIcon"></use>
                                                                                        <title>Edit</title>
                                                                                    </svg>
                                                                                </button>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                ) : (
                                                                    <tr
                                                                        key={index}
                                                                        className="has-child row-expanded align-top"
                                                                    >
                                                                        <td className="grid-cell td-quantity-uom">
                                                                            <div className="grid-cell-data">
                                                                                <div
                                                                                    className={`dropdown-wrap menu-down dropdown-open modal-dropdown ${
                                                                                        res?.VP_PK
                                                                                            ? 'disabled-select'
                                                                                            : ''
                                                                                    }`}
                                                                                >
                                                                                    <Select
                                                                                        // menuPlacement="bottom"
                                                                                        menuShouldScrollIntoView={false}
                                                                                        options={allVendors}
                                                                                        isClearable={false}
                                                                                        placeholder="Select"
                                                                                        menuPosition="fixed"
                                                                                        isDisabled={
                                                                                            res?.VP_PK ? true : false
                                                                                        }
                                                                                        menuPortalTarget={document.getElementById(
                                                                                            'outside_div'
                                                                                        )}
                                                                                        onChange={(option: any) => {
                                                                                            var newArr = [
                                                                                                ...productVendors,
                                                                                            ];
                                                                                            newArr[index].VN_PK =
                                                                                                option.id;
                                                                                            newArr[
                                                                                                index
                                                                                            ].VN_VendorCode =
                                                                                                option.value;
                                                                                            newArr[
                                                                                                index
                                                                                            ].VN_VendorName =
                                                                                                option.name;
                                                                                            setProductVendors(newArr);
                                                                                        }}
                                                                                        styles={reactSelectStyle}
                                                                                        value={
                                                                                            res.VN_VendorCode
                                                                                                ? {
                                                                                                      value: res.VN_VendorCode,
                                                                                                      label: `${res.VN_VendorName} (${res.VN_VendorCode})`,
                                                                                                  }
                                                                                                : null
                                                                                        }
                                                                                        components={{
                                                                                            DropdownIndicator: () =>
                                                                                                null,
                                                                                            IndicatorSeparator: () =>
                                                                                                null,
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell  td-conversion-qty">
                                                                            <div className="grid-cell-data">
                                                                                <div className="modal-grid-input-holder">
                                                                                    <input
                                                                                        placeholder="Enter value"
                                                                                        type="text"
                                                                                        className="modal-grid-input input-text"
                                                                                        name="VP_SKU"
                                                                                        value={res?.VP_SKU}
                                                                                        onChange={(e) => {
                                                                                            const newArr = [
                                                                                                ...productVendors,
                                                                                            ];
                                                                                            newArr[index].VP_SKU =
                                                                                                e.target.value;
                                                                                            setProductVendors(newArr);
                                                                                        }}
                                                                                    ></input>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                            <div className="grid-cell-data">
                                                                                <div className="grid-cell-data">
                                                                                    <button
                                                                                        className="action-btn app-btn app-btn-secondary "
                                                                                        title="Cancel"
                                                                                        type="button"
                                                                                        // disabled={
                                                                                        //     res.PP_Quantity === 'Each'
                                                                                        // }
                                                                                        onClick={() => {
                                                                                            if (res?.VP_PK) {
                                                                                                getProductVendorData();
                                                                                            } else {
                                                                                                var newAr = [
                                                                                                    ...productVendors,
                                                                                                ];

                                                                                                newAr.splice(index, 1);
                                                                                                setProductVendors(
                                                                                                    newAr
                                                                                                );
                                                                                            }
                                                                                            setEditIndexVendor(null);
                                                                                        }}
                                                                                    >
                                                                                        <svg className="svg-icon cancel-icon ">
                                                                                            <use xlinkHref="#closetableIcon"></use>
                                                                                            <title>Remove</title>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <button
                                                                                        className="action-btn app-btn app-btn-secondary "
                                                                                        title="Save"
                                                                                        onClick={() =>
                                                                                            submitProductVendor(res)
                                                                                        }
                                                                                        disabled={
                                                                                            res?.VN_PK ? false : true
                                                                                        }
                                                                                        type="button"
                                                                                    >
                                                                                        <svg className="svg-icon save-icon ">
                                                                                            <use xlinkHref="#saveIcon"></use>
                                                                                            <title>Save</title>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    )}
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <Link
                            className="app-btn app-btn-secondary footer-btn"
                            to={`/profile/clients/${id}/product_master`}
                        >
                            <span className="button-text footer-button-text">Back</span>
                        </Link>
                        {mode !== 'add' && (
                            <button
                                className="app-btn app-btn-primary footer-btn"
                                onClick={() => setMode(() => (mode === 'edit' ? 'view' : 'edit'))}
                            >
                                <span className="button-text footer-button-text">
                                    {mode === 'edit' ? 'Cancel' : 'Edit'}
                                </span>
                            </button>
                        )}
                    </div>
                </div>
            </div>
            <div style={{ zIndex: 9999 }} id="outside_div"></div>
        </>
    );
};

export default Index;
