import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/store/reducers';
import { clearSelectedSearch } from 'src/store/reducers/dashboard/dashoboardSearchReducer';

import SearchField from './SearchField';

import '../Map/styles/map.scss';
import {
    clearInsightDestination,
    clearInsightMode,
    setInsightsDurationRange,
    setInsightsForwarded,
    setInsightsMainPage,
    setInsightsSelectedType,
    setInsightsSelectedTypeFilter,
    setSelectedTypeFilterIndex,
} from 'src/store/reducers/dashboard/insightsReducer';
import { makeApiCall } from '../Insights/makeApiCall';
import { FilterList, insightTabList } from 'src/pages/Dashboard/Insights/enums';
import { getStatusTitle } from 'src/pages/Dashboard/Insights/getStatusTitle';
import { Type } from 'src/pages/Dashboard/InsightsExpanded/enums';
import { SearchHeaderDisplayName } from 'src/pages/Dashboard/Search/searchHeaderDispayName';
// import { useLocation } from 'react-router-dom';

export default function SearchHeader() {
    const dispatch = useDispatch();
    // const location = useLocation();
    let insightsArray: string[] = [];
    // let favInsightArray: string[] = [];
    const [favInsightArray, setfavInsightArray] = useState<string[]>([]);
    const { isRemoveAllSuccess } = useSelector((state: RootState) => state.favourites);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);

    const {
        insightsMode,
        insightsDestination,
        // insightsDurationRange,
        insightsSelectedType,
        insightsSelectedTypeFilter,
        // insightsForwarded,
        insightTab,
        insightsMainPage,
        favInsightsMode,
        favInsightsDestination,
        favInsightsForwarded,
        favInsightsSelectedType,
        favInsightsSelectedTypeFilter,
        insightsFavMainPage,
    } = useSelector((state: RootState) => state.dashboardInsights);
    const isActiveOrders =
        insightsMainPage === 'Active Orders' || insightsMainPage === 'Active Order Details' || insightsMainPage === ''
            ? true
            : false;

    const isActiveBookings =
        insightsMainPage === 'Active Bookings' || insightsMainPage === 'Active Bookings Details' ? true : false;
    //==================================== setting header filter display ====================================//
    insightsArray = insightsMode?.concat(insightsDestination);
    if (insightsSelectedTypeFilter) {
        insightsArray = insightsArray.concat(
            isActiveOrders
                ? 'Order Status: '.concat(getStatusTitle(insightsSelectedTypeFilter))?.split(',')
                : isActiveBookings
                ? 'Booking Status: '.concat(getStatusTitle(insightsSelectedTypeFilter))?.split(',')
                : ''
        );
    }
    if (insightsSelectedType) {
        insightsArray = insightsArray.concat(insightsSelectedType?.split(','));
    }
    //======================================================================================================//

    useEffect(() => {
        let resultArray = favInsightsMode?.concat(favInsightsDestination);
        if (favInsightsSelectedTypeFilter) {
            resultArray = resultArray.concat(
                favInsightsForwarded === 'active_orders'
                    ? 'Order Status: '.concat(getStatusTitle(favInsightsSelectedTypeFilter))?.split(',')
                    : 'Booking Status: '.concat(getStatusTitle(favInsightsSelectedTypeFilter))?.split(',')
            );
        }
        if (favInsightsSelectedType) {
            resultArray = resultArray.concat(favInsightsSelectedType?.split(','));
        }
        if (insightsFavMainPage) {
            resultArray = resultArray.concat(insightsFavMainPage?.split(','));
        }
        setfavInsightArray(resultArray);
    }, [
        favInsightsMode,
        favInsightsDestination,
        favInsightsForwarded,
        favInsightsSelectedType,
        favInsightsSelectedTypeFilter,
        insightsFavMainPage,
        isRemoveAllSuccess,
    ]);

    // function handleClear() {
    //     dispatch(clearSelectedSearch());
    //     makeApiCall(dispatch, insightsMode, insightsDurationRange, insightsDestination, 'activeBooking', '');
    //     makeApiCall(dispatch, insightsMode, insightsDurationRange, insightsDestination, 'activeOrder', '');
    // }
    function handleResetSearch() {
        dispatch(clearSelectedSearch());
    }
    function handleReset() {
        if (insightTab === insightTabList.all) {
            insightsArray = [];
            dispatch(clearInsightMode());
            dispatch(clearInsightDestination());
            dispatch(setInsightsDurationRange(FilterList.Days));
            dispatch(setInsightsSelectedTypeFilter(''));
            dispatch(setSelectedTypeFilterIndex(0));
            dispatch(setInsightsSelectedType(''));
            // if (insightsForwarded === 'active_orders') {
            // if (location?.pathname === '/dashboard/insight') {
            dispatch(setInsightsMainPage(''));
            // } else {
            //     dispatch(setInsightsMainPage(''));
            // }
            // } else if (insightsForwarded === 'active_bookings') {
            //     dispatch(setInsightsMainPage(''));
            // } else {
            //     dispatch(setInsightsMainPage(''));
            // }
            makeApiCall(dispatch, [], FilterList.Days, [], 'activeOrdersDetails', insightsSelectedTypeFilter);
            makeApiCall(dispatch, [], FilterList.Days, [], 'activeOrder', '');
            makeApiCall(dispatch, [], FilterList.Days, [], 'activeBookingsDetails', insightsSelectedTypeFilter);
            makeApiCall(dispatch, [], FilterList.Days, [], 'activeBooking', '');
        } else {
            return false;
        }
    }

    const bookingOrOrderMapping = {
        active_orders: 'Active Orders',
        active_bookings: 'Active Bookings',
    } as any;

    const filterListObj = {
        fcl: 'Mode: FCL',
        lcl: 'Mode: LCL',
        bcn: 'Mode: BCN',
        air: 'Mode: Air',
        sea: 'Mode: Sea',
        land: 'Mode: Land',
        International: 'Destination Type: International',
        Triangle: 'Destination Type: Triangle',
        [Type.itpo]: `${bookingOrOrderMapping[favInsightsForwarded]}: International POs`,
        [Type.ibpo]: `${bookingOrOrderMapping[favInsightsForwarded]}: Inbound POs`,
        [Type.ao]:
            favInsightsForwarded === 'active_orders'
                ? `${bookingOrOrderMapping[favInsightsForwarded]}: Allocation Orders`
                : `${bookingOrOrderMapping[favInsightsForwarded]}: AO Bookings`,
        [Type.po]: `${bookingOrOrderMapping[favInsightsForwarded]}: PO Bookings`,
    } as any;

    //=========== checking is active tab all ===============//
    const isInsightAllTabActive = React.useMemo(() => {
        let insightAllActive = false;
        if (insightTab === insightTabList.all) {
            if (selectedSearchItem || insightsArray?.length > 0) {
                insightAllActive = true;
            } else if (isActiveOrders && insightsMainPage !== '') {
                insightAllActive = true;
            } else if (isActiveBookings) {
                insightAllActive = true;
            } else {
                insightAllActive = false;
            }
        }
        return insightAllActive;
    }, [insightTab, selectedSearchItem, insightsArray]);
    //======================================================//

    return (
        <div className="main-header-content-holder dashboard-header">
            <div className="dashborad-header-right-section">
                <h1 className="header-title">Dashboard</h1>
                <div className="status-badge-result">
                    {isInsightAllTabActive ? <div className="result-title">Showing results for:</div> : <></>}
                    {selectedSearchItem ? (
                        <>
                            <div className="result-badge-items-holder">
                                <div className="count-insight">
                                    {selectedSearchItem?.type}:
                                    <span className="count-content">
                                        {selectedSearchItem?.name ||
                                            selectedSearchItem?.poNumber ||
                                            selectedSearchItem?.bpNumber ||
                                            selectedSearchItem?.vendorName}
                                    </span>
                                    {/* <button className="app-btn text-close-btn" title="Close" onClick={handleClear}>
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </button> */}
                                </div>
                            </div>
                        </>
                    ) : (
                        <></>
                    )}
                    &nbsp;&nbsp;
                    {insightTab === insightTabList.all && insightsArray?.length && insightsArray?.length > 0 ? (
                        insightsArray.reverse()?.map((val: string) => (
                            <>
                                <div className="result-badge-items-holder">
                                    <div className="count-insight">
                                        {SearchHeaderDisplayName(val, isActiveOrders, isActiveBookings)}
                                    </div>
                                    &nbsp;&nbsp;
                                </div>
                            </>
                        ))
                    ) : (
                        <></>
                    )}
                    {insightTab === insightTabList.all &&
                    isActiveOrders &&
                    insightsMainPage !== '' &&
                    !insightsSelectedType ? (
                        <>
                            <div className="result-badge-items-holder">
                                <div className="count-insight">{'Active Orders'}</div>
                            </div>
                            &nbsp;&nbsp;
                        </>
                    ) : (
                        <></>
                    )}
                    {insightTab === insightTabList.all && isActiveBookings && !insightsSelectedType ? (
                        <>
                            <div className="result-badge-items-holder">
                                <div className="count-insight">{'Active Bookings'}</div>
                            </div>
                            &nbsp;&nbsp;
                        </>
                    ) : (
                        <></>
                    )}
                    {insightTab === insightTabList.fav && favInsightArray?.length
                        ? favInsightArray.reverse()?.map((val: string) => (
                              <>
                                  {filterListObj[val] ? (
                                      <div className="result-badge-items-holder">
                                          <div className="count-insight">{filterListObj[val]}</div>
                                      </div>
                                  ) : null}
                                  {val?.startsWith('Order Status') ||
                                  val?.startsWith('Booking Status') ||
                                  val === 'Active Bookings' ||
                                  val === 'Active Orders' ? (
                                      <div className="result-badge-items-holder">
                                          <div className="count-insight">{val}</div>
                                      </div>
                                  ) : null}
                                  &nbsp;&nbsp;
                              </>
                          ))
                        : null}
                    &nbsp;&nbsp;
                    {isInsightAllTabActive || selectedSearchItem ? (
                        <div className="link">
                            <a
                                className="reset-link"
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    handleReset();
                                    handleResetSearch();
                                    dispatch(setInsightsForwarded(''));
                                }}
                            >
                                Reset
                            </a>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>

            {/* {selectedSearchItem?.name ||
                selectedSearchItem?.vendorName ||
                selectedSearchItem?.poNumber ||
                selectedSearchItem?.bpNumber} */}
            <div className="dashboard-left-portion">
                <SearchField />
            </div>
        </div>
    );
}
