import React, { useEffect } from 'react';
import BSTooltip from 'src/components/common/BSTooltip';
import SearchField from 'src/pages/Tracking/LifecycleSearch/SearchField';
import './favouritesList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import {
    fetchFavList,
    removeFromFav,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { setSelectedData } from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import { useNavigate } from 'react-router-dom';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const { favList, removeSuccess } = useSelector((state: RootState) => state.lifecyclesearchFav);
    const { userName, profileType } = useUserProfile();
    const navigate = useNavigate();

    const getLatestFavData = () => {
        dispatch(fetchFavList({ userName, profileType }));
    };

    useEffect(() => {
        getLatestFavData();
    }, []);

    useEffect(() => {
        if (removeSuccess) {
            getLatestFavData();
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    const removeFromLifeCycleFav = (id: string) => {
        dispatch(removeFromFav({ id }));
    };

    const redirectToSearchResult = (item: any) => {
        if (item.searchResult) {
            const { id, searchType, name, type } = JSON.parse(item.searchResult);
            dispatch(setSelectedData({ id, searchType, name, type }));
            navigate(`/tracking/lifecycle_booking/${searchType}/${id}`, {
                state: {
                    name,
                    type,
                },
            });
        }
    };

    return (
        <div className="lcs-favourites">
            <h5 className="lcs-title">Lifecycle Search</h5>
            <p className="lcs-text">
                Search for PO, Bookings, Agents and{' '}
                <BSTooltip
                    id="sd"
                    title={
                        <>
                            <div>Carrier</div>
                            <div>Client</div>
                            <div>House Bill</div>
                            <div>Master Bill</div>
                            <div>Port</div>
                            <div>SKU</div>
                            <div>Vendor</div>
                            <div>Vessel</div>
                            <div>Warehouse</div>
                        </>
                    }
                    position="right"
                    tooltipClassList="tooltip-right"
                >
                    <a href="#" className="lcs-more">
                        More
                    </a>
                </BSTooltip>
            </p>
            <SearchField />
            <div className="lcs-favourites-wrapper">
                <h4>Favourites</h4>
                <div className="lcs-favourites-grid">
                    {favList?.favourites?.map((item: any, index: number) => {
                        return (
                            <a
                                className="lcs-favourites-card"
                                key={`fav_${index}`}
                                onClick={() => {
                                    redirectToSearchResult(item);
                                }}
                            >
                                <span className="lcs-favourites-title">{item.favourite}</span>
                                <span className="lcs-favourites-value">{item.number}</span>
                                <div className="icons-holder">
                                    <button
                                        className="action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star selected-fav-icon"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            removeFromLifeCycleFav(item.id);
                                        }}
                                    >
                                        <svg className="svg-icon star-icon">
                                            <use xlinkHref="#starIcon">
                                                <title>Favourites</title>
                                            </use>
                                        </svg>
                                    </button>
                                </div>
                            </a>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};
export default Index;
