import React, { useEffect, useState } from 'react';
// import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import { RootState } from '../../../store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import AirPOSelection from 'src/pages/PoBookingsAir/POSelection';
import AirSailingScheludeSelection from 'src/pages/PoBookingsAir/SailingScheludeSelection';
import AirBookingProposalAttachmentSelection from 'src/pages/PoBookingsAir/AttachmentSelection';
import AirBookingProposalShippingDetail from 'src/pages/PoBookingsAir/ShippingDetailsSection';
import AirBookingProposalBookingDetail from 'src/pages/PoBookingsAir/EnrichmentAdditionalSelection';
import AirBookingProposalAddSchelude from 'src/pages/PoBookingsAir/BookingChangeVessel/AddSchelude';
import AirBookLineDetails from 'src/pages/PoBookingsAir/BookLineDetails';
import { resetBpStatus } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import Packing from '../Packing';
import Options from '../Options';
export interface NavData {
    key:
        | 'po_selection'
        | 'options'
        | 'flight_schedules'
        | 'packing'
        | 'booking_details'
        | 'shipment_details'
        | 'attachments';
    title: string;
    selected: number;
    path: string;
    disabled: boolean;
}
const Index: React.FC = () => {
    const headerData = useSelector((state: RootState) => state.bpHeaderData) as any;
    const { id, section } = useParams();
    const dispatch = useDispatch();
    const hasBookingId = id !== 'POSelection' && id !== 'POSelectionDetails';

    const [navData, setNavData] = useState<NavData[]>([
        {
            key: 'po_selection',
            title: 'PO Selection',
            selected: 0,
            path: hasBookingId ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new',
            disabled: false,
        },
        {
            key: 'options',
            title: 'Options',
            selected: 0,
            path: `/air_booking_list/${id}/options`,
            disabled: false,
        },
        {
            key: 'flight_schedules',
            title: 'Flight Schedules',
            selected: 0,
            path: `/air_booking_list/${id}/FlightSelectionDetails`,
            disabled: false,
        },
        {
            key: 'packing',
            title: 'Packing',
            selected: 0,
            path: `/air_booking_list/${id}/packing`,
            disabled: false,
        },
    ]);
    useEffect(() => {
        return () => {
            dispatch(resetBpStatus({}));
        };
    }, []);

    useEffect(() => {
        if (id) {
            const initialNavData: NavData[] = [
                {
                    key: 'po_selection',
                    title: 'PO Selection',
                    selected: 0,
                    path: hasBookingId ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new',
                    disabled: false,
                },
                {
                    key: 'options',
                    title: 'Options',
                    selected: 0,
                    path: `/air_booking_list/${id}/options`,
                    disabled: false,
                },
                {
                    key: 'flight_schedules',
                    title: 'Flight Schedules',
                    selected: 0,
                    path: `/air_booking_list/${id}/FlightSelectionDetails`,
                    disabled: false,
                },
                {
                    key: 'packing',
                    title: 'Packing',
                    selected: 0,
                    path: `/air_booking_list/${id}/packing`,
                    disabled: false,
                },
            ];
            setNavData(initialNavData);
        }
    }, [id]);

    useEffect(() => {
        if (headerData.bpStatus) {
            let newNavData = [...navData];

            if (
                headerData.bpStatus === 'Ready_for_Booking' ||
                headerData.bpStatus === 'Booked' ||
                headerData.bpStatus === 'Draft_Booking'
            ) {
                if (!newNavData.some((item) => item.key === 'booking_details')) {
                    newNavData.push({
                        key: 'booking_details',
                        title: 'Booking Details',
                        selected: 0,
                        path: `/air_booking_list/${id}/booking_details`,
                        disabled: false,
                    });
                }
                if (!newNavData.some((item) => item.key === 'shipment_details')) {
                    newNavData.push({
                        key: 'shipment_details',
                        title: 'Shipment Details',
                        selected: 0,
                        path: `/air_booking_list/${id}/shipment_details`,
                        disabled: false,
                    });
                }
                // if (!newNavData.some((item) => item.key === 'attachments')) {
                //     newNavData.push({
                //         key: 'attachments',
                //         title: 'Attachments',
                //         selected: 0,
                //         path: `/air_booking_list/${id}/attachments`,
                //         disabled: false,
                //     });
                // }
            } else {
                newNavData = newNavData.filter(
                    (navItem) =>
                        navItem.key !== 'booking_details' &&
                        navItem.key !== 'shipment_details' &&
                        navItem.key !== 'attachments'
                );
            }
            setNavData(newNavData);
        }
    }, [id, headerData.bpStatus]);

    return (
        <>
            {id === 'POSelection' && <AirPOSelection navData={navData} />}

            {id === 'POSelectionDetails' && <AirBookLineDetails />}
            {hasBookingId ? (
                <>
                    {section === 'POSelection' && <AirPOSelection navData={navData} />}

                    {section === 'FlightSelectionDetails' && <AirSailingScheludeSelection navData={navData} />}

                    {section === 'options' && <Options navData={navData} />}
                    {section === 'packing' && <Packing navData={navData} />}

                    {section === 'attachments' && <AirBookingProposalAttachmentSelection navData={navData} />}

                    {section === 'shipment_details' && <AirBookingProposalShippingDetail navData={navData} />}

                    {section === 'booking_details' && <AirBookingProposalBookingDetail navData={navData} />}

                    {section === 'flight_schedule' && <AirBookingProposalAddSchelude />}
                    {section === 'POSelectionDetails' && <AirBookLineDetails />}
                </>
            ) : null}
        </>
    );
};
export default Index;
