import { get, post } from '../../HttpMethods';
import { getOrganisationData } from '../../../utils';

export const addRequiredDetails = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        const newPayload = { ao: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/ao/basicdetails/requiredfields', newPayload, null);
        return { data: data, status: 200 };
    } catch (error) {
        return error;
    }
};

export const addAdditionalDetails = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        const newPayload = { ao: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/ao/basicdetails/additionalfields', newPayload, null);
        return { data: data, status: 200 };
    } catch (error) {
        return error;
    }
};

export const getBasicDetails = async (payload: any) => {
    try {
        const res = await get(`api/ord/ao/basicdetails?aoId=${payload}`, null);
        return res.info;
    } catch (err) {
        return err;
    }
};

export const getPreviewData = async (payload: any) => {
    try {
        const res = await get(`api/ord/ao/preview?aoId=${payload}`, null);
        return res.info;
    } catch (err) {
        return err;
    }
};

export const submitAllocation = async (payload: any) => {
    try {
        const res = await get(`api/ord/ao/submit?aoId=${payload}`, null);
        return res.info;
    } catch (err) {
        return err;
    }
};

export const approveAllocation = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        const res = await post(`api/ord/ao/releasetoblp?aoId=${payload}`, { roles, listOfUserOrganisations }, null);
        return res.info;
    } catch (err) {
        return err;
    }
};
