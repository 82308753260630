import React, { useEffect, useState, useLayoutEffect } from 'react';
import '../ClientDetails/vendor-client-detail.scss';
import '../vendor-tab.scss';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import HeaderTabs from '../HeaderTabs';
import { RootState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';
import { clearClients, fetchData } from '../../../../store/reducers/linkClientVendorReducer';
import { useDispatch } from 'react-redux';
import ShowForPermissions from '../../../../ShowForPermissions';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { fetchVendorData } from 'src/store/reducers/createVendorReducer';

const Index: React.FC = () => {
    const { id } = useParams();
    const {
        totalActiveClients,

        loading,
    } = useSelector((state: RootState) => state.linkClientVendor);
    const { vendorsHeader } = useSelector((state: RootState) => state.profilesVendors) as any;
    const { vendorName } = useSelector((state: RootState) => state.vendorDetails);
    const [modalView, setmodalView] = useState(false);
    const [modalViewUnlink, setmodalViewUnlink] = useState(false);
    const [currentPage, setcurrentPage] = useState(0);
    const [perPageCount, setperPageCount] = useState(10);
    const [columnStatus, setcolumnStatus] = useState('code');
    const [currentOrder, setcurrentOrder] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [_toastStat, settoastStat] = useState(false);
    const [_failedToast, setfailedToast] = useState(false);
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnOrder: currentOrder,
        columnStatus: columnStatus,
        id: id,
        keyword: search,
    };

    useEffect(() => {
        dispatch(clearClients([]));
        dispatch(fetchVendorData({ vendorPK: id }));
    }, []);

    useEffect(() => {
        dispatch(fetchData(payload));
        //dispatch(fetchAllData(payload));
    }, [columnStatus, currentOrder, currentPage, perPageCount]);

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder">
                <Breadcrumbs second={'vendor'} third={'Vendor'} />
                <div className="pageheading-holder">
                    <h2 className="page-heading">
                        {id ? (vendorsHeader?.vendorName ? vendorsHeader?.vendorName : vendorName) : 'Add New Vendor'}
                    </h2>
                </div>
                <HeaderTabs disableThird={!totalActiveClients || totalActiveClients === 0} />
            </div>
            <div className="grid-section user-profile-grid vendor-client-grid loader-container grid-p-0">
                {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-holder">
                    <div className="grid-header grid-ml-20">
                        <div className="add-btn-vendors">
                            <ShowForPermissions permission="create" type="vendor">
                                <a
                                    style={{ textDecoration: 'none' }}
                                    href={`/profile/vendor/${id}/users/add`}
                                    target="_blank"
                                >
                                    <div className="btn-holder">
                                        <button className="app-btn app-btn-secondary icon-button" title="Add Vendor">
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Vendor User</span>
                                        </button>
                                    </div>
                                </a>
                            </ShowForPermissions>
                        </div>
                    </div>
                    <LayoutWithColumns
                        style={'tab'}
                        filter={`{field: "VN_PK", operator: "eq", value: "${id}"}`}
                        content={'vendor-user-list'}
                    />
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder">
                    {vendorsHeader.vnUseTabComplete ? (
                        <NavLink
                            title="Previous"
                            to={`/profile/vendor/${id}/integration`}
                            className="app-btn app-btn-secondary footer-btn"
                        >
                            <span className="button-text footer-button-text">Previous</span>
                        </NavLink>
                    ) : (
                        <button title="Previous" disabled className="app-btn app-btn-secondary footer-btn">
                            <span className="button-text footer-button-text">Previous</span>
                        </button>
                    )}
                </div>
            </div>
            {/* <ClientModal></ClientModal> */}
            {/* <UnlinkModal></UnlinkModal> */}
        </div>
    );
};
export default Index;
