import { lazy } from 'react';
import BasicDetails from './pages/Profiles/Clients/BasicDetails';
import ContractDetails from './pages/Profiles/Clients/ContractDetails';
import IntegrationDetails from './pages/Profiles/Clients/IntegrationDetails';
import AutoApproval from './pages/Profiles/Clients/AutoApproval';
import ProductMaster from './pages/Profiles/Clients/ProductMaster';
import SOPDetails from './pages/Profiles/Clients/SOPDetails';
import VendorDetails from './pages/Profiles/Clients/VendorDetails';
import AttachmentClient from './pages/Profiles/Clients/Attachment';
import NoInternet from './components/common/NoNetwork';
import History from './pages/Users/History';
import BasicDetail from './pages/Profiles/Vendors/BasicDetail';
import ShippingLineBasicDetails from './pages/Profiles/ShippingLines/BasicDetail';
import ClientDetails from './pages/Profiles/Vendors/ClientDetails';
import VendorIntegrationDetails from './pages/Profiles/Vendors/VendorIntegrationDetails';
import VendorUsersDetails from './pages/Profiles/Vendors/Users';
import VendorUsersDetailsEdit from './pages/Profiles/Vendors/Users/userDetails';
import ShippingLineContractDetail from './pages/Profiles/ShippingLines/ContractDetail';
import RoleCheck from './pages/RolesPermissions/NewRole/newRole';
import RoleDetails from './pages/RolesPermissions/RoleDetails';
import AgentsBasicDetails from './pages/Profiles/Agents/BasicDetail';
import AgentsPortDetails from './pages/Profiles/Agents/PortDetails';
import AllocatedTEU from './pages/Report/AllocatedTEU';
import AgentsCommercialDetails from './pages/Profiles/Agents/CommercialDetails';
import AgentIntegrationDetail from './pages/Profiles/Agents/IntegrationDetails';
import PurchaseOrders from './pages/PO/PurchaseOrders';
import PoBasicDetail from './pages/PO/BasicDetail';
import PoDates from './pages/PO/Dates';
import PoProductDetails from './pages/PO/ProductDetails';
import PoReview from './pages/PO/PO Review';
import PurchaseOrderAddNew from './pages/PO/AddNew';
import Bookings from './pages/Booking';
import OnTheWaterReport from './pages/Report/OnTheWater';
import DcDeliveryReport from './pages/Report/DcDelivery';
import IntegrationLog from './pages/IntegrationLog';
import IntegrationLogDetails from './pages/IntegrationLogDetails';
import PoProductDetailsConfirmation from './pages/PO/ProductDetailsConfirmation';
import PoLifeCycle from './pages/PO/POLifecycle';
import PoNotes from './pages/PO/PoNotes';
import PoAttachment from './pages/PO/POAttachment';
import Warehouse from './pages/Warehouse';
import PoIntegrationLog from './pages/PO/PoIntegrationLog';
import PoConfirmationDetails from './pages/PO/ContainerModeSelection';
import POShipment from './pages/PO/ShipmentConfirmation';
import PoContainerModeDetails from './pages/PO/ContainerModeDetails';
import POSelection from './pages/BookingProposal/POSelection';
import LandPOSelection from './pages/LandBookingProposal/POSelection';
import BookLineDetails from './pages/BookingProposal/BookLineDetails';
import BookLineDetailsBcn from './pages/BcnBookingProposal/BookLineDetails';
import LclBookLineDetails from './pages/LCLBookingProposal/BookLineDetails';
import SailingScheludeSelection from './pages/BookingProposal/SailingScheludeSelection';
import LandTransportSchedule from './pages/LandBookingProposal/TransportSchedule';
import SailingScheludeSelectionBCN from './pages/BcnBookingProposal/SailingScheludeSelection';
import AdditionalInfoSelection from './pages/BookingProposal/AdditionalInfoSelection';
import LandGoodsReceived from './pages/LandBookingProposal/GoodsReceived';
import AdditionalInfoSelectionBCN from './pages/BcnBookingProposal/AdditionalInfoSelection';
import ManualVesselEntry from './pages/BookingProposal/BookingChangeVessel/ManualVesselEntry';
import ManualVesselEntryBCN from './pages/BcnBookingProposal/BookingChangeVessel/ManualVesselEntry';
import SelectedFromSchedule from './pages/BookingProposal/BookingChangeVessel/SelectedFromSchedule';
import AttachmentSelection from './pages/BookingProposal/AttachmentSelection';
import AttachmentSelectionBCN from './pages/BcnBookingProposal/AttachmentSelection';
import LinkedBookings from './pages/BcnBookingProposal/LinkedBookingGrid';
import AttachmentHistory from './pages/AttachmentHistory';
import VendorPortal from './pages/PO/VendorPortal';
import TestPage from './pages/TestPage';
import EnrichmentAdditionalSelection from './pages/BookingProposal/EnrichmentAdditionalSelection';
import ContainerAllocations from './pages/BookingProposal/ContainerAllocations';
import ContainerDetails from './pages/BookingProposal/ContainerAllocations/ContainerDetails';
import ContainerAllocationsBCN from './pages/BcnBookingProposal/ContainerAllocations';
import ContainerDetailsBCN from './pages/BcnBookingProposal/ContainerAllocations/ContainerDetails';
import ReceiptsDataGrid from './pages/BLP/ReceiptsDataGrid';
import AddNewReceipt from './pages/BLP/AddNewReceipt';
import Documents from './pages/BLP/ReceiptDetails/Documents';
import ReceiptHeader from './pages/BLP/ReceiptDetails/ReceiptHeader';
import POLines from './pages/BLP/ReceiptDetails/POLines';
import POLinesConfirmation from './pages/BLP/ReceiptDetails/POLinesConfirmation';
import InboundPo from './pages/BLPInboundPo/PurchaseOrders';
import InboundBasicDetail from './pages/BLPInboundPo/BasicDetail';
import InboundDates from './pages/BLPInboundPo/Dates';
import InboundProductDetails from './pages/BLPInboundPo/ProductDetails';
import InboundReview from './pages/BLPInboundPo/PO Review';
import InboundAddNew from './pages/BLPInboundPo/AddNew';
import InboundProductDetailsConfirmation from './pages/BLPInboundPo/ProductDetailsConfirmation';
import WarehouseInventory from './pages/Inventory/index';
import AllocationOrders from './pages/AOC/AllocationOrders';
import AllocationAddNew from './pages/AOC/AddNew';
import AllocationBasicDetail from './pages/AOC/BasicDetail';
import AoDates from './pages/AOC/Dates';
import AoReview from './pages/AOC/AO Review';
import AoProductAllocation from './pages/AOC/ProductAllocation';
import Settings from './pages/Settings';
import DashboardTodo from './pages/Dashboard/TodoGridExpandView';
import DashboardTodoActivity from './pages/Dashboard/TodoActivityTabs';
import POSelectionBcn from './pages/BcnBookingProposal/POSelection';
import ClassbReport from './pages/Report/ClassbReport';
import PortGroup from './pages/Qms/PortGroup';
import Quote from './pages/Qms/Quote';
import QuoteDetail from './pages/Qms/Quote/QuoteDetail';
import QuoteItem from './pages/Qms/QuoteItem';
import Rate from './pages/Qms/Rate';
import RateHtml from './pages/Qms/RateHtml';
import RateAttachment from './pages/Qms/RateHtml/RateAttachment';
import RateTerm from './pages/Qms/RateHtml/RateTerms';
import RateNotes from './pages/Qms/RateHtml/RateNote';
import RateEvents from './pages/Qms/RateHtml/RateEvent';
import QuoteAttachment from './pages/Qms/Quote/QuoteDetail/QuoteAttachment';
import QuoteTerm from './pages/Qms/Quote/QuoteDetail/QuoteTerms';
import QuoteNotes from './pages/Qms/Quote/QuoteDetail/QuoteNote';
import QuoteEvents from './pages/Qms/Quote/QuoteDetail/QuoteEvent';
import QuoteSection from './pages/Qms/QuoteSection';
import QmsConfigPort from './pages/Qms/Port';
import QmsConfigHeader from './pages/Qms/ConifgHeader';
import QmsConfigDetail from './pages/Qms/ConfigDetail';
const Dashboard = lazy(() => import('./pages/Dashboard'));
const Users = lazy(() => import('./pages/Users'));
const Profile = lazy(() => import('./pages/Profiles/Profiles'));
const Vendor = lazy(() => import('./pages/Profiles/Vendors'));
const Agent = lazy(() => import('./pages/Profiles/Agents'));
const Events = lazy(() => import('./pages/Events'));
const Shipping = lazy(() => import('./pages/Profiles/ShippingLines'));
const VendorUsers = lazy(() => import('./pages/Users/Vendor'));
const AgentUsers = lazy(() => import('./pages/Users/Agent'));
const ShippingUsers = lazy(() => import('./pages/Users/Shipping'));
const Forwarder = lazy(() => import('./pages/Users/Forwarder'));
const ClientUsers = lazy(() => import('./pages/Users/Client'));
const ForwarderBasicDetails = lazy(() => import('./pages/Users/Forwarder/BasicDetail'));
const ClientBasicDetails = lazy(() => import('./pages/Users/Client/BasicDetail'));
// const VendorBasicDetails = lazy(() => import('./pages/Users/Vendor/VendorBasicDetail'));
const ShippingBasicDetails = lazy(() => import('./pages/Users/Shipping/BasicDetail'));
const AgentBasicDetails = lazy(() => import('./pages/Users/Agent/BasicDetail'));
const RolesFORWARDER = lazy(() => import('./pages/RolesPermissions/Forwarder'));
const RolesVendor = lazy(() => import('./pages/RolesPermissions/Vendor'));
const RolesShipping = lazy(() => import('./pages/RolesPermissions/Shipping'));
const RolesAgent = lazy(() => import('./pages/RolesPermissions/Agent'));
const RolesClients = lazy(() => import('./pages/RolesPermissions/Client'));
const RoleEdit = lazy(() => import('./pages/RolesPermissions/EditRole'));
const CartageView = lazy(() => import('./pages/CartageView'));
const Shipments = lazy(() => import('./pages/Shipment'));
const ShipmentWithOrders = lazy(() => import('./pages/ShipmentWithOrders'));
const ShipmentWithProducts = lazy(() => import('./pages/ShipmentWithProducts'));
const ShipmentWithContainer = lazy(() => import('./pages/ShipmentWithContainers'));
const ContainerStatus = lazy(() => import('./pages/ContainerStatus'));
const ConsolDocumentsList = lazy(() => import('./pages/ConsolDocumentsList'));
const ConsoleDocumentsDates = lazy(() => import('./pages/ConsolDocumentsDates'));
const ShipmentDocumentsList = lazy(() => import('./pages/ShipmentDocumentList'));
const ShipmentDocumentDates = lazy(() => import('./pages/ShipmentDocumentDates'));
const InternationalBrokerageShipments = lazy(() => import('./pages/InternationalBrokerageShipments'));
const CommercialInvoice = lazy(() => import('./pages/CommercialInvoice'));
const LandedCost = lazy(() => import('./pages/LandedCost'));
const WarehousingShipment = lazy(() => import('./pages/WarehousingShipment'));
const WarehousingReceipt = lazy(() => import('./pages/WarehousingReceipt'));
const NotFound = lazy(() => import('./components/common/NotFound'));

//AO Booking proposal

import AoPOSelection from './pages/AoBookingProposal/AOSelection';
import AoSailingScheludeSelection from './pages/AoBookingProposal/SailingScheludeSelection';
import AoAdditionalInfoSelection from './pages/AoBookingProposal/AdditionalInfoSelection';
import AoManualVesselEntry from './pages/AoBookingProposal/BookingChangeVessel/ManualVesselEntry';
import AoSelectedFromSchedule from './pages/AoBookingProposal/BookingChangeVessel/SelectedFromSchedule';
import AoAttachmentSelection from './pages/AoBookingProposal/AttachmentSelection';
import AoEnrichmentAdditionalSelection from './pages/AoBookingProposal/EnrichmentAdditionalSelection';
import AoContainerAllocations from './pages/AoBookingProposal/ContainerAllocations';
import AoContainerDetails from './pages/AoBookingProposal/ContainerAllocations/ContainerDetails';

//LCL Booking proposal

import LCLPOSelection from './pages/LCLBookingProposal/POSelection';
import LCLSailingScheludeSelection from './pages/LCLBookingProposal/SailingScheludeSelection';
import LCLAdditionalInfoSelection from './pages/LCLBookingProposal/AdditionalInfoSelection';
import LCLManualVesselEntry from './pages/LCLBookingProposal/BookingChangeVessel/ManualVesselEntry';
import LCLAttachmentSelection from './pages/LCLBookingProposal/AttachmentSelection';
import LCLEnrichmentAdditionalSelection from './pages/LCLBookingProposal/EnrichmentAdditionalSelection';
import LCLContainerAllocations from './pages/LCLBookingProposal/ContainerAllocations';
import LCLContainerDetails from './pages/LCLBookingProposal/ContainerAllocations/ContainerDetails';
import LCLContainerDetailsForwarder from './pages/LCLBookingProposal/ContainerAllocations/ContainerDetailsFORWARDER';
import LCLExistingSchelude from './pages/LCLBookingProposal/BookingChangeVessel/ExistingSchedule';

//AO Booking proposal
import CoLoader from './pages/Profiles/CoLoader/index';
import CoLoaderBasicDetail from './pages/Profiles/CoLoader/BasicDetail';
import CoLoaderContractDetail from './pages/Profiles/CoLoader/ContractDetail';

// LAND Booking Proposal
import LandAttachmentSelection from './pages/LandBookingProposal/AttachmentSelection';

//PO Booking proposal -Air

// import AirPOSelection from './pages/PoBookingsAir/POSelection';
// import AirSailingScheludeSelection from './pages/PoBookingsAir/SailingScheludeSelection';
// import AirBookingProposalAttachmentSelection from './pages/PoBookingsAir/AttachmentSelection';
// import AirBookingProposalShippingDetail from './pages/PoBookingsAir/ShippingDetailsSection';
// import AirBookingProposalBookingDetail from './pages/PoBookingsAir/EnrichmentAdditionalSelection';
// import AirBookingProposalAddSchelude from './pages/PoBookingsAir/BookingChangeVessel/AddSchelude';
// import AirSailingSchelude from './pages/PoBookingsAir/SailingScheludeSelection';
// import AirBookLineDetails from './pages/PoBookingsAir/BookLineDetails';
import AirBookingWrapper from 'src/pages/PoBookingsAir/Wrapper';
import Logout from './pages/Logout';
// import AirBookingList from './pages/Booking/PoBookingAir';

import DashboardMap from './pages/Dashboard/Map';
// import BookPOLineDetails from './pages/BookingProposal/BookPOLineDetails';
import WorkTrendsDetailed from './pages/Dashboard/WorkTrendsDetailed';

import ReassignAgentPo from './pages/ReassignAgent/PurchaseOrder';
import ReassignAgentBooking from './pages/ReassignAgent/BookingProposal';
import AgentReassignmentApproval from './pages/ReassignAgent/Approval';
import InsightExpanded from './pages/Dashboard/InsightsExpanded';
import InsightDetailsList from './pages/Tracking/InsightDetailsList';
import LifecycleBooking from './pages/Tracking/LifecycleSearch/LifecycleBooking';
import Tracking from './pages/Tracking';
import LayoutWithColumns from './components/KendoGridJquery/layoutWithColumns';
import ManualScheduleCreation from './pages/BookingProposal/ManualScheduleCreation';
import ShipmentDetails from './pages/ShipmentDetails';
import LifecycleHeader from './pages/Configuration/LifecycleHeader';
import LifecycleDetail from './pages/Configuration/LifecycleDetail';
import BpNotes from './pages/PO/PoNotes/bp';
import LandBpNotes from './pages/LandBookingProposal/PoNotes/bp';
import BpEvents from './pages/PO/Events/bp';
import PoEvents from './pages/PO/Events';
import ProductMasterEdit from './pages/Profiles/Clients/ProductMaster/ProductMasterEdit';
import ShipmentInvoiceUploader from './pages/Shipment/ShipmentInvoice';
import QuoteItemDetail from './pages/Qms/QuoteItem/Details';
import PortGroupDetail from './pages/Qms/PortGroup/Details';

const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/notfound',
        name: 'Notfound',
        component: NotFound,
        permission: 'none',
        type: 'none',
    },
    {
        path: '/logout',
        name: 'Logout',
        component: Logout,
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
    },
    {
        path: '/users',
        name: 'Users',
        component: Users,
        permission: 'view',
        type: 'users',
    },
    {
        path: '/users/history',
        name: 'History',
        component: History,
        permission: 'view',
        type: 'users',
    },
    {
        path: '/vendor',
        name: 'Vendor',
        component: Vendor,
        //permission: 'view',
        //type: 'vendor',
    },
    {
        path: '/profile/vendor/basic_detail',
        name: 'VendorBasicDetail',
        component: BasicDetail,
        permission: 'create',
        type: 'vendor',
    },
    {
        path: '/users/vendor',
        name: 'VendorUsers',
        component: VendorUsers,
        permission: 'view',
        type: 'users',
    },
    {
        //ForwarderBasicDetails
        path: '/profile/forwarder/:id/basic_detail',
        name: 'ForwarderBasicDetail',
        component: ForwarderBasicDetails,
        permission: 'create',
        type: 'users',
    },
    {
        //ClientBasicDetails
        path: '/profile/client/:id/basic_detail',
        name: 'ClientBasicDetail',
        component: ClientBasicDetails,
        permission: 'create',
        type: 'users',
    },
    // {
    //     //ClientBasicDetails
    //     path: '/profile/vendor/:id/basic_details',
    //     name: 'ClientBasicDetail',
    //     component: VendorBasicDetails,
    //     permission: 'create',
    //     type: 'users',
    // },
    {
        //ClientBasicDetails
        path: '/profile/shipping/:id/basic_detail',
        name: 'ShippingBasicDetail',
        component: ShippingBasicDetails,
        permission: 'create',
        type: 'users',
    },
    {
        path: '/profile/agent/:id/basic_detail',
        name: 'AgentBasicDetail',
        component: AgentBasicDetails,
        permission: 'create',
        type: 'users',
    },

    {
        path: '/profile/shippingline/:id/basic_details',
        name: 'ShippingLineBasicDetails',
        component: ShippingLineBasicDetails,
        permission: 'view',
        type: 'shipping',
    },
    {
        path: '/profile/shippingline/:id/contract_details',
        name: 'ShippingLineContractDetail',
        component: ShippingLineContractDetail,
        permission: 'view',
        type: 'shipping',
    },
    {
        path: '/agent',
        name: 'Agent',
        component: Agent,
        permission: 'view',
        type: 'agent',
    },
    {
        path: '/profile/agent/:id/basic_details',
        name: 'AgentsBasicDetails',
        component: AgentsBasicDetails,
        permission: 'view',
        type: 'agent',
    },
    {
        path: '/profile/agent/:id/port_details',
        name: 'AgentsPortDetails',
        component: AgentsPortDetails,
        permission: 'view',
        type: 'agent',
    },
    {
        //ShipmentInvoiceUploader
        // TODO: Permission is missing
        path: '/organisation/documents',
        name: 'ShipmentInvoiceUploader',
        component: ShipmentInvoiceUploader,
    },
    {
        path: '/profile/agent/:id/commercial_details',
        name: 'AgentsCommercialDetails',
        component: AgentsCommercialDetails,
        permission: 'view',
        type: 'agent',
    },
    {
        path: '/profile/agent/:id/integration_details',
        name: 'IntegrationDetails',
        component: AgentIntegrationDetail,
        permission: 'view',
        type: 'agent',
    },
    {
        path: '/shippingline',
        name: 'ShippingLine',
        component: Shipping,
        permission: 'view',
        type: 'shipping',
    },
    {
        path: '/users/client',
        name: 'ClientsUsers',
        component: ClientUsers,
        permission: 'view',
        type: 'users',
    },
    {
        path: '/users/agent',
        name: 'AgentUsers',
        component: AgentUsers,
        permission: 'view',
        type: 'users',
    },
    {
        path: '/custom/3justgro/classb',
        name: 'ClassbReport',
        component: ClassbReport,
    },
    {
        path: '/users/shipping',
        name: 'ShippingLineUsers',
        component: ShippingUsers,
        permission: 'view',
        type: 'users',
    },
    {
        path: '/users/forwarder',
        name: 'Forwarder',
        component: Forwarder,
        permission: 'view',
        type: 'users',
    },
    {
        path: '/profile/clients/:id/basic_details',
        name: 'Basic Details',
        component: BasicDetails,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/contract_details',
        name: 'Contract Details',
        component: ContractDetails,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/integration_details',
        name: 'Integration Details',
        component: IntegrationDetails,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/auto_approval',
        name: 'Auto Approval',
        component: AutoApproval,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/product_master',
        name: 'Product Master',
        component: ProductMaster,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/product_master/:pmPk',
        name: 'Product Master Edit',
        component: ProductMasterEdit,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/sop_details',
        name: 'SOP Details',
        component: SOPDetails,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/vendor_details',
        name: 'Vendor Details',
        component: VendorDetails,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/profile/clients/:id/attachment',
        name: 'Attachment Details',
        component: AttachmentClient,
        permission: 'view',
        type: 'client',
    },
    {
        path: '/no-internet',
        name: 'No internet',
        component: NoInternet,
    },
    {
        path: '/allocated_teu',
        name: 'Allocated Teu',
        component: AllocatedTEU,
    },
    {
        path: '/tst/allocatedteu',
        name: 'test page',
        component: TestPage,
    },
    {
        path: '/configuration/lifecycle',
        name: 'Lifecycle Header',
        component: LifecycleHeader,
        permission: 'read',
        type: 'header',
    },
    {
        path: '/configuration/lifecycle/:id',
        name: 'Lifecycle Detail',
        component: LifecycleDetail,
        permission: 'read',
        type: 'detail',
    },
    {
        path: '/rolespermissions/forwarder',
        name: 'Role FORWARDER',
        component: RolesFORWARDER,
        permission: 'view',
        type: 'roles',
    },
    {
        path: '/rolespermissions/client',
        name: 'Role Client',
        component: RolesClients,
        permission: 'view',
        type: 'roles',
    },
    {
        path: '/rolespermissions/vendor',
        name: 'Role Ventor',
        component: RolesVendor,
        permission: 'view',
        type: 'roles',
    },
    {
        path: '/rolespermissions/agent',
        name: 'Role Agent',
        component: RolesAgent,
        permission: 'view',
        type: 'roles',
    },
    {
        path: '/rolespermissions/shipping',
        name: 'Role ShippingLine',
        component: RolesShipping,
        permission: 'view',
        type: 'roles',
    },
    {
        path: '/rolespermissions/newrole',
        name: 'New Role2',
        component: RoleCheck,
        permission: 'edit',
        type: 'roles',
    },
    {
        path: '/rolespermissions/roledetails',
        name: 'Role Details',
        component: RoleDetails,
        permission: 'view',
        type: 'roles',
    },
    {
        path: '/profile/vendor/:id/basic_detail',
        name: 'Vendor Basic Detail',
        component: BasicDetail,
        permission: 'view',
        type: 'vendor',
    },
    {
        path: '/profile/vendor/:id/client_details',
        name: 'Client Listing',
        component: ClientDetails,
        permission: 'view',
        type: 'vendor',
    },
    {
        path: '/profile/vendor/:id/integration',
        name: 'Integration',
        component: VendorIntegrationDetails,
        permission: 'view',
        type: 'vendor',
    },
    {
        path: '/profile/vendor/:id/users',
        name: 'Vendor Users',
        component: VendorUsersDetails,
        permission: 'view',
        type: 'vendor',
    },
    {
        path: '/profile/vendor/:id/users/:vn_user_pk',
        name: 'Vendor Users Details Edit',
        component: VendorUsersDetailsEdit,
        permission: 'view',
        type: 'vendor',
    },
    {
        path: '/rolespermissions/roleEdit',
        name: 'Role Edit',
        component: RoleEdit,
        permission: 'view',
        type: 'roles',
    },
    {
        path: '/purchase_order',
        name: 'Purchase Order',
        component: PurchaseOrders,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/warehousing_order',
        name: 'Purchase Order',
        component: PurchaseOrders,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/basic_details',
        name: 'Purchase Order Add New',
        component: PurchaseOrderAddNew,
        permission: 'create',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/basic_details',
        name: 'Basic Details',
        component: PoBasicDetail,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/dates',
        name: 'Dates',
        component: PoDates,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/product_details',
        name: 'Product Details',
        component: PoProductDetails,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/vendorPacking',
        name: 'Product Details',
        component: VendorPortal,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/po_review',
        name: 'Review Detail',
        component: PoReview,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/product_details_confirmation',
        name: 'Product Details Confirmation',
        component: PoProductDetailsConfirmation,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/lifecycle',
        name: 'PO Lifecycle',
        component: PoLifeCycle,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/notes',
        name: 'PO Notes',
        component: PoNotes,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/attachments',
        name: 'PO Attachments',
        component: PoAttachment,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/events',
        name: 'PO Events',
        component: PoEvents,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/booking_list/:id/notes',
        name: 'BP Notes',
        component: BpNotes,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/rate/:id/attachments',
        name: 'Rate Attachments',
        component: RateAttachment,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/rate/:id/terms',
        name: 'Rate Terms',
        component: RateTerm,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/rate/:id/events',
        name: 'Rate Events',
        component: RateEvents,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/rate/:id/notes',
        name: 'Rate Notes',
        component: RateNotes,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/quote/:id/attachments',
        name: 'Quote Attachments',
        component: QuoteAttachment,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/quote/:id/terms',
        name: 'Quote Terms',
        component: QuoteTerm,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/quote/:id/events',
        name: 'Quote Events',
        component: QuoteEvents,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/quote/:id/notes',
        name: 'Quote Notes',
        component: QuoteNotes,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/bcnBookingList/:id/notes',
        name: 'BP Notes',
        component: BpNotes,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/lclbooking_list/:id/notes',
        name: 'BP Notes',
        component: BpNotes,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/Aobooking_list/:id/notes',
        name: 'BP Notes',
        component: BpNotes,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/air_booking_list/:id/notes',
        name: 'BP Notes',
        component: BpNotes,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/land_booking_list/:id/notes',
        name: 'BP Notes',
        component: LandBpNotes,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/booking_list/:id/events',
        name: 'BP Events',
        component: BpEvents,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/Aobooking_list/:id/events',
        name: 'BP Events',
        component: BpEvents,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/events',
        name: 'BP Events',
        component: BpEvents,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/air_booking_list/:id/events',
        name: 'BP Events',
        component: BpEvents,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/lclbooking_list/:id/events',
        name: 'BP Events',
        component: BpEvents,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/purchase_order/:id/integration_log',
        name: 'PO Integration Log',
        component: PoIntegrationLog,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/container_mode_selection',
        name: 'Confirmation Groups',
        component: PoConfirmationDetails,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/shipment_information',
        name: 'Container Mode Selection',
        component: POShipment,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/purchase_order/:id/container_mode_selection/:group_id',
        name: 'Container Mode Details',
        component: PoContainerModeDetails,
        permission: 'view',
        type: 'po',
    },
    {
        path: '/booking_list',
        name: 'Booking List',
        component: Bookings,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/qms/rate/:id',
        name: 'rate detail',
        component: RateHtml,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/config/portgroup',
        name: 'Booking List',
        component: PortGroup,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/config/portgroup/:Id',
        name: 'Port Group Detail',
        component: PortGroupDetail,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/config/quoteitem',
        name: 'Booking List',
        component: QuoteItem,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/quote/:id',
        name: 'Booking List',
        component: QuoteDetail,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/config/quotesection',
        name: 'Quote Section',
        component: QuoteSection,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/config/quoteitem/:Id',
        name: 'Quote Item Detail',
        component: QuoteItemDetail,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/rate',
        name: 'Booking List',
        component: Rate,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/qms/quote',
        name: 'Booking List',
        component: Quote,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/report/custom/3justgro/otw',
        name: 'On The Water',
        component: OnTheWaterReport,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/report/custom/3justgro/dcd',
        name: 'DC Delivery',
        component: DcDeliveryReport,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/integration_log',
        name: 'Integration Log',
        component: IntegrationLog,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/integrationlog/:id',
        name: 'Integration Log Details',
        component: IntegrationLogDetails,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/PO/purchase_order/product_details_confirmation',
        name: 'Product Details Confirmation',
        component: PoProductDetailsConfirmation,
    },
    {
        path: '/booking_list/POSelection',
        name: 'PO Selection',
        component: POSelection,
        permission: 'edit',
        type: 'bp',
    },
    {
        path: '/land_booking_list/POSelection',
        name: 'PO Selection',
        component: LandPOSelection,
        permission: 'edit',
        type: 'bp',
    },
    {
        path: '/land_booking_list/:id/POSelection',
        name: 'PO Selection',
        component: LandPOSelection,
        permission: 'edit',
        type: 'bp',
    },
    {
        path: '/booking_list/:id/POSelection',
        name: 'PO Selection',
        component: POSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/POSelection',
        name: 'PO Selection',
        component: POSelectionBcn,
        permission: 'edit',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/POSelection',
        name: 'PO Selection',
        component: POSelectionBcn,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/POSelectionDetails',
        name: 'PO Selection Details',
        component: BookLineDetailsBcn,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/POSelectionDetails',
        name: 'PO Selection Details',
        component: BookLineDetailsBcn,
        permission: 'edit',
        type: 'bp',
    },
    {
        path: '/BookingProposal/:id/POSelectionDetails',
        name: 'PO Selection Details',
        component: BookLineDetails,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/booking_list/:id/SailingSelectionDetails',
        name: 'Sailing Selection Details',
        component: SailingScheludeSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/booking_list/:id/additional-details',
        name: 'Additional Details',
        component: AdditionalInfoSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/land_booking_list/:id/transport-schedule',
        name: 'Transport Schedule Details',
        component: LandTransportSchedule,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/land_booking_list/:id/goods-received',
        name: 'Goods Received',
        component: LandGoodsReceived,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/BookingProposal/POSelectionDetails',
        name: 'PO Selection Details',
        component: BookLineDetails,
        permission: 'edit',
        type: 'bp',
    },
    {
        path: '/booking_list/:id/bookingproposal/SailingSchedule/change-vessel/manual',
        name: 'Manual Vessel Entry',
        component: ManualVesselEntry,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/BookingProposal/SailingSchedule/change-vessel/selectedSchedule',
        name: 'Selected Vessel Entry',
        component: SelectedFromSchedule,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/BookingProposal/SailingSelectionDetails',
        name: 'Sailing Selection Details',
        component: SailingScheludeSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/BookingProposal/AdditionalDetails',
        name: 'Additional Details',
        component: AdditionalInfoSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/genericReport/containerFclDeliveryAndEmpty',
        name: 'Container Cartage View',
        component: CartageView,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/international/shipment',
        name: 'Shipments',
        component: Shipments,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT', 'AGENT', 'VENDOR'],
    },
    {
        path: '/international/shipmentByPO',
        name: 'Shipment View (With Orders)',
        component: ShipmentWithOrders,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT', 'AGENT', 'VENDOR'],
    },
    {
        path: '/international/container',
        name: 'Shipment View (With Containers)',
        component: ShipmentWithContainer,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT', 'AGENT', 'VENDOR'],
    },
    {
        path: '/international/containerBreakup',
        name: 'Shipment View (With Products)',
        component: ShipmentWithProducts,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT', 'AGENT', 'VENDOR'],
    },
    {
        path: '/international/containerStatus',
        name: 'Container Status',
        component: ContainerStatus,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/international/doctracking/consol',
        name: 'Consol Documents List',
        component: ConsolDocumentsList,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: '/international/eventlog',
        name: 'Events',
        component: Events,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/international/doctracking/consolDates',
        name: 'Consol Document Dates',
        component: ConsoleDocumentsDates,
        roleCheck: true,
        roles: ['FORWARDER'],
    },

    {
        path: '/international/doctracking/Shipment',
        name: 'Shipment Document List',
        component: ShipmentDocumentsList,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/international/doctracking/ShipmentDates',
        name: 'Shipment Document Dates',
        component: ShipmentDocumentDates,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/international/brokerageShipments',
        name: 'International Brokerage Shipments',
        component: InternationalBrokerageShipments,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/international/commercialInvoice',
        name: 'Commercial Invoice',
        component: CommercialInvoice,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/international/customs/landedCost',
        name: 'Landed Cost',
        component: LandedCost,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: 'international/warehouse/outbound/shipment',
        name: 'Warehousing Shipment',
        component: WarehousingShipment,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: 'qms/config/port',
        name: 'Config Port',
        component: QmsConfigPort,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: 'qms/config/detail/:type',
        name: 'Config Details',
        component: QmsConfigDetail,
        roleCheck: true,
        roles: ['FORWARDER'],
    },
    {
        path: 'international/warehouse/inbound/receipt',
        name: 'Warehousing Receipt',
        component: WarehousingReceipt,
        roleCheck: true,
        roles: ['FORWARDER', 'CLIENT'],
    },
    {
        path: '/booking_list/:id/attachments',
        name: 'Additional Details',
        component: AttachmentSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/:bpType/:id/attachments-history',
        name: 'Attachment History',
        component: AttachmentHistory,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/BookingProposal/enrichment/AdditionalDetails',
        name: 'Enrichment Additional Details',
        component: EnrichmentAdditionalSelection,
    },
    {
        path: '/booking_list/:id/container-allocation',
        name: 'Container Allocation',
        component: ContainerAllocations,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/booking_list/:id/container-allocation/:allocationId/details',
        name: 'Container Allocation Details',
        component: ContainerDetails,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/SailingSelectionDetails',
        name: 'Sailing Selection Details',
        component: SailingScheludeSelectionBCN,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/additional-details',
        name: 'Additional Details',
        component: AdditionalInfoSelectionBCN,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/attachments',
        name: 'Additional Details',
        component: AttachmentSelectionBCN,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/linked-bookings',
        name: 'Linked Bookings',
        component: LinkedBookings,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/container-allocation',
        name: 'Container Allocation',
        component: ContainerAllocationsBCN,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/container-allocation/:allocationId/details',
        name: 'Container Allocation Details',
        component: ContainerDetailsBCN,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/bcnBookingList/:id/bookingproposal/SailingSchedule/change-vessel/manual',
        name: 'Manual Vessel Entry',
        component: ManualVesselEntryBCN,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/blp/receipt',
        name: 'Receipts Data',
        component: ReceiptsDataGrid,
        permission: 'view',
        type: 'receipt',
    },
    {
        path: '/blp/receipt/new-receipt',
        name: 'Add new Receipt',
        component: AddNewReceipt,
        permission: 'create',
        type: 'receipt',
    },
    {
        path: '/blp/receipt/:id/documents',
        name: 'Documents',
        component: Documents,
        permission: 'view',
        type: 'receipt',
    },
    {
        path: '/blp/receipt/:id/receipt-header',
        name: 'Receipt Header',
        component: ReceiptHeader,
        permission: 'view',
        type: 'receipt',
    },
    {
        path: '/blp/receipt/:id/polines',
        name: 'PO Lines',
        component: POLines,
        permission: 'view',
        type: 'receipt',
    },

    {
        path: '/blp/ReceiptDetails/POLines/confirmation',
        name: 'PO Lines',
        component: POLinesConfirmation,
    },
    {
        path: '/inbound',
        name: 'Inbound Purchase Order',
        component: InboundPo,
    },
    {
        path: '/inbound/basic_details',
        name: 'Inbound Purchase Order Add New',
        component: InboundAddNew,
        permission: 'create',
        type: 'inbound',
    },
    {
        path: '/inbound/:id/basic_details',
        name: 'Inbound Basic Details',
        component: InboundBasicDetail,
        permission: 'view',
        type: 'inbound',
    },
    {
        path: '/inbound/:id/dates',
        name: 'Inbound Dates',
        component: InboundDates,
        permission: 'view',
        type: 'inbound',
    },
    {
        path: '/inbound/:id/product_details',
        name: 'Inbound Product Details',
        component: InboundProductDetails,
        permission: 'view',
        type: 'inbound',
    },
    {
        path: '/inbound/:id/po_review',
        name: 'Inbound Review Detail',
        component: InboundReview,
        permission: 'view',
        type: 'inbound',
    },
    {
        path: '/inbound/:id/product_details_confirmation',
        name: 'Inbound Product Details Confirmation',
        component: InboundProductDetailsConfirmation,
        permission: 'view',
        type: 'inbound',
    },
    {
        path: '/inventory',
        name: 'Inventory View',
        component: WarehouseInventory,
    },
    {
        path: '/allocation_orders',
        name: 'Allocation Orders List',
        component: AllocationOrders,
        permission: 'view',
        type: 'ao',
    },
    {
        path: '/allocation_orders/add_new',
        name: 'Allocation Order Add New',
        component: AllocationAddNew,
        permission: 'create',
        type: 'ao',
    },
    {
        path: '/allocation_orders/:id/basic_details',
        name: 'Allocation Order details',
        component: AllocationBasicDetail,
        permission: 'view',
        type: 'ao',
    },
    {
        path: '/allocation_orders/:id/dates',
        name: 'Dates',
        component: AoDates,
        permission: 'view',
        type: 'ao',
    },
    {
        path: '/allocation_orders/:id/product-allocation',
        name: 'Product Allocation',
        component: AoProductAllocation,
        permission: 'view',
        type: 'ao',
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
    },
    {
        path: '/allocation_orders/:id/ao-preview',
        name: 'AO Preview',
        component: AoReview,
        permission: 'view',
        type: 'ao',
    },
    {
        path: '/Aobooking_list/AoSelection',
        name: 'AO Selection',
        component: AoPOSelection,
    },
    {
        path: '/Aobooking_list/:id/AoSelection',
        name: 'AO Selection',
        component: AoPOSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/Aobooking_list/:id/SailingSelectionDetails',
        name: 'Sailing Selection Details',
        component: AoSailingScheludeSelection,
        permission: 'view',
        type: 'bp',
    },

    {
        path: '/AoBookingProposal/SailingSelectionDetails',
        name: 'Sailing Selection Details',
        component: AoSailingScheludeSelection,
    },

    {
        path: '/AoBookingProposal/AdditionalDetails',
        name: 'Additional Details',
        component: AoAdditionalInfoSelection,
    },
    {
        path: '/Aobooking_list/:id/additional-details',
        name: 'Additional Details',
        component: AoAdditionalInfoSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/Aobooking_list/:id/bookingproposal/SailingSchedule/change-vessel/manual',
        name: 'Manual Vessel Entry',
        component: AoManualVesselEntry,
        permission: 'view',
        type: 'bp',
    },

    {
        path: '/AoBookingProposal/SailingSchedule/change-vessel/selectedSchedule',
        name: 'Selected Vessel Entry',
        component: AoSelectedFromSchedule,
    },
    {
        path: '/Aobooking_list/:id/attachments',
        name: 'Additional Details',
        component: AoAttachmentSelection,
        permission: 'view',
        type: 'bp',
    },

    {
        path: '/AoBookingProposal/enrichment/AdditionalDetails',
        name: 'Enrichment Additional Details',
        component: AoEnrichmentAdditionalSelection,
    },
    {
        path: '/Aobooking_list/:id/container-allocation',
        name: 'Container Allocation',
        component: AoContainerAllocations,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/Aobooking_list/:id/container-allocation/:allocationId/details',
        name: 'Container Allocation Details',
        component: AoContainerDetails,
        permission: 'view',
        type: 'bp',
    },
    // Lcl Booking list urls
    {
        path: '/lclbooking_list/POSelection',
        name: 'LCL Selection',
        component: LCLPOSelection,
    },
    {
        path: '/lclbooking_list/:id/POSelection',
        name: 'AO Selection',
        component: LCLPOSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/lclbooking_list/:id/SailingSelectionDetails',
        name: 'Sailing Selection Details',
        component: LCLSailingScheludeSelection,
        permission: 'view',
        type: 'bp',
    },
    { path: '/bookingProposalLcl/POSelectionDetails', name: 'PO Selection Details', component: LclBookLineDetails },
    {
        path: '/bookingProposalLcl/:id/POSelectionDetails',
        name: 'PO Selection Details',
        component: LclBookLineDetails,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/lclbookingproposal/:id/SailingSelectionDetails',
        name: 'Sailing Selection Details',
        component: LCLSailingScheludeSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/LCLBookingProposal/AdditionalDetails',
        name: 'Additional Details',
        component: LCLAdditionalInfoSelection,
    },
    {
        path: '/lclbooking_list/:id/additional-details',
        name: 'Additional Details',
        component: LCLAdditionalInfoSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/lclbooking_list/:id/bookingproposal/SailingSchedule/change-vessel/new_Schedule',
        name: 'Manual Vessel Entry',
        component: LCLManualVesselEntry,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/booking_list/:id/bookingproposal/SailingSchedule/manual-creation/new_Schedule',
        name: 'Manual Vessel Creation',
        component: ManualScheduleCreation,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/lclbookingproposal/:id/SailingSchedule/change-vessel/existing_schelude',
        name: 'LCLExistingSchelude',
        component: LCLExistingSchelude,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/LCLbooking_list/:id/attachments',
        name: 'Additional Details',
        component: LCLAttachmentSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/land_booking_list/:id/attachments',
        name: 'Attachments',
        component: LandAttachmentSelection,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/LCLBookingProposal/enrichment/AdditionalDetails',
        name: 'Enrichment Additional Details',
        component: LCLEnrichmentAdditionalSelection,
    },
    {
        path: '/LCLbooking_list/:id/container-allocation',
        name: 'Container Allocation',
        component: LCLContainerAllocations,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/LCLbooking_list/:id/container-allocation/:allocationId/details',
        name: 'Container Allocation Details',
        component: LCLContainerDetails,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/LCLbooking_list/:id/container-allocation/:allocationId/detailsForwarder',
        name: 'Container Allocation Details',
        component: LCLContainerDetailsForwarder,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/allocation_orders/ao_review',
        name: 'AO Review',
        component: AoReview,
    },
    {
        path: '/allocation_orders/Product_allocation',
        name: 'Product Allocation',
        component: AoProductAllocation,
    },
    {
        path: '/coloader',
        name: 'Co Loader',
        component: CoLoader,
        permission: 'view',
        type: 'coloader',
    },
    {
        path: '/coloader/:id/basic_detail',
        name: 'Co Loader',
        component: CoLoaderBasicDetail,
        permission: 'view',
        type: 'coloader',
    },
    {
        path: '/coloader/:id/contract_detail',
        name: 'Co Loader',
        component: CoLoaderContractDetail,
        permission: 'view',
        type: 'coloader',
    },
    //ao end

    // bp air
    {
        path: '/air_booking_list/:id/:section',
        name: 'air booking proposal',
        component: AirBookingWrapper,
        permission: 'view',
        type: 'bp',
    },
    {
        path: '/dashboard/to-do/:type',
        name: 'Dashboard To do',
        component: DashboardTodo,
    },
    {
        path: '/dashboard/activity-feed/:type',
        name: 'Dashboard Activity Feed',
        component: DashboardTodo,
    },
    {
        path: '/dashboard/to-do-activity-tabs',
        name: 'Dashboard To do Activity',
        component: DashboardTodoActivity,
    },
    {
        path: '/configuration/warehouse',
        name: 'Warehouse',
        component: Warehouse,
    },

    // {
    //     path: '/air_booking_list/POSelection',
    //     name: 'LCL Selection',
    //     component: AirPOSelection,
    // },
    // {
    //     path: '/air_booking_list/:id/POSelection',
    //     name: 'POSelectionAir',
    //     component: AirPOSelection,
    // },
    // {
    //     path: '/air_booking_list/:id/FlightSelectionDetails',
    //     name: 'Sailing Selection Details',
    //     component: AirSailingScheludeSelection,
    // },
    // {
    //     path: '/air_booking_list/:id/FlightSelectionDetails',
    //     name: 'Sailing Selection Details',
    //     component: AirSailingScheludeSelection,
    // },
    // {
    //     path: '/air_booking_list/:id/attachments',
    //     name: 'Sailing Selection Details',
    //     component: AirBookingProposalAttachmentSelection,
    // },
    // {
    //     path: '/air_booking_list/:id/shipment_details',
    //     name: 'Sailing Selection Details',
    //     component: AirBookingProposalShippingDetail,
    // },
    // {
    //     path: '/air_booking_list/:id/booking_details',
    //     name: 'Sailing Selection Details',
    //     component: AirBookingProposalBookingDetail,
    // },
    // {
    //     path: '/air_booking_list/:id/FlightSelectionDetails',
    //     name: 'AirBookingProposalAddSchelude',
    //     component: AirSailingSchelude,
    // },
    // {
    //     path: '/air_booking_list/:id/bookingproposal/SailingSchedule/change-vessel/manual',
    //     name: 'AirBookingProposalAddSchelude',
    //     component: AirBookingProposalAddSchelude,
    // },
    // {
    //     path: '/air_booking_list/:id/POSelectionDetails',
    //     name: 'AirBookLineDetails',
    //     component: AirBookLineDetails,
    // },
    // {
    //     path: '/air_booking_list/POSelectionDetails',
    //     name: 'AirBookLineDetails',
    //     component: AirBookLineDetails,
    // },
    {
        path: '/dashboard/map',
        name: 'Map',
        component: DashboardMap,
    },
    {
        path: '/dashboard/work-trends',
        name: 'WorkTrends',
        component: WorkTrendsDetailed,
    },
    {
        path: '/reassign-agent/purchase-order',
        name: 'ReassignAgent PO',
        component: ReassignAgentPo,
    },
    {
        path: '/reassign-agent/booking-proposal/:type',
        name: 'ReassignAgent Booking',
        component: ReassignAgentBooking,
    },
    {
        path: '/reassign-agent/approval',
        name: 'ReassignAgent Approval',
        component: AgentReassignmentApproval,
    },
    {
        path: '/dashboard/insight',
        name: 'Insight',
        component: InsightExpanded,
    },
    {
        path: '/tracking/insight/:id',
        name: 'InsightDetailsList',
        component: InsightDetailsList,
    },
    {
        path: '/shipments-view',
        name: 'ShipmentsViewNormal',
        component: LayoutWithColumns,
    },
    {
        path: '/doc-tracking/:id',
        name: 'DocTrackingShipment',
        component: LayoutWithColumns,
    },
    {
        path: '/warehouse/:id',
        name: 'WarehousingReceipt',
        component: LayoutWithColumns,
    },
    {
        path: '/shipments-view/:id',
        name: 'ShipmentsView',
        component: LayoutWithColumns,
    },
    {
        path: '/scr/fwd/shp/:id/:tab',
        name: 'ShipmentsViewDetails',
        component: ShipmentDetails,
    },
    {
        path: '/containers/:id',
        name: 'Containers',
        component: LayoutWithColumns,
    },
    {
        path: '/booking/:id',
        name: 'Booking (With Confirmation Groups)',
        component: LayoutWithColumns,
    },
    {
        path: '/tracking',
        name: 'Tracking',
        component: Tracking,
    },
    {
        path: '/tracking/lifecycle_booking/:type/:id',
        name: 'LifecycleBooking',
        component: LifecycleBooking,
    },
];

export default routes;
