/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface receiptHeaderData {
    plannedCustomsClearance: string;
    plannedGoodsReceivedDate: string;
    shippingTerms: string;
    fumigationRequired: boolean | string;
    customsInspectionRequired: boolean | string;
    customsInspectionDate: string;
    qcInspectionRequired: boolean | string;
    qcInspectionDate: string;
    actualCustomsClearance?: string;
    actualGoodsReceivedDate?: string;
    qcCompleted?: boolean | string;
    receiptStatus?: string;
    actualGoodsReadyDate: string;
    actualCustomsInspectionDate?: string;
    rhtabComplete?: boolean;
    polinesTabComplete?: boolean;
    documentTabComplete?: boolean;
}
const initialReceiptHeaderData = {
    plannedCustomsClearance: '',
    plannedGoodsReceivedDate: '',
    shippingTerms: '',
    fumigationRequired: '',
    customsInspectionRequired: '',
    customsInspectionDate: '',
    qcInspectionRequired: '',
    qcInspectionDate: '',
    actualCustomsClearance: '',
    actualGoodsReceivedDate: '',
    qcCompleted: '',
    receiptStatus: '',
    actualGoodsReadyDate: '',
    actualCustomsInspectionDate: '',
};

interface ReceiptHeaderState {
    isLoading: boolean;
    receiptHeader: receiptHeaderData;
    shippingTerms: { shippingTermsId: string; shippingTerms: string }[];
    receiptHeaderSuccess: any;
    receiptHeaderError: any;
    isError: boolean;
    reopenError: boolean;
    reopenSuccess: boolean;
    reopenErrorMessage: string;
}

const initialState: ReceiptHeaderState = {
    isLoading: true,
    receiptHeader: initialReceiptHeaderData,
    shippingTerms: [],
    receiptHeaderSuccess: undefined,
    receiptHeaderError: undefined,
    isError: false,
    reopenError: false,
    reopenSuccess: false,
    reopenErrorMessage: '',
};

export const receiptHeaderSlice = createSlice({
    name: 'receiptHeader',
    initialState,
    reducers: {
        fetchReceiptHeaderList: (state, _action) => {
            state.isLoading = true;
        },
        fetchReceiptHeaderListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeader = action.payload?.data?.info;
        },
        fetchReceiptHeaderListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeader = action.payload;
        },
        fetchShippingTerms: (state, _action) => {
            state.isLoading = true;
        },
        fetchShippingTermsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.shippingTerms = action.payload?.data?.info;
        },
        fetchShippingTermsFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeader = action.payload;
        },
        discardReceiptHeader(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        discardReceiptHeaderSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        discardReceiptHeaderFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        cancelReceiptHeader(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        cancelReceiptHeaderSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        cancelReceiptHeaderFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        closeReceipt(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        closeReceiptSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        closeReceiptFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        receiveInFull(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        receiveInFullSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        receiveInFullFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        updateComplianceCheck(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateComplianceCheckSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        updateComplianceCheckFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        updateReceivedQty(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateReceivedQtySuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        updateReceivedQtyFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        updateReceiptHeader(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateReceiptHeaderSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        updateReceiptHeaderFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        updateActualReceipt(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateActualReceiptSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        updateActualReceiptFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            state.receiptHeaderError = action.payload;
        },
        downloadPoLinesImages(state, _action) {
            // state.isLoading = true;
            state.isError = false;
        },
        downloadPoLinesImagesSuccess(state, _action) {
            // state.isLoading = false;
            state.isError = false;
            // state.receiptHeaderSuccess = action.payload;
            state.receiptHeaderError = {};
        },
        downloadPoLinesImagesFailure(state, _action) {
            // state.isLoading = false;
            state.isError = true;
            state.receiptHeaderSuccess = {};
            // state.receiptHeaderError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.receiptHeaderError = undefined;
            state.receiptHeaderSuccess = undefined;
            state.isError = false;
        },
        reopenReceipt(state, action) {
            state.isLoading = true;
            if (action.payload === 'Success') {
                state.reopenSuccess = true;
            } else if (action.payload?.error) {
                state.reopenError = true;
                state.reopenErrorMessage = action.payload?.error?.data.data;
            }
            state.isLoading = false;
        },
        clearReopenFlags(state, _action) {
            state.reopenSuccess = false;
            state.reopenErrorMessage = '';
            state.reopenError = false;
        },
    },
});

export const {
    fetchReceiptHeaderList,
    fetchReceiptHeaderListSuccess,
    fetchReceiptHeaderListFailed,
    fetchShippingTerms,
    fetchShippingTermsSuccess,
    fetchShippingTermsFailed,
    discardReceiptHeader,
    discardReceiptHeaderSuccess,
    discardReceiptHeaderFailure,
    cancelReceiptHeader,
    cancelReceiptHeaderSuccess,
    cancelReceiptHeaderFailure,
    closeReceipt,
    closeReceiptSuccess,
    closeReceiptFailure,
    receiveInFull,
    receiveInFullSuccess,
    receiveInFullFailure,
    updateComplianceCheck,
    updateComplianceCheckSuccess,
    updateComplianceCheckFailure,
    updateReceivedQty,
    updateReceivedQtySuccess,
    updateReceivedQtyFailure,
    updateReceiptHeader,
    updateReceiptHeaderSuccess,
    updateReceiptHeaderFailure,
    updateActualReceipt,
    updateActualReceiptSuccess,
    updateActualReceiptFailure,
    downloadPoLinesImages,
    downloadPoLinesImagesSuccess,
    downloadPoLinesImagesFailure,
    isLoadingFn,
    clearFailure,
    reopenReceipt,
    clearReopenFlags,
} = receiptHeaderSlice.actions;

export const selectReceiptHeaderLoading = (state: RootState) => state.receiptHeaderData.isLoading;
export const selectReceiptHeader = (state: RootState) => state.receiptHeaderData.receiptHeader;
export const selectShippingTerms = (state: RootState) => state.receiptHeaderData.shippingTerms;
export const selectReceiptHeaderSuccess = (state: RootState) => state.receiptHeaderData.receiptHeaderSuccess;
export const selectReceiptHeaderError = (state: RootState) => state.receiptHeaderData.receiptHeaderError;
export const selectReceiptHeaderIsError = (state: RootState) => state.receiptHeaderData.isError;

export default receiptHeaderSlice.reducer;
