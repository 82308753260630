import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    changePackingStatus,
    fetchPackingDetailsApi,
    fetchSsccApi,
    getSsrsApi,
    packContainerApi,
    unpackContainerApi,
} from 'src/services/apis/purchaseOrder/vendorPortalApi';

import {
    deleteContainerPacking,
    fetchPackingStatus,
    fetchSsccData,
    finalisePacking,
    postContainerPacking,
    fetchSsrsDaReport,
} from 'src/store/reducers/purchaseOrder/vendorPortalReducer';

/**
 * get current packing status - cix vp/packing
 * @param payload
 */
function* getPackingStatusDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchPackingDetailsApi, payload);
        yield put({ type: fetchPackingStatus, payload: response.data });
    } catch (error) {
        // do nothing
    }
}

/**
 * get SSCC - cix vp/sscc
 * @param payload
 */
function* getSsccData({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchSsccApi, payload);
        yield put({ type: fetchSsccData, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

/**
 * Ratio Packing API
 * post - cix vp/packing
 * @param payload
 */
function* packContainer({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(packContainerApi, payload);
        yield put({ type: postContainerPacking, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

/**
 * delete - cix vp/packing
 * @param payload
 */
function* unpackContainers({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(unpackContainerApi, payload);
        yield put({ type: deleteContainerPacking, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

/**
 * post - cix /:PO_PK/vp/finalise/:action
 * @param payload
 */
function* postFinalisePacking({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(changePackingStatus, payload);
        yield put({ type: finalisePacking, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* getSsrsDaReport({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getSsrsApi, payload);
        yield put({ type: fetchSsrsDaReport, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* vendorPortalSaga() {
    yield takeEvery('vendorPacking/fetchPackingStatus', getPackingStatusDetails);
    yield takeEvery('vendorPacking/fetchSsccData', getSsccData);
    yield takeEvery('vendorPacking/postContainerPacking', packContainer);
    yield takeEvery('vendorPacking/deleteContainerPacking', unpackContainers);
    yield takeEvery('vendorPacking/finalisePacking', postFinalisePacking);
    yield takeEvery('vendorPacking/fetchSsrsDaReport', getSsrsDaReport);
}

export default vendorPortalSaga;
