import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import CancelModal from '../../../components/common/CancelModal';
import { FILE_SIZE, SUPPORTED_FORMATS, fileTypesInfoText, fileUploadInfoText } from 'src/utils/constants';
import { selectCurrency } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { saveSellQuote } from 'src/store/reducers/bookingProposalAir/flightScheduleReducer';
import '../../../assets/scss/components/_modal.scss';
import './quote-detail.scss';
import { convertDataForSelect } from 'src/utils';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useParams } from 'react-router-dom';
import { getSelectedOptionDet } from 'src/services/apis/bookingProposalAir/existingFlightSchedules';
interface QuoteDetailModalProps {
    setShowQuoteDetail: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
    loader: boolean;
    setLoader: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    uomData: any;
}

interface CostBuyAndSell {
    amount: string | number;
    currency: string;
}

const Index = ({ setShowQuoteDetail, data, loader, setLoader, uomData }: QuoteDetailModalProps) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [_showCurrency, setShowCurrency] = useState(false);
    const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
    const [costSell, setCostSell] = useState<CostBuyAndSell>({ amount: '', currency: '' });
    const [quotationSell, setQuotationSell] = useState({ fileName: '', fileSize: 0 });
    const currency = useAppSelector(selectCurrency);
    const [file, setFile] = useState<any>(null);
    const [error, setError] = useState('');
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [sellUomCode, setSellUomCode] = useState('');

    useEffect(() => {
        if (data) {
            if (!data?.sellCurrency) {
                getSelectedOptionDet(id ?? '')
                    .then((res: any) => {
                        setCostSell({
                            amount: res?.ZO_SellCost ?? '',
                            currency: res?.ZO_SellCurrency,
                        });
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            } else {
                setCostSell({
                    amount: data?.sellCost ?? '',
                    currency: data?.sellCurrency,
                });
            }

            setQuotationSell({
                fileName: data?.sellQuoteName,
                fileSize: +data?.sellFileSizeInKb * 1024,
            });
            setSellUomCode(data?.sellUomCode);
        }
    }, [data]);

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            if (!SUPPORTED_FORMATS.includes(e.target.files[0].type)) {
                setError('File type is not supported');
                setQuotationSell({ fileName: '', fileSize: 0 });
                setFile(null);
            } else if (e.target.files[0].size > FILE_SIZE) {
                setError('Maximum file size is 25mb');
                setQuotationSell({ fileName: '', fileSize: 0 });
                setFile(null);
            } else {
                setError('');
                setQuotationSell({ fileName: e.target.files[0]?.name, fileSize: e.target.files[0]?.size });
                setFile(e.target.files[0]);
            }
        }
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const removeFile = () => {
        setFile(null);
        setQuotationSell({ fileName: '', fileSize: 0 });
    };

    const onSubmit = () => {
        const formData = new FormData();

        if (file?.type) {
            formData.append('file', file, file.name);
        }

        const payload = {
            bookingProposalId: id,
            airFreightId: data?.baId,
            sellCost: costSell.amount,
            sellCurrency: costSell.currency,
            type: 'SELL',
            sellUomCode,
        };
        formData.append('request', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
        dispatch(saveSellQuote({ formData, mode: data?.sellCost ? 'editSellQuote' : 'addSellQuote' }));
        setLoader(true);
    };

    const saveButtonStatus = () => {
        if (
            typeof costSell?.amount === 'number' &&
            costSell?.amount >= 0 &&
            costSell?.currency &&
            sellUomCode &&
            quotationSell?.fileName /* &&
            (costSell?.amount !== data?.sellCost ||
                costSell?.currency != data?.sellCurrency ||
                quotationSell?.fileName != data?.sellQuoteName) */
        ) {
            return false;
        } else {
            return true;
        }
    };

    const toggleCancel = () => {
        setShowQuoteDetail(false);
    };

    const showError = (errMsg: string, fieldValue: any) => {
        return <>{isSaveClicked && !fieldValue ? <div className="error-text">{errMsg}</div> : null}</>;
    };

    return (
        <div
            onClick={() => {
                setShowCurrency(false);
            }}
            className="app-modal container quote-detail"
        >
            <div className="modal-content-holder medium-popup">
                <div className="modal-header">
                    <div className="title">Quote Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            if (!saveButtonStatus()) {
                                setCancelModalVisibility(true);
                            } else {
                                setShowQuoteDetail(false);
                            }
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className={`app-loader ${loader ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="modal-main-content quote-modal-main-content">
                        <form id="my-form" action="" className="modal-form layout-holder one-column">
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Total Cost (Sell)<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <div className="total-term-wrapper">
                                        <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                            <span className="unit-style"></span>
                                            <input
                                                placeholder="Enter Total Cost"
                                                type="number"
                                                step="any"
                                                min="0"
                                                id="costSellAmount"
                                                name="costSellAmount"
                                                value={costSell?.amount}
                                                onChange={(e) => {
                                                    setCostSell((prev) => ({
                                                        ...prev,
                                                        amount: +parseFloat(e.target.value),
                                                    }));
                                                }}
                                                className="modal-input input-text"
                                            />
                                            {showError('Total Cost (Sell) is required', costSell?.amount)}
                                        </div>
                                        <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                            <SearchableSelectBox
                                                data={convertDataForSelect(currency, 'code', 'code')}
                                                placeholder="Enter currency to select"
                                                setStateValue={(currencyCode: string) =>
                                                    setCostSell((prev) => ({
                                                        ...prev,
                                                        currency: currencyCode,
                                                    }))
                                                }
                                                stateValue={costSell?.currency}
                                                fieldName="currencySell"
                                                menuPortalTarget={document.getElementById('outside_div')}
                                            />
                                            {showError('Currency is required', costSell?.currency)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    UOM<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <div className="total-term-wrapper">
                                        <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                            <SearchableSelectBox
                                                data={uomData}
                                                placeholder="Enter UOM to select"
                                                setStateValue={(value: string) => setSellUomCode(value)}
                                                stateValue={sellUomCode}
                                                fieldName="currencySell"
                                                menuPortalTarget={document.getElementById('outside_div')}
                                            />
                                            {showError('UOM is required', sellUomCode)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label htmlFor="name" className="modal-input-label">
                                    Quotation (Sell)<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    {!quotationSell?.fileName ? (
                                        <>
                                            <div className="browse-btn-wrapper">
                                                <input
                                                    type={'file'}
                                                    id={'quotationFile'}
                                                    name={'quotationFile'}
                                                    accept={
                                                        '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                    }
                                                    className="browse-input"
                                                    onClick={(e) => {
                                                        (e.target as HTMLInputElement).value = '';
                                                    }}
                                                    onChange={(e) => {
                                                        fileUpload(e);
                                                    }}
                                                ></input>
                                                <button
                                                    className="app-btn app-btn-secondary icon-button browse-btn"
                                                    title="Upload file"
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <svg className="svg-icon plus-icon">
                                                        <use xlinkHref="#plusIcon">
                                                            <title>Upload File</title>
                                                        </use>
                                                    </svg>
                                                    <span className="button-text upload-btn">Upload File</span>
                                                </button>
                                                {error ? <div className="error-text">{error}</div> : null}
                                                {showError('File is required', quotationSell?.fileName)}
                                            </div>

                                            <div className="heading-type-1 file-size">
                                                <p>{fileUploadInfoText}</p>
                                                <p dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}></p>
                                            </div>
                                        </>
                                    ) : (
                                        <div className="content-with-icon  attachment-holder">
                                            <button
                                                type="button"
                                                className="app-btn "
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                }}
                                            >
                                                <svg className="svg-icon file-attachment-icon ">
                                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                                    <title>Attach file</title>
                                                </svg>
                                            </button>
                                            <div>
                                                {/* file-uploaded class to higlight data with blue color */}
                                                <a className="description-type-1 file-uploaded" title="File">
                                                    {quotationSell?.fileName?.length > 20
                                                        ? `${quotationSell?.fileName?.slice(0, 20)}...`
                                                        : quotationSell?.fileName}
                                                </a>
                                                <div className="heading-type-1">
                                                    {getFileSize(quotationSell?.fileSize)}
                                                </div>
                                            </div>

                                            <a
                                                // href="#"
                                                className="file-remove-link read-all-link"
                                                title="Remove"
                                                onClick={() => removeFile()}
                                            >
                                                Remove
                                            </a>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (!saveButtonStatus()) {
                                setCancelModalVisibility(true);
                            } else {
                                setShowQuoteDetail(false);
                            }
                        }}
                        className="app-btn app-btn-secondary modal-btn footer-btn footer-btn-container"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn  footer-btn-container"
                        type="button"
                        // disabled={saveButtonStatus()}

                        onClick={() => {
                            setIsSaveClicked(true);
                            if (saveButtonStatus()) {
                                return;
                            }
                            onSubmit();
                        }}
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                </div>
            </div>
            <CancelModal
                displayFunction={null}
                customDisplayFunction={toggleCancel}
                mode={'edit'}
                cancelModalVisibility={cancelModalVisibility}
                setCancelModalVisibility={setCancelModalVisibility}
                header={''}
            />
            <div id="outside_div"></div>
        </div>
    );
};
export default Index;
