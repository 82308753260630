/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { debounce } from 'lodash';
import { getPortMatching } from 'src/services/apis/quoteAPi';

type Props = {
    title: string;
    keyName: string;
    quoteData: any;
    setQuoteData: any;
    quoteItemData: any;
    setQuoteItemData: any;
    loading: any;
    saveTriggered: boolean;
    isRequired: boolean;
};

const QuoteItemSinglePortCard: React.FC<Props> = ({
    title,
    keyName,
    quoteData,
    setQuoteData,
    quoteItemData,
    setQuoteItemData,
    loading,
    saveTriggered,
    isRequired,
}) => {
    const [dropdownLoading, setDropdownLoading] = useState(false);
    const [currentSelected, setCurrentSelected] = useState('');

    const getQuoteItemDropDownData = () => {
        return quoteItemData;
    };

    useEffect(() => {
        if (quoteData?.[keyName]?.length === 0) {
            const copy = JSON.parse(JSON.stringify(quoteData));
            copy?.[keyName]?.push({ label: '', value: '' });
            setQuoteData(copy);
        }
    }, [quoteData?.[keyName]]);

    const addItem = () => {
        const copy = JSON.parse(JSON.stringify(quoteData));
        copy?.[keyName]?.push({ label: '', value: '' });
        setQuoteData(copy);
    };

    const saveItem = (value: string, index: number) => {
        const filtered = quoteItemData?.find((res: any) => res?.value === value);
        const copy = JSON.parse(JSON.stringify(quoteData));
        copy[keyName][index] = filtered ? filtered : { label: '', value: '' };

        setQuoteData(copy);
    };

    const removeItem = (index: number) => {
        const copy = JSON.parse(JSON.stringify(quoteData));
        if (copy?.[keyName]?.length <= 1) {
            copy?.[keyName]?.splice(index, 1);
            copy?.[keyName]?.push({ label: '', value: '' });
            setQuoteData(copy);
        } else {
            copy?.[keyName]?.splice(index, 1);
            setQuoteData(copy);
        }
    };

    const getData = debounce(async (value: string) => {
        setDropdownLoading(true);
        const provider = await getPortMatching(value);
        setQuoteItemData(provider);
        setDropdownLoading(false);
    }, 500);

    return (
        <div className="detail-box-common">
            <h6 className="details-box-heading-2">
                {title} {isRequired && <span className="mandatory-field-text">*</span>}
            </h6>
            {quoteData?.[keyName]
                ?.filter((res: any) => res?.isSaved !== false)
                ?.map((res: any, index: number) => (
                    <div key={index + '_' + res?.value} className="details-box-detail">
                        <div className="details-box-detail-dropdown-port">
                            <SearchableSelectBox
                                data={getQuoteItemDropDownData()}
                                placeholder="Choose one item"
                                setStateValue={(value: string) => saveItem(value, index)}
                                stateValue={res?.value}
                                fieldName={`${keyName}-port-${res?.value}-${index}`}
                                isClearable={true}
                                isLoading={loading[keyName] || (dropdownLoading && `port-${index}` === currentSelected)}
                                menuPlacement={'top'}
                                overrideValue={res?.value ? { label: res?.value, value: res?.value } : null}
                                onInputChangeFn={(value: string) => {
                                    if (value?.length > 2) {
                                        getData(value);
                                    }
                                }}
                                onFocusFn={() => {
                                    setCurrentSelected(`port-${index}`);
                                    if (getQuoteItemDropDownData()?.length < 1000) {
                                        getData('');
                                    }
                                }}
                            />
                        </div>
                        <div className="details-box-detail-btn">
                            <button
                                className="app-btn app-btn-secondary icon-button  add-product-btn"
                                title="Remove"
                                onClick={() => removeItem(index)}
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <use xlinkHref="#dashCheck"></use>
                                </svg>
                            </button>
                            <button
                                onClick={() => addItem()}
                                className="app-btn app-btn-secondary icon-button add-product-btn"
                                title="Add"
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <use xlinkHref="#plusIcon"></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                ))}

            {saveTriggered &&
                isRequired &&
                (!quoteData?.portMatching?.length || quoteData?.portMatching[0]?.value === '') && (
                    <div className="error-text">This field is required</div>
                )}
        </div>
    );
};

export default QuoteItemSinglePortCard;
