/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Header from '../Header';
// import ConfirmBookingModal from './ConfirmBookingModal';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchProductDetailsConfirmationList,
    fetchLclThreshold,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import './index.scss';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';

import { useUserProfile } from 'src/hooks/useUserProfile';
// import { Formik } from 'formik';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import NotesModal from './NotesModal';
import { getNoteType, getSingleNote } from 'src/services/apis/notesApi';
import { Position, error } from 'src/utils/toast';
import { getRateByPK } from 'src/services/apis/qmsApi';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import QuoteHeader from '../Header';
import QuoteTab from '../Tab';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { fetchQuoteHeader } from 'src/store/reducers/qmsReducer';

const Index: React.FC = () => {
    const { id } = useParams();
    const { userName, profileType } = useUserProfile();
    const dispatch = useAppDispatch();
    const headerData = useAppSelector(selectHeader);

    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;

    const initialNotesData = {
        NT_FK: id,
        NT_FKID: 'QH',
        NT_Subject: '',
        NT_CD_NoteType: '',
        NT_IsImportant: false,
        NT_IsForwarderViewable: true,
        NT_IsAgentViewable: profileType === 'agent' ? true : false,
        NT_IsClientViewable: profileType === 'client' ? true : false,
        NT_IsSupplierViewable: profileType === 'vendor' ? true : false,
        NT_IsLockedToCreator: true,
        NT_Text: '',
        US_UserName: '',
    };

    const refreshGrid = () => {
        const refresh: any = document.querySelector('.k-pager-refresh');
        if (refresh) {
            refresh?.click();
        }
    };

    const [modalView, setModalView] = useState(false);
    const [mode, setMode] = useState('');
    const [noteTypeData, setNoteTypeData] = useState([]);
    const [noteId, setNoteId] = useState('');
    const [body, setBody] = useState({});
    const { quoteHeader } = useSelector((state: RootState) => state.qmsData);

    // useEffect(() => {
    //     if (id && quoteHeader?.length === 0) {
    //         dispatch(fetchQuoteHeader({ payload: { key: id } }));
    //     }
    // }, [id]);

    const navData = [
        {
            title: 'Quote Input',
            selected: 0,
            path: id ? `/qms/quote/${id}` : '/qms/quote',
        },
    ];

    useEffect(() => {
        // dispatch(
        //     fetchProductDetailsConfirmationList({
        //         poId: id,
        //     })
        // );
        dispatch(fetchPermissions(username));
        dispatch(fetchLclThreshold({ userName }));
        getNoteType()
            .then((res: any) => {
                setNoteTypeData(res);
            })
            .catch((err: any) => console.log(err));
        // dispatch(fetchPort({ key: 'a', type: 'PO', service: 'ord' }));
    }, []);

    useEffect(() => {
        if (!modalView && mode === 'edit') {
            refreshGrid();
        }
    }, [modalView]);

    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';

    //@ts-ignore
    window.noteUp = (value: string) => {
        if (value === 'NEW') {
            setMode('add');
            setBody(initialNotesData);
            setModalView(true);
        } else {
            getSingleNote(value)
                .then((res: any) => {
                    setBody(res);
                    setNoteId(value);
                    setMode('edit');
                    setModalView(true);
                })
                .catch((err: any) => {
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                });
        }
    };

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={'Quotes'}
                        firstUrl={'/qms/quote'}
                        secondName={'Quote Input'}
                        secondUrl={'#'}
                        backUrl={'/qms/quote'}
                    />
                    <QuoteHeader />
                    <QuoteTab navData={navData} />
                </div>
                <div className="lifecycle-grid">
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'lifecycle'} content={'purchase-order-notes'} />
                    </div>
                </div>
                {modalView ? (
                    <NotesModal
                        initialNotesData={initialNotesData}
                        setModalView={setModalView}
                        noteTypeData={noteTypeData}
                        mode={mode}
                        noteId={noteId}
                        setNoteId={setNoteId}
                        body={body}
                        setBody={setBody}
                        refreshGrid={refreshGrid}
                    />
                ) : null}
            </div>
        </>
    );
};
export default Index;
