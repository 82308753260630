/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import {
    activeInboundPos,
    activeStatus,
    clearFilterText,
    inboundPoHeaders,
    statusOfInboundPos,
} from '../../../utils/constants';
import {
    fetchPoData,
    setPageCount,
    setDefault,
    resetPage,
    fetchFilterData,
    fetchStatusCount,
    setInboundPoSelectedDropDown as setSelectedDropDown,
    setInboundPoFilter as setFilter,
} from '../../../store/reducers/purchaseOrder/poListReducer';
import TableFooter from '../../../components/common/Table/tableFooter';
import ExportButton from '../../../components/common/ExportButton';
import ShowForPermissions from '../../../ShowForPermissions';
import { fetchPermissions } from '../../../store/reducers/permissionReducer';
import ProfileListing from '../../../components/common/ProfileListing';
import './purchase-order.scss';
import { Link, useLocation } from 'react-router-dom';
import { Position, success } from '../../../utils/toast';
import { submitSuccess } from '../../../store/reducers/purchase_order/basicDetails';
import { getNotification } from '../../../store/reducers/authReducer';
import { exportPoList } from '../../../services/apis/purchaseOrder/poListApi';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { getOrganisationData } from '../../../utils';
import { clearHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import ClickAwayListener from 'react-click-away-listener';
const Index: React.FC = () => {
    const dispatch = useDispatch();
    const {
        purchaseOrders,
        filterValues,
        currentPage,
        perPageCount,
        totalPages,
        columnStatus,
        totalItems,
        isLoading,
        isLoadingPO,
        statusCount,
        inboundPoFilter: filter,
        inboundPoSelectedDropDown: selectedDropDown,
    } = useSelector((state: RootState) => state.purchaseOrderList);
    const { successPo, poName } = useSelector((state: RootState) => state.purchaseOrder);
    const [selectedHeader, setSelectedHeader] = useState(inboundPoHeaders.createdDate);
    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();
    // eslint-disable-next-line
    const [expandId, setexpandId] = useState<any | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [activeCount, setActiveCount] = useState(0);
    const userDetail = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = userDetail?.user?.us_UserName;
    const firstUpdate = useRef(true);
    const { profileType } = useUserProfile();
    const organisationData = getOrganisationData();
    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter, type: 'IBPO', loggedInUserName: userDetail?.user?.us_UserName },
        profileType,
    };
    const sortPayload = {
        sortOrder: currentOrder ? 'DESC' : 'ASC',
        sortColumn: columnStatus,
    };
    const payloadForFilter = {
        ...payload,
        data: {
            ...organisationData,
            ...{ poStatus: filter.poStatus },
            type: 'IBPO',
            loggedInUserName: userDetail?.user?.us_UserName,
            ...filter,
        },
    };

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (searchTerm?.trim()?.length > 2) {
            if (
                filter?.poStatus === 'Cancelled' ||
                filter?.poStatus === 'Inbound Received' ||
                filter?.poStatus === activeStatus
            ) {
                dispatch(setFilter({ ...filter, poSearch: searchTerm }));
            } else {
                dispatch(
                    setSelectedDropDown({
                        text: activeInboundPos,
                        count: activeCount,
                    })
                );
                dispatch(setFilter({ ...filter, poSearch: searchTerm, poStatus: activeStatus }));
            }
        } else if (searchTerm?.trim()?.length === 0) {
            setSearchTerm('');
            if ((filter as any)?.poSearch) {
                const filterObj = JSON.parse(JSON.stringify(filter));
                delete filterObj.poSearch;
                dispatch(setFilter(filterObj));
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        dispatch(clearHeader([]));
        dispatch(getNotification({}));
        dispatch(resetPage({}));
        return () => {
            dispatch(setDefault({}));
        };
    }, []);

    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userType = '';
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            userType = roles[roleIndex].profile?.toLowerCase();
        }
    }

    useEffect(() => {
        const sum = getTotalNumberOfPOs();
        setActiveCount(sum);
    }, [statusCount]);

    useEffect(() => {
        dispatch(fetchPoData(payload));
        dispatch(fetchFilterData(payloadForFilter));
        dispatch(fetchStatusCount(payload));
        dispatch(fetchPermissions(username));
    }, [dispatch, /* currentPage, */ setSelectedHeader, /* perPageCount, */ currentOrder, filter]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
    }, [totalPages]);

    useEffect(() => {
        if (successPo) {
            success(`Inbound Purchase Order ${poName} submitted successfully`, Position.TOP_RIGHT);
            dispatch(submitSuccess(false));
            dispatch(fetchPoData(payload));
            dispatch(fetchFilterData(payloadForFilter));
            dispatch(fetchStatusCount(payload));
            dispatch(fetchPermissions(username));
        }
        dispatch(getNotification({}));
    }, [successPo]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        setselectedFilter(data);
    };

    const setExpandIdOnClick = (id: string) => {
        setexpandId(id);
    };

    const filterToggle = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const onSort = (sortKey: string) => {
        setSelectedHeader(sortKey);
        setcurrentOrder(!currentOrder);
    };

    const getTotalNumberOfPOs = () => {
        const status = statusCount as any;
        if (status) {
            let sum = (Object.values(status) as number[])?.reduce((prev: number, current: number) => prev + current, 0);
            if (sum) {
                sum = sum - ((status['Cancelled'] || 0) + (status['Inbound Received'] || 0));
            }
            return sum;
        } else {
            return 0;
        }
    };

    const onSearchClose = () => {
        setSearchTerm('');
        if ((filter as any)?.poSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.poSearch;
            dispatch(setFilter(filterObj));
        }
    };
    const getDropDown = () => {
        const finalArray = [];
        for (const key of statusOfInboundPos) {
            if (statusCount && Object.prototype.hasOwnProperty.call(statusCount, key)) {
                finalArray.push(
                    <a
                        className={`menu-item app-btn ${selectedDropDown.text === key ? ' active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                                setSelectedDropDown({
                                    text: key,
                                    count: statusCount[key as keyof typeof statusCount],
                                })
                            );
                            setDropDownOpen(false);
                            dispatch(setFilter({ ...filter, poStatus: key }));
                        }}
                    >
                        <div className="content-heading-holder">
                            <span className="content-heading">{key}</span>
                            <span className=" modal-toast">
                                <div className="toast-message ">{statusCount[key as keyof typeof statusCount]}</div>
                            </span>
                        </div>
                    </a>
                );
            }
        }
        return finalArray;
    };

    const closeAllDropDown = () => {
        setDropDownOpen(false);
        setToggleDropdown(false);
        setShowExportDropDown(false);
        setPageCountDisplay(false);
    };

    const clearSearchFilter = () => {
        if ((filter as any)?.poSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.poSearch;
            dispatch(setFilter(filterObj));
        }
        dispatch(fetchStatusCount(payload));
    };
    useLayoutEffect(() => {
        if ((location as any)?.state?.reload !== true) {
            resetFilters();
        }
    }, [location]);

    const resetFilters = () => {
        if (
            (Object.keys(filter)?.length === 1 &&
                filter?.poStatus !== activeStatus &&
                selectedDropDown?.text !== activeInboundPos) ||
            Object.keys(filter)?.length > 1
        ) {
            const initialFilter = { poStatus: activeStatus };
            const initialDropDown = { text: activeInboundPos, count: 0 };
            setSearchTerm('');
            dispatch(setFilter(initialFilter));
            dispatch(setSelectedDropDown(initialDropDown));
        }
    };

    return (
        <div
            className="main-wrapper"
            onClick={() => {
                closeAllDropDown();
            }}
        >
            <div className="main-content-holder no-main-grid-border purchase-grid">
                <h2 className="page-heading">Inbound PO</h2>
                <div className="grid-section">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="search-field-purchase-grid">
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search Inbound PO"
                                        type="text"
                                        value={searchTerm}
                                        className="search-input input-text"
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                    ></input>
                                    <div
                                        className={`search-toolip ${
                                            searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                        }`}
                                    >
                                        Enter atleast 3 characters to search
                                    </div>
                                    <svg className="svg-icon search-icon">
                                        <use href="#searchIcon"></use>
                                    </svg>
                                    <button
                                        className="app-btn text-close-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSearchClose();
                                        }}
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                                <ClickAwayListener onClickAway={() => setDropDownOpen(false)}>
                                    <div
                                        className={`dropdown-wrap ${
                                            dropDownOpen ? 'dropdown-open' : ''
                                        } download-dropdown search-toast-content-holder`}
                                    >
                                        <button
                                            className="app-btn app-btn-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDropDownOpen(!dropDownOpen);
                                            }}
                                        >
                                            <div className="purchase-dropdown-content">
                                                <span className="button-text">Status :</span>
                                                <div className="content-heading-holder">
                                                    <span className="content-heading">{selectedDropDown?.text}</span>
                                                    <span className=" modal-toast">
                                                        <div className="toast-message ">
                                                            {selectedDropDown?.text === activeInboundPos
                                                                ? activeCount
                                                                : statusCount &&
                                                                  statusCount[
                                                                      selectedDropDown.text as keyof typeof statusCount
                                                                  ]}
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <svg className="svg-icon arrow-icon">
                                                <use href="#downArrow">
                                                    <title>Expand row</title>
                                                </use>
                                            </svg>
                                        </button>
                                        <div className="dropdown-menu">
                                            <>
                                                <a
                                                    className={`menu-item app-btn ${
                                                        selectedDropDown.text === activeInboundPos ? 'active' : ''
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(
                                                            setSelectedDropDown({
                                                                text: activeInboundPos,
                                                                count: activeCount,
                                                            })
                                                        );
                                                        setDropDownOpen(false);
                                                        dispatch(setFilter({ ...filter, poStatus: activeStatus }));
                                                    }}
                                                >
                                                    <div className="content-heading-holder">
                                                        <span className="content-heading">{activeInboundPos}</span>
                                                        <span className=" modal-toast">
                                                            <div className="toast-message ">{activeCount}</div>
                                                        </span>
                                                    </div>
                                                </a>
                                                {getDropDown()}
                                            </>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>

                            <div className="add-btn-purchase">
                                <a
                                    href="javascript:void(0)"
                                    className="clear-filter-link"
                                    onClick={() => resetFilters()}
                                >
                                    {clearFilterText}
                                </a>
                                <ExportButton
                                    organisationData={{
                                        ...organisationData,
                                        type: 'IBPO',
                                        loggedInUserName: userDetail?.user?.us_UserName,
                                        ...filter,
                                        ...sortPayload,
                                        poSearch: searchTerm ? searchTerm : undefined,
                                    }}
                                    disabled={
                                        (purchaseOrders && purchaseOrders.length === 0 && selectedFilter === null) ||
                                        !purchaseOrders
                                            ? true
                                            : false
                                    }
                                    showExportDropDown={showExportDropDown}
                                    setShowExportDropDown={setShowExportDropDown}
                                    exportListWithBody={exportPoList}
                                />
                                {(userType === 'agent' || userType === 'client' || userType === 'forwarder') && (
                                    <ShowForPermissions permission="create" type="inbound">
                                        <Link to="/inbound/basic_details" style={{ textDecoration: 'none' }}>
                                            <button
                                                className="app-btn app-btn-secondary icon-button"
                                                title="Add Inbound PO"
                                                onClick={() => {
                                                    localStorage.removeItem('poStatus');
                                                    localStorage.removeItem('poNumber');
                                                }}
                                            >
                                                <svg className="svg-icon add-btn-icon">
                                                    <use xlinkHref="#plusIcon"></use>
                                                </svg>
                                                <span className="button-text footer-button-text">
                                                    Add New Inbound PO
                                                </span>
                                            </button>
                                        </Link>
                                    </ShowForPermissions>
                                )}
                            </div>
                        </div>
                        <ProfileListing
                            filter={filter}
                            setFilter={setFilter}
                            tabName={'inboundPO'}
                            data={purchaseOrders}
                            columnStatus={columnStatus}
                            currentOrder={currentOrder}
                            isLoading={isLoading}
                            isLoading2={isLoadingPO}
                            toggleDropdown={toggleDropdown}
                            selectedFilter={selectedFilter}
                            closeToggle={closeToggle}
                            expandId={expandId}
                            setExpandIdOnClick={setExpandIdOnClick}
                            filterToggle={filterToggle}
                            onSort={onSort}
                            filterDataLists={filterValues}
                            isGlobalStateFilter={true}
                            clearSearchFilter={clearSearchFilter}
                            resetPage={resetPage}
                        />
                        <TableFooter
                            fetchData={fetchPoData}
                            currentPage={currentPage}
                            stat={payload}
                            displayCountfn={setPageCountDisplay}
                            pageCountDisplay={PageCount}
                            perPageCount={perPageCount}
                            setPageCount={setPageCount}
                            totalItems={totalItems}
                            items={purchaseOrders}
                            itemsInList={null}
                            totalPages={totalPages - 1}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
