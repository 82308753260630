import { ProductDetailsConfirmationDataDetails } from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import BSTooltip from '../../../components/common/BSTooltip';

// Confirmed,In_Booking_Proposal,Booked,Pending_confirmation,Dropped,Ready_To_Receive, Confirmed_for_Booking,LCL_under_Approval
export const poStatusPillList = (status: string) => {
    switch (status) {
        case 'Pending_confirmation':
        case 'Pending Confirmation':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-hold">Pending Confirmation</div>
                </div>
            );

        case 'In_Booking_Proposal':
        case 'In Booking Proposal':
            return (
                <div className="allocation-status">
                    <div className="allocated-status completed">In Booking Proposal</div>
                </div>
            );
        case 'Booked':
            return (
                <div className="allocation-status">
                    <div className="allocated-status completed">Booked</div>
                </div>
            );
        case 'Confirmed_for_Booking':
        case 'Confirmed For Booking':
            return (
                <div className="allocation-status">
                    <div className="allocated-status completed">Confirmed for Booking</div>
                </div>
            );
        case 'Ready_To_Receive':
        case 'Ready To Receive':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-progress">Ready to Receive</div>
                </div>
            );
        case 'Dropped':
            return (
                <div className="allocation-status">
                    <div className="allocated-status pending">Dropped</div>
                </div>
            );
        case 'Ordered':
            return (
                <div className="allocation-status">
                    <div className="allocated-status cancel">Ordered</div>
                </div>
            );
        case 'LCL_under_Approval':
        case 'LCL Under Approval':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-hold">LCL Under Approval</div>
                </div>
            );
        case 'FCL_under_Approval':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-hold">FCL Under Approval</div>
                </div>
            );
        default:
            return (
                <div className="allocation-status">
                    <div className="allocated-status cancel">{status}</div>
                </div>
            );
    }
};

export const qtyStatus = (product: ProductDetailsConfirmationDataDetails) => {
    const total = product?.splitLines.reduce((a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0), 0);
    let type = product.updatedQuantityType;
    if (!product?.isShort && type === 'Short Shipped') {
        type = 'Pending';
    }
    switch (type) {
        case 'Short Shipped':
            return (
                <BSTooltip
                    id={`view-${product.poProductId}`}
                    classList="action-btn-wrapper"
                    position="right"
                    tooltipClassList="surplus-tooltip"
                    title={
                        <>
                            <div className="outer-wrapper-tooltip">
                                <div className="icon-header-holder">
                                    <span className="reset-unit-icon">
                                        <svg className="svg-icon ao-forward-down-icon">
                                            <use xlinkHref="#forwardDownUpIcon"></use>
                                        </svg>
                                    </span>
                                    <h1 className="id-name">SHORT SHIPPED</h1>
                                </div>
                                <div className="main-content-holder">
                                    <div className="content 1">
                                        <div className="content-title">Ordered Quantity </div>
                                        <div className="content-count">: {product?.qty}</div>
                                    </div>
                                    <div className="content 1">
                                        <div className="content-title">Confirmed Quantity </div>
                                        <div className="content-count">: {total}</div>
                                    </div>
                                    <div className="content 1">
                                        <div className="content-title">Shortage </div>
                                        <div className="content-count">: {product?.qty - total}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                >
                    <button type="button" className="app-btn grid">
                        <span className="reset-unit-icon">
                            <svg className="svg-icon ao-forward-down-icon">
                                <use xlinkHref="#forwardDownUpIcon"></use>
                            </svg>
                        </span>
                    </button>
                </BSTooltip>
            );

        case 'Surplus':
            return (
                <BSTooltip
                    id={`view-${product.poProductId}`}
                    classList="action-btn-wrapper"
                    position="right"
                    tooltipClassList="surplus-tooltip"
                    title={
                        <>
                            <div className="outer-wrapper-tooltip">
                                <div className="icon-header-holder">
                                    <span className="reset-unit-icon">
                                        <svg className="svg-icon ao-forward-icon">
                                            <use xlinkHref="#forwardUpIcon"></use>
                                        </svg>
                                    </span>
                                    <h1 className="id-name">SURPLUS QUANTITY</h1>
                                </div>
                                <div className="main-content-holder">
                                    <div className="content 1">
                                        <div className="content-title">Ordered Quantity </div>
                                        <div className="content-count">: {product?.qty}</div>
                                    </div>
                                    <div className="content 1">
                                        <div className="content-title">Confirmed Quantity </div>
                                        <div className="content-count">: {total}</div>
                                    </div>
                                    <div className="content 1">
                                        <div className="content-title">Surplus Quantity </div>
                                        <div className="content-count">: {total - product?.qty}</div>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                >
                    <button type="button" className="app-btn grid">
                        <span className="reset-unit-icon">
                            <svg className="svg-icon ao-forward-icon">
                                <use xlinkHref="#forwardUpIcon"></use>
                            </svg>
                        </span>
                    </button>
                </BSTooltip>
            );
        case 'Fulfilled':
            return (
                <BSTooltip
                    id={`view-${product.poProductId}`}
                    classList="action-btn-wrapper"
                    position="right"
                    tooltipClassList="surplus-tooltip"
                    title={
                        <>
                            <div className="outer-wrapper-tooltip">
                                <div className="icon-header-holder">
                                    <span className="reset-unit-icon">
                                        <svg className="svg-icon success-badge-icon">
                                            <use xlinkHref="#successBadgeIcon"></use>
                                        </svg>
                                    </span>
                                    <h1 className="id-name">Fulfilled</h1>
                                </div>
                                <div className="main-content-holder">
                                    <div className="content 1">
                                        <div className="content-title">Ordered Quantity </div>
                                        <div className="content-count">: {product?.qty}</div>
                                    </div>
                                    <div className="content 1">
                                        <div className="content-title">Confirmed Quantity </div>
                                        <div className="content-count">: {total}</div>
                                    </div>
                                    {/* <div className="content 1">
                                        <div className="content-title">Surplus Quantity </div>
                                        <div className="content-count">: {total}</div>
                                    </div> */}
                                </div>
                            </div>
                        </>
                    }
                >
                    <button type="button" className="app-btn grid">
                        <span className="reset-unit-icon">
                            <svg className="svg-icon success-badge-icon">
                                <use href="#successBadgeIcon">
                                    <title>check mark</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </BSTooltip>
            );
        case 'Pending':
            return (
                <button title="Pending" type="button" className="app-btn grid">
                    <span className="reset-unit-icon">
                        <svg className="svg-icon clock-fill-icon">
                            <use xlinkHref="#clockFillIcon"></use>
                        </svg>
                    </span>
                </button>
            );
        case 'Dropped':
            return (
                <button title="Dropped" type="button" className="app-btn grid">
                    <span className="reset-unit-icon">
                        <svg className="svg-icon ao-drop-down-icon">
                            <use xlinkHref="#dropIcon"></use>
                        </svg>
                    </span>
                </button>
            );
    }
};

export const convertFilterName = (filter: string) => {
    switch (filter) {
        case 'Pending_confirmation':
        case 'Pending confirmation':
            return 'Pending Confirmation';

        case 'In_Booking_Proposal':
            return 'In Booking Proposal';
        case 'Booked':
            return 'Booked';
        case 'Confirmed_for_Booking':
            return 'Confirmed for Booking';
        case 'LCL_under_Approval':
            return 'LCL Under Approval';
        case 'Ready_To_Receive':
            return 'Ready to Receive';
        case 'Dropped':
            return 'Dropped';
        case 'Ordered':
            return 'Ordered';
    }
};
