import React, { useEffect, useState } from 'react';
import '../role-new-edit.scss';
import Breadcrumbs from '../../../components/Breadcrumbs';
import { useNavigate } from 'react-router-dom';
import { profileTypes } from '../../../utils/constants';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { createRoleNew, createRoleStart, fetchRoleList, listAllClient } from '../../../store/reducers/rolesReducer';
import CancelModal from '../cancelModal';
import { Position, success } from '../../../utils/toast';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { getOrganisationData } from '../../../utils';
//import roleData from './data.json';

/* eslint no-var: off */
/* eslint-disable no-undefined */

var roleArray2: any = [];
var rolePrivs: any = {};
const listOfType = ['view', 'edit', 'create'];
var arrayofType: any[] = [];

const Index: React.FC = () => {
    const { isLoading, roleData, createSuccess, roleStart, wholeAgent, wholeClients, wholeVendor, wholeShipping } =
        useSelector((state: RootState) => state.rolesReducer);
    rolePrivs = { ...JSON.parse(JSON.stringify(roleData)) };
    const navigate = useNavigate();
    const [Type, setType] = useState(null || '');
    const [typeDisplay, settypeDisplay] = useState(false);
    const [ClientDisplay, setClientDisplay] = useState(false);
    const [Client, setClient] = useState({ id: '', name: '' });
    const [selectedAll, selectAll] = useState(false);
    const [permissions, setpermissions] = useState<any[] | []>([]);
    const [profileNames, setprofileNames] = useState<any[] | []>([]);
    const [_allPermission, setallPermission] = useState(false);
    const [_rowStat, setRowStat] = useState(false);
    const [total, settotal] = useState(0);
    const [bookingcount, setbookingcount] = useState(0);
    const [_totalcheck, settotalcheck] = useState(0);
    const [name, setname] = useState('');
    const [alldata, setalldata] = useState({});
    const [DisplayCancel, setDisplayCancel] = useState(false);
    const [saving, setsaving] = useState(false);
    const [selectedSingle, setselectedSingle] = useState(false);
    const [selectedProfile, setselectedProfile] = useState(false);
    const [currentActive, setcurrentActive] = useState('Admin');
    const { profileType } = useUserProfile();
    const organisationData = getOrganisationData();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(listAllClient({ profileType, data: organisationData }));
        dispatch(
            fetchRoleList({
                profile:
                    profileType === 'forwarder'
                        ? Type
                            ? Type.toLowerCase()
                            : 'forwarder'
                        : profileType === 'shipping'
                        ? 'carrier'
                        : profileType,
            })
        );
    }, [total, Type]);

    useEffect(() => {
        setcurrentActive('Admin');
    }, [Type]);

    useEffect(() => {
        if (createSuccess) {
            navigate(`/rolespermissions/${Type === 'Carrier' ? 'shipping' : Type.toLowerCase()}`);
            setsaving(false);
            success(`${name} Created Successfully`, Position.TOP_RIGHT);
        }
    }, [createSuccess, roleStart]);

    useEffect(() => {
        var newArrVal = [];
        var checkingNew = [];
        newArrVal = (roleData as any)?.Admin?.Profiles;
        if (newArrVal) {
            for (var i = 0; i < newArrVal?.length - 1; i += 2) {
                var checkArry = [];
                if (i < newArrVal.length - 5) {
                    for (var j = i; j < i + 2; j++) {
                        checkArry.push(newArrVal[j]);
                    }
                } else {
                    for (var k = newArrVal.length - 5; k < newArrVal.length; k++) {
                        checkArry.push(newArrVal[k]);
                    }
                }
                // eslint-disable-next-line no-undefined
                checkArry = checkArry.filter((a: any) => a !== undefined);
                checkingNew.push(checkArry);
            }
            if (Type?.toLowerCase() === 'forwarder' || (profileType === 'forwarder' && Type?.toLowerCase() === '')) {
                checkingNew.pop();
            }
            rolePrivs.Admin.Profiles = [...checkingNew];
            (Object.keys((rolePrivs as any)['Admin' as unknown as number]) as any).forEach((element: any) => {
                if (element === 'Settings') {
                    roleArray2.push((rolePrivs as any).Admin[element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs.Admin = { ...rolePrivs.Admin, ...newvar };
                    roleArray2 = [];
                } else if (element !== 'Profiles') {
                    if ((rolePrivs as any).Admin[element]?.length !== 1) {
                        roleArray2.push((rolePrivs as any).Admin[element]);
                        var newvar: any = {};
                        newvar[element] = roleArray2;
                        rolePrivs.Admin = { ...rolePrivs.Admin, ...newvar };
                        roleArray2 = [];
                    }
                }
            });
            (Object.keys((rolePrivs as any)['Purchase Order' as unknown as number]) as any).forEach((element: any) => {
                if (element !== 'Profiles') {
                    roleArray2.push((rolePrivs as any)['Purchase Order'][element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs['Purchase Order'] = { ...(rolePrivs as any)['Purchase Order'], ...newvar };
                    roleArray2 = [];
                }
            });
            (Object.keys((rolePrivs as any)['BLP' as unknown as number]) as any).forEach((element: any) => {
                if (element !== 'Profiles') {
                    roleArray2.push((rolePrivs as any)['BLP'][element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs['BLP'] = { ...(rolePrivs as any)['BLP'], ...newvar };
                    roleArray2 = [];
                }
            });
            (Object.keys((rolePrivs as any)['Quotes' as unknown as number]) as any).forEach((element: any) => {
                if (element !== 'Profiles') {
                    roleArray2.push((rolePrivs as any)['Quotes'][element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs['Quotes'] = { ...(rolePrivs as any)['Quotes'], ...newvar };
                    roleArray2 = [];
                }
            });
        }
        setalldata(rolePrivs);
    }, [dispatch, roleData]);

    useEffect(() => {
        var totalPuchase = 0;
        Object.keys(alldata).length !== 0 &&
            (Object.keys((alldata as any)[currentActive as unknown as number]) as any).forEach((element: any) => {
                ((alldata as any)[currentActive as unknown as number] as any)[element].forEach((elementNew: any) => {
                    if (element !== 'Settings') {
                        elementNew.forEach((element2: any) => {
                            if (element2.type.toLowerCase() === 'purchase order') {
                                totalPuchase++;
                            }
                        });
                    }
                });
            });
        //settotal(total);
        settotalcheck(totalPuchase);
    }, [permissions, dispatch, roleData, createSuccess, alldata]);
    useEffect(() => {
        var total = 0;
        Object.keys(alldata).length !== 0 &&
            (Object.keys((alldata as any)['Admin']) as any).forEach((element: any) => {
                ((alldata as any)['Admin'] as any)[element].forEach((elementNew: any) => {
                    // eslint-disable-next-line no-undefined
                    if (elementNew[0].type !== undefined) {
                        elementNew.forEach((element2: any) => {
                            if (element2.permissionTypeName.includes('Admin')) {
                                total++;
                            }
                        });
                    }
                });
            });
        settotal(total);
    }, [alldata]);

    const checkingArray = (valueVal: any) => {
        var variableBlah = false;

        for (var i = 0; i < permissions.length; i++) {
            if (permissions[i]?.permissionId === valueVal?.permissionId) {
                variableBlah = true;
            }
        }
        return variableBlah;
    };

    const setallPermissions = () => {
        var newObj: any[] = [];
        var newArry: any[] = [];

        if (currentActive === 'Admin') {
            (Object.keys((alldata as any)[currentActive as unknown as number]) as any).forEach((element: any) => {
                ((alldata as any)[currentActive as unknown as number] as any)[element].forEach((elementNew: any) => {
                    elementNew.forEach((element2: any) => {
                        newObj.push(element2);
                    });
                });
            });
            newArry = [...permissions, ...newObj];
            var filterArray = newArry.filter(
                (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.permissionId === v.permissionId) === i
            );
            setpermissions(filterArray);
        } else {
            (Object.keys((alldata as any)[currentActive as unknown as number]) as any).forEach((element: any) => {
                ((alldata as any)[currentActive as unknown as number] as any)[element].forEach((elementNew: any) => {
                    elementNew.forEach((element2: any) => {
                        if (element2.toLowerCase() === 'purchase order') {
                            newObj.push(element2);
                        }
                    });
                });
            });
            (Object.keys((alldata as any)[currentActive as unknown as number]) as any).forEach((element: any) => {
                ((alldata as any)[currentActive as unknown as number] as any)[element].forEach((elementNew: any) => {
                    elementNew.forEach((element2: any) => {
                        if (element2.includes('BLP')) {
                            newObj.push(element2);
                        }
                    });
                });
            });
            (Object.keys((alldata as any)[currentActive as unknown as number]) as any).forEach((element: any) => {
                ((alldata as any)[currentActive as unknown as number] as any)[element].forEach((elementNew: any) => {
                    elementNew.forEach((element2: any) => {
                        if (element2.includes('Quotes')) {
                            newObj.push(element2);
                        }
                    });
                });
            });
            newArry = [...permissions, ...newObj];
            var filterArray = newArry.filter(
                (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.permissionId === v.permissionId) === i
            );
            setpermissions(newArry);
        }
    };

    const setRowChecked = (value: any, stat: any, data: any) => {
        var newAr: any[] = [];
        var newObj: any[] = [];

        if (stat) {
            newAr.push(value);
            (Object.keys((alldata as any)[currentActive as unknown as number]) as any).forEach((element: any) => {
                if (value.toLowerCase() === 'purchase order') {
                    ((alldata as any)?.['BLP'] as any)?.['Inbound Purchase Order']?.forEach((elementNew: any) => {
                        elementNew.forEach((element2: any) => {
                            if (permissions?.length === 0) {
                                newObj.push(element2);
                            } else {
                                for (var i = 0; i < permissions?.length; i++) {
                                    if (permissions[i].permissionId !== element2?.permissionId) {
                                        newObj.push(element2);
                                    }
                                }
                            }
                        });
                    });
                }
                ((alldata as any)[currentActive as unknown as number] as any)[element].forEach((elementNew: any) => {
                    elementNew.forEach((element2: any) => {
                        if (newAr.includes(element2?.type)) {
                            if (permissions?.length === 0) {
                                newObj.push(element2);
                            } else {
                                for (var i = 0; i < permissions?.length; i++) {
                                    if (permissions[i].permissionId !== element2?.permissionId) {
                                        newObj.push(element2);
                                    }
                                }
                            }
                        }
                    });
                });
            });
            var array2 = [...data, ...newObj];
            var array3 = array2.filter(
                (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.permissionId === v.permissionId) === i
            );
            setpermissions(array3);
        } else {
            var newCheck = [...data];
            var checker = newCheck.filter((item) => item.type !== value);
            setpermissions([...checker]);
        }
    };

    const createRole = () => {
        const payload = {
            roleName: name,
            profileType: Type === 'Carrier' ? 'Shipping' : Type,
            forAllOrgs: Type?.toLowerCase() === 'forwarder' ? null : selectedAll ? 1 : 0,
            orgId: Type?.toLowerCase() === 'forwarder' ? '' : selectedAll ? '' : Client?.id,
            permissions: permissions,
        };
        setsaving(true);
        if (name.length !== 0 && permissions.length !== 0 && Type !== '') {
            if (Type?.toUpperCase() !== 'FORWARDER' && Client.id === '' && !selectedAll) {
                // console.log('dafs');
            } else {
                dispatch(createRoleNew(payload));
            }
        }
    };

    const onConfirm = () => {
        navigate(-1);
        setDisplayCancel(false);
        setsaving(false);
    };
    const onCancelModal = () => {
        setDisplayCancel(false);
    };

    const setPurchaseCount = () => {
        var flag = 0;
        permissions.forEach((element) => {
            if (element.type.toLowerCase() === 'purchase order') {
                flag++;
            }
        });
        permissions.forEach((element) => {
            if (element.type.toLowerCase().includes('booking')) {
                flag++;
            }
        });
        return flag;
    };
    const setBlpCount = () => {
        var flag = 0;
        permissions.forEach((element) => {
            if (element.type.toLowerCase() === 'allocation order') {
                flag++;
            }
        });
        permissions.forEach((element) => {
            if (element.type.toLowerCase().includes('inbound purchase order')) {
                flag++;
            }
        });
        permissions.forEach((element) => {
            if (element.type.toLowerCase().includes('receipt')) {
                flag++;
            }
        });
        return flag;
    };
    const setQuotesCount = () => {
        var flag = 0;
        permissions.forEach((element) => {
            if (element.type.toLowerCase() === 'buy rates') {
                flag++;
            }
        });
        permissions.forEach((element) => {
            if (element.type.toLowerCase() === 'client quotes') {
                flag++;
            }
        });
        permissions.forEach((element) => {
            if (element.type.toLowerCase() === 'configuration') {
                flag++;
            }
        });
        return flag;
    };
    const CancelFunc = () => {
        if (permissions.length !== 0 || Type !== '' || Client.id !== '' || name.length !== 0) {
            setDisplayCancel(true);
        } else {
            navigate(-1);
            setsaving(false);
        }
    };

    const rowCheck = (arr: any, key: any) => {
        var start = 0;
        var rowArry: any = [];
        for (var i = 0; i < permissions.length; i++) {
            if (permissions[i].type === key) {
                start++;
            }
        }
        arr?.forEach((element: any) => {
            // eslint-disable-next-line no-undefined
            if (element.length !== undefined) {
                element?.forEach((_elementnew: any) => {
                    rowArry.push(element);
                });
            } else {
                rowArry.push(element);
            }
        });

        if (start === rowArry.length) {
            return true;
        } else {
            return false;
        }
    };

    const disableView = (val: any) => {
        var flag = false;
        if (Array.isArray(val)) {
            permissions.forEach((element) => {
                if (
                    element.access.toLowerCase() ===
                        `edit ${val[0].access.toLowerCase().substr(val[0].access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `create ${val[0].access.toLowerCase().substr(val[0].access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `enrich ${val[0].access.toLowerCase().substr(val[0].access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `approve ${val[0].access.toLowerCase().substr(val[0].access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `reopen ${val[0].access.toLowerCase().substr(val[0].access.toLowerCase().indexOf(' ') + 1)}`
                ) {
                    flag = true;
                }
            });
        } else if (val?.access?.toLowerCase().includes('view')) {
            permissions.forEach((element) => {
                if (
                    element.access.toLowerCase() ===
                        `edit ${val.access.toLowerCase().substr(val.access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `create ${val.access.toLowerCase().substr(val.access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `enrich ${val.access.toLowerCase().substr(val.access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `approve ${val.access.toLowerCase().substr(val.access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `reopen ${val.access.toLowerCase().substr(val.access.toLowerCase().indexOf(' ') + 1)}`
                ) {
                    flag = true;
                }
            });
        }
        return flag;
    };
    useEffect(() => {
        var booking = 0;

        permissions.forEach((element) => {
            if (element.permissionTypeName.includes('Admin')) {
                booking++;
            }
        });
        setbookingcount(booking);
    }, [permissions]);

    const totalCheck1 = () => {
        var adminTotal = 0;
        permissions.forEach((element) => {
            if (element.permissionTypeName === 'Admin') {
                adminTotal++;
            }
        });
        var flag = false;
        if (total === adminTotal && permissions.length !== 0) {
            flag = true;
        }
        return flag;
    };

    const getElement = (value: string, name: string) => {
        return (
            <a
                className="menu-item app-btn"
                onClick={(e) => {
                    e.stopPropagation();
                    setType(value);
                    settypeDisplay(false);
                    selectAll(false);
                    setClient({ id: '', name: '' });
                    if (value === 'Vendor') {
                        setprofileNames(wholeVendor);
                    } else if (value === 'Agent') {
                        setprofileNames(wholeAgent);
                    } else if (value === 'Shipping') {
                        setprofileNames(wholeShipping);
                    } else {
                        setprofileNames(wholeClients);
                    }
                }}
            >
                {name}
            </a>
        );
    };

    const checkAuto = (param1: any, param2: any, param3: any) => {
        if (param1) {
            var newObj = [
                ...permissions,
                {
                    ...param2,
                },
            ];
            const BlpRoles =
                (alldata as any)['BLP']['Inbound Purchase Order'] !== undefined &&
                (alldata as any)['BLP']['Inbound Purchase Order'][0];
            newObj.forEach((element) => {
                if (
                    element.permissionTypeName.toLowerCase() === 'purchase order' &&
                    param2.permissionTypeName.toLowerCase() === 'purchase order'
                ) {
                    //BlpRoles.forEach((blpVal: any) => {
                    if (
                        element.access.toLowerCase() ===
                            `edit ${param2.access
                                .toLowerCase()
                                .substr(param2.access.toLowerCase().indexOf(' ') + 1)}` ||
                        element.access.toLowerCase() ===
                            `create ${param2.access.toLowerCase().substr(param2.access.toLowerCase().indexOf(' ') + 1)}`
                    ) {
                        BlpRoles &&
                            BlpRoles?.forEach((element1: any) => {
                                listOfType.forEach((lists: any) => {
                                    if (element1.access.toLowerCase().replace(/ /g, '').includes(lists)) {
                                        newObj = [
                                            ...newObj,
                                            {
                                                ...element1,
                                            },
                                        ];
                                    }
                                });
                            });
                    } else {
                        BlpRoles &&
                            BlpRoles?.forEach((element1: any) => {
                                if (element1.access.toLowerCase().replace(/ /g, '').includes('view')) {
                                    newObj = [
                                        ...newObj,
                                        {
                                            ...element1,
                                        },
                                    ];
                                }
                            });
                    }
                    //});
                }
                if (
                    element.access.toLowerCase() ===
                        `edit ${param2.access.toLowerCase().substr(param2.access.toLowerCase().indexOf(' ') + 1)}` ||
                    element.access.toLowerCase() ===
                        `create ${param2.access.toLowerCase().substr(param2.access.toLowerCase().indexOf(' ') + 1)}`
                ) {
                    param3.forEach((element1: any) => {
                        listOfType.forEach((lists: any) => {
                            if (
                                element1.access
                                    .toLowerCase()
                                    .replace(/ /g, '')
                                    .includes(
                                        `${element.type.toLowerCase() !== 'receipt' ? lists : 'view'} ${param2.access
                                            .toLowerCase()
                                            .substr(param2.access.toLowerCase().indexOf(' ') + 1)}`.replace(/ /g, '')
                                    )
                            ) {
                                newObj = [
                                    ...newObj,
                                    {
                                        ...element1,
                                    },
                                ];
                            }
                        });
                    });
                }
            });

            const itemsToView = ['enrich', 'approve', 'reopen'];

            itemsToView.forEach((item: any) => {
                newObj.forEach((element) => {
                    if (
                        element.permissionTypeName.toLowerCase() === 'purchase order' &&
                        param2.permissionTypeName.toLowerCase() === 'purchase order'
                    ) {
                        if (element.access.toLowerCase().includes(item)) {
                            BlpRoles &&
                                BlpRoles?.forEach((element1: any) => {
                                    if (element1.access.toLowerCase().replace(/ /g, '').includes('view')) {
                                        newObj = [
                                            ...newObj,
                                            {
                                                ...element1,
                                            },
                                        ];
                                    }
                                    if (element1.access.toLowerCase().replace(/ /g, '').includes(item)) {
                                        newObj = [
                                            ...newObj,
                                            {
                                                ...element1,
                                            },
                                        ];
                                    }
                                });
                        }
                    }
                    if (
                        element.access.toLowerCase() ===
                        `${item} ${param2.access.toLowerCase().substr(param2.access.toLowerCase().indexOf(' ') + 1)}`
                    ) {
                        param3.forEach((element1: any) => {
                            if (
                                element1.access
                                    .toLowerCase()
                                    .replace(/ /g, '')
                                    .includes(
                                        `view ${param2.access
                                            .toLowerCase()
                                            .substr(param2.access.toLowerCase().indexOf(' ') + 1)}`.replace(/ /g, '')
                                    )
                            ) {
                                newObj = [
                                    ...newObj,
                                    {
                                        ...element1,
                                    },
                                ];
                            }
                        });
                    }
                });
            });
            var array3 = newObj.filter(
                (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.permissionId === v.permissionId) === i
            );
            setpermissions(array3);
        } else {
            var toDel = [...permissions];
            var index2 = permissions
                .map((x: any) => {
                    return x.permissionId;
                })
                .indexOf(param2.permissionId);

            if (
                permissions[index2].access.toLowerCase().replace(/ /g, '').includes('edit') &&
                permissions[index2].type.toLowerCase() !== 'receipt'
            ) {
                for (var j = 0; j < permissions.length; j++) {
                    if (
                        permissions[j].access.toLowerCase().replace(/ /g, '').includes('create') &&
                        permissions[j].access.toLowerCase().replace(/ /g, '') ===
                            `create ${param2.access
                                .toLowerCase()
                                .substr(param2.access.toLowerCase().indexOf(' ') + 1)}`.replace(/ /g, '')
                        //			||
                        //(permissions[j].access.toLowerCase().replace(/ /g, '').includes('view') &&
                        //    permissions[j].access.toLowerCase().replace(/ /g, '') ===
                        //        `view ${param2.access
                        //            .toLowerCase()
                        //            .substr(param2.access.toLowerCase().indexOf(' ') + 1)}`.replace(/ /g, ''))
                    ) {
                        toDel = toDel.filter((val: any) => permissions[j].permissionId !== val.permissionId);
                    }
                }
            } else if (
                permissions[index2].access.toLowerCase().replace(/ /g, '').includes('create') &&
                permissions[index2].type.toLowerCase() !== 'receipt'
            ) {
                for (var j = 0; j < permissions.length; j++) {
                    if (
                        permissions[j].access.toLowerCase().replace(/ /g, '').includes('edit') &&
                        permissions[j].access.toLowerCase().replace(/ /g, '') ===
                            `edit ${param2.access
                                .toLowerCase()
                                .substr(param2.access.toLowerCase().indexOf(' ') + 1)}`.replace(/ /g, '')
                        // ||
                        //(permissions[j].access.toLowerCase().replace(/ /g, '').includes('view') &&
                        //    permissions[j].access.toLowerCase().replace(/ /g, '') ===
                        //        `view ${param2.access
                        //            .toLowerCase()
                        //            .substr(param2.access.toLowerCase().indexOf(' ') + 1)}`.replace(/ /g, ''))
                    ) {
                        toDel = toDel.filter((val: any) => permissions[j].permissionId !== val.permissionId);
                    }
                }
            }

            toDel = toDel.filter((val: any) => permissions[index2].permissionId !== val.permissionId);
            setpermissions(toDel);
            setallPermission(false);
        }
    };

    return (
        <div
            className="main-wrapper"
            onClick={() => {
                settypeDisplay(false);
                setClientDisplay(false);
                if (selectedSingle) {
                    setClient({ name: '', id: '' });
                }
            }}
        >
            <div className="main-header-content-holder grid-pages">
                <Breadcrumbs second={'Roles & Permissions'} third={'Add New Role'} />
                <div className="pageheading-holder">
                    <button className="back-btn app-btn" onClick={() => CancelFunc()}>
                        <svg className="svg-icon back-icon">
                            <use xlinkHref="#previousArrowIcon">
                                <title>Back</title>
                            </use>
                        </svg>
                    </button>
                    <h2 className="page-heading">Add New Role</h2>
                </div>
            </div>
            {isLoading && (
                <div className="app-loader loading">
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
            )}
            <div className="main-content-holder">
                <div className="content-section main-wrapper addnewrole">
                    <div className="card-details">
                        <div className="card-details-box">
                            <div className="details-col search-entry-holder">
                                <label className="label">
                                    Role Name<span className="mandatory-field-text">*</span>
                                </label>
                                <input
                                    placeholder="Enter Role Name"
                                    type="text"
                                    className="search-input input-text"
                                    onChange={(e) => {
                                        setname(e.target.value);
                                        dispatch(createRoleStart(false));
                                    }}
                                ></input>
                                {!createSuccess && roleStart && name && (
                                    <span className="error-text">* Name already exists</span>
                                )}
                                {!name && saving && <span className="error-text">* Role name is required</span>}
                            </div>
                            <div className="details-col search-entry-holder">
                                <label className="label">
                                    Profile Type<span className="mandatory-field-text">*</span>
                                </label>
                                <div
                                    className={
                                        typeDisplay
                                            ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                            : 'dropdown-wrap  menu-down modal-dropdown'
                                    }
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    <button
                                        className="menu-btn app-btn"
                                        type="button"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            settypeDisplay(!typeDisplay);
                                        }}
                                    >
                                        <span className="btn-text">{Type ? Type : 'Select Profile Type'}</span>
                                        <span className="dropdown-arrow">
                                            <svg className="svg-icon arrow-icon">
                                                <use xlinkHref="#downArrow">
                                                    <title>dropdown</title>
                                                </use>
                                            </svg>
                                        </span>
                                    </button>

                                    <div className="dropdown-menu">
                                        {profileType === 'forwarder' &&
                                            profileTypes.map((val) => (
                                                <a
                                                    className="menu-item app-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setType(val.name);
                                                        settypeDisplay(false);
                                                        selectAll(false);
                                                        setClient({ id: '', name: '' });
                                                        if (val.value === 'Vendor') {
                                                            setprofileNames(wholeVendor);
                                                        } else if (val.value === 'Agent') {
                                                            setprofileNames(wholeAgent);
                                                        } else if (val.value === 'Shipping') {
                                                            setprofileNames(wholeShipping);
                                                        } else {
                                                            setprofileNames(wholeClients);
                                                        }
                                                    }}
                                                >
                                                    {val.name}
                                                </a>
                                            ))}
                                        {profileType === 'client' && getElement('Client', 'Client')}
                                        {profileType === 'agent' && getElement('Agent', 'Agent')}
                                        {profileType === 'vendor' && getElement('Vendor', 'Vendor')}
                                        {profileType === 'shipping' && getElement('Shipping', 'Shipping Line')}
                                    </div>
                                </div>
                                {!Type && saving && <span className="error-text">* Profile type is required</span>}
                            </div>

                            {Type?.toLowerCase() !== 'forwarder' && Type !== '' && (
                                <div className="details-col search-entry-holder">
                                    <label className="label">
                                        {Type} {Type.toLowerCase() === 'shipping' && 'Line'} Name
                                        <span className="mandatory-field-text">*</span>
                                    </label>
                                    <div
                                        className={
                                            ClientDisplay
                                                ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                : 'dropdown-wrap  menu-down modal-dropdown'
                                        }
                                        onClick={(e) => e.stopPropagation()}
                                    >
                                        <input
                                            placeholder={`Enter ${Type} ${
                                                Type === 'Shipping' ? 'Line' : ''
                                            } Name to tag`}
                                            type="text"
                                            className="search-input input-text with-border"
                                            value={
                                                Client?.name === '' && Client?.name
                                                    ? ''
                                                    : selectedAll
                                                    ? 'All'
                                                    : Client?.name
                                            }
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setClientDisplay(!ClientDisplay);
                                            }}
                                            readOnly={selectedProfile}
                                            disabled={selectedAll}
                                            onChange={(e) => {
                                                setselectedSingle(true);
                                                setClient({ ...Client, name: e.target.value });
                                            }}
                                        ></input>
                                        {Client.name.length !== 0 && (
                                            <button
                                                className="app-btn text-close-btn"
                                                onClick={() => {
                                                    setClient({ id: '', name: '' });
                                                    setselectedProfile(false);
                                                }}
                                            >
                                                <svg className="svg-icon text-close-icon">
                                                    <use xlinkHref="#closeIcon"></use>
                                                </svg>
                                            </button>
                                        )}

                                        <div className="dropdown-menu client">
                                            <div className="customscrollbar">
                                                {profileNames
                                                    .filter((val: any) =>
                                                        val?.orgName.toLowerCase().includes(Client?.name?.toLowerCase())
                                                    )
                                                    .map((res: any) => (
                                                        <a
                                                            className="menu-item app-btn"
                                                            onClick={(e) => {
                                                                setselectedProfile(true);
                                                                setselectedSingle(false);
                                                                e.stopPropagation();
                                                                setClient({ id: res?.or_PK, name: res?.orgName });
                                                                setClientDisplay(false);
                                                            }}
                                                        >
                                                            <span className="main-content">{res?.orgName}</span>
                                                        </a>
                                                    ))}
                                            </div>
                                        </div>

                                        {/* <div className="no-data-content">
                                                <svg className="svg-icon grid-no-content-icon ">
                                                    <use xlinkHref="#gridNoContentIcon"></use>
                                                </svg>
                                                <p className="no-content-message">No Matching Records Found</p>
                                    </div> */}
                                    </div>
                                    {!selectedAll && Client.id === '' && saving && (
                                        <span className="error-text">
                                            * {Type} {Type.toLowerCase() === 'shipping' && 'Line'} name is required
                                        </span>
                                    )}
                                    <div className="checkbox-wrapper">
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    checked={selectedAll}
                                                    onChange={(e) => selectAll(e.currentTarget.checked)}
                                                />
                                                <div className="checkmark">
                                                    <svg className="svg-icon tick-icon">
                                                        <use xlinkHref="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">
                                                    Select All {Type}
                                                    {Type.toLowerCase() !== 'shipping' && 's'}
                                                    {Type.toLowerCase() === ' shipping' && 'Lines'}
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="tabcontent">
                        <h6 className="title">Permissions</h6>
                        <div className="tab-nav-holder">
                            <a
                                className={`tab-nav ${currentActive === 'Admin' ? 'active' : ''}`}
                                href="javascript:void(0)"
                                onClick={() => setcurrentActive('Admin')}
                            >
                                Admin <span className="count">{bookingcount}</span>
                            </a>
                            {(alldata as any)['Purchase Order'] !== undefined &&
                                Object.keys((alldata as any)['Purchase Order'] as any)?.length > 0 && (
                                    <a
                                        className={`tab-nav ${currentActive === 'Purchase Order' ? 'active' : ''}`}
                                        href="javascript:void(0)"
                                        onClick={() => setcurrentActive('Purchase Order')}
                                    >
                                        Purchase Order & BP<span className="count">{setPurchaseCount()}</span>
                                    </a>
                                )}
                            {(alldata as any)['BLP'] !== undefined &&
                                Object.keys((alldata as any)['BLP'] as any)?.length > 0 && (
                                    <a
                                        className={`tab-nav ${currentActive === 'BLP' ? 'active' : ''}`}
                                        href="javascript:void(0)"
                                        onClick={() => setcurrentActive('BLP')}
                                    >
                                        BLP<span className="count">{setBlpCount()}</span>
                                    </a>
                                )}
                            {(Type?.toLowerCase() === 'forwarder' || Type === '') && (
                                <>
                                    <a className="tab-nav " href="javascript:void(0)">
                                        Tracking
                                    </a>
                                    <a className="tab-nav" href="javascript:void(0)">
                                        Reports
                                    </a>
                                </>
                            )}
                            {(alldata as any)['Quotes'] !== undefined &&
                                Object.keys((alldata as any)['Quotes'] as any)?.length > 0 && (
                                    <a
                                        className={`tab-nav ${currentActive === 'Quotes' ? 'active' : ''}`}
                                        href="javascript:void(0)"
                                        onClick={() => setcurrentActive('Quotes')}
                                    >
                                        Quotes<span className="count">{setQuotesCount()}</span>
                                    </a>
                                )}
                        </div>
                    </div>

                    <div className="grid-section add-roles-grid">
                        <div className="grid-holder">
                            <div className="grid-header">
                                {currentActive === 'Admin' ? (
                                    <div className="checkbox-wrapper">
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    disabled={name == '' || Type === ''}
                                                    onChange={(e) => {
                                                        setallPermission(e.currentTarget.checked);
                                                        if (e.currentTarget.checked) {
                                                            setallPermissions();
                                                        } else {
                                                            var purchaseArray: any[] = [];
                                                            permissions.forEach((element) => {
                                                                if (element.type.toLowerCase() === 'purchase order') {
                                                                    purchaseArray.push(element);
                                                                }
                                                            });
                                                            permissions.forEach((element) => {
                                                                if (element.type.toLowerCase().includes('booking')) {
                                                                    purchaseArray.push(element);
                                                                }
                                                            });
                                                            permissions.forEach((element) => {
                                                                if (element.type.toLowerCase() === 'allocation order') {
                                                                    purchaseArray.push(element);
                                                                }
                                                            });
                                                            permissions.forEach((element) => {
                                                                if (
                                                                    element.type.toLowerCase() ===
                                                                    'inbound purchase order'
                                                                ) {
                                                                    purchaseArray.push(element);
                                                                }
                                                            });
                                                            permissions.forEach((element) => {
                                                                if (element.type.toLowerCase() === 'receipt') {
                                                                    purchaseArray.push(element);
                                                                }
                                                            });
                                                            setpermissions(purchaseArray);
                                                        }
                                                    }}
                                                    checked={totalCheck1()}
                                                />
                                                <div className="checkmark">
                                                    <svg className="svg-icon tick-icon">
                                                        <use xlinkHref="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>

                                                <div className="checkbox-label">All Admin Permissions</div>
                                            </label>
                                        </div>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                                {permissions.length === 0 && saving && (
                                    <span className="error-text">* Permissions are required</span>
                                )}
                            </div>
                            <div className="grid-container">
                                <div className="grid-scroll-content">
                                    <table className="grid-table">
                                        <tbody>
                                            {Object.keys(alldata).length !== 0 &&
                                                (
                                                    Object.keys(
                                                        (alldata as any)[currentActive as unknown as number]
                                                    ) as any
                                                ).map((res1: any) => (
                                                    <tr className="has-child row-expanded">
                                                        <td
                                                            className="grid-cell grid-cell-profile"
                                                            style={{ width: '20%' }}
                                                        >
                                                            <div className="grid-cell-data">
                                                                <div className="checkbox-wrapper">
                                                                    <div className="checkbox-item">
                                                                        <label className="app-check-wrapper">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="checkbox-input"
                                                                                disabled={name == '' || Type === ''}
                                                                                checked={rowCheck(
                                                                                    (
                                                                                        (alldata as any)[
                                                                                            currentActive as unknown as number
                                                                                        ] as any
                                                                                    )[res1],
                                                                                    res1
                                                                                )}
                                                                                onChange={(e) => {
                                                                                    if (e.currentTarget.checked) {
                                                                                        setRowChecked(
                                                                                            res1,
                                                                                            true,
                                                                                            permissions
                                                                                        );
                                                                                        setRowStat(true);
                                                                                        arrayofType.push(res1);
                                                                                    } else {
                                                                                        setRowChecked(
                                                                                            res1,
                                                                                            false,
                                                                                            permissions
                                                                                        );
                                                                                        setRowStat(false);
                                                                                        var index2 = arrayofType
                                                                                            .map((x: any) => {
                                                                                                return x;
                                                                                            })
                                                                                            .indexOf(res1);
                                                                                        arrayofType.splice(index2, 1);
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <div className="checkmark">
                                                                                <svg className="svg-icon tick-icon">
                                                                                    <use xlinkHref="#tickIcon">
                                                                                        <title>check mark</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                            <div
                                                                                className="checkbox-label"
                                                                                style={{ wordBreak: 'keep-all' }}
                                                                            >
                                                                                {res1}
                                                                            </div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="grid-cell"
                                                            style={{ display: 'flex', flexWrap: 'wrap' }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <div className="grid-cell-data">
                                                                    {true &&
                                                                        Object.keys(alldata)?.length !== 0 &&
                                                                        (
                                                                            (alldata as any)[
                                                                                currentActive as unknown as number
                                                                            ] as any
                                                                        )[res1]?.map((val: any, i: any) => (
                                                                            <div
                                                                                className="checkbox-wrapper"
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexWrap: 'wrap',
                                                                                    padding: '0px',
                                                                                }}
                                                                            >
                                                                                {Object.keys(alldata).length !== 0 &&
                                                                                    (
                                                                                        (alldata as any)[
                                                                                            currentActive as unknown as number
                                                                                        ] as any
                                                                                    )[res1][i].map((newVal: any) => (
                                                                                        <div
                                                                                            className="checkbox-item"
                                                                                            style={{
                                                                                                width: '30%',
                                                                                                padding: '32px 0px',
                                                                                            }}
                                                                                        >
                                                                                            <label className="app-check-wrapper">
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="checkbox-input"
                                                                                                    checked={checkingArray(
                                                                                                        newVal
                                                                                                    )}
                                                                                                    //disabled={arrayofType.includes(
                                                                                                    //    key
                                                                                                    //)}
                                                                                                    disabled={
                                                                                                        disableView(
                                                                                                            newVal
                                                                                                        ) ||
                                                                                                        name == '' ||
                                                                                                        Type === ''
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        checkAuto(
                                                                                                            e
                                                                                                                .currentTarget
                                                                                                                .checked,
                                                                                                            newVal,
                                                                                                            (
                                                                                                                (
                                                                                                                    alldata as any
                                                                                                                )[
                                                                                                                    currentActive as unknown as number
                                                                                                                ] as any
                                                                                                            )[res1][i]
                                                                                                        );
                                                                                                    }}
                                                                                                />
                                                                                                <div className="checkmark">
                                                                                                    <svg className="svg-icon tick-icon">
                                                                                                        <use xlinkHref="#tickIcon">
                                                                                                            <title>
                                                                                                                check
                                                                                                                mark
                                                                                                            </title>
                                                                                                        </use>
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div className="checkbox-label">
                                                                                                    {newVal.access
                                                                                                        ?.toLowerCase()
                                                                                                        ?.includes(
                                                                                                            'shipping'
                                                                                                        )
                                                                                                        ? newVal.access
                                                                                                              ?.toLowerCase()
                                                                                                              ?.includes(
                                                                                                                  'view'
                                                                                                              )
                                                                                                            ? 'View Carrier Profile'
                                                                                                            : 'Edit Carrier Profile'
                                                                                                        : newVal.access}
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    {/*<button className="app-btn app-btn-secondary footer-btn disabled" title="Previous">
                            <span className="button-text footer-button-text">Previous</span>
                        </button>*/}

                    <button
                        className="app-btn app-btn-secondary footer-btn"
                        title="Cancel"
                        onClick={() => CancelFunc()}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>

                    <button
                        className="app-btn app-btn-primary footer-btn"
                        title="Add New Role"
                        onClick={() => createRole()}
                    >
                        <span className="button-text footer-button-text">Add New Role</span>
                    </button>
                    {DisplayCancel && <CancelModal onConfirm={onConfirm} onCancel={onCancelModal} type="create" />}

                    {/* <NavLink to="/profile/clients/vendor_details" className="app-btn app-btn-primary">
                            <span className="button-text vendor-button-text">Next</span>
                        </NavLink> */}
                </div>
            </div>
        </div>
    );
};

export default Index;
