import { createSlice } from '@reduxjs/toolkit';

interface trackingType {
    searchList: any;
    isSearching: boolean;
    isError: boolean;
    bookingTimeline: any;
    bookingTimelineMbl: any;
    isLoading: boolean;
    bookingBasicDetails: any;
    bookingBasicDetailsMbl: any;
    portsTimeline: any;
    wareHouseDetails: any;
    clientBasicDeatils: any;
    clientTimeLine: any;
    agentTimeLine: any;
    vendorDetails: any;
    selectedData: any;
}

const initialState: trackingType = {
    isSearching: false,
    searchList: {},
    isError: false,
    bookingTimeline: {},
    bookingTimelineMbl: {},
    isLoading: false,
    bookingBasicDetails: {},
    bookingBasicDetailsMbl: {},
    portsTimeline: {},
    wareHouseDetails: {},
    clientBasicDeatils: {},
    clientTimeLine: {},
    agentTimeLine: {},
    vendorDetails: {},
    selectedData: {},
};

export const lifecyclesearchSlice = createSlice({
    name: 'lifecyclesearch',
    initialState,
    reducers: {
        isLoadingFunction: (state, action) => {
            state.isLoading = action.payload;
        },
        onLifeCycleSearch: (state, _payload) => {
            state.isSearching = true;
            state.isError = false;
        },
        clearLifeCycleSearch: (state) => {
            state.searchList = {};

            state.isSearching = false;
            state.isError = false;
        },
        onLifeCycleSearchSuccess: (state, action) => {
            state.isError = false;
            const temp = action.payload
                .filter((el: any) => el?.status === 'fulfilled')
                .reduce((prev: any, current: any) => ({ ...prev, ...current.value }), {});
            // if (!action?.payload?.error) {
            // state.searchList = action.payload?.reduce((prev: any, current: any) => {
            //     return { ...prev, ...current.value };
            // }, {});

            state.searchList = Object.keys(temp)?.length ? temp : '';
            state.isSearching = Object.keys(temp)?.length ? false : true;
        },
        onLifeCycleSearchFailure: (state, _action) => {
            state.isSearching = false;
            // state.searchList = {};
        },
        bookingTimeLineFetch: (state, action) => {
            state.bookingTimeline = action.payload;
        },
        bookingTimeLineFetchMbl: (state, action) => {
            state.bookingTimelineMbl = action.payload;
        },
        bookingBasicDetailsFetch: (state, action) => {
            state.bookingBasicDetails = action.payload;
        },
        bookingBasicDetailsFetchMbl: (state, action) => {
            state.bookingBasicDetailsMbl = action.payload;
        },
        portTimeLineFetch: (state, action) => {
            state.portsTimeline = action.payload;
        },
        clientTimeLineFetch: (state, _action) => {
            state.isLoading = true;
        },
        clientTimeLineFetchSuccess: (state, action) => {
            state.clientTimeLine = action.payload?.reduce(
                (prev: any, current: any) => ({ ...prev, ...current.value }),
                {}
            );
            state.isLoading = false;
        },
        clientTimeLineFetchError: (state, _action) => {
            state.isLoading = false;
        },
        agentTimeLineFetch: (state, _action) => {
            state.isLoading = true;
        },
        clearAgentTimeLine: (state, _action) => {
            state.agentTimeLine = {};
        },
        agentTimeLineFetchSuccess: (state, action) => {
            state.agentTimeLine = action.payload?.reduce(
                (prev: any, current: any) => ({ ...prev, ...current.value }),
                {}
            );
            state.isLoading = false;
        },
        agentTimeLineFetchError: (state, _action) => {
            state.isLoading = false;
        },
        vendorTimeLineFetch: (state, action) => {
            state.vendorDetails = action.payload;
        },
        wareHouseTimelineFetch: (state, action) => {
            state.wareHouseDetails = action.payload;
        },
        setSelectedData: (state, action) => {
            state.selectedData = action.payload;
        },
    },
});

export const {
    onLifeCycleSearch,
    clearLifeCycleSearch,
    onLifeCycleSearchSuccess,
    onLifeCycleSearchFailure,
    bookingTimeLineFetch,
    bookingTimeLineFetchMbl,
    wareHouseTimelineFetch,
    isLoadingFunction,
    bookingBasicDetailsFetch,
    bookingBasicDetailsFetchMbl,
    portTimeLineFetch,
    clientTimeLineFetch,
    clientTimeLineFetchSuccess,
    clientTimeLineFetchError,
    agentTimeLineFetch,
    agentTimeLineFetchSuccess,
    agentTimeLineFetchError,
    vendorTimeLineFetch,
    clearAgentTimeLine,
    setSelectedData,
} = lifecyclesearchSlice.actions;

export default lifecyclesearchSlice.reducer;
