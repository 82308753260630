import React, { useRef, useState, useEffect } from 'react';
import './index.scss';
import logo from 'src/assets/images/forwarder-global-logo.svg';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { login, selectAuthIsError, selectAuthUser } from 'src/store/reducers/authReducer';
import Loader from '../../Loading';
import CustomLoader from '../../Loading/Custom';
import { useMsal } from '@azure/msal-react';
import { passwordChangeRequest } from 'src/authConfig';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
import { Position, success } from 'src/utils/toast';
import { InteractionStatus } from '@azure/msal-browser';
import { checkProfilePermissions } from 'src/ShowForPermissions/profilePerms';
import { checkPurchasePermissions } from 'src/ShowForPermissions/purchasePerm';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import NotificationList from './Notifications';
import { profileChangeFn, profileChangeUpdation } from 'src/store/reducers/rolesReducer';
import { fetchPermissions, setCurrentIndex } from 'src/store/reducers/permissionReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { getOrganisationData } from 'src/services/apis/authApi';
import ClickAwayListener from 'react-click-away-listener';
import { getCookie } from 'src/utils';
import ShowForPermissions from 'src/ShowForPermissions';
import {
    activeStatus,
    allPos,
    awaitingClientApproval,
    awaitingEnrichment,
    bookingProposalStatusAll,
    bpActiveStatus,
} from 'src/utils/constants';
import {
    setFilter,
    setSelectedDropDown,
    setCurrentPage,
} from 'src/store/reducers/bookingProposal/bookingProposalListReducer';
import {
    setFilter as setAirFilter,
    setSelectedDropDown as setAirSelectedDropDown,
    setCurrentPage as setAirCurrentPage,
} from 'src/store/reducers/bookingProposalAir/listReducer';
import { resetPage } from 'src/store/reducers/purchaseOrder/poListReducer';
import {
    setFilter as setAoFilter,
    setSelectedDropDown as setAoSelectedDropDown,
    setCurrentPage as setAoCurrentPage,
} from 'src/store/reducers/aobookingProposal/bookingProposalListReducer';
import { clearDashboardInsightsFilters } from 'src/pages/Dashboard/Insights/clearDashboardInsightsFilters';
import { setTodoType } from 'src/store/reducers/dashboard/dashboardTodoReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faAnchor,
    faBuildingUser,
    faCartFlatbed,
    faCartShopping,
    faChartMixed,
    faContainerStorage,
    faFileInvoiceDollar,
    faFileLines,
    faGears,
    faGridHorizontal,
    faListUl,
    faPalletBoxes,
    faShelves,
    faShip,
    faShuffle,
    faWarehouse,
    faFileInvoice,
    faKey,
} from '@fortawesome/pro-duotone-svg-icons';
import ApiModal from './ApiModal';

/* eslint-disable no-undefined */
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const PASSWORD_CHANGE_POLICY_NAME = process.env.REACT_APP_PASSWORD_CHANGE_POLICY_NAME!;

interface Locationprops {
    setlocationChange: any;
}

const Index: React.FC<Locationprops> = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { instance, accounts, inProgress } = useMsal();
    const account = instance.getActiveAccount();
    let tokenAuthTime: any = account?.idTokenClaims?.auth_time || '';
    const user = useAppSelector(selectAuthUser);
    const isError = useAppSelector(selectAuthIsError);
    const loggedInSession = localStorage.getItem('loggedIn');
    const loggedInCookie = getCookie('loggedIn');
    const [showDropdown, setShowDropdown] = useState(false);
    const [apiModal, setApiModal] = useState(false);
    const [showForwarding, setShowForwarding] = useState(false);
    const [permissionsValues, setpermissionsValues] = useState([]);
    const [showWareHouse, setShowWareHouse] = useState(false);
    const [showQuotes, setShowQuotes] = useState(false);
    const [showPasswordExpiry, setShowPasswordExpiry] = useState(true);
    const [switchDisplay, setswitchDisplay] = useState(false);
    const { permissions, permissionsWithoutRole } = useSelector((state: RootState) => state.permissionData);
    const { profileChange, profileChangeName } = useSelector((state: RootState) => state.rolesReducer);
    const { profileType, userType, isBlp, userEmail } = useUserProfile();
    const [_tabActive, setTabActive] = useState('Dashboard');
    const { poFilter } = useSelector((state: RootState) => state.purchaseOrderList);
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const isExecutive = userData.roles?.find((x: any) => x.roleName?.toLowerCase() === 'executive');
    const username = userData?.user?.us_UserName;
    const userDetails =
        localStorage?.getItem('wholePerms') != null &&
        localStorage?.getItem('wholePerms') != undefined &&
        localStorage?.getItem('wholePerms') != 'undefined' &&
        JSON.parse(localStorage?.getItem('wholePerms') || '');

    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setShowDropdown);
    useOutsideAlerter(wrapperRef, setswitchDisplay);

    const indexVal = localStorage.getItem('roleIndex');
    useEffect(() => {
        props.setlocationChange(location);
        if (indexVal === null) {
            userData?.roles?.forEach((element: any, i: any) => {
                if (element.isDefault) {
                    localStorage.setItem('roleIndex', i);
                    const perm = (permissionsWithoutRole[i] as any)?.permissions;
                    setpermissionsValues(perm);
                }
            });
        } else {
            const perm = (permissionsWithoutRole[indexVal as any] as any)?.permissions;
            setpermissionsValues(perm);
        }
    }, [indexVal, userData]);
    useEffect(() => {
        if (indexVal === null) {
            userData?.roles?.forEach((element: any, i: any) => {
                if (element.isDefault) {
                    dispatch(setCurrentIndex(i));
                    localStorage.setItem('roleIndex', i);
                }
            });
        }
    }, [indexVal, userData]);

    useEffect(() => {
        if (showWareHouse || showForwarding) {
            setShowQuotes(false);
        }
    }, [showWareHouse, showForwarding]);

    useEffect(() => {
        if (profileChange) {
            success(`Profile has been switched to ${profileChangeName}`, Position.TOP_RIGHT);
        }
    }, [profileChange]);
    useEffect(() => {
        if (location.pathname === '/') {
            setTabActive('Dashboard');
        } else if (
            location.pathname.includes('/inbound') ||
            location.pathname.includes('/booking_list') ||
            location.pathname.includes('/lclbooking_list') ||
            location.pathname.includes('/LCLbooking_list') ||
            location.pathname.includes('/bcnBookingList') ||
            location.pathname.includes('/purchase_order') ||
            location.pathname.includes('/warehousing_order') ||
            location.pathname.includes('/allocation_orders') ||
            location.pathname.includes('/air_booking_list') ||
            location.pathname.includes('/reassign-agent') ||
            location.pathname.includes('/Aobooking_list') ||
            location.pathname.includes('/shipments-view') ||
            location.pathname.includes('/containers') ||
            location.pathname.includes('/doc-tracking') ||
            location.pathname.includes('/booking/with-confirmation-groups') ||
            location.pathname.includes('/scr/fwd/shp')
        ) {
            setTabActive('Forwarding');
        } else if (location.pathname.includes('/warehouse')) {
            setTabActive('WareHouse');
        } else if (location.pathname.includes('/tracking')) {
            setTabActive('Tracking');
        }
    }, []);
    useEffect(() => {
        if (username && profileType) {
            getOrganisationData({ userName: username, profile: profileType });
        }
    }, [profileType]);

    const checkPermission = (permission: any, type: any) => {
        let flag = true;
        for (let i = 0; i < permissionsValues?.length; i++) {
            if (
                (permissionsValues[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                (permissionsValues[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(type.toLowerCase())
            ) {
                flag = false;
            }
        }
        return flag;
    };

    const resetPoFilters = () => {
        const initialFilter = {
            poStatus:
                profileType === 'agent' || profileType === 'forwarder' || profileType === 'client'
                    ? awaitingEnrichment
                    : activeStatus,
        };
        const initialDropDown = {
            text:
                profileType === 'agent' || profileType === 'forwarder' || profileType === 'client'
                    ? awaitingEnrichment
                    : allPos,
            count: 0,
        };
        dispatch(setFilter(initialFilter));
        dispatch(setSelectedDropDown(initialDropDown));
    };

    useEffect(() => {
        if (inProgress === InteractionStatus.None) {
            if (accounts.length) {
                if (
                    accounts[0].homeAccountId.includes(PASSWORD_CHANGE_POLICY_NAME) ||
                    (accounts[1] && accounts[1]?.homeAccountId?.includes(PASSWORD_CHANGE_POLICY_NAME))
                ) {
                    if (accounts[0].homeAccountId.includes(PASSWORD_CHANGE_POLICY_NAME)) {
                        tokenAuthTime = accounts[0].idTokenClaims?.auth_time;
                    } else if (accounts[1] && accounts[1]?.homeAccountId?.includes(PASSWORD_CHANGE_POLICY_NAME)) {
                        tokenAuthTime = accounts[1].idTokenClaims?.auth_time;
                    }
                    if (
                        localStorage.getItem('showToast') === 'true' &&
                        localStorage.getItem('tokenAuthTime') != tokenAuthTime
                    ) {
                        success('Password has been changed successfully', Position.TOP_RIGHT);
                        localStorage.setItem('showToast', 'false');
                        localStorage.removeItem('tokenAuthTime');
                        dispatch(login('fromForgotPassword'));
                    }
                }
            }
        }
    }, [dispatch, accounts, inProgress]);

    const resetBookingListStates = () => {
        const initialFilter = { bpStatus: profileType === 'client' ? awaitingClientApproval : bpActiveStatus };
        const initialDropDown = {
            text: profileType === 'client' ? awaitingClientApproval : bookingProposalStatusAll,
            count: 0,
        };
        //bp - sea
        dispatch(setCurrentPage(0));
        dispatch(setFilter(initialFilter));
        dispatch(setSelectedDropDown(initialDropDown));

        //bp - air
        dispatch(setAirCurrentPage(0));
        dispatch(setAirFilter(initialFilter));
        dispatch(setAirSelectedDropDown(initialDropDown));

        //bp - ao
        dispatch(setAoCurrentPage(0));
        dispatch(setAoFilter(initialFilter));
        dispatch(setAoSelectedDropDown(initialDropDown));
    };

    const HeaderType1 = () => {
        return (
            <ClickAwayListener onClickAway={() => setShowForwarding(false)}>
                <div className="sub-menu-header-menu-wrapper type1 mega-sub-menu">
                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faCartShopping} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Orders</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <ShowForPermissions permission="view" type="po">
                                        {!checkPermission('view', 'po') && (
                                            <li className="menu-list-item">
                                                <a
                                                    href={checkPurchasePermissions(permissions, 'intermodal')}
                                                    className="menu-list-item-link"
                                                    style={{ cursor: 'pointer' }}
                                                    // state={{ fromNav: true }}
                                                    onClick={() => {
                                                        setShowForwarding(false);
                                                        dispatch(resetPage({}));
                                                        resetPoFilters();
                                                    }}
                                                >
                                                    Intermodal PO
                                                </a>
                                            </li>
                                        )}
                                    </ShowForPermissions>
                                    {/* <ShowForPermissions permission="view" type="po">
                                        {!checkPermission('view', 'po') && (
                                            <li className="menu-list-item">
                                                <a
                                                    href={checkPurchasePermissions(permissions, 'warehousing')}
                                                    className="menu-list-item-link"
                                                    style={{ cursor: 'pointer' }}
                                                    // state={{ fromNav: true }}
                                                    onClick={() => {
                                                        setShowForwarding(false);
                                                        dispatch(resetPage({}));
                                                        resetPoFilters();
                                                    }}
                                                >
                                                    Warehousing PO
                                                </a>
                                            </li>
                                        )}
                                    </ShowForPermissions> */}
                                    {/* <ShowForPermissions permission="view" type="inbound">
                                        {!checkPermission('view', 'inbound') && (
                                            <li className="menu-list-item">
                                                <Link
                                                    to="/inbound"
                                                    className="menu-list-item-link"
                                                    onClick={() => {
                                                        setShowForwarding(false);
                                                        dispatch(resetPage({}));
                                                    }}
                                                >
                                                    Inbound PO
                                                </Link>
                                            </li>
                                        )}
                                    </ShowForPermissions>
                                    {!checkPermission('view', 'ao') &&
                                        (profileType === 'agent' && !isBlp ? null : (
                                            <li className="menu-list-item">
                                                <Link
                                                    to="/allocation_orders"
                                                    className="menu-list-item-link"
                                                    onClick={() => setShowForwarding(false)}
                                                >
                                                    Allocation Orders
                                                </Link>
                                            </li>
                                        ))} */}
                                </ul>
                            </div>
                        </div>
                    </div>
                    {!checkPermission('view', 'bp') && (
                        <div className="menu-item-type">
                            <div className="icon-holder-dashboard-inside">
                                <FontAwesomeIcon icon={faListUl} />
                            </div>
                            <div className="menu-header-holder">
                                <h4 className="menu-title">Booking</h4>
                                <div className="menu-fields">
                                    <ul className="menu-field-items">
                                        <li className="menu-list-item">
                                            <Link
                                                to="/booking_list"
                                                style={{ cursor: 'pointer' }}
                                                className="menu-list-item-link"
                                                onClick={() => {
                                                    setShowForwarding(false);
                                                    resetBookingListStates();
                                                }}
                                            >
                                                Booking Management
                                            </Link>
                                        </li>

                                        <li className="menu-list-item">
                                            <a
                                                href="/booking/with-confirmation-groups"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Booking (With Confirmation Groups)
                                            </a>
                                        </li>

                                        {/* {isExecutive && profileType === 'forwarder' && (
                                            <li className="menu-list-item">
                                                <Link
                                                    to="/allocated_teu"
                                                    style={{ cursor: 'pointer' }}
                                                    className="menu-list-item-link"
                                                    onClick={() => {
                                                        setShowForwarding(false);
                                                        resetBookingListStates();
                                                    }}
                                                >
                                                    NAC Report
                                                </Link>
                                            </li>
                                        )} */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    {profileType !== 'vendor' && (
                        <div className="menu-item-type">
                            <div className="icon-holder-dashboard-inside">
                                <FontAwesomeIcon icon={faContainerStorage} />
                            </div>
                            <div className="menu-header-holder">
                                <h4 className="menu-title">Containers</h4>
                                <div className="menu-fields">
                                    <ul className="menu-field-items">
                                        <li className="menu-list-item">
                                            {/* <Link
                                            to="/international/containerStatus"
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Containers Status
                                        </Link> */}
                                            <a
                                                href="/containers/containers-status"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Container Status
                                            </a>
                                        </li>
                                        <li className="menu-list-item">
                                            {/* <Link
                                            to="/genericReport/containerFclDeliveryAndEmpty"
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Containers Cartage View
                                        </Link> */}
                                            <a
                                                href="/containers/containers-cartage-view"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Container Cartage View
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    {profileType !== 'vendor' && (
                        <div className="menu-item-type">
                            <div className="icon-holder-dashboard-inside">
                                <FontAwesomeIcon icon={faShip} />
                            </div>
                            <div className="menu-header-holder">
                                <h4 className="menu-title">Shipments</h4>
                                <div className="menu-fields">
                                    <ul className="menu-field-items">
                                        <li className="menu-list-item">
                                            {/* <Link
                                            to="/international/shipment"
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Shipments View
                                        </Link> */}
                                            <a
                                                href="/shipments-view"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Shipment View
                                            </a>
                                        </li>
                                        <li className="menu-list-item">
                                            {/* <Link
                                            to="/international/shipmentByPO"
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Shipments View (With orders)
                                        </Link> */}
                                            <a
                                                href="/shipments-view/with-order"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Shipment View (With orders)
                                            </a>
                                        </li>
                                        <li className="menu-list-item">
                                            {/* <Link
                                            to="/international/container"
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Shipments View (With Containers)
                                        </Link> */}
                                            <a
                                                href="/shipments-view/with-container"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Shipment View (With Containers)
                                            </a>
                                        </li>
                                        <li className="menu-list-item">
                                            {/* <Link
                                            to="/international/container"
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Shipments View (With Containers)
                                        </Link> */}
                                            <a
                                                href="/shipments-view/with-product"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Shipment View (With Products)
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    {profileType !== 'vendor' && (
                        <div className="menu-item-type">
                            <div className="icon-holder-dashboard-inside">
                                <FontAwesomeIcon icon={faFileLines} />
                            </div>
                            <div className="menu-header-holder">
                                <h4 className="menu-title">Document Tracking</h4>
                                <div className="menu-fields">
                                    <ul className="menu-field-items">
                                        <li className="menu-list-item">
                                            <a
                                                href="/doc-tracking/docShipment"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Shipment
                                            </a>
                                        </li>
                                        <li className="menu-list-item">
                                            <a
                                                href="/doc-tracking/docShipmentDates"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Shipment Dates
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    {profileType !== 'vendor' && (
                        <div className="menu-item-type">
                            <div className="icon-holder-dashboard-inside">
                                <FontAwesomeIcon icon={faAnchor} />
                            </div>
                            <div className="menu-header-holder">
                                <h4 className="menu-title">Customs</h4>
                                <div className="menu-fields">
                                    <ul className="menu-field-items">
                                        <li className="menu-list-item">
                                            <a
                                                href="/doc-tracking/docShipmentCommercialInvoice"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Commercial Invoices
                                            </a>
                                        </li>
                                        {/* <li className="menu-list-item">
                                        <Link
                                            to=""
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Brokerage View
                                        </Link>
                                    </li>
                                    <li className="menu-list-item">
                                        <Link
                                            to=""
                                            className="menu-list-item-link"
                                            onClick={() => setShowForwarding(false)}
                                        >
                                            Commerical Invoice
                                        </Link>
                                    </li> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    {userDetails?.accountManager && (
                        <div className="menu-item-type">
                            <div className="icon-holder">
                                <svg className="svg-icon file-icon ">
                                    <use xlinkHref="#singleStockSuccessBadgeIcon"></use>
                                </svg>
                            </div>
                            <div className="menu-header-holder">
                                <h4 className="menu-title">Approvals</h4>
                                <div className="menu-fields">
                                    <ul className="menu-field-items">
                                        <li className="menu-list-item">
                                            <Link
                                                to="/reassign-agent/approval"
                                                className="menu-list-item-link"
                                                onClick={() => setShowForwarding(false)}
                                            >
                                                Agent Reassignment
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        );
    };
    const HeaderType2 = () => {
        return (
            <ClickAwayListener onClickAway={() => setShowWareHouse(false)}>
                <div className="sub-menu-header-menu-wrapper type2 mega-sub-menu">
                    {!isBlp && profileType === 'agent' ? (
                        ''
                    ) : (
                        <div className="menu-item-type">
                            <div className="icon-holder-dashboard-inside">
                                <FontAwesomeIcon icon={faPalletBoxes} />
                            </div>
                            <div className="menu-header-holder">
                                <h4 className="menu-title">Inbound</h4>
                                <div className="menu-fields">
                                    <ul className="menu-field-items">
                                        <li className="menu-list-item">
                                            <a
                                                href="/warehouse/inbound-receipt"
                                                className="menu-list-item-link"
                                                onClick={() => setShowWareHouse(false)}
                                            >
                                                Receipt
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faShelves} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Inventory</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <li className="menu-list-item">
                                        <Link
                                            to="/warehouse/inventory"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Inventory view
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faCartFlatbed} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Outbound</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <li className="menu-list-item">
                                        <a
                                            href="/warehouse/outbound-shipment"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Shipment
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faShuffle} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Cross Dock</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <li className="menu-list-item">
                                        <Link to="#" className="menu-list-item-link">
                                            Receipt
                                        </Link>
                                    </li>
                                    <li className="menu-list-item">
                                        <Link to="#" className="menu-list-item-link">
                                            Shipments
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        );
    };
    const HeaderType3 = () => {
        return (
            <ClickAwayListener onClickAway={() => setShowQuotes(false)}>
                <div className="sub-menu-header-menu-wrapper type3 mega-sub-menu" style={{ left: '690px' }}>
                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faGears} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Config</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/config/detail/QTE_RateType"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Quote Type (Groups)
                                        </a>
                                    </li>

                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/config/quotesection"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Quote Sections
                                        </a>
                                    </li>

                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/config/quoteitem"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Quote Items
                                        </a>
                                    </li>

                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/config/detail/QTE_UOM"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Quote Unit of Measures
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faAnchor} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Port Config</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/config/port"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Ports
                                        </a>
                                    </li>
                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/config/portGroup"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Port Groups
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faFileInvoiceDollar} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Quote Input</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/rate"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Buy Rates
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div className="menu-item-type">
                        <div className="icon-holder-dashboard-inside">
                            <FontAwesomeIcon icon={faFileInvoice} />
                        </div>
                        <div className="menu-header-holder">
                            <h4 className="menu-title">Quote Output</h4>
                            <div className="menu-fields">
                                <ul className="menu-field-items">
                                    <li className="menu-list-item">
                                        <a
                                            href="/qms/quote"
                                            className="menu-list-item-link"
                                            onClick={() => setShowWareHouse(false)}
                                        >
                                            Quotes
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </ClickAwayListener>
        );
    };
    if (loggedInCookie === null && loggedInSession === 'true') {
        return <CustomLoader />;
    }
    if ((!isError && user?.user) || loggedInSession) {
        return (
            <div className="header">
                <div className="forwarder-logo">
                    <img className="logo-icon" src={logo} alt="IFC Global Logistics" />
                </div>
                <nav className="main-nav">
                    <div className={`main-menu-wrapper header-nav ? 'menu-active' : ' '}`}>
                        <Link
                            className={
                                location.pathname === '/' || location.pathname.includes('/dashboard/')
                                    ? 'header-nav active'
                                    : 'header-nav'
                            }
                            to="/"
                            style={{ cursor: 'pointer' }}
                            state={{ dashboardEntered: true }}
                            onClick={() => {
                                setTabActive('Dashboard');
                                if (!(location.pathname === '/' || location.pathname.includes('/dashboard/'))) {
                                    dispatch(setTodoType({ type: null }));
                                    clearDashboardInsightsFilters(dispatch);
                                }
                            }}
                        >
                            <div className="icon-holder-dashboard">
                                <FontAwesomeIcon icon={faGridHorizontal} />
                            </div>
                            Dashboard
                            <div className="menu-arrow"></div>
                        </Link>
                    </div>
                    <div className={`main-menu-wrapper header-nav ${showForwarding ? 'menu-active' : ' '}`}>
                        <a
                            className={
                                // location.pathname.includes('/inbound') ||
                                location.pathname.includes('/booking_list') ||
                                location.pathname.includes('/lclbooking_list') ||
                                location.pathname.includes('/LCLbooking_list') ||
                                location.pathname.includes('/bcnBookingList') ||
                                location.pathname.includes('/purchase_order') ||
                                location.pathname.includes('/warehousing_order') ||
                                location.pathname.includes('/allocation_orders') ||
                                location.pathname.includes('/air_booking_list') ||
                                location.pathname.includes('/reassign-agent') ||
                                location.pathname.includes('/Aobooking_list') ||
                                location.pathname.includes('/shipments-view') ||
                                location.pathname.includes('/containers') ||
                                location.pathname.includes('/doc-tracking') ||
                                location.pathname.includes('/booking/with-confirmation-groups') ||
                                location.pathname.includes('/scr/fwd/shp')
                                    ? 'header-nav active'
                                    : 'header-nav'
                            }
                            onClick={() => {
                                setTabActive('Forwarding');
                                setShowForwarding(!showForwarding);
                                setShowWareHouse(false);
                                dispatch(fetchPermissions(username));
                            }}
                        >
                            <div className="icon-holder-dashboard">
                                <FontAwesomeIcon icon={faShip} />
                            </div>
                            Forwarding
                            <span className="dropdown-arrow">
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>dropdown</title>
                                    </use>
                                </svg>
                            </span>
                            <div className="menu-arrow"></div>
                        </a>
                        <HeaderType1 />
                    </div>
                    {profileType !== 'vendor' && (
                        <div className={`main-menu-wrapper header-nav ${showWareHouse ? 'menu-active' : ' '}`}>
                            <a
                                className={
                                    location.pathname.includes('/receipt') ||
                                    location.pathname.includes('/inventory') ||
                                    location.pathname.includes('/warehouse')
                                        ? 'header-nav active'
                                        : 'header-nav'
                                }
                                onClick={() => {
                                    setShowWareHouse(!showWareHouse);
                                    setShowForwarding(false);
                                    dispatch(fetchPermissions(username));
                                }}
                            >
                                <div className="icon-holder-dashboard">
                                    <FontAwesomeIcon icon={faWarehouse} />
                                </div>
                                Warehousing
                                <span className="dropdown-arrow">
                                    <svg className="arrow-icon">
                                        <use xlinkHref="#downArrow">
                                            <title>dropdown</title>
                                        </use>
                                    </svg>
                                </span>
                                <div className="menu-arrow"></div>
                            </a>
                            <HeaderType2 />
                        </div>
                    )}
                    {/*{!checkPermission('view', 'po') && (
                        <a
                            onClick={() => window.location.replace(checkPurchasePermissions(permissions))}
                            className={
                                location.pathname.includes('/purchase_order') ? 'header-nav active' : 'header-nav'
                            }
                            style={{ cursor: 'pointer' }}
                        >
                            Purchase Orders
                        </a>
                    )}*/}
                    {/*{!checkPermission('view', 'po') && (
                        <a
                            onClick={() => window.location.replace(checkBookingPermissions(permissions))}
                            style={{ cursor: 'pointer' }}
                            className={location.pathname.includes('/booking_list') ? 'header-nav active' : 'header-nav'}
                        >
                            Bookings
                        </a>
                    )}
                    <a className="header-nav" href="#">
                        BLP
                    </a>
                    <a className="header-nav" href="#">
                        Users
                    </a>*/}
                    {/* TODO:  update 'a' tag to Link when the reports page is ready */}
                    {/* <a className="header-nav">Reports</a> */}
                    {/* TODO:  update 'a' tag to Link when the Tracking page is ready */}
                    {/*{profileType === 'forwarder' && (*/}
                    {profileType !== 'vendor' && (
                        <Link
                            className={`header-nav ${location.pathname.includes('/tracking') ? ' active' : ''}`}
                            to="/tracking"
                        >
                            <div className="icon-holder-dashboard">
                                <FontAwesomeIcon icon={faChartMixed} />
                            </div>
                            Tracking
                        </Link>
                    )}
                    {/*)}*/}
                    {profileType !== 'vendor' && (
                        <Link
                            to={checkProfilePermissions(permissions)}
                            style={{ cursor: 'pointer' }}
                            className={
                                location.pathname.includes('/profile') ||
                                location.pathname.includes('/users') ||
                                location.pathname.includes('/roles') ||
                                location.pathname.includes('/coloader') ||
                                location.pathname.startsWith('/vendor') ||
                                location.pathname.includes('/shippingline') ||
                                location.pathname === '/agent'
                                    ? // location.pathname.includes('/agent')
                                      'header-nav active'
                                    : 'header-nav'
                            }
                        >
                            <div className="icon-holder-dashboard">
                                <FontAwesomeIcon icon={faBuildingUser} />
                            </div>
                            Organisations &amp; Users
                        </Link>
                    )}
                    {profileType === 'forwarder' && (
                        <div className={`main-menu-wrapper header-nav ${showQuotes ? 'menu-active' : ' '}`}>
                            <a
                                className={location.pathname.includes('/quotes') ? 'header-nav active' : 'header-nav'}
                                onClick={() => {
                                    setShowQuotes(!showQuotes);
                                    setShowForwarding(false);
                                    setShowWareHouse(false);
                                    dispatch(fetchPermissions(username));
                                }}
                            >
                                <div className="icon-holder-dashboard">
                                    <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                </div>
                                Quotes
                                <span className="dropdown-arrow">
                                    <svg className="arrow-icon">
                                        <use xlinkHref="#downArrow">
                                            <title>dropdown</title>
                                        </use>
                                    </svg>
                                </span>
                                <div className="menu-arrow"></div>
                            </a>
                            <HeaderType3 />
                        </div>
                    )}
                </nav>
                <div className="header-right">
                    <div className={`setting-btn-wrapper ${location.pathname.includes('/settings') ? 'active' : ' '}`}>
                        <Link to="/settings" className="app-btn app-setting-btn ">
                            <svg className="svg-icon setting-icon">
                                <use xlinkHref="#settingIcon">
                                    <title>Settings</title>
                                </use>
                            </svg>
                        </Link>
                    </div>
                    <NotificationList />

                    <div
                        ref={wrapperRef}
                        className={`dropdown-wrap menu-down ${
                            showDropdown ? ' dropdown-open ' : ''
                        } user-menu-dropdown`}
                    >
                        <a
                            onClick={() => setShowDropdown((prev) => !prev)}
                            className={`profile-link update-notification app-btn ${
                                (userData?.passwordResetNoOfDays || userData?.passwordExpiredMessage) &&
                                userType !== 'AD'
                                    ? ' active'
                                    : ''
                            }`}
                            title="User details"
                        >
                            <span className="profile-icon">
                                {userData?.user?.us_FirstName && userData?.user?.us_FirstName.charAt(0).toUpperCase()}
                            </span>

                            <span className="dropdown-arrow">
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>User details</title>
                                    </use>
                                </svg>
                            </span>
                        </a>
                        <div className="dropdown-menu">
                            <div className="menu-item not-link">
                                <div className="profile-main-title">
                                    {userData?.user?.us_FirstName ? userData?.user?.us_FirstName : ''}{' '}
                                    {userData?.user?.us_LastName ? userData?.user?.us_LastName : ''}
                                </div>
                                <div className="profile-description">{userData?.user?.us_Email}</div>
                            </div>
                            {userData?.roles?.length > 1 && (
                                <div className="dropdown-wrap menu-down  menu-item switch-link">
                                    <button
                                        className="app-btn app-btn-secondary"
                                        onClick={() => setswitchDisplay(!switchDisplay)}
                                    >
                                        <div className="switch-content-wrapper">
                                            <svg className="profile-switch-icon">
                                                <use xlinkHref="#profileSwitchIcon">
                                                    <title>Switch Profile</title>
                                                </use>
                                            </svg>
                                            <span>Switch Profile</span>
                                        </div>
                                        <svg className="svg-icon arrow-icon">
                                            <use href="#downArrow">
                                                <title>Expand row</title>
                                            </use>
                                        </svg>
                                    </button>
                                    {switchDisplay && (
                                        <div className="dropdown-menu switch-sub-menu">
                                            {userData?.roles?.map((res: any, i: any) => (
                                                <a
                                                    className="menu-item app-btn"
                                                    onClick={() => {
                                                        localStorage.setItem('roleIndex', i);
                                                        setswitchDisplay(false);
                                                        setShowDropdown(false);
                                                        dispatch(
                                                            profileChangeFn({
                                                                status: true,
                                                                name:
                                                                    res?.profile.toLowerCase().charAt(0).toUpperCase() +
                                                                    res?.profile.toLowerCase().slice(1),
                                                            })
                                                        );
                                                        dispatch(
                                                            profileChangeUpdation({
                                                                email: userEmail,
                                                                profile: res?.profile,
                                                            })
                                                        );
                                                        setTimeout(() => {
                                                            dispatch(fetchPermissions(username));
                                                            window.location.replace('/');
                                                            dispatch(
                                                                profileChangeFn({
                                                                    status: false,
                                                                    name: '',
                                                                })
                                                            );
                                                        }, 1500);
                                                    }}
                                                >
                                                    <span className="tick-icon-holder">
                                                        {i == indexVal && (
                                                            <svg className="svg-icon tick-icon">
                                                                <use href="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        )}
                                                    </span>

                                                    <span
                                                        className="main-content"
                                                        style={{ textTransform: 'capitalize' }}
                                                    >
                                                        {res?.profile.toLowerCase()}
                                                    </span>
                                                </a>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}

                            {userType !== 'AD' && (
                                <a
                                    onClick={() => {
                                        localStorage.setItem('showToast', 'true');
                                        localStorage.setItem('tokenAuthTime', tokenAuthTime);
                                        instance.loginRedirect({ ...passwordChangeRequest });
                                    }}
                                    className="menu-item password-link btn-pointer-cursor"
                                    title="Change Password"
                                >
                                    <svg className="lock-icon">
                                        <use xlinkHref="#lockIcon">
                                            <title>Change Password</title>
                                        </use>
                                    </svg>
                                    <div className="password-content-wrapper">
                                        <span>Change Password</span>
                                        <p className="details-box-heading">
                                            {userData?.passwordResetNoOfDays && userType !== 'AD'
                                                ? `Password expires in ${userData?.passwordResetNoOfDays} days`
                                                : ''}
                                        </p>
                                    </div>
                                </a>
                            )}
                            {/* <a
                                onClick={() => {
                                    setShowDropdown((prev) => !prev);
                                    setApiModal(true);
                                }}
                                className="menu-item password-link btn-pointer-cursor"
                                title="Generate API Key"
                            >
                                <div className="icon-holder-dashboard">
                                    <FontAwesomeIcon icon={faKey} />
                                </div>
                                <div className="password-content-wrapper">
                                    <span>Generate API Key</span>
                                </div>
                            </a> */}

                            <a
                                onClick={() => {
                                    navigate('/logout');
                                    // localStorage.removeItem('roleIndex');
                                    // dispatch(logout(instance));
                                    // instance.logoutRedirect({ postLogoutRedirectUri: '/' });
                                }}
                                className="menu-item logout-link btn-pointer-cursor"
                                title="Logout"
                            >
                                <svg className="logout-icon">
                                    <use xlinkHref="#logoutIcon">
                                        <title>logout</title>
                                    </use>
                                </svg>
                                <span>Logout</span>
                            </a>
                        </div>
                    </div>
                    {userData?.passwordResetNoOfDays &&
                        userType !== 'AD' &&
                        !localStorage.getItem('showPasswordExpiry') &&
                        showPasswordExpiry && (
                            <div className="app-modal">
                                <div className="modal-content-holder header-password-expiry-popup">
                                    <div className="modal-header">
                                        <div className="title">Password Expiry</div>
                                        <button
                                            onClick={() => {
                                                localStorage.setItem('showPasswordExpiry', 'true');
                                                setShowPasswordExpiry(false);
                                            }}
                                            className="app-btn modal-close-btn"
                                        >
                                            <span>
                                                <svg className="svg-icon modal-header-close-icon">
                                                    <use xlinkHref="#closeIcon">
                                                        <title>Close</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-content">
                                        Your current password expires in {userData?.passwordResetNoOfDays}{' '}
                                        {userData?.passwordResetNoOfDays === 1 ? 'day' : 'days'}. For security purposes
                                        we recommend you to change it now.
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            onClick={() => {
                                                localStorage.setItem('showToast', 'true');
                                                localStorage.setItem('tokenAuthTime', tokenAuthTime);
                                                localStorage.setItem('showPasswordExpiry', 'true');
                                                instance.loginRedirect({ ...passwordChangeRequest });
                                            }}
                                            className="app-btn app-btn-primary modal-btn"
                                        >
                                            <span className="button-text vendor-button-text">Change Password</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    {userData?.passwordExpiredMessage &&
                        userType !== 'AD' &&
                        !localStorage.getItem('showPasswordExpiry') &&
                        showPasswordExpiry && (
                            <div className="app-modal">
                                <div className="modal-content-holder header-password-expiry-popup">
                                    <div className="modal-header">
                                        <div className="title">Password Expiry</div>
                                        <button
                                            onClick={() => {
                                                localStorage.setItem('showPasswordExpiry', 'true');
                                                setShowPasswordExpiry(false);
                                            }}
                                            className="app-btn modal-close-btn"
                                        >
                                            <span>
                                                <svg className="svg-icon modal-header-close-icon">
                                                    <use xlinkHref="#closeIcon">
                                                        <title>Close</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-content">
                                        Your current password has been expired. Please change your password.
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            onClick={() => {
                                                localStorage.setItem('showToast', 'true');
                                                localStorage.setItem('tokenAuthTime', tokenAuthTime);
                                                localStorage.setItem('showPasswordExpiry', 'true');
                                                instance.loginRedirect({ ...passwordChangeRequest });
                                            }}
                                            className="app-btn app-btn-primary modal-btn"
                                        >
                                            <span className="button-text vendor-button-text">Change Password</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                </div>
                {apiModal ? <ApiModal setModalView={setApiModal} /> : null}
            </div>
        );
    }
    return <Loader />;
};

export default Index;
