import React from 'react';
import { Marker } from 'react-leaflet';
import { getPointWithinCurve } from '../../utils';
// import TransitPointIcon from '../../Icons/transitPoitIcon';
import ShippingPointIcon from '../../Icons/shipIcon';

const LineOverlayIcon2 = ({
    startPoint,
    endPoint,
    shippingMode,
    zoomlevel,
}: // changeVal,
{
    startPoint: [number, number];
    endPoint: [number, number];
    shippingMode: 'AIR' | 'SEA';
    zoomlevel?: any;
    changeVal?: number;
}) => {
    const shippingModeIconStartPoint = getPointWithinCurve({
        startPoint,
        endPoint,
        t: 0.4,
        zoomlevel,
    });
    const shippingModeIconEndPoint = getPointWithinCurve({
        startPoint,
        endPoint,
        t: 0.7,
        zoomlevel,
    });
    //iconWidth();
    if (shippingModeIconStartPoint && shippingModeIconEndPoint) {
        return (
            <>
                <>
                    <Marker
                        position={[shippingModeIconStartPoint[0], shippingModeIconStartPoint[1]]}
                        icon={ShippingPointIcon(shippingMode, zoomlevel)}
                    />
                </>
            </>
        );
    } else {
        return null;
    }
};

export default LineOverlayIcon2;
