/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import '../PurchaseOrderHeader/purchase-order-header.scss';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import '../BasicDetail/purchase-basic-detail.scss';
import '../po-tab.scss';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchClient,
    fetchVendor,
    selectAddPoLoading,
    selectClientDetails,
    selectVendorDetails,
    savePo,
    // selectAddPo,
    selectAddPoIsError,
    selectAddPoError,
    selectAddPoSuccess,
    clearFailure,
} from 'src/store/reducers/purchaseOrder/addPoReducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { error, Position, success } from 'src/utils/toast';
import { Link, useNavigate } from 'react-router-dom';
import { useQueryParam } from 'src/hooks/useQueryParam';
import { getOrganisationData } from 'src/utils';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { InputActionMeta } from 'react-select';

interface AddPoForm {
    poNumber: string;
    clientCode: string;
    clientName: string;
    vendorCode: string;
    vendorName: string;
    createdUser: string;
    createdUserId: string;
}

const Index: React.FC = () => {
    const organisationData = getOrganisationData();
    const { profileType } = useUserProfile();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const clientDetails = useAppSelector(selectClientDetails);
    // const clientDetails = organisationData?.poClients?.length
    //     ? organisationData?.poClients.map((res: any) => ({ value: res.orgCode, label: res.orgName, id: res.id }))
    //     : [];
    const vendorDetails = useAppSelector(selectVendorDetails);
    const isLoading = useAppSelector(selectAddPoLoading);
    const isError = useAppSelector(selectAddPoIsError);
    // const addPo = useAppSelector(selectAddPo);
    const addPoSuccess = useAppSelector(selectAddPoSuccess);
    const addPoError = useAppSelector(selectAddPoError);
    const [disableVendor, setDisableVendor] = useState(false);
    const [clientUid, setClientUid] = useState('');
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userName = '';
    let userFullName = '';
    let clientEmailId = '';
    if (userData) {
        const { user } = JSON.parse(userData);
        const { roles } = JSON.parse(userData);
        userName = user?.us_UserName;
        userFullName = user?.us_FirstName + ' ' + user?.us_LastName;
        if (roleIndex) {
            clientEmailId = roles[roleIndex].profile?.toLowerCase() === 'client' ? user?.us_Email : null;
        }
    }
    const shippingMode = useQueryParam('shippingMode');

    useEffect(() => {
        dispatch(fetchClient({ userName, fromAddPo: true }));
    }, [dispatch]);

    const initialValues: AddPoForm = {
        poNumber: '',
        clientCode: '',
        clientName: '',
        vendorCode: '',
        vendorName: '',
        createdUser: userFullName,
        createdUserId: userName,
    };

    const AddPoFormSchema = Yup.object().shape({
        poNumber: Yup.string().required('PO Number is required'),
        clientCode: Yup.string().required('Client Code is required'),
        vendorCode: Yup.string().required('Vendor Code is required'),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddPoFormSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                dispatch(
                    savePo({
                        ...values,
                        poNumber: values.poNumber?.trim(),
                        clientId: clientUid,
                        clientEmailId,
                        type: 'ITPO',
                        shippingMode:
                            shippingMode === 'air' ? 'Air Freight' : shippingMode === 'land' ? 'Land' : 'Sea Freight',
                        profileType,
                    })
                );
            }
        },
    });

    useEffect(() => {
        if (isError) {
            error(addPoError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            document?.getElementById('name_div')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (addPoSuccess?.status === 200 || addPoSuccess?.status === 201) {
            success(`Purchase Order ${addPoSuccess?.data?.poNumber} created successfully`, Position.TOP_RIGHT);
            localStorage.setItem('poNumber', addPoSuccess?.data?.poNumber);
            localStorage.setItem('poStatus', 'Draft');
            setTimeout(() => {
                formik.resetForm();
                navigate(`/purchase_order/${addPoSuccess?.data?.poId}/basic_details`);
            }, 2000);
            dispatch(clearFailure([]));
        }
    }, [isError, addPoSuccess, addPoError]);

    useEffect(() => {
        formik.setFieldValue('vendorCode', '');
        formik.setFieldValue('vendorName', '');
        if (formik.values.clientCode) {
            const uid = clientDetails?.find((element: any) => element.value === formik.values.clientCode);
            dispatch(
                fetchVendor({
                    clientCode: uid.id,
                    fromAddPo: true,
                })
            );
            setDisableVendor(false);
            setClientUid(uid.id);
            localStorage.setItem('clientName', uid.name);
            formik.setFieldValue('clientName', uid.name);
        } else {
            setDisableVendor(true);
            formik.setFieldValue('clientName', '');
        }
    }, [formik.values.clientCode]);

    useEffect(() => {
        if (!formik.values.vendorCode) {
            formik.setFieldValue('vendorCode', '');
            formik.setFieldValue('vendorName', '');
        } else {
            const uid = vendorDetails?.find((element: any) => element.value === formik.values.vendorCode);
            formik.setFieldValue('vendorName', uid.name);
        }
    }, [formik.values.vendorCode]);

    useEffect(() => {
        if (!vendorDetails) {
            formik.setFieldValue('vendorCode', '');
            formik.setFieldValue('vendorName', '');
        }
    }, [vendorDetails]);

    // useEffect(() => {
    //     if (clientDetails?.length === 1 && !formik.values.clientCode) {
    //         formik.setFieldValue('clientCode', clientDetails[0].value);
    //         formik.setFieldValue('clientName', clientDetails[0].name);
    //     }
    // }, [clientDetails]);

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">
                    No matches found.
                    <br />
                    No active vendors are linked to the
                    <br />
                    client profile. Only active vendors
                    <br />
                    will be available for selection
                    <br />
                </p>
                <br />
                <Link className="redirection-link" to={`/profile/clients/${clientUid}/vendor_details`}>
                    Go to Vendor Details
                </Link>
            </div>
        );
    };

    const clientStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            fontFamily: 'Poppins',
            minHeight: '34px',
            height: '34px',
            fontSize: '1.4rem',
            backgroundColor: '#FFF',
            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #cccccc'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
            }),
        }),
    };
    const vendorStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            fontFamily: 'Poppins',
            minHeight: '34px',
            height: '34px',
            fontSize: '1.4rem',
            backgroundColor: disableVendor ? '#e2e3e7' : '#FFF',
            color: disableVendor ? '#737373' : 'hsl(0, 0%, 20%)',
            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #cccccc'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
            }),
        }),
        noOptionsMessage: (base: any) => ({ ...base, minHeight: '210px' }),
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/purchase_order">
                                Purchase Orders
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Add New PO
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="purchase-order-header-fields">
                    <div className="pageheading-holder">
                        <div className="back-btn-holder">
                            <Link to="/purchase_order">
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </Link>
                            <h2 className="page-heading">Add New PO</h2>
                            <div className="transport-icon-wrapper">
                                <div className="item-count-status-holder">
                                    <div className="grid-item-count-status status-ui info-status">
                                        <span className="count">
                                            <svg className="svg-icon aeroplane-icon">
                                                <use
                                                    xlinkHref={`${
                                                        shippingMode === 'air'
                                                            ? '#aeroplaneIcon'
                                                            : shippingMode === 'land'
                                                            ? '#truckIcon'
                                                            : '#shipIcon'
                                                    }`}
                                                >
                                                    <title>
                                                        {shippingMode === 'air'
                                                            ? 'Airplane'
                                                            : shippingMode === 'land'
                                                            ? 'Land'
                                                            : 'Sea'}
                                                    </title>
                                                </use>
                                            </svg>
                                        </span>
                                        <span className="status-text">
                                            {shippingMode === 'air' ? 'Air' : shippingMode === 'land' ? 'Land' : 'Sea'}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div className="field-entries-added">
                        <div className="entry-fields-holder">
                            <form id="my-form" onSubmit={formik.handleSubmit} className="modal-form form-fields">
                                <div className="modal-input-holder layout-item">
                                    <label className="modal-input-label">PO Number</label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter PO Number"
                                            type="text"
                                            className="modal-input input-text"
                                            id="poNumber"
                                            name="poNumber"
                                            onChange={(event) => {
                                                formik.setFieldError(event.target.name, undefined);
                                                formik.setFieldValue('poNumber', event.target.value?.toUpperCase());
                                            }}
                                            value={formik.values.poNumber}
                                        ></input>
                                    </div>
                                </div>
                                <div className=" modal-input-holder layout-item">
                                    <label className="modal-input-label">Client Name</label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={clientDetails?.length ? clientDetails : []}
                                            placeholder="Enter Client Name to select"
                                            formik={formik}
                                            fieldName="clientCode"
                                            // extraField="clientName"
                                            isClearable={clientDetails?.length === 1 ? false : true}
                                            customStyle={clientStyle}
                                            onInputChangeFn={(value: string, actionMeta: InputActionMeta) => {
                                                if (value?.length > 2) {
                                                    const found = clientDetails.filter((res: any) =>
                                                        res.label?.toLowerCase().includes(value?.toLowerCase())
                                                    );
                                                    if (!found?.length) {
                                                        dispatch(
                                                            fetchClient({ userName, search: value, fromAddPo: true })
                                                        );
                                                    }
                                                } else if (
                                                    (actionMeta.action === 'menu-close' &&
                                                        actionMeta.prevInputValue !== '') ||
                                                    (actionMeta.action === 'input-change' && value === '')
                                                ) {
                                                    dispatch(fetchClient({ userName, fromAddPo: true }));
                                                }
                                            }}
                                            onClear={() => dispatch(fetchClient({ userName, fromAddPo: true }))}
                                        />
                                    </div>
                                </div>
                                <div className=" modal-input-holder  layout-item">
                                    <label className="modal-input-label">Vendor Name</label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={vendorDetails?.length ? vendorDetails : []}
                                            placeholder="Enter Vendor Name to select"
                                            formik={formik}
                                            fieldName="vendorCode"
                                            // extraField="vendorName"
                                            isDisabled={disableVendor}
                                            NoMessageComponent={NoMessageComponent}
                                            customStyle={vendorStyle}
                                            onInputChangeFn={(value: string, actionMeta: InputActionMeta) => {
                                                if (value?.length > 2) {
                                                    const found = vendorDetails.filter((res: any) =>
                                                        res.label?.toLowerCase().includes(value?.toLowerCase())
                                                    );
                                                    if (!found?.length) {
                                                        dispatch(
                                                            fetchVendor({
                                                                clientCode: clientUid,
                                                                search: value,
                                                                fromAddPo: true,
                                                            })
                                                        );
                                                    }
                                                } else if (
                                                    (actionMeta.action === 'menu-close' &&
                                                        actionMeta.prevInputValue !== '') ||
                                                    (actionMeta.action === 'input-change' && value === '')
                                                ) {
                                                    dispatch(
                                                        fetchVendor({
                                                            clientCode: clientUid,
                                                            fromAddPo: true,
                                                        })
                                                    );
                                                }
                                            }}
                                            onClear={() =>
                                                dispatch(
                                                    fetchVendor({
                                                        clientCode: clientUid,
                                                        fromAddPo: true,
                                                    })
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-submit-btn">
                                    <button
                                        disabled={
                                            formik.values.clientCode === '' ||
                                            formik.values.vendorCode === '' ||
                                            formik.values.poNumber === ''
                                        }
                                        className="app-btn app-btn-primary modal-btn form-btn"
                                        title="Submit"
                                        form="my-form"
                                        type="submit"
                                    >
                                        <span className="button-text footer-button-text">Submit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
