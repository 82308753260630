/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import './po-basic-modal.scss';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CancelModal from './cancelModal';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { createPo } from '../../../store/reducers/purchase_order/basicDetails';
import { useDispatch } from 'react-redux';
import { getAddress } from '../../../store/reducers/purchase_order/basicDetails';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';

interface BioProps {
    displayFn: any;
    display: boolean;
    data: any;
    edit: boolean;
    editData?: any;
    id?: string;
    setisEdit: any;
    addressVal: any;
}
interface requiredForm {
    poId: string;
    ordertype: string;
    shippingmode: string;
    // fclservicetype: string;
    // freightterms: string;
    incoTerm: string;
    releaseType: string;
    blp: string;
    blpAgentCode: string;
    blpAddress: string;
    blpAddressId: string;
    // originPort: string;
    // destinationPort: string;
    // isMultiAddress: boolean;
    // destinationAddress: string;
    // destinationAddressId: string;
    // agentCode: string;
    // agentName: string;
}
// const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
const Index: React.FC<BioProps> = (props) => {
    const { data } = useSelector((state: RootState) => state.purchaseOrder);
    // const [isChecked, setisChecked] = useState(false);
    const [cancelModal, setCancelModal] = useState(false);
    // const [portCode, setportCode] = useState('');
    const dispatch = useDispatch();
    const initialValues: requiredForm = {
        poId: props.id as any,
        ordertype: props.editData.ordertype?.orderTypeName || 'BLP - Inbound Bulk PO',
        shippingmode: props.editData.shippingmode?.shippingModeName || 'Land',
        // freightterms: props.editData.freightterms?.freightTermsCode || '',
        incoTerm: props.editData.incoTerm?.incoTermCode || '',
        releaseType: props.editData.releaseType?.releaseTypeName || '',
        blp: props.editData?.blpAgentName || '',
        blpAgentCode: props.editData?.blpAgentCode || '',
        blpAddress: props.editData?.blpAddress || '',
        blpAddressId: props.editData?.blpAddressId || '',
        // originPort: (props.editData.originPort !== '' && props.editData.originPort) || '',
        // destinationPort: (!isChecked && props.editData.destinationPort) || '',
        // isMultiAddress: props.editData.isMultiAddress || false,
        // destinationAddress: (!isChecked && props.editData.destinationAddress) || '',
        // destinationAddressId: (!isChecked && props.editData.destinationAddressId) || '',
        // agentName: props.editData.agentName || '',
        // agentCode: props.editData.agentCode || '',
    };

    const CommercialDetailFormSchema = Yup.object().shape({
        ordertype: Yup.string().required('Order type is required'),
        shippingmode: Yup.string().required('Shipping mode is required'),
        // freightterms: Yup.string().required('Freight term is required'),
        incoTerm: Yup.string().required('Incoterm is required'),
        releaseType: Yup.string().required('Release type is required'),
        blp: Yup.string().required('BLP is required'),
        blpAgentCode: Yup.string().required('BLP is required'),
        blpAddress: Yup.string().required('BLP address is required'),
    });
    // const validationSchema = Yup.object().shape({
    //     destinationPort: Yup.string().required('Destnation port is required'),
    //     destinationAddressId: Yup.string().required('Destination address is required'),
    //     //agents: Yup.string().required('Agent is required'),
    // });
    // const agentValidationSchema = Yup.object().shape({
    //     agentCode: Yup.string().required('Agent is required'),
    // }); /* eslint no-var: off */
    // const currentUser = data1?.userProfileRole[0]?.up_PR?.pr_ProfileCode;
    // const checkValidation = () => {
    //     let flag = true;
    //     const formikshort = formik.values;
    //     if (
    //         formikshort.ordertype &&
    //         formikshort.shippingmode &&
    //         formikshort.blp &&
    //         formikshort.blpAddress &&
    //         formikshort.incoTerm
    //         // formikshort.freightterms
    //     ) {
    //         flag = false;
    //     }
    //     return flag;
    // };
    const formik = useFormik({
        initialValues,
        enableReinitialize: false,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema:
            // currentUser === 'FORWARDER'
            //     ? !isChecked
            //         ? CommercialDetailFormSchema.concat(validationSchema).concat(agentValidationSchema)
            //         : CommercialDetailFormSchema.concat(agentValidationSchema)
            //     : !isChecked
            //     ? CommercialDetailFormSchema.concat(validationSchema)
            CommercialDetailFormSchema,
        onSubmit: (values: any) => {
            let data = {};
            data = {
                ...values,
                inboundPo: true,
                // destinationAddress: null,
                // destinationAddressId: null,
                // destinationPort: null,
            };

            dispatch(createPo({ payload: data, id: props.id }));
            formik.resetForm();
            props.displayFn(false);
        },
    });
    // useEffect(() => {
    //     var code = portCode ? portCode : props.editData.originPort;
    //     dispatch(getAgent(code));
    // }, [portCode, props.editData.originPort]);
    // useEffect(() => {
    //     if (props.editData.isMultiAddress) {
    //         setisChecked(props.editData.isMultiAddress);
    //     }
    // }, [props.editData.isMultiAddress]);
    const onConfirm = () => {
        props.displayFn(false);
        props.setisEdit(false);
    };

    useEffect(() => {
        if (formik.values.blpAgentCode) {
            dispatch(
                getAddress({
                    orgCode: formik.values.blpAgentCode,
                })
            );
            if (formik.values?.blpAgentCode !== props.editData?.blpAgentCode) {
                formik.setFieldValue('blpAddress', '');
                formik.setFieldValue('blpAddressId', '');
            }
        }
        if (!formik.values?.blp) {
            formik.setFieldValue('blpAddress', '');
            formik.setFieldValue('blpAddressId', '');
        }
    }, [formik.values.blpAgentCode]);
    useEffect(() => {
        if (formik.values?.blpAddressId) {
            formik.setFieldError('blpAddress', undefined);
        }
    }, [formik.values.blpAddressId]);

    const onCancel = () => {
        setCancelModal(false);
    };

    useBrowserBackCheck(formik.dirty, () => setCancelModal(true));
    return (
        <div className="app-modal purchase-detail-modal">
            <div className="modal-content-holder medium-popup ">
                <div className="modal-header">
                    <div className="title">Required Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            !formik.dirty ? props.displayFn(false) : setCancelModal(true);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="modal-form layout-holder one-column"
                            id="basicForm"
                        >
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Order Type<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="BLP - Inbound Bulk PO"
                                        value={formik.values.ordertype}
                                        type="text"
                                        className="modal-input input-text read-only"
                                        id="ordertype"
                                        name="ordertype"
                                        readOnly
                                    ></input>
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Shipping Mode<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Land"
                                        value={formik.values.shippingmode}
                                        type="text"
                                        className="modal-input input-text read-only"
                                        id="shippingmode"
                                        name="shippingmode"
                                        readOnly
                                    ></input>
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Incoterm<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item auto-dp-height">
                                    <SearchableSelectBox
                                        data={(data as any)?.incoterm}
                                        placeholder="Select Incoterm"
                                        formik={formik}
                                        fieldName="incoTerm"
                                    />
                                </div>
                                {formik.errors.incoTerm ? (
                                    <div className="error-text">{formik.errors.incoTerm}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Release Type<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={(data as any)?.releaseType}
                                    placeholder="Select Release Type"
                                    formik={formik}
                                    fieldName="releaseType"
                                />
                                {formik.errors.releaseType ? (
                                    <div className="error-text">{formik.errors.releaseType}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    BLP<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={(data as any)?.blp}
                                    placeholder=" Enter BLP Name to select"
                                    formik={formik}
                                    fieldName="blpAgentCode"
                                    extraField="blp"
                                />
                                {formik.errors.blpAgentCode ? (
                                    <div className="error-text">{formik.errors.blpAgentCode}</div>
                                ) : null}
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    BLP Address<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item auto-dp-height">
                                    <SearchableSelectBox
                                        data={props?.addressVal}
                                        placeholder={'BLP Address'}
                                        formik={formik}
                                        fieldName="blpAddressId"
                                        extraField="blpAddress"
                                        isDisabled={formik.values.blpAgentCode ? false : true}
                                    />
                                    {formik.errors.blpAddress ? (
                                        <div className="error-text">{formik.errors.blpAddress}</div>
                                    ) : null}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                        onClick={() => {
                            !formik.dirty ? props.displayFn(false) : setCancelModal(true);
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <form
                        id="basicForm"
                        onSubmit={formik.handleSubmit}
                        style={{ width: 'unset' }}
                        className="modal-form layout-holder one-column"
                    >
                        <button
                            className="app-btn app-btn-primary modal-btn footer-btn "
                            type="submit"
                            title="Save"
                            id="basicForm"
                            // disabled={
                            //     !props.edit
                            //         ? checkValidation()
                            //         : !formik.dirty ||
                            //           (formik.errors &&
                            //               formik.errors.constructor === Object &&
                            //               Object.keys(formik.errors).length > 0)
                            // }
                        >
                            <span className="button-text footer-button-text">Save</span>
                        </button>
                    </form>
                </div>
            </div>
            {cancelModal && <CancelModal onConfirm={onConfirm} onCancel={onCancel} />}
        </div>
    );
};
export default Index;
