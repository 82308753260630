import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { post } from 'src/services/HttpMethods';
import { getDateDifference } from 'src/utils';
import '../lcsBookingTiimeline.scss';
import MaxLimitModal from '../MaxLimitModal';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { RootState } from 'src/store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { lifeCycleSearchCategories } from './Common/constants';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation() as any;
    const { profileType, userName } = useUserProfile();
    const [vesselLogs, setVesselLogs] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();
    const { name } = location?.state || '';
    const { selectedData } = useSelector((state: any) => state.lifeCycleSearch);
    const { favList, removeSuccess, addSuccess, isLoading, isError, error } = useSelector(
        (state: RootState) => state.lifecyclesearchFav
    );

    const fetchBookingLogs = async () => {
        try {
            const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
            const response = await post(
                `/api/bkg/tracking/lifecycle/search/bplog?id=${id}&profile=${profileType}`,
                {
                    ...listOfUserOrganisations,
                    loggedInUserName: userName,
                },
                null
            );
           // console.log(response, 'result');
            setVesselLogs(response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchBookingLogs();
    }, [id]);

    const getCurrentPosition = () => {
        let flag = false;
        let currentPosition = 0;
        vesselLogs?.logs?.forEach((element: any, i: any) => {
            if (element?.date === null && !flag) {
                flag = true;
                currentPosition = i;
            }
        });
        return currentPosition - 1;
    };

    //checking date difference of current/etd with atd
    const getDateDifferenceFn = (atd: any) => {
        const atdValue = moment.utc(atd).local().format('YYYY-MM-DD');
        const today = moment().local().format('YYYY-MM-DD');
        const etdValue = moment.utc(vesselLogs?.etd).local().format('YYYY-MM-DD');
        if (atd) {
            return getDateDifference(etdValue, atdValue).days;
        } else {
            return getDateDifference(etdValue, today).days;
        }
    };

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.vessel,
                    favourite: 'Vessel',
                    number: `${vesselLogs?.vessel} ${vesselLogs?.voyage ? `-${vesselLogs?.voyage}` : ''}`,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">Vessel</div>
                <div className="timeline-head-value">{name}</div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="lcs-timeline-wrapper">
                {vesselLogs?.logs?.length
                    ? vesselLogs.logs?.map(
                          (res: any, i: number) =>
                              (res?.action === 'Departed_From_Origin' ||
                                  res?.action === 'InTransit' ||
                                  res?.action === 'Arrival_at_Destination') && (
                                  <div
                                      className={`lcs-timeline-item ${
                                          getCurrentPosition() == i ? 'current' : res?.date ? ' completed' : ''
                                      }`}
                                      key={i}
                                  >
                                      {res?.date && (
                                          <span className="lcs-timeline-date">
                                              {moment.utc(res?.date).local().format('DD-MM-YYYY, HH:mm')}
                                          </span>
                                      )}
                                      <h5 className="lcs-timeline-title">{res?.text}</h5>
                                      {res?.action === 'Departed_From_Origin' ? (
                                          <>
                                              <div className="lcs-timeline-etd">
                                                  <span>ETD :</span>{' '}
                                                  {moment.utc(vesselLogs?.etd).local().format('DD-MM-YYYY')}
                                              </div>
                                              <span className="lcs-timeline-status delayed">
                                                  {getDateDifferenceFn(res.date) > 0 &&
                                                      ` Delayed ` + getDateDifferenceFn(res.date) + ` Days`}
                                              </span>
                                          </>
                                      ) : res?.action === 'InTransit' ? (
                                          <>
                                              <div className="lcs-timeline-etd">
                                                  <span>Next Stop :</span> {vesselLogs?.nextStop}
                                              </div>
                                              <div className="lcs-timeline-etd">
                                                  <span>ETD :</span>{' '}
                                                  {moment.utc(vesselLogs?.etd).local().format('DD-MM-YYYY')}
                                              </div>
                                          </>
                                      ) : res?.action === 'Arrival_at_Destination' ? (
                                          <>
                                              <div className="lcs-timeline-etd">
                                                  <span>ETA :</span>{' '}
                                                  {moment.utc(vesselLogs?.eta).local().format('DD-MM-YYYY')}
                                              </div>
                                          </>
                                      ) : (
                                          <></>
                                      )}
                                  </div>
                              )
                      )
                    : null}
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`Vessel : ${vesselLogs?.vessel} ${vesselLogs?.voyage ? `-${vesselLogs?.voyage}` : ''}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
