import { get } from '../HttpMethods';
import fileDownload from 'js-file-download';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export interface ShippingLineData {
    orgId: string;
    orgName: string;
    orgCode: string;
    orgStatus: string;
    parentOrgCode: string;
    shippingLineId: string;
    shippingLineStatus: string;
    oceanCarrierName: string;
    shippingLineCode: string;
    carrierCategoryCode: string;
    relationship: string;
    // country: string[];
    childShippingLines: ShippingLineData[];
}

export interface shippingLineResponse {
    shippingLineResponseList: ShippingLineData[];
    totalNoOfPages: number;
    totalNoOfItems: number;
}

export interface shippingLineSearchData {
    content: ShippingLineData[];
    totalPages: number;
    totalElements: number;
}

// Get All Datas
export const getShippingLineData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    slOrgCode: string;
    profile: string;
}) => {
    try {
        const res = await get(
            `api/mgt/shippinglines?limit=${payload.perPageCount}&page=${payload.currentPage}&sortby=${
                payload.columnStatus
            }&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}&slOrgCode=${payload.slOrgCode ?? ''}&profile=${
                payload.profile ?? ''
            }${payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''}`,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

/**
 * call cix service and get carrier contract for a particular port pair
 * 
 * @param payload 
 * @returns object {
        "status": number,
        "message": string,
        "total": number
    }
 */
export const searchShippingLineContract = async (payload: { portPair: string; slPk: string; clientId: string }) => {
    try {
        const data = await get(
            `/cix/scr/admin/carrier/${payload.slPk}/contracts?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B1%5D%5Bfield%5D=SC_Status&filter%5Bfilters%5D%5B1%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B1%5D%5Bvalue%5D=ACTIVE&filter%5Bfilters%5D%5B2%5D%5Bfield%5D=PortPairs&filter%5Bfilters%5D%5B2%5D%5Boperator%5D=contains&filter%5Bfilters%5D%5B2%5D%5Bvalue%5D=${payload.portPair}&filter%5Bfilters%5D%5B3%5D%5Bfield%5D=Clients&filter%5Bfilters%5D%5B3%5D%5Boperator%5D=contains&filter%5Bfilters%5D%5B3%5D%5Bvalue%5D=${payload.clientId}&$count`,
            null,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        return console.error(error);
    }
};

export const exportShippingLineList = async (type: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/shippinglines/export?type=${type}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ShippingLineProfiles.xlsx' : 'ShippingLineProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
export const exportShippingLineListProfiles = async (type: string, organisationData: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        const selectedFilter = organisationData?.selectedFilter;
        const searchTerm = organisationData?.searchTerm;
        const columnOrder = organisationData?.sortPayload['columnOrder'];
        const columnStatus = organisationData?.sortPayload['columnStatus'];
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/mgt/shippinglines/export?type=${type}${
                selectedFilter ? '&filterby=' + selectedFilter : ''
            }${searchTerm ? '&keyword=' + searchTerm : ''}&sortColumn=${columnStatus}&sortOrder=${columnOrder}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'ShippingLineProfiles.xlsx' : 'ShippingLineProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchShippingLine = async (payload: {
    keyword: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
}) => {
    try {
        const res = await get(
            `api/mgt/search/shippingline?keyword=${payload.keyword}&size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus}&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }`,
            null,
            null,
            true
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};
