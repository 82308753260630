import React, { useEffect, useState } from 'react';
import './role-details.scss';
import Breadcrumbs from '../../../components/Breadcrumbs/newBreadcrumb';
import { useNavigate } from 'react-router-dom';
import ToolTip from '../../../components/ToolTip';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useDispatch } from 'react-redux';
import { deleteaRole, fetchRoleList, roleDetailsFetch, setDeletename } from '../../../store/reducers/rolesReducer';
import DeleteConfirm from '../deleteConfirm';
import Moment from 'react-moment';
import ShowForPermissions from '../../../ShowForPermissions';
// import { useUserProfile } from 'src/hooks/useUserProfile';

/* eslint no-var: off */
/* eslint-disable no-undefined */

var obj: any = [];
var stateData: any;

var rolePrivs: any = {};
var roleArray2: any = [];
var roleArray2Role: any = [];
var rolePrivsRole: any = {};

const Index: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const location = useLocation();
    const [deleteView, setdeleteView] = useState(false);
    const [total, settotal] = useState(0);
    const [totalcheck, settotalcheck] = useState(0);
    const [BlpTotal, setBlpTotal] = useState(0);
    const [quoteTotal, setQuoteTotal] = useState(0);
    const [currentActive, setcurrentActive] = useState('Admin');
    const [alldata, setalldata] = useState({});
    const [alldataRoles, setalldataRoles] = useState({});
    // const { profileType } = useUserProfile();

    const { isLoading, roleData, roleDetail } = useSelector((state: RootState) => state.rolesReducer);
    rolePrivs = { ...JSON.parse(JSON.stringify(roleData)) };
    rolePrivsRole = roleDetail && { ...JSON.parse(JSON.stringify((roleDetail as any)['info']['permissions'])) };
    const currentRoleType = localStorage.getItem('currentRoleType');
    // var view1 = {};

    useEffect(() => {
        if (currentRoleType) {
            setcurrentActive(currentRoleType);
        }
    }, [currentRoleType]);

    //changing structure of role privillages
    useEffect(() => {
        var newArrVal = [];
        var checkingNew = [];
        newArrVal = (roleData as any)?.Admin?.Profiles;
        if (newArrVal) {
            for (var i = 0; i < newArrVal?.length - 1; i += 2) {
                var checkArry = [];
                if (i < newArrVal.length - 5) {
                    for (var j = i; j < i + 2; j++) {
                        checkArry.push(newArrVal[j]);
                    }
                } else {
                    for (var k = newArrVal.length - 5; k < newArrVal.length; k++) {
                        checkArry.push(newArrVal[k]);
                    }
                }
                // eslint-disable-next-line no-undefined
                checkArry = checkArry.filter((a: any) => a !== undefined);
                checkingNew.push(checkArry);
            }
            if (stateData?.type === 'forwarder') {
                checkingNew.pop();
            }
            rolePrivs.Admin.Profiles = [...checkingNew];
            (Object.keys((rolePrivs as any)['Admin' as unknown as number]) as any).forEach((element: any) => {
                if (element === 'Settings') {
                    roleArray2.push((rolePrivs as any).Admin[element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs.Admin = { ...rolePrivs.Admin, ...newvar };
                    roleArray2 = [];
                } else if (element !== 'Profiles') {
                    if ((rolePrivs as any).Admin[element]?.length !== 1) {
                        roleArray2.push((rolePrivs as any).Admin[element]);
                        var newvar: any = {};
                        newvar[element] = roleArray2;
                        rolePrivs.Admin = { ...rolePrivs.Admin, ...newvar };
                        roleArray2 = [];
                    }
                }
            });
            (Object.keys((rolePrivs as any)['Purchase Order' as unknown as number]) as any)?.forEach((element: any) => {
                if (element !== 'Profiles') {
                    roleArray2.push((rolePrivs as any)['Purchase Order'][element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs['Purchase Order'] = { ...(rolePrivs as any)['Purchase Order'], ...newvar };
                    roleArray2 = [];
                }
            });
            (Object.keys((rolePrivs as any)['BLP' as unknown as number]) as any)?.forEach((element: any) => {
                if (element !== 'Profiles') {
                    roleArray2.push((rolePrivs as any)['BLP'][element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs['BLP'] = { ...(rolePrivs as any)['BLP'], ...newvar };
                    roleArray2 = [];
                }
            });
            (Object.keys((rolePrivs as any)['Quotes' as unknown as number]) as any)?.forEach((element: any) => {
                if (element !== 'Profiles') {
                    roleArray2.push((rolePrivs as any)['Quotes'][element]);
                    var newvar: any = {};
                    newvar[element] = roleArray2;
                    rolePrivs['Quotes'] = { ...(rolePrivs as any)['Quotes'], ...newvar };
                    roleArray2 = [];
                }
            });
        }

        setalldata(rolePrivs);
    }, [dispatch, roleData]);

    //changing structure of role details
    useEffect(() => {
        var newArrVal = [];
        var checkingNew = [];
        //var total = 0;
        newArrVal = (rolePrivsRole as any)?.Admin?.Profiles;
        if (newArrVal) {
            for (var i = 0; i < newArrVal?.length - 1; i += 2) {
                var checkArry = [];
                if (i < newArrVal.length - 3) {
                    for (var j = i; j < i + 2; j++) {
                        checkArry.push(newArrVal[j]);
                        //total++;
                    }
                } else {
                    for (var k = newArrVal.length - 3; k < newArrVal.length; k++) {
                        checkArry.push(newArrVal[k]);
                        //total++;
                    }
                }
                // eslint-disable-next-line no-undefined
                checkArry = checkArry.filter((a: any) => a !== undefined);
                checkingNew.push(checkArry);
            }
            rolePrivsRole.Admin.Profiles = [...checkingNew];
            (Object.keys((rolePrivsRole as any)['Admin' as unknown as number]) as any).forEach((element: any) => {
                if (element !== 'Profiles') {
                    if (
                        (rolePrivsRole as any).Admin[element] !== null &&
                        (rolePrivsRole as any).Admin[element].length !== 1
                    ) {
                        roleArray2Role.push((rolePrivsRole as any).Admin[element]);
                        var newvar: any = {};
                        newvar[element] = roleArray2Role;
                        rolePrivsRole.Admin = { ...rolePrivsRole.Admin, ...newvar };
                        roleArray2Role = [];
                    }
                }
            });
            //const total2 = total - 1;
            //settotal(total2);
            (Object.keys((rolePrivsRole as any)['Purchase Order' as unknown as number]) as any).forEach(
                (element: any) => {
                    if (element !== 'Profiles') {
                        if (
                            (rolePrivsRole as any)['Purchase Order'][element] !== null &&
                            (rolePrivsRole as any)['Purchase Order'][element].length !== 1
                        ) {
                            roleArray2Role.push((rolePrivsRole as any)['Purchase Order'][element]);
                            var newvar: any = {};
                            newvar[element] = roleArray2Role;
                            rolePrivsRole['Purchase Order'] = {
                                ...(rolePrivsRole as any)['Purchase Order'],
                                ...newvar,
                            };
                            roleArray2Role = [];
                        }
                    }
                }
            );
            (Object.keys((rolePrivsRole as any)['BLP' as unknown as number]) as any).forEach((element: any) => {
                if (element !== 'Profiles') {
                    if (
                        (rolePrivsRole as any)['BLP'][element] !== null &&
                        (rolePrivsRole as any)['BLP'][element].length !== 1
                    ) {
                        roleArray2Role.push((rolePrivsRole as any)['BLP'][element]);
                        var newvar: any = {};
                        newvar[element] = roleArray2Role;
                        rolePrivsRole['BLP'] = {
                            ...(rolePrivsRole as any)['BLP'],
                            ...newvar,
                        };
                        roleArray2Role = [];
                    }
                }
            });
            (Object.keys((rolePrivsRole as any)['Quotes' as unknown as number]) as any).forEach((element: any) => {
                if (element !== 'Profiles') {
                    if (
                        (rolePrivsRole as any)['Quotes'][element] !== null &&
                        (rolePrivsRole as any)['Quotes'][element].length !== 1
                    ) {
                        roleArray2Role.push((rolePrivsRole as any)['Quotes'][element]);
                        var newvar: any = {};
                        newvar[element] = roleArray2Role;
                        rolePrivsRole['Quotes'] = {
                            ...(rolePrivsRole as any)['Quotes'],
                            ...newvar,
                        };
                        roleArray2Role = [];
                    }
                }
            });
        }
        setalldataRoles(rolePrivsRole);
    }, [dispatch, roleDetail]);

    useEffect(() => {
        var retrievedObject = localStorage.getItem('testObject');
        stateData = JSON.parse(retrievedObject as any);
        dispatch(fetchRoleList({ profile: stateData?.type === 'shipping' ? 'carrier' : stateData?.type }));

        const payload = {
            roleId: stateData?.data,
            type: stateData?.type,
        };
        dispatch(roleDetailsFetch(payload));
        // view1 = roleDetail as any;
        window.scroll(0, 0);
    }, [dispatch]);

    useEffect(() => {
        var total = 0;
        var poTotal = 0;
        var blpTotal = 0;
        var quoteTotal = 0;
        if (alldataRoles !== null && Object.keys(alldataRoles).length !== 0) {
            Object.keys((roleDetail as any)?.info?.permissions).forEach((element) => {
                Object.keys((roleDetail as any)?.info?.permissions[element]).forEach((element1) => {
                    if ((roleDetail as any)?.info?.permissions[element][element1] !== null) {
                        for (var i = 0; i < (roleDetail as any)?.info?.permissions[element][element1].length; i++) {
                            if (
                                (roleDetail as any)?.info?.permissions[element][element1][i].permissionTypeName ===
                                'Admin'
                            ) {
                                total++;
                            } else {
                                if (
                                    (roleDetail as any)?.info?.permissions[element][element1][
                                        i
                                    ].permissionTypeName.toLowerCase() === 'purchase order' ||
                                    (roleDetail as any)?.info?.permissions[element][element1][
                                        i
                                    ].permissionTypeName.toLowerCase() === 'booking proposal'
                                ) {
                                    poTotal++;
                                } else if (
                                    (roleDetail as any)?.info?.permissions[element][element1][
                                        i
                                    ].permissionTypeName.toLowerCase() === 'blp'
                                ) {
                                    blpTotal++;
                                } else if (
                                    (roleDetail as any)?.info?.permissions[element][element1][
                                        i
                                    ].permissionTypeName.toLowerCase() === 'quotes'
                                ) {
                                    quoteTotal++;
                                }
                            }
                        }
                    }
                });
            });
        }
        settotalcheck(poTotal);
        settotal(total);
        setBlpTotal(blpTotal);
        setQuoteTotal(quoteTotal);
    }, [alldataRoles, roleData]);

    /* eslint-disable no-undefined */

    const checkValue = (value: any, type: any) => {
        var flagVal = false;
        if (alldataRoles !== null && Object.keys(alldataRoles).length !== 0) {
            roleDetail !== null &&
                roleDetail !== undefined &&
                (Object.keys((alldataRoles as any)[currentActive as unknown as number]) as any).forEach(
                    (element: any) => {
                        ((alldataRoles as any)[currentActive as unknown as number] as any)[element] !== null &&
                            ((alldataRoles as any)[currentActive as unknown as number] as any)[element].forEach(
                                (elementNew: any) => {
                                    if (elementNew !== null && elementNew.length > 1) {
                                        elementNew.forEach((element2: any) => {
                                            obj.push(element2);
                                            if (
                                                element2 !== undefined &&
                                                element2.access === value &&
                                                element2?.type === type
                                            ) {
                                                flagVal = true;
                                            }
                                        });
                                    } else {
                                        obj.push(elementNew);

                                        if (elementNew.access === value && elementNew.type === type) {
                                            flagVal = true;
                                        }
                                    }
                                }
                            );
                    }
                );
        }

        return flagVal;
    };

    const onConfirm = () => {
        setdeleteView(false);
        dispatch(deleteaRole(stateData?.data));
        dispatch(setDeletename(stateData?.name));
        setTimeout(() => {
            navigate(`/rolespermissions/${stateData?.type}`, {
                state: {
                    name: stateData?.name,
                },
            });
        }, 1000);
    };
    const onCancel = () => {
        setdeleteView(false);
    };

    return (
        <div className="main-wrapper roledetails-main-wrapper">
            <div className="main-header-content-holder grid-pages">
                <Breadcrumbs
                    second={'Roles & Permissions'}
                    third={stateData?.type?.toUpperCase()}
                    fourth={roleDetail !== null && (roleDetail as any)?.info?.roleName}
                />
                <div className="pageheading-holder">
                    <button
                        className="back-btn app-btn"
                        onClick={() => navigate(`/rolespermissions/${stateData?.type}`, { state: { reload: true } })}
                    >
                        <svg className="svg-icon back-icon">
                            <use xlinkHref="#previousArrowIcon">
                                <title>Back</title>
                            </use>
                        </svg>
                    </button>
                    <h2 className="page-heading">Role Details</h2>
                    {(roleDetail as any)?.info?.roleName?.toLowerCase() !== 'executive' &&
                        (roleDetail as any)?.info?.roleName?.toLowerCase() !== 'it admin' && (
                            <ShowForPermissions permission="edit" type="roles">
                                <button
                                    className={
                                        stateData?.status === 'ACTIVE'
                                            ? 'app-btn app-btn-secondary footer-btn disabled'
                                            : 'app-btn app-btn-secondary footer-btn'
                                    }
                                    title={
                                        stateData?.status !== 'ACTIVE'
                                            ? 'Delete Role'
                                            : 'Deactivate the role to enable delete'
                                    }
                                    onClick={() => stateData?.status !== 'ACTIVE' && setdeleteView(true)}
                                >
                                    <svg className="svg-icon delete-icon">
                                        <use xlinkHref="#deleteIcon"></use>
                                    </svg>
                                    <span className="button-text footer-button-text">Delete Role</span>
                                </button>
                            </ShowForPermissions>
                        )}
                </div>
            </div>
            <div className="main-content-holder">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="content-section main-wrapper roledetails">
                    <div className="card details-box">
                        <div className="details-col">
                            <div className="box-content-holder">
                                <h6 className="details-box-heading">Role Name</h6>
                                <div className="details-box-content">
                                    {roleDetail && (roleDetail as any)?.info?.roleName}
                                </div>
                            </div>
                            <div className="box-content-holder">
                                <h6 className="details-box-heading">Profile Type</h6>
                                <div className="details-box-content">
                                    <p>
                                        {roleDetail &&
                                        (roleDetail as any)?.info?.profileType?.toLowerCase() === 'shipping'
                                            ? 'CARRIER'
                                            : (roleDetail as any)?.info?.profileType}
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="details-col">
                            <div className="box-content-holder">
                                <h6 className="details-box-heading">Created On</h6>
                                <div className="details-box-content">
                                    <Moment format="DD-MM-YYYY">{(roleDetail as any)?.info?.createdOn}</Moment>
                                </div>
                            </div>
                            {stateData?.type.toLowerCase() !== 'forwarder' ? (
                                <div className="box-content-holder">
                                    <h6 className="details-box-heading" style={{ textTransform: 'capitalize' }}>
                                        {stateData?.type.toLowerCase() === 'shipping' ? 'Carrier' : stateData?.type}{' '}
                                        Name
                                    </h6>

                                    <ToolTip>
                                        <div className="details-box-content">
                                            <p>
                                                {roleDetail && (roleDetail as any)?.info?.orgNames
                                                    ? roleDetail && (roleDetail as any)?.info?.orgNames
                                                    : 'ALL'}
                                            </p>
                                        </div>
                                    </ToolTip>
                                </div>
                            ) : (
                                <div className="box-content-holder address-details">
                                    <h6 className="details-box-heading">Users</h6>
                                    <div className="details-box-content">
                                        <div className="customscrollbar" style={{ overflowX: 'auto' }}>
                                            {roleDetail &&
                                                (roleDetail as any)?.info?.users &&
                                                roleDetail &&
                                                (roleDetail as any)?.info?.users.map((res: any) => (
                                                    <p className="list">{res}</p>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="details-col">
                            <div className="box-content-holder">
                                <h6 className="details-box-heading">Last Modified</h6>
                                <div className="details-box-content">
                                    <Moment format="DD-MM-YYYY">{(roleDetail as any)?.info?.lastModifiedDate}</Moment>
                                </div>
                            </div>
                            {stateData?.type.toLowerCase() !== 'forwarder' && (
                                <div className="box-content-holder address-details">
                                    <h6 className="details-box-heading">Users</h6>
                                    <div className="details-box-content">
                                        <div className="customscrollbar" style={{ overflowX: 'auto' }}>
                                            {roleDetail &&
                                                (roleDetail as any)?.info?.users &&
                                                roleDetail &&
                                                (roleDetail as any)?.info?.users.map((res: any) => (
                                                    <p className="list">{res}</p>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="tabcontent">
                        <h6 className="title">Permissions</h6>
                        <div className="tab-nav-holder">
                            <a
                                className={`tab-nav ${currentActive === 'Admin' ? 'active' : ''}`}
                                href="javascript:void(0)"
                                onClick={() => setcurrentActive('Admin')}
                            >
                                Admin <span className="count">{total}</span>
                            </a>
                            {(alldata as any)['Purchase Order'] !== undefined &&
                                Object.keys((alldata as any)['Purchase Order'] as any)?.length > 0 && (
                                    <a
                                        className={`tab-nav ${currentActive === 'Purchase Order' ? 'active' : ''}`}
                                        href="javascript:void(0)"
                                        onClick={() => setcurrentActive('Purchase Order')}
                                    >
                                        Purchase Order & BP<span className="count">{totalcheck}</span>
                                    </a>
                                )}
                            {(alldata as any)['BLP'] !== undefined &&
                                Object.keys((alldata as any)['BLP'] as any)?.length > 0 && (
                                    <a
                                        className={`tab-nav ${currentActive === 'BLP' ? 'active' : ''}`}
                                        href="javascript:void(0)"
                                        onClick={() => setcurrentActive('BLP')}
                                    >
                                        BLP<span className="count">{BlpTotal}</span>
                                    </a>
                                )}
                            {stateData?.type === 'forwarder' && (
                                <>
                                    <a className="tab-nav" href="javascript:void(0)">
                                        Tracking
                                    </a>
                                    <a className="tab-nav" href="javascript:void(0)">
                                        Reports
                                    </a>
                                </>
                            )}
                            {(alldata as any)['Quotes'] !== undefined &&
                                Object.keys((alldata as any)['Quotes'] as any)?.length > 0 && (
                                    <a
                                        className={`tab-nav ${currentActive === 'Quotes' ? 'active' : ''}`}
                                        href="javascript:void(0)"
                                        onClick={() => setcurrentActive('Quotes')}
                                    >
                                        Quotes<span className="count">{quoteTotal}</span>
                                    </a>
                                )}
                        </div>
                    </div>

                    <div className="grid-section add-roles-grid">
                        <div className="grid-holder">
                            <div className="grid-header"></div>
                            <div className="grid-container">
                                <div className="grid-scroll-content">
                                    <table className="grid-table">
                                        <tbody>
                                            {Object.keys(alldata).length !== 0 &&
                                                (
                                                    Object.keys(
                                                        (alldata as any)[currentActive as unknown as number]
                                                    ) as any
                                                ).map((key: any, index: any) => (
                                                    <tr key={index} className="has-child row-expanded">
                                                        <td
                                                            className="grid-cell grid-cell-profile"
                                                            style={{ width: '20%' }}
                                                        >
                                                            <div className="grid-cell-data">
                                                                <div className="checkbox-wrapper">
                                                                    <div className="checkbox-item">
                                                                        <label className="app-check-wrapper">
                                                                            <div className="checkbox-label">{key}</div>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td
                                                            className="grid-cell"
                                                            style={{ display: 'flex', flexWrap: 'wrap' }}
                                                        >
                                                            <div
                                                                style={{
                                                                    display: 'flex',
                                                                    flexDirection: 'column',
                                                                    width: '100%',
                                                                }}
                                                            >
                                                                <div className="grid-cell-data">
                                                                    {Object.keys(alldata).length !== 0 &&
                                                                        (
                                                                            (alldata as any)[
                                                                                currentActive as unknown as number
                                                                            ] as any
                                                                        )[key].map((val: any, i: any) => (
                                                                            <div
                                                                                className="checkbox-wrapper"
                                                                                style={{
                                                                                    display: 'flex',
                                                                                    flexWrap: 'wrap',
                                                                                    padding: '0px',
                                                                                }}
                                                                            >
                                                                                {Object.keys(alldata).length !== 0 &&
                                                                                    (
                                                                                        (alldata as any)[
                                                                                            currentActive as unknown as number
                                                                                        ] as any
                                                                                    )[key][i].map((newVal: any) => (
                                                                                        <div
                                                                                            className="checkbox-item"
                                                                                            style={{
                                                                                                width: '30%',
                                                                                                padding: '32px 0px',
                                                                                            }}
                                                                                        >
                                                                                            <label className="app-check-wrapper">
                                                                                                {checkValue(
                                                                                                    newVal.access,
                                                                                                    newVal.type
                                                                                                ) ? (
                                                                                                    <svg className="svg-icon tickblue-icon">
                                                                                                        <use xlinkHref="#ticktableIcon"></use>
                                                                                                    </svg>
                                                                                                ) : (
                                                                                                    <svg className="svg-icon tickblue-icon">
                                                                                                        <use xlinkHref="#closetableIcon"></use>
                                                                                                    </svg>
                                                                                                )}
                                                                                                <div className="checkbox-label">
                                                                                                    {newVal.access
                                                                                                        ?.toLowerCase()
                                                                                                        ?.includes(
                                                                                                            'shipping'
                                                                                                        )
                                                                                                        ? newVal.access
                                                                                                              ?.toLowerCase()
                                                                                                              ?.includes(
                                                                                                                  'view'
                                                                                                              )
                                                                                                            ? 'View Carrier Profile'
                                                                                                            : 'Edit Carrier Profile'
                                                                                                        : newVal.access}
                                                                                                </div>
                                                                                            </label>
                                                                                        </div>
                                                                                    ))}
                                                                            </div>
                                                                        ))}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <button
                        className="app-btn app-btn-secondary footer-btn"
                        title="Cancel"
                        onClick={() => navigate(`/rolespermissions/${stateData?.type}`)}
                        type="button"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    {(roleDetail as any)?.info?.roleName?.toLowerCase() !== 'executive' &&
                        (roleDetail as any)?.info?.roleName?.toLowerCase() !== 'it admin' && (
                            <ShowForPermissions permission="edit" type="roles">
                                <button
                                    className="app-btn app-btn-primary footer-btn"
                                    title="Edit Role"
                                    type="button"
                                    onClick={() =>
                                        navigate('/rolespermissions/roleEdit', {
                                            state: {
                                                data: roleDetail && (roleDetail as any)?.info,
                                                id: stateData?.data,
                                                type: stateData?.type,
                                                status: stateData?.status,
                                                currentType: currentActive,
                                            },
                                        })
                                    }
                                >
                                    <span className="button-text footer-button-text">Edit Role</span>
                                </button>
                            </ShowForPermissions>
                        )}

                    {/* <NavLink to="/profile/clients/vendor_details" className="app-btn app-btn-primary">
                            <span className="button-text vendor-button-text">Next</span>
                        </NavLink> */}
                </div>
            </div>
            {deleteView && (
                <DeleteConfirm
                    onConfirm={onConfirm}
                    onCancel={onCancel}
                    name={roleDetail && (roleDetail as any)?.info?.roleName}
                />
            )}
        </div>
    );
};

export default Index;
