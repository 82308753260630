import Pagination from 'src/components/Pagination/Pagination';
import { paginationLimit } from '../../../utils/constants';
// import { useDispatch } from 'react-redux';

interface footerItemProps {
    displayCountfn: any;
    pageCountDisplay: any;
    totalItems: any;
    setcurrentPage: any;
    setperPageCount: any;
    perPageCount: any;
    currentPage: any;
    items: any;
    totalPages: any;
}
const tableFooter2: React.FC<footerItemProps> = ({
    displayCountfn,
    pageCountDisplay,
    totalItems,
    setcurrentPage,
    setperPageCount,
    perPageCount,
    currentPage,
    items,
    totalPages,
}) => {
    // const dispatch = useDispatch();
    return totalItems > 0 ? (
        <div className="grid-footer">
            <div className="grid-footer-left">
                {/* toast message */}
                {/* <div className="toast-holder">
                                    <svg className="svg-icon checkmark-icon">
                                        <use xlinkHref="#checkmarkIcon">
                                            <title>Vendors linked</title>
                                        </use>
                                    </svg>
                                    <div className="toast-message">6 new vendors have been linked</div>
                                    <svg className="svg-icon close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </div> */}
                <div className="footer-text">Showing</div>
                <div className="row-per-page">
                    <div
                        className={
                            'dropdown-wrap pagination-dropdown menu-up ' + (pageCountDisplay ? 'dropdown-open' : '')
                        }
                    >
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                displayCountfn(!pageCountDisplay);
                            }}
                            className="menu-btn app-btn"
                        >
                            {totalItems < perPageCount ? (
                                <span className="btn-text">{totalItems}&nbsp;</span>
                            ) : (
                                <span className="btn-text">
                                    {perPageCount > items?.length ? items?.length : perPageCount}&nbsp;
                                </span>
                            )}
                            <span className="dropdown-arrow">
                                <svg className="svg-icon arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>dropdown</title>
                                    </use>
                                </svg>
                            </span>
                        </button>

                        {pageCountDisplay && (
                            <div className="dropdown-menu">
                                {paginationLimit.map((res) => (
                                    <a
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            displayCountfn(false);
                                            setcurrentPage(0);
                                            setperPageCount(res);
                                        }}
                                        className="menu-item app-btn"
                                    >
                                        {res}
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
                <div className="footer-text">
                    <span>of</span>
                    <span className="grid-total-row-count">{totalItems}</span>
                    <span>{totalItems > 1 ? 'entries' : 'entry'}</span>
                </div>
            </div>
            <div className="grid-footer-right">
                <div className="grid-pagination">
                    <Pagination
                        currentPage={currentPage + 1}
                        totalCount={totalItems}
                        pageSize={perPageCount}
                        onPageChange={(page: any) => setcurrentPage(page - 1)}
                        totalPageSize={totalPages + 1}
                    />
                </div>
            </div>
        </div>
    ) : null;
};

export default tableFooter2;
