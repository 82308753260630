import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import BookingAttachments from '../BookingAttachment';
import { fetchFiles, setWarning } from 'src/store/reducers/bookingProposal/attachmentsReducer';
import { updateBP, clearFailure } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { fetchBpHeaderData } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import BookingHeaderSidebar from './../BookingHeaderSidebar';
import ShowForPermissions from 'src/ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { error, Position, success } from 'src/utils/toast';
import { getUserDetails } from 'src/utils/getUserDetails';
import { useAppSelector } from '../../../hooks';
import { fetchNacAdditionalDetails } from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import {
    selectEnrichBooking,
    fetchEnrichBooking,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import {
    // selectContainers,
    fetchContainers,
    resetFields,
} from '../../../store/reducers/bookingProposal/containerAllocationReducer';
import { Icon } from 'semantic-ui-react';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { cixDownloadFile, fileEventHistory } from 'src/services/apis/documentApi';
import DeleteModal from './deleteConfirmModal';
import EventModal from './eventModal';
import { resetDeleteResult } from 'src/store/reducers/documentReducer';
import FileUploadModal from 'src/pages/Common/fileUploader';

const Index: React.FC = () => {
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { submitBPSuccess, submitBPError } = useSelector((state: RootState) => state.additionalDetailsData);
    const { files, isLoading, errorMessage, errorStat } = useSelector((state: RootState) => state.attachmentsReducer);
    const { bpStatus, gateInDateExpired } = useSelector((state: RootState) => state.bpHeaderData);
    const { profileType } = useUserProfile();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [validation, setvalidation] = useState(false);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const [nextBtn, setNextBtn] = useState(false);
    const [nextAddBtn, setNextAddBtn] = useState(false);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [doPk, setDoPk] = useState('');
    const { deleteResult } = useSelector((state: RootState) => state.document);
    const [eventHistory, setEventHistory] = useState([]);
    /* eslint no-var: off */

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { additionalDetails } = useSelector((state: RootState) => state.additionalDetailsNac);
    // const containers = useAppSelector(selectContainers);

    //Show the toast after successful booking proposal approval
    useEffect(() => {
        if (submitBPSuccess) {
            success(`Booking details sent successfully`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPError) {
            error(`Something went wrong`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPSuccess || submitBPError) {
            dispatch(clearFailure([]));
            navigate('/booking_list');
        }
    }, [submitBPSuccess, submitBPError]);
    useEffect(() => {
        dispatch(fetchFiles(id));
        dispatch(fetchBpHeaderData({ bpId: id, profileType }));
    }, [id]);

    // var stat = false;

    useEffect(() => {
        dispatch(setWarning({ errorStat: false, message: '' }));
        if (errorStat) {
            error(errorMessage, Position.TOP_RIGHT);
        }
    }, [errorStat, errorMessage]);

    useEffect(() => {
        dispatch(fetchEnrichBooking({ bpId: id }));

        // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
    }, []);

    useEffect(() => {
        dispatch(fetchNacAdditionalDetails({ bpId: id }));
    }, []);
    useEffect(() => {
        dispatch(fetchContainers({ bpId: id }));
        dispatch(resetFields({}));
    }, []);
    useEffect(() => {
        if (headerData.bpStatus === 'Draft_Booking') {
            if (
                enrichBooking &&
                enrichBooking?.soNumber &&
                enrichBooking?.originCartageCompany &&
                enrichBooking?.houseBillNo
            ) {
                setNextBtn(true);
            } else {
                setNextBtn(false);
            }
        }
    }, [headerData.bpStatus, enrichBooking]);

    useEffect(() => {
        // if (additionalDetails?.contractType === 'FAK' && profileType === 'forwarder') {
        //     if (
        //         additionalDetails?.savedFakFileName &&
        //         additionalDetails?.totOriginCost &&
        //         additionalDetails?.sellQuotationName &&
        //         additionalDetails?.sellTotOriginCost
        //     ) {
        //         setNextAddBtn(true);
        //     }
        // }
        const headerDataForContainer = JSON?.parse(JSON?.stringify(headerData));
        if (additionalDetails?.contractType === 'FAK' && profileType === 'agent') {
            if (additionalDetails?.savedFakFileName && additionalDetails?.totOriginCost) {
                setNextAddBtn(true);
            }
        }
        if (additionalDetails?.contractType === 'NAC') {
            setNextAddBtn(true);
        }
        if (headerDataForContainer?.tabValues?.containerDetails === false) {
            setNextAddBtn(false);
        }
    }, [additionalDetails, headerData]);

    useEffect(() => {
        if (!gateInDateExpired) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    }, [files, gateInDateExpired]);

    const navData = [
        {
            title: 'AO Selection',
            selected: 0,
            path: id ? `/Aobooking_list/${id}/AoSelection` : '/Aobooking_list/AoSelection',
            disabled: false,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/Aobooking_list/${id}/SailingSelectionDetails`,
            disabled: false,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/Aobooking_list/${id}/additional-details`,
            disabled: false,
        },
    ];
    // if (
    //     headerData.bpStatus === 'Ready_for_Booking' ||
    //     headerData.bpStatus === 'Draft_Booking' ||
    //     headerData.bpStatus === 'Booked'
    // ) {
    navData.push(
        {
            title: 'Container Allocation',
            selected: 0,
            path: `/Aobooking_list/${id}/container-allocation`,
            disabled: false,
        }

        // {
        //     title: 'Attachments',
        //     selected: 1,
        //     path: `/Aobooking_list/${id}/attachments`,
        //     disabled: false,
        // }
    );
    // }

    const submitReadyForShipment = () => {
        const { userName } = getUserDetails();
        dispatch(updateBP({ bookingId: id, userName, profile: profileType }));
    };
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    useEffect(() => {
        if (deleteResult?.length > 0) {
            if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
            dispatch(resetDeleteResult({}));
            setShowDeleteModal(false);
            setDoPk('');
        }
    }, [deleteResult]);

    const downloadFile = (doPk: string, filename: string) => {
        cixDownloadFile(doPk, filename);
    };

    //@ts-ignore
    window.attachmentAction = (action: string, doPK?: string, filename?: string) => {
        if (action === 'DLD') {
            if (doPK && filename) downloadFile(doPK, filename);
        } else if (action === 'DEL') {
            if (doPK) {
                setDoPk(doPK);
                setShowDeleteModal(true);
            }
        } else if (action === 'EVT') {
            if (doPK) {
                fileEventHistory(doPK ?? '')
                    .then((res: any) => {
                        setEventHistory(res);
                        setShowEventModal(true);
                    })
                    .catch((err: any) => console.log(err));
            }
        } else if (action === 'NEW') {
            setShowUploadModal(true);
        }
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>
                {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )}
                <BookingProcessTab navData={navData} />
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper grid-p-0">
                <div className="grid-header grid-ml-20">
                    <div className="content-heading-holder card-head-btn-edit-holder">
                        <span className="content-heading">
                            Attachments
                            <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                <span className="hub-tooltiptext" style={{ width: '400px' }}>
                                    Attachments will show documents uploaded for this booking, its children, or linked
                                    POs.
                                </span>
                                <Icon name="question circle outline" />
                            </span>
                        </span>
                    </div>
                </div>

                <LayoutWithColumns content={'bookingDocuments'} hideLoader style="booking_details" />
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' && (bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') && (
                        <ShowForPermissions type="bp" permission="create">
                            <button
                                title="Cancel Booking"
                                className="app-btn app-btn-secondary footer-btn"
                                onClick={() => {
                                    setShowBookingCancelModal(true);
                                }}
                            >
                                <span className="button-text footer-button-text">Cancel Booking</span>
                            </button>
                        </ShowForPermissions>
                    )} */}
                    {/* <Link title="Save" className="app-btn app-btn-secondary footer-btn" to={'/booking_list'}>
                        <span className="button-text footer-button-text">Save as Draft</span>
                    </Link> */}
                    <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/Aobooking_list/${id}/container-allocation`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <ShowForPermissions type="bp" permission="create">
                        {profileType === 'agent' && bpStatus !== 'Booked' && (
                            <a
                                title="Ready for Shipment"
                                className={`app-btn app-btn-primary footer-btn ${
                                    validation && nextBtn && nextAddBtn ? '' : 'disabled'
                                }`}
                                onClick={() => {
                                    if (validation && nextBtn && nextAddBtn) {
                                        submitReadyForShipment();
                                    }
                                }}
                            >
                                <span className="button-text footer-button-text">Ready for Shipment</span>
                                {/* </button> */}
                            </a>
                        )}
                    </ShowForPermissions>
                </div>
            </div>

            {showUploadModal && (
                <FileUploadModal setShowFileUploadModal={setShowUploadModal} referenceId={id} referenceType={'BP'} />
            )}

            {showDeleteModal && (
                <DeleteModal setModalView={setShowDeleteModal} modalView={showDeleteModal} doPK={doPk} />
            )}

            {showEventModal && <EventModal setModalView={setShowEventModal} eventHistory={eventHistory} />}

            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
        </div>
    );
};
export default Index;
