import React from 'react';
// import './style.css';
import '../../../assets/scss/components/_modal.scss';

interface modalProps {
    onOkay: () => void;
    onCancel: () => void;
    title: string;
    message: string;
}

const Index: React.FC<modalProps> = (props) => {
    return (
        <div className="app-modal container">
            <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2 className="title">{props.title}</h2>
                    <button className="app-btn modal-close-btn" onClick={() => props.onCancel()}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <p>{props.message}</p>
                </div>
                <div className="modal-footer">
                    <button className="app-btn app-btn-secondary modal-btn footer-btn" onClick={() => props.onCancel()}>
                        <span className="button-text vendor-button-text">No</span>
                    </button>
                    <button
                        style={{ marginLeft: '8px' }}
                        className="app-btn app-btn-primary modal-btn footer-btn"
                        onClick={() => {
                            props.onOkay();
                        }}
                    >
                        <span className="button-text vendor-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
