/* eslint-disable no-undefined */
import React from 'react';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { togglePort } from 'src/services/apis/quoteAPi';
import { success, error, Position } from 'src/utils/toast';

const Index: React.FC = () => {
    const refreshGrid = () => {
        const refresh: any = document.querySelector('.k-pager-refresh');
        if (refresh) {
            refresh?.click();
        }
    };
    //@ts-ignore
    window.setMainPort = (event: string, key: string) => {
        togglePort(key)
            .then((res: any) => {
                success('Port updated successfully', Position.TOP_RIGHT);
                refreshGrid();
            })
            .catch((err: any) => {
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                console.log(err);
            });
    };

    return (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Ports</h2>
                <div className="grid-section grid-p-0">
                    <div className="grid-holder">
                        <LayoutWithColumns style={'report'} content={'qms-config-port'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
