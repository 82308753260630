import { get } from '../HttpMethods';
import fileDownload from 'js-file-download';

export interface IData {
    id: number;
    name: string;
    email: string;
}

// Get All Datas

export const getAllData = async (value: number) => {
    try {
        const res = await get(`posts/${value}/comments`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
export const exportFile = async (payload: {
    type: string;
    profile: string;
    data: any;
    filterRoles?: any;
    filter?: any;
    filterValue?: any;
    filterProfileStatus?: any;
    keyword?: any;
    sortPayload?: any;
}) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        const columnOrder = payload?.sortPayload['columnOrder'];
        const columnStatus = payload?.sortPayload['columnStatus'];
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/mgt/user/export?type=${payload.type}&profile=${payload.profile}${
                payload?.filterRoles ? '&filterRoles=' + payload?.filterRoles : ''
            }${payload?.filterProfileStatus ? '&filterProfileStatus=' + payload?.filterProfileStatus : ''}${
                payload?.keyword ? '&keyWord=' + payload?.keyword : ''
            }&sortColumn=${columnStatus}&sortOrder=${columnOrder}${
                payload?.filter ? '&filter=' + payload?.filter + '&filterValue=' + payload?.filterValue : ''
            } `,
            {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(payload.data),
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName =
                    payload.type === 'excel'
                        ? `${payload.profile.toUpperCase()} List.xlsx`
                        : `${payload.profile.toUpperCase()} List.pdf`;
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
