import axios from 'axios';
import { get, post } from '../../HttpMethods';

export const getAddedPoLinesAir = async (payload: { bpId: string }) => {
    try {
        const res = await get(`api/bkg/bp/added/polines?bpId=${payload.bpId}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const removePoFromBpAir = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/remove/subpolines?type=air', payload, null);
        return res;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const searchAirAdded = async (payload: any) => {
    try {
        const res = await post(`api/ord/search/addedair?keyword=${payload.val}&bpId=${payload.id}`, payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
