import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchCommercialDetailsColumnListFailed,
    fetchCommercialDetailsColumnListSuccess,
    fetchCommercialDetailsList,
    fetchCommercialDetailsListFailed,
    fetchCommercialDetailsListSuccess,
    fetchCommercialDetailsColumnList,
    isLoadingFn,
    createCommercialDetailsSuccess,
    createCommercialDetailsFailure,
    updateCommercialDetailsSuccess,
    updateCommercialDetailsFailure,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    fetchForwarderSignatoryDetailsSuccess,
    fetchForwarderSignatoryDetailsFailure,
    createCommercialDetails,
    updateCommercialDetails,
    fetchAuthorDetails,
    fetchForwarderSignatoryDetails,
    fetchAgreementTermsDetails,
    fetchAgreementTermsDetailsSuccess,
    fetchAgreementTermsDetailsFailure,
    submitAgent,
    submitAgentSuccess,
    submitAgentFailure,
    searchCommercialDetailsData,
    searchCommercialDetailsDataSuccess,
    searchCommercialDetailsDataFailure,
} from '../../../reducers/profiles/agents/commercialDetailsReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchCommercialDetailsListData,
    fetchCommercialDetailsColumnListData,
    createCommercialDetailsApi,
    updateCommercialDetailsApi,
    fetchCommercialDetailsAuthorApi,
    fetchCommercialDetailsForwarderSignatoryApi,
    fetchCommercialDetailsAgreementTermsApi,
    submitAndActivateAgentApi,
    searchCommercialDetails,
} from '../../../../services/apis/profiles/agents/commercialDetailsApi';

// eslint-disable-next-line
function* getCommercialDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchCommercialDetailsListData, payload);
        yield put({ type: fetchCommercialDetailsListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchCommercialDetailsListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getCommercialDetailsColumn({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchCommercialDetailsColumnListData, payload);
        yield put({ type: fetchCommercialDetailsColumnListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchCommercialDetailsColumnListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* createCommercialDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createCommercialDetailsApi, payload);
        yield put({ type: createCommercialDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: createCommercialDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateCommercialDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateCommercialDetailsApi, payload);
        yield put({ type: updateCommercialDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateCommercialDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* submitAndActivateAgent({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(submitAndActivateAgentApi, payload);
        yield put({ type: submitAgentSuccess, payload: response });
    } catch (error) {
        yield put({ type: submitAgentFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getAuthorCommercialDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchCommercialDetailsAuthorApi);
        yield put({ type: fetchAuthorDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAuthorDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* getForwarderSignatoryCommercialDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchCommercialDetailsForwarderSignatoryApi);
        yield put({ type: fetchForwarderSignatoryDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchForwarderSignatoryDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getAgreementTermsCommercialDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchCommercialDetailsAgreementTermsApi);
        yield put({ type: fetchAgreementTermsDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAgreementTermsDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getCommercialDetailsSearchData({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(searchCommercialDetails, payload);
        yield put({ type: searchCommercialDetailsDataSuccess, payload: response });
    } catch (error) {
        yield put({ type: searchCommercialDetailsDataFailure, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* agentCommercialDetailsSaga() {
    yield takeEvery(fetchCommercialDetailsList, getCommercialDetails);
    yield takeEvery(fetchCommercialDetailsColumnList, getCommercialDetailsColumn);
    yield takeEvery(createCommercialDetails, createCommercialDetailsData);
    yield takeEvery(updateCommercialDetails, updateCommercialDetailsData);
    yield takeEvery(fetchAuthorDetails, getAuthorCommercialDetails);
    yield takeEvery(fetchForwarderSignatoryDetails, getForwarderSignatoryCommercialDetails);
    yield takeEvery(fetchAgreementTermsDetails, getAgreementTermsCommercialDetails);
    yield takeEvery(submitAgent, submitAndActivateAgent);
    yield takeEvery(searchCommercialDetailsData, getCommercialDetailsSearchData);
}

export default agentCommercialDetailsSaga;
