import React, { useEffect, useState } from 'react';
import RequiredDetailsModal from './RequiredDetailsModal';
import LandRequiredDetailsModal from './LandRequiredDetailsModal';
import AdditionalDetailsModal from './AdditionalDetailsModal';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import './purchase-basic-detail.scss';
import '../po-tab.scss';
import {
    adSuccess,
    fetchBasicDetails,
    getAddress,
    getBuyer,
    getDatas,
    reqSuccess,
} from '../../../store/reducers/purchase_order/basicDetails';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../../hooks';
import { fetchHeader, fetchPoAttachment, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import { Position, success } from '../../../utils/toast';
import { fetchDatesPoList } from '../../../store/reducers/purchaseOrder/datesPoReducer';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from '../../../ShowForPermissions';
import { fetchProductPoList } from 'src/store/reducers/purchaseOrder/productPoReducer';
import {
    fetchProductDetailsConfirmationList,
    // selectProductDetailsConfirmation,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import ShowMandatoryFields from 'src/components/common/ShowMandatoryFields';
import { InterfaceType, defaultPoDestinationAddress } from 'src/utils/constants';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import PODetailsNextButton from 'src/pages/PO/PODetailsNextButton';
import { useUserProfile } from 'src/hooks/useUserProfile';
import PurchaseOrderAttachment from './purchaseOrderAttachment';

const Index: React.FC = () => {
    const { profileType } = useUserProfile();
    // const location = useLocation();
    // const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const [addDetails, setaddDetails] = useState(false);
    const [addRequired, setaddRequired] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const [isEditAdditional, setisEditAdditional] = useState(false);
    const [_flagNew, setflagNew] = useState(true);
    const [viaIntegration, setViaIntegration] = useState(false);
    const { loading } = useSelector((state: RootState) => state.vendorDat);
    const { basicDetails, addtionalSuccess, requiredSuccess, addressVal, buyerVal } = useSelector(
        (state: RootState) => state.purchaseOrder
    );
    const { attchments, fileUploadStatus } = useSelector((state: RootState) => state.addPoData);
    // const datesPoData = useAppSelector(selectDatesPo);
    const headerData = useAppSelector(selectHeader);
    // const products = useAppSelector(selectProductPo);
    // const productDetailsConfirmation = useAppSelector(selectProductDetailsConfirmation);

    const { id } = useParams();

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchBasicDetails(id));

        // dispatch(
        //     fetchDatesPoList({
        //         id,
        //     })
        // );
        // dispatch(fetchProductPoList({ poId: id }));
        // dispatch(
        //     fetchProductDetailsConfirmationList({
        //         poId: id,
        //     })
        // );
    }, []);

    useEffect(() => {
        if (fileUploadStatus) {
            dispatch(fetchPoAttachment({ poNumber: headerData.poNumber, clientCode: headerData.clientCode }));
        }
    }, [fileUploadStatus]);

    useEffect(() => {
        if (headerData?.clientCode) {
            dispatch(
                getDatas({
                    portType: headerData?.shippingMode?.shippingModeCode === 'AIR' ? 'Air' : null,
                    orderType: {},
                    shippingmode: {},
                    fclservicetype: {},
                    freightterms: {},
                    // portlist: {},
                    incotermfob: {},
                    paymentterms: {},
                    agentsList: {},
                    buyers: {},
                    currency: [],
                })
            );
            dispatch(fetchPoAttachment({ poNumber: headerData.poNumber, clientCode: headerData.clientCode }));
            setViaIntegration(headerData?.createdUser === InterfaceType.InterfacedPO);
        }
    }, [headerData]);

    useEffect(() => {
        if (headerData?.clientCode) {
            dispatch(
                getAddress({
                    orgCode: headerData && headerData?.clientCode,
                })
            );
            dispatch(
                getBuyer({
                    orgCode: headerData && headerData?.clientCode,
                })
            );
        }
    }, [headerData?.clientCode]);

    useEffect(() => {
        if (addtionalSuccess) {
            success(`Additional details have been ${isEditAdditional ? 'updated' : 'added'}`, Position.TOP_RIGHT);
            dispatch(adSuccess(false));
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
        } else if (requiredSuccess) {
            success(`Required details have been ${isEdit ? 'updated' : 'added'}`, Position.TOP_RIGHT);
            dispatch(reqSuccess(false));
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
        }
    }, [addtionalSuccess, requiredSuccess]);

    const val = basicDetails as any;
    /* eslint no-var: off */
    const showCreate = () => {
        var flag = false;
        if (
            val?.promRefNo ||
            val?.pickGroup ||
            val?.brandName ||
            val?.currency ||
            val?.buyerUserName ||
            val?.impVendorCode ||
            val?.impShipmentRefNo ||
            val?.distribOrderNo
        ) {
            flag = true;
        }
        return flag;
    };
    const showEditRequired = () => {
        var flag = false;
        if (
            val?.ordertype ||
            val?.agentName ||
            val?.destinationAddress ||
            val?.destinationPort ||
            val?.originPort ||
            val?.paymentterms ||
            val?.incotermfob ||
            val?.freightterms ||
            val?.fclservicetype
        ) {
            flag = true;
        }
        return flag;
    };
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userType = '';
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            userType = roles[roleIndex].profile?.toLowerCase();
        }
    }

    const showMandatoryFieldsIcon = () => {
        if (!viaIntegration) {
            return false;
        }
        return headerData.bdTabComplete ? false : true;
    };

    // const nextButtonStatus = () => {
    //     if (!viaIntegration) {
    //         return val?.ordertype === null;
    //     }
    //     return condition ? true : false;
    // };

    // function checkPoReviewEnabled() {
    //     let enabled = true;

    //     if (!skipPOLine && (products as any)?.length < 1) {
    //         enabled = false;
    //     }
    //     if (skipPOLine && headerData?.destinations?.length < 2) {
    //         enabled = false;
    //     }
    //     if ((products as any)?.length <= 0) {
    //         enabled = false;
    //     }
    //     if (headerData?.destinations[0]?.includes('null')) {
    //         enabled = false;
    //     }
    //     return enabled ? '' : 'disabled';
    // }

    // const checkDisabled = () => {
    //     let flag = true;
    //     if (val?.agentName !== null && val?.orderDate !== null) {
    //         flag = false;
    //     }
    //     if (skipPOLine && headerData?.destinations?.length < 2) {
    //         flag = true;
    //     }
    //     if (!skipPOLine && val?.purchaseOrderProducts?.length === 0) {
    //         flag = true;
    //     }
    //     return flag;
    // };
    // const destines = headerData?.destinations?.map((d: any) => ({
    //     label: d,
    //     value: d,
    // }));
    useEffect(() => {
        let flag = false;
        val?.purchaseOrderProducts?.forEach((element: any) => {
            if (val?.isMultiAddress && element.destinationAddress === null) {
                flag = true;
            }
        });
        setflagNew(flag);
    }, [val]);

    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={`${poName}`}
                    firstUrl={`${poUrl}`}
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New PO' : 'PO Details'}
                    secondUrl={`/purchase_order/${id}/basic_details`}
                />
                <PurchaseOrderHeader id={id}></PurchaseOrderHeader>
                {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <PODetailsHeaderTabs />
            </div>
            <div className="content-section main-wrapper purchase-basic-detail-wrapper">
                {showMandatoryFieldsIcon() && (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missing. Please integrate again/ edit details to enter the mandatory fields
                            to proceed.
                        </div>
                    </div>
                )}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Required Details</h6>
                            <ShowForPermissions permission="edit" type="po">
                                {showEditRequired() &&
                                    val?.poStatus !== 'Cancelled' &&
                                    val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    val?.poStatus !== 'In_Booking_Proposal' &&
                                    val?.poStatus !== 'Booked' &&
                                    !headerData?.enriched &&
                                    userType !== 'agent' && (
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                            onClick={() => {
                                                setaddDetails(true);
                                                setisEdit(true);
                                            }}
                                        >
                                            <svg className="svg-icon edit-icon" style={{ width: '100%' }}>
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="po">
                            <div className="btn-holder">
                                {!showEditRequired() &&
                                    val?.poStatus !== 'Cancelled' &&
                                    val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    val?.poStatus !== 'In_Booking_Proposal' &&
                                    val?.poStatus !== 'Booked' &&
                                    userType !== 'agent' && (
                                        <button
                                            onClick={() => {
                                                setaddDetails(true);
                                                setisEdit(false);
                                            }}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.ordertype
                                    ? val?.ordertype?.orderTypeName
                                    : headerData?.shippingMode?.shippingModeCode === 'LND'
                                    ? 'Warehouse Order'
                                    : 'International PO'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Shipping Mode<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.shippingmode ? val.shippingmode.shippingModeName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Origin<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!val?.originPort}
                                name="Origin"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {val?.originPort ? `${val?.originPortName}(${val?.originPort})` : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Destination<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={
                                    val?.isMultiAddress && val?.ordertype ? false : !val?.destinationPort
                                }
                                name="Destination"
                                viaIntegration={viaIntegration}
                            />
                            {headerData?.shippingMode?.shippingModeCode === 'LND' ? (
                                <div className="details-box-content">
                                    {val?.destinationPort ? `${val?.destinationPort}` : '-'}
                                </div>
                            ) : (
                                <div className="details-box-content">
                                    {val?.isMultiAddress && val?.ordertype
                                        ? 'Multi Destination'
                                        : val?.destinationPort
                                        ? `${val?.destinationPortName}(${val?.destinationPort})`
                                        : '-'}
                                </div>
                            )}
                        </div>
                        {/* !(typeof val.includesAUPortDestination === 'boolean') */}
                        {val.isTriangleShipment && val?.isMultiAddress && (
                            <div className="details-col box-content-holder">
                                {/* <h6 className="details-box-heading">
                                    Includes AU Destination<span className="mandatory-field-text">*</span>
                                </h6> */}
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={
                                        typeof val.includesAUPortDestination === 'boolean' ? false : true
                                    }
                                    name="Includes AU Destination"
                                    viaIntegration={viaIntegration}
                                />
                                <div className="details-box-content" style={{ width: '80%' }}>
                                    {val.includesAUPortDestination ? 'Yes' : 'No'}
                                </div>
                            </div>
                        )}
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Destination Address
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={
                                    val?.isMultiAddress && val?.ordertype ? false : !val?.destinationAddress
                                }
                                name="Destination Address"
                                showAsterisk={!val.isTriangleShipment ? true : false}
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content" style={{ width: '80%' }}>
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationAddress
                                    ? val.destinationAddress
                                    : '-'}{' '}
                            </div>
                        </div>
                        {headerData?.shippingMode?.shippingModeCode === 'LND' ? null : (
                            <>
                                <div className="details-col box-content-holder">
                                    {/* <h6 className="details-box-heading">
                                Service Type
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6> */}
                                    <ShowMandatoryFields
                                        showMandatoryFieldsIcon={!val.isTriangleShipment ? !val?.fclservicetype : false}
                                        name="Service Type"
                                        showAsterisk={!val.isTriangleShipment ? true : false}
                                        viaIntegration={viaIntegration}
                                    />
                                    <div className="details-box-content">
                                        {val?.fclservicetype ? val?.fclservicetype?.fclServiceTypeName : '-'}
                                    </div>
                                </div>
                                {/* {!!(val?.shippingmode?.shippingModeCode !== 'AIR') && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Freight Term<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {val?.freightterms ? val?.freightterms?.freightTermsCode : '-'}
                                </div>
                            </div>
                        )} */}
                                <div className="details-col box-content-holder">
                                    {/* <h6 className="details-box-heading">
                                Incoterm
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6> */}
                                    <ShowMandatoryFields
                                        showMandatoryFieldsIcon={!val.isTriangleShipment ? !val?.incoTerm : false}
                                        name="Incoterm"
                                        showAsterisk={!val.isTriangleShipment ? true : false}
                                        viaIntegration={viaIntegration}
                                    />
                                    <div className="details-box-content">
                                        {val?.incoTerm ? val?.incoTerm?.incoTermName : '-'}
                                    </div>
                                </div>
                                <div className="details-col box-content-holder">
                                    {/* <h6 className="details-box-heading">
                                Release Type
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6> */}
                                    <ShowMandatoryFields
                                        showMandatoryFieldsIcon={
                                            !val.isTriangleShipment ? !val?.releaseType?.releaseTypeName : false
                                        }
                                        name="Release Type"
                                        showAsterisk={!val.isTriangleShipment ? true : false}
                                        viaIntegration={viaIntegration}
                                    />
                                    <div className="details-box-content">
                                        {val?.releaseType?.releaseTypeName
                                            ? val?.releaseType?.releaseTypeCode +
                                              ' (' +
                                              val?.releaseType?.releaseTypeName +
                                              ')'
                                            : '-'}
                                    </div>
                                </div>
                                {/* Release type field for air freight shipping mode */}
                                {/* {!(val?.shippingmode?.shippingModeCode === 'AIR') && ( */}
                                <div className="details-col box-content-holder">
                                    {/* <h6 className="details-box-heading">
                                    Payment Term
                                    {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                                </h6> */}
                                    <ShowMandatoryFields
                                        showMandatoryFieldsIcon={!val.isTriangleShipment ? !val?.paymentterms : false}
                                        name="Payment Term"
                                        showAsterisk={!val.isTriangleShipment ? true : false}
                                        viaIntegration={viaIntegration}
                                    />
                                    <div className="details-box-content">
                                        {val?.paymentterms ? val?.paymentterms?.paymentTermsName : '-'}
                                    </div>
                                </div>
                            </>
                        )}
                        {/* )} */}

                        {profileType === 'forwarder' && (
                            <div className="details-col box-content-holder">
                                {/* <h6 className="details-box-heading">
                                    Agent
                                    <span className="mandatory-field-text">*</span>
                                </h6> */}
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={false}
                                    name="Agent"
                                    viaIntegration={viaIntegration}
                                    showAsterisk={false}
                                />
                                <div className="details-box-content">{val?.agentName ? val?.agentName : '-'}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Additional Details</h6>
                            <ShowForPermissions permission="edit" type="po">
                                {showCreate() &&
                                    val?.poStatus !== 'Cancelled' &&
                                    val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    val?.poStatus !== 'In_Booking_Proposal' &&
                                    val?.poStatus !== 'Booked' &&
                                    !headerData?.enriched &&
                                    userType !== 'agent' && (
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                            onClick={() => {
                                                setaddRequired(true);
                                                setisEditAdditional(true);
                                            }}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="po">
                            <div className="btn-holder">
                                {!showCreate() &&
                                    val?.poStatus !== 'Cancelled' &&
                                    val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    val?.poStatus !== 'In_Booking_Proposal' &&
                                    val?.poStatus !== 'Booked' &&
                                    !headerData?.enriched &&
                                    userType !== 'agent' && (
                                        <button
                                            onClick={() => {
                                                setaddRequired(true);
                                                setisEditAdditional(false);
                                            }}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    {!showCreate() && (
                        <div className="no-data-wrapper">
                            <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No additional details added</p>
                            </div>
                        </div>
                    )}

                    <div className="card-detail-col">
                        {val?.promRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Promotional Ref No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.promRefNo ? val?.promRefNo : '-'}</div>
                            </div>
                        )}

                        {val?.pickGroup && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Pick Group
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.pickGroup ? val?.pickGroup : '-'}</div>
                            </div>
                        )}
                        {val?.brandName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Brand Name
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.brandName ? val?.brandName : '-'}</div>
                            </div>
                        )}
                        {val?.currency && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Order Currency
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.currency?.currencycode ? val.currency.currencycode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.buyerUserName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Buyer
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.buyerUserName ? val?.buyerUserName : '-'}
                                </div>
                            </div>
                        )}
                        {val?.impVendorCode && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Vendor Code
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impVendorCode ? val?.impVendorCode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.impShipmentRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Shipment Ref No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impShipmentRefNo ? val?.impShipmentRefNo : '-'}
                                </div>
                            </div>
                        )}
                        {val?.distribOrderNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Distribution Order No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.distribOrderNo ? val?.distribOrderNo : '-'}
                                </div>
                            </div>
                        )}
                        {val?.department && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Department
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.department ? val?.department : '-'}</div>
                            </div>
                        )}
                        {val?.subCategory && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Sub Category
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.subCategory ? val?.subCategory : '-'}</div>
                            </div>
                        )}
                    </div>
                </div>
                {/* PO Attachment only visible */}
                {/* {(profileType === 'forwarder' || profileType === 'agent' || profileType === 'client') && (
                    <PurchaseOrderAttachment data={attchments} />
                )} */}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    {/*<Link
                        title="Next"
                        className="app-btn app-btn-primary footer-btn"
                        to={`/purchase_order/${id}/dates`}
                    >*/}
                    <PODetailsNextButton />
                    {/*</Link>*/}
                </div>
            </div>
            {addDetails && headerData?.shippingMode?.shippingModeCode === 'LND' && (
                <LandRequiredDetailsModal
                    displayFn={setaddDetails}
                    display={addDetails}
                    edit={isEdit}
                    editData={val}
                    setisEdit={setisEdit}
                    id={id}
                    addressVal={[
                        {
                            label: defaultPoDestinationAddress.label,
                            value: defaultPoDestinationAddress.value,
                        },
                        ...addressVal,
                    ]}
                    portType={headerData?.shippingMode?.shippingModeCode === 'AIR' ? 'Air' : 'Sea'}
                />
            )}
            {addDetails && headerData?.shippingMode?.shippingModeCode !== 'LND' && (
                <RequiredDetailsModal
                    displayFn={setaddDetails}
                    display={addDetails}
                    edit={isEdit}
                    editData={val}
                    setisEdit={setisEdit}
                    id={id}
                    addressVal={[
                        {
                            label: defaultPoDestinationAddress.label,
                            value: defaultPoDestinationAddress.value,
                        },
                        ...addressVal,
                    ]}
                    portType={headerData?.shippingMode?.shippingModeCode === 'AIR' ? 'Air' : 'Sea'}
                />
            )}
            {addRequired && (
                <AdditionalDetailsModal
                    data1={buyerVal}
                    data2={[]}
                    displayFn={setaddRequired}
                    display={addRequired}
                    edit={isEditAdditional}
                    editData={isEditAdditional && val}
                    setisEdit={setisEditAdditional}
                    id={id}
                />
            )}
            {/* <RequiredDetailsModal></RequiredDetailsModal> */}
            {/* <AdditionalDetailsModal></AdditionalDetailsModal> */}
            {/* <CancellationDetailModal></CancellationDetailModal> */}
            {/* <CancellationModal></CancellationModal> */}
        </div>
    );
};
export default Index;
