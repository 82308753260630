import { get } from '../HttpMethods';
import fileDownload from 'js-file-download';

export interface agentData {
    orgId: string;
    orgName: string;
    orgCode: string;
    orgStatus: string;
    parentOrgCode: string;
    agentId: string;
    agentStatus: string;
    relationship: string;
    agentCountry: string;
    childAgents: agentData[];
}

export interface agentResponse {
    agentResponseLists: agentData[];
    totalNoOfPages: number;
    totalNoOfItems: number;
}

export interface agentSearchData {
    content: agentData[];
    totalPages: number;
    totalElements: number;
}

// Get All Datas
export const getAgentData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    profile: string;
    orgCode: string;
}) => {
    try {
        const res = await get(
            `api/mgt/agents?limit=${payload.perPageCount}&page=${payload.currentPage}&sortby=${
                payload.columnStatus
            }&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}&profile=${payload.profile ?? ''}&orgCode=${
                payload.orgCode ?? ''
            }${payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''}`,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const exportAgentList = async (type: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/agents/export?type=${type} `, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'AgentProfiles.xlsx' : 'AgentProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
export const exportAgentListProfiles = async (type: string, organisationData: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const selectedFilter = organisationData?.selectedFilter;
        const searchTerm = organisationData?.searchTerm;
        const columnOrder = organisationData?.sortPayload['columnOrder'];
        const columnStatus = organisationData?.sortPayload['columnStatus'];
        const profile = organisationData?.profile;
        const orgCode = organisationData?.orgCode;
        const isForwarder = organisationData?.isForwarder;
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/mgt/agents/export?type=${type}${
                selectedFilter ? '&filterby=' + selectedFilter : ''
            }${
                searchTerm ? '&keyword=' + searchTerm : ''
            }&sortColumn=${columnStatus}&sortOrder=${columnOrder}&profile=${profile}&orgCode=${orgCode}&isForwarder=${isForwarder}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'AgentProfiles.xlsx' : 'AgentProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchAgents = async (payload: {
    keyword: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    isForwarder: boolean;
}) => {
    try {
        const res = await get(
            `api/mgt/search/agent?keyword=${payload.keyword}&size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus}&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }&isForwarder=${payload?.isForwarder}`,
            null,
            null,
            true
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchAgentHeader = async (payload: any) => {
    try {
        const data = await get(`api/mgt/agents/profile/header?agentId=${payload}`, null);
        return data;
    } catch (error) {
        return error;
    }
};
