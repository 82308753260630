import React from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DropDownComponent from './DropDownComponent';

interface propsData {
    bookingData?: any;
    Btn?: any;
    dataAir?: any;
}
const Index: React.FC<propsData> = ({ bookingData, Btn, dataAir }) => {
    const { id } = useParams() as any;
    const { type } = useParams() as any;
    const navigate = useNavigate();

    const bpRedirection = () => {
        let path = '/';
        switch (bookingData?.bpType?.toLowerCase()) {
            case 'fcl':
                path = `/booking_list/${id}/POSelection`;
                break;
            case 'lcl':
                path = `/lclbooking_list/${id}/POSelection`;
                break;
            case 'ao':
                path = `/Aobooking_list/${id}/AOSelection`;
                break;
            case 'bcn':
                path = `/bcnBookingList/${id}/POSelection`;
                break;
            case 'air':
                path = `/air_booking_list/${id}/POSelection`;
                break;
        }
        localStorage.setItem('bpNumber', bookingData?.bpNumber);
        navigate(path);
    };
    return (
        <>
            {Btn && <Btn />}
            <div className="lcs-basic-details">
                {type === 'house-bill' && (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">BP No.</div>
                        <div className="cm-key-pair-value">
                            {bookingData?.bpNumber ? (
                                <span
                                    className="view-more"
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => bpRedirection()}
                                >
                                    {bookingData?.bpNumber}
                                </span>
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>
                )}
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Client</div>
                    <DropDownComponent data={bookingData?.clients} keyValue="clients" />
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Vendors</div>
                    <DropDownComponent data={bookingData?.vendors} keyValue="vendors" />
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Purchase Orders</div>
                    <DropDownComponent data={bookingData?.purchaseOrders} keyValue="purchase-orders" />
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Origin</div>
                    <div className="cm-key-pair-value">{bookingData?.originPort ?? '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Destination</div>
                    <div className="cm-key-pair-value">{bookingData?.destinationPort ?? '-'}</div>
                </div>
                {bookingData?.bpType?.toLowerCase() !== 'air' && (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Vessel</div>
                        <div className="cm-key-pair-value">
                            {bookingData?.vessel ? (
                                <Link
                                    to={`/tracking/lifecycle_booking/vessels/${id}`}
                                    className="view-more"
                                    state={{ name: bookingData?.vessel }}
                                >
                                    {bookingData?.vessel}
                                </Link>
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>
                )}
                {bookingData?.bpType?.toLowerCase() !== 'air' ? (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Voyage</div>
                        <div className="cm-key-pair-value">{bookingData?.voyage ?? '-'}</div>
                    </div>
                ) : (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Carrier Flight</div>
                        <div className="cm-key-pair-value">{dataAir[0]?.flightNo ?? '-'}</div>
                    </div>
                )}
                {bookingData?.bpType?.toLowerCase() !== 'air' && (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Containers</div>
                        <DropDownComponent data={bookingData?.containers} keyValue="container" />
                    </div>
                )}
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Agent</div>
                    <div className="cm-key-pair-value">{bookingData?.agent ?? '-'}</div>
                </div>
            </div>
        </>
    );
};
export default Index;
