/* eslint-disable no-undefined */
import React from 'react';
import GraphView from './GraphView';
import AllOrderTabs from './AllOrderTab';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

interface DisplayHandler {
    changeValue: string;
}
//Faheem
const Index = () => {
    //============== variables to interact b/w childs ==================//
    const displayComponentRef = React.useRef<DisplayHandler>({
        changeValue: '',
    });

    const selectedRef = React.useRef({
        value: {},
    });
    //==================================================================//

    //========================= Redux values =======================================//
    const { dashboardActiveBookingsExpanded, isDasboardActiveBookingsExpandedLoading, insightsSelectedTypeFilter } =
        useSelector((state: RootState) => state.dashboardInsights);
    const dashboardActiveBookingsExpandedRedux = React.useMemo(() => {
        if (dashboardActiveBookingsExpanded) {
            return dashboardActiveBookingsExpanded;
        }
    }, [dashboardActiveBookingsExpanded]);
    //==============================================================================//

    //================== setting ref with default value selected=====================//
    React.useEffect(() => {
        if (insightsSelectedTypeFilter) {
            selectedRef.current.value = dashboardActiveBookingsExpandedRedux[insightsSelectedTypeFilter];
        } else {
            if (dashboardActiveBookingsExpandedRedux['total'] && !isDasboardActiveBookingsExpandedLoading) {
                selectedRef.current.value = dashboardActiveBookingsExpandedRedux['total'];
            }
        }
    }, [insightsSelectedTypeFilter, dashboardActiveBookingsExpandedRedux]);
    //==============================================================================//

    return (
        <div className="tab-content-body">
            <div className="graph-container">
                <div className="all-orders-grid">
                    {!isDasboardActiveBookingsExpandedLoading ? (
                        <React.Fragment>
                            <AllOrderTabs
                                dashboardExpandedReduxVal={dashboardActiveBookingsExpandedRedux}
                                isLoading={isDasboardActiveBookingsExpandedLoading}
                                displayComponentRef={displayComponentRef}
                                page={'bookings'}
                            />
                            <GraphView
                                ref={displayComponentRef}
                                selectedRef={selectedRef}
                                type="Active Bookings"
                            ></GraphView>
                        </React.Fragment>
                    ) : (
                        <div className="app-loader inner-loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Index;
