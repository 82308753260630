import React, { useState, useEffect } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import '../data-grid.scss';
import '../../../assets/scss/components/_jquery-kendo-grid.scss';
import '../../../assets/scss/components/library/kendo-bootstrap-main-min.css';
import GridLayout from './GridLayout';

interface GridLayoutProps {
    scPk: string;
    slPk: string;
    readonly?: boolean;
}

const Index: React.FC<GridLayoutProps> = ({ scPk, slPk, readonly }) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setVisible(true);
        }, 1000);
    }, []);
    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-content-holder">
                <HelmetProvider>
                    <div className="insight-agent-list-data-trend">
                        <div className="container-fluid carrier-contract">
                            <div className="k-kendo-row one-column">
                                <div className="k-kendo-col-2 custom-kendo-grid">
                                    <div id="grid"></div>
                                </div>
                            </div>
                        </div>
                        {visible ? (
                            <GridLayout scPk={scPk} slPk={slPk} readonly={readonly} />
                        ) : (
                            <div className="app-loader loading">
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                        )}
                    </div>
                </HelmetProvider>
            </div>
        </div>
    );
};
export default Index;
