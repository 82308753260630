import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable no-undefined */

export const productData = createSlice({
    name: 'products',
    initialState: {
        products: [],
        isLoading: false,
        currentPage: 1,
        success: false,
        linkedVendors: 0,
        unlinkDat: [],
        selectedList: [],
        activeVendors: [],
        totalActiveProducts: 0,
        totalInactiveProducts: 0,
        totalPages: 1,
        successFetch: false,
        status: { name: '', status: 'ACTIVE' },
        statusView: false,
        totalNoOfItems: 0,
        createError: '',
        warning: false,
        productCreate: false,
        productEdit: false,
        productName: '',
        editFailed: false,
        editStared: false,
        deactivateErrorMessage: null,
        clientCode: '',
        distinctValues: {},
    },
    reducers: {
        fetchData: (state, _action) => {
            state.isLoading = true;
        },
        saveFetchedData: (state, action) => {
            state.products = action.payload?.info?.productMasterDtoList;
            if (
                action.payload?.info?.vendorList?.length !== 0 &&
                Array.isArray(action.payload?.info?.productMasterDtoList)
            ) {
                state.successFetch = true;
            } else {
                state.successFetch = false;
            }
            state.isLoading = false;
            state.totalActiveProducts = action.payload?.info?.totalActiveProducts;
            state.totalInactiveProducts = action.payload?.info?.totalInactiveProducts;
            state.totalPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
            state.clientCode = action.payload?.info?.clientCode;
            state.distinctValues = action.payload?.info?.distinctColumnValues;
        },
        getVendorList: (_state, _action) => {
            // console.log(action.payload, 'vendor payload');
        },
        vendorList: (state, action) => {
            state.linkedVendors = action.payload?.info?.vendorList;
        },
        createProduct: (_state, _action) => {
            // console.log(action.payload, 'create product');
        },
        changeStatus: (state, _action) => {
            // console.log(action.payload);
            state.deactivateErrorMessage = null;
        },
        statusValues: (state, action) => {
            // console.log(action.payload, 'status value');
            state.status = action.payload;
            state.statusView = true;
            state.deactivateErrorMessage = null;
        },
        deactivateFailed: (state, action) => {
            state.deactivateErrorMessage = action.payload ?? null;
        },
        changeStatusValue: (state, action) => {
            state.statusView = action.payload;
        },
        editProduct: (_state, _action) => {
            // console.log(action.payload, 'edit values');
        },
        isLoadingfn: (state, action) => {
            state.isLoading = action.payload;
        },
        removePacket: (_state, _action) => {
            // console.log('first', action.payload);
        },
        createSinglePacket: (_state, _action) => {
            // console.log(action, 'single packet data');
        },
        downloadFile: (_state, _action) => {
            // console.log(action);
        },
        productAddError: (state, action) => {
            if (action.payload !== undefined) {
                state.warning = true;
                state.createError = action.payload?.response?.data?.message ?? action.payload;
            } else {
                state.createError = '';
            }
        },
        productAddErrorStatus: (state, action) => {
            state.warning = action.payload;
        },
        productCreateSuccess: (state, action) => {
            // console.log(action.payload, 'response');
            state.productCreate = action.payload;
        },
        productCreated: (state, action) => {
            state.productName = action.payload?.info?.pm_sku;
        },
        productEditSuccess: (state, action) => {
            state.productEdit = action.payload;
        },
        editingStarted: (state, action) => {
            state.editStared = action.payload;
        },
        editingFailed: (state, action) => {
            state.editFailed = action.payload;
        },
        searchProductList: (state, action) => {
            state.products = action.payload?.info?.productMasterDtoList;
            if (
                action.payload?.info?.vendorList?.length !== 0 &&
                Array.isArray(action.payload?.info?.productMasterDtoList)
            ) {
                state.successFetch = true;
            } else {
                state.successFetch = false;
            }
            state.isLoading = false;
            state.totalActiveProducts = action.payload?.info?.totalActiveProducts;
            state.totalInactiveProducts = action.payload?.info?.totalInactiveProducts;
            state.totalPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
    },
});

export const {
    fetchData,
    vendorList,
    saveFetchedData,
    changeStatus,
    getVendorList,
    createProduct,
    statusValues,
    changeStatusValue,
    editProduct,
    isLoadingfn,
    removePacket,
    createSinglePacket,
    downloadFile,
    productAddError,
    productAddErrorStatus,
    productEditSuccess,
    productCreateSuccess,
    productCreated,
    editingFailed,
    editingStarted,
    searchProductList,
    deactivateFailed,
} = productData.actions;
export default productData.reducer;
