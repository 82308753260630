import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BasicDetailsModal from './BasicDetailsModal';
import '../../../BookingProposal/POSelection.scss';
import '../../../BookingProposal/ContainerAllocations/ContainerDetails/container-details.scss';
import '../../../BookingProposal/BookingEnrichmentAdditionalDetail/booking-enrichment-additional.scss';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useAppDispatch } from 'src/hooks';
import {
    fetchShippingDetail,
    fetchPacking,
    clearFailure,
    BpAirShippingDetails,
} from 'src/store/reducers/bookingProposalAir/shippingDetailsReducer';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import BookingHeaderSidebar from '../../BookingHeaderSidebar';
import CancelModal from 'src/components/common/CancelModal';
import moment from 'moment';
import { error, Position } from 'src/utils/toast';
import ShowForPermissions from 'src/ShowForPermissions';

interface BasicData {
    title: string;
    key: string;
    fields: string;
    required: boolean;
}
const basicData: BasicData[] = [
    {
        title: 'Container Type',
        key: 'containerType',
        fields: 'Loose',
        required: false,
    },
    {
        title: 'Delivery Mode',
        key: 'deliveryMode',
        fields: 'Loose',
        required: false,
    },
    {
        title: 'Packing',
        key: 'packing',
        fields: '',
        required: false,
    },
    {
        title: 'VGM (in Kg)',
        key: 'vgm',
        fields: '- ',
        required: false,
    },
    {
        title: 'Pick Up Date from Vendor',
        key: 'pickUpDate',
        fields: '-',
        required: false,
    },
    {
        title: 'Drop Off Date at Depot',
        key: 'dropOffDate',
        fields: '-',
        required: false,
    },
];
// const dateData = [
//     {
//         title: 'Collection Date from Depot',
//         key: 'collectionDate',
//         fields: '-',
//         required: false,
//     },
//     {
//         title: 'Drop Off Date at BLP',
//         key: 'dropOffDate',
//         fields: '-',
//         required: false,
//     },
//     {
//         title: 'Pick Up Date from BLP',
//         key: 'pickUpDate',
//         fields: '-',
//         required: false,
//     },
//     {
//         title: 'Gate in Date and Time',
//         key: 'gateInDate',
//         fields: '-',
//         required: false,
//     },
// ];
const Index: React.FC = () => {
    const [showBasicDetails, setShowBasicDetails] = useState(false);
    const [showDatesDetail, setShowDatesDetails] = useState(false);
    const [cancelModalView, setCancelModalView] = useState(false);
    const [tab, _setTab] = useState(0);
    const { id, shipmentId } = useParams();
    // const location: any = useLocation();
    const { profileType } = useUserProfile();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    // const [selectedItemsAdded, setSelectedItemsAdded] = useState<any[]>([]);
    const [_loader, setLoader] = useState(true);
    // const [containerData, setContainerData] = useState<BpContainerDetail>(defaultBpContainerDetail);
    const dispatch = useAppDispatch();
    const { headerData, shippingDetailsAir } = useSelector((state: RootState) => ({
        headerData: state.bpHeaderData,
        shippingDetailsAir: state.shippingDetailsAir,
    }));
    const {
        shippingDetails,
        shippingDetailsUpdateError,
        shippingDetailsUpdateSuccess,
        isError,
        // isLoading,
        // packingList,
    } = shippingDetailsAir;

    // const packingData = useAppSelector(selectPacking);
    // const assignedPo = useAppSelector(selectAssignedPo);

    useEffect(() => {
        dispatch(fetchPacking({}));
    }, []);
    useEffect(() => {
        if (id) {
            dispatch(fetchShippingDetail({ shipmentId: id }));
        }
    }, [id]);

    // const getPackageName = (code: any[]) => {
    //     if (!code) {
    //         return '-';
    //     }
    //     const newPackage = [...code];
    //     if (newPackage?.length) {
    //         for (let index = 0; index < code.length; index++) {
    //             newPackage[index] = packingList.find((el: any) => el.packingCode === code[index])?.packingName;
    //         }
    //         return newPackage.join(', ');
    //     } else {
    //         return '-';
    //     }
    // };

    useEffect(() => {
        for (let index = 0; index < basicData.length; index++) {
            const basicDataKey = basicData[index].key as keyof BpAirShippingDetails;
            if (basicData[index].key === 'packing') {
                //basicData[index].fields = getPackageName(shippingDetails['packing']);
                basicData[index].fields = shippingDetails?.['packing'];
            } else {
                basicData[index].fields = shippingDetails?.[basicDataKey] ? shippingDetails?.[basicDataKey] : '-';
            }
        }
        // for (let index = 0; index < dateData.length; index++) {
        //     if (dateData[index].key === 'gateInDate') {
        //         dateData[index].fields = containerDetail[dateData[index].key]
        //             ? moment(containerDetail[dateData[index].key]).format('DD-MM-YYYY, HH:mm')
        //             : '-';
        //     } else {
        //         dateData[index].fields = containerDetail[dateData[index].key]
        //             ? moment(containerDetail[dateData[index].key]).format('DD-MM-YYYY')
        //             : '-';
        //     }
        // }
    }, [shippingDetails]);

    // const removePoFromContainer = () => {
    //     setLoader(true);
    //     const bsId: any[] = [];
    //     for (let i = 0; i < selectedItemsAdded.length; i++) {
    //         bsId.push(...selectedItemsAdded[i].subPolineIds);
    //     }
    //     dispatch(removeFromContainer({ containerDetailsId: location?.state?.containerDetailsId, bsId }));
    //     setSelectedItemsAdded([]);
    // };

    // const getCount = () => {
    //     let total = 0;
    //     selectedItemsAdded.forEach((element: any) => {
    //         // eslint-disable-next-line @typescript-eslint/no-unused-vars
    //         element.subPolineIds.forEach((element2: any) => {
    //             total++;
    //         });
    //     });
    //     return total;
    // };

    useEffect(() => {
        setLoader(false);
        if (isError && shippingDetailsUpdateError) {
            error('Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (shippingDetailsUpdateSuccess) {
            dispatch(fetchShippingDetail({ shipmentId: id }));
            dispatch(clearFailure([]));
        }
    }, [isError, shippingDetailsUpdateSuccess, shippingDetailsUpdateError]);
    //TODO confirm integration validation
    // const agentIntegrationStatusValidation = (type: string) => {
    //     if (headerData?.agentIntegrationStatus === true) {
    //         if (type === 'basicDetails') {
    //             if (
    //                 containerData?.containerNumber === '' ||
    //                 containerData?.containerNumber === null ||
    //                 containerData?.packing?.length === 0 ||
    //                 containerData?.vgm === '' ||
    //                 containerData?.vgm === null ||
    //                 containerData?.eir === '' ||
    //                 containerData?.eir === null ||
    //                 containerData?.sealNumber === '' ||
    //                 containerData?.sealNumber === null
    //             ) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } else if (type === 'dates') {
    //             if (
    //                 containerData?.collectionDate === '' ||
    //                 containerData?.collectionDate === null ||
    //                 containerData?.dropOffDate === '' ||
    //                 containerData?.dropOffDate === null ||
    //                 containerData?.pickUpDate === '' ||
    //                 containerData?.pickUpDate === null ||
    //                 containerData?.gateInDate === '' ||
    //                 containerData?.gateInDate === null
    //             ) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         } else {
    //             if (!assignedPo?.bookingProposalPoList?.length) {
    //                 return true;
    //             } else {
    //                 return false;
    //             }
    //         }
    //     } else {
    //         return true;
    //     }
    // };
    const isEdit = !!shippingDetails?.['packing'];

    const ContainerDetailTab = () => {
        return (
            <div className="booking-detail-wrapper main-content-holder">
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Shipment Details</h6>
                            {(profileType === 'agent' || profileType === 'forwarder') &&
                                isEdit &&
                                headerData?.bpStatus !== 'Booked' && (
                                    // containerData.containerNumber &&
                                    // agentIntegrationStatusValidation('basicDetails') &&
                                    <ShowForPermissions permission="edit" type="bp">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Shipment Details"
                                            onClick={() => setShowBasicDetails(true)}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    </ShowForPermissions>
                                )}
                        </div>
                        {(profileType === 'agent' || profileType === 'forwarder') &&
                            !isEdit &&
                            headerData?.bpStatus !== 'Booked' && (
                                // agentIntegrationStatusValidation('basicDetails') &&
                                <ShowForPermissions permission="edit" type="bp">
                                    <div className="btn-holder">
                                        <button
                                            className="app-btn app-btn-secondary icon-button  add-product-btn"
                                            title="Add Details"
                                            onClick={() => setShowBasicDetails(true)}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Details</span>
                                        </button>
                                    </div>
                                </ShowForPermissions>
                            )}
                    </div>
                    {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No additional details added</p>
                            </div> */}
                    <div className="card-detail-col">
                        {basicData?.map((element: any, index: number) => {
                            const basicDataKey = basicData[index].key as keyof BpAirShippingDetails;
                            return (
                                <div className="details-col box-content-holder" key={`basicData ${index}`}>
                                    <h6 className="details-box-heading">
                                        {element.title}
                                        {element.required && <span className="mandatory-field-text">*</span>}
                                    </h6>
                                    {element.key === 'dropOffDate' || element.key === 'pickUpDate' ? (
                                        <div className="details-box-content">
                                            {shippingDetails?.[basicDataKey]
                                                ? moment(shippingDetails?.[basicDataKey])?.format('DD-MM-YYYY')
                                                : '-'}
                                        </div>
                                    ) : (
                                        <div className="details-box-content">
                                            {shippingDetails?.[basicDataKey] || '-'}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    const viewContainerRenderer = () => {
        switch (tab) {
            case 0:
                return <ContainerDetailTab />;
        }
    };
    return (
        <div className="main-wrapper container" style={{ overflow: 'hidden' }}>
            {viewContainerRenderer()}
            {showBookingSideBar && <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar} />}

            <BasicDetailsModal
                modalView={showBasicDetails}
                setModalView={setShowBasicDetails}
                bpId={id}
                shipmentId={shipmentId}
                setCancelModalView={setCancelModalView}
                shippingDetailsData={shippingDetails}
            />
            <CancelModal
                displayFunction={() => {
                    showBasicDetails && setShowBasicDetails(false);
                    showDatesDetail && setShowDatesDetails(false);
                }}
                header="Booking Details"
                cancelModalVisibility={cancelModalView}
                setCancelModalVisibility={setCancelModalView}
            />
        </div>
    );
};
export default Index;
