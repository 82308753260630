import axios from 'axios';
import { del, get, post } from '../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export interface IData {
    id: number;
    name: string;
    email: string;
}
export interface vendorData {
    cl_pk: string;
    ve_pk: any;
}
// name: string;
// url: string;

// Get All Datas
export const getVendorData = async (payload: any) => {
    try {
        // const res = await get('api/mgt/vendors?ve_status=SUBMITTED', null);
        const res = await get(
            `/cix/scr/admin/organisation/vendor/lst/OR_OrgCode/Or_OrgName?filter[logic]=and&filter[filters][1][field]=OR_OrgCode&filter[filters][1][operator]=contains&filter[filters][1][value]=${payload.search}`,
            null,
            externalBaseUrl
        );

        const data = res?.data?.map((el: any) => ({
            vn_PK: el.VN_PK,
            vn_vendorcode: el.OR_OrgCode,
            vn_vendorname: el.Or_OrgName,
        }));
        const filtered = data?.filter(
            (val: any) => !payload?.assignedVendors?.some((elem: any) => elem.vn_vendorcode === val.vn_vendorcode)
        );
        return filtered;
    } catch (err) {
        return console.error(err);
    }
};
export const getListVendor = async (payload: {
    currentPage: any;
    perPageCount: any;
    columnOrder: any;
    columnStatus: any;
    id: string;
}) => {
    try {
        const res = await get(
            `api/mgt/clients/vendors?UUID=${payload?.id}&limit=${payload?.perPageCount}&page=${
                payload?.currentPage
            }&sortby=${payload?.columnStatus}&sortorder=${!payload.columnOrder ? 'ASC' : 'DESC'}`,
            null
        );
        return res;
    } catch (err) {
        return [];
    }
};
export const unlinkVendor = async (payload: { client_id: string; vendor_id: string }) => {
    try {
        const res = await del(
            `api/mgt/clients/unlink/vendor?cl_pk=${payload.client_id}&ve_pk=${payload.vendor_id}`,
            null
        );
        return res;
    } catch (er) {
        return er;
    }
};

export const linkVendorApi = async (payload: { cl_pk: string; ve_pk: any }) => {
    try {
        await post('api/mgt/clients/link/vendor', payload, null);
        //return console.log(res, 'payload in api');
    } catch (er) {
        return console.log(er);
    }
};

export const searchClientVendors = async (payload: {
    keyword: string;
    id: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    columnOrder: boolean;
}) => {
    try {
        const res = await get(
            `api/mgt/search/vendorclients?vn_pk=${payload.id}&keyword=${payload.keyword}&limit=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortby=${payload.columnStatus}&sortorder=${
                payload.columnOrder ? 'DESC' : 'ASC'
            }`,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchClientVendors2 = async (payload: {
    keyword: string;
    id: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    columnOrder: boolean;
}) => {
    try {
        const res = await get(
            `api/mgt/search/clientvendors?UUID=${payload.id}&keyword=${payload.keyword}&limit=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortby=${payload.columnStatus}&sortorder=${
                payload.columnOrder ? 'DESC' : 'ASC'
            }`,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const validateOrganisationCode = async (code: string) => {
    try {
        const token = localStorage.getItem('token');
        const myHeaders = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
        const res = await axios(`${process.env.REACT_APP_BASE_URL}/api/mgt/vendors/code?code=${code}`, {
            method: 'GET',
            headers: myHeaders,
        });
        return res.data;
    } catch (err) {
        return console.error(err);
    }
};
