import React from 'react';
import { useUserProfile } from 'src/hooks/useUserProfile';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import { useAppDispatch } from '../../../hooks';
import { updateSellQuote } from '../../../store/reducers/bookingProposalAir/flightScheduleReducer';
interface ModalListProps {
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    airFreightId: string;
}
const Index = ({ displayFunction, airFreightId }: ModalListProps) => {
    const { profileType } = useUserProfile();
    const dispatch = useAppDispatch();
    return (
        <div className="app-modal purchase-detail-modal">
            <div className="modal-content-holder ">
                <div className="modal-header">
                    <div className="title">Remove Schedule</div>
                    <button onClick={() => displayFunction(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content">
                        Once removed, this schedule option will not be shared to the client for approval. Are you sure
                        to remove schedule?
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => displayFunction(false)}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        // title="No"
                    >
                        <span className="button-text footer-button-text">No</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        onClick={() => {
                            displayFunction(false);
                            dispatch(
                                updateSellQuote({
                                    airFreightId,
                                    type: 'remove',
                                    mode: 'removeSellQuote',
                                    profile: profileType,
                                })
                            );
                        }}
                        // title="Yes"
                    >
                        <span className="button-text footer-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
