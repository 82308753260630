import { Type } from 'src/pages/Dashboard/InsightsExpanded/enums';

export const SearchHeaderDisplayName = (val: string, isActiveOrders: boolean, isActiveBookings: boolean) => {
    const getForwarderName = () => {
        let forwarderName = '';
        if (isActiveOrders) {
            forwarderName = 'Active Orders';
        } else if (isActiveBookings) {
            forwarderName = 'Active Bookings';
        }
        return forwarderName;
    };

    let name = '';
    switch (val) {
        case 'fcl':
            name = 'Mode: FCL';
            break;
        case 'lcl':
            name = 'Mode: LCL';
            break;
        case 'bcn':
            name = 'Mode: BCN';
            break;
        case 'air':
            name = 'Mode: Air';
            break;
        case 'sea':
            name = 'Mode: Sea';
            break;
        case 'land':
            name = 'Mode: Land';
            break;
        case 'International':
            name = 'Destination Type: International';
            break;
        case 'Triangle':
            name = 'Destination Type: Triangle';
            break;
        case Type.itpo:
            name = `${getForwarderName()}: International POs`;
            break;
        case Type.ibpo:
            name = `${getForwarderName()}: Inbound POs`;
            break;
        case Type.ao:
            name =
                getForwarderName() === 'Active Orders'
                    ? `${getForwarderName()}: Allocation Orders`
                    : `${getForwarderName()}: AO Bookings`;
            break;
        case Type.po:
            name = `${getForwarderName()}: PO Bookings`;
            break;
        default:
            name = val;
            break;
    }
    return name;
};
