import React, { useEffect, useState } from 'react';
import './performance-matrix.scss';
// import CustomerService from './CustomerService';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { filterValues, trackingPerformanceMatrixList } from 'src/utils/kendoGrid/performanceMatrix';
import { useUserProfile } from 'src/hooks/useUserProfile';
const Index: React.FC = () => {
    const { userName, roleName } = useUserProfile();

    const [bodyPayload, setBodyPayload] = useState<any>(null);
    const [filter, _setFilter] = useState('customer-service');
    const [search, setSearch] = useState('');
    const trackingDetails = trackingPerformanceMatrixList.filter((res: any) => res.id === filter)[0];
    const gridItem = JSON.parse(JSON.stringify(trackingDetails));

    useEffect(() => {
        if (userName) {
            setBodyPayload({ loggedInUserName: userName, role: roleName });
        }
    }, [userName]);

    return (
        <div className="performance-matrix-wrapper">
            <div className="header-wrapper">
                <div className="search-field-insight-menu-holder">
                    <div className="insight-search-wrapper">
                        <div className="search-entry-holder">
                            <input
                                placeholder="Search"
                                value={search}
                                onChange={(e) => {
                                    setSearch(e.target.value);
                                    // if (e.target.value?.length >= 3) {
                                    //     setBodyPayload((prev: any) => ({ ...prev, search: e.target.value }));
                                    // }
                                }}
                                type="text"
                                className="search-input input-text"
                                id="search-field-kendo"
                            ></input>
                            <div
                                className={`search-toolip ${
                                    search?.length > 0 && search?.length < 3 ? 'tooltip-show' : ''
                                }`}
                            >
                                Enter atleast 3 characters to search
                            </div>
                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon">
                                    <title>Search</title>
                                </use>
                            </svg>
                            <button
                                onClick={() => {
                                    setSearch('');
                                    // setBodyPayload((prev: any) => ({ ...prev, search: '' }));
                                }}
                                className="app-btn text-close-btn"
                                id="clear-btn-kendo"
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </button>
                        </div>
                        <div className="insight-menu-wrapper">
                            <button
                                // onClick={() => setFilter('customer-service')}
                                className={`app-btn app-btn-secondary ${
                                    filter === 'customer-service' ? 'active' : 'non-active'
                                } `}
                                title="Customer Service"
                                type="button"
                            >
                                <span className="button-text">Customer Service</span>
                            </button>
                            <button
                                // onClick={() => setFilter('customs')}
                                className={`app-btn app-btn-secondary ${
                                    filter === 'customs' ? 'active' : 'non-active'
                                } `}
                                title="Customs"
                                type="button"
                            >
                                <span className="button-text">Customs</span>
                            </button>
                            <button
                                // onClick={() => setFilter('operations')}
                                className={`app-btn app-btn-secondary ${
                                    filter === 'operations' ? 'active' : 'non-active'
                                } `}
                                title="Operations"
                                type="button"
                            >
                                <span className="button-text">Operations</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <CustomerService></CustomerService> */}
            {bodyPayload && (
                <KendoGridJquery
                    gridItem={gridItem}
                    filterValues={filterValues}
                    body={bodyPayload}
                    hideFilter={false}
                    type="tracking-performance-matrix"
                />
            )}
        </div>
    );
};
export default Index;
