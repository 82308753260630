import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { fetchSelectedSailing } from 'src/store/reducers/bookingProposal/sailingReducer';
// import { bookingBasicDetailsFetch } from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import {
    bookingBasicDetailsFetch,
    bookingBasicDetailsFetchMbl,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import BasicDetailsTab from '../BasicDetailsTab';
import '../lcsBookingDetails.scss';
import ScheduleTab from '../ScheduleTab';
// import SkuTab from '../SkuTab';
// import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { getSelectedSchedules, getSelectedSchedulesMBL } from 'src/store/reducers/bookingProposalAir/addSchedule';
import { RootState } from 'src/store/reducers';

/* eslint-disable no-undefined */
const baseURL = process.env.REACT_APP_BASE_URL;
const Index: React.FC = () => {
    const { bookingBasicDetails } = useSelector((state: any) => state.lifeCycleSearch);
    const { selectedSchedules } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const { id, type } = useParams();
    const [lcsTab, setLcsTab] = useState('');
    const [loading, setLoading] = useState<boolean>(false);
    const dispatch = useDispatch();
    const location = useLocation() as any;
    const navigate = useNavigate();
    const { profileType, userName } = useUserProfile();

    const gridItemBookings = gridDetails.filter((res) => res.name === 'bookings')[0];
    const gridItemHBL = gridDetails.filter((res) => res.name === 'house-bill')[0];
    const [gridItem, setgridItem] = useState(gridItemBookings);
    const mblno = location?.state?.name;
    // const nameOfGraph = nameOfPages.name2;
    const gridItemMBL = gridDetails.filter((res) => res.name === 'master-bill')[0];
    const gridItemBookingsMBL = gridDetails.filter((res) => res.name === 'mbl-bookings')[0];
    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };

    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    useEffect(() => {
        // if (type === 'booking') {
        // if (!gridItem.url.includes('?')) {
        // gridItem.url += `?id=${id}&profile=${profileType}`;
        // }
        if (lcsTab === 'SKU') {
            gridItemBookings.url = `${baseURL}/api/bkg/tracking/lifecycle/search/bookings/sku?id=${id}&profile=${profileType}`;
            gridItemHBL.url = `${baseURL}/api/bkg/tracking/lifecycle/search/bookings/sku?id=${id}&profile=${profileType}`;
            gridItemMBL.url = `${baseURL}/api/bkg/tracking/lifecycle/mbldata/sku?mblNumber=${id}`;
        }
        if (lcsTab === 'MblBookings' && type === 'master-bill') {
            gridItemBookingsMBL.url = `${baseURL}/api/bkg/tracking/lifecycle/mbldata/bookings?mblNumber=${id}`;
        }

        setLoading(true);
    }, [lcsTab, type, mblno]);
    // const { mblno1 } = useParams();
    useEffect(() => {
        if (type === 'bookings') {
            setgridItem(gridItemBookings);
        } else if (type === 'house-bill') {
            setgridItem(gridItemHBL);
        } else if (type === 'master-bill') {
            setgridItem(gridItemMBL);
        }
    }, [type]);
    useEffect(() => {
        if (type === 'master-bill') {
            setLcsTab('MblBookings');
        } else {
            setLcsTab('Basic Details');
        }
    }, [id]);

    useEffect(() => {
        gridItemBookings.url = `${baseURL}/api/bkg/tracking/lifecycle/search/bookings/sku?id=${id}&profile=${profileType}`;
        gridItemHBL.url = `${baseURL}/api/bkg/tracking/lifecycle/search/bookings/sku?id=${id}&profile=${profileType}`;
        gridItemMBL.url = `${baseURL}/api/bkg/tracking/lifecycle/mbldata/sku?mblNumber=${location?.state?.name}`;
    }, []);
    const bookingType = location?.state?.type;
    const booking_type = location?.state?.bpType;
    useEffect(() => {
        if (type === 'master-bill') {
            dispatch(getSelectedSchedulesMBL(id));
        } else if (type === 'house-bill' && booking_type === 'AIR') {
            dispatch(getSelectedSchedules(id));
            dispatch(bookingBasicDetailsFetch({ id: id, profile: profileType }));
        } else {
            if (bookingType !== 'AIR') {
                dispatch(fetchSelectedSailing(id));
            } else {
                dispatch(getSelectedSchedules(id));
            }
            dispatch(bookingBasicDetailsFetch({ id: id, profile: profileType }));
        }
    }, [id, profileType, bookingType, lcsTab, type]);
    useEffect(() => {
        if (type === 'master-bill') {
            dispatch(bookingBasicDetailsFetchMbl({ mblNumber: location?.state?.name }));
        }
    }, [type]);

    const Btn: any = () => {
        return (
            <div className="head-btn-holder">
                {type !== 'master-bill' ? (
                    <button
                        className="app-btn app-btn-secondary"
                        title="Go to Booking"
                        onClick={() => {
                            navigate(
                                bookingType === 'FCL' || booking_type === 'FCL'
                                    ? `/booking_list/${id}/POSelection`
                                    : bookingType === 'BCN' || booking_type === 'BCN'
                                    ? `/bcnBookingList/${id}/POSelection`
                                    : bookingType === 'LCL' || booking_type === 'LCL'
                                    ? `/lclbooking_list/${id}/POSelection`
                                    : bookingType === 'AIR' || booking_type === 'AIR'
                                    ? `/air_booking_list/${id}/POSelection`
                                    : `/Aobooking_list/${id}/AOSelection`
                            );
                        }}
                    >
                        <span className="button-text">Go to Booking</span>
                    </button>
                ) : (
                    <div></div>
                )}
            </div>
        );
    };

    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    {type === 'master-bill' ? (
                        <a
                            className={`tab-nav ${lcsTab === 'MblBookings' ? 'active' : ''}`}
                            onClick={() => setLcsView('MblBookings')}
                        >
                            Bookings
                        </a>
                    ) : (
                        <a
                            className={`tab-nav ${lcsTab === 'Basic Details' ? 'active' : ''}`}
                            onClick={() => setLcsView('Basic Details')}
                        >
                            Basic Details
                        </a>
                    )}

                    <a className={`tab-nav ${lcsTab === 'SKU' ? 'active' : ''}`} onClick={() => setLcsView('SKU')}>
                        SKU
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'Schedule' ? 'active' : ''}`}
                        onClick={() => setLcsView('Schedule')}
                    >
                        Schedule
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content">
                {lcsTab === 'Basic Details' && bookingBasicDetails.originPort !== undefined && (
                    <BasicDetailsTab bookingData={bookingBasicDetails} Btn={Btn} dataAir={selectedSchedules} />
                )}

                {lcsTab === 'SKU' && loading && (
                    <KendoGridJquery
                        gridItem={gridItem}
                        Btn={Btn}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'MblBookings' && loading && (
                    <KendoGridJquery
                        gridItem={gridItemBookingsMBL}
                        Btn={Btn}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'Schedule' && <ScheduleTab Btn={Btn} />}
            </div>
        </div>
    );
};
export default Index;
