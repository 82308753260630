import { createSlice } from '@reduxjs/toolkit';
/* eslint-disable no-undefined */

export const dataSlice = createSlice({
    name: 'qms',
    initialState: {
        rateHeader: [] as any,
        quoteHeader: [] as any,
        ssrsReport: 'NA' as any,
    },
    reducers: {
        fetchRateHeader: (state, action) => {
            state.rateHeader = action.payload;
        },
        fetchQuoteHeader: (state, action) => {
            state.quoteHeader = action.payload;
        },
        fetchQuoteReport: (state, action) => {
            state.ssrsReport = action.payload;
        },
        resetQuoteSSRS: (state, action) => {
            state.ssrsReport = 'NA';
        },
    },
});

export const { fetchRateHeader, fetchQuoteHeader, fetchQuoteReport, resetQuoteSSRS } = dataSlice.actions;
export default dataSlice.reducer;
