import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { RootState } from 'src/store/reducers';

import '../todo-grid-view.scss';
import { useDispatch } from 'react-redux';
import { markSingleRead } from 'src/store/reducers/authReducer';
// import BSTooltip from 'src/components/common/BSTooltip';
// import TableToolTip from 'src/components/common/TableToolTip';

const Index: React.FC<any> = ({ path }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { todoItem, isLoading } = useSelector((state: RootState) => state.dashboardTodo);
    const [expandGoods, setexpandGoods] = useState(false);
    const [goodIndex, setgoodIndex] = useState(0);

    return (
        <>
            {!todoItem?.poListingDtos?.length && !isLoading && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No recent activity from the last 30 Days</p>
                </div>
            )}
            <div className="view-list-grid draft-po-view" onClick={() => setexpandGoods(false)}>
                {todoItem?.poListingDtos?.map((po: any, i: any) => (
                    <div key={po.poId} className={`row-wrapper ${!po?.isViewed ? ' click-active' : ''}`}>
                        <div className="list-grid">
                            <div className="grid-child-item col-name">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">PO No.</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">{po.poNumber}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item span-2"></div>
                            <div className="grid-child-item col-destination">
                                {po.originPort && po.destinationPort && (
                                    <div className="grid-child-data">
                                        <div className="des-progress-bar">
                                            <div className="destination begin-journey">
                                                <div className="point12">
                                                    <div className="point1" title="Yantain">
                                                        {po.originPortName}
                                                    </div>
                                                    {po?.originPortCode && (
                                                        <div className="point-name">({po?.originPortCode})</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="destination end-journey">
                                                <div className="point1">
                                                    {po.destinationPortName}
                                                    {/* Multi Destination */}
                                                </div>
                                                {po?.destinationPortCode &&
                                                    po?.destinationPortName !== 'Multi destination' && (
                                                        <div className="point-name">({po?.destinationPortCode})</div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                <div className="status-badge">
                                    {po.shippingmode && (
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">{po.shippingmode}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="item-count-status-holder">
                                        <div className="grid-item-count-status status-ui disabled-status">
                                            <span className="status-text">{po.skuCount} SKU</span>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    title="View PO"
                                    className="app-btn app-btn-secondary grid-btn"
                                    onClick={() => {
                                        if (po?.notificationIds) {
                                            po?.notificationIds?.forEach((notifications: string) => {
                                                dispatch(
                                                    markSingleRead({
                                                        id: notifications,
                                                        status: 'read',
                                                    })
                                                );
                                            });
                                        }
                                        navigate(path(po.poId));
                                    }}
                                >
                                    <span className="button-text footer-button-text">View PO</span>
                                </button>
                            </div>
                        </div>
                        <div className="list-grid">
                            <div className="grid-child-item col-client">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Client</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">{po.clientName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item col-vendor">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Vendor</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">{po.vendorName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item col-grd">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Goods Ready Date</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">
                                                {po?.goodsReadyDate?.length > 0 ? (
                                                    <span className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {po?.goodsReadyDate &&
                                                                moment(po?.goodsReadyDate[0]).format('DD-MM-YYYY')}
                                                            <div className="dropdown-wrap  menu-down dropdown-open info-dropdown left-align">
                                                                {po?.goodsReadyDate?.length > 1 && (
                                                                    <button
                                                                        className="menu-btn app-btn"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setexpandGoods(!expandGoods);
                                                                            setgoodIndex(i);
                                                                        }}
                                                                    >
                                                                        <span className="btn-text">
                                                                            +{po?.goodsReadyDate?.length - 1}
                                                                        </span>
                                                                    </button>
                                                                )}
                                                                {expandGoods && i === goodIndex && (
                                                                    <div
                                                                        className="dropdown-menu"
                                                                        style={{ zIndex: 50 }}
                                                                    >
                                                                        {po?.goodsReadyDate
                                                                            ?.slice(1, po?.goodsReadyDate?.length)
                                                                            .map((item: any) => (
                                                                                <a
                                                                                    href="#"
                                                                                    className="menu-item app-btn"
                                                                                >
                                                                                    {moment(item).format('DD-MM-YYYY')}
                                                                                </a>
                                                                            ))}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </span>
                                                ) : (
                                                    moment(po?.goodsReadyDate).format('DD-MM-YYYY')
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item col-ssw">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Start Shipping Window</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">
                                                {po?.startShipWindowDate
                                                    ? moment(po?.startShipWindowDate).format('DD-MM-YYYY')
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
export default Index;
