import React, { useEffect, useState } from 'react';
import './agent-commercial-detail.scss';
import '../agent-tab.scss';
import AddCommercialModal from './AddCommercialModal';
// import CommercialDetailsModal from './CommercialDetailsModal';
import { Link, useParams } from 'react-router-dom';
import TableWithFilter from '../../../../components/TableWithFilter';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    selectCommercialDetailUser,
    fetchCommercialDetailsList,
    // fetchCommercialDetailsColumnList,
    selectCommercialDetailCurrentPage,
    selectCommercialDetailColumnStatus,
    selectCommercialDetailtotalNoOfPages,
    selectCommercialDetailtotalNoOfItems,
    selectCommercialDetailLoading,
    clearCommercialDetails,
    selectCommercialDetailColumnUser,
    // CommercialDetailsDocumentsDtoList,
    fetchAuthorDetails,
    // fetchForwarderSignatoryDetails,
    fetchAgreementTermsDetails,
    submitAgent,
    searchCommercialDetailsData,
} from '../../../../store/reducers/profiles/agents/commercialDetailsReducer';
import { paginationLimit } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination/Pagination';
// import { useMeasure } from 'react-use';
import ColumnFilter from '../../../../components/TableWithFilter/filter';
import { commercialDetails as tableProperties } from '../../../../utils/tableStructure/agent';
import CancelModal from '../../../../components/common/CancelModal';
import ShowForPermissions from '../../../../ShowForPermissions';
import HeaderTabsAgents from 'src/pages/Profiles/Agents/HeaderTabsAgents';
import { fetchHeaderDataAgents } from 'src/store/reducers/agentReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    downloadAgentCommercial,
    getSingleCommercialData,
} from 'src/services/apis/profiles/agents/commercialDetailsApi';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isFromView, setIsFromView] = useState(false);
    const [mode, setMode] = useState('add');
    const [displayCancel, setDisplayCancel] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const commercialDetails = useAppSelector(selectCommercialDetailUser);
    const commercialDetailsColumn = useAppSelector(selectCommercialDetailColumnUser);
    const [commercialDetailColumnData, setCommercialDetailColumnData] = useState<any>([]);
    const [commercialDetailColumnName, setCommercialDetailColumnName] = useState<any>('');
    const [currentColumnName, setCurrentColumnName] = useState<any>('');
    const [commercialData, setCommercialData] = useState({});
    const isLoading = useAppSelector(selectCommercialDetailLoading);
    const [selectedHeader, setSelectedHeader] = useState('ad_docname');
    const currentPage = useAppSelector(selectCommercialDetailCurrentPage);
    const columnStatus = useAppSelector(selectCommercialDetailColumnStatus);
    const totalNoOfPages = useAppSelector(selectCommercialDetailtotalNoOfPages);
    const totalNoOfItems = useAppSelector(selectCommercialDetailtotalNoOfItems);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [selectedFilter, _setselectedFilter] = useState<any | null>(null);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>([]);
    const [setPosition, setSetPosition] = useState<number>(0);
    // const [refName, { width: widthName }] = useMeasure<HTMLTableCellElement>();
    // const [refVersion, { width: widthVersion }] = useMeasure<HTMLTableCellElement>();
    // const [refAuthor, { width: widthAuthor }] = useMeasure<HTMLTableCellElement>();
    // const [refForwarderSignatory, { width: widthForwarderSignatory }] = useMeasure<HTMLTableCellElement>();
    // const [refAgentSignatory, { width: widthAgentSignatory }] = useMeasure<HTMLTableCellElement>();
    // const [refStatus, { width: widthStatus }] = useMeasure<HTMLTableCellElement>();
    // const [refStartDate, { width: widthStartDate }] = useMeasure<HTMLTableCellElement>();
    // const [refEndDate, { width: widthEndDate }] = useMeasure<HTMLTableCellElement>();
    // const [refRenewal, { width: widthRenewal }] = useMeasure<HTMLTableCellElement>();
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const { agentHeader } = useSelector((state: RootState) => state.agent);

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        statusFilter: selectedFilter,
        columnOrder: !currentOrder,
        totalNoOfPages: totalNoOfPages,
        totalNoOfItems: totalNoOfItems,
        id,
    };

    useEffect(() => {
        dispatch(clearCommercialDetails([]));
        dispatch(fetchAuthorDetails([]));
        // dispatch(fetchForwarderSignatoryDetails([]));
        dispatch(fetchAgreementTermsDetails([]));
        dispatch(fetchHeaderDataAgents(id));
    }, []);

    useEffect(() => {
        if (commercialDetailsColumn) {
            setCommercialDetailColumnData(
                (commercialDetails as any)?.data?.info?.distinctColumnValues[commercialDetailColumnName]
            );
            setStatusFilterSelected([]);
            setSearchName('');
        }
    }, [commercialDetailsColumn, dispatch]);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        // if (keyword) {
        // const results = commercialDetailsColumn?.info.filter((user: string) => {
        //     return user && user.toLowerCase().includes(keyword.toLowerCase());
        // });
        setCommercialDetailColumnData(
            (commercialDetails as any)?.data?.info?.distinctColumnValues[commercialDetailColumnName]
        );
        // } else {
        //     setCommercialDetailColumnData(
        //         (commercialDetails as any)?.data?.info?.distinctColumnValues[commercialDetailColumnName]
        //     );
        // }
        setSearchName(keyword);
    };

    useEffect(() => {
        if (searchTerm?.trim()?.length > 1) {
            // dispatch(
            //     searchCommercialDetailsData({
            //         ...payload,
            //         keyword: searchTerm,
            //         id,
            //         currentPage: 0,
            //         columnStatus: selectedHeader,
            //         statusFilterColumn: commercialDetailColumnName,
            //         statusFilter: statusFilterSelected,
            //         columnOrder: !currentOrder,
            //     })
            // );
        } else {
            // dispatch(
            //     fetchCommercialDetailsList({
            //         ...payload,
            //         id,
            //         currentPage: 0,
            //         columnStatus: selectedHeader,
            //         statusFilterColumn: commercialDetailColumnName,
            //         statusFilter: statusFilterSelected,
            //         columnOrder: !currentOrder,
            //     })
            // );
        }
    }, [statusFilterSelected]);

    const [currentPageNumber, setCurrentPageNumber] = useState<null | number>(null);

    useEffect(() => {
        if (currentPageNumber) {
            if (searchTerm?.trim()?.length > 1) {
                const payload2 = { ...payload, keyword: searchTerm, perPageCount, currentPage: 0 };
                // dispatch(searchCommercialDetailsData(payload2));
            } else if (searchTerm?.trim()?.length === 0) {
                // dispatch(
                //     fetchCommercialDetailsList({
                //         ...payload,
                //         currentPage: currentPageNumber - 1,
                //         perPageCount: perPageCount,
                //         columnStatus: selectedHeader,
                //         // statusFilter: selectedFilter,
                //         columnOrder: !currentOrder,
                //         statusFilterColumn: commercialDetailColumnName,
                //         statusFilter: statusFilterSelected,
                //     })
                // );
            }
        }
    }, [currentPageNumber]);

    const sortByColumn = (columnName: string) => {
        setcurrentOrder(!currentOrder);
        setSelectedHeader(columnName);
        if (searchTerm?.trim()?.length > 1) {
            // dispatch(
            //     searchCommercialDetailsData({
            //         ...payload,
            //         keyword: searchTerm,
            //         currentPage: 0,
            //         columnStatus: columnName,
            //         columnOrder: currentOrder,
            //         statusFilterColumn: commercialDetailColumnName,
            //         statusFilter: statusFilterSelected,
            //     })
            // );
        } else {
            // dispatch(
            //     fetchCommercialDetailsList({
            //         ...payload,
            //         currentPage: 0,
            //         columnStatus: columnName,
            //         columnOrder: currentOrder,
            //         statusFilterColumn: commercialDetailColumnName,
            //         statusFilter: statusFilterSelected,
            //     })
            // );
        }
    };
    const filterByColumn = (e: any, columnName: string) => {
        setTimeout(() => {
            setToggleDropdown(!toggleDropdown);
        }, 300);
        if (!toggleDropdown) {
            //dispatch(
            //    fetchCommercialDetailsColumnList({
            //        id,
            //        columnName,
            //    })
            //);
            setCommercialDetailColumnName(columnName);
            setTimeout(() => {
                setCommercialDetailColumnData((commercialDetails as any)?.data?.info?.distinctColumnValues[columnName]);
            }, 250);
        }
        filterDistance(columnName);
        setSearchName('');
        filter(e);

        e.stopPropagation();
    };

    const filterDistance = (columnName: string) => {
        const el = document.getElementById(columnName);
        const distance = el?.getBoundingClientRect().left;
        distance && setSetPosition(distance - 30);
    };

    useEffect(() => {
        if (searchTerm?.trim()?.length > 2) {
            const payload2 = { ...payload, keyword: searchTerm, perPageCount, currentPage: 0 };
            // dispatch(searchCommercialDetailsData(payload2));
        } else if (searchTerm?.trim()?.length === 0) {
            // dispatch(
            //     fetchCommercialDetailsList({
            //         ...payload,
            //         currentPage: 0,
            //     })
            // );
        }
    }, [searchTerm]);

    // const { roleName } = useUserProfile();
    // const { permissions } = useSelector((state: any) => state.permissionData) as any;
    // const checkPermission = (permission: any, type: any) => {
    //     let flag = true;
    //     if (roleName?.toLowerCase() === 'it admin') {
    //         return false;
    //     }
    //     for (let i = 0; i < permissions?.length; i++) {
    //         if (
    //             (permissions[i].access
    //                 .toLowerCase()
    //                 .split(/\s+|\./)
    //                 .includes(permission.toLowerCase()) &&
    //                 permissions[i].access
    //                     .toLowerCase()
    //                     .split(/\s+|\./)
    //                     .includes(type.toLowerCase())) ||
    //             permissions[i].access
    //                 .toLowerCase()
    //                 .split(/\s+|\./)
    //                 .includes('alladmin')
    //         ) {
    //             flag = false;
    //         }
    //     }
    //     return flag;
    // };

    //@ts-ignore
    window.attachmentAction = (type: string, id: string, fileName: string) => {
        // if (!checkPermission('download', 'commercial')) {
        downloadAgentCommercial(id, fileName);
        // } else {
        // error('Download permission is not available', Position.TOP_RIGHT);
        // }
    };
    //@ts-ignore
    window.viewContract = async (type: string, AD_PK: string) => {
        const res: any = await getSingleCommercialData({ AD_AG: id, AD_PK });
        setCommercialData(res);
        setIsEdit(true);
        setModalView(true);
    };

    return (
        <div
            onClick={() => {
                setPageCountDisplay(false);
            }}
            className="main-wrapper container agent-commercial-container"
        >
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link className="dropdown-arrow" to="/agent" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link to="/profile" className="breadcrumb-item">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to="/agent" className="breadcrumb-item">
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Agent
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    {/* <Link to="/agent">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">{localStorage.getItem('agentName')}</h2>
                </div>
                <div className="arrow-tab-container">
                    <div className="arrow-tab-holder vendor-arrow-holder">
                        <HeaderTabsAgents />
                    </div>
                </div>
            </div>
            <div className="grid-section agent-commercial-grid loader-container grid-p-0">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-holder">
                    <div className="grid-header grid-mr-20">
                        <div className="search-entry-holder">
                            {/* <input
                                placeholder="Search Commercials"
                                type="text"
                                className="search-input input-text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            ></input>
                            <div
                                className={`search-toolip ${
                                    searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                }`}
                            >
                                Enter atleast 3 characters to search
                            </div>
                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon"></use>
                            </svg>
                            <button
                                className="app-btn text-close-btn"
                                onClick={() => {
                                    if (searchTerm?.trim()?.length) {
                                        setSearchTerm('');
                                        dispatch(
                                            fetchCommercialDetailsList({
                                                ...payload,
                                                currentPage: 0,
                                                perPageCount: perPageCount,
                                                columnStatus: selectedHeader,
                                                statusFilter: selectedFilter,
                                                columnOrder: currentOrder,
                                            })
                                        );
                                    }
                                }}
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button> */}
                        </div>
                        <div className="item-count-status-holder">
                            {/* <div className="grid-item-count-status status-ui success-status">
                                <span className="count">{commercialDetails?.data?.info?.totalActiveDocuments}</span>
                                <span className="status-text">Active Commercials</span>
                            </div> */}
                        </div>
                        <ShowForPermissions permission="edit" type="agent">
                            <button
                                onClick={() => {
                                    if (buttonClicked) {
                                        setModalView(true);
                                    } else {
                                        // setTimeout(() => {
                                        setModalView(true);
                                        // }, 700);
                                        setButtonClicked(true);
                                    }
                                    setIsEdit(false);
                                    setMode('add');
                                    setCommercialData({});
                                }}
                                className="app-btn app-btn-secondary icon-button grid-top-btn add-commercial-btn"
                                title="Add Commercial"
                            >
                                <svg className="svg-icon plus-icon   ">
                                    <use href="#plusIcon"></use>
                                </svg>
                                <span className="button-text client-button-text">Add Commercial</span>
                            </button>
                        </ShowForPermissions>
                    </div>
                    <LayoutWithColumns style={'tab'} content={'agent-commercial'} />
                    {/* <div
                        onClick={(e) => {
                            setToggleDropdown(false);
                            setSearchName('');
                            filter(e);
                        }}
                        onScroll={() => filterDistance('')}
                        className="grid-container"
                    >
                        {!commercialDetails?.data?.info?.agentDocumentList.length && (
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No Commercial Details Available</p>
                            </div>
                        )}
                        <div className="grid-scroll-content">
                            <TableWithFilter
                                name="commercialDetails"
                                tableProperties={tableProperties}
                                data={commercialDetails?.data?.info?.agentDocumentList}
                                setData={setCommercialData}
                                setIsEdit={setIsEdit}
                                setModalView={setModalView}
                                sortFunction={sortByColumn}
                                columnStatus={columnStatus}
                                currentOrder={currentOrder}
                                toggleDropdown={toggleDropdown}
                                columnName={commercialDetailColumnName}
                                filterFunction={filterByColumn}
                                selectedFilterItem={statusFilterSelected}
                            />
                        </div>
                    </div> */}
                    {/* {toggleDropdown && (
                        <ColumnFilter
                            setPosition={setPosition}
                            searchName={searchName}
                            setSearchName={setSearchName}
                            filter={filter}
                            data={commercialDetailColumnData}
                            statusFilterSelected={statusFilterSelected}
                            setStatusFilterSelected={setStatusFilterSelected}
                            isLoading={isLoading}
                            setToggleDropdown={setToggleDropdown}
                            columnName={commercialDetailColumnName}
                            currentColumnName={currentColumnName}
                            setCurrentColumnName={setCurrentColumnName}
                        />
                    )} */}
                    {/* {totalNoOfItems > 0 ? (
                        <div className="grid-footer ">
                            <div className="grid-footer-left">
                                <div className="footer-text">Showing</div>
                                <div className="row-per-page">
                                    <div
                                        className={
                                            'dropdown-wrap pagination-dropdown menu-up ' +
                                            (PageCount ? 'dropdown-open' : '')
                                        }
                                    >
                                        <button
                                            className="menu-btn app-btn"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setPageCountDisplay(!PageCount);
                                            }}
                                        >
                                            <span className="btn-text">
                                                {commercialDetails?.data?.info?.agentDocumentList &&
                                                perPageCount > commercialDetails?.data?.info?.agentDocumentList.length
                                                    ? commercialDetails?.data?.info?.agentDocumentList.length
                                                    : perPageCount}
                                            </span>
                                            <span className="dropdown-arrow">
                                                <svg className="svg-icon arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                        {PageCount && (
                                            <div className="dropdown-menu">
                                                {paginationLimit.map((res, index: number) => (
                                                    <a
                                                        key={index}
                                                        onClick={(e) => {
                                                            setPageCountDisplay(false);
                                                            e.stopPropagation();
                                                            setPerPageCount(res);
                                                            if (searchTerm?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchCommercialDetailsData({
                                                                        ...payload,
                                                                        keyword: searchTerm,
                                                                        currentPage: 0,
                                                                        perPageCount: res,
                                                                        columnStatus: selectedHeader,
                                                                        // statusFilter: selectedFilter,
                                                                        columnOrder: !currentOrder,
                                                                        statusFilterColumn: commercialDetailColumnName,
                                                                        statusFilter: statusFilterSelected,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchCommercialDetailsList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        perPageCount: res,
                                                                        columnStatus: selectedHeader,
                                                                        // statusFilter: selectedFilter,
                                                                        columnOrder: !currentOrder,
                                                                        statusFilterColumn: commercialDetailColumnName,
                                                                        statusFilter: statusFilterSelected,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="menu-item app-btn"
                                                    >
                                                        {res}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <span>of</span>
                                    <span className="grid-total-row-count">{totalNoOfItems ? totalNoOfItems : 0}</span>
                                    <span>{totalNoOfItems > 1 ? 'entries' : 'entry'}</span>
                                </div>
                            </div>
                            <div className="grid-footer-right">
                                <div className="grid-pagination">
                                    <Pagination
                                        currentPage={currentPage + 1}
                                        totalCount={totalNoOfItems}
                                        pageSize={perPageCount}
                                        onPageChange={(page: number) => setCurrentPageNumber(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null} */}
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/profile/agent/${id}/port_details`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    {agentHeader?.isBLPAgent ? (
                        <Link
                            className="app-btn app-btn-primary footer-btn"
                            title="Next"
                            to={`/profile/agent/${id}/integration_details`}
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </Link>
                    ) : null}
                    <ShowForPermissions permission="edit" type="agent">
                        {
                            // commercialDetails?.data?.info?.agentDocumentList?.length > 0 &&
                            agentHeader?.commercialsTabComplete &&
                            localStorage.getItem('agentStatus') !== 'ACTIVE' &&
                            !agentHeader?.isBLPAgent ? (
                                <button
                                    style={{ marginLeft: '8px' }}
                                    type="button"
                                    className="app-btn app-btn-primary footer-btn commercial-submit-btn"
                                    title="Submit and Activate"
                                    onClick={() => {
                                        dispatch(submitAgent({ agentId: id }));
                                    }}
                                >
                                    <span className="button-text footer-button-text">Submit and Activate</span>
                                </button>
                            ) : null
                        }
                        {
                            // commercialDetails?.data?.info?.agentDocumentList?.length > 0 &&
                            agentHeader?.commercialsTabComplete &&
                            localStorage.getItem('agentStatus') === 'ACTIVE' &&
                            !agentHeader?.isBLPAgent ? (
                                <Link
                                    to={'/agent'}
                                    className="app-btn app-btn-primary footer-btn"
                                    title="Back to Profile"
                                >
                                    <span className="button-text footer-button-text">Back to Profile</span>
                                </Link>
                            ) : null
                        }
                    </ShowForPermissions>
                    {/* <a
                        className="app-btn app-btn-primary footer-btn commercial-submit-btn"
                        title="Submit and Activate"
                        href="#"
                    >
                        <span className="button-text footer-button-text">Submit and Activate</span>
                    </a> */}
                </div>
            </div>
            <AddCommercialModal
                display={modalView}
                displayFunction={setModalView}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                data={commercialData}
                setData={setCommercialData}
                isFromView={isFromView}
                setIsFromView={setIsFromView}
                mode={mode}
                setMode={setMode}
                selectedHeader={selectedHeader}
                selectedFilter={selectedFilter}
                perPageCount={perPageCount}
                setDisplayCancel={setDisplayCancel}
            />
            {/* <CommercialDetailsModal></CommercialDetailsModal> */}
            <CancelModal
                displayFunction={setModalView}
                mode={mode}
                header="Commercial"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
        </div>
    );
};
export default Index;
