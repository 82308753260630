import { createSlice } from '@reduxjs/toolkit';
import { agentTableHeaders } from '../../utils/constants';
import { agentBasicDetails } from '../../services/apis/agentApi';

interface IAgentHeader {
    bdTabComplete: boolean;
    portDetailsTabComplete: boolean;
    commercialsTabComplete: boolean;
    idTabComplete: boolean;
    isBLPAgent: boolean;
    or_PK: string;
}

interface IAgentState {
    agents: any;
    headerTab: any;
    isLoading: boolean;
    isLoading2: boolean;
    currentPage: number | string | any;
    columnStatus: any;
    perPageCount: number | string | any;
    totalPages: number | string | any;
    totalItems: number | string | any;
    filterValues: any;
    itemsInList: number | string | any;
    selectedFilter: any;
    agentHeader: IAgentHeader;
    agentBasicDetails?: agentBasicDetails;
}

const initialState: IAgentState = {
    agents: [],
    headerTab: [],
    isLoading: true,
    isLoading2: true,
    currentPage: 0,
    columnStatus: agentTableHeaders.status,
    perPageCount: 10,
    totalPages: 2,
    totalItems: 10,
    filterValues: {},
    itemsInList: 10,
    selectedFilter: null,
    agentHeader: {
        bdTabComplete: false,
        portDetailsTabComplete: false,
        commercialsTabComplete: false,
        idTabComplete: false,
        isBLPAgent: false,
        or_PK: '',
    },
    agentBasicDetails: undefined,
};

export const dataSlice = createSlice({
    name: 'agent',
    initialState,
    reducers: {
        fetchAgentDetails: (state, action) => {
            state.agentBasicDetails = action.payload.agentDetails;
        },
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchAgentData: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload?.columnStatus;
            state.currentPage = action.payload?.currentPage;
            state.agents = action.payload.agents?.agentResponseLists;
            state.totalPages = action.payload.agents?.totalNoOfPages;
            state.totalItems = action.payload.agents?.totalNoOfItems;
            const num = action.payload.agents?.agentResponseLists?.reduce(
                (prev: number, current: any) => prev + current.childAgents?.length,
                0
            );
            state.itemsInList = action.payload.agents?.agentResponseLists?.length + (num || 0);
            state.filterValues = action.payload.agents?.distinctColumnValues;
            state.isLoading = false;
            state.isLoading2 = true;
        },
        fetchHeaderData: (state, action) => {
            state.headerTab = action.payload.headerTab?.info;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading2 = action.payload;
        },
        searchAgentData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload?.columnStatus;
            state.agents = action.payload.agents?.content;
            state.totalPages = action.payload.agents?.totalPages;
            state.totalItems = action.payload.agents?.totalElements;
            state.filterValues = action.payload.agents?.distinctColumnValues;
            const num = action.payload.agents?.content?.reduce(
                (prev: number, current: any) => prev + current.childAgents?.length,
                0
            );
            state.itemsInList = action.payload.agents?.content?.length + (num || 0);
            state.isLoading = false;
            state.isLoading2 = false;
        },
        setselectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        fetchHeaderDataAgents: (_state, _action) => {
            // console.log(state);
        },
        setHeaderDataAgents: (state, action) => {
            state.agentHeader = { ...action.payload?.info?.tabValues, or_PK: action.payload?.info?.or_PK };
        },
    },
});

export const {
    fetchAgentData,
    fetchHeaderData,
    setPageCount,
    isLoadingFn,
    setDefault,
    searchAgentData,
    setselectedFilter,
    setCurrentPage,
    fetchHeaderDataAgents,
    setHeaderDataAgents,
    fetchAgentDetails,
} = dataSlice.actions;

export default dataSlice.reducer;
