import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './discrepencies.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { getOrganisationData } from '../../../utils';
import {
    fetchClientAndWarehouseData,
    fetchInventoryData,
    fetchInventoryDataDiscrepancy,
    fetchWareHouseData,
    setPageCount,
    viewedDiscrepancy,
} from '../../../store/reducers/inventory/inventoryListReducer';
import TableFooter from '../../../components/common/Table/tableFooter';
import ExportButton from '../../../components/common/ExportButton';
import { exportInventoryListDiscrepancy } from '../../../services/apis/inventory/inventoryListApi';
import ProductDetailModal from '../../BookingProposal/BookingAddedGrid/ProductDetailsModal';
import moment from 'moment';
import EnquiryLogModal from './EnquiryLogModal';
import FilterCheckboxDropdown from 'src/components/common/FIlterCheckboxDropdown';

interface pageProp {
    setcurrentPage: any;
    setlogAvailable: any;
    logAvailable: boolean;
    setWarehouseDropDownOpen: any;
    warehouseDropDownOpen: boolean;
    clientDropDownOpen: boolean;
    setClientDropDownOpen: any;
}

const Index: React.FC<pageProp> = ({
    setcurrentPage,
    setlogAvailable,
    setWarehouseDropDownOpen,
    warehouseDropDownOpen,
    setClientDropDownOpen,
    clientDropDownOpen,
}) => {
    /* discrepancy listing page
        1. dispatch action for discrepancy listing network call.
        2. mapping discrepancy response corresponding to each header items.
        [ID, SKU, DESCRIPTION, CLIENT, ENQUIRY STATUS, DISCREPANCY -> [AVAILABLE, ON HOLD], DATE IDENTIFIED, ACTIONS].
        3. Multi-level Filters to -> [SKU, DESCRIPTION, DATE IDENTIFIED].
        4. Use <FilterCheckDropdown /> for check box filters
    */

    const dispatch = useDispatch();
    const {
        clients,
        warehouses,
        currentPage,
        perPageCount,
        totalPages,
        discrepancies,
        totalItems,
        isLoading,
        newDsPresent,
        newNotification,
        filterValuesDiscrepancy,
    } = useSelector((state: RootState) => state.inventoryList);
    const [searchTerm, setSearchTerm] = useState('');
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [logModal, setlogModal] = useState({ status: false, id: '' });
    const [filter, setFilter] = useState<any>({ clientName: 'All' });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    const [date, setDate] = useState(new Date());
    const { profileType } = useUserProfile();
    const firstUpdate = useRef(true);
    const firstClientUpdate = useRef(true);
    const organisationData = getOrganisationData();

    const urlParams = new URLSearchParams(window.location.search);
    const sku = urlParams.get('sku');

    useEffect(() => {
        if (sku) {
            setSearchTerm(sku);
        }
    }, [sku]);

    //===================== ref to store filter value ===================//
    const filterRef = React.useRef({
        sku: [],
        description: [],
        dateIdentified: [],
    } as any);

    //===================================================================//

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        data: { ...organisationData, ...filter },
        profileType,
        profile: profileType,
    };

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (searchTerm?.trim()?.length > 2) {
            setFilter({ ...filter, skuSearch: searchTerm });
        } else if (searchTerm?.trim()?.length === 0) {
            if (filter?.skuSearch) {
                const filterObj = JSON.parse(JSON.stringify(filter));
                delete filterObj.skuSearch;
                setFilter(filterObj);
            }
        }
    }, [searchTerm]);
    useEffect(() => {
        if (firstClientUpdate.current) {
            firstClientUpdate.current = false;
            return;
        }
        if (filter?.clientName !== 'All') {
            const clientCode = (clients?.find((client: any) => client.name === filter.clientName) as any)?.clientCode;
            dispatch(fetchWareHouseData({ clientCode: clientCode, data: organisationData, profileType }));
        }
        if (filter?.clientName === 'All') {
            dispatch(fetchClientAndWarehouseData(payload));
        }
    }, [filter?.clientName]);

    useEffect(() => {
        dispatch(fetchInventoryDataDiscrepancy(payload));
        setDate(new Date());
    }, [dispatch, currentPage, perPageCount, filter]);

    useEffect(() => {
        setTimeout(() => {
            dispatch(viewedDiscrepancy(payload));
        }, 6000);
    }, []);

    useEffect(() => {
        dispatch(fetchClientAndWarehouseData(payload));
    }, []);

    useEffect(() => {
        if (warehouses?.length) {
            const filterValues = JSON.parse(JSON.stringify(filter));
            setFilter({ ...filterValues, blpName: (warehouses as any[])[0]?.blpName });
        }
    }, [warehouses]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
    }, [totalPages]);

    useEffect(() => {
        let flag = false;
        discrepancies?.forEach((element: any) => {
            if (element?.logAdded) {
                flag = true;
            }
        });
        if (flag) {
            setlogAvailable(true);
        }
    }, [discrepancies]);
    const onSearchClose = () => {
        setSearchTerm('');
        if (filter?.skuSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.skuSearch;
            setFilter(filterObj);
        }
    };
    const filterValues = {
        sku: filterRef.current?.sku,
        description: filterRef.current?.description,
        dateIdentified: filterRef.current?.dateIdentified,
    };

    const resetAndFetchData = () => {
        setFilter({ clientName: 'All' });
        dispatch(fetchClientAndWarehouseData(payload));
        setSearchTerm('');
    };

    //=== call back to update filter ref and thereby action dispatch ===//
    const handleChangeInputCallback = (value: any, type: any) => {
        if (type) {
            filterRef.current[type] = value;
            const filter = {
                sku: filterRef.current?.sku,
                description: filterRef.current?.description,
                dateIdentified: filterRef.current?.dateIdentified,
            };
            const payload = {
                currentPage: currentPage,
                perPageCount: perPageCount,
                data: { ...organisationData, ...filter },
                profileType,
                profile: profileType,
            };

            dispatch(fetchInventoryDataDiscrepancy(payload));
        }
    };
    //=====================================================================//

    return (
        <>
            <div className="search-dropdown-btn-fields-wrapper">
                <div className="search-field-purchase-grid">
                    <div className="search-entry-holder">
                        <input
                            placeholder="Search by SKU"
                            type="text"
                            className="search-input input-text"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                        ></input>
                        <div
                            className={`search-toolip ${
                                searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                            }`}
                        >
                            Enter atleast 3 characters to search
                        </div>
                        <svg className="svg-icon search-icon">
                            <use href="#searchIcon"></use>
                        </svg>
                        <button className="app-btn text-close-btn" onClick={() => onSearchClose()}>
                            <svg className="svg-icon text-close-icon">
                                <use href="#closeIcon"></use>
                            </svg>
                        </button>
                    </div>
                    <div
                        className={`dropdown-wrap ${
                            clientDropDownOpen ? 'dropdown-open' : ''
                        } download-dropdown search-toast-content-holder`}
                    >
                        <button
                            className="app-btn app-btn-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setClientDropDownOpen(!clientDropDownOpen);
                            }}
                        >
                            <div className="purchase-dropdown-content">
                                <span className="button-text">Client :</span>
                                <div className="content-heading-holder">
                                    <span className="content-heading">{filter?.clientName}</span>
                                </div>
                            </div>
                            <svg className="svg-icon arrow-icon">
                                <use href="#downArrow">
                                    <title>Expand row</title>
                                </use>
                            </svg>
                        </button>
                        <div className="dropdown-menu">
                            <>
                                {clients?.length === 1 ? null : (
                                    <a
                                        className={`menu-item app-btn ${filter?.clientName === 'All' ? 'active' : ''}`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setClientDropDownOpen(false);
                                            setFilter({ ...filter, clientName: 'All' });
                                        }}
                                    >
                                        <div className="content-heading-holder">
                                            <span className="content-heading">All</span>
                                        </div>
                                    </a>
                                )}
                                {clients?.map((item: any, ind: number) => (
                                    <a
                                        key={ind}
                                        className={`menu-item app-btn ${
                                            filter?.clientName === item.name ? 'active' : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setClientDropDownOpen(false);
                                            setFilter({ ...filter, clientName: item.name });
                                        }}
                                    >
                                        <div className="content-heading-holder">
                                            <span className="content-heading">{item.name}</span>
                                        </div>
                                    </a>
                                ))}
                            </>
                        </div>
                    </div>
                    <div
                        className={`dropdown-wrap warehouse-dropdown ${
                            warehouseDropDownOpen ? 'dropdown-open' : ''
                        } download-dropdown search-toast-content-holder`}
                    >
                        <button
                            className="app-btn app-btn-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setWarehouseDropDownOpen(!warehouseDropDownOpen);
                            }}
                        >
                            <div className="purchase-dropdown-content">
                                <span className="button-text">Warehouse : </span>
                                <div className="content-heading-holder">
                                    <span className="content-heading">{filter?.blpName}</span>
                                </div>
                            </div>
                            <svg className="svg-icon arrow-icon">
                                <use href="#downArrow">
                                    <title>Expand row</title>
                                </use>
                            </svg>
                        </button>
                        <div className="dropdown-menu">
                            <>
                                {warehouses?.map((item: any, ind: number) => (
                                    <a
                                        key={ind}
                                        className={`menu-item app-btn ${
                                            filter?.blpName === item.blpName ? 'active' : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setWarehouseDropDownOpen(false);
                                            setFilter({ ...filter, blpName: item.blpName });
                                        }}
                                    >
                                        <div className="content-heading-holder">
                                            <span className="content-heading">{item.blpName}</span>
                                        </div>
                                    </a>
                                ))}
                            </>
                            {!warehouses?.length && !isLoading ? (
                                <div className="no-data-content">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No Warehouses Found</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="add-btn-purchase">
                    <button
                        className="action-btn app-btn app-btn-secondary reset-btn"
                        onClick={() => resetAndFetchData()}
                        title="Refresh"
                    >
                        <svg className="svg-icon reset-icon">
                            <use xlinkHref="#resetIcon">
                                <title>Split</title>
                            </use>
                        </svg>
                    </button>
                    <ExportButton
                        organisationData={{ ...organisationData, ...filterValues, ...filter }}
                        disabled={(discrepancies && discrepancies?.length === 0) || !discrepancies ? true : false}
                        showExportDropDown={showExportDropDown}
                        setShowExportDropDown={setShowExportDropDown}
                        exportListWithBody={exportInventoryListDiscrepancy}
                    />
                </div>
            </div>
            <div className="tab-nav-grid-holder">
                <div className="tab-nav-holder">
                    <a href="#" className="tab-nav" onClick={() => setcurrentPage('stock')}>
                        Stock
                    </a>
                    <a
                        href="#"
                        className={`tab-nav active ${newNotification || newDsPresent ? 'tab-notification' : ''}`}
                    >
                        Discrepancies
                    </a>
                </div>
                <div className="date-time-field">
                    Last updated on <span className="date-field">{moment(date).format('DD-MM-YYYY, HH:mm')}</span>
                </div>
            </div>

            <div className="grid-section inventory-discrepancies-grid grid-added-booking product-details-wrapper">
                {ModalViewproduct.status && (
                    <ProductDetailModal
                        display={ModalViewproduct}
                        displayFunction={setModalViewproduct}
                        //id={VendorCode}
                        //data={data}
                    />
                )}
                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
                <div className="grid-holder">
                    <div className="grid-header"></div>
                    <div className="grid-container">
                        {(!discrepancies || discrepancies.length === 0) && !searchTerm ? (
                            <div className="grid-no-content ">
                                <svg
                                    className="svg-icon grid-no-content-icon "
                                    style={{ width: '48px', height: '48px', color: '#6DAE63' }}
                                >
                                    <use xlinkHref="#checkmarkIcon"></use>
                                </svg>
                                <p className="no-content-message">
                                    All SKU quantities are synced with WMS without any discrepancies
                                </p>
                            </div>
                        ) : (
                            (!discrepancies || discrepancies.length === 0) && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No records found</p>
                                </div>
                            )
                        )}
                        {((discrepancies && discrepancies.length !== 0) || searchTerm) && (
                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <thead>
                                        <tr className="grid-sticky-row">
                                            <th
                                                className="grid-cell-header grid-sticky-column sticky-left-aligned col-id"
                                                rowSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span className="header-title">ID</span>
                                                </div>
                                            </th>
                                            <th
                                                className="grid-cell-header grid-sticky-column sticky-left-aligned col-sku"
                                                rowSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span className="header-title">SKU</span>
                                                    <FilterCheckboxDropdown
                                                        dataObj={filterValuesDiscrepancy}
                                                        isLoading={isLoading}
                                                        type="sku"
                                                        handleChangeInputCallback={handleChangeInputCallback}
                                                    />
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-description" rowSpan={2}>
                                                <div className="grid-cell-data">
                                                    <span className="header-title">DESCRIPTION</span>
                                                    <FilterCheckboxDropdown
                                                        dataObj={filterValuesDiscrepancy}
                                                        isLoading={isLoading}
                                                        type="description"
                                                        handleChangeInputCallback={handleChangeInputCallback}
                                                    />
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-client" rowSpan={2}>
                                                <div className="grid-cell-data">
                                                    <span className="header-title">CLIENT</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-enquiry" rowSpan={2}>
                                                <div className="grid-cell-data">
                                                    <span className="header-title">ENQUIRY STATUS</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header centre-align col-discrepency " colSpan={2}>
                                                <div className="grid-cell-data">
                                                    <span className="header-title">DISCREPANCY</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-date" rowSpan={2}>
                                                <div className="grid-cell-data">
                                                    <span className="header-title">DATE IDENTIFIED</span>
                                                    <FilterCheckboxDropdown
                                                        dataObj={filterValuesDiscrepancy}
                                                        isLoading={isLoading}
                                                        type="dateIdentified"
                                                        handleChangeInputCallback={handleChangeInputCallback}
                                                        isDate={true}
                                                        right={'2px'}
                                                    />
                                                </div>
                                            </th>
                                            <th
                                                className="grid-cell-header grid-sticky-column sticky-right-aligned col-action"
                                                rowSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span className="header-title">ACTIONS</span>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr className="grid-sticky-row">
                                            <th className="grid-cell-header col-available">
                                                <div className="grid-cell-data">
                                                    <span>AVAILABLE</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-on-hold">
                                                <div className="grid-cell-data">
                                                    <span>ON HOLD</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <>
                                            {discrepancies?.map((res: any) => (
                                                <tr className="has-child row-expanded">
                                                    <td className="grid-cell col-td-id grid-sticky-column sticky-left-aligned no-border">
                                                        <div className="grid-cell-data">{res?.id}</div>
                                                    </td>
                                                    <td className="grid-cell col-id-sku grid-sticky-column sticky-left-aligned">
                                                        <div className="grid-cell-data">
                                                            <a
                                                                className="add-content-link"
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={() =>
                                                                    setModalViewproduct({
                                                                        status: true,
                                                                        value: res?.clientCode,
                                                                        id: res?.skuId,
                                                                    })
                                                                }
                                                            >
                                                                {res?.sku}
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={`grid-cell td-origin ${
                                                            (profileType === 'forwarder' && res?.dsViewedByForwarder) ||
                                                            (profileType === 'client' && res?.dsViewedByClient) ||
                                                            (profileType === 'agent' && res?.dsViewedByAgent)
                                                                ? ''
                                                                : 'notification-active-clr'
                                                        }`}
                                                    >
                                                        <div className="grid-cell-data">{res?.description}</div>
                                                    </td>
                                                    <td
                                                        className={`grid-cell td-origin ${
                                                            (profileType === 'forwarder' && res?.dsViewedByForwarder) ||
                                                            (profileType === 'client' && res?.dsViewedByClient) ||
                                                            (profileType === 'agent' && res?.dsViewedByAgent)
                                                                ? ''
                                                                : 'notification-active-clr'
                                                        }`}
                                                    >
                                                        <div className="grid-cell-data">{res?.clientName}</div>
                                                    </td>
                                                    <td
                                                        className={`grid-cell td-origin ${
                                                            (profileType === 'forwarder' && res?.dsViewedByForwarder) ||
                                                            (profileType === 'client' && res?.dsViewedByClient) ||
                                                            (profileType === 'agent' && res?.dsViewedByAgent)
                                                                ? ''
                                                                : 'notification-active-clr'
                                                        }`}
                                                    >
                                                        <div className="grid-cell-data">
                                                            <div className="allocation-status">
                                                                <div
                                                                    className={`allocated-status ${
                                                                        res?.status === 'Pending_Response'
                                                                            ? 'in-hold'
                                                                            : res?.status === 'Response_Added'
                                                                            ? 'in-progress'
                                                                            : 'draft'
                                                                    }`}
                                                                >
                                                                    {res?.status === 'Pending_Response'
                                                                        ? 'Pending Response'
                                                                        : res?.status === 'Response_Added'
                                                                        ? 'Response Added'
                                                                        : 'Not Raised'}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={`grid-cell td-origin ${
                                                            (profileType === 'forwarder' && res?.dsViewedByForwarder) ||
                                                            (profileType === 'client' && res?.dsViewedByClient) ||
                                                            (profileType === 'agent' && res?.dsViewedByAgent)
                                                                ? ''
                                                                : 'notification-active-clr'
                                                        }`}
                                                    >
                                                        <div className="grid-cell-data">
                                                            <div className="unit-process-wrapper">
                                                                <span className="first-unit">
                                                                    {res?.existingAvailable}
                                                                </span>
                                                                {res?.existingAvailable !== res?.updatedAvailable && (
                                                                    <>
                                                                        {' '}
                                                                        <span className="unit-process-icon">
                                                                            <svg className="svg-icon straight-arrow-pointer-icon">
                                                                                <use href="#straightArrowPointerIcon">
                                                                                    <title>Close</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                        <span className="second-unit">
                                                                            {res?.updatedAvailable
                                                                                ? res?.updatedAvailable
                                                                                : ''}
                                                                        </span>
                                                                        <span
                                                                            className={`third-unit ${
                                                                                res?.updatedAvailable -
                                                                                    res?.existingAvailable >
                                                                                0
                                                                                    ? 'success'
                                                                                    : 'error'
                                                                            }`}
                                                                        >
                                                                            {res?.updatedAvailable -
                                                                                res?.existingAvailable >
                                                                            0
                                                                                ? '+'
                                                                                : ''}
                                                                            {res?.updatedAvailable -
                                                                                res?.existingAvailable}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={`grid-cell td-origin ${
                                                            (profileType === 'forwarder' && res?.dsViewedByForwarder) ||
                                                            (profileType === 'client' && res?.dsViewedByClient) ||
                                                            (profileType === 'agent' && res?.dsViewedByAgent)
                                                                ? ''
                                                                : 'notification-active-clr'
                                                        }`}
                                                    >
                                                        <div className="grid-cell-data">
                                                            <div className="unit-process-wrapper">
                                                                <span className="first-unit">
                                                                    {res?.existingOnHold}
                                                                </span>
                                                                {res?.existingOnHold !== res?.updatedOnHold && (
                                                                    <>
                                                                        <span className="unit-process-icon">
                                                                            <svg className="svg-icon straight-arrow-pointer-icon">
                                                                                <use href="#straightArrowPointerIcon">
                                                                                    <title>Close</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                        <span className="second-unit">
                                                                            {res?.updatedOnHold}
                                                                        </span>
                                                                        <span
                                                                            className={`third-unit ${
                                                                                res?.updatedOnHold -
                                                                                    res?.existingOnHold >
                                                                                0
                                                                                    ? 'success'
                                                                                    : 'error'
                                                                            }`}
                                                                        >
                                                                            {res?.updatedOnHold - res?.existingOnHold >
                                                                            0
                                                                                ? '+'
                                                                                : ''}
                                                                            {res?.updatedOnHold - res?.existingOnHold}
                                                                        </span>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td
                                                        className={`grid-cell td-origin ${
                                                            (profileType === 'forwarder' && res?.dsViewedByForwarder) ||
                                                            (profileType === 'client' && res?.dsViewedByClient) ||
                                                            (profileType === 'agent' && res?.dsViewedByAgent)
                                                                ? ''
                                                                : 'notification-active-clr'
                                                        }`}
                                                    >
                                                        <div className="grid-cell-data">
                                                            {moment(res?.dateIdentified).format('DD-MM-YYYY')}
                                                        </div>
                                                    </td>

                                                    <td
                                                        className={`grid-cell  grid-sticky-column sticky-right-aligned  td-origin ${
                                                            (profileType === 'forwarder' && res?.dsViewedByForwarder) ||
                                                            (profileType === 'client' && res?.dsViewedByClient) ||
                                                            (profileType === 'agent' && res?.dsViewedByAgent)
                                                                ? ''
                                                                : 'notification-active-clr'
                                                        }`}
                                                    >
                                                        <div className="grid-cell-data">
                                                            <button
                                                                className={`action-btn app-btn app-btn-secondary ${
                                                                    (profileType === 'forwarder' &&
                                                                        !res?.newNotificationForForwarder) ||
                                                                    (profileType === 'client' &&
                                                                        !res?.newNotificationForClient) ||
                                                                    (profileType === 'agent' &&
                                                                        !res?.newNotificationForAgent)
                                                                        ? ''
                                                                        : res?.logAdded
                                                                        ? 'icon-notification'
                                                                        : ''
                                                                }`}
                                                                title="Enquiry Log"
                                                                onClick={() =>
                                                                    setlogModal({
                                                                        status: true,
                                                                        id: res?.discrepancyId,
                                                                    })
                                                                }
                                                            >
                                                                <svg className="svg-icon message-note-icon ">
                                                                    <use xlinkHref="#messageNoteIcon"></use>
                                                                    <title>Message</title>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                    <TableFooter
                        fetchData={fetchInventoryData}
                        currentPage={currentPage}
                        stat={payload}
                        displayCountfn={setPageCountDisplay}
                        pageCountDisplay={PageCount}
                        perPageCount={perPageCount}
                        setPageCount={setPageCount}
                        totalItems={totalItems}
                        items={discrepancies}
                        itemsInList={null}
                        totalPages={totalPages - 1}
                    />
                </div>
            </div>
            {logModal.status && <EnquiryLogModal setlogModal={setlogModal} logModal={logModal} payloadDis={payload} />}
        </>
    );
};
export default Index;
