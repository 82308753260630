import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Position, error, success } from 'src/utils/toast';
import QuoteItemSingleDropdownCard from '../QuoteItem/Details/QuoteItemSingleDropdownCard';
import QuoteItemDropdownCard from '../QuoteItem/Details/QuoteItemDropdownCard';
import './quote-item-details.scss';
import { addSpotRateApi, getDefaultCurrency, getProviders } from 'src/services/apis/quoteAPi';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    qeQuoteSegments: any;
    quoteHeader: any;
}
const spotRateInitialData = {
    quotes: [],
    transports: [],
    containers: [],
    providers: [],
};
const spotLoadingState = {
    providers: true,
    currency: true,
};

const SpotRateModal: React.FC<ModalProps> = ({ modalView, setModalView, qeQuoteSegments, quoteHeader }) => {
    const { id } = useParams();
    const [spotRateData, setSpotRateData] = useState<any>(spotRateInitialData);
    const [spotLoading, setSpotLoading] = useState<any>(spotLoadingState);
    const [loading, setLoading] = useState(true);
    const [quoteData, setQuoteData] = useState<any>();
    const [transportData, setTransportData] = useState<any>();
    const [containerData, setContainerData] = useState<any>();
    const [providerData, setProviderData] = useState<any>();
    const [currencyData, setCurrencyData] = useState<any>();
    const [warning, setWarning] = useState(false);

    const filteredItem = (key: string) => {
        return qeQuoteSegments
            ?.map((item: any) => item?.[key])
            .filter((value: any, index: number, self: any) => self.indexOf(value) === index)
            ?.map((res: any) => ({
                label: res,
                value: res,
            }));
    };

    useEffect(() => {
        if (!providerData) {
            getProviders().then((provider) => {
                setSpotLoading((prev: any) => ({ ...prev, providers: false }));
                setProviderData(provider);

                const quotesFiltered = filteredItem('QE_Code');
                setQuoteData(quotesFiltered);

                const transportFiltered = filteredItem('QE_CD_TransportMode');
                setTransportData(transportFiltered);

                const containerFiltered = filteredItem('QE_CD_ContainerMode');
                setContainerData(containerFiltered);

                getDefaultCurrency().then((currency) => {
                    setSpotLoading((prev: any) => ({ ...prev, currency: false }));
                    setCurrencyData(currency);
                });
            });
        }
    }, []);

    const validation = () => {
        return (
            spotRateData?.quotes?.filter((t: any) => {
                return t.value;
            })?.length > 0 &&
            spotRateData?.transports?.filter((t: any) => {
                return t.value;
            })?.length > 0 &&
            spotRateData.containers.filter((t: any) => {
                return t.value;
            })?.length > 0 &&
            spotRateData.providers.filter((t: any) => {
                return t.value;
            })?.length > 0 &&
            spotRateData?.buyPrice &&
            spotRateData?.sellPrice &&
            spotRateData?.currency?.value
        );
    };

    const addQuote = () => {
        try {
            const payload = {
                quoteSection: spotRateData.quotes
                    .filter((t: any) => {
                        return t.value;
                    })
                    .map((t: any) => t.value),
                transportMode: spotRateData.transports
                    .filter((t: any) => {
                        return t.value;
                    })
                    .map((t: any) => t.value),
                containerMode: spotRateData.containers
                    .filter((t: any) => {
                        return t.value;
                    })
                    .map((t: any) => t.value),
                providers: spotRateData.providers
                    .filter((t: any) => {
                        return t.value;
                    })
                    .map((t: any) => t.value),
                buyPrice: spotRateData?.buyPrice,
                sellPrice: spotRateData?.sellPrice,
                defaultCurrency: spotRateData?.currency?.value,
            };
            addSpotRateApi(quoteHeader?.QH_PK, payload).then((data: any) => {
                success('Spot Rate added successfully', Position.TOP_RIGHT);
                setModalView(false);
                $('.k-i-reload').click();
            });
        } catch (e) {
            error('Incorrect Input', Position.TOP_RIGHT);
        }
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal quote-item-detail spot-modal">
                    <div className="modal-content-holder large-popup">
                        <div className="modal-header">
                            <div className="title">Add SPOT Rate</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="card details-box custom">
                                            <div className="row-1">
                                                <QuoteItemDropdownCard
                                                    key={1}
                                                    isRequired={true}
                                                    title={'Quote Section'}
                                                    keyName={'quotes'}
                                                    quoteData={spotRateData}
                                                    setQuoteData={setSpotRateData}
                                                    quoteItemData={quoteData}
                                                    loading={spotLoading}
                                                />
                                            </div>
                                            <div className="row-2">
                                                <QuoteItemDropdownCard
                                                    key={2}
                                                    isRequired={true}
                                                    title={'Transport Mode(s)'}
                                                    keyName={'transports'}
                                                    quoteData={spotRateData}
                                                    setQuoteData={setSpotRateData}
                                                    quoteItemData={transportData}
                                                    loading={spotLoading}
                                                />
                                            </div>
                                            <div className="row-2">
                                                <QuoteItemDropdownCard
                                                    key={3}
                                                    isRequired={true}
                                                    title={'Container Mode(s)'}
                                                    keyName={'containers'}
                                                    quoteData={spotRateData}
                                                    setQuoteData={setSpotRateData}
                                                    quoteItemData={containerData}
                                                    loading={spotLoading}
                                                />
                                            </div>
                                            <div className="row-2">
                                                <QuoteItemDropdownCard
                                                    key={4}
                                                    isRequired={true}
                                                    title={'Providers'}
                                                    keyName={'providers'}
                                                    quoteData={spotRateData}
                                                    setQuoteData={setSpotRateData}
                                                    quoteItemData={providerData}
                                                    loading={spotLoading}
                                                />
                                            </div>
                                            <div className="row-6">
                                                <div className="row-3-first">
                                                    <div className="detail-box-common">
                                                        <h6 className="details-box-heading-2">
                                                            Buy Price <span className="mandatory-field-text">*</span>
                                                        </h6>
                                                        <div className="details-box-detail">
                                                            <div className="details-box-detail-single">
                                                                <input
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        setSpotRateData({
                                                                            ...spotRateData,
                                                                            buyPrice:
                                                                                e.target.value !== ''
                                                                                    ? +e.target.value
                                                                                    : e.target.value,
                                                                        });
                                                                    }}
                                                                    min={0}
                                                                    className="input-text details-box-detail-text-box"
                                                                    value={spotRateData?.buyPrice}
                                                                    placeholder="Enter Buy Price"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-6">
                                                <div className="row-3-first">
                                                    <div className="detail-box-common">
                                                        <h6 className="details-box-heading-2">
                                                            Sell Price <span className="mandatory-field-text">*</span>
                                                        </h6>
                                                        <div className="details-box-detail">
                                                            <div className="details-box-detail-single">
                                                                <input
                                                                    type="number"
                                                                    onChange={(e) => {
                                                                        setSpotRateData({
                                                                            ...spotRateData,
                                                                            sellPrice:
                                                                                e.target.value !== ''
                                                                                    ? +e.target.value
                                                                                    : e.target.value,
                                                                        });
                                                                    }}
                                                                    min={0}
                                                                    className="input-text details-box-detail-text-box"
                                                                    value={spotRateData?.sellPrice}
                                                                    placeholder="Enter Sell Price"
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-6">
                                                <QuoteItemSingleDropdownCard
                                                    isRequired={true}
                                                    key={5}
                                                    title={'Default Currency'}
                                                    keyName={'currency'}
                                                    quoteData={spotRateData}
                                                    setQuoteData={setSpotRateData}
                                                    quoteItemData={currencyData}
                                                    loading={spotLoading}
                                                    menuPlacement="top"
                                                />
                                            </div>
                                            {warning && <div className="error-text">* Error - ANY is not allowed</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Confirm"
                                onClick={() => {
                                    addQuote();
                                }}
                                disabled={!validation()}
                            >
                                <span className="button-text footer-button-text">Add</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default SpotRateModal;
