import { getOrganizationProfileData } from 'src/utils';
import { post, get } from '../../HttpMethods';

export const dashboardActivityFeedListORD = async (payload: any) => {
    const search = payload?.search?.selectedSearchItem;
    // const filteredListOfOrg = search ? todoOrgList(search?.type, search?.code) : listOfUserOrganisations;
    const searchItem = getOrganizationProfileData(search?.type, search?.code);
    try {
        const response = await post(
            `api/ord/activityfeed/list/${payload.profileType}`,
            {
                loggedInUserName: payload.userName,
                ...(payload.search ?? {}),
                ...searchItem,
                // ...filteredListOfOrg,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
export const dashboardActivityFeedListBKG = async (payload: any) => {
    const search = payload?.search?.selectedSearchItem;
    // const filteredListOfOrg = search ? todoOrgList(search?.type, search?.code) : listOfUserOrganisations;
    const searchItem = getOrganizationProfileData(search?.type, search?.code);

    try {
        const response = await post(
            `api/bkg/activityfeed/list/${payload.profileType}`,
            {
                loggedInUserName: payload.userName,
                ...(payload.search ?? {}),
                // ...filteredListOfOrg,
                ...searchItem,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
export const dashboardActivityCountList = async (payload: any) => {
    const search = payload?.search?.selectedSearchItem;
    // const filteredListOfOrg = search ? todoOrgList(search?.type, search?.code) : listOfUserOrganisations;
    const searchItem = getOrganizationProfileData(search?.type, search?.code);
    try {
        const response = await post(
            `api/ord/activityfeed/list/count/${payload.profileType}`,
            {
                loggedInUserName: payload.userName,
                ...(payload.search ?? {}),
                ...searchItem,
                // ...filteredListOfOrg,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
export const dashboardActivityCountListBkg = async (payload: any) => {
    const search = payload?.search?.selectedSearchItem;
    // const filteredListOfOrg = search ? todoOrgList(search?.type, search?.code) : listOfUserOrganisations;
    const searchItem = getOrganizationProfileData(search?.type, search?.code);
    try {
        const response = await post(
            `api/bkg/activityfeed/list/count/${payload.profileType}`,
            {
                loggedInUserName: payload.userName,
                ...(payload.search ?? {}),
                ...searchItem,
                // ...filteredListOfOrg,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const activityFeedSelectedList = async (payload: any) => {
    try {
        const response = await get(`api/ord/activityfeed/settings/${payload.userName}/${payload.profileType}`, null);
        return { data: { success: true, data: response.info } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const activityFeedSettingSave = async (payload: any) => {
    try {
        const response = await post(
            `api/ord/activityfeed/save/settings/${payload.profileType}`,
            { loggedInUserName: payload.userName, ...(payload.selected ?? {}) },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
