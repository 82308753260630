import axios from 'axios';
import fileDownload from 'js-file-download';
import moment from 'moment';
import { del, get, post, put } from '../../HttpMethods';

export const fetchReceiptHeaderListDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/blp/receipts/header/${payload.id}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchShippingTermsApi = async (_payload: any) => {
    try {
        const data = await get('api/ord/blp/receipts/shippingterms', null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const discardReceiptHeaderDetailsApi = async (payload: { receiptId: string }) => {
    try {
        const data = await del(`api/ord/blp/receipts/discard?receiptId=${payload.receiptId}`, null);
        data.type = 'discard';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const cancelReceiptHeaderDetailsApi = async (payload: { receiptId: string }) => {
    try {
        const data = await get(`api/ord/blp/receipts/cancel?receiptId=${payload.receiptId}`, null);
        data.type = 'cancelReceipt';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const closeReceiptDetailsApi = async (payload: { receiptId: string }) => {
    try {
        const data = await put(`api/ord/blp/receipts/close?receiptId=${payload.receiptId}`, null);
        data.type = 'closeReceipt';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateReceiptHeaderDetailsApi = async (payload: any) => {
    const type = payload.type;
    delete payload.type;
    for (const key in payload) {
        if (
            key === 'plannedCustomsClearance' ||
            key === 'plannedGoodsReceivedDate' ||
            key === 'customsInspectionDate' ||
            key === 'qcInspectionDate' ||
            key === 'actualGoodsReadyDate'
        ) {
            payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
        }
    }
    try {
        const data = await post('api/ord/blp/receipts/header', payload, null);
        data.type = type;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateActualReceiptDetailsApi = async (payload: any) => {
    const type = payload.type;
    delete payload.type;
    for (const key in payload) {
        if (
            key === 'actualCustomsClearance' ||
            key === 'actualGoodsReceivedDate' ||
            key === 'actualCustomsInspectionDate'
        ) {
            payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
        }
    }
    try {
        const data = await post('api/ord/blp/receipts/header/actualreceipt', payload, null);
        data.type = type;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const receiveInFullDetailsApi = async (payload: any) => {
    try {
        const data = await post('api/ord/blp/receipts/receivedfull', payload, null);
        data.type = 'receivedInFull';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const updateComplianceCheckDetailsApi = async (payload: any) => {
    try {
        const data = await post(`api/ord/blp/receipts/compliancecheck/${payload.receiptId}`, payload?.formData, null);
        data.type = 'updateCompliance';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const updateReceivedQtyDetailsApi = async (payload: any) => {
    try {
        const data = await post(`api/ord/blp/receipts/edit/${payload.receiptSubPoLineId}`, payload?.formData, null);
        data.type = 'updateReceivedQty';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const downloadPoLinesImagesApi = async (payload: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/ord/blp/receipts/downloadAllImages/${payload?.receiptSubPoLineId}?docType=${payload.type}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                console.log(result);
                fileDownload(result, `${payload.type}.zip`);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
export const getFailedReasonImage = async (payload: any) => {
    try {
        const data = await get(
            `api/ord/blp/receipts/getAllImages/${payload?.receiptSubPoLineId}?docType=${payload.type}`,
            null
        );
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const reopenReceiptApi = async (payload: { receiptId: string }) => {
    try {
        const res = await post(`api/ord/blp/receipts/reopen/${payload.receiptId}`, null, null);
        return res;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            throw error;
        }
    }
};
