import React from 'react';

const Index = () => {
    return (
        <div
            style={{
                border: '1px solid #e3e8ee',
                margin: '16px 40px 7px 40px',
                padding: '40px',
            }}
        >
            <p style={{ fontWeight: 600, fontSize: '2rem' }}>ACCESS DENIED</p>
            <h6>Sorry, but you dont have permissions to access this view.</h6>
        </div>
    );
};

export default Index;
