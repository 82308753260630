import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import KendoGridJquery from 'src/components/KendoGridJquery';
import './agent-list.scss';
import { filterValues, profileCode, trackingInsightsList } from 'src/utils/kendoGrid/insights';
import Trend from './Trend';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    favourite,
    fetchFavouriteList,
    selectFavourite,
    selectTrackingInsightsError,
    selectTrackingInsightsIsError,
    selectTrackingInsightsSuccess,
    clearFailure,
    fetchTrendMultiGraph,
    fetchTrendGraph,
    fetchInsightsTilesList,
    selectTrackingInsightsViewInsight,
} from 'src/store/reducers/tracking/trackingInsightsReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { error, Position, success } from 'src/utils/toast';
import Loader from '../../../components/common/Loading';
export enum TrendDetails {
    Grid = 'dataGrid',
    Trend = 'trend',
    Compare = 'trendComparison',
}
const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const name = id;
    const trackingDetails = trackingInsightsList.filter((res) => res.id === name)?.[0];

    const [trackingTab, setTrackingTab] = useState('');
    const [titleName, setTitleName] = useState(trackingDetails?.title);
    const [graphDetails, setGraphDetails] = useState(TrendDetails.Trend);
    const [bodyPayload, setBodyPayload] = useState<any>(null);
    const [favourites, setFavourites] = useState<any>([]);
    const favouriteData = useAppSelector(selectFavourite);
    const trackingSuccess = useAppSelector(selectTrackingInsightsSuccess);
    const viewInsight = useAppSelector(selectTrackingInsightsViewInsight);
    const trackingError = useAppSelector(selectTrackingInsightsError);
    const isError = useAppSelector(selectTrackingInsightsIsError);

    const { profileType, userName, roleName } = useUserProfile();
    const dispatch = useAppDispatch();
    const setTrackingView = (arg: string) => {
        setTrackingTab(arg);
    };

    const profileTypeToCode = profileCode[profileType];

    const gridItem = JSON.parse(JSON.stringify(trackingDetails));
    gridItem.url = gridItem?.gridUrl?.replace('##PROFILE##', profileTypeToCode);
    gridItem.trendUrl = gridItem?.trendUrl?.replace('##PROFILE##', profileTypeToCode);

    useEffect(() => {
        // setGraphDetails(trackingDetails.detailPage.some((res) => res === TrendDetails.Compare));
        if (trackingDetails.detailPage.some((res) => res === TrendDetails.Compare)) {
            setGraphDetails(TrendDetails.Compare);
        } else {
            setGraphDetails(TrendDetails.Trend);
        }
    }, [trackingInsightsList]);

    useEffect(() => {
        if (userName) {
            setBodyPayload({ loggedInUserName: userName });
        }
    }, [userName]);

    useEffect(() => {
        if (viewInsight === false) {
            navigate('/tracking');
        }
    }, [viewInsight]);

    useEffect(() => {
        dispatch(fetchInsightsTilesList({ userName, roleName, profile: profileType, tileName: name }));
        dispatch(fetchFavouriteList({ userName, profile: profileType }));
        if (trackingDetails.detailPage.some((res) => res === TrendDetails.Compare)) {
            dispatch(
                fetchTrendMultiGraph({
                    url: gridItem.trendUrl,
                    server: trackingDetails.server,
                    key: trackingDetails?.graphKeyToMap,
                    field: trackingDetails?.graphFilters?.length
                        ? trackingDetails?.graphFilters[0]
                        : trackingDetails?.field2,
                    graphType: TrendDetails.Compare,
                })
            );
        } else if (trackingDetails.detailPage.some((res) => res === TrendDetails.Trend)) {
            dispatch(
                fetchTrendGraph({
                    url: gridItem.trendUrl,
                    server: trackingDetails.server,
                    key: trackingDetails?.graphKeyToMap,
                    field: trackingDetails?.graphFilters?.length
                        ? trackingDetails?.graphFilters[0]
                        : trackingDetails?.field2,
                    graphType: TrendDetails.Trend,
                })
            );
        }
        if (trackingDetails.detailPage.some((res) => res === TrendDetails.Grid)) {
            setTrackingTab('DataGrid');
        } else {
            setTrackingTab('Trend');
        }
    }, []);

    useEffect(() => {
        if (trackingTab === 'DataGrid') {
            setTitleName(trackingDetails?.title);
        }
    }, [trackingTab]);

    useEffect(() => {
        if (favouriteData?.favourites) {
            const favouriteDetails = JSON.parse(favouriteData.favourites);
            setFavourites(favouriteDetails);
        }
    }, [favouriteData]);

    const isFavourited = () => {
        return favourites?.some((res: any) => res.id === name);
    };

    const updateFavourite = () => {
        const isFav = isFavourited();
        let favouriteFinalResult = [];
        let mode = '';
        const favouritedList = favourites.filter((res: any) => res.id !== name);
        if (isFav) {
            favouriteFinalResult = [...favouritedList];
            mode = 'remove';
        } else {
            favouriteFinalResult = [
                {
                    title: trackingDetails?.title,
                    tab: trackingDetails?.type.name,
                    id: trackingDetails?.id,
                },
                ...favouritedList,
            ];
            mode = 'add';
        }
        const payload = {
            favourites: JSON.stringify(favouriteFinalResult),
            type: mode,
            userName,
            profile: profileType,
        };
        setFavourites(favouriteFinalResult);
        dispatch(favourite({ ...payload }));
    };

    useEffect(() => {
        if (isError) {
            error(trackingError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (trackingSuccess?.status === 200 || trackingSuccess?.status === 201) {
            let message = '';
            if (trackingSuccess?.type === 'add') {
                message = 'Item has been added to the favourite list';
            } else if (trackingSuccess?.type === 'remove') {
                message = 'Item has been removed from the favourite list';
            }
            success(message, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
    }, [isError, trackingSuccess, trackingError]);

    if (!viewInsight) {
        return <Loader />;
    }

    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-header-content-holder ">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link to="/tracking" className="dropdown-arrow">
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link to="/tracking" className="breadcrumb-item">
                                Insights
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a className="breadcrumb-item">{titleName}</a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    <div className="header-wrapper">
                        <h2 className="page-heading">{titleName}</h2>
                        <button
                            onClick={() => updateFavourite()}
                            className={`action-btn app-btn icon-only sm fav-icon-holder--star ${
                                isFavourited() ? 'selected-fav-icon' : 'unselected-fav-icon'
                            }`}
                        >
                            <svg className="svg-icon star-icon">
                                <use xlinkHref="#starIcon">
                                    <title>Favourites</title>
                                </use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div className="tab-nav-holder">
                    {trackingDetails.detailPage.some((res) => res === TrendDetails.Grid) && (
                        <a
                            // href="#"
                            className={`tab-nav ${trackingTab === 'DataGrid' ? 'active' : ''}`}
                            onClick={() => setTrackingView('DataGrid')}
                        >
                            Data Grid
                        </a>
                    )}
                    {(trackingDetails.detailPage.some((res) => res === TrendDetails.Trend) ||
                        trackingDetails.detailPage.some((res) => res === TrendDetails.Compare)) && (
                        <a
                            // href="#"
                            className={`tab-nav ${trackingTab === 'Trend' ? 'active' : ''}`}
                            onClick={() => setTrackingView('Trend')}
                        >
                            Trend
                        </a>
                    )}
                </div>
            </div>
            <div className="main-content-holder">
                {trackingTab === 'DataGrid' && id && bodyPayload && (
                    <KendoGridJquery
                        gridItem={gridItem}
                        filterValues={filterValues}
                        body={bodyPayload}
                        hideFilter={false}
                        type="tracking-insights"
                    />
                )}

                {trackingTab === 'Trend' && (
                    <Trend
                        graphDetails={graphDetails}
                        gridItem={gridItem}
                        titleName={titleName}
                        setTitleName={setTitleName}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
