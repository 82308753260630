import React from 'react';
import './map-sidebar.scss';
import '../../styles/map-sidepanel-common-style.scss';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {
    // fetchAllMapData,
    saveSingleMapData,
    selectedLocation,
    showSidebar,
    sideBarType,
} from 'src/store/reducers/dashboard/map';
import { BookingTransitSidebar } from '..';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Index: React.FC = () => {
    const { sideBarView, sideBarValue, sideBarData, singleMapData, locationValue, isLoadingSideBar } = useSelector(
        (state: any) => state.map
    );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const headFunction = () => {
        switch (sideBarValue) {
            case 'bookingsIntransitArray':
                return 'Bookings In Transit';
            case 'bookingsAtOriginPort':
                return 'Bookings at Port of Origin';
            case 'bookingsAtDestinationPort':
                return 'Bookings at Port of Destination';
            case 'posAtOriginCountry':
                return 'POs at Origin Country';
            case 'bookingsAtOriginCountry':
                return 'Bookings at Origin Country';
            default:
                break;
        }
    };
    const bpRedirect = (type: string, id: any) => {
        switch (type.toLowerCase()) {
            case 'fcl':
                return navigate(`/booking_list/${id}/POSelection`);
            case 'bcn':
                return navigate(`/bcnBookingList/${id}/POSelection`);
            case 'lcl':
                return navigate(`/lclbooking_list/${id}/POSelection`);
            case 'ao':
                return navigate(`/Aobooking_list/${id}/AoSelection`);
            case 'air':
                return navigate(`/air_booking_list/${id}/POSelection`);
            default:
                return navigate('/');
        }
    };
    const poRedirect = (type: string, id: any) => {
        switch (type.toLowerCase()) {
            case 'sea':
                return navigate(`/purchase_order/${id}/basic_details`);
            case 'air':
                return navigate(`/purchase_order/${id}/basic_details`);
            default:
                return navigate(`/allocation_orders/${id}/basic_details`);
        }
    };
    return (
        // {`map-sidebar ${expandMode ? 'open' : ''}`}
        sideBarValue === 'bookingsIntransitArray' ? (
            <BookingTransitSidebar />
        ) : (
            <div className={`map-sidebar ${sideBarView ? 'open' : ''}`} style={{ zIndex: '10' }}>
                {isLoadingSideBar ? (
                    <div className="graph-holder" style={{ zIndex: '1000', height: '100%' }}>
                        <div className="graph-scaler loading"></div>
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    </div>
                ) : (
                    sideBarValue && (
                        <button
                            className="app-btn arrow-icon-holder"
                            onClick={() => {
                                dispatch(showSidebar(!sideBarView));
                                //dispatch(saveSingleMapData({}));
                            }}
                            title="Collapse Panel"
                        >
                            <svg className="svg-icon dashboard-header-icon">
                                <use xlinkHref="#downArrow">
                                    <title>dropdown</title>
                                </use>
                            </svg>
                        </button>
                    )
                )}
                <div className="side-bar active">
                    <div className="sidebar-header">
                        <button
                            onClick={() => {
                                dispatch(selectedLocation(!locationValue));
                                dispatch(showSidebar(!sideBarView));
                                dispatch(saveSingleMapData(null));
                                dispatch(sideBarType(''));
                            }}
                            className="app-btn text-close-btn"
                            title="Close"
                        >
                            <svg className="svg-icon text-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </button>
                        <h2 className="page-heading">{headFunction()}</h2>
                        <h2 className="sub-heading">
                            {(singleMapData as any)?.countryName
                                ? (singleMapData as any)?.countryName
                                : singleMapData?.originPortName
                                ? singleMapData?.originPortName
                                : singleMapData?.destinationPortName}
                            (
                            {(singleMapData as any)?.countryCode
                                ? (singleMapData as any)?.countryCode
                                : singleMapData?.originPortCode
                                ? singleMapData?.originPortCode
                                : singleMapData?.destinationPortCode}
                            )
                        </h2>
                    </div>
                    <div className="badge-status">
                        {singleMapData?.bookingsCount ? (
                            <div className="count">{singleMapData?.bookingCount} Bookings</div>
                        ) : (
                            ''
                        )}
                        {singleMapData?.poCount ? <div className="count">{singleMapData?.poCount} POs</div> : ''}
                        {singleMapData?.teuCount ? <div className="count">{singleMapData?.teuCount} TEU</div> : ''}
                    </div>
                    <div className="main-content-wrapper">
                        {sideBarData?.map((res: any) => (
                            <div className="detail-content-items">
                                <div className="col-booking-status">
                                    <div className="booking-id">
                                        {sideBarValue.includes('posAtOriginCountry') ? (
                                            <a
                                                onClick={() => poRedirect(res?.shippingMode, res?.poId)}
                                                className="booking-link"
                                            >
                                                {res?.poNumber}
                                            </a>
                                        ) : (
                                            <a
                                                onClick={() => bpRedirect(res?.bpType, res?.bpId)}
                                                className="booking-link"
                                            >
                                                {res?.bpNumber}
                                            </a>
                                        )}
                                    </div>
                                    {sideBarValue.includes('posAtOriginCountry') ? (
                                        <div className="booking-badges">
                                            {!!res?.shippingMode ? (
                                                <div className="count">{res?.shippingMode}</div>
                                            ) : (
                                                ''
                                            )}
                                            {!!res?.skuCount ? (
                                                <div className="count">
                                                    {res?.skuCount} SKU{res?.skuCount > 1 ? 's' : ''}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    ) : (
                                        <div className="booking-badges">
                                            {!!res?.bpType ? <div className="count">{res?.bpType}</div> : ''}
                                            {!!res?.poCount ? (
                                                <div className="count">
                                                    {res?.poCount} PO{res?.poCount > 1 ? 's' : ''}
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            {!!res?.teu ? <div className="count">{res?.teu} TEU</div> : ''}
                                        </div>
                                    )}
                                </div>
                                {!sideBarValue.includes('posAtOriginCountry') && (
                                    <div className="col-destination">
                                        <div className="des-progress-bar">
                                            <div className="destination begin-journey">
                                                <div className="point12">
                                                    <div className="point1" title={res?.originPort?.name}>
                                                        {res?.originPort?.name}
                                                    </div>
                                                </div>
                                                <div className="date-time">
                                                    <div className="destination-name">ATD</div>
                                                    <div className="time-field">
                                                        {res?.atd
                                                            ? moment.utc(res?.atd).local().format('DD-MM-YYYY, HH:mm')
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="destination center-journey">
                                                <span className="center-point">{res?.noOfStops} Stop</span>
                                            </div>
                                            <div className="destination end-journey">
                                                <div className="point12">
                                                    <div className="point1" title={res?.destinationPort.name}>
                                                        {res?.destinationPort.name}
                                                    </div>
                                                </div>
                                                <div className="date-time">
                                                    <div className="time-field">
                                                        {res?.ata
                                                            ? moment.utc(res?.ata).local().format('DD-MM-YYYY, HH:mm')
                                                            : '-'}
                                                    </div>
                                                    <div className="destination-name">ATA</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="col-name-content">
                                    <div className="col-content-detail">
                                        <div className="content-name">Client</div>
                                        <div className="content-detail" style={{ wordBreak: 'break-word' }}>
                                            {res?.client[0]?.name}
                                        </div>
                                    </div>
                                    <div className="col-content-detail">
                                        <div className="content-name">Carrier</div>
                                        <div className="content-detail">{res?.shippingLine?.name}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="sidebar-backdrop"></div>
            </div>
        )
    );
};
export default Index;
