import React from 'react';
import { RootState } from 'src/store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import BSTooltip from 'src/components/common/BSTooltip';
import { makeApiCall } from 'src/pages/Dashboard/Insights/makeApiCall';
import {
    setInsightsMode,
    setInsightsDestination,
    setInsightsForwarded,
    setInsightsSelectedType,
    setInsightsSelectedTypeFilter,
    setInsightsMainPage,
} from 'src/store/reducers/dashboard/insightsReducer';
import { FilterDestinationTypeList, FilterModeList, Type } from 'src/pages/Dashboard/InsightsExpanded/enums';

interface Props {
    tabName: string;
    count: number;
}

//Faheem
const InsightViewHeaderExpanded: React.FC<Props> = (props) => {
    const dispatch = useDispatch();
    const { tabName, count } = props;

    //======================= ReduxValues ===========================//
    const { insightsMode, insightsDestination, insightsDurationRange, insightsSelectedType, insightsMainPage } =
        useSelector((state: RootState) => state.dashboardInsights);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);
    //===============================================================//

    //==================== Determine which api to be called ===================//
    const whichApiToBeCalled = () => {
        let apiDeterminationString = '';

        if (insightsMainPage === 'Active Order Details') {
            apiDeterminationString = 'activeOrdersDetails';
        } else if (insightsMainPage === 'Active Orders' || insightsMainPage === '') {
            apiDeterminationString = 'activeOrder';
        } else if (insightsMainPage === 'Active Bookings Details') {
            apiDeterminationString = 'activeBookingsDetails';
        } else if (insightsMainPage === 'Active Bookings') {
            apiDeterminationString = 'activeBooking';
        } else {
            return '';
        }
        return apiDeterminationString;
    };

    //=========================================================================//

    //==================== mode change function =======================//
    const handleModeChange = (type: string) => {
        let currentMode: string[] = [];
        if (insightsMode.includes(type)) {
            currentMode = insightsMode.filter((item: string) => item !== type);
        } else {
            currentMode = [...insightsMode, type];
        }
        dispatch(setInsightsMode(currentMode));
        makeApiCall(
            dispatch,
            currentMode,
            insightsDurationRange,
            insightsDestination,
            whichApiToBeCalled(),
            insightsSelectedType,
            '',
            selectedSearchItem,
            selectedSearchItem?.type
        );
    };
    //======================= destination Change function =============================//
    const handleDestinationTypeChange = (type: string) => {
        if (insightsDestination?.length === 0) {
            dispatch(setInsightsMode(insightsMode.filter((item: string) => item !== FilterModeList.Land)));
        }
        let currentDestination: string[] = [];
        if (insightsDestination.includes(type)) {
            currentDestination = insightsDestination.filter((item: string) => item !== type);
        } else {
            currentDestination = [...insightsDestination, type];
        }
        dispatch(setInsightsDestination(currentDestination));
        makeApiCall(
            dispatch,
            insightsMode.filter((item: string) => item !== FilterModeList.Land),
            insightsDurationRange,
            currentDestination,
            whichApiToBeCalled(),
            insightsSelectedType,
            '',
            selectedSearchItem,
            selectedSearchItem?.type
        );
    };

    //================================================================================//

    //console.log('insightsSelectedType>>', insightsSelectedType);

    return (
        <React.Fragment>
            {insightsMainPage === 'Active Orders' ||
            insightsMainPage === '' ||
            insightsMainPage === 'Active Bookings' ? (
                <div>
                    <h5 className="header-title">{`All Active ${tabName}`}</h5>
                    <span className="order-count">{count}</span>
                </div>
            ) : (
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <a
                            className="dropdown-arrow"
                            onClick={() => {
                                if (insightsMainPage === 'Active Order Details') {
                                    dispatch(setInsightsMainPage('Active Orders'));
                                } else if (insightsMainPage === 'Active Bookings Details') {
                                    dispatch(setInsightsMainPage('Active Bookings'));
                                }
                                dispatch(setInsightsForwarded(''));
                            }}
                        >
                            <svg className="arrow-icon">
                                <use href="#downArrow">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </a>
                        <li className="breadcrumb-list-item">
                            <a
                                href="#"
                                className="breadcrumb-item"
                                onClick={() => {
                                    if (insightsMainPage === 'Active Order Details') {
                                        dispatch(setInsightsMainPage('Active Orders'));
                                    } else if (insightsMainPage === 'Active Bookings Details') {
                                        dispatch(setInsightsMainPage('Active Bookings'));
                                    }
                                    dispatch(setInsightsForwarded(''));
                                    dispatch(setInsightsSelectedType(''));
                                    dispatch(setInsightsSelectedTypeFilter(''));
                                }}
                            >
                                Active {tabName}
                            </a>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a className="breadcrumb-item" href="#">
                                {tabName === 'Orders'
                                    ? insightsSelectedType === Type.itpo
                                        ? 'International POs'
                                        : insightsSelectedType === Type.ibpo
                                        ? 'Inbound POs'
                                        : insightsSelectedType === Type.ao
                                        ? 'Allocation Orders'
                                        : ''
                                    : insightsSelectedType === Type.ao
                                    ? 'AO Bookings'
                                    : insightsSelectedType === Type.po
                                    ? 'PO Bookings'
                                    : ''}
                            </a>
                        </li>
                    </ul>
                </div>
            )}
            <div className="other-actions">
                <div className="other-actions-set">
                    {insightsSelectedType === Type.ibpo ||
                    (tabName === 'Bookings' && insightsSelectedType === Type.ao) ? (
                        <></>
                    ) : (
                        <span className="action-title">Mode</span>
                    )}
                    <div className="actions-group">
                        {tabName === 'Orders' ? (
                            <React.Fragment>
                                {insightsMainPage === 'Active Order Details' && insightsSelectedType === Type.ibpo ? (
                                    <></>
                                ) : (
                                    <BSTooltip id="mode1" title="Sea" position="bottom">
                                        <button
                                            className={`action-btn app-btn icon-only light-border ${
                                                insightsMode?.includes(FilterModeList.Sea) ? 'active' : ''
                                            }`}
                                            onClick={() => {
                                                handleModeChange(FilterModeList.Sea);
                                            }}
                                        >
                                            <svg className="svg-icon ship-icon">
                                                <use href="#bySeaIcon">
                                                    <title>{FilterModeList.Sea}</title>
                                                </use>
                                            </svg>
                                        </button>
                                    </BSTooltip>
                                )}
                            </React.Fragment>
                        ) : insightsSelectedType !== Type.ao ? (
                            <React.Fragment>
                                <React.Fragment>
                                    <BSTooltip id="mode2" title="FCL" position="bottom">
                                        <button
                                            className={`action-btn app-btn icon-only ${
                                                insightsMode?.includes(FilterModeList.Fcl) ? '' : 'light-border'
                                            }`}
                                            onClick={() => handleModeChange(FilterModeList.Fcl)}
                                        >
                                            <span className="btn-text">FCL</span>
                                        </button>
                                    </BSTooltip>
                                </React.Fragment>
                                <React.Fragment>
                                    <BSTooltip id="mode3" title="LCL" position="bottom">
                                        <button
                                            className={`action-btn app-btn icon-only ${
                                                insightsMode?.includes(FilterModeList.Lcl) ? '' : 'light-border'
                                            }`}
                                            onClick={() => handleModeChange(FilterModeList.Lcl)}
                                        >
                                            <span className="btn-text">LCL</span>
                                        </button>
                                    </BSTooltip>
                                </React.Fragment>
                                <React.Fragment>
                                    <BSTooltip id="mode6" title="BCN" position="bottom">
                                        <button
                                            className={`action-btn app-btn icon-only ${
                                                insightsMode?.includes(FilterModeList.Bcn) ? '' : 'light-border'
                                            }`}
                                            onClick={() => handleModeChange(FilterModeList.Bcn)}
                                        >
                                            <span className="btn-text">BCN</span>
                                        </button>
                                    </BSTooltip>
                                </React.Fragment>
                            </React.Fragment>
                        ) : (
                            <></>
                        )}
                        {(insightsMainPage === 'Active Order Details' &&
                            tabName === 'Orders' &&
                            (insightsSelectedType === Type.ao || insightsSelectedType === Type.ibpo)) ||
                        (insightsMainPage === 'Active Bookings Details' &&
                            tabName === 'Bookings' &&
                            insightsSelectedType === Type.ao) ? (
                            <></>
                        ) : (
                            <BSTooltip id="mode4" title="Air" position="bottom">
                                <button
                                    className={`action-btn app-btn icon-only light-border ${
                                        insightsMode?.includes(FilterModeList.Air) ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        handleModeChange(FilterModeList.Air);
                                    }}
                                >
                                    <svg className="svg-icon aeroplane-icon">
                                        <use xlinkHref="#byAirIcon">
                                            <title>{FilterModeList.Air}</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        )}
                        {(insightsMainPage === 'Active Order Details' &&
                            (insightsSelectedType === Type.ao ||
                                insightsSelectedType === Type.itpo ||
                                insightsSelectedType === Type.ibpo)) ||
                        insightsDestination?.length > 0 ||
                        tabName === 'Bookings' ? (
                            <></>
                        ) : (
                            <BSTooltip id="mode5" title="Land" position="bottom">
                                <button
                                    className={`action-btn app-btn icon-only light-border ${
                                        insightsMode?.includes(FilterModeList.Land) ? 'active' : ''
                                    }`}
                                    onClick={() => {
                                        handleModeChange(FilterModeList.Land);
                                    }}
                                >
                                    <svg className="svg-icon setting-icon">
                                        <use xlinkHref="#byLandIcon">
                                            <title>{FilterModeList.Land}</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        )}
                    </div>
                </div>
                {(insightsMode?.length === 1 && insightsMode?.includes(FilterModeList.Land)) ||
                insightsSelectedType === Type.ibpo ? (
                    <></>
                ) : (
                    <div className="other-actions-set">
                        <span className="action-title">Destination Type</span>
                        <div className="actions-group">
                            <BSTooltip id="international" title="International" position="bottom">
                                <button
                                    className={`action-btn app-btn icon-only light-border ${
                                        insightsDestination?.includes(FilterDestinationTypeList.International)
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        handleDestinationTypeChange(FilterDestinationTypeList.International);
                                    }}
                                >
                                    <svg className="svg-icon setting-icon">
                                        <use xlinkHref="#globeIcon">
                                            <title>{FilterDestinationTypeList.International}</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="triangle" title="Triangle" position="bottom">
                                <button
                                    className={`action-btn app-btn icon-only light-border ${
                                        insightsDestination?.includes(FilterDestinationTypeList.Triangle)
                                            ? 'active'
                                            : ''
                                    }`}
                                    onClick={() => {
                                        handleDestinationTypeChange(FilterDestinationTypeList.Triangle);
                                    }}
                                >
                                    <svg className="svg-icon setting-icon">
                                        <use xlinkHref="#triangleIcon">
                                            <title>{FilterDestinationTypeList.Triangle}</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default InsightViewHeaderExpanded;
