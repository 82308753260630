import React from 'react';
import BSTooltip from '../BSTooltip';

interface ShowMandatoryProps {
    showMandatoryFieldsIcon: boolean;
    name: string;
    showAsterisk?: boolean;
    viaIntegration: boolean;
}
const Index: React.FC<ShowMandatoryProps> = ({
    showMandatoryFieldsIcon,
    name,
    showAsterisk = true,
    viaIntegration = false,
}) => {
    return viaIntegration ? (
        <h6 className={`details-box-heading ${showMandatoryFieldsIcon ? 'mandatory-text' : ''}`}>
            {showMandatoryFieldsIcon && (
                <BSTooltip
                    id={`view-field-${name?.toLowerCase()?.replaceAll(' ', '-')}`}
                    title="Missing Mandatory Field"
                    classList="tooltip-error-btn"
                    tooltipClassList="mandatory-error-icon"
                >
                    <button className="app-btn mandatory-btn">
                        <svg className="svg-icon error-icon">
                            <use xlinkHref="#errorIcon">
                                <title>Warning</title>
                            </use>
                        </svg>
                    </button>
                </BSTooltip>
            )}
            {name}
            {showAsterisk && <span className="mandatory-field-text">*</span>}
        </h6>
    ) : (
        <h6 className="details-box-heading">
            {name}
            {showAsterisk && <span className="mandatory-field-text">*</span>}
        </h6>
    );
};

export default Index;
