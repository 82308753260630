import { useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { RootState } from '../../store/reducers';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useLocation } from 'react-router-dom';

interface contractDropdownPropsType {
    contract: string;
    setContract: { (carrier: string): void };
    additionalOption?: any;
}

const Index: React.FC<contractDropdownPropsType> = (props) => {
    const location = useLocation();
    const { allContractList } = useSelector((state: RootState) => state.bookingproposalAirschedule);

    const convertDataForSelect = (data: any) => {
        if (Array.isArray(allContractList)) {
            const convertedData = data?.map((option: any) => {
                return { value: option.SC_PK, label: `${option.SC_ContractName} (${option.OR_OrgCode})` };
            });

            if (props.additionalOption) {
                const mergedArray = props.additionalOption.concat(convertedData);
                return mergedArray;
            }

            return convertedData;
        } else {
            return [];
        }
    };

    return (
        <div className="modal-input-holder layout-item" style={{ width: 300 }}>
            <SearchableSelectBox
                data={convertDataForSelect(allContractList)}
                placeholder="Enter Contract Name to Select"
                setStateValue={props.setContract}
                stateValue={props.contract}
                fieldName="contract"
                menuPortalTarget={document.getElementById('outside_div')}
            />
        </div>
    );
};

export default Index;
