import { useState } from 'react';
import { BpEnrichBooking } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import '../../BookingProposal/BookingEnrichmentAdditionalDetail/booking-detail-modal.scss';
import { fetchHouseBillsDataCheck } from 'src/services/apis/bookingProposal/additionalDetailsApi';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    submit: any;
    bookingRefNum: string;
    setBookingRefNum: (value: string | ((prevVar: string) => string)) => void;
    soNumber: string;
    setSoNumber: (value: string | ((prevVar: string) => string)) => void;
    houseBillNo: string;
    setHouseBillNo: (value: string | ((prevVar: string) => string)) => void;
    originCartageCompany: string;
    setOriginCartageCompany: (value: string | ((prevVar: string) => string)) => void;
    isLoading: any;
    data: BpEnrichBooking;
    validationHbl: boolean;
    setValidationHbl: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    submit,
    bookingRefNum,
    setBookingRefNum,
    soNumber,
    setSoNumber,
    houseBillNo,
    setHouseBillNo,
    originCartageCompany,
    setOriginCartageCompany,
    isLoading,
    setCancelModalView,
    data,
    validationHbl,
    setValidationHbl,
}) => {
    const [validation, setValidation] = useState(false);

    const checkSubmitStatus = () => {
        return !soNumber || !houseBillNo || !originCartageCompany;
    };
    const checkCancelStatus = () => {
        if (!data.bookingRefNum && !bookingRefNum && !soNumber && !houseBillNo && !originCartageCompany) {
            return true;
        } else {
            return (
                bookingRefNum === data.bookingRefNum &&
                soNumber === data.soNumber &&
                houseBillNo === data.houseBillNo &&
                originCartageCompany === data.originCartageCompany
            );
        }
    };

    const validateAndSetHouseBillNo = (value: string) => {
        const finalValue = value?.replace(/[^a-zA-Z0-9\s]/g, '');
        setHouseBillNo(finalValue);
        setValidationHbl(false);
    };

    const showError = (arg: any) => (
        <>{validation ? <div className="error-text">{`${arg} cannot be empty`} </div> : null}</>
    );

    const onBlurFn = (e: React.FocusEvent<HTMLInputElement, Element>) => {
        fetchHouseBillsDataCheck(e?.target?.value).then((res: any) => {
            if (res?.toLowerCase() === 'existing') {
                setValidationHbl(true);
            } else {
                setValidationHbl(false);
            }
        });
    };
    return (
        <>
            {modalView ? (
                <div className="app-modal booking-detail-modal">
                    <div className="modal-content-holder medium-popup">
                        <div className="modal-header">
                            <div className="title">Booking Details</div>
                            <button
                                onClick={() => {
                                    if (checkCancelStatus()) {
                                        setModalView(false);
                                    } else {
                                        setCancelModalView(true);
                                    }
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                            <div className="modal-main-content agent-commercial-detail-modal-main-content">
                                <form id="my-form" action="" className="modal-form layout-holder one-column">
                                    <div id="name_div" className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Booking Reference No.<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Booking Reference No."
                                                type="text"
                                                className="modal-input input-text"
                                                id="bookingRefNum"
                                                name="bookingRefNum"
                                                maxLength={50}
                                                value={bookingRefNum}
                                                onChange={(event) => {
                                                    setBookingRefNum(event.target.value);
                                                }}
                                            ></input>
                                        </div>
                                    </div>
                                    <div id="name_div" className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            SO Number<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter SO Number"
                                                type="text"
                                                className="modal-input input-text"
                                                id="soNumber"
                                                name="soNumber"
                                                value={soNumber}
                                                maxLength={50}
                                                onChange={(event) => {
                                                    setSoNumber(event.target.value);
                                                }}
                                            ></input>
                                            {soNumber?.length === 0 ? showError('SO Number') : null}
                                        </div>
                                    </div>
                                    <div id="name_div" className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            House Bill No.<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter House Bill No"
                                                type="text"
                                                className="modal-input input-text"
                                                id="houseBillNo"
                                                name="houseBillNo"
                                                value={houseBillNo}
                                                maxLength={50}
                                                onChange={(event: any) => {
                                                    validateAndSetHouseBillNo(event.target.value);
                                                    // onBlurFn(event);
                                                }}
                                                onBlur={(e: any) => onBlurFn(e)}
                                            ></input>
                                            {validationHbl && (
                                                <div className="error-text">House bill number is already used</div>
                                            )}
                                            {houseBillNo?.length === 0 ? showError('House bill Numbers') : null}
                                        </div>
                                    </div>
                                    <div id="name_div" className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Origin Cartage Company<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Origin Cartage Company"
                                                type="text"
                                                className="modal-input input-text"
                                                id="originCartageCompany"
                                                name="originCartageCompany"
                                                value={originCartageCompany}
                                                maxLength={50}
                                                onChange={(event) => {
                                                    setOriginCartageCompany(event.target.value);
                                                }}
                                            ></input>
                                            {originCartageCompany?.length === 0
                                                ? showError('Origin Cartage Company ')
                                                : null}
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => {
                                    setValidationHbl(false);
                                    if (checkCancelStatus()) {
                                        setValidation(false);
                                        setModalView(false);
                                    } else {
                                        setCancelModalView(true);
                                    }
                                }}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                // disabled={checkSubmitStatus()}
                                onClick={() => {
                                    if (!checkSubmitStatus()) {
                                        setValidation(false);
                                        submit();
                                    } else {
                                        setValidation(true);
                                    }
                                }}
                                title="Save"
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
