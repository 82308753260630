import React from 'react';
import { ShipmentTabProps } from '.';
import { HelmetProvider } from 'react-helmet-async';
import GridLayout from 'src/components/KendoGridJquery/GridLayoutWithLocalData';
import { shipmentGridDetails } from './columns';
import { useTimeout } from 'react-use';

const Packing: React.FC<ShipmentTabProps> = ({ data }) => {
    const [isReady] = useTimeout(100);

    const getData = () => {
        return data?.packingLineCollection?.packingLine?.map((res) => {
            return {
                containerNumber: res?.containerNumber,
                goodsDescription: res?.goodsDescription,
                // poNumber: '', // TODO
                packQty: res?.packQty,
                eaQty: 0, // TODO
                volume: res?.volume,
                weight: res?.weight,
            };
        });
    };
    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-content-holder">
                {isReady() ? (
                    <HelmetProvider>
                        <div className="insight-agent-list-data-trend">
                            <div className="container-fluid shipment-details">
                                <div className="k-kendo-row one-column">
                                    <div className="k-kendo-col-2 custom-kendo-grid">
                                        <div id="grid"></div>
                                    </div>
                                </div>
                            </div>

                            <GridLayout
                                data={getData()}
                                columns={shipmentGridDetails['packing'].columns}
                                fileName={shipmentGridDetails['packing'].fileName}
                                fields={shipmentGridDetails['packing'].fields}
                            />
                        </div>
                    </HelmetProvider>
                ) : null}
            </div>
        </div>
    );
};

export default Packing;
