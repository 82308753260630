import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchShippingDetail,
    fetchPacking,
    fetchPackingSuccess,
    fetchShippingDetailFailed,
    fetchShippingDetailSuccess,
    updateShippingDetail,
    updateShippingDetailFailure,
    updateShippingDetailSuccess,
} from 'src/store/reducers/bookingProposalAir/shippingDetailsReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchShippingDetails,
    fetchPackingApi,
    updateShippingDetails,
} from 'src/services/apis/bookingProposalAir/shippingDetailsApi';

function* getshippingDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(fetchShippingDetails, payload);
        yield put({ type: fetchShippingDetailSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchShippingDetailFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* saveShippingDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateShippingDetails, payload);
        yield put({ type: updateShippingDetailSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateShippingDetailFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* getPacking({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchPackingApi, payload);
        yield put({ type: fetchPackingSuccess, payload: response });
    } catch (error) {
        console.log('Fetch packing error', error);
    }
}

function* containerAllocationSaga() {
    yield takeEvery(fetchShippingDetail, getshippingDetails);
    yield takeEvery(updateShippingDetail, saveShippingDetails);
    yield takeEvery(fetchPacking, getPacking);
}

export default containerAllocationSaga;
