import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'landsailingbp',
    initialState: {
        isLoading: false,
        contractData: [],
        contractSuccess: null,
        sailingCounts: {},
        sailingDataValues: [],
        selectedSailing: {},
        fakData: [],
        isLoading2: false,
        ports: [],
        sailingUpdate: false,
        existingSchedules: [],
        contractCompleted: false,
        fakLoaded: false,
        countFirst: null,
        countSecond: null,
        countThird: null,
        listLoading: true,
        sailingStatusCheck: true,
        changeSelection: false,
        transportSchedule: [] as any,
    },
    reducers: {
        checkContractData: (_state, _action) => {
            // console.log('recieved first');
        },
        checkContractResponse: (state, action) => {
            state.contractData = action.payload?.contractsDtoList;
            const successStatus =
                action.payload?.isContractPresent && action.payload?.contractsDtoList?.length ? true : false;
            state.contractSuccess = successStatus;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        isLoadingFn2: (state, action) => {
            state.isLoading2 = action.payload;
        },
        removePo: (_state, _action) => {
            // console.log(action);
        },
        searchData: (_state, _action) => {
            // console.log(action);
        },
        getSailingCounts: (_state, _action) => {
            // console.log(action);
        },
        saveSailingcount: (state, action) => {
            state.sailingCounts = action.payload;
        },
        getFakData: (_state, _action) => {
            // console.log(action);
        },
        saveFAKdata: (state, action) => {
            state.isLoading = true;
            const newFakData = action.payload?.filter(
                (value: any, index: any, self: any) =>
                    index === self.findIndex((t: any) => t.shippingline === value.shippingline)
            );
            state.fakData = newFakData;
            state.isLoading = false;
        },
        fetchSailingData: (_state, _action) => {
            // console.log(action);
        },
        saveSailingData: (state, action) => {
            state.sailingDataValues = action.payload?.sailingScheduleLists;
        },
        addToBp: (_state, _action) => {
            // console.log(action);
        },
        fetchSelectedSailing: (_state, _action) => {
            // console.log(action);
        },
        viewSelectedSailing: (state, action) => {
            state.selectedSailing = action.payload;
        },
        removeSailing: (_state, _action) => {
            // console.log(action);
        },
        getPortValues: (_state, _action) => {
            // console.log(action);
        },
        savePortValues: (state, action) => {
            state.ports = action.payload;
        },
        sailingUpdated: (state, action) => {
            state.sailingUpdate = action.payload;
        },
        fetchExistingSchedules: (_state, _action) => {
            // console.log(action);
        },
        saveExistingSchedules: (state, action) => {
            state.existingSchedules = action.payload;
        },
        contractLoaded: (state, action) => {
            state.contractCompleted = action.payload;
        },
        fakListCompletedFn: (state, action) => {
            state.fakLoaded = action.payload;
        },
        saveCountValues: (state, action) => {
            state.countFirst = action.payload.firstCount;
            state.countSecond = action.payload.secondCount;
            state.countThird = action.payload.thirdCount;
        },
        listLoadingFn: (state, action) => {
            state.listLoading = action.payload;
        },
        clearsailingBpUpdated: (state, _action) => {
            state.selectedSailing = { status: null, data: {} };
        },
        changeSailingStatus: (state, action) => {
            state.sailingStatusCheck = action.payload;
        },
        removeSailingStatus: (state, action) => {
            state.changeSelection = action.payload;
        },
        saveTransportSchedule: (state, action) => {
            state.transportSchedule = action.payload;
            state.sailingUpdate = true;
        },
        resetStatus: (state, _action) => {
            state.sailingUpdate = false;
        },
        getTransportSchedule: (state, action) => {
            state.transportSchedule = action.payload;
        },
        updateTransportSchedule: (state, action) => {
            state.transportSchedule = action.payload;
        },
    },
});

export const {
    checkContractData,
    isLoadingFn,
    checkContractResponse,
    removePo,
    searchData,
    getSailingCounts,
    saveSailingcount,
    fetchSailingData,
    saveSailingData,
    addToBp,
    fetchSelectedSailing,
    viewSelectedSailing,
    removeSailing,
    getFakData,
    saveFAKdata,
    isLoadingFn2,
    getPortValues,
    savePortValues,
    sailingUpdated,
    fetchExistingSchedules,
    saveExistingSchedules,
    contractLoaded,
    fakListCompletedFn,
    saveCountValues,
    listLoadingFn,
    clearsailingBpUpdated,
    changeSailingStatus,
    removeSailingStatus,
    saveTransportSchedule,
    resetStatus,
    getTransportSchedule,
    updateTransportSchedule,
} = dataSlice.actions;
export default dataSlice.reducer;
