import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

const TransitPointIcon = (className?: string) => {
    const iconMarkup = renderToStaticMarkup(
        <div className="icon-holder bg-yellow">
            <svg className={`svg-icon map-point-icon ${className}`}>
                <use href="#mapPoint">
                    <title>Map point</title>
                </use>
            </svg>
        </div>
    );
    const customMarkerIcon = divIcon({
        html: iconMarkup,
    });
    return customMarkerIcon;
};

export default TransitPointIcon;
