import { createSlice } from '@reduxjs/toolkit';
import {
    vendorBasicDetails,
    parentData,
    manufacturingCategories,
    unLoco,
    addressList,
    integrationData,
    defaultIncoTerms,
    organisationCategories,
    contactLists,
} from '../../services/apis/createVendorApi';

interface VendorDetailsState {
    vendorBasicDetails?: vendorBasicDetails;
    parents: parentData[];
    isLoading: boolean;
    isUnlocoLoading: boolean;
    vendorName: string;
    error: any;
    isError: boolean;
    manufacturingCategories: manufacturingCategories[];
    unLocoList: unLoco[];
    addressList: addressList[];
    contacts?: contactLists;
    integrationData: integrationData[];
    defaultIncoTermsList: defaultIncoTerms[];
    organisationCategoryList: organisationCategories[];
    selectedPk: string;
    createVendorSuccess: boolean;
    createVendorError: boolean;
    editVendorSuccess: boolean;
    editVendorError: boolean;
    addAddressSuccess: boolean;
    addAddressError: boolean;
    editAddressSuccess: boolean;
    editAddressError: boolean;
}

const initialState: VendorDetailsState = {
    // eslint-disable-next-line no-undefined
    vendorBasicDetails: undefined,
    parents: [],
    isLoading: true,
    isUnlocoLoading: false,
    vendorName: '',
    error: {},
    isError: false,
    manufacturingCategories: [],
    unLocoList: [],
    addressList: [],
    // eslint-disable-next-line no-undefined
    contacts: undefined,
    integrationData: [],
    defaultIncoTermsList: [],
    organisationCategoryList: [],
    selectedPk: '',
    createVendorSuccess: false,
    createVendorError: false,
    addAddressSuccess: false,
    addAddressError: false,
    editAddressSuccess: false,
    editAddressError: false,
    editVendorSuccess: false,
    editVendorError: false,
};

export const dataSlice = createSlice({
    name: 'vendorDetails',
    initialState,
    reducers: {
        fetchVendorData: (state, action) => {
            state.isLoading = true;
            state.vendorBasicDetails = action.payload?.vendorBasicDetails;
            state.vendorName = action.payload?.vendorBasicDetails?.vn_name;
            state.isLoading = false;
        },
        fetchManufacturingCategories: (state, action) => {
            state.isLoading = true;
            state.manufacturingCategories = action.payload.manufacturingCategories;
            state.isLoading = false;
        },
        saveVendorAddress: (state, _action) => {
            state.isLoading = true;
        },
        saveVendorAddressSuccess: (state, _action) => {
            state.addAddressSuccess = true;
            state.isLoading = false;
        },
        saveVendorAddressError: (state, action) => {
            state.error = action.payload;
            state.addAddressError = true;
            state.isLoading = false;
        },
        fetchParentList: (state, action) => {
            state.isLoading = true;
            state.parents = action.payload.parents;
            state.isLoading = false;
        },
        saveVendorBasicDetails: (state, _action) => {
            state.isLoading = true;
        },
        saveVendorBasicDetailsSuccess: (state, action) => {
            state.vendorBasicDetails = action.payload;
            state.selectedPk = action.payload?.vn_pk;
            state.createVendorSuccess = true;
            state.isLoading = false;
        },
        saveVendorBasicDetailsError: (state, action) => {
            state.error = action.payload;
            state.createVendorError = true;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        isUnlocoLoadingFn: (state, action) => {
            state.isUnlocoLoading = action.payload;
        },
        searchUnlocoList: (state, action) => {
            state.isLoading = true;
            state.unLocoList = action.payload.unLocoList;
            state.isLoading = false;
        },
        clearUnlocoList: (state) => {
            state.unLocoList = [];
        },
        fetchAddressList: (state, action) => {
            state.isLoading = true;
            state.addressList = action.payload.addressList;
            state.isLoading = false;
        },
        fetchVendorContacts: (state, action) => {
            state.isLoading = true;
            state.contacts = action.payload.contacts;
            state.isLoading = false;
        },
        editVendorAddressData: (state, _action) => {
            state.isLoading = true;
        },
        editVendorAddressDataSuccess: (state, _action) => {
            state.editAddressSuccess = true;
            state.isLoading = false;
        },
        editVendorAddressDataError: (state, action) => {
            state.error = action.payload;
            state.editAddressError = true;
            state.isLoading = false;
        },
        editVendorProfileData: (state, _action) => {
            state.isLoading = true;
        },
        editVendorProfileDataSuccess: (state, _action) => {
            state.editVendorSuccess = true;
            state.isLoading = false;
        },
        editVendorProfileDataError: (state, action) => {
            state.editVendorError = true;
            state.error = action.payload;
            state.isLoading = false;
        },
        fetchIntegrationDetails: (state, action) => {
            state.isLoading = true;
            state.integrationData = action.payload.integrationData;
            state.isLoading = false;
        },
        fetchDefaultIncoTermsDetails: (state, action) => {
            state.isLoading = true;
            state.defaultIncoTermsList = action.payload.defaultIncoTermsList;
            state.isLoading = false;
        },
        fetchOrganisationCategoryDetails: (state, action) => {
            state.isLoading = true;
            state.organisationCategoryList = action.payload.organisationCategoryList;
            state.isLoading = false;
        },
        setSelectedPK: (state, action) => {
            state.selectedPk = action.payload;
        },
        resetProfileSuccessFlag: (state, action) => {
            state.createVendorSuccess = action.payload;
            state.editVendorSuccess = action.payload;
        },
        resetAddressSuccessFlag: (state, action) => {
            state.addAddressSuccess = action.payload;
            state.editAddressSuccess = action.payload;
        },
        resetProfileError: (state, action) => {
            state.createVendorError = action.payload;
            state.editVendorError = action.payload;
            state.error = {};
        },
        resetAddressError: (state, action) => {
            state.addAddressError = action.payload;
            state.editAddressError = action.payload;
            state.error = {};
        },
        resetVendorBasicDetails: (state, action) => {
            state.vendorBasicDetails = action.payload;
            state.addressList = [];
        },
    },
});

export const {
    fetchVendorData,
    saveVendorAddress,
    fetchParentList,
    saveVendorBasicDetails,
    isLoadingFn,
    isUnlocoLoadingFn,
    fetchManufacturingCategories,
    searchUnlocoList,
    clearUnlocoList,
    fetchAddressList,
    fetchVendorContacts,
    editVendorAddressData,
    editVendorProfileData,
    fetchIntegrationDetails,
    fetchDefaultIncoTermsDetails,
    fetchOrganisationCategoryDetails,
    setSelectedPK,
    saveVendorBasicDetailsSuccess,
    saveVendorBasicDetailsError,
    resetProfileSuccessFlag,
    resetProfileError,
    editVendorProfileDataSuccess,
    editVendorProfileDataError,
    editVendorAddressDataSuccess,
    editVendorAddressDataError,
    resetAddressError,
    saveVendorAddressError,
    saveVendorAddressSuccess,
    resetAddressSuccessFlag,
    resetVendorBasicDetails,
} = dataSlice.actions;
export default dataSlice.reducer;
