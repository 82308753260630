import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchShipmentDetailsList,
    fetchShipmentDetailsListSuccess,
    fetchShipmentDetailsListFailure,
} from '../reducers/shipmentDetailsReducer';
import { fetchShipmentDetailsApi } from '../../services/apis/shipmentDetailsApi';

function* getShipmentDetailsDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchShipmentDetailsApi, payload);
        yield put({ type: fetchShipmentDetailsListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchShipmentDetailsListFailure, payload: error });
    }
}

function* shipmentDetailsSaga() {
    yield takeEvery(fetchShipmentDetailsList, getShipmentDetailsDetails);
}

export default shipmentDetailsSaga;
