import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchAvailablePoData,
    isLoadingFn,
    addOrSavePoData,
    getBpData,
    savePoDataAll,
    searchAvailablePoDataSuccess,
} from '../../reducers/bookingProposalLcl/availablePoReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    getAvailablePosLcl,
    addSelectedPos,
    getBpDetails,
    getPoData,
    searchAvailablePosLcl,
} from '../../../services/apis/bookingProposalLcl/availablePosApi';

function* getAvailablePOListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(getAvailablePosLcl, payload);
        payload['response'] = response;
        yield put({ type: fetchAvailablePoData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addSelectedAvailablePos({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const data: AxiosResponse<any[]> = yield call(addSelectedPos, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: addOrSavePoData, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getBookingProposalData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any[]> = yield call(getBpDetails, payload);
        payload['success'] = true;
        payload['data'] = data;
        yield put({ type: getBpData, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getAllPoData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any[]> = yield call(getPoData, payload);
        yield put({ type: savePoDataAll, payload: data });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* searchAvailablePOListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(searchAvailablePosLcl, payload);
        payload['response'] = response;
        yield put({ type: searchAvailablePoDataSuccess, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* lclAvailablePoListSaga() {
    yield takeEvery('availablePosLcl/fetchAvailablePoData', getAvailablePOListData);
    yield takeEvery('availablePosLcl/getBpData', getBookingProposalData);
    yield takeEvery('availablePosLcl/poDataFetch', getAllPoData);
    yield takeEvery('availablePosLcl/addOrSavePoData', addSelectedAvailablePos);
    yield takeEvery('availablePosLcl/searchAvailablePoData', searchAvailablePOListData);
}

export default lclAvailablePoListSaga;
