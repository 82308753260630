import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    login,
    loginSuccess,
    loginFailed,
    logout,
    logoutSuccess,
    logoutFailed,
    getNotification,
    getNotificationSuccess,
    getNotificationFailed,
    markSingleRead,
    markSingleReadSuccess,
    markSingleReadFailed,
    markAllRead,
    markAllReadSuccess,
    markAllReadFailed,
} from '../reducers/authReducer';

import {
    checkUser,
    logoutUserApi,
    getUserNotificationApi,
    markAllAsRead,
    markSingleAsRead,
    updateProfileAPI,
} from '../../services/apis/authApi';
import { savePermissions } from '../reducers/permissionReducer';
import { profileChangeUpdation } from '../reducers/rolesReducer';

// eslint-disable-next-line
function* checkUserStatus({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(checkUser, payload);
        yield put({ type: loginSuccess, payload: response });
        yield put({ type: savePermissions, payload: response });
    } catch (error) {
        yield put({ type: loginFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* logoutUser({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(logoutUserApi, payload);
        yield put({ type: logoutSuccess, payload: response });
    } catch (error) {
        yield put({ type: logoutFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* getUserNotification({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(getUserNotificationApi, payload);
        yield put({ type: getNotificationSuccess, payload: response });
    } catch (error) {
        yield put({ type: getNotificationFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* updateSingleNotification({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(markSingleAsRead, payload);
        yield put({ type: markSingleReadSuccess, payload: response });
    } catch (error) {
        yield put({ type: markSingleReadFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* updateAllNotification({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(markAllAsRead, payload);
        yield put({ type: markAllReadSuccess, payload: response });
    } catch (error) {
        yield put({ type: markAllReadFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* updateUserRoleNewAPI({ payload }: { payload: any; type: any }) {
    try {
        yield call(updateProfileAPI, payload);
    } catch (er) {
        console.log(er);
    }
}

function* authSaga() {
    yield takeEvery(login, checkUserStatus);
    yield takeEvery(logout, logoutUser);
    yield takeEvery(getNotification, getUserNotification);
    yield takeEvery(markSingleRead, updateSingleNotification);
    yield takeEvery(markAllRead, updateAllNotification);
    yield takeEvery(profileChangeUpdation, updateUserRoleNewAPI);
}

export default authSaga;
