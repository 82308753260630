import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { fetchConfirmedLinesData, submitContainerToForwarder } from 'src/services/apis/purchaseOrder/cofirmationPoApi';
import {
    addAdditionalDetails,
    addRequiredDetails,
    confirmSubmit,
    getagents,
    getBasicDetails,
    getBuyers,
    getCurrency,
    getdestinationaddress,
    getFclService,
    getIncoterm,
    getOrderType,
    getpaymentterms,
    // getPorts,
    getShippingmode,
    getBlpAgents,
    getReleaseType,
    getDestAgentsApi,
} from '../../../services/apis/purchase_order/basicDetails';
import {
    adSuccess,
    getAgentLoadFn,
    reqSuccess,
    saveAddress,
    saveAgentData,
    saveAllData,
    saveBasicDetails,
    saveBuyer,
    saveConfirmedLines,
    saveDestAgentData,
    submitSuccess,
    submitToSuccess,
} from '../../reducers/purchase_order/basicDetails';
import { isLoadingfn } from '../../reducers/vendorReducer';
// import { showLoading, hideLoading } from 'react-redux-loading-bar';

// eslint-disable-next-line

function* fetchAlldata({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        const orderType: AxiosResponse<any[]> = yield call(getOrderType);
        const shippingmode: AxiosResponse<any[]> = yield call(getShippingmode);
        const fclservicetype: AxiosResponse<any[]> = yield call(getFclService);
        // const freightterms: AxiosResponse<any[]> = yield call(getFrieghtTerm);
        // const portlist: AxiosResponse<any[]> = yield call(getPorts, { portType: payload?.portType });
        const incoterm: AxiosResponse<any[]> = yield call(getIncoterm);
        const releaseType: AxiosResponse<any[]> = yield call(getReleaseType);

        const paymentterms: AxiosResponse<any[]> = yield call(getpaymentterms);
        const currency: AxiosResponse<any[]> = yield call(getCurrency);
        //const address: AxiosResponse<any[]> = yield call(getdestinationaddress, payload.orgCode);

        //const buyers: AxiosResponse<any[]> = yield call(getBuyers);

        payload['orderType'] = orderType;
        payload['shippingmode'] = shippingmode;
        payload['fclservicetype'] = fclservicetype;
        // payload['freightterms'] = freightterms;
        // payload['portlist'] = portlist;
        payload['incoterm'] = incoterm;
        payload['releaseType'] = releaseType;
        payload['paymentterms'] = paymentterms;
        //payload['destinationAddress'] = address;
        payload['currency'] = currency;

        yield put({ type: saveAllData, payload: payload });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}
function* fetchAlldataBlp({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        // const orderType: AxiosResponse<any[]> = yield call(getOrderType);
        // const shippingmode: AxiosResponse<any[]> = yield call(getShippingmode);
        // const fclservicetype: AxiosResponse<any[]> = yield call(getFclService);
        // const freightterms: AxiosResponse<any[]> = yield call(getFrieghtTerm);
        // const portlist: AxiosResponse<any[]> = yield call(getPorts);
        const incoterm: AxiosResponse<any[]> = yield call(getIncoterm);
        const releaseType: AxiosResponse<any[]> = yield call(getReleaseType);
        // const paymentterms: AxiosResponse<any[]> = yield call(getpaymentterms);
        const currency: AxiosResponse<any[]> = yield call(getCurrency);
        const blp: AxiosResponse<any[]> = yield call(getBlpAgents);
        if (payload.orgCode) {
            const address: AxiosResponse<any[]> = yield call(getdestinationaddress, payload.orgCode);
            payload['destinationAddress'] = address;
        } else {
            payload['destinationAddress'] = [];
        }

        //const buyers: AxiosResponse<any[]> = yield call(getBuyers);

        // payload['orderType'] = orderType;
        // payload['shippingmode'] = shippingmode;
        // payload['fclservicetype'] = fclservicetype;
        // payload['freightterms'] = freightterms;
        // payload['portlist'] = portlist;
        payload['incoterm'] = incoterm;
        payload['releaseType'] = releaseType;
        // payload['paymentterms'] = paymentterms;
        // payload['destinationAddress'] = address;
        payload['currency'] = currency;
        payload['blp'] = blp;

        yield put({ type: saveAllData, payload: payload });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}
function* getAddress({ payload }: { payload: any; type: any }) {
    try {
        const agents: AxiosResponse<any[]> = yield call(getdestinationaddress, payload.orgCode);
        yield put({ type: saveAddress, payload: agents });
    } finally {
        // console.log('first');
    }
}
function* getBuyer({ payload }: { payload: any; type: any }) {
    try {
        const agents: AxiosResponse<any[]> = yield call(getBuyers, payload.orgCode);
        yield put({ type: saveBuyer, payload: agents });
    } finally {
        // console.log('first');
    }
}
function* getAgents({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: getAgentLoadFn, payload: true });
        const agents: AxiosResponse<any[]> = yield call(getagents, payload);
        yield put({ type: saveAgentData, payload: agents });
    } finally {
        yield put({ type: getAgentLoadFn, payload: false });
        // console.log('first');
    }
}

function* getDestAgents({ payload }: { payload: any; type: any }) {
    try {
        const agents: AxiosResponse<any[]> = yield call(getDestAgentsApi, payload);
        yield put({ type: saveDestAgentData, payload: agents });
    } catch (err) {
        console.log('err', err);
    }
}

function* createPo({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield call(addRequiredDetails, payload.payload);
        const response: AxiosResponse = yield call(getBasicDetails, payload.id);
        yield put({ type: saveBasicDetails, payload: response });
        yield put({ type: reqSuccess, payload: true });
        //yield put({ type: saveAgentData, payload: agents });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}
function* addAdditional({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield call(addAdditionalDetails, payload.payload);
        const response: AxiosResponse = yield call(getBasicDetails, payload.id);
        yield put({ type: saveBasicDetails, payload: response });
        yield put({ type: adSuccess, payload: true });
        //yield put({ type: saveAgentData, payload: agents });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        // console.log('first');
    }
}

function* fetchBasic({ payload }: { payload: any; type: any }) {
    try {
        //yield put({ type: isLoadingfn, payload: true });
        const response: AxiosResponse = yield call(getBasicDetails, payload);
        yield put({ type: saveBasicDetails, payload: response });
    } catch (error) {
        //yield put({ type: isLoadingfn, payload: false });
    } finally {
        //yield put({ type: isLoadingfn, payload: false });
    }
}

function* fetchConfirmedLinesValues({ payload }: { payload: any; type: any }) {
    try {
        //yield put({ type: isLoadingfn, payload: true });
        const response: AxiosResponse = yield call(fetchConfirmedLinesData, payload);
        yield put({ type: saveConfirmedLines, payload: response });
    } catch (error) {
        //yield put({ type: isLoadingfn, payload: false });
    } finally {
        //yield put({ type: isLoadingfn, payload: false });
    }
}

function* submitContainerForwarder({ payload }: { payload: any; type: any }) {
    try {
        yield call(submitContainerToForwarder, payload);
        yield put({ type: submitToSuccess, payload: { status: true, type: 'Forwarder' } });
    } catch (error) {
        //yield put({ type: isLoadingfn, payload: false });
    } finally {
        //yield put({ type: isLoadingfn, payload: false });
    }
}

function* submitPO({ payload }: { payload: any; type: any }) {
    try {
        //yield put({ type: isLoadingfn, payload: true });
        yield call(confirmSubmit, payload);
        yield put({ type: submitSuccess, payload: true });
    } catch (error) {
        //yield put({ type: isLoadingfn, payload: false });
    } finally {
        //yield put({ type: isLoadingfn, payload: false });
    }
}

function* authSaga() {
    yield takeLatest('purchase/getDatas', fetchAlldata);
    yield takeEvery('purchase/getDatasBlp', fetchAlldataBlp);
    yield takeLatest('purchase/fetchBasicDetails', fetchBasic);
    yield takeEvery('purchase/getAgent', getAgents);
    yield takeEvery('purchase/getDestAgent', getDestAgents);
    yield takeEvery('purchase/createPo', createPo);
    yield takeEvery('purchase/additionalPo', addAdditional);
    yield takeEvery('purchase/getAddress', getAddress);
    yield takeEvery('purchase/getBuyer', getBuyer);
    yield takeEvery('purchase/submitPo', submitPO);
    yield takeEvery('purchase/fetchConfirmedLines', fetchConfirmedLinesValues);
    yield takeEvery('purchase/submitForwardercontainer', submitContainerForwarder);
}

export default authSaga;
