import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { isLoadingFn, saveInventoryDataDiscrepancy } from '../../reducers/inventory/inventoryListReducer';
import { saveEnquiryLog } from '../../reducers/inventory/inventoryLog';
import {
    addCommentEnquiry,
    forwardToClient,
    getEnquiryData,
    raiseEnquiry,
    viewedLogapi,
} from '../../../services/apis/inventory/enquiryApi';
import { getInventoryDataDiscrepancies } from '../../../services/apis/inventory/inventoryListApi';

function* getEnquiryLog({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const res: AxiosResponse<any> = yield call(getEnquiryData, payload);
        yield put({ type: saveEnquiryLog, payload: res });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* submitEnquiry({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(raiseEnquiry, payload);
    } finally {
        const res: AxiosResponse<any> = yield call(getEnquiryData, payload);
        yield put({ type: saveEnquiryLog, payload: res });
        const inventories: AxiosResponse<any> = yield call(getInventoryDataDiscrepancies, payload.payloadDis);
        payload['discrepancies'] = inventories;
        yield put({ type: saveInventoryDataDiscrepancy, payload: payload });
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addCommentToEnquiry({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(addCommentEnquiry, payload);
    } finally {
        const res: AxiosResponse<any> = yield call(getEnquiryData, payload);
        yield put({ type: saveEnquiryLog, payload: res });
        const inventories: AxiosResponse<any> = yield call(getInventoryDataDiscrepancies, payload.payloadDis);
        payload['discrepancies'] = inventories;
        yield put({ type: saveInventoryDataDiscrepancy, payload: payload });
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* fowardingEnquiryToClient({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(forwardToClient, payload);
    } finally {
        const res: AxiosResponse<any> = yield call(getEnquiryData, payload);
        yield put({ type: saveEnquiryLog, payload: res });
        const inventories: AxiosResponse<any> = yield call(getInventoryDataDiscrepancies, payload.payloadDis);
        payload['discrepancies'] = inventories;
        yield put({ type: saveInventoryDataDiscrepancy, payload: payload });
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* LogviewedByUser({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(viewedLogapi, payload);
    } finally {
        const res: AxiosResponse<any> = yield call(getEnquiryData, payload);
        yield put({ type: saveEnquiryLog, payload: res });
        const inventories: AxiosResponse<any> = yield call(getInventoryDataDiscrepancies, payload.payloadDis);
        payload['discrepancies'] = inventories;
        yield put({ type: saveInventoryDataDiscrepancy, payload: payload });
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* InventoryLogSaga() {
    yield takeEvery('inventoryLog/fetchEnquiryLog', getEnquiryLog);
    yield takeEvery('inventoryLog/raiseAnEnquiry', submitEnquiry);
    yield takeEvery('inventoryLog/enquiryAddComment', addCommentToEnquiry);
    yield takeEvery('inventoryLog/forwardingToClient', fowardingEnquiryToClient);
    yield takeEvery('inventoryLog/viewedLog', LogviewedByUser);
}

export default InventoryLogSaga;
