import React, { useEffect, useState } from 'react';
import './received-quantity.scss';
import { useAppDispatch } from '../../../../hooks';
import { downloadPoLinesImages } from '../../../../store/reducers/receipts/receiptHeaderReducer';
import BSTooltip from '../../../../components/common/BSTooltip';
interface ReceivedQtyModalProps {
    viewReceivedQuantityModalVisibility: boolean;
    setReceivedQtyModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
}
import ImageView from './ImageView';
import { poStatusPillList } from '../POLinesConfirmation/PoStatusList';
import { getFailedReasonImage } from '../../../../services/apis/receipts/receiptHeaderApi';
import imageLoader from '../../../../assets/images/image-loader.svg';
const Index: React.FC<ReceivedQtyModalProps> = ({
    viewReceivedQuantityModalVisibility,
    setReceivedQtyModalVisibility,
    data,
}) => {
    const [showImageViewer, setShowImageViewer] = useState(false);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [receivedQtyImages, setReceivedQtyImages] = useState<any>([]);
    const dispatch = useAppDispatch();
    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };
    const generateUrl = (base64Image: string, fileName: string) => {
        const [extension] = fileName.split('.').reverse();
        return `data:image/${extension};base64,${base64Image}`;
    };

    useEffect(() => {
        if (viewReceivedQuantityModalVisibility && data?.editImageDetailsList?.length) {
            setReceivedQtyImages(data?.editImageDetailsList);
            getFailedReasonImage({
                receiptSubPoLineId: data?.rsId,
                type: 'EDIT_RECEIPT_PO_IMAGE',
            })
                .then((res: any) => {
                    const urls = res?.data;
                    const images = JSON.parse(JSON.stringify(data?.editImageDetailsList));
                    const imageWithUrl = images?.map((response: any) => ({
                        ...response,
                        fileUrl: generateUrl(
                            urls?.find((elem: any) => elem?.fileId === response?.fileId)?.base64,
                            response?.fileName
                        ),
                    }));
                    setReceivedQtyImages(imageWithUrl);
                })
                .catch((err: any) => console.log(err, 'err'));
        } else {
            setReceivedQtyImages([]);
            setCurrentImageIndex(0);
        }
    }, [viewReceivedQuantityModalVisibility]);

    return (
        <>
            {viewReceivedQuantityModalVisibility ? (
                <div className="app-modal">
                    <div className="app-loader ">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className="modal-content-holder medium-popup received-modal"
                    >
                        <div className="modal-header">
                            <div className="title">Received Quantity</div>
                            <button
                                onClick={() => setReceivedQtyModalVisibility(false)}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                {/* {data?.currentComplianceCheckStatus === 'failed' && (
                                    <div className="warning-text">
                                        <div>
                                            <svg className="svg-icon error-icon">
                                                <use xlinkHref="#errorIcon">
                                                    <title>Warning</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="error-text">
                                            Compliance check has failed for this SKU and quantity can only be received
                                            On Hold
                                        </div>
                                    </div>
                                )} */}
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">SKU</h6>
                                        <div className="description-type-1">{data?.sku}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Description</h6>
                                        <div className="description-type-1">{data?.description}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Inbound PO</h6>
                                        <div className="description-type-1">{data?.inboundPoNumber}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Planned Qty</h6>
                                        <div className="description-type-1">{data?.plannedReceiptQuantity}</div>
                                    </div>
                                </div>
                                <h2 className="modal-layout-title">Received Quantity</h2>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1 input-heading">Available</h6>
                                        <div className="description-type-1">
                                            <div className="modal-grid-input-holder">{data?.availableQty}</div>
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className="heading-icon-holder">
                                            <h6 className="heading-type-1 input-heading">On Hold</h6>
                                            <BSTooltip
                                                id="onHold"
                                                title="On Hold quantity can include goods not ready for allocation/Qty received if compliance check has failed/ excess Qty received than planned. On Hold quantity can be adjusted after receipt"
                                                classList="info-btn"
                                                tooltipClassList="info-btn-text"
                                            >
                                                <button className="app-btn">
                                                    <svg className="svg-icon help-icon">
                                                        <use xlinkHref="#helpIcon">
                                                            <title>Warning</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </BSTooltip>
                                        </div>
                                        <div className="description-type-1">
                                            <div className="modal-grid-input-holder">{data?.onHoldQty}</div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="title-content">
                                    Since Available Qty cannot be more than the Planned, the extra Qty will be received
                                    On Hold
                                </div> */}
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Total Received Qty</h6>
                                        <div className="description-type-1">{data?.totalReceivedQty}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Status</h6>
                                        <div className="description-type-1">{poStatusPillList(data?.status)}</div>
                                    </div>
                                </div>

                                {(data?.markAsReceivedFull === true ||
                                    data?.status === 'Cancelled' ||
                                    data?.onHoldQty > 0) && (
                                    <div className="layout-holder">
                                        <div className="layout-item">
                                            <h6 className="heading-type-1">Comments</h6>
                                            <div className="description-type-1">{data?.comments}</div>
                                        </div>
                                        {receivedQtyImages?.length > 0 && (
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">Images</h6>
                                                <div className="description-type-1">
                                                    {receivedQtyImages?.map((res: any, index: number) => (
                                                        <div key={index} className="attachment-items-holder">
                                                            <div className="attachment-icon-holder">
                                                                <div className="image-holder">
                                                                    <a href="#">
                                                                        <img
                                                                            onClick={() => {
                                                                                setCurrentImageIndex(index);
                                                                                setShowImageViewer(true);
                                                                            }}
                                                                            className="logo-icon"
                                                                            src={
                                                                                res?.fileUrl
                                                                                    ? res?.fileUrl
                                                                                    : imageLoader
                                                                            }
                                                                            alt={res?.fileName}
                                                                        />
                                                                    </a>
                                                                </div>
                                                                <div className="filename-folder">
                                                                    <div
                                                                        className="description-type-1 file-uploaded"
                                                                        title="File"
                                                                    >
                                                                        {res?.fileName?.length > 20
                                                                            ? `${res?.fileName?.slice(0, 20)}...`
                                                                            : res?.fileName}
                                                                    </div>
                                                                    <div className="heading-type-1">
                                                                        {getFileSizeOfUploadedFile(res?.fileSize)}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="btn-holder">
                                                    <button
                                                        onClick={() =>
                                                            dispatch(
                                                                downloadPoLinesImages({
                                                                    receiptSubPoLineId: data?.rsId,
                                                                    type: 'EDIT_RECEIPT_PO_IMAGE',
                                                                })
                                                            )
                                                        }
                                                        className="app-btn app-btn-secondary icon-button  add-product-btn "
                                                        title="Download All"
                                                    >
                                                        <svg className="svg-icon add-btn-icon">
                                                            <use xlinkHref="#downloadIcon"></use>
                                                            <title>Download</title>
                                                        </svg>
                                                        <span className="button-text footer-button-text">
                                                            Download All
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <ImageView
                        imageViewerModalVisibility={showImageViewer}
                        setImageViewerModalVisibility={setShowImageViewer}
                        originalData={receivedQtyImages}
                        currentImageIndex={currentImageIndex}
                        setCurrentImageIndex={setCurrentImageIndex}
                    />
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
