import React from 'react';
import KendoGridReact from 'src/components/KendoGridReact';
import { post, patch, del } from 'src/services/HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

const LifecycleHeader = () => {
    const title = { name: 'Lifecycle Header', extraKey: '' };
    const permissionName = 'header';
    const uri = 'cix/scr/lifecycle/flow';

    const convertForSubmit = (data: any) => {
        return {
            LH_Name: data.LH_Name,
            LH_SystemCreated: data.LH_SystemCreated ?? false,
            LH_FunctionalArea: data.LH_FunctionalArea,
        };
    };

    const addItem = (payload: any) =>
        post('cix/scr/lifecycle/flow', convertForSubmit(payload), null, false, externalBaseUrl);
    const updateItem = (payload: any, key: string) => patch(`cix/scr/lifecycle/flow/${key}`, convertForSubmit(payload));
    const deleteItem = (payload: any, key: string) => del(`cix/scr/lifecycle/flow/${key}`, null, externalBaseUrl);

    const uniqueKey = 'LH_PK';

    const systemCreatedKey = 'LH_SystemCreated';

    const columns = [
        {
            field: 'LH_PK',
            title: 'LH_PK',
            filterable: false,
            editable: false,
            hidden: true,
        },
        {
            field: 'LH_Name',
            title: 'Name',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
        },
        {
            field: 'LH_SystemCreated',
            title: 'System Created',
            filter: 'text',
            editor: 'boolean',
            filterable: true,
            editable: true,
            hidden: false,
        },
        {
            field: 'LH_FunctionalArea',
            title: 'Functional Area',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
        },
        {
            field: 'LH_CreatedDateTime',
            title: 'Created DateTime',
            filterable: true,
            editable: false,
            hidden: false,
            filter: 'text',
            editor: 'date',
        },
        {
            field: 'LH_ModifiedDateTime',
            title: 'Modified DateTime',
            filterable: true,
            editable: false,
            hidden: false,
            filter: 'text',
            editor: 'date',
        },
        {
            field: 'LH_US',
            title: 'US',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: false,
            hidden: false,
        },
    ];

    const extraActionButtons = [
        {
            title: 'Details',
            icon: 'eyeIcon',
            onClick: (data: any) => {
                window.open('/configuration/lifecycle/' + data?.dataItem?.LH_PK, '_blank');
            },
            disabled: false,
            hidden: false,
        },
    ];

    return (
        <KendoGridReact
            title={title}
            uri={uri}
            permissionName={permissionName}
            columns={columns}
            uniqueKey={uniqueKey}
            systemCreatedKey={systemCreatedKey}
            addItem={addItem}
            updateItem={updateItem}
            deleteItem={deleteItem}
            extraActionButtons={extraActionButtons}
        />
    );
};

export default LifecycleHeader;
