import { get, post } from '../../HttpMethods';

// Get All Datas

export const getAssignedPoLines = async (payload: any) => {
    try {
        const res = await get(`api/ord/blp/receipts/added/polines?receiptId=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getAllpoLines = async (payload: any) => {
    try {
        const res = await get(`api/ord/blp/receipts/po?receiptId=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const assignToPo = async (payload: any) => {
    try {
        const res = await post('api/ord/blp/receipts/addpo', payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const removeFromReceipt = async (payload: any) => {
    try {
        const res = await post('api/ord/blp/receipts/removepo', payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
