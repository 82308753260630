import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../store/reducers';
import { cancelBp, reset } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { error, Position, success } from '../../../utils/toast';
import './cancel-booking-modal.scss';
import { resetHeader } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { setDisabledFilterData, setFilterDispatch } from 'src/store/reducers/bookingProposalBcn/availablePoReducer';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
interface CancelBpProps {
    setShowBookingCancelModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index = ({ setShowBookingCancelModal }: CancelBpProps): ReactElement => {
    const { id } = useParams();
    const { bpNumber, clientName } = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoading } = useSelector((state: RootState) => state.bpHeaderData);
    const [reason, setReason] = useState('');
    const [reasonError, setError] = useState(false);
    const userDetails = JSON.parse(localStorage.getItem('user') || '')?.user;
    // const bpID = localStorage.getItem('bpId');
    const userName = userDetails?.us_UserName;
    const name = (userDetails?.us_FirstName || '') + ' ' + (userDetails?.us_LastName || '');
    const { error: errorMessage, success: successFlag } = useSelector((state: RootState) => state.bpHeaderData);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const onConfirmCancellation = () => {
        if (reason?.trim()?.length) {
            const payload = {
                bpId: id,
                name,
                userName,
                reason,
            };
            dispatch(cancelBp(payload));
        } else {
            setError(true);
        }
    };

    useEffect(() => {
        if (successFlag) {
            dispatch(
                setDisabledFilterData({
                    clientName: null,
                    originPort: null,
                    destinationPort: null,
                    vendorName: null,
                    isHeaderOnlyPo: null,
                })
            );
            propagateBcnData(id, true);
            dispatch(setFilterDispatch({}));
            success(`Booking Proposal ${bpNumber} has been cancelled`, Position.TOP_RIGHT);
            navigate('/booking_list');
            dispatch(reset({}));
            setShowBookingCancelModal(false);
            dispatch(resetHeader({}));
        }
    }, [successFlag]);

    useEffect(() => {
        if (errorMessage) {
            error(errorMessage, Position.TOP_RIGHT);
            dispatch(reset({}));
        }
    }, [errorMessage]);

    return (
        <div className="app-modal">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder medium-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Cancel Booking Proposal</div>
                    <button className="app-btn modal-close-btn" onClick={() => setShowBookingCancelModal(false)}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">
                                Once cancelled, this Booking Proposal will be inactive in the application and the action
                                cannot be undone
                            </div>
                        </div>

                        <div className="layout-holder two-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Booking Proposal No.</h6>
                                <div className="description-type-1">{bpNumber}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Client Name</h6>
                                <div className="description-type-1">{clientName}</div>
                            </div>
                        </div>
                        <form id="productForm" action="" className="modal-form">
                            <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder="Enter the reason to Cancel Booking Proposal"
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                            if (reasonError) {
                                                setError(false);
                                            }
                                        }}
                                        required
                                    ></textarea>
                                </div>

                                {reasonError ? (
                                    <div className="error-text">
                                        Please provide a reason for booking proposal cancellation
                                    </div>
                                ) : null}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                        onClick={() => setShowBookingCancelModal(false)}
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="my-form"
                        title="Confirm Cancellation"
                        onClick={() => onConfirmCancellation()}
                        disabled={!reason}
                    >
                        <span className="button-text footer-button-text">Confirm Cancellation</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
