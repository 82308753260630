import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { Link, useLocation, useParams } from 'react-router-dom';

const BLPInboundPoTabs = () => {
    const { id } = useParams();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const { headerDetails } = useSelector((state: RootState) => state.addPoData);

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    React.useEffect(() => {
        if (lastSegment) {
            if (lastSegment === 'po_review') {
                setSelectedIndex(3);
            } else if (lastSegment === 'product_details') {
                setSelectedIndex(2);
            } else if (lastSegment === 'dates') {
                setSelectedIndex(1);
            } else {
                setSelectedIndex(0);
            }
        }
    }, []);

    const inboundTabList = [
        {
            label: 'Basic Details',
            value: 'basic_details',
            id: 1,
        },
        {
            label: 'Dates',
            value: 'dates',
            id: 2,
        },
        {
            label: 'Product Details',
            value: 'product_details',
            id: 3,
        },
        {
            label: 'PO Preview',
            value: 'po_review',
            id: 4,
        },
    ];

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (headerDetails) {
                if (param === 'po_review') {
                    return (
                        headerDetails.bdTabComplete &&
                        headerDetails.datesTabComplete &&
                        headerDetails.validDate &&
                        headerDetails.productTabComplete
                    );
                } else if (param === 'product_details') {
                    return headerDetails.productTabComplete;
                } else if (param === 'dates') {
                    return headerDetails.datesTabComplete && headerDetails.validDate;
                } else if (param === 'basic_details') {
                    return headerDetails.bdTabComplete;
                } else {
                    return false;
                }
            }
        };
    }, [lastSegment, headerDetails]);

    const checkTabNavigationFromApi = (param: string, headerDetailsVal: any) => {
        if (headerDetailsVal) {
            if (param === 'po_review') {
                return (
                    headerDetailsVal.bdTabComplete &&
                    headerDetailsVal.datesTabComplete &&
                    headerDetailsVal.validDate &&
                    headerDetailsVal.productTabComplete
                );
            } else if (param === 'product_details') {
                return (
                    headerDetailsVal.bdTabComplete && headerDetailsVal.datesTabComplete && headerDetailsVal.validDate
                );
            } else if (param === 'dates') {
                return headerDetailsVal.bdTabComplete === true;
            } else {
                return true;
            }
        }
    };

    return (
        <div className="arrow-tab-container">
            <div className="arrow-tab-holder">
                {inboundTabList.map((tabs: any, index: number) => {
                    return (
                        <Link
                            className={`arrow-tab-border ${index === selectedIndex && 'active'} po-basic-tab ${
                                !checkTabNavigationFromApi(tabs.value, headerDetails) && 'disabled'
                            }`}
                            to={`/inbound/${id}/${tabs.value}`}
                        >
                            {isTabActive(tabs.value) ? (
                                <div
                                    className="arrow-badge verify-badge"
                                    style={{
                                        backgroundColor: '#18ab56',
                                    }}
                                >
                                    <svg className="svg-icon tick-icon">
                                        <use href="#tickIcon">
                                            <title>check mark</title>
                                        </use>
                                    </svg>
                                </div>
                            ) : (
                                <div className="arrow-badge">{tabs.id}</div>
                            )}
                            <div className="arrow-tab">{tabs.label}</div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default BLPInboundPoTabs;
