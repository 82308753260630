/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import './purchase-order-detail.scss';
import '../../../assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_accordation.scss';
import '../../../assets/scss/components/_modal.scss';
import { poDataFetch } from '../../../store/reducers/bookingProposalLand/availablePoReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import moment from 'moment';
import { poStatusPillList } from 'src/pages/PO/ProductDetailsConfirmation/PoStatusList';
import InboundProductDetails from './InboundProductDetails';

interface ModalProps {
    displayFn?: any;
    display?: any;
    customStyle?: any;
}
const Index: React.FC<ModalProps> = ({ display, displayFn, customStyle }) => {
    const { poData, isLoading } = useSelector((state: RootState) => state.availablePos);

    const dispatch = useDispatch();
    const [expandedId, setExpandedId] = useState('');
    const [currentOpen, setcurrentOpen] = useState('header');
    useEffect(() => {
        dispatch(poDataFetch(display.id));
    }, []);
    const data = poData as any;
    // const changeInValues = () => {
    //     let flag = true;
    //     if (window.location.href.toLowerCase().includes('receipt')) {
    //         flag = false;
    //     }
    //     return flag;
    // };

    const getRemainingQty = (po: any) => {
        const qty = po?.qty || 0;
        const total = po?.productSplitLines?.reduce(
            (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
            0
        );
        if (total === 0) {
            return <span className="second-unit">(R:{qty})</span>;
        } else {
            if (total > qty) {
                const remaining = total - qty;
                return <span className="second-unit error-text">(R:0, S:{remaining})</span>;
            } else {
                const remaining = qty - total;
                return <span className="second-unit">(R:{remaining})</span>;
            }
        }
    };

    return (
        <>
            <div className="app-modal large-popup">
                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
                <div id="modal_body" className="modal-content-holder booking-purchase-holder large-popup">
                    <div className="modal-header">
                        <div className="title">Purchase Order Details</div>
                        <button className="app-btn modal-close-btn" onClick={() => displayFn({ status: false })}>
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use href="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div style={{ overflow: customStyle ? 'auto' : '' }} className="modal-content">
                        <div className="app-loader">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                        <div className="accordion-wrapper">
                            <div className="accordion-section section-header">
                                <input
                                    checked={currentOpen === 'header'}
                                    type="checkbox"
                                    className="accordion-radio"
                                    id="ac1"
                                    name="shipping-accordion-group"
                                ></input>
                                <label
                                    className="accordion-title"
                                    htmlFor="ac1"
                                    onClick={() => {
                                        currentOpen === 'header' ? setcurrentOpen('') : setcurrentOpen('header');
                                    }}
                                >
                                    <div className="label-header">
                                        <svg className="svg-icon accordion-arrow-icon">
                                            <use xlinkHref="#downArrow">
                                                <title>dropdown</title>
                                            </use>
                                        </svg>

                                        <span className="label-title">Header Details</span>
                                    </div>
                                </label>
                                <div className="accordion-content">
                                    <div className="modal-main-content-header">
                                        {/* ADD-CONTRACT-VIEW-HEADER-INFORMATION */}
                                        <div className="details-box custom">
                                            <div className="card-detail-col pogrid__details">
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">PO Number</h6>
                                                    <div className="details-box-content">{data?.poNumber}</div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Client Name</h6>
                                                    <div className="details-box-content">{data?.clientName}</div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Vendor Name</h6>
                                                    <div className="details-box-content">{data?.vendorName}</div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Order Lines</h6>
                                                    <div className="details-box-content">{data?.totOrderLines}</div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Total CBM</h6>
                                                    <div className="details-box-content">{data?.totCBM}</div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Total Kgs</h6>
                                                    <div className="details-box-content">{data?.totKGS}</div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Total Order Value</h6>
                                                    <div className="details-box-content">{data?.totOrderValue}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-section section-basic-detail">
                                <input
                                    type="checkbox"
                                    className="accordion-radio"
                                    id="ac2"
                                    name="shipping-accordion-group"
                                    checked={currentOpen === 'basic'}
                                ></input>
                                <label
                                    className="accordion-title"
                                    htmlFor="ac2"
                                    onClick={() => {
                                        currentOpen === 'basic' ? setcurrentOpen('') : setcurrentOpen('basic');
                                    }}
                                >
                                    <div className="label-header">
                                        <svg className="svg-icon accordion-arrow-icon">
                                            <use xlinkHref="#downArrow">
                                                <title>dropdown</title>
                                            </use>
                                        </svg>

                                        <span className="label-title">Basic Details</span>
                                    </div>
                                </label>
                                <div className="accordion-content">
                                    <div className="card modal-main-content-header">
                                        <span className="label-card-title">Required Details</span>
                                        {/* ADD-CONTRACT-VIEW-HEADER-INFORMATION */}
                                        <div className="details-box custom">
                                            <div className="card-detail-col pogrid__details">
                                                {data?.ordertype && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Order Type</h6>
                                                        <div className="details-box-content">
                                                            {data?.ordertype?.orderTypeName}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.shippingmode && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Shipping Mode</h6>
                                                        <div className="details-box-content">
                                                            {data?.shippingmode?.shippingModeName}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.fclservicetype && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">FCL service Type</h6>
                                                        <div className="details-box-content">
                                                            {data?.fclservicetype?.fclServiceTypeName}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.freightterms && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Freight Term</h6>
                                                        <div className="details-box-content">
                                                            {data?.freightterms?.freightTermsCode}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.incoTerm && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Incoterm</h6>
                                                        <div className="details-box-content">
                                                            {data?.incoTerm?.incoTermName}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.releaseType && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Release Type</h6>
                                                        <div className="details-box-content">
                                                            {data?.releaseType?.releaseTypeName}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.paymentterms && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Payment Term</h6>
                                                        <div className="details-box-content">
                                                            {data?.paymentterms?.paymentTermsName}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.originPort && data?.originPort !== 'null(null)' && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Origin</h6>
                                                        <div className="details-box-content">{data?.originPort}</div>
                                                    </div>
                                                )}
                                                {data?.destinationPort && data?.destinationPort !== 'null(null)' && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Destination</h6>
                                                        <div className="details-box-content">
                                                            {data?.destinationPort}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.destinationAddress && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Destination Address</h6>
                                                        <div className="details-box-content">
                                                            {data?.destinationAddress}
                                                        </div>
                                                    </div>
                                                )}
                                                {data?.agentName && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Agent</h6>
                                                        <div className="details-box-content">{data?.agentName}</div>
                                                    </div>
                                                )}
                                                {data?.blpAddress && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">BLP Address</h6>
                                                        <div className="details-box-content">{data?.blpAddress}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {(data?.promRefNo ||
                                        data?.brandName ||
                                        data?.impVendorCode ||
                                        data?.pickGroup ||
                                        data?.currency ||
                                        data?.buyerUserName ||
                                        data?.impShipmentRefNo ||
                                        data?.distribOrderNo) && (
                                        <div className="card modal-main-content-header">
                                            <span className="label-card-title">Additional Details</span>
                                            {/* ADD-CONTRACT-VIEW-HEADER-INFORMATION */}
                                            <div className="details-box custom">
                                                <div className="card-detail-col pogrid__details">
                                                    {data?.promRefNo && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">Promotional Ref No.</h6>
                                                            <div className="details-box-content">{data?.promRefNo}</div>
                                                        </div>
                                                    )}
                                                    {data?.brandName && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">Brand Name</h6>
                                                            <div className="details-box-content">{data?.brandName}</div>
                                                        </div>
                                                    )}
                                                    {data?.impVendorCode && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">
                                                                Importer’s Vendor Code{' '}
                                                            </h6>
                                                            <div className="details-box-content">
                                                                {data?.impVendorCode}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {data?.pickGroup && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">Pick Group </h6>
                                                            <div className="details-box-content">{data?.pickGroup}</div>
                                                        </div>
                                                    )}
                                                    {data?.currency && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">Order Currency </h6>
                                                            <div className="details-box-content">
                                                                {data?.currency?.currencycode}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {data?.buyerUserName && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">Buyer’s Name </h6>
                                                            <div className="details-box-content">
                                                                {data?.buyerUserName}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {data?.impShipmentRefNo && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">
                                                                Importer’s Shipment Ref No.
                                                            </h6>
                                                            <div className="details-box-content">
                                                                {data?.impShipmentRefNo}
                                                            </div>
                                                        </div>
                                                    )}
                                                    {data?.distribOrderNo && (
                                                        <div className="details-col box-content-holder">
                                                            <h6 className="details-box-heading">
                                                                Distribution Order No.{' '}
                                                            </h6>
                                                            <div className="details-box-content">
                                                                {data?.distribOrderNo}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="accordion-section section-dates">
                                <input
                                    type="checkbox"
                                    checked={currentOpen === 'date'}
                                    className="accordion-radio"
                                    id="ac3"
                                    name="shipping-accordion-group"
                                ></input>
                                <label
                                    className="accordion-title"
                                    htmlFor="ac3"
                                    onClick={() => {
                                        currentOpen === 'date' ? setcurrentOpen('') : setcurrentOpen('date');
                                    }}
                                >
                                    <div className="label-header">
                                        <svg className="svg-icon accordion-arrow-icon">
                                            <use xlinkHref="#downArrow">
                                                <title>dropdown</title>
                                            </use>
                                        </svg>

                                        <span className="label-title">Dates</span>
                                    </div>
                                </label>
                                <div className="accordion-content">
                                    <div className="modal-main-content-header">
                                        {/* ADD-CONTRACT-VIEW-HEADER-INFORMATION */}
                                        <div className="details-box custom">
                                            <div className="card-detail-col pogrid__details">
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Order Date</h6>
                                                    <div className="details-box-content">
                                                        {data?.orderDate
                                                            ? moment(data?.orderDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Start Shipping Window</h6>
                                                    <div className="details-box-content">
                                                        {data?.startShipWindowDate
                                                            ? moment(data?.startShipWindowDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">End Shipping Window</h6>
                                                    <div className="details-box-content">
                                                        {data?.endShipWindowDate
                                                            ? moment(data?.endShipWindowDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </div>
                                                {!data?.isMultiAddress && (
                                                    <div className="details-col box-content-holder">
                                                        <h6 className="details-box-heading">Goods Ready Date</h6>
                                                        <div className="details-box-content">
                                                            {data?.goodsReadyDate
                                                                ? moment(data?.goodsReadyDate).format('DD-MM-YYYY')
                                                                : '-'}
                                                        </div>
                                                    </div>
                                                )}
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Created Date</h6>
                                                    <div className="details-box-content">
                                                        {data?.createdDate
                                                            ? moment(data?.createdDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">
                                                        Projected ETA into Warehouse
                                                    </h6>
                                                    <div className="details-box-content">
                                                        {data?.projectedEtaDate
                                                            ? moment(data?.projectedEtaDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="accordion-section section-product-detail">
                                <input
                                    type="checkbox"
                                    className="accordion-radio"
                                    id="ac4"
                                    name="shipping-accordion-group"
                                    checked={currentOpen === 'product'}
                                ></input>
                                <label
                                    className="accordion-title"
                                    htmlFor="ac4"
                                    onClick={() => {
                                        currentOpen === 'product' ? setcurrentOpen('') : setcurrentOpen('product');
                                    }}
                                >
                                    <div className="label-header">
                                        <svg className="svg-icon accordion-arrow-icon">
                                            <use xlinkHref="#downArrow">
                                                <title>dropdown</title>
                                            </use>
                                        </svg>

                                        <span className="label-title">Product Details</span>
                                    </div>
                                </label>
                                <div className="accordion-content">
                                    <div className="grid-section booking-product-details-grid lcl-fcl-grid">
                                        <div id="table_div" className="grid-holder">
                                            {data?.type === 'IBPO' ? (
                                                <InboundProductDetails
                                                    clientCode={data?.clientCode}
                                                    products={data?.purchaseOrderProducts}
                                                />
                                            ) : (
                                                <div className="grid-container">
                                                    {data?.purchaseOrderProducts &&
                                                        data?.purchaseOrderProducts.length === 0 && (
                                                            <div className="grid-no-content ">
                                                                <svg className="svg-icon grid-no-content-icon ">
                                                                    <use xlinkHref="#gridNoContentIcon"></use>
                                                                </svg>
                                                                <p className="no-content-message">No records found</p>
                                                            </div>
                                                        )}
                                                    <div className="grid-scroll-content">
                                                        <table className="grid-table">
                                                            <thead>
                                                                {data?.isHeaderOnlyPo ? (
                                                                    <tr>
                                                                        <th className="grid-cell-header col-line">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    CBM
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-line">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    WEIGHT (KG)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-line">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    GOODS READY DATE
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-line">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    DESTINATION
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-desc">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    DESTINATION ADDRESS
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-sku">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    SKU
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-qty">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    QTY
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-code">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    HS CODE
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                ) : (
                                                                    <tr className="grid-sticky-row">
                                                                        <th className="grid-cell-header  grid-sticky-column sticky-left-aligned col-ao-arrow">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title"></span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-po-line grid-sticky-column sticky-left-aligned">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    PO LINE NO.
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-sku grid-sticky-column sticky-left-aligned">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    SKU
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-description">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    DESCRIPTION
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-status">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    STATUS
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-quanity">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    QUANTITY
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-grd">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    GOODS READY DATE
                                                                                </span>
                                                                            </div>
                                                                        </th>

                                                                        <th className="grid-cell-header col-ao-origin">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    ORIGIN
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-destination-port">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    DESTINATION
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-destination-address ">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    DESTINATION ADDRESS
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-cbm-cost">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    CBM
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-ao-weight">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    WEIGHT(Kg)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                )}
                                                            </thead>
                                                            <tbody>
                                                                {data?.purchaseOrderProducts?.map(
                                                                    (po: any, indx: number) =>
                                                                        data?.isHeaderOnlyPo ? (
                                                                            <>
                                                                                <tr>
                                                                                    <td className="grid-cell col-po-desc td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.cbm}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell col-po-desc td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.weight}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell col-po-grd td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {moment(
                                                                                                po?.subPoLineId
                                                                                                    ?.confirmedGoodsdate
                                                                                            ).format('DD-MM-YYYY')}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell col-po-desc td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.destinationPort
                                                                                                ? po?.destinationPort
                                                                                                : '-'}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell col-po-desc td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.destinationAddress}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell col-po-sku td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.sku}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell col-po-qty td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.qty}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell col-po-code td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.hsCode}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        ) : (
                                                                            <>
                                                                                <tr
                                                                                    className={`has-child ${
                                                                                        po?.poProductId === expandedId
                                                                                            ? 'row-expanded'
                                                                                            : ''
                                                                                    } no-border`}
                                                                                    key={indx}
                                                                                >
                                                                                    <td className="grid-cell td-arrow search-entry-holder grid-sticky-column sticky-left-aligned">
                                                                                        {po?.productSplitLines
                                                                                            ?.length ? (
                                                                                            <button
                                                                                                type="button"
                                                                                                className="grid-expander app-btn"
                                                                                                onClick={() => {
                                                                                                    setExpandedId(
                                                                                                        po?.poProductId ===
                                                                                                            expandedId
                                                                                                            ? ''
                                                                                                            : po?.poProductId
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <svg className="svg-icon arrow-icon">
                                                                                                    <use xlinkHref="#arrowIcon">
                                                                                                        <title>
                                                                                                            Expand row
                                                                                                        </title>
                                                                                                    </use>
                                                                                                </svg>
                                                                                            </button>
                                                                                        ) : null}
                                                                                    </td>
                                                                                    <td className="grid-cell td-po-line grid-sticky-column sticky-left-aligned">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.poLineNumber}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku grid-sticky-column sticky-left-aligned">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.sku}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.description}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {poStatusPillList(
                                                                                                po?.status
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="quality-count">
                                                                                                <div className="units-holder">
                                                                                                    <span className="first-unit">
                                                                                                        {po?.qty}
                                                                                                    </span>
                                                                                                    {getRemainingQty(
                                                                                                        po
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.goodsReadyDate &&
                                                                                                moment(
                                                                                                    po?.goodsReadyDate
                                                                                                ).format('DD-MM-YYYY')}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.originPort
                                                                                                ? po?.originPort
                                                                                                : '-'}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.destinationPort
                                                                                                ? po?.destinationPort
                                                                                                : '-'}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.destinationAddress}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.cbm}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder td-sku">
                                                                                        <div className="grid-cell-data">
                                                                                            {po?.weight}
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                {po?.poProductId === expandedId &&
                                                                                    po?.productSplitLines?.map(
                                                                                        (
                                                                                            splitLine: any,
                                                                                            ind: number
                                                                                        ) => (
                                                                                            <tr
                                                                                                className="child-l1"
                                                                                                key={ind}
                                                                                            >
                                                                                                <td className="grid-cell   td-arrow search-entry-holder grid-sticky-column sticky-left-aligned"></td>
                                                                                                <td className="grid-cell td-po-line grid-sticky-column sticky-left-aligned">
                                                                                                    <div className="grid-cell-data"></div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku grid-sticky-column sticky-left-aligned">
                                                                                                    <div className="grid-cell-data"></div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data"></div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        {poStatusPillList(
                                                                                                            splitLine?.splitLineStatus
                                                                                                        )}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        <div className="quality-count">
                                                                                                            <div className="units-holder">
                                                                                                                <span className="first-unit">
                                                                                                                    {
                                                                                                                        splitLine?.confirmedQty
                                                                                                                    }
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        {splitLine?.confirmedGoodsdate &&
                                                                                                            moment(
                                                                                                                splitLine?.confirmedGoodsdate
                                                                                                            ).format(
                                                                                                                'DD-MM-YYYY'
                                                                                                            )}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        {po?.originPort
                                                                                                            ? po?.originPort
                                                                                                            : '-'}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        {po?.destinationPort
                                                                                                            ? po?.destinationPort
                                                                                                            : '-'}
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        {
                                                                                                            po?.destinationAddress
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        {
                                                                                                            splitLine?.confirmedCbm
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td className="grid-cell search-entry-holder td-sku">
                                                                                                    <div className="grid-cell-data">
                                                                                                        {
                                                                                                            splitLine?.confirmedWeight
                                                                                                        }
                                                                                                    </div>
                                                                                                </td>
                                                                                            </tr>
                                                                                        )
                                                                                    )}
                                                                            </>
                                                                        )
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div></div>
        </>
    );
};
export default Index;
