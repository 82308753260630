import axios from 'axios';
import { del, get, post } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const fetchContainersApi = async (payload: { bpId: string }) => {
    try {
        const data = await get(`api/bkg/bp/enrich/containers/${payload.bpId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const deleteOneContainer = async (payload: { bpPk: string; bcPk: string; payload: any }) => {
    try {
        const data = await del(
            `/cix/scr/forwarding/booking/${payload.bpPk}/container/${payload.bcPk}`,
            payload.payload,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchAttachedPurchaseOrdersApi = async (payload: { bpPk: string }) => {
    try {
        const data = await get(
            `cix/scr/forwarding/booking/${payload.bpPk}/confirmationgroups?take=1000&page=1&pagesize=1000`,
            null,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchContainerOnlyBasicDetailApi = async (payload: { containerId: string }) => {
    try {
        const data = await get(`api/bkg/bp/containerbasic/${payload.containerId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchContainerDetailApi = async (payload: { containerDetailsId: string }) => {
    try {
        const data = await get(`api/bkg/bp/container-allocation/${payload.containerDetailsId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchInitialContainerDetailApi = async (payload: { bookingProposalId: string }) => {
    try {
        const data = await get(
            `api/bkg/bp/container-allocation/privatecoloadercontainer/${payload.bookingProposalId}`,
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateBasicDetailApi = async (payload: any) => {
    const mode = payload.mode;
    delete payload.mode;
    try {
        const data = await post('api/bkg/bp/container-allocation/basic', payload, null);
        data.type = 'basicDetail';
        data.mode = mode;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateDateAPi = async (payload: any) => {
    try {
        const mode = payload.mode;
        delete payload.mode;
        const data = await post('api/bkg/bp/container-allocation/dates', payload, null);
        data.type = 'date';
        data.mode = mode;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchContainerTypeApi = async (payload: { measurement: string }) => {
    try {
        const data = await get(`api/bkg/bp/container/${payload.measurement}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchDeliveryModeApi = async (_payload: any) => {
    try {
        const data = await get('api/bkg/bp/deliverymode', null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchContainerModeApi = async (_payload: any) => {
    try {
        const data = await get('api/bkg/bp/containermode', null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchPackingApi = async (_payload: any) => {
    try {
        const data = await get('api/bkg/bp/packing', null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchAssignedPoApi = async (payload: { containerDetailsId: string; bpId: string }) => {
    try {
        const data = await get(
            `api/bkg/bp/container-allocation/skus?containerDetailsId=${payload.containerDetailsId}&bpId=${payload.bpId}`,
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchAllPoApi = async (payload: { bpId: string }) => {
    try {
        const data = await get(`api/bkg/bp/container-allocation/pos?bpId=${payload.bpId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchPoDetailsApi = async (payload: { poId: string }) => {
    try {
        const data = await get(`api/bkg/po/details/${payload.poId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchSkuDetailsApi = async (payload: { clientCode: string }) => {
    try {
        const data = await get(`api/bkg/bp/products?clientCode=${payload.clientCode}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const postContainerPackingApi = async (payload: { payload: any; bpPk: string; bdPk: string }) => {
    try {
        const data = await post(
            `/cix/scr/forwarding/booking/${payload.bpPk}/container/${payload.bdPk}/packing`,
            payload.payload,
            null,
            undefined,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addToContainerApi = async (payload: any) => {
    const mode = payload.mode;
    delete payload.mode;
    try {
        const data = await post('api/bkg/bp/container-allocation', payload, null);
        data.type = 'addToContainer';
        data.mode = mode;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const removeFromContainerApi = async (payload: any) => {
    const mode = payload.mode;
    delete payload.mode;
    try {
        const data = await post('api/bkg/bp/container-allocation/remove', payload, null);
        data.type = 'removeFromContainer';
        data.mode = mode;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const removeContainerPackingApi = async (payload: { bpPk: string; bdPk: string; caPk: string }) => {
    try {
        const data = await del(
            `/cix/scr/forwarding/booking/${payload.bpPk}/container/${payload.bdPk}/packing/${payload.caPk}`,
            null,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
