import React, { useEffect, useState, useReducer } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../POSelection.scss';
import BookingAdditionalInfo from '../BookingAdditionalInfo';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    clearFailure,
    resetLoader,
    // fetchAllQuotation,
    selectAdditionalDetailsError,
    selectAdditionalDetailsIsError,
    selectAdditionalDetailsSuccess,
    // fetchSingleQuotation,
    fetchCurrency,
    approveBp,
    selectAdditionalDetailsLoading,
    // updateViewStatus,
    selectgateInDateValidation,
    selectEnrichBooking,
    fetchEnrichBooking,
    // selectHouseBillDetails,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { error, Position, success } from '../../../utils/toast';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { useSelector } from 'react-redux';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { RootState } from '../../../store/reducers';
import {
    fetchDeliveryAndContainerModes,
    fetchNacAdditionalDetails,
} from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import ShowForPermissions from '../../../ShowForPermissions';
import UnavailableModel from '../SelectedSailingSchelude/UnavailableModel';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];

const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { profileType, userName } = useUserProfile();
    const [reason, setReason] = useState('');
    const [displayModal, setdisplayModal] = useState(false);
    const [rejectionModalView, setRejectionModalView] = useState(false);
    const [reasonModalView, setReasonModalView] = useState(false);
    const [addDetailsModalView, setAddDetailsModalView] = useState(false);
    const [rejectBpModalView, setRejectBpModalView] = useState(false);
    const [reapproveBpModalView, setReapproveBpModalView] = useState(false);
    const [contractDetailsModalView, setContractDetailsModalView] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [rejectedBpForwarderModalView, setRejectedBpForwarderModalView] = useState(false);
    const [reasonRejection, setReasonRejection] = useState('');
    const [assignAgentModalView, setAssignAgentModalView] = useState(false);
    // const houseBillData = useAppSelector(selectHouseBillDetails);

    const initialState = {
        sellQuotationName: '',
        sellQuotationVersion: '',
        sellQuotationId: '',
        sellTotOriginCost: '',
        sellCurrency: '',
    };

    const [additionalDetails, updateAdditionalDetails] = useReducer(
        (state: typeof initialState, updates: any) => ({ ...state, ...updates }),
        initialState
    );

    const { initialContainerValues } = useSelector((state: RootState) => state.additionalDetailsNac);

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectAdditionalDetailsIsError);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const gateinVal = useAppSelector(selectgateInDateValidation);
    const additionalDetailsError = useAppSelector(selectAdditionalDetailsError);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const bpStatus = headerData.bpStatus;
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const [isShipmentOverride, setIsShipmentOverride] = useState(false);
    // const agentIntegrationStatus = headerData.agentIntegrationStatus;

    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: id ? `/bcnBookingList/${id}/POSelection` : '/bcnBookingList/POSelection',
            disabled: false,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/bcnBookingList/${id}/SailingSelectionDetails`,
            disabled: false,
        },
        {
            title: 'Additional Details',
            selected: 1,
            path: `/bcnBookingList/${id}/additional-details`,
            disabled: false,
        },
        {
            title: 'Container Allocation',
            selected: 0,
            path: `/bcnBookingList/${id}/container-allocation`,
            disabled: !(headerData?.tabValues as any)?.sailing,
        },
    ];

    const navForChild = headerData?.bcnType === 'CLD' ? 'attachments' : 'container-allocation';

    if (
        headerData.bpStatus === 'Ready_for_Booking' ||
        headerData.bpStatus === 'Draft_Booking' ||
        headerData.bpStatus === 'Booked'
    ) {
        navData
            .push
            // {
            //     title: 'Container Allocation',
            //     selected: 0,
            //     path: `/bcnBookingList/${id}/container-allocation`,
            //     disabled: false,
            // },
            // {
            //     title: 'Attachments',
            //     selected: 0,
            //     path: `/bcnBookingList/${id}/attachments`,
            //     disabled: false,
            // }
            ();
    }

    if (headerData?.bcnType === 'PAR') {
        navData.push({
            title: 'Linked Bookings',
            selected: 0,
            path: `/bcnBookingList/${id}/linked-bookings`,
            disabled: false,
        });
    }
    const { additionalDetails: additionalDetailsCurrent } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    useEffect(() => {
        if (gateinVal) {
            setdisplayModal(true);
        } else {
            setdisplayModal(false);
        }
    }, [gateinVal]);

    useEffect(() => {
        dispatch(fetchCurrency({}));
        dispatch(resetLoader([]));
        // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
    }, []);
    useEffect(() => {
        if (
            headerData.bpStatus === 'Booked' ||
            headerData.bpStatus === 'Draft_Booking' ||
            headerData.bpStatus === 'Ready_for_Booking'
        ) {
            dispatch(fetchEnrichBooking({ bpId: id }));
        }
        // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
    }, [headerData?.bpStatus]);

    useEffect(() => {
        dispatch(fetchNacAdditionalDetails({ bpId: id }));
        dispatch(fetchDeliveryAndContainerModes({ bpType: 'bcn', coloaderCode: headerData?.coLoaderCode }));
    }, [id]);

    useEffect(() => {
        if (isError) {
            error(additionalDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (additionalDetailsSuccess?.status === 200 || additionalDetailsSuccess?.status === 201) {
            if (additionalDetailsSuccess?.data?.type === 'rejected') {
                success(
                    `Booking Proposal ${
                        additionalDetailsSuccess?.data?.info?.bpNumber
                            ? additionalDetailsSuccess?.data?.info?.bpNumber
                            : ''
                    } rejected successfully`,
                    Position.TOP_RIGHT
                );
                dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(fetchNacAdditionalDetails({ bpId: id }));
                setTimeout(() => navigate('/booking_list'), 2000);
            } else if (additionalDetailsSuccess?.data?.type === 'assignedToAgent') {
                // success(
                //     `Booking Proposal ${
                //         additionalDetailsSuccess?.data?.info?.bpNumber
                //             ? additionalDetailsSuccess?.data?.info?.bpNumber
                //             : ''
                //     } assigned to agent`,
                //     Position.TOP_RIGHT
                // );
                // setTimeout(() => navigate('/booking_list'), 2000);
            } else if (additionalDetailsSuccess?.data?.type === 'approved') {
                const bpNumber = additionalDetailsSuccess?.data?.info?.bpNumber
                    ? additionalDetailsSuccess?.data?.info?.bpNumber
                    : '';
                const message =
                    profileType === 'client'
                        ? `Booking Proposal ${bpNumber} approved successfully`
                        : `Booking Proposal ${bpNumber} submitted for approval`;
                success(message, Position.TOP_RIGHT);
                // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(fetchNacAdditionalDetails({ bpId: id }));
                setTimeout(() => navigate('/booking_list'), 2000);
            } else if (
                additionalDetailsSuccess?.data?.type === 'add' ||
                additionalDetailsSuccess?.data?.type === 'edit'
            ) {
                const modifiedType = additionalDetailsSuccess?.data?.type === 'add' ? 'added' : 'updated';
                success(
                    `Additional Details ${
                        additionalDetailsSuccess?.data?.info?.bpNumber
                            ? additionalDetailsSuccess?.data?.info?.bpNumber
                            : ''
                    } ${modifiedType} successfully`,
                    Position.TOP_RIGHT
                );
                setAddDetailsModalView(false);
                updateAdditionalDetails(initialState);
                dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(fetchNacAdditionalDetails({ bpId: id }));
            }
            dispatch(clearFailure([]));
        }
    }, [isError, additionalDetailsSuccess, additionalDetailsError]);

    const submitBp = () => {
        const fromForwarderDraft =
            (headerData.bpStatus === 'Draft_Booking_Proposal' || headerData.bpStatus === 'Rejected_by_Forwarder') &&
            profileType === 'forwarder';
        dispatch(approveBp({ bpId: id, userName, profile: profileType, fromForwarderDraft }));
        propagateBcnData(id, true);
    };

    const rejectBp = () => {
        setRejectionModalView(true);
    };

    useEffect(() => {
        if (!rejectionModalView) {
            setReason('');
        }
    }, [rejectionModalView]);
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    summaryStats={summaryStats}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                    setIsShipmentOverride={setIsShipmentOverride}
                />
                {/* <div className="pageheading-holder">
                    <div className="back-btn-holder">
                        <Link to="/booking_list">
                            <button className="back-btn app-btn">
                                <svg className="svg-icon back-icon">
                                    <use href="#previousArrowIcon">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </button>
                        </Link>
                        <h2 className="page-heading">{headerData.bpNumber}</h2>
                    </div>
                </div> */}
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                />
                {((isLoading && !additionalDetailsCurrent?.contractType) || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <BookingProcessTab navData={navData} />
            </div>

            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper bo-additional-detail">
                <div className="main-wrapper">
                    {headerData.bcnType === 'CLD' && (
                        <div className="warning-text-holder">
                            <div className="warning-icon-text-wrapper">
                                Additional Details and Container Details are defined against the Parent Booking for
                                BCNs. Please Go to&nbsp;
                                <a
                                    href="#"
                                    onClick={() =>
                                        (window.location.href = `/bcnBookingList/${headerData.bcnParentPk}/additional-details`)
                                    }
                                >
                                    {headerData.bcnParentNumber}
                                </a>
                                &nbsp; to Review
                            </div>
                        </div>
                    )}
                    <BookingAdditionalInfo
                        rejection={{ modalView: rejectionModalView, setModalView: setRejectionModalView }}
                        reason={{ modalView: reasonModalView, setModalView: setReasonModalView }}
                        reasonState={{ reason, setReason }}
                        addDetails={{ modalView: addDetailsModalView, setModalView: setAddDetailsModalView }}
                        additionalDetailsData={{ additionalDetails, updateAdditionalDetails }}
                        contractDetails={{
                            modalView: contractDetailsModalView,
                            setModalView: setContractDetailsModalView,
                        }}
                        rejectBp={{
                            modalView: rejectBpModalView,
                            setModalView: setRejectBpModalView,
                        }}
                        reapproveBp={{
                            modalView: reapproveBpModalView,
                            setModalView: setReapproveBpModalView,
                        }}
                        cancel={{
                            modalView: displayCancel,
                            setModalView: setDisplayCancel,
                        }}
                        headerData={headerData}
                        userProfile={{ profileType, userName }}
                        bpId={id ? id : ''}
                        rejectedBpForwarder={{ rejectedBpForwarderModalView, setRejectedBpForwarderModalView }}
                        reasonRejection={{ reasonRejection, setReasonRejection }}
                        assignAgent={{ assignAgentModalView, setAssignAgentModalView }}
                        isShipmentOverride={isShipmentOverride}
                        setIsShipmentOverride={setIsShipmentOverride}
                    />
                </div>
            </div>
            {showBookingSideBar && <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar} />}
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {headerData.bcnType === 'CLD' && (
                        <Link
                            title="Previous"
                            className="app-btn app-btn-primary footer-btn"
                            to={`/bcnBookingList/${headerData.bcnParentPk}/additional-details`}
                        >
                            <span className="button-text footer-button-text">
                                Go to Parent ({headerData.bcnParentNumber})
                            </span>
                        </Link>
                    )}
                    {headerData.bcnType !== 'CLD' &&
                        (() => {
                            if (profileType === 'forwarder') {
                                return (
                                    <>
                                        {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <ShowForPermissions permission="create" type="bp">
                                            <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                                <span className="button-text footer-button-text">Save as Draft</span>
                                            </a>
                                        </ShowForPermissions>
                                    )} */}
                                        <Link
                                            title="Previous"
                                            className="app-btn app-btn-secondary footer-btn"
                                            to={`/bcnBookingList/${id}/SailingSelectionDetails`}
                                        >
                                            <span className="button-text footer-button-text">Previous</span>
                                        </Link>

                                        <Link
                                            title="Next"
                                            className="app-btn app-btn-primary footer-btn"
                                            to={`/bcnBookingList/${id}/container-allocation`}
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                        {initialContainerValues?.length > 0 &&
                                            (headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                                headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                (headerData.bpStatus === 'Rejected_by_Forwarder' &&
                                                    headerData.isForwarderSubmit) ||
                                                headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action') &&
                                            headerData?.bcnType !== 'CLD' && (
                                                <>
                                                    {headerData.bpStatus === 'Awaiting_Forwarder_Approval' && (
                                                        <ShowForPermissions permission="approve" type="bp">
                                                            <a
                                                                onClick={() => rejectBp()}
                                                                title="Reject Proposal"
                                                                className="app-btn app-btn-secondary footer-btn"
                                                            >
                                                                <span className="button-text footer-button-text">
                                                                    Reject Proposal
                                                                </span>
                                                            </a>
                                                        </ShowForPermissions>
                                                    )}
                                                    {headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action' &&
                                                        !headerData.isForwarderSubmit && (
                                                            <a
                                                                title="Assign to Agent"
                                                                className="app-btn app-btn-secondary footer-btn"
                                                                onClick={() => {
                                                                    setAssignAgentModalView(true);
                                                                    setReasonRejection(
                                                                        headerData.reason ? headerData.reason : ''
                                                                    );
                                                                }}
                                                            >
                                                                <span className="button-text footer-button-text">
                                                                    Assign to Agent
                                                                </span>
                                                            </a>
                                                        )}
                                                    <ShowForPermissions permission="approve" type="bp">
                                                        <button
                                                            onClick={() => submitBp()}
                                                            // title="Submit for Client Approval"
                                                            title={
                                                                (additionalDetailsCurrent?.contractType === 'FAK' &&
                                                                    headerData.bpStatus ===
                                                                        'Awaiting_Forwarder_Approval' &&
                                                                    !additionalDetailsCurrent?.sellQuotationName) ||
                                                                (additionalDetailsCurrent?.contractType === 'FAK' &&
                                                                    (headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                                        headerData.bpStatus ===
                                                                            'Rejected_by_Forwarder') &&
                                                                    !additionalDetailsCurrent?.totOriginCostCurrency) ||
                                                                headerData.gateInDateExpired ||
                                                                (headerData?.isTriangleShipment &&
                                                                    !additionalDetailsCurrent?.destinationAgentCode)
                                                                    ? 'Mandatory fields are missing or needs to be filled'
                                                                    : `Submit for${
                                                                          headerData.bpStatus ===
                                                                              'Draft_Booking_Proposal' ||
                                                                          headerData.bpStatus ===
                                                                              'Rejected_by_Forwarder'
                                                                              ? ' '
                                                                              : ' Client '
                                                                      }Approval`
                                                            }
                                                            className="app-btn app-btn-primary footer-btn"
                                                            disabled={
                                                                (additionalDetailsCurrent?.contractType === 'FAK' &&
                                                                    headerData.bpStatus ===
                                                                        'Awaiting_Forwarder_Approval' &&
                                                                    !additionalDetailsCurrent?.sellQuotationName) ||
                                                                (additionalDetailsCurrent?.contractType === 'FAK' &&
                                                                    (headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                                        headerData.bpStatus ===
                                                                            'Rejected_by_Forwarder') &&
                                                                    !additionalDetailsCurrent?.totOriginCostCurrency) ||
                                                                headerData.gateInDateExpired ||
                                                                (headerData?.isTriangleShipment &&
                                                                    !additionalDetailsCurrent?.destinationAgentCode)
                                                                    ? true
                                                                    : false
                                                            }
                                                        >
                                                            <span className="button-text footer-button-text">
                                                                Submit for
                                                                {headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                                headerData.bpStatus === 'Rejected_by_Forwarder'
                                                                    ? ' '
                                                                    : ' Client '}
                                                                Approval
                                                            </span>
                                                        </button>
                                                    </ShowForPermissions>
                                                </>
                                            )}
                                        {/* {(headerData.bpStatus === 'Draft_Booking' ||
                                            headerData.bpStatus === 'Booked' ||
                                            headerData.bpStatus === 'Ready_for_Booking') && (
                                            <>
                                                {enrichBooking?.bookingRefNum ? (
                                                    <Link
                                                        title="Next"
                                                        className="app-btn app-btn-primary footer-btn"
                                                        to={`/bcnBookingList/${id}/${navForChild}`}
                                                    >
                                                        <span className="button-text footer-button-text">Next</span>
                                                    </Link>
                                                ) : (
                                                    <button
                                                        title="Next"
                                                        className="app-btn app-btn-primary footer-btn"
                                                        disabled={true}
                                                    >
                                                        <span className="button-text footer-button-text">Next</span>
                                                    </button>
                                                )}
                                            </>
                                        )} */}
                                    </>
                                );
                            } else if (profileType === 'client') {
                                return (
                                    <>
                                        {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                            <span className="button-text footer-button-text">Save as Draft</span>
                                        </a>
                                    )} */}
                                        <Link
                                            title="Previous"
                                            className="app-btn app-btn-secondary footer-btn"
                                            to={`/bcnBookingList/${id}/SailingSelectionDetails`}
                                        >
                                            <span className="button-text footer-button-text">Previous</span>
                                        </Link>
                                        <Link
                                            title="Next"
                                            className="app-btn app-btn-primary footer-btn"
                                            to={`/bcnBookingList/${id}/container-allocation`}
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                        {headerData.bpStatus === 'Awaiting_Client_Approval' &&
                                            headerData?.bcnType !== 'CLD' && (
                                                <>
                                                    <a
                                                        onClick={() => rejectBp()}
                                                        title="Reject Proposal"
                                                        className="app-btn app-btn-secondary footer-btn"
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Reject Proposal
                                                        </span>
                                                    </a>
                                                    <button
                                                        onClick={() => submitBp()}
                                                        title="Approve Proposal"
                                                        disabled={headerData.gateInDateExpired ? true : false}
                                                        className="app-btn app-btn-primary footer-btn"
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Approve Proposal
                                                        </span>
                                                    </button>
                                                </>
                                            )}
                                        {/* {(headerData.bpStatus === 'Draft_Booking' ||
                                            headerData.bpStatus === 'Booked' ||
                                            headerData.bpStatus === 'Ready_for_Booking') && (
                                            <>
                                                {enrichBooking?.bookingRefNum ? (
                                                    <Link
                                                        title="Next"
                                                        className="app-btn app-btn-primary footer-btn"
                                                        to={`/bcnBookingList/${id}/${navForChild}`}
                                                    >
                                                        <span className="button-text footer-button-text">Next</span>
                                                    </Link>
                                                ) : (
                                                    <button
                                                        title="Next"
                                                        className="app-btn app-btn-primary footer-btn"
                                                        disabled={true}
                                                    >
                                                        <span className="button-text footer-button-text">Next</span>
                                                    </button>
                                                )}
                                            </>
                                        )} */}
                                    </>
                                );
                            } else {
                                return (
                                    <>
                                        {/* {profileType === 'agent' &&
                                        (bpStatus === 'Draft_Booking_Proposal' ||
                                            bpStatus === 'Rejected_by_Forwarder' ||
                                            bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                            // <>
                                            //     {bpStatus === 'Booked' || bpStatus === 'Cancelled' ? null : (
                                            <ShowForPermissions type="bp" permission="create">
                                                <button
                                                    title="Cancel Booking Proposal"
                                                    className="app-btn app-btn-secondary footer-btn"
                                                    onClick={() => {
                                                        setShowBookingCancelModal(true);
                                                    }}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        Cancel Booking Proposal
                                                    </span>
                                                </button>
                                            </ShowForPermissions>
                                            //     )}
                                            // </>
                                        )} */}
                                        {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <ShowForPermissions type="bp" permission="create">
                                            <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                                <span className="button-text footer-button-text">Save as Draft</span>
                                            </a>
                                        </ShowForPermissions>
                                    )} */}
                                        <Link
                                            title="Previous"
                                            className="app-btn app-btn-secondary footer-btn"
                                            to={`/bcnBookingList/${id}/SailingSelectionDetails`}
                                        >
                                            <span className="button-text footer-button-text">Previous</span>
                                        </Link>
                                        <Link
                                            title="Next"
                                            className="app-btn app-btn-primary footer-btn"
                                            to={`/bcnBookingList/${id}/container-allocation`}
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                        {(initialContainerValues?.length > 0 &&
                                            headerData?.bcnType !== 'CLD' &&
                                            additionalDetailsCurrent?.contractType === 'FAK' &&
                                            additionalDetailsCurrent?.totOriginCost &&
                                            additionalDetailsCurrent?.savedFakFileName &&
                                            (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action')) ||
                                        (initialContainerValues?.length > 0 &&
                                            headerData?.bcnType !== 'CLD' &&
                                            additionalDetailsCurrent?.contractType !== 'FAK' &&
                                            (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action')) ? (
                                            <a
                                                title="Submit for Approval"
                                                onClick={() => submitBp()}
                                                className="app-btn app-btn-primary footer-btn"
                                            >
                                                <span className="button-text footer-button-text">
                                                    Submit for Approval
                                                </span>
                                            </a>
                                        ) : null}
                                        {/* {profileType === 'agent' &&
                                        (bpStatus === 'Booked' ||
                                            bpStatus === 'Ready_for_Booking' ||
                                            bpStatus === 'Draft_Booking') && (
                                            <ShowForPermissions type="bp" permission="create">
                                                {agentIntegrationStatus !== true && (
                                                    <button
                                                        title="Cancel Booking"
                                                        className="app-btn app-btn-secondary footer-btn"
                                                        onClick={() => {
                                                            setShowBookingCancelModal(true);
                                                        }}
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Cancel Booking
                                                        </span>
                                                    </button>
                                                )}
                                            </ShowForPermissions>
                                        )} */}

                                        {/* {(headerData.bpStatus === 'Draft_Booking' ||
                                            headerData.bpStatus === 'Booked' ||
                                            headerData.bpStatus === 'Ready_for_Booking') && (
                                            <>
                                                {enrichBooking?.bookingRefNum ? (
                                                    <Link
                                                        title="Next"
                                                        className="app-btn app-btn-primary footer-btn"
                                                        to={`/bcnBookingList/${id}/${navForChild}`}
                                                    >
                                                        <span className="button-text footer-button-text">Next</span>
                                                    </Link>
                                                ) : (
                                                    <button
                                                        title="Next"
                                                        className="app-btn app-btn-primary footer-btn"
                                                        disabled={true}
                                                    >
                                                        <span className="button-text footer-button-text">Next</span>
                                                    </button>
                                                )}
                                            </>
                                        )} */}
                                    </>
                                );
                            }
                        })()}
                </div>
            </div>
            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            {displayModal && <UnavailableModel setdisplayModal={setdisplayModal} />}
        </div>
    );
};
export default Index;
