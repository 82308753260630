import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../hooks';
import { rejectBp, assignToAgent } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import '../../BookingProposal/BookingAdditionalInfo/reject-booking-proposal.scss';
import { useUserProfile } from 'src/hooks/useUserProfile';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setRejectedBpForwarderModalView?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    userProfile: { profileType: string; userName: string };
    header: { bpNumber: string; clientName: string; gateInDateExpired: boolean };
    reason: string;
    setReason: (value: string | ((prevVar: string) => string)) => void;
    headerText: string;
    gateInDateExpired: boolean;
}

const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    header,
    userProfile,
    reason,
    setReason,
    headerText,
    setRejectedBpForwarderModalView,
    gateInDateExpired,
}) => {
    const { id } = useParams();
    const { fullName } = useUserProfile();
    const [isError, setIsError] = useState(false);

    if (header.gateInDateExpired) {
        setReason(
            'Approval expired. The selected schedule is no longer available, Please select a different schedule to continue Booking.'
        );
    }

    const dispatch = useAppDispatch();
    const rejectBookingProposal = () => {
        if (!reason?.trim()) {
            setIsError(true);
            return;
        }
        if (headerText === 'Assign to Agent') {
            dispatch(
                assignToAgent({
                    userName: userProfile.userName,
                    profile: userProfile.profileType,
                    reason,
                    bpId: id,
                    fullName,
                })
            );
            setRejectedBpForwarderModalView && setRejectedBpForwarderModalView(false);
        } else {
            dispatch(
                rejectBp({
                    bpId: id,
                    reason: reason,
                    profile: userProfile.profileType,
                    userName: userProfile.userName,
                    gateInDateExpired,
                    fullName,
                })
            );
        }
        setTimeout(() => {
            setModalView(false);
            setReason('');
        }, 1000);
        setTimeout(() => {
            dispatch(fetchBpHeaderData({ bpId: id, profileType: userProfile.profileType }));
        }, 2000);
    };
    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder medium-popup reject-booking-modal">
                        <div className="modal-header">
                            <div className="title">{headerText}</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Booking Proposal No.</h6>
                                        <div className="description-type-1">{header.bpNumber}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Client Name</h6>
                                        <div className="description-type-1">{header.clientName}</div>
                                    </div>
                                </div>
                                <form id="productForm" action="" className="modal-form">
                                    <div className=" modal-input-holder">
                                        <label htmlFor="name" className="modal-input-label">
                                            Reason<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder">
                                            <textarea
                                                name="reason"
                                                className="input-text"
                                                placeholder="Enter the reason to Reject Booking Proposal"
                                                value={reason}
                                                onChange={(e) => {
                                                    setReason(e.target.value);
                                                    if (isError && e.target.value?.trim()) {
                                                        setIsError(false);
                                                    }
                                                }}
                                                maxLength={250}
                                                disabled={header.gateInDateExpired}
                                            ></textarea>
                                        </div>
                                        {isError ? (
                                            <div className="error-text">
                                                Please provide a reason for Rejecting Booking Proposal
                                            </div>
                                        ) : null}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => setModalView(false)}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Back"
                            >
                                <span className="button-text footer-button-text">Back</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title={headerText === 'Assign to Agent' ? 'Submit' : 'Reject Proposal'}
                                onClick={() => rejectBookingProposal()}
                            >
                                <span className="button-text footer-button-text">
                                    {headerText === 'Assign to Agent' ? 'Submit' : 'Reject Proposal'}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
