/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Configuration, LogLevel } from '@azure/msal-browser';
export const b2cPolicies = {
    authorities: {
        signUpSignIn: {
            authority: process.env.REACT_APP_SIGN_IN_POLICY!,
        },
        forgotPassword: {
            authority: process.env.REACT_APP_FORGOT_PASSWORD_POLICY!,
        },
        passwordChange: {
            authority: process.env.REACT_APP_PASSWORD_CHANGE_POLICY!,
        },
    },
    authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN!,
};

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_CLIENT_ID!,
        authority: b2cPolicies.authorities.signUpSignIn.authority,
        knownAuthorities: [b2cPolicies.authorityDomain],
        redirectUri: '/',
        postLogoutRedirectUri: '/',
        navigateToLoginRequestUrl: true,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: false,
    },
    system: {
        loggerOptions: {
            loggerCallback: (level: LogLevel, message: string, containsPii: boolean) => {
                if (containsPii) {
                    return;
                }
                if (process.env.NODE_ENV !== 'development') {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
        tokenRenewalOffsetSeconds: 60,
    },
};

export const loginRequest = {
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    scopes: [process.env.REACT_APP_SCOPE!],
    forceRefresh: false,
    redirectStartPage: '/',
};

export const forgotPasswordRequest = {
    authority: b2cPolicies.authorities.forgotPassword.authority,
    scopes: [process.env.REACT_APP_SCOPE!],
    forceRefresh: false,
};
export const passwordChangeRequest = {
    authority: b2cPolicies.authorities.passwordChange.authority,
    scopes: [process.env.REACT_APP_SCOPE!],
    forceRefresh: false,
};

export interface CustomPolicyRequest {
    authority: string;
    scopes: string[];
    forceRefresh: boolean;
}
