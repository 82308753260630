import { createSlice } from '@reduxjs/toolkit';
const indexVal = localStorage.getItem('roleIndex');
/* eslint-disable no-undefined */

export const permissionData = createSlice({
    name: 'permissionList',
    initialState: {
        permissions: [],
        permissionsWithoutRole: [],
        currentIndex: 0,
    },
    reducers: {
        fetchPermissions: (_state, _action) => {
            // console.log(action);
        },
        setCurrentIndex: (state, action) => {
            state.currentIndex = action.payload;
        },
        savePermissions: (state, action) => {
            state.permissions =
                action.payload?.roles[indexVal !== null ? (indexVal as any) : state.currentIndex]?.permissions;
            state.permissionsWithoutRole = action.payload?.roles;
        },
    },
});

export const { fetchPermissions, savePermissions, setCurrentIndex } = permissionData.actions;
export default permissionData.reducer;
