import React, { useEffect } from 'react';
import {
    fetchActivityList,
    resetDataWhileChangingTimePeriodFilter,
    resetWorkTrendsError,
    selectWorkTrendsError,
} from 'src/store/reducers/dashboard/workTrendsReducer';
import './activityList.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { getOrganisationData } from 'src/utils';
import moment from 'moment';
import { FilterList } from 'src/pages/Dashboard/WorkTrends';
import { useAppSelector } from 'src/hooks';
import { Position, error } from 'src/utils/toast';
const Index: React.FC = () => {
    const { progressSelectedIndex, workTrends, currentFilter, selectedActivityDetails, page, perPageCount, userIds } =
        useSelector((state: RootState) => state.workTrendsData);
    const { profileType, userName } = useUserProfile();
    const dispatch = useDispatch();
    const workTrendsError = useAppSelector(selectWorkTrendsError);
    const endDate =
        workTrends[progressSelectedIndex]?.label?.split('to')?.[1]?.trim() || workTrends[progressSelectedIndex]?.label;
    const startDate =
        workTrends[progressSelectedIndex]?.label?.split('to')?.[0]?.trim() || workTrends[progressSelectedIndex]?.label;
    const listOfUserOrganisations = getOrganisationData();
    const payload = {
        userIds,
        loggedInUserName: userName,
        filter: currentFilter?.toLowerCase(),
        activityType: selectedActivityDetails?.key,
        date:
            currentFilter === FilterList.Months
                ? endDate
                : currentFilter === FilterList.Weeks
                ? `${moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')} to ${moment(endDate, 'DD-MM-YYYY').format(
                      'YYYY-MM-DD'
                  )}`
                : moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        profile: profileType, //forwarder, client, agent
        limit: perPageCount,
        page,
        currentPage: page,
        perPageCount,
        ...listOfUserOrganisations,
        activityData: { name: '', value: -1, key: '', service: '' },
    };

    useEffect(() => {
        const { name, value, key, service } = selectedActivityDetails;
        if (value > -1) {
            dispatch(resetDataWhileChangingTimePeriodFilter());
            payload.activityType = key;
            dispatch(fetchActivityList({ ...payload, activityData: { name, value, key, service } }));
        }
    }, [currentFilter, perPageCount]);

    useEffect(() => {
        if (workTrendsError) {
            error(workTrendsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetWorkTrendsError({}));
        }
    }, [workTrendsError]);

    return (
        <>
            <div className="activity-header">
                <h4 className="activity-title">
                    Activities
                    {workTrends[progressSelectedIndex]?.yAxis ? (
                        <span className="activity-count md">{workTrends[progressSelectedIndex]?.yAxis}</span>
                    ) : null}
                </h4>
            </div>
            {!!workTrends[progressSelectedIndex]?.activities?.length ? (
                <div className="activity-grid customscrollbar">
                    {workTrends &&
                        workTrends[progressSelectedIndex]?.activities?.map(
                            (
                                {
                                    name,
                                    value,
                                    key,
                                    service,
                                }: { name: string; value: number; key: string; service: string },
                                index: number
                            ) => (
                                <div
                                    onClick={() => {
                                        payload.activityType = key;
                                        dispatch(
                                            fetchActivityList({
                                                ...payload,
                                                activityData: { name, value, key, service },
                                            })
                                        );
                                    }}
                                    className="activty-list-item"
                                    key={`activity_${index}`}
                                >
                                    <a className="activity-list-label">
                                        <span>{name}</span>
                                        <span className="activity-count">{value}</span>
                                    </a>
                                </div>
                            )
                        )}
                </div>
            ) : (
                <div className="no-graphdata-wrapper">
                    <div className="no-graphdata-content">
                        <div className="no-data-svg">
                            <svg className="svg-icon nodata-icon">
                                <use xlinkHref="#gridNoContentIcon">
                                    <title>No Data</title>
                                </use>
                            </svg>
                        </div>
                        <p className="no-content-message">No works were performed for this period</p>
                    </div>
                </div>
            )}
        </>
    );
};
export default Index;
