// import './style.css';
import '../../../assets/scss/components/_modal.scss';

interface CbmUnavailableModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    sku: string;
    formik: any;
    setModalViewSku: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<CbmUnavailableModalProps> = ({ display, displayFunction, sku, formik, setModalViewSku }) => {
    return (
        <>
            {display ? (
                <div className="app-modal container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">CBM Unavailable</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => {
                                    displayFunction(false);
                                    formik.setFieldValue('skuId', '');
                                    formik.setFieldValue('sku', '');
                                    formik.setFieldValue('description', '');
                                }}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                SKU <span style={{ fontWeight: 700 }}>{sku}</span> does not have an{' '}
                                <span style={{ fontWeight: 700 }}>EACH</span> CBM value in its Pack Details. Update SKU
                                details in the Product Master to proceed with PO Line addition
                            </p>
                        </div>
                        <div className="modal-footer">
                            {/* <button className="app-btn app-btn-secondary modal-btn" onClick={() => onCancel()}>
                        <span className="button-text vendor-button-text">No</span>
                    </button> */}
                            <button
                                className="app-btn app-btn-primary modal-btn"
                                onClick={() => {
                                    setModalViewSku(true);
                                    displayFunction(false);
                                    formik.setFieldValue('skuId', '');
                                    formik.setFieldValue('sku', '');
                                    formik.setFieldValue('description', '');
                                }}
                            >
                                <span className="button-text vendor-button-text">Go to Product Master</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
