/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react';
import '../../../Profiles/Clients/BasicDetails/basic-details.scss';
import '../../../Profiles/Clients/ProductMaster/product-master-edit.scss';
import '../../QuoteItem/Details/quote-item-details.scss';
import QuoteItemDropdownCard from '../../QuoteItem/Details/QuoteItemDropdownCard';
import QuoteItemSingleItemCard from '../../QuoteItem/Details/QuoteItemSingleItemCard';
import QuoteItemSinglePortCard from '../../QuoteItem/Details/QuoteItemSinglePortCard';
import { getPortMatching, getProviders, getPortGroupDetails, savePortGroupDetails } from 'src/services/apis/quoteAPi';
import { useParams } from 'react-router-dom';
import { success, error, Position } from 'src/utils/toast';

const PortGroupDetail: React.FC = () => {
    const { Id } = useParams();
    const portGroupInitialData = {
        portMatching: [],
        providers: [],
    };
    const portGroupLoadingState = {
        portMatching: true,
        providers: true,
    };

    const [portGroupData, setPortGroupData] = useState<any>(portGroupInitialData);
    const [portGroupLoading, setPortGroupLoading] = useState<any>(portGroupLoadingState);
    const [loading, setLoading] = useState(true);
    const [saveTriggered, setSaveTriggered] = useState(false);

    const [portData, setPortData] = useState<any>();
    const [providerData, setProviderData] = useState<any>();

    const getConvertedData = async (portGroupDetailData: any, convertData: any, value: string) => {
        let data = [];
        if (value === 'Port') {
            for (let index = 0; index < portGroupDetailData?.JA_PG_POs.length; index++) {
                let found = convertData?.find(
                    (res: any) => res?.value === portGroupDetailData?.JA_PG_POs[index]?.JA_PortCode
                );
                if (!found) {
                    const searchPort = await getPortMatching(portGroupDetailData?.JA_PG_POs[index]?.JA_PortCode);
                    const filteredData = searchPort?.find(
                        (res: any) => res?.value === portGroupDetailData?.JA_PG_POs[index]?.JA_PortCode
                    );
                    found = filteredData;
                }
                data.push(found);
            }
            return data;
        } else {
            for (let index = 0; index < portGroupDetailData?.JB_PG_ORs.length; index++) {
                const searchKey = portGroupDetailData?.JB_PG_ORs[index]?.JB_OR_OR_Organisation?.OR_OrgCode;
                const found = convertData?.find(
                    (res: any) =>
                        res?.value === portGroupDetailData?.JB_PG_ORs[index]?.JB_OR_OR_Organisation?.OR_OrgCode
                );
                if (!found && searchKey) {
                    const provider = await getProviders(searchKey);
                    const filteredData = provider?.find((res: any) => res?.value === searchKey);
                    data.push(filteredData);
                } else {
                    data.push(found);
                }
            }
            return data;
        }
    };

    const getPortGroupItemData = async () => {
        const [port, provider] = await Promise.all([getPortMatching(), getProviders()]);
        setPortGroupLoading((prev: any) => ({ ...prev, portMatching: false }));
        setPortData(port);
        setPortGroupLoading((prev: any) => ({ ...prev, providers: false }));
        setProviderData(provider);

        if (Id && Id !== 'add') {
            const portGroupDetailData = await getPortGroupDetails(Id);
            setPortGroupData({
                ...portGroupData,
                itemCode: portGroupDetailData?.PG_Code,
                itemDescription: portGroupDetailData?.PG_Name,
                portMatching: await getConvertedData(portGroupDetailData, port, 'Port'),
                providers: await getConvertedData(portGroupDetailData, provider, 'Organisation'),
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        getPortGroupItemData();
    }, []);

    const buttonStatus = () => {
        return portGroupData?.itemCode &&
            portGroupData?.itemDescription &&
            portGroupData?.portMatching?.length &&
            portGroupData?.providers?.length
            ? false
            : true;
    };

    const submitPortGroup = async () => {
        if (buttonStatus()) {
            setSaveTriggered(true);
            return false;
        }
        setLoading(true);
        let payload: any = {
            PG_PK: Id === 'add' ? null : Id,
            PG_Code: portGroupData?.itemCode,
            PG_Name: portGroupData?.itemDescription,
            JA_PG_POs: [],
            JB_PG_ORs: [],
        };

        if (portGroupData?.portMatching?.length) {
            for (let index = 0; index < portGroupData?.portMatching.length; index++) {
                payload?.JA_PG_POs?.push({ JA_PortCode: portGroupData?.portMatching[index]?.value });
            }
        }
        if (portGroupData?.providers?.length) {
            for (let index = 0; index < portGroupData?.providers.length; index++) {
                payload?.JB_PG_ORs?.push({ JB_OR: portGroupData?.providers[index]?.extraValue });
            }
        }
        try {
            setSaveTriggered(false);
            const data = await savePortGroupDetails(payload);
            success(`Port Group submitted successfully`, Position.TOP_RIGHT);
            setTimeout(() => {
                setLoading(false);
                if (Id === 'add') {
                    location.href = '/qms/config/portgroup/' + data?.data?.[0]?.PG_PK;
                } else {
                    location.href = '/qms/config/portgroup';
                }
            }, 2000);
        } catch (err: any) {
            setLoading(false);
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    return (
        <div className="main-wrapper quote-item-detail">
            <div className={`app-loader ${loading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="main-content-holder no-main-grid-border booking-grid port-item-detail">
                <h2 className="page-heading">Port Group Detail</h2>
                <div className={`content-section main-wrapper basic-detail-wrapper`}>
                    <div className="card details-box custom">
                        <div className="row-1">
                            <QuoteItemSingleItemCard
                                type={'port'}
                                title={'Item'}
                                keyName={'item'}
                                quoteData={portGroupData}
                                setQuoteData={setPortGroupData}
                                saveTriggered={saveTriggered}
                            />
                        </div>
                        <div className="row-2">
                            <QuoteItemDropdownCard
                                title={'Providers'}
                                keyName={'providers'}
                                quoteData={portGroupData}
                                setQuoteData={setPortGroupData}
                                quoteItemData={providerData}
                                loading={portGroupLoading}
                                isRequired={true}
                                saveTriggered={saveTriggered}
                            />
                        </div>

                        <div className="row-3">
                            <QuoteItemSinglePortCard
                                title={'Port'}
                                keyName={'portMatching'}
                                quoteData={portGroupData}
                                setQuoteData={setPortGroupData}
                                quoteItemData={portData}
                                setQuoteItemData={setPortData}
                                loading={portGroupLoading}
                                saveTriggered={saveTriggered}
                                isRequired={true}
                            />
                        </div>
                        <div className="quote-footer-btn">
                            <button
                                onClick={() => (location.href = '/qms/config/portgroup')}
                                className="app-btn app-btn-secondary footer-btn"
                                title="Cancel"
                                type="button"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                onClick={() => submitPortGroup()}
                                disabled={loading}
                                className="app-btn app-btn-primary footer-btn"
                                title={loading ? 'Submitting..' : 'Submit'}
                                type="button"
                            >
                                <span className="button-text footer-button-text">
                                    {loading ? 'Submitting..' : 'Submit'}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PortGroupDetail;
