import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    fetchClient,
    fetchClientSuccess,
    fetchClientFailed,
    fetchVendor,
    fetchVendorSuccess,
    fetchVendorFailed,
    savePoSuccess,
    savePoFailed,
    savePo,
    fetchHeader,
    fetchHeaderSuccess,
    fetchHeaderFailed,
    fetchCancelReason,
    fetchPoAttachment,
    uploadCixDocs,
} from '../../reducers/purchaseOrder/addPoReducer';
import {
    getClientData,
    getVendorData,
    getHeaderData,
    savePoData,
    getCancelledReasonData,
    getPurchaseOrderAttachment,
    savePurchaseOrderAttachment,
} from '../../../services/apis/purchaseOrder/addPoReducerApi';

function* getClient({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getClientData, payload);
        yield put({ type: fetchClientSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchClientFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* getVendor({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getVendorData, payload);
        yield put({ type: fetchVendorSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchVendorFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* createPo({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(savePoData, payload);
        yield put({ type: savePoSuccess, payload: response });
    } catch (error) {
        yield put({ type: savePoFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* getHeader({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getHeaderData, payload);
        yield put({ type: fetchHeaderSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchHeaderFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* getcancelledReason({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getCancelledReasonData, payload);
        payload['cancelledReason'] = response;
        yield put({ type: fetchCancelReason, payload });
    } catch (error) {
        console.log('error', error);
    }
}

function* getPoAttachment({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getPurchaseOrderAttachment, payload);
        yield put({ type: fetchPoAttachment, payload: response });
    } catch (error) {
        console.log('error', error);
    }
}

function* uploadPoAttachment({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(savePurchaseOrderAttachment, payload);
        yield put({ type: uploadCixDocs, payload: response });
    } catch (error) {
        console.log('error', error);
    }
}

function* addPoSaga() {
    yield takeEvery(fetchClient, getClient);
    yield takeEvery(fetchVendor, getVendor);
    yield takeEvery(savePo, createPo);
    yield takeEvery(fetchHeader, getHeader);
    yield takeEvery(fetchCancelReason, getcancelledReason);
    yield takeEvery(fetchPoAttachment, getPoAttachment);
    yield takeEvery(uploadCixDocs, uploadPoAttachment);
}

export default addPoSaga;
