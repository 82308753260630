import React, { useEffect, useState } from 'react';
import './tracking.scss';
import TrackingInsight from './Insight';
import LifecycleSearch from './LifecycleSearch';
// import { FavoriteData } from '../Dashboard/Insights/constants';
import TrackingPerformance from './PerformanceMatrix';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchInsightsList,
    fetchInsightsTilesList,
    resetGraph,
    selectInsightsTiles,
} from 'src/store/reducers/tracking/trackingInsightsReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { trackingPerformanceMatrixPermissions } from 'src/utils/kendoGrid/performanceMatrix';
import { useLocation } from 'react-router-dom';
const Index: React.FC = () => {
    const { profileType, userName, roleName } = useUserProfile();
    const location = useLocation() as any;
    const dispatch = useAppDispatch();

    const [trackingTab, setTrackingTab] = useState('Insights');
    const setTrackingView = (arg: string) => {
        setTrackingTab(arg);
    };

    const insightsTiles = useAppSelector(selectInsightsTiles);

    useEffect(() => {
        document.title = 'HUB>Tracking';
        if (location?.state) {
            setTrackingView(location.state.currentTab);
        }
    }, [location]);

    useEffect(() => {
        dispatch(fetchInsightsTilesList({ userName, roleName, profile: profileType }));
        dispatch(resetGraph());
    }, []);

    useEffect(() => {
        if (insightsTiles?.length) {
            dispatch(fetchInsightsList({ insightsTiles, userName, profile: profileType }));
        }
    }, [insightsTiles.length]);

    const showTab = () => {
        return trackingPerformanceMatrixPermissions.includes(roleName?.toLowerCase());
    };

    return (
        <div className="main-wrapper container tracking-module">
            <div className="main-header-content-holder large-header no-background">
                <h2 className="page-heading">Tracking</h2>
                <div className="tab-nav-holder">
                    <a
                        // href="#"
                        className={`tab-nav ${trackingTab === 'Insights' ? 'active' : ''}`}
                        onClick={() => setTrackingView('Insights')}
                    >
                        Insights
                    </a>
                    <a
                        className={`tab-nav ${trackingTab === 'Lifecycle Search' ? 'active' : ''}`}
                        onClick={() => setTrackingView('Lifecycle Search')}
                    >
                        Lifecycle Search
                    </a>
                    <a
                        // href="#"
                        className={`tab-nav ${trackingTab === 'Statistics' ? 'active' : ''}`}
                        onClick={() => setTrackingView('Statistics')}
                    >
                        Statistics
                    </a>
                    {showTab() && (
                        <a
                            // href="#"
                            className={`tab-nav ${trackingTab === 'Performance Matrix' ? 'active' : ''}`}
                            onClick={() => setTrackingView('Performance Matrix')}
                        >
                            Performance Matrix
                        </a>
                    )}
                    <a
                        // href="#"
                        className={`tab-nav ${trackingTab === 'Inventory' ? 'active' : ''}`}
                        onClick={() => setTrackingView('Inventory')}
                    >
                        Inventory
                    </a>
                </div>
            </div>
            <div className="main-content-holder no-main-grid-border tracking-memu-items">
                {trackingTab === 'Insights' && <TrackingInsight />}
                {trackingTab === 'Lifecycle Search' && <LifecycleSearch></LifecycleSearch>}
                {trackingTab === 'Statistics' && <span>Statistics</span>}
                {trackingTab === 'Performance Matrix' && <TrackingPerformance></TrackingPerformance>}
                {trackingTab === 'Inventory' && <span>Inventory</span>}
            </div>
        </div>
    );
};
export default Index;
