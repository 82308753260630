import { PublicClientApplication } from '@azure/msal-browser';
import moment from 'moment';
import { sleep } from '.';
import { logoutUserApi } from '.././services/apis/authApi';
import { error, Position } from '.././utils/toast';

const logOut = async (msalInstance: PublicClientApplication) => {
    await sleep(1000);
    error('User session expired', Position.TOP_RIGHT);
    await sleep(1000);
    Promise.resolve(logoutUserApi(msalInstance));
};

export const checkUserEvent = (msalInstance: PublicClientApplication) => {
    // msalInstance.addEventCallback((event: EventMessage) => {
    // if (event.eventType === EventType.INITIALIZE_END) {
    logOut(msalInstance)
        .then((resp) => {
            return resp;
        })
        .catch((err) => console.error(err));
    // }
    // });
};

export const sessionExpiry = async (msalInstance: PublicClientApplication) => {
    const now = moment().unix();
    const lastApiRequest = localStorage.getItem('lastApiRequest');
    if (lastApiRequest) {
        const lastFetched = +lastApiRequest;
        const duration = moment.duration(moment.unix(now).diff(moment.unix(lastFetched)));
        const asHours = duration.asHours();
        if (asHours > 8) {
            checkUserEvent(msalInstance);
        }
    }
};
