import { get, post } from '../../../HttpMethods';

export interface AvailablePoData {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    createdDate: string;
    clientName: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
    createdOn: string;
    totKGS: number;
    totCBM: number;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

export interface AvailablePoResponse {
    poListingDtos: AvailablePoData[];
}

export interface CreateBpResponse {
    success: boolean;
    info: {
        bpId: string;
        bpNumber: string;
        totalCBM: 46.0;
        totalKgs: 6.0;
        client: string;
        originPort: string;
        destinationPort: string;
        goodsReadyDate: string[];
        purchaseOrders: AvailablePoData[];
    };
}

export const contractCheck = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/contractcheck?clientCode=${payload.clientCode}&bpId=${payload.bpid}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const sailingData = async (payload: any) => {
    try {
        const res = await post(
            `api/bkg/bp/sailing/list?sortOrder=ASC&sortColumn=${payload.sortColumn}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const sailingCount = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/sailing/count', payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const fakData = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/sailing/fak', payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const sailingAddToBp = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/sailing', payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const selectedSalilingData = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/sailing/schedule?bpId=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const removeSailingData = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/sailing/changeschedule?bpId=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getPorts = async (payload: any) => {
    try {
        const res = await get(
            `api/mgt/shippinglinecontract/portlist${payload?.portType ? `?portType=${payload?.portType}` : ''}`,
            null
        );

        const result = res?.info.map((res: any) => ({ value: res.sp_portcode, label: res.sp_portname }));
        return result;
    } catch (err) {
        return err;
    }
};
