/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ExportButton from '../../../components/common/ExportButton';
import ProfileListing from '../../../components/common/ProfileListing';
import TableFooter from '../../../components/common/Table/tableFooter';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { exportAoList } from '../../../services/apis/allocationOrder/allocationOrderListApi';
import ShowForPermissions from '../../../ShowForPermissions';
import { RootState } from '../../../store/reducers';
import {
    fetchAoData,
    fetchAoFilterData,
    fetchAoStatusCount,
    setPageCount,
    setFilter,
    setSelectedDropDown,
    resetPage,
} from '../../../store/reducers/allocationOrder/allocationOrderListReducer';
import { aoConfirmed, aoSubmited } from '../../../store/reducers/allocationOrder/basicDetails';
import { fetchPermissions } from '../../../store/reducers/permissionReducer';
import { activeAos, activeStatus, aoHeaders, clearFilterText, statusOfAos } from '../../../utils/constants';
import { Position, success } from '../../../utils/toast';
import { getOrganisationData } from '../../../utils';
import './allocation-order.scss';
import ClickAwayListener from 'react-click-away-listener';
const Index: React.FC = () => {
    const dispatch = useDispatch();
    const {
        allocationOrders,
        filterValues,
        currentPage,
        perPageCount,
        totalPages,
        columnStatus,
        totalItems,
        isLoading,
        isLoadingAO,
        statusCount,
        filter,
        selectedDropDown,
    } = useSelector((state: RootState) => state.allocationOrderList);
    // const { permissions } = useSelector((state: RootState) => state.permissionData);
    const [selectedHeader, setSelectedHeader] = useState(aoHeaders.createdDate);
    const { aoStatus, aoName, aoConfirmStatus } = useSelector((state: RootState) => state.allocationOrder);

    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    // eslint-disable-next-line
    const [expandId, setexpandId] = useState<any | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    // const [filter, setFilter] = useState<any>({ aoStatus: 'Active' });
    const userDetail = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = userDetail?.user?.us_UserName;
    // const [selectedDropDown, setSelectedDropDown] = useState({ text: 'Active AOs', count: 0 });
    const { profileType, isBlp } = useUserProfile();
    const firstUpdate = useRef(true);
    const organisationData = getOrganisationData();
    const [activeCount, setActiveCount] = useState(0);
    const location = useLocation();
    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter },
        profileType,
    };
    const sortPayload = {
        sortOrder: currentOrder ? 'DESC' : 'ASC',
        sortColumn: columnStatus,
    };
    const payloadForFilter = {
        ...payload,
        data: { ...organisationData, ...{ aoStatus: filter.aoStatus }, ...filter },
    };

    useEffect(() => {
        const sum = getTotalNumberOfAOs();
        setActiveCount(sum);
    }, [statusCount]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (searchTerm?.trim()?.length > 2) {
            if (
                filter?.aoStatus === 'Cancelled' ||
                filter?.aoStatus === 'Completed' ||
                filter?.aoStatus === activeStatus
            ) {
                dispatch(setFilter({ ...filter, aoSearch: searchTerm }));
            } else {
                dispatch(
                    setSelectedDropDown({
                        text: activeAos,
                        count: activeCount,
                    })
                );
                dispatch(setFilter({ ...filter, aoSearch: searchTerm, aoStatus: activeStatus }));
            }
        } else if (searchTerm?.trim()?.length === 0) {
            setSearchTerm('');
            if ((filter as any)?.aoSearch) {
                const filterObj = JSON.parse(JSON.stringify(filter));
                delete filterObj.aoSearch;
                dispatch(setFilter(filterObj));
            }
        }
    }, [searchTerm]);

    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userType = '';
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            userType = roles[roleIndex].profile?.toLowerCase();
        }
    }

    useEffect(() => {
        dispatch(fetchAoData(payload));
        dispatch(fetchAoFilterData(payloadForFilter));
        dispatch(fetchAoStatusCount(payload));
        dispatch(fetchPermissions(username));
    }, [dispatch, currentPage, setSelectedHeader, perPageCount, currentOrder, filter]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
    }, [totalPages]);

    useEffect(() => {
        if (aoStatus || aoConfirmStatus) {
            if (aoConfirmStatus) {
                success(`${aoName} is submitted successfully`, Position.TOP_RIGHT);
            } else {
                success(`${aoName} has been released to BLP and now is ready for booking`, Position.TOP_RIGHT);
            }
            dispatch(fetchAoData(payload));
            dispatch(fetchAoFilterData(payloadForFilter));
            dispatch(fetchAoStatusCount(payload));
            dispatch(fetchPermissions(username));
            dispatch(aoSubmited({ status: false, name: '' }));
            dispatch(aoConfirmed(false));
        }
    }, [aoStatus, aoConfirmStatus]);

    useEffect(() => {
        if (aoConfirmStatus) {
            dispatch(fetchAoData(payload));
            dispatch(fetchAoFilterData(payloadForFilter));
            dispatch(fetchAoStatusCount(payload));
            dispatch(fetchPermissions(username));
            dispatch(aoConfirmed(false));
        }
    }, [aoConfirmStatus]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        setselectedFilter(data);
    };

    const setExpandIdOnClick = (id: string) => {
        setexpandId(id);
    };

    const filterToggle = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const onSort = (sortKey: string) => {
        setSelectedHeader(sortKey);
        setcurrentOrder(!currentOrder);
    };

    const getTotalNumberOfAOs = () => {
        const status = statusCount as any;
        if (status) {
            let sum = (Object.values(status) as number[])?.reduce((prev: number, current: number) => prev + current, 0);
            if (sum) {
                sum = sum - ((status['Cancelled'] || 0) + (status['Completed'] || 0));
            }
            return sum;
        } else {
            return 0;
        }
    };

    const onSearchClose = () => {
        setSearchTerm('');
        if ((filter as any)?.aoSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.aoSearch;
            dispatch(setFilter(filterObj));
        }
    };

    const getDropDown = () => {
        const finalArray = [];
        for (const key of statusOfAos) {
            if (userType !== 'agent' || key !== 'Draft') {
                if (statusCount && Object.prototype.hasOwnProperty.call(statusCount, key)) {
                    finalArray.push(
                        <a
                            className={`menu-item app-btn ${selectedDropDown.text === key ? ' active' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                    setSelectedDropDown({
                                        text: key,
                                        count: statusCount[key as keyof typeof statusCount],
                                    })
                                );
                                setDropDownOpen(false);
                                dispatch(setFilter({ ...filter, aoStatus: key }));
                            }}
                        >
                            <div className="content-heading-holder">
                                <span className="content-heading">{key}</span>
                                <span className=" modal-toast">
                                    <div className="toast-message ">{statusCount[key as keyof typeof statusCount]}</div>
                                </span>
                            </div>
                        </a>
                    );
                }
            }
        }
        return finalArray;
    };

    const closeAllDropDown = () => {
        setDropDownOpen(false);
        setToggleDropdown(false);
        setShowExportDropDown(false);
        setPageCountDisplay(false);
    };
    useLayoutEffect(() => {
        if ((location as any)?.state?.reload !== true) {
            resetFilters();
        }
    }, [location]);
    const resetFilters = () => {
        const initialFilter = { aoStatus: activeStatus };
        const initialDropDown = {
            text: activeAos,
            count: 0,
        };
        setSearchTerm('');
        dispatch(setFilter(initialFilter));
        dispatch(setSelectedDropDown(initialDropDown));
    };

    const clearSearchFilter = () => {
        if ((filter as any)?.aoSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.aoSearch;
            dispatch(setFilter(filterObj));
        }
        dispatch(fetchAoStatusCount(payload));
    };

    return (
        <div
            className="main-wrapper"
            onClick={() => {
                closeAllDropDown();
            }}
        >
            <div className="main-content-holder no-main-grid-border allocation-grid">
                <h2 className="page-heading">Allocation Orders</h2>
                <div className="grid-section">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="search-field-allocation-grid">
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search by AO Number"
                                        type="text"
                                        value={searchTerm}
                                        className="search-input input-text"
                                        onChange={(e) => {
                                            e.preventDefault();
                                            setSearchTerm(e.target.value);
                                        }}
                                    ></input>
                                    <div
                                        className={`search-toolip ${
                                            searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                        }`}
                                    >
                                        Enter atleast 3 characters to search
                                    </div>
                                    <svg className="svg-icon search-icon">
                                        <use href="#searchIcon"></use>
                                    </svg>
                                    <button
                                        className="app-btn text-close-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSearchClose();
                                        }}
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                                <ClickAwayListener onClickAway={() => setDropDownOpen(false)}>
                                    <div
                                        className={`dropdown-wrap ${
                                            dropDownOpen ? 'dropdown-open' : ''
                                        } download-dropdown search-toast-content-holder`}
                                    >
                                        <button
                                            className="app-btn app-btn-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDropDownOpen(!dropDownOpen);
                                            }}
                                        >
                                            <div className="purchase-dropdown-content">
                                                <span className="button-text">Status :</span>
                                                <div className="content-heading-holder">
                                                    <span className="content-heading">{selectedDropDown?.text}</span>
                                                    <span className=" modal-toast">
                                                        <div className="toast-message ">
                                                            {selectedDropDown?.text === activeAos
                                                                ? activeCount
                                                                : statusCount &&
                                                                  statusCount[
                                                                      selectedDropDown.text as keyof typeof statusCount
                                                                  ]}
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <svg className="svg-icon arrow-icon">
                                                <use href="#downArrow">
                                                    <title>Expand row</title>
                                                </use>
                                            </svg>
                                        </button>
                                        <div className="dropdown-menu">
                                            <>
                                                <a
                                                    className={`menu-item app-btn ${
                                                        selectedDropDown.text === activeAos ? ' active' : ''
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(
                                                            setSelectedDropDown({
                                                                text: activeAos,
                                                                count: getTotalNumberOfAOs(),
                                                            })
                                                        );
                                                        setDropDownOpen(false);
                                                        dispatch(setFilter({ ...filter, aoStatus: 'Active' }));
                                                    }}
                                                >
                                                    <div className="content-heading-holder">
                                                        <span className="content-heading">{activeAos}</span>
                                                        <span className=" modal-toast">
                                                            <div className="toast-message ">
                                                                {getTotalNumberOfAOs()}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </a>
                                                {getDropDown()}
                                            </>
                                        </div>
                                    </div>
                                </ClickAwayListener>
                            </div>

                            <div className="add-btn-box">
                                <a
                                    href="javascript:void(0)"
                                    className="clear-filter-link"
                                    onClick={() => resetFilters()}
                                >
                                    {clearFilterText}
                                </a>
                                <ExportButton
                                    organisationData={{
                                        ...organisationData,
                                        loggedInUserName: username,
                                        ...filter,
                                        ...sortPayload,
                                        poSearch: searchTerm ? searchTerm : undefined,
                                    }}
                                    disabled={
                                        (allocationOrders &&
                                            allocationOrders.length === 0 &&
                                            selectedFilter === null) ||
                                        !allocationOrders
                                            ? true
                                            : false
                                    }
                                    showExportDropDown={showExportDropDown}
                                    setShowExportDropDown={setShowExportDropDown}
                                    exportListWithBody={exportAoList}
                                />
                                <ShowForPermissions permission="create" type="ao">
                                    {!isBlp ? (
                                        <Link to="/allocation_orders/add_new" style={{ textDecoration: 'none' }}>
                                            <button
                                                className="app-btn app-btn-secondary icon-button"
                                                title="Add AO"
                                                onClick={() => {
                                                    localStorage.removeItem('aoStatus');
                                                    localStorage.removeItem('aoNumber');
                                                }}
                                            >
                                                <svg className="svg-icon add-btn-icon">
                                                    <use xlinkHref="#plusIcon"></use>
                                                </svg>
                                                <span className="button-text footer-button-text">
                                                    Create New Allocation Order
                                                </span>
                                            </button>
                                        </Link>
                                    ) : null}
                                </ShowForPermissions>
                            </div>
                        </div>
                        <ProfileListing // Has column mapping in constants file - allocationOrder
                            filter={filter}
                            setFilter={setFilter}
                            tabName={'allocationOrder'}
                            data={allocationOrders}
                            columnStatus={columnStatus}
                            currentOrder={currentOrder}
                            isLoading={isLoading}
                            isLoading2={isLoadingAO}
                            toggleDropdown={toggleDropdown}
                            selectedFilter={selectedFilter}
                            closeToggle={closeToggle}
                            expandId={expandId}
                            setExpandIdOnClick={setExpandIdOnClick}
                            filterToggle={filterToggle}
                            onSort={onSort}
                            filterDataLists={filterValues}
                            isGlobalStateFilter={true}
                            clearSearchFilter={clearSearchFilter}
                            resetPage={resetPage}
                        />
                        <TableFooter
                            fetchData={fetchAoData}
                            currentPage={currentPage}
                            stat={payload}
                            displayCountfn={setPageCountDisplay}
                            pageCountDisplay={PageCount}
                            perPageCount={perPageCount}
                            setPageCount={setPageCount}
                            totalItems={totalItems}
                            items={allocationOrders}
                            itemsInList={null}
                            totalPages={totalPages - 1}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
