import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import './linked-booking.scss';

interface LinkedProps {
    linkedBpDetails: any;
    setShowLinkedModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
const Index = ({ linkedBpDetails, setShowLinkedModal }: LinkedProps): ReactElement => {
    return (
        <div className="app-modal">
            <div className="modal-content-holder linked-booking-modal">
                <div className="modal-header">
                    <div className="title">Linked Booking</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            setShowLinkedModal(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="layout-holder two-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Booking No.</h6>
                                <div className="description-type-1">{linkedBpDetails?.bpNumber}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Status</h6>
                                <div className="description-type-1">
                                    <div className="item-count-status-holder">
                                        <div className="grid-item-count-status status-ui error-status">
                                            <span className="status-text">{linkedBpDetails?.bpStatus}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="layout-holder one-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Reason</h6>
                                <div className="description-type-1">{linkedBpDetails?.cancelReason}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <Link to={`/bcnBookingList/${linkedBpDetails.bpId}/POSelection`} style={{ textDecoration: 'none' }}>
                        <button
                            className="app-btn app-btn-secondary modal-btn footer-btn"
                            title="Go to Linked Booking"
                            onClick={() => {
                                setShowLinkedModal(false);
                            }}
                        >
                            <span className="button-text footer-button-text">Go to Linked Booking</span>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
};
export default Index;
