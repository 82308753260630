import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { rateRefreshScheduleApi } from 'src/services/apis/qmsApi';
import { Position, error, success } from 'src/utils/toast';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<ModalProps> = ({ modalView, setModalView }) => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const confirmAction = () => {
        setLoading(true);
        rateRefreshScheduleApi(id)
            .then((res: any) => {
                setModalView(false);
                success('Duplicate rate schedule created successfully', Position.TOP_RIGHT);
                setTimeout(() => {
                    window.location.href = `/qms/rate/${res?.[0]?.RA_PK}`;
                }, 2000);
            })
            .catch((err: any) => {
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder booking-cancellation-modal">
                        <div className="modal-header">
                            <div className="title">Refresh Schedules</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="description-type-1">
                                            Do you wish to proceed with generating a new duplicate Rate schedule?
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title={loading ? 'Submitting...' : 'Submit'}
                                onClick={() => {
                                    confirmAction();
                                }}
                                disabled={loading}
                            >
                                <span className="button-text footer-button-text">
                                    {loading ? 'Submitting...' : 'Submit'}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
