import { post, get } from '../../../HttpMethods';

export const addAgentIntegrationDetails = async (payload: any) => {
    try {
        const res = await post('api/mgt/agents/integration', payload, null);
        if (res) {
            const data = await get('api/auth/userdetails', null);
            localStorage.setItem('user', JSON.stringify(data));
        }
        return res;
    } catch (err) {
        console.log('error', err);
        throw err;
    }
};

export const getAgentIntegrationDetails = async (payload: { agentId: string }) => {
    try {
        const res = await get(`api/mgt/agents/integration/${payload.agentId}`, null);
        return res;
    } catch (err) {
        console.log('error', err);
        throw err;
    }
};
