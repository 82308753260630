export const attachmentsHistory = [
    {
        columnName: 'ATTACHMENT TYPE',
        name: 'attachmentType',
        headerClass: 'grid-cell-header col-history-attachment-type',
        hasSort: true,
        sortName: 'attachmentType',
        hasFilter: true,
        filterName: 'attachmentType',
    },
    {
        columnName: 'FILE NAME',
        name: 'fileName',
        headerClass: 'grid-cell-header col-history-file-name',
        hasSort: true,
        sortName: 'fileName',
        hasFilter: true,
        filterName: 'fileName',
    },
    {
        columnName: 'USER',
        name: 'userName',
        headerClass: 'grid-cell-header col-history-user-name',
        hasSort: true,
        sortName: 'userName',
        hasFilter: true,
        filterName: 'userName',
    },
    {
        columnName: 'DATE & TIME',
        name: 'dateAndTime',
        headerClass: 'grid-cell-header col-history-date-time-activity',
        hasSort: true,
        sortName: 'dateAndTime',
        hasFilter: true,
        filterName: 'dateAndTime',
    },
    {
        columnName: 'ACTIVITY',
        name: 'activity',
        headerClass: 'grid-cell-header col-history-activity',
        hasSort: true,
        sortName: 'activity',
        hasFilter: true,
        filterName: 'activity',
    },
];
