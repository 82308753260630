import axios from 'axios';
import moment from 'moment';
import { getOrganisationData } from '../../../utils';
import { patch, post, put, del, get } from '../../HttpMethods';

export const fetchProductDetailsConfirmationListDetailsApi = async (payload: any) => {
    try {
        const data = await post(
            `api/ord/po/confirmedproducts?poId=${payload.poId}`,
            {
                searchKey: null,
                status: null,
                quantityType: null,
            },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchLclThresholdDetailsApi = async (payload: any) => {
    try {
        const data = await get(
            `api/ord/setting/threshold/${payload.userName}`,

            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const confirmPoDetailsApi = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        for (const key in payload) {
            if (key === 'confirmedGoodsdate') {
                payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
            }
        }
        const newPayload = { data: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/po/confirm', newPayload, null);
        data.type = 'confirmPo';
        data.currentIndex = payload.currentIndex;
        data.currentSplitLineIndex = payload.currentSplitLineIndex;
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const saveAllPoDetailsApi = async (payloads: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        for (let i = 0; i < payloads.length; i++) {
            const payload = payloads[i];
            for (let j = 0; j < payload.splitLines.length; j++) {
                if (payload.splitLines[j].editMode) {
                    if (payload.splitLines[j].splitLineId) {
                        const newPayload = {
                            data: {
                                ...payload.splitLines[j],
                                splitLineId: payload.splitLines[j].splitLineId,
                                quantityType: payload.quantityType,
                                confirmedGoodsdate: moment(payload.splitLines[j].confirmedGoodsdate).format(
                                    'DD-MM-YYYY'
                                ),
                                isShort: false,
                            },
                            notification: { roles, listOfUserOrganisations },
                        };
                        if (payload.splitLines[j].confirmedQty == 0) {
                            await del(`api/ord/po/poline/${payload.poProductId}`, null);
                        } else {
                            await put(`api/ord/po/poline/${payload.splitLines[j].splitLineId}`, newPayload);
                        }
                    } else {
                        const newPayload = {
                            data: {
                                ...payload.splitLines[j],
                                poProductId: payload.poProductId,
                                confirmedGoodsdate: moment(payload.splitLines[j].confirmedGoodsdate).format(
                                    'DD-MM-YYYY'
                                ),
                                quantityType: payload.quantityType,
                                isShort: false,
                            },
                            notification: { roles, listOfUserOrganisations },
                        };
                        if (payload.splitLines[j].confirmedQty == 0) {
                            await del(`api/ord/po/poline/${payload.poProductId}`, null);
                        } else {
                            await post('api/ord/po/confirm', newPayload, null);
                        }
                    }
                }
            }
        }
        return { data: { type: 'saveAllPo' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const submitPoDetailsApi = async (payload: any) => {
    try {
        await post(`api/ord/cg`, payload, null);
        // data.type = 'submitPo';
        return { data: { type: 'submitPo' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const submitPOWithoutLCLApi = async (payload: { poId: string }) => {
    try {
        const payloadData = {
            shortShippedPoLines: [],
        };
        await patch(`api/ord/po/postatus/${payload.poId}`, payloadData);
        return { data: { type: 'submitPo' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const changePoLineStatusApi = async (payload: any) => {
    try {
        const data = await patch(`api/ord/po/shipment/${payload.quantityType}/${payload.splitLineId}`, null);
        data.type = 'changePoLineStatus';
        data.currentIndex = payload.currentIndex;
        data.currentSplitLineIndex = payload.currentSplitLineIndex;
        data.poLineNumber = payload.poLineNumber;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const editPoLineStatusApi = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        for (const key in payload) {
            if (key === 'confirmedGoodsdate') {
                payload[key] = payload[key] ? moment(payload[key]).format('DD-MM-YYYY') : null;
            }
        }
        const newPayload = { data: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await put(`api/ord/po/poline/${payload.splitLineId}`, newPayload);
        data.type = 'editPoLineStatus';
        data.currentIndex = payload.currentIndex;
        data.currentSplitLineIndex = payload.currentSplitLineIndex;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const removeProductDetailsConfirmationDetailsApi = async (payload: any) => {
    try {
        const data = await del(
            `api/ord/po/line/${payload.splitLineId}/${payload.quantityType}/${payload.isShort}`,
            null
        );
        data.type = 'removePo';
        data.currentIndex = payload.currentIndex;
        data.currentSplitLineIndex = payload.currentSplitLineIndex;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const dropProductDetailsConfirmationDetailsApi = async (payload: any) => {
    try {
        const data = await del(`api/ord/po/poline/${payload.splitLineId}`, null);
        data.type = 'dropPo';
        data.currentIndex = payload.currentIndex;
        data.currentSplitLineIndex = payload.currentSplitLineIndex;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
