import React from 'react';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import { useAppDispatch } from '../../../hooks';
import { submitPoBlp } from '../../../store/reducers/purchaseOrder/productPoConfirmationReducer';
interface ModalListProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    shortShipmentList: any;
    setShortShipmentList: any;
    poId: any;
}
const Index = ({ display, displayFunction, shortShipmentList, setShortShipmentList, poId }: ModalListProps) => {
    const dispatch = useAppDispatch();
    const type = shortShipmentList?.type;
    return (
        <>
            {display ? (
                <div className="app-modal purchase-detail-modal">
                    <div className="modal-content-holder ">
                        <div className="modal-header">
                            <div className="title">{type === 'short' ? 'Short' : 'Surplus'} Inbound</div>
                            <button onClick={() => displayFunction(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content">
                                Total Confirmed quantity for{' '}
                                <span style={{ fontWeight: 700 }}>PO Line {shortShipmentList?.data?.toString()}</span>{' '}
                                is {type === 'short' ? 'less' : 'greater'} than the initial quantity. Are you sure to
                                confirm {type === 'short' ? 'short' : 'surplus'} inbound?
                            </div>
                        </div>
                        <div className="modal-footer">
                            {type === 'short' ? (
                                <>
                                    <button
                                        onClick={() => displayFunction(false)}
                                        className="app-btn app-btn-secondary modal-btn footer-btn"
                                        title="No"
                                    >
                                        <span className="button-text footer-button-text">No</span>
                                    </button>
                                    <button
                                        className="app-btn app-btn-primary modal-btn footer-btn "
                                        onClick={() => {
                                            displayFunction(false);
                                            dispatch(submitPoBlp({ poId }));
                                        }}
                                        title="Yes"
                                    >
                                        <span className="button-text footer-button-text">Yes</span>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        onClick={() => displayFunction(false)}
                                        className="app-btn app-btn-secondary modal-btn footer-btn"
                                        title="No"
                                    >
                                        <span className="button-text footer-button-text">No</span>
                                    </button>
                                    <button
                                        className="app-btn app-btn-primary modal-btn footer-btn "
                                        onClick={() => {
                                            if (type === 'both') {
                                                setShortShipmentList({
                                                    data: shortShipmentList?.shortShipmentData,
                                                    type: 'short',
                                                });
                                            } else {
                                                displayFunction(false);
                                                dispatch(submitPoBlp({ poId }));
                                            }
                                        }}
                                        title="Yes"
                                    >
                                        <span className="button-text footer-button-text">Yes</span>
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
