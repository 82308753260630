import axios from 'axios';
// import moment from 'moment';
// import { getOrganisationData } from '../../../utils';
import { get, post, put, del } from '../../HttpMethods';

export const fetchProductPoListDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/po/shipmentconfirmationlist/${payload.poId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createShipmentLine = async (poId: string, payload: any) => {
    try {
        const data = await post(`api/ord/po/shipmentconfirmation?poId=${poId}`, payload, null);

        return { data, success: true };
    } catch (error: any) {
        // if (axios.isAxiosError(error)) {
        //     console.log('error message: ', error.message);
        //     throw error.response;
        // } else {
        console.log('unexpected error: ', error);
        return { message: error.message, success: false };
        // }
    }
};

export const updateShipmentLine = async (sId: string, payload: any) => {
    try {
        const data = await put(`api/ord/po/shipmentconfirmation?shippmentId=${sId}`, payload);

        return { data, success: true };
    } catch (error: any) {
        // if (axios.isAxiosError(error)) {
        //     console.log('error message: ', error.message);
        //     throw error.response;
        // } else {
        console.log('unexpected error: ', error);
        return { message: error.message, success: false };
        // }
    }
};

export const deleteShipmentLine = async (sId: string) => {
    try {
        const data = await del(`api/ord/po/shipmentconfirmation?shippmentId=${sId}`, null);

        return { data, success: true };
    } catch (error: any) {
        // if (axios.isAxiosError(error)) {
        //     console.log('error message: ', error.message);
        //     throw error.response;
        // } else {
        console.log('unexpected error: ', error);
        return { message: error.message, success: false };
        // }
    }
};
