/* eslint-disable no-undefined */
import React, { useState } from 'react';

import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useUserProfile } from 'src/hooks/useUserProfile';
import NoAccess from 'src/components/NoAccess';

const Index: React.FC = () => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const isExecutive = userData.roles?.find((x: any) => x.roleName?.toLowerCase() === 'executive');
    const { profileType, userType, isBlp, userEmail } = useUserProfile();

    const [companyType, setCompanyType] = useState('SC_Company');
    const [teuType, setTeuType] = useState('BP_AllocatedTEU');
    const [dateType, setDateType] = useState('STD_WK');

    const companyTypeValueArray = [
        { value: 'SC_Company', label: 'Forwarder (IFC)' },
        { value: 'BP_ClientCode', label: 'Clients' },
        { value: 'BP_AgentCode', label: 'Agents' },
    ];

    const teuTypeValueArray = [
        { value: 'BP_TotTEU', label: 'Estimated' },
        { value: 'BP_AllocatedTEU', label: 'Actual' },
    ];

    const dateTypeValueArray = [
        { value: 'STD_WK', label: 'Booking Scheduled Departure' },
        { value: 'STA_WK', label: 'Booking Scheduled Arrival' },
        { value: 'ATD_WK', label: 'Booking Actual Departure' },
        { value: 'ATA_WK', label: 'Booking Actual Arrival' },
    ];

    return isExecutive && profileType === 'forwarder' ? (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h1 className="page-heading">
                    {teuTypeValueArray.find((obj) => obj.value === teuType)?.label} NAC Allocation for{' '}
                    {companyTypeValueArray.find((obj) => obj.value === companyType)?.label} by{' '}
                    {dateTypeValueArray.find((obj) => obj.value === dateType)?.label}
                </h1>

                {/* Kendo Grid */}
                <div className="grid-section" style={{ marginTop: 20 }}>
                    <div className="layout-holder three-column">
                        {/* TEU Type Dropdown */}
                        <div className="layout-item">
                            <div className=" modal-input-holder layout-item" style={{ marginRight: 20 }}>
                                <label className="modal-input-label">
                                    TEU Type<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={teuTypeValueArray}
                                    placeholder="By Estimated TEU"
                                    setStateValue={setTeuType}
                                    stateValue={teuType}
                                    fieldName="carrier"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                    isClearable={false}
                                />
                            </div>
                        </div>

                        {/* Company Type Dropdown */}
                        <div className="layout-item" style={{ marginRight: 20 }}>
                            <div className=" modal-input-holder layout-item">
                                <label className="modal-input-label">
                                    Company Type<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={companyTypeValueArray}
                                    placeholder="By Forwarder"
                                    setStateValue={setCompanyType}
                                    stateValue={companyType}
                                    fieldName="carrier"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                    isClearable={false}
                                />
                            </div>
                        </div>

                        {/* Date Types Dropdown */}
                        <div className="layout-item">
                            <div className=" modal-input-holder layout-item">
                                <label className="modal-input-label">
                                    Date Type<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={dateTypeValueArray}
                                    placeholder="By Scheduled Departure"
                                    setStateValue={setDateType}
                                    stateValue={dateType}
                                    fieldName="carrier"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                    isClearable={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid-holder">
                        <LayoutWithColumns
                            key={`${companyType}/${teuType}/${dateType}`}
                            style={'booking'}
                            content={'allocated-teu'}
                            customProp={{ keyword: `trk/nac/${companyType}/${teuType}/${dateType}` }}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <NoAccess />
    );
};

export default Index;
