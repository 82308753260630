import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import BookingSailingSchelude from '../BookingSailingSchelude';
import SelectedSailingSchelude from '../SelectedSailingSchelude';
import {
    addToBp,
    checkContractData,
    checkContractResponse,
    contractLoaded,
    fakListCompletedFn,
    fetchSailingData,
    fetchSelectedSailing,
    getFakData,
    saveCountValues,
    saveSailingData,
} from '../../../store/reducers/bookingProposal/sailingReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Position, success } from '../../../utils/toast';
import moment from 'moment';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import Loader from './Loader';
import { fetchAddedPoData } from '../../../store/reducers/aobookingProposal/addedPoReducer';
// import ShowForPermissions from '../../../ShowForPermissions';
import BookingHeaderSidebar from './../BookingHeaderSidebar';
import { useUserProfile } from '../../../hooks/useUserProfile';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { getOrganisationData } from '../../../utils';
import dateValuesSailing from 'src/pages/BookingProposal/SailingScheludeSelection/dateValuesSailing';

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];
let dateValues: any = {};

const Index: React.FC = () => {
    const { profileType } = useUserProfile();
    const { fakData, isLoading, contractSuccess, contractData, selectedSailing, contractCompleted, fakLoaded } =
        useSelector((state: RootState) => state.sailingRed);
    // const { addPoList } = useSelector((state: RootState) => state.addedPos);
    const { bpStatus, clientName, isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);

    const dispatch = useDispatch();
    // const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [dateSelected, setdateSelected] = useState(null || {});
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [currentSort, setcurrentSort] = useState('plannedETD');
    const [selectedContract, setselectedContract] = useState(null || {});
    const [selectedView, setselectedView] = useState(false);
    const [serviceType, setserviceType] = useState([]);
    const [shippingLine, setshippingLine] = useState(['']);
    const [filterIndex, setfilterIndex] = useState(null);
    const [contractType, setcontractType] = useState(['NAC']);
    const [_notification, setnotification] = useState(false);
    const [shippingDates, setshippingDates] = useState([
        {
            shippingLineName: null,
            firstTwoWeekStartDate: null,
            firstTwoWeekEndDate: null,
            secondTwoWeekStartDate: null,
            secondTwoWeekEndDate: null,
            afterFourWeeksStartDate: null,
            afterFourWeeksEndDate: null,
        },
    ]);
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const navData = [
        {
            title: 'AO Selection',
            selected: 0,
            path: id ? `/Aobooking_list/${id}/AoSelection` : '/Aobooking_list/AoSelection',
            disabled: false,
        },
        {
            title: 'Sailing Schedules',
            selected: 1,
            path: `/Aobooking_list/${id}/SailingSelectionDetails`,
            disabled: false,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/Aobooking_list/${id}/additional-details`,
            disabled: !selectedView,
        },
    ];
    if (
        headerData.bpStatus === 'Ready_for_Booking' ||
        headerData.bpStatus === 'Draft_Booking' ||
        headerData.bpStatus === 'Booked'
    ) {
        navData.push(
            {
                title: 'Container Allocation',
                selected: 0,
                path: `/Aobooking_list/${id}/container-allocation`,
                disabled: false,
            }

            // {
            //     title: 'Attachments',
            //     selected: 0,
            //     path: `/Aobooking_list/${id}/attachments`,
            //     disabled: false,
            // }
        );
    }
    /* eslint-disable no-undefined */
    /* eslint no-var: off */

    useEffect(() => {
        dispatch(saveSailingData([]));
        dispatch(
            saveCountValues({
                thirdCount: null,
                secondCount: null,
                firstCount: null,
            })
        );
    }, []);

    useEffect(() => {
        dateValues = dateValuesSailing((headerData as any)?.maxStartShipDate, (headerData as any)?.minEndShipDate);
    }, [headerData]);

    useEffect(() => {
        dispatch(contractLoaded(false));
        localStorage.removeItem('itemSelectedSailing');
    }, [id]);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(fetchAddedPoData(id));
        }, 1000);
        return () => clearTimeout(timer);
    }, [id]);

    useEffect(() => {
        const payload = {
            clientCode: [localStorage.getItem('ClientCode') ? localStorage.getItem('ClientCode') : clientName],
            bpid: id,
            originPort: headerData?.originPort,
            destinationPort: headerData?.destinationPort,
            postData: {
                firstTwoWeekStartDate: dateValues?.startDate1,
                firstTwoWeekEndDate: dateValues?.endDate1,
                secondTwoWeekStartDate: dateValues?.startDate2,
                secondTwoWeekEndDate: dateValues?.endDate2,
                afterFourWeeksStartDate: dateValues?.startDate3,
                afterFourWeeksEndDate: dateValues?.endDate3,
            },
        };
        setnotification(true);
        setdateSelected({ startDate: dateValues.startDate2, endDate: dateValues.endDate2 });
        if (
            (localStorage.getItem('itemSelectedSailing') === null ||
                localStorage.getItem('itemSelectedSailing') === 'false' ||
                localStorage.getItem('ClientCode') ||
                clientName) &&
            id
        ) {
            const timer = setTimeout(() => {
                if (!selectedView && headerData) {
                    dispatch(checkContractData(payload));
                }
            }, 250);
            return () => clearTimeout(timer);
        }
        dispatch(fakListCompletedFn(false));
    }, [headerData, clientName, selectedView, id]);
    useEffect(() => {
        if (contractData?.length === 0) {
            setcontractType(['FAK']);
        }
    }, [contractData]);

    useEffect(() => {
        dispatch(fetchSelectedSailing(id));
    }, [selectedView]);

    useEffect(() => {
        /* eslint-disable no-undefined */
        var not = true;

        if (
            (selectedSailing as any)['sailingScheduleLists'] !== undefined &&
            (selectedSailing as any)['sailingScheduleLists'][0]?.contractType !== null
        ) {
            if (localStorage.getItem('changeVesselao') !== id) {
                setselectedView(true);
                setshippingLine([]);
                setshippingDates([]);
                setserviceType([]);
            } else {
                setselectedView(true);
                // var shippingLineSaved = localStorage.getItem('selectedShipping');
                setcontractType([(selectedSailing as any)['sailingScheduleLists'][0]?.contractType]);
                setshippingLine([(selectedSailing as any)['sailingScheduleLists'][0]?.shippinglineCode]);
                localStorage.setItem(
                    'selectedShipping',
                    (selectedSailing as any)['sailingScheduleLists'][0]?.shippingLineCode
                );
            }
        } else {
            setselectedView(false);
            if (!selectedView) {
                if (contractSuccess && not) {
                    setcontractType(['NAC']);
                    not = false;
                    const timer = setTimeout(() => {
                        success('NAC is available for this Booking Proposal', Position.TOP_RIGHT, 1);
                        dispatch(checkContractResponse({ contractsDtoList: contractData, isContractPresent: false }));
                    }, 200);
                    return () => clearTimeout(timer);
                }
            }
        }
    }, [contractSuccess, selectedSailing, localStorage.getItem('changeVesselao'), contractData, id, selectedView]);
    useEffect(() => {
        var not = true;

        if (
            (selectedSailing as any)['sailingScheduleLists'] !== undefined &&
            (selectedSailing as any)['sailingScheduleLists'][0]?.contractType === null &&
            contractData?.length === 0 &&
            contractCompleted &&
            !selectedView &&
            not
        ) {
            //setcontractType(['FAK']);
            success('No NAC is available for this Booking Proposal', Position.TOP_RIGHT, 1);
            not = false;
        }
    }, [contractData?.length, (selectedSailing as any)['sailingScheduleLists'], contractCompleted]);

    useEffect(() => {
        if (contractData && headerData?.originPort && contractCompleted) {
            const timer = setTimeout(() => {
                if (!selectedView) {
                    dispatch(
                        getFakData({
                            contractsDtoList: contractData,
                            originPort: headerData?.originPort,
                            destinationPort: headerData?.destinationPort,
                            //originPort: 'Melbourne(AUMEL)', // Value is hardcoded
                            //destinationPort: 'Shanghai(CNSHA)', // Value is hardcoded
                            bpId: id,
                            startDate:
                                moment(headerData?.maxStartShipDate).subtract(7, 'days').format('yyyy-MM-DD') +
                                ' 00:00:00',
                            endDate:
                                moment(headerData?.minEndShipDate).add(7, 'days').format('yyyy-MM-DD') + ' 00:00:00',
                        })
                    );
                }
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [contractData, selectedView, headerData, contractCompleted]);

    //useEffect(() => {
    //    if (!selectedView && contractSuccess) {
    //        success('NAC is available for this Booking Proposal', Position.TOP_RIGHT);
    //        dispatch(checkContractResponse({ data: contractData, status: false }));
    //    }
    //}, [contractSuccess, selectedView]);

    useEffect(() => {
        var newAr: any = [...shippingDates];
        for (var i = 0; i < newAr?.length; i++) {
            if (
                newAr[i].firstTwoWeekStartDate === null &&
                newAr[i].secondTwoWeekStartDate === null &&
                newAr[i].afterFourWeeksStartDate == null
            ) {
                newAr[i].shippingLineName = null;
            }
        }
        const newshippinglines: any = shippingLine?.length === 0 ? null : shippingLine?.filter(Boolean);
        const payload2 = {
            sortColumn: currentSort,
            data: {
                bpId: id,
                contractsDtoList: contractData,
                isContractPresent: contractSuccess,
                contractType: contractType.length === 0 ? null : contractType,
                serviceType: serviceType.length === 0 ? null : serviceType,
                shippingLine: newshippinglines?.filter(
                    (item: any, index: any) => newshippinglines?.indexOf(item) === index
                ),
                startDate: (dateSelected as any).startDate,
                endDate: (dateSelected as any).endDate,
                //startDate: '2023-06-07 00:00:00', // Value is hardcoded
                //endDate: '2023-06-20 00:00:00', // Value is hardcoded
                shippingLineDateFilter: validation()
                    ? shippingDates.filter((r: any) => r.shippingLineName !== null)
                    : null,
                originPort: headerData?.originPort,
                destinationPort: headerData?.destinationPort,
                dates: {
                    firstTwoWeekStartDate: dateValues?.startDate1,
                    firstTwoWeekEndDate: dateValues?.endDate1,
                    secondTwoWeekStartDate: dateValues?.startDate2,
                    secondTwoWeekEndDate: dateValues?.endDate2,
                    afterFourWeeksStartDate: dateValues?.startDate3,
                    afterFourWeeksEndDate: dateValues?.endDate3,
                },
                //originPort: 'Melbourne(AUMEL)', // Value is hardcoded
                //destinationPort: 'Shanghai(CNSHA)', // Value is hardcoded
            },
        };
        if (
            contractData &&
            headerData &&
            headerData?.originPort &&
            headerData?.destinationPort &&
            contractCompleted &&
            fakLoaded &&
            fakData?.length
        ) {
            const timer = setTimeout(() => {
                if (!selectedView) {
                    dispatch(fetchSailingData(payload2));
                }
            }, 500);
            return () => clearTimeout(timer);
        }
    }, [
        contractData,
        dateSelected,
        currentSort,
        serviceType,
        shippingLine,
        contractType,
        shippingDates,
        headerData,
        selectedView,
        contractCompleted,
        fakLoaded,
    ]);
    const validation = () => {
        var flag = false;
        // var newAr: any = [...shippingDates];

        shippingDates.forEach((element: any) => {
            if (element?.shippingLineName !== null) {
                flag = true;
            }
        });
        //setshippingDates(newAr);
        return flag;
    };

    const resetFilter = () => {
        setshippingLine([]);
        setserviceType([]);
        if (fakData?.length > 0) {
            setcontractType([]);
        }
        setshippingDates([
            {
                shippingLineName: null,
                firstTwoWeekStartDate: null,
                firstTwoWeekEndDate: null,
                secondTwoWeekStartDate: null,
                secondTwoWeekEndDate: null,
                afterFourWeeksStartDate: null,
                afterFourWeeksEndDate: null,
            },
        ]);
    };

    const isEmpty = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    };

    const addSelectedContract = () => {
        localStorage.setItem('itemSelectedSailing', 'true');
        var transitPorts = JSON.parse(JSON.stringify(selectedContract as any));
        transitPorts?.sailingScheduleTransitPorts?.forEach((element: any) => {
            element['plannedETA'] = element.plannedETA
                ? moment(element.plannedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['plannedETD'] = element.plannedETD
                ? moment(element.plannedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['portCutOff'] = element.portCutOff
                ? moment(element.portCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['predictedETA'] = element.predictedETA
                ? moment(element.predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['predictedETD'] = element.predictedETD
                ? moment(element.predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['vgmCutOff'] = element.vgmCutOff
                ? moment(element.vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['gateInCutOff'] = element.gateInCutOff
                ? moment(element.gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['docCutOff'] = element.docCutOff
                ? moment(element.docCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['gateInOpen'] = element.gateInOpen
                ? moment(element.gateInOpen).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
        });
        const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
        const listOfUserOrganisations = getOrganisationData();
        const indexVal = localStorage.getItem('roleIndex');
        const roles = userData?.roles[indexVal as any];

        const payload = {
            sailingAddRequest: {
                bookingProposalId: id,
                sailingId: (selectedContract as any)?.sailingId,
                contractType: (selectedContract as any).contractType,
                contractId: (selectedContract as any).contractId,
                contractName: (selectedContract as any).contractName,
                shippingLine: (selectedContract as any).shippingline,
                shippingLineCode: (selectedContract as any).shippinglineCode,
                transitDays: (selectedContract as any).plannedTransitDays,
                serviceType: (selectedContract as any).serviceType,
                isPrimary: (selectedContract as any).isPrimaryClient,
                changeVessel:
                    localStorage.getItem('changeVesselao') && localStorage.getItem('changeVesselao') === id
                        ? true
                        : false,
                eta: (selectedContract as any).plannedETA
                    ? moment((selectedContract as any).plannedETA).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                etd: (selectedContract as any).plannedETD
                    ? moment((selectedContract as any).plannedETD).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                vesselVoyage: (selectedContract as any).voyageId,
                gateInDate: (selectedContract as any).gateInDate
                    ? moment((selectedContract as any).gateInDate).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                //gateInDate: '2023-06-20 00:00:00 //Hardcoded',
                contractVersion: (selectedContract as any)?.contractVersion,
                isManuallyEntered: false,
                predictedETD: (selectedContract as any).predictedETD
                    ? moment((selectedContract as any).predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                predictedETA: (selectedContract as any).predictedETA
                    ? moment((selectedContract as any).predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                predictedTransitDays: (selectedContract as any).predictedTransitDays,
                service: (selectedContract as any).service,
                destinationPortCode: (selectedContract as any).destinationPortCode,
                destinationPortName: (selectedContract as any).destinationPortName,
                destinationPortTerminal: (selectedContract as any).destinationPortTerminal,
                originPortCode: (selectedContract as any).originPortCode,
                originPortName: (selectedContract as any).originPortName,
                originPortTerminal: (selectedContract as any).originPortTerminal,
                vesselIMO: (selectedContract as any).vesselIMO ?? '',
                vgmCutOff: (selectedContract as any).vgmCutOff
                    ? moment((selectedContract as any).vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                gateInCutOff: (selectedContract as any).gateInCutOff
                    ? moment((selectedContract as any).gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                standardBookingCutOff: (selectedContract as any).standardBookingCutOff
                    ? moment((selectedContract as any).standardBookingCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                gateInOpen: (selectedContract as any).gateInOpen
                    ? moment((selectedContract as any).gateInOpen).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                docCutOff: (selectedContract as any).docCutOff
                    ? moment((selectedContract as any).docCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                sailingScheduleTransitPorts:
                    (selectedContract as any)?.sailingScheduleTransitPorts?.length === 0
                        ? []
                        : transitPorts?.sailingScheduleTransitPorts,
                teuBalance: undefined,
                teuTotal: undefined,
            },
            notification: { roles, listOfUserOrganisations },
        };

        if ((selectedContract as any)?.option && (selectedContract as any)?.option !== 'FAK') {
            payload.sailingAddRequest['contractId'] = (selectedContract as any)?.option?.contractId;
            payload.sailingAddRequest['contractName'] = (selectedContract as any)?.option?.contractName;
            payload.sailingAddRequest['contractType'] = 'NAC';
            payload.sailingAddRequest['contractVersion'] = (selectedContract as any)?.option?.contractVersion;
            payload.sailingAddRequest['isPrimary'] = (selectedContract as any)?.option?.isPrimaryClient;
            payload.sailingAddRequest['shippingLineCode'] = (selectedContract as any)?.option?.shippingLineCode;
            payload.sailingAddRequest['shippingLine'] = (selectedContract as any)?.option?.shippingLineName;
            payload.sailingAddRequest['teuBalance'] = (selectedContract as any)?.option?.teuBalance;
            payload.sailingAddRequest['teuTotal'] = (selectedContract as any)?.option?.teuTotal;
        }
        if ((selectedContract as any)?.option && (selectedContract as any)?.option === 'FAK') {
            // payload.sailingAddRequest['contractId'] = '';
            // payload.sailingAddRequest['contractName'] = '';
            payload.sailingAddRequest['contractType'] = 'FAK';
            // payload.sailingAddRequest['contractVersion'] = '';
            // payload.sailingAddRequest['isPrimary'] = '';
            // payload.sailingAddRequest['shippingLineCode'] = (selectedContract as any)?.option?.shippingLineCode;
            // payload.sailingAddRequest['shippingLine'] = (selectedContract as any)?.option?.shippingLineName;
            // payload.sailingAddRequest['teuBalance'] = (selectedContract as any)?.option?.teuBalance;
            // payload.sailingAddRequest['teuTotal'] = (selectedContract as any)?.option?.teuTotal;
        }
        dispatch(addToBp(payload));
        setTimeout(() => {
            setselectedView(true);
        }, 1000);
    };

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };
    return (
        <div className="main-wrapper container" style={{ overflow: 'hidden' }}>
            <div className="main-header-content-holder large-header">
                {localStorage.getItem('changeVesselao') !== id ? (
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={`/Aobooking_list/${id}/AoSelection`}
                        thirdName={'Sailing Schedule'}
                        thirdUrl={`/Aobooking_list/${id}/SailingSelectionDetails`}
                        summaryStats={summaryStats}
                        handleClickCancel={handleClickCancel}
                    />
                ) : (
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={`/Aobooking_list/${id}/AoSelection`}
                        thirdName={'Sailing Schedule'}
                        thirdUrl={`/Aobooking_list/${id}/SailingSelectionDetails`}
                        fourthName={'Change Vessel'}
                        fourthUrl={'#'}
                        summaryStats={summaryStats}
                        handleClickCancel={handleClickCancel}
                    />
                )}
                <BookingProposalHeader
                    id={
                        localStorage.getItem('changeVesselao') &&
                        localStorage.getItem('changeVesselao') === id &&
                        !selectedView
                            ? 'Select from Schedules'
                            : localStorage.getItem('bpNumber') || ''
                    }
                    setSelected={setselectedView}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>{' '}
                {(isLoading ||
                    (isLoadingHeader && contractCompleted && !selectedView) ||
                    (isLoadingHeader && selectedView)) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )}
                {(localStorage.getItem('changeVesselao') !== id || selectedView) && (
                    <BookingProcessTab navData={navData} />
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper bo-sailing-schelude">
                <div className="main-wrapper">
                    {!contractCompleted && !selectedView && localStorage.getItem('itemSelectedSailing') !== 'false' ? (
                        <Loader />
                    ) : selectedView ||
                      ((selectedSailing as any)['sailingScheduleLists'] !== undefined &&
                          (selectedSailing as any)['sailingScheduleLists'][0]?.contractType !== null) ? (
                        <SelectedSailingSchelude
                            id={id}
                            data={(selectedSailing as any)['sailingScheduleLists']}
                            selectedView={selectedView}
                            setselectedView={setselectedView}
                            setselectedContract={setselectedContract}
                            bpStatus={bpStatus}
                        ></SelectedSailingSchelude>
                    ) : (
                        <BookingSailingSchelude
                            data={[]}
                            dates={dateValues}
                            rangeSelectFn={setdateSelected}
                            selectedRange={dateSelected}
                            sortFn={setcurrentSort}
                            sortValue={currentSort}
                            selectedValue={setselectedContract}
                            filterData={contractData}
                            serviceType={serviceType}
                            setserviceType={setserviceType}
                            shippingLine={shippingLine}
                            setshippingLine={setshippingLine}
                            setfilterIndex={setfilterIndex}
                            filterIndex={filterIndex}
                            resetFilter={resetFilter}
                            setcontractType={setcontractType}
                            contractType={contractType}
                            shippingDates={shippingDates}
                            setshippingDates={setshippingDates}
                            bpStatus={bpStatus}
                            isLoading={isLoading}
                            valueSelected={selectedContract}
                        ></BookingSailingSchelude>
                    )}
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Draft_Booking_Proposal' ||
                            bpStatus === 'Rejected_by_Forwarder' ||
                            bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                            // <>
                            //     {bpStatus !== 'Cancelled' && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking Proposal"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking Proposal</span>
                                </button>
                            </ShowForPermissions>
                            //     )}
                            // </>
                        )} */}
                    {/* {bpStatus === 'Draft_Booking_Proposal' && (
                        <ShowForPermissions type="bp" permission="create">
                            {!selectedView && (
                                <ShowForPermissions type="bp" permission="create">
                                    <a
                                        title="Save as Draft"
                                        onClick={() => addSelectedContract()}
                                        className={`app-btn app-btn-primary footer-btn${
                                            isEmpty(selectedContract) ? ' disabled' : ''
                                        }`}
                                    >
                                        <span className="button-text footer-button-text">Save as Draft</span>
                                    </a>
                                </ShowForPermissions>
                            )}
                        </ShowForPermissions>
                    )} */}
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking</span>
                                </button>
                            </ShowForPermissions>
                        )} */}
                    <a
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        onClick={() => navigate(`/Aobooking_list/${id}/AoSelection`)}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </a>
                    {localStorage.getItem('changeVesselao') !== id || selectedView ? (
                        <a
                            title="Next"
                            className={`app-btn app-btn-primary footer-btn${
                                (isEmpty(selectedContract) && !selectedView) ||
                                (selectedView &&
                                    (selectedSailing as any)['sailingScheduleLists'][0]?.gateInDateExpired &&
                                    (profileType === 'agent' || profileType === 'forwarder'))
                                    ? ' disabled'
                                    : ''
                            }`}
                            onClick={() => {
                                !selectedView
                                    ? addSelectedContract()
                                    : navigate(
                                          `/Aobooking_list/${id}/${
                                              profileType === 'vendor' ? 'container-allocation' : 'additional-details'
                                          }`
                                      );
                            }}
                        >
                            <span className="button-text footer-button-text">Next</span>
                            {/* </button> */}
                        </a>
                    ) : (
                        <a
                            title="Done"
                            className={`app-btn app-btn-primary footer-btn${
                                (isEmpty(selectedContract) && !selectedView) ||
                                (selectedView &&
                                    (selectedSailing as any)['sailingScheduleLists'][0]?.gateInDateExpired &&
                                    (profileType === 'agent' || profileType === 'forwarder'))
                                    ? ' disabled'
                                    : ''
                            }`}
                            onClick={() => {
                                addSelectedContract();
                                //!selectedView
                                //    ? addSelectedContract()
                                //    : navigate(`/booking_list/${id}/additional-details`);
                            }}
                        >
                            <span className="button-text footer-button-text">Done</span>
                            {/* </button> */}
                        </a>
                    )}
                </div>
            </div>
            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
        </div>
    );
};
export default Index;
