import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'dashboardActivity',
    initialState: {
        isLoading: true,
        activityList: [] as any[],
        activityListCount: 0,
        activityListCountBkg: 0,
        activityIsRead: false,
        activityIsReadBkg: false,
        error: null,
    },
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        activityListCountFetch: (state, _action) => {
            state.activityListCount = _action.payload?.info?.count ?? 0;
            state.activityIsRead = !_action.payload?.info?.viewed;
        },
        activityListCountFetchBkg: (state, _action) => {
            state.activityListCountBkg = _action.payload?.info?.count ?? 0;
            state.activityIsReadBkg = !_action.payload?.info?.viewed;
        },
        activityListFetching: (state, _action) => {
            state.isLoading = true;
            state.activityList = [];
            //state.activityListCount = 0;
        },
        clearActivityList: (state) => {
            state.isLoading = false;
            state.activityList = [];
            state.activityListCount = 0;
        },
        activityListFetchingSuccess: (state, action) => {
            state.isLoading = false;
            console.log(action.payload, 'payloadbalue');
            state.activityList = action.payload;
            //state.activityListCount = totalCount;
            state.error = null;
        },
        activityListFetchingError: (state, action) => {
            state.isLoading = false;
            state.activityList = [];
            state.activityListCount = 0;
            state.error = action.payload;
        },
        activityListFetchingErrorBkg: (state, action) => {
            state.isLoading = false;
            state.activityList = [];
            state.activityListCountBkg = 0;
            state.error = action.payload;
        },
    },
});

export const {
    isLoading,
    activityListFetching,
    activityListCountFetch,
    activityListCountFetchBkg,
    activityListFetchingSuccess,
    activityListFetchingError,
    activityListFetchingErrorBkg,
    clearActivityList,
} = dataSlice.actions;
export default dataSlice.reducer;
