import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, post } from '../HttpMethods';
import axios from 'axios';

export const getQuoteDetails = async (QI_PK: string) => {
    try {
        const response = await get(`cix/scr/qms/config/quoteItem/${QI_PK}`, null, externalBaseUrl);
        return response?.data?.[0];
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getQuoteCodeExists = async (code: string) => {
    try {
        const response = await get(
            `cix/scr/qms/config/quoteItems/?take=50&skip=0&page=1&pageSize=50&filter[logic]=and&filter[filters][1][operator]=contains&filter[filters][1][value]=${code}&filter[filters][1][field]=QI_Code&$count`,
            null,
            externalBaseUrl
        );
        return response?.total;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const getPortGroupDetails = async (QI_PK: string) => {
    try {
        const response = await get(`cix/scr/qms/config/portGroup/${QI_PK}`, null, externalBaseUrl);
        return response?.data?.[0];
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const saveQuoteDetails = async (payload: any) => {
    try {
        const response = await post('cix/scr/qms/config/quoteItem', payload, null, false, externalBaseUrl);
        return response;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const savePortGroupDetails = async (payload: any) => {
    try {
        const response = await post('cix/scr/qms/config/portGroup', payload, null, false, externalBaseUrl);
        return response;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getQuoteSection = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/quotesection/lst/QS_Code/QS_Name?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: `${res?.QS_Name} (${res?.QS_Code})`,
            value: res?.QS_Code,
            extraValue: res?.QS_PK,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getRelativeTo = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/SHP_TDA/lst/CD_Key/CD_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.CD_UserDefinedText4,
            value: res?.CD_Key,
            extraValue: res?.CD_UserDefinedText4,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getQuoteUoms = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/QTE_UOM/lst/CD_Key/CD_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.CD_UserDefinedText4,
            value: res?.CD_Key,
            extraValue: res?.CD_UserDefinedText4,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getQuoteContainerMode = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/CON_MDE/lst/CD_Key/CD_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.CD_UserDefinedText4,
            value: res?.CD_Key,
            extraValue: res?.CD_UserDefinedText4,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getQuoteTransportMode = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/INT_TMD/lst/CD_Key/CD_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.CD_UserDefinedText4,
            value: res?.CD_Key,
            extraValue: res?.CD_UserDefinedText4,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const getDefaultCurrency = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/currency/lst/CU_CurrencyCode/CU_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.CU_UserDefinedText4,
            value: res?.CU_CurrencyCode,
            extraValue: res?.CU_PK,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const getPortMatching = async (search?: string) => {
    try {
        const key = search
            ? '&filter[filters][1][field]=Description&filter[filters][1][operator]=contains&filter[filters][1][value]=' +
              search
            : '';
        const response = await get(
            'cix/scr/admin/config/qmsports/lst/Code/Description?take=1000&skip=0&page=1&pageSize=1000' + key,
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.Description,
            value: res?.Code,
            extraValue: res?.PK,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addQuoteApi = async (payload: any) => {
    try {
        const response = await post('cix/scr/qms/quote', payload, undefined, undefined, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addSpotRateApi = async (QH_PK: string, payload: any) => {
    try {
        const response = await post(`cix/scr/qms/spot-rate/${QH_PK}`, payload, undefined, undefined, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
// getClientData
export const getClientData = async () => {
    try {
        const response = await get(
            'cix/scr/admin/organisation/clients?take=1000&filter[logic]=and&filter[filters][3][field]=OR_Status&filter[filters][3][operator]=eq&filter[filters][3][value]=Active&attributes=CL_PK,OR_OrgCode,OR_OrgName,OR_UserDefinedText4',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.OR_UserDefinedText4,
            value: res?.OR_OrgCode,
            extraValue: res?.CL_PK,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const getQuoteServices = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/quotesection/lst/QS_Code/QS_Name?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.QS_Name,
            value: res?.QS_Code,
            extraValue: res?.PK,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const getProviders = async (search?: string) => {
    try {
        const key = search
            ? '&filter[filters][1][field]=OR_UserDefinedText4&filter[filters][1][operator]=contains&filter[filters][1][value]=' +
              search
            : '';
        const response = await get(
            'cix/scr/admin/organisation/lst/OR_OrgCode/OR_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000' + key,
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: res?.OR_UserDefinedText4,
            value: res?.OR_OrgCode,
            extraValue: res?.OR_PK,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addConfigHeader = async (payload: any) => {
    try {
        const response = await post('cix/scr/admin/config/configHeader/add', payload, null, false, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const editConfigHeader = async (payload: any) => {
    try {
        const response = await patch('cix/scr/admin/config/configHeader/edit', payload, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getConfigDetails = async (CD_CH_Key: string, CD_Key: string) => {
    try {
        const response = await get(`cix/scr/admin/config/${CD_CH_Key}/configDetail/${CD_Key}`, null, externalBaseUrl);
        return response?.data?.[0];
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const getConfigHeaderDetails = async () => {
    try {
        const response = await get(
            'cix/scr/admin/config/configHeader/?take=1000&skip=0&page=1&pageSize=1000',
            null,
            externalBaseUrl
        );
        return response?.data?.map((res: any) => ({
            label: `${res?.CH_Description} (${res?.CH_Key})`,
            value: res?.CH_Key,
        }));
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return [];
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addConfigDetails = async (payload: any) => {
    try {
        const response = await post('cix/scr/admin/config/configDetail/add', payload, null, false, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const editConfigDetails = async (payload: any) => {
    try {
        const response = await patch('cix/scr/admin/config/configDetail/edit', payload, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const togglePort = async (id: string) => {
    try {
        const response = await patch(`cix/scr/admin/config/port/${id}`, null, externalBaseUrl);
        return response;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
