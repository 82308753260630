import { Grid, GridColumn as Column } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { RootState } from 'src/store/reducers';
import { postContainerPacking } from 'src/store/reducers/purchaseOrder/vendorPortalReducer';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    packingData: any;
    setPackSuccess: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<ModalProps> = ({ modalView, setModalView, packingData, setPackSuccess }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [buttonDisplay, setButtonDisplay] = useState('Pack');
    const { savingResult } = useSelector((state: RootState) => state.vendorPortalData);

    const submitPackData = () => {
        setButtonDisplay('Saving...');
        dispatch(postContainerPacking({ payload: packingData, poPk: id }));
    };

    useEffect(() => {
        if (savingResult.length > 0) {
            setModalView(false);
            setPackSuccess(true);
        }
    }, [savingResult]);

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder">
                        <div className="modal-header">
                            <div className="title">Confirm Packing</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="description-type-1">
                                            <Grid data={packingData.containers}>
                                                <Column
                                                    className="hub-tx-mono"
                                                    field="container_id"
                                                    title="Container Number"
                                                />
                                                <Column
                                                    className="hub-tx-mono"
                                                    field="container_type"
                                                    title="Container Type"
                                                />
                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Pack"
                                onClick={() => {
                                    submitPackData();
                                }}
                                disabled={buttonDisplay === 'Saving...'}
                            >
                                <span className="button-text footer-button-text">{buttonDisplay}</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
