import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'bpHeaderData',
    initialState: {
        bpId: null,
        bpNumber: null,
        bpStatus: null,
        isForwarderSubmit: false,
        reason: null,
        reAssignmentReason: null,
        withdrawnPopUpViewed: null,
        blpCode: null,
        clientCode: null,
        clientName: null,
        clientId: null,
        totalCBM: 0,
        totalKgs: 0,
        totalTEU: 0,
        originPort: '',
        destinationPort: '',
        goodsReadyDate: [],
        reSubmit: false,
        showRejectModalForwarder: false,
        showRejectModalAgent: false,
        reSubmitPopupForwarder: false,
        reSubmitPopupClient: false,
        isViewed: false,
        success: false,
        successMessage: { message: '', type: '' },
        error: null,
        isLoading: false,
        isLoadingHeader: true,
        cancelDate: null,
        cancelReason: null,
        cancelledUser: null,
        newBp: { bpNumber: null, bpId: null },
        linkedBp: null,
        linkedBpDetails: {},
        linkedBpData: [] as any,
        gateInDateExpired: false,
        cancelSuccess: false,
        cancelError: null,
        agentIntegrationStatus: false,
        agentCode: '',
        blpName: null,
        isAutoApprovalFailed: false,
        autoApprovalFailureReason: '',
        bcnParentNumber: '',
        bcnParentPk: '',
        bcnType: '',
        bcnParentBp: [],
        autoApprovalFailedModalForwarder: null,
        autoApprovalFailedModalClient: null,
        coLoader: null,
        clientDetailsList: [],
        coLoaderCode: null,
        bpType: '',
        containerDetailsId: '',
        isTriangleShipment: null,
        isHeaderOnlyPo: null,
        poNumbers: [],
        tabValues: {},
        cgCount: null,
        vendorName: null,
        vendorCode: null,
        rejectionReason: null,
        maxStartShipDate: null,
        minEndShipDate: null,
        clientApproveCount: 0,
        clientOptionApproveCount: 0,
        notesUnread: false,
    },
    reducers: {
        fetchBpHeaderData: (state, action) => {
            if (!state.bpNumber || action.payload?.data?.bpNumber) {
                if (state.bpId === action.payload?.bpId) {
                    if (action.payload?.data?.bpStatus) {
                        state.bpStatus = action.payload?.data?.bpStatus;
                    }
                } else {
                    state.bpStatus = null;
                    state.bpId = action.payload?.bpId;
                }
                state.bpNumber = action.payload?.data?.bpNumber;
                if (action.payload?.data?.bpNumber) {
                    localStorage.setItem('bpNumber', action.payload?.data?.bpNumber);
                }
                state.isTriangleShipment = action.payload?.data?.isTriangleShipment;
                state.isHeaderOnlyPo = action.payload?.data?.isHeaderOnlyPo;
                state.poNumbers = action.payload?.data?.poNumbers;
                state.clientApproveCount = action.payload?.data?.clientApproveCount;
                state.clientOptionApproveCount = action.payload?.data?.clientOptionApproveCount;
                state.coLoaderCode = action.payload?.data?.coLoaderCode;
                state.reason = action.payload?.data?.reason;
                state.reAssignmentReason = action.payload?.data?.reAssignmentReason;
                state.withdrawnPopUpViewed = action.payload?.data?.withdrawnPopUpViewed;
                state.clientCode = action.payload?.data?.clientCode;
                state.clientName = action.payload?.data?.clientName;
                state.blpCode = action.payload?.data?.blpCode;
                state.blpName = action.payload?.data?.blpName;
                state.clientId = action.payload?.data?.clientId;
                state.totalCBM = action.payload?.data?.totalCBM;
                state.totalKgs = action.payload?.data?.totalKgs;
                state.totalTEU = action.payload?.data?.totalTEU;
                state.originPort = action.payload?.data?.originPort;
                state.destinationPort = action.payload?.data?.destinationPort;
                state.goodsReadyDate = action.payload?.data?.goodsReadyDate;
                state.reSubmit = action.payload?.data?.reSubmit;
                state.showRejectModalForwarder = action.payload?.data?.showRejectModalForwarder;
                state.showRejectModalAgent = action.payload?.data?.showRejectModalAgent;
                state.reSubmitPopupForwarder = action.payload?.data?.reSubmitPopupForwarder;
                state.reSubmitPopupClient = action.payload?.data?.reSubmitPopupClient;
                state.isViewed = action.payload?.data?.isViewed;
                state.cancelDate = action.payload?.data?.cancelDate;
                state.cancelReason = action.payload?.data?.cancelReason;
                state.cancelledUser = action.payload?.data?.cancelledUser;
                state.linkedBp = action.payload?.data?.linkedBp;
                state.agentCode = action.payload?.data?.agentCode;
                state.isAutoApprovalFailed = action.payload?.data?.isAutoApprovalFailed;
                state.isForwarderSubmit = action.payload?.data?.isForwarderSubmit;
                state.autoApprovalFailureReason = action.payload?.data?.autoApprovalFailureReason;
                state.bcnParentNumber = action.payload?.data?.bcnParentNumber;
                state.bcnParentPk = action.payload?.data?.bcnParentPk;
                state.bcnType = action.payload?.data?.bcnType;
                state.autoApprovalFailedModalForwarder = action.payload?.data?.autoApprovalFailedModalForwarder;
                state.autoApprovalFailedModalClient = action.payload?.data?.autoApprovalFailedModalClient;
                state.clientDetailsList = action.payload?.data?.clientDetailsList;
                state.coLoader = action.payload?.data?.coLoader;
                if (action.payload?.data?.clientCode) {
                    localStorage.setItem('ClientCode', action.payload?.data?.clientCode);
                }
                state.gateInDateExpired = action.payload?.data?.gateInDateExpired;
                state.bpType = action.payload?.data?.bpType;
                state.containerDetailsId = action.payload?.data?.containerDetailsId;
                state.tabValues = action.payload?.data?.tabValues;
                state.cgCount = action.payload?.data?.cgCount;
                state.isLoading = false;
                state.vendorName = action.payload?.data?.vendorName;
                state.vendorCode = action.payload?.data?.vendorCode;
                state.minEndShipDate = action.payload?.data?.minEndShipDate;
                state.maxStartShipDate = action?.payload?.data?.maxStartShipDate;
            }
        },
        fetchLinkedBpData: (state, action) => {
            state.linkedBpData = action.payload;
        },
        fetchBcnParentBpList: (state, action) => {
            state.bcnParentBp = action.payload?.data;
        },
        updateBcnParentBp: (state, _action) => {
            state.isLoading = true;
        },
        updateBcnParentBpSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.success = true;
            state.successMessage = action.payload;
        },
        updateBcnParentBpFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateBcnTeu: (state, _action) => {
            state.isLoading = true;
        },
        updateBcnTeuSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.success = true;
            state.successMessage = action.payload;
        },
        updateBcnTeuFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        resetBcnBack: (state, _action) => {
            state.isLoading = true;
        },
        resetBcnBackSuccess(state, action) {
            state.isLoading = false;
            state.error = null;
            state.success = true;
            state.successMessage = action.payload;
        },
        resetBcnBackFailure(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        agentIntegration: (state, action) => {
            state.agentIntegrationStatus = action.payload?.data;
        },
        cancelBp: (state, action) => {
            state.isLoading = true;
            if (!action.payload.res?.success) {
                state.error = action.payload.error;
            } else {
                state.success = true;
                if (action.payload?.res?.info) {
                    state.newBp = action.payload?.res?.info?.newBP;
                }
            }
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        isLoadingHeaderFn: (state, action) => {
            state.isLoadingHeader = action.payload;
        },
        reset: (state, _action) => {
            state.success = false;
            state.successMessage = { message: '', type: '' };
            state.error = null;
        },
        resetHeader: (state, _action) => {
            state.clientCode = null;
            state.clientName = null;
            state.clientId = null;
            state.totalCBM = 0;
            state.totalKgs = 0;
            state.totalTEU = 0;
            state.originPort = '';
            state.destinationPort = '';
            state.isTriangleShipment = null;
            state.isHeaderOnlyPo = null;
            state.bpStatus = null;
            state.isForwarderSubmit = false;
            state.blpCode = null;
            state.blpName = null;
            state.goodsReadyDate = [];
            state.poNumbers = [];
            state.coLoader = null;
            state.clientDetailsList = [];
            state.reAssignmentReason = null;
            state.withdrawnPopUpViewed = null;
            state.tabValues = {};
            state.cgCount = null;
            state.bpId = null;
            state.bpNumber = null;
            state.vendorName = null;
            state.vendorCode = null;
            state.linkedBp = null;
            state.bcnParentNumber = '';
            state.bcnParentPk = '';
            state.bcnType = '';
            state.bcnParentBp = [];
            state.clientApproveCount = 0;
            state.clientOptionApproveCount = 0;
        },
        fetchLinkedBpHeader: (state, action) => {
            state.linkedBpDetails = action.payload?.linkedBpDetails;
        },
        cancelBooking: (state, action) => {
            state.isLoading = true;
            if (!action.payload.res?.success) {
                state.cancelError = action.payload.error;
            } else {
                state.cancelSuccess = true;
                if (action.payload?.res?.info) {
                    state.newBp = action.payload?.res?.info?.newBP;
                }
            }
            state.isLoading = false;
        },
        resetBpStatus: (state, _action) => {
            state.bpStatus = null;
        },
        fetchRejectionReason: (_state, _action) => {
            // console.log(state);
        },
        saveRejectionReason: (state, action) => {
            state.rejectionReason = action.payload;
        },
        updateNotesRead: (state, action) => {
            state.notesUnread = action.payload;
        },
    },
});

export const {
    fetchBpHeaderData,
    fetchBcnParentBpList,
    updateBcnParentBp,
    updateBcnParentBpSuccess,
    updateBcnParentBpFailure,
    updateBcnTeu,
    updateBcnTeuSuccess,
    updateBcnTeuFailure,
    resetBcnBack,
    resetBcnBackSuccess,
    resetBcnBackFailure,
    agentIntegration,
    cancelBp,
    isLoadingFn,
    isLoadingHeaderFn,
    reset,
    resetHeader,
    fetchLinkedBpHeader,
    cancelBooking,
    resetBpStatus,
    fetchRejectionReason,
    saveRejectionReason,
    fetchLinkedBpData,
    updateNotesRead,
} = dataSlice.actions;
export default dataSlice.reducer;
