import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../index';

export const dataSlice = createSlice({
    name: 'existingFlightSchedules',
    initialState: {
        isLoading: false,
        isError: false,
        message: '',
        existingFlightSchedules: {},
        currencyList: [],
    },
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        fetchExistingFlightSchedulesList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
            state.existingFlightSchedules = {};
        },
        fetchExistingFlightSchedulesListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.existingFlightSchedules = action.payload;
        },
        fetchExistingFlightSchedulesListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.existingFlightSchedules = {};
            state.message = action.payload;
        },
        fetchCurrencyList: (state) => {
            state.currencyList = [];
        },
        fetchCurrencyListSuccess(state, action) {
            state.currencyList = action.payload;
        },
        fetchCurrencyListFailed(state, _action) {
            state.currencyList = [];
        },
        createScheduleDetails: (state, _action) => {
            state.isLoading = true;
        },
        createScheduleDetailsSuccess: (state) => {
            state.isLoading = false;
        },
        createScheduleDetailsFailed: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.message = action.payload;
        },
    },
});

export const {
    isLoading,
    fetchExistingFlightSchedulesList,
    fetchExistingFlightSchedulesListSuccess,
    fetchExistingFlightSchedulesListFailed,
    fetchCurrencyList,
    fetchCurrencyListSuccess,
    fetchCurrencyListFailed,
    createScheduleDetails,
    createScheduleDetailsSuccess,
    createScheduleDetailsFailed,
} = dataSlice.actions;

export const existingFlightSchedulesList = (state: RootState) => state.existingFlightSchedules.existingFlightSchedules;
export const currencyList = (state: RootState) => state.existingFlightSchedules.currencyList;
export const isLoadingFetch = (state: RootState) => state.existingFlightSchedules.isLoading;

export default dataSlice.reducer;
