export const useUserProfile = () => {
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let profileType = '';
    let roleName = '';
    let userType = '';
    let userName = '';
    let fullName = '';
    let isBlp = false;
    let blpIntegrationsReceipt = false;
    let userEmail = '';

    if (userData) {
        const { roles, user, userType: loggedInUserType } = JSON.parse(userData);
        userType = loggedInUserType;
        if (roleIndex) {
            profileType = roles[roleIndex]?.profile?.toLowerCase();
            roleName = roles[roleIndex]?.roleName;
            userName = user?.us_UserName;
            fullName = user?.us_FirstName + ' ' + user?.us_LastName;
            isBlp = roles[roleIndex]?.isBlp == true ? true : false;
            userEmail = user?.us_Email;
            const blpIntegrations = roles[roleIndex]?.blpIntegrations;
            if (isBlp && blpIntegrations?.length) {
                const integrationList = blpIntegrations?.find(
                    (res: any) => res?.integration === 'Receipts' && res?.isIntegrated === true
                );
                if (integrationList) {
                    blpIntegrationsReceipt = true;
                }
            }
        }
    }
    return { profileType, roleName, userType, userName, fullName, isBlp, blpIntegrationsReceipt, userEmail };
};
