import React, { useCallback, useEffect, useState } from 'react';
import '../../BookingProposal/BookingAdditionalInfo/booking-additional-info.scss';
import AddModal from './AddModal';
import RejectBookingProposalModal from './RejectBookingProposalModal';
import RejectBpClientModal from './RejectBpClientModal';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import CancelModal from '../../../components/common/CancelModal';
import {
    // BpQuotation,
    // BpQuotationList,
    fetchAllQuotation,
    selectCurrency,
    selectQuotation,
    selectQuotationList,
    selectAdditionalDetailsLoading,
    fetchSingleQuotation,
    // fetchCurrency,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import {
    addContainerNac,
    addRefNumberNac,
    deleteContainerNac,
    editContainerNac,
    fetchContractDetails,
    fetchNacAdditionalDetails,
    resetSuccessFlags,
    setContainers,
} from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import { fetchIncoTermsDetails } from '../../../store/reducers/profiles/shippingLine/ContractDetailsReducer';

// import {
//     addContainerNac,
//     deleteContainerNac,
//     editContainerNac,
//     fetchNacAdditionalDetails,
//     resetSuccessFlags,
//     setContainers,
// } from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
// import {
//     selectAdditionalDetailsLoading,
//     fetchCurrency,
// } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { error, Position, success } from '../../../utils/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import { downloadFile } from '../../../services/apis/bookingProposal/additionalDetailsNacApi';
import BookingEnrichmentAdditionalDetail from '../BookingEnrichmentAdditionalDetail';
// import { fetchDetailsLcl } from 'src/store/reducers/bookingProposalLcl/additionalDetailsReducer';
// import { useUserProfile } from 'src/hooks/useUserProfile';
// import PoReview from '../../BookingProposal/BookingAddedGrid/PurchaseOrderDetails';
// import { downloadLclQuotationFile } from 'src/services/apis/bookingProposalLcl/additionalDetailsApi';
// import moment from 'moment';
import ShowForPermissions from 'src/ShowForPermissions';
import DestinationAgentModal from 'src/pages/BookingProposal/BookingAdditionalInfo/DestinationAgentModal';
// import { fetchData } from 'src/store/reducers/data-reducer';
import { getDestAgent } from 'src/store/reducers/purchase_order/basicDetails';
import AdditionalDetailModal from 'src/pages/BookingProposal/BookingAdditionalInfo/AdditionalDetailModal';
import ContractViewModal from 'src/components/common/ContractViewModal';
import AddUserAdditionalModal from 'src/pages/BookingProposal/BookingAdditionalInfo/AddUserAdditionalModal';
import NoDataAdditionalDetailModal from 'src/pages/BookingProposal/BookingAdditionalInfo/NoDataAdditionalDetailModal';
import ContractDetailModal from 'src/pages/BookingProposal/BookingAdditionalInfo/ContractDetailModal';
interface ModalViewProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
interface AdditionalInfoProps {
    rejection: ModalViewProps;
    reason: ModalViewProps;
    addDetails: ModalViewProps;
    contractDetails: ModalViewProps;
    rejectBp: ModalViewProps;
    reapproveBp: ModalViewProps;
    cancel: ModalViewProps;
    headerData: any;
    userProfile: { profileType: string; userName: string };
    bpId: string;
    additionalDetailsData: any;
    reasonState: any;
    rejectedBpForwarder: any;
    reasonRejection: any;
    assignAgent: any;
}

const Index: React.FC<AdditionalInfoProps> = ({
    rejection,
    reasonState,
    addDetails,
    headerData,
    userProfile,
    contractDetails,
    bpId,
    rejectBp,
    cancel,
    additionalDetailsData,
    rejectedBpForwarder,
    reasonRejection,
    assignAgent,
}) => {
    // const dispatch = useAppDispatch();
    // const [showFakAddModal, setShowFakAddModal] = useState(false);
    // const { profileType } = useUserProfile();
    // const isLoading = useAppSelector(selectAdditionalDetailsLoading);
    // const [isEdit, setIsEdit] = useState(-1);
    // const [cdId, setCdId] = useState('');
    // const [containerModesModified, setContainerModesModified] = useState<any[]>([]);
    // const [deliveryModesModified, setDeliveryModesModified] = useState<any[]>([]);
    // const [containerTypesModified, setContainerTypesModified] = useState<any[]>([]);
    // const [showModal, setShowModal] = useState(false); // destination agent modal
    // const {
    //     additionalDetails,
    //     success: additionalSuccess,
    //     error: additonalError,
    //     containerTypes,
    //     containerModes,
    //     deliveryModes,
    //     containers,
    //     addOrEditContainerSuccess,
    //     addOrEditContainerError,
    //     containerDeleteSuccess,
    //     containerDeleteError,
    // } = useSelector((state: RootState) => state.additionalDetailsNac);
    // const {
    //     sailingReferenceNumber,
    //     clientNames,
    //     additionalDetails: additionPrivateColoaderDetails,
    //     destinationAgentCode,
    //     destinationAgentName,
    // } = useSelector((state: RootState) => state.additionalDetailsLcl);

    const dispatch = useAppDispatch();
    const [showNoData, setShowNoData] = useState(false);
    const [mode, setMode] = useState('');
    const quotation = useAppSelector(selectQuotation);
    const quotationList = useAppSelector(selectQuotationList);
    const currency = useAppSelector(selectCurrency);
    const [showModal, setShowModal] = useState(false); // destination agent modal
    const [showNacAddModal, setShowNacAddModal] = useState(false);
    const [showFakAddModal, setShowFakAddModal] = useState(false);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);
    const [showContractViewModal, setShowContractViewModal] = useState(false);
    const [isEdit, setIsEdit] = useState(-1);
    const [cdId, setCdId] = useState('');
    const [_containerModesModified, setContainerModesModified] = useState<any[]>([]);
    const [deliveryModesModified, setDeliveryModesModified] = useState<any[]>([]);
    const [containerTypesModified, setContainerTypesModified] = useState<any[]>([]);
    const { totalCBM, destinationPort, isTriangleShipment } = useSelector((state: RootState) => state.bpHeaderData);
    const {
        additionalDetails,
        success: additionalSuccess,
        error: additonalError,
        isLoading: additionalDetailsLoading,
        errorMessage,
        containerTypes,
        containerModes,
        deliveryModes,
        containers,
        addOrEditContainerSuccess,
        addOrEditContainerError,
        containerDeleteSuccess,
        containerDeleteError,
        contractDetails: contractDetailsData,
    } = useSelector((state: RootState) => state.additionalDetailsNac);

    useEffect(() => {
        const destCode = (destinationPort as any)?.split('(')[1]?.trim();
        const destCodeFinal = destCode && (destCode as any)?.split(')')[0]?.trim();
        if (destCodeFinal) {
            dispatch(getDestAgent(destCodeFinal));
        }
    }, [destinationPort]);

    const getContainerMode = useCallback(
        () => containerModes?.find(({ containerMode }: any) => containerMode === 'LCL') || ({} as any),
        [containerModes]
    );

    const initialContainerValues = {
        containerTypeId: '',
        deliveryModeId: '',
        containerModeId: getContainerMode()?.containerModeId,
        cbm: 0,
        quantity: '',
    };

    useEffect(() => {
        const containerModeData = containerModes?.map((option: any) => {
            return { value: option.containerModeId, label: option.containerMode };
        });
        setContainerModesModified(containerModeData ? containerModeData : []);

        const containeTypeData = containerTypes?.map((option: any) => {
            return { ...option, value: option.containerTypeId, label: `${option.description} (${option.code})` };
        });
        setContainerTypesModified(containeTypeData ? containeTypeData : []);

        const deliveyModeData = deliveryModes?.map((option: any) => {
            return { value: option.deliveryModeId, label: option.deliveryMode };
        });
        setDeliveryModesModified(deliveyModeData ? deliveyModeData : []);
    }, [containerModes]);

    const ContainerDataValidationSchema = Yup.object().shape({
        containerTypeId: Yup.string().required('Container type is required'),
        deliveryModeId: Yup.string().required('Delivery mode required'),
        containerModeId: Yup.string().required('Container mode is required'),
        quantity: Yup.number()
            .typeError('Quantity must be a number')
            .required('Quantity is required')
            .min(1, 'Quantity must be greater than zero'),
        cbm: Yup.number().required('CBM is required'),
    });

    const formik = useFormik({
        initialValues: initialContainerValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ContainerDataValidationSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                values.cbm = getCbm();
                const finalValues = JSON.parse(JSON.stringify(values));
                delete finalValues.containerTypeId;
                if (!cdId) {
                    dispatch(
                        addContainerNac({
                            ...finalValues,
                            bookingProposalId: bpId,
                            containerId: values.containerTypeId,
                        })
                    );
                } else {
                    dispatch(
                        editContainerNac({
                            cdId,
                            data: { ...finalValues, bookingProposalId: bpId, containerId: values.containerTypeId },
                        })
                    );
                }
            }
        },
    });

    const updateAdditionalDetails = (type: string) => {
        if (userProfile.profileType === 'forwarder') {
            setShowNoData(true);
            setMode(type);
            addDetails.setModalView(true);
            if (type === 'add' && quotationList?.length === 0) {
                dispatch(fetchAllQuotation({ clientId: headerData.clientId }));
            } else if (type === 'edit') {
                additionalDetailsData.updateAdditionalDetails({
                    sellQuotationName: additionalDetails?.sellQuotationName,
                    sellQuotationVersion: additionalDetails?.sellQuotationVersion,
                    sellQuotationId: additionalDetails?.sellQuotationId,
                    sellTotOriginCost: additionalDetails?.sellTotOriginCost,
                    sellCurrency: additionalDetails?.sellCurrency?.currencycode,
                    bookingContractNo: additionalDetails?.bookingContractNo,
                    referenceNumber: additionalDetails?.referenceNumber,
                    buytotalOriginCost: additionalDetails?.totOriginCost,
                    buyCurrencyCode: additionalDetails?.totOriginCostCurrency,
                });
            }
        } else if (userProfile.profileType === 'agent') {
            if (additionalDetails?.contractType === 'NAC') {
                setShowNacAddModal(true);
            } else if (additionalDetails?.contractType === 'FAK') {
                setShowFakAddModal(true);
            }
        }
    };

    const onAddDetails = (refNo: string) => {
        dispatch(addRefNumberNac({ bpId, refNo }));
    };

    const dispatchGetAdditionalDetails = () => {
        dispatch(fetchNacAdditionalDetails({ bpId }));
    };
    useEffect(() => {
        if (userProfile.profileType === 'agent') {
            dispatchGetAdditionalDetails();
        }
    }, []);

    const getContainerCbm = () => {
        return containers?.reduce((prev: number, current: any) => {
            if (!current.quantity) {
                return Math.round(prev);
            }
            return Math.round(prev + current.quantity * current.container?.capacity);
        }, 0);
    };

    useEffect(() => {
        if (additionalDetails?.contractId) {
            dispatch(fetchContractDetails({ contractId: additionalDetails.contractId }));
            dispatch(fetchIncoTermsDetails([]));
        }
        if (additionalDetails?.contractType === 'FAK') {
            // dispatch(fetchCurrency({}));
        }
    }, [additionalDetails]);

    useEffect(() => {
        if (additionalSuccess) {
            dispatch(resetSuccessFlags({}));
            success('Additional Details has been updated', Position.TOP_RIGHT);
            setShowNacAddModal(false);
            setShowFakAddModal(false);
            dispatchGetAdditionalDetails();
        }
    }, [additionalSuccess]);

    useEffect(() => {
        if (additonalError || addOrEditContainerError || containerDeleteError) {
            error(errorMessage || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetSuccessFlags({}));
        }
    }, [additonalError, addOrEditContainerError, containerDeleteError]);

    useEffect(() => {
        if (addOrEditContainerSuccess) {
            formik.setValues(initialContainerValues);
            if (cdId) {
                success('Container Details updated', Position.TOP_RIGHT);
                setCdId('');
            } else {
                const modifiedContainer = JSON.parse(JSON.stringify(containers));
                modifiedContainer.pop();
                dispatch(setContainers(modifiedContainer));
                success('Container Details added', Position.TOP_RIGHT);
            }
            setIsEdit(-1);
            dispatch(resetSuccessFlags({}));
            dispatchGetAdditionalDetails();
        }
    }, [addOrEditContainerSuccess]);

    useEffect(() => {
        if (containerDeleteSuccess) {
            dispatch(resetSuccessFlags({}));
            success('Container deleted', Position.TOP_RIGHT);
            dispatchGetAdditionalDetails();
        }
    }, [containerDeleteSuccess]);

    const initialState = {
        sellQuotationName: '',
        sellQuotationVersion: '',
        sellQuotationId: '',
        sellTotOriginCost: '',
        sellCurrency: '',
        bookingContractNo: additionalDetails?.bookingContractNo ? additionalDetails?.bookingContractNo : '',
        referenceNumber: additionalDetails?.referenceNumber ? additionalDetails?.referenceNumber : '',
        buytotalOriginCost: '',
        buyCurrencyCode: '',
    };
    useEffect(() => {
        if (headerData.clientId) {
            dispatch(fetchAllQuotation({ clientId: headerData.clientId }));
        }
        if (additionalDetails?.sellQuotationId) {
            dispatch(fetchSingleQuotation({ quotationId: additionalDetails?.sellQuotationId }));
        }
    }, [additionalDetails?.sellQuotationName, headerData.clientId]);

    const getCbm = () => {
        return Math.round(
            (formik.values['quantity'] ? parseInt(formik.values['quantity']) : 0) *
                containerTypesModified.find((option: any) => option.value === formik.values['containerTypeId'])
                    ?.capacity || 0
        );
    };

    // const { coLoader, isTriangleShipment, destinationPort } = useSelector((state: RootState) => state.bpHeaderData);
    // const [poDisplay, setpoDisplay] = useState({ id: '', status: false });

    // const initialContainerValues = {
    //     containerTypeId: '',
    //     deliveryModeId: '',
    //     containerModeId: '',
    //     cbm: 0,
    //     quantity: 0,
    // };

    // useEffect(() => {
    //     const containerModeData = containerModes?.map((option: any) => {
    //         return { value: option.containerModeId, label: option.containerMode };
    //     });
    //     setContainerModesModified(containerModeData ? containerModeData : []);

    //     const containeTypeData = containerTypes?.map((option: any) => {
    //         return { ...option, value: option.containerTypeId, label: `${option.description} (${option.code})` };
    //     });
    //     setContainerTypesModified(containeTypeData ? containeTypeData : []);

    //     const deliveyModeData = deliveryModes?.map((option: any) => {
    //         return { value: option.deliveryModeId, label: option.deliveryMode };
    //     });
    //     setDeliveryModesModified(deliveyModeData ? deliveyModeData : []);
    // }, [containerModes]);

    // useEffect(() => {
    //     const destCode = (destinationPort as any)?.split('(')[1]?.trim();
    //     const destCodeFinal = destCode && (destCode as any)?.split(')')[0]?.trim();
    //     if (destCodeFinal) {
    //         dispatch(getAgent(destCodeFinal));
    //     }
    // }, [destinationPort]);

    // const ContainerDataValidationSchema = Yup.object().shape({
    //     containerTypeId: Yup.string().required('Container type is required'),
    //     deliveryModeId: Yup.string().required('Delivery mode required'),
    //     containerModeId: Yup.string().required('Container mode is required'),
    //     quantity: Yup.number().required('Quantity is required'),
    //     cbm: Yup.number().required('CBM is required'),
    // });

    // const formik = useFormik({
    //     initialValues: initialContainerValues,
    //     enableReinitialize: true,
    //     validateOnChange: false,
    //     validateOnBlur: false,
    //     validationSchema: ContainerDataValidationSchema,
    //     onSubmit: (values) => {
    //         if (formik.isSubmitting) {
    //             values.cbm = getCbm();
    //             const finalValues = JSON.parse(JSON.stringify(values));
    //             delete finalValues.containerTypeId;
    //             if (!cdId) {
    //                 dispatch(
    //                     addContainerNac({
    //                         ...finalValues,
    //                         bookingProposalId: bpId,
    //                         containerId: values.containerTypeId,
    //                     })
    //                 );
    //             } else {
    //                 dispatch(
    //                     editContainerNac({
    //                         cdId,
    //                         data: { ...finalValues, bookingProposalId: bpId, containerId: values.containerTypeId },
    //                     })
    //                 );
    //             }
    //         }
    //     },
    // });

    // const updateAdditionalDetails = () => {
    //     setShowFakAddModal(true);
    // };

    // useEffect(() => {
    //     dispatch(fetchDetailsLcl({ bpId }));
    // }, []);

    // const fetchData = () => {
    //     dispatch(fetchDetailsLcl({ bpId }));
    // };

    // const dispatchGetAdditionalDetails = () => {
    //     dispatch(fetchNacAdditionalDetails({ bpId }));
    // };
    // useEffect(() => {
    //     if (userProfile.profileType === 'agent') {
    //         dispatchGetAdditionalDetails();
    //     }
    // }, []);

    // useEffect(() => {
    //     // dispatch(fetchCurrency({}));
    // }, []);

    // useEffect(() => {
    //     if (additionalSuccess) {
    //         dispatch(resetSuccessFlags({}));
    //         success('Additional Details has been updated', Position.TOP_RIGHT);
    //         setShowFakAddModal(false);
    //         dispatchGetAdditionalDetails();
    //     }
    // }, [additionalSuccess]);

    // useEffect(() => {
    //     if (additonalError || addOrEditContainerError || containerDeleteError) {
    //         error('Something went wrong', Position.TOP_RIGHT);
    //     }
    // }, [additonalError, addOrEditContainerError, containerDeleteError]);

    // useEffect(() => {
    //     if (addOrEditContainerSuccess) {
    //         formik.setValues(initialContainerValues);
    //         if (cdId) {
    //             success('Container Details updated', Position.TOP_RIGHT);
    //             setCdId('');
    //         } else {
    //             const modifiedContainer = JSON.parse(JSON.stringify(containers));
    //             modifiedContainer.pop();
    //             dispatch(setContainers(modifiedContainer));
    //             success('Container Details added', Position.TOP_RIGHT);
    //         }
    //         setIsEdit(-1);
    //         dispatch(resetSuccessFlags({}));
    //         dispatchGetAdditionalDetails();
    //     }
    // }, [addOrEditContainerSuccess]);

    // useEffect(() => {
    //     if (containerDeleteSuccess) {
    //         dispatch(resetSuccessFlags({}));
    //         success('Container deleted', Position.TOP_RIGHT);
    //         dispatchGetAdditionalDetails();
    //     }
    // }, [containerDeleteSuccess]);

    // const getCbm = () => {
    //     return Math.round(
    //         formik.values['quantity'] *
    //             containerTypesModified.find((option: any) => option.value === formik.values['containerTypeId'])
    //                 ?.capacity || 0
    //     );
    // };

    return (
        <>
            <div className="booking-additional-detail-wrapper lcl-booking-additional-detail">
                {(isLoading || additionalDetailsLoading) && (
                    <div
                        className={`app-loader ${
                            isLoading || additionalDetailsLoading ? 'loading' : ''
                        } app-loader-with-modal`}
                    >
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Additional Details</h6>
                            {headerData?.bpStatus !== 'Cancelled' &&
                                headerData?.bpStatus !== 'Withdrawn' &&
                                headerData?.bpStatus !== 'Pending_Reassignment' && (
                                    <>
                                        <ShowForPermissions type="bp" permission="create">
                                            {(additionalDetails?.referenceNumber &&
                                                (userProfile.profileType === 'agent' ||
                                                    userProfile.profileType === 'forwarder') &&
                                                additionalDetails?.contractType === 'NAC' &&
                                                (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                    headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                    headerData.bpStatus === 'Draft_Booking_Proposal')) ||
                                            (additionalDetails?.sellQuotationName &&
                                                userProfile.profileType === 'forwarder' &&
                                                (headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                                    headerData?.bpStatus === 'Draft_Booking_Proposal' ||
                                                    headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action') &&
                                                additionalDetails?.contractType === 'FAK') ||
                                            (additionalDetails?.bookingContractNo &&
                                                ((userProfile.profileType === 'agent' &&
                                                    headerData.bpStatus !== 'Awaiting_Forwarder_Approval') ||
                                                    userProfile.profileType === 'forwarder') &&
                                                (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                    headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                                    headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                    headerData.bpStatus === 'Draft_Booking_Proposal') &&
                                                additionalDetails?.contractType === 'FAK' &&
                                                headerData?.bpStatus !== 'Booked') ? (
                                                <button
                                                    onClick={() => updateAdditionalDetails('edit')}
                                                    type="button"
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit Details"
                                                >
                                                    <svg style={{ width: '100%' }} className="svg-icon edit-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                        </ShowForPermissions>
                                        <ShowForPermissions type="bp" permission="create">
                                            {isTriangleShipment &&
                                            additionalDetails?.contractType === 'NAC' &&
                                            userProfile.profileType === 'forwarder' &&
                                            additionalDetails?.destinationAgentCode &&
                                            (headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                                headerData?.bpStatus === 'Draft_Booking_Proposal') &&
                                            headerData?.bpStatus !== 'Booked' ? (
                                                <button
                                                    onClick={() => setShowModal(true)}
                                                    type="button"
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit Details"
                                                >
                                                    <svg style={{ width: '100%' }} className="svg-icon edit-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                        </ShowForPermissions>
                                    </>
                                )}
                        </div>
                        <ShowForPermissions type="bp" permission="create">
                            {(additionalDetails?.referenceNumber &&
                                userProfile.profileType === 'agent' &&
                                additionalDetails?.contractType === 'NAC') ||
                            (additionalDetails?.sellQuotationName && userProfile.profileType === 'forwarder') ||
                            // (headerData?.bpStatus === 'Draft_Booking_Proposal' &&
                            //     userProfile.profileType === 'forwarder') ||
                            (additionalDetails?.contractType === 'NAC' && userProfile.profileType === 'forwarder') ||
                            userProfile.profileType === 'client' ||
                            ((userProfile.profileType === 'agent' || userProfile.profileType === 'forwarder') &&
                                additionalDetails?.bookingContractNo &&
                                additionalDetails?.contractType === 'FAK') ||
                            headerData?.bpStatus === 'Withdrawn' ||
                            headerData?.bpStatus === 'Pending_Reassignment' ||
                            headerData?.bpStatus === 'Cancelled' ||
                            headerData?.bpStatus === 'Booked' ? null : (
                                <div className="btn-holder">
                                    <button
                                        onClick={() => updateAdditionalDetails('add')}
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Details"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Details</span>
                                    </button>
                                </div>
                            )}
                        </ShowForPermissions>
                        <ShowForPermissions type="bp" permission="create">
                            {isTriangleShipment &&
                            additionalDetails?.contractType === 'NAC' &&
                            userProfile.profileType === 'forwarder' &&
                            !additionalDetails?.destinationAgentCode &&
                            (headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                headerData?.bpStatus === 'Draft_Booking_Proposal') &&
                            headerData?.bpStatus !== 'Booked' ? (
                                <div className="btn-holder">
                                    <button
                                        onClick={() => setShowModal(true)}
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Details"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Details</span>
                                    </button>
                                </div>
                            ) : null}
                        </ShowForPermissions>
                    </div>
                    <div className="card-detail-col">
                        {additionalDetails?.contractType === 'NAC' ? (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Reference No</h6>
                                <div className="details-box-content">{additionalDetails?.referenceNumber || '-'}</div>
                            </div>
                        ) : null}
                        {additionalDetails?.contractType === 'FAK' ? (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Booking Contract No.<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{additionalDetails?.bookingContractNo || '-'}</div>
                            </div>
                        ) : null}
                        {additionalDetails?.contractType === 'NAC' ? (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Contract Type</h6>
                                <div className="details-box-content">
                                    <div className="status-ui nac-status nac-p">
                                        <div className="status-nac-left">{additionalDetails?.contractType || '-'}</div>

                                        <div
                                            className={`status-nac-right ${
                                                additionalDetails?.clientType === 'P' ? 'p-status' : 's-status'
                                            }`}
                                        >
                                            {additionalDetails?.clientType || '-'}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Contract Type</h6>
                                <div className="details-box-content">
                                    <div className="status-ui confirmed-status">
                                        {additionalDetails?.contractType || '-'}
                                    </div>
                                </div>
                            </div>
                        )}
                        {(userProfile.profileType === 'forwarder' ||
                            (userProfile.profileType === 'agent' &&
                                (headerData?.bpStatus === 'Awaiting Client Approval' ||
                                    headerData?.bpStatus === 'Ready_for_Booking' ||
                                    headerData?.bpStatus === 'Draft_Booking' ||
                                    headerData?.bpStatus === 'Booked' ||
                                    headerData?.bpStatus === 'In_transit' ||
                                    headerData?.bpStatus === 'Delivered' ||
                                    headerData?.bpStatus === 'Cancelled'))) &&
                        // headerData?.bpStatus !== 'Draft_Booking_Proposal' &&
                        isTriangleShipment ? (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Destination Agent <span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {additionalDetails?.destinationAgentName || '-'}
                                </div>
                            </div>
                        ) : null}
                        {additionalDetails?.contractType === 'NAC' && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Contract</h6>
                                <div className="details-box-content">
                                    <a
                                        href="javascript:void(0)"
                                        // target="_blank"
                                        className="details-box-content link-url"
                                        onClick={() => {
                                            setShowContractViewModal(true);
                                        }}
                                    >
                                        {`${additionalDetails?.contractName}${
                                            additionalDetails?.contractVersion
                                                ? '-' + additionalDetails?.contractVersion
                                                : ''
                                        }`}
                                    </a>
                                </div>
                            </div>
                        )}
                        {additionalDetails?.contractType === 'FAK' && (
                            <>
                                {(() => {
                                    if (userProfile.profileType === 'forwarder') {
                                        return (
                                            <>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">
                                                        Quotation (Buy)
                                                        {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                            headerData.bpStatus ===
                                                                'Rejected_by_Client_Agent_to_Action' ||
                                                            headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                            <span className="mandatory-field-text">*</span>
                                                        )}
                                                    </h6>
                                                    <div className="details-box-content">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="details-box-content link-url"
                                                            onClick={() => {
                                                                if (additionalDetails?.savedFakFileName) {
                                                                    downloadFile(
                                                                        bpId,
                                                                        additionalDetails?.savedFakFileName
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {additionalDetails?.savedFakFileName}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">
                                                        Total Origin Cost (Buy)
                                                        {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                            headerData.bpStatus ===
                                                                'Rejected_by_Client_Agent_to_Action' ||
                                                            headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                            <span className="mandatory-field-text">*</span>
                                                        )}
                                                    </h6>
                                                    <div className="details-box-content">
                                                        {additionalDetails?.totOriginCost &&
                                                        additionalDetails?.totOriginCostCurrency
                                                            ? `${additionalDetails?.totOriginCost} ${additionalDetails?.totOriginCostCurrency}`
                                                            : '-'}
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">
                                                        Quotation (Sell)
                                                        {headerData.bpStatus !== 'Rejected_by_Forwarder' &&
                                                            headerData.bpStatus !==
                                                                'Rejected_by_Client_Agent_to_Action' &&
                                                            headerData.bpStatus !== 'Draft_Booking_Proposal' && (
                                                                <span className="mandatory-field-text">*</span>
                                                            )}
                                                    </h6>
                                                    <div className="details-box-content">
                                                        {additionalDetails?.sellQuotationName ? (
                                                            <a
                                                                onClick={() => contractDetails.setModalView(true)}
                                                                className="details-box-content link-url"
                                                            >
                                                                {additionalDetails?.sellQuotationName}
                                                                {', '}
                                                                {additionalDetails?.sellQuotationVersion}
                                                            </a>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">
                                                        Total Origin Cost (Sell)
                                                        {headerData.bpStatus !== 'Rejected_by_Forwarder' &&
                                                            headerData.bpStatus !==
                                                                'Rejected_by_Client_Agent_to_Action' &&
                                                            headerData.bpStatus !== 'Draft_Booking_Proposal' && (
                                                                <span className="mandatory-field-text">*</span>
                                                            )}
                                                    </h6>
                                                    <div className="details-box-content">
                                                        {additionalDetails?.sellTotOriginCost
                                                            ? additionalDetails?.sellTotOriginCost +
                                                              ' ' +
                                                              additionalDetails?.sellCurrency?.currencycode
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    } else if (userProfile.profileType === 'client') {
                                        return (
                                            <>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Quotation</h6>
                                                    <div className="details-box-content">
                                                        <a
                                                            onClick={() => contractDetails.setModalView(true)}
                                                            className="details-box-content link-url"
                                                        >
                                                            {additionalDetails?.sellQuotationName}
                                                            {', '}
                                                            {additionalDetails?.sellQuotationVersion}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Total Origin Cost</h6>
                                                    <div className="details-box-content">
                                                        {additionalDetails?.sellTotOriginCost}{' '}
                                                        {additionalDetails?.sellCurrency?.currencycode}
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    } else {
                                        return (
                                            <>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">
                                                        Quotation (Buy)<span className="mandatory-field-text">*</span>
                                                    </h6>
                                                    <div className="details-box-content">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="details-box-content link-url"
                                                            onClick={() => {
                                                                if (additionalDetails?.savedFakFileName) {
                                                                    downloadFile(
                                                                        bpId,
                                                                        additionalDetails?.savedFakFileName
                                                                    );
                                                                }
                                                            }}
                                                        >
                                                            {additionalDetails?.savedFakFileName}
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">
                                                        Total Origin Cost (Buy)
                                                        <span className="mandatory-field-text">*</span>
                                                    </h6>
                                                    <div className="details-box-content">
                                                        {additionalDetails?.totOriginCost &&
                                                        additionalDetails?.totOriginCostCurrency
                                                            ? `${additionalDetails?.totOriginCost} ${additionalDetails?.totOriginCostCurrency}`
                                                            : '-'}
                                                    </div>
                                                </div>
                                            </>
                                        );
                                    }
                                })()}
                            </>
                        )}
                    </div>
                </div>
                {headerData?.bpStatus && (
                    <>
                        {headerData?.bpStatus === 'Ready_for_Booking' ||
                        headerData?.bpStatus === 'Draft_Booking' ||
                        headerData?.bpStatus === 'Booked' ? (
                            <BookingEnrichmentAdditionalDetail />
                        ) : (
                            <div className="card details-box custom">
                                <div className="card-head-holder add-btn-holder">
                                    <div className="card-head-btn-edit-holder">
                                        <h6 className="details-box-heading-2">Container Details</h6>
                                        <div className="container-count">
                                            <div className="total-cbm-count">
                                                <span className="btn-pills container-pill disable-pill">
                                                    <span className="pill-text">Total CBM :</span>
                                                    <span className="pill-text-count">{totalCBM}</span>
                                                </span>
                                            </div>
                                            {containers?.length && Object.keys(containers[0]).length !== 0 ? (
                                                <div className="total-container-count">
                                                    {/* use success-pill class for success state */}
                                                    {/* use disable-pill class for success state */}
                                                    {/* use error-pill class for success state */}
                                                    <span
                                                        className={`btn-pills container-pill ${
                                                            totalCBM > getContainerCbm() ? 'error-pill' : ''
                                                        } ${!containers?.length ? 'disable-pill' : ''} ${
                                                            totalCBM <= getContainerCbm() ? 'success-pill' : ''
                                                        }`}
                                                    >
                                                        <span className="pill-text">Container CBM :</span>
                                                        <span className="pill-text-count">{getContainerCbm()}</span>
                                                    </span>
                                                </div>
                                            ) : null}
                                            {/* use success-text class and badgeIcon for success state */}
                                            {/* use error-text class and errorIcon for error state*/}

                                            {containers?.length && Object.keys(containers[0]).length !== 0 ? (
                                                <div
                                                    className={`total-cbm-container-count ${
                                                        totalCBM > getContainerCbm() ? 'error-text' : 'success-text'
                                                    }`}
                                                >
                                                    <span>
                                                        <svg className="svg-icon">
                                                            {totalCBM > getContainerCbm() ? (
                                                                <use xlinkHref="#errorIcon">
                                                                    <title>warning message</title>
                                                                </use>
                                                            ) : (
                                                                <use xlinkHref="#badgeIcon">
                                                                    <title>Success</title>
                                                                </use>
                                                            )}
                                                        </svg>
                                                    </span>
                                                    <div className="container-cbm-text">
                                                        {totalCBM <= getContainerCbm()
                                                            ? 'Container CBM satisfies Total CBM'
                                                            : 'Container CBM less than Total CBM'}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="btn-holder">
                                        <ShowForPermissions type="bp" permission="create">
                                            {(userProfile.profileType === 'agent' ||
                                                userProfile.profileType === 'forwarder') &&
                                                (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                    headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                    headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action' ||
                                                    headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                    <button
                                                        className="app-btn app-btn-secondary icon-button"
                                                        title="Add Container"
                                                        disabled={
                                                            !!containers?.length &&
                                                            isEdit === containers?.length - 1 &&
                                                            !cdId
                                                        }
                                                        onClick={() => {
                                                            setCdId('');
                                                            formik.setValues(initialContainerValues);
                                                            setIsEdit(containers.length);
                                                            const modifiedContainer = JSON.parse(
                                                                JSON.stringify(containers)
                                                            );
                                                            modifiedContainer.push({});
                                                            dispatch(setContainers(modifiedContainer));
                                                        }}
                                                    >
                                                        <svg className="svg-icon add-btn-icon">
                                                            <use xlinkHref="#plusIcon"></use>
                                                        </svg>
                                                        <span className="button-text">Add Container</span>
                                                    </button>
                                                )}
                                        </ShowForPermissions>
                                    </div>
                                </div>
                                <div className="card-detail-col">
                                    <div className="main-content-holder">
                                        <div className="grid-section container_grid">
                                            <div className="grid-holder">
                                                <div className="grid-container">
                                                    {containers?.length === 0 ? (
                                                        <div className="grid-no-content ">
                                                            <svg className="svg-icon grid-no-content-icon ">
                                                                <use xlinkHref="#gridNoContentIcon"></use>
                                                            </svg>
                                                            <p className="no-content-message">No Containers Added</p>
                                                        </div>
                                                    ) : null}

                                                    <div className="grid-scroll-content">
                                                        <form
                                                            id="container-form"
                                                            action=""
                                                            onSubmit={formik.handleSubmit}
                                                        >
                                                            <table className="grid-table">
                                                                <thead>
                                                                    <tr className="grid-sticky-row">
                                                                        <th className="grid-cell-header col-container-type">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    CONTAINER TYPE
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-delivery-mode">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    DELIVERY MODE
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-container-mode">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    CONTAINER MODE
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-quantity">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    QUANTITY
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-cbm">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    CBM
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <ShowForPermissions
                                                                            type="bp"
                                                                            permission="create"
                                                                        >
                                                                            {(userProfile.profileType === 'agent' ||
                                                                                userProfile.profileType ===
                                                                                    'forwarder') &&
                                                                                (headerData.bpStatus ===
                                                                                    'Rejected_by_Forwarder' ||
                                                                                    headerData.bpStatus ===
                                                                                        'Rejected_by_Client_Agent_to_Action' ||
                                                                                    headerData.bpStatus ===
                                                                                        'Rejected_by_Client_Forwarder_to_Action' ||
                                                                                    headerData.bpStatus ===
                                                                                        'Draft_Booking_Proposal') && (
                                                                                    <th className="grid-cell-header col-actions">
                                                                                        <div className="grid-cell-data">
                                                                                            <span className="header-title">
                                                                                                ACTIONS
                                                                                            </span>
                                                                                        </div>
                                                                                    </th>
                                                                                )}
                                                                        </ShowForPermissions>
                                                                    </tr>
                                                                </thead>

                                                                <tbody>
                                                                    {containers?.map((item: any, index: number) => {
                                                                        return isEdit === index ? (
                                                                            <tr className="has-child row-expanded">
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder">
                                                                                            <SearchableSelectBox
                                                                                                data={
                                                                                                    containerTypesModified?.length
                                                                                                        ? containerTypesModified
                                                                                                        : []
                                                                                                }
                                                                                                placeholder="Enter container type"
                                                                                                formik={formik}
                                                                                                fieldName="containerTypeId"
                                                                                                customStyle={''}
                                                                                                menuPosition="fixed"
                                                                                                menuPortalTarget={document.getElementById(
                                                                                                    'outside_div'
                                                                                                )}
                                                                                                closeMenuOnScroll={true}
                                                                                            />
                                                                                            {formik.errors
                                                                                                .containerTypeId ? (
                                                                                                <div className="error-text">
                                                                                                    {
                                                                                                        formik.errors
                                                                                                            .containerTypeId
                                                                                                    }
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder delivery-mode-dropdown">
                                                                                            <SearchableSelectBox
                                                                                                data={
                                                                                                    deliveryModesModified?.length
                                                                                                        ? deliveryModesModified
                                                                                                        : []
                                                                                                }
                                                                                                placeholder="Enter delivery mode"
                                                                                                formik={formik}
                                                                                                fieldName="deliveryModeId"
                                                                                                customStyle={''}
                                                                                                menuPosition="fixed"
                                                                                                menuPortalTarget={document.getElementById(
                                                                                                    'outside_div'
                                                                                                )}
                                                                                                closeMenuOnScroll={true}
                                                                                            />
                                                                                            {formik.errors
                                                                                                .deliveryModeId ? (
                                                                                                <div className="error-text">
                                                                                                    {
                                                                                                        formik.errors
                                                                                                            .deliveryModeId
                                                                                                    }
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {
                                                                                            getContainerMode()
                                                                                                ?.containerMode
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        <input
                                                                                            placeholder="Enter quantity"
                                                                                            type="text"
                                                                                            name={'quantity'}
                                                                                            className="search-input input-text input-qty"
                                                                                            onChange={(event) => {
                                                                                                // eslint-disable-next-line no-undefined
                                                                                                formik.setFieldError(
                                                                                                    event.target.name,
                                                                                                    // eslint-disable-next-line no-undefined
                                                                                                    undefined
                                                                                                );
                                                                                                formik.setFieldValue(
                                                                                                    'quantity',
                                                                                                    event.target.value
                                                                                                        .replace(
                                                                                                            /[^.\d]/g,
                                                                                                            ''
                                                                                                        )
                                                                                                        .replace(
                                                                                                            /^0+/,
                                                                                                            ''
                                                                                                        )
                                                                                                );
                                                                                            }}
                                                                                            onKeyDown={(e) => {
                                                                                                if (e.key === 'Enter') {
                                                                                                    e.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            onKeyPress={(event) => {
                                                                                                if (
                                                                                                    !/[0-9]/.test(
                                                                                                        event.key
                                                                                                    )
                                                                                                ) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                            value={
                                                                                                formik.values[
                                                                                                    'quantity'
                                                                                                ]
                                                                                            }
                                                                                        ></input>
                                                                                        {formik.errors.quantity ? (
                                                                                            <div className="error-text">
                                                                                                {formik.errors.quantity}
                                                                                            </div>
                                                                                        ) : null}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {getCbm()}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <button
                                                                                        className="view-nav grid-icon icon-nav "
                                                                                        title="Save"
                                                                                        type="submit"
                                                                                        form="container-form"
                                                                                    >
                                                                                        <svg className="svg-icon save-icon">
                                                                                            <use xlinkHref="#saveIcon"></use>
                                                                                        </svg>
                                                                                    </button>
                                                                                    <button
                                                                                        className="view-nav grid-icon icon-nav "
                                                                                        title="Remove Container"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            if (item.containerId) {
                                                                                                dispatch(
                                                                                                    deleteContainerNac({
                                                                                                        bookingProposalId:
                                                                                                            bpId,
                                                                                                        cdId: item.containerId,
                                                                                                    })
                                                                                                );
                                                                                            } else {
                                                                                                const modifiedContainer =
                                                                                                    containers?.filter(
                                                                                                        (c: any) =>
                                                                                                            c.containerId
                                                                                                    );
                                                                                                dispatch(
                                                                                                    setContainers(
                                                                                                        modifiedContainer
                                                                                                    )
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <svg className="svg-icon eye-icon ">
                                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                                        </svg>
                                                                                    </button>
                                                                                </td>
                                                                            </tr>
                                                                        ) : (
                                                                            <tr className="has-child row-expanded">
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {`${item?.container?.description} (${item?.container?.code})`}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {
                                                                                            item.deliveryMode
                                                                                                ?.deliveryMode
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {
                                                                                            item.containerMode
                                                                                                ?.containerMode
                                                                                        }
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {item.quantity}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {Math.round(
                                                                                            item.quantity *
                                                                                                item.container
                                                                                                    ?.capacity || 0
                                                                                        )}
                                                                                    </div>
                                                                                </td>
                                                                                <ShowForPermissions
                                                                                    type="bp"
                                                                                    permission="create"
                                                                                >
                                                                                    {(userProfile.profileType ===
                                                                                        'agent' ||
                                                                                        userProfile.profileType ===
                                                                                            'forwarder') &&
                                                                                        (headerData.bpStatus ===
                                                                                            'Rejected_by_Forwarder' ||
                                                                                            headerData.bpStatus ===
                                                                                                'Rejected_by_Client_Agent_to_Action' ||
                                                                                            headerData.bpStatus ===
                                                                                                'Rejected_by_Client_Forwarder_to_Action' ||
                                                                                            headerData.bpStatus ===
                                                                                                'Draft_Booking_Proposal') &&
                                                                                        headerData?.bpStatus !==
                                                                                            'Booked' && (
                                                                                            <td className="grid-cell">
                                                                                                <button
                                                                                                    className="view-nav grid-icon icon-nav"
                                                                                                    title="Edit"
                                                                                                    type="button"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        if (
                                                                                                            isEdit ===
                                                                                                                containers?.length -
                                                                                                                    1 &&
                                                                                                            !cdId
                                                                                                        ) {
                                                                                                            const modifiedContainer =
                                                                                                                JSON.parse(
                                                                                                                    JSON.stringify(
                                                                                                                        containers
                                                                                                                    )
                                                                                                                );
                                                                                                            modifiedContainer.pop();
                                                                                                            dispatch(
                                                                                                                setContainers(
                                                                                                                    modifiedContainer
                                                                                                                )
                                                                                                            );
                                                                                                        }
                                                                                                        setCdId(
                                                                                                            item.containerId
                                                                                                        );
                                                                                                        setIsEdit(
                                                                                                            index
                                                                                                        );
                                                                                                        formik.setValues(
                                                                                                            {
                                                                                                                containerTypeId:
                                                                                                                    item
                                                                                                                        .container
                                                                                                                        ?.containerTypeId,
                                                                                                                deliveryModeId:
                                                                                                                    item
                                                                                                                        .deliveryMode
                                                                                                                        ?.deliveryModeId,
                                                                                                                containerModeId:
                                                                                                                    item
                                                                                                                        .containerMode
                                                                                                                        ?.containerModeId,
                                                                                                                cbm: item
                                                                                                                    .container
                                                                                                                    ?.capacity,
                                                                                                                quantity:
                                                                                                                    item.quantity,
                                                                                                            }
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <svg className="svg-icon edit-icon ">
                                                                                                        <use xlinkHref="#editIcon"></use>
                                                                                                    </svg>
                                                                                                </button>
                                                                                                <button
                                                                                                    className="view-nav grid-icon icon-nav "
                                                                                                    title="Remove Container"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        if (
                                                                                                            item.containerId
                                                                                                        ) {
                                                                                                            dispatch(
                                                                                                                deleteContainerNac(
                                                                                                                    {
                                                                                                                        bookingProposalId:
                                                                                                                            bpId,
                                                                                                                        cdId: item.containerId,
                                                                                                                    }
                                                                                                                )
                                                                                                            );
                                                                                                        } else {
                                                                                                            const modifiedContainer =
                                                                                                                containers?.filter(
                                                                                                                    (
                                                                                                                        c: any
                                                                                                                    ) =>
                                                                                                                        c.containerId
                                                                                                                );
                                                                                                            dispatch(
                                                                                                                setContainers(
                                                                                                                    modifiedContainer
                                                                                                                )
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                >
                                                                                                    <svg className="svg-icon eye-icon ">
                                                                                                        <use xlinkHref="#deleteIcon"></use>
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </td>
                                                                                        )}
                                                                                </ShowForPermissions>
                                                                            </tr>
                                                                        );
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {/*commented for make same as fcl*/}
                {/* {(sailingReferenceNumber || profileType !== 'client') && (
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder">
                            <ShowForPermissions type="bp" permission="create">
                                <div className="card-head-btn-edit-holder">
                                    <h6 className="details-box-heading-2">Additional Details</h6>
                                    {profileType === 'forwarder' &&
                                    isTriangleShipment &&
                                    destinationAgentCode &&
                                    headerData.bpStatus === 'Awaiting_Forwarder_Approval' ? (
                                        <button
                                            onClick={() => setShowModal(true)}
                                            type="button"
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                        >
                                            <svg style={{ width: '100%' }} className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    ) : null}
                                </div>
                            </ShowForPermissions>
                            <ShowForPermissions type="bp" permission="create">
                                {profileType === 'forwarder' &&
                                isTriangleShipment &&
                                !destinationAgentCode &&
                                headerData.bpStatus === 'Awaiting_Forwarder_Approval' ? (
                                    <div className="btn-holder">
                                        <button
                                            onClick={() => setShowModal(true)}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    </div>
                                ) : null}
                            </ShowForPermissions>
                        </div>
                        <div className="card-detail-col">
                            {sailingReferenceNumber ? (
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">Sailing Reference No.</h6>
                                    <div className="details-box-content">{sailingReferenceNumber}</div>
                                </div>
                            ) : null}
                            {(profileType === 'forwarder' ||
                                (profileType === 'agent' &&
                                    (headerData?.bpStatus === 'Awaiting Client Approval' ||
                                        headerData?.bpStatus === 'Ready_for_Booking' ||
                                        headerData?.bpStatus === 'Draft_Booking' ||
                                        headerData?.bpStatus === 'Booked' ||
                                        headerData?.bpStatus === 'In_transit' ||
                                        headerData?.bpStatus === 'Delivered' ||
                                        headerData?.bpStatus === 'Cancelled'))) &&
                            headerData?.bpStatus !== 'Draft_Booking_Proposal' &&
                            isTriangleShipment ? (
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">
                                        Destination Agent
                                        <span className="mandatory-field-text">*</span>
                                    </h6>
                                    <div className="details-box-content">{destinationAgentName || '-'}</div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )} */}

                {/*commented for make same as fcl*/}
                {/*only if forwarder*/}
                {/* {coLoaderCode === process.env.REACT_APP_ORG_CODE ? (
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder" style={{ paddingBottom: '12px' }}>
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">Contract Details</h6>
                                {headerData.bpStatus !== 'Booked' && (
                                    <ShowForPermissions type="bp" permission="create">
                                        {userProfile.profileType === 'agent' &&
                                            headerData?.bpStatus !== 'Cancelled' &&
                                            additionalDetails?.bookingContractNo && (
                                                <button
                                                    onClick={() => updateAdditionalDetails()}
                                                    type="button"
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit Details"
                                                >
                                                    <svg style={{ width: '100%' }} className="svg-icon edit-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                    </ShowForPermissions>
                                )}
                            </div>
                            <ShowForPermissions type="bp" permission="create">
                                {userProfile.profileType === 'agent' &&
                                !additionalDetails?.bookingContractNo &&
                                headerData?.bpStatus !== 'Cancelled' ? (
                                    <div className="btn-holder">
                                        <button
                                            onClick={() => updateAdditionalDetails()}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    </div>
                                ) : null}
                            </ShowForPermissions>
                        </div>
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Booking Contract No.<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{additionalDetails?.bookingContractNo || '-'}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Contract Type</h6>
                                <div className="details-box-content">
                                    {additionalDetails?.contractType === 'NAC' ? (
                                        <div
                                            className={`status-ui nac-status ${
                                                additionalDetails?.clientType === 'p' ? 'nac-p' : 'nac-s'
                                            }`}
                                        >
                                            <div className="status-nac-left">{additionalDetails?.contractType}</div>
                                            <div
                                                className={`status-nac-right ${
                                                    additionalDetails?.clientType !== 'P' ? 's-status' : 'p-status'
                                                }`}
                                            >
                                                {additionalDetails?.clientType}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="status-ui confirmed-status">FAK</div>
                                    )}
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Quotation (Buy)
                                    <span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    <a
                                        href="javascript:void(0)"
                                        className="details-box-content link-url"
                                        onClick={() => {
                                            if (additionalDetails?.savedFakFileName) {
                                                downloadFile(bpId, additionalDetails?.savedFakFileName);
                                            }
                                        }}
                                    >
                                        {additionalDetails?.savedFakFileName}
                                    </a>
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Total Origin Cost (Buy)
                                    <span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {additionalDetails?.totOriginCost && additionalDetails?.totOriginCostCurrency
                                        ? `${additionalDetails?.totOriginCost} ${additionalDetails?.totOriginCostCurrency}`
                                        : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null} */}

                {/*commented for make same as fcl*/}
                {/* {sailingReferenceNumber && additionPrivateColoaderDetails?.length ? (
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder">
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">Quote Details</h6>
                            </div>
                        </div>
                        <div className="card-detail-col">
                            <div className="main-content-holder">
                                <div className="grid-section additional_grid">
                                    <div className="grid-holder">
                                        <div className="grid-container">
                                            <div className="grid-scroll-content">
                                                <table className="grid-table">
                                                    <thead>
                                                        <tr className="grid-sticky-row">
                                                            <th className="grid-cell-header col-add-po-number">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">PO NUMBER</span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-add-client">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">CLIENT</span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-add-grd">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        GOODS READY DATE
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-app-cost">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        APPROVED LCL COST
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-app-quote">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        APPROVED LCL QUOTATION
                                                                    </span>
                                                                </div>
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {additionPrivateColoaderDetails?.map(
                                                            (data: any, ind: number) => (
                                                                <tr className="has-child row-expanded" key={ind}>
                                                                    <td className="grid-cell">
                                                                        <div className="grid-cell-data">
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="booking-proposal-link"
                                                                                onClick={() =>
                                                                                    setpoDisplay({
                                                                                        status: true,
                                                                                        id: data?.poId,
                                                                                    })
                                                                                }
                                                                            >
                                                                                {data?.poNumber}
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell">
                                                                        <div className="grid-cell-data">
                                                                            {data?.client}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell">
                                                                        <div className="grid-cell-data">
                                                                            {data?.goodsReadyDate
                                                                                ? moment(data?.goodsReadyDate).format(
                                                                                      'DD-MM-YYYY'
                                                                                  )
                                                                                : '-'}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell">
                                                                        <div className="grid-cell-data">
                                                                            {data?.approvedLclCost}
                                                                        </div>
                                                                    </td>
                                                                    <td className="grid-cell">
                                                                        <div
                                                                            className="grid-cell-data"
                                                                            onClick={() => {
                                                                                if (data?.approvedLclQuotation) {
                                                                                    downloadLclQuotationFile(
                                                                                        data?.confirmationGroupId,
                                                                                        data?.approvedLclQuotation
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <a
                                                                                href="javascript:void(0)"
                                                                                className="booking-proposal-link"
                                                                            >
                                                                                {data?.approvedLclQuotation}
                                                                            </a>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            )
                                                        )}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null} */}

                {/*commented for make same as fcl*/}
                {/* {headerData?.bpStatus && (
                    <>
                        {' '}
                        {headerData?.bpStatus === 'Ready_for_Booking' || headerData?.bpStatus === 'Draft_Booking' ? (
                            <BookingEnrichmentAdditionalDetail />
                        ) : (
                            <div className="card details-box custom">
                                <div className="card-head-holder add-btn-holder">
                                    <div className="card-head-btn-edit-holder">
                                        <h6 className="details-box-heading-2">Container Details</h6>
                                    </div>
                                    <div className="btn-holder">
                                        <ShowForPermissions type="bp" permission="create">
                                            {!sailingReferenceNumber &&
                                                userProfile.profileType === 'agent' &&
                                                (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                    headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                    headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                    <button
                                                        className="app-btn app-btn-secondary icon-button"
                                                        title="Add Container"
                                                        disabled={
                                                            !!containers?.length &&
                                                            isEdit === containers?.length - 1 &&
                                                            !cdId
                                                        }
                                                        onClick={() => {
                                                            setCdId('');
                                                            formik.setValues(initialContainerValues);
                                                            setIsEdit(containers.length);
                                                            const modifiedContainer = JSON.parse(
                                                                JSON.stringify(containers)
                                                            );
                                                            modifiedContainer.push({});
                                                            dispatch(setContainers(modifiedContainer));
                                                        }}
                                                    >
                                                        <svg className="svg-icon add-btn-icon">
                                                            <use xlinkHref="#plusIcon"></use>
                                                        </svg>
                                                        <span className="button-text">Add Container</span>
                                                    </button>
                                                )}
                                        </ShowForPermissions>
                                    </div>
                                </div>
                                <div className="card-detail-col">
                                    <div className="main-content-holder">
                                        <div className="grid-section container_grid">
                                            <div className="grid-holder">
                                                <div className="grid-container">
                                                    {!containers?.length && !sailingReferenceNumber ? (
                                                        <div className="grid-no-content ">
                                                            <svg className="svg-icon grid-no-content-icon ">
                                                                <use xlinkHref="#gridNoContentIcon"></use>
                                                            </svg>
                                                            <p className="no-content-message">No Containers Added</p>
                                                        </div>
                                                    ) : null}
                                                    {sailingReferenceNumber ? (
                                                        <div className="grid-scroll-content">
                                                            <form
                                                                id="container-form"
                                                                action=""
                                                                onSubmit={formik.handleSubmit}
                                                            >
                                                                <table className="grid-table">
                                                                    <thead>
                                                                        <tr className="grid-sticky-row">
                                                                            <th className="grid-cell-header col-container-type">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER TYPE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-delivery-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        DELIVERY MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-container-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-quantity">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        QUANTITY
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr className="has-child row-expanded">
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    Unknown
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    CFS/CFS
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    LCL
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">1</div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </div>
                                                    ) : (
                                                        <div className="grid-scroll-content">
                                                            <form
                                                                id="container-form"
                                                                action=""
                                                                onSubmit={formik.handleSubmit}
                                                            >
                                                                <table className="grid-table">
                                                                    <thead>
                                                                        <tr className="grid-sticky-row">
                                                                            <th className="grid-cell-header col-container-type">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER TYPE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-delivery-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        DELIVERY MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-container-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-quantity">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        QUANTITY
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-cbm">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CBM
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <ShowForPermissions
                                                                                type="bp"
                                                                                permission="create"
                                                                            >
                                                                                {userProfile.profileType === 'agent' &&
                                                                                    (headerData.bpStatus ===
                                                                                        'Rejected_by_Forwarder' ||
                                                                                        headerData.bpStatus ===
                                                                                            'Rejected_by_Client_Agent_to_Action' ||
                                                                                        headerData.bpStatus ===
                                                                                            'Draft_Booking_Proposal') && (
                                                                                        <th className="grid-cell-header col-actions">
                                                                                            <div className="grid-cell-data">
                                                                                                <span className="header-title">
                                                                                                    ACTIONS
                                                                                                </span>
                                                                                            </div>
                                                                                        </th>
                                                                                    )}
                                                                            </ShowForPermissions>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {containers?.map((item: any, index: number) => {
                                                                            return isEdit === index ? (
                                                                                <tr className="has-child row-expanded inline-height">
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder">
                                                                                                <SearchableSelectBox
                                                                                                    data={
                                                                                                        containerTypesModified?.length
                                                                                                            ? containerTypesModified
                                                                                                            : []
                                                                                                    }
                                                                                                    placeholder="Enter container type"
                                                                                                    formik={formik}
                                                                                                    fieldName="containerTypeId"
                                                                                                    customStyle={''}
                                                                                                    menuPosition="fixed"
                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                        'outside_div'
                                                                                                    )}
                                                                                                    closeMenuOnScroll={
                                                                                                        true
                                                                                                    }
                                                                                                />
                                                                                                {formik.errors
                                                                                                    .containerTypeId ? (
                                                                                                    <div className="error-text">
                                                                                                        {
                                                                                                            formik
                                                                                                                .errors
                                                                                                                .containerTypeId
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder delivery-mode-dropdown">
                                                                                                <SearchableSelectBox
                                                                                                    data={
                                                                                                        deliveryModesModified?.length
                                                                                                            ? deliveryModesModified
                                                                                                            : []
                                                                                                    }
                                                                                                    placeholder="Enter delivery mode"
                                                                                                    formik={formik}
                                                                                                    fieldName="deliveryModeId"
                                                                                                    customStyle={''}
                                                                                                    menuPosition="fixed"
                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                        'outside_div'
                                                                                                    )}
                                                                                                    closeMenuOnScroll={
                                                                                                        true
                                                                                                    }
                                                                                                />
                                                                                                {formik.errors
                                                                                                    .deliveryModeId ? (
                                                                                                    <div className="error-text">
                                                                                                        {
                                                                                                            formik
                                                                                                                .errors
                                                                                                                .deliveryModeId
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder container-mode-dropdown">
                                                                                                <SearchableSelectBox
                                                                                                    data={
                                                                                                        containerModesModified?.length
                                                                                                            ? containerModesModified
                                                                                                            : []
                                                                                                    }
                                                                                                    placeholder="Enter container mode"
                                                                                                    formik={formik}
                                                                                                    fieldName="containerModeId"
                                                                                                    customStyle={''}
                                                                                                    menuPosition="fixed"
                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                        'outside_div'
                                                                                                    )}
                                                                                                    closeMenuOnScroll={
                                                                                                        true
                                                                                                    }
                                                                                                />
                                                                                                {formik.errors
                                                                                                    .containerModeId ? (
                                                                                                    <div className="error-text">
                                                                                                        {
                                                                                                            formik
                                                                                                                .errors
                                                                                                                .containerModeId
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <input
                                                                                                placeholder="Enter quantity"
                                                                                                type="text"
                                                                                                name={'quantity'}
                                                                                                className="search-input input-text input-qty"
                                                                                                onChange={(event) => {
                                                                                                    // eslint-disable-next-line no-undefined
                                                                                                    formik.setFieldError(
                                                                                                        event.target
                                                                                                            .name,
                                                                                                        // eslint-disable-next-line no-undefined
                                                                                                        undefined
                                                                                                    );
                                                                                                    formik.handleChange(
                                                                                                        event
                                                                                                    );
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (
                                                                                                        e.key ===
                                                                                                        'Enter'
                                                                                                    ) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                                value={
                                                                                                    formik.values[
                                                                                                        'quantity'
                                                                                                    ]
                                                                                                }
                                                                                            ></input>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {getCbm()}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <button
                                                                                            className="view-nav grid-icon icon-nav "
                                                                                            title="Save"
                                                                                            type="submit"
                                                                                            form="container-form"
                                                                                        >
                                                                                            <svg className="svg-icon save-icon">
                                                                                                <use xlinkHref="#saveIcon"></use>
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button
                                                                                            className="view-nav grid-icon icon-nav "
                                                                                            title="Remove Container"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                dispatch(
                                                                                                    deleteContainerNac({
                                                                                                        bookingProposalId:
                                                                                                            bpId,
                                                                                                        cdId: item.containerId,
                                                                                                    })
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <svg className="svg-icon eye-icon ">
                                                                                                <use xlinkHref="#deleteIcon"></use>
                                                                                            </svg>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr className="has-child row-expanded">
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {`${item?.container?.description} (${item?.container?.code})`}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                item.deliveryMode
                                                                                                    ?.deliveryMode
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                item.containerMode
                                                                                                    ?.containerMode
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {item.quantity}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {Math.round(
                                                                                                item.quantity *
                                                                                                    item.container
                                                                                                        ?.capacity || 0
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <ShowForPermissions
                                                                                        type="bp"
                                                                                        permission="create"
                                                                                    >
                                                                                        {userProfile.profileType ===
                                                                                            'agent' &&
                                                                                            (headerData.bpStatus ===
                                                                                                'Rejected_by_Forwarder' ||
                                                                                                headerData.bpStatus ===
                                                                                                    'Rejected_by_Client_Agent_to_Action' ||
                                                                                                headerData.bpStatus ===
                                                                                                    'Draft_Booking_Proposal') && (
                                                                                                <td className="grid-cell">
                                                                                                    <button
                                                                                                        className="view-nav grid-icon icon-nav"
                                                                                                        title="Edit"
                                                                                                        type="button"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.preventDefault();
                                                                                                            if (
                                                                                                                isEdit ===
                                                                                                                    containers?.length -
                                                                                                                        1 &&
                                                                                                                !cdId
                                                                                                            ) {
                                                                                                                const modifiedContainer =
                                                                                                                    JSON.parse(
                                                                                                                        JSON.stringify(
                                                                                                                            containers
                                                                                                                        )
                                                                                                                    );
                                                                                                                modifiedContainer.pop();
                                                                                                                dispatch(
                                                                                                                    setContainers(
                                                                                                                        modifiedContainer
                                                                                                                    )
                                                                                                                );
                                                                                                            }
                                                                                                            setCdId(
                                                                                                                item.containerId
                                                                                                            );
                                                                                                            setIsEdit(
                                                                                                                index
                                                                                                            );
                                                                                                            formik.setValues(
                                                                                                                {
                                                                                                                    containerTypeId:
                                                                                                                        item
                                                                                                                            .container
                                                                                                                            ?.containerTypeId,
                                                                                                                    deliveryModeId:
                                                                                                                        item
                                                                                                                            .deliveryMode
                                                                                                                            ?.deliveryModeId,
                                                                                                                    containerModeId:
                                                                                                                        item
                                                                                                                            .containerMode
                                                                                                                            ?.containerModeId,
                                                                                                                    cbm: item
                                                                                                                        .container
                                                                                                                        ?.capacity,
                                                                                                                    quantity:
                                                                                                                        item.quantity,
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <svg className="svg-icon edit-icon ">
                                                                                                            <use xlinkHref="#editIcon"></use>
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="view-nav grid-icon icon-nav "
                                                                                                        title="Remove Container"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.preventDefault();
                                                                                                            dispatch(
                                                                                                                deleteContainerNac(
                                                                                                                    {
                                                                                                                        bookingProposalId:
                                                                                                                            bpId,
                                                                                                                        cdId: item.containerId,
                                                                                                                    }
                                                                                                                )
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <svg className="svg-icon eye-icon ">
                                                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                </td>
                                                                                            )}
                                                                                    </ShowForPermissions>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )} */}
            </div>
            {/* {showModal ? (
                <DestinationAgentModal
                    agentCode={destinationAgentCode || ''}
                    setShowModal={setShowModal}
                    isAdd={!destinationAgentCode ? true : false}
                    callFetchFunction={fetchData}
                ></DestinationAgentModal>
            ) : null}
            {showFakAddModal && <AddModal data={additionalDetails} setShowAddModal={setShowFakAddModal}></AddModal>}
            <RejectBookingProposalModal
                modalView={rejection.modalView}
                setModalView={rejection.setModalView}
                header={{
                    bpNumber: headerData.bpNumber,
                    clientName: headerData.clientName,
                    gateInDateExpired: headerData.gateInDateExpired,
                    clientDetailsList: headerData.clientDetailsList,
                }}
                userProfile={userProfile}
                reason={reasonState.reason}
                setReason={reasonState.setReason}
                headerText="Reject Booking Proposal"
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            <RejectBpClientModal
                modalView={rejectBp.modalView}
                setModalView={rejectBp.setModalView}
                reason={headerData.reason}
                setReason={reasonState.setReason}
            />
            <CancelModal
                displayFunction={() => {
                    addDetails.setModalView(false);
                }}
                header="Additional Details"
                cancelModalVisibility={cancel.modalView}
                setCancelModalVisibility={cancel.setModalView}
            />
            <RejectBookingProposalModal
                modalView={assignAgent.assignAgentModalView}
                setModalView={assignAgent.setAssignAgentModalView}
                header={{
                    bpNumber: headerData.bpNumber != null ? headerData.bpNumber : '',
                    clientName: headerData.clientName != null ? headerData.clientName : '',
                    gateInDateExpired: headerData.gateInDateExpired,
                    clientDetailsList: headerData.clientDetailsList,
                }}
                userProfile={{ profileType: userProfile.profileType, userName: userProfile.userName }}
                reason={reasonRejection.reasonRejection}
                setReason={reasonRejection.setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={rejectedBpForwarder.setRejectedBpForwarderModalView}
                gateInDateExpired={headerData?.gateInDateExpired}
            /> */}
            {/* {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />} */}
            {showNacAddModal ? (
                <AdditionalDetailModal
                    referenceNumber={additionalDetails?.referenceNumber || ''}
                    setShowNacAddModal={setShowNacAddModal}
                    onAddDetails={onAddDetails}
                ></AdditionalDetailModal>
            ) : null}
            {showModal ? (
                <DestinationAgentModal
                    agentCode={additionalDetails?.destinationAgentCode || ''}
                    setShowModal={setShowModal}
                    isAdd={!additionalDetails?.destinationAgentCode ? true : false}
                ></DestinationAgentModal>
            ) : null}
            {showFakAddModal && <AddModal data={additionalDetails} setShowAddModal={setShowFakAddModal}></AddModal>}
            {/* Contract modal popup has been resued here from shipping line */}
            {showContractViewModal ? (
                <ContractViewModal
                    data={contractDetailsData}
                    isLoading={isLoading}
                    setShowContractViewModal={setShowContractViewModal}
                ></ContractViewModal>
            ) : null}
            <RejectBookingProposalModal
                modalView={rejection.modalView}
                setModalView={rejection.setModalView}
                header={{
                    bpNumber: headerData.bpNumber,
                    clientName: headerData.clientName,
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={userProfile}
                reason={reasonState.reason}
                setReason={reasonState.setReason}
                headerText="Reject Booking Proposal"
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            {showNoData && !isLoading && (
                <AddUserAdditionalModal
                    modalView={addDetails.modalView}
                    setModalView={addDetails.setModalView}
                    quotation={quotationList}
                    currency={currency}
                    bpId={bpId}
                    userProfile={userProfile}
                    type={mode}
                    additionalDetailsData={additionalDetailsData}
                    setShowNoData={setShowNoData}
                    setCancelModalVisibility={cancel.setModalView}
                    contractType={additionalDetails?.contractType ?? ''}
                />
            )}
            {/* {showNoData && quotationList.length === 0 && (
                <NoDataAdditionalDetailModal
                    modalView={addDetails.modalView}
                    setModalView={addDetails.setModalView}
                    data={quotationList}
                    loading={isLoading}
                    setShowNoData={setShowNoData}
                    header={{ clientId: headerData.clientId, clientName: headerData.clientName }}
                />
            )} */}
            <ContractDetailModal
                modalView={contractDetails.modalView}
                setModalView={contractDetails.setModalView}
                data={quotation}
            />
            {/* <RejectedBookingProposalModal
                modalView={reason.modalView}
                setModalView={reason.setModalView}
                reason={headerData.reason}
            /> */}
            {/* <ReapprovalBpClientModal
                modalView={reapproveBp.modalView}
                setModalView={reapproveBp.setModalView}
                reason={headerData.reason}
                bpId={bpId}
                profileType={userProfile.profileType}
            /> */}
            <RejectBpClientModal
                modalView={rejectBp.modalView}
                setModalView={rejectBp.setModalView}
                reason={headerData.reason}
                setReason={reasonState.setReason}
            />
            <CancelModal
                displayFunction={() => {
                    cancel.setModalView(false);
                    addDetails.setModalView(false);
                    setShowNoData(false);
                    additionalDetailsData.updateAdditionalDetails(initialState);
                }}
                header="Additional Details"
                cancelModalVisibility={cancel.modalView}
                setCancelModalVisibility={cancel.setModalView}
            />
            <RejectBookingProposalModal
                modalView={assignAgent.assignAgentModalView}
                setModalView={assignAgent.setAssignAgentModalView}
                header={{
                    bpNumber: headerData.bpNumber != null ? headerData.bpNumber : '',
                    clientName: headerData.clientName != null ? headerData.clientName : '',
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={{ profileType: userProfile.profileType, userName: userProfile.userName }}
                reason={reasonRejection.reasonRejection}
                setReason={reasonRejection.setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={rejectedBpForwarder.setRejectedBpForwarderModalView}
                gateInDateExpired={headerData?.gateInDateExpired}
            />
        </>
    );
};
export default Index;
