import React, { useState, useEffect } from 'react';
import './header.scss';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { fetchQuoteHeader } from 'src/store/reducers/qmsReducer';
// import FA package

interface HeaderProps {
    quoteHeaderObj?: any;
    setUpdate?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    update?: boolean;
}

const Index: React.FC<HeaderProps> = ({ quoteHeaderObj, setUpdate, update }) => {
    const { quoteHeader } = useSelector((state: RootState) => state.qmsData);
    const { id } = useParams();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id && quoteHeader?.length === 0) {
            dispatch(fetchQuoteHeader({ payload: { key: id } }));
        }
    }, [id]);

    return (
        <div className="booking-proposal-header-fields quote-module">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    <h2 className="page-heading">{quoteHeader[0]?.QH_Number}</h2>
                </div>

                <div className="header-container-view-eye-reason-wrapper">
                    <div className="header-container-view">
                        <span className="btn-pills container-pill waiting-pill">
                            <span className="pill-text">{quoteHeader[0]?.CD_ValueQuoteStatus}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Quote Type</h6>
                        <div className="details-box-content" title={quoteHeader[0]?.QH_CD_QuoteType}>
                            {quoteHeader[0]?.QH_CD_QuoteType || '-'}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Client</h6>
                        <div className="details-box-content" title={quoteHeader[0]?.QH_OR_ClientCode}>
                            {quoteHeader[0]?.QH_OR_ClientCode || '-'}
                        </div>
                    </div>

                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Valid From</h6>
                        <div
                            className="details-box-content"
                            title={
                                quoteHeader[0]?.QH_ValidFrom
                                    ? moment(quoteHeader[0]?.QH_ValidFrom).format('YYYY-MM-DD')
                                    : '-'
                            }
                        >
                            {quoteHeader[0]?.QH_ValidFrom
                                ? moment(quoteHeader[0]?.QH_ValidFrom).format('YYYY-MM-DD')
                                : '-'}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Valid To</h6>
                        <div
                            className="details-box-content"
                            title={
                                quoteHeader[0]?.QH_ValidTo
                                    ? moment(quoteHeader[0]?.QH_ValidTo).format('YYYY-MM-DD')
                                    : '-'
                            }
                        >
                            {quoteHeader[0]?.QH_ValidTo ? moment(quoteHeader[0]?.QH_ValidTo).format('YYYY-MM-DD') : '-'}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
