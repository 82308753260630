import { AxiosResponse } from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    activityListCountFetch,
    activityListCountFetchBkg,
    activityListFetching,
    activityListFetchingError,
    activityListFetchingErrorBkg,
    activityListFetchingSuccess,
    isLoading,
} from 'src/store/reducers/dashboard/dashboardActivityFeed';
import {
    dashboardActivityCountList,
    dashboardActivityCountListBkg,
    dashboardActivityFeedListBKG,
    dashboardActivityFeedListORD,
} from 'src/services/apis/dashboard/dashboardActivityFeedApi';

function* getDashboardactivityList({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoading, payload: true });
        const ordResponse: AxiosResponse<any> = yield call(dashboardActivityFeedListORD, payload);
        const bkgResponse: AxiosResponse<any> = yield call(dashboardActivityFeedListBKG, payload);
        if (ordResponse?.data?.success || bkgResponse?.data?.success) {
            if (payload.search.isPurchaseOrder === true) {
                yield put({
                    type: activityListFetchingSuccess,
                    payload: [...ordResponse?.data?.data?.info] ?? {},
                });
            } else {
                yield put({
                    type: activityListFetchingSuccess,
                    payload: [...ordResponse?.data?.data?.info, ...bkgResponse?.data?.data?.info] ?? {},
                });
            }
        } else {
            yield put({ type: activityListFetchingError, payload: ordResponse.data.message });
        }
    } catch (error: any) {
        yield put({ type: activityListFetchingError, payload: error.message });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoading, payload: false });
    }
}
function* getActivityCount({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(dashboardActivityCountList, payload);
        if (response?.data?.success) {
            yield put({
                type: activityListCountFetch,
                payload: response?.data?.data ?? {},
            });
        } else {
            yield put({ type: activityListFetchingError, payload: response.data.message });
        }
    } catch (error: any) {
        yield put({ type: activityListFetchingError, payload: error.message });
    } finally {
        yield put(hideLoading());
    }
}
function* getActivityCountBkg({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(dashboardActivityCountListBkg, payload);
        if (response?.data?.success) {
            yield put({
                type: activityListCountFetchBkg,
                payload: response?.data?.data ?? {},
            });
        } else {
            yield put({ type: activityListFetchingErrorBkg, payload: response.data.message });
        }
    } catch (error: any) {
        yield put({ type: activityListFetchingErrorBkg, payload: error.message });
    } finally {
        yield put(hideLoading());
    }
}
function* dashBoardActivitySaga() {
    yield takeLatest(activityListFetching, getDashboardactivityList);
    yield takeLatest(activityListCountFetch, getActivityCount);
    yield takeLatest(activityListCountFetchBkg, getActivityCountBkg);
}

export default dashBoardActivitySaga;
