import React, { useState } from 'react';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    unpackOneContainer: (value: string) => void;
    id: string;
}

const Index: React.FC<ModalProps> = ({ modalView, setModalView, unpackOneContainer, id }) => {
    const [buttonDisplay, setButtonDisplay] = useState('Confirm');

    const unPackData = () => {
        setButtonDisplay('Unpacking');
        unpackOneContainer(id);
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder">
                        <div className="modal-header">
                            <div className="title">Confirm Unpack</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Warning</h6>
                                        <div className="description-type-1">
                                            {`Please Confirm You Would Like to Unpack ${id}`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Pack"
                                onClick={() => {
                                    unPackData();
                                }}
                                disabled={buttonDisplay === 'Unpacking...'}
                            >
                                <span className="button-text footer-button-text">{buttonDisplay}</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
