/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export interface AgentOrganisationContactListDataProp {
    oc_contactName: string;
    oc_title: string;
    oc_phonenumber: string;
    oc_mobilenumber: string;
    oc_email: string;
    oc_jc: string;
    oc_PK: string;
}

export interface AgentUsersDataProp {
    us_Azure_Key: string;
    us_UT: string;
    us_TokenValue: string;
    us_State: string;
    us_AzureUserId: string;
    us_PK: string;
    us_Date_Of_Birth: string;
    us_PhoneNo: string;
    us_UserName: string;
    us_Address1: string;
    us_MiddleName: string;
    us_Postal_Code: string;
    us_Address_Type: string;
    us_City: string;
    us_LastName: string;
    us_FirstName: string;
    us_LandLineNo: string;
    us_Email: string;
    us_Address2: string;
    us_Country: string;
    us_Address3: string;
    us_Role: string;
}

export interface AgentUsersListDataProp {
    [key: string]: AgentUsersDataProp[];
}

export interface AgentForwarderRankingDataProp {
    rankingCode: string;
    rank: string;
    ir_PK: string;
}

export interface AgentAddressListDataProp {
    addressline1: string;
    addressline2: string;
    addressline3: string;
    address_type: string;
    city: string;
    state: string;
    postcode: string;
    countrycode: string;
    contact_email: string;
    contact_mobno: string;
    contact_person: string;
    closest_unlocode_portcode: string;
    oa_PK: string;
}

export interface AgentOrgDataProp {
    addressList: AgentAddressListDataProp[];
    or_Phone: string;
    or_ABN: number;
    or_Unloco: string;
    or_WebSiteUrl: string;
    or_ForwarderRanking: AgentForwarderRankingDataProp;
    or_PK: string;
    or_UsersList: AgentUsersListDataProp;
    or_Orgname: string;
    or_Orgcode: string;
    or_Status: string;
    or_Orgtype: string;
    or_OrganisationContactList: AgentOrganisationContactListDataProp[];
    or_ParentOrgCode: string;
}
export interface AgentBasicDetailsDataProp {
    ag_PK: string;
    ag_Status: string;
    ag_Org: AgentOrgDataProp;
}

interface AgentBasicDetailsProp {
    status: number;
    message: string;
    info: AgentBasicDetailsDataProp;
    data: any;
}

interface AgentBasicDetailsState {
    isLoading: boolean;
    basicDetails?: AgentBasicDetailsProp;
    ForwarderRank?: any;
    basicDetailsSuccess: any;
    basicDetailsError: any;
    isError?: boolean;
    id?: number;
}

const initialState: AgentBasicDetailsState = {
    isLoading: true,
    basicDetails: undefined,
    ForwarderRank: undefined,
    basicDetailsSuccess: undefined,
    basicDetailsError: undefined,
    isError: false,
    id: undefined,
};

export const agentBasicDetailsSlice = createSlice({
    name: 'agentBasicDetails',
    initialState,
    reducers: {
        fetchAgentBasicDetailsList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchAgentBasicDetailsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.basicDetails = action.payload;
            state.basicDetailsSuccess = {};
            state.basicDetailsError = {};
        },
        fetchAgentBasicDetailsListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsSuccess = {};
            state.basicDetailsError = action.payload;
        },
        fetchForwarderRankList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchForwarderRankListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.ForwarderRank = action.payload;
        },
        fetchForwarderRankListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsError = action.payload;
        },
        updateForwarderRank: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        updateForwarderRankSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.basicDetailsSuccess = action.payload;
        },
        updateForwarderRankFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsError = action.payload;
        },
        resetAgentBasicDetails(state) {
            state.basicDetails = undefined;
        },
    },
});

export const {
    fetchAgentBasicDetailsList,
    fetchAgentBasicDetailsListSuccess,
    fetchAgentBasicDetailsListFailed,
    fetchForwarderRankList,
    fetchForwarderRankListSuccess,
    fetchForwarderRankListFailed,
    updateForwarderRank,
    updateForwarderRankSuccess,
    updateForwarderRankFailed,
    resetAgentBasicDetails,
} = agentBasicDetailsSlice.actions;

export const selectAgentBasicDetailsLoading = (state: RootState) => state.agentBasicDetailsData.isLoading;
export const selectAgentBasicDetails = (state: RootState) => state.agentBasicDetailsData.basicDetails;
export const selectAgentBasicDetailsSuccess = (state: RootState) => state.agentBasicDetailsData.basicDetailsSuccess;
export const selectAgentBasicDetailsIsError = (state: RootState) => state.agentBasicDetailsData.isError;
export const selectAgentBasicDetailsError = (state: RootState) => state.agentBasicDetailsData.basicDetailsError;
export const selectForwarderRank = (state: RootState) => state.agentBasicDetailsData.ForwarderRank;

export default agentBasicDetailsSlice.reducer;
