import { AxiosResponse } from 'axios';
import { put, call, takeLatest, debounce } from 'redux-saga/effects';
import {
    lifeCycleAgentTimeline,
    lifeCycleBookingDataApi,
    lifeCycleBookingDataMblApi,
    lifeCycleBookingTimelineApi,
    lifeCycleBookingTimelineMblApi,
    lifeCycleClientTimeline,
    lifeCyclePortTimeline,
    lifeCycleSearchMgtApi,
    wareHouseTimelineApi,
    lifeCyclePurchaseOrderTimelineApi,
} from 'src/services/apis/lifecycleSearch/lifecycleSearchApi';

import {
    agentTimeLineFetch,
    agentTimeLineFetchError,
    agentTimeLineFetchSuccess,
    bookingBasicDetailsFetch,
    bookingBasicDetailsFetchMbl,
    bookingTimeLineFetch,
    bookingTimeLineFetchMbl,
    wareHouseTimelineFetch,
    clientTimeLineFetch,
    clientTimeLineFetchError,
    clientTimeLineFetchSuccess,
    vendorTimeLineFetch,
    isLoadingFunction,
    onLifeCycleSearch,
    onLifeCycleSearchFailure,
    onLifeCycleSearchSuccess,
    portTimeLineFetch,
} from '../../reducers/lifeCycleSearch/lifeCycleSearchReducer';

function* handleLifeCycleSearch({ payload }: any) {
    try {
        const responseMgt: AxiosResponse = yield call(lifeCycleSearchMgtApi, payload);
        // console.log(responseMgt, 'apt');
        // const responseOrd: AxiosResponse = yield call(lifeCycleSearchOrdApi, payload);
        yield put({ type: onLifeCycleSearchSuccess, payload: responseMgt });

        // if (!responseMgt?.data?.success && !responseOrd?.data?.success) {
        //     yield put({
        //         type: onLifeCycleSearchFailure,
        //         payload: responseMgt?.data?.data || responseOrd?.data?.data,
        //     });
        // } else {
        //     yield put({
        //         type: onLifeCycleSearchSuccess,
        //         payload: { ...responseMgt?.data?.data, ...responseOrd?.data?.data },
        //     });
        // }
    } catch (error) {
        yield put({ type: onLifeCycleSearchFailure, payload: error });
    }
}

function* bookingTimeLineData({ payload }: any) {
    try {
        yield put({ type: isLoadingFunction, payload: true });
        const res: AxiosResponse = yield call(lifeCycleBookingTimelineApi, payload);
        yield put({ type: bookingTimeLineFetch, payload: res });
        yield put({ type: isLoadingFunction, payload: false });
    } catch (error) {
        console.log(error);
    }
}
function* bookingTimeLineDataMbl({ payload }: any) {
    try {
        yield put({ type: isLoadingFunction, payload: true });
        const res: AxiosResponse = yield call(lifeCycleBookingTimelineMblApi, payload);
        yield put({ type: bookingTimeLineFetchMbl, payload: res });
        yield put({ type: isLoadingFunction, payload: false });
    } catch (error) {
        console.log(error);
    }
}
function* bookingBasicData({ payload }: any) {
    try {
        yield put({ type: isLoadingFunction, payload: true });
        const res: AxiosResponse = yield call(lifeCycleBookingDataApi, payload);
        yield put({ type: bookingBasicDetailsFetch, payload: res });
        yield put({ type: isLoadingFunction, payload: false });
    } catch (error) {
        console.log(error);
    }
}
function* bookingBasicDataMbl({ payload }: any) {
    try {
        yield put({ type: isLoadingFunction, payload: true });
        const res: AxiosResponse = yield call(lifeCycleBookingDataMblApi, payload);
        yield put({ type: bookingBasicDetailsFetchMbl, payload: res });
        yield put({ type: isLoadingFunction, payload: false });
    } catch (error) {
        console.log(error);
    }
}
function* portTimelineData({ payload }: any) {
    try {
        yield put({ type: isLoadingFunction, payload: true });
        const res: AxiosResponse = yield call(lifeCyclePortTimeline, payload);
        yield put({ type: portTimeLineFetch, payload: res });
        yield put({ type: isLoadingFunction, payload: false });
    } catch (error) {
        console.log(error);
    }
}

function* clientTimelineData({ payload }: any) {
    try {
        const res: AxiosResponse = yield call(lifeCycleClientTimeline, payload);
        yield put({ type: clientTimeLineFetchSuccess, payload: res });
    } catch (error) {
        console.log(error);
        yield put({ type: clientTimeLineFetchError, payload: error });
    }
}

function* agentTimelineData({ payload }: any) {
    try {
        const res: AxiosResponse = yield call(lifeCycleAgentTimeline, payload);
        yield put({ type: agentTimeLineFetchSuccess, payload: res });
    } catch (error) {
        console.log(error);
        yield put({ type: agentTimeLineFetchError, payload: error });
    }
}

function* vendorTimeLineData({ payload }: any) {
    try {
        yield put({ type: isLoadingFunction, payload: true });
        const res: AxiosResponse = yield call(lifeCyclePurchaseOrderTimelineApi, payload);
        yield put({ type: vendorTimeLineFetch, payload: res });
        yield put({ type: isLoadingFunction, payload: false });
    } catch (error) {
        console.log(error);
    }
}
function* wareHouseTimeLineData({ payload }: any) {
    try {
        yield put({ type: isLoadingFunction, payload: true });
        const res: AxiosResponse = yield call(wareHouseTimelineApi, payload);
        yield put({ type: wareHouseTimelineFetch, payload: res });
        yield put({ type: isLoadingFunction, payload: false });
    } catch (error) {
        console.log(error);
    }
}

function* trackingDataSage() {
    yield debounce(1000, onLifeCycleSearch, handleLifeCycleSearch);
    yield takeLatest(bookingTimeLineFetch, bookingTimeLineData);
    yield takeLatest(bookingTimeLineFetchMbl, bookingTimeLineDataMbl);
    yield takeLatest(wareHouseTimelineFetch, wareHouseTimeLineData);
    yield takeLatest(vendorTimeLineFetch, vendorTimeLineData);
    yield takeLatest(bookingBasicDetailsFetch, bookingBasicData);
    yield takeLatest(bookingBasicDetailsFetchMbl, bookingBasicDataMbl);
    yield takeLatest(portTimeLineFetch, portTimelineData);
    yield takeLatest(clientTimeLineFetch, clientTimelineData);
    yield takeLatest(agentTimeLineFetch, agentTimelineData);
}

export default trackingDataSage;
