/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-undefined */
import React, { useEffect, useState, useRef } from 'react';
import ProductDetailsModal from './ProductDetailsModal';
import LinkVendorModal from './LinkVendorModal';
import CbmUnavailableModal from './CbmUnavailableModal';
import SkuUnavailableModal from './SkuUnavailableModal';
import './product-details.scss';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import DatePicker from '../../../components/common/DatePicker';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    fetchProductPoList,
    // fetchCountry,
    fetchCurrency,
    // fetchPort,
    // fetchDestinationAddress,
    // selectCountry,
    selectCurrency,
    // selectDestinationAddress,
    // selectPort,
    selectProductPo,
    selectProductPoLoading,
    selectSku,
    fetchSkuList,
    ProductPoDataDetails,
    selectProductPoIsError,
    selectProductPoSuccess,
    selectProductPoError,
    clearFailure,
    SkuDataDetails,
    createProductPo,
    updateProductPo,
    linkVendorProductPo,
} from '../../../store/reducers/purchaseOrder/productPoReducer';
import { fetchHeader, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import moment from 'moment';
import { error, Position, success } from '../../../utils/toast';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from '../../../ShowForPermissions';
import { MenuPlacement } from 'react-select';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
import { usePrompt } from 'src/hooks/usePrompt';
import CancelModal from '../BasicDetail/cancelModal';
import { InterfaceType } from 'src/utils/constants';
import UnsavedChangeModal from 'src/pages/AOC/ProductAllocation/UnsavedChangeModal';
import RemovePoLine from 'src/components/common/ConfirmRemovePoLine';
import BLPInboundPoTabs from '../BLPInboundPoTabs';
import BLPInboundPONextButton from '../BLPInboundPONextButton';
interface ProductsPo {
    sku: string;
    skuId: string;
    description: string;
    // countryCode: string;
    countryName: string;
    // destinationPort: string | null;
    // destinationAddress: string | null;
    // destinationAddressIdInProduct: string | null;
    goodsReadyDate: string | null;
    projWhouseETA: string | null;
    cbm: any;
    weight: number;
    qty: any;
    unitCost: any;
    netCost: any;
    currency: string;
    convertedQty: number;
    uom: string;
    barcodes: string;
    conversionQuantity: number;
}
// type MultiDestination = Pick<
//     ProductsPo,
//     'destinationPort' | 'destinationAddress' | 'destinationAddressIdInProduct' | 'goodsReadyDate' | 'projWhouseETA'
// >;
const Index: React.FC = () => {
    const { id } = useParams();
    const scrollElement = useRef<any>(null);
    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [modalViewVendor, setModalViewVendor] = useState(false);
    const [modalViewCbm, setModalViewCbm] = useState(false);
    const [modalViewSku, setModalViewSku] = useState(false);
    const [showBarcode, setShowBarcode] = useState(false);
    const [currentBarcode, setCurrentBarcode] = useState('');
    const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>('auto');
    const [unsavedChangeModalVisibility, setUnsavedChangeModalVisibility] = useState(false);
    const [callBackFn, setCallBackFn] = useState<any>();
    const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
    const [poProductId, setPoProductId] = useState('');
    // const [isEachPresent, setIsEachPresent] = useState(true);
    // const [isVendorLinked, setIsVendorLinked] = useState(true);
    const [cbmForEach, setCbmForEach] = useState(0);
    const [weightForEach, setWeightForEach] = useState(0);
    const [distanceFromTopForBarcode, setDistanceFromTopForBarcode] = useState(0);
    const [distanceFromLeftForBarcode, setDistanceFromLeftForBarcode] = useState(0);
    const [skuDetail, setSkuDetail] = useState<any>({});
    const [singleProduct, setSingleProduct] = useState<any>({});
    const [skuData, setSkuData] = useState<any>([]);
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [editIndex, setEditIndex] = useState(0);
    const isLoading = useAppSelector(selectProductPoLoading);
    const products = useAppSelector(selectProductPo);
    const sku = useAppSelector(selectSku);
    // const country = useAppSelector(selectCountry);
    const currency = useAppSelector(selectCurrency);
    // const port = useAppSelector(selectPort);
    // const destinationAddress = useAppSelector(selectDestinationAddress);
    const headerData = useAppSelector(selectHeader);
    const isError = useAppSelector(selectProductPoIsError);
    const productPoSuccess = useAppSelector(selectProductPoSuccess);
    const productPoError = useAppSelector(selectProductPoError);
    const [viaIntegration, setViaIntegration] = useState(false);
    useEffect(() => {
        dispatch(fetchProductPoList({ poId: id }));
        // dispatch(fetchCountry([]));
        // dispatch(fetchPort([]));
    }, []);
    useEffect(() => {
        if (sku?.length) {
            setSkuData(sku.map((res: any) => ({ value: res.pm_PK, label: res.pm_sku })));
        }
    }, [sku]);

    useEffect(() => {
        if (headerData?.clientCode) {
            headerData?.clientCode && dispatch(fetchSkuList({ clientCode: headerData?.clientCode }));
        }
        // headerData?.clientCode && dispatch(fetchDestinationAddress({ orgCode: headerData?.clientCode }));
        setViaIntegration(headerData?.createdUser === InterfaceType.InterfacedPO);
    }, [headerData?.clientCode]);

    useEffect(() => {
        if (headerData?.currency !== undefined) {
            dispatch(fetchCurrency({ currency: headerData?.currency }));
            localStorage.setItem('clientName', headerData?.clientName);
        }
    }, [headerData?.currency]);

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= scrollElement.current.scrollWidth;
    };

    const scrollDown = () => {
        const targetElm = document.querySelector('.grid-scroll-content');
        if (targetElm) {
            setTimeout(() => {
                targetElm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
            }, 100);
        }
    };

    let multiDestinationValidation = {};
    // let multiDestinationData: MultiDestination;
    // if (headerData?.isMultiAddress) {
    multiDestinationValidation = {
        // destinationPort: Yup.string().required('Destination is required'),
        // destinationAddress: Yup.string().required('Destination address is required'),
        goodsReadyDate: Yup.date().required('Goods ready date is required'),
        projWhouseETA: Yup.date().required('Projected ETA into warehouse is required'),
    };
    const multiDestinationData = {
        // destinationPort: singleProduct.destinationPort || '',
        // destinationAddressIdInProduct: singleProduct.destinationAddressIdInProduct || '',
        // destinationAddress: singleProduct.destinationAddress || '',
        goodsReadyDate: singleProduct.goodsReadyDate ? moment(singleProduct.goodsReadyDate).format('YYYY-MM-DD') : '',
        projWhouseETA: singleProduct.projWhouseETA ? moment(singleProduct.projWhouseETA).format('YYYY-MM-DD') : '',
    };
    // } else {
    //     multiDestinationData = {
    //         destinationPort: null,
    //         destinationAddressIdInProduct: null,
    //         destinationAddress: null,
    //         goodsReadyDate: null,
    //         projWhouseETA: null,
    //     };
    // }
    const ProductsPoSchema = Yup.object().shape({
        skuId: Yup.string().required('SKU is required'),
        // countryCode: Yup.string().required('Country of origin is required'),
        qty: Yup.string()
            .required('Quantity is required')
            .test('no-leading-zero', 'Quantity must be greater than zero', (context: any) => {
                if (headerData?.poStatus === 'Draft') {
                    return context && parseFloat(context) !== 0;
                } else {
                    return true;
                }
            }),
        unitCost: Yup.number()
            .typeError('Unit cost is required')
            .required('Unit cost is required')
            .test('no-leading-zero', 'Unit cost must be greater than zero', (context: any) => {
                if (headerData?.poStatus === 'Draft') {
                    return context && parseFloat(context) !== 0;
                } else {
                    return true;
                }
            }),
        currency: Yup.string().required('Currency is required'),
        ...multiDestinationValidation,
    });

    const initialValues: ProductsPo = {
        sku: singleProduct.sku || '',
        skuId: singleProduct.skuId || '',
        description: singleProduct.description || '',
        // countryCode: singleProduct.countryCode || '',
        countryName: singleProduct.countryName || '',
        cbm: singleProduct.cbm >= 0 ? singleProduct.cbm : '',
        weight: singleProduct.weight || 0,
        qty: singleProduct.qty >= 0 ? singleProduct.qty : '',
        unitCost: singleProduct.unitCost >= 0 ? singleProduct.unitCost : '',
        netCost: singleProduct.netCost >= 0 ? singleProduct.netCost : '',
        currency: singleProduct.currency?.currencycode || '',
        convertedQty: singleProduct?.convertedQty || '',
        uom: singleProduct?.uom || '',
        barcodes: singleProduct?.barcodes || '',
        conversionQuantity: singleProduct?.conversionQuantity || '',
        ...multiDestinationData,
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ProductsPoSchema,
        onSubmit: (values, { resetForm }) => {
            if (formik.isSubmitting) {
                if (checkForSameSku()) {
                    return;
                }
                const hsCode = sku ? sku.find((e: SkuDataDetails) => e.pm_PK === values.skuId)?.pm_purchasecode : '';
                if (add) {
                    dispatch(
                        createProductPo({
                            ...values,
                            poId: id,
                            poLineNumber: products?.length ? products[products?.length - 1].poLineNumber + 1 : 1,
                            hsCode,
                        })
                    );
                } else if (edit) {
                    dispatch(updateProductPo({ ...values, poId: id, poProductId: singleProduct.poProductId, hsCode }));
                }
            }
            resetForm();
        },
    });

    const removeProduct = (poProductId: string) => {
        if (poProductId) {
            setRemoveModalVisibility(true);
            setPoProductId(poProductId);
            setEdit(false);
            setEditIndex(0);
            formik.resetForm();
            setSingleProduct({});
        }
    };

    useEffect(() => {
        if (isError) {
            error(productPoError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (productPoSuccess?.status === 200 || productPoSuccess?.status === 201) {
            if (productPoSuccess?.data?.type === 'link') {
                success(`${headerData?.vendorName} has been linked to the SKU`, Position.TOP_RIGHT);
                setModalViewVendor(false);
                dispatch(fetchSkuList({ clientCode: headerData?.clientCode }));
            } else {
                if (productPoSuccess?.data?.type === 'create') {
                    success(
                        `PO Line ${
                            productPoSuccess?.data?.info?.poLineNumber ? productPoSuccess?.data?.info?.poLineNumber : ''
                        } created successfully`,
                        Position.TOP_RIGHT
                    );
                    setAdd(false);
                } else if (productPoSuccess?.data?.type === 'update') {
                    success(
                        `PO Line ${
                            productPoSuccess?.data?.info?.poLineNumber ? productPoSuccess?.data?.info?.poLineNumber : ''
                        } updated successfully`,
                        Position.TOP_RIGHT
                    );
                } else if (productPoSuccess?.data?.type === 'delete') {
                    success(
                        `PO Line ${
                            productPoSuccess?.data?.info?.poLineNumber ? productPoSuccess?.data?.info?.poLineNumber : ''
                        } removed successfully`,
                        Position.TOP_RIGHT
                    );
                }
                dispatch(fetchProductPoList({ poId: id }));
                dispatch(
                    fetchHeader({
                        poId: id,
                    })
                );
                setTimeout(() => {
                    setAdd(false);
                    setEdit(false);
                    setEditIndex(0);
                }, 1000);
                formik.resetForm();
            }
            dispatch(clearFailure([]));
            scrollLeft();
        }
    }, [isError, productPoSuccess, productPoError]);

    const customStyle = {
        menuPortal: (provided: any) => ({
            ...provided,
            width: '300px',
        }),
        menuList: (base: any) => ({
            ...base,
            '::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: '#F6F6FE',
            },
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(#000, 0.3)',
                backgroundColor: '#C4C6D0',
            },
        }),
    };

    const portalStyle = {
        ...customStyle,
        noOptionsMessage: (base: any) => ({ ...base, height: '180px' }),
    };

    const skuUpdate = (field: string) => {
        const skuDetail: any = sku?.find((res: any) => res.pm_PK === field);

        if (skuDetail) {
            setSkuDetail(skuDetail);
            formik.setFieldValue('description', skuDetail.pm_description);
            const pmPack = skuDetail?.pm_packets?.find((res: any) => res.pp_quantity?.toLowerCase() === 'each');
            if (pmPack) {
                if (pmPack?.pp_cbm > 0) {
                    setCbmForEach(pmPack?.pp_cbm);
                    const cbm = +pmPack?.pp_cbm * +formik.values.qty;
                    formik.setFieldValue('cbm', parseFloat(cbm.toFixed(5)));
                }
                if (pmPack?.pp_weight > 0) {
                    setWeightForEach(pmPack?.pp_weight);
                    const weight = +pmPack?.pp_weight * +formik.values.qty;
                    formik.setFieldValue('weight', parseFloat(weight.toFixed(5)));
                }
            }

            // setIsEachPresent(skuDetail.isEachPresent);
            // setIsVendorLinked(skuDetail.pm_vendors.some((e: any) => e.vn_vendorcode === headerData?.vendorCode));
            if (!skuDetail.isEachPresent) {
                setModalViewCbm(true);
            } else if (!skuDetail.pm_vendors.some((e: any) => e.vn_vendorcode === headerData?.vendorCode)) {
                setModalViewVendor(true);
            }
        }
    };

    const resizeFilter = (idSelector: string, skuId: string, uom: string) => {
        const el_table = document.getElementById('table_div');
        let gridDistance = 0;
        let gridDistanceLeft = 0;
        if (el_table?.getBoundingClientRect().top) {
            gridDistance = el_table?.getBoundingClientRect().top - 25;
            gridDistanceLeft = el_table?.getBoundingClientRect().left + 10;
        }
        const el_barcode = document.getElementById(idSelector);
        if (el_barcode?.getBoundingClientRect().top) {
            setDistanceFromTopForBarcode(el_barcode?.getBoundingClientRect().top - gridDistance);
        }
        if (el_barcode?.getBoundingClientRect().left) {
            setDistanceFromLeftForBarcode(el_barcode?.getBoundingClientRect().left - gridDistanceLeft);
        }
        if (sku?.length) {
            for (let index = 0; index < sku.length; index++) {
                if (sku[index].pm_PK === skuId) {
                    const barcode = sku[index]?.pm_packets?.find(
                        (res: any) => res.pp_quantity?.toLowerCase() === uom?.toLowerCase()
                    )?.pp_barcode;
                    setCurrentBarcode(barcode ? barcode : '');
                }
            }
        }
    };

    const menuPlacementCheck = (mode: string) => {
        let id = '';

        if (mode === 'add') {
            id = 'add_row';
        } else {
            id = `edit_row_${editIndex}`;
        }
        const windowHeight = document.documentElement.clientHeight;
        const noMessageHeight = 200;

        const element = document.getElementById(id);
        if (element) {
            const bottom = element.getBoundingClientRect().bottom;
            const spaceBelow = windowHeight - bottom;
            spaceBelow < noMessageHeight ? setMenuPlacement('top') : setMenuPlacement('auto');
        }
    };

    const editPoLine = (index: number, product: ProductPoDataDetails) => {
        setEdit(true);
        setEditIndex(index);
        const skuDetail: any = sku?.find((res: any) => res.pm_sku === product.sku);
        const productCopy = JSON.parse(JSON.stringify(product));
        if (skuDetail) {
            productCopy.skuId = skuDetail.pm_PK;
            skuUpdate(skuDetail.pm_PK);
        } else {
            skuUpdate(product.skuId);
        }
        setSingleProduct(productCopy);
    };

    const viewPoLine = (e: any, name: string) => {
        e.preventDefault();
        setModalView(true);
        const skuDetail: any = sku?.find((res: any) => res.pm_sku === name);
        setSkuDetail(skuDetail);
    };

    useEffect(() => {
        if (!formik.values?.sku) {
            formik.setFieldValue('description', '');
        }
    }, [formik.values.sku]);

    useEffect(() => {
        if (add) {
            menuPlacementCheck('add');
        } else if (edit) {
            menuPlacementCheck('edit');
        }
    }, [add, edit]);

    const addPoLine = () => {
        setAdd(true);
        formik.resetForm();
        setSingleProduct({});
        if (currency?.length === 1) {
            formik.setFieldValue('currency', currency[0].value);
        }
        scrollLeft();
        scrollDown();
    };

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">
                    No matches found.
                    <br />
                    SKU added to the Product Master <br />
                    will only be available for selection
                </p>
                <br />
                <a
                    className="redirection-link"
                    onClick={() => {
                        setModalViewSku(true);
                    }}
                >
                    Go to Product Master
                </a>
            </div>
        );
    };

    const linkVendor = () => {
        dispatch(linkVendorProductPo({ vendorCode: headerData?.vendorCode, pm_pk: skuDetail.pm_PK }));
    };

    // const dateStyles = (idSelector: string) => {
    // const dateElement = document.querySelector<HTMLElement>(`#${idSelector}`);
    // const domRect = dateElement!.getBoundingClientRect();
    // const spaceBelow = window.innerHeight - domRect.bottom;
    // let dateDistanceTop = 0;
    // let dateDistanceBottom = 0;
    // let dateDistanceLeft = 0;
    // dateDistanceTop = dateElement!.getBoundingClientRect().top;
    // dateDistanceBottom = dateElement!.getBoundingClientRect().bottom;
    // dateDistanceLeft = dateElement!.getBoundingClientRect().left;
    // setTimeout(() => {
    //     const element = document.querySelector<HTMLElement>('.react-date-picker__calendar--open');
    //     if (element !== null) {
    //         if (spaceBelow > 300) {
    //             // if (element.style.top) {
    //             element.style.visibility = 'visible';
    //             element.style.top = `${dateDistanceBottom}px`;
    //             element.style.left = `${dateDistanceLeft - 18}px`;
    //             // }
    //         } else {
    //             setTimeout(() => {
    //                 element.style.visibility = 'visible';
    //                 if (element.style.top === 'auto') {
    //                     element.style.inset = `${dateDistanceTop - 255}px auto auto ${dateDistanceLeft - 18}px`;
    //                 }
    //             }, 100);
    //             element.style.top = `${dateDistanceTop - 255}px`;
    //             element.style.left = `${dateDistanceLeft - 18}px`;
    //         }
    //     }
    // }, 100);
    // };
    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let userType = '';
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     if (roleIndex) {
    //         userType = roles[roleIndex].profile?.toLowerCase();
    //     }
    // }

    const checkForSameSku = () => {
        if (
            products?.some(
                (res: any) => res.sku === formik.values.sku && singleProduct.poLineNumber !== res.poLineNumber
            ) &&
            products?.some(
                (res: any) =>
                    moment(res.goodsReadyDate).format('YYYY-MM-DD') === formik.values.goodsReadyDate &&
                    singleProduct.poLineNumber !== res.poLineNumber
            )
        ) {
            formik.setFieldError('skuId', 'Selected SKU and Goods Ready Date is already added');
            formik.setFieldError('goodsReadyDate', 'Selected SKU and Goods Ready Date  is already added');
            return true;
        } else {
            formik.setFieldError('skuId', undefined);
            formik.setFieldError('goodsReadyDate', undefined);
            return false;
        }
    };

    useEffect(() => {
        checkForSameSku();
    }, [formik.values.sku, formik.values.goodsReadyDate]);

    useEffect(() => {
        const pmPackCase = skuDetail?.pm_packets?.find((res: any) => res.pp_quantity?.toLowerCase() === 'case');
        const pmPackEach = skuDetail?.pm_packets?.find((res: any) => res.pp_quantity?.toLowerCase() === 'each');
        if (pmPackCase && formik.values.qty >= 0 && pmPackCase.pp_conversionquantity > 0) {
            const convertedQty = formik.values.qty / pmPackCase.pp_conversionquantity;
            formik.setFieldValue('convertedQty', Math.ceil(convertedQty));
            formik.setFieldValue('uom', 'CASE');
            formik.setFieldValue(
                'barcodes',
                skuDetail?.pm_uom
                    ?.filter(
                        (x: string) =>
                            x?.toLowerCase() === 'each' || x?.toLowerCase() === 'inner' || x?.toLowerCase() === 'case'
                    )
                    ?.join(', ')
            );
            formik.setFieldValue('conversionQuantity', pmPackCase.pp_conversionquantity);
        } else if (pmPackEach && formik.values.qty >= 0 && pmPackEach.pp_conversionquantity > 0) {
            const convertedQty = formik.values.qty / pmPackEach.pp_conversionquantity;
            formik.setFieldValue('convertedQty', Math.ceil(convertedQty));
            formik.setFieldValue('uom', 'EACH');
            formik.setFieldValue(
                'barcodes',
                skuDetail?.pm_uom
                    ?.filter(
                        (x: string) =>
                            x?.toLowerCase() === 'each' || x?.toLowerCase() === 'inner' || x?.toLowerCase() === 'case'
                    )
                    ?.join(', ')
            );
            formik.setFieldValue('conversionQuantity', pmPackEach.pp_conversionquantity);
        } else {
            formik.setFieldValue('convertedQty', '');
            formik.setFieldValue('uom', '');
            formik.setFieldValue('barcodes', '');
            formik.setFieldValue('conversionQuantity', '');
        }
    }, [skuDetail, formik.values.qty]);

    // const condition = !((products as any)?.length > 0);

    const showMandatoryFieldsIcon = () => {
        if (!viaIntegration) {
            return false;
        }
        return headerData?.productTabComplete ? false : true;
    };

    // const nextButtonStatus = () => {
    //     if (!viaIntegration) {
    //         return (products as any)?.length > 0 && !edit;
    //     }
    //     return condition ? true : false;
    // };

    const [cancelModal, setCancelModal] = useState(false);
    const onCancel = () => {
        setCancelModal(false);
    };

    const onConfirm = () => {
        formik.resetForm();
        setSingleProduct({});
        setAdd(false);
        setEdit(false);
        setEditIndex(0);
        onCancel();
    };

    const handleSubmit = () => {
        if (add || edit) {
            formik.handleSubmit();
            return true;
        }
        return false;
    };

    usePrompt('Leave screen?', formik.dirty, setUnsavedChangeModalVisibility, setCallBackFn);
    useBrowserBackCheck(formik.dirty, () => setCancelModal(true));

    return (
        <div
            onClick={() => {
                setShowBarcode(false);
            }}
            className="main-wrapper container product-details-wrapper"
        >
            <div className="main-header-content-holder large-header">
                {/* <div className="po-breadcrumb-holder">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/inbound">
                                    Purchase Orders
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link to={`/inbound/${id}/product_details`} className="breadcrumb-item">
                                    Add New PO
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="btn-holder discard-po-btn-holder">
                        <button
                            className="app-btn app-btn-secondary black-outline-btn icon-button"
                            title="Discard Purchase Order"
                        >
                            <svg className="svg-icon add-btn-icon">
                                <use xlinkHref="#deleteIcon"></use>
                            </svg>
                            <span className="button-text">Discord PO</span>
                        </button>
                    </div>
                </div> */}
                <Breadcrumbs
                    firstName="Inbound PO"
                    firstUrl="/inbound"
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New Inbound PO' : 'Inbound PO Details'}
                    secondUrl={`/inbound/${id}/product_details`}
                    disabled={formik.dirty}
                    callbackFn={handleSubmit}
                />
                <PurchaseOrderHeader id={id} />
                {/* <div className="arrow-tab-container">
                    <div className="arrow-tab-holder">
                        <Link
                            aria-current="page"
                            className="arrow-tab-border po-basic-tab"
                            to={`/inbound/${id}/basic_details`}
                        >
                            <div className="arrow-badge">1</div>
                            <div className="arrow-tab">Basic Details</div>
                        </Link>
                        <Link className="arrow-tab-border po-date-tab" to={`/inbound/${id}/dates`}>
                            <div className="arrow-badge">2</div>
                            <div className="arrow-tab ">Dates</div>
                        </Link>
                        <Link className="arrow-tab-border po-product-tab active" to={`/inbound/${id}/product_details`}>
                            <div className="arrow-badge">3</div>
                            <div className="arrow-tab">Product Details</div>
                        </Link>

                        {
                            <Link
                                className={`arrow-tab-border po-review-tab ${
                                    !(products as any)?.length || !nextButtonStatus() ? 'disabled' : ''
                                }`}
                                to={`/inbound/${id}/po_review`}
                            >
                                <div className="arrow-badge">4</div>
                                <div className="arrow-tab">PO Preview</div>
                            </Link>
                        }
                    </div>
                </div> */}
                <BLPInboundPoTabs />
            </div>
            <div className="grid-section product-details-grid">
                {isLoading > 0 && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div id="table_div" className="grid-holder">
                    <div className="grid-header">
                        <div className="grid-header--wrapper">
                            <div className="grid-header--title">Product Details</div>
                        </div>
                        {showMandatoryFieldsIcon() && (
                            <div className="mandatory-warning-text">
                                <div>
                                    <svg className="svg-icon error-icon">
                                        <use xlinkHref="#errorIcon">
                                            <title>Warning</title>
                                        </use>
                                    </svg>
                                </div>

                                <div className="error-text">
                                    Mandatory fields missing. Please integrate again/ edit the line details to enter the
                                    mandatory fields to proceed.
                                </div>
                            </div>
                        )}
                        <div className="add-btn-users">
                            {headerData?.poStatus === 'Draft' && (
                                <ShowForPermissions permission="edit" type="inbound">
                                    {edit || add ? (
                                        <button className="app-btn export-btn icon-button app-btn-secondary disabled">
                                            <svg className="svg-icon plus-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add PO Line</span>
                                        </button>
                                    ) : (
                                        <button
                                            onClick={() => {
                                                addPoLine();
                                            }}
                                            className="app-btn export-btn icon-button app-btn-secondary"
                                        >
                                            <svg className="svg-icon plus-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add PO Line</span>
                                        </button>
                                    )}
                                </ShowForPermissions>
                            )}
                        </div>
                    </div>

                    <div
                        onScroll={() => {
                            setShowBarcode(false);
                        }}
                        ref={scrollElement}
                        className="grid-container"
                    >
                        {!products?.length && !add && (
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No PO Lines added</p>
                            </div>
                        )}

                        <div className="grid-scroll-content">
                            <form id="my-form" action="" onSubmit={formik.handleSubmit}>
                                <table className="grid-table">
                                    <thead>
                                        <tr className="grid-sticky-row">
                                            <th className="grid-cell-header col-po-no">
                                                <div className="grid-cell-data">
                                                    <span>PO LINE NO.</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-sku">
                                                <div className="grid-cell-data">
                                                    <span>SKU</span>
                                                </div>
                                            </th>

                                            <th className="grid-cell-header col-description">
                                                <div className="grid-cell-data">
                                                    <span>DESCRIPTION</span>
                                                </div>
                                            </th>
                                            {/* <th className="grid-cell-header col-country">
                                                <div className="grid-cell-data">
                                                    <span>COUNTRY OF ORIGIN</span>
                                                </div>
                                            </th> */}
                                            {/* {headerData?.isMultiAddress && ( */}
                                            <>
                                                {/* <th className="grid-cell-header col-destination-port">
                                                    <div className="grid-cell-data">
                                                        <span>DESTINATION</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-destination-address">
                                                    <div className="grid-cell-data">
                                                        <span>DESTINATION ADDRESS</span>
                                                    </div>
                                                </th> */}
                                                <th className="grid-cell-header col-ready-date">
                                                    <div className="grid-cell-data">
                                                        <span>GOODS READY DATE</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-eta-whouse">
                                                    <div className="grid-cell-data">
                                                        <span>PROJ. ETA INTO WHOUSE</span>
                                                    </div>
                                                </th>
                                            </>
                                            {/*  )} */}

                                            <th className="grid-cell-header col-quantity">
                                                <div className="grid-cell-data">
                                                    <span>QUANTITY</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-cbm">
                                                <div className="grid-cell-data">
                                                    <span>CBM</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-currency">
                                                <div className="grid-cell-data">
                                                    <span>CURRENCY</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-unit-cost">
                                                <div className="grid-cell-data">
                                                    <span>UNIT COST</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-net-cost">
                                                <div className="grid-cell-data">
                                                    <span>NET COST</span>
                                                </div>
                                            </th>
                                            {/* BLP INBOUND PO - additional table column added */}
                                            <th className="grid-cell-header col-converted">
                                                <div className="grid-cell-data">CONVERTED QTY & UOM</div>
                                            </th>
                                            <th className="grid-cell-header col-barcode">
                                                <div className="grid-cell-data">BARCODES</div>
                                            </th>
                                            <th className="grid-cell-header grid-sticky-column sticky-right-aligned col-user-action">
                                                <div className="grid-cell-data">ACTION</div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {products?.map((product: ProductPoDataDetails, index: number) =>
                                            edit && editIndex === index ? (
                                                <tr
                                                    id={`edit_row_${index}`}
                                                    key={index}
                                                    className="has-child row-expanded"
                                                >
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            <span className="client-id">
                                                                {product.poLineNumber ? product.poLineNumber : '-'}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                <SearchableSelectBox
                                                                    data={skuData?.length ? skuData : []}
                                                                    placeholder="Enter SKU to select"
                                                                    formik={formik}
                                                                    fieldName="skuId"
                                                                    extraField="sku"
                                                                    customStyle={portalStyle}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    closeMenuOnScroll={true}
                                                                    extraFieldFn={skuUpdate}
                                                                    NoMessageComponent={NoMessageComponent}
                                                                    menuPlacement={menuPlacement}
                                                                />
                                                                {formik.errors.skuId ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.skuId}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {formik.values.description}
                                                        </div>
                                                    </td>
                                                    {/* <td className="grid-cell search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown country-region">
                                                                <SearchableSelectBox
                                                                    data={country?.length ? country : []}
                                                                    placeholder="Enter country to select"
                                                                    formik={formik}
                                                                    fieldName="countryCode"
                                                                    extraField="countryName"
                                                                    customStyle={customStyle}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    closeMenuOnScroll={true}
                                                                />
                                                                {formik.errors.countryCode ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.countryCode}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td> */}
                                                    {/* {headerData?.isMultiAddress && ( */}
                                                    <>
                                                        {/* <td className="grid-cell search-entry-holder">
                                                            <div className="grid-cell-data">
                                                                <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown country-region">
                                                                    <SearchableSelectBox
                                                                        data={port?.length ? port : []}
                                                                        placeholder="Enter destination to select"
                                                                        formik={formik}
                                                                        fieldName="destinationPort"
                                                                        customStyle={customStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        closeMenuOnScroll={true}
                                                                    />
                                                                    {formik.errors.destinationPort ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.destinationPort}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </td> */}
                                                        {/* <td className="grid-cell search-entry-holder">
                                                            <div
                                                                id={editIndex === index ? 'address_div' : ''}
                                                                className="grid-cell-data"
                                                            >
                                                                <div
                                                                    className={
                                                                        showAddress
                                                                            ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                            : 'dropdown-wrap  menu-down modal-dropdown'
                                                                    }
                                                                >
                                                                    <button
                                                                        onClick={(e) => {
                                                                            resizeFilter();
                                                                            e.stopPropagation();
                                                                            setShowAddress((prev) => !prev);
                                                                        }}
                                                                        type="button"
                                                                        className="menu-btn app-btn"
                                                                    >
                                                                        {formik.values.destinationAddress ? (
                                                                            <span className="btn-text placeholder-text-address">
                                                                                {formik.values.destinationAddress}
                                                                            </span>
                                                                        ) : (
                                                                            <span className="btn-text btn-address">
                                                                                Select address
                                                                            </span>
                                                                        )}

                                                                        {/* <span className="placeholder-text">Select Address</span> */}
                                                        {/* <span className="dropdown-arrow">
                                                                            <svg className="svg-icon arrow-icon">
                                                                                <use xlinkHref="#downArrow">
                                                                                    <title>dropdown</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                                {formik.errors.destinationAddress ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.destinationAddress}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>  */}
                                                        <td className="grid-cell search-entry-holder">
                                                            <div
                                                                id={`goodsReadyDateDiv_${index}`}
                                                                className="grid-cell-data date-picker-grid"
                                                            >
                                                                <DatePicker
                                                                    // minDate={new Date()}
                                                                    formik={formik}
                                                                    fieldName="goodsReadyDate"
                                                                    portalId="date-portal"
                                                                />
                                                                {formik.errors.goodsReadyDate ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.goodsReadyDate}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell search-entry-holder">
                                                            <div
                                                                id={`projWhouseETADiv_${index}`}
                                                                className="grid-cell-data date-picker-grid"
                                                            >
                                                                <DatePicker
                                                                    minDate={new Date()}
                                                                    formik={formik}
                                                                    fieldName="projWhouseETA"
                                                                    portalId="date-portal"
                                                                />
                                                                {formik.errors.projWhouseETA ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.projWhouseETA}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                    </>
                                                    {/*  )} */}

                                                    <td className="grid-cell search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            <input
                                                                placeholder="Enter quantity"
                                                                type="text"
                                                                className="search-input input-text input-qty"
                                                                id="qty"
                                                                name="qty"
                                                                onChange={(event) => {
                                                                    formik.setFieldError(event.target.name, undefined);
                                                                    // formik.handleChange(event);
                                                                    formik.setFieldValue('qty', +event.target.value);
                                                                    formik.setFieldValue(
                                                                        'cbm',
                                                                        +cbmForEach * +event.target.value
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'netCost',
                                                                        +formik.values.unitCost * +event.target.value
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'weight',
                                                                        +weightForEach * +event.target.value
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    e.target.value !== '' &&
                                                                        parseFloat(e.target.value) >= 0 &&
                                                                        formik.validateField('qty');
                                                                }}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                value={formik.values.qty}
                                                            ></input>
                                                            {formik.errors.qty ? (
                                                                <div className="error-text">
                                                                    {typeof formik.errors.qty === 'string' &&
                                                                        formik.errors.qty}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{formik.values.cbm}</div>
                                                    </td>
                                                    <td className="grid-cell search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                <SearchableSelectBox
                                                                    data={currency?.length ? currency : []}
                                                                    placeholder="Enter currency to select"
                                                                    formik={formik}
                                                                    fieldName="currency"
                                                                    customStyle={customStyle}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    closeMenuOnScroll={true}
                                                                    isClearable={currency?.length === 1 ? false : true}
                                                                />
                                                                {formik.errors.currency ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.currency}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell  search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            <input
                                                                placeholder="Enter unit cost"
                                                                type="number"
                                                                className="search-input input-text input-qty"
                                                                id="unitCost"
                                                                name="unitCost"
                                                                min="0"
                                                                step="any"
                                                                onChange={(event) => {
                                                                    formik.setFieldError(event.target.name, undefined);
                                                                    // formik.handleChange(event);
                                                                    formik.setFieldValue(
                                                                        'unitCost',
                                                                        +parseFloat(event.target.value)
                                                                    );
                                                                    const netCost =
                                                                        +formik.values.qty *
                                                                        +parseFloat(event.target.value);
                                                                    if (event.target.value) {
                                                                        formik.setFieldValue(
                                                                            'netCost',
                                                                            parseFloat(netCost.toFixed(2))
                                                                        );
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    e.target.value !== '' &&
                                                                        parseFloat(e.target.value) >= 0 &&
                                                                        formik.validateField('unitCost');
                                                                }}
                                                                // onKeyPress={(event) => {
                                                                //     if (!/[0-9]/.test(event.key)) {
                                                                //         event.preventDefault();
                                                                //     }
                                                                // }}
                                                                value={parseFloat((formik as any).values.unitCost)}
                                                            ></input>
                                                            {formik.errors.unitCost ? (
                                                                <div className="error-text">
                                                                    {typeof formik.errors.unitCost === 'string' &&
                                                                        formik.errors.unitCost}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell  search-entry-holder">
                                                        <div className="grid-cell-data">{formik.values.netCost}</div>
                                                    </td>
                                                    <td className="grid-cell  search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            {formik.values.convertedQty} {formik.values.uom}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell  search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            <button
                                                                id={`barcode_each_${index}`}
                                                                className="action-btn app-btn app-btn-secondary action-text "
                                                                title="Each"
                                                                type="button"
                                                                disabled={
                                                                    formik.values.barcodes
                                                                        ?.toLowerCase()
                                                                        ?.includes('each')
                                                                        ? false
                                                                        : true
                                                                }
                                                                onClick={(e) => {
                                                                    resizeFilter(
                                                                        `barcode_each_${index}`,
                                                                        formik.values.skuId,
                                                                        'Each'
                                                                    );
                                                                    e.stopPropagation();
                                                                    setShowBarcode((prev) => !prev);
                                                                }}
                                                            >
                                                                <span className=" each">E</span>
                                                            </button>
                                                            <button
                                                                id={`barcode_inner_${index}`}
                                                                className="action-btn app-btn app-btn-secondary action-text "
                                                                title="Inner"
                                                                type="button"
                                                                disabled={
                                                                    formik.values.barcodes
                                                                        ?.toLowerCase()
                                                                        ?.includes('inner')
                                                                        ? false
                                                                        : true
                                                                }
                                                                onClick={(e) => {
                                                                    resizeFilter(
                                                                        `barcode_inner_${index}`,
                                                                        formik.values.skuId,
                                                                        'Inner'
                                                                    );
                                                                    e.stopPropagation();
                                                                    setShowBarcode((prev) => !prev);
                                                                }}
                                                            >
                                                                <span className="inner">I</span>
                                                            </button>
                                                            <button
                                                                id={`barcode_case_${index}`}
                                                                className="action-btn app-btn app-btn-secondary action-text"
                                                                title="Case"
                                                                type="button"
                                                                disabled={
                                                                    formik.values.barcodes
                                                                        ?.toLowerCase()
                                                                        ?.includes('case')
                                                                        ? false
                                                                        : true
                                                                }
                                                                onClick={(e) => {
                                                                    resizeFilter(
                                                                        `barcode_case_${index}`,
                                                                        formik.values.skuId,
                                                                        'Case'
                                                                    );
                                                                    e.stopPropagation();
                                                                    setShowBarcode((prev) => !prev);
                                                                }}
                                                            >
                                                                <span className=" count">C</span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned">
                                                        <div className="grid-cell-data">
                                                            {headerData?.poStatus === 'Draft' ? (
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary"
                                                                    title="Save PO Line"
                                                                    type="submit"
                                                                    form="my-form"
                                                                >
                                                                    <svg className="svg-icon save-icon">
                                                                        <use xlinkHref="#saveIcon"></use>
                                                                    </svg>
                                                                </button>
                                                            ) : null}
                                                            {headerData?.poStatus === 'Draft' && (
                                                                <button
                                                                    className={`action-btn app-btn app-btn-secondary ${
                                                                        add ? 'disabled' : ''
                                                                    }`}
                                                                    title="Remove PO Line"
                                                                    type="button"
                                                                    onClick={(e: any) => {
                                                                        e.preventDefault();
                                                                        removeProduct(product.poProductId);
                                                                    }}
                                                                >
                                                                    <svg className="svg-icon eye-icon ">
                                                                        <use xlinkHref="#deleteIcon"></use>
                                                                    </svg>
                                                                </button>
                                                            )}
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="View SKU Details"
                                                                disabled={formik.values.skuId ? false : true}
                                                                onClick={(e: any) => {
                                                                    viewPoLine(e, formik.values.sku);
                                                                }}
                                                            >
                                                                <svg className="svg-icon eye-icon">
                                                                    <use xlinkHref="#eyeIcon"></use>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ) : (
                                                <tr key={index} className="has-child row-expanded">
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            <span className="client-id">
                                                                {product.poLineNumber ? product.poLineNumber : '-'}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{product.sku}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{product.description}</div>
                                                    </td>
                                                    {/* <td className="grid-cell">
                                                        <div className="grid-cell-data">{product.countryName}</div>
                                                    </td> */}
                                                    {/* {headerData?.isMultiAddress && ( */}
                                                    <>
                                                        {/* <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {product.destinationPort}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {product.destinationAddress}
                                                            </div>
                                                        </td> */}
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {product.goodsReadyDate
                                                                    ? moment(product.goodsReadyDate).format(
                                                                          'DD-MM-YYYY'
                                                                      )
                                                                    : null}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {product.projWhouseETA
                                                                    ? moment(product.projWhouseETA).format('DD-MM-YYYY')
                                                                    : null}
                                                            </div>
                                                        </td>
                                                    </>
                                                    {/* )} */}

                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{product?.qty}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{product?.cbm}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {product?.currency?.currencycode}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{product?.unitCost}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{product?.netCost}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {product?.convertedQty} {product?.uom}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell  search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            <button
                                                                id={`barcode_each_${index}`}
                                                                className="action-btn app-btn app-btn-secondary action-text "
                                                                title="Each"
                                                                type="button"
                                                                disabled={
                                                                    product.barcodes?.toLowerCase()?.includes('each')
                                                                        ? false
                                                                        : true
                                                                }
                                                                onClick={(e) => {
                                                                    resizeFilter(
                                                                        `barcode_each_${index}`,
                                                                        product.skuId,
                                                                        'Each'
                                                                    );
                                                                    e.stopPropagation();
                                                                    setShowBarcode((prev) => !prev);
                                                                }}
                                                            >
                                                                <span className=" each">E</span>
                                                            </button>
                                                            <button
                                                                id={`barcode_inner_${index}`}
                                                                className="action-btn app-btn app-btn-secondary action-text "
                                                                title="Inner"
                                                                type="button"
                                                                disabled={
                                                                    product.barcodes?.toLowerCase()?.includes('inner')
                                                                        ? false
                                                                        : true
                                                                }
                                                                onClick={(e) => {
                                                                    resizeFilter(
                                                                        `barcode_inner_${index}`,
                                                                        product.skuId,
                                                                        'Inner'
                                                                    );
                                                                    e.stopPropagation();
                                                                    setShowBarcode((prev) => !prev);
                                                                }}
                                                            >
                                                                <span className="inner">I</span>
                                                            </button>
                                                            <button
                                                                id={`barcode_case_${index}`}
                                                                className="action-btn app-btn app-btn-secondary action-text"
                                                                title="Case"
                                                                type="button"
                                                                disabled={
                                                                    product.barcodes?.toLowerCase()?.includes('case')
                                                                        ? false
                                                                        : true
                                                                }
                                                                onClick={(e) => {
                                                                    resizeFilter(
                                                                        `barcode_case_${index}`,
                                                                        product.skuId,
                                                                        'Case'
                                                                    );
                                                                    e.stopPropagation();
                                                                    setShowBarcode((prev) => !prev);
                                                                }}
                                                            >
                                                                <span className=" count">C</span>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned">
                                                        <div className="grid-cell-data">
                                                            {headerData?.poStatus === 'Draft' && (
                                                                <>
                                                                    <ShowForPermissions
                                                                        permission="edit"
                                                                        type="inbound"
                                                                    >
                                                                        <button
                                                                            className={`action-btn app-btn app-btn-secondary ${
                                                                                add || edit ? 'disabled' : ''
                                                                            }`}
                                                                            disabled={add || edit ? true : false}
                                                                            title="Edit PO Line"
                                                                            type="button"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                editPoLine(index, product);
                                                                            }}
                                                                        >
                                                                            <svg className="svg-icon save-icon">
                                                                                <use xlinkHref="#editIcon"></use>
                                                                            </svg>
                                                                        </button>
                                                                    </ShowForPermissions>
                                                                    {headerData?.poStatus === 'Draft' && (
                                                                        <ShowForPermissions
                                                                            permission="edit"
                                                                            type="inbound"
                                                                        >
                                                                            <button
                                                                                className={`action-btn app-btn app-btn-secondary ${
                                                                                    add || edit ? 'disabled' : ''
                                                                                }`}
                                                                                disabled={add || edit ? true : false}
                                                                                title="Remove PO Line"
                                                                                onClick={(e: any) => {
                                                                                    e.preventDefault();
                                                                                    removeProduct(product.poProductId);
                                                                                }}
                                                                            >
                                                                                <svg className="svg-icon eye-icon ">
                                                                                    <use xlinkHref="#deleteIcon"></use>
                                                                                </svg>
                                                                            </button>
                                                                        </ShowForPermissions>
                                                                    )}
                                                                </>
                                                            )}
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="View SKU Details"
                                                                onClick={(e) => {
                                                                    viewPoLine(e, product.sku);
                                                                }}
                                                            >
                                                                <svg className="svg-icon eye-icon">
                                                                    <use xlinkHref="#eyeIcon"></use>
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                        {add && (
                                            <tr id="add_row" className="has-child row-expanded">
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        <span className="client-id">
                                                            {products?.length
                                                                ? products[products?.length - 1].poLineNumber + 1
                                                                : 1}
                                                        </span>
                                                    </div>
                                                </td>
                                                <td className="grid-cell search-entry-holder">
                                                    <div className="grid-cell-data">
                                                        <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                            <SearchableSelectBox
                                                                data={skuData?.length ? skuData : []}
                                                                placeholder="Enter SKU to select"
                                                                formik={formik}
                                                                fieldName="skuId"
                                                                extraField="sku"
                                                                customStyle={portalStyle}
                                                                menuPosition="fixed"
                                                                menuPortalTarget={document.getElementById(
                                                                    'outside_div'
                                                                )}
                                                                closeMenuOnScroll={true}
                                                                extraFieldFn={skuUpdate}
                                                                NoMessageComponent={NoMessageComponent}
                                                                menuPlacement={menuPlacement}
                                                            />
                                                            {formik.errors.skuId ? (
                                                                <div className="error-text">{formik.errors.skuId}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{formik.values.description}</div>
                                                </td>

                                                <td className="grid-cell search-entry-holder">
                                                    <div
                                                        id="goodsReadyDateDiv"
                                                        className="grid-cell-data date-picker-grid"
                                                    >
                                                        <DatePicker
                                                            // minDate={new Date()}
                                                            formik={formik}
                                                            fieldName="goodsReadyDate"
                                                            portalId="date-portal"
                                                        />
                                                        {formik.errors.goodsReadyDate ? (
                                                            <div className="error-text">
                                                                {formik.errors.goodsReadyDate}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </td>
                                                <td className="grid-cell search-entry-holder">
                                                    <div
                                                        id="projWhouseETADiv"
                                                        className="grid-cell-data date-picker-grid"
                                                    >
                                                        <DatePicker
                                                            minDate={new Date()}
                                                            formik={formik}
                                                            fieldName="projWhouseETA"
                                                            portalId="date-portal"
                                                        />
                                                        {formik.errors.projWhouseETA ? (
                                                            <div className="error-text">
                                                                {formik.errors.projWhouseETA}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </td>

                                                <td className="grid-cell search-entry-holder">
                                                    <div className="grid-cell-data">
                                                        <input
                                                            placeholder="Enter quantity"
                                                            type="text"
                                                            className="search-input input-text input-qty"
                                                            id="qty"
                                                            name="qty"
                                                            onChange={(event) => {
                                                                const cbm = +cbmForEach * +event.target.value;
                                                                formik.setFieldError(event.target.name, undefined);
                                                                formik.setFieldValue('qty', +event.target.value);
                                                                formik.setFieldValue('cbm', parseFloat(cbm.toFixed(5)));
                                                                formik.setFieldValue(
                                                                    'netCost',
                                                                    +formik.values.unitCost * +event.target.value
                                                                );
                                                                const weight = +weightForEach * +event.target.value;
                                                                formik.setFieldValue(
                                                                    'weight',
                                                                    parseFloat(weight.toFixed(5))
                                                                );
                                                            }}
                                                            onKeyPress={(event) => {
                                                                if (!/[0-9]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                e.target.value !== '' &&
                                                                    parseFloat(e.target.value) >= 0 &&
                                                                    formik.validateField('qty');
                                                            }}
                                                            value={formik.values.qty}
                                                        ></input>
                                                        {formik.errors.qty ? (
                                                            <div className="error-text">
                                                                {typeof formik.errors.qty === 'string' &&
                                                                    formik.errors.qty}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{formik.values.cbm}</div>
                                                </td>
                                                <td className="grid-cell search-entry-holder">
                                                    <div className="grid-cell-data">
                                                        <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                            <SearchableSelectBox
                                                                data={currency?.length ? currency : []}
                                                                placeholder="Enter currency to select"
                                                                formik={formik}
                                                                fieldName="currency"
                                                                customStyle={customStyle}
                                                                menuPosition="fixed"
                                                                menuPortalTarget={document.getElementById(
                                                                    'outside_div'
                                                                )}
                                                                closeMenuOnScroll={true}
                                                                isClearable={currency?.length === 1 ? false : true}
                                                            />
                                                            {formik.errors.currency ? (
                                                                <div className="error-text">
                                                                    {formik.errors.currency}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="grid-cell  search-entry-holder">
                                                    <div className="grid-cell-data">
                                                        <input
                                                            placeholder="Enter unit cost"
                                                            type="number"
                                                            className="search-input input-text input-qty"
                                                            id="unitCost"
                                                            name="unitCost"
                                                            min="0"
                                                            step="any"
                                                            onChange={(event) => {
                                                                formik.setFieldError(event.target.name, undefined);
                                                                // formik.handleChange(event);
                                                                formik.setFieldValue(
                                                                    'unitCost',
                                                                    parseFloat(event.target.value)
                                                                );
                                                                const netCost =
                                                                    +formik.values.qty *
                                                                    +parseFloat(event.target.value);
                                                                if (event.target.value) {
                                                                    formik.setFieldValue(
                                                                        'netCost',
                                                                        parseFloat(netCost.toFixed(2))
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(e) => {
                                                                e.target.value !== '' &&
                                                                    parseFloat(e.target.value) >= 0 &&
                                                                    formik.validateField('unitCost');
                                                            }}
                                                            // onKeyPress={(event) => {
                                                            //     if (!/[0-9]/.test(event.key)) {
                                                            //         event.preventDefault();
                                                            //     }
                                                            // }}
                                                            value={formik.values.unitCost}
                                                        ></input>
                                                        {formik.errors.unitCost ? (
                                                            <div className="error-text">
                                                                {typeof formik.errors.unitCost === 'string' &&
                                                                    formik.errors.unitCost}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </td>
                                                <td className="grid-cell  search-entry-holder">
                                                    <div className="grid-cell-data">{formik.values.netCost}</div>
                                                </td>
                                                {/* BLP INBOUND PO - additional table column data added */}
                                                <td className="grid-cell  search-entry-holder">
                                                    <div className="grid-cell-data">
                                                        {formik.values.convertedQty} {formik.values.uom}
                                                    </div>
                                                </td>
                                                <td className="grid-cell  search-entry-holder">
                                                    <div className="grid-cell-data">
                                                        <button
                                                            id="barcode_each_add"
                                                            className="action-btn app-btn app-btn-secondary action-text "
                                                            title="Each"
                                                            type="button"
                                                            disabled={
                                                                formik.values.barcodes
                                                                    ?.toLowerCase()
                                                                    ?.includes('each') && formik.values.sku
                                                                    ? false
                                                                    : true
                                                            }
                                                            onClick={(e) => {
                                                                resizeFilter(
                                                                    'barcode_each_add',
                                                                    formik.values.skuId,
                                                                    'Each'
                                                                );
                                                                e.stopPropagation();
                                                                setShowBarcode((prev) => !prev);
                                                            }}
                                                        >
                                                            <span className=" each">E</span>
                                                        </button>
                                                        <button
                                                            id="barcode_inner_add"
                                                            className="action-btn app-btn app-btn-secondary action-text "
                                                            title="Inner"
                                                            type="button"
                                                            disabled={
                                                                formik.values.barcodes
                                                                    ?.toLowerCase()
                                                                    ?.includes('inner') && formik.values.sku
                                                                    ? false
                                                                    : true
                                                            }
                                                            onClick={(e) => {
                                                                resizeFilter(
                                                                    'barcode_inner_add',
                                                                    formik.values.skuId,
                                                                    'Inner'
                                                                );
                                                                e.stopPropagation();
                                                                setShowBarcode((prev) => !prev);
                                                            }}
                                                        >
                                                            <span className="inner">I</span>
                                                        </button>
                                                        <button
                                                            id="barcode_case_add"
                                                            className="action-btn app-btn app-btn-secondary action-text"
                                                            title="Case"
                                                            type="button"
                                                            disabled={
                                                                formik.values.barcodes
                                                                    ?.toLowerCase()
                                                                    ?.includes('case') && formik.values.sku
                                                                    ? false
                                                                    : true
                                                            }
                                                            onClick={(e) => {
                                                                resizeFilter(
                                                                    'barcode_case_add',
                                                                    formik.values.skuId,
                                                                    'Case'
                                                                );
                                                                e.stopPropagation();
                                                                setShowBarcode((prev) => !prev);
                                                            }}
                                                        >
                                                            <span className=" count">C</span>
                                                        </button>
                                                    </div>
                                                </td>
                                                <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned">
                                                    <div className="grid-cell-data">
                                                        {headerData?.poStatus === 'Draft' ? (
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary"
                                                                title="Save PO Line"
                                                                type="submit"
                                                                form="my-form"
                                                            >
                                                                <svg className="svg-icon save-icon">
                                                                    <use xlinkHref="#saveIcon"></use>
                                                                </svg>
                                                            </button>
                                                        ) : null}
                                                        {headerData?.poStatus === 'Draft' ? (
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="Remove PO Line"
                                                                onClick={() => {
                                                                    setAdd(false);
                                                                    formik.resetForm();
                                                                    setSingleProduct({});
                                                                }}
                                                            >
                                                                <svg className="svg-icon eye-icon ">
                                                                    <use xlinkHref="#deleteIcon"></use>
                                                                </svg>
                                                            </button>
                                                        ) : null}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </form>
                        </div>
                    </div>
                    {showBarcode && (
                        <div
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                top: `${distanceFromTopForBarcode}px`,
                                left: `${distanceFromLeftForBarcode}px`,
                            }}
                            className="dropdown-wrap outside-menu menu-down dropdown-open info-dropdown left-align barcode-dropdown"
                        >
                            <div className="dropdown-menu">
                                <span className="menu-item not-link">{currentBarcode}</span>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link to={`/inbound/${id}/dates`} className="app-btn app-btn-secondary footer-btn" title="Previous">
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <BLPInboundPONextButton page="product_details" />
                </div>
            </div>
            <ProductDetailsModal data={skuDetail} display={modalView} displayFunction={setModalView} />
            <LinkVendorModal
                sku={skuDetail?.pm_sku}
                vendor={headerData?.vendorName}
                display={modalViewVendor}
                displayFunction={setModalViewVendor}
                linkFunction={linkVendor}
                formik={formik}
            />
            <CbmUnavailableModal
                sku={skuDetail?.pm_sku}
                display={modalViewCbm}
                displayFunction={setModalViewCbm}
                formik={formik}
                setModalViewSku={setModalViewSku}
            />
            <SkuUnavailableModal
                clientId={headerData?.clientId}
                skuUnavailableModalVisibility={modalViewSku}
                setSkuUnavailableModalVisibility={setModalViewSku}
            />
            {cancelModal && <CancelModal onConfirm={onConfirm} onCancel={onCancel} />}

            <RemovePoLine
                removeModalVisibility={removeModalVisibility}
                setRemoveModalVisibility={setRemoveModalVisibility}
                poProductId={poProductId}
            />
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
            <div style={{ zIndex: 9999 }} id="date-portal"></div>
            <UnsavedChangeModal
                unsavedChangeModalVisibility={unsavedChangeModalVisibility}
                setUnsavedChangeModalVisibility={setUnsavedChangeModalVisibility}
                callBackFn={callBackFn}
            />
        </div>
    );
};
export default Index;
