/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';
import { combineTrendGraph } from 'src/utils';
import { TrendDetails } from 'src/pages/Tracking/InsightDetailsList';

interface TrendTotalprop {
    count: number;
    key: {
        count: number;
        date: string | null;
        value: string | null;
    };
}

interface TrendProp {
    total: TrendTotalprop;
}

interface TrackingInsightsType {
    insightsTiles: string[];
    insights: any;
    favourite: any;
    trendGraph: TrendProp | any;
    trendMultiGraph: any;
    trendDataCopy: any;
    isLoading: boolean;
    isTileLoading: boolean;
    viewInsight: boolean | null;
    isError: boolean;
    trackingInsightsError: any;
    trackingInsightsSuccess: any;
}

const initialState: TrackingInsightsType = {
    isLoading: true,
    isTileLoading: true,
    viewInsight: null,
    insightsTiles: [],
    insights: [],
    favourite: [],
    trendGraph: {},
    trendMultiGraph: {},
    trendDataCopy: {},
    isError: false,
    trackingInsightsError: undefined,
    trackingInsightsSuccess: undefined,
};

export const trackingInsightsSlice = createSlice({
    name: 'trackingInsights',
    initialState,
    reducers: {
        fetchInsightsTilesList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
            state.viewInsight = null;
        },
        fetchInsightsTilesListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.insightsTiles = action.payload?.data?.permissions;
            if (!action.payload?.data?.permissions?.length) {
                state.isTileLoading = false;
            }
            if (action.payload.tileName) {
                state.viewInsight = action.payload?.data?.permissions?.includes(action.payload.tileName);
            }
        },
        fetchInsightsTilesListFailure: (state, _action) => {
            state.isLoading = false;
            state.isTileLoading = false;
            state.viewInsight = false;
        },
        fetchInsightsList: (state, _action) => {
            state.isTileLoading = true;
            state.isError = false;
        },
        fetchInsightsListSuccess: (state, action) => {
            state.isTileLoading = false;
            state.isError = false;
            const currentData = JSON.parse(JSON.stringify(state.insights));
            const newData = action.payload;
            let mergeData: any = [];
            if (Array.isArray(currentData)) {
                mergeData = currentData.map((item: any) =>
                    newData.code === item.code
                        ? {
                              ...newData,
                              ...item,
                          }
                        : {
                              ...item,
                          }
                );
            } else {
                mergeData.push(currentData);
            }
            state.insights = mergeData;
        },
        fetchInsightsListFailure: (state, _action) => {
            state.isTileLoading = false;
        },
        fetchFavouriteList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchFavouriteListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.favourite = action.payload?.data?.info;
        },
        fetchFavouriteListFailure: (state, _action) => {
            state.isLoading = false;
        },
        fetchTrendGraph: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchTrendGraphSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            if (Array.isArray(action.payload?.graph)) {
                if (action.payload?.keyToCheck) {
                    const updatedData = combineTrendGraph(
                        action.payload?.data,
                        action.payload?.graph,
                        TrendDetails.Trend,
                        action.payload?.keyToCheck
                    );
                    state.trendGraph = updatedData;
                    state.trendDataCopy = action.payload;
                } else {
                    state.trendGraph = action.payload;
                }
            } else {
                state.trendGraph = action.payload?.graph ? action.payload?.graph : action.payload;
            }
        },
        fetchTrendGraphFailure: (state, _action) => {
            state.isLoading = false;
        },
        fetchTrendMultiGraph: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchTrendMultiGraphSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            if (action.payload?.keyToCheck) {
                const updatedData = combineTrendGraph(
                    action.payload?.data,
                    action.payload?.graph,
                    TrendDetails.Compare,
                    action.payload?.keyToCheck
                );
                state.trendMultiGraph = updatedData;
                state.trendDataCopy = action.payload;
            } else {
                state.trendMultiGraph = action.payload;
            }
        },
        updateTrendGraphFilter: (state, action) => {
            const dataCopy = action.payload?.data && JSON.parse(JSON.stringify(action.payload?.data));
            const key = action.payload?.keyToCheck.field;
            dataCopy?.sort((a: any, b: any) => b?.[key] - a?.[key]);
            const updatedData = combineTrendGraph(
                dataCopy,
                action.payload?.graph,
                action.payload?.graphType,
                action.payload?.keyToCheck
            );
            if (action.payload?.graphType === TrendDetails.Compare) {
                state.trendMultiGraph = updatedData;
            } else {
                state.trendGraph = updatedData;
            }
        },
        fetchTrendMultiGraphFailure: (state, _action) => {
            state.isLoading = false;
        },
        clearTrendGraph: (state, _action) => {
            state.isLoading = false;
            state.trendGraph = {};
        },
        favourite: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        favouriteSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.trackingInsightsSuccess = action.payload;
        },
        favouriteFailure: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.trackingInsightsError = action.payload;
        },
        clearFailure: (state, _action) => {
            state.trackingInsightsError = undefined;
            state.trackingInsightsSuccess = undefined;
            state.isError = false;
        },
        initializeInsights: (state, action) => {
            state.insights = action.payload;
        },
        resetGraph: (state) => {
            state.trendGraph = {};
            state.trendMultiGraph = {};
        },
    },
});

export const {
    fetchInsightsTilesList,
    fetchInsightsTilesListSuccess,
    fetchInsightsTilesListFailure,
    fetchInsightsList,
    fetchInsightsListSuccess,
    fetchInsightsListFailure,
    fetchFavouriteList,
    fetchFavouriteListSuccess,
    fetchFavouriteListFailure,
    fetchTrendGraph,
    fetchTrendGraphFailure,
    fetchTrendGraphSuccess,
    fetchTrendMultiGraph,
    fetchTrendMultiGraphFailure,
    fetchTrendMultiGraphSuccess,
    updateTrendGraphFilter,
    clearTrendGraph,
    favourite,
    favouriteSuccess,
    favouriteFailure,
    clearFailure,
    initializeInsights,
    resetGraph,
} = trackingInsightsSlice.actions;

export const selectTrackingInsightsLoading = (state: RootState) => state.trackingInsightsData.isLoading;
export const selectTrackingInsightsTileLoading = (state: RootState) => state.trackingInsightsData.isTileLoading;
export const selectTrackingInsightsViewInsight = (state: RootState) => state.trackingInsightsData.viewInsight;
export const selectInsightsTiles = (state: RootState) => state.trackingInsightsData.insightsTiles;
export const selectInsights = (state: RootState) => state.trackingInsightsData.insights;
export const selectFavourite = (state: RootState) => state.trackingInsightsData.favourite;
export const selectTrendGraph = (state: RootState) => state.trackingInsightsData.trendGraph;
export const selectTrendMultiGraph = (state: RootState) => state.trackingInsightsData.trendMultiGraph;
export const selectTrendDataCopy = (state: RootState) => state.trackingInsightsData.trendDataCopy;
export const selectTrackingInsightsSuccess = (state: RootState) => state.trackingInsightsData.trackingInsightsSuccess;
export const selectTrackingInsightsError = (state: RootState) => state.trackingInsightsData.trackingInsightsError;
export const selectTrackingInsightsIsError = (state: RootState) => state.trackingInsightsData.isError;

export default trackingInsightsSlice.reducer;
