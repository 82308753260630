/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import './reassign.scss';
import { Link } from 'react-router-dom';
import ReassignModal from '../ReassignModal';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchReassignPoList,
    selectReassignPo,
    fetchDistinctPoList,
    selectDistinctPo,
    selectReassignPoLoading,
    ReassignPoDetails,
} from 'src/store/reducers/reassignAgent/purchaseOrderReducer';
import { getOrganisationData } from 'src/utils';
import { useUserProfile } from 'src/hooks/useUserProfile';
import moment from 'moment';
import ColumnFilter from '../Filter';
import Header from '../Header';
import CancelModal from 'src/components/common/CancelModal';
import { reassignAgentPoDetails } from 'src/utils/tableStructure/reassignAgentPo';
const Index: React.FC = () => {
    const { profileType } = useUserProfile();
    const organisationData = getOrganisationData();

    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>({});
    const [sortColumn, setSortColumn] = useState('poOrderDate');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [filterColumn, setFilterColumn] = useState('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [selected, setSelected] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);

    const payload = {
        body: {
            ...organisationData,
            poStatus: 'Awaiting Enrichment',
            type: 'ITPO',
        },
        sortColumn,
        sortOrder,
        profileType,
    };

    useEffect(() => {
        dispatch(fetchDistinctPoList({ ...payload }));
    }, []);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        setSearchName(keyword);
    };

    const filterDistance = (columnName: string) => {
        const el = document.getElementById(columnName);
        const distanceLeft = el?.getBoundingClientRect().left ?? 0;
        const distanceRight = el?.getBoundingClientRect().right ?? 0;
        if (window.innerWidth - distanceRight > 280) {
            setSetPosition(distanceRight - 60);
        } else {
            setSetPosition(distanceLeft - 195);
        }
    };

    const filterByColumn = (e: any, columnName: string) => {
        setFilterColumn(columnName);
        filterDistance(columnName);
        setSearchName('');
        filter(e);
        e.stopPropagation();
        setToggleDropdown((prev) => !prev);
    };

    const searchOrFilter = (newPayload: any, searchKey?: string) => {
        const poSearch = searchKey !== undefined ? searchKey : searchTerm;
        if (poSearch?.trim()?.length > 1) {
            const finalPayload = { ...newPayload };
            finalPayload.body = { ...finalPayload.body, poSearch };
            dispatch(fetchReassignPoList({ ...finalPayload }));
        } else {
            dispatch(fetchReassignPoList({ ...newPayload }));
        }
    };

    const sortByColumn = (columnName: string) => {
        const newOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newOrder);
        setSortColumn(columnName);
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
        }
        searchOrFilter({ ...payload, sortColumn: columnName, sortOrder: newOrder });
    };

    const onSearch = (searchKey: string) => {
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
        }
        if (searchKey.length > 2 || !searchKey.length) {
            searchOrFilter(payload, searchKey);
        }
        setSearchTerm(searchKey);
    };

    const onPoSelection = (poDetail: ReassignPoDetails) => {
        const poSelected = JSON.parse(JSON.stringify(selected));
        const alreadyExists = poSelected.findIndex((res: any) => res.poId === poDetail.poId);
        if (alreadyExists >= 0) {
            const filtered = poSelected.filter((res: any) => res.poId !== poDetail.poId);
            setSelected(filtered);
        } else {
            const filtered = [...poSelected, poDetail];
            setSelected(filtered);
        }
    };

    const filterData = () => {
        if (filterColumn === 'poOrderDate') {
            return distinctPo[filterColumn].map((res: string) => moment(res).format('DD-MM-YYYY'));
        }
        return distinctPo[filterColumn];
    };

    useEffect(() => {
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
            searchOrFilter(payload);
        } else {
            searchOrFilter(payload);
        }
    }, [statusFilterSelected]);

    const dispatch = useAppDispatch();
    const reassignPo = useAppSelector(selectReassignPo);
    const distinctPo = useAppSelector(selectDistinctPo);
    const isLoading = useAppSelector(selectReassignPoLoading);

    const selectAllStatus = (property: string) => {
        if (property === 'disabled') {
            if (!selected.length) {
                return true;
            }
        } else {
            if (!selected.length) {
                return false;
            }
            const selectedItems = reassignPo?.filter(
                (e: any) => e.agentName === selected[0].agentName && e.originPort === selected[0].originPort
            );
            return (
                selectedItems.length !== 0 &&
                selectedItems.every((selectedItem) =>
                    selected.some((select: ReassignPoDetails) => selectedItem.poId === select.poId)
                )
            );
        }
    };

    const selectAllOnChange = (checkStatus: boolean) => {
        const filtered = reassignPo.filter(
            (e: any) => e.agentName === selected[0].agentName && e.originPort === selected[0].originPort
        );
        if (checkStatus) {
            setSelected(filtered);
        } else {
            setSelected([]);
        }
    };

    return (
        <>
            <div className="main-wrapper container reassign-grid-wrapper">
                <div className="main-header-content-holder large-header">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <Link to="/purchase_order" className="dropdown-arrow">
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>User details</title>
                                    </use>
                                </svg>
                            </Link>
                            <li className="breadcrumb-list-item">
                                <Link to="/purchase_order" className="breadcrumb-item">
                                    Purchase Orders
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link to="/reassign-agent/purchase-order" className="breadcrumb-item">
                                    Reassign Agent
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="pageheading-holder">
                        <div className="back-btn-holder">
                            <h2 className="page-heading">Reassign Agent</h2>
                            <div className="page-counter-wrapper">
                                <div className="page-heading__caption">Select from the list to reassign new agent</div>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                </div>
                <div className="grid-section reassign-grid po-reassign-grid">
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="search-entry-holder">
                                <input
                                    placeholder="Search PO Numbers"
                                    type="text"
                                    className="search-input input-text"
                                    value={searchTerm ?? ''}
                                    onChange={(e) => onSearch(e.target.value)}
                                ></input>
                                <svg className="svg-icon search-icon">
                                    <use xlinkHref="#searchIcon"></use>
                                </svg>
                                <button onClick={() => onSearch('')} className="app-btn text-close-btn">
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="grid-container">
                            {(reassignPo === null || reassignPo?.length) === 0 && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No records found</p>
                                </div>
                            )}

                            <div className="grid-scroll-content">
                                <table className="grid-table" id="tableId">
                                    <Header
                                        content={reassignAgentPoDetails}
                                        filterFunction={filterByColumn}
                                        sortFunction={sortByColumn}
                                        sortColumn={sortColumn}
                                        sortOrder={sortOrder}
                                        filterColumn={filterColumn}
                                        toggleDropdown={toggleDropdown}
                                        selectedFilterItem={statusFilterSelected}
                                        selectAllStatus={selectAllStatus}
                                        selectAllOnChange={selectAllOnChange}
                                    />
                                    <tbody className="has-child row-expanded">
                                        {reassignPo?.map((res: ReassignPoDetails) => (
                                            <tr key={res.poId}>
                                                <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                                    <div className="grid-cell-data centre-align">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox-input"
                                                                checked={selected?.some(
                                                                    (e: any) => e.poId === res.poId
                                                                )}
                                                                disabled={
                                                                    selected?.length &&
                                                                    selected?.some(
                                                                        (e: any) =>
                                                                            e.agentName === res.agentName &&
                                                                            e.originPort === res.originPort
                                                                    ) === false
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => onPoSelection(res)}
                                                            />
                                                            <div className="checkmark">
                                                                <svg className="svg-icon tick-icon">
                                                                    <use xlinkHref="#tickIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                    <div className="grid-cell-data">
                                                        {/* <a
                                                            href="#"
                                                            //className="booking-proposal-link"
                                                            style={{
                                                                paddingLeft: '0',
                                                                marginLeft: '0px',
                                                                textDecoration: 'none',
                                                                color: 'unset',
                                                            }}
                                                        > */}
                                                        {res.poNumber}
                                                        {/* </a> */}
                                                    </div>
                                                </td>

                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.poStatus}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.agentName ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.clientName ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.originPort ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.destinationPort ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        {res.poOrderDate
                                                            ? moment(res.poOrderDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.shippingmode ?? '-'}</div>
                                                </td>

                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.vendorName ?? '-'}</div>
                                                </td>

                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.destinationType ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.releaseType ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.createdBy ?? '-'}</div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {toggleDropdown && (
                            <ColumnFilter
                                setPosition={setPosition}
                                searchName={searchName}
                                setSearchName={setSearchName}
                                filter={filter}
                                data={filterData()}
                                statusFilterSelected={statusFilterSelected}
                                setStatusFilterSelected={setStatusFilterSelected}
                                isLoading={isLoading}
                                setToggleDropdown={setToggleDropdown}
                                columnName={filterColumn}
                                currentColumnName={filterColumn}
                                setCurrentColumnName={setFilterColumn}
                            />
                        )}
                    </div>
                </div>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <div className="tab-with-proposal">
                            <div className="tab-grid-select-holder">
                                {selected?.length > 0 && (
                                    <span className="btn-pills info-pill">
                                        <div className="pill-text">
                                            {selected[0].agentName} :{' '}
                                            <span>
                                                {selected?.length} {selected?.length === 1 ? 'PO' : 'POs'} Selected
                                            </span>
                                        </div>
                                        <button onClick={() => setSelected([])} className="pill-action" type="button">
                                            <svg className="svg-icon close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </span>
                                )}

                                {/* <a style={{ textDecoration: 'none' }}> */}
                                <button
                                    disabled={selected?.length ? false : true}
                                    className="app-btn app-btn-primary footer-btn"
                                    title="Reassign Agent"
                                    onClick={() => setShowModal(true)}
                                >
                                    <span className="button-text footer-button-text">Reassign Agent</span>
                                </button>
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <ReassignModal
                    type="po"
                    data={selected}
                    setShowModal={setShowModal}
                    setDisplayCancel={setDisplayCancel}
                />
            )}
            <CancelModal
                displayFunction={setShowModal}
                mode="edit"
                header="Reassign"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
        </>
    );
};
export default Index;
