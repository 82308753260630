/* eslint-disable no-undefined */
import React, { useState } from 'react';
import './insightExpanded.scss';
import InsightHeader from './InsightHeader';
import InsightAllTab from './InsightAllTab';
import SearchHeader from '../Search/SearchHeader';
import InsightFavouritesTab from './InsightFavouritesTab';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { insightTabList } from '../Insights/enums';
import FavouritesSidepanel from '../Insights/Favourites/FavouritesSidepanel';
import {
    setInsightsSelectedTypeFilter,
    setInsightTab,
    setSelectedTypeFilterIndex,
} from 'src/store/reducers/dashboard/insightsReducer';

//Faheem
const Index: React.FC = () => {
    const dispatch = useDispatch();
    const { insightTab } = useSelector((state: RootState) => state.dashboardInsights);
    const [favSettings, setFavSettings] = useState(false);
    // const [viewfavSettings, setViewFavSettings] = useState(true);
    const { favourites, isLoading } = useSelector((state: RootState) => state.favourites);
    return (
        <div className="main-wrapper container dashboard-wrapper">
            <SearchHeader />
            <div className="insight-page">
                <InsightHeader />
                <div className="insight-page-wrapper">
                    <div className="tab-nav-holder">
                        <div className="tab-nav-flex-holder">
                            <a
                                className={`tab-nav ${insightTab === insightTabList.all ? 'active' : ''}`}
                                onClick={() => {
                                    dispatch(setInsightTab(insightTabList.all));
                                    dispatch(setInsightsSelectedTypeFilter(''));
                                    dispatch(setSelectedTypeFilterIndex(0));
                                }}
                            >
                                All
                            </a>
                            <a
                                className={`tab-nav ${insightTab === insightTabList.fav ? 'active' : ''}`}
                                onClick={() => {
                                    !isLoading && dispatch(setInsightTab(insightTabList.fav));
                                    dispatch(setInsightsSelectedTypeFilter(''));
                                    dispatch(setSelectedTypeFilterIndex(0));
                                }}
                                style={{ cursor: isLoading ? 'not-allowed' : 'pointer' }}
                            >
                                Favourites
                            </a>
                        </div>

                        {insightTab === 'Fav' && (
                            <a
                                className="action-btn app-btn app-btn-secondary setting-btn"
                                onClick={() => setFavSettings(true)}
                            >
                                <svg className="svg-icon setting-icon">
                                    <use xlinkHref="#settingIcon">
                                        <title>Settings</title>
                                    </use>
                                </svg>
                            </a>
                        )}
                    </div>
                    <div className="insight-tab-content">
                        <FavouritesSidepanel modalHandle={setFavSettings} data={favourites} modalOpen={favSettings} />
                        {insightTab === 'All' && <InsightAllTab />}
                        {insightTab === 'Fav' && <InsightFavouritesTab></InsightFavouritesTab>}
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
