import React, { useEffect, useState } from 'react';
import '../PO/Dates/purchase-dates.scss';
import '../PO/PurchaseOrderHeader/purchase-order-header.scss';
import '../../assets/scss/components/_breadcrumbs.scss';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';

import {
    ShipmentDetailsDataType,
    selectShipmentDetails,
    selectShipmentDetailsLoading,
} from '../../store/reducers/shipmentDetailsReducer';
import { fetchShipmentDetailsList } from 'src/store/reducers/shipmentDetailsReducer';
import Shipment from './Shipment';
import Containers from './Containers';
import Packing from './Packing';
import RoutingLegs from './RoutingLegs';
import MilestoneDates from './MilestoneDates';
import HeaderCards from '../Common/headerCards';

var shipmentDetailsTabList = [
    {
        label: 'Shipment',
        value: 'shipment',
        id: 1,
    },
    {
        label: 'Containers',
        value: 'containers',
        id: 2,
    },
    {
        label: 'Commodity Packing',
        value: 'packing',
        id: 3,
    },
    {
        label: 'Routing',
        value: 'routing-legs',
        id: 4,
    },
    {
        label: 'Lifecycle',
        value: 'milestone-dates',
        id: 5,
    },
];

export interface ShipmentTabProps {
    data: ShipmentDetailsDataType;
}

const Index: React.FC = () => {
    const lookup = {
        shipment: Shipment,
        containers: Containers,
        packing: Packing,
        ['routing-legs']: RoutingLegs,
        ['milestone-dates']: MilestoneDates,
    };

    const { id } = useParams();
    const dispatch = useAppDispatch();

    const shipmentDetailsData = useAppSelector(selectShipmentDetails);
    const shipmentDetails = shipmentDetailsData?.[0];
    const isLoading = useAppSelector(selectShipmentDetailsLoading);

    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const RenderComponent = lookup[lastSegment as keyof typeof lookup];

    const [selectedIndex, setSelectedIndex] = useState(0);

    useEffect(() => {
        dispatch(
            fetchShipmentDetailsList({
                shipmentId: id,
            })
        );
    }, [id]);
    useEffect(() => {
        if (shipmentDetails?._shipmentId) {
            document.title = `SHP > ${shipmentDetails?._shipmentId}`;
        } else {
            document.title = 'SHP';
        }
    }, [shipmentDetails]);

    useEffect(() => {
        // if shipment has customsDeclaration, add the 6th tab to show customs
        if (shipmentDetails) {
            const customsDeclaration = shipmentDetails.dataContext?.dataSourceCollection?.dataSource?.find(
                (d) => d.type === 'CustomsDeclaration'
            );
            if (customsDeclaration) {

                shipmentDetailsTabList = [
                    {
                        label: 'Shipment',
                        value: 'shipment',
                        id: 1,
                    },
                    {
                        label: 'Containers',
                        value: 'containers',
                        id: 2,
                    },
                    {
                        label: 'Commodity Packing',
                        value: 'packing',
                        id: 3,
                    },
                    {
                        label: 'Routing',
                        value: 'routing-legs',
                        id: 4,
                    },
                    {
                        label: 'Declaration',
                        value: 'customs',
                        id: 5,
                    },
                    {
                        label: 'Lifecycle',
                        value: 'milestone-dates',
                        id: 6,
                    },
                ];
            }
        }
    }, [shipmentDetails]);

    useEffect(() => {
        if (lastSegment) {
            if (lastSegment === 'shipment') {
                setSelectedIndex(0);
            } else if (lastSegment === 'containers') {
                setSelectedIndex(1);
            } else if (lastSegment === 'packing') {
                setSelectedIndex(2);
            } else if (lastSegment === 'routing-legs') {
                setSelectedIndex(3);
            } else if (lastSegment === 'milestone-dates') {
                if (shipmentDetailsTabList?.length === 5 ) 
                    setSelectedIndex(4);
                if (shipmentDetailsTabList?.length === 6 ) 
                    setSelectedIndex(5);
            } else if (lastSegment === 'customs') {
                setSelectedIndex(4);
            }
        }
    }, [lastSegment]);

    // client, vendor, ports, dates and container mode
    // TODO - constant string
    const mapShipmentHeaderData = () => {
        const shipmentHeaderArray = [];

        if (shipmentDetails) {
            // Client
            const clientName =
                shipmentDetails.organizationAddressCollection?.organizationAddress?.find(
                    (org) => org.addressType === 'ConsigneeDocumentaryAddress'
                )?.companyName || '';

            shipmentHeaderArray.push({ title: 'Client', value: clientName, style: 'primary' });

            // Vendor
            const vendorName =
                shipmentDetails.organizationAddressCollection?.organizationAddress?.find(
                    (org) => org.addressType === 'ConsignorDocumentaryAddress'
                )?.companyName || '';

            shipmentHeaderArray.push({ title: 'Vendor', value: vendorName, style: 'primary' });

            // Origin
            const originPortCode = shipmentDetails.portOfOrigin?.code;
            const originPortName = shipmentDetails.portOfOrigin?.name;
            shipmentHeaderArray.push({
                title: 'Origin',
                value: `${originPortName} (${originPortCode})`,
                style: 'primary',
            });

            // Destination
            const destPortCode = shipmentDetails.portOfDestination?.code;
            const destPortName = shipmentDetails.portOfDestination?.name;
            shipmentHeaderArray.push({
                title: 'Destination',
                value: `${destPortName} (${destPortCode})`,
                style: 'primary',
            });

            // Purchase Orders
            let orders: string = '';
            shipmentDetails.localProcessing?.orderNumberCollection?.orderNumber?.map((order) => {
                orders += orders === '' ? order.orderReference : ', ' + order.orderReference;
            });
            shipmentHeaderArray.push({ title: 'Purchase Orders', value: orders, style: 'secondary' });

            // Total CBM
            const volume = shipmentDetails.documentedVolume;
            shipmentHeaderArray.push({ title: 'Total CBM', value: volume, style: 'secondary' });

            // Total Kgs
            const weight = shipmentDetails.documentedWeight;
            shipmentHeaderArray.push({ title: 'Total Kgs', value: weight, style: 'secondary' });

            // Actual TEU
            const teu = shipmentDetails.agg_TEU;
            shipmentHeaderArray.push({ title: 'Actual TEU', value: teu, style: 'secondary' });
        }

        return shipmentHeaderArray;
    };

    return (
        <div className="main-wrapper container purchase-dates-wrapper">
            <div className="main-header-content-holder large-header">
                <div className="po-breadcrumb-holder">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <Link to="/shipments-view" className="dropdown-arrow">
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </Link>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/shipments-view">
                                    Shipments View
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="#">
                                    Details
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="purchase-order-header-fields">
                    <div className="pageheading-holder">
                        <div className="back-btn-holder">
                            <div className="breadcrumbs-holder">
                                <ul className="breadcrumbs" style={{ paddingTop: '4px' }}>
                                    {shipmentDetails?._parentShipmentId && (
                                        <li className="breadcrumb-list-item">
                                            <Link
                                                className="breadcrumb-item"
                                                style={{ fontSize: '18px' }}
                                                to={`/scr/fwd/shp/${shipmentDetails?._parentShipmentId}/shipment`}
                                            >
                                                {shipmentDetails?._parentShipmentId}
                                            </Link>
                                        </li>
                                    )}

                                    <li className="breadcrumb-list-item">
                                        <Link className="breadcrumb-item" style={{ fontSize: '18px' }} to={`#`}>
                                            {shipmentDetails?._shipmentId}
                                        </Link>
                                    </li>
                                </ul>
                            </div>

                            <div className="transport-icon-wrapper">
                                <div className="item-count-status-holder">
                                    <div className="grid-item-count-status status-ui info-status">
                                        <span className="count">
                                            <svg className="svg-icon aeroplane-icon">
                                                <use xlinkHref="#shipIcon">
                                                    <title>-</title>
                                                </use>
                                            </svg>
                                        </span>

                                        <span className="status-text">{shipmentDetails?.containerMode?.code}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* rendering shipment header, style copied from the booking headers */}
                <HeaderCards headers={mapShipmentHeaderData()} />
                <div className="arrow-tab-container">
                    <div className="arrow-tab-holder">
                        {shipmentDetailsTabList.map((tabs: any, index: number) => {
                            return (
                                <Link
                                    key={tabs.value}
                                    className={`arrow-tab-border ${index === selectedIndex && 'active'} po-basic-tab`}
                                    to={`/scr/fwd/shp/${id}/${tabs.value}`}
                                >
                                    <div
                                        className="arrow-badge verify-badge"
                                        style={{
                                            backgroundColor: '#18ab56',
                                        }}
                                    >
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>

                                    <div className="arrow-tab">{tabs.label}</div>
                                </Link>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div
                className={`content-section main-wrapper agent-basic-detail-wrapper ${
                    lastSegment === 'shipment' ? '' : 'grid-p-0'
                }`}
            >
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {RenderComponent ? <RenderComponent data={shipmentDetails} /> : null}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    {selectedIndex > 0 && (
                        <Link
                            to={`/scr/fwd/shp/${id}/${shipmentDetailsTabList[selectedIndex - 1].value}`}
                            className="app-btn app-btn-secondary footer-btn"
                            title="Previous"
                        >
                            <span className="button-text footer-button-text">Previous</span>
                        </Link>
                    )}
                    {selectedIndex < shipmentDetailsTabList.length - 1 && (
                        <Link
                            to={`/scr/fwd/shp/${id}/${shipmentDetailsTabList[selectedIndex + 1].value}`}
                            className="app-btn app-btn-primary footer-btn"
                            title="Previous"
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Index;
