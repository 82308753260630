/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { getProviders } from 'src/services/apis/quoteAPi';
import { debounce } from 'lodash';
type Props = {
    title: string;
    keyName: string;
    quoteData: any;
    setQuoteData: any;
    quoteItemData: any;
    loading: any;
    saveTriggered?: boolean;
    defaultValue?: any[];
    isRequired: boolean;
};

const QuoteItemDropdownCard: React.FC<Props> = ({
    title,
    keyName,
    quoteData,
    setQuoteData,
    quoteItemData: originalData,
    loading,
    saveTriggered,
    defaultValue,
    isRequired,
}) => {
    const [dropdownData, setDropdownData] = useState<any>([]);
    const [quoteItemData, setQuoteItemData] = useState<any>([]);
    const [dropdownLoading, setDropdownLoading] = useState(false);

    useEffect(() => {
        if (originalData?.length) {
            setDropdownData(originalData);
            setQuoteItemData(originalData);
        }
    }, [originalData]);

    useEffect(() => {
        if (quoteData?.[keyName]?.length === 0) {
            const copy = JSON.parse(JSON.stringify(quoteData));
            copy?.[keyName]?.push({ label: '', value: '' });
            setQuoteData(copy);
        }
    }, [quoteData?.[keyName]]);

    const getQuoteItemDropDownData = () => {
        let tempData: any = [];
        let data = keyName === 'providers' ? dropdownData : quoteItemData;
        if (!quoteData?.[keyName]?.length) {
            return data;
        }
        for (let index = 0; index < data.length; index++) {
            if (!quoteData?.[keyName]?.find((res: any) => res?.value === data[index].value)) {
                tempData.push(data[index]);
            }
        }
        return tempData;
    };

    const addItem = () => {
        const copy = JSON.parse(JSON.stringify(quoteData));
        copy?.[keyName]?.push({ label: '', value: '' });
        setQuoteData(copy);
    };

    const addAllItem = () => {
        const copy = JSON.parse(JSON.stringify(quoteData));
        copy[keyName] = quoteItemData;
        setQuoteData(copy);
    };

    const saveItem = (value: string, index: number) => {
        const filtered = [...quoteItemData, ...dropdownData]?.find((res: any) => res?.value === value);
        const copy = JSON.parse(JSON.stringify(quoteData));
        copy[keyName][index] = filtered ? filtered : { label: '', value: '' };
        setQuoteData(copy);
    };

    const removeItem = (index: number) => {
        const copy = JSON.parse(JSON.stringify(quoteData));
        if (copy?.[keyName]?.length <= 1) {
            copy?.[keyName]?.splice(index, 1);
            copy?.[keyName]?.push({ label: '', value: '' });
            setQuoteData(copy);
        } else {
            copy?.[keyName]?.splice(index, 1);
            setQuoteData(copy);
        }
    };

    const getData = debounce(async (value: string) => {
        setDropdownLoading(true);
        const provider = await getProviders(value);
        setDropdownData(provider);
        setDropdownLoading(false);
    }, 500);

    return (
        <div className="detail-box-common">
            <h6 className="details-box-heading-2">
                {title} {isRequired && <span className="mandatory-field-text">*</span>}
            </h6>
            {defaultValue?.map((value) => {
                return (
                    <div key={value.value} className="details-box-detail">
                        <div className="details-box-detail-text-readonly with-dropdown-btn">
                            <div className="readonly-textbox">
                                <input
                                    type="text"
                                    readOnly
                                    className="input-text details-box-detail-text-box"
                                    value={value?.value}
                                />
                            </div>
                        </div>
                        <div className="details-box-detail-text  with-input-btn">
                            <input
                                type="text"
                                readOnly
                                className="input-text details-box-detail-text-box"
                                value={value?.label}
                            />
                        </div>
                        <div className="details-box-detail-btn"></div>
                    </div>
                );
            })}
            {quoteData?.[keyName]?.map((res: any, index: number) => (
                <div key={index} className="details-box-detail">
                    <div className="details-box-detail-dropdown with-dropdown-btn">
                        <SearchableSelectBox
                            data={getQuoteItemDropDownData()}
                            placeholder="Choose one item"
                            setStateValue={(value: string) => saveItem(value, index)}
                            stateValue={res?.value}
                            fieldName={`${keyName}-${res?.value}-${index}`}
                            isClearable={true}
                            isLoading={loading[keyName] || dropdownLoading}
                            overrideValue={res?.value ? { label: res?.value, value: res?.value } : null}
                            onInputChangeFn={(value: string) => {
                                if (value?.length > 2 && keyName === 'providers') {
                                    getData(value);
                                }
                            }}
                            onFocusFn={() => {
                                if (keyName === 'providers') {
                                    getData('');
                                }
                            }}
                        />
                    </div>
                    <div className="details-box-detail-text  with-input-btn">
                        <input
                            type="text"
                            readOnly
                            className="input-text details-box-detail-text-box"
                            value={res?.label}
                        />
                    </div>
                    <div className="details-box-detail-btn">
                        <button
                            className="app-btn app-btn-secondary icon-button  add-product-btn"
                            title="Remove"
                            onClick={() => removeItem(index)}
                        >
                            <svg className="svg-icon add-btn-icon">
                                <use xlinkHref="#dashCheck"></use>
                            </svg>
                        </button>
                        <button
                            onClick={() => addItem()}
                            className="app-btn app-btn-secondary icon-button add-product-btn"
                            title="Add"
                        >
                            <svg className="svg-icon add-btn-icon">
                                <use xlinkHref="#plusIcon"></use>
                            </svg>
                        </button>
                        {(keyName === 'transportMode' || keyName === 'containerMode') && index === 0 && (
                            <button
                                onClick={() => addAllItem()}
                                className="app-btn app-btn-secondary icon-button add-product-btn"
                                title="Add All"
                            >
                                ALL
                            </button>
                        )}
                    </div>
                </div>
            ))}
            {saveTriggered &&
                isRequired &&
                (!quoteData?.[keyName]?.length || !quoteData?.[keyName]?.filter((res: any) => res?.value)?.length) && (
                    <div className="error-text">This field is required</div>
                )}
        </div>
    );
};

export default QuoteItemDropdownCard;
