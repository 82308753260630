import React from 'react';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import { useAppDispatch } from '../../../hooks';
import {
    editPoLineStatus,
    confirmPo,
    confirmAllPo,
} from '../../../store/reducers/purchaseOrder/productPoConfirmationReducer';
interface ModalListProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    zeroProductData: any;
    setZeroProductData: any;
    product: any;
}
const Index = ({ display, displayFunction, zeroProductData, product, setZeroProductData }: ModalListProps) => {
    const dispatch = useAppDispatch();
    const onSubmit = () => {
        if (zeroProductData.type === 'confirmPoLine') {
            const data = product[zeroProductData.index].splitLines[zeroProductData.splitLineIndex];
            if (zeroProductData.splitLineStatus === 'Pending Confirmation') {
                dispatch(editPoLineStatus({ ...data, splitLineId: data.splitLineId }));
            } else {
                dispatch(confirmPo({ ...data, poProductId: product[zeroProductData.index].poProductId }));
            }
        } else {
            dispatch(confirmAllPo({ poLines: zeroProductData.poLines }));
        }
        displayFunction(false);
        setZeroProductData({});
    };
    return (
        <>
            {display ? (
                <div className="app-modal purchase-detail-modal">
                    <div className="modal-content-holder ">
                        <div className="modal-header">
                            <div className="title">Zero Quantity</div>
                            <button onClick={() => displayFunction(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content">
                                Line items with <span style={{ fontWeight: 700 }}>Zero</span> quantity will not be
                                available for Booking Proposal. Are you sure to continue?
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => displayFunction(false)}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                            >
                                <span className="button-text footer-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                onClick={() => onSubmit()}
                            >
                                <span className="button-text footer-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
