import axios from 'axios';
import fileDownload from 'js-file-download';
import { patch, post, get, del } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const fetchPackingDetailsApi = async (payload: { poPk: string }) => {
    try {
        const data = await get(`/cix/scr/forwarding/purchaseorder/${payload.poPk}/vp/packing`, null, externalBaseUrl);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchSsccApi = async (payload: { prefix: number; sequence: number; quantity: number }) => {
    try {
        const data = await post(`/cix/scr/utility/vp/sscc`, payload, null, undefined, externalBaseUrl);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const packContainerApi = async (payload: { payload: any; poPk: string }) => {
    try {
        const data = await post(
            `/cix/scr/forwarding/purchaseorder/${payload.poPk}/vp/packing`,
            payload.payload,
            null,
            undefined,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const unpackContainerApi = async (payload: { payload: any; poPk: string }) => {
    try {
        const data = await del(
            `/cix/scr/forwarding/purchaseorder/${payload.poPk}/vp/packing`,
            payload.payload,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const changePackingStatus = async (payload: { payload: any; poPk: string; action: string; reason?: string }) => {
    try {
        const data = await post(
            `/cix/scr/forwarding/purchaseorder/${payload.poPk}/vp/finalise/${payload.action}`,
            { reason: payload.reason },
            null,
            undefined,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getSsrsApi = async (payload: { payload: any; type: string; poNumber: string }) => {
    try {
        const fileName =
            payload.type === 'Draft'
                ? process.env.REACT_APP_JG_DRAFT_DA_REPORT_NAME
                : process.env.REACT_APP_JG_FINAL_DA_REPORT_NAME;
        const data = await post(
            `/cix/scr/utility/ssrs`,
            {
                path: `${fileName}`,
                format: 'EXCELOPENXML',
                params: `PURCHASE_ORDER_ID=${payload.poNumber}`,
            },
            null,
            undefined,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
