export const checkPurchasePermissions = (permissions: any, type: string) => {
    /* eslint no-var: off */
    let flag = type === 'warehousing' ? '/warehousing_order' : '/purchase_order';
    var array: any[] = [];
    var array1: any[] = [];
    permissions?.forEach((element: any) => {
        array.push(element.type.toLowerCase());
        array1.push(element.access.toLowerCase());
        if (array1.includes('view po')) {
            flag = type === 'warehousing' ? '/warehousing_order' : '/purchase_order';
        } else {
            flag = '/profile';
        }
    });

    return flag;
};
export const checkBookingPermissions = (permissions: any) => {
    /* eslint no-var: off */
    let flag = '/booking_list';
    var array: any[] = [];
    var array1: any[] = [];
    permissions?.forEach((element: any) => {
        array.push(element.type.toLowerCase());
        array1.push(element.access.toLowerCase());
        if (array1.includes('view bp')) {
            flag = '/booking_list';
        } else {
            flag = '/profile';
        }
    });

    return flag;
};
