export function bookingsPathGenerator(id: string, bpType: string) {
    const pathMaps: any = {
        AO: '/Aobooking_list/<id>/AoSelection',
        AIR: '/air_booking_list/<id>/POSelection',
        FCL: '/booking_list/<id>/POSelection',
        LCL: '/lclbooking_list/<id>/POSelection',
        BCN: '/bcnBookingList/<id>/POSelection',
    };

    return pathMaps[bpType]?.replace('<id>', id);
}

function lclPathGenerator(id: string, groupId: string) {
    return '/purchase_order/<id>/container_mode_selection/<groupid>'.replace('<id>', id).replace('<groupid>', groupId);
}

export const TODO_ICON_MAP: any = {
    // PO
    DRAFT_POS: {
        name: 'Draft PO',
        icon: 'note-edit-icon',
        iconType: '#noteDraftEditIcon',
        fetchItemPayload: {
            poStatus: 'Draft',
            type: 'ITPO',
        },
        type: 'PO',
        path: (id: string) => '/purchase_order/<id>/basic_details'.replace('<id>', id),
    },
    PENDING_REASSIGNMENT: {
        name: 'Pending Reassignment',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        // fetchItemPayload: {
        //     poStatus: 'Draft',
        // },
        type: 'pendingReassignment',
        path: (_id: string) => '/reassign-agent/approval',
    },
    DRAFT_INBOUND_POS: {
        name: 'Draft Inbound PO',
        icon: 'note-edit-icon',
        iconType: '#noteDraftEditIcon',
        fetchItemPayload: {
            poStatus: 'Draft',
            type: 'IBPO',
        },
        type: 'PO',
        path: (id: string) => '/inbound/<id>/basic_details'.replace('<id>', id),
    },
    PO_AWAITING_ENRICHMENT: {
        name: 'PO Awaiting Enrichment',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            poStatus: 'Awaiting Enrichment',
            type: 'ITPO',
        },
        type: 'PO',
        path: (id: string) => '/purchase_order/<id>/basic_details'.replace('<id>', id),
    },
    // AO
    DRAFT_ALLOCATION_ORDERS: {
        name: 'Draft Allocation Orders',
        icon: 'note-edit-icon',
        iconType: '#noteDraftEditIcon',
        fetchItemPayload: {
            aoStatus: 'Draft',
        },
        type: 'AO',
        path: (id: string) => '/allocation_orders/<id>/basic_details'.replace('<id>', id),
    },
    AOS_PENDING_WITH_FORWARDER: {
        name: 'AOs Pending With Forwarder',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            aoStatus: 'Pending with Forwarder',
        },
        type: 'AO',
        path: (id: string) => '/allocation_orders/<id>/basic_details'.replace('<id>', id),
    },
    // Receipts
    DRAFT_RECEIPTS: {
        name: 'Draft Receipts',
        icon: 'note-edit-icon',
        iconType: '#noteDraftEditIcon',
        fetchItemPayload: {
            status: 'Draft',
        },
        type: 'Receipt',
        path: (id: string) => '/blp/receipt/<id>/receipt-header'.replace('<id>', id),
    },
    READY_TO_RECEIVE_RECEIPTS: {
        name: 'Ready To Receive Receipts',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            status: 'Ready to Receive',
        },
        type: 'Receipt',
        path: (id: string) => '/blp/receipt/<id>/receipt-header'.replace('<id>', id),
    },
    IN_PROGRESS_RECEIPTS: {
        name: 'In Progress Receipts',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            status: 'In Progress',
        },
        type: 'Receipt',
        path: (id: string) => '/blp/receipt/<id>/receipt-header'.replace('<id>', id),
    },

    // Bookings
    DRAFT_BOOKINGS: {
        name: 'Draft Bookings',
        icon: 'note-edit-icon',
        iconType: '#noteDraftEditIcon',
        fetchItemPayload: {
            bpStatus: 'Draft Booking',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },
    READY_FOR_BOOKING: {
        name: 'Ready for Booking',
        icon: 'note-edit-icon',
        iconType: '#noteDraftEditIcon',
        fetchItemPayload: {
            bpStatus: 'Ready for Booking',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },
    BOOKINGS_AWAITING_FORWARDER_APPROVAL: {
        name: 'Bookings Awaiting Forwarder Approval',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            bpStatus: 'Awaiting Forwarder Approval',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },
    BOOKINGS_REJECTED_BY_FORWARDER: {
        name: 'Bookings Rejected By Forwarder',
        icon: 'note-draft-icon',
        iconType: '#noteDraftIcon',
        fetchItemPayload: {
            bpStatus: 'Rejected by Forwarder',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },
    BOOKINGS_AWAITING_CLIENT_APPROVAL: {
        name: 'Bookings Awaiting Client Approval',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            bpStatus: 'Awaiting Client Approval',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },
    BOOKINGS_REJECTED_BY_CLIENT_WITH_FORWARDER: {
        name: 'Bookings Rejected By Client With Forwarder',
        icon: 'note-draft-icon',
        iconType: '#noteDraftIcon',
        fetchItemPayload: {
            bpStatus: 'Rejected by Client: Forwarder to Action',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },
    DRAFT_BOOKING_PROPOSAL: {
        name: 'Draft Booking Proposal',
        icon: 'note-edit-icon',
        // icon: 'note-draft-icon',
        iconType: '#noteDraftEditIcon',
        fetchItemPayload: {
            bpStatus: 'Draft Booking Proposal',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },
    BOOKINGS_REJECTED_BY_CLIENT_WITH_AGENT: {
        name: 'Bookings Rejected By Client With Agent',
        icon: 'note-draft-icon',
        iconType: '#noteDraftIcon',
        fetchItemPayload: {
            bpStatus: 'Rejected by Client: Agent to Action',
            //bpType: ['AO', 'Air', 'FCL', 'LCL'],
        },
        type: 'BP',
        path: bookingsPathGenerator,
    },

    // LCL
    LCL_OPTIONS_PENDING_FORWARDER_ACTION: {
        name: 'LCL Options Pending Forwarder Action',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            type: 'PENDING_ACTION_FORWARDER',
        },
        type: 'LCL',
        path: lclPathGenerator,
    },
    LCL_OPTIONS_PENDING_SELECTION_CLIENT: {
        name: 'LCL Options Pending Selection Client',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            type: 'PENDING_SELECTION_CLIENT',
        },
        type: 'LCL',
        path: lclPathGenerator,
    },
    LCL_OPTIONS_REJECTED_BY_CLIENT_WITH_FORWARDER: {
        name: 'LCL Options Rejected By Client With Forwarder',
        icon: 'note-draft-icon',
        iconType: '#noteDraftIcon',
        fetchItemPayload: {
            type: 'REJECTED_BY_CLIENT__WITH_FORWARDER',
        },
        type: 'LCL',
        path: lclPathGenerator,
    },
    LCL_OPTIONS_REJECTED_BY_CLIENT_WITH_AGENT: {
        name: 'LCL Options Rejected By Client With Agent',
        icon: 'note-draft-icon',
        iconType: '#noteDraftIcon',
        fetchItemPayload: {
            type: 'REJECTED_BY_CLIENT__WITH_AGENT',
        },
        type: 'LCL',
        path: lclPathGenerator,
    },
    OTHER_TODO: {
        name: 'OTHER_TODO',
        icon: 'note-icon draft-color',
        iconType: '#noteIcon',
        fetchItemPayload: {
            poStatus: 'Awaiting Enrichment',
            type: 'ITPO',
        },
        type: 'OTHER_TODO',
        path: (id: string) => '/purchase_order/<id>/basic_details'.replace('<id>', id),
    },
};
