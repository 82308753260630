import React, { useEffect, useState } from 'react';
import AddDatesModal from './AddDatesModal';
import AllocationOrderHeader from '../AllocationOrderHeader';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    selectDatesAo,
    fetchDatesAoList,
    selectDatesAoLoading,
} from 'src/store/reducers/allocationOrder/datesAoReducer';
import moment from 'moment';
import ShowForPermissions from 'src/ShowForPermissions';
import CancelModal from 'src/components/common/CancelModal';
import { selectHeader } from 'src/store/reducers/allocationOrder/addAOReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import AOBreadCrumb from '../BreadCrumb';
import {
    fetchProductAllocationList,
    // selectProductAllocation,
} from 'src/store/reducers/allocationOrder/productAllocationReducer';
// import { useSelector } from 'react-redux';
// import { RootState } from 'src/store/reducers';
// import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
import AllocationOrderTabs from '../AllocationOrderTabs';
import AllocationOrderNextButton from '../AllocationOrderNextButton/AllocationOrderNextButton';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const datesAoData = useAppSelector(selectDatesAo);
    const isLoading = useAppSelector(selectDatesAoLoading);
    const headerData = useAppSelector(selectHeader);
    const { basicDetails } = useSelector((state: RootState) => state.allocationOrder);
    const multiDestination = datesAoData?.data?.info?.isMultiAddress ? true : false;
    // const productAllocation = useAppSelector(selectProductAllocation);
    // const { previewData } = useSelector((state: RootState) => state.allocationOrder) as any;
    const formikIsDirtyRef = React.useRef<any>();

    const { profileType } = useUserProfile();
    const { headerDetails } = useSelector((state: RootState) => state.addAoData);

    useEffect(() => {
        dispatch(
            fetchDatesAoList({
                id,
            })
        );
        dispatch(fetchProductAllocationList({ aoId: id }));
    }, []);

    // if (formikIsDirtyRef.current?.value?.dirty) {
    //     useBrowserBackCheck(formikIsDirtyRef.current.value.dirty, () => setDisplayCancel(true));
    // }

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <AOBreadCrumb />
                {/* <Breadcrumbs
                    firstName="Allocation Orders"
                    firstUrl="/allocation_orders"
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New AO' : 'Create New Allocation Order'}
                    secondUrl={`/allocation_orders/${id}/dates`}
                /> */}
                <AllocationOrderHeader id={id} />
                <AllocationOrderTabs />
            </div>
            <div className="content-section main-wrapper agent-basic-detail-wrapper">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {basicDetails?.createdUserId === 'INTERFACE' && !headerDetails.datesTabComplete ? (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missed while integrating. Please integrate again with all mandatory fields
                            to proceed.
                        </div>
                    </div>
                ) : null}
                {basicDetails?.createdUserId === 'INTERFACE' &&
                headerDetails.datesTabComplete &&
                !headerDetails.validDate ? (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">Invalid dates present .Please enter valid dates to proceed</div>
                    </div>
                ) : null}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>
                            <ShowForPermissions permission="edit" type="ao">
                                {datesAoData?.data?.info?.orderDate &&
                                    profileType !== 'agent' &&
                                    headerData?.aoStatus !== 'Cancelled' &&
                                    headerData?.aoStatus !== 'In_Booking_Proposal' &&
                                    headerData?.aoStatus !== 'Booked' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        profileType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && profileType === 'forwarder')) &&
                                    headerData?.dataSourceName != 'ERP' && (
                                        <button
                                            onClick={() => {
                                                setModalView(true);
                                            }}
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Dates"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="ao">
                            <div className="btn-holder">
                                {!datesAoData?.data?.info?.orderDate &&
                                    headerData?.aoStatus !== 'In_Booking_Proposal' &&
                                    headerData?.aoStatus !== 'Booked' &&
                                    profileType !== 'agent' && (
                                        <button
                                            onClick={() => {
                                                setModalView(true);
                                            }}
                                            className="app-btn app-btn-secondary icon-button btn-bold"
                                            title="Add Dates"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Dates</span>
                                        </button>
                                    )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Date<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {datesAoData?.data?.info?.orderDate
                                    ? moment(datesAoData?.data?.info?.orderDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        {headerData?.aoStatus !== 'Draft' && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Created Date<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {datesAoData?.data?.info?.createdDate
                                        ? moment(datesAoData?.data?.info?.createdDate).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                        )}

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Start Shipping Window<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {datesAoData?.data?.info?.startShipWindowDate
                                    ? moment(datesAoData?.data?.info?.startShipWindowDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                End Shipping Window<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {datesAoData?.data?.info?.endShipWindowDate
                                    ? moment(datesAoData?.data?.info?.endShipWindowDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        <>
                            {!multiDestination && (
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">
                                        Projected ETA into Warehouse
                                        {!headerData?.isTriangleShipment && (
                                            <span className="mandatory-field-text">*</span>
                                        )}
                                    </h6>
                                    <div className="details-box-content">
                                        {datesAoData?.data?.info?.projectedEtaDate
                                            ? moment(datesAoData?.data?.info?.projectedEtaDate).format('DD-MM-YYYY')
                                            : '-'}
                                    </div>
                                </div>
                            )}
                        </>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/allocation_orders/${id}/basic_details`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>

                    {/* <Link
                        to={
                            datesAoData?.data?.info?.orderDate
                                ? `/allocation_orders/${id}/product-allocation`
                                : `/allocation_orders/${id}/dates`
                        }
                        className={`app-btn app-btn-primary footer-btn ${
                            datesAoData?.data?.info?.orderDate && headerDetails.datesTabComplete ? '' : 'disabled'
                        }`}
                        title="Next"
                    >
                        <span className="button-text footer-button-text">Next</span>
                    </Link> */}
                    <AllocationOrderNextButton page="dates" />
                </div>
            </div>
            <AddDatesModal
                display={modalView}
                displayFunction={setModalView}
                data={datesAoData?.data?.info}
                multiDestination={multiDestination}
                setDisplayCancel={setDisplayCancel}
                headerData={headerData}
                formikIsDirtyRef={formikIsDirtyRef}
            />
            <CancelModal
                displayFunction={setModalView}
                mode="edit"
                header="Dates"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
        </div>
    );
};
export default Index;
