import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/containerBreadcrumb';
import ConfirmationGroupHeader from './ConfirmationGroupHeader';
import { Position, success, error } from '../../../utils/toast';
import AddModal from './AddModal';
import RejectOptions from './RejectOptions';
// import AssignToAgent from './AssignToAgent';
// import DiscardConfirmationModal from './DiscardConfirmationModal';
import './container-group.scss';
import BSTooltip from 'src/components/common/BSTooltip';
import { useDispatch } from 'react-redux';
import {
    fetchConfirmedLines,
    submitForwardercontainer,
    submitToSuccess,
} from 'src/store/reducers/purchase_order/basicDetails';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useAppSelector } from 'src/hooks';
import {
    fetchConfirmationGroup,
    selectConfirmationGroup,
    selectContainerModePoLoading,
    ConfirmationGroups,
    fetchCoLoader,
    submitToClient,
    approveGroup,
    clearFailure,
    selectContainerModePoIsError,
    selectContainerModePoSuccess,
    selectContainerModePoError,
    confirmationGroupRejectionStat,
} from 'src/store/reducers/purchaseOrder/containerModePoReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { UserProfileType } from 'src/utils/constants';
import { fetchCurrency } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { fetchHeader, selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import ProductDetailModal from '../../BookingProposal/BookingAddedGrid/ProductDetailsModal';
import { downloadConfirmationGroupQuote } from 'src/services/apis/purchaseOrder/containerModePoApi';
import { PoContainerStatus } from '../ContainerModeSelection/PoStatusList';
import moment from 'moment';
import DiscardConfirmationModal from './DiscardConfirmationModal';
import AssignToAgent from './AssignToAgent';

type approvalStatus = {
    status: boolean;
    type: string;
};

interface OptionsComponentProps {
    profileType: string;
    addEditSubmitStatus: (type: string) => boolean;
    groupSelected: string;
    setDetailsComponent: (value: approvalStatus | ((prevVar: approvalStatus) => approvalStatus)) => void;
    setGroupSelected: (value: string | ((prevVar: string) => string)) => void;
    detailsComponent: approvalStatus;
    confirmationGroup: ConfirmationGroups;
    headerData: any;
}
const OptionsComponent = ({
    profileType,
    addEditSubmitStatus,
    setDetailsComponent,
    groupSelected,
    setGroupSelected,
    detailsComponent,
    confirmationGroup,
    headerData,
}: OptionsComponentProps) => {
    const addOrEdit = (type: string) => {
        if (profileType === UserProfileType.Agent) {
            if (type === 'LCL') {
                return confirmationGroup?.coLoader ? 'edit' : 'add';
            } else {
                return confirmationGroup?.fclBuyCost ? 'edit' : 'add';
            }
        }
        if (profileType === UserProfileType.FORWARDER || profileType === UserProfileType.Vendor) {
            if (type === 'LCL') {
                if (confirmationGroup?.status?.toLowerCase() === PoContainerStatus.OptionsToBeAdded) {
                    return confirmationGroup?.lclBuyCost ? 'edit' : 'add';
                } else {
                    return confirmationGroup?.lclSellCost ? 'edit' : 'add';
                }
            } else {
                if (confirmationGroup?.status?.toLowerCase() === PoContainerStatus.OptionsToBeAdded) {
                    return confirmationGroup?.fclBuyCost ? 'edit' : 'add';
                } else {
                    return confirmationGroup?.fclSellCost ? 'edit' : 'add';
                }
            }
        }
    };
    const approveSelectionStatus = (type: string) => {
        if (!confirmationGroup?.selectedGroup) {
            return '';
        }
        if (type === confirmationGroup?.selectedGroup?.toUpperCase()) {
            return 'selected-option';
        } else {
            return 'disabled-option';
        }
    };
    const selectedContainerAgent =
        confirmationGroup?.status?.toLowerCase() !== PoContainerStatus.OptionsToBeAdded &&
        confirmationGroup?.status?.toLowerCase() !== PoContainerStatus.RejectedByClientWithAgent;
    const selectedContainerForwarder =
        confirmationGroup?.status?.toLowerCase() !== PoContainerStatus.OptionsToBeAdded &&
        confirmationGroup?.status?.toLowerCase() !== PoContainerStatus.PendingActionForwarder &&
        confirmationGroup?.status?.toLowerCase() !== PoContainerStatus.RejectedByClientWithForwarder;

    return (
        <>
            <div className="option-wrapper">
                <div className={`option-card ${approveSelectionStatus('LCL')}`}>
                    <div className="option-wrapper__header">
                        <h3 className="options-wrapper__title">
                            {profileType === UserProfileType.Client &&
                                confirmationGroup?.status?.toLowerCase() ===
                                    PoContainerStatus.PendingSelectionClient && (
                                    <label>
                                        <input
                                            type="radio"
                                            name="optionSelection"
                                            className="option-selection"
                                            checked={groupSelected === 'LCL' ? true : false}
                                            onChange={() => setGroupSelected('LCL')}
                                        />
                                        <span className="input-radio" />
                                    </label>
                                )}
                            LCL
                        </h3>
                        {profileType === UserProfileType.Agent ||
                        profileType === UserProfileType.FORWARDER ||
                        profileType === UserProfileType.Vendor ? (
                            <>
                                <div className="option-wrapper__item">
                                    <div className="option-wrapper__item-title">
                                        Coloader <span className="mandatory-field-text">*</span>{' '}
                                        {confirmationGroup?.isUpdatedByForwarder &&
                                            profileType === UserProfileType.Agent && (
                                                <BSTooltip title="Changed by Forwarder">
                                                    <svg className="svg-icon edit-icon ">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </BSTooltip>
                                            )}
                                    </div>
                                    <div className="option-wrapper__item-details">
                                        {confirmationGroup?.coLoader ?? '-'}
                                    </div>
                                </div>
                                <div className="option-wrapper__row">
                                    <div className="option-wrapper__item">
                                        <div className="option-wrapper__item-title">
                                            Buy Cost
                                            {(profileType === UserProfileType.Agent ||
                                                (profileType === UserProfileType.FORWARDER &&
                                                    confirmationGroup?.status?.toLowerCase() ===
                                                        PoContainerStatus.OptionsToBeAdded)) && (
                                                <span className="mandatory-field-text">*</span>
                                            )}
                                        </div>
                                        <div className="option-wrapper__item-details">
                                            {confirmationGroup?.lclBuyCost ? (
                                                <span
                                                    className={`${
                                                        addEditSubmitStatus('addEditDetails') &&
                                                        profileType === UserProfileType.Agent
                                                            ? 'green-text'
                                                            : ''
                                                    }`}
                                                >
                                                    {confirmationGroup?.lclBuyCost} {confirmationGroup?.lclBuyCurrency}
                                                </span>
                                            ) : (
                                                // confirmationGroup?.lclBuyCost +
                                                //   ' ' +
                                                //   confirmationGroup?.lclBuyCurrency
                                                '-'
                                            )}
                                        </div>
                                    </div>
                                    <div className="option-wrapper__item">
                                        <div className="option-wrapper__item-title">
                                            Buy Quote
                                            {(profileType === UserProfileType.Agent ||
                                                (profileType === UserProfileType.FORWARDER &&
                                                    confirmationGroup?.status?.toLowerCase() ===
                                                        PoContainerStatus.OptionsToBeAdded)) && (
                                                <span className="mandatory-field-text">*</span>
                                            )}
                                        </div>
                                        <div className="option-wrapper__item-details">
                                            {confirmationGroup?.lclBuyQuoteName ? (
                                                <a
                                                    onClick={() =>
                                                        downloadConfirmationGroupQuote(
                                                            confirmationGroup?.lclSavedFileId,
                                                            confirmationGroup?.type,
                                                            'buy',
                                                            confirmationGroup?.confirmationGroupId,
                                                            confirmationGroup?.lclBuyQuoteName
                                                        )
                                                    }
                                                >
                                                    {confirmationGroup?.lclBuyQuoteName}
                                                </a>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="option-wrapper__item"></div>
                                <div className="option-wrapper__item"></div>
                            </>
                        )}
                        {profileType === UserProfileType.FORWARDER || profileType === UserProfileType.Vendor ? (
                            <>
                                <div className="option-wrapper__row">
                                    <div className="option-wrapper__item">
                                        <div className="option-wrapper__item-title">
                                            Sell Cost
                                            {confirmationGroup?.status?.toLowerCase() !==
                                                PoContainerStatus.OptionsToBeAdded && (
                                                <span className="mandatory-field-text">*</span>
                                            )}
                                        </div>
                                        <div className="option-wrapper__item-details">
                                            {confirmationGroup?.lclSellCost ? (
                                                <>
                                                    <span
                                                        className={`${
                                                            addEditSubmitStatus('addEditDetails') &&
                                                            (profileType === UserProfileType.FORWARDER ||
                                                                profileType === UserProfileType.Vendor)
                                                                ? 'green-text'
                                                                : ''
                                                        }`}
                                                    >
                                                        {confirmationGroup?.lclSellCost}{' '}
                                                        {confirmationGroup?.lclSellCurrency}
                                                    </span>
                                                    {/* <span className="green-text">
                                                        {confirmationGroup?.lclSellCost}{' '}
                                                        {confirmationGroup?.lclSellCurrency}
                                                    </span> */}
                                                </>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </div>
                                    <div className="option-wrapper__item">
                                        <div className="option-wrapper__item-title">
                                            Sell Quote
                                            {confirmationGroup?.status?.toLowerCase() !==
                                                PoContainerStatus.OptionsToBeAdded && (
                                                <span className="mandatory-field-text">*</span>
                                            )}
                                        </div>
                                        <div className="option-wrapper__item-details">
                                            {confirmationGroup?.lclSellQuoteName ? (
                                                <a
                                                    onClick={() =>
                                                        downloadConfirmationGroupQuote(
                                                            confirmationGroup?.lclSellSavedFileId,
                                                            confirmationGroup?.type,
                                                            'sell',
                                                            confirmationGroup?.confirmationGroupId,
                                                            confirmationGroup?.lclSellQuoteName
                                                        )
                                                    }
                                                >
                                                    {confirmationGroup?.lclSellQuoteName}
                                                </a>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </div>{' '}
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="option-wrapper__item"></div>
                            </>
                        )}
                        {profileType === UserProfileType.Client ? (
                            <>
                                <div className="option-wrapper__row -col-2">
                                    <div className="option-wrapper__item">
                                        <div className="option-wrapper__item-title">Cost</div>
                                        <div className="option-wrapper__item-details">
                                            {confirmationGroup?.lclSellCost
                                                ? confirmationGroup?.lclSellCost +
                                                  ' ' +
                                                  confirmationGroup?.lclSellCurrency
                                                : '-'}
                                        </div>
                                    </div>
                                    <div className="option-wrapper__item">
                                        <div className="option-wrapper__item-title">Quote</div>
                                        <div className="option-wrapper__item-details">
                                            {confirmationGroup?.lclSellQuoteName ? (
                                                <a
                                                    onClick={() =>
                                                        downloadConfirmationGroupQuote(
                                                            confirmationGroup?.lclSellSavedFileId,
                                                            confirmationGroup?.type,
                                                            'sell',
                                                            confirmationGroup?.confirmationGroupId,
                                                            confirmationGroup?.lclSellQuoteName
                                                        )
                                                    }
                                                >
                                                    {confirmationGroup?.lclSellQuoteName}
                                                </a>
                                            ) : (
                                                '-'
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="option-wrapper__item"></div>
                            </>
                        )}
                        <div className="option-wrapper__item wrapper_with-edit">
                            {groupSelected === 'LCL' &&
                                (confirmationGroup?.status?.toLowerCase() === PoContainerStatus.ConfirmedForBooking ||
                                    confirmationGroup?.status?.toLowerCase() === PoContainerStatus.InBookingProposal ||
                                    confirmationGroup?.status?.toLowerCase() === PoContainerStatus.Booked) && (
                                    <div className="modal-toast completed">Selected</div>
                                )}
                            {addEditSubmitStatus('addEditDetails') &&
                                headerData?.poStatus !== 'Pending_Reassignment' &&
                                headerData?.poStatus?.toLowerCase() !== 'cancelled' && (
                                    <div className="btn-holder">
                                        {addOrEdit('LCL') === 'add' ? (
                                            <button
                                                className="app-btn app-btn-secondary icon-button"
                                                title="Add Details"
                                                onClick={() => setDetailsComponent({ status: true, type: 'LCL' })}
                                            >
                                                <svg className="svg-icon add-btn-icon">
                                                    <use xlinkHref="#plusIcon"></use>
                                                </svg>
                                                <span className="button-text">Add Details</span>
                                            </button>
                                        ) : (
                                            ((profileType === 'agent' &&
                                                confirmationGroup?.status?.toLowerCase() !==
                                                    'pending_action_forwarder') ||
                                                confirmationGroup?.status?.toLowerCase() !== 'in_booking_proposal') && (
                                                <button
                                                    type="button"
                                                    className="app-btn app-btn-secondary icon-button"
                                                    title="Edit Details"
                                                    onClick={() => setDetailsComponent({ status: true, type: 'LCL' })}
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                    <span className="button-text">Edit Details</span>
                                                </button>
                                            )
                                        )}
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                {(profileType === UserProfileType.Client && !confirmationGroup?.fclSellCost) ||
                (profileType === UserProfileType.Agent && !confirmationGroup?.fclBuyCost && selectedContainerAgent) ||
                ((profileType === UserProfileType.FORWARDER || profileType === UserProfileType.Vendor) &&
                    selectedContainerForwarder &&
                    !confirmationGroup?.fclSellCost) ? (
                    <div></div>
                ) : (
                    <div className={`option-card ${approveSelectionStatus('FCL')}`}>
                        <div className="option-wrapper__header">
                            <h3 className="options-wrapper__title">
                                {profileType === UserProfileType.Client &&
                                    confirmationGroup?.status?.toLowerCase() ===
                                        PoContainerStatus.PendingSelectionClient && (
                                        <label>
                                            <input
                                                type="radio"
                                                name="optionSelection"
                                                className="option-selection"
                                                checked={groupSelected === 'FCL' ? true : false}
                                                onChange={() => setGroupSelected('FCL')}
                                            />
                                            <span className="input-radio" />
                                        </label>
                                    )}
                                FCL
                            </h3>
                            {profileType === UserProfileType.Agent ||
                            profileType === UserProfileType.FORWARDER ||
                            profileType === UserProfileType.Vendor ? (
                                <>
                                    <div className="option-wrapper__item"></div>
                                    <div className="option-wrapper__row">
                                        <div className="option-wrapper__item">
                                            <div className="option-wrapper__item-title">Buy Cost</div>
                                            <div className="option-wrapper__item-details">
                                                {confirmationGroup?.fclBuyCost ? (
                                                    <span
                                                        className={`${
                                                            addEditSubmitStatus('addEditDetails') &&
                                                            profileType === UserProfileType.Agent
                                                                ? 'green-text'
                                                                : ''
                                                        }`}
                                                    >
                                                        {confirmationGroup?.fclBuyCost}{' '}
                                                        {confirmationGroup?.fclBuyCurrency}
                                                    </span>
                                                ) : (
                                                    // confirmationGroup?.fclBuyCost +
                                                    //       ' ' +
                                                    //       confirmationGroup?.fclBuyCurrency
                                                    '-'
                                                )}
                                            </div>
                                        </div>
                                        <div className="option-wrapper__item">
                                            <div className="option-wrapper__item-title">Buy Quote</div>
                                            <div className="option-wrapper__item-details">
                                                {confirmationGroup?.fclBuyQuoteName ? (
                                                    <a
                                                        onClick={() =>
                                                            downloadConfirmationGroupQuote(
                                                                confirmationGroup?.fclSavedFileId,
                                                                confirmationGroup?.type,
                                                                'buy',
                                                                confirmationGroup?.confirmationGroupId,
                                                                confirmationGroup?.fclBuyQuoteName
                                                            )
                                                        }
                                                    >
                                                        {confirmationGroup?.fclBuyQuoteName}
                                                    </a>
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </div>{' '}
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="option-wrapper__item"></div>
                                    <div className="option-wrapper__item"></div>
                                </>
                            )}

                            {profileType === UserProfileType.FORWARDER || profileType === UserProfileType.Vendor ? (
                                <>
                                    <div className="option-wrapper__row">
                                        <div className="option-wrapper__item">
                                            <div className="option-wrapper__item-title">
                                                Sell Cost
                                                {confirmationGroup?.fclBuyCost &&
                                                    confirmationGroup?.status?.toLowerCase() !==
                                                        PoContainerStatus.OptionsToBeAdded && (
                                                        <span className="mandatory-field-text">*</span>
                                                    )}
                                            </div>
                                            <div className="option-wrapper__item-details">
                                                {confirmationGroup?.fclSellCost ? (
                                                    <>
                                                        <span
                                                            className={`${
                                                                addEditSubmitStatus('addEditDetails') &&
                                                                (profileType === UserProfileType.FORWARDER ||
                                                                    profileType === UserProfileType.Vendor)
                                                                    ? 'green-text'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {confirmationGroup?.fclSellCost}{' '}
                                                            {confirmationGroup?.fclSellCurrency}
                                                        </span>
                                                    </>
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </div>
                                        <div className="option-wrapper__item">
                                            <div className="option-wrapper__item-title">
                                                Sell Quote
                                                {confirmationGroup?.fclBuyCost &&
                                                    confirmationGroup?.status?.toLowerCase() !==
                                                        PoContainerStatus.OptionsToBeAdded && (
                                                        <span className="mandatory-field-text">*</span>
                                                    )}
                                            </div>
                                            <div className="option-wrapper__item-details">
                                                {confirmationGroup?.fclSellQuoteName ? (
                                                    <a
                                                        onClick={() =>
                                                            downloadConfirmationGroupQuote(
                                                                confirmationGroup?.fclSellSavedFileId,
                                                                confirmationGroup?.type,
                                                                'sell',
                                                                confirmationGroup?.confirmationGroupId,
                                                                confirmationGroup?.fclSellQuoteName
                                                            )
                                                        }
                                                    >
                                                        {confirmationGroup?.fclSellQuoteName}
                                                    </a>
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="option-wrapper__item"></div>
                                </>
                            )}
                            {profileType === UserProfileType.Client ? (
                                <>
                                    <div className="option-wrapper__row -col-2">
                                        <div className="option-wrapper__item">
                                            <div className="option-wrapper__item-title">Cost</div>
                                            <div className="option-wrapper__item-details">
                                                {confirmationGroup?.fclSellCost
                                                    ? confirmationGroup?.fclSellCost +
                                                      ' ' +
                                                      confirmationGroup?.fclSellCurrency
                                                    : '-'}
                                            </div>
                                        </div>
                                        <div className="option-wrapper__item">
                                            <div className="option-wrapper__item-title">Quote</div>
                                            <div className="option-wrapper__item-details">
                                                {confirmationGroup?.fclSellQuoteName ? (
                                                    <a
                                                        onClick={() =>
                                                            downloadConfirmationGroupQuote(
                                                                confirmationGroup?.fclSellSavedFileId,
                                                                confirmationGroup?.type,
                                                                'sell',
                                                                confirmationGroup?.confirmationGroupId,
                                                                confirmationGroup?.fclSellQuoteName
                                                            )
                                                        }
                                                    >
                                                        {confirmationGroup?.fclSellQuoteName}
                                                    </a>
                                                ) : (
                                                    '-'
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="option-wrapper__item"></div>
                                </>
                            )}
                            <div className="option-wrapper__item  wrapper_with-edit">
                                {groupSelected === 'FCL' &&
                                    (confirmationGroup?.status?.toLowerCase() ===
                                        PoContainerStatus.ConfirmedForBooking ||
                                        confirmationGroup?.status?.toLowerCase() ===
                                            PoContainerStatus.InBookingProposal ||
                                        confirmationGroup?.status?.toLowerCase() === PoContainerStatus.Booked) && (
                                        <div className="modal-toast completed">Selected</div>
                                    )}
                                {addEditSubmitStatus('addEditDetails1') &&
                                    headerData?.poStatus !== 'Pending_Reassignment' &&
                                    headerData?.poStatus?.toLowerCase() !== 'cancelled' && (
                                        <div className="btn-holder">
                                            {addOrEdit('FCL') === 'add' ? (
                                                <button
                                                    className="app-btn app-btn-secondary icon-button"
                                                    title="Add Details"
                                                    onClick={() => setDetailsComponent({ status: true, type: 'FCL' })}
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#plusIcon"></use>
                                                    </svg>
                                                    <span className="button-text">Add Details</span>
                                                </button>
                                            ) : (
                                                <button
                                                    type="button"
                                                    className="app-btn app-btn-secondary icon-button"
                                                    title="Edit Details"
                                                    onClick={() => setDetailsComponent({ status: true, type: 'FCL' })}
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                    <span className="button-text">Edit Details</span>
                                                </button>
                                            )}
                                        </div>
                                    )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {detailsComponent.status && (
                <AddModal
                    modalType={detailsComponent.type}
                    setDetailsComponent={setDetailsComponent}
                    profileType={profileType}
                    confirmationGroup={confirmationGroup}
                />
            )}
        </>
    );
};

const ConfirmedLinesComponent = (props: any) => {
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    const shipmentGrid = props?.headerData?.isHeaderOnlyPo;
    return (
        <>
            <div className="grid-section px-0">
                <div className="grid-holder confirm-line-grid">
                    <div className="grid-container">
                        {!shipmentGrid &&
                            (props?.confirmedlinesDtos?.length === 0 ? (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No records found</p>
                                </div>
                            ) : (
                                <div className="grid-scroll-content">
                                    <table className="grid-table">
                                        <thead>
                                            <tr className="grid-sticky-row">
                                                <th className="grid-cell-header col-line-no">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">PO LINE NO.</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-sku">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">SKU</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-desc">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">DESCRIPTION</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-quality">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">Quantity</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-desc">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">GOODS READY DATE</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-desc">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">ORIGIN PORT</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-desc">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">DESTINATION PORT</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header  col-cbm">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">CBM</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-weight">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">WEIGHT (Kg)</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="grouped-splited-row">
                                            {props?.confirmedlinesDtos?.map((res: any) => (
                                                <tr>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">1</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            <a
                                                                onClick={() =>
                                                                    setModalViewproduct({
                                                                        status: true,
                                                                        value: props.clientCode,
                                                                        id: res?.skuId,
                                                                    })
                                                                }
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {res?.sku}
                                                            </a>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.description}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.qty}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {moment(res?.goodsReadyDate).format('DD-MM-YYYY')}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.originPort}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.destinationPort}</div>
                                                    </td>
                                                    <td className="grid-cell col-grd-confirmed">
                                                        <div className="grid-cell-data">{res?.cbm}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.weight}</div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            ))}

                        {shipmentGrid && (
                            <>
                                {/* Confirmed Lines for triangle */}
                                <div className="grid-scroll-content">
                                    <table className="grid-table shipment-grid">
                                        <thead>
                                            <tr className="grid-sticky-row">
                                                <th className="grid-cell-header col-ao-cbm-cost">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">
                                                            CBM <span className="mandatory-field-text">*</span>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-weight">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">
                                                            WEIGHT(Kg) <span className="mandatory-field-text">*</span>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-grd">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">
                                                            GOODS READY DATE{' '}
                                                            <span className="mandatory-field-text">*</span>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-destination-port">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">
                                                            DESTINATION <span className="mandatory-field-text">*</span>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-dest">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">
                                                            DESTINATION Address{' '}
                                                            <span className="mandatory-field-text">*</span>
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-sku">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">SKU</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-quanity">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">QUANTITY</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-hs-line">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">HS CODE</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props?.confirmedlinesDtos?.map((res: any) => (
                                                <tr className="no-border" key={res.poLineNo}>
                                                    <td className="grid-cell td-po-line">
                                                        <div className="grid-cell-data">{res?.cbm ?? '-'}</div>
                                                    </td>
                                                    <td className="grid-cell search-entry-holder td-sku">
                                                        <div className="grid-cell-data">{res?.weight ?? '-'}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        {moment(res?.goodsReadyDate).format('DD-MM-YYYY')}
                                                    </td>
                                                    <td className="grid-cell search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            {res?.destinationPort ?? '-'}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell search-entry-holder">
                                                        <div className="grid-cell-data">
                                                            {res?.destinationAddress ?? '-'}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.sku ?? '-'}</div>
                                                    </td>

                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.qty ?? '-'}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.hsCode ?? '-'}</div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            {ModalViewproduct.status && (
                <div className="grid-section grid-added-booking product-details-wrapper">
                    <div className="grid-holder">
                        <div className="grid-container">
                            <ProductDetailModal display={ModalViewproduct} displayFunction={setModalViewproduct} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

const Index = () => {
    const { profileType } = useUserProfile();
    const { id, group_id } = useParams();
    const dispatch = useDispatch();
    const confirmationGroup = useAppSelector(selectConfirmationGroup);
    const RejectionStat = useAppSelector(confirmationGroupRejectionStat);
    const isError = useAppSelector(selectContainerModePoIsError);
    const containerModePoConfirmationSuccess = useAppSelector(selectContainerModePoSuccess);
    const containerModePoConfirmationError = useAppSelector(selectContainerModePoError);
    const headerData = useAppSelector(selectHeader);
    const isLoading = useAppSelector(selectContainerModePoLoading);
    const [optionsSelector, setOptionsSelector] = useState(false); //tab function to switch between options and lines tab
    const [rejectOptions, setRejectOptions] = useState(false); //rejected status enabled with modal open
    // const [assignToAgent, setAssignToAgent] = useState(false); //assigned to agent modal and related screens
    const [detailsComponent, setDetailsComponent] = useState({ status: false, type: 'LCL' }); //enable to edit/add view between selected option for LCL OR FCL in modal
    const [groupSelected, setGroupSelected] = useState('');
    const [AssignAgentModalView, setAssignAgentModalView] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);

    const { confirmedLines, submitText, submitToForwarder } = useSelector((state: RootState) => state.purchaseOrder);
    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';

    const breadData = [
        {
            title: poName,
            url: poUrl,
        },
        {
            title: 'PO Details',
            url: `/purchase_order/${id}/container_mode_selection`,
        },
        {
            title: 'Confirmation Groups',
            url: `/purchase_order/${id}/container_mode_selection`,
        },
        {
            title: confirmationGroup?.confirmationGroupName,
        },
    ];

    useEffect(() => {
        dispatch(fetchConfirmedLines(group_id));
    }, [group_id]);

    useEffect(() => {
        dispatch(fetchConfirmationGroup({ confirmationGroupId: group_id }));
        dispatch(fetchCurrency({}));
    }, []);
    useEffect(() => {
        if (headerData?.clientCode) {
            dispatch(fetchCoLoader({ clientCode: headerData?.clientCode }));
        }
    }, [headerData?.clientCode]);

    useEffect(() => {
        if ((confirmationGroup?.type === 'FCL' || confirmationGroup?.type === 'BCN') && confirmedLines?.length) {
            setOptionsSelector(true);
        }
        if (confirmationGroup?.selectedGroup) {
            setGroupSelected(confirmationGroup?.selectedGroup?.toUpperCase());
        } else {
            setGroupSelected('');
        }
    }, [confirmationGroup, confirmedLines]);

    useEffect(() => {
        //if (RejectionStat) {
        dispatch(fetchConfirmationGroup({ confirmationGroupId: group_id }));
        //}
    }, [RejectionStat]);

    const onSubmit = (type: string) => {
        if (type === 'submitToForwarder') {
            dispatch(submitForwardercontainer(group_id));
        } else if (type === 'submitToClient') {
            dispatch(submitToClient({ confirmationGroupId: confirmationGroup?.confirmationGroupId }));
        } else if (type === 'approveSelection') {
            dispatch(approveGroup({ confirmationGroupId: confirmationGroup?.confirmationGroupId, groupSelected }));
        }
    };

    useEffect(() => {
        if (isError) {
            error(containerModePoConfirmationError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (
            containerModePoConfirmationSuccess?.status === 200 ||
            containerModePoConfirmationSuccess?.status === 201
        ) {
            if (containerModePoConfirmationSuccess?.data?.mode === 'addLcl') {
                success('LCL added successfully', Position.TOP_RIGHT);
            } else if (containerModePoConfirmationSuccess?.data?.mode === 'editLcl') {
                success('LCL  updated successfully', Position.TOP_RIGHT);
            } else if (containerModePoConfirmationSuccess?.data?.mode === 'addFcl') {
                success('FCL added successfully', Position.TOP_RIGHT);
            } else if (containerModePoConfirmationSuccess?.data?.mode === 'editFcl') {
                success('FCL updated successfully', Position.TOP_RIGHT);
            } else if (containerModePoConfirmationSuccess?.data?.mode === 'submitToClient') {
                success('Container mode options has been sent to Client', Position.TOP_RIGHT);
            } else if (containerModePoConfirmationSuccess?.data?.mode === 'approveGroup') {
                success(
                    `Confirmation approved with ${containerModePoConfirmationSuccess?.data?.groupSelected} option`,
                    Position.TOP_RIGHT
                );
            }
            setDetailsComponent({ status: false, type: 'LCL' });
            dispatch(clearFailure([]));
            dispatch(fetchConfirmationGroup({ confirmationGroupId: group_id }));
            dispatch(
                fetchHeader({
                    poId: id,
                    profile: profileType,
                    cgId: group_id,
                })
            );
        }
    }, [isError, containerModePoConfirmationSuccess, containerModePoConfirmationError]);

    useEffect(() => {
        if (submitToForwarder) {
            success(`Container mode options has been sent to ${submitText}`, Position.TOP_RIGHT);
            dispatch(submitToSuccess({ status: false, type: '' }));
            dispatch(fetchConfirmationGroup({ confirmationGroupId: group_id }));
        }
    }, [submitToForwarder, submitText]);

    const addEditSubmitStatus = (type: string) => {
        const status = confirmationGroup?.status?.toLowerCase();

        if (profileType === UserProfileType.Client) {
            return type === 'approveSelection' && status === PoContainerStatus.PendingSelectionClient;
        }
        if (profileType === UserProfileType.Agent) {
            return type === 'submitToForwarder' || type === 'addEditDetails1'
                ? status === PoContainerStatus.OptionsToBeAdded ||
                      status === PoContainerStatus.RejectedByClientWithAgent
                : type === 'addEditDetails' &&
                      status !== PoContainerStatus.InBookingProposal &&
                      //   status !== PoContainerStatus.PendingActionForwarder &&
                      status !== PoContainerStatus.Booked;
        }
        if (profileType === UserProfileType.FORWARDER) {
            return type === 'submitToForwarder'
                ? status === PoContainerStatus.OptionsToBeAdded
                : type === 'submitToClient'
                ? status === PoContainerStatus.PendingActionForwarder ||
                  status === PoContainerStatus.RejectedByClientWithForwarder
                : type === 'addEditDetails1'
                ? status === PoContainerStatus.PendingActionForwarder ||
                  status === PoContainerStatus.RejectedByClientWithForwarder ||
                  status === PoContainerStatus.OptionsToBeAdded
                : type === 'addEditDetails' &&
                  //   status !== PoContainerStatus.OptionsToBeAdded &&
                  status !== PoContainerStatus.Booked &&
                  status !== PoContainerStatus.InBookingProposal;
        }

        return false;
    };

    return (
        <div className="main-wrapper container product-details-confirmation ">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    data={breadData}
                    component={
                        profileType === UserProfileType.Agent &&
                        headerData?.poStatus !== 'Pending_Reassignment' &&
                        headerData?.poStatus?.toLowerCase() !== 'cancelled' &&
                        (confirmationGroup?.status?.toLowerCase() === PoContainerStatus.OptionsToBeAdded ||
                            confirmationGroup?.status?.toLowerCase() ===
                                PoContainerStatus.RejectedByClientWithAgent) ? (
                            <>
                                <div className="action-wrapper">
                                    <div className="btn-holder discard-group">
                                        <button
                                            className="app-btn app-btn-secondary black-outline-btn icon-button"
                                            title="Discard Confirmation Group"
                                            onClick={() => setShowDiscardModal(true)}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#deleteIcon"></use>
                                            </svg>
                                            <span className="button-text">Discard Confirmation Group</span>
                                        </button>
                                    </div>
                                    {showDiscardModal ? (
                                        <DiscardConfirmationModal
                                            setShowDiscardModal={setShowDiscardModal}
                                            confirmationGroup={confirmationGroup}
                                        />
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <></>
                        )
                    }
                />
                <ConfirmationGroupHeader id={id} data={confirmationGroup} />
            </div>
            {isLoading && (
                <div style={{ zIndex: 9999 }} className="app-loader loading">
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
            )}
            <div className="content-section main-wrapper container-group">
                <div className="tab-nav-holder tab-nav-reset">
                    {(() => {
                        if (confirmationGroup?.type === 'LCL') {
                            return (
                                <>
                                    <a
                                        className={`tab-nav ${optionsSelector === false && 'active'}`}
                                        onClick={() => setOptionsSelector(false)}
                                    >
                                        Options
                                    </a>

                                    <a
                                        className={`tab-nav ${optionsSelector === true && 'active'}`}
                                        onClick={() => setOptionsSelector(true)}
                                    >
                                        Confirmed Lines
                                    </a>
                                </>
                            );
                        } else {
                            return (
                                <a
                                    className={`tab-nav ${optionsSelector === true && 'active'}`}
                                    onClick={() => setOptionsSelector(true)}
                                >
                                    Confirmed Lines
                                </a>
                            );
                        }
                    })()}
                </div>
                {(() => {
                    if (confirmationGroup?.type === 'LCL') {
                        return (
                            <>
                                {optionsSelector ? (
                                    <ConfirmedLinesComponent
                                        profileType={profileType}
                                        confirmedlinesDtos={confirmedLines}
                                        clientCode={headerData?.clientCode}
                                        headerData={headerData}
                                    />
                                ) : (
                                    <OptionsComponent
                                        profileType={profileType}
                                        addEditSubmitStatus={addEditSubmitStatus}
                                        setDetailsComponent={setDetailsComponent}
                                        detailsComponent={detailsComponent}
                                        confirmationGroup={confirmationGroup}
                                        setGroupSelected={setGroupSelected}
                                        groupSelected={groupSelected}
                                        headerData={headerData}
                                    />
                                )}
                            </>
                        );
                    } else {
                        return (
                            <ConfirmedLinesComponent
                                profileType={profileType}
                                confirmedlinesDtos={confirmedLines}
                                clientCode={headerData?.clientCode}
                                headerData={headerData}
                            />
                        );
                    }
                })()}

                {/* {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )} */}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/purchase_order/${id}/container_mode_selection`}
                        className="app-btn app-btn-secondary footer-btn footer-btn-container"
                        title="Back"
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </Link>
                    {confirmationGroup?.type === 'LCL' &&
                        headerData?.poStatus !== 'Pending_Reassignment' &&
                        headerData?.poStatus?.toLowerCase() !== 'cancelled' && (
                            <>
                                {confirmationGroup?.status?.toLowerCase() === 'rejected_by_client__with_forwarder' &&
                                    (profileType === UserProfileType.FORWARDER ||
                                        profileType === UserProfileType.Vendor) &&
                                    !confirmationGroup?.isSubmittedbyForwarder && (
                                        <button
                                            title="Assign to Agent"
                                            className="app-btn app-btn-secondary footer-btn footer-btn-container"
                                            onClick={() => {
                                                setAssignAgentModalView(true);
                                            }}
                                            type="button"
                                        >
                                            <span className="button-text footer-button-text">Assign to Agent</span>
                                        </button>
                                    )}
                                {addEditSubmitStatus('submitToForwarder') && (
                                    <button
                                        className="app-btn app-btn-primary footer-btn footer-btn-container"
                                        title="Submit to Forwarder"
                                        onClick={() => onSubmit('submitToForwarder')}
                                        disabled={confirmationGroup?.lclBuyCost ? false : true}
                                        type="button"
                                    >
                                        <span className="button-text footer-button-text">Submit to Forwarder</span>
                                    </button>
                                )}

                                {addEditSubmitStatus('submitToClient') && (
                                    <button
                                        className="app-btn app-btn-primary footer-btn footer-btn-container"
                                        title={`${
                                            confirmationGroup?.lclSellCost &&
                                            ((confirmationGroup?.fclBuyCost && confirmationGroup?.fclSellCost) ||
                                                !confirmationGroup?.fclBuyCost)
                                                ? 'Submit to Client'
                                                : 'Mandatory fields are missing or needs to be filled'
                                        }`}
                                        onClick={() => onSubmit('submitToClient')}
                                        disabled={
                                            confirmationGroup?.lclSellCost &&
                                            ((confirmationGroup?.fclBuyCost && confirmationGroup?.fclSellCost) ||
                                                !confirmationGroup?.fclBuyCost)
                                                ? false
                                                : true
                                        }
                                        type="button"
                                    >
                                        <span className="button-text footer-button-text">Submit to Client</span>
                                    </button>
                                )}

                                {addEditSubmitStatus('approveSelection') && (
                                    <>
                                        <button
                                            className="app-btn app-btn-secondary footer-btn footer-btn-container"
                                            title="Reject Options"
                                            onClick={() => setRejectOptions(true)}
                                            type="button"
                                        >
                                            <span className="button-text footer-button-text">Reject Options</span>
                                        </button>
                                        <button
                                            className="app-btn app-btn-primary footer-btn footer-btn-container"
                                            title="Approve Selection"
                                            onClick={() => onSubmit('approveSelection')}
                                            disabled={groupSelected ? false : true}
                                        >
                                            <span className="button-text footer-button-text">Approve Selection</span>
                                        </button>
                                    </>
                                )}
                            </>
                        )}
                </div>
            </div>
            {((rejectOptions && profileType === 'client') ||
                (headerData?.isViewed === null &&
                    (profileType === 'forwarder' || profileType === 'vendor') &&
                    confirmationGroup?.status?.toLowerCase() === 'rejected_by_client__with_forwarder') ||
                (headerData?.isViewed === null &&
                    profileType === 'agent' &&
                    confirmationGroup?.status?.toLowerCase() === 'rejected_by_client__with_agent')) && (
                <RejectOptions
                    handle={setRejectOptions}
                    reasonData={headerData?.reason}
                    onClose={setRejectOptions}
                    setAssignAgentModalView={setAssignAgentModalView}
                    confirmationGroup={confirmationGroup}
                />
            )}
            {AssignAgentModalView && (
                <AssignToAgent
                    handle={setAssignAgentModalView}
                    reasonData={headerData?.reason}
                    onClose={setAssignAgentModalView}
                />
            )}
        </div>
    );
};
export default Index;
