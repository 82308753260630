import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    fetchAgentBasicDetailsList,
    selectAgentBasicDetailsError,
    selectAgentBasicDetailsIsError,
    selectAgentBasicDetailsSuccess,
    updateForwarderRank,
} from '../../../../store/reducers/profiles/agents/basicDetailsReducer';
import { error, Position, success } from '../../../../utils/toast';
import './agent-profile-information-modal.scss';
import { fetchHeaderDataAgents } from 'src/store/reducers/agentReducer';

interface ModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    ForwarderRanks: any;
    agentId: string | undefined;
    ForwarderRanking: string;
}
const Index: React.FC<ModalProps> = (props) => {
    const [ForwarderRanking, setForwarderRanking] = useState('');
    const [ForwarderRankId, setForwarderRankId] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const dispatch = useAppDispatch();
    const agentBasicDetailsSuccess = useAppSelector(selectAgentBasicDetailsSuccess);
    const agentBasicDetailsIsError = useAppSelector(selectAgentBasicDetailsIsError);
    const agentBasicDetailsError = useAppSelector(selectAgentBasicDetailsError);

    useEffect(() => {
        if (agentBasicDetailsIsError) {
            error(agentBasicDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        } else if (agentBasicDetailsSuccess?.status === 200) {
            success('Forwarder Ranking has been updated', Position.TOP_RIGHT);
            dispatch(fetchAgentBasicDetailsList({ id: props.agentId }));
            dispatch(fetchHeaderDataAgents(props.agentId));
            props.displayFunction(false);
        }
    }, [agentBasicDetailsIsError, agentBasicDetailsSuccess, agentBasicDetailsError]);

    useEffect(() => {
        setForwarderRanking(props.ForwarderRanking);
    }, [props.display]);

    return (
        <>
            {props.display ? (
                <div
                    onClick={() => {
                        setShowDropdown(false);
                    }}
                    className="app-modal agent-profile-information-modal"
                >
                    <div className="modal-content-holder">
                        <div className="modal-header">
                            <div className="title">Profile Information</div>
                            <button
                                onClick={() => {
                                    props.displayFunction(false);
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content">
                                <form id="my-form" action="" className="modal-form layout-holder one-column">
                                    <div className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Forwarder Ranking<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <div
                                                className={
                                                    showDropdown
                                                        ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                        : 'dropdown-wrap  menu-down modal-dropdown'
                                                }
                                            >
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowDropdown((prev) => !prev);
                                                    }}
                                                    type="button"
                                                    className="menu-btn app-btn"
                                                >
                                                    <span className="btn-text">
                                                        {ForwarderRanking
                                                            ? ForwarderRanking
                                                            : 'Select Forwarder Ranking'}
                                                    </span>
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div
                                className={
                                    showDropdown
                                        ? 'dropdown-wrap forwarder-ranking-dropdown-wrap outside-menu  menu-down dropdown-open modal-dropdown'
                                        : 'dropdown-wrap forwarder-ranking-dropdown-wrap outside-menu  menu-down modal-dropdown'
                                }
                            >
                                <div className="dropdown-menu">
                                    {props?.ForwarderRanks &&
                                        props?.ForwarderRanks.map((ForwarderRank: any, index: number) => (
                                            <a
                                                onClick={() => {
                                                    setForwarderRanking(ForwarderRank?.rank);
                                                    setForwarderRankId(ForwarderRank?.fr_PK);
                                                    setShowSave(true);
                                                }}
                                                key={index}
                                                className="menu-item app-btn"
                                            >
                                                <span className="main-content">{ForwarderRank?.rank}</span>
                                            </a>
                                        ))}
                                    {/* <a className="menu-item app-btn">
                                        <span className="main-content">Tier 1</span>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <span className="main-content">Tier 2</span>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <span className="main-content">Tier 3</span>
                                    </a> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => {
                                    props.displayFunction(false);
                                }}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className={`app-btn app-btn-primary modal-btn footer-btn ${
                                    showSave && ForwarderRanking !== props.ForwarderRanking ? '' : 'disabled'
                                }`}
                                type="button"
                                onClick={() => {
                                    dispatch(updateForwarderRank({ agentId: props.agentId, rankId: ForwarderRankId }));
                                }}
                                disabled={showSave && ForwarderRanking !== props.ForwarderRanking ? false : true}
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
export default Index;
