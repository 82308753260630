import axios from 'axios';
import { get, post } from '../../HttpMethods';

export const getClientData = async (payload: any) => {
    try {
        const data = await get(
            `api/mgt/clients/clientuser?userName=${payload.userName}${
                payload.profile ? `&profile=${payload.profile}` : ''
            }`,
            null
        );
        const result = data?.info.map((res: any) => ({ value: res.code, label: res.name, id: res.id }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getBLPsData = async (payload: any) => {
    try {
        const data = await get(`api/ord/ao/warehousesforclient?clientCode=${payload.clientCode}`, null);
        const result = data?.info.map((res: any) => ({ value: res.code, label: res.name }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const saveAoData = async (payload: any) => {
    try {
        const data = await post('api/ord/ao', payload, null);
        return { data: data.info, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getHeaderData = async (payload: any) => {
    try {
        const data = await get(`api/ord/ao/header?aoId=${payload.aoId}`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

// export const getCancelledReasonData = async (payload: { poId: string }) => {
//     try {
//         const data = await get(`api/ord/po/viewcancelledord?poId=${payload.poId}`, null);
//         return data.info;
//     } catch (error) {
//         if (axios.isAxiosError(error)) {
//             console.log('error message: ', error.message);
//             throw error.response;
//         } else {
//             console.log('unexpected error: ', error);
//             return error;
//         }
//     }
// };
