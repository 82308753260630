/* eslint-disable no-undefined */
import React from 'react';
interface ContentType {
    columnName: string;
    name: string;
    headerClass: string;
    hasSort: boolean;
    sortName: string;
    hasFilter: boolean;
    filterName: string;
}

type HeaderProps = {
    content: ContentType[];
    filterFunction: any;
    sortFunction: any;
    sortColumn: string;
    sortOrder: string;
    filterColumn: string;
    toggleDropdown: boolean;
    selectedFilterItem: any;
    selectAllStatus?: any;
    selectAllOnChange?: any;
    hideCheckboxColumn?: boolean;
};
const Index: React.FC<HeaderProps> = ({
    content,
    filterFunction,
    sortFunction,
    sortColumn,
    sortOrder,
    filterColumn,
    toggleDropdown,
    selectedFilterItem,
    selectAllStatus,
    selectAllOnChange,
    hideCheckboxColumn,
}) => {
    return (
        <thead>
            <tr className="grid-sticky-row">
                {!hideCheckboxColumn && (
                    <th className="grid-cell-header col-checkbox sticky-left-aligned  grid-sticky-column">
                        <div className="grid-cell-data centre-align">
                            <label className="app-check-wrapper">
                                <input
                                    disabled={selectAllStatus('disabled')}
                                    type="checkbox"
                                    checked={selectAllStatus('checked')}
                                    onChange={(e) => selectAllOnChange(e.target.checked)}
                                    className="checkbox-input"
                                />
                                <div className="checkmark">
                                    <svg className="svg-icon tick-icon">
                                        <use xlinkHref="#tickIcon">
                                            <title>check mark</title>
                                        </use>
                                    </svg>
                                </div>
                            </label>
                        </div>
                    </th>
                )}
                {content?.map((res) => (
                    <th key={res.name} className={res.headerClass} id={res.name}>
                        <div className="grid-cell-data">
                            <span className="header-title">{res.columnName}</span>
                            {res.hasSort && (
                                <button onClick={() => sortFunction(res.sortName)} className="sort-btn app-btn">
                                    <svg
                                        className={
                                            sortColumn === res.name
                                                ? `svg-icon sort-icon ${sortOrder === 'ASC' ? 'sort-desc' : 'sort-asc'}`
                                                : 'svg-icon sort-icon'
                                        }
                                    >
                                        <use href="#sortIcon">
                                            <title>sort</title>
                                        </use>
                                    </svg>
                                </button>
                            )}
                            {res.hasFilter && (
                                <div
                                    className={
                                        selectedFilterItem[res.name] !== undefined ||
                                        (toggleDropdown &&
                                            filterColumn === res.name &&
                                            selectedFilterItem[filterColumn] === undefined)
                                            ? 'filter-menu-wrap open'
                                            : 'filter-menu-wrap'
                                    }
                                >
                                    <button
                                        onClick={(e) => filterFunction(e, res.filterName)}
                                        className="app-btn filter-menu-button"
                                    >
                                        <svg className="svg-icon filter-icon">
                                            <use xlinkHref="#filterIcon">
                                                <title>filter</title>
                                            </use>
                                        </svg>
                                        <span className="sr-only">header menu</span>
                                    </button>
                                </div>
                            )}
                        </div>
                    </th>
                ))}
            </tr>
        </thead>
    );
};
export default Index;
