import React from 'react';
import { useAppSelector } from 'src/hooks';
import { Link, useLocation, useParams } from 'react-router-dom';
import { selectReceiptHeader } from 'src/store/reducers/receipts/receiptHeaderReducer';

const Index = () => {
    const { id } = useParams();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const receiptHeader = useAppSelector(selectReceiptHeader);

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    React.useEffect(() => {
        if (lastSegment) {
            if (lastSegment === 'receipt-header') {
                setSelectedIndex(0);
            } else if (lastSegment === 'polines') {
                setSelectedIndex(1);
            } else if (lastSegment === 'documents') {
                setSelectedIndex(2);
            } else if (lastSegment === 'confirmation') {
                setSelectedIndex(1);
            }
        }
    }, []);

    const receiptTabList = [
        {
            label: 'Receipt Header',
            value: 'receipt-header',
            id: 1,
        },
        {
            label: 'PO Lines',
            value: 'polines',
            id: 2,
        },

        {
            label: 'Documents',
            value: 'documents',
            id: 3,
        },
    ];

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (receiptHeader && receiptHeader?.plannedCustomsClearance) {
                if (param === 'receipt-header') {
                    return receiptHeader.rhtabComplete;
                } else if (param === 'polines') {
                    return receiptHeader.polinesTabComplete;
                } else if (param === 'documents') {
                    return receiptHeader.documentTabComplete;
                } else {
                    return false;
                }
            }
        };
    }, [lastSegment, receiptHeader]);

    const checkTabNavigationFromApi = (param: string, headerDetailsVal: any) => {
        if (headerDetailsVal) {
            if (headerDetailsVal?.receiptStatus === 'Ready to Receive') {
                return true;
            } else {
                if (param === 'documents') {
                    return headerDetailsVal.rhtabComplete;
                } else if (param === 'polines') {
                    return headerDetailsVal.rhtabComplete;
                } else {
                    return true;
                }
            }
        }
    };

    return (
        <div className="arrow-tab-container">
            <div className="arrow-tab-holder vendor-arrow-holder">
                {receiptTabList.map((tabs: any, index: number) => {
                    return (
                        <Link
                            to={`/blp/receipt/${id}/${tabs.value}`}
                            className={`arrow-tab-border ${index === selectedIndex && 'active'} agent-basic-tab ${
                                !checkTabNavigationFromApi(tabs.value, receiptHeader) && 'disabled'
                            }`}
                        >
                            {isTabActive(tabs.value) ? (
                                <div
                                    className="arrow-badge verify-badge"
                                    style={{
                                        backgroundColor: '#18ab56',
                                    }}
                                >
                                    <svg className="svg-icon tick-icon">
                                        <use href="#tickIcon">
                                            <title>check mark</title>
                                        </use>
                                    </svg>
                                </div>
                            ) : (
                                <div className="arrow-badge">{tabs.id}</div>
                            )}
                            <div className="arrow-tab">{tabs.label}</div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default Index;
