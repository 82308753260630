import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import {
    fetchAgentBasicDetailsList,
    resetAgentBasicDetails,
    selectAgentBasicDetails,
} from 'src/store/reducers/profiles/agents/basicDetailsReducer';

interface propsData {
    bookingData?: any;
    Btn?: any;
}
const Index: React.FC<propsData> = ({ Btn }) => {
    const dispatch = useDispatch() as any;
    const basicDetails: any = useAppSelector(selectAgentBasicDetails);
    const { wareHouseDetails } = useSelector((state: any) => state.lifeCycleSearch) as any;
    // const { id } = useParams() as any;

    useEffect(() => {
        if (wareHouseDetails.agentID) {
            dispatch(fetchAgentBasicDetailsList({ id: wareHouseDetails.agentID }));
        }
        if (!wareHouseDetails.agentID) {
            dispatch(resetAgentBasicDetails());
        }
    }, [wareHouseDetails.agentID]);

    const { ag_Org: details } = basicDetails?.data?.info || {};

    return (
        <>
            {Btn && <Btn />}
            <div className="lcs-basic-details">
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Name</div>
                    <div className="cm-key-pair-value">{details?.or_Orgname || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Code</div>
                    <div className="cm-key-pair-value">{details?.or_Orgcode || '-'}</div>
                </div>

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Category</div>
                    <div className="cm-key-pair-value">{details?.or_Orgtype || '-'}</div>
                </div>

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Company Registration Number</div>
                    <div className="cm-key-pair-value">{details?.or_ABN || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">UNLOCO</div>
                    <div className="cm-key-pair-value">{details?.or_Unloco || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Phone</div>
                    <div className="cm-key-pair-value">{details?.or_Phone || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Website URL</div>
                    <div className="cm-key-pair-value">{details?.or_WebSiteUrl || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Email</div>
                    <div className="cm-key-pair-value">{details?.or_Email || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Forwarder Ranking</div>
                    <div className="cm-key-pair-value">{details?.or_ForwarderRanking?.rank || '-'}</div>
                </div>
            </div>
        </>
    );
};
export default Index;
