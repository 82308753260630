import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import BasicDetailsModal from './BasicDetailsModal';
import { PoDetailsModal } from './PoDetailsModal';
import PoList from './poList';
import DatesModal from './DatesModal';
import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../POSelection.scss';
import './container-details.scss';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    BpContainerDetail,
    selectContainerAllocationError,
    selectContainerAllocationIsError,
    selectContainerDetail,
    selectContainerAllocationSuccess,
    selectContainerAllocationLoading,
    fetchContainerDetail,
    defaultBpContainerDetail,
    fetchContainerType,
    fetchDeliveryMode,
    fetchContainerMode,
    fetchPacking,
    fetchContainerOnlyBasicDetail,
    selectPacking,
    fetchAssignedPo,
    selectAssignedPo,
    fetchAllPo,
    removeFromContainer,
    clearFailure,
} from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
import { RootState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import BookingProposalHeader from '../../BookingProposalHeader';
// import BookingHeaderSidebar from '../../BookingHeaderSidebar';
import CancelModal from '../../../../components/common/CancelModal';
import moment from 'moment';
import { error, Position, success } from '../../../../utils/toast';
import ShowForPermissions from '../../../../ShowForPermissions';
// import PoTriangleList from './poTriangleList';
// import { PoTriangleDetailsModal } from './PoTriangleDetailsModal';
const basicData: any = [
    {
        title: 'Container Type',
        key: 'containerType',
        fields: '-',
        required: true,
    },
    {
        title: 'Delivery Mode',
        key: 'deliveryMode',
        fields: '-',
        required: true,
    },
    {
        title: 'Container Mode',
        key: 'containerMode',
        fields: '-',
        required: true,
    },
    {
        title: 'Container Number',
        key: 'containerNumber',
        fields: '-',
        required: true,
    },
    {
        title: 'Packing',
        key: 'packing',
        fields: '-',
        required: true,
    },
    {
        title: 'VGM (in Kg)',
        key: 'vgm',
        fields: '- ',
        required: true,
    },
    {
        title: 'EIR',
        key: 'eir',
        fields: '-',
        required: false,
    },
    {
        title: 'Seal Number',
        key: 'sealNumber',
        fields: '-',
        required: true,
    },
];
const dateData = [
    {
        title: 'Collection Date from Depot',
        key: 'collectionDate',
        fields: '-',
        required: true,
    },
    {
        title: 'Drop Off Date at Vendor',
        key: 'dropOffDate',
        fields: '-',
        required: true,
    },
    {
        title: 'Pick Up Date from Vendor',
        key: 'pickUpDate',
        fields: '-',
        required: true,
    },
    {
        title: 'Gate in Date and Time',
        key: 'gateInDate',
        fields: '-',
        required: true,
    },
];
const Index: React.FC = () => {
    const [showBasicDetails, setShowBasicDetails] = useState(false);
    // const [triangleModal, setTriangleModal] = useState(false);
    // const [showTriangleDetails, setTriangleDetails] = useState(false);
    const [showDatesDetail, setShowDatesDetails] = useState(false);
    const [poDetails, setPoDetails] = useState(false);
    const [cancelModalView, setCancelModalView] = useState(false);
    const [tab, setTab] = useState(0);
    const { id, allocationId } = useParams();
    const location: any = useLocation();
    const { profileType } = useUserProfile();
    const [_showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [pillClicked, setPillClicked] = useState(false);
    const [selectedItemsAdded, setSelectedItemsAdded] = useState<any[]>([]);
    const [loader, setLoader] = useState(true);
    const [containerData, setContainerData] = useState<BpContainerDetail>(defaultBpContainerDetail);
    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectContainerAllocationIsError);
    const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const containerDetail: any = useAppSelector(selectContainerDetail);
    const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    const isLoading = useAppSelector(selectContainerAllocationLoading);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const packingData = useAppSelector(selectPacking);
    const assignedPo = useAppSelector(selectAssignedPo);
    const [validation, setValidation] = useState(false);

    useEffect(() => {
        if (location?.state?.containerDetailsId) {
            dispatch(fetchContainerDetail({ containerDetailsId: location?.state?.containerDetailsId }));
            dispatch(fetchAssignedPo({ containerDetailsId: location?.state?.containerDetailsId, bpId: id }));
            dispatch(fetchAllPo({ bpId: id }));
        } else {
            dispatch(fetchContainerOnlyBasicDetail({ containerId: allocationId }));
        }
        dispatch(fetchDeliveryMode({}));
        dispatch(fetchContainerMode({}));
        dispatch(fetchPacking({}));
    }, []);

    const getPackageName = (code: any[]) => {
        if (!code) {
            return '-';
        }
        const newPackage = [...code];
        if (newPackage?.length) {
            for (let index = 0; index < code.length; index++) {
                newPackage[index] = packingData.find((el: any) => el.packingCode === code[index])?.packingName;
            }
            return newPackage.join(', ');
        } else {
            return '-';
        }
    };

    useEffect(() => {
        if (containerDetail?.measurement) {
            dispatch(fetchContainerType({ measurement: containerDetail?.measurement }));
        }
        setContainerData(containerDetail);
        for (let index = 0; index < basicData.length; index++) {
            if (basicData[index].key === 'packing') {
                basicData[index].fields = getPackageName(containerDetail[basicData[index].key]);
            } else {
                basicData[index].fields = containerDetail[basicData[index].key]
                    ? containerDetail[basicData[index].key]
                    : '-';
            }
        }
        for (let index = 0; index < dateData.length; index++) {
            if (dateData[index].key === 'gateInDate') {
                dateData[index].fields = containerDetail[dateData[index].key]
                    ? moment.utc(containerDetail[dateData[index].key]).local().format('DD-MM-YYYY, HH:mm')
                    : '-';
            } else {
                dateData[index].fields = containerDetail[dateData[index].key]
                    ? moment(containerDetail[dateData[index].key]).format('DD-MM-YYYY')
                    : '-';
            }
        }
    }, [containerDetail]);

    const removePoFromContainer = () => {
        setLoader(true);
        const bsId: any[] = [];
        // for (let i = 0; i < selectedItemsAdded.length; i++) {
        //     bsId.push(...selectedItemsAdded[i].subPolineIds);
        // }
        for (let i = 0; i < selectedItemsAdded.length; i++) {
            const poIndex = assignedPo?.bookingProposalPoList.findIndex(
                (res: any) => res.poId === selectedItemsAdded[i].id && res.boId === selectedItemsAdded[i].boId
            );
            if (poIndex >= 0) {
                for (let j = 0; j < selectedItemsAdded[i].subPolineIds.length; j++) {
                    const subPoIndex = assignedPo?.bookingProposalPoList[
                        poIndex
                    ].bookingProposalSubPOLineList.findIndex(
                        (res: any) => res.bsId === selectedItemsAdded[i].subPolineIds[j]
                    );
                    if (subPoIndex >= 0) {
                        bsId.push(
                            assignedPo?.bookingProposalPoList[poIndex].bookingProposalSubPOLineList[subPoIndex].coId
                        );
                    }
                }
            }
        }
        dispatch(
            removeFromContainer({ containerDetailsId: location?.state?.containerDetailsId, coId: bsId, bpId: id })
        );
        setSelectedItemsAdded([]);
    };

    const getCount = () => {
        let total = 0;
        selectedItemsAdded.forEach((element: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            element.subPolineIds.forEach((element2: any) => {
                total++;
            });
        });
        return total;
    };

    useEffect(() => {
        setLoader(false);
        if (isError && ContainerAllocationError?.data?.path?.includes('dates')) {
            error(ContainerAllocationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (containerAllocationSuccess?.status === 200 || containerAllocationSuccess?.status === 201) {
            if (containerAllocationSuccess?.data?.type === 'addToContainer') {
                success('PO lines have been added to Container', Position.TOP_RIGHT);
                dispatch(fetchAssignedPo({ containerDetailsId: location?.state?.containerDetailsId, bpId: id }));
                setPoDetails(false);
            } else if (containerAllocationSuccess?.data?.type === 'removeFromContainer') {
                success('PO lines have been removed from Container', Position.TOP_RIGHT);
                dispatch(fetchAssignedPo({ containerDetailsId: location?.state?.containerDetailsId, bpId: id }));
            }
            dispatch(
                fetchContainerDetail({
                    containerDetailsId: location?.state?.containerDetailsId,
                })
            );
            dispatch(fetchAllPo({ bpId: id }));
            dispatch(clearFailure([]));
        }
    }, [isError, containerAllocationSuccess, ContainerAllocationError]);

    const agentIntegrationStatusValidation = (type: string, mode: string) => {
        if (headerData?.agentIntegrationStatus === true) {
            if (type === 'basicDetails') {
                if (
                    (containerData?.containerNumber === '' || containerData?.containerNumber === null) &&
                    containerData?.packing?.length === 0 &&
                    (containerData?.vgm === '' || containerData?.vgm === null) &&
                    (containerData?.sealNumber === '' || containerData?.sealNumber === null)
                ) {
                    return mode === 'add' ? true : false;
                } else if (
                    containerData?.containerNumber === '' ||
                    containerData?.containerNumber === null ||
                    containerData?.packing?.length === 0 ||
                    containerData?.vgm === '' ||
                    containerData?.vgm === null ||
                    containerData?.sealNumber === '' ||
                    containerData?.sealNumber === null
                ) {
                    return mode === 'add' ? false : true;
                } else {
                    return false;
                }
            } else if (type === 'dates') {
                if (
                    containerData?.collectionDate === '' ||
                    containerData?.collectionDate === null ||
                    containerData?.dropOffDate === '' ||
                    containerData?.dropOffDate === null ||
                    containerData?.pickUpDate === '' ||
                    containerData?.pickUpDate === null ||
                    containerData?.gateInDate === '' ||
                    containerData?.gateInDate === null
                ) {
                    return true;
                } else {
                    return false;
                }
            } else {
                if (!assignedPo?.bookingProposalPoList?.length) {
                    return true;
                } else {
                    return false;
                }
            }
        } else {
            return true;
        }
    };

    const containerStatus = containerDetail?.status ? containerDetail?.status : location?.state?.containerStatus;

    const ContainerDetailTab = () => {
        return (
            <div className="main-content-holder">
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Basic Details</h6>
                            {(profileType === 'agent' || profileType === 'forwarder') &&
                                headerData?.bpStatus !== 'Booked' &&
                                containerData.containerNumber &&
                                agentIntegrationStatusValidation('basicDetails', 'edit') && (
                                    <ShowForPermissions permission="edit" type="bp">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Basic Details"
                                            onClick={() => setShowBasicDetails(true)}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    </ShowForPermissions>
                                )}
                        </div>
                        {(profileType === 'agent' || profileType === 'forwarder') &&
                            headerData?.bpStatus !== 'Booked' &&
                            !containerData.containerNumber &&
                            agentIntegrationStatusValidation('basicDetails', 'add') && (
                                <ShowForPermissions permission="edit" type="bp">
                                    <div className="btn-holder">
                                        <button
                                            className="app-btn app-btn-secondary icon-button  add-product-btn"
                                            title="Add Details"
                                            onClick={() => {
                                                setShowBasicDetails(true);
                                                setValidation(false);
                                            }}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Details</span>
                                        </button>
                                    </div>
                                </ShowForPermissions>
                            )}
                    </div>
                    {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No additional details added</p>
                            </div> */}
                    <div className="card-detail-col">
                        {basicData?.map((element: any, index: number) => {
                            return (
                                <div className="details-col box-content-holder" key={`basicData ${index}`}>
                                    <h6 className="details-box-heading">
                                        {element.title}
                                        {element.required && <span className="mandatory-field-text">*</span>}
                                    </h6>
                                    <div className="details-box-content">{element.fields}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>
                            {(profileType === 'agent' || profileType === 'forwarder') &&
                                headerData?.bpStatus !== 'Booked' &&
                                containerData.collectionDate &&
                                agentIntegrationStatusValidation('dates', 'edit') && (
                                    <ShowForPermissions permission="edit" type="bp">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Dates Details"
                                            onClick={() => setShowDatesDetails(true)}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    </ShowForPermissions>
                                )}
                        </div>
                        {(profileType === 'agent' || profileType === 'forwarder') &&
                            headerData?.bpStatus !== 'Booked' &&
                            !containerData.collectionDate &&
                            agentIntegrationStatusValidation('dates', 'add') && (
                                <ShowForPermissions permission="edit" type="bp">
                                    <div className="btn-holder">
                                        <button
                                            className="app-btn app-btn-secondary icon-button  add-product-btn"
                                            title={
                                                !containerData.containerNumber
                                                    ? 'Please complete the basic details'
                                                    : 'Add Details'
                                            }
                                            disabled={!containerData.containerNumber}
                                            onClick={() => setShowDatesDetails(true)}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Dates</span>
                                        </button>
                                    </div>
                                </ShowForPermissions>
                            )}
                    </div>
                    {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No additional details added</p>
                            </div> */}
                    <div className="card-detail-col">
                        {dateData?.map((element, index) => {
                            return (
                                <div className="details-col box-content-holder" key={`dateData ${index}`}>
                                    <h6 className="details-box-heading">
                                        {element.title}
                                        {element.required && <span className="mandatory-field-text">*</span>}
                                    </h6>
                                    <div className="details-box-content">{element.fields}</div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };
    const viewContainerRenderer = () => {
        switch (tab) {
            case 0:
                return <ContainerDetailTab />;
            case 1:
                return (
                    <PoList
                        bpID={id}
                        addPoList={
                            assignedPo?.bookingProposalPoList?.length && assignedPo?.bookingProposalPoList[0].poId
                                ? assignedPo?.bookingProposalPoList
                                : []
                        }
                        originalData={assignedPo.bookingProposalPoList ? assignedPo.bookingProposalPoList : []}
                        selectedItemsAdded={selectedItemsAdded}
                        setSelectedItemsAdded={setSelectedItemsAdded}
                        showConditionStatus={
                            (profileType === 'agent' || profileType === 'forwarder') &&
                            !headerData?.gateInDateExpired &&
                            headerData?.bpStatus !== 'Booked'
                        }
                        containerDetailsId={location?.state?.containerDetailsId}
                        setPoDetails={setPoDetails}
                        listingPage={true}
                        isHeaderOnlyBp={containerData.isHeaderOnlyBp}
                        poDetails={poDetails}
                        pillClicked={pillClicked}
                        setPillClicked={setPillClicked}
                    />
                );
        }
    };
    return (
        <div className="main-wrapper container" style={{ overflow: 'hidden' }}>
            <div className="main-header-content-holder large-header  -container-details">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={`/booking_list/${id}/container-allocation`}
                    thirdName={'Container Allocation'}
                    thirdUrl={`/booking_list/${id}/container-allocation`}
                    fourthName={'Container Details'}
                    fourthUrl={'#'}
                    hideCount={true}
                    backUrl={`/booking_list/${id}/container-allocation`}
                />

                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                    extraData={{
                        ...containerData,
                        bpId: id,
                        containerStatus,
                    }}
                />
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>

            <div className="content-section main-wrapper po-selection-grid">
                {containerData?.containerType &&
                    (containerStatus === 'Pending' ||
                        containerStatus === 'In_Progress' ||
                        assignedPo?.bookingProposalPoList?.length === 0) && (
                        <div className="page-heading__caption" style={{ marginLeft: '0', color: '#fd1010' }}>
                            Fill all mandatory fields and make allocations to the container to mark as complete
                        </div>
                    )}
                <div className="main-wrapper">
                    <div className="tab-with-action">
                        <div className="tab-with-search">
                            <a className={tab === 0 ? 'tab-nav active' : 'tab-nav'} onClick={() => setTab(0)}>
                                Container Details
                            </a>
                            <a className={tab === 1 ? 'tab-nav active' : 'tab-nav'} onClick={() => setTab(1)}>
                                Allocations
                            </a>
                            {tab === 1 && (
                                <div className="tab-with-proposal">
                                    {!location?.state?.containerDetailsId || !containerData.containerNumber ? (
                                        <div className="error-text">
                                            <svg className="svg-icon error-badge-icon">
                                                <use href="#errorCautionIcon"></use>
                                            </svg>
                                            Only containers with container number can be used for allocation
                                        </div>
                                    ) : null}
                                    {selectedItemsAdded.length > 0 && assignedPo?.bookingProposalPoList && (
                                        <div className="tab-with-proposal">
                                            <span className="btn-pills info-pill">
                                                <span className="pill-text">{`${getCount()} Selected`}</span>
                                                <button
                                                    className="pill-action"
                                                    onClick={() => {
                                                        setSelectedItemsAdded([]);
                                                    }}
                                                >
                                                    <svg className="svg-icon close-icon">
                                                        <use xlinkHref="#closeIcon"></use>
                                                    </svg>
                                                </button>
                                            </span>
                                            <button
                                                className="app-btn app-btn-primary footer-btn"
                                                onClick={() => removePoFromContainer()}
                                                // disabled={bpStatus === 'Cancelled'}
                                            >
                                                Remove From Container
                                            </button>
                                        </div>
                                    )}
                                    {!selectedItemsAdded.length &&
                                        (profileType === 'agent' || profileType === 'forwarder') &&
                                        !headerData?.gateInDateExpired &&
                                        headerData?.bpStatus !== 'Booked' && (
                                            <ShowForPermissions permission="edit" type="bp">
                                                <button
                                                    className="app-btn app-btn-secondary icon-button  add-product-btn"
                                                    title="Add PO Lines"
                                                    onClick={() => {
                                                        setPoDetails(true);
                                                    }}
                                                    disabled={
                                                        location?.state?.containerDetailsId &&
                                                        containerData.containerNumber
                                                            ? false
                                                            : true
                                                    }
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#plusIcon"></use>
                                                    </svg>
                                                    <span className="button-text footer-button-text">Add PO Lines</span>
                                                </button>
                                            </ShowForPermissions>
                                        )}
                                </div>
                            )}
                        </div>
                    </div>
                    {viewContainerRenderer()}
                </div>
            </div>
            {/* Removed Sidepanel 31/12 */}
            {/* {showBookingSideBar && <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar} />} */}

            {/* <BasicDetailsModal
                modalView={showBasicDetails}
                setModalView={setShowBasicDetails}
                containerData={containerData}
                bpId={id}
                allocationId={allocationId}
                containerDetailsId={location?.state?.containerDetailsId}
                setCancelModalView={setCancelModalView}
                setValidation={setValidation}
                validation={validation}
            /> */}

            <DatesModal
                modalView={showDatesDetail}
                setModalView={setShowDatesDetails}
                containerData={containerData}
                bpId={id}
                allocationId={allocationId}
                containerDetailsId={location?.state?.containerDetailsId}
                setCancelModalView={setCancelModalView}
            />
            <CancelModal
                displayFunction={() => {
                    showBasicDetails && setShowBasicDetails(false);
                    showDatesDetail && setShowDatesDetails(false);
                }}
                header="Booking Details"
                cancelModalVisibility={cancelModalView}
                setCancelModalVisibility={setCancelModalView}
            />
            {poDetails && (
                <PoDetailsModal
                    isLoading={loader}
                    setLoader={setLoader}
                    handle={setPoDetails}
                    containerDetailsId={location?.state?.containerDetailsId}
                    setPoDetails={setPoDetails}
                    isHeaderOnlyBp={containerData.isHeaderOnlyBp}
                    poDetails={poDetails}
                    pillClicked={pillClicked}
                    setPillClicked={setPillClicked}
                />
            )}
            {/* {triangleModal && <PoTriangleDetailsModal handle={setTriangleModal} />} */}
        </div>
    );
};
export default Index;
