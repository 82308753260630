import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { availableAoHeaders, clearFilterText } from '../../../utils/constants';
import {
    addOrSavePoData,
    fetchAvailablePoData,
    resetFlags,
    setSelectedItems,
    setDisabledFilterData,
    setFilterDispatch,
    searchAvailablePoData,
} from '../../../store/reducers/aobookingProposal/availablePoReducer';
import { fetchPermissions } from '../../../store/reducers/permissionReducer';
import { error, Position, success } from '../../../utils/toast';
import '../../BookingProposal/BookingAvailableGrid/BookingAvailableGrid.scss';
import SelectableGrid from '../../../components/common/selectableGrid/index';
import PoReview from '../BookingAddedGrid/PurchaseOrderDetails';
import BookingAddedGrid from '../BookingAddedGrid';
import { useNavigate, useParams } from 'react-router-dom';
// import CancelModal from '../../../components/common/CancelModal';
import {
    fetchAddedPoData,
    removeMessage,
    removePo,
    searchData,
} from '../../../store/reducers/aobookingProposal/addedPoReducer';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from '../../../hooks/useUserProfile';
import UnavailableModel from '../SelectedSailingSchelude/UnavailableModel';
import ShowForPermissions from '../../../ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { getOrganisationData } from '../../../utils';
// import { updateViewStatus } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
// import ReapprovalBpClientModal from '../BookingAdditionalInfo/ReapprovalBpClientModal';
/* eslint-disable no-undefined */

interface HeaderProps {
    setisAdded?: any;
    showBookingCancelModal: boolean;
    setShowBookingCancelModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
const Index: React.FC<HeaderProps> = ({ setisAdded, showBookingCancelModal, setShowBookingCancelModal }) => {
    const { profileType } = useUserProfile();
    // const [reapproveBpModalView, setReapproveBpModalView] = useState(false);
    const dispatch = useDispatch();
    const {
        availablePoList,
        filterValues,
        columnStatus,
        isLoading,
        bpId,
        success: addSuccess,
        error: addError,
        selectedItems,
        disabledFilterData,
        filter,
    } = useSelector((state: RootState) => state.aoavailablePos);
    const { addPoList, removeStat, removeMessageval, gateInDate } = useSelector((state: RootState) => state.aoaddedPos);
    const { bpNumber, clientName, destinationPort, blpName, vendorName, isHeaderOnlyPo } = useSelector(
        (state: RootState) => state.bpHeaderData
    );
    const [showConditionStatus, setShowConditionStatus] = useState(true);
    const { id } = useParams();
    const [selectedHeader, setSelectedHeader] = useState(availableAoHeaders.poNumber);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentOrder, setcurrentOrder] = useState(false);
    // const [filter, setFilter] = useState<any>({});
    const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data1?.user?.us_UserName;
    const firstUpdate = useRef(true);
    const firstSortUpdate = useRef(true);
    const [selectedItemsAdded, setSelectedItemsAdded] = useState<any[]>([]);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    // const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
    const [addedgrid, setaddedgrid] = useState(false);
    const [searchAddedTerm, setsearchAddedTerm] = useState('');
    // const firstFilterUpdate = useRef(true);
    const [gateModal, setgateModal] = useState(false);
    const navigate = useNavigate();
    const { bpStatus, tabValues } = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const poTabStatus: any = tabValues;
    const organisationData = getOrganisationData();
    const payload = {
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter },
        id: id,
        profileType,
    };
    useEffect(() => {
        if (id) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, []);

    useEffect(() => {
        if (gateInDate) {
            setgateModal(true);
        } else {
            setgateModal(false);
        }
    }, [gateInDate]);

    const setFilter = (payload: any) => {
        dispatch(setFilterDispatch(payload));
    };
    const resetFilters = () => {
        setSelectedItemsDispatch([]);
        setSearchTerm('');
    };
    const setSelectedItemsDispatch = (selectedList: any[]) => {
        if (selectedList) {
            dispatch(setSelectedItems(selectedList));
        }
    };

    const setDisabledFilterDataDispatch = (filterValues: any) => {
        if (filterValues) {
            dispatch(setDisabledFilterData(filterValues));
        }
    };
    //useEffect(() => {
    //    localStorage.setItem('bpclientCode', (addPoList[0] as any)?.purchaseOrderId.clientCode);
    //    localStorage.setItem('bpid', id as any);
    //    if (addPoList.length !== 0) {
    //        localStorage.setItem('bpdisabled', 'false');
    //    } else {
    //        localStorage.setItem('bpdisabled', 'true');
    //    }
    //}, [addPoList, id]);

    const showCondition =
        (bpStatus === 'Rejected_by_Forwarder' ||
            bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
            bpStatus === 'Draft_Booking_Proposal' ||
            !bpStatus) &&
        (profileType === 'agent' || profileType === 'forwarder');
    // (profileType === 'forwarder' && bpStatus === 'Rejected_by_Client_Forwarder_to_Action');
    useEffect(() => {
        if (bpStatus) {
            if (showCondition === false) {
                setaddedgrid(true);
                setShowConditionStatus(false);
            }
        } else {
            setShowConditionStatus(true);
        }
    }, [bpStatus]);

    useEffect(() => {
        if (id) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [id, addedgrid]);

    useEffect(() => {
        clearSearch();
    }, [addedgrid]);

    useEffect(() => {
        if (bpNumber && id) {
            setDisabledFilterDataDispatch({
                clientName: clientName ? [clientName] : null,
                destinationPortName: destinationPort ? [destinationPort] : null,
                blpName: blpName ? [blpName] : null,
                vendorName: vendorName ? [vendorName] : null,
                isHeaderOnlyPo: isHeaderOnlyPo || isHeaderOnlyPo === false ? isHeaderOnlyPo : null,
            });
            const filterObj = {
                clientName: clientName ? [clientName] : null,
                destinationPortName: destinationPort ? [destinationPort] : null,
                blpName: blpName ? [blpName] : null,
                vendorName: vendorName ? [vendorName] : null,
            };
            setFilter({ ...filter, ...filterObj });
        }
    }, [bpNumber]);

    useEffect(() => {
        if (addSuccess) {
            const po = selectedItems.length > 1 ? 'AOs have' : 'AO has';
            let successMessage = `${po} been added to Booking Proposal.`;
            if (!id) {
                successMessage = successMessage + ' List of Available AOs have been filtered based on header details';
            }
            success(successMessage, Position.TOP_RIGHT);
            dispatch(resetFlags({}));
            setSelectedItemsDispatch([]);
            dispatch(fetchAddedPoData(bpId));
            dispatch(fetchBpHeaderData({ bpId, profileType }));
            setFilter({
                clientName: [disabledFilterData.clientName],
                blpName: [disabledFilterData.blpName],
                destinationPort: [disabledFilterData?.destinationPortName && disabledFilterData.destinationPortName[0]],
            });
            if (!id) {
                navigate(`/Aobooking_list/${bpId}/AoSelection`);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (addError) {
            error('Failed to create booking proposal', Position.TOP_RIGHT);
            dispatch(resetFlags({}));
        }
    }, [addError]);

    useEffect(() => {
        if (addPoList?.length > 0) {
            setisAdded(true);
            localStorage.setItem('isSelected', 'false');
        } else {
            setisAdded(false);
            localStorage.setItem('isSelected', 'true');
        }
    }, [addPoList]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (searchTerm?.trim()?.length > 2) {
            dispatch(searchAvailablePoData({ ...payload, keyword: searchTerm }));
        } else if (searchTerm?.trim()?.length === 0) {
            dispatch(fetchAvailablePoData(payload));
        }
    }, [searchTerm]);
    // useLayoutEffect(() => {
    //     if (firstFilterUpdate.current) {
    //         firstFilterUpdate.current = false;
    //         return;
    //     }
    //     dispatch(fetchAvailablePoData(payload));
    // }, [filter, addedgrid]);

    useLayoutEffect(() => {
        if (firstSortUpdate.current) {
            firstSortUpdate.current = false;
            if (!id) {
                dispatch(fetchAvailablePoData(payload));
                dispatch(fetchPermissions(username));
            }
            return;
        }
        dispatch(fetchAvailablePoData(payload));
        dispatch(fetchPermissions(username));
        // eslint-disable-next-line
    }, [setSelectedHeader, currentOrder, filter, addedgrid]);

    const onSort = (sortKey: string) => {
        setSelectedHeader(sortKey);
        setcurrentOrder(!currentOrder);
    };

    // const dismissModal = () => {
    //     setCancelModalVisibility(false);
    //     navigate('/booking_list');
    // };

    const addToBookingProposal = () => {
        const payload = {
            poDetails: selectedItems.map((x: any) => {
                return {
                    poId: x.poId,
                    poProductId: x.poProductId,
                };
            }),
            clientCode: disabledFilterData.clientName,
        } as any;
        if (id) {
            payload.bpId = id;
        }
        dispatch(addOrSavePoData(payload));
    };
    const removeFromBookingPo = () => {
        let payload = {
            bpId: id,
            purchaseOrderIds: selectedItemsAdded,
        };
        selectedItemsAdded.forEach((element1: any) => {
            addPoList.forEach((element: any) => {
                element?.bookingProposalSubPOLineList?.forEach((element3: any) => {
                    if (
                        Array.isArray(element1.subPolineIds) &&
                        Array.isArray(element3.bookingProposalSubPOLineList) &&
                        element1.subPolineIds.length === element3.bookingProposalSubPOLineList.length
                    ) {
                        payload = {
                            bpId: id,
                            purchaseOrderIds: selectedItemsAdded,
                        };
                        element1['isFullPo'] = true;
                    }
                });
            });
        });
        dispatch(removePo(payload));
        setSelectedItemsAdded([]);
    };
    useEffect(() => {
        if (removeStat) {
            success(`${removeMessageval}`, Position.TOP_RIGHT);
            dispatch(removeMessage({ status: false, message: '' }));
            if (id) {
                setTimeout(() => {
                    dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                }, 1000);
            }
            dispatch(fetchAvailablePoData(payload));
            if (!addPoList?.length) {
                setDisabledFilterDataDispatch({});
            }
        }
    }, [removeStat, id]);

    const getCount = () => {
        let total = 0;
        selectedItemsAdded.forEach((element: any) => {
            element.subPolineIds.forEach((_element2: any) => {
                total++;
            });
        });
        return total;
    };

    useEffect(() => {
        if (searchAddedTerm.length > 2) {
            dispatch(
                searchData({
                    val: searchAddedTerm,
                    id: id,
                })
            );
        } else if (searchAddedTerm.length === 0) {
            const timer = setTimeout(() => {
                dispatch(fetchAddedPoData(id));
            }, 250);
            return () => clearTimeout(timer);
        }
    }, [searchAddedTerm]);

    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let isAgent = false;
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     if (roleIndex) {
    //         isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
    //     }
    // }

    const setSearch = (value: string) => {
        if (addedgrid) {
            setsearchAddedTerm(value);
        } else {
            setSearchTerm(value);
        }
    };

    const clearSearch = () => {
        if (addedgrid) {
            setsearchAddedTerm('');
        } else {
            setSearchTerm('');
        }
    };

    return (
        <>
            <div className="content-section main-wrapper po-selection-grid grid-p-0">
                <div className="main-wrapper">
                    <div className="tab-with-action grid-ml-20">
                        {showConditionStatus ? (
                            <div className="tab-with-search">
                                <a
                                    className={!addedgrid ? 'tab-nav active' : 'tab-nav'}
                                    onClick={() => setaddedgrid(false)}
                                >
                                    Available
                                </a>
                                <a
                                    className={addedgrid ? 'tab-nav active' : 'tab-nav'}
                                    onClick={() => setaddedgrid(true)}
                                >
                                    Added
                                </a>
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={addedgrid ? searchAddedTerm : searchTerm}
                                        className="search-input input-text"
                                    ></input>
                                    <div
                                        className={`search-toolip ${
                                            (searchTerm?.length > 0 && searchTerm?.length < 3) ||
                                            (searchAddedTerm?.length > 0 && searchAddedTerm?.length < 3)
                                                ? 'tooltip-show'
                                                : ''
                                        }`}
                                    >
                                        Enter atleast 3 characters to search
                                    </div>
                                    <svg className="svg-icon search-icon">
                                        <use xlinkHref="#searchIcon"></use>
                                    </svg>
                                    <button className="app-btn text-close-btn" onClick={() => clearSearch()}>
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                                {!addedgrid && (
                                    <a
                                        href="javascript:void(0)"
                                        className="clear-filter-link"
                                        onClick={() => {
                                            if (!addPoList?.length) {
                                                setDisabledFilterDataDispatch({});
                                            }
                                            resetFilters();
                                            setFilter({});
                                        }}
                                    >
                                        {clearFilterText}
                                    </a>
                                )}
                            </div>
                        ) : null}
                        <ShowForPermissions type="bp" permission="create">
                            {selectedItems.length && !addedgrid ? (
                                <div className="tab-with-proposal">
                                    <span className="btn-pills info-pill">
                                        <span className="pill-text">{`${selectedItems.length} Selected`}</span>
                                        <button
                                            className="pill-action"
                                            onClick={() => {
                                                setSelectedItemsDispatch([]);
                                                setDisabledFilterDataDispatch({});
                                            }}
                                        >
                                            <svg className="svg-icon close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </span>
                                    <button
                                        className="app-btn app-btn-primary footer-btn"
                                        onClick={() => addToBookingProposal()}
                                    >
                                        Add to Booking Proposal
                                    </button>
                                </div>
                            ) : null}
                        </ShowForPermissions>
                        <ShowForPermissions type="bp" permission="create">
                            {selectedItemsAdded.length > 0 && addedgrid && (
                                <div className="tab-with-proposal">
                                    <span className="btn-pills info-pill">
                                        <span className="pill-text">{`${getCount()} Selected`}</span>
                                        <button
                                            className="pill-action"
                                            onClick={() => {
                                                setSelectedItemsAdded([]);
                                            }}
                                        >
                                            <svg className="svg-icon close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </span>
                                    <button
                                        className="app-btn app-btn-primary footer-btn"
                                        onClick={() => removeFromBookingPo()}
                                        disabled={bpStatus === 'Cancelled'}
                                    >
                                        Remove From Booking Proposal
                                    </button>
                                </div>
                            )}
                        </ShowForPermissions>
                    </div>
                    <div className="main-content-holder">
                        <div className="grid-section grid-available-booking">
                            <div className={`app-loader ${isLoading || isLoadingHeader ? 'loading' : ''}`}>
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                            {!addedgrid ? (
                                <SelectableGrid
                                    tabName="availableAos"
                                    data={availablePoList ? availablePoList : []}
                                    onSort={onSort}
                                    currentOrder={currentOrder}
                                    columnStatus={columnStatus}
                                    filter={filter}
                                    filterDataLists={filterValues}
                                    setFilter={setFilter}
                                    disabledFilterData={disabledFilterData}
                                    setDisabledFilterData={setDisabledFilterDataDispatch}
                                    selectedItems={selectedItems}
                                    setSelectedItems={setSelectedItemsDispatch}
                                    displayModalFunc={setpoDisplay}
                                    addPoList={addPoList}
                                    setaddedgrid={setaddedgrid}
                                />
                            ) : (
                                <BookingAddedGrid
                                    bpID={id}
                                    addPoList={id ? addPoList : []}
                                    setaddedgrid={setaddedgrid}
                                    selectedItemsAdded={selectedItemsAdded}
                                    setSelectedItemsAdded={setSelectedItemsAdded}
                                    showConditionStatus={showConditionStatus}
                                    searchAddedTerm={searchAddedTerm}
                                />
                            )}
                            {/* <BookingHeaderSidebar /> */}
                        </div>
                        {/* <BookingAddedGrid></BookingAddedGrid> */}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                {id && (
                    <div className="footer-button-holder ">
                        {/* Draft Booking Proposal */}
                        {/* {profileType === 'agent' &&
                            (bpStatus === 'Draft_Booking_Proposal' ||
                                bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                // <>
                                //     {bpStatus === 'Booked' || bpStatus === 'Cancelled' ? null : (
                                <ShowForPermissions type="bp" permission="create">
                                    <button
                                        title="Cancel Booking Proposal"
                                        className="app-btn app-btn-secondary footer-btn"
                                        onClick={() => {
                                            setShowBookingCancelModal(true);
                                        }}
                                        disabled={selectedItemsAdded?.length !== 0 ? true : false}
                                    >
                                        <span className="button-text footer-button-text">Cancel Booking Proposal</span>
                                    </button>
                                </ShowForPermissions>
                                //     )}
                                // </>
                            )} */}
                        {/* {profileType === 'agent' &&
                            (bpStatus === 'Booked' ||
                                bpStatus === 'Ready_for_Booking' ||
                                bpStatus === 'Draft_Booking') && (
                                <ShowForPermissions type="bp" permission="create">
                                    <button
                                        title="Cancel Booking"
                                        className="app-btn app-btn-secondary footer-btn"
                                        onClick={() => {
                                            setShowBookingCancelModal(true);
                                        }}
                                    >
                                        <span className="button-text footer-button-text">Cancel Booking</span>
                                    </button>
                                </ShowForPermissions>
                            )} */}
                        {/* {bpStatus === 'Draft_Booking_Proposal' && isAgent ? (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Save as Draft"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        if (selectedItemsAdded?.length) {
                                            setCancelModalVisibility(true);
                                        } else {
                                            dismissModal();
                                        }
                                    }}
                                    disabled={selectedItemsAdded?.length !== 0 ? true : false}
                                >
                                    <span className="button-text footer-button-text">Save as Draft</span>
                                </button>
                            </ShowForPermissions>
                        ) : null} */}
                        <button
                            title="Next"
                            className={`app-btn app-btn-primary footer-btn`}
                            disabled={poTabStatus?.po ? false : true}
                            // className={`app-btn app-btn-primary footer-btn ${
                            //     (addPoList?.length === 0 || selectedItemsAdded?.length !== 0) &&
                            //     searchAddedTerm?.length === 0
                            //         ? 'disabled'
                            //         : ''
                            // }`}
                            onClick={() => {
                                navigate(`/Aobooking_list/${id}/SailingSelectionDetails`);
                            }}
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </button>
                        {/* </button> */}
                    </div>
                )}
            </div>
            {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}
            {/* <CancelModal
                displayFunction={dismissModal}
                cancelModalVisibility={cancelModalVisibility}
                setCancelModalVisibility={setCancelModalVisibility}
            /> */}
            {showBookingCancelModal ? (
                bpStatus !== undefined &&
                (bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            {/* <ReapprovalBpClientModal
                modalView={reapproveBpModalView}
                setModalView={setReapproveBpModalView}
                reason={reason != null ? reason : ''}
                bpId={id}
                profileType={profileType}
            /> */}
            {bpStatus &&
                profileType === 'agent' &&
                bpStatus !== 'Ready_for_Booking' &&
                bpStatus !== 'Draft_Booking' &&
                bpStatus !== 'Cancelled' &&
                bpStatus !== 'Rejected_by_Forwarder' &&
                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                bpStatus !== 'Booked' &&
                bpStatus !== 'Awaiting_Client_Approval' &&
                bpStatus !== 'Awaiting_Forwarder_Approval' &&
                gateModal &&
                (addPoList[0] as any)?.purchaseOrderId?.clientCode !== undefined && (
                    <UnavailableModel setdisplayModal={setgateModal} />
                )}
        </>
    );
};
export default Index;
