import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';

import {
    favourite,
    favouriteFailure,
    favouriteSuccess,
    fetchInsightsTilesList,
    fetchInsightsTilesListFailure,
    fetchInsightsTilesListSuccess,
    fetchInsightsList,
    fetchInsightsListFailure,
    fetchInsightsListSuccess,
    fetchFavouriteList,
    fetchFavouriteListFailure,
    fetchFavouriteListSuccess,
    fetchTrendGraph,
    fetchTrendGraphSuccess,
    fetchTrendGraphFailure,
    fetchTrendMultiGraph,
    fetchTrendMultiGraphSuccess,
    fetchTrendMultiGraphFailure,
    initializeInsights,
} from '../../reducers/tracking/trackingInsightsReducer';
import {
    fetchTrendGraphApi,
    fetchTrendMultiGraphApi,
    fetchFavouriteApi,
    fetchInsightsTilesListApi,
    fetchInsightsListApi,
    updateFavouriteApi,
} from '../../../services/apis/tracking/trackingInsightsDataApi';
import { trackingInsightsList } from 'src/utils/kendoGrid/insights';

function* getInsightsTilesList({ payload }: { payload: any; type: any }) {
    try {
        if (!payload?.tileName) {
            const responseFav: AxiosResponse = yield call(fetchFavouriteApi, payload);
            yield put({ type: fetchFavouriteListSuccess, payload: responseFav });
        }
        const tileName = payload?.tileName ? trackingInsightsList.find((res) => res.id === payload.tileName)?.code : '';
        const response: AxiosResponse = yield call(fetchInsightsTilesListApi, payload);
        yield put({ type: fetchInsightsTilesListSuccess, payload: { ...response, tileName } });
    } catch (error) {
        yield put({ type: fetchInsightsTilesListFailure, payload: error });
    }
}

function* getFavouriteList({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchFavouriteApi, payload);
        yield put({ type: fetchFavouriteListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchFavouriteListFailure, payload: error });
    }
}
function* getTrendGraph({ payload }: { payload: any }) {
    try {
        const response: AxiosResponse = yield call(fetchTrendGraphApi, payload);
        yield put({ type: fetchTrendGraphSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchTrendGraphFailure, payload: error });
    }
}
function* getTrendMultiGraph({ payload }: { payload: any }) {
    try {
        const response: AxiosResponse = yield call(fetchTrendMultiGraphApi, payload);
        yield put({ type: fetchTrendMultiGraphSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchTrendMultiGraphFailure, payload: error });
    }
}
function* getInsightsList({ payload }: { payload: any; type: any }) {
    try {
        const tiles = trackingInsightsList.filter((res) => payload?.insightsTiles.includes(res.code));
        yield put({ type: initializeInsights, payload: tiles });
        for (let index = 0; index < payload?.insightsTiles.length; index++) {
            const insightItem = trackingInsightsList?.find((res) => res.code === payload?.insightsTiles[index]);
            if (insightItem) {
                const response: AxiosResponse = yield call(fetchInsightsListApi, {
                    ...insightItem,
                    profile: payload.profile,
                });
                yield put({
                    type: fetchInsightsListSuccess,
                    payload: response,
                });
            }
        }
    } catch (error) {
        yield put({ type: fetchInsightsListFailure, payload: error });
    }
}

function* updateFavourite({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(updateFavouriteApi, payload);
        yield put({ type: favouriteSuccess, payload: response });
    } catch (error) {
        yield put({ type: favouriteFailure, payload: error });
    }
}

function* trackingInsightsDataSage() {
    yield takeEvery(fetchInsightsTilesList, getInsightsTilesList);
    yield takeEvery(fetchInsightsList, getInsightsList);
    yield takeEvery(fetchFavouriteList, getFavouriteList);
    yield takeEvery(fetchTrendGraph, getTrendGraph);
    yield takeEvery(fetchTrendMultiGraph, getTrendMultiGraph);
    yield takeEvery(favourite, updateFavourite);
}

export default trackingInsightsDataSage;
