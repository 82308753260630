/* eslint-disable no-undefined */
import React, { useState } from 'react';

import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useUserProfile } from 'src/hooks/useUserProfile';
import NoAccess from 'src/components/NoAccess';

const Index: React.FC = () => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const isExecutive = userData.roles?.find((x: any) => x.roleName?.toLowerCase() === 'executive');
    const { profileType, userType, isBlp, userEmail } = useUserProfile();

    const [companyType, setCompanyType] = useState('SC_Company');
    const [teuType, setTeuType] = useState('BP_AllocatedTEU');
    const [dateType, setDateType] = useState('STD_WK');

    const companyTypeValueArray = [
        { value: 'SC_Company', label: 'Forwarder (IFC)' },
        { value: 'BP_ClientCode', label: 'Clients' },
        { value: 'BP_AgentCode', label: 'Agents' },
    ];

    const teuTypeValueArray = [
        { value: 'BP_TotTEU', label: 'Estimated' },
        { value: 'BP_AllocatedTEU', label: 'Actual' },
    ];

    const dateTypeValueArray = [
        { value: 'STD_WK', label: 'Booking Scheduled Departure' },
        { value: 'STA_WK', label: 'Booking Scheduled Arrival' },
        { value: 'ATD_WK', label: 'Booking Actual Departure' },
        { value: 'ATA_WK', label: 'Booking Actual Arrival' },
    ];

    return isExecutive && profileType === 'forwarder' ? (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h1 className="page-heading">
                    {teuTypeValueArray.find((obj) => obj.value === teuType)?.label} NAC Allocation for{' '}
                    {companyTypeValueArray.find((obj) => obj.value === companyType)?.label} by{' '}
                    {dateTypeValueArray.find((obj) => obj.value === dateType)?.label}
                </h1>

                {/* Kendo Grid */}
                <div className="grid-section" style={{ marginTop: 20 }}>
                    <div className="layout-holder three-column">
                        {/* TEU Type Dropdown */}
                        <div className="layout-item">
                            <div className=" modal-input-holder layout-item" style={{ marginRight: 20 }}>
                                <label className="modal-input-label">
                                    TEU Type<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={teuTypeValueArray}
                                    placeholder="By Estimated TEU"
                                    setStateValue={setTeuType}
                                    stateValue={teuType}
                                    fieldName="carrier"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                    isClearable={false}
                                />
                            </div>
                        </div>

                        {/* Company Type Dropdown */}
                        <div className="layout-item" style={{ marginRight: 20 }}>
                            <div className=" modal-input-holder layout-item">
                                <label className="modal-input-label">
                                    Company Type<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={companyTypeValueArray}
                                    placeholder="By Forwarder"
                                    setStateValue={setCompanyType}
                                    stateValue={companyType}
                                    fieldName="carrier"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                    isClearable={false}
                                />
                            </div>
                        </div>

                        {/* Date Types Dropdown */}
                        <div className="layout-item">
                            <div className=" modal-input-holder layout-item">
                                <label className="modal-input-label">
                                    Date Type<span className="mandatory-field-text">*</span>
                                </label>
                                <SearchableSelectBox
                                    data={dateTypeValueArray}
                                    placeholder="By Scheduled Departure"
                                    setStateValue={setDateType}
                                    stateValue={dateType}
                                    fieldName="carrier"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                    isClearable={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="grid-holder">
                        <LayoutWithColumns
                            key={`${companyType}/${teuType}/${dateType}`}
                            style={'booking'}
                            content={'allocated-teu'}
                            customProp={{ keyword: `trk/nac/${companyType}/${teuType}/${dateType}` }}
                            showLoader={true}
                        />
                    </div>
                </div>
            </div>
        </div>
    ) : (
        <NoAccess />
    );
};

export default Index;

// import React, { useEffect, useState } from 'react';
// import './test.css';
// import { setGroupIds, setSelectedState } from '@progress/kendo-react-data-tools';
// import { Grid, GridColumn as Column, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
// import { DataResult, State } from "@progress/kendo-data-query";
// import {
//     groupBy,
//     GroupDescriptor,
//   } from "@progress/kendo-data-query";

// const Index: React.FC = () => {
//     function toCIXQuery(state: any) {
//         let cixQuery = ""
//         if (state.take) cixQuery += `&take=${state.take}`
//         if (state.skip) cixQuery += `&skip=${state.skip}`
//         if (state.filter) cixQuery += `&filter=${encodeURIComponent(JSON.stringify(state.filter))}`
//         if (state.sort) cixQuery += `&sort=${encodeURIComponent(JSON.stringify(state.sort))}`
//         if (state.group) cixQuery += `&group=${encodeURIComponent(JSON.stringify(state.group))}`
//        // console.log(cixQuery)
//         return cixQuery
//     }

//     function getData(state: any) {
//         return fetch(
//             `http://localhost:3001/cix/scr/admin/config/country/lst/CN_CountryCode/CN_CountryName?a=b${toCIXQuery(state)}`,
//             {
//                 headers: {
//                     accept: "application/json",
//                     "x-api-key": "xYp8Q~gQvDi1bgxkRYLdt.9TrB0pVIBXEhs.maKH"
//                 }
//             }
//         )
//             .then((resp) => resp.json())
//             .then((result) => {
//                 //console.log(dataState.group)
//                 const data = processWithGroups(result.data as any, dataState.group as any)
//                 setCixData({data: data, total:result.total})
//             })
//             .catch((err) => console.log(err));
//     }

//     const [cixData, setCixData] = React.useState<DataResult>({
//         data: [],
//         total: 0,
//     });
//     const [dataState, setDataState] = React.useState<State>({
//         filter: undefined,
//         group: [] ,
//         sort: [],
//         take: 10,
//         skip: 0,
//     });

//     const processWithGroups = (data: any[], group: GroupDescriptor[]) => {
//         const newDataState = groupBy(data, group);
//         setGroupIds({ data: newDataState, group: group });
//         return newDataState;
//       };

//     const dataStateChange = (e: GridDataStateChangeEvent) => {
//         setDataState(e.dataState)
//     };

//     useEffect(() => {
//         getData(dataState)
//     }, [dataState]);

//     useEffect(() => {
//         getData(dataState)
//     }, []);

//     return (
//         <div>
//             <div>
//                 <Grid
//                     filterable={true}
//                     sortable={true}
//                     pageable={true}
//                     groupable={true}
//                     {...dataState}
//                     sort={dataState.sort}
//                     data={cixData}
//                     group={dataState.group}
//                     onDataStateChange={dataStateChange}
//                     expandField="expanded"
//                     >
//                     <Column field="CN_PK" filter="text" title="Id" />
//                     <Column field="CN_CountryCode" filter="text" title="CN_CountryCode" />
//                     <Column field="CN_CountryName" filter="text" title="CN_CountryName" />
//                 </Grid>
//             </div>
//         </div >
//     );
// };

// export default Index;
