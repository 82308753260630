import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get } from '../HttpMethods';
import axios from 'axios';

export interface clientBasicDetails {
    US_Email: string | null;
    US_UserName: string | null;
    US_FirstName: string | null;
    US_LastName: string | null;
    OR_Company: string | null;
    OR_OrgCode: string | null;
    OR_OrgName: string | null;
    OR_OrgType: string | null;
    OR_IsColoader: boolean;
    RL_RoleName?: string | null;
    US_Jobtitle?: string | null;
    OR_IsAgent: boolean;
    OR_IsClient: boolean;
    OR_IsShippingLine: boolean;
    OR_IsVendor: boolean;
    PR_ProfileCode?: string | null;
    PR_ProfileName?: string | null;
    US_Status?: string | null;
    OR_OrgCodes?: string | null;
    RL_PK?: string | null;
    US_PhoneNo?: string | null;
}

export const getClientDetails = async (payload: { clt_pk: string }) => {
    try {
        const data = await get(
            `/cix/scr/admin/userProfile/CLT?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=US_PK&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=${payload.clt_pk}`,
            null,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
