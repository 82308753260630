import React from 'react';
// import './style.css';
import '../../assets/scss/components/_breadcrumbs.scss';
import { Link, useNavigate } from 'react-router-dom';
interface BioProps {
    second: string;
    third: string;
    fourth: string;
}
const Index: React.FC<BioProps> = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="breadcrumbs-holder">
                <ul className="breadcrumbs">
                    <li className="breadcrumb-list-item">
                        <Link to="/profile" className="breadcrumb-item">
                            Organisations & Users
                        </Link>
                    </li>
                    <li className="breadcrumb-list-item">
                        {props.second === 'Users' ? (
                            <Link to="/users/forwarder" className="breadcrumb-item">
                                {props.second}
                            </Link>
                        ) : props.second.includes('roles') ? (
                            <Link to="/profile" className="breadcrumb-item">
                                {props.second}
                            </Link>
                        ) : (
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={() => navigate(`/rolespermissions/${props.third.toLowerCase()}`)}
                                className="breadcrumb-item"
                            >
                                {props.second}
                            </a>
                        )}
                    </li>
                    <li className="breadcrumb-list-item">
                        <a href="#" className="breadcrumb-item disabled">
                            {props.fourth}
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Index;
