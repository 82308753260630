import axios from 'axios';
import fileDownload from 'js-file-download';
import { get, post, put } from '../../../HttpMethods';
import { fileUploadForDocuments } from '../../bookingProposal/additionalDetailsApi';

export const fetchContractDetailsListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusFilterColumn: string;
    columnOrder: boolean;
    id: string;
}) => {
    try {
        const data = await get(
            `api/mgt/shippinglines/sc/list?shippingLineId=${payload.id}&limit=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus || ''}&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterby=${payload.statusFilter}`
                    : ''
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterByColumn=${payload.statusFilterColumn}`
                    : ''
            }`,
            null
        );

        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchContractDetailsColumnListData = async (payload: { columnName: string; id: string }) => {
    try {
        const data = await get(
            `api/mgt/shippinglinecontract/sc/distinct?columnName=${payload.columnName}&shippingLineId=${payload.id}`,
            null
        );
        return { ...data, columnName: payload.columnName };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createContractDetailsApi = async (payload: any) => {
    try {
        const data = await post('api/mgt/shippinglinecontract/add', payload, null);
        const file = payload?.get('file');
        if (file) {
            const document = payload?.get('document');
            const text = await document.text();
            const or_PK = JSON.parse(text)?.or_PK;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', or_PK);
            formData.append('DO_FKID', 'OR');
            formData.append('DO_CD_DocumentType', 'HCN');
            await fileUploadForDocuments(formData);
        }
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateContractDetailsApi = async (payload: any) => {
    try {
        const data = await put(`api/mgt/shippinglinecontract/edit/${payload.ad_PK}`, payload);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchContractDetailsAuthorApi = async () => {
    try {
        const data = await get('api/mgt/ad/forwardersignatory', null);
        const result = data.map((res: any) => ({ value: res.name?.trim(), label: res.name?.trim() }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchContractDetailsAgentUsersApi = async () => {
    try {
        const data = await get('api/mgt/shippinglinecontract/agentusers', null);
        const result = data.map((res: any) => ({ value: res.name?.trim(), label: res.name?.trim() }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchContractDetailsPortApi = async (payload: any) => {
    try {
        const data = await get(
            `api/mgt/shippinglinecontract/portlist${payload?.portType ? `?portType=${payload?.portType}` : ''}`,
            null
        );
        const result = data?.info.map((res: any) => ({
            value: res.sp_PK,
            label: `${res.sp_portname}(${res.sp_portId})`,
        }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchContractDetailsIncoTermsApi = async () => {
    try {
        const data = await get('api/bkg/quotation/incoterms', null);
        const result = data?.info.map((res: any) => ({ value: res.incoTermId, label: res.incoTermName }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchAllClientsDetailsApi = async () => {
    try {
        const data = await get('api/mgt/shippinglinecontract/clientlist', null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const searchContractDetailsApi = async (payload: {
    keyword: string;
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    statusFilterColumn: string;
    columnOrder: boolean;
    id: string;
}) => {
    try {
        const data = await get(
            `api/mgt/search/shippingcontract?shippingLineId=${payload.id}&keyword=${payload.keyword}&limit=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortby=${payload.columnStatus || ''}&sortorder=${
                payload.columnOrder ? 'ASC' : 'DESC'
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterby=${payload.statusFilter}`
                    : ''
            }${
                payload.statusFilter &&
                payload.statusFilterColumn &&
                payload.statusFilter != '' &&
                payload.statusFilterColumn != ''
                    ? `&filterByColumn=${payload.statusFilterColumn}`
                    : ''
            }`,
            null
        );
        return { data };
    } catch (err) {
        return console.error(err);
    }
};

export const downloadShippingLineContract = async (ad_pk: string, fileName: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/shippinglinecontract/sc/download/${ad_pk}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
