import axios from 'axios';
import { get, post, del } from '../../HttpMethods';

export const getFavourites = async (payload: { profileType: string; userName: string }) => {
    try {
        const res = await get(
            `/api/mgt/tracking/lifecycle?userName=${payload.userName}&profile=${payload.profileType}`,
            null
        );
        return res;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            throw error;
        }
    }
};

export const addToFav = async (payload: any) => {
    try {
        const data = await post('/api/mgt/tracking/lifecycle', payload, null);
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            throw error;
        }
    }
};

export const removeFromFavApi = async (payload: { id: string }) => {
    try {
        const res = await del(`/api/mgt/tracking/lifecycle/${payload.id}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
