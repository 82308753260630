import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { getForwarderDetails } from 'src/services/apis/forwarderApi';
import { fetchForwarderDetails } from '../reducers/forwarderReducer';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* fetchForwarderInfo({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getForwarderDetails, payload);
        payload['forwarderDetails'] = response?.data[0];
        yield put({ type: fetchForwarderDetails, payload: payload });
    } finally {
    }
}

function* forwarderListSaga() {
    yield takeEvery('forwarderDetails/fetchForwarderDetails', fetchForwarderInfo);
}
// Export the saga (data-saga)
export default forwarderListSaga;
