import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'inventoryLog',
    initialState: {
        enqLog: {},
    },
    reducers: {
        fetchEnquiryLog: (_state, _action) => {
            // console.log(action);
        },
        saveEnquiryLog: (state, action) => {
            state.enqLog = action.payload;
        },
        raiseAnEnquiry: (_state, _action) => {
            // console.log(action);
        },
        enquiryAddComment: (_state, _action) => {
            // console.log(action);
        },
        forwardingToClient: (_state, _action) => {
            // console.log(action);
        },
        viewedLog: (_state, _action) => {
            // console.log(action);
        },
    },
});

export const { saveEnquiryLog, fetchEnquiryLog, raiseAnEnquiry, enquiryAddComment, forwardingToClient, viewedLog } =
    dataSlice.actions;
export default dataSlice.reducer;
