import { createSlice } from '@reduxjs/toolkit';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { awaitingEnrichment, allPos, activeStatus, poHeaders, activeInboundPos } from '../../../utils/constants';
const { profileType } = useUserProfile();

export const dataSlice = createSlice({
    name: 'purchaseOrderList',
    initialState: {
        purchaseOrders: [],
        filterValues: [],
        isLoading: true,
        isLoadingPO: true,
        currentPage: 0,
        columnStatus: poHeaders.status,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        statusCount: {},
        error: null,
        success: false,
        poFilter: { poStatus: profileType === 'agent' ? awaitingEnrichment : activeStatus },
        poSelectedDropDown: {
            text: profileType === 'agent' ? awaitingEnrichment : allPos,
            count: 0,
        },
        inboundPoFilter: { poStatus: activeStatus },
        inboundPoSelectedDropDown: { text: activeInboundPos, count: 0 },
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        resetPage: (state, _action) => {
            state.currentPage = 0;
        },
        fetchPoData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.isLoadingPO = true;
            // state.isLoading = false;
        },
        savePoData: (state, action) => {
            // state.isLoading = true;
            state.purchaseOrders = action.payload.purchaseOrders?.poListingDtos;
            state.totalPages = action.payload.purchaseOrders?.totalPages;
            state.totalItems = action.payload.purchaseOrders?.totalElements;
            state.isLoading = true;
            state.isLoadingPO = false;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        fetchFilterData: (state, action) => {
            state.isLoading = true;
            if (action.payload?.filterData) {
                state.filterValues = action.payload?.filterData;
            }
            state.isLoading = false;
        },
        fetchStatusCount: (state, action) => {
            state.isLoading = true;
            state.statusCount = action.payload?.count;
            state.isLoading = false;
        },
        discardPo: (state, action) => {
            state.isLoading = true;
            if (!action.payload.success) {
                state.error = action.payload.error;
            } else {
                state.success = true;
            }
            state.isLoading = false;
        },
        cancelPo: (state, action) => {
            state.isLoading = true;
            if (!action.payload.success) {
                state.error = action.payload.error;
            } else {
                state.success = true;
            }
            state.isLoading = false;
        },
        reset: (state, _action) => {
            state.success = false;
            state.error = null;
        },
        setPoFilter: (state, action) => {
            state.poFilter = action.payload;
        },
        setPoSelectedDropDown: (state, action) => {
            state.poSelectedDropDown = action.payload;
        },
        setInboundPoFilter: (state, action) => {
            state.inboundPoFilter = action.payload;
        },
        setInboundPoSelectedDropDown: (state, action) => {
            state.inboundPoSelectedDropDown = action.payload;
        },
    },
});

export const {
    fetchPoData,
    setPageCount,
    savePoData,
    isLoadingFn,
    setDefault,
    resetPage,
    fetchFilterData,
    fetchStatusCount,
    discardPo,
    cancelPo,
    reset,
    setPoFilter,
    setPoSelectedDropDown,
    setInboundPoFilter,
    setInboundPoSelectedDropDown,
} = dataSlice.actions;
export default dataSlice.reducer;
