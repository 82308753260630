// import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, takeEvery } from 'redux-saga/effects';
import { isLoadingFn } from '../reducers/vendorListReducer';

// Import all api's
// import { vendorListResponse } from '../../services/apis/vendorListApi';
// import { getPermissions } from '../../services/apis/permissionApi';
import { savePermissions } from '../reducers/permissionReducer';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getPermissionData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const perms: any = JSON.parse(localStorage.getItem('wholePerms') as any);
        //const vendors: AxiosResponse<vendorListResponse> = yield call(getPermissions, payload);
        yield put({ type: savePermissions, payload: perms });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* permissionSaga() {
    yield takeEvery('permissionList/fetchPermissions', getPermissionData);
}

// Export the saga (data-saga)
export default permissionSaga;
