import React from 'react';
import { useAppDispatch } from '../../../hooks';
import { updateModalStatus } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
// import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import './rejected-booking-proposal.scss';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setAssignAgentModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    reason: string;
    bpId: any;
    profileType: string;
    setReason?: (value: string | ((prevVar: string) => string)) => void;
}
const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    setAssignAgentModalView,
    reason,
    bpId,
    profileType,
    setReason,
}) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const viewModal = () => {
        dispatch(
            updateModalStatus({
                bpId,
                profileType,
                modalType: 'reject',
            })
        );
        propagateBcnData(id);
        // setTimeout(() => {
        //     dispatch(fetchBpHeaderData({ bpId, profileType }));
        // }, 2000);
    };
    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder rejected-booking-modal">
                        <div className="modal-header">
                            <div className="title">Booking Proposal Rejected</div>
                            <button
                                onClick={() => {
                                    setModalView(false);
                                    viewModal();
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Reason</h6>
                                        <div className="description-type-1">{reason}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {!headerData.isForwarderSubmit && (
                                <button
                                    onClick={() => {
                                        setReason && setReason(reason);
                                        setAssignAgentModalView(true);
                                    }}
                                    className="app-btn app-btn-secondary modal-btn footer-btn"
                                    title="Assign to Agent"
                                >
                                    <span className="button-text footer-button-text">Assign to Agent</span>
                                </button>
                            )}
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Update Proposal"
                                onClick={() => {
                                    setModalView(false);
                                    viewModal();
                                }}
                            >
                                <span className="button-text footer-button-text">Update Proposal</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
