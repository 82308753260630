import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import './profile-basic-detail-modal.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    fetchHeaderDataCarriers,
    fetchShippingLineBasicDetailsList,
    selectShippingLineBasicDetailsError,
    selectShippingLineBasicDetailsIsError,
    selectShippingLineBasicDetailsSuccess,
    updateForwarderRank,
} from '../../../../store/reducers/profiles/shippingLine/basicDetailsReducer';
import { error, Position, success } from '../../../../utils/toast';
interface ModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    forwarderRanks: any;
    shippingLineId: string | undefined;
    forwarderRanking: string;
}
const Index: React.FC<ModalProps> = (props) => {
    const [forwarderRanking, setForwarderRanking] = useState('');
    const [forwarderRankId, setForwarderRankId] = useState('');
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSave, setShowSave] = useState(false);

    const dispatch = useAppDispatch();
    const shippingLineBasicDetailsSuccess = useAppSelector(selectShippingLineBasicDetailsSuccess);
    const shippingLineBasicDetailsIsError = useAppSelector(selectShippingLineBasicDetailsIsError);
    const shippingLineBasicDetailsError = useAppSelector(selectShippingLineBasicDetailsError);

    useEffect(() => {
        if (shippingLineBasicDetailsIsError) {
            error(shippingLineBasicDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        } else if (shippingLineBasicDetailsSuccess?.status === 200) {
            success('Forwarder Ranking has been updated', Position.TOP_RIGHT);
            dispatch(fetchShippingLineBasicDetailsList({ id: props.shippingLineId }));
            props.displayFunction(false);
        }
        dispatch(fetchHeaderDataCarriers(props.shippingLineId));
    }, [shippingLineBasicDetailsIsError, shippingLineBasicDetailsSuccess, shippingLineBasicDetailsError]);

    useEffect(() => {
        setForwarderRanking(props.forwarderRanking);
    }, [props.display]);
    return (
        <>
            {props.display ? (
                <div
                    onClick={() => {
                        setShowDropdown(false);
                    }}
                    className="app-modal shipping-profile-information-modal"
                >
                    <div className="modal-content-holder">
                        <div className="modal-header">
                            <div className="title">Profile Information</div>
                            <button
                                onClick={() => {
                                    props.displayFunction(false);
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content">
                                <form id="my-form" action="" className="modal-form layout-holder one-column">
                                    <div className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Forwarder Ranking
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <div
                                                className={
                                                    showDropdown
                                                        ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                        : 'dropdown-wrap  menu-down modal-dropdown'
                                                }
                                            >
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowDropdown((prev) => !prev);
                                                    }}
                                                    type="button"
                                                    className="menu-btn app-btn"
                                                >
                                                    <span className="btn-text">
                                                        {forwarderRanking
                                                            ? forwarderRanking
                                                            : 'Select Forwarder Ranking'}
                                                    </span>
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {/* dropdown-suggestion */}
                            <div
                                className={
                                    showDropdown
                                        ? 'dropdown-wrap forwarder-ranking-dropdown-wrap outside-menu  menu-down dropdown-open modal-dropdown'
                                        : 'dropdown-wrap forwarder-ranking-dropdown-wrap outside-menu  menu-down modal-dropdown'
                                }
                            >
                                <div className="dropdown-menu">
                                    {props?.forwarderRanks &&
                                        props?.forwarderRanks.map((forwarderRank: any, index: number) => (
                                            <a
                                                onClick={() => {
                                                    setForwarderRanking(forwarderRank?.rank);
                                                    setForwarderRankId(forwarderRank?.fr_PK);
                                                    setShowSave(true);
                                                }}
                                                key={index}
                                                className="menu-item app-btn"
                                            >
                                                <span className="main-content">{forwarderRank?.rank}</span>
                                            </a>
                                        ))}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => {
                                    props.displayFunction(false);
                                }}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className={`app-btn app-btn-primary modal-btn footer-btn ${
                                    showSave && forwarderRanking !== props.forwarderRanking ? '' : 'disabled'
                                }`}
                                type="button"
                                onClick={() => {
                                    dispatch(
                                        updateForwarderRank({
                                            shippingLineId: props.shippingLineId,
                                            rankId: forwarderRankId,
                                        })
                                    );
                                }}
                                disabled={showSave && forwarderRanking !== props.forwarderRanking ? false : true}
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : null}
        </>
    );
};
export default Index;
