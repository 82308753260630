import { post, patch } from 'src/services/HttpMethods';
import fileDownload from 'js-file-download';
import axios from 'axios';

export interface AoData {
    poId: string;
    aoNumber: string;
    aoStatus: string;
    orderDate: string;
    createdDate: string;
    clientName: string;
    blpAgentName: string;
    blpAgentCode: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
    createdOn: string;
    totKGS: number;
    totCBM: number;
    isMultiAddress: boolean;
    type: string;
}

export interface AoResponse {
    content: AoData[];
    totalPages: number;
    totalElements: number;
}

export interface PoSearchData {
    content: AoData[];
    totalPages: number;
    totalElements: number;
}

export interface AODiscardCancelParams {
    action: 'cancel' | 'discard';
    aoId: string;
    name: string;
    reason: string;
    userName: string;
}

export const getAoData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    profileType: string;
}) => {
    try {
        const res = await post(
            `api/ord/ao/list/${payload.profileType}?size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getAoFilterValues = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/ord/ao/distinct/${payload.profileType}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getAoStatusCount = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/ord/ao/statuscount/${payload.profileType}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const exportAoList = async (type: string, organisationData: any, profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const sortColumn = organisationData?.sortColumn;
        const sortOrder = organisationData?.sortOrder;
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/ord/ao/export/${profileType}?type=${type}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(organisationData),
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'AllocaionOrders.xlsx' : 'AllocaionOrders.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

//function calling API to cancel or discard AO
export const cancelOrDiscardAO = async ({ action, aoId, name, reason, userName }: AODiscardCancelParams) => {
    try {
        const res = await patch(
            `api/ord/ao/${action}/${aoId}?reason=${reason}&userName=${userName}&name=${name}`,
            null
        );
        return res.success;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
