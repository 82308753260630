import React from 'react';
import { useSelector } from 'react-redux';

const Index: React.FC = () => {
    const { singleMapData } = useSelector((state: any) => state.map);
    return (
        <div className="tooltip-dashboard-wrapper" style={{ width: '100%' }}>
            <div className="dashboard-heading">
                <div className="main-heading">Bookings at Origin Country</div>
                <div className="sub-heading">
                    {singleMapData?.countryName} ({singleMapData?.countryCode})
                </div>
            </div>

            <div className="count-badges">
                <div className="count">{singleMapData?.bookingCount} Bookings</div>
                <div className="count">{singleMapData?.poCount} POs</div>
            </div>
            <div className="link-back-btn">
                <a href="/booking_list" className="booking-link">
                    Go to Bookings
                </a>
                <div className="back-icon">
                    <svg className="svg-icon straight-arrow-pointer-icon">
                        <use href="#straightArrowPointerIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </div>
            </div>
        </div>
    );
};

export default Index;
