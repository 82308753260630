/* eslint-disable no-undefined */
import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useUserProfile } from 'src/hooks/useUserProfile';
import { clearListFetching, setTodoType, todoItemFetching } from 'src/store/reducers/dashboard/dashboardTodoReducer';
import { RootState } from 'src/store/reducers';

import DraftPo from './DraftPo';
import PendingReassignment from './PendingReassignment';
import DraftAo from './DraftAo';
import DraftReceipt from './DraftReceipt';
import AwaitingFORWARDER from './AwaitingFORWARDER';
import LclOptions from './LclOptions';
import SearchHeader from '../Search/SearchHeader';
import { TODO_ICON_MAP } from '../utils/dashboardTodoConstants';

import '../dashboard.scss';
import './todo-grid-view.scss';
// import { getOrganisationData } from 'src/utils';
import { searchItemFilter } from '../utils';
import { insightTabList } from '../Insights/enums';
import {
    clearInsightDestination,
    clearInsightMode,
    setInsightsDurationRange,
} from 'src/store/reducers/dashboard/insightsReducer';
import { FilterList } from 'src/pages/Dashboard/Insights/enums';
import OtherItems from './OtherItems';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);
    const {
        insightsMode,
        favInsightsMode,
        insightsDestination,
        insightsSelectedType,
        // insightsMainPage,
        insightsSelectedTypeFilter,
        insightsForwarded,
        favInsightsForwarded,
        favInsightsSelectedType,
        insightTab,
    } = useSelector((state: RootState) => state.dashboardInsights);
    const { selectedTodoType, isLoading, todoList } = useSelector((state: RootState) => state.dashboardTodo);

    const { type } = useParams();
    const [searchParams] = useSearchParams();
    const otherTodoName = searchParams.get('type');
    const currentItem = TODO_ICON_MAP[type ?? ''];
    const { profileType, userName } = useUserProfile();
    const bpTypePayloadFn = () => {
        if (
            (insightsMode?.length > 0 || favInsightsMode?.length > 0) &&
            currentItem?.type?.toLowerCase().includes('bp')
        ) {
            return (insightsMode?.length > 0 ? insightsMode : favInsightsMode)?.map((i) => {
                switch (i?.toLowerCase()) {
                    case 'air':
                        return 'AIR';
                    case 'sea':
                        return ['FCL', 'LCL', 'BCN'];
                    case 'fcl':
                        return 'FCL';
                    case 'lcl':
                        return 'LCL';
                    case 'bcn':
                        return 'BCN';
                    case 'land':
                    case 'lnd':
                        return 'AO';
                    default:
                        return i?.toUpperCase();
                }
            });
        } else if (
            ((favInsightsSelectedType?.toLowerCase() === 'ao' && insightTab?.toLowerCase() == 'fav') ||
                (insightsSelectedType?.toLowerCase() === 'ao' && insightTab?.toLowerCase() == 'all')) &&
            currentItem?.type?.toLowerCase().includes('bp')
        ) {
            return ['AO'];
        } else if (
            ((favInsightsSelectedType?.toLowerCase() === 'po' && insightTab?.toLowerCase() == 'fav') ||
                (insightsSelectedType?.toLowerCase() === 'po' && insightTab?.toLowerCase() == 'all')) &&
            currentItem?.type?.toLowerCase().includes('bp')
        ) {
            return ['FCL', 'LCL', 'BCN', 'AIR'];
        } else if (currentItem?.type?.toLowerCase().includes('bp')) {
            return ['FCL', 'LCL', 'BCN', 'AIR', 'AO'];
        } else {
            return undefined;
        }
    };
    useEffect(() => {
        if (!selectedSearchItem || selectedSearchItem?.poId || selectedSearchItem?.bpId || selectedSearchItem?.type) {
            const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
            const organisations = searchItemFilter(selectedSearchItem)
                ? searchItemFilter(selectedSearchItem)
                : listOfUserOrganisations;
            if (!todoList?.others) {
                dispatch(
                    todoItemFetching({
                        type: currentItem.type,
                        profileType: profileType,
                        userName,
                        fetchItemPayload: currentItem?.fetchItemPayload ?? {},
                        search: {
                            poId: selectedSearchItem?.poId,
                            bpId: selectedSearchItem?.bpId,
                            ...organisations,
                            mode:
                                insightTab === insightTabList.all
                                    ? insightsMode
                                          .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                                          .map((i) => {
                                              if (i === 'land') {
                                                  return 'LND';
                                              }
                                              return i.toUpperCase();
                                          })
                                    : favInsightsMode
                                          .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                                          .map((i) => {
                                              if (i === 'land') {
                                                  return 'LND';
                                              }
                                              return i.toUpperCase();
                                          }),
                            bpType: bpTypePayloadFn(),
                            // (insightsMode?.length > 0 || favInsightsMode?.length > 0) &&
                            // currentItem?.type?.toLowerCase().includes('bp')
                            //     ? (insightsMode?.length > 0 ? insightsMode : favInsightsMode)
                            //           ?.map((i) => {
                            //               switch (i?.toLowerCase()) {
                            //                   case 'air':
                            //                       return 'AIR';
                            //                   case 'sea':
                            //                       return ['FCL', 'LCL', 'BCN'];
                            //                   case 'land':
                            //                   case 'lnd':
                            //                       return 'AO';
                            //                   default:
                            //                       return i?.toUpperCase();
                            //               }
                            //           })
                            //           .filter(Boolean)
                            //           .flat()
                            //     : favInsightsSelectedType?.toLowerCase() === 'ao' &&
                            //       currentItem?.type?.toLowerCase().includes('bp')
                            //     ? ['AO']
                            //     : undefined,
                            shippingmode:
                                // !selectedTodoType?.toLowerCase()?.includes('booking')
                                //     ?
                                insightsMode
                                    .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                                    .map((i) => {
                                        if (i === 'land') {
                                            return 'Land';
                                        }
                                        if (i === 'air') {
                                            return 'Air';
                                        }
                                        if (i === 'sea') {
                                            return 'Sea';
                                        }
                                        return i.toUpperCase();
                                    }),
                            // : '',
                            // bpType: insightsMode?.length
                            //     ? insightsMode
                            //           .filter((i) => !['lcl', 'fcl', 'bcn'].includes(i))
                            //           .map((i) => {
                            //               if (i === 'land') {
                            //                   return 'LND';
                            //               }
                            //               if (i === 'air') {
                            //                   return 'Air';
                            //               }
                            //               if (i === 'sea') {
                            //                   return 'Sea';
                            //               }
                            //               return i.toUpperCase();
                            //           })
                            //     : insightsSelectedType === ''
                            //     ? []
                            //     : [insightsSelectedType?.toUpperCase()],
                            fromToDo: true,
                            destinationType: insightsDestination,
                            // isPurchaseOrder: insightsForwarded
                            //     ? insightsForwarded === 'active_orders'
                            //         ? true
                            //         : false
                            //     : null,
                            isPurchaseOrder:
                                insightTab === insightTabList.all
                                    ? insightsForwarded === 'active_orders'
                                        ? true
                                        : selectedTodoType?.toLowerCase().includes('po')
                                        ? true
                                        : false
                                    : favInsightsForwarded === 'active_orders'
                                    ? true
                                    : selectedTodoType?.toLowerCase().includes('po')
                                    ? true
                                    : false,
                            ...(insightsForwarded === 'active_bookings' && {
                                bookingsType: insightsMode.map((i) => i !== 'sea' && i.toUpperCase()),
                                bookingStatus: insightsSelectedTypeFilter ? [insightsSelectedTypeFilter] : [],
                            }),
                            ...(insightsForwarded === 'active_orders' && {
                                purchaseOrderType: insightsSelectedType ? [insightsSelectedType] : [],
                                purchaseOrderStatus: insightsSelectedTypeFilter ? [insightsSelectedTypeFilter] : [],
                            }),
                        },
                    })
                );
            }
        } else {
            dispatch(clearListFetching());
        }
    }, [selectedSearchItem]);

    useEffect(() => {
        if (!type) {
            navigate('/dashboard/to-do-activity-tabs');
        }
    }, [type]);
    return (
        <div className="main-wrapper container dashboard-wrapper todo-expand-inner-view">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <SearchHeader />
            <div className="breadcumbs-expand-wrapper">
                <div className="breadcumb-header-holder">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <li className="breadcumbs-back-arrow-item">
                                <Link className="dropdown-arrow" to={'/dashboard/to-do-activity-tabs'}>
                                    <svg className="arrow-icon">
                                        <use xlinkHref="#downArrow">
                                            <title>Details</title>
                                        </use>
                                    </svg>
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to={'/dashboard/to-do-activity-tabs'}>
                                    {location.pathname.includes('/dashboard/activity-feed') ? 'Activity Feed' : 'To Do'}
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item disabled" to={''}>
                                    {otherTodoName ? otherTodoName : currentItem?.name}
                                    {/* Awaiting FORWARDER Approval */}
                                    {/* Draft Receipt */}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <h1 className="page-heading">{otherTodoName ? otherTodoName : currentItem?.name}</h1>
                    {/* <h1 className="page-heading">Awaiting FORWARDER Approval</h1> */}
                    {/* <h1 className="page-heading">Draft Receipt</h1> */}
                </div>
                <button
                    className="view-nav grid-icon icon-nav "
                    title="Collapse"
                    onClick={() => {
                        dispatch(setTodoType({ type: null }));
                        dispatch(clearInsightMode());
                        dispatch(clearInsightDestination());
                        dispatch(setInsightsDurationRange(FilterList.Days));
                        navigate('/', {
                            state: {
                                selected: location.pathname.includes('/dashboard/activity-feed') ? 1 : 0,
                            },
                        });
                    }}
                >
                    <svg className="svg-icon maxi-icon ">
                        <use xlinkHref="#minimaxIcon">
                            <title>Collapse</title>
                        </use>
                    </svg>
                </button>
            </div>
            {/* draft-po-view class is for draft-view */}
            {/* awaiting-forwarder class is for awaiting todo tab */}
            {/* draft-receipt class is for draft-receipt */}
            {/* Each one having different layout */}
            <div className="todo-grid-view">
                {currentItem.type === 'PO' && <DraftPo path={currentItem.path ?? ''}></DraftPo>}
                {currentItem.type === 'pendingReassignment' && (
                    <PendingReassignment path={currentItem.path ?? ''}></PendingReassignment>
                )}
                {currentItem.type === 'AO' && <DraftAo path={currentItem.path ?? ''}></DraftAo>}
                {currentItem.type === 'Receipt' && <DraftReceipt path={currentItem.path ?? ''}></DraftReceipt>}
                {currentItem.type === 'BP' && <AwaitingFORWARDER path={currentItem.path ?? ''}></AwaitingFORWARDER>}
                {currentItem.type === 'LCL' && <LclOptions path={currentItem.path ?? ''}></LclOptions>}
                {currentItem.type === 'OTHER_TODO' && <OtherItems path={currentItem.path ?? ''}></OtherItems>}
            </div>
        </div>
    );
};
export default Index;
