import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchBpHeaderData,
    cancelBp,
    isLoadingFn,
    fetchLinkedBpHeader,
    cancelBooking,
} from '../../reducers/aobookingProposal/bookingProposalHeaderReducer';
import {
    getBpHeaderData,
    cancelBookingProposal,
} from '../../../services/apis/aobookingProposal/bookingProposalHeaderApi';

function* getBpHeader({ payload }: { payload: any; type: any }) {
    try {
        //yield put({ type: isLoadingFn, payload: true });
        const data: AxiosResponse<any> = yield call(getBpHeaderData, payload);
        payload['data'] = data;
        yield put({ type: fetchBpHeaderData, payload: payload });
    } catch (err) {
        console.log('Error', err);
    }
}

function* bookingProposalCancellation({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const data: AxiosResponse<any> = yield call(cancelBookingProposal, payload);
        payload['res'] = data;
        yield put({ type: cancelBp, payload: payload });
    } catch (error: any) {
        payload['success'] = false;
        payload['error'] = error?.data?.data || 'Something went wrong';
        yield put({ type: cancelBp, payload });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getLinkedBpHeader({ payload }: { payload: any; type: any }) {
    try {
        const linkedBpDetails: AxiosResponse<any> = yield call(getBpHeaderData, payload);
        payload['linkedBpDetails'] = linkedBpDetails;
        yield put({ type: fetchLinkedBpHeader, payload: payload });
    } catch (err) {
        console.log('Error', err);
    }
}

function* bookingCancellation({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const data: AxiosResponse<any> = yield call(cancelBookingProposal, payload);
        payload['res'] = data;
        yield put({ type: cancelBooking, payload: payload });
    } catch (error: any) {
        payload['success'] = false;
        payload['error'] = error?.data?.data || 'Something went wrong';
        yield put({ type: cancelBooking, payload });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* bpHeaderSaga() {
    yield takeEvery('bpHeaderDataao/fetchBpHeaderData', getBpHeader);
    yield takeEvery('bpHeaderDataao/cancelBp', bookingProposalCancellation);
    yield takeEvery('bpHeaderDataao/fetchLinkedBpHeader', getLinkedBpHeader);
    yield takeEvery('bpHeaderDataao/cancelBooking', bookingCancellation);
}

export default bpHeaderSaga;
