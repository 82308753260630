import axios from 'axios';
import { get, post } from '../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const getWhWarehouseApi = async () => {
    try {
        const data = await get(`cix/scr/cfg/whs`, null, externalBaseUrl);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
