import React from 'react';
// import './style.css';
import '../../../assets/scss/components/_modal.scss';
import '../../../pages/Profiles/Clients/VendorDetails/vendor-modal.scss';

interface BioProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    title: string;
    okButtonName: string;
    childComp?: React.ReactNode;
    okButtonFunc: any;
    cancelButtonFunc: any;
    disable: boolean;
}

const Index: React.FC<BioProps> = (props) => {
    return (
        <>
            {props.display ? (
                <div className="app-modal">
                    <div
                        className="modal-content-holder vendor-content-holder medium-popup"
                        onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-header">
                            <div className="title">{props.title}</div>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => {
                                    props.cancelButtonFunc();
                                }}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        {props.childComp}
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => {
                                    props.cancelButtonFunc();
                                }}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                style={{ marginLeft: '8px' }}
                                className={'app-btn app-btn-primary modal-btn ' + (props.disable ? 'disabled' : '')}
                                onClick={() => props.okButtonFunc()}
                            >
                                <span className="button-text vendor-button-text">{props.okButtonName}</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
