import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { fetchHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import { rejectContainerFunc, rejectViewedStatus } from 'src/store/reducers/purchaseOrder/containerModePoReducer';
import './container-group.scss';

const RejectOptions = (props: any) => {
    const dispatch = useDispatch();
    const { id, group_id } = useParams();
    const { profileType, userName } = useUserProfile();
    const [rejectionReason, setrejectionReason] = useState('');
    const [validation, setValidation] = useState(false);
    const rejectFunc = () => {
        const payload = {
            confirmationGroupId: group_id,
            reason: rejectionReason,
            profile: profileType,
            userName: userName,
        };
        rejectionReason?.length > 0 && dispatch(rejectContainerFunc(payload));
        rejectionReason?.length > 0 && props.onClose(false);
    };

    const viewedByUser = () => {
        dispatch(rejectViewedStatus({ id: group_id, user: profileType }));
    };

    useEffect(() => {
        if (props.reasonData) {
            setrejectionReason(props.reasonData);
        }
    }, [props.reasonData]);

    return (
        <div className="app-modal container-reject-modal">
            <div className="modal-content-holder medium-popup auto-height">
                <div className="modal-header">
                    <div className="title">{profileType === 'client' ? 'Reject Options' : 'Options Rejected'}</div>
                    <button
                        className="app-btn modal-close-btn"
                        type="button"
                        onClick={() => {
                            props.onClose(false);
                            setValidation(false);
                            if (profileType !== 'client' && props.viewReason !== true) {
                                viewedByUser();
                                dispatch(
                                    fetchHeader({
                                        poId: id,
                                        profile: profileType,
                                        cgId: group_id,
                                    })
                                );
                            }
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <label className={`modal-input-label ${profileType !== 'client' && 'viewOnly'}`}>Reason</label>
                    <div className="modal-input-field-holder">
                        {profileType !== 'client' ? (
                            props.reasonData
                        ) : (
                            <textarea
                                name="reason"
                                className="input-text"
                                placeholder="Enter the reason to reconsider"
                                maxLength={250}
                                onChange={(e) => setrejectionReason(e.target.value)}
                                defaultValue={rejectionReason}
                            ></textarea>
                        )}
                        {}
                        {rejectionReason?.length < 1 && validation && (
                            <div className="error-text">Reason cannot be empty</div>
                        )}
                    </div>
                </div>

                {/* hide footer on user perspective */}
                {props.viewReason !== true && (
                    <>
                        {profileType === 'client' && (
                            <div className="modal-footer">
                                <button
                                    className="app-btn app-btn-secondary modal-btn footer-btn footer-btn-container"
                                    title="Back"
                                    type="button"
                                    onClick={() => {
                                        props.onClose(false);
                                        setValidation(false);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Back</span>
                                </button>
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn footer-btn-container"
                                    type="submit"
                                    title="Reject Options"
                                    onClick={() => {
                                        setValidation(true);
                                        rejectFunc();
                                    }}
                                    // disabled={rejectionReason?.length > 0 ? false : true}
                                >
                                    <span className="button-text footer-button-text">Reject Options</span>
                                </button>
                            </div>
                        )}
                        {(profileType === 'forwarder' || profileType === 'vendor') && (
                            <div className="modal-footer">
                                {!props?.confirmationGroup?.isSubmittedbyForwarder && (
                                    <button
                                        className="app-btn app-btn-secondary modal-btn footer-btn footer-btn-container"
                                        title="Assign to Agent"
                                        type="button"
                                        onClick={() => {
                                            props.onClose(false);
                                            props.setAssignAgentModalView(true);
                                        }}
                                    >
                                        <span className="button-text footer-button-text">Assign to Agent</span>
                                    </button>
                                )}
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn footer-btn-container"
                                    type="submit"
                                    title="Update Options"
                                    onClick={() => {
                                        viewedByUser();
                                        setTimeout(() => {
                                            dispatch(
                                                fetchHeader({
                                                    poId: id,
                                                    profile: profileType,
                                                    cgId: group_id,
                                                })
                                            );
                                            props.onClose(false);
                                        }, 2000);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Update Options</span>
                                </button>
                            </div>
                        )}
                        {profileType === 'agent' && (
                            <div className="modal-footer">
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn footer-btn-container"
                                    type="submit"
                                    title="Update Options"
                                    onClick={() => {
                                        viewedByUser();
                                        setTimeout(() => {
                                            props.onClose(false);
                                            dispatch(
                                                fetchHeader({
                                                    poId: id,
                                                    profile: profileType,
                                                    cgId: group_id,
                                                })
                                            );
                                        }, 1000);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Update Options</span>
                                </button>
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};
export default RejectOptions;
