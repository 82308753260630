import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import { clientData, clientDataFull, getRoleWithPermission } from '../../services/apis/usersForwarderApis';
import {
    changeRoleOfUser,
    getUsersData,
    linkClient,
    removeClient,
    resendActivation,
    listAllClients,
} from '../../services/apis/usersForwarderApis';
import {
    saveUsersData,
    isLoadingFn,
    saveUsersHeaderData,
    saveClient,
    statusChangeSuccess,
    saveRoleData,
    saveRoleDetail,
    savedResponseRole,
    createRoleStart,
    saveDeletedResponse,
    saveVendor,
    saveAgent,
    saveShipping,
    saveRowData,
    savedEditResponseRole,
    editRoleStart,
    fetchRoleListWithPermission,
} from '../reducers/rolesReducer';
import { exportFile } from '../../services/apis/data-api';
import {
    changeStatusRoles,
    createRole,
    deleteRole,
    editRole,
    getHeaderDataRoles,
    getRolesData,
    listAllAgents,
    listAllShipping,
    listAllVendors,
    listRoleDetails,
    viewAllRoles,
    searchRoles,
    viewDataColumn,
} from '../../services/apis/rolesApi';
import { getPermissions } from 'src/services/apis/permissionApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const res: AxiosResponse<clientDataFull[]> = yield call(getRolesData, payload);
        yield put({ type: saveUsersData, payload: res });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* getAllRoles({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const res: AxiosResponse<clientDataFull[]> = yield call(viewAllRoles, payload);
        yield put({ type: saveRoleData, payload: res });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* getdetailRole({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const res: AxiosResponse<clientDataFull[]> = yield call(listRoleDetails, payload);
        yield put({ type: saveRoleDetail, payload: res });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* changeRoleUser({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield call(changeRoleOfUser, payload);
        const res: AxiosResponse<clientDataFull[]> = yield call(getUsersData, payload);
        yield put({ type: saveUsersData, payload: res });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* changeRoleStatus({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield call(changeStatusRoles, payload);
        yield put({
            type: statusChangeSuccess,
            payload: { status: true, name: payload.name, val: payload.currentStat },
        });
        const header: AxiosResponse<clientData[]> = yield call(getHeaderDataRoles, payload);
        yield put({ type: saveUsersHeaderData, payload: header });
    } finally {
        const res: AxiosResponse<clientDataFull[]> = yield call(getRolesData, payload);
        yield put({ type: saveUsersData, payload: res });
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* usersHeaderData({ payload }: { payload: any; type: any }) {
    try {
        const header: AxiosResponse<clientData[]> = yield call(getHeaderDataRoles, payload);
        yield put({ type: saveUsersHeaderData, payload: header });
    } finally {
    }
}
function* createNewRole({ payload }: { payload: any; type: any }) {
    try {
        const header: AxiosResponse<clientData[]> = yield call(createRole, payload);
        yield put({ type: createRoleStart, payload: true });
        if ((header as any).success) {
            yield put({ type: savedResponseRole, payload: true });
        } else if ((header as any).name === 'AxiosError') {
            yield put({ type: savedResponseRole, payload: false });
        }
    } finally {
    }
}
function* editNewRole({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const header: AxiosResponse<clientData[]> = yield call(editRole, payload);
        yield put({ type: editRoleStart, payload: true });
        if ((header as any).success) {
            yield put({ type: savedEditResponseRole, payload: true });
            // const res: AxiosResponse<any> = yield call(getPermissions, payload.userName);
            yield call(getPermissions, payload.userName);
        } else if ((header as any).name === 'AxiosError') {
            yield put({ type: savedEditResponseRole, payload: false });
        }
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* deleteRoles({ payload }: { payload: any; type: any }) {
    try {
        const header: AxiosResponse<clientData[]> = yield call(deleteRole, payload);
        //yield put({ type: createRoleStart, payload: true });
        if ((header as any).success) {
            yield put({ type: saveDeletedResponse, payload: true });
        } else if ((header as any).name === 'AxiosError') {
            yield put({ type: saveDeletedResponse, payload: false });
        }
    } finally {
    }
}
function* fileDownloadUsers({ payload }: { payload: any; type: any }) {
    try {
        yield call(exportFile, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* listClients({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const clients: AxiosResponse<any[]> = yield call(listAllClients, payload);
        const vendors: AxiosResponse<any[]> = yield call(listAllVendors, payload);
        const agents: AxiosResponse<any[]> = yield call(listAllAgents, payload);
        const shipping: AxiosResponse<any[]> = yield call(listAllShipping, payload);
        yield put({ type: saveClient, payload: clients });
        yield put({ type: saveVendor, payload: vendors });
        yield put({ type: saveAgent, payload: agents });
        yield put({ type: saveShipping, payload: shipping });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const agents: AxiosResponse<any> = yield call(searchRoles, payload);
        yield put({ type: saveUsersData, payload: agents });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getRowData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const agents: AxiosResponse<any> = yield call(viewDataColumn, payload);
        yield put({ type: saveRowData, payload: agents });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* emailResend({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield call(resendActivation, payload);
        const res: AxiosResponse<clientDataFull[]> = yield call(getUsersData, payload);
        yield put({ type: saveUsersData, payload: res });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* getRolesOptions({ payload }: { payload: any; type: any }) {
    const roles: AxiosResponse<any> = yield call(getRoleWithPermission, payload);

    yield put({ type: fetchRoleListWithPermission, payload: roles });
}

function* clienttoUsers({ payload }: { payload: any; type: any }) {
    yield call(linkClient, payload);
}
function* unlinkClient({ payload }: { payload: any; type: any }) {
    yield call(removeClient, payload);
}
function* rolesSaga() {
    yield takeEvery('roles/fetchData', getListData);
    yield takeEvery('roles/changeRole', changeRoleUser);
    yield takeEvery('roles/changeStatus', changeRoleStatus);
    yield takeEvery('roles/getHeaderData', usersHeaderData);
    yield takeEvery('roles/downloadFile', fileDownloadUsers);
    yield takeEvery('roles/clientLinkUser', clienttoUsers);
    yield takeEvery('roles/clientUnlinkUser', unlinkClient);
    yield takeEvery('roles/resendEmail', emailResend);
    yield takeEvery('roles/listAllClient', listClients);
    yield takeEvery('roles/createRoleNew', createNewRole);
    yield takeEvery('roles/fetchRoleList', getAllRoles);
    yield takeEvery('roles/roleDetailsFetch', getdetailRole);
    yield takeEvery('roles/deleteaRole', deleteRoles);
    yield takeEvery('roles/editaRole', editNewRole);
    yield takeEvery('roles/searchRolesData', getSearchData);
    yield takeEvery('roles/fetchRowData', getRowData);
    yield takeEvery('roles/fetchRoleListWithPermission', getRolesOptions);
}

// Export the saga (data-saga)
export default rolesSaga;
