import React, { useEffect, useState } from 'react';
import './booking-sailing-schelude.scss';
import ListGrid from '../ListGrid';
import { useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import Moment from 'react-moment';
import { listLoadingFn, saveCountValues } from 'src/store/reducers/bookingProposal/sailingReducer';
import { useDispatch } from 'react-redux';
import ShowForPermissions from 'src/ShowForPermissions';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useLocation, useNavigate } from 'react-router-dom';
// import SailingScheduleDetailModal from './SailingScheduleDetailModal';

interface Props {
    data?: any;
    dates: any;
    rangeSelectFn: any;
    selectedRange: any;
    sortFn: any;
    sortValue: any;
    selectedValue: any;
    filterData: any;
    setserviceType: any;
    serviceType: any;
    setshippingLine: any;
    shippingLine: any;
    filterIndex: any;
    setfilterIndex: any;
    resetFilter: any;
    contractType: any;
    setcontractType: any;
    shippingDates: any;
    setshippingDates: any;
    bpStatus: any;
    isLoading: boolean;
    valueSelected: any;
    onContractChange: any;
    onVesselChange: boolean;
}
/* eslint no-var: off */

const Index: React.FC<Props> = ({
    filterData,
    dates,
    rangeSelectFn,
    selectedRange,
    sortFn,
    sortValue,
    selectedValue,
    setserviceType,
    serviceType,
    shippingLine,
    setshippingLine,
    filterIndex,
    setfilterIndex,
    resetFilter,
    setcontractType,
    contractType,
    setshippingDates,
    shippingDates,
    bpStatus,
    isLoading,
    valueSelected,
    onContractChange,
    onVesselChange,
}) => {
    const { sailingDataValues, fakData, countThird, countSecond, countFirst, listLoading } = useSelector(
        (state: RootState) => state.sailingRed
    );
    const hideGrid = contractType?.length === 0 || shippingLine?.length === 0;
    const initialAccordionData = filterData?.map?.((res: any) => res?.contractId);
    const [DropDown, setDropDown] = useState(false);
    const [accordionList, setAccordionList] = useState<any>(initialAccordionData);
    const [displayFilter, setdisplayFilter] = useState(false);
    const [sliceValue, setsliceValue] = useState(3);
    const [selectedColumn, setselectedColumn] = useState('');
    const [selectedRangeName, setselectedRangeName] = useState('second');
    const { profileType } = useUserProfile();
    const navigate = useNavigate();
    // const location = useLocation();
    // const dispatch = useDispatch();
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!listLoading) {
            if (selectedRangeName === 'first') {
                dispatch(
                    saveCountValues({
                        firstCount: sailingDataValues?.length,
                        secondCount: countSecond,
                        thirdCount: countThird,
                    })
                );
            }
            if (selectedRangeName === 'second') {
                dispatch(
                    saveCountValues({
                        firstCount: countFirst,
                        secondCount: sailingDataValues?.length,
                        thirdCount: countThird,
                    })
                );
            }
            if (selectedRangeName === 'third') {
                dispatch(
                    saveCountValues({
                        firstCount: countFirst,
                        secondCount: countSecond,
                        thirdCount: sailingDataValues?.length,
                    })
                );
            }
        }
    }, [dates, selectedRangeName, sailingDataValues, listLoading]);

    const checkingTrue = (dateVal: any, name: any, contractId: string, type: any) => {
        var flag = false;
        shippingDates.forEach((element: any) => {
            if (element[type] === dateVal && element.shippingLineName === name && element.contractId === contractId) {
                flag = true;
            }
        });
        return flag;
    };

    const rowSelectedCheck = (name: any, contractId: string) => {
        var flag = false;
        shippingDates.forEach((element: any) => {
            if (
                element.shippingLineName === name &&
                element.contractId === contractId &&
                (element.firstTwoWeekStartDate !== null ||
                    element.secondTwoWeekStartDate !== null ||
                    element.afterFourWeeksStartDate != null)
            ) {
                flag = true;
            }
        });
        return flag;
    };

    useEffect(() => {
        if (!isLoading) {
            setdisplayFilter(true);
        }
    }, [isLoading]);

    const [search, setSearch] = useState('');

    function handleSearch(e: any) {
        setSearch(e.target.value);
    }

    function checkIsEmpty() {
        const nacEmpty =
            contractType?.includes('NAC') || contractType?.length === 0
                ? filterData?.filter((s: any) =>
                      s?.shippingLineName?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())
                  )?.length
                : false;
        const fakEmpty =
            contractType?.includes('FAK') || contractType?.length === 0
                ? fakData?.filter((s: any) =>
                      s?.shippingline?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())
                  )?.length
                : false;

        return !nacEmpty && !fakEmpty;
    }
    if (!isLoading) {
        return (
            <div onClick={() => setDropDown(false)}>
                <div className="booking-sailing-schelude-container">
                    {displayFilter && (
                        <div className="sailing-left-section active">
                            <div className="filter-link-holder">
                                <h2 className="title-heading">Filters</h2>
                                {localStorage.getItem('changeVessel') === null && !checkIsEmpty() && (
                                    <span className="filter-reset-btn">
                                        <a
                                            className="filter-reset-link"
                                            href="javascript:void(0)"
                                            onClick={() => {
                                                if (!!filterData?.length) {
                                                    resetFilter();
                                                } else {
                                                    setshippingLine([]);
                                                    setshippingDates([]);
                                                    setserviceType([]);
                                                }
                                            }}
                                        >
                                            Reset
                                        </a>
                                    </span>
                                )}
                                <button className="app-btn filter-close-btn" onClick={() => setdisplayFilter(false)}>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </button>
                            </div>
                            <div className="filter-holder">
                                {localStorage.getItem('changeVessel') !== id && !onVesselChange && (
                                    <div className="filter-types type-1">
                                        <div className="contract-type-header">
                                            <h2 className="filter-type-heading">Contract Type</h2>
                                            {!checkIsEmpty() && !!filterData?.length && (
                                                <span className="filter-reset-btn">
                                                    <a
                                                        className="filter-reset-link"
                                                        href="javascript:void(0)"
                                                        onClick={() => {
                                                            setshippingLine([]);
                                                            setshippingDates([]);
                                                            setserviceType([]);
                                                        }}
                                                    >
                                                        Unselect All
                                                    </a>
                                                </span>
                                            )}
                                        </div>
                                        {!!filterData?.length && (
                                            <div className="filter-type-holder">
                                                {filterData?.length !== 0 && (
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                checked={contractType.includes('NAC')}
                                                                onChange={(e) => {
                                                                    if (e.currentTarget.checked) {
                                                                        var newArray = [...contractType, 'NAC'];
                                                                        var uniqueArray = newArray.filter(function (
                                                                            item: any,
                                                                            pos: any
                                                                        ) {
                                                                            return newArray.indexOf(item) == pos;
                                                                        });
                                                                        setcontractType([...uniqueArray]);
                                                                        onContractChange({ nac: true });
                                                                    } else {
                                                                        const filterarray = contractType?.filter(
                                                                            (res: any) => res !== 'NAC'
                                                                        );
                                                                        setcontractType([...filterarray]);
                                                                    }
                                                                    selectedValue(null);
                                                                    setselectedColumn('');
                                                                    onContractChange({ nac: false });
                                                                }}
                                                                type="checkbox"
                                                                className="checkbox-input"
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon tick-icon">
                                                                    <use href="#tickIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">NAC</div>
                                                        </label>
                                                    </div>
                                                )}
                                                <div className="checkbox-item">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox-input"
                                                            checked={contractType.includes('FAK')}
                                                            disabled={fakData?.length === 0}
                                                            onChange={(e) => {
                                                                if (e.currentTarget.checked) {
                                                                    var newArray = [...contractType, 'FAK'];
                                                                    var uniqueArray = newArray?.filter(function (
                                                                        item: any,
                                                                        pos: any
                                                                    ) {
                                                                        return newArray.indexOf(item) == pos;
                                                                    });
                                                                    setcontractType([...uniqueArray]);
                                                                    onContractChange({ fak: true });
                                                                } else {
                                                                    const filterarray = contractType.filter(
                                                                        (res: any) => res !== 'FAK'
                                                                    );
                                                                    if (contractType.includes('NAC')) {
                                                                        setcontractType(['NAC']);
                                                                        setshippingLine([]);
                                                                    }
                                                                    setcontractType([...filterarray]);
                                                                    onContractChange({ fak: false });
                                                                }
                                                                selectedValue(null);
                                                                setselectedColumn('');
                                                            }}
                                                        ></input>
                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use href="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="checkbox-label">FAK</div>
                                                    </label>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}
                                <div className="filter-types type-2">
                                    <h2 className="filter-type-heading">Carrier</h2>

                                    <div className="search-entry-holder">
                                        <input
                                            placeholder="Search"
                                            type="text"
                                            className="search-input input-text"
                                            value={search}
                                            onChange={handleSearch}
                                        />
                                        <svg className="svg-icon search-icon">
                                            <use href="#searchIcon"></use>
                                        </svg>
                                        <button className="app-btn text-close-btn" onClick={() => setSearch('')}>
                                            <svg className="svg-icon text-close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </div>
                                    {(contractType?.includes('NAC') || contractType?.length === 0) &&
                                        filterData
                                            ?.filter((s: any) =>
                                                s?.shippingLineName
                                                    ?.toLocaleLowerCase()
                                                    .includes(search?.toLocaleLowerCase())
                                            )
                                            ?.map((res: any, index: any) => {
                                                const newVariable = (
                                                    <div
                                                        className={`checkbox-status-wrapper child-items accordion_child ${
                                                            accordionList?.includes(res?.contractId)
                                                                ? ''
                                                                : 'close-accordion_child'
                                                        }`}
                                                    >
                                                        <div className="checkbox-status-holder">
                                                            <button
                                                                className="accordion-btn"
                                                                onClick={() => {
                                                                    const copy = JSON.parse(
                                                                        JSON.stringify(accordionList)
                                                                    );
                                                                    const id = res?.contractId;
                                                                    if (!copy.includes(id)) {
                                                                        copy.push(id);
                                                                    } else {
                                                                        copy.splice(copy.indexOf(id), 1);
                                                                    }
                                                                    setAccordionList(copy);
                                                                }}
                                                            >
                                                                <svg className="svg-icon setting-icon">
                                                                    <use xlinkHref="#chevronRight">
                                                                        <title>Right Arrow Icon</title>
                                                                    </use>
                                                                </svg>
                                                            </button>
                                                            <div
                                                                title={
                                                                    contractType?.length
                                                                        ? ''
                                                                        : 'Select a Contract Type to choose Carrier'
                                                                }
                                                                className="checkbox-item"
                                                            >
                                                                <label className="app-check-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        checked={rowSelectedCheck(
                                                                            res?.shippingLineCode,
                                                                            res?.contractId
                                                                        )}
                                                                        disabled={contractType?.length ? false : true}
                                                                        onChange={(e) => {
                                                                            if (e.currentTarget.checked) {
                                                                                var newArray = [
                                                                                    ...shippingLine,
                                                                                    contractType.includes('NAC')
                                                                                        ? res?.shippingLineScacCode
                                                                                        : res?.shippingLineCode,
                                                                                ];
                                                                                var uniqueArray = newArray.filter(
                                                                                    function (item: any, pos: any) {
                                                                                        return (
                                                                                            newArray.indexOf(item) ==
                                                                                            pos
                                                                                        );
                                                                                    }
                                                                                );
                                                                                setshippingLine([...uniqueArray]);
                                                                                var newObj = {
                                                                                    shippingLineScacCode:
                                                                                        res?.shippingLineScacCode,
                                                                                    shippingLineName:
                                                                                        res?.shippingLineCode,
                                                                                    contractId: res?.contractId,
                                                                                    firstTwoWeekStartDate:
                                                                                        dates?.startDate1,
                                                                                    firstTwoWeekEndDate:
                                                                                        dates?.endDate1,
                                                                                    secondTwoWeekStartDate:
                                                                                        dates?.startDate2,
                                                                                    secondTwoWeekEndDate:
                                                                                        dates?.endDate2,
                                                                                    afterFourWeeksStartDate:
                                                                                        dates?.startDate3,
                                                                                    afterFourWeeksEndDate:
                                                                                        dates?.endDate3,
                                                                                };
                                                                                if (
                                                                                    shippingDates?.shippingLineName ===
                                                                                    res?.shippingLineCode
                                                                                ) {
                                                                                    setshippingDates([newObj]);
                                                                                } else {
                                                                                    setshippingDates([
                                                                                        ...shippingDates,
                                                                                        newObj,
                                                                                    ]);
                                                                                }
                                                                            } else {
                                                                                shippingDates.forEach(
                                                                                    (element: any) => {
                                                                                        if (
                                                                                            element.contractId ===
                                                                                            res?.contractId
                                                                                        ) {
                                                                                            var removeData: any = [
                                                                                                ...shippingDates,
                                                                                                (element.firstTwoWeekStartDate =
                                                                                                    null),
                                                                                                (element.firstTwoWeekEndDate =
                                                                                                    null),
                                                                                                (element.secondTwoWeekStartDate =
                                                                                                    null),
                                                                                                (element.secondTwoWeekEndDate =
                                                                                                    null),
                                                                                                (element.afterFourWeeksStartDate =
                                                                                                    null),
                                                                                                (element.afterFourWeeksEndDate =
                                                                                                    null),
                                                                                                (element.shippingLineName =
                                                                                                    null),
                                                                                                (element.contractId =
                                                                                                    null),
                                                                                            ];
                                                                                            removeData =
                                                                                                removeData.filter(
                                                                                                    function (el: any) {
                                                                                                        return (
                                                                                                            el != null
                                                                                                        );
                                                                                                    }
                                                                                                );
                                                                                            setshippingDates(
                                                                                                removeData
                                                                                            );
                                                                                        }
                                                                                        const filterarray =
                                                                                            shippingLine.filter(
                                                                                                (res1: any) =>
                                                                                                    res1 !==
                                                                                                    res?.contractName
                                                                                            );
                                                                                        setshippingLine([
                                                                                            ...filterarray,
                                                                                        ]);
                                                                                    }
                                                                                );
                                                                                const checkKey = contractType.includes(
                                                                                    'NAC'
                                                                                )
                                                                                    ? res?.shippingLineScacCode
                                                                                    : res?.shippingLineCode;
                                                                                const filterarray = shippingLine.filter(
                                                                                    (res1: any) => res1 !== checkKey
                                                                                );
                                                                                setshippingLine([...filterarray]);
                                                                            }
                                                                            selectedValue(null);
                                                                            setselectedColumn('');
                                                                        }}
                                                                    ></input>
                                                                    <div className="checkmark">
                                                                        {/* <svg className="svg-icon tick-icon">
                                                                        <use href="#tickIcon">
                                                                            <title>check mark</title>
                                                                        </use>
                                                                    </svg> */}
                                                                        <svg className="svg-icon tick-icon">
                                                                            <use href="#dashCheck">
                                                                                <title>check mark</title>
                                                                            </use>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="checkbox-label">
                                                                        {res?.shippingLineName}
                                                                    </div>
                                                                </label>
                                                            </div>
                                                            <div
                                                                className={`status-ui nac-status${
                                                                    res.isPrimaryClient === 1
                                                                        ? ' nac-p'
                                                                        : res.isPrimaryClient === 0 && ' nac-s'
                                                                }`}
                                                            >
                                                                <div className="status-nac-left">NAC </div>
                                                                {/*{res?.isPrimaryClient && (*/}
                                                                <div
                                                                    className={`status-nac-right${
                                                                        res.isPrimaryClient === 1
                                                                            ? ' p-status'
                                                                            : res.isPrimaryClient === 0 && ' s-status'
                                                                    }`}
                                                                >
                                                                    {res?.isPrimaryClient == 1
                                                                        ? 'P'
                                                                        : res.isPrimaryClient === 0 && 'S'}
                                                                </div>
                                                                {/*)}*/}
                                                            </div>
                                                        </div>
                                                        <div className="checkbox-child-wrapper">
                                                            <div className="checkbox-child-items-holder">
                                                                <div
                                                                    title={
                                                                        contractType?.length
                                                                            ? ''
                                                                            : 'Select a Contract Type to choose Carrier'
                                                                    }
                                                                    className="checkbox-item"
                                                                >
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            checked={checkingTrue(
                                                                                dates?.startDate1,
                                                                                res?.shippingLineCode,
                                                                                res?.contractId,
                                                                                'firstTwoWeekStartDate'
                                                                            )}
                                                                            disabled={
                                                                                contractType?.length ? false : true
                                                                            }
                                                                            onChange={(e) => {
                                                                                if (e.currentTarget.checked) {
                                                                                    setfilterIndex(index);
                                                                                    shippingDates?.forEach(
                                                                                        (element: any) => {
                                                                                            var newObj = {
                                                                                                shippingLineScacCode:
                                                                                                    res?.shippingLineScacCode,
                                                                                                shippingLineName:
                                                                                                    res?.shippingLineCode,
                                                                                                contractId:
                                                                                                    res?.contractId,
                                                                                                firstTwoWeekStartDate:
                                                                                                    dates?.startDate1,
                                                                                                firstTwoWeekEndDate:
                                                                                                    dates?.endDate1,
                                                                                                secondTwoWeekStartDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.secondTwoWeekStartDate
                                                                                                        ? dates?.startDate2
                                                                                                        : null,
                                                                                                secondTwoWeekEndDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.secondTwoWeekEndDate
                                                                                                        ? dates?.endDate2
                                                                                                        : null,
                                                                                                afterFourWeeksStartDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.afterFourWeeksStartDate
                                                                                                        ? dates?.startDate3
                                                                                                        : null,
                                                                                                afterFourWeeksEndDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.afterFourWeeksEndDate
                                                                                                        ? dates?.endDate3
                                                                                                        : null,
                                                                                            };

                                                                                            if (
                                                                                                element?.shippingLineName ===
                                                                                                res?.shippingLineCode
                                                                                            ) {
                                                                                                setshippingDates([
                                                                                                    newObj,
                                                                                                ]);
                                                                                            } else {
                                                                                                setshippingDates([
                                                                                                    ...shippingDates,
                                                                                                    newObj,
                                                                                                ]);
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    const checkKey =
                                                                                        contractType.includes('NAC')
                                                                                            ? res?.shippingLineScacCode
                                                                                            : res?.shippingLineCode;
                                                                                    const currentFilterIndex =
                                                                                        filterIndex == null
                                                                                            ? 0
                                                                                            : filterIndex;
                                                                                    const contact =
                                                                                        index === currentFilterIndex &&
                                                                                        checkKey;
                                                                                    setshippingLine([
                                                                                        ...shippingLine,
                                                                                        contact,
                                                                                    ]);
                                                                                } else {
                                                                                    shippingDates.forEach(
                                                                                        (element: any) => {
                                                                                            if (
                                                                                                element.shippingLineName ===
                                                                                                res?.shippingLineCode
                                                                                            ) {
                                                                                                var removeData: any = [
                                                                                                    ...shippingDates,
                                                                                                    (element.firstTwoWeekStartDate =
                                                                                                        null),
                                                                                                    (element.firstTwoWeekEndDate =
                                                                                                        null),
                                                                                                ];
                                                                                                removeData =
                                                                                                    removeData.filter(
                                                                                                        function (
                                                                                                            el: any
                                                                                                        ) {
                                                                                                            return (
                                                                                                                el !=
                                                                                                                null
                                                                                                            );
                                                                                                        }
                                                                                                    );
                                                                                                setshippingDates(
                                                                                                    removeData
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    const checkKey =
                                                                                        contractType.includes('NAC')
                                                                                            ? res?.shippingLineScacCode
                                                                                            : res?.shippingLineCode;
                                                                                    const filterarray =
                                                                                        shippingLine.filter(
                                                                                            (res1: any) =>
                                                                                                res1 !== checkKey
                                                                                        );
                                                                                    setshippingLine([...filterarray]);
                                                                                    //rangeSelectFn({});
                                                                                }
                                                                                selectedValue(null);
                                                                                setselectedColumn('');
                                                                            }}
                                                                        ></input>
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use href="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="checkbox-label">
                                                                            Preceding Window
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="checkbox-item child-item">
                                                                    <label className="app-check-wrapper">
                                                                        <div className="checkbox-label">
                                                                            {/* <span className="label-count-value">
                                                                                {res?.teuBalanceFirstTwoWeeks}
                                                                            </span>
                                                                            TEU of {res?.teuTotalFirstTwoWeeks} */}
                                                                            <span className="label-count-value">
                                                                                {res?.teuBalanceFirstTwoWeeks}
                                                                            </span>{' '}
                                                                            TEU {res?.teuCommintmentUnit}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="checkbox-child-items-holder">
                                                                <div
                                                                    title={
                                                                        contractType?.length
                                                                            ? ''
                                                                            : 'Select a Contract Type to choose Carrier'
                                                                    }
                                                                    className="checkbox-item"
                                                                >
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={checkingTrue(
                                                                                dates?.startDate2,
                                                                                res?.shippingLineCode,
                                                                                res?.contractId,
                                                                                'secondTwoWeekStartDate'
                                                                            )}
                                                                            disabled={
                                                                                contractType?.length ? false : true
                                                                            }
                                                                            onChange={(e) => {
                                                                                if (e.currentTarget.checked) {
                                                                                    setfilterIndex(index);
                                                                                    shippingDates?.forEach(
                                                                                        (element: any) => {
                                                                                            var newObj = {
                                                                                                shippingLineScacCode:
                                                                                                    res?.shippingLineScacCode,
                                                                                                shippingLineName:
                                                                                                    res?.shippingLineCode,
                                                                                                contractId:
                                                                                                    res?.contractId,
                                                                                                firstTwoWeekStartDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.firstTwoWeekStartDate
                                                                                                        ? dates?.startDate1
                                                                                                        : null,
                                                                                                firstTwoWeekEndDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.firstTwoWeekEndDate
                                                                                                        ? dates?.endDate1
                                                                                                        : null,
                                                                                                secondTwoWeekStartDate:
                                                                                                    dates?.startDate2,
                                                                                                secondTwoWeekEndDate:
                                                                                                    dates?.endDate2,
                                                                                                afterFourWeeksStartDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.afterFourWeeksStartDate
                                                                                                        ? dates?.startDate3
                                                                                                        : null,
                                                                                                afterFourWeeksEndDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.afterFourWeeksEndDate
                                                                                                        ? dates?.endDate3
                                                                                                        : null,
                                                                                            };
                                                                                            if (
                                                                                                element?.shippingLineName ===
                                                                                                res?.shippingLineCode
                                                                                            ) {
                                                                                                setshippingDates([
                                                                                                    newObj,
                                                                                                ]);
                                                                                            } else {
                                                                                                setshippingDates([
                                                                                                    ...shippingDates,
                                                                                                    newObj,
                                                                                                ]);
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    const checkKey =
                                                                                        contractType.includes('NAC')
                                                                                            ? res?.shippingLineScacCode
                                                                                            : res?.shippingLineCode;
                                                                                    const currentFilterIndex =
                                                                                        filterIndex == null
                                                                                            ? 0
                                                                                            : filterIndex;
                                                                                    const contact =
                                                                                        index === currentFilterIndex &&
                                                                                        checkKey;
                                                                                    setshippingLine([
                                                                                        ...shippingLine,
                                                                                        contact,
                                                                                    ]);
                                                                                } else {
                                                                                    shippingDates.forEach(
                                                                                        (element: any) => {
                                                                                            if (
                                                                                                element.shippingLineName ===
                                                                                                res?.shippingLineCode
                                                                                            ) {
                                                                                                var removeData: any = [
                                                                                                    ...shippingDates,
                                                                                                    (element.secondTwoWeekStartDate =
                                                                                                        null),
                                                                                                    (element.secondTwoWeekEndDate =
                                                                                                        null),
                                                                                                ];
                                                                                                removeData =
                                                                                                    removeData.filter(
                                                                                                        function (
                                                                                                            el: any
                                                                                                        ) {
                                                                                                            return (
                                                                                                                el !=
                                                                                                                null
                                                                                                            );
                                                                                                        }
                                                                                                    );
                                                                                                setshippingDates(
                                                                                                    removeData
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    const checkKey =
                                                                                        contractType.includes('NAC')
                                                                                            ? res?.shippingLineScacCode
                                                                                            : res?.shippingLineCode;
                                                                                    const filterarray =
                                                                                        shippingLine.filter(
                                                                                            (res1: any) =>
                                                                                                res1 !== checkKey
                                                                                        );
                                                                                    setshippingLine([...filterarray]);
                                                                                }
                                                                                selectedValue(null);
                                                                                setselectedColumn('');
                                                                            }}
                                                                            className="checkbox-input"
                                                                        ></input>
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use href="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="checkbox-label">
                                                                            Shipping Window
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="checkbox-item child-item">
                                                                    <label className="app-check-wrapper">
                                                                        <div className="checkbox-label">
                                                                            {/* <span className="label-count-value">
                                                                                {res?.teuBalanceNextTwoWeeks}
                                                                            </span>
                                                                            TEU of {res?.teuTotalNextTwoWeeks} */}
                                                                            <span className="label-count-value">
                                                                                {res?.teuBalanceNextTwoWeeks}
                                                                            </span>{' '}
                                                                            TEU {res?.teuCommintmentUnit}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="checkbox-child-items-holder">
                                                                <div
                                                                    title={
                                                                        contractType?.length
                                                                            ? ''
                                                                            : 'Select a Contract Type to choose Carrier'
                                                                    }
                                                                    className="checkbox-item"
                                                                >
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            checked={checkingTrue(
                                                                                dates?.startDate3,
                                                                                res?.shippingLineCode,
                                                                                res?.contractId,
                                                                                'afterFourWeeksStartDate'
                                                                            )}
                                                                            disabled={
                                                                                contractType?.length ? false : true
                                                                            }
                                                                            onChange={(e) => {
                                                                                if (e.currentTarget.checked) {
                                                                                    setfilterIndex(index);
                                                                                    shippingDates?.forEach(
                                                                                        (element: any) => {
                                                                                            var newObj = {
                                                                                                shippingLineScacCode:
                                                                                                    res?.shippingLineScacCode,
                                                                                                shippingLineName:
                                                                                                    res?.shippingLineCode,
                                                                                                contractId:
                                                                                                    res?.contractId,
                                                                                                firstTwoWeekStartDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.firstTwoWeekStartDate
                                                                                                        ? dates?.startDate1
                                                                                                        : null,
                                                                                                firstTwoWeekEndDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.firstTwoWeekEndDate
                                                                                                        ? dates?.endDate1
                                                                                                        : null,
                                                                                                secondTwoWeekStartDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.secondTwoWeekStartDate
                                                                                                        ? dates?.startDate2
                                                                                                        : null,
                                                                                                secondTwoWeekEndDate:
                                                                                                    element?.shippingLineName ===
                                                                                                        res?.shippingLineCode &&
                                                                                                    element?.secondTwoWeekEndDate
                                                                                                        ? dates?.endDate2
                                                                                                        : null,
                                                                                                afterFourWeeksStartDate:
                                                                                                    dates?.startDate3,
                                                                                                afterFourWeeksEndDate:
                                                                                                    dates?.endDate3,
                                                                                            };
                                                                                            if (
                                                                                                element?.shippingLineName ===
                                                                                                res?.shippingLineCode
                                                                                            ) {
                                                                                                setshippingDates([
                                                                                                    newObj,
                                                                                                ]);
                                                                                            } else {
                                                                                                setshippingDates([
                                                                                                    ...shippingDates,
                                                                                                    newObj,
                                                                                                ]);
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    //rangeSelectFn({
                                                                                    //    startDate: dates?.startDate3,
                                                                                    //    endDate: null,
                                                                                    //});
                                                                                    const checkKey =
                                                                                        contractType.includes('NAC')
                                                                                            ? res?.shippingLineScacCode
                                                                                            : res?.shippingLineCode;
                                                                                    const currentFilterIndex =
                                                                                        filterIndex == null
                                                                                            ? 0
                                                                                            : filterIndex;
                                                                                    const contact =
                                                                                        index === currentFilterIndex &&
                                                                                        checkKey;
                                                                                    setshippingLine([
                                                                                        ...shippingLine,
                                                                                        contact,
                                                                                    ]);
                                                                                } else {
                                                                                    //rangeSelectFn({});
                                                                                    shippingDates.forEach(
                                                                                        (element: any) => {
                                                                                            if (
                                                                                                element.shippingLineName ===
                                                                                                res?.shippingLineCode
                                                                                            ) {
                                                                                                var removeData: any = [
                                                                                                    ...shippingDates,
                                                                                                    (element.afterFourWeeksStartDate =
                                                                                                        null),
                                                                                                    (element.afterFourWeeksEndDate =
                                                                                                        null),
                                                                                                ];
                                                                                                removeData =
                                                                                                    removeData.filter(
                                                                                                        function (
                                                                                                            el: any
                                                                                                        ) {
                                                                                                            return (
                                                                                                                el !=
                                                                                                                null
                                                                                                            );
                                                                                                        }
                                                                                                    );
                                                                                                setshippingDates(
                                                                                                    removeData
                                                                                                );
                                                                                            }
                                                                                        }
                                                                                    );
                                                                                    const checkKey =
                                                                                        contractType.includes('NAC')
                                                                                            ? res?.shippingLineScacCode
                                                                                            : res?.shippingLineCode;
                                                                                    const filterarray =
                                                                                        shippingLine.filter(
                                                                                            (res1: any) =>
                                                                                                res1 !== checkKey
                                                                                        );
                                                                                    setshippingLine([...filterarray]);
                                                                                }
                                                                                selectedValue(null);
                                                                                setselectedColumn('');
                                                                            }}
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                        ></input>
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use href="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="checkbox-label">
                                                                            Succeeding Window
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                                <div className="checkbox-item child-item">
                                                                    <label className="app-check-wrapper">
                                                                        <div className="checkbox-label">
                                                                            {/* <span className="label-count-value">
                                                                                {res?.teuBalanceAfterFourWeeks}
                                                                            </span>
                                                                            TEU of {res?.teuTotalAfterFourWeeks} */}
                                                                            <span className="label-count-value">
                                                                                {res?.teuBalanceAfterFourWeeks}
                                                                            </span>{' '}
                                                                            TEU {res?.teuCommintmentUnit}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                                if (localStorage.getItem('changeVessel') !== id) {
                                                    return newVariable;
                                                } else {
                                                    if (
                                                        localStorage.getItem('changeVessel') === id &&
                                                        localStorage.getItem('selectedShipping') ===
                                                            res?.shippingLineCode
                                                    ) {
                                                        return newVariable;
                                                    }
                                                }
                                            })}
                                    {(contractType?.includes('FAK') || contractType?.length === 0) &&
                                        fakData
                                            ?.filter((s: any) =>
                                                s?.shippingline?.toLowerCase().includes(search?.toLowerCase())
                                            )
                                            .slice(0, sliceValue)
                                            ?.map((res: any) => {
                                                const varNew = (
                                                    <div className="checkbox-status-holder single-item">
                                                        <div
                                                            title={
                                                                contractType?.length
                                                                    ? ''
                                                                    : 'Select a Contract Type to choose Carrier'
                                                            }
                                                            className="checkbox-item"
                                                        >
                                                            <label className="app-check-wrapper">
                                                                <input
                                                                    checked={
                                                                        shippingLine.includes(res?.shippinglineCode)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                    disabled={contractType?.length ? false : true}
                                                                    onChange={(e) => {
                                                                        if (e.currentTarget.checked) {
                                                                            var newArray = [
                                                                                ...shippingLine,
                                                                                res?.shippinglineCode,
                                                                            ];
                                                                            var uniqueArray = newArray.filter(function (
                                                                                item: any,
                                                                                pos: any
                                                                            ) {
                                                                                return newArray.indexOf(item) == pos;
                                                                            });
                                                                            setshippingLine([...uniqueArray]);
                                                                        } else {
                                                                            const filterarray = shippingLine.filter(
                                                                                (res1: any) =>
                                                                                    res1 !== res?.shippinglineCode
                                                                            );
                                                                            setshippingLine([...filterarray]);
                                                                        }
                                                                        selectedValue(null);
                                                                        setselectedColumn('');
                                                                    }}
                                                                    type="checkbox"
                                                                    className="checkbox-input"
                                                                ></input>
                                                                <div className="checkmark">
                                                                    <svg className="svg-icon tick-icon">
                                                                        <use href="#tickIcon">
                                                                            <title>check mark</title>
                                                                        </use>
                                                                    </svg>
                                                                </div>
                                                                <div className="checkbox-label">
                                                                    {res?.shippingline}
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="status-ui confirmed-status">FAK</div>
                                                    </div>
                                                );
                                                if (localStorage.getItem('changeVessel') !== id) {
                                                    return varNew;
                                                } else {
                                                    if (
                                                        localStorage.getItem('changeVessel') === id &&
                                                        localStorage.getItem('selectedShipping') ===
                                                            res?.shippingLineCode
                                                    ) {
                                                        return varNew;
                                                    }
                                                }
                                            })}

                                    {fakData?.filter((s: any) =>
                                        s?.shippingline?.toLocaleLowerCase().includes(search?.toLocaleLowerCase())
                                    )?.length -
                                        sliceValue >
                                        0 &&
                                        (contractType.includes('FAK') || !contractType.includes('NAC')) && (
                                            <div className="checkbox-status-holder single-item">
                                                <a
                                                    className="more-link"
                                                    href="javascript:void(0)"
                                                    onClick={() => setsliceValue(fakData?.length)}
                                                >
                                                    +{fakData?.length - sliceValue} More
                                                </a>
                                            </div>
                                        )}
                                    {checkIsEmpty() && <div className="">No matching results found </div>}
                                </div>
                                {!checkIsEmpty() && (
                                    <div className="filter-types type-1">
                                        <h2 className="filter-type-heading">Service Type</h2>
                                        <div className="filter-type-holder">
                                            <div
                                                title={
                                                    contractType?.length
                                                        ? ''
                                                        : 'Select a Contract Type to choose Carrier'
                                                }
                                                className="checkbox-item"
                                            >
                                                <label className="app-check-wrapper">
                                                    <input
                                                        checked={serviceType.includes('Direct') ? true : false}
                                                        type="checkbox"
                                                        disabled={contractType?.length ? false : true}
                                                        className="checkbox-input"
                                                        onChange={(e) => {
                                                            if (e.currentTarget.checked) {
                                                                var newArray = [...serviceType, 'Direct'];
                                                                var uniqueArray = newArray.filter(function (
                                                                    item: any,
                                                                    pos: any
                                                                ) {
                                                                    return newArray.indexOf(item) == pos;
                                                                });
                                                                setserviceType([...uniqueArray]);
                                                            } else {
                                                                const filterarray = serviceType.filter(
                                                                    (res: any) => res !== 'Direct'
                                                                );
                                                                setserviceType([...filterarray]);
                                                            }
                                                            selectedValue(null);
                                                            setselectedColumn('');
                                                        }}
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">Direct</div>
                                                </label>
                                            </div>
                                            <div
                                                title={
                                                    contractType?.length
                                                        ? ''
                                                        : 'Select a Contract Type to choose Carrier'
                                                }
                                                className="checkbox-item"
                                            >
                                                <label className="app-check-wrapper">
                                                    <input
                                                        checked={serviceType.includes('Transhipment') ? true : false}
                                                        type="checkbox"
                                                        disabled={contractType?.length ? false : true}
                                                        className="checkbox-input"
                                                        onChange={(e) => {
                                                            if (e.currentTarget.checked) {
                                                                var newArray = [...serviceType, 'Transhipment'];
                                                                var uniqueArray = newArray.filter(function (
                                                                    item: any,
                                                                    pos: any
                                                                ) {
                                                                    return newArray.indexOf(item) == pos;
                                                                });
                                                                setserviceType([...uniqueArray]);
                                                            } else {
                                                                const filterarray = serviceType.filter(
                                                                    (res: any) => res !== 'Transhipment'
                                                                );
                                                                setserviceType([...filterarray]);
                                                            }
                                                            selectedValue(null);
                                                            setselectedColumn('');
                                                        }}
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">Multi-Legged</div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    <div className="sidebar-backdrop"></div>
                    <div className="sailing-right-section">
                        <div className="sailing-header-dropdown-wrapper">
                            <div className="content-heading-holder">
                                <span className="title-heading"> Sailing Schedules</span>
                                {/*<span className="modal-toast header-toast">
                                    {Number.isInteger(
                                        (sailingCounts as any)?.next14Days +
                                            (sailingCounts as any)?.next15To28Days +
                                            (sailingCounts as any)?.next29days
                                    )
                                        ? (sailingCounts as any)?.next14Days +
                                          (sailingCounts as any)?.next15To28Days +
                                          (sailingCounts as any)?.next29days
                                        : ''}
                                </span>*/}
                            </div>
                            <span style={{ display: 'flex' }}>
                                {(profileType === 'agent' || profileType === 'forwarder') &&
                                    (bpStatus === 'Draft_Booking_Proposal' ||
                                        bpStatus === 'Rejected_by_Forwarder' ||
                                        bpStatus === 'Rejected_by_Client_Forwarder_to_Action' ||
                                        bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                        <button
                                            className="app-btn export-btn icon-button  app-btn-secondary"
                                            onClick={() =>
                                                navigate(
                                                    `/booking_list/${id}/bookingproposal/SailingSchedule/manual-creation/new_Schedule`
                                                )
                                            }
                                        >
                                            <span className="button-text">Manual Create</span>
                                        </button>
                                    )}
                                {(profileType === 'agent' || profileType === 'forwarder') &&
                                    (bpStatus === 'Draft_Booking' ||
                                        bpStatus === 'Ready_for_Booking' ||
                                        bpStatus === 'Booked') && (
                                        <button
                                            className="app-btn export-btn icon-button  app-btn-secondary"
                                            onClick={() =>
                                                navigate(
                                                    `/booking_list/${id}/bookingproposal/SailingSchedule/change-vessel/manual`
                                                )
                                            }
                                        >
                                            <span className="button-text">Manual Create</span>
                                        </button>
                                    )}
                                <div className="content-filter-dropdown-wrapper">
                                    <a
                                        className={`view-nav grid-icon icon-nav${
                                            serviceType?.length !== 0 ||
                                            shippingLine?.length !== 0 ||
                                            contractType?.length !== 0
                                                ? ' icon-filter'
                                                : ''
                                        }`}
                                        title="Show filter option"
                                        onClick={() => setdisplayFilter(true)}
                                    >
                                        <svg className="svg-icon filter-icon">
                                            <use href="#filterIcon"></use>
                                        </svg>
                                    </a>
                                </div>
                                <div
                                    className={`dropdown-wrap download-dropdown 
						 search-toast-content-holder ${DropDown ? 'dropdown-open' : ''}`}
                                    style={{ marginLeft: '8px' }}
                                >
                                    <button
                                        className="app-btn app-btn-secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDropDown(!DropDown);
                                        }}
                                    >
                                        <div className="purchase-dropdown-content">
                                            <span className="button-text">Sort By : </span>
                                            <div className="dropdown-content-heading">
                                                {sortValue === 'plannedETD'
                                                    ? 'Earliest ETD'
                                                    : sortValue === 'plannedETA'
                                                    ? 'Earliest ETA'
                                                    : 'Fastest Transit Time'}
                                            </div>
                                        </div>

                                        <svg className="svg-icon arrow-icon">
                                            <use href="#downArrow">
                                                <title>Expand row</title>
                                            </use>
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu">
                                        <a
                                            className={`menu-item app-btn ${
                                                sortValue === 'plannedETD' ? 'active' : ''
                                            }`}
                                            onClick={() => sortFn('plannedETD')}
                                        >
                                            Earliest ETD
                                        </a>
                                        <a
                                            className={`menu-item app-btn ${
                                                sortValue === 'plannedETA' ? 'active' : ''
                                            }`}
                                            onClick={() => sortFn('plannedETA')}
                                        >
                                            Earliest ETA
                                        </a>
                                        <a
                                            className={`menu-item app-btn ${
                                                sortValue === 'plannedTransitDays' ? 'active' : ''
                                            }`}
                                            onClick={() => sortFn('plannedTransitDays')}
                                        >
                                            Fastest Transit Time
                                        </a>
                                    </div>
                                </div>
                            </span>
                        </div>
                        <div className="tab-items">
                            <a
                                className={selectedRange.startDate === dates?.startDate1 ? 'tab-nav active' : 'tab-nav'}
                                onClick={() => {
                                    dispatch(listLoadingFn(true));
                                    rangeSelectFn({
                                        startDate: dates?.startDate1,
                                        endDate: dates?.endDate1,
                                    });
                                    setselectedRangeName('first');
                                }}
                            >
                                <div className="schelude-tab-wrapper">
                                    {selectedColumn === dates?.startDate1 && (
                                        <div className="badge-completed">
                                            <svg className="svg-icon success-badge-icon">
                                                <use href="#successBadgeIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                    )}
                                    <div className="content-heading-holder">
                                        <span className="tab-heading">Preceding Window</span>
                                        {countFirst !== null ? (
                                            <span className="modal-toast tab-toast">{hideGrid ? 0 : countFirst}</span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="schelude-date">
                                        <span className="schelude-from-date">
                                            <Moment format="DD-MM-YYYY">{dates?.startDate1?.slice(0, 10)}</Moment>
                                        </span>
                                        to
                                        <span className="schelude-to-date">
                                            <Moment format="DD-MM-YYYY">{dates?.endDate1?.slice(0, 10)}</Moment>
                                        </span>
                                    </div>
                                </div>
                            </a>
                            <a
                                onClick={() => {
                                    dispatch(listLoadingFn(true));
                                    rangeSelectFn({
                                        startDate: dates?.startDate2,
                                        endDate: dates?.endDate2,
                                    });
                                    setselectedRangeName('second');
                                }}
                                className={selectedRange.startDate === dates?.startDate2 ? 'tab-nav active' : 'tab-nav'}
                            >
                                <div className="schelude-tab-wrapper">
                                    {selectedColumn === dates?.startDate2 && (
                                        <div className="badge-completed">
                                            <svg className="svg-icon success-badge-icon">
                                                <use href="#successBadgeIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                    )}
                                    <div className="content-heading-holder">
                                        <span className="tab-heading">Shipping Window</span>
                                        {countSecond !== null ? (
                                            <span className="modal-toast tab-toast">{hideGrid ? 0 : countSecond}</span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="schelude-date">
                                        <span className="schelude-from-date">
                                            <Moment format="DD-MM-YYYY">{dates?.startDate2?.slice(0, 10)}</Moment>
                                        </span>
                                        to
                                        <span className="schelude-to-date">
                                            <Moment format="DD-MM-YYYY">{dates?.endDate2?.slice(0, 10)}</Moment>
                                        </span>
                                    </div>
                                </div>
                            </a>
                            <a
                                onClick={() => {
                                    dispatch(listLoadingFn(true));
                                    rangeSelectFn({
                                        startDate: dates?.startDate3,
                                        endDate: dates?.endDate3,
                                    });
                                    setselectedRangeName('third');
                                }}
                                className={selectedRange.startDate === dates?.startDate3 ? 'tab-nav active' : 'tab-nav'}
                            >
                                <div className="schelude-tab-wrapper">
                                    {selectedColumn === dates?.startDate3 && (
                                        <div className="badge-completed">
                                            <svg className="svg-icon success-badge-icon">
                                                <use href="#successBadgeIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                    )}
                                    <div className="content-heading-holder">
                                        <span className="tab-heading">Succeeding Window</span>
                                        {countThird !== null ? (
                                            <span className="modal-toast tab-toast">{hideGrid ? 0 : countThird}</span>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                    <div className="schelude-date">
                                        <span className="schelude-from-to-date">
                                            {' '}
                                            <Moment format="DD-MM-YYYY">
                                                {dates?.startDate3?.slice(0, 10)}
                                            </Moment> to{' '}
                                            <Moment format="DD-MM-YYYY">{dates?.endDate3?.slice(0, 10)}</Moment>
                                        </span>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <ListGrid
                            data={sailingDataValues}
                            dates={dates}
                            rangeSelectFn={rangeSelectFn}
                            selectedValue={selectedValue}
                            bpStatus={bpStatus}
                            selectedRange={selectedRange}
                            setselectedColumn={setselectedColumn}
                            valueSelected={valueSelected}
                            hideGrid={hideGrid}
                        ></ListGrid>
                    </div>
                </div>
                {/*<SailingScheduleDetailModal></SailingScheduleDetailModal>*/}
            </div>
        );
    } else {
        return <div></div>;
    }
};
export default Index;
