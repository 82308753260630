import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { NavData } from 'src/pages/PoBookingsAir/Wrapper';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import OptionsModal from './OptionsModal';
import {
    deleteAirBPOptions,
    getAirBPOptions,
    submitAirBPOptions,
} from 'src/services/apis/bookingProposalAir/optionsApi';
import DeleteModal from './DeleteModal';
import Footer from './Footer';
import { success, error, Position } from 'src/utils/toast';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';

interface Props {
    navData: NavData[];
}

export const airOptionStatus = [
    'Awaiting_Agent_Options',
    'Awaiting_Client_Acceptance',
    'Awaiting_Forwarder_Review',
    'Options_Rejected_By_Client',
    'Options_Expired',
];

const Index: React.FC<Props> = ({ navData }) => {
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [deleteModalView, setDeleteModalView] = useState(false);
    const [mode, setMode] = useState('');
    const [saving, setSaving] = useState('');
    const [currentSelected, setCurrentSelected] = useState('');
    const [detailData, setDetailData] = useState({});

    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { profileType, userName } = useUserProfile();

    const { bpStatus, tabValues } = useSelector((state: RootState) => state.bpHeaderData);

    const customNavData = navData.map((navItem) => {
        if (navItem.key === 'options') {
            return { ...navItem, selected: 1 };
        } else if (navItem.key === 'flight_schedules') {
            return {
                ...navItem,
                disabled: !(tabValues as any)?.airOptions || airOptionStatus?.includes(bpStatus ?? ''),
            };
        } else if (navItem.key === 'packing') {
            return {
                ...navItem,
                disabled: !(tabValues as any)?.airSchedules || airOptionStatus?.includes(bpStatus ?? ''),
            };
        } else {
            return navItem;
        }
    });

    const refreshGrid = () => {
        const refresh: any = document.querySelector('.k-pager-refresh');
        if (refresh) {
            refresh?.click();
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    };

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    const approveRejectOption = async (type: string) => {
        setSaving(type);
        try {
            if (profileType === 'client') {
                const response = await submitAirBPOptions(id ?? '', 'approve', currentSelected);
                if (response?.status) {
                    success(`Options approved successfully`, Position.TOP_RIGHT);
                    refreshGrid();
                    setTimeout(() => {
                        navigate('/booking_list');
                    }, 2000);
                } else {
                    error('Something went wrong', Position.TOP_RIGHT);
                }
            } else {
                const response = await submitAirBPOptions(id ?? '', '');
                if (response?.status) {
                    success(
                        `Options ${type === 'approve' ? 'submitted' : 'rejected'} successfully`,
                        Position.TOP_RIGHT
                    );
                    refreshGrid();
                    setTimeout(() => {
                        navigate('/booking_list');
                    }, 2000);
                } else {
                    error('Something went wrong', Position.TOP_RIGHT);
                }
            }
        } catch (err: any) {
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        } finally {
            setSaving('');
            setCurrentSelected('');
        }
    };

    //@ts-ignore
    window.addAirOption = () => {
        setMode('add');
        setModalView(true);
    };

    const onDelete = async (ZO_PK: string) => {
        try {
            const response: any = await deleteAirBPOptions(ZO_PK, id ?? '', '');
            if (response?.status) {
                success(`Options deleted successfully`, Position.TOP_RIGHT);
                setModalView(false);
                refreshGrid();
            } else {
                error('Something went wrong', Position.TOP_RIGHT);
            }
        } catch (err: any) {
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    //@ts-ignore
    window.deleteAirOption = async (event: string, ZO_PK: string, status: string, createdUserName: string) => {
        if (status === 'Expired') {
            error('Editing or deleting expired options is not permitted', Position.TOP_RIGHT);
            return;
        }
        if (createdUserName !== userName) {
            error('Deleting option that is created by another user is not permitted', Position.TOP_RIGHT);
            return;
        }
        onDelete(ZO_PK);
        // setDeleteModalView(true);
        // setDetailData({
        //     ZO_PK,
        // });
    };

    //@ts-ignore
    window.selectAirOption = (event: string, ZO_PK: string) => {
        setCurrentSelected(ZO_PK);
    };

    //@ts-ignore
    window.EditAirOption = (event: string, ZO_PK: string, status: string) => {
        if (status === 'Expired') {
            error('Editing or deleting expired options is not permitted', Position.TOP_RIGHT);
            return;
        }
        getAirBPOptions(id ?? '', ZO_PK)
            .then((res: any) => {
                setMode('edit');
                setDetailData({
                    ZO_PK: res?.ZO_PK,
                    ZO_CarrierCode: res?.ZO_CarrierCode,
                    ZO_CarrierName: res?.ZO_CarrierName,
                    ZO_IsDirect: res?.ZO_IsDirect === 'Y' ? true : false,
                    ZO_IFCPreferred: res?.ZO_IFCPreferred === 'Y' ? true : false,
                    ZO_Publish: res?.ZO_Publish === 'Y' ? true : false,
                    ZO_SellCost: res?.ZO_SellCost,
                    ZO_BuyCost: res?.ZO_BuyCost,
                    ZO_BuyCurrency: res?.ZO_BuyCurrency,
                    ZO_SellCurrency: res?.ZO_SellCurrency,
                    ZO_CreatedByUser: res?.ZO_CreatedByUser,
                });
                setModalView(true);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const onGridLoad = () => {
        setTimeout(() => {
            const selected: any = document.querySelector('input[name="selected"]:checked');
            if (selected && selected?.value && bpStatus === 'Awaiting_Client_Acceptance') {
                setCurrentSelected(selected?.value);
            }
        }, 1000);
    };

    return (
        <div className="main-wrapper container" style={{ overflow: 'hidden' }}>
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={`/air_booking_list/${id}/packing`}
                    // thirdName={'Sailing Schedule'}
                    // thirdUrl={`/Aobooking_list/${id}/SailingSelectionDetails`}
                    // fourthName={'Change Vessel'}
                    // fourthUrl={'#'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>{' '}
                {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )}
                <BookingProcessTab navData={customNavData} />
            </div>
            <div className="lifecycle-grid">
                <div className="grid-holder">
                    <div className="grid-header"></div>
                    <LayoutWithColumns
                        style={'lifecycle'}
                        content={'air-bp-options'}
                        customUrl={true}
                        onGridLoad={onGridLoad}
                    />
                </div>
            </div>
            <Footer approveRejectOption={approveRejectOption} saving={saving} currentSelected={currentSelected} />
            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            {modalView ? (
                <OptionsModal
                    setModalView={setModalView}
                    mode={mode}
                    refreshGrid={refreshGrid}
                    detailData={detailData}
                    setDetailData={setDetailData}
                />
            ) : null}
            {deleteModalView ? (
                <DeleteModal setModalView={setDeleteModalView} refreshGrid={refreshGrid} detailData={detailData} />
            ) : null}
        </div>
    );
};
export default Index;
