import React from 'react';

import { deleteSchedule } from 'src/services/apis/bookingProposalAir/addSchedule';
import { Position, success } from 'src/utils/toast';

import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';

interface ModalListProps {
    handleClose: (update?: boolean) => void;
    id: string;
}

const RemoveSchedule = ({ handleClose, id = '' }: ModalListProps) => {
    async function handleRemove() {
        const response = await deleteSchedule(id);
        if (response.success) {
            handleClose(true);
            success(`Schedule deleted successfully`, Position.TOP_RIGHT);
        } else {
            success(response.message, Position.TOP_RIGHT);
            handleClose();
        }
    }
    return (
        <div className="app-modal purchase-detail-modal">
            <div className="modal-content-holder ">
                <div className="modal-header">
                    <div className="title">Remove Schedule</div>
                    <button onClick={() => handleClose()} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content">Are you sure to remove schedule?</div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => handleClose()}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        // title="No"
                    >
                        <span className="button-text footer-button-text">No</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        onClick={() => handleRemove()}
                        // title="Yes"
                    >
                        <span className="button-text footer-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default RemoveSchedule;
