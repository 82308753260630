/* eslint-disable no-undefined */
import React from 'react';
import { FilterList, Type } from './enums';
import { getOrganisationData } from 'src/utils';
import {
    fetchDashboardActiveBookings,
    fetchDashboardActiveBookingsExpanded,
    fetchDashboardActiveOrders,
    fetchDashboardActiveOrdersExpanded,
} from 'src/store/reducers/dashboard/insightsReducer';
import { convertDateFormat, startDateCalculation } from 'src/pages/Dashboard/Insights/startDateCalculation';
// import { useUserProfile } from 'src/hooks/useUserProfile';

export const makeApiCall = (
    dispatch: React.Dispatch<any>,
    modeValue: string | any,
    currentFilterValue: string | any,
    destinationTypeValue: string | any,
    typeOfFetch: string,
    typeVal?: string | any,
    key?: string,
    searchItem?: any,
    searchType?: string | null
) => {
    let roleProfile: string;
    const listOfUserOrganisations = getOrganisationData();
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    const userDetail = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = userDetail?.user?.us_UserName;
    // const { roleName } = useUserProfile();
    if (userData && roleIndex) {
        const { roles } = JSON.parse(userData);
        roleProfile = roles[roleIndex].profile?.toLowerCase();
        const roleName = roles[roleIndex]?.roleName;
        const getOrganizationProfileData = (
            typeOfSearch: string | null | undefined,
            searchedItemCode: string | null | undefined
        ) => {
            if (typeOfSearch) {
                if (typeOfSearch === 'Clients') {
                    return {
                        client: [{ orgCode: searchedItemCode }],
                    };
                } else if (typeOfSearch === 'Agents') {
                    return {
                        agent: [{ orgCode: searchedItemCode }],
                    };
                } else if (typeOfSearch === 'Carriers') {
                    return {
                        sl: [{ orgCode: searchedItemCode }],
                    };
                } else if (typeOfSearch === 'Vendors') {
                    return {
                        vendor: [{ orgCode: searchedItemCode }],
                    };
                }
            }
            return listOfUserOrganisations;
        };

        const payload = {
            mode: modeValue?.length > 0 ? modeValue : undefined,
            destinationType: destinationTypeValue?.length > 0 ? destinationTypeValue : undefined,
            dateFilterType:
                currentFilterValue === FilterList.Days
                    ? 'days'
                    : currentFilterValue === FilterList.Months
                    ? 'month'
                    : currentFilterValue === FilterList.Weeks
                    ? 'week'
                    : undefined,
            type:
                typeOfFetch === 'activeOrder' || typeOfFetch === 'activeBooking'
                    ? undefined
                    : typeVal === Type.ao
                    ? 'AO'
                    : typeVal === Type.itpo
                    ? 'ITPO'
                    : typeVal === Type.ibpo
                    ? 'IBPO'
                    : typeVal === Type.po
                    ? 'PO'
                    : undefined,
            startDate: startDateCalculation(currentFilterValue),
            endDate: convertDateFormat(new Date()),
            ...getOrganizationProfileData(searchType, searchItem?.code),
            isOrgCodeSearch: searchItem?.code && roleName === 'executive' ? true : false,
            profileName: roleProfile ? roleProfile : undefined,
            loggedInUserName: username ? username : undefined,
            aggregateKey: key ? key : undefined,
            currentFilterValue,
            searchItem:
                searchType === 'Agents' ||
                searchType === 'Clients' ||
                searchType === 'Vendors' ||
                searchType === 'Carriers'
                    ? undefined
                    : searchItem?.poId || searchItem?.bpId,
        };

        dispatch(
            typeOfFetch === 'activeOrdersDetails'
                ? fetchDashboardActiveOrdersExpanded(payload)
                : typeOfFetch === 'activeOrder'
                ? fetchDashboardActiveOrders(payload)
                : typeOfFetch === 'activeBooking'
                ? fetchDashboardActiveBookings(payload)
                : fetchDashboardActiveBookingsExpanded(payload)
        );
    }
};
