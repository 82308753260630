import { post } from '../../HttpMethods';
import fileDownload from 'js-file-download';

export interface receiptData {
    receiptId: string;
    receiptNo: string;
    status: string;
    plannedCustClearanceDate: string;
    actualCustClearanceDate: string;
    plannedGoodsRecvdDate: string;
    actualGoodsRecvdDate: string;
    clientName: string;
    vendorName: string;
    createdUser: string;
}

export interface receiptListResponse {
    content: receiptData[];
    totalPages: number;
    totalElements: number;
}

export interface receiptSearchData {
    content: receiptData[];
    totalPages: number;
    totalElements: number;
}

// Get All Datas
export const getReceiptListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    profileType: string;
}) => {
    try {
        const res = await post(
            `api/ord/blp/receipts/list/${payload.profileType}?size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getReceiptFilterValues = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/ord/blp/receipts/distinct/${payload.profileType}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getReceiptStatusCount = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/ord/blp/receipts/statuscount/${payload.profileType}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const exportReceiptList = async (type: string, organisationData: any, profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(`${process.env.REACT_APP_BASE_URL}/api/ord/blp/receipts/export/${profileType}?type=${type}`, {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(organisationData),
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'Receipts.xlsx' : 'Receipts.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
