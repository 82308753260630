/* eslint-disable no-undefined */
/* eslint-disable camelcase */
import React, { useState } from 'react';
import 'src/assets/scss/components/_modal.scss';
import 'src/assets/scss/components/_grid.scss';
import '../../Profiles/Clients/ProductMaster/product-master-modal.scss';
import { success, error, Position } from 'src/utils/toast';
import { addConfigDetails, editConfigDetails } from 'src/services/apis/quoteAPi';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useParams } from 'react-router-dom';

type HeaderProps = {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    mode: string;
    refreshGrid: any;
    detailData: any;
    setDetailData: any;
    headerData: any;
};

const DetailModal: React.FC<HeaderProps> = ({
    setModalView,
    mode,
    refreshGrid,
    detailData,
    setDetailData,
    headerData,
}) => {
    const initialData = { CD_Key: '', CD_Value: '', CD_CH_Key: '', CD_Description: '', CD_Active: true };
    const [saving, setSaving] = useState(false);
    const onClose = () => {
        setModalView(false);
        setDetailData(initialData);
    };

    const { type } = useParams();

    const onSubmit = async () => {
        let response;
        setSaving(true);
        try {
            const payload = {
                CD_Key: detailData.CD_Key,
                CD_Value: detailData.CD_Value,
                CD_CH_Key: detailData.CD_CH_Key,
                CD_Description: detailData.CD_Description,
                CD_Active: detailData.CD_Active,
            };
            if (mode === 'add') {
                response = await addConfigDetails(payload);
            } else {
                response = await editConfigDetails(payload);
            }
            if (response?.status) {
                success(`Config Detail ${mode === 'add' ? 'created' : 'updated'} successfully`, Position.TOP_RIGHT);
                setModalView(false);
                refreshGrid();
                setDetailData(initialData);
            } else {
                error('Something went wrong', Position.TOP_RIGHT);
            }
        } catch (err: any) {
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        } finally {
            setSaving(false);
        }
    };

    return (
        <div className="app-modal medium-popup notes-modal">
            <div className="modal-content-holder sop-content-holder medium-popup">
                <div className="modal-header">
                    <h2 className="title">{mode === 'add' ? 'Add' : 'Edit'} Config Detail</h2>
                    <button onClick={() => onClose()} className="app-btn modal-close-btn" title="Close">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content details-box">
                        <div className="input-form-wrapper">
                            <div className="modal-col-top-1">
                                <div className=" modal-input-holder">
                                    <label htmlFor="hsCode" className="modal-input-label">
                                        Header Key<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-field-holder text-area-holder">
                                        <SearchableSelectBox
                                            data={headerData?.filter((h: any) => h.value === type)}
                                            placeholder="Choose one item"
                                            stateValue={detailData.CD_CH_Key}
                                            setStateValue={(value: string) => {
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    CD_CH_Key: value,
                                                }));
                                            }}
                                            fieldName={'CD_CH_Key'}
                                            menuPortalTarget={document.getElementById('outside_div')}
                                            isClearable={false}
                                            isDisabled={mode === 'edit' ? true : false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="input-form-wrapper">
                            <div className="modal-col-top-1">
                                <div className=" modal-input-holder">
                                    <label htmlFor="hsCode" className="modal-input-label">
                                        Key<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-field-holder text-area-holder">
                                        <input
                                            placeholder="Enter Key"
                                            type="text"
                                            className="modal-input input-text with-border"
                                            id="CD_Key"
                                            name="CD_Key"
                                            value={detailData.CD_Key}
                                            onChange={(e) =>
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    CD_Key: e.target.value?.toUpperCase(),
                                                }))
                                            }
                                            disabled={mode === 'edit' ? true : false}
                                            maxLength={3}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="input-form-wrapper">
                            <div className="modal-col-top-1">
                                <div className=" modal-input-holder">
                                    <label htmlFor="hsCode" className="modal-input-label">
                                        Value<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-field-holder text-area-holder">
                                        <input
                                            placeholder="Enter Value"
                                            type="text"
                                            className="modal-input input-text with-border"
                                            id="CD_Value"
                                            name="CD_Value"
                                            value={detailData.CD_Value}
                                            onChange={(e) =>
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    CD_Value: e.target.value,
                                                }))
                                            }
                                            maxLength={50}
                                        ></input>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="input-form-wrapper">
                            <div className="modal-col-bottom">
                                <div className=" modal-input-holder" style={{ paddingBottom: '16px' }}>
                                    <label htmlFor="name" className="modal-input-label">
                                        Long Description
                                        <span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-field-holder text-area-holder">
                                        <textarea
                                            style={{ width: '100%' }}
                                            name="description"
                                            placeholder="Enter Long Description"
                                            rows={7}
                                            onChange={(e) =>
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    CD_Description: e.target.value,
                                                }))
                                            }
                                            className="input-text"
                                            value={detailData.CD_Description}
                                            maxLength={2000}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="input-form-wrapper">
                            <div className="modal-col-bottom">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            name="isMandatory"
                                            checked={detailData.CD_Active}
                                            onChange={(e) => {
                                                setDetailData((prev: any) => ({
                                                    ...prev,
                                                    CD_Active: e.target.checked,
                                                }));
                                            }}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon notification-circle-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Active</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => onClose()}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Cancel"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        onClick={() => onSubmit()}
                        className="app-btn app-btn-primary footer-btn"
                        title={saving ? 'Saving...' : 'Submit'}
                        type="button"
                        disabled={
                            detailData?.CD_Key &&
                            detailData?.CD_Value &&
                            detailData?.CD_CH_Key &&
                            detailData?.CD_Description &&
                            !saving
                                ? false
                                : true
                        }
                    >
                        <span className="button-text footer-button-text">{saving ? 'Saving...' : 'Submit'}</span>
                    </button>
                </div>
            </div>
            <div id="outside_div"></div>
        </div>
    );
};

export default DetailModal;
