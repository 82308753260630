import React from 'react';
// import './style.css';
import '../../../../assets/scss/components/_modal.scss';
import { useDispatch } from 'react-redux';
import { unlinkVendor } from '../../../../store/reducers/vendorReducer';

interface BioProps {
    displayUnlink: boolean;
    displayUnlinkFun: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    unlinkData: any;
    clientId: any;
    payload: any;
    data: any;
    setfailedToast: any;
}

const Index: React.FC<BioProps> = (props) => {
    const dispatch = useDispatch();
    const payload = {
        client_id: props.clientId,
        vendor_id: props.unlinkData.vn_PK,
    };
    const finalPayload = { ...payload, ...props.payload };
    return (
        <>
            {props.displayUnlink ? (
                <div className="app-modal container">
                    <div className="modal-content-holder">
                        <div className="modal-header">
                            <h2 className="title">Unlink Vendor</h2>
                            <button className="app-btn modal-close-btn" onClick={() => props.displayUnlinkFun(false)}>
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                <span className="highlight-content"> {props.unlinkData.vn_vendorname}</span> will be
                                unliked from the Client profile. Are you sure you want to continue?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn"
                                onClick={() => props.displayUnlinkFun(false)}
                            >
                                <span className="button-text vendor-button-text">Cancel</span>
                            </button>
                            <button
                                style={{ marginLeft: '8px' }}
                                className="app-btn app-btn-primary modal-btn"
                                onClick={() => {
                                    props.displayUnlinkFun(false);
                                    dispatch(unlinkVendor(finalPayload));
                                }}
                            >
                                <span className="button-text vendor-button-text">Unlink</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
