/* eslint-disable @typescript-eslint/no-non-null-assertion */
import axios from 'axios';

import { CustomPolicyRequest, forgotPasswordRequest, loginRequest, passwordChangeRequest } from '../../authConfig';
import { msalInstance } from '../../index';
import { updateAccessToken } from '../apis/authApi';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { checkHealth } from 'src/utils/checkHealth';
// import { Position, error } from '../../utils/toast';

let baseURL = process.env.REACT_APP_BASE_URL;
const trackingBaseURL = process.env.REACT_APP_TRACKING_BASE_URL;
const FORGOT_PASSWORD_POLICY_NAME = process.env.REACT_APP_FORGOT_PASSWORD_POLICY_NAME!;
const PASSWORD_CHANGE_POLICY_NAME = process.env.REACT_APP_PASSWORD_CHANGE_POLICY_NAME!;
// const baseURL = 'https://jsonplaceholder.typicode.com/';
// const baseURL = 'https://pokeapi.co/api/v2/';

// const token = localStorage.getItem('token');

// eslint-disable-next-line
let decoded: any;
let cancelToken: any;

const base = async (options: any, headerOptions: any, newBaseUrl?: string | null, cancelTokenStatus?: boolean) => {
    // eslint-disable-next-line no-undefined
    if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel('Operation canceled due to new request.');
    }
    cancelToken = axios.CancelToken.source();
    const account = msalInstance.getActiveAccount();
    if (!account) {
        // msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
        // throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
        return;
    }

    let request: CustomPolicyRequest;
    if (account.homeAccountId.includes(FORGOT_PASSWORD_POLICY_NAME)) {
        request = forgotPasswordRequest;
    } else if (account.homeAccountId.includes(PASSWORD_CHANGE_POLICY_NAME)) {
        request = passwordChangeRequest;
    } else {
        request = loginRequest;
    }

    try {
        const response = await msalInstance.acquireTokenSilent({
            ...request,
            account: account,
        });
        localStorage.setItem('lastApiRequest', moment().unix().toString());

        if (!response?.fromCache) {
            try {
                await updateAccessToken(localStorage.getItem('token'), response?.accessToken);
            } catch (err: any) {
                console.log(err, 'err');
                if (err?.data?.status === 'FORBIDDEN') {
                    // setTimeout(() => {
                    localStorage.clear();
                    msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
                    // }, 1500);
                    // error(err?.data?.message, Position.TOP_RIGHT);
                }
            }
        }
        const token = response?.accessToken;

        // if (account.homeAccountId.includes(LOGIN_POLICY_NAME)) {
        decoded = jwt_decode(token);

        if (decoded?.extension_passwordUpdated && options.url === 'api/auth/login') {
            options.url = 'api/auth/login?reset_pwd=true';
        }
        // }
        //baseUrl for tracking map apis from client
        if (options.url.includes('/OrderTracking')) {
            baseURL = trackingBaseURL;
        }
        localStorage.setItem('token', token);
        return axios({
            baseURL: newBaseUrl ? newBaseUrl : baseURL,
            headers: {
                // 'Access-Control-Allow-Origin': '*',
                Authorization: token ? `Bearer ${token}` : null,
                // redirect: 'follow',
                ...headerOptions,
            },
            ...options,
            timeout: newBaseUrl ? 120000 : 180000,
            cancelToken:
                (options?.method === 'post' &&
                    options?.url?.includes('api/ord/bp/listing/') &&
                    options?.data?.search) ||
                (options?.url?.includes('api/ord/po/list/') && options?.data?.poSearch) ||
                // options?.url?.includes(`api/ord/tracking/lifecycle/search?profile`) ||
                // options?.url?.includes(`api/bkg/tracking/lifecycle/search?profile`) ||
                // options?.url?.includes(`api/mgt/tracking/lifecycle/search?profile`) ||
                cancelTokenStatus
                    ? cancelToken.token
                    : '',
        }).then((res) => res.data);
        // .catch((err) => {
        //     console.log(err, 'err');
        //     checkHealth(err);
        // });
    } catch (error: any) {
        console.log(error, 'error');
        if (error && error?.toString()?.includes('AADB2C90077')) {
            localStorage.clear();
            return msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
        } else {
            return msalInstance.acquireTokenRedirect(loginRequest);
        }
        // localStorage.clear();
        // msalInstance.logoutRedirect({ postLogoutRedirectUri: '/' });
        // throw Error('No active account! Verify a user has been signed in and setActiveAccount has been called.');
    }
};

axios.interceptors.response.use(
    (response) => response,
    (error) => {
        checkHealth(error);
        return Promise.reject(error);
    }
);
// eslint-disable-next-line
export const get = (url: string, params: any, newBaseUrl?: string | null, cancelTokenStatus?: boolean) => {
    const options = {
        method: 'get',
        url,
        params,
    };
    return base(options, null, newBaseUrl, cancelTokenStatus);
};
// eslint-disable-next-line
export const patch = (url: string, data: any, newBaseUrl?: string | null) => {
    const options = {
        method: 'patch',
        url,
        data,
    };
    return base(options, null, newBaseUrl);
};
// eslint-disable-next-line
export const post = (
    url: string,
    data: any,
    headerOptions: any,
    cancelTokenStatus?: boolean,
    newBaseUrl?: string | null
) => {
    const options = {
        method: 'post',
        url,
        data,
    };
    return base(options, headerOptions, newBaseUrl, cancelTokenStatus);
};

// eslint-disable-next-line
export const put = (url: string, data: any) => {
    const options = {
        method: 'put',
        url,
        data,
    };
    return base(options, null);
};
// eslint-disable-next-line
export const del = (url: string, data: any, newBaseUrl?: string | null) => {
    const options = {
        method: 'delete',
        url,
        data,
    };
    return base(options, null, newBaseUrl);
};
