/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export interface ShippingLineOrganisationContactListDataProp {
    oc_contactName: string;
    oc_title: string;
    oc_phonenumber: string;
    oc_mobilenumber: string;
    oc_email: string;
    oc_jc: string;
    oc_PK: string;
}

export interface ShippingLineUsersDataProp {
    us_Azure_Key: string;
    us_UT: string;
    us_TokenValue: string;
    us_State: string;
    us_AzureUserId: string;
    us_PK: string;
    us_Date_Of_Birth: string;
    us_PhoneNo: string;
    us_UserName: string;
    us_Address1: string;
    us_MiddleName: string;
    us_Postal_Code: string;
    us_Address_Type: string;
    us_City: string;
    us_LastName: string;
    us_FirstName: string;
    us_LandLineNo: string;
    us_Email: string;
    us_Address2: string;
    us_Country: string;
    us_Address3: string;
    us_Role: string;
}

export interface ShippingLineUsersListDataProp {
    [key: string]: ShippingLineUsersDataProp[];
}

export interface ShippingLineForwarderRankingDataProp {
    rankingCode: string;
    rank: string;
    ir_PK: string;
}

export interface ShippingLineAddressListDataProp {
    addressline1: string;
    addressline2: string;
    addressline3: string;
    address_type: string;
    city: string;
    state: string;
    postcode: string;
    countrycode: string;
    contact_email: string;
    contact_mobno: string;
    contact_person: string;
    closest_unlocode_portcode: string;
    oa_PK: string;
}

export interface ShippingLineOrgDataProp {
    addressList: ShippingLineAddressListDataProp[];
    or_Phone: string;
    or_ABN: number;
    or_Unloco: string;
    or_WebSiteUrl: string;
    or_ForwarderRanking: ShippingLineForwarderRankingDataProp;
    or_PK: string;
    or_UsersList: ShippingLineUsersListDataProp;
    or_Orgname: string;
    or_Orgcode: string;
    or_Status: string;
    or_Orgtype: string;
    or_OrganisationContactList: ShippingLineOrganisationContactListDataProp[];
    or_ParentOrgCode: string;
}
export interface ShippingLineBasicDetailsDataProp {
    oceanCarrierName: string;
    shippingLineCode: string;
    scac: string;
    seaCarrier: string;
    carrierCategory: string;
    sl_Status: string;
    sl_PK: string;
    sl_Org: ShippingLineOrgDataProp;
}

interface ShippingLineBasicDetailsProp {
    status: number;
    message: string;
    info: ShippingLineBasicDetailsDataProp;
    data: any;
}

interface ShippingLineBasicDetailsState {
    isLoading: boolean;
    basicDetails?: ShippingLineBasicDetailsProp;
    ForwarderRank?: any;
    basicDetailsSuccess: any;
    basicDetailsError: any;
    isError?: boolean;
    id?: number;
    carrierHeader: any;
}

const initialState: ShippingLineBasicDetailsState = {
    isLoading: true,
    basicDetails: undefined,
    ForwarderRank: undefined,
    basicDetailsSuccess: undefined,
    basicDetailsError: undefined,
    isError: false,
    id: undefined,
    carrierHeader: [],
};

export const shippingLineBasicDetailsSlice = createSlice({
    name: 'shippingLineBasicDetails',
    initialState,
    reducers: {
        fetchShippingLineBasicDetailsList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchShippingLineBasicDetailsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.basicDetails = action.payload;
            state.basicDetailsSuccess = {};
            state.basicDetailsError = {};
        },
        fetchShippingLineBasicDetailsListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsSuccess = {};
            state.basicDetailsError = action.payload;
        },
        fetchForwarderRankList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchForwarderRankListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.ForwarderRank = action.payload;
        },
        fetchForwarderRankListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsError = action.payload;
        },
        updateForwarderRank: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        updateForwarderRankSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.basicDetailsSuccess = action.payload;
        },
        updateForwarderRankFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsError = action.payload;
        },
        fetchHeaderDataCarriers: (_state, _action) => {
            // console.log(state);
        },
        saveHeaderDataCarriers: (state, action) => {
            state.carrierHeader = action.payload?.info;
        },
    },
});

export const {
    fetchShippingLineBasicDetailsList,
    fetchShippingLineBasicDetailsListSuccess,
    fetchShippingLineBasicDetailsListFailed,
    fetchForwarderRankList,
    fetchForwarderRankListSuccess,
    fetchForwarderRankListFailed,
    updateForwarderRank,
    updateForwarderRankSuccess,
    updateForwarderRankFailed,
    saveHeaderDataCarriers,
    fetchHeaderDataCarriers,
} = shippingLineBasicDetailsSlice.actions;

export const selectShippingLineBasicDetailsLoading = (state: RootState) => state.shippingLineBasicDetailsData.isLoading;
export const selectShippingLineBasicDetails = (state: RootState) => state.shippingLineBasicDetailsData.basicDetails;
export const selectShippingLineBasicDetailsSuccess = (state: RootState) =>
    state.shippingLineBasicDetailsData.basicDetailsSuccess;
export const selectShippingLineBasicDetailsIsError = (state: RootState) => state.shippingLineBasicDetailsData.isError;
export const selectShippingLineBasicDetailsError = (state: RootState) =>
    state.shippingLineBasicDetailsData.basicDetailsError;
export const selectForwarderRank = (state: RootState) => state.shippingLineBasicDetailsData.ForwarderRank;

export default shippingLineBasicDetailsSlice.reducer;
