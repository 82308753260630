import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchReassignApprovalList,
    fetchReassignApprovalListSuccess,
    agentApproval,
    agentApprovalSuccess,
    agentApprovalFailure,
    agentRejection,
    agentRejectionSuccess,
    agentRejectionFailure,
    fetchReassignApprovalDistinctList,
    fetchReassignApprovalDistinctListSuccess,
} from '../../reducers/reassignAgent/approvalReducer';
import {
    fetchReassignApprovalListDetailsApi,
    fetchReassignApprovalDistinctListDetailsApi,
    approveAgentPoApi,
    approveAgentBpApi,
    rejectAgentPoApi,
    rejectAgentBpApi,
} from '../../../services/apis/reassignAgent/approvalApi';

function* getReassignApprovalDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchReassignApprovalListDetailsApi, payload);
        yield put({ type: fetchReassignApprovalListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignApprovalListFailed, payload: error });
    }
}
function* getReassignApprovalDistinctDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchReassignApprovalDistinctListDetailsApi, payload);
        yield put({ type: fetchReassignApprovalDistinctListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignApprovalListFailed, payload: error });
    }
}

function* approveAgent({ payload }: { payload: any; type: any }) {
    try {
        const newPayload = {
            bps: payload.data.map((res: any) => res.bpNumber),
            pos: payload.data.map((res: any) => [...res.poNumber])?.flat(Infinity),
            newAgentCode: payload.agentCode[0],
            ...payload,
        };
        // const request = [call(approveAgentPoApi, newPayload)];
        // const approvalWithBp = payload.data.filter((res: any) => res.bpNumber);
        // if (approvalWithBp.length) {
        //     request.push(call(approveAgentBpApi, newPayload));
        // }
        // const response: AxiosResponse = yield all(request);
        // yield put({ type: agentApprovalSuccess, payload: response });
        const approvalWithBp = payload.data.filter((res: any) => res.bpNumber);
        if (approvalWithBp.length) {
            const response: AxiosResponse = yield call(approveAgentBpApi, newPayload);
            yield put({ type: agentApprovalSuccess, payload: response });
        } else {
            const response: AxiosResponse = yield call(approveAgentPoApi, newPayload);
            yield put({ type: agentApprovalSuccess, payload: response });
        }
    } catch (error) {
        yield put({ type: agentApprovalFailure, payload: error });
    }
}
function* rejectAgent({ payload }: { payload: any; type: any }) {
    try {
        const newPayload = {
            bps: payload.data.map((res: any) => res.bpNumber),
            pos: payload.data.map((res: any) => res.poNumber)?.flat(Infinity),
            ...payload,
        };
        // const request = [call(rejectAgentPoApi, newPayload)];
        // const approvalWithBp = payload.data.filter((res: any) => res.bpNumber);
        // if (approvalWithBp.length) {
        //     request.push(call(rejectAgentBpApi, newPayload));
        // }
        // const response: AxiosResponse = yield all(request);
        // yield put({ type: agentRejectionSuccess, payload: response });
        const approvalWithBp = payload.data.filter((res: any) => res.bpNumber);
        if (approvalWithBp.length) {
            const response: AxiosResponse = yield call(rejectAgentBpApi, newPayload);
            yield put({ type: agentRejectionSuccess, payload: response });
        } else {
            const response: AxiosResponse = yield call(rejectAgentPoApi, newPayload);
            yield put({ type: agentRejectionSuccess, payload: response });
        }
    } catch (error) {
        yield put({ type: agentRejectionFailure, payload: error });
    }
}

function* reassignApprovalSaga() {
    yield takeEvery(fetchReassignApprovalList, getReassignApprovalDetails);
    yield takeEvery(fetchReassignApprovalDistinctList, getReassignApprovalDistinctDetails);
    yield takeEvery(agentApproval, approveAgent);
    yield takeEvery(agentRejection, rejectAgent);
}

export default reassignApprovalSaga;
