import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'attachmentsao',
    initialState: {
        isLoading: false,
        files: {},
        errorStat: false,
        errorMessage: '',
    },
    reducers: {
        fetchFiles: (_state, _action) => {
            // console.log(action);
        },
        saveFiles: (state, action) => {
            state.files = action.payload;
        },
        uploadFiles: (_state, _action) => {
            // console.log(action);
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        removeSingleFile: (_state, _action) => {
            // console.log(action);
        },
        downloadSingle: (_state, _action) => {
            // console.log(action);
        },
        downloadAll: (_state, _action) => {
            // console.log(action);
        },
        setWarning: (state, action) => {
            state.errorMessage = action.payload.message;
            state.errorStat = action.payload.errorStat;
        },
    },
});

export const {
    isLoadingFn,
    fetchFiles,
    saveFiles,
    uploadFiles,
    removeSingleFile,
    downloadSingle,
    downloadAll,
    setWarning,
} = dataSlice.actions;
export default dataSlice.reducer;
