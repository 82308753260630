import React from 'react';
// import './style.css';
import 'src/assets/scss/components/_modal.scss';

interface BioProps {
    data: any;
}

const Index: React.FC<BioProps> = (props) => {
    return (
        <>
            <div>
                <div className="details-col distributed-col">
                    <div className="box-content-holder sku-content-holder">
                        <h6 className="details-box-heading">SKU</h6>
                        <div className="details-box-content">{props.data?.pm_sku}</div>
                    </div>
                    <div className="box-content-holder description-content-holder">
                        <h6 className="details-box-heading">Description</h6>
                        <div className="details-box-content">{props.data?.pm_description}</div>
                    </div>
                    <div className="box-content-holder hscode-content-holder">
                        <h6 className="details-box-heading">Code</h6>
                        <div className="details-box-content">{props.data?.pm_purchasecode}</div>
                    </div>
                    <div className="box-content-holder fumigation-content-holder">
                        <h6 className="details-box-heading">Fumigation Required</h6>
                        <div className="details-box-content">
                            <p>{props.data?.pm_fumigationrequired ? 'Yes' : 'No'} </p>
                        </div>
                    </div>
                </div>
                <div className="details-col vendor-details-col">
                    <div className="box-content-holder">
                        <h6 className="details-box-heading">Vendors</h6>
                        {props.data?.pm_vendors === null ? (
                            'No vendors'
                        ) : (
                            <div className="details-box-content suggestion-container">
                                {props.data?.pm_vendors?.map((vendors: any) => (
                                    <div className="content-card read-only">{vendors?.vn_vendorname}</div>
                                ))}
                            </div>
                        )}
                    </div>
                    {/* added for commodity screen sprint11 */}
                    {props.data?.pm_type === 'Commodity' && (
                        <div className="box-content-holder Dangerous-good-content-holder">
                            <h6 className="details-box-heading">Dangerous Good</h6>
                            <div className="details-box-content">
                                <p>{props.data?.pm_dangerousgood ? 'Yes' : 'No'} </p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Index;
