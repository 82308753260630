import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import {
    // bookingTimeLineFetch,
    bookingTimeLineFetchMbl,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import '../lcsBookingTiimeline.scss';
import MaxLimitModal from '../MaxLimitModal';
// import { getDateDifference } from '../../../../../utils';
import { useUserProfile } from 'src/hooks/useUserProfile';
// import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
// import { useNavigate } from 'react-router-dom';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { RootState } from 'src/store/reducers';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { lifeCycleSearchCategories } from './Common/constants';

const Index: React.FC = () => {
    const { type } = useParams();
    const dispatch = useDispatch();
    const location = useLocation() as any;
    // const navigate = useNavigate();
    const { userName, profileType } = useUserProfile();
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (type === 'master-bill') {
            dispatch(bookingTimeLineFetchMbl({ mblnumber: location?.state?.name }));
        }
    }, [location.state.name]);

    const { bookingTimelineMbl, selectedData } = useSelector((state: any) => state.lifeCycleSearch);
    const { favList, removeSuccess, addSuccess, isLoading, isError, error } = useSelector(
        (state: RootState) => state.lifecyclesearchFav
    );

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.mbl,
                    favourite: 'Master Bill Number',
                    number: location.state?.name,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    //current status position by checking date null

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">Master Bill Number</div>
                <div className="timeline-head-value">{location?.state?.name}</div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="lcs-timeline-wrapper">
                <div className="cm-key-pair-details">
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Origin</div>
                        <div className="cm-key-pair-value">{bookingTimelineMbl?.info?.originPort || '-'}</div>
                    </div>
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Destination</div>
                        <div className="cm-key-pair-value">{bookingTimelineMbl?.info?.destinationPort || '-'}</div>
                    </div>
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Count of Bookings</div>
                        <div className="cm-key-pair-value">
                            {bookingTimelineMbl?.info?.bpCount >= 0 ? bookingTimelineMbl?.info?.bpCount : '-'}
                        </div>
                    </div>
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Count of Orders</div>
                        <div className="cm-key-pair-value">
                            {bookingTimelineMbl?.info?.orderCount >= 0 ? bookingTimelineMbl?.info?.orderCount : '-'}
                        </div>
                    </div>

                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Count of SKUs</div>
                        <div className="cm-key-pair-value">
                            {bookingTimelineMbl?.info?.skuCount >= 0 ? bookingTimelineMbl?.info?.skuCount : '-'}
                        </div>
                    </div>
                </div>
                <div>
                    {/* <div>bpcount: {bookingTimelineMbl?.info?.bpcount}</div>
                    <div>Destination: {bookingTimelineMbl?.info?.destinationPort}</div>
                    <div>Order Count: {bookingTimelineMbl?.info?.orderCount}</div>
                    <div>Origin: {bookingTimelineMbl?.info?.originPort}</div>
                    <div>sku count: {bookingTimelineMbl?.info?.skuCount}</div> */}
                </div>
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`Master Bill Number : ${location.state?.name}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
