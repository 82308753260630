import React from 'react';

export interface TileItem {
    count: number;
    delayedCount?: number;
    tileKey: string;
    title: string;
}
interface Props extends TileItem {
    activeKey?: string; //To show active state
    delayedCount?: number;
    onClick?: (key: string) => void;
    onMouseEnter?: (key: string) => void;
    onMouseLeave?: (key: string) => void;
}
const Tile: React.FC<Props> = ({
    activeKey,
    count,
    delayedCount,
    tileKey,
    onClick,
    onMouseEnter,
    onMouseLeave,
    title,
}) => {
    return (
        <button
            className={`app-btn btn-value-holder ${activeKey === tileKey ? ' active' : ''}`}
            onClick={() => {
                !!onClick && onClick(tileKey);
            }}
            onMouseEnter={() => !!onMouseEnter && onMouseEnter(tileKey)}
            onMouseLeave={() => !!onMouseLeave && onMouseLeave(tileKey)}
        >
            <div className="btn-heading">{title}</div>
            {delayedCount ? (
                <div className="btn-content">
                    {/* added as a new one as per design */}
                    <div className="btn-count">{count + delayedCount ?? 0}</div>
                    <div className="btn-count-text">{delayedCount} delayed</div>
                </div>
            ) : (
                <div className="btn-content">{count}</div>
            )}
        </button>
    );
};
export default Tile;
