import { createSlice } from '@reduxjs/toolkit';
import { availableAoHeaders } from '../../../utils/constants';

export const dataSlice = createSlice({
    name: 'availablePoLinesao',
    initialState: {
        poId: null,
        bpId: null,
        bpNumber: null,
        totalCBM: 0,
        totalKgs: 0,
        infoValues: {
            originPort: '',
            destinationPort: '',
            goodsReadyDates: [],
        },
        availablePoLineList: [],
        filterValues: [],
        isLoading: true,
        columnStatus: availableAoHeaders.poNumber,
        error: false,
        success: false,
        poData: {},
        distinctFilterValues: [],
    },
    reducers: {
        fetchAvailablePoLineData: (state, action) => {
            state.isLoading = true;
            state.availablePoLineList = action.payload?.availablePoLineList?.splitLineDataResList;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        addPoLineData: (state, action) => {
            state.isLoading = true;
            state.bpNumber = action.payload?.data?.info?.bpNumber;
            if (action.payload?.data?.success) {
                state.success = action.payload?.data?.success;
                localStorage.setItem('bpId', action.payload?.data?.info?.bpId);
                state.bpId = action.payload?.data?.info?.bpId;
            } else if (action.payload.data === 'error') {
                state.error = true;
            }
            state.isLoading = false;
        },
        setHeaderData: (state, action) => {
            state.totalCBM = action.payload?.totalCBM;
            state.totalKgs = action.payload?.totalKgs;
            state.infoValues = action.payload?.infoValues;
        },
        setPoId: (state, action) => {
            state.poId = action.payload?.poId;
        },
        resetFlags: (state, _action) => {
            state.success = false;
            state.error = false;
        },
        resetAvailablePoLineList: (state, _action) => {
            state.availablePoLineList = [];
        },
        fetchDistinctFilterValues: (state, action) => {
            state.distinctFilterValues = action.payload?.distinctFilterValues;
        },
    },
});

export const {
    fetchAvailablePoLineData,
    isLoadingFn,
    addPoLineData,
    resetAvailablePoLineList,
    setPoId,
    resetFlags,
    fetchDistinctFilterValues,
} = dataSlice.actions;
export default dataSlice.reducer;
