import React, { useEffect } from 'react';
import '../../../assets/scss/components/_modal.scss';
import { useAppDispatch } from 'src/hooks';
import { useSelector } from 'react-redux';
import { Position, error, success } from 'src/utils/toast';
import { RootState } from 'src/store/reducers';
import { resetBcnBack, reset } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';

interface CancelProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    bpId: string;
}

const Index: React.FC<CancelProps> = ({ setModalView, bpId }) => {
    const dispatch = useAppDispatch();
    const {
        success: successBcn,
        successMessage,
        error: errorBcn,
    } = useSelector((state: RootState) => state.bpHeaderData);
    const resetBcn = () => {
        dispatch(resetBcnBack({ bpId }));
    };

    useEffect(() => {
        const errorBcnCopy = JSON.parse(JSON.stringify(errorBcn));
        if (successBcn && successMessage?.type === 'resetBp') {
            success(successMessage?.message || 'Reset of BCN completed successfully', Position.TOP_RIGHT);
            setModalView(false);
            propagateBcnData(bpId, true);
            setTimeout(() => {
                location.reload();
            }, 3000);
        } else if (errorBcnCopy && errorBcnCopy?.config?.data?.includes('BP_CD_Status')) {
            error(errorBcn ?? 'Something Went Wrong', Position.TOP_RIGHT);
            dispatch(reset({}));
        }
    }, [successBcn, errorBcn]);
    return (
        <div className="app-modal container">
            <div className="modal-content-holder">
                <div className="modal-header">
                    <h2 className="title">Reset Booking Proposal</h2>
                    <button className="app-btn modal-close-btn" onClick={() => setModalView(false)}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <p>Are you sure you want to reset this Booking Proposal?</p>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        onClick={() => setModalView(false)}
                    >
                        <span className="button-text vendor-button-text">No</span>
                    </button>
                    <button className="app-btn app-btn-primary modal-btn footer-btn" onClick={() => resetBcn()}>
                        <span className="button-text vendor-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
