import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import './agent-list.scss';
import './index.scss';

import GridLayout from './grid';
import { Position, error } from 'src/utils/toast';
import { useParams } from 'react-router-dom';
import { getQuoteByPK } from 'src/services/apis/qmsApi';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import QuoteHeader from './Header';
import QuoteTab from './Tab';
import SpotRateModal from '../SpotRateModal';

const Index: React.FC = () => {
    const [quoteStringify, setQuoteStringify] = useState({} as any);
    const [qeQuoteSegments, setQeQuoteSegments] = useState([] as any);
    const [quoteHeader, setQuoteHeader] = useState([] as any);
    const [showSpotRateModal, setShowSpotRateModal] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            getQuoteByPK(id)
                .then((res: any) => {
                    if (res?.length > 0 && res[0].QE_QuoteSegments?.length > 0) {
                        setQuoteStringify(JSON.stringify(res[0]));
                        setQeQuoteSegments(res[0].QE_QuoteSegments);
                        setQuoteHeader(res[0]);
                    }
                })
                .catch((err: any) => {
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                });
        }
    }, [id]);

    const navData = [
        {
            title: 'Quote Input',
            selected: 1,
            path: id ? `/qms/quote/${id}` : '/qms/quote',
        },
    ];

    return (
        <div className="main-wrapper container insight-agent-list quote-detail-input">
            <div className="main-header-content-holder booking-proposal-header large-header">
                <Breadcrumbs
                    firstName={'Quotes'}
                    firstUrl={'/qms/quote'}
                    secondName={'Quote Input'}
                    secondUrl={'#'}
                    backUrl={'/qms/quote'}
                />
                <QuoteHeader quoteHeaderObj={quoteHeader} />
                <QuoteTab navData={navData} />
            </div>

            <div className="grid-section grid-p-0">
                <div className="spot-btn">
                    {quoteHeader?.QH_CD_QuoteType === 'FAK' ? (
                        <button
                            onClick={() => setShowSpotRateModal(true)}
                            className="app-btn app-btn-secondary icon-button"
                            title="Add SPOT Rate"
                        >
                            <span className="button-text">Add SPOT Rate</span>
                        </button>
                    ) : null}
                </div>
                <div className="main-content-holder ">
                    <div>
                        <div id="tabstrip">
                            <ul>
                                {qeQuoteSegments.length > 0 &&
                                    qeQuoteSegments.map((section: any, index: number) => (
                                        <li className={index === 0 ? `k-active` : ''} data-code={section.QE_PK}>
                                            {`${section.QE_Name || ''} ${
                                                section.QE_CD_ContainerMode && section.QE_CD_TransportMode
                                                    ? '(' + section.QE_CD_TransportMode
                                                    : section.QE_CD_TransportMode
                                                    ? '(' + section.QE_CD_TransportMode + ')'
                                                    : ''
                                            } ${section.QE_CD_ContainerMode ? section.QE_CD_ContainerMode + ')' : ''}`}
                                        </li>
                                    ))}
                            </ul>
                            {qeQuoteSegments.length > 0 &&
                                qeQuoteSegments.map((section: any) => (
                                    <div key={section.QE_PK} className="insight-agent-list-data-trend">
                                        <div className={`container-fluid`}>
                                            <div className="">
                                                <div className="k-kendo-col-2 custom-kendo-grid">
                                                    <div id={section.QE_PK}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                    {quoteStringify !== '' && (
                        <HelmetProvider key={id}>
                            <GridLayout quote={quoteStringify} />
                        </HelmetProvider>
                    )}
                </div>
            </div>
            {showSpotRateModal ? (
                <SpotRateModal
                    modalView={showSpotRateModal}
                    setModalView={setShowSpotRateModal}
                    qeQuoteSegments={qeQuoteSegments}
                    quoteHeader={quoteHeader}
                />
            ) : null}
        </div>
    );
};
export default Index;
