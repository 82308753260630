import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './styles/map.scss';
//import BSTooltip from 'src/components/common/BSTooltip';
import './styles/popup-tooltip-style.scss';
import { Map, MapSidebar } from './components';
import { useDispatch } from 'react-redux';
import {
    fetchMapData,
    saveSingleMapData,
    showSidebar,
    sideBarType,
    isLoadingFn,
} from 'src/store/reducers/dashboard/map';
import { useSelector } from 'react-redux';
import TileSideBar from './components/MapSidebar/TileSideBar';
import SearchHeader from '../Search/SearchHeader';
import { RootState } from 'src/store/reducers';
import { insightTabList } from '../Insights/enums';

const Index: React.FC = () => {
    const { sideBarView, clickedTileKey, isLoading, sideBarVewTiles } = useSelector((state: any) => state.map);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata) as any;
    const {
        insightTab,
        insightsMode,
        insightsDestination,
        insightsSelectedType,
        insightsMainPage,
        insightsSelectedTypeFilter,
        // insightsForwarded,
        favInsightsMode,
        favInsightsDestination,
        // favInsightsForwarded,
        favInsightsSelectedType,
        favInsightsSelectedTypeFilter,
    } = useSelector((state: RootState) => state.dashboardInsights);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const timeout = setTimeout(
            () => {
                if (insightTab === insightTabList.all) {
                    dispatch(
                        fetchMapData({
                            mode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                            destinationType: !!insightsDestination?.length ? insightsDestination[0].toLowerCase() : '',
                            selectedType: insightsMainPage?.toLowerCase()?.includes('orders')
                                ? 'orders'
                                : insightsMainPage?.toLowerCase()?.includes('booking')
                                ? 'bookings'
                                : '',
                            selectedStatus: insightsSelectedTypeFilter,
                            selectedCategory:
                                insightsSelectedType?.toLowerCase() === 'ao'
                                    ? 'ao'
                                    : insightsSelectedType === 'ITPO'
                                    ? 'international'
                                    : insightsSelectedType === 'IBPO'
                                    ? 'inbound'
                                    : insightsSelectedType?.toLowerCase() === 'po'
                                    ? 'po'
                                    : insightsSelectedType?.toLowerCase() === 'ao'
                                    ? 'ao'
                                    : '',
                            selectedMode: !!insightsMode?.length ? insightsMode[0].toLowerCase() : '',
                            searchValue: selectedSearchItem?.bpNumber,
                        })
                    );
                } else {
                    dispatch(
                        fetchMapData({
                            mode: !!favInsightsMode?.length ? favInsightsMode[0].toLowerCase() : '',
                            destinationType: !!favInsightsDestination?.length
                                ? favInsightsDestination[0].toLowerCase() === 'triangle'
                                    ? 'triangle'
                                    : 'export'
                                : '',
                            selectedType: favInsightsMode[0]?.toLowerCase()?.includes('orders')
                                ? 'orders'
                                : insightsMainPage?.toLowerCase()?.includes('booking')
                                ? 'bookings'
                                : '',
                            selectedStatus: favInsightsSelectedTypeFilter,
                            selectedCategory:
                                favInsightsSelectedType?.toLowerCase() === 'ao'
                                    ? 'ao'
                                    : favInsightsSelectedType === 'ITPO'
                                    ? 'international'
                                    : favInsightsSelectedType === 'IBPO'
                                    ? 'inbound'
                                    : favInsightsSelectedType?.toLowerCase() === 'po'
                                    ? 'po'
                                    : favInsightsSelectedType?.toLowerCase() === 'ao'
                                    ? 'ao'
                                    : '',
                            selectedMode: !!favInsightsMode?.length ? favInsightsMode[0].toLowerCase() : '',
                            searchValue: selectedSearchItem?.bpNumber,
                        })
                    );
                }
            },
            window.location.pathname.includes('dashboard/map') ? 0 : 1000
        );
        return () => clearTimeout(timeout);
    }, [
        insightTab,
        insightsMode,
        insightsDestination,
        insightsMainPage,
        insightsSelectedTypeFilter,
        insightsSelectedType,
        favInsightsMode,
        favInsightsDestination,
        favInsightsSelectedTypeFilter,
        favInsightsSelectedType,
        selectedSearchItem?.bpNumber,
    ]);

    const onMapCollapse = () => {
        navigate('/', { state: { reload: true } });
        dispatch(isLoadingFn(true));
        setTimeout(() => {
            dispatch(saveSingleMapData({ searchValue: selectedSearchItem }));
            dispatch(showSidebar(false));
            dispatch(sideBarType(''));
        }, 200);
    };

    const renderRightTopIcon = () => {
        return (
            <button className="view-nav grid-icon icon-nav " title="Collapse" onClick={onMapCollapse}>
                <svg className="svg-icon maxi-icon ">
                    <use xlinkHref="#minimaxIcon">
                        <title>Collapse</title>
                    </use>
                </svg>
            </button>
        );
    };
    return (
        <>
            <div className="main-wrapper container dashboard-wrapper">
                <SearchHeader />

                {/* btn-container-with-arrow -> bg-border-light-yellow ,bg-border-gray-white, bg-border-light-green, bg-border-light-purple ,bg-border-dark-purple, bg-border-light-violet ,bg-border-dark-violet  
                    ,bg-border-light-blue ,bg-border-dark-green, bg-border-yellow-black for ---btn-container-with-arrow*/}
                {/* icon-holder - bg-yellow,bg-light-green,bg-light-purplet,bg-light-violet,bg-light-blue */}
                {/* btn-count - text-black , text-white used to change the text color */}
                {/* <button className="app-btn btn-container">
                        <div className="btn-container-with-arrow bg-border-light-purple">
                            <div className="icon-holder bg-light-purple"> */}
                {/* <svg className="svg-icon aeroplane-icon">
                                    <use href="#aeroplanefillIcon">
                                        <title>Aeroplane</title>
                                    </use>
                                </svg> */}
                {/* <svg className="svg-icon ship-icon">
                                    <use href="#shipfillIcon">
                                        <title>Icon</title>
                                    </use>
                                </svg> */}
                {/* <svg className="svg-icon note-icon">
                                    <use href="#mapcheckIcon">
                                        <title>Icon</title>
                                    </use>
                                </svg> */}
                {/* <svg className="svg-icon note-icon">
                                    <use href="#maphouseIcon">
                                        <title>Icon</title>
                                    </use>
                                </svg> */}
                {/* <svg className="svg-icon note-icon">
                                    <use href="#mapnoteIcon">
                                        <title>Icon</title>
                                    </use>
                                </svg> */}
                {/* </div>
                            <div className="btn-count text-black">12</div>
                        </div>
                    </button> */}
                {/* <button className="app-btn btn-location-container"> */}
                {/* In - btn-count - text-dark-pink , text-light-pink used to change the text color */}
                {/* In - location-icon -> add- bg-yellow,bg-light-green,bg-light-purplet,bg-light-violet,bg-light-blue,bg-dark-pink,bg-light-pink*/}
                {/* <svg className="svg-icon location-icon bg-yellow">
                            <use href="#locationLargeIcon">
                                <title>Location Ping Icon</title>
                            </use>
                        </svg>
                        <div className="icon-holder"> */}
                {/* .bg-white --- is a class for background white */}
                {/*<svg className="svg-icon ship-icon">
                                <use href="#shipfillIcon">
                                    <title>Icon</title>
                                </use>
                            </svg>*/}
                {/* <svg className="svg-icon map-check-icon">
                                <use href="#mapcheckIcon">
                                    <title>Icon</title>
                                </use>
                            </svg> */}
                {/* <svg className="svg-icon map-house-icon">
                                <use href="#maphouseIcon">
                                    <title>Icon</title>
                                </use>
                            </svg> */}
                {/* <svg className="svg-icon map-note-icon">
                                <use href="#mapnoteIcon">
                                    <title>Icon</title>
                                </use>
                            </svg> */}
                {/* <div className="btn-count text-light-pink">12</div> */}
                {/* </div>
                    </button> */}
                {/* <button className="app-btn btn-pointer-location">
                        <svg className="svg-icon location-pointer-icon bg-red">
                            <use href="#locationPointerIcon">
                                <title> Main Location Ping Icon</title>
                            </use>
                        </svg>
                        <div className="icon-holder main-pointer"></div>
                    </button>
                    {/* icon was added as part of new dashboard changes pointericonformap */}
                {/*<svg className="svg-icon arrow-location-pointer" style={{ width: '12px', height: '12px' }}>
                        <use href="#mapArrowPointerIcon">
                            <title> Main Location Ping Icon</title>
                        </use>
                    </svg>
                </div>*/}
                <MapSidebar></MapSidebar>
                {isLoading ? (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                ) : (
                    <Map
                        haveTrackLines={true}
                        renderRightTopIcon={renderRightTopIcon}
                        mapId="map"
                        haveToolTips={true}
                    />
                )}

                {sideBarView && !clickedTileKey && <MapSidebar />}
                {sideBarVewTiles && clickedTileKey && <TileSideBar />}
            </div>
        </>
    );
};

export default Index;
