import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers';

const Index: React.FC = () => {
    // const { id } = useParams();
    const navigate = useNavigate();
    const { clientBasicDetails } = useSelector((state: RootState) => state.basicDetails);

    return (
        <>
            <div className="head-btn-holder">
                <button
                    className="app-btn app-btn-secondary"
                    title="Go to Profile"
                    onClick={() => {
                        navigate(`/profile/clients/${clientBasicDetails?.cl_PK}/basic_details`, {
                            state: {
                                state: clientBasicDetails?.cl_Org.or_Status,
                                name: clientBasicDetails?.cl_Org.or_Orgname,
                            },
                        });
                    }}
                >
                    <span className="button-text">Go to Profile</span>
                </button>
            </div>
            <div className="lcs-basic-details">
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Name</div>
                    <div className="cm-key-pair-value">{clientBasicDetails?.cl_Org.or_Orgname || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Code</div>
                    <div className="cm-key-pair-value">{clientBasicDetails?.cl_Org.or_Orgcode || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Category</div>
                    <div className="cm-key-pair-value">{clientBasicDetails?.cl_Org.or_Orgtype || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Company Registration Number</div>
                    <div className="cm-key-pair-value">{clientBasicDetails?.cl_Org.or_ABN || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">UNLOCO</div>
                    <div className="cm-key-pair-value">{clientBasicDetails?.cl_Org.or_Unloco || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Phone</div>
                    <div className="cm-key-pair-value">{clientBasicDetails?.cl_Org.or_Phone || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Website URL</div>
                    <div className="cm-key-pair-value">{clientBasicDetails?.cl_Org.or_WebSiteUrl || '-'}</div>
                </div>
            </div>
        </>
    );
};
export default Index;
