import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchShippingLineData,
    isLoadingFn,
    searchShippingLineData,
    fetchContract,
} from '../reducers/shippingLineReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import {
    getShippingLineData,
    searchShippingLine,
    shippingLineResponse,
    shippingLineSearchData,
    searchShippingLineContract,
} from '../../services/apis/shippingLineApi';
import { getShippingDetails } from 'src/services/apis/shippingApi';
import { fetchShippingDetails } from '../reducers/shippingReducer';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getShippingLineListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const shippingLines: AxiosResponse<shippingLineResponse> = yield call(getShippingLineData, payload);
        payload['shippingLines'] = shippingLines;
        yield put({ type: fetchShippingLineData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const shippingLines: AxiosResponse<shippingLineSearchData> = yield call(searchShippingLine, payload);
        payload['shippingLines'] = shippingLines;
        yield put({ type: searchShippingLineData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* fetchCarrierContract({ payload }: { payload: any; type: any }) {
    const response: AxiosResponse<any> = yield call(searchShippingLineContract, payload);
    yield put({ type: fetchContract, payload: response });
}

function* fetcShippingInfo({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getShippingDetails, payload);
        payload['shippingDetails'] = response?.data[0];
        yield put({ type: fetchShippingDetails, payload: payload });
    } finally {
    }
}

function* shippingLineSaga() {
    yield takeEvery('shippingLine/fetchShippingLineData', getShippingLineListData);
    yield takeEvery('shippingLine/searchShippingLineData', getSearchData);
    yield takeEvery('shippingDetails/fetchShippingDetails', fetcShippingInfo);
    yield takeEvery('shippingLine/fetchContract', fetchCarrierContract);
}

// Export the saga (data-saga)
export default shippingLineSaga;
