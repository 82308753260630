import React from 'react';
import './rejected-booking-proposal.scss';
import { useAppDispatch } from '../../../hooks';
import { updateModalStatus } from '../../../store/reducers/bookingProposalLand/additionalDetailsReducer';
// import { fetchBpHeaderData } from '../../../store/reducers/bookingProposalLand/bookingProposalHeaderReducer';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    reason: string;
    oneTimeModal: boolean;
    bpId: any;
    profileType: string;
}
const Index: React.FC<ModalProps> = ({ modalView, setModalView, reason, oneTimeModal, bpId, profileType }) => {
    const dispatch = useAppDispatch();
    const viewModal = () => {
        if (oneTimeModal) {
            dispatch(
                updateModalStatus({
                    bpId,
                    profileType,
                    modalType: 'reject',
                })
            );
            // setTimeout(() => {
            //     dispatch(fetchBpHeaderData({ bpId, profileType }));
            // }, 2000);
        }
    };
    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder rejected-booking-modal">
                        <div className="modal-header">
                            <div className="title">Booking Proposal Rejected</div>
                            <button
                                onClick={() => {
                                    setModalView(false);
                                    viewModal();
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Reason</h6>
                                        <div className="description-type-1">{reason}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {oneTimeModal && (
                            <div className="modal-footer">
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn "
                                    type="button"
                                    title="Update Proposal"
                                    onClick={() => {
                                        setModalView(false);
                                        viewModal();
                                    }}
                                >
                                    <span className="button-text footer-button-text">Update Proposal</span>
                                </button>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
