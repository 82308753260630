import React from 'react';
import 'src/assets/scss/components/_layout.scss';
import 'src/assets/scss/components/_modal.scss';
import './productdetails-modal.scss';

interface PropUser {
    data: any;
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
const Index: React.FC<PropUser> = (props) => {
    return (
        <>
            {props.display ? (
                <div className="app-modal product-detail-modal">
                    <div className="modal-content-holder large-popup">
                        <div className="modal-header">
                            <div className="title">Product Details</div>
                            <button
                                onClick={() => {
                                    props.displayFunction(false);
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="card details-box custom">
                                    <div className="card-detail-col">
                                        <div className="details-col box-content-holder">
                                            <h6 className="details-box-heading">SKU</h6>
                                            <div className="details-box-content">{props.data.pm_sku}</div>
                                        </div>
                                        <div className="details-col box-content-holder">
                                            <h6 className="details-box-heading">Description</h6>
                                            <div className="details-box-content">{props.data.pm_description}</div>
                                        </div>
                                        <div className="details-col box-content-holder">
                                            <h6 className="details-box-heading">HS Code</h6>
                                            <div className="details-box-content">{props.data.pm_purchasecode}</div>
                                        </div>
                                        <div className="details-col box-content-holder">
                                            <h6 className="details-box-heading">Fumigation Required</h6>
                                            <div className="details-box-content">
                                                {props.data.pm_fumigationrequired ? 'Yes' : 'No'}
                                            </div>
                                        </div>
                                        <div className="vendor-dangerous-content-row">
                                            <div className="details-col box-content-holder vendor-details">
                                                <h6 className="details-box-heading">Vendor</h6>
                                                <div className="details-box-content">
                                                    <div className="suggestion-container">
                                                        {props.data.pm_vendors.map((res: any, index: number) => (
                                                            <div key={index} className="content-card">
                                                                <span className="main-content">
                                                                    {res.vn_vendorname}
                                                                </span>
                                                            </div>
                                                        ))}

                                                        {/* <div className="content-card">
                                                            <span className="main-content">Biffco Enterprises Ltd.</span>
                                                        </div>
                                                        <div className="content-card">
                                                            <span className="main-content">Binford Ltd.</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* added for commodity screen sprint11 */}
                                            {props.data?.pm_type === 'Commodity' && (
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">Dangerous Good</h6>
                                                    <div className="details-box-content">
                                                        <p>{props.data?.pm_dangerousgood ? 'Yes' : 'No'} </p>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!!(props.data?.pm_type !== 'Commodity') && (
                                    <>
                                        <h5 className="subtitle">Pack Details</h5>
                                        <div className="grid-section productdetails-grid">
                                            <div className="grid-holder">
                                                <div className="grid-container">
                                                    {/* {(!data || data?.length === 0) && (
                                                <div className="grid-no-content ">
                                                    <svg className="svg-icon grid-no-content-icon ">
                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                    </svg>
                                                    <p className="no-content-message">No records found</p>
                                                </div>
                                            )} */}
                                                    <div className="grid-scroll-content">
                                                        <table className="grid-table">
                                                            <thead>
                                                                <tr className="grid-sticky-row">
                                                                    <th className="grid-cell-header col-role-id">
                                                                        <div className="grid-cell-data">
                                                                            <span>QUANTITY UOM</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="grid-cell-header col-cnvr-quantity">
                                                                        <div className="grid-cell-data">
                                                                            <span>CONVERSION QUANTITY</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="grid-cell-header col-length">
                                                                        <div className="grid-cell-data">
                                                                            <span>LENGTH (M)</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="grid-cell-header col-width">
                                                                        <div className="grid-cell-data">
                                                                            <span>WIDTH (M)</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="grid-cell-header col-height">
                                                                        <div className="grid-cell-data">
                                                                            <span>HEIGHT (M)</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="grid-cell-header col-cbm">
                                                                        <div className="grid-cell-data">
                                                                            <span>CBM</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="grid-cell-header col-weight">
                                                                        <div className="grid-cell-data">
                                                                            <span>WEIGHT(KG)</span>
                                                                        </div>
                                                                    </th>
                                                                    <th className="grid-cell-header col-barcode">
                                                                        <div className="grid-cell-data">
                                                                            <span>BARCODE</span>
                                                                        </div>
                                                                    </th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {props?.data?.pm_packets?.map(
                                                                    (res: any, index: number) => (
                                                                        <tr
                                                                            key={index}
                                                                            className="has-child row-expanded"
                                                                        >
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="client-id">
                                                                                        {res.pp_quantity}
                                                                                    </span>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {res.pp_conversionquantity}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {res.pp_length}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {res.pp_width}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {res.pp_height}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {res.pp_cbm}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {res.pp_weight}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {res.pp_barcode}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                )}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
