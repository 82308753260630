// fetchForwarderData
import { createSlice } from '@reduxjs/toolkit';
import { forwarderBasicDetails, getForwarderDetails } from '../../services/apis/forwarderApi';

interface ForwarderDetailsState {
    forwarderBasicDetails?: forwarderBasicDetails;
}

const initialState: ForwarderDetailsState = {
    // eslint-disable-next-line no-undefined
    forwarderBasicDetails: undefined,
};

export const dataSlice = createSlice({
    name: 'forwarderDetails',
    initialState,
    reducers: {
        fetchForwarderDetails: (state, action) => {
            state.forwarderBasicDetails = action.payload.forwarderDetails;
        },
    },
});

export const { fetchForwarderDetails } = dataSlice.actions;
export default dataSlice.reducer;
