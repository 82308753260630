import React from 'react';
import {
    // Button,
    Card,
    CardContent,
    Icon,
    Label,
    Popup,
    Progress,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
} from 'semantic-ui-react';

import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import './vendorPortal.css';
import BSTooltip from 'src/components/common/BSTooltip';

interface ContainerCardGroupProps {
    displayPackedSSCC: (value: string) => void;
}

const Index: React.FC<ContainerCardGroupProps> = ({ displayPackedSSCC }) => {
    const { packingStatus } = useSelector((state: RootState) => state.vendorPortalData);

    const getColour = (progress: number, packType: string) => {
        // return display colour based on the packing percentage
        if (packType?.toUpperCase() === 'ZA') {
            // if ZA
            if (progress === 0 || progress > 105) return 'red';
            else if (progress < 95) return 'orange';
            else return 'green';
        } else {
            // not started - red
            // in progress - amber
            // completed - green
            if (progress === 0) return 'red';
            else if (progress < 100) return 'orange';
            else return 'green';
        }
    };

    return (
        // rendering cards for different container type
        // header - 2 buttons (packed & unpack) - progress bar
        <Card.Group itemsPerRow={10} stackable={true} doubling={true} className="card_group">
            {packingStatus.length > 0 &&
                packingStatus.map((element: any, index: number) => {
                    return (
                        <Card className="single_card">
                            <CardContent
                                header={element.packType}
                                textAlign="center"
                                className="card_title"
                            ></CardContent>
                            <CardContent extra>
                                <div>
                                    <Table celled>
                                        <TableHeader>
                                            <TableRow>
                                                <BSTooltip
                                                    id={`packed-${index}`}
                                                    title="Click to view packing"
                                                    position="left"
                                                >
                                                    <TableHeaderCell
                                                        onClick={() => {
                                                            displayPackedSSCC(element.packType);
                                                        }}
                                                        className="cell-packed card-column-title"
                                                        textAlign="center"
                                                    >
                                                        PACKED
                                                    </TableHeaderCell>
                                                </BSTooltip>
                                                <TableHeaderCell textAlign="center" className="card-column-title">
                                                    UNPACKED
                                                </TableHeaderCell>
                                            </TableRow>
                                        </TableHeader>

                                        <TableBody>
                                            <TableRow>
                                                <TableCell textAlign="center">{element.packed}</TableCell>
                                                <TableCell textAlign="center">{element.unpacked}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </CardContent>
                            <CardContent textAlign="center" extra>
                                <Icon color={getColour(element.progress, element.packType)} name="inbox" />
                                <a className="card-progress-text">
                                    {element.progress}%{'  '}
                                </a>

                                {/* {element.packType === 'ZA' && element.progress > 105 && (
                                    <Popup
                                        inverted
                                        trigger={<Icon name="warning circle" color="red" />}
                                        content="ZA cannot be packed over 5%"
                                        basic
                                    />
                                )} */}
                            </CardContent>
                            <Progress
                                percent={element.progress}
                                attached="bottom"
                                color={getColour(element.progress, element.packType)}
                                active
                            />
                        </Card>
                    );
                })}
        </Card.Group>
    );
};

export default Index;
