import React, { useEffect } from 'react';
import '../vendor-tab.scss';
import '../../../../assets/scss/components/_tab.scss';
import { Link, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { fetchHeaderDataVendors } from 'src/store/reducers/profiles/vendors';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

interface HeaderProps {
    disableSecond?: boolean;
    disableThird?: boolean;
}

const Index: React.FC<HeaderProps> = (_props) => {
    const location = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const { vendorsHeader } = useSelector((state: RootState) => state.profilesVendors) as any;
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    React.useEffect(() => {
        if (lastSegment) {
            if (lastSegment === 'users') {
                setSelectedIndex(3);
            } else if (lastSegment === 'integration') {
                setSelectedIndex(2);
            } else if (lastSegment === 'client_details') {
                setSelectedIndex(1);
            } else {
                setSelectedIndex(0);
            }
        }
        document.title = 'CNF>ORG>VND';
    }, []);

    const vendorsTabList = [
        {
            label: 'Basic Details',
            value: 'basic_detail',
            id: 1,
        },
        {
            label: 'Client Details',
            value: 'client_details',
            id: 2,
        },
        {
            label: 'Integration Details',
            value: 'integration',
            id: 3,
        },
        {
            label: 'Users',
            value: 'users',
            id: 4,
        },
    ];

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (vendorsHeader) {
                if (param === 'integration') {
                    return vendorsHeader.idTabComplete;
                } else if (param === 'client_details') {
                    return vendorsHeader.cdTabComplete;
                } else if (param === 'basic_detail') {
                    return vendorsHeader.bdTabComplete;
                } else if (param === 'users') {
                    return vendorsHeader.vnUseTabComplete;
                } else {
                    return false;
                }
            }
        };
    }, [lastSegment, vendorsHeader]);

    const checkTabNavigationFromApi = (param: string, headerDetailsVal: any) => {
        if (headerDetailsVal) {
            if (param === 'integration') {
                return headerDetailsVal.bdTabComplete && headerDetailsVal.cdTabComplete;
            } else if (param === 'client_details') {
                return headerDetailsVal.bdTabComplete === true;
            } else {
                return true;
            }
        }
    };

    useEffect(() => {
        dispatch(fetchHeaderDataVendors(id));
    }, [id]);

    return (
        <>
            <div className="arrow-tab-container">
                <div className="arrow-tab-holder">
                    {vendorsTabList.map((tabs: any, index: number) => {
                        return (
                            <Link
                                className={`arrow-tab-border ${index === selectedIndex && 'active'} po-basic-tab ${
                                    !checkTabNavigationFromApi(tabs.value, vendorsHeader) && 'disabled'
                                }`}
                                to={`/profile/vendor/${id}/${tabs.value}`}
                            >
                                {isTabActive(tabs.value) ? (
                                    <div
                                        className="arrow-badge verify-badge"
                                        style={{
                                            backgroundColor: '#18ab56',
                                        }}
                                    >
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="arrow-badge">{tabs.id}</div>
                                )}
                                <div className="arrow-tab">{tabs.label}</div>
                            </Link>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default Index;
