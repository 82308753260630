/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';

interface LifecycleEvent {}

const initialState: LifecycleEvent = {};

export const lifecycleEventSlice = createSlice({
    name: 'lifecycleEvent',
    initialState,
    reducers: {
        uploadEvent: (_state, _action) => {},
    },
});

export const { uploadEvent } = lifecycleEventSlice.actions;

export default lifecycleEventSlice.reducer;
