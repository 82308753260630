import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchParentList,
    isLoadingFn,
    isUnlocoLoadingFn,
    fetchVendorData,
    fetchManufacturingCategories,
    searchUnlocoList,
    fetchAddressList,
    fetchVendorContacts,
    fetchDefaultIncoTermsDetails,
    fetchOrganisationCategoryDetails,
    saveVendorBasicDetailsError,
    saveVendorBasicDetailsSuccess,
    editVendorProfileDataSuccess,
    editVendorProfileDataError,
    saveVendorAddressSuccess,
    saveVendorAddressError,
    editVendorAddressDataSuccess,
    editVendorAddressDataError,
} from '../reducers/createVendorReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import {
    listParents,
    addAddress,
    addBasicDetails,
    parentData,
    getVendorBasicDetails,
    manufacturingCategories,
    getManufacturingCategories,
    unLoco,
    searchUnloco,
    getVendorAddressList,
    addressList,
    editVendorAddress,
    editVendorProfile,
    getDefaultIncoTerms,
    getOrganisationCategories,
    organisationCategories,
    defaultIncoTerms,
    getVendorContacts,
    contactLists,
} from '../../services/apis/createVendorApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getParentData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const parents: AxiosResponse<parentData[]> = yield call(listParents, payload);
        payload['parents'] = parents;
        yield put({ type: fetchParentList, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getManufacuringCategoryData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const manufacturingCategories: AxiosResponse<manufacturingCategories[]> = yield call(
            getManufacturingCategories,
            payload
        );
        payload['manufacturingCategories'] = manufacturingCategories;
        yield put({ type: fetchManufacturingCategories, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addVendorAddress({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(addAddress, payload);
        yield put({ type: saveVendorAddressSuccess, payload: response });
    } catch (err) {
        yield put({ type: saveVendorAddressError, payload: err });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addVendorDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(addBasicDetails, payload);
        yield put({ type: saveVendorBasicDetailsSuccess, payload: response });
    } catch (err) {
        yield put({ type: saveVendorBasicDetailsError, payload: err });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getVendorDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const vendorBasicDetails: AxiosResponse<any> = yield call(getVendorBasicDetails, payload);
        payload['vendorBasicDetails'] = vendorBasicDetails;
        yield put({ type: fetchVendorData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* searchUnlocoData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put({ type: isUnlocoLoadingFn, payload: true });
        yield put(showLoading());
        const unLocoList: AxiosResponse<unLoco[]> = yield call(searchUnloco, payload);
        payload['unLocoList'] = unLocoList;
        yield put({ type: searchUnlocoList, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put({ type: isUnlocoLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getAddressList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const addressList: AxiosResponse<addressList[]> = yield call(getVendorAddressList, payload);
        payload['addressList'] = addressList;
        yield put({ type: fetchAddressList, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getVendorContactList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const contacts: AxiosResponse<contactLists> = yield call(getVendorContacts, payload);
        payload['contacts'] = contacts;
        yield put({ type: fetchVendorContacts, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* editVendorDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(editVendorAddress, payload);
        yield put({ type: editVendorAddressDataSuccess, payload: response });
    } catch (err) {
        yield put({ type: editVendorAddressDataError, payload: err });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* editVendorProfileDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(editVendorProfile, payload);
        yield put({ type: editVendorProfileDataSuccess, payload: response });
    } catch (err) {
        yield put({ type: editVendorProfileDataError, payload: err });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getDefaultIncoTermsList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const defaultIncoTermsList: AxiosResponse<defaultIncoTerms[]> = yield call(getDefaultIncoTerms, payload);
        payload['defaultIncoTermsList'] = defaultIncoTermsList;
        yield put({ type: fetchDefaultIncoTermsDetails, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getOrganisationCategoryList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const organisationCategoryList: AxiosResponse<organisationCategories[]> = yield call(
            getOrganisationCategories,
            payload
        );
        payload['organisationCategoryList'] = organisationCategoryList;
        yield put({ type: fetchOrganisationCategoryDetails, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* createVendorSaga() {
    yield takeEvery('vendorDetails/fetchParentList', getParentData);
    yield takeEvery('vendorDetails/saveVendorAddress', addVendorAddress);
    yield takeEvery('vendorDetails/saveVendorBasicDetails', addVendorDetails);
    yield takeEvery('vendorDetails/fetchVendorData', getVendorDetails);
    yield takeEvery('vendorDetails/fetchManufacturingCategories', getManufacuringCategoryData);
    yield takeEvery('vendorDetails/searchUnlocoList', searchUnlocoData);
    yield takeEvery('vendorDetails/fetchAddressList', getAddressList);
    yield takeEvery('vendorDetails/fetchVendorContacts', getVendorContactList);
    yield takeEvery('vendorDetails/editVendorAddressData', editVendorDetails);
    yield takeEvery('vendorDetails/editVendorProfileData', editVendorProfileDetails);
    yield takeEvery('vendorDetails/fetchDefaultIncoTermsDetails', getDefaultIncoTermsList);
    yield takeEvery('vendorDetails/fetchOrganisationCategoryDetails', getOrganisationCategoryList);
}

// Export the saga (data-saga)
export default createVendorSaga;
