import axios from 'axios';
import fileDownload from 'js-file-download';
import { getClientCode } from 'src/utils';
import { get, patch, post } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export interface SubmitBPparams {
    userName: string;
    profile: string;
    bookingId: string;
}
export const fetchAllQuotationApi = async (payload: { clientId: string }) => {
    try {
        const data = await get(`api/bkg/quotation/quotations/${payload.clientId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchSingleQuotationApi = async (payload: { quotationId: string }) => {
    try {
        const data = await get(`api/bkg/quotation/${payload.quotationId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fileUploadForDocuments = async (payload: any) => {
    try {
        const res = await post(`cix/scr/documents`, payload, null, undefined, externalBaseUrl);
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            return error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateQuotationApi = async (payload: any) => {
    const type = payload.type;
    delete payload.type;
    try {
        const data = await post('api/bkg/bp/quotation', payload?.formData, null);
        const file = payload?.formData?.get('file');
        if (file) {
            const document = payload?.formData?.get('document');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bpId;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HBQ');
            await fileUploadForDocuments(formData);
        }
        data.type = type;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const approveBookingProposalApi = async (payload: any) => {
    const bpId = payload.bpId;
    delete payload.bpId;
    let data;
    try {
        if (payload.profile === 'agent' || payload?.fromForwarderDraft) {
            data = await post(`api/bkg/bp/submit?bookingProposalId=${bpId}`, null, null);
        } else {
            data = await patch(`api/bkg/bp/approve/${bpId}`, payload);
        }
        data.type = 'approved';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const rejectBookingProposalApi = async (payload: any) => {
    try {
        const data = await post('api/bkg/bp/reject', payload, null);
        data.type = 'rejected';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const assignBpToAgentApi = async (payload: any) => {
    const bpId = payload.bpId;
    delete payload.bpId;
    try {
        const data = await patch(`api/bkg/bp/assigntoagent/${bpId}`, payload);
        data.type = 'assignedToAgent';
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCurrencyDetailsApi = async (_payload: any) => {
    try {
        const data = await get('api/bkg/bp/basicdetails/ordercurrency', null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const downloadFile = async (contract_pk: any, fileName: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/cd/download/${contract_pk}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const updateViewStatusAPi = async (payload: any) => {
    try {
        const data = await patch(`api/bkg/bp/viewed/${payload.bpId}/${payload.profileType}`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateModalStatusAPi = async (payload: any) => {
    try {
        const data = await patch(
            `api/bkg/bp/closemodal/${payload.bpId}/${payload.profileType}/${payload.modalType}`,
            null
        );
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const updateReassignAgentStatusAPi = async (payload: any) => {
    try {
        const data = await patch(`api/bkg/bp/closemodal/${payload.bpId}/${payload.profileType}/withdrawn`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchEnrichBookingApi = async (payload: any) => {
    try {
        const data = await get(`api/bkg/bp/enrich/${payload.bpId}`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            throw error;
        }
    }
};

export const updateEnrichBookingAPi = async (payload: any) => {
    try {
        const mode = payload.mode;
        delete payload.mode;
        const data = await post('api/bkg/bp/enrich', payload, null);
        data.type = 'enrichBooking';
        data.mode = mode;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

//Api triggered when a booking proposal ready for shipment
export const submitBookingProposal = async ({ userName, profile, bookingId }: SubmitBPparams) => {
    try {
        const res: { success: boolean; message: string } = await patch(`/api/bkg/bp/readyForShipment/${bookingId}`, {
            userName,
            profile,
        });
        return res.message;
    } catch (err) {
        return err;
    }
};

export const fetchHouseBillsData = async (payload: { bpId: string }) => {
    try {
        const userData = localStorage.getItem('user');
        const roleIndex = localStorage.getItem('roleIndex');
        let profileType = '';
        if (userData && roleIndex) {
            const { roles } = JSON.parse(userData);
            profileType = roles[roleIndex].profile?.toLowerCase();
        }

        let clientCode = null;
        if (profileType && profileType === 'client') {
            clientCode = await getClientCode(profileType);
        }

        const data = await get(
            `api/bkg/bp/housebillnumbers/${payload.bpId}${clientCode ? `?clientCode=${clientCode}` : ''}`,
            null
        );

        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchHouseBillsDataCheck = async (houseBillNo: string) => {
    try {
        // const token = localStorage.getItem('token');
        // const myHeaders = { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' };
        const dataHbl = await get(`api/bkg/bp/check/houseBill?${houseBillNo ? `houseBill=${houseBillNo}` : ''}`, null);

        return dataHbl;
    } catch (error) {
        console.log('error');
    }
};

export const updateHouseBillsData = async (payload: { data: any }) => {
    try {
        const res = await post(`api/bkg/bp/housebillnumbers`, payload.data, null);
        return res;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateHouseBillsDataCix = async (payload: { data: any }) => {
    try {
        const res = await post(
            `cix/scr/forwarding/booking/1/additionaldetails/housebillnumbers`,
            payload.data,
            null,
            undefined,
            externalBaseUrl
        );
        return res.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
