import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { Form, FormField, TextArea } from 'semantic-ui-react';

import { cixDeleteDoc } from 'src/store/reducers/documentReducer';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    shipmentList: [];
    setShipmentList: (value: any | ((prevVar: any) => any)) => void;
    linkShipmentObj: any;
    setLinkShipmentObj: (value: any | ((prevVar: any) => any)) => void;
}

const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    shipmentList,
    setShipmentList,
    linkShipmentObj,
    setLinkShipmentObj,
}) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();

    const confirmAction = (decision: boolean) => {
        if (decision) {
            setShipmentList([...shipmentList, linkShipmentObj]);
        } else {
            setShipmentList([...shipmentList, { parentShipment: linkShipmentObj.parentShipment }]);
        }
        setLinkShipmentObj({});
        setModalView(false);
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder  booking-cancellation-modal">
                        <div className="modal-header">
                            <div className="title">Action Required</div>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="description-type-1">
                                            The shipment you selected has linked child shipment(s), please confirm if
                                            you wish to use the same documentation for the child shipment(s)
                                        </div>
                                        <div className="description-type-1">
                                            {linkShipmentObj.parentShipment}
                                            {' -> '}
                                            {linkShipmentObj.childShipment}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => {
                                    confirmAction(false);
                                    setModalView(false);
                                }}
                            >
                                <span className="button-text footer-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Delete"
                                onClick={() => {
                                    confirmAction(true);
                                    setModalView(false);
                                }}
                            >
                                <span className="button-text footer-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
