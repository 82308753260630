import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
import '../lcsBookingDetails.scss';
import KendoGridJquery from 'src/components/KendoGridJquery';
import BasicDetailsTab from '../warehouseBasicDetails';
import { useSelector } from 'react-redux';
import { fetchAgentBasicDetailsList } from 'src/store/reducers/profiles/agents/basicDetailsReducer';

const baseURL = process.env.REACT_APP_BASE_URL;

const Index: React.FC = () => {
    const [lcsTab, setLcsTab] = useState('basicDetails');

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation() as any;
    const { userName } = useUserProfile();

    const { id } = useParams() as any;

    const gridItem = gridDetails.filter((res: any) => res.name === 'purcahseOrders')[0];
    const allocationOrderItem = gridDetails.filter((res: any) => res.name === 'allocationOrders')[0];
    const inventoryItem = gridDetails.filter((res: any) => res.name === 'inventory')[0];
    const receiptItem = gridDetails.filter((res: any) => res.name === 'receipt')[0];

    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const { wareHouseDetails } = useSelector((state: any) => state.lifeCycleSearch) as any;
    useEffect(() => {
        gridItem.url = `${baseURL}/api/ord/tracking/lifecycle/warehouse/podetails?blpAgentCode=${id}`;
        allocationOrderItem.url = `${baseURL}/api/ord/tracking/lifecycle/warehouse/allocationOrder?blpAgentCode=${id}`;
        inventoryItem.url = `${baseURL}/api/ord/tracking/lifecycle/warehouse/inventory?blpAgentCode=${id}`;
        receiptItem.url = `${baseURL}/api/ord/tracking/lifecycle/warehouse/receipt?blpAgentCode=${id}`;
    }, [lcsTab, id]);

    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };
    useEffect(() => {
        if (lcsTab === 'inventory') {
            localStorage.setItem('currentWarehouse', location.state.name); //setting warehouse name to be passed to inventory section filters
        }
    }, [lcsTab, location]);

    useEffect(() => {
        if (lcsTab === 'basicDetails') {
            dispatch(fetchAgentBasicDetailsList({ id: wareHouseDetails.agentID }));
        }
    }, [wareHouseDetails.agentID, lcsTab]);

    const Btn: any = () => {
        return (
            <div className="head-btn-holder">
                <button
                    className="app-btn app-btn-secondary"
                    title="Go to Profile"
                    onClick={() => {
                        navigate(`/profile/agent/${wareHouseDetails?.agentID}/basic_details`);
                    }}
                >
                    <span className="button-text">Go to Profile</span>
                </button>
            </div>
        );
    };

    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${lcsTab === 'basicDetails' ? 'active' : ''}`}
                        onClick={() => setLcsView('basicDetails')}
                    >
                        Basic Details
                    </a>
                    <a className={`tab-nav ${lcsTab === 'po' ? 'active' : ''}`} onClick={() => setLcsView('po')}>
                        Purchase Orders
                    </a>
                    <a className={`tab-nav ${lcsTab === 'ao' ? 'active' : ''}`} onClick={() => setLcsView('ao')}>
                        Allocation Orders
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'inventory' ? 'active' : ''}`}
                        onClick={() => setLcsView('inventory')}
                    >
                        Inventory
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'receipt' ? 'active' : ''}`}
                        onClick={() => setLcsView('receipt')}
                    >
                        Receipt
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content">
                {lcsTab === 'basicDetails' && <BasicDetailsTab Btn={Btn} />}
                {lcsTab === 'po' && (
                    <KendoGridJquery
                        gridItem={gridItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'ao' && (
                    <KendoGridJquery
                        gridItem={allocationOrderItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'inventory' && (
                    <KendoGridJquery
                        gridItem={inventoryItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'receipt' && (
                    <KendoGridJquery
                        gridItem={receiptItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
