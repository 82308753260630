import { createSlice } from '@reduxjs/toolkit';
import { clientTableHeaders } from '../../utils/constants';

export const dataSlice = createSlice({
    name: 'users',
    initialState: {
        clients: [],
        headerTab: [],
        isLoading: true,
        currentPage: 0,
        columnStatus: clientTableHeaders.status,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        roles: [],
        headerData: [],
        wholeClients: [],
        emailSuccess: false,
        roleChangeStat: false,
        statusMessage: '',
        filterValues: {},
        statusChangeMessage: '',
        statusChangeStatus: false,
    },
    reducers: {
        fetchData: (state, action) => {
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
        },
        saveUsersData: (state, action) => {
            state.clients = action.payload?.info?.contents;
            state.totalPages = action.payload?.info?.totalNoOfPages;
            state.totalItems = action.payload?.info?.totalNoOfElements;
            state.filterValues = action.payload?.info?.distinctColumnValues;
        },
        fetchHeaderData: (state, action) => {
            state.headerTab = action.payload.headerTab?.info;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        listUserRoles: (_state, _action) => {
            // console.log(action);
        },
        saveRoles: (state, action) => {
            state.roles = action.payload?.info;
        },
        changeRole: (_state, _action) => {
            // console.log(action);
        },
        changeRoleSuccess: (state, action) => {
            state.roleChangeStat = action.payload;
        },
        changeStatus: (_state, _action) => {
            // console.log(action);
        },
        getHeaderData: (_state, _action) => {
            // console.log(action);
        },
        saveUsersHeaderData: (state, action) => {
            state.headerData = action.payload;
        },
        downloadFile: (_state, _action) => {
            // console.log(action);
        },
        clientLinkUser: (_state, _action) => {
            // console.log(action);
        },
        orgClientLinkUser: (_state, _action) => {
            // console.log('action');
        },
        clientUnlinkUser: (_state, _action) => {
            // console.log(action);
        },
        resendEmail: (_state, _action) => {
            // console.log(action);
        },
        listAllClient: (_state, _action) => {
            // console.log(action);
        },
        saveClient: (state, action) => {
            state.wholeClients = action.payload?.info;
        },
        emailSendSuccess: (state, action) => {
            state.emailSuccess = action.payload;
        },
        statuchangeMessage: (state, action) => {
            state.statusMessage = action.payload;
        },
        searchUsersData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.clients = action.payload?.content;
            state.totalPages = action.payload?.totalPages;
            state.totalItems = action.payload?.totalElements;
            state.filterValues = action.payload?.distinctColumnValues;
            state.isLoading = false;
        },
        statusChangeValidation: (state, action) => {
            state.statusChangeStatus = action.payload.status;
            state.statusChangeMessage = action.payload.message;
        },
    },
});

export const {
    fetchData,
    fetchHeaderData,
    setPageCount,
    saveUsersData,
    isLoadingFn,
    listUserRoles,
    saveRoles,
    changeRole,
    changeStatus,
    getHeaderData,
    saveUsersHeaderData,
    downloadFile,
    clientLinkUser,
    orgClientLinkUser,
    clientUnlinkUser,
    resendEmail,
    listAllClient,
    saveClient,
    emailSendSuccess,
    changeRoleSuccess,
    searchUsersData,
    statuchangeMessage,
    statusChangeValidation,
} = dataSlice.actions;
export default dataSlice.reducer;
