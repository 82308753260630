import { post, get } from '../../HttpMethods';
import fileDownload from 'js-file-download';

export const getInventoryData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    profileType: string;
}) => {
    try {
        const res = await post(
            `api/ord/inventory/${payload.profileType}?size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getInventoryDataDiscrepancies = async (payload: {
    currentPage: number;
    perPageCount: number;
    data: any;
    profileType: string;
}) => {
    try {
        const res = await post(
            `api/ord/discrepancy/list/${payload.profileType}?size=${payload.perPageCount}&page=${payload.currentPage}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getClientAndWarehouseValues = async (payload: { data: any; profileType: string }) => {
    try {
        const body = JSON.parse(JSON.stringify(payload.data));
        if (payload.data.clientName || payload.data?.blpName) {
            delete body.clientName;
            delete body.blpName;
        }
        const res = await post(`api/ord/inventory/clientwarehouse?profile=${payload.profileType}`, body, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getWarehouse = async (payload: { data: any; profileType: string; clientCode: string }) => {
    try {
        const body = JSON.parse(JSON.stringify(payload.data));
        if (payload.data.clientName || payload.data?.blpName) {
            delete body.clientName;
            delete body.blpName;
        }
        const res = await post(
            `api/ord/inventory/warehouse?profile=${payload.profileType}&clientCode=${payload.clientCode}`,
            body,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const exportInventoryList = async (type: string, data: any, profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(`${process.env.REACT_APP_BASE_URL}/api/ord/inventory/export/${profileType}?type=${type}`, {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data),
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'InventoryList.xlsx' : 'InventoryList.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const exportInventoryListDiscrepancy = async (type: string, data: any, profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(`${process.env.REACT_APP_BASE_URL}/api/ord/discrepancy/export/${profileType}?type=${type}`, {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data),
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'DiscrepancyList.xlsx' : 'DiscrepancyList.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
export const getInventoryLog = async (payload: { inventoryId: string }) => {
    try {
        const res = await get(`api/ord/inventory/log?inventoryId=${payload.inventoryId}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const viewAllDiscrepancy = async (payload: any) => {
    try {
        const res = await post(`api/ord/discrepancy/viewed/${payload?.profile}`, payload.data, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
