import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'count',
    initialState: {
        agent: {
            inactive: 0,
            active: 0,
            total: 0,
        },
        shipping: {
            inactive: 0,
            active: 0,
            total: 0,
        },
        vendor: {
            inactive: 0,
            active: 0,
            total: 0,
            draft: 0,
            submitted: 0,
        },
        client: {
            inactive: 0,
            active: 0,
            total: 0,
        },
        coloader: {
            inactive: 0,
            active: 0,
            total: 0,
            draft: 0,
            submitted: 0,
        },
        isLoading: false,
        payload: null,
    },
    reducers: {
        fetchCountData: (state, action) => {
            // state.isLoading = true;
            if (action.payload?.count) {
                state.agent = action.payload.count?.agent;
                state.client = action.payload.count?.client;
                state.vendor = action.payload.count?.vendor;
                state.shipping = action.payload.count?.shipping;
                state.coloader = action.payload.count?.coloader;
            }
            state.payload = action.payload.payload;
            // state.isLoading = false;
        },
    },
});

export const { fetchCountData } = dataSlice.actions;
export default dataSlice.reducer;
