import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './po-line.scss';
import AssignPoLineModal from './AssignPoLineModal';
import PoLinesTable from './PoLinesTable';
import PoLinesCompletionTable from './PoLinesCompletionTable';
import { useDispatch } from 'react-redux';
import {
    fetchAllPoLines,
    fetchPolineReceipt,
    removeSuccessFn,
    successAssigning,
} from '../../../../store/reducers/receipt/poLine';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { Position, success } from '../../../../utils/toast';
import DiscardModal from '../DiscardModal';
import ShowForPermissions from '../../../../ShowForPermissions';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import '../blp-receipt-header.scss';
import { useAppSelector } from 'src/hooks';
import { fetchReceiptHeaderList, selectReceiptHeader } from 'src/store/reducers/receipts/receiptHeaderReducer';
import ReceiptCancelModal from '../ReceiptHeader/ReceiptCancelModal';
import ReceiptHeaderTabs from 'src/pages/BLP/ReceiptDetails/ReceiptHeaderTabs';
import ReceiptDetailsNextButton from 'src/pages/BLP/ReceiptDetails/ReceiptDetailsNextButton';

const Index: React.FC = () => {
    const { isBlp, blpIntegrationsReceipt } = useUserProfile();
    const [_addedgrid, setaddedgrid] = useState(false);
    const [displayDiscard, setDisplayDiscard] = useState(false);
    const [selectedItemsAdded, setSelectedItemsAdded] = useState<any[]>([]);
    const [assignedItems, setassignedItems] = useState<any[]>([]);
    const { poLine, isLoading, inboundPos, assignedSuccess, removeSuccess } = useSelector(
        (state: RootState) => state.receiptPOline
    );
    const { receiptDetails } = useSelector((state: RootState) => state.addReceipt);
    const [assignModal, setassignModal] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const receiptHeader = useAppSelector(selectReceiptHeader);
    const [displayReceiptCancel, setDisplayReceiptCancel] = useState(false);
    useEffect(() => {
        dispatch(fetchPolineReceipt(id));
        dispatch(fetchAllPoLines(id));
        dispatch(
            fetchReceiptHeaderList({
                id,
            })
        );
    }, [id]);

    useEffect(() => {
        if (assignedSuccess) {
            success('PO lines have been added to Receipt', Position.TOP_RIGHT);
            dispatch(fetchPolineReceipt(id));
            dispatch(
                fetchReceiptHeaderList({
                    id,
                })
            );
            dispatch(successAssigning(false));
            dispatch(fetchAllPoLines(id));
        }
    }, [assignedSuccess]);
    useEffect(() => {
        if (removeSuccess) {
            success('PO lines have been removed from Receipt', Position.TOP_RIGHT);
            dispatch(fetchPolineReceipt(id));
            dispatch(
                fetchReceiptHeaderList({
                    id,
                })
            );
            dispatch(removeSuccessFn(false));
            dispatch(fetchAllPoLines(id));
        }
    }, [removeSuccess]);

    return (
        <div className="main-wrapper container">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="main-header-content-holder large-header">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link to="/blp/receipt" className="dropdown-arrow" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/blp/receipt" state={{ reload: true }}>
                                Receipt
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Receipt Details
                            </a>
                        </li>
                    </ul>
                    <div className="action-wrapper">
                        <div className="btn-holder discard-receipt-btn-holder">
                            <ShowForPermissions type="receipt" permission="create">
                                {receiptHeader?.receiptStatus !== 'Closed' && (
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title={'Save & Exit'}
                                        onClick={() => {
                                            navigate('/blp/receipt');
                                            success(
                                                `${localStorage.getItem('receiptNo')} has been saved`,
                                                Position.TOP_RIGHT
                                            );
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#saveIcon2">
                                                <title>Save & Exit</title>
                                            </use>
                                        </svg>
                                        <span className="button-text">Save & Exit</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                            <ShowForPermissions permission="create" type="receipt">
                                {isBlp && !blpIntegrationsReceipt && localStorage.getItem('receiptStatus') === 'Draft' && (
                                    <button
                                        onClick={() => setDisplayDiscard(true)}
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title="Discard Receipt"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#deleteIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Discard Receipt</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                            <ShowForPermissions permission="edit" type="receipt">
                                {isBlp &&
                                    !blpIntegrationsReceipt &&
                                    (receiptHeader?.receiptStatus === 'Ready to Receive' ||
                                        receiptHeader?.receiptStatus === 'Ready To Receive') && (
                                        <button
                                            onClick={() => setDisplayReceiptCancel(true)}
                                            className="app-btn app-btn-secondary black-outline-btn icon-button"
                                            title="Cancel Receipt"
                                        >
                                            <span className="button-text footer-button-text">Cancel Receipt</span>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>
                </div>
                <div className="pageheading-holder">
                    {/* <a href="/blp/receipt">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </a> */}
                    <h2 className="page-heading">{receiptDetails?.receiptNo || localStorage.getItem('receiptNo')}</h2>
                </div>
                <ReceiptHeaderTabs />
            </div>
            {localStorage.getItem('receiptStatus') === 'Draft' ? (
                <PoLinesTable
                    bpID={id}
                    addPoList={poLine ? poLine : []}
                    setaddedgrid={setaddedgrid}
                    selectedItemsAdded={selectedItemsAdded}
                    setSelectedItemsAdded={setSelectedItemsAdded}
                    setassignModal={setassignModal}
                />
            ) : (
                <PoLinesCompletionTable
                    bpID={id}
                    addPoList={id ? poLine : []}
                    originalData={id ? poLine : []}
                    selectedItemsAdded={selectedItemsAdded}
                    setSelectedItemsAdded={setSelectedItemsAdded}
                />
            )}

            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/blp/receipt/${id}/receipt-header`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <ReceiptDetailsNextButton page="polines" />
                </div>
            </div>
            {assignModal && (
                <AssignPoLineModal
                    setassignModal={setassignModal}
                    data={inboundPos}
                    setassignedItems={setassignedItems}
                    assignedItems={assignedItems}
                    id={id}
                />
            )}
            <DiscardModal discardModalVisibility={displayDiscard} setDiscardModalVisibility={setDisplayDiscard} />
            <ReceiptCancelModal
                receiptCancelModalVisibility={displayReceiptCancel}
                setReceiptCancelModalVisibility={setDisplayReceiptCancel}
            />
        </div>
    );
};
export default Index;
