/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import DetailModal from './DetailModal';
import { getConfigDetails, getConfigHeaderDetails } from 'src/services/apis/quoteAPi';
import '../ConifgHeader/index.scss';
import { useParams } from 'react-router-dom';

const Index: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [headerData, setHeaderData] = useState([]);
    const [mode, setMode] = useState('');
    const [title, setTitle] = useState('');
    const [detailData, setDetailData] = useState({
        CD_Key: '',
        CD_Value: '',
        CD_CH_Key: '',
        CD_Description: '',
        CD_Active: true,
    });

    const { type } = useParams();

    useEffect(() => {
        if (headerData.length === 0) {
            getConfigHeaderDetails()
                .then((res: any) => {
                    setHeaderData(res);
                    const typeArray = res.filter((r: any) => r.value === type);
                    if (typeArray && typeArray.length > 0) setTitle(typeArray[0].label.split('(')[0].trim());
                    else setTitle('Config Detail');
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    }, []);

    const refreshGrid = () => {
        const refresh: any = document.querySelector('.k-pager-refresh');
        if (refresh) {
            refresh?.click();
        }
    };

    //@ts-ignore
    window.addConfigDetail = () => {
        setMode('add');
        setModalView(true);
    };

    //@ts-ignore
    window.EditConfigDetails = (event: string, headerKey: string, detailKey: string) => {
        getConfigDetails(headerKey, detailKey)
            .then((res: any) => {
                setMode('edit');
                setDetailData({
                    CD_Key: res?.CD_Key,
                    CD_Value: res?.CD_Value,
                    CD_CH_Key: res?.CD_CH_Key,
                    CD_Description: res?.CD_UserDefinedText1,
                    CD_Active: res?.CD_Active,
                });
                setModalView(true);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    return (
        <div className="main-wrapper config-detail-page">
            <div className={`app-loader config-detail-page-loader ${loading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">{title}</h2>
                <div className="grid-section grid-p-0">
                    <div className="grid-holder">
                        {title && <LayoutWithColumns style={'report'} content={'qms-config-detail'} />}
                    </div>
                </div>
            </div>
            {modalView ? (
                <DetailModal
                    setModalView={setModalView}
                    mode={mode}
                    refreshGrid={refreshGrid}
                    detailData={detailData}
                    setDetailData={setDetailData}
                    headerData={headerData}
                />
            ) : null}
        </div>
    );
};

export default Index;
