import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'sailingbpao',
    initialState: {
        isLoading: false,
        contractData: [],
        contractSuccess: false,
        sailingCounts: {},
        sailingDataValues: [],
        selectedSailing: {},
        fakData: [],
        isLoading2: false,
        ports: [],
        sailingUpdate: false,
    },
    reducers: {
        checkContractData: (_state, _action) => {
            // console.log('recieved first');
        },
        checkContractResponse: (state, action) => {
            state.contractData = action.payload.data;
            state.contractSuccess = action.payload.status;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        isLoadingFn2: (state, action) => {
            state.isLoading2 = action.payload;
        },
        removePo: (_state, _action) => {
            // console.log(action);
        },
        searchData: (_state, _action) => {
            // console.log(action);
        },
        getSailingCounts: (_state, _action) => {
            // console.log(action);
        },
        saveSailingcount: (state, action) => {
            state.sailingCounts = action.payload;
        },
        getFakData: (_state, _action) => {
            // console.log(action);
        },
        saveFAKdata: (state, action) => {
            state.fakData = action.payload?.sailingScheduleLists;
        },
        fetchSailingData: (_state, _action) => {
            // console.log(action);
        },
        saveSailingData: (state, action) => {
            state.sailingDataValues = action.payload;
        },
        addToBp: (_state, _action) => {
            // console.log(action);
        },
        fetchSelectedSailing: (_state, _action) => {
            // console.log(action);
        },
        viewSelectedSailing: (state, action) => {
            state.selectedSailing = action.payload;
        },
        removeSailing: (_state, _action) => {
            // console.log(action);
        },
        getPortValues: (_state, _action) => {
            // console.log(action);
        },
        savePortValues: (state, action) => {
            state.ports = action.payload;
        },
        sailingUpdated: (state, action) => {
            state.sailingUpdate = action.payload;
        },
    },
});

export const {
    checkContractData,
    isLoadingFn,
    checkContractResponse,
    removePo,
    searchData,
    getSailingCounts,
    saveSailingcount,
    fetchSailingData,
    saveSailingData,
    addToBp,
    fetchSelectedSailing,
    viewSelectedSailing,
    removeSailing,
    getFakData,
    saveFAKdata,
    isLoadingFn2,
    getPortValues,
    savePortValues,
    sailingUpdated,
} = dataSlice.actions;
export default dataSlice.reducer;
