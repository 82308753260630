import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import BSTooltip from 'src/components/common/BSTooltip';

import { RootState } from 'src/store/reducers';
import { markSingleRead } from 'src/store/reducers/authReducer';

import '../todo-grid-view.scss';

const Index: React.FC<any> = ({ path }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { todoItem, isLoading } = useSelector((state: RootState) => state.dashboardTodo);

    return (
        <>
            {!todoItem?.bpListingDtos?.length && !isLoading && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No recent activity from the last 30 Days</p>
                </div>
            )}
            <div className="view-list-grid awaiting-forwarder">
                {todoItem?.bpListingDtos?.map((bp: any) => {
                    const totalScheduleCount = bp?.totalSchedulesCount;
                    return (
                        <div className={`row-wrapper  ${bp?.isViewed === false ? ' click-active' : ''}`}>
                            <div className="list-grid">
                                <>
                                    <div className="grid-child-item col-booking-no">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Booking No.</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">{bp?.bpNumber}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-booking-no">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Last Modified</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">
                                                        {bp?.LastModifiedDateandTime
                                                            ? moment(bp?.LastModifiedDateandTime).format(
                                                                  'DD-MM-YYYY HH:mm:ss'
                                                              )
                                                            : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {bp?.clientNames?.length > 0 && (
                                        <div className="grid-child-item col-client">
                                            <div className="grid-child-data list-view-items">
                                                {bp?.clientNames?.map((cl: any) => (
                                                    <div className="content-list-wrapper" key={cl}>
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Client</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="sub-content selected-color">{cl}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    <div className="grid-child-item col-destination">
                                        <div className="grid-child-data">
                                            <div className="des-progress-bar">
                                                <div className="destination begin-journey">
                                                    <div className="point12">
                                                        <div className="point1">{bp?.originPortName}</div>
                                                        {bp?.originPortCode && (
                                                            <div className="point-name">({bp?.originPortCode})</div>
                                                        )}
                                                    </div>
                                                    <div className="date-time">
                                                        ETD
                                                        <span className="time-field">
                                                            {bp?.plannedEtd
                                                                ? moment(bp?.plannedEtd).format('DD-MM-YYYY')
                                                                : '-'}
                                                        </span>
                                                        {bp?.bpType?.toLowerCase() === 'air' &&
                                                            bp?.isMultipleSchedule && (
                                                                <span className="etd-text">Earliest ETD</span>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="destination center-journey">
                                                    <span className="center-point">
                                                        {bp?.transitPortCount
                                                            ? `1 stop${bp?.transitPortCount > 1 ? 's' : ''}`
                                                            : 'Direct'}
                                                    </span>
                                                </div>
                                                <div className="destination end-journey">
                                                    <div className="point1">{bp?.destinationPortName}</div>
                                                    {bp?.destinationPortCode &&
                                                        bp?.destinationPortName !== 'Multi destination' && (
                                                            <div className="point-name">
                                                                ({bp?.destinationPortCode})
                                                            </div>
                                                        )}
                                                    <div className="time-field">
                                                        {bp?.plannedEta
                                                            ? moment(bp?.plannedEta).format('DD-MM-YYYY')
                                                            : '-'}
                                                        <span className="date-time">ETA</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                        <div className="status-badge">
                                            {bp?.bpType?.toLowerCase() === 'air' && bp?.isMultipleSchedule && (
                                                // <BSTooltip
                                                //     id="view-option"
                                                //     title={`${totalScheduleCount} Schedule Options`}
                                                //     position="top"
                                                // >

                                                <div className="item-count-status-holder scheduletooltip">
                                                    <span className="custom-tooltip">{`${totalScheduleCount} Schedule Options`}</span>
                                                    <div className="grid-item-count-status status-ui disabled-status">
                                                        <span className="status-text">{bp?.totalSchedulesCount}</span>
                                                        <span className="icon-status">
                                                            <svg className="svg-icon aeroplane-icon">
                                                                <use xlinkHref="#aeroplaneIcon">
                                                                    <title>Aeroplace</title>
                                                                </use>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                </div>
                                                // </BSTooltip>
                                            )}
                                            <div className="item-count-status-holder">
                                                <div className="grid-item-count-status status-ui disabled-status">
                                                    <span className="status-text">{bp?.bpType}</span>
                                                </div>
                                            </div>
                                            {bp?.bpType === 'BCN' && (
                                                <div className="item-count-status-holder">
                                                    <div className="grid-item-count-status status-ui disabled-status">
                                                        <span className="status-text">
                                                            {bp?.bpBCNType === 'PAR' ? 'PARENT' : 'CHILD'}
                                                        </span>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="item-count-status-holder">
                                                <div className="grid-item-count-status status-ui disabled-status">
                                                    <span className="status-text">
                                                        {bp?.poNumber?.length}{' '}
                                                        {bp?.bpType === 'AO'
                                                            ? `AO${bp?.poNumber?.length > 1 ? 's' : ''}`
                                                            : `PO${bp?.poNumber?.length > 1 ? 's' : ''}`}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="item-count-status-holder">
                                                <div className="grid-item-count-status status-ui disabled-status">
                                                    <span className="status-text">{bp?.totTEU} TEU</span>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            title="View Booking"
                                            className="app-btn app-btn-secondary grid-btn"
                                            onClick={() => {
                                                if (bp?.notificationIds) {
                                                    bp?.notificationIds?.forEach((notifications: string) => {
                                                        dispatch(
                                                            markSingleRead({
                                                                id: notifications,
                                                                status: 'read',
                                                            })
                                                        );
                                                    });
                                                }
                                                navigate(path(bp.bpId, bp.bpType));
                                            }}
                                        >
                                            <span className="button-text footer-button-text">View Booking</span>
                                        </button>
                                    </div>
                                </>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
export default Index;
