// fetchForwarderData
import { createSlice } from '@reduxjs/toolkit';

interface DocumentState {
    fileTypes: any[];
    uploadResult: any[];
    deleteResult: any[];
    notesUnread: boolean;
}

const initialState: DocumentState = {
    // eslint-disable-next-line no-undefined
    fileTypes: [],
    uploadResult: [],
    deleteResult: [],
    notesUnread: false,
};

export const dataSlice = createSlice({
    name: 'documents',
    initialState,
    reducers: {
        fetchDocumentTypes: (state, action) => {
            state.fileTypes = action.payload;
        },
        cixUploadDoc: (state, action) => {
            state.uploadResult = action.payload;
        },
        cixUploadRateDoc: (state, action) => {
            state.uploadResult = action.payload;
        },
        resetUploadResult: (state, _action) => {
            state.uploadResult = [];
        },
        cixDeleteDoc: (state, action) => {
            state.deleteResult = action.payload;
        },
        resetDeleteResult: (state, _action) => {
            state.deleteResult = [];
        },
        updateNotesRead: (state, action) => {
            state.notesUnread = action.payload;
        },
    },
});

export const {
    fetchDocumentTypes,
    cixUploadDoc,
    cixUploadRateDoc,
    resetUploadResult,
    cixDeleteDoc,
    resetDeleteResult,
    updateNotesRead,
} = dataSlice.actions;
export default dataSlice.reducer;
