import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

interface GridProps {
    rs: any;
}

const GridLayout = (gridProps: GridProps) => {
    const token = `${localStorage.getItem('token') ?? ''}`;
    if (!token) {
        return null;
    }

    return (
        <Helmet>
            <script type="text/x-kendo-template" id="approval-template">
                {`
                #
                let ico = ''
                switch(RI_CD_Status){
                    case "DFT":
                        ico = '<i class="fa-duotone fa-user fa-xl" title="Draft \\n\\n Please Submit For Approval" style="--fa-primary-color: \\\\#5c626a; --fa-secondary-color: \\\\#5c626a;"></i>'
                        break;
                    case "REJ":
                        ico = '<i class="fa-duotone fa-user-xmark fa-xl" title="Rejected \\n\\n Comments: \\n Are you Joking! " style="--fa-primary-color: \\\\#ff0000; --fa-secondary-color: \\\\#ff0000;"></i>'
                        break;
                    case "WAI":
                        ico = '<i class="fa-duotone fa-user-minus fa-xl" title="Waiting \\n\\n Submitted for approval but not yet approved" style="--fa-primary-color: \\\\#ffa500; --fa-secondary-color: \\\\#ffa500;"></i>'
                        break;
                    case "APR":
                        ico = '<i class="fa-duotone fa-user-check fa-xl" title="Approved" style="--fa-primary-color: \\\\#219b08; --fa-secondary-color: \\\\#219b08;"></i>'
                        break;
                }
                #

                #=ico#
            `}
            </script>
            <script type="text/x-kendo-template" id="errors-template">
                {`
                    #
                    try{
                    if(inserted){
                        # <i class="fa-duotone fa-hexagon-exclamation fa-xl" style="--fa-primary-color: \\\\#005eff ; --fa-secondary-color: \\\\#005eff;" title="Inserted"></i> #
                    }else if(error){
                        let messages = ""
                        let colour = "\\\\#ec7d22"
                        let icon = "fa-hexagon-exclamation"
                        for(const errorType of error){
                            messages += "\\n"+errorType.message
                            if(errorType.type == "ERR"){
                                colour = "\\\\#ff004c"
                            }else if(errorType.type == "DUP"){
                                console.log()
                                colour = "\\\\#ee9d2b"
                                icon = "fa-hexagon-minus"
                            }
                        }
                        # <i class="fa-duotone #:icon# fa-xl" style="--fa-primary-color: #:colour# ; --fa-secondary-color: #:colour#;" title="#:messages#"></i> #
                    }
                    }catch(e){
                        
                    }
                    #
                    `}
            </script>
            <script>
                {`

    function importExcel(gridId, RS_RA) {

        const upload = $('<input name="files" accept=".xlsx" type="file" />');
        const grid = $("#"+gridId).getKendoGrid();

        const onSelect = (e) => {
            var deferred = new $.Deferred();
            var fileToUpload = e.target.files[0];
            var workbook = new kendo.spreadsheet.Workbook({
                sheets: {
                    rows: 200,
                    columns: 15
                }
            });

            kendo.spreadsheet.readExcel(fileToUpload, workbook, deferred);

            upload.off("change", onSelect);
            upload.remove();

            kendo.ui.progress($("#"+gridId), true);

            toast("info","Please wait while we import the excel document. This can take some time")

            deferred.then(function () {
                const excelSheet = workbook.toJSON().sheets[0];

                console.log(excelSheet)

                //const columns = excelSheet.rows.shift().cells.map(cell => ({title: cell.value, field: cell.value.replace(/\s/, '_')}));
                //const data = excelSheet.rows.map(row => row.cells.reduce((a, v, i) => ({...a, [columns[i].field]: v.value}), {id: kendo.guid()}));

                let i =0
                for(const excelRow of excelSheet.rows){
                    i ++
                    if(i > 1){
                        if(typeof excelRow.cells[0].value !== 'undefined'){
                            var dataSource = grid.dataSource;
                            let row = {}
                            row.RI_PK = kendo.guid();
                            row.RS_RA = RS_RA
                            row.RI_Code = excelRow.cells[0].value
                            row.RI_CD_UOM = excelRow.cells[1].value
                            row.OR_OrgCode = excelRow.cells[2].value
                            row.RI_TransitDays = excelRow.cells[3].value
                            row.RI_Service = excelRow.cells[4].value
                            row.RI_PortOfOrigin = excelRow.cells[5].value
                            RI_X_POL_CountryName = excelRow.cells[6].value
                            RI_X_POL_PortName = excelRow.cells[7].value
                            row.RI_PortOfDestination = excelRow.cells[8].value
                            RI_X_POD_CountryName = excelRow.cells[9].value
                            RI_X_POD_PortName = excelRow.cells[10].value
                            row.RI_CD_Currency = excelRow.cells[11].value
                            row.RI_Buy = excelRow.cells[12].value
                            row.RI_Sell = excelRow.cells[13].value
                            row.RI_Comment = excelRow.cells[14].value
                            row.RI_X_InsertedFromExcel = true
                            // hack
                            row.RI_Calc_TargetSell = 0
                            row.RI_MinimumMarkup = 0
                            row.RI_MinimumMarkupPercent = 0
                            row.RI_CD_Status = "NEW"
                            row.RI_X_RSCode = gridId
                            let newRow = dataSource.insert(i, row);
                            newRow.set("inserted", true)
                            newRow.set("RI_CD_Status", "DFT")
                        }else{
                            break
                        }
                    }
                }

                kendo.ui.progress($("#"+gridId), false);
                grid.saveChanges();

                // grid.setOptions({
                //     columns: columns,
                //     dataSource: {
                //         data: data
                //     }
                // });
            });

        }


        upload.on("change", onSelect);
        upload.click();
    }

                function updateRow(grid, id, field, value) {
                    // Get the Kendo Grid instance
                    var gridInstance = $("#"+grid).data("kendoGrid");
                    var dataItem = gridInstance.dataSource.get(id);
                    if(dataItem.error?.length > 0){
                        toast("warning","Warning: This row cannot be approved or rejected as it has errors")
                        return
                    }

                    if (dataItem) {
                    if(dataItem.RI_CD_Status == "WAI" && value == "WAI") value = "APR"
                        // Update the data item
                        dataItem.set(field, value);
                        dataItem.set("RI_CD_Status",value)
                    } else {
                        console.log("Item not found");
                    }
            }  
                    
            function deleteRow(grid,id) {
            var grid = $("#"+grid).data("kendoGrid");
            var dataSource = grid.dataSource;
            var item = dataSource.get(id);
                if (item.RI_CD_Status || undefined !== "APR") {
                    dataSource.remove(item);
                    grid.saveChanges();
                    //toast("info","Remember to save after deleting all your records")
                }else{
                    toast("warning","This has been approved so cannot be deleted")
                }
            }

            function copyRow(grid,id) {
                var grid = $("#"+grid).data("kendoGrid");
                var dataSource = grid.dataSource;
                var item = dataSource.get(id);
                var index = dataSource.indexOf(item);
                var clonedRow = $.extend({}, item.toJSON());

                // Remove the id from the cloned data to avoid conflicts
                clonedRow.RI_PK = undefined
                clonedRow.error = undefined
                clonedRow.RI_PK = kendo.guid();

                // Add the cloned data as a new row
                var newRow = dataSource.insert(index + 1, clonedRow);
                newRow.set("inserted", true)
                newRow.set("RI_CD_Status", "DFT")
                //grid.saveChanges();
            }

            function approveAll(grid){
                var grid = $("#"+grid).data("kendoGrid");
                var dataSource = grid.dataSource;
                var data = dataSource.data();

                for (var i = 0; i < data.length; i++) {
                    if(data[i].error?.length > 0){
                        // do nothing
                    }else{
                        if(data[i].RI_CD_Status == "REJ"){
                            // do nothing
                        }else if(data[i].RI_CD_Status == "WAI"){
                            data[i].set("RI_CD_Status", "APR");
                            data[i].set("RI_X_CD_Status", "APR");
                        }else{
                            data[i].set("RI_CD_Status", "WAI");
                            data[i].set("RI_X_CD_Status", "WAI");
                        }
                    }
                }

                //dataSource.sync();
            }

            `}
            </script>
            <script type="text/x-kendo-template" id="markup-template">
                {`
            # 
            let markup = kendo.toString(((RI_Sell - RI_Buy) / RI_Buy * 100), 'n2') + "%";
            let valid = (RI_Sell >= RI_Calc_TargetSell)

            //if(isFinite(markup) || isNaN(markup)) markup = 0

            let message = "Markup does not satisfy the minimum sellout rules: \\n\\n 1. Cost plus $"+RI_MinimumMarkup+" or, \\n 2. Cost plus "+ RI_MinimumMarkupPercent+"% \\n\\n The target is determined based on the higher of these two values \\n\\n Note: This is defined against the Quote Item"
            let markupColor = 'green'
            let ico = '<i class="fa-duotone fa-hexagon-check fa-xl" style="--fa-primary-color: \\\\#219b08; --fa-secondary-color: \\\\#219b08;"></i>'


            if(!valid){
                ico = '<i class="fa-duotone fa-hexagon-exclamation fa-xl" title="'+message+'" style="--fa-primary-color: \\\\#ffa500; --fa-secondary-color: \\\\#ffa500;"></i>'
                markupColor = 'red'
            }

            if(RI_Buy == 0 && RI_Sell == 0){
                markup = "N/A"
                markupColor = 'blue'
                message = "This will not appear on the quote output as no buy or sell is entered"
                ico = '<i class="fa-duotone fa-hexagon-exclamation fa-xl" title="'+message+'" style="--fa-primary-color: \\\\#0055ff; --fa-secondary-color: \\\\#0055ff;"></i>'
            }

            if(RI_Sell < RI_Buy){
                message = "This is being sold at a loss!"
                markupColor = 'red'
                ico = '<i class="fa-duotone fa-hexagon-xmark fa-beat fa-xl" title="'+message+'" style="--fa-primary-color: \\\\#ff0000; --fa-secondary-color: \\\\#ff0000;"></i>'
            }

            
            #
            #=ico# <span style="color: #: markupColor #;"> #= markup #</span> 
            `}
            </script>
            <script>
                {`
                    function editNumber(container, options) {
                    $('<input data-bind="value:' + options.field + '"/>')
                        .appendTo(container)
                        .kendoNumericTextBox({
                        spinners : false,
                        change: function (o) {
                                                options.model.set("RI_CD_Status", "DFT");
                                            }
                    });
                }

                function refreshGrid(id){
                    var grid = $("#"+id)
                    if (grid.length) {
                        var kendoGrid = grid.data("kendoGrid");
                        if (kendoGrid) {
                        kendoGrid.dataSource.read(); // or kendoGrid.refresh();
                        }
                    }
                }
                    `}
            </script>
            <script>
                {`
                    $(document).ready(function () {

                    const height = $(window).height - ($(".main-header-content-holder").height() + $(".header").height())

                $("#tabstrip").kendoTabStrip({
                        animation:  {
                            open: {
                                effects: "fadeIn"
                            }
                        },
                        select : function(e, options){
                        console.log($(e.item).data("code"))
                            refreshGrid($(e.item).data("code"))
                        }
                    });

                    var tabstrip = $("#tabstrip").kendoTabStrip().data("kendoTabStrip");
        tabstrip.select(tabstrip.tabGroup.children("li:first"));

                const rs = ${gridProps.rs}

                for (const gridProps of rs.RS_RateSections){
                setTimeout('', 5000);
                // TODO - param
                    $("#"+gridProps.RS_Code).kendoGrid({
                        dataSource: {
                            serverPaging: true,
                            serverSorting: true,
                            serverFiltering: true,
                            pageSize: 20,
                            //sort : { field: "RI_CD_Status", dir: "desc" },
                            transport: {
                                read: {
                                    url: '${externalBaseUrl}/cix/scr/qms/config/rate/'+gridProps.RS_RA+'/'+gridProps.RS_PK,
                                    headers: {
                                        Authorization: 'Bearer: ${token}'
                                    },
                                    dataType: 'json',
                                    timeout: 240000
                                        },
                                update: {
                                    url: '${externalBaseUrl}/cix/scr/qms/config/rate/'+gridProps.RS_RA+'/'+gridProps.RS_PK,
                                    headers: {
                                        Authorization: 'Bearer: ${token}'
                                    },
                                    dataType: 'json',
                                    type: "PATCH",
                                    contentType: 'application/json',
                                    timeout: 240000
                                },
                                destroy: {
                                    url: '${externalBaseUrl}/cix/scr/qms/config/rate/'+gridProps.RS_RA+'/'+gridProps.RS_PK,
                                    headers: {
                                        Authorization: 'Bearer: ${token}'
                                    },
                                    dataType: 'json',
                                    type: "DELETE",
                                    contentType: 'application/json'
                                },
                                create: {
                                url: '${externalBaseUrl}/cix/scr/qms/config/rate/'+gridProps.RS_RA+'/'+gridProps.RS_PK,
                                headers: {
                                    Authorization: 'Bearer: ${token}'
                                },
                                timeout: 240000,
                                dataType: 'json',
                                type: "POST",
                                contentType: 'application/json'
                                },
                                parameterMap: function (data, type) {
                                    if (type == 'update' || type == 'create' || type == 'destroy') {
                                        data = JSON.stringify(data);
                                    }
                                    return data;
                                }
                            },
                            schema: {
                                total: function (response) {
                                    return response.total;
                                },
                                data: function (response) {
                                    return response.data;
                                },
                                model: {
                                    id: "RI_PK",
                                    fields: {
                                    RI_PK: { type: "string" },
                                    RI_PortOfOrigin: { type: "string", defaultValue : "ANY" },
                                    OriginCountry: { type: "string", defaultValue : "Any Ports (All)"},
                                    RI_PortOfDestination: { type: "string", defaultValue : "ANY"  },
                                    DestinationCountry: { type: "string", defaultValue : "Any Ports (All)" },
                                    OR_OrgCode: { type: "string" },
                                    OR_OrgName: { type: "string" },
                                    RI_Comment: { type: "string"},
                                    RI_Code: { type: "string"},
                                    RI_Description: { type: "string"},
                                    RI_CD_UOM: { type: "string" },
                                    UOM_CD_Value: { type: "string" },
                                    RI_Sell: { type: "number" },
                                    RI_Buy: { type: "number", validation: { min: 1 }, defaultValue: 1 },
									RS_Code: {type: "string", editable: false, defaultValue: gridProps.RS_Code},
									RI_CD_Currency: {type: "string", defaultValue: "AUD"},
                                    RI_Calc_TargetSell : { type: "number", editable: false},
                                    RI_MinimumMarkup : { type: "number"}, 
                                    RI_MinimumMarkupPercent : { type: "number"},
                                    RI_TransitDays: { type: "number" },
                                    RI_Service: {type: "string"},
                                    RI_CD_Status: {type: "string", editable: true},
                                    RI_CD_StatusMessage: {type: "string", editable: false},
                                    RI_X_CD_Status : {type : "string"},
                                    error : {type: "object"},
                                    inserted : {type: "boolean"},
                                    RS_RA : {type: "string", defaultValue: gridProps.RS_RA},
                                    RI_X_InsertedFromExcel : {type: "boolean"},
                                    RI_X_RSCode : {type: "string"},
                                    RI_X_POL_CountryName : {type : "string"},
                                    RI_X_POL_PortName : {type : "string"},
                                    RI_X_POD_PortName : {type : "string"},
                                    RI_X_POD_CountryName : {type : "string"}
                                }
                                }
                            },
                            batch: true
                        },
                        toolbar: [
                        { 
                            name: "Import", 
                            template: '<button class="k-button app-btn icon-button app-btn-secondary" onclick="importExcel(\\''+gridProps.RS_Code+'\\',\\''+gridProps.RS_RA+'\\')" data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-file-import fa-2xl"></i>Import</button>',
                        },
                        {
                        name:"create",
                        template:
                                '<button class="k-button k-grid-add app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk-pen fa-2xl" style="--fa-primary-color: \\\\#0e9fc4; --fa-secondary-color: \\\\#0e9fc4;"></i>New</button>',
                        },
                         {name : "save" ,
                         template:
                                '<button class="k-button k-grid-save-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk fa-2xl" style="--fa-primary-color: \\\\#003cb3; --fa-secondary-color: \\\\#003cb3;"></i>Save</button>',
                         }, 
                         {name : "cancel" ,
                         template:
                                '<button class="k-button k-grid-cancel-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk-circle-xmark fa-2xl" style="--fa-primary-color: \\\\#ff004c; --fa-secondary-color: \\\\#ff004c;"></i>Cancel</button>',
                         }, 
                         {
                         name : "dd",
                         template : '<button class="k-button app-btn icon-button app-btn-secondary" onclick="approveAll(\\''+gridProps.RS_Code+'\\')"  data-bs-toggle="tooltip" data-bs-placement="left" title="Approve or Submit for Approval all rates that are not in an error state \\n\\n Note: If you do not have permissions to approve rates they will go to awaiting approval"><i class="fa-duotone fa-list-check fa-2xl" style="--fa-primary-color: \\\\#219b08; --fa-secondary-color: \\\\#219b08;"></i>Approve All</button>',
                         },
                         {
                            name: 'search',
                            // template: '<input type="search" id="searchBox" class="k-textbox llama" style="width: 250px"/>',
                        }
                            ],
                        selectable: "multiple row",
                        navigatable: true,
                        height: 500,
                        groupable: true,
                        resizable: true,
                        sortable: true,
                        pageable: {
                            refresh: true,
                            pageSizes: [10, 25, 100, 500],
                            buttonCount: 5,
                            alwaysVisible: true
                        },

                        columns: [
                        {
                        title: " ",
                        width: 40,
                        template: kendo.template($("#errors-template").html())
                        },
                            {
                                title: "Rate",
                                columns: [
                                    {
                                        field: "RI_Code",
                                        title: "Code",
                                        width: 50,
                                        attributes: {
                                            "class" : "hub-code-cell",
                                            
                                        },
                                        editor: function (container, options) {
                                        var input = $('<input required name="' + options.field + '" data-value-primitive="true" style="width:400px" />')
										console.log(options)
                                        // append to the editor container
                                        input.appendTo(container);
                                        input.kendoDropDownList({
                                            dataTextField: "QI_LongDescription",
                                            dataValueField: "QI_Code",
                                            template: '<span class="hub-code">#:QI_Code# </span> #:QI_LongDescription#',
                                            dataSource: {
                                                serverFiltering: true,

                                                transport: {
                                                    read: {
                                                        // this needs to be worked on for the user's saved filters.
                                                        url: "${externalBaseUrl}/cix/scr/admin/config/quoteitem/"+gridProps.RS_Code+"/QI_Code/QI_LongDescription",
                                                        dataType: 'json',
                                                        headers: {
                                                            Authorization: 'Bearer: ${token}'
                                                        },
                                                    },
                                                },
                                                schema: {
                                                    data: function (response) {
                                                        return response.data;
                                                    },
                                                },
                                            },
                                            filter: "contains",
                                            minLength: 2,
                                                enforceMinLength: true,
                                            select: function (o) {
                                                options.model.set("RI_QI",o.dataItem.QI_PK)
                                                options.model.set("RI_Description", o.dataItem.QI_LongDescription);
                                                options.model.set("RI_CD_Status", "DFT");
                                                options.model.set("RI_CD_Currency",o.dataItem.QI_CD_DefaultCurrency)
                                            }
                                        })
                                    }                                        
                                    },                                    
                                    {
                                        field: "RI_Description",
                                        title: "Name",
                                        editor: function (container, options) {
                                         var input = $('<input required name="' + options.field + '" data-value-primitive="true" disabled />')
                                        },
                                        attributes : {
                                            "class" : "hub-cell-clip"
                                        },
                                    },
                                        
                                    ]
                            },
                            {
                                title: "UOM",
                                columns: [
                                    {
                                        field: "RI_CD_UOM",
                                        title: "Code",
                                        width: 50,
                                        attributes: {
                                            "class" : "hub-code-cell"
                                        },
                                        
                                        editor: function (container, options) {
                                            var input = $('<input required name="' + options.field + '" data-value-primitive="true" style="width:400px" />')

                                            // append to the editor container
                                            input.appendTo(container);

                                            input.kendoDropDownList({
                                                dataTextField: "CD_UserDefinedText4",
                                                dataValueField: "CD_Key",
                                                template: '<span class="hub-code">#:CD_Key# </span> #:CD_UserDefinedText4#',
                                                popup: {
                                                    origin: "top left"
                                                },
                                                dataSource: {
                                                    serverFiltering: true,
                                                    pageSize: 500,
                                                    transport: {
                                                        read: {
                                                            // this needs to be worked on for the user's saved filters.
                                                            url: function(){
                                                            console.log(options)
                                                            return "${externalBaseUrl}/cix/scr/admin/config/QTE_UOM/"+options.model.RI_QI+"/CD_Key/CD_UserDefinedText4"
                                                            },
                                                            dataType: 'json',
                                                            headers: {
                                                                "Authorization": 'Bearer: ${token}',
                                                            },
                                                        },
                                                    },
                                                    schema: {
                                                        data: function (response) {
                                                            return response.data;
                                                        },
                                                    },
                                                },
                                                filter: "contains",
                                                minLength: 2,
                                                enforceMinLength: true,
                                                select: function (o) {
                                                    options.model.set("UOM_CD_Value", o.dataItem.CD_UserDefinedText4);
                                                    options.model.set("RI_CD_Status", "DFT");
                                                }
                                            })
                                        }
                                    }, {
                                        field: "UOM_CD_Value",
                                        title: "Name",
                                        editor: function (container, options) {
                                         var input = $('<input required name="' + options.field + '" data-value-primitive="true" disabled />')
                                        },
                                        attributes : {
                                            "class" : "hub-cell-clip"
                                        },
                                    }]
                            }
                            ,
                            {
                                title: "Provider",
                                headerTemplate : '<i class="fa-duotone fa-buildings"></i> &nbsp; Provider',
                                columns: [
                                    {
                                        field: "OR_OrgCode",
                                        title: "Code",
                                        width: 95,
                                        attributes: {
                                            "class" : "hub-code-cell"
                                        },
                                        editor: function (container, options) {
                                            var input = $('<input required name="' + options.field + '" data-value-primitive="true" style="width:600px" />')

                                            // append to the editor container
                                            input.appendTo(container);

                                            // initialize a dropdownlist
                                            input.kendoDropDownList({
                                                dataTextField: "OR_UserDefinedText4",
                                                dataValueField: "OR_OrgCode",
                                                template: '<span class="hub-code-org">#:OR_OrgCode#</span> #:OR_UserDefinedText4#',
                                                popup: {
                                                    origin: "top left"
                                                },
                                                dataSource: {
                                                    serverFiltering: true,
                                                    transport: {
                                                        read: {
                                                            // this needs to be worked on for the user's saved filters.
                                                            url: function(){
                                                                const RA_PK = options.model.RS_RA
                                                                const RI_Code = options.model.RI_Code
                                                                return "${externalBaseUrl}/cix/scr/admin/organisation/lst/OR_OrgCode/OR_UserDefinedText4?RA="+RA_PK+"&RI_Code="+RI_Code
                                                            },
                                                            dataType: 'json',
                                                            headers: {
                                                                "Authorization": 'Bearer: ${token}',
                                                            },
                                                        },
                                                    },
                                                    schema: {
                                                        data: function (response) {
                                                            return response.data;
                                                        },
                                                    },
                                                },
                                                filter: "contains",
                                                minLength: 2,
                                                enforceMinLength: true,
                                                select: function (o) {
                                                    options.model.set("OR_OrgName", o.dataItem.OR_UserDefinedText4);
                                                    options.model.set("RI_CD_Status", "DFT");
                                                }
                                            })
                                        }
                                    }, {
                                        field: "OR_OrgName",
                                        title: "Name",
                                        attributes : {
                                            "class" : "hub-cell-clip"
                                        },
                                        editor: function (container, options) {
                                         var input = $('<input required name="' + options.field + '" data-value-primitive="true" disabled />')
                                        }
                                    },
                                                                        {
                                        field: "RI_TransitDays",
                                        title: "Trans.",
                                        width: 50,
                                        hidden : (gridProps.RS_Code !== "FRT"),
                                        editor: editNumber
                                    },
                                    {
                                        field: "RI_Service",
                                        title: "Service",
                                        width: 80,
                                        hidden : (gridProps.RS_Code !== "FRT")
                                    },]
                            },
                            {
                                title: "Origin",
                                headerTemplate : '<i class="fa-duotone fa-anchor"></i> &nbsp; Origin',
                                columns: [
                                    {
                                        field: "RI_PortOfOrigin",
                                        title: "Code",
                                        width: 60,
                                        attributes: {
                                            "class" : "hub-code-cell"
                                        },
                                        
                                        editor: function (container, options) {
                                            var input = $('<input required name="' + options.field + '" data-value-primitive="true" style="width:400px" />')

                                            // append to the editor container
                                            input.appendTo(container);

                                            // initialize a dropdownlist
                                            input.kendoDropDownList({
                                                dataTextField: "Description",
                                                dataValueField: "Code",
                                                template: '<span class="hub-code">#:Code#</span> #:Description#',
                                                popup: {
                                                    origin: "top left"
                                                },
                                                dataSource: {
                                                    serverFiltering: true,
                                                    pageSize: 100,
                                                    transport: {
                                                        read: {
                                                            // this needs to be worked on for the user's saved filters.
                                                            url: "${externalBaseUrl}/cix/scr/admin/config/qmsports/lst/Code/Description",
                                                            dataType: 'json',
                                                            headers: {
                                                                "Authorization": 'Bearer: ${token}',
                                                            },
                                                        },
                                                    },
                                                    schema: {
                                                        data: function (response) {
                                                            return response.data;
                                                        },
                                                    },
                                                },
                                                filter: "contains",
                                                minLength: 2,
                                                enforceMinLength: true,
                                                select: function (o) {
                                                    options.model.set("OriginCountry", o.dataItem.Description);
                                                    options.model.set("RI_CD_Status", "DFT");
                                                }
                                            })
                                        }
                                    }, {
                                        field: "OriginCountry",
                                        title: "Name",
                                        editor: function (container, options) {
                                         var input = $('<input required name="' + options.field + '" data-value-primitive="true" disabled />')
                                        },
                                        attributes : {
                                            "class" : "hub-cell-clip"
                                        }
                                    }]

                            }
                            ,
                            {
                                title: "Destination",
                                headerTemplate : '<i class="fa-duotone fa-anchor"></i> &nbsp; Destination',
                                columns: [
                                    {
                                        field: "RI_PortOfDestination",
                                        title: "Code",
                                        width: 60,
                                        attributes: {
                                            "class" : "hub-code-cell"
                                        },
                                        editor: function (container, options) {
                                            var input = $('<input required name="' + options.field + '" data-value-primitive="true" style="width:400px" />')

                                            // append to the editor container
                                            input.appendTo(container);

                                            input.kendoDropDownList({
                                                dataTextField: "Description",
                                                dataValueField: "Code",
                                                template: '<span class="hub-code">#:Code#</span> #:Description#',
                                                popup: {
                                                    origin: "top left"
                                                },
                                                dataSource: {
                                                    serverFiltering: true,
                                                    pageSize: 100,
                                                    transport: {
                                                        read: {
                                                            // this needs to be worked on for the user's saved filters.
                                                            url: "${externalBaseUrl}/cix/scr/admin/config/qmsports/lst/Code/Description",
                                                            dataType: 'json',
                                                            headers: {
                                                                "Authorization": 'Bearer: ${token}',
                                                            },
                                                        },
                                                    },
                                                    schema: {
                                                        data: function (response) {
                                                            return response.data;
                                                        },
                                                    },
                                                },
                                                filter: "contains",
                                                minLength: 2,
                                                enforceMinLength: true,
                                                select: function (o) {
                                                    options.model.set("DestinationCountry", o.dataItem.Description);
                                                    options.model.set("RI_CD_Status", "DFT");
                                                }
                                            })
                                        }
                                    }, {
                                        field: "DestinationCountry",
                                        title: "Name",
                                        editor: function (container, options) {
                                         var input = $('<input required name="' + options.field + '" data-value-primitive="true" disabled />')
                                        },
                                        attributes : {
                                            "class" : "hub-cell-clip"
                                        }
                                    }]
                            },
                            
                            {

                                title: "Financial",
                                headerTemplate : '<i class="fa-duotone fa-dollar-sign"></i> &nbsp; Financial',
                                columns: [
                                {
                                        field: "RI_CD_Currency",
                                        title: "Curr.",
                                        width: 50,
                                        attributes: {
                                            "class" : "hub-code-cell"
                                        },
                                        editor: function (container, options) {
                                            var input = $('<input required name="' + options.field + '" data-value-primitive="true" style="width:350px" />')

                                            // append to the editor container
                                            input.appendTo(container);

                                            // initialize a dropdownlist
                                            input.kendoDropDownList({
                                                dataTextField: "CU_UserDefinedText4",
                                                dataValueField: "CU_CurrencyCode",
                                                template: '<span class="hub-code">#:CU_CurrencyCode#</span> #:CU_UserDefinedText4#',
                                                popup: {
                                                    origin: "top left"
                                                },
                                                autobind: true,
                                                dataSource: {
                                                    serverFiltering: true,
                                                    pageSize: 5,
                                                    transport: {
                                                        read: {
                                                            // this needs to be worked on for the user's saved filters.
                                                            url: "${externalBaseUrl}/cix/scr/admin/config/currency/lst/CU_CurrencyCode/CU_UserDefinedText4",
                                                            dataType: 'json',
                                                            headers: {
                                                                "Authorization": 'Bearer: ${token}',
                                                            },
                                                        },
                                                    },
                                                    schema: {
                                                        data: function (response) {
                                                            return response.data;
                                                        },
                                                    },
                                                },
                                                filter: "contains",
                                                select: function (o) {
                                                    options.model.set("RI_CD_Status", "DFT");
                                                }
                                            })
                                        }
                                    },
                                    {
                                        field: "RI_Buy",
                                        title: "Buy",
                                        width: 60,
                                        editor: editNumber
                                        
                                    },
                                    {
                                        field: "RI_Calc_TargetSell",
                                        title: "Target",
                                        width: 60,
                                        editor: function (container, options) {
                                         var input = $('<input required name="' + options.field + '" data-value-primitive="true" disabled />')
                                        }
                                    }, {
                                        field: "RI_Sell",
                                        title: "Sell",
                                        width: 60,
                                        editor: editNumber
                                    },
                                    {
                                        title: "Markup",
                                        width: 90,
                                        template: kendo.template($("#markup-template").html())
                                    },
                                                                        

                                    
                                    
                                ]
                            },
                            {
                                field: "RI_Comment",
                                title: "Comment"
                            },
                        {
                        title: " ",
                        field:"RI_CD_Status",
                        width: 40,  
                        template: kendo.template($("#approval-template").html()),
                        editor: function (container, options) {
                                         
                        },
                        },
                            {
                            title: "Actions",
                            width: 170 ,
                            template: "<button class='action-btn app-btn app-btn-secondary' onclick='copyRow(\\""+gridProps.RS_Code+"\\",\\"#=RI_PK#\\")'><i title=\\"Copy\\" class=\\"fa-duotone fa-copy\\"></i></button><button class='action-btn app-btn app-btn-secondary' onclick='updateRow(\\""+gridProps.RS_Code+"\\",\\"#=RI_PK#\\",\\"RI_X_CD_Status\\",\\"WAI\\")'><i title=\\"Approve\\" class=\\"fa-duotone fa-check\\"></i></button><button class='action-btn app-btn app-btn-secondary' onclick='updateRow(\\""+gridProps.RS_Code+"\\",\\"#=RI_PK#\\",\\"RI_X_CD_Status\\",\\"REJ\\")'><i title=\\"Reject\\" class=\\"fa-duotone fa-xmark\\"></i></button><button class='action-btn app-btn app-btn-secondary' onclick='rateItemAction(\\"HIS\\",\\"#=RI_PK#\\")'><i title=\\"Events\\" class=\\"fa-duotone fa-calendar-circle-user\\"></i></button> <button type='button' class='action-btn app-btn app-btn-secondary' onclick='deleteRow(\\""+gridProps.RS_Code+"\\",\\"#=RI_PK#\\")'><i class='fa-duotone fa-trash-xmark' title='Delete'></i></button>",
                            attributes: { style: "text-align: center" },

                            },


                        ],
                        edit: function (e) {
                            var isEditable = (e.model.RI_CD_Status !== "APR");
                            if (!isEditable) {
                                // Prevent editing for rows where isEditable is false
                                this.closeCell();
                                toast("warning","Warning: This row cannot be edited as it is approved")
                            }
                        },
                        remove: function (e) {
                            var isDeletable = (e.model.RI_CD_Status !== "APR");
                            if (!isDeletable) {
                                e.preventDefault();
                                toast("warning","Warning: This row cannot be deleted as it is approved")
                            }
                        },
                        dataBound : function(e){
                        const grid = e.sender
                        const scrollPosition = localStorage.getItem("spos"+gridProps.RS_Code);
                            if((scrollPosition || 0) > 0 ){
                                grid.element.find(".k-grid-content").scrollTop(scrollPosition);
                                localStorage.setItem("spos"+gridProps.RS_Code,0);
                            }
                        },
                        groupable: true,
                        editable: {confirmation: false, mode: "incell"},
                        pageSize: 100
                    });
                    }

                });

                toast("warning","This functionality is in a pilot phase.")
                //refreshGrid(${gridProps?.rs[0].RS_Code})
            `}
            </script>
        </Helmet>
    );
};

export default GridLayout;
