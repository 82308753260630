export const reassignAgentBpSeaDetails = [
    {
        columnName: 'BOOKING NUMBER',
        name: 'bpNumber',
        headerClass: 'grid-cell-header col-po-number grid-sticky-column sticky-left-aligned',
        hasSort: true,
        sortName: 'bpNumber',
        hasFilter: true,
        filterName: 'bpNumber',
    },
    {
        columnName: 'STATUS',
        name: 'bpStatus',
        headerClass: 'grid-cell-header col-status-po-air',
        hasSort: true,
        sortName: 'bpStatus',
        hasFilter: true,
        filterName: 'bpStatus',
    },
    {
        columnName: 'AGENT',
        name: 'agentName',
        headerClass: 'grid-cell-header col-agent-po-air',
        hasSort: true,
        sortName: 'agentName',
        hasFilter: true,
        filterName: 'agentName',
    },
    {
        columnName: 'PO NUMBER',
        name: 'poNumber',
        headerClass: 'grid-cell-header col-po-number-air',
        hasSort: true,
        sortName: 'poNumber',
        hasFilter: true,
        filterName: 'poNumber',
    },
    {
        columnName: 'CLIENT',
        name: 'clientName',
        headerClass: 'grid-cell-header col-client',
        hasSort: true,
        sortName: 'clientName',
        hasFilter: true,
        filterName: 'clientName',
    },
    {
        columnName: 'ORIGIN',
        name: 'originPort',
        headerClass: 'grid-cell-header col-origin',
        hasSort: true,
        sortName: 'originPort',
        hasFilter: true,
        filterName: 'originPort',
    },
    {
        columnName: 'DESTINATION',
        name: 'destinationPort',
        headerClass: 'grid-cell-header col-destination',
        hasSort: true,
        sortName: 'destinationPort',
        hasFilter: true,
        filterName: 'destinationPort',
    },
    {
        columnName: 'DESTINATION TYPE',
        name: 'destinationType',
        headerClass: 'grid-cell-header col-destination-type',
        hasSort: true,
        sortName: 'destinationType',
        hasFilter: true,
        filterName: 'destinationType',
    },
    {
        columnName: 'VENDOR',
        name: 'vendorName',
        headerClass: 'grid-cell-header col-vendor',
        hasSort: true,
        sortName: 'vendorName',
        hasFilter: true,
        filterName: 'vendorName',
    },
    {
        columnName: 'GOODS READY DATE',
        name: 'goodsReadyDate',
        headerClass: 'grid-cell-header col-etd',
        hasSort: true,
        sortName: 'goodsReadyDate',
        hasFilter: true,
        filterName: 'goodsReadyDate',
    },
    {
        columnName: 'ETD',
        name: 'plannedEtd',
        headerClass: 'grid-cell-header col-etd',
        hasSort: true,
        sortName: 'plannedEtd',
        hasFilter: true,
        filterName: 'plannedEtd',
    },
    {
        columnName: 'ETA',
        name: 'plannedEta',
        headerClass: 'grid-cell-header col-eta',
        hasSort: true,
        sortName: 'plannedEta',
        hasFilter: true,
        filterName: 'plannedEta',
    },
    {
        columnName: 'RELEASE TYPE',
        name: 'releaseType',
        headerClass: 'grid-cell-header col-eta',
        hasSort: true,
        sortName: 'releaseType',
        hasFilter: true,
        filterName: 'releaseType',
    },
    {
        columnName: 'VESSEL-VOYAGE',
        name: 'vesselVoyage',
        headerClass: 'grid-cell-header col-eta',
        hasSort: true,
        sortName: 'vesselVoyage',
        hasFilter: true,
        filterName: 'vesselVoyage',
    },
];
export const reassignAgentBpAirDetails = [
    {
        columnName: 'BOOKING NUMBER',
        name: 'bpNumber',
        headerClass: 'grid-cell-header col-po-number grid-sticky-column sticky-left-aligned',
        hasSort: true,
        sortName: 'bpNumber',
        hasFilter: true,
        filterName: 'bpNumber',
    },
    {
        columnName: 'STATUS',
        name: 'bpStatus',
        headerClass: 'grid-cell-header col-status-po-air',
        hasSort: true,
        sortName: 'bpStatus',
        hasFilter: true,
        filterName: 'bpStatus',
    },
    {
        columnName: 'AGENT',
        name: 'agentName',
        headerClass: 'grid-cell-header col-agent-po-air',
        hasSort: true,
        sortName: 'agentName',
        hasFilter: true,
        filterName: 'agentName',
    },
    {
        columnName: 'PO NUMBER',
        name: 'poNumber',
        headerClass: 'grid-cell-header col-po-number-air',
        hasSort: true,
        sortName: 'poNumber',
        hasFilter: true,
        filterName: 'poNumber',
    },
    {
        columnName: 'CLIENT',
        name: 'clientNames',
        headerClass: 'grid-cell-header col-client',
        hasSort: true,
        sortName: 'clientNames',
        hasFilter: true,
        filterName: 'clientNames',
    },
    {
        columnName: 'ORIGIN',
        name: 'originPort',
        headerClass: 'grid-cell-header col-origin',
        hasSort: true,
        sortName: 'originPort',
        hasFilter: true,
        filterName: 'originPort',
    },
    {
        columnName: 'DESTINATION',
        name: 'destinationPort',
        headerClass: 'grid-cell-header col-destination',
        hasSort: true,
        sortName: 'destinationPort',
        hasFilter: true,
        filterName: 'destinationPort',
    },
    {
        columnName: 'DESTINATION TYPE',
        name: 'destinationType',
        headerClass: 'grid-cell-header col-destination-type',
        hasSort: true,
        sortName: 'destinationType',
        hasFilter: true,
        filterName: 'destinationType',
    },
    {
        columnName: 'VENDOR',
        name: 'vendorName',
        headerClass: 'grid-cell-header col-vendor',
        hasSort: true,
        sortName: 'vendorName',
        hasFilter: true,
        filterName: 'vendorName',
    },
    {
        columnName: 'GOODS READY DATE',
        name: 'goodsReadyDate',
        headerClass: 'grid-cell-header col-etd',
        hasSort: true,
        sortName: 'goodsReadyDate',
        hasFilter: true,
        filterName: 'goodsReadyDate',
    },
    {
        columnName: 'ETD',
        name: 'plannedEtd',
        headerClass: 'grid-cell-header col-etd',
        hasSort: true,
        sortName: 'plannedEtd',
        hasFilter: true,
        filterName: 'plannedEtd',
    },
    {
        columnName: 'ETA',
        name: 'plannedEta',
        headerClass: 'grid-cell-header col-eta',
        hasSort: true,
        sortName: 'plannedEta',
        hasFilter: true,
        filterName: 'plannedEta',
    },
    {
        columnName: 'RELEASE TYPE',
        name: 'releaseType',
        headerClass: 'grid-cell-header col-eta',
        hasSort: true,
        sortName: 'releaseType',
        hasFilter: true,
        filterName: 'releaseType',
    },
    {
        columnName: 'CARRIER-FLIGHT',
        name: 'carrierFlights',
        headerClass: 'grid-cell-header col-eta',
        hasSort: true,
        sortName: 'carrierFlights',
        hasFilter: true,
        filterName: 'carrierFlights',
    },
];
