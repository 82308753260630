import { getOrganizationProfileData } from 'src/utils';
import { post, put, get } from '../../HttpMethods';
import { externalBaseUrl, profileCode } from 'src/utils/kendoGrid/insights';
import { TODO_ICON_MAP } from 'src/pages/Dashboard/utils/dashboardTodoConstants';
import { todoBpAPIMap } from 'src/utils/constants';

export const dashboardTodoListORD = async (payload: any) => {
    const search = payload?.search?.selectedSearchItem;
    // const filteredListOfOrg = search ? todoOrgList(search?.type, search?.code) : listOfUserOrganisations;
    const searchItem = getOrganizationProfileData(search?.type, search?.code);
    try {
        const response = await post(
            `api/ord/todo/list/${payload.profileType}`,
            {
                loggedInUserName: payload.userName,
                ...(payload.search ?? {}),
                roleName: payload.roleName,
                // ...filteredListOfOrg,
                ...searchItem,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
export const dashboardTodoListBKG = async (payload: any) => {
    // const search = payload?.search?.selectedSearchItem;
    // const filteredListOfOrg = search ? todoOrgList(search?.type, search?.code) : listOfUserOrganisations;
    // const searchItem = getOrganizationProfileData(search?.type, search?.code);
    try {
        //     const response = await post(
        //         `api/bkg/todo/list/${payload.profileType}`,
        //         {
        //             loggedInUserName: payload.userName,
        //             ...(payload.search ?? {}),
        //             roleName: payload.roleName,
        //             ...searchItem,
        //         },
        //         null
        //     );
        //     const copyFavourites = JSON.parse(JSON.stringify(response?.info?.favourites));
        //     const copyOthers = JSON.parse(JSON.stringify(response?.info?.others));
        //     let totalCount = 0;
        //     for (let index = 0; index < copyFavourites.length; index++) {
        //         const responseFavourites = await get(
        //             `cix/scr/forwarding/booking/?c=${
        //                 profileCode?.[payload.profileType]
        //             }&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=${TODO_ICON_MAP?.[
        //                 copyFavourites[index]?.type
        //             ]?.fetchItemPayload?.bpStatus?.replace('.', '')}&$count`,
        //             null,
        //             externalBaseUrl
        //         );
        //         copyFavourites[index].count = responseFavourites?.total;
        //         totalCount += responseFavourites?.total;
        //     }
        //     for (let index = 0; index < copyOthers.length; index++) {
        //         const responseOthers = await get(
        //             `cix/scr/forwarding/booking/?c=${
        //                 profileCode?.[payload.profileType]
        //             }&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=${TODO_ICON_MAP?.[
        //                 copyOthers[index]?.type
        //             ]?.fetchItemPayload?.bpStatus?.replace(':', '')}&$count`,
        //             null,
        //             externalBaseUrl
        //         );
        //         copyOthers[index].count = responseOthers?.total;
        //         totalCount += responseOthers?.total;
        //     }
        let otherTodoMapped = [];
        const otherTodo = await get('cix/scr/trk/todo', null, externalBaseUrl);
        if (otherTodo?.data?.length) {
            otherTodoMapped = otherTodo?.data?.map((res: any) => ({
                count: res?.total,
                type: 'OTHER_TODO',
                name: res?.name,
                updated: false,
                trend: res?.trend,
            }));
        }
        let newItemOthersTodo = false;
        const othersTodoCount =
            [...(otherTodoMapped ?? [])]?.reduce((accumulator: number, value: any) => {
                if (value.updated) {
                    newItemOthersTodo = true;
                }
                return accumulator + (value.count ?? 0);
            }, 0) ?? 0;
        let otherActivityMapped = [];
        const otherActivity = await get('cix/scr/trk/activityfeed', null, externalBaseUrl);
        if (otherActivity?.data?.length) {
            otherActivityMapped = otherActivity?.data?.map((res: any) => ({
                count: res?.total,
                type: 'OTHER_TODO',
                name: res?.name,
                updated: false,
                trend: res?.trend,
            }));
        }
        let newItemOthersActivity = false;
        const othersActivityCount =
            [...(otherActivityMapped ?? [])]?.reduce((accumulator: number, value: any) => {
                if (value.updated) {
                    newItemOthersActivity = true;
                }
                return accumulator + (value.count ?? 0);
            }, 0) ?? 0;

        return {
            data: {
                success: true,
                data: {
                    info: {
                        favourites: [],
                        others: { todo: otherTodoMapped, activity: otherActivityMapped },
                        custom: { todo: otherTodoMapped, activity: otherActivityMapped },
                        otherTodo,
                        otherActivity,
                        totalCount: { todo: othersTodoCount, activity: othersActivityCount },
                    },
                },
            },
        };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboardTodoItemPO = async (payload: any) => {
    try {
        const response = await post(
            `api/ord/po/list/${payload.profileType}?size=500&page=0&sortColumn=createdDate&sortOrder=DESC`,
            {
                loggedInUserName: payload.userName,
                fromToDo: true,
                ...payload.fetchItemPayload,
                ...(payload.search ?? {}),
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboardTodoItemBP = async (payload: any) => {
    // const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    try {
        // const response = await post(
        //     `api/bkg/bp/list/${payload.profileType}?size=500&page=0&sortColumn=createdDateTime&sortOrder=DESC`,
        //     {
        //         ...payload.fetchItemPayload,
        //         fromToDo: true,
        //         loggedInUserName: payload.userName,
        //         ...(payload.search ?? {}),
        //     },
        //     null
        // );
        let newResponseCopy: any;
        // if (response?.info?.bpListingDtos?.length) {
        // const bpListingDtosCopy = JSON.parse(JSON.stringify(response?.info?.bpListingDtos));
        // const todoBpAPIMapCopy: any = todoBpAPIMap;
        const newResponse = await get(
            `/cix/scr/forwarding/booking/?take=1000&skip=0&page=1&pageSize=1000&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=${payload.fetchItemPayload?.bpStatus}&sort=[{"field":"BP_ModifiedDateTime","dir":"desc"}]`,
            null,
            externalBaseUrl
        );
        newResponseCopy = JSON.parse(JSON.stringify(newResponse?.data));
        const resultArray = newResponseCopy.map((elm: any) => ({
            bpId: elm?.BP_PK,
            bpNumber: elm?.BP_Number,
            originPortName: elm?.BP_OriginPort,
            plannedEtd: elm?.BP_PlannedEtd,
            bpType: elm?.BP_Type,
            bpBCNType: elm?.BP_CD_BCNType,
            destinationPortName: elm?.BP_DestinationPort,
            plannedEta: elm?.BP_PlannedEta,
            poNumber: [elm?.BO_PoNumbers],
            totTEU: elm?.BP_TotTEU,
            transitPortCount: elm?.BT_ViaPort,
            LastModifiedDateandTime: elm?.BP_ModifiedDateTime,
        }));

        // for (let index = 0; index < newResponseCopy.length; index++) {
        //     const filtered = bpListingDtosCopy?.find(
        //         (res: any) => res?.bpId?.toLowerCase() === newResponseCopy[index]?.BP_PK?.toLowerCase()
        //     );
        //     Object.keys(filtered).forEach((key: any) => {
        //         filtered[key] = todoBpAPIMapCopy[key]
        //             ? newResponseCopy[index]?.[todoBpAPIMapCopy[key]]
        //             : filtered[key];
        //     });
        //     newResponseCopy[index] = JSON.parse(JSON.stringify(filtered));
        // }
        return {
            data: {
                success: true,
                data: {
                    // ...response,
                    info: {
                        bpListingDtos: resultArray,
                        totalElements: newResponse?.total,
                        totalPages: 1,
                    },
                },
            },
        };
        // }
        // return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const otherTodoItem = async (payload: any) => {
    try {
        let param = payload?.selectedTodoName;
        if (!param) {
            const urlParams = new URLSearchParams(window.location.search);
            param = urlParams.get('type');
        }
        const responseTodo = await get('cix/scr/trk/todo', null, externalBaseUrl);
        const responseActivity = await get('cix/scr/trk/activityfeed', null, externalBaseUrl);

        // const updated = response?.data?.filter((res: any) => res?.name === param)?.[0];
        return {
            data: {
                success: true,
                data: {
                    info: {
                        otherTodo: responseTodo,
                        otherActivity: responseActivity,
                        // todoType: updated?.type,
                        // totalElements: updated?.total,
                        // totalPages: 1,
                    },
                },
            },
        };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboardTodoItemLCL = async (payload: any) => {
    try {
        const response = await get(
            `api/ord/todo/cg-list/${payload.profileType}/${payload.userName}/${payload.fetchItemPayload.type}`,
            null
        );
        return { data: { success: true, data: { info: { lclListingDtos: response.info } } } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
export const dashboardTodoItemPendingReassignment = async (payload: any) => {
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const shippingmode = payload?.search?.shippingmode;
    const mode = payload?.search?.mode;

    try {
        const response = await post(
            `api/ord/reassignment/list?sortColumn=currentAgentName&sortOrder=ASC`,
            {
                ...listOfUserOrganisations,
                loggedInUserName: payload.userName,
                ...(payload.search ? payload?.search?.mode : {}),
                mode,
                shippingmode,
                // fromToDo: true,
                // ...payload.fetchItemPayload,
                // ...(payload.search ?? {}),
            },
            null
        );

        return { data: { success: true, data: { info: response.response } } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboardTodoItemAO = async (payload: any) => {
    // const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    try {
        const response = await post(
            `api/ord/ao/list/${payload.profileType}?size=500&page=0&sortColumn=createdDate&sortOrder=DESC`,
            {
                loggedInUserName: payload.userName,
                fromToDo: true,
                ...payload.fetchItemPayload,
                ...(payload.search ?? {}),
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboardTodoItemReceipt = async (payload: any) => {
    // const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    try {
        const response = await post(
            `api/ord/blp/receipts/list/${payload.profileType}?size=500&page=0&sortColumn=createdDate&sortOrder=DESC`,
            {
                ...payload.fetchItemPayload,
                fromToDo: true,
                loggedInUserName: payload.userName,
                ...(payload.search ?? {}),
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboardTodoFavoriteToggle = async (payload: any) => {
    try {
        const response = await put(
            `api/ord/todo/favs/toggle/${payload.profileType}/${payload.userName}/${payload.todoType}`,
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
