import axios from 'axios';
import { get } from '../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const fetchShipmentDetailsApi = async (payload: { shipmentId: string }) => {
    try {
        const data = await get(`cix/scr/fwd/shp/${payload.shipmentId}`, null, externalBaseUrl);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
