import React, { useEffect } from 'react';
import { useAppSelector } from 'src/hooks';
import { Link, useLocation, useParams } from 'react-router-dom';
import { selectHeader, selectNotesUnread, updateNotesRead } from 'src/store/reducers/purchaseOrder/addPoReducer';
import { Icon } from 'semantic-ui-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotes } from '@fortawesome/pro-duotone-svg-icons';
import { getUnreadBpNotes } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { noteIconStyle } from 'src/utils/constants';
import { useDispatch } from 'react-redux';

const Index = () => {
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userType = '';
    let userCompany = [] as any;
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            userType = roles[roleIndex].profile?.toLowerCase();
        }
    }

    const headerData = useAppSelector(selectHeader);
    const notesUnread = useAppSelector(selectNotesUnread);
    const skipPOLine = headerData?.isHeaderOnlyPo;

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const productDetailsConfirmationTabCheck = React.useMemo(() => {
        return (
            userType === 'agent' ||
            headerData?.poStatus?.toLowerCase().includes('awaiting') ||
            headerData?.poStatus?.toLowerCase().includes('ready') ||
            headerData?.poStatus?.toLowerCase().includes('pending_reassignment') ||
            headerData?.poStatus?.toLowerCase().includes('booked')
        );
    }, [lastSegment, headerData]);

    React.useEffect(() => {
        if (lastSegment) {
            if (lastSegment === 'po_review') {
                setSelectedIndex(3);
            } else if (lastSegment === 'shipment_information') {
                setSelectedIndex(3);
            } else if (lastSegment === 'product_details_confirmation') {
                setSelectedIndex(3);
            } else if (lastSegment === 'product_details') {
                setSelectedIndex(2);
            } else if (lastSegment === 'dates') {
                setSelectedIndex(1);
            } else if (lastSegment === 'basic_details') {
                setSelectedIndex(0);
            } else if (lastSegment === 'container_mode_selection') {
                setSelectedIndex(4);
            } else if (lastSegment === 'lifecycle') {
                setSelectedIndex(98);
            } else if (lastSegment === 'integration_log') {
                setSelectedIndex(99);
            } else if (lastSegment === 'notes') {
                setSelectedIndex(100);
            } else if (lastSegment === 'events') {
                setSelectedIndex(101);
            } else if (lastSegment === 'vendorPacking') {
                setSelectedIndex(102);
            } else if (lastSegment === 'attachments') {
                setSelectedIndex(103);
            }
        }
    }, []);

    useEffect(() => {
        if (!headerData?.notesUnread && id && id !== 'POSelection') {
            if (!notesUnread) {
                getUnreadBpNotes(id)
                    ?.then((res: any) => dispatch(updateNotesRead(res)))
                    .catch((err) => console.log(err, 'err'));
            }
        }
    }, []);

    const productDetailsConfirmationObj = {
        label: 'Product Details Confirmation',
        value: 'product_details_confirmation',
        id: 4,
    };

    const shipmentConfirmation = {
        label: 'Shipment Confirmation',
        value: 'shipment_information',
        id: 4,
    };

    const previewObj = {
        label: 'PO Preview',
        value: 'po_review',
        id: 4,
    };

    const containerModeSelection = {
        label: 'Confirmation Groups',
        value: 'container_mode_selection',
        id: 5,
    };

    const lifecycleSelection = {
        label: 'Lifecycle',
        value: 'lifecycle',
        id: 6,
    };

    const integrationLogSelection = {
        label: 'Integration Log',
        value: 'integration_log',
        id: 7,
    };

    const noteSelection = {
        label: 'Notes',
        value: 'notes',
        id: 8,
    };
    const eventsSelection = {
        label: 'Events',
        value: 'events',
        id: 9,
    };

    const attachmentsSelection = {
        label: 'Attachments',
        value: 'attachments',
        id: 10,
    };

    const vendorPack = {
        label: 'Vendor Packing',
        value: 'vendorPacking',
        id: 11,
    };

    const poTabList = [
        {
            label: 'Basic Details',
            value: 'basic_details',
            id: 1,
        },
        {
            label: 'Dates',
            value: 'dates',
            id: 2,
        },
        {
            label: 'Product Details',
            value: 'product_details',
            id: 3,
        },
    ];

    if (productDetailsConfirmationTabCheck) {
        if (!skipPOLine) {
            poTabList.push(productDetailsConfirmationObj);
        } else {
            poTabList.push(shipmentConfirmation);
        }
    } else {
        poTabList.push(previewObj);
    }

    if (
        headerData?.poStatus?.toLowerCase() !== 'draft' &&
        // headerData?.shippingMode?.shippingModeCode !== 'AIR' &&
        (headerData?.pconfirmationComplete || headerData?.shipmentConfirmationComplete)
    ) {
        poTabList.push(containerModeSelection);
    }
    poTabList.push(attachmentsSelection);

    poTabList.push(lifecycleSelection);
    if (headerData?.createdUser === 'CIX') {
        poTabList.push(integrationLogSelection);
    }

    poTabList.push(noteSelection);
    poTabList.push(eventsSelection);

    // TODO: need to check user permission
    if (
        userType === 'forwarder' ||
        headerData?.clientCode === 'SMIGUK' ||
        headerData?.clientCode === 'SMIGSG' ||
        headerData?.clientCode === '3JUSTGRO' ||
        headerData?.clientCode === 'KIMINVAKL'
    )
        poTabList.push(vendorPack);

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (headerData) {
                if (param === 'po_review') {
                    return (
                        headerData.bdTabComplete &&
                        headerData.datesTabComplete &&
                        headerData.validDate &&
                        headerData.productTabComplete &&
                        !productDetailsConfirmationTabCheck
                    );
                } else if (param === 'product_details') {
                    return headerData.productTabComplete;
                } else if (param === 'dates') {
                    return headerData.datesTabComplete && headerData?.validDate;
                } else if (param === 'basic_details') {
                    return headerData.bdTabComplete;
                } else if (param === 'product_details_confirmation') {
                    if (productDetailsConfirmationTabCheck && !skipPOLine) {
                        return headerData.pconfirmationComplete;
                    }
                    return false;
                } else if (param === 'shipment_information') {
                    if (productDetailsConfirmationTabCheck && skipPOLine) {
                        return headerData.shipmentConfirmationComplete;
                    }
                    return false;
                } else if (param === 'container_mode_selection') {
                    if (
                        headerData?.poStatus?.toLowerCase() !== 'draft'
                        // &&
                        // headerData?.shippingMode?.shippingModeCode !== 'AIR'
                    ) {
                        return headerData.containerModeSelectionComplete;
                    }
                    return false;
                }
            }
            return false;
        };
    }, [lastSegment, headerData]);

    const checkTabNavigationFromApi = (param: string, headerDetailsVal: any) => {
        if (headerDetailsVal) {
            if (param === 'po_review') {
                return (
                    headerDetailsVal.bdTabComplete &&
                    headerDetailsVal.datesTabComplete &&
                    headerDetailsVal.validDate &&
                    headerDetailsVal.productTabComplete &&
                    !productDetailsConfirmationTabCheck
                );
            } else if (param === 'product_details') {
                return (
                    headerDetailsVal.bdTabComplete && headerDetailsVal.datesTabComplete && headerDetailsVal?.validDate
                );
            } else if (param === 'dates') {
                return headerDetailsVal.bdTabComplete;
            } else {
                if (param === 'basic_details') {
                    return true;
                } else if (param === 'product_details_confirmation') {
                    if (productDetailsConfirmationTabCheck && !skipPOLine) {
                        return (
                            headerDetailsVal.bdTabComplete &&
                            headerDetailsVal.datesTabComplete &&
                            headerDetailsVal.validDate &&
                            headerDetailsVal.productTabComplete
                        );
                    }
                    return false;
                } else if (param === 'shipment_information') {
                    if (productDetailsConfirmationTabCheck && skipPOLine) {
                        return (
                            headerDetailsVal.bdTabComplete &&
                            headerDetailsVal.datesTabComplete &&
                            headerDetailsVal.validDate &&
                            headerDetailsVal.productTabComplete
                        );
                    }
                    return false;
                } else if (param === 'container_mode_selection') {
                    if (
                        headerData?.poStatus?.toLowerCase() !== 'draft'
                        // &&
                        // headerData?.shippingMode?.shippingModeCode !== 'AIR'
                    ) {
                        return (
                            headerDetailsVal.bdTabComplete &&
                            headerDetailsVal.datesTabComplete &&
                            headerDetailsVal.validDate &&
                            headerDetailsVal.productTabComplete &&
                            (headerDetailsVal.pconfirmationComplete || headerDetailsVal.shipmentConfirmationComplete)
                        );
                    }
                    return false;
                } else if (param === 'lifecycle') {
                    return true;
                } else if (param === 'integration_log') {
                    return true;
                } else if (param === 'notes') {
                    return true;
                } else if (param === 'events') {
                    return true;
                } else if (param === 'vendorPacking') {
                    return true;
                } else if (param === 'attachments') {
                    return true;
                }
            }
        }
    };

    return (
        <div className="arrow-tab-container">
            <div className="arrow-tab-holder">
                {poTabList.map((tabs: any, index: number) => {
                    return (
                        <Link
                            className={`arrow-tab-border ${
                                (index === selectedIndex ||
                                    (selectedIndex === 98 && tabs.value === 'lifecycle') ||
                                    (selectedIndex === 99 && tabs.value === 'integration_log') ||
                                    (selectedIndex === 100 && tabs.value === 'notes') ||
                                    (selectedIndex === 101 && tabs.value === 'events') ||
                                    (selectedIndex === 102 && tabs.value === 'vendorPacking') ||
                                    (selectedIndex === 103 && tabs.value === 'attachments')) &&
                                'active'
                            } po-basic-tab ${!checkTabNavigationFromApi(tabs.value, headerData) && 'disabled'}`}
                            to={`/purchase_order/${id}/${tabs.value}`}
                            style={{
                                justifyContent:
                                    tabs.value === 'notes' || tabs.value === 'events' || tabs.value === 'attachments'
                                        ? 'center'
                                        : 'space-between',
                            }}
                        >
                            {tabs.value === 'notes' ? (
                                <FontAwesomeIcon
                                    icon={faNotes}
                                    beatFade={notesUnread}
                                    style={noteIconStyle as React.CSSProperties}
                                />
                            ) : tabs.value === 'events' ? (
                                <div>
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon calendar-icon">
                                        <use xlinkHref="#calendarIcon">
                                            <title>Calendar</title>
                                        </use>
                                    </svg>
                                </div>
                            ) : tabs.value === 'attachments' ? (
                                <div>
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon eye-icon">
                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                        <title>Attach file</title>
                                    </svg>
                                </div>
                            ) : tabs.value === 'vendorPacking' ? (
                                <Icon name="inbox" />
                            ) : tabs.value === 'lifecycle' || tabs.value === 'integration_log' ? (
                                <div>
                                    <svg className="svg-icon eye-icon">
                                        <use href="#eyeIcon"></use>
                                    </svg>
                                </div>
                            ) : tabs.value === 'container_mode_selection' && headerData?.cgTotalStatus === false ? (
                                <div
                                    className="arrow-badge verify-badge"
                                    style={{
                                        backgroundColor: '#fd1010',
                                        background: '#fd1010',
                                        border: '1px solid #fff',
                                    }}
                                >
                                    <svg className="svg-icon tick-icon">
                                        <use href="#closeIcon">
                                            <title>close mark</title>
                                        </use>
                                    </svg>
                                </div>
                            ) : isTabActive(tabs.value) ? (
                                <div
                                    className="arrow-badge verify-badge"
                                    style={{
                                        backgroundColor: '#18ab56',
                                    }}
                                >
                                    <svg className="svg-icon tick-icon">
                                        <use href="#tickIcon">
                                            <title>check mark</title>
                                        </use>
                                    </svg>
                                </div>
                            ) : (
                                <div className="arrow-badge">{tabs.id}</div>
                            )}
                            <div className="arrow-tab">{tabs.label}</div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default Index;
