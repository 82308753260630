import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'bpHeaderDataao',
    initialState: {
        bpId: null,
        bpNumber: null,
        bpStatus: null,
        reason: null,
        clientCode: null,
        clientName: null,
        clientId: null,
        totalCBM: 0,
        totalKgs: 0,
        originPort: null,
        destinationPort: null,
        goodsReadyDate: [],
        reSubmit: false,
        showRejectModalForwarder: false,
        showRejectModalAgent: false,
        reSubmitPopupForwarder: false,
        reSubmitPopupClient: false,
        isViewed: false,
        success: false,
        error: null,
        isLoading: false,
        cancelDate: null,
        cancelReason: null,
        cancelledUser: null,
        newBp: { bpNumber: null, bpId: null },
        linkedBp: null,
        linkedBpDetails: {},
        gateInDateExpired: false,
        cancelSuccess: false,
        cancelError: null,
        blpCode: null,
        blpName: null,
        isAutoApprovalFailed: false,
        autoApprovalFailureReason: '',
        autoApprovalFailedModalForwarder: null,
        autoApprovalFailedModalClient: null,
    },
    reducers: {
        fetchBpHeaderData: (state, action) => {
            //work around to fix the bp tabs reshaping issue
            //TODO Remove this after refactor and common tabs section all bp pages
            if (state.bpId === action.payload?.bpId) {
                if (action.payload?.data?.bpStatus) {
                    state.bpStatus = action.payload?.data?.bpStatus;
                }
            } else {
                state.bpStatus = null;
                state.bpId = action.payload?.bpId;
            }
            state.bpNumber = action.payload?.data?.bpNumber;
            if (action.payload?.data?.bpNumber) {
                localStorage.setItem('bpNumber', action.payload?.data?.bpNumber);
            }
            state.bpNumber = action.payload?.data?.bpNumber;
            if (action.payload?.data?.bpNumber) {
                localStorage.setItem('bpNumber', action.payload?.data?.bpNumber);
            }
            state.reason = action.payload?.data?.reason;
            state.clientCode = action.payload?.data?.clientCode;
            state.clientName = action.payload?.data?.clientName;
            state.clientId = action.payload?.data?.clientId;
            state.totalCBM = action.payload?.data?.totalCBM;
            state.totalKgs = action.payload?.data?.totalKgs;
            state.originPort = action.payload?.data?.originPort;
            state.destinationPort = action.payload?.data?.destinationPort;
            state.blpCode = action.payload?.data?.blpCode;
            state.blpName = action.payload?.data?.blpName;
            state.goodsReadyDate = action.payload?.data?.goodsReadyDate;
            state.reSubmit = action.payload?.data?.reSubmit;
            state.showRejectModalForwarder = action.payload?.data?.showRejectModalForwarder;
            state.showRejectModalAgent = action.payload?.data?.showRejectModalAgent;
            state.reSubmitPopupForwarder = action.payload?.data?.reSubmitPopupForwarder;
            state.reSubmitPopupClient = action.payload?.data?.reSubmitPopupClient;
            state.isViewed = action.payload?.data?.isViewed;
            state.cancelDate = action.payload?.data?.cancelDate;
            state.cancelReason = action.payload?.data?.cancelReason;
            state.cancelledUser = action.payload?.data?.cancelledUser;
            state.linkedBp = action.payload?.data?.linkedBp;
            state.isAutoApprovalFailed = action.payload?.data?.isAutoApprovalFailed;
            state.autoApprovalFailureReason = action.payload?.data?.autoApprovalFailureReason;
            state.autoApprovalFailedModalForwarder = action.payload?.data?.autoApprovalFailedModalForwarder;
            state.autoApprovalFailedModalClient = action.payload?.data?.autoApprovalFailedModalClient;
            if (action.payload?.data?.clientCode) {
                localStorage.setItem('ClientCode', action.payload?.data?.clientCode);
            }
            state.gateInDateExpired = action.payload?.data?.gateInDateExpired;
            state.isLoading = false;
        },
        cancelBp: (state, action) => {
            state.isLoading = true;
            if (!action.payload.res?.success) {
                state.error = action.payload.error;
            } else {
                state.success = true;
                if (action.payload?.res?.info) {
                    state.newBp = action.payload?.res?.info?.newBP;
                }
            }
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        reset: (state, _action) => {
            state.success = false;
            state.error = null;
        },
        resetHeader: (state, _action) => {
            state.clientCode = null;
            state.clientName = null;
            state.clientId = null;
            state.totalCBM = 0;
            state.totalKgs = 0;
            state.originPort = null;
            state.destinationPort = null;
            state.bpStatus = null;
            state.blpCode = null;
            state.blpName = null;
            state.goodsReadyDate = [];
        },
        fetchLinkedBpHeader: (state, action) => {
            state.linkedBpDetails = action.payload?.linkedBpDetails;
        },
        cancelBooking: (state, action) => {
            state.isLoading = true;
            if (!action.payload.res?.success) {
                state.cancelError = action.payload.error;
            } else {
                state.cancelSuccess = true;
                if (action.payload?.res?.info) {
                    state.newBp = action.payload?.res?.info?.newBP;
                }
            }
            state.isLoading = false;
        },
    },
});

export const { fetchBpHeaderData, cancelBp, isLoadingFn, reset, resetHeader, fetchLinkedBpHeader, cancelBooking } =
    dataSlice.actions;
export default dataSlice.reducer;
