export const layoutWithColumnsList = {
    normal: { keyword: 'forwarding/shipment', title: 'Shipments View' },
    'with-order': { keyword: 'forwarding/shipmentByOrder', title: 'Shipments View (With orders)' },
    'with-container': { keyword: 'forwarding/shipmentByContainer', title: 'Shipments View (With Containers)' },
    'containers-status': { keyword: 'forwarding/containerStatus', title: 'Containers Status' },
    'containers-cartage-view': { keyword: 'forwarding/containerCartage', title: 'Containers Cartage View' },
    'with-product': { keyword: 'forwarding/containerBreakup', title: 'Shipments View (With products)' },
    docShipment: { keyword: 'doctracking/shipment', title: 'Document Tracking (Shipment)' },
    docShipmentDates: { keyword: 'doctracking/shipmentDates', title: 'Document Tracking (Shipment Dates)' },
    docShipmentCommercialInvoice: {
        keyword: 'documentTracking/commercialInvoice',
        title: 'Document Tracking (Commercial Invoice)',
    },
    'inbound-receipt': { keyword: 'warehousing/inbound/receipt', title: 'Receipt' },
    'outbound-shipment': { keyword: 'warehousing/outbound/shipment', title: 'Shipment' },
    inventory: { keyword: 'warehousing/inventory', title: 'Inventory' },
    forwarder: { keyword: 'admin/userProfile/FWD', title: '' },
    client: { keyword: 'admin/userProfile/CLT', title: '' },
    vendor: { keyword: 'admin/userProfile/VND', title: '' },
    agent: { keyword: 'admin/userProfile/AGT', title: '' },
    shipping: { keyword: 'admin/userProfile/CAR', title: '' },
    'booking-list': { keyword: 'forwarding/booking', title: '' },
    'purchase-order-list': { keyword: 'forwarding/purchaseorder', title: '', savedLayout: 'forwarding~purchaseorder' },
    'warehouse-order-list': {
        keyword: 'forwarding/purchaseorder',
        title: '',
        extraData:
            '?filter[logic]=and&filter[filters][1][operator]=eq&filter[filters][1][value]=LND&filter[filters][1][field]=PO_SM',
    },
    'profile-client': { keyword: 'admin/organisation/clients', title: '' },
    'profile-vendor': { keyword: 'admin/organisation/vendors', title: '' },
    'profile-agent': { keyword: 'admin/organisation/agents', title: '' },
    'profile-carrier': { keyword: 'admin/organisation/carriers', title: '' },
    'profile-coloader': { keyword: 'admin/organisation/coloaders', title: '' },
    integrationlog: { keyword: 'admin/integrationlog', title: '' },
    'allocated-teu': { keyword: 'trk/nac/SC_Company/BP_AllocatedTEU/STD_WK', title: '' },
    'with-confirmation-groups': {
        keyword: 'forwarding/booking/all/confirmationgroups',
        title: 'Booking (With Confirmation Groups)',
    },
    'on-the-water-report': {
        keyword: 'custom/3justgro/otw',
        title: '',
    },
    'dc-delivery-report': {
        keyword: 'custom/3justgro/dcd',
        title: '',
    },
    'warehouse-config': {
        keyword: 'cfg/whs',
        title: '',
    },
    'shipment-invoice': {
        keyword: 'admin/organisation/documents',
        title: '',
    },
    'qms-quote': {
        keyword: 'qms/config/quote',
        title: '',
    },
    'qms-quote-item': {
        keyword: 'qms/config/quoteitem',
        title: '',
    },
    'qms-port-group': {
        keyword: 'qms/config/portgroups',
        title: '',
    },
    'qms-rate': {
        keyword: 'qms/config/rate',
        title: '',
    },
    'qms-config-port': { keyword: 'admin/config/port', title: '' },
    'qms-quotes': { keyword: 'qms/quote', title: '' },
    // 'qms-config-header': { keyword: 'admin/config/configHeader', title: '' },
};
