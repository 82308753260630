import { ReactElement, useState } from 'react';
import { useSelector } from 'react-redux';
import TableToolTip from '../../../components/common/TableToolTip';
import { RootState } from '../../../store/reducers';
import './booking-header-sidebar.scss';
interface BookingHeaderProps {
    setShowBookingSideBar: any;
}

const getValidItemFor = (value: string[]) => {
    return value?.map((data: string) => {
        if (!data) {
            return '';
        }
        const fullDate = data.split('T')[0];
        const date = fullDate.split('-');
        return `${date[2]}-${date[1]}-${date[0]}`;
    });
};

const Index = ({ setShowBookingSideBar }: BookingHeaderProps): ReactElement => {
    const { clientName, originPort, destinationPort, goodsReadyDate } = useSelector(
        (state: RootState) => state.bpHeaderData
    );
    const [rowexpand, settableRowExp] = useState(false);
    const [rowLine, setRowLine] = useState(null);
    return (
        <>
            <div className="side-bar active">
                <button className="app-btn text-close-btn" onClick={() => setShowBookingSideBar(false)}>
                    <svg className="svg-icon text-close-icon">
                        <use href="#closeIcon"></use>
                    </svg>
                </button>
                <h2 className="page-heading">Header Details</h2>
                <div className="details-box custom">
                    <div className="card-detail-col col-1">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Client</h6>
                            <div className="details-box-content">{clientName}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Origin</h6>
                            <div className="details-box-content">{originPort}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Destination</h6>
                            <div className="details-box-content">{destinationPort}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Goods Ready Date</h6>

                            <div className="content-heading-holder">
                                {getValidItemFor(goodsReadyDate)?.length !== 0 && (
                                    <TableToolTip
                                        data={getValidItemFor(goodsReadyDate)}
                                        index={0}
                                        settableRowExp={settableRowExp}
                                        rowexpand={rowexpand}
                                        rowLine={rowLine}
                                        setRowLine={setRowLine}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="sidebar-backdrop"></div>
        </>
    );
};
export default Index;
