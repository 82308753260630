import axios from 'axios';

import { get, post } from '../../HttpMethods';
import { fileUploadForDocuments } from '../bookingProposal/additionalDetailsApi';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const getExistingSchedules = async (payload: any) => {
    try {
        const data = await get(
            `api/bkg/bp/air/schedules?bpId=${payload.bpId}${
                payload?.selectedScheduleId ? `&selectedScheduleId=${payload?.selectedScheduleId}` : ''
            }`,
            null
        );

        return data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getSelectedOptionDet = async (ZO_BP: string) => {
    try {
        const response = await get(`cix/air/bkg/SelectedOptionDet/${ZO_BP}`, null, externalBaseUrl);
        return response?.data?.[0];
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchCurrencyDetailsApi = async () => {
    try {
        const data = await get('api/bkg/bp/basicdetails/ordercurrency', null);

        return { data: data?.info.map((res: any) => ({ value: res.code, label: res.code })) };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createFlightScheduleDetailsApi = async (payload: any, id?: string) => {
    try {
        const data = await post(`api/bkg/bp/air/buy${id ? `?baId=${id}` : ``}`, payload, null);
        const fileBuy = payload?.get('buyfile');
        if (fileBuy) {
            const document = payload?.get('request');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bpId;
            const formData = new FormData();
            formData.append('file', fileBuy, fileBuy.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HBQ');
            await fileUploadForDocuments(formData);
        }
        const fileSell = payload?.get('sellfile');
        if (fileSell) {
            const document = payload?.get('request');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bpId;
            const formData = new FormData();
            formData.append('file', fileSell, fileSell.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HSQ');
            await fileUploadForDocuments(formData);
        }
        return { data, sucess: true };
    } catch (error: any) {
        const errorMsg = JSON.parse(JSON.stringify(error?.response?.data));
        if (errorMsg) {
            return errorMsg;
        } else if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message, errorMsg);
            return { message: error.message, sucess: false };
        } else {
            console.log('unexpected error: ', error);
            return { message: error.message, sucess: false };
        }
    }
};
