import React, { useEffect, useState } from 'react';
import VesselSearch from 'src/pages/Common/vesselSearch';
import VesselDropdownList from '../../Common/vesselDropdown';
import TransitVesselDropdownList from '../../Common/transitVesselDropdown';
import { vesselListAirApi } from 'src/services/apis/bookingProposalAir/addSchedule';

type props = {
    type: string;
    index?: any;
    vessel: string;
    setVessel: any;
    setVesselFullName: any;
    portDetails?: any;
    updatePortDetails?: any;
    errorValidation: any;
};
const WrapperComponent = ({
    type,
    vessel,
    setVessel,
    setVesselFullName,
    errorValidation,
    index,
    portDetails,
    updatePortDetails,
}: props) => {
    const [vesselData, setVesselData] = useState<any>([]);

    const getUpdatedVessel = async (payload?: any) => {
        try {
            const data = await vesselListAirApi(payload);
            setVesselData(data);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!vesselData?.length) {
            getUpdatedVessel({});
        }
    }, []);

    useEffect(() => {
        if (type === 'single') {
            if (vessel) {
                const name = vesselData?.find((res: any) => res?.imo == vessel)?.name;
                setVesselFullName(name);
            } else {
                setVesselFullName('');
            }
        }
    }, [vessel]);

    return type === 'single' ? (
        <>
            <div className="layout-item">
                <VesselDropdownList
                    vessel={vessel}
                    setVessel={setVessel}
                    isErrorMessageShow={errorValidation && (vessel === '' || vessel === undefined)}
                    vesselData={vesselData}
                    setVesselData={setVesselData}
                    type="manual"
                />
            </div>
            <div className="layout-item">
                <VesselSearch updateVessel={setVessel} vessel={vessel} getUpdatedVessel={getUpdatedVessel} />
            </div>
        </>
    ) : (
        <>
            <div className="layout-item">
                <TransitVesselDropdownList
                    index={index}
                    portDetails={portDetails}
                    setPortDetails={updatePortDetails}
                    isErrorMessageShow={false}
                    vesselData={vesselData}
                    setVesselData={setVesselData}
                    type="manual"
                />
            </div>
            <div className="layout-item">
                <VesselSearch
                    index={index}
                    vessel={portDetails[index].vessel}
                    updateVessel={updatePortDetails}
                    getUpdatedVessel={getUpdatedVessel}
                />
            </div>
        </>
    );
};
export default WrapperComponent;
