import { get, post, put } from '../HttpMethods';
import axios from 'axios';

export interface address {
    address1: string;
    address2: string;
    country: string;
    city: string;
    postCode: string;
    state: string;
}

export interface manufacturingCategories {
    mc_pk: string;
    mc_manufacturingcategory: string;
}

export interface vendorBasicDetails {
    is_Child: boolean;
    vn_parent: string | null;
    vn_name: string;
    vn_org_code: string;
    vn_org_category: string;
    vn_abn: string;
    vn_unloco: string;
    vn_origin_country: string;
    vn_originCountryname?: string;
    vn_origin_country_code?: string;
    vn_default_incoterms: string;
    vn_phone: string;
    vn_website_url: string;
    vn_manufacturing_categories: string[];
    vn_pk?: string;
}

export interface parentData {
    vn_vendorcode: string;
    vn_vendorname: string;
    vn_vendorcountry: string;
    vn_status: string;
    vn_PK: string;
}

export interface unLoco {
    pr_Country: string;
    pr_Name: string;
    pr_PK: string;
    pr_PortId: string;
}

export interface addressList extends address {
    oa_pk?: string;
}

export interface editVendorProfile extends vendorBasicDetails {
    vn_pk: string;
}

export interface integrationData {
    vi_integrationtype: string;
    vi_functiontype: string;
    vi_pk?: string;
}

export interface contacts {
    OC_PK: string;
    oc_contactName: string;
    oc_title: string;
    oc_phonenumber: string;
    oc_mobilenumber: string;
    oc_email: string;
    isPrimary: string;
}

export interface defaultIncoTerms {
    di_pk: string;
    di_default_incoterms: string;
}

export interface organisationCategories {
    oc_pk: string;
    oc_organisation_category: string;
}

export interface userDetails {
    us_FirstName: string;
    us_Email: string;
    us_LastName: string;
    us_PhoneNo: string;
    us_UserName: string;
    us_MiddleName: string | null;
}

export const contactRoles = [
    'Account Manager',
    'Customs Agent',
    'Sales Representative',
    'Complier',
    'Offshore Complier',
    'Import Operator',
    'Customs Broker',
    'Customer Service Representative',
];
export interface forwarderContacts {
    role: typeof contactRoles[number];
    users: userDetails[];
}

export interface contactLists {
    forwarder_contacts: forwarderContacts[];
    client_contacts: userDetails[];
}

export const addAddress = async (payload: { data: address; vn_pk: string }) => {
    try {
        const data = await post(`/api/mgt/vendors/address/${payload.vn_pk}`, payload.data, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addBasicDetails = async (payload: { data: vendorBasicDetails }) => {
    try {
        const data = await post('/api/mgt/vendors', payload.data, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const listParents = async (_payload: any) => {
    try {
        const res = await get('/api/mgt/vendors/parents', null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getVendorBasicDetails = async (payload: { vendorPK: string }) => {
    try {
        const res = await get(`/api/mgt/vendors/profile?vn_pk=${payload.vendorPK}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getManufacturingCategories = async (_payload: any) => {
    try {
        const res = await get('/api/mgt/vendors/manufacturingcategories', null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchUnloco = async (payload: { service: string; searchTerm: string; portType: string }) => {
    try {
        const res = await get(
            `/api/${payload.service ?? 'mgt'}/search/unloco?search=${payload.searchTerm}${
                payload?.portType ? '&portType=' + payload?.portType : ''
            }`,
            null
        );

        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getVendorAddressList = async (payload: { vn_pk: string }) => {
    try {
        const res = await get(`/api/mgt/vendors/list/address/${payload.vn_pk} `, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getVendorContacts = async (payload: { vn_pk: string }) => {
    try {
        const res = await get(`/api/mgt/vendors/list/contact/${payload.vn_pk}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const editVendorAddress = async (payload: { vendorAddress: address; vn_pk: string; oa_pk: string }) => {
    try {
        const data = await put(
            `/api/mgt/vendors/address/edit?vn_pk=${payload.vn_pk}&oa_pk=${payload.oa_pk}`,
            payload.vendorAddress
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const editVendorProfile = async (payload: { vn_pk: string; data: editVendorProfile }) => {
    try {
        const data = await put(`/api/mgt/vendors/${payload.vn_pk}`, payload.data);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getVendorIntegrationDetails = async (payload: { vn_pk: string }) => {
    try {
        const res = await get(`/api/mgt/vendors/integration?vn_pk=${payload.vn_pk}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const addVendorIntegrationDetails = async (payload: { vn_pk: string; data: integrationData[] }) => {
    try {
        const data = await post(`/api/mgt/vendors/integration?vn_pk=${payload.vn_pk}`, payload.data, null);
        return { data, status: 200, success: true };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getDefaultIncoTerms = async (_payload: any) => {
    try {
        const res = await get('/api/mgt/vendors/defaultincoterms', null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getOrganisationCategories = async (_payload: any) => {
    try {
        const res = await get('/api/mgt/vendors/organisationcategories', null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const submitVendorIntegrationDetailsAndActivate = async (payload: { vn_pk: string }) => {
    try {
        const data = await post(`/api/mgt/vendors/activate?vn_pk=${payload.vn_pk}`, null, null);
        return { data, status: 200, success: true };
    } catch (err) {
        return err;
    }
};
