/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';

interface additionalDetailsPrivateColoader {
    poNumber: string;
    client: string;
    goodsReadyDate: string;
    approvedLclCost: string;
    approvedLclQuotation: string;
    approvedLclQuotationId: string;
}
interface AdditionalDetailsState {
    isLoading: boolean;
    sailingReferenceNumber: string;
    clientNames: string[];
    additionalDetails: additionalDetailsPrivateColoader[];
    destinationAgentCode: string | null;
    destinationAgentName: string | null;
}

const initialState: AdditionalDetailsState = {
    isLoading: true,
    sailingReferenceNumber: '',
    clientNames: [],
    additionalDetails: [],
    destinationAgentCode: '',
    destinationAgentName: '',
};

export const additionalDetailsSlice = createSlice({
    name: 'additionalDetailsLcl',
    initialState,
    reducers: {
        fetchDetailsLcl: (state, _action) => {
            state.isLoading = true;
        },
        fetchDetailsLclSuccess(state, action) {
            state.isLoading = false;
            state.sailingReferenceNumber = action.payload?.sailingReferenceNumber;
            state.clientNames = action.payload?.clientNames;
            state.additionalDetails = action.payload?.additionalDetails;
            state.destinationAgentCode = action.payload?.destinationAgentCode;
            state.destinationAgentName = action.payload?.destinationAgentName;
        },
        fetchDetailsLclFailed(state, _action) {
            state.isLoading = false;
        },
    },
});

export const { fetchDetailsLcl, fetchDetailsLclSuccess, fetchDetailsLclFailed } = additionalDetailsSlice.actions;
export default additionalDetailsSlice.reducer;
