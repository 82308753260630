import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

const ShippingPointIcon = (shippingMode?: string, zoom?: any) => {
    const iconMarkup = renderToStaticMarkup(
        <svg
            className={`svg-icon ship-icon aeroplane-icon`}
            style={{
                width: zoom > 10 ? '60px' : zoom > 8 ? '30px' : '15px',
                height: zoom > 10 ? '60px' : zoom > 8 ? '30px' : '15px',
            }}
        >
            <use xlinkHref={`${shippingMode === 'AIR' ? '#aeroplanefillIcon' : '#shipfillIcon'}`}>
                <title>{shippingMode === 'AIR' ? 'Aeroplace' : 'Ship'}</title>
            </use>
        </svg>
    );
    const customMarkerIcon = divIcon({
        html: iconMarkup,
    });
    return customMarkerIcon;
};

export default ShippingPointIcon;
