import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'classbOptions',
    initialState: {
        options: [],
        ssrsReport: 'NA' as any,
        sendClassBResult: 0,
        sendEmailResult: 0,
    },
    reducers: {
        fetchClassbDropdownList: (state, action) => {
            state.options = action.payload;
        },
        fetchSsrsClassbReport: (state, action) => {
            state.ssrsReport = action.payload;
        },
        resetSsrsClassbReport: (state, _action) => {
            state.ssrsReport = 'NA';
        },
        sendClassbFileToBlob: (state, action) => {
            state.sendClassBResult = action.payload;
        },
        resetSendClassbFileToBlob: (state, _action) => {
            state.sendClassBResult = 0;
        },
        sendClassBEmailFile: (state, action) => {
            state.sendEmailResult = action.payload;
        },
        resetSendClassBEmailFile: (state, _action) => {
            state.sendEmailResult = 0;
        },
    },
});

export const {
    fetchClassbDropdownList,
    fetchSsrsClassbReport,
    resetSsrsClassbReport,
    sendClassbFileToBlob,
    resetSendClassbFileToBlob,
    sendClassBEmailFile,
    resetSendClassBEmailFile,
} = dataSlice.actions;
export default dataSlice.reducer;
