import { get, post } from '../../HttpMethods';
import { getOrganisationData } from '../../../utils';

export interface ShippingLineData {
    orgId: string;
    orgName: string;
    orgCode: string;
    orgStatus: string;
    parentOrgCode: string;
    shippingLineId: string;
    shippingLineStatus: string;
    oceanCarrierName: string;
    shippingLineCode: string;
    carrierCategoryCode: string;
    relationship: string;
    // country: string[];
    childShippingLines: ShippingLineData[];
}

export interface shippingLineResponse {
    shippingLineResponseList: ShippingLineData[];
    totalNoOfPages: number;
    totalNoOfItems: number;
}

export interface shippingLineSearchData {
    content: ShippingLineData[];
    totalPages: number;
    totalElements: number;
}

export const getBasicDetails = async (payload: any) => {
    try {
        const res = await get(`api/ord/po/basicdetails/list?poId=${payload}`, null);
        return res.info;
    } catch (err) {
        return err;
    }
};
//required details create
export const getOrderType = async () => {
    try {
        const res = await get('api/ord/po/basicdetails/ordertype', null);
        const result = res?.info.map((res: any) => ({ value: res.name, label: res.name }));
        return result;
    } catch (err) {
        return err;
    }
};
export const getShippingmode = async () => {
    try {
        const res = await get('api/ord/po/basicdetails/shippingmode', null);
        const result = res?.info.map((res: any) => ({ value: res.name, label: res.name }));
        return result;
    } catch (err) {
        return err;
    }
};
export const getFclService = async () => {
    try {
        const res = await get('api/ord/po/basicdetails/fclservicetype', null);
        const result = res?.info.map((res: any) => ({ value: res.name, label: res.name }));
        return result;
    } catch (err) {
        return err;
    }
};
export const getFrieghtTerm = async () => {
    try {
        const res = await get('api/ord/po/basicdetails/freightterm', null);
        const result = res?.info.map((res: any) => ({ value: res.name, label: res.name }));
        return result;
    } catch (err) {
        return err;
    }
};
export const getPorts = async (payload: any) => {
    try {
        const res = await get(
            `api/mgt/shippinglinecontract/portlist${payload?.portType ? `?portType=${payload?.portType}` : ''}`,
            null
        );
        const result = res?.info.map((res: any) => ({
            value: res.sp_portcode,
            label: `${res.sp_portname}(${res.sp_portId})`,
        }));
        return result;
    } catch (err) {
        return err;
    }
};
export const getIncoterm = async () => {
    try {
        const res = await get('api/ord/po/basicdetails/incoterm', null);
        const result = res?.info.map((res: any) => ({ value: res.code, label: res.name }));
        return result;
    } catch (err) {
        return err;
    }
};

export const getReleaseType = async () => {
    try {
        const res = await get('api/ord/po/basicdetails/releasetype', null);
        const result = res?.info.map((res: any) => ({ value: res.name, label: res.code + ' (' + res.name + ')' }));
        return result;
    } catch (err) {
        return err;
    }
};
export const getpaymentterms = async () => {
    try {
        const res = await get('api/ord/po/basicdetails/paymentterm', null);
        const result = res?.info.map((res: any) => ({ value: res.name, label: res.name }));
        return result;
    } catch (err) {
        return err;
    }
};
export const getagents = async (payload: any) => {
    try {
        const res = await get(`api/ord/po/agents?portcode=${payload.code}&mode=${payload.mode}`, null);
        const result = res?.info.map((res: any) => ({
            value: res.agentCode,
            label: res.agentName,
            extraField: res?.isAppointedAgent,
        }));
        return result;
    } catch (err) {
        return err;
    }
};

export const getDestAgentsApi = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/agents?portcode=${payload}`, null);
        const result = res?.info.map((res: any) => ({
            value: res.agentCode,
            label: res.agentName,
            extraField: res?.isAppointedAgent,
        }));
        return result;
    } catch (err) {
        return err;
    }
};

export const getdestinationaddress = async (payload: any) => {
    try {
        /* eslint no-var: off */
        const res = await get(`api/ord/po/clientaddress?orgCode=${payload}`, null);
        const fnCheck = (res: any) => {
            var address = res.addressLine1;
            if (res.addressLine2) {
                address = address + ', ' + res.addressLine2;
            }
            if (res.addressLine3) {
                address = address + ', ' + res.addressLine3;
            }
            if (res.city) {
                address = address + ', ' + res.city;
            }

            if (res.state) {
                address = address + ', ' + res.state;
            }

            if (res.postcode) {
                address = address + ', ' + res.postcode;
            }
            return address;
        };
        const result = res?.info.map((res: any) => ({
            value: res.addressId,
            label: fnCheck(res),
        }));
        return result;
    } catch (err) {
        return err;
    }
};
export const addRequiredDetails = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        const newPayload = { po: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/po/basicdetails/requiredfields', newPayload, null);
        return { data: data, status: 200 };
    } catch (error) {
        return error;
    }
};

//add additional details

export const getCurrency = async () => {
    try {
        const res = await get('api/bkg/bp/basicdetails/ordercurrency', null);
        const result = res?.info.map((res: any) => ({ value: res.name, label: res.code }));
        return result;
    } catch (err) {
        return err;
    }
};

export const getStoreIDData = async (clientCode: string) => {
    try {
        const res = await get(`api/ord/ao/storeids/${clientCode}`, null);
        const result = res?.info.map((res: any) => ({ value: res.storeId, label: res.storeId }));
        return result;
    } catch (err) {
        return err;
    }
};

export const getBlpAgents = async () => {
    try {
        const res = await get('api/ord/po/blpagents', null);
        const result = res?.info.map((res: any) => ({ value: res.blpAgentCode, label: res.blp }));
        return result;
    } catch (err) {
        return err;
    }
};

export const getBuyers = async (payload: any) => {
    try {
        const res = await get(`api/ord/po/clientusers?orgCode=${payload}`, null);
        const result = res?.info.map((res: any) => ({ value: res.id, label: res.name }));
        return result;
    } catch (err) {
        return err;
    }
};

export const addAdditionalDetails = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        const newPayload = { basicDetails: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await post('api/ord/po/basicdetails/additionalfields', newPayload, null);
        return { data: data, status: 200 };
    } catch (error) {
        return error;
    }
};

export const confirmSubmit = async (payload: any) => {
    try {
        const data = await get(`api/ord/po/submit/${payload}`, null);
        return { data: data, status: 200 };
    } catch (error) {
        return error;
    }
};
