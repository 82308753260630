import { lazy, Suspense, useEffect, useState } from 'react';
// import { ErrorBoundary } from 'react-error-boundary';
// import { Provider } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import ErrorFallback from './components/common/ErrorFallback';
const NotFound = lazy(() => import('./components/common/NotFound'));
// import Router from './Common/Router';
// import store from './store/store';
import routes from './routes';
import Header from './components/common/Layouts/Header';
import Loading from './components/common/Loading';
import Icons from './components/common/Icons';
import { MsalProvider } from '@azure/msal-react';
import { IPublicClientApplication } from '@azure/msal-browser';
/* eslint-disable no-undefined */
import AuthenticationTemplate from './components/AuthenticationTemplate';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingBar from 'react-redux-loading-bar';
import { checkPermissionsWrap, checkRoles } from './utils/checkPermission';
import FetchPermission from './ShowForPermissions/getPermissionData';
import { OnlineStatusProvider } from './hooks/useOnlineStatus';
import { msalInstance } from '.';
import { get } from './services/HttpMethods';
import { registerPageLoad } from 'src/services/matomoRegisterPageLoad';
import { clearDashboardInsightsFilters } from './pages/Dashboard/Insights/clearDashboardInsightsFilters';
import { useDispatch } from 'react-redux';
import { modifyPermissionData } from './services/apis/permissionApi';

type AppProps = {
    pca: IPublicClientApplication;
};

const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
const indexVal = localStorage.getItem('roleIndex');

const App = ({ pca }: AppProps) => {
    const location = useLocation();
    const dispatch = useDispatch();
    const [permissions, setpermissions] = useState([]); //to store the permissions
    const [loader, setLoader] = useState(true);
    const [locationChange, setlocationChange] = useState(null);
    const [username, setusername] = useState('');
    const [firstRender, setfirstRender] = useState(false);

    useEffect(() => {
        const userDetails = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
        if (indexVal === null && userDetails) {
            userDetails?.roles?.forEach((element: any, i: any) => {
                if (element.isDefault) {
                    localStorage.setItem('roleIndex', i); //setting the profile role to isdefault value on initial load
                }
            });
        }
    }, [indexVal, data1]);

    useEffect(() => {
        if (!msalInstance.getActiveAccount()) {
            setLoader(false);
            return;
        }
        const userDetails =
            localStorage?.getItem('wholePerms') != null &&
            localStorage?.getItem('wholePerms') != undefined &&
            localStorage?.getItem('wholePerms') != 'undefined' &&
            JSON.parse(localStorage?.getItem('wholePerms') || '');
        const userProfileNumber = localStorage?.getItem('roleIndex'); //index of current profile role
        document.addEventListener('build', function (props) {
            //event listner to catch username right after loging in
            if ((props as any)?.detail) {
                setusername((props as any)?.detail);
                getLatestPerms((props as any).detail, userProfileNumber);
            }
            //username = (props as any)?.detail;
        });

        //get current roles on each reload
        if (username) {
            getLatestPerms(username, userProfileNumber);
        } else {
            getLatestPerms(data1?.user?.us_UserName, userProfileNumber);
        }
        if (userDetails !== false) {
            //switching between localstorage and api call for permissions
            if ((permissions as any)?.length <= 0 && userProfileNumber) {
                setpermissions(
                    userDetails?.roles[userProfileNumber !== null ? (userProfileNumber as any) : 0]?.permissions
                );
            }
        }

        setLoader(false);
    }, [checkPermissionsWrap, window.location.pathname, locationChange, data1, localStorage?.getItem('roleIndex')]);

    //removing current inventory warehouse selected data
    useEffect(() => {
        if (!window.location.pathname.includes('/inventory')) {
            localStorage.removeItem('currentWarehouse');
        }
    }, [window.location.pathname]);

    //to update the permissions on each load regardless of localstorage
    const getLatestPerms = async (params: any, userIndex: any) => {
        //run the api if detects hard refresh or first rendering
        if (!firstRender && params) {
            const result = await get(`api/auth/refreshuserdetails?userName=${params}`, null);
            const res = modifyPermissionData(result);
            localStorage.setItem('wholePerms', JSON.stringify(res)); //saving the permission data to local storage
            const timeout = setTimeout(() => {
                setpermissions(res.roles[userIndex !== null ? (userIndex as any) : 0]?.permissions);
            }, 200);
            setTimeout(() => getLatestPerms(params, userIndex), 900000); //run the api every 15 minutes
            setfirstRender(true); //changing the state to false for checking hard refresh
            return () => clearTimeout(timeout);
        }
    };

    useEffect(() => {
        registerPageLoad(location?.pathname);
        // if (location.pathname?.startsWith('/dashboard/to-do/'))
        //     clearDashboardInsightsFilters(dispatch, 'from-todo');
        // } else
        if (
            location.pathname !== '/' &&
            location.pathname !== '/dashboard/insight' &&
            location.pathname !== '/dashboard/map' &&
            location.pathname !== '/dashboard/to-do-activity-tabs' &&
            !location.pathname?.startsWith('/dashboard/to-do/') &&
            !location.pathname?.startsWith('/dashboard/activity-feed/')
        ) {
            clearDashboardInsightsFilters(dispatch);
        }
    }, [location]);

    if (loader) {
        return <Loading />;
    }
    return (
        <OnlineStatusProvider>
            <MsalProvider instance={pca}>
                <Suspense fallback={<Loading />}>
                    <Routes>
                        {permissions &&
                            routes.map(({ component: Component, path, permission, type, roleCheck, roles }, key) => {
                                if (permission === undefined && type === undefined && !roleCheck) {
                                    return (
                                        <Route
                                            path={path}
                                            element={
                                                <AuthenticationTemplate>
                                                    {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
                                                    <LoadingBar className="loading-bar" />
                                                    <Header setlocationChange={setlocationChange} />
                                                    <ErrorFallback>
                                                        <Component />
                                                    </ErrorFallback>
                                                    <ToastContainer />
                                                    {/* </ErrorBoundary> */}
                                                </AuthenticationTemplate>
                                            }
                                            key={key}
                                        />
                                    );
                                } else if (path === '/notfound') {
                                    return (
                                        <Route
                                            path={path}
                                            element={
                                                <AuthenticationTemplate>
                                                    {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
                                                    <LoadingBar className="loading-bar" />
                                                    <Component />
                                                    <ToastContainer />
                                                    {/* </ErrorBoundary> */}
                                                </AuthenticationTemplate>
                                            }
                                            key={key}
                                        />
                                    );
                                } else if (permission !== undefined && type !== undefined && !roleCheck) {
                                    if (checkPermissionsWrap(permissions, permission, type)) {
                                        //checking page access permissions
                                        return (
                                            <Route
                                                path={path}
                                                element={
                                                    <AuthenticationTemplate>
                                                        {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
                                                        <LoadingBar className="loading-bar" />
                                                        <Header setlocationChange={setlocationChange} />
                                                        <ErrorFallback>
                                                            <Component />
                                                        </ErrorFallback>
                                                        <ToastContainer />
                                                        {/* </ErrorBoundary> */}
                                                    </AuthenticationTemplate>
                                                }
                                                key={key}
                                            />
                                        );
                                    }
                                } else if (permission === undefined && type === undefined && roleCheck) {
                                    if (checkRoles(roles)) {
                                        return (
                                            <Route
                                                path={path}
                                                element={
                                                    <AuthenticationTemplate>
                                                        {/* <ErrorBoundary FallbackComponent={ErrorFallback}> */}
                                                        <LoadingBar className="loading-bar" />
                                                        <Header setlocationChange={setlocationChange} />
                                                        <ErrorFallback>
                                                            <Component />
                                                        </ErrorFallback>
                                                        <ToastContainer />
                                                        {/* </ErrorBoundary> */}
                                                    </AuthenticationTemplate>
                                                }
                                                key={key}
                                            />
                                        );
                                    }
                                } else {
                                }
                            })}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>
                <Icons />
                <FetchPermission />
            </MsalProvider>
        </OnlineStatusProvider>
    );
};

export default App;
