import React from 'react';
import { Helmet } from 'react-helmet-async';

interface GridLayoutProps {
    data: unknown;
    columns: unknown;
    fileName: string;
    fields: unknown;
}
const GridLayout = ({ data, columns, fileName, fields }: GridLayoutProps) => {
    if (!data) {
        return null;
    }

    return (
        <Helmet>
            <script>
                {`
                $(document).ready(function () {
                    $('#grid').kendoGrid({
                    toolbar: ['excel','pdf'],
                    excel: {
                        fileName: '${fileName}.xlsx'
                    },
                    pdf: {
                        fileName: '${fileName}.pdf'
                    },
                    toolbar: [
                        {
                          name: 'search',
                          template: '<input type="search" id="searchBox" class="k-textbox llama" style="width: 250px"/>',
                        },
                        {
                          name: 'excel',
                          template: '<button class="k-button k-grid-excel app-btn icon-button app-btn-secondary custom-button"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><svg class="svg-icon add-btn-icon custom-icon"><use xlink:href="\\\\\\#downloadIcon"><title>Warning</title></use></svg></button>',
                        },
                        // {
                        //   template:
                        //     '<select id="layoutDDL" class="k-dropdown  k-ie k-select k-state-border-down"><option>Layout</option></select>',
                        // },
                        // {
                        //   template: '<button class="app-btn icon-button app-btn-secondary custom-button" onClick="updloadSettings()"  data-bs-toggle="tooltip" data-bs-placement="left" title="Save Layout"><svg class="svg-icon export-icon custom-icon"><use xlink:href="\\\\\\#saveIcon"><title>Warning</title></use></svg></button>',
                        // },
                        // {
                        //   template: '<button class="app-btn icon-button app-btn-secondary custom-button" data-bs-toggle="tooltip" data-bs-placement="left" title="Help"><svg class="svg-icon custom-icon"><use xlink:href="\\\\\\#helpIcon"><title>Warning</title></use></svg></button>',
                        // },
                      ],
                    dataSource: {
                        data: ${JSON.stringify(data)},
                        schema: {
                            model: {
                                fields: ${JSON.stringify(fields)},
                            },
                        },
                        pageSize: 25,
                      },
                      groupable: true,
                      sortable: {
                        allowUnsort: false,
                      },
                      filterable: true,
                      pageable: {
                        refresh: false,
                        pageSizes: [10, 25, 50, 100, 250, 500],
                        buttonCount: 5,
                      },
                      columns: ${JSON.stringify(columns)},
                      noRecords: true,
                      messages: {
                        noRecords: 'No records found',
                      },
                    });
                  });
                `}
            </script>
        </Helmet>
    );
};

export default GridLayout;
