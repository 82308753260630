import axios, { AxiosError } from 'axios';
const baseURL = process.env.REACT_APP_BASE_URL;

const recheckHealth = async (service: string) => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            Authorization: token ? `Bearer ${token}` : '',
        },
    };
    try {
        await axios.get(
            baseURL +
                `${
                    service === 'ord'
                        ? '/api/ord/notification/ordhealthcheck'
                        : service === 'mgt'
                        ? '/api/mgt/mgthealthcheck'
                        : '/api/bkg/notification/bkghealthcheck'
                }`,
            config
        );
        return { service, status: true };
    } catch (error) {
        return { service, status: false };
    }
};

const getService = (url: string) => {
    if (url?.includes('api/ord')) {
        return 'ord';
    } else if (url?.includes('api/mgt')) {
        return 'mgt';
    } else if (url?.includes('api/bkg')) {
        return 'bkg';
    }
};

const checkOtherService = async (service: string) => {
    const { status } = await recheckHealth(service);
    if (!status) {
        if (service === 'ord') {
            localStorage.setItem('orderServiceFailed', 'true');
            window.dispatchEvent(new Event('orderServiceFailedEvt'));
        } else if (service === 'mgt') {
            localStorage.setItem('mgtServiceFailed', 'true');
            window.dispatchEvent(new Event('mgtServiceFailedEvt'));
        } else if (service === 'bkg') {
            localStorage.setItem('bkgServiceFailed', 'true');
            window.dispatchEvent(new Event('bkgServiceFailedEvt'));
        }
    }
};

export const checkHealth = async (err: AxiosError) => {
    if (axios.isAxiosError(err)) {
        if (err?.response?.status === 503) {
            if (
                err?.config?.url?.includes('ordhealthcheck') ||
                err?.config?.url?.includes('mgthealthcheck') ||
                err?.config?.url?.includes('bkghealthcheck')
            ) {
                return;
            }
            const service = getService(err?.config?.url ?? '');
            if (service) {
                const response = await recheckHealth(service);
                if (response?.service === 'ord') {
                    localStorage.setItem('orderServiceFailed', response?.status ? 'false' : 'true');
                    window.dispatchEvent(new Event('orderServiceFailedEvt'));
                    if (!response?.status) {
                        await checkOtherService('mgt');
                        await checkOtherService('bkg');
                    }
                } else if (response?.service === 'mgt') {
                    localStorage.setItem('mgtServiceFailed', response?.status ? 'false' : 'true');
                    window.dispatchEvent(new Event('mgtServiceFailedEvt'));
                    if (!response?.status) {
                        await checkOtherService('ord');
                        await checkOtherService('bkg');
                    }
                } else if (response?.service === 'bkg') {
                    localStorage.setItem('bkgServiceFailed', response?.status ? 'false' : 'true');
                    window.dispatchEvent(new Event('bkgServiceFailedEvt'));
                    if (!response?.status) {
                        await checkOtherService('ord');
                        await checkOtherService('mgt');
                    }
                }
            }
        }
    }
};
