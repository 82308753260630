import moment from 'moment';

const dateValuesSailing = (startDate?: any, endDat?: any) => {
    return {
        startDate1: moment(startDate).subtract(7, 'days').format('yyyy-MM-DD') + ' 00:00:00',
        endDate1: moment(startDate).subtract(1, 'days').format('YYYY-MM-DD') + ' 00:00:00',
        startDate2: moment(startDate).format('YYYY-MM-DD') + ' 00:00:00',
        endDate2: moment(endDat).format('YYYY-MM-DD') + ' 00:00:00',
        startDate3: moment(endDat).add(1, 'days').format('YYYY-MM-DD') + ' 00:00:00',
        endDate3: moment(endDat).add(14, 'days').format('YYYY-MM-DD') + ' 00:00:00',
    };
};

export default dateValuesSailing;
