import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    addAgentIntegrationDetails,
    getAgentIntegrationDetails,
} from '../../../../services/apis/profiles/agents/integrationApi';
import {
    addAgentIntegrationData,
    fetchAgentIntegrationData,
    isLoadingFn,
} from '../../../reducers/profiles/agents/integrationReducer';

function* putIntegrationData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse<any> = yield call(addAgentIntegrationDetails, payload);
        payload['response'] = response;
        yield put({ type: addAgentIntegrationData, payload });
        yield put({ type: isLoadingFn, payload: false });
    } catch (err) {
        yield put({ type: isLoadingFn, payload: false });
        console.log('err', err);
        payload['error'] = err;
        yield put({ type: addAgentIntegrationData, payload });
    }
}

function* getIntegrationData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse<any> = yield call(getAgentIntegrationDetails, payload);
        payload['response'] = response;
        yield put({ type: fetchAgentIntegrationData, payload });
        yield put({ type: isLoadingFn, payload: false });
    } catch (err) {
        yield put({ type: isLoadingFn, payload: false });
        console.log('err', err);
    }
}

function* agentIntegrationDetailsSaga() {
    yield takeEvery('agentIntegrationDetails/addAgentIntegrationData', putIntegrationData);
    yield takeEvery('agentIntegrationDetails/fetchAgentIntegrationData', getIntegrationData);
}

export default agentIntegrationDetailsSaga;
