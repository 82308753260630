import React, { useEffect, useState } from 'react';
import '../../BookingProposal/BookingProcessTab/booking-process-tab.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotes } from '@fortawesome/pro-duotone-svg-icons';
import { getUnreadBpNotes } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { updateNotesRead } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { noteIconStyle } from 'src/utils/constants';
interface HeaderProps {
    id?: string;
    navData?: any;
}
const Index: React.FC<HeaderProps> = (props) => {
    const headerData = useSelector((state: RootState) => state.bpHeaderData) as any;
    const { profileType } = useUserProfile();
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate() as any;
    const dispatch = useDispatch();

    useEffect(() => {
        //navigate to booking list when trying to access other pages other than add po while no po is selected
        if (
            !location.pathname.includes('POSelection') &&
            !location.pathname.includes('events') &&
            !location.pathname.includes('notes') &&
            !location.pathname.includes('attachments') &&
            headerData?.tabValues &&
            headerData?.bpId &&
            !headerData?.tabValues?.po
        ) {
            navigate('/booking_list');
        }
    }, [headerData]);

    useEffect(() => {
        if (!headerData?.notesUnread && id && id !== 'POSelection') {
            getUnreadBpNotes(id)
                ?.then((res: any) => dispatch(updateNotesRead(res)))
                .catch((err) => console.log(err, 'err'));
        }
    }, []);

    let navDataCopy = [...props?.navData];
    if (profileType === 'client') {
        for (let index = 0; index < navDataCopy.length; index++) {
            if (navDataCopy[index]?.key === 'packing' && headerData.bpStatus === 'Awaiting_Client_Approval') {
                navDataCopy[index].disabled = false;
            }
        }
    }
    const { currentClientSelectedSchedule } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const [selectedFlightSchedule, setSelectedFlightSchedule] = useState('');
    useEffect(() => {
        if (
            selectedFlightSchedule === '' &&
            id === currentClientSelectedSchedule?.bpId &&
            currentClientSelectedSchedule?.value
        ) {
            setSelectedFlightSchedule(currentClientSelectedSchedule?.value);
        }
    }, [selectedFlightSchedule]);

    const tabCheckFunction = (element: any) => {
        let flag = false;
        switch (element.title) {
            case 'PO Selection':
                if (element.selected === 0 && headerData?.tabValues?.po) {
                    return (flag = true);
                }
                break;
            case 'Options':
                if (element.selected === 0 && headerData?.tabValues?.airOptions) {
                    return (flag = true);
                }
                break;
            case 'Flight Schedules':
                if (element.selected === 0 && (headerData?.tabValues?.airSchedules || selectedFlightSchedule)) {
                    return (flag = true);
                }
                break;
            case 'Booking Details':
                if (element.selected === 0 && headerData?.tabValues?.airBookingDetails) {
                    return (flag = true);
                }

                break;
            case 'Packing':
                if (element.selected === 0 && headerData?.tabValues?.containerDetails) {
                    return (flag = true);
                }

                break;
            case 'Shipment Details':
                if (element.selected === 0 && headerData?.tabValues?.airShipmentDetails) {
                    return (flag = true);
                }
                break;
        }

        return flag;
    };

    const extraTab = [
        {
            title: 'Attachments',
            selected: window.location.pathname.includes('/attachments') ? 1 : 0,
            path: `/air_booking_list/${id}/attachments`,
            disabled: window.location.pathname.includes('/POSelection/new') ? true : false,
        },
        {
            title: 'Notes',
            selected: window.location.pathname.includes('/notes') ? 1 : 0,
            path: `/air_booking_list/${id}/notes`,
            disabled: window.location.pathname.includes('/POSelection/new') ? true : false,
        },
        {
            title: 'Events',
            selected: window.location.pathname.includes('/events') ? 1 : 0,
            path: `/air_booking_list/${id}/events`,
            disabled: window.location.pathname.includes('/POSelection/new') ? true : false,
        },
    ];
    return (
        <div className="arrow-tab-container">
            <div className="arrow-tab-holder">
                {[...navDataCopy, ...extraTab]
                    ?.filter((res: any) => {
                        if (profileType === 'vendor') {
                            return res?.title !== 'Additional Details';
                        } else {
                            return res;
                        }
                    })
                    ?.map((element: any, index: number) => {
                        return (
                            <Link
                                key={index}
                                className={`arrow-tab-border booking-proposal-tab ${
                                    element.selected === 1
                                        ? ' active'
                                        : id && !element.disabled
                                        ? ' enabled'
                                        : ' disabled'
                                }`}
                                style={{
                                    justifyContent:
                                        element.title === 'Notes' ||
                                        element.title === 'Events' ||
                                        element.title === 'Attachments'
                                            ? 'center'
                                            : 'space-between',
                                }}
                                to={element.path || '/'}
                            >
                                {element.title === 'Notes' ? (
                                    <FontAwesomeIcon
                                        icon={faNotes}
                                        beatFade={headerData?.notesUnread}
                                        style={noteIconStyle as React.CSSProperties}
                                    />
                                ) : element.title === 'Events' ? (
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon calendar-icon">
                                        <use xlinkHref="#calendarIcon">
                                            <title>Calendar</title>
                                        </use>
                                    </svg>
                                ) : element.title === 'Attachments' ? (
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon eye-icon">
                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                        <title>Attach file</title>
                                    </svg>
                                ) : tabCheckFunction(element) ? (
                                    <div className="arrow-badge verify-badge">
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="arrow-badge">{index + 1}</div>
                                )}
                                <div className="arrow-tab">{element.title}</div>
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};
export default Index;
