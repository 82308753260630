// import './style.css';
import '../../../assets/scss/components/_modal.scss';

interface SkipModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    skipFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<SkipModalProps> = ({ display, displayFunction, skipFunction }) => {
    return (
        <>
            {display ? (
                <div className="app-modal container">
                    <div className="modal-content-holder modal-medium-width" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Skip PO Line Addition</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => {
                                    displayFunction(false);
                                }}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                By Skipping, this PO will be marked as header only with unknown PO line details. Are you
                                sure to continue?
                            </p>
                        </div>
                        <div className="modal-footer skip-action">
                            <button
                                className="app-btn app-btn-secondary modal-btn"
                                onClick={() => displayFunction(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn"
                                onClick={() => {
                                    skipFunction(true);
                                    // displayFunction(false);
                                }}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
