import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchAgentData,
    fetchAgentDetails,
    fetchHeaderDataAgents,
    isLoadingFn,
    searchAgentData,
    setHeaderDataAgents,
} from '../reducers/agentReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import {
    getAgentData,
    agentResponse,
    searchAgents,
    agentSearchData,
    searchAgentHeader,
} from '../../services/apis/agentListApi';
import { getAgentDetails } from 'src/services/apis/agentApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getAgentListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const agents: AxiosResponse<agentResponse> = yield call(getAgentData, payload);
        payload['agents'] = agents;
        yield put({ type: fetchAgentData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const agents: AxiosResponse<agentSearchData> = yield call(searchAgents, payload);
        payload['agents'] = agents;
        yield put({ type: searchAgentData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getAgentheaderData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const agents: AxiosResponse<any> = yield call(searchAgentHeader, payload);
        yield put({ type: setHeaderDataAgents, payload: agents });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* fetchAgentInfo({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getAgentDetails, payload);
        payload['agentDetails'] = response?.data[0];
        yield put({ type: fetchAgentDetails, payload: payload });
    } finally {
    }
}

function* agentSaga() {
    yield takeEvery('agent/fetchAgentData', getAgentListData);
    yield takeEvery('agent/searchAgentData', getSearchData);
    yield takeEvery('agent/fetchAgentDetails', fetchAgentInfo);
    yield takeEvery(fetchHeaderDataAgents, getAgentheaderData);
}

// Export the saga (data-saga)
export default agentSaga;
