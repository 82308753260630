// import { useSelector } from 'react-redux';
// import { RootState } from 'src/store/reducers';
import { getOrganisationData } from 'src/utils';

const listOfUserOrganisations = getOrganisationData();

//Get point in a line
const getPointWithinLine = ({
    startPoint,
    endPoint,
    ratio,
}: {
    startPoint: [number, number];
    endPoint: [number, number];
    ratio: number;
}): [number, number] | null => {
    if (ratio <= 0 || ratio >= 1) {
        return null;
    }
    const x1 = startPoint[0];
    const y1 = startPoint[1];
    const x2 = endPoint[0];
    const y2 = endPoint[1];

    const x3 = ratio * x2 + (1 - ratio) * x1; //find point that divides the segment
    const y3 = ratio * y2 + (1 - ratio) * y1; //into the ratio (1-ratio):ratio

    return [x3, y3];
};

//Get point in a bazier curve( degree-2, three control points)
const getCurveMidPoint = ({
    startPoint,
    endPoint,
}: {
    startPoint: [number, number];
    endPoint: [number, number];
}): { midPoint: [number, number]; theta: number } => {
    const offsetX = endPoint[1] - startPoint[1];
    const offsetY = endPoint[0] - startPoint[0];

    const r = Math.sqrt(Math.pow(offsetX, 2) + Math.pow(offsetY, 2));
    const theta = Math.atan2(offsetY, offsetX);

    const thetaOffset = 3.14 / 10;

    const r2 = r / 2 / Math.cos(thetaOffset);
    const theta2 = theta + thetaOffset;

    const midpointX = r2 * Math.cos(theta2) + startPoint[1];
    const midpointY = r2 * Math.sin(theta2) + startPoint[0];

    return { midPoint: [midpointY, midpointX], theta };
};

//Get point in a degree-2 bazier curve
const getPointWithinCurve = ({
    startPoint,
    endPoint,
    t,
}: {
    startPoint: [number, number];
    endPoint: [number, number];
    t: number; // t is any parameter where 0 <= t <= 1
}): [number, number] | null => {
    if (t < 0 || t > 1) {
        return null;
    }
    let changefactor = t;
    const { midPoint, theta } = getCurveMidPoint({
        startPoint,
        endPoint,
    });
    //The area to consider the icon in the line need to consider the inclination between the points
    // Checking theta values for that
    //TODO: Need to form more generalized queation  based on real data
    if (t === 0.7) {
        changefactor = theta > -1 ? 0.49 : theta < -1.4 ? 0.85 : 0.6;
    }
    if (t === 0.4 && theta < -1.4) {
        changefactor = 0.55;
    }

    const x0 = midPoint
        ? (1 - changefactor) * (1 - changefactor) * startPoint[0] +
          2 * (1 - changefactor) * changefactor * midPoint[0] +
          changefactor * changefactor * endPoint[0]
        : 0;
    const y0 = midPoint
        ? (1 - changefactor) * (1 - changefactor) * startPoint[1] +
          2 * (1 - changefactor) * changefactor * midPoint[1] +
          changefactor * changefactor * endPoint[1]
        : 0;

    return [x0, y0];
};
const searchItemFilter = (selectedSearchItem: any) => {
    if (selectedSearchItem?.type) {
        if (selectedSearchItem?.type === 'Clients') {
            return {
                client: [{ orgCode: selectedSearchItem?.code }],
            };
        } else if (selectedSearchItem?.type === 'Agents') {
            return {
                agent: [{ orgCode: selectedSearchItem?.code }],
            };
        } else if (selectedSearchItem?.type === 'Carriers') {
            return {
                sl: [{ orgCode: selectedSearchItem?.code }],
            };
        } else if (selectedSearchItem?.type === 'Vendors') {
            return {
                vendor: [{ orgCode: selectedSearchItem?.code }],
            };
        }
    }
    return listOfUserOrganisations;
};

export { getPointWithinLine, getCurveMidPoint, getPointWithinCurve, searchItemFilter };
