import React, { useEffect, useState } from 'react';
//import '../../BookingAddedGrid/booking-added-grid.scss';
import PoReview from '../../BookingAddedGrid/PurchaseOrderDetails';
import { useSelector } from 'react-redux';
// import { selectSku } from '../../../../store/reducers/purchaseOrder/productPoReducer';
// import { useAppSelector } from '../../../../hooks';
// import { fetchAddedPoData } from '../../../../store/reducers/bookingProposal/addedPoReducer';
import { RootState } from '../../../../store/reducers';
import ProductDetailModal from '../../BookingAddedGrid/ProductDetailsModal';
import ShowForPermissions from '../../../../ShowForPermissions';
// import { useUserProfile } from '../../../../hooks/useUserProfile';
// import { removeFromContainer } from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
import { checkUncheckSelectAll, checkUncheckSelectSingle, checkUncheckSelectedIds } from './checkUncheck';
/* eslint no-var: off */

interface BioProps {
    bpID?: any;
    setSelectedItemsAdded: any;
    selectedItemsAdded: any;
    addPoList: any;
    showConditionStatus: any;
    containerDetailsId: any;
    setPoDetails: any;
    listingPage: boolean;
    originalData?: any;
    isHeaderOnlyBp?: boolean;
    setAllPoData?: any;
    poDetails: boolean;
    pillClicked?: boolean;
    setPillClicked?: any;
    onSearch?: any;
}
const Index: React.FC<BioProps> = ({
    bpID,
    setSelectedItemsAdded,
    selectedItemsAdded,
    addPoList,
    showConditionStatus,
    // containerDetailsId,
    // setPoDetails,
    listingPage,
    originalData,
    setAllPoData,
    poDetails,
    pillClicked,
    setPillClicked,
    onSearch,
}) => {
    // const { profileType } = useUserProfile();
    // const sku = useAppSelector(selectSku);
    const { prouctsList } = useSelector((state: RootState) => state.addedPos);
    // const dispatch = useDispatch();
    // const [modalView, setModalView] = useState(true);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [productsAll, setproductsAll] = useState([]);
    const [VendorCode, setVendorCode] = useState('');
    // const [poLine, setpoLine] = useState(0);
    var poLineNum = 0;
    var poStatus = true;
    useEffect(() => {
        // dispatch(fetchAddedPoData(bpID));
    }, [bpID]);

    const selectedIds = (boId: string, poId: string, bsid: any, index: any, type: boolean) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === poId && selectedAr[i].boId === boId) {
                    var filterAr: any = [...selectedAr[i].subPolineIds, ...bsid];
                    flag = true;

                    filterAr = filterAr.filter(function (item: any, pos: any) {
                        return filterAr.indexOf(item) == pos;
                    });
                    selectedAr[i].subPolineIds = [...filterAr];
                }
            }
            if (!flag) {
                var newObj = {
                    id: poId,
                    boId,
                    isFullPo: false,
                    subPolineIds: [...bsid],
                };
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === poId && selectedAr[i].boId === boId) {
                    var delAr: any[] = [...selectedAr[i].subPolineIds];
                    for (var j = 0; j < delAr.length; j++) {
                        for (var k = 0; k < bsid.length; k++) {
                            if (delAr[j] === bsid[k]) {
                                delAr.splice(j, 1);
                                selectedAr[i].subPolineIds.splice(j, 1);
                                if (selectedAr[i].subPolineIds.length === 0) {
                                    selectedAr.splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
        checkUncheckSelectedIds(addPoList, originalData, setAllPoData, type, index);
    };

    useEffect(() => {
        if (pillClicked) {
            if (setAllPoData) {
                checkUncheckSelectAll(addPoList, originalData, setAllPoData, false);
                setPillClicked(false);
            }
        }
    }, [pillClicked]);

    const selectAll = (type: boolean) => {
        if (setAllPoData) {
            checkUncheckSelectAll(addPoList, originalData, setAllPoData, type);
        }
        var selectallar: any[] = [];
        var newObj = {};
        if (type) {
            addPoList?.forEach((element: any) => {
                var singleVal: any = [];
                newObj = {
                    id: element.poId,
                    boId: element.boId,
                    isFullPo: false,
                    subPolineIds: singleVal,
                };
                element.bookingProposalSubPOLineList?.forEach((element2: object) => {
                    var val = (element2 as any).bsId;
                    singleVal.push(val);
                });
                selectallar.push(newObj);
            });
            setSelectedItemsAdded(selectallar);
        } else {
            setSelectedItemsAdded([]);
        }
    };
    const getAllIds = (val: any) => {
        var newArray: any[] = [];
        val.forEach((element: any) => {
            newArray.push(element.bsId);
        });
        return newArray;
    };

    const checkSelected = (boId: string, poId: string, val: any) => {
        var flag = false;
        selectedItemsAdded.forEach((element: any) => {
            if (poId === element.id && boId === element.boId) {
                element.subPolineIds.forEach((element2: any) => {
                    if (element2 === val) {
                        flag = true;
                    }
                });
            }
        });

        return flag;
    };
    const allSelected = () => {
        var totalAll = 0;
        var totalSelected = 0;
        var flag = false;
        originalData?.forEach((element: any) => {
            element.bookingProposalSubPOLineList?.forEach((element1: any) => {
                if (element1.length !== 0) {
                    totalAll++;
                }
            });
        });
        selectedItemsAdded?.forEach((element: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            element.subPolineIds?.forEach((element1: any) => {
                totalSelected++;
            });
        });

        if (totalSelected >= totalAll && totalAll !== 0 && addPoList?.length) {
            flag = true;
        }
        return flag;
    };
    const singleRowChecked = (boId: string, poId: string, poData: any) => {
        var flag = false;
        if (poData?.length) {
            selectedItemsAdded?.forEach((element: any) => {
                if (
                    element.subPolineIds.length === poData[0]?.bookingProposalSubPOLineList?.length &&
                    element.id === poId &&
                    element.boId === boId
                ) {
                    flag = true;
                }
            });
        }
        return flag;
    };
    const selectSingle = (
        boId: string,
        poId: string,
        bsid: any,
        index: number,
        subLineIndex: number,
        type: boolean
    ) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === poId && selectedAr[i].boId === boId) {
                    flag = true;
                    selectedAr[i].subPolineIds = [...selectedAr[i].subPolineIds, bsid];
                }
            }
            if (!flag) {
                var newObj = {
                    id: poId,
                    boId,
                    isFullPo: false,
                    subPolineIds: [bsid],
                };
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === poId && selectedAr[i].boId === boId) {
                    var delAr: any[] = [...selectedAr[i].subPolineIds];
                    for (var j = 0; j < delAr.length; j++) {
                        if (delAr[j] === bsid) {
                            delAr.splice(j, 1);
                            selectedAr[i].subPolineIds.splice(j, 1);
                            if (selectedAr[i].subPolineIds.length === 0) {
                                selectedAr.splice(i, 1);
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
        checkUncheckSelectSingle(index, subLineIndex, addPoList, originalData, setAllPoData, type);
    };
    /* eslint-disable no-undefined */

    useEffect(() => {
        setproductsAll(prouctsList);
    }, [prouctsList]);

    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let isAgent = false;
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     // if (roleIndex) {
    //     //     isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
    //     // }
    // }

    const onQtyChange = (e: React.ChangeEvent<HTMLInputElement>, index: number, subLineIndex: number) => {
        const copy = JSON.parse(JSON.stringify(addPoList));
        const subPoLineOriginal = originalData[index].bookingProposalSubPOLineList[subLineIndex];
        const subPoLineCopy = copy[index].bookingProposalSubPOLineList[subLineIndex];

        if (
            subPoLineOriginal?.remainingQty === null ||
            subPoLineCopy?.remainingQty === subPoLineCopy?.allocationQuantity
        ) {
            subPoLineCopy.addedQty = +e.target.value;
            subPoLineCopy.remainingQty = subPoLineCopy.allocationQuantity - +e.target.value;
        } else {
            subPoLineCopy.addedQty = +e.target.value;
            subPoLineCopy.remainingQty = subPoLineOriginal.remainingQty - +e.target.value;
        }

        const cbmEach = subPoLineOriginal.cbm / subPoLineOriginal.allocationQuantity;
        const weightEach = subPoLineOriginal.weight / subPoLineOriginal.allocationQuantity;
        subPoLineCopy.cbm = parseFloat((cbmEach * subPoLineCopy.addedQty)?.toFixed(5));
        subPoLineCopy.weight = parseFloat((weightEach * subPoLineCopy.addedQty)?.toFixed(5));

        setAllPoData(copy);
    };

    const onSearchFn = onSearch ? onSearch : addPoList;

    return (
        <div className="grid-section grid-added-booking product-details-wrapper allocation-table__triangle">
            <div className="grid-holder">
                <div className="grid-container">
                    {onSearchFn?.length === 0 && (
                        <div className="grid-no-content ">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">
                                No AO lines {listingPage ? 'added to this container' : 'found'}
                            </p>
                        </div>
                    )}
                    <div className="grid-scroll-content">
                        <table className="grid-table allocation-table">
                            <thead>
                                <tr className="grid-sticky-row">
                                    <th className="grid-cell-header col-number grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data">
                                            <span className="header-title">AO NUMBER</span>
                                        </div>
                                    </th>
                                    {showConditionStatus && (
                                        <ShowForPermissions permission="edit" type="bp">
                                            <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data centre-align">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox-input"
                                                            checked={allSelected()}
                                                            onChange={(e) => selectAll(e.currentTarget.checked)}
                                                        />
                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </th>
                                        </ShowForPermissions>
                                    )}

                                    <th id="" className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CLIENT</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon sort-desc">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESTINATION</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-blp">
                                        <div className="grid-cell-data">
                                            <span className="header-title">BLP</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">sku</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESCRIPTION</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-qty">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ORDER QTY</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-qty">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ADDED QTY</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-qty">
                                        <div className="grid-cell-data">
                                            <span className="header-title">REMAINING QTY</span>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">
                                                CUSTOMER ORDER
                                                <br />
                                                REFERENCE NO.
                                            </span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">
                                                CUSTOMER ORDER <br />
                                                LINE NO.
                                            </span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ORIGIN</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESTINATION ADDRESS</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-cbm">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CBM</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-weight">
                                        <div className="grid-cell-data">
                                            <span className="header-title">WEIGHT (KG)</span>
                                            {/*<button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>*/}
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            {onSearchFn?.map((res: any, i: any) => (
                                <tbody key={i} className="grouped-splited-row">
                                    {res?.bookingProposalSubPOLineList?.map((val: any, i2: any) => {
                                        if (poLineNum === val?.poLineNumber && i2 !== 0) {
                                            poStatus = false;
                                        } else {
                                            poStatus = true;
                                        }
                                        poLineNum = val?.poLineNumber;
                                        return (
                                            <tr key={i2}>
                                                <td className="grid-cell col-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                    <div className="grid-cell-data">
                                                        <div className="checkbox-data-wrapper">
                                                            <ShowForPermissions permission="edit" type="bp">
                                                                {showConditionStatus && (
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            type="checkbox"
                                                                            checked={singleRowChecked(
                                                                                res?.boId,
                                                                                res?.poId,
                                                                                originalData?.filter(
                                                                                    (e: any) => e.poId === res?.poId
                                                                                )
                                                                            )}
                                                                            className="checkbox-input"
                                                                            onChange={(e) => {
                                                                                selectedIds(
                                                                                    res?.boId,
                                                                                    res?.poId,
                                                                                    getAllIds(
                                                                                        res?.bookingProposalSubPOLineList
                                                                                    ),
                                                                                    i,
                                                                                    e.currentTarget.checked
                                                                                );
                                                                            }}
                                                                        />
                                                                        {i2 === 0 && (
                                                                            <div className="checkmark">
                                                                                <svg className="svg-icon tick-icon">
                                                                                    <use xlinkHref="#tickIcon">
                                                                                        <title>check mark</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                        )}
                                                                    </label>
                                                                )}
                                                            </ShowForPermissions>
                                                            {i2 === 0 && (
                                                                <a
                                                                    //className="booking-proposal-link"
                                                                    style={{
                                                                        paddingLeft: '0',
                                                                        marginLeft: showConditionStatus ? '28px' : '',
                                                                        //cursor: 'pointer',
                                                                        color: 'unset',
                                                                        textDecoration: 'none',
                                                                    }}
                                                                    //onClick={() =>
                                                                    //    setpoDisplay({
                                                                    //        status: true,
                                                                    //        id: res?.poId,
                                                                    //    })
                                                                    //}
                                                                >
                                                                    {res?.poNumber}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                                {showConditionStatus && (
                                                    <ShowForPermissions permission="edit" type="bp">
                                                        <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                                            <div className="grid-cell-data centre-align">
                                                                <label className="app-check-wrapper">
                                                                    <input
                                                                        checked={checkSelected(
                                                                            res?.boId,
                                                                            res?.poId,
                                                                            val?.bsId
                                                                        )}
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        onChange={(e) =>
                                                                            selectSingle(
                                                                                res?.boId,
                                                                                res?.poId,
                                                                                val?.bsId,
                                                                                i,
                                                                                i2,
                                                                                e.currentTarget.checked
                                                                            )
                                                                        }
                                                                    />
                                                                    <div className="checkmark">
                                                                        <svg className="svg-icon tick-icon">
                                                                            <use xlinkHref="#tickIcon">
                                                                                <title>check mark</title>
                                                                            </use>
                                                                        </svg>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </td>
                                                    </ShowForPermissions>
                                                )}

                                                <td className="grid-cell col-po-line">
                                                    <div className="grid-cell-data">{poStatus && val?.clientName}</div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">
                                                        {poStatus && val?.destinationPortName}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">{poStatus && val?.blpName}</div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">
                                                        <a
                                                            onClick={() => {
                                                                setVendorCode(res?.clientCode);
                                                                setModalViewproduct({
                                                                    status: true,
                                                                    value: res?.clientCode,
                                                                    id: val?.skuId,
                                                                });
                                                            }}
                                                            style={{ cursor: 'pointer' }}
                                                        >
                                                            {poStatus && val?.sku}
                                                        </a>
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">{poStatus && val?.description}</div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">
                                                        {poStatus && val?.allocationQuantity}
                                                    </div>
                                                </td>
                                                {poDetails ? (
                                                    <>
                                                        <td className="grid-cell col-po-qty search-entry-holder">
                                                            <div className="grid-cell-data">
                                                                <input
                                                                    placeholder=""
                                                                    type="text"
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    className="search-input input-text input-qty-add"
                                                                    name="confirmedQty"
                                                                    value={val?.addedQty}
                                                                    disabled={
                                                                        checkSelected(res?.boId, res?.poId, val?.bsId)
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onChange={(e) => onQtyChange(e, i, i2)}
                                                                ></input>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell col-po-qty search-entry-holder">
                                                            <div className="grid-cell-data">
                                                                {(val?.remainingQty === null ||
                                                                    val?.remainingQty === val?.allocationQuantity) &&
                                                                checkSelected(res?.boId, res?.poId, val?.bsId) ===
                                                                    false ? (
                                                                    '-'
                                                                ) : (
                                                                    <span
                                                                        className={`${
                                                                            val?.remainingQty < 0
                                                                                ? 'status-remaining'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        {val?.remainingQty}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="grid-cell col-po-qty search-entry-holder">
                                                            <div className="grid-cell-data">{val?.addedQty}</div>
                                                        </td>
                                                        <td className="grid-cell col-po-qty search-entry-holder">
                                                            <div className="grid-cell-data">{val?.remainingQty}</div>
                                                        </td>
                                                    </>
                                                )}
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">
                                                        {poStatus && val?.customerOrderRefNo}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-qty">
                                                    <div className="grid-cell-data">{val?.customerOrderLineNo}</div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">
                                                        {poStatus && val?.originPortName}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">
                                                        {poStatus && val?.destinationAddress}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-desc">
                                                    <div className="grid-cell-data">{poStatus && val?.cbm}</div>
                                                </td>

                                                <td className="grid-cell col-po-cbm">
                                                    <div className="grid-cell-data">{val?.weight}</div>
                                                </td>

                                                {/*<td className="grid-cell col-po-code">
                                                    <div className="grid-cell-data">{val?.hsCode}</div>
                                                </td>*/}
                                                {/*<td className="grid-cell col-po-added-vendor">
                                                    <div className="grid-cell-data">{res?.vendorName}</div>
                                                </td>*/}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ))}
                        </table>
                    </div>
                    {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} customStyle={true} />}{' '}
                    {ModalViewproduct.status && (
                        <ProductDetailModal
                            display={ModalViewproduct}
                            displayFunction={setModalViewproduct}
                            id={VendorCode}
                            data={prouctsList}
                            customStyle={true}
                        />
                    )}
                    {/* <CancelBookingModal></CancelBookingModal> */}
                    {/* <ProductDetailsModal data={skuDetail} display={modalView} displayFunction={setModalView} /> */}
                </div>
                {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} customStyle={true} />}{' '}
                {/* <CancelBookingModal></CancelBookingModal> */}
                {/* <ProductDetailsModal data={skuDetail} display={modalView} displayFunction={setModalView} /> */}
                {/* <LinkedBookingModal></LinkedBookingModal> */}
            </div>
        </div>
    );
};
export default Index;
