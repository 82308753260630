import React from 'react';
import MultiLineGraph from 'src/components/common/MultiLineGraph';
import './trend-graph.scss';
import { TrendDetails } from '../..';

interface Props {
    trendComparison: string;
    data?: any;
    allData?: any;
    tooltipUnit: string;
}

const Index: React.FC<Props> = ({ trendComparison, data, allData, tooltipUnit }) => {
    // const monthsIndexArray: any[] = [];
    // const j = new Date().getMonth();
    // let k = j;
    // for (let i = 0; i < 12; i++) {
    //     if (j === 11 && k === 11) {
    //         monthsIndexArray.push(11);
    //         k = 0;
    //         monthsIndexArray.push(k);
    //         k++;
    //     } else if (j === 11 && k > 0) {
    //         monthsIndexArray.push(k);
    //         k++;
    //     }
    //     if (j === 0 && k >= 0) {
    //         monthsIndexArray.push(k);
    //         k++;
    //     } else if (j > 0 && k === 12) {
    //         k = 0;
    //         monthsIndexArray.push(k);
    //         k++;
    //     } else if (j > 0 && k > 0) {
    //         monthsIndexArray.push(k);
    //         k--;
    //     } else if (j > 0 && k === 0) {
    //         monthsIndexArray.push(k);
    //         k = 11;
    //     }
    //     console.log(addit j= ', j, 'k= ', k);
    // }

    const plotGraphPoints = () => {
        const dataCopy = JSON.parse(JSON.stringify(data));
        delete dataCopy?.count;
        let graphPoints: any = [];
        trendComparison === TrendDetails.Compare
            ? (graphPoints = data?.map((val: any, key: number) => {
                  const data = allData?.find((res: any) => res[val?.agentName]);

                  return {
                      key: key,
                      name: val?.agentName,
                      points: Object.values(data[val?.agentName])
                          ?.reverse()
                          ?.map((res: any) => res.count),
                      value: Object.values(data[val?.agentName])
                          ?.reverse()
                          ?.map((res: any) => res.date),
                  };
              }))
            : (graphPoints = [
                  {
                      points: Object.values(dataCopy)
                          ?.reverse()
                          ?.map((res: any) => res.count),
                      value: Object.values(dataCopy)
                          ?.reverse()
                          ?.map((res: any) => res.date),
                      extraValue: Object.values(dataCopy)
                          ?.reverse()
                          ?.map((res: any) => res?.clientCounts),
                  },
              ]);

        return graphPoints;
    };

    return (
        <>
            <div className="content-graph-area">
                <MultiLineGraph
                    drawn={plotGraphPoints()}
                    unit={tooltipUnit ?? ''}
                    name="insightTrendComparison"
                    tooltip={true}
                    lineDots={true}
                    pointSize={3}
                    graphAxis={true}
                />
            </div>
        </>
    );
};
export default Index;
