import React, { useEffect, useState } from 'react';
// import './style.css';
import '../../../../assets/scss/components/_modal.scss';
import '../../../../assets/scss/components/_status.scss';
import './vendor-modal.scss';
import Modal from '../../../../components/common/Modals';
import { useDispatch } from 'react-redux';
import {
    linkingVendors,
    linkingVendorsData,
    activeVendors,
    actionVendorsData,
} from '../../../../store/reducers/vendorReducer';
import CancelModal from './cancelModal';
interface BioProps {
    display: boolean;
    displayFunction: any;
    clientId: any;
    vendorList: any;
    assignedVendors: any;
    payload: any;
    settoastStat: any;
    vendorLoading: boolean;
}
interface simpleInt {
    vn_PK: string;
    vn_vendorcode: string;
    vn_vendorname: string;
}

type simpleType = simpleInt[];

const array: simpleType = [];

const Index: React.FC<BioProps> = (props) => {
    const [suggetions, setSuggetions] = useState(false);
    const [search, setSearch] = useState('');
    const [disable, setdisable] = useState(true);
    const [selectedVendors, setselectedVendors] = useState<simpleInt[] | []>([]);
    const [render, setrender] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    var found = false;
    var parentArray = [...props.vendorList];
    parentArray = JSON.parse(JSON.stringify(props.vendorList));

    const dispatch = useDispatch();

    useEffect(() => {
        setselectedVendors(array);
        if (selectedVendors.length === 0) {
            setdisable(true);
        }
    }, [render, selectedVendors, props.assignedVendors]);

    useEffect(() => {
        if (!props.display) {
            setSearch('');
            dispatch(actionVendorsData([]));
        }
    }, [props.display]);

    // useEffect(() => {
    //     if (props?.clientId) {
    //         dispatch(activeVendors(props?.clientId));
    //     }
    // }, [props?.clientId]);

    const selectedVendor = (val: any) => {
        array.push(val);
        setdisable(false);
        setrender(!render);
    };

    const linkVendor = () => {
        dispatch(linkingVendorsData(selectedVendors));
        props.displayFunction(false);
        const arraySel: string[] = [];
        selectedVendors.forEach((element: any) => {
            arraySel.push(element.vn_PK);
        });
        const payloadArray = {
            cl_pk: props.clientId,
            ve_pk: arraySel,
        };
        const finalPayload = { ...payloadArray, ...props.payload };
        dispatch(linkingVendors(finalPayload));
        setselectedVendors([]);
        array.length = 0;
        props.settoastStat(true);
        //toast.success(`${arraySel.length}${arraySel.length <= 1 ? ' vendor' : ' vendors'} have been linked`, {
        //    position: 'bottom-left',
        //    autoClose: 2000,
        //    hideProgressBar: false,
        //});
        setdisable(true);
    };

    const arryFilter = () => {
        /* eslint no-var: off */
        const childArray = props.assignedVendors;
        //if (parentArray.length !== childArray.length) {
        for (let i = 0; i < parentArray?.length; i++) {
            for (let j = 0; j < childArray?.length; j++) {
                if (parentArray[i]?.vn_PK === childArray[j]?.vn_PK) {
                    parentArray.splice(i, 1);
                }
            }
        }
        //if (array.length === parentArray.length) {
        //    return [];
        //} else {
        for (let i = 0; i <= parentArray?.length; i++) {
            for (let j = 0; j < selectedVendors?.length; j++) {
                if (parentArray[i]?.vn_PK === selectedVendors[j]?.vn_PK) {
                    parentArray.splice(i, 1);
                }
            }
        }

        return parentArray;
        //}
        //} else {
        //    return [];
        //}
    };

    const cancelButton = () => {
        if (!disable) {
            setDisplayCancel(true);
        } else {
            setSearch('');
            array.length = 0;
            setSuggetions(false);
            props.displayFunction(false);
            setdisable(true);
        }
    };
    const cancelButtonProp = () => {
        setSearch('');
        array.length = 0;
        setSuggetions(false);
        props.displayFunction(false);
        setdisable(true);
    };

    useEffect(() => {
        if (search?.length > 2) {
            dispatch(activeVendors({ search, assignedVendors: props.assignedVendors }));
        } else if (search?.length === 0) {
            dispatch(actionVendorsData([]));
        }
    }, [search]);

    return (
        <>
            <Modal
                display={props.display}
                displayFunction={props.displayFunction}
                title={'Link Vendors'}
                okButtonName={'Link Vendors'}
                okButtonFunc={linkVendor}
                disable={disable}
                cancelButtonFunc={cancelButton}
                childComp={
                    <div className="modal-content with-input" onClick={() => setSuggetions(false)}>
                        <div className="modal-main-content">
                            <div className="search-entry-holder modal-search">
                                <input
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        props?.vendorList && setSuggetions(!suggetions);
                                        props?.vendorList && arryFilter();
                                    }}
                                    placeholder="Enter vendor name or code to start searching (at least 3 characters)"
                                    type="text"
                                    className="search-input input-text with-border"
                                    onChange={(e) => setSearch(e.target.value)}
                                    value={search}
                                ></input>
                                {search.length !== 0 && (
                                    <button className="app-btn text-close-btn" onClick={() => setSearch('')}>
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                )}
                                {/* Search suggestions */}
                                {suggetions && search.length > 2 && (
                                    <div className="dropdown-wrap  menu-down dropdown-open text-dropdown vendor-suggestion-menu">
                                        <div
                                            style={{
                                                maxHeight: '260px',
                                                flexDirection: 'column',
                                                display: 'block',
                                            }}
                                            className="dropdown-menu roledetails details-col details-col details-box-content customscrollbar"
                                        >
                                            {props?.vendorList &&
                                                arryFilter()
                                                    ?.filter(
                                                        (val: any) =>
                                                            val.vn_vendorname
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase()) ||
                                                            val.vn_vendorcode
                                                                .toLowerCase()
                                                                .includes(search.toLowerCase())
                                                    )
                                                    .map((res: any) => {
                                                        found = true;
                                                        return (
                                                            <a
                                                                className="menu-item app-btn"
                                                                onClick={() => selectedVendor(res)}
                                                                style={{ width: '100%' }}
                                                            >
                                                                <span className="highlight-content">
                                                                    {res?.vn_vendorcode}
                                                                </span>
                                                                <span className="main-content" style={{ width: '65%' }}>
                                                                    {res?.vn_vendorname}
                                                                </span>
                                                            </a>
                                                        );
                                                    })}

                                            {!found && props.vendorLoading ? (
                                                <div className="no-data-content">
                                                    <svg className="svg-icon grid-no-content-icon">
                                                        <use xlinkHref="#loaderIcon">
                                                            <title>Loading</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                            ) : !found ? (
                                                <div className="no-data-content">
                                                    <svg className="svg-icon grid-no-content-icon ">
                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                    </svg>
                                                    <p className="no-content-message">No Matching Records Found</p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="content-heading-holder">
                                <span className="content-heading">Selected Vendors</span>
                                <div className="tiny-status success-status">{array.length} </div>
                            </div>
                            <div
                                id="offsetlength"
                                className="selected-menu-container"
                                style={{ '--max-col-width': '150px' } as React.CSSProperties}
                            >
                                {array?.map((res: any, index: number) => {
                                    return (
                                        <div className="content-card">
                                            <span className="highlight-content">{res?.vn_vendorcode}</span>
                                            <span className="main-content" style={{ whiteSpace: 'normal' }}>
                                                {res?.vn_vendorname}
                                            </span>
                                            <button
                                                className="app-btn delete-button"
                                                title="Remove"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    array.splice(index, 1);
                                                    setrender(!render);
                                                }}
                                            >
                                                <span>
                                                    <svg className="svg-icon delete-icon">
                                                        <use xlinkHref="#deleteIcon">
                                                            <title>Remove</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                }
            ></Modal>
            {displayCancel && (
                <CancelModal
                    displayCancel={displayCancel}
                    setDisplayCancel={setDisplayCancel}
                    onOkFunc={cancelButtonProp}
                />
            )}
        </>
    );
};

export default Index;
