/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';

interface PoPackingState {
    isStatusLoading: boolean;
    isSsccLoading: boolean;
    packingStatus: any[];
    ssccData: any[];
    isSaving: boolean;
    savingResult: any;
    isDeleting: boolean;
    deleteResult: any;
    finaliseResult: any;
    ssrsReport: any;
}

const initialState: PoPackingState = {
    isStatusLoading: false,
    isSsccLoading: false,
    packingStatus: [],
    ssccData: [],
    isSaving: false,
    isDeleting: false,
    savingResult: {},
    deleteResult: {},
    finaliseResult: {},
    ssrsReport: 'NA',
};

export const poPackingSlice = createSlice({
    name: 'vendorPacking',
    initialState,
    reducers: {
        fetchPackingStatus: (state, action) => {
            state.isStatusLoading = true;
            state.packingStatus = action.payload;
            state.isStatusLoading = false;
        },
        fetchSsrsDaReport: (state, action) => {
            state.isStatusLoading = true;
            state.ssrsReport = action.payload;
            state.isStatusLoading = false;
        },
        fetchSsccData: (state, action) => {
            state.isSsccLoading = true;
            state.ssccData = action.payload;
            state.isSsccLoading = false;
        },
        postContainerPacking: (state, action) => {
            state.isSaving = true;
            state.savingResult = action.payload;
            state.isSaving = false;
        },
        deleteContainerPacking: (state, action) => {
            state.isDeleting = true;
            state.deleteResult = action.payload;
            state.isDeleting = false;
        },
        finalisePacking: (state, action) => {
            state.isDeleting = true;
            state.finaliseResult = action.payload;
            state.isDeleting = false;
        },
        resetSavingResult: (state, _action) => {
            state.savingResult = {};
        },
        resetDeleteResult: (state, _action) => {
            state.deleteResult = {};
        },
        resetSsrsDa: (state, _action) => {
            state.ssrsReport = 'NA';
        },
        resetFinaliseResult: (state, _action) => {
            state.finaliseResult = {};
        },
    },
});

export const {
    fetchPackingStatus,
    fetchSsccData,
    postContainerPacking,
    deleteContainerPacking,
    resetSavingResult,
    resetDeleteResult,
    finalisePacking,
    fetchSsrsDaReport,
    resetSsrsDa,
    resetFinaliseResult,
} = poPackingSlice.actions;

// export const selectProductPoLoading = (state: RootState) => state.productPoData.isLoading;
// export const selectProductPoIsError = (state: RootState) => state.vendorPortalData.packingStatus;

export default poPackingSlice.reducer;
