import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'addedSchedulesAir',
    initialState: {
        isLoading: false,
        existingSchedules: [],
        selectedSchedules: [],
        carrierList: [],
        vesselList: [{ imo: '', name: '' }],
        sailingUpdate: { status: null, data: {} },
        contractList: [] as any,
        allContractList: [] as any,
        currentClientSelectedSchedule: {} as any,
    },
    reducers: {
        addSchedule: (_state, _action) => {
            // console.log(action);
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        listExistingCall: (_state, _action) => {
            // console.log(action);
        },
        saveExistingSchedules: (state, action) => {
            state.existingSchedules = action.payload?.airFreightScheduleSelected;
        },
        buyQuote: (_state, _action) => {
            // console.log(action);
        },
        getSelectedSchedules: (_state, _action) => {
            // console.log(action);
        },
        getSelectedSchedulesMBL: (_state, _action) => {
            //console.log(first)
        },
        getCarrierList: (state, action) => {
            state.carrierList = action.payload;
        },
        getVesselList: (state, action) => {
            state.vesselList = action.payload;
        },
        getContractList: (state, action) => {
            state.contractList = action.payload;
        },
        getAllContractList: (state, action) => {
            state.allContractList = action.payload;
        },
        saveSelectedSchedules: (state, action) => {
            state.selectedSchedules = action.payload;
        },
        sailingUpdated: (state, action) => {
            state.sailingUpdate = action.payload;
        },
        clearsailingUpdated: (state, _action) => {
            state.sailingUpdate = { status: null, data: {} };
        },
        updateCurrentClientSelectedSchedule: (state, action) => {
            state.currentClientSelectedSchedule = action.payload;
        },
    },
});

export const {
    addSchedule,
    isLoadingFn,
    saveExistingSchedules,
    listExistingCall,
    buyQuote,
    saveSelectedSchedules,
    getSelectedSchedules,
    getSelectedSchedulesMBL,
    getCarrierList,
    getVesselList,
    sailingUpdated,
    clearsailingUpdated,
    getContractList,
    updateCurrentClientSelectedSchedule,
    getAllContractList,
} = dataSlice.actions;
export default dataSlice.reducer;
