import React, { useState } from 'react';
import { generateNewApiKey } from 'src/services/apis/notificationApi';
import { Position, error, success } from 'src/utils/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/pro-duotone-svg-icons';

interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<ModalProps> = ({ setModalView }) => {
    const [apiKey, setApiKey] = useState<any>(null);
    const [loading, setLoading] = useState(false);

    const generateApi = () => {
        setLoading(true);
        generateNewApiKey()
            .then((res: any) => {
                setApiKey(res);
                success('API key created successfully', Position.TOP_RIGHT);
            })
            .catch((err: any) => {
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        success('Copied!', Position.TOP_RIGHT);
    };
    return (
        <div className="app-modal api-modal">
            <div className="modal-content-holder small-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Generate API Key</div>
                    <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    {apiKey?.data?.length > 0 ? (
                        <>
                            <div className="heading-type-1 file-size">
                                <p>{'* ' + apiKey?.message}</p>
                            </div>
                            <div>
                                <span className="content-text title">API Key : </span>
                                <span className="content-text">{apiKey?.data?.[0]?.US_APIKey}</span>
                                <button
                                    className="app-btn copy-btn"
                                    title="Copy API Key"
                                    onClick={() => {
                                        copyText(apiKey?.data?.[0]?.US_APIKey);
                                    }}
                                >
                                    <div className="icon-holder-dashboard">
                                        <FontAwesomeIcon icon={faCopy} />
                                    </div>
                                </button>
                            </div>
                        </>
                    ) : (
                        'Generating a new API key will invalidate any previous keys generated. Do you wish to continue?'
                    )}
                </div>
                {!apiKey?.data?.length ? (
                    <div className="modal-footer prompts-footer-modal">
                        <button
                            className="app-btn app-btn-primary modal-btn footer-btn "
                            type="button"
                            title={loading ? 'Generating..' : 'Yes'}
                            disabled={loading}
                            onClick={() => {
                                generateApi();
                            }}
                        >
                            {loading ? 'Generating...' : 'Yes'}
                        </button>
                        <button
                            className="app-btn app-btn-secondary modal-btn footer-btn "
                            type="button"
                            title="No"
                            onClick={() => {
                                setModalView(false);
                            }}
                        >
                            No
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    );
};
export default Index;
