import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import './vendor-client-detail.scss';
import '../vendor-tab.scss';
import ClientModal from './ClientModal';
import UnlinkModal from './unlinkModal';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import HeaderTabs from '../HeaderTabs';
import Table from './table';
import TableFooter2 from '../../../../components/common/Table/tableFooter2';
import TableHeaderVendor from './tableHeader';
import { RootState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import { Link, NavLink, useParams } from 'react-router-dom';
import {
    clearClients,
    // fetchAllData,
    fetchData,
    linkingToast,
    unlinkingtoast,
    searchVendorClientsData,
} from '../../../../store/reducers/linkClientVendorReducer';
import { useDispatch } from 'react-redux';
// import { Link } from 'react-router-dom';
// import BackButton from '../../../../components/common/BackButton';
import { error as errorToast, Position, success as successToast } from '../../../../utils/toast';
import ShowForPermissions from '../../../../ShowForPermissions';
import { fetchHeaderDataVendors } from 'src/store/reducers/profiles/vendors';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { unlinkData } from '../../../../store/reducers/linkClientVendorReducer';

const Index: React.FC = () => {
    const { id } = useParams();
    const {
        // success,
        linkedClients,
        unlinkDat,
        clients,
        allClients,
        activeClients,
        totalActiveClients,
        totalPages,
        successFetch /*isLoading*/,
        loading,
        linkToast,
        unlinkToast,
    } = useSelector((state: RootState) => state.linkClientVendor);
    const { vendorName } = useSelector((state: RootState) => state.vendorDetails);
    const { vendorsHeader } = useSelector((state: RootState) => state.profilesVendors) as any;

    const [modalView, setmodalView] = useState(false);
    const [modalViewUnlink, setmodalViewUnlink] = useState(false);
    const [currentPage, setcurrentPage] = useState(0);
    const [perPageCount, setperPageCount] = useState(10);
    const [columnStatus, setcolumnStatus] = useState('code');
    const [currentOrder, setcurrentOrder] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [_toastStat, settoastStat] = useState(false);
    const [_failedToast, setfailedToast] = useState(false);
    const [search, setSearch] = useState('');
    const firstUpdate = useRef(true);

    const dispatch = useDispatch();

    const headerData = [
        {
            title: 'Client Code',
            sorting: true,
            key: 'orgCode',
            sortKey: 'code',
        },
        {
            title: 'Client Name',
            sorting: true,
            key: 'orgName',
            sortKey: 'name',
        },
        {
            title: 'Country',
            sorting: true,
            key: 'clientCountry',
            sortKey: 'country',
        },
    ];

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnOrder: currentOrder,
        columnStatus: columnStatus,
        id: id,
        keyword: search,
    };

    useEffect(() => {
        dispatch(clearClients([]));
    }, []);

    useEffect(() => {
        // dispatch(fetchData(payload));
        //dispatch(fetchAllData(payload));
    }, [columnStatus, currentOrder, currentPage, perPageCount]);

    useEffect(() => {
        if (linkToast) {
            successToast(
                ` ${linkedClients} new ${linkedClients <= 1 ? 'client' : 'clients'} have been linked`,
                Position.TOP_RIGHT
            );
            dispatch(linkingToast(false));
            $('.k-i-reload').click();
            dispatch(fetchHeaderDataVendors(id));
        }
        if (unlinkToast.success) {
            successToast('Client unlinked successfully', Position.TOP_RIGHT);
            dispatch(unlinkingtoast(false));
            $('.k-i-reload').click();
            dispatch(fetchHeaderDataVendors(id));
        } else if (unlinkToast.failed) {
            errorToast('Vendor should have atleast one client', Position.TOP_RIGHT);
            dispatch(unlinkingtoast(false));
        }
    }, [linkToast, unlinkToast]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
        setTimeout(function () {
            // dispatch(linkingToast(false));
            // dispatch(unlinkingtoast(false));
            settoastStat(false);
            setfailedToast(false);
        }, 3000);
    }, [totalPages, clients]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (search?.trim()?.length > 2) {
            // dispatch(searchVendorClientsData({ ...payload, keyword: search }));
        } else if (search?.trim()?.length === 0) {
            // dispatch(searchVendorClientsData(payload));
            //dispatch(fetchAllData(payload));
        }
    }, [search]);

    //@ts-ignore
    window.unlinkClient = (clientId: string, vendorId: string, orgName: string) => {
        setmodalViewUnlink((prev: boolean) => !prev);
        dispatch(unlinkData({ clientId, orgName }));
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder">
                <Breadcrumbs second={'vendor'} third={'Vendor'} />
                <div className="pageheading-holder">
                    {/* <Link to="/vendor">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link>*/}
                    <h2 className="page-heading">
                        {id ? (vendorsHeader?.vendorName ? vendorsHeader?.vendorName : vendorName) : 'Add New Vendor'}
                    </h2>
                </div>
                <HeaderTabs disableThird={!totalActiveClients || totalActiveClients === 0} />
            </div>
            <div className="grid-section vendor-client-grid loader-container grid-p-0-15">
                {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-holder">
                    <TableHeaderVendor
                        search={search}
                        setSearch={setSearch}
                        setmodalView={setmodalView}
                        data={clients}
                        totalActive={totalActiveClients}
                    />
                    <LayoutWithColumns style={'tab'} content={'vendor-client-details'} />
                    {/* <div className="grid-container"> */}
                    {modalView && (
                        <ClientModal
                            display={modalView}
                            displayFunction={setmodalView}
                            clientId={id}
                            clientList={activeClients}
                            currentClients={clients}
                            assignedClients={allClients}
                            payload={payload}
                            settoastStat={settoastStat}
                            setcurrentPage={setcurrentPage}
                        />
                    )}
                    <UnlinkModal
                        displayUnlink={modalViewUnlink}
                        displayUnlinkFun={setmodalViewUnlink}
                        unlinkData={unlinkDat}
                        clientId={id}
                        payload={payload}
                        data={clients}
                        setfailedToast={setfailedToast}
                        clientStatus={null}
                        setcurrentPage={setcurrentPage}
                    />
                    {/* {!successFetch && (
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                {search?.trim()?.length > 1 ? (
                                    <p className="no-content-message">No Client Details Available</p>
                                ) : (
                                    <>
                                        <p className="no-content-message">No clients linked to this profile</p>
                                        <ShowForPermissions permission="create" type="vendor">
                                            <a
                                                className="add-content-link"
                                                href="javascript:void(0)"
                                                onClick={() => setmodalView(true)}
                                            >
                                                Link Client
                                            </a>
                                        </ShowForPermissions>
                                    </>
                                )}
                            </div>
                        )}
                        <div className="grid-scroll-content">
                            <Table
                                header={headerData}
                                data={clients}
                                action={setmodalViewUnlink}
                                displayUnlink={modalViewUnlink}
                                setcurrentOrder={setcurrentOrder}
                                setcolumnStatus={setcolumnStatus}
                                setperPageCount={setperPageCount}
                                setcurrentPage={setcurrentPage}
                                currentOrder={currentOrder}
                                columnStatus={columnStatus}
                            />
                        </div> */}
                    {/* </div> */}
                    {/* <TableFooter2
                        setcurrentPage={setcurrentPage}
                        setperPageCount={setperPageCount}
                        perPageCount={perPageCount}
                        currentPage={currentPage}
                        displayCountfn={setPageCountDisplay}
                        pageCountDisplay={PageCount}
                        totalItems={totalActiveClients}
                        items={clients}
                        totalPages={totalPages - 1}
                    /> */}
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder">
                    <NavLink
                        title="Previous"
                        to={`/profile/vendor/${id}/basic_detail`}
                        className="app-btn app-btn-secondary footer-btn"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </NavLink>
                    <Link to={!vendorsHeader?.cdTabComplete ? '' : `/profile/vendor/${id}/integration`}>
                        <button
                            className="app-btn app-btn-primary footer-btn"
                            title="Next"
                            style={{ marginLeft: '16px' }}
                            disabled={!vendorsHeader?.cdTabComplete}
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </button>
                    </Link>
                </div>
            </div>
            {/* <ClientModal></ClientModal> */}
            {/* <UnlinkModal></UnlinkModal> */}
        </div>
    );
};
export default Index;
