import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers';
import { useAppDispatch } from '../../../hooks';
import {
    BpQuotationList,
    Currency,
    updateQuotation,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import '../../BookingProposal/BookingAdditionalInfo/add-user-additional-modal.scss';
import { useSelector } from 'react-redux';
import { convertDataForSelect } from 'src/utils';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    quotation: BpQuotationList[];
    currency: Currency[];
    bpId: string;
    userProfile: { profileType: string; userName: string };
    type: string;
    additionalDetailsData: { additionalDetails: any; updateAdditionalDetails: any };
    setShowNoData: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    quotation,
    currency,
    bpId,
    userProfile,
    type,
    additionalDetailsData: { additionalDetails, updateAdditionalDetails },
    setShowNoData,
    setCancelModalVisibility,
}) => {
    const { additionalDetails: additionalDetailsCurrent } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    const dispatch = useAppDispatch();
    const [showQuotation, setShowQuotation] = useState(false);
    const [_showCurrency, setShowCurrency] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [isSaveClicked, setIsSaveClicked] = useState(false);

    const submitAdditionalDetails = (type: string) => {
        dispatch(
            updateQuotation({
                type,
                bpId,
                sellQuotationName: additionalDetails.sellQuotationName,
                sellQuotationVersion: additionalDetails.sellQuotationVersion,
                sellQuotationId: additionalDetails.sellQuotationId,
                sellTotOriginCost: additionalDetails.sellTotOriginCost,
                sellCurrency: additionalDetails.sellCurrency,
                userName: userProfile.userName,
            })
        );
    };

    const checkButtonStatus = () => {
        if (
            additionalDetails.sellQuotationName === '' ||
            !additionalDetails.sellCurrency ||
            additionalDetails.sellTotOriginCost === '' ||
            Number.isNaN(additionalDetails.sellTotOriginCost)
        ) {
            setDisableButton(true);
            return;
        }

        if (additionalDetailsCurrent?.sellQuotationName) {
            if (
                additionalDetails.sellQuotationName === additionalDetailsCurrent?.sellQuotationName &&
                additionalDetails.sellTotOriginCost == additionalDetailsCurrent?.sellTotOriginCost &&
                additionalDetails.sellCurrency === additionalDetailsCurrent?.sellCurrency?.currencycode
            ) {
                setDisableButton(true);
            } else {
                setDisableButton(false);
            }
        } else {
            if (
                additionalDetails.sellQuotationName &&
                additionalDetails.sellTotOriginCost &&
                additionalDetails.sellCurrency
            ) {
                setDisableButton(false);
            }
        }
    };

    useEffect(() => {
        checkButtonStatus();
    }, [additionalDetails]);

    const initialState = {
        sellQuotationName: '',
        sellQuotationVersion: '',
        sellQuotationId: '',
        sellTotOriginCost: '',
        sellCurrency: '',
    };

    const closeModal = () => {
        setModalView(false);
        setShowNoData(false);
        updateAdditionalDetails(initialState);
    };

    const showError = (errMsg: string, fieldValue: any) => {
        return <>{isSaveClicked && !fieldValue ? <div className="error-text">{errMsg}</div> : null}</>;
    };

    return (
        <>
            {modalView ? (
                <div
                    onClick={() => {
                        setShowQuotation(false);
                        setShowCurrency(false);
                    }}
                    className="app-modal additional-add-user-modal"
                >
                    <div className="modal-content-holder medium-popup">
                        <div className="modal-header">
                            <div className="title">Additional Details</div>
                            <button
                                onClick={() => {
                                    disableButton ? closeModal() : setCancelModalVisibility(true);
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content agent-commercial-detail-modal-main-content">
                                <form id="my-form" action="" className="modal-form layout-holder one-column">
                                    <div id="name_div" className=" modal-input-holder ">
                                        <div className="modal-label-pill-container">
                                            <label htmlFor="name" className="modal-input-label">
                                                Quotation (Sell)<span className="mandatory-field-text">*</span>
                                            </label>
                                            <span className="btn-pills container-pill disable-pill">
                                                {quotation?.length === 1 ? (
                                                    <span className="pill-text">
                                                        {quotation?.length} Quotation found
                                                    </span>
                                                ) : (
                                                    <span className="pill-text">
                                                        {quotation?.length} Quotations found
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        <div className="modal-input-layout-item">
                                            <div
                                                className={`dropdown-wrap download-dropdown modal-dropdown ${
                                                    showQuotation ? 'dropdown-open' : ''
                                                } search-toast-content-holder modal-dropdown-btn quotation-dropdown`}
                                            >
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowQuotation((prev) => !prev);
                                                        setShowCurrency(false);
                                                    }}
                                                    className="menu-btn app-btn select-modal"
                                                    type="button"
                                                >
                                                    {additionalDetails.sellQuotationName ? (
                                                        <span className="button-text">
                                                            {`${additionalDetails.sellQuotationName}, ${additionalDetails.sellQuotationVersion}`}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">Select Quotation</span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use href="#downArrow">
                                                                <title>Expand row</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {quotation.map((res: BpQuotationList, index: number) => (
                                                        <a
                                                            onClick={() => {
                                                                updateAdditionalDetails({
                                                                    sellQuotationName: res.name,
                                                                    sellQuotationVersion: res.version,
                                                                    sellQuotationId: res.id,
                                                                });
                                                                checkButtonStatus();
                                                            }}
                                                            key={index}
                                                            className={`menu-item app-btn ${
                                                                additionalDetails.sellQuotationName === res.name &&
                                                                additionalDetails.sellQuotationVersion === res.version
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {res.name}, {res.version}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {showError('Quotation (Sell) is required', additionalDetails.sellQuotationName)}
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Total Origin Cost (Sell)<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <div className="total-term-wrapper">
                                                <div
                                                    style={{ width: '60%' }}
                                                    className="modal-input-layout-item total-terms-data-holder textfield-holder"
                                                >
                                                    <span className="unit-style"></span>
                                                    <input
                                                        placeholder="Enter Total Origin Cost"
                                                        type="number"
                                                        step="any"
                                                        min="0"
                                                        className="modal-input input-text"
                                                        value={additionalDetails.sellTotOriginCost}
                                                        onChange={(e) => {
                                                            updateAdditionalDetails({
                                                                sellTotOriginCost: +parseFloat(e.target.value),
                                                            });
                                                            checkButtonStatus();
                                                        }}
                                                    />
                                                    {showError(
                                                        'Total Origin Cost (Sell) is required',
                                                        additionalDetails.sellTotOriginCost
                                                    )}
                                                </div>
                                                <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                    <SearchableSelectBox
                                                        data={convertDataForSelect(currency, 'code', 'code')}
                                                        placeholder="Enter currency to select"
                                                        setStateValue={(currencyCode: string) => {
                                                            updateAdditionalDetails({
                                                                sellCurrency: currencyCode,
                                                            });
                                                        }}
                                                        onInputChangeFn={checkButtonStatus}
                                                        stateValue={additionalDetails.sellCurrency}
                                                        fieldName="currencySell"
                                                        menuPortalTarget={document.getElementById('outside_div')}
                                                    />
                                                    {showError('Currency is required', additionalDetails.sellCurrency)}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => {
                                    disableButton ? closeModal() : setCancelModalVisibility(true);
                                }}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Save"
                                // disabled={disableButton}
                                onClick={() => {
                                    setIsSaveClicked(true);
                                    if (disableButton) {
                                        return;
                                    }
                                    setModalView(false);
                                    submitAdditionalDetails(type);
                                }}
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
