import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { availablePoHeaders, clearFilterText } from '../../../utils/constants';
import {
    addOrSavePoData,
    fetchAvailablePoData,
    resetFlags,
    clearErrorMessage,
    setSelectedItems,
    setDisabledFilterData,
    setFilterDispatch,
    searchAvailablePoData,
} from '../../../store/reducers/bookingProposalLand/availablePoReducer';
import { fetchPermissions } from '../../../store/reducers/permissionReducer';
import { error, Position, success } from '../../../utils/toast';
import './BookingAvailableGrid.scss';
import SelectableGrid from '../../../components/common/selectableGrid/index';
import PoReview from '../BookingAddedGrid/PurchaseOrderDetails';
import BookingAddedGrid from '../BookingAddedGrid';
import { useNavigate, useParams } from 'react-router-dom';
// import CancelModal from '../../../components/common/CancelModal';
import {
    detachMessage,
    fetchAddedPoData,
    removeMessage,
    removePo,
    searchData,
} from '../../../store/reducers/bookingProposalLand/addedPoReducer';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposalLand/bookingProposalHeaderReducer';
import { useUserProfile } from '../../../hooks/useUserProfile';
import UnavailableModel from '../SelectedSailingSchelude/UnavailableModel';
import ShowForPermissions from '../../../ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { getOrganisationData } from '../../../utils';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import PromptsModal from './PromptsModal';
import { Icon } from 'semantic-ui-react';
// import PoTriangleList from '../ContainerAllocations/ContainerDetails/poTriangleList';
// import { updateViewStatus } from '../../../store/reducers/bookingProposalLand/additionalDetailsReducer';
// import ReapprovalBpClientModal from '../BookingAdditionalInfo/ReapprovalBpClientModal';
/* eslint-disable no-undefined */

interface HeaderProps {
    setisAdded?: any;
    showBookingCancelModal: boolean;
    setShowBookingCancelModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    showBookingCancelForDetachModal: boolean;
    setShowBookingCancelForDetachModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
const Index: React.FC<HeaderProps> = ({
    setisAdded,
    showBookingCancelModal,
    setShowBookingCancelModal,
    showBookingCancelForDetachModal,
    setShowBookingCancelForDetachModal,
}) => {
    const { profileType } = useUserProfile();
    // const [reapproveBpModalView, setReapproveBpModalView] = useState(false);
    const dispatch = useDispatch();
    const {
        availablePoList,
        filterValues,
        columnStatus,
        isLoading,
        isFetching,
        bpId,
        success: addSuccess,
        error: addError,
        errorMessage,
        selectedItems,
        disabledFilterData,
        filter,
    } = useSelector((state: RootState) => state.availableLandPos);

    const {
        addPoList,
        removeStat,
        removeMessageval,
        gateInDate,
        isLoading: isLoadingAdded,
        detachStat,
        detachMessageval,
    } = useSelector((state: RootState) => state.addedPos);
    const {
        bpNumber,
        clientName,
        originPort,
        destinationPort,
        // gateInDateExpired,
        isLoading: mainLoading,
        vendorName,
        isHeaderOnlyPo,
        tabValues,
    } = useSelector((state: RootState) => state.bpHeaderData);
    const poTabStatus: any = tabValues;
    const [showConditionStatus, setShowConditionStatus] = useState(true);
    const { id } = useParams();
    const [selectedHeader, setSelectedHeader] = useState(availablePoHeaders.poNumber);
    const [searchTerm, setSearchTerm] = useState('');
    // eslint-disable-next-line
    /* eslint no-var: off */
    const [currentOrder, setcurrentOrder] = useState(false);
    // const [filter, setFilter] = useState<any>({});
    const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data1?.user?.us_UserName;
    const firstUpdate = useRef(true);
    const firstSortUpdate = useRef(true);
    const [selectedItemsAdded, setSelectedItemsAdded] = useState<any[]>([]);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    // const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
    const [addedgrid, setaddedgrid] = useState(false);
    const [currentTab, setCurrentTab] = useState('');
    const [modalView, setModalView] = useState(false);
    const [currentPrompt, setCurrentPrompt] = useState('');
    const [currentCGId, setCurrentCGId] = useState('');
    const [currentPoId, setCurrentPoId] = useState('');
    const [currentPoPk, setCurrentPoPk] = useState('');
    // const [addedgridTriangle, setaddedgridTriangle] = useState(true);
    const [searchAddedTerm, setsearchAddedTerm] = useState('');
    const firstFilterUpdate = useRef(true);
    const [gateModal, setgateModal] = useState(false);
    const navigate = useNavigate();
    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const [bpActionComplete, setBpActionComplete] = useState(false);
    // const [tabDelay, setTabDelay] = useState(false);

    const organisationData = getOrganisationData();
    const payload = {
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter },
        id: id,
        profileType,
    };

    useEffect(() => {
        if (gateInDate) {
            setgateModal(true);
        } else {
            setgateModal(false);
        }
    }, [gateInDate]);

    useEffect(() => {
        if (!isFetching) {
            setBpActionComplete(!bpActionComplete);
        }
    }, [isFetching]);

    const setFilter = (payload: any) => {
        // dispatch(setFilterDispatch(payload));
    };
    const resetFilters = () => {
        setSelectedItemsDispatch([]);
        setSearchTerm('');
    };

    const setSelectedItemsDispatch = (selectedList: any[]) => {
        if (selectedList) {
            // dispatch(setSelectedItems(selectedList));
        }
    };

    const setDisabledFilterDataDispatch = (filterValues: any) => {
        if (filterValues) {
            // dispatch(setDisabledFilterData(filterValues));
        }
    };
    //useEffect(() => {
    //    localStorage.setItem('bpclientCode', (addPoList[0] as any)?.purchaseOrderId.clientCode);
    //    localStorage.setItem('bpid', id as any);
    //    if (addPoList.length !== 0) {
    //        localStorage.setItem('bpdisabled', 'false');
    //    } else {
    //        localStorage.setItem('bpdisabled', 'true');
    //    }
    //}, [addPoList, id]);

    const showCondition =
        (bpStatus === 'Rejected_by_Forwarder' ||
            bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
            bpStatus === 'Draft_Booking_Proposal' ||
            !bpStatus) &&
        (profileType === 'agent' || profileType === 'forwarder');
    // (profileType === 'forwarder' && bpStatus === 'Rejected_by_Client_Forwarder_to_Action');

    useEffect(() => {
        if (bpStatus) {
            if (showCondition === false) {
                setaddedgrid(true);
                setCurrentTab('attachedPos');
                setShowConditionStatus(false);
            } else {
                setCurrentTab('available');
            }
        } else {
            setShowConditionStatus(true);
        }
    }, [bpStatus]);

    useEffect(() => {
        if (!id) {
            setCurrentTab('available');
            // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [id, addedgrid]);

    useEffect(() => {
        if (bpNumber && id) {
            setDisabledFilterDataDispatch({
                clientName: clientName ? [clientName] : null,
                originPort: originPort ? [originPort] : null,
                destinationPort: destinationPort ? [destinationPort] : null,
                vendorName: vendorName ? [vendorName] : null,
                isHeaderOnlyPo: isHeaderOnlyPo || isHeaderOnlyPo === false ? isHeaderOnlyPo : null,
            });
            const filterObj = {
                clientName: clientName ? [clientName] : null,
                originPort: originPort ? [originPort] : null,
                destinationPort: destinationPort ? [destinationPort] : null,
                vendorName: vendorName ? [vendorName] : null,
            };
            setFilter({ ...filter, ...filterObj });
        }
    }, [bpNumber]);

    useEffect(() => {
        if (addSuccess) {
            const po = selectedItems.length > 1 ? 'POs have' : 'PO has';
            let successMessage = `${po} been added to Booking Proposal.`;
            if (!id) {
                successMessage = successMessage + ' List of Available POs have been filtered based on header details';
            }
            success(successMessage, Position.TOP_RIGHT);
            dispatch(resetFlags({}));
            setSelectedItemsDispatch([]);
            // dispatch(fetchAddedPoData(id));
            // dispatch(fetchBpHeaderData({ bpId: id || bpId, profileType }));
            setFilter({
                clientName: [disabledFilterData.clientName],
                originPort: [disabledFilterData.originPort],
                destinationPort: [
                    disabledFilterData?.destinationPort && Array.isArray(disabledFilterData.destinationPort)
                        ? disabledFilterData.destinationPort[0]
                        : disabledFilterData.destinationPort,
                ],
            });
            if (!id) {
                navigate(`/land_booking_list/${bpId}/POSelection`);
            }
            setBpActionComplete(!bpActionComplete);
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
            setModalView(false);
        }
    }, [addSuccess]);

    useEffect(() => {
        if (errorMessage) {
            error(errorMessage, Position.TOP_RIGHT);
            // dispatch(resetFlags({}));
            dispatch(clearErrorMessage({}));
        }
    }, [errorMessage]);

    useEffect(() => {
        if (addPoList?.length > 0) {
            setisAdded(true);
            localStorage.setItem('isSelected', 'false');
        } else {
            setisAdded(false);
            localStorage.setItem('isSelected', 'true');
        }
    }, [addPoList]);
    useEffect(() => {
        clearSearch();
    }, [addedgrid]);

    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (searchTerm?.trim()?.length > 2) {
            // dispatch(searchAvailablePoData({ ...payload, keyword: searchTerm }));
        } else if (searchTerm?.trim()?.length === 0) {
            // dispatch(fetchAvailablePoData(payload));
        }
    }, [searchTerm]);

    useLayoutEffect(() => {
        if (firstFilterUpdate.current) {
            firstFilterUpdate.current = false;
            return;
        }
        // dispatch(fetchAvailablePoData(payload));
    }, [filter, addedgrid]);

    useLayoutEffect(() => {
        if (firstSortUpdate.current) {
            firstSortUpdate.current = false;
            if (!id) {
                // dispatch(fetchAvailablePoData(payload));
                // dispatch(fetchPermissions(username));
            }
            return;
        }
        // dispatch(fetchAvailablePoData(payload));
        // dispatch(fetchPermissions(username));
        // eslint-disable-next-line
    }, [setSelectedHeader, currentOrder, filter, addedgrid]);

    const addToBookingProposal = () => {
        const payload = {} as any;
        const cgIds: any[] = [];

        selectedItems.forEach((x: any) => {
            cgIds.push(x.cgId);
        });
        payload.cgIds = cgIds;

        payload.bpId = id ? id : null;

        dispatch(addOrSavePoData(payload));
    };

    const removeFromBookingPo = () => {
        var payload = {
            bpId: id,
            purchaseOrderIds: selectedItemsAdded,
        };

        selectedItemsAdded.forEach((element1: any) => {
            addPoList.forEach((element: any) => {
                element?.bookingProposalSubPOLineList?.forEach((element3: any) => {
                    if (
                        Array.isArray(element1.subPolineIds) &&
                        Array.isArray(element3.bookingProposalSubPOLineList) &&
                        element1.subPolineIds.length === element3.bookingProposalSubPOLineList.length
                    ) {
                        payload = {
                            bpId: id,
                            purchaseOrderIds: selectedItemsAdded,
                        };
                        element1['isFullPo'] = true;
                    }
                });
            });
        });
        dispatch(removePo(payload));
        setSelectedItemsAdded([]);
    };
    useEffect(() => {
        if (removeStat) {
            success(`${removeMessageval}`, Position.TOP_RIGHT);
            dispatch(removeMessage({ status: false, message: '' }));
            if (id) {
                // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
            }
            dispatch(fetchAvailablePoData(payload));
            if (!addPoList?.length) {
                setDisabledFilterDataDispatch({});
            }
        }
    }, [removeStat, id]);
    useEffect(() => {
        if (detachStat === true) {
            success(`${detachMessageval}`, Position.TOP_RIGHT);
            dispatch(detachMessage({ status: null, message: '' }));
        } else if (detachStat === false) {
            error(`${detachMessageval}`, Position.TOP_RIGHT);
            dispatch(detachMessage({ status: null, message: '' }));
        }
    }, [detachStat]);

    const getCount = () => {
        var total = 0;
        selectedItemsAdded.forEach((_element: any) => {
            //element.subPolineIds.forEach((element2: any) => {
            total++;
            //});
        });
        return total;
    };

    useEffect(() => {
        if (searchAddedTerm.length > 2) {
            dispatch(
                searchData({
                    val: searchAddedTerm,
                    id: id,
                })
            );
        } else if (searchAddedTerm.length === 0) {
            // const timer = setTimeout(() => {
            // dispatch(fetchAddedPoData(id));
            // }, 500);
            // return () => clearTimeout(timer);
        }
    }, [searchAddedTerm, id]);

    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let isAgent = false;
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     if (roleIndex) {
    //         isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
    //     }
    // }

    const setSearch = (value: string) => {
        if (addedgrid) {
            setsearchAddedTerm(value);
        } else {
            setSearchTerm(value);
        }
    };
    const clearSearch = () => {
        if (addedgrid) {
            setsearchAddedTerm('');
        } else {
            setSearchTerm('');
        }
    };

    //@ts-ignore
    window.cgAction = (type: string, poId: string, cgId: string, poPk: string) => {
        setCurrentPoId(poId);
        setCurrentPoPk(poPk);
        setCurrentCGId(cgId);
        if (type === 'DTH') {
            setCurrentPrompt('Remove');
        } else if (type === 'APR') {
            setCurrentPrompt('Approve');
        } else if (type === 'REJ') {
            setCurrentPrompt('Reject');
        } else if (type === 'CAN') {
            setCurrentPrompt('Cancel');
        } else if (type === 'ATH') {
            dispatch(addOrSavePoData({ cgIds: [cgId], bpId: id ?? null, bpType: 'FTL' }));
        } else if (type === 'ATR') {
            setCurrentPrompt('Attach');
        } else if (type === 'HIS') {
            setCurrentPrompt('History');
        }
        if (type !== 'ATH') {
            setModalView(true);
        }
    };

    useEffect(() => {
        $('.k-i-reload').click();
        if (id) {
            // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [bpActionComplete]);

    return (
        <>
            <div className="content-section main-wrapper po-selection-grid grid-p-0">
                <div className="main-wrapper">
                    <div className="tab-with-action grid-ml-20">
                        {/* {showConditionStatus ? ( */}
                        <div className="tab-with-search">
                            {!addedgrid && (
                                <a
                                    className={currentTab === 'available' ? 'tab-nav active' : 'tab-nav'}
                                    onClick={() => {
                                        setCurrentTab('available');
                                        // setBpActionComplete(!bpActionComplete);
                                        // setTabDelay(true);
                                    }}
                                >
                                    Available
                                    <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                        <span className="hub-tooltiptext" style={{ width: '235px' }}>
                                            Volume, Weight, Quantities and Goods Ready Date are defined on the PO
                                            Confirmation group(s)
                                        </span>
                                        <Icon name="question circle outline" />
                                    </span>
                                </a>
                            )}
                            <a
                                className={currentTab === 'attachedPos' ? 'tab-nav active' : 'tab-nav'}
                                onClick={() => {
                                    setCurrentTab('attachedPos');
                                    //setTabDelay(false);
                                    // setBpActionComplete(!bpActionComplete);
                                }}
                            >
                                Attached POs
                                <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                    <span className="hub-tooltiptext" style={{ width: '235px' }}>
                                        Volume, Weight, Quantities and Goods Ready Date are defined on the PO
                                        Confirmation group(s)
                                    </span>
                                    <Icon name="question circle outline" />
                                </span>
                            </a>
                            <a
                                className={currentTab === 'attachedPosDetail' ? 'tab-nav active' : 'tab-nav'}
                                onClick={() => {
                                    setCurrentTab('attachedPosDetail');
                                    //setTabDelay(false);
                                    // setBpActionComplete(!bpActionComplete);
                                }}
                            >
                                Attached POs (Detail)
                                <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                    <span className="hub-tooltiptext" style={{ width: '235px' }}>
                                        Volume, Weight and Quantities are defined by the PO and the SKU Item Master
                                    </span>
                                    <Icon name="question circle outline" />
                                </span>
                            </a>

                            {/* <div className="search-entry-holder">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={addedgrid ? searchAddedTerm : searchTerm}
                                        className="search-input input-text"
                                    ></input>
                                    <div
                                        className={`search-toolip ${
                                            (searchTerm?.length > 0 && searchTerm?.length < 3) ||
                                            (searchAddedTerm?.length > 0 && searchAddedTerm?.length < 3)
                                                ? 'tooltip-show'
                                                : ''
                                        }`}
                                    >
                                        Enter atleast 3 characters to search
                                    </div>
                                    <svg className="svg-icon search-icon">
                                        <use xlinkHref="#searchIcon"></use>
                                    </svg>
                                    <button className="app-btn text-close-btn" onClick={() => clearSearch()}>
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div> */}
                            {/* {!addedgrid && (
                                    <a
                                        href="javascript:void(0)"
                                        className="clear-filter-link"
                                        onClick={() => {
                                            if (!addPoList?.length) {
                                                setDisabledFilterDataDispatch({});
                                            }
                                            resetFilters();
                                            setFilter({});
                                        }}
                                    >
                                        {clearFilterText}
                                    </a>
                                )} */}
                        </div>

                        {/* ) : null} */}

                        <ShowForPermissions type="bp" permission="create">
                            {selectedItems.length && !addedgrid ? (
                                <div className="tab-with-proposal">
                                    <span className="btn-pills info-pill">
                                        <span className="pill-text">{`${selectedItems.length} Selected`}</span>
                                        <button
                                            className="pill-action"
                                            onClick={() => {
                                                setSelectedItemsDispatch([]);
                                                setDisabledFilterDataDispatch({});
                                            }}
                                        >
                                            <svg className="svg-icon close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </span>
                                    <button
                                        className="app-btn app-btn-primary footer-btn"
                                        onClick={() => addToBookingProposal()}
                                    >
                                        Add to Booking Proposal
                                    </button>
                                </div>
                            ) : null}
                        </ShowForPermissions>
                        <ShowForPermissions type="bp" permission="create">
                            {selectedItemsAdded.length > 0 && addedgrid && (
                                <div className="tab-with-proposal">
                                    <span className="btn-pills info-pill">
                                        <span className="pill-text">{`${getCount()} Selected`}</span>
                                        <button
                                            className="pill-action"
                                            onClick={() => {
                                                setSelectedItemsAdded([]);
                                            }}
                                        >
                                            <svg className="svg-icon close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </span>
                                    <button
                                        className="app-btn app-btn-primary footer-btn"
                                        onClick={() => removeFromBookingPo()}
                                        disabled={bpStatus === 'Cancelled'}
                                    >
                                        Remove From Booking Proposal
                                    </button>
                                </div>
                            )}
                        </ShowForPermissions>
                    </div>

                    <div className="main-content-holder" style={{ paddingTop: '8px' }}>
                        <div className="grid-section grid-available-booking">
                            <div
                                style={{ zIndex: 999 }}
                                className={`app-loader ${isLoading || isLoadingHeader ? 'loading' : ''}`}
                            >
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>

                            {currentTab === 'available' && (
                                <LayoutWithColumns
                                    key={'' + currentTab ?? '1'}
                                    content={'availablePos'}
                                    hideLoader
                                    style="double_tab"
                                />
                            )}

                            {currentTab === 'attachedPos' && (
                                <LayoutWithColumns
                                    key={'' + currentTab ?? '2'}
                                    content={'attachedPos'}
                                    hideLoader
                                    style="double_tab"
                                />
                            )}

                            {currentTab === 'attachedPosDetail' && (
                                <LayoutWithColumns
                                    key={'' + currentTab ?? '3'}
                                    content={'attachedPosDetail'}
                                    hideLoader
                                    style="double_tab"
                                />
                            )}
                            {/* {addedgrid && !mainLoading ? (
                                <BookingAddedGrid
                                    bpID={id}
                                    addPoList={id ? addPoList : []}
                                    setaddedgrid={setaddedgrid}
                                    selectedItemsAdded={selectedItemsAdded}
                                    setSelectedItemsAdded={setSelectedItemsAdded}
                                    showConditionStatus={showConditionStatus}
                                    searchAddedTerm={searchAddedTerm}
                                    bpStatus={bpStatus}
                                    setShowBookingCancelModal={setShowBookingCancelForDetachModal}
                                />
                            ) : null} */}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                {id && (
                    <div className="footer-button-holder ">
                        {/* Draft Booking Proposal */}
                        {/* {profileType === 'agent' &&
                            (bpStatus === 'Draft_Booking_Proposal' ||
                                bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                // <>
                                //     {bpStatus === 'Booked' || bpStatus === 'Cancelled' ? null : (
                                <ShowForPermissions type="bp" permission="create">
                                    <button
                                        title="Cancel Booking Proposal"
                                        className="app-btn app-btn-secondary footer-btn"
                                        onClick={() => {
                                            setShowBookingCancelModal(true);
                                        }}
                                        disabled={selectedItemsAdded?.length !== 0 ? true : false}
                                    >
                                        <span className="button-text footer-button-text">Cancel Booking Proposal</span>
                                    </button>
                                </ShowForPermissions>
                                //     )}
                                // </>
                            )} */}
                        {/* {profileType === 'agent' &&
                            (bpStatus === 'Booked' ||
                                bpStatus === 'Ready_for_Booking' ||
                                bpStatus === 'Draft_Booking') && (
                                <ShowForPermissions type="bp" permission="create">
                                    <button
                                        title="Cancel Booking"
                                        className="app-btn app-btn-secondary footer-btn"
                                        onClick={() => {
                                            setShowBookingCancelModal(true);
                                        }}
                                    >
                                        <span className="button-text footer-button-text">Cancel Booking</span>
                                    </button>
                                </ShowForPermissions>
                            )} */}
                        {/* {bpStatus === 'Draft_Booking_Proposal' && isAgent ? (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Save as Draft"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        if (selectedItemsAdded?.length) {
                                            setCancelModalVisibility(true);
                                        } else {
                                            dismissModal();
                                        }
                                    }}
                                    disabled={selectedItemsAdded?.length !== 0 ? true : false}
                                >
                                    <span className="button-text footer-button-text">Save as Draft</span>
                                </button>
                            </ShowForPermissions>
                        ) : null} */}
                        <button
                            title="Next"
                            className={`app-btn app-btn-primary footer-btn`}
                            // className={`app-btn app-btn-primary footer-btn ${
                            //     (addPoList?.length === 0 || selectedItemsAdded?.length !== 0) &&
                            //     searchAddedTerm?.length === 0
                            //         ? 'disabled'
                            //         : ''
                            // }`}
                            disabled={poTabStatus?.po ? false : true}
                            onClick={() => {
                                navigate(`/land_booking_list/${id}/SailingSelectionDetails`);
                            }}
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </button>
                        {/* </button> */}
                    </div>
                )}
            </div>
            {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}
            {/* <CancelModal
                displayFunction={dismissModal}
                cancelModalVisibility={cancelModalVisibility}
                setCancelModalVisibility={setCancelModalVisibility}
            /> */}
            {showBookingCancelModal ? (
                bpStatus !== undefined &&
                (bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            {showBookingCancelForDetachModal ? (
                <BookingCancellationModal
                    setShowBookingCancelModal={setShowBookingCancelForDetachModal}
                ></BookingCancellationModal>
            ) : null}
            {/* <ReapprovalBpClientModal
                modalView={reapproveBpModalView}
                setModalView={setReapproveBpModalView}
                reason={reason != null ? reason : ''}
                bpId={id}
                profileType={profileType}
            /> */}
            {bpStatus &&
                profileType === 'agent' &&
                bpStatus !== 'Ready_for_Booking' &&
                bpStatus !== 'Draft_Booking' &&
                bpStatus !== 'Cancelled' &&
                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                bpStatus !== 'Rejected_by_Forwarder' &&
                bpStatus !== 'Booked' &&
                bpStatus !== 'Awaiting_Client_Approval' &&
                bpStatus !== 'Awaiting_Forwarder_Approval' &&
                gateModal &&
                (addPoList[0] as any)?.purchaseOrderId?.clientCode !== undefined && (
                    <UnavailableModel setdisplayModal={setgateModal} />
                )}
            {modalView ? (
                <PromptsModal
                    setModalView={setModalView}
                    currentPrompt={currentPrompt}
                    poNumber={currentPoId}
                    cgId={currentCGId}
                    poPk={currentPoPk}
                    bpActionComplete={bpActionComplete}
                    setBpActionComplete={setBpActionComplete}
                    addOrSavePoData={addOrSavePoData}
                />
            ) : null}
        </>
    );
};
export default Index;
