/* eslint-disable no-undefined */
import { ReactElement } from 'react';
import Select, { components, MenuPosition, MenuPlacement, InputActionMeta } from 'react-select';
import { reactSelectStyle, reactSelectTheme } from '../../../utils/constants';
import './style.css';
const REACT_SELECT_WRAPPER = process.env.NODE_ENV === 'development' ? 'MenuList' : 'css-';
interface DataProps {
    value: string;
    label: string;
    extraField?: string;
}
interface SelectProps {
    data: DataProps[];
    placeholder: string;
    formik: any;
    fieldName: string;
    extraField?: string;
    extraFieldFn?: any;
    isDisabled?: boolean;
    isClearable?: boolean;
    NoMessageComponent?: any;
    customStyle?: any;
    menuPortalTarget?: any;
    menuPosition?: MenuPosition;
    onInputChangeFn?: any;
    closeMenuOnScroll?: boolean;
    menuPlacement?: MenuPlacement;
    className?: string;
    showDropdownIndicator?: boolean;
    onClear?: any;
    onChangeFn?: any;
}

const Index = ({
    data,
    placeholder,
    formik,
    fieldName,
    extraField,
    isDisabled,
    isClearable,
    NoMessageComponent,
    customStyle,
    extraFieldFn,
    menuPortalTarget,
    menuPosition,
    onInputChangeFn,
    closeMenuOnScroll,
    menuPlacement,
    className = 'react-select',
    showDropdownIndicator,
    onClear,
    onChangeFn,
}: SelectProps): ReactElement => {
    const NoOptionsMessage = (props: any) => {
        return (
            <components.NoOptionsMessage {...props}>
                {NoMessageComponent && extraField === 'agentName' && data?.length === 0 ? (
                    <NoMessageComponent />
                ) : NoMessageComponent && data?.length === 0 ? (
                    <NoMessageComponent />
                ) : (
                    <div className="no-data-content">
                        <svg className="svg-icon grid-no-content-icon">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">
                            {props?.selectProps?.inputValue ? 'No Matching Records Found' : 'No Records Found'}
                        </p>
                    </div>
                )}
            </components.NoOptionsMessage>
        );
    };
    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <svg className="svg-icon arrow-icon">
                    <use href="#downArrow">
                        <title>Expand row</title>
                    </use>
                </svg>
            </components.DropdownIndicator>
        );
    };
    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };
    return (
        <Select
            options={data}
            isClearable={isClearable === false ? false : true}
            placeholder={placeholder}
            menuPosition={menuPosition ? menuPosition : undefined}
            menuPortalTarget={menuPortalTarget ? menuPortalTarget : undefined}
            onChange={(option: any, { action }) => {
                if (action === 'clear') {
                    onClear && onClear();
                }
                if (onChangeFn) {
                    onChangeFn(option?.value);
                }
                formik.setFieldValue(fieldName, option?.value);
                extraField && formik.setFieldValue(extraField, option?.label);
                formik.setFieldError(fieldName, undefined);
                extraFieldFn && extraFieldFn(option?.value);
            }}
            value={data?.filter((option: any) => {
                if (option.value?.trim() === formik.values[fieldName]?.trim()) {
                    return {
                        label: formik.values[fieldName],
                        value: extraField ? formik.values[extraField] : formik.values[fieldName],
                    };
                }
            })}
            onInputChange={(text: string, actionMeta: InputActionMeta) => {
                if (onInputChangeFn) {
                    onInputChangeFn(text, actionMeta);
                }
            }}
            menuPlacement={menuPlacement ? menuPlacement : 'auto'}
            name={fieldName}
            closeMenuOnScroll={(event: any) => {
                if (closeMenuOnScroll) {
                    return event?.target?.classList?.value.includes(REACT_SELECT_WRAPPER) ? false : true;
                } else {
                    return false;
                }
            }}
            className={className}
            classNamePrefix={className}
            isDisabled={isDisabled === true ? true : false}
            components={{
                DropdownIndicator: showDropdownIndicator ? DropdownIndicator : () => null,
                IndicatorSeparator: () => null,
                NoOptionsMessage,
                ClearIndicator,
            }}
            styles={customStyle ? { ...reactSelectStyle, ...customStyle } : reactSelectStyle}
            theme={reactSelectTheme}
        />
    );
};

export default Index;
