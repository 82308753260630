/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import BookingProposalHeaderFCL from '../../BookingProposal/BookingProposalHeader';
import BookingProcessTabFCL from '../../BookingProposal/BookingProcessTab';

import BookingProposalHeaderLCL from '../../LCLBookingProposal/BookingProposalHeader';
import BookingProcessTabLCL from '../../LCLBookingProposal/BookingProcessTab';

import BookingProposalHeaderBCN from '../../BcnBookingProposal/BookingProposalHeader';
import BookingProcessTabBCN from '../../BcnBookingProposal/BookingProcessTab';

import BookingProposalHeaderAir from '../../PoBookingsAir/BookingProposalHeader';
import BookingProcessTabAir from '../../PoBookingsAir/BookingProcessTab';

import BookingProposalHeaderAo from '../../AoBookingProposal/BookingProposalHeader';
import BookingProcessTabAo from '../../AoBookingProposal/BookingProcessTab';

import { useAppDispatch } from 'src/hooks';
import {
    fetchProductDetailsConfirmationList,
    fetchLclThreshold,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import './index.scss';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';

import { useUserProfile } from 'src/hooks/useUserProfile';
// import { Formik } from 'formik';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import EventsModal from './EventsModal';
import { getSingleEvent } from 'src/services/apis/eventsApi';
import { Position, error } from 'src/utils/toast';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { airOptionStatus } from 'src/pages/PoBookingsAir/Options';

const Index: React.FC = () => {
    const { id } = useParams();
    const { userName, profileType } = useUserProfile();
    const dispatch = useAppDispatch();
    const headerData = useSelector((state: RootState) => state.bpHeaderData);

    let bookingUrl = '';
    let navData: any = [];

    if (
        window.location.pathname.includes('/booking_list') ||
        window.location.pathname.includes('/bcnBookingList') ||
        window.location.pathname.includes('/lclbooking_list') ||
        window.location.pathname.includes('/Aobooking_list')
    ) {
        let poSelectionName = 'POSelection';
        if (window.location.pathname.includes('/booking_list')) {
            bookingUrl = 'booking_list';
        } else if (window.location.pathname.includes('/bcnBookingList')) {
            bookingUrl = 'bcnBookingList';
        } else if (window.location.pathname.includes('/lclbooking_list')) {
            bookingUrl = 'lclbooking_list';
        } else {
            bookingUrl = 'Aobooking_list';
            poSelectionName = 'AoSelection';
        }
        navData = [
            {
                title: 'PO Selection',
                selected: 0,
                path: id ? `/${bookingUrl}/${id}/${poSelectionName}` : `/${bookingUrl}/${poSelectionName}`,
                disabled: false,
            },
            {
                title: 'Sailing Schedules',
                selected: 0,
                path: `/${bookingUrl}/${id}/SailingSelectionDetails`,
                disabled: !(headerData?.tabValues as any)?.po,
            },
            {
                title: 'Additional Details',
                selected: 0,
                path: `/${bookingUrl}/${id}/additional-details`,
                disabled: !(headerData?.tabValues as any)?.sailing,
            },
            {
                title: bookingUrl === 'lclbooking_list' ? 'Container Details' : 'Container Allocation',
                selected: 0,
                path: `/${bookingUrl}/${id}/container-allocation`,
                disabled: !(headerData?.tabValues as any)?.sailing,
            },
        ];
        if (
            headerData.bpStatus === 'Ready_for_Booking' ||
            headerData.bpStatus === 'Draft_Booking' ||
            headerData.bpStatus === 'Booked'
        ) {
            navData
                .push
                // {
                //     title: 'Container Allocation',
                //     selected: 0,
                //     path: `/${bookingUrl}/${id}/container-allocation`,
                //     disabled: false,
                // },

                // {
                //     title: 'Attachments',
                //     selected: 0,
                //     path: `/${bookingUrl}/${id}/attachments`,
                //     disabled: false,
                // }
                ();
        }
        if (headerData?.bcnType === 'PAR') {
            navData.push({
                title: 'Linked Bookings',
                selected: 0,
                path: `/bcnBookingList/${id}/linked-bookings`,
                disabled: false,
            });
        }
    } else if (window.location.pathname.includes('/air_booking_list')) {
        bookingUrl = 'air_booking_list';
        const hasBookingId = id !== 'POSelection' && id !== 'POSelectionDetails';
        navData = [
            {
                key: 'po_selection',
                title: 'PO Selection',
                selected: 0,
                path: hasBookingId ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new',
                disabled: false,
            },
            {
                key: 'options',
                title: 'Options',
                selected: 0,
                path: `/air_booking_list/${id}/options`,
                disabled: !(headerData?.tabValues as any)?.po,
            },
            {
                key: 'flight_schedules',
                title: 'Flight Schedules',
                selected: 0,
                path: `/air_booking_list/${id}/FlightSelectionDetails`,
                disabled:
                    !(headerData?.tabValues as any)?.airOptions ||
                    airOptionStatus?.includes(headerData?.bpStatus ?? ''),
            },
            {
                key: 'packing',
                title: 'Packing',
                selected: 0,
                path: `/air_booking_list/${id}/packing`,
                disabled:
                    !(headerData?.tabValues as any)?.airSchedules ||
                    airOptionStatus?.includes(headerData?.bpStatus ?? ''),
            },
        ];
        if (headerData.bpStatus) {
            let newNavData = [...navData];

            if (
                headerData.bpStatus === 'Ready_for_Booking' ||
                headerData.bpStatus === 'Booked' ||
                headerData.bpStatus === 'Draft_Booking'
            ) {
                if (!newNavData.some((item) => item.key === 'booking_details')) {
                    newNavData.push({
                        key: 'booking_details',
                        title: 'Booking Details',
                        selected: 0,
                        path: `/air_booking_list/${id}/booking_details`,
                        disabled: !(headerData?.tabValues as any)?.airBookingDetails,
                    });
                }
                if (!newNavData.some((item) => item.key === 'shipment_details')) {
                    newNavData.push({
                        key: 'shipment_details',
                        title: 'Shipment Details',
                        selected: 0,
                        path: `/air_booking_list/${id}/shipment_details`,
                        disabled: !(headerData?.tabValues as any)?.airShipmentDetails,
                    });
                }
                if (!newNavData.some((item) => item.key === 'attachments')) {
                    // newNavData.push({
                    //     key: 'attachments',
                    //     title: 'Attachments',
                    //     selected: 0,
                    //     path: `/air_booking_list/${id}/attachments`,
                    //     disabled: false,
                    // });
                }
            } else {
                newNavData = newNavData.filter(
                    (navItem) => navItem.key !== 'booking_details' && navItem.key !== 'shipment_details'
                    // &&
                    // navItem.key !== 'attachments'
                );
            }
            navData = [...newNavData];
        }
    }

    const refreshGrid = () => {
        const refresh: any = document.querySelector('.k-pager-refresh');
        if (refresh) {
            refresh?.click();
        }
    };

    const [modalView, setModalView] = useState(false);
    const [eventsData, setEventsData] = useState([]);

    //@ts-ignore
    window.eventUp = (value: string) => {
        getSingleEvent(value)
            .then((res: any) => {
                setModalView(true);
                setEventsData(res);
            })
            .catch((err: any) => {
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            });
    };

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={'#'}
                        backUrl={'/booking_list'}
                    />
                    {(() => {
                        if (bookingUrl === 'booking_list') {
                            return (
                                <>
                                    <BookingProposalHeaderFCL id={localStorage.getItem('bpNumber') || ''} />
                                    <BookingProcessTabFCL navData={navData} />
                                </>
                            );
                        } else if (bookingUrl === 'bcnBookingList') {
                            return (
                                <>
                                    <BookingProposalHeaderBCN id={localStorage.getItem('bpNumber') || ''} />
                                    <BookingProcessTabBCN navData={navData} />
                                </>
                            );
                        } else if (bookingUrl === 'lclbooking_list') {
                            return (
                                <>
                                    <BookingProposalHeaderLCL id={localStorage.getItem('bpNumber') || ''} />
                                    <BookingProcessTabLCL navData={navData} />
                                </>
                            );
                        } else if (bookingUrl === 'air_booking_list') {
                            return (
                                <>
                                    <BookingProposalHeaderAir id={localStorage.getItem('bpNumber') || ''} />
                                    <BookingProcessTabAir navData={navData} />
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <BookingProposalHeaderAo id={localStorage.getItem('bpNumber') || ''} />
                                    <BookingProcessTabAo navData={navData} />
                                </>
                            );
                        }
                    })()}
                </div>
                <div className="lifecycle-grid">
                    <div
                        style={{ zIndex: 999 }}
                        className={`app-loader ${headerData?.isLoadingHeader ? 'loading' : ''}`}
                    >
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'lifecycle'} content={'purchase-order-booking-events'} />
                    </div>
                </div>
                {modalView ? <EventsModal eventsData={eventsData} setModalView={setModalView} /> : null}
            </div>
        </>
    );
};
export default Index;
