import React from 'react';
import { useParams } from 'react-router-dom';
import KendoGridReact from 'src/components/KendoGridReact';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { post, patch, del } from 'src/services/HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

const LifecycleDetail = () => {
    const { id } = useParams();
    const { profileType } = useUserProfile();

    const title = { name: 'Lifecycle Detail', extraKey: 'LH_Name' };
    const permissionName = 'detail';
    const uri = 'cix/scr/lifecycle/flow/' + id;

    const convertForSubmit = (data: any) => {
        return {
            LD_CD_Code: data.LD_CD_Code,
            LD_Sequence: data.LD_Sequence,
            LD_CD_MomentType: data.LD_CD_MomentType,
            LD_Name: data.LD_Name,
            LD_SystemCreated: data.LD_SystemCreated,
            LD_IsMandatory: data.LD_IsMandatory ?? false,
            LD_IsActualEditable: data.LD_IsActualEditable,
            [`LD_Is${profileType[0].toUpperCase()}${profileType.slice(1).toLowerCase()}Viewable`]:
                data?.[`LD_Is${profileType[0].toUpperCase()}${profileType.slice(1).toLowerCase()}Viewable`] ?? false,
            LD_CD_MomentTypeTargetAfter: data.LD_CD_MomentTypeTargetAfter,
            LD_TargetTimeMinutes: data.LD_TargetTimeMinutes,
        };
    };

    const addItem = (payload: any) =>
        post('cix/scr/lifecycle/flow', convertForSubmit(payload), null, false, externalBaseUrl);
    const updateItem = (payload: any, key: string) => patch(`cix/scr/lifecycle/flow/${key}`, convertForSubmit(payload));
    const deleteItem = (payload: any, key: string) => del(`cix/scr/lifecycle/flow/${key}`, null, externalBaseUrl);

    const uniqueKey = 'LD_PK';

    const systemCreatedKey = 'LD_SystemCreated';

    const responseDataKey = 'LD_LifecycleFlowDetails';

    const columns = [
        {
            field: 'LD_PK',
            title: 'LD_PK',
            filter: 'text',
            editor: 'text',
            filterable: false,
            editable: false,
            hidden: true,
        },
        {
            field: 'LD_CD_Code',
            title: 'Code',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
        },
        {
            field: 'LD_Sequence',
            title: 'Sequence',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
        },
        {
            field: 'LD_CD_MomentType',
            title: 'Moment Type',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
        },
        {
            field: 'LD_Name',
            title: 'Name',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
        },
        {
            field: 'LD_SystemCreated',
            title: 'System Created',
            filter: 'text',
            editor: 'boolean',
            filterable: true,
            editable: false,
            hidden: false,
        },
        {
            field: 'LD_IsMandatory',
            title: 'Is Mandatory',
            filter: 'text',
            filterable: true,
            editable: true,
            hidden: false,
            editor: 'boolean',
        },
        {
            field: 'LD_IsActualEditable',
            title: 'Is Actual Editable',
            filter: 'text',
            filterable: true,
            editable: true,
            hidden: false,
            editor: 'boolean',
        },
        {
            field: `LD_Is${profileType[0].toUpperCase()}${profileType.slice(1).toLowerCase()}Viewable`,
            title: `Is ${profileType} Viewable`,
            filter: 'text',
            filterable: true,
            editable: true,
            hidden: false,
            editor: 'boolean',
            width: 200,
        },
        {
            field: 'LD_CD_MomentTypeTargetAfter',
            title: 'Moment Type Target After',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
            width: 200,
        },
        {
            field: 'LD_TargetTimeMinutes',
            title: 'Target Time Minutes',
            filter: 'text',
            editor: 'text',
            filterable: true,
            editable: true,
            hidden: false,
        },
    ];

    return (
        <KendoGridReact
            title={title}
            uri={uri}
            permissionName={permissionName}
            columns={columns}
            uniqueKey={uniqueKey}
            systemCreatedKey={systemCreatedKey}
            addItem={addItem}
            updateItem={updateItem}
            deleteItem={deleteItem}
            responseDataKey={responseDataKey}
        />
    );
};

export default LifecycleDetail;
