import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import '../../BLPInboundPo/ProductDetails/productdetails-modal.scss';

interface PropUser {
    products: any;
    clientCode: string;
}

import { selectSku, fetchSkuList } from '../../../store/reducers/purchaseOrder/productPoReducer';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';

const Index: React.FC<PropUser> = ({ products, clientCode }) => {
    const dispatch = useAppDispatch();
    const [currentBarcode, setCurrentBarcode] = useState('');
    const [distanceFromTopForBarcode, setDistanceFromTopForBarcode] = useState(0);
    const [distanceFromLeftForBarcode, setDistanceFromLeftForBarcode] = useState(0);
    const [showBarcode, setShowBarcode] = useState(false);

    useEffect(() => {
        if (clientCode) {
            dispatch(fetchSkuList({ clientCode }));
        }
    }, [clientCode]);

    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, setShowBarcode);

    const sku = useAppSelector(selectSku);

    const resizeFilter = (idSelector: string, skuId: string, uom: string) => {
        const el_table = document.getElementById('table_div');
        let gridDistance = 0;
        let gridDistanceLeft = 0;
        if (el_table?.getBoundingClientRect().top) {
            gridDistance = el_table?.getBoundingClientRect().top - 25;
            gridDistanceLeft = el_table?.getBoundingClientRect().left + 10;
        }
        const el_barcode = document.getElementById(idSelector);
        if (el_barcode?.getBoundingClientRect().top) {
            setDistanceFromTopForBarcode(el_barcode?.getBoundingClientRect().top - gridDistance);
        }
        if (el_barcode?.getBoundingClientRect().left) {
            setDistanceFromLeftForBarcode(el_barcode?.getBoundingClientRect().left - gridDistanceLeft);
        }
        if (sku?.length) {
            for (let index = 0; index < sku.length; index++) {
                if (sku[index].pm_PK === skuId) {
                    const barcode = sku[index]?.pm_packets?.find(
                        (res: any) => res.pp_quantity?.toLowerCase() === uom?.toLowerCase()
                    )?.pp_barcode;
                    setCurrentBarcode(barcode ? barcode : '');
                }
            }
        }
    };
    return (
        <>
            <div
                onScroll={() => {
                    setShowBarcode(false);
                }}
                className="grid-container"
            >
                {products && products.length === 0 && (
                    <div className="grid-no-content ">
                        <svg className="svg-icon grid-no-content-icon ">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">No records found</p>
                    </div>
                )}
                <div className="grid-scroll-content">
                    <table className="grid-table">
                        <thead>
                            <tr className="grid-sticky-row">
                                <th className="grid-cell-header col-po-no">
                                    <div style={{ minWidth: '100px' }} className="grid-cell-data">
                                        <span>PO LINE NO.</span>
                                    </div>
                                </th>
                                <th className="grid-cell-header col-sku">
                                    <div className="grid-cell-data">
                                        <span>SKU</span>
                                    </div>
                                </th>

                                <th className="grid-cell-header col-description">
                                    <div style={{ minWidth: '250px' }} className="grid-cell-data">
                                        <span>DESCRIPTION</span>
                                    </div>
                                </th>

                                <th className="grid-cell-header col-ready-date">
                                    <div style={{ minWidth: '200px' }} className="grid-cell-data">
                                        <span>GOODS READY DATE</span>
                                    </div>
                                </th>
                                <th className="grid-cell-header col-eta-whouse">
                                    <div style={{ minWidth: '200px' }} className="grid-cell-data">
                                        <span>PROJ. ETA INTO WHOUSE</span>
                                    </div>
                                </th>

                                <th className="grid-cell-header col-quantity">
                                    <div className="grid-cell-data">
                                        <span>QUANTITY</span>
                                    </div>
                                </th>
                                <th className="grid-cell-header col-cbm">
                                    <div className="grid-cell-data">
                                        <span>CBM</span>
                                    </div>
                                </th>
                                <th className="grid-cell-header col-currency">
                                    <div className="grid-cell-data">
                                        <span>CURRENCY</span>
                                    </div>
                                </th>
                                <th className="grid-cell-header col-unit-cost">
                                    <div style={{ minWidth: '100px' }} className="grid-cell-data">
                                        <span>UNIT COST</span>
                                    </div>
                                </th>
                                <th className="grid-cell-header col-net-cost">
                                    <div style={{ minWidth: '100px' }} className="grid-cell-data">
                                        <span>NET COST</span>
                                    </div>
                                </th>
                                {/* BLP INBOUND PO - additional table column added */}
                                <th className="grid-cell-header col-converted">
                                    <div className="grid-cell-data">CONVERTED QTY & UOM</div>
                                </th>
                                <th className="grid-cell-header col-barcode">
                                    <div style={{ minWidth: '200px' }} className="grid-cell-data">
                                        BARCODES
                                    </div>
                                </th>
                            </tr>
                        </thead>

                        <tbody>
                            {products?.map((product: any, index: number) => (
                                <tr key={index} className="has-child row-expanded">
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">
                                            <span className="client-id">
                                                {product.poLineNumber ? product.poLineNumber : '-'}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">{product.sku}</div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">{product.description}</div>
                                    </td>
                                    {/* <td className="grid-cell">
                                    <div className="grid-cell-data">{product.countryName}</div>
                                </td> */}
                                    {/* {headerData?.isMultiAddress && ( */}
                                    <>
                                        {/* <td className="grid-cell">
                                        <div className="grid-cell-data">
                                            {product.destinationPort}
                                        </div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">
                                            {product.destinationAddress}
                                        </div>
                                    </td> */}
                                        <td className="grid-cell">
                                            <div className="grid-cell-data">
                                                {product.goodsReadyDate
                                                    ? moment(product.goodsReadyDate).format('DD-MM-YYYY')
                                                    : null}
                                            </div>
                                        </td>
                                        <td className="grid-cell">
                                            <div className="grid-cell-data">
                                                {product.projWhouseETA
                                                    ? moment(product.projWhouseETA).format('DD-MM-YYYY')
                                                    : null}
                                            </div>
                                        </td>
                                    </>
                                    {/* )} */}

                                    <td className="grid-cell">
                                        <div className="grid-cell-data">{product.qty}</div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">{product.cbm}</div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">{product.currency?.currencycode}</div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">{product.unitCost}</div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">{product.netCost}</div>
                                    </td>
                                    <td className="grid-cell">
                                        <div className="grid-cell-data">
                                            {product.convertedQty} {product.uom}
                                        </div>
                                    </td>
                                    <td className="grid-cell  search-entry-holder">
                                        <div className="grid-cell-data">
                                            <button
                                                id={`barcode_each_${index}`}
                                                className="action-btn app-btn app-btn-secondary action-text "
                                                title="Each"
                                                type="button"
                                                disabled={
                                                    product.barcodes?.toLowerCase()?.includes('each') ? false : true
                                                }
                                                onClick={(e) => {
                                                    resizeFilter(`barcode_each_${index}`, product.skuId, 'Each');
                                                    e.stopPropagation();
                                                    setShowBarcode((prev) => !prev);
                                                }}
                                            >
                                                <span className=" each">E</span>
                                            </button>
                                            <button
                                                id={`barcode_inner_${index}`}
                                                className="action-btn app-btn app-btn-secondary action-text "
                                                title="Inner"
                                                type="button"
                                                disabled={
                                                    product.barcodes?.toLowerCase()?.includes('inner') ? false : true
                                                }
                                                onClick={(e) => {
                                                    resizeFilter(`barcode_inner_${index}`, product.skuId, 'Inner');
                                                    e.stopPropagation();
                                                    setShowBarcode((prev) => !prev);
                                                }}
                                            >
                                                <span className="inner">I</span>
                                            </button>
                                            <button
                                                id={`barcode_case_${index}`}
                                                className="action-btn app-btn app-btn-secondary action-text"
                                                title="Case"
                                                type="button"
                                                disabled={
                                                    product.barcodes?.toLowerCase()?.includes('case') ? false : true
                                                }
                                                onClick={(e) => {
                                                    resizeFilter(`barcode_case_${index}`, product.skuId, 'Case');
                                                    e.stopPropagation();
                                                    setShowBarcode((prev) => !prev);
                                                }}
                                            >
                                                <span className=" count">C</span>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            {showBarcode && (
                <div
                    ref={wrapperRef}
                    onClick={(e) => e.stopPropagation()}
                    style={{
                        top: `${distanceFromTopForBarcode}px`,
                        left: `${distanceFromLeftForBarcode}px`,
                    }}
                    className="dropdown-wrap outside-menu menu-down dropdown-open info-dropdown left-align barcode-dropdown"
                >
                    <div className="dropdown-menu">
                        <span className="menu-item not-link">{currentBarcode}</span>
                    </div>
                </div>
            )}
        </>
    );
};
export default Index;
