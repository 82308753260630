import { ReactElement, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useAppDispatch } from 'src/hooks';
import { RootState } from 'src/store/reducers';
import {
    addOrEditDestinationAgent,
    fetchNacAdditionalDetails,
    resetBillDesinationAgentFlags,
} from 'src/store/reducers/bookingProposal/additionalDetailsNacReducer';
import { error, Position, success } from 'src/utils/toast';
import './additional-detail-modal.scss';
interface DestinationAgentProps {
    setShowModal: (data: boolean) => void;
    agentCode: string;
    isAdd: boolean;
    callFetchFunction?: any;
}

const Index = ({ setShowModal, agentCode, isAdd, callFetchFunction }: DestinationAgentProps): ReactElement => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const { destinationAgents } = useSelector((state: RootState) => state.purchaseOrder) as any;
    const { isAddOrEditDestinationAgentError, isAddOrEditDestinationAgentSuccess, destinationAgentError } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    const [agentCodeError, setAgentCodeError] = useState(false);
    const [destinationAgentCode, setDestinationAgentCode] = useState(agentCode);

    const onSave = () => {
        if (!destinationAgentCode) {
            setAgentCodeError(true);
        } else {
            if (agentCodeError) {
                setAgentCodeError(false);
            }
            const destinationAgentName = destinationAgents?.find((x: any) => x.value === destinationAgentCode).label;
            dispatch(addOrEditDestinationAgent({ bpId: id, destinationAgentName, destinationAgentCode }));
        }
    };

    useEffect(() => {
        if (isAddOrEditDestinationAgentSuccess) {
            dispatch(resetBillDesinationAgentFlags({}));
            dispatch(fetchNacAdditionalDetails({ bpId: id }));
            setShowModal(false);
            success(`Destination agent ${isAdd ? 'added' : 'updated'}`, Position.TOP_RIGHT);
            if (callFetchFunction) {
                callFetchFunction();
            }
        }
    }, [isAddOrEditDestinationAgentSuccess]);

    useEffect(() => {
        if (isAddOrEditDestinationAgentError) {
            dispatch(resetBillDesinationAgentFlags({}));
            error((destinationAgentError as any)?.response.data?.data || 'Something went wrong', Position.TOP_RIGHT);
        }
    }, [isAddOrEditDestinationAgentError]);

    return (
        <div className="app-modal">
            <div className="modal-content-holder additional-detail-modal">
                <div className="modal-header">
                    <div className="title">Additional Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className=" modal-input-holder ">
                            <label htmlFor="name" className="modal-input-label">
                                Destination Agent <span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-layout-item">
                                <SearchableSelectBox
                                    data={destinationAgents}
                                    placeholder="Enter Agent Name to select"
                                    setStateValue={setDestinationAgentCode}
                                    stateValue={destinationAgentCode}
                                    fieldName="destinationAgentCode"
                                    menuPortalTarget={document.getElementById('outside_div')}
                                />
                            </div>
                            {agentCodeError && !destinationAgentCode ? (
                                <div className="error-text">Please select destination Agent</div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                        onClick={() => {
                            setShowModal(false);
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="my-form"
                        title="Save"
                        // disabled={
                        //     !destinationAgentCode ||
                        //     (destinationAgentCode && agentCode && destinationAgentCode === agentCode)
                        //         ? true
                        //         : false
                        // }
                        onClick={() => {
                            onSave();
                        }}
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
