import axios from 'axios';
import { get, patch, post } from '../../HttpMethods';

export const fetchConfirmedLinesData = async (payload: any) => {
    try {
        const data = await get(`api/ord/cg/listing/confirmedLines?confirmationGroupId=${payload}`, null);
        return data?.info?.confirmedLineDtoList;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const submitContainerToForwarder = async (payload: any) => {
    try {
        const data = await patch(`api/ord/cg/agent/${payload}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const rejectContainerapi = async (payload: any) => {
    try {
        const data = await post(`api/ord/cg/reject`, payload, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const rejectAssignToAgentapi = async (payload: any) => {
    try {
        const data = await patch(`api/ord/cg/reassignagent/${payload.id}`, payload.payload);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const updateViewedStatusapi = async (payload: any) => {
    try {
        const data = await patch(`/api/ord/cg/closemodal/${payload.id}/${payload.user}`, payload);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
