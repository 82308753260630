import React, { useEffect } from 'react';
import { Polyline, Marker } from 'react-leaflet';
import '@elfalem/leaflet-curve';
import TransitPointIcon from '../../Icons/transitPoitIcon';
import PortName from '../../Icons/portName';

import { useCurvedLine as curvedLine } from '../../hooks';
// import LineOverlayIcon from './LineOverlayIcon';
import LineOverlayIcon2 from './LineOverlayIcon2';
// import LineOverlayIcon3 from './LineOverlayIcon3';

export type Coordinates = [number, number];

export interface Props {
    destinationPoint: Coordinates;
    nextStop?: any;
    originPoint: Coordinates;
    routePoints: Coordinates[];
    shippingMode: 'SEA' | 'AIR';
    hideOriginMarker?: boolean;
    hideDestinationMarker?: boolean;
    originPointName?: string;
    destinationPointName?: string;
    nextStopName?: string;
    zoomlevel?: any;
}
const TrackLine: React.FC<Props> = ({
    destinationPoint,
    hideDestinationMarker,
    hideOriginMarker,
    nextStop,
    originPoint,
    routePoints,
    shippingMode,
    originPointName,
    destinationPointName,
    nextStopName,
    zoomlevel,
}) => {
    if (typeof destinationPoint[0] === 'string' || destinationPoint[0] === null) {
        destinationPoint = [0, 0];
    }
    if (typeof originPoint[0] === 'string' || originPoint[0] === null) {
        originPoint = [0, 0];
    }
    nextStop = nextStop?.length !== 0 ? nextStop : destinationPoint;
    //routePoints &&
    //    routePoints?.forEach((x: any) => {
    //        if (x[0] === null) {
    //            x = [0, 0];
    //        }
    //    });
    //check the destination and next stop are same and keep the boolean variable to show the transit stop according to that value
    const haveTransitStop = nextStop && nextStop[0] !== destinationPoint[0] && nextStop[1] !== destinationPoint[1];
    curvedLine({
        startPoint: routePoints.length ? routePoints[routePoints.length - 1] : originPoint,
        endPoint: nextStop ? nextStop : destinationPoint,
    });
    if (haveTransitStop) {
        curvedLine({
            startPoint: nextStop,
            endPoint: destinationPoint,
        });
    }
    useEffect(() => {
       // console.log('zoomlevel');
    }, [zoomlevel]);
    const pathOption = { color: 'black', weight: 1 };

    return (
        <>
            {!hideOriginMarker && (
                <>
                    <Marker position={originPoint} icon={TransitPointIcon()} />
                    {!!originPointName && <Marker position={originPoint} icon={PortName(originPointName)} />}
                </>
            )}
            {!hideDestinationMarker && (
                <>
                    <Marker position={destinationPoint} icon={TransitPointIcon('green-point')} />
                    {!!destinationPointName && (
                        <Marker position={destinationPoint} icon={PortName(destinationPointName)} />
                    )}
                </>
            )}
            {haveTransitStop && (
                <>
                    <Marker position={nextStop} icon={TransitPointIcon('orange-point')} />
                    {!!nextStopName && <Marker position={nextStop} icon={PortName(nextStopName)} />}
                </>
            )}
            {/* TODO: remove the below marker in the track line, it will be there when the map icons rendered , fix the position of custom marker
            to make sure its pointing in the correct coordinates*/}
            {/* {routePoints.length > 0 && (
                <Marker position={routePoints[routePoints.length - 1]} icon={Icons('bookingsIntransitArray', 15)} />
            )} */}
            {/* Show solid straight lines if it has travelled, which should have minimum two coordinates including origin port */}
            {routePoints.length > 0 && <Polyline positions={[originPoint, ...routePoints]} pathOptions={pathOption} />}
            {
                <LineOverlayIcon2
                    startPoint={routePoints.length ? routePoints[routePoints.length - 1] : originPoint}
                    endPoint={haveTransitStop ? nextStop : destinationPoint}
                    shippingMode={shippingMode}
                    zoomlevel={zoomlevel}
                    changeVal={0}
                />
            }
        </>
    );
};
export default TrackLine;
