export enum PoContainerStatus {
    OptionsToBeAdded = 'options_to_be_added',
    PendingActionForwarder = 'pending_action_forwarder',
    PendingSelectionClient = 'pending_selection_client',
    RejectedByClientWithAgent = 'rejected_by_client__with_agent',
    RejectedByClientWithForwarder = 'rejected_by_client__with_forwarder',
    ConfirmedForBooking = 'confirmed_for_booking',
    InBookingProposal = 'in_booking_proposal',
    Booked = 'booked',
}

export const poContainerStatusPillList = (status: string) => {
    switch (status?.toLowerCase()) {
        case PoContainerStatus.OptionsToBeAdded:
            return <div className="modal-toast pending">Options to be added</div>;
        case PoContainerStatus.PendingActionForwarder:
            return <div className="modal-toast pending">Pending Action: Forwarder</div>;
        case PoContainerStatus.PendingSelectionClient:
            return <div className="modal-toast pending">Pending Selection: Client</div>;
        case PoContainerStatus.RejectedByClientWithAgent:
            return <div className="modal-toast rejected">Rejected by Client: With Agent</div>;
        case PoContainerStatus.RejectedByClientWithForwarder:
            return <div className="modal-toast rejected">Rejected by Client: With Forwarder</div>;
        case PoContainerStatus.ConfirmedForBooking:
            return <div className="modal-toast in-progress">Confirmed for Booking</div>;
        case PoContainerStatus.InBookingProposal:
            return <div className="modal-toast completed">In Booking Proposal</div>;
        case PoContainerStatus.Booked:
            return <div className="modal-toast completed">Booked</div>;
        default:
            return <div className="modal-toast neutral">{status}</div>;
    }
};
