import { ReactElement, useState } from 'react';
import '../../BookingProposal/BookingAdditionalInfo/additional-detail-modal.scss';
interface AddProps {
    setShowNacAddModal: (data: boolean) => void;
    onAddDetails: (data: string) => void;
    referenceNumber: string;
}

const Index = ({ setShowNacAddModal, onAddDetails, referenceNumber }: AddProps): ReactElement => {
    const [ref, setRef] = useState(referenceNumber || '');

    const onSave = () => {
        onAddDetails(ref?.trim());
    };
    return (
        <div className="app-modal">
            <div className="modal-content-holder additional-detail-modal">
                <div className="modal-header">
                    <div className="title">Additional Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            setShowNacAddModal(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div id="name_div" className=" modal-input-holder ">
                            <label htmlFor="name" className="modal-input-label">
                                Reference No.
                            </label>
                            <div className="modal-input-layout-item">
                                <input
                                    placeholder="Enter Reference No."
                                    type="text"
                                    className="modal-input input-text"
                                    value={ref}
                                    onChange={(e: any) => {
                                        setRef(e.target.value);
                                    }}
                                ></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                        onClick={() => {
                            setShowNacAddModal(false);
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="my-form"
                        title="Save"
                        onClick={() => {
                            onSave();
                        }}
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
