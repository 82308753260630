import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import 'src/assets/scss/components/_breadcrumbs.scss';
import { Position, success, error } from 'src/utils/toast';
import { useAppSelector } from 'src/hooks';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import { selectHeader } from 'src/store/reducers/allocationOrder/addAOReducer';
import CancelDiscardModal from './CancelDiscardModal';
import { cancelDiscardAO, reset } from 'src/store/reducers/allocationOrder/allocationOrderListReducer';
import ShowForPermissions from 'src/ShowForPermissions';
import { activeAos, activeStatus } from 'src/utils/constants';
import { setFilter, setSelectedDropDown } from '../../../store/reducers/allocationOrder/allocationOrderListReducer';

interface Props {
    disabled?: boolean;
    callbackFn?: any;
}

const Index: React.FC<Props> = (props) => {
    const [displayModal, setDisplayModal] = useState<'cancel' | 'discard' | null>(null);

    const headerData = useAppSelector(selectHeader);
    const { profileType } = useUserProfile();
    const dispatch = useDispatch();
    const { error: errorMessage, success: successFlag } = useSelector((state: RootState) => state.allocationOrderList);
    const navigate = useNavigate();
    const location = useLocation();

    const userDetails = JSON.parse(localStorage.getItem('user') || '')?.user;
    const userName = userDetails?.us_UserName;
    const name = (userDetails?.us_FirstName || '') + ' ' + (userDetails?.us_LastName || '');

    const onConfirmCancelDiscard = (reason: string) => {
        dispatch(
            cancelDiscardAO({
                aoId: headerData.aoId,
                reason,
                name,
                userName,
                action: displayModal,
            })
        );
    };

    // Close either cancel or discard modal by setting state value to null
    const onClose = () => {
        setDisplayModal(null);
    };

    useEffect(() => {
        if (successFlag) {
            if (headerData?.aoStatus === 'Pending_with_Forwarder') {
                success(`AO ${headerData.aoNumber} has been cancelled`, Position.TOP_RIGHT);
            } else {
                success(`AO ${headerData.aoNumber} has been discarded`, Position.TOP_RIGHT);
            }
            if (location.pathname.includes('/allocation_orders')) {
                navigate('/allocation_orders');
            } else {
                navigate('/');
            }
        }
        if (errorMessage) {
            error(errorMessage, Position.TOP_RIGHT);
        }
        dispatch(reset());
        setDisplayModal(null);
    }, [errorMessage, successFlag]);

    const disabled = props.disabled ?? false;

    const redirection = async () => {
        if (props?.callbackFn) {
            const status = props?.callbackFn();
            if (!status) {
                success('Details has been saved', Position.TOP_RIGHT);
            }
        } else {
            success('Details has been saved', Position.TOP_RIGHT);
        }
        navigate('/allocation_orders');
    };
    window.onpopstate = () => {
        const initialFilter = { aoStatus: activeStatus };
        const initialDropDown = {
            text: activeAos,
            count: 0,
        };
        dispatch(setFilter(initialFilter));
        dispatch(setSelectedDropDown(initialDropDown));
    };
    return (
        <>
            <div className="breadcrumbs-holder">
                <ul className="breadcrumbs">
                    <Link
                        to="/allocation_orders"
                        className={`dropdown-arrow ${disabled ? 'disabled' : ''}`}
                        state={{ reload: true }}
                    >
                        <svg className="arrow-icon">
                            <use xlinkHref="#downArrow">
                                <title>User details</title>
                            </use>
                        </svg>
                    </Link>
                    <li className="breadcrumb-list-item">
                        <Link
                            className={`breadcrumb-item ${disabled ? 'disabled' : ''}`}
                            to="/allocation_orders"
                            state={{ reload: true }}
                        >
                            Allocation Orders
                        </Link>
                    </li>
                    <li className="breadcrumb-list-item">
                        <Link to="" className={`breadcrumb-item ${disabled ? 'disabled' : ''}`}>
                            {headerData?.aoStatus === 'Draft'
                                ? 'Create New Allocation Order'
                                : 'Allocation Order Details'}
                        </Link>
                    </li>
                </ul>
                <div className="action-wrapper">
                    {/* show cancel/discard buttons only for AO with draft/pending_with_Forwarder status  */}
                    {(headerData?.aoStatus === 'Draft' ||
                        (headerData?.aoStatus === 'Pending_with_Forwarder' && profileType !== 'client')) &&
                        headerData?.dataSourceName != 'ERP' && (
                            <ShowForPermissions permission="edit" type="ao">
                                <div className="btn-holder discard-btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title={
                                            headerData?.aoStatus === 'Draft'
                                                ? 'Discard Allocation Order'
                                                : headerData?.aoStatus === 'Pending_with_Forwarder'
                                                ? 'Cancel Allocation Order'
                                                : ''
                                        }
                                        onClick={() => {
                                            if (headerData?.aoStatus === 'Draft') {
                                                setDisplayModal('discard');
                                            } else {
                                                setDisplayModal('cancel');
                                            }
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#deleteIcon"></use>
                                        </svg>
                                        <span className="button-text">
                                            {headerData?.aoStatus === 'Draft' ? 'Discard AO' : 'Cancel AO'}
                                        </span>
                                    </button>
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title={'Save & Exit'}
                                        onClick={() => redirection()}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#saveIcon2">
                                                <title>Save & Exit</title>
                                            </use>
                                        </svg>
                                        <span className="button-text">Save & Exit</span>
                                    </button>
                                </div>
                            </ShowForPermissions>
                        )}
                </div>
                {!!displayModal ? (
                    <CancelDiscardModal
                        aoNumber={headerData?.aoNumber}
                        blp={headerData?.blpAgentName}
                        clientName={headerData?.clientName}
                        onClose={onClose}
                        onConfirm={onConfirmCancelDiscard}
                        type={displayModal}
                        warningText={
                            displayModal === 'cancel'
                                ? 'Once cancelled, all allocations made with this order will be reversed and the order will be inactive'
                                : 'Once discarded, all allocations made with this order will be reversed and the order will be removed from system.'
                        }
                    />
                ) : null}
            </div>
        </>
    );
};

export default Index;
