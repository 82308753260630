import React, { useEffect, useState } from 'react';
import { RootState } from 'src/store/reducers';
import { useAppDispatch } from '../../../hooks';
import {
    BpQuotationList,
    Currency,
    updateQuotation,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import './add-user-additional-modal.scss';
import { useSelector } from 'react-redux';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { convertDataForSelect } from 'src/utils';
import { FILE_SIZE, SUPPORTED_FORMATS_FOR_FAK, fileTypesInfoText, fileUploadInfoText } from 'src/utils/constants';
import { Link } from 'react-router-dom';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    quotation: BpQuotationList[];
    currency: Currency[];
    bpId: string;
    userProfile: { profileType: string; userName: string };
    type: string;
    additionalDetailsData: { additionalDetails: any; updateAdditionalDetails: any };
    setShowNoData: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    contractType?: string;
}
const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    quotation,
    currency,
    bpId,
    userProfile,
    type,
    additionalDetailsData: { additionalDetails, updateAdditionalDetails },
    setShowNoData,
    setCancelModalVisibility,
    contractType,
}) => {
    const dispatch = useAppDispatch();
    const { additionalDetails: additionalDetailsCurrent } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    const { destinationAgents } = useSelector((state: RootState) => state.purchaseOrder) as any;
    const { isTriangleShipment, bpStatus, isForwarderSubmit, clientId, clientName } = useSelector(
        (state: RootState) => state.bpHeaderData
    );
    const { profileType } = useUserProfile();
    const [showQuotation, setShowQuotation] = useState(false);
    const [_showCurrency, setShowCurrency] = useState(false);
    const [disableButton, setDisableButton] = useState(true);
    const [agentCode, setAgentCode] = useState(additionalDetailsCurrent?.destinationAgentCode || '');
    const [isSaveClicked, setIsSaveClicked] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [validation, setValidation] = useState('');

    useEffect(() => {
        if (additionalDetailsCurrent?.savedFakFileName) {
            const file = {
                size: additionalDetailsCurrent?.savedFakFileSizeInKb
                    ? additionalDetailsCurrent?.savedFakFileSizeInKb * 1024
                    : 0,
                name: additionalDetailsCurrent?.savedFakFileName,
            };
            setFile(file);
        }
    }, [additionalDetailsCurrent]);

    const submitAdditionalDetails = (type: string) => {
        if (
            profileType === 'forwarder' &&
            contractType === 'FAK' &&
            (bpStatus === 'Rejected_by_Forwarder' ||
                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                bpStatus === 'Draft_Booking_Proposal')
        ) {
            if (!file) {
                setValidation('File is required');
                return false;
            }
        }
        if (disableButton) {
            return;
        }
        const destinationAgentName = destinationAgents?.find((x: any) => x.value === agentCode)?.label;
        const formData = new FormData();
        const data = {
            // type,
            bpId,
            sellQuotationName: additionalDetails?.sellQuotationName,
            sellQuotationVersion: additionalDetails?.sellQuotationVersion,
            sellQuotationId: additionalDetails?.sellQuotationId,
            sellTotOriginCost: additionalDetails?.sellTotOriginCost,
            bookingContractNo: additionalDetails?.bookingContractNo,
            referenceNumber: additionalDetails?.referenceNumber,
            sellCurrency: additionalDetails?.sellCurrency == undefined ? null : additionalDetails?.sellCurrency,
            userName: userProfile.userName,
            destinationAgentCode: agentCode || additionalDetails?.destinationAgentCode,
            destinationAgentName: agentCode ? destinationAgentName : additionalDetails?.destinationAgentName,
            buytotalOriginCost: additionalDetails?.buytotalOriginCost,
            buyCurrencyCode: additionalDetails?.buyCurrencyCode,
        };
        formData.append('document', new Blob([JSON.stringify(data)], { type: 'application/json' }));
        if (file?.type) {
            formData.append('file', file, file.name);
        }
        dispatch(updateQuotation({ formData, type }));
        setModalView(false);
        // dispatch(
        //     updateQuotation({
        //         type,
        //         bpId,
        //         sellQuotationName: additionalDetails?.sellQuotationName,
        //         sellQuotationVersion: additionalDetails?.sellQuotationVersion,
        //         sellQuotationId: additionalDetails?.sellQuotationId,
        //         sellTotOriginCost: additionalDetails?.sellTotOriginCost,
        //         bookingContractNo: additionalDetails?.bookingContractNo,
        //         referenceNumber: additionalDetails?.referenceNumber,
        //         sellCurrency: additionalDetails?.sellCurrency,
        //         userName: userProfile.userName,
        //         destinationAgentCode: agentCode || additionalDetails?.destinationAgentCode,
        //         destinationAgentName: agentCode ? destinationAgentName : additionalDetails?.destinationAgentName,
        //         buytotalOriginCost: additionalDetails?.buytotalOriginCost,
        //         buyCurrencyCode: additionalDetails?.buyCurrencyCode,
        //     })
        // );
    };

    const checkButtonStatus = () => {
        let cond;
        if (
            profileType === 'forwarder' &&
            (bpStatus === 'Rejected_by_Forwarder' ||
                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                bpStatus === 'Draft_Booking_Proposal')
        ) {
            cond =
                !additionalDetails?.buyCurrencyCode ||
                additionalDetails?.buytotalOriginCost === '' ||
                Number.isNaN(additionalDetails?.buytotalOriginCost);
        } else {
            cond =
                additionalDetails?.sellQuotationName === '' ||
                !additionalDetails?.sellCurrency ||
                additionalDetails?.sellTotOriginCost === '' ||
                Number.isNaN(additionalDetails?.sellTotOriginCost);
        }

        if (
            contractType === 'NAC' &&
            additionalDetails?.sellQuotationName === '' &&
            additionalDetails?.referenceNumber === ''
        ) {
            setDisableButton(true);
            return;
        } else if (contractType !== 'NAC' && (cond || additionalDetails?.bookingContractNo === '')) {
            setDisableButton(true);
            return;
        } else if (
            isTriangleShipment &&
            profileType === 'forwarder' &&
            (bpStatus === 'Awaiting_Forwarder_Approval' || bpStatus === 'Draft_Booking_Proposal') &&
            !agentCode
        ) {
            setDisableButton(true);
            return;
        }
        setDisableButton(false);
        // if (additionalDetailsCurrent?.sellQuotationName) {
        //     if (
        //         additionalDetails?.sellQuotationName === additionalDetailsCurrent?.sellQuotationName &&
        //         additionalDetails?.sellTotOriginCost == additionalDetailsCurrent?.sellTotOriginCost &&
        //         additionalDetails?.sellCurrency === additionalDetailsCurrent?.sellCurrency?.currencycode &&
        //         (!agentCode || agentCode === additionalDetails?.destinationAgentCode)
        //     ) {
        //         setDisableButton(true);
        //     } else {
        //         setDisableButton(false);
        //     }
        // } else {
        //     if (
        //         additionalDetails?.sellQuotationName &&
        //         additionalDetails?.sellTotOriginCost &&
        //         additionalDetails?.sellCurrency
        //     ) {
        //         setDisableButton(false);
        //     }
        // }
    };

    useEffect(() => {
        checkButtonStatus();
    }, [additionalDetails]);
    useEffect(() => {
        if (additionalDetailsCurrent?.destinationAgentCode) {
            setAgentCode(additionalDetailsCurrent?.destinationAgentCode);
        }
    }, [additionalDetailsCurrent?.destinationAgentCode]);

    const initialState = {
        sellQuotationName: '',
        sellQuotationVersion: '',
        sellQuotationId: '',
        sellTotOriginCost: '',
        bookingContractNo: additionalDetails?.bookingContractNo ? additionalDetails?.bookingContractNo : '',
        referenceNumber: additionalDetails?.referenceNumber ? additionalDetails?.referenceNumber : '',
        sellCurrency: '',
        buytotalOriginCost: '',
        buyCurrencyCode: '',
    };

    const closeModal = () => {
        setModalView(false);
        setShowNoData(false);
        updateAdditionalDetails(initialState);
        setAgentCode('');
    };

    const showError = (errMsg: string, fieldValue: any) => {
        return <>{isSaveClicked && !fieldValue ? <div className="error-text">{errMsg}</div> : null}</>;
    };

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            // formik.setFieldValue('quotationFile', e.target.files[0]);
            if (e.target.files[0]?.size > FILE_SIZE) {
                setValidation('Maximum file size is 25mb');
            } else if (!SUPPORTED_FORMATS_FOR_FAK.includes(e.target.files[0]?.type)) {
                setValidation('File type is not supported');
            } else {
                setFile(e.target.files[0]);
                setValidation('');
            }
        }
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const removeFile = () => {
        setFile(null);
        setValidation('File is required');
        // formik.setFieldValue('quotationFile', null);
    };

    return (
        <>
            {modalView ? (
                <div
                    onClick={() => {
                        setShowQuotation(false);
                        setShowCurrency(false);
                    }}
                    className="app-modal additional-add-user-modal"
                >
                    <div
                        className="modal-content-holder medium-popup"
                        style={{
                            maxHeight:
                                bpStatus === 'Draft_Booking_Proposal' || bpStatus === 'Rejected_by_Forwarder'
                                    ? '720px'
                                    : '',
                        }}
                    >
                        <div className="modal-header">
                            <div className="title">Additional Details</div>
                            <button
                                onClick={() => {
                                    disableButton ? closeModal() : setCancelModalVisibility(true);
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div
                            className="modal-content"
                            style={{
                                height:
                                    bpStatus === 'Draft_Booking_Proposal' || bpStatus === 'Rejected_by_Forwarder'
                                        ? '620px'
                                        : '',
                            }}
                        >
                            <div className="modal-main-content agent-commercial-detail-modal-main-content">
                                <form id="my-form" action="" className="modal-form layout-holder one-column">
                                    {(bpStatus === 'Draft_Booking_Proposal' ||
                                        bpStatus === 'Rejected_by_Forwarder' ||
                                        (bpStatus === 'Rejected_by_Client_Forwarder_to_Action' &&
                                            isForwarderSubmit)) && (
                                        <>
                                            {contractType === 'NAC' ? (
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">Reference No</label>
                                                    <div className="modal-input-layout-item">
                                                        <div className="total-term-wrapper">
                                                            <div
                                                                style={{ width: '100%' }}
                                                                className="modal-input-layout-item total-terms-data-holder textfield-holder"
                                                            >
                                                                <span className="unit-style"></span>
                                                                <input
                                                                    placeholder="Reference No"
                                                                    type="text"
                                                                    className="modal-input input-text"
                                                                    value={additionalDetails?.referenceNumber}
                                                                    onChange={(e) => {
                                                                        updateAdditionalDetails({
                                                                            referenceNumber: e.target.value,
                                                                        });
                                                                        checkButtonStatus();
                                                                    }}
                                                                />
                                                                {showError(
                                                                    'Reference No is required',
                                                                    additionalDetails?.referenceNumber
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Booking Contract No.
                                                        <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <div className="total-term-wrapper">
                                                            <div
                                                                style={{ width: '100%' }}
                                                                className="modal-input-layout-item total-terms-data-holder textfield-holder"
                                                            >
                                                                <span className="unit-style"></span>
                                                                <input
                                                                    placeholder="Booking Contract No"
                                                                    type="text"
                                                                    className="modal-input input-text"
                                                                    value={additionalDetails?.bookingContractNo}
                                                                    onChange={(e) => {
                                                                        updateAdditionalDetails({
                                                                            bookingContractNo: e.target.value,
                                                                        });
                                                                        checkButtonStatus();
                                                                    }}
                                                                />
                                                                {showError(
                                                                    'Booking Contract No is required',
                                                                    additionalDetails?.bookingContractNo
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {contractType === 'FAK' &&
                                        profileType === 'forwarder' &&
                                        (bpStatus === 'Draft_Booking_Proposal' ||
                                            bpStatus === 'Rejected_by_Forwarder' ||
                                            bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                            <>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Total Origin Cost (Buy)
                                                        {(bpStatus === 'Rejected_by_Forwarder' ||
                                                            bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                            bpStatus === 'Draft_Booking_Proposal') && (
                                                            <span className="mandatory-field-text">*</span>
                                                        )}
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <div className="total-term-wrapper">
                                                            <div
                                                                style={{ width: '40%' }}
                                                                className="modal-input-layout-item total-terms-data-holder textfield-holder"
                                                            >
                                                                <span className="unit-style"></span>
                                                                <input
                                                                    placeholder="Enter Total Origin Cost"
                                                                    type="number"
                                                                    step="any"
                                                                    min="0"
                                                                    className="modal-input input-text"
                                                                    value={additionalDetails?.buytotalOriginCost}
                                                                    onChange={(e) => {
                                                                        updateAdditionalDetails({
                                                                            buytotalOriginCost: +parseFloat(
                                                                                e.target.value
                                                                            ),
                                                                        });
                                                                        checkButtonStatus();
                                                                    }}
                                                                />
                                                                {(bpStatus === 'Rejected_by_Forwarder' ||
                                                                    bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                                    bpStatus === 'Draft_Booking_Proposal') &&
                                                                    showError(
                                                                        'Total Origin Cost (Buy) is required',
                                                                        additionalDetails?.buytotalOriginCost
                                                                    )}
                                                                {/* {showError(
                                                                    'Total Origin Cost (Buy) is required',
                                                                    additionalDetails?.buytotalOriginCost
                                                                )} */}
                                                            </div>
                                                            <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                                <SearchableSelectBox
                                                                    data={convertDataForSelect(
                                                                        currency,
                                                                        'code',
                                                                        'code'
                                                                    )}
                                                                    placeholder="Enter currency to select"
                                                                    setStateValue={(currencyCode: string) => {
                                                                        updateAdditionalDetails({
                                                                            buyCurrencyCode: currencyCode,
                                                                        });
                                                                    }}
                                                                    onInputChangeFn={checkButtonStatus}
                                                                    stateValue={additionalDetails?.buyCurrencyCode}
                                                                    fieldName="currencySell"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                />
                                                                {(bpStatus === 'Rejected_by_Forwarder' ||
                                                                    bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                                    bpStatus === 'Draft_Booking_Proposal') &&
                                                                    showError(
                                                                        'Currency is required',
                                                                        additionalDetails?.buyCurrencyCode
                                                                    )}
                                                                {/* {showError(
                                                                    'Currency is required',
                                                                    additionalDetails?.buyCurrencyCode
                                                                )} */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label htmlFor="name" className="modal-input-label">
                                                        Quotation (Buy)
                                                        {(bpStatus === 'Rejected_by_Forwarder' ||
                                                            bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                            bpStatus === 'Draft_Booking_Proposal') && (
                                                            <span className="mandatory-field-text">*</span>
                                                        )}
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <div className="browse-btn-wrapper">
                                                            <input
                                                                type={'file'}
                                                                id={'quotationFile'}
                                                                name={'quotationFile'}
                                                                accept={
                                                                    '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                }
                                                                className="browse-input"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    (e.target as HTMLInputElement).value = '';
                                                                }}
                                                                onChange={(e) => {
                                                                    // eslint-disable-next-line no-undefined
                                                                    // formik.setFieldError(e.target.name, undefined);
                                                                    fileUpload(e);
                                                                }}
                                                            ></input>
                                                            <button
                                                                className="app-btn app-btn-secondary icon-button browse-btn"
                                                                title="Upload file"
                                                                type="button"
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                }}
                                                            >
                                                                <svg className="svg-icon plus-icon">
                                                                    <use xlinkHref="#plusIcon">
                                                                        <title>Upload File</title>
                                                                    </use>
                                                                </svg>
                                                                <span className="button-text upload-btn">
                                                                    Upload File
                                                                </span>
                                                            </button>
                                                            {validation &&
                                                            isSaveClicked &&
                                                            (bpStatus === 'Rejected_by_Forwarder' ||
                                                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                                bpStatus === 'Draft_Booking_Proposal') ? (
                                                                <div className="error-text">{validation}</div>
                                                            ) : null}
                                                        </div>

                                                        <div className="heading-type-1 file-size">
                                                            <p>{fileUploadInfoText}</p>
                                                            <p
                                                                dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}
                                                            ></p>
                                                        </div>
                                                        {file ? (
                                                            // && !formik.errors?.quotationFile
                                                            <div className="content-with-icon  attachment-holder">
                                                                <button
                                                                    type="button"
                                                                    className="app-btn "
                                                                    title="Attach file"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                >
                                                                    <svg className="svg-icon file-attachment-icon ">
                                                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                                                        <title>Attach file</title>
                                                                    </svg>
                                                                </button>
                                                                <div>
                                                                    {/* file-uploaded class to higlight data with blue color */}
                                                                    <a
                                                                        className="description-type-1 file-uploaded"
                                                                        title="File"
                                                                    >
                                                                        {file?.name?.length > 20
                                                                            ? `${file?.name?.slice(0, 20)}...`
                                                                            : file?.name}
                                                                    </a>
                                                                    <div className="heading-type-1">
                                                                        {getFileSize(file?.size)}
                                                                    </div>
                                                                </div>
                                                                <a
                                                                    href="#"
                                                                    className="file-remove-link"
                                                                    title="Remove"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        removeFile();
                                                                    }}
                                                                >
                                                                    Remove
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </>
                                        )}

                                    {contractType === 'NAC' ? null : (
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Total Origin Cost (Sell)
                                                {bpStatus !== 'Rejected_by_Forwarder' &&
                                                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                    bpStatus !== 'Draft_Booking_Proposal' && (
                                                        <span className="mandatory-field-text">*</span>
                                                    )}
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <div className="total-term-wrapper">
                                                    <div
                                                        style={{ width: '40%' }}
                                                        className="modal-input-layout-item total-terms-data-holder textfield-holder"
                                                    >
                                                        <span className="unit-style"></span>
                                                        <input
                                                            placeholder="Enter Total Origin Cost"
                                                            type="number"
                                                            step="any"
                                                            min="0"
                                                            className="modal-input input-text"
                                                            value={additionalDetails?.sellTotOriginCost}
                                                            onChange={(e) => {
                                                                updateAdditionalDetails({
                                                                    sellTotOriginCost: +parseFloat(e.target.value),
                                                                });
                                                                checkButtonStatus();
                                                            }}
                                                        />
                                                        {bpStatus !== 'Rejected_by_Forwarder' &&
                                                            bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                            bpStatus !== 'Draft_Booking_Proposal' &&
                                                            showError(
                                                                'Total Origin Cost (Sell) is required',
                                                                additionalDetails?.sellTotOriginCost
                                                            )}
                                                        {/* {showError(
                                                            'Total Origin Cost (Sell) is required',
                                                            additionalDetails?.sellTotOriginCost
                                                        )} */}
                                                    </div>
                                                    <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                        <SearchableSelectBox
                                                            data={convertDataForSelect(currency, 'code', 'code')}
                                                            placeholder="Enter currency to select"
                                                            setStateValue={(currencyCode: string) => {
                                                                updateAdditionalDetails({
                                                                    sellCurrency: currencyCode,
                                                                });
                                                            }}
                                                            onInputChangeFn={checkButtonStatus}
                                                            stateValue={additionalDetails?.sellCurrency}
                                                            fieldName="currencySell"
                                                            menuPortalTarget={document.getElementById('outside_div')}
                                                        />
                                                        {bpStatus !== 'Rejected_by_Forwarder' &&
                                                            bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                            bpStatus !== 'Draft_Booking_Proposal' &&
                                                            showError(
                                                                'Currency is required',
                                                                additionalDetails?.sellCurrency
                                                            )}
                                                        {/* {showError(
                                                            'Currency is required',
                                                            additionalDetails?.sellCurrency
                                                        )} */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <div id="name_div" className=" modal-input-holder ">
                                        <div className="modal-label-pill-container">
                                            <label htmlFor="name" className="modal-input-label">
                                                Quotation {contractType === 'NAC' ? '' : '(Sell)'}
                                                {bpStatus !== 'Rejected_by_Forwarder' &&
                                                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                    bpStatus !== 'Draft_Booking_Proposal' && (
                                                        <span className="mandatory-field-text">*</span>
                                                    )}
                                            </label>
                                            <span className="btn-pills container-pill disable-pill">
                                                {quotation?.length === 1 ? (
                                                    <span className="pill-text">
                                                        {quotation?.length} Quotation found
                                                    </span>
                                                ) : (
                                                    <span className="pill-text">
                                                        {quotation?.length} Quotations found
                                                    </span>
                                                )}
                                            </span>
                                        </div>
                                        <div className="modal-input-layout-item">
                                            <div
                                                className={`dropdown-wrap download-dropdown modal-dropdown ${
                                                    showQuotation ? 'dropdown-open' : ''
                                                } search-toast-content-holder modal-dropdown-btn quotation-dropdown`}
                                            >
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        setShowQuotation((prev) => !prev);
                                                        setShowCurrency(false);
                                                    }}
                                                    className="menu-btn app-btn select-modal"
                                                    type="button"
                                                >
                                                    {additionalDetails?.sellQuotationName ? (
                                                        <span className="button-text">
                                                            {`${additionalDetails?.sellQuotationName}, ${additionalDetails?.sellQuotationVersion}`}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">Select Quotation</span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use href="#downArrow">
                                                                <title>Expand row</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {quotation?.length === 0 ? (
                                                        <div className="no-data-content" style={{ margin: '5px 0' }}>
                                                            <p className="no-content-message-detail">
                                                                No quotations available. Please add a valid quotation to
                                                                the
                                                            </p>
                                                            <Link
                                                                className="add-content-link"
                                                                style={{ textDecoration: 'underline' }}
                                                                to={`/profile/clients/${clientId}/contract_details`}
                                                                onClick={() =>
                                                                    localStorage.setItem('clientName', clientName ?? '')
                                                                }
                                                            >
                                                                Client Profile
                                                            </Link>
                                                        </div>
                                                    ) : null}
                                                    {quotation.map((res: BpQuotationList, index: number) => (
                                                        <a
                                                            onClick={() => {
                                                                updateAdditionalDetails({
                                                                    sellQuotationName: res.name,
                                                                    sellQuotationVersion: res.version,
                                                                    sellQuotationId: res.id,
                                                                });
                                                                checkButtonStatus();
                                                            }}
                                                            key={index}
                                                            className={`menu-item app-btn ${
                                                                additionalDetails?.sellQuotationName === res.name &&
                                                                additionalDetails?.sellQuotationVersion === res.version
                                                                    ? 'active'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {res.name}, {res.version}
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                        {bpStatus !== 'Rejected_by_Forwarder' &&
                                            bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                            bpStatus !== 'Draft_Booking_Proposal' &&
                                            showError(
                                                `Quotation ${contractType === 'NAC' ? '' : '(Sell)'} is required`,
                                                additionalDetails?.sellQuotationName
                                            )}
                                        {/* {showError(
                                            `Quotation ${contractType === 'NAC' ? '' : '(Sell)'} is required`,
                                            additionalDetails?.sellQuotationName
                                        )} */}
                                    </div>

                                    {isTriangleShipment &&
                                    profileType === 'forwarder' &&
                                    (bpStatus === 'Awaiting_Forwarder_Approval' ||
                                        bpStatus === 'Draft_Booking_Proposal') ? (
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Destination Agent
                                                <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <SearchableSelectBox
                                                    data={destinationAgents}
                                                    placeholder="Enter Agent Name to select"
                                                    setStateValue={setAgentCode}
                                                    stateValue={agentCode}
                                                    onInputChangeFn={checkButtonStatus}
                                                    fieldName="destinationAgentCode"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                />
                                            </div>
                                            {showError('Destination Agent is required', agentCode)}
                                        </div>
                                    ) : null}
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => {
                                    disableButton ? closeModal() : setCancelModalVisibility(true);
                                }}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Save"
                                // disabled={disableButton}
                                onClick={() => {
                                    setIsSaveClicked(true);
                                    submitAdditionalDetails(type);
                                }}
                            >
                                <span className="button-text footer-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
