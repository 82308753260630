/**
 * this function will convert object {key: value} to
 * Url parameters, combined with &
 *
 * For example, input {shipment: 123, container: abc}
 * output - shipment=123&container=abc
 */
export const convertObjectToUrlParams = (object: { [key: string]: string }) => {
    const params = [];

    for (const key in object) {
        params.push(`${encodeURIComponent(key)}=${encodeURIComponent(object[key])}`);
    }

    return params.join('&');
};
