export const checkUncheckSelectSingle = (
    index: number,
    subLineIndex: number,
    addPoList: any,
    originalData: any,
    setAllPoData: any,
    type: boolean
) => {
    if (typeof index === 'number' && typeof subLineIndex === 'number') {
        const copy = JSON.parse(JSON.stringify(addPoList));
        if (
            copy[index].bookingProposalSubPOLineList[subLineIndex]?.remainingQty < 0 &&
            originalData[index].bookingProposalSubPOLineList[subLineIndex]?.remainingQty !== null
        ) {
            return;
        }
        if (type) {
            if (
                copy[index].bookingProposalSubPOLineList[subLineIndex]?.remainingQty === null ||
                copy[index].bookingProposalSubPOLineList[subLineIndex]?.remainingQty ===
                    copy[index].bookingProposalSubPOLineList[subLineIndex]?.qty
            ) {
                copy[index].bookingProposalSubPOLineList[subLineIndex].addedQty =
                    copy[index].bookingProposalSubPOLineList[subLineIndex].qty;
            } else {
                copy[index].bookingProposalSubPOLineList[subLineIndex].addedQty =
                    copy[index].bookingProposalSubPOLineList[subLineIndex].remainingQty;
            }
            copy[index].bookingProposalSubPOLineList[subLineIndex].remainingQty = 0;
        } else {
            if (
                copy[index].bookingProposalSubPOLineList[subLineIndex]?.remainingQty === null ||
                copy[index].bookingProposalSubPOLineList[subLineIndex]?.remainingQty ===
                    copy[index].bookingProposalSubPOLineList[subLineIndex]?.qty
            ) {
                copy[index].bookingProposalSubPOLineList[subLineIndex].addedQty = 0;
                copy[index].bookingProposalSubPOLineList[subLineIndex].remainingQty = null;
            } else {
                copy[index].bookingProposalSubPOLineList[subLineIndex].addedQty = 0;
                copy[index].bookingProposalSubPOLineList[subLineIndex].remainingQty =
                    originalData[index].bookingProposalSubPOLineList[subLineIndex].remainingQty;
            }
        }
        setAllPoData(copy);
    }
};

export const checkUncheckSelectAll = (addPoList: any, originalData: any, setAllPoData: any, type: boolean) => {
    const copy = JSON.parse(JSON.stringify(addPoList));
    for (let i = 0; i < addPoList.length; i++) {
        for (let j = 0; j < addPoList[i].bookingProposalSubPOLineList.length; j++) {
            if (
                copy[i].bookingProposalSubPOLineList[j]?.remainingQty < 0 &&
                originalData[i].bookingProposalSubPOLineList[j]?.remainingQty !== null
            ) {
                return;
            }
            if (type) {
                if (
                    copy[i].bookingProposalSubPOLineList[j]?.remainingQty === null ||
                    copy[i].bookingProposalSubPOLineList[j]?.remainingQty ===
                        copy[i].bookingProposalSubPOLineList[j]?.qty
                ) {
                    copy[i].bookingProposalSubPOLineList[j].addedQty = copy[i].bookingProposalSubPOLineList[j].qty;
                } else {
                    copy[i].bookingProposalSubPOLineList[j].addedQty =
                        copy[i].bookingProposalSubPOLineList[j].remainingQty;
                }
                copy[i].bookingProposalSubPOLineList[j].remainingQty = 0;
            } else {
                if (
                    copy[i].bookingProposalSubPOLineList[j]?.remainingQty === null ||
                    copy[i].bookingProposalSubPOLineList[j]?.remainingQty ===
                        copy[i].bookingProposalSubPOLineList[j]?.qty
                ) {
                    copy[i].bookingProposalSubPOLineList[j].addedQty = 0;
                    copy[i].bookingProposalSubPOLineList[j].remainingQty = null;
                } else {
                    copy[i].bookingProposalSubPOLineList[j].addedQty = 0;
                    copy[i].bookingProposalSubPOLineList[j].remainingQty =
                        originalData[i].bookingProposalSubPOLineList[j].remainingQty;
                }
            }
        }
    }
    setAllPoData(copy);
};

export const checkUncheckSelectedIds = (
    addPoList: any,
    originalData: any,
    setAllPoData: any,
    type: boolean,
    index: number
) => {
    const copy = JSON.parse(JSON.stringify(addPoList));
    for (let j = 0; j < addPoList[index].bookingProposalSubPOLineList.length; j++) {
        if (
            copy[index].bookingProposalSubPOLineList[j]?.remainingQty < 0 &&
            originalData[index].bookingProposalSubPOLineList[j]?.remainingQty !== null
        ) {
            return;
        }
        if (type) {
            if (
                copy[index].bookingProposalSubPOLineList[j]?.remainingQty === null ||
                copy[index].bookingProposalSubPOLineList[j]?.remainingQty ===
                    copy[index].bookingProposalSubPOLineList[j]?.qty
            ) {
                copy[index].bookingProposalSubPOLineList[j].addedQty = copy[index].bookingProposalSubPOLineList[j].qty;
            } else {
                copy[index].bookingProposalSubPOLineList[j].addedQty =
                    copy[index].bookingProposalSubPOLineList[j].remainingQty;
            }
            copy[index].bookingProposalSubPOLineList[j].remainingQty = 0;
        } else {
            if (
                copy[index].bookingProposalSubPOLineList[j]?.remainingQty === null ||
                copy[index].bookingProposalSubPOLineList[j]?.remainingQty ===
                    copy[index].bookingProposalSubPOLineList[j]?.qty
            ) {
                copy[index].bookingProposalSubPOLineList[j].addedQty = 0;
                copy[index].bookingProposalSubPOLineList[j].remainingQty = null;
            } else {
                copy[index].bookingProposalSubPOLineList[j].addedQty = 0;
                copy[index].bookingProposalSubPOLineList[j].remainingQty =
                    originalData[index].bookingProposalSubPOLineList[j].remainingQty;
            }
        }
    }

    setAllPoData(copy);
};
