/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface datesPoDataDetails {
    poId: string;
    poNumber: string;
    orderDate: string;
    startShipWindowDate: string;
    endShipWindowDate: string;
    projectedEtaDate: string;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

interface datesPoData {
    status: number;
    message: string;
    info: datesPoDataDetails;
    data: any;
}

interface DatesPoState {
    isLoading: boolean;
    datesPo?: datesPoData;
    datesPoSuccess: any;
    datesPoError: any;
    isError?: boolean;
    id?: number;
}

const initialState: DatesPoState = {
    isLoading: true,
    datesPo: undefined,
    datesPoSuccess: undefined,
    datesPoError: undefined,
    isError: false,
    id: undefined,
};

export const datesPoSlice = createSlice({
    name: 'datesPo',
    initialState,
    reducers: {
        fetchDatesPoList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchDatesPoListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.datesPo = action.payload;
        },
        fetchDatesPoListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.datesPo = action.payload;
        },
        createDatesPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        createDatesPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.datesPoSuccess = action.payload;
            state.datesPoError = {};
        },
        createDatesPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.datesPoSuccess = {};
            state.datesPoError = action.payload;
        },
        updateDatesPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateDatesPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.datesPoSuccess = action.payload;
            state.datesPoError = {};
        },
        updateDatesPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.datesPoSuccess = {};
            state.datesPoError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.datesPoError = undefined;
            state.datesPoSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchDatesPoList,
    fetchDatesPoListSuccess,
    fetchDatesPoListFailed,
    createDatesPo,
    createDatesPoSuccess,
    createDatesPoFailure,
    updateDatesPo,
    updateDatesPoSuccess,
    updateDatesPoFailure,
    isLoadingFn,
    clearFailure,
} = datesPoSlice.actions;

export const selectDatesPoLoading = (state: RootState) => state.datesPoData.isLoading;
export const selectDatesPo = (state: RootState) => state.datesPoData.datesPo;
export const selectDatesPoSuccess = (state: RootState) => state.datesPoData.datesPoSuccess;
export const selectDatesPoError = (state: RootState) => state.datesPoData.datesPoError;
export const selectDatesPoIsError = (state: RootState) => state.datesPoData.isError;

export default datesPoSlice.reducer;
