import React, { useCallback, useEffect, useState } from 'react';
import './booking-additional-info.scss';

import { useAppDispatch, useAppSelector } from '../../../hooks';

import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import ContractDropdownList from '../../Common/contractDropdown';
import { getContractList } from 'src/store/reducers/bookingProposalAir/addSchedule';
import { overrideShipmentDetails } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { fetchOverrideShipments } from 'src/store/reducers/bookingProposal/overrideAdditionalReducer';
import { fetchOvrContractDetails } from 'src/store/reducers/bookingProposal/additionalDetailsNacReducer';
import ContractViewModal from 'src/components/common/ContractViewModal';
import { selectAdditionalDetailsLoading } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';

interface AdditionalInfoProps {
    headerData: any;
    userProfile: { profileType: string; userName: string };
    bpId: string;
    isShipmentOverride: boolean;
    setIsShipmentOverride: (value: boolean) => void;
}

const Index: React.FC<AdditionalInfoProps> = ({
    // reason,
    headerData,
    userProfile,
    bpId,
    isShipmentOverride,
    setIsShipmentOverride,
}) => {
    const dispatch = useAppDispatch();
    const [ref, setRef] = useState('');
    const [contractType, setContractType] = useState('FAK');
    const [contractVersion, setContractVersion] = useState('');
    const [errorValidation, seterrorValidation] = useState(false);
    const [contract, setContract] = useState('');
    const [_containerModesModified, setContainerModesModified] = useState<any[]>([]);
    const { clientCode, originPort, destinationPort } = useSelector((state: RootState) => state.bpHeaderData);
    const { contractDetails, ovrContractDetails: contractDetailsData } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    const { contractList } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const {} = useSelector((state: RootState) => state.additionalDetailsData);
    const { overrideShipments } = useSelector((state: RootState) => state.overrideShipments);
    const [showContractViewModal, setShowContractViewModal] = useState(false);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);

    useEffect(() => {
        if (overrideShipments?.length > 0)
            dispatch(fetchOvrContractDetails({ contractId: overrideShipments[0].BP_OVR_ContractId }));
    }, [overrideShipments]);

    useEffect(() => {
        if (contractDetails?.shippingLineId && contractList.length === 0) {
            // const originPortCode = originPort.slice(originPort.indexOf('(') + 1, originPort.indexOf(')'));
            // const destinationPortCode = destinationPort.slice(
            //     destinationPort.indexOf('(') + 1,
            //     destinationPort.indexOf(')')
            // );
            const originPortCode = originPort.match(/.*\(([^)]+)\)/)?.[1];
            const destinationPortCode = destinationPort.match(/.*\(([^)]+)\)/)?.[1];

            const payload = {
                slPk: contractDetails?.shippingLineId,
                portPair: `${originPortCode} > ${destinationPortCode}`,
                clientId: clientCode,
            };
            // dispatch(fetchContract(payload));
            dispatch(getContractList(payload));
        }
    }, [contractDetails]);

    const checkContractType = () => {
        for (let i = 0; i < contractList.length; i++) {
            if (contractList[i].SC_PK === contract) {
                if (contractList[i].ClientsPrimary.includes(clientCode)) return 'P';
                else return 'S';
            }
        }
        return '';
    };

    const getContractName = () => {
        for (let i = 0; i < contractList.length; i++) {
            if (contractList[i].SC_PK === contract) {
                return contractList[i].SC_ContractName;
            }
        }
        return '';
    };

    useEffect(() => {
        if (contract && contract === 'FAK') {
            setContractType('FAK');
        } else if (contract && contract !== 'FAK') {
            setContractType('NAC');
        }
    }, [contract]);

    const submitOverrideShipment = async () => {
        const data = {
            BP_PK: bpId,
            BP_OVR_ReferenceNumber: ref,
            BP_OVR_ContractId: contract,
            BP_OVR_ContractName: getContractName(),
            BP_OVR_ContractType: contractType,
            BP_OVR_ContractVersion: checkContractType(),
            BP_OVR_Status: 'Approved',
        };
        const reason = '';
        await overrideShipmentDetails({ bpId, reason, data });
        dispatch(fetchOverrideShipments({ bpId: bpId }));
    };

    return (
        <div className="card details-box custom">
            <div className="card-head-holder add-btn-holder">
                <div className="card-head-btn-edit-holder">
                    <h6 className="details-box-heading-2">Shipment Override Details</h6>
                </div>
            </div>

            {overrideShipments?.length > 0 && !overrideShipments[0].BP_OVR_ContractId ? (
                <div className="card-detail-col card-col-shipments">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Reference No.</h6>
                        <div className="details-box-content">
                            {true ? (
                                <input
                                    placeholder="Enter Reference No."
                                    type="text"
                                    className="modal-input input-text with-border"
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setRef(value);
                                    }}
                                    style={{ height: '36px' }}
                                    value={ref}
                                />
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>

                    <div className="details-col box-content-holder">
                        <div className="details-box-content">
                            {true ? (
                                <ContractDropdownList
                                    additionalOption={[{ value: 'FAK', label: 'FAK' }]}
                                    contract={contract}
                                    setContract={setContract}
                                    clientCode={clientCode}
                                    isErrorMessageShow={errorValidation && (contract === '' || contract === undefined)}
                                />
                            ) : (
                                '-'
                            )}
                        </div>
                    </div>

                    {contract && (
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Contract Type</h6>
                            <div className="details-box-content">
                                <div className="status-ui nac-status nac-p">
                                    <div className="status-nac-left">{contract === 'FAK' ? 'FAK' : 'NAC' || '-'}</div>

                                    {contract !== 'FAK' && (
                                        <div
                                            className={`status-nac-right ${
                                                checkContractType() === 'P' ? 'p-status' : 's-status'
                                            }`}
                                        >
                                            {checkContractType() || '-'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {contract && (
                        <div className="details-col box-content-holder">
                            <div className="details-box-content">
                                <button
                                    title="Save"
                                    disabled={false}
                                    className="app-btn app-btn-primary"
                                    onClick={() => {
                                        submitOverrideShipment();
                                    }}
                                >
                                    <span className="button-text footer-button-text">Save</span>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            ) : (
                overrideShipments?.length > 0 && (
                    <div className="card-detail-col card-col-shipments">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Reference No</h6>
                            <div className="details-box-content">
                                {overrideShipments[0].BP_OVR_ReferenceNumber || '-'}
                            </div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Contract Type</h6>
                            <div className="details-box-content">
                                <div className="status-ui nac-status nac-p">
                                    <div className="status-nac-left">
                                        {overrideShipments[0].BP_OVR_ContractType || '-'}
                                    </div>

                                    {overrideShipments[0].BP_OVR_ContractVersion && (
                                        <div
                                            className={`status-nac-right ${
                                                overrideShipments[0].BP_OVR_ContractVersion === 'P'
                                                    ? 'p-status'
                                                    : 's-status'
                                            }`}
                                        >
                                            {overrideShipments[0].BP_OVR_ContractVersion || '-'}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>

                        {overrideShipments[0]?.BP_OVR_ContractType === 'NAC' && (
                            <>
                                {userProfile.profileType !== 'client' && (
                                    <div className="details-col box-content-holder">
                                        <h6 className="details-box-heading">Contract</h6>
                                        <div className="details-box-content">
                                            {(userProfile.profileType === 'agent' ||
                                                userProfile.profileType === 'forwarder') && (
                                                <a
                                                    href="javascript:void(0)"
                                                    // target="_blank"
                                                    className="details-box-content link-url"
                                                    onClick={() => {
                                                        setShowContractViewModal(true);
                                                    }}
                                                >
                                                    {overrideShipments[0]?.BP_OVR_ContractName}
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                )
            )}
            {showContractViewModal ? (
                <ContractViewModal
                    data={contractDetailsData}
                    isLoading={isLoading}
                    setShowContractViewModal={setShowContractViewModal}
                ></ContractViewModal>
            ) : null}
        </div>
    );
};

export default Index;
