import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { gateInDateValidation } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { removeSailing } from '../../../store/reducers/bookingProposal/sailingReducer';
import '../../BookingProposal/SelectedSailingSchelude/unavailable-modal.scss';

interface PropsModal {
    setdisplayModal: any;
}
const Index: React.FC<PropsModal> = ({ setdisplayModal }) => {
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    return (
        <div className="app-modal">
            <div className="modal-content-holder unavailable-modal">
                <div className="modal-header">
                    <div className="title">Sailing Schedule Unavailable</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            setdisplayModal(false);
                            if (!window.location.href.includes('SailingSelectionDetails')) {
                                dispatch(removeSailing(id));
                                dispatch(gateInDateValidation(false));
                                setTimeout(() => {
                                    navigate(`/lclbooking_list/${id}/SailingSelectionDetails`);
                                    //window.location.replace('./SailingSelectionDetails');
                                }, 1000);
                            }
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <p className="main-heading-content">
                            The selected sailing schedule is no longer available as the gate in time has passed. Please
                            select a different schedule to continue with the Booking Process
                        </p>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        form="my-form"
                        title="OK"
                        onClick={() => {
                            setdisplayModal(false);
                            if (!window.location.href.includes('SailingSelectionDetails')) {
                                dispatch(removeSailing(id));
                                dispatch(gateInDateValidation(false));
                                setTimeout(() => {
                                    navigate(`/lclbooking_list/${id}/SailingSelectionDetails`);
                                    //window.location.replace('./SailingSelectionDetails');
                                }, 1000);
                            }
                        }}
                    >
                        <span className="button-text footer-button-text">OK</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
