import { createSlice } from '@reduxjs/toolkit';
import { FilterList, insightTabList } from 'src/pages/Dashboard/Insights/enums';

interface ActiveOrderKeyValues {
    count: number;
    dateFrom: string;
    dateTo: string;
    pointName: string;
}

interface DashboardActiveOrderList {
    count: number;
    key1: ActiveOrderKeyValues;
    key2: ActiveOrderKeyValues;
    key3: ActiveOrderKeyValues;
    key4: ActiveOrderKeyValues;
    key5: ActiveOrderKeyValues;
    key6: ActiveOrderKeyValues;
    key7: ActiveOrderKeyValues;
    key8: ActiveOrderKeyValues;
    key9: ActiveOrderKeyValues;
    key10: ActiveOrderKeyValues;
    key11: ActiveOrderKeyValues;
    key12: ActiveOrderKeyValues;
}
interface DashboardActiveOrdersData {
    total: DashboardActiveOrderList | any;
    po: DashboardActiveOrderList | any;
    inbound: DashboardActiveOrderList | any;
}
interface DashboardActiveBookingsData {
    total: DashboardActiveOrderList | any;
    poBookings: DashboardActiveOrderList | any;
    aoBookings: DashboardActiveOrderList | any;
}
interface QuickLinksState {
    dashboardActiveOrders: DashboardActiveOrdersData | any;
    dashboardActiveBookings: DashboardActiveBookingsData | any;
    dashboardActiveOrdersExpanded: any;
    dashboardActiveBookingsExpanded: any;
    isDasboardActiveOrdersLoading: boolean;
    isDasboardActiveBookingsLoading: boolean;
    isDasboardActiveOrdersExpandedLoading: boolean;
    isDasboardActiveBookingsExpandedLoading: boolean;
    error: any;
    insightsMode: string[];
    insightsDestination: string[];
    insightsForwarded: string;
    insightsSelectedType: string | null;
    insightsSelectedTypeFilter: string | null;
    insightsDurationRange: string;
    insightsMainPage: string;
    insightTab: string;
    insightsSelectedTypeFilterIndex: number;
    favInsightsMode: string[];
    favInsightsDestination: string[];
    favInsightsForwarded: string;
    favInsightsSelectedType: string | null;
    favInsightsSelectedTypeFilter: string | null;
    insightsFavMainPage: string;
}

const initialState: QuickLinksState = {
    dashboardActiveOrders: {},
    dashboardActiveBookings: {},
    dashboardActiveOrdersExpanded: [],
    dashboardActiveBookingsExpanded: [],
    isDasboardActiveOrdersLoading: false,
    isDasboardActiveBookingsLoading: false,
    isDasboardActiveOrdersExpandedLoading: false,
    isDasboardActiveBookingsExpandedLoading: false,
    error: {},
    insightsMode: [],
    insightsDestination: [],
    insightsForwarded: '',
    insightsSelectedType: '',
    insightsSelectedTypeFilter: '',
    insightsDurationRange: FilterList.Days,
    insightsMainPage: '',
    insightTab: insightTabList.all,
    insightsSelectedTypeFilterIndex: 0,
    favInsightsMode: [],
    favInsightsDestination: [],
    favInsightsForwarded: '',
    favInsightsSelectedType: '',
    favInsightsSelectedTypeFilter: '',
    insightsFavMainPage: '',
};

export const quickLinkSlice = createSlice({
    name: 'insights',
    initialState,
    reducers: {
        fetchDashboardActiveOrders: (state, action) => {
            if (!action.payload.aggregateKey) {
                state.isDasboardActiveOrdersLoading = true;
            }
        },
        fetchDashboardActiveOrdersSuccess: (state, action) => {
            state.isDasboardActiveOrdersLoading = false;
            state.dashboardActiveOrders = action.payload;
        },
        fetchDashboardActiveOrdersError: (state, _action) => {
            state.isDasboardActiveOrdersLoading = false;
        },
        clearDashboardActiveOrders: (state) => {
            state.isDasboardActiveOrdersLoading = false;
            state.dashboardActiveOrders = {};
        },

        fetchDashboardActiveOrdersExpanded: (state, action) => {
            if (!action.payload.aggregateKey) {
                state.isDasboardActiveOrdersExpandedLoading = true;
            }
        },
        fetchDashboardActiveOrdersSuccessExpanded: (state, action) => {
            state.isDasboardActiveOrdersExpandedLoading = false;
            state.dashboardActiveOrdersExpanded = action.payload;
        },
        fetchDashboardActiveOrdersErrorExpanded: (state, _action) => {
            state.isDasboardActiveOrdersExpandedLoading = false;
        },
        clearDashboardActiveOrdersExpanded: (state) => {
            state.isDasboardActiveOrdersExpandedLoading = false;
            state.dashboardActiveOrdersExpanded = {};
        },
        fetchDashboardActiveBookings: (state, action) => {
            if (!action.payload.aggregateKey) {
                state.isDasboardActiveBookingsLoading = true;
            }
        },
        fetchDashboardActiveBookingsSuccess: (state, action) => {
            state.isDasboardActiveBookingsLoading = false;
            state.dashboardActiveBookings = action.payload;
        },
        fetchDashboardActiveBookingsError: (state, _action) => {
            state.isDasboardActiveBookingsLoading = false;
        },
        clearDashboardBookingsOrders: (state) => {
            state.isDasboardActiveBookingsLoading = false;
            state.dashboardActiveBookings = {};
        },
        fetchDashboardActiveBookingsExpanded: (state, action) => {
            if (!action.payload.aggregateKey) {
                state.isDasboardActiveBookingsExpandedLoading = true;
            }
        },
        fetchDashboardActiveBookingsSuccessExpanded: (state, action) => {
            state.isDasboardActiveBookingsExpandedLoading = false;
            state.dashboardActiveBookingsExpanded = action.payload;
        },
        fetchDashboardActiveBookingsErrorExpanded: (state, _action) => {
            state.isDasboardActiveBookingsExpandedLoading = false;
        },
        clearDashboardActiveBookingsExpanded: (state) => {
            state.isDasboardActiveBookingsExpandedLoading = false;
            state.dashboardActiveBookingsExpanded = {};
        },
        setInsightsMode: (state, action) => {
            state.insightsMode = action.payload;
        },
        clearInsightMode: (state) => {
            state.insightsMode = [];
        },
        setInsightsDestination: (state, action) => {
            state.insightsDestination = action.payload;
        },
        clearInsightDestination: (state) => {
            state.insightsDestination = [];
        },
        setInsightsDurationRange: (state, action) => {
            state.insightsDurationRange = action.payload;
        },
        setInsightsForwarded: (state, action) => {
            state.insightsForwarded = action.payload;
        },
        setInsightsSelectedType: (state, action) => {
            state.insightsSelectedType = action.payload;
        },
        setInsightsSelectedTypeFilter: (state, action) => {
            state.insightsSelectedTypeFilter = action.payload;
        },
        setInsightsMainPage: (state, action) => {
            state.insightsMainPage = action.payload;
        },
        setInsightsFavMainPage: (state, action) => {
            state.insightsFavMainPage = action.payload;
        },
        setInsightTab: (state, action) => {
            state.insightTab = action.payload;
        },
        setSelectedTypeFilterIndex: (state, action) => {
            state.insightsSelectedTypeFilterIndex = action.payload;
        },
        setAllFavFilterData: (state, action) => {
            state.favInsightsMode = action.payload.favInsightsMode;
            state.favInsightsDestination = action.payload.favInsightsDestination;
            state.favInsightsForwarded = action.payload.favInsightsForwarded;
            state.favInsightsSelectedType = action.payload.favInsightsSelectedType;
            state.favInsightsSelectedTypeFilter = action.payload.favInsightsSelectedTypeFilter;
            state.insightsFavMainPage = action.payload.insightsFavMainPage;
        },
    },
});

export const {
    fetchDashboardActiveOrders,
    fetchDashboardActiveOrdersSuccess,
    fetchDashboardActiveOrdersError,
    clearDashboardActiveOrders,
    fetchDashboardActiveOrdersExpanded,
    fetchDashboardActiveOrdersSuccessExpanded,
    fetchDashboardActiveOrdersErrorExpanded,
    clearDashboardActiveOrdersExpanded,
    fetchDashboardActiveBookings,
    fetchDashboardActiveBookingsSuccess,
    fetchDashboardActiveBookingsError,
    clearDashboardBookingsOrders,
    fetchDashboardActiveBookingsExpanded,
    fetchDashboardActiveBookingsSuccessExpanded,
    fetchDashboardActiveBookingsErrorExpanded,
    clearDashboardActiveBookingsExpanded,
    setInsightsMode,
    clearInsightMode,
    setInsightsDestination,
    clearInsightDestination,
    setInsightsDurationRange,
    setInsightsForwarded,
    setInsightsSelectedType,
    setInsightsSelectedTypeFilter,
    setInsightsMainPage,
    setInsightTab,
    setSelectedTypeFilterIndex,
    setAllFavFilterData,
    setInsightsFavMainPage,
} = quickLinkSlice.actions;

export default quickLinkSlice.reducer;
