import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
// import { portTimeLineFetch } from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import '../lcsBookingTiimeline.scss';
import MaxLimitModal from '../MaxLimitModal';
import { post } from 'src/services/HttpMethods';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { RootState } from 'src/store/reducers';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { lifeCycleSearchCategories } from './Common/constants';

const Index: React.FC = () => {
    const [carrierStatusData, setCarrierStatusData] = useState<any>();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { userName, profileType } = useUserProfile();
    const [showModal, setShowModal] = useState(false);
    const { favList, removeSuccess, addSuccess, isLoading, isError, error } = useSelector(
        (state: RootState) => state.lifecyclesearchFav
    );
    const { selectedData } = useSelector((state: any) => state.lifeCycleSearch);

    const fetchCarrierSidepanelData = async () => {
        try {
            const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
            const response = await post(
                `/api/mgt/tracking/lifecycle/search/carrier?id=${id}&profile=${profileType}`,
                {
                    ...listOfUserOrganisations,
                    loggedInUserName: userName,
                },
                null
            );
            // 47c5ba58-5cb2-4b76-bd3d-0e71a81114a0
            setCarrierStatusData(response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchCarrierSidepanelData();
    }, [id]);

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.carrier,
                    favourite: 'Carrier',
                    number: carrierStatusData?.name,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">Carrier</div>
                <div className="timeline-head-value">{carrierStatusData?.name}</div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="cm-key-pair-details">
                {carrierStatusData?.status && (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Status</div>
                        <div className="cm-key-pair-value">
                            <div
                                className={`status-ui ${
                                    carrierStatusData?.status === 'ACTIVE' ? 'success-status' : 'error-status'
                                }  `}
                            >
                                {carrierStatusData?.status}
                            </div>
                        </div>
                    </div>
                )}
                {carrierStatusData?.tier && (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Tier</div>
                        <div className="cm-key-pair-value">{carrierStatusData?.tier}</div>
                    </div>
                )}
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Contract Status</div>
                    <div className="cm-key-pair-value">{carrierStatusData?.contractStatus}</div>
                </div>
            </div>

            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`Carrier : ${carrierStatusData?.name}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
