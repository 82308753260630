import React, { useEffect } from 'react';
import {
    fetchWorkTrendsList,
    setActiveActivityList,
    WorkTrendsDetails,
} from 'src/store/reducers/dashboard/workTrendsReducer';
import { useDispatch, useSelector } from 'react-redux';
import './workTrendsProgress.scss';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { FilterList } from '../../WorkTrends';
interface Props {
    data: WorkTrendsDetails[];
}
const Index: React.FC<Props> = ({ data }) => {
    const max = Math.max(...data.map((o: WorkTrendsDetails) => o.yAxis));
    const { progressSelectedIndex, currentFilter, userIds } = useSelector((state: RootState) => state.workTrendsData);
    const dispatch = useDispatch();
    const { profileType, userName } = useUserProfile();

    useEffect(() => {
        if (profileType && userName) {
            dispatch(fetchWorkTrendsList({ filter: currentFilter, profile: profileType, userName, userIds }));
        }
    }, [profileType, userName, currentFilter]);

    return (
        <div className="work-trends-progress customscrollbar">
            <ul className="progress-list" style={{ '--maxX': `${max === 0 ? 1 : max}` } as React.CSSProperties}>
                {data.map((res: WorkTrendsDetails, index: number) => (
                    <li
                        key={res.xAxis}
                        onClick={() => {
                            dispatch(setActiveActivityList({ index }));
                        }}
                        className={`progress-list-item ${index === progressSelectedIndex ? 'active' : ''}`}
                    >
                        <div className="progress-bar-holder">
                            <label className="progress-list-label">
                                {currentFilter === FilterList.Days ? res.label : res.expandedLabel || res.xAxis}
                                {currentFilter === FilterList.Weeks ? <span className="sm">({res.label})</span> : null}
                            </label>
                            <div className="progress-bar">
                                <div
                                    className="progress-bar-fill"
                                    style={{ '--xVal': `${res.yAxis}` } as React.CSSProperties}
                                ></div>
                            </div>
                        </div>
                        <span className="progress-bar-value">{res.yAxis}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};
export default Index;
