/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    // fetchClient,
    fetchBlp,
    selectAddAoLoading,
    // selectClientDetails,
    selectBlpDetails,
    saveAo,
    // selectAddAo,
    selectAddAoIsError,
    selectAddAoError,
    selectAddAoSuccess,
    clearFailure,
} from '../../../store/reducers/allocationOrder/addAOReducer';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { error, Position, success } from '../../../utils/toast';
import { Link, useNavigate } from 'react-router-dom';
import { getOrganisationData } from 'src/utils';
import { useUserProfile } from 'src/hooks/useUserProfile';

interface AddAoForm {
    aoNumber: string;
    clientCode: string;
    clientName: string;
    blpAgentCode: string;
    blpAgentName: string;
    createdUser: string;
    createdUserId: string;
}

const Index: React.FC = () => {
    const organisationData = getOrganisationData();
    const { profileType } = useUserProfile();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    // const clientDetails = useAppSelector(selectClientDetails);
    const clientDetails = organisationData?.poClients?.length
        ? organisationData?.poClients.map((res: any) => ({ value: res.orgCode, label: res.orgName, id: res.id }))
        : [];
    const blpDetails = useAppSelector(selectBlpDetails);
    const isLoading = useAppSelector(selectAddAoLoading);
    const isError = useAppSelector(selectAddAoIsError);
    // const addAo = useAppSelector(selectAddAo);
    const addAoSuccess = useAppSelector(selectAddAoSuccess);
    const addAoError = useAppSelector(selectAddAoError);

    const [disableVendor, setDisableVendor] = useState(false);
    const [clientUid, setClientUid] = useState('');
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userName = '';
    let userFullName = '';
    let clientEmailId = '';
    if (userData) {
        const { user } = JSON.parse(userData);
        const { roles } = JSON.parse(userData);
        userName = user?.us_UserName;
        userFullName = user?.us_FirstName + ' ' + user?.us_LastName;
        if (roleIndex) {
            clientEmailId = roles[roleIndex].profile?.toLowerCase() === 'client' ? user?.us_Email : null;
        }
    }

    // useEffect(() => {
    //     dispatch(fetchClient({ userName }));
    // }, [dispatch]);

    const initialValues: AddAoForm = {
        aoNumber: '',
        clientCode: '',
        clientName: '',
        blpAgentCode: '',
        blpAgentName: '',
        createdUser: userFullName,
        createdUserId: userName,
    };

    const AddAoFormSchema = Yup.object().shape({
        aoNumber: Yup.string().required('AO Number is required'),
        clientCode: Yup.string().required('Client Code is required'),
        blpAgentCode: Yup.string().required('BLP Code is required'),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddAoFormSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                dispatch(
                    saveAo({
                        ...values,
                        aoNumber: values.aoNumber?.trim(),
                        clientId: clientUid,
                        clientEmailId,
                        type: 'AO',
                        profileType,
                    })
                );
            }
        },
    });

    useEffect(() => {
        if (isError) {
            error(addAoError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            document?.getElementById('name_div')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else if (addAoSuccess?.status === 200 || addAoSuccess?.status === 201) {
            success(`Allocation orders ${addAoSuccess?.data?.aoNumber} created successfully`, Position.TOP_RIGHT);
            localStorage.setItem('aoNumber', addAoSuccess?.data?.aoNumber);
            localStorage.setItem('aoStatus', 'Draft');
            setTimeout(() => {
                formik.resetForm();
                navigate(`/allocation_orders/${addAoSuccess?.data?.aoId}/basic_details`);
            }, 2000);
            dispatch(clearFailure([]));
        }
    }, [isError, addAoSuccess, addAoError]);

    useEffect(() => {
        formik.setFieldValue('blpAgentCode', '');
        formik.setFieldValue('blpAgentName', '');
        if (formik.values.clientCode) {
            const uid = clientDetails?.find((element: any) => element.value === formik.values.clientCode);
            dispatch(fetchBlp({ clientCode: formik.values.clientCode }));
            setDisableVendor(false);
            setClientUid(uid.id);
            localStorage.setItem('clientName', uid.label);
        } else {
            setDisableVendor(true);
        }
    }, [formik.values.clientCode]);

    useEffect(() => {
        if (!formik.values.blpAgentCode) {
            formik.setFieldValue('blpAgentCode', '');
            formik.setFieldValue('blpAgentName', '');
        }
    }, [formik.values.blpAgentCode]);

    useEffect(() => {
        if (!blpDetails?.data) {
            formik.setFieldValue('blpAgentCode', '');
            formik.setFieldValue('blpAgentName', '');
        }
    }, [blpDetails]);

    useEffect(() => {
        if (clientDetails?.length === 1 && !formik.values.clientCode) {
            formik.setFieldValue('clientCode', clientDetails[0].value);
            formik.setFieldValue('clientName', clientDetails[0].label);
        }
    }, [clientDetails]);

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">
                    No matches found.
                    <br /> BLP linked to the client profile <br />
                    will only be available for selection
                </p>
                <br />
                {/* <Link className="redirection-link" to={`/profile/clients/${clientUid}/vendor_details`}>
                    Go to Vendor Details
                </Link> */}
            </div>
        );
    };

    const clientStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            fontFamily: 'Poppins',
            fontSize: '1.4rem',
            minHeight: '34px',
            height: '34px',
            backgroundColor: '#FFF',
            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #cccccc'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
            }),
        }),
    };
    const blpStyle = {
        control: (base: any, state: any) => ({
            ...base,
            borderRadius: '5px',
            transitionProperty: 'box-shadow,outline-color',
            transitionDuration: '0.3s',
            fontFamily: 'Poppins',
            fontSize: '1.4rem',
            minHeight: '34px',
            height: '34px',
            backgroundColor: disableVendor ? '#e2e3e7' : '#FFF',
            color: disableVendor ? '#737373' : 'hsl(0, 0%, 20%)',
            border: state.isFocused
                ? '1px solid #2028EB'
                : state.isDisabled
                ? '1px solid #C7C9D1'
                : '1px solid #828282',
            boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
            outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
            '&:hover': {
                border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
            },
            ...(state.isDisabled && {
                pointerEvents: 'auto',
                cursor: 'not-allowed',
                backgroundColor: '#e2e3e7',
            }),
        }),
        noOptionsMessage: (base: any) => ({ ...base, minHeight: '210px' }),
    };

    return (
        <>
            <div className="main-wrapper container">
                <div className="main-header-content-holder large-header">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <Link to="/allocation_orders" className="dropdown-arrow">
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>User details</title>
                                    </use>
                                </svg>
                            </Link>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/allocation_orders">
                                    Allocation Orders
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <a href="javascript:void(0)" className="breadcrumb-item">
                                    Create New Allocation Order
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="allocation-order-header-fields">
                        <div className="pageheading-holder">
                            <div className="back-btn-holder">
                                {/* <Link to="/allocation_orders">
                                    <button className="back-btn app-btn">
                                        <svg className="svg-icon back-icon">
                                            <use href="#previousArrowIcon">
                                                <title>Back</title>
                                            </use>
                                        </svg>
                                    </button>
                                </Link> */}
                                <h2 className="page-heading">Create New Allocation Order</h2>
                            </div>
                        </div>
                        {isLoading && (
                            <div className="app-loader loading">
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                        )}
                        <div className="field-entries-added">
                            <div className="entry-fields-holder">
                                <form id="my-form" onSubmit={formik.handleSubmit} className="modal-form form-fields">
                                    <div className="modal-input-holder layout-item">
                                        <label className="modal-input-label">AO Number</label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter AO Number"
                                                type="text"
                                                className="modal-input input-text"
                                                id="aoNumber"
                                                name="aoNumber"
                                                onChange={(event) => {
                                                    formik.setFieldError(event.target.name, undefined);
                                                    formik.handleChange(event);
                                                }}
                                                value={formik.values.aoNumber}
                                            ></input>
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">Client Name</label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                data={clientDetails?.length ? clientDetails : []}
                                                placeholder="Enter Client Name to select"
                                                formik={formik}
                                                fieldName="clientCode"
                                                extraField="clientName"
                                                isClearable={clientDetails?.length === 1 ? false : true}
                                                customStyle={clientStyle}
                                            />
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder  layout-item">
                                        <label className="modal-input-label">BLP</label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                data={blpDetails?.data ? blpDetails?.data : []}
                                                placeholder="Enter BLP Name to select"
                                                formik={formik}
                                                fieldName="blpAgentCode"
                                                extraField="blpAgentName"
                                                isDisabled={disableVendor}
                                                NoMessageComponent={NoMessageComponent}
                                                customStyle={blpStyle}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-submit-btn">
                                        <button
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                            title="Submit"
                                            form="my-form"
                                            type="submit"
                                            disabled={
                                                formik.values.clientCode === '' ||
                                                formik.values.blpAgentCode === '' ||
                                                formik.values.aoNumber === ''
                                            }
                                        >
                                            <span className="button-text footer-button-text">Submit</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
