import React, { useEffect, useState } from 'react';
// import BookingProposalHeader from '../../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../../BookingProposal/POSelection.scss';
import '../../../BookingProposal/BookingChangeVessel/ManualVesselEntry/manual-vessel-entry.scss';
// import { useFormik } from 'formik';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import * as Yup from 'yup';
import { reactSelectStyle, reactSelectTheme } from '../../../../utils/constants';
import Select, { components } from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { addToBp, fetchSelectedSailing } from '../../../../store/reducers/bookingProposal/sailingReducer';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { fetchBpHeaderData } from '../../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { getOrganisationData } from '../../../../utils';
import { searchUnlocoList, clearUnlocoList } from '../../../../store/reducers/createVendorReducer';
import VesselDropdownList from '../../../Common/vesselDropdown';
import { getVesselList } from 'src/store/reducers/bookingProposalAir/addSchedule';

/* eslint no-var: off */
/* eslint-disable no-undefined */

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];

var dataValues: any = [];

const Index: React.FC = () => {
    const { vesselList } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const [Type, setType] = useState('Direct');
    const [originEtd, setoriginEtd] = useState('');
    const [destinationEta, setdestinationEta] = useState('');
    const [vessel, setvessel] = useState('');
    const [voyage, setvoyage] = useState('');
    const [service, setservice] = useState('');
    const [errorValidation, seterrorValidation] = useState(false);
    const [_NEwData, setNEwData] = useState({});
    const [portValues, setPorts] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [portDetails, setportDetails] = useState([
        {
            portName: '',
            portCode: '',
            vessel: '',
            etd: '',
            eta: '',
            service: '',
            voyage: '',
        },
    ]);

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const convertIMOtoName = (imo: string) => {
        if (vesselList && vesselList?.length > 0) {
            for (var i = 0; i < vesselList?.length; i++) {
                if (imo == vesselList[i].imo) {
                    return vesselList[i].name;
                }
            }
        }

        return '';
    };

    const { isLoading2, selectedSailing, sailingUpdate } = useSelector((state: RootState) => state.sailingRed);
    const { profileType } = useUserProfile();
    const { unLocoList } = useSelector((state: RootState) => state.vendorDetails);
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    const payload = {
        sailingAddRequest: {
            bookingProposalId: id,
            sailingId: dataValues?.sailingId,
            contractType: dataValues.contractType,
            contractId: dataValues.contractId,
            contractName: dataValues.contractName,
            shippingLine: dataValues.shippingline,
            shippingLineCode: dataValues.shippinglineCode,
            transitDays: dataValues.plannedTransitDays,
            serviceType: Type,
            isPrimary: dataValues.isPrimaryClient,
            eta: destinationEta + ' 00:00:00',
            etd: originEtd + ' 00:00:00',
            vesselVoyage: convertIMOtoName(vessel) + '-' + voyage,
            vessel: convertIMOtoName(vessel),
            gateInDate: dataValues.gateInDate ? moment(dataValues.gateInDate).format('yyyy-MM-DD') + ' 00:00:00' : null,
            contractVersion: dataValues?.contractVersion,
            isManuallyEntered: true,
            changeVessel: true,
            predictedETD: dataValues.predictedETD
                ? moment(dataValues.predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            predictedETA: dataValues.predictedETA
                ? moment(dataValues.predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            predictedTransitDays: dataValues.predictedTransitDays,
            service: service,
            destinationPortCode: dataValues.destinationPortCode,
            destinationPortName: dataValues.destinationPortName,
            destinationPortTerminal: dataValues.destinationPortTerminal,
            originPortCode: dataValues.originPortCode,
            originPortName: dataValues.originPortName,
            vesselIMO: vessel ?? '',
            originPortTerminal: dataValues.originPortTerminal,
            vgmCutOff: dataValues.vgmCutOff ? moment(dataValues.vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00' : null,
            gateInCutOff: dataValues.gateInCutOff
                ? moment(dataValues.gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            standardBookingCutOff: dataValues.standardBookingCutOff
                ? moment(dataValues.standardBookingCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            gateInOpen: dataValues.gateInOpen ? moment(dataValues.gateInOpen).format('yyyy-MM-DD') + ' 00:00:00' : null,
            docCutOff: dataValues.docCutOff ? moment(dataValues.docCutOff).format('yyyy-MM-DD') + ' 00:00:00' : null,
            sailingScheduleTransitPorts: [],
        },
        notification: { roles, listOfUserOrganisations },
    };

    useEffect(() => {
        dispatch(fetchSelectedSailing(id));
        // dispatch(getPortValues(null));
        dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        dispatch(getVesselList({}));
    }, [id]);

    useEffect(() => {
        if (sailingUpdate) {
            navigate(-1);
        }
    }, [sailingUpdate]);

    useEffect(() => {
        if (Object.keys(selectedSailing).length !== 0) {
            setNEwData((selectedSailing as any)['sailingScheduleLists'][0]);
            dataValues = (selectedSailing as any)['sailingScheduleLists'][0];
        }
    }, [selectedSailing, Object.keys(selectedSailing)]);
    const submitValues = () => {
        var error = false;
        var payloadNew: any = [];
        if (Type === 'Direct') {
            if (
                !voyage?.trim() ||
                !service?.trim() ||
                !vessel?.trim() ||
                !originEtd?.trim() ||
                !destinationEta?.trim()
            ) {
                seterrorValidation(true);
            } else {
                seterrorValidation(false);
                dispatch(addToBp(payload));
            }
        } else {
            portDetails.forEach((element) => {
                if (
                    !element.portCode?.trim() ||
                    !element.portName?.trim() ||
                    !element.eta?.trim() ||
                    !element.etd?.trim() ||
                    !element.service?.trim() ||
                    !element.vessel?.trim() ||
                    !element.voyage?.trim() ||
                    !voyage?.trim() ||
                    !service?.trim() ||
                    !vessel?.trim() ||
                    !originEtd?.trim() ||
                    !destinationEta?.trim()
                ) {
                    error = true;
                    seterrorValidation(true);
                } else {
                    error = false;
                    payloadNew.push({
                        portName: element.portName,
                        portCode: element.portCode,
                        plannedETA: element.eta + ' 00:00:00',
                        plannedETD: element.etd + ' 00:00:00',
                        route: element.service,
                        vesselVoyage: convertIMOtoName(element.vessel) + '-' + element.voyage,
                        vessel: convertIMOtoName(element.vessel),
                        predictedETA: null,
                        predictedETD: null,
                        plannedTransitDays: null,
                        predictedTransitDays: null,
                        transitOrder: null,
                        vgmCutOff: null,
                        portCutOff: null,
                        terminal: null,
                    });
                }
            });
            const newData = {
                sailingAddRequest: {
                    ...(payload as any)['sailingAddRequest'],
                    sailingScheduleTransitPorts: payloadNew,
                },
                notification: { roles, listOfUserOrganisations },
            };
            if (!error) {
                seterrorValidation(false);
                dispatch(addToBp(newData));
            }
        }
    };
    useEffect(() => {
        if (searchTerm?.trim().length) {
            dispatch(searchUnlocoList({ searchTerm, portType: 'sea', service: 'bkg' }));
        }
        //setPorts([]);
    }, [searchTerm]);
    useEffect(() => {
        if (unLocoList?.length > 0) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    portCountry: option?.portCountry ?? '',
                };
            });
            setPorts(portList);
        }
    }, [unLocoList]);

    useEffect(() => {
        dispatch(clearUnlocoList());
    }, []);

    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };
    return (
        <div className="main-wrapper container booking-change-vessel">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={`/Aobooking_list/${id}/POSelection`}
                    thirdName={'Sailing Schedule'}
                    thirdUrl={`/Aobooking_list/${id}/SailingSelectionDetails`}
                    fourthName={'Change Vessel'}
                    fourthUrl={'#'}
                    summaryStats={summaryStats}
                    backUrl={'/Aobooking_list/${id}/SailingSelectionDetails'}
                />
                <div className="pageheading-holder">
                    <div className="back-btn-holder">
                        <Link to={`/Aobooking_list/${id}/SailingSelectionDetails`}>
                            <button className="back-btn app-btn">
                                <svg className="svg-icon back-icon">
                                    <use href="#previousArrowIcon">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </button>
                        </Link>
                        <h2 className="page-heading">Manual Vessel Entry</h2>
                    </div>
                </div>
                {isLoading2 && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper change-vessel-detail">
                <div className="main-wrapper">
                    <div className="manual-entry-wrapper">
                        <div className="filter-types">
                            <div className="layout-holder two-column">
                                <div className="layout-item">
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">Carrier</label>
                                        <div className="modal-input-layout-item">
                                            <div className="modal-input input-text read-only">
                                                {dataValues?.shippingline}
                                                <span className="modal-toast">{dataValues?.contractType}</span>
                                            </div>
                                            {/*<input
                                                placeholder={`${dataValues?.shippingline}`}
                                                type="text"
                                                className="modal-input input-text read-only"
                                                id="sd_version"
                                                name="sd_version"
                                                readOnly
                                            ></input>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-type-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            onChange={() => setType('Direct')}
                                            checked={Type === 'Direct'}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Direct</div>
                                    </label>
                                </div>
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            onChange={() => setType('Transhipment')}
                                            checked={Type === 'Transhipment'}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Multi-Legged</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="layout-header">Origin</div>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">Port</label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder={`${dataValues?.originPortName} (${
                                                        dataValues?.originPortCode ? dataValues?.originPortCode : ''
                                                    })`}
                                                    type="text"
                                                    className="modal-input input-text read-only"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    readOnly
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                ETD<span className="mandatory-field-text">*</span>
                                            </label>

                                            <div className="modal-input-layout-item">
                                                <DatePicker
                                                    //disabled={disabled}
                                                    minDate={
                                                        new Date(
                                                            moment(new Date(), 'yyyy-MM-DD')
                                                                .add(5, 'days')
                                                                .format('MM-DD-YYYY')
                                                        )
                                                    }
                                                    onChange={(value: any) => {
                                                        setoriginEtd(moment(value).format('yyyy-MM-DD'));
                                                    }}
                                                    value={originEtd ? moment(originEtd).format('DD-MM-YYYY') : ''}
                                                    placeholderText="Select Date"
                                                    customInput={
                                                        <input
                                                            className="modal-input input-text with-border date-input input-date-cursor"
                                                            type="text"
                                                        />
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                            {errorValidation && originEtd === '' && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    ETD is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <VesselDropdownList
                                            vessel={vessel}
                                            setVessel={setvessel}
                                            isErrorMessageShow={
                                                errorValidation && (vessel === '' || vessel === undefined)
                                            }
                                        />
                                    </div>
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                Voyage<span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter Voyage"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    onChange={(e) => setvoyage(e.target.value)}
                                                    value={voyage}
                                                ></input>
                                            </div>
                                            {errorValidation && !voyage?.trim() && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    Voyage is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                Service<span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter Service"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    onChange={(e) => setservice(e.target.value)}
                                                    value={service}
                                                ></input>
                                            </div>
                                            {errorValidation && !service?.trim() && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    Service is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Type !== 'Direct' && (
                            <>
                                {portDetails?.map((res: any, index: any) => (
                                    <div className="card details-box custom">
                                        <div className="card-head-holder add-btn-holder">
                                            <div className="layout-holder two-column">
                                                <div className="layout-item">
                                                    <div className="layout-header via">
                                                        Multi-Legged {index + 1} - Via
                                                    </div>
                                                </div>
                                                <div className="layout-item remove-link">
                                                    {index !== 0 && (
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="file-remove-link"
                                                            title="Remove Leg"
                                                            onClick={() => {
                                                                const newArray = [...portDetails];
                                                                newArray.splice(index, 1);
                                                                setportDetails(newArray);
                                                            }}
                                                        >
                                                            Remove Leg
                                                        </a>
                                                    )}
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label htmlFor="name" className="modal-input-label">
                                                            Port<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div id="cd_author_div" className="modal-input-layout-item">
                                                            <Select
                                                                options={portValues}
                                                                isClearable={true}
                                                                placeholder={'Enter Port to select'}
                                                                onChange={(option: any) => {
                                                                    res.portName = option.label;
                                                                    res.portCode = option.value;
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                                menuPlacement={'auto'}
                                                                components={{
                                                                    DropdownIndicator: () => null,
                                                                    IndicatorSeparator: () => null,
                                                                    ClearIndicator,
                                                                }}
                                                                onInputChange={(text: string) => {
                                                                    setSearchTerm(text);
                                                                }}
                                                                theme={reactSelectTheme}
                                                                styles={reactSelectStyle}
                                                                value={portValues?.filter((option: any) => {
                                                                    if (option.value === res.portCode) {
                                                                        return {
                                                                            label: res.portName,
                                                                            value: res.portCode,
                                                                        };
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                        {errorValidation &&
                                                            (res.portName === '' || res.portCode === '') && (
                                                                <p
                                                                    className="error_message_product"
                                                                    style={{ paddingTop: '8px' }}
                                                                >
                                                                    Port is required
                                                                </p>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            ETA<span className="mandatory-field-text">*</span>
                                                        </label>

                                                        <div className="modal-input-layout-item">
                                                            <DatePicker
                                                                //disabled={disabled}
                                                                minDate={new Date()}
                                                                onChange={(value: any) => {
                                                                    res.eta = moment(value).format('yyyy-MM-DD');
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                                value={
                                                                    res.eta ? moment(res.eta).format('DD-MM-YYYY') : ''
                                                                }
                                                                placeholderText="Select Date"
                                                                customInput={
                                                                    <input
                                                                        className="modal-input input-text with-border date-input input-date-cursor"
                                                                        type="text"
                                                                    />
                                                                }
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                            />
                                                        </div>
                                                        {errorValidation && res.eta === '' && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                ETA is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            Vessel<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <input
                                                                placeholder="Enter Vessel"
                                                                type="text"
                                                                className="modal-input input-text"
                                                                id="sd_version"
                                                                name="sd_version"
                                                                onChange={(e) => (res.vessel = e.target.value)}
                                                            ></input>
                                                        </div>
                                                        {errorValidation && res.vessel === '' && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                Vessel is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            Voyage<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <input
                                                                placeholder="Enter Voyage"
                                                                type="text"
                                                                className="modal-input input-text"
                                                                id="sd_version"
                                                                name="sd_version"
                                                                onChange={(e) => (res.voyage = e.target.value)}
                                                            ></input>
                                                        </div>
                                                        {errorValidation && !res.voyage?.trim() && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                Voyage is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            Service<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <input
                                                                placeholder="Enter Service"
                                                                type="text"
                                                                className="modal-input input-text"
                                                                id="sd_version"
                                                                name="sd_version"
                                                                onChange={(e) => (res.service = e.target.value)}
                                                            ></input>
                                                        </div>
                                                        {errorValidation && !res.service?.trim() && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                Service is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            ETD<span className="mandatory-field-text">*</span>
                                                        </label>

                                                        <div className="modal-input-layout-item">
                                                            <DatePicker
                                                                //disabled={disabled}
                                                                minDate={new Date()}
                                                                onChange={(value: any) => {
                                                                    res.etd = moment(value).format('yyyy-MM-DD');
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                                value={
                                                                    res.etd ? moment(res.etd).format('DD-MM-YYYY') : ''
                                                                }
                                                                placeholderText="Select Date"
                                                                customInput={
                                                                    <input
                                                                        className="modal-input input-text with-border date-input input-date-cursor"
                                                                        type="text"
                                                                    />
                                                                }
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                            />
                                                        </div>
                                                        {errorValidation && res.etd === '' && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                ETD is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Leg"
                                        onClick={() => {
                                            setportDetails([
                                                ...portDetails,
                                                {
                                                    portName: '',
                                                    portCode: '',
                                                    vessel: '',
                                                    etd: '',
                                                    eta: '',
                                                    service: '',
                                                    voyage: '',
                                                },
                                            ]);
                                            setdestinationEta('');
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Leg</span>
                                    </button>
                                </div>
                            </>
                        )}
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="layout-header destination">Destination</div>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">Port</label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder={`${dataValues?.destinationPortName} (${
                                                        dataValues?.destinationPortCode
                                                            ? dataValues?.destinationPortCode
                                                            : ''
                                                    })`}
                                                    type="text"
                                                    className="modal-input input-text read-only"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    readOnly
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                ETA<span className="mandatory-field-text">*</span>
                                            </label>

                                            <div className="modal-input-layout-item">
                                                <DatePicker
                                                    //disabled={disabled}
                                                    onChange={(value: any) => {
                                                        setdestinationEta(moment(value).format('yyyy-MM-DD'));
                                                    }}
                                                    minDate={new Date()}
                                                    value={
                                                        destinationEta
                                                            ? moment(destinationEta).format('DD-MM-YYYY')
                                                            : ''
                                                    }
                                                    placeholderText="Select Date"
                                                    customInput={
                                                        <input
                                                            className="modal-input input-text with-border date-input input-date-cursor"
                                                            type="text"
                                                        />
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                            {errorValidation && destinationEta === '' && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    ETA is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    <>
                        <button
                            type="submit"
                            title="Done"
                            id="basicForm"
                            className="app-btn app-btn-primary footer-btn"
                            onClick={() => submitValues()}
                        >
                            <span className="button-text footer-button-text">Done</span>
                        </button>
                    </>
                </div>
            </div>
        </div>
    );
};
export default Index;
