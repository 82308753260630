import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { del, get, patch, post, put } from '../HttpMethods';
import axios from 'axios';

export const getRateByPK = async (key: string) => {
    try {
        const response = await get(`cix/scr/qms/config/rate/${key}`, null, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getQuoteByPK = async (key: string) => {
    try {
        const response = await get(`cix/scr/qms/quote/${key}`, null, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getRateByPKSagaApi = async ({ payload }: { payload: any }) => {
    try {
        const response = await get(`cix/scr/qms/config/rate/${payload.key}`, null, externalBaseUrl);

        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getSsrsApi = async (payload: { key: any }) => {
    try {
        const fileName = process.env.REACT_APP_QUOTE_REPORT_NAME;

        const data = await post(
            `/cix/scr/utility/ssrs`,
            {
                path: `${fileName}`,
                format: 'EXCELOPENXML',
                params: `QH_PK=${payload.key}`,
            },
            null,
            undefined,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getQuoteByPKSagaApi = async ({ payload }: { payload: any }) => {
    try {
        const response = await get(`cix/scr/qms/quote/${payload.key}`, null, externalBaseUrl);

        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateDateApi = async (key: string, payload: any) => {
    try {
        const response = await patch(`cix/scr/qms/config/rate/${key}`, payload, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const rateRefreshScheduleApi = async (id: any) => {
    try {
        const response = await patch(`cix/scr/qms/config/copyrate/${id}`, null, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createRateHeader = async (key: string) => {
    try {
        const response = await post(`cix/scr/qms/config/rate/${key}`, null, null, undefined, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
