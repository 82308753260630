/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface PmPackets {
    pp_quantity: string;
    pp_conversionquantity: number;
    pp_length: number;
    pp_width: number;
    pp_height: number;
    pp_weight: number;
    pp_barcode: string;
    pp_weightuom: string;
    pp_dimensionuom: string;
    pp_cbm: number;
    pp_PK: string;
}

interface Vendors {
    vn_vendorcode: string;
    vn_vendorname: string;
    vn_vendorcountry: string;
    vn_status: string;
    vn_PK: string;
}

export interface SkuDataDetails {
    pm_sku: string;
    pm_description: string;
    pm_purchasecode: string;
    pm_totalweight: string;
    pm_weightuom: string;
    pm_uom: string[];
    pm_status: string;
    pm_fumigationrequired: boolean;
    pm_vendors: Vendors[];
    pm_packets: PmPackets[];
    isEachPresent: boolean;
    pm_PK: string;
}
export interface AllocationSkuDataDetails {
    originPortCode: string;
    originPortName: string;
    inventoryId: string;
    sku: string;
    skuId: string;
    description: string;
    clientCode: string;
    clientName: string;
    clientId: string;
    blpCode: string;
    blpName: string;
    qtyScheduledForRcpt: number;
    availableQty: number;
    onHoldQty: number;
    stockOnHand: number;
    availableToOrder: number;
    allocatedQty: number;
    adjAvailableQty: number;
    adjHoldQty: number;
    adjAvailableCmnt: string;
    adjHoldCmnt: string;
    createdDate: string;
    createdUser: string;
    createdUserId: string;
}
export interface ProductAllocationDataDetails {
    allocationOrderId: string;
    poProductId: string;
    sku: string;
    skuId: string;
    description: string;
    customerOrderReferenceNo: string;
    customerOrderLineNo: string;
    availableToOrder: number;
    originPortName: string;
    destinationPortName: string;
    destinationAddress: string;
    projWhouseETA: string;
    currency: string;
    cbm: number | string;
    qty: number | string;
    balanceQty: number | string;
    originalQty: number;
    unitCost: number | string;
    netCost: number | string;
    weight: number | string;
    destinationAddressIdInProduct: string;
    hasWarning: boolean;
    hasError: boolean;
    isIntegration: boolean;
    editMode?: boolean;
    saveTriggered?: boolean;
    validationError?: boolean;
    isSkuIdInvalid?: boolean;
    indexValue: number;
    inboundPoReferenceNos: { allocationQty: number; inobundPoReferenceNo: string }[];
}
export const productAllocationDataDetailsInitial = {
    allocationOrderId: '',
    poProductId: '',
    sku: '',
    skuId: '',
    description: '',
    customerOrderReferenceNo: '',
    customerOrderLineNo: '',
    availableToOrder: '',
    originPortName: '',
    destinationPortName: '',
    destinationAddress: '',
    projWhouseETA: '',
    currency: '',
    cbm: '',
    qty: '',
    originalQty: '',
    balanceQty: '',
    unitCost: '',
    netCost: '',
    weight: '',
    destinationAddressIdInProduct: '',
    hasWarning: false,
    hasError: false,
    isIntegration: false,
    editMode: true,
    saveTriggered: false,
    isSkuIdInvalid: false,
    indexValue: 0,
    inboundPoReferenceNos: [],
};
export interface DestinationAddressDataDetails {
    addressline1: string;
    addressline2: string;
    addressline3: string;
    address_type: string;
    city: string;
    state: string;
    postcode: number;
    countrycode: string;
    contact_email: string;
    contact_mobno: string;
    contact_person: string;
    isPrimary: number;
    isClient: number;
    isVendor: number;
    isAgent: number;
    isShippingLine: number;
    closest_unlocode_portcode: string;
    oa_PK: string;
    oa_CreatedDateTime: string;
    oa_UserDefinedText3: string;
    oa_UserDefinedText1: string;
    oa_UserDefinedText2: string;
    oa_UserDefinedInt1: string;
    oa_UserDefinedInt2: string;
    oa_UserDefinedInt3: string;
    oa_UserDefinedInt4: string;
    oa_UserDefinedNumeric1: string;
    oa_UserDefinedNumeric2: string;
    oa_UserDefinedNumeric3: string;
    oa_UserDefinedNumeric4: string;
    oa_UserDefinedDateTime1: string;
    oa_UserDefinedDateTime2: string;
    oa_UserDefinedDateTime3: string;
    oa_UserDefinedDateTime4: string;
    oa_ModifiedDateTime: string;
    oa_ModifiedByUser: string;
    oa_CreatedByUser: string;
    oa_ManuallyEntered: string;
    oa_PS: string;
}

interface ProductAllocationState {
    isLoading: number;
    isLoadingPort: boolean;
    productAllocation: {
        errorCount: number;
        totalCount: number;
        warningCount: number;
        productListAllocationOrderList: ProductAllocationDataDetails[];
    };
    sku: SkuDataDetails[];
    allocationSku: AllocationSkuDataDetails[];
    currency: { value: string; label: string }[];
    port: { value: string; label: string }[];
    distinctValue: [];
    destinationAddress: DestinationAddressDataDetails[];
    productAllocationSuccess: any;
    productAllocationError: any;
    isError?: boolean;
}

const initialState: ProductAllocationState = {
    isLoading: 0,
    isLoadingPort: false,
    productAllocation: {
        errorCount: 0,
        totalCount: 0,
        warningCount: 0,
        productListAllocationOrderList: [],
    },
    sku: [],
    allocationSku: [],
    currency: [],
    port: [],
    distinctValue: [],
    destinationAddress: [],
    productAllocationSuccess: undefined,
    productAllocationError: undefined,
    isError: false,
};

export const productAllocationSlice = createSlice({
    name: 'productAllocation',
    initialState,
    reducers: {
        fetchProductAllocationList: (state, _action) => {
            state.isLoading += 1;
            // state.isError = false;
        },
        fetchProductAllocationListSuccess(state, action) {
            state.isLoading -= 1;
            // state.isError = false;
            state.productAllocation = action.payload?.data?.info;
        },
        fetchProductAllocationListFailure(state, action) {
            state.isLoading -= 1;
            // state.isError = true;
            state.productAllocation = action.payload;
        },
        fetchSkuList: (state, _action) => {
            state.isLoading += 1;
            state.isError = false;
        },
        fetchSkuListSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.sku = action.payload?.data?.info;
        },
        fetchSkuListFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.sku = action.payload;
        },
        fetchAllocationSkuList: (state, _action) => {
            // state.isLoading = true;
            state.isError = false;
        },
        fetchAllocationSkuListSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.allocationSku = action.payload?.data;
        },
        fetchAllocationSkuListFailure(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.allocationSku = action.payload;
        },
        createProductAllocation(state, _action) {
            state.isLoading += 1;
            state.isError = false;
        },
        createProductAllocationSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.productAllocationSuccess = action.payload;
            state.productAllocationError = {};
        },
        createProductAllocationFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.productAllocationSuccess = {};
            state.productAllocationError = action.payload;
        },
        updateProductAllocation(state, _action) {
            state.isLoading += 1;
            state.isError = false;
        },
        updateProductAllocationSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.productAllocationSuccess = action.payload;
            state.productAllocationError = {};
        },
        updateProductAllocationFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.productAllocationSuccess = {};
            state.productAllocationError = action.payload;
        },
        removeProductAllocation(state, _action) {
            state.isLoading += 1;
            state.isError = false;
        },
        removeProductAllocationSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.productAllocationSuccess = action.payload;
            state.productAllocationError = {};
        },
        removeProductAllocationFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.productAllocationSuccess = {};
            state.productAllocationError = action.payload;
        },
        fetchDistinctValues(state, _action) {
            state.isLoading += 1;
            // state.isError = false;
        },
        fetchDistinctValuesSuccess(state, action) {
            state.isLoading -= 1;
            // state.isError = false;
            state.distinctValue = action.payload?.data?.info;
            state.productAllocationError = {};
        },
        fetchDistinctValuesFailure(state, action) {
            state.isLoading -= 1;
            // state.isError = true;
            state.productAllocationSuccess = {};
            state.productAllocationError = action.payload;
        },
        fetchCurrency: (state, _action) => {
            state.isError = false;
        },
        fetchCurrencySuccess(state, action) {
            state.isError = false;
            state.currency = action.payload?.data;
        },
        fetchCurrencyFailure(state, action) {
            state.isError = true;
            state.productAllocationError = action.payload;
        },
        fetchPort: (state, _action) => {
            state.isError = false;
            state.isLoadingPort = true;
        },
        fetchPortSuccess(state, action) {
            state.isError = false;
            state.port = action.payload?.data;
            state.isLoadingPort = false;
        },
        fetchPortFailure(state, action) {
            state.isError = true;
            state.productAllocationError = action.payload;
            state.isLoadingPort = false;
        },
        fetchDestinationAddress: (state, _action) => {
            state.isError = false;
        },
        fetchDestinationAddressSuccess(state, action) {
            state.isError = false;
            state.destinationAddress = action.payload?.data?.info;
        },
        fetchDestinationAddressFailure(state, action) {
            state.isError = true;
            state.productAllocationError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.productAllocationError = undefined;
            state.productAllocationSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchProductAllocationList,
    fetchProductAllocationListSuccess,
    fetchProductAllocationListFailure,
    fetchSkuList,
    fetchSkuListSuccess,
    fetchSkuListFailure,
    fetchAllocationSkuList,
    fetchAllocationSkuListSuccess,
    fetchAllocationSkuListFailure,
    createProductAllocation,
    createProductAllocationSuccess,
    createProductAllocationFailure,
    updateProductAllocation,
    updateProductAllocationSuccess,
    updateProductAllocationFailure,
    removeProductAllocation,
    removeProductAllocationSuccess,
    removeProductAllocationFailure,
    fetchDistinctValues,
    fetchDistinctValuesSuccess,
    fetchDistinctValuesFailure,
    fetchCurrency,
    fetchCurrencySuccess,
    fetchCurrencyFailure,
    fetchPort,
    fetchPortSuccess,
    fetchPortFailure,
    fetchDestinationAddress,
    fetchDestinationAddressSuccess,
    fetchDestinationAddressFailure,
    isLoadingFn,
    clearFailure,
} = productAllocationSlice.actions;

export const selectProductAllocationLoading = (state: RootState) => state.productAllocationData.isLoading;
export const selectPortLoading = (state: RootState) => state.productAllocationData.isLoadingPort;
export const selectProductAllocation = (state: RootState) => state.productAllocationData.productAllocation;
export const selectSku = (state: RootState) => state.productAllocationData.sku;
export const selectDistinctValue = (state: RootState) => state.productAllocationData.distinctValue;
export const selectAllocationSku = (state: RootState) => state.productAllocationData.allocationSku;
export const selectCurrency = (state: RootState) => state.productAllocationData.currency;
export const selectPort = (state: RootState) => state.productAllocationData.port;
export const selectDestinationAddress = (state: RootState) => state.productAllocationData.destinationAddress;
export const selectProductAllocationSuccess = (state: RootState) =>
    state.productAllocationData.productAllocationSuccess;
export const selectProductAllocationError = (state: RootState) => state.productAllocationData.productAllocationError;
export const selectProductAllocationIsError = (state: RootState) => state.productAllocationData.isError;

export default productAllocationSlice.reducer;
