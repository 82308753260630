import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { Form, FormField, TextArea } from 'semantic-ui-react';

import { cixDeleteDoc } from 'src/store/reducers/documentReducer';
import { createRateHeader } from 'src/services/apis/qmsApi';
import { Position, error, success } from 'src/utils/toast';
import QuoteItemPortCard from '../QuoteItem/Details/QuoteItemPortCard';
import QuoteItemSingleItemCard from '../QuoteItem/Details/QuoteItemSingleItemCard';
import QuoteItemSingleDropdownCard from '../QuoteItem/Details/QuoteItemSingleDropdownCard';
import QuoteItemMarkup from '../QuoteItem/Details/QuoteItemMarkup';
import QuoteItemDropdownCard from '../QuoteItem/Details/QuoteItemDropdownCard';
import DatePicker from 'react-datepicker';
import './quote-item-details.scss';
import moment from 'moment';
import {
    addQuoteApi,
    getClientData,
    getPortMatching,
    getQuoteServices,
    getQuoteTransportMode,
} from 'src/services/apis/quoteAPi';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import SearchableSelectBoxWithoutFormik from 'src/components/common/SearchableSelectBox/withoutFormik';
import { fetchClient, selectClientDetails } from 'src/store/reducers/purchaseOrder/addPoReducer';
import { InputActionMeta } from 'react-select';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
const quoteInitialData = {
    portMatching: [],
    providers: [],
    services: [],
    clients: [],
    transport: [],
    contractType: '',
    container: [],
};
const quoteLoadingState = {
    portMatching: true,
    services: true,
    providers: true,
    clients: true,
    transport: true,
};

const contractTypeData = [
    {
        label: 'FAK',
        value: 'FAK',
    },
    {
        label: 'NAC',
        value: 'NAC',
    },
];

const defaultServices = [
    {
        label: 'Declaration Fees',
        value: 'DEC',
    },
    {
        label: 'Freight',
        value: 'FRT',
    },
    {
        label: 'Carrier Freight Surcharges',
        value: 'FSU',
    },
];

const Index: React.FC<ModalProps> = ({ modalView, setModalView }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [buttonDisplay, setButtonDisplay] = useState('Add');
    const [quoteData, setQuoteData] = useState<any>(quoteInitialData);
    const [quoteLoading, setQuoteLoading] = useState<any>(quoteLoadingState);
    const [newEffectiveDate, setNewEffectiveDate] = useState('');
    const [newExpireDate, setNewExpireDate] = useState('');
    const [loading, setLoading] = useState(true);
    const [saveTriggered, setSaveTriggered] = useState(false);
    const [portData, setPortData] = useState<any>();
    const [providerData, setProviderData] = useState<any>();
    const [serviceData, setServiceData] = useState<any>();
    const [clientData, setClientData] = useState<any>();
    const [transportData, setTransportData] = useState<any>();
    const [containerData, setContainerData] = useState<any>();
    const [contractType, setContractType] = useState('');
    const [warning, setWarning] = useState(false);

    useEffect(() => {
        if (!portData) {
            getPortMatching().then((port) => {
                setQuoteLoading((prev: any) => ({ ...prev, portMatching: false }));
                setPortData(port);
                setContainerData([
                    { value: 'LCL', label: 'LCL', extraValue: 'LCL' },
                    { value: 'FCL', label: 'FCL', extraValue: 'FCL' },
                ]);
                getQuoteServices().then((service) => {
                    setQuoteLoading((prev: any) => ({ ...prev, services: false }));

                    const newService = service?.filter((s: any) => {
                        return s.value !== 'DEC' && s.value !== 'FRT' && s.value !== 'FSU';
                    });
                    setServiceData(newService);
                    getClientData().then((clients) => {
                        setQuoteLoading((prev: any) => ({ ...prev, clients: false }));
                        setClientData(clients);
                        setQuoteLoading((prev: any) => ({ ...prev, providers: false }));
                        getQuoteTransportMode().then((transport) => {
                            setQuoteLoading((prev: any) => ({ ...prev, transport: false }));
                            setTransportData(transport);
                        });
                    });
                });
            });
        }
    }, []);

    useEffect(() => {
        if (
            quoteData.portMatching?.filter((q: any) => {
                return q.origin.value === 'ANY' || q.destination.value === 'ANY';
            })?.length > 0
        ) {
            setWarning(true);
        } else {
            setWarning(false);
        }
    }, [quoteData.portMatching]);

    const checkInvalidPort = () => {
        let status = false;
        if (!quoteData?.portMatching?.length) {
            return false;
        }
        for (let index = 0; index < quoteData?.portMatching?.length; index++) {
            if (!quoteData?.portMatching[index]?.origin?.value || !quoteData?.portMatching[index]?.destination?.value) {
                status = true;
            }
        }
        return status;
    };

    const validation = () => {
        return (
            quoteData?.clients?.value &&
            newEffectiveDate &&
            newExpireDate &&
            contractType &&
            quoteData?.transport?.filter((t: any) => {
                return t.value;
            })?.length > 0 &&
            quoteData.container.filter((t: any) => {
                return t.value;
            })?.length > 0 &&
            quoteData.providers.filter((t: any) => {
                return t.value;
            })?.length > 0 &&
            quoteData.portMatching.filter((q: any) => {
                return (
                    q.origin.value && q.destination.value && q.origin.value !== 'ANY' && q.destination.value !== 'ANY'
                );
            })?.length > 0
        );
    };

    const addQuote = () => {
        try {
            const payload = {
                QH_OR_ClientCode: quoteData.clients.value,
                QH_ValidFrom: moment(newEffectiveDate).format('YYYY-MM-DD'),
                QH_ValidTo: moment(newExpireDate).format('YYYY-MM-DD'),
                QH_X_TransportModes: quoteData.transport
                    .filter((t: any) => {
                        return t.value;
                    })
                    .map((t: any) => {
                        return { QE_CD_TransportMode: t.value };
                    }),
                QH_X_ContainerModes: quoteData.container
                    .filter((t: any) => {
                        return t.value;
                    })
                    .map((c: any) => {
                        return { QE_CD_ContainerMode: c.value };
                    }),
                QH_X_Providers: quoteData.providers
                    .filter((t: any) => {
                        return t.value;
                    })
                    .map((p: any) => {
                        return { QD_OrgCode: p.value };
                    }),
                QH_X_Services: [{ QE_Code: 'FSU' }, { QE_Code: 'FRT' }, { QE_Code: 'DEC' }].concat(
                    quoteData.services
                        .filter((t: any) => {
                            return t.value;
                        })
                        .map((s: any) => {
                            return { QE_Code: s.value };
                        })
                ),
                QH_X_PortPairs: quoteData.portMatching
                    .filter((q: any) => {
                        return (
                            q.origin.value &&
                            q.destination.value &&
                            q.origin.value !== 'ANY' &&
                            q.destination.value !== 'ANY'
                        );
                    })
                    .map((q: any) => {
                        return { QD_PortOfOrigin: q.origin.value, QD_PortOfDestination: q.destination.value };
                    }),
            };
            addQuoteApi(payload).then((data: any) => {
                success('Add Quote Action Complete', Position.TOP_RIGHT);
                setModalView(false);
                $('.k-i-reload').click();
            });
        } catch (e) {
            error('Incorrect Input', Position.TOP_RIGHT);
        }
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal  quote-item-detail">
                    <div className="modal-content-holder large-popup">
                        <div className="modal-header">
                            <div className="title">Add Quote</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="card details-box custom">
                                            <div className="row-1">
                                                <QuoteItemSingleDropdownCard
                                                    isRequired={true}
                                                    key={1}
                                                    title={'Who For?'}
                                                    keyName={'clients'}
                                                    quoteData={quoteData}
                                                    setQuoteData={setQuoteData}
                                                    quoteItemData={clientData}
                                                    loading={quoteLoading}
                                                />
                                                <div className="detail-box-common">
                                                    <h6 className="details-box-heading-2">
                                                        From<span className="mandatory-field-text">*</span>
                                                    </h6>

                                                    {/* todo */}
                                                    <div className="modal-input-layout-item">
                                                        <DatePicker
                                                            //disabled={disabled}
                                                            onChange={(value: any) => {
                                                                setNewEffectiveDate(value);
                                                            }}
                                                            value={
                                                                newEffectiveDate
                                                                    ? moment(newEffectiveDate).format('DD-MM-YYYY')
                                                                    : '-'
                                                            }
                                                            placeholderText="Select Date"
                                                            customInput={
                                                                <div className="modal-input-field-holder date-input-holder">
                                                                    <input
                                                                        className="modal-input input-text with-border date-input input-date-cursor"
                                                                        type="text"
                                                                        placeholder={'Select Date'}
                                                                        value={
                                                                            newEffectiveDate
                                                                                ? moment(newEffectiveDate).format(
                                                                                      'DD-MM-YYYY'
                                                                                  )
                                                                                : '-'
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="detail-box-common">
                                                    <h6 className="details-box-heading-2">
                                                        To<span className="mandatory-field-text">*</span>
                                                    </h6>

                                                    <div className="modal-input-layout-item">
                                                        <DatePicker
                                                            //disabled={disabled}
                                                            minDate={
                                                                new Date(
                                                                    moment(newEffectiveDate)
                                                                        .add(1, 'days')
                                                                        .toISOString()
                                                                )
                                                            }
                                                            onChange={(value: any) => {
                                                                setNewExpireDate(value);
                                                            }}
                                                            value={
                                                                newExpireDate
                                                                    ? moment(newExpireDate).format('DD-MM-YYYY')
                                                                    : '-'
                                                            }
                                                            placeholderText="Select Date"
                                                            customInput={
                                                                <div className="modal-input-field-holder date-input-holder">
                                                                    <input
                                                                        className="modal-input input-text with-border date-input input-date-cursor"
                                                                        type="text"
                                                                        placeholder={'Select Date'}
                                                                        value={
                                                                            newExpireDate
                                                                                ? moment(newExpireDate).format(
                                                                                      'DD-MM-YYYY'
                                                                                  )
                                                                                : '-'
                                                                        }
                                                                    />
                                                                </div>
                                                            }
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-2">
                                                <QuoteItemDropdownCard
                                                    key={2}
                                                    isRequired={true}
                                                    title={'Providers'}
                                                    keyName={'providers'}
                                                    quoteData={quoteData}
                                                    setQuoteData={setQuoteData}
                                                    quoteItemData={providerData}
                                                    loading={quoteLoading}
                                                />
                                                <div className="detail-box-common">
                                                    <h6 className="details-box-heading-2">
                                                        Contract Type<span className="mandatory-field-text">*</span>
                                                    </h6>
                                                    <div className="details-box-detail-text contract-input">
                                                        <SearchableSelectBoxWithoutFormik
                                                            data={contractTypeData}
                                                            placeholder="Choose one item"
                                                            setStateValue={(value: string) => setContractType(value)}
                                                            stateValue={contractType}
                                                            fieldName={'contractType'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-2">
                                                <QuoteItemDropdownCard
                                                    key={3}
                                                    isRequired={true}
                                                    title={'Transport Mode(s)'}
                                                    keyName={'transport'}
                                                    quoteData={quoteData}
                                                    setQuoteData={setQuoteData}
                                                    quoteItemData={transportData}
                                                    loading={quoteLoading}
                                                />
                                            </div>
                                            <div className="row-2">
                                                <QuoteItemDropdownCard
                                                    key={5}
                                                    isRequired={true}
                                                    title={'Container Mode(s)'}
                                                    keyName={'container'}
                                                    quoteData={quoteData}
                                                    setQuoteData={setQuoteData}
                                                    quoteItemData={containerData}
                                                    loading={quoteLoading}
                                                />
                                            </div>
                                            <div className="row-2">
                                                <QuoteItemDropdownCard
                                                    key={4}
                                                    isRequired={true}
                                                    title={'Services'}
                                                    keyName={'services'}
                                                    quoteData={quoteData}
                                                    setQuoteData={setQuoteData}
                                                    quoteItemData={serviceData}
                                                    loading={quoteLoading}
                                                    defaultValue={defaultServices}
                                                />
                                            </div>
                                            <div className="row-6">
                                                <QuoteItemPortCard
                                                    title={'Port Matching'}
                                                    keyName={'portMatching'}
                                                    quoteData={quoteData}
                                                    setQuoteData={setQuoteData}
                                                    quoteItemData={portData}
                                                    setQuoteItemData={setPortData}
                                                    loading={quoteLoading}
                                                    saveTriggered={saveTriggered}
                                                    checkInvalidPort={checkInvalidPort}
                                                    isRequired={false}
                                                />
                                            </div>
                                            {warning && <div className="error-text">* Error - ANY is not allowed</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Confirm"
                                onClick={() => {
                                    addQuote();
                                }}
                                disabled={!validation() || buttonDisplay === 'Please Wait...'}
                            >
                                <span className="button-text footer-button-text">{buttonDisplay}</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
