import React, { useEffect, useRef, useState } from 'react';
import './trend.scss';
import '../../../../components/KendoGridJquery/data-grid.scss';
import TrendGraph from './TrendGraph';
import TrendTopFiveAgents from '../Trend/TrendTopFIveAgents/index';
import TrendTwentyAgents from '../Trend/TrendTwentyAgents/index';
import {
    // fetchTrendGraph,
    // fetchTrendMultiGraph,
    // fetchTrendGraph,
    selectTrendGraph,
    selectTrendMultiGraph,
    selectTrendDataCopy,
    updateTrendGraphFilter,
    fetchTrendGraph,
    fetchTrendMultiGraph,
} from 'src/store/reducers/tracking/trackingInsightsReducer';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { TrendDetails } from '..';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
// import { useAppDispatch } from 'src/hooks';
// import { useSelector } from 'react-redux';

interface Props {
    graphDetails: string;
    gridItem: any;
    setTitleName: any;
    titleName: string;
}

const filterName = {
    TEU: 'TEU',
    CBM: 'CBM',
    SHP: 'Shipments',
    BKG: 'Bookings',
};
const Index: React.FC<Props> = ({ graphDetails, gridItem, titleName, setTitleName }) => {
    const filterValues: string[] = gridItem?.graphFilters ?? [];
    const dispatch = useAppDispatch();
    const [filterDropDownOpen, setFilterDropDownOpen] = useState(false);
    const [filterValue, setFilterValue] = useState(filterValues?.[0] ?? '');
    const filterDropDownRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(filterDropDownRef, setFilterDropDownOpen);

    const [activeTrend, setActiveTrend] = useState(0);
    const [addedForCompare, setAddedForCompare] = useState([]);
    const trendSingleGraph = useAppSelector(selectTrendGraph);
    const trendMultiGraph = useAppSelector(selectTrendMultiGraph);
    const trendDataCopy = useAppSelector(selectTrendDataCopy);
    useEffect(() => {
        if (graphDetails === TrendDetails.Compare && trendMultiGraph?.length) {
            setAddedForCompare(
                trendMultiGraph
                    ?.map((res: any) => ({
                        id: res.id,
                        agentName: Object.keys(res)[1],
                    }))
                    ?.slice(0, 5)
            );
        }
    }, [trendMultiGraph]);
    const getCount = (id: number, name: string) => {
        const ids = trendMultiGraph?.find((res: any) => res?.id === id);
        return ids[name]
            ? Object.values(ids[name])
                  .map((el: any) => el.count)
                  .reduce((acc: number, val: number) => acc + val, 0)
            : '';
    };

    useEffect(() => {
        if (gridItem.detailPage.some((res: any) => res === TrendDetails.Compare)) {
            if (gridItem.server === 'internal') {
                dispatch(fetchTrendMultiGraph({ url: gridItem.trendUrl, server: gridItem.server }));
            } else {
                dispatch(
                    updateTrendGraphFilter({
                        data: trendDataCopy.data,
                        graph: trendDataCopy.graph,
                        graphType: TrendDetails.Compare,
                        keyToCheck: { ...trendDataCopy.keyToCheck, field: filterValue },
                    })
                );
            }
        } else if (gridItem.detailPage.some((res: any) => res === TrendDetails.Trend)) {
            if (gridItem.server === 'internal') {
                dispatch(fetchTrendGraph({ url: gridItem.trendUrl, server: gridItem.server }));
            } else {
                dispatch(
                    updateTrendGraphFilter({
                        data: trendDataCopy.data,
                        graph: trendDataCopy.graph,
                        graphType: TrendDetails.Trend,
                        keyToCheck: { ...trendDataCopy.keyToCheck, field: filterValue },
                    })
                );
            }
        }
        const title = gridItem?.titleForTrend
            ? gridItem?.titleForTrend.replace('##TYPE##', filterName[filterValue as keyof typeof filterName])
            : gridItem.title;
        setTitleName(title);
    }, [filterValue]);

    return (
        <>
            {gridItem.detailPage.some((res: any) => res === TrendDetails.Compare) && (
                <div className="insight-agent-list-data-trend">
                    <div className="container-fluid tracking-insights">
                        <div className="row">
                            <div
                                ref={filterDropDownRef}
                                className={`dropdown-wrap right-align ${
                                    filterDropDownOpen ? 'dropdown-open' : ''
                                } kendo-dropdown`}
                            >
                                <button
                                    onClick={() => setFilterDropDownOpen((prev) => !prev)}
                                    className="app-btn app-btn-secondary blue-shade"
                                >
                                    <div className="purchase-dropdown-content">
                                        <div className="dropdown-content-heading">{filterValue}</div>
                                    </div>
                                    <svg className="svg-icon arrow-icon">
                                        <use href="#downArrow">
                                            <title>Expand row</title>
                                        </use>
                                    </svg>
                                </button>

                                <div
                                    style={{ visibility: filterDropDownOpen ? 'visible' : 'hidden' }}
                                    className="dropdown-menu"
                                >
                                    {filterValues.map((res) => (
                                        <a
                                            key={res}
                                            onClick={() => {
                                                setFilterDropDownOpen(false);
                                                setFilterValue(res);
                                            }}
                                            className="menu-item app-btn filter-btn"
                                        >
                                            {res}
                                        </a>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {graphDetails === TrendDetails.Compare ? (
                <div className={`insight-trend-wrapper ${activeTrend ? 'active-trend-' + activeTrend : ''}`}>
                    <div className="left-trend-block">
                        <div className="badge-portion">
                            <div className="badge-heading">Compare ({addedForCompare.length}/5)</div>
                            <TrendTopFiveAgents
                                addedForCompare={addedForCompare}
                                setAddedForCompare={setAddedForCompare}
                                setActiveTrend={setActiveTrend}
                                getCount={getCount}
                            />{' '}
                        </div>
                        <TrendTwentyAgents
                            data={trendMultiGraph}
                            addedForCompare={addedForCompare}
                            setAddedForCompare={setAddedForCompare}
                            getCount={getCount}
                            filterValue={filterValue ?? gridItem.trendTooltipUnit}
                        />
                    </div>
                    <TrendGraph
                        trendComparison={graphDetails}
                        data={addedForCompare}
                        allData={trendMultiGraph}
                        tooltipUnit={filterValue ?? gridItem.trendTooltipUnit}
                    />
                </div>
            ) : (
                <div className="insight-trend-wrapper active-trend">
                    <TrendGraph
                        trendComparison={graphDetails}
                        data={trendSingleGraph}
                        tooltipUnit={filterValue ? filterValue : gridItem.trendTooltipUnit}
                    />
                </div>
            )}
        </>
    );
};
export default Index;
