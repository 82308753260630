import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchProductAllocationList,
    fetchProductAllocationListFailure,
    fetchProductAllocationListSuccess,
    createProductAllocationSuccess,
    createProductAllocationFailure,
    updateProductAllocationSuccess,
    updateProductAllocationFailure,
    createProductAllocation,
    updateProductAllocation,
    fetchCurrencySuccess,
    fetchCurrencyFailure,
    fetchCurrency,
    fetchPortSuccess,
    fetchPortFailure,
    fetchPort,
    fetchDestinationAddress,
    fetchDestinationAddressSuccess,
    fetchDestinationAddressFailure,
    fetchSkuList,
    fetchSkuListSuccess,
    fetchSkuListFailure,
    fetchAllocationSkuList,
    fetchAllocationSkuListSuccess,
    fetchAllocationSkuListFailure,
    removeProductAllocationSuccess,
    removeProductAllocationFailure,
    removeProductAllocation,
    fetchDistinctValues,
    fetchDistinctValuesSuccess,
    fetchDistinctValuesFailure,
} from '../../reducers/allocationOrder/productAllocationReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchProductAllocationListDetailsApi,
    createProductAllocationDetailsApi,
    updateProductAllocationDetailsApi,
    fetchCurrencyDetailsApi,
    fetchPortDetailsApi,
    fetchDestinationAddressDetailsApi,
    fetchSkuListDetailsApi,
    fetchAllocationSkuListDetailsApi,
    removeProductAllocationDetailsApi,
    fetchDistinctValuesApi,
} from '../../../services/apis/allocationOrder/productAllocationApi';

// eslint-disable-next-line
function* getProductAllocationDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchProductAllocationListDetailsApi, payload);
        yield put({ type: fetchProductAllocationListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchProductAllocationListFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getSkuDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchSkuListDetailsApi, payload);
        yield put({ type: fetchSkuListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchSkuListFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}
// eslint-disable-next-line
function* getAllocationSkuDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchAllocationSkuListDetailsApi, payload);
        yield put({ type: fetchAllocationSkuListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAllocationSkuListFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* createProductAllocationDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createProductAllocationDetailsApi, payload);
        yield put({ type: createProductAllocationSuccess, payload: response });
    } catch (error) {
        yield put({ type: createProductAllocationFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateProductAllocationDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateProductAllocationDetailsApi, payload);
        yield put({ type: updateProductAllocationSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateProductAllocationFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* removeProductAllocationDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(removeProductAllocationDetailsApi, payload);
        yield put({ type: removeProductAllocationSuccess, payload: response });
    } catch (error) {
        yield put({ type: removeProductAllocationFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* fetchDistinctValuesData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(fetchDistinctValuesApi, payload);
        yield put({ type: fetchDistinctValuesSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchDistinctValuesFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getCurrencyDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchCurrencyDetailsApi, payload);
        yield put({ type: fetchCurrencySuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchCurrencyFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getPortDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchPortDetailsApi, payload);
        yield put({ type: fetchPortSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchPortFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        // yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getDestinationAddressDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchDestinationAddressDetailsApi, payload);
        yield put({ type: fetchDestinationAddressSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchDestinationAddressFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* productAllocationSaga() {
    yield takeEvery(fetchProductAllocationList, getProductAllocationDetails);
    yield takeEvery(fetchSkuList, getSkuDetails);
    yield takeEvery(fetchAllocationSkuList, getAllocationSkuDetails);
    yield takeEvery(createProductAllocation, createProductAllocationDetailsData);
    yield takeEvery(updateProductAllocation, updateProductAllocationDetailsData);
    yield takeEvery(removeProductAllocation, removeProductAllocationDetailsData);
    yield takeEvery(fetchDistinctValues, fetchDistinctValuesData);
    yield takeEvery(fetchCurrency, getCurrencyDetails);
    yield takeEvery(fetchPort, getPortDetails);
    yield takeEvery(fetchDestinationAddress, getDestinationAddressDetails);
}

export default productAllocationSaga;
