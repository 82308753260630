import { permissionsWithProfileView } from 'src/utils/constants';

export const checkProfilePermissions = (permissions: any) => {
    /* eslint no-var: off */
    let flag = '/profile';
    var array: any[] = [];
    var array1: any[] = [];
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let roleName = '';
    let profileType = '';
    if (userData && roleIndex) {
        const { roles } = JSON.parse(userData);
        roleName = roles[roleIndex]?.roleName;
        profileType = roles[roleIndex]?.profile?.toLowerCase();
    }
    permissions?.forEach((element: any) => {
        array.push(element.type.toLowerCase());
        array1.push(element.access.toLowerCase());
        if (
            (array.includes('profiles') || array1.includes('alladmin')) &&
            (permissionsWithProfileView.includes(roleName?.toLowerCase()) || profileType?.toLowerCase() === 'forwarder')
        ) {
            flag = '/profile';
        } else if (array.includes('users')) {
            flag = '/users/forwarder';
        } else {
            flag = '/rolespermissions/forwarder';
        }
    });

    return flag;
};
