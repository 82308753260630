import moment from 'moment';
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import {
    selectCurrentFilter,
    updateFilter,
    fetchUsersList,
    selectUsers,
    Users,
    fetchWorkTrendsListSuccess,
    setActiveActivityList,
    fetchWorkTrendsList,
    fetchActivityList,
    setUserIds,
} from 'src/store/reducers/dashboard/workTrendsReducer';
import { getOrganisationData } from 'src/utils';
import { FilterList } from '../../WorkTrends';
import './workTrendsHeader.scss';

const Index: React.FC = () => {
    const dispatch = useAppDispatch();
    const currentFilter = useAppSelector(selectCurrentFilter);
    const navigate = useNavigate();
    const [showDropdown, setShowDropdown] = useState(false);
    const [selectedFilters, setSelectedFilters] = useState<Users[]>([]);
    const [search, setSearch] = useState('');
    const dropDownRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(dropDownRef, setShowDropdown);
    const { profileType, userName } = useUserProfile();
    const listOfUserOrganisations = getOrganisationData();
    const userData = localStorage.getItem('user');
    const { roles } = userData ? JSON.parse(userData) : null;
    const isExecutive = roles?.find((x: any) => x.roleName?.toLowerCase() === 'executive');
    const { progressSelectedIndex, workTrends, page, perPageCount, selectedActivityDetails, userIds } = useSelector(
        (state: RootState) => state.workTrendsData
    );
    const endDate =
        workTrends[progressSelectedIndex]?.label?.split('to')?.[1]?.trim() || workTrends[progressSelectedIndex]?.label;
    const startDate =
        workTrends[progressSelectedIndex]?.label?.split('to')?.[0]?.trim() || workTrends[progressSelectedIndex]?.label;
    const payload = {
        userIds,
        loggedInUserName: userName,
        filter: currentFilter?.toLowerCase(),
        activityType: selectedActivityDetails?.key || '',
        date:
            currentFilter === FilterList.Months
                ? endDate
                : currentFilter === FilterList.Weeks
                ? `${moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')} to ${moment(endDate, 'DD-MM-YYYY').format(
                      'YYYY-MM-DD'
                  )}`
                : moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        profile: profileType, //forwarder, client, agent
        limit: perPageCount,
        page,
        currentPage: page,
        perPageCount,
        ...listOfUserOrganisations,
        activityData: { name: '', value: -1, key: '', service: '' },
    };
    useEffect(() => {
        dispatch(fetchUsersList({}));
        return () => {
            dispatch(setActiveActivityList({ index: 0 }));
            if (!window?.location?.href?.includes('/dashboard/work-trends') && window?.location?.pathname !== '/') {
                dispatch(updateFilter(FilterList.Days));
            }
        };
    }, []);

    useEffect(() => {
        if (!showDropdown) {
            setSearch('');
            dispatch(fetchUsersList({}));
        }
    }, [showDropdown]);

    useEffect(() => {
        const userIds = Array.from(selectedFilters, (obj: any) => obj.userId);
        dispatch(setUserIds(userIds));
    }, [selectedFilters]);

    useEffect(() => {
        const { name, value, key, service } = selectedActivityDetails;
        dispatch(fetchWorkTrendsList({ filter: currentFilter, profile: profileType, userName, userIds }));
        if (value > -1) {
            if (name) {
                dispatch(fetchActivityList({ ...payload, activityData: { name, value, key, service } }));
            }
        }
    }, [userIds]);

    const users = useAppSelector(selectUsers);

    const onFilterChange = (user: Users) => {
        const selectedFiltersCopy: Users[] = JSON.parse(JSON.stringify(selectedFilters));
        const itemIndex = selectedFilters.findIndex((item: any) => item.userId === user.userId);
        if (itemIndex === -1) {
            selectedFiltersCopy.push(user);
        } else {
            selectedFiltersCopy.splice(itemIndex, 1);
        }
        setSelectedFilters(selectedFiltersCopy);
    };

    const ifSelected = (user: Users) => {
        return selectedFilters.some((item: any) => item.userId === user.userId);
    };

    // const onSearchFn = () => {
    //     if (search.trim().length) {
    //         const searchKey = search.toLocaleLowerCase();
    //         const usersCopy: Users[] = JSON.parse(JSON.stringify(users));
    //         return usersCopy?.filter((el: Users) =>
    //             searchKey ? el.fullName.toLocaleLowerCase().includes(searchKey) : true
    //         );
    //     } else {
    //         return users;
    //     }
    // };
    // const onSearch = onSearchFn();

    const onSearchChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        if (e.target.value?.length > 2) {
            dispatch(fetchUsersList({ search: e.target.value }));
        } else if (e.target.value?.length === 0) {
            dispatch(fetchUsersList({}));
        }
    };

    const userMapFn = () => {
        if (search === '') {
            const ids = new Set(users.map((d) => d.userId));
            return [...selectedFilters.filter((d) => !ids.has(d.userId)), ...users];
        } else {
            return [...users];
        }
    };

    const userMap = userMapFn();

    return (
        <div className="work-trend-header">
            <div>
                <h4 className="work-trend-title">Work Trends</h4>
                <span className="work-trend-sub-title">
                    Showing trend for Last {currentFilter === FilterList.Months ? 13 : 8} {currentFilter}
                </span>
            </div>
            <div className="work-trend-filters">
                {isExecutive ? (
                    <div
                        ref={dropDownRef}
                        className={`dropdown-wrap ${
                            showDropdown ? 'dropdown-open' : ''
                        } download-dropdown search-toast-content-holder right-align`}
                    >
                        <button onClick={() => setShowDropdown((prev) => !prev)} className="app-btn app-btn-secondary">
                            <div className="purchase-dropdown-content">
                                <span className="button-text">Users : </span>
                                <div className="content-heading-holder">
                                    <span className="content-heading">
                                        {selectedFilters?.length ? selectedFilters[0]?.fullName : 'All'}
                                    </span>
                                    {selectedFilters?.length > 1 ? (
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{`+${selectedFilters?.length - 1}`}</div>
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <svg className="svg-icon arrow-icon">
                                <use href="#downArrow">
                                    <title>Expand row</title>
                                </use>
                            </svg>
                        </button>
                        <div className="dropdown-menu">
                            <>
                                <div className="filter-menu searchable-dropdown-common">
                                    <div className="search-entry-holder">
                                        <input
                                            placeholder="Search"
                                            type="text"
                                            value={search}
                                            onChange={(e) => onSearchChanged(e)}
                                            className="search-input input-text grid-search-field"
                                        ></input>
                                        <div
                                            className={`search-toolip ${
                                                search?.length > 0 && search?.length < 3 ? 'tooltip-show' : ''
                                            }`}
                                        >
                                            Enter atleast 3 characters to search
                                        </div>
                                        <svg className="svg-icon search-icon">
                                            <use xlinkHref="#searchIcon"></use>
                                        </svg>
                                        <button
                                            className="app-btn text-close-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                if (search?.trim()?.length) {
                                                    setSearch('');
                                                    dispatch(fetchUsersList({}));
                                                }
                                            }}
                                        >
                                            <svg className="svg-icon text-close-icon">
                                                <use xlinkHref="#closeIcon">
                                                    <title>Close</title>
                                                </use>
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="checkbox-wrapper customscrollbar">
                                        {userMap?.map((user: Users) => (
                                            <div key={user.userId} className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                        checked={ifSelected(user)}
                                                        onChange={() => onFilterChange(user)}
                                                    />
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use xlinkHref="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label sm">{user.fullName}</div>
                                                </label>
                                            </div>
                                        ))}
                                        {users?.length === 0 && (
                                            <div className="no-data-content">
                                                <svg className="svg-icon grid-no-content-icon ">
                                                    <use xlinkHref="#gridNoContentIcon"></use>
                                                </svg>
                                                <p className="no-content-message">No Matches Found</p>
                                            </div>
                                        )}
                                    </div>

                                    <div className="clear-btn">
                                        <button
                                            onClick={() => {
                                                setSelectedFilters([]);
                                                setSearch('');
                                                dispatch(fetchUsersList({}));
                                            }}
                                            className="app-btn"
                                            title="Clear All"
                                        >
                                            <span className="button-text">Clear All</span>
                                        </button>
                                    </div>
                                </div>
                            </>
                        </div>
                    </div>
                ) : null}
                <div className="date-filter">
                    {(Object.keys(FilterList) as (keyof typeof FilterList)[]).map((key) => (
                        <button
                            key={FilterList[key]}
                            onClick={() => {
                                if (currentFilter !== FilterList[key]) {
                                    dispatch(setActiveActivityList({ index: 0 }));
                                    dispatch(updateFilter(FilterList[key]));
                                    dispatch(fetchWorkTrendsListSuccess({ data: [] }));
                                }
                            }}
                            className={`app-btn calendar-set ${currentFilter === FilterList[key] ? 'active' : ''}`}
                        >
                            {FilterList[key]}
                        </button>
                    ))}
                </div>
                <button
                    onClick={() => {
                        navigate('/');
                        if (selectedFilters?.length) {
                            dispatch(setUserIds([]));
                        }
                    }}
                    className="view-nav grid-icon icon-nav "
                    title="Collapse"
                >
                    <svg className="svg-icon maxi-icon ">
                        <use xlinkHref="#minimaxIcon">
                            <title>Collapse</title>
                        </use>
                    </svg>
                </button>
            </div>
        </div>
    );
};
export default Index;
