import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    getAvailablePoLines,
    listPoLineResponse,
    searchAvailablePoLines,
} from '../../../services/apis/bookingProposalBcn/availablePoLineApi';
import {
    fetchAvailablePoLineData,
    isLoadingFn,
    searchAvailablePoLineDataSuccess,
    searchAvailablePoLineData,
} from '../../reducers/bookingProposalBcn/availablePoLineReducer';

function* getAvailablePoLineListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const availablePoLineList: AxiosResponse<listPoLineResponse> = yield call(getAvailablePoLines, payload);
        payload['response'] = availablePoLineList;
        yield put({ type: fetchAvailablePoLineData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* searchAvailablePoLineListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const availablePoLineList: AxiosResponse<listPoLineResponse> = yield call(searchAvailablePoLines, payload);
        payload['response'] = availablePoLineList;
        yield put({ type: searchAvailablePoLineDataSuccess, payload: payload });
    } catch (err) {
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* availablePoLineListBcnSaga() {
    yield takeEvery(fetchAvailablePoLineData, getAvailablePoLineListData);
    yield takeEvery(searchAvailablePoLineData, searchAvailablePoLineListData);
}

export default availablePoLineListBcnSaga;
