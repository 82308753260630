import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    fetchColoaderBasicDetailsList,
    fetchColoaderBasicDetailsListSuccess,
    fetchColoaderBasicDetailsListFailed,
    fetchForwarderRankList,
    fetchForwarderRankListSuccess,
    fetchForwarderRankListFailed,
    updateForwarderRankSuccess,
    updateForwarderRankFailed,
    updateForwarderRank,
} from '../../../reducers/profiles/coloader/basicDetailsReducer';
import {
    getColoaderBasicDetailsData,
    getForwarderRankingData,
    updateForwarderRankData,
} from '../../../../services/apis/profiles/coloader/basicDetailsApi';

function* getColoaderBasicDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getColoaderBasicDetailsData, payload);
        yield put({ type: fetchColoaderBasicDetailsListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchColoaderBasicDetailsListFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* getForwarderRanking({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getForwarderRankingData, payload);
        yield put({ type: fetchForwarderRankListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchForwarderRankListFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* updateForwarderRanking({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(updateForwarderRankData, payload);
        yield put({ type: updateForwarderRankSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateForwarderRankFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* coloaderBasicDetailsSaga() {
    yield takeEvery(fetchColoaderBasicDetailsList, getColoaderBasicDetails);
    yield takeEvery(fetchForwarderRankList, getForwarderRanking);
    yield takeEvery(updateForwarderRank, updateForwarderRanking);
}

export default coloaderBasicDetailsSaga;
