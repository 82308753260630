import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

interface GridProps {
    rs?: any;
}

const GridLayout = (gridProps: GridProps) => {
    const token = `${localStorage.getItem('token') ?? ''}`;
    if (!token) {
        return null;
    }

    return (
        <Helmet>
            <script>
                {`
                $("#grid").kendoGrid({
                    dataSource: {
                        serverPaging: true,
                        serverSorting: true,
                        serverFiltering: true,
                        pageSize: 1000,
                        //sort : { field: "RI_CD_Status", dir: "desc" },
                        transport: {
                            read: {
                                url: '${externalBaseUrl}/cix/scr/qms/config/quotesection',
                                headers: {
                                    Authorization: 'Bearer: ${token}'
                                },
                                dataType: 'json'
                            },
                            update: {
                                url: '${externalBaseUrl}/cix/scr/qms/config/quotesection',
                                headers: {
                                    Authorization: 'Bearer: ${token}'
                                },
                                dataType: 'json',
                                type: "PATCH",
                                contentType: 'application/json'
                            },
                            destroy: {
                                url: '${externalBaseUrl}/cix/scr/qms/config/quotesection',
                                headers: {
                                    Authorization: 'Bearer: ${token}'
                                },
                                dataType: 'json',
                                type: "DELETE",
                                contentType: 'application/json'
                            },
                            create: {
                                url: '${externalBaseUrl}/cix/scr/qms/config/quotesection',
                                headers: {
                                    Authorization: 'Bearer: ${token}'
                                }
                                ,
                                dataType: 'json',
                                type: "POST",
                                contentType: 'application/json'
                            },
                            parameterMap: function (data, type) {
                                if (type == 'update' || type == 'create' || type == 'destroy') {
                                    data = JSON.stringify(data);
                                }
                                return data;
                            }
                        },
                        schema: {
                            total: function (response) {
                                return response.total;
                            },
                            data: function (response) {
                                return response.data;
                            },
                            model: {
                                id: "QS_PK",
                                fields: {
                                    QS_PK: { type: "string", hidden: true },
                                    QS_Code: { type: "string", validation: { required: true, maxlength: '3',
                                        qscodevalidation: function (input) {
                                            if (input.is("[name='QS_Code']") && input.val() != "") {
                                                input.attr("data-qscodevalidation-msg", "Code should always be with capital letters");
                                                return /[A-Z0-9]/.test(input.val());
                                            }
                                            return true;
                                        }
                                    }},
                                    QS_Name: { type: "string", validation: { required: true } },
                                    QS_Description: { type: "string", validation: { required: true } },
                                    QS_Disclaimer: { type: "string", validation: { required: true } },
                                    QS_CD_RateType: { type: "string", validation: { required: true } }
                                }
                            }
                        },
                        batch: true
                    },

                    toolbar: [
                        {
                            name: "create",
                            template:
                                '<button class="k-button k-grid-add app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk-pen fa-2xl" style="--fa-primary-color: \\\\#0e9fc4; --fa-secondary-color: \\\\#0e9fc4;"></i>New</button>',
                        },
                        {
                            name: "save",
                            template:
                                '<button class="k-button k-grid-save-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk fa-2xl" style="--fa-primary-color: \\\\#003cb3; --fa-secondary-color: \\\\#003cb3;"></i>Save</button>',
                        },
                        {
                            name: "cancel",
                            template:
                                '<button class="k-button k-grid-cancel-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk-circle-xmark fa-2xl" style="--fa-primary-color: \\\\#ff004c; --fa-secondary-color: \\\\#ff004c;"></i>Cancel</button>',
                        },
                        {
                            name: 'search',
                            // template: '<input type="search" id="searchBox" class="k-textbox llama" style="width: 250px"/>',
                        }
                    ],
                    selectable: "multiple row",
                    navigatable: true,
                    height: 500,
                    groupable: true,
                    resizable: true,
                    sortable: true,
                    pageable: {
                        refresh: true,
                        pageSizes: [10, 25, 100, 500, 1000, "all"],
                        buttonCount: 5,
                        alwaysVisible: true
                    },
                    groupable: true,
                    editable: { confirmation: false, mode: "incell" },
                    pageSize: 100,
                    columns: [
                        { field: "QS_Code", title: "Code" },
                        { field: "QS_Name", title: "Name" },
                        { field: "QS_Description", title: "Description" },
                        { field: "QS_Disclaimer", title: "Disclaimer" },
                        {
                            field: "QS_CD_RateType", title: "Rate Grouping",
                            editor: function (container, options) {
                                var input = $('<input required name="' + options.field + '" data-value-primitive="true" style="width:400px" />')
                                console.log(options)
                                // append to the editor container
                                input.appendTo(container);
                                input.kendoDropDownList({
                                    dataTextField: "CD_Value",
                                    dataValueField: "CD_Key",
                                    template: '<span class="hub-code">#:CD_Key# </span> #:CD_Value#',
                                    dataSource: {
                                        serverFiltering: true,

                                        transport: {
                                            read: {
                                                // this needs to be worked on for the user's saved filters.
                                                url: "${externalBaseUrl}/cix/scr/admin/config/QTE_RateType/lst/CD_Key/CD_Value",
                                                dataType: 'json',
                                                headers: {
                                                    Authorization: 'Bearer: ${token}'
                                                },
                                            },
                                        },
                                        schema: {
                                            data: function (response) {
                                                return response.data;
                                            },
                                        },
                                    },
                                    filter: "contains",
                                    minLength: 2,
                                    enforceMinLength: true,
                                })
                            }
                        }
                    ]
                })        
            `}
            </script>
        </Helmet>
    );
};

export default GridLayout;
