import { FilterList } from 'src/pages/Dashboard/InsightsExpanded/enums';

//============== date calculation pre 14-days/months/weeks ==================//
export function startDateCalculation(currentFilter: string) {
    let end_date: Date | null = null;
    if (currentFilter) {
        if (currentFilter === FilterList.Days) {
            end_date = new Date(Date.now() - 12 * 24 * 60 * 60 * 1000);
        } else if (currentFilter === FilterList.Weeks) {
            end_date = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() - 84);
        } else {
            end_date = new Date(new Date().setMonth(new Date().getMonth() - 12));
        }
        return convertDateFormat(end_date);
    }
}
//=========================================================================//

//================= convert date to yyyy/mm/dd format ========================//
export function convertDateFormat(date: Date | null) {
    if (date) {
        const offset = date.getTimezoneOffset();
        date = new Date(date.getTime() - offset * 60 * 1000);
        return date.toISOString().split('T')[0];
    }
}
//===========================================================================//
