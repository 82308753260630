import BookingProposalPop from './BookingProposalPop';
import TransitPop from './TransitPop';
import BookingAtPort from './BookingsAtPort';
import PosAtCountry from './PosAtCountry';
import BookingsAtCountry from './BookingsAtCountry';

const PopUps = (type: string) => {
    switch (type) {
        case 'bookingsIntransitArray':
            return <BookingProposalPop />;
        case 'cluster':
            return <TransitPop />;
        case 'bookingsAtOriginPort':
            return <BookingAtPort />;
        case 'bookingsAtDestinationPort':
            return <BookingAtPort />;
        case 'posAtOriginCountry':
            return <TransitPop />;
        case 'bookingsAtOriginCountry':
            return <BookingsAtCountry />;
        default:
            return <BookingAtPort />;
    }
};
export default PopUps;
