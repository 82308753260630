import { post, patch, get } from '../../HttpMethods';
import fileDownload from 'js-file-download';
import axios from 'axios';
import { statusOfPosWithUrls } from 'src/utils/constants';
import { externalBaseUrl, profileCode } from 'src/utils/kendoGrid/insights';

export interface PoData {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    clientName: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
}

export interface PoResponse {
    content: PoData[];
    totalPages: number;
    totalElements: number;
}

export interface PoSearchData {
    content: PoData[];
    totalPages: number;
    totalElements: number;
}

// Get All Datas
export const getPoData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    profileType: string;
}) => {
    try {
        const res = await post(
            `api/ord/po/list/${payload.profileType}?size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getFilterValues = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/ord/po/distinct/${payload.profileType}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getStatusCount = async (payload: { data: any; profileType: string; poType: string }) => {
    try {
        const cixApiResponse = await get('cix/scr/forwarding/purchaseorder/all/summary', null, externalBaseUrl);
        const data = cixApiResponse.data;
        let result = {};
        for (let index = 0; index < data.length; index++) {
            result = { ...result, [data[index].name]: data?.[index]?.total ?? 0 };
        }
        return result;
    } catch (err) {
        return console.error(err);
    }
};

export const exportPoList = async (type: string, organisationData: any, profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const sortColumn = organisationData?.sortColumn;
        const sortOrder = organisationData?.sortOrder;
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/ord/po/export/${profileType}?type=${type}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(organisationData),
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = organisationData?.type === 'IBPO' ? 'InboundPO' : 'PurchaseOrders';
                const fileType = type === 'excel' ? '.xlsx' : '.pdf';
                fileDownload(result, fileName + fileType);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const discardPO = async (payload: { poPk: string; userName: string; name: string; buttonType: string }) => {
    try {
        const res = await patch(
            `api/ord/po/${payload.buttonType === 'Restore' ? 'restore' : 'discard'}/${payload.poPk}?userName=${
                payload.userName
            }&name=${payload.name}`,
            null
        );
        return res.success;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const cancelPO = async (payload: { poPk: string; data: { reason: string; userName: string; name: string } }) => {
    try {
        const res = await patch(
            `api/ord/po/cancel/${payload.poPk}?reason=${payload.data.reason}&userName=${payload.data.userName}&name=${payload.data.name}`,
            payload.data
        );
        return res.success;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
