import React from 'react';
import { ShipmentTabProps } from '.';
import SubShipment from './SubShipment';

const Shipment: React.FC<ShipmentTabProps> = ({ data }) => {
    return (
        <>
            <div className="card details-box custom" style={{minHeight: '110px'}}>
                <div className="card-head-holder add-btn-holder">
                    <div className="card-head-btn-edit-holder">
                        <h6 className="details-box-heading-2">Shipment</h6>
                    </div>
                </div>

                <div className="card-detail-col">
                    {/* <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Shipment No.</h6>
                        <div className="details-box-content">{data?._shipmentId}</div>
                    </div> */}
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">House Bill No.</h6>
                        <div className="details-box-content">{data?._wayBillNumber}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Transport Mode</h6>
                        <div className="details-box-content">{data?.transportMode?.code}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Consignor</h6>
                        <div className="details-box-content">{data?._consignor}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Consignee</h6>
                        <div className="details-box-content">{data?._consignee}</div>
                    </div>
                </div>
            </div>
            {data?.subShipmentCollection?.subShipment?.length! > 0 && (
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Sub Shipments</h6>
                        </div>
                    </div>

                    <div className="card-detail-col">
                        <SubShipment data={data} />
                    </div>
                </div>
            )}
        </>
    );
};

export default Shipment;
