import * as Yup from 'yup';

export const clientTableHeaders = {
    status: 'orgName',
};

export const shippingTableHeaders = {
    status: 'oceanCarrierName',
};

export const agentTableHeaders = {
    status: 'orgName',
};
export const coloaderTableHeaders = {
    status: 'name',
};

export const vendortTableHeaders = {
    status: 'orgName',
};

export const poHeaders = {
    status: 'poStatus',
    createdDate: 'createdDate',
};

export const inventoryHeaders = {
    modifiedOn: 'modifiedDateTime',
};

export const inboundPoHeaders = {
    poOrderDate: 'poOrderDate',
    createdDate: 'createdDate',
};

export const bpHeaders = {
    status: 'bpStatus',
    createdDate: 'createdDateTime',
};

export const availablePoHeaders = {
    poNumber: 'poNumber',
};

export const availableAoHeaders = {
    poNumber: 'poNumber',
};

export const availablePoHeaderLcl = {
    createdOn: 'createdOn',
};

export const receiptHeaders = {
    status: 'status',
    createdDate: 'createdDate',
};

export const aoHeaders = {
    orderDate: 'orderDate',
    createdDate: 'createdDate',
};

export const sopTableHeaders = {
    Name: 'cd_docname',
    Version: 'cd_version',
    Author: 'cd_author',
    ReviewedBy: 'cd_reviewedby',
    ClientSignatory: 'cd_clientsignatory',
    ActiveDate: 'cd_activedate',
    ExpiryDate: 'cd_enddate',
    ClientAcceptanceDate: 'cd_clientacceptancedate',
    Status: 'cd_status',
};

export const contractTableHeaders = {
    Name: 'cd_docname',
    Version: 'cd_version',
    Author: 'cd_author',
    ForwarderSignatory: 'cd_forwardersignatory',
    ClientSignatory: 'cd_clientsignatory',
    StartDate: 'cd_startdate',
    EndDate: 'cd_enddate',
    Renewal: 'cd_renewal',
    Status: 'cd_status',
    Type: 'cd_doctype',
};
export const historyTableHeaders = {
    UserName: 'name',
    ProfileType: 'ul_profileType',
    ProfileName: 'ul_profileName',
    ActionedBy: 'modifiedByUser',
    Activity: 'ul_action',
    ActionedOn: 'createdDateTime',
};
export const agentPortDetailsTableHeaders = {
    PortName: 'portname',
    Location: 'location',
    Direction: 'direction',
    Air: 'air',
    Sea: 'sea',
    Rail: 'rail',
    Road: 'road',
    PortAddress: 'portaddress',
};
export const paginationLimit = [10, 20, 30];

export const integrationColumnHeaders = ['Purchase Order', 'BLP', 'Shipment'];

export const vendorIntegrationColumnHeaders = ['Value 1', 'Value 2'];

export const vendorIntegrationRowHeaders = ['None', 'Type 1', 'Type 2'];

export const integrationRowHeaders = [
    'None',
    'Web Services',
    'SFTP',
    'SFTP - CSV',
    'SFTP - TSV',
    'SFTP - XML',
    'SFTP - Pipe',
    'RPA - XLS',
    'RPA - PDF',
    'RPA - EML',
];

export const profileTypes = [
    { name: 'Forwarder', value: 'FORWARDER' },
    { name: 'Client', value: 'Client' },
    { name: 'Agent', value: 'Agent' },
    { name: 'Vendor', value: 'Vendor' },
    { name: 'Carrier', value: 'Shipping' },
];

export const rolesCreationData = [
    {
        title: 'Profiles',
        content: ['Create Vendor', 'Edit Vendor Profiles', 'Delete Vendor Profiles'],
    },
    //{
    //    title: 'Clients',
    //    content: ['Create Vendor  ProfileListing', 'Edit  ProfileListing', 'Delete  ProfileListing'],
    //},
    {
        title: 'Users',
        content: ['Create Users', 'Edit Users', 'Delete Users'],
    },
    {
        title: 'Roles',
        content: ['Create Roles', 'Edit Roles', 'Delete Roles'],
    },
];

export const reactSelectStyle = {
    control: (base: any, state: any) => ({
        ...base,
        background: 'white',
        borderRadius: '5px',
        transitionProperty: 'box-shadow,outline-color',
        transitionDuration: '0.3s',
        minHeight: '34px',
        height: '34px',
        fontFamily: 'Poppins',
        fontSize: '1.2rem',
        backgroundColor: state.isDisabled && '#FAFAFA',
        border: state.isFocused ? '1px solid #2028EB' : state.isDisabled ? '1px solid #cccccc' : '1px solid #828282',
        boxShadow: state.isFocused ? '0px 0px 0px 4px rgb(32 40 235 / 8%)' : '1px 1px 12px 3px transparent',
        outline: state.isFocused ? '1px solid rgba(32, 40, 235, 0.3)' : '1px solid transparent',
        '&:hover': {
            border: state.isFocused ? '1px solid #2028EB' : '1px solid #828282',
        },
        ...(state.isDisabled && {
            pointerEvents: 'auto',
            cursor: 'not-allowed',
        }),
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        color: state.isSelected ? '#2028EB;' : '#000',
        zIndex: 1000,
    }),
    //menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
    placeholder: (defaultStyles: any, state: any) => {
        return {
            ...defaultStyles,
            fontFamily: 'Poppins',
            fontSize: '1.2rem',
            whiteSpace: 'nowrap',
            color: state.isDisabled ? '#B3B3B3' : 'hsl(0, 0%, 50%)',
        };
    },
    singleValue: (provided: any) => ({
        ...provided,
        color: 'rgb(0, 0, 0)',
        whiteSpace: 'wrap',
    }),
    clearIndicator: (base: any) => ({
        ...base,
        color: '#3E4954',
        '&:hover': {
            color: '#3E4954',
        },
    }),
    noOptionsMessage: (base: any) => ({ ...base, minHeight: '116px' }),
    CrossIcon: (base: any) => ({ ...base }),
    menuPortal: (provided: any) => ({ ...provided, zIndex: 1000 }),
    menu: (base: any) => ({ ...base, zIndex: 1000 }),
};

export const reactSelectTheme = (theme: any) => ({
    ...theme,
    borderRadius: 5,
    colors: {
        ...theme.colors,
        primary25: '#F3F4FF',
        primary: '#F3F4FF',
    },
});

export const tableProperties = {
    clients: {
        name: 'Client',
        childKey: 'childClients',
        childDisplayKey: 'orgCode',
        navigationKey: 'clientId',
        navigationName: 'orgName',
        childDisplaySubKey: null,
        isMultiLevel: true,
        customGridStyle: '',
        headerRowCount: 1,
        hasCustomStatus: true,
        tableHeaderList: [
            {
                headerName: 'ORGANISATION CODE',
                filter: false,
                sort: true,
                key: 'orgCode',
                style: 'grid-cell-header col-client-id',
                isExpandable: true,
                sortKey: 'orgCode',
            },
            {
                headerName: 'NAME',
                filter: false,
                sort: true,
                key: 'orgName',
                style: 'grid-cell-header col-client-name',
                sortKey: 'orgName',
            },
            {
                headerName: 'RELATIONSHIP',
                filter: false,
                sort: false,
                key: 'relationship',
                style: 'grid-cell-header col-client-relationship',
                sortKey: 'relationship',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'clientStatus',
                style: 'grid-cell-header col-client-status',
                type: 'status',
                sortKey: 'clientStatus',
                specialFilter: true,
                filterKey: 'clientStatus',
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-client-view',
            },
        ],
    },
    shippingLines: {
        name: 'Carrier',
        childKey: 'childShippingLines',
        childDisplayKey: 'orgCode',
        childDisplaySubKey: null,
        navigationKey: 'shippingLineId',
        navigationName: 'orgName',
        isMultiLevel: true,
        headerRowCount: 1,
        customGridStyle: '',
        hasCustomStatus: true,
        tableHeaderList: [
            {
                headerName: 'ORGANISATION CODE',
                filter: false,
                sort: true,
                key: 'orgCode',
                style: 'grid-cell-header col-shipping-id',
                isExpandable: true,
                sortKey: 'orgCode',
            },
            {
                headerName: 'NAME',
                filter: false,
                sort: true,
                key: 'oceanCarrierName',
                style: 'grid-cell-header col-ocean-career-name',
                sortKey: 'oceanCarrierName',
            },
            {
                headerName: 'CARRIER CODE',
                filter: false,
                sort: true,
                key: 'shippingLineCode',
                style: 'grid-cell-header col-shipping-line-code',
                sortKey: 'shippingLineCode',
            },
            {
                headerName: 'CARRIER CATEGORY',
                filter: false,
                sort: true,
                key: 'carrierCategoryCode',
                style: 'grid-cell-header col-career-category',
                sortKey: 'carrierCategoryCode',
                tooltipName: 'carrierCategoryName',
            },
            {
                headerName: 'RELATIONSHIP',
                filter: false,
                sort: false,
                key: 'relationship',
                style: 'grid-cell-header col-shipping-relationship',
                sortKey: 'relationship',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'shippingLineStatus',
                style: 'grid-cell-header col-shipping-status',
                sortKey: 'shippingLineStatus',
                specialFilter: true,
                filterKey: 'shippingStatus',
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-shipping-action',
                bodyStyle: 'grid-cell',
            },
        ],
    },
    coloader: {
        name: 'Coloader',
        childKey: 'childColoaders',
        childDisplayKey: 'orgCode',
        childDisplaySubKey: null,
        navigationKey: 'coloaderId',
        navigationName: 'orgName',
        navigationId: 'shippingLineId',
        navigationPath: '/coloader/:id/basic_detail',
        isMultiLevel: false,
        headerRowCount: 1,
        customGridStyle: '',
        hasCustomStatus: true,
        tableHeaderList: [
            {
                headerName: 'ORGANISATION CODE',
                sort: true,
                key: 'code',
                style: 'grid-cell-header col-coloader-code',
                sortKey: 'code',
                hasfilter: true,
            },
            {
                headerName: 'NAME',
                filter: false,
                sort: true,
                key: 'name',
                style: 'grid-cell-header col-coloader-name',
                sortKey: 'name',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'status',
                style: 'grid-cell-header col-coloader-status',
                sortKey: 'status',
                specialFilter: true,
                filterKey: 'status',
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header  col-coloader-action',
                bodyStyle: 'grid-cell',
            },
        ],
    },
    agents: {
        name: 'Agents',
        childKey: 'childAgents',
        childDisplayKey: 'orgCode',
        childDisplaySubKey: null,
        navigationKey: 'agentId',
        navigationName: 'orgName',
        isMultiLevel: true,
        headerRowCount: 1,
        customGridStyle: '',
        hasCustomStatus: true,
        tableHeaderList: [
            {
                headerName: 'ORGANISATION CODE',
                filter: false,
                sort: true,
                key: 'orgCode',
                style: 'grid-cell-header col-agent-id',
                isExpandable: true,
                sortKey: 'orgCode',
            },
            {
                headerName: 'NAME',
                filter: false,
                sort: true,
                key: 'orgName',
                style: 'grid-cell-header col-agent-name',
                sortKey: 'orgName',
            },
            {
                headerName: 'BLP',
                filter: false,
                sort: true,
                key: 'blp',
                style: 'grid-cell-header col-agent-relationship',
                sortKey: 'blp',
            },
            {
                headerName: 'COUNTRY',
                filter: false,
                sort: true,
                key: 'agentPrimaryCountry',
                style: 'grid-cell-header col-agent-country',
                sortKey: 'agentPrimaryCountry',
            },
            {
                headerName: 'RELATIONSHIP',
                filter: false,
                sort: false,
                key: 'relationship',
                style: 'grid-cell-header col-agent-relationship',
                sortKey: 'relationship',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'agentStatus',
                style: 'grid-cell-header col-agent-status',
                sortKey: 'agentStatus',
                specialFilter: true,
                filterKey: 'agentStatus',
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-agent-action',
            },
        ],
    },
    vendors: {
        name: 'Vendors',
        childKey: 'childVendors',
        childDisplayKey: 'orgCode',
        childDisplaySubKey: null,
        navigationKey: 'vendorId',
        navigationName: 'orgName',
        isMultiLevel: true,
        customGridStyle: '',
        hasCustomStatus: true,
        headerRowCount: 1,
        tableHeaderList: [
            {
                headerName: 'ORGANISATION CODE',
                filter: false,
                sort: true,
                key: 'orgCode',
                style: 'grid-cell-header col-vendor-id',
                isExpandable: true,
                sortKey: 'orgCode',
            },
            {
                headerName: 'NAME',
                filter: false,
                sort: true,
                key: 'orgName',
                style: 'grid-cell-header col-vendor-name',
                sortKey: 'orgName',
            },
            {
                headerName: 'COUNTRY',
                filter: false,
                sort: true,
                key: 'vendorCountry',
                style: 'grid-cell-header col-vendor-country',
                sortKey: 'vendorCountry',
            },
            {
                headerName: 'RELATIONSHIP',
                filter: false,
                sort: false,
                key: 'relationship',
                style: 'grid-cell-header col-vendor-relationship',
                sortKey: 'relationship',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'vendorStatus',
                style: 'grid-cell-header col-vendor-status',
                sortKey: 'vendorStatus',
                specialFilter: true,
                filterKey: 'vendorStatus',
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-vendor-action',
            },
        ],
    },
    purchaseOrder: {
        name: 'Purchase Orders',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'poNumber',
        isMultiLevel: false,
        customGridStyle: '',
        headerRowCount: 1,
        hasCustomStatus: false,
        tableHeaderList: [
            {
                headerName: 'PO NUMBER',
                filter: true,
                sort: true,
                key: 'poNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-aligned col-po-number',
                isExpandable: true,
                sortKey: 'poNumber',
                columnWidth: 188,
                bodyStyle: 'grid-cell grid-sticky-column sticky-left-aligned',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'poStatus',
                style: 'grid-cell-header col-po-status',
                sortKey: 'poStatus',
                columnWidth: 185,
            },

            {
                headerName: 'SHIPPING MODE',
                filter: true,
                sort: true,
                key: 'shippingmode',
                style: 'grid-cell-header col-po-shipping-mode',
                sortKey: 'shippingmode',
                columnWidth: 206,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-po-client',
                sortKey: 'clientName',
                columnWidth: 258,
            },
            {
                headerName: 'START SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'startShipWindowDate',
                style: 'grid-cell-header  col-order-date',
                sortKey: 'startShippingWindow',
                isDate: true,
                columnWidth: 206,
            },
            {
                headerName: 'END SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'endShipWindowDate',
                style: 'grid-cell-header col-order-date',
                sortKey: 'endShippingWindow',
                isDate: true,
                columnWidth: 206,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'goodsReadyDate',
                style: 'grid-cell-header  col-order-date',
                sortKey: 'goodsReadyDate',
                isDate: true,
                columnWidth: 206,
                hasToolTip: true,
            },

            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-po-vendor',
                sortKey: 'vendorName',
                columnWidth: 258,
            },
            {
                headerName: 'AGENT',
                filter: true,
                sort: true,
                key: 'agentName',
                style: 'grid-cell-header col-po-agent',
                sortKey: 'agentName',
                columnWidth: 195,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                key: 'originPort',
                style: 'grid-cell-header col-port-origin',
                sortKey: 'originPort',
                columnWidth: 195,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-port-destination',
                sortKey: 'destinationPort',
                columnWidth: 258,
            },
            {
                headerName: 'DESTINATION TYPE',
                filter: true,
                sort: true,
                key: 'destinationType',
                style: 'grid-cell-header col-port-destination',
                sortKey: 'destinationType',
                columnWidth: 258,
            },
            {
                headerName: 'RELEASE TYPE',
                filter: true,
                sort: true,
                key: 'releaseType',
                style: 'grid-cell-header col-port-incoterm',
                sortKey: 'releaseType',
                columnWidth: 195,
            },
            {
                headerName: 'INCOTERM',
                filter: true,
                sort: true,
                key: 'incoTerm',
                style: 'grid-cell-header col-port-incoterm',
                sortKey: 'incoTerm',
                columnWidth: 195,
            },
            {
                headerName: 'ORDER DATE',
                filter: false,
                sort: true,
                key: 'poOrderDate',
                style: 'grid-cell-header col-order-date',
                sortKey: 'poOrderDate',
                columnWidth: 206,
                isDate: true,
            },
            {
                headerName: 'CREATED BY',
                filter: true,
                sort: true,
                key: 'createdBy',
                style: 'grid-cell-header col-created-by',
                sortKey: 'createdBy',
                columnWidth: 195,
            },
            {
                headerName: 'CREATION DATE',
                filter: true,
                sort: true,
                key: 'createdDate',
                style: 'grid-cell-header col-createdDate',
                sortKey: 'createdDate',
                columnWidth: 195,
                isDate: true,
                isFilterRightAligned: true,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-po-action',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    bookingProposal: {
        name: 'Booking Proposal',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'bpNumber',
        navigationKey: 'bpId',
        headerRowCount: 1,
        customGridStyle: '',
        isMultiLevel: false,
        hasCustomStatus: false,
        tableHeaderList: [
            {
                headerName: 'BOOKING NO.',
                filter: true,
                sort: true,
                key: 'bpNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-aligned col-booking-number',
                isExpandable: true,
                sortKey: 'bpNumber',
                columnWidth: 191,
                bodyStyle: 'grid-sticky-column sticky-left-aligned',
            },
            {
                headerName: 'HOUSE BILL NO.',
                filter: true,
                sort: true,
                key: 'houseBillNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-second-aligned col-booking-housebill',
                bodyStyle: 'grid-sticky-column sticky-left-second-aligned',
                sortKey: 'houseBillNumber',
                columnWidth: 180,
                isExpandable: true,
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'bpStatus',
                style: 'grid-cell-header col-booking-status',
                sortKey: 'bpStatus',
                columnWidth: 248,
            },
            {
                headerName: 'PO NUMBER',
                filter: true,
                sort: true,
                key: 'poNumber',
                style: 'grid-cell-header col-po-number',
                sortKey: 'poNumber',
                columnWidth: 191,
                hasToolTip: true,
            },
            {
                headerName: 'CONTAINER MODE',
                filter: true,
                sort: true,
                key: 'bpType',
                style: 'grid-cell-header col-container-mode',
                sortKey: 'bpType',
                columnWidth: 230,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-booking-client',
                sortKey: 'clientName',
                columnWidth: 251,
            },
            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-booking-vendor',
                sortKey: 'vendorName',
                columnWidth: 328,
            },
            {
                headerName: 'AGENT',
                filter: true,
                sort: true,
                key: 'agentName',
                style: 'grid-cell-header col-booking-vendor',
                sortKey: 'agentName',
                columnWidth: 328,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                key: 'originPort',
                style: 'grid-cell-header col-booking-origin',
                sortKey: 'originPort',
                columnWidth: 251,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-booking-destination',
                sortKey: 'destinationPort',
                columnWidth: 251,
            },
            {
                headerName: 'DESTINATION TYPE',
                filter: true,
                sort: true,
                key: 'destinationType',
                style: 'grid-cell-header col-booking-destination',
                sortKey: 'destinationType',
                columnWidth: 171,
            },
            {
                headerName: 'Hub Link',
                filter: true,
                sort: true,
                key: 'hubLink',
                style: 'grid-cell-header col-booking-hublink',
                sortKey: 'hubLink',
                columnWidth: 190,
                colorFilter: true,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: true,
                sort: true,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-goods-ready-date',
                sortKey: 'goodsReadyDate',
                columnWidth: 251,
                isDate: true,
                hasToolTip: true,
            },
            {
                headerName: 'ETD',
                filter: true,
                sort: true,
                key: 'plannedEtd',
                style: 'grid-cell-header col-booking-etd',
                sortKey: 'plannedEtd',
                columnWidth: 180,
                isDate: true,
            },
            {
                headerName: 'ETA',
                filter: true,
                sort: true,
                key: 'plannedEta',
                style: 'grid-cell-header col-booking-eta',
                sortKey: 'plannedEta',
                columnWidth: 180,
                isDate: true,
            },
            {
                headerName: 'RELEASE TYPE',
                filter: true,
                sort: true,
                key: 'releaseType',
                style: 'grid-cell-header col-booking-incoterm',
                sortKey: 'releaseType',
                columnWidth: 180,
                hasToolTip: true,
            },
            {
                headerName: 'INCOTERM',
                filter: true,
                sort: true,
                key: 'incoTerm',
                style: 'grid-cell-header col-booking-incoterm',
                sortKey: 'incoTerm',
                columnWidth: 195,
                hasToolTip: true,
            },
            {
                headerName: 'VESSEL-VOYAGE',
                filter: true,
                sort: true,
                key: 'vesselVoyage',
                style: 'grid-cell-header col-vessel-voyage',
                sortKey: 'vesselVoyage',
                columnWidth: 234,
            },
            {
                headerName: 'CREATION DATE',
                filter: true,
                sort: true,
                key: 'createdDateTime',
                style: 'grid-cell-header col-booking-createdDate',
                sortKey: 'createdDateTime',
                columnWidth: 195,
                isDate: true,
                isFilterRightAligned: true,
            },
            {
                headerName: 'ACTIONS',
                key: 'bpAttachment',
                filter: false,
                sort: false,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-booking-action',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
                actionWithAttachmentIcon: true,
            },
        ],
    },
    inboundPO: {
        name: 'Inbound PO',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'poNumber',
        isMultiLevel: false,
        customGridStyle: '',
        headerRowCount: 1,
        hasCustomStatus: false,
        tableHeaderList: [
            {
                headerName: 'INBOUND PO NUMBER',
                filter: true,
                sort: true,
                key: 'poNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-aligned col-po-number',
                isExpandable: true,
                sortKey: 'poNumber',
                columnWidth: 188,
                bodyStyle: 'grid-cell grid-sticky-column sticky-left-aligned',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'poStatus',
                style: 'grid-cell-header col-po-status',
                sortKey: 'poStatus',
                columnWidth: 185,
            },

            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-po-client',
                sortKey: 'clientName',
                columnWidth: 258,
            },
            {
                headerName: 'START SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'startShipWindowDate',
                style: 'grid-cell-header col-order-date',
                sortKey: 'startShippingWindow',
                isDate: true,
                columnWidth: 206,
            },
            {
                headerName: 'END SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'endShipWindowDate',
                style: 'grid-cell-header col-order-date',
                sortKey: 'endShippingWindow',
                isDate: true,
                columnWidth: 206,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-order-date',
                sortKey: 'goodsReadyDate',
                isDate: true,
                columnWidth: 206,
                hasToolTip: true,
            },
            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-po-vendor',
                sortKey: 'vendorName',
                columnWidth: 258,
            },
            {
                headerName: 'BLP',
                filter: true,
                sort: true,
                key: 'agentName',
                style: 'grid-cell-header col-po-vendor',
                sortKey: 'agentName',
                columnWidth: 258,
            },
            {
                headerName: 'RELEASE TYPE',
                filter: true,
                sort: true,
                key: 'releaseType',
                style: 'grid-cell-header col-port-incoterm',
                sortKey: 'releaseType',
                columnWidth: 195,
            },
            {
                headerName: 'INCOTERM',
                filter: true,
                sort: true,
                key: 'incoTerm',
                style: 'grid-cell-header col-port-incoterm',
                sortKey: 'incoTerm',
                columnWidth: 195,
            },
            {
                headerName: 'ORDER DATE',
                filter: false,
                sort: true,
                key: 'poOrderDate',
                style: 'grid-cell-header col-order-date',
                sortKey: 'poOrderDate',
                columnWidth: 206,
                isDate: true,
            },
            {
                headerName: 'CREATED BY',
                filter: true,
                sort: true,
                key: 'createdBy',
                style: 'grid-cell-header col-created-by',
                sortKey: 'createdBy',
                columnWidth: 195,
            },
            {
                headerName: 'CREATION DATE',
                filter: true,
                sort: true,
                key: 'createdDate',
                style: 'grid-cell-header col-createdDate',
                sortKey: 'createdDate',
                columnWidth: 195,
                isDate: true,
                isFilterRightAligned: true,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-po-action',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    receipts: {
        name: 'Receipts',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'receiptNo',
        headerRowCount: 2,
        isMultiLevel: false,
        customGridStyle: 'receipt-grid',
        hasCustomStatus: false,
        tableHeaderList: [
            {
                headerName: 'RECEIPT ID',
                filter: true,
                sort: true,
                key: 'receiptNo',
                style: 'grid-cell-header grid-sticky-column col-receipt sticky-left-aligned',
                isExpandable: true,
                sortKey: 'receiptNo',
                columnWidth: 174,
                bodyStyle: 'grid-cell grid-sticky-column sticky-left-aligned',
                secondRowStyle: 'grid-cell-header grid-sticky-column sticky-left-aligned',
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'status',
                style: 'grid-cell-header',
                sortKey: 'status',
                columnWidth: 184,
            },
            {
                headerName: 'CUSTOMS CLEARANCE DATE',
                filter: false,
                sort: false,
                key: 'customs',
                style: 'grid-cell-header multi-column-th col-customs centre-align',
                columnWidth: 320,
                secondRowDetails: [
                    {
                        headerName: 'PLANNED',
                        filter: true,
                        sort: true,
                        columnWidth: 160,
                        key: 'plannedCustClearanceDate',
                        style: 'grid-cell-header',
                        sortKey: 'plannedCustClearanceDate',
                        isDate: true,
                        dateFilter: true,
                    },
                    {
                        headerName: 'ACTUAL',
                        filter: true,
                        sort: true,
                        columnWidth: 160,
                        key: 'actualCustClearanceDate',
                        style: 'grid-cell-header',
                        sortKey: 'actualCustClearanceDate',
                        isDate: true,
                        dateFilter: true,
                    },
                ],
            },
            {
                headerName: 'GOODS RECEIVED DATE',
                filter: false,
                sort: false,
                key: 'goods',
                style: 'grid-cell-header multi-column-th col-customs centre-align',
                columnWidth: 320,
                secondRowDetails: [
                    {
                        headerName: 'PLANNED',
                        filter: true,
                        sort: true,
                        columnWidth: 160,
                        key: 'plannedGoodsRecvdDate',
                        style: 'grid-cell-header',
                        sortKey: 'plannedGoodsRecvdDate',
                        isDate: true,
                        dateFilter: true,
                    },
                    {
                        headerName: 'ACTUAL',
                        filter: true,
                        sort: true,
                        columnWidth: 160,
                        key: 'actualGoodsRecvdDate',
                        style: 'grid-cell-header',
                        sortKey: 'actualGoodsRecvdDate',
                        isDate: true,
                        dateFilter: true,
                    },
                ],
            },
            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-vendor',
                sortKey: 'vendorName',
                columnWidth: 240,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-client',
                sortKey: 'clientName',
                columnWidth: 320,
            },
            {
                headerName: 'CREATED BY',
                filter: true,
                sort: true,
                key: 'createdUser',
                style: 'grid-cell-header col-created',
                sortKey: 'createdUser',
                columnWidth: 320,
            },
            {
                headerName: 'CREATION DATE',
                filter: true,
                sort: true,
                key: 'createdDate',
                style: 'grid-cell-header col-createdDate',
                sortKey: 'createdDate',
                columnWidth: 195,
                isDate: true,
                isFilterRightAligned: true,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-action  grid-sticky-column sticky-right-aligned',
                bodyStyle: 'grid-cell grid-cell grid-sticky-column sticky-right-aligned',
                secondRowStyle: 'grid-cell-header grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    inventories: {
        name: 'Inventory',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'sku',
        isMultiLevel: false,
        customGridStyle: '',
        headerRowCount: 1,
        hasCustomStatus: false,
        tableHeaderList: [
            {
                headerName: 'SKU',
                filter: true,
                sort: false,
                isLink: true,
                key: 'sku',
                style: 'grid-cell-header grid-sticky-column sticky-left-aligned col-sku',
                sortKey: 'sku',
                bodyStyle: 'grid-cell grid-cell grid-sticky-column sticky-left-aligned td-origin',
                columnWidth: 112,
            },
            {
                headerName: 'DESCRIPTION',
                filter: true,
                sort: false,
                key: 'description',
                style: 'grid-cell-header col-description',
                sortKey: 'description',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 260,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-client',
                sortKey: 'clientName',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 176,
            },
            {
                headerName: 'QTY SCHEDULED FOR RECEIPT',
                filter: false,
                sort: false,
                info: 'Quantity includes goods that are planned to receive in a receipt, but not yet received.',
                infoId: 'view-1',
                key: 'qtyScheduledForRcpt',
                style: 'grid-cell-header col-quality-receipt',
                sortKey: 'qtyScheduledForRcpt',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 172,
            },
            {
                headerName: 'AVAILABLE QTY (A)',
                filter: true,
                sort: false,
                info: 'Available Quantity includes goods in warehouse which are ready for allocation + goods which are already allocated',
                infoId: 'view-2',
                key: 'availableQty',
                style: 'grid-cell-header col-available-qty',
                sortKey: 'availableQty',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 178,
            },
            {
                headerName: 'ON HOLD QTY (B)',
                filter: false,
                sort: false,
                infoId: 'view-3',
                info: 'On hold quantity includes goods that are in the warehouse that are faulty or not good for allocation.',
                key: 'onHoldQty',
                style: 'grid-cell-header col-hold-qty',
                sortKey: 'onHoldQty',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 178,
            },
            {
                headerName: 'ALLOCATED QTY (C)',
                filter: false,
                sort: false,
                infoId: 'view-4',
                info: 'Allocated quantity includes goods that are in the warehouse which are part of Allocation orders.',
                key: 'allocatedQty',
                style: 'grid-cell-header col-allocated-qty',
                sortKey: 'allocatedQty',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 178,
            },
            {
                headerName: 'STOCK ON HAND (A+B)',
                filter: false,
                sort: false,
                infoId: 'view-5',
                info: 'Quantity includes goods in warehouse house that are Available plus goods that are On Hold.',
                key: 'stockOnHand',
                style: 'grid-cell-header col-stock-qty',
                sortKey: 'stockOnHand',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 176,
            },
            {
                headerName: 'AVAILABLE TO ORDER (A-C)',
                filter: true,
                sort: false,
                infoId: 'view-6',
                info: 'Quantity includes goods in warehouse house that are Available minus goods that are Allocated.',
                key: 'availableToOrder',
                style: 'grid-cell-header col-available-qty',
                sortKey: 'availableToOrder',
                bodyStyle: 'grid-cell td-origin',
                columnWidth: 178,
            },
            {
                headerName: 'MODIFIED ON',
                filter: false,
                sort: true,
                key: 'modifiedDateTime',
                style: 'grid-cell-header col-modified-on',
                sortKey: 'modifiedDateTime',
                bodyStyle: 'grid-cell td-origin',
                isDate: true,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                actionIcons: true,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-action',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    allocationOrder: {
        name: 'Allocation Orders',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'aoNumber',
        isMultiLevel: false,
        customGridStyle: '',
        headerRowCount: 1,
        hasCustomStatus: false,
        navigationPath: '/allocation_orders/:id/basic_details',
        navigationId: 'aoId',
        navigationKeys: ['aoStatus', 'aoNumber'],
        tableHeaderList: [
            {
                headerName: 'AO NUMBER',
                filter: true,
                sort: true,
                key: 'aoNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-aligned col-number',
                bodyStyle: 'grid-cell grid-sticky-column sticky-left-aligned',
                isExpandable: true,
                sortKey: 'aoNumber',
                columnWidth: 190,
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'aoStatus',
                style: 'grid-cell-header col-status',
                sortKey: 'aoStatus',
                columnWidth: 190,
            },

            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-client',
                sortKey: 'clientName',
                columnWidth: 250,
            },
            {
                headerName: 'START SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'startShipWindowDate',
                style: 'grid-cell-header col-date',
                sortKey: 'startShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'END SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'endShipWindowDate',
                style: 'grid-cell-header col-date',
                sortKey: 'endShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-port-destination',
                sortKey: 'goodsReadyDate',
                isDate: true,
                columnWidth: 191,
                hasToolTip: true,
            },
            {
                headerName: 'BLP',
                filter: true,
                sort: true,
                key: 'blpAgentName',
                style: 'grid-cell-header col-blp',
                sortKey: 'blpAgentName',
                columnWidth: 328,
            },
            {
                headerName: 'AGENT',
                filter: true,
                sort: true,
                key: 'agentName',
                style: 'grid-cell-header col-agent',
                sortKey: 'agentName',
                columnWidth: 195,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                key: 'originPort',
                style: 'grid-cell-header col-origin-port',
                sortKey: 'originPort',
                columnWidth: 186,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-port-destination',
                sortKey: 'destinationPort',
                columnWidth: 234,
            },
            {
                headerName: 'DESTINATION TYPE',
                filter: true,
                sort: true,
                key: 'destinationType',
                style: 'grid-cell-header col-port-destination',
                sortKey: 'destinationType',
                columnWidth: 258,
            },
            {
                headerName: 'RELEASE TYPE',
                filter: true,
                sort: true,
                key: 'releaseType',
                style: 'grid-cell-header col-term',
                sortKey: 'releaseType',
                columnWidth: 186,
            },
            {
                headerName: 'INCOTERM',
                filter: true,
                sort: true,
                key: 'incoTerm',
                style: 'grid-cell-header col-port-incoterm',
                sortKey: 'incoTerm',
                columnWidth: 195,
            },
            {
                headerName: 'CREATED BY',
                filter: true,
                sort: true,
                key: 'createdBy',
                style: 'grid-cell-header col-created-by',
                sortKey: 'createdBy',
                columnWidth: 195,
            },
            {
                headerName: 'CREATION DATE',
                filter: true,
                sort: true,
                key: 'createdDate',
                style: 'grid-cell-header col-createdDate',
                sortKey: 'createdDate',
                columnWidth: 195,
                isDate: true,
                isFilterRightAligned: true,
            },
            {
                headerName: 'ORDER DATE',
                filter: false,
                sort: true,
                key: 'orderDate',
                style: 'grid-cell-header col-date',
                sortKey: 'orderDate',
                columnWidth: 186,
                isDate: true,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-action',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    aoBookings: {
        name: 'aoBookings',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'bpNumber',
        isMultiLevel: false,
        customGridStyle: '',
        headerRowCount: 1,
        hasCustomStatus: false,
        navigationPath: '/Aobooking_list/:id/AoSelection',
        navigationId: 'bpId',
        navigationKeys: ['bpStatus', 'bpNumber'],
        tableHeaderList: [
            {
                headerName: 'BOOKING NO.',
                filter: true,
                sort: true,
                key: 'bpNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-aligned col-booking-number',
                bodyStyle: 'grid-cell grid-sticky-column sticky-left-aligned',
                isExpandable: true,
                sortKey: 'bpNumber',
                columnWidth: 221,
            },
            {
                headerName: 'HOUSE BILL NO.',
                filter: true,
                sort: true,
                key: 'houseBillNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-second-aligned col-booking-housebill',
                bodyStyle: 'grid-sticky-column sticky-left-second-aligned',
                sortKey: 'houseBillNumber',
                columnWidth: 180,
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'bpStatus',
                style: 'grid-cell-header col-booking-status',
                sortKey: 'bpStatus',
                columnWidth: 248,
            },
            {
                headerName: 'AO NUMBER',
                filter: true,
                sort: true,
                key: 'poNumber',
                style: 'grid-cell-header col-ao-number',
                sortKey: 'poNumber',
                columnWidth: 191,
                hasToolTip: true,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-booking-client',
                sortKey: 'clientName',
                columnWidth: 251,
            },
            {
                headerName: 'BLP',
                filter: true,
                sort: true,
                key: 'blpAgentName',
                style: 'grid-cell-header col-booking-blp',
                sortKey: 'blpAgentName',
                columnWidth: 328,
            },
            {
                headerName: 'AGENT',
                filter: true,
                sort: true,
                key: 'agentName',
                style: 'grid-cell-header col-booking-agent',
                sortKey: 'agentName',
                columnWidth: 328,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                key: 'originPort',
                style: 'grid-cell-header col-booking-origin',
                sortKey: 'originPort',
                columnWidth: 251,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-booking-destination',
                sortKey: 'destinationPort',
                columnWidth: 251,
            },
            {
                headerName: 'DESTINATION TYPE',
                filter: true,
                sort: true,
                key: 'destinationType',
                style: 'grid-cell-header col-booking-destination',
                sortKey: 'destinationType',
                columnWidth: 171,
            },
            {
                headerName: 'Hub Link',
                filter: true,
                sort: true,
                key: 'hubLink',
                style: 'grid-cell-header col-booking-hublink',
                sortKey: 'hubLink',
                columnWidth: 190,
                colorFilter: true,
            },
            {
                headerName: 'ETD',
                filter: true,
                sort: true,
                key: 'plannedEtd',
                style: 'grid-cell-header col-booking-etd',
                sortKey: 'plannedEtd',
                columnWidth: 180,
                isDate: true,
            },
            {
                headerName: 'ETA',
                filter: true,
                sort: true,
                key: 'plannedEta',
                style: 'grid-cell-header col-booking-eta',
                sortKey: 'plannedEta',
                columnWidth: 180,
                isDate: true,
            },
            {
                headerName: 'RELEASE TYPE',
                filter: true,
                sort: true,
                key: 'releaseType',
                style: 'grid-cell-header col-booking-incoterm',
                sortKey: 'releaseType',
                columnWidth: 180,
                hasToolTip: true,
            },
            {
                headerName: 'INCOTERM',
                filter: true,
                sort: true,
                key: 'incoTerm',
                style: 'grid-cell-header col-booking-incoterm',
                sortKey: 'incoTerm',
                columnWidth: 195,
                hasToolTip: true,
            },
            {
                headerName: 'VESSEL-VOYAGE',
                filter: true,
                sort: true,
                key: 'vesselVoyage',
                style: 'grid-cell-header col-vessel-voyage',
                sortKey: 'vesselVoyage',
                columnWidth: 234,
            },
            {
                headerName: 'CREATION DATE',
                filter: true,
                sort: true,
                key: 'createdDateTime',
                style: 'grid-cell-header col-booking-createdDate',
                sortKey: 'createdDateTime',
                columnWidth: 195,
                isDate: true,
                isFilterRightAligned: true,
            },
            {
                headerName: 'ACTIONS',
                key: 'bpAttachment',
                filter: false,
                sort: false,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-booking-action',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
                actionWithAttachmentIcon: true,
            },
        ],
    },
    poBookingsAir: {
        name: 'poBookingsAir',
        childKey: '',
        childDisplayKey: '',
        childDisplaySubKey: null,
        navigationName: 'bpNumber',
        isMultiLevel: false,
        customGridStyle: '',
        headerRowCount: 1,
        hasCustomStatus: false,
        navigationPath: '/air_booking_list/:id/POSelection',
        navigationId: 'bpId',
        navigationKeys: ['bpStatus', 'bpNumber'],
        tableHeaderList: [
            {
                headerName: 'BOOKING NO.',
                filter: true,
                sort: true,
                key: 'bpNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-aligned col-booking-number',
                bodyStyle: 'grid-cell grid-sticky-column sticky-left-aligned',
                isExpandable: true,
                sortKey: 'bpNumber',
                columnWidth: 221,
            },
            {
                headerName: 'HOUSE BILL NO.',
                filter: true,
                sort: true,
                key: 'houseBillNumber',
                style: 'grid-cell-header grid-sticky-column sticky-left-second-aligned col-booking-housebill',
                bodyStyle: 'grid-sticky-column sticky-left-second-aligned',
                sortKey: 'houseBillNumber',
                columnWidth: 180,
            },
            {
                headerName: 'STATUS',
                filter: false,
                sort: true,
                key: 'bpStatus',
                style: 'grid-cell-header col-booking-status',
                sortKey: 'bpStatus',
                columnWidth: 248,
            },
            {
                headerName: 'PO NUMBER',
                filter: true,
                sort: true,
                key: 'poNumber',
                style: 'grid-cell-header col-po-number',
                sortKey: 'poNumber',
                columnWidth: 191,
                hasToolTip: true,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-booking-client',
                sortKey: 'clientName',
                columnWidth: 251,
            },
            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-booking-client',
                sortKey: 'vendorName',
                columnWidth: 251,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                key: 'originPort',
                style: 'grid-cell-header col-booking-origin',
                sortKey: 'originPort',
                columnWidth: 251,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-booking-destination',
                sortKey: 'destinationPort',
                columnWidth: 251,
            },
            {
                headerName: 'DESTINATION TYPE',
                filter: true,
                sort: true,
                key: 'destinationType',
                style: 'grid-cell-header col-booking-destination',
                sortKey: 'destinationType',
                columnWidth: 171,
            },
            {
                headerName: 'Hub Link',
                filter: true,
                sort: true,
                key: 'hubLink',
                style: 'grid-cell-header col-booking-hublink',
                sortKey: 'hubLink',
                columnWidth: 190,
                colorFilter: true,
            },
            {
                headerName: 'ETD',
                filter: true,
                sort: true,
                key: 'plannedEtd',
                style: 'grid-cell-header col-booking-etd',
                sortKey: 'plannedEtd',
                columnWidth: 180,
                isDate: true,
            },
            {
                headerName: 'ETA',
                filter: true,
                sort: true,
                key: 'plannedEta',
                style: 'grid-cell-header col-booking-eta',
                sortKey: 'plannedEta',
                columnWidth: 180,
                isDate: true,
            },
            {
                headerName: 'RELEASE TYPE',
                filter: true,
                sort: true,
                key: 'releaseType',
                style: 'grid-cell-header col-booking-incoterm',
                sortKey: 'releaseType',
                columnWidth: 180,
                hasToolTip: true,
            },
            {
                headerName: 'INCOTERM',
                filter: true,
                sort: true,
                key: 'incoTerm',
                style: 'grid-cell-header col-booking-incoterm',
                sortKey: 'incoTerm',
                columnWidth: 195,
                hasToolTip: true,
            },
            {
                headerName: 'CARRIER- FLIGHT',
                filter: true,
                sort: true,
                key: 'carrierFlights',
                subKey: 'carrierFlight',
                style: 'grid-cell-header col-vessel-voyage',
                sortKey: 'carrierFlights',
                columnWidth: 234,
                hasToolTip: true,
            },
            {
                headerName: 'CREATION DATE',
                filter: true,
                sort: true,
                key: 'createdDateTime',
                style: 'grid-cell-header col-booking-createdDate',
                sortKey: 'createdDateTime',
                columnWidth: 195,
                isDate: true,
                isFilterRightAligned: true,
            },
            {
                headerName: 'ACTIONS',
                key: 'bpAttachment',
                filter: false,
                sort: false,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-booking-action',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
                actionWithAttachmentIcon: true,
            },
        ],
    },
};

export const fileUploadInfoText = '( Maximum upload file size: 25 MB )';
export const fileTypesInfoText =
    '( Allowed file types: .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd, <br>.vsdx, .xls, .xlsx )';

export const SUPPORTED_FORMATS = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.visio',
    'application/vnd.ms-visio.drawing, application/vnd.ms-visio.viewer',
    'application/msproject',
    'application/vnd.ms-project',
];

export const SUPPORTED_FORMATS_FOR_FAK = [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.visio',
    'application/vnd.ms-visio.drawing, application/vnd.ms-visio.viewer',
    'application/msproject',
    'application/vnd.ms-project',
];

export const FILE_SIZE = 26214400;

const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/;

const urlRegex =
    /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/;

export const profileModalFieldSettings = {
    vendor: {
        profileInfo: {
            modalHeader: 'Profile Information',
            childBoolean: 'is_Child',
            content: [
                {
                    fieldName: 'Name',
                    isRequired: true,
                    fieldType: 'text',
                    placeholder: 'Enter Vendor Name',
                    idName: 'vn_name',
                    validation: Yup.string()
                        .required('Name is required')
                        .min(1, 'Name is required')
                        .max(150, 'Must be 150 characters or less'),
                },
                {
                    fieldName: 'Parent Vendor',
                    isRequired: true,
                    fieldType: 'select',
                    placeholder: 'Enter Parent Vendor Name',
                    list: 'parentList',
                    idName: 'vn_parent',
                    canBeHidden: true,
                    validation: Yup.string().when('is_Child', {
                        is: true,
                        then: Yup.string().required('Parent is required').nullable(),
                        otherwise: Yup.string().notRequired().nullable(),
                    }),
                },
                {
                    fieldName: 'Organisation Code',
                    isRequired: true,
                    fieldType: 'text',
                    placeholder: 'Enter Organisation Code',
                    idName: 'vn_org_code',
                    disabled: true,
                    validation: Yup.string()
                        .required('Organisation Code is required')
                        .matches(/^[a-z0-9 ]+$/i, 'Organisation Code should not contain any special characters')
                        .min(1, 'Organisation Code is required')
                        .max(15, 'Must be 15 characters or less'),
                },
                {
                    fieldName: 'Organisation Category',
                    isRequired: true,
                    fieldType: 'dropdown',
                    placeholder: 'Select Organisation Category',
                    idName: 'vn_org_category',
                    list: 'organisationCategoryList',
                    validation: Yup.string().required('Organisation Category is required'),
                },
                {
                    fieldName: 'Company Registration Number',
                    isRequired: false,
                    fieldType: 'text',
                    placeholder: 'Enter Company Registration Number',
                    idName: 'vn_abn',
                    validation: Yup.string().notRequired().nullable(),
                    // .matches(/^[0-9]+$/, 'Company Registration Number must be 11 digits')
                    // .min(11, 'Company Registration Number must be 11 digits')
                    // .max(11, 'Company Registration Number must be 11 digits')
                    // .nullable(),
                },
                {
                    fieldName: 'UNLOCO',
                    isRequired: true,
                    fieldType: 'select',
                    placeholder: 'Type in for suggestions',
                    idName: 'vn_unloco',
                    list: 'unLocoList',
                    validation: Yup.string().required('UNLOCO is required'),
                    searchable: true,
                },
                {
                    fieldName: 'Country of origin',
                    isRequired: true,
                    fieldType: 'selectCountry',
                    list: 'countryList',
                    placeholder: 'Enter Country of origin',
                    idName: 'vn_origin_country',
                    validation: Yup.string().required('Country of origin is required'),
                },
                {
                    fieldName: 'Default Incoterm',
                    isRequired: true,
                    fieldType: 'dropdown',
                    placeholder: 'Select Default Incoterm',
                    idName: 'vn_default_incoterms',
                    list: 'defaultIncoTermsList',
                    validation: Yup.string().required('Default Incoterm is required'),
                },
                {
                    fieldName: 'Phone',
                    isRequired: false,
                    fieldType: 'number',
                    placeholder: 'Enter Phone Number',
                    idName: 'vn_phone',
                    validation: Yup.string()
                        .matches(phoneRegExp, 'Please enter a valid phone number with country code')
                        // .required('Phone number is required')
                        .notRequired()
                        .nullable(),
                },
                {
                    fieldName: 'Website URL',
                    isRequired: false,
                    fieldType: 'text',
                    placeholder: 'Enter Website URL',
                    idName: 'vn_website_url',
                    validation: Yup.string()
                        .matches(urlRegex, 'Website should be a valid URL')
                        .notRequired()
                        .nullable(),
                },
                {
                    fieldName: 'Manufacturing Categories',
                    isRequired: false,
                    fieldType: 'multiSelect',
                    placeholder: 'Select Manufacturing Categories',
                    idName: 'vn_manufacturing_categories',
                    list: 'manufacturingCategoryList',
                    validation: Yup.array()
                        // .min(1, 'Manufacturing Category is required')
                        // .of(Yup.string())
                        .notRequired()
                        .nullable(),
                },
            ],
        },
        addressInfo: {
            modalHeader: 'Address',
            content: [
                {
                    fieldName: 'Address line 1',
                    isRequired: true,
                    fieldType: 'text',
                    placeholder: 'Enter Address line',
                    idName: 'address1',
                    validation: Yup.string().required('Address is required'),
                },
                {
                    fieldName: 'Address line 2',
                    isRequired: false,
                    fieldType: 'text',
                    placeholder: 'Enter Address line',
                    idName: 'address2',
                    validation: Yup.string().notRequired().nullable(),
                },
                {
                    fieldName: 'Country',
                    isRequired: true,
                    fieldType: 'selectCountry',
                    placeholder: 'Enter Country',
                    idName: 'country',
                    list: 'countryList',
                    validation: Yup.string().required('Country is required'),
                },
                {
                    fieldName: 'City',
                    isRequired: true,
                    fieldType: 'text',
                    placeholder: 'Enter City',
                    idName: 'city',
                    validation: Yup.string().required('City is required'),
                },
                {
                    fieldName: 'Post Code',
                    isRequired: false,
                    fieldType: 'text',
                    placeholder: 'Enter Post Code',
                    idName: 'postCode',
                    // validation: Yup.string().required('Post Code is required'),
                    validation: Yup.string().notRequired().nullable(),
                },
                {
                    fieldName: 'State',
                    isRequired: true,
                    fieldType: 'text',
                    placeholder: 'Enter State',
                    idName: 'state',
                    validation: Yup.string().required('State is required'),
                },
            ],
        },
    },
};

export const statusOfPos = [
    'Draft',
    'Awaiting Enrichment',
    'Ready For Booking',
    'Partially Booked',
    'Booked',
    'In Transit',
    'Completed',
    'Pending Reassignment',
    'Discarded',
    'Cancelled',
];

export const statusOfInboundPos = [
    'Draft',
    'Submitted',
    'Awaiting Enrichment',
    'Inbound In Progress',
    'Inbound Received',
    'Cancelled',
];

export const statusOfBps = [
    'Awaiting Consolidation',
    'Draft Booking Proposal',
    'Awaiting Forwarder Approval',
    'Awaiting Client Approval',
    'Rejected by Client: Forwarder to Action',
    'Rejected by Client: Agent to Action',
    'Rejected by Forwarder',
    'Rejected by Client',
    'Ready for Booking',
    'Draft Booking',
    'Booked',
    'In transit',
    'Delivered',
    'Withdrawn',
    'Pending Reassignment',
    'Cancelled',
];

export const statusOfReceipts = [
    'Draft',
    'Ready to Receive',
    'Ready To Receive',
    'In Progress',
    'Receipt Complete',
    'Closed',
];

export const statusOfAos = [
    'Draft',
    'Pending with Forwarder',
    'Ready for Booking',
    'Partially Booked',
    'Booked',
    'In Transit',
    'Completed',
    'Cancelled',
];

export const gridProperties = {
    availablePos: {
        name: 'availablePos',
        state: 'availablePos',
        hasSelfGeneratingIndex: false,
        indexingKey: '',
        primaryKeyToStore: 'cgId',
        keysToStore: ['totCBM', 'totKGS', 'goodsReadyDate', 'isMultiAddress', 'poNumber'],
        poLineViewPath: '/BookingProposal/:id/POSelectionDetails',
        poLineKeyToStore: 'poId',
        columnsToDisableBy: ['clientName', 'vendorName', 'originPort', 'destinationPort', 'isHeaderOnlyPo'],
        shouldClearFitlerOnUnselect: true,
        noDataMessage: 'No Purchase Orders available for Booking',
        noDataForFilter: 'No Purchase orders available for the applied filters.',
        tableHeaderList: [
            {
                headerName: 'CHECKBOX',
                filter: false,
                sort: false,
                key: 'checkbox',
                renderSpecialData: true,
                style: 'grid-cell-header col-checkbox',
                columnWidth: 48,
            },
            {
                headerName: 'PO NUMBER',
                filter: false,
                sort: false,
                key: 'poNumber',
                style: 'grid-cell-header col-po-number',
                renderSpecialData: true,
                sortKey: 'poNumber',
                columnWidth: 112,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header col-client',
                sortKey: 'clientName',
                columnWidth: 232,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                key: 'originPort',
                style: 'grid-cell-header col-origin',
                sortKey: 'originPort',
                columnWidth: 216,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-destination',
                sortKey: 'destinationPort',
                hasToolTip: true,
                columnWidth: 243,
            },
            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-vendor',
                sortKey: 'vendorName',
                columnWidth: 303,
            },
            {
                headerName: 'START SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'startShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'startShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'END SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'endShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'endShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: true,
                dateFilter: true,
                sort: true,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-grd',
                sortKey: 'goodsReadyDate',
                isDate: true,
                columnWidth: 245,
            },

            {
                headerName: 'CREATED ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'createdOn',
                style: 'grid-cell-header col-created-on',
                sortKey: 'createdOn',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                renderSpecialData: true,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-actions',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    poLines: {
        name: 'poLines',
        state: 'availablePos',
        hasSelfGeneratingIndex: false,
        indexingKey: 'poLineNumber',
        primaryKeyToStore: 'splitLineId',
        keysToStore: ['confirmedGoodsdate', 'confirmedCbm', 'confirmedWeight'],
        poLineViewPath: '',
        poLineKeyToStore: '',
        columnsToDisableBy: ['originPort', 'clientName', 'destinationPort'],
        shouldClearFitlerOnUnselect: false,
        noDataMessage: 'No PO Lines available',
        noDataForFilter: null,
        tableHeaderList: [
            {
                headerName: 'PO LINE NO.',
                key: 'poLineNo',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-po-number',
                columnWidth: 112,
            },
            {
                headerName: 'SKU',
                filter: false,
                sort: false,
                key: 'sku',
                renderSpecialData: true,
                style: 'grid-cell-header col-sku',
                columnWidth: 112,
                sortKey: 'sku',
            },
            {
                headerName: 'DESCRIPTION',
                filter: false,
                sort: false,
                key: 'description',
                style: 'grid-cell-header col-desc',
                columnWidth: 232,
            },
            {
                headerName: 'QTY',
                filter: false,
                sort: false,
                key: 'qty',
                style: 'grid-cell-header col-qty',
                columnWidth: 112,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                sort: false,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-grd',
                isDate: true,
                columnWidth: 207,
            },
            {
                headerName: 'ORIGIN',
                filter: false,
                sort: false,
                key: 'originPort',
                style: 'grid-cell-header col-op',
                sortKey: 'originPort',
                columnWidth: 167,
            },
            {
                headerName: 'DESTINATION',
                filter: false,
                sort: false,
                key: 'destinationPort',
                style: 'grid-cell-header col-dp',
                columnWidth: 216,
            },
            {
                headerName: 'CBM',
                filter: false,
                sort: false,
                key: 'cbm',
                style: 'grid-cell-header col-cbm',
                columnWidth: 75,
            },
            {
                headerName: 'WEIGHT (KG)',
                filter: false,
                sort: false,
                key: 'weight',
                style: 'grid-cell-header col-weight',
                columnWidth: 160,
            },
        ],
    },
    poLinesAir: {
        name: 'poLinesAir',
        state: 'availablePos',
        hasSelfGeneratingIndex: false,
        indexingKey: 'poLineNumber',
        primaryKeyToStore: 'splitLineId',
        keysToStore: ['confirmedGoodsdate', 'confirmedCbm', 'confirmedWeight'],
        poLineViewPath: '',
        poLineKeyToStore: '',
        columnsToDisableBy: ['originPort', 'clientName', 'destinationPort'],
        shouldClearFitlerOnUnselect: false,
        noDataMessage: 'No PO Lines available',
        noDataForFilter: null,
        tableHeaderList: [
            {
                headerName: 'PO LINE NO.',
                key: 'poLineNo',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-po-number',
                columnWidth: 112,
            },
            {
                headerName: 'SKU',
                filter: false,
                sort: false,
                key: 'sku',
                renderSpecialData: true,
                style: 'grid-cell-header col-sku',
                columnWidth: 112,
            },
            {
                headerName: 'DESCRIPTION',
                filter: false,
                sort: false,
                key: 'description',
                style: 'grid-cell-header col-desc',
                columnWidth: 232,
            },
            {
                headerName: 'QTY',
                filter: false,
                sort: false,
                key: 'qty',
                style: 'grid-cell-header col-qty',
                columnWidth: 112,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                sort: false,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-grd',
                isDate: true,
                columnWidth: 207,
            },
            {
                headerName: 'ORIGIN',
                filter: false,
                sort: false,
                key: 'originPort',
                style: 'grid-cell-header col-op',
                sortKey: 'originPort',
                columnWidth: 167,
            },
            {
                headerName: 'DESTINATION',
                filter: false,
                sort: false,
                key: 'destinationPort',
                style: 'grid-cell-header col-dp',
                columnWidth: 216,
            },
            {
                headerName: 'CBM',
                filter: false,
                sort: false,
                key: 'cbm',
                style: 'grid-cell-header col-cbm',
                columnWidth: 75,
            },
            {
                headerName: 'WEIGHT (KG)',
                filter: false,
                sort: false,
                key: 'weight',
                style: 'grid-cell-header col-weight',
                columnWidth: 160,
            },
        ],
    },
    availableAos: {
        name: 'availableAos',
        state: 'aoavailablePos',
        hasSelfGeneratingIndex: false,
        indexingKey: '',
        primaryKeyToStore: 'poProductId',
        keysToStore: ['poProductId', 'poId'],
        columnsToDisableBy: ['clientName', 'vendorName', 'blpName', 'destinationPortName', 'isHeaderOnlyPo'],
        poLineViewPath: '',
        poLineKeyToStore: '',
        shouldClearFitlerOnUnselect: true,
        noDataMessage: 'No Allocation Orders available for Booking',
        noDataForFilter: 'No Allocation Orders available for the applied filters.',
        tableHeaderList: [
            {
                headerName: 'CHECKBOX',
                filter: false,
                sort: false,
                key: 'checkbox',
                renderSpecialData: true,
                style: 'grid-cell-header col-checkbox',
                columnWidth: 48,
            },
            {
                headerName: 'AO No.',
                filter: true,
                sort: true,
                key: 'poNumber',
                style: 'grid-cell-header col-ao-number',
                sortKey: 'poNumber',
                columnWidth: 112,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                key: 'clientName',
                style: 'grid-cell-header',
                sortKey: 'clientName',
                columnWidth: 145,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                key: 'originPortName',
                style: 'grid-cell-header col-origin',
                sortKey: 'originPortName',
                columnWidth: 216,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                key: 'destinationPortName',
                style: 'grid-cell-header col-destination',
                sortKey: 'destinationPortName',
                hasToolTip: true,
                columnWidth: 243,
            },
            {
                headerName: 'START SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'startShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'startShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'END SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'endShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'endShippingWindow',
                isDate: true,
                columnWidth: 191,
            },

            {
                headerName: 'BLP',
                filter: true,
                sort: true,
                key: 'blpName',
                style: 'grid-cell-header col-blp',
                sortKey: 'blpName',
                columnWidth: 122,
            },
            {
                headerName: 'SKU',
                filter: false,
                sort: false,
                key: 'sku',
                renderSpecialData: true,
                style: 'grid-cell-header col-sku',
                sortKey: 'sku',
                columnWidth: 110,
            },
            {
                headerName: 'DESCRIPTION',
                filter: false,
                sort: false,
                key: 'description',
                style: 'grid-cell-header col-sku',
                sortKey: 'description',
                columnWidth: 118,
            },
            {
                headerName: 'ALLOCATION QTY',
                filter: false,
                sort: false,
                key: 'allocationQuantity',
                style: 'grid-cell-header col-allocation',
                sortKey: 'allocationQuantity',
                columnWidth: 208,
            },
            {
                headerName: 'CUSTOMER ORDER REFERENCE NO.',
                filter: false,
                sort: false,
                key: 'customerOrderRefNo',
                style: 'grid-cell-header col-customer',
                sortKey: 'customerOrderRefNo',
                columnWidth: 329,
            },
            {
                headerName: 'CUSTOMER ORDER LINE NO.',
                filter: false,
                sort: false,
                key: 'customerOrderLineNo',
                style: 'grid-cell-header col-customer',
                sortKey: 'customerOrderLineNo',
                columnWidth: 329,
            },

            {
                headerName: 'DESTINATION ADDRESS',
                filter: false,
                sort: false,
                key: 'destinationAddress',
                style: 'grid-cell-header col-destination2',
                sortKey: 'destinationAddress',
                columnWidth: 322,
            },
            {
                headerName: 'CBM',
                filter: false,
                sort: false,
                key: 'totCBM',
                style: 'grid-cell-header col-cbm',
                sortKey: 'totCBM',
                columnWidth: 58,
            },
            {
                headerName: 'WEIGHT (KG)',
                filter: false,
                sort: false,
                key: 'totKGS',
                style: 'grid-cell-header col-weight',
                sortKey: 'totKGS',
                columnWidth: 186,
            },
            {
                headerName: 'CREATED ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'createdOn',
                style: 'grid-cell-header col-created-on',
                sortKey: 'createdOn',
                isDate: true,
                columnWidth: 191,
            },
        ],
    },
    availableLcl: {
        name: 'availableLcl',
        state: 'availablePosLcl',
        hasSelfGeneratingIndex: false,
        indexingKey: '',
        primaryKeyToStore: 'cgId',
        keysToStore: ['totCBM', 'totKGS', 'goodsReadyDate', 'isMultiAddress', 'poNumber'],
        columnsToDisableBy: ['clientName', 'coLoader', 'vendorName', 'originPort', 'destinationPort', 'isHeaderOnlyPo'],
        poLineViewPath: '/bookingProposalLcl/:id/POSelectionDetails',
        poLineKeyToStore: 'cgId',
        shouldClearFitlerOnUnselect: true,
        noDataMessage: 'No confirmed POs available',
        noDataForFilter: 'No Purchase orders available for the applied filters.',
        tableHeaderList: [
            {
                headerName: 'CHECKBOX',
                filter: false,
                sort: false,
                key: 'checkbox',
                renderSpecialData: true,
                style: 'grid-cell-header col-checkbox',
                columnWidth: 48,
            },
            {
                headerName: 'PO NUMBER',
                filter: false,
                sort: false,
                key: 'poNumber',
                style: 'grid-cell-header col-po-number',
                renderSpecialData: true,
                sortKey: 'poNumber',
                columnWidth: 112,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                isFilteredOnSelect: true,
                key: 'clientName',
                style: 'grid-cell-header col-client',
                sortKey: 'clientName',
                columnWidth: 232,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                isFilteredOnSelect: true,
                key: 'originPort',
                style: 'grid-cell-header col-origin',
                sortKey: 'originPort',
                columnWidth: 216,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                isFilteredOnSelect: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-destination',
                sortKey: 'destinationPort',
                hasToolTip: true,
                columnWidth: 243,
            },
            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-vendor',
                sortKey: 'vendorName',
                columnWidth: 303,
            },
            {
                headerName: 'COLOADER',
                filter: true,
                sort: true,
                key: 'coLoader',
                style: 'grid-cell-header col-co-loader',
                sortKey: 'coLoader',
                columnWidth: 178,
            },
            {
                headerName: 'START SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'startShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'startShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'END SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'endShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'endShippingWindow',
                isDate: true,
                columnWidth: 191,
            },

            {
                headerName: 'GOODS READY DATE',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-grd',
                sortKey: 'goodsReadyDate',
                isDate: true,
                columnWidth: 245,
            },

            {
                headerName: 'CREATED ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'createdOn',
                style: 'grid-cell-header col-created-on',
                sortKey: 'createdOn',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                renderSpecialData: true,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-actions',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    lclpoLines: {
        name: 'poLines',
        state: 'availablePosLcl',
        hasSelfGeneratingIndex: false,
        indexingKey: 'poLineNumber',
        primaryKeyToStore: 'splitLineId',
        keysToStore: ['confirmedGoodsdate', 'confirmedCbm', 'confirmedWeight'],
        columnsToDisableBy: ['originPort', 'clientName', 'destinationPort'],
        poLineViewPath: '',
        poLineKeyToStore: '',
        shouldClearFitlerOnUnselect: false,
        noDataMessage: 'No PO Lines available',
        noDataForFilter: null,
        tableHeaderList: [
            {
                headerName: 'PO LINE NO.',
                key: 'poLineNo',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-po-number',
                columnWidth: 112,
            },
            {
                headerName: 'SKU',
                filter: false,
                sort: false,
                key: 'sku',
                renderSpecialData: true,
                sortKey: 'sku',
                style: 'grid-cell-header col-sku',
                columnWidth: 112,
            },
            {
                headerName: 'DESCRIPTION',
                filter: false,
                sort: false,
                key: 'description',
                style: 'grid-cell-header col-desc',
                columnWidth: 232,
            },
            {
                headerName: 'QTY',
                filter: false,
                sort: false,
                key: 'qty',
                style: 'grid-cell-header col-qty',
                columnWidth: 112,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                sort: false,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-grd',
                isDate: true,
                columnWidth: 207,
            },
            {
                headerName: 'ORIGIN',
                filter: false,
                sort: false,
                key: 'originPort',
                style: 'grid-cell-header col-op',
                columnWidth: 167,
            },
            {
                headerName: 'DESTINATION',
                filter: false,
                sort: false,
                key: 'destinationPort',
                style: 'grid-cell-header col-dp',
                columnWidth: 216,
            },
            {
                headerName: 'CBM',
                filter: false,
                sort: false,
                key: 'cbm',
                style: 'grid-cell-header col-cbm',
                columnWidth: 75,
            },
            {
                headerName: 'WEIGHT (KG)',
                filter: false,
                sort: false,
                key: 'weight',
                style: 'grid-cell-header col-weight',
                columnWidth: 160,
            },
        ],
    },
    availablePosAir: {
        name: 'availablePosAir',
        state: 'availablePosAir',
        hasSelfGeneratingIndex: false,
        indexingKey: '',
        primaryKeyToStore: 'airIndex',
        keysToStore: ['poId', 'goodsReadyDate', 'destinationPort'],
        columnsToDisableBy: ['clientName', 'vendorName', 'originPort', 'destinationPort', 'isHeaderOnlyPo'],
        poLineViewPath: '/air_booking_list/:id/POSelectionDetails',
        poLineKeyToStore: 'poId',
        shouldClearFitlerOnUnselect: true,
        noDataMessage: 'No Purchase Orders available for Booking',
        noDataForFilter: 'No Purchase orders available for the applied filters.',
        tableHeaderList: [
            {
                headerName: 'CHECKBOX',
                filter: false,
                sort: false,
                key: 'checkbox',
                renderSpecialData: true,
                style: 'grid-cell-header col-checkbox',
                columnWidth: 48,
            },
            {
                headerName: 'PO NUMBER',
                filter: false,
                sort: false,
                key: 'poNumber',
                style: 'grid-cell-header col-po-number',
                renderSpecialData: true,
                sortKey: 'poNumber',
                columnWidth: 112,
            },
            {
                headerName: 'CLIENT',
                filter: true,
                sort: true,
                isFilteredOnSelect: true,
                key: 'clientName',
                style: 'grid-cell-header col-air-client',
                sortKey: 'clientName',
                columnWidth: 232,
            },
            {
                headerName: 'ORIGIN',
                filter: true,
                sort: true,
                isFilteredOnSelect: true,
                key: 'originPort',
                style: 'grid-cell-header col-air-origin',
                sortKey: 'originPort',
                columnWidth: 216,
            },
            {
                headerName: 'DESTINATION',
                filter: true,
                sort: true,
                isFilteredOnSelect: true,
                key: 'destinationPort',
                style: 'grid-cell-header col-air-destination',
                sortKey: 'destinationPort',
                hasToolTip: true,
                columnWidth: 243,
            },
            {
                headerName: 'VENDOR',
                filter: true,
                sort: true,
                key: 'vendorName',
                style: 'grid-cell-header col-air-vendor',
                sortKey: 'vendorName',
                columnWidth: 303,
            },
            {
                headerName: 'START SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'startShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'startShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'END SHIPPING ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'endShippingWindow',
                style: 'grid-cell-header col-created-on',
                sortKey: 'endShippingWindow',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-grd',
                sortKey: 'goodsReadyDate',
                isDate: true,
                columnWidth: 245,
            },

            {
                headerName: 'CREATED ON',
                filter: false,
                dateFilter: true,
                sort: true,
                key: 'createdDate',
                style: 'grid-cell-header col-created-on',
                sortKey: 'createdDate',
                isDate: true,
                columnWidth: 191,
            },
            {
                headerName: 'ACTIONS',
                filter: false,
                sort: false,
                renderSpecialData: true,
                style: 'grid-cell-header grid-sticky-column sticky-right-aligned col-actions',
                bodyStyle: 'grid-cell grid-sticky-column sticky-right-aligned',
            },
        ],
    },
    poLinesHeaderOnly: {
        name: 'poLines',
        state: 'availablePos',
        hasSelfGeneratingIndex: false,
        indexingKey: 'poLineNumber',
        primaryKeyToStore: 'splitLineId',
        keysToStore: ['confirmedGoodsdate', 'confirmedCbm', 'confirmedWeight'],
        poLineViewPath: '',
        poLineKeyToStore: '',
        columnsToDisableBy: ['originPort', 'clientName', 'destinationPort'],
        shouldClearFitlerOnUnselect: false,
        noDataMessage: 'No PO Lines available',
        noDataForFilter: null,
        tableHeaderList: [
            {
                headerName: 'CBM',
                key: 'cbm',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-ao-cbm-cost',
                isMandatory: true,
            },
            {
                headerName: 'WEIGHT(Kg)',
                key: 'weight',
                filter: false,
                sort: false,
                style: 'grid-cell-header col-ao-weight',
                isMandatory: true,
            },
            {
                headerName: 'GOODS READY DATE',
                filter: false,
                sort: false,
                key: 'goodsReadyDate',
                style: 'grid-cell-header col-ao-grd',
                isDate: true,
                isMandatory: true,
            },
            {
                headerName: 'DESTINATION',
                filter: false,
                sort: false,
                key: 'destinationPort',
                style: 'grid-cell-header col-ao-destination-port',
                isMandatory: true,
            },
            {
                headerName: 'DESTINATION ADDRESS',
                filter: false,
                sort: false,
                key: 'destinationAddress',
                style: 'grid-cell-header col-ao-dest',
                isMandatory: true,
            },
            {
                headerName: 'SKU',
                filter: false,
                sort: false,
                key: 'sku',
                renderSpecialData: true,
                style: 'grid-cell-header col-sku',
                sortKey: 'sku',
            },
            {
                headerName: 'QUANTITY',
                filter: false,
                sort: false,
                key: 'qty',
                style: 'grid-cell-header col-ao-quanity',
                columnWidth: 112,
            },
            {
                headerName: 'HS CODE',
                filter: false,
                sort: false,
                key: 'hsCode',
                style: 'grid-cell-header col-ao-hs-line',
                columnWidth: 232,
            },
        ],
    },
};

export const agentIntegrationHeaders = [
    'Inbound PO',
    'Inbound Advice',
    'Receipts',
    'Inventory',
    'Allocation Order',
    'Goods Issued',
];

export const defaultAgentIntegration = [
    { integration: 'Inbound PO', isIntegrated: false },
    { integration: 'Inbound Advice', isIntegrated: false },
    { integration: 'Receipts', isIntegrated: false },
    { integration: 'Inventory', isIntegrated: false },
    { integration: 'Allocation Order', isIntegrated: false },
    { integration: 'Goods Issued', isIntegrated: false },
];

export enum UserProfileType {
    Agent = 'agent',
    FORWARDER = 'forwarder',
    Client = 'client',
    Vendor = 'vendor',
    ShippingLine = 'shippingLine',
}

export enum InterfaceType {
    InterfacedPO = 'Interfaced PO',
    InterfacedAO = 'Interfaced AO',
}

export enum BookingType {
    poBooking = 1,
    poBookingAir = 2,
    aoBooking = 3,
}

export const bookingProposalStatusAll = 'Active BPs';
export const allPos = 'Active POs';
export const activeInboundPos = 'Active Inbound POs';
export const activeAos = 'Active AOs';
export const activeReceipts = 'Active Receipts';
export const awaitingClientApproval = 'Awaiting Client Approval';
export const bpActiveStatus = 'Active';
export const clearFilterText = 'Clear Filters';
export const awaitingEnrichment = 'Awaiting Enrichment';
export const activeStatus = 'Active';
export const noRecordForAppliedFilters = 'No records based on the applied filters';
export const noRecords = 'No records found';
export const clientAuthorization = {
    CONTRACT: 'In Contract',
    SOP: 'In SOP',
    EMAIL: 'In Email',
};

export const quickLinks = {
    createPo: {
        titleType: 'Create ',
        title: 'PO',
        iconName: '#createPoIcon',
        linkedURL: '/purchase_order/basic_details/?shippingMode=sea',
        permission: 'edit',
        type: 'po',
        userTypeCheck: true,
    },
    createInboundPo: {
        titleType: 'Create ',
        title: 'Inbound PO',
        iconName: '#createPoIcon',
        linkedURL: '/inbound/basic_details',
        permission: 'create',
        type: 'inbound',
        userTypeCheck: true,
    },
    createAllocation: {
        titleType: 'Create ',
        title: 'Allocation',
        iconName: '#createPoIcon',
        linkedURL: '/allocation_orders/add_new',
        permission: 'create',
        type: 'ao', // special case permission // !isBlp ?
        userTypeCheck: true,
    },
    createReceipt: {
        titleType: 'Create ',
        title: 'Receipt',
        iconName: '#createPoIcon',
        linkedURL: '/blp/receipt/new-receipt',
        permission: 'create',
        type: 'receipt', // special case permission //isBlp && blpIntegrationsReceipt
        userTypeCheck: true,
    },
    createBp: {
        titleType: 'Create ',
        title: 'BP',
        iconName: '#createPoIcon',
        linkedURL: 'booking_list/POSelection',
        permission: 'create',
        type: 'bp',
        userTypeCheck: true,
    },
    createSku: {
        titleType: 'Create ',
        title: 'SKU',
        iconName: '#createSkuIcon',
        linkedURL: `profile/clients/${localStorage.getItem('clientIdOfUser')}/product_master`,
        permission: 'edit',
        type: 'client',
        userTypeCheck: true,
    },
    createVendor: {
        titleType: 'Create ',
        title: 'Vendor',
        iconName: '#createVendorIcon',
        linkedURL: '/profile/vendor/basic_detail',
        permission: 'create',
        type: 'vendor',
        userTypeCheck: true,
    },
    // createUser: {
    //     titleType: 'Create ',
    //     title: 'User',
    //     iconName: '#createUserIcon',
    //     linkedURL: 'users/forwarder',
    //     permission: null,
    //     type: null,
    //     userTypeCheck: true,
    // },
    createRole: {
        titleType: 'Create ',
        title: 'Role',
        iconName: '#createRoleIcon',
        linkedURL: '/rolespermissions/newrole',
        permission: 'create',
        type: 'roles',
        userTypeCheck: true,
    },
    quickCheck: {
        titleType: 'Quick ',
        title: 'Check',
        iconName: '#createCheckStatusIcon',
        linkedURL: '#',
        permission: null,
        type: null,
    },
};

export const statusOfBpsWithUrls = [
    {
        name: 'Active BPs',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&$count',
        permission: ['agent', 'forwarder', 'client'],
    },
    {
        name: 'Awaiting Consolidation',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Awaiting Consolidation&$count',
        permission: ['agent', 'forwarder'],
    },
    {
        name: 'Draft Booking Proposal',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Draft Booking Proposal&$count',
        permission: ['agent', 'forwarder'],
    },
    {
        name: 'Awaiting Forwarder Approval',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Awaiting Forwarder Approval&$count',
        permission: ['agent', 'forwarder'],
    },
    {
        name: 'Awaiting Client Approval',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Awaiting Client Approval&$count',
        permission: ['agent', 'forwarder', 'client'],
    },
    {
        name: 'Rejected by Client: Forwarder to Action',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Rejected by Client Forwarder to Action&$count',
        permission: ['agent', 'forwarder'],
    },
    {
        name: 'Rejected by Client: Agent to Action',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Rejected by Client Agent to Action&$count',
        permission: ['agent', 'forwarder'],
    },
    {
        name: 'Rejected by Forwarder',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Rejected by Forwarder&$count',
        permission: ['agent', 'forwarder'],
    },
    {
        name: 'Rejected by Client',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Rejected by Client&$count',
        permission: ['agent', 'forwarder', 'client'],
    },
    {
        name: 'Ready for Booking',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Ready for Booking&$count',
        permission: ['agent', 'forwarder', 'client'],
    },
    {
        name: 'Draft Booking',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Draft Booking&$count',
        permission: ['agent', 'forwarder', 'client'],
    },
    {
        name: 'Booked',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Booked&$count',
        permission: ['agent', 'forwarder', 'client'],
    },
    {
        name: 'Withdrawn',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Withdrawn&$count',
        permission: ['agent', 'forwarder'],
    },
    {
        name: 'Pending Reassignment',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Pending Reassignment&$count',
        permission: ['forwarder'],
    },
    {
        name: 'Cancelled',
        url: 'cix/scr/forwarding/booking/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=BP_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Cancelled&$count',
        permission: ['agent', 'forwarder', 'client'],
    },
];

export const statusOfPosWithUrls = [
    { name: 'Active POs', url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&##WAREHOUSING##$count' },
    {
        name: 'Draft',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Draft&##WAREHOUSING##$count',
    },
    {
        name: 'Awaiting Enrichment',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Awaiting Enrichment&##WAREHOUSING##$count',
    },
    {
        name: 'Ready For Booking',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Ready For Booking&##WAREHOUSING##$count',
    },
    {
        name: 'Partially Booked',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Partially Booked&##WAREHOUSING##$count',
    },
    {
        name: 'Booked',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Booked&##WAREHOUSING##$count',
    },
    {
        name: 'Pending Reassignment',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Pending Reassignment&##WAREHOUSING##$count',
    },
    {
        name: 'Discarded',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Discarded&##WAREHOUSING##$count',
    },
    {
        name: 'Cancelled',
        url: 'cix/scr/forwarding/purchaseorder/?c=##PROFILE##&filter[logic]=and&filter[filters][1][field]=PO_Status&filter[filters][1][operator]=eq&filter[filters][1][value]=Cancelled&##WAREHOUSING##$count',
    },
];

export const permissionsWithProfileView = ['executive', 'cls admin'];

export const defaultPoDestinationAddress = {
    label: 'Required At Time Of Booking',
    value: '00000-00000-000000-0000',
};

export const todoBpAPIMap = {
    bpId: 'BP_PK',
    bpNumber: 'BP_Number',
    originPortName: 'BP_OriginPort',
    plannedEtd: 'BP_PlannedEtd',
    bpType: 'BP_Type',
    destinationPortName: 'BP_DestinationPort',
    plannedEta: 'BP_PlannedEta',
    poNumber: 'BO_PoNumbers',
    totTEU: 'BP_TotTEU',
    transitPortCount: 'BT_ViaPort',
};

export const noteIconStyle = {
    '--fa-primary-color': '#ff7b00',
    '--fa-secondary-color': '#ff7b00',
};
