/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import '../PurchaseOrder/reassign.scss';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchReassignBpList,
    selectReassignBp,
    fetchDistinctBpList,
    selectDistinctBp,
    selectReassignBpLoading,
    ReassignBpDetails,
} from 'src/store/reducers/reassignAgent/bookingProposalReducer';
import { getOrganisationData } from 'src/utils';
import { useUserProfile } from 'src/hooks/useUserProfile';
import moment from 'moment';
import ColumnFilter from '../Filter';
import Header from '../Header';
import CancelModal from 'src/components/common/CancelModal';
import ReassignModal from '../ReassignModal';
import { reassignAgentBpSeaDetails, reassignAgentBpAirDetails } from 'src/utils/tableStructure/reassignAgentBp';
import TableToolTip from 'src/components/common/TableToolTip';
const Index: React.FC = () => {
    const { type } = useParams();

    const { profileType } = useUserProfile();
    const organisationData = getOrganisationData();

    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>({});
    const [sortColumn, setSortColumn] = useState('bpNumber');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [filterColumn, setFilterColumn] = useState('');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const [selected, setSelected] = useState<any>([]);
    const [showModal, setShowModal] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const [rowExpand, setRowExpand] = useState(false);
    const [rowLine, setRowLine] = useState(null);
    const [columnExpand, setColumnExpand] = useState(null);

    const payload = {
        body: {
            ...organisationData,
        },
        sortColumn,
        sortOrder,
        profileType,
    };
    if (type === 'air') {
        payload.body.bpType = ['air'];
    }

    useEffect(() => {
        dispatch(fetchDistinctBpList({ ...payload }));
    }, []);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        setSearchName(keyword);
    };

    const filterDistance = (columnName: string) => {
        const el = document.getElementById(columnName);
        const distanceLeft = el?.getBoundingClientRect().left ?? 0;
        const distanceRight = el?.getBoundingClientRect().right ?? 0;
        if (window.innerWidth - distanceRight > 280) {
            setSetPosition(distanceRight - 60);
        } else {
            setSetPosition(distanceLeft - 195);
        }
    };

    const filterByColumn = (e: any, columnName: string) => {
        setFilterColumn(columnName);
        filterDistance(columnName);
        setSearchName('');
        filter(e);
        e.stopPropagation();
        setToggleDropdown((prev) => !prev);
    };

    const searchOrFilter = (newPayload: any, searchKey?: string) => {
        const bpSearch = searchKey !== undefined ? searchKey : searchTerm;
        const payloadCopy = JSON.parse(JSON.stringify(newPayload));
        if (newPayload?.body?.carrierFlights?.length) {
            const filterCarrierFlights = distinctBp?.carrierFlights?.length
                ? distinctBp?.carrierFlights?.map((res: any) => JSON.parse(res))
                : [];
            if (filterCarrierFlights?.length) {
                payloadCopy.body.carrierFlights = newPayload?.body?.carrierFlights?.map(
                    (res: any) => filterCarrierFlights?.filter((item: any) => item.carrierFlight === res)?.[0]
                );
            }
        }

        if (bpSearch?.trim()?.length > 1) {
            const finalPayload = { ...payloadCopy };
            finalPayload.body = { ...finalPayload.body, search: bpSearch };
            dispatch(fetchReassignBpList({ ...finalPayload }));
        } else {
            dispatch(fetchReassignBpList({ ...payloadCopy }));
        }
    };

    const sortByColumn = (columnName: string) => {
        const newOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newOrder);
        setSortColumn(columnName);
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
        }
        searchOrFilter({ ...payload, sortColumn: columnName, sortOrder: newOrder });
    };

    const onSearch = (searchKey: string) => {
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
        }
        if (searchKey.length > 2 || !searchKey.length) {
            searchOrFilter(payload, searchKey);
        }
        setSearchTerm(searchKey);
    };

    const onBpSelection = (bpDetail: ReassignBpDetails) => {
        const bpSelected = JSON.parse(JSON.stringify(selected));
        const alreadyExists = bpSelected.findIndex((res: any) => res.bpId === bpDetail.bpId);
        if (alreadyExists >= 0) {
            const filtered = bpSelected.filter((res: any) => res.bpId !== bpDetail.bpId);
            setSelected(filtered);
        } else {
            const filtered = [...bpSelected, bpDetail];
            setSelected(filtered);
        }
    };

    const filterData = () => {
        if (filterColumn === 'goodsReadyDate' || filterColumn === 'plannedEtd' || filterColumn === 'plannedEta') {
            return distinctBp[filterColumn]?.length
                ? distinctBp[filterColumn].map((res: string) => moment(res).format('DD-MM-YYYY'))
                : [];
        } else if (filterColumn === 'carrierFlights') {
            return distinctBp[filterColumn]?.map((res: any) => JSON.parse(res))?.map((res: any) => res?.carrierFlight);
        }
        return distinctBp[filterColumn];
    };

    useEffect(() => {
        if (Object.keys(statusFilterSelected).length) {
            payload.body = { ...payload.body, ...statusFilterSelected };
            searchOrFilter(payload);
        } else {
            searchOrFilter(payload);
        }
    }, [statusFilterSelected]);

    const dispatch = useAppDispatch();
    const reassignBp = useAppSelector(selectReassignBp);
    const distinctBp = useAppSelector(selectDistinctBp);
    const isLoading = useAppSelector(selectReassignBpLoading);

    const selectAllStatus = (property: string) => {
        if (property === 'disabled') {
            if (!selected.length) {
                return true;
            }
        } else {
            if (!selected.length) {
                return false;
            }
            const selectedItems = reassignBp?.filter(
                (e: any) => e.agentName === selected[0].agentName && e.originPort === selected[0].originPort
            );
            return (
                selectedItems.length !== 0 &&
                selectedItems.every((selectedItem) =>
                    selected.some((select: ReassignBpDetails) => selectedItem.bpId === select.bpId)
                )
            );
        }
    };

    const selectAllOnChange = (checkStatus: boolean) => {
        const filtered = reassignBp.filter(
            (e: any) => e.agentName === selected[0].agentName && e.originPort === selected[0].originPort
        );
        if (checkStatus) {
            setSelected(filtered);
        } else {
            setSelected([]);
        }
    };
    return (
        <>
            <div className="main-wrapper container reassign-grid-wrapper">
                <div className="main-header-content-holder large-header">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <Link to="/booking_list" className="dropdown-arrow">
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>User details</title>
                                    </use>
                                </svg>
                            </Link>
                            <li className="breadcrumb-list-item">
                                <Link to="/booking_list" className="breadcrumb-item">
                                    PO Bookings - {type === 'sea' ? 'Sea' : 'Air'}
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link to={`/reassign-agent/booking-proposal/${type}`} className="breadcrumb-item">
                                    Reassign Agent
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="pageheading-holder">
                        <div className="back-btn-holder">
                            <h2 className="page-heading">Reassign Agent</h2>
                            <div className="page-counter-wrapper">
                                <div className="page-heading__caption">Select from the list to reassign new agent</div>
                            </div>
                        </div>
                    </div>
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                </div>
                <div className="grid-section reassign-grid po-air-reassign-grid">
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="search-entry-holder">
                                <input
                                    placeholder={`Search PO Bookings - ${type === 'sea' ? 'Sea' : 'Air'}`}
                                    type="text"
                                    className="search-input input-text"
                                    value={searchTerm ?? ''}
                                    onChange={(e) => onSearch(e.target.value)}
                                ></input>
                                <div
                                    className={`search-toolip ${
                                        searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                    }`}
                                >
                                    Enter atleast 3 characters to search
                                </div>
                                <svg className="svg-icon search-icon">
                                    <use xlinkHref="#searchIcon"></use>
                                </svg>
                                <button onClick={() => onSearch('')} className="app-btn text-close-btn">
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="grid-container">
                            {(reassignBp === null || reassignBp?.length) === 0 && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No records found</p>
                                </div>
                            )}

                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <Header
                                        content={type === 'sea' ? reassignAgentBpSeaDetails : reassignAgentBpAirDetails}
                                        filterFunction={filterByColumn}
                                        sortFunction={sortByColumn}
                                        sortColumn={sortColumn}
                                        sortOrder={sortOrder}
                                        filterColumn={filterColumn}
                                        toggleDropdown={toggleDropdown}
                                        selectedFilterItem={statusFilterSelected}
                                        selectAllStatus={selectAllStatus}
                                        selectAllOnChange={selectAllOnChange}
                                    />

                                    <tbody className="has-child row-expanded">
                                        {reassignBp?.map((res: ReassignBpDetails, index: number) => (
                                            <tr key={res.bpId}>
                                                <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                                    <div className="grid-cell-data centre-align">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="checkbox"
                                                                className="checkbox-input"
                                                                checked={selected?.some(
                                                                    (e: any) => e.bpId === res.bpId
                                                                )}
                                                                disabled={
                                                                    selected?.length &&
                                                                    selected?.some(
                                                                        (e: any) =>
                                                                            e.agentName === res.agentName &&
                                                                            e.originPort === res.originPort
                                                                    ) === false
                                                                        ? true
                                                                        : false
                                                                }
                                                                onChange={() => onBpSelection(res)}
                                                            />
                                                            <div className="checkmark">
                                                                <svg className="svg-icon tick-icon">
                                                                    <use xlinkHref="#tickIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                    <div className="grid-cell-data">{res.bpNumber}</div>
                                                </td>

                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.bpStatus}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.agentName ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        <TableToolTip
                                                            data={res.poNumber?.length ? res.poNumber : []}
                                                            index={index}
                                                            settableRowExp={setRowExpand}
                                                            rowexpand={rowExpand}
                                                            rowLine={rowLine}
                                                            setRowLine={setRowLine}
                                                            itemId="poNumber"
                                                            columnExpand={columnExpand}
                                                            setColumnExpand={setColumnExpand}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        {/* <TableToolTip
                                                            data={res.clientNames?.length ? res.clientNames : []}
                                                            index={index}
                                                            settableRowExp={setRowExpand}
                                                            rowexpand={rowExpand}
                                                            rowLine={rowLine}
                                                            setRowLine={setRowLine}
                                                            itemId="clientNames"
                                                            columnExpand={columnExpand}
                                                            setColumnExpand={setColumnExpand}
                                                        /> */}
                                                        {res.clientName ?? '-'}
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.originPort ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.destinationPort ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">{res.destinationType ?? '-'}</div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        {/* <TableToolTip
                                                            data={res.vendorName?.length ? res.vendorName : []}
                                                            index={index}
                                                            settableRowExp={setRowExpand}
                                                            rowexpand={rowExpand}
                                                            rowLine={rowLine}
                                                            setRowLine={setRowLine}
                                                            itemId="vendorName"
                                                            columnExpand={columnExpand}
                                                            setColumnExpand={setColumnExpand}
                                                        /> */}
                                                        {res.vendorName ?? '-'}
                                                    </div>
                                                </td>

                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        {res?.goodsReadyDate?.length > 0 ? (
                                                            <TableToolTip
                                                                data={
                                                                    res?.goodsReadyDate
                                                                        ? res?.goodsReadyDate?.map((el) =>
                                                                              moment(el).format('DD-MM-YYYY')
                                                                          )
                                                                        : []
                                                                }
                                                                index={index}
                                                                settableRowExp={setRowExpand}
                                                                rowexpand={rowExpand}
                                                                rowLine={rowLine}
                                                                setRowLine={setRowLine}
                                                                itemId="goodsReadyDate"
                                                                columnExpand={columnExpand}
                                                                setColumnExpand={setColumnExpand}
                                                            />
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        {res.plannedEtd
                                                            ? moment(res.plannedEtd).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        {res.plannedEta
                                                            ? moment(res.plannedEta).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        <TableToolTip
                                                            data={res.releaseType?.length ? res.releaseType : []}
                                                            index={index}
                                                            settableRowExp={setRowExpand}
                                                            rowexpand={rowExpand}
                                                            rowLine={rowLine}
                                                            setRowLine={setRowLine}
                                                            itemId="releaseType"
                                                            columnExpand={columnExpand}
                                                            setColumnExpand={setColumnExpand}
                                                        />
                                                    </div>
                                                </td>
                                                <td className="grid-cell">
                                                    <div className="grid-cell-data">
                                                        {type === 'sea' ? (
                                                            res.vesselVoyage ?? '-'
                                                        ) : (
                                                            <TableToolTip
                                                                data={
                                                                    res.carrierFlights?.length
                                                                        ? res.carrierFlights?.map(
                                                                              (res) => res.carrierFlight
                                                                          )
                                                                        : []
                                                                }
                                                                index={index}
                                                                settableRowExp={setRowExpand}
                                                                rowexpand={rowExpand}
                                                                rowLine={rowLine}
                                                                setRowLine={setRowLine}
                                                                itemId="carrierFlights"
                                                                columnExpand={columnExpand}
                                                                setColumnExpand={setColumnExpand}
                                                            />
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {toggleDropdown && (
                            <ColumnFilter
                                setPosition={setPosition}
                                searchName={searchName}
                                setSearchName={setSearchName}
                                filter={filter}
                                data={filterData()}
                                statusFilterSelected={statusFilterSelected}
                                setStatusFilterSelected={setStatusFilterSelected}
                                isLoading={isLoading}
                                setToggleDropdown={setToggleDropdown}
                                columnName={filterColumn}
                                currentColumnName={filterColumn}
                                setCurrentColumnName={setFilterColumn}
                            />
                        )}
                    </div>
                </div>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <div className="tab-with-proposal">
                            <div className="tab-grid-select-holder">
                                {selected?.length > 0 && (
                                    <span className="btn-pills info-pill">
                                        <div className="pill-text">
                                            {selected[0].agentName} :{' '}
                                            <span>
                                                {selected?.length} {selected?.length === 1 ? 'Booking' : 'Bookings'}{' '}
                                                Selected
                                            </span>
                                        </div>
                                        <button onClick={() => setSelected([])} className="pill-action" type="button">
                                            <svg className="svg-icon close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </span>
                                )}

                                {/* <a style={{ textDecoration: 'none' }}> */}
                                <button
                                    disabled={selected?.length ? false : true}
                                    className="app-btn app-btn-primary footer-btn"
                                    title="Reassign Agent"
                                    onClick={() => setShowModal(true)}
                                >
                                    <span className="button-text footer-button-text">Reassign Agent</span>
                                </button>
                                {/* </a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showModal && (
                <ReassignModal
                    type="bp"
                    data={selected}
                    setShowModal={setShowModal}
                    setDisplayCancel={setDisplayCancel}
                />
            )}
            <CancelModal
                displayFunction={setShowModal}
                mode="edit"
                header="Reassign"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
        </>
    );
};
export default Index;
