import axios from 'axios';
import { get, post } from '../HttpMethods';

export const getVendorUser = async (id: string | undefined) => {
    try {
        const data = await get(`api/mgt/user/vendoruserdetail/${id}`, null);
        const response = {
            ...data,
        };
        delete response?.orgPK;
        delete response?.profile;
        delete response?.status;
        delete response?.userTitle;
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const addVendorUser = async (payload: any) => {
    try {
        const data = await post('api/mgt/user/createvendoruser', payload, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
