import React, { Fragment, useEffect, useState } from 'react';
import './booking-enrichment-additional.scss';
import BookingDetailModal from '../BookingEnrichmentAdditionalDetail/BookingDetailModal';
// import CancelBookingModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { useParams } from 'react-router-dom';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    fetchEnrichBooking,
    selectEnrichBooking,
    updateEnrichBooking,
    selectAdditionalDetailsIsError,
    selectAdditionalDetailsSuccess,
    selectAdditionalDetailsError,
    clearFailure,
    selectAdditionalDetailsLoading,
    fetchHouseBills,
    selectHouseBillDetails,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import CancelModal from '../../../components/common/CancelModal';
import { error, Position, success } from '../../../utils/toast';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import HouseBillModal from './HouseBillNumbersModal';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import ShowForPermissions from 'src/ShowForPermissions';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const [modalView, setModalView] = useState(false);
    const [cancelModalView, setCancelModalView] = useState(false);
    const [bookingRefNum, setBookingRefNum] = useState('');
    const [soNumber, setSoNumber] = useState('');

    // const [houseBillNo, setHouseBillNo] = useState('');
    const [originCartageCompany, setOriginCartageCompany] = useState('');
    const { profileType } = useUserProfile();
    const { agentIntegrationStatus, bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const [houseBilllModalView, setHouseBillModalView] = useState(false);
    const houseBillData = useAppSelector(selectHouseBillDetails);
    const isError = useAppSelector(selectAdditionalDetailsIsError);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const additionalDetailsError = useAppSelector(selectAdditionalDetailsError);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);

    useEffect(() => {
        // dispatch(fetchEnrichBooking({ bpId: id }));
        dispatch(fetchHouseBills({ bpId: id }));
    }, []);

    const submit = () => {
        dispatch(
            updateEnrichBooking({
                bpId: id,
                bookingRefNum: bookingRefNum,
                soNumber: soNumber,
                // houseBillNo: houseBillNo,
                originCartageCompany: originCartageCompany,
                mode: enrichBooking?.soNumber ? 'update' : 'add',
            })
        );
    };

    useEffect(() => {
        if (enrichBooking) {
            setBookingRefNum(enrichBooking.bookingRefNum ? enrichBooking.bookingRefNum : '');
            setSoNumber(enrichBooking.soNumber ? enrichBooking.soNumber : '');
            // setHouseBillNo(enrichBooking.houseBillNo ? enrichBooking.houseBillNo : '');
            setOriginCartageCompany(enrichBooking.originCartageCompany ? enrichBooking.originCartageCompany : '');
        }
    }, [modalView]);

    useEffect(() => {
        if (isError) {
            error(additionalDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (additionalDetailsSuccess?.status === 200 || additionalDetailsSuccess?.status === 201) {
            if (additionalDetailsSuccess?.data?.type === 'enrichBooking') {
                success(
                    `Booking Details ${
                        additionalDetailsSuccess?.data?.mode === 'add' ? 'added' : 'updated'
                    } successfully`,
                    Position.TOP_RIGHT
                );
                setModalView(false);
                dispatch(fetchEnrichBooking({ bpId: id }));
                dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(clearFailure([]));
            }
        }
    }, [isError, additionalDetailsSuccess, additionalDetailsError]);

    const agentIntegrationStatusValidation = () => {
        if (agentIntegrationStatus === true) {
            if (
                enrichBooking?.soNumber === '' ||
                enrichBooking?.soNumber === null ||
                // enrichBooking?.houseBillNo === '' ||
                // enrichBooking?.houseBillNo === null ||
                enrichBooking?.originCartageCompany === '' ||
                enrichBooking?.originCartageCompany === null
            ) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    };

    return (
        <>
            <div className="booking-detail-wrapper">
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Booking Details</h6>
                            <ShowForPermissions type="bp" permission="create">
                                {enrichBooking?.soNumber &&
                                    (profileType === 'agent' || profileType === 'forwarder') &&
                                    agentIntegrationStatusValidation() &&
                                    bpStatus !== 'Booked' && (
                                        <button
                                            onClick={() => setModalView(true)}
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon">
                                                    <title>Edit</title>
                                                </use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions type="bp" permission="create">
                            {!enrichBooking?.soNumber &&
                                (profileType === 'agent' || profileType === 'forwarder') &&
                                agentIntegrationStatusValidation() &&
                                bpStatus !== 'Booked' && (
                                    <div className="btn-holder">
                                        <button
                                            onClick={() => setModalView(true)}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon">
                                                    <title>Add</title>
                                                </use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    </div>
                                )}
                        </ShowForPermissions>
                    </div>
                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Booking Reference No.<span className="mandatory-field-text">*</span>
                            </h6>

                            <div className="details-box-content">
                                {enrichBooking?.bookingRefNum ? enrichBooking?.bookingRefNum : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                SO Number<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {enrichBooking?.soNumber ? enrichBooking?.soNumber : '-'}
                            </div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                House Bill No.<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {enrichBooking?.houseBillNo ? enrichBooking?.houseBillNo : '-'}
                            </div>
                        </div> */}
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Origin Cartage Company<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {enrichBooking?.originCartageCompany ? enrichBooking?.originCartageCompany : '-'}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Billing Numbers</h6>
                            <ShowForPermissions type="bp" permission="create">
                                {(profileType === 'agent' || profileType === 'forwarder') &&
                                    houseBillData?.numbers?.[0]?.vendors?.[0].houseBillNumber &&
                                    bpStatus !== 'Booked' && (
                                        <button
                                            onClick={() => setHouseBillModalView(true)}
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon">
                                                    <title>Edit</title>
                                                </use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions type="bp" permission="create">
                            {(profileType === 'agent' || profileType === 'forwarder') &&
                            !houseBillData?.numbers?.[0]?.vendors?.[0].houseBillNumber &&
                            bpStatus !== 'Booked' ? (
                                <div className="btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Details"
                                        onClick={() => setHouseBillModalView(true)}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon">
                                                <title>Add</title>
                                            </use>
                                        </svg>
                                        <span className="button-text">Add Details</span>
                                    </button>
                                </div>
                            ) : null}
                        </ShowForPermissions>
                    </div>
                    {profileType !== 'client' && (
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Master Bill No.</h6>
                                <div className="details-box-content">
                                    {houseBillData?.masterBillNumber ? houseBillData?.masterBillNumber : '-'}
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="card-head-holder add-btn-holder ">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">
                                House Bill Number{houseBillData?.isHBLAutoGen ? ' (Auto Generated)' : ''}
                            </h6>
                            <span className="count">1 Vendor</span>
                        </div>
                    </div>
                    {houseBillData?.numbers?.map((item: any, index: number) => (
                        <Fragment key={index}>
                            {/* <div className="card-head-holder add-btn-holder -house-bill-wrapper">
                                <div className="card-head-btn-edit-holder">
                                    <h6 className="details-box-heading-2">
                                        {index + 1}. {item.client.name}
                                    </h6>
                                    <span className="count">{item.vendorCount} Vendors</span>
                                </div>
                            </div> */}
                            {/* <div className="card-detail-col">
                                {item.vendors?.map((vendor: any, indx: number) => (
                                    <div className="details-col box-content-holder" key={indx}>
                                        <h6 className="details-box-heading">
                                            {vendor.name}
                                            <span className="mandatory-field-text">*</span>
                                        </h6>
                                        <div className="details-box-content">{vendor.houseBillNumber}</div>
                                    </div>
                                ))}
                            </div> */}
                            <div className="card-detail-col">
                                {item.vendors.map((vendor: any, vendorIndex: number) => {
                                    return (
                                        <div className="details-col box-content-holder">
                                            <h6 className="details-box-heading">
                                                {vendorIndex + 1}. {vendor.name}
                                                <span className="mandatory-field-text">*</span>
                                            </h6>
                                            <div className="details-box-content">{vendor.houseBillNumber || '-'}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </Fragment>
                    ))}

                    {/* <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Just Jeans</h6>
                            <span className="count">2</span>
                        </div>
                        </div>
                        <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Vendor Name 1<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">A11456755</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Vendor Name 2<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">A11456766</div>
                        </div>
                    </div> */}
                </div>
            </div>
            <BookingDetailModal
                modalView={modalView}
                setModalView={setModalView}
                bookingRefNum={bookingRefNum}
                setBookingRefNum={setBookingRefNum}
                soNumber={soNumber}
                setSoNumber={setSoNumber}
                // houseBillNo={houseBillNo}
                // setHouseBillNo={setHouseBillNo}
                originCartageCompany={originCartageCompany}
                setOriginCartageCompany={setOriginCartageCompany}
                submit={submit}
                isLoading={isLoading}
                setCancelModalView={setCancelModalView}
                data={enrichBooking}
            />

            {/* <CancelBookingModal></CancelBookingModal> */}
            {houseBilllModalView ? (
                <HouseBillModal
                    setCancelModalView={setCancelModalView}
                    setHouseBillModalView={setHouseBillModalView}
                    houseBillData={houseBillData}
                ></HouseBillModal>
            ) : null}
            <CancelModal
                displayFunction={() => {
                    setModalView(false);
                    setHouseBillModalView(false);
                }}
                header="Booking Details"
                cancelModalVisibility={cancelModalView}
                setCancelModalVisibility={setCancelModalView}
            />
        </>
    );
};
export default Index;
