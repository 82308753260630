import ShowForPermissions from '../../../../ShowForPermissions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
    downloadAll,
    downloadSingle,
    removeSingleFile,
    saveFilesSuccess,
    uploadFiles,
} from '../../../../store/reducers/receipts/receiptAttachment';
//import './booking-attachment.scss';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { fetchReceiptHeaderList, selectReceiptHeader } from 'src/store/reducers/receipts/receiptHeaderReducer';
import { useAppSelector } from '../../../../hooks';
import { fileUploadInfoText } from '../../../../utils/constants';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { fileTypesInfoText } from 'src/utils/constants';

/* eslint no-var: off */
/* eslint-disable no-undefined */

interface fileInterface {
    data: any;
}
const Index: React.FC<fileInterface> = ({ data }) => {
    const dispatch = useDispatch();
    const [showMore, setshowMore] = useState({
        customsInspectionCertificate: false,
        fumigationCertificate: false,
        packingList: false,
        qcCertificate: false,
    });
    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const receiptHeader = useAppSelector(selectReceiptHeader);
    const { isBlp, blpIntegrationsReceipt } = useUserProfile();
    const saveFilesSuccessData = useAppSelector(saveFilesSuccess);
    const { id } = useParams();
    const upload = (type: any, file: any) => {
        const formData = new FormData();
        formData.append('docType', type);
        formData.append('files', file[0]);
        const payload = {
            id: id,
            data: formData,
        };
        dispatch(uploadFiles(payload));
    };

    React.useEffect(() => {
        dispatch(
            fetchReceiptHeaderList({
                id,
            })
        );
    }, [saveFilesSuccessData]);

    const removeFile = (fileId: any) => {
        dispatch(removeSingleFile({ fileId: fileId, id: id }));
    };

    const downloadFiles = (type: any) => {
        dispatch(downloadAll({ type: type, id: id }));
    };

    const downloadFileSingle = (fileId: any, name: any) => {
        dispatch(downloadSingle({ fileId: fileId, name: name }));
    };
    const checkPermission = (permission: any, type: any) => {
        var flag = true;
        for (var i = 0; i < permissions?.length; i++) {
            if (
                ((permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                    (permissions[i] as any).access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes(type.toLowerCase())) ||
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('alladmin')
            ) {
                flag = false;
            }
        }
        return flag;
    };
    return (
        <>
            <div className="booking-attachment-wrapper">
                <div className="attachments-wrapper">
                    {/*Packing list*/}
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder">
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">
                                    Packing List{' '}
                                    {receiptHeader?.receiptStatus !== 'Draft' ? (
                                        <span className="mandatory-field-text">*</span>
                                    ) : (
                                        ''
                                    )}
                                </h6>
                            </div>
                            {data?.packingList?.length !== 0 && (
                                <div className="btn-holder">
                                    <button
                                        className="action-btn app-btn app-btn-secondary "
                                        title="Download All"
                                        onClick={() => downloadFiles('PACKING_LIST')}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#downloadIcon"></use>
                                            <title>Download</title>
                                        </svg>
                                    </button>

                                    <>
                                        <ShowForPermissions permission="create" type="receipt">
                                            {!blpIntegrationsReceipt && data?.receiptStatus !== 'Closed' && isBlp && (
                                                <div className="browse-btn-wrapper">
                                                    <input
                                                        type="file"
                                                        id={'ad_file'}
                                                        title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                        name={'ad_file'}
                                                        accept={
                                                            '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                        }
                                                        className="browse-input"
                                                        onChange={(e) => upload('PACKING_LIST', e.target.files)}
                                                        onClick={(event) => ((event.target as any).value = null)}
                                                    ></input>
                                                    <button
                                                        className="app-btn app-btn-secondary icon-button browse-btn"
                                                        title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                        type="button"
                                                    >
                                                        <svg className="svg-icon plus-icon">
                                                            <use xlinkHref="#plusIcon">
                                                                <title>Upload File</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            )}
                                        </ShowForPermissions>
                                    </>
                                </div>
                            )}
                        </div>
                        <div className="card-detail-col">
                            <div className="attachment-items-holder">
                                {data?.packingList?.length !== 0 ? (
                                    data?.packingList
                                        ?.slice(0, showMore.packingList ? data?.packingList?.length : 3)
                                        .map((res: any) => (
                                            <div className="content-with-icon  attachment-holder">
                                                <div
                                                    className="attachment-icon-holder"
                                                    onClick={() => downloadFileSingle(res?.fileId, res?.fileName)}
                                                >
                                                    <button type="button" className="app-btn " title="Attach file">
                                                        <svg className="svg-icon file-attachment-icon ">
                                                            <use xlinkHref="#fileAttachmentIcon"></use>
                                                            <title>Attach file</title>
                                                        </svg>
                                                    </button>
                                                    <div className="filename-folder">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="description-type-1 file-uploaded"
                                                            title="Download"
                                                        >
                                                            {res?.fileName}
                                                        </a>
                                                        <div className="heading-type-1">{res?.fileSize}kb</div>
                                                    </div>
                                                </div>
                                                <ShowForPermissions permission="create" type="receipt">
                                                    {!blpIntegrationsReceipt &&
                                                        data?.receiptStatus !== 'Closed' &&
                                                        isBlp && (
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="file-remove-link"
                                                                title="Remove"
                                                                onClick={() => removeFile(res?.fileId)}
                                                            >
                                                                Remove
                                                            </a>
                                                        )}
                                                </ShowForPermissions>
                                            </div>
                                        ))
                                ) : (
                                    <div className=" modal-input-holder upload-file-btn">
                                        <div className="modal-input-layout-item">
                                            {data?.receiptStatus !== 'Closed' && isBlp && !blpIntegrationsReceipt && (
                                                <ShowForPermissions permission="create" type="receipt">
                                                    <>
                                                        <div className="browse-btn-wrapper">
                                                            <input
                                                                type="file"
                                                                id={'ad_file'}
                                                                name={'ad_file'}
                                                                accept={
                                                                    '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                }
                                                                className="browse-input"
                                                                onChange={(e) =>
                                                                    upload('PACKING_LIST', e.target.files as any[0])
                                                                }
                                                            ></input>
                                                            <button
                                                                className="app-btn app-btn-secondary icon-button browse-btn"
                                                                title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                            >
                                                                <svg className="svg-icon plus-icon">
                                                                    <use xlinkHref="#plusIcon">
                                                                        <title>Upload File</title>
                                                                    </use>
                                                                </svg>
                                                                <span className="button-text upload-btn">
                                                                    Upload File
                                                                </span>
                                                            </button>

                                                            {/* <div className="error-text">mk</div> */}
                                                        </div>
                                                        <div className="heading-type-1 file-size">
                                                            <p style={{ textAlign: 'center' }}>{fileUploadInfoText}</p>
                                                            <p
                                                                style={{ textAlign: 'center' }}
                                                                dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}
                                                            ></p>
                                                        </div>
                                                    </>
                                                </ShowForPermissions>
                                            )}
                                        </div>
                                    </div>
                                )}
                                {data?.packingList?.length > 3 && !showMore.packingList && (
                                    <div className="content-with-icon view-more">
                                        <a
                                            href="javascript:void(0)"
                                            className="file-remove-link"
                                            title="More"
                                            onClick={() => setshowMore({ ...showMore, packingList: true })}
                                        >
                                            +{data?.packingList?.length - 3} more
                                        </a>
                                    </div>
                                )}
                                {showMore.packingList && (
                                    <div className="content-with-icon view-more">
                                        <a
                                            href="javascript:void(0)"
                                            className="file-remove-link"
                                            title="Show Less"
                                            onClick={() => setshowMore({ ...showMore, packingList: false })}
                                        >
                                            Show less
                                        </a>
                                    </div>
                                )}
                                {((checkPermission('create', 'receipt') !== undefined &&
                                    checkPermission('create', 'receipt')) ||
                                    !isBlp ||
                                    blpIntegrationsReceipt) &&
                                data?.packingList?.length === 0 ? (
                                    <div className="no-data-content">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No files attached</p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                    {/*Fumigation Certificate*/}
                    {receiptHeader?.fumigationRequired && (
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="card-head-btn-edit-holder">
                                    <h6 className="details-box-heading-2">
                                        Fumigation Certificate{' '}
                                        {receiptHeader?.fumigationRequired &&
                                        receiptHeader?.receiptStatus !== 'Draft' ? (
                                            <span className="mandatory-field-text">*</span>
                                        ) : (
                                            ''
                                        )}
                                    </h6>
                                </div>
                                {data?.fumigationCertificate?.length !== 0 && (
                                    <div className="btn-holder">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Download All"
                                            onClick={() => downloadFiles('FUMIGATION_CERTIFICATE')}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#downloadIcon"></use>
                                                <title>Download</title>
                                            </svg>
                                        </button>

                                        <>
                                            {' '}
                                            <ShowForPermissions permission="create" type="receipt">
                                                {!blpIntegrationsReceipt && data?.receiptStatus !== 'Closed' && isBlp && (
                                                    <div className="browse-btn-wrapper">
                                                        <input
                                                            type="file"
                                                            id={'ad_file'}
                                                            title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                            name={'ad_file'}
                                                            accept={
                                                                '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                            }
                                                            className="browse-input"
                                                            onChange={(e) =>
                                                                upload(
                                                                    'FUMIGATION_CERTIFICATE',
                                                                    e.target.files as any[0]
                                                                )
                                                            }
                                                            onClick={(event) => ((event.target as any).value = null)}
                                                        ></input>
                                                        <button
                                                            className="app-btn app-btn-secondary icon-button browse-btn"
                                                            title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                        >
                                                            <svg className="svg-icon plus-icon">
                                                                <use xlinkHref="#plusIcon">
                                                                    <title>Upload File</title>
                                                                </use>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </ShowForPermissions>
                                        </>
                                    </div>
                                )}
                            </div>
                            <div className="card-detail-col">
                                <div className="attachment-items-holder">
                                    {data?.fumigationCertificate?.length !== 0 ? (
                                        data?.fumigationCertificate
                                            ?.slice(
                                                0,
                                                showMore.fumigationCertificate ? data?.fumigationCertificate?.length : 3
                                            )
                                            .map((res: any) => (
                                                <div className="content-with-icon  attachment-holder">
                                                    <div
                                                        className="attachment-icon-holder"
                                                        onClick={() => downloadFileSingle(res?.fileId, res?.fileName)}
                                                    >
                                                        <button type="button" className="app-btn " title="Attach file">
                                                            <svg className="svg-icon file-attachment-icon ">
                                                                <use xlinkHref="#fileAttachmentIcon"></use>
                                                                <title>Attach file</title>
                                                            </svg>
                                                        </button>
                                                        <div className="filename-folder">
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="description-type-1 file-uploaded"
                                                                title="Download"
                                                            >
                                                                {res?.fileName}
                                                            </a>
                                                            <div className="heading-type-1">{res?.fileSize}kb</div>
                                                        </div>
                                                    </div>
                                                    <ShowForPermissions permission="create" type="receipt">
                                                        {!blpIntegrationsReceipt &&
                                                            data?.receiptStatus !== 'Closed' &&
                                                            isBlp && (
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    className="file-remove-link"
                                                                    title="Remove"
                                                                    onClick={() => removeFile(res?.fileId)}
                                                                >
                                                                    Remove
                                                                </a>
                                                            )}
                                                    </ShowForPermissions>
                                                </div>
                                            ))
                                    ) : (
                                        <div className=" modal-input-holder upload-file-btn">
                                            <div className="modal-input-layout-item">
                                                {!blpIntegrationsReceipt && data?.receiptStatus !== 'Closed' && isBlp && (
                                                    <ShowForPermissions permission="create" type="receipt">
                                                        <>
                                                            <div className="browse-btn-wrapper">
                                                                <input
                                                                    type="file"
                                                                    id={'ad_file'}
                                                                    name={'ad_file'}
                                                                    accept={
                                                                        '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                    }
                                                                    className="browse-input"
                                                                    onChange={(e) =>
                                                                        upload(
                                                                            'FUMIGATION_CERTIFICATE',
                                                                            e.target.files as any[0]
                                                                        )
                                                                    }
                                                                ></input>
                                                                <button
                                                                    className="app-btn app-btn-secondary icon-button browse-btn"
                                                                    title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                                >
                                                                    <svg className="svg-icon plus-icon">
                                                                        <use xlinkHref="#plusIcon">
                                                                            <title>Upload File</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="button-text upload-btn">
                                                                        Upload File
                                                                    </span>
                                                                </button>

                                                                {/* <div className="error-text">mk</div> */}
                                                            </div>
                                                            <div className="heading-type-1 file-size">
                                                                <p style={{ textAlign: 'center' }}>
                                                                    {fileUploadInfoText}
                                                                </p>
                                                                <p
                                                                    style={{ textAlign: 'center' }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: fileTypesInfoText,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </>
                                                    </ShowForPermissions>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {data?.fumigationCertificate?.length > 3 && !showMore.fumigationCertificate && (
                                        <div className="content-with-icon view-more">
                                            <a
                                                href="javascript:void(0)"
                                                className="file-remove-link"
                                                title="More"
                                                onClick={() =>
                                                    setshowMore({
                                                        ...showMore,
                                                        fumigationCertificate: true,
                                                    })
                                                }
                                            >
                                                +{data?.fumigationCertificate?.length - 3} more
                                            </a>
                                        </div>
                                    )}
                                    {showMore.fumigationCertificate && (
                                        <div className="content-with-icon view-more">
                                            <a
                                                href="javascript:void(0)"
                                                className="file-remove-link"
                                                title="Show Less"
                                                onClick={() =>
                                                    setshowMore({ ...showMore, fumigationCertificate: false })
                                                }
                                            >
                                                Show less
                                            </a>
                                        </div>
                                    )}
                                    {((checkPermission('create', 'receipt') !== undefined &&
                                        checkPermission('create', 'receipt')) ||
                                        !isBlp ||
                                        blpIntegrationsReceipt) &&
                                    data?.fumigationCertificate?.length === 0 ? (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No files attached</p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {/*Custom Inspection Service*/}
                    {receiptHeader?.customsInspectionRequired && (
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="card-head-btn-edit-holder">
                                    <h6 className="details-box-heading-2">
                                        Custom Inspection Certificate
                                        {receiptHeader?.customsInspectionRequired &&
                                        receiptHeader?.receiptStatus !== 'Draft' ? (
                                            <span className="mandatory-field-text">*</span>
                                        ) : (
                                            ''
                                        )}{' '}
                                    </h6>
                                </div>
                                {data?.customsInspectionCertificate?.length !== 0 && (
                                    <div className="btn-holder">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Download All"
                                            onClick={() => downloadFiles('CUSTOMS_INSPECTION_CERTIFICATE')}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#downloadIcon"></use>
                                                <title>Download</title>
                                            </svg>
                                        </button>

                                        <>
                                            {' '}
                                            <ShowForPermissions permission="create" type="receipt">
                                                {!blpIntegrationsReceipt && data?.receiptStatus !== 'Closed' && isBlp && (
                                                    <div className="browse-btn-wrapper">
                                                        <input
                                                            type="file"
                                                            id={'ad_file'}
                                                            title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                            name={'ad_file'}
                                                            accept={
                                                                '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                            }
                                                            className="browse-input"
                                                            onChange={(e) =>
                                                                upload(
                                                                    'CUSTOMS_INSPECTION_CERTIFICATE',
                                                                    e.target.files as any[0]
                                                                )
                                                            }
                                                        ></input>
                                                        <button
                                                            className="app-btn app-btn-secondary icon-button browse-btn"
                                                            title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                        >
                                                            <svg className="svg-icon plus-icon">
                                                                <use xlinkHref="#plusIcon">
                                                                    <title>Upload File</title>
                                                                </use>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </ShowForPermissions>
                                        </>
                                    </div>
                                )}
                            </div>
                            <div className="card-detail-col">
                                <div className="attachment-items-holder">
                                    {data?.customsInspectionCertificate?.length !== 0 ? (
                                        data?.customsInspectionCertificate
                                            ?.slice(
                                                0,
                                                showMore.customsInspectionCertificate
                                                    ? data?.customsInspectionCertificate?.length
                                                    : 3
                                            )
                                            .map((res: any) => (
                                                <div className="content-with-icon  attachment-holder">
                                                    <div
                                                        className="attachment-icon-holder"
                                                        onClick={() => downloadFileSingle(res?.fileId, res?.fileName)}
                                                    >
                                                        <button type="button" className="app-btn " title="Attach file">
                                                            <svg className="svg-icon file-attachment-icon ">
                                                                <use xlinkHref="#fileAttachmentIcon"></use>
                                                                <title>Attach file</title>
                                                            </svg>
                                                        </button>
                                                        <div className="filename-folder">
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="description-type-1 file-uploaded"
                                                                title="Download"
                                                            >
                                                                {res?.fileName}
                                                            </a>
                                                            <div className="heading-type-1">{res?.fileSize}kb</div>
                                                        </div>
                                                    </div>
                                                    <ShowForPermissions permission="create" type="receipt">
                                                        {!blpIntegrationsReceipt &&
                                                            data?.receiptStatus !== 'Closed' &&
                                                            isBlp && (
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    className="file-remove-link"
                                                                    title="Remove"
                                                                    onClick={() => removeFile(res?.fileId)}
                                                                >
                                                                    Remove
                                                                </a>
                                                            )}
                                                    </ShowForPermissions>
                                                </div>
                                            ))
                                    ) : (
                                        <div className=" modal-input-holder upload-file-btn">
                                            <div className="modal-input-layout-item">
                                                {!blpIntegrationsReceipt && data?.receiptStatus !== 'Closed' && isBlp && (
                                                    <ShowForPermissions permission="create" type="receipt">
                                                        <>
                                                            <div className="browse-btn-wrapper">
                                                                <input
                                                                    type="file"
                                                                    id={'ad_file'}
                                                                    name={'ad_file'}
                                                                    accept={
                                                                        '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                    }
                                                                    className="browse-input"
                                                                    onChange={(e) =>
                                                                        upload(
                                                                            'CUSTOMS_INSPECTION_CERTIFICATE',
                                                                            e.target.files as any[0]
                                                                        )
                                                                    }
                                                                ></input>
                                                                <button
                                                                    className="app-btn app-btn-secondary icon-button browse-btn"
                                                                    title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                                >
                                                                    <svg className="svg-icon plus-icon">
                                                                        <use xlinkHref="#plusIcon">
                                                                            <title>Upload File</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="button-text upload-btn">
                                                                        Upload File
                                                                    </span>
                                                                </button>

                                                                {/* <div className="error-text">mk</div> */}
                                                            </div>
                                                            <div className="heading-type-1 file-size">
                                                                <p style={{ textAlign: 'center' }}>
                                                                    {fileUploadInfoText}
                                                                </p>
                                                                <p
                                                                    style={{ textAlign: 'center' }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: fileTypesInfoText,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </>
                                                    </ShowForPermissions>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {data?.customsInspectionCertificate?.length > 3 &&
                                        !showMore.customsInspectionCertificate && (
                                            <div className="content-with-icon view-more">
                                                <a
                                                    href="javascript:void(0)"
                                                    className="file-remove-link"
                                                    title="More"
                                                    onClick={() =>
                                                        setshowMore({
                                                            ...showMore,
                                                            customsInspectionCertificate: true,
                                                        })
                                                    }
                                                >
                                                    +{data?.customsInspectionCertificate?.length - 3} more
                                                </a>
                                            </div>
                                        )}
                                    {showMore.customsInspectionCertificate && (
                                        <div className="content-with-icon view-more">
                                            <a
                                                href="javascript:void(0)"
                                                className="file-remove-link"
                                                title="Show Less"
                                                onClick={() =>
                                                    setshowMore({
                                                        ...showMore,
                                                        customsInspectionCertificate: false,
                                                    })
                                                }
                                            >
                                                Show less
                                            </a>
                                        </div>
                                    )}
                                    {((checkPermission('create', 'receipt') !== undefined &&
                                        checkPermission('create', 'receipt')) ||
                                        !isBlp ||
                                        blpIntegrationsReceipt) &&
                                    data?.customsInspectionCertificate?.length === 0 ? (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No files attached</p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                    {/*QC Certificate*/}
                    {receiptHeader?.qcInspectionRequired && (
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="card-head-btn-edit-holder">
                                    <h6 className="details-box-heading-2">
                                        QC Certificate{' '}
                                        {receiptHeader?.qcInspectionRequired &&
                                        receiptHeader?.receiptStatus !== 'Draft' ? (
                                            <span className="mandatory-field-text">*</span>
                                        ) : (
                                            ''
                                        )}
                                    </h6>
                                </div>
                                {data?.qcCertificate?.length !== 0 && (
                                    <div className="btn-holder">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Download All"
                                            onClick={() => downloadFiles('QC_CERTIFICATE')}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#downloadIcon"></use>
                                                <title>Download</title>
                                            </svg>
                                        </button>

                                        <>
                                            {' '}
                                            <ShowForPermissions permission="create" type="receipt">
                                                {!blpIntegrationsReceipt && data?.receiptStatus !== 'Closed' && isBlp && (
                                                    <div className="browse-btn-wrapper">
                                                        <input
                                                            type="file"
                                                            id={'ad_file'}
                                                            title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                            name={'ad_file'}
                                                            accept={
                                                                '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                            }
                                                            className="browse-input"
                                                            onChange={(e) =>
                                                                upload('QC_CERTIFICATE', e.target.files as any[0])
                                                            }
                                                        ></input>
                                                        <button
                                                            className="app-btn app-btn-secondary icon-button browse-btn"
                                                            title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                        >
                                                            <svg className="svg-icon plus-icon">
                                                                <use xlinkHref="#plusIcon">
                                                                    <title>Upload File</title>
                                                                </use>
                                                            </svg>
                                                        </button>
                                                    </div>
                                                )}
                                            </ShowForPermissions>
                                        </>
                                    </div>
                                )}
                            </div>
                            <div className="card-detail-col">
                                <div className="attachment-items-holder">
                                    {data?.qcCertificate?.length !== 0 ? (
                                        data?.qcCertificate
                                            ?.slice(0, showMore.qcCertificate ? data?.qcCertificate?.length : 3)
                                            .map((res: any) => (
                                                <div className="content-with-icon  attachment-holder">
                                                    <div
                                                        className="attachment-icon-holder"
                                                        onClick={() => downloadFileSingle(res?.fileId, res?.fileName)}
                                                    >
                                                        <button type="button" className="app-btn " title="Attach file">
                                                            <svg className="svg-icon file-attachment-icon ">
                                                                <use xlinkHref="#fileAttachmentIcon"></use>
                                                                <title>Attach file</title>
                                                            </svg>
                                                        </button>
                                                        <div className="filename-folder">
                                                            <a
                                                                href="javascript:void(0)"
                                                                className="description-type-1 file-uploaded"
                                                                title="Download"
                                                            >
                                                                {res?.fileName}
                                                            </a>
                                                            <div className="heading-type-1">{res?.fileSize}kb</div>
                                                        </div>
                                                    </div>
                                                    <ShowForPermissions permission="create" type="receipt">
                                                        {!blpIntegrationsReceipt &&
                                                            data?.receiptStatus !== 'Closed' &&
                                                            isBlp && (
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    className="file-remove-link"
                                                                    title="Remove"
                                                                    onClick={() => removeFile(res?.fileId)}
                                                                >
                                                                    Remove
                                                                </a>
                                                            )}
                                                    </ShowForPermissions>
                                                </div>
                                            ))
                                    ) : (
                                        <div className=" modal-input-holder upload-file-btn">
                                            <div className="modal-input-layout-item">
                                                {!blpIntegrationsReceipt && data?.receiptStatus !== 'Closed' && isBlp && (
                                                    <ShowForPermissions permission="create" type="receipt">
                                                        <>
                                                            <div className="browse-btn-wrapper">
                                                                <input
                                                                    type="file"
                                                                    id={'ad_file'}
                                                                    name={'ad_file'}
                                                                    accept={
                                                                        '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                    }
                                                                    className="browse-input"
                                                                    onChange={(e) =>
                                                                        upload(
                                                                            'QC_CERTIFICATE',
                                                                            e.target.files as any[0]
                                                                        )
                                                                    }
                                                                ></input>
                                                                <button
                                                                    className="app-btn app-btn-secondary icon-button browse-btn"
                                                                    title="Upload .doc, .docx, .mpp, .mppx, .pdf, .ppsx, .ppt, .pptx, .vsd,.vsdx, .xls, .xlsx file less than 25mb"
                                                                >
                                                                    <svg className="svg-icon plus-icon">
                                                                        <use xlinkHref="#plusIcon">
                                                                            <title>Upload File</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="button-text upload-btn">
                                                                        Upload File
                                                                    </span>
                                                                </button>

                                                                {/* <div className="error-text">mk</div> */}
                                                            </div>
                                                            <div className="heading-type-1 file-size">
                                                                <p style={{ textAlign: 'center' }}>
                                                                    {fileUploadInfoText}
                                                                </p>
                                                                <p
                                                                    style={{ textAlign: 'center' }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: fileTypesInfoText,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                        </>
                                                    </ShowForPermissions>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                    {data?.qcCertificate?.length > 3 && !showMore.qcCertificate && (
                                        <div className="content-with-icon view-more">
                                            <a
                                                href="javascript:void(0)"
                                                className="file-remove-link"
                                                title="More"
                                                onClick={() => setshowMore({ ...showMore, qcCertificate: true })}
                                            >
                                                +{data?.qcCertificate?.length - 3} more
                                            </a>
                                        </div>
                                    )}
                                    {showMore.qcCertificate && (
                                        <div className="content-with-icon view-more">
                                            <a
                                                href="javascript:void(0)"
                                                className="file-remove-link"
                                                title="Show Less"
                                                onClick={() => setshowMore({ ...showMore, qcCertificate: false })}
                                            >
                                                Show less
                                            </a>
                                        </div>
                                    )}
                                    {((checkPermission('create', 'receipt') !== undefined &&
                                        checkPermission('create', 'receipt')) ||
                                        !isBlp ||
                                        blpIntegrationsReceipt) &&
                                    data?.qcCertificate?.length === 0 ? (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No files attached</p>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};
export default Index;
