import React from 'react';
import Favourites from '../../Insights/Favourites';
import './insightFavouritesTab.scss';
import GraphView from './GraphView';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

const Index: React.FC = () => {
    const { favourites } = useSelector((state: RootState) => state.favourites);
    return (
        <div className="insight-favourites-tab">
            <div className="favourites-list-holder">
                <Favourites />
            </div>
            <div className="insight-favourites-graph">{favourites?.length ? <GraphView /> : null}</div>
        </div>
    );
};
export default Index;
