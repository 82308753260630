/**
	@desc applyMiddleware is used for applying some middleware to redux, in this case we gonna using redux-saga
*/

// This is the middleware that we gonna use redux-saga
import createSagaMiddleware from 'redux-saga';

// This is the root saga that will contain our sagas, or i should say model? XD
import rootSaga from './sagas';

// This will be contain our reducer for the application
import rootReducer from './reducers';
import logger from 'redux-logger';
import { configureStore } from '@reduxjs/toolkit';

const sagaMiddleware = createSagaMiddleware();

const middleware = process.env.NODE_ENV !== 'production' ? [sagaMiddleware, logger] : [sagaMiddleware];

const store = configureStore({
    reducer: rootReducer,
    middleware: middleware,
    devTools: process.env.NODE_ENV !== 'production' ? true : false,
});

export type AppDispatch = typeof store.dispatch;

// Run redux-saga
sagaMiddleware.run(rootSaga);

export default store;
