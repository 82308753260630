/* eslint-disable no-undefined */
import { useFormik } from 'formik';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { InputActionMeta } from 'react-select';
import { Button } from 'semantic-ui-react';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { RootState } from 'src/store/reducers';
import {
    fetchClassbDropdownList,
    fetchSsrsClassbReport,
    resetSsrsClassbReport,
    sendClassbFileToBlob,
    resetSendClassbFileToBlob,
    sendClassBEmailFile,
    resetSendClassBEmailFile,
} from 'src/store/reducers/classbReducer';
import { Position, error, success } from 'src/utils/toast';
import * as Yup from 'yup';

interface ClassbParams {
    vessel: string;
    voyage: string;
    portOfLoading: string;
    portOfDischarge: string;
}

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const { options, ssrsReport, sendClassBResult, sendEmailResult } = useSelector(
        (state: RootState) => state.classBData
    );
    const [vesselList, setVesselList] = useState([] as any);
    const [voyageList, setVoyageList] = useState([] as any);
    const [portOfLoadingList, setPortOfLoadingList] = useState([] as any);
    const [portOfDischargeList, setPortOfDischargeList] = useState([] as any);
    const [isDownloadProcessing, setIsDownloadProcessing] = useState(false);
    const [isFileTransitProcessing, setIsFileTransitProcessing] = useState(false);
    const [isEmailSendingProcessing, setIsEmailSendingProcessing] = useState(false);

    const initialValues: ClassbParams = {
        vessel: '',
        voyage: '',
        portOfLoading: '',
        portOfDischarge: '',
    };

    const ClassBFormSchema = Yup.object().shape({
        vessel: Yup.string().required('Vessel is required'),
        voyage: Yup.string().required('Voyage Code is required'),
        portOfLoading: Yup.string().required('Port of loading is required'),
        portOfDischarge: Yup.string().required('Port of discharge is required'),
    });

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ClassBFormSchema,
        onSubmit: () => {},
    });

    useEffect(() => {
        dispatch(fetchClassbDropdownList({}));
    }, []);

    function uniqueFilter(value: any, index: any, self: any) {
        return self.indexOf(value) === index;
    }

    useEffect(() => {
        if (options?.length > 0) {
            var vesselArray = options.map((o: any) => o.FS_VesselName);
            const vesselList = vesselArray.filter(uniqueFilter);
            const vesselSelections = vesselList.map((v: any) => {
                return { label: v, value: v };
            });

            setVesselList(vesselSelections);
        }
    }, [options]);

    useEffect(() => {
        if (formik.values.vessel) {
            const vessel = formik.values.vessel;
            var voyageArray = options
                .filter((o: any) => o.FS_VesselName === vessel)
                .map((o: any) => o.FS_VoyageFlightNo);
            const voyageList = voyageArray.filter(uniqueFilter);
            const voyageSelections = voyageList.map((v: any) => {
                return { label: v, value: v };
            });

            setVoyageList(voyageSelections);
        } else {
            formik.setFieldValue('portOfLoading', '');
            formik.setFieldValue('portOfDischarge', '');
            formik.setFieldValue('voyage', '');
        }
    }, [formik.values.vessel]);

    useEffect(() => {
        if (formik.values.voyage) {
            const vessel = formik.values.vessel;
            const voyage = formik.values.voyage;
            var portOfLoadingArray = options
                .filter((o: any) => o.FS_VesselName === vessel && o.FS_VoyageFlightNo === voyage)
                .map((o: any) => o.FS_PortOfLoading);
            const portOfLoadingList = portOfLoadingArray.filter(uniqueFilter);
            const portOfLoadingSelections = portOfLoadingList.map((v: any) => {
                return { label: v, value: v };
            });

            setPortOfLoadingList(portOfLoadingSelections);
        } else {
            formik.setFieldValue('portOfLoading', '');
            formik.setFieldValue('portOfDischarge', '');
        }
    }, [formik.values.voyage]);

    useEffect(() => {
        if (formik.values.portOfLoading) {
            const vessel = formik.values.vessel;
            const voyage = formik.values.voyage;
            const portOfLoading = formik.values.portOfLoading;
            var portOfDischargeArray = options
                .filter(
                    (o: any) =>
                        o.FS_VesselName === vessel &&
                        o.FS_VoyageFlightNo === voyage &&
                        o.FS_PortOfLoading === portOfLoading
                )
                .map((o: any) => o.FS_PortOfDestination);
            const portOfDischargeList = portOfDischargeArray.filter(uniqueFilter);
            const portOfDischargeSelections = portOfDischargeList.map((v: any) => {
                return { label: v, value: v };
            });

            setPortOfDischargeList(portOfDischargeSelections);
        } else {
            formik.setFieldValue('portOfDischarge', '');
        }
    }, [formik.values.portOfLoading]);

    useEffect(() => {
        if (ssrsReport !== 'NA' && !ssrsReport.vessel) {
            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${ssrsReport}`;
            const downloadLink = document.createElement('a');
            const fileName = `JUSTGROUP_CLASS_B_MASTER.xlsx`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            dispatch(resetSsrsClassbReport('NA'));
            setIsDownloadProcessing(false);
        }
    }, [ssrsReport]);

    useEffect(() => {
        if (sendClassBResult === 200) {
            success('Class B File Sent Successfully', Position.TOP_RIGHT);
            resetSendClassbFileToBlob({});
            setIsFileTransitProcessing(false);
        } else if (sendClassBResult === 500) {
            error('Oops... Something went wrong, please try again later or contact system admin', Position.TOP_RIGHT);
            resetSendClassbFileToBlob({});
            setIsFileTransitProcessing(false);
        }
    }, [sendClassBResult]);

    useEffect(() => {
        if (sendEmailResult === 200) {
            success('Class B Email Sent Successfully', Position.TOP_RIGHT);
            resetSendClassBEmailFile({});
            setIsEmailSendingProcessing(false);
        } else if (sendEmailResult === 500) {
            error('Oops... Something went wrong, please try again later or contact system admin', Position.TOP_RIGHT);
            resetSendClassBEmailFile({});
            setIsEmailSendingProcessing(false);
        }
    }, [sendEmailResult]);

    const downloadReport = () => {
        setIsDownloadProcessing(true);
        dispatch(
            fetchSsrsClassbReport({
                vessel: formik.values.vessel,
                voyage: formik.values.voyage,
                portOfLoading: formik.values.portOfLoading,
                portOfDischarge: formik.values.portOfDischarge,
            })
        );
    };

    const transmitFile = () => {
        setIsFileTransitProcessing(true);
        dispatch(
            sendClassbFileToBlob({
                vessel: formik.values.vessel,
                voyage: formik.values.voyage,
                portOfLoading: formik.values.portOfLoading,
                portOfDischarge: formik.values.portOfDischarge,
            })
        );
    };

    const sendEmail = () => {
        setIsEmailSendingProcessing(true);
        dispatch(
            sendClassBEmailFile({
                vessel: formik.values.vessel,
                voyage: formik.values.voyage,
                portOfLoading: formik.values.portOfLoading,
                portOfDischarge: formik.values.portOfDischarge,
            })
        );
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <br />
                <h2 className="page-heading">Class B Report Management</h2>
                <div className="purchase-order-header-fields">
                    <div className="field-entries-added">
                        <div className="entry-fields-holder">
                            <form id="my-form" onSubmit={formik.handleSubmit} className="modal-form form-fields">
                                <div className="modal-input-holder layout-item">
                                    <label className="modal-input-label">Vessel</label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={vesselList?.length ? vesselList : []}
                                            placeholder="Enter vessel name to select"
                                            formik={formik}
                                            fieldName="vessel"
                                            // extraField="clientName"
                                            isClearable={true}
                                            // onInputChangeFn={(value: string, actionMeta: InputActionMeta) => {
                                            //     if (value?.length > 2) {
                                            //         const found = vesselList.filter((res: any) =>
                                            //             res.label?.toLowerCase().includes(value?.toLowerCase())
                                            //         );
                                            //         setVesselList(found);
                                            //     } else if (
                                            //         (actionMeta.action === 'menu-close' &&
                                            //             actionMeta.prevInputValue !== '') ||
                                            //         (actionMeta.action === 'input-change' && value === '')
                                            //     ) {
                                            //         if (options?.length > 0) {
                                            //             var vesselArray = options.map((o: any) => o.FS_VesselName);
                                            //             const vesselList = vesselArray.filter(uniqueFilter);
                                            //             const vesselSelections = vesselList.map((v: any) => {
                                            //                 return { label: v, value: v };
                                            //             });

                                            //             setVesselList(vesselSelections);
                                            //         }
                                            //     }
                                            // }}
                                            // onClear={() => dispatch(fetchClient({ userName, fromAddPo: true }))}
                                        />
                                    </div>
                                </div>
                                <div className="modal-input-holder layout-item">
                                    <label className="modal-input-label">Voyage</label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={voyageList?.length ? voyageList : []}
                                            isDisabled={formik.values.vessel ? false : true}
                                            placeholder="Enter voyage name to select"
                                            formik={formik}
                                            fieldName="voyage"
                                            // extraField="clientName"
                                            isClearable={true}
                                            // onClear={() => dispatch(fetchClient({ userName, fromAddPo: true }))}
                                        />
                                    </div>
                                </div>
                                <div className=" modal-input-holder  layout-item">
                                    <label className="modal-input-label">Port of Loading</label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={portOfLoadingList?.length ? portOfLoadingList : []}
                                            isDisabled={formik.values.voyage ? false : true}
                                            placeholder="Enter port of loading code to select"
                                            formik={formik}
                                            fieldName="portOfLoading"
                                            // extraField="clientName"
                                            isClearable={true}
                                            // onClear={() => dispatch(fetchClient({ userName, fromAddPo: true }))}
                                        />
                                    </div>
                                </div>
                                <div className=" modal-input-holder  layout-item">
                                    <label className="modal-input-label">Port of Discharge</label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            data={portOfDischargeList?.length ? portOfDischargeList : []}
                                            placeholder="Enter port of discharge code to select"
                                            formik={formik}
                                            isDisabled={formik.values.portOfLoading ? false : true}
                                            fieldName="portOfDischarge"
                                            // extraField="clientName"
                                            isClearable={true}

                                            // onClear={() => dispatch(fetchClient({ userName, fromAddPo: true }))}
                                        />
                                    </div>
                                </div>
                                <div className="form-submit-btn">
                                    {isDownloadProcessing ? (
                                        <Button
                                            color="red"
                                            loading
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                        />
                                    ) : (
                                        <button
                                            disabled={
                                                formik.values.vessel &&
                                                formik.values.voyage &&
                                                formik.values.portOfDischarge &&
                                                formik.values.portOfLoading
                                                    ? false
                                                    : true
                                            }
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                downloadReport();
                                            }}
                                        >
                                            <span className="button-text footer-button-text">Download</span>
                                        </button>
                                    )}
                                </div>
                                <div className="form-submit-btn">
                                    {isEmailSendingProcessing ? (
                                        <Button
                                            color="red"
                                            loading
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                        />
                                    ) : (
                                        <button
                                            disabled={
                                                formik.values.vessel &&
                                                formik.values.voyage &&
                                                formik.values.portOfDischarge &&
                                                formik.values.portOfLoading
                                                    ? false
                                                    : true
                                            }
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                sendEmail();
                                            }}
                                        >
                                            <span className="button-text footer-button-text">Send Email</span>
                                        </button>
                                    )}
                                </div>
                                <div className="form-submit-btn">
                                    {isFileTransitProcessing ? (
                                        <Button
                                            color="red"
                                            loading
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                        />
                                    ) : (
                                        <button
                                            disabled={
                                                formik.values.vessel &&
                                                formik.values.voyage &&
                                                formik.values.portOfDischarge &&
                                                formik.values.portOfLoading
                                                    ? false
                                                    : true
                                            }
                                            className="app-btn app-btn-primary modal-btn form-btn"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                transmitFile();
                                            }}
                                        >
                                            <span className="button-text footer-button-text">Transmit File</span>
                                        </button>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
