/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface BpContainerDetail {
    containerType: string;
    measurement: string;
    deliveryMode: string;
    containerMode: string;
    containerNumber: string;
    description: string;
    code: string;
    packing: string[];
    vgm: string;
    sealNumber: string;
    eir: string;
    collectionDate: string;
    dropOffDate: string;
    pickUpDate: string;
    gateInDate: string;
}

export const defaultBpContainerDetail = {
    containerType: '',
    measurement: '',
    deliveryMode: '',
    containerMode: '',
    containerNumber: '',
    packing: [],
    vgm: '',
    eir: '',
    description: '',
    code: '',
    sealNumber: '',
    collectionDate: '',
    dropOffDate: '',
    pickUpDate: '',
    gateInDate: '',
};

export interface BpContainersList {
    description: string;
    code: string;
    containerType: string;
    containerDetailsId: string;
    containerNumber: string;
    cdId: string;
    status: string;
}

export interface PackingList {
    packingId: string;
    packingCode: string;
    packingName: string;
}

interface PmPackets {
    pp_quantity: string;
    pp_conversionquantity: number;
    pp_length: number;
    pp_width: number;
    pp_height: number;
    pp_weight: number;
    pp_barcode: string;
    pp_weightuom: string;
    pp_dimensionuom: string;
    pp_cbm: number;
    pp_PK: string;
}

interface Vendors {
    vn_vendorcode: string;
    vn_vendorname: string;
    vn_vendorcountry: string;
    vn_status: string;
    vn_PK: string;
}

export interface SkuDataDetails {
    pm_sku: string;
    pm_description: string;
    pm_purchasecode: string;
    pm_totalweight: string;
    pm_weightuom: string;
    pm_uom: string[];
    pm_status: string;
    pm_fumigationrequired: boolean;
    pm_vendors: Vendors[];
    pm_packets: PmPackets[];
    isEachPresent: boolean;
    pm_PK: string;
}

export interface BookingProposalSubPOLineList {
    bsId: string;
    sku: string;
    skuId: string;
    hsCode: string;
    description: string;
    cbm: string;
    qty: string;
    weight: string;
    goodsReadyDate: string;
}

export interface BookingProposalPoList {
    boId: string;
    poId: string;
    poNumber: string;
    vendorCode: string;
    vendorName: string;
    clientCode: string;
    bookingProposalSubPOLineList: BookingProposalSubPOLineList[];
}

export interface AllPoList {
    bookingProposalPoList: BookingProposalPoList[];
}

export interface AssignedPoList {
    bookingProposalPoList: BookingProposalPoList[];
    gateInDateExpired: boolean;
}
const defaultAssignedPoList = {
    bookingProposalPoList: [
        {
            boId: '',
            poId: '',
            poNumber: '',
            vendorCode: '',
            vendorName: '',
            clientCode: '',
            bookingProposalSubPOLineList: [
                {
                    bsId: '',
                    sku: '',
                    skuId: '',
                    hsCode: '',
                    description: '',
                    cbm: '',
                    qty: '',
                    weight: '',
                    goodsReadyDate: '',
                },
                {
                    bsId: '',
                    sku: '',
                    skuId: '',
                    hsCode: '',
                    description: '',
                    cbm: '',
                    qty: '',
                    weight: '',
                    goodsReadyDate: '',
                },
            ],
        },
    ],
    gateInDateExpired: false,
};

const defaultAllPoList = {
    bookingProposalPoList: [
        {
            boId: '',
            poId: '',
            poNumber: '',
            vendorCode: '',
            vendorName: '',
            clientCode: '',
            bookingProposalSubPOLineList: [
                {
                    bsId: '',
                    sku: '',
                    skuId: '',
                    hsCode: '',
                    description: '',
                    cbm: '',
                    qty: '',
                    weight: '',
                    goodsReadyDate: '',
                },
                {
                    bsId: '',
                    sku: '',
                    skuId: '',
                    hsCode: '',
                    description: '',
                    cbm: '',
                    qty: '',
                    weight: '',
                    goodsReadyDate: '',
                },
            ],
        },
    ],
};

interface ContainerAllocationState {
    isLoading: boolean;
    containers: BpContainersList[];
    containerDetail: BpContainerDetail;
    containerAllocationSuccess: any;
    containerAllocationError: any;
    isError?: boolean;
    containerType: { containerTypeId: string; containerType: string }[];
    deliveryMode: { deliveryModeId: string; deliveryMode: string }[];
    containerMode: { containerModeId: string; containerMode: string }[];
    packing: PackingList[];
    assignedPo: AssignedPoList;
    allPo: AllPoList;
    sku: SkuDataDetails[];
    poDetails: any;
}

const initialState: ContainerAllocationState = {
    isLoading: true,
    containers: [],
    containerType: [],
    deliveryMode: [],
    containerMode: [],
    packing: [],
    assignedPo: defaultAssignedPoList,
    allPo: defaultAllPoList,
    sku: [],
    poDetails: {},
    containerDetail: defaultBpContainerDetail,
    containerAllocationSuccess: undefined,
    containerAllocationError: undefined,
    isError: false,
};

export const containerAllocationSlice = createSlice({
    name: 'containerAllocationao',
    initialState,
    reducers: {
        fetchContainers: (state, _action) => {
            state.isLoading = true;
        },
        fetchContainersSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containers = action.payload?.data?.info;
        },
        fetchContainersFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchContainerDetail: (state, _action) => {
            state.isLoading = true;
        },
        fetchContainerDetailSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerDetail = action.payload?.data?.info;
        },
        fetchContainerDetailFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchContainerOnlyBasicDetail: (state, _action) => {
            state.isLoading = true;
        },
        fetchContainerOnlyBasicDetailSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerDetail = { ...defaultBpContainerDetail, ...action.payload?.data?.info };
        },
        fetchContainerOnlyBasicDetailFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        updateBasicDetail(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateBasicDetailSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerAllocationSuccess = action.payload;
            state.containerAllocationError = {};
        },
        updateBasicDetailFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationSuccess = {};
            state.containerAllocationError = action.payload;
        },
        updateDate(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateDateSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerAllocationSuccess = action.payload;
            state.containerAllocationError = {};
        },
        updateDateFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationSuccess = {};
            state.containerAllocationError = action.payload;
        },
        fetchContainerType: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchContainerTypeSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.containerType = action.payload?.data?.info;
        },
        fetchContainerTypeFailed(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchDeliveryMode: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchDeliveryModeSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.deliveryMode = action.payload?.data?.info;
        },
        fetchDeliveryModeFailed(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchContainerMode: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchContainerModeSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.containerMode = action.payload?.data?.info;
        },
        fetchContainerModeFailed(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchPacking: (_state, _action) => {
            // state.isLoading = true;
        },
        fetchPackingSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.packing = action.payload?.data?.info;
        },
        fetchPackingFailed(state, action) {
            // state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchAssignedPo: (state, _action) => {
            state.isLoading = true;
        },
        fetchAssignedPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.assignedPo = action.payload?.data?.info;
        },
        fetchAssignedPoFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchAllPo: (state, _action) => {
            state.isLoading = true;
        },
        fetchAllPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.allPo = action.payload?.data?.info;
        },
        fetchAllPoFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchPoDetails: (state, _action) => {
            state.isLoading = true;
        },
        fetchPoDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.poDetails = action.payload?.data?.info;
        },
        fetchPoDetailsFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        fetchSkuDetails: (state, _action) => {
            state.isLoading = true;
        },
        fetchSkuDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.sku = action.payload?.data?.info;
        },
        fetchSkuDetailsFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationError = action.payload;
        },
        addToContainer(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        addToContainerSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerAllocationSuccess = action.payload;
            state.containerAllocationError = {};
        },
        addToContainerFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationSuccess = {};
            state.containerAllocationError = action.payload;
        },
        removeFromContainer(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        removeFromContainerSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.containerAllocationSuccess = action.payload;
            state.containerAllocationError = {};
        },
        removeFromContainerFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.containerAllocationSuccess = {};
            state.containerAllocationError = action.payload;
        },
        clearFailure: (state, _action) => {
            state.containerAllocationError = undefined;
            state.containerAllocationSuccess = undefined;
            state.isError = false;
        },
        resetFields: (state, _action) => {
            state.assignedPo = { ...defaultAssignedPoList };
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const {
    fetchContainers,
    fetchContainersSuccess,
    fetchContainersFailed,
    fetchContainerDetail,
    fetchContainerDetailSuccess,
    fetchContainerDetailFailed,
    fetchContainerOnlyBasicDetail,
    fetchContainerOnlyBasicDetailSuccess,
    fetchContainerOnlyBasicDetailFailed,
    updateBasicDetail,
    updateBasicDetailSuccess,
    updateBasicDetailFailure,
    updateDate,
    updateDateSuccess,
    updateDateFailure,
    fetchContainerType,
    fetchContainerTypeSuccess,
    fetchContainerTypeFailed,
    fetchDeliveryMode,
    fetchDeliveryModeSuccess,
    fetchDeliveryModeFailed,
    fetchContainerMode,
    fetchContainerModeSuccess,
    fetchContainerModeFailed,
    fetchPacking,
    fetchPackingSuccess,
    fetchPackingFailed,
    fetchAssignedPo,
    fetchAssignedPoSuccess,
    fetchAssignedPoFailed,
    fetchAllPo,
    fetchAllPoSuccess,
    fetchAllPoFailed,
    fetchPoDetails,
    fetchPoDetailsSuccess,
    fetchPoDetailsFailed,
    fetchSkuDetails,
    fetchSkuDetailsSuccess,
    fetchSkuDetailsFailed,
    addToContainer,
    addToContainerSuccess,
    addToContainerFailed,
    removeFromContainer,
    removeFromContainerSuccess,
    removeFromContainerFailed,
    clearFailure,
    resetFields,
    isLoadingFn,
} = containerAllocationSlice.actions;

export const selectContainerAllocationLoading = (state: RootState) => state.containerAllocationData.isLoading;
export const selectContainers = (state: RootState) => state.containerAllocationData.containers;
export const selectContainerType = (state: RootState) => state.containerAllocationData.containerType;
export const selectDeliveryMode = (state: RootState) => state.containerAllocationData.deliveryMode;
export const selectContainerMode = (state: RootState) => state.containerAllocationData.containerMode;
export const selectPacking = (state: RootState) => state.containerAllocationData.packing;
export const selectAssignedPo = (state: RootState) => state.containerAllocationData.assignedPo;
export const selectAllPo = (state: RootState) => state.containerAllocationData.allPo;
export const selectPoDetails = (state: RootState) => state.containerAllocationData.poDetails;
export const selectSkuDetails = (state: RootState) => state.containerAllocationData.sku;
export const selectContainerDetail = (state: RootState) => state.containerAllocationData.containerDetail;
export const selectContainerAllocationSuccess = (state: RootState) =>
    state.containerAllocationData.containerAllocationSuccess;
export const selectContainerAllocationError = (state: RootState) =>
    state.containerAllocationData.containerAllocationError;
export const selectContainerAllocationIsError = (state: RootState) => state.containerAllocationData.isError;

export default containerAllocationSlice.reducer;
