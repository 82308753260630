export const poStatusList = (status: string, setShowViewReceivedQtyModal: any, setCurrentPoLine: any, val: any) => {
    switch (status) {
        case 'Ready to Receive':
        case 'Ready To Receive':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-progress">Ready to Receive</div>
                </div>
            );
        case 'Received in Full':
            return (
                <>
                    {(() => {
                        if (val?.markAsReceivedFull) {
                            return (
                                <div className="allocation-status">
                                    <div className="allocated-status completed">
                                        Received in Full
                                        <div
                                            className="tooltip-container status-tooltip-container-received-short top-right-tooltip"
                                            tabIndex={0}
                                        >
                                            <a href="javascript:void(0)">
                                                <svg className="svg-icon error-icon">
                                                    <use xlinkHref="#errorIcon">
                                                        <title>Warning</title>
                                                    </use>
                                                </svg>
                                            </a>
                                            <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                                                <div className="tooltip-subheading">
                                                    {/* On Hold text also used for complete class */}
                                                    Short Receipt
                                                    <a
                                                        onClick={() => {
                                                            setCurrentPoLine(val);
                                                            setShowViewReceivedQtyModal(true);
                                                        }}
                                                        className="tooltip-link"
                                                    >
                                                        View Reason
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else if (val?.onHoldQty > 0 && val?.onHoldQty != null) {
                            return (
                                <div className="allocation-status">
                                    <div className="allocated-status completed">
                                        Received in Full
                                        <div
                                            className="tooltip-container status-tooltip-container-received top-right-tooltip"
                                            tabIndex={0}
                                        >
                                            <a href="javascript:void(0)">
                                                <svg className="svg-icon error-icon">
                                                    <use xlinkHref="#errorIcon">
                                                        <title>Warning</title>
                                                    </use>
                                                </svg>
                                            </a>
                                            <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                                                <div className="tooltip-subheading">
                                                    {/* On Hold text also used for complete class */}
                                                    On Hold Qty
                                                    <a
                                                        onClick={() => {
                                                            setCurrentPoLine(val);
                                                            setShowViewReceivedQtyModal(true);
                                                        }}
                                                        className="tooltip-link"
                                                    >
                                                        View Reason
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        } else {
                            return (
                                <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                                    <div className="allocation-status">
                                        <div className="allocated-status completed">Received in Full</div>
                                    </div>
                                </div>
                            );
                        }
                    })()}
                </>
            );
        case 'Over Received':
            return (
                <div className="allocation-status">
                    <div className="allocated-status pending">
                        Over Received
                        <div className="tooltip-container status-tooltip-container top-right-tooltip" tabIndex={0}>
                            <a href="javascript:void(0)">
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </a>
                            <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                                <div className="tooltip-subheading">
                                    {/* On Hold text also used for complete class */}
                                    Over Received
                                    <a
                                        onClick={() => {
                                            setCurrentPoLine(val);
                                            setShowViewReceivedQtyModal(true);
                                        }}
                                        className="tooltip-link"
                                    >
                                        View Reason
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 'Short Receipt':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-hold">Short Receipt</div>
                </div>
            );
        case 'Cancelled':
            return (
                <div className="allocation-status">
                    <div className="allocated-status cancel">
                        Cancelled
                        <div
                            className="tooltip-container status-tooltip-container-cancelled top-right-tooltip"
                            tabIndex={0}
                        >
                            <a href="javascript:void(0)">
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </a>
                            <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                                <div className="tooltip-subheading">
                                    Cancelled
                                    <a
                                        onClick={() => {
                                            setCurrentPoLine(val);
                                            setShowViewReceivedQtyModal(true);
                                        }}
                                        className="tooltip-link"
                                    >
                                        View Reason
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
};
export const skuStatusList = (
    status: any,
    setVendorCode: any,
    setModalViewproduct: any,
    res: any,
    val: any,
    poStatus: any,
    setShowViewComplianceModal: any,
    setCurrentPoLine: any
) => {
    switch (status) {
        case 'pass':
            return (
                <div className="link-tooltip-holder">
                    <a
                        onClick={() => {
                            setVendorCode(res?.clientCode);
                            setModalViewproduct({
                                status: true,
                                value: res?.clientCode,
                                id: val?.skuId,
                            });
                        }}
                        className="booking-proposal-link read-all-link"
                    >
                        {poStatus && val?.sku}
                    </a>
                    <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                        <a href="javascript:void(0)">
                            <span className="grid-icon-holder">
                                <svg className="svg-icon success-badge-icon">
                                    <use xlinkHref="#successgridBadgeIcon"></use>
                                </svg>
                            </span>
                        </a>
                        <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                            <div className="tooltip-subheading">Compliance Check Passed</div>
                        </div>
                    </div>
                </div>
            );
        case 'failed':
            return (
                <div className="link-tooltip-holder">
                    <a
                        onClick={() => {
                            setVendorCode(res?.clientCode);
                            setModalViewproduct({
                                status: true,
                                value: res?.clientCode,
                                id: val?.skuId,
                            });
                        }}
                        className="booking-proposal-link read-all-link"
                    >
                        {poStatus && val?.sku}
                    </a>
                    <div className="tooltip-container sku-tooltip-container top-right-tooltip" tabIndex={0}>
                        <a href="javascript:void(0)">
                            <span className="grid-icon-holder">
                                <svg className="svg-icon error-close-icon">
                                    <use xlinkHref="#errorCloseIcon"></use>
                                </svg>
                            </span>
                        </a>
                        <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                            <div className="tooltip-subheading">
                                Compliance Check Failed
                                <a
                                    onClick={() => {
                                        const complianceData = res?.skuDetailsReceipts?.find(
                                            (elem: any) => elem.skuId === val?.skuId
                                        );

                                        setCurrentPoLine({
                                            ...complianceData,
                                            rsId: val?.rsId,
                                        });
                                        setShowViewComplianceModal(true);
                                    }}
                                    className="tooltip-link"
                                >
                                    View Reason
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        case 'noCompliance':
            return (
                <div className="link-tooltip-holder">
                    <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                        <a
                            onClick={() => {
                                setVendorCode(res?.clientCode);
                                setModalViewproduct({
                                    status: true,
                                    value: res?.clientCode,
                                    id: val?.skuId,
                                });
                            }}
                            className="booking-proposal-link read-all-link"
                        >
                            {poStatus && val?.sku}
                        </a>
                    </div>
                </div>
            );
        case 'pending':
            return (
                <div className="link-tooltip-holder">
                    <a
                        onClick={() => {
                            setVendorCode(res?.clientCode);
                            setModalViewproduct({
                                status: true,
                                value: res?.clientCode,
                                id: val?.skuId,
                            });
                        }}
                        className="booking-proposal-link read-all-link"
                    >
                        {poStatus && val?.sku}
                    </a>
                    <div className="tooltip-container top-right-tooltip inline-block" tabIndex={0}>
                        <a href="javascript:void(0)">
                            <span className="grid-icon-holder">
                                <svg className="svg-icon clock-icon">
                                    <use xlinkHref="#clockIcon"></use>
                                </svg>
                            </span>
                        </a>
                        <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                            <div className="tooltip-subheading">Compliance Check Pending</div>
                        </div>
                    </div>
                </div>
            );
        default:
            return (
                <div className="link-tooltip-holder">
                    <a
                        onClick={() => {
                            setVendorCode(res?.clientCode);
                            setModalViewproduct({
                                status: true,
                                value: res?.clientCode,
                                id: val?.skuId,
                            });
                        }}
                        className="booking-proposal-link read-all-link"
                    >
                        {poStatus && val?.sku}
                    </a>
                    <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                        <a href="javascript:void(0)">
                            <span className="grid-icon-holder">
                                <svg className="svg-icon clock-icon">
                                    <use xlinkHref="#clockIcon"></use>
                                </svg>
                            </span>
                        </a>
                        <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                            <div className="tooltip-subheading">Compliance Check Pending</div>
                        </div>
                    </div>
                </div>
            );
    }
};

export const poStatusPillList = (status: string) => {
    switch (status) {
        case 'Ready to Receive':
        case 'Ready To Receive':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-progress">Ready to Receive</div>
                </div>
            );
        case 'Received in Full':
            return (
                <div className="allocation-status">
                    <div className="allocated-status completed">Received in Full</div>
                </div>
            );
        case 'Over Received':
            return (
                <div className="allocation-status">
                    <div className="allocated-status pending">Over Received</div>
                </div>
            );
        case 'Short Receipt':
            return (
                <div className="allocation-status">
                    <div className="allocated-status in-hold">Short Receipt</div>
                </div>
            );
        case 'Cancelled':
            return (
                <div className="allocation-status">
                    <div className="allocated-status cancel">Cancelled</div>
                </div>
            );
    }
};
