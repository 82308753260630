import { addToFavourites, removeFromFavourites } from 'src/store/reducers/dashboard/insightsFavoritesReducer';
import {
    setPoFilter,
    setPoSelectedDropDown,
    setInboundPoSelectedDropDown,
    setInboundPoFilter,
} from 'src/store/reducers/purchaseOrder/poListReducer';
import { setFilter, setSelectedDropDown } from 'src/store/reducers/allocationOrder/allocationOrderListReducer';
import {
    setBookingList,
    setFilter as setPoBookingFilter,
    setSelectedDropDown as setPoBookingSelectedDropDown,
} from 'src/store/reducers/bookingProposal/bookingProposalListReducer';
import {
    setFilter as setAoBookingFilter,
    setSelectedDropDown as setAoBookingSelectedDropDown,
} from 'src/store/reducers/aobookingProposal/bookingProposalListReducer';
import {
    setFilter as setAirListFilter,
    setSelectedDropDown as setAirBookingSelectedDropDown,
} from 'src/store/reducers/bookingProposalAir/listReducer';
import { activeAos, activeInboundPos, allPos, bookingProposalStatusAll, BookingType } from 'src/utils/constants';
import { TypeOfBooking } from './enums';

export const favouriteTypeMapping = {
    'International POs': 'international',
    'Active Orders': 'total',
    'Inbound POs': 'inbound',
    'Allocation Orders': 'allocationOrders',
    'AO Bookings': 'ao',
    'PO Bookings': 'po',
    'Active Bookings': 'total',
} as any;

export const favouriteStatusMapping = {
    Total: 'total',
    Draft: 'draft',
    'Awaiting Enrichment': 'awaitingEnrichment',
    'Ready For Booking': 'readyForBooking',
    'Partially Booked': 'partiallyBooked',
    Booked: 'booked',
    pending: 'Draft',
    'Pending with Forwarder': 'pendingWithForwarder',
    'Inbound In Progress': 'inBoundInProgress',
    Submitted: 'submitted',
    'Awaiting Client Approval': 'awaitingClientApproval',
    'Awaiting Forwarder Approval': 'awaitingForwarderApproval',
    'Draft Booking': 'draftBooking',
    'Draft Booking Proposal': 'draftBookingProposal',
    'Rejected by Client: Agent to Action': 'rejectedByClientAgenttoAction',
    'Rejected by Client: Forwarder to Action': 'rejectedByClientForwardertoAction',
    'Rejected by Forwarder': 'rejectedByForwarder',
    Active: 'bpActiveStatus',
} as any;

export const typeOfFav = {
    activeOrder: 'po',
    activeBookings: 'bp',
};

const getKeyCombination = (entryArray: any) => {
    return entryArray.reduce((previousKeysString: string, current: any) => {
        const [filterKey, isFIltered] = current;
        if (isFIltered === true) {
            return previousKeysString + filterKey;
        } else {
            return previousKeysString;
        }
    }, '');
};

export const getDynamicFilterKey = (entry: any[], status: string | null, type: string, favouriteType: string) => {
    entry.sort((a: any, b: any) => a[0].localeCompare(b[0]));
    return (
        ((status ? 'typed' : '') + getKeyCombination(entry) || 'noFilter') +
        type +
        (type === 'bp' ? (favouriteType.startsWith('AO') ? TypeOfBooking.aoBooking : TypeOfBooking.poBooking) : '')
    );
};

export const addOrRemoveFromFavs = (
    dispatch: any,
    title: string,
    favourites: any,
    currentFilters: any,
    userName: string,
    type: string,
    status?: string
) => {
    const favAdded =
        type === typeOfFav.activeOrder
            ? favourites?.find(
                  (x: any) =>
                      x.favourite === title &&
                      x.isSea === currentFilters.isSea &&
                      x.isLand === currentFilters.isLand &&
                      x.isAir === currentFilters.isAir &&
                      x.isInternational === currentFilters.isInternational &&
                      x.isTriangle === currentFilters.isTriangle &&
                      x.type === type &&
                      (status ? x.status === status : true)
              )
            : favourites?.find(
                  (x: any) =>
                      x.favourite === title &&
                      x.isFcl === currentFilters.isFcl &&
                      x.isLcl === currentFilters.isLcl &&
                      x.isBcn === currentFilters.isBcn &&
                      x.isAir === currentFilters.isAir &&
                      x.isInternational === currentFilters.isInternational &&
                      x.isTriangle === currentFilters.isTriangle &&
                      x.type === type &&
                      (status ? x.status === status : true) &&
                      (currentFilters.isSea ? x.isSea === currentFilters.isSea : true)
              );
    if (favAdded) {
        dispatch(removeFromFavourites({ insightId: favAdded.id }));
    } else {
        dispatch(addToFavourites({ ...currentFilters, favourite: title, userName, type, status: status || null }));
    }
};

export const checkIfAddedToFavs = (
    title: string,
    favourites: any,
    currentFilters: any,
    type: string,
    status?: string
) => {
    const favAdded =
        type === typeOfFav.activeOrder
            ? favourites?.find(
                  (x: any) =>
                      x.favourite === title &&
                      x.isSea === currentFilters.isSea &&
                      x.isLand === currentFilters.isLand &&
                      x.isAir === currentFilters.isAir &&
                      x.isInternational === currentFilters.isInternational &&
                      x.isTriangle === currentFilters.isTriangle &&
                      x.type === type &&
                      (status ? x.status === status : true)
              )
            : favourites?.find(
                  (x: any) =>
                      x.favourite === title &&
                      x.isFcl === currentFilters.isFcl &&
                      x.isLcl === currentFilters.isLcl &&
                      x.isBcn === currentFilters.isBcn &&
                      x.isAir === currentFilters.isAir &&
                      x.isInternational === currentFilters.isInternational &&
                      x.isTriangle === currentFilters.isTriangle &&
                      x.type === type &&
                      (status ? x.status === status : true) &&
                      (typeof currentFilters.isSea === 'boolean' ? x.isSea === currentFilters.isSea : true)
              );
    if (favAdded) {
        return true;
    } else {
        return false;
    }
};

export const navigationObj = {
    'International POs': {
        navigationPath: '/purchase_order',
        setDropDown: setPoSelectedDropDown,
        setFilter: setPoFilter,
        filterName: 'poFilter',
        shippingModeFilterList: ['isSea', 'isAir'],
        destinationTypeFilterList: ['isInternational', 'isTriangle'],
        modeFilterKey: 'shippingmode',
        activeKey: allPos,
        activeStatusKey: 'Active',
        statusKey: 'poStatus',
    },
    'Inbound POs': {
        navigationPath: '/inbound',
        setDropDown: setInboundPoSelectedDropDown,
        setFilter: setInboundPoFilter,
        filterName: 'inboundPoFilter',
        shippingModeFilterList: ['isSea', 'isAir'],
        destinationTypeFilterList: ['isInternational', 'isTriangle'],
        modeFilterKey: 'shippingmode',
        activeKey: activeInboundPos,
        activeStatusKey: 'Active',
        statusKey: 'poStatus',
    },
    'Allocation Orders': {
        navigationPath: '/allocation_orders',
        setDropDown: setSelectedDropDown,
        setFilter: setFilter,
        filterName: 'aoFilter',
        shippingModeFilterList: ['isSea', 'isAir'],
        destinationTypeFilterList: ['isInternational', 'isTriangle'],
        modeFilterKey: 'shippingmode',
        activeKey: activeAos,
        activeStatusKey: 'Active',
        statusKey: 'aoStatus',
    },
    'PO Bookings': {
        navigationPath: '/booking_list',
        setDropDown: setPoBookingSelectedDropDown,
        setFilter: setPoBookingFilter,
        filterName: 'poBookingFilter',
        setBookingList,
        bookingType: BookingType.poBooking,
        Air: {
            setFilter: setAirListFilter,
            filterName: 'airPoBookingFilter',
            bookingType: BookingType.poBookingAir,
            setDropDown: setAirBookingSelectedDropDown,
        },
        shippingModeFilterList: ['isLcl', 'isFcl', 'isBcn'],
        destinationTypeFilterList: ['isInternational', 'isTriangle'],
        modeFilterKey: 'bpType',
        activeKey: bookingProposalStatusAll,
        activeStatusKey: 'Active',
        statusKey: 'bpStatus',
    },
    'AO Bookings': {
        navigationPath: '/booking_list',
        setDropDown: setAoBookingSelectedDropDown,
        setFilter: setAoBookingFilter,
        filterName: 'aoBookingFilter',
        setBookingList,
        bookingType: BookingType.aoBooking,
        shippingModeFilterList: [],
        destinationTypeFilterList: ['isInternational'],
        modeFilterKey: 'bpType',
        activeKey: bookingProposalStatusAll,
        activeStatusKey: 'Active',
        statusKey: 'bpStatus',
    },
} as any;

export const capitalizeFirstLetter = (s: string) => (s && s[0].toUpperCase() + s.slice(1)) || '';
