import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchContainers,
    fetchContainersSuccess,
    fetchContainersFailed,
    fetchContainerDetail,
    fetchContainerDetailSuccess,
    fetchContainerDetailFailed,
    fetchInitialContainerDetail,
    fetchInitialContainerDetailSuccess,
    fetchInitialContainerDetailFailed,
    fetchContainerOnlyBasicDetail,
    fetchContainerOnlyBasicDetailSuccess,
    fetchContainerOnlyBasicDetailFailed,
    updateBasicDetail,
    updateBasicDetailSuccess,
    updateBasicDetailFailure,
    updateDate,
    updateDateSuccess,
    updateDateFailure,
    fetchContainerType,
    fetchContainerTypeSuccess,
    fetchContainerTypeFailed,
    fetchDeliveryMode,
    fetchDeliveryModeSuccess,
    fetchDeliveryModeFailed,
    fetchContainerMode,
    fetchContainerModeSuccess,
    fetchContainerModeFailed,
    fetchPacking,
    fetchPackingSuccess,
    fetchPackingFailed,
    fetchAssignedPo,
    fetchAssignedPoSuccess,
    fetchAssignedPoFailed,
    fetchAllPo,
    fetchAllPoSuccess,
    fetchAllPoFailed,
    fetchPoDetails,
    fetchPoDetailsSuccess,
    fetchPoDetailsFailed,
    fetchSkuDetails,
    fetchSkuDetailsSuccess,
    fetchSkuDetailsFailed,
    addToContainer,
    addToContainerSuccess,
    addToContainerFailed,
    removeFromContainer,
    removeFromContainerSuccess,
    removeFromContainerFailed,
    removeContainer,
    fetchAttachedPurchaseOrders,
    saveContainerPacking,
    deleteContainerPacking,
} from '../../reducers/bookingProposal/containerAllocationReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchContainerDetailApi,
    fetchInitialContainerDetailApi,
    fetchContainersApi,
    fetchContainerOnlyBasicDetailApi,
    updateBasicDetailApi,
    updateDateAPi,
    fetchContainerTypeApi,
    fetchDeliveryModeApi,
    fetchContainerModeApi,
    fetchPackingApi,
    fetchAssignedPoApi,
    fetchAllPoApi,
    fetchPoDetailsApi,
    fetchSkuDetailsApi,
    addToContainerApi,
    removeFromContainerApi,
    deleteOneContainer,
    fetchAttachedPurchaseOrdersApi,
    postContainerPackingApi,
    removeContainerPackingApi,
} from '../../../services/apis/bookingProposal/containerAllocationApi';

function* getContainers({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContainersApi, payload);
        yield put({ type: fetchContainersSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContainersFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* removeOneContainer({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(deleteOneContainer, payload);
        yield put({ type: removeContainer, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* getAttachedPos({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchAttachedPurchaseOrdersApi, payload);
        yield put({ type: fetchAttachedPurchaseOrders, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}
function* getContainerDetail({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContainerDetailApi, payload);
        yield put({ type: fetchContainerDetailSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContainerDetailFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* getInitialContainerDetail({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(fetchInitialContainerDetailApi, payload);
        yield put({ type: fetchInitialContainerDetailSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchInitialContainerDetailFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* getContainerOnlyBasicDetail({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContainerOnlyBasicDetailApi, payload);
        yield put({ type: fetchContainerOnlyBasicDetailSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContainerOnlyBasicDetailFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* changeBasicDetail({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateBasicDetailApi, payload);
        yield put({ type: updateBasicDetailSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateBasicDetailFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* changeDate({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(updateDateAPi, payload);
        yield put({ type: updateDateSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateDateFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getContainerType({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContainerTypeApi, payload);
        yield put({ type: fetchContainerTypeSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContainerTypeFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getDeliveryMode({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchDeliveryModeApi, payload);
        yield put({ type: fetchDeliveryModeSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchDeliveryModeFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getContainerMode({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContainerModeApi, payload);
        yield put({ type: fetchContainerModeSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContainerModeFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getPacking({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchPackingApi, payload);
        yield put({ type: fetchPackingSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchPackingFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getAssignedPo({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchAssignedPoApi, payload);
        yield put({ type: fetchAssignedPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAssignedPoFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getAllPo({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchAllPoApi, payload);
        yield put({ type: fetchAllPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAllPoFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getPoDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchPoDetailsApi, payload);
        yield put({ type: fetchPoDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchPoDetailsFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* getSkuDetails({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchSkuDetailsApi, payload);
        yield put({ type: fetchSkuDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchSkuDetailsFailed, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* postContainerPacking({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(postContainerPackingApi, payload);
        yield put({ type: saveContainerPacking, payload: response.data });
    } catch (error) {
        yield put({ type: saveContainerPacking, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* changeToContainer({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(addToContainerApi, payload);
        yield put({ type: addToContainerSuccess, payload: response });
    } catch (error) {
        yield put({ type: addToContainerFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* deleteFromContainer({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(removeFromContainerApi, payload);
        yield put({ type: removeFromContainerSuccess, payload: response });
    } catch (error) {
        yield put({ type: removeFromContainerFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* removeContainerPacking({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(removeContainerPackingApi, payload);
        yield put({ type: deleteContainerPacking, payload: response.data });
    } catch (error) {
        yield put({ type: deleteContainerPacking, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* containerAllocationSaga() {
    yield takeEvery(fetchContainers, getContainers);
    yield takeEvery(fetchContainerDetail, getContainerDetail);
    yield takeEvery(fetchInitialContainerDetail, getInitialContainerDetail);
    yield takeEvery(fetchContainerOnlyBasicDetail, getContainerOnlyBasicDetail);
    yield takeEvery(updateBasicDetail, changeBasicDetail);
    yield takeEvery(updateDate, changeDate);
    yield takeEvery(fetchContainerType, getContainerType);
    yield takeEvery(fetchDeliveryMode, getDeliveryMode);
    yield takeEvery(fetchContainerMode, getContainerMode);
    yield takeEvery(fetchPacking, getPacking);
    yield takeEvery(fetchAssignedPo, getAssignedPo);
    yield takeEvery(fetchAllPo, getAllPo);
    yield takeEvery(fetchPoDetails, getPoDetails);
    yield takeEvery(fetchSkuDetails, getSkuDetails);
    yield takeEvery(addToContainer, changeToContainer);
    yield takeEvery(removeFromContainer, deleteFromContainer);
    yield takeEvery(removeContainer, removeOneContainer);
    yield takeEvery(fetchAttachedPurchaseOrders, getAttachedPos);
    yield takeEvery(saveContainerPacking, postContainerPacking);
    yield takeEvery(deleteContainerPacking, removeContainerPacking);
}

export default containerAllocationSaga;
