import React from 'react';
import './lifecycleBooking.scss';
import BookingTimeLine from './TimeLine/BookingTimeLine';
import BookingTimeLineMbl from './TimeLine/BookingTimeLineMbl';
import VesselTimeline from './TimeLine/vesselTimeline';
import WarehouseTimeline from './TimeLine/warehouseTimeline';
import PortTimeLine from './TimeLine/PortTimeLine';
import PoTimeLine from './TimeLine/PoTimeline';
import SkuTimeLine from './TimeLine/SkuTimeLine';
import BookingDetails from './LifeCycleDetails/BookingDetails';
import SkuDetails from './LifeCycleDetails/SkuDetails';
import PortDetails from './LifeCycleDetails/PortDetails';
import VesselDetails from './LifeCycleDetails/VesselDetails';
import WarehouseDetails from './LifeCycleDetails/WarehouseDetails';
import Carrier from './LifeCycleDetails/Carrier';
import PoDetails from './LifeCycleDetails/PoDetails';
// import UserDetails from './LifeCycleDetails/UsersDetails';
import ClientTimeLine from './TimeLine/ClientTimeLine';
import AgentTimeLine from './/TimeLine/AgentTimeLine';
import ClientDetails from './LifeCycleDetails/Client/LifeCycleDetails';
import AgentDetails from './LifeCycleDetails/Agent/LifeCycleDetails';
import CarrierTimeline from './TimeLine/carrierTimeline';
import VendorTimeline from './TimeLine/VendorTimeline';
import VendorDetails from './LifeCycleDetails/VendorDetails';

interface propsData {
    type?: string;
}

const Index: React.FC<propsData> = ({ type }) => {
    const TimeLineView = () => {
        switch (type) {
            case 'bookings':
                return <BookingTimeLine />;
            case 'master-bill':
                return <BookingTimeLineMbl />;
            case 'house-bill':
                return <BookingTimeLine />;
            case 'sku':
                return <SkuTimeLine />;
            case 'ports':
                return <PortTimeLine />;
            case 'warehouse':
                return <WarehouseTimeline />;
            case 'vessels':
                return <VesselTimeline />;
            case 'carriers':
                return <CarrierTimeline />;
            case 'purchase-orders':
                return <PoTimeLine />;
            case 'vendors':
                return <VendorTimeline />;
            case 'clients':
                return <ClientTimeLine />;
            case 'agents':
                return <AgentTimeLine />;
            default:
                break;
        }
    };
    const LifeCycleData = () => {
        switch (type) {
            case 'bookings':
                return <BookingDetails />;
            case 'master-bill':
                return <BookingDetails />;
            case 'house-bill':
                return <BookingDetails />;
            case 'sku':
                return <SkuDetails />;
            case 'ports':
                return <PortDetails />;
            case 'warehouse':
                return <WarehouseDetails />;
            case 'vessels':
                return <VesselDetails />;
            case 'carriers':
                return <Carrier />;
            case 'purchase-orders':
                return <PoDetails />;
            case 'vendors':
                return <VendorDetails />;
            case 'clients':
                return <ClientDetails />;
            case 'agents':
                return <AgentDetails />;
            default:
                break;
        }
    };
    return (
        <div className="Lifecycle-booking-wrapper">
            {TimeLineView()}
            {LifeCycleData()}
        </div>
    );
};
export default Index;
