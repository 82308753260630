import { FunctionComponent, useState, useRef, useEffect } from 'react';
// import { useDispatch } from 'react-redux';

// import { removeAttachment } from 'src/store/reducers/autoApprovalReducer';
import { downloadFile } from 'src/services/apis/autoApprovalApi';
import ConfirmFileRemoveModal from 'src/components/common/UnsaveModal';

const FILE_SIZE_LIMIT = 25 * 1024 * 1024;
export interface AuthorizationTypeOption {
    key: 'SOP' | 'CONTRACT' | 'EMAIL';
    name: string;
}
export interface Attachment {
    fileName: string | null;
    savedFileName: string | null;
    savedFileId: string | null;
    savedFileSizeInKb: number | null;
}
interface Props {
    attachment: Attachment;
    clientId: string | undefined;
    completeRemoveAttachment: () => void;
    dropDownOpen: boolean;
    fileMissingError?: string;
    handleSelection: (value: AuthorizationTypeOption['key'] | null) => void;
    hasEditPermission: boolean;
    hasValidationError: boolean;
    label: string;
    options: AuthorizationTypeOption[];
    placeholder: string;
    removedFileId: null | string;
    resetFileError: () => void;
    saveRemovedFileId: (value: null | string) => void;
    setDropDownOpen: (value: boolean) => void;
    selectedOption: AuthorizationTypeOption['key'] | null;
    fileRefUpdateCallback: (value: any) => void;
    tempFileAvailable: boolean;
}
const AuthorizationDropDown: FunctionComponent<Props> = ({
    attachment,
    // clientId,
    completeRemoveAttachment,
    dropDownOpen,
    fileMissingError,
    handleSelection,
    hasEditPermission,
    hasValidationError,
    label,
    options,
    placeholder,
    removedFileId,
    resetFileError,
    saveRemovedFileId,
    setDropDownOpen,
    selectedOption,
    fileRefUpdateCallback,
    tempFileAvailable,
}) => {
    // const dispatch = useDispatch();
    const [fileSizeExceedError, setFileExceedError] = useState(false);
    const [showAttachmentRemovalModal, setShowAttachmentRemovalModal] = useState(false);
    const [tempFileValues, setTempFileValues] = useState<any>({
        name: '',
        size: 0,
        file: null,
    });
    const dropDownRef = useRef<HTMLDivElement | null>(null);
    const onUploadAttachment = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFileExceedError(false);
        resetFileError();
        if (removedFileId) {
            completeRemoveAttachment();
        }
        const file: any = event.target.files && event.target.files[0];
        fileRefUpdateCallback(file);
        setTempFileValues({ ...tempFileValues, ['name']: file.name, ['size']: file.size, file: file });

        if (file?.size && file?.size > FILE_SIZE_LIMIT) {
            setFileExceedError(true);
            return;
        }
        const formData = new FormData();
        formData.append('file', file);
    };
    const onClickRemove = () => {
        setShowAttachmentRemovalModal(true);
    };

    const handleCloseRemovalModal = () => {
        setShowAttachmentRemovalModal(false);
    };

    useEffect(() => {
        if (!tempFileAvailable) {
            setTempFileValues({
                name: '',
                size: 0,
                file: null,
            });
        }
    }, [tempFileAvailable]);

    const onRemoveAttachment = () => {
        saveRemovedFileId(attachment.savedFileId);
        setShowAttachmentRemovalModal(false);
        setTempFileValues({
            name: '',
            size: 0,
            file: null,
        });
        fileRefUpdateCallback(null);
    };
    const handleFileDownload = () => {
        if (tempFileValues?.file) {
            if (tempFileValues) {
                console.log('hitting1234', tempFileValues.file.type);
                const fileObj = new Blob([tempFileValues.file, { type: tempFileValues.file?.type }]);
                const element = document.createElement('a');
                element.href = URL.createObjectURL(fileObj);
                if (tempFileValues.name) {
                    element.download = tempFileValues.name;
                    document.body.appendChild(element);
                    element.click();
                }
            }
        } else {
            attachment.fileName &&
                attachment.savedFileId &&
                downloadFile({
                    fileName: attachment.fileName,
                    fileId: attachment.savedFileId,
                });
        }
    };
    const dropDownHeight = dropDownRef?.current
        ? dropDownRef.current.getBoundingClientRect().bottom + 22 + 'px'
        : '200px';

    return (
        <>
            <div className="modal-input-holder">
                <label htmlFor="name" className="modal-input-label">
                    {label}
                    <span className="mandatory-field-text">*</span>
                </label>
                <div className="modal-input-layout-item">
                    <div
                        ref={dropDownRef}
                        className={`dropdown-wrap ${
                            dropDownOpen ? 'dropdown-open' : ''
                        } download-dropdown search-toast-content-holder`}
                    >
                        <button
                            className="app-btn app-btn-secondary menu-btn"
                            onClick={() => setDropDownOpen(!dropDownOpen)}
                            type="button"
                            disabled={!hasEditPermission}
                        >
                            <div className="purchase-dropdown-content">
                                <span className="btn-text">
                                    {''}
                                    {selectedOption
                                        ? options.find((option) => option.key === selectedOption)?.name
                                        : placeholder}
                                </span>
                                <span className="placeholder-text">Select Client Authorization</span>
                            </div>
                            <svg className="svg-icon arrow-icon">
                                <use href="#downArrow">
                                    <title>Expand row</title>
                                </use>
                            </svg>
                        </button>
                        <div className="dropdown-menu" style={{ maxHeight: `calc(100vh - ${dropDownHeight})` }}>
                            {options.map((option) => (
                                <a
                                    className={`menu-item app-btn  ${selectedOption === option.key ? 'active' : ''}`}
                                    onClick={() => {
                                        handleSelection(option.key);
                                        setDropDownOpen(false);
                                    }}
                                    key={option.key}
                                >
                                    <div className="content-heading-holder">
                                        <span className="content-heading">{option.name}</span>
                                    </div>
                                </a>
                            ))}
                        </div>
                    </div>
                    {hasValidationError && <div className="error-text">Mandatory Field</div>}
                </div>
            </div>
            {selectedOption === 'EMAIL' && (
                <div className=" modal-input-holder">
                    <label htmlFor="name" className="modal-input-label">
                        Attachments
                    </label>
                    <div className="modal-input-layout-item">
                        {hasEditPermission && !tempFileAvailable && (
                            <>
                                <div className="browse-btn-wrapper">
                                    <input
                                        type={'file'}
                                        id={'approveFile'}
                                        name={'approveFile'}
                                        accept={'.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'}
                                        className="browse-input"
                                        title="Upload .pdf ,.doc file less than 25MB"
                                        onChange={onUploadAttachment}
                                        onClick={(event) => ((event.target as HTMLInputElement).value = '')}
                                    ></input>
                                    <button
                                        className="app-btn app-btn-secondary icon-button browse-btn"
                                        title="Upload file"
                                        type="button"
                                    >
                                        <svg className="svg-icon plus-icon">
                                            <use xlinkHref="#plusIcon">
                                                <title>Upload File</title>
                                            </use>
                                        </svg>
                                        <span className="button-text upload-btn">Upload File</span>
                                    </button>

                                    {fileSizeExceedError && (
                                        <div className="error-text">File exceeds maximum size limit</div>
                                    )}
                                    {!!fileMissingError && <div className="error-text">{fileMissingError}</div>}
                                </div>

                                <div className="heading-type-1 file-size">
                                    <p>( Upload .pdf, .doc file less than 25 mb )</p>
                                </div>
                            </>
                        )}

                        {((hasEditPermission && tempFileAvailable) || (!hasEditPermission && attachment.fileName)) && (
                            <div
                                className={`content-with-icon  attachment-holder ${
                                    !hasEditPermission ? 'without-remove-icon' : ''
                                }`}
                            >
                                <button
                                    type="button"
                                    className="app-btn "
                                    title="Attach file"
                                    onClick={handleFileDownload}
                                >
                                    <svg className="svg-icon file-attachment-icon ">
                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                        <title>Attach file</title>
                                    </svg>
                                </button>
                                <div>
                                    {/* file-uploaded class to higlight data with blue color */}
                                    <a
                                        href="#"
                                        className="description-type-1 file-uploaded"
                                        title="File"
                                        onClick={handleFileDownload}
                                    >
                                        {tempFileValues?.name ? tempFileValues.name : attachment.fileName || ''}
                                    </a>
                                    <div className="heading-type-1">
                                        {tempFileValues?.size ? tempFileValues.size : attachment.savedFileSizeInKb || 0}
                                    </div>
                                </div>
                                {hasEditPermission && tempFileAvailable && (
                                    <a href="#" className="file-remove-link" title="Remove" onClick={onClickRemove}>
                                        Remove
                                    </a>
                                )}
                            </div>
                        )}
                    </div>
                    {showAttachmentRemovalModal && (
                        <ConfirmFileRemoveModal
                            description="Are you sure you want to remove the attachment?"
                            handleDiscard={handleCloseRemovalModal}
                            handleClose={handleCloseRemovalModal}
                            handleSubmit={onRemoveAttachment}
                            title="Remove Attachment"
                        />
                    )}
                </div>
            )}
        </>
    );
};

export default AuthorizationDropDown;
