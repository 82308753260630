import { get } from '../HttpMethods';

export interface Address {
    addressline1: string;
    addressline2: string;
    addressline3: string;
    city: string;
    postcode: string;
    state: string;
    countrycode: string;
    contact_email: string;
    contact_mobno: string;
    contact_person: string;
}

export interface basicDetails {
    forwarder_accountmanagerUPN: string;
    forwarder_crmUPN: string;
    cl_Org: {
        or_Orgname: string;
        or_Orgcode: string;
    };
    addressList: Address[];
}

// Get All Datas
export const getBasicDetails = async (payload: { clientPK: string }) => {
    try {
        const res = await get(`api/mgt/clients/basicdetails?UUID=${payload.clientPK}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
