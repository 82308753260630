import React from 'react';
import './no-internet.scss';
const Index: React.FC = () => {
    return (
        <div className="no-network-page msg-page">
            <div className="msg-page-content-holder">
                <span className="network-icon-holder">
                    <svg className="svg-icon no-network-icon ">
                        <use xlinkHref="#noNetworkIcon"></use>
                    </svg>
                </span>
                <p className="msg-page-heading">No internet connection</p>
                <p className="msg-page-content">Please make sure you are connected to the internet and try again</p>
                <button className="app-btn app-btn-primary retry-btn" onClick={() => window.location.reload()}>
                    Retry
                </button>
            </div>
        </div>
    );
};

export default Index;
