import React, { useCallback, useState } from 'react';
import { reactSelectStyle, reactSelectTheme } from '../../../utils/constants';
import Select, { components, InputActionMeta, MenuProps } from 'react-select';
import { searchUnloco } from 'src/services/apis/createVendorApi';
import { debounce } from 'lodash';

type props = {
    setPortDetails: any;
    portDetails: any;
    errorValidation: any;
    item: any;
};
const PortWrapper = ({ setPortDetails, portDetails, errorValidation, item }: props) => {
    const [portsvalues, setPorts] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };
    const getPort = useCallback(
        debounce(async (search: string) => {
            searchUnloco({ searchTerm: search, portType: 'sea', service: 'bkg' })
                ?.then((res: any) => {
                    const portList = res?.map((option: any) => {
                        return {
                            value: option.portCode,
                            label: `${option.portName}(${option.portCode})`,
                            portCountry: option?.portCountry ?? '',
                        };
                    });
                    setPorts(portList);
                })
                ?.catch((err) => console.log(err))
                .finally(() => {
                    setLoading(false);
                });
        }, 1000),
        []
    );

    const Menu = (props: MenuProps) => {
        const length = props.selectProps.inputValue?.length || 0;
        return length < 3 ? null : <components.Menu {...props}>{props.children}</components.Menu>;
    };

    let prevValue = '';

    return (
        <div className="layout-item">
            <div className=" modal-input-holder layout-item">
                <label htmlFor="name" className="modal-input-label">
                    Port<span className="mandatory-field-text">*</span>
                </label>
                <div id="cd_author_div" className="modal-input-layout-item">
                    <Select
                        options={portsvalues}
                        isClearable={true}
                        placeholder={'Enter atleast 3 characters to search'}
                        onChange={(option: any, { action }) => {
                            if (action === 'clear') {
                                setPorts([]);
                            }
                            item.portName = option?.label;
                            item.portCode = option?.value;
                            setPortDetails([...portDetails]);
                        }}
                        styles={reactSelectStyle}
                        menuPlacement={'auto'}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                            ClearIndicator,
                            Menu,
                        }}
                        isLoading={loading}
                        onInputChange={(value: string, actionMeta: InputActionMeta) => {
                            prevValue = prevValue === 'set-value' ? '' : actionMeta.action;
                            if (value?.length > 2) {
                                setLoading(true);
                                getPort(value);
                            } else if (
                                actionMeta.action === 'menu-close' &&
                                actionMeta.prevInputValue !== '' &&
                                prevValue
                            ) {
                                setPorts([]);
                            }
                        }}
                        theme={reactSelectTheme}
                        value={portsvalues?.filter((option: any) => {
                            if (option?.value === item.portCode) {
                                return {
                                    label: `${item.portName}`,
                                    value: item.portCode,
                                };
                            }
                        })}
                    />
                </div>
                {errorValidation && (item.portName === '' || item.portCode === '') && (
                    <p className="error_message_product" style={{ paddingTop: '8px' }}>
                        Port is required
                    </p>
                )}
            </div>
        </div>
    );
};
export default PortWrapper;
