/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export interface ColoaderOrganisationContactListDataProp {
    oc_contactName: string;
    oc_title: string;
    oc_phonenumber: string;
    oc_mobilenumber: string;
    oc_email: string;
    oc_jc: string;
    oc_PK: string;
}

export interface ColoaderUsersDataProp {
    us_Azure_Key: string;
    us_UT: string;
    us_TokenValue: string;
    us_State: string;
    us_AzureUserId: string;
    us_PK: string;
    us_Date_Of_Birth: string;
    us_PhoneNo: string;
    us_UserName: string;
    us_Address1: string;
    us_MiddleName: string;
    us_Postal_Code: string;
    us_Address_Type: string;
    us_City: string;
    us_LastName: string;
    us_FirstName: string;
    us_LandLineNo: string;
    us_Email: string;
    us_Address2: string;
    us_Country: string;
    us_Address3: string;
    us_Role: string;
}

export interface ColoaderUsersListDataProp {
    [key: string]: ColoaderUsersDataProp[];
}

export interface ColoaderForwarderRankingDataProp {
    rankingCode: string;
    rank: string;
    ir_PK: string;
}

export interface ColoaderAddressListDataProp {
    addressline1: string;
    addressline2: string;
    addressline3: string;
    address_type: string;
    city: string;
    state: string;
    postcode: string;
    countrycode: string;
    contact_email: string;
    contact_mobno: string;
    contact_person: string;
    closest_unlocode_portcode: string;
    oa_PK: string;
}

export interface ColoaderOrgDataProp {
    addressList: ColoaderAddressListDataProp[];
    or_Phone: string;
    or_ABN: number;
    or_Unloco: string;
    or_WebSiteUrl: string;
    or_ForwarderRanking: ColoaderForwarderRankingDataProp;
    or_PK: string;
    or_UsersList: ColoaderUsersListDataProp;
    or_Orgname: string;
    or_Orgcode: string;
    or_Status: string;
    or_Orgtype: string;
    or_OrganisationContactList: ColoaderOrganisationContactListDataProp[];
    or_ParentOrgCode: string;
}
export interface ColoaderBasicDetailsDataProp {
    oceanCarrierName: string;
    coloaderCode: string;
    scac: string;
    seaCarrier: string;
    carrierCategory: string;
    sl_Status: string;
    sl_PK: string;
    sl_Org: ColoaderOrgDataProp;
}

interface ColoaderBasicDetailsProp {
    status: number;
    message: string;
    info: ColoaderBasicDetailsDataProp;
    data: any;
}

interface ColoaderBasicDetailsState {
    isLoading: boolean;
    basicDetails?: ColoaderBasicDetailsProp;
    ForwarderRank?: any;
    basicDetailsSuccess: any;
    basicDetailsError: any;
    isError?: boolean;
    id?: number;
}

const initialState: ColoaderBasicDetailsState = {
    isLoading: true,
    basicDetails: undefined,
    ForwarderRank: undefined,
    basicDetailsSuccess: undefined,
    basicDetailsError: undefined,
    isError: false,
    id: undefined,
};

export const coloaderBasicDetailsSlice = createSlice({
    name: 'coloaderBasicDetails',
    initialState,
    reducers: {
        fetchColoaderBasicDetailsList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchColoaderBasicDetailsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.basicDetails = action.payload;
            state.basicDetailsSuccess = {};
            state.basicDetailsError = {};
        },
        fetchColoaderBasicDetailsListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsSuccess = {};
            state.basicDetailsError = action.payload;
        },
        fetchForwarderRankList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchForwarderRankListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.ForwarderRank = action.payload;
        },
        fetchForwarderRankListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsError = action.payload;
        },
        updateForwarderRank: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        updateForwarderRankSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.basicDetailsSuccess = action.payload;
        },
        updateForwarderRankFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.basicDetailsError = action.payload;
        },
    },
});

export const {
    fetchColoaderBasicDetailsList,
    fetchColoaderBasicDetailsListSuccess,
    fetchColoaderBasicDetailsListFailed,
    fetchForwarderRankList,
    fetchForwarderRankListSuccess,
    fetchForwarderRankListFailed,
    updateForwarderRank,
    updateForwarderRankSuccess,
    updateForwarderRankFailed,
} = coloaderBasicDetailsSlice.actions;

export const selectColoaderBasicDetailsLoading = (state: RootState) => state.coloaderBasicDetailsData.isLoading;
export const selectColoaderBasicDetails = (state: RootState) => state.coloaderBasicDetailsData.basicDetails;
export const selectColoaderBasicDetailsSuccess = (state: RootState) =>
    state.coloaderBasicDetailsData.basicDetailsSuccess;
export const selectColoaderBasicDetailsIsError = (state: RootState) => state.coloaderBasicDetailsData.isError;
export const selectColoaderBasicDetailsError = (state: RootState) => state.coloaderBasicDetailsData.basicDetailsError;
export const selectForwarderRank = (state: RootState) => state.coloaderBasicDetailsData.ForwarderRank;

export default coloaderBasicDetailsSlice.reducer;
