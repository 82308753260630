/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';

const Index: React.FC = () => {
    const dispatch = useDispatch();

    return (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Port Group</h2>
                <div className="grid-section grid-p-0">
                    <div className="grid-holder">
                        <LayoutWithColumns style={'report'} content={'qms-port-group'} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
