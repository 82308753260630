/* eslint-disable no-undefined */
import * as Yup from 'yup';
import { forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';

import { useAppDispatch, useAppSelector } from 'src/hooks';
import { decimalNumberValidation, getDateDifference } from 'src/utils';
import {
    fetchExistingFlightSchedulesList,
    existingFlightSchedulesList,
    isLoadingFetch,
    fetchCurrencyList,
    currencyList,
} from 'src/store/reducers/bookingProposalAir/existingFlightSchedulesReducer';
import { createFlightScheduleDetailsApi } from 'src/services/apis/bookingProposalAir/existingFlightSchedules';
import { error, Position, success } from 'src/utils/toast';

import ChangeModal from './ChangeModal';
import { useLocation, useNavigate } from 'react-router-dom';
import ScheduleDetailsModal from './ScheduleDetailsModal';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { isLoadingFn2 } from 'src/store/reducers/bookingProposal/sailingReducer';
import { useDispatch, useSelector } from 'react-redux';
import { fileUploadInfoText, fileTypesInfoText } from 'src/utils/constants';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import { getQuoteUoms } from 'src/services/apis/quoteAPi';
import { getpaymentterms } from 'src/services/apis/purchase_order/basicDetails';

const ScheduleFormSchema = Yup.object().shape({
    flightSchedule: Yup.string().required('Select any existing flight schedule').nullable(),
    bookingQuoteNumber: Yup.string().required('Booking quote number is required'),
    totalCoast: Yup.number().required('Total coast is required'),
    currency: Yup.string().required('Currency is required'),
    file: Yup.mixed().required('File is required'),
});

interface initialValuesProps {
    flightSchedule: string;
    bookingQuoteNumber: string;
    totalCoast: string;
    currency: string;
    file: any;
    totalCoastSell: string;
    currencySell: string;
    buyUomCode: string;
    sellUomCode: string;
    paymentTermCode: string;
    fileSell: any;
}

const INITIAL_VALUES = {
    flightSchedule: null,
    bookingQuoteNumber: '',
    totalCoast: '',
    currency: '',
    file: null,
    totalCoastSell: '',
    currencySell: '',
    buyUomCode: '',
    sellUomCode: '',
    paymentTermCode: '',
    fileSell: null,
};

const combineDateTime = (date: string, time: string) => {
    return moment(date).format('YYYY-MM-DD') + ' ' + time;
};

export default function useUseExistingSchedule(
    bpId: string,
    editData: any = null,
    createMethod: string,
    orgnlEditData: any
) {
    const dispatch = useAppDispatch();
    const { profileType } = useUserProfile();
    const formRef = useRef<any>(null);
    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const flightSchedulesList = useAppSelector(existingFlightSchedulesList) as any;
    const isLoading = useAppSelector(isLoadingFetch) as any;

    useEffect(() => {
        dispatch(fetchExistingFlightSchedulesList({ bpId, selectedScheduleId: editData?.selectedScheduleId ?? null }));
        dispatch(fetchCurrencyList());
    }, [dispatch]);

    const [openChangeModal, setOpenChangeModal] = useState(false);
    const [focus, setFocus] = useState('');
    const [flightScheduleError, setFlightScheduleError] = useState(false);
    const [initialValues, setInitialValues] = useState<any>(INITIAL_VALUES);
    const [detailsModal, setDetailsModal] = useState(null);
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [uomData, setUomData] = useState([]);
    const [paymentTermsData, setPaymentTermsData] = useState([]);
    const [scheduleLists, setScheduleLists] = useState<any>([]);
    // const scheduleListsData =
    //     flightSchedulesList?.info?.airFreightScheduleSelected?.filter((sl: any) => {
    //         if (initialValues.flightSchedule) {
    //             return sl.baId === initialValues.flightSchedule;
    //         }
    //         return true;
    //     }) ?? [];

    useEffect(() => {
        if (flightSchedulesList?.info?.airFreightScheduleSelected?.length) {
            setScheduleLists(flightSchedulesList?.info?.airFreightScheduleSelected);
        }
    }, [flightSchedulesList]);

    const getScheduleListsData = () => {
        if (initialValues.flightSchedule) {
            let filtered =
                scheduleLists
                    ?.filter((sl: any) => {
                        if (initialValues.flightSchedule) {
                            return sl.baId === initialValues.flightSchedule;
                        }
                        return true;
                    })
                    ?.map((res: any) => ({
                        ...res,
                        paymentTermCode: initialValues.paymentTermCode,
                    })) ?? [];
            return filtered;
        } else {
            return scheduleLists;
        }
    };

    // if (editData?.selectedScheduleId && initialValues.flightSchedule === editData?.selectedScheduleId) {
    //     scheduleLists.push(editData);
    // }
    const updateInitialValues = (name: string, value: string) => {
        let updatedData = {
            ...initialValues,
            [name]: value,
        };
        if (name === 'flightSchedule') {
            const code = scheduleLists?.find((res: any) => res?.baId === value)?.paymentTermCode;
            updatedData = {
                ...updatedData,
                paymentTermCode: code ?? '',
            };
        }
        setInitialValues(updatedData);
        setFocus(name);
    };

    const resetForm = () => {
        setInitialValues(INITIAL_VALUES);
        setFocus('');
    };
    useEffect(() => {
        //to reset the data only if its in create stage
        if (!editData) {
            if (createMethod === 'new') {
                resetForm();
            }
        }
    }, [createMethod, editData]);
    useEffect(() => {
        // initialValues.bookingQuoteNumber &&
        // initialValues.currency &&
        // initialValues.file &&
        // initialValues.flightSchedule &&
        // initialValues.totalCoast
        let extraCond = true;
        if (
            profileType === 'forwarder' &&
            bpStatus !== 'Rejected_by_Forwarder' &&
            bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
            bpStatus !== 'Draft_Booking_Proposal'
        ) {
            extraCond =
                initialValues.fileSell != null &&
                initialValues.totalCoastSell != '' &&
                initialValues.currencySell != '' &&
                initialValues.currencySell != null &&
                initialValues.sellUomCode != '' &&
                initialValues.sellUomCode != null;
            initialValues.paymentTermCode != '' && initialValues.paymentTermCode != null;
        } else {
            extraCond = initialValues.totalCoast != '';
        }
        const allFieldValidation =
            extraCond &&
            initialValues.bookingQuoteNumber != '' &&
            initialValues.file != null &&
            // initialValues.totalCoast != '' &&
            initialValues.currency != '' &&
            initialValues.currency != null &&
            initialValues.buyUomCode != '' &&
            initialValues.buyUomCode != null &&
            initialValues.flightSchedule != null &&
            initialValues.flightSchedule != '';

        setSubmitEnabled(allFieldValidation);
    }, [initialValues]);

    useEffect(() => {
        if (editData) {
            const editInitialValues = {
                flightSchedule: editData?.selectedScheduleId || editData?.baId,
                bookingQuoteNumber: editData?.bookingQuoteNo,
                totalCoast: editData?.buyCost,
                totalCoastSell: editData?.sellCost,
                currency: editData?.buyCurrency,
                buyUomCode: editData?.buyUomCode,
                currencySell: editData?.sellCurrency,
                sellUomCode: editData?.sellUomCode,
                paymentTermCode: editData?.paymentTermCode,
                file: {
                    name: editData?.buyQuoteName,
                    size: editData?.buyFileSizeInKb,
                    sizeInKb: editData?.buyFileSizeInKb,
                },
                fileSell: {
                    name: editData?.sellQuoteName,
                    size: editData?.sellFileSizeInKb,
                    sizeInKb: editData?.sellFileSizeInKb,
                },
            };
            setInitialValues(editInitialValues);
        }
    }, [editData]);

    const getQuoteUomsData = () => {
        getQuoteUoms()
            .then((res: any) => {
                setUomData(res);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const getPaymentTermsData = () => {
        getpaymentterms()
            .then((res: any) => {
                setPaymentTermsData(res);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getQuoteUomsData();
        getPaymentTermsData();
    }, []);

    const ExistingScheduleList = () => {
        if (createMethod === 'new') {
            return <></>;
        }
        return (
            <>
                {detailsModal && <ScheduleDetailsModal data={detailsModal} handleClose={() => setDetailsModal(null)} />}
                {openChangeModal && (
                    <ChangeModal
                        handleClose={() => setOpenChangeModal(false)}
                        handleConfirm={() => {
                            updateInitialValues('flightSchedule', '');
                            setOpenChangeModal(false);
                        }}
                    />
                )}
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="create-schelude-existing-agent air-bp">
                    <div className="view-list-grid">
                        {getScheduleListsData().length === 0 ? (
                            <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No Existing Schedules Available</p>
                            </div>
                        ) : (
                            <div className="list-grid">
                                {getScheduleListsData().map((fs: any, index: number) => {
                                    const startDateTime = combineDateTime(fs?.originEtdDate, fs?.originEtdTime);
                                    const endDateTime = combineDateTime(fs?.destEtaDate, fs?.destEtaTime);
                                    const dateDifference = getDateDifference(startDateTime, endDateTime);
                                    return (
                                        <Fragment key={fs.baId}>
                                            <div className="grid-child-item col-name">
                                                <div className="grid-child-data list-view-items">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon aeroplane-icon">
                                                            <use href="#aeroplaneIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">{fs.carrier}</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            {fs.flightNo}
                                                            {/* <span>+1</span> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid-child-item col-destination">
                                                <div className="grid-child-data">
                                                    <div className="des-progress-bar">
                                                        <div className="destination begin-journey">
                                                            <span className="point1">ETD</span>
                                                            <div className="date-time">
                                                                {/* {moment(fs?.originEtdDate).format('DD-MM-YYYY')}, */}
                                                                {moment(
                                                                    moment(fs?.originEtdDate).format('YYYY-MM-DD') +
                                                                        ' ' +
                                                                        fs?.originEtdTime
                                                                ).format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {/* {fs?.originEtdTime} */}
                                                                    {/*
                                                                {moment
                                                                    .utc(
                                                                        moment(fs?.originEtdDate).format('YYYY-MM-DD') +
                                                                            ' ' +
                                                                            fs?.originEtdTime,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    )
                                                                    .local()
                                                                    .format('HH:mm')}*/}
                                                                    {moment(
                                                                        moment(fs?.originEtdDate).format('YYYY-MM-DD') +
                                                                            ' ' +
                                                                            fs?.originEtdTime,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    ).format('HH:mm')}
                                                                </span>
                                                            </div>
                                                            <span className="point2">{fs.originAirPortName}</span>
                                                        </div>
                                                        <div className="destination center-journey">
                                                            <span className="center-point">
                                                                {fs.bookingProposalAirSchedule.length}{' '}
                                                                {`Stop${
                                                                    fs.bookingProposalAirSchedule.length > 1 ? 's' : ''
                                                                }`}
                                                            </span>
                                                            <div className="point1">
                                                                {dateDifference?.days > 0
                                                                    ? `${dateDifference?.days}d`
                                                                    : ''}
                                                                {dateDifference?.hour > 0 ? (
                                                                    <span className="time-field">
                                                                        {` ${dateDifference?.hour}h`}
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {dateDifference?.minute > 0 ? (
                                                                    <span className="time-field">
                                                                        {` ${dateDifference?.minute}m`}
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="destination end-journey">
                                                            <span className="point1">ETA</span>
                                                            <div className="date-time">
                                                                {/* {moment(fs?.destEtaDate).format('DD-MM-YYYY')} */}
                                                                {moment(
                                                                    moment(fs?.destEtaDate).format('YYYY-MM-DD') +
                                                                        ' ' +
                                                                        fs?.destEtaTime
                                                                ).format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {moment(
                                                                        moment(fs?.destEtaDate).format('YYYY-MM-DD') +
                                                                            ' ' +
                                                                            fs?.destEtaTime,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    ).format('HH:mm')}
                                                                    {/*{moment
                                                                    .utc(
                                                                        moment(fs?.destEtaDate).format('YYYY-MM-DD') +
                                                                            ' ' +
                                                                            fs?.destEtaTime,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    )
                                                                    .local()
                                                                    .format('HH:mm')}*/}
                                                                    {/* {fs?.destEtaTime} */}
                                                                </span>
                                                            </div>
                                                            <span className="point2">{fs.destinationPort}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid-child-item col-name">
                                                <div className="grid-child-data list-view-items">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">Flight No</label>
                                                        <div className="modal-input-layout-item">{fs.flightNo}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid-child-item col-name">
                                                <div className="grid-child-data list-view-items">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            Payment Terms<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div
                                                            className="total-term-wrapper dropdown-full"
                                                            style={{ width: '160px' }}
                                                        >
                                                            <SearchableSelectBox
                                                                data={paymentTermsData}
                                                                placeholder="Select Payment Terms"
                                                                setStateValue={(c: any) => {
                                                                    const copy = JSON.parse(
                                                                        JSON.stringify(scheduleLists)
                                                                    );
                                                                    copy[index].paymentTermCode = c;
                                                                    setScheduleLists(copy);

                                                                    // setOpenDropdown(false);
                                                                }}
                                                                stateValue={fs.paymentTermCode}
                                                                fieldName="paymentTermCode"
                                                                menuPosition="fixed"
                                                                menuPortalTarget={document.getElementById(
                                                                    'outside_div'
                                                                )}
                                                                closeMenuOnScroll={true}
                                                                menuPlacement="auto"
                                                                isDisabled={initialValues.flightSchedule === fs.baId}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                                <button
                                                    title={
                                                        initialValues.flightSchedule === fs.baId ? 'Change' : 'Select'
                                                    }
                                                    className={`app-btn ${
                                                        initialValues.flightSchedule === fs.baId
                                                            ? 'app-btn-secondary'
                                                            : 'app-btn-primary'
                                                    } grid-btn`}
                                                    onClick={() => {
                                                        if (initialValues.flightSchedule === fs.baId) {
                                                            setOpenChangeModal(true);
                                                        } else {
                                                            updateInitialValues('flightSchedule', fs.baId);
                                                            setFlightScheduleError(false);
                                                        }
                                                    }}
                                                    disabled={fs.paymentTermCode ? false : true}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        {initialValues.flightSchedule === fs.baId ? 'Change' : 'Select'}
                                                    </span>
                                                </button>

                                                <a
                                                    className="more-link app-btn-secondary app-btn action-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                        setDetailsModal(fs);
                                                    }}
                                                >
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#eyeIcon">
                                                            <title>View</title>
                                                        </use>
                                                    </svg>
                                                </a>
                                            </div>
                                        </Fragment>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                    {!initialValues.flightSchedule && flightScheduleError && (
                        <div className="error-text">Select any existing flight schedule</div>
                    )}
                </div>
            </>
        );
    };

    function checkSubmitDisabled() {
        return submitEnabled;
        // initialValues.bookingQuoteNumber &&
        // initialValues.currency &&
        // initialValues.file &&
        // initialValues.flightSchedule &&
        // initialValues.totalCoast
    }

    return {
        ExistingScheduleList: ExistingScheduleList,
        SubmitButton: ({ children }: any) => {
            return <>{children}</>;
        },
        ExistingForm: () => {
            if (createMethod === 'new') {
                return <></>;
            }
            return (
                <ExistingForm
                    bpId={bpId}
                    initialValues={initialValues}
                    ref={formRef}
                    setField={updateInitialValues}
                    focus={focus}
                    resetForm={resetForm}
                    edit={!!editData}
                    baId={editData?.baId ?? null}
                    orgnlEditData={orgnlEditData}
                    uomData={uomData}
                    paymentTermsData={paymentTermsData}
                />
            );
        },
        existingScheduleSubmit: () => {
            if (initialValues.flightSchedule) {
                formRef?.current?.handleSubmit();
                setFlightScheduleError(false);
            } else {
                setFlightScheduleError(true);
            }
        },
        existingScheduleCount: flightSchedulesList?.info?.airFreightScheduleSelected?.length || 0,
        existSubmitDisabled: checkSubmitDisabled(),
    };
}

const ExistingForm = forwardRef(
    ({ bpId, initialValues, setField, focus, resetForm, edit, orgnlEditData, uomData, paymentTermsData }: any, ref) => {
        const { profileType } = useUserProfile();
        const dispatch = useDispatch();
        const navigate = useNavigate();
        const location: any = useLocation();
        const editData = location.state as any;
        const currency = useAppSelector(currencyList) as any;

        const customStyle = {
            menuPortal: (provided: any) => ({
                ...provided,
                width: '200px',
            }),
            menuList: (base: any) => ({
                ...base,
                '::-webkit-scrollbar': {
                    width: '5px',
                    backgroundColor: '#F6F6FE',
                },
                '::-webkit-scrollbar-thumb': {
                    borderRadius: '10px',
                    boxShadow: 'inset 0 0 6px rgba(#000, 0.3)',
                    backgroundColor: '#C4C6D0',
                },
            }),
        };

        const portalStyle = {
            ...customStyle,
            noOptionsMessage: (base: any) => ({ ...base, height: '180px' }),
        };

        const portalStyleLg = {
            ...portalStyle,
            menuPortal: (provided: any) => ({
                ...provided,
                width: '280px',
            }),
        };

        // const [openDropdown, setOpenDropdown] = useState(false);
        const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
        const formik = useFormik<initialValuesProps>({
            initialValues,
            enableReinitialize: true,
            validationSchema: ScheduleFormSchema,
            onSubmit: async (values) => {
                dispatch(isLoadingFn2(true));
                const formData = new FormData();
                let data: any = {
                    schedulesList: [values.flightSchedule],
                    bookingQuoteNumber: values.bookingQuoteNumber,
                    totalCost: values.totalCoast,
                    totalCostSell: values.totalCoastSell,
                    currency: values.currency,
                    currencySell: values.currencySell,
                    sellUomCode: values.sellUomCode,
                    buyUomCode: values.buyUomCode,
                    paymentTermCode: values.paymentTermCode,
                    bpId: bpId,
                };
                if (!values.fileSell?.file) {
                    if (
                        (profileType === 'forwarder' &&
                            (bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                bpStatus === 'Draft_Booking_Proposal') &&
                            editData?.data?.sellQuoteName &&
                            !formik.values?.fileSell) ||
                        (profileType === 'agent' &&
                            (bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                bpStatus === 'Draft_Booking_Proposal'))
                    ) {
                        data = { ...data, isSellFileremoved: true };
                    }
                }

                formData.append('request', new Blob([JSON.stringify(data)], { type: 'application/json' }));
                if (values.file?.file) {
                    formData.append('buyfile', values?.file?.file);
                }
                if (values.fileSell?.file) {
                    formData.append('sellfile', values?.fileSell?.file);
                }
                const response = await createFlightScheduleDetailsApi(formData, orgnlEditData?.baId);
                if (response.sucess) {
                    resetForm();
                    if (edit) {
                        success('Schedule updated successfully', Position.TOP_RIGHT);
                        dispatch(isLoadingFn2(false));
                    } else {
                        success('Schedule has been added', Position.TOP_RIGHT);
                        dispatch(isLoadingFn2(false));
                    }
                    navigate(`/air_booking_list/${bpId}/FlightSelectionDetails`);
                } else if (response?.status === 'NOT_FOUND') {
                    // } else if (response?.message === undefined && response?.status === 'NOT_FOUND') {
                    error(response?.data, Position.TOP_RIGHT);
                } else {
                    error(response.data || 'Something went wrong', Position.TOP_RIGHT);
                }
            },
        });
        const { values, errors, setFieldValue, handleSubmit } = formik;

        const removeFile = () => {
            setFieldValue('file', null);
            setField('file', null);
        };

        const fileUpload = (e: any) => {
            if (e.target.files[0]) {
                setFieldValue('file', {
                    file: e.target.files[0],
                    name: e.target.files[0]?.name,
                    size: e.target.files[0].size,
                });
                setField('file', {
                    file: e.target.files[0],
                    name: e.target.files[0]?.name,
                    size: e.target.files[0].size,
                });
            }
        };

        useImperativeHandle(
            ref,
            () => ({
                handleSubmit,
            }),
            []
        );

        return (
            <>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder buy-quote">
                        <div className="layout-header-cost">Buy Quote</div>
                        <div className="layout-holder two-column">
                            {/* {profileType === 'agent' ? ( */}
                            <div className="layout-item">
                                <div className=" modal-input-holder layout-item">
                                    <label className="modal-input-label">
                                        Booking Quote Number<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="Enter Booking Quote Number"
                                            type="text"
                                            className="modal-input input-text"
                                            id="bookingQuoteNumber"
                                            autoFocus={focus === 'bookingQuoteNumber'}
                                            value={values.bookingQuoteNumber}
                                            maxLength={50}
                                            min="0"
                                            onChange={(event: any) => {
                                                setFieldValue('bookingQuoteNumber', event.target.value);
                                                setField('bookingQuoteNumber', event.target.value);
                                            }}
                                        />
                                        {errors.bookingQuoteNumber && (
                                            <div className="error-text">{errors.bookingQuoteNumber}</div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            {/* ) : ( */}
                            {/* <div className="layout-item">
                                    <div className=" modal-input-holder layout-item"></div>
                                </div> */}
                            {/* )} */}
                            <div className="layout-item">
                                <div className=" modal-input-holder layout-item"></div>
                            </div>
                            <div className="layout-item">
                                <div className=" modal-input-holder">
                                    <label className="modal-input-label">
                                        Total Cost (Buy)
                                        <span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <div className="total-term-wrapper">
                                            <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                                <span className="unit-style"></span>
                                                <input
                                                    placeholder="Enter Total Cost"
                                                    type="text"
                                                    id={'totalCoast'}
                                                    name="totalCoast"
                                                    className="modal-input input-text"
                                                    autoFocus={focus === 'totalCoast'}
                                                    value={values.totalCoast}
                                                    style={{ width: '115px' }}
                                                    onChange={(event: any) => {
                                                        // setFieldValue(
                                                        //     'totalCoast',
                                                        //     event.target.value
                                                        //         .replace(/[^.\d]/g, '')
                                                        //         .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
                                                        // );
                                                        // setField(
                                                        //     'totalCoast',
                                                        //     event.target.value
                                                        //         .replace(/[^.\d]/g, '')
                                                        //         .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
                                                        // );
                                                        const value = event.target.value
                                                            .replace(/[^.\d]/g, '')
                                                            .replace(/(?:^(\d*\.?))|(\d*)\.?/g, '$1$2');
                                                        if (decimalNumberValidation(parseFloat(value), 10, 2)) {
                                                            formik.setFieldValue('totalCoast', value);
                                                            setField('totalCoast', value);
                                                        }
                                                    }}
                                                />
                                                {errors.totalCoast && (
                                                    <div className="error-text">{errors.totalCoast}</div>
                                                )}
                                            </div>
                                            <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                {currency && (
                                                    <SearchableSelectBox
                                                        data={currency?.data}
                                                        placeholder="Select currency"
                                                        setStateValue={(c: any) => {
                                                            setFieldValue('currency', c);
                                                            setField('currency', c);
                                                            // setOpenDropdown(false);
                                                        }}
                                                        stateValue={values?.currency}
                                                        fieldName="currency"
                                                        menuPosition="fixed"
                                                        menuPortalTarget={document.getElementById('outside_div')}
                                                        closeMenuOnScroll={true}
                                                        menuPlacement="auto"
                                                    />
                                                )}
                                            </div>
                                            <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                <SearchableSelectBox
                                                    data={uomData}
                                                    placeholder="Select UOMs"
                                                    setStateValue={(c: any) => {
                                                        setFieldValue('buyUomCode', c);
                                                        setField('buyUomCode', c);
                                                        // setOpenDropdown(false);
                                                    }}
                                                    stateValue={values?.buyUomCode}
                                                    fieldName="buyUomCode"
                                                    menuPosition="fixed"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                    closeMenuOnScroll={true}
                                                    menuPlacement="auto"
                                                />
                                            </div>
                                            {/* <div
                                        className={`dropdown-wrap ${
                                            openDropdown ? 'dropdown-open' : ''
                                        } menu-down modal-dropdown search-toast-content-holder`}
                                    >
                                        <button
                                            type="button"
                                            className="menu-btn app-btn select-modal"
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                setOpenDropdown(!openDropdown);
                                            }}
                                        >
                                            <span className="btn-text">{values.currency}</span>
                                            <span className="placeholder-text">Select Currency</span>
                                            <span className="dropdown-arrow">
                                                <svg className="svg-icon arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>

                                        <div className="dropdown-menu">
                                            <div className="customscrollbar" style={{ maxHeight: '150px' }}>
                                                {currency?.data?.map((c: any) => (
                                                    <a
                                                        key={c.value}
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            setFieldValue('currency', c.value);
                                                            setField('currency', c.value);
                                                            setOpenDropdown(false);
                                                        }}
                                                    >
                                                        {c.label}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div> */}
                                            {errors.currency && <div className="error-text">{errors.currency}</div>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="layout-item">
                                <div className=" modal-input-holder">
                                    <label htmlFor="name" className="modal-input-label">
                                        Quotation (Buy)
                                        <span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        {!values.file && (
                                            <div className="browse-btn-wrapper">
                                                <input
                                                    type={'file'}
                                                    id={'quotationFile'}
                                                    name={'quotationFile'}
                                                    accept={
                                                        '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                    }
                                                    className="browse-input"
                                                    onChange={(e) => {
                                                        if (
                                                            (e as any).target.files[0]?.name.includes('.pdf') ||
                                                            (e as any).target.files[0]?.name.includes('.doc') ||
                                                            (e as any).target.files[0]?.name.includes('.docx')
                                                        ) {
                                                            fileUpload(e);
                                                        } else {
                                                            error('Invalid File Type', Position.TOP_RIGHT);
                                                        }
                                                    }}
                                                    onClick={(event) => ((event.target as any).value = null)}
                                                />
                                                <button
                                                    className="app-btn app-btn-secondary icon-button browse-btn"
                                                    title="Upload file"
                                                    type="button"
                                                >
                                                    <svg className="svg-icon plus-icon">
                                                        <use xlinkHref="#plusIcon">
                                                            <title>Upload File</title>
                                                        </use>
                                                    </svg>
                                                    <span className="button-text upload-btn">Upload File</span>
                                                </button>
                                                <div className="heading-type-1 file-size">
                                                    <p>{fileUploadInfoText}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}></p>
                                                </div>
                                            </div>
                                        )}

                                        {errors.file && <div className="error-text">File is required</div>}
                                        {values.file && (
                                            <div className="content-with-icon  attachment-holder">
                                                <button type="button" className="app-btn " title="Attach file">
                                                    <svg className="svg-icon file-attachment-icon ">
                                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                                        <title>Attach file</title>
                                                    </svg>
                                                </button>

                                                <div>
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="description-type-1 file-uploaded"
                                                        title="File"
                                                    >
                                                        {values.file?.name?.length > 20
                                                            ? `${values?.file?.name?.slice(0, 20)}...`
                                                            : values?.file?.name}
                                                    </a>
                                                    <div className="heading-type-1">
                                                        {values.file?.sizeInKb
                                                            ? values.file?.sizeInKb
                                                            : getFileSize(values.file?.size)}
                                                    </div>
                                                </div>
                                                <a
                                                    href="javascript:void(0)"
                                                    className="file-remove-link"
                                                    title="Remove"
                                                    onClick={() => removeFile()}
                                                >
                                                    Remove
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {profileType !== 'agent' && (
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder buy-quote">
                            <div className="layout-header-cost">Sell Quote</div>
                            <div className="layout-holder two-column">
                                {/* <div className="layout-item">
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">
                                            Booking Quote Number<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Booking Quote Number"
                                                type="text"
                                                className="modal-input input-text"
                                                id="bookingQuoteNumber"
                                                autoFocus={focus === 'bookingQuoteNumber'}
                                                value={values.bookingQuoteNumber}
                                                maxLength={50}
                                                min="0"
                                                onChange={(event: any) => {
                                                    setFieldValue('bookingQuoteNumber', event.target.value);
                                                    setField('bookingQuoteNumber', event.target.value);
                                                }}
                                            />
                                            {errors.bookingQuoteNumber && (
                                                <div className="error-text">{errors.bookingQuoteNumber}</div>
                                            )}
                                        </div>
                                    </div>
                                </div> */}
                                <div className="layout-item">
                                    <div className=" modal-input-holder layout-item"></div>
                                </div>
                                <div className="layout-item">
                                    <div className=" modal-input-holder layout-item"></div>
                                </div>
                                <div className="layout-item">
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Total Cost (Sell)
                                            {bpStatus !== 'Rejected_by_Forwarder' &&
                                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                bpStatus !== 'Draft_Booking_Proposal' && (
                                                    <span className="mandatory-field-text">*</span>
                                                )}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <div className="total-term-wrapper">
                                                <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                                    <span className="unit-style"></span>
                                                    <input
                                                        placeholder="Enter Total Cost"
                                                        type="text"
                                                        id={'totalCoastSell'}
                                                        name="totalCoastSell"
                                                        className="modal-input input-text"
                                                        autoFocus={focus === 'totalCoastSell'}
                                                        value={values.totalCoastSell}
                                                        style={{ width: '115px' }}
                                                        onChange={(event: any) => {
                                                            // setFieldValue(
                                                            //     'totalCoast',
                                                            //     event.target.value
                                                            //         .replace(/[^.\d]/g, '')
                                                            //         .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
                                                            // );
                                                            // setField(
                                                            //     'totalCoast',
                                                            //     event.target.value
                                                            //         .replace(/[^.\d]/g, '')
                                                            //         .replace(/^(\d*\.?)|(\d*)\.?/g, '$1$2')
                                                            // );
                                                            const value = event.target.value
                                                                .replace(/[^.\d]/g, '')
                                                                .replace(/(?:^(\d*\.?))|(\d*)\.?/g, '$1$2');
                                                            if (decimalNumberValidation(parseFloat(value), 10, 2)) {
                                                                formik.setFieldValue('totalCoastSell', value);
                                                                setField('totalCoastSell', value);
                                                            }
                                                        }}
                                                    />
                                                    {errors.totalCoastSell && (
                                                        <div className="error-text">{errors.totalCoastSell}</div>
                                                    )}
                                                </div>
                                                <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                    {currency && (
                                                        <SearchableSelectBox
                                                            data={currency?.data}
                                                            placeholder="Select currency"
                                                            setStateValue={(c: any) => {
                                                                setFieldValue('currencySell', c);
                                                                setField('currencySell', c);
                                                                // setOpenDropdown(false);
                                                            }}
                                                            stateValue={values?.currencySell}
                                                            fieldName="currencySell"
                                                            menuPosition="fixed"
                                                            menuPortalTarget={document.getElementById('outside_div')}
                                                            closeMenuOnScroll={true}
                                                            menuPlacement="top"
                                                        />
                                                    )}
                                                </div>
                                                <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                    <SearchableSelectBox
                                                        data={uomData}
                                                        placeholder="Select UOMs"
                                                        setStateValue={(c: any) => {
                                                            setFieldValue('sellUomCode', c);
                                                            setField('sellUomCode', c);
                                                            // setOpenDropdown(false);
                                                        }}
                                                        stateValue={values?.sellUomCode}
                                                        fieldName="sellUomCode"
                                                        menuPosition="fixed"
                                                        menuPortalTarget={document.getElementById('outside_div')}
                                                        closeMenuOnScroll={true}
                                                        menuPlacement="top"
                                                    />
                                                </div>
                                                {/* <div
                                        className={`dropdown-wrap ${
                                            openDropdown ? 'dropdown-open' : ''
                                        } menu-down modal-dropdown search-toast-content-holder`}
                                    >
                                        <button
                                            type="button"
                                            className="menu-btn app-btn select-modal"
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                setOpenDropdown(!openDropdown);
                                            }}
                                        >
                                            <span className="btn-text">{values.currency}</span>
                                            <span className="placeholder-text">Select Currency</span>
                                            <span className="dropdown-arrow">
                                                <svg className="svg-icon arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>

                                        <div className="dropdown-menu">
                                            <div className="customscrollbar" style={{ maxHeight: '150px' }}>
                                                {currency?.data?.map((c: any) => (
                                                    <a
                                                        key={c.value}
                                                        className="menu-item app-btn"
                                                        onClick={() => {
                                                            setFieldValue('currency', c.value);
                                                            setField('currency', c.value);
                                                            setOpenDropdown(false);
                                                        }}
                                                    >
                                                        {c.label}
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    </div> */}
                                                {errors.currencySell && (
                                                    <div className="error-text">{errors.currencySell}</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="layout-item">
                                    <div className=" modal-input-holder">
                                        <label htmlFor="name" className="modal-input-label">
                                            Quotation (Sell)
                                            {bpStatus !== 'Rejected_by_Forwarder' &&
                                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                bpStatus !== 'Draft_Booking_Proposal' && (
                                                    <span className="mandatory-field-text">*</span>
                                                )}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            {!values.fileSell?.name && (
                                                <div className="browse-btn-wrapper">
                                                    <input
                                                        type={'file'}
                                                        id={'quotationFile'}
                                                        name={'quotationFile'}
                                                        accept={
                                                            '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                        }
                                                        className="browse-input"
                                                        onChange={(e: any) => {
                                                            if (
                                                                (e as any).target.files[0]?.name.includes('.pdf') ||
                                                                (e as any).target.files[0]?.name.includes('.doc') ||
                                                                (e as any).target.files[0]?.name.includes('.docx')
                                                            ) {
                                                                if (e.target.files[0]) {
                                                                    setFieldValue('fileSell', {
                                                                        file: e.target.files[0],
                                                                        name: e.target.files[0]?.name,
                                                                        size: e.target.files[0].size,
                                                                    });
                                                                    setField('fileSell', {
                                                                        file: e.target.files[0],
                                                                        name: e.target.files[0]?.name,
                                                                        size: e.target.files[0].size,
                                                                    });
                                                                }
                                                            } else {
                                                                error('Invalid File Type', Position.TOP_RIGHT);
                                                            }
                                                        }}
                                                        onClick={(event) => ((event.target as any).value = null)}
                                                    />
                                                    <button
                                                        className="app-btn app-btn-secondary icon-button browse-btn"
                                                        title="Upload file"
                                                        type="button"
                                                    >
                                                        <svg className="svg-icon plus-icon">
                                                            <use xlinkHref="#plusIcon">
                                                                <title>Upload File</title>
                                                            </use>
                                                        </svg>
                                                        <span className="button-text upload-btn">Upload File</span>
                                                    </button>
                                                    <div className="heading-type-1 file-size">
                                                        <p>{fileUploadInfoText}</p>
                                                        <p dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}></p>
                                                    </div>
                                                </div>
                                            )}

                                            {errors.fileSell && <div className="error-text">File is required</div>}
                                            {values.fileSell?.name && (
                                                <div className="content-with-icon  attachment-holder">
                                                    <button type="button" className="app-btn " title="Attach file">
                                                        <svg className="svg-icon file-attachment-icon ">
                                                            <use xlinkHref="#fileAttachmentIcon"></use>
                                                            <title>Attach file</title>
                                                        </svg>
                                                    </button>

                                                    <div>
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="description-type-1 file-uploaded"
                                                            title="File"
                                                        >
                                                            {values.fileSell?.name?.length > 20
                                                                ? `${values?.fileSell?.name?.slice(0, 20)}...`
                                                                : values?.fileSell?.name}
                                                        </a>
                                                        <div className="heading-type-1">
                                                            {values.fileSell?.sizeInKb
                                                                ? values.fileSell?.sizeInKb
                                                                : getFileSize(values.fileSell?.size)}
                                                        </div>
                                                    </div>
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="file-remove-link"
                                                        title="Remove"
                                                        onClick={() => {
                                                            setFieldValue('fileSell', null);
                                                            setField('fileSell', null);
                                                        }}
                                                    >
                                                        Remove
                                                    </a>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="layout-item">
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            UOMs
                                            {bpStatus !== 'Rejected_by_Forwarder' &&
                                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                bpStatus !== 'Draft_Booking_Proposal' && (
                                                    <span className="mandatory-field-text">*</span>
                                                )}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <div className="total-term-wrapper dropdown-full">
                                                <SearchableSelectBox
                                                    data={uomData}
                                                    placeholder="Select UOMs"
                                                    setStateValue={(c: any) => {
                                                        setFieldValue('uomCode', c);
                                                        setField('uomCode', c);
                                                        // setOpenDropdown(false);
                                                    }}
                                                    stateValue={values?.uomCode}
                                                    fieldName="uomCode"
                                                    menuPosition="fixed"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                    closeMenuOnScroll={true}
                                                    menuPlacement="auto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className="layout-item">
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Payment Terms
                                            {bpStatus !== 'Rejected_by_Forwarder' &&
                                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                bpStatus !== 'Draft_Booking_Proposal' && (
                                                    <span className="mandatory-field-text">*</span>
                                                )}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <div className="total-term-wrapper dropdown-full">
                                                <SearchableSelectBox
                                                    data={paymentTermsData}
                                                    placeholder="Select UOMs"
                                                    setStateValue={(c: any) => {
                                                        setFieldValue('paymentTermCode', c);
                                                        setField('paymentTermCode', c);
                                                        // setOpenDropdown(false);
                                                    }}
                                                    stateValue={values?.paymentTermCode}
                                                    fieldName="paymentTermCode"
                                                    menuPosition="fixed"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                    closeMenuOnScroll={true}
                                                    menuPlacement="auto"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                )}
            </>
        );
    }
);

const getFileSize = (size: number) => {
    let totalSize = '';
    if (size > 1048576) {
        totalSize = (size / Math.pow(1024, 2)).toFixed(2);
        return `${totalSize} Mb`;
    }
    totalSize = (size / Math.pow(1024, 1)).toFixed(2);
    return `${totalSize} Kb`;
};
