import React, { ReactElement, useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { downloadShippingLineContract } from '../../../services/apis/profiles/shippingLine/contractDetailsApi';
import { selectContractDetailIncoTerms } from '../../../store/reducers/profiles/shippingLine/ContractDetailsReducer';
import './contract-view-modal-additional.scss';
import moment from 'moment';
import CarrierContractGrid from 'src/components/KendoGridJquery/CarrierContract/CarrierContractGrid';
interface ModalViewProps {
    data: any;
    isLoading: boolean;
    setShowContractViewModal: (data: boolean) => void;
}

const ContractViewModal = ({ data, isLoading, setShowContractViewModal }: ModalViewProps): ReactElement => {
    const [accordion, setAccordion] = useState('headerInformation');
    const contractIncoTerms = useAppSelector(selectContractDetailIncoTerms);
    const [totalTeuAllocated, setTotalTeuAllocated] = useState(0);

    const getDate = (date: string) => {
        if (date) {
            const fullDate = date.split('T')[0];
            const dateData = fullDate?.split('-');
            return `${dateData[2]}-${dateData[1]}-${dateData[0]}`;
        }
        return '';
    };

    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };

    useEffect(() => {
        let totalTeuAllocated = 0;
        if (data?.ports?.length) {
            const startDate = moment(data?.startDate, 'YYYY-MM-DD');
            const endDate = moment(data?.endDate, 'YYYY-MM-DD');
            const days = endDate.diff(startDate, 'days');
            for (let index = 0; index < data?.ports?.length; index++) {
                const { so_teu }: { so_teu: number } = data?.ports[index];
                if (so_teu && days > 0) {
                    totalTeuAllocated += (days / 7) * so_teu;
                }
            }
            setTotalTeuAllocated(Math.round(totalTeuAllocated));
        }
    }, [data]);

    return (
        <div className="app-modal large-popup">
            <div id="modal_body" className="modal-content-holder shipping-content-holder large-popup">
                <div className="modal-header">
                    <div className="title">Contract Details</div>
                    <button
                        onClick={() => {
                            setShowContractViewModal(false);
                        }}
                        className="app-btn modal-close-btn"
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="accordion-wrapper">
                        <div className="accordion-section section-header">
                            <input
                                checked={accordion === 'headerInformation'}
                                type="checkbox"
                                className="accordion-radio"
                                id="ac1"
                                name="shipping-accordion-group"
                                onChange={() => {
                                    accordion === 'headerInformation'
                                        ? setAccordion('')
                                        : setAccordion('headerInformation');
                                }}
                            ></input>
                            <label className="accordion-title" htmlFor="ac1">
                                <div className="label-header">
                                    <svg className="svg-icon accordion-arrow-icon">
                                        <use xlinkHref="#downArrow">
                                            <title>dropdown</title>
                                        </use>
                                    </svg>
                                    <span className="label-title">Header Information</span>
                                </div>
                            </label>
                            <div style={{ overflow: 'auto' }} className="accordion-content">
                                <div className="modal-main-content-header">
                                    <div className="input-form-wrapper">
                                        <div className="modal-form layout-holder four-column">
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">Name</label>
                                                <div className="modal-input-layout-item">{data?.contractName}</div>
                                            </div>
                                            <div className=" modal-input-holder layout-item">
                                                <label className="modal-input-label">Version</label>
                                                <div className="modal-input-layout-item">{data?.version}</div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label htmlFor="name" className="modal-input-label">
                                                    Author
                                                </label>
                                                <div className="modal-input-layout-item">{data?.author}</div>
                                            </div>
                                            <div className=" modal-input-holder layout-item ">
                                                <label htmlFor="name" className="modal-input-label">
                                                    Forwarder Signatory
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    {data?.FORWARDERSignatory}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder layout-item">
                                                <label htmlFor="name" className="modal-input-label">
                                                    Carrier Signatory
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    {data?.shippingLineSignatory}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder layout-item">
                                                <label className="modal-input-label">Start Date</label>
                                                <div className="modal-input-layout-item date-input-holder">
                                                    {getDate(data?.startDate)}
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">End Date</label>
                                                <div className="modal-input-layout-item date-input-holder">
                                                    {getDate(data?.endDate)}
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">Status</label>
                                                <div className="modal-input-layout-item date-input-holder">Active</div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label className="modal-input-label">Renewal Type</label>
                                                <div className={'modal-input-layout-item date-input-holder'}>
                                                    {data?.renewal}
                                                </div>
                                            </div>
                                            <div className="modal-input-holder layout-item">
                                                <label htmlFor="name" className="modal-input-label">
                                                    Managed By
                                                </label>
                                                <div className="form-select-holder">{`${data?.managedBy}: ${data?.managedByName}`}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="accordion-section section-port">
                            <input
                                checked={accordion === 'portPairs'}
                                type="checkbox"
                                className="accordion-radio"
                                id="ac2"
                                name="shipping-accordion-group"
                                onChange={() => {
                                    accordion === 'portPairs' ? setAccordion('') : setAccordion('portPairs');
                                }}
                            ></input>
                            <label className="accordion-title" htmlFor="ac2">
                                <div className="label-header">
                                    <svg className="svg-icon accordion-arrow-icon">
                                        <use xlinkHref="#downArrow">
                                            <title>dropdown</title>
                                        </use>
                                    </svg>
                                    <span className="label-title">Port Pairs Combination</span>
                                </div>
                            </label>
                            <div className="accordion-content">
                                {/* <div className="grid-section modal-grid port-grid">
                                    <div className="grid-holder">
                                        <div className="grid-header">
                                            <div className="content-heading-holder">
                                                <span className="content-heading">Total TEU Allocated</span>
                                                <span className=" modal-toast">
                                                    <div className="toast-message ">{totalTeuAllocated}</div>
                                                </span>
                                            </div>
                                        </div>
                                        <div id="productForm" className="modal-form">
                                            <div className="grid-container">
                                                {!data?.ports?.length && (
                                                    <div className="grid-no-content ">
                                                        <svg className="svg-icon grid-no-content-icon ">
                                                            <use xlinkHref="#gridNoContentIcon"></use>
                                                        </svg>
                                                        <p className="no-content-message">No Port Pair Added</p>
                                                    </div>
                                                )}
                                                <div className="grid-scroll-content">
                                                    <table className="grid-table">
                                                        <thead>
                                                            <tr className="grid-sticky-row">
                                                                <th className="grid-cell-header grid-sticky-column sticky-left-aligned col-origin-port">
                                                                    <div className="grid-cell-data">
                                                                        <span className="header-title">Origin</span>
                                                                    </div>
                                                                </th>
                                                                <th className="grid-cell-header col-destination-port">
                                                                    <div className=" grid-cell-data">
                                                                        <span className="header-title">
                                                                            DESTINATION
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                <th className="grid-cell-header  col-inco-team">
                                                                    <div className=" grid-cell-data">
                                                                        <span className="header-title">INCO TERM</span>
                                                                    </div>
                                                                </th>
                                                                <th className="grid-cell-header  col-teu-allocation">
                                                                    <div className=" grid-cell-data">
                                                                        <span className="header-title">
                                                                            TEU ALLOCATED/WEEK
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <>
                                                                {data?.ports?.length > 0 &&
                                                                    data?.ports?.map((res: any, index: number) => (
                                                                        <tr
                                                                            key={index}
                                                                            className="has-child row-expanded"
                                                                        >
                                                                            <td className="grid-cell  grid-sticky-column td-origin">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.so_originPort}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-destination">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.so_destinationPort}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-inco">
                                                                                <div className="grid-cell-data">
                                                                                    {
                                                                                        contractIncoTerms?.data?.find(
                                                                                            (element: any) =>
                                                                                                element.value ===
                                                                                                res?.so_incoTerm
                                                                                        )?.label
                                                                                    }
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-teu">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.so_teu}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                            </>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                {data?.contractId && (
                                    <CarrierContractGrid
                                        scPk={data?.contractId}
                                        slPk={data?.shippingLineId ?? ''}
                                        readonly={true}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="accordion-section section-attachment">
                            <input
                                checked={accordion === 'attachments'}
                                type="checkbox"
                                className="accordion-radio"
                                id="ac4"
                                name="shipping-accordion-group"
                                value="attachment"
                                onChange={() => {
                                    accordion === 'attachments' ? setAccordion('') : setAccordion('attachments');
                                }}
                            ></input>
                            <label className="accordion-title" htmlFor="ac4">
                                <div className="label-header">
                                    <svg className="svg-icon accordion-arrow-icon">
                                        <use xlinkHref="#downArrow">
                                            <title>dropdown</title>
                                        </use>
                                    </svg>
                                    <span className="label-title">Attachment</span>
                                </div>
                            </label>
                            <div className="accordion-content">
                                <div className="modal-input-layout-item">
                                    <div className="content-with-icon  attachment-holder  view-file">
                                        <div>
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => {
                                                    if (data?.fileName) {
                                                        downloadShippingLineContract(data.contractId, data.fileName);
                                                    }
                                                }}
                                                className="description-type-1 file-uploaded"
                                                title="File"
                                            >
                                                {data?.fileName?.length > 20
                                                    ? `${data?.fileName?.slice(0, 20)}...`
                                                    : data?.fileName}
                                            </a>
                                            {data?.savedFileSizeInKb ? (
                                                <div className="heading-type-1">
                                                    {getFileSizeOfUploadedFile(data?.savedFileSizeInKb)}
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer"></div>
            </div>
        </div>
    );
};

export default ContractViewModal;
