/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import ConfirmModal from './confirmModal';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const [showConfirmModal, setShowConfirmModal] = useState('');
    const [actionCode, setActionCode] = useState('');
    const [raPk, setRaPk] = useState('');

    //@ts-ignore
    window.createRate = (action: string, name: string) => {
        setShowConfirmModal(name);
        setActionCode(action);
    };

    useEffect(() => {
        if (raPk) {
            const url = `/qms/rate/${raPk}`;
            setRaPk('');
            window.location.href = url;
        }
    }, [raPk]);

    return (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Rate</h2>
                <div className="grid-section grid-p-0">
                    <div className="grid-holder">
                        <LayoutWithColumns style={'report'} content={'qms-rate'} />
                    </div>
                </div>
            </div>
            {showConfirmModal && (
                <ConfirmModal
                    actionCode={actionCode}
                    modalView={showConfirmModal}
                    setModalView={setShowConfirmModal}
                    setRaPk={setRaPk}
                />
            )}
        </div>
    );
};

export default Index;
