import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'dashboardTodo',
    initialState: {
        isLoading: true,
        todoList: {} as any,
        todoListTotalCount: 0,
        todoListFavoriteCount: 0,
        todoListOtherCount: 0,
        todoListNewItemTotal: false,
        todoListNewItemFavs: false,
        todoItem: {} as any,
        todoItemCount: 0,
        error: null,
        selectedTodoType: null as any,
        selectedTodoName: null as any,
    },
    reducers: {
        isLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        todoListFetching: (state, _action) => {
            state.isLoading = true;
            state.todoList = {};
            state.todoListTotalCount = 0;
            state.todoListFavoriteCount = 0;
            state.todoListOtherCount = 0;
            state.todoListNewItemTotal = false;
            state.todoListNewItemFavs = false;
        },
        clearListFetching: (state) => {
            state.isLoading = false;
            state.todoList = {};
            state.todoItem = {};
            state.todoListTotalCount = 0;
            state.todoListFavoriteCount = 0;
            state.todoListOtherCount = 0;
            state.todoListNewItemTotal = false;
            state.todoListNewItemFavs = false;
        },
        todoListFetchingSuccess: (state, action) => {
            let newItemFavs = false;
            // const favoriteCount =
            //     [...(action.payload?.favourites ?? [])]?.reduce((accumulator: number, value: any) => {
            //         if (value.updated) {
            //             newItemFavs = true;
            //         }
            //         return accumulator + (value.count ?? 0);
            //     }, 0) ?? 0;

            // let newItemOthers = false;

            // const othersCount =
            //     [...(action.payload?.others ?? [])]?.reduce((accumulator: number, value: any) => {
            //         if (value.updated) {
            //             newItemOthers = true;
            //         }
            //         return accumulator + (value.count ?? 0);
            //     }, 0) ?? 0;

            // const totalCount = favoriteCount + othersCount;

            state.isLoading = false;
            state.todoList = action.payload;
            // state.todoListTotalCount = totalCount;
            // state.todoListFavoriteCount = favoriteCount;
            // state.todoListOtherCount = othersCount;
            // state.todoListNewItemTotal = newItemFavs || newItemOthers;
            state.todoListNewItemFavs = newItemFavs;
            state.error = null;
        },
        todoListFetchingError: (state, action) => {
            state.isLoading = false;
            state.todoList = {};
            state.todoListTotalCount = 0;
            state.todoListFavoriteCount = 0;
            state.todoListOtherCount = 0;
            state.error = action.payload;
        },
        todoItemFetching: (state, _action) => {
            state.isLoading = true;
            state.todoItem = {};
            state.todoItemCount = 0;
            state.error = null;
        },
        todoItemFetchingSuccess: (state, action) => {
            state.isLoading = false;
            state.todoItem = action.payload;
            state.todoItemCount = 0;
            state.error = null;
        },
        todoItemFetchingError: (state, action) => {
            state.isLoading = false;
            state.todoItem = {};
            state.todoItemCount = 0;
            state.error = action.payload;
        },
        setTodoType: (state, action) => {
            state.selectedTodoType = action.payload.type ?? null;
            state.selectedTodoName = action.payload.name ?? null;
        },
    },
});

export const {
    isLoading,
    todoListFetching,
    todoListFetchingSuccess,
    todoListFetchingError,
    todoItemFetching,
    todoItemFetchingSuccess,
    todoItemFetchingError,
    setTodoType,
    clearListFetching,
} = dataSlice.actions;
export default dataSlice.reducer;
