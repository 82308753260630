import React, { useEffect, useState } from 'react';
// import './style.css';
import '../../assets/scss/components/_breadcrumbs.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import CancellationModal from '../../pages/PO/BasicDetail/CancellationModal';
import DiscardModal from '../../pages/PO/BasicDetail/Discard';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import { Position, success, error } from '../../utils/toast';
import { discardPo, reset, cancelPo } from '../../store/reducers/purchaseOrder/poListReducer';
import ShowForPermissions from '../../ShowForPermissions';
import { useAppSelector } from '../../hooks';
import { selectHeader } from '../../store/reducers/purchaseOrder/addPoReducer';
import { sleep } from 'src/utils';
import {
    setInboundPoSelectedDropDown as setSelectedDropDown,
    setInboundPoFilter as setFilter,
} from '../../store/reducers/purchaseOrder/poListReducer';
import { activeInboundPos, activeStatus } from 'src/utils/constants';
interface PurchaseOrderProps {
    firstName: string;
    firstUrl: string;
    secondName: string;
    secondUrl: string;
    disabled?: boolean;
    callbackFn?: any;
}
const Index: React.FC<PurchaseOrderProps> = (props) => {
    const dispatch = useDispatch();
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);
    const headerData = useAppSelector(selectHeader);
    const { error: errorMessage, success: successFlag } = useSelector((state: RootState) => state.purchaseOrderList);
    const { id } = useParams();
    const userDetails = JSON.parse(localStorage.getItem('user') || '')?.user;
    const userName = userDetails?.us_UserName;
    const name = (userDetails?.us_FirstName || '') + ' ' + (userDetails?.us_LastName || '');
    const navigate = useNavigate();
    const location = useLocation();
    const buttonType = headerData?.poStatus === 'Discarded' ? 'Restore' : 'Discard';

    const onClose = () => {
        setShowCancelModal(false);
    };

    // useEffect(() => {
    // dispatch(
    //     fetchHeader({
    //         poId: id,
    //     })
    // );
    // }, [id]);

    const onConfirm = (reason: string) => {
        dispatch(cancelPo({ poPk: id, data: { reason, userName, name } }));
    };

    const onDiscard = () => {
        dispatch(discardPo({ poPk: id, userName, name, buttonType }));
        //API call
    };

    const onDiscardCancel = () => {
        setShowDiscardModal(false);
    };

    useEffect(() => {
        if (successFlag) {
            if (headerData?.poStatus === 'Awaiting_Enrichment' || headerData?.poStatus === 'Ready_For_Booking') {
                success(`PO ${headerData.poNumber} has been cancelled`, Position.TOP_RIGHT);
            } else if (headerData?.poStatus === 'Discarded') {
                success(`PO ${headerData.poNumber} has been restored`, Position.TOP_RIGHT);
            } else {
                success(`PO ${headerData.poNumber} has been discarded`, Position.TOP_RIGHT);
            }
            if (location.pathname.includes('/purchase_order')) {
                navigate('/purchase_order');
            } else {
                navigate('/inbound');
            }
        }
        if (errorMessage) {
            error(errorMessage, Position.TOP_RIGHT);
        }
        dispatch(reset({}));
        setShowCancelModal(false);
        setShowDiscardModal(false);
    }, [errorMessage, successFlag]);

    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let isAgent = false;
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
        }
    }

    const disabled = props.disabled ?? false;

    const redirection = async () => {
        if (props?.callbackFn) {
            const status = props?.callbackFn();
            if (status) {
                await sleep(2000);
            } else {
                success('Details has been saved', Position.TOP_RIGHT);
            }
        } else {
            success('Details has been saved', Position.TOP_RIGHT);
        }
        if (location.pathname.includes('/purchase_order')) {
            navigate('/purchase_order');
        } else {
            navigate('/inbound');
        }
    };

    window.onpopstate = () => {
        const initialFilter = { poStatus: activeStatus };
        const initialDropDown = { text: activeInboundPos, count: 0 };
        dispatch(setFilter(initialFilter));
        dispatch(setSelectedDropDown(initialDropDown));
    };

    return (
        <>
            <div className="po-breadcrumb-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link
                            to={`${location.pathname.includes('/inbound') ? '/inbound' : '/purchase_order'} `}
                            className={`dropdown-arrow ${disabled && 'disabled'}`}
                            state={{ reload: true }}
                        >
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>
                                        {location.pathname.includes('/inbound') ? 'Inbound PO' : 'International PO'}
                                    </title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link
                                className={`breadcrumb-item ${disabled && 'disabled'}`}
                                to={props.firstUrl}
                                state={{ reload: true }}
                            >
                                {props.firstName}
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to={props.secondUrl} className={`breadcrumb-item ${disabled && 'disabled'}`}>
                                {props.secondName}
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="action-wrapper">
                    <div className="btn-holder discard-po-btn-holder">
                        {(!isAgent || location.pathname.includes('/inbound')) && (
                            <ShowForPermissions
                                permission="edit"
                                type={location.pathname.includes('/purchase_order') ? 'po' : 'inbound'}
                            >
                                {headerData?.poStatus === 'Draft' ||
                                headerData?.poStatus === 'Awaiting_Enrichment' ||
                                headerData?.poStatus === 'Ready_For_Booking' ||
                                !headerData?.poStatus ? (
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title={
                                            headerData?.poStatus === 'Draft'
                                                ? 'Discard Purchase Order'
                                                : 'Cancel Purchase Order'
                                        }
                                        onClick={() => {
                                            if (headerData?.poStatus === 'Draft') {
                                                setShowDiscardModal(true);
                                            } else {
                                                setShowCancelModal(true);
                                            }
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#deleteIcon"></use>
                                        </svg>
                                        <span className="button-text">
                                            {headerData?.poStatus === 'Draft' ? 'Discard PO' : 'Cancel PO'}
                                        </span>
                                    </button>
                                ) : null}
                            </ShowForPermissions>
                        )}

                        {headerData?.poStatus === 'Discarded' && (
                            <button
                                className="app-btn app-btn-secondary black-outline-btn icon-button"
                                title="Restore PO"
                                onClick={() => setShowDiscardModal(true)}
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <use xlinkHref="#resetIcon"></use>
                                </svg>
                                <span className="button-text">Restore PO</span>
                            </button>
                        )}

                        {headerData?.poStatus === 'Draft' && (
                            <ShowForPermissions
                                permission="edit"
                                type={location.pathname.includes('/purchase_order') ? 'po' : 'inbound'}
                            >
                                <button
                                    className="app-btn app-btn-secondary black-outline-btn icon-button"
                                    title={'Save & Exit'}
                                    onClick={() => redirection()}
                                >
                                    <svg className="svg-icon add-btn-icon">
                                        <use xlinkHref="#saveIcon2">
                                            <title>Save & Exit</title>
                                        </use>
                                    </svg>
                                    <span className="button-text">Save & Exit</span>
                                </button>
                            </ShowForPermissions>
                        )}
                    </div>
                </div>
            </div>
            {showCancelModal ? (
                <CancellationModal onClose={onClose} id={id ? id : ''} onConfirm={onConfirm}></CancellationModal>
            ) : null}
            {showDiscardModal ? (
                <DiscardModal
                    onOkay={onDiscard}
                    onCancel={onDiscardCancel}
                    title={`${buttonType} PO`}
                    message={`Are you sure you want to ${buttonType} this PO and all its details?`}
                />
            ) : null}
        </>
    );
};

export default Index;
