/* eslint-disable no-undefined */
import React, { useEffect, useState, useRef } from 'react';
import AllocationOrderHeader from '../AllocationOrderHeader';
import { Link, useParams } from 'react-router-dom';
import './product-allocation.scss';
import BSTooltip from 'src/components/common/BSTooltip';
import DatePicker from 'src/components/common/DatePicker/withoutFormik';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import AOBreadCrumb from '../BreadCrumb';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    fetchProductAllocationList,
    fetchCurrency,
    fetchPort,
    fetchDestinationAddress,
    selectCurrency,
    selectDestinationAddress,
    selectPort,
    selectProductAllocation,
    selectProductAllocationLoading,
    selectSku,
    selectAllocationSku,
    fetchSkuList,
    fetchAllocationSkuList,
    ProductAllocationDataDetails,
    selectProductAllocationIsError,
    selectProductAllocationSuccess,
    selectProductAllocationError,
    clearFailure,
    createProductAllocation,
    updateProductAllocation,
    fetchDistinctValues,
    productAllocationDataDetailsInitial,
    selectDistinctValue,
} from '../../../store/reducers/allocationOrder/productAllocationReducer';
import { fetchHeader, selectHeader } from '../../../store/reducers/allocationOrder/addAOReducer';
import moment from 'moment';
import { error, Position, success } from '../../../utils/toast';
import ShowForPermissions from '../../../ShowForPermissions';
import { MenuPlacement } from 'react-select';
import ExportButton from '../../../components/common/ExportButton';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { exportAoProductAllocationList } from '../../../services/apis/allocationOrder/productAllocationApi';
import RemoveSkuModal from './RemoveSkuModal';
import UnsavedChangeModal from './UnsavedChangeModal';
import { usePrompt } from 'src/hooks/usePrompt';
// import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
// import CancelModal from '../BasicDetail/cancelModal';
// import { useSelector } from 'react-redux';
// import { RootState } from 'src/store/reducers';
import AllocationOrderTabs from '../AllocationOrderTabs';
import AllocationOrderNextButton from '../AllocationOrderNextButton/AllocationOrderNextButton';

const Index: React.FC = () => {
    const scrollElement = useRef<any>(null);
    const { userName, profileType, fullName } = useUserProfile();
    const organisationData =
        localStorage.getItem('listOfUserOrganisations') != null &&
        JSON.parse(localStorage.getItem('listOfUserOrganisations') || '');
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [currentSelectedField, setCurrentSelectedField] = useState('');
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [customError, setCustomError] = useState(false);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>([]);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [removeSkuModalVisibility, setRemoveSkuModalVisibility] = useState(false);
    const [unsavedChangeModalVisibility, setUnsavedChangeModalVisibility] = useState(false);
    const [poProductId, setPoProductId] = useState('');
    const [search, setSearch] = useState('');
    const [searchKeywordDelete, setSearchKeywordDelete] = useState('');
    const [filterItem, setFilterItem] = useState('');
    const [filterSku, setFilterSku] = useState('');
    const [currentColumn, setcurrentColumn] = useState('');
    const [currentOrder, setcurrentOrder] = useState(false);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [showWarningDropdown, setShowWarningDropdown] = useState(false);
    const [showErrorDropdown, setShowErrorDropdown] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>('auto');
    const [cbmForEach, setCbmForEach] = useState(0);
    const [weightForEach, setWeightForEach] = useState(0);
    const [distanceFromTopForAddress, setDistanceFromTopForAddress] = useState(0);
    const [distanceFromLeftForAddress, setDistanceFromLeftForAddress] = useState(0);
    const [productsData, setProductsData] = useState<ProductAllocationDataDetails[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const isLoading = useAppSelector(selectProductAllocationLoading);
    const productAllocation = useAppSelector(selectProductAllocation);
    const sku = useAppSelector(selectSku);
    const allocationSku = useAppSelector(selectAllocationSku);
    const currency = useAppSelector(selectCurrency);
    const port = useAppSelector(selectPort);
    const destinationAddress = useAppSelector(selectDestinationAddress);
    const headerData = useAppSelector(selectHeader);
    const isError = useAppSelector(selectProductAllocationIsError);
    const productAllocationSuccess = useAppSelector(selectProductAllocationSuccess);
    const productAllocationError = useAppSelector(selectProductAllocationError);
    const allDistinctValues = useAppSelector(selectDistinctValue);
    const [distinctValues, setDistinctValues] = useState<any>([]);
    const [ports, setPorts] = useState<any>([]);
    const [callBackFn, setCallBackFn] = useState<any>();
    // const { previewData } = useSelector((state: RootState) => state.allocationOrder) as any;
    // const sortPayload = {
    //     sortOrder: currentOrder ? 'DESC' : 'ASC',
    //     sortColumn: currentColumn,
    // };
    useEffect(() => {
        dispatch(fetchProductAllocationList({ aoId: id }));
        // dispatch(fetchPort({ key: 'a', type: 'AO' }));
        dispatch(fetchCurrency({}));
        dispatch(
            fetchDistinctValues({
                aoId: id,
                sortColumn: currentColumn ? currentColumn : null,
                sortOrder: currentOrder,
                destinationPort: statusFilterSelected?.length ? statusFilterSelected : null,
            })
        );
    }, []);

    useEffect(() => {
        if (allDistinctValues?.length) {
            setDistinctValues(allDistinctValues);
        } else {
            setDistinctValues([]);
        }
    }, [allDistinctValues]);

    useEffect(() => {
        if (productAllocation?.productListAllocationOrderList?.length) {
            setProductsData(productAllocation?.productListAllocationOrderList);
            // dispatch(
            //     fetchDistinctValues({
            //         aoId: id,
            //         sortColumn: currentColumn ? currentColumn : null,
            //         sortOrder: currentOrder,
            //         destinationPort: statusFilterSelected?.length ? statusFilterSelected[0] : null,
            //     })
            // );
        } else {
            setProductsData([]);
        }
    }, [productAllocation?.productListAllocationOrderList]);

    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= scrollElement.current.scrollWidth;
    };

    const scrollDown = () => {
        const targetElm = document.querySelector('.grid-scroll-content');
        if (targetElm) {
            setTimeout(() => {
                targetElm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
            }, 100);
        }
    };

    const convertDataForSelect = (data: any, value: string, label: string) => {
        const convertedData = data?.map((option: any) => {
            return { value: option[value], label: option[label] };
        });
        return convertedData;
    };
    const getBalanceQty = (
        qty: number | string,
        availableToOrder: number,
        skuId: string,
        index: number,
        fullProduct?: any
    ) => {
        const data = fullProduct ? fullProduct : productsData;
        const totalQty = data.reduce(function (sum: number, record: any) {
            if (record.skuId === skuId && record.editMode) {
                return sum + +record.qty;
            } else {
                return sum;
            }
        }, 0);
        if (typeof qty === 'number' && typeof availableToOrder === 'number') {
            // const oldQtySum = data.reduce(function (sum: number, record: any) {
            //     if (record.skuId === skuId && record.editMode) {
            //         return sum + +record.originalQty;
            //     } else {
            //         return sum;
            //     }
            // }, 0);

            // if (typeof qty === 'number') {
            //     if (oldQtySum > totalQty) {
            //         const difference = oldQtySum - totalQty;
            //         return availableToOrder + difference;
            //     } else if (oldQtySum < totalQty) {
            //         const difference = totalQty - oldQtySum;
            //         return availableToOrder - difference;
            //     } else {
            //         return availableToOrder;
            //     }
            // } else {
            return availableToOrder - totalQty;
            // }
        } else {
            return '';
        }
    };

    const onPortSearch = (search: string) => {
        setPorts([]);
        if (search?.length > 2) {
            dispatch(fetchPort({ key: search, type: 'AO', service: 'ord' }));
        }
    };

    const checkIfIsInEditMode = () => {
        return productsData.some((e) => e.editMode);
    };

    const returnAllErrorAndWarning = (type: string) => {
        const data = productsData.filter((e: any) => {
            if (type === 'both') {
                return (e.hasWarning || e.hasError) && e.saveTriggered;
            } else if (type === 'hasWarning') {
                return e.hasWarning;
            } else {
                return e[type] && e.saveTriggered;
            }
        });
        const result = data.reduce((acc: any, item: any) => {
            if (!acc.find((other: any) => item.sku == other.sku)) {
                acc.push(item);
            }
            return acc;
        }, []);
        return result;
    };

    // const returnAllErrorAndWarningForIntegration = () => {
    //     let hasErrorAndWarning = false;
    //     for (let i = 0; i < productsData.length; i++) {
    //         if (
    //             productsData[i].qty > productsData[i].availableToOrder ||
    //             productsData[i].isSkuIdInvalid ||
    //             productsData[i]?.balanceQty < 0 ||
    //             checkIfIsReferenceLineUnique(productsData[i], i) >= 0 ||
    //             productsData[i].originPortName != `${headerData?.originPortName} (${headerData?.originPort})`
    //         ) {
    //             hasErrorAndWarning = true;
    //             break;
    //         }
    //     }
    //     return hasErrorAndWarning;
    // };

    useEffect(() => {
        if (headerData?.dataSourceName === 'ERP' && productAllocation?.productListAllocationOrderList?.length) {
            const newSku = JSON.parse(JSON.stringify(productAllocation?.productListAllocationOrderList));
            for (let i = 0; i < newSku.length; i++) {
                if (
                    newSku[i].qty > newSku[i].availableToOrder ||
                    newSku[i].isSkuIdInvalid ||
                    checkIfIsReferenceLineUnique(newSku[i], i) >= 0 ||
                    newSku[i].originPortName != `${headerData?.originPortName} (${headerData?.originPort})`
                ) {
                    newSku[i].hasError = true;
                    newSku[i].saveTriggered = true;
                }
                if (newSku[i]?.balanceQty < 0) {
                    newSku[i].hasWarning = true;
                    newSku[i].saveTriggered = true;
                }
            }
            setProductsData(newSku);
        }
    }, [headerData?.dataSourceName, productAllocation?.productListAllocationOrderList]);

    const checkIfIsReferenceLineUnique = (data: ProductAllocationDataDetails, index: number) => {
        if (data?.customerOrderReferenceNo && data?.customerOrderLineNo && productsData?.length > 1) {
            const result = productsData.findIndex(
                (e, i) =>
                    i !== index &&
                    e.customerOrderReferenceNo === data?.customerOrderReferenceNo &&
                    e.customerOrderLineNo === data?.customerOrderLineNo
            );
            return result;
        } else {
            return -1;
        }
    };

    const checkError = (data: any, index: number) => {
        data[index].saveTriggered = false;
        const errorProductIndex = checkIfIsReferenceLineUnique(data[index], index);
        const balanceQty = getBalanceQty(data[index].qty, data[index].availableToOrder, data[index].skuId, index, data);
        if (data[index].qty > data[index].availableToOrder || errorProductIndex >= 0) {
            if (errorProductIndex >= 0 && data[errorProductIndex].editMode) {
                data[errorProductIndex].hasError = true;
            }
            data[index].hasError = true;
        } else {
            data[index].hasError = false;
        }
        const allOtherProducts = productsData.filter(
            (e, i) =>
                i !== index &&
                e.customerOrderReferenceNo === productsData[index]?.customerOrderReferenceNo &&
                e.customerOrderLineNo === productsData[index]?.customerOrderLineNo
        );
        if (allOtherProducts?.length === 1) {
            const otherProductIndex = checkIfIsReferenceLineUnique(productsData[index], index);
            if (
                otherProductIndex >= 0 &&
                data[otherProductIndex].editMode &&
                data[otherProductIndex].qty < data[otherProductIndex].availableToOrder
            ) {
                data[otherProductIndex].hasError = false;
            }
        }
        const allProductsWithSameSKu = productsData.filter((e, i) => i !== index && e.skuId === data[index].skuId);

        if (typeof balanceQty === 'number' && balanceQty < 0) {
            // data[index].hasWarning = true;
            for (let i = 0; i < data.length; i++) {
                if (data[i].skuId === data[index].skuId) {
                    if (data[i].hasError && !allProductsWithSameSKu?.length) {
                        data[i].hasWarning = false;
                    } else {
                        data[i].hasWarning = true;
                    }
                }
            }
        } else {
            // data[index].hasWarning = false;
            for (let i = 0; i < data.length; i++) {
                if (data[i].skuId === data[index].skuId) {
                    data[i].hasWarning = false;
                }
            }
        }

        for (let i = 0; i < data.length; i++) {
            if (data[i].skuId === data[index].skuId) {
                if (balanceQty === '') {
                    const currentBalance = data?.find(
                        (res: any) => res.skuId === data[index].skuId && res.balanceQty != ''
                    );
                    if (currentBalance) {
                        data[i].balanceQty = currentBalance?.balanceQty;
                    } else {
                        data[i].balanceQty = data[i].availableToOrder;
                    }
                } else {
                    data[i].balanceQty = balanceQty;
                }
            }
        }

        setProductsData(data);
    };

    const resetIndexValue = (data: ProductAllocationDataDetails[]) => {
        for (let index = 0; index < data.length; index++) {
            data[index].indexValue = index;
        }

        if (!data?.length) {
            dispatch(
                fetchAllocationSkuList({
                    clientCode: headerData?.clientCode,
                    blpAgentCode: headerData?.blpAgentCode,
                    allocationOrderId: id,
                })
            );
        }
        setProductsData(data);
    };

    const updateDataAfterSave = (type: string, skuId: string, qty: number, indexValue: number) => {
        const newSku = JSON.parse(JSON.stringify(productsData));
        if (type === 'fromAdd') {
            for (let index = 0; index < newSku.length; index++) {
                if (newSku[index].skuId === skuId) {
                    newSku[index].availableToOrder = newSku[index].availableToOrder - qty;
                    if (newSku[index].editMode && newSku[index].qty > newSku[index].availableToOrder) {
                        newSku[index].hasError = true;
                        newSku[index].hasWarning = false;
                    } else {
                        newSku[index].hasError = false;
                    }
                }
            }
            newSku[indexValue].poProductId = productAllocationSuccess?.data?.poProductId;
            newSku[indexValue].saveTriggered = true;
            setProductsData(newSku);
        }
        if (type === 'fromUpdate') {
            newSku[indexValue].saveTriggered = true;
            // const availableToOrder: number;
            // if (newSku[indexValue].originalQty > newSku[indexValue].qty) {
            //     const difference = newSku[indexValue].originalQty - newSku[indexValue].qty;
            //     availableToOrder = newSku[indexValue].availableToOrder + difference;
            // } else if (newSku[indexValue].originalQty < newSku[indexValue].qty) {
            //     const difference = newSku[indexValue].qty - newSku[indexValue].originalQty;
            //     availableToOrder = newSku[indexValue].availableToOrder - difference;
            // } else {
            // const availableToOrder = newSku[indexValue].balanceQty;
            // }
            for (let index = 0; index < newSku.length; index++) {
                if (newSku[index].skuId === skuId) {
                    newSku[index].availableToOrder = newSku[index].availableToOrder - newSku[indexValue].qty;
                    if (newSku[index].editMode && newSku[index].qty > newSku[index].availableToOrder) {
                        newSku[index].hasError = true;
                        newSku[index].hasWarning = false;
                    } else {
                        newSku[index].hasError = false;
                    }
                }
            }
            setProductsData(newSku);
        }
        if (type === 'fromDelete') {
            const deletedIndex = newSku.findIndex(
                (res: ProductAllocationDataDetails) => res.poProductId === poProductId
            );

            if (deletedIndex >= 0) {
                const deletedQty = newSku[deletedIndex].qty;
                const deletedSku = newSku[deletedIndex].sku;
                newSku.splice(deletedIndex, 1);
                for (let index = 0; index < newSku.length; index++) {
                    if (newSku[index].sku === deletedSku) {
                        newSku[index].availableToOrder += deletedQty;
                        newSku[index].balanceQty += deletedQty;
                        if (newSku[index].editMode && newSku[index].qty > newSku[index].availableToOrder) {
                            newSku[index].hasError = true;
                            newSku[index].hasWarning = false;
                        } else {
                            newSku[index].hasError = false;
                            newSku[index].hasWarning = false;
                        }
                    }
                }
                resetIndexValue(newSku);
            }
        }
        if (!productsData.some((e) => e.editMode)) {
            dispatch(fetchProductAllocationList({ aoId: id }));
            dispatch(
                fetchAllocationSkuList({
                    clientCode: headerData?.clientCode,
                    blpAgentCode: headerData?.blpAgentCode,
                    allocationOrderId: id,
                })
            );
        }
    };

    useEffect(() => {
        if (port?.length) {
            const initial = [...ports, ...port];
            const result = initial.reduce((acc: any, item: any) => {
                if (!acc.find((other: any) => item.value == other.value)) {
                    acc.push(item);
                }
                return acc;
            }, []);
            setPorts(result);
        }
    }, [port]);

    useEffect(() => {
        headerData?.clientCode && dispatch(fetchSkuList({ clientCode: headerData?.clientCode }));
        headerData?.clientCode && dispatch(fetchDestinationAddress({ orgCode: headerData?.clientCode }));
        if (headerData?.clientCode && headerData?.blpAgentCode) {
            dispatch(
                fetchAllocationSkuList({
                    clientCode: headerData?.clientCode,
                    blpAgentCode: headerData?.blpAgentCode,
                    allocationOrderId: id,
                })
            );
        }
    }, [headerData?.clientCode]);

    useEffect(() => {
        if (isError) {
            error(productAllocationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (productAllocationSuccess?.status === 200 || productAllocationSuccess?.status === 201) {
            if (productAllocationSuccess?.data?.type === 'create') {
                success(
                    `SKU Line ${
                        productAllocationSuccess?.data?.info?.poLineNumber
                            ? productAllocationSuccess?.data?.info?.poLineNumber
                            : ''
                    } created successfully`,
                    Position.TOP_RIGHT
                );
                updateDataAfterSave(
                    'fromAdd',
                    productAllocationSuccess?.data?.skuId,
                    productAllocationSuccess?.data?.qty,
                    productAllocationSuccess?.data?.indexValue
                );
            } else if (productAllocationSuccess?.data?.type === 'update') {
                success(
                    `SKU Line ${
                        productAllocationSuccess?.data?.info?.poLineNumber
                            ? productAllocationSuccess?.data?.info?.poLineNumber
                            : ''
                    } updated successfully`,
                    Position.TOP_RIGHT
                );
                updateDataAfterSave(
                    'fromUpdate',
                    productAllocationSuccess?.data?.info?.skuId,
                    productAllocationSuccess?.data?.info?.skuId,
                    productAllocationSuccess?.data?.indexValue
                );
            } else if (productAllocationSuccess?.data?.type === 'delete') {
                success(
                    `SKU Line ${
                        productAllocationSuccess?.data?.info?.poLineNumber
                            ? productAllocationSuccess?.data?.info?.poLineNumber
                            : ''
                    } removed successfully`,
                    Position.TOP_RIGHT
                );
                updateDataAfterSave(
                    'fromDelete',
                    productAllocationSuccess?.data?.skuId,
                    productAllocationSuccess?.data?.qty,
                    productAllocationSuccess?.data?.indexValue
                );
            }
            dispatch(
                fetchDistinctValues({
                    aoId: id,
                    sortColumn: currentColumn ? currentColumn : null,
                    sortOrder: currentOrder,
                    // destinationPort: statusFilterSelected?.length ? statusFilterSelected[0] : null,
                    destinationPort: null,
                })
            );

            dispatch(
                fetchHeader({
                    aoId: id,
                })
            );
            scrollLeft();
            dispatch(clearFailure([]));
        }
    }, [isError, productAllocationSuccess, productAllocationError]);

    const customStyle = {
        menuPortal: (provided: any) => ({
            ...provided,
            width: '200px',
        }),
        menuList: (base: any) => ({
            ...base,
            '::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: '#F6F6FE',
            },
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(#000, 0.3)',
                backgroundColor: '#C4C6D0',
            },
        }),
    };

    const portalStyle = {
        ...customStyle,
        noOptionsMessage: (base: any) => ({ ...base, height: '180px' }),
    };

    const skuUpdate = (skuId: string, skuName: string, index: number) => {
        const newSku = JSON.parse(JSON.stringify(productsData));
        if (skuName == undefined || (newSku[index].sku != '' && newSku[index].sku != skuName)) {
            for (let i = 0; i < newSku.length; i++) {
                if (newSku[i].skuId === newSku[index].skuId && newSku[index].qty > 0) {
                    newSku[i].balanceQty += newSku[index].qty;
                }
            }
        }

        if (skuId && skuName) {
            const skuDetail: any = sku?.find((res: any) => res.pm_PK === skuId);
            const allocationSkuDetail: any = allocationSku?.find((res: any) => res.skuId === skuId);
            newSku[index].sku = skuName;
            newSku[index].skuId = skuId;
            if (skuDetail && allocationSkuDetail) {
                newSku[index].description = skuDetail.pm_description;
                const existingAvailableToOrder = newSku?.find(
                    (res: any, i: number) => res.skuId === skuId && res.editMode && i !== index
                )?.availableToOrder;

                if (existingAvailableToOrder) {
                    newSku[index].availableToOrder = existingAvailableToOrder;
                } else {
                    newSku[index].availableToOrder = allocationSkuDetail.availableToOrder;
                }
                newSku[index].currency = allocationSkuDetail.currencyCode;
                if (allocationSkuDetail.originPortName) {
                    newSku[
                        index
                    ].originPortName = `${allocationSkuDetail.originPortName} (${allocationSkuDetail.originPortCode})`;
                }
                if (allocationSkuDetail.destinationPortName) {
                    newSku[
                        index
                    ].destinationPortName = `${allocationSkuDetail.destinationPortName} (${allocationSkuDetail.destinationPortCode})`;
                }
                newSku[index].destinationAddressIdInProduct = allocationSkuDetail.destinationAddressIdInProduct;
                newSku[index].destinationAddress = allocationSkuDetail.destinationAddress;
                newSku[index].projWhouseETA = allocationSkuDetail.projWhouseETA;
                const pmPack = skuDetail?.pm_packets?.find((res: any) => res.pp_quantity?.toLowerCase() === 'each');
                if (pmPack) {
                    if (pmPack?.pp_cbm > 0) {
                        setCbmForEach(pmPack?.pp_cbm);
                        if (newSku[index].qty != '') {
                            newSku[index].cbm = +pmPack?.pp_cbm * +newSku[index].qty;
                        }
                    }
                    if (pmPack?.pp_weight > 0) {
                        setWeightForEach(pmPack?.pp_weight);
                        if (newSku[index].qty != '') {
                            newSku[index].weight = +pmPack?.pp_weight * +newSku[index].qty;
                        }
                    }
                }
            }
            newSku[index].balanceQty = '';
            checkError(newSku, index);
            const initial = [
                ...ports,
                // { label: newSku[index].destinationPortName, value: newSku[index].destinationPortName },
            ];
            if (newSku[index]?.destinationPortName && !newSku[index]?.destinationPortName?.includes('(null)')) {
                initial.push({ label: newSku[index].destinationPortName, value: newSku[index].destinationPortName });
            }
            const result = initial.reduce((acc: any, item: any) => {
                if (!acc.find((other: any) => item.value == other.value)) {
                    acc.push(item);
                }

                return acc;
            }, []);
            setPorts(result);
        } else {
            newSku[index].sku = '';
            newSku[index].skuId = '';
            newSku[index].description = '';
            newSku[index].cbm = '';
            newSku[index].availableToOrder = '';
            newSku[index].destinationPortName = '';
            newSku[index].originPortName = '';
            newSku[index].destinationAddress = '';
            newSku[index].destinationAddressIdInProduct = '';
            newSku[index].balanceQty = '';
            newSku[index].projWhouseETA = '';
            newSku[index].currency = '';
            setProductsData(newSku);
        }
    };

    const resizeFilter = (idSelector: string) => {
        const el_table = document.getElementById('table_div');
        let gridDistance = 0;
        let gridDistanceLeft = 0;
        if (el_table?.getBoundingClientRect().top) {
            gridDistance = el_table?.getBoundingClientRect().top - 40;
            gridDistanceLeft = el_table?.getBoundingClientRect().left + 4;
        }

        const el_address = document.getElementById(idSelector);
        const dropdown_address_bottom = document.getElementById(idSelector)?.getBoundingClientRect().bottom || 0;
        const spaceBelow = window.innerHeight - dropdown_address_bottom;

        if (el_address?.getBoundingClientRect().top) {
            if (spaceBelow < 150) {
                gridDistance = gridDistance + 175;
            }
            setDistanceFromTopForAddress(el_address?.getBoundingClientRect().top - gridDistance);
        }
        if (el_address?.getBoundingClientRect().left) {
            setDistanceFromLeftForAddress(el_address?.getBoundingClientRect().left - gridDistanceLeft);
        }
    };

    const menuPlacementCheck = (index: number) => {
        const windowHeight = document.documentElement.clientHeight;
        const noMessageHeight = 200;

        const element = document.getElementById(`edit_row_${index}`);
        if (element) {
            const bottom = element.getBoundingClientRect().bottom;
            const spaceBelow = windowHeight - bottom;
            spaceBelow < noMessageHeight ? setMenuPlacement('top') : setMenuPlacement('auto');
        }
    };

    const addSkuLine = () => {
        scrollLeft();
        scrollDown();
        const newSku = JSON.parse(JSON.stringify(productAllocationDataDetailsInitial));
        setProductsData([...productsData, { ...newSku, allocationOrderId: id, indexValue: productsData?.length }]);
        menuPlacementCheck(newSku?.length + 1);
    };

    const editSkuLine = (index: number) => {
        const newSku = JSON.parse(JSON.stringify(productsData));
        newSku[index].editMode = true;
        newSku[index].originalQty = newSku[index].qty;
        newSku[index].availableToOrder += newSku[index].qty;
        for (let i = 0; i < newSku.length; i++) {
            if (newSku[i].skuId === newSku[index].skuId) {
                newSku[i].availableToOrder = newSku[index].availableToOrder;
            }
        }
        const isReferenceLineUnique = checkIfIsReferenceLineUnique(newSku[index], index);
        if (isReferenceLineUnique >= 0 || newSku[index].qty > newSku[index].availableToOrder) {
            newSku[index].hasError = true;
            newSku[index].hasWarning = false;
        }
        setProductsData(newSku);
        menuPlacementCheck(index);
        const initial = [
            ...ports,
            // { label: newSku[index].destinationPortName, value: newSku[index].destinationPortName },
        ];
        if (!newSku[index]?.destinationPortName?.includes('(null)')) {
            initial.push({ label: newSku[index].destinationPortName, value: newSku[index].destinationPortName });
        }
        const result = initial.reduce((acc: any, item: any) => {
            if (!acc.find((other: any) => item.value == other.value)) {
                acc.push(item);
            }

            return acc;
        }, []);
        setPorts(result);
    };

    const duplicateSkuLine = (index: number) => {
        scrollLeft();
        const newSku = JSON.parse(JSON.stringify(productsData));
        newSku[index].poProductId = '';
        newSku[index].editMode = true;
        newSku[index].hasError = false;
        newSku[index].hasWarning = false;
        newSku[index].saveTriggered = false;
        newSku[index].validationError = false;
        newSku[index].allocationOrderId = id;
        newSku[index].originalQty = '';
        newSku[index].customerOrderLineNo = '';
        newSku[index].qty = '';
        newSku[index].destinationPortName = '';
        newSku[index].destinationAddress = '';
        newSku[index].destinationAddressIdInProduct = '';
        newSku[index].unitCost = '';
        newSku[index].cbm = '';
        newSku[index].netCost = '';
        newSku[index].indexValue = newSku?.length;
        setProductsData([...productsData, newSku[index]]);
        menuPlacementCheck(index);
    };

    const scrollToError = (element: string) => {
        const targetElm = document.querySelector(`.${element}`);
        if (targetElm) {
            targetElm.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
        return false;
    };

    const checkSaveButtonStatus = (productData: ProductAllocationDataDetails) => {
        if (!productData.skuId) {
            return false;
        } else if (!productData.customerOrderReferenceNo) {
            scrollToError('allocation-reference-no');
        } else if (!productData.customerOrderLineNo) {
            scrollToError('allocation-line-no');
        } else if (!productData.qty) {
            scrollToError('allocation-qty-td');
        } else if (!productData.destinationPortName) {
            scrollToError('allocation-destination-td');
        } else if (!productData.destinationAddressIdInProduct) {
            scrollToError('allocation-destination-address-td');
        } else if (!productData.projWhouseETA) {
            scrollToError('allocation-projected-eta-td');
        } else if (!productData.currency) {
            scrollToError('allocation-currency-td');
        } else if (!productData.unitCost) {
            scrollToError('allocation-cost-td');
        } else {
            return true;
        }
    };

    const showError = (field: string, productData: ProductAllocationDataDetails) => {
        return (
            <>
                {(() => {
                    switch (field) {
                        case 'skuId':
                            return <>{!productData.skuId && <div className="error-text">SKU is required</div>}</>;
                        case 'customerOrderReferenceNo':
                            return (
                                <>
                                    {!productData.customerOrderReferenceNo && (
                                        <div className="error-text">Customer Order Reference No. is required</div>
                                    )}
                                </>
                            );
                        case 'customerOrderLineNo':
                            return (
                                <>
                                    {!productData.customerOrderLineNo && (
                                        <div className="error-text">Customer Order Line No. is required</div>
                                    )}
                                </>
                            );
                        case 'qty':
                            return (
                                <>
                                    {typeof productData.qty === 'number' && productData.qty > 0 ? null : (
                                        <div className="error-text">
                                            Allocation Quantity{' '}
                                            {productData.qty === 0 ? 'cannot be zero' : 'is required'}
                                        </div>
                                    )}
                                </>
                            );
                        case 'qtyZero':
                            return (
                                <>
                                    {typeof productData.qty === 'number' && productData.qty === 0 && customError ? (
                                        <div className="error-text">Allocation Quantity cannot be zero</div>
                                    ) : null}
                                </>
                            );
                        case 'destinationPortName':
                            return (
                                <>
                                    {!productData.destinationPortName && (
                                        <div className="error-text">Destination is required</div>
                                    )}
                                </>
                            );
                        case 'destinationAddressIdInProduct':
                            return (
                                <>
                                    {!productData.destinationAddressIdInProduct && (
                                        <div className="error-text">Destination Address is required</div>
                                    )}
                                </>
                            );
                        case 'projWhouseETA':
                            return (
                                <>
                                    {!productData.projWhouseETA && (
                                        <div className="error-text">Prof. ETA into Whouse is required</div>
                                    )}
                                </>
                            );
                        case 'currency':
                            return (
                                <>{!productData.currency && <div className="error-text">Currency is required</div>}</>
                            );
                        case 'unitCost':
                            return (
                                <>
                                    {typeof productData.unitCost === 'number' && productData.unitCost > 0 ? null : (
                                        <div className="error-text">
                                            Unit Cost {productData.unitCost === 0 ? 'cannot be zero' : 'is required'}
                                        </div>
                                    )}
                                </>
                            );
                        case 'unitCostZero':
                            return (
                                <>
                                    {typeof productData.unitCost === 'number' &&
                                    productData.unitCost === 0 &&
                                    customError ? (
                                        <div className="error-text">Unit Cost cannot be zero</div>
                                    ) : null}
                                </>
                            );

                        default:
                            return null;
                    }
                })()}
            </>
        );
    };

    const saveSku = (data: ProductAllocationDataDetails, index: number) => {
        const newSku = JSON.parse(JSON.stringify(productsData));
        if (checkSaveButtonStatus(data)) {
            if (data.hasError) {
                newSku[index].saveTriggered = true;
                setProductsData(newSku);
            } else {
                delete data.editMode;
                const totalQty = newSku.reduce(function (sum: number, record: any) {
                    if (record.skuId === data?.skuId && record.editMode === false) {
                        return sum + +record.qty;
                    } else {
                        return sum;
                    }
                }, 0);

                if (data.poProductId) {
                    dispatch(
                        updateProductAllocation({
                            ...data,
                            projWhouseETA: moment(data.projWhouseETA).format('DD-MM-YYYY'),
                            fullName,
                            userName,
                            allSavedQty: totalQty + data?.qty,
                        })
                    );
                } else {
                    dispatch(
                        createProductAllocation({
                            ...data,
                            projWhouseETA: moment(data.projWhouseETA).format('DD-MM-YYYY'),
                            fullName,
                            userName,
                        })
                    );
                }
            }
        } else {
            newSku[index].validationError = true;
            setProductsData(newSku);
        }
    };

    const removeSku = (poProductId: string, index: number) => {
        if (poProductId) {
            setRemoveSkuModalVisibility(true);
            setPoProductId(poProductId);
            // dispatch(removeProductAllocation({ poProductId: poProductId }));
        } else {
            const newSku = JSON.parse(JSON.stringify(productsData));
            const deletedQty = newSku[index].qty;
            const deletedSku = newSku[index].sku;
            for (let i = 0; i < newSku.length; i++) {
                if (newSku[i].sku === deletedSku) {
                    newSku[i].balanceQty += deletedQty;
                }
            }
            newSku.splice(index, 1);
            for (let i = 0; i < newSku?.length; i++) {
                newSku[i].indexValue = i;
                if (newSku[i].qty <= newSku[i].availableToOrder) {
                    newSku[i].hasWarning = false;
                }
            }
            if (!newSku?.length) {
                dispatch(fetchProductAllocationList({ aoId: id }));
                dispatch(
                    fetchAllocationSkuList({
                        clientCode: headerData?.clientCode,
                        blpAgentCode: headerData?.blpAgentCode,
                        allocationOrderId: id,
                    })
                );
            }
            setProductsData(newSku);
        }
    };

    const updateSkuData = (data: any, index: number, field: string) => {
        setCurrentIndex(index);
        const newSku = JSON.parse(JSON.stringify(productsData));
        if (field === 'skuId') {
            skuUpdate(data?.value, data?.label, index);
        } else if (field === 'destinationPortName') {
            newSku[index].destinationPortName = data?.value;
        } else if (field === 'currency') {
            newSku[index].currency = data?.value;
        } else if (field === 'destinationAddress') {
            newSku[index].destinationAddressIdInProduct = data?.oa_PK ? data.oa_PK : data.addressId;
            newSku[index].destinationAddress = data.fullAddress;
        } else if (field === 'qty') {
            newSku[index].cbm = +cbmForEach * +data;
            newSku[index].netCost = +newSku[index].unitCost * +data;
            newSku[index].weight = +weightForEach * +data;
            newSku[index].qty = +data;
        } else if (field === 'unitCost') {
            if (data >= 0) {
                const netCost = +newSku[index].qty * +parseFloat(data);
                newSku[index].netCost = parseFloat(netCost.toFixed(2));
                newSku[index].unitCost = +data;
            } else {
                newSku[index].unitCost = 0;
                newSku[index].netCost = 0;
            }
        } else {
            newSku[index][field] = data;
        }
        if (field !== 'skuId') {
            // setProductsData(newSku);
            checkError(newSku, index);
        }
    };

    const searchOrFilterFn = (productsData: ProductAllocationDataDetails[]) => {
        if (searchKeywordDelete || search.trim().length > 2 || filterSku != '' || filterItem != '') {
            const searchKey = searchKeywordDelete.toLocaleLowerCase();
            const searchResult = productsData?.filter((el: any) => {
                const searchCondition = searchKey ? el.sku.toLocaleLowerCase().includes(searchKey) : true;
                if (filterItem === 'error') {
                    const errorCondition = filterSku
                        ? filterItem === 'error' && el.hasError && el.sku === filterSku
                        : filterItem === 'error' && el.hasError;
                    return searchCondition && errorCondition;
                } else if (filterItem === 'warning') {
                    const warningCondition = filterSku
                        ? filterItem === 'warning' && el.hasWarning && el.sku === filterSku
                        : filterItem === 'warning' && el.hasWarning;
                    return searchCondition && warningCondition;
                } else {
                    return searchCondition;
                }
            });
            if (searchResult?.length === 0 && filterItem && searchKey === '') {
                setFilterItem('');
                setFilterSku('');
            }
            return searchResult;
        } else {
            return productsData;
        }
    };

    const searchOrFilter = searchOrFilterFn(productsData);

    useEffect(() => {
        if (search.trim().length === 0) {
            setSearchKeywordDelete('');
        } else if (search.trim().length > 2) {
            setSearchKeywordDelete(search);
        }
    }, [search]);

    const filterByColumn = (e: any) => {
        setToggleDropdown(!toggleDropdown);
        e.stopPropagation();
    };

    const resizeCheckboxFilter = () => {
        const el_filter_div = document.getElementById('filter_div');
        if (el_filter_div?.getBoundingClientRect().left) {
            setSetPosition(el_filter_div?.getBoundingClientRect().left - 35);
        }
    };

    const filterCheckbox = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        if (keyword) {
            const results = allDistinctValues.filter((user: string) => {
                return user && user.toLowerCase().includes(keyword.toLowerCase());
            });
            setDistinctValues(results);
        } else {
            setDistinctValues(allDistinctValues);
        }
        setSearchName(keyword);
    };

    useEffect(() => {
        if (statusFilterSelected?.length) {
            // const destinationPort = statusFilterSelected[0];
            dispatch(
                fetchProductAllocationList({
                    aoId: id,
                    sortColumn: currentColumn ? currentColumn : null,
                    sortOrder: currentOrder,
                    destinationPort: statusFilterSelected,
                })
            );
            dispatch(
                fetchDistinctValues({
                    aoId: id,
                    sortColumn: currentColumn ? currentColumn : null,
                    sortOrder: currentOrder,
                    destinationPort: null,
                })
            );
        }
    }, [statusFilterSelected]);

    const getAllSkuCount = () => {
        if (search?.trim()) {
            const result = searchOrFilter.reduce((acc: any, item: any) => {
                if (!acc.find((other: any) => item.sku === other.sku)) {
                    acc.push(item);
                }
                return acc;
            }, []);
            return result?.length;
        } else {
            const result = productsData.reduce((acc: any, item: any) => {
                if (!acc.find((other: any) => item.sku === other.sku)) {
                    if (item?.saveTriggered != false) {
                        acc.push(item);
                    }
                }
                return acc;
            }, []);
            return result?.length;
        }
    };

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">No matches found in the Inventory</p>
            </div>
        );
    };

    // const onConfirm = () => {
    //     setUnsavedChangeModalVisibility(false);
    //     setProductsData(productAllocation?.productListAllocationOrderList);
    // };

    usePrompt('Leave screen?', checkIfIsInEditMode(), setUnsavedChangeModalVisibility, setCallBackFn);
    // useBrowserBackCheck(checkIfIsInEditMode(), () => setUnsavedChangeModalVisibility(true));

    const isAuPortInclude = headerData?.isMultiAddress && headerData?.includesAUPortDestination;

    const formatedPorts = isAuPortInclude ? ports : ports?.filter((p: any) => p.country !== 'AU');

    const handleSubmit = () => {
        return checkIfIsInEditMode();
    };

    return (
        <div
            onClick={() => {
                setShowAddress(false);
                setShowWarningDropdown(false);
                setShowErrorDropdown(false);
            }}
            className="main-wrapper container"
        >
            <div className="main-header-content-holder large-header">
                <AOBreadCrumb disabled={checkIfIsInEditMode()} callbackFn={handleSubmit} />
                {/* <Breadcrumbs
                    firstName="Allocation Orders"
                    firstUrl="/allocation_orders"
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New AO' : 'Create New Allocation Order'}
                    secondUrl={`/allocation_orders/${id}/dates`}
                /> */}
                <AllocationOrderHeader id={id} />
                <AllocationOrderTabs />
            </div>
            <div className="content-section main-wrapper product-allocation-wrapper">
                {isLoading > 0 && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-header-notification">
                    <div className="search-dropdown-fields">
                        <div className="search-entry-holder">
                            <input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search by SKU"
                                type="text"
                                className="search-input input-text"
                            ></input>
                            <div
                                className={`search-toolip ${
                                    search?.length > 0 && search?.length < 3 ? 'tooltip-show' : ''
                                }`}
                            >
                                Enter atleast 3 characters to search
                            </div>
                            <svg className="svg-icon search-icon">
                                <use xlinkHref="#searchIcon"></use>
                            </svg>
                            <button
                                onClick={() => {
                                    setSearch('');
                                }}
                                type="button"
                                className="app-btn text-close-btn"
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </div>
                        <div className="search-fields-ao-list">
                            <div className={`header-btn ${filterItem === '' ? 'active' : ''}`}>
                                <button
                                    onClick={() => {
                                        setFilterItem('');
                                        setFilterSku('');
                                    }}
                                    type="button"
                                    className="app-btn app-btn-secondary"
                                    title="All SKU"
                                >
                                    <span className="button-text">All SKU:</span>
                                    <span className="count">{getAllSkuCount()}</span>
                                </button>
                            </div>

                            {(() => {
                                const warning = returnAllErrorAndWarning('hasWarning');
                                if (warning?.length) {
                                    return (
                                        <div
                                            className={`dropdown-wrap modal-dropdown" ${
                                                filterItem === 'warning' ? 'active' : ''
                                            } ${
                                                showWarningDropdown && warning?.length > 1 ? 'dropdown-open' : ''
                                            } search-toast-content-holder align-right warning-dropdown`}
                                        >
                                            <button
                                                onClick={(e) => {
                                                    if (filterItem != 'warning') {
                                                        setFilterSku('');
                                                    }
                                                    setFilterItem('warning');
                                                    e.stopPropagation();
                                                    setShowWarningDropdown((prev: boolean) => !prev);
                                                    setShowErrorDropdown(false);
                                                }}
                                                type="button"
                                                className="app-btn app-btn-secondary menu-btn"
                                            >
                                                <div className="purchase-dropdown-content">
                                                    <span>
                                                        <svg className="svg-icon alert-icon">
                                                            <use xlinkHref="#alertIcon"></use>
                                                        </svg>
                                                    </span>

                                                    <span className="button-text">Warning :</span>
                                                    {warning?.length === 1 ? (
                                                        <div className="content-heading-holder">
                                                            <span className="content-heading">{warning[0].sku}</span>
                                                        </div>
                                                    ) : (
                                                        <div className="content-heading-holder">
                                                            {filterItem === 'warning' && (
                                                                <span className="content-heading">{filterSku}</span>
                                                            )}
                                                            {filterSku === '' && (
                                                                <span className=" modal-toast">
                                                                    <div className="toast-message ">
                                                                        {warning?.length}
                                                                    </div>
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                {warning?.length > 1 && (
                                                    <svg className="svg-icon arrow-icon">
                                                        <use href="#downArrow">
                                                            <title>Expand row</title>
                                                        </use>
                                                    </svg>
                                                )}
                                            </button>
                                            <div className="dropdown-menu dropdown-wrap">
                                                <a
                                                    onClick={() => setFilterSku('')}
                                                    className={`menu-item app-btn ${filterSku === '' ? 'active' : ''}`}
                                                >
                                                    <div className="content-heading-holder">
                                                        <span className="content-heading">All Warnings</span>
                                                        <span className=" modal-toast sm">
                                                            <div className="toast-message ">{warning?.length}</div>
                                                        </span>
                                                    </div>
                                                </a>
                                                {warning?.map((res: any, index: number) => (
                                                    <a
                                                        onClick={() => setFilterSku(res.sku)}
                                                        style={{ borderTop: '0px none' }}
                                                        key={index}
                                                        className={`menu-item app-btn ${
                                                            filterSku === res.sku ? 'active' : ''
                                                        }`}
                                                    >
                                                        <div className="content-heading-holder">
                                                            <span className="content-heading">{res.sku}</span>
                                                        </div>
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                }
                            })()}
                            {(() => {
                                const error = returnAllErrorAndWarning('hasError');
                                if (error?.length) {
                                    return (
                                        <div
                                            className={`dropdown-wrap modal-dropdown" ${
                                                filterItem === 'error' ? 'active' : ''
                                            } ${
                                                showErrorDropdown && error?.length > 1 ? 'dropdown-open' : ''
                                            } search-toast-content-holder align-right error-dropdown`}
                                        >
                                            <button
                                                onClick={(e) => {
                                                    if (filterItem != 'error') {
                                                        setFilterSku('');
                                                    }
                                                    setFilterItem('error');
                                                    e.stopPropagation();
                                                    setShowErrorDropdown((prev: boolean) => !prev);
                                                    setShowWarningDropdown(false);
                                                }}
                                                type="button"
                                                className="app-btn app-btn-secondary menu-btn"
                                            >
                                                <div className="purchase-dropdown-content">
                                                    <span>
                                                        <svg className="svg-icon error-icon">
                                                            <use xlinkHref="#errorIcon"></use>
                                                        </svg>
                                                    </span>

                                                    <span className="button-text">Error :</span>
                                                    {error?.length === 1 ? (
                                                        <div className="content-heading-holder">
                                                            <span className="content-heading">{error[0].sku}</span>
                                                        </div>
                                                    ) : (
                                                        <div className="content-heading-holder">
                                                            {filterItem === 'error' && (
                                                                <span className="content-heading">{filterSku}</span>
                                                            )}
                                                            {filterSku === '' && (
                                                                <span className=" modal-toast">
                                                                    <div className="toast-message ">
                                                                        {error?.length}
                                                                    </div>
                                                                </span>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                {error?.length > 1 && (
                                                    <svg className="svg-icon arrow-icon">
                                                        <use href="#downArrow">
                                                            <title>Expand row</title>
                                                        </use>
                                                    </svg>
                                                )}
                                            </button>
                                            <div className="dropdown-menu dropdown-wrap">
                                                <a
                                                    onClick={() => setFilterSku('')}
                                                    className={`menu-item app-btn ${filterSku === '' ? 'active' : ''}`}
                                                >
                                                    <div className="content-heading-holder">
                                                        <span className="content-heading">All Errors</span>
                                                        <span className=" modal-toast sm">
                                                            <div className="toast-message ">{error?.length}</div>
                                                        </span>
                                                    </div>
                                                </a>
                                                {error?.map((res: any, index: number) => (
                                                    <a
                                                        onClick={() => setFilterSku(res.sku)}
                                                        style={{ borderTop: '0px none' }}
                                                        key={index}
                                                        className={`menu-item app-btn ${
                                                            filterSku === res.sku ? 'active' : ''
                                                        }`}
                                                    >
                                                        <div className="content-heading-holder">
                                                            <span className="content-heading">{res.sku}</span>
                                                        </div>
                                                    </a>
                                                ))}
                                            </div>
                                        </div>
                                    );
                                }
                            })()}
                        </div>
                    </div>
                    <div className="add-btn-purchase">
                        <ExportButton
                            organisationData={{
                                ...organisationData,
                                loggedInUserName: userName,
                            }}
                            disabled={
                                searchOrFilterFn(productsData).length === 0 || checkIfIsInEditMode() ? true : false
                            }
                            showExportDropDown={showExportDropDown}
                            setShowExportDropDown={setShowExportDropDown}
                            exportListWithBody={exportAoProductAllocationList}
                            extraFilter={{
                                aoId: id,
                                skuSearchKeyword:
                                    searchKeywordDelete || search.trim().length > 2 ? searchKeywordDelete : undefined,
                                destinationPort: statusFilterSelected.length ? statusFilterSelected : null,
                                filter: null,
                                sortOrder: currentOrder ? 'DESC' : 'ASC',
                                sortColumn: currentColumn ? currentColumn : undefined,
                            }}
                        />
                        <ShowForPermissions permission="create" type="ao">
                            {(profileType === 'client' || profileType === 'forwarder') &&
                                headerData?.dataSourceName != 'ERP' &&
                                headerData?.aoStatus === 'Draft' && (
                                    // headerData?.aoStatus === 'Pending_with_Forwarder') && (
                                    <button
                                        type="button"
                                        onClick={() => addSkuLine()}
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add SKU"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use href="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Add SKU</span>
                                    </button>
                                )}
                        </ShowForPermissions>
                    </div>
                </div>
                <div className="grid-section product-allocation-grid">
                    <div id="table_div" className="grid-holder">
                        {/* <div className="grid-header">
                            <div className="add-btn-users"></div>
                        </div> */}
                        <div
                            ref={scrollElement}
                            onScroll={() => {
                                resizeCheckboxFilter();
                            }}
                            onClick={() => setToggleDropdown(false)}
                            className="grid-container"
                        >
                            {!searchOrFilter?.length && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">
                                        {search.trim().length || filterSku != '' || filterItem != ''
                                            ? 'No AO Lines found'
                                            : 'No AO Lines added'}
                                    </p>
                                </div>
                            )}

                            <div className="grid-scroll-content">
                                <form id="my-form" action="">
                                    <table className="grid-table">
                                        <thead>
                                            <tr className="grid-sticky-row">
                                                <th className="grid-cell-header  grid-sticky-column sticky-left-aligned col-ao-sku">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">SKU</span>
                                                        {headerData?.aoStatus?.toLowerCase() !== 'ready_for_booking' &&
                                                            headerData?.aoStatus !== 'Booked' && (
                                                                <button
                                                                    onClick={() => {
                                                                        setcurrentOrder(!currentOrder);
                                                                        setcurrentColumn('sku');
                                                                        dispatch(
                                                                            fetchProductAllocationList({
                                                                                aoId: id,
                                                                                sortColumn: 'sku',
                                                                                sortOrder: !currentOrder,
                                                                                destinationPort:
                                                                                    statusFilterSelected.length
                                                                                        ? statusFilterSelected
                                                                                        : null,
                                                                            })
                                                                        );
                                                                    }}
                                                                    disabled={checkIfIsInEditMode()}
                                                                    type="button"
                                                                    className="sort-btn app-btn"
                                                                >
                                                                    <svg
                                                                        className={
                                                                            currentColumn === 'sku'
                                                                                ? `svg-icon sort-icon ${
                                                                                      currentOrder
                                                                                          ? 'sort-asc'
                                                                                          : 'sort-desc'
                                                                                  }`
                                                                                : 'svg-icon sort-icon'
                                                                        }
                                                                    >
                                                                        <use href="#sortIcon">
                                                                            <title>sort</title>
                                                                        </use>
                                                                    </svg>
                                                                </button>
                                                            )}
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-description">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">DESCRIPTION</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-reference">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">
                                                            CUSTOMER ORDER REFERENCE NO.
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-line">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">CUSTOMER ORDER LINE NO.</span>
                                                    </div>
                                                </th>
                                                {(headerData?.aoStatus?.toLowerCase() === 'ready_for_booking' ||
                                                    headerData?.aoStatus === 'Booked') && (
                                                    <th className="grid-cell-header col-ao-allocation">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">
                                                                INBOUND PO REFERENCE NO.
                                                            </span>
                                                        </div>
                                                    </th>
                                                )}
                                                <th className="grid-cell-header col-ao-allocation">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">ALLOCATION QTY</span>
                                                    </div>
                                                </th>
                                                {headerData?.aoStatus?.toLowerCase() !== 'ready_for_booking' &&
                                                    headerData?.aoStatus !== 'Booked' && (
                                                        <>
                                                            <th className="grid-cell-header col-ao-available">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">
                                                                        AVAILABLE TO ORDER
                                                                    </span>
                                                                </div>
                                                            </th>
                                                            <th className="grid-cell-header col-ao-balance">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">BALANCE QTY</span>
                                                                    <BSTooltip
                                                                        id="view-8"
                                                                        title="Balance Quantity is the goods Available to Order minus Allocated QTY that are not yet saved"
                                                                        className="grid-warning-icon"
                                                                    >
                                                                        <button className="app-btn inline-icon">
                                                                            <span>
                                                                                <svg className="svg-icon info-round-icon">
                                                                                    <use xlinkHref="#infoIconRound"></use>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </BSTooltip>
                                                                </div>
                                                            </th>
                                                        </>
                                                    )}
                                                <th className="grid-cell-header col-ao-origin">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">ORIGIN</span>
                                                    </div>
                                                </th>
                                                <th
                                                    id="filter_div"
                                                    className="grid-cell-header col-ao-destination-port"
                                                >
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">DESTINATION</span>
                                                        {headerData?.aoStatus?.toLowerCase() !== 'ready_for_booking' &&
                                                            headerData?.aoStatus !== 'Booked' && (
                                                                <>
                                                                    <button
                                                                        disabled={checkIfIsInEditMode()}
                                                                        onClick={() => {
                                                                            setcurrentOrder(!currentOrder);
                                                                            setcurrentColumn('destinationPort');
                                                                            dispatch(
                                                                                fetchProductAllocationList({
                                                                                    aoId: id,
                                                                                    sortColumn: 'destinationPort',
                                                                                    sortOrder: !currentOrder,
                                                                                    destinationPort:
                                                                                        statusFilterSelected.length
                                                                                            ? statusFilterSelected
                                                                                            : null,
                                                                                })
                                                                            );
                                                                        }}
                                                                        type="button"
                                                                        className="sort-btn app-btn"
                                                                    >
                                                                        <svg
                                                                            className={
                                                                                currentColumn === 'destinationPort'
                                                                                    ? `svg-icon sort-icon ${
                                                                                          currentOrder
                                                                                              ? 'sort-asc'
                                                                                              : 'sort-desc'
                                                                                      }`
                                                                                    : 'svg-icon sort-icon'
                                                                            }
                                                                        >
                                                                            <use href="#sortIcon">
                                                                                <title>sort</title>
                                                                            </use>
                                                                        </svg>
                                                                    </button>
                                                                    <div
                                                                        className={
                                                                            toggleDropdown ||
                                                                            statusFilterSelected.length
                                                                                ? 'filter-menu-wrap open'
                                                                                : 'filter-menu-wrap'
                                                                        }
                                                                    >
                                                                        <button
                                                                            disabled={checkIfIsInEditMode()}
                                                                            onClick={(e) => {
                                                                                filterByColumn(e);
                                                                                resizeCheckboxFilter();
                                                                            }}
                                                                            type="button"
                                                                            className="app-btn filter-menu-button"
                                                                        >
                                                                            <svg className="svg-icon filter-icon">
                                                                                <use href="#filterIcon">
                                                                                    <title>filter</title>
                                                                                </use>
                                                                            </svg>
                                                                            <span className="sr-only">header menu</span>
                                                                        </button>
                                                                    </div>
                                                                </>
                                                            )}
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-destination-address">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">DESTINATION ADDRESS</span>
                                                        {/* <button
                                                            onClick={() => {
                                                                setcurrentOrder(!currentOrder);
                                                                setcurrentColumn('destinationAddress');
                                                                dispatch(
                                                                    fetchProductAllocationList({
                                                                        aoId: id,
                                                                        sortColumn: 'destinationAddress',
                                                                        sortOrder: !currentOrder,
                                                                    })
                                                                );
                                                            }}
                                                            type="button"
                                                            className="sort-btn app-btn"
                                                        >
                                                            <svg
                                                                className={
                                                                    currentColumn === 'destinationAddress'
                                                                        ? `svg-icon sort-icon ${
                                                                              currentOrder ? 'sort-asc' : 'sort-desc'
                                                                          }`
                                                                        : 'svg-icon sort-icon'
                                                                }
                                                            >
                                                                <use href="#sortIcon">
                                                                    <title>sort</title>
                                                                </use>
                                                            </svg>
                                                        </button> */}
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-house">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">
                                                            PROJ. ETA<br></br> INTO WHOUSE
                                                        </span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-currency">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">CURRENCY</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-unit-cost">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">UNIT COST</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-cbm-cost">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">CBM</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-ao-net-cost">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">NET COST</span>
                                                    </div>
                                                </th>
                                                <ShowForPermissions permission="create" type="ao">
                                                    {(profileType === 'client' || profileType === 'forwarder') &&
                                                        headerData?.dataSourceName != 'ERP' &&
                                                        headerData?.aoStatus === 'Draft' && (
                                                            // headerData?.aoStatus === 'Pending_with_Forwarder') && (
                                                            <th className="grid-cell-header grid-sticky-column sticky-right-aligned col-ao-action">
                                                                <div className="grid-cell-data">
                                                                    <span className="header-title">ACTION</span>
                                                                </div>
                                                            </th>
                                                        )}
                                                </ShowForPermissions>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {searchOrFilter?.map(
                                                (productData: ProductAllocationDataDetails, index: number) =>
                                                    productData?.editMode ? (
                                                        <tr
                                                            id={`edit_row_${index}`}
                                                            key={index}
                                                            className="has-child row-expanded"
                                                        >
                                                            <td className="grid-cell  search-entry-holder grid-sticky-column sticky-left-aligned td-sku">
                                                                <div className="grid-cell-data">
                                                                    <div className="dropdown-wrap menu-down dropdown-open modal-dropdown">
                                                                        <SearchableSelectBox
                                                                            data={convertDataForSelect(
                                                                                allocationSku,
                                                                                'skuId',
                                                                                'sku'
                                                                            )}
                                                                            placeholder="Enter SKU to select"
                                                                            setStateValue={updateSkuData}
                                                                            stateValue={productData.skuId}
                                                                            fieldName="skuId"
                                                                            customStyle={portalStyle}
                                                                            menuPosition="fixed"
                                                                            menuPortalTarget={document.getElementById(
                                                                                'outside_div'
                                                                            )}
                                                                            index={productData.indexValue}
                                                                            closeMenuOnScroll={true}
                                                                            // extraFieldFn={skuUpdate}
                                                                            NoMessageComponent={NoMessageComponent}
                                                                            menuPlacement={menuPlacement}
                                                                        />
                                                                        {productData.validationError &&
                                                                            showError('skuId', productData)}
                                                                    </div>
                                                                    {productData?.hasWarning && (
                                                                        <BSTooltip
                                                                            id={`qty-${productData.indexValue}`}
                                                                            title="One or more allocations with this SKU 
                                                                                   cannot be fulfilled as the Total Allocation Qty for this SKU
                                                                                   exceeds the Qty Available to Order"
                                                                            tooltipClassList="grid-warning-icon"
                                                                            position="bottom"
                                                                            classList="product-allocation-icon"
                                                                        >
                                                                            <button
                                                                                type="button"
                                                                                className="app-btn inline-icon"
                                                                            >
                                                                                <span>
                                                                                    <svg className="svg-icon alert-icon">
                                                                                        <use xlinkHref="#alertIcon"></use>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </BSTooltip>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {productData?.description}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder allocation-reference-no">
                                                                <div className="grid-cell-data">
                                                                    <div className="modal-grid-input-holder">
                                                                        {/* with-error-border is the class for red-border */}
                                                                        <input
                                                                            placeholder=""
                                                                            type="text"
                                                                            className={`modal-grid-input input-text ${
                                                                                productData.hasError &&
                                                                                checkIfIsReferenceLineUnique(
                                                                                    productData,
                                                                                    productData.indexValue
                                                                                ) >= 0
                                                                                    ? 'with-error-border'
                                                                                    : ''
                                                                            }`}
                                                                            id="customerOrderReferenceNo"
                                                                            name="customerOrderReferenceNo"
                                                                            autoFocus={
                                                                                currentSelectedField ===
                                                                                    'customerOrderReferenceNo' &&
                                                                                currentIndex === productData.indexValue
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={(event) => {
                                                                                if (
                                                                                    event.target.value.length === 1 &&
                                                                                    parseFloat(event.target.value) === 0
                                                                                ) {
                                                                                    return false;
                                                                                }
                                                                                updateSkuData(
                                                                                    event.target.value,
                                                                                    productData.indexValue,
                                                                                    event.target.name
                                                                                );
                                                                                setCurrentSelectedField(
                                                                                    'customerOrderReferenceNo'
                                                                                );
                                                                            }}
                                                                            value={productData.customerOrderReferenceNo}
                                                                        ></input>
                                                                        {productData.hasError &&
                                                                            checkIfIsReferenceLineUnique(
                                                                                productData,
                                                                                productData.indexValue
                                                                            ) >= 0 &&
                                                                            productData.saveTriggered && (
                                                                                <BSTooltip
                                                                                    id={`customerOrderReferenceNo-${productData.indexValue}`}
                                                                                    title="Customer Order Reference Number and 
                                                                                           Customer Order Line Number combination already exists"
                                                                                    classList="action-btn-wrapper"
                                                                                    tooltipClassList="grid-error-icon"
                                                                                >
                                                                                    <button
                                                                                        className="app-btn inline-grid-input"
                                                                                        type="button"
                                                                                    >
                                                                                        <span>
                                                                                            <svg className="svg-icon error-icon">
                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </BSTooltip>
                                                                            )}
                                                                    </div>
                                                                    {productData.validationError &&
                                                                        showError(
                                                                            'customerOrderReferenceNo',
                                                                            productData
                                                                        )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder allocation-line-no">
                                                                <div className="grid-cell-data">
                                                                    <div className="modal-grid-input-holder">
                                                                        {/* with-error-border is the class for red-border */}
                                                                        <input
                                                                            placeholder=""
                                                                            type="text"
                                                                            className={`modal-grid-input input-text ${
                                                                                productData.hasError &&
                                                                                checkIfIsReferenceLineUnique(
                                                                                    productData,
                                                                                    productData.indexValue
                                                                                ) >= 0
                                                                                    ? 'with-error-border'
                                                                                    : ''
                                                                            }`}
                                                                            id="customerOrderLineNo"
                                                                            name="customerOrderLineNo"
                                                                            autoFocus={
                                                                                currentSelectedField ===
                                                                                    'customerOrderLineNo' &&
                                                                                currentIndex === productData.indexValue
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={(event) => {
                                                                                if (
                                                                                    event.target.value.length === 1 &&
                                                                                    parseFloat(event.target.value) === 0
                                                                                ) {
                                                                                    return false;
                                                                                }
                                                                                updateSkuData(
                                                                                    event.target.value,
                                                                                    productData.indexValue,
                                                                                    event.target.name
                                                                                );
                                                                                setCurrentSelectedField(
                                                                                    'customerOrderLineNo'
                                                                                );
                                                                            }}
                                                                            value={productData.customerOrderLineNo}
                                                                            onKeyPress={(event) => {
                                                                                if (!/[0-9]/.test(event.key)) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                        ></input>
                                                                        {productData.hasError &&
                                                                            checkIfIsReferenceLineUnique(
                                                                                productData,
                                                                                productData.indexValue
                                                                            ) >= 0 &&
                                                                            productData.saveTriggered && (
                                                                                <BSTooltip
                                                                                    id={`customerOrderLineNo-${productData.indexValue}`}
                                                                                    title="Customer Order Reference Number and 
                                                                                           Customer Order Line Number combination already exists"
                                                                                    classList="action-btn-wrapper"
                                                                                    tooltipClassList="grid-error-icon"
                                                                                >
                                                                                    <button
                                                                                        className="app-btn inline-grid-input"
                                                                                        type="button"
                                                                                    >
                                                                                        <span>
                                                                                            <svg className="svg-icon error-icon">
                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </BSTooltip>
                                                                            )}
                                                                    </div>
                                                                    {productData.validationError &&
                                                                        showError('customerOrderLineNo', productData)}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder allocation-qty-td">
                                                                <div className="grid-cell-data">
                                                                    <div className="modal-grid-input-holder">
                                                                        {/* with-error-border is the class for red-border */}
                                                                        <input
                                                                            placeholder=""
                                                                            type="text"
                                                                            className={`modal-grid-input input-text ${
                                                                                productData.hasError &&
                                                                                typeof productData.qty === 'number' &&
                                                                                typeof productData.availableToOrder ===
                                                                                    'number' &&
                                                                                productData.qty >
                                                                                    productData.availableToOrder
                                                                                    ? 'with-error-border'
                                                                                    : ''
                                                                            }`}
                                                                            id="qty"
                                                                            name="qty"
                                                                            autoFocus={
                                                                                currentSelectedField === 'qty' &&
                                                                                currentIndex === productData.indexValue
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            onChange={(event) => {
                                                                                updateSkuData(
                                                                                    +event.target.value,
                                                                                    productData.indexValue,
                                                                                    event.target.name
                                                                                );
                                                                                setCurrentSelectedField('qty');
                                                                                setCustomError(false);
                                                                            }}
                                                                            value={productData.qty}
                                                                            onBlur={() => setCustomError(true)}
                                                                            onKeyPress={(event: any) => {
                                                                                if (
                                                                                    !/[0-9]/.test(event.key) ||
                                                                                    event.target?.value?.toString()
                                                                                        .length >= 6
                                                                                ) {
                                                                                    event.preventDefault();
                                                                                }
                                                                            }}
                                                                        ></input>

                                                                        {productData.hasError &&
                                                                            typeof productData.qty === 'number' &&
                                                                            typeof productData.availableToOrder ===
                                                                                'number' &&
                                                                            productData.qty >
                                                                                productData.availableToOrder &&
                                                                            productData.saveTriggered && (
                                                                                <BSTooltip
                                                                                    id="view-5"
                                                                                    title="Allocation quantity cannot exceed quantity available to order"
                                                                                    tooltipClassList="grid-error-icon"
                                                                                    classList="action-btn-wrapper"
                                                                                >
                                                                                    <button
                                                                                        className="app-btn inline-grid-input"
                                                                                        type="button"
                                                                                    >
                                                                                        <span>
                                                                                            <svg className="svg-icon error-icon">
                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                            </svg>
                                                                                        </span>
                                                                                    </button>
                                                                                </BSTooltip>
                                                                            )}
                                                                    </div>
                                                                    {productData.validationError
                                                                        ? showError('qty', productData)
                                                                        : showError('qtyZero', productData)}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {productData.availableToOrder}
                                                                </div>
                                                            </td>

                                                            <td className="grid-cell">
                                                                <div
                                                                    className={`grid-cell-data ${
                                                                        typeof productData?.balanceQty === 'number' &&
                                                                        productData?.balanceQty < 0
                                                                            ? 'error-text'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {productData?.balanceQty}
                                                                </div>
                                                            </td>

                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {productData.originPortName}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell allocation-destination-td">
                                                                <div className="grid-cell-data auto-dp-height">
                                                                    <SearchableSelectBox
                                                                        data={formatedPorts}
                                                                        placeholder="Enter destination to select"
                                                                        setStateValue={updateSkuData}
                                                                        stateValue={
                                                                            productData?.destinationPortName?.includes(
                                                                                '(null)'
                                                                            )
                                                                                ? ''
                                                                                : productData?.destinationPortName
                                                                        }
                                                                        overrideValue={
                                                                            !productData?.destinationPortName ||
                                                                            productData?.destinationPortName?.includes(
                                                                                '(null)'
                                                                            )
                                                                                ? ''
                                                                                : {
                                                                                      label: productData?.destinationPortName,
                                                                                      value: productData?.destinationPortName,
                                                                                  }
                                                                        }
                                                                        fieldName="destinationPortName"
                                                                        customStyle={portalStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        onInputChangeFn={onPortSearch}
                                                                        index={productData.indexValue}
                                                                        closeMenuOnScroll={true}
                                                                        menuPlacement={menuPlacement}
                                                                    />
                                                                    {productData.validationError &&
                                                                        showError('destinationPortName', productData)}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder allocation-destination-address-td">
                                                                <div
                                                                    id={`address_div_${productData.indexValue}`}
                                                                    className="grid-cell-data"
                                                                >
                                                                    <div
                                                                        className={
                                                                            showAddress &&
                                                                            currentIndex === productData.indexValue
                                                                                ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                                : 'dropdown-wrap  menu-down modal-dropdown'
                                                                        }
                                                                    >
                                                                        <button
                                                                            onClick={(e) => {
                                                                                resizeFilter(
                                                                                    `address_div_${productData.indexValue}`
                                                                                );
                                                                                e.stopPropagation();
                                                                                setShowAddress((prev) => !prev);
                                                                                setCurrentIndex(productData.indexValue);
                                                                            }}
                                                                            type="button"
                                                                            className="menu-btn app-btn"
                                                                        >
                                                                            {productData.destinationAddress ? (
                                                                                <span className="btn-text placeholder-text-address">
                                                                                    {productData.destinationAddress}
                                                                                </span>
                                                                            ) : (
                                                                                <span className="btn-text btn-address">
                                                                                    Select address
                                                                                </span>
                                                                            )}

                                                                            {/* <span className="placeholder-text">Select Address</span> */}
                                                                            <span className="dropdown-arrow">
                                                                                <svg className="svg-icon arrow-icon">
                                                                                    <use xlinkHref="#downArrow">
                                                                                        <title>dropdown</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    {productData.validationError &&
                                                                        showError(
                                                                            'destinationAddressIdInProduct',
                                                                            productData
                                                                        )}
                                                                    {/* {formik.errors.destinationAddress ? (
                                                                        <div className="error-text">
                                                                            {formik.errors?.destinationAddress}
                                                                        </div>
                                                                    ) : null} */}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder allocation-projected-eta-td">
                                                                <div
                                                                    id="goodsReadyDateDiv"
                                                                    className="grid-cell-data date-picker-grid"
                                                                >
                                                                    <DatePicker
                                                                        minDate={new Date()}
                                                                        state={productData.projWhouseETA}
                                                                        onChange={updateSkuData}
                                                                        fieldName="projWhouseETA"
                                                                        portalId="date-portal"
                                                                        index={productData.indexValue}
                                                                    />
                                                                    {productData.validationError &&
                                                                        showError('projWhouseETA', productData)}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell allocation-currency-td">
                                                                <div className="grid-cell-data">
                                                                    <SearchableSelectBox
                                                                        data={currency}
                                                                        placeholder="Select currency"
                                                                        setStateValue={updateSkuData}
                                                                        stateValue={productData?.currency}
                                                                        fieldName="currency"
                                                                        customStyle={portalStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        index={productData.indexValue}
                                                                        closeMenuOnScroll={true}
                                                                        menuPlacement={menuPlacement}
                                                                    />
                                                                    {productData.validationError &&
                                                                        showError('currency', productData)}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder allocation-cost-td">
                                                                <div className="grid-cell-data ">
                                                                    <input
                                                                        placeholder=""
                                                                        type="number"
                                                                        className="search-input input-text input-qty"
                                                                        id="unitCost"
                                                                        name="unitCost"
                                                                        min="0"
                                                                        onChange={(event) => {
                                                                            updateSkuData(
                                                                                +parseFloat(event.target.value),
                                                                                productData.indexValue,
                                                                                event.target.name
                                                                            );
                                                                            setCustomError(false);
                                                                        }}
                                                                        value={productData.unitCost}
                                                                        onBlur={() => setCustomError(true)}
                                                                    ></input>
                                                                    {productData.validationError
                                                                        ? showError('unitCost', productData)
                                                                        : showError('unitCostZero', productData)}
                                                                    {/* {formik.errors.unitCost ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.unitCost}
                                                                        </div>
                                                                    ) : null} */}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">{productData.cbm}</div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {productData.netCost}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell grid-sticky-column sticky-right-aligned">
                                                                <div className="grid-cell-data">
                                                                    <button
                                                                        type="button"
                                                                        className="action-btn app-btn app-btn-secondary "
                                                                        title={
                                                                            // checkSaveButtonStatus(productData)
                                                                            //     ? 'Mandatory fields are missing'
                                                                            // :
                                                                            'Save SKU Line'
                                                                        }
                                                                        // disabled={checkSaveButtonStatus(productData)}
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            saveSku(
                                                                                productData,
                                                                                productData.indexValue
                                                                            );
                                                                        }}
                                                                    >
                                                                        <svg className="svg-icon save-icon">
                                                                            <use href="#saveIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="action-btn app-btn app-btn-secondary "
                                                                        title="Remove SKU Line"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            removeSku(
                                                                                productData.poProductId,
                                                                                productData.indexValue
                                                                            );
                                                                        }}
                                                                    >
                                                                        <svg className="svg-icon circle-close-icon">
                                                                            <use href=" #deleteIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                    <button
                                                                        type="button"
                                                                        className="action-btn app-btn app-btn-secondary "
                                                                        title="Duplicate SKU Line"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                            duplicateSkuLine(productData.indexValue);
                                                                        }}
                                                                    >
                                                                        <svg className="svg-icon duplicate-icon">
                                                                            <use href="#duplicateIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ) : (
                                                        <React.Fragment key={index}>
                                                            {(() => {
                                                                if (
                                                                    productData?.inboundPoReferenceNos?.length > 0 &&
                                                                    (headerData?.aoStatus?.toLowerCase() ===
                                                                        'ready_for_booking' ||
                                                                        headerData?.aoStatus === 'Booked')
                                                                ) {
                                                                    return productData?.inboundPoReferenceNos?.map(
                                                                        (
                                                                            inboundPoReferenceNo: {
                                                                                allocationQty: number;
                                                                                inobundPoReferenceNo: string;
                                                                            },
                                                                            inboundPoReferenceNoIndex: number
                                                                        ) => {
                                                                            return (
                                                                                <tr
                                                                                    key={
                                                                                        inboundPoReferenceNoIndex +
                                                                                        '_' +
                                                                                        index
                                                                                    }
                                                                                    className="has-child row-expanded"
                                                                                >
                                                                                    {inboundPoReferenceNoIndex === 0 ? (
                                                                                        <td className="grid-cell  search-entry-holder grid-sticky-column sticky-left-aligned td-sku">
                                                                                            <div className="grid-cell-data">
                                                                                                {headerData?.dataSourceName ===
                                                                                                'ERP' ? (
                                                                                                    <>
                                                                                                        {(() => {
                                                                                                            if (
                                                                                                                productData.isSkuIdInvalid
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    <div className="modal-grid-input-holder">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            className="modal-grid-input input-text"
                                                                                                                            id="skuId"
                                                                                                                            name="skuId"
                                                                                                                            disabled={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                productData.sku
                                                                                                                            }
                                                                                                                        ></input>

                                                                                                                        <BSTooltip
                                                                                                                            id={`skuId-${productData.indexValue}`}
                                                                                                                            title="SKU does not exist in the Inventory"
                                                                                                                            classList="action-btn-wrapper"
                                                                                                                            tooltipClassList="grid-error-icon"
                                                                                                                        >
                                                                                                                            <button
                                                                                                                                className="app-btn inline-grid-input"
                                                                                                                                type="button"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    <svg className="svg-icon error-icon">
                                                                                                                                        <use xlinkHref="#errorIcon"></use>
                                                                                                                                    </svg>
                                                                                                                                </span>
                                                                                                                            </button>
                                                                                                                        </BSTooltip>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            } else if (
                                                                                                                typeof productData?.balanceQty ===
                                                                                                                    'number' &&
                                                                                                                productData?.balanceQty <
                                                                                                                    0
                                                                                                            ) {
                                                                                                                return (
                                                                                                                    <div className="modal-grid-input-holder">
                                                                                                                        <input
                                                                                                                            type="text"
                                                                                                                            className="modal-grid-input input-text"
                                                                                                                            id="skuId"
                                                                                                                            name="skuId"
                                                                                                                            disabled={
                                                                                                                                true
                                                                                                                            }
                                                                                                                            value={
                                                                                                                                productData.sku
                                                                                                                            }
                                                                                                                        ></input>

                                                                                                                        <BSTooltip
                                                                                                                            id={`qty-${productData.indexValue}`}
                                                                                                                            title="One or more allocations with this SKU 
                                                                                                       cannot be fulfilled as the Total Allocation Qty for this SKU
                                                                                                       exceeds the Qty Available to Order"
                                                                                                                            tooltipClassList="grid-warning-icon"
                                                                                                                            position="bottom"
                                                                                                                            classList="product-allocation-icon"
                                                                                                                        >
                                                                                                                            <button
                                                                                                                                type="button"
                                                                                                                                className="app-btn inline-icon"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    <svg className="svg-icon alert-icon">
                                                                                                                                        <use xlinkHref="#alertIcon"></use>
                                                                                                                                    </svg>
                                                                                                                                </span>
                                                                                                                            </button>
                                                                                                                        </BSTooltip>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            } else {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        {' '}
                                                                                                                        {
                                                                                                                            productData?.sku
                                                                                                                        }
                                                                                                                    </>
                                                                                                                );
                                                                                                            }
                                                                                                        })()}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <>
                                                                                                        {' '}
                                                                                                        {
                                                                                                            productData?.sku
                                                                                                        }
                                                                                                    </>
                                                                                                )}
                                                                                            </div>
                                                                                        </td>
                                                                                    ) : (
                                                                                        <td className="grid-cell  search-entry-holder grid-sticky-column sticky-left-aligned td-sku">
                                                                                            <div className="grid-cell-data"></div>
                                                                                        </td>
                                                                                    )}

                                                                                    {inboundPoReferenceNoIndex === 0 ? (
                                                                                        <>
                                                                                            <td className="grid-cell">
                                                                                                <div className="grid-cell-data">
                                                                                                    {
                                                                                                        productData?.description
                                                                                                    }
                                                                                                </div>
                                                                                            </td>
                                                                                            {headerData?.dataSourceName ===
                                                                                                'ERP' &&
                                                                                            checkIfIsReferenceLineUnique(
                                                                                                productData,
                                                                                                productData.indexValue
                                                                                            ) >= 0 ? (
                                                                                                <>
                                                                                                    <td className="grid-cell search-entry-holder">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <div className="modal-grid-input-holder">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="modal-grid-input input-text"
                                                                                                                    id="customerOrderReferenceNo"
                                                                                                                    name="customerOrderReferenceNo"
                                                                                                                    disabled={
                                                                                                                        true
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        productData.customerOrderReferenceNo
                                                                                                                    }
                                                                                                                ></input>

                                                                                                                <BSTooltip
                                                                                                                    id={`customerOrderReferenceNo-${productData.indexValue}`}
                                                                                                                    title="SKU does not exist in the Inventory"
                                                                                                                    classList="action-btn-wrapper"
                                                                                                                    tooltipClassList="grid-error-icon"
                                                                                                                >
                                                                                                                    <button
                                                                                                                        className="app-btn inline-grid-input"
                                                                                                                        type="button"
                                                                                                                    >
                                                                                                                        <span>
                                                                                                                            <svg className="svg-icon error-icon">
                                                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                </BSTooltip>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell search-entry-holder">
                                                                                                        <div className="grid-cell-data">
                                                                                                            <div className="modal-grid-input-holder">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="modal-grid-input input-text"
                                                                                                                    id="customerOrderLineNo"
                                                                                                                    name="customerOrderLineNo"
                                                                                                                    disabled={
                                                                                                                        true
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        productData.customerOrderLineNo
                                                                                                                    }
                                                                                                                ></input>

                                                                                                                <BSTooltip
                                                                                                                    id={`customerOrderLineNo-${productData.indexValue}`}
                                                                                                                    title="SKU does not exist in the Inventory"
                                                                                                                    classList="action-btn-wrapper"
                                                                                                                    tooltipClassList="grid-error-icon"
                                                                                                                >
                                                                                                                    <button
                                                                                                                        className="app-btn inline-grid-input"
                                                                                                                        type="button"
                                                                                                                    >
                                                                                                                        <span>
                                                                                                                            <svg className="svg-icon error-icon">
                                                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                </BSTooltip>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    <td className="grid-cell search-entry-holder">
                                                                                                        <div className="grid-cell-data">
                                                                                                            {
                                                                                                                productData?.customerOrderReferenceNo
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td className="grid-cell search-entry-holder">
                                                                                                        <div className="grid-cell-data">
                                                                                                            {
                                                                                                                productData?.customerOrderLineNo
                                                                                                            }
                                                                                                        </div>
                                                                                                    </td>
                                                                                                </>
                                                                                            )}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <td className="grid-cell">
                                                                                                <div className="grid-cell-data"></div>
                                                                                            </td>
                                                                                            <td className="grid-cell">
                                                                                                <div className="grid-cell-data"></div>
                                                                                            </td>
                                                                                            <td className="grid-cell">
                                                                                                <div className="grid-cell-data"></div>
                                                                                            </td>
                                                                                        </>
                                                                                    )}

                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                inboundPoReferenceNo?.inobundPoReferenceNo
                                                                                            }
                                                                                        </div>
                                                                                    </td>

                                                                                    {headerData?.dataSourceName ===
                                                                                        'ERP' &&
                                                                                    inboundPoReferenceNo?.allocationQty >
                                                                                        productData.availableToOrder ? (
                                                                                        <td className="grid-cell search-entry-holder">
                                                                                            <div className="grid-cell-data">
                                                                                                <div className="modal-grid-input-holder">
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="modal-grid-input input-text"
                                                                                                        id="qty"
                                                                                                        name="qty"
                                                                                                        disabled={true}
                                                                                                        value={
                                                                                                            inboundPoReferenceNo?.allocationQty
                                                                                                        }
                                                                                                    ></input>

                                                                                                    <BSTooltip
                                                                                                        id={`qty-${productData.indexValue}`}
                                                                                                        title="SKU does not exist in the Inventory"
                                                                                                        classList="action-btn-wrapper"
                                                                                                        tooltipClassList="grid-error-icon"
                                                                                                    >
                                                                                                        <button
                                                                                                            className="app-btn inline-grid-input"
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <span>
                                                                                                                <svg className="svg-icon error-icon">
                                                                                                                    <use xlinkHref="#errorIcon"></use>
                                                                                                                </svg>
                                                                                                            </span>
                                                                                                        </button>
                                                                                                    </BSTooltip>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    ) : (
                                                                                        <td className="grid-cell search-entry-holder">
                                                                                            <div className="grid-cell-data">
                                                                                                {
                                                                                                    inboundPoReferenceNo?.allocationQty
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    )}

                                                                                    {headerData?.dataSourceName ===
                                                                                        'ERP' &&
                                                                                    productData.originPortName !=
                                                                                        `${headerData?.originPortName} (${headerData?.originPort})` ? (
                                                                                        <td className="grid-cell search-entry-holder">
                                                                                            <div className="grid-cell-data">
                                                                                                <div className="modal-grid-input-holder">
                                                                                                    <input
                                                                                                        type="text"
                                                                                                        className="modal-grid-input input-text"
                                                                                                        id="originPortName"
                                                                                                        name="originPortName"
                                                                                                        disabled={true}
                                                                                                        value={
                                                                                                            productData.originPortName
                                                                                                        }
                                                                                                    ></input>

                                                                                                    <BSTooltip
                                                                                                        id={`originPortName-${productData.indexValue}`}
                                                                                                        title="Origin is different from the BLP location"
                                                                                                        classList="action-btn-wrapper"
                                                                                                        tooltipClassList="grid-error-icon"
                                                                                                    >
                                                                                                        <button
                                                                                                            className="app-btn inline-grid-input"
                                                                                                            type="button"
                                                                                                        >
                                                                                                            <span>
                                                                                                                <svg className="svg-icon error-icon">
                                                                                                                    <use xlinkHref="#errorIcon"></use>
                                                                                                                </svg>
                                                                                                            </span>
                                                                                                        </button>
                                                                                                    </BSTooltip>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                    ) : (
                                                                                        <td className="grid-cell search-entry-holder">
                                                                                            <div className="grid-cell-data">
                                                                                                {
                                                                                                    productData?.originPortName
                                                                                                }
                                                                                            </div>
                                                                                        </td>
                                                                                    )}

                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                productData?.destinationPortName
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                productData?.destinationAddress
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data ">
                                                                                            {productData.projWhouseETA
                                                                                                ? moment(
                                                                                                      productData.projWhouseETA
                                                                                                  ).format('DD-MM-YYYY')
                                                                                                : null}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {productData?.currency}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data ">
                                                                                            {productData?.unitCost}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {productData?.cbm}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {productData?.netCost}
                                                                                        </div>
                                                                                    </td>
                                                                                    <ShowForPermissions
                                                                                        permission="create"
                                                                                        type="ao"
                                                                                    >
                                                                                        {(profileType === 'client' ||
                                                                                            profileType ===
                                                                                                'forwarder') &&
                                                                                            headerData?.aoStatus ===
                                                                                                'Draft' &&
                                                                                            headerData?.dataSourceName !=
                                                                                                'ERP' && (
                                                                                                <td className="grid-cell grid-sticky-column sticky-right-aligned">
                                                                                                    <div className="grid-cell-data">
                                                                                                        <button
                                                                                                            className="action-btn app-btn app-btn-secondary"
                                                                                                            title="Edit SKU Line"
                                                                                                            type="button"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                e.preventDefault();
                                                                                                                editSkuLine(
                                                                                                                    productData.indexValue
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            <svg className="svg-icon save-icon">
                                                                                                                <use xlinkHref="#editIcon"></use>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            className="action-btn app-btn app-btn-secondary "
                                                                                                            title="Remove SKU Line"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                e.preventDefault();
                                                                                                                removeSku(
                                                                                                                    productData.poProductId,
                                                                                                                    productData.indexValue
                                                                                                                );
                                                                                                            }}
                                                                                                        >
                                                                                                            <svg className="svg-icon circle-close-icon">
                                                                                                                <use href=" #deleteIcon"></use>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            onClick={(
                                                                                                                e
                                                                                                            ) => {
                                                                                                                e.preventDefault();
                                                                                                                duplicateSkuLine(
                                                                                                                    productData.indexValue
                                                                                                                );
                                                                                                            }}
                                                                                                            className="action-btn app-btn app-btn-secondary "
                                                                                                            title="Duplicate SKU Line"
                                                                                                        >
                                                                                                            <svg className="svg-icon duplicate-icon">
                                                                                                                <use href="#duplicateIcon"></use>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                    </div>
                                                                                                </td>
                                                                                            )}
                                                                                    </ShowForPermissions>
                                                                                </tr>
                                                                            );
                                                                        }
                                                                    );
                                                                } else {
                                                                    return (
                                                                        <tr
                                                                            key={index}
                                                                            className="has-child row-expanded"
                                                                        >
                                                                            <td className="grid-cell  search-entry-holder grid-sticky-column sticky-left-aligned td-sku">
                                                                                <div className="grid-cell-data">
                                                                                    {headerData?.dataSourceName ===
                                                                                    'ERP' ? (
                                                                                        <>
                                                                                            {(() => {
                                                                                                if (
                                                                                                    productData.isSkuIdInvalid
                                                                                                ) {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <div className="modal-grid-input-holder">
                                                                                                                <input
                                                                                                                    type="text"
                                                                                                                    className="modal-grid-input input-text"
                                                                                                                    id="skuId"
                                                                                                                    name="skuId"
                                                                                                                    disabled={
                                                                                                                        true
                                                                                                                    }
                                                                                                                    value={
                                                                                                                        productData.sku
                                                                                                                    }
                                                                                                                ></input>

                                                                                                                <BSTooltip
                                                                                                                    id={`skuId-${productData.indexValue}`}
                                                                                                                    title="SKU does not exist in the Inventory"
                                                                                                                    classList="action-btn-wrapper"
                                                                                                                    tooltipClassList="grid-error-icon"
                                                                                                                >
                                                                                                                    <button
                                                                                                                        className="app-btn inline-grid-input"
                                                                                                                        type="button"
                                                                                                                    >
                                                                                                                        <span>
                                                                                                                            <svg className="svg-icon error-icon">
                                                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                                                            </svg>
                                                                                                                        </span>
                                                                                                                    </button>
                                                                                                                </BSTooltip>
                                                                                                            </div>
                                                                                                            {typeof productData?.balanceQty ===
                                                                                                                'number' &&
                                                                                                                productData?.balanceQty <
                                                                                                                    0 && (
                                                                                                                    <div className="modal-grid-input-holder">
                                                                                                                        <BSTooltip
                                                                                                                            id={`sku-warning-${productData.indexValue}`}
                                                                                                                            title="One or more allocations with this SKU 
                                                                                           cannot be fulfilled as the Total Allocation Qty for this SKU
                                                                                           exceeds the Qty Available to Order"
                                                                                                                            tooltipClassList="grid-warning-icon"
                                                                                                                            position="bottom"
                                                                                                                            classList="product-allocation-icon"
                                                                                                                        >
                                                                                                                            <button
                                                                                                                                type="button"
                                                                                                                                className="app-btn inline-icon"
                                                                                                                            >
                                                                                                                                <span>
                                                                                                                                    <svg className="svg-icon alert-icon">
                                                                                                                                        <use xlinkHref="#alertIcon"></use>
                                                                                                                                    </svg>
                                                                                                                                </span>
                                                                                                                            </button>
                                                                                                                        </BSTooltip>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                        </>
                                                                                                    );
                                                                                                }
                                                                                                if (
                                                                                                    typeof productData?.balanceQty ===
                                                                                                        'number' &&
                                                                                                    productData?.balanceQty <
                                                                                                        0
                                                                                                ) {
                                                                                                    return (
                                                                                                        <div className="modal-grid-input-holder">
                                                                                                            <input
                                                                                                                type="text"
                                                                                                                className="modal-grid-input input-text"
                                                                                                                id="skuId"
                                                                                                                name="skuId"
                                                                                                                disabled={
                                                                                                                    true
                                                                                                                }
                                                                                                                value={
                                                                                                                    productData.sku
                                                                                                                }
                                                                                                            ></input>

                                                                                                            <BSTooltip
                                                                                                                id={`sku-qty-${productData.indexValue}`}
                                                                                                                title="One or more allocations with this SKU 
                                                                                               cannot be fulfilled as the Total Allocation Qty for this SKU
                                                                                               exceeds the Qty Available to Order"
                                                                                                                tooltipClassList="grid-warning-icon"
                                                                                                                position="bottom"
                                                                                                                classList="product-allocation-icon"
                                                                                                            >
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="app-btn inline-icon"
                                                                                                                >
                                                                                                                    <span>
                                                                                                                        <svg className="svg-icon alert-icon">
                                                                                                                            <use xlinkHref="#alertIcon"></use>
                                                                                                                        </svg>
                                                                                                                    </span>
                                                                                                                </button>
                                                                                                            </BSTooltip>
                                                                                                        </div>
                                                                                                    );
                                                                                                }

                                                                                                return (
                                                                                                    <>
                                                                                                        {
                                                                                                            productData?.sku
                                                                                                        }
                                                                                                    </>
                                                                                                );
                                                                                            })()}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            <div className="modal-grid-input-holder">
                                                                                                {productData?.sku}
                                                                                                {typeof productData?.balanceQty ===
                                                                                                    'number' &&
                                                                                                    productData?.balanceQty <
                                                                                                        0 && (
                                                                                                        <BSTooltip
                                                                                                            id={`sku-qty-${productData.indexValue}`}
                                                                                                            title="One or more allocations with this SKU 
                                                                                               cannot be fulfilled as the Total Allocation Qty for this SKU
                                                                                               exceeds the Qty Available to Order"
                                                                                                            tooltipClassList="grid-warning-icon"
                                                                                                            position="bottom"
                                                                                                            classList="product-allocation-icon"
                                                                                                        >
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="app-btn inline-icon"
                                                                                                            >
                                                                                                                <span>
                                                                                                                    <svg className="svg-icon alert-icon">
                                                                                                                        <use xlinkHref="#alertIcon"></use>
                                                                                                                    </svg>
                                                                                                                </span>
                                                                                                            </button>
                                                                                                        </BSTooltip>
                                                                                                    )}
                                                                                            </div>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {productData?.description}
                                                                                </div>
                                                                            </td>
                                                                            {headerData?.dataSourceName === 'ERP' &&
                                                                            checkIfIsReferenceLineUnique(
                                                                                productData,
                                                                                productData.indexValue
                                                                            ) >= 0 ? (
                                                                                <>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    id="customerOrderReferenceNo"
                                                                                                    name="customerOrderReferenceNo"
                                                                                                    disabled={true}
                                                                                                    value={
                                                                                                        productData.customerOrderReferenceNo
                                                                                                    }
                                                                                                ></input>

                                                                                                <BSTooltip
                                                                                                    id={`customerOrderReferenceNo-${productData.indexValue}`}
                                                                                                    title="Customer Order Reference Number and 
                                                                                                    Customer Order Line Number combination already exists"
                                                                                                    classList="action-btn-wrapper"
                                                                                                    tooltipClassList="grid-error-icon"
                                                                                                >
                                                                                                    <button
                                                                                                        className="app-btn inline-grid-input"
                                                                                                        type="button"
                                                                                                    >
                                                                                                        <span>
                                                                                                            <svg className="svg-icon error-icon">
                                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                                            </svg>
                                                                                                        </span>
                                                                                                    </button>
                                                                                                </BSTooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    id="customerOrderLineNo"
                                                                                                    name="customerOrderLineNo"
                                                                                                    disabled={true}
                                                                                                    value={
                                                                                                        productData.customerOrderLineNo
                                                                                                    }
                                                                                                ></input>

                                                                                                <BSTooltip
                                                                                                    id={`customerOrderLineNo-${productData.indexValue}`}
                                                                                                    title="Customer Order Reference Number and 
                                                                                                    Customer Order Line Number combination already exists"
                                                                                                    classList="action-btn-wrapper"
                                                                                                    tooltipClassList="grid-error-icon"
                                                                                                >
                                                                                                    <button
                                                                                                        className="app-btn inline-grid-input"
                                                                                                        type="button"
                                                                                                    >
                                                                                                        <span>
                                                                                                            <svg className="svg-icon error-icon">
                                                                                                                <use xlinkHref="#errorIcon"></use>
                                                                                                            </svg>
                                                                                                        </span>
                                                                                                    </button>
                                                                                                </BSTooltip>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                productData?.customerOrderReferenceNo
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                productData?.customerOrderLineNo
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            )}

                                                                            {headerData?.dataSourceName === 'ERP' &&
                                                                            typeof productData.qty === 'number' &&
                                                                            typeof productData.availableToOrder ===
                                                                                'number' &&
                                                                            productData.qty >
                                                                                productData.availableToOrder ? (
                                                                                <td className="grid-cell search-entry-holder">
                                                                                    <div className="grid-cell-data">
                                                                                        <div className="modal-grid-input-holder">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="modal-grid-input input-text"
                                                                                                id="qty"
                                                                                                name="qty"
                                                                                                disabled={true}
                                                                                                value={productData.qty}
                                                                                            ></input>

                                                                                            <BSTooltip
                                                                                                id={`qty-${productData.indexValue}`}
                                                                                                title="Allocation quantity cannot exceed quantity available to order"
                                                                                                classList="action-btn-wrapper"
                                                                                                tooltipClassList="grid-error-icon"
                                                                                            >
                                                                                                <button
                                                                                                    className="app-btn inline-grid-input"
                                                                                                    type="button"
                                                                                                >
                                                                                                    <span>
                                                                                                        <svg className="svg-icon error-icon">
                                                                                                            <use xlinkHref="#errorIcon"></use>
                                                                                                        </svg>
                                                                                                    </span>
                                                                                                </button>
                                                                                            </BSTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            ) : (
                                                                                <td className="grid-cell search-entry-holder">
                                                                                    <div className="grid-cell-data">
                                                                                        {productData?.qty}
                                                                                    </div>
                                                                                </td>
                                                                            )}

                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {productData?.availableToOrder}
                                                                                </div>
                                                                            </td>

                                                                            <td className="grid-cell">
                                                                                <div
                                                                                    className={`grid-cell-data ${
                                                                                        typeof productData?.balanceQty ===
                                                                                            'number' &&
                                                                                        productData?.balanceQty < 0
                                                                                            ? 'error-text'
                                                                                            : ''
                                                                                    }`}
                                                                                >
                                                                                    {productData?.balanceQty}
                                                                                </div>
                                                                            </td>

                                                                            {headerData?.dataSourceName === 'ERP' &&
                                                                            productData.originPortName !=
                                                                                `${headerData?.originPortName} (${headerData?.originPort})` ? (
                                                                                <td className="grid-cell search-entry-holder">
                                                                                    <div className="grid-cell-data">
                                                                                        <div className="modal-grid-input-holder">
                                                                                            <input
                                                                                                type="text"
                                                                                                className="modal-grid-input input-text"
                                                                                                id="originPortName"
                                                                                                name="originPortName"
                                                                                                disabled={true}
                                                                                                value={
                                                                                                    productData.originPortName
                                                                                                }
                                                                                            ></input>

                                                                                            <BSTooltip
                                                                                                id={`originPortName-${productData.indexValue}`}
                                                                                                title="Origin is different from the BLP location"
                                                                                                classList="action-btn-wrapper"
                                                                                                tooltipClassList="grid-error-icon"
                                                                                            >
                                                                                                <button
                                                                                                    className="app-btn inline-grid-input"
                                                                                                    type="button"
                                                                                                >
                                                                                                    <span>
                                                                                                        <svg className="svg-icon error-icon">
                                                                                                            <use xlinkHref="#errorIcon"></use>
                                                                                                        </svg>
                                                                                                    </span>
                                                                                                </button>
                                                                                            </BSTooltip>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                            ) : (
                                                                                <td className="grid-cell search-entry-holder">
                                                                                    <div className="grid-cell-data">
                                                                                        {productData?.originPortName}
                                                                                    </div>
                                                                                </td>
                                                                            )}

                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {productData?.destinationPortName}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {productData?.destinationAddress}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell search-entry-holder">
                                                                                <div className="grid-cell-data ">
                                                                                    {productData.projWhouseETA
                                                                                        ? moment(
                                                                                              productData.projWhouseETA
                                                                                          ).format('DD-MM-YYYY')
                                                                                        : null}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {productData?.currency}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell search-entry-holder">
                                                                                <div className="grid-cell-data ">
                                                                                    {productData?.unitCost}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {productData?.cbm}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {productData?.netCost}
                                                                                </div>
                                                                            </td>
                                                                            <ShowForPermissions
                                                                                permission="create"
                                                                                type="ao"
                                                                            >
                                                                                {(profileType === 'client' ||
                                                                                    profileType === 'forwarder') &&
                                                                                    headerData?.aoStatus === 'Draft' &&
                                                                                    headerData?.dataSourceName !=
                                                                                        'ERP' && (
                                                                                        <td className="grid-cell grid-sticky-column sticky-right-aligned">
                                                                                            <div className="grid-cell-data">
                                                                                                <button
                                                                                                    className="action-btn app-btn app-btn-secondary"
                                                                                                    title="Edit SKU Line"
                                                                                                    type="button"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        editSkuLine(
                                                                                                            productData.indexValue
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <svg className="svg-icon save-icon">
                                                                                                        <use xlinkHref="#editIcon"></use>
                                                                                                    </svg>
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                                    title="Remove SKU Line"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        removeSku(
                                                                                                            productData.poProductId,
                                                                                                            productData.indexValue
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <svg className="svg-icon circle-close-icon">
                                                                                                        <use href=" #deleteIcon"></use>
                                                                                                    </svg>
                                                                                                </button>
                                                                                                <button
                                                                                                    type="button"
                                                                                                    onClick={(e) => {
                                                                                                        e.preventDefault();
                                                                                                        duplicateSkuLine(
                                                                                                            productData.indexValue
                                                                                                        );
                                                                                                    }}
                                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                                    title="Duplicate SKU Line"
                                                                                                >
                                                                                                    <svg className="svg-icon duplicate-icon">
                                                                                                        <use href="#duplicateIcon"></use>
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </div>
                                                                                        </td>
                                                                                    )}
                                                                            </ShowForPermissions>
                                                                        </tr>
                                                                    );
                                                                }
                                                            })()}
                                                        </React.Fragment>
                                                    )
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        {/* outside dropdown items , sku-no-match-dropdown-wrap need to set top and left for this dynamically according to the component*/}
                        <div className="dropdown-wrap sku-dropdown-wrap outside-menu menu-down  modal-dropdown">
                            <div className="dropdown-menu sku">
                                <div className="no-data-content">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No matches found in the Inventory. </p>
                                </div>
                            </div>
                        </div>
                        {/* outside dropdown items , destination-adress-dropdown-wrap need to set top and left for this dynamically according to the component*/}
                        <div
                            className={
                                showAddress
                                    ? 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                    : 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down modal-dropdown'
                            }
                            style={{ top: `${distanceFromTopForAddress}px`, left: `${distanceFromLeftForAddress}px` }}
                        >
                            <div className="dropdown-menu client">
                                {destinationAddress?.length === 0 && (
                                    <div className="no-data-content">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No matching Found</p>
                                    </div>
                                )}
                                <div className="customscrollbar">
                                    {destinationAddress?.length > 0 &&
                                        destinationAddress?.map((item: any, index: number) => (
                                            <a
                                                onClick={() => {
                                                    updateSkuData(item, currentIndex, 'destinationAddress');
                                                }}
                                                key={index}
                                                className="menu-item app-btn"
                                            >
                                                <span className="main-content">{item.fullAddress}</span>
                                            </a>
                                        ))}
                                </div>
                            </div>
                        </div>
                        {toggleDropdown && (
                            <div
                                style={{ left: String(setPosition) + 'px', top: '95px' }}
                                className="filter-menu searchable-dropdown-common"
                            >
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        className="search-input input-text grid-search-field"
                                        value={searchName}
                                        onChange={filterCheckbox}
                                    ></input>
                                    <svg className="svg-icon search-icon">
                                        <use xlinkHref="#searchIcon"></use>
                                    </svg>
                                    <button
                                        onClick={(e) => {
                                            setSearchName('');
                                            filterCheckbox(e);
                                        }}
                                        className="app-btn text-close-btn"
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                                <div className="checkbox-wrapper">
                                    {distinctValues &&
                                        distinctValues.map((value: string, index: number) => (
                                            <div key={index} className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        onChange={(e: any) => {
                                                            if (e.target.checked) {
                                                                setStatusFilterSelected((prev: any) => [
                                                                    ...prev,
                                                                    value,
                                                                ]);
                                                            } else {
                                                                const filtered = statusFilterSelected?.filter(
                                                                    (res: any) => res !== value
                                                                );
                                                                setStatusFilterSelected(filtered);
                                                            }
                                                            // setToggleDropdown(false);
                                                        }}
                                                        // defaultChecked={false}
                                                        checked={statusFilterSelected.includes(value)}
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                    />
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use xlinkHref="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">{value}</div>
                                                </label>
                                            </div>
                                        ))}
                                    {distinctValues?.length === 0 && (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No Matches Found</p>
                                        </div>
                                    )}
                                </div>
                                {distinctValues?.length > 0 && (
                                    <div className="clear-btn">
                                        <button onClick={() => setStatusFilterSelected([])} className="app-btn">
                                            <span className="button-text">Clear All</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/allocation_orders/${id}/dates`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    {/* {(headerData?.aoStatus === 'Draft' || headerData?.aoStatus === 'Pending_with_Forwarder') && (
                        <>
                            {headerData?.dataSourceName === 'ERP' ? (
                                <>
                                    {returnAllErrorAndWarningForIntegration() ? (
                                        <button
                                            type="button"
                                            className="app-btn app-btn-primary footer-btn"
                                            disabled={true}
                                            title="Next"
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </button>
                                    ) : (
                                        <Link
                                            to={`/allocation_orders/${id}/ao-preview`}
                                            className="app-btn app-btn-primary footer-btn"
                                            title="Next"
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                    )}
                                </>
                            ) : (
                                <>
                                    {returnAllErrorAndWarning('both')?.length ||
                                    !productAllocation?.productListAllocationOrderList?.length ? (
                                        <button
                                            type="button"
                                            className="app-btn app-btn-primary footer-btn"
                                            disabled={true}
                                            title="Next"
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </button>
                                    ) : (
                                        <Link
                                            to={`/allocation_orders/${id}/ao-preview`}
                                            className="app-btn app-btn-primary footer-btn"
                                            title="Next"
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                    )}
                                </>
                            )}
                        </>
                    )} */}
                    <AllocationOrderNextButton page="product-allocation" />
                </div>
            </div>
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
            <div style={{ zIndex: 9999 }} id="date-portal"></div>

            <RemoveSkuModal
                removeSkuModalVisibility={removeSkuModalVisibility}
                setRemoveSkuModalVisibility={setRemoveSkuModalVisibility}
                poProductId={poProductId}
            />
            {/* {unsavedChangeModalVisibility && (
                <CancelModal
                    onConfirm={onConfirm}
                    onCancel={() => {
                        setUnsavedChangeModalVisibility(false);
                    }}
                />
            )} */}

            <UnsavedChangeModal
                unsavedChangeModalVisibility={unsavedChangeModalVisibility}
                setUnsavedChangeModalVisibility={setUnsavedChangeModalVisibility}
                callBackFn={callBackFn}
            />
        </div>
    );
};
export default Index;
