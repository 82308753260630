import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    isLoadingFn,
    saveAutoApprovalData,
    fetchAutoApprovalData,
    saveAutoApprovalFailure,
    removeAttachment,
    uploadAttachment,
    saveAutoApprovalSuccess,
    saveUploadedAttachment,
    fetchAutoApprovalHistory,
    fetchAutoApprovalHistoryError,
    fetchAutoApprovalHistorySuccess,
} from 'src/store/reducers/autoApprovalReducer';

import {
    fetchAutoApprovalData as updateApprovalData,
    removeFile,
    saveAutoApprovalData as saveApprovalData,
    uploadFile,
    ApprovalData as ApprovalDataInterface,
    getAutoApprovalHistory,
} from 'src/services/apis/autoApprovalApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* updateAutoApprovalData({
    payload,
}: {
    payload: {
        payload: ApprovalDataInterface;
    };
    type: any;
}) {
    try {
        yield put(showLoading());
        const formData = new FormData();
        if (payload.payload.file) {
            formData.append('file', payload.payload.file);
        }
        formData.append(
            'document',
            new Blob([JSON.stringify(payload.payload.document)], {
                type: 'application/json',
            })
        );
        const response: AxiosResponse = yield call(saveApprovalData, formData);
        yield put({ type: saveAutoApprovalSuccess, payload: response });
        // get the latest auto approval data and update store
        const autoApprovalData: AxiosResponse = yield call(updateApprovalData, {
            clientId: payload.payload.document.clientId,
        });
        yield put({ type: fetchAutoApprovalData, payload: autoApprovalData });
    } catch (error) {
        yield put({ type: saveAutoApprovalFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* fetchAndUpdateAutoApprovalData({ payload }: { payload: { clientId: string }; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateApprovalData, payload);
        yield put({ type: fetchAutoApprovalData, payload: response });
        yield put({ type: isLoadingFn, payload: false });
    } catch (error) {
        //yield put({ type: saveAutoApprovalFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* uploadAttachmentFile({ payload }: { payload: { clientId: string; payload: FormData }; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(uploadFile, payload);
        yield put({ type: saveUploadedAttachment, payload: response });
    } catch (error) {
        // yield put({ type: saveAutoApprovalFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
function* removeAttachmentFile({ payload }: { payload: { clientId: string; fileId: string }; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(removeFile, payload);
        yield put({ type: removeAttachment, payload: response });
        yield put({ type: fetchAutoApprovalData, payload: response });
        yield put({ type: isLoadingFn, payload: false });
    } catch (error) {
        // yield put({ type: saveAutoApprovalFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* fetchAutoApprovalHistoryData({ payload }: { payload: { clientId: string }; type: any }) {
    try {
        const response: AxiosResponse = yield call(getAutoApprovalHistory, payload);
        yield put({ type: fetchAutoApprovalHistorySuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAutoApprovalHistoryError, payload: error });
    }
}

function* autoApprovalSaga() {
    yield takeEvery(saveAutoApprovalData, updateAutoApprovalData);
    yield takeEvery(fetchAutoApprovalData, fetchAndUpdateAutoApprovalData);
    yield takeEvery(uploadAttachment, uploadAttachmentFile);
    yield takeEvery(removeAttachment, removeAttachmentFile);
    yield takeEvery(fetchAutoApprovalHistory, fetchAutoApprovalHistoryData);
}

export default autoApprovalSaga;
