//APIs related in client auto approval settings
import axios from 'axios';
import { get, post, del } from '../HttpMethods';
import fileDownload from 'js-file-download';
import { fileUploadForDocuments } from './bookingProposal/additionalDetailsApi';

export interface ApprovalData {
    document: {
        clientId: string | null;
        autoApproveNAC: boolean;
        autoApproveFAK: boolean;
        autoApproveETD: boolean;
        authorisationType: 'SOP' | 'CONTRACT' | 'EMAIL';
    };
    file: any;
}

export const fetchAutoApprovalData = async ({ clientId }: { clientId: string | null }) => {
    try {
        const data = await get(`api/mgt/clients/autoapproval/${clientId}`, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            return error;
        }
    }
};

export const saveAutoApprovalData = async (payload: any) => {
    try {
        const data: any = await post(`api/mgt/clients/autoapproval`, payload, null);
        const file = payload?.get('file');
        if (file) {
            const document = payload?.get('document');
            const text = await document.text();
            const or_PK = JSON.parse(text)?.or_PK;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', or_PK);
            formData.append('DO_FKID', 'OR');
            formData.append('DO_CD_DocumentType', 'EML');
            await fileUploadForDocuments(formData);
        }
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const uploadFile = async ({ clientId, payload }: { clientId: string; payload: FormData }) => {
    try {
        const res = await post(`api/mgt/clients/autoapproval/upload/${clientId}`, payload, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const downloadFile = async ({ fileName, fileId }: { fileName: string; fileId: string }) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/clients/autoapproval/download?savedFileId=${fileId}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const removeFile = async ({ clientId, fileId }: { clientId: string; fileId: string }) => {
    try {
        const res = await del(`api/mgt/clients/autoapproval/remove/${clientId}?savedFileId=${fileId}`, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const getAutoApprovalHistory = async ({ clientId }: { clientId: string }) => {
    try {
        const data = await get(`api/mgt/clients/autoapproval/history/${clientId}`, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            throw error;
        }
    }
};
