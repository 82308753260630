import { createSlice } from '@reduxjs/toolkit';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { bookingProposalStatusAll, bpHeaders, awaitingClientApproval, bpActiveStatus } from '../../../utils/constants';
const { profileType } = useUserProfile();

export const dataSlice = createSlice({
    name: 'bookingProposalAirList',
    initialState: {
        bookingProposalsAir: [],
        filterValues: [],
        isLoading: true,
        currentPage: 0,
        columnStatus: bpHeaders.status,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        statusCount: {},
        error: null,
        success: false,
        isStatusLoading: true,
        isFilterLoading: true,
        filter: { bpStatus: profileType === 'client' ? awaitingClientApproval : bpActiveStatus },
        selectedDropDown: {
            text: profileType === 'client' ? awaitingClientApproval : bookingProposalStatusAll,
            count: 0,
        },
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchBpDataAir: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
        },
        saveBpDataAir: (state, action) => {
            state.bookingProposalsAir = action.payload.response?.bpListingDtos;
            state.totalPages = action.payload.response?.totalPages;
            state.totalItems = action.payload.response?.totalElements;
            state.isLoading = false;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        fetchBpFilterDataAir: (state, _action) => {
            state.isFilterLoading = true;
        },
        saveBpFilterDataAir: (state, action) => {
            state.filterValues = action.payload?.filterData;
            state.isFilterLoading = false;
        },
        fetchBpStatusCountAir: (state, _action) => {
            state.isStatusLoading = true;
        },
        saveBpStatusCountAir: (state, action) => {
            state.statusCount = action.payload?.count;
            state.isStatusLoading = false;
        },
        reset: (state, _action) => {
            state.success = false;
            state.error = null;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setSelectedDropDown: (state, action) => {
            state.selectedDropDown = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
});

export const {
    fetchBpDataAir,
    setPageCount,
    saveBpDataAir,
    setDefault,
    fetchBpFilterDataAir,
    fetchBpStatusCountAir,
    reset,
    saveBpFilterDataAir,
    saveBpStatusCountAir,
    setFilter,
    setSelectedDropDown,
    setCurrentPage,
} = dataSlice.actions;
export default dataSlice.reducer;
