import React from 'react';
import moment from 'moment';
import { useMeasure } from 'react-use';
import { useDispatch } from 'react-redux';

import { historyTableHeaders, paginationLimit } from 'src/utils/constants';
import Breadcrumbs from 'src/components/Breadcrumbs';
import {
    clearHistorys,
    fetchHistoryColumnList,
    fetchHistoryList,
    HistoryDocumentsDtoList,
    selectHistoryColumnStatus,
    selectHistoryColumnUser,
    selectHistoryCurrentPage,
    selectHistoryLoading,
    selectHistorytotalNoOfItems,
    selectHistorytotalNoOfPages,
    selectHistoryUser,
} from 'src/store/reducers/historyReducer';
import { useAppSelector } from 'src/hooks';
import useOutsideClickHandler from 'src/hooks/useOutsideClickHandler';
// import { useNavigate } from 'react-router-dom';
import { filterTableByColumn } from 'src/components/common/filterTableByColumn';
import Pagination from 'src/components/Pagination/Pagination';

import './user-history.scss';
// import ClickAwayListener from 'react-click-away-listener';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';

const UserHistory = () => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const elasticSearchRef = React.useRef('' as any);
    //====================== States =========================//
    const [searchName, setSearchName] = React.useState('');
    const [perPageCount, setPerPageCount] = React.useState(10);
    const [currentOrder, setcurrentOrder] = React.useState(false);
    const [PageCount, setPageCountDisplay] = React.useState(false);
    const [setPosition, setSetPosition] = React.useState<number>(0);
    const [historyColumnData, setHistoryColumnData] = React.useState<any>([]);
    const [historyColumnName, setHistoryColumnName] = React.useState<any>('');
    const [statusFilterSelected, setStatusFilterSelected] = React.useState<any>([]);
    const [currentPageNumber, setCurrentPageNumber] = React.useState<null | number>(null);
    const [searchTerm, setSearchTerm] = React.useState<string>('');
    const [selectedHeader, setSelectedHeader] = React.useState(historyTableHeaders.ActionedBy);
    const [currentSelectedFilter, setCurrentSelectedFilter] = React.useState('');
    //======================================================//

    //======================= measure hooks ================//
    const [refUserName, { width: widthUserName }] = useMeasure<HTMLTableCellElement>();
    const [refProfileType, { width: widthProfileType }] = useMeasure<HTMLTableCellElement>();
    const [refProfileName, { width: widthProfileName }] = useMeasure<HTMLTableCellElement>();
    const [refActivity, { width: widthActivity }] = useMeasure<HTMLTableCellElement>();
    //========================================================

    //===================== Hookes =========================//
    const { ref, isComponentVisible, setIsComponentVisible } = useOutsideClickHandler(false);
    useOutsideAlerter(ref, setIsComponentVisible);

    // outside click event
    const historyVal = useAppSelector(selectHistoryUser);
    const isLoading = useAppSelector(selectHistoryLoading);
    const currentPage = useAppSelector(selectHistoryCurrentPage);
    const historyColumn = useAppSelector(selectHistoryColumnUser);
    const columnStatus = useAppSelector(selectHistoryColumnStatus);
    const totalNoOfPages = useAppSelector(selectHistorytotalNoOfPages);
    const totalNoOfItems = useAppSelector(selectHistorytotalNoOfItems);
    //======================================================//

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        // eslint-disable-next-line no-undefined
        statusFilter: undefined,
        columnOrder: !currentOrder,
        totalNoOfPages: totalNoOfPages,
        totalNoOfItems: totalNoOfItems,
    };

    const fetchHistoryListCall = () => {
        dispatch(
            fetchHistoryList({
                ...payload,
                currentPage: 0,
                columnStatus: selectedHeader,
                statusFilterColumn: historyColumnName,
                statusFilter: statusFilterSelected,
                columnOrder: !currentOrder,
                // eslint-disable-next-line no-undefined
                keyword: undefined,
            })
        );
    };

    React.useEffect(() => {
        dispatch(clearHistorys([]));
        // fetchHistoryListCall();
    }, []);

    React.useEffect(() => {
        if (historyColumn) {
            setHistoryColumnData(historyColumn);
        } else {
            setHistoryColumnData([]);
        }
    }, [historyColumn]);

    React.useEffect(() => {
        if (historyColumnName && historyVal) {
            const dispatchAction = dispatch(
                fetchHistoryColumnList(historyVal.info.distinctColumnValues[historyColumnName])
            );
            filterTableByColumn(
                null,
                historyColumnName,
                setIsComponentVisible,
                isComponentVisible,
                dispatchAction,
                historyVal.info.distinctColumnValues,
                setSearchName,
                true
            );
        }
    }, [historyColumnName, historyVal]);

    React.useEffect(() => {
        if (statusFilterSelected) {
            if (statusFilterSelected?.length) {
                setCurrentSelectedFilter(historyColumnName);
            } else {
                setCurrentSelectedFilter('');
            }
            dispatch(
                fetchHistoryList({
                    ...payload,
                    currentPage: 0,
                    columnStatus: selectedHeader,
                    statusFilterColumn: historyColumnName,
                    statusFilter: statusFilterSelected,
                    columnOrder: !currentOrder,
                    // eslint-disable-next-line no-undefined
                    keyword: elasticSearchRef.current.value
                        ? elasticSearchRef.current.value
                        : // eslint-disable-next-line no-undefined
                          undefined,
                })
            );
        }
    }, [statusFilterSelected]);

    React.useEffect(() => {
        if (typeof currentPageNumber === 'number' && currentPage + 1 !== currentPageNumber) {
            dispatch(
                fetchHistoryList({
                    ...payload,
                    currentPage: currentPageNumber - 1,
                    perPageCount: perPageCount,
                    columnStatus: selectedHeader,
                    // statusFilter: selectedFilter,
                    columnOrder: !currentOrder,
                    statusFilterColumn: historyColumnName,
                    statusFilter: statusFilterSelected,
                    // eslint-disable-next-line no-undefined
                    keyword: elasticSearchRef.current.value
                        ? elasticSearchRef.current.value
                        : // eslint-disable-next-line no-undefined
                          undefined,
                })
            );
        }
    }, [currentPageNumber]);

    React.useEffect(() => {
        if (typeof currentPageNumber === 'number' && currentPage + 1 !== currentPageNumber) {
            setCurrentPageNumber(currentPage + 1);
        }
    }, [currentPage]);

    const filterByColumn = (event: any, name: string) => {
        // if (!name?.includes(historyColumnData) && statusFilterSelected?.length > 0) {
        // setStatusFilterSelected([]);
        // }
        if (historyVal) {
            if (historyVal.info) {
                if (historyVal.info.distinctColumnValues) {
                    const dispatchAction = dispatch(fetchHistoryColumnList(historyVal.info.distinctColumnValues[name]));
                    filterTableByColumn(
                        event,
                        name,
                        setIsComponentVisible,
                        isComponentVisible,
                        dispatchAction,
                        historyVal.info.distinctColumnValues,
                        setSearchName,
                        false
                    );
                }
            }
        }
        setHistoryColumnName(name);
        filter(event);
    };
    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        if (keyword) {
            const results = historyColumn?.filter((user: string) => {
                return user && user.toLowerCase()?.includes(keyword.toLowerCase());
            });
            if ('carrier'.includes(keyword.toLowerCase())) {
                results.push(historyColumn?.find((res: string) => res?.toLowerCase()?.includes('shipping')));
            }

            setHistoryColumnData(results);
        } else {
            setHistoryColumnData(historyColumn);
        }
        setSearchName(keyword);
    };

    return (
        <div
            onClick={() => {
                setPageCountDisplay(false);
            }}
            className="main-wrapper"
        >
            <div className="main-header-content-holder grid-pages">
                <Breadcrumbs second={'Users'} third={'History'} />
                <div className="pageheading-holder">
                    {/* <button className="back-btn app-btn" onClick={() => navigate(-1)}>
                        <svg className="svg-icon back-icon">
                            <use xlinkHref="#previousArrowIcon">
                                <title>Back</title>
                            </use>
                        </svg>
                    </button> */}
                    <h2 className="page-heading">History</h2>
                </div>
            </div>
            <div className="main-content-holder">
                <div className="grid-section user-history-grid loader-container">
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div id="grid_holder" className="grid-holder">
                        <div className="grid-header">
                            <div className="search-entry-holder">
                                <input
                                    ref={elasticSearchRef}
                                    placeholder="Search"
                                    type="text"
                                    className="search-input input-text"
                                    onChange={(e) => {
                                        setSearchTerm(e.target.value);
                                        elasticSearchRef.current.value = e.target.value;
                                        if (e.target.value?.trim()?.length > 2) {
                                            dispatch(
                                                fetchHistoryList({
                                                    ...payload,
                                                    currentPage: 0,
                                                    columnStatus: selectedHeader,
                                                    statusFilterColumn: historyColumnName,
                                                    statusFilter: statusFilterSelected,
                                                    columnOrder: !currentOrder,
                                                    // eslint-disable-next-line no-undefined
                                                    keyword: e.target.value ? e.target.value : undefined,
                                                })
                                            );
                                        } else if (e.target.value?.trim()?.length === 0) {
                                            dispatch(clearHistorys([]));
                                            fetchHistoryListCall();
                                        }
                                    }}
                                ></input>
                                <div
                                    className={`search-toolip ${
                                        searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                    }`}
                                >
                                    Enter atleast 3 characters to search
                                </div>
                                <svg className="svg-icon search-icon">
                                    <use xlinkHref="#searchIcon"></use>
                                </svg>
                                <button
                                    className="app-btn text-close-btn"
                                    onClick={() => {
                                        if (elasticSearchRef.current.value?.length !== 0) {
                                            dispatch(clearHistorys([]));
                                            fetchHistoryListCall();
                                        }
                                        elasticSearchRef.current.value = '';
                                    }}
                                >
                                    <svg className="svg-icon text-close-icon">
                                        <use xlinkHref="#closeIcon"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="grid-container" onScroll={() => setIsComponentVisible(false)}>
                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <thead>
                                        <tr className="grid-sticky-row">
                                            <th ref={refUserName} className="grid-cell-header col-user-history-name">
                                                <div className="grid-cell-data">
                                                    <span>NAME</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(historyTableHeaders.UserName);
                                                            dispatch(
                                                                fetchHistoryList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: historyTableHeaders.UserName,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: historyColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                    keyword: elasticSearchRef.current.value
                                                                        ? elasticSearchRef.current.value
                                                                        : // eslint-disable-next-line no-undefined
                                                                          undefined,
                                                                })
                                                            );
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === historyTableHeaders.UserName
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            {/* class to reverse order icon:  sort-desc */}
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            (isComponentVisible &&
                                                                historyColumnName === historyTableHeaders.UserName) ||
                                                            currentSelectedFilter === historyTableHeaders.UserName
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                setSetPosition(0);
                                                                filterByColumn(e, historyTableHeaders.UserName);
                                                                setIsComponentVisible(!isComponentVisible);
                                                            }}
                                                            className="app-btn filter-menu-button"
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th
                                                ref={refProfileType}
                                                id="profile_type"
                                                className="grid-cell-header col-history-profile-type"
                                            >
                                                <div className="grid-cell-data">
                                                    <span>PROFILE TYPE</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(historyTableHeaders.ProfileType);
                                                            dispatch(
                                                                fetchHistoryList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: historyTableHeaders.ProfileType,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: historyColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                    keyword: elasticSearchRef.current.value
                                                                        ? elasticSearchRef.current.value
                                                                        : // eslint-disable-next-line no-undefined
                                                                          undefined,
                                                                })
                                                            );
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === historyTableHeaders.ProfileType
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            (isComponentVisible &&
                                                                historyColumnName ===
                                                                    historyTableHeaders.ProfileType) ||
                                                            currentSelectedFilter === historyTableHeaders.ProfileType
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                setSetPosition(widthUserName);
                                                                filterByColumn(e, historyTableHeaders.ProfileType);
                                                                setIsComponentVisible(!isComponentVisible);
                                                            }}
                                                            className="app-btn filter-menu-button"
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th
                                                ref={refProfileName}
                                                className="grid-cell-header col-history-profile-name"
                                            >
                                                <div className="grid-cell-data">
                                                    <span>PROFILE NAME </span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(historyTableHeaders.ProfileName);
                                                            dispatch(
                                                                fetchHistoryList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: historyTableHeaders.ProfileName,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: historyColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                    keyword: elasticSearchRef.current.value
                                                                        ? elasticSearchRef.current.value
                                                                        : // eslint-disable-next-line no-undefined
                                                                          undefined,
                                                                })
                                                            );
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === historyTableHeaders.ProfileName
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            (isComponentVisible &&
                                                                historyColumnName ===
                                                                    historyTableHeaders.ProfileName) ||
                                                            currentSelectedFilter === historyTableHeaders.ProfileName
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                setSetPosition(widthUserName + widthProfileType);
                                                                filterByColumn(e, historyTableHeaders.ProfileName);
                                                                setIsComponentVisible(!isComponentVisible);
                                                            }}
                                                            className="app-btn filter-menu-button"
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th
                                                ref={refActivity}
                                                className="grid-cell-header col-history-profile-activity"
                                            >
                                                <div className="grid-cell-data">
                                                    <span>ACTIVITY</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(historyTableHeaders.Activity);
                                                            dispatch(
                                                                fetchHistoryList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: historyTableHeaders.Activity,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: historyColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                    keyword: elasticSearchRef.current.value
                                                                        ? elasticSearchRef.current.value
                                                                        : // eslint-disable-next-line no-undefined
                                                                          undefined,
                                                                })
                                                            );
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === historyTableHeaders.Activity
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            (isComponentVisible &&
                                                                historyColumnName === historyTableHeaders.Activity) ||
                                                            currentSelectedFilter === historyTableHeaders.Activity
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                setSetPosition(
                                                                    widthUserName + widthProfileType + widthProfileName
                                                                );
                                                                filterByColumn(e, historyTableHeaders.Activity);
                                                                setIsComponentVisible(!isComponentVisible);
                                                            }}
                                                            className="app-btn filter-menu-button"
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-history-actioned-by">
                                                <div className="grid-cell-data">
                                                    <span>ACTIONED BY</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(historyTableHeaders.ActionedBy);
                                                            dispatch(
                                                                fetchHistoryList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: historyTableHeaders.ActionedBy,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: historyColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                    keyword: elasticSearchRef.current.value
                                                                        ? elasticSearchRef.current.value
                                                                        : // eslint-disable-next-line no-undefined
                                                                          undefined,
                                                                })
                                                            );
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === historyTableHeaders.ActionedBy
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                    <div
                                                        className={
                                                            (isComponentVisible &&
                                                                historyColumnName === historyTableHeaders.ActionedBy) ||
                                                            currentSelectedFilter === historyTableHeaders.ActionedBy
                                                                ? 'filter-menu-wrap open'
                                                                : 'filter-menu-wrap'
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                // setToggleDropdown(!toggleDropdown);
                                                                setSetPosition(
                                                                    widthUserName +
                                                                        widthProfileType +
                                                                        widthProfileName +
                                                                        widthActivity
                                                                );
                                                                filterByColumn(e, historyTableHeaders.ActionedBy);
                                                            }}
                                                            className="app-btn filter-menu-button"
                                                        >
                                                            <svg className="svg-icon filter-icon">
                                                                <use xlinkHref="#filterIcon">
                                                                    <title>filter</title>
                                                                </use>
                                                            </svg>
                                                            <span className="sr-only">header menu</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-history-actioned-on">
                                                <div className="grid-cell-data">
                                                    <span>ACTIONED ON</span>
                                                    <button
                                                        onClick={() => {
                                                            setcurrentOrder(!currentOrder);
                                                            setSelectedHeader(historyTableHeaders.ActionedOn);
                                                            dispatch(
                                                                fetchHistoryList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    columnStatus: historyTableHeaders.ActionedOn,
                                                                    columnOrder: currentOrder,
                                                                    statusFilterColumn: historyColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                    keyword: elasticSearchRef.current.value
                                                                        ? elasticSearchRef.current.value
                                                                        : // eslint-disable-next-line no-undefined
                                                                          undefined,
                                                                })
                                                            );
                                                        }}
                                                        className="sort-btn app-btn"
                                                    >
                                                        <svg
                                                            className={
                                                                columnStatus === historyTableHeaders.ActionedOn
                                                                    ? `svg-icon sort-icon ${
                                                                          currentOrder ? 'sort-asc' : 'sort-desc'
                                                                      }`
                                                                    : 'svg-icon sort-icon'
                                                            }
                                                        >
                                                            <use xlinkHref="#sortIcon">
                                                                <title>sort</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {historyVal?.info?.userLogDtoList.map(
                                            (detail: HistoryDocumentsDtoList, index: number) => (
                                                <tr key={index} className="has-child row-expanded">
                                                    <td className="grid-cell user-height">
                                                        <div className="grid-cell-data">
                                                            <span className="client-id">{detail.name}</span>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {detail.ul_profileType?.toLowerCase()?.includes('shipping')
                                                                ? 'Carrier'
                                                                : detail.ul_profileType}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{detail.ul_profileName}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{detail.ul_action}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{detail.modifiedByUser}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <span className="grid-history-action-date">
                                                            {moment
                                                                .utc(detail.createdDateTime)
                                                                .local()
                                                                .format('DD-MM-YYYY HH:mm:ss')}
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </div>
                            {(historyVal?.info?.userLogDtoList?.length === 0 || !historyVal?.info?.userLogDtoList) && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No records found</p>
                                </div>
                            )}
                        </div>
                        {/* </ClickAwayListener> */}
                        {/* filter dropdown for actioned by */}

                        {isComponentVisible && (
                            <div
                                style={{ left: String(setPosition) + 'px' }}
                                className="filter-menu searchable-dropdown"
                                ref={ref}
                            >
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        className="search-input input-text grid-search-field"
                                        value={searchName}
                                        onChange={filter}
                                    ></input>
                                    <svg className="svg-icon search-icon">
                                        <use xlinkHref="#searchIcon"></use>
                                    </svg>
                                    <button
                                        onClick={(e) => {
                                            setSearchName('');
                                            filter(e);
                                        }}
                                        className="app-btn text-close-btn"
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                                <div className="checkbox-wrapper">
                                    {historyColumnData &&
                                        historyColumnData.map(
                                            (value: string, index: number) =>
                                                value !== '' && (
                                                    <div key={index} className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                onChange={(e: any) => {
                                                                    statusFilterSelected.filter(
                                                                        (item: string, index: number) => {
                                                                            if (
                                                                                statusFilterSelected.indexOf(item) ==
                                                                                index
                                                                            ) {
                                                                                return item;
                                                                            }
                                                                        }
                                                                    );

                                                                    if (e.target.checked) {
                                                                        setStatusFilterSelected((_prev: any) => {
                                                                            // return [...prev, value];
                                                                            return [value];
                                                                        });
                                                                    } else {
                                                                        setStatusFilterSelected(
                                                                            statusFilterSelected.filter(
                                                                                (item: string) => item != value
                                                                            )
                                                                        );
                                                                        if (statusFilterSelected?.length === 1) {
                                                                            setIsComponentVisible(!isComponentVisible);
                                                                        }
                                                                    }
                                                                }}
                                                                // defaultChecked={false}
                                                                checked={statusFilterSelected?.includes(value)}
                                                                type="checkbox"
                                                                className="checkbox-input"
                                                            />
                                                            <div className="checkmark">
                                                                <svg className="svg-icon tick-icon">
                                                                    <use xlinkHref="#tickIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div
                                                                className="checkbox-label"
                                                                style={{ textTransform: 'capitalize' }}
                                                            >
                                                                {value?.toLowerCase()?.includes('shipping')
                                                                    ? 'Carrier'
                                                                    : value}
                                                            </div>
                                                        </label>
                                                    </div>
                                                )
                                        )}
                                    {historyColumnData?.length === 0 && !isLoading && (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No Matches Found</p>
                                        </div>
                                    )}
                                </div>
                                {historyColumnData?.length > 0 && (
                                    <div className="clear-btn">
                                        <button
                                            onClick={() => {
                                                setStatusFilterSelected([]);
                                                setIsComponentVisible(!isComponentVisible);
                                                elasticSearchRef.current.value = '';
                                            }}
                                            className="app-btn"
                                        >
                                            <span className="button-text">Clear All</span>
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}
                        {totalNoOfItems > 0 ? (
                            <div className="grid-footer ">
                                <div className="grid-footer-left">
                                    <div className="footer-text">Showing</div>
                                    <div className="row-per-page">
                                        <div
                                            className={
                                                'dropdown-wrap pagination-dropdown menu-up ' +
                                                (PageCount ? 'dropdown-open' : '')
                                            }
                                        >
                                            <button
                                                className="menu-btn app-btn"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setPageCountDisplay(!PageCount);
                                                }}
                                            >
                                                <span className="btn-text">
                                                    {historyVal?.info?.userLogDtoList &&
                                                    perPageCount > historyVal?.info?.userLogDtoList.length
                                                        ? historyVal?.info?.userLogDtoList.length
                                                        : perPageCount}
                                                </span>
                                                <span className="dropdown-arrow">
                                                    <svg className="svg-icon arrow-icon">
                                                        <use xlinkHref="#downArrow">
                                                            <title>dropdown</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                            </button>
                                            {PageCount && (
                                                <div className="dropdown-menu">
                                                    {paginationLimit.map((res, index: number) => (
                                                        <a
                                                            key={index}
                                                            onClick={(e) => {
                                                                setPageCountDisplay(false);
                                                                e.stopPropagation();
                                                                setPerPageCount(res);
                                                                dispatch(
                                                                    fetchHistoryList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        perPageCount: res,
                                                                        columnStatus: selectedHeader,
                                                                        columnOrder: !currentOrder,
                                                                        statusFilterColumn: historyColumnName,
                                                                        statusFilter: statusFilterSelected,
                                                                        keyword: elasticSearchRef.current.value
                                                                            ? elasticSearchRef.current.value
                                                                            : // eslint-disable-next-line no-undefined
                                                                              undefined,
                                                                    })
                                                                );
                                                            }}
                                                            className="menu-item app-btn"
                                                        >
                                                            {res}
                                                        </a>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="footer-text">
                                        <span>of</span>
                                        <span className="grid-total-row-count">
                                            {totalNoOfItems ? totalNoOfItems : 0}
                                        </span>
                                        <span>{totalNoOfItems > 1 ? 'entries' : 'entry'}</span>
                                    </div>
                                </div>
                                <div className="grid-footer-right">
                                    <div className="grid-pagination">
                                        <Pagination
                                            currentPage={currentPage + 1}
                                            totalCount={totalNoOfItems}
                                            pageSize={perPageCount}
                                            onPageChange={(page: number) => setCurrentPageNumber(page)}
                                        />
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserHistory;
