/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import ShipmentModal from './ShipmentModal';
import ConfirmBookingModal from './ConfirmBookingModal';
import ZeroQuantityModal from './ZeroQuantityModal';
import ModifyProductModal from './ModifyProductModal';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    selectProductPoConfirmation,
    fetchProductPoConfirmationList,
    selectProductPoConfirmationLoading,
    selectCountConfirmed,
    selectAllConfirmed,
    ProductPoConfirmationDataDetails,
    SplitLines,
    // selectAllPoLineConfirmed,
    confirmPo,
    confirmAllPo,
    selectProductPoConfirmationIsError,
    selectProductPoConfirmationSuccess,
    selectProductPoConfirmationError,
    clearFailure,
    removeProductPoConfirmation,
    editPoLineStatus,
    changePoLineStatus,
} from '../../../store/reducers/purchaseOrder/productPoConfirmationReducer';
import moment from 'moment';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
// import ShowForPermissions from '../../../ShowForPermissions';
// import CancelModal from '../../../components/common/CancelModal';
import { fetchHeader, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import './index.scss';
import DatePicker from '../../../components/common/DatePicker/withoutFormik';
import { error, Position, success } from '../../../utils/toast';
import { fetchPermissions } from '../../../store/reducers/permissionReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
const Index: React.FC = () => {
    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [modalViewShort, setModalViewShort] = useState(false);
    const [modalViewConfirm, setModalViewConfirm] = useState(false);
    const [modalViewZero, setModalViewZero] = useState(false);
    const [modalViewModifyProduct, setModalViewModifyProduct] = useState(false);
    const [disableConfirmPoButton, setDisableConfirmPoButton] = useState(false);
    const [addedNewPo, setAddedNewPo] = useState(false);
    const [productPo, setProductPo] = useState<ProductPoConfirmationDataDetails[]>([]);
    const [zeroProductData, setZeroProductData] = useState<any>({});
    const [shortShipmentList, setShortShipmentList] = useState<any>({});
    const [currentSplitLine, setCurrentSplitLine] = useState('');
    const [disableConfirmAll, setDisableConfirmAll] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentSplitIndex, setCurrentSplitIndex] = useState(0);
    const productPoConfirmation = useAppSelector(selectProductPoConfirmation);
    const countConfirmed = useAppSelector(selectCountConfirmed);
    const allConfirmed = useAppSelector(selectAllConfirmed);
    // const allPoLineConfirmed = useAppSelector(selectAllPoLineConfirmed);
    const isLoading = useAppSelector(selectProductPoConfirmationLoading);
    const isError = useAppSelector(selectProductPoConfirmationIsError);
    const productPoConfirmationSuccess = useAppSelector(selectProductPoConfirmationSuccess);
    const productPoConfirmationError = useAppSelector(selectProductPoConfirmationError);
    const headerData = useAppSelector(selectHeader);
    const userData = localStorage.getItem('user');
    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;
    const roleIndex = localStorage.getItem('roleIndex');
    useEffect(() => {
        dispatch(
            fetchProductPoConfirmationList({
                poId: id,
            })
        );
        dispatch(fetchPermissions(username));
    }, []);

    useEffect(() => {
        if (productPoConfirmation?.length) {
            const newArr = JSON.parse(JSON.stringify(productPoConfirmation));
            for (let index = 0; index < newArr.length; index++) {
                newArr[index]['eachCbm'] = newArr[index]['cbm'] / newArr[index]['qty'];
                newArr[index]['eachWeight'] = newArr[index]['weight'] / newArr[index]['qty'];
            }
            setProductPo(newArr);
        } else {
            setProductPo([]);
        }
    }, [productPoConfirmation]);

    const appendNewRow = (index: number) => {
        const newData = {
            splitLineId: null,
            splitLineStatus: null,
            confirmedQty: null,
            confirmedGoodsdate: '',
            confirmedCbm: '',
            confirmedWeight: '',
            confirmedUom: '',
            convertedQuantity: '',
        };
        const newArr = JSON.parse(JSON.stringify(productPo));
        newArr[index].splitLines.push(newData);
        setProductPo(newArr);
        setAddedNewPo(false);
    };

    useEffect(() => {
        if (productPo?.length) {
            let disabled = true;
            let disabledPoButton = false;
            for (let i = 0; i < productPo.length; i++) {
                for (let j = 0; j < productPo[i].splitLines.length; j++) {
                    if (
                        (productPo[i].splitLines[j].splitLineStatus?.toLowerCase() === 'pending confirmation' ||
                            (productPo[i].splitLines[j].splitLineStatus === null && productPo[i].qty !== 0)) &&
                        productPo[i].splitLines[j].confirmedQty >= 0 &&
                        productPo[i].splitLines[j].confirmedGoodsdate
                    ) {
                        if (disabled) {
                            disabled = false;
                        }
                    }
                    if (
                        productPo[i].splitLines[j].splitLineStatus?.toLowerCase() === 'pending_confirmation' ||
                        productPo[i].splitLines[j].splitLineStatus?.toLowerCase() === 'pending confirmation' ||
                        (productPo[i].splitLines[j].splitLineStatus === null && productPo[i].qty !== 0)
                    ) {
                        disabledPoButton = true;
                        if (!disabledPoButton) {
                            disabledPoButton = true;
                        }
                    }
                }
            }

            setDisableConfirmAll(disabled);
            if (disabledPoButton) {
                setDisableConfirmPoButton(true);
            } else {
                setDisableConfirmPoButton(false);
            }
            if (addedNewPo) {
                appendNewRow(currentIndex);
            }
        }
    }, [productPo]);

    const updateFieldChanged = (index: number, splitLineIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        newArr[index].splitLines[splitLineIndex] = {
            ...newArr[index].splitLines[splitLineIndex],
            [e.target.name]: +e.target.value,
            confirmedCbm: +e.target.value * newArr[index].eachCbm,
            confirmedWeight: +e.target.value * newArr[index].eachWeight,
            confirmedUom: newArr[index].uom,
            convertedQuantity: +e.target.value > 0 ? Math.ceil(+e.target.value / newArr[index].conversionQuantity) : 0,
        };

        setProductPo(newArr);
    };
    const updateDateChanged = (date: string) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        newArr[currentIndex].splitLines[currentSplitIndex] = {
            ...newArr[currentIndex].splitLines[currentSplitIndex],
            confirmedGoodsdate: moment(date).format('YYYY-MM-DD'),
        };

        setProductPo(newArr);
    };

    const createSplitLine = (index: number, splitLineId: string, splitLineStatus: string) => {
        if (splitLineStatus === 'Pending Confirmation' || splitLineStatus === null) {
            appendNewRow(index);
        } else {
            setCurrentIndex(index);

            dispatch(changePoLineStatus({ splitLineId, poId: id, poLineNumber: index + 1 }));
        }
    };

    const removePoLine = (index: number, splitLineIndex: number, splitLineId: string) => {
        if (splitLineId) {
            dispatch(removeProductPoConfirmation({ splitLineId }));
        } else {
            const newArr = JSON.parse(JSON.stringify(productPo));
            newArr[index].splitLines.splice(splitLineIndex, 1);

            setProductPo(newArr);
        }
    };
    const confirmPoLine = (index: number, splitLineIndex: number, splitLineStatus: string) => {
        const data = productPo[index].splitLines[splitLineIndex];
        if (data.confirmedQty === 0) {
            setModalViewZero(true);
            setZeroProductData({ index, splitLineIndex, splitLineStatus, type: 'confirmPoLine' });
        } else {
            if (
                splitLineStatus?.toLowerCase() === 'pending_confirmation' ||
                splitLineStatus?.toLowerCase() === 'pending confirmation'
            ) {
                dispatch(editPoLineStatus({ ...data, splitLineId: data.splitLineId }));
            } else {
                dispatch(confirmPo({ ...data, poProductId: productPo[index].poProductId }));
            }
        }
    };
    const confirmAllPoLine = () => {
        const tempArr: any = [];
        const newArr = JSON.parse(JSON.stringify(productPo));
        let zeroQuantity = false;
        for (let index = 0; index < newArr.length; index++) {
            for (let j = 0; j < newArr[index].splitLines.length; j++) {
                if (
                    (newArr[index].splitLines[j].splitLineStatus === null ||
                        newArr[index].splitLines[j].splitLineStatus?.toLowerCase() === 'pending_confirmation' ||
                        newArr[index].splitLines[j].splitLineStatus?.toLowerCase() === 'pending confirmation') &&
                    newArr[index].splitLines[j].confirmedQty >= 0 &&
                    newArr[index].splitLines[j].confirmedGoodsdate &&
                    newArr[index].qty > 0
                ) {
                    newArr[index].splitLines[j].poProductId = newArr[index].poProductId;
                    newArr[index].splitLines[j].lineId = newArr[index].splitLines[j].splitLineId;
                    tempArr.push(newArr[index].splitLines[j]);
                }
                if (zeroQuantity === false) {
                    if (
                        (newArr[index].splitLines[j].splitLineStatus === null ||
                            newArr[index].splitLines[j].splitLineStatus?.toLowerCase() === 'pending_confirmation' ||
                            newArr[index].splitLines[j].splitLineStatus?.toLowerCase() === 'pending confirmation') &&
                        newArr[index].splitLines[j].confirmedQty === 0 &&
                        newArr[index].splitLines[j].confirmedGoodsdate &&
                        newArr[index].qty > 0
                    ) {
                        zeroQuantity = true;
                    }
                }
            }
        }
        if (zeroQuantity) {
            setModalViewZero(true);
            setZeroProductData({ poLines: tempArr, type: 'confirmAllPoLine' });
        } else {
            dispatch(confirmAllPo({ poLines: tempArr }));
        }
    };

    const editPoLine = (splitLineId: string) => {
        if (allConfirmed) {
            setModalViewModifyProduct(true);
            setCurrentSplitLine(splitLineId);
        } else {
            dispatch(changePoLineStatus({ splitLineId, poId: id }));
        }
    };
    const submitPoLine = () => {
        const shortShipment = [];
        const surplusShipment = [];
        let isSurplus = false;
        let isShort = false;
        for (let i = 0; i < productPo.length; i++) {
            let confirmedQty = 0;
            for (let j = 0; j < productPo[i].splitLines.length; j++) {
                confirmedQty = confirmedQty + productPo[i].splitLines[j].confirmedQty;
            }
            if (confirmedQty < productPo[i].qty && productPo[i].qty !== 0) {
                shortShipment.push(i + 1);
                isShort = true;
            }
            if (confirmedQty > productPo[i].qty && productPo[i].qty !== 0) {
                surplusShipment.push(i + 1);
                isSurplus = true;
            }
        }

        if (shortShipment.length || surplusShipment.length) {
            setModalViewShort(true);
            if (isSurplus) {
                setShortShipmentList({
                    data: surplusShipment,
                    shortShipmentData: shortShipment,
                    type: isShort ? 'both' : 'surplus',
                });
            } else if (isShort) {
                setShortShipmentList({ data: shortShipment, type: 'short' });
            }
        } else {
            setModalViewConfirm(true);
        }
    };

    useEffect(() => {
        if (isError) {
            error(productPoConfirmationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (productPoConfirmationSuccess?.status === 200 || productPoConfirmationSuccess?.status === 201) {
            if (
                productPoConfirmationSuccess?.data?.type === 'confirmPo' ||
                productPoConfirmationSuccess?.data?.type === 'editPoLineStatus'
            ) {
                success(
                    `PO Line ${
                        productPoConfirmationSuccess?.data?.info?.poLineNumber
                            ? productPoConfirmationSuccess?.data?.info?.poLineNumber
                            : ''
                    } confirmed successfully`,
                    Position.TOP_RIGHT
                );
            } else if (productPoConfirmationSuccess?.data?.type === 'confirmAllPo') {
                success('All PO Lines confirmed', Position.TOP_RIGHT);
            } else if (productPoConfirmationSuccess?.data?.type === 'submitPo') {
                success(`PO ${headerData?.poNumber} has been submitted for receipt creation`, Position.TOP_RIGHT);
                setTimeout(() => navigate('/inbound'), 2000);
            } else if (productPoConfirmationSuccess?.data?.type === 'removePo') {
                success('Split line removed successfully', Position.TOP_RIGHT);
            } else if (productPoConfirmationSuccess?.data?.type === 'changePoLineStatus') {
                productPoConfirmationSuccess?.data?.poLineNumber && setAddedNewPo(true);
            }
            dispatch(clearFailure([]));
            dispatch(fetchProductPoConfirmationList({ poId: id }));
            dispatch(fetchHeader({ poId: id }));
        }
    }, [isError, productPoConfirmationSuccess, productPoConfirmationError]);

    const checkPermission = (permission: any, type: any) => {
        var flag = true;

        for (var i = 0; i < permissions?.length; i++) {
            if (
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(type.toLowerCase())
            ) {
                flag = false;
            }
        }
        return flag;
    };
    let isAgent = false;
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            isAgent = roles[roleIndex]?.isBlp == true ? true : false;
        }
    }

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName="Inbound PO"
                        firstUrl="/inbound"
                        secondName="Inbound PO Details"
                        secondUrl={`/inbound/${id}/product_details_confirmation`}
                    />
                    <PurchaseOrderHeader id={id} />
                    <div className="arrow-tab-container">
                        <div className="arrow-tab-holder">
                            <Link
                                aria-current="page"
                                className="arrow-tab-border po-basic-tab"
                                to={`/inbound/${id}/basic_details`}
                            >
                                <div className="arrow-badge">1</div>
                                <div className="arrow-tab">Basic Details</div>
                            </Link>
                            <Link className="arrow-tab-border po-date-tab" to={`/inbound/${id}/dates`}>
                                <div className="arrow-badge">2</div>
                                <div className="arrow-tab ">Dates</div>
                            </Link>
                            <Link className="arrow-tab-border po-product-tab" to={`/inbound/${id}/product_details`}>
                                <div className="arrow-badge">3</div>
                                <div className="arrow-tab">Product Details</div>
                            </Link>
                            <Link
                                className="arrow-tab-border po-review-tab active"
                                to={`/inbound/${id}/product_details_confirmation`}
                            >
                                <div className="arrow-badge">4</div>
                                <div className="arrow-tab">Product Details Confirmation</div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="grid-section product-details-grid">
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="grid-header--wrapper">
                                <div className="grid-header--title">Product Details</div>
                                <div className="grid-header--status">
                                    <div className="grid-header--count">
                                        {countConfirmed}/{productPo?.length}
                                    </div>
                                    <div className="grid-header--caption">PO Lines Confirmed</div>
                                </div>
                            </div>
                            {isAgent && (
                                <>
                                    {checkPermission('enrich', 'inbound') !== undefined &&
                                    !checkPermission('enrich', 'inbound') &&
                                    // allConfirmed === false &&
                                    (headerData?.poStatus === 'Awaiting_Enrichment' ||
                                        headerData?.poStatus === 'Inbound_In_Progress' ||
                                        headerData?.poStatus === 'Ready_For_Booking') &&
                                    disableConfirmAll ? (
                                        <button
                                            className="app-btn app-btn-secondary icon-button grid-top-btn confirm-all-btn disabled"
                                            title="Confirm all"
                                            type="button"
                                            disabled={
                                                checkPermission('enrich', 'inbound') !== undefined &&
                                                checkPermission('enrich', 'inbound')
                                            }
                                        >
                                            <svg className="svg-icon tick-icon">
                                                <use xlinkHref="#ticktableIcon">Confirm all</use>
                                            </svg>
                                            <span className="button-text vendor-button-text">Confirm all</span>
                                        </button>
                                    ) : (
                                        <button
                                            className="app-btn app-btn-secondary icon-button grid-top-btn confirm-all-btn"
                                            title="Confirm all"
                                            onClick={() => confirmAllPoLine()}
                                            type="button"
                                            disabled={
                                                checkPermission('enrich', 'inbound') !== undefined &&
                                                checkPermission('enrich', 'inbound')
                                            }
                                        >
                                            <svg className="svg-icon tick-icon">
                                                <use xlinkHref="#ticktableIcon">Confirm all</use>
                                            </svg>
                                            <span className="button-text vendor-button-text">Confirm all</span>
                                        </button>
                                    )}
                                </>
                            )}
                        </div>

                        <div className="grid-container">
                            {(!productPo || productPo?.length === 0) && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No PO Lines added</p>
                                </div>
                            )}
                            <div className="grid-scroll-content">
                                <table className="grid-table product-details-conf">
                                    <thead className="multi-row-grid-header">
                                        <tr className="grid-sticky-row">
                                            <th className="grid-cell-header col-po-no po-th-no grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data">
                                                    <span>PO lIne NO.</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-sku po-th-sku grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data">
                                                    <span>SKU</span>
                                                </div>
                                            </th>

                                            <th className="grid-cell-header col-description  po-th-desc">
                                                <div className="grid-cell-data">
                                                    <span>DESCRIPTION</span>
                                                </div>
                                            </th>
                                            {allConfirmed && (
                                                <th className="grid-cell-header po-th-line">
                                                    <div className="grid-cell-data">
                                                        <span>PO LINE STATUS</span>
                                                    </div>
                                                </th>
                                            )}
                                            <th
                                                className="grid-cell-header multi-column-th  po-th-qty centre-align"
                                                colSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span>QUANTITY</span>
                                                </div>
                                            </th>
                                            <th
                                                className="grid-cell-header multi-column-th po-th-grd centre-align"
                                                colSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span>GOODS READY DATE</span>
                                                </div>
                                            </th>
                                            <th
                                                className="grid-cell-header multi-column-th po-th-grd centre-align"
                                                colSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span>CBM</span>
                                                </div>
                                            </th>
                                            <th
                                                className="grid-cell-header multi-column-th po-th-grd centre-align"
                                                colSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span>WEIGHT(KG)</span>
                                                </div>
                                            </th>
                                            <th
                                                className="grid-cell-header multi-column-th po-th-grd centre-align"
                                                colSpan={2}
                                            >
                                                <div className="grid-cell-data">
                                                    <span>CONVERTED QTY & UOM</span>
                                                </div>
                                            </th>
                                            {/* <th className="grid-cell-header col-description  po-th-desc">
                                                <div className="grid-cell-data">
                                                    <span>ORIGIN</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-description  po-th-desc">
                                                <div className="grid-cell-data">
                                                    <span>DESTINATION</span>
                                                </div>
                                            </th> */}
                                            {checkPermission('enrich', 'inbound') !== undefined &&
                                                !checkPermission('enrich', 'inbound') &&
                                                isAgent && (
                                                    <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-user-action ">
                                                        <div className="grid-cell-data">ACTION</div>
                                                    </th>
                                                )}
                                        </tr>
                                        <tr className="grid-sticky-row">
                                            <th className="grid-cell-header col-po-no grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data">
                                                    <span></span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-sku grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data">
                                                    <span></span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header">
                                                <div className="grid-cell-data">
                                                    <span></span>
                                                </div>
                                            </th>
                                            {allConfirmed && (
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span></span>
                                                    </div>
                                                </th>
                                            )}

                                            <th className="grid-cell-header col-initial">
                                                <div className="grid-cell-data">
                                                    <span>initial</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-confirmed">
                                                <div className="grid-cell-data">
                                                    <span>CONFIRMED</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-initial col-goods-ready">
                                                <div className="grid-cell-data">
                                                    <span>initial</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-confirmed col-goods-ready">
                                                <div className="grid-cell-data">
                                                    <span>CONFIRMED</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-initial">
                                                <div className="grid-cell-data">
                                                    <span>initial</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-confirmed">
                                                <div className="grid-cell-data">
                                                    <span>CONFIRMED</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-initial">
                                                <div className="grid-cell-data">
                                                    <span>initial</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-confirmed">
                                                <div className="grid-cell-data">
                                                    <span>CONFIRMED</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-initial">
                                                <div className="grid-cell-data">
                                                    <span>initial</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no col-confirmed">
                                                <div className="grid-cell-data">
                                                    <span>CONFIRMED</span>
                                                </div>
                                            </th>
                                            {/* <th className="grid-cell-header col-po-no">
                                                <div className="grid-cell-data">
                                                    <span></span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-po-no">
                                                <div className="grid-cell-data">
                                                    <span></span>
                                                </div>
                                            </th> */}
                                            {checkPermission('enrich', 'inbound') !== undefined &&
                                                !checkPermission('enrich', 'inbound') &&
                                                isAgent && (
                                                    <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-user-action ">
                                                        <div className="grid-cell-data">
                                                            <span></span>
                                                        </div>
                                                    </th>
                                                )}
                                        </tr>
                                    </thead>

                                    {/* One detailed row */}
                                    {productPo.map((product: ProductPoConfirmationDataDetails, index: number) => (
                                        <tbody key={product.poProductId} className="grouped-splited-row">
                                            {product.splitLines.map((splitLine: SplitLines, splitLineIndex: number) => (
                                                <tr
                                                    key={splitLineIndex}
                                                    className={
                                                        splitLine.splitLineStatus === 'In_Booking_Proposal' ||
                                                        splitLine.splitLineStatus === 'Booked'
                                                            ? 'no-action-available'
                                                            : ''
                                                    }
                                                >
                                                    <td className="grid-cell po-line grid-sticky-column sticky-left-aligned  no-border">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0
                                                                ? product.poLineNumber
                                                                    ? product.poLineNumber
                                                                    : '-'
                                                                : ''}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-sku grid-sticky-column sticky-left-aligned">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0 && product.sku}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-desc">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0 && product.description}
                                                        </div>
                                                    </td>
                                                    {allConfirmed && (
                                                        <td className="grid-cell po-line">
                                                            <div className="grid-cell-data">
                                                                {(() => {
                                                                    if (splitLine.splitLineStatus === 'Confirmed') {
                                                                        return (
                                                                            <div className="status-ui confirmed-status">
                                                                                Confirmed
                                                                            </div>
                                                                        );
                                                                    } else if (
                                                                        splitLine.splitLineStatus === 'Dropped' ||
                                                                        (product.qty === 0 &&
                                                                            splitLine.splitLineStatus === null)
                                                                    ) {
                                                                        return (
                                                                            <div className="status-ui error-status">
                                                                                Dropped
                                                                            </div>
                                                                        );
                                                                    } else if (
                                                                        splitLine.splitLineStatus?.toLowerCase() ===
                                                                            'pending_confirmation' ||
                                                                        splitLine.splitLineStatus?.toLowerCase() ===
                                                                            'pending confirmation'
                                                                    ) {
                                                                        return (
                                                                            <div className="status-ui warning-status">
                                                                                Pending Confirmation
                                                                            </div>
                                                                        );
                                                                    } else if (
                                                                        splitLine.splitLineStatus ===
                                                                        'In_Booking_Proposal'
                                                                    ) {
                                                                        return (
                                                                            <div className="status-ui success-status">
                                                                                In Booking Proposal
                                                                            </div>
                                                                        );
                                                                    } else if (splitLine.splitLineStatus === 'Booked') {
                                                                        return (
                                                                            <div className="status-ui success-status">
                                                                                Booked
                                                                            </div>
                                                                        );
                                                                    } else {
                                                                        return <div className="status-ui">-</div>;
                                                                    }
                                                                })()}
                                                            </div>
                                                        </td>
                                                    )}

                                                    <td className="grid-cell po-init">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0 && product.qty}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-conf">
                                                        <div className="grid-cell-data confirmed-bold">
                                                            {splitLine.splitLineStatus?.toLowerCase() ===
                                                                'pending_confirmation' ||
                                                            splitLine.splitLineStatus?.toLowerCase() ===
                                                                'pending confirmation' ||
                                                            splitLine.splitLineStatus === null ? (
                                                                isAgent ? (
                                                                    <input
                                                                        placeholder="Enter quantity"
                                                                        type="text"
                                                                        className="search-input input-text input-qty confirmed-bold input-field-grid"
                                                                        id="confirmedQty"
                                                                        name="confirmedQty"
                                                                        onChange={updateFieldChanged(
                                                                            index,
                                                                            splitLineIndex
                                                                        )}
                                                                        onKeyPress={(event) => {
                                                                            if (!/[0-9]/.test(event.key)) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        disabled={
                                                                            (checkPermission('enrich', 'inbound') !==
                                                                                undefined &&
                                                                                checkPermission('enrich', 'inbound')) ||
                                                                            headerData?.poStatus === 'Cancelled' ||
                                                                            product.qty === 0
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        value={isAgent ? splitLine.confirmedQty : '-'}
                                                                    />
                                                                ) : (
                                                                    '-'
                                                                )
                                                            ) : (
                                                                <>
                                                                    {isAgent ||
                                                                    splitLine.splitLineStatus === 'Confirmed'
                                                                        ? splitLine.confirmedQty
                                                                        : '-'}
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-init col-goods-ready-init">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0
                                                                ? product.goodsReadyDate
                                                                    ? moment(product.goodsReadyDate).format(
                                                                          'DD-MM-YYYY'
                                                                      )
                                                                    : '-'
                                                                : ''}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-conf col-goods-ready-conf">
                                                        <div
                                                            id={`confirmedGoodsdate_${index}_${splitLineIndex}`}
                                                            onClick={() => {
                                                                setCurrentIndex(index);
                                                                setCurrentSplitIndex(splitLineIndex);
                                                            }}
                                                            className="grid-cell-data confirmed-bold date-picker-grid-confirm"
                                                        >
                                                            {splitLine.splitLineStatus?.toLowerCase() ===
                                                                'pending_confirmation' ||
                                                            splitLine.splitLineStatus?.toLowerCase() ===
                                                                'pending confirmation' ||
                                                            splitLine.splitLineStatus === null ? (
                                                                isAgent ? (
                                                                    <DatePicker
                                                                        disabled={
                                                                            (checkPermission('enrich', 'inbound') !==
                                                                                undefined &&
                                                                                checkPermission('enrich', 'inbound')) ||
                                                                            headerData?.poStatus === 'Cancelled' ||
                                                                            product.qty === 0
                                                                                ? true
                                                                                : false
                                                                        }
                                                                        minDate={
                                                                            product.orderDate
                                                                                ? new Date(product.orderDate)
                                                                                : new Date()
                                                                        }
                                                                        state={splitLine.confirmedGoodsdate}
                                                                        onChange={updateDateChanged}
                                                                        fieldName={`confirmedGoodsdate_${index}_${splitLineIndex}`}
                                                                        portalId="date-portal"
                                                                    />
                                                                ) : (
                                                                    '-'
                                                                )
                                                            ) : (
                                                                <>
                                                                    {isAgent ||
                                                                    splitLine.splitLineStatus === 'Confirmed'
                                                                        ? moment(splitLine.confirmedGoodsdate).format(
                                                                              'DD-MM-YYYY'
                                                                          )
                                                                        : '-'}
                                                                </>
                                                            )}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-init">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0 && product.cbm}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-conf">
                                                        <div className="grid-cell-data confirmed-bold">
                                                            {isAgent || splitLine.splitLineStatus === 'Confirmed'
                                                                ? splitLine.confirmedCbm >= 0
                                                                    ? splitLine.confirmedCbm
                                                                    : '-'
                                                                : '-'}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-init">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0 && product.weight}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-conf">
                                                        <div className="grid-cell-data confirmed-bold">
                                                            {isAgent || splitLine.splitLineStatus === 'Confirmed'
                                                                ? splitLine.confirmedWeight >= 0
                                                                    ? splitLine.confirmedWeight
                                                                    : '-'
                                                                : '-'}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-init">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0 &&
                                                                `${product.convertedQty} ${product.uom}`}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-conf">
                                                        <div className="grid-cell-data confirmed-bold">
                                                            {isAgent || splitLine.splitLineStatus === 'Confirmed'
                                                                ? `${splitLine.convertedQuantity} ${splitLine.confirmedUom}`
                                                                : '-'}
                                                        </div>
                                                    </td>
                                                    {/* <td className="grid-cell po-desc">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0 && product.originPort}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell po-desc">
                                                        <div className="grid-cell-data">
                                                            {splitLineIndex === 0
                                                                ? product.destinationPort
                                                                    ? product.destinationPort
                                                                    : '-'
                                                                : ''}
                                                        </div>
                                                    </td> */}
                                                    {checkPermission('enrich', 'inbound') !== undefined &&
                                                        !checkPermission('enrich', 'inbound') &&
                                                        isAgent && (
                                                            <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned col-user-action">
                                                                {(headerData?.poStatus === 'Awaiting_Enrichment' ||
                                                                    headerData?.poStatus === 'Inbound_In_Progress' ||
                                                                    headerData?.poStatus === 'Ready_For_Booking') &&
                                                                    (splitLine.splitLineStatus === 'Confirmed' ||
                                                                        splitLine.splitLineStatus?.toLowerCase() ===
                                                                            'pending_confirmation' ||
                                                                        splitLine.splitLineStatus?.toLowerCase() ===
                                                                            'pending confirmation' ||
                                                                        splitLine.splitLineStatus === 'Dropped' ||
                                                                        splitLine.splitLineStatus === null) && (
                                                                        <div className="grid-cell-data">
                                                                            <button
                                                                                onClick={() =>
                                                                                    createSplitLine(
                                                                                        index,
                                                                                        splitLine.splitLineId,
                                                                                        splitLine.splitLineStatus
                                                                                    )
                                                                                }
                                                                                className="action-btn app-btn app-btn-secondary"
                                                                                title="Split PO Line"
                                                                                disabled={
                                                                                    (checkPermission(
                                                                                        'enrich',
                                                                                        'inbound'
                                                                                    ) !== undefined &&
                                                                                        checkPermission(
                                                                                            'enrich',
                                                                                            'inbound'
                                                                                        )) ||
                                                                                    product.qty === 0
                                                                                        ? true
                                                                                        : false
                                                                                }
                                                                            >
                                                                                <svg className="svg-icon icon-20">
                                                                                    <use xlinkHref="#splitRow">
                                                                                        <title>Split</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </button>
                                                                            {product.splitLines.length > 1 && (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        removePoLine(
                                                                                            index,
                                                                                            splitLineIndex,
                                                                                            splitLine.splitLineId
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        (checkPermission(
                                                                                            'enrich',
                                                                                            'inbound'
                                                                                        ) !== undefined &&
                                                                                            checkPermission(
                                                                                                'enrich',
                                                                                                'inbound'
                                                                                            )) ||
                                                                                        product.qty === 0
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                    title="Remove Split Line"
                                                                                >
                                                                                    <svg className="svg-icon icon-20">
                                                                                        <use xlinkHref="#deleteIcon">
                                                                                            <title>Cancel</title>
                                                                                        </use>
                                                                                    </svg>
                                                                                </button>
                                                                            )}
                                                                            {splitLine.splitLineStatus ===
                                                                                'Confirmed' ||
                                                                            splitLine.splitLineStatus === 'Dropped' ? (
                                                                                <button
                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                    title="Edit PO Line"
                                                                                    onClick={() =>
                                                                                        editPoLine(
                                                                                            splitLine.splitLineId
                                                                                        )
                                                                                    }
                                                                                    disabled={
                                                                                        (checkPermission(
                                                                                            'enrich',
                                                                                            'inbound'
                                                                                        ) !== undefined &&
                                                                                            checkPermission(
                                                                                                'enrich',
                                                                                                'inbound'
                                                                                            )) ||
                                                                                        product.qty === 0
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                >
                                                                                    <svg className="svg-icon edit-icon icon-20">
                                                                                        <use xlinkHref="#editIcon">
                                                                                            <title>Edit</title>
                                                                                        </use>
                                                                                    </svg>
                                                                                </button>
                                                                            ) : null}
                                                                            {splitLine.splitLineStatus?.toLowerCase() ===
                                                                                'pending_confirmation' ||
                                                                            splitLine.splitLineStatus?.toLowerCase() ===
                                                                                'pending confirmation' ||
                                                                            splitLine.splitLineStatus === null ? (
                                                                                <button
                                                                                    onClick={() =>
                                                                                        confirmPoLine(
                                                                                            index,
                                                                                            splitLineIndex,
                                                                                            splitLine.splitLineStatus
                                                                                        )
                                                                                    }
                                                                                    className={`action-btn app-btn app-btn-secondary ${
                                                                                        splitLine.confirmedQty ===
                                                                                            null ||
                                                                                        !splitLine.confirmedGoodsdate
                                                                                            ? 'disabled'
                                                                                            : ''
                                                                                    }`}
                                                                                    title="Confirm PO Line"
                                                                                    disabled={
                                                                                        splitLine.confirmedQty ===
                                                                                            null ||
                                                                                        !splitLine.confirmedGoodsdate
                                                                                            ? true
                                                                                            : false ||
                                                                                              (checkPermission(
                                                                                                  'enrich',
                                                                                                  'inbound'
                                                                                              ) !== undefined &&
                                                                                                  checkPermission(
                                                                                                      'enrich',
                                                                                                      'inbound'
                                                                                                  )) ||
                                                                                              product.qty === 0
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                >
                                                                                    <svg className="svg-icon tick-icon icon-20">
                                                                                        <use xlinkHref="#ticktableIcon">
                                                                                            <title>
                                                                                                Confirm PO line
                                                                                            </title>
                                                                                        </use>
                                                                                    </svg>
                                                                                </button>
                                                                            ) : null}
                                                                        </div>
                                                                    )}
                                                            </td>
                                                        )}
                                                </tr>
                                            ))}
                                        </tbody>
                                    ))}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <Link
                            to={`/inbound/${id}/product_details`}
                            className="app-btn app-btn-secondary footer-btn"
                            title="Previous"
                        >
                            <span className="button-text footer-button-text">Previous</span>
                        </Link>
                        {checkPermission('enrich', 'inbound') !== undefined &&
                            !checkPermission('enrich', 'inbound') &&
                            isAgent &&
                            headerData?.poStatus === 'Awaiting_Enrichment' && (
                                <button
                                    onClick={() => submitPoLine()}
                                    className="app-btn app-btn-primary footer-btn "
                                    title="Confirm Inbound Goods"
                                    disabled={
                                        disableConfirmPoButton
                                            ? true
                                            : false ||
                                              (checkPermission('enrich', 'inbound') !== undefined &&
                                                  checkPermission('enrich', 'inbound'))
                                    }
                                >
                                    <span className="button-text footer-button-text">Confirm Inbound Goods</span>
                                </button>
                            )}
                    </div>
                </div>
            </div>
            <ShipmentModal
                display={modalViewShort}
                displayFunction={setModalViewShort}
                shortShipmentList={shortShipmentList}
                setShortShipmentList={setShortShipmentList}
                poId={id}
            />
            <ConfirmBookingModal display={modalViewConfirm} displayFunction={setModalViewConfirm} poId={id} />
            <ZeroQuantityModal
                zeroProductData={zeroProductData}
                setZeroProductData={setZeroProductData}
                product={productPo}
                display={modalViewZero}
                displayFunction={setModalViewZero}
            />
            <ModifyProductModal
                display={modalViewModifyProduct}
                displayFunction={setModalViewModifyProduct}
                splitLine={currentSplitLine}
                poId={id}
            />
            <div style={{ zIndex: 9999 }} id="date-portal"></div>
        </>
    );
};
export default Index;
