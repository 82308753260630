import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'profilesClients',
    initialState: {
        clientHeader: [],
    },
    reducers: {
        fetchHeaderDataClients: (_state, _action) => {
            // console.log(state);
        },
        saveHeaderDataClients: (state, action) => {
            state.clientHeader = action.payload?.info;
        },
    },
});

export const { fetchHeaderDataClients, saveHeaderDataClients } = dataSlice.actions;
export default dataSlice.reducer;
