import { ReactElement, useRef, useEffect, useState } from 'react';
import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
interface TooltipProps {
    data: any;
    settableRowExp: any;
    rowexpand: boolean;
    index: number;
    hideMenu?: any;
    setHideMenu?: any;
    rowLine: number | null | undefined;
    setRowLine: any;
    itemId?: string;
    setColumnExpand?: any;
    columnExpand?: string | null | undefined;
}
const TableToolTip = ({
    data,
    settableRowExp,
    rowexpand,
    hideMenu,
    index,
    rowLine,
    setHideMenu,
    setRowLine,
    itemId,
    setColumnExpand,
    columnExpand,
}: TooltipProps): ReactElement => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const toolTipRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(toolTipRef, settableRowExp as any);

    const [leftPos, setLeftPos] = useState(0);
    const [topPos, setTopPos] = useState(0);
    useEffect(() => {
        if (hideMenu == true) {
            settableRowExp(false);
            setHideMenu(false);
        }
    }, [hideMenu]);

    return (
        <div className="grid-cell-data">
            {data && (data[0]?.or_OrgName ? data[0]?.or_OrgName : data[0])}
            <div className="dropdown-wrap  menu-down dropdown-open info-dropdown left-align" ref={dropdownRef}>
                {data?.length > 1 && (
                    <button
                        className="menu-btn app-btn"
                        onClick={(e) => {
                            const left = dropdownRef.current?.getBoundingClientRect().left;
                            const top = dropdownRef.current?.getBoundingClientRect().bottom;
                            hideMenu = false;
                            setLeftPos(left ? left : 0);
                            setTopPos(top ? top : 0);
                            e.stopPropagation();
                            settableRowExp(!rowexpand);
                            setRowLine(index);
                            if (itemId && setColumnExpand) {
                                setColumnExpand(itemId);
                            }
                        }}
                    >
                        <span className="btn-text">+{data?.length - 1}</span>
                    </button>
                )}
                {rowexpand &&
                    rowLine === index &&
                    (itemId ? (
                        itemId === columnExpand ? (
                            <div className="dropdown-menu" style={{ zIndex: 50 }} ref={toolTipRef}>
                                {data?.slice(1, data?.length).map((item: any) => (
                                    <a href="#" className="menu-item app-btn">
                                        {item}
                                    </a>
                                ))}
                            </div>
                        ) : null
                    ) : (
                        <div
                            className="dropdown-menu"
                            ref={toolTipRef}
                            style={{
                                zIndex: 50,
                                position: 'fixed',
                                left: leftPos,
                                top: topPos + 5,
                                maxHeight: `calc(100vh - ${topPos}px - 10px) `,
                            }}
                        >
                            {data?.slice(1, data?.length).map((clients: any) => (
                                <a href="#" className="menu-item app-btn">
                                    {clients?.or_OrgName ? clients?.or_OrgName : clients}
                                </a>
                            ))}
                        </div>
                    ))}
            </div>
        </div>
    );
};

export default TableToolTip;
