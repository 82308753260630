import React, { useEffect, useState } from 'react';
import BookingProposalDetailsHeader from '../BookingProposalDetailsHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../POSelection.scss';
import './BookingDetailsGrid.scss';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
    fetchAvailablePoLineData,
    resetAvailablePoLineList,
    resetFlags,
    searchAvailablePoLineData,
    setPoId,
} from '../../../store/reducers/bookingProposalBcn/availablePoLineReducer';
import SelectableGrid from '../../../components/common/selectableGrid/index';
import { Position, success } from '../../../utils/toast';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from '../../../hooks/useUserProfile';

const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const { bpNumber, bpId } = useSelector((state: RootState) => state.bpHeaderData);
    const [searchText, setSearchText] = useState('');
    const {
        availablePoLineList,
        isLoading,
        isHeaderOnlyPo,
        poId,
        cgId,
        success: addSuccess,
    } = useSelector((state: RootState) => state.availablePoLinesBcn);
    const bpID = localStorage.getItem('bpId');
    const poID = localStorage.getItem('poId');
    const cgID = localStorage.getItem('cgId');
    const { profileType } = useUserProfile();

    useEffect(() => {
        dispatch(fetchAvailablePoLineData({ cgId: cgID }));
    }, [cgId]);

    useEffect(() => {
        if (searchText?.trim()?.length > 1) {
            dispatch(searchAvailablePoLineData({ cgId: cgID, keyword: searchText }));
        } else if (searchText?.trim()?.length === 0) {
            dispatch(fetchAvailablePoLineData({ cgId: cgID }));
        }
    }, [searchText]);

    useEffect(() => {
        return () => {
            dispatch(resetAvailablePoLineList({}));
        };
    }, []);

    useEffect(() => {
        if (addSuccess) {
            const poLine = selectedItems.length > 1 ? 'PO Lines have' : 'PO Line has';
            let successMessage = `${poLine} been added to Booking Proposal.`;
            if (!id) {
                successMessage = successMessage + ' List of Available POs have been filtered based on header details';
            }
            success(successMessage, Position.TOP_RIGHT);
            dispatch(resetFlags({}));
            setSelectedItems([]);
            dispatch(fetchBpHeaderData({ bpId: bpId || bpID, profileType }));
            navigate(`/bcnBookingList/${bpId || bpID}/POSelection`);
        }
    }, [addSuccess]);

    useEffect(() => {
        if (poID) {
            dispatch(setPoId({ poId }));
        }
    }, []);

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder medium-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={id ? 'Booking Proposal Details' : 'New Booking Proposal'}
                    secondUrl={id ? `/bcnBookingList/${id}/POSelection` : '/bcnBookingList/POSelection'}
                    thirdName={'PO Line Details'}
                    thirdUrl={'/BookingProposal/POSelectionDetails'}
                    backUrl={id ? `/bcnBookingList/${id}/POSelection` : '/bcnBookingList/POSelection'}
                />
                <BookingProposalDetailsHeader
                    setShowBookingSideBar={setShowBookingSideBar}
                    id={bpNumber ? bpNumber : ''}
                ></BookingProposalDetailsHeader>
            </div>
            <div
                className={`content-section main-wrapper ${
                    isHeaderOnlyPo ? 'po-selection-grid__bpdetails' : 'po-selection-grid'
                }`}
            >
                <div className="main-wrapper">
                    {isHeaderOnlyPo ? (
                        <span className="alert-msg">
                            <svg className="svg-icon">
                                <use xlinkHref="#errorCautionIcon"></use>
                            </svg>
                            This PO has been marked as Header Only with no PO lines included
                        </span>
                    ) : (
                        <div className="tab-with-action">
                            <div className="tab-with-search">
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        value={searchText}
                                        className="search-input input-text"
                                        onChange={(e: any) => {
                                            setSearchText(e.target.value);
                                        }}
                                    ></input>
                                    <svg className="svg-icon search-icon">
                                        <use xlinkHref="#searchIcon"></use>
                                    </svg>
                                    <button
                                        className="app-btn text-close-btn"
                                        onClick={() => {
                                            setSearchText('');
                                        }}
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="main-content-holder">
                        <div
                            className={`grid-section ${
                                isHeaderOnlyPo ? 'grid-bookpo-booking' : 'grid-details-booking'
                            }`}
                        >
                            {isHeaderOnlyPo && !isLoading ? (
                                <SelectableGrid tabName="poLinesHeaderOnly" data={availablePoLineList} />
                            ) : null}
                            {!isHeaderOnlyPo && !isLoading ? (
                                <SelectableGrid tabName="poLines" data={availablePoLineList} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row"></div>
            {showBookingSideBar && (
                <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
            )}
        </div>
    );
};
export default Index;
