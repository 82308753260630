import fileDownload from 'js-file-download';
import { del, get, post } from '../../HttpMethods';

export interface AvailablePoData {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    createdDate: string;
    clientName: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
    createdOn: string;
    totKGS: number;
    totCBM: number;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

export interface AvailablePoResponse {
    poListingDtos: AvailablePoData[];
}

export interface CreateBpResponse {
    success: boolean;
    info: {
        bpId: string;
        bpNumber: string;
        totalCBM: 46.0;
        totalKgs: 6.0;
        client: string;
        originPort: string;
        destinationPort: string;
        goodsReadyDate: string[];
        purchaseOrders: AvailablePoData[];
    };
}
/* eslint-disable no-undefined */

export const getAllFiles = async (payload: any) => {
    try {
        const res = await get(`api/ord/blp/receipts/view/${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const uploadFile = async (payload: any) => {
    try {
        const res = await post(`api/ord/blp/receipts/upload/${payload?.id}`, payload?.data, null);
        return res;
    } catch (err) {
        console.log(err, 'fileserror1234');
        return err;
    }
};

export const removeFile = async (payload: any) => {
    try {
        const res = await del(`api/ord/blp/receipts/remove?fileId=${payload.fileId}`, null);
        return res;
    } catch (err) {
        console.log(err, 'fileserror123');
        return err;
    }
};

export const downloadSingleFile = async (payload: any) => {
    try {
        //const res = await patch(`api/bkg/bp/attachments/download?fileId=${payload}`, null);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/ord/blp/receipts/download?fileId=${payload.fileId}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                console.log(result);
                fileDownload(result, payload.name);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const downloadAllFiles = async (payload: any) => {
    try {
        //const res = await patch(`api/bkg/bp/attachments/downloadAll/${payload?.id}?docType=${payload.type}`, null);
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/ord/blp/receipts/downloadAll/${payload?.id}?docType=${payload.type}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                console.log(result);
                fileDownload(result, `${payload.type}.zip`);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const submitReceipt = async (payload: any) => {
    try {
        const res = await get(`api/ord/blp/receipts/submit/${payload}`, null);
        return res;
    } catch (err) {
        console.log(err, 'fileserror123');
        return err;
    }
};
