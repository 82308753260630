import React, { useEffect, useLayoutEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import BookingSailingSchelude from '../BookingSailingSchelude';
import SelectedSailingSchelude from '../SelectedSailingSchelude';
import { addToBp } from '../../../store/reducers/bookingProposal/sailingReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { error, Position, success } from '../../../utils/toast';
// import data from './sailing.json';
import moment from 'moment';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import Loader from './Loader';
import { fetchAddedPoData } from '../../../store/reducers/aobookingProposal/addedPoReducer';
import ShowForPermissions from '../../../ShowForPermissions';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import { useUserProfile } from '../../../hooks/useUserProfile';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { getOrganisationData } from '../../../utils';
// import FlightSchelude from '../BookingSailingSchelude/ApprovalFlightSchelude';
import {
    getSelectedSchedules,
    updateCurrentClientSelectedSchedule,
} from 'src/store/reducers/bookingProposalAir/addSchedule';
import {
    clearFailure,
    selectAdditionalDetailsError,
    selectAdditionalDetailsIsError,
    selectAdditionalDetailsSuccess,
    approveBp,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { useAppSelector } from 'src/hooks';
import { selectSchedule } from 'src/store/reducers/bookingProposalAir/flightScheduleReducer';
import RejectBookingProposalModal from '../BookingAdditionalInfo/RejectBookingProposalModal';
import RejectBpClientModal from '../BookingAdditionalInfo/RejectBpClientModal';
import { NavData } from 'src/pages/PoBookingsAir/Wrapper';

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
];

interface Props {
    navData: NavData[];
}

const Index: React.FC<Props> = ({ navData }) => {
    const { profileType, userName } = useUserProfile();
    const { isLoading, selectedSailing } = useSelector((state: RootState) => state.sailingRed);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { selectedSchedules, currentClientSelectedSchedule } = useSelector(
        (state: RootState) => state.bookingproposalAirschedule
    );
    const dispatch = useDispatch();
    const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    const [rejectionModalView, setRejectionModalView] = useState(false);
    const [assignAgentModalView, setAssignAgentModalView] = useState(false);
    const [reasonRejection, setReasonRejection] = useState('');
    const [rejectBpModalView, setRejectBpModalView] = useState(false);
    const [_rejectedBpForwarderModalView, setRejectedBpForwarderModalView] = useState(false);
    const [reason, setReason] = useState('');
    const [selectedFlightSchedule, setSelectedFlightSchedule] = useState('');
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [selectedContract, setselectedContract] = useState(null || {});
    const [selectedView, setselectedView] = useState(false);
    const [shippingDates, _setshippingDates] = useState([
        {
            shippingLineName: null,
            firstTwoWeekStartDate: null,
            firstTwoWeekEndDate: null,
            secondTwoWeekStartDate: null,
            secondTwoWeekEndDate: null,
            afterFourWeeksStartDate: null,
        },
    ]);
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    // const navData = [
    //     {
    //         title: 'PO Selection',
    //         selected: 0,
    //         path: id ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new',
    //         disabled: false,
    //     },
    //     {
    //         title: 'Flight Schedules',
    //         selected: 1,
    //         path: `/air_booking_list/${id}/FlightSelectionDetails`,
    //         disabled: false,
    //     },
    // ];
    // if (
    //     headerData.bpStatus === 'Ready_for_Booking' ||
    //     headerData.bpStatus === 'Booked' ||
    //     headerData.bpStatus === 'Draft_Booking'
    // ) {
    //     navData.push({
    //         title: 'Booking Details',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/booking_details`,
    //         disabled: false,
    //     });
    // }
    // if (headerData.bpStatus === 'Ready_for_Booking' || headerData.bpStatus === 'Draft_Booking') {
    //     navData.push(
    //         {
    //             title: 'Shipment Details',
    //             selected: 0,
    //             path: `/air_booking_list/${id}/shipment_details`,
    //             disabled: false,
    //         },

    //         {
    //             title: 'Attachments',
    //             selected: 0,
    //             path: `/air_booking_list/${id}/attachments`,
    //             disabled: false,
    //         }
    //     );
    // }
    /* eslint-disable no-undefined */
    /* eslint no-var: off */
    useEffect(() => {
        dispatch(fetchAddedPoData(id));
    }, []);

    useLayoutEffect(() => {
        dispatch(getSelectedSchedules(id));
    }, [selectedView, id]);

    useLayoutEffect(() => {
        if (selectedSchedules?.length > 0 && !isLoading) {
            setselectedView(true);
        } else {
            setselectedView(false);
        }
    }, [selectedSchedules, isLoading]);

    const isEmpty = (obj: any) => {
        for (var prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    };

    const customNavData = navData.map((navItem) => {
        if (navItem.key === 'flight_schedules') {
            return { ...navItem, selected: 1 };
        } else if (navItem.key === 'packing') {
            return { ...navItem, disabled: !(headerData?.tabValues as any)?.airSchedules };
        } else {
            return navItem;
        }
    });

    const addSelectedContract = () => {
        localStorage.setItem('itemSelectedSailingAo', 'true');
        var transitPorts = JSON.parse(JSON.stringify(selectedContract as any));
        transitPorts?.sailingScheduleTransitPorts?.forEach((element: any) => {
            element['plannedETA'] = element.plannedETA
                ? moment(element.plannedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['plannedETD'] = element.plannedETD
                ? moment(element.plannedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['portCutOff'] = element.portCutOff
                ? moment(element.portCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['predictedETA'] = element.predictedETA
                ? moment(element.predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['predictedETD'] = element.predictedETD
                ? moment(element.predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['vgmCutOff'] = element.vgmCutOff
                ? moment(element.vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['gateInCutOff'] = element.gateInCutOff
                ? moment(element.gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['docCutOff'] = element.docCutOff
                ? moment(element.docCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['gateInOpen'] = element.gateInOpen
                ? moment(element.gateInOpen).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
        });
        const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
        const listOfUserOrganisations = getOrganisationData();
        const indexVal = localStorage.getItem('roleIndex');
        const roles = userData?.roles[indexVal as any];

        const payload = {
            sailingAddRequest: {
                bookingProposalId: id,
                contractType: (selectedContract as any).contractType,
                contractId: (selectedContract as any).contractId,
                contractName: (selectedContract as any).contractName,
                shippingLine: (selectedContract as any).shippingline,
                shippingLineCode: (selectedContract as any).shippinglineCode,
                transitDays: (selectedContract as any).plannedTransitDays,
                serviceType: (selectedContract as any).servicetype,
                isPrimary: (selectedContract as any).isPrimaryClient,
                changeVessel:
                    localStorage.getItem('changeVesselao') && localStorage.getItem('changeVesselao') === id
                        ? true
                        : false,
                eta: (selectedContract as any).plannedETA
                    ? moment((selectedContract as any).plannedETA).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                etd: (selectedContract as any).plannedETD
                    ? moment((selectedContract as any).plannedETD).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                vesselVoyage: (selectedContract as any).voyageId,
                gateInDate: (selectedContract as any).gateInDate
                    ? moment((selectedContract as any).gateInDate).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                //gateInDate: '2023-06-20 00:00:00',
                contractVersion: (selectedContract as any)?.contractVersion,
                isManuallyEntered: false,
                predictedETD: (selectedContract as any).predictedETD
                    ? moment((selectedContract as any).predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                predictedETA: (selectedContract as any).predictedETA
                    ? moment((selectedContract as any).predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                predictedTransitDays: (selectedContract as any).predictedTransitDays,
                service: (selectedContract as any).service,
                destinationPortCode: (selectedContract as any).destinationPortCode,
                destinationPortName: (selectedContract as any).destinationPortName,
                destinationPortTerminal: (selectedContract as any).destinationPortTerminal,
                originPortCode: (selectedContract as any).originPortCode,
                originPortName: (selectedContract as any).originPortName,
                originPortTerminal: (selectedContract as any).originPortTerminal,
                vgmCutOff: (selectedContract as any).vgmCutOff
                    ? moment((selectedContract as any).vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                gateInCutOff: (selectedContract as any).gateInCutOff
                    ? moment((selectedContract as any).gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                standardBookingCutOff: (selectedContract as any).standardBookingCutOff
                    ? moment((selectedContract as any).standardBookingCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                gateInOpen: (selectedContract as any).gateInOpen
                    ? moment((selectedContract as any).gateInOpen).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                docCutOff: (selectedContract as any).docCutOff
                    ? moment((selectedContract as any).docCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                sailingScheduleTransitPorts:
                    (selectedContract as any)?.sailingScheduleTransitPorts?.length === 0
                        ? []
                        : transitPorts?.sailingScheduleTransitPorts,
            },
            notification: { roles, listOfUserOrganisations },
        };
        setTimeout(() => {
            setselectedView(true);
            dispatch(addToBp(payload));
        }, 1000);
    };

    const rejectBp = () => {
        setRejectionModalView(true);
    };

    const submitBp = () => {
        const fromForwarderDraft =
            (headerData.bpStatus === 'Draft_Booking_Proposal' || headerData.bpStatus === 'Rejected_by_Forwarder') &&
            profileType === 'forwarder';
        dispatch(approveBp({ bpId: id, userName, profile: profileType, fromForwarderDraft }));
    };
    const submitBpClient = () => {
        dispatch(
            selectSchedule({
                bpId: id,
                userName,
                profile: profileType,
                airFreightId: selectedFlightSchedule,
            })
        );
    };

    useEffect(() => {
        if (
            selectedFlightSchedule === '' &&
            id === currentClientSelectedSchedule?.bpId &&
            currentClientSelectedSchedule?.value
        ) {
            setSelectedFlightSchedule(currentClientSelectedSchedule?.value);
        } else if (selectedFlightSchedule !== currentClientSelectedSchedule?.value) {
            dispatch(
                updateCurrentClientSelectedSchedule({
                    bpId: id,
                    value: selectedFlightSchedule,
                })
            );
        }
    }, [selectedFlightSchedule]);

    const isError = useAppSelector(selectAdditionalDetailsIsError);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const additionalDetailsError = useAppSelector(selectAdditionalDetailsError);
    useEffect(() => {
        if (isError) {
            error(additionalDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (additionalDetailsSuccess?.status === 200 || additionalDetailsSuccess?.status === 201) {
            if (additionalDetailsSuccess?.data?.type === 'rejected') {
                success(
                    `Booking Proposal ${
                        additionalDetailsSuccess?.data?.info?.bpNumber
                            ? additionalDetailsSuccess?.data?.info?.bpNumber
                            : ''
                    } rejected successfully`,
                    Position.TOP_RIGHT
                );
                setTimeout(() => navigate('/booking_list'), 1000);
            } else if (additionalDetailsSuccess?.data?.type === 'approved') {
                const bpNumber = additionalDetailsSuccess?.data?.info?.bpNumber
                    ? additionalDetailsSuccess?.data?.info?.bpNumber
                    : '';
                const message =
                    profileType === 'client'
                        ? `Booking Proposal ${bpNumber} approved successfully`
                        : `Booking Proposal ${bpNumber} submitted for approval`;
                success(message, Position.TOP_RIGHT);
                setTimeout(() => navigate('/booking_list'), 2000);
            }
            dispatch(clearFailure([]));
        }
    }, [isError, additionalDetailsSuccess, additionalDetailsError]);

    const getSubmitButtonStatus = () => {
        if (selectedSchedules.every((e: any) => e.isRemoved || e.isRemovedByAgent)) {
            return true;
        }
        return selectedSchedules.some((e: any) => {
            let cond;
            if (
                bpStatus === 'Rejected_by_Forwarder' ||
                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                bpStatus === 'Draft_Booking_Proposal'
            ) {
                cond = e.buyCurrency === null;
            } else {
                cond = e.sellCurrency === null;
            }
            if (cond && !e.isRemoved) {
                return e.isRemovedByAgent ? false : true;
            } else {
                return false;
            }
        });
    };
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };
    return (
        <div className="main-wrapper container" style={{ overflow: 'hidden' }}>
            <div className="main-header-content-holder large-header">
                {localStorage.getItem('changeVesselao') !== id ? (
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={`/air_booking_list/${id}/FlightSelectionDetails`}
                        // thirdName={'Sailing Schedule'}
                        // thirdUrl={`/Aobooking_list/${id}/SailingSelectionDetails`}
                        summaryStats={summaryStats}
                        handleClickCancel={handleClickCancel}
                    />
                ) : (
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={`/air_booking_list/${id}/FlightSelectionDetails`}
                        // thirdName={'Sailing Schedule'}
                        // thirdUrl={`/Aobooking_list/${id}/SailingSelectionDetails`}
                        fourthName={'Change Vessel'}
                        fourthUrl={'#'}
                        summaryStats={summaryStats}
                        handleClickCancel={handleClickCancel}
                    />
                )}
                <BookingProposalHeader
                    id={
                        localStorage.getItem('changeVesselao') &&
                        localStorage.getItem('changeVesselao') === id &&
                        !selectedView
                            ? 'Select from Schedules'
                            : localStorage.getItem('bpNumber') || ''
                    }
                    setSelected={setselectedView}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>{' '}
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )}
                {(localStorage.getItem('changeVesselao') !== id || selectedView) && (
                    <BookingProcessTab navData={customNavData} />
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper bo-sailing-schelude">
                <div className="main-wrapper">
                    {isLoading ? (
                        !selectedView && <Loader />
                    ) : selectedView || (location?.state as any)?.added === true ? (
                        <SelectedSailingSchelude
                            id={id}
                            data={(selectedSailing as any)?.['sailingScheduleLists']}
                            selectedView={selectedView}
                            setselectedView={setselectedView}
                            setselectedContract={setselectedContract}
                            bpStatus={bpStatus}
                            selectedFlightSchedule={selectedFlightSchedule}
                            setSelectedFlightSchedule={setSelectedFlightSchedule}
                            headerData={headerData}
                        ></SelectedSailingSchelude>
                    ) : (
                        <BookingSailingSchelude
                            shippingDates={shippingDates}
                            isLoading={isLoading}
                            headerData={headerData}
                        ></BookingSailingSchelude>
                    )}
                    {/* <FlightSchelude></FlightSchelude> */}
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* <button
                        title="Previous"
                        onClick={() => navigate(`/air_booking_list/${id}/POSelection`, { state: { prev: true } })}
                        className="app-btn app-btn-secondary footer-btn"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </button>
                    <button
                        title="Next"
                        className="app-btn app-btn-primary footer-btn"
                        onClick={() => navigate(`/air_booking_list/${id}/packing`)}
                        disabled={!(headerData?.tabValues as any)?.airSchedules}
                    >
                        <span className="button-text footer-button-text">Next</span>
                    </button> */}
                    {(() => {
                        if (profileType === 'forwarder') {
                            return (
                                <>
                                    <a
                                        title="Previous"
                                        onClick={() =>
                                            navigate(`/air_booking_list/${id}/options`, { state: { prev: true } })
                                        }
                                        className="app-btn app-btn-secondary footer-btn"
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </a>
                                    <button
                                        title="Next"
                                        className="app-btn app-btn-primary footer-btn"
                                        onClick={() => navigate(`/air_booking_list/${id}/packing`)}
                                        // disabled={!(headerData?.tabValues as any)?.airSchedules}
                                    >
                                        <span className="button-text footer-button-text">Next</span>
                                    </button>
                                    {(headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                        headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                        (headerData.bpStatus === 'Rejected_by_Forwarder' &&
                                            headerData.isForwarderSubmit) ||
                                        headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action') && (
                                        <>
                                            {headerData.bpStatus === 'Awaiting_Forwarder_Approval' && (
                                                <ShowForPermissions permission="approve" type="bp">
                                                    <a
                                                        onClick={() => rejectBp()}
                                                        title="Reject Proposal"
                                                        className="app-btn app-btn-secondary footer-btn"
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Reject Proposal
                                                        </span>
                                                    </a>
                                                </ShowForPermissions>
                                            )}
                                            {headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action' &&
                                                !headerData.isForwarderSubmit && (
                                                    <a
                                                        title="Assign to Agent"
                                                        className="app-btn app-btn-secondary footer-btn"
                                                        onClick={() => {
                                                            setAssignAgentModalView(true);
                                                            setReasonRejection(
                                                                headerData.reason ? headerData.reason : ''
                                                            );
                                                        }}
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Assign to Agent
                                                        </span>
                                                    </a>
                                                )}
                                            <ShowForPermissions permission="edit" type="bp">
                                                <button
                                                    onClick={() => submitBp()}
                                                    title="Submit for Approval"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    disabled={getSubmitButtonStatus()}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        Submit for Approval
                                                    </span>
                                                </button>
                                            </ShowForPermissions>
                                        </>
                                    )}
                                    {/* {(headerData.bpStatus === 'Ready_for_Booking' ||
                                        headerData.bpStatus === 'Booked' ||
                                        headerData.bpStatus === 'Draft_Booking') && (
                                        <Link
                                            title="Next"
                                            className="app-btn app-btn-primary footer-btn"
                                            to={`/air_booking_list/${id}/packing`}
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                    )} */}
                                </>
                            );
                        } else if (profileType === 'client') {
                            return (
                                <>
                                    <a
                                        title="Previous"
                                        onClick={() =>
                                            navigate(`/air_booking_list/${id}/options`, { state: { prev: true } })
                                        }
                                        className="app-btn app-btn-secondary footer-btn"
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </a>
                                    <button
                                        title="Next"
                                        className="app-btn app-btn-primary footer-btn"
                                        onClick={() => navigate(`/air_booking_list/${id}/packing`)}
                                        // disabled={!(headerData?.tabValues as any)?.airSchedules}
                                    >
                                        <span className="button-text footer-button-text">Next</span>
                                    </button>
                                    {headerData.bpStatus === 'Awaiting_Client_Approval' && (
                                        <>
                                            <a
                                                onClick={() => rejectBp()}
                                                title="Reject Proposal"
                                                className="app-btn app-btn-secondary footer-btn"
                                            >
                                                <span className="button-text footer-button-text">Reject Proposal</span>
                                            </a>
                                            <button
                                                onClick={() => submitBpClient()}
                                                title="Approve Proposal"
                                                disabled={selectedFlightSchedule ? false : true}
                                                className="app-btn app-btn-primary footer-btn"
                                            >
                                                <span className="button-text footer-button-text">Approve Proposal</span>
                                            </button>
                                        </>
                                    )}
                                    {/* {(headerData.bpStatus === 'Ready_for_Booking' ||
                                        headerData.bpStatus === 'Booked' ||
                                        headerData.bpStatus === 'Draft_Booking') && (
                                        <Link
                                            title="Next"
                                            className="app-btn app-btn-primary footer-btn"
                                            to={`/air_booking_list/${id}/packing`}
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                    )} */}
                                </>
                            );
                        } else {
                            return (
                                <>
                                    <a
                                        title="Previous"
                                        onClick={() =>
                                            navigate(`/air_booking_list/${id}/options`, { state: { prev: true } })
                                        }
                                        className="app-btn app-btn-secondary footer-btn"
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </a>
                                    <button
                                        title="Next"
                                        className="app-btn app-btn-primary footer-btn"
                                        onClick={() => navigate(`/air_booking_list/${id}/packing`)}
                                        // disabled={!(headerData?.tabValues as any)?.airSchedules}
                                    >
                                        <span className="button-text footer-button-text">Next</span>
                                    </button>
                                    {/* {(headerData.bpStatus === 'Ready_for_Booking' ||
                                        headerData.bpStatus === 'Booked' ||
                                        headerData.bpStatus === 'Draft_Booking') && (
                                        <Link
                                            title="Next"
                                            className="app-btn app-btn-primary footer-btn"
                                            to={`/air_booking_list/${id}/packing`}
                                        >
                                            <span className="button-text footer-button-text">Next</span>
                                        </Link>
                                    )} */}

                                    <ShowForPermissions type="bp" permission="edit">
                                        {(profileType === 'agent' || profileType === 'forwarder') && (
                                            <>
                                                {(bpStatus === 'Draft_Booking_Proposal' ||
                                                    bpStatus === 'Rejected_by_Forwarder' ||
                                                    bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                                    <button
                                                        title="Submit for Approval"
                                                        className={`app-btn app-btn-primary footer-btn${
                                                            (
                                                                selectedView
                                                                    ? selectedSchedules?.length <= 0
                                                                    : isEmpty(selectedContract)
                                                            )
                                                                ? ' disabled'
                                                                : ''
                                                        }`}
                                                        disabled={getSubmitButtonStatus()}
                                                        onClick={() => {
                                                            if (
                                                                !(selectedView
                                                                    ? selectedSchedules?.length <= 0
                                                                    : isEmpty(selectedContract))
                                                            ) {
                                                                if (selectedView) {
                                                                    submitBp();
                                                                } else {
                                                                    addSelectedContract();
                                                                    setselectedView(true);
                                                                }
                                                            }
                                                        }}
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Submit for Approval
                                                        </span>
                                                    </button>
                                                )}
                                            </>
                                        )}
                                    </ShowForPermissions>
                                </>
                            );
                        }
                    })()}
                </div>
            </div>
            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            <RejectBookingProposalModal
                modalView={rejectionModalView}
                setModalView={setRejectionModalView}
                header={{
                    bpNumber: headerData?.bpNumber ?? '',
                    clientName: headerData?.clientName ?? '',
                    gateInDateExpired: headerData?.gateInDateExpired ?? '',
                }}
                userProfile={{ profileType, userName }}
                reason={reason}
                setReason={setReason}
                headerText="Reject Booking Proposal"
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            <RejectBpClientModal
                modalView={rejectBpModalView}
                setModalView={setRejectBpModalView}
                reason={headerData?.reason ?? ''}
                setReason={setReason}
            />
            <RejectBookingProposalModal
                modalView={assignAgentModalView}
                setModalView={setAssignAgentModalView}
                header={{
                    bpNumber: headerData.bpNumber != null ? headerData.bpNumber : '',
                    clientName: headerData.clientName != null ? headerData.clientName : '',
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={{ profileType, userName }}
                reason={reasonRejection}
                setReason={setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={setRejectedBpForwarderModalView}
                gateInDateExpired={headerData?.gateInDateExpired}
            />
        </div>
    );
};
export default Index;
