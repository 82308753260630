import { AxiosResponse } from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import {
    saveBpData,
    saveBpFilterData,
    saveBpStatusCount,
} from '../../reducers/aobookingProposal/bookingProposalListReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    getBpData,
    BpResponse,
    getFilterValuesForBp,
    getBpStatusCount,
} from '../../../services/apis/aobookingProposal/bookingProposalListingApi';

function* getBpListData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const bookingProposals: AxiosResponse<BpResponse> = yield call(getBpData, payload);
        payload['bookingProposals'] = bookingProposals;
        yield put({ type: saveBpData, payload: payload });
    } finally {
        yield put(hideLoading());
    }
}

function* getBpfilterData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const filterData: AxiosResponse<any[]> = yield call(getFilterValuesForBp, payload);
        payload['filterData'] = filterData;
        yield put({ type: saveBpFilterData, payload: payload });
    } finally {
        yield put(hideLoading());
    }
}

function* getBpStatusData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const count: AxiosResponse<any[]> = yield call(getBpStatusCount, payload);
        payload['count'] = count;
        yield put({ type: saveBpStatusCount, payload: payload });
    } finally {
        yield put(hideLoading());
    }
}

function* bpListSaga() {
    yield takeLatest('bookingProposalListao/fetchBpData', getBpListData);
    yield takeLatest('bookingProposalListao/fetchBpFilterData', getBpfilterData);
    yield takeLatest('bookingProposalListao/fetchBpStatusCount', getBpStatusData);
}

// Export the saga (data-saga)
export default bpListSaga;
