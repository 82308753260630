import React from 'react';

const Index: React.FC = () => {
    return (
        <div style={{ zIndex: 999 }} className="app-loader loading">
            <svg className="svg-icon loader-icon">
                <use xlinkHref="#loaderIcon">
                    <title>Loading</title>
                </use>
            </svg>
        </div>
    );
};

export default Index;
