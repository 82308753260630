import React, { useEffect, useState } from 'react';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';

const Index: React.FC = () => {
    const [modal, setModal] = useState('');
    const [whPk, setWhPk] = useState('');

    //@ts-ignore
    window.whAction = (type: string, wh_pk?: string) => {
        if (type === 'EDT') {
            // EDIT
            setWhPk(wh_pk || '');
            setModal('EDT');
        } else if (type === 'DEL') {
            // DELETE
            setWhPk(wh_pk || '');
            setModal('DEL');
        } else if (type === 'NEW') {
            // ADD NEW WAREHOUSE
            setModal('NEW');
        }
    };

    return (
        <>
            <div className="main-wrapper">
                <div className="main-content-holder no-main-grid-border booking-grid">
                    <h2 className="page-heading">Warehouse Management</h2>
                    <div className="grid-section">
                        <div className="grid-holder">
                            <div className="grid-header">
                                {/* grid external action buttons or header can be placed here*/}
                            </div>
                            <div className="grid-body__kendo">
                                <LayoutWithColumns content="warehouse-config" style="fullpage" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Index;
