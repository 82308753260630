/* eslint-disable no-undefined */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import './index.scss';
import 'src/assets/scss/components/_modal.scss';
import 'src/assets/scss/components/_grid.scss';
import '../../Profiles/Clients/ProductMaster/product-master-modal.scss';
import { useParams } from 'react-router-dom';
import moment from 'moment';

type NoteProps = {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    eventsData: any;
};

const Index: React.FC<NoteProps> = ({ setModalView, eventsData }) => {
    const { id } = useParams();

    const onClose = () => {
        setModalView(false);
    };

    return (
        <div className="app-modal large-popup notes-modal">
            <div className="modal-content-holder sop-content-holder large-popup">
                <div className="modal-header">
                    <h2 className="title">Event Details</h2>
                    <button onClick={() => onClose()} className="app-btn modal-close-btn" title="Close">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-section card">
                        <div className="modal-main-content details-box">
                            <div className="grid-container" style={{ overflow: 'auto' }}>
                                <div className="grid-scroll-content">
                                    <table className="grid-table">
                                        <thead>
                                            <tr className="grid-sticky-row">
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span>Event Code</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span>Event Description</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span>Event Details</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header">
                                                    <div className="grid-cell-data">
                                                        <span>Date</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {eventsData?.map((res: any, index: number) => (
                                                <tr key={index} className="has-child row-expanded">
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            <span className="client-id">{res?.EV_CD_Code}</span>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            <span className="client-id">{res?.CD_Value}</span>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.EV_Context}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {res?.EV_CreatedDateTime
                                                                ? moment(res?.EV_CreatedDateTime).format(
                                                                      'DD-MM-YYYY HH:mm:ss'
                                                                  )
                                                                : '-'}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    {/* <button className="app-btn app-btn-primary footer-btn" title="Submit" type="button">
                        <span className="button-text footer-button-text">Save</span>
                    </button> */}
                    <button
                        onClick={() => onClose()}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Close"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Close</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
