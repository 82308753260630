/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
// import ConfirmBookingModal from './ConfirmBookingModal';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    submitPo,
    selectProductDetailsConfirmation,
    fetchProductDetailsConfirmationList,
    selectProductDetailsConfirmationLoading,
    ProductDetailsConfirmationDataDetails,
    // SplitLines,
    // confirmPo,
    selectProductDetailsConfirmationIsError,
    selectProductDetailsConfirmationSuccess,
    selectProductDetailsConfirmationError,
    clearFailure,
    // removeProductDetailsConfirmation,
    // editPoLineStatus,
    fetchLclThreshold,
    selectLclThreshold,
    ConfirmationGroups,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import moment from 'moment';
import Breadcrumbs from 'src/components/Breadcrumbs/purchaseOrder';
import { fetchHeader, selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import './index.scss';
import DatePicker from 'src/components/common/DatePicker/withoutFormik';
import { error, Position, success } from 'src/utils/toast';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import ConfirmShipmentModal from './ConfirmShipmentModal';
import { poStatusPillList } from './PoStatusList';
// import ShowForPermissions from 'src/ShowForPermissions';
import { usePrompt } from 'src/hooks/usePrompt';
import UnsavedChangeModal from 'src/pages/AOC/ProductAllocation/UnsavedChangeModal';
import {
    fetchDestinationAddress,
    selectDestinationAddress,
    // fetchPort,
    selectPort,
} from 'src/store/reducers/allocationOrder/productAllocationReducer';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { MenuPlacement } from 'react-select';
import ConfirmProductsModal from './ConfirmProductsModal';
import ConfirmBookingModal from './ConfirmBookingModal';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useFormik } from 'formik';
import {
    fetchShipmentPoList,
    fetchSkuList,
    selectProductPo,
    selectSku,
    // fetchPort,
} from 'src/store/reducers/purchaseOrder/shipmentConfirmationReducer';
import { searchUnlocoList } from 'src/store/reducers/createVendorReducer';
import Select from 'react-select';
import {
    createShipmentLine,
    deleteShipmentLine,
    updateShipmentLine,
} from 'src/services/apis/purchaseOrder/shipmentConfirmationApi';
import CbmUnavailableModal from '../ProductDetails/CbmUnavailableModal';
import SkuUnavailableModal from '../ProductDetails/SkuUnavailableModal';
import { decimalNumberValidation } from 'src/utils';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import PODetailsNextButton from 'src/pages/PO/PODetailsNextButton';

const allDistinctValues = [
    'In Booking Proposal',
    'Booked',
    'Pending Confirmation',
    'LCL Under Approval',
    'Dropped',
    'Ready to Receive',
    'Confirmed for Booking',
];

interface ProductsPo {
    cbm: string;
    weight: string;
    goodsReadyDate: string;
    destination: string;
    destinationAddress: string;
    sku: string;
    skuId: string;
    qty: any;
    hscode: string;
}

const Index: React.FC = () => {
    // const { permissions } = useSelector((state: RootState) => state.permissionData);
    const { id } = useParams();
    const navigate = useNavigate();
    const { profileType, userName, fullName } = useUserProfile();
    const dispatch = useAppDispatch();
    const [showAddress, setShowAddress] = useState(false);
    const [menuPlacement, _setMenuPlacement] = useState<MenuPlacement>('auto');
    // const [ports, setPorts] = useState<any>([]);
    const [distanceFromTopForAddress, setDistanceFromTopForAddress] = useState(0);
    const [distanceFromLeftForAddress, setDistanceFromLeftForAddress] = useState(0);
    const [searchName, setSearchName] = useState('');
    const [unsavedChangeModalVisibility, setUnsavedChangeModalVisibility] = useState(false);
    const [callBackFn, setCallBackFn] = useState<any>();
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [_filterDropdown, setFilterDropdown] = useState(false);
    // const [selectedFilter, setSelectedFilter] = useState('');
    const [distinctValues, setDistinctValues] = useState<string[]>(allDistinctValues);
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>([]);
    const [setPosition, setSetPosition] = useState<number>(0);
    // const [search, setSearch] = useState('');
    const [modalViewConfirmShipment, setModalConfirmShipment] = useState(false);
    const [modalViewConfirm, setModalViewConfirm] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [productPo, setProductPo] = useState<ProductDetailsConfirmationDataDetails[]>([]);
    const [currentIndex, _setCurrentIndex] = useState(0);
    const [currentSplitIndex, _setCurrentSplitIndex] = useState(0);
    const productDetailsConfirmation = useAppSelector(selectProductDetailsConfirmation);
    const destinationAddress = useAppSelector(selectDestinationAddress);
    const port: any = useAppSelector(selectPort);
    const isLoading = useAppSelector(selectProductDetailsConfirmationLoading);
    const isError = useAppSelector(selectProductDetailsConfirmationIsError);
    const productDetailsConfirmationSuccess = useAppSelector(selectProductDetailsConfirmationSuccess);
    const productDetailsConfirmationError = useAppSelector(selectProductDetailsConfirmationError);
    const lclThreshold = useAppSelector(selectLclThreshold);
    const headerData = useAppSelector(selectHeader);
    const userData = localStorage.getItem('user');
    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;
    const roleIndex = localStorage.getItem('roleIndex');
    const [unlocoLoadingFn, setunlocoLoadingFn] = useState(false);
    const [formatedAUPorts, setFormatedAUPorts] = useState([]);

    useEffect(() => {
        dispatch(
            fetchProductDetailsConfirmationList({
                poId: id,
            })
        );
        dispatch(fetchPermissions(username));
        dispatch(fetchLclThreshold({ userName }));
        //  dispatch(fetchPort({ key: 'a', type: 'PO', service: 'ord' }));
    }, []);

    useEffect(() => {
        headerData?.clientCode && dispatch(fetchDestinationAddress({ orgCode: headerData?.clientCode }));
    }, [headerData?.clientCode]);

    // const onPortSearch = (search: string) => {
    //     if (search != '') {
    //         dispatch(fetchPort({ key: search, type: 'PO', service: 'ord' }));
    //     }
    // };

    useEffect(() => {
        if (port?.length) {
            const mappedPort = port?.map((res: any) => ({
                value: res.portCode,
                label: `${res.portName} (${res.portCode})`,
                extraField: res.portName,
            }));
            const initial = [...ports, ...mappedPort];
            const result = initial.reduce((acc: any, item: any) => {
                if (!acc.find((other: any) => item.value == other.value)) {
                    acc.push(item);
                }
                return acc;
            }, []);
            setPorts(result);
        }
    }, [port]);

    useEffect(() => {
        if (productDetailsConfirmation?.enrichedProductDetails?.length) {
            const newArr = JSON.parse(JSON.stringify(productDetailsConfirmation?.enrichedProductDetails));
            for (let index = 0; index < newArr.length; index++) {
                newArr[index]['eachCbm'] = newArr[index]['cbm'] / newArr[index]['qty'];
                newArr[index]['eachWeight'] = newArr[index]['weight'] / newArr[index]['qty'];
                newArr[index].updatedQuantityType = newArr[index].quantityType;
                if (newArr[index]?.splitLines?.length) {
                    newArr[index]['isExpanded'] = true;
                    newArr[index]['saveTriggered'] = true;
                    for (let j = 0; j < newArr[index]?.splitLines.length; j++) {
                        newArr[index].splitLines[j].editMode = false;
                    }
                } else {
                    newArr[index]['isExpanded'] = true;
                    newArr[index]['saveTriggered'] = true;
                    newArr[index]['editMode'] = true;
                    if (isAgent) {
                        newArr[index]['splitLines'] = [
                            {
                                splitLineId: null,
                                splitLineStatus: null,
                                confirmedQty: '',
                                confirmedGoodsdate: '',
                                confirmedCbm: '',
                                confirmedWeight: '',
                                confirmedUom: '',
                                convertedQuantity: '',
                                poLineNumber: '',
                                editMode: true,
                                destination: newArr[index].destination,
                                destinationPort: newArr[index].destinationPort,
                                destinationPortName: newArr[index].destinationPortName,
                                destinationAddressId: newArr[index].destinationAddressId,
                                destinationAddress: newArr[index].destinationAddress,
                            },
                        ];
                    } else {
                        newArr[index]['splitLines'] = [];
                    }
                }
            }
            for (let i = 0; i < newArr.length; i++) {
                newArr[i].indexValue = i;
                const splitLines = newArr[i].splitLines;
                for (let j = 0; j < splitLines.length; j++) {
                    splitLines[j].splitLineIndexValue = j;
                }
            }
            setProductPo(newArr);
            if (newArr?.length) {
                const initial = [...ports];
                if (!newArr[0]?.destination?.includes('(null)')) {
                    initial.push({
                        label: newArr[0].destination,
                        value: newArr[0].destinationPort,
                        extraField: newArr[0].destinationPortName,
                    });
                }

                const result = initial.reduce((acc: any, item: any) => {
                    if (!acc.find((other: any) => item.value == other.value)) {
                        acc.push(item);
                    }

                    return acc;
                }, []);
                setPorts(result);
            }
        } else {
            setProductPo([]);
        }
    }, [productDetailsConfirmation?.enrichedProductDetails]);

    // const togglePoLine = (poProductId: string) => {
    //     const newArr: ProductDetailsConfirmationDataDetails[] = JSON.parse(JSON.stringify(productPo));
    //     const updatedArr = newArr.map((item) => {
    //         if (item.poProductId === poProductId) {
    //             return { ...item, isExpanded: !item.isExpanded };
    //         } else {
    //             return item;
    //         }
    //     });
    //     setProductPo(updatedArr);
    // };

    // const getRemainingQty = (index: number) => {
    //     const newArr = JSON.parse(JSON.stringify(productPo));
    //     const total = newArr[index]?.splitLines.reduce(
    //         (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
    //         0
    //     );
    //     if (total === 0) {
    //         return <span className="second-unit">(R:{newArr[index].qty})</span>;
    //     } else {
    //         if (total > newArr[index].qty) {
    //             const remaining = total - newArr[index].qty;
    //             if (newArr[index].saveTriggered) {
    //                 return <span className="second-unit">(R:0)</span>;
    //             } else {
    //                 return <span className="second-unit error-text">(R:0, S:{remaining})</span>;
    //             }
    //         } else {
    //             const remaining = newArr[index].qty - total;
    //             if (newArr[index].isShort) {
    //                 return <span className="second-unit">(R:0)</span>;
    //             } else {
    //                 return <span className="second-unit">(R:{remaining})</span>;
    //             }
    //         }
    //     }
    // };

    // const appendNewRow = (index: number) => {
    //     const newArr = JSON.parse(JSON.stringify(productPo));
    //     const newData = {
    //         splitLineId: null,
    //         splitLineStatus: null,
    //         confirmedQty: '',
    //         confirmedGoodsdate: '',
    //         confirmedCbm: '',
    //         confirmedWeight: '',
    //         confirmedUom: '',
    //         convertedQuantity: '',
    //         poLineNumber: '',
    //         editMode: true,
    //         splitLineIndexValue: '',
    //         destination: newArr[index].destination,
    //         destinationPort: newArr[index].destinationPort,
    //         destinationPortName: newArr[index].destinationPortName,
    //         destinationAddressId: newArr[index].destinationAddressId,
    //         destinationAddress: newArr[index].destinationAddress,
    //     };
    //     newArr[index].splitLines.push(newData);
    //     for (let i = 0; i < newArr.length; i++) {
    //         newArr[i].indexValue = i;
    //         const splitLines = newArr[i].splitLines;
    //         for (let j = 0; j < splitLines.length; j++) {
    //             splitLines[j].splitLineIndexValue = j;
    //         }
    //     }
    //     setProductPo(newArr);

    //     const initial = [...ports];

    //     if (!newArr[index]?.destination?.includes('(null)')) {
    //         initial.push({
    //             label: newArr[index].destination,
    //             value: newArr[index].destinationPort,
    //             extraField: newArr[index].destinationPortName,
    //         });
    //     }

    //     const result = initial.reduce((acc: any, item: any) => {
    //         if (!acc.find((other: any) => item.value == other.value)) {
    //             acc.push(item);
    //         }

    //         return acc;
    //     }, []);
    //     setPorts(result);
    // };

    // const checkIfIsInEditMode = (index: number) => {
    //     return productPo[index].splitLines.some((e) => e.editMode);
    // };

    // const getAllPendingConfirmationLines = () => {
    //     const pendingConfirmationPo = [];
    //     for (let i = 0; i < productPo.length; i++) {
    //         const splitLines = productPo[i].splitLines;
    //         for (let j = 0; j < splitLines.length; j++) {
    //             if (splitLines[j].splitLineStatus === 'Pending_confirmation') {
    //                 if (pendingConfirmationPo.indexOf(splitLines[j].splitLineId) === -1) {
    //                     pendingConfirmationPo.push(splitLines[j].splitLineId);
    //                 }
    //             }
    //         }
    //     }

    //     return pendingConfirmationPo;
    // };

    const checkAtLeastOneEditExists = () => {
        const editMode = [];
        for (let i = 0; i < productPo.length; i++) {
            const splitLines = productPo[i].splitLines;
            for (let j = 0; j < splitLines.length; j++) {
                if (
                    splitLines[j].editMode &&
                    (typeof splitLines[j].confirmedQty === 'number' || splitLines[j].confirmedGoodsdate != '')
                ) {
                    editMode.push(splitLines[j]);
                }
            }
        }

        return editMode?.length ? true : false;
    };

    // const updateFieldChanged = (index: number, splitLineIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const newArr = JSON.parse(JSON.stringify(productPo));
    //     newArr[index].splitLines[splitLineIndex] = {
    //         ...newArr[index].splitLines[splitLineIndex],
    //         [e.target.name]: +e.target.value,
    //         confirmedCbm: parseFloat((+e.target.value * newArr[index].eachCbm).toFixed(5)),
    //         confirmedWeight: parseFloat((+e.target.value * newArr[index].eachWeight).toFixed(5)),
    //     };
    //     const total = newArr[index]?.splitLines.reduce(
    //         (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
    //         0
    //     );
    //     if (total === 0) {
    //         newArr[index].quantityType = 'Dropped';
    //     } else if (total > newArr[index].qty) {
    //         newArr[index].quantityType = 'Surplus';
    //     } else if (total < newArr[index].qty) {
    //         newArr[index].quantityType = 'Short Shipped';
    //     } else if (total === newArr[index].qty) {
    //         newArr[index].quantityType = 'Fulfilled';
    //     }
    //     newArr[index].saveTriggered = false;

    //     setProductPo(newArr);
    // };

    // const updateDateChanged = (date: string) => {
    //     const newArr = JSON.parse(JSON.stringify(productPo));
    //     newArr[currentIndex].splitLines[currentSplitIndex] = {
    //         ...newArr[currentIndex].splitLines[currentSplitIndex],
    //         confirmedGoodsdate: moment(date).format('YYYY-MM-DD'),
    //     };

    //     setProductPo(newArr);
    // };

    // const removePoLine = (index: number, splitLineIndex: number, splitLineId: string) => {
    //     const newArr = JSON.parse(JSON.stringify(productPo));
    //     newArr[index].splitLines.splice(splitLineIndex, 1);
    //     if (newArr[index]?.splitLines?.length) {
    //         const total = newArr[index]?.splitLines.reduce(
    //             (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
    //             0
    //         );
    //         if (total === 0) {
    //             newArr[index].quantityType = 'Dropped';
    //         } else if (total > newArr[index].qty) {
    //             newArr[index].quantityType = 'Surplus';
    //         } else if (total < newArr[index].qty) {
    //             newArr[index].quantityType = 'Short Shipped';
    //         } else if (total === newArr[index].qty) {
    //             newArr[index].quantityType = 'Fulfilled';
    //         }
    //     } else {
    //         newArr[index].quantityType = 'Pending';
    //     }
    //     if (splitLineId) {
    //         const quantityType = newArr[index].quantityType === 'Short Shipped' ? 'Short' : newArr[index].quantityType;
    //         dispatch(
    //             removeProductDetailsConfirmation({
    //                 splitLineId,
    //                 quantityType,
    //                 currentIndex: index,
    //                 currentSplitLineIndex: splitLineIndex,
    //                 isShort: false,
    //             })
    //         );
    //     }
    //     for (let i = 0; i < newArr.length; i++) {
    //         newArr[i].indexValue = i;
    //         const splitLines = newArr[i].splitLines;
    //         for (let j = 0; j < splitLines.length; j++) {
    //             splitLines[j].splitLineIndexValue = j;
    //         }
    //     }
    //     setProductPo(newArr);
    // };
    // const confirmPoLine = (index: number, splitLineIndex: number) => {
    //     setCurrentIndex(index);
    //     setCurrentSplitIndex(splitLineIndex);
    //     const newArr = JSON.parse(JSON.stringify(productPo));
    //     if (newArr[index].quantityType === 'Surplus') {
    //         setModalConfirmShipment(true);
    //     } else {
    //         if (newArr[index].splitLines[splitLineIndex].splitLineId) {
    //             dispatch(
    //                 editPoLineStatus({
    //                     ...newArr[index].splitLines[splitLineIndex],
    //                     splitLineId: newArr[index].splitLines[splitLineIndex].splitLineId,
    //                     quantityType: newArr[index].quantityType,
    //                     currentIndex: index,
    //                     currentSplitLineIndex: splitLineIndex,
    //                     isShort: false,
    //                 })
    //             );
    //         } else {
    //             dispatch(
    //                 confirmPo({
    //                     ...newArr[index].splitLines[splitLineIndex],
    //                     poProductId: productPo[index].poProductId,
    //                     quantityType: newArr[index].quantityType,
    //                     currentIndex: index,
    //                     currentSplitLineIndex: splitLineIndex,
    //                     isShort: false,
    //                 })
    //             );
    //         }
    //         newArr[index].splitLines[splitLineIndex].editMode = false;
    //         setProductPo(newArr);
    //     }
    // };

    // const checkSaveButtonStatus = (splitLine: SplitLines) => {
    //     if (
    //         splitLine?.confirmedQty >= 0 &&
    //         splitLine?.confirmedGoodsdate &&
    //         splitLine?.destination &&
    //         splitLine?.destinationAddress
    //     ) {
    //         return false;
    //     } else {
    //         return true;
    //     }
    // };

    const updateAfterFormSubmit = (index: number, splitLineIndex: number, type: string) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        if (typeof index === 'number' && newArr[index]?.quantityType) {
            newArr[index].updatedQuantityType = newArr[index].quantityType;
        }
        if (type === 'confirmPo') {
            newArr[index].saveTriggered = true;
            newArr[index].splitLines[splitLineIndex].editMode = false;
            newArr[index].splitLines[splitLineIndex].splitLineStatus =
                productDetailsConfirmationSuccess?.data?.info?.splitLineStatus;
            newArr[index].isShort = false;
            const total = newArr[index]?.splitLines.reduce(
                (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
                0
            );
            if (total === 0) {
                newArr[index].status = 'Dropped';
            }
            newArr[index].splitLines[splitLineIndex].splitLineId =
                productDetailsConfirmationSuccess?.data?.info?.splitLineId;
        }
        if (type === 'editPoLineStatus') {
            newArr[index].saveTriggered = true;
            newArr[index].splitLines[splitLineIndex].editMode = false;
            newArr[index].isShort = false;
            newArr[index].splitLines[splitLineIndex].splitLineStatus =
                productDetailsConfirmationSuccess?.data?.info?.splitLineStatus;
            const total = newArr[index]?.splitLines.reduce(
                (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
                0
            );
            if (total === 0) {
                newArr[index].status = 'Dropped';
            }
        }
        if (type === 'changePoLineStatus') {
            newArr[index].saveTriggered = true;
            newArr[index].isShort = true;
        }
        if (type === 'dropPo') {
            newArr[index].saveTriggered = true;
            newArr[index].status = 'Dropped';
            newArr[index].quantityType = 'Dropped';
            newArr[index].qty = 0;
            newArr[index].weight = 0;
            newArr[index].cbm = 0;
        }
        if (type === 'removePo') {
            for (let i = 0; i < newArr.length; i++) {
                newArr[i].indexValue = i;
                const splitLines = newArr[i].splitLines;
                for (let j = 0; j < splitLines.length; j++) {
                    splitLines[j].splitLineIndexValue = j;
                }
            }
        }
        setProductPo(newArr);
    };

    // const updateData = (data: any, type: string) => {
    //     const newArr = JSON.parse(JSON.stringify(productPo));
    //     if (type === 'address') {
    //         newArr[currentIndex].splitLines[currentSplitIndex].destinationAddressId = data?.oa_PK
    //             ? data.oa_PK
    //             : data.addressId;
    //         newArr[currentIndex].splitLines[currentSplitIndex].destinationAddress = data.fullAddress;
    //     } else {
    //         newArr[currentIndex].splitLines[currentSplitIndex].destinationPortName = data?.extraField;
    //         newArr[currentIndex].splitLines[currentSplitIndex].destinationPort = data?.value;
    //         newArr[currentIndex].splitLines[currentSplitIndex].destination = data?.label;
    //         newArr[currentIndex].splitLines[currentSplitIndex].destinationAddressId = '';
    //         newArr[currentIndex].splitLines[currentSplitIndex].destinationAddress = '';
    //     }
    //     setProductPo(newArr);
    // };

    useEffect(() => {
        if (isError) {
            error(productDetailsConfirmationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (
            productDetailsConfirmationSuccess?.status === 200 ||
            productDetailsConfirmationSuccess?.status === 201
        ) {
            if (
                productDetailsConfirmationSuccess?.data?.type === 'confirmPo' ||
                productDetailsConfirmationSuccess?.data?.type === 'editPoLineStatus'
            ) {
                success(
                    `PO Line ${
                        productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            ? productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            : ''
                    } confirmed successfully`,
                    Position.TOP_RIGHT
                );
            } else if (productDetailsConfirmationSuccess?.data?.type === 'submitPo') {
                success(`PO ${headerData?.poNumber} has been confirmed for Booking Proposal`, Position.TOP_RIGHT);
                setTimeout(() => {
                    if (headerData?.shippingMode?.shippingModeCode === 'AIR') {
                        navigate(`/purchase_order`);
                    } else {
                        navigate(`/purchase_order/${id}/container_mode_selection`);
                    }
                }, 2000);
            } else if (productDetailsConfirmationSuccess?.data?.type === 'removePo') {
                success('Split line removed successfully', Position.TOP_RIGHT);
            } else if (productDetailsConfirmationSuccess?.data?.type === 'changePoLineStatus') {
                success(
                    `PO Line ${
                        productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            ? productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            : ''
                    } confirmed as Short Shipped`,
                    Position.TOP_RIGHT
                );
            } else if (productDetailsConfirmationSuccess?.data?.type === 'dropPo') {
                success(
                    `PO Line ${
                        productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            ? productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            : ''
                    } dropped`,
                    Position.TOP_RIGHT
                );
            }
            updateAfterFormSubmit(
                productDetailsConfirmationSuccess?.data?.currentIndex,
                productDetailsConfirmationSuccess?.data?.currentSplitLineIndex,
                productDetailsConfirmationSuccess?.data?.type
            );
            dispatch(clearFailure([]));
            if (!checkAtLeastOneEditExists()) {
                dispatch(fetchProductDetailsConfirmationList({ poId: id }));
            }
            dispatch(fetchHeader({ poId: id }));
        }
    }, [isError, productDetailsConfirmationSuccess, productDetailsConfirmationError]);

    // const searchOrFilterFn = (productsData: ProductDetailsConfirmationDataDetails[], includeFilter: boolean) => {
    //     if (search.trim().length || statusFilterSelected?.length || selectedFilter) {
    //         const searchKey = search.toLocaleLowerCase();
    //         const duplicate = JSON.parse(JSON.stringify(productsData));
    //         let searchResult = duplicate?.filter((el: any) => {
    //             const searchCondition = searchKey ? el.sku.toLocaleLowerCase().includes(searchKey) : true;
    //             return searchCondition;
    //         });
    //         if (selectedFilter && includeFilter) {
    //             if (selectedFilter === 'Short Shipped') {
    //                 searchResult = searchResult?.filter(
    //                     (res: any) => res.updatedQuantityType === selectedFilter && res?.isShort
    //                 );
    //             } else if (selectedFilter === 'Pending') {
    //                 searchResult = searchResult?.filter(
    //                     (res: any) =>
    //                         res.updatedQuantityType === selectedFilter ||
    //                         (res.updatedQuantityType === 'Short Shipped' && !res?.isShort)
    //                 );
    //             } else {
    //                 searchResult = searchResult?.filter((res: any) => res.updatedQuantityType === selectedFilter);
    //             }
    //         }

    //         if (typeof statusFilterSelected[0] === 'string') {
    //             let filtered: any = [];
    //             for (let i = 0; i < searchResult.length; i++) {
    //                 if (statusFilterSelected[0] === 'Dropped') {
    //                     filtered = searchResult?.filter((res: any) => res.status === 'Dropped');
    //                 } else {
    //                     const splitLines = searchResult[i].splitLines;
    //                     const onePoLine = splitLines.some(
    //                         (e: any) => convertFilterName(e.splitLineStatus) === statusFilterSelected[0]
    //                     );

    //                     if (onePoLine) {
    //                         filtered.push(searchResult[i]);
    //                         const filteredIndex = filtered?.findIndex(
    //                             (res: any) => res.poProductId === searchResult[i].poProductId
    //                         );
    //                         if (filteredIndex >= 0) {
    //                             const filteredSplitLines = filtered[filteredIndex].splitLines.filter(
    //                                 (res: any) => convertFilterName(res.splitLineStatus) === statusFilterSelected[0]
    //                             );
    //                             filtered[filteredIndex].splitLines = filteredSplitLines;
    //                         }
    //                     }
    //                 }
    //             }
    //             return filtered;
    //         } else {
    //             return searchResult;
    //         }
    //     } else {
    //         return productsData;
    //     }
    // };

    // const searchOrFilter = searchOrFilterFn(productPo, true);

    // const getFilterCount = (filter: string) => {
    //     const result = searchOrFilterFn(productPo, false);
    //     if (filter === '') {
    //         return result?.length;
    //     } else {
    //         if (filter === 'Short Shipped') {
    //             return result?.filter((res: any) => res.updatedQuantityType === filter && res?.isShort)?.length;
    //         } else if (filter === 'Pending') {
    //             return result?.filter(
    //                 (res: any) =>
    //                     res.updatedQuantityType === filter ||
    //                     (res.updatedQuantityType === 'Short Shipped' && !res?.isShort)
    //             )?.length;
    //         } else {
    //             return result?.filter((res: any) => res.updatedQuantityType === filter)?.length;
    //         }
    //     }
    // };

    // const filterByColumn = (e: any) => {
    //     setToggleDropdown(!toggleDropdown);
    //     e.stopPropagation();
    // };

    const resizeCheckboxFilter = () => {
        const el_filter_div = document.getElementById('filter_div');
        if (el_filter_div?.getBoundingClientRect().left) {
            setSetPosition(el_filter_div?.getBoundingClientRect().left - 35);
        }
    };

    const filterCheckbox = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        if (keyword) {
            const results = allDistinctValues.filter((user: string) => {
                return user && user?.toLowerCase().includes(keyword.toLowerCase());
            });
            setDistinctValues(results);
        } else {
            setDistinctValues(allDistinctValues);
        }
        setSearchName(keyword);
    };

    const resizeFilter = (idSelector: string) => {
        const el_table = document.getElementById('table_div');
        let gridDistance = 0;
        let gridDistanceLeft = 0;
        if (el_table?.getBoundingClientRect().top) {
            gridDistance = el_table?.getBoundingClientRect().top - 40;
            gridDistanceLeft = el_table?.getBoundingClientRect().left + 4;
        }

        const el_address = document.getElementById(idSelector);
        const dropdown_address_bottom = document.getElementById(idSelector)?.getBoundingClientRect().bottom || 0;
        const spaceBelow = window.innerHeight - dropdown_address_bottom;

        if (el_address?.getBoundingClientRect().top) {
            if (spaceBelow < 150) {
                gridDistance = gridDistance + 110;
            }
            setDistanceFromTopForAddress(el_address?.getBoundingClientRect().top - gridDistance);
        }
        if (el_address?.getBoundingClientRect().left) {
            setDistanceFromLeftForAddress(el_address?.getBoundingClientRect().left - gridDistanceLeft);
        }
    };

    // const menuPlacementCheck = (index: number, splitLineIndex: number) => {
    //     const windowHeight = document.documentElement.clientHeight;
    //     const noMessageHeight = 300;

    //     const element = document.getElementById(`edit_row_${index}_${splitLineIndex}`);
    //     if (element) {
    //         const bottom = element.getBoundingClientRect().bottom;
    //         const spaceBelow = windowHeight - bottom;
    //         spaceBelow < noMessageHeight ? setMenuPlacement('top') : setMenuPlacement('auto');
    //     }
    // };

    // const checkPermission = (permission: any, type: any) => {
    //     var flag = true;

    //     for (var i = 0; i < permissions?.length; i++) {
    //         if (
    //             (permissions[i] as any).access
    //                 .toLowerCase()
    //                 .split(/\s+|\./)
    //                 .includes(permission.toLowerCase()) &&
    //             (permissions[i] as any).access
    //                 .toLowerCase()
    //                 .split(/\s+|\./)
    //                 .includes(type.toLowerCase())
    //         ) {
    //             flag = false;
    //         }
    //     }
    //     return flag;
    // };
    let isAgent = false;
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            isAgent =
                roles[roleIndex].profile?.toLowerCase() === 'agent' ||
                roles[roleIndex].profile?.toLowerCase() === 'forwarder'
                    ? true
                    : false;
        }
    }

    const customStyle = {
        menuPortal: (provided: any) => ({
            ...provided,
            width: '350px',
            zIndex: 999,
        }),
        menuList: (base: any) => ({
            ...base,
            '::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: '#F6F6FE',
            },
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(#000, 0.3)',
                backgroundColor: '#C4C6D0',
            },
        }),
    };

    const portalStyle = {
        ...customStyle,
        noOptionsMessage: (base: any) => ({ ...base, height: '180px' }),
    };

    const handleConfirm = useCallback(() => {
        setShowConfirmModal(false);
        dispatch(submitPo({ poId: headerData?.poId, isAirFreight: true }));
    }, [headerData?.poId]);

    usePrompt('Leave screen?', checkAtLeastOneEditExists(), setUnsavedChangeModalVisibility, setCallBackFn);

    //new codes ================================================================================================>>>>>>>>>>>>>>>>>>>

    const [add, setAdd] = useState(false);
    const [editItem, setEditItem] = useState<any>(null);
    const [singleProduct, setSingleProduct] = useState<any>(null);
    const [ports, setPorts] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');

    const { unLocoList, isLoading: unlocoLoading } = useSelector((state: RootState) => state.vendorDetails);

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            dispatch(
                searchUnlocoList({
                    searchTerm,
                    portType: headerData?.shippingMode?.shippingModeCode === 'AIR' ? 'Air' : null,
                    service: 'ord',
                })
            );
        }
    }, [searchTerm]);

    useEffect(() => {
        if (unlocoLoading && searchTerm?.length > 2) {
            setunlocoLoadingFn(true);
        } else {
            setunlocoLoadingFn(false);
        }
    }, [unlocoLoading, searchTerm]);

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    country: option.portCountry,
                };
            });
            setPorts(portList);
        }
    }, [unLocoList, searchTerm]);
    const shipmentPo = useSelector(selectProductPo);
    useEffect(() => {
        dispatch(fetchShipmentPoList({ poId: id }));
    }, []);

    const ProductsPoSchema = Yup.object().shape({
        // skuId: Yup.string().required('SKU is required'),
        // sku: Yup.string().required('SKU is required'),
        destination: Yup.string().required('Destination is required'),
        destinationAddress: Yup.string().required('Destination Address is required'),
        // countryCode: Yup.string().required('Country of origin is required'),
        qty: Yup.string()
            // .required('Quantity is required')
            .test('no-leading-zero', 'Quantity must be greater than zero', (context: any) => {
                // if (headerData?.poStatus === 'Draft' || (headerData?.enriched && add)) {
                if (!context) {
                    return true;
                }
                return context && parseFloat(context) !== 0;
                // } else {
                //     return true;
                // }
            }),
        // unitCost: Yup.number()
        //     .typeError('Unit cost is required')
        //     .required('Unit cost is required')
        //     .test('no-leading-zero', 'Unit cost must be greater than zero', (context: any) => {
        //         if (headerData?.poStatus === 'Draft') {
        //             return context && parseFloat(context) !== 0;
        //         } else {
        //             return true;
        //         }
        //     }),
        // currency: Yup.string().required('Currency is required'),
        cbm: Yup.number()
            .required('CBM is required')
            .test('no-leading-zero', 'CBM must be greater than zero', (context: any) => {
                return context && parseFloat(context) !== 0;
            }),
        weight: Yup.number()
            .required('Weight is required')
            .test('no-leading-zero', 'Weight must be greater than zero', (context: any) => {
                return context && parseFloat(context) !== 0;
            }),
        goodsReadyDate: Yup.date().required('Goods Ready Date is required'),
    });

    const initialValues: ProductsPo = {
        cbm: singleProduct?.cbm || '',
        weight: singleProduct?.weight || '',
        goodsReadyDate: singleProduct?.goodsReadyDate || '',
        destination: singleProduct?.destination || '',
        destinationAddress: singleProduct?.destinationAddress || '',
        sku: singleProduct?.sku || '',
        skuId: singleProduct?.skuId || '',
        qty: singleProduct?.qty || '',
        hscode: singleProduct?.hscode || '',
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ProductsPoSchema,
        onSubmit: async (values: any) => {
            if (formik.isSubmitting) {
                // if (headerData?.isMultiAddress) {
                //     const destination = values.destinationPort.toString().split('(');
                //     values.destinationPort = destination[1].toString().split(')')[0];
                //     values.destinationPortName = destination[0];

                const formatedData = {
                    cbm: values.cbm,
                    weight: values.weight,
                    goodsReadyDate: moment(values.goodsReadyDate).format('DD-MM-YYYY'),
                    destination: values.destination,
                    destinationAddress: values.destinationAddress,
                    sku: values.sku,
                    skuId: values.skuId,
                    qty: values.qty,
                    hsCode: values.hscode,
                };
                // }
                if (add) {
                    const response = await createShipmentLine(id ?? '', formatedData);
                    if (response.success) {
                        success(`Shipment Confirmation Line added`, Position.TOP_RIGHT);
                        setEditMode(false);
                        setAdd(false);
                        setSingleProduct(null);
                        formik.resetForm();
                        dispatch(fetchShipmentPoList({ poId: id }));
                        setEditItem(null);
                        dispatch(fetchHeader({ poId: id }));
                    } else {
                        error(response?.message || 'Something went wrong', Position.TOP_RIGHT);
                    }
                } else {
                    const response = await updateShipmentLine(editItem ?? '', formatedData);
                    if (response.success) {
                        success(`Shipment Confirmation Line updated`, Position.TOP_RIGHT);
                        setEditMode(false);
                        setSingleProduct(null);
                        formik.resetForm();
                        dispatch(fetchShipmentPoList({ poId: id }));
                        setEditItem(null);
                        dispatch(fetchHeader({ poId: id }));
                    } else {
                        error(response?.message || 'Something went wrong', Position.TOP_RIGHT);
                    }
                }
                // const hsCode = sku ? sku.find((e: SkuDataDetails) => e.pm_PK === values.skuId)?.pm_purchasecode : '';
                // if (add) {
                //     dispatch(
                //         createProductPo({
                //             ...values,
                //             poId: id,
                //             poLineNumber: products?.length ? products[products?.length - 1].poLineNumber + 1 : 1,
                //             hsCode,
                //         })
                //     );
                // } else if (edit) {
                //     dispatch(updateProductPo({ ...values, poId: id, poProductId: singleProduct.poProductId, hsCode }));
                // }
            }
        },
    });

    const [modalViewSku, setModalViewSku] = useState(false);

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#errorCautionIcon"></use>
                </svg>
                <p className="no-content-message">
                    No matches found. <br />
                    SKU added to the Product Master <br />
                    will only be available for selection
                </p>
                <br />
                <a
                    className="redirection-link"
                    onClick={() => {
                        setModalViewSku(true);
                    }}
                >
                    Go to Product Master
                </a>
            </div>
        );
    };

    const addLine = () => {
        setAdd(true);
        formik.resetForm();
        setEditItem(null);
        setEditMode(true);

        const isSingleLocation = !headerData?.isMultiAddress;

        setSingleProduct({
            cbm: '',
            weight: '',
            goodsReadyDate: '',
            destination: isSingleLocation ? headerData?.destination ?? '' : '',
            destinationAddress: isSingleLocation ? headerData?.destinationAddress ?? '' : '',
            sku: '',
            status: '',
            qty: '',
            hscode: '',
        });
    };

    async function handleDelete(sId?: string) {
        if (sId) {
            const response = await deleteShipmentLine(sId);
            if (response.success) {
                success(`Shipment Confirmation Line deleted`, Position.TOP_RIGHT);
                dispatch(fetchShipmentPoList({ poId: id }));
                dispatch(fetchHeader({ poId: id }));
            } else {
                error(response?.message || 'Something went wrong', Position.TOP_RIGHT);
            }
        }
        setAdd(false);
        setEditMode(false);
        setSingleProduct(null);
        formik.resetForm();
    }

    async function handleEdit(sId: string, item: any) {
        formik.resetForm();
        setAdd(false);
        setEditMode(true);
        setEditItem(sId);
        setSingleProduct({
            cbm: item?.cbm,
            weight: item?.weight,
            goodsReadyDate: item?.goodsReadyDate,
            destination: item?.destination,
            destinationAddress: item?.destinationAddress,
            sku: item?.sku,
            skuId: item?.skuId,
            qty: item?.qty,
            hscode: item?.hsCode,
        });
        const initial = [...ports];
        const newArr = JSON.parse(JSON.stringify(shipmentList));
        const index = newArr.findIndex((el: any) => el.shipmentId === sId);
        if (!newArr[sId]?.destination?.includes('(null)')) {
            initial.push({
                label: newArr[index].destination,
                value: newArr[index].destinationPort,
                extraField: newArr[index].destinationPortName,
            });
        }

        const result = initial.reduce((acc: any, item: any) => {
            if (!acc.find((other: any) => item.value == other.value)) {
                acc.push(item);
            }

            return acc;
        }, []);
        setPorts(result);
    }

    const getAllPendingShipmentConfirmationLines = () => {
        // const pendingShipments = shipmentPo?.filter((s) => s.status === 'Pending Confirmation');
        const pendingShipments = shipmentPo?.filter(
            (s) => s.splitLines?.[0]?.splitLineStatus === 'Pending Confirmation'
        );

        return pendingShipments ?? [];
    };

    const shipmentList = add && singleProduct ? [...(shipmentPo ?? []), singleProduct] : shipmentPo;
    const getAllConfirmationGroups = () => {
        // const confirmationGroups: any[] = [];
        // const lclThresholdPercentage = lclThreshold / 100;
        // let count = headerData?.totalConfirmationGroups ? headerData?.totalConfirmationGroups + 1 : 1;
        // const len = shipmentPo?.length ?? 0;
        // for (let i = 0; i < len; i++) {
        //     if (shipmentPo[i]?.status === 'Pending Confirmation') {
        //         const checkIfExists = confirmationGroups?.findIndex((o: any) => {
        //             return (
        //                 o?.goodsReadyDate === moment(shipmentPo[i].goodsReadyDate).format('DD-MM-YYYY') &&
        //                 o?.destination === shipmentPo[i].destination
        //             );
        //         });
        //         if (checkIfExists >= 0) {
        //             const cbmSum = confirmationGroups[checkIfExists].cbm + shipmentPo[i].cbm;
        //             confirmationGroups[checkIfExists] = {
        //                 ...confirmationGroups[checkIfExists],
        //                 type: cbmSum <= Math.ceil(lclThresholdPercentage * 28) ? 'LCL' : 'FCL',
        //                 poLines: confirmationGroups[checkIfExists].poLines + 1,
        //                 cbm: cbmSum,
        //                 weight: confirmationGroups[checkIfExists].weight + shipmentPo[i].weight,
        //                 splitLines: shipmentPo[i].splitLines,
        //             };
        //         } else {
        //             const data: any = {
        //                 confirmationGroupName: `Confirmation Group ${count}`,
        //                 type: shipmentPo[i].cbm <= Math.ceil(lclThresholdPercentage * 28) ? 'LCL' : 'FCL',
        //                 poLines: 1,
        //                 cbm: shipmentPo[i].cbm,
        //                 weight: shipmentPo[i].weight,
        //                 goodsReadyDate: shipmentPo[i].goodsReadyDate
        //                     ? moment(shipmentPo[i].goodsReadydate).format('DD-MM-YYYY')
        //                     : '',
        //                 destination: shipmentPo[i].destination,
        //                 splitLines: shipmentPo[i].splitLines,
        //             };
        //             confirmationGroups.push(data);
        //             count++;
        //         }
        //     }
        // }

        // return { poId: id || '', confirmationGroups: confirmationGroups };
        const confirmationGroups: ConfirmationGroups[] = [];
        const lclThresholdPercentage = lclThreshold / 100;
        let count = headerData?.totalConfirmationGroups ? headerData?.totalConfirmationGroups + 1 : 1;
        for (let i = 0; i < shipmentPo.length; i++) {
            const splitLines = shipmentPo[i].splitLines;
            for (let j = 0; j < splitLines.length; j++) {
                if (splitLines[j].splitLineStatus === 'Pending Confirmation' && !splitLines[j].confirmationGroup) {
                    const checkIfExists = confirmationGroups?.findIndex((o: any) => {
                        return (
                            o?.goodsReadyDate === moment(shipmentPo[i].goodsReadyDate).format('DD-MM-YYYY') &&
                            o?.destinationPortName.replaceAll(' ', '') ===
                                shipmentPo[i].destinationPortName?.replaceAll(' ', '')
                        );
                    });
                    if (checkIfExists >= 0) {
                        const cbmSum = confirmationGroups[checkIfExists].cbm + shipmentPo[i].cbm;
                        confirmationGroups[checkIfExists] = {
                            ...confirmationGroups[checkIfExists],
                            type: cbmSum <= lclThresholdPercentage * 28 ? 'LCL' : 'FCL',
                            poLines: confirmationGroups[checkIfExists].poLines + 1,
                            cbm: cbmSum,
                            weight: confirmationGroups[checkIfExists].weight + shipmentPo[i].weight,
                            subPoLinesId: [
                                ...confirmationGroups[checkIfExists].subPoLinesId,
                                splitLines[j].splitLineId,
                            ],
                        };
                    } else {
                        const data: ConfirmationGroups = {
                            confirmationGroupName: '',
                            type: shipmentPo[i].cbm <= lclThresholdPercentage * 28 ? 'LCL' : 'FCL',
                            poLines: 1,
                            cbm: shipmentPo[i].cbm,
                            weight: shipmentPo[i].weight,
                            goodsReadyDate: shipmentPo[i].goodsReadyDate
                                ? moment(shipmentPo[i].goodsReadyDate).format('DD-MM-YYYY')
                                : '',
                            destinationPortName: shipmentPo[i].destinationPortName,
                            destinationPort: shipmentPo[i].destinationPort,
                            createdUserId: userName,
                            createdUser: fullName,
                            subPoLinesId: [splitLines[j].splitLineId],
                        };
                        confirmationGroups.push(data);
                        count++;
                    }
                }
            }
        }

        return { poId: id || '', confirmationGroups };
    };

    const [skuDetail, setSkuDetail] = useState<any>({});
    const [_cbmForEach, setCbmForEach] = useState(0);
    const [modalViewCbm, setModalViewCbm] = useState(false);
    const [_weightForEach, setWeightForEach] = useState(0);
    const [_modalViewVendor, setModalViewVendor] = useState(false);

    const skuUpdate = (field: string) => {
        const skuDetail: any = sku?.find((res: any) => res.pm_PK === field);
        if (skuDetail) {
            setSkuDetail(skuDetail);
            // formik.setFieldValue('description', skuDetail.pm_description);
            const pmPack = skuDetail?.pm_packets?.find((res: any) => res.pp_quantity?.toLowerCase() === 'each');
            if (pmPack) {
                if (pmPack?.pp_cbm > 0) {
                    setCbmForEach(pmPack?.pp_cbm);
                    // const cbm = +pmPack?.pp_cbm * +formik.values.qty;
                    // formik.setFieldValue('cbm', parseFloat(cbm.toFixed(5)));
                }
                if (pmPack?.pp_weight > 0) {
                    setWeightForEach(pmPack?.pp_weight);
                    // const weight = +pmPack?.pp_weight * +formik.values.qty;
                    // formik.setFieldValue('weight', parseFloat(weight.toFixed(5)));
                }
            }
            if (skuDetail?.pm_type === 'Commodity') {
                // formik.setFieldValue('weight', 0);
            }
            // setIsEachPresent(skuDetail.isEachPresent);
            // setIsVendorLinked(skuDetail.pm_vendors.some((e: any) => e.vn_vendorcode === headerData?.vendorCode));
            if (skuDetail.pm_type !== 'Commodity' && !skuDetail.isEachPresent) {
                setModalViewCbm(true);
            } else if (!skuDetail.pm_vendors.some((e: any) => e.vn_vendorcode === headerData?.vendorCode)) {
                setModalViewVendor(true);
            }
        }
    };

    // const isAuPortInclude = headerData?.isMultiAddress && headerData?.includesAUPortDestination;
    // isAuPortInclude ? ports :

    const formatedPorts = ports?.filter((p: any) => p.country !== 'AU');
    useEffect(() => {
        setFormatedAUPorts(formatedPorts);
    }, [ports]);
    const [skuData, setSkuData] = useState<any>([]);
    const sku = useAppSelector(selectSku);

    useEffect(() => {
        if (headerData?.clientCode) {
            headerData?.clientCode && dispatch(fetchSkuList({ clientCode: headerData?.clientCode }));
            headerData?.clientCode && dispatch(fetchDestinationAddress({ orgCode: headerData?.clientCode }));
        }
    }, [headerData?.clientCode]);

    useEffect(() => {
        if (sku?.length) {
            const skuData1 = sku.map((res: any) => ({ value: res.pm_PK, label: res.pm_sku }));
            // value: res.pm_PK
            setSkuData(skuData1);
        }
    }, [sku]);

    useEffect(() => {
        const hsCode = sku ? sku.find((e: any) => e.pm_PK === formik?.values?.skuId)?.pm_purchasecode : '';
        formik.setFieldValue('hscode', hsCode ?? '');
    }, [formik?.values?.sku]);
    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={`${poName}`}
                        firstUrl={`${poUrl}`}
                        secondName="PO Details"
                        secondUrl={`/purchase_order/${id}/product_details_confirmation`}
                    />
                    <PurchaseOrderHeader id={id} />
                    <PODetailsHeaderTabs />
                </div>
                <div
                    onClick={() => {
                        setFilterDropdown(false);
                        setShowAddress(false);
                    }}
                    className="content-section main-wrapper ao-product-detail-confirmation-wrapper"
                >
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div className="shipping-wrapper">
                        <h4>Shipment Confirmation</h4>
                        <div className="shipping-btn-wrapper">
                            {/* display */}
                            {isAgent &&
                                headerData?.poStatus !== 'Cancelled' &&
                                headerData?.poStatus !== 'Pending_Reassignment' &&
                                headerData?.poStatus !== 'Booked' && (
                                    <button
                                        className="app-btn icon-button app-btn-secondary "
                                        onClick={() => {
                                            addLine();
                                        }}
                                        disabled={add || editMode}
                                    >
                                        <svg className="svg-icon plus-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Line</span>
                                    </button>
                                )}
                        </div>
                    </div>
                    <div className="grid-section ao-product-detail-conformation-grid">
                        <div id="table_div" className="grid-holder">
                            <div
                                onScroll={() => {
                                    resizeCheckboxFilter();
                                }}
                                onClick={(e) => {
                                    setToggleDropdown(false);
                                    setSearchName('');
                                    filterCheckbox(e);
                                    setFilterDropdown(false);
                                }}
                                className="grid-container"
                            >
                                {!shipmentList?.length && (
                                    <div className="no-data-content">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>

                                        <p className="no-content-message">
                                            No CBM lines added to confirm
                                            {/* {(() => {
                                                if (search.trim().length) {
                                                    return 'No PO lines were found';
                                                } else if (statusFilterSelected?.length || selectedFilter) {
                                                    return 'No PO lines were found for your filter';
                                                } else {
                                                    return 'No PO lines were found';
                                                }
                                            })()} */}
                                        </p>
                                    </div>
                                )}
                                <div className="grid-scroll-content">
                                    <form id="my-form" action="">
                                        <table className="grid-table shipment-grid">
                                            <thead>
                                                <tr className="grid-sticky-row">
                                                    <th className="grid-cell-header col-ao-cbm-cost">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">
                                                                CBM <span className="mandatory-field-text">*</span>
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-weight">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">
                                                                WEIGHT(Kg)
                                                                <span className="mandatory-field-text">*</span>
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-grd">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">
                                                                GOODS READY DATE
                                                                <span className="mandatory-field-text">*</span>
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-destination-port">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">
                                                                DESTINATION{' '}
                                                                <span className="mandatory-field-text">*</span>
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-dest">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">
                                                                DESTINATION Address{' '}
                                                                <span className="mandatory-field-text">*</span>
                                                            </span>
                                                        </div>
                                                    </th>
                                                    <th id="filter_div" className="grid-cell-header col-ao-status">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">STATUS</span>

                                                            {/* <div
                                                                className={
                                                                    toggleDropdown ||
                                                                    (statusFilterSelected.length &&
                                                                        statusFilterSelected[0] != null)
                                                                        ? 'filter-menu-wrap open'
                                                                        : 'filter-menu-wrap'
                                                                }
                                                            >
                                                                <button
                                                                    onClick={(e) => {
                                                                        filterByColumn(e);
                                                                        resizeCheckboxFilter();
                                                                        setSearchName('');
                                                                        filterCheckbox(e);
                                                                    }}
                                                                    type="button"
                                                                    className="app-btn filter-menu-button"
                                                                >
                                                                    <svg className="svg-icon filter-icon">
                                                                        <use xlinkHref="#filterIcon">
                                                                            <title>filter</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="sr-only">header menu</span>
                                                                </button>
                                                            </div> */}
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-sku">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">SKU</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-quanity">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">QUANTITY</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-hs-line">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">HS CODE</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header grid-sticky-column sticky-right-aligned col-ao-action">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">ACTION</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {!!shipmentList?.length &&
                                                    shipmentList?.map((sPo: any, index: number) => (
                                                        <tr className="no-border" key={index}>
                                                            <td className="grid-cell td-po-line">
                                                                <div className="grid-cell-data">
                                                                    {editMode &&
                                                                    (sPo.shipmentId === editItem ||
                                                                        (add && !sPo.shipmentId)) ? (
                                                                        <>
                                                                            <input
                                                                                placeholder=""
                                                                                type="text"
                                                                                className="search-input input-text"
                                                                                id="CBM"
                                                                                name="CBM"
                                                                                value={formik.values.cbm}
                                                                                onChange={(e) => {
                                                                                    const value = e.target.value
                                                                                        .replace(/[^.\d]/g, '')
                                                                                        .replace(
                                                                                            /(?:^(\d*\.?))|(\d*)\.?/g,
                                                                                            '$1$2'
                                                                                        );
                                                                                    if (
                                                                                        decimalNumberValidation(
                                                                                            parseFloat(value),
                                                                                            19,
                                                                                            5
                                                                                        )
                                                                                    ) {
                                                                                        formik.setFieldValue(
                                                                                            'cbm',
                                                                                            value
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {formik.errors.cbm ? (
                                                                                <div className="error-text">
                                                                                    {formik.errors.cbm}
                                                                                </div>
                                                                            ) : null}
                                                                        </>
                                                                    ) : (
                                                                        <>{sPo.cbm}</>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder td-sku">
                                                                <div className="grid-cell-data">
                                                                    {editMode &&
                                                                    (sPo.shipmentId === editItem ||
                                                                        (add && !sPo.shipmentId)) ? (
                                                                        <>
                                                                            <input
                                                                                placeholder=""
                                                                                type="text"
                                                                                className="search-input input-text"
                                                                                id="weight"
                                                                                name="weight"
                                                                                value={formik.values.weight}
                                                                                onChange={(e) => {
                                                                                    // formik.setFieldValue(
                                                                                    //     'weight',
                                                                                    //     e.target.value
                                                                                    //         .replace(/[^.\d]/g, '')
                                                                                    //         .replace(
                                                                                    //             /^(\d*\.?)|(\d*)\.?/g,
                                                                                    //             '$1$2'
                                                                                    //         )
                                                                                    // );
                                                                                    const value = e.target.value
                                                                                        .replace(/[^.\d]/g, '')
                                                                                        .replace(
                                                                                            /(?:^(\d*\.?))|(\d*)\.?/g,
                                                                                            '$1$2'
                                                                                        );
                                                                                    if (
                                                                                        decimalNumberValidation(
                                                                                            parseFloat(value),
                                                                                            19,
                                                                                            5
                                                                                        )
                                                                                    ) {
                                                                                        formik.setFieldValue(
                                                                                            'weight',
                                                                                            value
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            />
                                                                            {formik.errors.weight ? (
                                                                                <div className="error-text">
                                                                                    {formik.errors.weight}
                                                                                </div>
                                                                            ) : null}
                                                                        </>
                                                                    ) : (
                                                                        <>{sPo.weight}</>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                {editMode &&
                                                                (sPo.shipmentId === editItem ||
                                                                    (add && !sPo.shipmentId)) ? (
                                                                    <div
                                                                        id="goodsReadyDateDivShip"
                                                                        className="grid-cell-data date-picker-grid"
                                                                    >
                                                                        <DatePicker
                                                                            // minDate={new Date()}
                                                                            state={formik.values.goodsReadyDate}
                                                                            onChange={(e: any) => {
                                                                                formik.setFieldValue(
                                                                                    'goodsReadyDate',
                                                                                    e
                                                                                );
                                                                            }}
                                                                            fieldName={`confirmedGoodsdate_ship`}
                                                                            portalId="date-portal"
                                                                        />
                                                                        {formik.errors.goodsReadyDate ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.goodsReadyDate}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {moment(sPo.goodsReadyDate).format(
                                                                            'DD-MM-YYYY'
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td className="grid-cell search-entry-holder">
                                                                <div className="grid-cell-data">
                                                                    {editMode &&
                                                                    (sPo.shipmentId === editItem ||
                                                                        (add && !sPo.shipmentId)) ? (
                                                                        <>
                                                                            <Select
                                                                                options={formatedAUPorts}
                                                                                placeholder="Enter destination to select"
                                                                                onChange={(option: any) => {
                                                                                    // eslint-disable-next-line no-undefined
                                                                                    formik.setFieldError(
                                                                                        'destination',
                                                                                        undefined
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        'destination',
                                                                                        option?.label
                                                                                    );
                                                                                }}
                                                                                onInputChange={(text: string) => {
                                                                                    if (text.length > 2) {
                                                                                        setSearchTerm(text);
                                                                                    } else {
                                                                                        setFormatedAUPorts([]);
                                                                                        setSearchTerm('');
                                                                                    }
                                                                                }}
                                                                                isLoading={unlocoLoadingFn}
                                                                                noOptionsMessage={({ inputValue }) => {
                                                                                    if (inputValue?.length < 3) {
                                                                                        return 'Enter at least 3 characters to search';
                                                                                    } else if (!formatedPorts?.length) {
                                                                                        return 'No results found';
                                                                                    }
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                id={'destination'}
                                                                                name={'destination'}
                                                                                // defaultInputValue={
                                                                                //     singleProduct.destination ?? ''
                                                                                // }
                                                                                value={
                                                                                    formik.values.destination === ''
                                                                                        ? {
                                                                                              label: 'Enter destination to select',
                                                                                              value: null,
                                                                                          }
                                                                                        : formik.values.destination ===
                                                                                          sPo.destination
                                                                                        ? {
                                                                                              label: sPo.destination,
                                                                                              value: sPo.destinationPort,
                                                                                          }
                                                                                        : formatedPorts?.filter(
                                                                                              (option: any) => {
                                                                                                  if (
                                                                                                      option.label?.replaceAll(
                                                                                                          ' ',
                                                                                                          ''
                                                                                                      ) ===
                                                                                                      formik.values[
                                                                                                          'destination'
                                                                                                      ]?.replaceAll(
                                                                                                          ' ',
                                                                                                          ''
                                                                                                      )
                                                                                                  ) {
                                                                                                      return {
                                                                                                          label: option.label,
                                                                                                          value: option.value,
                                                                                                      };
                                                                                                  }
                                                                                              }
                                                                                          )
                                                                                }
                                                                                onFocus={() => {
                                                                                    setFormatedAUPorts([]);
                                                                                }}
                                                                                // value={
                                                                                //     ports?.find((option: any) => {
                                                                                //         if (
                                                                                //             option.label ===
                                                                                //             formik.values['destination']
                                                                                //         ) {
                                                                                //             return true;
                                                                                //         }
                                                                                //         return false;
                                                                                //     }) || null
                                                                                // }
                                                                                components={{
                                                                                    DropdownIndicator: () => null,
                                                                                    IndicatorSeparator: () => null,
                                                                                }}
                                                                                styles={customStyle}
                                                                            />
                                                                            {formik.errors.destination ? (
                                                                                <div className="error-text">
                                                                                    {formik.errors.destination}
                                                                                </div>
                                                                            ) : null}
                                                                        </>
                                                                    ) : (
                                                                        <>{sPo.destination}</>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder">
                                                                <div className="grid-cell-data">
                                                                    {editMode &&
                                                                    (sPo.shipmentId === editItem ||
                                                                        (add && !sPo.shipmentId)) ? (
                                                                        <div
                                                                            id={`address_div_ship`}
                                                                            className="grid-cell-data"
                                                                        >
                                                                            <div
                                                                                className={`dropdown-wrap  menu-down  ${
                                                                                    showAddress ? 'dropdown-open' : ''
                                                                                } modal-dropdown`}
                                                                            >
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        resizeFilter(
                                                                                            `address_div_ship`
                                                                                        );
                                                                                        e.stopPropagation();
                                                                                        setShowAddress((prev) => !prev);
                                                                                        // setCurrentIndex(product.indexValue);
                                                                                        // setCurrentSplitIndex(
                                                                                        //     splitLine.splitLineIndexValue
                                                                                        // );
                                                                                    }}
                                                                                    type="button"
                                                                                    className="menu-btn app-btn"
                                                                                >
                                                                                    {formik.values
                                                                                        .destinationAddress ? (
                                                                                        <span className="btn-text placeholder-text-address">
                                                                                            {
                                                                                                formik.values
                                                                                                    .destinationAddress
                                                                                            }
                                                                                        </span>
                                                                                    ) : (
                                                                                        <span className="btn-text btn-address">
                                                                                            Select address
                                                                                        </span>
                                                                                    )}

                                                                                    {/* <span className="placeholder-text">Select Address</span> */}
                                                                                    <span className="dropdown-arrow">
                                                                                        <svg className="svg-icon arrow-icon">
                                                                                            <use xlinkHref="#downArrow">
                                                                                                <title>dropdown</title>
                                                                                            </use>
                                                                                        </svg>
                                                                                    </span>
                                                                                </button>
                                                                            </div>
                                                                            {formik.errors.destinationAddress ? (
                                                                                <div className="error-text">
                                                                                    {formik.errors.destinationAddress}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    ) : (
                                                                        <>{sPo.destinationAddress}</>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder">
                                                                <div className="grid-cell-data">
                                                                    {/* {poStatusPillList(sPo.status || '-')} */}
                                                                    {poStatusPillList(
                                                                        sPo.splitLines?.[0]?.splitLineStatus || '-'
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {editMode &&
                                                                    (sPo.shipmentId === editItem ||
                                                                        (add && !sPo.shipmentId)) ? (
                                                                        <>
                                                                            <SearchableSelectBox
                                                                                data={skuData?.length ? skuData : []}
                                                                                placeholder="Enter SKU to select"
                                                                                formik={formik}
                                                                                fieldName="skuId"
                                                                                extraField="sku"
                                                                                customStyle={portalStyle}
                                                                                menuPosition="fixed"
                                                                                menuPortalTarget={document.getElementById(
                                                                                    'outside_div'
                                                                                )}
                                                                                closeMenuOnScroll={true}
                                                                                extraFieldFn={skuUpdate}
                                                                                NoMessageComponent={NoMessageComponent}
                                                                                menuPlacement={menuPlacement}
                                                                            />
                                                                            {formik.errors.sku ? (
                                                                                <div className="error-text">
                                                                                    {formik.errors.sku}
                                                                                </div>
                                                                            ) : null}
                                                                        </>
                                                                    ) : (
                                                                        <>{sPo.sku}</>
                                                                    )}
                                                                </div>
                                                            </td>

                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {editMode &&
                                                                    (sPo.shipmentId === editItem ||
                                                                        (add && !sPo.shipmentId)) ? (
                                                                        <>
                                                                            <input
                                                                                placeholder=""
                                                                                // type="number"
                                                                                className="search-input input-text"
                                                                                id="qty"
                                                                                value={formik.values.qty}
                                                                                onChange={(e) => {
                                                                                    // formik.setFieldValue(
                                                                                    //     'qty',
                                                                                    //     e.target.value
                                                                                    //         .replace(/[^.\d]/g, '')
                                                                                    //         .replace(
                                                                                    //             /^(\d*\.?)|(\d*)\.?/g,
                                                                                    //             '$1$2'
                                                                                    //         )
                                                                                    //         .replace(/^0+/, '')
                                                                                    // );
                                                                                    const value = e.target.value
                                                                                        .replace(/[^.\d]/g, '')
                                                                                        .replace(
                                                                                            /(?:^(\d*\.?))|(\d*)\.?/g,
                                                                                            '$1$2'
                                                                                        );
                                                                                    if (
                                                                                        decimalNumberValidation(
                                                                                            parseFloat(value),
                                                                                            19,
                                                                                            5
                                                                                        )
                                                                                    ) {
                                                                                        formik.setFieldValue(
                                                                                            'qty',
                                                                                            value
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                name="qty"
                                                                            />
                                                                            {formik.errors.qty ? (
                                                                                <div className="error-text">
                                                                                    {typeof formik.errors.qty ===
                                                                                        'string' && formik.errors.qty}
                                                                                </div>
                                                                            ) : null}
                                                                        </>
                                                                    ) : (
                                                                        <>{sPo.qty}</>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {editMode &&
                                                                    (sPo.shipmentId === editItem ||
                                                                        (add && !sPo.shipmentId)) ? (
                                                                        // <input
                                                                        //     placeholder=""
                                                                        //     type="text"
                                                                        //     className="search-input input-text"
                                                                        //     id="hs"
                                                                        //     name="hs"
                                                                        //     value={formik.values.hscode}
                                                                        //     onChange={(e) => {
                                                                        //         formik.setFieldValue(
                                                                        //             'hscode',
                                                                        //             e.target.value
                                                                        //         );
                                                                        //     }}
                                                                        // />
                                                                        <>{formik.values.hscode}</>
                                                                    ) : (
                                                                        <>{sPo.hsCode}</>
                                                                    )}
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell grid-sticky-column sticky-right-aligned">
                                                                <div className="grid-cell-data">
                                                                    {isAgent &&
                                                                        headerData?.poStatus !== 'Cancelled' &&
                                                                        headerData?.poStatus !==
                                                                            'Pending_Reassignment' &&
                                                                        headerData?.poStatus !== 'Booked' &&
                                                                        (sPo.splitLines?.[0]?.splitLineStatus ===
                                                                            'Pending Confirmation' ||
                                                                            !sPo.status) && (
                                                                            <button
                                                                                type="button"
                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                title={editMode ? 'Save' : 'Edit'}
                                                                                onClick={() => {
                                                                                    editMode &&
                                                                                        (sPo.shipmentId === editItem ||
                                                                                            (add && !sPo.shipmentId)) &&
                                                                                        formik.handleSubmit();
                                                                                    !(
                                                                                        editMode &&
                                                                                        (sPo.shipmentId === editItem ||
                                                                                            (add && !sPo.shipmentId))
                                                                                    ) &&
                                                                                        handleEdit(sPo.shipmentId, sPo);
                                                                                }}
                                                                            >
                                                                                {editMode &&
                                                                                (sPo.shipmentId === editItem ||
                                                                                    (add && !sPo.shipmentId)) ? (
                                                                                    <svg className="svg-icon save-icon">
                                                                                        <use href="#saveIcon">
                                                                                            <title>Save</title>
                                                                                        </use>
                                                                                    </svg>
                                                                                ) : (
                                                                                    <svg className="svg-icon edit-icon icon-20">
                                                                                        <use xlinkHref="#editIcon">
                                                                                            <title>Edit</title>
                                                                                        </use>
                                                                                    </svg>
                                                                                )}
                                                                            </button>
                                                                        )}
                                                                    {/* {sPo.splitLines?.[0]?.splitLineStatus} */}
                                                                    {isAgent &&
                                                                        headerData?.poStatus !== 'Cancelled' &&
                                                                        headerData?.poStatus !==
                                                                            'Pending_Reassignment' &&
                                                                        headerData?.poStatus !== 'Booked' &&
                                                                        (sPo.splitLines?.[0]?.splitLineStatus ===
                                                                            'Pending Confirmation' ||
                                                                            sPo.status === '') && (
                                                                            // sPo.splitLines?.[0]?.splitLineStatus !==
                                                                            //     'Confirmed For Booking' &&
                                                                            // sPo.splitLines?.[0]?.splitLineStatus !== 'LCL Under Approval'
                                                                            <button
                                                                                type="button"
                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                title="Delete"
                                                                                // {
                                                                                //     editItem === sPo.shipmentId
                                                                                //         ? 'Close'
                                                                                //         : 'Delete'
                                                                                // }
                                                                                onClick={() => {
                                                                                    // if (editItem === sPo.shipmentId) {
                                                                                    //     setEditItem(null);
                                                                                    //     setEditMode(false);
                                                                                    // } else {
                                                                                    handleDelete(sPo.shipmentId);
                                                                                    // }
                                                                                }}
                                                                            >
                                                                                {/* {editItem === sPo.shipmentId ? (
                                                                                    <svg className="svg-icon save-icon">
                                                                                        <use href="#circleCloseIcon">
                                                                                            <title>close</title>
                                                                                        </use>
                                                                                    </svg>
                                                                                ) : ( */}
                                                                                <svg className="svg-icon save-icon">
                                                                                    <use href="#deleteIcon">
                                                                                        <title>delete</title>
                                                                                    </use>
                                                                                </svg>
                                                                                {/* )} */}
                                                                            </button>
                                                                        )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                            {/* outside dropdown items , destination-adress-dropdown-wrap need to set top and left for this dynamically according to the component*/}
                            <div
                                className={
                                    showAddress
                                        ? 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                        : 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down modal-dropdown'
                                }
                                style={{
                                    top: `${distanceFromTopForAddress}px`,
                                    left: `${distanceFromLeftForAddress}px`,
                                    zIndex: 999,
                                    width: '345px',
                                }}
                            >
                                <div className="dropdown-menu client">
                                    {destinationAddress?.length === 0 && (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No matching Found</p>
                                        </div>
                                    )}
                                    <div className="customscrollbar">
                                        {destinationAddress?.length > 0 &&
                                            destinationAddress?.map((item: any, index: number) => (
                                                <a
                                                    onClick={() => {
                                                        formik.setFieldValue('destinationAddress', item.fullAddress);
                                                    }}
                                                    key={index}
                                                    className="menu-item app-btn"
                                                >
                                                    <span className="main-content">{item.fullAddress}</span>
                                                </a>
                                            ))}
                                    </div>
                                </div>
                            </div>

                            {toggleDropdown && (
                                <div className="search-filter-holder">
                                    <div
                                        style={{ left: String(setPosition) + 'px', top: '75px' }}
                                        className="filter-menu searchable-dropdown-common"
                                    >
                                        <div className="search-entry-holder">
                                            <input
                                                placeholder="Search"
                                                type="text"
                                                className="search-input input-text grid-search-field search-input-box"
                                                value={searchName}
                                                onChange={filterCheckbox}
                                            ></input>
                                            <svg className="svg-icon search-icon">
                                                <use xlinkHref="#searchIcon"></use>
                                            </svg>
                                            <button
                                                onClick={(e) => {
                                                    setSearchName('');
                                                    filterCheckbox(e);
                                                }}
                                                className="app-btn text-close-btn"
                                            >
                                                <svg className="svg-icon text-close-icon">
                                                    <use xlinkHref="#closeIcon"></use>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            {distinctValues &&
                                                distinctValues.map((value: string, index: number) => (
                                                    <div key={index} className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                onChange={(e: any) => {
                                                                    if (e.target.checked) {
                                                                        setStatusFilterSelected([value]);
                                                                    } else {
                                                                        setStatusFilterSelected([null]);
                                                                    }
                                                                    setToggleDropdown(false);
                                                                }}
                                                                // defaultChecked={false}
                                                                checked={statusFilterSelected.includes(value)}
                                                                type="checkbox"
                                                                className="checkbox-input"
                                                            />
                                                            <div className="checkmark">
                                                                <svg className="svg-icon tick-icon">
                                                                    <use xlinkHref="#tickIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">{value}</div>
                                                        </label>
                                                    </div>
                                                ))}
                                            {distinctValues?.length === 0 && (
                                                <div className="no-data-content">
                                                    <svg className="svg-icon grid-no-content-icon ">
                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                    </svg>
                                                    <p className="no-content-message">No Matches Found</p>
                                                </div>
                                            )}
                                        </div>
                                        {distinctValues?.length > 0 && (
                                            <div className="clear-btn">
                                                <button
                                                    onClick={() => setStatusFilterSelected([null])}
                                                    className="app-btn"
                                                >
                                                    <span className="button-text">Clear All</span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        {getAllPendingShipmentConfirmationLines()?.length > 0 &&
                            headerData?.poStatus !== 'Cancelled' &&
                            headerData?.poStatus !== 'Pending_Reassignment' &&
                            headerData?.poStatus !== 'Booked' && (
                                <span
                                    style={{ background: '#FBF3E7', color: '#F86A1B' }}
                                    className="btn-pills warning-pill"
                                >
                                    <span className="pill-text">
                                        {getAllPendingShipmentConfirmationLines()?.length} Pending Confirmation
                                    </span>
                                </span>
                            )}

                        {/* checkPermission('enrich', 'po') !== undefined &&
                            !checkPermission('enrich', 'po') && */}
                        {/* isAgent && */}
                        {isAgent && headerData?.poStatus !== 'Cancelled' && headerData?.poStatus !== 'Booked' && (
                            // headerData?.poStatus === 'Awaiting_Enrichment' &&
                            <button
                                disabled={!getAllPendingShipmentConfirmationLines()?.length || editMode}
                                onClick={() => {
                                    if (headerData?.shippingMode?.shippingModeCode === 'AIR') {
                                        setShowConfirmModal(true);
                                    } else {
                                        setModalViewConfirm(true);
                                    }
                                }}
                                className="app-btn app-btn-primary footer-btn "
                                title="Confirm PO for Booking Proposal"
                            >
                                <span className="button-text footer-button-text">
                                    Confirm Products for Booking Proposal
                                </span>
                            </button>
                        )}
                        <Link
                            to={`/purchase_order/${id}/product_details`}
                            className="app-btn app-btn-secondary footer-btn"
                            title="Previous"
                        >
                            <span className="button-text footer-button-text">Previous</span>
                        </Link>
                        {headerData?.poStatus?.toLowerCase() !== 'draft' &&
                            headerData?.shippingMode?.shippingModeCode !== 'AIR' &&
                            headerData?.confirmationGroupPresent && (
                                //Only show conatiner mode tab for PO in draft status and sea freight shipping mode
                                //(Air freight hasn't implemented the concept of LCL.So, hide container mode selection tab for air freight)
                                <PODetailsNextButton />
                            )}
                    </div>
                </div>
            </div>

            <CbmUnavailableModal
                sku={skuDetail?.pm_sku}
                display={modalViewCbm}
                displayFunction={setModalViewCbm}
                formik={formik}
                setModalViewSku={setModalViewSku}
            />
            <SkuUnavailableModal
                clientId={headerData?.clientId}
                skuUnavailableModalVisibility={modalViewSku}
                setSkuUnavailableModalVisibility={setModalViewSku}
            />
            {/* <ShipmentModal
                display={modalViewShort}
                displayFunction={setModalViewShort}
                shortShipmentList={shortShipmentList}
                setShortShipmentList={setShortShipmentList}
                poId={id}
            /> */}
            {/* Show confirm for booking modal for Po with shipping mode air freight */}
            <ConfirmBookingModal
                display={showConfirmModal}
                displayFunction={setShowConfirmModal}
                handleConfirm={handleConfirm}
            />
            {/* <ZeroQuantityModal
                zeroProductData={zeroProductData}
                setZeroProductData={setZeroProductData}
                product={productPo}
                display={modalViewZero}
                displayFunction={setModalViewZero}
            />
            <ModifyProductModal
                display={modalViewModifyProduct}
                displayFunction={setModalViewModifyProduct}
                splitLine={currentSplitLine}
                poId={id}
            /> */}
            <ConfirmShipmentModal
                modalViewConfirmShipment={modalViewConfirmShipment}
                setModalConfirmShipment={setModalConfirmShipment}
                product={productPo?.length ? productPo[currentIndex] : {}}
                currentIndex={currentIndex}
                currentSplitIndex={currentSplitIndex}
            />
            {modalViewConfirm && (
                <ConfirmProductsModal
                    display={modalViewConfirm}
                    displayFunction={setModalViewConfirm}
                    products={getAllConfirmationGroups()}
                    isLand={headerData?.shippingMode?.shippingModeCode === 'LND'}
                />
            )}
            <UnsavedChangeModal
                unsavedChangeModalVisibility={unsavedChangeModalVisibility}
                setUnsavedChangeModalVisibility={setUnsavedChangeModalVisibility}
                callBackFn={callBackFn}
            />
            <div style={{ zIndex: 9999 }} id="date-portal"></div>
        </>
    );
};
export default Index;
