import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { cixDownloadFile, fileEventHistory } from 'src/services/apis/documentApi';
import DeleteModal from './deleteConfirmModal';
import EventModal from './eventModal';
import FileUploadModal from './fileUploader';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { getAllClientList } from 'src/services/apis/clientListApi';

// import { updateHouseBills } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
// import coloader from 'src/store/reducers/profiles/coloader';

const Index: React.FC = () => {
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [doPk, setDoPk] = useState('');
    const { deleteResult } = useSelector((state: RootState) => state.document);
    const [eventHistory, setEventHistory] = useState([]);
    const [organisationData, setOrganisationData] = useState<any>([]);

    const downloadFile = (doPk: string, filename: string) => {
        cixDownloadFile(doPk, filename);
    };

    const getData = async () => {
        const clientData = await getAllClientList({});
        const modifiedData = clientData?.map((res: any) => ({
            label: res?.Or_OrgCode,
            value: res?.OR_PK,
        }));
        setOrganisationData(modifiedData);
    };

    useEffect(() => {
        getData();
    }, []);

    //@ts-ignore
    window.attachmentAction = (action: string, doPK?: string, filename?: string) => {
        if (action === 'DLD') {
            if (doPK && filename) downloadFile(doPK, filename);
        } else if (action === 'DEL') {
            if (doPK) {
                setDoPk(doPK);
                setShowDeleteModal(true);
            }
        } else if (action === 'EVT') {
            if (doPK) {
                fileEventHistory(doPK ?? '')
                    .then((res: any) => {
                        setEventHistory(res);
                        setShowEventModal(true);
                    })
                    .catch((err: any) => console.log(err));
            }
        } else if (action === 'NEW') {
            setShowUploadModal(true);
        }
    };

    return (
        <div className="main-wrapper container">
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper">
                <div className="grid-header">
                    <div className="content-heading-holder card-head-btn-edit-holder">
                        <span className="content-heading">
                            Attachments
                            <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                <span className="hub-tooltiptext" style={{ width: '400px' }}>
                                    Attachments will show documents uploaded against this booking, children or, against
                                    linked POs.
                                </span>
                                <Icon name="question circle outline" />
                            </span>
                        </span>
                    </div>
                </div>

                <LayoutWithColumns content={'shipment-invoice'} hideLoader style="fullpage" />
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder "></div>
            </div>

            {showUploadModal && (
                <FileUploadModal
                    setShowFileUploadModal={setShowUploadModal}
                    referenceType={'OR'}
                    organisationData={organisationData}
                />
            )}

            {showDeleteModal && (
                <DeleteModal setModalView={setShowDeleteModal} modalView={showDeleteModal} doPK={doPk} />
            )}

            {showEventModal && <EventModal setModalView={setShowEventModal} eventHistory={eventHistory} />}
        </div>
    );
};
export default Index;
