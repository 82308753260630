import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

import { TileItem } from '../components/Tiles/Tile';

//To return the tiles in the map
export const useGetTilesData = () => {
    //To get the count data in tiles, accumated sum of all the etdin7dys, etain7days or delayed count, in transit total bookings,
    // total bookings at destination port
    const { bookingsAtOriginCountry, bookingsAtOriginPort, bookingsIntransitArray, bookingsAtDestinationPort } =
        useSelector((state: RootState) => state.map);
    let etdDelayedCount = 0;
    let etaDelayedCount = 0;
    // const etdCount = [...bookingsAtOriginCountry, ...bookingsAtOriginPort].reduce((total, element) => {
    const etdCount = [...bookingsAtOriginPort].reduce((total, element) => {
        if (element.etdDelayedCount) {
            etdDelayedCount += element.etdDelayedCount;
        }
        return total + element.etdIn7DaysCount;
    }, 0);
    const etaCount = [...bookingsAtOriginCountry, ...bookingsAtOriginPort, ...bookingsIntransitArray].reduce(
        (total, element) => {
            if (element.etaDelayedCount) {
                etaDelayedCount += element.etaDelayedCount;
            }
            if (element.etaIn7DaysCount) {
                return total + element.etaIn7DaysCount;
            } else {
                return total + 0;
            }
        },
        0
    );
    const bookingInTransitCount = bookingsIntransitArray.reduce((total: any, element: any) => {
        return total + element.bookingCount;
    }, 0);

    const bookingsAtDestinationCount = bookingsAtDestinationPort.reduce((total: any, element: any) => {
        return total + element.bookingCount;
    }, 0);

    const tilesData: TileItem[] = [];
    if (etdCount || etdDelayedCount) {
        tilesData.push({
            tileKey: 'etd',
            title: 'ETD in 7 Days',
            count: etdCount,
            delayedCount: etdDelayedCount,
        });
    }
    if (bookingInTransitCount) {
        tilesData.push({
            tileKey: 'transit',
            title: 'In Transit',
            count: bookingInTransitCount,
        });
    }
    if (etaCount || etaDelayedCount) {
        tilesData.push({
            tileKey: 'eta',
            title: 'ETA in 7 Days',
            count: etaCount,
            delayedCount: etaDelayedCount,
        });
    }
    if (bookingsAtDestinationCount) {
        tilesData.push({
            tileKey: 'destination',
            title: 'At Destination',
            count: bookingsAtDestinationCount,
        });
    }

    return {
        tilesData,
    };
};
