import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    updateAgentBp,
    clearFailureBp,
    selectReassignBpError,
    selectReassignBpIsError,
    selectReassignBpLoading,
    selectReassignBpSuccess,
} from 'src/store/reducers/reassignAgent/bookingProposalReducer';
import {
    fetchAgentList,
    selectAgents,
    updateAgentPo,
    selectReassignPoLoading,
    selectReassignPoIsError,
    selectReassignPoError,
    selectReassignPoSuccess,
    clearFailurePo,
} from 'src/store/reducers/reassignAgent/purchaseOrderReducer';
import { error, Position, success } from 'src/utils/toast';
import '../../assets/scss/components/_layout.scss';
import '../../assets/scss/components/_modal.scss';
import './reassign-modal.scss';

type ModalProps = {
    type: string;
    data: any;
    setShowModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};
const Index: React.FC<ModalProps> = ({ type, data, setShowModal, setDisplayCancel }) => {
    const navigate = useNavigate();
    const { userName, roleName, fullName } = useUserProfile();
    const userDetails =
        localStorage?.getItem('wholePerms') != null && JSON.parse(localStorage?.getItem('wholePerms') || '');

    const dispatch = useAppDispatch();
    const [showDropDown, setShowDropDown] = useState(false);
    const [newAgent, setNewAgent] = useState({ agentName: '', agentCode: '' });
    const [reason, setReason] = useState('');

    const agents = useAppSelector(selectAgents);
    const isLoadingPo = useAppSelector(selectReassignPoLoading);
    const isErrorPo = useAppSelector(selectReassignPoIsError);
    const reassignPoSuccess = useAppSelector(selectReassignPoSuccess);
    const reassignPoError = useAppSelector(selectReassignPoError);

    const isLoadingBp = useAppSelector(selectReassignBpLoading);
    const isErrorBp = useAppSelector(selectReassignBpIsError);
    const reassignBpSuccess = useAppSelector(selectReassignBpSuccess);
    const reassignBpError = useAppSelector(selectReassignBpError);

    useEffect(() => {
        // if (agents?.length) {
        //     if (agents[0].agentPortDetail.location !== data[0].originPortCode) {
        //         dispatch(fetchAgentList({ portCode: data[0].originPortCode, currentAgent: data[0].agentName }));
        //     }
        // } else {
        dispatch(fetchAgentList({ portCode: data[0].originPortCode, currentAgent: data[0].agentName }));
        // }
    }, []);

    useEffect(() => {
        if (isErrorBp) {
            error(reassignBpError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailureBp([]));
        } else if (reassignBpSuccess?.status === 200 || reassignBpSuccess?.status === 201) {
            success(
                `Selected booking's have been reassigned to new agent :${reassignBpSuccess?.data?.agentName}`,
                Position.TOP_RIGHT
            );
            dispatch(clearFailureBp([]));
            setShowModal(false);
            setTimeout(() => {
                navigate('/booking_list');
            }, 1000);
        }
    }, [isErrorBp, reassignBpSuccess, reassignBpError]);

    useEffect(() => {
        if (isErrorPo) {
            error(reassignPoError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailurePo([]));
        } else if (reassignPoSuccess?.status === 200 || reassignPoSuccess?.status === 201) {
            success(
                `Selected PO's have been reassigned to new agent :${reassignPoSuccess?.data?.agentName}`,
                Position.TOP_RIGHT
            );
            dispatch(clearFailurePo([]));
            setShowModal(false);
            setTimeout(() => {
                navigate('/purchase_order');
            }, 1000);
        }
    }, [isErrorPo, reassignPoSuccess, reassignPoError]);

    const onSubmit = () => {
        const payload = {
            newAgentCode: newAgent.agentCode,
            newAgentName: newAgent.agentName,
            reason,
            roleName: userDetails?.accountManager ? 'Account Manager' : roleName,
            userName,
            fullName,
            clientCodes: data
                .map((res: any) => res.clientCode)
                .filter((clientCode: string, index: number, arr: any) => arr.indexOf(clientCode) == index),
        };
        if (type === 'po') {
            dispatch(
                updateAgentPo({
                    pos: data.map((res: any) => res.poId),
                    poNumbers: data.map((res: any) => res.poNumber),
                    ...payload,
                })
            );
        } else {
            dispatch(
                updateAgentBp({
                    bps: data.map((res: any) => res.bpId),
                    bpNumbers: data.map((res: any) => res.bpNumber),
                    ...payload,
                })
            );
        }
    };

    return (
        <div onClick={() => setShowDropDown(false)} className="app-modal">
            <div className="modal-content-holder reassign-modal medium-popup">
                <div className="modal-header">
                    <div className="title">Reassign Agent</div>
                    <button
                        onClick={() => (newAgent.agentCode || reason ? setDisplayCancel(true) : setShowModal(false))}
                        className="app-btn modal-close-btn"
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    {(isLoadingPo || isLoadingBp) && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div className="content-title">
                        <span className="highlight-content">{data?.length}</span>
                        {type === 'po'
                            ? `${data?.length === 1 ? 'PO' : 'POs'}  selected to reassign`
                            : `${data?.length === 1 ? 'Booking' : 'Bookings'}  selected to reassign`}
                    </div>
                    <div className="layout-holder three-column">
                        <div className="layout-item">
                            <h6 className="heading-type-1">Origin</h6>
                            <div className="description-type-1">{data[0].originPort}</div>
                        </div>
                        <div className="layout-item" style={{ padding: '0' }}>
                            <h6 className="heading-type-1">Current Agent</h6>
                            <div className="description-type-1">{data[0].agentName}</div>
                        </div>
                    </div>

                    <form id="my-form" action="" className="modal-form layout-holder one-column">
                        <div className=" modal-input-holder">
                            <label className="modal-input-label">
                                New Agent<span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-layout-item">
                                <div
                                    className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                        showDropDown ? 'dropdown-open' : ''
                                    }`}
                                >
                                    <button
                                        onClick={(e) => {
                                            setShowDropDown((prev) => !prev);
                                            e.stopPropagation();
                                        }}
                                        type="button"
                                        className="menu-btn app-btn select-modal"
                                    >
                                        {newAgent.agentCode ? (
                                            <span className="button-text selected-text">{newAgent.agentName}</span>
                                        ) : (
                                            <>
                                                <span className="placeholder-text">Select New Agent</span>
                                                <span className="button-text"></span>
                                            </>
                                        )}

                                        <span className="dropdown-arrow">
                                            <svg className="svg-icon arrow-icon">
                                                <use xlinkHref="#downArrow">
                                                    <title>dropdown</title>
                                                </use>
                                            </svg>
                                        </span>
                                    </button>

                                    <div className="dropdown-menu">
                                        <div className="customscrollbar" style={{ maxHeight: '150px' }}>
                                            {agents?.length === 0 && (
                                                <a style={{ textAlign: 'center' }} className="menu-item app-btn ">
                                                    <span className="main-content">No Agent found</span>
                                                </a>
                                            )}
                                            {agents?.map((res) => (
                                                <a
                                                    key={res.agentCode}
                                                    onClick={() =>
                                                        setNewAgent({
                                                            agentName: res.agentName,
                                                            agentCode: res.agentCode,
                                                        })
                                                    }
                                                    className={`menu-item app-btn ${
                                                        res.agentCode === newAgent.agentCode ? 'active' : ''
                                                    }`}
                                                >
                                                    <span className="main-content">{res.agentName}</span>
                                                </a>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                            <label htmlFor="name" className="modal-input-label">
                                Reason<span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-field-holder">
                                <textarea
                                    name="reason"
                                    className="input-text"
                                    placeholder="Enter reason for reassignment to a maximum of 250 characters"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    maxLength={250}
                                ></textarea>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => (newAgent.agentCode || reason ? setDisplayCancel(true) : setShowModal(false))}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        disabled={newAgent.agentCode && reason ? false : true}
                        onClick={() => onSubmit()}
                        className="app-btn app-btn-primary modal-btn footer-btn "
                    >
                        <span className="button-text vendor-button-text">Reassign Agent</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
