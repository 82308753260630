import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    getReceiptListData,
    receiptListResponse,
    getReceiptFilterValues,
    getReceiptStatusCount,
} from '../../../services/apis/receipts/receiptsListApi';
import {
    fetchReceiptData,
    fetchReceiptFilterData,
    isLoadingFn,
    fetchReceiptStatusCount,
} from '../../reducers/receipts/receiptListReducer';

function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const receipts: AxiosResponse<receiptListResponse> = yield call(getReceiptListData, payload);
        payload['receipts'] = receipts;
        yield put({ type: fetchReceiptData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getfilterData({ payload }: { payload: any; type: any }) {
    try {
        // yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const filterData: AxiosResponse<any[]> = yield call(getReceiptFilterValues, payload);
        payload['filterData'] = filterData;
        yield put({ type: fetchReceiptFilterData, payload: payload });
    } finally {
        // yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getStatusData({ payload }: { payload: any; type: any }) {
    try {
        // yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const count: AxiosResponse<any[]> = yield call(getReceiptStatusCount, payload);
        payload['count'] = count;
        yield put({ type: fetchReceiptStatusCount, payload: payload });
    } finally {
        // yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* receiptListSaga() {
    yield takeEvery('receiptList/fetchReceiptData', getListData);
    yield takeEvery('receiptList/fetchReceiptFilterData', getfilterData);
    yield takeEvery('receiptList/fetchReceiptStatusCount', getStatusData);
}

// Export the saga (data-saga)
export default receiptListSaga;
