import React, { useEffect, useRef, useState } from 'react';
import './po-basic-modal.scss';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CancelModal from './cancelModal';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { createPo, getAgent, saveAgentData } from '../../../store/reducers/purchase_order/basicDetails';
import { useDispatch } from 'react-redux';
import Select, { components } from 'react-select';
import { searchUnlocoList, clearUnlocoList } from '../../../store/reducers/createVendorReducer';
import { defaultPoDestinationAddress, reactSelectStyle } from '../../../utils/constants';
import { useUserProfile } from 'src/hooks/useUserProfile';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
import { useAppSelector } from 'src/hooks';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import { fetchWhWarehouse } from 'src/store/reducers/warehouseReducer';

interface BioProps {
    displayFn: any;
    display: boolean;
    edit: boolean;
    editData?: any;
    portType?: string;
    id?: string;
    setisEdit: any;
    addressVal: any;
}
interface requiredForm {
    poId: string;
    ordertype: string;
    shippingmode: string;
    fclservicetype: string;
    includesAUPortDestination: string;
    // freightterms: string;
    incoTerm: string;
    paymentterms: string;
    originPort: string;
    destinationPort: string;
    isMultiAddress: boolean;
    isAu: boolean;
    destinationAddress: string;
    destinationAddressId: string;
    auPort: string;
    auPortId: string;
    agentCode: string;
    agentName: string;
    releaseType: string;
}

//Release types for air freight
// const releaseTypes: { value: string; label: string }[] = [
//     {
//         value: 'Original',
//         label: 'Original',
//     },
//     {
//         value: 'Express',
//         label: 'Express',
//     },
//     {
//         value: 'Bank Release',
//         label: 'Bank Release',
//     },
// ];

// const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
const Index: React.FC<BioProps> = (props) => {
    const { data: poData, agents, getAgentLoading } = useSelector((state: RootState) => state.purchaseOrder) as any;
    const { unLocoList, isLoading } = useSelector((state: RootState) => state.vendorDetails);
    const { warehouses } = useSelector((state: RootState) => state.warehouseReducer);
    const [isChecked, setisChecked] = useState(false);
    const scrollElement = useRef<any>(null);
    const [cancelModal, setCancelModal] = useState(false);
    const [portCode, setportCode] = useState('');
    const dispatch = useDispatch();
    const [isFieldMandatory, setIsFieldMandatory] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [ports, setPorts] = useState<any>([]);
    const [unlocoLoading, setunlocoLoading] = useState(false);
    const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState('');
    const [touched, settouched] = useState(false);
    const headerData = useAppSelector(selectHeader);

    useEffect(() => {
        dispatch(fetchWhWarehouse({}));
    }, []);

    // const shippingMode = props.editData?.shippingmode?.shippingModeCode === 'AIR' ? 'air' : 'sea';
    const initialValues: requiredForm = {
        poId: props.id as any,
        ordertype:
            props.editData.ordertype?.orderTypeName || headerData?.shippingMode?.shippingModeCode === 'LND'
                ? 'Warehouse Order'
                : 'International PO',
        shippingmode: props.editData.shippingmode?.shippingModeName || 'Sea Freight',
        fclservicetype: props.editData.fclservicetype?.fclServiceTypeName || 'Direct',
        includesAUPortDestination: props.editData?.includesAUPortDestination ? 'Yes' : 'No',
        // freightterms: props.editData.freightterms?.freightTermsCode || '',
        incoTerm: props.editData.incoTerm?.incoTermCode || 'FOB',
        paymentterms: props.editData.paymentterms?.paymentTermsName || 'Collect',
        releaseType: props.editData.releaseType?.releaseTypeName || 'Original Bill Required at Destination',
        originPort:
            (props.editData.originPort !== '' &&
                props.editData.originPortName &&
                JSON.stringify({
                    value: props.editData.originPort,
                    label: `${props.editData.originPortName}(${props.editData.originPort})`,
                })) ||
            '',
        destinationPort:
            (!isChecked &&
                props.editData.destinationPortName &&
                JSON.stringify({
                    value: props.editData.destinationPort,
                    label: `${props.editData.destinationPortName}(${props.editData.destinationPort})`,
                })) ||
            '',
        isAu: (!isChecked && props.editData.destinationPort?.startsWith('AU')) ?? false,
        isMultiAddress: props.editData.isMultiAddress || false,
        destinationAddress: (!isChecked && props.editData.destinationAddress) || defaultPoDestinationAddress.label,
        destinationAddressId: (!isChecked && props.editData.destinationAddressId) || defaultPoDestinationAddress.value,
        auPort: '',
        auPortId: '',
        agentName: props.editData.agentName || '',
        agentCode: props.editData.agentCode || '',
    };

    const CommercialDetailFormSchema = Yup.object().shape({
        ordertype: Yup.string().required('Order type is required'),
        shippingmode: Yup.string().required('Shipping mode is required'),
        originPort: Yup.string().required('Origin is required').nullable(),
        // ...(shippingMode !== 'air' && { freightterms: Yup.string().required('Freight term is required') }),

        // fclservicetype: Yup.string().required('Service type is required'),
        // incoTerm: Yup.string().required('Incoterm is required'),
        // ...(shippingMode !== 'air' && { paymentterms: Yup.string().required('Payment term is required') }),
        // ...(shippingMode === 'air' && { releaseType: Yup.string().required('Release type  is required') }),
    });
    const AllValidationFormSchema = Yup.object().shape({
        ordertype: Yup.string().required('Order type is required'),
        shippingmode: Yup.string().required('Shipping mode is required'),
        originPort: Yup.string().required('Origin is required').nullable(),
        fclservicetype: Yup.string().required('Service type is required'),
        incoTerm: Yup.string().required('Incoterm is required'),
        releaseType: Yup.string().required('Release type is required'),
        paymentterms: Yup.string().required('Payment term is required'),
        destinationPort: Yup.string()
            .typeError('Destination is required')
            .when('includesAUPortDestination', {
                is: (val: string) => val?.toLowerCase() === 'no' || !val,
                then: Yup.string().required('Destination is required').nullable(),
            }),
        destinationAddressId: Yup.string().when('includesAUPortDestination', {
            is: (val: string) => val?.toLowerCase() === 'no' || !val,

            then: Yup.string().required('Destination Address is required').nullable(),
        }),
        // agentCode: Yup.string().required('Agent is required'),
    });
    const validationSchema = Yup.object().shape({
        destinationPort: Yup.string().required('Destnation port is required').nullable(),
        destinationAddressId: Yup.string().required('Destination Address is required').nullable(),
        //agents: Yup.string().required('Agent is required'),
    });
    const agentValidationSchema = Yup.object().shape({
        // agentCode: Yup.string().required('Agent is required'),
    }); /* eslint no-var: off */
    const { profileType } = useUserProfile();

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema:
            profileType === 'forwarder' || profileType === 'client'
                ? isFieldMandatory
                    ? AllValidationFormSchema
                    : !isChecked
                    ? CommercialDetailFormSchema.concat(validationSchema).concat(agentValidationSchema)
                    : CommercialDetailFormSchema.concat(agentValidationSchema)
                : !isChecked
                ? CommercialDetailFormSchema.concat(validationSchema)
                : CommercialDetailFormSchema,
        onSubmit: (values: any) => {
            var data = {};
            if (values.originPort.startsWith('{')) {
                const originData = JSON.parse(values.originPort).label.toString();
                const lastOpeningParenthesisIndex = originData.lastIndexOf('(');

                if (lastOpeningParenthesisIndex !== -1) {
                    values.originPortName = originData.substring(0, lastOpeningParenthesisIndex).trim();
                    values.originPort = originData
                        .substring(lastOpeningParenthesisIndex + 1, originData.length - 1)
                        .trim();
                }
            } else {
                values.originPortName = props.editData.originPortName;
                values.originPort = props.editData.originPort;
            }

            data = { ...values, isMultiAddress: isChecked, inboundPo: false, includesAUPortDestination: null };

            if (formik.values.originPort) {
                dispatch(createPo({ payload: data, id: props.id }));
            }
            formik.resetForm();
            props.displayFn(false);
        },
    });

    useEffect(() => {
        if (formik.values.destinationAddressId) {
            const address = formik.values.destinationAddressId;
            const selectedWarehouse = warehouses.find((w) => {
                return w.WH_AddressLine1 === address;
            });
            formik.setFieldValue('destinationPort', selectedWarehouse?.WH_ClosestPort);
        }
    }, [formik.values.destinationAddressId]);

    useEffect(() => {
        if (formik.values.destinationPort) {
            dispatch(saveAgentData([]));
            var code = formik.values.destinationPort;
            var mode = 'SEA';
            dispatch(getAgent({ code, mode }));
        }
    }, [formik.values.destinationPort]);
    useEffect(() => {
        let flag = false;
        if (!props?.editData?.agentName || touched) {
            agents?.forEach((x: any) => {
                if (x.extraField && !flag) {
                    flag = true;
                    formik.setFieldValue('agentName', x.label);
                    formik.setFieldValue('agentCode', x.value);
                }
            });
        }
    }, [agents]);

    useEffect(() => {
        setIsFieldMandatory(mandatoryFields);
    }, [isChecked, formik.values]);

    useEffect(() => {
        if (props.editData.isMultiAddress) {
            setisChecked(props.editData.isMultiAddress);
        }
    }, [props.editData.isMultiAddress]);
    const onConfirm = () => {
        props.displayFn(false);
        props.setisEdit(false);
        dispatch(saveAgentData([]));
    };
    const onCancel = () => {
        setCancelModal(false);
    };
    // useEffect(() => {
    //     setTimeout(() => {
    //         formik.validateForm();
    //     }, 100);
    // }, [formik.values]);

    useEffect(() => {
        if (isLoading && searchTerm?.length > 2) {
            setunlocoLoading(true);
        } else {
            setunlocoLoading(false);
        }
    }, [isLoading, searchTerm]);

    // const checkErrorStatus = () => {
    //     const formikshort = formik.values;
    //     if (
    //         formikshort.originPort &&
    //         formikshort.destinationPort &&
    //         formikshort.originPort === formikshort.destinationPort
    //     ) {
    //         return true;
    //     }

    //     if (!isChecked && !formikshort.destinationPort) {
    //         return true;
    //     }

    //     return formik.errors && formik.errors.constructor === Object && Object.keys(formik.errors).length > 0;
    // };
    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            dispatch(searchUnlocoList({ searchTerm, portType: props.portType, service: 'ord' }));
        } else {
            dispatch(clearUnlocoList());
        }
        setPorts([]);
    }, [searchTerm]);

    useEffect(() => {
        dispatch(clearUnlocoList());
    }, []);

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    portCountry: option?.portCountry ?? '',
                };
            });
            setPorts(portList);
        }
    }, [unLocoList, searchTerm]);

    useBrowserBackCheck(formik.dirty, () => setCancelModal(true));

    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">No agents assigned for the Origin Port</p>
            </div>
        );
    };

    const mandatoryFields = !isChecked
        ? formik.values.destinationPort
            ? formik.values.isAu
            : true
        : formik.values.includesAUPortDestination === 'Yes' ?? false;

    const scrollUp = () => {
        let targetDiv;
        if (scrollElement.current) {
            if (formik.errors.originPort || formik.errors.destinationPort || formik.errors.destinationAddressId) {
                targetDiv = document.getElementById('scroll1');
            } else if (
                formik.errors.fclservicetype ||
                formik.errors.incoTerm ||
                formik.errors.releaseType ||
                formik.errors.paymentterms
            ) {
                targetDiv = document.getElementById('scroll2');
            } else if (formik.errors.agentCode) {
                targetDiv = document.getElementById('scroll3');
            }
            if (targetDiv) {
                scrollElement.current.scrollTop = targetDiv.offsetTop;
            } else {
                scrollElement.current.scrollTop = 0;
            }
        }

        // scrollElement.current.scrollTop = 0;
    };

    useEffect(() => {
        if (!formik.isValid) {
            scrollUp();
        }
    }, [scroll, formik.isValid]);

    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };

    return (
        <div className="app-modal purchase-detail-modal">
            <div className="modal-content-holder medium-popup ">
                <div className="modal-header">
                    <div className="title">Required Details</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            !formik.dirty ? props.displayFn(false) : setCancelModal(true);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content" ref={scrollElement}>
                    <div className="modal-main-content">
                        <form
                            onSubmit={formik.handleSubmit}
                            className="modal-form layout-holder one-column"
                            id="basicForm"
                        >
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Order Type<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="BLP - Inbound Bulk PO"
                                        value={formik.values.ordertype}
                                        type="text"
                                        className="modal-input input-text read-only"
                                        id="ordertype"
                                        name="ordertype"
                                        readOnly
                                    ></input>
                                </div>
                            </div>
                            <div className=" modal-input-holder" id="scroll1">
                                <label className="modal-input-label">
                                    Shipping Mode<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="Land"
                                        value={formik.values.shippingmode}
                                        type="text"
                                        className="modal-input input-text read-only"
                                        id="shippingmode"
                                        name="shippingmode"
                                        readOnly
                                    ></input>
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Origin<span className="mandatory-field-text">*</span>
                                </label>
                                <Select
                                    options={ports}
                                    isClearable
                                    placeholder="Enter Origin to select"
                                    onChange={(option: any) => {
                                        // eslint-disable-next-line no-undefined
                                        formik.setFieldError('originPort', undefined);
                                        formik.setFieldValue(
                                            'originPort',
                                            option
                                                ? JSON.stringify({ value: option.value, label: option.label })
                                                : option
                                        );
                                        setportCode(option?.value);
                                    }}
                                    // onBlur={() => {
                                    //     formik.setFieldTouched('originPort');
                                    // }}
                                    onInputChange={(text: string) => {
                                        setCurrentSelectedDropdown('originPort');
                                        setSearchTerm(text);
                                        settouched(true);
                                    }}
                                    noOptionsMessage={({ inputValue }) =>
                                        inputValue?.length < 3
                                            ? 'Enter at least 3 characters to search'
                                            : 'No results found'
                                    }
                                    isLoading={unlocoLoading && currentSelectedDropdown === 'originPort'}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                    id={'originPort'}
                                    name={'originPort'}
                                    // defaultInputValue={initialValues.originPort}
                                    value={
                                        formik.values['originPort']?.startsWith('{') &&
                                        JSON.parse(formik.values['originPort'])
                                    }
                                    components={{
                                        DropdownIndicator: () => null,
                                        IndicatorSeparator: () => null,
                                        ClearIndicator,
                                    }}
                                    styles={reactSelectStyle}
                                />
                                {/* {formik.errors.originPort ? (
                                    <div className="error-text">{formik.errors.originPort}</div>
                                ) : null} */}
                                {formik.errors.originPort && typeof formik.errors.originPort === 'string' ? (
                                    <div className="error-text">{formik.errors.originPort}</div>
                                ) : null}
                                {/* {formik.values.originPort && formik.values.agentCode === '' && !getAgentLoading ? (
                                    <div className="error-text">No agent is assigned for the origin port</div>
                                ) : null} */}
                            </div>
                            <div className=" modal-input-holder" id="scroll2">
                                <label className="modal-input-label">
                                    Destination Address
                                    {!isChecked && <span className="mandatory-field-text">*</span>}
                                </label>
                                <div className="modal-input-layout-item auto-dp-height">
                                    {/* destination address dropdown menu items are outside this block, need to set its position dynamically */}
                                    <SearchableSelectBox
                                        data={warehouses?.map((w) => {
                                            return { label: w.WH_AddressLine1, value: w.WH_AddressLine1 };
                                        })}
                                        placeholder={isChecked ? 'Multi Destination' : 'Select Destination Address'}
                                        formik={formik}
                                        fieldName="destinationAddressId"
                                        extraField="destinationAddress"
                                        isDisabled={isChecked}
                                    />
                                    {/* {!isChecked && formik.errors.destinationAddressId ? (
                                        <div className="error-text">{formik.errors.destinationAddressId}</div>
                                    ) : null} */}
                                    {!isChecked &&
                                    formik.errors.destinationAddressId &&
                                    typeof formik.errors.destinationAddressId === 'string' ? (
                                        <div className="error-text">{formik.errors.destinationAddressId}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className=" modal-input-holder">
                                <label className="modal-input-label">
                                    Destination<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <input
                                        placeholder="BLP - Inbound Bulk PO"
                                        value={formik.values.destinationPort}
                                        type="text"
                                        className="modal-input input-text read-only"
                                        id="destinationPort"
                                        name="destinationPort"
                                        readOnly
                                    ></input>
                                </div>
                            </div>

                            {profileType === 'forwarder' && (
                                <div className=" modal-input-holder">
                                    <label className="modal-input-label">
                                        Agent<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <input
                                            placeholder="BLP - Inbound Bulk PO"
                                            value={formik.values.agentName}
                                            type="text"
                                            className="modal-input input-text read-only"
                                            id="agentCode"
                                            name="agentCode"
                                            readOnly
                                        ></input>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                        onClick={() => {
                            !formik.dirty ? props.displayFn(false) : setCancelModal(true);
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <form
                        id="basicForm"
                        onSubmit={formik.handleSubmit}
                        style={{ width: 'unset' }}
                        className="modal-form layout-holder one-column"
                    >
                        <button
                            className="app-btn app-btn-primary modal-btn footer-btn "
                            type="submit"
                            title="Save"
                            id="basicForm"
                            onClick={() => setScroll(!scroll)}
                            // disabled={
                            //     !props.edit ? checkValidation() : !formik.dirty || checkValidation()
                            // props.edit && !props.editData.isMultiAddress
                            // ? (props.editData.destinationAddressId === formik.values.destinationAddressId &&
                            //       !formik.dirty) ||
                            //   checkErrorStatus()
                            // : props.editData.destinationAddressId === formik.values.destinationAddressId ||
                            //   !formik.dirty ||
                            //   checkErrorStatus()
                            // }
                        >
                            <span className="button-text footer-button-text">Save</span>
                        </button>
                    </form>
                </div>
            </div>
            {cancelModal && <CancelModal onConfirm={onConfirm} onCancel={onCancel} />}
        </div>
    );
};
export default Index;
