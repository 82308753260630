/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react';
import '../../../Profiles/Clients/BasicDetails/basic-details.scss';
import '../../../Profiles/Clients/ProductMaster/product-master-edit.scss';
import './quote-item-details.scss';
import QuoteItemDropdownCard from './QuoteItemDropdownCard';
import QuoteItemSingleItemCard from './QuoteItemSingleItemCard';
import QuoteItemSingleDropdownCard from './QuoteItemSingleDropdownCard';
import QuoteItemPortCard from './QuoteItemPortCard';
import {
    getPortMatching,
    getQuoteUoms,
    getQuoteContainerMode,
    getQuoteTransportMode,
    getProviders,
    getQuoteSection,
    getDefaultCurrency,
    getRelativeTo,
    getQuoteDetails,
    saveQuoteDetails,
    getQuoteCodeExists,
} from 'src/services/apis/quoteAPi';
import { useParams } from 'react-router-dom';
import { success, error, Position } from 'src/utils/toast';
import QuoteItemMarkup from './QuoteItemMarkup';

const QuoteItemDetail: React.FC = () => {
    const { Id } = useParams();
    const quoteInitialData = {
        quoteSection: {},
        relativeTo: {},
        transportMode: [],
        containerMode: [],
        uom: [],
        currency: {},
        portMatching: [],
        providers: [],
    };
    const quoteLoadingState = {
        quoteSection: true,
        relativeTo: true,
        transportMode: true,
        containerMode: true,
        uom: true,
        currency: true,
        portMatching: true,
        providers: true,
    };

    const [quoteData, setQuoteData] = useState<any>(quoteInitialData);
    const [quoteLoading, setQuoteLoading] = useState<any>(quoteLoadingState);
    const [isMandatory, setIsMandatory] = useState(false);
    const [loading, setLoading] = useState(true);
    const [saveTriggered, setSaveTriggered] = useState(false);

    const [quoteSectionData, setQuoteSectionData] = useState<any>();
    const [relativeToData, setRelativeToData] = useState<any>();
    const [transportData, setTransportData] = useState<any>();
    const [containerData, setContainerData] = useState<any>();
    const [uomData, setUomData] = useState<any>();
    const [currencyData, setCurrencyData] = useState<any>();
    const [portData, setPortData] = useState<any>();
    const [providerData, setProviderData] = useState<any>();
    const [codeExists, setCodeExists] = useState(false);

    const checkInvalidPort = () => {
        let status = false;
        if (quoteData?.portMatching?.length === 0) {
            return false;
        }
        for (let index = 0; index < quoteData?.portMatching?.length; index++) {
            if (
                (quoteData?.portMatching[index]?.origin?.value === '' &&
                    quoteData?.portMatching[index]?.destination?.value === '') ||
                (quoteData?.portMatching[index]?.origin?.value && quoteData?.portMatching[index]?.destination?.value)
            ) {
                status = false;
            } else {
                status = true;
                return true;
            }
        }
        return status;
    };

    const getConvertedData = async (quoteDetailData: any, convertData: any, value: string) => {
        let data = [];
        if (value === 'Port') {
            const allPorts = quoteDetailData?.MT_Matchers?.filter((res: any) => res?.MT_Type === value);
            for (let index = 0; index < allPorts.length; index++) {
                const origin = allPorts[index]?.MR_MatcherRules?.find(
                    (val: any) => val?.MR_Field === 'QL_PortOfOrigin'
                );
                const destination = allPorts[index]?.MR_MatcherRules?.find(
                    (val: any) => val?.MR_Field === 'QL_PortOfDestination'
                );
                let foundOrigin = convertData?.find((res: any) => res?.value === origin?.MR_Value);
                let foundDestination = convertData?.find((res: any) => res?.value === destination?.MR_Value);
                if (!foundOrigin) {
                    const provider = await getPortMatching(origin?.MR_Value);
                    const filteredData = provider?.find((res: any) => res?.value === origin?.MR_Value);
                    foundOrigin = filteredData;
                }
                if (!foundDestination) {
                    const provider = await getPortMatching(destination?.MR_Value);
                    const filteredData = provider?.find((res: any) => res?.value === destination?.MR_Value);
                    foundDestination = filteredData;
                }
                data.push({ origin: foundOrigin, destination: foundDestination });
            }
            return data;
        } else if (value === 'uom') {
            let data = [];
            for (let index = 0; index < quoteDetailData.QU_QuoteUOMs?.length; index++) {
                const filtered = convertData?.find(
                    (res: any) => res?.value === quoteDetailData.QU_QuoteUOMs[index]?.QU_CD_Uom
                );
                if (filtered) {
                    data.push(filtered);
                }
            }
            return data.reverse();
        } else {
            const filtered = quoteDetailData?.MT_Matchers?.filter((res: any) => res?.MT_Type === value)?.sort(
                (a: any, b: any) => a.MT_Sequence - b.MT_Sequence
            );
            for (let index = 0; index < filtered?.length; index++) {
                const found = convertData?.find(
                    (res: any) => res?.value === filtered[index]?.MR_MatcherRules[0]?.MR_Value
                );
                const searchKey = filtered[index]?.MR_MatcherRules[0]?.MR_Value;
                if (!found && searchKey) {
                    const provider = await getProviders(searchKey);
                    const filteredData = provider?.find((res: any) => res?.value === searchKey);
                    data.push(filteredData);
                } else {
                    data.push(found);
                }
            }
            return data;
        }
    };

    const getQuoteItemData = async () => {
        const [quoteSection, relativeTo, transport] = await Promise.all([
            getQuoteSection(),
            getRelativeTo(),
            getQuoteTransportMode(),
        ]);
        // const quoteSection = await getQuoteSection();
        setQuoteLoading((prev: any) => ({ ...prev, quoteSection: false }));
        setQuoteSectionData(quoteSection);
        // const relativeTo = await getRelativeTo();
        setQuoteLoading((prev: any) => ({ ...prev, relativeTo: false }));
        setRelativeToData(relativeTo);
        // const transport = await getQuoteTransportMode();
        setQuoteLoading((prev: any) => ({ ...prev, transportMode: false }));
        setTransportData(transport);

        const [container, uom, currency] = await Promise.all([
            getQuoteContainerMode(),
            getQuoteUoms(),
            getDefaultCurrency(),
        ]);
        // const container = await getQuoteContainerMode();
        setQuoteLoading((prev: any) => ({ ...prev, containerMode: false }));
        setContainerData(container);
        // const uom = await getQuoteUoms();
        setQuoteLoading((prev: any) => ({ ...prev, uom: false }));
        setUomData(uom);
        // const currency = await getDefaultCurrency();
        setQuoteLoading((prev: any) => ({ ...prev, currency: false }));
        setCurrencyData(currency);

        const [port, provider] = await Promise.all([getPortMatching(), getProviders()]);
        // const port = await getPortMatching();
        setQuoteLoading((prev: any) => ({ ...prev, portMatching: false }));
        setPortData(port);
        // const provider = await getProviders();
        setQuoteLoading((prev: any) => ({ ...prev, providers: false }));
        setProviderData(provider);

        if (Id && Id !== 'add') {
            const quoteDetailData = await getQuoteDetails(Id);
            setIsMandatory(quoteDetailData?.QI_IsMandatory);
            setQuoteData({
                ...quoteData,
                itemCode: quoteDetailData?.QI_Code,
                itemDescription: quoteDetailData?.QI_Description,
                itemDefinition: quoteDetailData?.QI_LongDescription,
                minMarkup: {
                    value: quoteDetailData?.QI_MinimumMarkup,
                    percentage: quoteDetailData?.QI_MinimumMarkupPercent,
                },
                maxMarkup: {
                    value: quoteDetailData?.QI_MaximumMarkup,
                    percentage: quoteDetailData?.QI_MaximumMarkupPercent,
                },
                markupMore: quoteDetailData?.QI_MarkupMore,
                quoteSection: quoteSection?.find((res: any) => res?.extraValue === quoteDetailData?.QI_QS),
                relativeTo: relativeTo?.find((res: any) => res?.value === quoteDetailData?.QI_CD_RelativeTo),
                transportMode: await getConvertedData(quoteDetailData, transport, 'TransportMode'),
                containerMode: await getConvertedData(quoteDetailData, container, 'CON_MDE'),
                uom: await getConvertedData(quoteDetailData, uom, 'uom'),
                currency: currency?.find((res: any) => res?.value === quoteDetailData?.QI_CD_DefaultCurrency),
                portMatching: await getConvertedData(quoteDetailData, port, 'Port'),
                providers: await getConvertedData(quoteDetailData, provider, 'Organisation'),
            });
        }
        setLoading(false);
    };

    useEffect(() => {
        getQuoteItemData();
    }, []);

    const checkIfQuoteExists = async (code: string) => {
        try {
            const count = await getQuoteCodeExists(code);
            if ((Id === 'add' && count > 0) || count > 1) {
                setCodeExists(true);
                return true;
            } else {
                setCodeExists(false);
                return false;
            }
        } catch (error) {
            console.log(error, 'error');
        }
    };

    const buttonStatus = () => {
        return quoteData?.itemCode &&
            quoteData?.itemDescription &&
            quoteData?.itemDefinition &&
            quoteData?.quoteSection?.value &&
            typeof quoteData?.minMarkup?.value === 'number' &&
            typeof quoteData?.minMarkup?.percentage === 'number' &&
            typeof quoteData?.maxMarkup?.value === 'number' &&
            typeof quoteData?.maxMarkup?.percentage === 'number' &&
            quoteData?.minMarkup?.value >= 0 &&
            quoteData?.minMarkup?.percentage >= 0 &&
            quoteData?.maxMarkup?.value >= 0 &&
            quoteData?.maxMarkup?.percentage >= 0 &&
            quoteData?.uom?.length &&
            quoteData?.uom?.filter((res: any) => res?.value)?.length
            ? false
            : true;
    };

    const submitQuote = async () => {
        if (buttonStatus() || checkInvalidPort()) {
            setSaveTriggered(true);
            return false;
        }
        const status = await checkIfQuoteExists(quoteData?.itemCode);
        if (status) {
            return false;
        }
        setLoading(true);
        let payload: any = {
            QI_PK: Id === 'add' ? null : Id,
            QI_QS: quoteData?.quoteSection?.extraValue,
            QI_Code: quoteData?.itemCode,
            QI_Description: quoteData?.itemDescription,
            QI_LongDescription: quoteData?.itemDefinition,
            QI_CD_RelativeTo: quoteData?.relativeTo?.value,
            QI_CD_DefaultCurrency: quoteData?.currency?.value,
            QI_MinimumMarkup: quoteData?.minMarkup?.value,
            QI_MinimumMarkupPercent: quoteData?.minMarkup?.percentage,
            QI_MaximumMarkup: quoteData?.maxMarkup?.value,
            QI_MaximumMarkupPercent: quoteData?.maxMarkup?.percentage,
            QI_MarkupMore: quoteData?.markupMore,
            QI_IsMandatory: isMandatory,
            QU_QuoteUOMs: quoteData?.uom
                ?.filter((res: any) => res?.value !== '')
                ?.map((res: any) => ({ QU_CD_Uom: res?.value })),
            MT_Matchers: [],
        };

        // if (quoteData?.relativeTo?.value) {
        //     payload?.MT_Matchers?.push({
        //         MT_Type: 'RelativeTo',
        //         MR_MatcherRules: [
        //             {
        //                 MR_Field: 'QL_CD_RelativeTo',
        //                 MR_CD_Operand: 'eq',
        //                 MR_Value: quoteData?.relativeTo?.value,
        //                 MR_Sequence: 1,
        //             },
        //         ],
        //     });
        // }
        if (quoteData?.transportMode?.length) {
            for (let index = 0; index < quoteData?.transportMode.length; index++) {
                if (quoteData?.transportMode[index]?.value) {
                    payload?.MT_Matchers?.push({
                        MT_Type: 'TransportMode',
                        MT_Sequence: index + 1,
                        MR_MatcherRules: [
                            {
                                MR_Field: 'QL_CD_TransportMode',
                                MR_CD_Operand: 'eq',
                                MR_Value: quoteData?.transportMode[index]?.value,
                                MR_Sequence: index + 1,
                            },
                        ],
                    });
                }
            }
        }
        if (quoteData?.containerMode?.length) {
            for (let index = 0; index < quoteData?.containerMode.length; index++) {
                if (quoteData?.containerMode[index]?.value) {
                    payload?.MT_Matchers?.push({
                        MT_Type: 'CON_MDE',
                        MT_Sequence: index + 1,
                        MR_MatcherRules: [
                            {
                                MR_Field: 'CD_ContainerMode',
                                MR_CD_Operand: 'eq',
                                MR_Value: quoteData?.containerMode[index]?.value,
                                MR_Sequence: index + 1,
                            },
                        ],
                    });
                }
            }
        }
        // if (quoteData?.uom?.length) {
        //     payload?.MT_Matchers?.push({
        //         MT_Type: 'UOM',
        //         MR_MatcherRules: quoteData?.uom?.map((res: any, index: number) => ({
        //             MR_Field: 'CD_UOM',
        //             MR_CD_Operand: 'eq',
        //             MR_Value: res?.value,
        //             MR_Sequence: index + 1,
        //         })),
        //     });
        // }
        // if (quoteData?.currency?.value) {
        //     payload?.MT_Matchers?.push({
        //         MT_Type: 'Currency',
        //         MR_MatcherRules: [
        //             {
        //                 MR_Field: 'QL_CD_Currency',
        //                 MR_CD_Operand: 'eq',
        //                 MR_Value: quoteData?.currency?.value,
        //                 MR_Sequence: 1,
        //             },
        //         ],
        //     });
        // }
        if (quoteData?.portMatching?.length) {
            for (let index = 0; index < quoteData?.portMatching.length; index++) {
                if (quoteData?.portMatching[index]?.origin?.value) {
                    payload?.MT_Matchers?.push({
                        MT_Type: 'Port',
                        MT_SEQUENCE: index + 1,
                        MR_MatcherRules: [
                            {
                                MR_Field: 'QL_PortOfOrigin',
                                MR_CD_Operand: 'eq',
                                MR_Value: quoteData?.portMatching[index]?.origin?.value,
                                MR_Sequence: index + 1,
                            },
                            {
                                MR_Field: 'QL_PortOfDestination',
                                MR_CD_Operand: 'eq',
                                MR_Value: quoteData?.portMatching[index]?.destination?.value,
                                MR_Sequence: index + 1,
                            },
                        ],
                    });
                }
            }
        }
        if (quoteData?.providers?.length) {
            for (let index = 0; index < quoteData?.providers.length; index++) {
                if (quoteData?.providers[index]?.value) {
                    payload?.MT_Matchers?.push({
                        MT_Type: 'Organisation',
                        MT_Sequence: index + 1,
                        MR_MatcherRules: [
                            {
                                MR_Field: 'QL_OR_Provider',
                                MR_CD_Operand: 'eq',
                                MR_Value: quoteData?.providers[index]?.value,
                                MR_Sequence: index + 1,
                            },
                        ],
                    });
                }
            }
        }
        try {
            setSaveTriggered(false);
            const data = await saveQuoteDetails(payload);
            success(`Quote submitted successfully`, Position.TOP_RIGHT);
            setLoading(false);
            setTimeout(() => {
                if (Id === 'add') {
                    location.href = '/qms/config/quoteitem/' + data?.data?.[0]?.QI_PK;
                }
            }, 2000);
        } catch (err: any) {
            setLoading(false);
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    return (
        <div className="main-wrapper quote-item-detail">
            <div className={`app-loader ${loading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Quote Item Detail</h2>
                <div className={`content-section main-wrapper basic-detail-wrapper`}>
                    <div className="card details-box custom">
                        <div className="row-1">
                            <QuoteItemSingleItemCard
                                type={'quote'}
                                title={'Item'}
                                keyName={'item'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                saveTriggered={saveTriggered}
                                codeExists={codeExists}
                                checkIfQuoteExists={checkIfQuoteExists}
                            />
                        </div>
                        <div className="row-2">
                            <QuoteItemSingleDropdownCard
                                title={'Quote Section'}
                                keyName={'quoteSection'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={quoteSectionData}
                                loading={quoteLoading}
                                saveTriggered={saveTriggered}
                            />
                            <QuoteItemSingleDropdownCard
                                title={'Relative To'}
                                keyName={'relativeTo'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={relativeToData}
                                loading={quoteLoading}
                            />
                            <QuoteItemSingleDropdownCard
                                title={'Default Currency'}
                                keyName={'currency'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={currencyData}
                                loading={quoteLoading}
                            />
                        </div>
                        {/* <div className="row-3"> */}
                        <QuoteItemMarkup
                            title={'Markup Rules'}
                            keyName={'markup'}
                            quoteData={quoteData}
                            setQuoteData={setQuoteData}
                            saveTriggered={saveTriggered}
                        />
                        {/* </div> */}
                        <div className="row-4">
                            <QuoteItemDropdownCard
                                title={'Transport Mode(s)'}
                                keyName={'transportMode'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={transportData}
                                loading={quoteLoading}
                                isRequired={false}
                            />

                            <QuoteItemDropdownCard
                                title={'Container Mode'}
                                keyName={'containerMode'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={containerData}
                                loading={quoteLoading}
                                isRequired={false}
                            />
                        </div>
                        <div className="row-5">
                            <QuoteItemDropdownCard
                                title={'UOMs'}
                                keyName={'uom'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={uomData}
                                loading={quoteLoading}
                                saveTriggered={saveTriggered}
                                isRequired={true}
                            />
                            <QuoteItemDropdownCard
                                title={'Providers'}
                                keyName={'providers'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={providerData}
                                loading={quoteLoading}
                                isRequired={false}
                            />
                        </div>
                        <div className="row-6">
                            <QuoteItemPortCard
                                title={'Port Matching'}
                                keyName={'portMatching'}
                                quoteData={quoteData}
                                setQuoteData={setQuoteData}
                                quoteItemData={portData}
                                setQuoteItemData={setPortData}
                                loading={quoteLoading}
                                saveTriggered={saveTriggered}
                                checkInvalidPort={checkInvalidPort}
                                isRequired={false}
                            />
                        </div>
                        <div className="checkbox-wrapper-modal mandatory-checkbox modal-input-holder">
                            <div className="checkbox-item">
                                <label className="app-check-wrapper">
                                    <input
                                        type="checkbox"
                                        className="checkbox-input"
                                        name="isMandatory"
                                        checked={isMandatory}
                                        onChange={(e) => setIsMandatory(e.target.checked)}
                                    ></input>
                                    <div className="checkmark">
                                        <svg className="svg-icon notification-circle-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div style={{ marginTop: '2px' }} className="checkbox-label">
                                        Is Mandatory
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div className="quote-footer-btn">
                            <button
                                onClick={() => (location.href = '/qms/config/quoteitem')}
                                className="app-btn app-btn-secondary footer-btn"
                                title="Back"
                                type="button"
                            >
                                <span className="button-text footer-button-text">Back</span>
                            </button>
                            <button
                                onClick={() => submitQuote()}
                                disabled={loading}
                                className="app-btn app-btn-primary footer-btn"
                                title={loading ? 'Submitting..' : 'Submit'}
                                type="button"
                            >
                                <span className="button-text footer-button-text">
                                    {loading ? 'Submitting..' : 'Submit'}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuoteItemDetail;
