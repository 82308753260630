/* eslint-disable no-undefined */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers';
import {
    FilterDestinationTypeList,
    // FilterList,
    FilterModeList,
    Type,
} from 'src/pages/Dashboard/InsightsExpanded/enums';
import { useSelector, useDispatch } from 'react-redux';
import BSTooltip from 'src/components/common/BSTooltip';
import LineGraph from 'src/components/common/LineGraph';
import { makeApiCall } from 'src/pages/Dashboard/Insights/makeApiCall';
import {
    setPoFilter as setFilter,
    setPoSelectedDropDown as setSelectedDropDown,
    setInboundPoSelectedDropDown,
    setInboundPoFilter,
} from 'src/store/reducers/purchaseOrder/poListReducer';
import {
    fetchFavourites,
    resetAddSuccessFlag,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/dashboard/insightsFavoritesReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { addOrRemoveFromFavs, checkIfAddedToFavs, typeOfFav } from '../../Insights/common';
import { setInsightsMainPage, setInsightsSelectedType } from 'src/store/reducers/dashboard/insightsReducer';
import {
    setFilter as setAoFilter,
    setSelectedDropDown as setSelectedAoDropdown,
} from 'src/store/reducers/allocationOrder/allocationOrderListReducer';
import { clearDashboardInsightsFilters } from 'src/pages/Dashboard/Insights/clearDashboardInsightsFilters';

//Faheem
const Index = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [_typeState, setTypeState] = React.useState('');

    //========================= Redux values =======================================//
    const { dashboardActiveOrders, insightsMode, insightsDestination, insightsDurationRange } = useSelector(
        (state: RootState) => state.dashboardInsights
    );
    const { userName, profileType } = useUserProfile();
    const { favourites, isAddSuccess, isRemoveSuccess, isLoading } = useSelector(
        (state: RootState) => state.favourites
    );
    const currentFilters = {
        isSea: insightsMode?.includes(FilterModeList.Sea) ? true : false,
        isAir: insightsMode?.includes(FilterModeList.Air) ? true : false,
        isLand: insightsMode?.includes(FilterModeList.Land) ? true : false,
        isInternational: insightsDestination?.includes(FilterDestinationTypeList.International) ? true : false,
        isTriangle: insightsDestination?.includes(FilterDestinationTypeList.Triangle) ? true : false,
    } as any;
    const { filter: aoFilter } = useSelector((state: RootState) => state.allocationOrderList);
    const { poFilter: filter, inboundPoFilter } = useSelector((state: RootState) => state.purchaseOrderList);
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);
    const dashboardActiveOrdersRedux = React.useMemo(() => {
        if (dashboardActiveOrders) {
            return dashboardActiveOrders;
        }
    }, [dashboardActiveOrders]);

    //=========================================================================//

    const plotGraphPoints = (type: string) => [
        [
            dashboardActiveOrdersRedux[type]?.key1?.pointName,
            dashboardActiveOrdersRedux[type]?.key1?.count,
            dashboardActiveOrdersRedux[type]?.key1?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key1?.dateTo,
            dashboardActiveOrdersRedux[type]?.key1?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key2?.pointName,
            dashboardActiveOrdersRedux[type]?.key2?.count,
            dashboardActiveOrdersRedux[type]?.key2?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key2?.dateTo,
            dashboardActiveOrdersRedux[type]?.key2?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key3?.pointName,
            dashboardActiveOrdersRedux[type].key3?.count,
            dashboardActiveOrdersRedux[type]?.key3?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key3?.dateTo,
            dashboardActiveOrdersRedux[type]?.key3?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key4?.pointName,
            dashboardActiveOrdersRedux[type].key4?.count,
            dashboardActiveOrdersRedux[type]?.key4?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key4?.dateTo,
            dashboardActiveOrdersRedux[type]?.key4?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key5?.pointName,
            dashboardActiveOrdersRedux[type].key5?.count,
            dashboardActiveOrdersRedux[type]?.key5?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key5?.dateTo,
            dashboardActiveOrdersRedux[type]?.key5?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key6?.pointName,
            dashboardActiveOrdersRedux[type].key6?.count,
            dashboardActiveOrdersRedux[type]?.key6?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key6?.dateTo,
            dashboardActiveOrdersRedux[type]?.key6?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key7?.pointName,
            dashboardActiveOrdersRedux[type].key7?.count,
            dashboardActiveOrdersRedux[type]?.key7?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key7?.dateTo,
            dashboardActiveOrdersRedux[type]?.key7?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key8?.pointName,
            dashboardActiveOrdersRedux[type].key8?.count,
            dashboardActiveOrdersRedux[type]?.key8?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key8?.dateTo,
            dashboardActiveOrdersRedux[type]?.key8?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key9?.pointName,
            dashboardActiveOrdersRedux[type].key9?.count,
            dashboardActiveOrdersRedux[type]?.key9?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key9?.dateTo,
            dashboardActiveOrdersRedux[type]?.key9?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key10?.pointName,
            dashboardActiveOrdersRedux[type].key10?.count,
            dashboardActiveOrdersRedux[type]?.key10?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key10?.dateTo,
            dashboardActiveOrdersRedux[type]?.key10?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key11?.pointName,
            dashboardActiveOrdersRedux[type].key11?.count,
            dashboardActiveOrdersRedux[type]?.key11?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key11?.dateTo,
            dashboardActiveOrdersRedux[type]?.key11?.pointName,
        ],
        [
            dashboardActiveOrdersRedux[type]?.key12?.pointName,
            dashboardActiveOrdersRedux[type].key12?.count,
            dashboardActiveOrdersRedux[type]?.key12?.dateFrom,
            dashboardActiveOrdersRedux[type]?.key12?.dateTo,
            dashboardActiveOrdersRedux[type]?.key12?.pointName,
        ],
    ];

    const handleClick = (type: string) => {
        dispatch(setInsightsMainPage('Active Order Details'));
        setTypeState(type);
        dispatch(setInsightsSelectedType(type));
        makeApiCall(
            dispatch,
            insightsMode,
            insightsDurationRange,
            insightsDestination,
            'activeOrdersDetails',
            type,
            '',
            selectedSearchItem,
            selectedSearchItem?.type
        );
    };

    useEffect(() => {
        if (isAddSuccess) {
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetAddSuccessFlag({}));
        }
    }, [isAddSuccess]);

    useEffect(() => {
        if (isRemoveSuccess) {
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag({}));
        }
    }, [isRemoveSuccess]);

    const addOrRemoveFromFavourites = (title: string, type: string) => {
        if (type === Type.itpo) {
            currentFilters.isItpo = true;
        } else if (type === Type.ibpo) {
            currentFilters.isIbpo = true;
        } else if (type === Type.ao) {
            currentFilters.isAo = true;
        }
        addOrRemoveFromFavs(dispatch, title, favourites, currentFilters, userName, typeOfFav.activeOrder);
    };

    const checkIfAddedToFavourites = (title: string) => {
        return checkIfAddedToFavs(title, favourites, currentFilters, typeOfFav.activeOrder);
    };

    return (
        <>
            <div className="category-tile-wrapper">
                {insightsMode?.includes(FilterModeList.Land) ||
                dashboardActiveOrdersRedux['international']?.count < 1 ? (
                    <></>
                ) : (
                    <div className="category-tile-card">
                        <div className="category-nav">
                            <span className="category-nav-title">International POs</span>
                            <div className="category-nav-flex">
                                <span className="category-nav-count">
                                    {dashboardActiveOrdersRedux['international']?.count || 0}
                                </span>
                                <div className="graph-holder">
                                    <LineGraph
                                        drawn={
                                            dashboardActiveOrdersRedux['international']
                                                ? plotGraphPoints('international')
                                                : []
                                        }
                                        name="purchaseOrdersList"
                                        tooltip={true}
                                        lineDots={true}
                                        gradientFill={false}
                                        lineWidth={2}
                                        pointSize={2}
                                        tooltipTitle={'Active Orders'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="graph-filters">
                            <BSTooltip
                                id="favourites"
                                title={
                                    checkIfAddedToFavourites('International POs')
                                        ? 'Remove from Favourites'
                                        : 'Add to Favourites'
                                }
                                position="bottom"
                            >
                                {/* Replace the class name "unselected-fav-icon" to "selected-fav-icon" on click */}
                                <button
                                    className={`action-btn app-btn icon-only sm fav-icon-holder--star ${
                                        checkIfAddedToFavourites('International POs')
                                            ? 'selected-fav-icon'
                                            : 'unselected-fav-icon'
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addOrRemoveFromFavourites('International POs', Type.itpo);
                                    }}
                                    disabled={isLoading}
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Favourites</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="reports" title="Go to Reports" position="bottom">
                                <button className="action-btn app-btn icon-only sm app-btn-secondary">
                                    <svg className="svg-icon">
                                        <use xlinkHref="#fileIcon">
                                            <title>Go to Reports</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="redirect" title="Go to Orders Page" position="bottom">
                                <button
                                    className="action-btn app-btn icon-only sm app-btn-secondary"
                                    onClick={() => {
                                        dispatch(
                                            setFilter({
                                                ...filter, // eslint-disable-next-line no-undefined
                                                shippingmode:
                                                    insightsMode?.length > 0
                                                        ? insightsMode?.map((val: string) => {
                                                              if (val === 'sea') {
                                                                  return 'Sea';
                                                              }
                                                              if (val === 'air') {
                                                                  return 'Air';
                                                              }
                                                          })
                                                        : // eslint-disable-next-line no-undefined
                                                          undefined,
                                                destinationType:
                                                    insightsDestination &&
                                                    insightsDestination.length &&
                                                    insightsDestination.length > 0
                                                        ? insightsDestination
                                                        : undefined,
                                                poStatus: 'Active',
                                                poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                                                clientName:
                                                    selectedSearchItem?.type === 'Clients'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                                agentName:
                                                    selectedSearchItem?.type === 'Agents'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                                vendorName:
                                                    selectedSearchItem?.type === 'Vendors'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                            })
                                        );
                                        dispatch(
                                            setSelectedDropDown({
                                                text: 'Active POs',
                                                count: dashboardActiveOrdersRedux['international']?.count || 0,
                                            })
                                        );
                                        navigate('/purchase_order', { state: { reload: true } });
                                        clearDashboardInsightsFilters(dispatch);
                                    }}
                                >
                                    <svg className="svg-icon">
                                        <use xlinkHref="#redirectIcon">
                                            <title>Redirect</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        </div>
                        <a className="tile-anchor" onClick={() => handleClick(Type.itpo)}>
                            &nbsp;
                        </a>
                    </div>
                )}
                {insightsMode?.includes(FilterModeList.Sea) ||
                insightsMode?.includes(FilterModeList.Air) ||
                insightsDestination?.includes(FilterDestinationTypeList.International) ||
                insightsDestination?.includes(FilterDestinationTypeList.Triangle) ||
                dashboardActiveOrdersRedux['inbound']?.count < 1 ? (
                    <></>
                ) : (
                    <div className="category-tile-card">
                        <div className="category-nav">
                            <span className="category-nav-title">Inbound POs</span>
                            <div className="category-nav-flex">
                                <span className="category-nav-count">
                                    {dashboardActiveOrdersRedux['inbound']?.count || 0}
                                </span>
                                <div className="graph-holder">
                                    <LineGraph
                                        drawn={dashboardActiveOrdersRedux['inbound'] ? plotGraphPoints('inbound') : []}
                                        name="inboundPOs"
                                        tooltip={true}
                                        lineDots={true}
                                        gradientFill={false}
                                        lineWidth={2}
                                        pointSize={2}
                                        tooltipTitle={'Active Orders'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="graph-filters">
                            <BSTooltip
                                id="favourites1"
                                title={
                                    checkIfAddedToFavourites('Inbound POs')
                                        ? 'Remove from Favourites'
                                        : 'Add to Favourites'
                                }
                                position="bottom"
                            >
                                {/* Replace the class name "unselected-fav-icon" to "selected-fav-icon" on click */}
                                <button
                                    className={`action-btn app-btn icon-only sm fav-icon-holder--star ${
                                        checkIfAddedToFavourites('Inbound POs')
                                            ? 'selected-fav-icon'
                                            : 'unselected-fav-icon'
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addOrRemoveFromFavourites('Inbound POs', Type.ibpo);
                                    }}
                                    disabled={isLoading}
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Favourites</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="reports1" title="Go to Reports" position="bottom">
                                <button className="action-btn app-btn icon-only sm app-btn-secondary">
                                    <svg className="svg-icon">
                                        <use xlinkHref="#fileIcon">
                                            <title>Go to Reports</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="redirect1" title="Go to Orders Page" position="bottom">
                                <button
                                    className="action-btn app-btn icon-only sm app-btn-secondary"
                                    onClick={() => {
                                        dispatch(
                                            setInboundPoFilter({
                                                ...inboundPoFilter,
                                                poStatus: 'Active',
                                                poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                                                clientName:
                                                    selectedSearchItem?.type === 'Clients'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                                agentName:
                                                    selectedSearchItem?.type === 'Agents'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                                vendorName:
                                                    selectedSearchItem?.type === 'Vendors'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                            })
                                        );
                                        dispatch(
                                            setInboundPoSelectedDropDown({
                                                text: 'Active Inbound POs',
                                                count: dashboardActiveOrdersRedux['inbound']?.count || 0,
                                            })
                                        );
                                        navigate('/inbound', { state: { reload: true } });
                                        clearDashboardInsightsFilters(dispatch);
                                    }}
                                >
                                    <svg className="svg-icon">
                                        <use xlinkHref="#redirectIcon">
                                            <title>Redirect</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        </div>
                        <a className="tile-anchor" onClick={() => handleClick(Type.ibpo)}>
                            &nbsp;
                        </a>
                    </div>
                )}
                {insightsMode?.includes(FilterModeList.Air) ||
                insightsMode?.includes(FilterModeList.Land) ||
                dashboardActiveOrdersRedux['allocationOrders']?.count < 1 ? (
                    <></>
                ) : (
                    <div className="category-tile-card">
                        <div className="category-nav">
                            <span className="category-nav-title">Allocation Orders</span>
                            <div className="category-nav-flex">
                                <span className="category-nav-count">
                                    {dashboardActiveOrdersRedux['allocationOrders']?.count || 0}
                                </span>
                                <div className="graph-holder">
                                    <LineGraph
                                        drawn={
                                            dashboardActiveOrdersRedux['allocationOrders']
                                                ? plotGraphPoints('allocationOrders')
                                                : []
                                        }
                                        name="allocationOrders"
                                        tooltip={true}
                                        lineDots={true}
                                        gradientFill={false}
                                        lineWidth={2}
                                        pointSize={2}
                                        tooltipTitle={'Active Orders'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="graph-filters">
                            <BSTooltip
                                id="favourites2"
                                title={
                                    checkIfAddedToFavourites('Allocation Orders')
                                        ? 'Remove from Favourites'
                                        : 'Add to Favourites'
                                }
                                position="bottom"
                            >
                                {/* Replace the class name "unselected-fav-icon" to "selected-fav-icon" on click */}
                                <button
                                    className={`action-btn app-btn icon-only sm fav-icon-holder--star ${
                                        checkIfAddedToFavourites('Allocation Orders')
                                            ? 'selected-fav-icon'
                                            : 'unselected-fav-icon'
                                    }`}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addOrRemoveFromFavourites('Allocation Orders', Type.ao);
                                    }}
                                    disabled={isLoading}
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Favourites</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="reports2" title="Go to Reports" position="bottom">
                                <button className="action-btn app-btn icon-only sm app-btn-secondary">
                                    <svg className="svg-icon">
                                        <use xlinkHref="#fileIcon">
                                            <title>Go to Reports</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="redirect2" title="Go to Orders Page" position="bottom">
                                <button
                                    className="action-btn app-btn icon-only sm app-btn-secondary"
                                    onClick={() => {
                                        dispatch(
                                            setAoFilter({
                                                ...aoFilter,
                                                // eslint-disable-next-line no-undefined
                                                destinationType:
                                                    insightsDestination &&
                                                    insightsDestination.length &&
                                                    insightsDestination.length > 0
                                                        ? insightsDestination
                                                        : undefined,
                                                aoStatus: 'Active',
                                                poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                                                clientName:
                                                    selectedSearchItem?.type === 'Clients'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                                agentName:
                                                    selectedSearchItem?.type === 'Agents'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                                vendorName:
                                                    selectedSearchItem?.type === 'Vendors'
                                                        ? [selectedSearchItem?.name]
                                                        : undefined,
                                            })
                                        );
                                        dispatch(
                                            setSelectedAoDropdown({
                                                text: 'Active AOs',
                                                count: dashboardActiveOrdersRedux['allocationOrders']?.count || 0,
                                            })
                                        );
                                        navigate('/allocation_orders', { state: { reload: true } });
                                        clearDashboardInsightsFilters(dispatch);
                                    }}
                                >
                                    <svg className="svg-icon">
                                        <use xlinkHref="#redirectIcon">
                                            <title>Redirect</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        </div>
                        <a className="tile-anchor" onClick={() => handleClick(Type.ao)}>
                            &nbsp;
                        </a>
                    </div>
                )}
            </div>
            <div className="tab-content-body">
                <div className="graph-container">
                    <div className="tab-content-head">
                        <h5>Trends</h5>
                        <p>Showing trend for Last 12 {insightsDurationRange}</p>
                    </div>
                    <div className="graph-holder set-height">
                        <LineGraph
                            drawn={dashboardActiveOrdersRedux['total'] ? plotGraphPoints('total') : []}
                            name="activeOrdersLarge"
                            tooltip={true}
                            lineDots={true}
                            lineWidth={1}
                            pointSize={2}
                            graphAxis={true}
                            tooltipTitle={'Active Orders'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
