import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import '../../BookingProposal/BookingAdditionalInfo/reject-booking-proposal.scss';
import { submitAirBPOptions } from 'src/services/apis/bookingProposalAir/optionsApi';
import { success, error, Position } from 'src/utils/toast';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const RejectModal: React.FC<ModalProps> = ({ setModalView }) => {
    const { id } = useParams();
    const [reason, setReason] = useState('');
    const [saving, setSaving] = useState(false);

    const navigate = useNavigate();

    const { bpNumber, clientName } = useSelector((state: RootState) => state.bpHeaderData);

    const onSubmit = async () => {
        setSaving(true);
        try {
            const response = await submitAirBPOptions(id ?? '', 'reject', '', reason);
            if (response?.status) {
                success(`Options rejected successfully`, Position.TOP_RIGHT);
                setModalView(false);
                setTimeout(() => {
                    navigate('/booking_list');
                }, 2000);
            } else {
                error('Something went wrong', Position.TOP_RIGHT);
            }
        } catch (err: any) {
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        } finally {
            setSaving(false);
        }
    };
    return (
        <div className="app-modal">
            <div className="modal-content-holder medium-popup reject-booking-modal">
                <div className="modal-header">
                    <div className="title">Reject Option</div>
                    <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="layout-holder two-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Booking Proposal No.</h6>
                                <div className="description-type-1">{bpNumber}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Client Name</h6>
                                <div className="description-type-1">{clientName}</div>
                            </div>
                        </div>
                        <form id="productForm" action="" className="modal-form">
                            <div className=" modal-input-holder">
                                <label htmlFor="name" className="modal-input-label">
                                    Reason<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-field-holder">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        placeholder="Enter the reason to Reject Option"
                                        value={reason}
                                        onChange={(e) => {
                                            setReason(e.target.value);
                                        }}
                                        maxLength={250}
                                    ></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => setModalView(false)}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title={saving ? 'Rejecting...' : 'Confirm Rejection'}
                        onClick={() => onSubmit()}
                        disabled={reason && !saving ? false : true}
                    >
                        <span className="button-text footer-button-text">
                            {saving ? 'Rejecting...' : 'Confirm Rejection'}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default RejectModal;
