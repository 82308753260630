import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './activitySubList.scss';
import '../../../WorkTrendsDetailed/workTrendsCommon.scss';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { ActivitiesArray, resetSubActivityVisiblity } from 'src/store/reducers/dashboard/workTrendsReducer';
// import { Pagination } from 'reactstrap';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { getOrganisationData } from 'src/utils';
import TableFooter from 'src/components/common/Table/tableFooter';
import { fetchActivityList, setPerPageCount } from 'src/store/reducers/dashboard/workTrendsReducer';
import { FilterList } from 'src/pages/Dashboard/WorkTrends';
import moment from 'moment';
const Index: React.FC = () => {
    const {
        activities,
        selectedActivityDetails: { name, key, value, service },
        progressSelectedIndex,
        workTrends,
        currentFilter,
        page,
        perPageCount,
    } = useSelector((state: RootState) => state.workTrendsData);
    const { profileType, userName } = useUserProfile();
    const endDate =
        workTrends[progressSelectedIndex]?.label?.split('to')?.[1]?.trim() || workTrends[progressSelectedIndex]?.label;
    const startDate =
        workTrends[progressSelectedIndex]?.label?.split('to')?.[0]?.trim() || workTrends[progressSelectedIndex]?.label;
    const listOfUserOrganisations = getOrganisationData();
    const payload = {
        userIds: [],
        loggedInUserName: userName,
        filter: currentFilter?.toLowerCase(),
        date:
            currentFilter === FilterList.Months
                ? endDate
                : currentFilter === FilterList.Weeks
                ? `${moment(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD')} to ${moment(endDate, 'DD-MM-YYYY').format(
                      'YYYY-MM-DD'
                  )}`
                : moment(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        profile: profileType, //forwarder, client, agent
        limit: perPageCount,
        perPageCount,
        page: page,
        currentPage: page,
        activityType: key,
        ...listOfUserOrganisations,
        activityData: { name, value, key, service },
    };
    const [PageCount, setPageCountDisplay] = useState(false);
    const dispatch = useDispatch();
    return (
        <>
            <div className="work-trend-sub-lists">
                <div className="activity-header">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <Link
                                className="dropdown-arrow"
                                to="/dashboard/work-trends"
                                onClick={() => {
                                    dispatch(resetSubActivityVisiblity({}));
                                }}
                            >
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>Details</title>
                                    </use>
                                </svg>
                            </Link>
                            <li className="breadcrumb-list-item">
                                <Link
                                    className="breadcrumb-item"
                                    to="/dashboard/work-trends"
                                    onClick={() => {
                                        dispatch(resetSubActivityVisiblity({}));
                                    }}
                                >
                                    Activites
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to={''}>
                                    {name}
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <h4 className="activity-title">
                        {name} <span className="activity-count md">{activities?.totalNoOfItems}</span>
                    </h4>
                </div>
                <div className="activity-sub-list-wrapper customscrollbar">
                    {activities?.activities?.map(
                        ({ profileName, profile, userName, uuid, dateTime }: ActivitiesArray) => (
                            <div className="activity-sub-list" key={uuid}>
                                <div className="activty-list-item">
                                    <div className="activity-list-label">
                                        {profileName}
                                        <span className="sub-name">( {profile} )</span>
                                    </div>
                                    <div className="activity-list-label gray-text">
                                        {userName}
                                        <span className="sub-name">
                                            {moment(moment.utc(dateTime)).local().format('DD-MM-YYYY, HH:mm')}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        )
                    )}
                </div>
                <TableFooter
                    fetchData={fetchActivityList}
                    currentPage={page}
                    stat={payload}
                    displayCountfn={setPageCountDisplay}
                    pageCountDisplay={PageCount}
                    perPageCount={perPageCount}
                    setPageCount={setPerPageCount}
                    totalItems={activities?.totalNoOfItems}
                    items={activities?.activities}
                    itemsInList={null}
                    totalPages={activities?.totalNoOfPages - 1}
                />
            </div>
        </>
    );
};
export default Index;
