/* eslint-disable no-undefined */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import ClickAwayListener from 'react-click-away-listener';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ExportButton from '../../../components/common/ExportButton';
import ProfileListing from '../../../components/common/ProfileListing';
import TableFooter from '../../../components/common/Table/tableFooter';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { exportPoList } from '../../../services/apis/purchaseOrder/poListApi';
import ShowForPermissions from '../../../ShowForPermissions';
import { RootState } from '../../../store/reducers';
import { getNotification } from '../../../store/reducers/authReducer';
import { fetchPermissions } from '../../../store/reducers/permissionReducer';
import {
    fetchFilterData,
    fetchPoData,
    fetchStatusCount,
    setDefault,
    setPageCount,
    resetPage,
    setPoFilter as setFilter,
    setPoSelectedDropDown as setSelectedDropDown,
} from '../../../store/reducers/purchaseOrder/poListReducer';
import { submitSuccess } from '../../../store/reducers/purchase_order/basicDetails';
import { getOrganisationData } from '../../../utils';
import {
    activeStatus,
    allPos,
    awaitingEnrichment,
    clearFilterText,
    poHeaders,
    statusOfPos,
} from '../../../utils/constants';
import { Position, success } from '../../../utils/toast';
// import AdNewPoButton from './AdNewPo';
import './purchase-order.scss';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { PlaceholderImage, Placeholder } from 'semantic-ui-react';
const Index: React.FC = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const poType = location.pathname.includes('/warehousing_order') ? 'warehousing' : 'intermodal';
    const {
        purchaseOrders,
        filterValues,
        currentPage,
        perPageCount,
        totalPages,
        columnStatus,
        totalItems,
        isLoading,
        isLoadingPO,
        statusCount,
        poFilter: filter,
        poSelectedDropDown: selectedDropDown,
    } = useSelector((state: RootState) => state.purchaseOrderList);
    const { successPo, poName } = useSelector((state: RootState) => state.purchaseOrder);
    const [selectedHeader, setSelectedHeader] = useState(poHeaders.createdDate);
    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState('');

    // eslint-disable-next-line
    const [expandId, setexpandId] = useState<any | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [kendoFilter, setKendoFilter] = useState('{}');
    // const [showAdNewPoDropDown, setShowAdNewPoDropDown] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const [addNewDropdownOpen, setAddNewDropdownOpen] = useState(false); //To control the add new PO dropdwon open/close state
    const [activeCount, setActiveCount] = useState(0);
    const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data1?.user?.us_UserName;
    const { profileType } = useUserProfile();
    const firstUpdate = useRef(true);
    const organisationData = getOrganisationData();
    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter, type: 'ITPO' },
        profileType,
        poType,
    };
    const sortPayload = {
        sortOrder: currentOrder ? 'DESC' : 'ASC',
        sortColumn: columnStatus,
    };
    const payloadForFilter = {
        ...payload,
        data: { ...organisationData, ...{ poStatus: filter.poStatus }, type: 'ITPO', ...filter },
    };

    useEffect(() => {
        const sum = getTotalNumberOfPOs();
        setActiveCount(sum);
    }, [statusCount]);
    const wasCalled = useRef(false);

    useEffect(() => {
        document.title = 'GRD>Orders';

        // when user select booking status from the dropdown, kendo grid should rerender
        let status = '';
        if (selectedDropDown?.text) {
            switch (selectedDropDown.text) {
                case 'Draft':
                    status = 'Draft';
                    break;
                case 'Awaiting Enrichment':
                    status = 'Awaiting Enrichment';
                    break;
                case 'Ready For Booking':
                    status = 'Ready For Booking';
                    break;
                case 'Partially Booked':
                    status = 'Partially Booked';
                    break;
                case 'Booked':
                    status = 'Booked';
                    break;
                case 'Pending Reassignment':
                    status = 'Pending Reassignment';
                    break;
                case 'Cancelled':
                    status = 'Cancelled';
                    break;
                case 'Discarded':
                    status = 'Discarded';
                    break;
                default:
                    status = '';
            }
        }

        if (status.length > 0) setKendoFilter(`{field: "PO_Status", operator: "eq", value: "${status}"}`);
        else setKendoFilter('{}');
    }, [selectedDropDown]);

    useEffect(() => {
        if (payload?.data?.poStatus && (location as any)?.state?.fromNav === true) {
            if (!wasCalled.current) {
                payload.data.poStatus = profileType === 'agent' ? awaitingEnrichment : activeStatus;
            }
            wasCalled.current = true;
        }
        // dispatch(fetchPoData(payload));
        // dispatch(fetchFilterData(payloadForFilter));
        // dispatch(fetchStatusCount(payload));
        dispatch(fetchPermissions(username));
    }, [dispatch, /* currentPage, */ setSelectedHeader, /* perPageCount, */ currentOrder, filter]);

    useEffect(() => {
        dispatch(fetchStatusCount(payload));
    }, []);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
    }, [totalPages]);

    useEffect(() => {
        if (successPo) {
            success(`Purchase Order ${poName} submitted successfully`, Position.TOP_RIGHT);
            dispatch(submitSuccess(false));
            dispatch(fetchPoData(payload));
            dispatch(fetchFilterData(payloadForFilter));
            dispatch(fetchStatusCount(payload));
            dispatch(fetchPermissions(username));
        }
        dispatch(getNotification({}));
    }, [successPo]);

    const getTotalNumberOfPOs = () => {
        // const status = statusCount as any;
        // if (status) {
        //     let sum = (Object.values(status) as number[])?.reduce((prev: number, current: number) => prev + current, 0);
        //     if (sum) {
        //         sum = sum - ((status['Cancelled'] || 0) + (status['Completed'] || 0));
        //     }
        //     return sum;
        // } else {
        //     return 0;
        // }
        return (statusCount as any)?.['Active POs'] ?? 0;
    };

    const getDropDown = () => {
        const finalArray = [];
        for (const key of statusOfPos) {
            if (profileType !== 'agent' || key !== 'Draft') {
                if (statusCount && Object.prototype.hasOwnProperty.call(statusCount, key)) {
                    finalArray.push(
                        <a
                            className={`menu-item app-btn ${selectedDropDown.text === key ? ' active' : ''}`}
                            onClick={() => {
                                dispatch(
                                    setSelectedDropDown({
                                        text: key,
                                        count: statusCount[key as keyof typeof statusCount],
                                    })
                                );
                                setDropDownOpen(false);
                                dispatch(resetPage({}));
                                dispatch(setFilter({ ...filter, poStatus: key }));
                            }}
                        >
                            <div className="content-heading-holder">
                                <span className="content-heading">{key}</span>
                                <span className=" modal-toast">
                                    <div className="toast-message ">{statusCount[key as keyof typeof statusCount]}</div>
                                </span>
                            </div>
                        </a>
                    );
                }
            }
        }
        return finalArray;
    };

    const closeAllDropDown = () => {
        setDropDownOpen(false);
        setToggleDropdown(false);
        setShowExportDropDown(false);
        setPageCountDisplay(false);
    };

    const clearSearchFilter = () => {
        if ((filter as any)?.poSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.poSearch;
            dispatch(setFilter(filterObj));
        }
        dispatch(fetchStatusCount(payload));
    };

    useLayoutEffect(() => {
        if ((location as any)?.state?.reload !== true) {
            resetFilters();
        }
    }, [location]);

    const resetFilters = () => {
        const globalStatus = allPos;

        const globalDropDown = allPos;
        if (
            (Object.keys(filter)?.length === 1 &&
                filter?.poStatus !== globalStatus &&
                selectedDropDown?.text !== globalDropDown) ||
            Object.keys(filter)?.length > 1
        ) {
            const initialFilter = {
                poStatus: allPos,
            };
            const initialDropDown = {
                text: allPos,
                count: 0,
            };
            setSearchTerm('');
            dispatch(setFilter(initialFilter));
            dispatch(setSelectedDropDown(initialDropDown));
        }
    };

    const getPlaceHolder = () => {
        const placeholder = [];

        for (let i = 0; i < 4; i++) {
            placeholder.push(
                <>
                    <div className="layout-holder one-column">
                        <Placeholder style={{ height: 50, width: 50 }}>
                            <PlaceholderImage />
                        </Placeholder>
                    </div>
                    <div className="layout-holder one-column">
                        <Placeholder style={{ height: 50, width: 50 }}>
                            <PlaceholderImage />
                        </Placeholder>
                    </div>
                </>
            );
        }
        return placeholder;
    };

    const getTiles = () => {
        if ((statusCount as any)?.['Active POs']) {
            const tilesArray = [
                <a
                    className="card insight-card"
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                            setSelectedDropDown({
                                text: 'Active POs',
                                count: (statusCount as any)?.['Active POs'],
                            })
                        );
                    }}
                    href="#"
                    // style={{ width: '110px', height: '70px', padding: '5px' }}
                >
                    <>
                        <div
                            className="card-header"
                            // style={{ minHeight: '63px', position: 'relative' }}
                        >
                            <div className="title-wrapper">
                                <h5 className="card-title bold" style={{ fontSize: 11 }}>
                                    {'Active POs'}
                                </h5>
                                <span
                                    className="counts"
                                    // style={{ fontSize: 11 }}
                                >
                                    {(statusCount as any)?.['Active POs'] || 0}
                                </span>
                            </div>
                        </div>
                    </>
                </a>,
            ];
            for (const key of statusOfPos) {
                if (statusCount && Object.prototype.hasOwnProperty.call(statusCount, key)) {
                    tilesArray.push(
                        <a
                            className="card insight-card"
                            onClick={(e) => {
                                e.preventDefault();
                                dispatch(
                                    setSelectedDropDown({
                                        text: key,
                                        count: statusCount[key as keyof typeof statusCount],
                                    })
                                );
                            }}
                            href="#"
                            // style={{ width: '110px', height: '70px', padding: '5px' }}
                        >
                            <>
                                <div
                                    className="card-header"
                                    // style={{ minHeight: '63px', position: 'relative' }}
                                >
                                    <div className="title-wrapper">
                                        <h5
                                            className="card-title bold"
                                            style={{ width: '100%', fontSize: key.length <= 10 ? 10 : 11 }}
                                        >
                                            {key}
                                        </h5>
                                        <span
                                            className="counts"
                                            // style={{ fontSize: 16, position: 'absolute', bottom: '0px', left: '0px' }}
                                        >
                                            {statusCount[key as keyof typeof statusCount] || 0}
                                        </span>
                                    </div>
                                </div>
                            </>
                        </a>
                    );
                }
            }
            return tilesArray;
        }

        return [];
    };

    return (
        <div
            className="main-wrapper"
            onClick={() => {
                closeAllDropDown();
            }}
        >
            <div className="main-content-holder no-main-grid-border purchase-grid booking-grid">
                <h2 className="page-heading">{poType === 'warehousing' ? 'Warehousing PO' : 'Intermodal PO'}</h2>
                <div className="grid-section grid-p-0">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="grid-holder">
                        <div className="grid-header grid-m-0-30">
                            <div className="search-field-booking-list">
                                {/* <ClickAwayListener onClickAway={() => setDropDownOpen(false)}>
                                    <div
                                        className={`dropdown-wrap ${
                                            dropDownOpen ? 'dropdown-open' : ''
                                        } download-dropdown search-toast-content-holder`}
                                    >
                                        <button
                                            className="app-btn app-btn-secondary"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDropDownOpen(!dropDownOpen);
                                            }}
                                        >
                                            <div className="purchase-dropdown-content">
                                                <span className="button-text">Status :</span>
                                                <div className="content-heading-holder">
                                                    <span className="content-heading">{selectedDropDown?.text}</span>
                                                    <span className=" modal-toast">
                                                        <div className="toast-message ">
                                                            {selectedDropDown?.text === allPos
                                                                ? activeCount
                                                                : statusCount &&
                                                                  statusCount[
                                                                      selectedDropDown.text as keyof typeof statusCount
                                                                  ]}
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>

                                            <svg className="svg-icon arrow-icon">
                                                <use href="#downArrow">
                                                    <title>Expand row</title>
                                                </use>
                                            </svg>
                                        </button>
                                        <div className="dropdown-menu">
                                            <>
                                                <a
                                                    className={`menu-item app-btn ${
                                                        selectedDropDown.text === 'Active POs' ? ' active' : ''
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        dispatch(
                                                            setSelectedDropDown({
                                                                text: 'Active POs',
                                                                count: getTotalNumberOfPOs(),
                                                            })
                                                        );
                                                        setDropDownOpen(false);
                                                        dispatch(resetPage({}));
                                                        dispatch(setFilter({ ...filter, poStatus: 'Active' }));
                                                    }}
                                                >
                                                    <div className="content-heading-holder">
                                                        <span className="content-heading">Active POs</span>
                                                        <span className=" modal-toast">
                                                            <div className="toast-message ">
                                                                {getTotalNumberOfPOs()}
                                                            </div>
                                                        </span>
                                                    </div>
                                                </a>
                                                {statusCount && getDropDown()}
                                            </>
                                        </div>
                                    </div>
                                </ClickAwayListener> */}
                                {isLoading ? getPlaceHolder() : getTiles()}
                            </div>

                            <div className="add-btn-purchase">
                                {/* added Reassign Agent button for sprint16 */}
                                {profileType === 'forwarder' && (
                                    <ShowForPermissions permission="agent" type="reassignment" item="Purchase Order">
                                        <Link style={{ textDecoration: 'none' }} to="/reassign-agent/purchase-order">
                                            <button className="app-btn app-btn-secondary" title="Reassign Agent">
                                                <span className="button-text">Reassign Agent</span>
                                            </button>
                                        </Link>
                                    </ShowForPermissions>
                                )}

                                {/* <AdNewPoButton
                                    showAdNewPoDropDown={showAdNewPoDropDown}
                                    setShowAdNewPoDropDown={setShowAdNewPoDropDown}
                                    disabled={false}
                                /> */}
                                {(profileType === 'client' || profileType === 'forwarder') && (
                                    <ShowForPermissions permission="edit" type="po">
                                        <ClickAwayListener
                                            onClickAway={() => {
                                                if (addNewDropdownOpen) {
                                                    setAddNewDropdownOpen(false);
                                                }
                                            }}
                                        >
                                            <div
                                                className={`dropdown-wrap grid-header-dropdown right-align ${
                                                    addNewDropdownOpen ? 'dropdown-open' : ''
                                                }`}
                                            >
                                                <button
                                                    className="app-btn app-btn-secondary icon-button"
                                                    title="Add New PO"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        localStorage.removeItem('poStatus');
                                                        localStorage.removeItem('poNumber');
                                                        setAddNewDropdownOpen(!addNewDropdownOpen);
                                                    }}
                                                >
                                                    <svg className="svg-icon add-btn-icon">
                                                        <use xlinkHref="#plusIcon">
                                                            <title>Add New PO</title>
                                                        </use>
                                                    </svg>
                                                    <span className="button-text footer-button-text">Add New PO</span>
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {poType === 'intermodal' ? (
                                                        <>
                                                            <Link
                                                                to="/purchase_order/basic_details/?shippingMode=sea"
                                                                style={{ textDecoration: 'none' }}
                                                                className="menu-item app-btn"
                                                            >
                                                                Sea Freight
                                                            </Link>
                                                            <Link
                                                                to="/purchase_order/basic_details/?shippingMode=air"
                                                                style={{ textDecoration: 'none' }}
                                                                className="menu-item app-btn"
                                                            >
                                                                Air Freight
                                                            </Link>
                                                            {/* <Link
                                                                to="/purchase_order/basic_details/?shippingMode=land"
                                                                style={{ textDecoration: 'none' }}
                                                                className="menu-item app-btn"
                                                            >
                                                                Land (Into BLP)
                                                            </Link> */}
                                                        </>
                                                    ) : (
                                                        <Link
                                                            to="/purchase_order/basic_details/?shippingMode=land"
                                                            style={{ textDecoration: 'none' }}
                                                            className="menu-item app-btn"
                                                        >
                                                            Land Freight
                                                        </Link>
                                                    )}
                                                </div>
                                            </div>
                                        </ClickAwayListener>
                                        {/* <button
                                                className="app-btn app-btn-secondary icon-button"
                                                title="Add PO"
                                                onClick={() => {
                                                    localStorage.removeItem('poStatus');
                                                    localStorage.removeItem('poNumber');
                                                }}
                                            >
                                                <svg className="svg-icon add-btn-icon">
                                                    <use xlinkHref="#plusIcon"></use>
                                                </svg>
                                                <span className="button-text footer-button-text">Add New PO</span>
                                            </button> */}
                                    </ShowForPermissions>
                                )}
                            </div>
                        </div>
                        {statusCount && (
                            <LayoutWithColumns
                                showLoader={false}
                                key={kendoFilter}
                                style={'booking'}
                                content={poType === 'warehousing' ? 'warehouse-order-list' : 'purchase-order-list'}
                                filter={kendoFilter}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Index;
