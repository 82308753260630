import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { isLoadingFn } from '../../reducers/bookingProposal/addedPoReducer';
import { hideLoading } from 'react-redux-loading-bar';
import { removePoLine } from '../../../services/apis/bookingProposalLcl/addedPosApi';
import { removeError, removeSuccess } from 'src/store/reducers/bookingProposalLcl/addedPoReducer';

function* removePoFromList({ payload }: { payload: any; type: any }) {
    try {
        const res: AxiosResponse<any> = yield call(removePoLine, payload);
        yield put({ type: removeSuccess, payload: res });
    } catch (err) {
        yield put({ type: removeError, payload: err });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* lclAddedPoListSaga() {
    yield takeEvery('addedPosLcl/removePo', removePoFromList);
}

export default lclAddedPoListSaga;
