import React from 'react';
import { ShipmentTabProps } from '.';
import { HelmetProvider } from 'react-helmet-async';
import GridLayout from 'src/components/KendoGridJquery/GridLayoutWithLocalData';
import { shipmentGridDetails } from './columns';
import { useTimeout } from 'react-use';

const SubShipment: React.FC<ShipmentTabProps> = ({ data }) => {
    const [isReady] = useTimeout(100);
    const getData = () => {
        return data?.subShipmentCollection?.subShipment?.map((res) => {
            return {
                shipmentId: res?.dataContext?.dataSourceCollection?.dataSource?.[0].key,
                // entryStatus: '',
                containerMode: res?.containerMode?.code,
                shipmentIncoTerm: res?.shipmentIncoTerm?.code,
                portOfLoading: res?.portOfLoading?.code,
                portOfDischarge: res?.portOfDischarge?.code,
                documentedWeight: res?.totalWeight,
                documentedVolume: res?.totalVolume,
                // consignor: res?._consignor,
                // consignee: res?._consignee,
                // scacCode: '',
                // vesselName: res?.vesselName,
                // voyageFlightNo: res?.voyageFlightNo,
                hbl: res?.wayBillNumber,
            };
        });
    };
    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-content-holder">
                {isReady() ? (
                    <HelmetProvider>
                        <div className="insight-agent-list-data-trend">
                            <div className="container-fluid subshipment-details">
                                <div className="k-kendo-row one-column">
                                    <div className="k-kendo-col-2 custom-kendo-grid">
                                        <div id="grid"></div>
                                    </div>
                                </div>
                            </div>

                            <GridLayout
                                data={getData()}
                                columns={shipmentGridDetails['sub-shipment'].columns}
                                fileName={shipmentGridDetails['sub-shipment'].fileName}
                                fields={shipmentGridDetails['sub-shipment'].fields}
                            />
                        </div>
                    </HelmetProvider>
                ) : null}
            </div>
        </div>
    );
};

export default SubShipment;
