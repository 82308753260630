interface UserDetails {
    userName: string;
    name: string;
}
export const getUserDetails = (): UserDetails => {
    const userDetails = JSON.parse(localStorage.getItem('user') || '')?.user;
    const userName = userDetails?.us_UserName;
    const name = (userDetails?.us_FirstName || '') + ' ' + (userDetails?.us_LastName || '');
    return { userName, name };
};
