import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../POSelection.scss';
import './container-allocations.scss';
// import BookingAdditionalInfo from '../BookingAdditionalInfo';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { error, Position, success } from '../../../utils/toast';
import {
    // selectContainerAllocationError,
    // selectContainerAllocationIsError,
    selectContainerAllocationLoading,
    // selectContainerAllocationSuccess,
    fetchContainers,
    selectContainers,
    BpContainersList,
    resetFields,
    defaultBpContainerDetail,
    BpContainerDetail,
    fetchContainerOnlyBasicDetail,
    fetchContainerDetail,
    fetchContainerMode,
    fetchPacking,
} from '../../../store/reducers/bookingProposal/containerAllocationReducer';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import {
    clearFailure,
    selectAdditionalDetailsError,
    selectAdditionalDetailsIsError,
    selectAdditionalDetailsSuccess,
    approveBp,
    updateBP,
    selectEnrichBooking,
    selectHouseBillDetails,
    fetchEnrichBooking,
    fetchHouseBills,
    selectgateInDateValidation,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import {
    fetchDeliveryAndContainerModes,
    fetchNacAdditionalDetails,
    fetchContractDetails,
} from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useUserProfile } from '../../../hooks/useUserProfile';
// import ShowForPermissions from '../../../ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import ShowForPermissions from 'src/ShowForPermissions';
import CancelModal from 'src/components/common/CancelModal';
import BasicDetailsModal from './ContainerDetails/BasicDetailsModal';
import NewContainerModal from './ContainerDetails/NewContainerModal';
import DeleteConfirmModal from './deleteConfirmModal';
import ContainerPackingModal from './containerPackingModal';
import RejectBookingProposalModal from '../BookingAdditionalInfo/RejectBookingProposalModal';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { getUserDetails } from 'src/utils/getUserDetails';
import UnavailableModel from '../SelectedSailingSchelude/UnavailableModel';

const allocationStatus = (status: string) => {
    switch (status) {
        case 'Pending':
            return <div className="allocated-status pending">Pending</div>;
        case 'In_Progress':
            return <div className="allocated-status in-progress">In-progress</div>;
        case 'Completed':
            return <div className="allocated-status completed">Completed</div>;
    }
};

const Index: React.FC = () => {
    const { id } = useParams();
    const { profileType, userName } = useUserProfile();
    const navigate = useNavigate();
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const [rejectionModalView, setRejectionModalView] = useState(false);
    const [assignAgentModalView, setAssignAgentModalView] = useState(false);
    const [reason, setReason] = useState('');
    const [reasonRejection, setReasonRejection] = useState('');
    const [rejectedBpForwarderModalView, setRejectedBpForwarderModalView] = useState(false);
    const [displayModal, setdisplayModal] = useState(false);
    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: `/bcnBookingList/${id}/POSelection`,
            disabled: false,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/bcnBookingList/${id}/SailingSelectionDetails`,
            disabled: false,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/bcnBookingList/${id}/additional-details`,
            disabled: false,
        },
        {
            title: 'Container Allocation',
            selected: 1,
            path: `/bcnBookingList/${id}/container-allocation`,
            disabled: false,
        },
        // {
        //     title: 'Attachments',
        //     selected: 0,
        //     path: `/bcnBookingList/${id}/attachments`,
        // },
    ];
    if (
        headerData.bpStatus === 'Ready_for_Booking' ||
        headerData.bpStatus === 'Draft_Booking' ||
        headerData.bpStatus === 'Booked'
    ) {
        // navData.push({
        //     title: 'Attachments',
        //     selected: 0,
        //     path: `/bcnBookingList/${id}/attachments`,
        //     disabled: false,
        // });
    }

    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [containerData, setContainerData] = useState<BpContainersList[]>([]);
    const [search, setSearch] = useState('');
    const dispatch = useAppDispatch();
    // const isError = useAppSelector(selectContainerAllocationIsError);
    // const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const containers = useAppSelector(selectContainers);
    // const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    const isLoading = useAppSelector(selectContainerAllocationLoading);
    // const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { initialContainerValues, additionalDetails: additionalDetailsCurrent } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const [cancelModalView, setCancelModalView] = useState(false);
    const [validation, setValidation] = useState(false);
    const [bookingContainerId, setBookingContainerId] = useState('');
    const [containerDetailId, setContainerDetailId] = useState('');
    const { containerDetail } = useSelector((state: RootState) => state.containerAllocation);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPackingModal, setShowPackingModal] = useState(false);
    const [hasUpdateCompleted, setHasUpdateCompleted] = useState(false);
    const [showBasicDetails, setShowBasicDetails] = useState(false);
    const [showContainerCreateModal, setShowContainerCreateModal] = useState(false);

    const [nextBtn, setNextBtn] = useState(false);
    const [nextAddBtn, setNextAddBtn] = useState(false);
    const [validationBtn, setvalidationBtn] = useState(false);

    const isError = useAppSelector(selectAdditionalDetailsIsError);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const additionalDetailsError = useAppSelector(selectAdditionalDetailsError);

    const enrichBooking = useAppSelector(selectEnrichBooking);
    const houseBillData = useAppSelector(selectHouseBillDetails);
    const { additionalDetails } = useSelector((state: RootState) => state.additionalDetailsNac);
    const { submitBPSuccess, submitBPError } = useSelector((state: RootState) => state.additionalDetailsData);
    const gateinVal = useAppSelector(selectgateInDateValidation);

    useEffect(() => {
        if (id) {
            dispatch(fetchEnrichBooking({ bpId: id }));
            dispatch(fetchHouseBills({ bpId: id }));
            dispatch(fetchContainers({ bpId: id }));
            dispatch(fetchNacAdditionalDetails({ bpId: id }));
            dispatch(clearFailure(null));
        }
    }, [id]);

    useEffect(() => {
        if (gateinVal) {
            setdisplayModal(true);
        } else {
            setdisplayModal(false);
        }
    }, [gateinVal]);

    useEffect(() => {
        if (!showBasicDetails) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [showBasicDetails]);
    useEffect(() => {
        if (!showContainerCreateModal) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [showContainerCreateModal]);
    useEffect(() => {
        if (!showPackingModal) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [showPackingModal]);

    useEffect(() => {
        /* eslint no-var: off */
        /* eslint-disable no-undefined */
        if (!headerData?.gateInDateExpired) {
            setvalidationBtn(true);
        } else {
            setvalidationBtn(false);
        }
    }, [headerData?.gateInDateExpired]);

    useEffect(() => {
        if (submitBPSuccess) {
            success(`Booking details sent successfully`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPError) {
            error(`Something went wrong`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPSuccess) {
            dispatch(clearFailure([]));
            setTimeout(() => {
                window.location.replace('/booking_list');
            }, 3000);
        }
    }, [submitBPSuccess, submitBPError]);

    useEffect(() => {
        if (additionalDetails?.contractId) {
            dispatch(fetchContractDetails({ contractId: additionalDetails.contractId }));
        }
    }, [additionalDetails]);

    useEffect(() => {
        if (containers.length) {
            setContainerData(containers);
        }
    }, [containers]);

    useEffect(() => {
        if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
        dispatch(fetchNacAdditionalDetails({ bpId: id }));
    }, [hasUpdateCompleted]);

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    //@ts-ignore
    window.containerAction = (action: string, bpPk: string, bcPk: string, bdPk: string) => {
        if (action === 'EDT') {
            setBookingContainerId(bcPk);
            setContainerDetailId(bdPk);
            if (bdPk && bdPk !== 'null') dispatch(fetchContainerDetail({ containerDetailsId: bdPk }));
            else if (bcPk && bcPk !== 'null') dispatch(fetchContainerOnlyBasicDetail({ containerId: bcPk }));
            setShowBasicDetails(true);
        } else if (action === 'DEL') {
            setBookingContainerId(bcPk);
            setShowDeleteModal(true);
        } else if (action === 'PCK') {
            setContainerDetailId(bdPk);
            setShowPackingModal(true);
        } else if (action === 'NEW') {
            setShowContainerCreateModal(true);
        }
    };

    if (headerData?.bcnType === 'PAR') {
        navData.push({
            title: 'Linked Bookings',
            selected: 0,
            path: `/bcnBookingList/${id}/linked-bookings`,
            disabled: false,
        });
    }
    useEffect(() => {
        if (isError) {
            error(additionalDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (additionalDetailsSuccess?.status === 200 || additionalDetailsSuccess?.status === 201) {
            if (additionalDetailsSuccess?.data?.type === 'rejected') {
                success(
                    `Booking Proposal ${
                        additionalDetailsSuccess?.data?.info?.bpNumber
                            ? additionalDetailsSuccess?.data?.info?.bpNumber
                            : ''
                    } rejected successfully`,
                    Position.TOP_RIGHT
                );
                dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(fetchNacAdditionalDetails({ bpId: id }));
                setTimeout(() => navigate('/booking_list'), 2000);
            } else if (additionalDetailsSuccess?.data?.type === 'approved') {
                const bpNumber = additionalDetailsSuccess?.data?.info?.bpNumber
                    ? additionalDetailsSuccess?.data?.info?.bpNumber
                    : '';
                const message =
                    profileType === 'client'
                        ? `Booking Proposal ${bpNumber} approved successfully`
                        : `Booking Proposal ${bpNumber} submitted for approval`;
                success(message, Position.TOP_RIGHT);
                dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(fetchNacAdditionalDetails({ bpId: id }));
                setTimeout(() => navigate('/booking_list'), 2000);
            }
            dispatch(clearFailure([]));
        }
    }, [isError, additionalDetailsSuccess, additionalDetailsError]);

    const submitBp = () => {
        const fromForwarderDraft =
            (headerData.bpStatus === 'Draft_Booking_Proposal' || headerData.bpStatus === 'Rejected_by_Forwarder') &&
            profileType === 'forwarder';
        dispatch(approveBp({ bpId: id, userName, profile: profileType, fromForwarderDraft }));
        propagateBcnData(id, true);
    };

    const rejectBp = () => {
        setRejectionModalView(true);
    };

    useEffect(() => {
        if (headerData.bpStatus === 'Draft_Booking' && profileType !== 'client') {
            if (
                enrichBooking &&
                houseBillData &&
                containers &&
                houseBillData.numbers?.length > 0 &&
                houseBillData.numbers[0]?.vendors?.length > 0 &&
                enrichBooking?.soNumber &&
                enrichBooking?.originCartageCompany &&
                houseBillData?.numbers[0].vendors[0].houseBillNumber &&
                containers?.length > 0
            ) {
                setNextBtn(true);
            } else {
                setNextBtn(false);
            }
        }
    }, [headerData, enrichBooking, houseBillData, containers]);

    useEffect(() => {
        const headerDataForContainer = JSON?.parse(JSON?.stringify(headerData));
        if (headerData?.isTriangleShipment) {
            if (additionalDetails?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.destinationAgentName &&
                    additionalDetails?.sellQuotationName &&
                    additionalDetails?.sellTotOriginCost !== 0
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'FAK' && (profileType === 'agent' || profileType === 'forwarder')) {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.destinationAgentName
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'NAC' && additionalDetails?.destinationAgentName) {
                setNextAddBtn(true);
            }
        } else {
            if (additionalDetails?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.sellQuotationName &&
                    additionalDetails?.sellTotOriginCost &&
                    additionalDetails?.sellTotOriginCost !== '0'
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'FAK' && (profileType === 'agent' || profileType === 'forwarder')) {
                // if (additionalDetails?.savedFakFileName && additionalDetails?.totOriginCost) {

                setNextAddBtn(true);

                // }
            }
            if (additionalDetails?.contractType === 'NAC') {
                setNextAddBtn(true);
            }
            if (headerDataForContainer?.tabValues?.containerDetails === false) {
                setNextAddBtn(false);
            }
        }
    }, [additionalDetails, headerData, profileType]);

    const submitReadyForShipment = () => {
        const { userName } = getUserDetails();
        dispatch(updateBP({ bookingId: id, userName, profile: profileType }));
        propagateBcnData(id, true);
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                />

                <BookingProcessTab navData={navData} />
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper grid-p-0">
                <div className="grid-header grid-ml-20">
                    <div className="content-heading-holder card-head-btn-edit-holder">
                        <span className="content-heading">Containers</span>
                    </div>
                </div>

                <LayoutWithColumns content={'containerAllocation'} style="booking_details" />
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {headerData.bcnType === 'CLD' && (
                        <Link
                            title="Previous"
                            className="app-btn app-btn-primary footer-btn"
                            to={`/bcnBookingList/${headerData.bcnParentPk}/container-allocation`}
                        >
                            <span className="button-text footer-button-text">
                                Go to Parent ({headerData.bcnParentNumber})
                            </span>
                        </Link>
                    )}
                    {/* {profileType === 'agent' &&
                        (headerData?.bpStatus === 'Booked' ||
                            headerData?.bpStatus === 'Ready_for_Booking' ||
                            headerData?.bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                {headerData?.agentIntegrationStatus !== true && (
                                    <button
                                        title="Cancel Booking"
                                        className="app-btn app-btn-secondary footer-btn"
                                        onClick={() => {
                                            setShowBookingCancelModal(true);
                                        }}
                                    >
                                        <span className="button-text footer-button-text">Cancel Booking</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                        )} */}
                    {headerData.bcnType !== 'CLD' && (
                        <div>
                            <Link
                                title="Previous"
                                className="app-btn app-btn-secondary footer-btn"
                                to={`/bcnBookingList/${id}/${
                                    profileType === 'vendor' ? 'SailingSelectionDetails' : 'additional-details'
                                }`}
                            >
                                <span className="button-text footer-button-text">Previous</span>
                                {/* </button> */}
                            </Link>
                            {headerData.bpStatus === 'Ready_for_Booking' ||
                            headerData.bpStatus === 'Draft_Booking' ||
                            headerData.bpStatus === 'Booked'
                                ? null
                                : // <Link
                                  //     title="Next"
                                  //     className="app-btn app-btn-primary footer-btn"
                                  //     to={`/bcnBookingList/${id}/attachments`}
                                  // >
                                  //     <span className="button-text footer-button-text">Next</span>
                                  // </Link>
                                  (() => {
                                      if (profileType === 'forwarder') {
                                          return (
                                              <>
                                                  {initialContainerValues?.length > 0 &&
                                                      (headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                                          headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                          (headerData.bpStatus === 'Rejected_by_Forwarder' &&
                                                              headerData.isForwarderSubmit) ||
                                                          headerData.bpStatus ===
                                                              'Rejected_by_Client_Forwarder_to_Action') && (
                                                          <>
                                                              {headerData.bpStatus ===
                                                                  'Awaiting_Forwarder_Approval' && (
                                                                  <ShowForPermissions permission="approve" type="bp">
                                                                      <a
                                                                          onClick={() => rejectBp()}
                                                                          title="Reject Proposal"
                                                                          className="app-btn app-btn-secondary footer-btn"
                                                                      >
                                                                          <span className="button-text footer-button-text">
                                                                              Reject Proposal
                                                                          </span>
                                                                      </a>
                                                                  </ShowForPermissions>
                                                              )}
                                                              {headerData.bpStatus ===
                                                                  'Rejected_by_Client_Forwarder_to_Action' &&
                                                                  !headerData.isForwarderSubmit && (
                                                                      <a
                                                                          title="Assign to Agent"
                                                                          className="app-btn app-btn-secondary footer-btn"
                                                                          onClick={() => {
                                                                              setAssignAgentModalView(true);
                                                                              setReasonRejection(
                                                                                  headerData.reason
                                                                                      ? headerData.reason
                                                                                      : ''
                                                                              );
                                                                          }}
                                                                      >
                                                                          <span className="button-text footer-button-text">
                                                                              Assign to Agent
                                                                          </span>
                                                                      </a>
                                                                  )}
                                                              <ShowForPermissions permission="approve" type="bp">
                                                                  <button
                                                                      onClick={() => submitBp()}
                                                                      title={
                                                                          (additionalDetailsCurrent?.contractType ===
                                                                              'FAK' &&
                                                                              headerData.bpStatus ===
                                                                                  'Awaiting_Forwarder_Approval' &&
                                                                              !additionalDetailsCurrent?.sellQuotationName) ||
                                                                          (additionalDetailsCurrent?.contractType ===
                                                                              'FAK' &&
                                                                              (headerData.bpStatus ===
                                                                                  'Draft_Booking_Proposal' ||
                                                                                  headerData.bpStatus ===
                                                                                      'Rejected_by_Forwarder') &&
                                                                              !additionalDetailsCurrent?.totOriginCostCurrency) ||
                                                                          headerData.gateInDateExpired ||
                                                                          (headerData?.isTriangleShipment &&
                                                                              !additionalDetailsCurrent?.destinationAgentCode)
                                                                              ? 'Mandatory fields are missing or needs to be filled'
                                                                              : `Submit for${
                                                                                    headerData.bpStatus ===
                                                                                        'Draft_Booking_Proposal' ||
                                                                                    headerData.bpStatus ===
                                                                                        'Rejected_by_Forwarder'
                                                                                        ? ' '
                                                                                        : ' Client '
                                                                                }Approval`
                                                                      }
                                                                      className="app-btn app-btn-primary footer-btn"
                                                                      disabled={
                                                                          (additionalDetailsCurrent?.contractType ===
                                                                              'FAK' &&
                                                                              headerData.bpStatus ===
                                                                                  'Awaiting_Forwarder_Approval' &&
                                                                              !additionalDetailsCurrent?.sellQuotationName) ||
                                                                          (additionalDetailsCurrent?.contractType ===
                                                                              'FAK' &&
                                                                              (headerData.bpStatus ===
                                                                                  'Draft_Booking_Proposal' ||
                                                                                  headerData.bpStatus ===
                                                                                      'Rejected_by_Forwarder') &&
                                                                              !additionalDetailsCurrent?.totOriginCostCurrency) ||
                                                                          headerData.gateInDateExpired ||
                                                                          (headerData?.isTriangleShipment &&
                                                                              !additionalDetailsCurrent?.destinationAgentCode)
                                                                              ? true
                                                                              : false
                                                                      }
                                                                  >
                                                                      <span className="button-text footer-button-text">
                                                                          Submit for
                                                                          {headerData.bpStatus ===
                                                                              'Draft_Booking_Proposal' ||
                                                                          headerData.bpStatus ===
                                                                              'Rejected_by_Forwarder'
                                                                              ? ' '
                                                                              : ' Client '}
                                                                          Approval
                                                                      </span>
                                                                  </button>
                                                              </ShowForPermissions>
                                                          </>
                                                      )}
                                              </>
                                          );
                                      } else if (profileType === 'client') {
                                          return (
                                              <>
                                                  {headerData.bpStatus === 'Awaiting_Client_Approval' && (
                                                      <ShowForPermissions permission="approve" type="bp">
                                                          <a
                                                              onClick={() => rejectBp()}
                                                              title="Reject Proposal"
                                                              className="app-btn app-btn-secondary footer-btn"
                                                          >
                                                              <span className="button-text footer-button-text">
                                                                  Reject Proposal
                                                              </span>
                                                          </a>
                                                          <button
                                                              onClick={() => submitBp()}
                                                              title="Approve Proposal"
                                                              disabled={headerData.gateInDateExpired ? true : false}
                                                              className="app-btn app-btn-primary footer-btn"
                                                          >
                                                              <span className="button-text footer-button-text">
                                                                  Approve Proposal
                                                              </span>
                                                          </button>
                                                      </ShowForPermissions>
                                                  )}
                                              </>
                                          );
                                      } else {
                                          return (
                                              <>
                                                  {(initialContainerValues?.length > 0 &&
                                                      additionalDetailsCurrent?.contractType === 'FAK' &&
                                                      additionalDetailsCurrent?.totOriginCost &&
                                                      additionalDetailsCurrent?.savedFakFileName &&
                                                      (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                          headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                          headerData.bpStatus ===
                                                              'Rejected_by_Client_Agent_to_Action')) ||
                                                  (initialContainerValues?.length > 0 &&
                                                      additionalDetailsCurrent?.contractType !== 'FAK' &&
                                                      (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                          headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                                          headerData.bpStatus ===
                                                              'Rejected_by_Client_Agent_to_Action')) ? (
                                                      <a
                                                          title="Submit for Approval"
                                                          onClick={() => submitBp()}
                                                          className="app-btn app-btn-primary footer-btn"
                                                      >
                                                          <span className="button-text footer-button-text">
                                                              Submit for Approval
                                                          </span>
                                                      </a>
                                                  ) : null}
                                              </>
                                          );
                                      }
                                  })()}
                            <ShowForPermissions type="bp" permission="create">
                                {(profileType === 'agent' || profileType === 'forwarder') &&
                                    headerData?.bpStatus === 'Draft_Booking' && (
                                        <a
                                            title="Ready for Shipment"
                                            className={`app-btn app-btn-primary footer-btn ${
                                                validationBtn && nextBtn && nextAddBtn ? '' : 'disabled'
                                            }`}
                                            onClick={() => {
                                                if (validationBtn && nextBtn && nextAddBtn) {
                                                    submitReadyForShipment();
                                                }
                                            }}
                                        >
                                            <span className="button-text footer-button-text">Ready for Shipment</span>
                                        </a>
                                    )}
                            </ShowForPermissions>
                        </div>
                    )}
                </div>
            </div>
            <BasicDetailsModal
                modalView={showBasicDetails}
                setModalView={setShowBasicDetails}
                containerData={containerDetail}
                bpId={id}
                allocationId={bookingContainerId}
                containerDetailsId={containerDetailId}
                setCancelModalView={setCancelModalView}
                setValidation={setValidation}
                validation={validation}
                setHasUpdateCompleted={setHasUpdateCompleted}
                hasUpdateCompleted={hasUpdateCompleted}
            />

            <NewContainerModal
                modalView={showContainerCreateModal}
                setModalView={setShowContainerCreateModal}
                bpId={id}
                setHasUpdateCompleted={setHasUpdateCompleted}
                setCancelModalView={setCancelModalView}
                setValidation={setValidation}
                validation={validation}
                hasUpdateCompleted={hasUpdateCompleted}
            />

            {showDeleteModal ? (
                <DeleteConfirmModal
                    modalView={showDeleteModal}
                    setModalView={setShowDeleteModal}
                    bcPk={bookingContainerId}
                    setHasUpdateCompleted={setHasUpdateCompleted}
                    hasUpdateCompleted={hasUpdateCompleted}
                />
            ) : null}

            {showBookingCancelModal ? (
                headerData?.bpStatus === 'Booked' ||
                headerData?.bpStatus === 'Ready_for_Booking' ||
                headerData?.bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}

            {showPackingModal ? (
                <ContainerPackingModal
                    modalView={showPackingModal}
                    setModalView={setShowPackingModal}
                    bdPk={containerDetailId}
                />
            ) : null}

            <CancelModal
                displayFunction={() => {
                    showBasicDetails && setShowBasicDetails(false);
                    showContainerCreateModal && setShowContainerCreateModal(false);
                }}
                header="Booking Details"
                cancelModalVisibility={cancelModalView}
                setCancelModalVisibility={setCancelModalView}
            />
            <RejectBookingProposalModal
                modalView={rejectionModalView}
                setModalView={setRejectionModalView}
                header={{
                    bpNumber: headerData.bpNumber ?? '',
                    clientName: headerData.clientName ?? '',
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={{ profileType, userName }}
                reason={reason}
                setReason={setReason}
                headerText="Reject Booking Proposal"
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            <RejectBookingProposalModal
                modalView={assignAgentModalView}
                setModalView={setAssignAgentModalView}
                header={{
                    bpNumber: headerData.bpNumber != null ? headerData.bpNumber : '',
                    clientName: headerData.clientName != null ? headerData.clientName : '',
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={{ profileType: profileType, userName: userName }}
                reason={reasonRejection}
                setReason={setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={setRejectedBpForwarderModalView}
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            {displayModal && <UnavailableModel setdisplayModal={setdisplayModal} />}
        </div>
    );
};
export default Index;
