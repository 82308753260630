/* eslint-disable no-undefined */
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers';
import { BookingType } from 'src/utils/constants';
import {
    FilterDestinationTypeList,
    // FilterList,
    FilterModeList,
    Type,
} from 'src/pages/Dashboard/InsightsExpanded/enums';
import { useSelector, useDispatch } from 'react-redux';
import BSTooltip from 'src/components/common/BSTooltip';
import LineGraph from 'src/components/common/LineGraph';
import { makeApiCall } from 'src/pages/Dashboard/Insights/makeApiCall';
import { setInsightsMainPage, setInsightsSelectedType } from 'src/store/reducers/dashboard/insightsReducer';
import {
    setFilter as setAirListFilter,
    setSelectedDropDown as setSelectedBookingAirDropDown,
} from 'src/store/reducers/bookingProposalAir/listReducer';
import {
    setBookingList,
    setFilter,
    setSelectedDropDown as setSelectedBookingDropDown,
} from 'src/store/reducers/bookingProposal/bookingProposalListReducer';
import {
    setFilter as setAoListFilter,
    setSelectedDropDown as setSelectedBookingAoDropDown,
} from 'src/store/reducers/aobookingProposal/bookingProposalListReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    fetchFavourites,
    resetAddSuccessFlag,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/dashboard/insightsFavoritesReducer';
import { addOrRemoveFromFavs, checkIfAddedToFavs, typeOfFav } from '../../Insights/common';
import { clearDashboardInsightsFilters } from 'src/pages/Dashboard/Insights/clearDashboardInsightsFilters';

//Faheem
const Index = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [_typeState, setTypeState] = React.useState('');
    const { filter } = useSelector((state: RootState) => state.bookingProposalList);

    const { filter: airListFilter } = useSelector((state: RootState) => state.bookingProposalAirList);
    const { userName, profileType } = useUserProfile();
    const { favourites, isAddSuccess, isRemoveSuccess, isLoading } = useSelector(
        (state: RootState) => state.favourites
    );
    const { filter: aoListFilter } = useSelector((state: RootState) => state.aoBookingProposalList);
    //========================= Redux values =======================================//
    const { dashboardActiveBookings, insightsMode, insightsDestination, insightsDurationRange } = useSelector(
        (state: RootState) => state.dashboardInsights
    );
    const { selectedSearchItem } = useSelector((state: RootState) => state.dashboarddata);

    const dashboardActiveBookingsRedux = React.useMemo(() => {
        if (dashboardActiveBookings) {
            return dashboardActiveBookings;
        }
    }, [dashboardActiveBookings]);

    //=========================================================================//

    const plotGraphPoints = (type: string) => [
        [
            dashboardActiveBookingsRedux[type]?.key1?.pointName,
            dashboardActiveBookingsRedux[type]?.key1?.count,
            dashboardActiveBookingsRedux[type]?.key1?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key1?.dateTo,
            dashboardActiveBookingsRedux[type]?.key1?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key2?.pointName,
            dashboardActiveBookingsRedux[type]?.key2?.count,
            dashboardActiveBookingsRedux[type]?.key2?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key2?.dateTo,
            dashboardActiveBookingsRedux[type]?.key2?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key3?.pointName,
            dashboardActiveBookingsRedux[type].key3?.count,
            dashboardActiveBookingsRedux[type]?.key3?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key3?.dateTo,
            dashboardActiveBookingsRedux[type]?.key3?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key4?.pointName,
            dashboardActiveBookingsRedux[type].key4?.count,
            dashboardActiveBookingsRedux[type]?.key4?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key4?.dateTo,
            dashboardActiveBookingsRedux[type]?.key4?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key5?.pointName,
            dashboardActiveBookingsRedux[type].key5?.count,
            dashboardActiveBookingsRedux[type]?.key5?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key5?.dateTo,
            dashboardActiveBookingsRedux[type]?.key5?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key6?.pointName,
            dashboardActiveBookingsRedux[type].key6?.count,
            dashboardActiveBookingsRedux[type]?.key6?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key6?.dateTo,
            dashboardActiveBookingsRedux[type]?.key6?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key7?.pointName,
            dashboardActiveBookingsRedux[type].key7?.count,
            dashboardActiveBookingsRedux[type]?.key7?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key7?.dateTo,
            dashboardActiveBookingsRedux[type]?.key7?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key8?.pointName,
            dashboardActiveBookingsRedux[type].key8?.count,
            dashboardActiveBookingsRedux[type]?.key8?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key8?.dateTo,
            dashboardActiveBookingsRedux[type]?.key8?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key9?.pointName,
            dashboardActiveBookingsRedux[type].key9?.count,
            dashboardActiveBookingsRedux[type]?.key9?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key9?.dateTo,
            dashboardActiveBookingsRedux[type]?.key9?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key10?.pointName,
            dashboardActiveBookingsRedux[type].key10?.count,
            dashboardActiveBookingsRedux[type]?.key10?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key10?.dateTo,
            dashboardActiveBookingsRedux[type]?.key10?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key11?.pointName,
            dashboardActiveBookingsRedux[type].key11?.count,
            dashboardActiveBookingsRedux[type]?.key11?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key11?.dateTo,
            dashboardActiveBookingsRedux[type]?.key11?.pointName,
        ],
        [
            dashboardActiveBookingsRedux[type]?.key12?.pointName,
            dashboardActiveBookingsRedux[type].key12?.count,
            dashboardActiveBookingsRedux[type]?.key12?.dateFrom,
            dashboardActiveBookingsRedux[type]?.key12?.dateTo,
            dashboardActiveBookingsRedux[type]?.key12?.pointName,
        ],
    ];

    const currentFilters = {
        isFcl: insightsMode?.includes(FilterModeList.Fcl) ? true : false,
        isLcl: insightsMode?.includes(FilterModeList.Lcl) ? true : false,
        isBcn: insightsMode?.includes(FilterModeList.Bcn) ? true : false,
        isAir: insightsMode?.includes(FilterModeList.Air) ? true : false,
        isInternational: insightsDestination?.includes(FilterDestinationTypeList.International) ? true : false,
        isTriangle: insightsDestination?.includes(FilterDestinationTypeList.Triangle) ? true : false,
    };

    const handleClick = (type: string) => {
        dispatch(setInsightsMainPage('Active Bookings Details'));
        setTypeState(type);
        dispatch(setInsightsSelectedType(type));
        makeApiCall(
            dispatch,
            insightsMode,
            insightsDurationRange,
            insightsDestination,
            'activeBookingsDetails',
            type,
            '',
            selectedSearchItem,
            selectedSearchItem?.type
        );
    };

    const organizationProfileData = () => {
        if (selectedSearchItem?.type === 'Clients') {
            return {
                clientName: [selectedSearchItem?.name],
            };
        } else if (selectedSearchItem?.type === 'Agents') {
            return {
                agentName: [selectedSearchItem?.name],
            };
        } else if (selectedSearchItem?.type === 'Vendors') {
            return {
                vendorName: [selectedSearchItem?.name],
            };
        }
    };

    //========================= Function to determine to which page it should be navigated ==================//
    const navigateToWhichPage = (type: string, count: number) => {
        const bpTypeFilters = insightsMode
            ?.map((val: string) => {
                if (val === FilterModeList.Fcl) {
                    return 'FCL';
                }
                if (val === FilterModeList.Lcl) {
                    return 'LCL';
                }
                if (val === FilterModeList.Bcn) {
                    return 'BCN';
                }
                if (val === FilterModeList.Air) {
                    return 'Air';
                }
                return val;
            })
            ?.filter((value: string) => {
                return value && value !== 'Air';
            });
        // selectedSearchItem?.mode === 'SEA'
        //     ? bpTypeFilters.push('Sea')
        //     : selectedSearchItem?.mode === 'AIR'
        //     ? bpTypeFilters.push('Air')
        //     : null;
        if (selectedSearchItem?.mode === 'SEA') {
            bpTypeFilters.push('Sea');
        } else if (selectedSearchItem?.mode === 'AIR') {
            bpTypeFilters.push('Air');
        }
        if (type === 'po') {
            if (insightsMode?.length === 1) {
                if (insightsMode.includes(FilterModeList.Air)) {
                    dispatch(setBookingList(BookingType.poBookingAir));
                    dispatch(
                        setAirListFilter({
                            ...airListFilter,
                            bpType: ['Air'],
                            destinationType:
                                insightsDestination && insightsDestination.length && insightsDestination.length > 0
                                    ? insightsDestination
                                    : undefined,
                            bpStatus: 'Active',
                            bpId: selectedSearchItem ? selectedSearchItem.bpId : undefined,
                            poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                            clientName: selectedSearchItem?.type === 'Clients' ? [selectedSearchItem?.name] : undefined,
                            agentName: selectedSearchItem?.type === 'Agents' ? [selectedSearchItem?.name] : undefined,
                            vendorName: selectedSearchItem?.type === 'Vendors' ? [selectedSearchItem?.name] : undefined,
                        })
                    );
                    dispatch(
                        setSelectedBookingAirDropDown({
                            text: 'Active BPs',
                            count: count,
                        })
                    );
                } else {
                    dispatch(setBookingList(BookingType.poBooking));
                    dispatch(
                        setFilter({
                            ...filter,
                            destinationType:
                                insightsDestination && insightsDestination.length && insightsDestination.length > 0
                                    ? insightsDestination
                                    : undefined,
                            bpStatus: 'Active',
                            // bpType: ['FCL', 'LCL', 'BCN'],
                            bpType: bpTypeFilters,
                            bpId: selectedSearchItem ? selectedSearchItem.bpId : undefined,
                            poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                            ...organizationProfileData(),
                        })
                    );
                    dispatch(
                        setSelectedBookingDropDown({
                            text: 'Active BPs',
                            count: count,
                        })
                    );
                }
            } else {
                if (
                    insightsMode?.length === 0 ||
                    insightsMode.includes(FilterModeList.Fcl) ||
                    insightsMode.includes(FilterModeList.Lcl) ||
                    insightsMode.includes(FilterModeList.Bcn)
                ) {
                    dispatch(
                        selectedSearchItem?.mode === 'AIR'
                            ? setBookingList(BookingType.poBookingAir)
                            : setBookingList(BookingType.poBooking)
                    );
                    dispatch(
                        selectedSearchItem?.mode === 'AIR'
                            ? setAirListFilter({
                                  ...airListFilter,
                                  bpType: ['Air'],
                                  destinationType:
                                      insightsDestination &&
                                      insightsDestination.length &&
                                      insightsDestination.length > 0
                                          ? insightsDestination
                                          : undefined,
                                  bpStatus: 'Active',
                                  bpId: selectedSearchItem ? selectedSearchItem.bpId : undefined,
                                  poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                                  ...organizationProfileData(),
                              })
                            : setFilter({
                                  ...filter,
                                  bpType: bpTypeFilters,
                                  destinationType:
                                      insightsDestination &&
                                      insightsDestination.length &&
                                      insightsDestination.length > 0
                                          ? insightsDestination
                                          : undefined,
                                  bpStatus: 'Active',
                                  bpId: selectedSearchItem ? selectedSearchItem.bpId : undefined,
                                  poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                                  ...organizationProfileData(),
                              })
                    );
                    dispatch(
                        selectedSearchItem?.mode === 'AIR'
                            ? setSelectedBookingAirDropDown({
                                  text: 'Active BPs',
                                  count: count,
                              })
                            : setSelectedBookingDropDown({
                                  text: 'Active BPs',
                                  count: count,
                              })
                    );
                }
            }
        } else if (type === 'ao') {
            dispatch(setBookingList(BookingType.aoBooking));
            dispatch(
                setAoListFilter({
                    ...aoListFilter,
                    destinationType:
                        insightsDestination &&
                        insightsDestination.length &&
                        insightsDestination.length > 0 &&
                        insightsDestination.includes(FilterDestinationTypeList.International)
                            ? [FilterDestinationTypeList.International]
                            : undefined,
                    bpStatus: 'Active',
                    bpType: ['AO'],
                    bpId: selectedSearchItem ? selectedSearchItem.bpId : undefined,
                    poId: selectedSearchItem ? selectedSearchItem.poId : undefined,
                    ...organizationProfileData(),
                })
            );
            dispatch(
                setSelectedBookingAoDropDown({
                    text: 'Active BPs',
                    count: count,
                })
            );
        }
        navigate('/booking_list', { state: { reload: true } });
        clearDashboardInsightsFilters(dispatch);
    };
    //=======================================================================================================//

    useEffect(() => {
        if (isAddSuccess) {
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetAddSuccessFlag({}));
        }
    }, [isAddSuccess]);

    useEffect(() => {
        if (isRemoveSuccess) {
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag({}));
        }
    }, [isRemoveSuccess]);
    const addOrRemoveFromFavourites = (title: string) => {
        addOrRemoveFromFavs(dispatch, title, favourites, currentFilters, userName, typeOfFav.activeBookings);
    };

    const checkIfAddedToFavourites = (title: string) => {
        return checkIfAddedToFavs(title, favourites, currentFilters, typeOfFav.activeBookings);
    };

    return (
        <>
            <div className="category-tile-wrapper">
                {dashboardActiveBookingsRedux['po']?.count > 0 && (
                    <div className="category-tile-card">
                        <div className="category-nav">
                            <span className="category-nav-title">PO Bookings</span>
                            <div className="category-nav-flex">
                                <span className="category-nav-count">
                                    {dashboardActiveBookingsRedux['po']?.count || 0}
                                </span>
                                <div className="graph-holder">
                                    <LineGraph
                                        drawn={dashboardActiveBookingsRedux['po'] ? plotGraphPoints('po') : []}
                                        name="purchaseOrdersList"
                                        tooltip={true}
                                        lineDots={true}
                                        gradientFill={false}
                                        lineWidth={2}
                                        pointSize={2}
                                        tooltipTitle={'Active Bookings'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="graph-filters">
                            <BSTooltip
                                id="favourites"
                                title={
                                    checkIfAddedToFavourites('PO Bookings')
                                        ? 'Remove from Favourites'
                                        : 'Add to Favourites'
                                }
                                position="bottom"
                            >
                                {/* Replace the class name "unselected-fav-icon" to "selected-fav-icon" on click */}
                                <button
                                    className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                                        checkIfAddedToFavourites('PO Bookings')
                                            ? 'selected-fav-icon'
                                            : 'unselected-fav-icon'
                                    } `}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addOrRemoveFromFavourites('PO Bookings');
                                    }}
                                    disabled={isLoading}
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Favourites</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="reports" title="Go to Reports" position="bottom">
                                <button className="action-btn app-btn icon-only sm app-btn-secondary">
                                    <svg className="svg-icon">
                                        <use xlinkHref="#fileIcon">
                                            <title>Go to Reports</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="redirect" title="Go to Bookings Page" position="bottom">
                                <button
                                    className="action-btn app-btn icon-only sm app-btn-secondary"
                                    onClick={() =>
                                        navigateToWhichPage('po', dashboardActiveBookingsRedux?.['po']?.count)
                                    }
                                >
                                    <svg className="svg-icon">
                                        <use xlinkHref="#redirectIcon">
                                            <title>Redirect</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        </div>
                        <a className="tile-anchor" onClick={() => handleClick(Type.po)}>
                            &nbsp;
                        </a>
                    </div>
                )}
                {dashboardActiveBookingsRedux['ao']?.count > 0 && (
                    <div className="category-tile-card">
                        <div className="category-nav">
                            <span className="category-nav-title">AO Bookings</span>
                            <div className="category-nav-flex">
                                <span className="category-nav-count">
                                    {dashboardActiveBookingsRedux['ao']?.count || 0}
                                </span>
                                <div className="graph-holder">
                                    <LineGraph
                                        drawn={dashboardActiveBookingsRedux['ao'] ? plotGraphPoints('ao') : []}
                                        name="inboundPOs"
                                        tooltip={true}
                                        lineDots={true}
                                        gradientFill={false}
                                        lineWidth={2}
                                        pointSize={2}
                                        tooltipTitle={'Active Bookings'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="graph-filters">
                            <BSTooltip
                                id="favourites1"
                                title={
                                    checkIfAddedToFavourites('AO Bookings')
                                        ? 'Remove from Favourites'
                                        : 'Add to Favourites'
                                }
                                position="bottom"
                            >
                                {/* Replace the class name "unselected-fav-icon" to "selected-fav-icon" on click */}
                                <button
                                    className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                                        checkIfAddedToFavourites('AO Bookings')
                                            ? 'selected-fav-icon'
                                            : 'unselected-fav-icon'
                                    } `}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addOrRemoveFromFavourites('AO Bookings');
                                    }}
                                    disabled={isLoading}
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Favourites</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="reports1" title="Go to Reports" position="bottom">
                                <button className="action-btn app-btn icon-only sm app-btn-secondary">
                                    <svg className="svg-icon">
                                        <use xlinkHref="#fileIcon">
                                            <title>Go to Reports</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                            <BSTooltip id="redirect1" title="Go to Bookings Page" position="bottom">
                                <button
                                    className="action-btn app-btn icon-only sm app-btn-secondary"
                                    onClick={() =>
                                        navigateToWhichPage('ao', dashboardActiveBookingsRedux?.['ao']?.count)
                                    }
                                >
                                    <svg className="svg-icon">
                                        <use xlinkHref="#redirectIcon">
                                            <title>Redirect</title>
                                        </use>
                                    </svg>
                                </button>
                            </BSTooltip>
                        </div>
                        <a className="tile-anchor" onClick={() => handleClick(Type.ao)}>
                            &nbsp;
                        </a>
                    </div>
                )}
            </div>
            <div className="tab-content-body">
                <div className="graph-container">
                    <div className="tab-content-head">
                        <h5>Trends</h5>
                        <p>Showing trend for Last 12 {insightsDurationRange}</p>
                    </div>
                    <div className="graph-holder set-height">
                        <LineGraph
                            drawn={dashboardActiveBookingsRedux['total'] ? plotGraphPoints('total') : []}
                            name="activeOrdersLarge"
                            tooltip={true}
                            lineDots={true}
                            lineWidth={1}
                            pointSize={2}
                            graphAxis={true}
                            tooltipTitle={'Active Bookings'}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
