/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
type Props = {
    title: string;
    keyName: string;
    quoteData: any;
    setQuoteData: any;
    saveTriggered: boolean;
};

const QuoteItemMarkup: React.FC<Props> = ({ title, keyName, quoteData, setQuoteData, saveTriggered }) => {
    const [minMarkup, setMinMarkup] = useState<any>({});
    const [maxMarkup, setMaxMarkup] = useState<any>({});
    const [markupHigher, setMarkupHigher] = useState(true);

    useEffect(() => {
        if (quoteData) {
            setMinMarkup({ value: quoteData?.minMarkup?.value, percentage: quoteData?.minMarkup?.percentage });
            setMaxMarkup({ value: quoteData?.maxMarkup?.value, percentage: quoteData?.maxMarkup?.percentage });
            setMarkupHigher(quoteData?.markupMore);
        }
    }, [quoteData]);

    return (
        <>
            <div className="row-3-first">
                <div className="detail-box-common">
                    <h6 className="details-box-heading-2">
                        {title} <span className="mandatory-field-text">*</span>
                    </h6>
                    <div className="details-box-detail">
                        <div className="details-box-detail-single">
                            <label className="modal-input-label">
                                Minimum Markup <span className="mandatory-field-text">*</span>
                            </label>
                            <input
                                type="number"
                                onChange={(e) => {
                                    setMinMarkup(e.target.value !== '' ? +e.target.value : e.target.value);
                                    setQuoteData({
                                        ...quoteData,
                                        minMarkup: {
                                            value: e.target.value !== '' ? +e.target.value : e.target.value,
                                            percentage: quoteData?.minMarkup?.percentage,
                                        },
                                    });
                                }}
                                min={0}
                                className="input-text details-box-detail-text-box"
                                value={minMarkup?.value}
                                placeholder="Enter Minimum Markup"
                            />
                        </div>
                    </div>
                    {saveTriggered && typeof quoteData?.minMarkup?.value !== 'number' && (
                        <div className="error-text">This field is required</div>
                    )}
                </div>
                <div className="detail-box-common percentage-min">
                    <div className="details-box-detail">
                        <div className="details-box-detail-single">
                            <label className="modal-input-label">
                                Minimum Markup (% of Buy Cost) <span className="mandatory-field-text">*</span>
                            </label>
                            <input
                                type="number"
                                min={0}
                                onChange={(e) => {
                                    setMinMarkup(e.target.value !== '' ? +e.target.value : e.target.value);
                                    setQuoteData({
                                        ...quoteData,
                                        minMarkup: {
                                            value: quoteData?.minMarkup?.value,
                                            percentage: e.target.value !== '' ? +e.target.value : e.target.value,
                                        },
                                    });
                                }}
                                className="input-text details-box-detail-text-box"
                                value={minMarkup?.percentage}
                                placeholder="Enter Minimum Markup (% of Buy Cost)"
                            />
                        </div>
                    </div>
                    {saveTriggered && typeof quoteData?.minMarkup?.percentage !== 'number' && (
                        <div className="error-text">This field is required</div>
                    )}
                </div>
            </div>
            <div className="row-3-second">
                <div className="detail-box-common">
                    <div className="details-box-detail">
                        <div className="details-box-detail-single">
                            <label className="modal-input-label">
                                Maximum Markup <span className="mandatory-field-text">*</span>
                            </label>
                            <input
                                type="number"
                                min={0}
                                onChange={(e) => {
                                    setMaxMarkup(e.target.value !== '' ? +e.target.value : e.target.value);
                                    setQuoteData({
                                        ...quoteData,
                                        maxMarkup: {
                                            value: e.target.value !== '' ? +e.target.value : e.target.value,
                                            percentage: quoteData?.maxMarkup?.percentage,
                                        },
                                    });
                                }}
                                className="input-text details-box-detail-text-box"
                                value={maxMarkup?.value}
                                placeholder="Enter Maximum Markup"
                            />
                        </div>
                    </div>
                    {saveTriggered && typeof quoteData?.maxMarkup?.value !== 'number' && (
                        <div className="error-text">This field is required</div>
                    )}
                </div>
                <div className="detail-box-common">
                    <div className="details-box-detail">
                        <div className="details-box-detail-single">
                            <label className="modal-input-label">
                                Maximum Markup (% of Buy Cost) <span className="mandatory-field-text">*</span>
                            </label>
                            <input
                                type="number"
                                min={0}
                                onChange={(e) => {
                                    setMaxMarkup(e.target.value !== '' ? +e.target.value : e.target.value);
                                    setQuoteData({
                                        ...quoteData,
                                        maxMarkup: {
                                            value: quoteData?.maxMarkup?.value,
                                            percentage: e.target.value !== '' ? +e.target.value : e.target.value,
                                        },
                                    });
                                }}
                                className="input-text details-box-detail-text-box"
                                value={maxMarkup?.percentage}
                                placeholder="Enter Maximum Markup (% of Buy Cost)"
                            />
                        </div>
                    </div>
                    {saveTriggered && typeof quoteData?.maxMarkup?.percentage !== 'number' && (
                        <div className="error-text">This field is required</div>
                    )}
                </div>
                <div className="checkbox-wrapper-modal markup-checkbox modal-input-holder">
                    <div className="checkbox-item">
                        <label className="app-check-wrapper">
                            <input
                                type="checkbox"
                                className="checkbox-input"
                                name="markupHigher"
                                checked={markupHigher}
                                onChange={(e) => {
                                    setMarkupHigher(e.target.checked);
                                    setQuoteData({
                                        ...quoteData,
                                        markupMore: e.target.checked,
                                    });
                                }}
                            ></input>
                            <div className="checkmark">
                                <svg className="svg-icon notification-circle-icon">
                                    <use href="#tickIcon">
                                        <title>check mark</title>
                                    </use>
                                </svg>
                            </div>
                            <div style={{ marginTop: '2px' }} className="checkbox-label">
                                Markup Higher
                            </div>
                        </label>
                    </div>
                </div>
            </div>
        </>
    );
};

export default QuoteItemMarkup;
