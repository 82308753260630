import React, { useEffect, useState, useCallback } from 'react';
import '../../BookingProposal/BookingAdditionalInfo/booking-additional-info.scss';
import AddModal from './AddModal';
import RejectBookingProposalModal from './RejectBookingProposalModal';
import RejectBpClientModal from './RejectBpClientModal';

import { useAppDispatch, useAppSelector } from '../../../hooks';
import CancelModal from '../../../components/common/CancelModal';
// import { fetchIncoTermsDetails } from '../../../store/reducers/profiles/shippingLine/ContractDetailsReducer';

import {
    addContainerNac,
    deleteContainerNac,
    editContainerNac,
    fetchNacAdditionalDetails,
    resetSuccessFlags,
    setContainers,
} from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import {
    selectAdditionalDetailsLoading,
    // fetchCurrency,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { error, Position, success } from '../../../utils/toast';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SearchableSelectBox from '../../../components/common/SearchableSelectBox';
// import { downloadFile } from '../../../services/apis/bookingProposal/additionalDetailsNacApi';
import BookingEnrichmentAdditionalDetail from '../BookingEnrichmentAdditionalDetail';
import { fetchDetailsLcl } from 'src/store/reducers/bookingProposalLcl/additionalDetailsReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import PoReview from '../../BookingProposal/BookingAddedGrid/PurchaseOrderDetails';
import { downloadLclQuotationFile } from 'src/services/apis/bookingProposalLcl/additionalDetailsApi';
import moment from 'moment';
import ShowForPermissions from 'src/ShowForPermissions';
import DestinationAgentModal from 'src/pages/BookingProposal/BookingAdditionalInfo/DestinationAgentModal';
// import { fetchData } from 'src/store/reducers/data-reducer';
import { getDestAgent } from 'src/store/reducers/purchase_order/basicDetails';
// import AdditionalDetailModal from 'src/pages/BookingProposal/BookingAdditionalInfo/AdditionalDetailModal';
// import ContractViewModal from 'src/components/common/ContractViewModal';
// import AddUserAdditionalModal from 'src/pages/BookingProposal/BookingAdditionalInfo/AddUserAdditionalModal';
// import NoDataAdditionalDetailModal from 'src/pages/BookingProposal/BookingAdditionalInfo/NoDataAdditionalDetailModal';
// import ContractDetailModal from 'src/pages/BookingProposal/BookingAdditionalInfo/ContractDetailModal';
interface ModalViewProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
interface AdditionalInfoProps {
    rejection: ModalViewProps;
    reason: ModalViewProps;
    addDetails: ModalViewProps;
    contractDetails: ModalViewProps;
    rejectBp: ModalViewProps;
    reapproveBp: ModalViewProps;
    cancel: ModalViewProps;
    headerData: any;
    userProfile: { profileType: string; userName: string };
    bpId: string;
    additionalDetailsData: any;
    reasonState: any;
    rejectedBpForwarder: any;
    reasonRejection: any;
    assignAgent: any;
}

const Index: React.FC<AdditionalInfoProps> = ({
    rejection,
    reasonState,
    addDetails,
    headerData,
    userProfile,
    // contractDetails,
    bpId,
    rejectBp,
    cancel,
    // additionalDetailsData,
    rejectedBpForwarder,
    reasonRejection,
    assignAgent,
}) => {
    const dispatch = useAppDispatch();
    const [showFakAddModal, setShowFakAddModal] = useState(false);
    const { profileType } = useUserProfile();
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);
    const [isEdit, setIsEdit] = useState(-1);
    const [cdId, setCdId] = useState('');
    const [_containerModesModified, setContainerModesModified] = useState<any[]>([]);
    const [deliveryModesModified, setDeliveryModesModified] = useState<any[]>([]);
    const [containerTypesModified, setContainerTypesModified] = useState<any[]>([]);
    const [showModal, setShowModal] = useState(false); // destination agent modal
    const {
        additionalDetails,
        success: additionalSuccess,
        error: additonalError,
        errorMessage,
        containerTypes,
        containerModes,
        deliveryModes,
        containers,
        addOrEditContainerSuccess,
        addOrEditContainerError,
        containerDeleteSuccess,
        containerDeleteError,
    } = useSelector((state: RootState) => state.additionalDetailsNac);
    const {
        sailingReferenceNumber,
        // clientNames,
        additionalDetails: additionPrivateColoaderDetails,
        destinationAgentCode,
        destinationAgentName,
    } = useSelector((state: RootState) => state.additionalDetailsLcl);

    const { isTriangleShipment, destinationPort, coLoaderCode } = useSelector((state: RootState) => state.bpHeaderData);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });

    const getContainerMode = useCallback(
        () => containerModes?.find(({ containerMode }: any) => containerMode === 'LCL') || ({} as any),
        [containerModes]
    );

    const initialContainerValues = {
        containerTypeId: '',
        deliveryModeId: '',
        containerModeId: getContainerMode()?.containerModeId,
        cbm: 0,
        quantity: 0,
    };

    useEffect(() => {
        const containerModeData = containerModes?.map((option: any) => {
            return { value: option.containerModeId, label: option.containerMode };
        });
        setContainerModesModified(containerModeData ? containerModeData : []);

        const containeTypeData = containerTypes?.map((option: any) => {
            return { ...option, value: option.containerTypeId, label: `${option.description} (${option.code})` };
        });
        setContainerTypesModified(containeTypeData ? containeTypeData : []);

        const deliveyModeData = deliveryModes?.map((option: any) => {
            return { value: option.deliveryModeId, label: option.deliveryMode };
        });
        setDeliveryModesModified(deliveyModeData ? deliveyModeData : []);
    }, [containerModes]);

    useEffect(() => {
        const destCode = (destinationPort as any)?.split('(')[1]?.trim();
        const destCodeFinal = destCode && (destCode as any)?.split(')')[0]?.trim();
        if (destCodeFinal) {
            dispatch(getDestAgent(destCodeFinal));
        }
    }, [destinationPort]);

    const ContainerDataValidationSchema = Yup.object().shape({
        containerTypeId: Yup.string().required('Container type is required'),
        deliveryModeId: Yup.string().required('Delivery mode required'),
        containerModeId: Yup.string().required('Container mode is required'),
        quantity: Yup.number()
            .typeError('Quantity must be a number')
            .required('Quantity is required')
            .min(1, 'Quantity must be greater than zero'),
        cbm: Yup.number().required('CBM is required'),
    });

    const formik = useFormik({
        initialValues: initialContainerValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ContainerDataValidationSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                values.cbm = getCbm();
                const finalValues = JSON.parse(JSON.stringify(values));
                delete finalValues.containerTypeId;
                if (!cdId) {
                    dispatch(
                        addContainerNac({
                            ...finalValues,
                            bookingProposalId: bpId,
                            containerId: values.containerTypeId,
                        })
                    );
                } else {
                    dispatch(
                        editContainerNac({
                            cdId,
                            data: { ...finalValues, bookingProposalId: bpId, containerId: values.containerTypeId },
                        })
                    );
                }
            }
        },
    });

    // const updateAdditionalDetails = () => {
    //     setShowFakAddModal(true);
    // };

    useEffect(() => {
        dispatch(fetchDetailsLcl({ bpId }));
    }, []);

    const fetchData = () => {
        dispatch(fetchDetailsLcl({ bpId }));
    };

    const dispatchGetAdditionalDetails = () => {
        dispatch(fetchNacAdditionalDetails({ bpId }));
    };
    useEffect(() => {
        if (userProfile.profileType === 'agent') {
            dispatchGetAdditionalDetails();
        }
    }, []);

    useEffect(() => {
        // dispatch(fetchCurrency({}));
    }, []);

    useEffect(() => {
        if (additionalSuccess) {
            dispatch(resetSuccessFlags({}));
            success('Additional Details has been updated', Position.TOP_RIGHT);
            setShowFakAddModal(false);
            dispatchGetAdditionalDetails();
        }
    }, [additionalSuccess]);

    useEffect(() => {
        if (additonalError || addOrEditContainerError || containerDeleteError) {
            error(errorMessage || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetSuccessFlags({}));
        }
    }, [additonalError, addOrEditContainerError, containerDeleteError]);

    useEffect(() => {
        if (addOrEditContainerSuccess) {
            formik.setValues(initialContainerValues);
            if (cdId) {
                success('Container Details updated', Position.TOP_RIGHT);
                setCdId('');
            } else {
                const modifiedContainer = JSON.parse(JSON.stringify(containers));
                modifiedContainer.pop();
                dispatch(setContainers(modifiedContainer));
                success('Container Details added', Position.TOP_RIGHT);
            }
            setIsEdit(-1);
            dispatch(resetSuccessFlags({}));
            dispatchGetAdditionalDetails();
        }
    }, [addOrEditContainerSuccess]);

    useEffect(() => {
        if (containerDeleteSuccess) {
            dispatch(resetSuccessFlags({}));
            success('Container deleted', Position.TOP_RIGHT);
            dispatchGetAdditionalDetails();
        }
    }, [containerDeleteSuccess]);

    const getCbm = () => {
        return Math.round(
            formik.values['quantity'] *
                containerTypesModified.find((option: any) => option.value === formik.values['containerTypeId'])
                    ?.capacity || 0
        );
    };

    return (
        <>
            <div className="booking-additional-detail-wrapper lcl-booking-additional-detail">
                {isLoading /* && !sailingReferenceNumber */ && (
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {/* {userProfile.profileType !== 'client' && (
                    <div className="card details-box custom">
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Client</h6>
                                <div className="details-box-content">
                                    <div className="suggestion-container">
                                        {clientNames?.map((client: string, indx: number) => (
                                            <div className="content-card" key={indx}>
                                                <span className="main-content">{client}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}

                {/*commented for make same as fcl*/}
                {
                    /* sailingReferenceNumber ||  */ (coLoaderCode !== process.env.REACT_APP_ORG_CODE ||
                        profileType !== 'client') && (
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <ShowForPermissions type="bp" permission="create">
                                    <div className="card-head-btn-edit-holder">
                                        <h6 className="details-box-heading-2">Additional Details</h6>
                                        {profileType === 'forwarder' &&
                                        isTriangleShipment &&
                                        destinationAgentCode &&
                                        (headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                            headerData.bpStatus === 'Draft_Booking_Proposal') ? (
                                            <button
                                                onClick={() => setShowModal(true)}
                                                type="button"
                                                className="action-btn app-btn app-btn-secondary "
                                                title="Edit Details"
                                            >
                                                <svg style={{ width: '100%' }} className="svg-icon edit-icon">
                                                    <use xlinkHref="#editIcon"></use>
                                                </svg>
                                            </button>
                                        ) : null}
                                    </div>
                                </ShowForPermissions>
                                <ShowForPermissions type="bp" permission="create">
                                    {profileType === 'forwarder' &&
                                    isTriangleShipment &&
                                    !destinationAgentCode &&
                                    (headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                        headerData.bpStatus === 'Draft_Booking_Proposal') ? (
                                        <div className="btn-holder">
                                            <button
                                                onClick={() => setShowModal(true)}
                                                className="app-btn app-btn-secondary icon-button"
                                                title="Add Details"
                                            >
                                                <svg className="svg-icon add-btn-icon">
                                                    <use xlinkHref="#plusIcon"></use>
                                                </svg>
                                                <span className="button-text">Add Details</span>
                                            </button>
                                        </div>
                                    ) : null}
                                </ShowForPermissions>
                            </div>
                            <div className="card-detail-col">
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">Sailing Reference No.</h6>
                                    <div className="details-box-content">{sailingReferenceNumber || '-'}</div>
                                </div>
                                {(profileType === 'forwarder' ||
                                    (profileType === 'agent' &&
                                        (headerData?.bpStatus === 'Awaiting Client Approval' ||
                                            headerData?.bpStatus === 'Ready_for_Booking' ||
                                            headerData?.bpStatus === 'Draft_Booking' ||
                                            headerData?.bpStatus === 'Booked' ||
                                            headerData?.bpStatus === 'In_transit' ||
                                            headerData?.bpStatus === 'Delivered' ||
                                            headerData?.bpStatus === 'Cancelled'))) &&
                                // headerData?.bpStatus !== 'Draft_Booking_Proposal' &&
                                isTriangleShipment ? (
                                    <div className="details-col box-content-holder">
                                        <h6 className="details-box-heading">
                                            Destination Agent
                                            <span className="mandatory-field-text">*</span>
                                        </h6>
                                        <div className="details-box-content">{destinationAgentName || '-'}</div>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    )
                }

                {/*commented for make same as fcl*/}
                {/*only if forwarder*/}
                {/* Contract details to be displayed only for IFC Coloader */}

                {/* {coLoaderCode === process.env.REACT_APP_ORG_CODE ? (
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder" style={{ paddingBottom: '12px' }}>
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">Contract Details</h6>
                                {headerData.bpStatus !== 'Booked' && (
                                    <ShowForPermissions type="bp" permission="create">
                                        {userProfile.profileType === 'agent' &&
                                            headerData?.bpStatus !== 'Cancelled' &&
                                            additionalDetails?.bookingContractNo && (
                                                <button
                                                    onClick={() => updateAdditionalDetails()}
                                                    type="button"
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit Details"
                                                >
                                                    <svg style={{ width: '100%' }} className="svg-icon edit-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                    </ShowForPermissions>
                                )}
                            </div>
                            <ShowForPermissions type="bp" permission="create">
                                {userProfile.profileType === 'agent' &&
                                !additionalDetails?.bookingContractNo &&
                                headerData?.bpStatus !== 'Cancelled' ? (
                                    <div className="btn-holder">
                                        <button
                                            onClick={() => updateAdditionalDetails()}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    </div>
                                ) : null}
                            </ShowForPermissions>
                        </div>
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Booking Contract No.<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{additionalDetails?.bookingContractNo || '-'}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Contract Type</h6>
                                <div className="details-box-content">
                                    {additionalDetails?.contractType === 'NAC' ? (
                                        <div
                                            className={`status-ui nac-status ${
                                                additionalDetails?.clientType === 'p' ? 'nac-p' : 'nac-s'
                                            }`}
                                        >
                                            <div className="status-nac-left">{additionalDetails?.contractType}</div>
                                            <div
                                                className={`status-nac-right ${
                                                    additionalDetails?.clientType !== 'P' ? 's-status' : 'p-status'
                                                }`}
                                            >
                                                {additionalDetails?.clientType}
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="status-ui confirmed-status">FAK</div>
                                    )}
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Quotation (Buy)
                                    <span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    <a
                                        href="javascript:void(0)"
                                        className="details-box-content link-url"
                                        onClick={() => {
                                            if (additionalDetails?.savedFakFileName) {
                                                downloadFile(bpId, additionalDetails?.savedFakFileName);
                                            }
                                        }}
                                    >
                                        {additionalDetails?.savedFakFileName}
                                    </a>
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Total Origin Cost (Buy)
                                    <span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {additionalDetails?.totOriginCost && additionalDetails?.totOriginCostCurrency
                                        ? `${additionalDetails?.totOriginCost} ${additionalDetails?.totOriginCostCurrency}`
                                        : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null} */}

                {/*commented for make same as fcl*/}
                {
                    /* sailingReferenceNumber &&  */ coLoaderCode !== process.env.REACT_APP_ORG_CODE &&
                    additionPrivateColoaderDetails?.length ? (
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="card-head-btn-edit-holder">
                                    <h6 className="details-box-heading-2">Quote Details</h6>
                                </div>
                            </div>
                            <div className="card-detail-col">
                                <div className="main-content-holder">
                                    <div className="grid-section additional_grid">
                                        <div className="grid-holder">
                                            <div className="grid-container">
                                                <div className="grid-scroll-content">
                                                    <table className="grid-table">
                                                        <thead>
                                                            <tr className="grid-sticky-row">
                                                                <th className="grid-cell-header col-add-po-number">
                                                                    <div className="grid-cell-data">
                                                                        <span className="header-title">PO NUMBER</span>
                                                                    </div>
                                                                </th>
                                                                <th className="grid-cell-header col-add-client">
                                                                    <div className="grid-cell-data">
                                                                        <span className="header-title">CLIENT</span>
                                                                    </div>
                                                                </th>
                                                                <th className="grid-cell-header col-add-grd">
                                                                    <div className="grid-cell-data">
                                                                        <span className="header-title">
                                                                            GOODS READY DATE
                                                                        </span>
                                                                    </div>
                                                                </th>
                                                                {(profileType === 'agent' ||
                                                                    profileType === 'forwarder') && (
                                                                    <>
                                                                        <th className="grid-cell-header col-app-cost">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    LCL BUY COST
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-app-quote">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    LCL BUY QUOTATION
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    </>
                                                                )}
                                                                {(profileType === 'forwarder' ||
                                                                    profileType === 'client') && (
                                                                    <>
                                                                        <th className="grid-cell-header col-app-cost">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    LCL SELL COST
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-app-quote">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    LCL SELL QUOTATION
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    </>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {additionPrivateColoaderDetails?.map(
                                                                (data: any, ind: number) => (
                                                                    <tr className="has-child row-expanded" key={ind}>
                                                                        <td className="grid-cell">
                                                                            <div className="grid-cell-data">
                                                                                <a
                                                                                    href="javascript:void(0)"
                                                                                    className="booking-proposal-link"
                                                                                    onClick={() =>
                                                                                        setpoDisplay({
                                                                                            status: true,
                                                                                            id: data?.poId,
                                                                                        })
                                                                                    }
                                                                                >
                                                                                    {data?.poNumber}
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell">
                                                                            <div className="grid-cell-data">
                                                                                {data?.client}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell">
                                                                            <div className="grid-cell-data">
                                                                                {data?.goodsReadyDate
                                                                                    ? moment(
                                                                                          data?.goodsReadyDate
                                                                                      ).format('DD-MM-YYYY')
                                                                                    : '-'}
                                                                            </div>
                                                                        </td>
                                                                        {profileType === 'forwarder' && (
                                                                            <>
                                                                                <td className="grid-cell">
                                                                                    <div className="grid-cell-data">
                                                                                        {data?.lclBuyCost}
                                                                                    </div>
                                                                                </td>
                                                                                <td className="grid-cell">
                                                                                    <div
                                                                                        className="grid-cell-data"
                                                                                        onClick={() => {
                                                                                            if (data?.lclBuyQuotation) {
                                                                                                downloadLclQuotationFile(
                                                                                                    data?.confirmationGroupId,
                                                                                                    data?.lclBuyQuotation,
                                                                                                    data?.lclBuyQuotationFileId
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    >
                                                                                        <a
                                                                                            href="javascript:void(0)"
                                                                                            className="booking-proposal-link"
                                                                                        >
                                                                                            {data?.lclBuyQuotation}
                                                                                        </a>
                                                                                    </div>
                                                                                </td>
                                                                            </>
                                                                        )}
                                                                        <td className="grid-cell">
                                                                            <div className="grid-cell-data">
                                                                                {data?.approvedLclCost}
                                                                            </div>
                                                                        </td>
                                                                        <td className="grid-cell">
                                                                            <div
                                                                                className="grid-cell-data"
                                                                                onClick={() => {
                                                                                    if (data?.approvedLclQuotation) {
                                                                                        downloadLclQuotationFile(
                                                                                            data?.confirmationGroupId,
                                                                                            data?.approvedLclQuotation,
                                                                                            data?.approvedLclQuotationFileId
                                                                                        );
                                                                                    }
                                                                                }}
                                                                            >
                                                                                <a
                                                                                    href="javascript:void(0)"
                                                                                    className="booking-proposal-link"
                                                                                >
                                                                                    {data?.approvedLclQuotation}
                                                                                </a>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }

                {/*commented for make same as fcl*/}
                {headerData?.bpStatus && (
                    <>
                        {' '}
                        {headerData?.bpStatus === 'Ready_for_Booking' ||
                        headerData?.bpStatus === 'Draft_Booking' ||
                        headerData?.bpStatus === 'Booked' ? (
                            <BookingEnrichmentAdditionalDetail />
                        ) : (
                            <div className="card details-box custom">
                                <div className="card-head-holder add-btn-holder">
                                    <div className="card-head-btn-edit-holder">
                                        <h6 className="details-box-heading-2">Container Details</h6>
                                    </div>
                                    <div className="btn-holder">
                                        <ShowForPermissions type="bp" permission="create">
                                            {
                                                /* !sailingReferenceNumber && */
                                                coLoaderCode === process.env.REACT_APP_ORG_CODE &&
                                                    userProfile.profileType === 'agent' &&
                                                    (headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                        headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                        headerData.bpStatus === 'Draft_Booking_Proposal') && (
                                                        <button
                                                            className="app-btn app-btn-secondary icon-button"
                                                            title="Add Container"
                                                            disabled={
                                                                !!containers?.length &&
                                                                isEdit === containers?.length - 1 &&
                                                                !cdId
                                                            }
                                                            onClick={() => {
                                                                setCdId('');
                                                                formik.setValues(initialContainerValues);
                                                                setIsEdit(containers.length);
                                                                const modifiedContainer = JSON.parse(
                                                                    JSON.stringify(containers)
                                                                );
                                                                modifiedContainer.push({});
                                                                dispatch(setContainers(modifiedContainer));
                                                            }}
                                                        >
                                                            <svg className="svg-icon add-btn-icon">
                                                                <use xlinkHref="#plusIcon"></use>
                                                            </svg>
                                                            <span className="button-text">Add Container</span>
                                                        </button>
                                                    )
                                            }
                                        </ShowForPermissions>
                                    </div>
                                </div>
                                <div className="card-detail-col">
                                    <div className="main-content-holder">
                                        <div className="grid-section container_grid">
                                            <div className="grid-holder">
                                                <div className="grid-container">
                                                    {!containers?.length &&
                                                    coLoaderCode ===
                                                        process.env
                                                            .REACT_APP_ORG_CODE /* && !sailingReferenceNumber */ ? (
                                                        <div className="grid-no-content ">
                                                            <svg className="svg-icon grid-no-content-icon ">
                                                                <use xlinkHref="#gridNoContentIcon"></use>
                                                            </svg>
                                                            <p className="no-content-message">No Containers Added</p>
                                                        </div>
                                                    ) : null}
                                                    {coLoaderCode !== process.env.REACT_APP_ORG_CODE ? (
                                                        <div className="grid-scroll-content">
                                                            <form
                                                                id="container-form"
                                                                action=""
                                                                onSubmit={formik.handleSubmit}
                                                            >
                                                                <table className="grid-table">
                                                                    <thead>
                                                                        <tr className="grid-sticky-row">
                                                                            <th className="grid-cell-header col-container-type">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER TYPE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-delivery-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        DELIVERY MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-container-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-quantity">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        QUANTITY
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr className="has-child row-expanded">
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    Unknown
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    CFS/CFS
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    LCL
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">1</div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </div>
                                                    ) : (
                                                        <div className="grid-scroll-content">
                                                            <form
                                                                id="container-form"
                                                                action=""
                                                                onSubmit={formik.handleSubmit}
                                                            >
                                                                <table className="grid-table">
                                                                    <thead>
                                                                        <tr className="grid-sticky-row">
                                                                            <th className="grid-cell-header col-container-type">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER TYPE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-delivery-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        DELIVERY MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-container-mode">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CONTAINER MODE
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-quantity">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        QUANTITY
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <th className="grid-cell-header col-cbm">
                                                                                <div className="grid-cell-data">
                                                                                    <span className="header-title">
                                                                                        CBM
                                                                                    </span>
                                                                                </div>
                                                                            </th>
                                                                            <ShowForPermissions
                                                                                type="bp"
                                                                                permission="create"
                                                                            >
                                                                                {userProfile.profileType === 'agent' &&
                                                                                    (headerData.bpStatus ===
                                                                                        'Rejected_by_Forwarder' ||
                                                                                        headerData.bpStatus ===
                                                                                            'Rejected_by_Client_Agent_to_Action' ||
                                                                                        headerData.bpStatus ===
                                                                                            'Draft_Booking_Proposal') && (
                                                                                        <th className="grid-cell-header col-actions">
                                                                                            <div className="grid-cell-data">
                                                                                                <span className="header-title">
                                                                                                    ACTIONS
                                                                                                </span>
                                                                                            </div>
                                                                                        </th>
                                                                                    )}
                                                                            </ShowForPermissions>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody>
                                                                        {containers?.map((item: any, index: number) => {
                                                                            return isEdit === index ? (
                                                                                <tr className="has-child row-expanded inline-height">
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder">
                                                                                                <SearchableSelectBox
                                                                                                    data={
                                                                                                        containerTypesModified?.length
                                                                                                            ? containerTypesModified
                                                                                                            : []
                                                                                                    }
                                                                                                    placeholder="Enter container type"
                                                                                                    formik={formik}
                                                                                                    fieldName="containerTypeId"
                                                                                                    customStyle={''}
                                                                                                    menuPosition="fixed"
                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                        'outside_div'
                                                                                                    )}
                                                                                                    closeMenuOnScroll={
                                                                                                        true
                                                                                                    }
                                                                                                />
                                                                                                {formik.errors
                                                                                                    .containerTypeId ? (
                                                                                                    <div className="error-text">
                                                                                                        {
                                                                                                            formik
                                                                                                                .errors
                                                                                                                .containerTypeId
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder delivery-mode-dropdown">
                                                                                                <SearchableSelectBox
                                                                                                    data={
                                                                                                        deliveryModesModified?.length
                                                                                                            ? deliveryModesModified
                                                                                                            : []
                                                                                                    }
                                                                                                    placeholder="Enter delivery mode"
                                                                                                    formik={formik}
                                                                                                    fieldName="deliveryModeId"
                                                                                                    customStyle={''}
                                                                                                    menuPosition="fixed"
                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                        'outside_div'
                                                                                                    )}
                                                                                                    closeMenuOnScroll={
                                                                                                        true
                                                                                                    }
                                                                                                />
                                                                                                {formik.errors
                                                                                                    .deliveryModeId ? (
                                                                                                    <div className="error-text">
                                                                                                        {
                                                                                                            formik
                                                                                                                .errors
                                                                                                                .deliveryModeId
                                                                                                        }
                                                                                                    </div>
                                                                                                ) : null}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                getContainerMode()
                                                                                                    ?.containerMode
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <input
                                                                                                placeholder="Enter quantity"
                                                                                                type="text"
                                                                                                name={'quantity'}
                                                                                                className="search-input input-text input-qty"
                                                                                                onChange={(event) => {
                                                                                                    // eslint-disable-next-line no-undefined
                                                                                                    formik.setFieldError(
                                                                                                        event.target
                                                                                                            .name,
                                                                                                        // eslint-disable-next-line no-undefined
                                                                                                        undefined
                                                                                                    );
                                                                                                    formik.handleChange(
                                                                                                        event
                                                                                                    );
                                                                                                }}
                                                                                                onKeyDown={(e) => {
                                                                                                    if (
                                                                                                        e.key ===
                                                                                                        'Enter'
                                                                                                    ) {
                                                                                                        e.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                                onKeyPress={(event) => {
                                                                                                    if (
                                                                                                        !/[0-9]/.test(
                                                                                                            event.key
                                                                                                        )
                                                                                                    ) {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                                value={
                                                                                                    formik.values[
                                                                                                        'quantity'
                                                                                                    ]
                                                                                                }
                                                                                            ></input>
                                                                                            {formik.errors.quantity ? (
                                                                                                <div className="error-text">
                                                                                                    {
                                                                                                        formik.errors
                                                                                                            .quantity
                                                                                                    }
                                                                                                </div>
                                                                                            ) : null}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {getCbm()}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <button
                                                                                            className="view-nav grid-icon icon-nav "
                                                                                            title="Save"
                                                                                            type="submit"
                                                                                            form="container-form"
                                                                                        >
                                                                                            <svg className="svg-icon save-icon">
                                                                                                <use xlinkHref="#saveIcon"></use>
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button
                                                                                            className="view-nav grid-icon icon-nav "
                                                                                            title="Remove Container"
                                                                                            onClick={(e) => {
                                                                                                e.preventDefault();
                                                                                                dispatch(
                                                                                                    deleteContainerNac({
                                                                                                        bookingProposalId:
                                                                                                            bpId,
                                                                                                        cdId: item.containerId,
                                                                                                    })
                                                                                                );
                                                                                            }}
                                                                                        >
                                                                                            <svg className="svg-icon eye-icon ">
                                                                                                <use xlinkHref="#deleteIcon"></use>
                                                                                            </svg>
                                                                                        </button>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr className="has-child row-expanded">
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {`${item?.container?.description} (${item?.container?.code})`}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                item.deliveryMode
                                                                                                    ?.deliveryMode
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                item.containerMode
                                                                                                    ?.containerMode
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {item.quantity}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {Math.round(
                                                                                                item.quantity *
                                                                                                    item.container
                                                                                                        ?.capacity || 0
                                                                                            )}
                                                                                        </div>
                                                                                    </td>
                                                                                    <ShowForPermissions
                                                                                        type="bp"
                                                                                        permission="create"
                                                                                    >
                                                                                        {userProfile.profileType ===
                                                                                            'agent' &&
                                                                                            (headerData.bpStatus ===
                                                                                                'Rejected_by_Forwarder' ||
                                                                                                headerData.bpStatus ===
                                                                                                    'Rejected_by_Client_Agent_to_Action' ||
                                                                                                headerData.bpStatus ===
                                                                                                    'Draft_Booking_Proposal') && (
                                                                                                <td className="grid-cell">
                                                                                                    <button
                                                                                                        className="view-nav grid-icon icon-nav"
                                                                                                        title="Edit"
                                                                                                        type="button"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.preventDefault();
                                                                                                            if (
                                                                                                                isEdit ===
                                                                                                                    containers?.length -
                                                                                                                        1 &&
                                                                                                                !cdId
                                                                                                            ) {
                                                                                                                const modifiedContainer =
                                                                                                                    JSON.parse(
                                                                                                                        JSON.stringify(
                                                                                                                            containers
                                                                                                                        )
                                                                                                                    );
                                                                                                                modifiedContainer.pop();
                                                                                                                dispatch(
                                                                                                                    setContainers(
                                                                                                                        modifiedContainer
                                                                                                                    )
                                                                                                                );
                                                                                                            }
                                                                                                            setCdId(
                                                                                                                item.containerId
                                                                                                            );
                                                                                                            setIsEdit(
                                                                                                                index
                                                                                                            );
                                                                                                            formik.setValues(
                                                                                                                {
                                                                                                                    containerTypeId:
                                                                                                                        item
                                                                                                                            .container
                                                                                                                            ?.containerTypeId,
                                                                                                                    deliveryModeId:
                                                                                                                        item
                                                                                                                            .deliveryMode
                                                                                                                            ?.deliveryModeId,
                                                                                                                    containerModeId:
                                                                                                                        item
                                                                                                                            .containerMode
                                                                                                                            ?.containerModeId,
                                                                                                                    cbm: item
                                                                                                                        .container
                                                                                                                        ?.capacity,
                                                                                                                    quantity:
                                                                                                                        item.quantity,
                                                                                                                }
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <svg className="svg-icon edit-icon ">
                                                                                                            <use xlinkHref="#editIcon"></use>
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                    <button
                                                                                                        className="view-nav grid-icon icon-nav "
                                                                                                        title="Remove Container"
                                                                                                        onClick={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            e.preventDefault();
                                                                                                            dispatch(
                                                                                                                deleteContainerNac(
                                                                                                                    {
                                                                                                                        bookingProposalId:
                                                                                                                            bpId,
                                                                                                                        cdId: item.containerId,
                                                                                                                    }
                                                                                                                )
                                                                                                            );
                                                                                                        }}
                                                                                                    >
                                                                                                        <svg className="svg-icon eye-icon ">
                                                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                </td>
                                                                                            )}
                                                                                    </ShowForPermissions>
                                                                                </tr>
                                                                            );
                                                                        })}
                                                                    </tbody>
                                                                </table>
                                                            </form>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            {showModal ? (
                <DestinationAgentModal
                    agentCode={destinationAgentCode || ''}
                    setShowModal={setShowModal}
                    isAdd={!destinationAgentCode ? true : false}
                    callFetchFunction={fetchData}
                ></DestinationAgentModal>
            ) : null}
            {showFakAddModal && <AddModal data={additionalDetails} setShowAddModal={setShowFakAddModal}></AddModal>}
            <RejectBookingProposalModal
                modalView={rejection.modalView}
                setModalView={rejection.setModalView}
                header={{
                    bpNumber: headerData.bpNumber,
                    clientName: headerData.clientName,
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={userProfile}
                reason={reasonState.reason}
                setReason={reasonState.setReason}
                headerText="Reject Booking Proposal"
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            <RejectBpClientModal
                modalView={rejectBp.modalView}
                setModalView={rejectBp.setModalView}
                reason={headerData.reason}
                setReason={reasonState.setReason}
            />
            <CancelModal
                displayFunction={() => {
                    addDetails.setModalView(false);
                }}
                header="Additional Details"
                cancelModalVisibility={cancel.modalView}
                setCancelModalVisibility={cancel.setModalView}
            />
            <RejectBookingProposalModal
                modalView={assignAgent.assignAgentModalView}
                setModalView={assignAgent.setAssignAgentModalView}
                header={{
                    bpNumber: headerData.bpNumber != null ? headerData.bpNumber : '',
                    clientName: headerData.clientName != null ? headerData.clientName : '',
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={{ profileType: userProfile.profileType, userName: userProfile.userName }}
                reason={reasonRejection.reasonRejection}
                setReason={reasonRejection.setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={rejectedBpForwarder.setRejectedBpForwarderModalView}
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}
        </>
    );
};
export default Index;
