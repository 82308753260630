import { get } from '../HttpMethods';
import fileDownload from 'js-file-download';

export interface vendorData {
    orgId: string;
    orgName: string;
    orgCode: string;
    orgStatus: string;
    parentOrgCode: string;
    vendorId: string;
    vendorStatus: string;
    vendorCountry: string;
    relationship: string;
    childVendors: vendorData[];
}

export interface vendorListResponse {
    vendorResponseLists: vendorData[];
    totalNoOfPages: number;
    totalNoOfItems: number;
}

export interface vendorSearchData {
    content: vendorData[];
    totalPages: number;
    totalElements: number;
}
// Get All Datas
export const getVendorListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    profile: string;
    orgCode: string;
    cvId: any;
}) => {
    try {
        const res = await get(
            `api/mgt/vendors/allvendors?limit=${payload.perPageCount}&page=${payload.currentPage}&sortby=${
                payload.columnStatus
            }&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }&profile=${payload.profile ?? ''}&orgCode=${payload.orgCode ?? ''}&cvId=${payload.cvId ?? ''}`,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const exportVendorList = async (type: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/vendors/export?type=${type} `, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'VendorProfiles.xlsx' : 'VendorProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
export const exportVendorListProfiles = async (type: string, organisationData: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        const selectedFilter = organisationData?.selectedFilter;
        const searchTerm = organisationData?.searchTerm;
        const columnOrder = organisationData?.sortPayload['columnOrder'];
        const columnStatus = organisationData?.sortPayload['columnStatus'];
        const profile = organisationData?.profile;
        const orgCode = organisationData?.orgCode;
        const cvId = organisationData?.cvId;
        const isForwarder = organisationData?.isForwarder;
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/mgt/vendors/export?type=${type}${
                selectedFilter ? '&filterby=' + selectedFilter : ''
            }${
                searchTerm ? '&keyword=' + searchTerm : ''
            }&sortColumn=${columnStatus}&sortOrder=${columnOrder}&profile=${profile}&orgCode=${orgCode}&cvId=${cvId}&isForwarder=${isForwarder} `,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'VendorProfiles.xlsx' : 'VendorProfiles.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchVendors = async (payload: {
    keyword: string;
    perPageCount: number;
    currentPage: number;
    columnStatus: string;
    columnOrder: boolean;
    statusFilter: string;
    isForwarder: boolean;
    profile: string;
}) => {
    try {
        const res = await get(
            `api/mgt/search/vendor?keyword=${payload.keyword}&size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus}&sortorder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.statusFilter !== null ? `&filterby=${payload.statusFilter}` : ''
            }&isForwarder=${payload?.isForwarder}&profile=${payload?.profile}`,
            null,
            null,
            true
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};
