import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

interface GridLayoutProps {
    keyword: string;
    filter?: string;
    extraData?: string;
    setLoader?: any;
    overrideDiv?: string;
    customUrl?: boolean;
    onGridLoad?: any;
}
const baseURL = process.env.REACT_APP_BASE_URL;

const GridLayout = (gridLayoutProps: GridLayoutProps) => {
    const token = `Bearer ${localStorage.getItem('token') ?? ''}`;
    if (!token) {
        return null;
    }
    //@ts-ignore
    window.hideLoader = () => {
        gridLayoutProps?.setLoader(false);
        gridLayoutProps?.onGridLoad && gridLayoutProps?.onGridLoad();
    };

    //@ts-ignore
    let params = new URL(document.location).searchParams;
    let filter: any = params.get('filter');

    return (
        <Helmet>
            <script>
                {`

                

                function splitString(field) {
                    if(!field) return ""

                    var valuesArray = field.split(',');
                    let r = ""
                    for (var i = 0; i < valuesArray.length; i++) { 
                        r += "<div>"+ valuesArray[i].trim() + "</div>"
                    }
                        console.log(r)
                    return r
                }


                function collapseAll(){
                    var grid = $("#${gridLayoutProps.overrideDiv || 'grid'}").data("kendoGrid")
                    $(".k-grouping-row").each(function(e){grid.collapseGroup($(this))})
                }
                
                    function updloadSettings() {
                        
                        let endpoint = '${gridLayoutProps.keyword}';
                        const gridParam = endpoint.replaceAll('/', '~');
            
                        var model, columns;
                        const host = '${externalBaseUrl}/cix';
                        const hostSub = '${gridLayoutProps?.customUrl ? '/' : '/scr/'}';
                        const baseUrl = host + hostSub + endpoint + '/';
                        const headers = {
                            Authorization: '${token}',
                        };
                        kendo.prompt('Please enter a filter name', '').then(
                            function (data) {
                                var settings = {
                                    url: host + '/user/me/userdata/',
                                    method: 'POST',
                                    timeout: 0,
                                    headers: {
                                        Authorization: '${token}',
                                        'Content-Type': 'application/json',
                                    },
                                    data: JSON.stringify({
                                        UD_Type: 'gridLayout',
                                        UD_DataKey: gridParam,
                                        UD_UserDefinedText1: data,
                                        UD_Data: btoa(kendo.stringify($('#${
                                            gridLayoutProps.overrideDiv || 'grid'
                                        }').data('kendoGrid').getOptions())),
                                    }),
                                };
            
                                $.ajax(settings).done(function (response) {
                                    kendo.alert(kendo.format('Grid layout saved'));
                                    SaveFilter();
                                });
                            },
                            function () {
                                //kendo.alert("Cancel entering value.");
                            }
                        );
                    };

                    function SaveFilter() {
                        let endpoint = '${gridLayoutProps.keyword}';
                        const gridParam = endpoint.replaceAll('/', '~');
            
                        var model, columns;
                        const host = '${externalBaseUrl}/cix';
                        const hostSub = '${gridLayoutProps?.customUrl ? '/' : '/scr/'}';
                        const baseUrl = host + hostSub + endpoint + '/';
                        const headers = {
                            Authorization: '${token}',
                        };

                        $('#${gridLayoutProps.overrideDiv || 'grid'}')
                            .find('#layoutDDL')
                            .kendoDropDownList({
                                autoBind: true,
                                optionLabel: 'My Layouts',
                                messages: {
                                    noData: 'No saved Layouts',
                                },
                                dataTextField: 'UD_UserDefinedText1',
                                dataValueField: 'UD_Data',
                                dataSource: {
                                    transport: {
                                        read: {
                                            // this needs to be worked on for the user's saved filters.
                                            url: host + '/user/me/userdata/gridLayout/' + gridParam,
                                            dataType: 'json',
                                            headers: headers,
                                        },
                                    },
                                    schema: {
                                        data: function (response) {
                                            return response.data;
                                        },
                                    },
                                },
                                change: function () {
                                    let value = this.value();
                                    let gridData = JSON.parse(atob(value));
            
                                    // change
                                    if (gridData.dataSource.transport.read.headers.Authorization) {
                                        gridData.dataSource.transport.read.headers.Authorization = '${token}';
                                    }
            
                                    $('#${
                                        gridLayoutProps.overrideDiv || 'grid'
                                    }').data('kendoGrid').setOptions(gridData);
                                    SaveFilter();
                                },
                            });
                    }

                    function dynamicRestFn(url, method, payload) {
                        const baseUrl = '${baseURL}/' + url;
                        const headers = {
                            Authorization: '${token}',
                        };
                        var settings = {
                            url: baseUrl,
                            method: method,
                            timeout: 0,
                            headers: {
                                Authorization: '${token}',
                                'Content-Type': 'application/json',
                            },
                            data: payload ?? JSON.stringify(payload),
                        };
                        $.ajax(settings).done(function (response) {
                            kendo.alert('Action Completed');
                            $('#${gridLayoutProps.overrideDiv || 'grid'}').data('kendoGrid').dataSource.read();
                        }).fail(function (response) {
                            if (response.responseJSON?.message)
                            {
                                kendo.alert(kendo.format(response.responseJSON?.message));
                            }
                            else 
                            {
                                kendo.alert("Oops! Action Failed...");
                            }
                        }); 
                    };

                    function removeDuplicates(str) {
                        if(str){
                           return [...new Set(str.split(", "))].toString();
                        } else {
                            return ''
                        }
                    }

                    function removeDuplicatesToArray(str) {
                        if(str){
                           return [...new Set(str.split(", "))];
                        } else {
                            return undefined
                        }
                    }
                    
                    $(document).ready(function () {
                      

                    let endpoint = '${gridLayoutProps.keyword}';
                    const gridParam = endpoint.replaceAll('/', '~');
            
                    let dateFields = []

                    var model, columns, toolbarAddition;
                    const host = '${externalBaseUrl}/cix';
                    const hostSub = '${gridLayoutProps?.customUrl ? '/' : '/scr/'}';
                    const baseUrl = host + hostSub + endpoint + '/';
                    const headers = {
                        Authorization: '${token}',
                    };
            
                    const init = async () => {

                        const data = await $.ajax({
                            method: 'get',
                            url: baseUrl + '~/gridOptions',
                            dataType: 'json',
                            headers: headers,
                        });
            
                        model = data.model;
                        columns = data.columns;
                        toolbarAddition = data.toolbarAddition;
                        excelName = data.excelName || "HUB.xlsx"

                        localdata = undefined

                        groupBy = model?.groupBy || [];
                        sortBy = model?.sortBy || []
                        id = model?.id;
                        searchfields = [];
                      

                        let toolbar = [{
                            name: 'search',
                            template: '<input type="search" id="searchBox" class="k-textbox llama" style="width: 250px"/>',
                        },
                        {
                            name: 'excel',
                            template:
                                '<button class="k-button k-grid-excel app-btn icon-button app-btn-secondary custom-button"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><svg class="svg-icon add-btn-icon custom-icon"><use xlink:href="\\\\\\#downloadIcon"><title>Warning</title></use></svg></button>',
                        },
                        {
                            template:
                                '<select id="layoutDDL" class="k-dropdown  k-ie k-select k-state-border-down"><option>Layout</option></select>',
                        },
                        {
                            template:
                                '<button class="app-btn icon-button app-btn-secondary custom-button" onClick="myData(\\'' + gridParam + '\\')"  data-bs-toggle="tooltip" data-bs-placement="left" title="Manage Layout"><svg class="svg-icon export-icon custom-icon"><use xlink:href="\\\\\\#settingIcon"><title>Warning</title></use></svg></button>',
                        },
                        {
                            template:
                                '<button class="app-btn icon-button app-btn-secondary custom-button" onClick="updloadSettings()"  data-bs-toggle="tooltip" data-bs-placement="left" title="Save Layout"><svg class="svg-icon export-icon custom-icon"><use xlink:href="\\\\\\#saveIcon"><title>Warning</title></use></svg></button>',
                        },
                        {
                            template:
                                '<button class="app-btn icon-button app-btn-secondary custom-button" data-bs-toggle="tooltip" data-bs-placement="left" title="Help"><svg class="svg-icon custom-icon"><use xlink:href="\\\\\\#helpIcon"><title>Warning</title></use></svg></button>',
                        }]


                        if(toolbarAddition?.length>0){
                            for(const t of toolbarAddition){
                                toolbar.unshift(t)
                            }
                        }

                        for (let column of columns) {
                            // create searchable fields.
                            if (!column.hidden && column.field && model.fields[column.field].type !== 'boolean') searchfields.push(column.field);

                            if(model.fields[column.field].type == "date"){
                                dateFields.push(column.field)
                                column.filterable.operators = {
                                    date: {
                                      // Additional custom operators.
                                      today: "Today",
                                      last_7_days: "Last 7 Days",
                                      next_7_days: "Next 7 Days",
                                      last_14_days: "Last 14 Days",
                                      next_14_days: "Next 14 Days",
                                      "z1" : "-----------------",
                                      last_week: "Last Week",
                                      this_week: "This Week",
                                      next_week: "Next Week",
                                      "z2" : "-----------------",
                                      last_month: "Last Month",
                                      this_month: "This Month",
                                      next_month: "Next Month",
                                      "z3" : "-----------------",

                                      eq: "Is",
                                      gt: "Is After",
                                      lt: "Is Before",
                                      ne: "Is Not",
                                      isnull: "Has No Date",
                                      isnotnull: "Has Date"
                                    }
                                  }
                            }                        
                            if(model.fields[column.field].type == 'boolean'){
                              column.filterable = {
                                    messages: {
                                    isFalse: "NO ",
                                    isTrue: "YES "
                                    }
                                }
                                column.headerAttributes = { style: "text-align: center; justify-content: center" }
                            }
                        }


            
                        if(data.override?.dataType == "local"){
                            const localData = await $.ajax({
                                method: 'get',
                                url: baseUrl + '${gridLayoutProps.extraData ?? ''}',
                                dataType: 'json',
                                headers: headers,
                            });
                            let initialLoad = true;

                            toolbar.push({
                                template:
                                '<button class="app-btn icon-button app-btn-secondary custom-button" onClick="collapseAll()"  data-bs-toggle="tooltip" data-bs-placement="left" title="Collapse All Groups"><svg class="svg-icon export-icon custom-icon"><use xlink:href="\\\\\\#dashCheck"><title>Warning</title></use></svg></button>',                            })

                            $('#${gridLayoutProps.overrideDiv || 'grid'}').kendoGrid({
                                dataSource: {
                                    data: localData.data,
                                    total: localData.total,
                                    schema: {
                                        model: { id: model.id, fields: model.fields },
                                    },
                                    sort: sortBy,
                                    group: groupBy,
                                    pageSize: 50,
                                    requestEnd: function () {
                                        hideLoader();
                                    },
                                },
                                height: '100%',
                                groupable: {
                                    showFooter: true
                                  },
                                sortable: false,
                                selectable: true,
                                filterable: {
                                    mode: "row",
                                    extra: true,
                                    cell: {
                                        operator: "contains",
                                        suggestionOperator: "contains"
                                    }
                                },
                                columnMenu: {
                                    autoSize: true,
                                    componentType: 'tabbed',
                                    clearAllFilters: true,
                                    columns: {
                                        search: true
                                    },
                                    sortable: true,
                                    resizable: true,
                                    filterable: {
                                        extra: true,
                                            operator: "contains",
                                            suggestionOperator: "contains",
                                            multi: true,
                                            search: true
                                    },
                                },
                                resizable: true,
                                reorderable: true,
                                pageable: {
                                    refresh: true,
                                    buttonCount: 5,
                                    pageSizes: ['all'],
                                },
                                columns: columns,
                                editable: 'inline',
                                selectable: true,
                                search: {
                                    fields: searchfields, // Or, specify multiple fields by adding them to the array, e.g ["name", "age"]
                                },
                                excel: {
                                    fileName: excelName,
                                },
                                toolbar: toolbar,
                            });


                        }else{
                            $('#${gridLayoutProps.overrideDiv || 'grid'}').kendoGrid({
                                dataSource: {
                                    serverPaging: true,
                                    serverSorting: true,
                                    serverFiltering: true,
                                    transport: {
                                        read: {
                                            url: baseUrl + '${gridLayoutProps.extraData ?? ''}',
                                            dataType: 'json',
                                            headers: headers,
                                        },
                                        parameterMap: function (data, type) {
                                            const group = $('#${
                                                gridLayoutProps.overrideDiv || 'grid'
                                            }').data('kendoGrid').dataSource.group();
                                            if (group?.length > 0) {
                                                data.group = group;
                                            }
                                            return data;
                                        },
                                    },
                                    schema: {
                                        total: function (response) {
                                            return response.total; // total is returned in the "total" field of the response
                                        },
                                        data: function (response) {
                                            return response.data;
                                        },
                                        model: { id: model.id, fields: model.fields },
                                    },
                                    pageSize: 50,
                                    requestEnd: function () {
                                        hideLoader();
                                    },
                                    filter: {
                                        filters:
                                            [
                                                ${
                                                    gridLayoutProps.filter !== '{}' && gridLayoutProps.filter
                                                        ? gridLayoutProps.filter
                                                        : filter
                                                        ? filter
                                                        : '{}'
                                                }
                                            ]
                                    },
                                    group: groupBy,
                                },
                                height: '100%',
                                groupable: true,
                                sortable: {
                                    mode: 'mixed',
                                    allowUnsort: true,
                                    showIndexes: true
                                  },
                                selectable: true,
                                filterable: {
                                    mode: "row",
                                    extra: true,
                                    cell: {
                                        operator: "contains",
                                        suggestionOperator: "contains"
                                    }
                                },
                                columnMenu: {
                                    autoSize: true,
                                    componentType: 'tabbed',
                                    clearAllFilters: true,
                                    columns: {
                                        sort: 'asc',
                                        search: true,
    
                                    },
                                    sortable: true,
                                    resizable: true,
                                    filterable: {
                                        extra: true,
                                            operator: "contains",
                                            suggestionOperator: "contains",
                                            multi: true,
                                            search: true
                                    },
                                },
                                resizable: true,
                                reorderable: true,
                                pageable: {
                                    refresh: true,
                                    buttonCount: 5,
                                    pageSizes: [25, 50, 100, 250, 500, 1000, 'all'],
                                },
                                columns: columns,
                                editable: 'inline',
                                selectable: true,
                                search: {
                                    fields: searchfields, // Or, specify multiple fields by adding them to the array, e.g ["name", "age"]
                                },
                                excel: {
                                    fileName: excelName,
                                },
                                dataBound: (e) => {
                                    for(const dateField of dateFields){
    
                                        let DDL = e.sender.wrapper.find("[data-field='"+dateField+"'] input.k-dropdown-operator").data("kendoDropDownList");
                                        DDL?.unbind("change").bind("change", (ev) => {
                                          let val = ev.sender.value();
                                          if (val !== "z1" && val !== "z2" && val !== "z3" && val !== "z4" && val !== "z5" && val !== "z6" && val !== "eq" && val !== "qt" && val !== "lt"
                                          && val !== "ne" && val !== "isnull" && val !== "isnotnull") {
                                            e.sender.dataSource.filter({
                                              field: dateField,
                                              operator: "is",
                                              value: val,
                                            })
                                          }
                                        });
                                    }
                                    
                                  },
                                toolbar: toolbar,
                            });
                        }


                    };
            
                    
            
                    init().then(function () {
                        notification = $('#notification')
                            .kendoNotification({
                                position: {
                                    pinned: true,
                                    top: 30,
                                    right: 30,
                                },
                                autoHideAfter: 2000,
                                stacking: 'down',
                                templates: [
                                    {
                                        type: 'info',
                                        template: $('#emailTemplate').html(),
                                    },
                                    {
                                        type: 'error',
                                        template: $('#errorTemplate').html(),
                                    },
                                    {
                                        type: 'success',
                                        template: $('#successTemplate').html(),
                                    },
                                ],
                            })
                            .data('kendoNotification');
            
                        SaveFilter();
                       
                        let params = new URL(document.location).searchParams;
                        let UD = params.get('UD') || undefined
                        if(UD){
                            var settings = {
                                    url: host + '/user/me/userdata/PK/'+UD,
                                    method: 'GET',
                                    timeout: 0,
                                    headers: {
                                        Authorization: '${token}',
                                        'Content-Type': 'application/json',
                                    }
                                };
            
                                $.ajax(settings).done(function (response) {
                                    const data = response.data[0].UD_Data

                                    let gridData = JSON.parse(atob(data));
                                    console.log(gridData)
            
                                    // change
                                    if (gridData.dataSource.transport.read.headers.Authorization) {
                                        gridData.dataSource.transport.read.headers.Authorization = '${token}';
                                    }
            
                                    $('#${
                                        gridLayoutProps.overrideDiv || 'grid'
                                    }').data('kendoGrid').setOptions(gridData);

                                    SaveFilter();
                                });
                        }
                                
                    });
            
                    
                });`}
            </script>
        </Helmet>
    );

    filter = undefined;
};

export default GridLayout;
