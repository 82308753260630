import React, { useEffect, useState } from 'react';
import RequiredDetailsModal from './RequiredDetailsModal';
import AdditionalDetailsModal from './AdditionalDetailsModal';
import AllocationOrderHeader from '../AllocationOrderHeader';
import './allocation-basic-detail.scss';
import '../ao-tab.scss';

import {
    adSuccess,
    fetchBasicDetails,
    getAddress,
    getBuyer,
    getStoreID,
    reqSuccess,
    getDatas,
} from 'src/store/reducers/allocationOrder/basicDetails';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useParams } from 'react-router-dom';
import { useAppSelector } from 'src/hooks';
import { fetchHeader, selectHeader } from 'src/store/reducers/allocationOrder/addAOReducer';
import { Position, success } from 'src/utils/toast';
// import { selectDatesPo } from 'src/store/reducers/purchaseOrder/datesPoReducer';
import ShowForPermissions from 'src/ShowForPermissions';
import AOBreadCrumb from '../BreadCrumb';
import { fetchDatesAoList } from 'src/store/reducers/allocationOrder/datesAoReducer';
import {
    fetchProductAllocationList,
    // selectProductAllocation,
} from 'src/store/reducers/allocationOrder/productAllocationReducer';
// import { useUserProfile } from 'src/hooks/useUserProfile';
import AllocationOrderTabs from '../AllocationOrderTabs';
import AllocationOrderNextButton from '../AllocationOrderNextButton/AllocationOrderNextButton';

const Index: React.FC = () => {
    // const location = useLocation();
    // const data1 = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const [addDetails, setaddDetails] = useState(false);
    const [addRequired, setaddRequired] = useState(false);
    const [isEdit, setisEdit] = useState(false);
    const [isEditAdditional, setisEditAdditional] = useState(false);
    const { loading } = useSelector((state: RootState) => state.vendorDat);
    // const datesAoData = useAppSelector(selectDatesAo);
    const { basicDetails, addtionalSuccess, requiredSuccess, addressVal, buyerVal } = useSelector(
        (state: RootState) => state.allocationOrder
    );
    const { headerDetails } = useSelector((state: RootState) => state.addAoData);
    // const { previewData } = useSelector((state: RootState) => state.allocationOrder) as any;
    // const datesPoData = useAppSelector(selectDatesPo);
    const headerData = useAppSelector(selectHeader);
    // const productAllocation = useAppSelector(selectProductAllocation);
    // const { profileType } = useUserProfile();

    const { id } = useParams();

    const dispatch = useDispatch();
    // const navigate = useNavigate();

    const data = {
        agentsList: [],
        paymentterms: [],
        incotermfob: [],
        // portlist,
        freightterms: [],
        fclservicetype: [],
        shippingmode: [],
        orderType: [],
        addressVal: [],
    };
    useEffect(() => {
        dispatch(fetchBasicDetails(id));
        dispatch(
            getDatas({
                orderType: {},
                shippingmode: {},
                fclservicetype: {},
                freightterms: {},
                // portlist: {},
                incotermfob: {},
                paymentterms: {},
                agentsList: {},
                buyers: {},
                currency: [],
            })
        );
    }, []);
    useEffect(() => {
        if (headerData?.clientCode) {
            dispatch(
                getAddress({
                    orgCode: headerData && headerData?.clientCode,
                })
            );
            dispatch(
                getBuyer({
                    orgCode: headerData && headerData?.clientCode,
                })
            );
            dispatch(
                getStoreID({
                    clientCode: headerData && headerData?.clientCode,
                })
            );
        }
        dispatch(fetchProductAllocationList({ aoId: id }));
        dispatch(
            fetchDatesAoList({
                id,
            })
        );
    }, [headerData?.clientCode]);

    useEffect(() => {
        if (addtionalSuccess) {
            success(`Additional details have been ${isEditAdditional ? 'updated' : 'added'}`, Position.TOP_RIGHT);
            dispatch(adSuccess(false));
            dispatch(
                fetchHeader({
                    aoId: id,
                })
            );
        } else if (requiredSuccess) {
            success(`Required details have been ${isEdit ? 'updated' : 'added'}`, Position.TOP_RIGHT);
            dispatch(reqSuccess(false));
            dispatch(
                fetchHeader({
                    aoId: id,
                })
            );
        }
    }, [addtionalSuccess, requiredSuccess]);
    const val = basicDetails;
    /* eslint no-var: off */
    const showCreate = () => {
        var flag = false;
        if (
            val &&
            (val.promRefNo ||
                val.pickGroup ||
                val.brandName ||
                val.currency ||
                val.buyerUserName ||
                val.impVendorCode ||
                val.impShipmentRefNo ||
                val.distribOrderNo)
        ) {
            flag = true;
        }
        return flag;
    };
    const showEditRequired = () => {
        var flag = false;
        if (
            val &&
            (val.ordertype ||
                // val.shippingmode ||
                val.agentName ||
                val.destinationAddress ||
                val.destinationPort ||
                val.originPort ||
                val.paymentterms ||
                val.incoTerm ||
                val.freightterms ||
                val.fclservicetype)
        ) {
            flag = true;
        }
        return flag;
    };
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userType = '';
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            userType = roles[roleIndex].profile?.toLowerCase();
        }
    }

    // const skipPOLine = headerData?.isHeaderOnlyPo;

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                {/* <Breadcrumbs
                    firstName="Allocation Orders"
                    firstUrl="/allocation_order"
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New AO' : 'Create New Allocation Order'}
                    secondUrl={`/allocation_order/${id}/basic_details`}
                /> */}
                <AOBreadCrumb />
                {/* <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/allocation_orders">
                                Allocation Orders
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Create New Allocation Order
                            </a>
                        </li>
                    </ul>
                    <div className="btn-holder discard-btn-holder">
                        <button
                            className="app-btn app-btn-secondary black-outline-btn icon-button"
                            title={
                                headerData?.aoStatus === 'Draft'
                                    ? 'Discard Allocation Order'
                                    : 'Cancel Allocation Order'
                            }
                        >
                            <svg className="svg-icon add-btn-icon">
                                <use xlinkHref="#deleteIcon"></use>
                            </svg>
                            <span className="button-text">Discard AO</span>
                        </button>
                    </div>
                </div> */}
                <AllocationOrderHeader id={id}></AllocationOrderHeader>
                {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <AllocationOrderTabs />
            </div>
            <div className="content-section main-wrapper allocation-basic-detail-wrapper">
                {val?.createdUserId?.toLowerCase() === 'interface' && !headerDetails.bdTabComplete ? (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missed while integrating. Please integrate again with all mandatory fields
                            to proceed.
                        </div>
                    </div>
                ) : null}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Required Details</h6>
                            <ShowForPermissions permission="edit" type="ao">
                                {showEditRequired() &&
                                    userType !== 'agent' &&
                                    val?.aoStatus !== 'Cancelled' &&
                                    val?.aoStatus !== 'In_Booking_Proposal' &&
                                    val?.aoStatus !== 'Booked' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        userType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && userType === 'forwarder')) &&
                                    headerData?.dataSourceName != 'ERP' && (
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                            onClick={() => {
                                                setaddDetails(true);
                                                setisEdit(true);
                                            }}
                                        >
                                            <svg className="svg-icon edit-icon" style={{ width: '100%' }}>
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="ao">
                            <div className="btn-holder">
                                {!showEditRequired() &&
                                    userType !== 'agent' &&
                                    val?.aoStatus !== 'Cancelled' &&
                                    val?.aoStatus !== 'In_Booking_Proposal' &&
                                    val?.aoStatus !== 'Booked' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        userType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && userType === 'forwarder')) &&
                                    headerData?.dataSourceName != 'ERP' && (
                                        <button
                                            onClick={() => {
                                                setaddDetails(true);
                                                setisEdit(false);
                                            }}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.ordertype ? val?.ordertype?.orderTypeName : 'Allocation order'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Shipping Mode<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.shippingmode ? val?.shippingmode?.shippingModeName : 'Sea Freight'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Origin<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.originPort ? `${val?.originPortName}(${val?.originPort})` : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationPort
                                    ? `${val?.destinationPortName}(${val?.destinationPort})`
                                    : '-'}
                            </div>
                        </div>
                        {val?.isMultiAddress && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Includes AU Destination<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content" style={{ width: '80%' }}>
                                    {val?.includesAUPortDestination ? 'Yes' : 'No'}
                                </div>
                            </div>
                        )}
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination Address
                                {!val?.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content" style={{ width: '80%' }}>
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationAddress
                                    ? val.destinationAddress
                                    : '-'}{' '}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Service Type
                                {!val?.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {val?.fclservicetype ? val?.fclservicetype?.fclServiceTypeName : '-'}
                            </div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Freight Term<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.freightterms ? val?.freightterms?.freightTermsCode : '-'}
                            </div>
                        </div> */}
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Incoterm
                                {!val?.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {val?.incoTerm ? val?.incoTerm?.incoTermName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Release type
                                {!val?.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {val?.releaseType?.releaseTypeName
                                    ? val?.releaseType?.releaseTypeCode + ' (' + val?.releaseType?.releaseTypeName + ')'
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Payment Term
                                {!val?.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {val?.paymentterms ? val?.paymentterms?.paymentTermsName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Agent<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.agentName ? val?.agentName : '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Store ID<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.storeId ? val?.storeId : '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Batch ID<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.batchId ? val?.batchId : '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Number<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{val?.erpOrderno ? val?.erpOrderno : '-'}</div>
                        </div>
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Additional Details</h6>
                            <ShowForPermissions permission="edit" type="ao">
                                {showCreate() &&
                                    userType !== 'agent' &&
                                    val?.aoStatus !== 'Cancelled' &&
                                    val?.aoStatus !== 'In_Booking_Proposal' &&
                                    val?.aoStatus !== 'Booked' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        userType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && userType === 'forwarder')) &&
                                    headerData?.dataSourceName != 'ERP' && (
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                            onClick={() => {
                                                setaddRequired(true);
                                                setisEditAdditional(true);
                                            }}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="ao">
                            <div className="btn-holder">
                                {!showCreate() &&
                                    userType !== 'agent' &&
                                    val?.aoStatus !== 'Cancelled' &&
                                    val?.aoStatus !== 'In_Booking_Proposal' &&
                                    val?.aoStatus !== 'Booked' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        userType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && userType === 'forwarder')) &&
                                    headerData?.dataSourceName != 'ERP' && (
                                        <button
                                            onClick={() => {
                                                setaddRequired(true);
                                                setisEditAdditional(false);
                                            }}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    {!showCreate() && (
                        <div className="no-data-wrapper">
                            <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No additional details added</p>
                            </div>{' '}
                        </div>
                    )}
                    <div className="card-detail-col">
                        {val?.promRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Promotional Ref No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.promRefNo ? val?.promRefNo : '-'}</div>
                            </div>
                        )}
                        {val?.brandName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Brand Name
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.brandName ? val?.brandName : '-'}</div>
                            </div>
                        )}
                        {val?.impVendorCode && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Vendor Code
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impVendorCode ? val?.impVendorCode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.pickGroup && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Pick Group
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.pickGroup ? val?.pickGroup : '-'}</div>
                            </div>
                        )}
                        {val?.currency && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Order Currency
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.currency ? val?.currency?.currencycode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.buyerUserName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Buyer
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.buyerUserName ? val?.buyerUserName : '-'}
                                </div>
                            </div>
                        )}
                        {val?.impShipmentRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Shipment Ref No .{/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impShipmentRefNo ? val?.impShipmentRefNo : '-'}
                                </div>
                            </div>
                        )}
                        {val?.distribOrderNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Distribution Order No
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.distribOrderNo ? val?.distribOrderNo : '-'}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <AllocationOrderNextButton page="basic_details" />
                </div>
            </div>
            {addDetails && (
                <RequiredDetailsModal
                    data={data}
                    displayFn={setaddDetails}
                    display={addDetails}
                    edit={isEdit}
                    editData={isEdit && val}
                    setisEdit={setisEdit}
                    id={id}
                    addressVal={addressVal}
                />
            )}
            {addRequired && (
                <AdditionalDetailsModal
                    data1={buyerVal}
                    data2={[]}
                    displayFn={setaddRequired}
                    display={addRequired}
                    edit={isEditAdditional}
                    editData={isEditAdditional && val}
                    setisEdit={setisEditAdditional}
                    id={id}
                />
            )}
            {/* <RequiredDetailsModal></RequiredDetailsModal> */}
            {/* <AdditionalDetailsModal></AdditionalDetailsModal> */}
            {/* <CancellationDetailModal></CancellationDetailModal> */}
            {/* <CancellationModal></CancellationModal> */}
        </div>
    );
};
export default Index;
