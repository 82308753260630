import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import 'src/pages/PO/Dates/purchase-dates.scss';
import '../POSelection.scss';
import TransportScheduleModal from './transportScheduleModal';
import {
    addToBp,
    checkContractData,
    checkContractResponse,
    contractLoaded,
    fakListCompletedFn,
    fetchSailingData,
    fetchSelectedSailing,
    getFakData,
    saveCountValues,
    isLoadingFn,
    removeSailingStatus,
} from '../../../store/reducers/bookingProposalLand/sailingReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Position, success } from '../../../utils/toast';
// import data from './sailing.json';
import moment from 'moment';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import Loader from './Loader';
// import { fetchAddedPoData } from '../../../store/reducers/bookingProposalLand/addedPoReducer';
// import ShowForPermissions from '../../../ShowForPermissions';
// import BookingHeaderSidebar from './../BookingHeaderSidebar';
import { useUserProfile } from '../../../hooks/useUserProfile';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { getOrganisationData } from '../../../utils';
import dateValuesSailing from './dateValuesSailing';
import { getTransportSchedule } from 'src/store/reducers/bookingProposalLand/sailingReducer';
import ShowMandatoryFields from 'src/components/common/ShowMandatoryFields';
// import ChangeScheduleReason from 'src/pages/BcnbookingProposalLand/SailingScheludeSelection/ChangeScheduleReason';

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '250',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];

let dateValues: any = {};

const Index: React.FC = () => {
    const { profileType } = useUserProfile();
    const { isLoading, contractCompleted } = useSelector((state: RootState) => state.sailingRed);
    // const { addPoList } = useSelector((state: RootState) => state.addedPos);
    const { bpStatus, clientName, isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { transportSchedule } = useSelector((state: RootState) => state.landsailingbp);

    const dispatch = useDispatch();
    // const location = useLocation();
    const { id } = useParams();
    const navigate = useNavigate();
    // const [reason, setReason] = useState('');
    // const [modalView, setModalView] = useState(false);
    // const [changeSelectionClicked, setChangeSelectionClicked] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [selectedView, setselectedView] = useState(false);
    const [_notification, setnotification] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);

    const [_showBookingSideBar, setShowBookingSideBar] = useState(false);
    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: id ? `/land_booking_list/${id}/POSelection` : '/land_booking_list/POSelection',
            disabled: false,
        },
        {
            title: 'Transport Schedule',
            selected: 1,
            path: `/land_booking_list/${id}/transport-schedule`,
            //disabled: !(headerData?.tabValues as any)?.po,
            disabled: false,
        },
        {
            title: 'Goods Received',
            selected: 0,
            path: `/land_booking_list/${id}/goods-received`,
            //disabled: !(headerData?.tabValues as any)?.sailing,
            disabled: false,
        },
    ];

    useEffect(() => {
        if (id) dispatch(getTransportSchedule({ id: id }));
    }, [id]);

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    const convertLegTypeCodeToDesc = (typeCode: string) => {
        switch (typeCode) {
            case 'CNT':
                return 'Container Number';
            case 'LPN':
                return 'Truck License Plate';
            case 'RCP':
                return 'Receipt ID';
            case 'PON':
                return 'Purchase Order ID';
            case 'COI':
                return 'Consignment Number';
            case 'HBL':
                return 'House Bill No';
            case 'MBL':
                return 'Master Bill No';
            case 'AWB':
                return 'Air Waybill No';
            default:
                return '-';
        }
    };

    return (
        <div className="main-wrapper container" style={{ overflow: 'hidden' }}>
            <div className="main-header-content-holder large-header">
                {localStorage.getItem('changeVessel') !== id ? (
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/land_booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={`/land_booking_list/${id}/POSelection`}
                        thirdName={'Sailing Schedule'}
                        thirdUrl={`/land_booking_list/${id}/transport-schedule`}
                        summaryStats={summaryStats}
                        backUrl={'/land_booking_list'}
                        handleClickCancel={handleClickCancel}
                    />
                ) : (
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/land_booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={`/land_booking_list/${id}/POSelection`}
                        thirdName={'Sailing Schedule'}
                        thirdUrl={`/land_booking_list/${id}/transport-schedule`}
                        fourthName={'Change Vessel'}
                        fourthUrl={'#'}
                        summaryStats={summaryStats}
                        backUrl={'/land_booking_list'}
                        handleClickCancel={handleClickCancel}
                    />
                )}
                <BookingProposalHeader
                    id={
                        localStorage.getItem('changeVessel') &&
                        localStorage.getItem('changeVessel') === id &&
                        !selectedView
                            ? 'Select from Schedules'
                            : localStorage.getItem('bpNumber') || ''
                    }
                    setSelected={setselectedView}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>{' '}
                {(isLoading ||
                    (isLoadingHeader && contractCompleted && !selectedView) ||
                    (isLoadingHeader && selectedView)) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {/* Hidden on new feature 30/12/2022 */}
                {/* {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )} */}
                {(localStorage.getItem('changeVessel') !== id || selectedView) && (
                    <BookingProcessTab navData={navData} />
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper agent-basic-detail-wrapper">
                <div className="main-wrapper">
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder">
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">Schedule</h6>

                                <button
                                    onClick={() => {
                                        setModalView(!modalView);
                                    }}
                                    className="action-btn app-btn app-btn-secondary "
                                    title="Edit Dates"
                                >
                                    <svg className="svg-icon edit-icon">
                                        <use xlinkHref="#editIcon"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={true}
                                    name="ETD"
                                    showAsterisk={true}
                                    viaIntegration={false}
                                />
                                <div className="details-box-content">
                                    {transportSchedule?.[0]?.TL_PEstimatedDeparture
                                        ? moment(transportSchedule?.[0]?.TL_PEstimatedDeparture).format('YYYY-MM-DD')
                                        : '-'}
                                </div>
                            </div>

                            <div className="details-col box-content-holder">
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={true}
                                    name="ETA"
                                    showAsterisk={true}
                                    viaIntegration={false}
                                />

                                <div className="details-box-content">
                                    {transportSchedule?.[0]?.TL_PEstimatedArrival
                                        ? moment(transportSchedule?.[0]?.TL_PEstimatedArrival).format('YYYY-MM-DD')
                                        : '-'}
                                </div>
                            </div>

                            <div className="details-col box-content-holder">
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={true}
                                    name="Transport Company"
                                    showAsterisk={true}
                                    viaIntegration={false}
                                />
                                <div className="details-box-content">{transportSchedule?.[0]?.TL_PCarrier || '-'}</div>
                            </div>
                            <div className="details-col box-content-holder">
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={true}
                                    name="ID"
                                    showAsterisk={true}
                                    viaIntegration={false}
                                />

                                <div className="details-box-content">
                                    {transportSchedule?.[0]?.TL_PVoyageFlightNo || '-'}
                                </div>
                            </div>

                            <div className="details-col box-content-holder">
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={true}
                                    name="ID Type"
                                    showAsterisk={true}
                                    viaIntegration={false}
                                />
                                <div className="details-box-content">
                                    {convertLegTypeCodeToDesc(transportSchedule?.[0]?.TL_PCD_LegType || '')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Draft_Booking_Proposal' ||
                            bpStatus === 'Rejected_by_Forwarder' ||
                            bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                            // <>
                            //     {bpStatus !== 'Cancelled' && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking Proposal"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking Proposal</span>
                                </button>
                            </ShowForPermissions>
                            //     )}
                            // </>
                        )} */}
                    {/* {bpStatus === 'Draft_Booking_Proposal' && (
                        <ShowForPermissions type="bp" permission="create">
                            {!selectedView && (
                                <ShowForPermissions type="bp" permission="create">
                                    <a
                                        title="Save as Draft"
                                        onClick={() => addSelectedContract()}
                                        className={`app-btn app-btn-primary footer-btn${
                                            isEmpty(selectedContract) ? ' disabled' : ''
                                        }`}
                                    >
                                        <span className="button-text footer-button-text">Save as Draft</span>
                                    </a>
                                </ShowForPermissions>
                            )}
                        </ShowForPermissions>
                    )} */}
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking</span>
                                </button>
                            </ShowForPermissions>
                        )} */}
                    <a
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        onClick={() => navigate(`/land_booking_list/${id}/POSelection`)}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </a>
                </div>
            </div>
            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            <TransportScheduleModal
                display={modalView}
                displayFunction={setModalView}
                data={undefined}
                setDisplayCancel={setDisplayCancel}
            ></TransportScheduleModal>
        </div>
    );
};
export default Index;
