import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    fetchContractList,
    fetchContractListFailed,
    fetchContractListSuccess,
    createContractDetails,
    createContractDetailsSuccess,
    createContractDetailsFailure,
    updateContractDetailsSuccess,
    updateContractDetailsFailure,
    updateContractDetails,
    fetchAuthorDetails,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    isLoadingFn,
    searchContract,
    duplicateContract,
} from '../reducers/contractReducer';

import {
    createContractData,
    fetchContractListData,
    updateContractData,
    fetchContractAuthor,
    searchContractListData,
    createDuplicateContract,
} from '../../services/apis/contractApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// eslint-disable-next-line
function* checkUserStatus({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse = yield call(fetchContractListData, payload);
        yield put({ type: fetchContractListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchContractListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* createContract({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createContractData, payload);
        yield put({ type: createContractDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: createContractDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateContract({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateContractData, payload);
        yield put({ type: updateContractDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateContractDetailsFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* authorContract({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(fetchContractAuthor);
        yield put({ type: fetchAuthorDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchAuthorDetailsFailure, payload: error });
    } finally {
        // yield put(hideLoading());
    }
}

function* saveDuplicateContract({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(createDuplicateContract, payload.carrierPK, payload.contractPK);
        yield put({ type: duplicateContract, payload: response });
    } catch (error) {}
}

function* searchContractData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse = yield call(searchContractListData, payload);
        yield put({ type: searchContract, payload: response });
    } catch (error) {
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* authSaga() {
    yield takeEvery(fetchContractList, checkUserStatus);
    yield takeLatest(createContractDetails, createContract);
    yield takeLatest(updateContractDetails, updateContract);
    yield takeLatest(fetchAuthorDetails, authorContract);
    yield takeLatest(searchContract, searchContractData);
    yield takeEvery(duplicateContract, saveDuplicateContract);
}

export default authSaga;
