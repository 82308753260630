export const aoStatus = (status: string) => {
    switch (status) {
        case 'Draft':
            return (
                <div className="grid-item-count-status status-ui info-status">
                    <span className="count"></span>
                    <span className="status-text">Draft</span>
                </div>
            );
        case 'Pending_with_Forwarder':
        case 'Pending_With_Forwarder':
        case 'Pending with FORWARDER':
            return (
                <div style={{ maxWidth: 'none' }} className="grid-item-count-status status-ui warning-status">
                    <span className="count"></span>
                    <span className="status-text">Pending with Forwarder</span>
                </div>
            );
        case 'Partially_Booked':
        case 'Partially Booked':
            return (
                <div style={{ maxWidth: 'none' }} className="grid-item-count-status status-ui info-status">
                    <span className="count"></span>
                    <span className="status-text">Partially Booked</span>
                </div>
            );
        case 'Booked':
            return (
                <div className="grid-item-count-status status-ui success-status">
                    <span className="count"></span>
                    <span className="status-text">Booked</span>
                </div>
            );
        case 'Ready_for_Booking':
        case 'Ready_For_Booking':
            return (
                <div className="grid-item-count-status status-ui in-progress">
                    <span className="count"></span>
                    <span className="status-text">Ready for Booking</span>
                </div>
            );
        case 'Cancelled':
            return (
                <div className="grid-item-count-status status-ui error-status">
                    <span className="count"></span>
                    <span className="status-text">Cancelled</span>
                </div>
            );
        default:
            return (
                <div className="grid-item-count-status status-ui confirmed-status">
                    <span className="count"></span>
                    <span className="status-text">{status}</span>
                </div>
            );
    }
};
