import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import '../../BookingProposal/ContainerAllocations/container-allocations.scss';
// import BookingAdditionalInfo from '../BookingAdditionalInfo';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    // selectContainerAllocationError,
    // selectContainerAllocationIsError,
    selectContainerAllocationLoading,
    // selectContainerAllocationSuccess,
    fetchContainers,
    selectContainers,
    BpContainersList,
    resetFields,
} from '../../../store/reducers/bookingProposal/containerAllocationReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useUserProfile } from '../../../hooks/useUserProfile';
// import ShowForPermissions from '../../../ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';

const allocationStatus = (status: string) => {
    switch (status) {
        case 'Pending':
            return <div className="allocated-status pending">Pending</div>;
        case 'In_Progress':
            return <div className="allocated-status in-progress">In-progress</div>;
        case 'Completed':
            return <div className="allocated-status completed">Completed</div>;
    }
};

const Index: React.FC = () => {
    const { id } = useParams();
    const { profileType } = useUserProfile();

    const navData = [
        {
            title: 'AO Selection',
            selected: 0,
            path: `/Aobooking_list/${id}/AoSelection`,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/Aobooking_list/${id}/SailingSelectionDetails`,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/Aobooking_list/${id}/additional-details`,
        },
        {
            title: 'Container Allocation',
            selected: 1,
            path: `/Aobooking_list/${id}/container-allocation`,
        },
        // {
        //     title: 'Attachments',
        //     selected: 0,
        //     path: `/Aobooking_list/${id}/attachments`,
        // },
    ];
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [containerData, setContainerData] = useState<BpContainersList[]>([]);
    const [search, setSearch] = useState('');
    const dispatch = useAppDispatch();
    // const isError = useAppSelector(selectContainerAllocationIsError);
    // const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const containers = useAppSelector(selectContainers);
    // const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    const isLoading = useAppSelector(selectContainerAllocationLoading);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);

    useEffect(() => {
        dispatch(fetchContainers({ bpId: id }));
        dispatch(resetFields({}));
    }, []);

    useEffect(() => {
        if (containers.length) {
            setContainerData(containers);
        }
    }, [containers]);

    const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const searchKey = e.target.value;
        setSearch(searchKey);
        if (searchKey.trim().length) {
            const searchResult = containers?.filter(
                (el: BpContainersList) =>
                    (el.code &&
                        !el.containerType &&
                        el.code.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) ||
                    (el.description &&
                        !el.containerType &&
                        el.description.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) ||
                    (el.containerType &&
                        el.containerType.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())) ||
                    (el.containerNumber &&
                        el.containerNumber.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase()))
            );
            setContainerData(searchResult);
        } else {
            setContainerData(containers);
        }
    };
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                />
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <BookingProcessTab navData={navData} />
            </div>
            {/* DATA GRID SECTION */}
            <div className="grid-holder">
                <div className="grid-section">
                    <div className="grid-header">
                        <div className="content-heading-holder card-head-btn-edit-holder">
                            <span className="content-heading">Containers</span>
                        </div>
                    </div>
                    <div className={`app-loader ${isLoading || isLoadingHeader ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>

                    <LayoutWithColumns content={'containerAllocation'} hideLoader style="booking_details" />
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (headerData?.bpStatus === 'Booked' ||
                            headerData?.bpStatus === 'Ready_for_Booking' ||
                            headerData?.bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking</span>
                                </button>
                            </ShowForPermissions>
                        )} */}
                    <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/Aobooking_list/${id}/${
                            profileType === 'vendor' ? 'SailingSelectionDetails' : 'additional-details'
                        }`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    <Link
                        title="Submit"
                        className="app-btn app-btn-primary footer-btn"
                        to={`/Aobooking_list/${id}/attachments`}
                    >
                        <span className="button-text footer-button-text">Next</span>
                        {/* </button> */}
                    </Link>
                </div>
            </div>
            {showBookingCancelModal ? (
                headerData?.bpStatus === 'Booked' ||
                headerData?.bpStatus === 'Ready_for_Booking' ||
                headerData?.bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
        </div>
    );
};
export default Index;
