import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { Link, useLocation, useParams } from 'react-router-dom';

const HeaderTabsCoLoader = () => {
    const { id } = useParams();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const { coloaderHeader } = useSelector((state: RootState) => state.coloader);

    React.useEffect(() => {
        if (lastSegment) {
            if (lastSegment === 'contract_detail') {
                setSelectedIndex(1);
            } else if (lastSegment === 'basic_detail') {
                setSelectedIndex(0);
            }
        }
        document.title = 'CNF>ORG>CLD';
    }, []);

    const coloaderTabList = [
        {
            label: 'Basic Details',
            value: 'basic_detail',
            id: 1,
        },
        {
            label: 'Contract Details',
            value: 'contract_detail',
            id: 2,
        },
    ];

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (coloaderHeader) {
                if (param === 'contract_detail') {
                    return coloaderHeader.contractTabComplete;
                } else if (param === 'basic_detail') {
                    return coloaderHeader.bdTabComplete;
                }
                return false;
            }
        };
    }, [lastSegment, coloaderHeader]);

    const checkTabNavigationFromApi = (param: string, headerDetailsVal: any) => {
        if (headerDetailsVal) {
            if (param === 'contract_detail') {
                return headerDetailsVal.bdTabComplete;
            } else if (param === 'basic_detail') {
                return true;
            }
            return false;
        }
    };

    return (
        <div className="arrow-tab-container">
            <div className="arrow-tab-holder vendor-arrow-holder">
                {coloaderTabList?.map((tabs: any, index: number) => {
                    return (
                        <Link
                            className={`arrow-tab-border ${index === selectedIndex && 'active'} po-basic-tab ${
                                !checkTabNavigationFromApi(tabs.value, coloaderHeader) && 'disabled'
                            }`}
                            to={`/coloader/${id}/${tabs.value}`}
                        >
                            {isTabActive(tabs.value) ? (
                                <div
                                    className="arrow-badge verify-badge"
                                    style={{
                                        backgroundColor: '#18ab56',
                                    }}
                                >
                                    <svg className="svg-icon tick-icon">
                                        <use href="#tickIcon">
                                            <title>check mark</title>
                                        </use>
                                    </svg>
                                </div>
                            ) : (
                                <div className="arrow-badge">{tabs.id}</div>
                            )}
                            <div className="arrow-tab">{tabs.label}</div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default HeaderTabsCoLoader;
