import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchReceiptHeaderList,
    fetchReceiptHeaderListSuccess,
    fetchShippingTerms,
    fetchShippingTermsSuccess,
    isLoadingFn,
    discardReceiptHeader,
    discardReceiptHeaderSuccess,
    discardReceiptHeaderFailure,
    cancelReceiptHeader,
    cancelReceiptHeaderSuccess,
    cancelReceiptHeaderFailure,
    closeReceipt,
    closeReceiptSuccess,
    closeReceiptFailure,
    updateReceiptHeaderSuccess,
    updateReceiptHeaderFailure,
    updateReceiptHeader,
    updateActualReceipt,
    updateActualReceiptSuccess,
    updateActualReceiptFailure,
    receiveInFull,
    receiveInFullSuccess,
    receiveInFullFailure,
    updateComplianceCheck,
    updateComplianceCheckSuccess,
    updateComplianceCheckFailure,
    updateReceivedQty,
    updateReceivedQtySuccess,
    updateReceivedQtyFailure,
    downloadPoLinesImages,
    downloadPoLinesImagesSuccess,
    downloadPoLinesImagesFailure,
    reopenReceipt,
} from '../../reducers/receipts/receiptHeaderReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchReceiptHeaderListDetailsApi,
    discardReceiptHeaderDetailsApi,
    cancelReceiptHeaderDetailsApi,
    updateReceiptHeaderDetailsApi,
    fetchShippingTermsApi,
    updateActualReceiptDetailsApi,
    closeReceiptDetailsApi,
    updateComplianceCheckDetailsApi,
    receiveInFullDetailsApi,
    updateReceivedQtyDetailsApi,
    downloadPoLinesImagesApi,
    reopenReceiptApi,
} from '../../../services/apis/receipts/receiptHeaderApi';

// eslint-disable-next-line
function* getReceiptHeaderDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse = yield call(fetchReceiptHeaderListDetailsApi, payload);
        yield put({ type: fetchReceiptHeaderListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReceiptHeaderListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
// eslint-disable-next-line
function* getShippingTerms({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchShippingTermsApi, payload);
        yield put({ type: fetchShippingTermsSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReceiptHeaderListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* discardReceiptHeaderDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(discardReceiptHeaderDetailsApi, payload);
        yield put({ type: discardReceiptHeaderSuccess, payload: response });
    } catch (error) {
        yield put({ type: discardReceiptHeaderFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* cancelReceiptHeaderDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(cancelReceiptHeaderDetailsApi, payload);
        yield put({ type: cancelReceiptHeaderSuccess, payload: response });
    } catch (error) {
        yield put({ type: cancelReceiptHeaderFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* closeReceiptDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(closeReceiptDetailsApi, payload);
        yield put({ type: closeReceiptSuccess, payload: response });
    } catch (error) {
        yield put({ type: closeReceiptFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateReceiptHeaderDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateReceiptHeaderDetailsApi, payload);
        yield put({ type: updateReceiptHeaderSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateReceiptHeaderFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateActualReceiptDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateActualReceiptDetailsApi, payload);
        yield put({ type: updateActualReceiptSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateActualReceiptFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* receiveInFullData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(receiveInFullDetailsApi, payload);
        yield put({ type: receiveInFullSuccess, payload: response });
    } catch (error) {
        yield put({ type: receiveInFullFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateComplianceCheckData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateComplianceCheckDetailsApi, payload);
        yield put({ type: updateComplianceCheckSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateComplianceCheckFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateReceivedQtyData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateReceivedQtyDetailsApi, payload);
        yield put({ type: updateReceivedQtySuccess, payload: response });
    } catch (error) {
        yield put({ type: updateReceivedQtyFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* downloadPoLinesImagesData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(downloadPoLinesImagesApi, payload);
        yield put({ type: downloadPoLinesImagesSuccess, payload: response });
    } catch (error) {
        yield put({ type: downloadPoLinesImagesFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* reopenReceiptData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(reopenReceiptApi, payload);
        yield put({ type: reopenReceipt, payload: response });
    } catch (error) {
        payload['error'] = error;
        yield put({ type: reopenReceipt, payload });
    } finally {
        yield put(hideLoading());
    }
}

function* receiptHeaderSaga() {
    yield takeEvery(fetchReceiptHeaderList, getReceiptHeaderDetails);
    yield takeEvery(fetchShippingTerms, getShippingTerms);
    yield takeEvery(discardReceiptHeader, discardReceiptHeaderDetailsData);
    yield takeEvery(cancelReceiptHeader, cancelReceiptHeaderDetailsData);
    yield takeEvery(closeReceipt, closeReceiptDetailsData);
    yield takeEvery(updateReceiptHeader, updateReceiptHeaderDetailsData);
    yield takeEvery(updateActualReceipt, updateActualReceiptDetailsData);
    yield takeEvery(receiveInFull, receiveInFullData);
    yield takeEvery(updateComplianceCheck, updateComplianceCheckData);
    yield takeEvery(updateReceivedQty, updateReceivedQtyData);
    yield takeEvery(downloadPoLinesImages, downloadPoLinesImagesData);
    yield takeEvery(reopenReceipt, reopenReceiptData);
}

export default receiptHeaderSaga;
