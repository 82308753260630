import React from 'react';
import { useAppDispatch } from 'src/hooks';
import { removeProductPo } from '../../../store/reducers/purchaseOrder/productPoReducer';
import '../../../assets/scss/components/_modal.scss';

interface RemovePoLineProps {
    removeModalVisibility: boolean;
    setRemoveModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    poProductId: string;
}

const Index: React.FC<RemovePoLineProps> = ({ removeModalVisibility, setRemoveModalVisibility, poProductId }) => {
    const dispatch = useAppDispatch();
    return (
        <>
            {removeModalVisibility ? (
                <div className="app-modal container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">PO Line Removal Confirmation</h2>
                            <button className="app-btn modal-close-btn" onClick={() => setRemoveModalVisibility(false)}>
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>Are you sure to remove PO Line ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => {
                                    setRemoveModalVisibility(false);
                                }}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                onClick={() => {
                                    dispatch(removeProductPo({ poProductId: poProductId }));
                                    setRemoveModalVisibility(false);
                                }}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
