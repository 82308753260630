import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
// import './agent-list.scss';

import AddQuoteModal from './addQuoteModal';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';

const Index: React.FC = () => {
    const [showAddQuoteModal, setShowAddQuoteModal] = useState(false);

    //@ts-ignore
    window.quoteAction = (action: string) => {
        if (action === 'NEW') {
            setShowAddQuoteModal(true);
        }
    };

    return (
        <div className="main-wrapper">
            <div className="main-content-holder no-main-grid-border booking-grid">
                <h2 className="page-heading">Quotes</h2>
                <div className="grid-section grid-p-0">
                    <div className="grid-holder">
                        <LayoutWithColumns style={'report'} content={'qms-quotes'} />
                    </div>
                </div>
            </div>
            {showAddQuoteModal && <AddQuoteModal modalView={showAddQuoteModal} setModalView={setShowAddQuoteModal} />}
        </div>
    );
};

export default Index;
