/* eslint-disable no-undefined */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
import './index.scss';
import 'src/assets/scss/components/_modal.scss';
import 'src/assets/scss/components/_grid.scss';
import '../../../../Profiles/Clients/ProductMaster/product-master-modal.scss';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { success, error, Position } from 'src/utils/toast';
import { addNote, readEvents, updateNote } from 'src/services/apis/notesApi';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useParams } from 'react-router-dom';

type NoteProps = {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setNoteId: (value: string | ((prevVar: string) => string)) => void;
    initialNotesData: any;
    mode: string;
    noteId: string;
    noteTypeData: { label: string; value: string }[];
    body: any;
    setBody: any;
    refreshGrid: any;
};

const Index: React.FC<NoteProps> = ({
    initialNotesData,
    setModalView,
    mode,
    noteTypeData,
    noteId,
    setNoteId,
    body,
    setBody,
    refreshGrid,
}) => {
    const { id } = useParams();
    const { userName, profileType } = useUserProfile();
    const [isLocked, setIsLocked] = useState(false);
    const [eventNotes, setEventNotes] = useState([]);

    const onClose = () => {
        setBody(initialNotesData);
        setModalView(false);
        setNoteId('');
    };

    useEffect(() => {
        if (body) {
            setIsLocked(body?.NT_IsLockedToCreator);
        }
    }, []);

    const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBody((prev: any) => ({ ...prev, [e.target.name]: e.target.checked }));
    };

    const onSelectBoxChange = (value: string) => {
        setBody((prev: any) => ({ ...prev, NT_CD_NoteType: value }));
    };

    const onSubmit = async () => {
        let response;
        try {
            if (mode === 'add') {
                response = await addNote(body);
            } else {
                response = await updateNote(noteId, body);
            }
            if (response?.status) {
                success(`Note ${mode === 'add' ? 'created' : 'updated'} successfully`, Position.TOP_RIGHT);
                setModalView(false);
                refreshGrid();
            } else {
                error('Something went wrong', Position.TOP_RIGHT);
            }
        } catch (err: any) {
            error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    const submitButtonDisabled = () => {
        return !body?.NT_Subject || !body?.NT_CD_NoteType || !body?.NT_Text;
    };

    const modalDisabled = (type?: string) => {
        if (isLocked && body?.US_UserName !== userName && mode === 'edit' && !type) {
            return true;
        }
        if (type) {
            if (profileType === 'forwarder') {
                return false;
            } else if (
                profileType === 'agent' &&
                (type === 'NT_IsAgentViewable' || (mode === 'edit' && body?.US_UserName !== userName))
            ) {
                return true;
            } else if (
                profileType === 'client' &&
                (type === 'NT_IsClientViewable' || (mode === 'edit' && body?.US_UserName !== userName))
            ) {
                return true;
            } else if (
                profileType === 'vendor' &&
                (type === 'NT_IsSupplierViewable' || (mode === 'edit' && body?.US_UserName !== userName))
            ) {
                return true;
            } else {
                return false;
            }
        }
        return false;
    };

    const readEventNotes = () => {
        if (!body?.NT_PK) {
            return false;
        }

        readEvents(body?.NT_PK ?? '')
            .then((res: any) => {
                setEventNotes(res);
            })
            .catch((err: any) => console.log(err));
    };

    return (
        <div className="app-modal large-popup notes-modal quote-module">
            <div className="modal-content-holder sop-content-holder large-popup">
                <div className="modal-header">
                    <h2 className="title">{mode === 'add' ? 'Add' : 'Edit'} Note</h2>
                    <button onClick={() => onClose()} className="app-btn modal-close-btn" title="Close">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-section card">
                        <div className="modal-main-content details-box">
                            <div className="input-form-wrapper">
                                <div className="modal-col-top-1">
                                    <div className=" modal-input-holder">
                                        <label htmlFor="hsCode" className="modal-input-label">
                                            Subject<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder text-area-holder">
                                            <input
                                                placeholder="Enter Subject"
                                                type="text"
                                                className="modal-input input-text with-border"
                                                id="NT_Subject"
                                                name="NT_Subject"
                                                value={body.NT_Subject}
                                                onChange={(e) =>
                                                    setBody((prev: any) => ({
                                                        ...prev,
                                                        [e.target.name]: e.target.value,
                                                    }))
                                                }
                                                maxLength={50}
                                                disabled={modalDisabled()}
                                            ></input>
                                            <span className="text-area-count">{body.NT_Subject?.length}/50</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-col-top-2">
                                    <div className=" modal-input-holder">
                                        <label htmlFor="hsCode" className="modal-input-label">
                                            Note Type<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder">
                                            <SearchableSelectBox
                                                data={noteTypeData}
                                                placeholder="Select Note Type"
                                                setStateValue={onSelectBoxChange}
                                                stateValue={body.NT_CD_NoteType}
                                                fieldName="NT_CD_NoteType"
                                                menuPortalTarget={document.getElementById('outside_div')}
                                                isDisabled={modalDisabled()}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-col-top-3">
                                    <div className="checkbox-wrapper-modal modal-input-holder">
                                        <label htmlFor="hsCode" className="modal-input-label">
                                            Viewable By
                                        </label>
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="NT_IsForwarderViewable"
                                                    checked={body.NT_IsForwarderViewable}
                                                    onChange={onCheckboxChange}
                                                    disabled={true}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Forwarder</div>
                                            </label>
                                        </div>
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="NT_IsAgentViewable"
                                                    checked={body.NT_IsAgentViewable}
                                                    onChange={onCheckboxChange}
                                                    disabled={modalDisabled('NT_IsAgentViewable')}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Agent</div>
                                            </label>
                                        </div>
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="NT_IsClientViewable"
                                                    checked={body.NT_IsClientViewable}
                                                    onChange={onCheckboxChange}
                                                    disabled={modalDisabled('NT_IsClientViewable')}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Client</div>
                                            </label>
                                        </div>
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="NT_IsSupplierViewable"
                                                    checked={body.NT_IsSupplierViewable}
                                                    onChange={onCheckboxChange}
                                                    disabled={modalDisabled('NT_IsSupplierViewable')}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Supplier</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div className="modal-col-top-4">
                                    <div className="checkbox-wrapper-modal">
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="NT_IsLockedToCreator"
                                                    checked={body.NT_IsLockedToCreator}
                                                    onChange={onCheckboxChange}
                                                    disabled={modalDisabled()}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Only note creator can edit</div>
                                            </label>
                                        </div>
                                        <div className="checkbox-item">
                                            <label className="app-check-wrapper">
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-input"
                                                    name="NT_IsImportant"
                                                    checked={body.NT_IsImportant}
                                                    onChange={onCheckboxChange}
                                                    disabled={modalDisabled()}
                                                ></input>
                                                <div className="checkmark">
                                                    <svg className="svg-icon notification-circle-icon">
                                                        <use href="#tickIcon">
                                                            <title>check mark</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="checkbox-label">Important</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="input-form-wrapper">
                                <div className="modal-col-bottom">
                                    <div className=" modal-input-holder" style={{ paddingBottom: '16px' }}>
                                        <label htmlFor="name" className="modal-input-label">
                                            Body
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder text-area-holder">
                                            <textarea
                                                style={{ width: '100%' }}
                                                name="NT_Text"
                                                placeholder="Enter Body"
                                                rows={7}
                                                onChange={(e) =>
                                                    setBody((prev: any) => ({
                                                        ...prev,
                                                        [e.target.name]: e.target.value,
                                                    }))
                                                }
                                                disabled={modalDisabled()}
                                                className="input-text"
                                                value={body.NT_Text}
                                                maxLength={2000}
                                            ></textarea>
                                            <span className="text-area-count">{body.NT_Text?.length}/2000</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button
                                onClick={() => readEventNotes()}
                                className="app-btn app-btn-secondary footer-btn read-events-btn"
                                title="Read Events"
                                type="button"
                            >
                                <span className="button-text footer-button-text">Read Events</span>
                            </button>
                            <br />
                            {eventNotes?.length > 0 && (
                                <div className="grid-container">
                                    <div className="grid-scroll-content">
                                        <table className="grid-table">
                                            <thead>
                                                <tr className="grid-sticky-row">
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Code</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Event Details</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Date</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {eventNotes?.map((res: any, index: number) => (
                                                    <tr key={index} className="has-child row-expanded">
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.EV_CD_Code}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.EV_Context}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {res?.EV_CreatedDateTime}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => onSubmit()}
                        disabled={submitButtonDisabled() || modalDisabled('submit')}
                        className="app-btn app-btn-primary footer-btn"
                        title="Submit"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                    <button
                        onClick={() => onClose()}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Cancel"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
