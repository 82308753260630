import { ReactElement } from 'react';
// import './style.css';
import '../../../../assets/scss/components/_modal.scss';

interface CancelModalProps {
    onCancel: () => void;
    onConfirm: () => void;
}

const Index = ({ onCancel, onConfirm }: CancelModalProps): ReactElement => {
    return (
        <div className="app-modal container">
            <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                <div className="modal-header">
                    <h2 className="title">Discard Changes</h2>
                    <button className="app-btn modal-close-btn" onClick={() => onCancel()}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <p>Are you sure you want to discard all the changes made ?</p>
                </div>
                <div className="modal-footer">
                    <button className="app-btn app-btn-secondary modal-btn" onClick={() => onCancel()}>
                        <span className="button-text vendor-button-text">No</span>
                    </button>
                    <button
                        style={{ marginLeft: '8px' }}
                        className="app-btn app-btn-primary modal-btn"
                        onClick={() => onConfirm()}
                    >
                        <span className="button-text vendor-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
