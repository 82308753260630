import React, { ReactElement, useRef, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    getNotification,
    selectNotification,
    markSingleRead,
    markAllRead,
    selectAuthLoading,
} from 'src/store/reducers/authReducer';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';
import moment from 'moment';
import { notificationContentDetailsList, notificationListIcon } from './NotificationList';

const NotificationList = (): ReactElement => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const notification = useAppSelector(selectNotification);
    const loading = useAppSelector(selectAuthLoading);
    const [showOnlyUnread, setShowOnlyUnread] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationData, setNotificationData] = useState<any>([]);
    const wrapperRefNot = useRef<HTMLDivElement>(null);
    const notificationRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRefNot, setShowNotification);

    useEffect(() => {
        if (notification?.data?.length > 0) {
            setNotificationData(notification);
        }
    }, [notification]);

    const scroll = () => {
        if (notificationRef.current?.scrollTop) {
            notificationRef.current.scrollTop = 0;
        }
    };

    useEffect(() => {
        setTimeout(() => {
            scroll();
            if (!showNotification) {
                setShowOnlyUnread(false);
            }
        }, 300);
    }, [showNotification, showOnlyUnread]);

    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;
    useEffect(() => {
        if (username && showNotification) {
            dispatch(getNotification({}));
        }
    }, [showNotification]);

    const markAsRead = (res: any) => {
        dispatch(
            markSingleRead({
                id: res?.notificationId,
                status: res?.isRead === 1 ? 'unread' : 'read',
            })
        );
        setNotificationData({
            total: notificationData.total,
            unread: res?.isRead ? notificationData.unread + 1 : notificationData.unread - 1,
            data: notificationData?.data?.map((obj: any) =>
                obj.notificationId === res.notificationId
                    ? {
                          ...obj,
                          isRead: obj.isRead === 1 ? 0 : 1,
                      }
                    : obj
            ),
        });
    };

    const markAsReadOnRedirect = (res: any) => {
        setShowNotification(false);
        dispatch(
            markSingleRead({
                id: res?.notificationId,
                status: 'read',
            })
        );
        setNotificationData({
            total: notificationData.total,
            unread: notificationData.unread - 1,
            data: notificationData?.data?.map((obj: any) =>
                obj.notificationId === res.notificationId
                    ? {
                          ...obj,
                          isRead: 1,
                      }
                    : obj
            ),
        });
    };

    const markAllAsRead = () => {
        dispatch(markAllRead({}));
        setNotificationData({
            total: notificationData.total,
            unread: 0,
            data: notificationData?.data?.map((obj: any) => {
                return {
                    ...obj,
                    isRead: 1,
                };
            }),
        });
    };
    return (
        <div
            ref={wrapperRefNot}
            className={`dropdown-wrap menu-down ${
                showNotification ? ' dropdown-open ' : ''
            } user-notification-dropdown`}
        >
            <a
                onClick={(e: any) => {
                    e.stopPropagation();
                    setShowNotification((prev) => !prev);
                }}
                className={`notification-nav update-notification btn-pointer-cursor ${
                    notificationData?.unread > 0 ? 'active' : ''
                }`}
            >
                <svg className="svg-icon notification-icon">
                    <use xlinkHref="#notificationIcon">
                        <title>Notification</title>
                    </use>
                </svg>
            </a>
            <div className="dropdown-menu">
                {notificationData?.data?.length ? (
                    <>
                        <div className="notification-update-state">
                            <div className="notification-header-wrapper">
                                <div className="notification-main-status-wrapper">
                                    <h2 className="page-heading">Notifications</h2>
                                    <div className="status-wrapper">
                                        <div className="item-count-status-holder">
                                            {notificationData?.unread > 0 && (
                                                <div className="grid-item-count-status status-ui warning-status">
                                                    <span className="count"></span>
                                                    <span className="status-text">
                                                        {notificationData?.unread} unread
                                                    </span>
                                                </div>
                                            )}
                                        </div>
                                        <a onClick={() => markAllAsRead()} className="add-content-link read-all-link">
                                            Mark all as read
                                        </a>
                                    </div>
                                </div>
                                <div className="details-box-heading">Showing notifications for last 30 days</div>
                            </div>
                            <div className="content-items-holder">
                                <div className="unread checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            checked={showOnlyUnread}
                                            onChange={() => {
                                                setShowOnlyUnread(!showOnlyUnread);
                                            }}
                                            className="checkbox-input"
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Show only unread</div>
                                    </label>
                                </div>
                                <div ref={notificationRef} className="notification-content-wrapper">
                                    {notificationData?.data &&
                                        notificationData?.data
                                            ?.filter((i: any) => (showOnlyUnread ? !i.isRead : true))
                                            ?.map((res: any, index: number) => {
                                                const message = notificationContentDetailsList(res);

                                                return (
                                                    <div key={index} className="notification-item">
                                                        <div className="menu-item notification-list">
                                                            <div className="content-icon">
                                                                {notificationListIcon(res?.notificationType)}
                                                            </div>
                                                            <div className="content-items-wrapper">
                                                                <div className="notification-content-list">
                                                                    {message?.type === 'link' ? (
                                                                        <>
                                                                            {message?.firstPart}{' '}
                                                                            {message?.keyToSplit != 'null' && (
                                                                                <Link
                                                                                    to={message?.redirectTo}
                                                                                    onClick={() =>
                                                                                        markAsReadOnRedirect(res)
                                                                                    }
                                                                                    className="content-link"
                                                                                >
                                                                                    {message?.keyToSplit}
                                                                                </Link>
                                                                            )}{' '}
                                                                            {message?.lastPart}{' '}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {message?.firstPart}{' '}
                                                                            <a
                                                                                onClick={() => {
                                                                                    navigate(message?.redirectTo, {
                                                                                        state: message?.state,
                                                                                    });
                                                                                    markAsReadOnRedirect(res);
                                                                                }}
                                                                                style={{ cursor: 'pointer' }}
                                                                                className="content-link"
                                                                            >
                                                                                {message?.keyToSplit}
                                                                            </a>
                                                                            {message?.lastPart}{' '}
                                                                        </>
                                                                    )}
                                                                    <span className="details-box-heading">
                                                                        {moment
                                                                            .utc(res?.createdDate)
                                                                            .local()
                                                                            .format('DD-MM-YYYY, HH:mm')}
                                                                    </span>
                                                                </div>
                                                                <div className="checkbox-item">
                                                                    <label
                                                                        className="app-check-wrapper"
                                                                        title={
                                                                            res?.isRead === 0
                                                                                ? 'Mark as read'
                                                                                : 'Mark as unread'
                                                                        }
                                                                    >
                                                                        <input
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            checked={res?.isRead === 0 ? true : false}
                                                                            onChange={() => markAsRead(res)}
                                                                        ></input>
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon notification-circle-icon">
                                                                                <use href="#circleIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}

                                    {notificationData?.data?.filter((i: any) => (showOnlyUnread ? !i.isRead : true))
                                        ?.length > 0 && (
                                        <div className="notification-end">
                                            <div className="end-content">
                                                <span className="end-contents-item">
                                                    You have reached end of the list
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {notificationData?.data?.filter((i: any) => (showOnlyUnread ? !i.isRead : true))
                                        ?.length === 0 && (
                                        <div className="no-notification">
                                            <div className="icon-no-notification">
                                                <svg className="svg-icon no-notification-icon">
                                                    <use xlinkHref="#noNotificationIcon">
                                                        <title>Notification</title>
                                                    </use>
                                                </svg>
                                            </div>
                                            <h2 className="no-notification-heading">No Unread Notifications</h2>
                                            <p className="no-notification-content">
                                                You have no unread notification from last 30 days
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="notification-update-state">
                            <div className="notification-header-wrapper">
                                <h2 className="page-heading">Notifications</h2>
                            </div>
                            <div className="content-items-holder">
                                <div className="notification-content-wrapper">
                                    <div className="no-notification">
                                        {loading ? (
                                            <h2 className="no-notification-heading loading">Loading</h2>
                                        ) : (
                                            <>
                                                <div className="icon-no-notification">
                                                    <svg className="svg-icon no-notification-icon">
                                                        <use xlinkHref="#noNotificationIcon">
                                                            <title>Notification</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <h2 className="no-notification-heading">No Notifications</h2>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default NotificationList;
