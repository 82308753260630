import React, { useState, useEffect } from 'react';
import './inventory.scss';
import InventoryStockGrid from './InventoryStockGrid/index';
import InventoryDiscrepanciesGrid from './InventoryDiscrepanciesGrid/index';
import { useLocation } from 'react-router-dom';
const Index: React.FC = () => {
    const [currentPage, setcurrentPage] = useState('stock');
    const [logAvailable, setlogAvailable] = useState(false);
    const [isWarehouseIntegrated, setisWarehouseIntegrated] = useState(false);
    const location = useLocation();
    const [clientDropDownOpen, setClientDropDownOpen] = useState(false);
    const [warehouseDropDownOpen, setWarehouseDropDownOpen] = useState(false);

    useEffect(() => {
        if (location.state && (location as any).state?.discrepancy && isWarehouseIntegrated) {
            setcurrentPage('discrepencies');
        } else {
            setcurrentPage('stock');
        }
    }, [location.state, isWarehouseIntegrated]);

    return (
        <div
            className="main-wrapper"
            onClick={() => {
                setClientDropDownOpen(false);
                setWarehouseDropDownOpen(false);
            }}
        >
            <div className="main-content-holder no-main-grid-border purchase-grid inventory-grid">
                <h2 className="page-heading">Inventory</h2>
                {currentPage === 'stock' ? (
                    <InventoryStockGrid
                        setcurrentPage={setcurrentPage}
                        setlogAvailable={setlogAvailable}
                        logAvailable={logAvailable}
                        setisWarehouseIntegrated={setisWarehouseIntegrated}
                        clientDropDownOpen={clientDropDownOpen}
                        warehouseDropDownOpen={warehouseDropDownOpen}
                        setClientDropDownOpen={setClientDropDownOpen}
                        setWarehouseDropDownOpen={setWarehouseDropDownOpen}
                    ></InventoryStockGrid>
                ) : (
                    <InventoryDiscrepanciesGrid
                        setcurrentPage={setcurrentPage}
                        setlogAvailable={setlogAvailable}
                        logAvailable={logAvailable}
                        setWarehouseDropDownOpen={setWarehouseDropDownOpen}
                        warehouseDropDownOpen={warehouseDropDownOpen}
                        clientDropDownOpen={clientDropDownOpen}
                        setClientDropDownOpen={setClientDropDownOpen}
                    ></InventoryDiscrepanciesGrid>
                )}
            </div>
        </div>
    );
};
export default Index;
