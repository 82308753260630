import { AxiosResponse } from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { profilesHeaderApi } from 'src/services/apis/profiles/clients';
import { fetchHeaderDataClients, saveHeaderDataClients } from 'src/store/reducers/profiles/clients';

// eslint-disable-next-line

function* getHeaderData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(profilesHeaderApi, payload);
        yield put({ type: saveHeaderDataClients, payload: response });
    } catch (er) {
        console.log(er);
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line

function* profileClientsSaga() {
    yield takeLatest(fetchHeaderDataClients, getHeaderData);
}

export default profileClientsSaga;
