import { get } from '../../../HttpMethods';

export const profilesHeaderVendorApi = async (payload: any) => {
    try {
        const data = await get(`api/mgt/vendors/profile/header?vn_pk=${payload}`, null);
        return data;
    } catch (error) {
        return error;
    }
};
