import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    addSelectedPosAir,
    AvailablePoAirResponse,
    getAvailablePosAir,
    getBpDetailsAir,
    getPoDataAir,
    searchAvailablePosAir,
} from 'src/services/apis/bookingProposalAir/availablePosApi';
import {
    addOrSavePoDataAir,
    fetchAvailablePoDataAir,
    getBpDataAir,
    isLoadingFn,
    savePoDataAir,
    searchAvailablePoDataSuccessAir,
} from 'src/store/reducers/bookingProposalAir/availablePosReducer';

function* getAvailablePoAirListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<AvailablePoAirResponse> = yield call(getAvailablePosAir, payload);
        payload['response'] = response;
        yield put({ type: fetchAvailablePoDataAir, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addSelectedAvailablePosAir({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(addSelectedPosAir, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: addOrSavePoDataAir, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getBookingProposalDataAir({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any[]> = yield call(getBpDetailsAir, payload);
        payload['success'] = true;
        payload['data'] = data;
        yield put({ type: getBpDataAir, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getAllPoDataAir({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any[]> = yield call(getPoDataAir, payload);
        yield put({ type: savePoDataAir, payload: data });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* searchAvailablePOListDataAir({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(searchAvailablePosAir, payload);
        payload['response'] = response;
        yield put({ type: searchAvailablePoDataSuccessAir, payload: payload });
    } catch (err) {
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* airAvailablePoListSaga() {
    yield takeEvery('availablePosAir/fetchAvailablePoDataAir', getAvailablePoAirListData);
    yield takeEvery('availablePosAir/getBpDataAir', getBookingProposalDataAir);
    yield takeEvery('availablePosAir/poDataFetchAir', getAllPoDataAir);
    yield takeEvery('availablePosAir/addOrSavePoDataAir', addSelectedAvailablePosAir);
    yield takeEvery('availablePosAir/searchAvailablePoDataAir', searchAvailablePOListDataAir);
}

export default airAvailablePoListSaga;
