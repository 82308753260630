import { createSlice } from '@reduxjs/toolkit';
import { activeStatus, aoHeaders, activeAos } from 'src/utils/constants';

export const dataSlice = createSlice({
    name: 'allocationOrderList',
    initialState: {
        allocationOrders: [],
        filterValues: [],
        isLoading: true,
        isLoadingAO: true,
        currentPage: 0,
        columnStatus: aoHeaders.orderDate,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        statusCount: {},
        error: null,
        success: false,
        filter: { aoStatus: activeStatus },
        selectedDropDown: {
            text: activeAos,
            count: 0,
        },
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        resetPage: (state, _action) => {
            state.currentPage = 0;
        },
        fetchAoData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.isLoading = false;
            state.isLoadingAO = true;
        },
        saveAoData: (state, action) => {
            state.isLoading = true;
            state.allocationOrders = action.payload.allocationOrders?.aoListingDtos;
            state.totalPages = action.payload.allocationOrders?.totalPages;
            state.totalItems = action.payload.allocationOrders?.totalElements;
            state.isLoading = false;
            state.isLoadingAO = false;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        fetchAoFilterData: (state, action) => {
            state.isLoading = true;
            if (action.payload?.filterData) {
                state.filterValues = action.payload?.filterData;
            }
            state.isLoading = false;
        },
        fetchAoStatusCount: (state, action) => {
            state.isLoading = true;
            state.statusCount = action.payload?.count;
            state.isLoading = false;
        },
        cancelDiscardAO: (state, action) => {
            state.isLoading = true;
            if (!action.payload.success) {
                state.error = action.payload.error;
            } else {
                state.success = true;
            }
            state.isLoading = false;
        },
        reset: (state) => {
            state.success = false;
            state.error = null;
        },
        setFilter: (state, action) => {
            state.filter = action.payload;
        },
        setSelectedDropDown: (state, action) => {
            state.selectedDropDown = action.payload;
        },
    },
});

export const {
    cancelDiscardAO,
    fetchAoData,
    fetchAoFilterData,
    fetchAoStatusCount,
    isLoadingFn,
    reset,
    setPageCount,
    saveAoData,
    setDefault,
    resetPage,
    setFilter,
    setSelectedDropDown,
} = dataSlice.actions;
export default dataSlice.reducer;
