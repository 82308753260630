/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
// import ConfirmBookingModal from './ConfirmBookingModal';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchLclThreshold } from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import Breadcrumbs from 'src/components/Breadcrumbs/purchaseOrder';
// import './index.scss';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';

import { useUserProfile } from 'src/hooks/useUserProfile';
// import { Formik } from 'formik';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { Icon } from 'semantic-ui-react';
import { cixDownloadFile, fileEventHistory } from 'src/services/apis/documentApi';
import DeleteModal from './deleteConfirmModal';
import EventModal from './eventModal';
import { resetDeleteResult } from 'src/store/reducers/documentReducer';
import FileUploadModal from 'src/pages/Common/fileUploader';

const Index: React.FC = () => {
    const { id } = useParams();
    const { userName, profileType } = useUserProfile();
    const dispatch = useAppDispatch();
    const headerData = useAppSelector(selectHeader);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [doPk, setDoPk] = useState('');
    const { deleteResult } = useSelector((state: RootState) => state.document);
    const [eventHistory, setEventHistory] = useState([]);

    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;

    useEffect(() => {
        // dispatch(
        //     fetchProductDetailsConfirmationList({
        //         poId: id,
        //     })
        // );
        dispatch(fetchPermissions(username));
        dispatch(fetchLclThreshold({ userName }));
        // dispatch(fetchPort({ key: 'a', type: 'PO', service: 'ord' }));
    }, []);

    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';

    useEffect(() => {
        if (deleteResult?.length > 0) {
            if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
            dispatch(resetDeleteResult({}));
            setShowDeleteModal(false);
            setDoPk('');
        }
    }, [deleteResult]);

    const downloadFile = (doPk: string, filename: string) => {
        cixDownloadFile(doPk, filename);
    };

    //@ts-ignore
    window.attachmentAction = (action: string, doPK?: string, filename?: string) => {
        if (action === 'DLD') {
            if (doPK && filename) downloadFile(doPK, filename);
        } else if (action === 'DEL') {
            if (doPK) {
                setDoPk(doPK);
                setShowDeleteModal(true);
            }
        } else if (action === 'EVT') {
            if (doPK) {
                fileEventHistory(doPK ?? '')
                    .then((res: any) => {
                        setEventHistory(res);
                        setShowEventModal(true);
                    })
                    .catch((err: any) => console.log(err));
            }
        } else if (action === 'NEW') {
            setShowUploadModal(true);
        }
    };

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={`${poName}`}
                        firstUrl={`${poUrl}`}
                        secondName="PO Details"
                        secondUrl={`/purchase_order/${id}/product_details_confirmation`}
                    />
                    <PurchaseOrderHeader id={id} />
                    <PODetailsHeaderTabs />
                </div>
                <div className="lifecycle-grid">
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'lifecycle'} content={'purchase-attachments'} />
                    </div>
                </div>
            </div>
            {showUploadModal && (
                <FileUploadModal setShowFileUploadModal={setShowUploadModal} referenceId={id} referenceType={'PO'} />
            )}

            {showDeleteModal && (
                <DeleteModal setModalView={setShowDeleteModal} modalView={showDeleteModal} doPK={doPk} />
            )}

            {showEventModal && <EventModal setModalView={setShowEventModal} eventHistory={eventHistory} />}
        </>
    );
};
export default Index;
