import React from 'react';
import './transit-sidebar.scss';
import '../../styles/map-sidepanel-common-style.scss';
import { useSelector } from 'react-redux';
import { saveSingleMapData, selectedLocation, showSidebar, sideBarType } from 'src/store/reducers/dashboard/map';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
const Index: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { sideBarView, sideBarData, sideBarValue, singleMapData, isLoadingSideBar, locationValue } = useSelector(
        (state: any) => state.map
    );

    const bpRedirect = (type: string, id: any) => {
        switch (type.toLowerCase()) {
            case 'fcl':
                return window.open(`/booking_list/${id}/POSelection`, '_blank');
            case 'bcn':
                return window.open(`/bcnBookingList/${id}/POSelection`, '_blank');
            case 'lcl':
                return window.open(`/lclbooking_list/${id}/POSelection`, '_blank');
            case 'ao':
                return window.open(`/Aobooking_list/${id}/AoSelection`, '_blank');
            case 'air':
                return window.open(`/air_booking_list/${id}/POSelection`, '_blank');
            default:
                return navigate('/');
        }
    };
    return (
        <div className={`map-sidebar ${sideBarView ? 'open' : ''}`}>
            {isLoadingSideBar && (
                <div className="graph-holder" style={{ zIndex: '200', height: '100%' }}>
                    <div className="graph-scaler loading"></div>
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                </div>
            )}
            {sideBarValue && (
                <button
                    className="app-btn arrow-icon-holder"
                    onClick={() => {
                        dispatch(showSidebar(!sideBarView));
                        // dispatch(saveSingleMapData({}));
                    }}
                    title="Collapse Panel"
                >
                    <svg className="svg-icon dashboard-header-icon">
                        <use xlinkHref="#downArrow">
                            <title>dropdown</title>
                        </use>
                    </svg>
                </button>
            )}
            <div className="side-bar active">
                <div className="sidebar-header">
                    <button
                        className="app-btn text-close-btn"
                        onClick={() => {
                            dispatch(selectedLocation(!locationValue));
                            dispatch(showSidebar(!sideBarView));
                            dispatch(saveSingleMapData(null));
                            dispatch(sideBarType(''));
                        }}
                    >
                        <svg className="svg-icon text-close-icon">
                            <use href="#closeIcon"></use>
                        </svg>
                    </button>
                    <h2 className="page-heading">Bookings in Transit</h2>
                </div>
                <div className="main-content-wrapper">
                    <div className="booking-item-content">
                        <div className="booking-name-updates">
                            <div className="grid-child-data list-view-items">
                                <div className="ship-logo">
                                    {singleMapData?.shippingMode?.toLowerCase() === 'sea' ? (
                                        <svg className="svg-icon ship-icon">
                                            <use href="#shipfillIcon">
                                                <title>Ship</title>
                                            </use>
                                        </svg>
                                    ) : (
                                        <svg className="svg-icon aeroplane-icon">
                                            <use href="#aeroplaneIcon">
                                                <title>Aeroplane</title>
                                            </use>
                                        </svg>
                                    )}
                                </div>
                                <div className="content-list-wrapper">
                                    <div className="content-heading-holder">
                                        <span className="tab-heading">{singleMapData?.vesselName}</span>
                                    </div>
                                    <div className="content-detail">{singleMapData?.voyageName}</div>
                                </div>
                            </div>
                            <div className="col-updates">
                                <div className="col-content-detail">
                                    <div className="content-name">Last Updated</div>
                                    <div className="content-detail">
                                        {' '}
                                        {moment.utc(singleMapData?.lastUpdate).local().format('DD-MM-YYYY, HH:mm')}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="booking-destination">
                            <div className="col-destination">
                                <div className="des-progress-bar">
                                    <div className="destination begin-journey">
                                        <div className="point12">
                                            <div className="point1" title={singleMapData?.originPortName}>
                                                {singleMapData?.originPortName}
                                            </div>
                                            <div className="point-name">({singleMapData?.originPortCode})</div>
                                        </div>
                                        <div className="date-time">
                                            <div className="destination-name">ETD</div>
                                            <div className="time-field">
                                                {moment.utc(singleMapData?.etd).local().format('DD-MM-YYYY, HH:mm')}
                                            </div>
                                        </div>
                                        <div className="date-time">
                                            <div className="destination-name">ATD</div>
                                            <div className="time-field">
                                                {moment.utc(singleMapData?.atd).local().format('DD-MM-YYYY, HH:mm')}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="destination center-journey">
                                        <span className="center-point">{singleMapData?.noOfStops} Stop</span>
                                    </div>
                                    <div className="destination end-journey">
                                        <div className="point12">
                                            <div className="point1" title={singleMapData?.destinationPortName}>
                                                {singleMapData?.destinationPortName}
                                            </div>
                                            <div className="point-name">({singleMapData?.destinationPortCode})</div>
                                        </div>
                                        <div className="date-time">
                                            <div className="time-field">
                                                {moment.utc(singleMapData?.eta).local().format('DD-MM-YYYY, HH:mm')}
                                            </div>
                                            <div className="destination-name">ETA</div>
                                        </div>
                                        <div className="date-time">
                                            <div className="time-field">
                                                {singleMapData?.ata
                                                    ? moment.utc(singleMapData?.ata).local().format('DD-MM-YYYY, HH:mm')
                                                    : '-'}
                                            </div>
                                            <div className="destination-name">ATA</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-name-content">
                                <div className="col-content-detail">
                                    <div className="content-name">Next Stop</div>
                                    <div className="content-detail">
                                        {singleMapData?.nextStopName},{' '}
                                        {moment.utc(singleMapData?.nextStopETA).local().format('DD-MM-YYYY, HH:mm')}
                                    </div>
                                </div>
                                <div className="col-content-detail">
                                    <div className="content-name">Speed</div>
                                    <div className="content-detail">{singleMapData?.speed} Knots</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {singleMapData?.bookings?.map((res: any) => (
                        <div className="detail-content-items">
                            <div className="col-booking-status">
                                <div className="booking-id">
                                    <a
                                        onClick={() => bpRedirect(res?.bpType ?? res?.containerMode, res?.bpId)}
                                        className="booking-link"
                                    >
                                        {res?.bpNumber}
                                    </a>
                                </div>
                                <div className="booking-badges">
                                    <div className="count">{res?.bpType ?? res?.containerMode}</div>
                                    <div className="count">{res?.poCount ?? res?.totalPo} POs</div>
                                    <div className="count">{res?.teu ?? res?.totalTeu} TEU</div>
                                </div>
                            </div>
                            <div className="col-destination">
                                <div className="des-progress-bar">
                                    <div className="destination begin-journey">
                                        <div className="point12">
                                            <div className="point1" title={res?.originPortName}>
                                                {res?.originPortName}
                                            </div>
                                        </div>
                                        <div className="date-time">
                                            <div className="time-field port-code">({res?.originPortCode})</div>
                                        </div>
                                    </div>
                                    <div className="destination center-journey">
                                        <span className="center-point">{res?.numberOfStops} Stop</span>
                                    </div>
                                    <div className="destination end-journey">
                                        <div className="point12">
                                            <div className="point1" title={res?.destinationPortName}>
                                                {res?.destinationPortName}
                                            </div>
                                        </div>
                                        <div className="date-time">
                                            <div className="time-field port-code">({res?.destinationPortCode})</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-name-content">
                                <div className="col-content-detail">
                                    <div className="content-name">Client</div>
                                    <div className="content-detail" style={{ wordBreak: 'break-word' }}>
                                        {res?.client[0]?.name ?? res?.client}
                                    </div>
                                </div>
                                {res?.carrier && (
                                    <div className="col-content-detail" style={{ wordBreak: 'break-word' }}>
                                        <div className="content-name">Carrier</div>
                                        <div className="content-detail">{res?.carrier}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="sidebar-backdrop"></div>
        </div>
    );
};
export default Index;
