import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, post } from '../../HttpMethods';

export interface AvailablePOResponse {
    success: boolean;
    info: Info;
}

export interface Info {
    availablePoLines: AvailablePoLine[];
    distinctColumnValues: DistinctColumnValues;
}

export interface AvailablePoLine {
    poId: string;
    cgId: string;
    poNumber: string;
    createdOn: Date;
    goodsReadyDate: Date;
    clientName: string;
    coLoader: null;
    originPort: string;
    destinationPort: string;
    vendorName: string;
}

export interface DistinctColumnValues {
    destinationPort: string[];
    clientName: string[];
    originPort: string[];
    vendorName: string[];
    poNumber: string[];
}

export interface AvailablePoData {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    createdDate: string;
    clientName: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
    createdOn: string;
    totKGS: number;
    totCBM: number;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

export interface AvailablePoResponse {
    poListingDtos: AvailablePoData[];
}

export interface CreateBpResponse {
    success: boolean;
    info: {
        bpId: string;
        bpNumber: string;
        totalCBM: number;
        totalKgs: number;
        client: string;
        originPort: string;
        destinationPort: string;
        goodsReadyDate: string[];
        purchaseOrders: AvailablePoData[];
    };
}
/* eslint-disable no-undefined */

export const getAvailablePos = async (payload: {
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    id: string;
    profileType: string;
}) => {
    try {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const res: AvailablePOResponse = await post(
            `api/bkg/bp/list/confirmationgroups/${payload.profileType}?sortColumn=${payload.columnStatus}&sortOrder=${
                payload.columnOrder ? 'DESC' : 'ASC'
            }${payload.id !== undefined ? `&cgId=${payload.id}` : ''}&type=BCN`,
            { ...payload.data, timeZone },
            null
        );

        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getFilterValuesForAvailablePos = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/bkg/bp/distinctpocolumns/${payload.profileType}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const addSelectedPos = async (payload: { bpId?: string; cgIds: string[]; bpType: string }) => {
    try {
        if (payload?.bpId) {
            const res = await patch(
                `cix/scr/forwarding/booking/${payload.bpId}/confirmationgroup/${payload.cgIds[0]}/ATH?BP_Type=${payload.bpType}`,
                null,
                externalBaseUrl
            );
            return res;
        } else {
            const res = await patch(
                `cix/scr/forwarding/booking/undefined/confirmationgroup/${payload.cgIds[0]}/ATH?BP_Type=${payload.bpType}`,
                null,
                externalBaseUrl
            );
            return res;
        }
    } catch (err) {
        return console.error(err);
    }
};

export const getBpDetails = async (payload: any) => {
    try {
        const res = await post(
            `api/bkg/bp/details?sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}&sortColumn=${payload.columnStatus}`,
            payload,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const getPoData = async (payload: any) => {
    try {
        const res = await get(`api/bkg/po/details/${payload}`, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const searchAvailablePos = async (payload: {
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    id: string;
    profileType: string;
    keyword: string;
}) => {
    try {
        const res = await post(
            `api/ord/search/availablebcn/${payload.profileType}?keyword=${payload.keyword}&sortColumn=${
                payload.columnStatus
            }&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}${
                payload.id !== undefined ? `&cgId=${payload.id}` : ''
            }&type=BCN`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
