import React from 'react';
// import './style.css';
import '../../../assets/scss/components/_modal.scss';
import '../../../pages/Profiles/Clients/VendorDetails/vendor-modal.scss';
import ShowForPermissions from '../../../ShowForPermissions';

interface BioProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    title: string;
    okButtonName: string;
    childComp?: React.ReactNode;
    disable: boolean;
    isEdit: boolean;
    isFromView: boolean;
    mode: string;
    setMode: any;
    setData: any;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    dirty: any;
    setIsEdit: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setIsFromView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const FormikModal: React.FC<BioProps> = (props) => {
    let saveButtonMessage = '';
    let largeBtn = '';
    if (props.mode === 'add') {
        saveButtonMessage = props.title.includes('SOP') ? 'Add SOP' : 'Add Contract';
        largeBtn = props.title.includes('SOP') ? '' : 'large-btn';
    } else {
        saveButtonMessage = 'Save';
    }
    return (
        <>
            {props.display ? (
                <div className="app-modal">
                    <div
                        className="modal-content-holder vendor-content-holder medium-popup"
                        // onClick={(e) => e.stopPropagation()}
                    >
                        <div className="modal-header">
                            <div className="title">{props.title}</div>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => {
                                    // props.displayFunction(false);
                                    props.setIsFromView(false);
                                    props.dirty && !props.isEdit
                                        ? props.setDisplayCancel(true)
                                        : props.displayFunction(false);
                                }}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        {props.childComp}
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => {
                                    // props.displayFunction(false);
                                    props.setIsFromView(false);
                                    props.dirty && !props.isEdit
                                        ? props.setDisplayCancel(true)
                                        : props.displayFunction(false);
                                }}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <ShowForPermissions permission="edit" type="client">
                                {props.isEdit ? (
                                    <button
                                        style={{ marginLeft: '10px' }}
                                        className="app-btn app-btn-primary modal-btn footer-btn"
                                        type="button"
                                        // form="my-form"
                                        onClick={() => {
                                            props.setIsEdit(false);
                                            props.setIsFromView(true);
                                            props.setMode('edit');
                                        }}
                                    >
                                        <span className="button-text vendor-button-text">Edit</span>
                                    </button>
                                ) : (
                                    <button
                                        style={{ marginLeft: '10px' }}
                                        className={`app-btn app-btn-primary modal-btn footer-btn ${largeBtn}`}
                                        type="submit"
                                        form="my-form"
                                        onClick={() => props.setIsFromView(false)}
                                        // disabled={!props.dirty}
                                    >
                                        <span className="button-text vendor-button-text">{saveButtonMessage}</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default FormikModal;
