import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from 'src/store/reducers';

import '../todo-grid-view.scss';
import { useDispatch } from 'react-redux';
import { markSingleRead } from 'src/store/reducers/authReducer';

const Index: React.FC<any> = ({ path }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { todoItem, isLoading } = useSelector((state: RootState) => state.dashboardTodo);
    const [_expandGoods, setexpandGoods] = useState(false);
    // const [goodIndex, setgoodIndex] = useState(0);
    console.log(todoItem, 'todoItem');

    return (
        <>
            {!todoItem?.length && !isLoading && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No recent activity from the last 30 Days</p>
                </div>
            )}
            <div className="view-list-grid draft-po-view" onClick={() => setexpandGoods(false)}>
                {Array.isArray(todoItem) &&
                    todoItem?.map((po: any, i: any) => (
                        <div key={i} className={`row-wrapper ${!po?.isViewed ? ' click-active' : ''}`}>
                            <div className="list-grid">
                                <div className="grid-child-item col-name">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">PO No.</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {po.poNumber?.toString()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item span-2"></div>
                                <div className="grid-child-item col-destination">
                                    {/* {po.originPort && po.destinationPort && (
                                        <div className="grid-child-data">
                                            <div className="des-progress-bar">
                                                <div className="destination begin-journey">
                                                    <div className="point12">
                                                        <div className="point1" title="Yantain">
                                                            {po.originPortName}
                                                        </div>
                                                        {po?.originPortCode && (
                                                            <div className="point-name">({po?.originPortCode})</div>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="destination end-journey">
                                                    <div className="point1">{po.destinationPortName}</div>
                                                    {po?.destinationPortCode &&
                                                        po?.destinationPortName !== 'Multi destination' && (
                                                            <div className="point-name">
                                                                ({po?.destinationPortCode})
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    )} */}
                                </div>
                                <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                    <div className="status-badge">
                                        {po.shippingmode && (
                                            <div className="item-count-status-holder">
                                                <div className="grid-item-count-status status-ui disabled-status">
                                                    <span className="status-text">{po.shippingmode}</span>
                                                </div>
                                            </div>
                                        )}
                                        {po.skuCount && (
                                            <div className="item-count-status-holder">
                                                <div className="grid-item-count-status status-ui disabled-status">
                                                    <span className="status-text">{po.skuCount} SKU</span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        title="View Approval"
                                        className="app-btn app-btn-secondary grid-btn"
                                        onClick={() => {
                                            if (po?.notificationIds) {
                                                po?.notificationIds?.forEach((notifications: string) => {
                                                    dispatch(
                                                        markSingleRead({
                                                            id: notifications,
                                                            status: 'read',
                                                        })
                                                    );
                                                });
                                            }
                                            navigate(path(po.poId));
                                        }}
                                    >
                                        <span className="button-text footer-button-text">View Approval</span>
                                    </button>
                                </div>
                            </div>
                            <div className="list-grid">
                                <div className="grid-child-item col-client">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Booking No.</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">{po.bpNumber ?? '-'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-client">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Client</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">{po.clientName}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-vendor">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Vendor</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {po.vendorName?.toString()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-grd">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">Current Agent</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {po.currentAgentName}({po.currentAgentCode})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-child-item col-ssw">
                                    <div className="grid-child-data list-view-items">
                                        <div className="content-list-wrapper">
                                            <div className="content-heading-holder">
                                                <span className="tab-lite-heading">New Agent</span>
                                            </div>
                                            <div className="content-detail">
                                                <span className="sub-content selected-color">
                                                    {po.newAgentName}({po.newAgentCode})
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
};
export default Index;
