import React, { useEffect, useState } from 'react';
import './purchase-order-header.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { fetchCancelReason, fetchHeader, selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import CancellationDetailModal from '../BasicDetail/CancellationDetailModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
interface HeaderProps {
    id?: string;
}

const Index: React.FC<HeaderProps> = (props) => {
    const dispatch = useAppDispatch();
    const headerData = useAppSelector(selectHeader);
    const { cancelledReason } = useSelector((state: RootState) => state.addPoData);
    const [showReasonModal, setShowReasonModal] = useState(false);

    const onClose = () => {
        setShowReasonModal(false);
    };

    useEffect(() => {
        dispatch(
            fetchHeader({
                poId: props.id,
            })
        );
    }, [props.id]);

    useEffect(() => {
        if (headerData?.poStatus === 'Cancelled') {
            dispatch(fetchCancelReason({ poId: props.id }));
        }
    }, [headerData]);

    return (
        <div className="blp-purchase-order-header-fields">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    {/* <Link to="/inbound">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">
                        {/* {localStorage.getItem('poNumber') ? localStorage.getItem('poNumber') : headerData?.poNumber} */}
                        {headerData?.poNumber}
                    </h2>
                    {headerData?.poStatus === 'Cancelled' ? (
                        <div className="po-cancel-wrapper">
                            <div className="item-count-status-holder">
                                <div className="grid-item-count-status status-ui error-status">
                                    <span className="count"></span>
                                    <span className="status-text">Cancelled</span>
                                </div>
                            </div>
                            <a
                                className="add-content-link"
                                href="javascript:void(0)"
                                onClick={() => setShowReasonModal(true)}
                            >
                                View Reason
                            </a>
                        </div>
                    ) : headerData?.poStatus === 'Ready_For_Booking' ? (
                        <div className="po-cancel-wrapper">
                            <div className="item-count-status-holder">
                                <div className="grid-item-count-status status-ui success-status ready-for-booking-status">
                                    <span className="count"></span>
                                    <span className="status-text">Ready for Booking Proposal</span>
                                </div>
                            </div>
                        </div>
                    ) : headerData?.poStatus === 'Inbound_In_Progress' ? (
                        <div className="po-cancel-wrapper">
                            <div className="item-count-status-holder">
                                <div className="grid-item-count-status status-ui success-status ready-for-booking-status">
                                    <span className="count"></span>
                                    <span className="status-text">Inbound in Progress</span>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div
                            className="grid-item-count-status status-ui info-status"
                            style={{ marginLeft: '4px', maxWidth: 'none' }}
                        >
                            <span className="count"></span>
                            <span className="status-text">{headerData?.poStatus?.replaceAll('_', ' ')}</span>
                        </div>
                    )}
                    <div className="box-content-holder">
                        <h6 className="details-box-heading" style={{ color: '#737373', marginLeft: '5px' }}>
                            Created by <span style={{ color: '#23272B' }}>{headerData?.createdUser}</span>
                        </h6>
                    </div>
                </div>
            </div>
            <div className="details-box custom">
                <div className="card-detail-col  col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Inbound PO Number</h6>
                        <div className="details-box-content">{headerData?.poNumber}</div>
                    </div>
                    <div className="details-col box-content-holder client-name-col">
                        <h6 className="details-box-heading">Client Name</h6>
                        <div className="details-box-content">{headerData?.clientName}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Vendor Name</h6>
                        <div className="details-box-content">{headerData?.vendorName}</div>
                    </div>
                </div>
                <div className="card-detail-col  col-info-2">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Order Lines</h6>
                        <div className="details-box-content">{headerData?.orderLines || 0}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">{headerData?.totalCbms || 0}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">{headerData?.totalKgs || 0}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Order Value</h6>
                        {/* <div className="details-box-content">{headerData?.totalOrderValue || 0} USD</div> */}
                        <div className="details-box-content">{headerData?.totalOrderValue || 0}</div>
                    </div>
                </div>
            </div>
            {showReasonModal ? (
                <CancellationDetailModal onClose={onClose} data={cancelledReason}></CancellationDetailModal>
            ) : null}
        </div>
    );
};
export default Index;
