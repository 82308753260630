import React from 'react';

interface ToolTipProps {
    children: React.ReactNode;
    tooltipClass?: any;
}
function ToolTip(props: ToolTipProps) {
    const children = props.children;
    return (
        <div className={`tooltip-container top-right-tooltip ${props.tooltipClass}`} tabIndex={0}>
            {children}
        </div>
    );
}

export default ToolTip;
