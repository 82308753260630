import { getOrganisationData } from '../../../utils';
import { get, patch, put } from '../../HttpMethods';

export const getEnquiryData = async (payload: any) => {
    try {
        const res = await get(
            `api/ord/discrepancy/adjustment?discrepancyId=${payload.id}&profile=${payload.profile}`,
            null
        );
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const raiseEnquiry = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    const newPayload = { request: { ...payload.data }, notification: { roles, listOfUserOrganisations } };
    try {
        const res = await put(
            `api/ord/discrepancy/enquiry?discrepancyId=${payload.id}&profile=${payload.profile}`,
            newPayload
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const addCommentEnquiry = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    const newPayload = { comment: { ...payload.data }, notification: { roles, listOfUserOrganisations } };
    try {
        const res = await put(`api/ord/discrepancy/comment?discrepancyId=${payload.id}`, newPayload);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const forwardToClient = async (payload: any) => {
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    const newPayload = { forward: { ...payload.data }, notification: { roles, listOfUserOrganisations } };
    try {
        const res = await put(`api/ord/discrepancy/forward?discrepancyId=${payload.id}`, newPayload);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const viewedLogapi = async (payload: any) => {
    try {
        const res = await patch(`api/ord/discrepancy/viewedby/${payload.id}/${payload.profile}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};
