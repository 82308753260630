import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { setTodoType } from 'src/store/reducers/dashboard/dashboardTodoReducer';
// import BSTooltip from 'src/components/common/BSTooltip';

import { RootState } from 'src/store/reducers';
import { markSingleRead } from 'src/store/reducers/authReducer';

import '../todo-grid-view.scss';

const Index: React.FC<any> = ({ path }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const otherTodoName = searchParams.get('type');
    const { todoItem: allTodoItems, isLoading } = useSelector((state: RootState) => state.dashboardTodo);
    const [todoItem, setTodoItem] = useState<any>({});
    useEffect(() => {
        dispatch(setTodoType({ type: null }));
        if (allTodoItems?.otherTodo) {
            const key = location.pathname.includes('/dashboard/activity-feed') ? 'otherActivity' : 'otherTodo';
            const updated = allTodoItems?.[key]?.data?.filter((res: any) => res?.name === otherTodoName)?.[0];

            setTodoItem({
                otherTodo: updated?.data,
                todoType: updated?.type,
                todoType2: updated?.type2,
                todoType3: updated?.type3,
                totalElements: updated?.total,
                totalPages: 1,
            });
        }
    }, [allTodoItems]);

    const getNavigationUrl = (item: any) => {
        let urlEnd = '';
        if (todoItem?.todoType3 && item?.PK3 && todoItem?.todoType2 && item?.PK2) {
            urlEnd = '/' + todoItem?.todoType3 + '/' + item?.PK3 + '/' + todoItem?.todoType2 + '/' + item?.PK2;
        } else if (todoItem?.todoType2 && item?.PK2) {
            urlEnd = '/' + todoItem?.todoType2 + '/' + item?.PK2;
        } else {
            urlEnd = todoItem?.todoType === 'PO' ? '/basic_details' : '/POSelection';
        }
        if (todoItem?.todoType === 'PO') {
            if (otherTodoName?.toLowerCase()?.includes('awaiting vendor packing')) {
                return `/purchase_order/${item?.PK}/vendorPacking`;
            }
            return `/purchase_order/${item?.PK}` + urlEnd;
        } else if (todoItem?.todoType === 'BP') {
            if (item?.Mode === 'BCN') {
                return `/bcnBookingList/${item?.PK}` + urlEnd;
            } else if (item?.Mode === 'LCL') {
                return `/lclbooking_list/${item?.PK}` + urlEnd;
            } else if (item?.Mode === 'AO') {
                return `/Aobooking_list/${item?.PK}` + urlEnd;
            } else if (item?.Mode === 'AIR') {
                return `/air_booking_list/${item?.PK}` + urlEnd;
            } else {
                return `/booking_list/${item?.PK}` + urlEnd;
            }
        } else {
            return '/';
        }
    };

    return (
        <>
            {!todoItem?.otherTodo?.length && !isLoading && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No recent activity from the last 30 Days</p>
                </div>
            )}

            <div className="view-list-grid awaiting-forwarder">
                {todoItem?.otherTodo?.map((res: any) => {
                    let gridTemplateColumnsValue = '10% 20% 20% 1fr';
                    const cols =
                        Object.keys(res).includes('PK2') || Object.keys(res).includes('VendorCode')
                            ? Object.keys(res).length - 2
                            : Object.keys(res).length - 1;
                    switch (cols) {
                        case 2:
                            gridTemplateColumnsValue = '10% 90% 1fr';
                            break;
                        case 3:
                            gridTemplateColumnsValue = '10% 10% 80% 1fr';
                            break;
                        case 4:
                            gridTemplateColumnsValue = '10% 10% 10% 70% 1fr';
                            break;
                        case 5:
                            gridTemplateColumnsValue = '10% 10% 10% 20% 50% 1fr';
                            break;
                        case 6:
                            gridTemplateColumnsValue = '10% 10% 10% 20% 10% 40% 1fr';
                            break;
                        case 7:
                            gridTemplateColumnsValue = '10% 10% 10% 10% 10% 20% 30% 1fr';
                            break;
                        case 8:
                            gridTemplateColumnsValue = '10% 10% 10% 10% 10% 10% 10% 30% 1fr';
                            break;
                    }

                    //dirty, but it'll do
                    const getEventContext = (EventReason: string, type?: string) => {
                        EventReason = EventReason.replaceAll('00:00:00', ''); // remove time from date string, as affects the output

                        const array = EventReason.split('^');
                        let reason: any = {};
                        reason.ChangeEvents = [];
                        for (const item of array) {
                            if (item.includes(':from')) {
                                // is an update event
                                let val = item.split(':');
                                if (val && val.length > 0) {
                                    let obj: any = {};
                                    obj[val[0]] = {
                                        From: val[1].split('=')[1],
                                        To: val[2].split('=')[1],
                                    };
                                    reason.ChangeEvents.push(obj);
                                }
                            } else if (item.includes(':=')) {
                                let val = item.split(':=');
                                if (val && val.length > 0) {
                                    let obj: any = {};
                                    obj[val[0]] = val[1];
                                    reason.ChangeEvents.push(obj);
                                }
                            } else {
                                let val = item.split('=');
                                if (val && val.length > 0) {
                                    reason[val[0]] = val[1];
                                }
                            }
                        }
                        if (type) {
                            return reason[type];
                        } else {
                            return reason;
                        }
                    };

                    return (
                        <div className={`row-wrapper  ${res?.isViewed === false ? ' click-active' : ''}`}>
                            <div className="list-grid" style={{ gridTemplateColumns: gridTemplateColumnsValue }}>
                                <>
                                    <div className="grid-child-item col-booking-no">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">
                                                        {todoItem?.todoType === 'PO' ? 'PO' : 'Booking'} No.
                                                    </span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">{res?.Number}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-booking-no">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Client Code</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">
                                                        {res?.ClientCode ? res?.ClientCode : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {res?.AgentCode !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Agent Code</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color">
                                                            {res?.AgentCode ? res?.AgentCode : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.SupplierCode !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Supplier Code</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color">
                                                            {res?.SupplierCode ? res?.SupplierCode : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.STD !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Scheduled Departure</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color tx-red">
                                                            {res?.STD ? moment(res?.STD).format('DD-MM-YYYY') : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.ETD !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">
                                                            Estimated Departure (Calculated)
                                                        </span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color tx-amber">
                                                            {res?.ETD ? moment(res?.ETD).format('DD-MM-YYYY') : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.ATD !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Actual Departure</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color tx-green">
                                                            {res?.ATD ? moment(res?.ATD).format('DD-MM-YYYY') : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.STA !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Scheduled Arrival</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color tx-red">
                                                            {res?.STA ? moment(res?.ATD).format('DD-MM-YYYY') : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.ETA !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">
                                                            Estimated Arrival (Calculated)
                                                        </span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color tx-amber">
                                                            {res?.ETA ? moment(res?.ETA).format('DD-MM-YYYY') : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.ATA !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Actual Arrival</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color tx-green">
                                                            {res?.ATA ? moment(res?.ATA).format('DD-MM-YYYY') : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.Date !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Date</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color">
                                                            {res?.Date ? moment(res?.Date).format('DD-MM-YYYY') : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.EventReason !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div className="grid-child-data list-view-items">
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Reason</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color">
                                                            {res?.EventReason
                                                                ? getEventContext(res.EventReason, 'Reason')
                                                                : '-'}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {res?.EventFull !== undefined && (
                                        <div className="grid-child-item col-booking-no">
                                            <div
                                                className="grid-child-data list-view-items"
                                                style={{ display: 'block' }}
                                            >
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-lite-heading">Event Details</span>
                                                    </div>
                                                    <div className="content-detail">
                                                        <span className="sub-content selected-color">
                                                            {/* {res?.EventFull
                                                                ? JSON.stringify(getEventContext(res.EventFull))
                                                                : '-'} */}
                                                            {getEventContext(res.EventFull)?.Reason && (
                                                                <div className="sub-content-first">
                                                                    <span className="sub-content-first-column-name">
                                                                        Reason :{' '}
                                                                    </span>
                                                                    <span className="sub-content-first-msg">
                                                                        {getEventContext(res.EventFull)?.Reason}
                                                                    </span>
                                                                </div>
                                                            )}
                                                            {getEventContext(res.EventFull)?.ChangeEvents?.map(
                                                                (val: any) => {
                                                                    const eventTitle = Object.keys(val)?.[0];
                                                                    return (
                                                                        <div className="sub-content-last">
                                                                            {typeof val?.[eventTitle] === 'string' ? (
                                                                                <>
                                                                                    <span className="sub-content-first-column-name">
                                                                                        {eventTitle} :
                                                                                    </span>
                                                                                    <span className="sub-content-last-msg custom-sub-content">
                                                                                        {val?.[eventTitle]}
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <span className="sub-content-first-column-name">
                                                                                        {eventTitle}
                                                                                    </span>
                                                                                    <div className="sub-content-last-sub">
                                                                                        <span>
                                                                                            From :
                                                                                            <span className="sub-content-last-msg">
                                                                                                {
                                                                                                    val?.[eventTitle]
                                                                                                        ?.From
                                                                                                }
                                                                                            </span>
                                                                                        </span>
                                                                                        <span>
                                                                                            To :
                                                                                            <span className="sub-content-last-msg">
                                                                                                {val?.[eventTitle]?.To}
                                                                                            </span>
                                                                                        </span>
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {/* {res?.clientNames?.length > 0 && (
                                        <div className="grid-child-item col-client">
                                            <div className="grid-child-data list-view-items">
                                                {res?.clientNames?.map((cl: any) => (
                                                    <div className="content-list-wrapper" key={cl}>
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Client</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            <span className="sub-content selected-color">{cl}</span>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )} */}
                                    {/* <div className="grid-child-item col-destination">
                                        <div className="grid-child-data">
                                            <div className="des-progress-bar">
                                                <div className="destination begin-journey">
                                                    <div className="point12">
                                                        <div className="point1">{res?.originPortName}</div>
                                                        {res?.originPortCode && (
                                                            <div className="point-name">({res?.originPortCode})</div>
                                                        )}
                                                    </div>
                                                    <div className="date-time">
                                                        ETD
                                                        <span className="time-field">
                                                            {res?.plannedEtd
                                                                ? moment(res?.plannedEtd).format('DD-MM-YYYY')
                                                                : '-'}
                                                        </span>
                                                        {res?.bpType?.toLowerCase() === 'air' &&
                                                            res?.isMultipleSchedule && (
                                                                <span className="etd-text">Earliest ETD</span>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="destination center-journey">
                                                    <span className="center-point">
                                                        {res?.transitPortCount
                                                            ? `1 stop${res?.transitPortCount > 1 ? 's' : ''}`
                                                            : 'Direct'}
                                                    </span>
                                                </div>
                                                <div className="destination end-journey">
                                                    <div className="point1">{res?.destinationPortName}</div>
                                                    {res?.destinationPortCode &&
                                                        res?.destinationPortName !== 'Multi destination' && (
                                                            <div className="point-name">
                                                                ({res?.destinationPortCode})
                                                            </div>
                                                        )}
                                                    <div className="time-field">
                                                        {res?.plannedEta
                                                            ? moment(res?.plannedEta).format('DD-MM-YYYY')
                                                            : '-'}
                                                        <span className="date-time">ETA</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                        {Object.entries(res).map(([key, value]) => {
                                            const item: any = value;
                                            if (
                                                !key?.startsWith('PK') &&
                                                key !== 'Number' &&
                                                key !== 'Description' &&
                                                key !== 'ClientCode' &&
                                                key !== 'AgentCode' &&
                                                key !== 'SupplierCode' &&
                                                key !== 'STD' &&
                                                key !== 'ETD' &&
                                                key !== 'ATD' &&
                                                key !== 'STA' &&
                                                key !== 'ETA' &&
                                                key !== 'ATA' &&
                                                !key?.startsWith('Event') && //TODO
                                                !key?.endsWith('Date')
                                            ) {
                                                return (
                                                    <div className="status-badge">
                                                        <div className="item-count-status-holder">
                                                            <div className="grid-item-count-status status-ui disabled-status">
                                                                <span className="status-text">{item}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        })}

                                        <button
                                            title={`View ${todoItem?.todoType === 'PO' ? 'PO' : 'Booking'}`}
                                            className="app-btn app-btn-secondary grid-btn"
                                            onClick={() => {
                                                if (res?.notificationIds) {
                                                    res?.notificationIds?.forEach((notifications: string) => {
                                                        dispatch(
                                                            markSingleRead({
                                                                id: notifications,
                                                                status: 'read',
                                                            })
                                                        );
                                                    });
                                                }
                                                navigate(getNavigationUrl(res));
                                            }}
                                        >
                                            <span className="button-text footer-button-text">
                                                View {todoItem?.todoType === 'PO' ? 'PO' : 'Booking'}
                                            </span>
                                        </button>
                                    </div>
                                </>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
export default Index;
