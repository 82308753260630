/* eslint-disable no-undefined */
import React from 'react';
import GraphView from './GraphView';
import AllOrderTabs from './AllOrderTab';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

interface DisplayHandler {
    changeValue: string;
}
//Faheem
const Index = () => {
    //============== variables to interact b/w childs ==================//
    const displayComponentRef = React.useRef<DisplayHandler>({
        changeValue: '',
    });

    const selectedRef = React.useRef({
        value: {},
    });
    //==================================================================//

    //========================= Redux values =======================================//
    const { dashboardActiveOrdersExpanded, isDasboardActiveOrdersExpandedLoading, insightsSelectedTypeFilter } =
        useSelector((state: RootState) => state.dashboardInsights);
    const dashboardActiveOrdersExpandedRedux = React.useMemo(() => {
        if (dashboardActiveOrdersExpanded) {
            return dashboardActiveOrdersExpanded;
        }
    }, [dashboardActiveOrdersExpanded]);
    //==============================================================================//

    //================== setting ref with default value selected=====================//
    React.useEffect(() => {
        if (insightsSelectedTypeFilter) {
            selectedRef.current.value = dashboardActiveOrdersExpandedRedux[insightsSelectedTypeFilter];
        } else {
            if (dashboardActiveOrdersExpandedRedux['total'] && !isDasboardActiveOrdersExpandedLoading) {
                selectedRef.current.value = dashboardActiveOrdersExpandedRedux['total'];
            }
        }
    }, [dashboardActiveOrdersExpandedRedux, insightsSelectedTypeFilter]);
    //==============================================================================//

    return (
        <div className="tab-content-body">
            <div className="graph-container">
                <div className="all-orders-grid active-order-panel">
                    {!isDasboardActiveOrdersExpandedLoading ? (
                        <React.Fragment>
                            <AllOrderTabs
                                dashboardExpandedReduxVal={dashboardActiveOrdersExpandedRedux}
                                isLoading={isDasboardActiveOrdersExpandedLoading}
                                displayComponentRef={displayComponentRef}
                                page={'orders'}
                            />
                            <GraphView
                                ref={displayComponentRef}
                                selectedRef={selectedRef}
                                type="Active Orders"
                            ></GraphView>
                        </React.Fragment>
                    ) : (
                        <div className="app-loader inner-loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Index;
