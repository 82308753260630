import React from 'react';
import { Position, success } from '../../utils/toast';

interface contentProps {
    data: {
        userName: string;
        role: string | null;
        email: string;
        phoneNumber: string;
    };
}
const ToolTipContentBox: React.FC<contentProps> = ({ data }) => {
    const copyText = (text: string) => {
        navigator.clipboard.writeText(text);
        success('Copied!', Position.TOP_RIGHT);
    };
    return (
        <div className="tooltip-holder bd-tooltip-holder">
            <div className="tooltip-heading">{data.userName}</div>
            {data.role ? <div className="tooltip-subheading">{data.role}</div> : null}
            <div className="tooltip-content">
                <button className="app-btn " title="Mail">
                    <span>
                        <svg className="svg-icon tooltip-icon">
                            <use xlinkHref="#mailIcon">
                                <title>Mail</title>
                            </use>
                        </svg>
                    </span>
                </button>
                <span className="tooltip-content-info">{data.email}</span>
                <button
                    className="app-btn copy-btn"
                    title="Copy"
                    onClick={() => {
                        copyText(data.email);
                    }}
                >
                    <span>
                        <svg className="svg-icon tooltip-icon">
                            <use xlinkHref="#copyIcon">
                                <title>Copy</title>
                            </use>
                        </svg>
                    </span>
                </button>
            </div>
            <div className="tooltip-content">
                <button className="app-btn " title="Phone">
                    <span>
                        <svg className="svg-icon tooltip-icon">
                            <use xlinkHref="#phoneIcon">
                                <title>Phone</title>
                            </use>
                        </svg>
                    </span>
                </button>
                <span className="tooltip-content-info">{data.phoneNumber}</span>
            </div>
        </div>
    );
};

export default ToolTipContentBox;
