import React, { useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import GridLayout from './GridLayoutWithColumns';
// import './agent-list.scss';
import './data-grid.scss';
import '../../assets/scss/components/_jquery-kendo-grid.scss';
import '../../assets/scss/components/library/kendo-bootstrap-main-min.css';
import { layoutWithColumnsList } from 'src/utils/kendoGrid/layoutWithColumns';
import NotFound from './NotFound';
import { fetchCountData } from 'src/store/reducers/countReducer';
import { useDispatch } from 'react-redux';
import SavedGridLayoutModal from './SavedGridLayoutModal';
import { getSavedGridLayouts } from 'src/services/apis/SavedGridLayout';
import { error, Position } from 'src/utils/toast';
interface gridPropsType {
    style?: string;
    content?: string;
    filter?: string;
    customProp?: any;
    hideLoader?: boolean;
    showLoader?: boolean;
    tabDelay?: boolean;
    overrideDiv?: string;
    customUrl?: boolean;
    onGridLoad?: any;
}

const Index: React.FC<gridPropsType> = (props) => {
    const { id, type } = useParams();

    const [visible, setVisible] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [layoutsData, setLayoutsData] = useState([]);
    const [layoutKeyword, setLayoutKeyword] = useState('');
    const [loader, setLoader] = useState(props?.showLoader ? true : false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (props.content && props.content.includes('profile')) {
            dispatch(fetchCountData({}));
        }
        setTimeout(() => {
            setVisible(true);
        }, 100);
    }, []);

    if (id && !Object.keys(layoutWithColumnsList)?.includes(id) && !props?.customProp && !props?.content) {
        return <NotFound />;
    }

    var { keyword, title, extraData, savedLayout } = props?.customProp
        ? props?.customProp
        : id
        ? layoutWithColumnsList?.[id as keyof typeof layoutWithColumnsList] ?? {}
        : props.content
        ? layoutWithColumnsList?.[props.content.toLowerCase() as keyof typeof layoutWithColumnsList] ?? {}
        : layoutWithColumnsList.normal;

    if (props.content === 'purchase-order-lifecycle') {
        keyword = `moment/po/${id}`;
    } else if (props.content === 'integrationlogdetails') {
        keyword = `admin/integrationlog/${id}`;
    } else if (props.content === 'purchase-order-integration-log') {
        keyword = `admin/integrationlog`;
    } else if (props.content === 'linked-bookings') {
        keyword = `forwarding/booking/${props.customProp}`;
    } else if (props.content === 'purchase-order-notes') {
        keyword = `utility/notes/${id}`;
    } else if (props.content === 'attachedPos' && id) {
        keyword = `forwarding/booking/${id}/confirmationgroups`;
    } else if (props.content === 'attachedPosDetail' && id) {
        keyword = `forwarding/booking/${id}/confirmationgroups/purchaseorders`;
    } else if (props.content === 'purchase-order-booking-events') {
        keyword = `lifecycle/events/${id}`;
    } else if (props.content === 'availablePos') {
        keyword = `forwarding/booking/${id === 'POSelection' ? undefined : id}/confirmationgroupsEligible`;
    } else if (props.content === 'vendor-user-list') {
        keyword = `admin/userProfile/VND`;
    } else if (props.content === 'containerAllocation') {
        keyword = `forwarding/booking/${id}/containers`;
    } else if (props.content === 'containerPacking') {
        keyword = `forwarding/booking/${id}/container/${props.customProp}/packing`;
    } else if (props.content === 'bookingDocuments' && id) {
        keyword = `forwarding/booking/${id}/documents`;
    } else if (props.content === 'purchase-attachments' && id) {
        keyword = `forwarding/purchaseorder/${id}/documents`;
    } else if (props.content === 'client-sop' && id) {
        keyword = `admin/organisation/client/${id}/sop`;
    } else if (props.content === 'client-contract' && id) {
        keyword = `admin/organisation/client/${id}/contract`;
    } else if (props.content === 'qms-quote-item') {
        keyword = `qms/config/quoteItems`;
    } else if (props.content === 'rate-attachments') {
        keyword = `qms/config/rate/${id}/documents`;
    } else if (props.content === 'qms-config-detail') {
        keyword = `admin/config/configDetails/${type}`;
    } else if (props.content === 'tracking-agent-port') {
        keyword = props?.customProp?.apiUrl;
    } else if (props.content === 'agent-commercial' && id) {
        keyword = `admin/organisation/agent/${id}/commercials`;
    } else if (props.content === 'agent-port-details' && id) {
        keyword = `admin/organisation/agent/${id}/ports`;
    } else if (props.content === 'vendor-client-details' && id) {
        keyword = `admin/organisation/vendor/${id}/client`;
    } else if (props.content === 'air-bp-options' && id) {
        keyword = `air/bkg/${id}/options`;
    }

    const getStyle = () => {
        switch (props.style) {
            case 'tab':
                return 'tab-details';
            case 'booking':
                return 'booking-list';
            case 'vendor':
                return 'vendor-details';
            case 'lifecycle':
                return 'lifecycle';
            case 'double_tab':
                return 'double-tab';
            case 'booking_details':
                return 'booking-details';
            case 'modal':
                return 'modal';
            case 'fullpage':
                return 'fullpage';
            default:
                return 'single-page-details';
        }
    };

    useEffect(() => {
        if (title?.toLowerCase()?.startsWith('container')) {
            document.title = 'CNT';
        } else if (title?.toLowerCase()?.startsWith('shipment')) {
            document.title = 'SHP';
        } else if (title?.toLowerCase()?.includes('commercial invoice')) {
            document.title = 'CUS';
        } else if (title?.toLowerCase()?.startsWith('receipt')) {
            document.title = 'RCP';
        } else if (title?.toLowerCase()?.startsWith('inventory')) {
            document.title = 'INV';
        }
    }, [title]);

    const gridDelay = () => {
        if (props?.tabDelay !== undefined) {
            return props?.tabDelay;
        } else {
            return visible;
        }
    };

    useEffect(() => {
        if (layoutKeyword)
            getSavedGridLayouts(layoutKeyword ?? '')
                .then((res: any) => {
                    setModalView(true);
                    setLayoutsData(res);
                })
                .catch((err: any) => {
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                });
    }, [layoutKeyword]);

    const getData = (layout: string) => {
        getSavedGridLayouts(layout ?? '')
            .then((res: any) => {
                setModalView(true);
                setLayoutsData(res);
            })
            .catch((err: any) => {
                error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            });
    };

    //@ts-ignore
    window.myData = (keyword?: string) => {
        setModalView(true);
        setLayoutKeyword(keyword ?? '');
    };

    return (
        <div className="main-wrapper container insight-agent-list">
            {title && (
                <div className="main-header-content-holder ">
                    <div className="pageheading-holder">
                        <div className="header-wrapper">
                            <h2 className="page-heading">{title}</h2>
                        </div>
                    </div>
                </div>
            )}
            <div className="main-content-holder">
                {loader ? (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                ) : null}
                {gridDelay() ? (
                    <HelmetProvider>
                        <div className="insight-agent-list-data-trend">
                            <div className={`container-fluid ${getStyle()}`}>
                                <div className="k-kendo-row one-column">
                                    <div className="k-kendo-col-2 custom-kendo-grid">
                                        <div id={props.overrideDiv || 'grid'}></div>
                                    </div>
                                </div>
                            </div>
                            <GridLayout
                                keyword={keyword ?? ''}
                                extraData={extraData}
                                filter={props.filter}
                                setLoader={setLoader}
                                overrideDiv={props.overrideDiv ?? ''}
                                customUrl={props.customUrl}
                                onGridLoad={props.onGridLoad}
                            />
                        </div>
                    </HelmetProvider>
                ) : props?.hideLoader ? null : (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {modalView ? (
                <SavedGridLayoutModal
                    layoutsData={layoutsData}
                    setLayoutsData={setLayoutsData}
                    setModalView={setModalView}
                    getData={getData}
                    layoutKeyword={layoutKeyword ?? ''}
                />
            ) : null}
        </div>
    );
};
export default Index;
