import React, { useEffect, useReducer, useRef, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
//import '../POSelection.scss';
//import './container-allocations.scss';
// import BookingAdditionalInfo from '../BookingAdditionalInfo';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    // selectContainerAllocationError,
    // selectContainerAllocationIsError,
    selectContainerAllocationLoading,
    // selectContainerAllocationSuccess,
    fetchContainers,
    selectContainers,
    BpContainersList,
    resetFields,
    selectInitialContainerCalled,
    resetContainerDetailsId,
    fetchContainerDetail,
    fetchContainerOnlyBasicDetail,
    removeContainer,
    resetRemoveResult,
    selectContainerDetail,
    // fetchInitialContainerDetail,
} from '../../../store/reducers/bookingProposal/containerAllocationReducer';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useUserProfile } from '../../../hooks/useUserProfile';
// import ShowForPermissions from '../../../ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import ContainerDetails from './ContainerDetails';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import {
    fetchNacAdditionalDetails,
    addContainerNac,
} from 'src/store/reducers/bookingProposalBcn/additionalDetailsNacReducer';
import BasicDetailsModal from './ContainerDetails/BasicDetailsModal';
import ContainerPackingModal from './containerPackingModal';
import NewContainerModal from './ContainerDetails/NewContainerModal';
import CancelModal from 'src/components/common/CancelModal';
import ShowForPermissions from 'src/ShowForPermissions';
import { Position, error, success } from 'src/utils/toast';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';

import {
    clearFailure,
    selectAdditionalDetailsError,
    selectAdditionalDetailsIsError,
    selectAdditionalDetailsSuccess,
    approveBp,
    selectAdditionalDetailsLoading,
    selectgateInDateValidation,
    selectEnrichBooking,
    selectHouseBillDetails,
    fetchEnrichBooking,
    fetchHouseBills,
    updateBP,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import RejectBookingProposalModal from '../BookingAdditionalInfo/RejectBookingProposalModal';
import { getUserDetails } from 'src/utils/getUserDetails';
import UnavailableModel from '../SelectedSailingSchelude/UnavailableModel';

const allocationStatus = (status: string) => {
    switch (status) {
        case 'Pending':
            return <div className="allocated-status pending">Pending</div>;
        case 'In_Progress':
            return <div className="allocated-status in-progress">In-progress</div>;
        case 'Completed':
            return <div className="allocated-status completed">Completed</div>;
    }
};

const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const { profileType, userName } = useUserProfile();
    const headerData = useSelector((state: RootState) => state.bpHeaderData);

    const [reason, setReason] = useState('');
    const [rejectionModalView, setRejectionModalView] = useState(false);
    const [reasonRejection, setReasonRejection] = useState('');
    const [assignAgentModalView, setAssignAgentModalView] = useState(false);
    const [addDetailsModalView, setAddDetailsModalView] = useState(false);
    const [rejectedBpForwarderModalView, setRejectedBpForwarderModalView] = useState(false);
    const [displayModal, setdisplayModal] = useState(false);

    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: `/LCLbooking_list/${id}/POSelection`,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/LCLbooking_list/${id}/SailingSelectionDetails`,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/LCLbooking_list/${id}/additional-details`,
        },
        {
            title: 'Container Details',
            selected: 1,
            path: `/LCLbooking_list/${id}/container-allocation`,
        },
        // {
        //     title: 'Attachments',
        //     selected: 0,
        //     path: `/LCLbooking_list/${id}/attachments`,
        // },
    ];
    if (
        headerData.bpStatus === 'Ready_for_Booking' ||
        headerData.bpStatus === 'Draft_Booking' ||
        headerData.bpStatus === 'Booked'
    ) {
        // navData.push({
        //     title: 'Attachments',
        //     selected: 0,
        //     path: `/LCLbooking_list/${id}/attachments`,
        // });
    }
    const { removeResult } = useSelector((state: RootState) => state.containerAllocation);
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [containerData, setContainerData] = useState<BpContainersList[]>([]);
    const [search, setSearch] = useState('');
    const dispatch = useAppDispatch();
    const [cancelModalView, setCancelModalView] = useState(false);
    const [validation, setValidation] = useState(false);
    const [bookingContainerId, setBookingContainerId] = useState('');
    const [containerDetailId, setContainerDetailId] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showPackingModal, setShowPackingModal] = useState(false);
    const [hasUpdateCompleted, setHasUpdateCompleted] = useState(false);
    const [showContainerCreateModal, setShowContainerCreateModal] = useState(false);
    const [showBasicDetails, setShowBasicDetails] = useState(false);
    // const isError = useAppSelector(selectContainerAllocationIsError);
    // const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const containers = useAppSelector(selectContainers);
    // const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    // const isLoading = useAppSelector(selectContainerAllocationLoading);

    const { containerDetail } = useSelector((state: RootState) => state.containerAllocation);
    const { coLoaderCode } = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const initialContainerCalled = useAppSelector(selectInitialContainerCalled);
    const { initialContainerValues, additionalDetails: lclDetails } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    const { destinationAgentCode } = useSelector((state: RootState) => state.additionalDetailsLcl);
    const wasCalled = useRef(false);

    const isError = useAppSelector(selectAdditionalDetailsIsError);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const gateinVal = useAppSelector(selectgateInDateValidation);
    const additionalDetailsError = useAppSelector(selectAdditionalDetailsError);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);

    const initialState = {
        sellQuotationName: '',
        sellQuotationVersion: '',
        sellQuotationId: '',
        sellTotOriginCost: '',
        sellCurrency: '',
    };

    const [additionalDetails, updateAdditionalDetails] = useReducer(
        (state: typeof initialState, updates: any) => ({ ...state, ...updates }),
        initialState
    );

    useEffect(() => {
        if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
        dispatch(fetchNacAdditionalDetails({ bpId: id }));
    }, [hasUpdateCompleted]);

    //@ts-ignore
    window.containerAction = (action: string, bpPk: string, bcPk: string, bdPk: string) => {
        if (action === 'EDT') {
            setBookingContainerId(bcPk);
            setContainerDetailId(bdPk);
            if (bdPk && bdPk !== 'null') dispatch(fetchContainerDetail({ containerDetailsId: bdPk }));
            else if (bcPk && bcPk !== 'null') dispatch(fetchContainerOnlyBasicDetail({ containerId: bcPk }));
            setShowBasicDetails(true);
        } else if (action === 'PCK') {
            setContainerDetailId(bdPk);
            setShowPackingModal(true);
        } else if (action === 'NEW') {
            if (initialContainerValues.length > 0)
                error(
                    'Only one container is allowed for LCL, you may delete the existing one to add a new container',
                    Position.TOP_RIGHT
                );
            else setShowContainerCreateModal(true);
        }
    };

    useEffect(() => {
        if (removeResult.length > 0) {
            dispatch(resetRemoveResult({}));
            setHasUpdateCompleted(!hasUpdateCompleted);
        }
    }, [removeResult]);

    useEffect(() => {
        if (gateinVal) {
            setdisplayModal(true);
        } else {
            setdisplayModal(false);
        }
    }, [gateinVal]);

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    const approveProposalBtnStatusForForwarder = () => {
        if (headerData?.isTriangleShipment) {
            return destinationAgentCode ? false : true;
        } else if (lclDetails?.contractType === 'FAK' && headerData?.coLoaderCode === process.env.REACT_APP_ORG_CODE) {
            if (!initialContainerValues?.length) {
                return true;
            }
            if (headerData.bpStatus === 'Awaiting_Forwarder_Approval') {
                return lclDetails?.sellQuotationName ? false : true;
            } else if (
                headerData.bpStatus === 'Draft_Booking_Proposal' ||
                headerData.bpStatus === 'Rejected_by_Forwarder'
            ) {
                return lclDetails?.totOriginCost >= 0 ? false : true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    function checkEnabled() {
        if (headerData?.coLoaderCode !== process.env.REACT_APP_ORG_CODE) {
            return false;
        }
        const lengthOfContainerLine = containers?.filter((c: any) => c?.containerId)?.length ?? 0;
        const lengthOfContainerLineInEditing = containers?.filter((c: any) => !c?.containerId)?.length ?? 0;

        return (
            lengthOfContainerLine === 0 ||
            lengthOfContainerLineInEditing > 0 ||
            (lclDetails?.contractType === 'FAK' && !lclDetails?.bookingContractNo)
        );
    }

    useEffect(() => {
        if (isError) {
            error(additionalDetailsError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (additionalDetailsSuccess?.status === 200 || additionalDetailsSuccess?.status === 201) {
            if (additionalDetailsSuccess?.data?.type === 'rejected') {
                success(
                    `Booking Proposal ${
                        additionalDetailsSuccess?.data?.info?.bpNumber
                            ? additionalDetailsSuccess?.data?.info?.bpNumber
                            : ''
                    } rejected successfully`,
                    Position.TOP_RIGHT
                );
                // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(fetchNacAdditionalDetails({ bpId: id }));
                setTimeout(() => navigate('/booking_list'), 2000);
            } else if (additionalDetailsSuccess?.data?.type === 'assignedToAgent') {
                // success(
                //     `Booking Proposal ${
                //         additionalDetailsSuccess?.data?.info?.bpNumber
                //             ? additionalDetailsSuccess?.data?.info?.bpNumber
                //             : ''
                //     } assigned to agent`,
                //     Position.TOP_RIGHT
                dispatch(fetchNacAdditionalDetails({ bpId: id })); // );
                // setTimeout(() => navigate('/booking_list'), 2000);
            } else if (additionalDetailsSuccess?.data?.type === 'approved') {
                const bpNumber = additionalDetailsSuccess?.data?.info?.bpNumber
                    ? additionalDetailsSuccess?.data?.info?.bpNumber
                    : '';
                const message =
                    profileType === 'forwarder'
                        ? `Booking Proposal ${bpNumber} approved successfully`
                        : `Booking Proposal ${bpNumber} submitted for approval`;
                success(message, Position.TOP_RIGHT);
                // dispatch(fetchBpHeaderData({ bpId: id, profileType }));

                setTimeout(() => navigate('/booking_list'), 2000);
            } else if (
                additionalDetailsSuccess?.data?.type === 'add' ||
                additionalDetailsSuccess?.data?.type === 'edit'
            ) {
                const modifiedType = additionalDetailsSuccess?.data?.type === 'add' ? 'added' : 'updated';
                success(
                    `Additional Details ${
                        additionalDetailsSuccess?.data?.info?.bpNumber
                            ? additionalDetailsSuccess?.data?.info?.bpNumber
                            : ''
                    } ${modifiedType} successfully`,
                    Position.TOP_RIGHT
                );
                setAddDetailsModalView(false);
                updateAdditionalDetails(initialState);
                dispatch(fetchBpHeaderData({ bpId: id, profileType }));
                dispatch(fetchNacAdditionalDetails({ bpId: id }));
            }
            dispatch(clearFailure([]));
        }
    }, [isError, additionalDetailsSuccess, additionalDetailsError]);

    const submitBp = () => {
        const fromForwarderDraft =
            (headerData.bpStatus === 'Draft_Booking_Proposal' || headerData.bpStatus === 'Rejected_by_Forwarder') &&
            profileType === 'forwarder';
        dispatch(approveBp({ bpId: id, userName, profile: profileType, fromForwarderDraft }));
    };

    const rejectBp = () => {
        setRejectionModalView(true);
    };

    useEffect(() => {
        if (!rejectionModalView) {
            setReason('');
        }
    }, [rejectionModalView]);

    const [validationBtn, setvalidationBtn] = useState(false);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const houseBillData = useAppSelector(selectHouseBillDetails);
    // const containers = useAppSelector(selectContainers);
    const [nextBtn, setNextBtn] = useState(false);
    const [nextAddBtn, setNextAddBtn] = useState(false);
    const { additionalDetails: additionalDetailsSubmit } = useSelector(
        (state: RootState) => state.additionalDetailsNac
    );
    // const currentContainerDetailsId = useAppSelector(selectContainerDetailsId);
    const containerDetailSubmit: any = useAppSelector(selectContainerDetail);
    const { submitBPSuccess, submitBPError } = useSelector((state: RootState) => state.additionalDetailsData);
    const { gateInDateExpired } = useSelector((state: RootState) => state.bpHeaderData);

    useEffect(() => {
        dispatch(fetchEnrichBooking({ bpId: id }));
        dispatch(fetchHouseBills({ bpId: id }));
        dispatch(fetchContainers({ bpId: id }));
        // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        dispatch(fetchNacAdditionalDetails({ bpId: id }));
    }, [id]);
    useEffect(() => {
        if (headerData?.containerDetailsId) {
            dispatch(fetchContainerDetail({ containerDetailsId: headerData?.containerDetailsId }));
        }
    }, [headerData?.containerDetailsId]);

    useEffect(() => {
        if (!showBasicDetails) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [showBasicDetails]);
    useEffect(() => {
        if (!showContainerCreateModal) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [showContainerCreateModal]);
    useEffect(() => {
        if (!showPackingModal) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [showPackingModal]);

    const submitReadyForShipment = () => {
        const { userName } = getUserDetails();
        dispatch(updateBP({ bookingId: id, userName, profile: profileType }));
    };

    useEffect(() => {
        if (
            houseBillData &&
            enrichBooking &&
            houseBillData.numbers?.length > 0 &&
            houseBillData.numbers[0]?.vendors?.length > 0 &&
            enrichBooking?.soNumber &&
            enrichBooking?.originCartageCompany &&
            houseBillData?.numbers[0].vendors[0].houseBillNumber !== ''
            // houseBillData?.masterBillNumber !== '' &&
        ) {
            setNextBtn(true);
        } else {
            setNextBtn(false);
        }
    }, [enrichBooking, houseBillData]);

    useEffect(() => {
        if (submitBPSuccess) {
            success(`Booking details sent successfully`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPError) {
            error(`Something went wrong`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPSuccess || submitBPError) {
            dispatch(clearFailure([]));
            setTimeout(() => {
                navigate('/booking_list');
            }, 1000);
        }
    }, [submitBPSuccess, submitBPError]);

    useEffect(() => {
        if (gateInDateExpired) {
            setvalidationBtn(false);
        } else {
            setvalidationBtn(true);
        }
    }, [gateInDateExpired]);

    useEffect(() => {
        const headerDataForContainer = JSON?.parse(JSON?.stringify(headerData));
        if (headerData?.coLoaderCode === process.env.REACT_APP_ORG_CODE && headerData?.isTriangleShipment) {
            if (additionalDetailsSubmit?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetailsSubmit?.savedFakFileName &&
                    // additionalDetailsSubmit?.totOriginCost &&
                    additionalDetailsSubmit?.destinationAgentName &&
                    additionalDetailsSubmit?.sellTotOriginCost &&
                    additionalDetailsSubmit?.sellQuotationName
                ) {
                    setNextAddBtn(true);
                }
            }
            if (
                additionalDetailsSubmit?.contractType === 'FAK' &&
                (profileType === 'agent' || profileType === 'forwarder')
            ) {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetailsSubmit?.destinationAgentName !== null
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetailsSubmit?.contractType === 'NAC') {
                if (additionalDetailsSubmit?.destinationAgentName !== null) {
                    setNextAddBtn(true);
                }
            }
        }

        if (headerData?.coLoaderCode === process.env.REACT_APP_ORG_CODE && headerData?.isTriangleShipment === false) {
            if (additionalDetailsSubmit?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetailsSubmit?.savedFakFileName &&
                    // additionalDetailsSubmit?.totOriginCost &&
                    additionalDetailsSubmit?.sellTotOriginCost &&
                    additionalDetailsSubmit?.sellQuotationName
                ) {
                    setNextAddBtn(true);
                }
            }
            if (
                additionalDetailsSubmit?.contractType === 'FAK' &&
                (profileType === 'agent' || profileType === 'forwarder')
            ) {
                // if (additionalDetailsSubmit?.savedFakFileName && additionalDetailsSubmit?.totOriginCost) {
                setNextAddBtn(true);
                // }
            }
            if (additionalDetailsSubmit?.contractType === 'NAC') {
                setNextAddBtn(true);
            }
        }
        if (headerData?.coLoaderCode !== process.env.REACT_APP_ORG_CODE) {
            if (
                containerDetailSubmit?.containerType &&
                containerDetailSubmit?.deliveryMode &&
                containerDetailSubmit?.containerMode &&
                containerDetailSubmit?.containerNumber &&
                containerDetailSubmit?.packing
                // &&
                // containerDetailSubmit?.sealNumber &&
                // containerDetailSubmit?.gateInDate
            ) {
                if (headerData?.isTriangleShipment === false) {
                    setNextAddBtn(true);
                } else {
                    additionalDetailsSubmit?.destinationAgentName && setNextAddBtn(true);
                }
            }
        }
        if (headerDataForContainer?.tabValues?.containerDetails === false) {
            setNextAddBtn(false);
        }
    }, [additionalDetailsSubmit, headerData, containerDetailSubmit]);

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                />
                <BookingProcessTab navData={navData} />
            </div>
            <div className="content-section main-wrapper grid-p-0">
                <div className="grid-header grid-ml-20">
                    <div className="content-heading-holder card-head-btn-edit-holder">
                        <span className="content-heading">Containers</span>
                    </div>
                </div>

                <LayoutWithColumns content={'containerAllocation'} style="booking_details" />
            </div>
            <div className="footer-bootom-row backdrop-active">
                {/* <div className="footer-button-holder ">
                    <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/LCLbooking_list/${id}/${
                            profileType === 'vendor' ? 'SailingSelectionDetails' : 'additional-details'
                        }`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <Link
                        title="Next"
                        className="app-btn app-btn-primary footer-btn"
                        to={`/LCLbooking_list/${id}/attachments`}
                    >
                        <span className="button-text footer-button-text">Next</span>
                    </Link>
                </div> */}
                <div className="footer-button-holder ">
                    {(() => {
                        if (profileType === 'forwarder') {
                            return (
                                <>
                                    {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <ShowForPermissions permission="create" type="bp">
                                            <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                                <span className="button-text footer-button-text">Save as Draft</span>
                                            </a>
                                        </ShowForPermissions>
                                    )} */}
                                    <Link
                                        title="Previous"
                                        className="app-btn app-btn-secondary footer-btn"
                                        to={`/LCLbooking_list/${id}/additional-details`}
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </Link>
                                    {(headerData.bpStatus === 'Awaiting_Forwarder_Approval' ||
                                        headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                        (headerData.bpStatus === 'Rejected_by_Forwarder' &&
                                            headerData.isForwarderSubmit) ||
                                        headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action') && (
                                        <>
                                            {headerData.bpStatus === 'Awaiting_Forwarder_Approval' && (
                                                // !headerData.isForwarderSubmit &&
                                                <ShowForPermissions permission="approve" type="bp">
                                                    <a
                                                        onClick={() => rejectBp()}
                                                        title="Reject Proposal"
                                                        className="app-btn app-btn-secondary footer-btn"
                                                    >
                                                        <span className="button-text footer-button-text">
                                                            Reject Proposal
                                                        </span>
                                                    </a>
                                                </ShowForPermissions>
                                            )}
                                            {headerData.bpStatus === 'Rejected_by_Client_Forwarder_to_Action' && (
                                                <a
                                                    title="Assign to Agent"
                                                    className="app-btn app-btn-secondary footer-btn"
                                                    onClick={() => {
                                                        setAssignAgentModalView(true);
                                                        setReasonRejection(headerData.reason ? headerData.reason : '');
                                                    }}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        Assign to Agent
                                                    </span>
                                                </a>
                                            )}
                                            <ShowForPermissions permission="approve" type="bp">
                                                <button
                                                    onClick={() => submitBp()}
                                                    title={
                                                        approveProposalBtnStatusForForwarder()
                                                            ? 'Mandatory fields are missing or needs to be filled'
                                                            : 'Approve Proposal'
                                                    }
                                                    className="app-btn app-btn-primary footer-btn"
                                                    disabled={approveProposalBtnStatusForForwarder()}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        Approve Proposal
                                                    </span>
                                                </button>
                                            </ShowForPermissions>
                                        </>
                                    )}
                                    {(headerData.bpStatus === 'Booked' ||
                                        headerData.bpStatus === 'Ready_for_Booking' ||
                                        headerData.bpStatus === 'Draft_Booking') && (
                                        <>
                                            {/*{enrichBooking?.bookingRefNum ? (*/}
                                            {/* <Link
                                                title="Next"
                                                className="app-btn app-btn-primary footer-btn"
                                                to={`/LCLbooking_list/${id}/attachments`}
                                            >
                                                <span className="button-text footer-button-text">Next</span>
                                            </Link> */}
                                            {/*) : (
                                                <button
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    disabled={true}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </button>
                                            )}*/}
                                        </>
                                    )}
                                </>
                            );
                        } else if (profileType === 'client') {
                            return (
                                <>
                                    {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                            <span className="button-text footer-button-text">Save as Draft</span>
                                        </a>
                                    )} */}
                                    <Link
                                        title="Previous"
                                        className="app-btn app-btn-secondary footer-btn"
                                        to={`/LCLbooking_list/${id}/additional-details`}
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </Link>
                                    {headerData.bpStatus === 'Awaiting_Client_Approval' && (
                                        <>
                                            <a
                                                onClick={() => rejectBp()}
                                                title="Reject Proposal"
                                                className="app-btn app-btn-secondary footer-btn"
                                            >
                                                <span className="button-text footer-button-text">Reject Proposal</span>
                                            </a>
                                            <button
                                                onClick={() => submitBp()}
                                                title="Approve Proposal"
                                                disabled={headerData.gateInDateExpired ? true : false}
                                                className="app-btn app-btn-primary footer-btn"
                                            >
                                                <span className="button-text footer-button-text">Approve Proposal</span>
                                            </button>
                                        </>
                                    )}
                                    {(headerData.bpStatus === 'Draft_Booking' ||
                                        headerData.bpStatus === 'Ready_for_Booking') && (
                                        <>
                                            {/* <Link
                                                title="Next"
                                                className="app-btn app-btn-primary footer-btn"
                                                to={`/LCLbooking_list/${id}/attachments`}
                                            >
                                                <span className="button-text footer-button-text">Next</span>
                                            </Link> */}
                                        </>
                                    )}
                                </>
                            );
                        } else {
                            return (
                                <>
                                    {/* {profileType === 'agent' &&
                                        (bpStatus === 'Draft_Booking_Proposal' ||
                                            bpStatus === 'Rejected_by_Forwarder' ||
                                            bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                                            <>
                                                {bpStatus === 'Booked' || bpStatus === 'Cancelled' ? null : (
                                                    <ShowForPermissions type="bp" permission="create">
                                                        <button
                                                            title="Cancel Booking Proposal"
                                                            className="app-btn app-btn-secondary footer-btn"
                                                            onClick={() => {
                                                                setShowBookingCancelModal(true);
                                                            }}
                                                        >
                                                            <span className="button-text footer-button-text">
                                                                Cancel Booking Proposal
                                                            </span>
                                                        </button>
                                                    </ShowForPermissions>
                                                )}
                                            </>
                                        )} */}
                                    {/* {headerData.bpStatus === 'Draft_Booking_Proposal' && (
                                        <ShowForPermissions type="bp" permission="create">
                                            <a title="Save as Draft" className="app-btn app-btn-secondary footer-btn">
                                                <span className="button-text footer-button-text">Save as Draft</span>
                                            </a>
                                        </ShowForPermissions>
                                    )} */}
                                    {/* {profileType === 'agent' &&
                                        (bpStatus === 'Booked' ||
                                            bpStatus === 'Ready_for_Booking' ||
                                            bpStatus === 'Draft_Booking') && (
                                            <ShowForPermissions type="bp" permission="create">
                                                <button
                                                    title="Cancel Booking"
                                                    className="app-btn app-btn-secondary footer-btn"
                                                    onClick={() => {
                                                        setShowBookingCancelModal(true);
                                                    }}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        Cancel Booking
                                                    </span>
                                                </button>
                                            </ShowForPermissions>
                                        )} */}
                                    <Link
                                        title="Previous"
                                        className="app-btn app-btn-secondary footer-btn"
                                        to={`/LCLbooking_list/${id}/${
                                            profileType === 'vendor' ? 'SailingSelectionDetails' : 'additional-details'
                                        }`}
                                    >
                                        <span className="button-text footer-button-text">Previous</span>
                                    </Link>
                                    {headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                    headerData.bpStatus === 'Draft_Booking_Proposal' ||
                                    headerData.bpStatus === 'Rejected_by_Client_Agent_to_Action' ? (
                                        coLoaderCode !== process.env.REACT_APP_ORG_CODE ||
                                        (coLoaderCode === process.env.REACT_APP_ORG_CODE &&
                                            lclDetails?.bookingContractNo &&
                                            initialContainerValues?.length > 0) ? (
                                            <ShowForPermissions type="bp" permission="edit">
                                                <a
                                                    title="Submit for Approval"
                                                    onClick={() => {
                                                        !checkEnabled() && submitBp();
                                                    }}
                                                    className={`app-btn app-btn-primary footer-btn ${
                                                        checkEnabled() ? 'disabled' : ''
                                                    }`}
                                                >
                                                    <span className="button-text footer-button-text">
                                                        Submit for Approval
                                                    </span>
                                                </a>
                                            </ShowForPermissions>
                                        ) : null
                                    ) : headerData.bpStatus === 'Draft_Booking' ||
                                      headerData.bpStatus === 'Ready_for_Booking' ||
                                      headerData.bpStatus === 'Booked' ? (
                                        <>
                                            {/*{enrichBooking?.bookingRefNum ? (*/}
                                            {/* <Link
                                                title="Next"
                                                className="app-btn app-btn-primary footer-btn"
                                                to={`/LCLbooking_list/${id}/attachments`}
                                            >
                                                <span className="button-text footer-button-text">Next</span>
                                            </Link> */}
                                            {/*) : (
                                                <button
                                                    title="Next"
                                                    className="app-btn app-btn-primary footer-btn"
                                                    disabled={true}
                                                >
                                                    <span className="button-text footer-button-text">Next</span>
                                                </button>
                                            )}*/}
                                        </>
                                    ) : null}
                                </>
                            );
                        }
                    })()}
                    <ShowForPermissions type="bp" permission="create">
                        {(profileType === 'agent' || profileType === 'forwarder') &&
                            headerData?.bpStatus === 'Draft_Booking' && (
                                <a
                                    title="Ready for Shipment"
                                    className={`app-btn app-btn-primary footer-btn ${
                                        validationBtn && nextBtn && nextAddBtn ? '' : 'disabled'
                                    }`}
                                    onClick={() => {
                                        if (nextBtn && validationBtn && nextAddBtn) {
                                            submitReadyForShipment();
                                        }
                                    }}
                                >
                                    <span className="button-text footer-button-text">Ready for Shipment</span>
                                    {/* </button> */}
                                </a>
                            )}
                    </ShowForPermissions>
                </div>
            </div>
            <BasicDetailsModal
                modalView={showBasicDetails}
                setModalView={setShowBasicDetails}
                containerData={containerDetail}
                bpId={id}
                allocationId={bookingContainerId}
                containerDetailsId={containerDetailId}
                setCancelModalView={setCancelModalView}
                setValidation={setValidation}
                validation={validation}
                setHasUpdateCompleted={setHasUpdateCompleted}
                hasUpdateCompleted={hasUpdateCompleted}
            />

            <NewContainerModal
                modalView={showContainerCreateModal}
                setModalView={setShowContainerCreateModal}
                bpId={id}
                setHasUpdateCompleted={setHasUpdateCompleted}
                setCancelModalView={setCancelModalView}
                setValidation={setValidation}
                validation={validation}
                hasUpdateCompleted={hasUpdateCompleted}
            />

            {showBookingCancelModal ? (
                headerData?.bpStatus === 'Booked' ||
                headerData?.bpStatus === 'Ready_for_Booking' ||
                headerData?.bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}

            {showPackingModal ? (
                <ContainerPackingModal
                    modalView={showPackingModal}
                    setModalView={setShowPackingModal}
                    bdPk={containerDetailId}
                />
            ) : null}

            <CancelModal
                displayFunction={() => {
                    showBasicDetails && setShowBasicDetails(false);
                    showContainerCreateModal && setShowContainerCreateModal(false);
                }}
                header="Booking Details"
                cancelModalVisibility={cancelModalView}
                setCancelModalVisibility={setCancelModalView}
            />
            <RejectBookingProposalModal
                modalView={rejectionModalView}
                setModalView={setRejectionModalView}
                header={{
                    bpNumber: headerData.bpNumber ?? '',
                    clientName: headerData.clientName ?? '',
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={{ profileType, userName }}
                reason={reason}
                setReason={setReason}
                headerText="Reject Booking Proposal"
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            <RejectBookingProposalModal
                modalView={assignAgentModalView}
                setModalView={setAssignAgentModalView}
                header={{
                    bpNumber: headerData.bpNumber != null ? headerData.bpNumber : '',
                    clientName: headerData.clientName != null ? headerData.clientName : '',
                    gateInDateExpired: headerData.gateInDateExpired,
                }}
                userProfile={{ profileType: profileType, userName: userName }}
                reason={reasonRejection}
                setReason={setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={setRejectedBpForwarderModalView}
                gateInDateExpired={headerData?.gateInDateExpired}
            />
            {displayModal && <UnavailableModel setdisplayModal={setdisplayModal} />}
        </div>
    );
};
export default Index;
