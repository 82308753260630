import React from 'react';
import { Button, Card, Placeholder, PlaceholderImage, Segment } from 'semantic-ui-react';
import './vendorPortal.css';
import { Grid } from '@progress/kendo-react-grid';

const loadingPanel = (
    <div className="k-loading-mask">
        <span className="k-loading-text">Loading</span>
        <div className="k-loading-image"></div>
        <div className="k-loading-color"></div>
    </div>
);

const Index: React.FC = () => {
    return (
        <div className="grid-section product-details-grid">
            <Card.Group itemsPerRow={10} stackable={true} doubling={true} className="card_group">
                <Card className="ph_single_card">
                    <Placeholder className="ph_image">
                        <PlaceholderImage />
                    </Placeholder>
                </Card>
                <Card className="ph_single_card">
                    <Placeholder className="ph_image">
                        <PlaceholderImage />
                    </Placeholder>
                </Card>
                <Card className="ph_single_card">
                    <Placeholder className="ph_image">
                        <PlaceholderImage />
                    </Placeholder>
                </Card>
                <Card className="ph_single_card">
                    <Placeholder className="ph_image">
                        <PlaceholderImage />
                    </Placeholder>
                </Card>
            </Card.Group>

            <br />
            <Segment className="sscc_segment">
                <form id="my-form" className="modal-form form-fields">
                    <div className="modal-input-holder layout-item">
                        <label className="modal-input-label">First SSCC</label>
                        <div className="modal-input-layout-item">
                            <input type="number" className="modal-input input-text" disabled={true}></input>
                        </div>
                    </div>

                    <div className="modal-input-holder layout-item">
                        <label className="modal-input-label">Quantity</label>
                        <div className="modal-input-layout-item">
                            <input type="number" className="modal-input input-text" disabled={true}></input>
                        </div>
                    </div>

                    <div className="form-submit-btn">
                        <Button primary disabled={true} title="Submit" form="my-form" type="submit">
                            Create
                        </Button>
                    </div>
                </form>
            </Segment>
            <Card.Group itemsPerRow={3} stackable={true} doubling={true} className="card_group">
                <Card className="single_card_grid">
                    {loadingPanel}
                    <Grid></Grid>
                </Card>
                <Card className="single_card_grid">
                    {loadingPanel}
                    <Grid></Grid>
                </Card>
            </Card.Group>
        </div>
    );
};

export default Index;
