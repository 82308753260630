import { createSlice } from '@reduxjs/toolkit';

export const linkClientVendor = createSlice({
    name: 'linkClientVendor',
    initialState: {
        clients: [],
        allClients: [],
        isLoading: false,
        currentPage: 1,
        success: false,
        linkedClients: 0,
        unlinkDat: [],
        selectedList: [],
        activeClients: [],
        totalActiveClients: 0,
        totalPages: 1,
        successFetch: false,
        loading: true,
        linkToast: false,
        unlinkToast: { success: false, failed: false },
    },
    reducers: {
        fetchData: (state, _action) => {
            state.isLoading = true;
        },
        fetchAllData: (state, _action) => {
            state.isLoading = true;
        },
        saveFetchedData: (state, action) => {
            state.clients = action.payload?.info?.clientResponseLists;
            if (
                action.payload?.info?.clientResponseLists?.length !== 0 &&
                Array.isArray(action.payload?.info?.clientResponseLists)
            ) {
                state.successFetch = true;
            } else {
                state.successFetch = false;
            }
            state.isLoading = false;
            state.totalActiveClients = action.payload?.info?.totalLinkedClients;
            state.totalPages = action.payload?.info?.totalNoOfPages;
        },
        saveAllFetchedData: (state, action) => {
            state.allClients = action.payload?.info?.clientResponseLists;
        },
        activeClients: (_state, _action) => {
            // console.log(action);
        },
        actionClientsData: (state, action) => {
            state.activeClients = action.payload;
        },
        linkingClients: (state, _action) => {
            state.success = true;
        },
        linkingClientsData: (state, action) => {
            state.success = true;
            state.linkedClients = action.payload.length;
            state.success = true;
        },
        unlinkData: (state, action) => {
            state.success = false;
            state.unlinkDat = action.payload;
            state.success = true;
        },
        unlinkClient: (_state, _action) => {
            // console.log(action.payload, 'client_id');
        },
        isLoadingfn: (state, action) => {
            state.loading = action.payload;
        },
        clearClients: (state, _action) => {
            state.clients = [];
        },
        onUnlinking: (state, action) => {
            if (action.payload?.message === 'Success') {
                state.unlinkToast.success = true;
                state.unlinkToast.failed = false;
            } else {
                state.unlinkToast.success = false;
                state.unlinkToast.failed = true;
            }
        },
        linkingToast: (state, action) => {
            state.linkToast = action.payload;
        },
        unlinkingtoast: (state, _action) => {
            state.unlinkToast.success = false;
            state.unlinkToast.failed = false;
        },
        searchVendorClientsData: (state, _action) => {
            state.isLoading = true;
        },
    },
});

export const {
    fetchData,
    fetchAllData,
    linkingClients,
    unlinkData,
    unlinkClient,
    linkingClientsData,
    activeClients,
    saveFetchedData,
    saveAllFetchedData,
    actionClientsData,
    isLoadingfn,
    clearClients,
    onUnlinking,
    unlinkingtoast,
    linkingToast,
    searchVendorClientsData,
} = linkClientVendor.actions;
export default linkClientVendor.reducer;
