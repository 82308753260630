import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useParams, useNavigate } from 'react-router-dom';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import BookingAttachments from '../BookingAttachment';
import { fetchFiles, setWarning } from 'src/store/reducers/bookingProposalAir/attachmentsReducer';
//TODO: update with reducers with bp air specific
import { updateBP, clearFailure } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
//TODO: update with reducers with bp air specific
import { fetchBpHeaderData } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from 'src/hooks/useUserProfile';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import ShowForPermissions from 'src/ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
// import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { error, Position, success } from 'src/utils/toast';
import { getUserDetails } from 'src/utils/getUserDetails';
import { NavData } from 'src/pages/PoBookingsAir/Wrapper';
import { fetchShippingDetail } from 'src/store/reducers/bookingProposalAir/shippingDetailsReducer';
import {
    fetchEnrichBooking,
    fetchHouseBills,
    selectEnrichBooking,
    selectHouseBillDetails,
} from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import { useAppSelector } from 'src/hooks';
import { Icon } from 'semantic-ui-react';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { cixDownloadFile, fileEventHistory } from 'src/services/apis/documentApi';
import DeleteModal from './deleteConfirmModal';
import EventModal from './eventModal';
import { resetDeleteResult } from 'src/store/reducers/documentReducer';
import FileUploadModal from 'src/pages/Common/fileUploader';
import { airOptionStatus } from '../Options';

interface Props {
    navData: NavData[];
}
const Index: React.FC<Props> = ({ navData }) => {
    const headerData: any = useSelector((state: RootState) => state.bpHeaderData);
    const { submitBPSuccess, submitBPError } = useSelector((state: RootState) => state.additionalDetailsData);
    const { files, isLoading, errorMessage, errorStat } = useSelector(
        (state: RootState) => state.bookingProposalAirAttachment
    );
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const { profileType } = useUserProfile();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const houseBillData = useAppSelector(selectHouseBillDetails);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const [validation, setvalidation] = useState(false);
    const [nextBtn, setNextBtn] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [doPk, setDoPk] = useState('');
    const { deleteResult } = useSelector((state: RootState) => state.document);
    const [eventHistory, setEventHistory] = useState([]);
    /* eslint no-var: off */

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const shippingDetails = useSelector((state: RootState) => ({
        shippingDetailsAir: state.shippingDetailsAir,
    }));
    //Show the toast after successful booking proposal approval
    useEffect(() => {
        if (submitBPSuccess) {
            success(`Booking details sent successfully`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPError) {
            error(`Something went wrong`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPSuccess || submitBPError) {
            dispatch(clearFailure([]));
            navigate('/booking_list');
        }
    }, [submitBPSuccess, submitBPError]);
    useEffect(() => {
        dispatch(fetchFiles(id));
        // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
    }, [id]);

    // var stat = false;

    useEffect(() => {
        dispatch(setWarning({ errorStat: false, message: '' }));
        if (errorStat) {
            error(errorMessage, Position.TOP_RIGHT);
        }
    }, [errorStat, errorMessage]);
    useEffect(() => {
        if (id) {
            dispatch(fetchEnrichBooking({ bpId: id }));
            dispatch(fetchHouseBills({ bpId: id, profileType }));
        }
    }, [id]);
    useEffect(() => {
        if (id) {
            dispatch(fetchShippingDetail({ shipmentId: id }));
        }
    }, [id]);
    useEffect(() => {
        if (headerData.bpStatus === 'Draft_Booking' && profileType !== 'client') {
            if (
                houseBillData &&
                enrichBooking &&
                shippingDetails &&
                shippingDetails?.shippingDetailsAir &&
                enrichBooking?.originCartageCompany &&
                houseBillData?.numbers?.length > 0 &&
                houseBillData?.numbers[0]?.vendors?.length > 0 &&
                houseBillData?.numbers[0]?.vendors[0]?.houseBillNumber &&
                enrichBooking?.soNumber &&
                // houseBillData?.masterBillNumber &&
                shippingDetails?.shippingDetailsAir?.shippingDetails?.containerType &&
                shippingDetails?.shippingDetailsAir?.shippingDetails?.deliveryMode &&
                shippingDetails?.shippingDetailsAir?.shippingDetails?.packing
                // &&
                // shippingDetails?.shippingDetailsAir?.shippingDetails?.vgm &&
                // shippingDetails?.shippingDetailsAir?.shippingDetails?.pickUpDate &&
                // shippingDetails?.shippingDetailsAir?.shippingDetails?.dropOffDate
            ) {
                setNextBtn(true);
            } else {
                setNextBtn(false);
            }
        }
    }, [headerData.bpStatus, enrichBooking, houseBillData, shippingDetails?.shippingDetailsAir?.shippingDetails]);

    useEffect(() => {
        setvalidation(true);
    }, [files]);

    // const navData = [
    //     {
    //         title: 'PO Selection',
    //         selected: 0,
    //         path: id ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new',
    //         disabled: false,
    //     },
    //     {
    //         title: 'Flight Schedules',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/FlightSelectionDetails`,
    //         disabled: false,
    //     },
    // ];
    // if (headerData.bpStatus === 'Ready_for_Booking' || headerData.bpStatus === 'Draft_Booking') {
    //     navData.push(
    //         {
    //             title: 'Booking Details',
    //             selected: 0,
    //             path: `/air_booking_list/${id}/booking_details`,
    //             disabled: false,
    //         },
    //         {
    //             title: 'Shipment Details',
    //             selected: 0,
    //             path: `/air_booking_list/${id}/shipment_details`,
    //             disabled: false,
    //         },

    //         {
    //             title: 'Attachments',
    //             selected: 1,
    //             path: `/air_booking_list/${id}/attachments`,
    //             disabled: false,
    //         }
    //     );
    // }

    const submitReadyForShipment = () => {
        const { userName } = getUserDetails();
        dispatch(updateBP({ bookingId: id, userName, profile: profileType }));
    };

    const customNavData = navData.map((navItem) => {
        if (navItem.key === 'options') {
            return { ...navItem, disabled: !(headerData?.tabValues as any)?.po };
        } else if (navItem.key === 'flight_schedules') {
            return {
                ...navItem,
                disabled:
                    !(headerData?.tabValues as any)?.airOptions ||
                    airOptionStatus?.includes(headerData?.bpStatus ?? ''),
            };
        } else if (navItem.key === 'packing') {
            return {
                ...navItem,
                disabled:
                    !(headerData?.tabValues as any)?.airSchedules ||
                    airOptionStatus?.includes(headerData?.bpStatus ?? ''),
            };
        } else if (navItem.key === 'attachments') {
            return { ...navItem, selected: 1 };
        } else {
            return navItem;
        }
    });
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    useEffect(() => {
        if (deleteResult?.length > 0) {
            if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
            dispatch(resetDeleteResult({}));
            setShowDeleteModal(false);
            setDoPk('');
        }
    }, [deleteResult]);

    const downloadFile = (doPk: string, filename: string) => {
        cixDownloadFile(doPk, filename);
    };

    //@ts-ignore
    window.attachmentAction = (action: string, doPK?: string, filename?: string) => {
        if (action === 'DLD') {
            if (doPK && filename) downloadFile(doPK, filename);
        } else if (action === 'DEL') {
            if (doPK) {
                setDoPk(doPK);
                setShowDeleteModal(true);
            }
        } else if (action === 'EVT') {
            if (doPK) {
                fileEventHistory(doPK ?? '')
                    .then((res: any) => {
                        setEventHistory(res);
                        setShowEventModal(true);
                    })
                    .catch((err: any) => console.log(err));
            }
        } else if (action === 'NEW') {
            setShowUploadModal(true);
        }
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>
                {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )}
                <BookingProcessTab navData={customNavData} />
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper grid-p-0">
                <div className="grid-header grid-ml-20">
                    <div className="content-heading-holder card-head-btn-edit-holder">
                        <span className="content-heading">
                            Attachments
                            <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                <span className="hub-tooltiptext" style={{ width: '400px' }}>
                                    Attachments will show documents uploaded for this booking, its children, or linked
                                    POs.
                                </span>
                                <Icon name="question circle outline" />
                            </span>
                        </span>
                    </div>
                </div>

                <LayoutWithColumns content={'bookingDocuments'} hideLoader style="booking_details" />
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking</span>
                                </button>
                            </ShowForPermissions>
                        )} */}
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Draft_Booking_Proposal' ||
                            bpStatus === 'Rejected_by_Forwarder' ||
                            bpStatus === 'Rejected_by_Client_Agent_to_Action') && (
                            <>
                                {bpStatus === 'Booked' || bpStatus === 'Cancelled' ? null : (
                                    <ShowForPermissions type="bp" permission="create">
                                        <button
                                            title="Cancel Booking Proposal"
                                            className="app-btn app-btn-secondary footer-btn"
                                            onClick={() => {
                                                setShowBookingCancelModal(true);
                                            }}
                                        >
                                            <span className="button-text footer-button-text">
                                                Cancel Booking Proposal
                                            </span>
                                        </button>
                                    </ShowForPermissions>
                                )}
                            </>
                        )} */}
                    {/* <Link title="Save" className="app-btn app-btn-secondary footer-btn" to={'/booking_list'}>
                        <span className="button-text footer-button-text">Save as Draft</span>
                    </Link> */}
                    {/* <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/air_booking_list/${id}/shipment_details`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <ShowForPermissions type="bp" permission="create">
                        {bpStatus !== 'Booked' && (profileType === 'agent' || profileType === 'forwarder') && (
                            <a
                                title="Ready for Shipment"
                                className={`app-btn app-btn-primary footer-btn ${
                                    nextBtn && validation && headerData?.tabValues?.containerDetails ? '' : 'disabled'
                                }`}
                                onClick={() => {
                                    if (nextBtn && validation && headerData?.tabValues?.containerDetails) {
                                        submitReadyForShipment();
                                    }
                                }}
                            >
                                <span className="button-text footer-button-text">Ready for Shipment</span>
                            </a>
                        )}
                    </ShowForPermissions> */}
                </div>
            </div>
            {showUploadModal && (
                <FileUploadModal setShowFileUploadModal={setShowUploadModal} referenceId={id} referenceType={'BP'} />
            )}

            {showDeleteModal && (
                <DeleteModal setModalView={setShowDeleteModal} modalView={showDeleteModal} doPK={doPk} />
            )}

            {showEventModal && <EventModal setModalView={setShowEventModal} eventHistory={eventHistory} />}

            {showBookingCancelModal &&
                (headerData?.bpStatus === 'Booked' ||
                    headerData?.bpStatus === 'Ready_for_Booking' ||
                    headerData?.bpStatus === 'Draft_Booking') && (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                )}
        </div>
    );
};
export default Index;
