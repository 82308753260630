import fileDownload from 'js-file-download';
import { del, get, post } from 'src/services/HttpMethods';

export const getAllFiles = async (id: string) => {
    try {
        const res = await get(`api/bkg/bp/attachments/view/${id}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const uploadFile = async ({ id, data }: { id: string; data: FormData }) => {
    try {
        const res = await post(`api/bkg/bp/attachments/upload/${id}`, data, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const removeFile = async ({ fileId }: { fileId: string }) => {
    try {
        const res = await del(`api/bkg/bp/attachments/remove?fileId=${fileId}`, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const downloadSingleFile = async ({ name, fileId }: { name: string; fileId: string }) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/bkg/bp/attachments/download?fileId=${fileId}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, name);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};

export const downloadAllFiles = async ({ id, type }: { id: string; type: string }) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/bkg/bp/attachments/downloadAll/${id}?docType=${type}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                //console.log(result);
                fileDownload(result, `${type}.zip`);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
