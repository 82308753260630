import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { fileTypesInfoText, fileUploadInfoText } from 'src/utils/constants';
import { cixUploadDoc, fetchDocumentTypes, resetUploadResult } from 'src/store/reducers/documentReducer';
import { RootState } from 'src/store/reducers';
import { Position, error } from 'src/utils/toast';
import './fileUploader.scss';
import {
    getAllLinkedShipment,
    getLinkedBpByShipmentId,
} from 'src/services/apis/bookingProposal/bookingProposalListingApi';
import LinkShipmentConfirmModal from './linkShipmentConfirmModal';
interface FileUploadProps {
    setShowFileUploadModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    referenceType: string;
    organisationData: any;
}

const Index = ({ setShowFileUploadModal, referenceType, organisationData }: FileUploadProps): ReactElement => {
    // const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const [file, setFile] = useState(null as any);
    // user selected shipment ids
    const [shipmentData, setShipmentData] = useState([] as any);
    const [shipmentList, setShipmentList] = useState([] as any);
    const [showLinkShipmentConfirm, setShowLinkShipmentConfirm] = useState(false);
    const [linkShipmentObj, setLinkShipmentObj] = useState({});

    const { fileTypes, uploadResult } = useSelector((state: RootState) => state.document);

    useEffect(() => {
        dispatch(fetchDocumentTypes({ searchTerm }));
    }, [searchTerm]);

    useEffect(() => {
        if (uploadResult?.length > 0) {
            if (uploadResult[0].error?.status === 400) {
                error(uploadResult[0].stack, Position.TOP_RIGHT);
            }
            if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
            dispatch(resetUploadResult({}));
            setShowFileUploadModal(false);
        }
    }, [uploadResult]);

    const validationSchema = Yup.object().shape({
        organisation: Yup.string().required('Organisation is Required'),
        primaryFileType: Yup.string().required('File Type is Required'),
        subFileType: Yup.string().test({
            name: 'primaryFileType',
            exclusive: false,
            params: {},
            message: 'Sub file type is required',
            test: function (value) {
                return (value ? true : false) || this.parent.primaryFileType !== 'INV';
            },
        }),
        fileName: Yup.string().required('File name is Required'),
    });

    const initialValues: any = {
        organisation: '',
        primaryFileType: '',
        subFileType: '',
        fileName: '',
    };

    const getShipmentIds = () => {
        let shipmentIds = '$';
        shipmentList.forEach((s: any) => {
            if (s.childShipment) shipmentIds += `,${s.parentShipment},${s.childShipment}`;
            else shipmentIds += `,${s.parentShipment}`;
        });
        return shipmentIds.replace('$,', '');
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // validateOnMount: modalView,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('DO_FK', values.organisation);
                formData.append('DO_FKID', referenceType);
                formData.append('DO_CD_DocumentType', values.primaryFileType);
                formData.append('DO_CD_SubDocumentType', values.subFileType || null);
                formData.append('XX_ShipmentIDs', getShipmentIds());
                const payload = {
                    data: formData,
                };

                dispatch(cixUploadDoc(payload));
            }
        },
    });

    const getShipmentData = async () => {
        const clientCode = organisationData?.find((res: any) => res?.value === formik.values.organisation)?.label;
        const shipmentData = await getAllLinkedShipment(clientCode);
        setShipmentData(shipmentData);
    };

    useEffect(() => {
        if (formik.values.organisation) {
            getShipmentData();
        }
    }, [formik.values]);

    const removeFile = (fileId: any) => {
        setFile(null);
        formik.setFieldValue('fileName', '');
    };

    const removeShipment = (shipmentId: string) => {
        let newShipments = shipmentList.filter((shp: any) => {
            return shp.parentShipment !== shipmentId;
        });
        setShipmentList(newShipments);
        setFile(null);
    };

    const addShipment = (shipmentId: string) => {
        getLinkedBpByShipmentId(shipmentId).then((data: any) => {
            if (data?.length > 0) {
                let subShipments = '$';
                data.forEach((d: any) => {
                    subShipments += `,${d?.BP_ShipmentId}`;
                });
                setLinkShipmentObj({ parentShipment: shipmentId, childShipment: subShipments.replace('$,', '') });
                setShowLinkShipmentConfirm(true);
            } else {
                const newList = [...shipmentList, { parentShipment: shipmentId }];
                setShipmentList(newList);
            }
        });
    };

    const isButtonEnable = (): boolean => {
        if (
            formik.values.organisation &&
            formik.values.primaryFileType === 'INV' &&
            formik.values.subFileType &&
            shipmentList.length > 0 &&
            formik.values.fileName
        )
            return true;
        else if (formik.values.organisation && formik.values.primaryFileType === 'SOA' && file) return true;
        else return false;
    };

    return (
        <div className="app-modal">
            <div className="modal-content-holder large-popup file-upload-modal">
                <div className="modal-header">
                    <div className="title">File Upload</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowFileUploadModal(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content modal-content-file-upload">
                    <div className="modal-main-content">
                        <form id="upload-form" action="" className="modal-form" onSubmit={formik.handleSubmit}>
                            <div className=" modal-input-holder layout-item">
                                <label className="modal-input-label">
                                    Organisation<span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <SearchableSelectBox
                                        // TODO: replace this with remote data
                                        // OR_PK
                                        data={organisationData}
                                        placeholder="Enter organisation and search"
                                        formik={formik}
                                        fieldName="organisation"
                                        isClearable={true}
                                        onInputChangeFn={(input: any) => {
                                            setSearchTerm(input?.toUpperCase());
                                        }}
                                        onClear={() => {
                                            formik.setFieldValue('primaryFileType', '');
                                            formik.setFieldValue('subFileType', '');
                                            formik.setFieldValue('shipment', '');
                                            formik.setFieldValue('fileName', '');
                                            setShipmentList([]);
                                            setFile(null);
                                        }}
                                    />
                                </div>
                                {formik.errors.organisation ? (
                                    <div className="error-text">{formik.errors.organisation as any}</div>
                                ) : null}
                            </div>
                            {formik.values.organisation && (
                                <div className=" modal-input-holder layout-item">
                                    <label className="modal-input-label">
                                        File Type<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            // hard code is accepted
                                            data={[
                                                { label: 'Invoice (INV)', value: 'INV' },
                                                { label: 'Statement of account (SOA)', value: 'SOA' },
                                            ]}
                                            placeholder="Select File Type"
                                            formik={formik}
                                            fieldName="primaryFileType"
                                            isClearable={true}
                                            onInputChangeFn={(input: any) => {
                                                setSearchTerm(input?.toUpperCase());
                                            }}
                                            onChangeFn={(value: any) => {
                                                if (formik.values.primaryFileType !== value) {
                                                    formik.setFieldValue('subFileType', '');
                                                    formik.setFieldValue('shipment', '');
                                                    formik.setFieldValue('fileName', '');
                                                    setShipmentList([]);
                                                    setFile(null);
                                                }
                                            }}
                                            onClear={() => {
                                                formik.setFieldValue('subFileType', '');
                                                formik.setFieldValue('shipment', '');
                                                formik.setFieldValue('fileName', '');
                                                setShipmentList([]);
                                                setFile(null);
                                            }}
                                        />
                                    </div>
                                    {formik.errors.primaryFileType ? (
                                        <div className="error-text">{formik.errors.primaryFileType as any}</div>
                                    ) : null}
                                </div>
                            )}
                            {formik.values.primaryFileType === 'INV' && (
                                <div className=" modal-input-holder layout-item">
                                    <label className="modal-input-label">
                                        Sub Type<span className="mandatory-field-text">*</span>
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <SearchableSelectBox
                                            // hard code is accepted
                                            data={[
                                                { label: 'Foreign Exchange (SOA)', value: 'SOA' },
                                                { label: 'Final (FIN)', value: 'FIN' },
                                                { label: 'Dispursment (DSP)', value: 'DSP' },
                                            ]}
                                            placeholder="Select File Type"
                                            formik={formik}
                                            fieldName="subFileType"
                                            isClearable={true}
                                            onInputChangeFn={(input: any) => {
                                                setSearchTerm(input?.toUpperCase());
                                            }}
                                            onClear={() => {
                                                formik.setFieldValue('shipment', '');
                                                formik.setFieldValue('fileName', '');
                                                setShipmentList([]);
                                                setFile(null);
                                            }}
                                        />
                                    </div>
                                    {formik.errors.subFileType ? (
                                        <div className="error-text">{formik.errors.subFileType as any}</div>
                                    ) : null}
                                </div>
                            )}
                            {formik.values.organisation &&
                                formik.values.primaryFileType === 'INV' &&
                                formik.values.subFileType && (
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">
                                            Shipments<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                // TODO: replace this with remote data
                                                // find shipment by organisation
                                                data={shipmentData}
                                                placeholder="Enter organisation and search"
                                                formik={formik}
                                                fieldName="shipment"
                                                isClearable={true}
                                                onInputChangeFn={(input: any) => {
                                                    setSearchTerm(input?.toUpperCase());
                                                }}
                                                onClear={() => {
                                                    formik.setFieldValue('fileName', '');
                                                    setShipmentList([]);
                                                    setFile(null);
                                                }}
                                                menuPortalTarget={document.getElementById('outside_div')}
                                            />
                                        </div>
                                        <button
                                            onClick={() => {
                                                addShipment(formik.values.shipment);
                                                formik.setFieldValue('shipment', '');
                                            }}
                                            className="app-btn app-btn-secondary modal-btn add-button"
                                            title="add"
                                            type="button"
                                            disabled={!formik.values.shipment}
                                        >
                                            <span className="button-text footer-button-text">Add</span>
                                        </button>
                                    </div>
                                )}

                            {shipmentList?.length > 0 && (
                                <div className="grid-container">
                                    <div className="grid-scroll-content">
                                        <table className="grid-table">
                                            <thead>
                                                <tr className="grid-sticky-row">
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Primary Shipment</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header">
                                                        <div className="grid-cell-data">
                                                            <span>Linked Shipment(s)</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-actions">
                                                        <div className="grid-cell-data header-title">ACTIONS</div>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {shipmentList?.map((res: any, index: number) => (
                                                    <tr key={index} className="has-child row-expanded">
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res?.parentShipment}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {res?.childShipment || 'N/A'}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell centre-align grid-sticky-column sticky-right-aligned">
                                                            <div className="grid-cell-data ">
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="Remove"
                                                                    onClick={() => {
                                                                        removeShipment(res?.parentShipment);
                                                                    }}
                                                                >
                                                                    <svg className="svg-icon unlink-icon">
                                                                        <use xlinkHref="#deleteIcon"></use>
                                                                        <title>Remove</title>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {formik.errors.shipment ? (
                                        <div className="error-text">{formik.errors.shipment as any}</div>
                                    ) : null}
                                </div>
                            )}
                            {((formik.values.primaryFileType === 'INV' && shipmentList?.length > 0) ||
                                formik.values.primaryFileType === 'SOA') && (
                                <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                    <label htmlFor="name" className="modal-input-label"></label>
                                    <div className="modal-input-field-holder">
                                        {file ? (
                                            <div className="content-with-icon  attachment-holder">
                                                <div
                                                    className="attachment-icon-holder"
                                                    // onClick={() => downloadFileSingle(file?.fileId, file?.fileName)}
                                                >
                                                    <button type="button" className="app-btn " title="Attach file">
                                                        <svg className="svg-icon file-attachment-icon ">
                                                            <use xlinkHref="#fileAttachmentIcon"></use>
                                                            <title>Attach file</title>
                                                        </svg>
                                                    </button>
                                                    <div className="filename-folder">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="description-type-1 file-uploaded"
                                                            title="Download"
                                                        >
                                                            {file?.name}
                                                        </a>
                                                        <div className="heading-type-1">{file?.size}kb</div>
                                                    </div>
                                                </div>

                                                <a
                                                    href="javascript:void(0)"
                                                    className="file-remove-link"
                                                    title="Remove"
                                                    onClick={() => removeFile(file?.name)}
                                                >
                                                    Remove
                                                </a>
                                            </div>
                                        ) : (
                                            <div className="modal-input-layout-item">
                                                <div className="browse-btn-wrapper">
                                                    <div className=" modal-input-holder upload-file-btn">
                                                        <input
                                                            type="file"
                                                            id={'ad_file'}
                                                            name={'ad_file'}
                                                            accept={'.pdf'}
                                                            className="browse-input"
                                                            onChange={(e) => {
                                                                setFile((e.target.files as any[0])[0]);
                                                                formik.setFieldValue(
                                                                    'fileName',
                                                                    (e.target.files as any[0])[0].name
                                                                );
                                                            }}
                                                        ></input>
                                                        <button className="app-btn app-btn-secondary icon-button browse-btn">
                                                            <svg className="svg-icon plus-icon">
                                                                <use xlinkHref="#plusIcon">
                                                                    <title>Upload File</title>
                                                                </use>
                                                            </svg>
                                                            <span className="button-text upload-btn">
                                                                Drag or Select Invoice to Upload
                                                            </span>
                                                        </button>

                                                        {/* <div className="error-text">mk</div> */}
                                                    </div>
                                                    <div className="heading-type-1 file-size">
                                                        <p style={{ textAlign: 'left' }}>{fileUploadInfoText}</p>
                                                        <p
                                                            style={{ textAlign: 'left' }}
                                                            dangerouslySetInnerHTML={{
                                                                __html: '( Allowed file types: .pdf )',
                                                            }}
                                                        ></p>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {/* {formik.errors.fileName ? (
                                            <div className="error-text">{formik.errors.fileName as any}</div>
                                        ) : null} */}
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                        onClick={(e) => {
                            e.preventDefault();
                            setShowFileUploadModal(false);
                        }}
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className={`app-btn app-btn-primary modal-btn footer-btn`}
                        type="submit"
                        form="upload-form"
                        title="Confirm Upload"
                        disabled={!isButtonEnable()}
                    >
                        <span className="button-text footer-button-text">Confirm Upload</span>
                    </button>
                </div>
            </div>
            {showLinkShipmentConfirm && (
                <LinkShipmentConfirmModal
                    modalView={showLinkShipmentConfirm}
                    setModalView={setShowLinkShipmentConfirm}
                    shipmentList={shipmentList}
                    setShipmentList={setShipmentList}
                    linkShipmentObj={linkShipmentObj}
                    setLinkShipmentObj={setLinkShipmentObj}
                />
            )}
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
        </div>
    );
};
export default Index;
