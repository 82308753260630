import React, { useEffect } from 'react';
import WorkTrendsHeader from './WorkTrendsHeader';
import WorkTrendsProgress from './WorkTrendsProgress';
import WorkTrendsActivityList from './WorkTrendsActivityList';
import './workTrendsCommon.scss';
import './workTrendsDetailed.scss';
import {
    selectCurrentFilter,
    updateFilter,
    selectWorkTrends,
    fetchWorkTrendsList,
    isLoading,
} from 'src/store/reducers/dashboard/workTrendsReducer';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';

const Index: React.FC = () => {
    const dispatch = useAppDispatch();
    const currentFilter = useAppSelector(selectCurrentFilter);
    const workTrends = useAppSelector(selectWorkTrends);
    const { profileType, userName } = useUserProfile();
    const isLoadingVal = useAppSelector(isLoading);
    const { userIds } = useSelector((state: RootState) => state.workTrendsData);

    useEffect(() => {
        if (profileType && userName) {
            dispatch(fetchWorkTrendsList({ filter: currentFilter, profile: profileType, userName, userIds }));
        }
    }, [profileType, userName]);

    useEffect(() => {
        if (currentFilter) {
            dispatch(updateFilter(currentFilter));
        }
    }, [currentFilter]);

    return (
        <div className="main-wrapper container dashboard-wrapper">
            <div className="main-header-content-holder dashboard-header">
                <h1 className="header-title">Dashboard</h1>
                {/* <div className="dashboard-left-portion">
                    <div className="search-entry-holder modal-search dashboard-search">
                        <input type="text" className="search-input input-text with-border" required />
                        <span className="search-placeholder default-view">Search and filter Dashboard</span>
                        <span className="search-placeholder expanded-view">
                            Search for Orders, Bookings, Clients, Vendors, Agents, Carriers
                        </span>
                        <svg className="svg-icon search-icon">
                            <use href="#searchIcon">
                                <title>Search</title>
                            </use>
                        </svg>
                        <button className="app-btn text-close-btn">
                            <svg className="svg-icon text-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </button> */}
                {/* <div className="dropdown-wrap  menu-down dropdown-open text-dropdown dashboard-suggestion-menu">
                            <div className="dropdown-menu details-col details-col details-box-content customscrollbar">
                                <div className="menu-item-wrapper">
                                    <div className="main-heading">Purchase Orders</div>
                                    <a className="menu-item app-btn">
                                        <div className="main-content">123AB111</div>
                                        <div className="main-content-name">Vendor Name</div>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <div className="main-content">123AB222</div>
                                        <div className="main-content-name">Vendor Name</div>
                                    </a>
                                </div>
                                <div className="menu-item-wrapper">
                                    <div className="main-heading">Bookings</div>
                                    <a className="menu-item app-btn">
                                        <div className="main-content">123AB111</div>
                                        <div className="main-content-name">
                                            <span>Yantian (CNYAN)</span> to <span>Melbourne (AUMEL)</span>
                                        </div>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <div className="main-content">123AB222</div>
                                        <div className="main-content-name">
                                            <span>Yantian (CNYAN)</span> to <span>Melbourne (AUMEL)</span>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div> */}
                {/* </div>
                </div> */}
            </div>
            <div className="work-trends-page">
                <WorkTrendsHeader />
                <div className={`app-loader ${isLoadingVal ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
                <div className="WorkTrends-wrapper">
                    <WorkTrendsProgress data={workTrends} />
                    <WorkTrendsActivityList></WorkTrendsActivityList>
                </div>
            </div>
        </div>
    );
};
export default Index;
