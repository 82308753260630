import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import './client-modal.scss';
import Modal from '../../../../components/common/Modals/clientModal';
import '../../../../assets/scss/components/_modal.scss';
import { useDispatch } from 'react-redux';
import { linkingClients, linkingClientsData, activeClients } from '../../../../store/reducers/linkClientVendorReducer';
import CancelModal from './cancelModal';
interface BioProps {
    display: boolean;
    displayFunction: any;
    clientId: any;
    clientList: any;
    assignedClients: any;
    payload: any;
    settoastStat: any;
    setcurrentPage: any;
    currentClients: any;
}
interface simpleInt {
    clientId: string;
    orgCode: string;
    orgName: string;
}

type simpleType = simpleInt[];

const array: simpleType = [];
const Index: React.FC<BioProps> = (props) => {
    const [suggetions, setSuggetions] = useState(false);
    const [search, setSearch] = useState('');
    const [disable, setdisable] = useState(true);
    const [selectedClients, setselectedClients] = useState<any>([]);
    const [render, setrender] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    var found = false;
    var parentArray = [...props.clientList];
    parentArray = JSON.parse(JSON.stringify(props.clientList));

    const dispatch = useDispatch();

    useEffect(() => {
        setselectedClients(array);
        if (selectedClients.length === 0) {
            setdisable(true);
        }

        dispatch(activeClients(props?.clientId));
    }, [render, selectedClients, props.assignedClients]);

    const selectedClient = (val: any) => {
        array.push(val);
        setdisable(false);
        setrender(!render);
    };

    const linkClient = () => {
        props.setcurrentPage(0);
        dispatch(linkingClientsData(selectedClients));
        props.displayFunction(false);
        const arraySel: string[] = [];
        selectedClients.forEach((element: any) => {
            arraySel.push(element.clientId);
        });

        const payloadArray = {
            vn_pk: props.payload.id,
            clientId: arraySel,
        };
        const finalPayload = { ...payloadArray, ...props.payload, currentPage: 0 };
        dispatch(linkingClients(finalPayload));
        setselectedClients([]);
        array.length = 0;
        props.settoastStat(true);
        //toast.success(`${arraySel.length}${arraySel.length <= 1 ? ' vendor' : ' vendors'} have been linked`, {
        //    position: 'bottom-left',
        //    autoClose: 2000,
        //    hideProgressBar: false,
        //});
        setdisable(true);
    };

    const arryFilter = () => {
        /* eslint no-var: off */
        const childArray = props.assignedClients;
        //if (parentArray.length !== childArray.length) {
        // for (let i = 0; i < parentArray?.length; i++) {
        for (let j = 0; j < childArray?.length; j++) {
            // if (parentArray[i]?.clientId === childArray[j]?.clientId) {
            //     parentArray.splice(i, 1);
            // }
            parentArray = parentArray.filter((x: any) => x?.clientId !== childArray[j]?.clientId);
        }
        // }
        //if (array.length === parentArray.length) {
        //    return [];
        //} else {
        // for (let i = 0; i <= parentArray?.length; i++) {
        for (let j = 0; j < selectedClients?.length; j++) {
            // if (parentArray[i]?.clientId === selectedClients[j]?.clientId) {
            //     parentArray.splice(i, 1);
            // }
            parentArray = parentArray.filter((x: any) => x?.clientId !== selectedClients[j]?.clientId);
        }
        for (let j = 0; j < props.currentClients?.length; j++) {
            // if (parentArray[i]?.clientId === selectedClients[j]?.clientId) {
            //     parentArray.splice(i, 1);
            // }
            parentArray = parentArray.filter(
                (x: any) => x?.clientId?.toLowerCase() !== props.currentClients[j]?.clientId?.toLowerCase()
            );
        }
        // }

        return parentArray;
        //}
        //} else {
        //    return [];
        //}
    };

    const cancelButton = () => {
        if (!disable) {
            setDisplayCancel(true);
        } else {
            array.length = 0;
            setSuggetions(false);
            props.displayFunction(false);
            setdisable(true);
        }
    };
    const cancelButtonProp = () => {
        array.length = 0;
        setSuggetions(false);
        props.displayFunction(false);
        setdisable(true);
    };

    useEffect(() => {
        setSearch('');
    }, [props.display]);

    return (
        <>
            <Modal
                display={props.display}
                displayFunction={props.displayFunction}
                title={'Link Clients'}
                okButtonName={'Link Clients'}
                okButtonFunc={linkClient}
                disable={disable}
                cancelButtonFunc={cancelButton}
                childComp={
                    <div className="modal-content with-input" onClick={() => setSuggetions(false)}>
                        <div className="modal-main-content">
                            <div className="dropdown-wrap menu-down dropdown-open text-dropdown vendor-suggestion-menu">
                                <div className="search-entry-holder modal-search">
                                    <input
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props?.clientList && setSuggetions(!suggetions);
                                            props?.clientList && arryFilter();
                                        }}
                                        placeholder="Enter client name to link"
                                        type="text"
                                        className="search-input input-text with-border"
                                        onChange={(e) => setSearch(e.target.value)}
                                        value={search}
                                    ></input>
                                    {search.length !== 0 && (
                                        <button className="app-btn text-close-btn" onClick={() => setSearch('')}>
                                            <svg className="svg-icon modal-header-close-icon-2">
                                                <use xlinkHref="#closeIcon">
                                                    <title>Close</title>
                                                </use>
                                            </svg>
                                        </button>
                                    )}
                                </div>
                                {/* Search suggestions */}
                                {suggetions && (
                                    <div
                                        style={{ maxHeight: '260px', flexDirection: 'column', display: 'block' }}
                                        className="dropdown-menu roledetails details-col details-col details-box-content customscrollbar"
                                    >
                                        {props?.clientList &&
                                            arryFilter()
                                                ?.filter((val: any) =>
                                                    val.orgName.toLowerCase().includes(search.toLowerCase())
                                                )
                                                .map((res: any) => {
                                                    found = true;
                                                    return (
                                                        <a
                                                            style={{ width: '100%' }}
                                                            className="menu-item app-btn"
                                                            onClick={() => selectedClient(res)}
                                                        >
                                                            <span className="highlight-content">{res?.orgCode}</span>
                                                            <span className="main-content" style={{ width: '65%' }}>
                                                                {res?.orgName}
                                                            </span>
                                                        </a>
                                                    );
                                                })}
                                        {!found && (
                                            <div className="no-data-content">
                                                <svg className="svg-icon grid-no-content-icon ">
                                                    <use xlinkHref="#gridNoContentIcon"></use>
                                                </svg>
                                                <p className="no-content-message">
                                                    No {arryFilter().length === 0 ? '' : 'Matching'} Records Found
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="content-heading-holder">
                                <span className="content-heading">Selected Clients</span>
                                <div className="tiny-status success-status">{array.length} </div>
                            </div>
                            <div
                                className="selected-menu-container"
                                style={{ '--max-col-width': '150px' } as React.CSSProperties}
                            >
                                {array?.map((res: any, index: number) => {
                                    return (
                                        <div className="content-card">
                                            <span className="highlight-content">{res?.orgCode}</span>
                                            <span className="main-content" style={{ whiteSpace: 'normal' }}>
                                                {res?.orgName}
                                            </span>
                                            <button
                                                className="app-btn delete-button"
                                                title="Delete"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    array.splice(index, 1);
                                                    setrender(!render);
                                                }}
                                            >
                                                <svg className="svg-icon delete-icon">
                                                    <use xlinkHref="#deleteIcon"></use>
                                                </svg>
                                            </button>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                }
            ></Modal>
            {displayCancel && (
                <CancelModal
                    displayCancel={displayCancel}
                    setDisplayCancel={setDisplayCancel}
                    onOkFunc={cancelButtonProp}
                />
            )}
        </>
    );
};
export default Index;
