import React from 'react';

import 'src/assets/scss/components/_modal.scss';
import ShowForPermissions from 'src/ShowForPermissions';

interface ChangeModalProps {
    handleClose: any;
    handleConfirm: any;
}

const ChangeModal: React.FC<ChangeModalProps> = (props) => {
    return (
        <div className="app-modal container">
            <ShowForPermissions type="bp" permission="create">
                <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2 className="title">Change Schedule</h2>
                        <button className="app-btn modal-close-btn" onClick={props.handleClose}>
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use xlinkHref="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="modal-content">
                        <p>
                            <span>Are you sure you want to change this schdule and select a different schedule?</span>
                        </p>
                    </div>
                    <div className="modal-footer">
                        <button className="app-btn app-btn-secondary modal-btn footer-btn" onClick={props.handleClose}>
                            <span className="button-text vendor-button-text">No</span>
                        </button>
                        <button
                            style={{ marginLeft: '8px' }}
                            className="app-btn app-btn-primary modal-btn footer-btn"
                            onClick={props.handleConfirm}
                        >
                            <span className="button-text vendor-button-text">Yes</span>
                        </button>
                    </div>
                </div>
            </ShowForPermissions>
        </div>
    );
};

export default ChangeModal;
