import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { Form, FormField, TextArea } from 'semantic-ui-react';

import { cixDeleteDoc } from 'src/store/reducers/documentReducer';
import { createRateHeader } from 'src/services/apis/qmsApi';
import { Position, error } from 'src/utils/toast';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setConfirmed: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<ModalProps> = ({ modalView, setConfirmed, setModalView }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [buttonDisplay, setButtonDisplay] = useState('Confirm');

    const confirmAction = () => {
        setConfirmed(true);
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder booking-cancellation-modal">
                        <div className="modal-header">
                            <div className="title">Action Confirm</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="description-type-1">
                                            Warning: Once confirmed, the header will be locked and changes may not be
                                            allowed
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Delete"
                                onClick={() => {
                                    confirmAction();
                                }}
                                disabled={buttonDisplay === 'Please Wait...'}
                            >
                                <span className="button-text footer-button-text">{buttonDisplay}</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
