import React from 'react';
import { RootState } from 'src/store/reducers';
import { useSelector, useDispatch } from 'react-redux';
import ShowForPermissions from 'src/ShowForPermissions';
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom';
import { savedPo, submitPo } from 'src/store/reducers/purchase_order/basicDetails';

interface Props {
    page: string;
}

const BLPInboundPONextButton: React.FC<Props> = ({ page }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const { headerDetails } = useSelector((state: RootState) => state.addPoData);

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (headerDetails) {
                if (param === 'po_review') {
                    return (
                        headerDetails.bdTabComplete &&
                        headerDetails.datesTabComplete &&
                        headerDetails.validDate &&
                        headerDetails.productTabComplete
                    );
                } else if (param === 'product_details') {
                    return headerDetails.productTabComplete;
                } else if (param === 'dates') {
                    return headerDetails.datesTabComplete && headerDetails.validDate;
                } else if (param === 'basic_details') {
                    return headerDetails.bdTabComplete;
                } else {
                    return false;
                }
            }
        };
    }, [lastSegment, headerDetails]);

    const navigation = (pageName: string) => {
        let toPage = '';
        if (pageName === 'basic_details') {
            toPage = 'dates';
        } else if (pageName === 'dates') {
            toPage = 'product_details';
        } else if (pageName === 'product_details') {
            toPage = 'po_review';
        }
        return toPage;
    };

    const renderIbpoReviewButton = () => {
        const buttonComp = (
            <>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <Link to={`/inbound/${id}/product_details`}>
                            <button className="app-btn app-btn-secondary footer-btn" title="previous">
                                <span className="button-text vendor-button-text">Previous</span>
                            </button>
                        </Link>
                        <ShowForPermissions permission="edit" type="inbound">
                            {headerDetails?.poStatus &&
                                headerDetails?.poStatus?.toLowerCase() !== 'awaiting_enrichment' &&
                                headerDetails?.poStatus?.toLowerCase() !== 'cancelled' &&
                                headerDetails?.poStatus !== 'In_Booking_Proposal' &&
                                headerDetails?.poStatus !== 'Booked' &&
                                headerDetails?.poStatus !== 'Submitted' &&
                                !headerDetails?.poStatus.toLowerCase().includes('inbound_in_progress') &&
                                !headerDetails?.poStatus.toLowerCase().includes('ready') && (
                                    <a
                                        onClick={() => {
                                            if (isTabActive(page)) {
                                                dispatch(savedPo(headerDetails.poNumber));
                                                dispatch(submitPo(id));
                                                navigate('/inbound');
                                            }
                                        }}
                                    >
                                        <button
                                            disabled={!isTabActive(page)}
                                            className="app-btn app-btn-primary footer-btn"
                                            title="confirm and submit"
                                        >
                                            <span className="button-text footer-button-text">
                                                Confirm and Submit PO
                                            </span>
                                        </button>
                                    </a>
                                )}
                        </ShowForPermissions>
                    </div>
                </div>
            </>
        );
        return buttonComp;
    };

    return (
        <>
            {page === 'po_review' ? (
                renderIbpoReviewButton()
            ) : (
                <Link
                    to={isTabActive(page) ? `/inbound/${id}/${navigation(page)}` : '#'}
                    className={`app-btn app-btn-primary footer-btn ${isTabActive(page) ? '' : 'disabled'}`}
                    title="Next"
                >
                    <span className="button-text footer-button-text">Next</span>
                </Link>
            )}
        </>
    );
};

export default BLPInboundPONextButton;
