import axios from 'axios';
import { deleteCookie, setCookie } from 'src/utils';
import { get, del, put, post } from '../HttpMethods';
const baseURL = process.env.REACT_APP_BASE_URL;

export const checkUser = async (payload: unknown) => {
    const queryParams = payload === 'fromForgotPassword' ? '?reset_pwd=true' : '';

    try {
        const data = await get(`api/auth/login${queryParams}`, null);
        const userDefaultRole = data.roles.find((role: any) => role.isDefault === true);
        if (data && userDefaultRole && data?.user?.us_UserName) {
            try {
                const listOfUserOrganisations = await get(
                    `api/auth/userprofileorganisations?userName=${data.user.us_UserName}&profile=${userDefaultRole.profile}`,
                    null
                );
                localStorage.setItem('listOfUserOrganisations', JSON.stringify(listOfUserOrganisations.info));
            } catch (err) {
                console.log(err);
            }
        }
        localStorage.setItem('user', JSON.stringify(data));
        localStorage.setItem('wholePerms', JSON.stringify(data));
        localStorage.setItem('loggedIn', 'true');
        const customEvent = new CustomEvent('build', { detail: data?.user?.us_UserName });
        document.dispatchEvent(customEvent);
        setCookie('loggedIn', 'true');
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const getOrganisationData = async (payload: { userName: string; profile: string }) => {
    try {
        const listOfUserOrganisations = await get(
            `api/auth/userprofileorganisations?userName=${payload.userName}&profile=${payload.profile}`,
            null
        );
        if (listOfUserOrganisations?.info && !window.location.pathname.includes('logout')) {
            localStorage.setItem('listOfUserOrganisations', JSON.stringify(listOfUserOrganisations.info));
        }
    } catch (error) {
        console.log('error', error);
    }
};

export const logoutUserApi = async (payload: any) => {
    try {
        const data = await del('api/auth/logout', null);
        return data;
    } catch (error) {
        console.log(error, 'error');
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    } finally {
        let postLogoutRedirectUri = '/';
        const userData = localStorage.getItem('user');
        if (userData) {
            const { userType } = JSON.parse(userData);
            postLogoutRedirectUri =
                userType === 'AD' ? 'https://login.microsoftonline.com/common/oauth2/v2.0/logout' : '/';
        }
        localStorage.removeItem('showToast');
        localStorage.removeItem('tokenAuthTime');
        localStorage.removeItem('loggedIn');
        localStorage.removeItem('token');
        localStorage.removeItem('tempToken');
        localStorage.removeItem('firstLogin');
        localStorage.removeItem('user');
        localStorage.removeItem('wholePerms');
        localStorage.removeItem('showPasswordExpiry');
        localStorage.removeItem('listOfUserOrganisations');
        localStorage.removeItem('roleIndex');
        localStorage.removeItem('lastApiRequest');
        localStorage.removeItem('orderServiceFailed');
        localStorage.removeItem('mgtServiceFailed');
        localStorage.removeItem('bkgServiceFailed');
        localStorage.removeItem('vendorView');
        localStorage.removeItem('clientView');

        deleteCookie('loggedIn');
        payload.logoutRedirect({
            postLogoutRedirectUri,
        });
    }
};

export const updateAccessToken = async (oldToken: string | null, newToken: string) => {
    try {
        const headers = {
            // 'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${oldToken}`,
        };
        const data = await axios.post(baseURL + '/api/auth/refreshtoken', { oldToken, newToken }, { headers });
        return data;
    } catch (error) {
        console.log(error, 'error');
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const getUserNotificationApi = async (_payload: unknown) => {
    try {
        const userData = localStorage.getItem('user');
        let listOfUserOrganisations = localStorage.getItem('listOfUserOrganisations');
        let roles = {};
        const roleIndex = localStorage.getItem('roleIndex');
        let userName = '';
        if (userData) {
            const { user } = JSON.parse(userData);
            userName = user?.us_UserName;

            const { roles: rolesData } = JSON.parse(userData);
            if (roleIndex) {
                roles = rolesData[roleIndex];
            }
        }
        if (listOfUserOrganisations) {
            listOfUserOrganisations = JSON.parse(listOfUserOrganisations);
        }
        const data = await post(`/api/ord/notification/${userName}`, { listOfUserOrganisations, roles }, null);
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const markSingleAsRead = async (payload: any) => {
    try {
        const userName = JSON.parse(localStorage.getItem('user') || '')?.user?.us_UserName;
        const userData = localStorage.getItem('user');
        let listOfUserOrganisations = localStorage.getItem('listOfUserOrganisations');
        const roleIndex = localStorage.getItem('roleIndex');
        let roles = {};
        if (userData) {
            const { roles: rolesData } = JSON.parse(userData);
            if (roleIndex) {
                roles = rolesData[roleIndex];
            }
        }
        if (listOfUserOrganisations) {
            listOfUserOrganisations = JSON.parse(listOfUserOrganisations);
        }
        const data = await put(`/api/ord/notification/status/${userName}/${payload.id}/${payload.status}`, {
            listOfUserOrganisations,
            roles,
        });
        return data;
    } catch (error) {
        console.log(error, 'error');
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const markAllAsRead = async (_payload: any) => {
    const userData = localStorage.getItem('user');
    let userName = '';
    const roleIndex = localStorage.getItem('roleIndex');
    let roles = {};
    if (userData) {
        const { user } = JSON.parse(userData);
        userName = user?.us_UserName;
        const { roles: rolesData } = JSON.parse(userData);
        if (roleIndex) {
            roles = rolesData[roleIndex];
        }
    }
    let listOfUserOrganisations = localStorage.getItem('listOfUserOrganisations');
    if (listOfUserOrganisations) {
        listOfUserOrganisations = JSON.parse(listOfUserOrganisations);
    }
    try {
        const data = await put(`/api/ord/notification/readAll/${userName}`, { listOfUserOrganisations, roles });
        return data;
    } catch (error) {
        console.log(error, 'error');
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            return error;
        }
    }
};

export const updateProfileAPI = async (payload: any) => {
    try {
        const res = await post('api/mgt/user/switch-profile', payload ?? null, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
