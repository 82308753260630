import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchAvailablePoData,
    isLoadingFn,
    addOrSavePoData,
    getBpData,
    savePoDataAll,
    searchAvailablePoDataSuccess,
} from 'src/store/reducers/bookingProposalLand/availablePoReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    getAvailablePos,
    AvailablePOResponse,
    addSelectedPos,
    getBpDetails,
    getPoData,
    searchAvailablePos,
} from 'src/services/apis/bookingProposalLand/availablePOsApi';
import { getSingleLine } from 'src/services/apis/bookingProposalLand/addedPosApi';
import { saveSingleData } from 'src/store/reducers/bookingProposalLand/addedPoReducer';

function* getAvailablePOListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<AvailablePOResponse> = yield call(getAvailablePos, payload);
        payload['response'] = response;
        yield put({ type: fetchAvailablePoData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addSelectedAvailablePos({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any[]> = yield call(addSelectedPos, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: addOrSavePoData, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getBookingProposalData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any[]> = yield call(getBpDetails, payload);
        payload['success'] = true;
        payload['data'] = data;
        yield put({ type: getBpData, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getAllPoData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any[]> = yield call(getPoData, payload);
        yield put({ type: savePoDataAll, payload: data });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getSingleLineSaga({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const res: AxiosResponse<any> = yield call(getSingleLine, payload);
        yield put({ type: saveSingleData, payload: res });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* searchAvailablePOListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<AvailablePOResponse> = yield call(searchAvailablePos, payload);
        payload['response'] = response;
        yield put({ type: searchAvailablePoDataSuccess, payload: payload });
    } catch (err) {
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* availablePoListSaga() {
    yield takeEvery('availableLandPos/fetchAvailablePoData', getAvailablePOListData);
    yield takeEvery('availableLandPos/searchAvailablePoData', searchAvailablePOListData);
    yield takeEvery('availableLandPos/addOrSavePoData', addSelectedAvailablePos);
    yield takeEvery('availableLandPos/getBpData', getBookingProposalData);
    yield takeEvery('availableLandPos/poDataFetch', getAllPoData);
    yield takeEvery('availableLandPos/getSingleData', getSingleLineSaga);
}

export default availablePoListSaga;
