import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    addPoLinestoBp,
    getAvailablePoLines,
    listPoLineResponse,
    poLineFilterData,
} from '../../../services/apis/aobookingProposal/availablePoLineApi';
import {
    addPoLineData,
    fetchAvailablePoLineData,
    fetchDistinctFilterValues,
    isLoadingFn,
} from '../../reducers/aobookingProposal/availablePoLineReducer';

function* getAvailablePoLineListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const availablePoLineList: AxiosResponse<listPoLineResponse> = yield call(getAvailablePoLines, payload);
        payload['availablePoLineList'] = availablePoLineList;
        yield put({ type: fetchAvailablePoLineData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* addPoLineDataToBooking({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const data: AxiosResponse<any> = yield call(addPoLinestoBp, payload);
        payload['data'] = data ? data : 'error';
        yield put({ type: addPoLineData, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getFilterValues({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const distinctFilterValues: AxiosResponse<listPoLineResponse> = yield call(poLineFilterData, payload);
        payload['distinctFilterValues'] = distinctFilterValues;
        yield put({ type: fetchDistinctFilterValues, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* availablePoLineListSaga() {
    yield takeEvery('availablePoLinesao/fetchAvailablePoLineData', getAvailablePoLineListData);
    yield takeEvery('availablePoLinesao/addPoLineData', addPoLineDataToBooking);
    yield takeEvery('availablePoLinesao/fetchDistinctFilterValues', getFilterValues);
}

export default availablePoLineListSaga;
