import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './multiLineGraph.scss';
import moment from 'moment';
const MultiLineGraph = (props: any) => {
    const { drawn, unit } = props;
    let yMax = 0;
    let yMin = null;
    for (let i = 0; i < drawn.length; i++) {
        for (let j = 0; j < drawn[i]['points'].length; j++) {
            const y = drawn[i]['points'][j] * -1;
            if (-y > yMax) {
                yMax = -y;
            }
            if ((yMin && -y < yMin) || yMin === null) {
                yMin = -y;
            }
        }
    }

    // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    // To setup Y cordinates of Line Graph
    const yFractionAbsArray = [
        1, 2, 5, 10, 20, 25, 50, 100, 200, 250, 500, 1000, 1500, 2000, 2500, 3000, 4000, 5000, 10000, 20000, 50000,
        100000,
    ];
    const yAxisMin = yMin ? yMin : 0;
    const yAxisMax = yMax;
    const yDiff = yAxisMax - yAxisMin;
    const yCordsCount = Math.min(6, yDiff);
    const yFraction = yDiff / yCordsCount;
    let yFractionAbs = 1;
    for (let i = 0; yFraction >= yFractionAbsArray[i]; i++) {
        yFractionAbs = yFractionAbsArray[i + 1];
    }
    const yStartAbs = yFractionAbs === 0 ? 0 : yAxisMin - (yAxisMin % yFractionAbs);
    const yAxis = [];
    const yEndAbs = yFractionAbs === 0 ? 1 : yAxisMax - (yAxisMax % yFractionAbs) + yFractionAbs;
    for (let j = yStartAbs, i = 0; j <= yEndAbs; j += yFractionAbs, i++) {
        yAxis[i] = j;
    }
    //===========================================================================//
    const TooltipGenerator = (props: any) => {
        const [tooltipOpen, setTooltipOpen] = useState(false);
        const toggle = () => setTooltipOpen(!tooltipOpen);
        let currentUnit = unit;
        if (props.data !== 1 && unit === 'Client') {
            currentUnit = unit + 's';
        }
        return (
            <Tooltip placement="top" isOpen={tooltipOpen} target={props.name} toggle={toggle}>
                {' '}
                {typeof props.extraValue === 'number' && (
                    <div>{props.extraValue === 1 ? `${props.extraValue} Client` : `${props.extraValue} Clients`} </div>
                )}
                <div>{drawn[props.index].name}</div> <div>{`${props.data}  ${currentUnit}`}</div>{' '}
                <div className="tooltip-small">{props.date}</div>{' '}
            </Tooltip>
        );
    };
    return (
        <div className={props.graphAxis ? 'multi-line-graph-wrapper axisEnabled' : 'multi-line-graph-wrapper'}>
            {' '}
            <svg className="multi-line-graph">
                {' '}
                <defs>
                    {' '}
                    {props.lineDots && (
                        <symbol id={`point_${props.name}`} className="multi-point-symbol set-symbol">
                            {' '}
                            <circle
                                cx="0"
                                cy="0"
                                r={props.pointSize * 3}
                                className="multi-graph-point-area set-point-area"
                            ></circle>{' '}
                            <circle
                                cx="0"
                                cy="0"
                                r={props.pointSize}
                                className="multi-graph-point-center set-point-center"
                            ></circle>{' '}
                        </symbol>
                    )}
                </defs>{' '}
                {yAxis.map((item: any, index: number) => (
                    <React.Fragment key={`yAxis${props.name}${index}`}>
                        {' '}
                        {props.graphAxis && (
                            <text
                                className="y-axis-value"
                                key={index}
                                x={0}
                                y={(100 * (yEndAbs - item)) / (yEndAbs - yStartAbs) + '%'}
                            >
                                {' '}
                                {item}
                            </text>
                        )}
                    </React.Fragment>
                ))}
                {drawn.map((mainItem: any, indexMain: number) => (
                    <g className={`multi-line-group trend-${indexMain + 1}`} key={indexMain}>
                        {' '}
                        {mainItem.points.map(
                            (item: any, index: number) =>
                                index < mainItem.points.length - 1 && (
                                    <>
                                        {' '}
                                        <React.Fragment key={`line${props.name}${index}`}>
                                            {' '}
                                            <line
                                                className="multi-graph-line colored"
                                                x1={(index / (mainItem.points.length - 1)) * 100 + '%'}
                                                y1={
                                                    ((drawn[indexMain]['points'][index] - yStartAbs) /
                                                        (yEndAbs - yStartAbs)) *
                                                        100 *
                                                        -1 +
                                                    100 +
                                                    '%'
                                                }
                                                x2={((index + 1) / (mainItem.points.length - 1)) * 100 + '%'}
                                                y2={
                                                    ((drawn[indexMain]['points'][index + 1] - yStartAbs) /
                                                        (yEndAbs - yStartAbs)) *
                                                        100 *
                                                        -1 +
                                                    100 +
                                                    '%'
                                                }
                                            />{' '}
                                            ;
                                        </React.Fragment>{' '}
                                    </>
                                )
                        )}
                    </g>
                ))}
                {drawn.map((mainItem: any, indexMain: number) => (
                    <>
                        {' '}
                        {mainItem.points.map((item: any, index: number) => {
                            // currentDate.setMonth(currentDate.getMonth() - index);
                            const value = index === 0 ? 12 : 12 - index;
                            const currentMonth = moment().subtract(value, 'months').format('MMM');
                            const currentYear = moment().subtract(value, 'months').format('YYYY');
                            // const currentDate = new Date(
                            //     new Date().getFullYear(),
                            //     new Date().getMonth() - value,
                            //     new Date().getDate()
                            // );
                            return (
                                <React.Fragment key={`tooltipgen${props.name}${index}`}>
                                    {' '}
                                    {props.lineDots && (
                                        <>
                                            {' '}
                                            <use
                                                href={`#point_${props.name}`}
                                                className={`multi-graph-point trend-${indexMain + 1} point-${
                                                    index + 1
                                                }`}
                                                key={index}
                                                x={(index / (mainItem.points.length - 1)) * 100 + '%'}
                                                y={((item - yStartAbs) / (yEndAbs - yStartAbs)) * 100 * -1 + 100 + '%'}
                                                transform-origin={`
                                ${(index / (mainItem.points.length - 1)) * 100 + '%'} 
                                ${((item - yStartAbs) / (yEndAbs - yStartAbs)) * 100 * -1 + 100 + '%'}`}
                                                id={`tooltip__${indexMain}${index}${props.name}`}
                                            ></use>{' '}
                                            {props.graphAxis && indexMain === drawn.length - 1 && (
                                                <text
                                                    textAnchor="middle"
                                                    className={`x-axis-value text-${index + 1}`}
                                                    key={index}
                                                    x={(index / (mainItem.points.length - 1)) * 100 + '%'}
                                                    y="100%"
                                                >
                                                    {' '}
                                                    {/* <tspan>{months[mainItem?.value[0][index]]}</tspan>{' '} */}
                                                    <tspan>
                                                        {currentMonth}
                                                        {/* {months[currentDate.getMonth()]} */}
                                                    </tspan>{' '}
                                                    <tspan
                                                        dy="1.2em"
                                                        x={(index / (mainItem.points.length - 1)) * 100 + '%'}
                                                    >
                                                        {' '}
                                                        {currentYear}
                                                        {/* {currentDate.getFullYear()} */}
                                                    </tspan>{' '}
                                                </text>
                                            )}
                                        </>
                                    )}
                                    {props.tooltip && (
                                        <TooltipGenerator
                                            data={item}
                                            name={`tooltip__${indexMain}${index}${props.name}`}
                                            index={indexMain}
                                            date={currentMonth + ' ' + currentYear}
                                            extraValue={drawn[indexMain]?.extraValue?.[index]}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </>
                ))}
            </svg>{' '}
        </div>
    );
};
export default MultiLineGraph;
