// New_Orders_Created,Recent_Departures,Recent_Arrival,Delayed_Departure,Delayed_Arrival,Departing_Soon,Arriving_Soon
export const quickLinks: any[] = [
    { name: 'New Orders Created', id: 'New_Orders_Created' },
    { name: 'Recent Departure', id: 'Recent_Departures' },
    { name: 'Departing Soon', id: 'Departing_Soon' },
    { name: 'Delayed Departure', id: 'Delayed_Departure' },
    // { name: 'Delayed Departure (Leg)', id: 'New_Orders_Created' },
    { name: 'Recent Arrival', id: 'Recent_Arrival' },
    { name: 'Arriving Soon', id: 'Arriving_Soon' },
    { name: 'Delayed Arrival', id: 'Delayed_Arrival' },
    { name: 'Vessel Change', id: 'Vessel_Change' },
];

export const typeMapping: any = {
    'New Orders Created': {
        class: 'note-icon completed-color',
        icon: '#noteIcon',
    },
    'Recent Departures': {
        class: 'progress-start-icon',
        icon: '#progressStartIcon',
    },
    'Departing Soon': {
        class: 'progress-start-icon',
        icon: '#progressStartIcon',
    },
    'Arriving Soon': {
        class: 'progress-end-icon',
        icon: '#progressEndIcon',
    },
    'Recent Arrival': {
        class: 'progress-end-icon',
        icon: '#progressEndIcon',
    },
    'Delayed Departure': {
        class: 'clock-icon',
        icon: '#clockIcon',
    },
    'Delayed Arrival': {
        class: 'clock-icon',
        icon: '#clockIcon',
    },
};
