/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { FilterList } from 'src/pages/Dashboard/WorkTrends';
import { RootState } from '../index';

export interface ActivitiesArray {
    profileName: string;
    profile: string;
    userName: string;
    uuid: string;
    dateTime: string;
}
export interface ActivityDetails {
    name: string;
    key: string;
    value: number;
    service: string;
}
export interface Activities {
    activities: ActivitiesArray[];
    totalNoOfItems: number;
    totalNoOfPages: number;
}
export interface Users {
    fullName: string;
    userId: string;
}
export interface WorkTrendsDetails {
    xAxis: string;
    yAxis: number;
    label: string;
    activities: ActivityDetails[];
    expandedLabel: string | null;
}

interface WorkTrendsState {
    isLoading: boolean;
    workTrends: WorkTrendsDetails[];
    users: Users[];
    activities: Activities;
    currentFilter: string;
    workTrendsSuccess: any;
    workTrendsError: any;
    isError?: boolean;
    progressSelectedIndex: number;
    isSubActivitiesVisible: boolean;
    selectedActivityDetails: ActivityDetails;
    perPageCount: number;
    page: number;
    userIds: Array<string>;
}

const initialState: WorkTrendsState = {
    isLoading: true,
    workTrends: [],
    users: [],
    activities: { activities: [], totalNoOfItems: 0, totalNoOfPages: 0 },
    currentFilter: FilterList.Days,
    workTrendsSuccess: undefined,
    workTrendsError: undefined,
    isError: false,
    progressSelectedIndex: 0,
    isSubActivitiesVisible: false,
    selectedActivityDetails: {
        name: '',
        key: '',
        value: -1,
        service: '',
    },
    perPageCount: 10,
    page: 0,
    userIds: [],
};

export const workTrendsSlice = createSlice({
    name: 'workTrends',
    initialState,
    reducers: {
        fetchWorkTrendsList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchWorkTrendsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.workTrends = action.payload?.data;
        },
        fetchWorkTrendsListFailure(state, _action) {
            state.isLoading = false;
            // state.workTrends = action.payload;
        },
        fetchUsersList: (state, _action) => {
            // state.isLoading = true;
            state.isError = false;
        },
        fetchUsersListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.users = action.payload?.data;
        },
        fetchUsersListFailure: (state) => {
            state.isLoading = false;
        },
        setActiveActivityList: (state, action) => {
            state.progressSelectedIndex = action.payload.index;
            state.isSubActivitiesVisible = false;
            state.selectedActivityDetails = {
                name: '',
                key: '',
                value: -1,
                service: '',
            };
            state.isError = false;
            state.page = 0;
        },
        fetchActivityList: (state, action) => {
            state.isLoading = true;
            state.selectedActivityDetails = action.payload?.activityData;
            state.page = action.payload?.currentPage;
            state.isError = false;
        },
        fetchActivityListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.activities = action.payload?.data;
            state.isSubActivitiesVisible = true;
        },
        fetchActivityListFailure: (state, action) => {
            state.isLoading = false;
            state.workTrendsError = action.payload;
        },
        resetSubActivityVisiblity: (state, _action) => {
            state.isLoading = false;
            state.isError = false;
            state.isSubActivitiesVisible = false;
            state.selectedActivityDetails = {
                name: '',
                key: '',
                value: -1,
                service: '',
            };
        },
        updateFilter: (state, action) => {
            state.currentFilter = action.payload;
        },
        resetDataWhileChangingTimePeriodFilter: (state) => {
            state.progressSelectedIndex = 0;
            state.isSubActivitiesVisible = false;
            state.selectedActivityDetails = {
                name: '',
                key: '',
                value: -1,
                service: '',
            };
        },
        setPerPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.page = action.payload;
        },
        setUserIds: (state, action) => {
            state.userIds = action.payload;
        },
        resetWorkTrendsError: (state, action) => {
            state.workTrendsError = null;
        },
    },
});

export const {
    fetchWorkTrendsList,
    fetchWorkTrendsListSuccess,
    fetchWorkTrendsListFailure,
    fetchUsersList,
    fetchUsersListSuccess,
    fetchUsersListFailure,
    setActiveActivityList,
    fetchActivityList,
    resetSubActivityVisiblity,
    fetchActivityListSuccess,
    fetchActivityListFailure,
    updateFilter,
    setPerPageCount,
    setCurrentPage,
    resetDataWhileChangingTimePeriodFilter,
    setUserIds,
    resetWorkTrendsError,
} = workTrendsSlice.actions;

export const selectWorkTrendsLoading = (state: RootState) => state.workTrendsData.isLoading;
export const selectWorkTrends = (state: RootState) => state.workTrendsData.workTrends;
export const selectUsers = (state: RootState) => state.workTrendsData.users;
export const selectActivities = (state: RootState) => state.workTrendsData.activities;
export const selectWorkTrendsSuccess = (state: RootState) => state.workTrendsData.workTrendsSuccess;
export const selectWorkTrendsError = (state: RootState) => state.workTrendsData.workTrendsError;
export const selectWorkTrendsIsError = (state: RootState) => state.workTrendsData.isError;
export const selectCurrentFilter = (state: RootState) => state.workTrendsData.currentFilter;
export const isLoading = (state: RootState) => state.workTrendsData.isLoading;

export default workTrendsSlice.reducer;
