/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-undefined */
import React, { useEffect, useState, useRef } from 'react';
import ProductDetailsModal from './ProductDetailsModal';
import LinkVendorModal from './LinkVendorModal';
import CbmUnavailableModal from './CbmUnavailableModal';
import SkuUnavailableModal from './SkuUnavailableModal';
import './product-details.scss';
import { Link, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import DatePicker from '../../../components/common/DatePicker';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import Select from 'react-select';
import {
    fetchProductPoList,
    fetchCountry,
    fetchCurrency,
    // fetchPort,
    fetchDestinationAddress,
    selectCountry,
    selectCurrency,
    selectDestinationAddress,
    // selectPort,
    selectProductPo,
    selectProductPoLoading,
    selectSku,
    fetchSkuList,
    ProductPoDataDetails,
    selectProductPoIsError,
    selectProductPoSuccess,
    selectProductPoError,
    clearFailure,
    SkuDataDetails,
    createProductPo,
    updateProductPo,
    linkVendorProductPo,
} from 'src/store/reducers/purchaseOrder/productPoReducer';
import { fetchHeader, selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import moment from 'moment';
import { error, Position, success } from 'src/utils/toast';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import Breadcrumbs from 'src/components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from 'src/ShowForPermissions';
import { MenuPlacement } from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { clearUnlocoList, searchUnlocoList } from 'src/store/reducers/createVendorReducer';
import { validateFractionInput } from 'src/utils/validateFractionInput';
import SkipPrompt from './SkipPrompt';
import CancelModal from '../BasicDetail/cancelModal';
import { usePrompt } from 'src/hooks/usePrompt';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
import {
    addPoLineRevert,
    skipLinePoDestinations,
    skipLinePORequest,
} from 'src/services/apis/purchaseOrder/addPoReducerApi';
import {
    fetchProductDetailsConfirmationList,
    // selectProductDetailsConfirmation,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import { fetchBasicDetails } from 'src/store/reducers/purchase_order/basicDetails';
import { fetchDatesPoList } from 'src/store/reducers/purchaseOrder/datesPoReducer';
import { InterfaceType } from 'src/utils/constants';
import { decimalNumberValidation } from 'src/utils';
import UnsavedChangeModal from 'src/pages/AOC/ProductAllocation/UnsavedChangeModal';
import RemovePoLine from 'src/components/common/ConfirmRemovePoLine';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import PODetailsNextButton from 'src/pages/PO/PODetailsNextButton';

interface ProductsPo {
    sku: string;
    skuId: string;
    description: string;
    countryCode: string;
    countryName: string;
    destinationPort: string | null;
    destinationAddress: string | null;
    destinationAddressIdInProduct: string | null;
    goodsReadyDate: string | null;
    projWhouseETA: string | null;
    cbm: any;
    weight: number;
    qty: any;
    unitCost: any;
    netCost: any;
    currency: string;
}
type MultiDestination = Pick<
    ProductsPo,
    'destinationPort' | 'destinationAddress' | 'destinationAddressIdInProduct' | 'goodsReadyDate' | 'projWhouseETA'
>;
const Index: React.FC = () => {
    const { id } = useParams();
    const scrollElement = useRef<any>(null);
    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [modalViewVendor, setModalViewVendor] = useState(false);
    const [modalViewCbm, setModalViewCbm] = useState(false);
    const [modalViewSku, setModalViewSku] = useState(false);
    const [showAddress, setShowAddress] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>('auto');
    const [unsavedChangeModalVisibility, setUnsavedChangeModalVisibility] = useState(false);
    const [callBackFn, setCallBackFn] = useState<any>();
    const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
    const [poProductId, setPoProductId] = useState('');
    // const [isEachPresent, setIsEachPresent] = useState(true);
    // const [isVendorLinked, setIsVendorLinked] = useState(true);
    const [cbmForEach, setCbmForEach] = useState(0);
    const [weightForEach, setWeightForEach] = useState(0);
    // const [destinationDP, setDestinationDP] = useState(false);
    // const [triangleMode, setTriangleMode] = useState(true);
    const [triangleModeModal, setTriangleModeModal] = useState(false);
    const [_flagNew, setflagNew] = useState(true);
    // const [skipPOLine, setSkipPOLine] = useState(false);
    const [distanceFromTopForAddress, setDistanceFromTopForAddress] = useState(0);
    const [distanceFromLeftForAddress, setDistanceFromLeftForAddress] = useState(0);
    const [skuDetail, setSkuDetail] = useState<any>({});
    const [singleProduct, setSingleProduct] = useState<any>({});
    const [skuData, setSkuData] = useState<any>([]);
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [editIndex, setEditIndex] = useState(0);
    const isLoading = useAppSelector(selectProductPoLoading);
    const products = useAppSelector(selectProductPo);
    const sku = useAppSelector(selectSku);
    const country = useAppSelector(selectCountry);
    const currency = useAppSelector(selectCurrency);
    // const port = useAppSelector(selectPort);
    const destinationAddress = useAppSelector(selectDestinationAddress);
    const headerData = useAppSelector(selectHeader);
    const isError = useAppSelector(selectProductPoIsError);
    const productPoSuccess = useAppSelector(selectProductPoSuccess);
    const productPoError = useAppSelector(selectProductPoError);
    // const productDetailsConfirmation = useAppSelector(selectProductDetailsConfirmation);
    const [ports, setPorts] = useState<any>([]);
    const { unLocoList, isLoading: unlocoLoadingFn } = useSelector((state: RootState) => state.vendorDetails);
    const { basicDetails } = useSelector((state: RootState) => state.purchaseOrder);
    const [searchTerm, setSearchTerm] = useState('');
    const [viaIntegration, setViaIntegration] = useState(false);
    const [unlocoLoading, setunlocoLoading] = useState(false);
    const scrollLeft = () => {
        scrollElement.current.scrollLeft -= scrollElement.current.scrollWidth;
    };
    const scrollDown = () => {
        const targetElm = document.querySelector('.grid-scroll-content');
        if (targetElm) {
            setTimeout(() => {
                targetElm.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
            }, 100);
        }
    };
    useEffect(() => {
        dispatch(fetchProductPoList({ poId: id }));
        dispatch(fetchCountry([]));
        // dispatch(
        //     fetchProductDetailsConfirmationList({
        //         poId: id,
        //     })
        // );
        // dispatch(fetchBasicDetails(id));
        // dispatch(
        //     fetchDatesPoList({
        //         id,
        //     })
        // );
        dispatch(clearUnlocoList());
    }, []);
    /* useEffect(() => {
        if (headerData?.shippingMode?.shippingModeCode) {
            dispatch(
                fetchPort({
                    portType: headerData?.shippingMode?.shippingModeCode === 'AIR' ? 'Air' : undefined,
                    service: 'ord',
                })
            );
        }
    }, [headerData?.shippingMode?.shippingModeCode]); */
    useEffect(() => {
        if (unlocoLoadingFn && searchTerm?.length > 2) {
            setunlocoLoading(true);
        } else {
            setunlocoLoading(false);
        }
    }, [unlocoLoadingFn, searchTerm]);

    useEffect(() => {
        if (sku?.length) {
            setSkuData(sku.map((res: any) => ({ value: res.pm_PK, label: res.pm_sku })));
        }
    }, [sku]);
    useEffect(() => {
        setPorts([]);
        if (searchTerm?.trim().length > 2) {
            dispatch(
                searchUnlocoList({
                    searchTerm,
                    portType: headerData?.shippingMode?.shippingModeCode === 'AIR' ? 'Air' : 'Sea',
                    service: 'ord',
                })
            );
        } else {
            dispatch(clearUnlocoList());
        }
    }, [searchTerm]);
    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    country: option.portCountry,
                };
            });

            setPorts(portList);
        }
    }, [unLocoList, searchTerm]);
    useEffect(() => {
        if (headerData?.clientCode) {
            if (!sku?.length) {
                dispatch(fetchSkuList({ clientCode: headerData?.clientCode }));
            }
            if (!destinationAddress?.length) {
                dispatch(fetchDestinationAddress({ orgCode: headerData?.clientCode }));
            }
        }
        setViaIntegration(headerData?.createdUser === InterfaceType.InterfacedPO);
    }, [headerData?.clientCode]);
    useEffect(() => {
        if (headerData?.currency !== undefined) {
            dispatch(fetchCurrency({ currency: headerData?.currency }));
            localStorage.setItem('clientName', headerData?.clientName);
        }
    }, [headerData?.currency]);

    const checkForSameSku = () => {
        if (formik.values.sku && !headerData?.isMultiAddress) {
            if (
                products?.some(
                    (res: any) => res.sku === formik.values.sku && singleProduct.poLineNumber !== res.poLineNumber
                )
            ) {
                formik.setFieldError('skuId', 'Selected SKU  is already added');
                formik.setFieldError('destinationPort', 'Selected SKU   is already added');
                return true;
            } else {
                formik.setFieldError('skuId', undefined);
                formik.setFieldError('destinationPort', undefined);
            }
        } else if (formik.values.destinationPort && formik.values.sku && headerData?.isMultiAddress) {
            const destination = formik.values.destinationPort.toString().split('(');

            if (destination?.length === 2) {
                const destinationPort = destination[1].toString().split(')')[0];
                const destinationPortName = destination[0];

                if (
                    products?.some(
                        (res: any) => res.sku === formik.values.sku && singleProduct.poLineNumber !== res.poLineNumber
                    ) &&
                    products?.some(
                        (res: any) =>
                            res.destinationPort === destinationPort && singleProduct.poLineNumber !== res.poLineNumber
                    ) &&
                    products?.some(
                        (res: any) =>
                            res.destinationPortName === destinationPortName &&
                            singleProduct.poLineNumber !== res.poLineNumber
                    )
                ) {
                    formik.setFieldError('skuId', 'Selected SKU and Destination is already added');
                    formik.setFieldError('destinationPort', 'Selected SKU and Destination  is already added');
                    return true;
                } else {
                    formik.setFieldError('skuId', undefined);
                    formik.setFieldError('destinationPort', undefined);
                }
            }
        }
        return false;
    };

    let multiDestinationValidation = {};
    let multiDestinationData: MultiDestination;
    if (headerData?.isMultiAddress) {
        multiDestinationValidation = {
            destinationPort: Yup.string().required('Destination is required'),
            destinationAddress: Yup.string().required('Destination address is required'),
            goodsReadyDate: Yup.date().required('Goods ready date is required'),
            projWhouseETA: Yup.date().required('Projected ETA into warehouse is required'),
        };
        multiDestinationData = {
            destinationPort:
                singleProduct.destinationPortName && singleProduct.destinationPort
                    ? `${singleProduct.destinationPortName}(${singleProduct.destinationPort})`
                    : '',
            destinationAddressIdInProduct: singleProduct.destinationAddressIdInProduct || '',
            destinationAddress: singleProduct.destinationAddress || '',
            goodsReadyDate: singleProduct.goodsReadyDate
                ? moment(singleProduct.goodsReadyDate).format('YYYY-MM-DD')
                : '',
            projWhouseETA: singleProduct.projWhouseETA ? moment(singleProduct.projWhouseETA).format('YYYY-MM-DD') : '',
        };
    } else {
        multiDestinationData = {
            destinationPort: null,
            destinationAddressIdInProduct: null,
            destinationAddress: null,
            goodsReadyDate: null,
            projWhouseETA: null,
        };
    }
    const ProductsPoSchema = Yup.object().shape({
        skuId: Yup.string().required('SKU is required'),
        countryCode: Yup.string().required('Country of origin is required'),
        qty: Yup.string()
            .required('Quantity is required')
            .test('no-leading-zero', 'Quantity must be greater than zero', (context: any) => {
                if (headerData?.poStatus === 'Draft' || add) {
                    return context && parseFloat(context) !== 0;
                } else {
                    return true;
                }
            }),
        unitCost: Yup.number()
            .typeError('Unit cost is required')
            .required('Unit cost is required')
            .test('no-leading-zero', 'Unit cost must be greater than zero', (context: any) => {
                if (headerData?.poStatus === 'Draft' || add) {
                    return context && parseFloat(context) !== 0;
                } else {
                    return true;
                }
            }),
        currency: Yup.string().required('Currency is required'),
        ...multiDestinationValidation,
        ...(skuDetail?.pm_type === 'Commodity' && {
            cbm: Yup.number().required('CBM is required'),
        }),
    });
    const initialValues: ProductsPo = {
        sku: singleProduct.sku || '',
        skuId: singleProduct.skuId || '',
        description: singleProduct.description || '',
        countryCode: singleProduct.countryCode || '',
        countryName: singleProduct.countryName || '',
        cbm: singleProduct.cbm >= 0 ? singleProduct.cbm : '',
        weight: singleProduct.weight || 0,
        qty: singleProduct.qty >= 0 ? singleProduct.qty : '',
        unitCost: typeof singleProduct.unitCost === 'number' ? singleProduct.unitCost : '',
        netCost: singleProduct.netCost >= 0 ? singleProduct.netCost : '',
        currency: singleProduct.currency?.currencycode || '',
        ...multiDestinationData,
    };
    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ProductsPoSchema,
        onSubmit: (values: any, submitProps: any) => {
            if (formik.isSubmitting) {
                if (checkForSameSku()) {
                    return;
                }
                if (headerData?.isMultiAddress) {
                    const destination = values.destinationPort.toString();
                    const lastOpeningParenthesisIndex = destination.lastIndexOf('(');

                    if (lastOpeningParenthesisIndex !== -1) {
                        values.destinationPortName = destination.substring(0, lastOpeningParenthesisIndex).trim();
                        values.destinationPort = destination
                            .substring(lastOpeningParenthesisIndex + 1, destination.length - 1)
                            .trim();
                    }
                }
                const hsCode = sku ? sku.find((e: SkuDataDetails) => e.pm_PK === values.skuId)?.pm_purchasecode : '';
                if (add) {
                    dispatch(
                        createProductPo({
                            ...values,
                            cbm: values.cbm === null ? 0 : values.cbm,
                            poId: id,
                            poLineNumber: products?.length ? products[products?.length - 1].poLineNumber + 1 : 1,
                            hsCode,
                        })
                    );
                } else if (edit) {
                    dispatch(
                        updateProductPo({
                            ...values,
                            cbm: values.cbm === null ? 0 : values.cbm,
                            poId: id,
                            poProductId: singleProduct.poProductId,
                            hsCode,
                        })
                    );
                }
                setDestinationsToAdd(false);
                submitProps.resetForm({ values });
            }
        },
    });
    const removeProduct = (poProductId: string) => {
        if (poProductId) {
            setRemoveModalVisibility(true);
            setPoProductId(poProductId);
            setEdit(false);
            setEditIndex(0);
            formik.resetForm();
            setSingleProduct({});
        }
    };

    useEffect(() => {
        if (isError) {
            error(productPoError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (productPoSuccess?.status === 200 || productPoSuccess?.status === 201) {
            if (productPoSuccess?.data?.type === 'link') {
                success(`${headerData?.vendorName} has been linked to the SKU`, Position.TOP_RIGHT);
                setModalViewVendor(false);
                dispatch(fetchSkuList({ clientCode: headerData?.clientCode }));
            } else {
                if (productPoSuccess?.data?.type === 'create') {
                    formik.resetForm();
                    success(
                        `PO Line ${
                            productPoSuccess?.data?.info?.poLineNumber ? productPoSuccess?.data?.info?.poLineNumber : ''
                        } created successfully`,
                        Position.TOP_RIGHT
                    );
                    setAdd(false);
                } else if (productPoSuccess?.data?.type === 'update') {
                    success(
                        `PO Line ${
                            productPoSuccess?.data?.info?.poLineNumber ? productPoSuccess?.data?.info?.poLineNumber : ''
                        } updated successfully`,
                        Position.TOP_RIGHT
                    );
                } else if (productPoSuccess?.data?.type === 'delete') {
                    success(
                        `PO Line ${
                            productPoSuccess?.data?.info?.poLineNumber ? productPoSuccess?.data?.info?.poLineNumber : ''
                        } removed successfully`,
                        Position.TOP_RIGHT
                    );
                }
                dispatch(fetchProductPoList({ poId: id }));
                dispatch(
                    fetchHeader({
                        poId: id,
                    })
                );
                setTimeout(() => {
                    setAdd(false);
                    setEdit(false);
                    setEditIndex(0);
                }, 1000);
            }
            scrollLeft();
            dispatch(clearFailure([]));
        }
    }, [isError, productPoSuccess, productPoError]);
    useEffect(() => {
        if (!formik.isSubmitting) {
            return;
        }
        if (Object.keys(formik.errors).length > 0) {
            const fieldName = Object.keys(formik.errors)[0];
            if (document?.getElementById(fieldName + '_div') != null) {
                document?.getElementById(fieldName + '_div')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [formik]);

    useEffect(() => {
        checkForSameSku();
    }, [formik.values.sku, formik.values.destinationPort, formik.isSubmitting]);

    const customStyle = {
        menuPortal: (provided: any) => ({
            ...provided,
            width: '300px',
        }),
        menuList: (base: any) => ({
            ...base,
            '::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: '#F6F6FE',
            },
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(#000, 0.3)',
                backgroundColor: '#C4C6D0',
            },
        }),
    };
    const portalStyle = {
        ...customStyle,
        noOptionsMessage: (base: any) => ({ ...base, height: '180px' }),
    };
    const skuUpdate = (field: string) => {
        const skuDetail: any = sku?.find((res: any) => res.pm_PK === field);
        if (skuDetail) {
            // if (products?.some((res: any) => res.sku === skuDetail.pm_sku)) {
            //     formik.setFieldError('skuId', 'Selected SKU is already added');
            //     formik.setFieldValue('skuId', '');
            //     formik.setFieldValue('sku', '');
            //     formik.setFieldValue('description', '');
            // } else {
            setSkuDetail(skuDetail);
            formik.setFieldValue('description', skuDetail.pm_description);
            const pmPack = skuDetail?.pm_packets?.find((res: any) => res.pp_quantity?.toLowerCase() === 'each');
            if (pmPack) {
                if (pmPack?.pp_cbm > 0) {
                    setCbmForEach(pmPack?.pp_cbm);
                    const cbm = +pmPack?.pp_cbm * +formik.values.qty;
                    formik.setFieldValue('cbm', parseFloat(cbm.toFixed(5)));
                }
                if (pmPack?.pp_weight > 0) {
                    setWeightForEach(pmPack?.pp_weight);
                    const weight = +pmPack?.pp_weight * +formik.values.qty;
                    formik.setFieldValue('weight', parseFloat(weight.toFixed(5)));
                }
            }
            if (skuDetail?.pm_type === 'Commodity') {
                formik.setFieldValue('weight', 0);
            }
            // setIsEachPresent(skuDetail.isEachPresent);
            // setIsVendorLinked(skuDetail.pm_vendors.some((e: any) => e.vn_vendorcode === headerData?.vendorCode));
            if (skuDetail.pm_type !== 'Commodity' && !skuDetail.isEachPresent) {
                setModalViewCbm(true);
            } else if (!skuDetail.pm_vendors.some((e: any) => e.vn_vendorcode === headerData?.vendorCode)) {
                setModalViewVendor(true);
            }
            formik.setFieldError('skuId', undefined);
            // }
        }
    };
    const resizeFilter = () => {
        const el_table = document.getElementById('table_div');
        let gridDistance = 0;
        let gridDistanceLeft = 0;
        if (el_table?.getBoundingClientRect().top) {
            gridDistance = el_table?.getBoundingClientRect().top - 40;
            gridDistanceLeft = el_table?.getBoundingClientRect().left + 4;
        }
        const el_address = document.getElementById('address_div');
        const dropdown_address_bottom = document.getElementById('address_div')?.getBoundingClientRect().bottom || 0;
        const spaceBelow = window.innerHeight - dropdown_address_bottom;
        if (el_address?.getBoundingClientRect().top) {
            if (spaceBelow < 150) {
                gridDistance = gridDistance + 175;
            }
            setDistanceFromTopForAddress(el_address?.getBoundingClientRect().top - gridDistance);
        }
        if (el_address?.getBoundingClientRect().left) {
            setDistanceFromLeftForAddress(el_address?.getBoundingClientRect().left - gridDistanceLeft);
        }
    };
    const menuPlacementCheck = (mode: string) => {
        let id = '';
        if (mode === 'add') {
            id = 'add_row';
        } else {
            id = `edit_row_${editIndex}`;
        }
        const windowHeight = document.documentElement.clientHeight;
        const noMessageHeight = 200;
        const element = document.getElementById(id);
        if (element) {
            const bottom = element.getBoundingClientRect().bottom;
            const spaceBelow = windowHeight - bottom;
            spaceBelow < noMessageHeight ? setMenuPlacement('top') : setMenuPlacement('auto');
        }
    };
    const editPoLine = (index: number, product: ProductPoDataDetails) => {
        setEdit(true);
        setEditIndex(index);
        const skuDetail: any = sku?.find((res: any) => res.pm_sku === product.sku);
        const productCopy = JSON.parse(JSON.stringify(product));
        if (skuDetail) {
            productCopy.skuId = skuDetail.pm_PK;
            skuUpdate(skuDetail.pm_PK);
        } else {
            skuUpdate(product.skuId);
        }
        setSingleProduct(productCopy);
    };
    const viewPoLine = (e: any, name: string) => {
        e.preventDefault();
        setModalView(true);
        const skuDetail: any = sku?.find((res: any) => res.pm_sku === name);
        setSkuDetail(skuDetail);
    };
    useEffect(() => {
        if (!formik.values?.sku) {
            formik.setFieldValue('description', '');
        }
    }, [formik.values.sku]);
    useEffect(() => {
        if (add) {
            menuPlacementCheck('add');
        } else if (edit) {
            menuPlacementCheck('edit');
        }
    }, [add, edit]);
    const addPoLine = () => {
        setAdd(true);
        formik.resetForm();
        setSingleProduct({});
        setTimeout(() => {
            if (currency?.length === 1) {
                formik.setFieldValue('currency', currency[0].value);
            } else {
                formik.setFieldValue('currency', 'USD');
            }
            formik.setFieldValue('countryCode', headerData?.vendorCountryCode);
            formik.setFieldValue('countryName', headerData?.vendorCountryName);
        }, 500);
        scrollLeft();
        scrollDown();
    };
    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#errorCautionIcon"></use>
                </svg>
                <p className="no-content-message">
                    No matches found. <br />
                    SKU added to the Product Master <br />
                    will only be available for selection
                </p>
                <br />
                <a
                    className="redirection-link"
                    onClick={() => {
                        setModalViewSku(true);
                    }}
                >
                    Go to Product Master
                </a>
            </div>
        );
    };
    const linkVendor = () => {
        dispatch(linkVendorProductPo({ vendorCode: headerData?.vendorCode, pm_pk: skuDetail.pm_PK }));
    };
    // const dateStyles = (idSelector: string) => {
    // const dateElement = document.querySelector<HTMLElement>(`#${idSelector}`);
    // const domRect = dateElement!.getBoundingClientRect();
    // const spaceBelow = window.innerHeight - domRect.bottom;
    // let dateDistanceTop = 0;
    // let dateDistanceBottom = 0;
    // let dateDistanceLeft = 0;
    // dateDistanceTop = dateElement!.getBoundingClientRect().top;
    // dateDistanceBottom = dateElement!.getBoundingClientRect().bottom;
    // dateDistanceLeft = dateElement!.getBoundingClientRect().left;
    // setTimeout(() => {
    //     const element = document.querySelector<HTMLElement>('.react-date-picker__calendar--open');
    //     if (element !== null) {
    //         if (spaceBelow > 300) {
    //             // if (element.style.top) {
    //             element.style.visibility = 'visible';
    //             element.style.top = `${dateDistanceBottom}px`;
    //             element.style.left = `${dateDistanceLeft - 18}px`;
    //             // }
    //         } else {
    //             setTimeout(() => {
    //                 element.style.visibility = 'visible';
    //                 if (element.style.top === 'auto') {
    //                     element.style.inset = `${dateDistanceTop - 255}px auto auto ${dateDistanceLeft - 18}px`;
    //                 }
    //             }, 100);
    //             element.style.top = `${dateDistanceTop - 255}px`;
    //             element.style.left = `${dateDistanceLeft - 18}px`;
    //         }
    //     }
    // }, 100);
    // };
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userType = '';
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            userType = roles[roleIndex].profile?.toLowerCase();
        }
    }
    const [cancelModal, setCancelModal] = useState(false);
    const onCancel = () => {
        setCancelModal(false);
    };
    const onConfirm = () => {
        formik.resetForm();
        setSingleProduct({});
        setAdd(false);
        setEdit(false);
        setEditIndex(0);
        onCancel();
    };

    const isTriangleShipement = headerData?.isTriangleShipment;
    // && headerData?.includesAUPortDestination === false

    const isAuPortInclude = headerData?.isMultiAddress && headerData?.includesAUPortDestination;

    const formatedPorts = isAuPortInclude ? ports : ports?.filter((p: any) => p.country !== 'AU');

    async function handleSkipPOLine() {
        const requestSkip = await skipLinePORequest(id ?? '');
        if (requestSkip.success) {
            success(`Skip PO Line Successful`, Position.TOP_RIGHT);
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
        } else {
            error(requestSkip?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
        setTriangleModeModal(false);
        setAdd(false);
    }

    async function handleAddPoLineRevert() {
        const requestSkip = await addPoLineRevert(id ?? '');
        if (requestSkip.success) {
            // success(`Skip PO Line Successful`, Position.TOP_RIGHT);
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
        } else {
            error(requestSkip?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
        setTriangleModeModal(false);
        setAdd(false);
    }
    const skipPOLine = headerData?.isHeaderOnlyPo;
    const multiDestination = headerData?.isMultiAddress;

    const [destinations, setDestinations] = useState<any[]>([]);
    const [destinationsToAdd, setDestinationsToAdd] = useState<boolean>(false);

    function handleAddDestination(option: any) {
        if (!destinations?.find((d) => d.label === option.label) && option) {
            setDestinations([...destinations, option]);
            setDestinationsToAdd(true);
            handleSaveDestinations([...destinations, option]);
        }
        setPorts([]);
    }

    function handleRemoveDestination(value: string) {
        const updatedDestinations = destinations.filter((d) => d.label !== value);
        setDestinations(updatedDestinations);
        handleSaveDestinations(updatedDestinations, 'Deleted');
    }
    const activateNextBtn = destinations.length > 1;
    // const navigate = useNavigate();

    async function handleSaveDestinations(destines: any[], type = 'Added') {
        // if (activateNextBtn) {
        // event.stopPropagation();
        const request = await skipLinePoDestinations(id ?? '', {
            destinations: destines.map((d) => d.label),
        });
        if (request.success) {
            success(`Destination ${type} Successfully`, Position.TOP_RIGHT);
            // navigate(`/purchase_order/${id}/po_review`);
            dispatch(
                fetchHeader({
                    poId: id,
                })
            );
        } else {
            error(request?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
        // }
    }

    useEffect(() => {
        const destines = headerData?.destinations?.map((d: any) => ({
            label: d,
            value: d,
        }));
        setDestinations(destines ?? []);
    }, [headerData?.destinations]);

    const condition =
        !((products as any)?.length > 0 || activateNextBtn || (skipPOLine && !multiDestination)) ||
        !headerData?.productTabComplete;

    const showMandatoryFieldsIcon = () => {
        if (!viaIntegration) {
            if (multiDestination && (products as any)?.length > 0) {
                return headerData.productTabComplete ? false : true;
            }
            return false;
        }
        return condition;
    };

    // function checkPoReviewEnabled() {
    //     let enabled = true;

    //     if (!skipPOLine && (products as any)?.length < 1) {
    //         enabled = false;
    //     }
    //     if (skipPOLine && headerData?.destinations?.length < 2) {
    //         enabled = false;
    //     }

    //     if (skipPOLine && !multiDestination) {
    //         enabled = true;
    //     }
    //     if (headerData?.destinations[0]?.includes('null')) {
    //         enabled = false;
    //     }
    //     return enabled ? '' : 'disabled';
    // }

    const val = basicDetails as any;
    // const checkDisabled = () => {
    //     let flag = true;
    //     if (val?.agentName !== null && val?.orderDate !== null) {
    //         flag = false;
    //     }
    //     if (skipPOLine && headerData?.destinations?.length < 2) {
    //         flag = true;
    //     }
    //     if (!skipPOLine && val?.purchaseOrderProducts?.length === 0) {
    //         flag = true;
    //     }
    //     return flag;
    // };

    useEffect(() => {
        let flag = false;
        val?.purchaseOrderProducts?.forEach((element: any) => {
            if (val?.isMultiAddress && element.destinationAddress === null) {
                flag = true;
            }
        });
        setflagNew(flag);
    }, [val]);

    const handleSubmit = () => {
        if (add || edit) {
            formik.handleSubmit();
            return true;
        }
        return false;
    };

    usePrompt('Leave screen?', formik.dirty, setUnsavedChangeModalVisibility, setCallBackFn);
    useBrowserBackCheck(formik.dirty || destinationsToAdd, () => setCancelModal(true));
    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';
    return (
        <div
            onClick={() => {
                setShowAddress(false);
            }}
            className="main-wrapper container product-details-wrapper"
        >
            <div className="main-header-content-holder large-header">
                {/* <div className="po-breadcrumb-holder">
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/purchase_order">
                                    Purchase Orders
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link to={`/purchase_order/${id}/product_details`} className="breadcrumb-item">
                                    Add New PO
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="btn-holder discard-po-btn-holder">
                        <button
                            className="app-btn app-btn-secondary black-outline-btn icon-button"
                            title="Discard Purchase Order"
                        >
                            <svg className="svg-icon add-btn-icon">
                                <use xlinkHref="#deleteIcon"></use>
                            </svg>
                            <span className="button-text">Discord PO</span>
                        </button>
                    </div>
                </div> */}
                <Breadcrumbs
                    firstName={`${poName}`}
                    firstUrl={`${poUrl}`}
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New PO' : 'PO Details'}
                    secondUrl={`/purchase_order/${id}/product_details`}
                    disabled={formik.dirty}
                    callbackFn={handleSubmit}
                />
                <PurchaseOrderHeader id={id} />
                <PODetailsHeaderTabs />
            </div>
            <div className="grid-section product-details-grid grid-p-0-15">
                {isLoading > 0 && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {!skipPOLine || add ? (
                    <div id="table_div" className="grid-holder">
                        {showMandatoryFieldsIcon() && (
                            <div className="mandatory-warning-text">
                                <div>
                                    <svg className="svg-icon error-icon">
                                        <use xlinkHref="#errorIcon">
                                            <title>Warning</title>
                                        </use>
                                    </svg>
                                </div>

                                <div className="error-text">
                                    Mandatory fields missing. Please {viaIntegration ? 'integrate again/' : ''} edit the
                                    line details to enter the mandatory fields to proceed.
                                </div>
                            </div>
                        )}
                        <div className="grid-header">
                            <div className="grid-header--wrapper">
                                <div className="grid-header--title">Product Details</div>
                            </div>
                            <div className="add-btn-users">
                                {isTriangleShipement &&
                                    !products?.length &&
                                    headerData?.shippingMode?.shippingModeCode !== 'LND' && (
                                        <button
                                            className="app-btn icon-button app-btn-secondary"
                                            onClick={() => setTriangleModeModal(true)}
                                            disabled={add || edit}
                                        >
                                            <span>Skip PO Line Addition</span>
                                        </button>
                                    )}
                                {headerData?.poStatus !== 'Cancelled' &&
                                    headerData?.poStatus !== 'In_Booking_Proposal' &&
                                    headerData?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    headerData?.poStatus !== 'Booked' &&
                                    userType !== 'agent' && (
                                        <ShowForPermissions permission="edit" type="po">
                                            {edit || add ? (
                                                <button className="app-btn export-btn icon-button app-btn-secondary disabled">
                                                    <svg className="svg-icon plus-icon">
                                                        <use xlinkHref="#plusIcon"></use>
                                                    </svg>
                                                    <span className="button-text">Add PO Line</span>
                                                </button>
                                            ) : (
                                                <button
                                                    onClick={() => {
                                                        addPoLine();
                                                    }}
                                                    className="app-btn export-btn icon-button app-btn-secondary"
                                                >
                                                    <svg className="svg-icon plus-icon">
                                                        <use xlinkHref="#plusIcon"></use>
                                                    </svg>
                                                    <span className="button-text">Add PO Line</span>
                                                </button>
                                            )}
                                        </ShowForPermissions>
                                    )}
                            </div>
                        </div>
                        <div
                            onScroll={() => {
                                setShowAddress(false);
                            }}
                            ref={scrollElement}
                            className="grid-container"
                        >
                            {!products?.length && !add && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No PO Lines added</p>
                                </div>
                            )}
                            <div className="grid-scroll-content">
                                <form id="my-form" action="" onSubmit={formik.handleSubmit}>
                                    <table className="grid-table">
                                        <thead>
                                            <tr className="grid-sticky-row">
                                                <th className="grid-cell-header col-po-no">
                                                    <div className="grid-cell-data">
                                                        <span>PO LINE NO.</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-sku">
                                                    <div className="grid-cell-data">
                                                        <span>SKU</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-description">
                                                    <div className="grid-cell-data">
                                                        <span>DESCRIPTION</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-country">
                                                    <div className="grid-cell-data">
                                                        <span>COUNTRY OF ORIGIN</span>
                                                    </div>
                                                </th>
                                                {headerData?.isMultiAddress && (
                                                    <>
                                                        <th className="grid-cell-header col-destination-port">
                                                            <div className="grid-cell-data">
                                                                <span>DESTINATION</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-destination-address">
                                                            <div className="grid-cell-data">
                                                                <span>DESTINATION ADDRESS</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-ready-date">
                                                            <div className="grid-cell-data">
                                                                <span>GOODS READY DATE</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-eta-whouse">
                                                            <div className="grid-cell-data">
                                                                <span>PROJ. ETA INTO WHOUSE</span>
                                                            </div>
                                                        </th>
                                                    </>
                                                )}
                                                <th className="grid-cell-header col-quantity">
                                                    <div className="grid-cell-data">
                                                        <span>QUANTITY</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-cbm">
                                                    <div className="grid-cell-data">
                                                        <span>CBM</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-currency">
                                                    <div className="grid-cell-data">
                                                        <span>CURRENCY</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-unit-cost">
                                                    <div className="grid-cell-data">
                                                        <span>UNIT COST</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-net-cost">
                                                    <div className="grid-cell-data">
                                                        <span>NET COST</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header grid-sticky-column sticky-right-aligned col-user-action">
                                                    <div className="grid-cell-data">ACTION</div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {products?.map((product: ProductPoDataDetails, index: number) =>
                                                edit && editIndex === index ? (
                                                    <tr
                                                        id={`edit_row_${index}`}
                                                        key={index}
                                                        className="has-child row-expanded"
                                                    >
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">
                                                                    {product.poLineNumber ? product.poLineNumber : '-'}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell search-entry-holder">
                                                            <div id="skuId_div" className="grid-cell-data">
                                                                <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                    <SearchableSelectBox
                                                                        data={skuData?.length ? skuData : []}
                                                                        placeholder="Enter SKU to select"
                                                                        formik={formik}
                                                                        fieldName="skuId"
                                                                        extraField="sku"
                                                                        customStyle={portalStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        closeMenuOnScroll={true}
                                                                        extraFieldFn={skuUpdate}
                                                                        NoMessageComponent={NoMessageComponent}
                                                                        menuPlacement={menuPlacement}
                                                                    />
                                                                    {formik.errors.skuId ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.skuId}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {formik.values.description}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell search-entry-holder">
                                                            <div id="countryCode_div" className="grid-cell-data">
                                                                <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown country-region">
                                                                    <SearchableSelectBox
                                                                        data={country?.length ? country : []}
                                                                        placeholder="Enter country to select"
                                                                        formik={formik}
                                                                        fieldName="countryCode"
                                                                        extraField="countryName"
                                                                        customStyle={customStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        closeMenuOnScroll={true}
                                                                    />
                                                                    {formik.errors.countryCode ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.countryCode}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        {headerData?.isMultiAddress && (
                                                            <>
                                                                <td className="grid-cell search-entry-holder">
                                                                    <div
                                                                        id="destinationPort_div"
                                                                        className="grid-cell-data"
                                                                    >
                                                                        <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown country-region">
                                                                            {/* <SearchableSelectBox
                                                                            data={port?.length ? port : []}
                                                                            placeholder="Enter destination to select"
                                                                            formik={formik}
                                                                            fieldName="destinationPort"
                                                                            customStyle={customStyle}
                                                                            menuPosition="fixed"
                                                                            menuPortalTarget={document.getElementById(
                                                                                'outside_div'
                                                                            )}
                                                                            closeMenuOnScroll={true}
                                                                        /> */}
                                                                            <Select
                                                                                options={formatedPorts}
                                                                                isClearable
                                                                                placeholder="Enter destination to select"
                                                                                onChange={(option: any) => {
                                                                                    // eslint-disable-next-line no-undefined
                                                                                    formik.setFieldError(
                                                                                        'destinationPort',
                                                                                        undefined
                                                                                    );
                                                                                    formik.setFieldValue(
                                                                                        'destinationPort',
                                                                                        option?.label
                                                                                    );
                                                                                }}
                                                                                onFocus={() => {
                                                                                    setPorts([]);
                                                                                }}
                                                                                onInputChange={(text: string) => {
                                                                                    setSearchTerm(text);
                                                                                }}
                                                                                noOptionsMessage={({ inputValue }) => {
                                                                                    return !inputValue ||
                                                                                        !formatedPorts?.length
                                                                                        ? 'No results'
                                                                                        : '';
                                                                                }}
                                                                                onKeyDown={(e) => {
                                                                                    if (e.key === 'Enter') {
                                                                                        e.preventDefault();
                                                                                    }
                                                                                }}
                                                                                id={'destinationPort'}
                                                                                name={'destinationPort'}
                                                                                defaultInputValue={
                                                                                    multiDestinationData.destinationPort ||
                                                                                    undefined
                                                                                }
                                                                                // value={ports?.filter((option: any) => {
                                                                                //     if (
                                                                                //         option.label ===
                                                                                //         formik.values['destinationPort']
                                                                                //     ) {
                                                                                //         return option;
                                                                                //     }
                                                                                // })}
                                                                                components={{
                                                                                    DropdownIndicator: () => null,
                                                                                    IndicatorSeparator: () => null,
                                                                                }}
                                                                                styles={customStyle}
                                                                            />
                                                                            {formik.errors.destinationPort ? (
                                                                                <div className="error-text">
                                                                                    {formik.errors.destinationPort}
                                                                                </div>
                                                                            ) : null}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell search-entry-holder">
                                                                    <div
                                                                        id={editIndex === index ? 'address_div' : ''}
                                                                        className="grid-cell-data"
                                                                    >
                                                                        <div
                                                                            className={
                                                                                showAddress
                                                                                    ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                                    : 'dropdown-wrap  menu-down modal-dropdown'
                                                                            }
                                                                            id="destinationAddress_div"
                                                                        >
                                                                            <button
                                                                                onClick={(e) => {
                                                                                    resizeFilter();
                                                                                    e.stopPropagation();
                                                                                    setShowAddress((prev) => !prev);
                                                                                }}
                                                                                type="button"
                                                                                className="menu-btn app-btn"
                                                                            >
                                                                                {formik.values.destinationAddress ? (
                                                                                    <span className="btn-text placeholder-text-address">
                                                                                        {
                                                                                            formik.values
                                                                                                .destinationAddress
                                                                                        }
                                                                                    </span>
                                                                                ) : (
                                                                                    <span className="btn-text btn-address">
                                                                                        Select address
                                                                                    </span>
                                                                                )}
                                                                                {/* <span className="placeholder-text">Select Address</span> */}
                                                                                <span className="dropdown-arrow">
                                                                                    <svg className="svg-icon arrow-icon">
                                                                                        <use xlinkHref="#downArrow">
                                                                                            <title>dropdown</title>
                                                                                        </use>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                        {formik.errors.destinationAddress ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.destinationAddress}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    id="goodsReadyDate_div"
                                                                    className="grid-cell search-entry-holder"
                                                                >
                                                                    <div
                                                                        id={`goodsReadyDateDiv_${index}`}
                                                                        className="grid-cell-data date-picker-grid"
                                                                    >
                                                                        <DatePicker
                                                                            // minDate={new Date()}
                                                                            formik={formik}
                                                                            fieldName="goodsReadyDate"
                                                                            portalId="date-portal"
                                                                        />
                                                                        {formik.errors.goodsReadyDate ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.goodsReadyDate}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </td>
                                                                <td
                                                                    id="projWhouseETA_div"
                                                                    className="grid-cell search-entry-holder"
                                                                >
                                                                    <div
                                                                        id={`projWhouseETADiv_${index}`}
                                                                        className="grid-cell-data date-picker-grid"
                                                                    >
                                                                        <DatePicker
                                                                            minDate={new Date()}
                                                                            formik={formik}
                                                                            fieldName="projWhouseETA"
                                                                            portalId="date-portal"
                                                                        />
                                                                        {formik.errors.projWhouseETA ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.projWhouseETA}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}
                                                        <td className="grid-cell search-entry-holder">
                                                            <div id="qty_div" className="grid-cell-data">
                                                                <input
                                                                    placeholder="Enter quantity"
                                                                    type="text"
                                                                    className="search-input input-text input-qty"
                                                                    id="qty"
                                                                    name="qty"
                                                                    onChange={(event) => {
                                                                        formik.setFieldError(
                                                                            event.target.name,
                                                                            undefined
                                                                        );
                                                                        // formik.handleChange(event);
                                                                        formik.setFieldValue(
                                                                            'qty',
                                                                            +event.target.value
                                                                        );
                                                                        if (skuDetail?.pm_type !== 'Commodity') {
                                                                            const cbm =
                                                                                +cbmForEach * +event.target.value;
                                                                            formik.setFieldValue(
                                                                                'cbm',
                                                                                parseFloat(cbm.toFixed(5))
                                                                            );
                                                                        }
                                                                        formik.setFieldValue(
                                                                            'netCost',
                                                                            +formik.values.unitCost *
                                                                                +event.target.value
                                                                        );
                                                                        const weight =
                                                                            +weightForEach * +event.target.value;
                                                                        formik.setFieldValue(
                                                                            'weight',
                                                                            parseFloat(weight.toFixed(5))
                                                                        );
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        e.target.value !== '' &&
                                                                            parseFloat(e.target.value) >= 0 &&
                                                                            formik.validateField('qty');
                                                                    }}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    value={formik.values.qty}
                                                                ></input>
                                                                {formik.errors.qty ? (
                                                                    <div className="error-text">
                                                                        {typeof formik.errors.qty === 'string' &&
                                                                            formik.errors.qty}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                        {skuDetail?.pm_type === 'Commodity' ? (
                                                            <td className="grid-cell search-entry-holder">
                                                                <div id="cbm_div" className="grid-cell-data">
                                                                    <input
                                                                        placeholder="Enter value"
                                                                        type="text"
                                                                        className="search-input input-text input-qty"
                                                                        id="cbm"
                                                                        name="cbm"
                                                                        onChange={(event) => {
                                                                            formik.setFieldError(
                                                                                event.target.name,
                                                                                undefined
                                                                            );
                                                                            if (
                                                                                decimalNumberValidation(
                                                                                    parseFloat(event.target.value),
                                                                                    10,
                                                                                    2
                                                                                )
                                                                            ) {
                                                                                formik.setFieldValue(
                                                                                    'cbm',
                                                                                    event.target.value
                                                                                );
                                                                            }
                                                                        }}
                                                                        onKeyPress={(event) => {
                                                                            if (
                                                                                !validateFractionInput({
                                                                                    inputKey: event.key,
                                                                                    currentValue: formik.values.cbm,
                                                                                })
                                                                            ) {
                                                                                event.preventDefault();
                                                                            }
                                                                        }}
                                                                        value={formik.values.cbm}
                                                                    ></input>
                                                                    {formik.errors.cbm ? (
                                                                        <div className="error-text">
                                                                            {typeof formik.errors.cbm === 'string' &&
                                                                                formik.errors.cbm}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </td>
                                                        ) : (
                                                            <td className="grid-cell">
                                                                <div className="grid-cell-data">
                                                                    {formik.values.cbm}
                                                                </div>
                                                            </td>
                                                        )}
                                                        <td className="grid-cell search-entry-holder">
                                                            <div id="currency_div" className="grid-cell-data">
                                                                <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                    <SearchableSelectBox
                                                                        data={currency?.length ? currency : []}
                                                                        placeholder="Enter currency to select"
                                                                        formik={formik}
                                                                        fieldName="currency"
                                                                        customStyle={customStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        closeMenuOnScroll={true}
                                                                        isClearable={
                                                                            currency?.length === 1 ? false : true
                                                                        }
                                                                    />
                                                                    {formik.errors.currency ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.currency}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell  search-entry-holder">
                                                            <div id="unitCost_div" className="grid-cell-data">
                                                                <input
                                                                    placeholder="Enter unit cost"
                                                                    type="number"
                                                                    className="search-input input-text input-qty"
                                                                    id="unitCost"
                                                                    name="unitCost"
                                                                    min="0"
                                                                    step="any"
                                                                    onChange={(event) => {
                                                                        formik.setFieldError(
                                                                            event.target.name,
                                                                            undefined
                                                                        );
                                                                        // formik.handleChange(event);
                                                                        formik.setFieldValue(
                                                                            'unitCost',
                                                                            +parseFloat(event.target.value)
                                                                        );
                                                                        const netCost =
                                                                            +formik.values.qty *
                                                                            +parseFloat(event.target.value);
                                                                        if (event.target.value) {
                                                                            formik.setFieldValue(
                                                                                'netCost',
                                                                                parseFloat(netCost.toFixed(2))
                                                                            );
                                                                        }
                                                                    }}
                                                                    onBlur={(e) => {
                                                                        e.target.value !== '' &&
                                                                            parseFloat(e.target.value) >= 0 &&
                                                                            formik.validateField('unitCost');
                                                                    }}
                                                                    //onKeyPress={(event) => {
                                                                    //    if (!/[0-9]/.test(event.key)) {
                                                                    //        event.preventDefault();
                                                                    //    }
                                                                    //}}
                                                                    value={parseFloat((formik as any).values.unitCost)}
                                                                ></input>
                                                                {formik.errors.unitCost ? (
                                                                    <div className="error-text">
                                                                        {typeof formik.errors.unitCost === 'string' &&
                                                                            formik.errors.unitCost}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell  search-entry-holder">
                                                            <div className="grid-cell-data">
                                                                {formik.values.netCost}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned">
                                                            <div className="grid-cell-data">
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary"
                                                                    title="Save PO Line"
                                                                    type="submit"
                                                                    form="my-form"
                                                                >
                                                                    <svg className="svg-icon save-icon">
                                                                        <use xlinkHref="#saveIcon"></use>
                                                                    </svg>
                                                                </button>
                                                                {headerData?.poStatus === 'Draft' && (
                                                                    <button
                                                                        className={`action-btn app-btn app-btn-secondary ${
                                                                            add ? 'disabled' : ''
                                                                        }`}
                                                                        title="Remove PO Line"
                                                                        type="button"
                                                                        onClick={(e: any) => {
                                                                            e.preventDefault();
                                                                            removeProduct(product.poProductId);
                                                                        }}
                                                                    >
                                                                        <svg className="svg-icon eye-icon ">
                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                )}
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="View SKU Details"
                                                                    disabled={formik.values.skuId ? false : true}
                                                                    onClick={(e: any) => {
                                                                        viewPoLine(e, formik.values.sku);
                                                                    }}
                                                                >
                                                                    <svg className="svg-icon eye-icon">
                                                                        <use xlinkHref="#eyeIcon"></use>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ) : (
                                                    <tr key={index} className="has-child row-expanded">
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">
                                                                    {product.poLineNumber ? product.poLineNumber : '-'}
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{product.sku}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{product.description}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{product.countryName}</div>
                                                        </td>
                                                        {headerData?.isMultiAddress && (
                                                            <>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {product.destinationPort &&
                                                                            `${product.destinationPortName}(${product.destinationPort})`}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {product.destinationAddress}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {product.goodsReadyDate
                                                                            ? moment(product.goodsReadyDate).format(
                                                                                  'DD-MM-YYYY'
                                                                              )
                                                                            : null}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {product.projWhouseETA
                                                                            ? moment(product.projWhouseETA).format(
                                                                                  'DD-MM-YYYY'
                                                                              )
                                                                            : null}
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{product.qty}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{product.cbm}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {product?.currency?.currencycode}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{product.unitCost}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{product.netCost}</div>
                                                        </td>
                                                        <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned">
                                                            <div className="grid-cell-data">
                                                                {headerData?.poStatus !== 'Cancelled' &&
                                                                    headerData?.poStatus !== 'In_Booking_Proposal' &&
                                                                    headerData?.poStatus?.toLowerCase() !==
                                                                        'pending_reassignment' &&
                                                                    headerData?.poStatus !== 'Booked' &&
                                                                    userType !== 'agent' && (
                                                                        <>
                                                                            <ShowForPermissions
                                                                                permission="edit"
                                                                                type="po"
                                                                            >
                                                                                {product.enriched !== true && (
                                                                                    <button
                                                                                        className={`action-btn app-btn app-btn-secondary ${
                                                                                            add || edit
                                                                                                ? 'disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                        disabled={
                                                                                            add || edit ? true : false
                                                                                        }
                                                                                        title="Edit PO Line"
                                                                                        type="button"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            editPoLine(index, product);
                                                                                        }}
                                                                                    >
                                                                                        <svg className="svg-icon save-icon">
                                                                                            <use xlinkHref="#editIcon"></use>
                                                                                        </svg>
                                                                                    </button>
                                                                                )}
                                                                            </ShowForPermissions>
                                                                            {headerData?.poStatus === 'Draft' && (
                                                                                <ShowForPermissions
                                                                                    permission="edit"
                                                                                    type="po"
                                                                                >
                                                                                    <button
                                                                                        className={`action-btn app-btn app-btn-secondary ${
                                                                                            add || edit
                                                                                                ? 'disabled'
                                                                                                : ''
                                                                                        }`}
                                                                                        disabled={
                                                                                            add || edit ? true : false
                                                                                        }
                                                                                        title="Remove PO Line"
                                                                                        onClick={(e: any) => {
                                                                                            e.preventDefault();
                                                                                            removeProduct(
                                                                                                product.poProductId
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <svg className="svg-icon eye-icon ">
                                                                                            <use xlinkHref="#deleteIcon"></use>
                                                                                        </svg>
                                                                                    </button>
                                                                                </ShowForPermissions>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="View SKU Details"
                                                                    onClick={(e) => {
                                                                        viewPoLine(e, product.sku);
                                                                    }}
                                                                >
                                                                    <svg className="svg-icon eye-icon">
                                                                        <use xlinkHref="#eyeIcon"></use>
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                            {add && (
                                                <tr id="add_row" className="has-child row-expanded">
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            <span className="client-id">
                                                                {products?.length
                                                                    ? products[products?.length - 1].poLineNumber + 1
                                                                    : 1}
                                                            </span>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell search-entry-holder">
                                                        <div id="skuId_div" className="grid-cell-data">
                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                <SearchableSelectBox
                                                                    data={skuData?.length ? skuData : []}
                                                                    placeholder="Enter SKU to select"
                                                                    formik={formik}
                                                                    fieldName="skuId"
                                                                    extraField="sku"
                                                                    customStyle={portalStyle}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    closeMenuOnScroll={true}
                                                                    extraFieldFn={skuUpdate}
                                                                    NoMessageComponent={NoMessageComponent}
                                                                    menuPlacement={menuPlacement}
                                                                />
                                                                {formik.errors.skuId ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.skuId}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {formik.values.description}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell search-entry-holder">
                                                        <div id="countryCode_div" className="grid-cell-data">
                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown country-region">
                                                                <SearchableSelectBox
                                                                    data={country?.length ? country : []}
                                                                    placeholder="Enter country to select"
                                                                    formik={formik}
                                                                    fieldName="countryCode"
                                                                    extraField="countryName"
                                                                    customStyle={customStyle}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    closeMenuOnScroll={true}
                                                                />
                                                                {formik.errors.countryCode ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.countryCode}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {headerData?.isMultiAddress && (
                                                        <>
                                                            <td className="grid-cell search-entry-holder">
                                                                <div
                                                                    id="destinationPort_div"
                                                                    className="grid-cell-data"
                                                                >
                                                                    <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown country-region">
                                                                        {/* <SearchableSelectBox
                                                                        data={port?.length ? port : []}
                                                                        placeholder="Enter destination to select"
                                                                        formik={formik}
                                                                        fieldName="destinationPort"
                                                                        customStyle={customStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        closeMenuOnScroll={true}
                                                                    /> */}
                                                                        <Select
                                                                            options={formatedPorts}
                                                                            isClearable
                                                                            placeholder="Enter destination to select"
                                                                            onChange={(option: any) => {
                                                                                // eslint-disable-next-line no-undefined
                                                                                formik.setFieldError(
                                                                                    'destinationPort',
                                                                                    undefined
                                                                                );
                                                                                formik.setFieldValue(
                                                                                    'destinationPort',
                                                                                    option?.label
                                                                                );
                                                                            }}
                                                                            onFocus={() => {
                                                                                setPorts([]);
                                                                            }}
                                                                            onInputChange={(text: string) => {
                                                                                setSearchTerm(text);
                                                                            }}
                                                                            isLoading={unlocoLoading}
                                                                            noOptionsMessage={({ inputValue }) => {
                                                                                if (inputValue?.length < 3) {
                                                                                    return 'Enter at least 3 characters to search';
                                                                                }
                                                                                if (!formatedPorts?.length) {
                                                                                    return 'No results found';
                                                                                }

                                                                                // !inputValue || !formatedPorts?.length
                                                                                //     ? 'Enter details to search'
                                                                                //     : 'No results found';
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                if (e.key === 'Enter') {
                                                                                    e.preventDefault();
                                                                                }
                                                                            }}
                                                                            id={'destinationPort'}
                                                                            name={'destinationPort'}
                                                                            defaultInputValue={
                                                                                multiDestinationData.destinationPort ||
                                                                                undefined
                                                                            }
                                                                            // value={ports?.filter((option: any) => {
                                                                            //     if (
                                                                            //         option.label ===
                                                                            //         formik.values['destinationPort']
                                                                            //     ) {
                                                                            //         return option;
                                                                            //     }
                                                                            // })}
                                                                            components={{
                                                                                DropdownIndicator: () => null,
                                                                                IndicatorSeparator: () => null,
                                                                            }}
                                                                            styles={customStyle}
                                                                        />
                                                                        {formik.errors.destinationPort ? (
                                                                            <div className="error-text">
                                                                                {formik.errors.destinationPort}
                                                                            </div>
                                                                        ) : null}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="grid-cell search-entry-holder">
                                                                <div id="address_div" className="grid-cell-data">
                                                                    <div
                                                                        className={
                                                                            showAddress
                                                                                ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                                : 'dropdown-wrap  menu-down modal-dropdown'
                                                                        }
                                                                        id="destinationAddress_div"
                                                                    >
                                                                        <button
                                                                            onClick={(e) => {
                                                                                resizeFilter();
                                                                                e.stopPropagation();
                                                                                setShowAddress((prev) => !prev);
                                                                            }}
                                                                            type="button"
                                                                            className="menu-btn app-btn"
                                                                        >
                                                                            {formik.values.destinationAddress ? (
                                                                                <span className="btn-text placeholder-text-address">
                                                                                    {formik.values.destinationAddress}
                                                                                </span>
                                                                            ) : (
                                                                                <span className="btn-text btn-address">
                                                                                    Select address
                                                                                </span>
                                                                            )}
                                                                            {/* <span className="placeholder-text">Select Address</span> */}
                                                                            <span className="dropdown-arrow">
                                                                                <svg className="svg-icon arrow-icon">
                                                                                    <use xlinkHref="#downArrow">
                                                                                        <title>dropdown</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                    {formik.errors.destinationAddress ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.destinationAddress}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </td>
                                                            <td
                                                                id="goodsReadyDate_div"
                                                                className="grid-cell search-entry-holder"
                                                            >
                                                                <div
                                                                    id="goodsReadyDateDiv"
                                                                    className="grid-cell-data date-picker-grid"
                                                                >
                                                                    <DatePicker
                                                                        // minDate={new Date()}
                                                                        formik={formik}
                                                                        fieldName="goodsReadyDate"
                                                                        portalId="date-portal"
                                                                    />
                                                                    {formik.errors.goodsReadyDate ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.goodsReadyDate}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </td>
                                                            <td
                                                                id="projWhouseETA_div"
                                                                className="grid-cell search-entry-holder"
                                                            >
                                                                <div
                                                                    id="projWhouseETADiv"
                                                                    className="grid-cell-data date-picker-grid"
                                                                >
                                                                    <DatePicker
                                                                        minDate={new Date()}
                                                                        formik={formik}
                                                                        fieldName="projWhouseETA"
                                                                        portalId="date-portal"
                                                                    />
                                                                    {formik.errors.projWhouseETA ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.projWhouseETA}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </td>
                                                        </>
                                                    )}
                                                    <td className="grid-cell search-entry-holder">
                                                        <div id="qty_div" className="grid-cell-data">
                                                            <input
                                                                placeholder="Enter quantity"
                                                                type="text"
                                                                className="search-input input-text input-qty"
                                                                id="qty"
                                                                name="qty"
                                                                onChange={(event) => {
                                                                    formik.setFieldError(event.target.name, undefined);
                                                                    // formik.handleChange(event);
                                                                    formik.setFieldValue('qty', +event.target.value);
                                                                    if (skuDetail?.pm_type !== 'Commodity') {
                                                                        const cbm = +cbmForEach * +event.target.value;
                                                                        formik.setFieldValue(
                                                                            'cbm',
                                                                            parseFloat(cbm.toFixed(5))
                                                                        );
                                                                    }
                                                                    formik.setFieldValue(
                                                                        'netCost',
                                                                        +formik.values.unitCost * +event.target.value
                                                                    );
                                                                    const weight = +weightForEach * +event.target.value;
                                                                    formik.setFieldValue(
                                                                        'weight',
                                                                        parseFloat(weight.toFixed(5))
                                                                    );
                                                                }}
                                                                onBlur={(e) => {
                                                                    e.target.value !== '' &&
                                                                        parseFloat(e.target.value) >= 0 &&
                                                                        formik.validateField('qty');
                                                                }}
                                                                onKeyPress={(event) => {
                                                                    if (!/[0-9]/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                value={formik.values.qty}
                                                            ></input>
                                                            {formik.errors.qty ? (
                                                                <div className="error-text">
                                                                    {typeof formik.errors.qty === 'string' &&
                                                                        formik.errors.qty}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    {skuDetail?.pm_type === 'Commodity' ? (
                                                        <td className="grid-cell search-entry-holder">
                                                            <div id="cbm_div" className="grid-cell-data">
                                                                <input
                                                                    placeholder="Enter value"
                                                                    type="text"
                                                                    className="search-input input-text input-qty"
                                                                    id="cbm"
                                                                    name="cbm"
                                                                    onChange={(event) => {
                                                                        formik.setFieldError(
                                                                            event.target.name,
                                                                            undefined
                                                                        );
                                                                        if (
                                                                            decimalNumberValidation(
                                                                                parseFloat(event.target.value),
                                                                                5,
                                                                                2
                                                                            )
                                                                        ) {
                                                                            formik.setFieldValue(
                                                                                'cbm',
                                                                                event.target.value
                                                                            );
                                                                        }
                                                                    }}
                                                                    onKeyPress={(event) => {
                                                                        if (
                                                                            !validateFractionInput({
                                                                                inputKey: event.key,
                                                                                currentValue: formik.values.cbm,
                                                                            })
                                                                        ) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                    value={formik.values.cbm}
                                                                ></input>
                                                                {formik.errors.cbm ? (
                                                                    <div className="error-text">
                                                                        {typeof formik.errors.cbm === 'string' &&
                                                                            formik.errors.cbm}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </td>
                                                    ) : (
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{formik.values.cbm}</div>
                                                        </td>
                                                    )}
                                                    <td className="grid-cell search-entry-holder">
                                                        <div id="currency_div" className="grid-cell-data">
                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                <SearchableSelectBox
                                                                    data={currency?.length ? currency : []}
                                                                    placeholder="Enter currency to select"
                                                                    formik={formik}
                                                                    fieldName="currency"
                                                                    customStyle={customStyle}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    closeMenuOnScroll={true}
                                                                    isClearable={currency?.length === 1 ? false : true}
                                                                />
                                                                {formik.errors.currency ? (
                                                                    <div className="error-text">
                                                                        {formik.errors.currency}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell  search-entry-holder">
                                                        <div id="unitCost_div" className="grid-cell-data">
                                                            <input
                                                                placeholder="Enter unit cost"
                                                                type="number"
                                                                className="search-input input-text input-qty"
                                                                id="unitCost"
                                                                name="unitCost"
                                                                min="0"
                                                                step="any"
                                                                onChange={(event) => {
                                                                    formik.setFieldError(event.target.name, undefined);
                                                                    // formik.handleChange(event);
                                                                    formik.setFieldValue(
                                                                        'unitCost',
                                                                        parseFloat(event.target.value)
                                                                    );
                                                                    const netCost =
                                                                        +formik.values.qty *
                                                                        +parseFloat(event.target.value);
                                                                    if (event.target.value) {
                                                                        formik.setFieldValue(
                                                                            'netCost',
                                                                            parseFloat(netCost.toFixed(2))
                                                                        );
                                                                    }
                                                                }}
                                                                onBlur={(e) => {
                                                                    e.target.value !== '' &&
                                                                        parseFloat(e.target.value) >= 0 &&
                                                                        formik.validateField('unitCost');
                                                                }}
                                                                //onKeyPress={(event) => {
                                                                //    if (!/[0-9]/.test(event.key)) {
                                                                //        event.preventDefault();
                                                                //    }
                                                                //}}
                                                                value={formik.values.unitCost}
                                                            ></input>
                                                            {formik.errors.unitCost ? (
                                                                <div className="error-text">
                                                                    {typeof formik.errors.unitCost === 'string' &&
                                                                        formik.errors.unitCost}
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell  search-entry-holder">
                                                        <div className="grid-cell-data">{formik.values.netCost}</div>
                                                    </td>
                                                    <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned">
                                                        <div className="grid-cell-data">
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary"
                                                                title="Save PO Line"
                                                                type="submit"
                                                                form="my-form"
                                                            >
                                                                <svg className="svg-icon save-icon">
                                                                    <use xlinkHref="#saveIcon"></use>
                                                                </svg>
                                                            </button>
                                                            <button
                                                                className="action-btn app-btn app-btn-secondary "
                                                                title="Remove PO Line"
                                                                onClick={() => {
                                                                    setAdd(false);
                                                                    formik.resetForm();
                                                                    setSingleProduct({});
                                                                }}
                                                            >
                                                                <svg className="svg-icon eye-icon ">
                                                                    <use xlinkHref="#deleteIcon"></use>
                                                                </svg>
                                                            </button>
                                                            {/* <button
                                                            className="action-btn app-btn app-btn-secondary "
                                                            title="View"
                                                        >
                                                            <svg className="svg-icon eye-icon">
                                                                <use xlinkHref="#eyeIcon"></use>
                                                            </svg>
                                                        </button> */}
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        {/* outside dropdown items , destination-adress-dropdown-wrap need to set top and left for this dynamically according to the component*/}
                        <div
                            className={
                                showAddress
                                    ? 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                    : 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down modal-dropdown'
                            }
                            style={{ top: `${distanceFromTopForAddress}px`, left: `${distanceFromLeftForAddress}px` }}
                        >
                            <div className="dropdown-menu client">
                                {destinationAddress?.length === 0 && (
                                    <div className="no-data-content">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>
                                        <p className="no-content-message">No matching Found</p>
                                    </div>
                                )}
                                <div className="customscrollbar">
                                    {destinationAddress?.length > 0 &&
                                        destinationAddress?.map((item: any, index: number) => (
                                            <a
                                                onClick={() => {
                                                    formik.setFieldValue('destinationAddressIdInProduct', item.oa_PK);
                                                    formik.setFieldValue('destinationAddress', item.fullAddress);
                                                    formik.setFieldError('destinationAddress', undefined);
                                                }}
                                                key={index}
                                                className="menu-item app-btn"
                                            >
                                                <span className="main-content">{item.fullAddress}</span>
                                            </a>
                                        ))}
                                    {/* <a className="menu-item app-btn">
                                    9898 Laverna Vista, Rice Island, Melbourne, Australia, 3207, Victoria
                                </a>
                                <a className="menu-item app-btn">
                                    7798 Strumburn , Rice Island, Melbourne, Australia, 3207, Victoria
                                </a>
                                <a className="menu-item app-btn">
                                    98 Longnorth Shrine, Rice Island, Melbourne, Australia, 3207, Victoria
                                </a> */}
                                </div>
                            </div>
                        </div>
                        {/* <div className="dropdown-wrap destination-port-wrap outside-menu menu-down dropdown-open modal-dropdown">
                        <div className="dropdown-menu client">
                            <div className="customscrollbar">
                                <a className="menu-item app-btn">
                                    <span className="main-content">Brisbane</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Sydney</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Adelaide</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Belarus</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Hedland</span>
                                </a>
                            </div> */}
                        {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No matching Found</p>
                            </div> */}
                        {/* </div>
                    </div> */}
                        {/* <div className="dropdown-wrap countryorigin-port-wrap outside-menu menu-down dropdown-open modal-dropdown">
                        <div className="dropdown-menu client">
                            <div className="customscrollbar">
                                <a className="menu-item app-btn">
                                    <span className="main-content">Australia</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Austria</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Bangladesh</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Belarus</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">Burma</span>
                                </a>
                            </div> */}
                        {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No matching Found</p>
                            </div> */}
                        {/* </div>
                    </div> */}
                        {/* <div className="dropdown-wrap sku-port-wrap outside-menu menu-down dropdown-open modal-dropdown">
                        <div className="dropdown-menu client">
                            <div className="customscrollbar">
                                <a className="menu-item app-btn">
                                    <span className="main-content">AS112233</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">AS112233</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">AS112233</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">AS112233</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">AS112233</span>
                                </a>
                            </div> */}
                        {/* <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No matching SKU in the Product Master.</p>
                                <a className="add-content-link" href="javascript:void(0)">
                                    Add SKU to Product Master
                                </a>
                            </div> */}
                        {/* </div>
                    </div> */}
                        {/* <div className="dropdown-wrap currency-port-wrap outside-menu menu-down dropdown-open modal-dropdown"> */}
                        {/* <div className="dropdown-menu client">
                            <div className="customscrollbar">
                                <a className="menu-item app-btn">
                                    <span className="main-content">USD</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">YEN</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">INR</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">KDR</span>
                                </a>
                                <a className="menu-item app-btn">
                                    <span className="main-content">AUD</span>
                                </a>
                            </div>
                        </div> */}
                        {/* </div> */}
                    </div>
                ) : (
                    <>
                        {!multiDestination ? (
                            <div className="single-po-no-data-holder">
                                <svg className="single-po-no-icon">
                                    <use xlinkHref="#headerOnlySinglePo"></use>
                                </svg>
                                <h2 className="single-po-no-data-heading">Header Only</h2>
                                <div className="single-po-no-data-message no-content-message">
                                    This PO has been marked as Header Only with no PO lines included
                                </div>
                                {!(
                                    headerData?.poStatus?.toLowerCase() === 'awaiting_enrichment' ||
                                    headerData?.poStatus?.toLowerCase().includes('ready') ||
                                    headerData?.poStatus?.toLowerCase().includes('booked')
                                ) && (
                                    <a
                                        className="single-po-no-data-link"
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleAddPoLineRevert();
                                        }}
                                    >
                                        Add PO Lines
                                    </a>
                                )}
                            </div>
                        ) : (
                            <div className="grid-holder-destination">
                                <div className="grid-header">
                                    <div className="grid-header--wrapper">
                                        <div className="grid-header--title">Product Details</div>
                                        <p className="grid-header--caption">
                                            This PO has been marked as Header Only with no PO lines included
                                        </p>
                                    </div>
                                    <div className="add-btn-users">
                                        {headerData?.poStatus !== 'Cancelled' &&
                                            headerData?.poStatus !== 'In_Booking_Proposal' &&
                                            headerData?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                            headerData?.poStatus !== 'Booked' &&
                                            userType !== 'agent' && (
                                                <ShowForPermissions permission="edit" type="po">
                                                    {edit || add ? (
                                                        <button className="app-btn export-btn icon-button app-btn-secondary disabled">
                                                            <svg className="svg-icon plus-icon">
                                                                <use xlinkHref="#plusIcon"></use>
                                                            </svg>
                                                            <span className="button-text">Add PO Line</span>
                                                        </button>
                                                    ) : destinations?.length ? (
                                                        <></>
                                                    ) : (
                                                        <button
                                                            onClick={() => {
                                                                handleAddPoLineRevert();
                                                            }}
                                                            className="app-btn export-btn icon-button app-btn-secondary"
                                                            // disabled={destinationsToAdd}
                                                        >
                                                            <svg className="svg-icon plus-icon">
                                                                <use xlinkHref="#plusIcon"></use>
                                                            </svg>
                                                            <span className="button-text">Add PO Line</span>
                                                        </button>
                                                    )}
                                                </ShowForPermissions>
                                            )}
                                    </div>
                                </div>
                                <div className="grid-destination-card">
                                    <h4>
                                        Destinations{' '}
                                        {!(destinations?.length > 1) && (
                                            <span className="alert-msg">
                                                <svg className="svg-icon">
                                                    <use xlinkHref="#errorCautionIcon"></use>
                                                </svg>{' '}
                                                Multi Destination POs require atleast 2 destinations to continue PO
                                                submission
                                            </span>
                                        )}
                                    </h4>
                                    <div className="modal-input-layout-item">
                                        {/* dropdown menu items are outside this block, need to set its position dynamically */}
                                        {/* <input className="modal-input input-text" placeholder="Enter Destination to add" /> */}

                                        {!(
                                            headerData?.poStatus?.toLowerCase() === 'awaiting_enrichment' ||
                                            headerData?.poStatus?.toLowerCase().includes('ready') ||
                                            headerData?.poStatus?.toLowerCase().includes('booked')
                                        ) && (
                                            <div className="select-text">
                                                <Select
                                                    options={formatedPorts}
                                                    isClearable
                                                    // isDisabled={isChecked}
                                                    placeholder="Enter Destination to select"
                                                    onChange={handleAddDestination}
                                                    onBlur={() => {
                                                        setPorts([]);
                                                    }}
                                                    onInputChange={(text: string) => {
                                                        setSearchTerm(text);
                                                    }}
                                                    isLoading={unlocoLoading}
                                                    noOptionsMessage={({ inputValue }) =>
                                                        inputValue?.length < 3
                                                            ? 'Enter at least 3 characters to search'
                                                            : 'No results found'
                                                    }
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                    id={'destinationPortSkip'}
                                                    name={'destinationPortSkip'}
                                                    defaultInputValue={''}
                                                    value={null}
                                                    components={{
                                                        DropdownIndicator: () => null,
                                                        IndicatorSeparator: () => null,
                                                    }}
                                                    // styles={reactSelectStyle}
                                                />
                                            </div>
                                        )}

                                        {/* Search suggestions capsule */}
                                        <div className="suggestion-container">
                                            {destinations?.map((d: any) => (
                                                <div className="content-card">
                                                    <span className="main-content">{d?.label}</span>
                                                    {headerData?.poStatus?.toLowerCase() !== 'awaiting_enrichment' && (
                                                        <button
                                                            type="button"
                                                            className="app-btn close-btn"
                                                            title="Remove"
                                                        >
                                                            <span onClick={() => handleRemoveDestination(d?.label)}>
                                                                <svg className="svg-icon suggestion-delete-icon">
                                                                    <use href="#deleteIcon">
                                                                        <title>Remove</title>
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    )}
                                                </div>
                                            ))}
                                            {/* <div className="content-card">
                                        <span className="main-content">Auckland (NZAUC)</span>
                                        <button type="button" className="app-btn close-btn" title="Remove">
                                            <span>
                                                <svg className="svg-icon suggestion-delete-icon">
                                                    <use href="#deleteIcon">
                                                        <title>Remove</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="content-card">
                                        <span className="main-content">Longfield (USLON)</span>
                                        <button type="button" className="app-btn close-btn" title="Remove">
                                            <span>
                                                <svg className="svg-icon suggestion-delete-icon">
                                                    <use href="#deleteIcon">
                                                        <title>Remove</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                    </div> */}
                                        </div>
                                        {/* {!props.isFromView && formik.errors.ad_service_type ? (
                                    <div className="error-text">{formik.errors.ad_service_type}</div>
                                ) : null} */}
                                        {!destinations?.length && (
                                            <div className="no-data-wrapper">
                                                <div className="no-data-content">
                                                    <svg className="svg-icon grid-no-content-icon ">
                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                    </svg>
                                                    <p className="no-content-message">No Destinations added</p>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/purchase_order/${id}/dates`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <PODetailsNextButton />
                </div>
            </div>
            <ProductDetailsModal data={skuDetail} display={modalView} displayFunction={setModalView} />
            <LinkVendorModal
                sku={skuDetail?.pm_sku}
                vendor={headerData?.vendorName}
                display={modalViewVendor}
                displayFunction={setModalViewVendor}
                linkFunction={linkVendor}
                formik={formik}
            />
            <SkipPrompt
                display={triangleModeModal}
                displayFunction={setTriangleModeModal}
                skipFunction={handleSkipPOLine}
            />
            <CbmUnavailableModal
                sku={skuDetail?.pm_sku}
                display={modalViewCbm}
                displayFunction={setModalViewCbm}
                formik={formik}
                setModalViewSku={setModalViewSku}
            />
            <SkuUnavailableModal
                clientId={headerData?.clientId}
                skuUnavailableModalVisibility={modalViewSku}
                setSkuUnavailableModalVisibility={setModalViewSku}
            />
            {cancelModal && <CancelModal onConfirm={onConfirm} onCancel={onCancel} />}
            <RemovePoLine
                removeModalVisibility={removeModalVisibility}
                setRemoveModalVisibility={setRemoveModalVisibility}
                poProductId={poProductId}
            />
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
            <div style={{ zIndex: 9999 }} id="date-portal"></div>
            <UnsavedChangeModal
                unsavedChangeModalVisibility={unsavedChangeModalVisibility}
                setUnsavedChangeModalVisibility={setUnsavedChangeModalVisibility}
                callBackFn={callBackFn}
            />
        </div>
    );
};
export default Index;
