import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import {
    addToFavList,
    removeFromFav,
    resetRemoveSuccessFlag,
    resetAddSuccessFlag,
    fetchFavList,
    resetErrorFlags,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { agentTimeLineFetch, clearAgentTimeLine } from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import { fetchAgentBasicDetailsList } from 'src/store/reducers/profiles/agents/basicDetailsReducer';
import '../lcsBookingTiimeline.scss';
import MaxLimitModal from '../MaxLimitModal';
import { lifeCycleSearchCategories } from './Common/constants';
import { checkIfAddedToFav } from './Common/favoutitesUtils';

const Index: React.FC = () => {
    const { basicDetails } = useSelector((state: RootState) => state.agentBasicDetailsData);
    const { agentTimeLine, selectedData } = useSelector((state: RootState) => state.lifeCycleSearch);
    const { userName, profileType } = useUserProfile();
    const [showModal, setShowModal] = useState(false);
    const { favList, removeSuccess, addSuccess, isLoading, isError, error } = useSelector(
        (state: RootState) => state.lifecyclesearchFav
    );
    const location = useLocation() as any;
    const dispatch = useDispatch();
    const { id } = useParams() as any;

    useEffect(() => {
        if (id && location.state?.type) {
            dispatch(agentTimeLineFetch({ id: id, code: location.state?.type, profile: profileType, userName }));
        }
    }, [id]);

    useEffect(() => {
        return () => {
            dispatch(clearAgentTimeLine({}));
        };
    }, []);

    useEffect(() => {
        if (agentTimeLine?.agentID) {
            dispatch(fetchAgentBasicDetailsList({ id: agentTimeLine?.agentID }));
        }
    }, [agentTimeLine]);

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.agent,
                    favourite: 'Agent',
                    number: basicDetails?.data?.info?.ag_Org?.or_Orgname,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">Agent</div>
                <div className="timeline-head-value">{basicDetails?.data?.info?.ag_Org?.or_Orgname}</div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="cm-key-pair-details">
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Status</div>
                    <div className="cm-key-pair-value">{agentTimeLine?.status || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Tier</div>
                    <div className="cm-key-pair-value">{agentTimeLine?.tier || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Count of Active PO's</div>
                    <div className="cm-key-pair-value">{agentTimeLine?.activePO}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Count of Active Booking's</div>
                    <div className="cm-key-pair-value">{agentTimeLine?.activeBp}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">No of Users</div>
                    <div className="cm-key-pair-value">{agentTimeLine?.noOfUsers}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Last Logon date</div>
                    <div className="cm-key-pair-value">
                        {agentTimeLine?.lastLogOnDate
                            ? moment.utc(agentTimeLine?.lastLogOnDate).local().format('DD-MM-YYYY ,HH:mm')
                            : '-'}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Contracts</div>
                    <div className="cm-key-pair-value">
                        {agentTimeLine?.activeContract?.length > 0 ? (
                            agentTimeLine?.activeContract?.map((res: any) => (
                                <div className="cm-key-pair-value">
                                    {res.status} - {res.count}
                                </div>
                            ))
                        ) : (
                            <div className="cm-key-pair-value">None</div>
                        )}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Location</div>
                    <div className="cm-key-pair-value">{agentTimeLine?.location || '-'}</div>
                </div>
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`Agent : ${basicDetails?.data?.info?.ag_Org?.or_Orgname}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
