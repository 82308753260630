// import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { RootState } from '../../../store/reducers';
import {
    enquiryAddComment,
    fetchEnquiryLog,
    forwardingToClient,
    raiseAnEnquiry,
    viewedLog,
} from '../../../store/reducers/inventory/inventoryLog';
import './enquiry-log.scss';
interface propsData {
    setlogModal: any;
    logModal: any;
    payloadDis: any;
}
const Index: React.FC<propsData> = ({ setlogModal, logModal, payloadDis }) => {
    const dispatch = useDispatch();
    const { enqLog } = useSelector((state: RootState) => state.inventoryLog);
    const { isLoading } = useSelector((state: RootState) => state.inventoryList);
    const { profileType } = useUserProfile();
    const { isBlp } = useUserProfile();
    const [addComment, setaddComment] = useState(false);
    const [Comment, setComment] = useState('');
    const [enquiryComment, setenquiryComment] = useState('');
    const [enquiryCommentVisible, setenquiryCommentVisible] = useState(false);
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = userData && userData?.user;

    useEffect(() => {
        dispatch(fetchEnquiryLog({ id: logModal.id, profile: profileType }));
    }, []);

    const submitEnquiry = () => {
        const payload = {
            id: logModal.id,
            data: {
                raisedBy: username?.us_UserName,
                raisedByUserName: username?.us_FirstName + ' ' + username?.us_LastName,
                comment: enquiryComment,
            },
            payloadDis: payloadDis,
            profile: profileType,
        };
        setenquiryCommentVisible(false);
        dispatch(raiseAnEnquiry(payload));
    };

    const submitComment = () => {
        setaddComment(false);
        const payload = {
            id: logModal.id,
            data: {
                comment: Comment,
                user: username?.us_UserName,
                userName: username?.us_FirstName + ' ' + username?.us_LastName,
                profile: profileType,
            },
            payloadDis: payloadDis,
            profile: profileType,
        };
        dispatch(enquiryAddComment(payload));
        setComment('');
    };

    const forwardToClientFn = () => {
        const payload = {
            id: logModal.id,
            data: {
                user: username?.us_UserName,
                userName: username?.us_FirstName + ' ' + username?.us_LastName,
            },
            payloadDis: payloadDis,
            profile: profileType,
        };
        dispatch(forwardingToClient(payload));
    };

    const countWords = () => {
        const arr = Comment.split('');
        return arr.filter((word: string) => word !== '').length;
    };
    const EnqcountWords = () => {
        const arr = enquiryComment.split('');
        return arr.filter((word: string) => word !== '').length;
    };

    return (
        <div>
            <div className="app-modal">
                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
                <div className="modal-content-holder medium-popup enquiry-modal">
                    <div className="modal-header">
                        <div className="title">Enquiry Log</div>
                        <button
                            className="app-btn modal-close-btn"
                            onClick={() => {
                                setlogModal({ status: false, id: '' });
                                if (
                                    ((enqLog as any)?.logs?.length > 0 && profileType === 'agent') ||
                                    ((enqLog as any)?.logs?.length > 1 &&
                                        (profileType === 'forwarder' || profileType === 'client'))
                                ) {
                                    dispatch(
                                        viewedLog({
                                            id: logModal.id,
                                            payloadDis: payloadDis,
                                            profile: profileType,
                                        })
                                    );
                                }
                            }}
                        >
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use href="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="modal-content">
                        <div className="layout-holder">
                            <div className="layout-item">
                                <h6 className="heading-type-1">SKU</h6>
                                <div className="description-type-1">{(enqLog as any)?.sku}</div>
                            </div>
                            <div className="layout-item" style={{ padding: '0' }}>
                                <h6 className="heading-type-1">Description</h6>
                                <div className="description-type-1">{(enqLog as any)?.description}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Available Qty</h6>
                                <div className="description-type-1">
                                    <div className="unit-process-wrapper">
                                        <span className="first-unit">{(enqLog as any)?.existingAvailable}</span>
                                        {(enqLog as any)?.existingAvailable !== (enqLog as any)?.updatedAvailable && (
                                            <>
                                                <span className="unit-process-icon">
                                                    <svg className="svg-icon straight-arrow-pointer-icon">
                                                        <use href="#straightArrowPointerIcon">
                                                            <title>Close</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                                <span className="second-unit">{(enqLog as any)?.updatedAvailable}</span>
                                                <span
                                                    className={`third-unit ${
                                                        (enqLog as any)?.updatedAvailable -
                                                            (enqLog as any)?.existingAvailable >
                                                        0
                                                            ? 'success'
                                                            : 'error'
                                                    }`}
                                                >
                                                    {(enqLog as any)?.updatedAvailable -
                                                        (enqLog as any)?.existingAvailable >
                                                    0
                                                        ? '+'
                                                        : ''}
                                                    {(enqLog as any)?.updatedAvailable -
                                                        (enqLog as any)?.existingAvailable}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">On Hold Qty</h6>
                                <div className="description-type-1">
                                    <div className="unit-process-wrapper">
                                        <span className="first-unit">{(enqLog as any)?.existingOnHold}</span>
                                        {(enqLog as any)?.existingOnHold !== (enqLog as any)?.updatedOnHold && (
                                            <>
                                                <span className="unit-process-icon">
                                                    <svg className="svg-icon straight-arrow-pointer-icon">
                                                        <use href="#straightArrowPointerIcon">
                                                            <title>Close</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                                <span className="second-unit">{(enqLog as any)?.updatedOnHold}</span>
                                                <span
                                                    className={`third-unit ${
                                                        (enqLog as any)?.updatedOnHold -
                                                            (enqLog as any)?.existingOnHold >
                                                        0
                                                            ? 'success'
                                                            : 'error'
                                                    }`}
                                                >
                                                    {(enqLog as any)?.updatedOnHold - (enqLog as any)?.existingOnHold >
                                                    0
                                                        ? '+'
                                                        : ''}
                                                    {(enqLog as any)?.updatedOnHold - (enqLog as any)?.existingOnHold}
                                                </span>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Date Identified</h6>
                                <div className="description-type-1">{(enqLog as any)?.dateIdentified}</div>
                            </div>
                        </div>
                        {(enqLog as any)?.logs?.length === 0 && !enquiryCommentVisible ? (
                            (profileType === 'forwarder' || profileType === 'client') && (
                                <div className="no-data-content">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No enquiry has been made for this discrepancy</p>
                                    <a className="add-content-link" href="javascript:void(0)">
                                        <button
                                            className="app-btn app-btn-secondary modal-btn footer-btn"
                                            onClick={() => setenquiryCommentVisible(true)}
                                        >
                                            <span className="button-text vendor-button-text">Submit Enquiry</span>
                                        </button>
                                    </a>
                                </div>
                            )
                        ) : (
                            <div className="layout-holder-items">
                                <div className="layout-item">
                                    {(enqLog as any)?.logs?.map(
                                        (res: any) =>
                                            res?.action === 'RAISED' && (
                                                <div className="layout-field-inline">
                                                    <h6 className="description-type-1">
                                                        <span className="desc-content">{res?.user}</span>
                                                        <span className="desc-content-1">
                                                            raised an enquiry on this discrepancy
                                                        </span>
                                                        <br />
                                                        <span className="desc-date" style={{ marginLeft: '0' }}>
                                                            {res?.date}
                                                        </span>
                                                    </h6>
                                                </div>
                                            )
                                    )}
                                </div>
                                <div className="layout-item" style={{ paddingTop: '4px' }}>
                                    {(enqLog as any)?.logs?.map(
                                        (res: any) =>
                                            res?.action === 'RAISED' && (
                                                <>
                                                    <div style={{ textAlign: 'justify' }}>{res?.enquiryComment}</div>
                                                </>
                                            )
                                    )}
                                </div>
                                {enquiryCommentVisible && (
                                    <form id="productForm" action="" className="modal-form">
                                        <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                            <label htmlFor="name" className="modal-input-label">
                                                Reason
                                            </label>
                                            <div className="modal-input-field-holder">
                                                <textarea
                                                    name="reason"
                                                    className="input-text"
                                                    placeholder="Enter comment"
                                                    required
                                                    onChange={(e) => setenquiryComment(e.target.value)}
                                                    value={enquiryComment}
                                                    maxLength={250}
                                                ></textarea>
                                                {EnqcountWords() <= 250 && (
                                                    <div className="heading-type-1 file-size">
                                                        <p>{EnqcountWords()} out of 250 characters</p>
                                                    </div>
                                                )}
                                                {EnqcountWords() > 250 && (
                                                    <p className="error_message_product">
                                                        You have exceeded character limit of 250
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                                {addComment && (
                                    <form id="productForm" action="" className="modal-form">
                                        <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                            {isBlp && (
                                                <label htmlFor="name" className="modal-input-label">
                                                    {username?.us_FirstName + ' ' + username?.us_LastName}
                                                </label>
                                            )}
                                            <div className="modal-input-field-holder">
                                                <textarea
                                                    name="reason"
                                                    className="input-text"
                                                    placeholder="Enter comment"
                                                    required
                                                    onChange={(e) => setComment(e.target.value)}
                                                    value={Comment}
                                                    maxLength={250}
                                                ></textarea>
                                                {countWords() <= 250 && (
                                                    <div className="heading-type-1 file-size">
                                                        <p>{countWords()} out of 250 characters</p>
                                                    </div>
                                                )}
                                                {countWords() > 250 && (
                                                    <p className="error_message_product">
                                                        You have exceeded character limit of 250
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    </form>
                                )}
                                {profileType === 'client' && (enqLog as any)?.logs?.length !== 3 ? (
                                    ''
                                ) : (
                                    <>
                                        {(enqLog as any)?.logs?.length === 3 && (
                                            <div className="forwareded-icon-heading">
                                                <span className="icon-holder">
                                                    <svg className="svg-icon forwarded-icon">
                                                        <use href="#forwardedIcon">
                                                            <title>Close</title>
                                                        </use>
                                                    </svg>
                                                </span>
                                                Forwarded to client
                                            </div>
                                        )}
                                        {(enqLog as any)?.logs?.length > 1 &&
                                            (enqLog as any)?.logs?.map(
                                                (res: any) =>
                                                    res?.action === 'RESPONDED' &&
                                                    !addComment && (
                                                        <div
                                                            className="layout-item"
                                                            style={{
                                                                paddingTop:
                                                                    (enqLog as any)?.logs?.length !== 3 ? '12px' : '',
                                                            }}
                                                        >
                                                            <div className="layout-field-inline">
                                                                <h6 className="description-type-1">
                                                                    <span className="desc-content">{res?.user}</span>
                                                                    <span className="desc-content-1">
                                                                        added a comment
                                                                    </span>
                                                                    <span className="desc-date">{res?.date}</span>
                                                                </h6>
                                                            </div>
                                                            <div style={{ textAlign: 'justify' }}>{res?.comment}</div>
                                                        </div>
                                                    )
                                            )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                    {(enqLog as any)?.logs?.length > 1 &&
                        profileType === 'forwarder' &&
                        !addComment &&
                        (enqLog as any)?.logs?.length !== 3 && (
                            <div className="modal-footer">
                                <button
                                    className="app-btn app-btn-secondary modal-btn footer-btn"
                                    onClick={() => forwardToClientFn()}
                                >
                                    <span className="button-text vendor-button-text">Forward to Client</span>
                                </button>
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn"
                                    onClick={() => {
                                        setaddComment(true);
                                        const newComment = (enqLog as any)?.logs[1]?.comment;
                                        setComment(newComment);
                                    }}
                                >
                                    <span className="button-text vendor-button-text">Edit Comment</span>
                                </button>
                            </div>
                        )}
                    {isBlp && (enqLog as any)?.logs?.length === 1 && (
                        <>
                            {!addComment ? (
                                <div className="modal-footer">
                                    <button
                                        className="app-btn app-btn-primary modal-btn footer-btn"
                                        onClick={() => setaddComment(true)}
                                    >
                                        <span className="button-text vendor-button-text">Add Comment</span>
                                    </button>
                                </div>
                            ) : (
                                <div className="modal-footer">
                                    <button
                                        className="app-btn app-btn-secondary modal-btn footer-btn"
                                        onClick={() => setaddComment(false)}
                                    >
                                        <span className="button-text vendor-button-text">Cancel</span>
                                    </button>
                                    <button
                                        className={`app-btn app-btn-primary modal-btn footer-btn ${
                                            Comment?.length === 0 ? 'disabled' : ''
                                        }`}
                                        onClick={() => Comment?.length > 0 && submitComment()}
                                    >
                                        <span className="button-text vendor-button-text">Save</span>
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    {profileType === 'forwarder' && addComment && (
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => setaddComment(false)}
                            >
                                <span className="button-text vendor-button-text">Cancel</span>
                            </button>
                            <button
                                className={`app-btn app-btn-primary modal-btn footer-btn ${
                                    Comment?.length === 0 || countWords() > 250 ? 'disabled' : ''
                                }`}
                                onClick={() => Comment?.length > 0 && countWords() <= 250 && submitComment()}
                            >
                                <span className="button-text vendor-button-text">Save</span>
                            </button>
                        </div>
                    )}
                    {(profileType === 'forwarder' || profileType === 'client') && enquiryCommentVisible && (
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => setenquiryCommentVisible(false)}
                            >
                                <span className="button-text vendor-button-text">Cancel</span>
                            </button>
                            <button
                                className={`app-btn app-btn-primary modal-btn footer-btn ${
                                    enquiryComment?.length === 0 || EnqcountWords() > 250 ? 'disabled' : ''
                                }`}
                                onClick={() => enquiryComment?.length > 0 && EnqcountWords() <= 250 && submitEnquiry()}
                            >
                                <span className="button-text vendor-button-text">Save</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export default Index;
