import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get } from '../HttpMethods';

export interface countData {
    agent: {
        inactive: number;
        active: number;
        total: number;
    };
    shipping: {
        inactive: number;
        active: number;
        total: number;
    };
    vendor: {
        inactive: number;
        active: number;
        total: number;
        draft: number;
        submitted: number;
    };
    client: {
        inactive: number;
        active: number;
        total: number;
    };
    coloader: {
        inactive: number;
        active: number;
        total: number;
        draft: number;
        submitted: number;
    };
}

// export const getCountData = async (payload: any) => {
//     try {
//         const res = await get('api/mgt/statuscount', payload ?? null);
//         return res.info;
//     } catch (err) {
//         return console.error(err);
//     }
// };

export const getCountData = async (payload: any) => {
    try {
        // const res = await post(`api/mgt/user/count?profile=${payload.profileType}`, payload.data, null);

        const result: countData = {
            agent: {
                inactive: 0,
                active: 0,
                total: 0,
            },
            shipping: {
                inactive: 0,
                active: 0,
                total: 0,
            },
            vendor: {
                inactive: 0,
                active: 0,
                total: 0,
                draft: 0,
                submitted: 0,
            },
            client: {
                inactive: 0,
                active: 0,
                total: 0,
            },
            coloader: {
                inactive: 0,
                active: 0,
                total: 0,
                draft: 0,
                submitted: 0,
            },
        };

        const activeCltResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeCltRes = await get(
                    `/cix/scr/admin/organisation/clients?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=CL_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.client.active = activeCltRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const inactiveCltResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveCltRes = await get(
                    `/cix/scr/admin/organisation/clients?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=CL_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.client.inactive = inactiveCltRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const subVendorResPromise = new Promise(async (resolve, reject) => {
            try {
                const subVendorRes = await get(
                    `/cix/scr/admin/organisation/vendors?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=VN_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=SUBMITTED&$count`,
                    null,
                    externalBaseUrl
                );
                result.vendor.submitted = subVendorRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const draftVendorResPromise = new Promise(async (resolve, reject) => {
            try {
                const draftVendorRes = await get(
                    `/cix/scr/admin/organisation/vendors?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=VN_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=DRAFT&$count`,
                    null,
                    externalBaseUrl
                );
                result.vendor.draft = draftVendorRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const activeAgentResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeAgentRes = await get(
                    `/cix/scr/admin/organisation/agents?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=AG_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.agent.active = activeAgentRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const inactiveAgentResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveAgentRes = await get(
                    `/cix/scr/admin/organisation/agents?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=AG_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.agent.inactive = inactiveAgentRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const activeCarrierResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeCarrierRes = await get(
                    `/cix/scr/admin/organisation/carriers?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=SL_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.shipping.active = activeCarrierRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const inactiveCarrierResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveCarrierRes = await get(
                    `/cix/scr/admin/organisation/carriers?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=SL_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.shipping.inactive = inactiveCarrierRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const activeColoaderResPromise = new Promise(async (resolve, reject) => {
            try {
                const activeColoaderRes = await get(
                    `/cix/scr/admin/organisation/coloaders?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=SL_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=ACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.coloader.active = activeColoaderRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        const inactiveColoaderResPromise = new Promise(async (resolve, reject) => {
            try {
                const inactiveColoaderRes = await get(
                    `/cix/scr/admin/organisation/coloaders?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B0%5D%5Bfield%5D=SL_Status&filter%5Bfilters%5D%5B0%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B0%5D%5Bvalue%5D=INACTIVE&$count`,
                    null,
                    externalBaseUrl
                );
                result.coloader.inactive = inactiveColoaderRes.total;
                resolve(1);
            } catch (e) {
                reject(0);
            }
        });

        await Promise.all([
            activeCltResPromise,
            inactiveCltResPromise,
            subVendorResPromise,
            draftVendorResPromise,
            activeAgentResPromise,
            inactiveAgentResPromise,
            activeCarrierResPromise,
            inactiveCarrierResPromise,
            activeColoaderResPromise,
            inactiveColoaderResPromise,
        ]);

        result.client.total = result.client.active + result.client.inactive;
        result.vendor.total = result.vendor.submitted + result.vendor.draft;
        result.agent.total = result.agent.active + result.agent.inactive;
        result.shipping.total = result.shipping.active + result.shipping.inactive;
        result.coloader.total = result.coloader.active + result.coloader.inactive;

        return result;
    } catch (err) {
        return console.error(err);
    }
};
