import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    fetchThresholdValue,
    isLoadingFn,
    setUpdateSuccess,
    setUpdateError,
    updateThresholdValue,
} from 'src/store/reducers/settings';

import {
    getThresholdValue,
    updateThresholdValue as updateThresholdApi,
    UpdateThresholdPayload,
} from 'src/services/apis/settingsApi';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

function* fetchThreshold({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getThresholdValue, payload);

        payload['threshold'] = response;

        yield put({ type: fetchThresholdValue, payload });
    } catch (error) {
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* updateThreshold({ payload }: { payload: UpdateThresholdPayload; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateThresholdApi, payload);
        yield put({ type: updateThresholdValue, payload: response });
        yield put({ type: setUpdateSuccess, payload: true });
    } catch (error) {
        yield put({ type: setUpdateError, payload: true });
    } finally {
        yield put(hideLoading());
    }
}

function* settingsSaga() {
    yield takeEvery(fetchThresholdValue, fetchThreshold);
    yield takeLatest(updateThresholdValue, updateThreshold);
}
export default settingsSaga;
