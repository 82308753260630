import React, { useRef } from 'react';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';

interface FilterProps {
    setPosition: any;
    searchName: string;
    setSearchName: any;
    filter: any;
    data: any;
    statusFilterSelected: any;
    setStatusFilterSelected: any;
    isLoading: any;
    setToggleDropdown?: any;
    columnName?: any;
    currentColumnName?: any;
    setCurrentColumnName?: any;
}

const ColumnFilter: React.FC<FilterProps> = (props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, props.setToggleDropdown);
    const searchDataFn = () => {
        const searchKey = props.searchName.toLocaleLowerCase();
        const duplicate = props.data?.length ? JSON.parse(JSON.stringify(props.data)) : [];
        const searchResult = duplicate?.filter((el: any) => {
            return el.toLocaleLowerCase().includes(searchKey);
        });
        return searchResult;
    };
    const searchData = searchDataFn();

    return (
        <div
            ref={wrapperRef}
            style={{ left: String(props.setPosition) + 'px' }}
            className="filter-menu searchable-dropdown"
        >
            <div className="search-entry-holder">
                <input
                    placeholder="Search"
                    type="text"
                    className="search-input input-text grid-search-field"
                    value={props.searchName}
                    onChange={props.filter}
                ></input>
                <svg className="svg-icon search-icon">
                    <use xlinkHref="#searchIcon"></use>
                </svg>
                <button
                    onClick={(e) => {
                        props.setSearchName('');
                        props.filter(e);
                    }}
                    className="app-btn text-close-btn"
                >
                    <svg className="svg-icon text-close-icon">
                        <use xlinkHref="#closeIcon"></use>
                    </svg>
                </button>
            </div>
            <div className="checkbox-wrapper">
                {searchData.map((value: string, index: number) => (
                    <div key={index} className="checkbox-item">
                        <label className="app-check-wrapper" style={{ width: 'auto' }}>
                            <input
                                onChange={(e: any) => {
                                    props.statusFilterSelected.filter((item: string, index: number) => {
                                        if (props.statusFilterSelected.indexOf(item) == index) {
                                            return item;
                                        }
                                    });
                                    // eslint-disable-next-line no-undefined
                                    if (props?.currentColumnName === undefined) {
                                        if (e.target.checked) {
                                            props.setStatusFilterSelected([...props.statusFilterSelected, value]);
                                        } else {
                                            props.setStatusFilterSelected(
                                                props.statusFilterSelected.filter((item: string) => item != value)
                                            );
                                        }
                                    }
                                    if (props?.currentColumnName === '') {
                                        props?.setCurrentColumnName(props?.columnName);
                                        props.setStatusFilterSelected([value]);
                                    } else if (props?.currentColumnName === props?.columnName) {
                                        if (e.target.checked) {
                                            props.setStatusFilterSelected([...props.statusFilterSelected, value]);
                                        } else {
                                            props.setStatusFilterSelected(
                                                props.statusFilterSelected.filter((item: string) => item != value)
                                            );
                                        }
                                    } else {
                                        if (e.target.checked) {
                                            props.setStatusFilterSelected([value]);
                                        } else {
                                            props.setStatusFilterSelected(
                                                props.statusFilterSelected.filter((item: string) => item != value)
                                            );
                                        }
                                        props?.setCurrentColumnName(props?.columnName);
                                    }

                                    // props.setToggleDropdown(false);
                                }}
                                // defaultChecked={false}
                                checked={props.statusFilterSelected.includes(value)}
                                type="checkbox"
                                className="checkbox-input"
                            />
                            <div className="checkmark">
                                <svg className="svg-icon tick-icon">
                                    <use xlinkHref="#tickIcon">
                                        <title>check mark</title>
                                    </use>
                                </svg>
                            </div>
                            <div style={{ wordBreak: 'initial' }} className="checkbox-label">
                                {value}
                            </div>
                        </label>
                    </div>
                ))}
                {searchData?.length === 0 && !props.isLoading && (
                    <div className="no-data-content">
                        <svg className="svg-icon grid-no-content-icon ">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">
                            {props.searchName === '' ? 'No Data Found' : 'No Matches Found'}
                        </p>
                    </div>
                )}
            </div>
            {props.data?.length > 0 && (
                <div className="clear-btn">
                    <button
                        onClick={() => {
                            props.setStatusFilterSelected([]);
                        }}
                        style={{ textDecoration: 'underline' }}
                        className="app-btn"
                    >
                        <span className="button-text">Clear All</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default ColumnFilter;
