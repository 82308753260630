export const reassignAgentPoDetails = [
    {
        columnName: 'PO NUMBER',
        name: 'poNumber',
        headerClass: 'grid-cell-header col-po-number grid-sticky-column sticky-left-aligned',
        hasSort: true,
        sortName: 'poNumber',
        hasFilter: true,
        filterName: 'poNumber',
    },
    {
        columnName: 'STATUS',
        name: 'poStatus',
        headerClass: 'grid-cell-header col-status',
        hasSort: false,
        sortName: 'poStatus',
        hasFilter: false,
        filterName: 'poStatus',
    },
    {
        columnName: 'AGENT',
        name: 'agentName',
        headerClass: 'grid-cell-header col-agent',
        hasSort: true,
        sortName: 'agentName',
        hasFilter: true,
        filterName: 'agentName',
    },
    {
        columnName: 'CLIENT',
        name: 'clientName',
        headerClass: 'grid-cell-header col-client',
        hasSort: true,
        sortName: 'clientName',
        hasFilter: true,
        filterName: 'clientName',
    },
    {
        columnName: 'ORIGIN',
        name: 'originPort',
        headerClass: 'grid-cell-header col-origin',
        hasSort: true,
        sortName: 'originPort',
        hasFilter: true,
        filterName: 'originPort',
    },
    {
        columnName: 'DESTINATION',
        name: 'destinationPort',
        headerClass: 'grid-cell-header col-destination',
        hasSort: true,
        sortName: 'destinationPort',
        hasFilter: true,
        filterName: 'destinationPort',
    },
    {
        columnName: 'ORDER DATE',
        name: 'poOrderDate',
        headerClass: 'grid-cell-header col-order-date',
        hasSort: true,
        sortName: 'poOrderDate',
        hasFilter: true,
        filterName: 'poOrderDate',
    },
    {
        columnName: 'SHIPPING MODE',
        name: 'shippingmode',
        headerClass: 'grid-cell-header col-shipping-mode',
        hasSort: true,
        sortName: 'shippingmode',
        hasFilter: true,
        filterName: 'shippingmode',
    },
    {
        columnName: 'VENDOR',
        name: 'vendorName',
        headerClass: 'grid-cell-header col-vendor',
        hasSort: true,
        sortName: 'vendorName',
        hasFilter: true,
        filterName: 'vendorName',
    },
    {
        columnName: 'DESTINATION TYPE',
        name: 'destinationType',
        headerClass: 'grid-cell-header col-destination-type',
        hasSort: true,
        sortName: 'destinationType',
        hasFilter: true,
        filterName: 'destinationType',
    },
    {
        columnName: 'RELEASE TYPE',
        name: 'releaseType',
        headerClass: 'grid-cell-header col-incoterm',
        hasSort: true,
        sortName: 'releaseType',
        hasFilter: true,
        filterName: 'releaseType',
    },
    {
        columnName: 'CREATED BY',
        name: 'createdBy',
        headerClass: 'grid-cell-header col-submitted-by',
        hasSort: true,
        sortName: 'createdBy',
        hasFilter: true,
        filterName: 'createdBy',
    },
];
