import React, { useRef } from 'react';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';

interface FilterProps {
    setPosition: any;
    searchName: string;
    setSearchName: any;
    filter: any;
    data: any;
    statusFilterSelected: any;
    setStatusFilterSelected: any;
    isLoading: any;
    setToggleDropdown?: any;
    columnName?: any;
    currentColumnName?: any;
    setCurrentColumnName?: any;
}

const ColumnFilter: React.FC<FilterProps> = (props) => {
    const wrapperRef = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRef, props.setToggleDropdown);
    const searchDataFn = () => {
        const searchKey = props.searchName.toLocaleLowerCase();
        const duplicate = props.data?.length ? JSON.parse(JSON.stringify(props.data)) : [];
        const searchResult = duplicate?.filter((el: any) => {
            return el && el.toLocaleLowerCase().includes(searchKey);
        });
        return searchResult;
    };
    const searchData = searchDataFn();

    const isChecked = (value: string) => {
        return Array.isArray(props.statusFilterSelected[props.currentColumnName]) &&
            props.statusFilterSelected[props.currentColumnName]?.includes(value)
            ? true
            : false;
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
        // if (props.currentColumnName === 'dateAndTime') {
        //     value = moment(value, 'DD-MM-YYYY').format('YYYY-MM-DD');
        // }

        const copiedValue = props.statusFilterSelected ? JSON.parse(JSON.stringify(props.statusFilterSelected)) : {};
        if (e.target.checked) {
            if (copiedValue[props?.currentColumnName]) {
                copiedValue[props.currentColumnName] = [...copiedValue[props.currentColumnName], value];
            } else {
                copiedValue[props?.currentColumnName] = [value];
            }
            props.setStatusFilterSelected(copiedValue);
        } else {
            const newValue = copiedValue[props.currentColumnName]?.filter((item: string) => item != value);
            if (newValue?.length) {
                copiedValue[props?.currentColumnName] = newValue;
            } else {
                delete copiedValue[props?.currentColumnName];
            }
            props.setStatusFilterSelected(copiedValue);
        }
    };

    return (
        <div
            ref={wrapperRef}
            style={{ left: String(props.setPosition) + 'px', zIndex: 11 }}
            className="filter-menu searchable-dropdown"
        >
            <div className="search-entry-holder">
                <input
                    placeholder="Search"
                    type="text"
                    className="search-input input-text grid-search-field"
                    value={props.searchName}
                    onChange={props.filter}
                ></input>
                <svg className="svg-icon search-icon">
                    <use xlinkHref="#searchIcon"></use>
                </svg>
                <button
                    onClick={(e) => {
                        props.setSearchName('');
                        props.filter(e);
                    }}
                    className="app-btn text-close-btn"
                >
                    <svg className="svg-icon text-close-icon">
                        <use xlinkHref="#closeIcon"></use>
                    </svg>
                </button>
            </div>
            <div className="checkbox-wrapper">
                {searchData.map((value: string, index: number) => (
                    <div key={index} className="checkbox-item">
                        <label className="app-check-wrapper" style={{ width: 'auto' }}>
                            <input
                                onChange={(e) => onChange(e, value)}
                                checked={isChecked(value)}
                                type="checkbox"
                                className="checkbox-input"
                            />
                            <div className="checkmark">
                                <svg className="svg-icon tick-icon">
                                    <use xlinkHref="#tickIcon">
                                        <title>check mark</title>
                                    </use>
                                </svg>
                            </div>
                            <div style={{ wordBreak: 'initial' }} className="checkbox-label">
                                {value}
                            </div>
                        </label>
                    </div>
                ))}
                {searchData?.length === 0 && !props.isLoading && (
                    <div className="no-data-content">
                        <svg className="svg-icon grid-no-content-icon ">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">
                            {props.searchName === '' ? 'No Data Found' : 'No Matches Found'}
                        </p>
                    </div>
                )}
            </div>
            {props.data?.length > 0 && (
                <div className="clear-btn">
                    <button
                        onClick={() => {
                            const copiedValue = props.statusFilterSelected
                                ? JSON.parse(JSON.stringify(props.statusFilterSelected))
                                : {};
                            if (copiedValue[props?.currentColumnName]) {
                                delete copiedValue[props?.currentColumnName];
                                props.setStatusFilterSelected(copiedValue);
                            }
                        }}
                        style={{ textDecoration: 'underline', color: '#2028eb' }}
                        className="app-btn"
                    >
                        <span className="button-text">Clear All</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default ColumnFilter;
