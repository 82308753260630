import React, { useEffect, useState } from 'react';
import BookingProposalDetailsHeader from '../BookingProposalDetailsHeader';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../BookingProposal/POSelection.scss';
import '../../BookingProposal/BookLineDetails/BookingDetailsGrid.scss';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../hooks';
import {
    fetchAvailablePoLineData,
    searchAvailablePoLineData,
    setCgId,
} from '../../../store/reducers/bookingProposalLcl/availablePoLineReducer';
import SelectableGrid from '../../../components/common/selectableGrid/index';
import BookingHeaderSidebar from '../BookingHeaderSidebar';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const { bpNumber, isLoading } = useSelector((state: RootState) => state.bpHeaderData);
    const { availablePoLineList, cgId, isHeaderOnlyPo } = useSelector((state: RootState) => state.availablePoLinesLcl);
    const cgID = localStorage.getItem('cgId');
    const [searchText, setSearchText] = useState('');

    useEffect(() => {
        dispatch(fetchAvailablePoLineData({ cgId: cgID }));
    }, [cgID]);

    useEffect(() => {
        if (searchText?.trim()?.length > 1) {
            dispatch(searchAvailablePoLineData({ cgId: cgID, keyword: searchText }));
        } else if (searchText?.trim()?.length === 0) {
            dispatch(fetchAvailablePoLineData({ cgId: cgID }));
        }
    }, [searchText]);

    useEffect(() => {
        if (cgID) {
            dispatch(setCgId({ cgId }));
        }
    }, []);

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder medium-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={id ? 'Booking Proposal Details' : 'New Booking Proposal'}
                    secondUrl={id ? `/lclbooking_list/${id}/POSelection` : '/lclbooking_list/POSelection'}
                    thirdName={'PO Line Details'}
                    thirdUrl={'/LCLBookingProposal/POSelectionDetails'}
                    backUrl={id ? `/lclbooking_list/${id}/POSelection` : '/lclbooking_list/POSelection'}
                />
                <BookingProposalDetailsHeader
                    setShowBookingSideBar={setShowBookingSideBar}
                    id={bpNumber ? bpNumber : ''}
                ></BookingProposalDetailsHeader>
            </div>
            <div
                className={`content-section main-wrapper ${
                    isHeaderOnlyPo ? 'po-selection-grid__bpdetails' : 'po-selection-grid'
                }`}
            >
                <div className="main-wrapper">
                    {isHeaderOnlyPo ? (
                        <span className="alert-msg">
                            <svg className="svg-icon">
                                <use xlinkHref="#errorCautionIcon"></use>
                            </svg>
                            This PO has been marked as Header Only with no PO lines included
                        </span>
                    ) : (
                        <div className="tab-with-action">
                            <div className="tab-with-search">
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search"
                                        type="text"
                                        value={searchText}
                                        className="search-input input-text"
                                        onChange={(e: any) => {
                                            setSearchText(e.target.value);
                                        }}
                                    ></input>
                                    <svg className="svg-icon search-icon">
                                        <use xlinkHref="#searchIcon"></use>
                                    </svg>
                                    <button
                                        className="app-btn text-close-btn"
                                        onClick={() => {
                                            setSearchText('');
                                        }}
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="main-content-holder">
                        <div
                            className={`grid-section ${
                                isHeaderOnlyPo ? 'grid-bookpo-booking' : 'grid-details-booking'
                            }`}
                        >
                            {isHeaderOnlyPo && !isLoading ? (
                                <SelectableGrid tabName="poLinesHeaderOnly" data={availablePoLineList} />
                            ) : null}
                            {!isHeaderOnlyPo && !isLoading ? (
                                <SelectableGrid tabName="lclpoLines" data={availablePoLineList} />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row"></div>
            {showBookingSideBar && (
                <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
            )}
        </div>
    );
};
export default Index;
