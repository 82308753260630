import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { filterValues, gridDetails } from 'src/utils/kendoGrid/vendor';
import '../lcsBookingDetails.scss';
// import ScheduleTab from '../ScheduleTab';
import BasicDetailsTab from '../VendorBasicDetailsTab';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { useSelector } from 'react-redux';

const baseURL = process.env.REACT_APP_BASE_URL;
const Index: React.FC = () => {
    const [lcsTab, setLcsTab] = useState('Basic Details');
    const navigate = useNavigate();
    const { id } = useParams() as any;
    // const location: any = useLocation();
    // const { name } = location.state || {};
    const { vendorDetails: vendorData } = useSelector((state: any) => state.lifeCycleSearch);
    const { userName } = useUserProfile();
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

    const purchaseOrderItem = gridDetails.filter((res) => res.name === 'vendor-purchaseorder')[0];
    const bookingItem = gridDetails.filter((res) => res.name === 'vendor-bookings')[0];

    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };

    useEffect(() => {
        purchaseOrderItem.url = `${baseURL}/api/ord/tracking/lifecycle/vendorData/podetails?orgID=${id}`;
        bookingItem.url = `${baseURL}/api/bkg/tracking/lifecycle/vendorData/bpdetails?orgID=${id}`;
    }, [lcsTab, id]);

    const Btn: any = () => {
        return (
            <div className="head-btn-holder">
                <button
                    className="app-btn app-btn-secondary"
                    title="Go to Profile"
                    onClick={() => {
                        if (vendorData?.vendorID) {
                            navigate(`/profile/vendor/${vendorData?.vendorID}/basic_detail`);
                        }
                    }}
                >
                    <span className="button-text">Go to Profile</span>
                </button>
            </div>
        );
    };
    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${lcsTab === 'Basic Details' ? 'active' : ''}`}
                        onClick={() => setLcsView('Basic Details')}
                    >
                        Basic Details
                    </a>
                    <a className={`tab-nav ${lcsTab === 'po' ? 'active' : ''}`} onClick={() => setLcsView('po')}>
                        Purchase Orders
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'bookings' ? 'active' : ''}`}
                        onClick={() => setLcsView('bookings')}
                    >
                        Bookings
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content vendor-detail-tab">
                {lcsTab === 'Basic Details' && <BasicDetailsTab Btn={Btn} />}
                {lcsTab === 'po' && (
                    <KendoGridJquery
                        gridItem={purchaseOrderItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'bookings' && (
                    <KendoGridJquery
                        gridItem={bookingItem}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
