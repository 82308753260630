import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    saveSellQuote,
    saveSellQuoteFailure,
    saveSellQuoteSuccess,
    updateSellQuote,
    updateSellQuoteFailure,
    updateSellQuoteSuccess,
    selectSchedule,
    selectScheduleFailure,
    selectScheduleSuccess,
} from '../../reducers/bookingProposalAir/flightScheduleReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    saveSellQuoteDetailsApi,
    updateSellQuoteDetailsApi,
    selectScheduleDetailsApi,
} from '../../../services/apis/bookingProposalAir/flightScheduleApi';

// eslint-disable-next-line
function* saveSellQuoteData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(saveSellQuoteDetailsApi, payload);
        yield put({ type: saveSellQuoteSuccess, payload: response });
    } catch (error) {
        yield put({ type: saveSellQuoteFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* updateSellQuoteData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(updateSellQuoteDetailsApi, payload);
        yield put({ type: updateSellQuoteSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateSellQuoteFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* selectScheduleData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(selectScheduleDetailsApi, payload);
        yield put({ type: selectScheduleSuccess, payload: response });
    } catch (error) {
        yield put({ type: selectScheduleFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* FlightScheduleSaga() {
    yield takeEvery(saveSellQuote, saveSellQuoteData);
    yield takeEvery(updateSellQuote, updateSellQuoteData);
    yield takeEvery(selectSchedule, selectScheduleData);
}

export default FlightScheduleSaga;
