/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';

export interface ContractDetailsDocumentsDtoList {
    sd_docname: string;
    sd_version: string;
    sd_author: string;
    sd_forwardersignatory: string;
    sd_coloaderSignatory: string;
    sd_startdate: string;
    sd_enddate: string;
    sd_renewal: string;
}
export interface ContractDetailsDataDetails {
    coloaderDocumentResponseList: ContractDetailsDocumentsDtoList[];
    totalActiveDocuments: number;
}

interface ContractDetailsData {
    status: number;
    message: string;
    info: ContractDetailsDataDetails;
    data: any;
}

interface ContractDetailsColumnData {
    status: number;
    message: string;
    info: string[];
    columnName?: string;
    data: any;
}

interface ContractDetailsState {
    isLoading: boolean;
    contractDetails?: ContractDetailsData;
    contractDetailsColumn?: ContractDetailsColumnData;
    contractDetailsSuccess: any;
    contractDetailsError: any;
    contractDetailsAuthor: any;
    contractDetailsForwarderUser: any;
    contractDetailsAgentUser: any;
    contractDetailsAgentUsers: any;
    contractDetailsIncoTerms: any;
    contractDetailsPort: any;
    contractDetailsAllClient: any;
    isError?: boolean;
    id?: number;
    currentPage: number;
    columnStatus: string;
    perPageCount: number;
    totalNoOfPages: number;
    totalNoOfItems: number;
}

const initialState: ContractDetailsState = {
    isLoading: true,
    contractDetails: undefined,
    contractDetailsColumn: undefined,
    contractDetailsSuccess: undefined,
    contractDetailsError: undefined,
    contractDetailsAuthor: undefined,
    contractDetailsForwarderUser: undefined,
    contractDetailsAgentUser: undefined,
    contractDetailsAgentUsers: undefined,
    contractDetailsIncoTerms: undefined,
    contractDetailsPort: undefined,
    contractDetailsAllClient: undefined,
    isError: false,
    id: undefined,
    currentPage: 0,
    columnStatus: '',
    perPageCount: 10,
    totalNoOfPages: 1,
    totalNoOfItems: 0,
};

export const coloaderContractDetailsSlice = createSlice({
    name: 'coloaderContractDetails',
    initialState,
    reducers: {
        fetchContractDetailsList: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload?.firstLoad ? 0 : action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.id = action.payload.id;
        },
        fetchContractDetailsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.contractDetails = action.payload;
            state.totalNoOfPages = action.payload?.data?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.data?.info?.totalNoOfItems;
        },
        fetchContractDetailsListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.contractDetails = action.payload;
        },
        fetchContractDetailsColumnList: (state, _action) => {
            state.isLoading = true;
        },
        fetchContractDetailsColumnListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.contractDetailsColumn = action.payload;
        },
        fetchContractDetailsColumnListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.contractDetailsColumn = action.payload;
        },
        createContractDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        createContractDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.contractDetailsSuccess = action.payload;
            state.contractDetailsError = {};
        },
        createContractDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.contractDetailsSuccess = {};
            state.contractDetailsError = action.payload;
        },
        updateContractDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateContractDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.contractDetailsSuccess = action.payload;
            state.contractDetailsError = {};
        },
        updateContractDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.contractDetailsSuccess = {};
            state.contractDetailsError = action.payload;
        },
        fetchAuthorDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAuthorDetailsSuccess(state, action) {
            state.isError = false;
            state.contractDetailsAuthor = action.payload;
        },
        fetchAuthorDetailsFailure(state, action) {
            state.isError = true;
            state.contractDetailsError = action.payload;
        },
        fetchForwarderUserDetails: (state, _action) => {
            state.isError = false;
        },
        fetchForwarderUserDetailsSuccess(state, action) {
            state.isError = false;
            state.contractDetailsForwarderUser = action.payload;
        },
        fetchForwarderUserDetailsFailure(state, action) {
            state.isError = true;
            state.contractDetailsError = action.payload;
        },

        fetchAgentUserDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAgentUserDetailsSuccess(state, action) {
            state.isError = false;
            state.contractDetailsAgentUser = action.payload;
        },
        fetchAgentUserDetailsFailure(state, action) {
            state.isError = true;
            state.contractDetailsError = action.payload;
        },
        fetchAgentUsersDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAgentUsersDetailsSuccess(state, action) {
            state.isError = false;
            state.contractDetailsAgentUsers = action.payload;
        },
        fetchAgentUsersDetailsFailure(state, action) {
            state.isError = true;
            state.contractDetailsAgentUsers = action.payload;
        },
        fetchIncoTermsDetails: (state, _action) => {
            state.isError = false;
        },
        fetchIncoTermsDetailsSuccess(state, action) {
            state.isError = false;
            state.contractDetailsIncoTerms = action.payload;
        },
        fetchIncoTermsDetailsFailure(state, action) {
            state.isError = true;
            state.contractDetailsIncoTerms = action.payload;
        },
        fetchPortDetails: (state, _action) => {
            state.isError = false;
        },
        fetchPortDetailsSuccess(state, action) {
            state.isError = false;
            state.contractDetailsPort = action.payload;
        },
        fetchPortDetailsFailure(state, action) {
            state.isError = true;
            state.contractDetailsPort = action.payload;
        },
        fetchAllClientsDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAllClientsDetailsSuccess(state, action) {
            state.isError = false;
            state.contractDetailsAllClient = action.payload;
        },
        fetchAllClientsDetailsFailure(state, action) {
            state.isError = true;
            state.contractDetailsAllClient = action.payload;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearContractDetails: (state, _action) => {
            state.contractDetails = undefined;
        },
        clearFailure: (state, _action) => {
            state.contractDetailsError = undefined;
            state.contractDetailsSuccess = undefined;

            state.isError = false;
        },
        searchContractDetailsData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
        },
        searchContractDetailsDataSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.contractDetails = action.payload;
            state.totalNoOfPages = action.payload?.data?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.data?.info?.totalNoOfItems;
        },
        searchContractDetailsDataFailure: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.contractDetails = action.payload;
        },
    },
});

export const {
    fetchContractDetailsList,
    fetchContractDetailsListSuccess,
    fetchContractDetailsListFailed,
    fetchContractDetailsColumnList,
    fetchContractDetailsColumnListSuccess,
    fetchContractDetailsColumnListFailed,
    createContractDetails,
    createContractDetailsSuccess,
    createContractDetailsFailure,
    updateContractDetails,
    updateContractDetailsSuccess,
    updateContractDetailsFailure,
    fetchAuthorDetails,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    fetchForwarderUserDetails,
    fetchForwarderUserDetailsSuccess,
    fetchForwarderUserDetailsFailure,
    fetchAgentUserDetails,
    fetchAgentUserDetailsSuccess,
    fetchAgentUserDetailsFailure,
    fetchAgentUsersDetails,
    fetchAgentUsersDetailsSuccess,
    fetchAgentUsersDetailsFailure,
    fetchIncoTermsDetails,
    fetchIncoTermsDetailsSuccess,
    fetchIncoTermsDetailsFailure,
    fetchPortDetails,
    fetchPortDetailsSuccess,
    fetchPortDetailsFailure,
    fetchAllClientsDetails,
    fetchAllClientsDetailsSuccess,
    fetchAllClientsDetailsFailure,
    isLoadingFn,
    clearContractDetails,
    clearFailure,
    searchContractDetailsData,
    searchContractDetailsDataSuccess,
    searchContractDetailsDataFailure,
} = coloaderContractDetailsSlice.actions;

export const selectContractDetailLoading = (state: RootState) => state.coloaderContractDetailsData.isLoading;
export const selectContractDetailUser = (state: RootState) => state.coloaderContractDetailsData.contractDetails;
export const selectContractDetailColumnUser = (state: RootState) =>
    state.coloaderContractDetailsData.contractDetailsColumn;
export const selectContractDetailSuccess = (state: RootState) =>
    state.coloaderContractDetailsData.contractDetailsSuccess;
export const selectContractDetailError = (state: RootState) => state.coloaderContractDetailsData.contractDetailsError;
export const selectContractDetailAuthor = (state: RootState) => state.coloaderContractDetailsData.contractDetailsAuthor;
export const selectContractDetailForwarderUser = (state: RootState) =>
    state.coloaderContractDetailsData.contractDetailsForwarderUser;

export const selectContractDetailAgentUser = (state: RootState) =>
    state.coloaderContractDetailsData.contractDetailsAgentUser;

export const selectContractDetailAgentUsers = (state: RootState) =>
    state.coloaderContractDetailsData.contractDetailsAgentUsers;
export const selectContractDetailIncoTerms = (state: RootState) =>
    state.coloaderContractDetailsData.contractDetailsIncoTerms;
export const selectContractDetailPort = (state: RootState) => state.coloaderContractDetailsData.contractDetailsPort;
export const selectContractDetailAllClient = (state: RootState) =>
    state.coloaderContractDetailsData.contractDetailsAllClient;
export const selectContractDetailIsError = (state: RootState) => state.coloaderContractDetailsData.isError;

export const selectContractDetailCurrentPage = (state: RootState) => state.coloaderContractDetailsData.currentPage;
export const selectContractDetailColumnStatus = (state: RootState) => state.coloaderContractDetailsData.columnStatus;
export const selectContractDetailPerPageCount = (state: RootState) => state.coloaderContractDetailsData.perPageCount;
export const selectContractDetailtotalNoOfPages = (state: RootState) =>
    state.coloaderContractDetailsData.totalNoOfPages;
export const selectContractDetailtotalNoOfItems = (state: RootState) =>
    state.coloaderContractDetailsData.totalNoOfItems;

export default coloaderContractDetailsSlice.reducer;
