import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, post } from '../HttpMethods';
import axios from 'axios';

export const getClassBOptions = async (payload: any) => {
    try {
        const response = await get('/cix/scr/custom/3justgro/clb', null, externalBaseUrl);
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

//postClassbApi
export const postClassbApi = async (payload: {
    payload: any;
    vessel: string;
    voyage: string;
    portOfLoading: string;
    portOfDischarge: string;
}) => {
    try {
        const data = await post(
            `cix/scr/custom/3justgro/clb/sendfile?vessel=${payload.vessel}&voyage=${payload.voyage}&portOfLoading=${payload.portOfLoading}&portOfDischarge=${payload.portOfDischarge}`,
            null,
            null,
            undefined,
            externalBaseUrl
        );
        if (data?.status !== 500) return 200;
        else return 500;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const postClassbEmail = async (payload: {
    payload: any;
    vessel: string;
    voyage: string;
    portOfLoading: string;
    portOfDischarge: string;
}) => {
    try {
        const fileName = process.env.REACT_APP_JG_CLASS_B_REPORT_NAME;

        const data = await post(
            `/cix/scr/custom/3justgro/clb/sendEmail?vessel=${payload.vessel}&voyage=${payload.voyage}&portOfLoading=${payload.portOfLoading}&portOfDischarge=${payload.portOfDischarge}`,
            {
                path: `${fileName}`,
                format: 'EXCELOPENXML',
                params: `vessel=${payload.vessel}&voyage=${payload.voyage}&PortOfLoading=${payload.portOfLoading}&PortOfDischarge=${payload.portOfDischarge}`,
            },
            null,
            undefined,
            externalBaseUrl
        );
        if (data?.status !== 500) return 200;
        else return 500;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getSsrsApi = async (payload: {
    payload: any;
    vessel: string;
    voyage: string;
    portOfLoading: string;
    portOfDischarge: string;
}) => {
    try {
        const fileName = process.env.REACT_APP_JG_CLASS_B_REPORT_NAME;

        const data = await post(
            `/cix/scr/utility/ssrs`,
            {
                path: `${fileName}`,
                format: 'EXCELOPENXML',
                params: `vessel=${payload.vessel}&voyage=${payload.voyage}&PortOfLoading=${payload.portOfLoading}&PortOfDischarge=${payload.portOfDischarge}`,
            },
            null,
            undefined,
            externalBaseUrl
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
