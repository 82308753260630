import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchColoaderData,
    fetchHeaderDataColoader,
    isLoadingFn,
    searchColoaderData,
    setHeaderDataColoader,
} from 'src/store/reducers/profiles/coloader';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import {
    getColoaderData,
    searchColoader,
    ColoaderListResponse,
    ColoaderSearchData,
    ColoaderListPayload,
    SearchPayload,
    searchCoLoaderHeader,
} from 'src/services/apis/profiles/coloader';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
function* getColoaderListData({
    payload,
}: {
    payload: ColoaderListPayload & { coloaders?: AxiosResponse<ColoaderListResponse> };
    type: 'fetchColoaderData';
}) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const coloaders: AxiosResponse<ColoaderListResponse> = yield call(getColoaderData, payload);
        payload['coloaders'] = coloaders;
        yield put({ type: fetchColoaderData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

//get search data
function* getSearchData({
    payload,
}: {
    payload: SearchPayload & { coloaders: AxiosResponse<ColoaderSearchData> };
    type: 'searchColoaderData';
}) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const coloaders: AxiosResponse<ColoaderSearchData> = yield call(searchColoader, payload);
        payload['coloaders'] = coloaders;
        yield put({ type: searchColoaderData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getColoaderheaderData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const coloader: AxiosResponse<any> = yield call(searchCoLoaderHeader, payload);
        yield put({ type: setHeaderDataColoader, payload: coloader });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* coloaderSaga() {
    yield takeEvery('coloader/fetchColoaderData', getColoaderListData);
    yield takeEvery('coloader/searchColoaderData', getSearchData);
    yield takeEvery(fetchHeaderDataColoader, getColoaderheaderData);
}

// Export the saga (data-saga)
export default coloaderSaga;
