import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchNotificationData } from '../reducers/notificationReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import { getNotifications } from '../../services/apis/notificationApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getNotificationData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const notifications: AxiosResponse<any> = yield call(getNotifications, payload);
        payload['notifications'] = notifications;
        yield put({ type: fetchNotificationData, payload: payload });
    } finally {
        yield put(hideLoading());
    }
}

function* notificationsSaga() {
    yield takeEvery('notifications/fetchNotificationData', getNotificationData);
}

// Export the saga (data-saga)
export default notificationsSaga;
