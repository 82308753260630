import { post } from '../../HttpMethods';

export interface SplitLineData {
    splitLineId: string;
    splitLineStatus: string;
    confirmedQty: number;
    confirmedGoodsdate: string;
    confirmedCbm: number;
    confirmedWeight: number;
    createdByUser: string;
    createdDateTime: string;
    modifiedByUser: string;
    modifiedDateTime: string;
    manuallyEntered: boolean;
}

export interface PoLineData {
    poProductId: string;
    sku: string;
    description: string;
    originPort: string;
    destinationPort: string;
    splitLines: SplitLineData[];
}

export interface listPoLineResponse {
    splitLineDataResList: PoLineData[];
}
export const getAvailablePoLines = async (payload: {
    poId: string;
    data?: {
        originPort: string;
        destinationPort: string;
        confirmedGoodsdate: string;
    };
}) => {
    try {
        const res = await post(`api/bkg/bp?poId=${payload.poId}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const addPoLinestoBp = async (payload: {
    bpId?: string;
    fullyBooked: boolean;
    purchaseOrderId: string;
    splitLines: string[];
}) => {
    try {
        const res = await post('api/bkg/bp/add/subpolines', payload, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const poLineFilterData = async (payload: { poId: string; data: any }) => {
    try {
        const res = await post(`api/bkg/bp/distinct/viewpo?poId=${payload.poId}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
