import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import ExportButton from '../../../components/common/ExportButton';
import ProfileListing from '../../../components/common/ProfileListing';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { RootState } from '../../../store/reducers';
import { fetchPermissions } from '../../../store/reducers/permissionReducer';
import {
    fetchReceiptData,
    fetchReceiptFilterData,
    fetchReceiptStatusCount,
    setPageCount,
    setFilter,
    setSelectedDropDown,
} from '../../../store/reducers/receipts/receiptListReducer';
import { activeReceipts, activeStatus, receiptHeaders, statusOfReceipts } from '../../../utils/constants';
import { Position, success } from '../../../utils/toast';
import TableFooter from '../../../components/common/Table/tableFooter';
import './styles.scss';
import { exportReceiptList } from '../../../services/apis/receipts/receiptsListApi';
import ShowForPermissions from '../../../ShowForPermissions';
import { submitReceiptSucces } from '../../../store/reducers/receipts/receiptAttachment';
import { getOrganisationData } from '../../../utils';
const Index: React.FC = () => {
    const dispatch = useDispatch();
    const {
        receipts,
        filterValues,
        currentPage,
        perPageCount,
        totalPages,
        columnStatus,
        totalItems,
        statusCount,
        filter,
        selectedDropDown,
        isLoading,
    } = useSelector((state: RootState) => state.receiptList);
    const [selectedHeader, setSelectedHeader] = useState(receiptHeaders.createdDate);
    const { successSubmitStat, successSubmitName } = useSelector((state: RootState) => state.receiptAttachmentReducer);
    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandId, setexpandId] = useState<any | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);
    const userDetail = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = userDetail?.user?.us_UserName;
    const { profileType, isBlp, blpIntegrationsReceipt } = useUserProfile();
    const firstUpdate = useRef(true);
    const organisationData = getOrganisationData();
    const [activeCount, setActiveCount] = useState(0);
    const location = useLocation();

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter, loggedInUserName: userDetail?.user?.us_UserName },
        profileType,
    };
    const sortPayload = {
        sortOrder: currentOrder ? 'DESC' : 'ASC',
        sortColumn: columnStatus,
    };
    const payloadForFilter = {
        ...payload,
        data: {
            ...organisationData,
            ...{ status: filter.status, loggedInUserName: userDetail?.user?.us_UserName },
            ...filter,
        },
    };
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (searchTerm?.trim()?.length > 2) {
            if (filter?.status === 'Closed' || filter?.status === activeStatus) {
                dispatch(setFilter({ ...filter, receiptSearch: searchTerm }));
            } else {
                dispatch(
                    setSelectedDropDown({
                        text: activeReceipts,
                        count: activeCount,
                    })
                );
                dispatch(setFilter({ ...filter, receiptSearch: searchTerm, status: activeStatus }));
            }
        } else if (searchTerm?.trim()?.length === 0) {
            if ((filter as any)?.receiptSearch) {
                const filterObj = JSON.parse(JSON.stringify(filter));
                delete filterObj.receiptSearch;
                dispatch(setFilter(filterObj));
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        const sum = getTotalNumberOfReceipts();
        setActiveCount(sum);
    }, [statusCount]);

    useEffect(() => {
        if (successSubmitStat) {
            success(`Receipt ${successSubmitName} has been submitted and ready to receive goods`, Position.TOP_RIGHT);
            dispatch(submitReceiptSucces({ name: '', stat: false }));
            dispatch(fetchReceiptData({ ...payload, currentPage: 0 }));
            dispatch(fetchReceiptFilterData(payloadForFilter));
            dispatch(fetchReceiptStatusCount(payload));
        }
    }, [successSubmitStat]);

    useEffect(() => {
        dispatch(fetchReceiptData(payload));
        dispatch(fetchReceiptFilterData(payloadForFilter));
        dispatch(fetchReceiptStatusCount(payload));
        dispatch(fetchPermissions(username));
    }, [dispatch, currentPage, setSelectedHeader, perPageCount, currentOrder, filter]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
    }, [totalPages]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        setselectedFilter(data);
    };

    const setExpandIdOnClick = (id: string) => {
        setexpandId(id);
    };

    const filterToggle = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const onSort = (sortKey: string) => {
        setSelectedHeader(sortKey);
        setcurrentOrder(!currentOrder);
    };

    const getTotalNumberOfReceipts = () => {
        const status = statusCount as any;
        if (status) {
            let sum = (Object.values(status) as number[])?.reduce((prev: number, current: number) => prev + current, 0);
            if (sum) {
                sum = sum - (status['Closed'] || 0);
            }
            return sum;
        } else {
            return 0;
        }
    };

    const onSearchClose = () => {
        setSearchTerm('');
        if ((filter as any)?.receiptSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.receiptSearch;
            dispatch(setFilter(filterObj));
        }
    };

    const getDropDown = () => {
        const finalArray = [];
        for (const key of statusOfReceipts) {
            if (statusCount && Object.prototype.hasOwnProperty.call(statusCount, key)) {
                finalArray.push(
                    <a
                        className={`menu-item app-btn ${selectedDropDown.text === key ? ' active' : ''}`}
                        onClick={(e) => {
                            e.preventDefault();
                            dispatch(
                                setSelectedDropDown({
                                    text: key,
                                    count: statusCount[key as keyof typeof statusCount],
                                })
                            );
                            setDropDownOpen(false);
                            dispatch(setFilter({ ...filter, status: key }));
                        }}
                    >
                        <div className="content-heading-holder">
                            <span className="content-heading">{key}</span>
                            <span className=" modal-toast">
                                <div className="toast-message ">{statusCount[key as keyof typeof statusCount]}</div>
                            </span>
                        </div>
                    </a>
                );
            }
        }
        return finalArray;
    };
    useLayoutEffect(() => {
        if ((location as any)?.state?.reload !== true) {
            resetAndFetchData();
        }
    }, [location]);

    const resetAndFetchData = () => {
        dispatch(setFilter({ status: activeStatus }));
        setSearchTerm('');
        setSelectedHeader(receiptHeaders.createdDate);
        dispatch(setSelectedDropDown({ text: activeReceipts, count: getTotalNumberOfReceipts() }));
    };

    const closeAllDropDown = () => {
        setDropDownOpen(false);
        setToggleDropdown(false);
        setShowExportDropDown(false);
        setPageCountDisplay(false);
    };

    const clearSearchFilter = () => {
        if ((filter as any)?.receiptSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.receiptSearch;
            dispatch(setFilter(filterObj));
        }
        dispatch(fetchReceiptStatusCount(payload));
    };

    return (
        <div
            className="main-wrapper"
            onClick={() => {
                closeAllDropDown();
            }}
        >
            <div className="main-content-holder no-main-grid-border purchase-grid">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <h2 className="page-heading">Receipts</h2>
                <div className="grid-section">
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="search-field-purchase-grid">
                                <div className="search-entry-holder">
                                    <input
                                        placeholder="Search Receipts"
                                        type="text"
                                        value={searchTerm}
                                        className="search-input input-text"
                                        onChange={(e) => {
                                            setSearchTerm(e.target.value);
                                        }}
                                    ></input>
                                    <div
                                        className={`search-toolip ${
                                            searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                        }`}
                                    >
                                        Enter atleast 3 characters to search
                                    </div>
                                    <svg className="svg-icon search-icon">
                                        <use href="#searchIcon"></use>
                                    </svg>
                                    <button
                                        className="app-btn text-close-btn"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            onSearchClose();
                                        }}
                                    >
                                        <svg className="svg-icon text-close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </div>
                                <div
                                    className={`dropdown-wrap ${
                                        dropDownOpen ? 'dropdown-open' : ''
                                    } download-dropdown search-toast-content-holder`}
                                >
                                    <button
                                        className="app-btn app-btn-secondary"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            setDropDownOpen(!dropDownOpen);
                                        }}
                                    >
                                        <div className="purchase-dropdown-content">
                                            <span className="button-text">Status :</span>
                                            <div className="content-heading-holder">
                                                <span className="content-heading">{selectedDropDown?.text}</span>
                                                <span className=" modal-toast">
                                                    <div className="toast-message ">
                                                        {selectedDropDown?.text === activeReceipts
                                                            ? activeCount
                                                            : statusCount &&
                                                              statusCount[
                                                                  selectedDropDown.text as keyof typeof statusCount
                                                              ]}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>

                                        <svg className="svg-icon arrow-icon">
                                            <use href="#downArrow">
                                                <title>Expand row</title>
                                            </use>
                                        </svg>
                                    </button>
                                    <div className="dropdown-menu">
                                        <>
                                            <a
                                                className={`menu-item app-btn ${
                                                    selectedDropDown.text === activeReceipts ? 'active' : ''
                                                }`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    dispatch(
                                                        setSelectedDropDown({
                                                            text: activeReceipts,
                                                            count: getTotalNumberOfReceipts(),
                                                        })
                                                    );
                                                    setDropDownOpen(false);
                                                    dispatch(setFilter({ ...filter, status: activeStatus }));
                                                }}
                                            >
                                                <div className="content-heading-holder">
                                                    <span className="content-heading">{activeReceipts}</span>
                                                    <span className=" modal-toast">
                                                        <div className="toast-message ">
                                                            {getTotalNumberOfReceipts()}
                                                        </div>
                                                    </span>
                                                </div>
                                            </a>
                                            {getDropDown()}
                                        </>
                                    </div>
                                </div>
                            </div>

                            <div className="add-btn-purchase">
                                <button
                                    className="action-btn app-btn app-btn-secondary reset-btn"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        resetAndFetchData();
                                    }}
                                    title="Refresh"
                                >
                                    <svg className="svg-icon reset-icon">
                                        <use xlinkHref="#resetIcon">
                                            <title>Split</title>
                                        </use>
                                    </svg>
                                </button>
                                <ExportButton
                                    organisationData={{
                                        ...organisationData,
                                        loggedInUserName: userDetail?.user?.us_UserName,
                                        ...filter,
                                        ...sortPayload,
                                    }}
                                    disabled={
                                        (receipts && receipts.length === 0 && selectedFilter === null) || !receipts
                                            ? true
                                            : false
                                    }
                                    showExportDropDown={showExportDropDown}
                                    setShowExportDropDown={setShowExportDropDown}
                                    exportListWithBody={exportReceiptList}
                                />
                                <ShowForPermissions permission="create" type="receipt">
                                    {isBlp && !blpIntegrationsReceipt && (
                                        <Link to="/blp/receipt/new-receipt" style={{ textDecoration: 'none' }}>
                                            <button
                                                className="app-btn app-btn-secondary icon-button"
                                                title="Add New Receipt"
                                            >
                                                <svg className="svg-icon add-btn-icon">
                                                    <use xlinkHref="#plusIcon"></use>
                                                </svg>
                                                <span className="button-text footer-button-text">Add New Receipt</span>
                                            </button>
                                        </Link>
                                    )}
                                </ShowForPermissions>
                            </div>
                        </div>
                        <ProfileListing
                            filter={filter}
                            setFilter={setFilter}
                            tabName={'receipts'}
                            data={receipts}
                            columnStatus={columnStatus}
                            currentOrder={currentOrder}
                            toggleDropdown={toggleDropdown}
                            selectedFilter={selectedFilter}
                            closeToggle={closeToggle}
                            expandId={expandId}
                            setExpandIdOnClick={setExpandIdOnClick}
                            filterToggle={filterToggle}
                            onSort={onSort}
                            filterDataLists={filterValues}
                            isGlobalStateFilter={true}
                            clearSearchFilter={clearSearchFilter}
                        />
                        <TableFooter
                            fetchData={fetchReceiptData}
                            currentPage={currentPage}
                            stat={payload}
                            displayCountfn={setPageCountDisplay}
                            pageCountDisplay={PageCount}
                            perPageCount={perPageCount}
                            setPageCount={setPageCount}
                            totalItems={totalItems}
                            items={receipts}
                            itemsInList={null}
                            totalPages={totalPages - 1}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
