import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import CancelModal from '../../../components/common/CancelModal';
import { FILE_SIZE, fileTypesInfoText, fileUploadInfoText, UserProfileType } from 'src/utils/constants';
import { selectCurrency } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import {
    ConfirmationGroups,
    selectCoLoader,
    CoLoader,
    saveLcl,
    saveFcl,
    fetchCoLoader,
} from 'src/store/reducers/purchaseOrder/containerModePoReducer';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { convertDataForSelect } from 'src/utils';

type approvalStatus = {
    status: boolean;
    type: string;
};

interface AddModalProps {
    modalType: string;
    setDetailsComponent: (value: approvalStatus | ((prevVar: approvalStatus) => approvalStatus)) => void;
    profileType: string;
    confirmationGroup: ConfirmationGroups;
}

interface CostBuyAndSell {
    amount: string | number;
    currency: string;
}

const AddModal = ({ modalType, setDetailsComponent, profileType, confirmationGroup }: AddModalProps) => {
    const { id, group_id } = useParams();
    const dispatch = useAppDispatch();
    const [showColoader, setShowColoader] = useState(false);
    const [_showCurrency, setShowCurrency] = useState(false);
    const [uploadError, setUploadError] = useState(false);
    const [cancelModalVisibility, setCancelModalVisibility] = useState(false);
    const [coloader, setColoader] = useState<CoLoader>({ coLoader: '', coLoaderCode: '', coLoaderId: '' });
    const [costBuy, setCostBuy] = useState<CostBuyAndSell>({ amount: '', currency: '' });
    const [costSell, setCostSell] = useState<CostBuyAndSell>({ amount: '', currency: '' });
    const [quotationBuy, setQuotationBuy] = useState({ fileName: '', fileSize: 0 });
    const [quotationSell, setQuotationSell] = useState({ fileName: '', fileSize: 0 });
    const currency = useAppSelector(selectCurrency);
    const allColoaders = useAppSelector(selectCoLoader);
    const [file, setFile] = useState<any>(null);
    const [fileSell, setFileSell] = useState<any>(null);
    const [isSaveClicked, setIsSaveClicked] = useState<boolean>(false);
    const [search, setSearch] = useState('');
    let coloaderCheck = 0;

    useEffect(() => {
        if (confirmationGroup) {
            if (modalType === 'LCL') {
                setColoader({
                    coLoader: confirmationGroup?.coLoader,
                    coLoaderCode: confirmationGroup?.coloaderCode,
                    coLoaderId: confirmationGroup?.coloaderId,
                });
                setCostBuy({
                    amount: confirmationGroup?.lclBuyCost ?? '',
                    currency: confirmationGroup?.lclBuyCurrency,
                });
                setCostSell({
                    amount: confirmationGroup?.lclSellCost ?? '',
                    currency: confirmationGroup?.lclSellCurrency,
                });
                setQuotationBuy({
                    fileName: confirmationGroup?.lclBuyQuoteName,
                    fileSize: +confirmationGroup?.lclSavedFileSizeInKb * 1024,
                });
                setQuotationSell({
                    fileName: confirmationGroup?.lclSellQuoteName,
                    fileSize: +confirmationGroup?.lclSellSavedFileSizeInKb * 1024,
                });
            } else {
                setCostBuy({
                    amount: confirmationGroup?.fclBuyCost ?? '',
                    currency: confirmationGroup?.fclBuyCurrency,
                });
                setCostSell({
                    amount: confirmationGroup?.fclSellCost ?? '',
                    currency: confirmationGroup?.fclSellCurrency,
                });
                setQuotationBuy({
                    fileName: confirmationGroup?.fclBuyQuoteName,
                    fileSize: +confirmationGroup?.fclSavedFileSizeInKb * 1024,
                });
                setQuotationSell({
                    fileName: confirmationGroup?.fclSellQuoteName,
                    fileSize: +confirmationGroup?.fclSellSavedFileSizeInKb * 1024,
                });
            }
        }
    }, [confirmationGroup]);

    const fileUpload = (e: any, type: string) => {
        if (e.target.files[0]) {
            if (e.target.files[0]?.size > FILE_SIZE) {
                setUploadError(true);
            } else {
                setUploadError(false);
                if (type === 'buy') {
                    setQuotationBuy({ fileName: e.target.files[0]?.name, fileSize: e.target.files[0]?.size });
                } else {
                    setQuotationSell({ fileName: e.target.files[0]?.name, fileSize: e.target.files[0]?.size });
                }
                setFile(e.target.files[0]);
            }
        }
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const removeFile = () => {
        setFile(null);
        setQuotationBuy({ fileName: '', fileSize: 0 });
    };

    const onSubmit = () => {
        if (saveButtonStatus()) {
            return;
        }
        let type = '';
        const formData = new FormData();
        const initialData = {
            poId: id,
            confirmationGroupId: group_id,
            type: profileType === UserProfileType.Agent ? 'buy' : 'sell',
        };

        if (file?.type) {
            formData.append('buyfile', file, file.name);
        }
        if (fileSell?.type) {
            formData.append('sellfile', fileSell, fileSell.name);
        }
        if (modalType === 'LCL') {
            let payload: any = {
                ...initialData,
                buyLclCost: costBuy.amount,
                buyLclCurrency: costBuy.currency,
                coLoader: coloader?.coLoader,
                coLoaderCode: coloader?.coLoaderCode,
                coLoaderId: coloader?.coLoaderId,
            };
            if (profileType === UserProfileType.FORWARDER) {
                payload = {
                    ...payload,
                    sellLclCost: costSell.amount,
                    sellLclCurrency: costSell.currency,
                };
            }
            formData.append('request', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
            if (profileType === UserProfileType.Agent) {
                type = confirmationGroup?.lclBuyCost ? 'editLcl' : 'addLcl';
            } else {
                type = confirmationGroup?.lclSellCost ? 'editLcl' : 'addLcl';
            }
            dispatch(saveLcl({ formData, mode: type }));
        } else {
            let payload: any = {
                ...initialData,
                buyFclCost: costBuy.amount,
                buyFclCurrency: costBuy.currency,
            };
            if (profileType === UserProfileType.FORWARDER) {
                payload = {
                    ...payload,
                    sellFclCost: costSell.amount,
                    sellFclCurrency: costSell.currency,
                };
            }
            formData.append('request', new Blob([JSON.stringify(payload)], { type: 'application/json' }));
            if (profileType === UserProfileType.Agent) {
                type = confirmationGroup?.fclBuyCost ? 'editFcl' : 'addFcl';
            } else {
                type = confirmationGroup?.fclSellCost ? 'editFcl' : 'addFcl';
            }
            dispatch(saveFcl({ formData, mode: type }));
        }
    };

    const saveButtonStatus = () => {
        switch (modalType) {
            case 'LCL':
                if (profileType === UserProfileType.Agent) {
                    if (
                        coloader?.coLoader &&
                        costBuy?.amount &&
                        costBuy?.currency &&
                        quotationBuy?.fileName &&
                        (coloader?.coLoader !== confirmationGroup?.coLoader ||
                            costBuy?.amount != confirmationGroup?.lclBuyCost ||
                            costBuy?.currency != confirmationGroup?.lclBuyCurrency ||
                            quotationBuy?.fileName !== confirmationGroup?.lclBuyQuoteName)
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    let cond;
                    if (confirmationGroup?.status.toLowerCase() === 'options_to_be_added') {
                        cond = coloader?.coLoader && costBuy?.amount && costBuy?.currency && quotationBuy?.fileName;
                    } else {
                        cond = costSell?.amount && costSell?.currency && quotationSell?.fileName;
                    }
                    if (
                        cond
                        // costSell?.amount &&
                        // costSell?.currency &&
                        // quotationSell?.fileName
                        // &&
                        // (coloader?.coLoader !== confirmationGroup?.coLoader ||
                        //     costSell?.amount !== confirmationGroup?.lclSellCost ||
                        //     costSell?.currency != confirmationGroup?.lclSellCurrency ||
                        //     quotationSell?.fileName !== confirmationGroup?.lclSellQuoteName)
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                }

            case 'FCL':
                if (profileType === UserProfileType.Agent) {
                    if (
                        costBuy?.amount &&
                        costBuy?.currency &&
                        quotationBuy?.fileName &&
                        (costBuy?.amount !== confirmationGroup?.fclBuyCost ||
                            costBuy?.currency != confirmationGroup?.fclBuyCurrency ||
                            quotationBuy?.fileName !== confirmationGroup?.fclBuyQuoteName)
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                } else {
                    let cond;
                    if (confirmationGroup?.status.toLowerCase() === 'options_to_be_added') {
                        cond = costBuy?.amount && costBuy?.currency && quotationBuy?.fileName;
                    } else {
                        cond = costSell?.amount && costSell?.currency && quotationSell?.fileName;
                    }
                    if (
                        cond
                        // costSell?.amount &&
                        // costSell?.currency &&
                        // (quotationSell?.fileName || !confirmationGroup?.lclSellCost) &&
                        // (costSell?.amount !== confirmationGroup?.fclSellCost ||
                        //     costSell?.currency != confirmationGroup?.fclSellCurrency ||
                        //     quotationSell?.fileName !== confirmationGroup?.fclSellQuoteName)
                    ) {
                        return false;
                    } else {
                        return true;
                    }
                }
            default:
                return false;
        }
    };

    const updateCurrencyBuy = (currencyCode: string) => {
        setCostBuy((prev) => ({
            ...prev,
            currency: currencyCode,
        }));
    };
    const updateCurrencySell = (currencyCode: string) => {
        setCostSell((prev) => ({
            ...prev,
            currency: currencyCode,
        }));
    };

    const toggleCancel = () => {
        setDetailsComponent({ status: false, type: 'LCL' });
    };

    const showError = (message: string, conditition: boolean) => {
        return conditition && isSaveClicked ? <div className="error-text">{message}</div> : <></>;
    };

    const combinedColoaders = () => {
        if (coloader?.coLoaderId) {
            if (allColoaders?.find((res: any) => res?.coLoaderId === coloader?.coLoaderId)) {
                return allColoaders;
            } else if (!search) {
                return [...allColoaders, coloader];
            } else {
                return allColoaders;
            }
        } else {
            return allColoaders;
        }
    };

    return (
        <div
            onClick={() => {
                setShowColoader(false);
                setShowCurrency(false);
            }}
            className="app-modal container-add-modal"
        >
            <div className="modal-content-holder medium-popup" style={{ maxHeight: '640px' }}>
                <div className="modal-header">
                    <div className="title">{modalType}</div>
                    <button
                        className="app-btn modal-close-btn"
                        type="button"
                        onClick={(e) => {
                            e.preventDefault();
                            if (!saveButtonStatus()) {
                                setCancelModalVisibility(true);
                            } else {
                                setDetailsComponent({ status: false, type: 'LCL' });
                            }
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content" style={{ height: '540px' }}>
                    <div className="modal-main-content container-add-modal">
                        <form id="my-form" action="" className="modal-form layout-holder one-column">
                            {modalType === 'LCL' && (
                                <div id="name_div" className=" modal-input-holder ">
                                    <div className="modal-label-pill-container">
                                        <label htmlFor="name" className="modal-input-label">
                                            Coloader<span className="mandatory-field-text">*</span>
                                        </label>
                                    </div>
                                    <div className="modal-input-layout-item">
                                        <div
                                            className={`dropdown-wrap modal-dropdown ${
                                                showColoader ? 'dropdown-open' : ''
                                            } search-toast-content-holder modal-dropdown-btn quotation-dropdown`}
                                        >
                                            <input
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    setShowColoader((prev) => !prev);
                                                    setShowCurrency(false);
                                                }}
                                                onChange={(e) => {
                                                    setSearch(e.target.value);
                                                    if (e.target.value?.length > 2) {
                                                        dispatch(fetchCoLoader({ search: e.target.value }));
                                                    } else if (e.target.value?.length === 0) {
                                                        dispatch(fetchCoLoader({}));
                                                    }
                                                }}
                                                onBlur={() => {
                                                    if (search) {
                                                        setSearch('');
                                                        setTimeout(() => {
                                                            dispatch(fetchCoLoader({}));
                                                        }, 1000);
                                                    }
                                                }}
                                                className="menu-btn app-btn select-modal coloader-search"
                                                type="text"
                                                disabled={
                                                    confirmationGroup?.isUpdatedByForwarder &&
                                                    confirmationGroup?.status?.toLowerCase() ===
                                                        'rejected_by_client__with_agent'
                                                }
                                                placeholder={
                                                    coloader?.coLoader ? coloader?.coLoader : 'Search for a coloader'
                                                }
                                                value={search}
                                            >
                                                {/* {coloader?.coLoader ? (
                                                    <span className="button-text">{coloader?.coLoader}</span>
                                                ) : (
                                                    <>
                                                        <span className="placeholder-text">Select Coloader</span>
                                                        <span className="button-text"></span>
                                                    </>
                                                )}
                                                <span className="dropdown-arrow">
                                                    <svg className="svg-icon arrow-icon">
                                                        <use href="#downArrow">
                                                            <title>Expand row</title>
                                                        </use>
                                                    </svg>
                                                </span> */}
                                            </input>
                                            {search.length !== 0 && (
                                                <button
                                                    style={{ top: '-30px', left: '410px' }}
                                                    className="app-btn text-close-btn"
                                                    onClick={() => {
                                                        setSearch('');
                                                        dispatch(fetchCoLoader({}));
                                                    }}
                                                >
                                                    <svg className="svg-icon text-close-icon">
                                                        <use xlinkHref="#closeIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                            <div
                                                className="dropdown-menu"
                                                style={{
                                                    top: search ? 'calc(100% + -13px)' : 'calc(100% + 8px)',
                                                    maxHeight: '340px',
                                                }}
                                            >
                                                {allColoaders?.length === 0 ? (
                                                    // ||
                                                    // (allColoaders?.length === 1 &&
                                                    //     coloader?.coLoaderCode === allColoaders[0]?.coLoaderCode)
                                                    <a>
                                                        <div className="grid-no-content ">
                                                            <p className="no-content-message">
                                                                {profileType === 'forwarder' || profileType === 'vendor'
                                                                    ? 'IFC Coloader is not linked for the Client'
                                                                    : 'No Coloaders linked for this client'}
                                                            </p>
                                                        </div>
                                                    </a>
                                                ) : profileType === 'forwarder' || profileType === 'vendor' ? (
                                                    combinedColoaders()?.map((res: CoLoader, index: number) => {
                                                        // if (res?.coLoaderCode === process.env.REACT_APP_ORG_CODE) {
                                                        coloaderCheck = 1;
                                                        return (
                                                            <a
                                                                onClick={() => {
                                                                    setColoader({
                                                                        coLoader: res?.coLoader,
                                                                        coLoaderCode: res?.coLoaderCode,
                                                                        coLoaderId: res?.coLoaderId,
                                                                    });
                                                                    setSearch('');
                                                                }}
                                                                key={index}
                                                                className={`menu-item app-btn ${
                                                                    coloader?.coLoaderId === res.coLoaderId
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {res.coLoader}
                                                            </a>
                                                        );
                                                        // }
                                                        // if (coloaderCheck === 0 && allColoaders.length === index - 1) {
                                                        //     return (
                                                        //         <a>
                                                        //             <div className="grid-no-content ">
                                                        //                 <p className="no-content-message">
                                                        //                     IFC Coloader is not linked for the Client
                                                        //                     {coloaderCheck}
                                                        //                 </p>
                                                        //             </div>
                                                        //         </a>
                                                        //     );
                                                        // }
                                                        // if (coloaderCheck === 0 && allColoaders.length - 1 === index) {
                                                        //     return (
                                                        //         <a>
                                                        //             <div className="grid-no-content ">
                                                        //                 <p className="no-content-message">
                                                        //                     IFC Coloader is not linked for the Client
                                                        //                     {/* {coloaderCheck} */}
                                                        //                 </p>
                                                        //             </div>
                                                        //         </a>
                                                        //     );
                                                        // }
                                                    })
                                                ) : (
                                                    combinedColoaders()?.map((res: CoLoader, index: number) => {
                                                        if (res?.coLoaderCode !== process.env.REACT_APP_ORG_CODE) {
                                                            coloaderCheck = 1;
                                                            return (
                                                                <a
                                                                    onClick={() => {
                                                                        setColoader({
                                                                            coLoader: res?.coLoader,
                                                                            coLoaderCode: res?.coLoaderCode,
                                                                            coLoaderId: res?.coLoaderId,
                                                                        });
                                                                        setSearch('');
                                                                    }}
                                                                    key={index}
                                                                    className={`menu-item app-btn ${
                                                                        coloader?.coLoaderId === res.coLoaderId
                                                                            ? 'active'
                                                                            : ''
                                                                    }`}
                                                                >
                                                                    {res.coLoader}
                                                                </a>
                                                            );
                                                        }
                                                        if (coloaderCheck === 0) {
                                                            return (
                                                                <a>
                                                                    <div className="grid-no-content ">
                                                                        <p className="no-content-message">
                                                                            No Coloaders linked for this client
                                                                        </p>
                                                                    </div>
                                                                </a>
                                                            );
                                                        }
                                                    })
                                                )}
                                            </div>
                                        </div>
                                        {showError('Coloader is required', !coloader?.coLoader)}
                                    </div>
                                </div>
                            )}
                            {(profileType === UserProfileType.Agent ||
                                profileType === UserProfileType.FORWARDER ||
                                (profileType === UserProfileType.Vendor && costBuy?.amount != '')) && (
                                <div className=" modal-input-holder">
                                    <label className="modal-input-label">
                                        Cost (Buy)
                                        {(profileType === UserProfileType.Agent ||
                                            (profileType === UserProfileType.FORWARDER &&
                                                confirmationGroup?.status?.toLowerCase() ===
                                                    'options_to_be_added')) && (
                                            <span className="mandatory-field-text">*</span>
                                        )}
                                    </label>
                                    <div className="modal-input-layout-item">
                                        <div className="total-term-wrapper">
                                            <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                                <span className="unit-style"></span>
                                                <input
                                                    placeholder="Enter Cost"
                                                    type={
                                                        // profileType === UserProfileType.FORWARDER ||
                                                        profileType === UserProfileType.Vendor ? 'text' : 'number'
                                                    }
                                                    min="0"
                                                    step="any"
                                                    id="costBuyAmount"
                                                    name="costBuyAmount"
                                                    value={costBuy?.amount}
                                                    onChange={(e) => {
                                                        setCostBuy((prev) => ({
                                                            ...prev,
                                                            amount: +parseFloat(e.target.value),
                                                        }));
                                                    }}
                                                    disabled={
                                                        profileType === UserProfileType.FORWARDER
                                                            ? confirmationGroup?.status.toLowerCase() !==
                                                              'options_to_be_added'
                                                            : confirmationGroup?.status.toLowerCase() !==
                                                                  'options_to_be_added' &&
                                                              confirmationGroup?.status.toLowerCase() !==
                                                                  'rejected_by_client__with_agent'
                                                    }
                                                    className={`modal-input input-text ${
                                                        // profileType === UserProfileType.FORWARDER ||
                                                        profileType === UserProfileType.Vendor
                                                            ? 'read-only-class-container'
                                                            : ''
                                                    }
                                                `}
                                                />
                                                {(profileType === UserProfileType.Agent ||
                                                    (profileType === UserProfileType.FORWARDER &&
                                                        confirmationGroup?.status?.toLowerCase() ===
                                                            'options_to_be_added')) &&
                                                    showError('Cost is required', !costBuy?.amount)}
                                                {/* {showError('Cost is required', !costBuy?.amount)} */}
                                            </div>

                                            {/* <div
                                                className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                    showCurrency && profileType === UserProfileType.Agent
                                                        ? 'dropdown-open'
                                                        : ''
                                                }`}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        if (profileType === UserProfileType.Agent) {
                                                            setShowCurrency((prev) => !prev);
                                                            setShowColoader(false);
                                                        }
                                                    }}
                                                    disabled={
                                                        confirmationGroup?.status.toLowerCase() !==
                                                            'options_to_be_added' &&
                                                        confirmationGroup?.status.toLowerCase() !==
                                                            'rejected_by_client__with_agent'
                                                    }
                                                    className={`menu-btn app-btn select-modal  ${
                                                        profileType === UserProfileType.FORWARDER ||
                                                        (confirmationGroup?.status.toLowerCase() !==
                                                            'options_to_be_added' &&
                                                            confirmationGroup?.status.toLowerCase() !==
                                                                'rejected_by_client__with_agent')
                                                            ? 'read-only-class-container'
                                                            : ''
                                                    }`}
                                                >
                                                    {costBuy?.currency ? (
                                                        <span className="button-text">{costBuy?.currency}</span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">Select Currency</span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    <div className="customscrollbar">
                                                        {currency.map((res: Currency, index: number) => (
                                                            <a
                                                                onClick={() => {
                                                                    setCostBuy((prev) => ({
                                                                        ...prev,
                                                                        currency: res.code,
                                                                    }));
                                                                }}
                                                                key={index}
                                                                className={`menu-item app-btn ${
                                                                    costBuy?.currency === res.code ? 'active' : ''
                                                                }`}
                                                            >
                                                                {res.code}
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                {
                                                    // profileType === UserProfileType.FORWARDER ||
                                                    profileType === UserProfileType.Vendor ||
                                                    (profileType === UserProfileType.FORWARDER
                                                        ? confirmationGroup?.status.toLowerCase() !==
                                                          'options_to_be_added'
                                                        : confirmationGroup?.status.toLowerCase() !==
                                                              'options_to_be_added' &&
                                                          confirmationGroup?.status.toLowerCase() !==
                                                              'rejected_by_client__with_agent') ? (
                                                        <input
                                                            type="text"
                                                            value={costBuy?.currency}
                                                            disabled={true}
                                                            className="modal-input input-text read-only-class-container"
                                                        />
                                                    ) : (
                                                        <SearchableSelectBox
                                                            data={convertDataForSelect(currency, 'code', 'code')}
                                                            placeholder="Enter currency to select"
                                                            setStateValue={updateCurrencyBuy}
                                                            stateValue={costBuy?.currency}
                                                            fieldName="currencyBuy"
                                                            menuPortalTarget={document.getElementById('outside_div')}
                                                        />
                                                    )
                                                }
                                                {(profileType === UserProfileType.Agent ||
                                                    (profileType === UserProfileType.FORWARDER &&
                                                        confirmationGroup?.status?.toLowerCase() ===
                                                            'options_to_be_added')) &&
                                                    showError('Currency is required', !costBuy?.currency)}
                                                {/* {showError('Currency is required', !costBuy?.currency)} */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {(profileType === UserProfileType.Agent || profileType === UserProfileType.FORWARDER) && (
                                <div className=" modal-input-holder">
                                    <label htmlFor="name" className="modal-input-label">
                                        Quotation (Buy)
                                        {(profileType === UserProfileType.Agent ||
                                            (profileType === UserProfileType.FORWARDER &&
                                                confirmationGroup?.status?.toLowerCase() ===
                                                    'options_to_be_added')) && (
                                            <span className="mandatory-field-text">*</span>
                                        )}
                                    </label>
                                    <div className="modal-input-layout-item">
                                        {!quotationBuy?.fileName ? (
                                            <>
                                                <div className="browse-btn-wrapper">
                                                    <input
                                                        type={'file'}
                                                        id={'quotationFile'}
                                                        name={'quotationFile'}
                                                        accept={
                                                            '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                        }
                                                        className="browse-input"
                                                        onClick={(e) => {
                                                            (e.target as HTMLInputElement).value = '';
                                                        }}
                                                        disabled={
                                                            profileType === UserProfileType.FORWARDER
                                                                ? confirmationGroup?.status.toLowerCase() !==
                                                                  'options_to_be_added'
                                                                : confirmationGroup?.status.toLowerCase() !==
                                                                      'options_to_be_added' &&
                                                                  confirmationGroup?.status.toLowerCase() !==
                                                                      'rejected_by_client__with_agent'
                                                        }
                                                        onChange={(e) => {
                                                            fileUpload(e, 'buy');
                                                        }}
                                                    ></input>
                                                    <button
                                                        className="app-btn app-btn-secondary icon-button browse-btn"
                                                        title="Upload file"
                                                        type="button"
                                                        disabled={
                                                            profileType === UserProfileType.FORWARDER
                                                                ? confirmationGroup?.status.toLowerCase() !==
                                                                  'options_to_be_added'
                                                                : confirmationGroup?.status.toLowerCase() !==
                                                                      'options_to_be_added' &&
                                                                  confirmationGroup?.status.toLowerCase() !==
                                                                      'rejected_by_client__with_agent'
                                                        }
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        <svg className="svg-icon plus-icon">
                                                            <use xlinkHref="#plusIcon">
                                                                <title>Upload File</title>
                                                            </use>
                                                        </svg>
                                                        <span className="button-text upload-btn">Upload File</span>
                                                    </button>
                                                    {uploadError ? (
                                                        <div className="error-text">Maximum file size is 25mb</div>
                                                    ) : null}
                                                    {(profileType === UserProfileType.Agent ||
                                                        (profileType === UserProfileType.FORWARDER &&
                                                            confirmationGroup?.status?.toLowerCase() ===
                                                                'options_to_be_added')) &&
                                                        showError('Document is required', !quotationBuy?.fileName)}
                                                    {/* {showError('Document is required', !quotationBuy?.fileName)} */}
                                                </div>

                                                <div className="heading-type-1 file-size">
                                                    <p>{fileUploadInfoText}</p>
                                                    <p dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}></p>
                                                </div>
                                            </>
                                        ) : (
                                            <div className="content-with-icon  attachment-holder">
                                                <button
                                                    type="button"
                                                    className="app-btn "
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                >
                                                    <svg className="svg-icon file-attachment-icon ">
                                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                                        <title>Attach file</title>
                                                    </svg>
                                                </button>
                                                <div>
                                                    {/* file-uploaded class to higlight data with blue color */}
                                                    <a className="description-type-1 file-uploaded" title="File">
                                                        {quotationBuy?.fileName?.length > 20
                                                            ? `${quotationBuy?.fileName?.slice(0, 20)}...`
                                                            : quotationBuy?.fileName}
                                                    </a>
                                                    <div className="heading-type-1">
                                                        {getFileSize(quotationBuy?.fileSize)}
                                                    </div>
                                                </div>
                                                {(confirmationGroup?.status.toLowerCase() === 'options_to_be_added' ||
                                                    (profileType === UserProfileType.Agent &&
                                                        confirmationGroup?.status.toLowerCase() ===
                                                            'rejected_by_client__with_agent')) && (
                                                    <a
                                                        // href="#"
                                                        className="file-remove-link read-all-link"
                                                        title="Remove"
                                                        onClick={() => removeFile()}
                                                    >
                                                        Remove
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                            {(profileType === UserProfileType.FORWARDER || profileType === UserProfileType.Vendor) && (
                                <>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Cost (Sell)
                                            {confirmationGroup?.status?.toLowerCase() !== 'options_to_be_added' && (
                                                <span className="mandatory-field-text">*</span>
                                            )}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <div className="total-term-wrapper">
                                                <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                                    <span className="unit-style"></span>
                                                    <input
                                                        placeholder="Enter Cost"
                                                        type="number"
                                                        step="any"
                                                        min="0"
                                                        id="costSellAmount"
                                                        name="costSellAmount"
                                                        value={costSell?.amount}
                                                        onChange={(e) => {
                                                            setCostSell((prev) => ({
                                                                ...prev,
                                                                amount: +parseFloat(e.target.value),
                                                            }));
                                                        }}
                                                        disabled={
                                                            confirmationGroup?.status.toLowerCase() !==
                                                                'options_to_be_added' &&
                                                            confirmationGroup?.status.toLowerCase() !==
                                                                'pending_action_forwarder' &&
                                                            confirmationGroup?.status.toLowerCase() !==
                                                                'rejected_by_client__with_forwarder'
                                                        }
                                                        className="modal-input input-text"
                                                    />
                                                    {confirmationGroup?.status?.toLowerCase() !==
                                                        'options_to_be_added' &&
                                                        showError('Cost is required', !costSell?.amount)}
                                                    {/* {showError('Cost is required', !costSell?.amount)} */}
                                                </div>
                                                <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                    {confirmationGroup?.status.toLowerCase() !==
                                                        'pending_action_forwarder' &&
                                                    confirmationGroup?.status.toLowerCase() !== 'options_to_be_added' &&
                                                    confirmationGroup?.status.toLowerCase() !==
                                                        'rejected_by_client__with_forwarder' ? (
                                                        <input
                                                            type="text"
                                                            value={costSell?.currency}
                                                            disabled={true}
                                                            className="modal-input input-text read-only-class-container"
                                                        />
                                                    ) : (
                                                        <SearchableSelectBox
                                                            data={convertDataForSelect(currency, 'code', 'code')}
                                                            placeholder="Enter currency to select"
                                                            setStateValue={updateCurrencySell}
                                                            stateValue={costSell?.currency}
                                                            fieldName="currencySell"
                                                            menuPortalTarget={document.getElementById('outside_div')}
                                                        />
                                                    )}
                                                    {confirmationGroup?.status?.toLowerCase() !==
                                                        'options_to_be_added' &&
                                                        showError('Currency is required', !costSell?.currency)}
                                                    {/* {showError('Currency is required', !costSell?.currency)} */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label htmlFor="name" className="modal-input-label">
                                            Quotation (Sell)
                                            {confirmationGroup?.status?.toLowerCase() !== 'options_to_be_added' && (
                                                <span className="mandatory-field-text">*</span>
                                            )}
                                            {/* {modalType === 'FCL' &&
                                                profileType === UserProfileType.FORWARDER &&
                                                confirmationGroup?.fclBuyCost && (
                                                    <span className="mandatory-field-text">*</span>
                                                )} */}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            {!quotationSell?.fileName ? (
                                                <>
                                                    <div className="browse-btn-wrapper">
                                                        <input
                                                            type={'file'}
                                                            id={'quotationFile'}
                                                            name={'quotationFile'}
                                                            accept={
                                                                '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                            }
                                                            className="browse-input"
                                                            onClick={(e) => {
                                                                (e.target as HTMLInputElement).value = '';
                                                            }}
                                                            onChange={(e: any) => {
                                                                if (e.target.files[0]) {
                                                                    if (e.target.files[0]?.size > FILE_SIZE) {
                                                                        setUploadError(true);
                                                                    } else {
                                                                        setUploadError(false);

                                                                        setQuotationSell({
                                                                            fileName: e.target.files[0]?.name,
                                                                            fileSize: e.target.files[0]?.size,
                                                                        });
                                                                        setFileSell(e.target.files[0]);
                                                                    }
                                                                }
                                                            }}
                                                        ></input>
                                                        <button
                                                            className="app-btn app-btn-secondary icon-button browse-btn"
                                                            title="Upload file"
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                            }}
                                                        >
                                                            <svg className="svg-icon plus-icon">
                                                                <use xlinkHref="#plusIcon">
                                                                    <title>Upload File</title>
                                                                </use>
                                                            </svg>
                                                            <span className="button-text upload-btn">Upload File</span>
                                                        </button>
                                                        {uploadError ? (
                                                            <div className="error-text">Maximum file size is 25mb</div>
                                                        ) : null}
                                                        {confirmationGroup?.status?.toLowerCase() !==
                                                            'options_to_be_added' &&
                                                            showError('Document is required', !quotationSell?.fileName)}
                                                        {/* {showError('Document is required', !quotationSell?.fileName)} */}
                                                    </div>

                                                    <div className="heading-type-1 file-size">
                                                        <p>{fileUploadInfoText}</p>
                                                        <p dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}></p>
                                                    </div>
                                                </>
                                            ) : (
                                                <div className="content-with-icon  attachment-holder">
                                                    <button
                                                        type="button"
                                                        className="app-btn "
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                        }}
                                                    >
                                                        <svg className="svg-icon file-attachment-icon ">
                                                            <use xlinkHref="#fileAttachmentIcon"></use>
                                                            <title>Attach file</title>
                                                        </svg>
                                                    </button>
                                                    <div>
                                                        {/* file-uploaded class to higlight data with blue color */}
                                                        <a className="description-type-1 file-uploaded" title="File">
                                                            {quotationSell?.fileName?.length > 20
                                                                ? `${quotationSell?.fileName?.slice(0, 20)}...`
                                                                : quotationSell?.fileName}
                                                        </a>
                                                        <div className="heading-type-1">
                                                            {getFileSize(quotationSell?.fileSize)}
                                                        </div>
                                                    </div>
                                                    {(confirmationGroup?.status.toLowerCase() ===
                                                        'pending_action_forwarder' ||
                                                        confirmationGroup?.status.toLowerCase() ===
                                                            'rejected_by_client__with_forwarder' ||
                                                        confirmationGroup?.status.toLowerCase() ===
                                                            'options_to_be_added') && (
                                                        <a
                                                            // href="#"
                                                            className="file-remove-link read-all-link"
                                                            title="Remove"
                                                            onClick={() => {
                                                                setFileSell(null);
                                                                setQuotationSell({ fileName: '', fileSize: 0 });
                                                            }}
                                                        >
                                                            Remove
                                                        </a>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            if (!saveButtonStatus()) {
                                setCancelModalVisibility(true);
                            } else {
                                setDetailsComponent({ status: false, type: 'LCL' });
                            }
                        }}
                        className="app-btn app-btn-secondary modal-btn footer-btn footer-btn-container"
                        type="button"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn  footer-btn-container"
                        type="button"
                        // disabled={saveButtonStatus()}
                        onClick={() => {
                            setIsSaveClicked(true);
                            onSubmit();
                        }}
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                </div>
            </div>
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
            <CancelModal
                displayFunction={null}
                customDisplayFunction={toggleCancel}
                mode={'edit'}
                cancelModalVisibility={cancelModalVisibility}
                setCancelModalVisibility={setCancelModalVisibility}
                header={''}
            />
        </div>
    );
};
export default AddModal;
