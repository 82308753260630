import React from 'react';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import { useAppDispatch } from '../../../hooks';
import { changePoLineStatus } from '../../../store/reducers/purchaseOrder/productPoConfirmationReducer';
interface ModalListProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    splitLine: string;
    poId: any;
}
const Index = ({ display, displayFunction, splitLine, poId }: ModalListProps) => {
    const dispatch = useAppDispatch();
    return (
        <>
            {display ? (
                <div className="app-modal purchase-detail-modal">
                    <div className="modal-content-holder ">
                        <div className="modal-header">
                            <div className="title">Modify Product Details</div>
                            <button onClick={() => displayFunction(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content">
                                Once modified, this PO will need to be confirmed again to make it ready for Booking
                                Proposal. Are you sure to continue?
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => displayFunction(false)}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                            >
                                <span className="button-text footer-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                onClick={() => {
                                    displayFunction(false);
                                    dispatch(changePoLineStatus({ splitLineId: splitLine, poId }));
                                }}
                            >
                                <span className="button-text footer-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
