import { AxiosResponse } from 'axios';
import { put, call, takeLatest } from 'redux-saga/effects';
import {
    saveBpDataAir,
    saveBpStatusCountAir,
    saveBpFilterDataAir,
} from '../../reducers/bookingProposalAir/listReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    getBpDataAir,
    BpResponse,
    getFilterValuesForBpAir,
    getBpStatusCountAir,
} from '../../../services/apis/bookingProposalAir/listingApi';

function* getBpListDataAir({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse<BpResponse> = yield call(getBpDataAir, payload);
        payload['response'] = response;
        yield put({ type: saveBpDataAir, payload: payload });
    } finally {
        yield put(hideLoading());
    }
}

function* getBpfilterDataAir({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const filterData: AxiosResponse<any[]> = yield call(getFilterValuesForBpAir, payload);
        payload['filterData'] = filterData;
        yield put({ type: saveBpFilterDataAir, payload: payload });
    } finally {
        yield put(hideLoading());
    }
}

function* getBpStatusDataAir({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const count: AxiosResponse<any[]> = yield call(getBpStatusCountAir, payload);
        payload['count'] = count;
        yield put({ type: saveBpStatusCountAir, payload: payload });
    } finally {
        yield put(hideLoading());
    }
}

function* bpListAirSaga() {
    yield takeLatest('bookingProposalAirList/fetchBpDataAir', getBpListDataAir);
    yield takeLatest('bookingProposalAirList/fetchBpFilterDataAir', getBpfilterDataAir);
    yield takeLatest('bookingProposalAirList/fetchBpStatusCountAir', getBpStatusDataAir);
}

// Export the saga (data-saga)
export default bpListAirSaga;
