import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'landadditionalDetailsNac',
    initialState: {
        additionalDetails: {
            referenceNumber: null,
            destinationAgentCode: null,
            destinationAgentName: null,
            bookingContractNo: null,
            contractType: null,
            contractVersion: null,
            clientType: null,
            contractName: null,
            contractId: null,
            sellQuotationName: null,
            sellQuotationVersion: null,
            sellQuotationId: null,
            sellTotOriginCost: null,
            sellCurrency: {
                currencyId: null,
                currencyName: null,
                currencycode: null,
            },
            savedFakFileName: null,
            savedFakFileId: null,
            savedFakFileSizeInKb: null,
            totOriginCost: 0,
            totOriginCostCurrency: null,
            isBookingReset: false,
            bookingResetCode: '',
        },
        isLoading: true,
        error: false,
        success: false,
        errorMessage: null,
        containers: [],
        deliveryModes: [],
        containerModes: [],
        containerTypes: [] as any,
        addOrEditContainerSuccess: false,
        addOrEditContainerError: false,
        containerDeleteError: false,
        containerDeleteSuccess: false,
        contractDetails: {} as any,
        ovrContractDetails: {} as any,
        initialContainerValues: [],
        isAddOrEditDestinationAgentSuccess: false,
        isAddOrEditDestinationAgentError: false,
        destinationAgentError: {},
    },
    reducers: {
        fetchNacAdditionalDetails: (state, action) => {
            state.additionalDetails = action.payload?.data?.details;
            state.containers = action.payload?.data?.containers;
            state.initialContainerValues = action.payload?.data?.containers;
        },
        fetchDeliveryAndContainerModes: (state, action) => {
            state.deliveryModes = action.payload?.data?.deliveryModes;
            state.containerModes = action.payload?.data?.containerModes;
            state.containerTypes = action.payload?.data?.containerTypes;
        },
        addRefNumberNac: (state, action) => {
            state.isLoading = true;
            state.success = action.payload?.data === 'Success';
            if (action.payload?.data) {
                state.error = action.payload?.data !== 'Success';
            }
            state.isLoading = false;
        },
        addContainerNac: (state, action) => {
            state.isLoading = true;
            state.addOrEditContainerSuccess = action.payload?.response;
            if (action.payload?.error) {
                state.addOrEditContainerError = true;
            }
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },

        resetSuccessFlags: (state, _action) => {
            state.success = false;
            state.error = false;
            state.addOrEditContainerError = false;
            state.addOrEditContainerSuccess = false;
            state.containerDeleteSuccess = false;
            state.containerDeleteError = false;
            state.errorMessage = null;
        },
        setContainers: (state, action) => {
            state.containers = action.payload;
        },
        editContainerNac: (state, action) => {
            state.isLoading = true;
            state.addOrEditContainerSuccess = action.payload?.info === 'Success';
            if (action.payload?.info) {
                state.addOrEditContainerError = action.payload?.info !== 'Success';
            }
            state.isLoading = false;
        },
        deleteContainerNac: (state, action) => {
            state.isLoading = true;
            state.containerDeleteSuccess = action.payload?.data === 'Success';
            if (action.payload?.data) {
                state.containerDeleteError = action.payload?.data !== 'Success';
            }
            state.isLoading = false;
        },
        fetchContractDetails: (state, action) => {
            state.contractDetails = action.payload?.contractDetails;
        },
        fetchOvrContractDetails: (state, action) => {
            state.ovrContractDetails = action.payload?.contractDetails;
        },
        addFakDetails: (state, action) => {
            state.isLoading = true;
            state.success = action.payload?.data?.success === true;
            if (action.payload?.data && !action.payload?.data?.success) {
                state.error = true;
            }
            state.isLoading = false;
        },
        addFakOnError: (state, action) => {
            state.error = true;
            state.errorMessage = action.payload?.response?.data?.data;
            state.isLoading = false;
        },
        editFakDetails: (state, action) => {
            state.isLoading = true;
            state.success = action.payload?.data?.success === true;
            if (action.payload?.data && !action.payload?.data?.success) {
                state.error = true;
            }
            state.isLoading = false;
        },
        addOrEditDestinationAgent: (state, _action) => {
            state.isLoading = true;
        },
        addOrEditDestinationAgentSuccess: (state, _action) => {
            state.isAddOrEditDestinationAgentSuccess = true;
            state.isLoading = false;
        },
        addOrEditDestinationAgentError: (state, action) => {
            state.isAddOrEditDestinationAgentError = true;
            state.destinationAgentError = action.payload;
            state.isLoading = false;
        },
        resetBillDesinationAgentFlags: (state, _action) => {
            state.isAddOrEditDestinationAgentError = false;
            state.isAddOrEditDestinationAgentSuccess = false;
            state.destinationAgentError = {};
        },
    },
});

export const {
    fetchNacAdditionalDetails,
    fetchDeliveryAndContainerModes,
    addContainerNac,
    addRefNumberNac,
    addFakDetails,
    addFakOnError,
    isLoadingFn,
    resetSuccessFlags,
    setContainers,
    editContainerNac,
    deleteContainerNac,
    fetchContractDetails,
    fetchOvrContractDetails,
    editFakDetails,
    addOrEditDestinationAgent,
    addOrEditDestinationAgentSuccess,
    addOrEditDestinationAgentError,
    resetBillDesinationAgentFlags,
} = dataSlice.actions;
export default dataSlice.reducer;
