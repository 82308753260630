import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchReassignPoList,
    fetchReassignPoListSuccess,
    fetchDistinctPoList,
    fetchDistinctPoListSuccess,
    fetchAgentList,
    fetchAgentListSuccess,
    updateAgentPo,
    updateAgentPoSuccess,
    updateAgentPoFailure,
} from '../../reducers/reassignAgent/purchaseOrderReducer';
import {
    fetchReassignPoListDetailsApi,
    fetchDistinctPoListDetailsApi,
    fetchAgentListDetailsApi,
    updateAgentPoDetailsApi,
} from '../../../services/apis/reassignAgent/purchaseOrderApi';

function* getReassignPoDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchReassignPoListDetailsApi, payload);
        yield put({ type: fetchReassignPoListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignPoListFailed, payload: error });
    }
}
function* getDistinctPoDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchDistinctPoListDetailsApi, payload);
        yield put({ type: fetchDistinctPoListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignPoListFailed, payload: error });
    }
}

function* getAgentDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchAgentListDetailsApi, payload);
        yield put({ type: fetchAgentListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignPoListFailed, payload: error });
    }
}

function* setAgent({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(updateAgentPoDetailsApi, payload);
        yield put({ type: updateAgentPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateAgentPoFailure, payload: error });
    }
}

function* reassignPoSaga() {
    yield takeEvery(fetchReassignPoList, getReassignPoDetails);
    yield takeEvery(fetchDistinctPoList, getDistinctPoDetails);
    yield takeEvery(fetchAgentList, getAgentDetails);
    yield takeEvery(updateAgentPo, setAgent);
}

export default reassignPoSaga;
