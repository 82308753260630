export enum FilterList {
    Days = 'Days',
    Weeks = 'Weeks',
    Months = 'Months',
}

export enum FilterModeList {
    Land = 'land',
    Sea = 'sea',
    Air = 'air',
    Fcl = 'fcl',
    Lcl = 'lcl',
    Bcn = 'bcn',
}

export enum FilterDestinationTypeList {
    International = 'International',
    Triangle = 'Triangle',
}

export enum Type {
    ao = 'AO',
    itpo = 'ITPO',
    ibpo = 'IBPO',
    po = 'po',
    all = 'ALL',
}
