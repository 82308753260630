import React, { ReactElement } from 'react';

interface Card {
    title: string;
    value: any;
    style: string;
}

interface HeaderPropType {
    headers: Array<Card>;
}

/**
 * rendering header cards
 * @param props 
 * @returns booking headers
 */
const Index: React.FC<HeaderPropType> = (props) => {
    const children: Array<JSX.Element> = [];

    props.headers.map((cardComponent, index) => {
        children.push(
            <div key={index} className={`card-detail-col ${cardComponent.style === 'primary' ? 'col-info-1' : 'col-info-2'}`}>
                <div className="details-col box-content-holder">
                    <h6 className="details-box-heading">{cardComponent.title}</h6>
                    <div className="details-box-content" title={cardComponent.title || '-'}>
                        {cardComponent.value || '-'}
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="booking-proposal-header-fields">
            <div className="details-box custom">{children}</div>
        </div>
    );
};

export default Index;
