import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import '../lcsBookingDetails.scss';
import { filterValues, gridDetails } from 'src/utils/kendoGrid/lifecyclesearch';
import KendoGridJquery from 'src/components/KendoGridJquery';
// import ScheduleTab from '../ScheduleTab';
const baseURL = process.env.REACT_APP_BASE_URL;

/* eslint-disable no-undefined */

const Index: React.FC = () => {
    const [lcsTab, setLcsTab] = useState('Departures');
    const [loading, setLoading] = useState<boolean>(false);
    const { id } = useParams();
    const { profileType, userName } = useUserProfile();
    // const location = useLocation();
    // const nameOfPage = 'ports';
    const gridItemDepart = gridDetails.filter((res) => res.name === 'portsDeparture')[0];
    const gridItemArrival = gridDetails.filter((res) => res.name === 'portsArrival')[0];
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };

    useEffect(() => {
        gridItemDepart.url = `${baseURL}/api/bkg/tracking/lifecycle/search/portdetails?id=${id}&type=${lcsTab}&profile=${profileType}`;
        gridItemArrival.url = `${baseURL}/api/bkg/tracking/lifecycle/search/portdetails?id=${id}&type=${lcsTab}&profile=${profileType}`;
        setLoading(true);
    }, [profileType, lcsTab, id]);

    // const Btn: any = () => {
    //     return (
    //         <div className="head-btn-holder">
    //             <button className="app-btn app-btn-secondary" title="Go to Booking">
    //                 <span className="button-text">Go to Booking</span>
    //             </button>
    //         </div>
    //     );
    // };
    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${lcsTab === 'Departures' ? 'active' : ''}`}
                        onClick={() => {
                            setLcsView('Departures');
                            if (lcsTab !== 'Departures') {
                                setLoading(!loading);
                            }
                        }}
                    >
                        Departures
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'Arrivals' ? 'active' : ''}`}
                        onClick={() => {
                            setLcsView('Arrivals');
                            if (lcsTab !== 'Arrivals') {
                                setLoading(!loading);
                            }
                        }}
                    >
                        Arrivals
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content">
                {lcsTab === 'Departures' && loading && (
                    <KendoGridJquery
                        gridItem={gridItemDepart}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
                {lcsTab === 'Arrivals' && loading && (
                    <KendoGridJquery
                        gridItem={gridItemArrival}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
