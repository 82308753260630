import React, { useEffect, useState } from 'react';
import '../../Clients/BasicDetails/basic-details.scss';
import '../../Clients/ProductMaster/product-master-edit.scss';
import '../../../../assets/scss/components/_tooltip.scss';
import '../BasicDetail/vendor-basic-detail.scss';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { getVendorUser } from 'src/services/apis/vendorUsersApi';
import moment from 'moment';
import ShowForPermissions from 'src/ShowForPermissions';
import UserDetailsModal from './UserDetailsModal';
import { error, Position, success } from 'src/utils/toast';

export const vendorInputTypeProfile = {
    firstName: {
        name: 'First Name',
        required: true,
        type: 'text',
        disable: false,
    },
    middleName: {
        name: 'Middle Name',
        required: false,
        type: 'text',
        disable: false,
    },
    lastName: {
        name: 'Last Name',
        required: true,
        type: 'text',
        disable: false,
    },
    dob: {
        name: 'Date of Birth',
        required: false,
        type: 'date',
        disable: false,
    },
    email: {
        name: 'Email',
        required: true,
        type: 'text',
        disable: false,
    },
    userName: {
        name: 'User Name',
        required: true,
        type: 'text',
        disable: true,
    },
    landPhone: {
        name: 'Land Phone',
        required: false,
        type: 'text',
        disable: false,
    },
    mobileNumber: {
        name: 'Mobile No',
        required: false,
        type: 'text',
        disable: false,
    },
    jobtitle: {
        name: 'Job Title',
        required: false,
        type: 'text',
        disable: false,
    },
    vendorRole: {
        name: 'Vendor Role',
        required: false,
        type: 'roleSelectBox',
        disable: false,
    },
};
export const vendorInputTypeAddress = {
    address1: {
        name: 'Address 1',
        required: false,
        type: 'text',
        disable: false,
    },
    address2: {
        name: 'Address 2',
        required: false,
        type: 'text',
        disable: false,
    },
    address3: {
        name: 'Address 3',
        required: false,
        type: 'text',
        disable: false,
    },
    addressType: {
        name: 'Address Type',
        required: false,
        type: 'text',
        disable: false,
    },
    city: {
        name: 'City',
        required: false,
        type: 'text',
        disable: false,
    },
    state: {
        name: 'State',
        required: false,
        type: 'text',
        disable: false,
    },
    postCode: {
        name: 'Post Code',
        required: false,
        type: 'text',
        disable: false,
    },
    countryCode: {
        name: 'Country Code',
        required: false,
        type: 'countrySelectBox',
        disable: false,
    },
};
const Index: React.FC = () => {
    const { id, vn_user_pk } = useParams();

    const [showModal, setShowModal] = useState(false);
    const [vendorData, setVendorData] = useState<any>({
        userName: '',
        email: '',
        vnPK: id,
        vendorRole: '',
        firstName: '',
        middleName: '',
        lastName: '',
        dob: '',
        landPhone: '',
        mobileNumber: '',
        jobtitle: '',
        address1: '',
        address2: '',
        address3: '',
        addressType: '',
        city: '',
        state: '',
        postCode: '',
        countryCode: '',
    });

    const [isLoading, setIsLoading] = useState(false);

    const getVendorLists = () =>
        getVendorUser(vn_user_pk)
            .then(async (res: any) => setVendorData(res))
            .catch((err: any) => {
                console.log('error', err);
                error(err?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            })
            .finally(() => setIsLoading(false));

    const getInitialData = async () => {
        setIsLoading(true);
        await getVendorLists();
    };

    useEffect(() => {
        if (id && vn_user_pk !== 'add') {
            getInitialData();
        }
        document.title = 'CNF>ORG>VND';
    }, [id, vn_user_pk]);

    return (
        <>
            <div className="main-wrapper container product-edit">
                <div className="main-header-content-holder" style={{ height: '75px' }}>
                    <div className="breadcrumbs-holder">
                        <ul className="breadcrumbs">
                            <Link className="dropdown-arrow" to="/vendor" state={{ reload: true }}>
                                <svg className="arrow-icon">
                                    <use xlinkHref="#downArrow">
                                        <title>User details</title>
                                    </use>
                                </svg>
                            </Link>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/profile">
                                    Organisations &amp; Users
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to="/vendor">
                                    Organisations
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <Link className="breadcrumb-item" to={`/profile/vendor/${id}/users`}>
                                    Vendor
                                </Link>
                            </li>
                            <li className="breadcrumb-list-item">
                                <a href="#" className="breadcrumb-item">
                                    Users
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="pageheading-holder">
                        <h2 className="page-heading">{vn_user_pk === 'add' ? 'Add New User' : 'Edit User'}</h2>
                    </div>
                    <div style={{ zIndex: 101 }} className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                </div>
                <div className={`content-section  main-wrapper vendor-basic-detail-wrapper`}>
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder">
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">Profile Information</h6>
                                {vendorData.userName ? (
                                    <ShowForPermissions permission="create" type="vendor">
                                        <button
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Vendor User"
                                            onClick={() => {
                                                setShowModal(true);
                                            }}
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    </ShowForPermissions>
                                ) : null}
                            </div>
                            {!vendorData.userName ? (
                                <ShowForPermissions permission="create" type="vendor">
                                    <div className="btn-holder">
                                        <button
                                            className="app-btn app-btn-secondary icon-button  add-product-btn"
                                            title="Add Vendor User"
                                            onClick={() => {
                                                setShowModal(true);
                                            }}
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text footer-button-text">Add Vendor User</span>
                                        </button>
                                    </div>
                                </ShowForPermissions>
                            ) : null}
                        </div>
                        <div className="card-detail-col" style={{ marginBottom: '30px' }}>
                            {Object.entries({ ...vendorInputTypeProfile }).map(([key, value]) => (
                                <div className="details-col vendor-user box-content-holder">
                                    <h6 className="details-box-heading">
                                        {value.name}
                                        {value.required && <span className="mandatory-field-text">*</span>}
                                    </h6>
                                    <div className="details-box-content">
                                        {key === 'dob'
                                            ? vendorData?.[key]
                                                ? moment(vendorData?.[key]).format('DD-MM-YYYY')
                                                : '-'
                                            : vendorData?.[key]
                                            ? vendorData?.[key]
                                            : '-'}
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="card-head-holder add-btn-holder">
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">Address</h6>
                            </div>
                        </div>
                        <div className="card-detail-col">
                            {Object.entries({ ...vendorInputTypeAddress }).map(([key, value]) => (
                                <div className="details-col vendor-user box-content-holder">
                                    <h6 className="details-box-heading">
                                        {value.name}
                                        {value.required && <span className="mandatory-field-text">*</span>}
                                    </h6>
                                    <div className="details-box-content">
                                        {vendorData?.[key] ? vendorData?.[key] : '-'}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <Link className="app-btn app-btn-secondary footer-btn" to={`/profile/vendor/${id}/users`}>
                            <span className="button-text footer-button-text">Back</span>
                        </Link>
                    </div>
                </div>
            </div>

            {showModal ? (
                <UserDetailsModal setShowModal={setShowModal} data={vendorData} getVendorLists={getVendorLists} />
            ) : null}
        </>
    );
};

export default Index;
