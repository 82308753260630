import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';

import 'src/assets/scss/components/_tooltip.scss';

//custom components
import Breadcrumbs from 'src/components/Breadcrumbs';
import BackButton from 'src/components/common/BackButton';
import HeaderTabs from '../HeaderTabs';
import { useUserProfile } from 'src/hooks/useUserProfile';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { Icon } from 'semantic-ui-react';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { id } = useParams();
    const { profileType } = useUserProfile();

    return (
        <>
            <div className="main-wrapper container">
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton />
                    <HeaderTabs />
                </div>

                <div className="content-section main-wrapper">
                    <div className="grid-header">
                        <div className="content-heading-holder card-head-btn-edit-holder">
                            <span className="content-heading">
                                Attachments
                                <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                    <span className="hub-tooltiptext" style={{ width: '400px' }}>
                                        Attachments will show documents uploaded against this Client.
                                    </span>
                                    <Icon name="question circle outline" />
                                </span>
                            </span>
                        </div>
                    </div>

                    <LayoutWithColumns content={'bookingDocuments'} hideLoader style="booking_details" />
                </div>
            </div>
        </>
    );
};

export default Index;
