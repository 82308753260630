import React, { useState, useEffect } from 'react';
import './document-attachment.scss';
import Attachments from './attachments';
import { useDispatch } from 'react-redux';
import { fetchFiles, setWarning, submitReceipt } from '../../../../store/reducers/receipts/receiptAttachment';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { error, Position, success } from '../../../../utils/toast';
import { Link } from 'react-router-dom';
import { fetchPolineReceipt } from '../../../../store/reducers/receipt/poLine';
import { fetchReceiptHeaderList, selectReceiptHeader } from '../../../../store/reducers/receipts/receiptHeaderReducer';
import DiscardModal from '../DiscardModal';
import { useAppSelector } from '../../../../hooks';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import ShowForPermissions from '../../../../ShowForPermissions';
import CloseReceiptModal from './CloseReceiptModal';
import ReopenReceiptModal from './reopenReceiptModal';
import ReceiptCancelModal from '../ReceiptHeader/ReceiptCancelModal';
import ReceiptHeaderTabs from 'src/pages/BLP/ReceiptDetails/ReceiptHeaderTabs';
/* eslint-disable no-undefined */

const Index: React.FC = () => {
    const { isBlp, blpIntegrationsReceipt } = useUserProfile();
    const [displayDiscard, setDisplayDiscard] = useState(false);
    const [displayCloseReceipt, setDisplayCloseReceipt] = useState(false);
    const [displayReopenReceipt, setDisplayReopenReceipt] = useState(false);
    const [saveasdraftToast, _setsaveasdraftToast] = useState(false);
    const [displayReceiptCancel, setDisplayReceiptCancel] = useState(false);
    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();
    // const location = useLocation();
    const {
        files,
        isLoading,
        errorMessage,
        errorStat,
    }: { files: any; isLoading: any; errorMessage: any; errorStat: any; receiptStat: any } = useSelector(
        (state: RootState) => state.receiptAttachmentReducer
    );
    const { poLine } = useSelector((state: RootState) => state.receiptPOline);
    const { receiptDetails } = useSelector((state: RootState) => state.addReceipt);

    const receiptHeader = useAppSelector(selectReceiptHeader);

    useEffect(() => {
        dispatch(fetchFiles(id));
        dispatch(fetchPolineReceipt(id));
        dispatch(
            fetchReceiptHeaderList({
                id,
            })
        );
    }, [id]);
    useEffect(() => {
        dispatch(setWarning({ errorStat: false, message: '' }));
        if (errorStat) {
            error(errorMessage, Position.TOP_RIGHT);
        }
    }, [errorStat, errorMessage]);
    useEffect(() => {
        if (files?.receiptStatus) {
            localStorage.setItem('receiptStatus', files?.receiptStatus);
        }
    }, [files?.receiptStatus]);

    useEffect(() => {
        if (saveasdraftToast) {
            success(
                `Receipt ${receiptDetails?.receiptNo || localStorage.getItem('receiptNo')} has been saved as draft`,
                Position.TOP_RIGHT
            );
        }
    }, [saveasdraftToast]);

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link to="/blp/receipt" className="dropdown-arrow" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/blp/receipt" state={{ reload: true }}>
                                Receipt
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Receipt Details
                            </a>
                        </li>
                    </ul>
                    <div className="action-wrapper">
                        <div className="btn-holder discard-receipt-btn-holder">
                            <ShowForPermissions type="receipt" permission="create">
                                {receiptHeader?.receiptStatus !== 'Closed' && (
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title={'Save & Exit'}
                                        onClick={() => {
                                            navigate('/blp/receipt');
                                            success(
                                                `${localStorage.getItem('receiptNo')} has been saved`,
                                                Position.TOP_RIGHT
                                            );
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#saveIcon2">
                                                <title>Save & Exit</title>
                                            </use>
                                        </svg>
                                        <span className="button-text">Save & Exit</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                            <ShowForPermissions permission="create" type="receipt">
                                {isBlp && !blpIntegrationsReceipt && files?.receiptStatus === 'Draft' && (
                                    <button
                                        onClick={() => setDisplayDiscard(true)}
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title="Discard Receipt"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#deleteIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Discard Receipt</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                            <ShowForPermissions permission="edit" type="receipt">
                                {isBlp &&
                                    !blpIntegrationsReceipt &&
                                    (receiptHeader?.receiptStatus === 'Ready to Receive' ||
                                        receiptHeader?.receiptStatus === 'Ready To Receive') && (
                                        <button
                                            onClick={() => setDisplayReceiptCancel(true)}
                                            className="app-btn app-btn-secondary black-outline-btn icon-button"
                                            title="Cancel Receipt"
                                        >
                                            <span className="button-text footer-button-text">Cancel Receipt</span>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>
                </div>
                <div className="pageheading-holder">
                    {/* <a href="/blp/receipt">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </a> */}
                    <h2 className="page-heading">{receiptDetails?.receiptNo || localStorage.getItem('receiptNo')}</h2>
                </div>
                <ReceiptHeaderTabs />
            </div>
            <div className="content-section main-wrapper agent-basic-detail-wrapper">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="document-attachment-wrapper">
                    <Attachments data={files} />
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                        to={`/blp/receipt/${id}/polines`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    {/*<ShowForPermissions permission="create" type="receipt">
                        {files?.receiptStatus === 'Draft' && (
                            <button
                                onClick={() => {
                                    setsaveasdraftToast(true);
                                    setTimeout(() => {
                                        navigate('/blp/receipt');
                                    }, 500);
                                }}
                                className="app-btn app-btn-secondary grid-top-btn footer-btn"
                                title="Save as Draft"
                            >
                                <span className="button-text vendor-button-text">Save as Draft</span>
                            </button>
                        )}
                    </ShowForPermissions>*/}
                    {files?.receiptStatus === 'Draft' && (
                        <ShowForPermissions permission="create" type="receipt">
                            <a
                                onClick={() => {
                                    dispatch(
                                        submitReceipt({
                                            id: id,
                                            name: receiptDetails?.receiptNo || localStorage.getItem('receiptNo'),
                                        })
                                    );
                                    navigate('/blp/receipt');
                                }}
                                className={`app-btn app-btn-primary footer-btn ${
                                    poLine?.length === 0 ||
                                    poLine === undefined ||
                                    receiptHeader?.plannedCustomsClearance === null
                                        ? 'disabled'
                                        : ''
                                }`}
                                title="Submit Receipt"
                                href="#"
                            >
                                <span className="button-text footer-button-text">Submit Receipt</span>
                            </a>
                        </ShowForPermissions>
                    )}
                    {isBlp && !blpIntegrationsReceipt && files?.receiptStatus === 'Receipt Complete' && (
                        <ShowForPermissions permission="edit" type="receipt">
                            <button
                                onClick={() => setDisplayCloseReceipt(true)}
                                className="app-btn app-btn-primary footer-btn"
                                title="Close Receipt"
                            >
                                <span className="button-text footer-button-text">Close Receipt</span>
                            </button>
                        </ShowForPermissions>
                    )}
                    {isBlp && files?.receiptStatus === 'Closed' && (
                        <ShowForPermissions permission="reopen" type="receipt">
                            <button
                                onClick={() => setDisplayReopenReceipt(true)}
                                className="app-btn app-btn-primary footer-btn"
                                title="Reopen Receipt"
                            >
                                <span className="button-text footer-button-text">Reopen Receipt</span>
                            </button>
                        </ShowForPermissions>
                    )}
                </div>
            </div>
            <DiscardModal discardModalVisibility={displayDiscard} setDiscardModalVisibility={setDisplayDiscard} />
            <CloseReceiptModal
                closeReceiptModalVisibility={displayCloseReceipt}
                setCloseReceiptModalVisibility={setDisplayCloseReceipt}
            />
            <ReopenReceiptModal
                reopenReceiptModalVisibility={displayReopenReceipt}
                setReopenReceiptModalVisibility={setDisplayReopenReceipt}
            />
            <ReceiptCancelModal
                receiptCancelModalVisibility={displayReceiptCancel}
                setReceiptCancelModalVisibility={setDisplayReceiptCancel}
            />
        </div>
    );
};
export default Index;
