import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, post, del } from '../HttpMethods';
import axios from 'axios';

const convertForItem = (data: any) => {
    return {
        PM_SKU: data?.PM_SKU,
        PM_Description: data?.PM_Description,
        PM_PurchaseCode: data?.PM_PurchaseCode,
        PM_FumigationRequired: data?.PM_FumigationRequired,
        PM_CD_DGClassification: data?.PM_CD_DGClassification,
    };
};

const convertForProduct = (data: any) => {
    return {
        PP_Quantity: data?.PP_Quantity,
        PP_ConversionQuantity: data?.PP_ConversionQuantity,
        PP_Length: data?.PP_Length,
        PP_Width: data?.PP_Width,
        PP_Height: data?.PP_Height,
        PP_CBM: data?.PP_CBM,
        PP_Weight: data?.PP_Weight,
        PP_BarCode: data?.PP_BarCode,
    };
};

export const getAllProductDetails = async (id: string | undefined) => {
    try {
        const response = await get(`/cix/scr/client/${id}/items`, null, externalBaseUrl);
        return { status: true, data: response?.data?.[0] };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const getItem = async (id: string | undefined, productId: string | undefined) => {
    try {
        const response = await get(`/cix/scr/client/${id}/item/${productId}`, null, externalBaseUrl);
        return { status: true, data: response?.data?.[0] };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addItem = async (id: string | undefined, payload: any) => {
    try {
        const response = await post(
            `/cix/scr/client/${id}/item`,
            convertForItem(payload),
            null,
            false,
            externalBaseUrl
        );
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const editItem = async (id: string | undefined, productId: string | undefined, payload: any) => {
    try {
        const response = await patch(
            `/cix/scr/client/${id}/item/${productId}`,
            convertForItem(payload),
            externalBaseUrl
        );
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const deleteItem = async (id: string | undefined, productId: string | undefined) => {
    try {
        const response = await del(`/cix/scr/client/${id}/item/${productId}`, null, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getPack = async (id: string | undefined, productId: string | undefined) => {
    try {
        const response = await get(`/cix/scr/client/${id}/item/${productId}/UOMs`, null, externalBaseUrl);
        return { status: true, data: response?.data };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addPack = async (id: string | undefined, productId: string | undefined, payload: any) => {
    try {
        const response = await post(
            `/cix/scr/client/${id}/item/${productId}/UOM`,
            convertForProduct(payload),
            null,
            false,
            externalBaseUrl
        );
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const editPack = async (
    id: string | undefined,
    productId: string | undefined,
    packId: string | undefined,
    payload: any
) => {
    try {
        const response = await patch(
            `/cix/scr/client/${id}/item/${productId}/UOM/${packId}`,
            convertForProduct(payload),
            externalBaseUrl
        );
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const deletePack = async (id: string | undefined, productId: string | undefined, packId: string) => {
    try {
        const response = await del(`/cix/scr/client/${id}/item/${productId}/UOM/${packId}`, null, externalBaseUrl);
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getVendor = async (id: string | undefined, productId: string | undefined) => {
    try {
        const response = await get(
            `/cix/scr/client/${id}/item/${productId}/relationships/vendors`,
            null,
            externalBaseUrl
        );
        return { status: true, data: response?.data };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const addVendor = async (
    id: string | undefined,
    productId: string | undefined,
    vendorId: string,
    payload: any
) => {
    try {
        const response = await post(
            `/cix/scr/client/${id}/item/${productId}/relationships/vendor/${vendorId}`,
            payload,
            null,
            false,
            externalBaseUrl
        );
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const editVendor = async (
    id: string | undefined,
    productId: string | undefined,
    vendorId: string,
    payload: any
) => {
    try {
        const response = await patch(
            `/cix/scr/client/${id}/item/${productId}/relationships/vendor/${vendorId}`,
            payload,
            externalBaseUrl
        );
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const deleteVendor = async (id: string | undefined, productId: string | undefined, vendorId: string) => {
    try {
        const response = await del(
            `/cix/scr/client/${id}/item/${productId}/relationships/vendor/${vendorId}`,
            null,
            externalBaseUrl
        );
        return { status: true, data: response };
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
