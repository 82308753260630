import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get } from '../HttpMethods';
import axios from 'axios';

export const getNotifications = async (payload: { userId: string }) => {
    try {
        const res = await get(`api/ord/notification/${payload.userId}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const generateNewApiKey = async () => {
    try {
        const response = await get('cix/user/me/apikey', null, externalBaseUrl);
        return response;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
