import React from 'react';
import { useSelector } from 'react-redux';

const TransitPop = () => {
    const { singleMapData } = useSelector((state: any) => state.map);
    // const [country, setcountry] = useState('');
    const countOfTypeFn = (dataType?: string, type?: string, carryType?: string) => {
        let countValue = 0;
        let count = 0;
        let flag = '';
        let posAtOriginCountryIndividuals = [];
        const countryList: any = [];

        var newSingleMapData: any[] = [];

        if (!Array.isArray(singleMapData) && singleMapData.countryName) {
            newSingleMapData.push({
                data: singleMapData,
                geometry: { type: 'Point', coordinates: singleMapData.countryCoordinates },
                type: 'Feature',
            });
        } else {
            newSingleMapData = singleMapData;
        }

        Array.isArray(newSingleMapData) &&
            newSingleMapData?.forEach((element: any) => {
                if (element.data?.countryName !== flag) {
                    count++;
                    flag = element.data?.countryName;
                }
                if (element.data?.countryName === flag) {
                    //getting countries list and total count
                    countryList.push({
                        name: element.data?.countryName,
                        count: element?.data?.poCount
                            ? element?.data?.poCount
                            : 0 + element?.data?.bookingCount
                            ? element?.data?.bookingCount
                            : 0,
                    });
                }
                if (element?.data?.type === dataType) {
                    if (type === 'bp') {
                        countValue = countValue + element?.data?.bookingCount;
                    } else {
                        if (carryType) {
                            if (carryType === 'sea' && element?.data?.shippingMode?.toLowerCase() === 'sea') {
                                countValue = countValue + element?.data?.poCount;
                            } else if (carryType === 'air' && element?.data?.shippingMode?.toLowerCase() !== 'sea') {
                                countValue = countValue + element?.data?.poCount;
                            }
                        } else {
                            countValue = countValue + element?.data?.poCount;
                        }
                    }
                }
            });
        const outputCountryList = countryList.reduce(function (accumulator: any, cur: any) {
            const name = cur.name,
                found = accumulator.find(function (elem: any) {
                    if (elem.count !== null) {
                        return elem.name == name;
                    }
                });
            if (found && cur.count !== null) {
                found.count += cur.count;
            } else {
                accumulator.push(cur);
            }
            return accumulator;
        }, []);

        if (Array.isArray(newSingleMapData) && newSingleMapData?.length) {
            const copy = JSON.parse(JSON.stringify(newSingleMapData));
            const filtered = copy
                ?.map((res: any) => {
                    if (res?.data?.type === 'posAtOriginCountry') {
                        return { poStatus: res?.data?.poStatus, poCount: res?.data?.poCount };
                    }
                })
                ?.filter(Boolean);

            posAtOriginCountryIndividuals = filtered?.reduce((acc: any, cur: any, i: number) => {
                const item = i > 0 && acc?.find((res: any) => res?.poStatus === cur.poStatus);
                if (item) {
                    item.poCount += cur.poCount;
                } else {
                    acc.push({ poStatus: cur.poStatus, poCount: cur.poCount });
                }
                return acc;
            }, []);
        }

        return { countValue, flag, count, outputCountryList, posAtOriginCountryIndividuals };
    };
    return (
        <div className="tooltip-dashboard-wrapper">
            <div className="tooltip-booking-list">
                <div className="info-type-items-wrapper">
                    {countOfTypeFn()?.count <= 1 ? (
                        <>
                            {(countOfTypeFn('posAtOriginCountry', 'po').countValue > 0 ||
                                countOfTypeFn('bookingsAtOriginCountry', 'bp').countValue > 0 ||
                                countOfTypeFn('bookingsAtOriginPort', 'bp').countValue > 0 ||
                                countOfTypeFn('bookingsAtDestinationPort', 'bp').countValue > 0) && (
                                <div className="info-type-1">
                                    <div className="dashboard-heading">
                                        {countOfTypeFn().flag ? countOfTypeFn().flag : '-'}
                                    </div>
                                    {countOfTypeFn('posAtOriginCountry', 'po').countValue > 0 && (
                                        <>
                                            {countOfTypeFn(
                                                'bookingsAtOriginCountry',
                                                'bp'
                                            ).posAtOriginCountryIndividuals?.map((res: any) => {
                                                return (
                                                    <div className="icon-content-holder">
                                                        <div className="icon-holder bg-light-blue">
                                                            <svg className="svg-icon aeroplane-icon">
                                                                <use href="#mapnoteIcon">
                                                                    <title>Aeroplane</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="content-name">
                                                            POs at Origin Country:
                                                            <span className="count-text">
                                                                {res?.poCount} ({res?.poStatus?.replaceAll('_', ' ')})
                                                            </span>
                                                        </div>
                                                        {/* <div className="content-name">
                                                            POs at Origin Country:
                                                            <span className="count-text">
                                                            {countOfTypeFn('posAtOriginCountry', 'po').countValue}
                                                            </span>
                                                        </div> */}
                                                    </div>
                                                );
                                            })}
                                        </>
                                    )}
                                    {countOfTypeFn('bookingsAtOriginCountry', 'bp').countValue > 0 && (
                                        <div className="icon-content-holder">
                                            <div className="icon-holder bg-light-violet">
                                                <svg className="svg-icon aeroplane-icon">
                                                    <use href="#mapcheckIcon">
                                                        <title>Aeroplane</title>
                                                    </use>
                                                </svg>
                                            </div>
                                            <div className="content-name">
                                                Bookings at Origin Country
                                                <span className="count-text">
                                                    {countOfTypeFn('bookingsAtOriginCountry', 'bp').countValue}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {countOfTypeFn('bookingsAtOriginPort', 'bp').countValue > 0 && (
                                        <div className="icon-content-holder">
                                            <div className="icon-holder bg-light-purple">
                                                <svg className="svg-icon aeroplane-icon">
                                                    <use href="#maphouseIcon">
                                                        <title>Aeroplane</title>
                                                    </use>
                                                </svg>
                                            </div>
                                            <div className="content-name">
                                                Bookings at Port of Origin:
                                                <span className="count-text">
                                                    {countOfTypeFn('bookingsAtOriginPort', 'bp').countValue}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                    {countOfTypeFn('bookingsAtDestinationPort', 'bp').countValue > 0 && (
                                        <div className="icon-content-holder">
                                            <div className="icon-holder bg-light-green">
                                                <svg className="svg-icon aeroplane-icon">
                                                    <use href="#maphouseIcon">
                                                        <title>Aeroplane</title>
                                                    </use>
                                                </svg>
                                            </div>
                                            <div className="content-name">
                                                Bookings at Port of Destination
                                                <span className="count-text">
                                                    {countOfTypeFn('bookingsAtDestinationPort', 'bp').countValue}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <div className="info-type-1">
                                <div className="dashboard-heading">Countries</div>
                                {countOfTypeFn()?.outputCountryList?.map((res: any) => (
                                    <div className="icon-content-holder">
                                        {res?.name && (
                                            <div className="content-name">
                                                {res?.name ?? ''}:<span className="count-text">{res?.count}</span>
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </>
                    )}
                    {countOfTypeFn('bookingsIntransitArray', 'po', 'air').countValue > 0 ||
                        (countOfTypeFn('bookingsIntransitArray', 'po', 'sea').countValue > 0 && (
                            <div className="info-type-2">
                                <div className="dashboard-heading">In Transit</div>
                                {countOfTypeFn('bookingsIntransitArray', 'po', 'sea').countValue > 0 && (
                                    <div className="icon-content-holder">
                                        <div className="icon-holder bg-yellow">
                                            <svg className="svg-icon aeroplane-icon">
                                                <use href="#shipfillIcon">
                                                    <title>Aeroplane</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="content-name">
                                            Bookings in Transit - Sea:
                                            <span className="count-text">
                                                {countOfTypeFn('bookingsIntransitArray', 'po', 'sea').countValue}
                                            </span>
                                        </div>
                                    </div>
                                )}
                                {countOfTypeFn('bookingsIntransitArray', 'po', 'air').countValue > 0 && (
                                    <div className="icon-content-holder">
                                        <div className="icon-holder bg-yellow">
                                            <svg className="svg-icon aeroplane-icon">
                                                <use href="#aeroplanefillIcon">
                                                    <title>Aeroplane</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="content-name">
                                            Bookings in Transit - Air:
                                            <span className="count-text">
                                                {countOfTypeFn('bookingsIntransitArray', 'po', 'air').countValue}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default TransitPop;
