import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { get } from 'src/services/HttpMethods';
import '../lcsBookingTiimeline.scss';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { RootState } from 'src/store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { lifeCycleSearchCategories } from './Common/constants';
import MaxLimitModal from '../MaxLimitModal';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    // const { type } = useParams();
    const navigate: any = useNavigate();
    const location: any = useLocation();
    const { profileType, userName } = useUserProfile();
    const [showModal, setShowModal] = useState(false);
    const [showMore, setshowMore] = useState(false);
    const { name: skuName, type: clientCode } = location?.state || {};
    const { selectedData } = useSelector((state: any) => state.lifeCycleSearch);
    const { favList, removeSuccess, addSuccess, isLoading, isError, error } = useSelector(
        (state: RootState) => state.lifecyclesearchFav
    );

    const [skuData, setSkuData] = useState<any>({});

    const fetchSkuSidePanelData = async () => {
        try {
            const response = await get(
                `/api/mgt/tracking/lifecycle/sp/sku?sku=${selectedData?.name ?? skuName}&clientCode=${clientCode}`,
                null
            );
            setSkuData(response);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchSkuSidePanelData();
    }, [selectedData, clientCode]);

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.sku,
                    favourite: 'SKU',
                    number: skuData?.sku,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">SKU</div>
                <div
                    className="timeline-head-value link-com"
                    onClick={() =>
                        navigate(`/profile/clients/${skuData?.clientId}/product_master`, {
                            state: {
                                isSkuorUser: skuData?.skuId,
                            },
                        })
                    }
                >
                    {skuData?.sku || '-'}
                </div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="cm-key-pair-details">
                {skuData?.status && (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Status</div>
                        <div className="cm-key-pair-value">
                            <div
                                className={`status-ui ${
                                    skuData?.status === 'ACTIVE' ? 'success-status' : 'error-status'
                                }  `}
                            >
                                {skuData?.status}
                            </div>
                        </div>
                    </div>
                )}
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Description</div>
                    <div className="cm-key-pair-value">{skuData?.description || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">
                        Type
                        {/*(Product/Commodity)*/}
                    </div>
                    <div className="cm-key-pair-value">{skuData?.type || '-'}</div>
                </div>
                {skuData?.vendors?.length ? (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">Vendor</div>
                        <div className="cm-key-pair-value">
                            {skuData?.vendors
                                ?.slice(0, showMore ? skuData?.vendors?.length : 3)
                                ?.map((vendor: any) => vendor)
                                .join(',')}
                            {skuData?.vendors?.length > 3 && !showMore && (
                                <div className="content-with-icon view-more">
                                    <a
                                        href="javascript:void(0)"
                                        className="file-remove-link"
                                        title="More"
                                        onClick={() => setshowMore(true)}
                                    >
                                        View More
                                    </a>
                                </div>
                            )}
                            {skuData?.vendors?.length > 3 && showMore && (
                                <div className="content-with-icon view-more">
                                    <a
                                        href="javascript:void(0)"
                                        className="file-remove-link"
                                        title="More"
                                        onClick={() => setshowMore(false)}
                                    >
                                        View Less
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">HS Code</div>
                    <div className="cm-key-pair-value">{skuData?.hsCode || '-'}</div>
                </div>

                {skuData?.uoms?.length ? (
                    <div className="cm-key-pair-items">
                        <div className="cm-key-pair-title">UOMs</div>
                        <div className="cm-key-pair-value">
                            {skuData?.uoms
                                ?.slice(0, showMore ? skuData?.uoms?.length : 3)
                                ?.map((uom: any) => uom)
                                .join(',')}
                            {skuData?.uoms?.length > 3 && !showMore && (
                                <div className="content-with-icon view-more">
                                    <a
                                        href="javascript:void(0)"
                                        className="file-remove-link"
                                        title="More"
                                        onClick={() => setshowMore(true)}
                                    >
                                        View More
                                    </a>
                                </div>
                            )}
                            {skuData?.uoms?.length > 3 && showMore && (
                                <div className="content-with-icon view-more">
                                    <a
                                        href="javascript:void(0)"
                                        className="file-remove-link"
                                        title="More"
                                        onClick={() => setshowMore(false)}
                                    >
                                        View Less
                                    </a>
                                </div>
                            )}
                        </div>
                    </div>
                ) : null}
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`SKU : ${skuData?.sku}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
