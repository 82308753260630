import React from 'react';
// import './style.css';
import '../../assets/scss/components/_breadcrumbs.scss';
import { Link, useNavigate } from 'react-router-dom';
interface BioProps {
    second: string;
    third: string;
}
const Index: React.FC<BioProps> = (props) => {
    const navigate = useNavigate();

    return (
        <>
            <div className="breadcrumbs-holder">
                <ul className="breadcrumbs">
                    <Link to="/profile" className="dropdown-arrow" state={{ reload: true }}>
                        <svg className="arrow-icon">
                            <use xlinkHref="#downArrow">
                                <title>User details</title>
                            </use>
                        </svg>
                    </Link>
                    <li className="breadcrumb-list-item">
                        <Link to="/profile" className="breadcrumb-item" state={{ reload: true }}>
                            Organisations & Users
                        </Link>
                    </li>
                    <li className="breadcrumb-list-item">
                        {props.second === 'Users' ? (
                            <Link to="/users/forwarder" className="breadcrumb-item">
                                {props.second}
                            </Link>
                        ) : props.second.includes('roles') ? (
                            <Link to="/profile" className="breadcrumb-item">
                                {props.second}
                            </Link>
                        ) : props.second === 'Profiles' ? (
                            <Link to="/profile" className="breadcrumb-item">
                                {props.second}
                            </Link>
                        ) : props.second === 'vendor' ? (
                            <Link to="/vendor" className="breadcrumb-item">
                                Organisations
                            </Link>
                        ) : (
                            <a style={{ cursor: 'pointer' }} onClick={() => navigate(-1)} className="breadcrumb-item">
                                {props.second}
                            </a>
                        )}
                    </li>
                    <li className="breadcrumb-list-item">
                        <a href="#" className="breadcrumb-item">
                            {props.third}
                        </a>
                    </li>
                </ul>
            </div>
        </>
    );
};

export default Index;
