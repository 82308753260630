import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';

import {
    fetchClassbDropdownList,
    fetchSsrsClassbReport,
    sendClassBEmailFile,
    sendClassbFileToBlob,
} from '../reducers/classbReducer';
import { getClassBOptions, getSsrsApi, postClassbApi, postClassbEmail } from 'src/services/apis/classbApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getClassBOptions, payload);
        yield put({ type: fetchClassbDropdownList, payload: response });
    } catch (error) {
        yield put({ type: fetchClassbDropdownList, payload: error });
    }
}

function* getSsrsClassBReport({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getSsrsApi, payload);
        yield put({ type: fetchSsrsClassbReport, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* postClassBFile({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(postClassbApi, payload);
        yield put({ type: sendClassbFileToBlob, payload: response });
    } catch (error) {
        console.log(error);
    }
}

function* postClassBEmail({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(postClassbEmail, payload);
        yield put({ type: sendClassBEmailFile, payload: response });
    } catch (error) {
        console.log(error);
    }
}

function* clientSaga() {
    yield takeEvery('classbOptions/fetchClassbDropdownList', getListData);
    yield takeEvery('classbOptions/fetchSsrsClassbReport', getSsrsClassBReport);
    yield takeEvery('classbOptions/sendClassbFileToBlob', postClassBFile);
    yield takeEvery('classbOptions/sendClassBEmailFile', postClassBEmail);
}

// Export the saga (data-saga)
export default clientSaga;
