import React from 'react';
import BSTooltip from 'src/components/common/BSTooltip';
import { WorkTrendsDetails } from 'src/store/reducers/dashboard/workTrendsReducer';
interface Props {
    data: WorkTrendsDetails[];
}

const Index: React.FC<Props> = ({ data }) => {
    let max: any = 10;
    const dataNew = [...data];
    if (Math.max(...data.map((o: WorkTrendsDetails) => o.yAxis)) === 0) {
        max = 10;
    } else {
        max = Math.max(...data.map((o: WorkTrendsDetails) => o.yAxis));
    }
    function areAllActivitiesEmpty() {
        return data?.every((obj: any) => obj.yAxis === 0);
    }
    return (
        // --maxY:<maximum Y value> - Use this in below line

        <div className="chart-container" style={{ '--maxY': `${max}` } as React.CSSProperties}>
            {/* --yVal:<Y co-ordinate value> - Use this in below line */}
            {!areAllActivitiesEmpty() ? (
                dataNew?.reverse()?.map((res: WorkTrendsDetails, index: number) => (
                    <div
                        key={res.xAxis}
                        className={`bar ${index === data?.length - 1 ? 'highlight' : ''}`}
                        style={{ '--yVal': `${res.yAxis}` } as React.CSSProperties}
                    >
                        <span className="y-value">{res.yAxis}</span>
                        <BSTooltip
                            id={`barToolTio${index}`}
                            title={
                                <>
                                    <div>{res.label}</div>
                                    <div>{res.yAxis} Activities</div>
                                </>
                            }
                            position="top"
                            tooltipClassList="graph-tooltip"
                            delay="0.8"
                        >
                            <div className="color-fill"></div>
                        </BSTooltip>

                        <div className="x-value">{res.xAxis}</div>
                    </div>
                ))
            ) : (
                <div className="no-graphdata-wrapper">
                    <div className="no-graphdata-content">
                        <div className="no-data-svg">
                            <svg className="svg-icon nodata-icon">
                                <use xlinkHref="#gridNoContentIcon">
                                    <title>No Data</title>
                                </use>
                            </svg>
                        </div>
                        <p className="no-content-message">No works were performed for this period</p>
                    </div>
                </div>
            )}
        </div>
    );
};
export default Index;
