import React, { useState } from 'react';
import '../../../assets/scss/components/_modal.scss';
import { Position, error, success } from 'src/utils/toast';
import {
    overrideShipmentDetails,
    propagateBcnData,
    resetBpToBookingProposal,
} from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';

interface CancelProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setIsShipmentOverride: (value: boolean) => void;
    bpId: string;
}

const Index: React.FC<CancelProps> = ({ setModalView, setIsShipmentOverride, bpId }) => {
    const [reason, setReason] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const overrideBp = async () => {
        try {
            setModalView(true);
            setIsLoading(true);
            const data = { BP_OVR_Status: 'Approved' };
            await overrideShipmentDetails({ bpId, reason, data });
            success('Override of BP completed successfully', Position.TOP_RIGHT);
            setIsShipmentOverride(true);
        } catch (err: any) {
            console.log(err, 'err');
            error(err ?? 'Something Went Wrong', Position.TOP_RIGHT);
        } finally {
            setIsLoading(false);
            setModalView(false);
        }
    };

    return (
        <div className="app-modal medium-popup">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder medium-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Override Booking Proposal</div>
                    <button
                        className="app-btn modal-close-btn"
                        onClick={() => {
                            setModalView(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="warning-text">
                            <div>
                                <svg className="svg-icon error-icon">
                                    <use xlinkHref="#errorIcon">
                                        <title>Warning</title>
                                    </use>
                                </svg>
                            </div>

                            <div className="error-text">This will override the booking additional details</div>
                        </div>

                        <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                            <label htmlFor="name" className="modal-input-label">
                                Reason<span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-field-holder">
                                <textarea
                                    name="reason"
                                    className="input-text"
                                    placeholder="Enter the reason to override booking additional details"
                                    value={reason}
                                    onChange={(e) => {
                                        setReason(e.target.value);
                                    }}
                                    required
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Back"
                        onClick={() => {
                            setModalView(false);
                        }}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Submit"
                        disabled={reason ? false : true}
                        onClick={() => {
                            overrideBp();
                        }}
                    >
                        <span className="button-text footer-button-text">Submit</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Index;
