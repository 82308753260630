import { createSlice } from '@reduxjs/toolkit';
import { coloaderTableHeaders } from 'src/utils/constants';
import { Content } from 'src/services/apis/profiles/coloader';

interface IColoaderHeader {
    bdTabComplete: boolean;
    contractTabComplete: boolean;
    or_PK: string;
}
export interface ColoaderState {
    coloaders: Content[];
    headerTab: any[]; //TODO: update correct type affter confirmation
    isLoading: boolean;
    isLoading2: boolean;
    currentPage: number;
    columnStatus: string;
    perPageCount: number;
    totalPages: number;
    totalItems: number;
    filterValues: any;
    selectedFilter: any;
    coloaderHeader: IColoaderHeader;
}

const initialState: ColoaderState = {
    coloaders: [],
    headerTab: [],
    isLoading: true,
    isLoading2: true,
    currentPage: 0,
    columnStatus: coloaderTableHeaders.status,
    perPageCount: 10,
    totalPages: 2,
    totalItems: 10,
    filterValues: {},
    selectedFilter: null,
    coloaderHeader: {
        bdTabComplete: false,
        contractTabComplete: false,
        or_PK: '',
    },
};

export const dataSlice = createSlice({
    name: 'coloader',
    initialState,
    reducers: {
        setDefault: (state) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchColoaderData: (state, action) => {
            state.isLoading = true;
            state.columnStatus = action.payload?.columnStatus;
            state.currentPage = action.payload?.currentPage;
            state.coloaders = action.payload.coloaders?.content;
            state.totalPages = action.payload.coloaders?.totalPages;
            state.totalItems = action.payload.coloaders?.totalElements;
            state.filterValues = action.payload.coloaders?.distinctColumnValues;
            state.isLoading = false;
            state.isLoading2 = true;
        },
        fetchHeaderData: (state, action) => {
            state.headerTab = action.payload.headerTab?.info;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading2 = action.payload;
        },
        searchColoaderData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload?.columnStatus;
            state.coloaders = action.payload.coloaders?.content;
            state.totalPages = action.payload.coloaders?.totalPages;
            state.totalItems = action.payload.coloaders?.totalElements;
            state.filterValues = action.payload.coloaders?.distinctColumnValues;
            state.isLoading = false;
            state.isLoading2 = false;
        },
        setselectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
        fetchHeaderDataColoader: (_state, _action) => {
            // console.log(state);
        },
        setHeaderDataColoader: (state, action) => {
            state.coloaderHeader = action.payload?.info;
        },
    },
});

export const {
    fetchColoaderData,
    fetchHeaderData,
    setPageCount,
    isLoadingFn,
    setDefault,
    searchColoaderData,
    setselectedFilter,
    setCurrentPage,
    fetchHeaderDataColoader,
    setHeaderDataColoader,
} = dataSlice.actions;
export default dataSlice.reducer;
