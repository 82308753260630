import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import * as React from 'react';

interface CellRenderProps {
    originalProps: GridCellProps;
    td: React.ReactElement<HTMLTableCellElement>;
    enterEdit: (dataItem: any, field: string | undefined) => void;
    editField: string | undefined;
}

interface RowRenderProps {
    originalProps: GridRowProps;
    tr: React.ReactElement<HTMLTableRowElement>;
    exitEdit: () => void;
    editField: string | undefined;
}

export const CellRender = (props: CellRenderProps) => {
    const dataItem = props.originalProps.dataItem;
    const cellField = props.originalProps.field;
    const inEditField = dataItem[props.editField || ''];
    const additionalProps =
        cellField && cellField === inEditField
            ? {
                  ref: (td: any) => {
                      const input = td && td.querySelector('input');
                      const activeElement = document.activeElement;

                      if (!input || !activeElement || input === activeElement || !activeElement.contains(input)) {
                          return;
                      }

                      if (input.type === 'checkbox') {
                          input.focus();
                      } else {
                          input.select();
                      }
                  },
              }
            : {
                  onClick: () => {
                      props.enterEdit(dataItem, cellField);
                  },
              };

    if (props?.td?.props) {
        const clonedProps: any = { ...props?.td?.props, ...additionalProps };
        // @ts-ignore
        return React.cloneElement(props.td, clonedProps, props?.td?.props?.children);
    } else {
        return props.td;
    }
};

export const RowRender = (props: RowRenderProps) => {
    let preventExit = false;
    const trProps = {
        ...props.tr.props,
        onMouseDown: () => {
            preventExit = true;
        },
        onBlur: () => {
            if (!preventExit) {
                props.exitEdit();
            }
            preventExit = false;
        },
    };
    // @ts-ignore
    return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};
