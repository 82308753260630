import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { RootState } from 'src/store/reducers';

import '../todo-grid-view.scss';

const Index: React.FC<any> = ({ path }) => {
    const navigate = useNavigate();
    const { todoItem, isLoading } = useSelector((state: RootState) => state.dashboardTodo);
    return (
        <>
            {!todoItem?.receiptListingDtos?.length && !isLoading && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No recent activity from the last 30 Days</p>
                </div>
            )}
            <div className="view-list-grid draft-receipt">
                {todoItem?.receiptListingDtos?.map((re: any) => {
                    return (
                        <div className={`row-wrapper  ${!re?.isViewed ? ' click-active' : ''}`}>
                            <div className="list-grid">
                                <>
                                    <div className="grid-child-item col-Receipt">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Receipt No.</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">{re.receiptNo}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-client">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Client</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">{re.clientName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-vendor">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Vendor</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">{re.vendorName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-blp">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">BLP</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">{re.blpName}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-prd">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Planned Receipt Date</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">
                                                        {re?.plannedGoodsRecvdDate
                                                            ? moment(re?.plannedGoodsRecvdDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-ard">
                                        <div className="grid-child-data list-view-items">
                                            <div className="content-list-wrapper">
                                                <div className="content-heading-holder">
                                                    <span className="tab-lite-heading">Actual Receipt Date</span>
                                                </div>
                                                <div className="content-detail">
                                                    <span className="sub-content selected-color">
                                                        {re?.actualGoodsRecvdDate
                                                            ? moment(re?.actualGoodsRecvdDate).format('DD-MM-YYYY')
                                                            : '-'}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                        <div className="status-badge">
                                            <div className="item-count-status-holder">
                                                <div className="grid-item-count-status status-ui disabled-status">
                                                    <span className="status-text">{re.shippingMode}</span>
                                                </div>
                                            </div>
                                            <div className="item-count-status-holder">
                                                <div className="grid-item-count-status status-ui disabled-status">
                                                    <span className="status-text">{re.skuCount} SKU</span>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            title="View Receipt"
                                            className="app-btn app-btn-secondary grid-btn"
                                            onClick={() => {
                                                localStorage.setItem('receiptNo', re?.receiptNo);
                                                navigate(path(re.receiptId));
                                            }}
                                        >
                                            <span className="button-text footer-button-text">View Receipt</span>
                                        </button>
                                    </div>
                                </>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
};
export default Index;
