interface Params {
    inputKey: string;
    currentValue: number;
}
export const validateFractionInput = ({ inputKey, currentValue }: Params): boolean => {
    if (!/[0-9.]/.test(inputKey)) {
        return false;
    } else if (
        inputKey === '.' &&
        (String(currentValue).length < 1 ||
            isNaN(currentValue) ||
            (String(currentValue).length && String(currentValue).includes('.')))
    ) {
        return false;
    }
    return true;
};
