import { getClientCode } from 'src/utils';
import { get, patch } from '../../HttpMethods';

export const getBpHeaderData = async (payload: { bpId: string; profileType: string }) => {
    try {
        let clientCode = null;
        if (payload.profileType === 'client') {
            clientCode = await getClientCode(payload.profileType);
        }
        const res = await get(
            `api/bkg/bp/header/${payload.bpId}/${payload.profileType}${clientCode ? `?clientCode=${clientCode}` : ''}`,
            null
        );
        return res.info;
    } catch (err) {
        return err;
    }
};
export const getAgentIntegrationData = async (payload: { agentCode: string }) => {
    try {
        const res = await get(
            `api/bkg/agents/integration?agentCode=${payload.agentCode}&integration=Goods Issued`,
            null
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const cancelBookingProposal = async (payload: {
    bpId: string;
    name: string;
    userName: string;
    reason: string;
    cancelDate: string;
}) => {
    try {
        const res = await patch(
            `api/bkg/bp/cancel/${payload.bpId}?name=${payload.name}&userName=${payload.userName}&reason=${
                payload.reason
            }
            ${payload.cancelDate ? '&cancelDate=' + payload.cancelDate : ''}`,
            null
        );
        return res;
    } catch (err: any) {
        throw err.response;
    }
};
export const updateAutoApprovalFailedViewStatus = async (payload: { bpId: string; profileType: string }) => {
    try {
        const res = await patch(
            `api/bkg/bp/closemodal/${payload.bpId}/${payload.profileType}/autoapprovalfailed`,
            null
        );
        return res;
    } catch (err: any) {
        throw err.response;
    }
};
