import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { isLoadingFn, setWarning, saveFiles } from 'src/store/reducers/bookingProposalAir/attachmentsReducer';
import {
    downloadAllFiles,
    downloadSingleFile,
    getAllFiles,
    removeFile,
    uploadFile,
} from 'src/services/apis/bookingProposalAir/attachmentApis';

function* getFileList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const files: AxiosResponse<any> = yield call(getAllFiles, payload);
        yield put({ type: saveFiles, payload: files });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* uploadSingleFile({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const files: AxiosResponse<any> = yield call(uploadFile, payload);
        if ((files as any)?.code === 'ERR_BAD_REQUEST') {
            yield put({
                type: setWarning,
                payload: {
                    errorStat: true,
                    message: (files as any)?.response?.data?.data,
                },
            });
        }
    } catch (err) {
    } finally {
        if (payload?.id) {
            const files: AxiosResponse<any> = yield call(getAllFiles, payload?.id);
            yield put({ type: saveFiles, payload: files });
        }
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* removeSingleFile({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(removeFile, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        if (payload?.id) {
            const files: AxiosResponse<any> = yield call(getAllFiles, payload?.id);
            yield put({ type: saveFiles, payload: files });
        }
        yield put(hideLoading());
    }
}

function* downloadSingle({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(downloadSingleFile, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* downloadAll({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(downloadAllFiles, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* attachmentSaga() {
    yield takeEvery('bookingProposalAirAttachment/fetchFiles', getFileList);
    yield takeEvery('bookingProposalAirAttachment/uploadFiles', uploadSingleFile);
    yield takeEvery('bookingProposalAirAttachment/removeSingleFile', removeSingleFile);
    yield takeEvery('bookingProposalAirAttachment/downloadSingle', downloadSingle);
    yield takeEvery('bookingProposalAirAttachment/downloadAll', downloadAll);
}

export default attachmentSaga;
