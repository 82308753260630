import { createSlice } from '@reduxjs/toolkit';

export const integrationSlice = createSlice({
    name: 'vendorIntegrationDetails',
    initialState: {
        integrationData: [{ vi_integrationtype: '', vi_functiontype: '', vi_pk: '' }],
        isLoading: true,
        response: {},
        vn_pk: '',
        isError: false,
    },
    reducers: {
        addVendorIntegrationData: (state, action) => {
            state.isLoading = true;
            state.vn_pk = action.payload.vn_pk;
            state.isLoading = false;
        },
        fetchVendorIntegrationData: (state, action) => {
            state.isLoading = true;
            state.integrationData = action.payload.integrationData;
            state.vn_pk = action.payload.vn_pk;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        submitAndActivate: (state, action) => {
            state.isLoading = true;
            state.vn_pk = action.payload.vn_pk;
            state.isLoading = false;
        },
    },
});

export const { addVendorIntegrationData, fetchVendorIntegrationData, isLoadingFn, submitAndActivate } =
    integrationSlice.actions;
export default integrationSlice.reducer;
