export const shipmentGridDetails = {
    ['sub-shipment']: {
        fileName: 'Sub Shipments',
        columns: [
            {
                field: 'shipmentId',
                title: 'Shipment ID',
                template: '<a class="link-com" href="/scr/fwd/shp/#=shipmentId#/shipment">#=shipmentId#</a>',
            },
            // {
            //     field: 'entryStatus', // TODO
            //     title: 'Entry Status',
            // },
            {
                field: 'containerMode',
                title: 'Cont. Mode',
            },
            {
                field: 'shipmentIncoTerm',
                title: 'Inco Terms',
            },
            {
                field: 'portOfLoading',
                title: 'Loading',
            },
            {
                field: 'portOfDischarge',
                title: 'Discharge',
            },
            {
                field: 'documentedWeight',
                title: 'Documented Weight',
            },
            {
                field: 'documentedVolume',
                title: 'Documented Volume',
            },
            // {
            //     field: 'consignor',
            //     title: 'Consignor',
            // },
            // {
            //     field: 'consignee',
            //     title: 'Consignee',
            // },
            // {
            //     field: 'scacCode', // TODO
            //     title: 'Scac Code',
            // },
            // {
            //     field: 'vesselName',
            //     title: 'Vessel',
            // },
            // {
            //     field: 'voyageFlightNo',
            //     title: 'Voyage',
            // },
            {
                field: 'hbl', // TODO
                title: 'HBL',
            },
        ],
        fields: {
            shipmentId: { type: 'string' },
            // entryStatus: { type: 'string' },
            containerMode: { type: 'string' },
            shipmentIncoTerm: { type: 'string' },
            portOfLoading: { type: 'string' },
            portOfDischarge: { type: 'string' },
            documentedWeight: { type: 'number' },
            documentedVolume: { type: 'number' },
            // consignor: { type: 'string' },
            // consignee: { type: 'string' },
            // scacCode: { type: 'string' },
            // vesselName: { type: 'string' },
            // voyageFlightNo: { type: 'string' },
            hbl: { type: 'string' },
        },
    },
    containers: {
        fileName: 'Containers',
        columns: [
            {
                field: 'containerNumber',
                title: 'Container No.',
                width: '150px',
            },
            {
                field: 'type',
                title: 'Type',
                width: '120px',
            },
            {
                field: 'category',
                title: 'Category',
                width: '120px',
            },
            {
                field: 'isoCode',
                title: 'ISO Code',
                width: '120px',
            },
            {
                field: 'teu', // TODO
                title: 'TEU',
                width: '120px',
            },
            {
                field: 'deliveryMode',
                title: 'Delivery Mode',
                width: '160px',
            },
            {
                field: 'seal',
                title: 'Seal',
                width: '120px',
            },
            {
                field: 'goodsWeight',
                title: 'Goods Weight',
                width: '160px',
            },
            {
                field: 'grossWeight',
                title: 'Gross Weight',
                width: '160px',
            },
            {
                field: 'weightUnit',
                title: 'Weight Unit',
                width: '120px',
            },
        ],
        fields: {
            containerNumber: { type: 'string' },
            type: { type: 'string' },
            category: { type: 'string' },
            isoCode: { type: 'string' },
            teu: { type: 'string' },
            deliveryMode: { type: 'string' },
            seal: { type: 'string' },
            goodsWeight: { type: 'string' },
            grossWeight: { type: 'string' },
            weightUnit: { type: 'string' },
        },
    },
    packing: {
        fileName: 'Packing',
        columns: [
            {
                field: 'containerNumber',
                title: 'Container No.',
                width: '120px',
            },
            {
                field: 'goodsDescription',
                title: 'Goods Description',
                width: '120px',
            },
            // {
            //     field: 'poNumber', // TODO
            //     title: 'PO No.',
            //     width: '120px',
            // },
            {
                field: 'packQty',
                title: 'Pack Qty.',
                width: '120px',
            },
            {
                field: 'eaQty', // TODO
                title: 'EA Qty.',
                width: '120px',
            },
            {
                field: 'volume',
                title: 'Volume (m3)',
                width: '120px',
            },
            {
                field: 'weight',
                title: 'Weight (kg)',
                width: '120px',
            },
        ],
        fields: {
            containerNumber: { type: 'string' },
            goodsDescription: { type: 'string' },
            poNumber: { type: 'string' },
            packQty: { type: 'string' },
            eaQty: { type: 'string' },
            volume: { type: 'string' },
            weight: { type: 'string' },
        },
    },
    ['routing-legs']: {
        fileName: 'Routing Legs',
        columns: [
            {
                field: 'legOrder',
                title: 'Leg No.',
                width: '120px',
            },
            {
                field: 'legType',
                title: 'Leg Type',
                width: '120px',
            },
            // legStatus
            {
                field: 'legStatus',
                title: 'Leg Status',
                width: '120px',
            },
            {
                field: 'transportMode',
                title: 'Transport Mode',
                width: '180px',
            },
            {
                field: 'vesselName',
                title: 'Vessel Name',
                width: '180px',
            },
            {
                field: 'voyageFlightNo',
                title: 'Voyage / Flight No.',
                width: '220px',
            },
            {
                field: 'portOfLoading',
                title: 'Load Port',
                width: '180px',
            },
            {
                field: 'portOfDischarge',
                title: 'Unload Port',
                width: '180px',
            },
            {
                field: 'etd',
                title: 'ETD',
                width: '120px',
            },
            {
                field: 'atd',
                title: 'ATD',
                width: '120px',
            },
            {
                field: 'eta',
                title: 'ETA',
                width: '120px',
            },
            {
                field: 'ata',
                title: 'ATA',
                width: '120px',
            },
        ],
        fields: {
            legOrder: { type: 'string' },
            legType: { type: 'string' },
            legStatus: {type: 'string'},
            transportMode: { type: 'string' },
            vesselName: { type: 'string' },
            voyageFlightNo: { type: 'string' },
            portOfLoading: { type: 'string' },
            portOfDischarge: { type: 'string' },
            etd: { type: 'string' },
            atd: { type: 'string' },
            eta: { type: 'string' },
            ata: { type: 'string' },
        },
    },
    ['milestone-dates']: {
        fileName: 'Milestone Dates',
        columns: [
            {
                field: 'milestone', // TODO
                title: 'Milestone',
            },
            {
                field: 'description',
                title: 'Description',
            },
            {
                field: 'estimatedDate', // TODO
                title: 'Estimated Date',
            },
            {
                field: 'actualDate',
                title: 'Actual Date',
            },
        ],
        fields: {
            eventCode: { type: 'string' },
            description: { type: 'string' },
            estimatedDate: { type: 'string' },
            actualDate: { type: 'string' },
        },
    },
};
