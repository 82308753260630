import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../../BookingProposal/POSelection.scss';
import '../../../BookingProposal/BookingChangeVessel/ManualVesselEntry/manual-vessel-entry.scss';
import '../../../BookingProposal/BookingChangeVessel/SelectedFromSchedule/selected-from-schedule.scss';
import ListGrid from '../../ListGrid';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { getOrganisationData } from 'src/utils';
import { addToBp, fetchExistingSchedules } from 'src/store/reducers/bookingProposal/sailingReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { addContainerNac } from 'src/store/reducers/bookingProposal/additionalDetailsNacReducer';

/* eslint-disable no-undefined */
/* eslint no-var: off */

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];
const sailingDataValues = [
    {
        title: 'Maersk',
        value: 'Maersk',
    },
];
const dates = [
    {
        title: 'Maersk',
        value: 'Maersk',
    },
];
const bpStatus = [
    {
        title: 'Maersk',
        value: 'Maersk',
    },
];

const Index: React.FC = () => {
    const [selectedContract, setselectedContract] = useState(null || {});
    const { isLoading2, existingSchedules } = useSelector((state: RootState) => state.sailingRed);
    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);

    const dispatch = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchExistingSchedules(id));
    }, [id]);

    const addSelectedContract = () => {
        localStorage.setItem('itemSelectedSailing', 'true');
        var transitPorts = JSON.parse(JSON.stringify(selectedContract as any));
        transitPorts?.sailingScheduleTransitPorts?.forEach((element: any) => {
            element['plannedETA'] = element.plannedETA
                ? moment(element.plannedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['plannedETD'] = element.plannedETD
                ? moment(element.plannedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['portCutOff'] = element.portCutOff
                ? moment(element.portCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['predictedETA'] = element.predictedETA
                ? moment(element.predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['predictedETD'] = element.predictedETD
                ? moment(element.predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['vgmCutOff'] = element.vgmCutOff
                ? moment(element.vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['gateInCutOff'] = element.gateInCutOff
                ? moment(element.gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['docCutOff'] = element.docCutOff
                ? moment(element.docCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
            element['gateInOpen'] = element.gateInOpen
                ? moment(element.gateInOpen).format('yyyy-MM-DD') + ' 00:00:00'
                : null;
        });
        const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
        const listOfUserOrganisations = getOrganisationData();
        const indexVal = localStorage.getItem('roleIndex');
        const roles = userData?.roles[indexVal as any];

        const payload = {
            sailingAddRequest: {
                sailingReferenceNumber: (selectedContract as any).sailingReferenceNumber || 0, //TODO: update contract type and remove any
                bookingProposalId: id,
                sailingId: (selectedContract as any)?.sailingId,
                contractType: (selectedContract as any).contractType,
                contractId: (selectedContract as any).contractId,
                contractName: (selectedContract as any).contractName,
                shippingLine: (selectedContract as any).shippingline,
                shippingLineCode: (selectedContract as any).shippinglineCode,
                transitDays: (selectedContract as any).plannedTransitDays,
                serviceType: (selectedContract as any).serviceType,
                isPrimary: (selectedContract as any).isPrimaryClient,
                changeVessel:
                    bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' || bpStatus === 'Booked'
                        ? true
                        : false,
                eta: (selectedContract as any).plannedETA
                    ? moment((selectedContract as any).plannedETA).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                etd: (selectedContract as any).plannedETD
                    ? moment((selectedContract as any).plannedETD).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                vesselVoyage: (selectedContract as any).voyageId,
                gateInDate: (selectedContract as any).gateInDate
                    ? moment((selectedContract as any).gateInDate).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                //gateInDate: '2023-06-20 00:00:00',
                contractVersion: (selectedContract as any)?.contractVersion,
                isManuallyEntered: (selectedContract as any)?.isManuallyEntered === 0 ? false : true,
                predictedETD: (selectedContract as any).predictedETD
                    ? moment((selectedContract as any).predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                predictedETA: (selectedContract as any).predictedETA
                    ? moment((selectedContract as any).predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                predictedTransitDays: (selectedContract as any).predictedTransitDays,
                service: (selectedContract as any).service,
                destinationPortCode: (selectedContract as any).destinationPortCode,
                destinationPortName: (selectedContract as any).destinationPortName,
                destinationPortTerminal: (selectedContract as any).destinationPortTerminal,
                originPortCode: (selectedContract as any).originPortCode,
                originPortName: (selectedContract as any).originPortName,
                originPortTerminal: (selectedContract as any).originPortTerminal,
                vgmCutOff: (selectedContract as any).vgmCutOff
                    ? moment((selectedContract as any).vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                gateInCutOff: (selectedContract as any).gateInCutOff
                    ? moment((selectedContract as any).gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                standardBookingCutOff: (selectedContract as any).standardBookingCutOff
                    ? moment((selectedContract as any).standardBookingCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                gateInOpen: (selectedContract as any).gateInOpen
                    ? moment((selectedContract as any).gateInOpen).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                docCutOff: (selectedContract as any).docCutOff
                    ? moment((selectedContract as any).docCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                    : null,
                sailingScheduleTransitPorts:
                    (selectedContract as any)?.sailingScheduleTransitPorts?.length === 0
                        ? []
                        : transitPorts?.sailingScheduleTransitPorts,
            },
            notification: { roles, listOfUserOrganisations },
        };

        dispatch(addToBp(payload));
        setTimeout(() => {
            //setselectedView(true);
            navigate(`/lclbookingproposal/${id}/SailingSelectionDetails`, {
                state: {
                    selected: true,
                },
            });
        }, 1000);
    };

    return (
        <>
            <div className="main-wrapper container selected-change-vessel-wrapper lcl-existing-schelude">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={'#'}
                        thirdName={'Sailing Schedule'}
                        thirdUrl={'#'}
                        fourthName={'Existing Schedules'}
                        fourthUrl={'#'}
                        summaryStats={summaryStats}
                        backUrl={'/booking_list'}
                    />
                    <div className="pageheading-holder">
                        <div className="back-btn-holder">
                            <button
                                className="back-btn app-btn"
                                onClick={() => {
                                    navigate(`/lclbooking_list/${id}/SailingSelectionDetails`);
                                }}
                            >
                                <svg className="svg-icon back-icon">
                                    <use href="#previousArrowIcon">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </button>
                            <h2 className="page-heading">Existing Schedules</h2>
                        </div>
                    </div>
                    {isLoading2 && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                </div>
                {/* DATA GRID SECTION */}
                <div className="content-section main-wrapper existing-schelude">
                    <div className="main-wrapper">
                        {/* <div className="no-data-content checking-data-content">
                            {View}
                            <p className="no-content-message">Checking for available contracts</p>
                        </div> */}
                        <ListGrid
                            data={sailingDataValues}
                            dates={dates}
                            selectedValue={setselectedContract}
                            bpStatus={bpStatus}
                            newData={existingSchedules}
                            selectedContract={selectedContract}
                        ></ListGrid>
                    </div>
                </div>
                <div className="footer-bootom-row backdrop-active">
                    <div className="footer-button-holder ">
                        <>
                            <a
                                title="Cancel"
                                className="app-btn app-btn-secondary footer-btn"
                                onClick={() => {
                                    setselectedContract({});
                                    navigate(-1);
                                }}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </a>
                            <a
                                title="Submit"
                                className={`app-btn app-btn-primary footer-btn ${
                                    (selectedContract as any)?.contractType !== undefined ? '' : 'disabled'
                                }`}
                                onClick={() => {
                                    addSelectedContract();
                                    localStorage.removeItem('changeVessellcl');
                                }}
                            >
                                <span className="button-text footer-button-text">Submit</span>
                            </a>
                        </>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
