import React from 'react';
import './contract-modal.scss';
import '../../../../assets/scss/components/_modal.scss';

interface BioProps {
    removeModalVisibility: boolean;
    setRemoveModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    clientData: any;
    setAllClientsData: any;
    primaryClientsData: any;
    setPrimaryClientsData: any;
    secondaryClientsData: any;
    setSecondaryClientsData: any;
    filterUsed: any;
}
const Index: React.FC<BioProps> = (props) => {
    return (
        <>
            {props.removeModalVisibility ? (
                <div className="app-modal profile-popup container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Remove</h2>
                            <button
                                onClick={() => props.setRemoveModalVisibility(false)}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                Removing {props.clientData.orgName} would also remove its Child profiles. Are you sure
                                to continue ?
                            </p>
                            <div className="box-content-holder">
                                <h6 className="details-box-heading">Client Profiles</h6>
                                <div className="suggestion-container">
                                    {props.clientData.childClients.map((res: any, index: number) => (
                                        <div key={index} className="content-card">
                                            <span className="main-content">{res.orgName}</span>
                                        </div>
                                    ))}

                                    {/* <div className="content-card">
                                        <span className="main-content">Kohler LLC</span>
                                    </div>
                                    <div className="content-card">
                                        <span className="main-content">West Group</span>
                                    </div>
                                    <div className="content-card">
                                        <span className="main-content">Altenwerth Group</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => props.setRemoveModalVisibility(false)}
                                className="app-btn app-btn-secondary modal-btn"
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                onClick={() => {
                                    props.setRemoveModalVisibility(false);
                                    if (props.filterUsed === 'primaryClient') {
                                        const result = props.primaryClientsData.filter(function (o1: any) {
                                            return !props.clientData.childClients.some(function (o2: any) {
                                                return (
                                                    o1.value === o2.clientId || o1.value === props.clientData.clientId
                                                );
                                            });
                                        });

                                        props.setPrimaryClientsData(result);
                                    } else {
                                        const result = props.secondaryClientsData.filter(function (o1: any) {
                                            return !props.clientData.childClients.some(function (o2: any) {
                                                return (
                                                    o1.value === o2.clientId || o1.value === props.clientData.clientId
                                                );
                                            });
                                        });

                                        props.setSecondaryClientsData(result);
                                    }

                                    const newArray = props.clientData.childClients.map((res: any) => ({
                                        label: res.orgName,
                                        value: res.clientId,
                                    }));

                                    props.setAllClientsData((prev: any) => [
                                        ...prev,
                                        { label: props.clientData.orgName, value: props.clientData.clientId },
                                        ...newArray,
                                    ]);
                                }}
                                style={{ marginLeft: '8px' }}
                                className="app-btn app-btn-primary modal-btn"
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
