import { get, post } from '../HttpMethods';

export interface UpdateThresholdPayload {
    userName: string;
    threshold: number | string;
}
export interface UpdateThresholdResponse {
    se_PK: string;
    createdByUser: string;
    thresholdValue: number | string;
    userDefinedText1: null;
    userDefinedText2: null;
    userDefinedText3: null;
    userDefinedText4: null;
    userDefinedInt1: null;
    userDefinedInt2: null;
    userDefinedInt3: null;
    userDefinedInt4: null;
    userDefinedNumeric1: null;
    userDefinedNumeric2: null;
    userDefinedNumeric3: null;
    userDefinedNumeric4: null;
    userDefinedDateTime1: null;
    userDefinedDateTime2: null;
    userDefinedDateTime3: null;
    userDefinedDateTime4: null;
    createdDateTime: Date;
    modifiedDateTime: Date;
    modifiedByUser: null;
    manuallyEntered: null;
    ps: string;
}

// Get threshold value for user
export const getThresholdValue = async ({ userName }: { userName: string }) => {
    try {
        const res = await get(`api/ord/setting/threshold/${userName}`, null);

        return res;
    } catch (err) {
        return console.error(err);
    }
};

//Update threshold value
export const updateThresholdValue = async ({ userName, threshold }: UpdateThresholdPayload) => {
    try {
        const res: UpdateThresholdResponse = await post(
            `api/ord/setting/threshold/${userName}/${threshold}`,
            null,
            null
        );

        return res;
    } catch (er) {
        throw er;
    }
};
