/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface datesAoDataDetails {
    aoId: string;
    aoNumber: string;
    orderDate: string;
    startShipWindowDate: string;
    endShipWindowDate: string;
    projectedEtaDate: string;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

interface datesAoData {
    status: number;
    message: string;
    info: datesAoDataDetails;
    data: any;
}

interface DatesAoState {
    isLoading: boolean;
    datesAo?: datesAoData;
    datesAoSuccess: any;
    datesAoError: any;
    isError?: boolean;
    id?: number;
}

const initialState: DatesAoState = {
    isLoading: true,
    datesAo: undefined,
    datesAoSuccess: undefined,
    datesAoError: undefined,
    isError: false,
    id: undefined,
};

export const datesAoSlice = createSlice({
    name: 'datesAo',
    initialState,
    reducers: {
        fetchDatesAoList: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchDatesAoListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.datesAo = action.payload;
        },
        fetchDatesAoListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.datesAo = action.payload;
        },
        createDatesAo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        createDatesAoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.datesAoSuccess = action.payload;
            state.datesAoError = {};
        },
        createDatesAoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.datesAoSuccess = {};
            state.datesAoError = action.payload;
        },
        updateDatesAo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        updateDatesAoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.datesAoSuccess = action.payload;
            state.datesAoError = {};
        },
        updateDatesAoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.datesAoSuccess = {};
            state.datesAoError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.datesAoError = undefined;
            state.datesAoSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchDatesAoList,
    fetchDatesAoListSuccess,
    fetchDatesAoListFailed,
    createDatesAo,
    createDatesAoSuccess,
    createDatesAoFailure,
    updateDatesAo,
    updateDatesAoSuccess,
    updateDatesAoFailure,
    isLoadingFn,
    clearFailure,
} = datesAoSlice.actions;

export const selectDatesAoLoading = (state: RootState) => state.datesAoData.isLoading;
export const selectDatesAo = (state: RootState) => state.datesAoData.datesAo;
export const selectDatesAoSuccess = (state: RootState) => state.datesAoData.datesAoSuccess;
export const selectDatesAoError = (state: RootState) => state.datesAoData.datesAoError;
export const selectDatesAoIsError = (state: RootState) => state.datesAoData.isError;

export default datesAoSlice.reducer;
