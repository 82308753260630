import React, { useEffect, useState } from 'react';
import ContractModal from './ContractModal';
import ProfileaddModal from './profileaddModal';
import RemoveModal from './removeModal';
import '../shipping-tab.scss';
import './contract-detail.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TableWithFilter from '../../../../components/TableWithFilter';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    selectContractDetailUser,
    fetchContractDetailsList,
    // fetchContractDetailsColumnList,
    selectContractDetailCurrentPage,
    selectContractDetailColumnStatus,
    selectContractDetailtotalNoOfPages,
    selectContractDetailtotalNoOfItems,
    selectContractDetailLoading,
    clearContractDetails,
    selectContractDetailColumnUser,
    // ContractDetailsDocumentsDtoList,
    fetchAuthorDetails,
    fetchAgentUsersDetails,
    fetchIncoTermsDetails,
    // fetchPortDetails,
    fetchAllClientsDetails,
    selectContractDetailAllClient,
    searchContractDetailsData,
} from '../../../../store/reducers/profiles/shippingLine/ContractDetailsReducer';
import { paginationLimit } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination/Pagination';
// import { useMeasure } from 'react-use';
import ColumnFilter from '../../../../components/TableWithFilter/filter';
import { contractDetails as tableProperties } from '../../../../utils/tableStructure/shippingLine';
import CancelModal from '../../../../components/common/CancelModal';
import ShowForPermissions from '../../../../ShowForPermissions';
import { activateColoader } from 'src/services/apis/profiles/coloader/contractDetailsApi';
import { error, Position, success } from 'src/utils/toast';
import {
    fetchHeaderDataCarriers,
    fetchShippingLineBasicDetailsList,
    selectShippingLineBasicDetails,
} from 'src/store/reducers/profiles/shippingLine/basicDetailsReducer';
import HeaderTabs from '../HeaderTabs';
import SubmitModal from './SubmitModal';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

const Index: React.FC = () => {
    const { id } = useParams();
    const { duplicatedScPk } = useSelector((state: RootState) => state.contractData);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [activating, setActivating] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isFromView, setIsFromView] = useState(false);
    const [mode, setMode] = useState('add');
    const [displayCancel, setDisplayCancel] = useState(false);
    const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
    const [submitModalVisibility, setSubmitModalVisibility] = useState(false);
    const [addModalVisibility, setAddModalVisibility] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const contractDetails = useAppSelector(selectContractDetailUser);
    const contractDetailsColumn = useAppSelector(selectContractDetailColumnUser);
    const [contractDetailColumnData, setContractDetailColumnData] = useState<any>([]);
    const [contractDetailColumnName, setContractDetailColumnName] = useState<any>('');
    const [currentColumnName, setCurrentColumnName] = useState<any>('');
    const [contractData, setContractData] = useState({});
    const isLoading = useAppSelector(selectContractDetailLoading);
    const [selectedHeader, setSelectedHeader] = useState('sd_docname');
    const currentPage = useAppSelector(selectContractDetailCurrentPage);
    const columnStatus = useAppSelector(selectContractDetailColumnStatus);
    const totalNoOfPages = useAppSelector(selectContractDetailtotalNoOfPages);
    const totalNoOfItems = useAppSelector(selectContractDetailtotalNoOfItems);
    const allClient = useAppSelector(selectContractDetailAllClient);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [selectedFilter, _setselectedFilter] = useState<any | null>(null);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>([]);
    const [setPosition, setSetPosition] = useState<number>(0);
    // const [refName, { width: widthName }] = useMeasure<HTMLTableCellElement>();
    // const [refVersion, { width: widthVersion }] = useMeasure<HTMLTableCellElement>();
    // const [refAuthor, { width: widthAuthor }] = useMeasure<HTMLTableCellElement>();
    // const [refForwarderSignatory, { width: widthForwarderSignatory }] = useMeasure<HTMLTableCellElement>();
    // const [refShippingLineSignatory, { width: widthShippingLineSignatory }] = useMeasure<HTMLTableCellElement>();
    // const [refStatus, { width: widthStatus }] = useMeasure<HTMLTableCellElement>();
    // const [refStartDate, { width: widthStartDate }] = useMeasure<HTMLTableCellElement>();
    // const [refEndDate, { width: widthEndDate }] = useMeasure<HTMLTableCellElement>();
    // const [refRenewal, { width: widthRenewal }] = useMeasure<HTMLTableCellElement>();
    const [deletedClient, setDeletedClient] = useState<any>({});
    const [selectedClient, setSelectedClient] = useState<any>({});
    const [allClientsData, setAllClientsData] = useState<any>([]);
    const [primaryClientsData, setPrimaryClientsData] = useState<any>([]);
    const [secondaryClientsData, setSecondaryClientsData] = useState<any>([]);
    const [filterUsed, setFilterUsed] = useState('primary');
    const [searchTerm, setSearchTerm] = useState<any>(null);
    const basicDetails = useAppSelector(selectShippingLineBasicDetails);

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        statusFilter: selectedFilter,
        columnOrder: !currentOrder,
        totalNoOfPages: totalNoOfPages,
        totalNoOfItems: totalNoOfItems,
        id,
    };

    // after duplicate contract, refresh the page
    useEffect(() => {
        if (duplicatedScPk) {
            window.location.reload();
        }
    }, [duplicatedScPk]);

    useEffect(() => {
        dispatch(clearContractDetails([]));
        dispatch(fetchAuthorDetails([]));
        dispatch(fetchAgentUsersDetails([]));
        dispatch(fetchIncoTermsDetails([]));
        // dispatch(fetchPortDetails([]));
        dispatch(fetchAllClientsDetails([]));
        dispatch(fetchShippingLineBasicDetailsList({ id }));
    }, []);

    useEffect(() => {
        if (contractDetailsColumn) {
            setContractDetailColumnData(
                (contractDetails as any)?.data?.info?.distinctColumnValues[contractDetailColumnName]
            );
            setStatusFilterSelected([]);
            setSearchName('');
        }
    }, [contractDetailsColumn, dispatch]);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        // if (keyword) {
        // const results = contractDetailsColumn?.info.filter((user: string) => {
        //     return user && user.toLowerCase().includes(keyword.toLowerCase());
        // });
        setContractDetailColumnData(
            (contractDetails as any)?.data?.info?.distinctColumnValues[contractDetailColumnName]
        );
        // } else {
        // setContractDetailColumnData(
        //     (contractDetails as any)?.data?.info?.distinctColumnValues[contractDetailColumnName]
        // );
        // }
        setSearchName(keyword);
    };

    useEffect(() => {
        if (searchTerm?.trim()?.length > 1) {
            dispatch(
                searchContractDetailsData({
                    ...payload,
                    keyword: searchTerm,
                    id,
                    currentPage: 0,
                    columnStatus: selectedHeader,
                    statusFilterColumn: contractDetailColumnName,
                    statusFilter: statusFilterSelected,
                    columnOrder: !currentOrder,
                })
            );
        } else {
            dispatch(
                fetchContractDetailsList({
                    ...payload,
                    id,
                    currentPage: 0,
                    columnStatus: selectedHeader,
                    statusFilterColumn: contractDetailColumnName,
                    statusFilter: statusFilterSelected,
                    columnOrder: !currentOrder,
                })
            );
        }
    }, [statusFilterSelected]);

    const [currentPageNumber, setCurrentPageNumber] = useState<null | number>(null);

    const activeContracts = basicDetails?.data?.info?.sl_Status === 'INACTIVE';

    const handleActivateColoader = async () => {
        setActivating(true);
        const response = await activateColoader(id ?? '');
        if (response.success) {
            success('Carrier has been activated', Position.TOP_RIGHT);
            navigate('/shippingline');
            dispatch(fetchHeaderDataCarriers(id));
        } else {
            error(response.message || 'Something went wrong', Position.TOP_RIGHT);
        }
        setActivating(false);
    };

    useEffect(() => {
        if (currentPageNumber) {
            if (searchTerm?.trim()?.length > 1) {
                const payloadData = { ...payload, keyword: searchTerm, perPageCount, currentPage: 0 };
                dispatch(searchContractDetailsData(payloadData));
            } else {
                dispatch(
                    fetchContractDetailsList({
                        ...payload,
                        currentPage: currentPageNumber - 1,
                        perPageCount: perPageCount,
                        columnStatus: selectedHeader,
                        // statusFilter: selectedFilter,
                        columnOrder: !currentOrder,
                        statusFilterColumn: contractDetailColumnName,
                        statusFilter: statusFilterSelected,
                    })
                );
            }
        }
    }, [currentPageNumber]);

    const sortByColumn = (columnName: string) => {
        setcurrentOrder(!currentOrder);
        setSelectedHeader(columnName);
        if (searchTerm?.trim()?.length > 1) {
            dispatch(
                searchContractDetailsData({
                    ...payload,
                    keyword: searchTerm,
                    currentPage: 0,
                    columnStatus: columnName,
                    columnOrder: currentOrder,
                    statusFilterColumn: contractDetailColumnName,
                    statusFilter: statusFilterSelected,
                })
            );
        } else {
            dispatch(
                fetchContractDetailsList({
                    ...payload,
                    currentPage: 0,
                    columnStatus: columnName,
                    columnOrder: currentOrder,
                    statusFilterColumn: contractDetailColumnName,
                    statusFilter: statusFilterSelected,
                })
            );
        }
    };
    const filterByColumn = (e: any, columnName: string) => {
        setTimeout(() => {
            setToggleDropdown(!toggleDropdown);
        }, 300);
        if (!toggleDropdown) {
            //dispatch(
            //    fetchContractDetailsColumnList({
            //        id,
            //        columnName,
            //    })
            //);
            setContractDetailColumnName(columnName);
            setTimeout(() => {
                setContractDetailColumnData((contractDetails as any)?.data?.info?.distinctColumnValues[columnName]);
            }, 250);
        }
        filterDistance(columnName);
        setSearchName('');
        filter(e);
        e.stopPropagation();
    };

    const filterDistance = (columnName: string) => {
        const el = document.getElementById(columnName);
        const distance = el?.getBoundingClientRect().left;
        distance && setSetPosition(distance - 30);
    };

    useEffect(() => {
        if (searchTerm?.trim()?.length > 2) {
            const payloadData = {
                ...payload,
                statusFilterColumn: contractDetailColumnName,
                statusFilter: statusFilterSelected,
                keyword: searchTerm,
                perPageCount,
                currentPage: 0,
            };
            dispatch(searchContractDetailsData(payloadData));
        } else if (searchTerm?.trim()?.length === 0) {
            dispatch(
                fetchContractDetailsList({
                    ...payload,
                    statusFilterColumn: contractDetailColumnName,
                    statusFilter: statusFilterSelected,
                    currentPage: 0,
                })
            );
        }
    }, [searchTerm]);

    return (
        <div
            onClick={() => {
                setPageCountDisplay(false);
            }}
            className="main-wrapper container"
        >
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link className="dropdown-arrow" to="/shippingline" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link to="/profile" className="breadcrumb-item">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to="/shippingline" className="breadcrumb-item">
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Carrier
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    {/* <Link to="/shippingline">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">{localStorage.getItem('shippinglineName')}</h2>
                </div>
                <HeaderTabs />
            </div>
            <div className="grid-section shipping-contract-grid loader-container grid-p-0-15">
                {(isLoading || activating) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-holder">
                    <div className="grid-header">
                        <div className="search-entry-holder">
                            <input
                                placeholder="Search Contract Details"
                                type="text"
                                className="search-input input-text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            ></input>
                            <div
                                className={`search-toolip ${
                                    searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                }`}
                            >
                                Enter atleast 3 characters to search
                            </div>
                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon"></use>
                            </svg>
                            <button
                                className="app-btn text-close-btn"
                                onClick={() => {
                                    if (searchTerm?.trim()?.length) {
                                        setSearchTerm('');
                                        dispatch(
                                            fetchContractDetailsList({
                                                ...payload,
                                                currentPage: 0,
                                                perPageCount: perPageCount,
                                                columnStatus: selectedHeader,
                                                statusFilter: selectedFilter,
                                                columnOrder: currentOrder,
                                            })
                                        );
                                    }
                                }}
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </div>
                        <div className="item-count-status-holder">
                            <div className="grid-item-count-status status-ui success-status">
                                <span className="count">{contractDetails?.data?.info?.totalActiveDocuments ?? 0}</span>
                                <span className="status-text">
                                    Active{' '}
                                    {contractDetails?.data?.info?.totalActiveDocuments === 1 ? 'Contract' : 'Contracts'}
                                </span>
                            </div>
                        </div>
                        <ShowForPermissions permission="edit" type="shipping">
                            <button
                                onClick={() => {
                                    if (buttonClicked) {
                                        setModalView(true);
                                    } else {
                                        // setTimeout(() => {
                                        setModalView(true);
                                        // }, 700);
                                        setButtonClicked(true);
                                    }
                                    setIsEdit(false);
                                    setMode('add');
                                    setContractData({});
                                }}
                                className="app-btn app-btn-secondary icon-button grid-top-btn"
                                title="Add Contracts"
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <use href="#plusIcon"></use>
                                </svg>
                                <span className="button-text client-button-text">Add Contract</span>
                            </button>
                        </ShowForPermissions>
                    </div>
                    <div
                        onClick={() => setToggleDropdown(false)}
                        onScroll={() => filterDistance('')}
                        className="grid-container"
                    >
                        {!contractDetails?.data?.info?.shippingLineDocumentResponseList.length && (
                            <div className="grid-no-content ">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No Contract Details Available</p>
                            </div>
                        )}
                        <div className="grid-scroll-content">
                            <TableWithFilter
                                name="contractDetails"
                                tableProperties={tableProperties}
                                data={contractDetails?.data?.info?.shippingLineDocumentResponseList}
                                setData={setContractData}
                                setIsEdit={setIsEdit}
                                setModalView={setModalView}
                                sortFunction={sortByColumn}
                                columnStatus={columnStatus}
                                currentOrder={currentOrder}
                                toggleDropdown={toggleDropdown}
                                columnName={contractDetailColumnName}
                                filterFunction={filterByColumn}
                                selectedFilterItem={statusFilterSelected}
                            />
                        </div>
                    </div>
                    {toggleDropdown && (
                        <ColumnFilter
                            setPosition={setPosition}
                            searchName={searchName}
                            setSearchName={setSearchName}
                            filter={filter}
                            data={contractDetailColumnData}
                            statusFilterSelected={statusFilterSelected}
                            setStatusFilterSelected={setStatusFilterSelected}
                            setToggleDropdown={setToggleDropdown}
                            isLoading={isLoading}
                            columnName={contractDetailColumnName}
                            currentColumnName={currentColumnName}
                            setCurrentColumnName={setCurrentColumnName}
                        />
                    )}
                    {totalNoOfItems > 0 ? (
                        <div className="grid-footer ">
                            <div className="grid-footer-left">
                                <div className="footer-text">Showing</div>
                                <div className="row-per-page">
                                    <div
                                        className={
                                            'dropdown-wrap pagination-dropdown menu-up ' +
                                            (PageCount ? 'dropdown-open' : '')
                                        }
                                    >
                                        {/* <div className="dropdown-wrap pagination-dropdown menu-up dropdown-open"> */}
                                        <button
                                            className="menu-btn app-btn"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setPageCountDisplay(!PageCount);
                                            }}
                                        >
                                            <span className="btn-text">
                                                {contractDetails?.data?.info?.shippingLineDocumentResponseList &&
                                                perPageCount >
                                                    contractDetails?.data?.info?.shippingLineDocumentResponseList.length
                                                    ? contractDetails?.data?.info?.shippingLineDocumentResponseList
                                                          .length
                                                    : perPageCount}
                                            </span>
                                            <span className="dropdown-arrow">
                                                <svg className="svg-icon arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                        {PageCount && (
                                            <div className="dropdown-menu">
                                                {paginationLimit.map((res, index: number) => (
                                                    <a
                                                        key={index}
                                                        onClick={(e) => {
                                                            setPageCountDisplay(false);
                                                            e.stopPropagation();
                                                            setPerPageCount(res);
                                                            setCurrentPageNumber(null);
                                                            if (searchTerm?.trim()?.length > 1) {
                                                                dispatch(
                                                                    searchContractDetailsData({
                                                                        ...payload,
                                                                        keyword: searchTerm,
                                                                        currentPage: 0,
                                                                        perPageCount: res,
                                                                        columnStatus: selectedHeader,
                                                                        // statusFilter: selectedFilter,
                                                                        columnOrder: !currentOrder,
                                                                        statusFilterColumn: contractDetailColumnName,
                                                                        statusFilter: statusFilterSelected,
                                                                    })
                                                                );
                                                            } else {
                                                                dispatch(
                                                                    fetchContractDetailsList({
                                                                        ...payload,
                                                                        currentPage: 0,
                                                                        perPageCount: res,
                                                                        columnStatus: selectedHeader,
                                                                        // statusFilter: selectedFilter,
                                                                        columnOrder: !currentOrder,
                                                                        statusFilterColumn: contractDetailColumnName,
                                                                        statusFilter: statusFilterSelected,
                                                                    })
                                                                );
                                                            }
                                                        }}
                                                        className="menu-item app-btn"
                                                    >
                                                        {res}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <span>of</span>
                                    <span className="grid-total-row-count">{totalNoOfItems ? totalNoOfItems : 0}</span>
                                    <span>{totalNoOfItems > 1 ? 'entries' : 'entry'}</span>
                                </div>
                            </div>
                            <div className="grid-footer-right">
                                <div className="grid-pagination">
                                    <Pagination
                                        currentPage={currentPage + 1}
                                        totalCount={totalNoOfItems}
                                        pageSize={perPageCount}
                                        onPageChange={(page: number) => setCurrentPageNumber(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                        to={`/profile/shippingline/${id}/basic_details`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    {activeContracts && (
                        <ShowForPermissions permission="edit" type="shipping">
                            <button
                                className="app-btn app-btn-primary footer-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    contractDetails?.data?.info?.shippingLineDocumentResponseList?.length
                                        ? handleActivateColoader()
                                        : setSubmitModalVisibility(true);
                                    // handleActivateColoader();
                                }}
                                // disabled={
                                //     contractDetails?.data?.info?.shippingLineDocumentResponseList?.length ? false : true
                                // }
                            >
                                <span className="button-text footer-button-text">Submit and Activate</span>
                            </button>
                        </ShowForPermissions>
                    )}
                    {!activeContracts && (
                        <Link
                            className="app-btn app-btn-primary footer-btn"
                            title="Back to profile"
                            to={`/shippingline`}
                        >
                            <span className="button-text footer-button-text">Back to profile</span>
                        </Link>
                    )}
                    {/* <Link
                        className="app-btn app-btn-primary footer-btn"
                        title="Next"
                        to={`/profile/shippingline/${id}/contract_details`}
                    >
                        <span className="button-text footer-button-text">Next</span>
                    </Link> */}
                </div>
            </div>
            <ContractModal
                display={modalView}
                displayFunction={setModalView}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                data={contractData}
                setData={setContractData}
                isFromView={isFromView}
                setIsFromView={setIsFromView}
                mode={mode}
                setMode={setMode}
                selectedHeader={selectedHeader}
                selectedFilter={selectedFilter}
                perPageCount={perPageCount}
                setDisplayCancel={setDisplayCancel}
                setDeletedClient={setDeletedClient}
                setSelectedClient={setSelectedClient}
                setAddModalVisibility={setAddModalVisibility}
                setRemoveModalVisibility={setRemoveModalVisibility}
                allClientsData={allClientsData}
                setAllClientsData={setAllClientsData}
                primaryClientsData={primaryClientsData}
                setPrimaryClientsData={setPrimaryClientsData}
                secondaryClientsData={secondaryClientsData}
                setSecondaryClientsData={setSecondaryClientsData}
                setFilterUsed={setFilterUsed}
                filterUsed={filterUsed}
                clientData={selectedClient}
                statusFilterColumn={contractDetailColumnName}
                statusFilter={statusFilterSelected}
                columnOrder={currentOrder}
            />
            <ProfileaddModal
                addModalVisibility={addModalVisibility}
                setAddModalVisibility={setAddModalVisibility}
                originalData={allClient?.data?.info}
                clientData={selectedClient}
                allClientsData={allClientsData}
                setAllClientsData={setAllClientsData}
                primaryClientsData={primaryClientsData}
                setPrimaryClientsData={setPrimaryClientsData}
                secondaryClientsData={secondaryClientsData}
                setSecondaryClientsData={setSecondaryClientsData}
                filterUsed={filterUsed}
            />
            <RemoveModal
                removeModalVisibility={removeModalVisibility}
                setRemoveModalVisibility={setRemoveModalVisibility}
                clientData={deletedClient}
                setAllClientsData={setAllClientsData}
                primaryClientsData={primaryClientsData}
                setPrimaryClientsData={setPrimaryClientsData}
                secondaryClientsData={secondaryClientsData}
                setSecondaryClientsData={setSecondaryClientsData}
                filterUsed={filterUsed}
            />
            {submitModalVisibility ? (
                <SubmitModal
                    setSubmitModalVisibility={setSubmitModalVisibility}
                    handleActivateColoader={handleActivateColoader}
                />
            ) : null}
            <CancelModal
                displayFunction={setModalView}
                mode={mode}
                header="Contract"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
        </div>
    );
};
export default Index;
