import 'src/assets/scss/components/_layout.scss';
import 'src/assets/scss/components/_modal.scss';
interface ModalListProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    handleConfirm: () => void;
}
const Index = ({ display, displayFunction, handleConfirm }: ModalListProps) => {
    return (
        <>
            {display ? (
                <div className="app-modal purchase-detail-modal">
                    <div className="modal-content-holder ">
                        <div className="modal-header">
                            <div className="title">Confirm </div>
                            <button onClick={() => displayFunction(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content">
                                Confirmed products will be available for Booking Proposal. Are you sure to continue?
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => displayFunction(false)}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                            >
                                <span className="button-text footer-button-text">No</span>
                            </button>
                            <button className="app-btn app-btn-primary modal-btn footer-btn " onClick={handleConfirm}>
                                <span className="button-text footer-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
