import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import './stock.scss';
import QuantityAdjustmentsModal from './QuantityAdjustmentsModal';
import InventoryStockSidebar from '../InventoryStockSidebar';
import '../../../../src/assets/scss/components/_tooltip.scss';
// import quantityAdjst from './quantityAdjst.json';
import ProfileListing from '../../../components/common/ProfileListing';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { inventoryHeaders } from '../../../utils/constants';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { getOrganisationData } from '../../../utils';
import {
    fetchClientAndWarehouseData,
    fetchInventoryData,
    fetchInventoryDataDiscrepancy,
    fetchInventoryLogs,
    fetchWareHouseData,
    setPageCount,
    // viewedDiscrepancy,
} from '../../../store/reducers/inventory/inventoryListReducer';
import TableFooter from '../../../components/common/Table/tableFooter';
import ExportButton from '../../../components/common/ExportButton';
import { exportInventoryList } from '../../../services/apis/inventory/inventoryListApi';
import { fetchAdjstMent, successMessage } from '../../../store/reducers/inventory/inventoryAdjstmntReducer';
import { Position, success } from '../../../utils/toast';
import moment from 'moment';
import ProductDetailModal from '../../BookingProposal/BookingAddedGrid/ProductDetailsModal';
import { useLocation } from 'react-router-dom';
// import { useOutsideAlerter } from '../../../hooks/useOutsideAlerter';
// import { useParams } from 'react-router';

interface pageProp {
    setcurrentPage: any;
    setlogAvailable: any;
    logAvailable: boolean;
    setisWarehouseIntegrated: any;
    clientDropDownOpen: boolean;
    warehouseDropDownOpen: boolean;
    setClientDropDownOpen: any;
    setWarehouseDropDownOpen: any;
}

const Index: React.FC<pageProp> = ({
    setcurrentPage,
    setlogAvailable,
    // logAvailable,
    setisWarehouseIntegrated,
    clientDropDownOpen,
    warehouseDropDownOpen,
    setClientDropDownOpen,
    setWarehouseDropDownOpen,
}) => {
    const [adjustmentModal, setadjustmentModal] = useState({ stat: false, id: '', name: '' });
    const dispatch = useDispatch();
    const location = useLocation();

    const {
        inventories,
        clients,
        warehouses,
        currentPage,
        perPageCount,
        columnStatus,
        isLoading,
        discrepancies,
        totalInventoryPages,
        totalInventoryItems,
        newNotification,
        newDsPresent,
        filterValues,
    } = useSelector((state: RootState) => state.inventoryList);
    const [selectedHeader, setSelectedHeader] = useState(inventoryHeaders.modifiedOn);
    const { adjstData, dataLoad, adjstSuccess } = useSelector((state: RootState) => state.inventoryAdjust);
    const [selectedFilter, setselectedFilter] = useState<any | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [expandId, setexpandId] = useState<any | null>(null);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [_pageNumbers, setPagenumbers] = useState<any | null>(null);
    const [PageCount, setPageCountDisplay] = useState(false);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [showExportDropDown, setShowExportDropDown] = useState(false);
    const [filter, setFilter] = useState<any>({ clientFilter: 'All' });
    // const [newFilterValue, setNewFilterValue] = useState<any>({ clientFilter: 'All' });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    const [selectedWareHouseIntegrated, setSelectedWareHouseIntegrated] = useState(false);
    const [showSideBar, setShowSideBar] = useState(false);
    const [date, setDate] = useState(new Date());
    const { profileType } = useUserProfile();
    const firstUpdate = useRef(true);
    const firstClientUpdate = useRef(true);
    const organisationData = getOrganisationData();

    const urlParams = new URLSearchParams(window.location.search);
    const sku = urlParams.get('sku');

    useEffect(() => {
        if (sku) {
            setSearchTerm(sku);
        }
    }, [sku]);

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        columnOrder: currentOrder,
        data: { ...organisationData, ...filter },
        profileType,
    };
    const sortPayload = {
        columnOrder: currentOrder ? 'DESC' : 'ASC',
        columnStatus: selectedHeader,
    };
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (searchTerm?.trim()?.length > 2) {
            setFilter({ ...filter, skuSearch: searchTerm });
        } else if (searchTerm?.trim()?.length === 0) {
            if (filter?.skuSearch) {
                const filterObj = JSON.parse(JSON.stringify(filter));
                delete filterObj.skuSearch;
                setFilter(filterObj);
            }
        }
    }, [searchTerm]);

    useEffect(() => {
        if (adjustmentModal.id) {
            dispatch(fetchAdjstMent(adjustmentModal.id));
        }
    }, [adjustmentModal.id]);

    useEffect(() => {
        if (adjstSuccess) {
            success(`Quantity adjusted for ${adjustmentModal?.name}`, Position.TOP_RIGHT);
            dispatch(fetchInventoryData(payload));
            dispatch(successMessage(false));
        }
    }, [adjstSuccess]);

    useEffect(() => {
        if (dataLoad) {
            setadjustmentModal({ ...adjustmentModal, stat: true });
        }
    }, [dataLoad]);

    useEffect(() => {
        if (firstClientUpdate.current) {
            firstClientUpdate.current = false;
            return;
        }
        if (filter?.clientFilter !== 'All') {
            const clientCode = (clients?.find((client: any) => client.name === filter.clientFilter) as any)?.code;
            dispatch(fetchWareHouseData({ clientCode: clientCode, data: organisationData, profileType }));
        }
        if (filter?.clientFilter === 'All') {
            dispatch(fetchClientAndWarehouseData(payload));
        }
    }, [filter?.clientFilter]);

    useEffect(() => {
        if (filter.blpName && filter.clientFilter) {
            dispatch(fetchInventoryData(payload));
        }
        dispatch(fetchInventoryDataDiscrepancy(payload));
        setDate(new Date());
    }, [dispatch, currentPage, setSelectedHeader, perPageCount, currentOrder, filter]);

    useEffect(() => {
        dispatch(fetchClientAndWarehouseData(payload));
    }, []);

    useEffect(() => {
        if (warehouses?.length) {
            const filterValues = JSON.parse(JSON.stringify(filter));
            const clientFilter = clients?.length === 1 ? (clients as any[])[0]?.name : 'All';
            setFilter({
                ...filterValues,
                clientFilter,
                blpName:
                    (location as any)?.state !== null
                        ? (location as any)?.state?.blpname
                        : localStorage.getItem('currentWarehouse') //selecting warehouse data from lifecycle search inventory
                        ? localStorage.getItem('currentWarehouse')
                        : (warehouses as any[])[0]?.blpName,
            });
            setSelectedWareHouseIntegrated((warehouses as any[])[0]?.isIntegrated);
            setisWarehouseIntegrated((warehouses as any[])[0]?.isIntegrated);
        }
    }, [warehouses, location]);

    useEffect(() => {
        const pageNumbers = [];
        for (let i = 1; i <= totalInventoryPages; i++) {
            pageNumbers.push(i);
        }
        setPagenumbers(pageNumbers);
    }, [totalInventoryPages]);

    useEffect(() => {
        let flag = false;
        discrepancies?.forEach((element: any) => {
            if (element?.logAdded) {
                flag = true;
            }
        });
        if (flag) {
            setlogAvailable(true);
        }
    }, [discrepancies]);

    const closeToggle = (data: string) => {
        setToggleDropdown(false);
        setselectedFilter(data);
    };

    const setExpandIdOnClick = (id: string) => {
        setexpandId(id);
    };

    const filterToggle = () => {
        setToggleDropdown(!toggleDropdown);
    };

    const onSort = (sortKey: string) => {
        setSelectedHeader(sortKey);
        setcurrentOrder(!currentOrder);
    };

    const onSearchClose = () => {
        setSearchTerm('');
        if (filter?.skuSearch) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            delete filterObj.skuSearch;
            setFilter(filterObj);
        }
    };

    const resetAndFetchData = () => {
        setFilter({ clientFilter: 'All' });
        dispatch(fetchClientAndWarehouseData(payload));
        setSearchTerm('');
        setSelectedHeader(inventoryHeaders.modifiedOn);
    };

    return (
        <>
            <div className="search-dropdown-btn-fields-wrapper">
                <div className="search-field-purchase-grid">
                    <div className="search-entry-holder">
                        <input
                            placeholder="Search by SKU"
                            type="text"
                            className="search-input input-text"
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                            }}
                        ></input>
                        <div
                            className={`search-toolip ${
                                searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                            }`}
                        >
                            Enter atleast 3 characters to search
                        </div>
                        <svg className="svg-icon search-icon">
                            <use href="#searchIcon"></use>
                        </svg>
                        <button
                            className="app-btn text-close-btn"
                            onClick={(e) => {
                                e.preventDefault();
                                onSearchClose();
                            }}
                        >
                            <svg className="svg-icon text-close-icon">
                                <use href="#closeIcon"></use>
                            </svg>
                        </button>
                    </div>
                    <div
                        className={`dropdown-wrap ${
                            clientDropDownOpen ? 'dropdown-open' : ''
                        } download-dropdown search-toast-content-holder`}
                    >
                        <button
                            className="app-btn app-btn-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setClientDropDownOpen(!clientDropDownOpen);
                            }}
                        >
                            <div className="purchase-dropdown-content">
                                <span className="button-text">Client :</span>
                                <div className="content-heading-holder">
                                    <span className="content-heading">{filter?.clientFilter}</span>
                                </div>
                            </div>
                            <svg className="svg-icon arrow-icon">
                                <use href="#downArrow">
                                    <title>Expand row</title>
                                </use>
                            </svg>
                        </button>
                        <div className="dropdown-menu">
                            <>
                                {clients?.length === 1 ? null : (
                                    <a
                                        className={`menu-item app-btn ${
                                            filter?.clientFilter === 'All' ? 'active' : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setClientDropDownOpen(false);
                                            setFilter({ ...filter, clientFilter: 'All' });
                                        }}
                                    >
                                        <div className="content-heading-holder">
                                            <span className="content-heading">All</span>
                                        </div>
                                    </a>
                                )}
                                {clients?.map((item: any, ind: number) => (
                                    <a
                                        key={ind}
                                        className={`menu-item app-btn ${
                                            filter?.clientFilter === item.name ? 'active' : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setClientDropDownOpen(false);
                                            setFilter({ ...filter, clientFilter: item.name, clientName: null });
                                        }}
                                    >
                                        <div className="content-heading-holder">
                                            <span className="content-heading">{item.name}</span>
                                        </div>
                                    </a>
                                ))}
                            </>
                        </div>
                    </div>
                    <div
                        className={`dropdown-wrap warehouse-dropdown ${
                            warehouseDropDownOpen ? 'dropdown-open' : ''
                        } download-dropdown search-toast-content-holder`}
                    >
                        <button
                            className="app-btn app-btn-secondary"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                setWarehouseDropDownOpen(!warehouseDropDownOpen);
                            }}
                        >
                            <div className="purchase-dropdown-content">
                                <span className="button-text">Warehouse : </span>
                                <div className="content-heading-holder">
                                    <span className="content-heading">{filter?.blpName}</span>
                                </div>
                            </div>
                            <svg className="svg-icon arrow-icon">
                                <use href="#downArrow">
                                    <title>Expand row</title>
                                </use>
                            </svg>
                        </button>
                        <div className="dropdown-menu">
                            <>
                                {warehouses?.map((item: any, ind: number) => (
                                    <a
                                        key={ind}
                                        className={`menu-item app-btn ${
                                            filter?.blpName === item.blpName ? 'active' : ''
                                        }`}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setWarehouseDropDownOpen(false);
                                            setFilter({ ...filter, blpName: item.blpName });
                                            setSelectedWareHouseIntegrated(item.isIntegrated);
                                        }}
                                    >
                                        <div className="content-heading-holder">
                                            <span className="content-heading">{item.blpName}</span>
                                        </div>
                                    </a>
                                ))}
                            </>
                            {!warehouses?.length && !isLoading ? (
                                <div className="no-data-content">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No Warehouses Found</p>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="add-btn-purchase">
                    <button
                        className="action-btn app-btn app-btn-secondary reset-btn"
                        onClick={(e) => {
                            e.preventDefault();
                            resetAndFetchData();
                        }}
                        title="Refresh"
                    >
                        <svg className="svg-icon reset-icon">
                            <use xlinkHref="#resetIcon">
                                <title>Split</title>
                            </use>
                        </svg>
                    </button>
                    <ExportButton
                        organisationData={{ ...organisationData, ...filter, ...sortPayload }}
                        disabled={
                            (inventories && inventories?.length === 0 && selectedFilter === null) || !inventories
                                ? true
                                : false
                        }
                        showExportDropDown={showExportDropDown}
                        setShowExportDropDown={setShowExportDropDown}
                        exportListWithBody={exportInventoryList}
                    />
                </div>
            </div>
            <div className="tab-nav-grid-holder">
                <div className="tab-nav-holder">
                    <a href="#" className="tab-nav active">
                        Stock
                    </a>

                    {selectedWareHouseIntegrated ? (
                        <a
                            href="#"
                            className={`tab-nav ${newNotification || newDsPresent ? 'tab-notification' : ''}`}
                            onClick={(e) => {
                                e.preventDefault();
                                setcurrentPage('discrepencies');
                            }}
                        >
                            Discrepancies
                        </a>
                    ) : null}
                </div>
                <div className="date-time-field">
                    Last updated on <span className="date-field">{moment(date).format('DD-MM-YYYY')}</span>,
                    <span className="time-field">{moment(date).format('HH:mm')}</span>
                </div>
            </div>
            <div className="grid-section inventory-stock-grid grid-added-booking product-details-wrapper">
                {ModalViewproduct.status && (
                    <ProductDetailModal
                        display={ModalViewproduct}
                        displayFunction={setModalViewproduct}
                        //id={VendorCode}
                        //data={data}
                    />
                )}
                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
                <div className="grid-holder">
                    <div className="grid-header"></div>
                    <ProfileListing
                        filter={filter}
                        setFilter={setFilter}
                        tabName={'inventories'}
                        data={inventories}
                        columnStatus={columnStatus}
                        currentOrder={currentOrder}
                        toggleDropdown={toggleDropdown}
                        selectedFilter={selectedFilter}
                        closeToggle={closeToggle}
                        expandId={expandId}
                        setExpandIdOnClick={setExpandIdOnClick}
                        filterToggle={filterToggle}
                        onSort={onSort}
                        setadjustmentModal={setadjustmentModal}
                        adjustmentModal={adjustmentModal}
                        fetchFunction={fetchInventoryLogs}
                        setShowSideBar={setShowSideBar}
                        setModalViewproduct={setModalViewproduct}
                        filterDataLists={filterValues ? filterValues : {}}
                    />
                    <TableFooter
                        fetchData={fetchInventoryData}
                        currentPage={currentPage}
                        stat={payload}
                        displayCountfn={setPageCountDisplay}
                        pageCountDisplay={PageCount}
                        perPageCount={perPageCount}
                        setPageCount={setPageCount}
                        totalItems={totalInventoryItems}
                        items={inventories}
                        itemsInList={null}
                        totalPages={totalInventoryPages - 1}
                    />
                    <InventoryStockSidebar
                        setShowSideBar={setShowSideBar}
                        showSideBar={showSideBar}
                    ></InventoryStockSidebar>
                </div>
            </div>
            {adjustmentModal.stat && (
                <QuantityAdjustmentsModal
                    data={adjstData}
                    setadjustmentModal={setadjustmentModal}
                    adjustmentModal={adjustmentModal}
                ></QuantityAdjustmentsModal>
            )}
        </>
    );
};
export default Index;
