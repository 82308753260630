import axios from 'axios';
import { get, put } from '../../../HttpMethods';

export const getShippingLineBasicDetailsData = async (payload: any) => {
    try {
        const data = await get(`api/mgt/shippinglines/basicdetails?shippingLineId=${payload.id}`, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getForwarderRankingData = async (_payload: any) => {
    try {
        const data = await get('api/mgt/agents/forwarderranks', null);
        return { data: data.info, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateForwarderRankData = async (payload: any) => {
    try {
        const data = await put(
            `/api/mgt/shippinglines/forwarderranking?shippingLineId=${payload.shippingLineId}&rankId=${payload.rankId}`,
            null
        );
        return { data: data.info, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const carrierHeaderDataApi = async (payload: any) => {
    try {
        const data = await get(`/api/mgt/shippinglines/header?carrierId=${payload}`, null);
        return data;
    } catch (er) {
        return er;
    }
};
