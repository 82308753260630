import { useState, useEffect, useRef } from 'react';

export default function useOutsideClickHandler(initialIsVisible: boolean) {
    const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);
    const ref: any = useRef(null);
    const ref2: any = useRef(null);

    const handleClickOutside = (event: any) => {
        if (
            ref.current &&
            !ref.current.contains(event.target) &&
            ref2.current &&
            !ref2.current.contains(event.target)
        ) {
            setIsComponentVisible(false);
        } else if (
            ref2.current &&
            ref2.current.contains(event.target) &&
            ref.current &&
            ref.current.contains(event.target)
        ) {
            setIsComponentVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);

    return { ref, ref2, isComponentVisible, setIsComponentVisible };
}
