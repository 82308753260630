import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'addedPosAir',
    initialState: {
        isLoading: false,
        addedPoList: [],
        prouctsList: [],
        success: false,
        error: false,
        removeMessageval: {},
        gateInDate: false,
    },
    reducers: {
        fetchAddedPoDataAir: (state, _action) => {
            state.isLoading = true;
        },
        saveAddedPoDataAir: (state, action) => {
            state.addedPoList = action.payload?.response
                ? action.payload?.response?.bookingProposalPoList
                : action.payload?.bookingProposalPoList;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        removePoAir: (_state, _action) => {
            // console.log(action);
        },
        searchData: (_state, _action) => {
            // console.log(action);
        },
        getSingleData: (_state, _action) => {
            // console.log('first');
        },
        saveSingleData: (state, action) => {
            state.prouctsList = action.payload;
        },
        removeError: (state, action) => {
            state.removeMessageval = action.payload.message;
            state.error = true;
        },
        removeSuccess: (state, _action) => {
            state.success = true;
        },
        resetErrorFlagAir: (state, _action) => {
            state.error = false;
            state.removeMessageval = {};
        },
        resetSuccessFlagAir: (state, _action) => {
            state.success = false;
        },
    },
});

export const {
    fetchAddedPoDataAir,
    isLoadingFn,
    saveAddedPoDataAir,
    removePoAir,
    searchData,
    getSingleData,
    saveSingleData,
    removeSuccess,
    removeError,
    resetErrorFlagAir,
    resetSuccessFlagAir,
} = dataSlice.actions;
export default dataSlice.reducer;
