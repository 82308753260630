import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchBpHeaderData,
    fetchBcnParentBpList,
    updateBcnParentBpSuccess,
    updateBcnParentBpFailure,
    updateBcnTeuSuccess,
    updateBcnTeuFailure,
    resetBcnBackSuccess,
    resetBcnBackFailure,
    agentIntegration,
    cancelBp,
    isLoadingFn,
    fetchLinkedBpHeader,
    cancelBooking,
    saveRejectionReason,
    isLoadingHeaderFn,
    fetchLinkedBpData,
} from '../../reducers/bookingProposal/bookingProposalHeaderReducer';
import {
    getBpHeaderData,
    getAgentBcnParentBpData,
    getAgentIntegrationData,
    cancelBookingProposal,
    rejectionReasonsAPI,
    changeBcnParentBpData,
    changeBcnTeuData,
    updateResetBcnBackData,
    getLinkedBookingsApi,
} from '../../../services/apis/bookingProposal/bookingProposalHeaderApi';

function* getBpHeader({ payload }: { payload: any; type: any }) {
    try {
        //yield put({ type: isLoadingHeaderFn, payload: true });
        //yield put({ type: isLoadingFn, payload: true });

        const data: AxiosResponse<any> = yield call(getBpHeaderData, payload);
        payload['data'] = data;
        yield put({ type: fetchBpHeaderData, payload: payload });
    } catch (err) {
        console.log('Error', err);
    } finally {
        yield put({ type: isLoadingHeaderFn, payload: false });
    }
}

//getLinkedBookingsApi
function* getLinkedBookings({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any> = yield call(getLinkedBookingsApi, payload);
        yield put({ type: fetchLinkedBpData, payload: data });
    } catch (err) {
        console.log('Error', err);
    } finally {
        yield put({ type: isLoadingHeaderFn, payload: false });
    }
}

function* getAgentBcnParentBp({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any> = yield call(getAgentBcnParentBpData, payload);
        payload['data'] = data;
        yield put({ type: fetchBcnParentBpList, payload: payload });
    } catch (err) {
        console.log('Error', err);
    }
}
function* changeBcnParentBp({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any> = yield call(changeBcnParentBpData, payload);
        yield put({ type: updateBcnParentBpSuccess, payload: data });
    } catch (err: any) {
        yield put({ type: updateBcnParentBpFailure, payload: err });
        console.log('Error', err);
    }
}
function* changeBcnTeu({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any> = yield call(changeBcnTeuData, payload);
        yield put({ type: updateBcnTeuSuccess, payload: data });
    } catch (err: any) {
        yield put({ type: updateBcnTeuFailure, payload: err });
        console.log('Error', err);
    }
}
function* updateResetBcnBack({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any> = yield call(updateResetBcnBackData, payload);
        yield put({ type: resetBcnBackSuccess, payload: data });
    } catch (err: any) {
        yield put({ type: resetBcnBackFailure, payload: err });
        console.log('Error', err);
    }
}
function* getAgentIntegration({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<any> = yield call(getAgentIntegrationData, payload);
        payload['data'] = data;
        yield put({ type: agentIntegration, payload: payload });
    } catch (err) {
        console.log('Error', err);
    }
}

function* bookingProposalCancellation({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const data: AxiosResponse<any> = yield call(cancelBookingProposal, payload);
        payload['res'] = data;
        yield put({ type: cancelBp, payload: payload });
    } catch (error: any) {
        payload['success'] = false;
        payload['error'] = error?.data?.data || 'Something went wrong';
        yield put({ type: cancelBp, payload });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getLinkedBpHeader({ payload }: { payload: any; type: any }) {
    try {
        const linkedBpDetails: AxiosResponse<any> = yield call(getBpHeaderData, payload);
        payload['linkedBpDetails'] = linkedBpDetails;
        yield put({ type: fetchLinkedBpHeader, payload: payload });
    } catch (err) {
        console.log('Error', err);
    }
}

function* getRejectionReasons({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse<any> = yield call(rejectionReasonsAPI, payload);
        yield put({ type: saveRejectionReason, payload: response });
    } catch (err) {
        console.log('Error', err);
    }
}

function* bookingCancellation({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const data: AxiosResponse<any> = yield call(cancelBookingProposal, payload);
        payload['res'] = data;
        yield put({ type: cancelBooking, payload: payload });
    } catch (error: any) {
        payload['success'] = false;
        payload['error'] = error?.data?.data || 'Something went wrong';
        yield put({ type: cancelBooking, payload });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* bpHeaderSaga() {
    yield takeEvery('bpHeaderData/fetchBpHeaderData', getBpHeader);
    yield takeEvery('bpHeaderData/fetchBcnParentBpList', getAgentBcnParentBp);
    yield takeEvery('bpHeaderData/updateBcnParentBp', changeBcnParentBp);
    yield takeEvery('bpHeaderData/updateBcnTeu', changeBcnTeu);
    yield takeEvery('bpHeaderData/resetBcnBack', updateResetBcnBack);
    yield takeEvery('bpHeaderData/agentIntegration', getAgentIntegration);
    yield takeEvery('bpHeaderData/cancelBp', bookingProposalCancellation);
    yield takeEvery('bpHeaderData/fetchLinkedBpHeader', getLinkedBpHeader);
    yield takeEvery('bpHeaderData/cancelBooking', bookingCancellation);
    yield takeEvery('bpHeaderData/fetchRejectionReason', getRejectionReasons);
    yield takeEvery('bpHeaderData/fetchLinkedBpData', getLinkedBookings);
}

export default bpHeaderSaga;
