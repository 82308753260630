import ShowForPermissions from '../../../ShowForPermissions';

interface headerItemProps {
    setmodalView: any;
    totalActive: any;
    search: any;
    setSearch: any;
    hideSearch?: boolean;
}

const tableHeader: React.FC<headerItemProps> = ({ setmodalView, totalActive, search, setSearch, hideSearch }) => {
    return (
        <div className="grid-header grid-mr-20">
            {!hideSearch && (
                <div className="search-entry-holder">
                    <input
                        placeholder="Search Vendor Details"
                        type="text"
                        className="search-input input-text"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                    ></input>
                    <div className={`search-toolip ${search.length > 0 && search.length < 3 ? 'tooltip-show' : ''}`}>
                        Enter atleast 3 characters to search
                    </div>
                    <svg className="svg-icon search-icon">
                        <use xlinkHref="#searchIcon"></use>
                    </svg>
                    <button
                        className="app-btn text-close-btn"
                        onClick={() => {
                            if (search?.trim()?.length) {
                                setSearch('');
                            }
                        }}
                    >
                        <svg className="svg-icon text-close-icon">
                            <use xlinkHref="#closeIcon"></use>
                        </svg>
                    </button>
                </div>
            )}
            <div className="item-count-status-holder">
                {/* <div className="grid-item-count-status status-ui success-status">
                    <span className="count">{totalActive ? totalActive : '0'}</span>
                    <span className="status-text">
                        {totalActive && totalActive <= 1 ? 'Vendor ' : 'Vendors '}linked
                    </span>
                </div> */}
            </div>
            <ShowForPermissions permission="edit" type="client">
                <button
                    className="app-btn app-btn-secondary icon-button grid-top-btn"
                    title="Link Vendor"
                    onClick={() => setmodalView(true)}
                >
                    <svg className="svg-icon link-vendor-btn-icon">
                        <use xlinkHref="#linkIcon"></use>
                    </svg>
                    <span className="button-text vendor-button-text">Link Vendors</span>
                </button>
            </ShowForPermissions>
        </div>
    );
};

export default tableHeader;
