import React, { useState, useEffect } from 'react';
import '../../BookingProposal/BookingProposalHeader/booking-proposal-header.scss';
import '../../BookingProposal/POSelection.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { useUserProfile } from '../../../hooks/useUserProfile';
import Select, { components } from 'react-select';
import RejectedBookingProposalModal from '../BookingAdditionalInfo/RejectedBookingProposalModal';
import RejectedBpForwarderModal from '../BookingAdditionalInfo/RejectedBpForwarderModal';
import ReapprovalBpClientModal from '../BookingAdditionalInfo/ReapprovalBpClientModal';
import RejectBookingProposalModal from '../BookingAdditionalInfo/RejectBookingProposalModal';
import {
    clearFailure,
    selectAdditionalDetailsSuccess,
    updateViewStatus,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import {
    fetchBpHeaderData,
    fetchLinkedBpHeader,
    agentIntegration,
    isLoadingHeaderFn,
} from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import CancellationDetailModal from '../../PO/BasicDetail/CancellationDetailModal';
import { Position, success } from '../../../utils/toast';
import { useAppSelector } from '../../../hooks';
import LinkedBookingModal from '../BookingAddedGrid/LinkedBookingModal';
import FeedbackModal from 'src/components/common/FeedbackModal';
import ClickAwayListener from 'react-click-away-listener';
// import { selectAssignedPo } from 'src/store/reducers/bookingProposal/containerAllocationReducer';
import RejectReasonHistory from 'src/pages/LCLBookingProposal/BookingAdditionalInfo/RejectReasonHistory';
import { updateBookingHeader } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { searchUnlocoList } from 'src/store/reducers/createVendorReducer';
import { reactSelectStyle } from 'src/utils/constants';
import ChangePortWarningModal from '../../BookingProposal/BookingProposalHeader/ChangePortWarningModal';

interface HeaderProps {
    id?: string;
    title?: string;
    desc?: string;
    backButtonLink?: string;
    setShowBookingSideBar?: any;
    infoValues?: any;
    extraData?: any;
    setSelected?: any;
    isAdded?: any;
}

const Index: React.FC<HeaderProps> = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const {
        clientName,
        // vendorName,
        originPort,
        destinationPort,
        blpName,
        totalCBM,
        totalKgs,
        totalTEU,
        bpStatus,
        isViewed,
        reason,
        reSubmit,
        reSubmitPopupClient,
        reSubmitPopupForwarder,
        showRejectModalAgent,
        showRejectModalForwarder,
        bpNumber,
        cancelDate,
        cancelReason,
        cancelledUser,
        linkedBp,
        linkedBpDetails,
        gateInDateExpired,
        agentCode,
        // goodsReadyDate,
        isAutoApprovalFailed,
        autoApprovalFailureReason,
        poNumbers,
    } = useSelector((state: RootState) => state.bpHeaderData);
    const dispatch = useDispatch();
    // const assignedPo = useAppSelector(selectAssignedPo);
    const { profileType, userName } = useUserProfile();
    const [reasonModalView, setReasonModalView] = useState(false);
    const [changePortModalView, setChangePortModalView] = useState(false);
    const [rejectionReasonModalView, setrejectionRasonModalView] = useState(false);
    const [assignAgentModalView, setAssignAgentModalView] = useState(false);
    const [reapproveBpModalView, setReapproveBpModalView] = useState(false);
    const [rejectedBpForwarderModalView, setRejectedBpForwarderModalView] = useState(false);
    const [reasonRejection, setReasonRejection] = useState('');
    const [oneTimeModal, setOneTimeModal] = useState(false);
    const [showReasonModal, setShowReasonModal] = useState(false);
    const additionalDetailsSuccess = useAppSelector(selectAdditionalDetailsSuccess);
    const [showLinkedModal, setShowLinkedModal] = useState(false);
    const [showAutoDeniedReasonModal, setShowAutoDeniedReasonModal] = useState<null | boolean>(false);
    const [showPurchaseOrderTooltip, setShowPurchaseOrderTooltip] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const { unLocoList, isLoading } = useSelector((state: RootState) => state.vendorDetails);
    const [ports, setPorts] = useState<any>([]);
    const [newOriginPortCode, setNewOriginPortCode] = useState<any>({});
    const [newDestinationPortCode, setNewDestinationPortCode] = useState<any>({});
    const [unlocoLoading, setunlocoLoading] = useState(false);
    const [currentSelectedDropdown, setCurrentSelectedDropdown] = useState('');
    const [touched, settouched] = useState(false);

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    portCountry: option.portCountry,
                };
            });
            setPorts(portList);
        }
    }, [unLocoList]);

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            dispatch(searchUnlocoList({ searchTerm, service: 'ord' }));
        }
        setPorts([]);
    }, [searchTerm]);

    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };

    /**
     * if user is agent, user can change port code
     * otherwise port code will be static display
     */
    const showPortDropdown = (type: string) => {
        if (
            type === 'origin' &&
            (profileType === 'agent' || profileType === 'forwarder') &&
            bpStatus === 'Draft_Booking_Proposal'
        ) {
            return (
                <Select
                    options={ports}
                    // isClearable
                    placeholder={'Enter Origin to select'}
                    onChange={(option: any) => {
                        if (option?.label && option?.label.indexOf('(') > -1) {
                            setNewOriginPortCode({ value: option?.value, label: option?.label });
                            setChangePortModalView(true);
                            // updatePortCode(option?.label, 'originPort').then(() => {
                            //     window.location.reload();
                            // });
                        }
                    }}
                    onInputChange={(text: string) => {
                        setCurrentSelectedDropdown('originPort');
                        setSearchTerm(text);
                        settouched(true);
                    }}
                    isLoading={unlocoLoading && currentSelectedDropdown === 'originPort'}
                    noOptionsMessage={({ inputValue }) =>
                        inputValue?.length < 3 ? 'Enter at least 3 characters to search' : 'No results found'
                    }
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                    id={'originPort'}
                    name={'originPort'}
                    value={newOriginPortCode.value ? newOriginPortCode : { value: originPort, label: originPort }}
                    // value={portCode ? portCode : { value: originPort, label: originPort }}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        ClearIndicator,
                    }}
                    styles={reactSelectStyle}
                />
            );
        } else if (
            type === 'destination' &&
            (profileType === 'agent' || profileType === 'forwarder') &&
            bpStatus === 'Draft_Booking_Proposal'
        ) {
            return (
                <Select
                    options={ports}
                    // isClearable
                    placeholder={'Enter Destination to select'}
                    onChange={(option: any) => {
                        if (option?.label && option?.label.indexOf('(') > -1) {
                            setNewDestinationPortCode({ value: option?.value, label: option?.label });
                            setChangePortModalView(true);
                            // updatePortCode(option?.label, 'destinationPort').then(() => {
                            //     window.location.reload();
                            // });
                        }
                    }}
                    onInputChange={(text: string) => {
                        setCurrentSelectedDropdown('destinationPort');
                        setSearchTerm(text);
                        settouched(true);
                    }}
                    isLoading={unlocoLoading && currentSelectedDropdown === 'destinationPort'}
                    noOptionsMessage={({ inputValue }) =>
                        inputValue?.length < 3 ? 'Enter at least 3 characters to search' : 'No results found'
                    }
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                    id={'destinationPort'}
                    name={'destinationPort'}
                    value={
                        newDestinationPortCode.value
                            ? newDestinationPortCode
                            : { value: destinationPort, label: destinationPort }
                    }
                    // value={portCode ? portCode : { value: originPort, label: originPort }}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        ClearIndicator,
                    }}
                    styles={reactSelectStyle}
                />
            );
        } else if (type === 'origin') {
            return (
                <div className="details-box-content" title={originPort || '-'}>
                    {originPort || '-'}
                </div>
            );
        } else if (type === 'destination') {
            return (
                <div className="details-box-content" title={destinationPort || '-'}>
                    {destinationPort || '-'}
                </div>
            );
        }
    };

    const updatePortCode = async (port: string, portType: string) => {
        const keyValuePairs: { [key: string]: string } = {};

        if (portType === 'originPort') {
            keyValuePairs.originPort = port;
        } else {
            keyValuePairs.destinationPort = port;
        }

        const payload = {
            bpId: id || '',
            keyValuePairs,
        };

        await updateBookingHeader(payload);
    };

    useEffect(() => {
        if (!id) {
            dispatch(isLoadingHeaderFn(false));
        }
    }, [id]);

    useEffect(() => {
        if (id) {
            dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        }
    }, [id, reasonModalView]);

    const onClose = () => {
        setShowReasonModal(false);
    };

    useEffect(() => {
        if (linkedBp) {
            dispatch(fetchLinkedBpHeader({ bpId: linkedBp, profileType }));
        }
    }, [linkedBp]);

    useEffect(() => {
        if (
            (profileType === 'forwarder' && bpStatus === 'Awaiting_Forwarder_Approval') ||
            (profileType === 'client' && bpStatus === 'Awaiting_Client_Approval')
        ) {
            dispatch(updateViewStatus({ bpId: id, profileType }));
        }
        if (
            (reSubmitPopupClient === false &&
                profileType === 'client' &&
                reSubmit &&
                bpStatus === 'Awaiting_Client_Approval') ||
            (reSubmitPopupForwarder === false &&
                profileType === 'forwarder' &&
                reSubmit &&
                bpStatus === 'Awaiting_Forwarder_Approval')
        ) {
            setReapproveBpModalView(true);
        }
        if (showRejectModalAgent && profileType === 'agent') {
            setReasonModalView(true);
            setOneTimeModal(true);
        }
        if (showRejectModalForwarder && profileType === 'forwarder') {
            setRejectedBpForwarderModalView(true);
            setReasonRejection(reason ? reason : '');
        }
        if (agentCode) {
            dispatch(agentIntegration({ agentCode }));
        }
        document.title = bpNumber ? `BKG>${bpNumber}` : '';
    }, [bpStatus]);

    useEffect(() => {
        if (additionalDetailsSuccess?.data?.type === 'assignedToAgent') {
            success(
                `Booking Proposal ${
                    additionalDetailsSuccess?.data?.info?.bpNumber ? additionalDetailsSuccess?.data?.info?.bpNumber : ''
                } assigned to agent`,
                Position.TOP_RIGHT
            );
            dispatch(clearFailure([]));
            setTimeout(() => navigate('/booking_list'), 2000);
        }
    }, [additionalDetailsSuccess]);

    const allocationStatus = (status: string) => {
        switch (status) {
            case 'Pending':
                return <div className="allocated-status pending">Pending</div>;
            case 'In_Progress':
                return <div className="allocated-status in-progress">In-progress</div>;
            case 'Completed':
                return <div className="allocated-status completed">Completed</div>;
        }
    };
    return (
        <div className="booking-proposal-header-fields">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    {props?.extraData ? (
                        <>
                            {/*<Link to={`/Aobooking_list/${props?.extraData?.bpId}/container-allocation`}>
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </Link>*/}
                            <h2 className="page-heading">
                                {props?.extraData?.containerType ? (
                                    <>{props?.extraData?.containerType}</>
                                ) : (
                                    <>
                                        {props?.extraData?.description} ({props?.extraData?.code})
                                    </>
                                )}
                                <p className="container-number">
                                    Container Number : {props?.extraData?.containerNumber}
                                </p>
                            </h2>
                        </>
                    ) : localStorage.getItem('changeVesselao') === id ? (
                        <>
                            <a onClick={() => props.setSelected(true)}>
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </a>
                            <h2 className="page-heading">{props.id?.length ? props.id : 'New Booking Proposal'}</h2>
                        </>
                    ) : (
                        <>
                            {/* <Link to="/booking_list">
                                <button className="back-btn app-btn">
                                    <svg className="svg-icon back-icon">
                                        <use href="#previousArrowIcon">
                                            <title>Back</title>
                                        </use>
                                    </svg>
                                </button>
                            </Link> */}
                            <h2 className="page-heading">{props.id?.length ? props.id : 'New Booking Proposal'}</h2>
                        </>
                    )}
                    <div className="transport-icon-wrapper">
                        <div className="item-count-status-holder">
                            {props?.extraData?.containerType &&
                                (props?.extraData?.containerStatus === 'Pending' ||
                                    props?.extraData?.containerStatus === 'In_Progress' ||
                                    props?.extraData?.containerStatus === 'Completed') && (
                                    <div
                                        className={`grid-item-count-status status-ui ${
                                            props?.extraData?.containerStatus === 'Pending'
                                                ? 'warning-status'
                                                : props?.extraData?.containerStatus === 'Completed'
                                                ? 'success-status'
                                                : 'info-status'
                                        }`}
                                    >
                                        <span className="status-text">
                                            {allocationStatus(props?.extraData?.containerStatus)}
                                        </span>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="page-counter-wrapper">
                        {!props.id?.length ? (
                            <div className="page-heading__caption">
                                Select at least one AO line to start Booking Proposal Creation
                            </div>
                        ) : null}
                        {/* link-count-wrapper is used for linking the page available in added tab */}
                        {linkedBp ? (
                            <div
                                className="page-heading__caption link-count-wrapper"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    setShowLinkedModal(true);
                                }}
                            >
                                <button className="back-btn app-btn" title="Linked Booking">
                                    <svg className="svg-icon chain-link-icon">
                                        <use href="#chainLinkIcon">
                                            <title>Linked Booking</title>
                                        </use>
                                    </svg>
                                </button>
                                <span className="chain-link-count">1</span>
                            </div>
                        ) : null}
                    </div>
                </div>
                {!props?.extraData && (
                    <div className="header-container-view-eye-reason-wrapper">
                        {(() => {
                            if (
                                profileType === 'agent' &&
                                (bpStatus === 'Awaiting_Forwarder_Approval' || bpStatus === 'Awaiting_Client_Approval')
                            ) {
                                return (
                                    <div className="header-container-view">
                                        <span className="btn-pills container-pill waiting-pill">
                                            <span className="pill-text">
                                                {bpStatus === 'Awaiting_Forwarder_Approval'
                                                    ? 'Awaiting Forwarder Approval'
                                                    : 'Awaiting Client Approval'}
                                            </span>
                                        </span>
                                        <div className="eye-view-container">
                                            {isViewed ? (
                                                <button title="Viewed" className="app-btn eye-open-view-container">
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#eyeIcon"></use>
                                                    </svg>
                                                </button>
                                            ) : (
                                                <button title="Not Viewed" className="app-btn eye-close-view-container">
                                                    <svg className="svg-icon eye-strick-icon">
                                                        <use xlinkHref="#eyeStrickIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                        {reason && bpStatus !== 'Booked' && bpStatus !== 'Draft_Booking' && id && (
                                            <a
                                                className="add-content-link"
                                                onClick={() => setrejectionRasonModalView(true)}
                                            >
                                                Reject Reason History
                                            </a>
                                        )}
                                    </div>
                                );
                            } else if (profileType === 'forwarder' && bpStatus === 'Awaiting_Client_Approval') {
                                return (
                                    <div className="header-container-view">
                                        <span className="btn-pills container-pill waiting-pill">
                                            <span className="pill-text">Awaiting Client Approval</span>
                                        </span>
                                        <div className="eye-view-container">
                                            {isViewed ? (
                                                <button title="Viewed" className="app-btn eye-open-view-container">
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#eyeIcon"></use>
                                                    </svg>
                                                </button>
                                            ) : (
                                                <button title="Not Viewed" className="app-btn eye-close-view-container">
                                                    <svg className="svg-icon eye-strick-icon">
                                                        <use xlinkHref="#eyeStrickIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                        </div>
                                        {reason && bpStatus !== 'Booked' && bpStatus !== 'Draft_Booking' && id && (
                                            <a
                                                className="add-content-link"
                                                onClick={() => setrejectionRasonModalView(true)}
                                            >
                                                Reject Reason History
                                            </a>
                                        )}
                                    </div>
                                );
                            } else {
                                return (
                                    <div className="header-container-view-reason">
                                        <div className="total-container-count">
                                            {(() => {
                                                if (bpStatus === 'Rejected_by_Client_Forwarder_to_Action') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">
                                                                    Rejected by Client :{' '}
                                                                    <span>Forwarder to Action</span>
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Rejected_by_Client_Agent_to_Action') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">
                                                                    Rejected by Client : <span>Agent to Action</span>
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Cancelled') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">Cancelled</span>
                                                            </span>
                                                            <a
                                                                className="add-content-link"
                                                                href="javascript:void(0)"
                                                                onClick={() => setShowReasonModal(true)}
                                                            >
                                                                View Reason
                                                            </a>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Rejected_by_Forwarder') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill error-pill">
                                                                <span className="pill-text">Rejected by Forwarder</span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Awaiting_Forwarder_Approval') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Awaiting Forwarder Approval
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Awaiting_Client_Approval') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Awaiting Client Approval
                                                                </span>
                                                            </span>
                                                            {isAutoApprovalFailed && (
                                                                <a
                                                                    className="add-content-link"
                                                                    href="javascript:void(0)"
                                                                    onClick={() => setShowAutoDeniedReasonModal(true)}
                                                                >
                                                                    View Reason
                                                                </a>
                                                            )}
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Ready_for_Booking') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">Ready for Booking</span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Draft_Booking_Proposal') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">
                                                                    Draft Booking Proposal
                                                                </span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Draft_Booking') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill waiting-pill">
                                                                <span className="pill-text">Draft Booking</span>
                                                            </span>
                                                        </>
                                                    );
                                                } else if (bpStatus === 'Booked') {
                                                    return (
                                                        <>
                                                            <span className="btn-pills container-pill success-pill">
                                                                <span className="pill-text">Booked</span>
                                                            </span>
                                                        </>
                                                    );
                                                }
                                            })()}
                                        </div>
                                        {reason && bpStatus !== 'Booked' && bpStatus !== 'Draft_Booking' && id && (
                                            <a
                                                className="add-content-link"
                                                onClick={() => setrejectionRasonModalView(true)}
                                            >
                                                Reject Reason History
                                            </a>
                                        )}
                                    </div>
                                );
                            }
                        })()}
                    </div>
                )}
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Client</h6>
                        <div className="details-box-content" title={clientName || '-'}>
                            {clientName || '-'}
                        </div>
                    </div>
                    {/* <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Vendor</h6>
                        <div className="details-box-content" title={vendorName || '-'}>
                            {vendorName || '-'}
                        </div>
                    </div> */}
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">BLP</h6>
                        <div className="details-box-content" title={blpName || '-'}>
                            {blpName || '-'}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Origin</h6>
                        {showPortDropdown('origin')}
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Destination</h6>
                        {showPortDropdown('destination')}
                    </div>
                    {/* <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Goods Ready Dates</h6>
                        <ClickAwayListener onClickAway={() => setShowTooltip(false)}>
                            <div className="details-box-content">
                                {goodsReadyDate?.length ? moment(goodsReadyDate[0]).format('DD-MM-YYYY') : '-'}
                                {goodsReadyDate?.length > 1 ? (
                                    <span
                                        className={`dropdown-wrap menu-down ${
                                            showTooltip ? 'dropdown-open' : ''
                                        } info-dropdown modal-toast header-count header-dp`}
                                    >
                                        <div
                                            className="toast-message "
                                            onClick={() => {
                                                setShowTooltip(!showTooltip);
                                                if (showPurchaseOrderTooltip) {
                                                    setShowPurchaseOrderTooltip(false);
                                                }
                                            }}
                                        >
                                            +{goodsReadyDate?.length - 1}
                                        </div>
                                        <div className="dropdown-menu ">
                                            <a href="#" className="menu-item app-btn">
                                                {goodsReadyDate
                                                    ?.slice(1, goodsReadyDate?.length)
                                                    .map((date: any, index: number) => (
                                                        <a
                                                            href="#"
                                                            className="menu-item app-btn"
                                                            key={`${index}_tooltip`}
                                                        >
                                                            {moment(date).format('DD-MM-YYYY')}
                                                        </a>
                                                    ))}
                                            </a>
                                        </div>
                                    </span>
                                ) : null}
                            </div>
                        </ClickAwayListener>
                    </div> */}
                </div>
                <div className="card-detail-col col-info-2">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Allocation Orders</h6>
                        <ClickAwayListener onClickAway={() => setShowPurchaseOrderTooltip(false)}>
                            <div className="details-box-content">
                                {poNumbers?.length ? poNumbers[0] : '-'}
                                {poNumbers?.length > 1 ? (
                                    <span
                                        className={`dropdown-wrap menu-down ${
                                            showPurchaseOrderTooltip ? 'dropdown-open' : ''
                                        } info-dropdown modal-toast header-count header-dp`}
                                    >
                                        <div
                                            className="toast-message hand-icon"
                                            onClick={() => {
                                                setShowPurchaseOrderTooltip(!showPurchaseOrderTooltip);
                                                if (showTooltip) {
                                                    setShowTooltip(false);
                                                }
                                            }}
                                        >
                                            +{poNumbers?.length - 1}
                                        </div>
                                        <div className="dropdown-menu ">
                                            <a href="#" className="menu-item app-btn">
                                                {poNumbers
                                                    ?.slice(1, poNumbers?.length)
                                                    .map((po: any, index: number) => (
                                                        <a
                                                            href="#"
                                                            className="menu-item app-btn"
                                                            key={`${index}_tooltip`}
                                                        >
                                                            {po}
                                                        </a>
                                                    ))}
                                            </a>
                                        </div>
                                    </span>
                                ) : null}
                            </div>
                        </ClickAwayListener>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">
                            {totalCBM
                                ? totalCBM.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 1,
                                  })
                                : totalCBM}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">
                            {totalKgs
                                ? totalKgs.toLocaleString(undefined, {
                                      maximumFractionDigits: 2,
                                      minimumFractionDigits: 1,
                                  })
                                : totalKgs}
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Estimated TEU</h6>
                        <div className="details-box-content">
                            {totalTEU}
                            {/* {totalCBM && Math.ceil(totalCBM / 28) ? Math.ceil(totalCBM / 28) : totalCBM === 0 ? 0 : '-'} */}
                        </div>
                    </div>
                </div>
            </div>
            <RejectedBookingProposalModal
                modalView={reasonModalView}
                setModalView={setReasonModalView}
                reason={reason != null ? reason : ''}
                oneTimeModal={oneTimeModal}
                bpId={id}
                profileType={profileType}
            />
            {reason && (
                <RejectReasonHistory
                    modalView={rejectionReasonModalView}
                    setModalView={setrejectionRasonModalView}
                    bpId={id}
                    profileType={profileType}
                />
            )}
            <RejectedBpForwarderModal
                modalView={rejectedBpForwarderModalView}
                setModalView={setRejectedBpForwarderModalView}
                reason={reason != null ? reason : ''}
                bpId={id}
                profileType={profileType}
                setAssignAgentModalView={setAssignAgentModalView}
                setReason={setReasonRejection}
            />
            <ReapprovalBpClientModal
                modalView={reapproveBpModalView}
                setModalView={setReapproveBpModalView}
                reason={reason != null ? reason : ''}
                bpId={id}
                profileType={profileType}
            />
            <RejectBookingProposalModal
                modalView={assignAgentModalView}
                setModalView={setAssignAgentModalView}
                header={{
                    bpNumber: bpNumber != null ? bpNumber : '',
                    clientName: clientName != null ? clientName : '',
                    gateInDateExpired: gateInDateExpired != null ? gateInDateExpired : false,
                }}
                userProfile={{ profileType, userName }}
                reason={reasonRejection}
                setReason={setReasonRejection}
                headerText="Assign to Agent"
                setRejectedBpForwarderModalView={setRejectedBpForwarderModalView}
                gateInDateExpired={gateInDateExpired}
            />
            {showReasonModal ? (
                <CancellationDetailModal
                    onClose={onClose}
                    data={{ cancelDate, cancelReason, cancelledUser }}
                ></CancellationDetailModal>
            ) : null}
            {showLinkedModal ? (
                <LinkedBookingModal
                    linkedBpDetails={linkedBpDetails}
                    setShowLinkedModal={setShowLinkedModal}
                ></LinkedBookingModal>
            ) : null}
            {showAutoDeniedReasonModal && (
                <FeedbackModal
                    title="Auto Approval Denied"
                    description={
                        autoApprovalFailureReason ||
                        "ETD for this Booking Proposal is not within the start and end ship window of one or more POs in this Booking Proposal. Booking Proposal requires client's approval to continue with booking"
                    }
                    handleClose={() => setShowAutoDeniedReasonModal(false)}
                    okText="Okay"
                />
            )}
            {changePortModalView ? (
                <ChangePortWarningModal
                    setChangePortModalView={setChangePortModalView}
                    portCode={newOriginPortCode.value ? newOriginPortCode : newDestinationPortCode}
                    setPortCode={newOriginPortCode.value ? setNewOriginPortCode : setNewDestinationPortCode}
                    updatePortCode={updatePortCode}
                    type={newOriginPortCode.value ? 'origin' : 'destination'}
                />
            ) : null}
        </div>
    );
};
export default Index;
