import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';

// Import all api's
import {} from '../../services/apis/vendorListApi';
import { fetchWhWarehouse } from '../reducers/warehouseReducer';
import { getWhWarehouseApi } from 'src/services/apis/whWarehouseApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getWarehouseData({ payload }: { payload: any; type: any }) {
    try {
        const warehouse: AxiosResponse = yield call(getWhWarehouseApi);
        yield put({ type: fetchWhWarehouse, payload: warehouse });
    } finally {
    }
}

function* warehouseSaga() {
    yield takeEvery(fetchWhWarehouse, getWarehouseData);
}

// Export the saga (data-saga)
export default warehouseSaga;
