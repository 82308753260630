import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    fetchMapData,
    BookingPosStats,
    // fetchBookingAtPortMap,
    // fetchBookingsInTransitMap,
    // fetchBookingsInOriginMap,
    // fetchPurchaseOrderMap,
    fetchSidebarData,
    isLoadingFn,
    isLoadingFnSideBar,
    // BookingsIntransit,
} from 'src/store/reducers/dashboard/map';

// Import all api's
import {
    getBookingsAndPosStats,
    getMapBookingsAtPort,
    getMapBookingsInOrigin,
    getMapBookingsInTransit,
    getMapPurchaseOrders,
} from 'src/services/apis/dashboard/map';

interface BookinsPosStatsResponse extends BookingPosStats {
    success: boolean;
}

//generator function*
function* getMapData({ payload }: { payload: { type?: string; searchValue?: string }; type: string }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const stats: AxiosResponse<BookinsPosStatsResponse> = yield call(getBookingsAndPosStats, payload);
        //sessionStorage.setItem('mapData', JSON.stringify(payload));
        yield put({ type: fetchMapData, payload: { ...payload, ...stats } });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getMapIndividuaData({ payload }: { payload: any; type: string }) {
    if (payload?.tileType === 'bookingsAtOriginCountry') {
        try {
            yield put({ type: isLoadingFnSideBar, payload: true });
            const stats1: AxiosResponse<any> = yield call(getMapBookingsInOrigin, payload);
            yield put({
                type: fetchSidebarData,
                payload: stats1,
            });
        } catch {
            yield put({ type: isLoadingFnSideBar, payload: false });
        } finally {
            yield put({ type: isLoadingFnSideBar, payload: false });
        }
    } else if (payload?.tileType === 'transit' || payload?.tileType === 'bookingsIntransitArray') {
        try {
            yield put({ type: isLoadingFnSideBar, payload: true });
            const stats2: AxiosResponse<any> = yield call(getMapBookingsInTransit, payload);
            yield put({
                type: fetchSidebarData,
                payload: stats2,
            });
        } catch {
            yield put({ type: isLoadingFnSideBar, payload: false });
        } finally {
            yield put({ type: isLoadingFnSideBar, payload: false });
        }
    } else if (payload?.tileType === 'eta') {
        try {
            yield put({ type: isLoadingFnSideBar, payload: true });
            const stats1: AxiosResponse<any> = yield call(getMapBookingsInOrigin, payload);
            const stats3: AxiosResponse<any> = yield call(getMapBookingsAtPort, payload);

            yield put({
                type: fetchSidebarData,
                payload: [...JSON.parse(JSON.stringify(stats1)), ...JSON.parse(JSON.stringify(stats3))],
            });
        } catch {
            yield put({ type: isLoadingFnSideBar, payload: false });
        } finally {
            yield put({ type: isLoadingFnSideBar, payload: false });
        }
    } else if (payload?.tileType === 'etd' || payload?.tileType === 'destination') {
        try {
            yield put({ type: isLoadingFnSideBar, payload: true });
            // const stats1: AxiosResponse<any> = yield call(getMapBookingsInOrigin, payload);
            const stats3: AxiosResponse<any> = yield call(getMapBookingsAtPort, payload);

            yield put({
                type: fetchSidebarData,
                payload: [...JSON.parse(JSON.stringify(stats3))],
            });
        } catch {
            yield put({ type: isLoadingFnSideBar, payload: false });
        } finally {
            yield put({ type: isLoadingFnSideBar, payload: false });
        }
    } else if (payload.tileType === 'posAtOriginCountry') {
        try {
            yield put({ type: isLoadingFnSideBar, payload: true });
            const res: AxiosResponse<any> = yield call(getMapPurchaseOrders, payload);
            yield put({
                type: fetchSidebarData,
                payload: res,
            });
        } catch {
            yield put({ type: isLoadingFnSideBar, payload: false });
        } finally {
            yield put({ type: isLoadingFnSideBar, payload: false });
        }
    } else if (payload?.tileType === 'bookingsAtDestinationPort') {
        try {
            yield put({ type: isLoadingFnSideBar, payload: true });
            const res: AxiosResponse<any> = yield call(getMapBookingsAtPort, payload);
            yield put({
                type: fetchSidebarData,
                payload: res,
            });
        } catch {
            yield put({ type: isLoadingFnSideBar, payload: false });
        } finally {
            yield put({ type: isLoadingFnSideBar, payload: false });
        }
    } else if (payload?.tileType === 'bookingsAtOriginPort') {
        try {
            yield put({ type: isLoadingFnSideBar, payload: true });
            const res: AxiosResponse<any> = yield call(getMapBookingsAtPort, payload);
            yield put({
                type: fetchSidebarData,
                payload: res,
            });
        } catch {
            yield put({ type: isLoadingFnSideBar, payload: false });
        } finally {
            yield put({ type: isLoadingFnSideBar, payload: false });
        }
    }

    //yield put({ type: fetchSidebarData, payload: stats3 });

    //yield put({ type: fetchBookingsInTransitMap, payload: stats2 });
    //yield put({ type: fetchBookingsInOriginMap, payload: stats1 });
    //yield put({ type: fetchPurchaseOrderMap, payload: stats });
}

function* mapSaga() {
    yield takeLatest('map/fetchMapData', getMapData);
    //yield throttle(30000, 'map/fetchMapData', getMapData);
    yield takeEvery('map/fetchAllMapData', getMapIndividuaData);
}

// Export the saga
export default mapSaga;
