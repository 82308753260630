import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import ShowForPermissions from '../../../../ShowForPermissions';
import PoReview from '../../../BookingProposal/BookingAddedGrid/PurchaseOrderDetails';
import ProductDetailModal from '../../../BookingProposal/BookingAddedGrid/ProductDetailsModal';
import './po-line.scss';
import { fetchPolineReceipt } from '../../../../store/reducers/receipt/poLine';
import BSTooltip from '../../../../components/common/BSTooltip';
import { poStatusList, skuStatusList } from '../POLinesConfirmation/PoStatusList';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    selectReceiptHeaderError,
    selectReceiptHeaderIsError,
    selectReceiptHeaderSuccess,
    clearFailure,
    receiveInFull,
    fetchReceiptHeaderList,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { error, Position, success } from '../../../../utils/toast';
import { useParams } from 'react-router-dom';
import ComplianceModal from '../POLinesConfirmation/ComplianceModal';
import ViewCompilanceCheckModal from '../POLinesConfirmation/ViewCompilanceCheckModal';
import ReceivedQuantityModal from '../POLinesConfirmation/ReceivedQuantityModal';
import ViewReceivedQuantityModal from '../POLinesConfirmation/ViewReceivedQuantityModal';
import { useUserProfile } from '../../../../hooks/useUserProfile';

/* eslint no-var: off */

interface BioProps {
    bpID?: any;
    // setaddedgrid: any;
    setSelectedItemsAdded: any;
    selectedItemsAdded: any;
    addPoList: any;
    originalData?: any;
}
const Index: React.FC<BioProps> = ({
    // bpID,
    // setaddedgrid,
    setSelectedItemsAdded,
    selectedItemsAdded,
    // addPoList,
    originalData,
}) => {
    const { isBlp, blpIntegrationsReceipt, userName, fullName } = useUserProfile();
    const { id } = useParams();
    const allPoStatus = ['Ready to Receive', 'Received in Full', 'Over Received', 'Short Receipt', 'Cancelled'];
    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectReceiptHeaderIsError);
    const receiptHeaderSuccess = useAppSelector(selectReceiptHeaderSuccess);
    const receiptHeaderError = useAppSelector(selectReceiptHeaderError);
    // const { profileType } = useUserProfile();
    // const sku = useAppSelector(selectSku);
    const { prouctsList } = useSelector((state: RootState) => state.addedPos);
    // const dispatch = useDispatch();
    // const [modalView, setModalView] = useState(true);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [productsAll, setproductsAll] = useState([]);
    const [VendorCode, setVendorCode] = useState('');
    const [search, setSearch] = useState('');
    const [searchName, setSearchName] = useState('');
    const [allPoData, setAllPoData] = useState<any>([]);
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>([]);
    const [poStatusData, setPoStatusData] = useState<any>(allPoStatus);
    const [isLoading, setIsLoading] = useState(false);
    const [showComplianceModal, setShowComplianceModal] = useState(false);
    const [showReceivedQtyModal, setShowReceivedQtyModal] = useState(false);
    const [showViewComplianceModal, setShowViewComplianceModal] = useState(false);
    const [showViewReceivedQtyModal, setShowViewReceivedQtyModal] = useState(false);
    const [currentPoLine, setCurrentPoLine] = useState<any>({});
    // const [poLine, setpoLine] = useState(0);
    var poLineNum = 0;
    var poStatus = true;

    useEffect(() => {
        if (originalData) {
            setAllPoData(originalData);
        }
    }, [originalData]);

    const selectedIds = (boid: string, rsId: any, index: any, type: boolean) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boid) {
                    var filterAr: any = [...selectedAr[i].receiptSubPoLineDtoList, ...rsId];
                    flag = true;

                    filterAr = filterAr.filter(function (item: any, pos: any) {
                        return filterAr.indexOf(item) == pos;
                    });
                    selectedAr[i].receiptSubPoLineDtoList = [...filterAr];
                }
            }
            if (!flag) {
                var newObj = {
                    id: boid,
                    isFullPo: false,
                    receiptSubPoLineDtoList: [...rsId],
                };
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boid) {
                    var delAr: any[] = [...selectedAr[i].receiptSubPoLineDtoList];
                    for (var j = 0; j < delAr.length; j++) {
                        for (var k = 0; k < rsId.length; k++) {
                            if (delAr[j] === rsId[k]) {
                                delAr.splice(j, 1);
                                selectedAr[i].receiptSubPoLineDtoList.splice(j, 1);
                                if (selectedAr[i].receiptSubPoLineDtoList.length === 0) {
                                    selectedAr.splice(i, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
    };

    const getComplianceFieldValue = (data: any, key: string, skuId: string) => {
        const value = data?.skuDetailsReceipts?.find((res: any) => res.skuId === skuId)[key];
        return value;
    };

    const selectAll = (type: boolean) => {
        var selectallar: any[] = [];
        var newObj = {};
        if (type) {
            allPoData?.forEach((element: any) => {
                var singleVal: any = [];
                newObj = {
                    id: element.poId,
                    isFullPo: false,
                    receiptSubPoLineDtoList: singleVal,
                };
                element.receiptSubPoLineDtoList.forEach((element2: object) => {
                    var val = (element2 as any).rsId;
                    var skuId = (element2 as any).skuId;
                    var status = (element2 as any).status;
                    var isComplianceCheckNeeded = getComplianceFieldValue(element, 'isComplainceCheckNeeded', skuId);
                    var complianceCheckStatus = getComplianceFieldValue(element, 'complianceCheckStatus', skuId);

                    if (
                        (isComplianceCheckNeeded && complianceCheckStatus === 'pass') ||
                        (!isComplianceCheckNeeded && (status === 'Ready to Receive' || status === 'Ready To Receive'))
                    ) {
                        singleVal.push(val);
                    }
                });
                if (singleVal?.length) {
                    selectallar.push(newObj);
                }
            });
            setSelectedItemsAdded(selectallar);
        } else {
            setSelectedItemsAdded([]);
        }
    };

    const getAllIds = (val: any) => {
        var newArray: any[] = [];
        val?.receiptSubPoLineDtoList?.forEach((element: any) => {
            var isComplianceCheckNeeded = getComplianceFieldValue(val, 'isComplainceCheckNeeded', element?.skuId);
            var complianceCheckStatus = getComplianceFieldValue(val, 'complianceCheckStatus', element?.skuId);

            if (
                (isComplianceCheckNeeded && complianceCheckStatus === 'pass') ||
                (!isComplianceCheckNeeded &&
                    (element?.status === 'Ready to Receive' || element?.status === 'Ready To Receive'))
            ) {
                newArray.push(element.rsId);
            }
        });
        return newArray;
    };

    const checkSelected = (boid: any, val: any) => {
        var flag = false;
        selectedItemsAdded.forEach((element: any) => {
            if (boid === element.id) {
                element.receiptSubPoLineDtoList.forEach((element2: any) => {
                    if (element2 === val) {
                        flag = true;
                    }
                });
            }
        });

        return flag;
    };
    const allSelected = () => {
        var totalAll = 0;
        var totalSelected = 0;
        var flag = false;
        originalData?.forEach((element: any) => {
            element.receiptSubPoLineDtoList.forEach((element1: any) => {
                if (element1.length !== 0) {
                    totalAll++;
                }
            });
        });
        selectedItemsAdded?.forEach((element: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            element.receiptSubPoLineDtoList?.forEach((element1: any) => {
                totalSelected++;
            });
        });

        if (totalSelected >= totalAll && totalAll !== 0 && allPoData?.length) {
            flag = true;
        }
        return flag;
    };
    const singleRowChecked = (id: any, poData: any) => {
        var flag = false;
        if (poData?.length) {
            selectedItemsAdded?.forEach((element: any) => {
                if (
                    element.receiptSubPoLineDtoList.length === poData[0]?.receiptSubPoLineDtoList?.length &&
                    element.id === id
                ) {
                    flag = true;
                }
            });
        }
        return flag;
    };
    const selectSingle = (boId: any, rsId: any, index: any, type: boolean) => {
        var selectedAr: any[] = [...selectedItemsAdded];
        var flag = false;
        if (type) {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boId) {
                    flag = true;
                    selectedAr[i].receiptSubPoLineDtoList = [...selectedAr[i].receiptSubPoLineDtoList, rsId];
                }
            }
            if (!flag) {
                var newObj = {
                    id: boId,
                    isFullPo: false,
                    receiptSubPoLineDtoList: [rsId],
                };
                selectedAr.push(newObj);
            }
        } else {
            for (var i = 0; i < selectedAr.length; i++) {
                if (selectedAr[i].id === boId) {
                    var delAr: any[] = [...selectedAr[i].receiptSubPoLineDtoList];
                    for (var j = 0; j < delAr.length; j++) {
                        if (delAr[j] === rsId) {
                            delAr.splice(j, 1);
                            selectedAr[i].receiptSubPoLineDtoList.splice(j, 1);
                            if (selectedAr[i].receiptSubPoLineDtoList.length === 0) {
                                selectedAr.splice(i, 1);
                            }
                        }
                    }
                }
            }
        }
        setSelectedItemsAdded(selectedAr);
    };
    /* eslint-disable no-undefined */

    useEffect(() => {
        setproductsAll(prouctsList);
    }, [prouctsList]);

    const updateToReceiveInFull = () => {
        setIsLoading(true);
        const receiptAddRequests = selectedItemsAdded?.map((res: any) => ({
            purchaseOrderId: res.id,
            receiptSubPoLineIds: res.receiptSubPoLineDtoList,
        }));
        const payload = {
            receiptId: id,
            receiptAddRequests,
            fullName,
            userName,
        };
        dispatch(receiveInFull(payload));
        setSelectedItemsAdded([]);
    };

    const getCount = () => {
        let total = 0;
        selectedItemsAdded.forEach((element: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            element.receiptSubPoLineDtoList.forEach((element2: any) => {
                total++;
            });
        });
        return total;
    };

    const onFilterChange = () => {
        let receiptSubPoLineDtoList;
        const searchResult = [];
        const data = search.length ? allPoData : originalData;

        if (statusFilterSelected.length) {
            // for (let index = 0; index < statusFilterSelected.length; index++) {
            // const searchKey = statusFilterSelected[index];

            // searchResult = data?.filter(
            //     (el: any) => el.poNumber && el.poNumber.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
            // );

            // if (!searchResult?.length) {
            for (let index = 0; index < data.length; index++) {
                receiptSubPoLineDtoList = data[index]?.receiptSubPoLineDtoList?.filter((e: any) =>
                    statusFilterSelected.some((str: string) => str.toLowerCase().includes(e.status.toLowerCase()))
                );
                if (receiptSubPoLineDtoList?.length) {
                    const newData = JSON.parse(JSON.stringify(data[index]));
                    newData['receiptSubPoLineDtoList'] = receiptSubPoLineDtoList;
                    searchResult.push(newData);
                }
            }
            // }
            // }
            setAllPoData(searchResult);
        } else {
            if (search.length > 2) {
                onSearch(search);
            } else {
                setAllPoData(originalData);
            }
        }
    };

    const onSearch = (searchKey: string) => {
        let receiptSubPoLineDtoList;
        setSearch(searchKey);
        const data = statusFilterSelected.length ? allPoData : originalData;
        if (searchKey.trim().length > 2) {
            let searchResult = data?.filter(
                (el: any) => el.poNumber && el.poNumber.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
            );
            if (!searchResult?.length) {
                for (let index = 0; index < data.length; index++) {
                    receiptSubPoLineDtoList = data[index]?.receiptSubPoLineDtoList?.filter(
                        (e: any) => e.sku && e.sku.toLocaleLowerCase().includes(searchKey.toLocaleLowerCase())
                    );
                    if (receiptSubPoLineDtoList?.length) {
                        const newData = JSON.parse(JSON.stringify(data[index]));
                        newData['receiptSubPoLineDtoList'] = receiptSubPoLineDtoList;
                        searchResult = [...searchResult, newData];
                    }
                }
            }
            setAllPoData(searchResult);
        } else {
            if (statusFilterSelected.length) {
                onFilterChange();
            } else {
                setAllPoData(originalData);
            }
        }
    };
    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        if (keyword) {
            const results = poStatusData.filter((user: string) => {
                return user && user.toLowerCase().includes(keyword.toLowerCase());
            });
            setPoStatusData(results);
        } else {
            setPoStatusData(allPoStatus);
        }
        setSearchName(keyword);
    };
    const filterByColumn = (e: any) => {
        setToggleDropdown(!toggleDropdown);
        if (!toggleDropdown) {
            setPoStatusData(allPoStatus);
        }
        setSearchName('');
        filter(e);
        e.stopPropagation();
    };

    useEffect(() => {
        onFilterChange();
    }, [statusFilterSelected, search]);

    const resizeFilter = () => {
        const el_status_div = document.getElementById('status_div');
        if (el_status_div?.getBoundingClientRect().left) {
            setSetPosition(el_status_div?.getBoundingClientRect().left - 35);
        }
    };

    useEffect(() => {
        if (isError && receiptHeaderError?.config?.url?.includes('received-in-full')) {
            error(receiptHeaderError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (receiptHeaderSuccess?.status === 200 || receiptHeaderSuccess?.status === 201) {
            if (receiptHeaderSuccess?.data?.type === 'receivedInFull') {
                success('PO line status updated successfully', Position.TOP_RIGHT);
                dispatch(clearFailure([]));
                dispatch(fetchPolineReceipt(id));
                dispatch(
                    fetchReceiptHeaderList({
                        id,
                    })
                );
            }
        }
        setIsLoading(false);
    }, [isError, receiptHeaderSuccess, receiptHeaderError]);

    const checkBoxStatus = () => {
        if (isBlp && !blpIntegrationsReceipt) {
            return localStorage.getItem('receiptStatus') !== 'Closed' ? true : false;
        } else {
            return false;
        }
    };

    return (
        <div className="grid-section po-line-confirmation-details-grid">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="grid-holder">
                <div className="grid-header">
                    <div className="search-entry-holder">
                        <input
                            placeholder="Search by PO Number, SKU"
                            type="text"
                            className="search-input input-text search-field-container"
                            value={search}
                            onChange={(e) => onSearch(e.target.value)}
                        ></input>
                        <div
                            className={`search-toolip ${
                                search?.length > 0 && search?.length < 3 ? 'tooltip-show' : ''
                            }`}
                        >
                            Enter atleast 3 characters to search
                        </div>
                        <svg className="svg-icon search-icon">
                            <use xlinkHref="#searchIcon"></use>
                        </svg>
                        <button
                            onClick={() => {
                                setSearch('');
                                if (statusFilterSelected.length) {
                                    onFilterChange();
                                } else {
                                    setAllPoData(originalData);
                                }
                            }}
                            className="app-btn text-close-btn"
                        >
                            <svg className="svg-icon text-close-icon">
                                <use xlinkHref="#closeIcon"></use>
                            </svg>
                        </button>
                    </div>
                    <div className="tab-with-proposal">
                        {selectedItemsAdded?.length !== 0 && (
                            <div className="tab-grid-select-holder">
                                <span className="btn-pills info-pill" onClick={() => setSelectedItemsAdded([])}>
                                    <span className="pill-text">{getCount()} Selected</span>
                                    <button className="pill-action">
                                        <svg className="svg-icon close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </span>
                                <button
                                    onClick={() => updateToReceiveInFull()}
                                    className="app-btn app-btn-primary footer-btn"
                                >
                                    Receive in Full
                                </button>
                            </div>
                        )}

                        {/* <div className="btn-holder">
                        <button className="app-btn app-btn-secondary icon-button" title="Assign PO Lines">
                            <svg className="svg-icon add-btn-icon">
                                <use xlinkHref="#plusIcon"></use>
                            </svg>
                            <span className="button-text">Assign PO Lines</span>
                        </button>
                    </div> */}
                    </div>
                </div>

                <div
                    onScroll={() => {
                        resizeFilter();
                    }}
                    onClick={() => setToggleDropdown(false)}
                    className="grid-container"
                >
                    {allPoData?.length === 0 && (
                        // <ShowForPermissions permission="create" type="receipt">
                        <div className="grid-no-content ">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No PO lines assigned to this receipt</p>
                        </div>
                        // </ShowForPermissions>
                    )}

                    <div className="grid-scroll-content">
                        <table className="grid-table ">
                            <thead className="multi-row-grid-header">
                                <tr className="grid-sticky-row">
                                    <th className="grid-cell-header col-inbound-po grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data">
                                            <span className="header-title">INBOUND PO NUMBER</span>
                                        </div>
                                    </th>
                                    {checkBoxStatus() && (
                                        <ShowForPermissions permission="edit" type="receipt">
                                            <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data centre-align">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            checked={allSelected()}
                                                            onChange={(e) => selectAll(e.currentTarget.checked)}
                                                            type="checkbox"
                                                            className="checkbox-input"
                                                        ></input>
                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use href="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                    <div className="grid-header-checkbox-title">
                                                        RIF
                                                        <BSTooltip
                                                            id="rif"
                                                            title="Select line to receive in full"
                                                            classList="info-btn"
                                                        >
                                                            <button className="app-btn">
                                                                <svg className="svg-icon error-icon">
                                                                    <use xlinkHref="#errorIcon">
                                                                        <title>Warning</title>
                                                                    </use>
                                                                </svg>
                                                            </button>
                                                        </BSTooltip>
                                                    </div>
                                                </div>
                                            </th>
                                        </ShowForPermissions>
                                    )}
                                    <th className="grid-cell-header col-line">
                                        <div className="grid-cell-data">
                                            <span className="header-title">PO LINE No.</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">SKU</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-desc">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESCRIPTION</span>
                                        </div>
                                    </th>
                                    <th id="status_div" className="grid-cell-header col-desc">
                                        <div style={{ justifyContent: 'start' }} className="grid-cell-data">
                                            <span className="header-title">STATUS</span>
                                            <div
                                                style={{ marginLeft: '10px' }}
                                                className={
                                                    toggleDropdown || statusFilterSelected.length
                                                        ? 'filter-menu-wrap open'
                                                        : 'filter-menu-wrap'
                                                }
                                            >
                                                <button
                                                    onClick={(e) => {
                                                        filterByColumn(e);
                                                        resizeFilter();
                                                    }}
                                                    className="app-btn filter-menu-button"
                                                >
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-prq">
                                        <div className="grid-cell-data">
                                            <span className="header-title">PLANNED RECEIPT QTY</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header multi-column-th  po-th-qty " colSpan={2}>
                                        <div className="grid-cell-data">
                                            <span className="header-title">qty received</span>
                                        </div>
                                    </th>
                                    {checkBoxStatus() && (
                                        <ShowForPermissions permission="edit" type="receipt">
                                            <th className="grid-cell-header  grid-sticky-column sticky-right-aligned col-user-action ">
                                                <div className="grid-cell-data">ACTION</div>
                                            </th>
                                        </ShowForPermissions>
                                    )}
                                </tr>
                                <tr className="grid-sticky-row">
                                    <th className="grid-cell-header col-po-no grid-sticky-column sticky-left-aligned">
                                        <div className="grid-cell-data">
                                            <span></span>
                                        </div>
                                    </th>
                                    {checkBoxStatus() && (
                                        <ShowForPermissions permission="edit" type="receipt">
                                            <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data">
                                                    <span></span>
                                                </div>
                                            </th>
                                        </ShowForPermissions>
                                    )}
                                    <th className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span></span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-available">
                                        <div className="grid-cell-data">
                                            <span>AVAILABLE</span>
                                        </div>
                                    </th>
                                    <th className="grid-cell-header col-on-hold">
                                        <div className="grid-cell-data">
                                            <span>ON HOLD</span>
                                        </div>
                                    </th>
                                    {checkBoxStatus() && (
                                        <ShowForPermissions permission="edit" type="receipt">
                                            <th className="grid-cell-header  grid-sticky-column sticky-right-aligned col-user-action ">
                                                <div className="grid-cell-data">
                                                    <span></span>
                                                </div>
                                            </th>
                                        </ShowForPermissions>
                                    )}
                                </tr>
                            </thead>

                            {/* One detailed row */}

                            {allPoData?.map((res: any, i: any) => (
                                <tbody key={i} className="grouped-splited-row">
                                    {res?.receiptSubPoLineDtoList.map((val: any, i2: any) => {
                                        if (poLineNum === val?.poLineNumber && i2 !== 0) {
                                            poStatus = false;
                                        } else {
                                            poStatus = true;
                                        }
                                        poLineNum = val?.poLineNumber;
                                        return (
                                            <tr key={i2}>
                                                <td className="grid-cell col-inbound-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                    <div className="grid-cell-data">
                                                        <div className="checkbox-data-wrapper">
                                                            {checkBoxStatus() && (
                                                                <ShowForPermissions permission="edit" type="receipt">
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            checked={singleRowChecked(
                                                                                res?.poId,
                                                                                originalData?.filter(
                                                                                    (e: any) => e.poId === res?.poId
                                                                                )
                                                                            )}
                                                                            disabled={
                                                                                i2 === 0 &&
                                                                                res?.receiptSubPoLineDtoList?.every(
                                                                                    (elem: any) =>
                                                                                        (elem?.isComplainceCheckNeeded &&
                                                                                            elem?.complianceCheckStatus !==
                                                                                                'pass') ||
                                                                                        (elem?.status !==
                                                                                            'Ready to Receive' &&
                                                                                            elem?.status !==
                                                                                                'Ready To Receive')
                                                                                )
                                                                            }
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            onChange={(e) => {
                                                                                selectedIds(
                                                                                    res?.poId,
                                                                                    getAllIds(res),
                                                                                    i,
                                                                                    e.currentTarget.checked
                                                                                );
                                                                            }}
                                                                        />

                                                                        {i2 === 0 && (
                                                                            <div className="checkmark">
                                                                                <svg className="svg-icon tick-icon">
                                                                                    <use xlinkHref="#tickIcon">
                                                                                        <title>check mark</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </div>
                                                                        )}
                                                                    </label>
                                                                </ShowForPermissions>
                                                            )}
                                                            {i2 === 0 && (
                                                                <a
                                                                    className="proposal-link read-all-link"
                                                                    onClick={() =>
                                                                        setpoDisplay({
                                                                            status: true,
                                                                            id: res?.poId,
                                                                        })
                                                                    }
                                                                >
                                                                    {res?.poNumber}
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </td>
                                                {checkBoxStatus() && (
                                                    <ShowForPermissions permission="edit" type="receipt">
                                                        <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                                            <div className="grid-cell-data centre-align">
                                                                <div className="checkbox-data-wrapper">
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            checked={checkSelected(
                                                                                res?.poId,
                                                                                val?.rsId
                                                                            )}
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            disabled={
                                                                                (res?.skuDetailsReceipts?.find(
                                                                                    (elem: any) =>
                                                                                        elem.skuId === val?.skuId
                                                                                )?.isComplainceCheckNeeded &&
                                                                                    res?.skuDetailsReceipts?.find(
                                                                                        (elem: any) =>
                                                                                            elem.skuId === val?.skuId
                                                                                    )?.complianceCheckStatus !==
                                                                                        'pass') ||
                                                                                (val?.status !== 'Ready to Receive' &&
                                                                                    val?.status !== 'Ready To Receive')
                                                                            }
                                                                            onChange={(e) =>
                                                                                selectSingle(
                                                                                    res?.poId,
                                                                                    val?.rsId,
                                                                                    i2,
                                                                                    e.currentTarget.checked
                                                                                )
                                                                            }
                                                                        />

                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use xlinkHref="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </ShowForPermissions>
                                                )}
                                                <td className="grid-cell col-po-sku">
                                                    <div className="grid-cell-data">
                                                        {poStatus && val?.poLineNumber}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-sku">
                                                    <div className="grid-cell-data">
                                                        {poStatus &&
                                                            skuStatusList(
                                                                res?.skuDetailsReceipts?.find(
                                                                    (elem: any) => elem.skuId === val?.skuId
                                                                )?.complianceCheckStatus != null
                                                                    ? res?.skuDetailsReceipts?.find(
                                                                          (elem: any) => elem.skuId === val?.skuId
                                                                      )?.complianceCheckStatus
                                                                    : res?.skuDetailsReceipts?.find(
                                                                          (elem: any) => elem.skuId === val?.skuId
                                                                      )?.isComplainceCheckNeeded
                                                                    ? 'pending'
                                                                    : 'noCompliance',
                                                                setVendorCode,
                                                                setModalViewproduct,
                                                                res,
                                                                val,
                                                                poStatus,
                                                                setShowViewComplianceModal,
                                                                setCurrentPoLine
                                                            )}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-qty">
                                                    <div className="grid-cell-data">{val?.description}</div>
                                                </td>
                                                <td className="grid-cell col-po-grd">
                                                    <div className="grid-cell-data">
                                                        {poStatusList(
                                                            val?.status,
                                                            setShowViewReceivedQtyModal,
                                                            setCurrentPoLine,
                                                            val
                                                        )}
                                                    </div>
                                                </td>
                                                <td className="grid-cell col-po-cbm">
                                                    <div className="grid-cell-data">
                                                        {val?.plannedReceiptQuantity
                                                            ? val?.plannedReceiptQuantity
                                                            : '-'}
                                                    </div>
                                                </td>
                                                {checkSelected(res?.poId, val?.rsId) ? (
                                                    <>
                                                        <td className="grid-cell col-po-weight">
                                                            <div className="grid-cell-data disabled">
                                                                {val?.plannedReceiptQuantity}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell col-po-weight">
                                                            <div className="grid-cell-data disabled">0</div>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td className="grid-cell col-po-weight">
                                                            <div className="grid-cell-data">
                                                                {val?.availableQty >= 0 && val?.availableQty != null
                                                                    ? val?.availableQty
                                                                    : '-'}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell col-po-weight">
                                                            <div className="grid-cell-data">
                                                                {val?.onHoldQty >= 0 && val?.onHoldQty != null
                                                                    ? val?.onHoldQty
                                                                    : '-'}
                                                            </div>
                                                        </td>
                                                    </>
                                                )}
                                                {checkBoxStatus() && (
                                                    <ShowForPermissions permission="edit" type="receipt">
                                                        <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned col-user-action">
                                                            <div className="grid-cell-data">
                                                                <button
                                                                    className="action-btn app-btn app-btn-secondary "
                                                                    title="Edit Received Quantity"
                                                                    onClick={() => {
                                                                        const complianceData =
                                                                            res?.skuDetailsReceipts?.find(
                                                                                (elem: any) => elem.skuId === val?.skuId
                                                                            );
                                                                        setCurrentPoLine({
                                                                            ...val,
                                                                            currentComplianceCheckStatus:
                                                                                complianceData?.complianceCheckStatus,
                                                                        });
                                                                        setShowReceivedQtyModal(true);
                                                                    }}
                                                                    disabled={
                                                                        res?.skuDetailsReceipts?.find(
                                                                            (elem: any) => elem.skuId === val?.skuId
                                                                        )?.isComplainceCheckNeeded &&
                                                                        res?.skuDetailsReceipts?.find(
                                                                            (elem: any) => elem.skuId === val?.skuId
                                                                        )?.complianceCheckStatus == null
                                                                            ? true
                                                                            : false
                                                                    }
                                                                >
                                                                    <svg className="svg-icon edit-icon icon-20">
                                                                        <use xlinkHref="#editIcon">
                                                                            <title>Edit</title>
                                                                        </use>
                                                                    </svg>
                                                                </button>
                                                                {poStatus &&
                                                                    res?.skuDetailsReceipts?.find(
                                                                        (elem: any) => elem.skuId === val?.skuId
                                                                    )?.isComplainceCheckNeeded &&
                                                                    val?.complianceCheckStatus == null && (
                                                                        <button
                                                                            className="action-btn app-btn app-btn-secondary"
                                                                            title="Compliance Check"
                                                                            onClick={() => {
                                                                                const complianceData =
                                                                                    res?.skuDetailsReceipts?.find(
                                                                                        (elem: any) =>
                                                                                            elem.skuId === val?.skuId
                                                                                    );

                                                                                setCurrentPoLine({
                                                                                    ...complianceData,
                                                                                    rsId: val?.rsId,
                                                                                });
                                                                                complianceData?.complianceCheckStatus ==
                                                                                null
                                                                                    ? setShowComplianceModal(true)
                                                                                    : setShowViewComplianceModal(true);
                                                                            }}
                                                                        >
                                                                            <svg className="svg-icon icon-20">
                                                                                <use xlinkHref="#noteIcon">
                                                                                    <title>Note</title>
                                                                                </use>
                                                                            </svg>
                                                                        </button>
                                                                    )}
                                                            </div>
                                                        </td>
                                                    </ShowForPermissions>
                                                )}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ))}
                        </table>
                    </div>
                    {ModalViewproduct.status && (
                        <div className="grid-section grid-added-booking product-details-wrapper">
                            <div className="grid-holder">
                                <div className="grid-container">
                                    <ProductDetailModal
                                        display={ModalViewproduct}
                                        displayFunction={setModalViewproduct}
                                        id={VendorCode}
                                        data={prouctsList}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {toggleDropdown && (
                    <div
                        style={{ left: String(setPosition) + 'px', top: '115px' }}
                        className="filter-menu searchable-dropdown-common"
                    >
                        <div className="search-entry-holder">
                            <input
                                placeholder="Search"
                                type="text"
                                className="search-input input-text grid-search-field"
                                value={searchName}
                                onChange={filter}
                            ></input>
                            <svg className="svg-icon search-icon">
                                <use xlinkHref="#searchIcon"></use>
                            </svg>
                            <button
                                onClick={(e) => {
                                    setSearchName('');
                                    filter(e);
                                    setPoStatusData(allPoStatus);
                                }}
                                className="app-btn text-close-btn"
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </div>
                        <div className="checkbox-wrapper">
                            {poStatusData &&
                                poStatusData.map((value: string, index: number) => (
                                    <div key={index} className="checkbox-item">
                                        <label className="app-check-wrapper">
                                            <input
                                                onChange={(e: any) => {
                                                    statusFilterSelected.filter((item: string, index: number) => {
                                                        if (statusFilterSelected.indexOf(item) == index) {
                                                            return item;
                                                        }
                                                    });

                                                    if (e.target.checked) {
                                                        setStatusFilterSelected((prev: any) => [...prev, value]);
                                                    } else {
                                                        const filtered = statusFilterSelected?.filter(
                                                            (res: any) => res !== value
                                                        );
                                                        setStatusFilterSelected(filtered);
                                                    }
                                                }}
                                                // defaultChecked={false}
                                                checked={statusFilterSelected.includes(value)}
                                                type="checkbox"
                                                className="checkbox-input"
                                            />
                                            <div className="checkmark">
                                                <svg className="svg-icon tick-icon">
                                                    <use xlinkHref="#tickIcon">
                                                        <title>check mark</title>
                                                    </use>
                                                </svg>
                                            </div>
                                            <div className="checkbox-label">{value}</div>
                                        </label>
                                    </div>
                                ))}
                            {poStatusData?.length === 0 && (
                                <div className="no-data-content">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No Matches Found</p>
                                </div>
                            )}
                        </div>
                        {poStatusData?.length > 0 && (
                            <div className="clear-btn">
                                <button onClick={() => setStatusFilterSelected([])} className="app-btn">
                                    <span className="button-text">Clear All</span>
                                </button>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}
            <ComplianceModal
                complianceModalVisibility={showComplianceModal}
                setComplianceModalVisibility={setShowComplianceModal}
                data={currentPoLine}
            />
            <ReceivedQuantityModal
                receivedQtyModalVisibility={showReceivedQtyModal}
                setReceivedQtyModalVisibility={setShowReceivedQtyModal}
                data={currentPoLine}
            />
            <ViewCompilanceCheckModal
                viewComplianceModalVisibility={showViewComplianceModal}
                setViewComplianceModalVisibility={setShowViewComplianceModal}
                data={currentPoLine}
            />
            <ViewReceivedQuantityModal
                viewReceivedQuantityModalVisibility={showViewReceivedQtyModal}
                setReceivedQtyModalVisibility={setShowViewReceivedQtyModal}
                data={currentPoLine}
            />
        </div>
    );
};
export default Index;
