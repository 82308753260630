import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import DatePicker from 'src/components/common/DatePicker/withoutFormik';
import { addVendorUser } from 'src/services/apis/vendorUsersApi';
import { useParams } from 'react-router-dom';
import { error, Position, success } from 'src/utils/toast';
import moment from 'moment';
import { vendorInputTypeAddress, vendorInputTypeProfile } from './userDetails';
import { validateEmail } from 'src/utils';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { fetchCountryByCountryCode, fetchCountryDetailsMgtApi } from 'src/services/apis/purchaseOrder/productPoApi';
import { getRoleWithPermission } from 'src/services/apis/usersForwarderApis';

interface ModalProps {
    setShowModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
    getVendorLists: any;
}

const Index: React.FC<ModalProps> = ({ setShowModal, data, getVendorLists }) => {
    const { id, vn_user_pk } = useParams();
    const mode = vn_user_pk === 'add' ? 'add' : 'edit';
    const [isLoading, setIsLoading] = useState(false);
    const [vendorUser, setVendorUser] = useState(data);
    const [countryData, setCountryData] = useState<any>([]);
    const [roleData, setRoleData] = useState<any>([]);

    const submitVendorUserSaveStatus = () => {
        return !vendorUser?.userName || !vendorUser?.email || !vendorUser?.firstName || !vendorUser?.lastName
            ? true
            : false;
    };
    const phoneRegExp = /^\+[1-9]{1}[0-9]{3,14}$/;

    const getCountryData = async (key: string | undefined) => {
        const countryData: any = await fetchCountryByCountryCode({ search: key ? key : '' });
        setCountryData(countryData?.data);
    };

    const getRoleData = async () => {
        const roleValue: any = await getRoleWithPermission({ profile: 'vendor' });
        setRoleData(
            roleValue?.map((res: any) => ({
                label: res?.RL_RoleName,
                value: res?.RL_RoleName,
            }))
        );
    };

    useEffect(() => {
        getCountryData(data?.countryCode ? data?.countryCode : '');
        getRoleData();
    }, [data]);

    const onCountrySearch = async (value: any, meta: any) => {
        if (value?.length > 3) {
            const countryData: any = await fetchCountryDetailsMgtApi({ search: value });
            setCountryData(countryData?.data);
        }
    };

    const submitVendorUser = async () => {
        if (vendorUser?.mobileNumber !== '' && !phoneRegExp.test(vendorUser?.mobileNumber)) {
            error('Please enter a valid phone number with country code', Position.TOP_RIGHT);
            return false;
        } else if (!validateEmail(vendorUser?.email)) {
            error('Please enter a valid email', Position.TOP_RIGHT);
            return false;
        }
        try {
            const payload = {
                ...vendorUser,
                vnPK: id,
                dob: vendorUser?.dob ? moment(vendorUser?.dob).format('MM/DD/YYYY') : null,
            };
            setIsLoading(true);
            if (mode === 'edit') {
                addVendorUser(payload)
                    .then(async (res: any) => {
                        success('Vendor user has been updated successfully', Position.TOP_RIGHT);
                        await getVendorLists();
                        setShowModal(false);
                    })
                    .catch((err: any) => {
                        console.log('error', err);
                        error(err?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                    })
                    .finally(() => setIsLoading(false));
            } else {
                addVendorUser(payload)
                    .then(async (res: any) => {
                        success('Vendor user has been created successfully', Position.TOP_RIGHT);
                        // setShowModal(false);
                        setTimeout(() => {
                            // setIsLoading(false);
                            location.href = `/profile/vendor/${id}/users`;
                        }, 1500);
                    })
                    .catch((err: any) => {
                        console.log('error', err);
                        setIsLoading(false);
                        error(err?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                    });
            }
        } catch (err: any) {
            setIsLoading(false);
            error(err?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    const isDisabled = (value: any, key: string) => {
        if (['firstName', 'lastName', 'email']?.includes(key)) {
            if (typeof vendorUser?.editAllowed === 'boolean') {
                return vendorUser?.editAllowed ? false : true;
            } else {
                return false;
            }
        } else {
            return value?.disable;
        }
    };

    return (
        <div className="app-modal purchase-detail-modal">
            <div className="modal-content-holder medium-popup ">
                <div className="modal-header">
                    <div className="title">{mode === 'add' ? 'Add' : 'Edit'} Vendor User</div>
                    <button onClick={() => setShowModal(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="modal-main-content">
                        {Object.entries({ ...vendorInputTypeProfile, ...vendorInputTypeAddress }).map(
                            ([key, value]) => (
                                <div className=" modal-input-holder">
                                    <label className="modal-input-label">
                                        {value.name}
                                        {value.required && <span className="mandatory-field-text">*</span>}
                                    </label>
                                    <div className="modal-input-layout-item calendar">
                                        {(() => {
                                            switch (value.type) {
                                                case 'text':
                                                    return (
                                                        <input
                                                            placeholder={`Enter ${value.name}`}
                                                            type="text"
                                                            disabled={isDisabled(value, key)}
                                                            className="modal-input input-text with-border"
                                                            onChange={(event) => {
                                                                if (key === 'email') {
                                                                    setVendorUser((prev: any) => ({
                                                                        ...prev,
                                                                        [key]: event.target.value,
                                                                        userName: event.target.value,
                                                                    }));
                                                                } else {
                                                                    setVendorUser((prev: any) => ({
                                                                        ...prev,
                                                                        [key]: event.target.value,
                                                                    }));
                                                                }
                                                            }}
                                                            value={vendorUser?.[key]}
                                                        />
                                                    );
                                                case 'date':
                                                    return (
                                                        <DatePicker
                                                            state={vendorUser?.[key] ? vendorUser?.[key] : null}
                                                            onChange={(value: any) =>
                                                                setVendorUser((prev: any) => ({
                                                                    ...prev,
                                                                    [key]: value,
                                                                }))
                                                            }
                                                            fieldName={value.name}
                                                            portalId="date-portal"
                                                        />
                                                    );
                                                case 'countrySelectBox':
                                                    return (
                                                        <SearchableSelectBox
                                                            data={countryData}
                                                            placeholder="Enter country to select"
                                                            setStateValue={(value: string) => {
                                                                setVendorUser((prev: any) => ({
                                                                    ...prev,
                                                                    [key]: value,
                                                                }));
                                                            }}
                                                            onClear={getCountryData}
                                                            onInputChangeFn={onCountrySearch}
                                                            stateValue={vendorUser?.[key]}
                                                            fieldName="country"
                                                            menuPortalTarget={document.getElementById('outside_div')}
                                                        />
                                                    );
                                                case 'roleSelectBox':
                                                    return (
                                                        <SearchableSelectBox
                                                            data={roleData}
                                                            placeholder="Enter vendor role to select"
                                                            setStateValue={(value: string) => {
                                                                setVendorUser((prev: any) => ({
                                                                    ...prev,
                                                                    [key]: value,
                                                                }));
                                                            }}
                                                            stateValue={vendorUser?.[key]}
                                                            fieldName="vendorRole"
                                                            menuPortalTarget={document.getElementById('outside_div')}
                                                        />
                                                    );
                                                default:
                                                    return null;
                                            }
                                        })()}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => setShowModal(false)}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        disabled={submitVendorUserSaveStatus()}
                        onClick={() => submitVendorUser()}
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Save"
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                </div>
            </div>
            <div style={{ zIndex: 9999 }} id="outside_div"></div>
        </div>
    );
};
export default Index;
