import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { RootState } from 'src/store/reducers';

import '../todo-grid-view.scss';
import { markSingleRead } from 'src/store/reducers/authReducer';
import { useDispatch } from 'react-redux';

const Index: React.FC<any> = ({ path }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { todoItem, isLoading } = useSelector((state: RootState) => state.dashboardTodo);

    return (
        <>
            {!todoItem?.aoListingDtos?.length && !isLoading && (
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No recent activity from the last 30 Days</p>
                </div>
            )}
            <div className="view-list-grid draft-po-view">
                {todoItem?.aoListingDtos?.map((ao: any) => (
                    <div key={ao.aoId} className={`row-wrapper ${!ao?.isViewed ? 'click-active' : ''}`}>
                        <div className="list-grid">
                            <div className="grid-child-item col-name">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">AO No.</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">{ao.aoNumber}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item span-2"></div>
                            <div className="grid-child-item col-destination">
                                {ao.originPort && ao.destinationPort && (
                                    <div className="grid-child-data">
                                        <div className="des-progress-bar">
                                            <div className="destination begin-journey">
                                                <div className="point12">
                                                    <div className="point1" title="Yantain">
                                                        {ao.originPortName}
                                                    </div>
                                                    {ao?.originPortCode && (
                                                        <div className="point-name">({ao?.originPortCode})</div>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="destination end-journey">
                                                <div className="point1">
                                                    {ao.destinationPortName}
                                                    {/* Multi Destination */}
                                                </div>
                                                {ao?.destinationPortCode &&
                                                    ao?.destinationPortName !== 'Multi destination' && (
                                                        <div className="point-name">({ao?.destinationPortCode})</div>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="grid-child-item col-action" style={{ textAlign: 'center' }}>
                                <div className="status-badge">
                                    {ao.shippingmode && (
                                        <div className="item-count-status-holder">
                                            <div className="grid-item-count-status status-ui disabled-status">
                                                <span className="status-text">{ao.shippingmode}</span>
                                            </div>
                                        </div>
                                    )}
                                    <div className="item-count-status-holder">
                                        <div className="grid-item-count-status status-ui disabled-status">
                                            <span className="status-text">{ao.skuCount} SKU</span>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    title="View AO"
                                    className="app-btn app-btn-secondary grid-btn"
                                    onClick={() => {
                                        if (ao?.notificationIds) {
                                            ao?.notificationIds?.forEach((notifications: string) => {
                                                dispatch(
                                                    markSingleRead({
                                                        id: notifications,
                                                        status: 'read',
                                                    })
                                                );
                                            });
                                        }
                                        navigate(path(ao.aoId));
                                    }}
                                >
                                    <span className="button-text footer-button-text">View AO</span>
                                </button>
                            </div>
                        </div>
                        <div className="list-grid">
                            <div className="grid-child-item col-client">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Client</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">{ao.clientName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item col-vendor">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Agent name</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">{ao.blpAgentName}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item col-grd">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Goods Ready Date</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">
                                                {ao?.goodsReadyDate
                                                    ? moment(ao?.goodsReadyDate).format('DD-MM-YYYY')
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="grid-child-item col-ssw">
                                <div className="grid-child-data list-view-items">
                                    <div className="content-list-wrapper">
                                        <div className="content-heading-holder">
                                            <span className="tab-lite-heading">Start Shipping Window</span>
                                        </div>
                                        <div className="content-detail">
                                            <span className="sub-content selected-color">
                                                {ao?.startShipWindowDate
                                                    ? moment(ao?.startShipWindowDate).format('DD-MM-YYYY')
                                                    : '-'}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};
export default Index;
