import React, { useEffect, useState } from 'react';
// import BookingProposalHeader from '../../BookingProposalHeader';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../../BookingProposal/POSelection.scss';
import 'src/pages/BookingProposal/BookingChangeVessel/ManualVesselEntry/manual-vessel-entry.scss';
import { fileTypesInfoText, fileUploadInfoText, reactSelectStyle, reactSelectTheme } from '../../../../utils/constants';
import Select, { MenuPlacement } from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import { fetchSelectedSailing, isLoadingFn2 } from '../../../../store/reducers/bookingProposal/sailingReducer';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { fetchBpHeaderData } from '../../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { getDateDifference, getOrganisationData } from '../../../../utils';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { useAppSelector } from 'src/hooks';
import { selectCurrency, fetchCurrency } from 'src/store/reducers/purchaseOrder/productPoReducer';
import {
    getSelectedSchedules,
    addSchedule,
    getCarrierList,
    clearsailingUpdated,
} from 'src/store/reducers/bookingProposalAir/addSchedule';
import { Position, error, error as errorToast, success } from 'src/utils/toast';
import useUseExistingSchedule from './UseExistingSchedule';
import { searchUnlocoList, clearUnlocoList } from 'src/store/reducers/createVendorReducer';
import { TimePicker as TimePickerPack } from '@mui/x-date-pickers/TimePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import CarrierDropdownList from '../../../Common/carrierDropdown';
import { getQuoteUoms } from 'src/services/apis/quoteAPi';
import { getpaymentterms } from 'src/services/apis/purchase_order/basicDetails';
import { getSelectedOptionDet } from 'src/services/apis/bookingProposalAir/existingFlightSchedules';

/* eslint no-var: off */
/* eslint-disable no-undefined */

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
];

// var dataValues: any = [];
var selectedPorts: any = [];
var timeVal = false;

const Index: React.FC = () => {
    const location: any = useLocation();
    const editData = location.state as any;
    const [Type, setType] = useState('Direct');
    const [originEtd, setoriginEtd] = useState('');
    const [destinationEta, setdestinationEta] = useState('');
    const [destinationTime, setdestinationTime] = useState('');
    const [originTime, setoriginTime] = useState('');
    const [flightno, setflightno] = useState('');
    const [errorValidation, seterrorValidation] = useState(false);
    const [_errorValidationSubmit, seterrorValidationSubmit] = useState(false);
    const [createMethod, setcreateMethod] = useState('new');
    const [submitEnabled, setSubmitEnabled] = useState(true);
    const [typeEnabled, setTypeEnabled] = useState(true);
    const [costValidationMsg, setCostValidtaionMsg] = useState(false);
    // const [NEwData, setNEwData] = useState({});
    const [Carrier, setCarrier] = useState('');
    const [_showCurrency, setShowCurrency] = useState(false);
    const [_showCarrier, setShowCarrier] = useState(false);
    const [costBuy, setCostBuy] = useState('');
    const [costSell, setCostSell] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadedFileDetail, setUploadedFileDetail] = useState({ name: '', size: '' });
    const [uploadedFileSell, setUploadedFileSell] = useState(null);
    const [uploadedFileDetailSell, setUploadedFileDetailSell] = useState({ name: '', size: '' });
    const [disableBtn, setdisableBtn] = useState(false);
    const [quoteNumber, setQuoteNumber] = useState('');
    const [totalCost, setTotalCost] = useState('');
    const [totalCostSell, setTotalCostSell] = useState('');
    // const port = useAppSelector(selectPort);
    const [portValues, setPorts] = useState<any>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [_createStarted, setcreateStarted] = useState(false);
    const [portDetails, setportDetails] = useState([
        {
            portName: '',
            eta: '',
            etd: '',
            etaTime: '',
            etdTime: '',
            flightNo: '',
        },
    ]);
    const [uomData, setUomData] = useState([]);
    const [paymentTermsData, setPaymentTermsData] = useState([]);
    const [buyUomCode, setBuyUomCode] = useState('');
    const [sellUomCode, setSellUomCode] = useState('');
    const [paymentTerms, setPaymentTerms] = useState('');
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { ExistingScheduleList, ExistingForm, existingScheduleSubmit, existingScheduleCount, existSubmitDisabled } =
        useUseExistingSchedule(
            id ?? '',
            editData?.edit && editData?.data && !editData?.data?.isManuallyEntered ? editData?.data : null,
            createMethod,
            editData?.data
        );

    const { bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const { isLoading2 } = useSelector((state: RootState) => state.sailingRed);
    const { sailingUpdate, carrierList } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const { originPort, destinationPort } = useSelector((state: RootState) => state.bpHeaderData);
    const { unLocoList } = useSelector((state: RootState) => state.vendorDetails);
    const { profileType } = useUserProfile();
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const currency = useAppSelector(selectCurrency);
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    const [buttonValidation, setbuttonValidation] = useState(false);
    const [legUpdate, _setlegUpdate] = useState(false);
    const [TypeManual, setTypeManual] = useState(false);
    const [menuPlacement, _setMenuPlacement] = useState<MenuPlacement>('auto');
    let payload: any;
    if (profileType === 'agent') {
        payload = {
            sailingAddRequest: {
                bookingProposalId: id,
                scheduleType: Type,
                baId: null,
                carrier: Carrier,
                contractType: null,
                originAirportName: originPort,
                flightNo: flightno,
                paymentTermCode: paymentTerms,
                originEtdDate: originEtd,
                originEtdTime: originTime + ':00',
                destinationAirportName: destinationPort,
                destEtaDate: destinationEta,
                destEtaTime: destinationTime + ':00',
                bookingQouteNumber: quoteNumber,
                buyCost: parseInt(totalCost),
                buyCurrency: costBuy,
                buyUomCode,
                listAirSailing: [],
                profile: profileType,
            },
            notification: { roles, listOfUserOrganisations },
        };
    } else {
        payload = {
            sailingAddRequest: {
                bookingProposalId: id,
                scheduleType: Type,
                baId: null,
                carrier: Carrier,
                contractType: null,
                originAirportName: originPort,
                flightNo: flightno,
                originEtdDate: originEtd,
                originEtdTime: originTime + ':00',
                destinationAirportName: destinationPort,
                destEtaDate: destinationEta,
                destEtaTime: destinationTime + ':00',
                bookingQouteNumber: quoteNumber,
                buyCost: parseInt(totalCost),
                buyCurrency: costBuy,
                sellCost: parseInt(totalCostSell),
                sellCurrency: costSell,
                buyUomCode,
                sellUomCode,
                paymentTermCode: paymentTerms,
                listAirSailing: [],
                profile: profileType,
            },
            notification: { roles, listOfUserOrganisations },
        };
    }

    useEffect(() => {
        //to remove data of new schedule entry when editing existing and changing to new schedule
        if (editData?.edit) {
            if (!editData?.data?.isManuallyEntered) {
                setTimeout(() => {
                    if (createMethod === 'new') {
                        setportDetails([]);
                        setCarrier('');
                        setoriginEtd('');
                        setoriginTime('');
                        setdestinationTime('');
                        setdestinationEta('');
                        setflightno('');
                        setType('Direct');
                        setQuoteNumber('');
                        setCostBuy('');
                        setCostSell('');
                        setBuyUomCode('');
                        setSellUomCode('');
                        setPaymentTerms('');
                        setUploadedFile(null);
                        setUploadedFileDetail({ name: '', size: '' });
                        setUploadedFileSell(null);
                        setUploadedFileDetailSell({ name: '', size: '' });
                        setTotalCostSell('');
                    } else {
                        editData.data = location?.state?.data;
                    }
                }, 500);
            }
        }
    }, [editData, createMethod]);

    const getQuoteUomsData = () => {
        getQuoteUoms()
            .then((res: any) => {
                setUomData(res);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    const getPaymentTermsData = () => {
        getpaymentterms()
            .then((res: any) => {
                setPaymentTermsData(res);
            })
            .catch((err: any) => {
                console.log(err);
            });
    };

    useEffect(() => {
        dispatch(fetchCurrency({}));
        dispatch(fetchSelectedSailing(id));
        dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        dispatch(getSelectedSchedules(id));
        getQuoteUomsData();
        getPaymentTermsData();
        if (!editData?.edit) {
            getSelectedOptionDet(id ?? '')
                .then((res: any) => {
                    setCarrier(`${res?.ZO_CarrierCode} (${res?.ZO_CarrierName})`);
                    setTotalCostSell(res?.ZO_SellCost);
                    setCostSell(res?.ZO_SellCurrency);
                    setTotalCost(res?.ZO_BuyCost);
                    setCostBuy(res?.ZO_BuyCurrency);
                })
                .catch((err: any) => {
                    console.log(err);
                });
        }
    }, [id]);

    useEffect(() => {
        dispatch(
            getCarrierList({
                type: 'AIR',
                search: editData?.data?.carrier ? editData?.data?.carrier : null,
                editFirstLoad: true,
            })
        );
    }, [editData?.data?.carrier]);

    useEffect(() => {
        setdestinationEta('');
        setdestinationTime('');
    }, [TypeManual]);
    useEffect(() => {
        const copy = costValidationMsg;
        setCostValidtaionMsg(copy);
    }, [totalCost, totalCostSell]);

    useEffect(() => {
        let allFieldValidation;
        if (createMethod === 'new') {
            if (Type === 'Direct') {
                let extraCond = true;
                if (
                    profileType === 'forwarder' &&
                    bpStatus !== 'Rejected_by_Forwarder' &&
                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                    bpStatus !== 'Draft_Booking_Proposal'
                ) {
                    extraCond =
                        totalCostSell?.toString()?.trim() !== '' &&
                        uploadedFileSell !== null &&
                        costSell?.trim() !== '' &&
                        costSell !== null &&
                        buyUomCode?.trim() !== '' &&
                        buyUomCode !== null &&
                        sellUomCode?.trim() !== '' &&
                        sellUomCode !== null &&
                        costBuy?.trim() !== '';
                } else {
                    extraCond = costBuy?.trim() !== '' && buyUomCode?.trim() !== '' && buyUomCode !== null;
                }
                allFieldValidation =
                    extraCond &&
                    Carrier?.trim() != '' &&
                    Carrier != undefined &&
                    flightno?.trim() != '' &&
                    paymentTerms?.trim() !== '' &&
                    paymentTerms !== null &&
                    originEtd?.trim() != '' &&
                    originTime?.trim() != '' &&
                    destinationTime !== '' &&
                    costBuy !== null &&
                    buyUomCode?.trim() !== '' &&
                    buyUomCode !== null &&
                    quoteNumber?.trim() !== '' &&
                    totalCost?.toString()?.trim() !== '' &&
                    (uploadedFile !== null || uploadedFileDetail?.name !== '');

                setSubmitEnabled(!allFieldValidation);
            } else if (Type === 'Transhipment') {
                portDetails.forEach(function (item) {
                    if (
                        item.eta !== '' &&
                        item.etaTime !== '' &&
                        item.etaTime !== 'Invalid date' &&
                        item.etd !== '' &&
                        item.etdTime !== '' &&
                        item.etdTime !== 'Invalid date' &&
                        item.flightNo?.trim() !== '' &&
                        item.portName?.trim() !== ''
                    ) {
                        setTypeEnabled(true);
                    }
                    let extraCond = true;
                    if (
                        profileType === 'forwarder' &&
                        bpStatus !== 'Rejected_by_Forwarder' &&
                        bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                        bpStatus !== 'Draft_Booking_Proposal'
                    ) {
                        extraCond =
                            totalCostSell?.toString()?.trim() !== '' &&
                            uploadedFileSell !== null &&
                            costSell?.trim() !== '' &&
                            costSell !== null &&
                            sellUomCode?.trim() !== '' &&
                            sellUomCode !== null &&
                            buyUomCode?.trim() !== '' &&
                            buyUomCode !== null &&
                            costBuy?.trim() !== '';
                    } else {
                        extraCond = costBuy?.trim() !== '' && buyUomCode?.trim() !== '' && buyUomCode !== null;
                    }
                    allFieldValidation =
                        extraCond &&
                        Carrier?.trim() != '' &&
                        Carrier != undefined &&
                        flightno?.trim() != '' &&
                        paymentTerms?.trim() !== '' &&
                        paymentTerms !== null &&
                        originEtd != '' &&
                        originTime !== '' &&
                        destinationTime !== '' &&
                        costBuy !== null &&
                        buyUomCode?.trim() !== '' &&
                        buyUomCode !== null &&
                        quoteNumber?.trim() !== '' &&
                        totalCost?.toString()?.trim() !== '' &&
                        (uploadedFile !== null || uploadedFileDetail?.name !== '');

                    setSubmitEnabled(!allFieldValidation);
                });
            }
        }
    }, [
        Carrier,
        flightno,
        originEtd,
        quoteNumber,
        totalCost,
        totalCostSell,
        costBuy,
        costSell,
        buyUomCode,
        sellUomCode,
        paymentTerms,
        uploadedFile,
        uploadedFileSell,
        portDetails,
        originTime,
        destinationTime,
        portDetails,
    ]);

    useEffect(() => {
        if (createMethod === 'new') {
            let errorFlag = true;
            if (editData?.edit && uploadedFileDetail?.name && uploadedFileDetail?.name != undefined) {
                errorFlag = false;
            } else if (Type === 'Direct') {
                let extraCond = true;
                if (
                    profileType === 'forwarder' &&
                    bpStatus !== 'Rejected_by_Forwarder' &&
                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                    bpStatus !== 'Draft_Booking_Proposal'
                ) {
                    extraCond =
                        totalCostSell?.toString()?.trim() !== '' &&
                        uploadedFileSell !== null &&
                        uploadedFileDetailSell?.name != undefined &&
                        costSell?.trim() !== '' &&
                        costSell !== null &&
                        sellUomCode?.trim() !== '' &&
                        sellUomCode !== null &&
                        buyUomCode?.trim() !== '' &&
                        buyUomCode !== null &&
                        costBuy?.trim() !== '';
                } else {
                    extraCond = costBuy?.trim() !== '' && buyUomCode?.trim() !== '' && buyUomCode !== null;
                }
                if (
                    extraCond &&
                    originEtd !== '' &&
                    destinationEta !== '' &&
                    flightno?.trim() !== '' &&
                    paymentTerms?.trim() !== '' &&
                    paymentTerms !== null &&
                    totalCost?.toString()?.trim() !== '' &&
                    quoteNumber?.trim() !== '' &&
                    originTime !== '' &&
                    destinationTime !== '' &&
                    Carrier?.trim() !== '' &&
                    Carrier !== undefined &&
                    (uploadedFile !== null || uploadedFileDetail?.name !== '') &&
                    getDateDifference(
                        moment(originEtd, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                            ' ' +
                            moment(originTime, 'HH:mm:ss').format('HH:mm:ss'),
                        moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                            ' ' +
                            moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
                    ).minute >= 0 &&
                    getDateDifference(
                        moment(originEtd, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                            ' ' +
                            moment(originTime, 'HH:mm:ss').format('HH:mm:ss'),
                        moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                            ' ' +
                            moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
                    ).hour >= 0 &&
                    getDateDifference(
                        moment(originEtd, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                        moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD')
                    ).days >= 0
                ) {
                    errorFlag = false;
                } else {
                    errorFlag = true;
                }
            } else {
                let extraCond = true;
                if (
                    profileType === 'forwarder' &&
                    bpStatus !== 'Rejected_by_Forwarder' &&
                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                    bpStatus !== 'Draft_Booking_Proposal'
                ) {
                    extraCond =
                        totalCostSell?.toString()?.trim() !== '' &&
                        uploadedFileSell !== null &&
                        uploadedFileDetailSell?.name != undefined &&
                        costSell?.trim() !== '' &&
                        costSell !== null &&
                        sellUomCode?.trim() !== '' &&
                        sellUomCode !== null &&
                        buyUomCode?.trim() !== '' &&
                        buyUomCode !== null &&
                        costBuy?.trim() !== '';
                } else {
                    extraCond = costBuy?.trim() !== '' && buyUomCode?.trim() !== '' && buyUomCode !== null;
                }
                multisub() &&
                    portDetails.forEach((_element: any) => {
                        if (
                            extraCond &&
                            originEtd !== '' &&
                            destinationEta !== '' &&
                            flightno?.trim() !== '' &&
                            paymentTerms?.trim() !== '' &&
                            paymentTerms !== null &&
                            totalCost?.toString()?.trim() !== '' &&
                            quoteNumber?.trim() !== '' &&
                            originTime !== '' &&
                            destinationTime !== '' &&
                            Carrier?.trim() !== '' &&
                            Carrier !== undefined &&
                            (uploadedFile !== null || uploadedFileDetail?.name !== '') &&
                            // &&
                            //getDateDifference(
                            //    moment(element.eta).format('YYYY-MM-DD') +
                            //        ' ' +
                            //        moment(element.etaTime, 'HH:mm:ss').format('HH:mm:ss'),
                            //    moment(element.etd).format('YYYY-MM-DD') +
                            //        ' ' +
                            //        moment(element.etdTime, 'HH:mm:ss').format('HH:mm:ss')
                            //).minute > 0 &&
                            //getDateDifference(
                            //    moment(element.eta, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                            //    moment(element.etd, 'DD-MM-YYYY').format('YYYY-MM-DD')
                            //).days !== 0
                            getDateDifference(
                                moment(portDetails[portDetails?.length - 1]?.etd, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                    ' ' +
                                    moment(portDetails[portDetails?.length - 1]?.etdTime, 'HH:mm').format('HH:mm:ss'),
                                moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                    ' ' +
                                    moment(destinationTime, 'HH:mm').format('HH:mm:ss')
                            ).minute >= 0 &&
                            getDateDifference(
                                moment(portDetails[portDetails?.length - 1]?.etd, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                    ' ' +
                                    moment(portDetails[portDetails?.length - 1]?.etdTime, 'HH:mm').format('HH:mm:ss'),
                                moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                    ' ' +
                                    moment(destinationTime, 'HH:mm').format('HH:mm:ss')
                            ).hour >= 0 &&
                            getDateDifference(
                                moment(portDetails[portDetails?.length - 1]?.etd, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                                moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD')
                            ).days >= 0
                        ) {
                            errorFlag = false;
                        } else {
                            errorFlag = true;
                        }
                    });
            }

            seterrorValidationSubmit(errorFlag);
        }
    }, [
        originEtd,
        destinationEta,
        flightno,
        paymentTerms,
        totalCost,
        totalCostSell,
        quoteNumber,
        originTime,
        destinationTime,
        Carrier,
        uploadedFile,
        uploadedFileSell,
        portDetails,
        Type,
        uploadedFileDetail,
        uploadedFileDetailSell,
        setportDetails,
    ]);

    useEffect(() => {
        if (editData?.edit) {
            if (editData?.data?.bookingProposalAirSchedule?.length > 0) {
                setType('Transhipment');
            }
            if (editData?.data?.isManuallyEntered === false) {
                setcreateMethod('existing');
            } else {
                setcreateMethod('new');
            }
            // if (profileType === 'agent') {
            setCostBuy(editData?.data?.buyCurrency);
            setCostSell(editData?.data?.sellCurrency);
            setBuyUomCode(editData?.data?.buyUomCode);
            setSellUomCode(editData?.data?.sellUomCode);
            setPaymentTerms(editData?.data?.paymentTermCode);
            setUploadedFileDetail({ name: editData?.data?.buyQuoteName, size: editData?.data?.buyFileSizeInKb });
            setUploadedFileDetailSell({ name: editData?.data?.sellQuoteName, size: editData?.data?.sellFileSizeInKb });
            setTotalCost(editData?.data?.buyCost);
            setTotalCostSell(editData?.data?.sellCost);
            // } else {
            //     setCostBuy(editData?.data?.sellCurrency);
            //     setUploadedFileDetail({ name: editData?.data?.sellQuoteName, size: editData?.data?.sellFileSizeInKb });
            //     setTotalCost(editData?.data?.sellCost);
            // }

            setQuoteNumber(editData?.data?.bookingQuoteNo);
            setCarrier(editData?.data?.carrier);
            setflightno(editData?.data?.flightNo);
            setoriginTime(moment(editData?.data?.originEtdTime, 'HH:mm:ss').format('HH:mm'));
            setdestinationTime(moment(editData?.data?.destEtaTime, 'HH:mm:ss').format('HH:mm'));
            setdestinationEta(moment(editData?.data?.destEtaDate).format('DD-MM-YYYY'));
            setoriginEtd(moment(editData?.data?.originEtdDate).format('DD-MM-YYYY'));
            var newPortArray: any = [];
            editData?.data?.bookingProposalAirSchedule?.forEach((element: any) => {
                portDetails?.forEach((_val: any) => {
                    newPortArray.push({
                        eta: moment(element?.originEtdDate).format('DD-MM-YYYY'),
                        etd: moment(element?.destEtaDate).format('DD-MM-YYYY'),
                        flightNo: element?.flightNo,
                        portName: element?.originAirPortName,
                        etaTime: moment(element?.originEtdTime, 'HH:mm:ss').format('HH:mm'),
                        etdTime: moment(element?.destEtaTime, 'HH:mm:ss').format('HH:mm'),
                    });
                });
            });

            var filterPorts = [...portDetails];
            filterPorts = filterPorts?.filter((filt: any) => filt?.eta !== '');
            setportDetails([...filterPorts, ...newPortArray]);
        }
    }, [editData, legUpdate]);

    const costValidation = (val: string, type: string) => {
        const regex = /^(?!$)\d{0,10}(?:\.\d{1,2})?$/;
        var found = val.match(regex);
        if (parseFloat(val) === 0) {
            return false;
        } else if (val.length < 14 && !val?.toLowerCase()?.includes('-')) {
            if (type === 'buy') {
                setTotalCost(val);
            } else {
                setTotalCostSell(val);
            }
        } else {
            return false;
        }
    };

    const customStyle = {
        menuPortal: (provided: any) => ({
            ...provided,
            width: '200px',
        }),
        menuList: (base: any) => ({
            ...base,
            '::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: '#F6F6FE',
            },
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(#000, 0.3)',
                backgroundColor: '#C4C6D0',
            },
        }),
    };

    const portalStyle = {
        ...customStyle,
        noOptionsMessage: (base: any) => ({ ...base, height: '180px' }),
    };

    const portalStyleLg = {
        ...portalStyle,
        menuPortal: (provided: any) => ({
            ...provided,
            width: '280px',
        }),
    };

    const submitValues = () => {
        setcreateStarted(true);
        setportDetails([...portDetails]);
        var error = false;
        var payloadNew: any = [];
        var payloadCurrent = (payload as any).sailingAddRequest;
        if (editData?.edit) {
            payloadCurrent = { ...payloadCurrent, baId: editData?.data?.baId };
        }
        if (Type === 'Direct') {
            let extraCond = true;
            if (
                profileType === 'forwarder' &&
                bpStatus !== 'Rejected_by_Forwarder' &&
                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                bpStatus !== 'Draft_Booking_Proposal'
            ) {
                extraCond = totalCostSell === '' || totalCost === '';
            } else {
                extraCond = totalCost === '';
            }

            if (
                (extraCond ||
                    originEtd === '' ||
                    destinationEta === '' ||
                    flightno === '' ||
                    paymentTerms === '' ||
                    quoteNumber === '' ||
                    originTime === '' ||
                    destinationTime === '' ||
                    Carrier === '') &&
                !editData?.edit
            ) {
                seterrorValidation(true);
            } else {
                seterrorValidation(false);
                const formData = new FormData();

                if (uploadedFile) {
                    formData.append('buyfile', uploadedFile as any, (uploadedFile as any)?.name);
                }
                if (uploadedFileSell) {
                    formData.append('sellfile', uploadedFileSell as any, (uploadedFileSell as any)?.name);
                } else {
                    if (
                        (profileType === 'forwarder' &&
                            (bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                bpStatus === 'Draft_Booking_Proposal') &&
                            editData?.data?.sellQuoteName &&
                            uploadedFileDetailSell?.name === '') ||
                        (profileType === 'agent' &&
                            (bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                bpStatus === 'Draft_Booking_Proposal'))
                    ) {
                        payloadCurrent = { ...payloadCurrent, isSellFileremoved: true };
                    }
                }
                formData.append('request', new Blob([JSON.stringify(payloadCurrent)], { type: 'application/json' }));

                //setTimeout(() => {
                dispatch(addSchedule(formData));
                //}, 500);
                // setdisableBtn(true);

                // setTimeout(() => {
                //     if (payloadCurrent?.baId !== null) {
                //         success(`Schedule has been updated successfully`, Position.TOP_RIGHT);
                //     } else {
                //         success(`New Schedule has been added successfully`, Position.TOP_RIGHT);
                //     }
                //     navigate(`/air_booking_list/${id}/FlightSelectionDetails`, { state: { added: true } });
                //     setdisableBtn(false);
                //     setportDetails([
                //         {
                //             portName: '',
                //             eta: '',
                //             etd: '',
                //             etaTime: '',
                //             etdTime: '',
                //             flightNo: '',
                //         },
                //     ]);
                // }, 500);
            }
        } else {
            setportDetails([...portDetails]);
            portDetails.forEach((element) => {
                let extraCond = true;
                if (
                    profileType === 'forwarder' &&
                    bpStatus !== 'Rejected_by_Forwarder' &&
                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                    bpStatus !== 'Draft_Booking_Proposal'
                ) {
                    extraCond = totalCostSell === '' || totalCost === '';
                } else {
                    extraCond = totalCost === '';
                }
                if (
                    extraCond ||
                    element.flightNo === '' ||
                    element.portName === '' ||
                    element.eta === '' ||
                    element.etd === '' ||
                    element.etaTime === '' ||
                    element.etdTime === '' ||
                    element.etaTime === 'Invalid date' ||
                    element.etdTime === 'Invalid date' ||
                    originEtd === '' ||
                    destinationEta === '' ||
                    flightno === '' ||
                    paymentTerms === '' ||
                    quoteNumber === '' ||
                    originTime === '' ||
                    destinationTime === '' ||
                    Carrier === ''
                ) {
                    error = true;
                    seterrorValidation(true);
                } else {
                    error = false;
                    payloadNew.push({
                        originAirPortName: element.portName,
                        flightNo: element.flightNo,
                        originEtdDate: element.eta,
                        originEtdTime: element.etaTime + ':00',
                        destEtaDate: element.etd,
                        destEtaTime: element.etdTime + ':00',
                    });
                }
            });

            const newData = {
                sailingAddRequest: {
                    ...payloadCurrent,
                    listAirSailing: payloadNew,
                },
                notification: { roles, listOfUserOrganisations },
            };
            if (!error) {
                const formData = new FormData();

                if (uploadedFile) {
                    formData.append('buyfile', uploadedFile as any, (uploadedFile as any)?.name);
                }
                if (uploadedFileSell) {
                    formData.append('sellfile', uploadedFileSell as any, (uploadedFileSell as any)?.name);
                } else {
                    if (
                        (profileType === 'forwarder' &&
                            (bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                bpStatus === 'Draft_Booking_Proposal') &&
                            editData?.data?.sellQuoteName &&
                            uploadedFileDetailSell?.name === '') ||
                        (profileType === 'agent' &&
                            (bpStatus === 'Rejected_by_Forwarder' ||
                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                bpStatus === 'Draft_Booking_Proposal'))
                    ) {
                        payloadCurrent = { ...payloadCurrent, isSellFileremoved: true };
                    }
                }
                formData.append(
                    'request',
                    new Blob([JSON.stringify((newData as any).sailingAddRequest)], { type: 'application/json' })
                );
                seterrorValidation(false);

                //setTimeout(() => {
                dispatch(addSchedule(formData));
                //}, 500);
                setdisableBtn(true);
                setTimeout(() => {
                    dispatch(getSelectedSchedules(id));

                    setdisableBtn(false);
                    // setportDetails([
                    //     {
                    //         portName: '',
                    //         eta: '',
                    //         etd: '',
                    //         etaTime: '',
                    //         etdTime: '',
                    //         flightNo: '',
                    //     },
                    // ]);
                    if (sailingUpdate?.status === true) {
                        if (payloadCurrent?.baId !== null) {
                            success(`Schedule has been updated successfully`, Position.TOP_RIGHT);
                            setcreateStarted(false);
                        } else {
                            success(`New Schedule has been added successfully`, Position.TOP_RIGHT);
                            setcreateStarted(false);
                        }
                        navigate(`/air_booking_list/${id}/FlightSelectionDetails`, { state: { added: true } });
                    } else if (sailingUpdate?.status === false) {
                        const sailingResponse = JSON.parse(JSON.stringify(sailingUpdate));
                        errorToast(
                            `${
                                sailingResponse?.data?.data?.data === 'Air schedule already exists'
                                    ? sailingResponse?.data?.data?.data
                                    : 'Something went wrong'
                            }`,
                            Position.TOP_RIGHT
                        );
                        dispatch(clearsailingUpdated(null));
                        setcreateStarted(false);
                    }
                }, 800);
            }
        }
    };
    useEffect(() => {
        const sailingResponse = JSON.parse(JSON.stringify(sailingUpdate));
        var payloadCurrent = (payload as any).sailingAddRequest;
        if (editData?.edit) {
            payloadCurrent = { ...payloadCurrent, baId: editData?.data?.baId };
        }
        if (sailingUpdate?.status === true) {
            setdisableBtn(true);
            setTimeout(() => {
                if (payloadCurrent?.baId !== null) {
                    success(`Schedule has been updated successfully`, Position.TOP_RIGHT);
                    setcreateStarted(false);
                } else {
                    success(`New Schedule has been added successfully`, Position.TOP_RIGHT);
                    setcreateStarted(false);
                }
                navigate(`/air_booking_list/${id}/FlightSelectionDetails`, { state: { added: true } });
                setdisableBtn(false);
                setportDetails([
                    {
                        portName: '',
                        eta: '',
                        etd: '',
                        etaTime: '',
                        etdTime: '',
                        flightNo: '',
                    },
                ]);
            }, 500);
        } else if (sailingUpdate?.status === false) {
            errorToast(
                `${
                    sailingResponse?.data?.data?.data === 'Air schedule already exists'
                        ? sailingResponse?.data?.data?.data
                        : 'Something went wrong'
                }`,
                Position.TOP_RIGHT
            );
            dispatch(clearsailingUpdated(null));
            setcreateStarted(false);
        }
        dispatch(clearsailingUpdated(null));
    }, [sailingUpdate?.status]);
    useEffect(() => {
        if (createMethod === 'new') {
            if (Type === 'Direct') {
                if (
                    (originEtd !== moment(editData?.data?.originEtdDate).format('DD-MM-YYYY') && originEtd !== '') ||
                    (destinationEta !== moment(editData?.data?.destEtaDate).format('DD-MM-YYYY') &&
                        destinationEta !== '') ||
                    (flightno !== editData?.data?.flightNo && flightno?.trim() !== '') ||
                    (paymentTerms !== editData?.data?.paymentTermCode && paymentTerms?.trim() !== '') ||
                    (profileType === 'agent' &&
                        totalCost !== editData?.data?.buyCost &&
                        totalCost?.toString()?.trim() !== '') ||
                    (profileType === 'forwarder' &&
                        bpStatus !== 'Rejected_by_Forwarder' &&
                        bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                        bpStatus !== 'Draft_Booking_Proposal' &&
                        totalCostSell !== editData?.data?.sellCost &&
                        totalCostSell?.toString()?.trim() !== '') ||
                    (quoteNumber !== editData?.data?.bookingQuoteNo && quoteNumber?.trim() !== '') ||
                    (originTime !== moment(editData?.data?.originEtdTime, 'HH:mm:ss').format('HH:mm') &&
                        originTime !== '') ||
                    (destinationTime !== moment(editData?.data?.destEtaTime, 'HH:mm:ss').format('HH:mm') &&
                        destinationTime !== '') ||
                    (Carrier !== editData?.data?.carrier && Carrier?.trim() !== '' && Carrier !== undefined) ||
                    (profileType === 'agent' &&
                        uploadedFileDetail?.name !== editData?.data?.buyQuoteName &&
                        uploadedFileDetail?.name) ||
                    (profileType === 'forwarder' &&
                        bpStatus !== 'Rejected_by_Forwarder' &&
                        bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                        bpStatus !== 'Draft_Booking_Proposal' &&
                        uploadedFileDetail?.name !== editData?.data?.sellQuoteName &&
                        uploadedFileDetail?.name) ||
                    (getDateDifference(
                        moment(originEtd).format('YYYY-MM-DD') +
                            ' ' +
                            moment(originTime, 'HH:mm:ss').format('HH:mm:ss'),
                        moment(destinationEta).format('YYYY-MM-DD') +
                            ' ' +
                            moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
                    ).minute >= 0 &&
                        getDateDifference(
                            moment(originEtd).format('YYYY-MM-DD') +
                                ' ' +
                                moment(originTime, 'HH:mm:ss').format('HH:mm:ss'),
                            moment(destinationEta).format('YYYY-MM-DD') +
                                ' ' +
                                moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
                        ).hour >= 0 &&
                        getDateDifference(
                            moment(originEtd, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                            moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD')
                        ).days === 0 &&
                        destinationTime)
                ) {
                    let extraCond = true;
                    if (
                        profileType === 'forwarder' &&
                        bpStatus !== 'Rejected_by_Forwarder' &&
                        bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                        bpStatus !== 'Draft_Booking_Proposal'
                    ) {
                        extraCond =
                            totalCostSell === '' ||
                            totalCost === '' ||
                            costSell === '' ||
                            buyUomCode === '' ||
                            sellUomCode === '' ||
                            paymentTerms === '' ||
                            uploadedFileDetailSell?.name === '';
                    } else {
                        extraCond = totalCost === '';
                    }
                    if (
                        extraCond ||
                        quoteNumber === '' ||
                        destinationEta === '' ||
                        flightno === '' ||
                        paymentTerms === '' ||
                        destinationTime === '' ||
                        originTime === '' ||
                        Carrier === '' ||
                        uploadedFileDetail?.name === '' ||
                        costBuy === '' ||
                        buyUomCode === ''
                    ) {
                        setbuttonValidation(false);
                    } else {
                        setbuttonValidation(true);
                    }
                } else {
                    setbuttonValidation(false);
                }
            } else {
                const newFlag = multiValidation();
                setbuttonValidation(newFlag);
            }
        }
    }, [
        originEtd,
        destinationEta,
        flightno,
        paymentTerms,
        totalCost,
        totalCostSell,
        quoteNumber,
        originTime,
        destinationTime,
        Carrier,
        editData,
        portDetails,
        uploadedFileDetail,
        uploadedFileDetailSell,
        Type,
    ]);

    const multiValidation = (): boolean => {
        let flag = 0;
        multisub() &&
            portDetails?.forEach((val: any) => {
                if (editData?.data?.bookingProposalAirSchedule?.length > 0) {
                    editData?.data?.bookingProposalAirSchedule?.forEach((res: any, i: any) => {
                        let extraCond = true;
                        if (
                            profileType === 'forwarder' &&
                            bpStatus !== 'Rejected_by_Forwarder' &&
                            bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                            bpStatus !== 'Draft_Booking_Proposal'
                        ) {
                            extraCond =
                                totalCostSell?.toString()?.trim() !== '' &&
                                totalCost?.toString()?.trim() !== '' &&
                                uploadedFileDetailSell?.name === '';
                        } else {
                            extraCond = totalCost?.toString()?.trim() !== '';
                        }
                        if (
                            extraCond &&
                            portDetails[i]?.flightNo?.trim() !== '' &&
                            portDetails[i]?.eta !== '' &&
                            portDetails[i]?.etd !== '' &&
                            portDetails[i]?.etaTime !== '' &&
                            portDetails[i]?.etdTime !== '' &&
                            portDetails[i]?.etaTime !== 'Invalid date' &&
                            portDetails[i]?.etdTime !== 'Invalid date' &&
                            portDetails[i]?.portName?.trim() !== '' &&
                            flightno?.trim() !== '' &&
                            paymentTerms?.trim() !== '' &&
                            quoteNumber?.trim() !== '' &&
                            Carrier?.trim() !== '' &&
                            Carrier !== undefined &&
                            uploadedFileDetail?.name
                        ) {
                            if (
                                portDetails[i]?.eta !== moment(res?.originEtdDate).format('DD-MM-YYYY') ||
                                portDetails[i]?.etd !== moment(res?.destEtaDate).format('DD-MM-YYYY') ||
                                portDetails[i]?.etaTime !== moment(res?.originEtdTime, 'HH:mm:ss').format('HH:mm') ||
                                portDetails[i]?.etdTime !== moment(res?.destEtaTime, 'HH:mm:ss').format('HH:mm') ||
                                portDetails[i]?.flightNo !== res?.flightNo ||
                                portDetails[i]?.portName !== res?.originAirPortName ||
                                originEtd !== moment(editData?.data?.originEtdDate).format('DD-MM-YYYY') ||
                                destinationEta !== moment(editData?.data?.destEtaDate).format('DD-MM-YYYY') ||
                                flightno !== editData?.data?.flightNo ||
                                paymentTerms !== editData?.data?.paymentTermCode ||
                                (profileType === 'agent' && totalCost !== editData?.data?.buyCost) ||
                                (profileType === 'forwarder' &&
                                    bpStatus !== 'Rejected_by_Forwarder' &&
                                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                    bpStatus !== 'Draft_Booking_Proposal' &&
                                    totalCostSell !== editData?.data?.sellCost) ||
                                quoteNumber !== editData?.data?.bookingQuoteNo ||
                                originTime !== moment(editData?.data?.originEtdTime, 'HH:mm:ss').format('HH:mm') ||
                                destinationTime !== moment(editData?.data?.destEtaTime, 'HH:mm:ss').format('HH:mm') ||
                                Carrier !== editData?.data?.carrier ||
                                (profileType === 'agent' &&
                                    uploadedFileDetail?.name !== editData?.data?.buyQuoteName) ||
                                (profileType === 'forwarder' &&
                                    bpStatus !== 'Rejected_by_Forwarder' &&
                                    bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                    bpStatus !== 'Draft_Booking_Proposal' &&
                                    uploadedFileDetailSell?.name !== editData?.data?.sellQuoteName)
                            ) {
                                flag = 1;
                            }
                        }
                    });
                } else {
                    if (
                        val?.eta !== '' &&
                        val?.etd !== '' &&
                        val.flightNo?.trim() !== '' &&
                        val.portName?.trim() !== '' &&
                        val.etaTime !== '' &&
                        val.etdTime !== '' &&
                        val.etaTime !== 'Invalid date' &&
                        val.etdTime !== 'Invalid date' &&
                        (originEtd !== moment(editData?.data?.originEtdDate).format('DD-MM-YYYY') ||
                            (destinationEta !== moment(editData?.data?.destEtaDate).format('DD-MM-YYYY') &&
                                destinationEta !== '') ||
                            (flightno !== editData?.data?.flightNo && flightno?.trim() !== '') ||
                            (paymentTerms !== editData?.data?.paymentTermCode && paymentTerms?.trim() !== '') ||
                            (profileType === 'agent' &&
                                totalCost !== editData?.data?.buyCost &&
                                totalCost?.toString()?.trim() !== '') ||
                            (profileType === 'forwarder' &&
                                bpStatus !== 'Rejected_by_Forwarder' &&
                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                bpStatus !== 'Draft_Booking_Proposal' &&
                                totalCostSell !== editData?.data?.sellCost &&
                                totalCostSell?.toString()?.trim() !== '') ||
                            (quoteNumber !== editData?.data?.bookingQuoteNo && quoteNumber !== '') ||
                            (originTime !== moment(editData?.data?.originEtdTime, 'HH:mm:ss').format('HH:mm') &&
                                originTime !== '') ||
                            (destinationTime !== moment(editData?.data?.destEtaTime, 'HH:mm:ss').format('HH:mm') &&
                                destinationTime !== '') ||
                            (Carrier !== editData?.data?.carrier && Carrier?.trim() !== '' && Carrier !== undefined) ||
                            (profileType === 'agent' &&
                                uploadedFileDetail?.name !== editData?.data?.buyQuoteName &&
                                uploadedFileDetail?.name) ||
                            (profileType === 'forwarder' &&
                                bpStatus !== 'Rejected_by_Forwarder' &&
                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                bpStatus !== 'Draft_Booking_Proposal' &&
                                uploadedFileDetailSell?.name !== editData?.data?.sellQuoteName &&
                                uploadedFileDetailSell?.name))
                    ) {
                        flag = 1;
                    }
                }
            });
        return flag === 1 ? true : false;
    };
    const multisub = (): boolean => {
        let flag = true;
        portDetails?.forEach((val: any) => {
            let extraCond = true;
            if (
                profileType === 'forwarder' &&
                bpStatus !== 'Rejected_by_Forwarder' &&
                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                bpStatus !== 'Draft_Booking_Proposal'
            ) {
                extraCond = totalCostSell === '' || totalCost === '' || uploadedFileDetailSell?.name === '';
            } else {
                extraCond = totalCost === '';
            }
            if (
                val.flightNo === '' ||
                val?.eta === '' ||
                val?.etd === '' ||
                val.etaTime === '' ||
                val.etdTime === '' ||
                val.etaTime === 'Invalid date' ||
                val.etdTime === 'Invalid date' ||
                val.portName === '' ||
                flightno === '' ||
                paymentTerms === '' ||
                quoteNumber === '' ||
                Carrier === '' ||
                uploadedFileDetail?.name === ''
            ) {
                flag = false;
            }
        });
        return flag;
    };

    useEffect(() => {
        //to add all selected and current airports to list
        if (createMethod === 'new') {
            if (originPort && destinationPort) {
                selectedPorts = [
                    (originPort as any)?.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, ''),
                    (destinationPort as any)?.replace(/\s*(?:\[[^\]]*\]|\([^)]*\))\s*/g, ''),
                ];
            }
            portDetails &&
                portDetails?.forEach((element: any) => {
                    selectedPorts.push(element.portName);
                });
        }
    }, [portDetails, originPort]);

    const convertDataForSelect = (data: any) => {
        if (Array.isArray(carrierList)) {
            const convertedData = data?.map((option: any) => {
                return { value: option.Or_orgname, label: option.Or_UserDefinedText4 };
            });
            return convertedData;
        } else {
            return [];
        }
    };

    function checkDateEtdToEta() {
        return (
            getDateDifference(
                moment(Type === 'Direct' ? originEtd : portDetails[portDetails?.length - 1]?.etd, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                ) +
                    ' ' +
                    moment(
                        Type === 'Direct' ? originTime : portDetails[portDetails?.length - 1]?.etdTime,
                        'HH:mm'
                    ).format('HH:mm:ss'),
                moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                    ' ' +
                    moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
            ).minute < 0 &&
            getDateDifference(
                moment(Type === 'Direct' ? originEtd : portDetails[portDetails?.length - 1]?.etd, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                ) +
                    ' ' +
                    moment(
                        Type === 'Direct' ? originTime : portDetails[portDetails?.length - 1]?.etdTime,
                        'HH:mm'
                    ).format('HH:mm:ss'),
                moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                    ' ' +
                    moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
            ).hour < 0 &&
            getDateDifference(
                moment(Type === 'Direct' ? originEtd : portDetails[portDetails?.length - 1]?.etd, 'DD-MM-YYYY').format(
                    'YYYY-MM-DD'
                ),
                moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD')
            ).days <= 0
        );
    }
    const checkDate = () => {
        const currentDate = moment(new Date()).format('DD-MM-YYYY');
        const currentTime = moment(new Date()).format('HH:mm');
        if (currentDate === originEtd) {
            if (moment(originTime, 'HH:mm').isSameOrBefore(moment(currentTime, 'HH:mm'))) {
                return true;
            }
            return false;
        }
        return false;
    };

    useEffect(() => {
        if (searchTerm?.trim().length > 2) {
            dispatch(searchUnlocoList({ searchTerm, portType: 'air', service: 'bkg' }));
        }
        //setPorts([]);
    }, [searchTerm]);

    //useEffect(() => {
    //    if (editData?.edit) {
    //        dispatch(
    //            searchUnlocoList({ searchTerm: portDetails[0].portName.slice(0, 3), portType: 'air', service: 'bkg' })
    //        );
    //    }
    //}, [editData]);
    useEffect(() => {
        if (unLocoList?.length > 0) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    portCountry: option?.portCountry ?? '',
                };
            });
            setPorts(portList);
        }
    }, [unLocoList]);

    useEffect(() => {
        dispatch(clearUnlocoList());
        dispatch(clearsailingUpdated(null));
    }, []);
    // const ClearIndicator = (props: any) => {
    //     return (
    //         <components.ClearIndicator {...props}>
    //             <span>
    //                 <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
    //                     <use xlinkHref="#closeIcon">
    //                         <title>Close</title>
    //                     </use>
    //                 </svg>
    //             </span>
    //         </components.ClearIndicator>
    //     );
    // };
    const checkIsFlightDetailsMissing = portDetails?.some(
        (item: any) =>
            !item['flightNo'] ||
            !item['etdTime'] ||
            !item['etaTime'] ||
            item['etaTime'] === 'Invalid date' ||
            item['etdTime'] === 'Invalid date'
    );

    const handleClearClick = (event: any, res: any) => {
        event.stopPropagation(); // prevent the event from propagating
        res.portName = '';
        res.portCode = '';
        setportDetails([...portDetails]);
    };

    return (
        <div
            className="main-wrapper container booking-change-vessel"
            onClick={() => {
                setShowCurrency(false);
                setShowCarrier(false);
            }}
        >
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={`/air_booking_list/${id}/POSelection`}
                    thirdName={'Flight Schedules'}
                    thirdUrl={`/air_booking_list/${id}/FlightSelectionDetails`}
                    fourthName={`${editData?.edit ? 'Edit' : 'Add'} Schedule`}
                    fourthUrl={'#'}
                    summaryStats={summaryStats}
                />
                <div className="pageheading-holder">
                    <div className="back-btn-holder">
                        {/*<Link to={`/Aobooking_list/${id}/SailingSelectionDetails`}>*/}
                        <button
                            className="back-btn app-btn"
                            onClick={() => {
                                navigate(-1);
                                setportDetails([
                                    {
                                        portName: '',
                                        eta: '',
                                        etd: '',
                                        etaTime: '',
                                        etdTime: '',
                                        flightNo: '',
                                    },
                                ]);
                            }}
                        >
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                        {/*</Link>*/}
                        <h2 className="page-heading">{editData?.edit ? 'Edit Schedule' : 'Add Schedule'}</h2>
                        <div className="transport-icon-wrapper">
                            <div className="item-count-status-holder">
                                <div className="grid-item-count-status status-ui info-status">
                                    <span className="count">
                                        <svg className="svg-icon aeroplane-icon">
                                            <use xlinkHref="#aeroplaneIcon">
                                                <title>Aeroplace</title>
                                            </use>
                                        </svg>
                                    </span>
                                    <span className="status-text">Air</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="booking-number-heading">{location?.state?.bpNumber}</div>
                {isLoading2 && (
                    <div className="app-loader loading" style={{ position: 'fixed' }}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper change-vessel-detail air-booking-manual">
                <div className="main-wrapper">
                    <div className="manual-entry-wrapper">
                        <div className="card details-box custom">
                            <div className="selection-heading-holder">
                                <div>
                                    <h2 className="selection-heading">Flight Schedules</h2>
                                    {createMethod === 'new' && (
                                        <p className="selection-content">
                                            Complete the mandatory fields to create a new schedule
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="filter-type-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="radio"
                                            className="checkbox-input"
                                            name="manual-group"
                                            checked={createMethod === 'new'}
                                            onChange={() => setcreateMethod('new')}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon notification-circle-icon">
                                                <use href="#circleIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Create New Schedule</div>
                                    </label>
                                </div>
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="radio"
                                            className="checkbox-input"
                                            name="manual-group"
                                            checked={createMethod === 'existing'}
                                            onChange={() => setcreateMethod('existing')}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon notification-circle-icon">
                                                <use href="#circleIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">
                                            Use Existing Schedule<span className="count">{existingScheduleCount}</span>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            {/* CREATE SCHELUDE FOR AN AGENT */}
                            {createMethod === 'new' && (
                                <div className="create-schelude-agent">
                                    <div className="filter-types">
                                        <div className="layout-holder two-column">
                                            <div className="layout-item">
                                                {/* <div className=" modal-input-holder layout-item"> */}
                                                {/* <label htmlFor="name" className="modal-input-label">
                                                        Carrier<span className="mandatory-field-text">*</span>
                                                    </label> */}
                                                {/* <div id="cd_author_div" className="modal-input-layout-item">
                                                        <input
                                                            placeholder="Enter Carrier"
                                                            type="text"
                                                            className="modal-input input-text"
                                                            id="sd_version"
                                                            name="sd_version"
                                                            onChange={(e) => setCarrier(e.target.value)}
                                                            value={Carrier ? Carrier : ''}
                                                        ></input>
                                                    </div> */}
                                                <CarrierDropdownList
                                                    carrier={Carrier}
                                                    type={'AIR'}
                                                    setCarrier={setCarrier}
                                                    isErrorMessageShow={
                                                        errorValidation && (Carrier === '' || Carrier === undefined)
                                                    }
                                                />
                                                {/* <SearchableSelectBox
                                                        data={convertDataForSelect(carrierList)}
                                                        placeholder="Enter Carrier to select"
                                                        setStateValue={setCarrier}
                                                        stateValue={Carrier}
                                                        fieldName="carrier"
                                                        menuPortalTarget={document.getElementById('outside_div')}
                                                    />
                                                    {errorValidation && Carrier === '' && (
                                                        <p
                                                            className="error_message_product"
                                                            style={{ paddingTop: '8px' }}
                                                        >
                                                            Carrier is required
                                                        </p>
                                                    )} */}
                                                {/* </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-head-holder add-btn-holder">
                                        <div className="filter-types">
                                            <div className="filter-type-holder">
                                                <div className="checkbox-item">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            type="radio"
                                                            className="checkbox-input"
                                                            onChange={() => {
                                                                setType('Direct');
                                                                setTypeManual(!TypeManual);
                                                                setUploadedFile(null);
                                                                setUploadedFileDetail({
                                                                    name: '',
                                                                    size: '',
                                                                });
                                                                setUploadedFileSell(null);
                                                                setUploadedFileDetailSell({
                                                                    name: '',
                                                                    size: '',
                                                                });
                                                            }}
                                                            checked={Type === 'Direct'}
                                                            name="selectgroup"
                                                        ></input>
                                                        <div className="checkmark">
                                                            <svg className="svg-icon notification-circle-icon">
                                                                <use href="#circleIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="checkbox-label">Direct</div>
                                                    </label>
                                                </div>
                                                <div className="checkbox-item">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            type="radio"
                                                            className="checkbox-input"
                                                            onChange={() => {
                                                                setType('Transhipment');
                                                                setUploadedFile(null);
                                                                setUploadedFileDetail({
                                                                    name: '',
                                                                    size: '',
                                                                });
                                                                setUploadedFileSell(null);
                                                                setUploadedFileDetailSell({
                                                                    name: '',
                                                                    size: '',
                                                                });

                                                                setTypeManual(!TypeManual);
                                                                setportDetails([
                                                                    {
                                                                        portName: '',
                                                                        eta: '',
                                                                        etd: '',
                                                                        etaTime: '',
                                                                        etdTime: '',
                                                                        flightNo: '',
                                                                    },
                                                                ]);
                                                                setdestinationEta('');
                                                                setdestinationTime('');
                                                            }}
                                                            checked={Type === 'Transhipment'}
                                                            name="selectgroup"
                                                        ></input>
                                                        <div className="checkmark">
                                                            <svg className="svg-icon notification-circle-icon">
                                                                <use href="#circleIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                        <div className="checkbox-label">Multi-Legged</div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="layout-header">Origin{Type !== 'Direct' ? '-Leg 1' : ''}</div>
                                        <div className="layout-holder two-column">
                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item">
                                                    <label className="modal-input-label">
                                                        Flight No<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <input
                                                            placeholder="Enter Flight No."
                                                            type="text"
                                                            className="modal-input input-text"
                                                            id="sd_version"
                                                            name="sd_version"
                                                            onChange={(e) => setflightno(e.target.value)}
                                                            value={flightno ? flightno : ''}
                                                        ></input>
                                                    </div>
                                                    {errorValidation && flightno === '' && (
                                                        <p
                                                            className="error_message_product"
                                                            style={{ paddingTop: '8px' }}
                                                        >
                                                            Flight No. is required
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item">
                                                    <label className="modal-input-label">
                                                        Payment Terms
                                                        <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <div className="total-term-wrapper dropdown-full">
                                                            <SearchableSelectBox
                                                                data={paymentTermsData}
                                                                placeholder="Select Payment Terms"
                                                                setStateValue={setPaymentTerms}
                                                                stateValue={paymentTerms}
                                                                fieldName="paymentTermCode"
                                                                customStyle={portalStyleLg}
                                                                menuPosition="fixed"
                                                                menuPortalTarget={document.getElementById(
                                                                    'outside_div'
                                                                )}
                                                                //index={productData.indexValue}
                                                                closeMenuOnScroll={true}
                                                                menuPlacement={menuPlacement}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item">
                                                    <label className="modal-input-label">Airport</label>
                                                    <div className="modal-input-layout-item">
                                                        <input
                                                            placeholder={`${
                                                                editData?.edit
                                                                    ? editData?.data?.originAirPortName
                                                                    : originPort
                                                            }`}
                                                            type="text"
                                                            className="modal-input input-text read-only"
                                                            id="sd_version"
                                                            name="sd_version"
                                                            readOnly
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item">
                                                    <label className="modal-input-label">
                                                        ETD<span className="mandatory-field-text">*</span>
                                                    </label>

                                                    <div className="modal-input-layout-item calendar date-time">
                                                        <DatePicker
                                                            //disabled={disabled}
                                                            minDate={new Date()}
                                                            onChange={(value: any) => {
                                                                setoriginEtd(moment(value).format('DD-MM-YYYY'));
                                                            }}
                                                            value={originEtd ?? ''}
                                                            placeholderText="Select Date"
                                                            customInput={
                                                                <div className="modal-input-field-holder date-input-holder">
                                                                    <input
                                                                        className="modal-input input-text with-border date-input input-date-cursor"
                                                                        type="text"
                                                                        placeholder={'Select Date'}
                                                                        value={originEtd ?? ''}
                                                                    />
                                                                    <svg className="svg-icon calendar-icon">
                                                                        <use xlinkHref="#calendarIcon">
                                                                            <title>Calendar</title>
                                                                        </use>
                                                                    </svg>
                                                                </div>
                                                            }
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        <div className="input-time-wrapper">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['TimePicker']}>
                                                                    <TimePickerPack
                                                                        onChange={(value: any) => {
                                                                            setoriginTime(
                                                                                moment(value.$d).format('HH:mm')
                                                                            );
                                                                        }}
                                                                        views={['hours', 'minutes']}
                                                                        format="HH:mm"
                                                                        ampm={false}
                                                                        timeSteps={{ minutes: 1 }}
                                                                        value={
                                                                            originTime
                                                                                ? dayjs('0000-00-00T' + originTime)
                                                                                : ''
                                                                        }
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                    {errorValidation && originEtd === '' && (
                                                        <p
                                                            className="error_message_product"
                                                            style={{ paddingTop: '8px' }}
                                                        >
                                                            ETD is required
                                                        </p>
                                                    )}
                                                    {errorValidation && originEtd !== '' && originTime === '' && (
                                                        <p
                                                            className="error_message_product"
                                                            style={{ paddingTop: '8px' }}
                                                        >
                                                            ETD Time is required
                                                        </p>
                                                    )}
                                                    {checkDate() && (
                                                        <p
                                                            className="error_message_product"
                                                            style={{ paddingTop: '8px' }}
                                                        >
                                                            ETD Time must be greater than current time
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {Type !== 'Direct' && (
                                            <>
                                                {portDetails?.map((res: any, index: any) => {
                                                    if (
                                                        (getDateDifference(
                                                            moment(
                                                                index === 0 ? originEtd : portDetails[index - 1]?.etd,
                                                                'DD-MM-YYYY'
                                                            ).format('YYYY-MM-DD') +
                                                                ' ' +
                                                                moment(
                                                                    index === 0
                                                                        ? originTime
                                                                        : portDetails[index - 1]?.etdTime,
                                                                    'HH:mm:ss'
                                                                ).format('HH:mm:ss'),
                                                            moment(res.eta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                                ' ' +
                                                                moment(res.etaTime, 'HH:mm:ss').format('HH:mm:ss')
                                                        ).minute < 0 &&
                                                            getDateDifference(
                                                                moment(
                                                                    index === 0
                                                                        ? originEtd
                                                                        : portDetails[index - 1]?.etd,
                                                                    'DD-MM-YYYY'
                                                                ).format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    moment(
                                                                        index === 0
                                                                            ? originTime
                                                                            : portDetails[index - 1]?.etdTime,
                                                                        'HH:mm:ss'
                                                                    ).format('HH:mm:ss'),
                                                                moment(res.eta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    moment(res.etaTime, 'HH:mm:ss').format('HH:mm:ss')
                                                            ).hour < 0 &&
                                                            getDateDifference(
                                                                moment(
                                                                    index === 0
                                                                        ? originEtd
                                                                        : portDetails[index - 1]?.etd,
                                                                    'DD-MM-YYYY'
                                                                ).format('YYYY-MM-DD'),
                                                                moment(res.eta, 'DD-MM-YYYY').format('YYYY-MM-DD')
                                                            ).days === 0 &&
                                                            res.etaTime) ||
                                                        (getDateDifference(
                                                            moment(res.eta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                                ' ' +
                                                                moment(res.etaTime, 'HH:mm:ss').format('HH:mm:ss'),
                                                            moment(res.etd, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                                ' ' +
                                                                moment(res.etdTime, 'HH:mm:ss').format('HH:mm:ss')
                                                        ).minute < 0 &&
                                                            getDateDifference(
                                                                moment(res.eta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    moment(res.etaTime, 'HH:mm:ss').format('HH:mm:ss'),
                                                                moment(res.etd, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                                    ' ' +
                                                                    moment(res.etdTime, 'HH:mm:ss').format('HH:mm:ss')
                                                            ).hour < 0 &&
                                                            getDateDifference(
                                                                moment(res.eta, 'DD-MM-YYYY').format('YYYY-MM-DD'),
                                                                moment(res.etd, 'DD-MM-YYYY').format('YYYY-MM-DD')
                                                            ).days === 0 &&
                                                            res.etdTime)
                                                    ) {
                                                        timeVal = true;
                                                    } else {
                                                        timeVal = false;
                                                    }

                                                    return (
                                                        <div className="layout-wrapper">
                                                            <div className="layout-holder two-column">
                                                                <div className="layout-item">
                                                                    <div className="layout-header via">
                                                                        Via- Leg {index + 2}{' '}
                                                                    </div>
                                                                </div>
                                                                <div className="layout-item remove-link">
                                                                    {index > 0 && (
                                                                        <a
                                                                            href="javascript:void(0)"
                                                                            className="file-remove-link"
                                                                            title="Remove Leg"
                                                                            onClick={() => {
                                                                                setportDetails([...portDetails]);
                                                                                const newArray = [...portDetails];
                                                                                newArray.splice(index, 1);
                                                                                setportDetails(newArray);
                                                                            }}
                                                                        >
                                                                            Remove Leg
                                                                        </a>
                                                                    )}
                                                                </div>
                                                                <div className="layout-item">
                                                                    <div className=" modal-input-holder layout-item">
                                                                        <label className="modal-input-label">
                                                                            Airport
                                                                            <span className="mandatory-field-text">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <div className="modal-input-layout-item">
                                                                            <Select
                                                                                options={portValues}
                                                                                isClearable={true}
                                                                                placeholder={'Enter Port to select'}
                                                                                onChange={(option: any) => {
                                                                                    res.portName = option.label;
                                                                                    res.portCode = option.value;
                                                                                    setportDetails([...portDetails]);
                                                                                }}
                                                                                onMenuOpen={() => setPorts([])}
                                                                                menuPlacement={'auto'}
                                                                                name="label"
                                                                                components={{
                                                                                    DropdownIndicator: () => null,
                                                                                    IndicatorSeparator: () => null,
                                                                                    ClearIndicator: () => (
                                                                                        <div
                                                                                            style={{
                                                                                                margin: '6px',
                                                                                            }}
                                                                                            onMouseDown={(e) =>
                                                                                                handleClearClick(e, res)
                                                                                            }
                                                                                        >
                                                                                            <span>
                                                                                                <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                                                                                                    <use xlinkHref="#closeIcon">
                                                                                                        <title>
                                                                                                            Close
                                                                                                        </title>
                                                                                                    </use>
                                                                                                </svg>
                                                                                            </span>
                                                                                        </div>
                                                                                    ),
                                                                                }}
                                                                                onInputChange={(text: string) => {
                                                                                    setSearchTerm(text);
                                                                                }}
                                                                                theme={reactSelectTheme}
                                                                                styles={reactSelectStyle}
                                                                                //value={res?.portName}
                                                                                noOptionsMessage={({ inputValue }) =>
                                                                                    inputValue?.length < 3
                                                                                        ? 'Enter at least 3 characters to search'
                                                                                        : 'No results found'
                                                                                }
                                                                                value={{
                                                                                    label: res?.portName,
                                                                                    value: res?.portCode,
                                                                                }}
                                                                            />

                                                                            {errorValidation && res.portName === '' && (
                                                                                <p
                                                                                    className="error_message_product"
                                                                                    style={{
                                                                                        paddingTop: '8px',
                                                                                    }}
                                                                                >
                                                                                    Airport is required
                                                                                </p>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="layout-item">
                                                                    <div className=" modal-input-holder layout-item">
                                                                        <label className="modal-input-label">
                                                                            ETA
                                                                            <span className="mandatory-field-text">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <div className="modal-input-layout-item calendar date-time">
                                                                            <DatePicker
                                                                                onChange={(value: any) => {
                                                                                    res.eta =
                                                                                        moment(value).format(
                                                                                            'DD-MM-YYYY'
                                                                                        );
                                                                                    setportDetails([...portDetails]);
                                                                                }}
                                                                                value={res.eta ? res.eta : ''}
                                                                                disabled={
                                                                                    index === 0
                                                                                        ? originEtd === ''
                                                                                        : portDetails[index - 1]
                                                                                              ?.etd === ''
                                                                                }
                                                                                minDate={
                                                                                    index === 0
                                                                                        ? new Date(
                                                                                              moment(
                                                                                                  originEtd,
                                                                                                  'DD-MM-YYYY'
                                                                                              ).format('MM-DD-YYYY')
                                                                                          )
                                                                                        : new Date(
                                                                                              moment(
                                                                                                  portDetails[index - 1]
                                                                                                      ?.etd,
                                                                                                  'DD-MM-YYYY'
                                                                                              ).format('MM-DD-YYYY')
                                                                                          )
                                                                                }
                                                                                placeholderText="Select Date"
                                                                                customInput={
                                                                                    <div className="modal-input-field-holder date-input-holder">
                                                                                        <input
                                                                                            className={`modal-input input-text with-border date-input input-date-cursor ${
                                                                                                (
                                                                                                    index === 0
                                                                                                        ? originEtd ===
                                                                                                          ''
                                                                                                        : portDetails[
                                                                                                              index - 1
                                                                                                          ]?.etd === ''
                                                                                                )
                                                                                                    ? 'disabled'
                                                                                                    : ''
                                                                                            }`}
                                                                                            type="text"
                                                                                            placeholder={'Select Date'}
                                                                                            value={
                                                                                                res.eta ? res.eta : ''
                                                                                            }
                                                                                            disabled={
                                                                                                index === 0
                                                                                                    ? originEtd === ''
                                                                                                    : portDetails[
                                                                                                          index - 1
                                                                                                      ]?.etd === ''
                                                                                            }
                                                                                        />
                                                                                        <svg className="svg-icon calendar-icon">
                                                                                            <use xlinkHref="#calendarIcon">
                                                                                                <title>Calendar</title>
                                                                                            </use>
                                                                                        </svg>
                                                                                    </div>
                                                                                }
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                            />
                                                                            <div className="input-time-wrapper">
                                                                                <LocalizationProvider
                                                                                    dateAdapter={AdapterDayjs}
                                                                                >
                                                                                    <DemoContainer
                                                                                        components={['TimePicker']}
                                                                                    >
                                                                                        <TimePickerPack
                                                                                            onChange={(value: any) => {
                                                                                                res.etaTime = moment(
                                                                                                    value.$d
                                                                                                ).format('HH:mm');
                                                                                                setportDetails([
                                                                                                    ...portDetails,
                                                                                                ]);
                                                                                            }}
                                                                                            views={['hours', 'minutes']}
                                                                                            format="HH:mm"
                                                                                            ampm={false}
                                                                                            timeSteps={{ minutes: 1 }}
                                                                                            value={
                                                                                                res?.etaTime
                                                                                                    ? dayjs(
                                                                                                          '0000-00-00T' +
                                                                                                              res?.etaTime
                                                                                                      )
                                                                                                    : editData?.edit
                                                                                                    ? dayjs(
                                                                                                          '0000-00-00T' +
                                                                                                              moment(
                                                                                                                  res?.originEtdTime,
                                                                                                                  'HH:mm:ss'
                                                                                                              ).format(
                                                                                                                  'HH:mm'
                                                                                                              )
                                                                                                      )
                                                                                                    : ''
                                                                                            }
                                                                                            disabled={
                                                                                                index === 0
                                                                                                    ? originEtd === ''
                                                                                                    : portDetails[
                                                                                                          index - 1
                                                                                                      ]?.etd === ''
                                                                                            }
                                                                                        />
                                                                                    </DemoContainer>
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                        {getDateDifference(
                                                                            moment(
                                                                                index === 0
                                                                                    ? originEtd
                                                                                    : portDetails[index - 1]?.etd,
                                                                                'DD-MM-YYYY'
                                                                            ).format('YYYY-MM-DD') +
                                                                                ' ' +
                                                                                moment(
                                                                                    index === 0
                                                                                        ? originTime
                                                                                        : portDetails[index - 1]
                                                                                              ?.etdTime,
                                                                                    'HH:mm:ss'
                                                                                ).format('HH:mm:ss'),
                                                                            moment(res.eta, 'DD-MM-YYYY').format(
                                                                                'YYYY-MM-DD'
                                                                            ) +
                                                                                ' ' +
                                                                                moment(res.etaTime, 'HH:mm:ss').format(
                                                                                    'HH:mm:ss'
                                                                                )
                                                                        ).minute <= 0 &&
                                                                            getDateDifference(
                                                                                moment(
                                                                                    index === 0
                                                                                        ? originEtd
                                                                                        : portDetails[index - 1]?.etd,
                                                                                    'DD-MM-YYYY'
                                                                                ).format('YYYY-MM-DD') +
                                                                                    ' ' +
                                                                                    moment(
                                                                                        index === 0
                                                                                            ? originTime
                                                                                            : portDetails[index - 1]
                                                                                                  ?.etdTime,
                                                                                        'HH:mm:ss'
                                                                                    ).format('HH:mm:ss'),
                                                                                moment(res.eta, 'DD-MM-YYYY').format(
                                                                                    'YYYY-MM-DD'
                                                                                ) +
                                                                                    ' ' +
                                                                                    moment(
                                                                                        res.etaTime,
                                                                                        'HH:mm:ss'
                                                                                    ).format('HH:mm:ss')
                                                                            ).hour <= 0 &&
                                                                            getDateDifference(
                                                                                moment(
                                                                                    index === 0
                                                                                        ? originEtd
                                                                                        : portDetails[index - 1]?.etd,
                                                                                    'DD-MM-YYYY'
                                                                                ).format('YYYY-MM-DD'),
                                                                                moment(res.eta, 'DD-MM-YYYY').format(
                                                                                    'YYYY-MM-DD'
                                                                                )
                                                                            ).days <= 0 &&
                                                                            res.etaTime && (
                                                                                <>
                                                                                    <p
                                                                                        className="error_message_product"
                                                                                        style={{ paddingTop: '8px' }}
                                                                                    >
                                                                                        ETA should be greater than the
                                                                                        ETD of previous leg
                                                                                    </p>
                                                                                </>
                                                                            )}
                                                                        {errorValidation && res.eta === '' && (
                                                                            <p
                                                                                className="error_message_product"
                                                                                style={{ paddingTop: '8px' }}
                                                                            >
                                                                                ETA is required
                                                                            </p>
                                                                        )}
                                                                        {errorValidation &&
                                                                            res.eta !== '' &&
                                                                            res.etaTime === '' && (
                                                                                <p
                                                                                    className="error_message_product"
                                                                                    style={{
                                                                                        paddingTop: '8px',
                                                                                    }}
                                                                                >
                                                                                    ETA Time is required
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                                <div className="layout-item">
                                                                    <div className=" modal-input-holder layout-item">
                                                                        <label className="modal-input-label">
                                                                            Flight No
                                                                            <span className="mandatory-field-text">
                                                                                *
                                                                            </span>
                                                                        </label>
                                                                        <div className="modal-input-layout-item">
                                                                            <input
                                                                                placeholder="Enter Flight No."
                                                                                type="text"
                                                                                className="modal-input input-text"
                                                                                id="sd_version"
                                                                                name="sd_version"
                                                                                onChange={(e) => {
                                                                                    res.flightNo = e.target.value;
                                                                                    setportDetails([...portDetails]);
                                                                                }}
                                                                                value={
                                                                                    res?.flightNo
                                                                                        ? res?.flightNo
                                                                                        : editData?.edit
                                                                                        ? res?.flightNo
                                                                                        : ''
                                                                                }
                                                                            ></input>
                                                                        </div>
                                                                        {errorValidation && res.flightNo === '' && (
                                                                            <p
                                                                                className="error_message_product"
                                                                                style={{
                                                                                    paddingTop: '8px',
                                                                                }}
                                                                            >
                                                                                Fligh No. is required
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div className="layout-item">
                                                                    <div className=" modal-input-holder layout-item">
                                                                        <label className="modal-input-label">
                                                                            ETD
                                                                            <span className="mandatory-field-text">
                                                                                *
                                                                            </span>
                                                                        </label>

                                                                        <div className="modal-input-layout-item calendar date-time">
                                                                            <DatePicker
                                                                                //disabled={disabled}
                                                                                minDate={
                                                                                    new Date(
                                                                                        moment(
                                                                                            res.eta,
                                                                                            'DD-MM-YYYY'
                                                                                        ).format('MM-DD-YYYY')
                                                                                    )
                                                                                }
                                                                                disabled={res.eta === ''}
                                                                                onChange={(value: any) => {
                                                                                    res.etd =
                                                                                        moment(value).format(
                                                                                            'DD-MM-YYYY'
                                                                                        );
                                                                                    setportDetails([...portDetails]);
                                                                                }}
                                                                                value={res.etd ? res.etd : ''}
                                                                                placeholderText="Select Date"
                                                                                customInput={
                                                                                    <div className="modal-input-field-holder date-input-holder">
                                                                                        <input
                                                                                            className={`modal-input input-text with-border date-input input-date-cursor ${
                                                                                                res.eta === ''
                                                                                                    ? 'disabled'
                                                                                                    : ''
                                                                                            }`}
                                                                                            type="text"
                                                                                            placeholder={'Select Date'}
                                                                                            value={
                                                                                                res.etd ? res.etd : ''
                                                                                            }
                                                                                            disabled={res.eta === ''}
                                                                                        />
                                                                                        <svg className="svg-icon calendar-icon">
                                                                                            <use xlinkHref="#calendarIcon">
                                                                                                <title>Calendar</title>
                                                                                            </use>
                                                                                        </svg>
                                                                                    </div>
                                                                                }
                                                                                peekNextMonth
                                                                                showMonthDropdown
                                                                                showYearDropdown
                                                                                dropdownMode="select"
                                                                            />
                                                                            <div className="input-time-wrapper">
                                                                                <LocalizationProvider
                                                                                    dateAdapter={AdapterDayjs}
                                                                                >
                                                                                    <DemoContainer
                                                                                        components={['TimePicker']}
                                                                                    >
                                                                                        <TimePickerPack
                                                                                            onChange={(value: any) => {
                                                                                                res.etdTime = moment(
                                                                                                    value.$d
                                                                                                ).format('HH:mm');
                                                                                                setportDetails([
                                                                                                    ...portDetails,
                                                                                                ]);
                                                                                            }}
                                                                                            views={['hours', 'minutes']}
                                                                                            format="HH:mm"
                                                                                            ampm={false}
                                                                                            timeSteps={{ minutes: 1 }}
                                                                                            value={
                                                                                                res?.etdTime
                                                                                                    ? dayjs(
                                                                                                          '0000-00-00T' +
                                                                                                              res?.etdTime
                                                                                                      )
                                                                                                    : editData?.edit
                                                                                                    ? dayjs(
                                                                                                          '0000-00-00T' +
                                                                                                              res?.destEtaTime
                                                                                                      )
                                                                                                    : ''
                                                                                            }
                                                                                            disabled={res.eta === ''}
                                                                                        />
                                                                                    </DemoContainer>
                                                                                </LocalizationProvider>
                                                                            </div>
                                                                        </div>
                                                                        {getDateDifference(
                                                                            moment(res.eta, 'DD-MM-YYYY').format(
                                                                                'YYYY-MM-DD'
                                                                            ) +
                                                                                ' ' +
                                                                                moment(res.etaTime, 'HH:mm:ss').format(
                                                                                    'HH:mm:ss'
                                                                                ),
                                                                            moment(res.etd, 'DD-MM-YYYY').format(
                                                                                'YYYY-MM-DD'
                                                                            ) +
                                                                                ' ' +
                                                                                moment(res.etdTime, 'HH:mm:ss').format(
                                                                                    'HH:mm:ss'
                                                                                )
                                                                        ).minute <= 0 &&
                                                                            getDateDifference(
                                                                                moment(res.eta, 'DD-MM-YYYY').format(
                                                                                    'YYYY-MM-DD'
                                                                                ) +
                                                                                    ' ' +
                                                                                    moment(
                                                                                        res.etaTime,
                                                                                        'HH:mm:ss'
                                                                                    ).format('HH:mm:ss'),
                                                                                moment(res.etd, 'DD-MM-YYYY').format(
                                                                                    'YYYY-MM-DD'
                                                                                ) +
                                                                                    ' ' +
                                                                                    moment(
                                                                                        res.etdTime,
                                                                                        'HH:mm:ss'
                                                                                    ).format('HH:mm:ss')
                                                                            ).hour <= 0 &&
                                                                            getDateDifference(
                                                                                moment(res.eta, 'DD-MM-YYYY').format(
                                                                                    'YYYY-MM-DD'
                                                                                ),
                                                                                moment(res.etd, 'DD-MM-YYYY').format(
                                                                                    'YYYY-MM-DD'
                                                                                )
                                                                            ).days <= 0 &&
                                                                            res.etdTime && (
                                                                                <p
                                                                                    className="error_message_product"
                                                                                    style={{ paddingTop: '8px' }}
                                                                                >
                                                                                    ETD should be greater than the ETA
                                                                                    of current leg
                                                                                </p>
                                                                            )}
                                                                        {errorValidation && res.etd === '' && (
                                                                            <p
                                                                                className="error_message_product"
                                                                                style={{ paddingTop: '8px' }}
                                                                            >
                                                                                ETD is required
                                                                            </p>
                                                                        )}
                                                                        {errorValidation &&
                                                                            res.etd !== '' &&
                                                                            res.etdTime === '' && (
                                                                                <p
                                                                                    className="error_message_product"
                                                                                    style={{
                                                                                        paddingTop: '8px',
                                                                                    }}
                                                                                >
                                                                                    ETD Time is required
                                                                                </p>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                                <div className="btn-holder">
                                                    <button
                                                        className="app-btn app-btn-secondary icon-button"
                                                        title="Add Leg"
                                                        onClick={() => {
                                                            //setlegUpdate(!legUpdate);

                                                            const newAr = [
                                                                ...portDetails,
                                                                {
                                                                    portName: '',
                                                                    eta: '',
                                                                    etd: '',
                                                                    etaTime: '',
                                                                    etdTime: '',
                                                                    flightNo: '',
                                                                },
                                                            ];

                                                            setdestinationEta('');
                                                            setdestinationTime('');
                                                            setportDetails(newAr);
                                                        }}
                                                    >
                                                        <svg className="svg-icon add-btn-icon">
                                                            <use xlinkHref="#plusIcon"></use>
                                                        </svg>
                                                        <span className="button-text">Add Leg</span>
                                                    </button>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    <div className="card-head-holder add-btn-holder">
                                        <div className="layout-header destination">
                                            Destination
                                            {Type !== 'Direct' ? '-Leg ' + Math.ceil(portDetails?.length + 2) : ''}
                                        </div>
                                        <div className="layout-holder two-column">
                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item">
                                                    <label className="modal-input-label">Airport</label>
                                                    <div className="modal-input-layout-item">
                                                        <input
                                                            placeholder={`${
                                                                editData?.edit
                                                                    ? editData?.data?.destinationPort
                                                                    : destinationPort
                                                            }`}
                                                            type="text"
                                                            className="modal-input input-text read-only"
                                                            id="sd_version"
                                                            name="sd_version"
                                                            readOnly
                                                        ></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item">
                                                    <label className="modal-input-label">
                                                        ETA<span className="mandatory-field-text">*</span>
                                                    </label>

                                                    <div className="modal-input-layout-item calendar date-time">
                                                        <DatePicker
                                                            //disabled={disabled}
                                                            minDate={
                                                                Type === 'Direct'
                                                                    ? new Date(
                                                                          moment(originEtd, 'DD-MM-YYYY').format(
                                                                              'MM-DD-YYYY'
                                                                          )
                                                                      )
                                                                    : new Date(
                                                                          moment(
                                                                              portDetails[portDetails?.length - 1]?.etd,
                                                                              'DD-MM-YYYY'
                                                                          ).format('MM-DD-YYYY')
                                                                      )
                                                            }
                                                            disabled={
                                                                Type === 'Direct'
                                                                    ? originEtd === ''
                                                                    : portDetails[portDetails?.length - 1]?.etd === ''
                                                            }
                                                            onChange={(value: any) => {
                                                                setdestinationEta(moment(value).format('DD-MM-YYYY'));
                                                            }}
                                                            value={destinationEta ? destinationEta : ''}
                                                            placeholderText="Select Date"
                                                            customInput={
                                                                <div className="modal-input-field-holder date-input-holder">
                                                                    <input
                                                                        className={`modal-input input-text with-border date-input input-date-cursor ${
                                                                            (
                                                                                Type === 'Direct'
                                                                                    ? originEtd === ''
                                                                                    : portDetails[
                                                                                          portDetails?.length - 1
                                                                                      ]?.etd === ''
                                                                            )
                                                                                ? 'disabled'
                                                                                : ''
                                                                        }`}
                                                                        type="text"
                                                                        placeholder={'Select Date'}
                                                                        disabled={
                                                                            Type === 'Direct'
                                                                                ? originEtd === ''
                                                                                : portDetails[portDetails?.length - 1]
                                                                                      ?.etd === ''
                                                                        }
                                                                        value={destinationEta ? destinationEta : ''}
                                                                    />
                                                                    <svg className="svg-icon calendar-icon">
                                                                        <use xlinkHref="#calendarIcon">
                                                                            <title>Calendar</title>
                                                                        </use>
                                                                    </svg>
                                                                </div>
                                                            }
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />
                                                        <div className="input-time-wrapper">
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['TimePicker']}>
                                                                    <TimePickerPack
                                                                        onChange={(value: any) =>
                                                                            setdestinationTime(
                                                                                moment(value.$d).format('HH:mm')
                                                                            )
                                                                        }
                                                                        views={['hours', 'minutes']}
                                                                        format="HH:mm"
                                                                        ampm={false}
                                                                        timeSteps={{ minutes: 1 }}
                                                                        value={
                                                                            destinationTime
                                                                                ? dayjs('0000-00-00T' + destinationTime)
                                                                                : ''
                                                                        }
                                                                        disabled={
                                                                            Type === 'Direct'
                                                                                ? originEtd === ''
                                                                                : portDetails[portDetails?.length - 1]
                                                                                      ?.etd === ''
                                                                        }
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </div>
                                                    </div>
                                                    {getDateDifference(
                                                        moment(
                                                            Type === 'Direct'
                                                                ? originEtd
                                                                : portDetails[portDetails?.length - 1]?.etd,
                                                            'DD-MM-YYYY'
                                                        ).format('YYYY-MM-DD') +
                                                            ' ' +
                                                            moment(
                                                                Type === 'Direct'
                                                                    ? originTime
                                                                    : portDetails[portDetails?.length - 1]?.etdTime,
                                                                'HH:mm'
                                                            ).format('HH:mm:ss'),
                                                        moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                            ' ' +
                                                            moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
                                                    ).minute <= 0 &&
                                                        getDateDifference(
                                                            moment(
                                                                Type === 'Direct'
                                                                    ? originEtd
                                                                    : portDetails[portDetails?.length - 1]?.etd,
                                                                'DD-MM-YYYY'
                                                            ).format('YYYY-MM-DD') +
                                                                ' ' +
                                                                moment(
                                                                    Type === 'Direct'
                                                                        ? originTime
                                                                        : portDetails[portDetails?.length - 1]?.etdTime,
                                                                    'HH:mm'
                                                                ).format('HH:mm:ss'),
                                                            moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD') +
                                                                ' ' +
                                                                moment(destinationTime, 'HH:mm:ss').format('HH:mm:ss')
                                                        ).hour <= 0 &&
                                                        getDateDifference(
                                                            moment(
                                                                Type === 'Direct'
                                                                    ? originEtd
                                                                    : portDetails[portDetails?.length - 1]?.etd,
                                                                'DD-MM-YYYY'
                                                            ).format('YYYY-MM-DD'),
                                                            moment(destinationEta, 'DD-MM-YYYY').format('YYYY-MM-DD')
                                                        ).days <= 0 &&
                                                        destinationTime && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                ETA should be greater than the ETD of previous leg
                                                            </p>
                                                        )}
                                                    {errorValidation && destinationEta === '' && (
                                                        <p
                                                            className="error_message_product"
                                                            style={{ paddingTop: '8px' }}
                                                        >
                                                            ETA is required
                                                        </p>
                                                    )}
                                                    {errorValidation &&
                                                        destinationEta !== '' &&
                                                        destinationTime === '' && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                ETA Time is required
                                                            </p>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <ExistingScheduleList />
                        </div>
                        <ExistingForm />

                        {createMethod === 'new' && (
                            <>
                                <div className="card details-box custom">
                                    <div className="card-head-holder add-btn-holder buy-quote">
                                        <div className="layout-header-cost">Buy Quote</div>
                                        <div className="layout-holder two-column">
                                            {/* {profileType === 'agent' ? ( */}
                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item">
                                                    <label className="modal-input-label">
                                                        Booking Quote Number
                                                        <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <input
                                                            placeholder="Enter Booking Quote Number"
                                                            type="text"
                                                            className="modal-input input-text"
                                                            id="sd_version"
                                                            name="sd_version"
                                                            min="0"
                                                            maxLength={50}
                                                            onChange={(e) => setQuoteNumber(e.target.value)}
                                                            value={quoteNumber ? quoteNumber : ''}
                                                        ></input>
                                                        {errorValidation && quoteNumber === '' && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                Booking Quote Number is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {/* // ) : (
                                            //     <div className="layout-item">
                                            //         <div className=" modal-input-holder layout-item"></div>
                                            //     </div>
                                            // )} */}
                                            <div className="layout-item">
                                                <div className=" modal-input-holder layout-item"></div>
                                            </div>
                                            <div className="layout-item">
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Total Cost (Buy)
                                                        <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <div className="total-term-wrapper">
                                                            <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                                                <span className="unit-style"></span>
                                                                <input
                                                                    placeholder="Enter Total Cost"
                                                                    type="text"
                                                                    id={'totalOriginCost'}
                                                                    name={'totalOriginCost'}
                                                                    className="modal-input input-text"
                                                                    onChange={(e) =>
                                                                        costValidation(e.target.value, 'buy')
                                                                    }
                                                                    style={{ width: '115px' }}
                                                                    value={totalCost ? totalCost : ''}
                                                                ></input>
                                                                {errorValidation && totalCost === '' && (
                                                                    <p
                                                                        className="error_message_product"
                                                                        style={{ paddingTop: '8px' }}
                                                                    >
                                                                        Total Cost is required
                                                                    </p>
                                                                )}
                                                                {costValidationMsg && (
                                                                    <p
                                                                        className="error_message_product"
                                                                        style={{ paddingTop: '8px' }}
                                                                    >
                                                                        Enter a valid Cost
                                                                    </p>
                                                                )}
                                                            </div>
                                                            <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                                <SearchableSelectBox
                                                                    data={currency}
                                                                    placeholder="Select currency"
                                                                    setStateValue={setCostBuy}
                                                                    stateValue={costBuy}
                                                                    fieldName="currency"
                                                                    customStyle={portalStyle}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    //index={productData.indexValue}
                                                                    closeMenuOnScroll={true}
                                                                    menuPlacement={
                                                                        profileType === 'agent' ? 'top' : 'auto'
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                                <SearchableSelectBox
                                                                    data={uomData}
                                                                    placeholder="Select UOMs"
                                                                    setStateValue={setBuyUomCode}
                                                                    stateValue={buyUomCode}
                                                                    fieldName="uomCode"
                                                                    customStyle={portalStyleLg}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    //index={productData.indexValue}
                                                                    closeMenuOnScroll={true}
                                                                    menuPlacement={
                                                                        profileType === 'agent' ? 'top' : 'auto'
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="layout-item">
                                                <div className=" modal-input-holder">
                                                    <label htmlFor="name" className="modal-input-label">
                                                        Quotation (Buy)
                                                        <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <div className="browse-btn-wrapper">
                                                            <input
                                                                type={'file'}
                                                                id={'quotationFile'}
                                                                name={'quotationFile'}
                                                                accept={
                                                                    '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                }
                                                                className="browse-input"
                                                                onChange={(e) => {
                                                                    dispatch(isLoadingFn2(true));
                                                                    if (
                                                                        (e as any).target.files[0]?.name.includes(
                                                                            '.pdf'
                                                                        ) ||
                                                                        (e as any).target.files[0]?.name.includes(
                                                                            '.doc'
                                                                        ) ||
                                                                        (e as any).target.files[0]?.name.includes(
                                                                            '.docx'
                                                                        )
                                                                    ) {
                                                                        setUploadedFile((e as any).target.files[0]);
                                                                        setUploadedFileDetail({
                                                                            name: (e as any).target.files[0]?.name,
                                                                            size: (e as any).target.files[0]?.size,
                                                                        });
                                                                        dispatch(isLoadingFn2(false));
                                                                    } else {
                                                                        error('Invalid File Type', Position.TOP_RIGHT);
                                                                        dispatch(isLoadingFn2(false));
                                                                    }
                                                                }}
                                                                onClick={(event) =>
                                                                    ((event.target as any).value = null)
                                                                }
                                                            ></input>
                                                            <button
                                                                className="app-btn app-btn-secondary icon-button browse-btn"
                                                                title="Upload file"
                                                                type="button"
                                                            >
                                                                <svg className="svg-icon plus-icon">
                                                                    <use xlinkHref="#plusIcon">
                                                                        <title>Upload File</title>
                                                                    </use>
                                                                </svg>
                                                                <span className="button-text upload-btn">
                                                                    Upload File
                                                                </span>
                                                            </button>
                                                        </div>

                                                        <div className="heading-type-1 file-size">
                                                            <p>{fileUploadInfoText}</p>
                                                            <p
                                                                dangerouslySetInnerHTML={{ __html: fileTypesInfoText }}
                                                            ></p>
                                                        </div>
                                                        {errorValidation &&
                                                            (!uploadedFileDetail?.name ||
                                                                uploadedFileDetail?.name == undefined) && (
                                                                <p
                                                                    className="error_message_product"
                                                                    style={{ paddingTop: '8px' }}
                                                                >
                                                                    Quotation is required
                                                                </p>
                                                            )}
                                                        {uploadedFileDetail?.name &&
                                                            uploadedFileDetail?.name != undefined && (
                                                                <div className="content-with-icon  attachment-holder">
                                                                    <button
                                                                        type="button"
                                                                        className="app-btn "
                                                                        title="Attach file"
                                                                        onClick={(e) => {
                                                                            e.preventDefault();
                                                                        }}
                                                                    >
                                                                        <svg className="svg-icon file-attachment-icon ">
                                                                            <use xlinkHref="#fileAttachmentIcon"></use>
                                                                            <title>Attach file</title>
                                                                        </svg>
                                                                    </button>
                                                                    <div>
                                                                        <a
                                                                            className="description-type-1 file-uploaded"
                                                                            title="File"
                                                                        >
                                                                            {(uploadedFileDetail as any)?.name}
                                                                        </a>
                                                                        {uploadedFile ? (
                                                                            <div className="heading-type-1">
                                                                                {(
                                                                                    (uploadedFile as any)?.size / 1000
                                                                                ).toFixed(2)}{' '}
                                                                                kb
                                                                            </div>
                                                                        ) : (
                                                                            <div className="heading-type-1">
                                                                                {(uploadedFileDetail as any)?.size} kb
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <a
                                                                        className="file-remove-link"
                                                                        title="Remove"
                                                                        onClick={() => {
                                                                            setUploadedFile(null);
                                                                            setUploadedFileDetail({
                                                                                name: '',
                                                                                size: '',
                                                                            });
                                                                        }}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        Remove
                                                                    </a>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {profileType !== 'agent' && (
                                    <div className="card details-box custom">
                                        <div className="card-head-holder add-btn-holder buy-quote">
                                            <div className="layout-header-cost">Sell Quote</div>
                                            <div className="layout-holder two-column">
                                                {/* <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            Booking Quote Number
                                                            <span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <input
                                                                placeholder="Enter Booking Quote Number"
                                                                type="text"
                                                                className="modal-input input-text"
                                                                id="sd_version"
                                                                name="sd_version"
                                                                min="0"
                                                                maxLength={50}
                                                                onChange={(e) => setQuoteNumber(e.target.value)}
                                                                value={quoteNumber ? quoteNumber : ''}
                                                            ></input>
                                                            {errorValidation && quoteNumber === '' && (
                                                                <p
                                                                    className="error_message_product"
                                                                    style={{ paddingTop: '8px' }}
                                                                >
                                                                    Booking Quote Number is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="layout-item two-column">
                                                    <div className=" modal-input-holder layout-item"></div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item"></div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder">
                                                        <label className="modal-input-label">
                                                            Total Cost (Sell)
                                                            {bpStatus !== 'Rejected_by_Forwarder' &&
                                                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                                bpStatus !== 'Draft_Booking_Proposal' && (
                                                                    <span className="mandatory-field-text">*</span>
                                                                )}
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <div className="total-term-wrapper">
                                                                <div className="modal-input-layout-item total-terms-data-holder textfield-holder">
                                                                    <span className="unit-style"></span>
                                                                    <input
                                                                        placeholder="Enter Total Cost"
                                                                        type="text"
                                                                        id={'totalOriginCost'}
                                                                        name={'totalOriginCost'}
                                                                        className="modal-input input-text"
                                                                        onChange={(e) =>
                                                                            costValidation(e.target.value, 'sell')
                                                                        }
                                                                        style={{ width: '115px' }}
                                                                        value={totalCostSell ? totalCostSell : ''}
                                                                    ></input>
                                                                    {errorValidation && totalCostSell === '' && (
                                                                        <p
                                                                            className="error_message_product"
                                                                            style={{ paddingTop: '8px' }}
                                                                        >
                                                                            Total Cost is required
                                                                        </p>
                                                                    )}
                                                                    {costValidationMsg && (
                                                                        <p
                                                                            className="error_message_product"
                                                                            style={{ paddingTop: '8px' }}
                                                                        >
                                                                            Enter a valid Cost
                                                                        </p>
                                                                    )}
                                                                </div>
                                                                <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                                    <SearchableSelectBox
                                                                        data={currency}
                                                                        placeholder="Select currency"
                                                                        setStateValue={setCostSell}
                                                                        stateValue={costSell}
                                                                        fieldName="currency"
                                                                        customStyle={portalStyle}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        //index={productData.indexValue}
                                                                        closeMenuOnScroll={true}
                                                                        menuPlacement={'top'}
                                                                    />
                                                                </div>
                                                                <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                                    <SearchableSelectBox
                                                                        data={uomData}
                                                                        placeholder="Select UOMs"
                                                                        setStateValue={setSellUomCode}
                                                                        stateValue={sellUomCode}
                                                                        fieldName="uomCode"
                                                                        customStyle={portalStyleLg}
                                                                        menuPosition="fixed"
                                                                        menuPortalTarget={document.getElementById(
                                                                            'outside_div'
                                                                        )}
                                                                        //index={productData.indexValue}
                                                                        closeMenuOnScroll={true}
                                                                        menuPlacement={'top'}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder">
                                                        <label htmlFor="name" className="modal-input-label">
                                                            Quotation (Sell)
                                                            {bpStatus !== 'Rejected_by_Forwarder' &&
                                                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                                bpStatus !== 'Draft_Booking_Proposal' && (
                                                                    <span className="mandatory-field-text">*</span>
                                                                )}
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <div className="browse-btn-wrapper">
                                                                <input
                                                                    type={'file'}
                                                                    id={'quotationFile'}
                                                                    name={'quotationFile'}
                                                                    accept={
                                                                        '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                    }
                                                                    className="browse-input"
                                                                    onChange={(e) => {
                                                                        dispatch(isLoadingFn2(true));
                                                                        if (
                                                                            (e as any).target.files[0]?.name.includes(
                                                                                '.pdf'
                                                                            ) ||
                                                                            (e as any).target.files[0]?.name.includes(
                                                                                '.doc'
                                                                            ) ||
                                                                            (e as any).target.files[0]?.name.includes(
                                                                                '.docx'
                                                                            )
                                                                        ) {
                                                                            setUploadedFileSell(
                                                                                (e as any).target.files[0]
                                                                            );
                                                                            setUploadedFileDetailSell({
                                                                                name: (e as any).target.files[0]?.name,
                                                                                size: (e as any).target.files[0]?.size,
                                                                            });
                                                                            dispatch(isLoadingFn2(false));
                                                                        } else {
                                                                            error(
                                                                                'Invalid File Type',
                                                                                Position.TOP_RIGHT
                                                                            );
                                                                            dispatch(isLoadingFn2(false));
                                                                        }
                                                                    }}
                                                                    onClick={(event) =>
                                                                        ((event.target as any).value = null)
                                                                    }
                                                                ></input>
                                                                <button
                                                                    className="app-btn app-btn-secondary icon-button browse-btn"
                                                                    title="Upload file"
                                                                    type="button"
                                                                >
                                                                    <svg className="svg-icon plus-icon">
                                                                        <use xlinkHref="#plusIcon">
                                                                            <title>Upload File</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="button-text upload-btn">
                                                                        Upload File
                                                                    </span>
                                                                </button>
                                                            </div>

                                                            <div className="heading-type-1 file-size">
                                                                <p>{fileUploadInfoText}</p>
                                                                <p
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: fileTypesInfoText,
                                                                    }}
                                                                ></p>
                                                            </div>
                                                            {errorValidation &&
                                                                (!uploadedFileDetailSell?.name ||
                                                                    uploadedFileDetailSell?.name == undefined) && (
                                                                    <p
                                                                        className="error_message_product"
                                                                        style={{ paddingTop: '8px' }}
                                                                    >
                                                                        Quotation is required
                                                                    </p>
                                                                )}
                                                            {uploadedFileDetailSell?.name &&
                                                                uploadedFileDetailSell?.name != undefined && (
                                                                    <div className="content-with-icon  attachment-holder">
                                                                        <button
                                                                            type="button"
                                                                            className="app-btn "
                                                                            title="Attach file"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        >
                                                                            <svg className="svg-icon file-attachment-icon ">
                                                                                <use xlinkHref="#fileAttachmentIcon"></use>
                                                                                <title>Attach file</title>
                                                                            </svg>
                                                                        </button>
                                                                        <div>
                                                                            <a
                                                                                className="description-type-1 file-uploaded"
                                                                                title="File"
                                                                            >
                                                                                {(uploadedFileDetailSell as any)?.name}
                                                                            </a>
                                                                            {uploadedFileSell ? (
                                                                                <div className="heading-type-1">
                                                                                    {(
                                                                                        (uploadedFileSell as any)
                                                                                            ?.size / 1000
                                                                                    ).toFixed(2)}{' '}
                                                                                    kb
                                                                                </div>
                                                                            ) : (
                                                                                <div className="heading-type-1">
                                                                                    {
                                                                                        (uploadedFileDetailSell as any)
                                                                                            ?.size
                                                                                    }{' '}
                                                                                    kb
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <a
                                                                            className="file-remove-link"
                                                                            title="Remove"
                                                                            onClick={() => {
                                                                                setUploadedFileSell(null);
                                                                                setUploadedFileDetailSell({
                                                                                    name: '',
                                                                                    size: '',
                                                                                });
                                                                            }}
                                                                            style={{ cursor: 'pointer' }}
                                                                        >
                                                                            Remove
                                                                        </a>
                                                                    </div>
                                                                )}
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="layout-item">
                                                    <div className=" modal-input-holder">
                                                        <label className="modal-input-label">
                                                            UOMs
                                                            {bpStatus !== 'Rejected_by_Forwarder' &&
                                                                bpStatus !== 'Rejected_by_Client_Agent_to_Action' &&
                                                                bpStatus !== 'Draft_Booking_Proposal' && (
                                                                    <span className="mandatory-field-text">*</span>
                                                                )}
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <div className="total-term-wrapper dropdown-full">
                                                                <SearchableSelectBox
                                                                    data={uomData}
                                                                    placeholder="Select UOMs"
                                                                    setStateValue={setUom}
                                                                    stateValue={uom}
                                                                    fieldName="uomCode"
                                                                    customStyle={portalStyleLg}
                                                                    menuPosition="fixed"
                                                                    menuPortalTarget={document.getElementById(
                                                                        'outside_div'
                                                                    )}
                                                                    //index={productData.indexValue}
                                                                    closeMenuOnScroll={true}
                                                                    menuPlacement={menuPlacement}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    <>
                        <button
                            type="button"
                            title="Cancel"
                            id="basicForm"
                            className="app-btn app-btn-secondary footer-btn"
                            onClick={() => {
                                navigate(-1);
                                setportDetails([
                                    {
                                        portName: '',
                                        eta: '',
                                        etd: '',
                                        etaTime: '',
                                        etdTime: '',
                                        flightNo: '',
                                    },
                                ]);
                            }}
                        >
                            <span className="button-text footer-button-text">Cancel</span>
                        </button>

                        <button
                            type="submit"
                            title="Submit"
                            id="basicForm"
                            className={'app-btn app-btn-primary footer-btn '}
                            //onMouseOverCapture={() => seterrorValidation(true)}
                            onClick={() => {
                                createMethod === 'existing' && existingScheduleSubmit();
                                if (!isLoading2) {
                                    createMethod === 'new' && !disableBtn && submitValues();
                                }
                            }}
                            disabled={
                                createMethod === 'new'
                                    ? (submitEnabled && typeEnabled && !buttonValidation) ||
                                      checkDateEtdToEta() ||
                                      checkDate() ||
                                      timeVal ||
                                      costBuy === '' ||
                                      costBuy === undefined ||
                                      buyUomCode === '' ||
                                      buyUomCode === undefined ||
                                      //   costSell === '' ||
                                      //   costSell === undefined ||
                                      !Carrier ||
                                      !flightno ||
                                      originTime === '' ||
                                      originTime === 'Invalid date' ||
                                      destinationTime === '' ||
                                      destinationTime === 'Invalid date' ||
                                      (Type === 'Transhipment' && checkIsFlightDetailsMissing)
                                    : !existSubmitDisabled
                            }
                        >
                            <span className="button-text footer-button-text">Submit</span>
                        </button>
                    </>
                </div>
            </div>
        </div>
    );
};
export default Index;
