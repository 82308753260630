// const baseURL = process.env.REACT_APP_BASE_URL;
export const filterValues = [
    {
        label: 'JAN-2022',
        value: '1,2023',
    },
    {
        label: 'DEC-2022',
        value: '12,2022',
    },
    {
        label: 'NOV-2022',
        value: '11,2022',
    },
    {
        label: 'OCT-2022',
        value: '10,2022',
    },
    {
        label: 'Last 3 months',
        value: 'l3m',
    },
];

export const gridDetails = [
    {
        name: 'vendor-purchaseorder',
        columns: [
            {
                field: 'poNumber',
                title: 'PO Number',
                width: '200px',
                template:
                    '#if (poType==="AO") {# <a class="link-com" href="/allocation_orders/#=poId#/basic_details" >#=poNumber#</a> #} else if(poType==="ITPO") {# <a class="link-com" href="/purchase_order/#=poId#/basic_details" >#=poNumber#</a> #} else {# <a class="link-com" href="/inbound/#=poId#/basic_details" >#=poNumber#</a> #}#',
            },
            {
                field: 'mode',
                title: 'Mode',
            },
            {
                field: 'status',
                title: 'Status',
                width: '200px',
            },
            {
                field: 'orderDate',
                title: 'Order Date',
                template: '#if(orderDate === null){# - #}else{# #=orderDate# #}#',
                width: '200px',
            },
            {
                field: 'origin',
                title: 'Orgin',
                width: '200px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '200px',
            },
            {
                field: 'clientName',
                title: 'Client',
                width: '200px',
            },
            {
                field: 'agentName',
                title: 'Agent',
                width: '200px',
            },
            {
                field: 'destType',
                title: 'Dest Type',
                width: '200px',
            },
        ],
        fields: {
            poId: { type: 'string' },
            poNumber: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            orderDate: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            clientName: { type: 'string' },
            agentName: { type: 'string' },
            destType: { type: 'string' },
        },
        url: '',
        fileName: 'vendor-purchaseorder-grid-data',
        method: 'POST',
        contentType: 'application/json',
    },
    {
        name: 'vendor-bookings',
        columns: [
            {
                field: 'bpNumber',
                title: 'Booking No',
                width: '200px',
                template:
                    '#if (mode==="FCL") {# <a class="link-com" href="/booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(mode==="LCL") {# <a class="link-com" href="/lclbooking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(mode==="BCN") {# <a class="link-com" href="/bcnBookingList/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(mode==="AIR") {# <a class="link-com" href="/air_booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else {# <a class="link-com" href="/Aobooking_list/#=poId#/AOSelection" >#=bpNumber#</a> #}#',
            },
            {
                field: 'type',
                title: 'Type',
                width: '200px',
            },

            {
                field: 'mode',
                title: 'Mode',
                width: '200px',
            },
            {
                field: 'status',
                title: 'Status',
                width: '200px',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '200px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '200px',
            },
            {
                field: 'etd',
                title: 'ETD',
                template: '#if(etd === null){# - #}else{# #=etd# #}#',
                // template: '{etd:dd-MM-yyyy}',
                width: '200px',
            },
            {
                field: 'atd',
                title: 'ATD',
                template: '#if(atd === null){# - #}else{# #=atd# #}#',
                width: '200px',
            },
            {
                field: 'eta',
                title: 'ETA',
                // template: '{eta:dd-MM-yyyy}',
                template: '#if(eta === null){# - #}else{# #=eta# #}#',
                width: '200px',
            },
            {
                field: 'ata',
                title: 'ATA',
                template: '#if(ata === null){# - #}else{# #=ata# #}#',
                width: '200px',
            },
        ],
        fields: {
            bpId: { type: 'string' },
            bpNumber: { type: 'string' },
            type: { type: 'string' },
            mode: { type: 'string' },
            status: { type: 'string' },
            origin: { type: 'array' },
            destination: { type: 'array' },
            etd: { type: 'string' },
            atd: { type: 'string' },
            eta: { type: 'string' },
            ata: { type: 'string' },
        },
        url: '',
        fileName: 'vendor-booking-data',
        method: 'POST',
        contentType: 'application/json',
    },
];
