import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { post } from 'src/services/HttpMethods';
import { wareHouseTimelineFetch } from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import '../lcsBookingTiimeline.scss';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { RootState } from 'src/store/reducers';
import { lifeCycleSearchCategories } from './Common/constants';
import MaxLimitModal from '../MaxLimitModal';

const Index: React.FC = () => {
    const { wareHouseDetails } = useSelector((state: any) => state.lifeCycleSearch) as any;
    // const { type } = useParams();
    const { selectedData } = useSelector((state: any) => state.lifeCycleSearch);
    const {
        favList,
        removeSuccess,
        addSuccess,
        isLoading: isFavLoading,
        isError,
        error,
    } = useSelector((state: RootState) => state.lifecyclesearchFav);
    const [showModal, setShowModal] = useState(false);
    const [activePOCount, setActivePOCount] = useState<number>(0);
    const [activeBPCount, setActiveBPCount] = useState<number>(0);
    // const location = useLocation() as any;
    const dispatch = useDispatch();
    const { id: code } = useParams();

    const { userName, profileType } = useUserProfile();

    const fetchActivePoCount = async () => {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
        try {
            const res = await post(
                `/api/ord/tracking/lifecycle/warehouse/pocount?blpAgentCode=${code}`,
                {
                    ...listOfUserOrganisations,
                },
                null
            );
            setActivePOCount(res.activePO);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchActiveBpCount = async () => {
        try {
            const res = await post(`/api/bkg/tracking/lifecycle/warehouse/bpcount?blpAgentCode=${code}`, null, null);
            setActiveBPCount(res.activeBp);
        } catch (error) {
            console.log(error);
        }
    };

    useLayoutEffect(() => {
        if (code) {
            dispatch(wareHouseTimelineFetch({ code, userName, isForwarder: profileType }));
            fetchActivePoCount();
            fetchActiveBpCount();
        }
    }, [code]);

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.warehouse,
                    favourite: 'WareHouse',
                    number: wareHouseDetails?.agentName,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">WareHouse</div>
                <div className="timeline-head-value">{wareHouseDetails?.agentName || '-'}</div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isFavLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="cm-key-pair-details">
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Status</div>
                    <div className="cm-key-pair-value">
                        <div
                            className={`status-ui ${
                                wareHouseDetails?.status === 'ACTIVE' ? 'success-status' : 'error-status'
                            } `}
                        >
                            {wareHouseDetails?.status || '-'}
                        </div>
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Tier</div>
                    <div className="cm-key-pair-value">{wareHouseDetails?.tier || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Count of Active PO's</div>
                    <div className="cm-key-pair-value">{activePOCount || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Count of Active Booking's</div>
                    <div className="cm-key-pair-value">{activeBPCount || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">No of Users</div>
                    <div className="cm-key-pair-value">{wareHouseDetails?.noOfUsers || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Last Logon date</div>
                    <div className="cm-key-pair-value">
                        {wareHouseDetails?.lastLogOnDate
                            ? moment.utc(wareHouseDetails.lastLogOnDate).local().format('DD-MM-YYYY')
                            : '-'}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Contracts</div>
                    <div className="cm-key-pair-value">
                        {wareHouseDetails?.activeContract?.length > 0 ? (
                            wareHouseDetails?.activeContract?.map((res: any) => (
                                <div className="cm-key-pair-value">
                                    {res.status} - {res.count}
                                </div>
                            ))
                        ) : (
                            <div className="cm-key-pair-value">None</div>
                        )}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Location</div>
                    <div className="cm-key-pair-value">{wareHouseDetails?.location || '-'}</div>
                </div>
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`WareHouse : ${wareHouseDetails?.agentName}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
