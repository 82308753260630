import axios from 'axios';
import { ClientDocumentsDtoListContract } from '../../store/reducers/contractReducer';
import { get, post, put } from '../HttpMethods';
import fileDownload from 'js-file-download';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { fileUploadForDocuments } from './bookingProposal/additionalDetailsApi';
/* eslint-disable no-undefined */

export const fetchContractListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    filterType: string;
    id: string;
}) => {
    try {
        const data = await get(
            `api/mgt/clients/contracts?UUID=${payload.id}&limit=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortby=${payload.columnStatus || ''}&sortorder=${payload.columnOrder ? 'ASC' : 'DESC'}${
                payload.statusFilter !== null ? `&filterStatus=${payload.statusFilter}` : ''
            }${
                payload.filterType !== null && payload.filterType !== undefined
                    ? `&filterType=${payload.filterType}`
                    : ''
            }`,
            null
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createContractData = async (payload: ClientDocumentsDtoListContract) => {
    try {
        const data = await post('api/mgt/cd/contract', payload, null);
        const modifiedPayload: any = payload;
        const file = modifiedPayload?.get('file');
        if (file) {
            const document = modifiedPayload?.get('document');
            const text = await document.text();
            const or_PK = JSON.parse(text)?.or_PK;
            const formData = new FormData();
            formData.append('file', file, file.name);
            formData.append('DO_FK', or_PK);
            formData.append('DO_FKID', 'OR');
            formData.append('DO_CD_DocumentType', JSON.parse(text)?.cd_doctype === 'CONTRACT' ? 'HCN' : 'QUO');
            await fileUploadForDocuments(formData);
        }
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateContractData = async (payload: ClientDocumentsDtoListContract) => {
    try {
        // const data = await put(`api/mgt/cd/contract/${payload.cd_PK}`, payload);
        const data = await post(`/cix/scr/admin/organisation/client/contract`, payload, null, false, externalBaseUrl);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchContractAuthor = async () => {
    try {
        const data = await get('api/mgt/ad/forwardersignatory', null);
        const result = data.map((res: any) => ({ value: res.name, label: res.name }));
        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createDuplicateContract = async (carrierPK: any, contractPK: any) => {
    try {
        const data = await post(
            `/cix/scr/admin/carrier/${carrierPK}/contract/${contractPK}/?action=duplicate`,
            null,
            null,
            undefined,
            externalBaseUrl
        );
        return data;
    } catch (e) {
        return e;
    }
};

export const downloadFile = async (contract_pk: string, fileName: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/mgt/cd/download/${contract_pk}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchContractListData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    statusFilter: string;
    columnOrder: boolean;
    filterType: string;
    id: string;
    keyword: string;
}) => {
    try {
        const data = await get(
            `api/mgt/search/clientcontract?UUID=${payload.id}&keyword=${payload.keyword}&limit=${
                payload.perPageCount
            }&page=${payload.currentPage}&sortby=${payload.columnStatus || ''}&sortorder=${
                payload.columnOrder ? 'ASC' : 'DESC'
            }${payload.statusFilter !== null ? `&filterStatus=${payload.statusFilter}` : ''}${
                payload.filterType !== null && payload.filterType !== undefined
                    ? `&filterType=${payload.filterType}`
                    : ''
            }`,
            null
        );
        return data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getSingleContractData = async (payload: any) => {
    try {
        const data = await get(
            `/cix/scr/admin/organisation/client/${payload?.CD_CL}/contract/${payload?.CD_PK}`,
            null,
            externalBaseUrl
        );
        const res = data?.data?.[0];
        const mapped = {
            cd_docname: res?.CD_DocName,
            cd_doctype: res?.CD_DocType,
            cd_doclocation: res?.CD_DocLocation,
            cd_status: res?.CD_Status,
            cd_iteration: res?.CD_Iteration,
            cd_version: res?.CD_Version,
            cd_author: res?.CD_Author,
            cd_forwardersignatory: res?.CD_ForwarderSignatory,
            cd_startdate: res?.CD_StartDate,
            cd_enddate: res?.CD_EndDate,
            cd_clientacceptancedate: res?.CD_ClientAcceptanceDate,
            cd_clientsignatory: res?.CD_ClientSignatory,
            cd_renewal: res?.CD_Renewal,
            cd_services: res?.CD_Services,
            cd_reviewedby: res?.CD_ReviewedBy,
            cd_clientacceptedby: res?.CD_ClientAcceptedBy,
            cd_activedate: res?.CD_ActiveDate,
            fileName: res?.CD_FileName,
            savedFileSizeInKb: res?.savedFileSizeInKb,
            cd_PK: res?.CD_PK,
        };
        return mapped;
    } catch (err) {
        return console.error(err);
    }
};
