import React, { useEffect, useState } from 'react';
import '../../../../src/assets/scss/components/_layout.scss';
import '../../../../src/assets/scss/components/_modal.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DatePicker from '../../../components/common/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    clearFailure,
    fetchDatesPoList,
    // selectDatesPo,
    selectDatesPoError,
    selectDatesPoIsError,
    selectDatesPoSuccess,
    updateDatesPo,
} from '../../../store/reducers/purchaseOrder/datesPoReducer';
import { useParams } from 'react-router-dom';
import { error, Position, success } from '../../../utils/toast';
import moment from 'moment';
import useBrowserBackCheck from 'src/hooks/useBrowserBackCheck';
import { clearHeader, fetchHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
interface ModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
    multiDestination: boolean;
}

interface DatesPo {
    orderDate: string;
    startShipWindowDate: string;
    endShipWindowDate: string;
    goodsReadyDate: string;
    projectedEtaDate: string;
    createdDate: string;
}

const Index: React.FC<ModalProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const { id } = useParams();
    let multiDestination = {};
    if (!props.multiDestination) {
        multiDestination = {
            goodsReadyDate: Yup.date()
                .required('Goods ready date is required')
                .max(
                    Yup.ref('startShipWindowDate'),
                    'Goods ready date must be lesser than or equal to start shipping window'
                ),
            // .test({
            //     name: 'same',
            //     exclusive: false,
            //     params: {},
            //     message: 'Goods ready date must be lesser than start shipping window 2',
            //     test: function (value) {
            //         const activeDate = moment(this.parent.startShipWindowDate).format('YYYY-MM-DD');
            //         const endShipWindowDate = moment(value).format('YYYY-MM-DD');
            //         return !moment(activeDate).isSame(moment(endShipWindowDate));
            //     },
            // })
            projectedEtaDate: Yup.date()
                .required('Projected ETA into warehouse is required')
                .min(
                    Yup.ref('endShipWindowDate'),
                    'Projected ETA into warehouse must be greater than end shipping window'
                )
                .test({
                    name: 'same',
                    exclusive: false,
                    params: {},
                    message: 'Projected ETA into warehouse must be greater than end shipping window',
                    test: function (value) {
                        const activeDate = moment(this.parent.endShipWindowDate).format('YYYY-MM-DD');
                        const projectedEtaDate = moment(value).format('YYYY-MM-DD');
                        return !moment(activeDate).isSame(moment(projectedEtaDate));
                    },
                }),
        };
    }
    const DatesPoSchema = Yup.object().shape({
        orderDate: Yup.date().required('Order date is required'),
        startShipWindowDate: Yup.date()
            .required('Start shipping window is required')
            .min(Yup.ref('orderDate'), 'Start shipping window must be greater than order date')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'Start shipping window must be greater than order date',
                test: function (value) {
                    const activeDate = moment(this.parent.orderDate).format('YYYY-MM-DD');
                    const startShipWindowDate = moment(value).format('YYYY-MM-DD');
                    return !moment(activeDate).isSame(moment(startShipWindowDate));
                },
            }),
        endShipWindowDate: Yup.date()
            .required('End shipping window is required')
            .min(Yup.ref('startShipWindowDate'), 'End shipping window must be greater than start shipping window')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End shipping window must be greater than the start shipping window',
                test: function (value) {
                    const activeDate = moment(this.parent.startShipWindowDate).format('YYYY-MM-DD');
                    const endShipWindowDate = moment(value).format('YYYY-MM-DD');
                    return !moment(activeDate).isSame(moment(endShipWindowDate));
                },
            }),
        ...multiDestination,
    });

    const initialValues: DatesPo = {
        orderDate: props?.data?.orderDate || '',
        startShipWindowDate: props?.data?.startShipWindowDate || '',
        endShipWindowDate: props?.data?.endShipWindowDate || '',
        goodsReadyDate: props?.data?.goodsReadyDate || '',
        projectedEtaDate: props?.data?.projectedEtaDate || '',
        createdDate: props?.data?.createdDate ? moment(props?.data?.createdDate).format('DD-MM-YYYY') : '',
    };

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectDatesPoIsError);
    // const datesPo = useAppSelector(selectDatesPo);
    const datesPoSuccess = useAppSelector(selectDatesPoSuccess);
    const datesPoError = useAppSelector(selectDatesPoError);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: DatesPoSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                setIsLoading(true);
                dispatch(updateDatesPo({ ...values, id, type: props.data?.orderDate ? 'edit' : 'add' }));
            }
        },
    });

    useEffect(() => {
        if (formik) {
            if (new Date(formik.values.goodsReadyDate) > new Date(formik.values.startShipWindowDate)) {
                formik.setFieldValue('startShipWindowDate', null);
            }

            if (new Date(formik.values.startShipWindowDate) > new Date(formik.values.endShipWindowDate)) {
                formik.setFieldValue('endShipWindowDate', null);
            }

            if (new Date(formik.values.endShipWindowDate) > new Date(formik.values.projectedEtaDate)) {
                formik.setFieldValue('projectedEtaDate', null);
            }
        }
    }, [formik?.values]);

    useEffect(() => {
        setIsLoading(false);
        if (isError) {
            error(datesPoError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (datesPoSuccess?.status === 200 || datesPoSuccess?.status === 201) {
            success(
                `Dates have been ${datesPoSuccess?.data?.type === 'add' ? 'added' : 'updated'}`,
                Position.TOP_RIGHT
            );
            dispatch(fetchDatesPoList({ id }));
            dispatch(clearHeader({}));
            const payload = {
                poId: id,
            };
            dispatch(fetchHeader(payload));
            props.displayFunction(false);
            formik.resetForm();
            dispatch(clearFailure([]));
        }
    }, [isError, datesPoSuccess, datesPoError]);

    useEffect(() => {
        if (!props.display) {
            formik.resetForm();
        }
    }, [props.display]);

    const showError = (field: keyof typeof initialValues) => (
        <>{formik.errors[field] ? <div className="error-text">{formik.errors[field]}</div> : null}</>
    );
    // const checkValidation = () => {
    //     let flag = true;
    //     const formikshort = formik.values;
    //     if (
    //         formikshort.orderDate &&
    //         formikshort.startShipWindowDate &&
    //         formikshort.endShipWindowDate &&
    //         formikshort.goodsReadyDate &&
    //         formikshort.projectedEtaDate &&
    //         formik.errors &&
    //         formik.errors.constructor === Object &&
    //         Object.keys(formik.errors).length === 0
    //     ) {
    //         flag = false;
    //     }
    //     return flag;
    // };

    // useEffect(() => {
    //     window.history.pushState(null, document.title, window.location.href);

    //     const formCheck = () => {
    //         if (formik.dirty) {
    //             props.setDisplayCancel(true);
    //             window.history.pushState(null, document.title, window.location.href);
    //         } else {
    //             history.back();
    //         }
    //     };

    //     window.addEventListener('popstate', formCheck);
    //     return () => {
    //         window.removeEventListener('popstate', formCheck);
    //     };
    // }, [formik.dirty]);

    useBrowserBackCheck(formik.dirty, () => props.setDisplayCancel(true));

    return (
        <>
            {props.display ? (
                <>
                    <div className="app-modal purchase-detail-modal">
                        <div className="modal-content-holder medium-popup ">
                            <div className="modal-header">
                                <div className="title">Dates</div>
                                <button
                                    onClick={() => {
                                        {
                                            // props.displayFunction(false);
                                            // formik.resetForm();
                                            formik.dirty ? props.setDisplayCancel(true) : props.displayFunction(false);
                                        }
                                    }}
                                    className="app-btn modal-close-btn"
                                >
                                    <span>
                                        <svg className="svg-icon modal-header-close-icon">
                                            <use href="#closeIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div className="modal-content">
                                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                                    <svg className="svg-icon loader-icon">
                                        <use xlinkHref="#loaderIcon">
                                            <title>Loading</title>
                                        </use>
                                    </svg>
                                </div>
                                <div className="modal-main-content">
                                    <form
                                        id="my-form"
                                        action=""
                                        onSubmit={formik.handleSubmit}
                                        className="modal-form layout-holder one-column"
                                    >
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Order Date <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    // minDate={new Date()}
                                                    formik={formik}
                                                    fieldName="orderDate"
                                                    portalId="date-portal"
                                                    // disabled={formik.values.orderDate ? false : true}
                                                />
                                                {showError('orderDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                Start Shipping Window <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    minDate={
                                                        formik.values.orderDate
                                                            ? (() => {
                                                                  const minDate = new Date(formik.values.orderDate);
                                                                  minDate.setDate(minDate.getDate() + 1);
                                                                  return minDate;
                                                              })()
                                                            : new Date()
                                                    }
                                                    formik={formik}
                                                    fieldName="startShipWindowDate"
                                                    portalId="date-portal"
                                                    disabled={formik.values.orderDate ? false : true}
                                                />
                                                {showError('startShipWindowDate')}
                                            </div>
                                        </div>
                                        <div className=" modal-input-holder">
                                            <label className="modal-input-label">
                                                End Shipping Window <span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item calendar">
                                                <DatePicker
                                                    minDate={
                                                        formik.values.startShipWindowDate
                                                            ? (() => {
                                                                  const minDate = new Date(
                                                                      formik.values.startShipWindowDate
                                                                  );
                                                                  minDate.setDate(minDate.getDate() + 1);
                                                                  return minDate;
                                                              })()
                                                            : new Date()
                                                    }
                                                    formik={formik}
                                                    fieldName="endShipWindowDate"
                                                    portalId="date-portal"
                                                    disabled={formik.values.startShipWindowDate ? false : true}
                                                />
                                                {showError('endShipWindowDate')}
                                            </div>
                                        </div>
                                        {!props.multiDestination && (
                                            <>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Goods Ready Date <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item calendar">
                                                        <DatePicker
                                                            maxDate={
                                                                formik.values.startShipWindowDate
                                                                    ? new Date(formik.values.startShipWindowDate)
                                                                    : new Date()
                                                            }
                                                            formik={formik}
                                                            fieldName="goodsReadyDate"
                                                            portalId="date-portal"
                                                        />
                                                        {showError('goodsReadyDate')}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Projected ETA into Warehouse
                                                        <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item calendar">
                                                        <DatePicker
                                                            minDate={
                                                                formik.values.endShipWindowDate
                                                                    ? (() => {
                                                                          const minDate = new Date(
                                                                              formik.values.endShipWindowDate
                                                                          );
                                                                          minDate.setDate(minDate.getDate() + 1);
                                                                          return minDate;
                                                                      })()
                                                                    : new Date()
                                                            }
                                                            formik={formik}
                                                            fieldName="projectedEtaDate"
                                                            portalId="date-portal"
                                                            disabled={formik.values.endShipWindowDate ? false : true}
                                                        />
                                                        {showError('projectedEtaDate')}
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                    </form>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => {
                                        {
                                            // props.displayFunction(false);
                                            // formik.resetForm();
                                            formik.dirty ? props.setDisplayCancel(true) : props.displayFunction(false);
                                        }
                                    }}
                                    className="app-btn app-btn-secondary modal-btn footer-btn"
                                    title="Cancel"
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <button
                                    className="app-btn app-btn-primary modal-btn footer-btn "
                                    type="submit"
                                    form="my-form"
                                    title="Save"
                                    // disabled={
                                    //     !props.data?.orderDate
                                    //         ? checkValidation()
                                    //         : !formik.dirty ||
                                    //           (formik.errors &&
                                    //               formik.errors.constructor === Object &&
                                    //               Object.keys(formik.errors).length > 0)
                                    // }
                                >
                                    <span className="button-text footer-button-text">Save</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
