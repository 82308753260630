import { spawn } from 'redux-saga/effects';
import authSaga from './authSaga';
import clientSaga from './clientListSaga';
import shippingLineSaga from './shippingLineSaga';
import coloaderSaga from './profiles/coloader';
import basicDetailsSaga from './basicDetailsSaga';
import sopSaga from './sopSaga';
import contractSaga from './contractSaga';
import integrationDetailsSaga from './integrationdetails';
import agentSaga from './agentSaga';

// Sagas
import dataSaga from './data-saga';
import classbSaga from './classbSaga';
import forwarderSaga from './forwarderListSaga';
import vendorSaga from './vendorDetailsSaga';
import productSaga from './productSaga';
import countSaga from './countSaga';
import historySaga from './historySaga';
import vendorListSaga from './vendorListSaga';
import usersSaga from './usersSaga';
import createVendorSaga from './createVendorSaga';
import rolesSaga from './rolesSaga';
import agentBasicDetailsSaga from './profiles/agents/basicDetailsSaga';
import permissionSaga from './permissionSaga';
import linkClientVendorSaga from './linkClientVendorSaga';
import vendorIntegrationDetailsSaga from './vendorIntegrationSaga';
import agentPortDetailsSaga from './profiles/agents/portDetailsSaga';
import agentCommercialDetailsSaga from './profiles/agents/commercialDetailsSaga';
import profilesClientSaga from './profiles/clients';
import profilesVendorSaga from './profiles/vendors';
import shippingLineBasicDetailsSaga from './profiles/shippingLine/basicDetailsSaga';
import coloaderBasicDetailsSaga from './profiles/coloader/basicDetailsSaga';
import coloaderContractDetailsSaga from './profiles/coloader/contractDetailsSaga';
import shippingLineContractDetailsSaga from './profiles/shippingLine/contractDetailsSaga';
import poSaga from './purchaseOrder/poListSaga';
import purchaseSaga from './purchaseSagas/basicDetailsSaga';
import addPoSaga from './purchaseOrder/addPoSaga';
import datesPoSaga from './purchaseOrder/datesPoSaga';
import productPoSaga from './purchaseOrder/productPoSaga';
import productPoConfirmationSaga from './purchaseOrder/productPoConfirmationSaga';
import productDetailsConfirmationSaga from './purchaseOrder/productDetailsConfirmationSaga';
import containerModePoSaga from './purchaseOrder/containerModePoSaga';
import notificationsSaga from './notificationSaga';
import bpListSaga from './bookingProposal/bookingProposalListSaga';
import availablePoListSaga from './bookingProposal/availablePoSaga';
import availablePosBcnSaga from './bookingProposalBcn/availablePoSaga';
import addedPoListSaga from './bookingProposal/addedPoSaga';
import kendoSaga from './kendoSaga';
import qmsSaga from './qmsSaga';
import availablePoLineListSaga from './bookingProposal/availablePoLineSaga';
import availablePoLineListBcnSaga from './bookingProposalBcn/availablePoLineSaga';
import sailingScheduleSaga from './bookingProposal/sailingScheduleSaga';
import landSailingScheduleSaga from './bookingProposalLand/sailingScheduleSaga';
import additionalDetailsSaga from './bookingProposal/additionalDetailsSaga';
import bpHeaderSaga from './bookingProposal/bookingProposalHeaderSaga';
import nacAdditionalDetailsSaga from './bookingProposal/additionalDetailsNacSaga';
import containerAllocationSaga from './bookingProposal/containerAllocationSaga';
import attachmentSaga from './bookingProposal/attachmentSaga';
import aobpListSaga from './aobookingProposal/bookingProposalListSaga';
import aoavailablePoListSaga from './aobookingProposal/availablePoSaga';
import aoaddedPoListSaga from './aobookingProposal/addedPoSaga';
import aoavailablePoLineListSaga from './aobookingProposal/availablePoLineSaga';
import aosailingSaga from './aobookingProposal/sailingSaga.ts';
import aoadditionalDetailsSaga from './aobookingProposal/additionalDetailsSaga';
import aobpHeaderSaga from './aobookingProposal/bookingProposalHeaderSaga';
import aonacAdditionalDetailsSaga from './aobookingProposal/additionalDetailsNacSaga';
import aocontainerAllocationSaga from './aobookingProposal/containerAllocationSaga';
import aoattachmentSaga from './aobookingProposal/attachmentSaga';
import ReceiptpoLineSaga from './receipt/poLines';
import receiptListSaga from './receipts/receiptListSaga';
import addReceiptSaga from './receipts/addReceiptSaga';
import ReceiptattachmentSaga from './receipts/receiptAttachmentSaga';
import agentIntegrationDetailsSaga from './profiles/agents/integrationSaga';
import receiptHeaderSaga from './receipts/receiptHeaderSaga';
import InventoryListSaga from './inventory/inventoryListSaga';
import InventoryAdjustSaga from './inventory/inventoryAdjstSaga';
import addAoSaga from './allocationOrder/addAoSaga';
import aoBasicDetailsSaga from './allocationOrder/basicDetailsSaga';
import datesAoSaga from './allocationOrder/datesAoSaga';
import productAllocationSaga from './allocationOrder/productAllocationSaga';
import InventoryLogSaga from './inventory/inventoryLogSaga';
import allocationOrderListSaga from './allocationOrder/allocationOrderListSaga';
import autoApprovalSaga from './autoApprovalSaga';
import lclAvailablePoListSaga from './bookingProposalLcl/availablePoSaga';
import lclAvailablePoLineListSaga from './bookingProposalLcl/availablePoLineSaga';
import lclAddedPoListSaga from './bookingProposalLcl/addedPoSaga';
import lndAvailablePoListSaga from './bookingProposalLand/availablePoSaga';
import lndAvailablePoLineListSaga from './bookingProposalLand/availablePoLineSaga';
import lndAddedPoListSaga from './bookingProposalLand/addedPoSaga';
import additionalDetailsLndSaga from './bookingProposalLand/additionalDetailsSaga';
import settingsSaga from './settingsSaga';
import airAvailablePoListSaga from './bookingProposalAir/availablePosSaga';
import airAvailablePoLineListSaga from './bookingProposalAir/availablePoLineSaga';
import additionalDetailsLclSaga from './bookingProposalLcl/additionalDetailsSaga';
import addedPoListAirSaga from './bookingProposalAir/addedPoSaga';
import airScheduleBp from './bookingProposalAir/addSchedule';
import flightScheduleSaga from './bookingProposalAir/flightScheduleSaga';
import existingFlightSchedulesSaga from './bookingProposalAir/existingFlightSchedulesSaga';
import bpListAirSaga from './bookingProposalAir/listSaga';
import bookingDetailsSaga from './bookingProposalAir/bookingDetailsSaga';
import airAttachmentSaga from './bookingProposalAir/attachmentsSaga';
import shippingDetailsAirSaga from './bookingProposalAir/shippingDetailsSaga';
import mapSaga from './dashboard/map';
import quickLinksSaga from './dashboard/quickLinksSaga';
import shipmentPoSaga from './purchaseOrder/shipmentPoSaga';
import dashBoardSearchSaga from './dashboard/dashboardSearchSaga';
import dashBoardTodoSaga from './dashboard/dashboardTodoSaga';
import workTrendsSaga from './dashboard/workTrendsSaga';
import favouritesSaga from './dashboard/insightsFavoritesSaga';
import dashBoardActivitySaga from './dashboard/dashboardActivityFeed';
import dashBoardInsightsSaga from './dashboard/insightsSaga';
import purchaseOrderSaga from './reassignAgent/purchaseOrderSaga';
import bookingProposalSaga from './reassignAgent/bookingProposalSaga';
import approvalSaga from './reassignAgent/approvalSaga';
import trackingInsightsDataSaga from './tracking/trackingInsightsDataSaga';
import lifeCycleSearchSaga from './LifeCycleSearch/lifeCycleSearchSaga';
import lifecycleSearchFavouritesSaga from './LifeCycleSearch/lifeCycleSearchFavouritesSaga';
import attachmentHistorySaga from './attachmentHistorySaga';
import shipmentDetailsSaga from './shipmentDetailsSaga';
import lifecycleSaga from './lifecycleSagas/lifecycleSaga';
import vendorPortalSaga from './purchaseOrder/vendorPortalSaga';
import overrideAdditionalSaga from './bookingProposal/overrideShipmentSaga';
import documentTyoeSage from './documentTypeSaga';
import warehouseSaga from './warehouseSaga';

// Export the root saga
export default function* rootSaga() {
    yield spawn(dashBoardActivitySaga);
    yield spawn(dashBoardTodoSaga);
    yield spawn(qmsSaga);
    yield spawn(documentTyoeSage);
    yield spawn(dashBoardSearchSaga);
    yield spawn(dashBoardInsightsSaga);
    yield spawn(dataSaga);
    yield spawn(overrideAdditionalSaga);
    yield spawn(shipmentPoSaga);
    yield spawn(existingFlightSchedulesSaga);
    yield spawn(clientSaga);
    yield spawn(shippingLineSaga);
    yield spawn(coloaderSaga);
    yield spawn(vendorSaga);
    yield spawn(forwarderSaga);
    yield spawn(authSaga);
    yield spawn(basicDetailsSaga);
    yield spawn(sopSaga);
    yield spawn(contractSaga);
    yield spawn(integrationDetailsSaga);
    yield spawn(agentSaga);
    yield spawn(productSaga);
    yield spawn(countSaga);
    yield spawn(historySaga);
    yield spawn(vendorListSaga);
    yield spawn(classbSaga);
    yield spawn(usersSaga);
    yield spawn(createVendorSaga);
    yield spawn(rolesSaga);
    yield spawn(agentBasicDetailsSaga);
    yield spawn(permissionSaga);
    yield spawn(linkClientVendorSaga);
    yield spawn(vendorIntegrationDetailsSaga);
    yield spawn(agentPortDetailsSaga);
    yield spawn(agentCommercialDetailsSaga);
    yield spawn(shippingLineBasicDetailsSaga);
    yield spawn(coloaderBasicDetailsSaga);
    yield spawn(coloaderContractDetailsSaga);
    yield spawn(shippingLineContractDetailsSaga);
    yield spawn(poSaga);
    yield spawn(purchaseSaga);
    yield spawn(addPoSaga);
    yield spawn(datesPoSaga);
    yield spawn(lifecycleSaga);
    yield spawn(profilesClientSaga);
    yield spawn(profilesVendorSaga);
    yield spawn(productPoSaga);
    yield spawn(productPoConfirmationSaga);
    yield spawn(productDetailsConfirmationSaga);
    yield spawn(containerModePoSaga);
    yield spawn(notificationsSaga);
    yield spawn(bpListSaga);
    yield spawn(availablePoListSaga);
    yield spawn(addedPoListSaga);
    yield spawn(kendoSaga);
    yield spawn(availablePoLineListSaga);
    yield spawn(sailingScheduleSaga);
    yield spawn(additionalDetailsSaga);
    yield spawn(bpHeaderSaga);
    yield spawn(nacAdditionalDetailsSaga);
    yield spawn(containerAllocationSaga);
    yield spawn(attachmentSaga);
    yield spawn(ReceiptpoLineSaga);
    yield spawn(receiptListSaga);
    yield spawn(addReceiptSaga);
    yield spawn(ReceiptattachmentSaga);
    yield spawn(landSailingScheduleSaga);
    yield spawn(agentIntegrationDetailsSaga);
    yield spawn(receiptHeaderSaga);
    yield spawn(InventoryListSaga);
    yield spawn(InventoryAdjustSaga);
    yield spawn(addAoSaga);
    yield spawn(aoBasicDetailsSaga);
    yield spawn(datesAoSaga);
    yield spawn(productAllocationSaga);
    yield spawn(InventoryLogSaga);
    yield spawn(allocationOrderListSaga);
    yield spawn(aobpListSaga);
    yield spawn(aoavailablePoListSaga);
    yield spawn(aoaddedPoListSaga);
    yield spawn(aoavailablePoLineListSaga);
    yield spawn(aosailingSaga);
    yield spawn(aoadditionalDetailsSaga);
    yield spawn(aobpHeaderSaga);
    yield spawn(aonacAdditionalDetailsSaga);
    yield spawn(aocontainerAllocationSaga);
    yield spawn(aoattachmentSaga);
    yield spawn(autoApprovalSaga);
    yield spawn(lclAvailablePoListSaga);
    yield spawn(lclAvailablePoLineListSaga);
    yield spawn(lclAddedPoListSaga);
    yield spawn(lndAvailablePoListSaga);
    yield spawn(lndAvailablePoLineListSaga);
    yield spawn(lndAddedPoListSaga);
    yield spawn(settingsSaga);
    yield spawn(airAvailablePoListSaga);
    yield spawn(airAvailablePoLineListSaga);
    yield spawn(additionalDetailsLclSaga);
    yield spawn(additionalDetailsLndSaga);
    yield spawn(addedPoListAirSaga);
    yield spawn(airScheduleBp);
    yield spawn(flightScheduleSaga);
    yield spawn(bpListAirSaga);
    yield spawn(bookingDetailsSaga);
    yield spawn(airAttachmentSaga);
    yield spawn(shippingDetailsAirSaga);
    yield spawn(mapSaga);
    yield spawn(quickLinksSaga);
    yield spawn(workTrendsSaga);
    yield spawn(trackingInsightsDataSaga);
    yield spawn(favouritesSaga);
    yield spawn(purchaseOrderSaga);
    yield spawn(bookingProposalSaga);
    yield spawn(approvalSaga);
    yield spawn(lifeCycleSearchSaga);
    yield spawn(lifecycleSearchFavouritesSaga);
    yield spawn(availablePosBcnSaga);
    yield spawn(availablePoLineListBcnSaga);
    yield spawn(attachmentHistorySaga);
    yield spawn(shipmentDetailsSaga);
    yield spawn(vendorPortalSaga);
    yield spawn(warehouseSaga);
}
