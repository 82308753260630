import { get, patch, post } from '../../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export interface AvailablePoData {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    createdDate: string;
    clientName: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
    createdOn: string;
    totKGS: number;
    totCBM: number;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

export interface AvailablePoResponse {
    poListingDtos: AvailablePoData[];
}

export interface CreateBpResponse {
    success: boolean;
    info: {
        bpId: string;
        bpNumber: string;
        totalCBM: 46.0;
        totalKgs: 6.0;
        client: string;
        originPort: string;
        destinationPort: string;
        goodsReadyDate: string[];
        purchaseOrders: AvailablePoData[];
    };
}

export const contractCheck = async (payload: any) => {
    if (payload?.currentContractType === 'FAK') {
        return { isContractPresent: false, contractsDtoList: [] };
    }
    try {
        const res = await post(
            `api/bkg/bp/contractcheck?clientCode=${payload.clientCode}&bpId=${payload.bpid}`,
            payload?.postData,
            null
        );
        if (payload?.currentShippingLine) {
            const mapped = res.info?.contractsDtoList?.filter(
                (res: any) => res?.shippingLineCode === payload?.currentShippingLine
            );
            return { isContractPresent: res.info?.isContractPresent, contractsDtoList: mapped };
        }
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const sailingData = async (payload: any) => {
    try {
        const res = await post(
            `api/bkg/bp/sailing/list?sortOrder=ASC&sortColumn=${payload.sortColumn}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const sailingCount = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/sailing/count', payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const fakData = async (payload: any) => {
    if (payload?.currentContractType === 'NAC') {
        return [];
    }
    try {
        const currentShippingLine = payload?.currentShippingLine;
        delete payload?.currentShippingLine;
        const res = await post('api/bkg/bp/sailing/fak', payload, null);
        if (currentShippingLine) {
            return res.info?.sailingScheduleLists?.filter(
                (res: any) => res?.shippingline === currentShippingLine || res?.shippinglineCode === currentShippingLine
            );
        }
        return res.info?.sailingScheduleLists;
    } catch (err) {
        return console.error(err);
    }
};
export const updateSailing = async (bpId: string) => {
    try {
        const response = await patch(`cix/bkg/updateSailingSchedule/${bpId}`, null, externalBaseUrl);
        if (response?.data?.[0]?.message) {
            await post(`api/bkg/bp/sendPaylodtoCW?bpNums=${response?.data?.[0]?.message}`, null, null);
        }
        return { status: true, data: response };
    } catch (error: any) {
        return error;
    }
};

export const sailingAddToBp = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/sailing', payload, null);
        if (!payload?.sailingAddRequest?.isManuallyEntered) {
            await updateSailing(payload?.sailingAddRequest?.bookingProposalId);
        }
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const selectedSalilingData = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/sailing/schedule?bpId=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
export const removeSailingData = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/sailing/changeschedule?bpId=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getPorts = async () => {
    try {
        const res = await get('api/mgt/shippinglinecontract/portlist', null);
        const result = res?.info.map((res: any) => ({ value: res.sp_portcode, label: res.sp_portname }));
        return result;
    } catch (err) {
        return err;
    }
};

export const getExistingSchedules = async (payload: any) => {
    try {
        const res = await get(`api/bkg/sailingschedule/data?bpId=${payload}`, null);
        return res.info.sailingScheduleLists;
    } catch (err) {
        return err;
    }
};
