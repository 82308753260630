/* eslint-disable no-undefined */
import React, { useState, useEffect } from 'react';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
type Props = {
    title: string;
    keyName: string;
    quoteData: any;
    setQuoteData: any;
    quoteItemData: any;
    loading: any;
    saveTriggered?: boolean;
    isRequired?: boolean;
    menuPlacement?: any;
};

const QuoteItemSingleDropdownCard: React.FC<Props> = ({
    title,
    keyName,
    quoteData,
    setQuoteData,
    quoteItemData,
    loading,
    saveTriggered,
    isRequired,
    menuPlacement,
}) => {
    const [quoteItemDropdownList, setQuoteItemDropdownList] = useState<any>();

    useEffect(() => {
        if (quoteData?.[keyName]?.value) {
            setQuoteItemDropdownList(quoteData?.[keyName]?.value);
        }
    }, [quoteData?.[keyName]]);

    const getDescription = () => {
        return quoteItemData?.find((res: any) => res?.value === quoteItemDropdownList)?.label ?? '';
    };

    const addItem = (value: string) => {
        const filtered = quoteItemData?.find((res: any) => res?.value === value);
        const copy = JSON.parse(JSON.stringify(quoteData));
        copy[keyName] = { ...filtered };
        setQuoteData(copy);
        setQuoteItemDropdownList(value);
    };

    return (
        <div className="detail-box-common">
            <h6 className="details-box-heading-2">
                {title}{' '}
                {(isRequired && <span className="mandatory-field-text">*</span>) ||
                    (keyName === 'quoteSection' && <span className="mandatory-field-text">*</span>)}
            </h6>

            <div className="details-box-detail">
                <div className="details-box-detail-dropdown">
                    <SearchableSelectBox
                        data={quoteItemData}
                        placeholder="Choose one item"
                        setStateValue={addItem}
                        stateValue={quoteItemDropdownList}
                        fieldName={keyName}
                        isLoading={loading[keyName]}
                        overrideValue={
                            quoteItemDropdownList
                                ? { label: quoteItemDropdownList, value: quoteItemDropdownList }
                                : null
                        }
                        menuPlacement={menuPlacement}
                    />
                </div>
                <div className="details-box-detail-text">
                    <input
                        type="text"
                        readOnly
                        className="input-text details-box-detail-text-box"
                        value={getDescription()}
                    />
                </div>
            </div>
            {saveTriggered && keyName === 'quoteSection' && !quoteData?.[keyName]?.value && (
                <div className="error-text">This field is required</div>
            )}
        </div>
    );
};

export default QuoteItemSingleDropdownCard;
