import { RootState } from '../store/reducers';
import { useSelector } from 'react-redux';

const ShowForPermissions = (props: any) => {
    const { permissions } = useSelector((state: RootState) => state.permissionData) as any;
    const checkPermission = () => {
        let flag = false;
        if (props.overRide) {
            flag = true;
        } else {
            if (permissions && permissions?.length !== 0) {
                for (let i = 0; i < permissions?.length; i++) {
                    if (props?.exact === true) {
                        const value = permissions[i].access
                            .toLowerCase()
                            .split(/\s+|\./)
                            .slice(0, 3);

                        if (
                            value.includes(props.permission.toLowerCase()) &&
                            value.includes(props.type.toLowerCase()) &&
                            value?.length == 2
                        ) {
                            if (props?.item) {
                                if (permissions[i].type.toLowerCase() === props.item.toLowerCase()) {
                                    flag = true;
                                }
                            } else {
                                //return true;
                                flag = true;
                            }
                        }
                    } else {
                        if (
                            permissions[i].access
                                .toLowerCase()
                                .split(/\s+|\./)
                                .slice(0, 2)
                                .includes(props.permission.toLowerCase()) &&
                            permissions[i].access
                                .toLowerCase()
                                .split(/\s+|\./)
                                .slice(0, 2)
                                .includes(props.type.toLowerCase())
                        ) {
                            if (props?.item) {
                                if (permissions[i].type.toLowerCase() === props.item.toLowerCase()) {
                                    flag = true;
                                }
                            } else {
                                //return true;
                                flag = true;
                            }
                        }
                    }
                }
            }
        }

        return flag;
    };
    return checkPermission()
        ? props.children
        : // eslint-disable-next-line no-undefined
        permissions !== undefined && permissions.length > 0
        ? null
        : props.children;
};

export default ShowForPermissions;
