import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../../hooks';
import '../BookingAdditionalInfo/add-user-additional-modal.scss';
import '../../BookingProposal/BookingAddedGrid/cancel-booking-modal.scss';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import {
    fetchBcnParentBpList,
    updateBcnParentBp,
    reset,
} from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useParams } from 'react-router-dom';
import { Position, success, error } from 'src/utils/toast';
interface ModalProps {
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    bcnValue: string;
}
const Index: React.FC<ModalProps> = ({ setModalView, bcnValue }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const {
        bcnParentBp,
        bcnParentPk,
        clientCode,
        destinationPort,
        success: successBcn,
        successMessage,
        error: errorBcn,
    } = useSelector((state: RootState) => state.bpHeaderData);
    const [bcnType, setBcnType] = useState(bcnValue);
    const [parentBp, setParentBp] = useState(bcnParentPk?.toUpperCase() ?? '');
    const [parentBpList, setparentBpList] = useState(bcnParentBp ?? []);

    useEffect(() => {
        dispatch(fetchBcnParentBpList({ clientCode, destinationPort }));
    }, []);

    useEffect(() => {
        if (bcnParentBp) {
            const newList = bcnParentBp.filter((p: any) => p.value !== id);
            setparentBpList(newList);
        }
    }, [bcnParentBp]);

    useEffect(() => {
        if (bcnType === 'PAR') {
            setParentBp('');
        }
    }, [bcnType]);

    const submitBcnParentBp = () => {
        if (bcnType === 'PAR') {
            dispatch(updateBcnParentBp({ body: { BP_CD_BCNType: bcnType }, bpId: id }));
        } else {
            dispatch(updateBcnParentBp({ body: { BP_CD_BCNType: bcnType, BP_BP_Parent: parentBp }, bpId: id }));
        }
    };

    const buttonStatus = () => {
        if (bcnType === 'CLD') {
            return bcnType && parentBp ? true : false;
        } else {
            return bcnType ? true : false;
        }
    };

    useEffect(() => {
        const errorBcnCopy = JSON.parse(JSON.stringify(errorBcn));
        if (successBcn && successMessage?.type === 'changeType') {
            success(successMessage?.message || 'BCN updated successfully', Position.TOP_RIGHT);
            setModalView(false);
            setTimeout(() => {
                location.reload();
            }, 2000);
        } else if (errorBcnCopy && errorBcnCopy?.config?.data?.includes('BP_CD_BCNType')) {
            error(errorBcnCopy?.data?.message ?? 'Something Went Wrong', Position.TOP_RIGHT);
            dispatch(reset({}));
        }
    }, [successBcn, errorBcn]);

    return (
        <div className="app-modal additional-add-user-modal">
            <div className="modal-content-holder small-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Update BCN Type</div>
                    <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content agent-commercial-detail-modal-main-content">
                        {/* {bcnValue === 'PAR' && bcnType === 'CLD' && (
                            <div className="warning-text">
                                <div>
                                    <svg className="svg-icon error-icon">
                                        <use xlinkHref="#errorIcon">
                                            <title>Warning</title>
                                        </use>
                                    </svg>
                                </div>

                                <div className="error-text">
                                    Updating Parent to Child is an irreversible action. All attached children will be
                                    moved to Awaiting Consolidation
                                </div>
                            </div>
                        )} */}

                        <form id="my-form" action="" className="modal-form layout-holder one-column">
                            <div className="modal-input-holder">
                                <label className="modal-input-label">Type</label>
                                <div className="modal-input-layout-item">
                                    <div className="total-term-wrapper">
                                        <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                            <SearchableSelectBox
                                                data={[
                                                    { value: 'PAR', label: 'Parent' },
                                                    { value: 'CLD', label: 'Child' },
                                                ]}
                                                placeholder="Select a type"
                                                setStateValue={setBcnType}
                                                stateValue={bcnType}
                                                fieldName="type"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {bcnType === 'CLD' && (
                                <div className="modal-input-holder">
                                    <label className="modal-input-label">Parent Booking Proposal</label>
                                    <div className="modal-input-layout-item">
                                        <div className="total-term-wrapper">
                                            <div className="dropdown-wrap menu-down modal-dropdown search-toast-content-holder">
                                                <SearchableSelectBox
                                                    data={parentBpList}
                                                    placeholder="Select a Parent Booking Proposal"
                                                    setStateValue={setParentBp}
                                                    stateValue={parentBp}
                                                    fieldName="parentBp"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => setModalView(false)}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        title="Cancel"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                        title="Update"
                        disabled={buttonStatus() ? false : true}
                        onClick={() => submitBcnParentBp()}
                    >
                        <span className="button-text footer-button-text">Update</span>
                    </button>
                </div>
            </div>
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
        </div>
    );
};
export default Index;
