import { ReactElement, useState } from 'react';

interface FilterProps {
    closeToggle: any;
    dataList: string[];
    currentFilter: string;
    customWidth?: string;
    alignleft?: boolean;
}

const SpecialFilter = ({ closeToggle, dataList, currentFilter, alignleft }: FilterProps): ReactElement => {
    const [searchText, setSearchText] = useState('');
    const changeLetterCasing = (data: string) => {
        const splitStr = data?.toLowerCase().split('_');
        for (let i = 0; i < splitStr.length; i++) {
            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
        }

        return splitStr.join(' ');
    };

    const searchDataFn = () => {
        const searchKey = searchText.toLocaleLowerCase();
        const duplicate = dataList?.length ? JSON.parse(JSON.stringify(dataList)) : [];
        const searchResult = duplicate?.filter((el: any) => {
            return el?.toLocaleLowerCase().includes(searchKey);
        });
        return searchResult;
    };
    const searchData = searchDataFn();
    const splitItem = currentFilter?.split(',');
    const checkIfExists = (item: any) => {
        if (currentFilter) {
            if (splitItem.indexOf(item) !== -1) {
                return true;
            }
        }
        return false;
    };

    const updateFilter = (item: any) => {
        let newData = splitItem?.length ? JSON.parse(JSON.stringify(splitItem)) : [];
        if (checkIfExists(item)) {
            newData = newData.filter((x: string) => x !== item);
        } else {
            newData?.push(item);
        }
        closeToggle(newData?.length ? newData?.toString() : null);
    };

    return (
        <div
            onClick={(e) => {
                e.stopPropagation();
            }}
            className="filter-menu searchable-dropdown-common"
            style={{
                left:
                    currentFilter?.toLowerCase() === 'active' && window.location.pathname.includes('users') && alignleft
                        ? '-200px'
                        : '-5px',
            }}
        >
            <div className="search-entry-holder">
                <input
                    placeholder="Search"
                    type="text"
                    className="search-input input-text grid-search-field"
                    style={{ height: 'auto' }}
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                ></input>
                <svg className="svg-icon search-icon">
                    <use xlinkHref="#searchIcon"></use>
                </svg>
                <button
                    onClick={() => {
                        setSearchText('');
                    }}
                    className="app-btn text-close-btn"
                >
                    <svg className="svg-icon text-close-icon">
                        <use xlinkHref="#closeIcon"></use>
                    </svg>
                </button>
            </div>
            <div className="checkbox-wrapper">
                {searchData?.map((item: string, indx: number) => (
                    <div className="checkbox-item" key={indx}>
                        <label
                            onClick={(e) => {
                                updateFilter(item);
                                e.stopPropagation();
                            }}
                            className="app-check-wrapper"
                        >
                            <input
                                type="checkbox"
                                defaultChecked={false}
                                className="checkbox-input"
                                checked={checkIfExists(item)}
                            />
                            <div className="checkmark">
                                <svg className="svg-icon tick-icon">
                                    <use xlinkHref="#tickIcon">
                                        <title>check mark</title>
                                    </use>
                                </svg>
                            </div>
                            <div className="checkbox-label">{changeLetterCasing(item)}</div>
                        </label>
                    </div>
                ))}
                {searchData?.length === 0 && (
                    <div className="no-data-content">
                        <svg className="svg-icon grid-no-content-icon ">
                            <use xlinkHref="#gridNoContentIcon"></use>
                        </svg>
                        <p className="no-content-message">No Matches Found</p>
                    </div>
                )}
            </div>
            {dataList?.length > 0 && (
                <div className="clear-btn">
                    <button
                        onClick={() => {
                            setSearchText('');
                            closeToggle(null);
                        }}
                        className="app-btn"
                    >
                        <span className="button-text">Clear All</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export default SpecialFilter;
