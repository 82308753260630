import React, { useEffect, useRef, useState } from 'react';
import './tracking-insight.scss';
import InsightFavouritePanel from './InsightFavouritePanel';
import {
    favourite,
    fetchInsightsTilesList,
    // fetchInsightsList,
    selectTrackingInsightsIsError,
    // selectInsightsTiles,
    selectTrackingInsightsSuccess,
    selectTrackingInsightsError,
    selectFavourite,
    clearFailure,
    selectInsights,
    selectTrackingInsightsLoading,
    selectTrackingInsightsTileLoading,
} from 'src/store/reducers/tracking/trackingInsightsReducer';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { error, Position, success } from 'src/utils/toast';
import { useAppDispatch, useAppSelector } from 'src/hooks';

const Index: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { profileType, userName, roleName } = useUserProfile();
    const [showFavouritePanel, setShowFavouritePanel] = useState(false);
    const [insights, setInsights] = useState([]);
    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('All');

    const isFirstRender = useRef(true);

    const isError = useAppSelector(selectTrackingInsightsIsError);
    const isLoading = useAppSelector(selectTrackingInsightsLoading);
    const isTileLoading = useAppSelector(selectTrackingInsightsTileLoading);
    // const insightsTiles = useAppSelector(selectInsightsTiles);
    const insightsData = useAppSelector(selectInsights);
    const favouriteData = useAppSelector(selectFavourite);
    const trackingSuccess = useAppSelector(selectTrackingInsightsSuccess);
    const trackingError = useAppSelector(selectTrackingInsightsError);

    // useEffect(() => {
    //     dispatch(fetchInsightsTilesList({ userName, roleName, profile: profileType }));
    // }, []);

    // useEffect(() => {
    //     if (insightsTiles?.length) {
    //         dispatch(fetchInsightsList({ insightsTiles, userName, profile: profileType }));
    //     }
    // }, [insightsTiles.length]);

    useEffect(() => {
        if (insightsData?.length) {
            const favouriteDetails = favouriteData?.favourites ? JSON.parse(favouriteData?.favourites) : [];
            const newInsights = insightsData.map((res: any) => ({
                ...res,
                favouriteStatus: favouriteDetails?.some((e: any) => e.id === res.id),
                favouriteSort: favouriteDetails?.findIndex((e: any) => e.id === res.id),
            }));
            setInsights(newInsights);
        }
    }, [insightsData, favouriteData]);

    useEffect(() => {
        if (favouriteData?.favourites?.length && isFirstRender.current) {
            const favouriteDetails = JSON.parse(favouriteData?.favourites);
            isFirstRender.current = false;
            if (favouriteDetails?.length != 0) {
                setFilter('Favourites');
            }
        }
    }, [favouriteData?.favourites]);

    useEffect(() => {
        if (isError) {
            error(trackingError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (trackingSuccess?.status === 200 || trackingSuccess?.status === 201) {
            let message = '';
            if (trackingSuccess?.type === 'add') {
                message = 'Item has been added to the favourite list';
            } else if (trackingSuccess?.type === 'remove') {
                message = 'Item has been removed from the favourite list';
            } else if (trackingSuccess?.type === 'all') {
                message = 'Favourite list updated successfully';
                setShowFavouritePanel(false);
            }
            dispatch(fetchInsightsTilesList({ userName, roleName, profile: profileType }));
            success(message, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
    }, [isError, trackingSuccess, trackingError]);

    const onSearchFn = () => {
        if (search.trim().length >= 3 || filter) {
            const searchKey = search.toLocaleLowerCase();
            return insights?.filter((data: any) => {
                if (searchKey.trim().length >= 3 && filter !== 'All' && filter !== 'Favourites') {
                    return data.title.toLocaleLowerCase().includes(searchKey) && data?.type?.name === filter;
                } else if (searchKey.trim().length >= 3) {
                    if (filter === 'Favourites') {
                        return data?.title?.toLocaleLowerCase().includes(searchKey) && data?.favouriteStatus;
                    }
                    return data.title.toLocaleLowerCase().includes(searchKey);
                } else if (filter !== 'All') {
                    return filter === 'Favourites' ? data?.favouriteStatus : data.type.name === filter;
                } else {
                    return insights;
                }
            });
        } else {
            return insights;
        }
    };

    const onSearch = onSearchFn();

    const makeFavourite = (item: any) => {
        let mode = '';
        const insightsCopy = JSON.parse(JSON.stringify(insights));
        const itemIndex = insightsCopy.findIndex((res: any) => res.id === item.id);
        if (insightsCopy[itemIndex]?.favouriteStatus) {
            insightsCopy[itemIndex].favouriteStatus = false;
            mode = 'remove';
        } else {
            insightsCopy[itemIndex].favouriteStatus = true;
            mode = 'add';
        }

        let favouriteFinalResult;

        const favouriteData = insightsCopy
            ?.sort((a: any, b: any) => a.favouriteSort - b.favouriteSort)
            .map((res: any) => {
                if (res.favouriteStatus && res.id !== item.id) {
                    return {
                        title: res.title,
                        tab: res.type.name,
                        id: res.id,
                    };
                }
            })
            .filter(Boolean);

        if (mode === 'add') {
            favouriteFinalResult = [
                {
                    title: item.title,
                    tab: item.type.name,
                    id: item.id,
                },
                ...favouriteData,
            ];
        } else {
            favouriteFinalResult = [...favouriteData];
        }
        const payload = {
            favourites: JSON.stringify(favouriteFinalResult),
            type: mode,
            userName,
            profile: profileType,
        };
        setInsights(insightsCopy);
        dispatch(favourite({ ...payload }));
    };

    const getFavourites = () => {
        return insights
            .filter((res: any) => res.favouriteStatus)
            ?.sort((a: any, b: any) => a.favouriteSort - b.favouriteSort);
    };

    const hideTab = (tabName: string) => {
        if (insights?.length) {
            return insights?.some((e: any) => e.type.name === tabName);
        }
        return true;
    };

    return (
        <div className="tracking-insight-module">
            <div className="search-field-insight-menu-holder">
                <div className="insight-search-wrapper">
                    <div className="search-entry-holder">
                        <input
                            placeholder="Search"
                            type="text"
                            className="search-input input-text"
                            value={search}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)}
                        />
                        <div
                            className={`search-toolip ${
                                search?.length > 0 && search?.length < 3 ? 'tooltip-show' : ''
                            }`}
                        >
                            Enter atleast 3 characters to search
                        </div>
                        <svg className="svg-icon search-icon">
                            <use href="#searchIcon">
                                <title>Search</title>
                            </use>
                        </svg>
                        <button className="app-btn text-close-btn" onClick={() => setSearch('')}>
                            <svg className="svg-icon text-close-icon">
                                <use xlinkHref="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </button>
                    </div>
                    <div className="insight-menu-wrapper">
                        <button
                            className={`app-btn app-btn-secondary ${filter === 'All' ? 'active' : 'non-active'} `}
                            title="All"
                            type="button"
                            onClick={() => setFilter('All')}
                        >
                            <span className="button-text">All</span>
                        </button>

                        <button
                            className={`app-btn app-btn-secondary ${
                                filter === 'Favourites' ? 'active' : 'non-active'
                            } `}
                            title="Favourites"
                            type="button"
                            onClick={() => setFilter('Favourites')}
                        >
                            <span className="button-text ">Favourites</span>
                        </button>

                        {hideTab('General') && (
                            <button
                                className={`app-btn app-btn-secondary ${
                                    filter === 'General' ? 'active' : 'non-active'
                                } `}
                                title="General"
                                type="button"
                                onClick={() => setFilter('General')}
                            >
                                <span className="button-text ">General</span>
                            </button>
                        )}
                        {hideTab('Agents') && (
                            <button
                                className={`app-btn app-btn-secondary ${
                                    filter === 'Agents' ? 'active' : 'non-active'
                                } `}
                                title="Agents"
                                type="button"
                                onClick={() => setFilter('Agents')}
                            >
                                <span className="button-text ">Agents</span>
                            </button>
                        )}
                        {hideTab('Carriers') && (
                            <button
                                className={`app-btn app-btn-secondary ${
                                    filter === 'Carriers' ? 'active' : 'non-active'
                                } `}
                                title="Carriers"
                                type="button"
                                onClick={() => setFilter('Carriers')}
                            >
                                <span className="button-text ">Carriers</span>
                            </button>
                        )}
                        {hideTab('Clients') && (
                            <button
                                className={`app-btn app-btn-secondary ${
                                    filter === 'Clients' ? 'active' : 'non-active'
                                } `}
                                title="Clients"
                                type="button"
                                onClick={() => setFilter('Clients')}
                            >
                                <span className="button-text">Clients</span>
                            </button>
                        )}
                        {hideTab('IFC') && (
                            <button
                                className={`app-btn app-btn-secondary ${filter === 'IFC' ? 'active' : 'non-active'} `}
                                title="IFC"
                                type="button"
                                onClick={() => setFilter('IFC')}
                            >
                                <span className="button-text">IFC</span>
                            </button>
                        )}
                    </div>
                </div>
                {filter === 'Favourites' && getFavourites()?.length > 0 && (
                    <div>
                        <button
                            className="view-nav grid-icon icon-nav"
                            title="Settings"
                            onClick={() => setShowFavouritePanel(true)}
                        >
                            <svg className="svg-icon setting-icon ">
                                <use xlinkHref="#settingIcon">
                                    <title>Expand</title>
                                </use>
                            </svg>
                        </button>
                    </div>
                )}
            </div>
            <div className="insight-card-wrapper">
                {(isLoading || isTileLoading) && (
                    <div className="app-loader loading" style={{ zIndex: 200 }}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {onSearch
                    ?.sort((a: any, b: any) =>
                        filter === 'Favourites' ? a.favouriteSort - b.favouriteSort : a.sort - b.sort
                    )
                    ?.map((item: any, index: number) =>
                        item?.isComplete ? (
                            <div className="card insight-card" key={item.id}>
                                <a
                                    style={{ cursor: item?.id && item.detailPage?.length ? 'pointer' : 'auto' }}
                                    className="insight-tracking-card-link"
                                    onClick={() => {
                                        if (item?.id && item.detailPage?.length) {
                                            item?.id === 'nac-contract-utilisation-by-forwarder-agent-or-client'
                                                ? navigate(`/tst/allocatedteu`)
                                                : navigate(`/tracking/insight/${item.id}`);
                                        }
                                    }}
                                >
                                    <span className="sr-only">{item?.title}</span>
                                </a>
                                <div className="insight-sub-card-holder">
                                    <div className="card-contents">
                                        <div className="main-content-holder">
                                            <div className="details-box-content">{item?.title}</div>
                                            <div className="sub-detail-contents">
                                                <div className="details-box-heading">{item?.type?.name}</div>
                                                {item?.type?.subType && (
                                                    <div className="sub-detail">{item?.type?.subType}</div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="fav-star-wrapper">
                                            {/* .star-icon --.starred is using for to fill the star color */}
                                            {/* button - class:.selected-fav-icon and svg - class:.starred is used for selected star */}
                                            {/* button - class:.unselected-fav-icon*/}
                                            <button
                                                onClick={() => makeFavourite(item)}
                                                className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                                                    item.favouriteStatus ? 'selected-fav-icon' : 'unselected-fav-icon'
                                                }`}
                                                title={`${
                                                    item.favouriteStatus
                                                        ? 'Remove from Favourites'
                                                        : 'Add to Favourites'
                                                }`}
                                            >
                                                <svg
                                                    className={`svg-icon star-icon ${
                                                        item.favouriteStatus ? 'starred' : ''
                                                    }`}
                                                >
                                                    <use xlinkHref="#starIcon">
                                                        <title>Notifications</title>
                                                    </use>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>

                                    {item?.format === 'miniGrid' && (
                                        <>
                                            <div className="sub-content-type">
                                                <ol className="item-name">
                                                    {item?.data?.data?.slice(0, 3)?.map((res: any, index: number) => (
                                                        <li key={index} className="item-name-list">
                                                            {res[item.field1 || 0]}
                                                            <span className="list-count">
                                                                <b>{res[item.field2 || 0]}</b>
                                                                {res?.client ?? ''} {res?.quality ?? ''}
                                                                {res?.averageTime ? res?.averageTime + ' hrs' : ''}
                                                            </span>
                                                        </li>
                                                    ))}
                                                </ol>
                                            </div>
                                            {item?.additionalTags?.first && (
                                                <div className="sub-content-pill">
                                                    <span className="status-ui nac-status nac-p">
                                                        <div className="status-nac-left bcn-left">
                                                            {item?.additionalTags?.first}
                                                        </div>
                                                        <div
                                                            style={{ backgroundColor: item?.additionalTags?.color }}
                                                            className="status-nac-right bcn-p"
                                                        >
                                                            {item?.additionalTags?.second}
                                                        </div>
                                                    </span>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    {item?.format === 'count' && (
                                        <>
                                            <div className="sub-content-type">
                                                <div className="sub-content-heading">Total Count</div>
                                                <div className="sub-content-count">{item?.data?.total ?? '-'}</div>
                                            </div>
                                            <div className="sub-content-pill">
                                                <span className="status-ui nac-status nac-p">
                                                    <div className="status-nac-left bcn-left">
                                                        {item?.additionalTags?.first}
                                                    </div>
                                                    <div
                                                        style={{ backgroundColor: item?.additionalTags?.color }}
                                                        className="status-nac-right bcn-p"
                                                    >
                                                        {item?.additionalTags?.second}
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    )}
                                    {item.format === 'percentage' && (
                                        <>
                                            <div className="sub-content-type">
                                                <div className="sub-content-heading">Total Percentage</div>
                                                <div className="sub-content-count">
                                                    {item?.data?.percentage ?? '-'}%
                                                </div>
                                            </div>
                                            <div className="sub-content-pill">
                                                <span className="status-ui nac-status nac-p">
                                                    <div className="status-nac-left bcn-left">
                                                        {item?.additionalTags?.first}
                                                    </div>
                                                    <div
                                                        style={{ backgroundColor: item?.additionalTags?.color }}
                                                        className="status-nac-right bcn-p"
                                                    >
                                                        {item?.additionalTags?.second}
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    )}
                                    {item.format === 'percentageWithXY' && (
                                        <>
                                            <div className="sub-content-type">
                                                <div className="sub-content-heading">Total Percentage</div>
                                                <div className="sub-content-count">
                                                    {item?.data?.percentage ?? '-'}%
                                                    <span className="division-count">
                                                        ({item?.data?.x ?? '-'}/{item?.data?.y ?? '-'})
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="sub-content-pill">
                                                <span className="status-ui nac-status nac-p">
                                                    <div className="status-nac-left bcn-left">
                                                        {item?.additionalTags?.first}
                                                    </div>
                                                    <div
                                                        style={{ backgroundColor: item?.additionalTags?.color }}
                                                        className="status-nac-right bcn-p"
                                                    >
                                                        {item?.additionalTags?.second}
                                                    </div>
                                                </span>
                                            </div>
                                        </>
                                    )}
                                    {item?.format === 'ratio' && (
                                        <div className="sub-content-type">
                                            <div className="sub-content-heading">
                                                {item?.type?.displayFormTitle ?? 'Total Pass : Fail'} (
                                                {item?.data?.ratio?.period ?? '-'})
                                            </div>
                                            <div className="sub-content-count">
                                                <div className="condition-check">
                                                    <div className="check1">
                                                        <svg className="svg-icon badge-icon">
                                                            <use xlinkHref="#successgridBadgeIcon">
                                                                <title>Success</title>
                                                            </use>
                                                        </svg>
                                                        {item?.data?.ratio?.x ?? '-'}
                                                    </div>
                                                    <div className="separator">:</div>
                                                    <div className="check2">
                                                        <svg className="svg-icon circle-close-icon">
                                                            <use xlinkHref="#closecircleIcon">
                                                                <title>Remove</title>
                                                            </use>
                                                        </svg>
                                                        {item?.data?.ratio?.y ?? '-'}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="sub-content-pill">
                                                <span className="status-ui nac-status nac-p">
                                                    <div className="status-nac-left bcn-left">
                                                        {item?.additionalTags?.first}
                                                    </div>
                                                    <div
                                                        style={{ backgroundColor: item?.additionalTags?.color }}
                                                        className="status-nac-right bcn-p"
                                                    >
                                                        {item?.additionalTags?.second}
                                                    </div>
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div key={index} className="card insight-card shimmer-background"></div>
                        )
                    )}

                {/* <div className="card insight-card">
                    <a href="#" className="insight-tracking-card-link">
                        <span className="sr-only">On time PO Enrichment</span>
                    </a>
                    <div className="insight-sub-card-holder">
                        <div className="card-contents">
                            <div className="main-content-holder">
                                <div className="details-box-content">On time PO Enrichment</div>
                                <div className="sub-detail-contents">
                                    <div className="details-box-heading">General</div>
                                    <div className="sub-detail">
                                        KPI : <span>30 Days prior to ETD</span>
                                    </div>
                                </div>
                            </div>

                            <div className="fav-star-wrapper">
                                <button
                                    className="app-btn fav-icon-holder selected-fav-icon"
                                    title="Selected Favourite"
                                    onClick={() => setFavSettings(true)}
                                >
                                    <svg className="svg-icon star-icon starred">
                                        <use xlinkHref="#starIcon">
                                            <title>Notifications</title>
                                        </use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="sub-content-type">
                            <div className="sub-content-heading">Total Percentage</div>
                            <div className="sub-content-count">88%</div>
                        </div>
                    </div>
                </div> */}

                {/* <div className="card insight-card">
                    <a href="#" className="insight-tracking-card-link">
                        <span className="sr-only">BP approved by client</span>
                    </a>
                    <div className="insight-sub-card-holder">
                        <div className="card-contents">
                            <div className="main-content-holder">
                                <div className="details-box-content">BP approved by client</div>
                                <div className="sub-detail-contents">
                                    <div className="details-box-heading">Carriers</div>
                                    <div className="sub-detail">
                                        KPI : <span>14 Days prior to ETD</span>
                                    </div>
                                </div>
                            </div>

                            <div className="fav-star-wrapper">
                                <button
                                    className="app-btn fav-icon-holder unselected-fav-icon"
                                    title="Selected Favourite"
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Notifications</title>
                                        </use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="sub-content-type">
                            <div className="sub-content-heading">Total Pass : Fail (January 2023 )</div>
                            <div className="sub-content-count">
                                <div className="condition-check">
                                    <div className="check1">
                                        <svg className="svg-icon badge-icon">
                                            <use xlinkHref="#successgridBadgeIcon">
                                                <title>Success</title>
                                            </use>
                                        </svg>
                                        400
                                    </div>
                                    <div className="separator">:</div>
                                    <div className="check2">
                                        <svg className="svg-icon circle-close-icon">
                                            <use xlinkHref="#closecircleIcon">
                                                <title>Remove</title>
                                            </use>
                                        </svg>
                                        200
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="card insight-card">
                    <a href="#" className="insight-tracking-card-link">
                        <span className="sr-only">Arrival Ontime Performance</span>
                    </a>
                    <div className="insight-sub-card-holder">
                        <div className="card-contents">
                            <div className="main-content-holder">
                                <div className="details-box-content">Arrival Ontime Performance</div>
                                <div className="sub-detail-contents">
                                    <div className="details-box-heading">Clients</div>
                                    <div className="sub-detail">
                                        KPI : <span>ETA vs ATA</span>
                                    </div>
                                </div>
                            </div>

                            <div className="fav-star-wrapper">
                                <button
                                    className="app-btn fav-icon-holder unselected-fav-icon"
                                    title="Selected Favourite"
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Notifications</title>
                                        </use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="sub-content-type">
                            <div className="sub-content-heading">Average Days</div>
                            <div className="sub-content-count">40</div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="card insight-card">
                    <a href="#" className="insight-tracking-card-link">
                        <span className="sr-only">Open POs</span>
                    </a>
                    <div className="insight-sub-card-holder">
                        <div className="card-contents">
                            <div className="main-content-holder">
                                <div className="details-box-content">Open POs</div>
                                <div className="sub-detail-contents">
                                    <div className="details-box-heading">IFC</div>
                                </div>
                            </div>

                            <div className="fav-star-wrapper">
                                <button
                                    className="app-btn fav-icon-holder unselected-fav-icon"
                                    title="Selected Favourite"
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Notifications</title>
                                        </use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="sub-content-type">
                            <div className="sub-content-heading">Total Open POs</div>
                            <div className="sub-content-count">20</div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="card insight-card">
                    <a href="#" className="insight-tracking-card-link">
                        <span className="sr-only">Client profiles with contracts</span>
                    </a>
                    <div className="insight-sub-card-holder">
                        <div className="card-contents">
                            <div className="main-content-holder">
                                <div className="details-box-content">Client profiles with contracts</div>
                                <div className="sub-detail-contents">
                                    <div className="details-box-heading">Agent</div>
                                    <div className="sub-detail">
                                        KPI : <span>30 Days prior to ETD</span>
                                    </div>
                                </div>
                            </div>

                            <div className="fav-star-wrapper">
                                <button
                                    className="app-btn fav-icon-holder unselected-fav-icon"
                                    title="Selected Favourite"
                                >
                                    <svg className="svg-icon star-icon">
                                        <use xlinkHref="#starIcon">
                                            <title>Notifications</title>
                                        </use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="sub-content-type">
                            <div className="sub-content-heading">Total Percentage</div>
                            <div className="sub-content-count">
                                80%<span className="division-count">(8/10)</span>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {/* no favourite content */}
            {filter === 'Favourites' && onSearch?.length === 0 && (
                <div className="grid-no-content">
                    <div className="unlink-star-module">
                        <svg className="svg-icon grid-no-content-icon ">
                            <use xlinkHref="#unlinkStar"></use>
                        </svg>
                    </div>
                    <p className="no-content-message">No Favourites added</p>
                    <p className="no-content-message fav-content">
                        Insights that are added to favourites will be listed here
                    </p>
                </div>
            )}
            {filter !== 'Favourites' && onSearch?.length === 0 && (
                <div className="grid-no-content">
                    <p className="no-content-message">No insights found</p>
                </div>
            )}
            {showFavouritePanel && (
                <InsightFavouritePanel
                    modalHandle={setShowFavouritePanel}
                    data={getFavourites()}
                    modalOpen={showFavouritePanel}
                />
            )}
        </div>
    );
};
export default Index;
