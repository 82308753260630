import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    cancelDiscardAO,
    fetchAoData,
    fetchAoFilterData,
    fetchAoStatusCount,
    saveAoData,
    isLoadingFn,
} from 'src/store/reducers/allocationOrder/allocationOrderListReducer';
import {
    cancelOrDiscardAO,
    AODiscardCancelParams,
    AoResponse,
    getAoData,
    getAoFilterValues,
    getAoStatusCount,
} from 'src/services/apis/allocationOrder/allocationOrderListApi';

function* getAoListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const allocationOrders: AxiosResponse<AoResponse> = yield call(getAoData, payload);
        payload['allocationOrders'] = allocationOrders;
        yield put({ type: saveAoData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getAofilterData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const filterData: AxiosResponse<any[]> = yield call(getAoFilterValues, payload);
        payload['filterData'] = filterData;
        yield put({ type: fetchAoFilterData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getAoStatusData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const count: AxiosResponse<any[]> = yield call(getAoStatusCount, payload);
        payload['count'] = count;
        yield put({ type: fetchAoStatusCount, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* cancelDiscardAllocationOrder({ payload }: any) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(cancelOrDiscardAO, payload);
        payload['success'] = true;
        yield put({ type: cancelDiscardAO, payload: payload });
    } catch (error: any) {
        payload['success'] = false;
        payload['error'] = error?.data?.data || 'Something went wrong';
        yield put({ type: cancelDiscardAO, payload });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* allocationOrderListSaga() {
    // yield takeEvery(cancelDiscardAO, cancelDiscardAllocationOrder);
    yield takeEvery(fetchAoData, getAoListData);
    yield takeEvery(fetchAoFilterData, getAofilterData);
    yield takeEvery(fetchAoStatusCount, getAoStatusData);
}

export default allocationOrderListSaga;
