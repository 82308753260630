import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, patch, post } from '../../HttpMethods';

export interface AvailablePoData {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    createdDate: string;
    clientName: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
    createdOn: string;
    totKGS: number;
    totCBM: number;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

export interface AvailablePoResponse {
    poListingDtos: AvailablePoData[];
}

export interface CreateBpResponse {
    success: boolean;
    info: {
        bpId: string;
        bpNumber: string;
        totalCBM: 46.0;
        totalKgs: 6.0;
        client: string;
        originPort: string;
        destinationPort: string;
        goodsReadyDate: string[];
        purchaseOrders: AvailablePoData[];
    };
}
/* eslint-disable no-undefined */

export const getAddedPoLines = async (payload: any) => {
    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let profileType = '';
    if (userData && roleIndex) {
        const { roles } = JSON.parse(userData);
        profileType = roles[roleIndex].profile?.toLowerCase();
    }
    const bpID = payload?.bpID ? payload?.bpID : payload;
    const orgCode = payload?.orgCode && profileType === 'client' ? `&clientCode=${payload?.orgCode}` : '';
    try {
        const res = await get(
            `api/bkg/bp/added/polines${payload !== undefined ? `?bpId=${bpID}` : ''}${orgCode}`,
            null
        );
        //const newData = {
        //    success: true,

        //    info: {
        //        bookingProposalPoList: [
        //            {
        //                boId: '2e37a81d-6fa2-41aa-8903-886eb3e49c80',

        //                purchaseOrderId: {
        //                    poId: 'e50f6269-5bed-4297-adf8-10bc2fd7ee49',

        //                    poStatus: 'Partially_Booked',

        //                    poNumber: 'check uiii',

        //                    clientCode: 'EASTRONG',

        //                    clientName: 'EASTRONG INTERNATIONAL LOGISTICS',

        //                    vendorCode: 'NINMEGNGB',

        //                    vendorName: 'NINGBO MEGAFEAT BEDDING CO LTD',

        //                    agentCode: 'EASTRONG',

        //                    agentName: 'EASTRONG INTERNATIONAL LOGISTICS',

        //                    originPort: 'AUMEL',

        //                    originPortName: 'Melbourne',

        //                    destinationPortName: 'Sydney',

        //                    destinationPort: 'AUSYD',

        //                    isMultiAddress: false,

        //                    isTriangleShipment: false,

        //                    isHeaderOnlyPo: null,

        //                    includesAUPortDestination: null,

        //                    destinationAddress:
        //                        '80 NESTOR DRIVE, Express Hiway, North Richie, BIBRA LAKE, West Ima, 342',

        //                    promRefNo: null,

        //                    pickGroup: null,

        //                    brandName: null,

        //                    impVendorCode: null,

        //                    impShipmentRefNo: null,

        //                    distribOrderNo: null,

        //                    orderDate: '2023-08-17T18:30:00.000+00:00',

        //                    startShipWindowDate: '2023-08-19T18:30:00.000+00:00',

        //                    endShipWindowDate: '2023-08-24T18:30:00.000+00:00',

        //                    goodsReadyDate: '2023-07-30T18:30:00.000+00:00',

        //                    warehouseta: null,

        //                    totKGS: 1650.0,

        //                    totOrderLines: 1,

        //                    totCBM: 330.0,

        //                    totOrderValue: '726 AED',

        //                    dataSourceName: 'UI',

        //                    buyerUserId: null,

        //                    buyerUserName: null,

        //                    ordertype: {
        //                        orderTypeId: '54e137da-3f43-11ed-b878-0242ac120002',

        //                        orderTypeCode: 'IPO',

        //                        orderTypeName: 'International PO',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        modifiedDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    fclservicetype: {
        //                        fclServiceTypeId: '871ac5d6-0f41-11ed-861d-0242ac120002',

        //                        fclServiceTypeCode: 'DIR',

        //                        fclServiceTypeName: 'Direct',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        modifiedDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    incoTerm: {
        //                        incoTermId: 'f49c34de-0f43-11ed-861d-0242ac120001',

        //                        incoTermCode: 'CIP',

        //                        incoTermName: 'Carriage & Insurance Paid To',
        //                    },

        //                    releaseType: {
        //                        releaseTypeId: '4d50d594-20b2-4e61-9d3c-000830a5bbc3',

        //                        releaseTypeCode: 'CSH',

        //                        releaseTypeName: 'Company/Cashier Cheque',
        //                    },

        //                    paymentterms: {
        //                        paymentTermsId: '81e03bd2-6fef-11ed-a1eb-0242ac120002',

        //                        paymentTermsCode: 'CCX',

        //                        paymentTermsName: 'Collect',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        modifiedDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    shippingmode: {
        //                        shippingModeId: 'eb6c94c0-0f40-11ed-861d-0242bc120005',

        //                        shippingModeCode: 'SEA',

        //                        shippingModeName: 'Sea Freight',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2023-01-09T18:30:00.000+00:00',

        //                        modifiedDateTime: '2023-01-09T18:30:00.000+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    currency: null,

        //                    purchaseOrderProducts: [
        //                        {
        //                            poProductId: '58f01eef-613c-48b2-a7b7-532dfc6393b7',

        //                            sku: 'Fridge',

        //                            skuId: '9a7bdc6e-ea3a-435f-a556-8901a2dc164e',

        //                            hsCode: 'F45',

        //                            description: 'Quality Fridge',

        //                            countryCode: 'AF',

        //                            countryName: 'Afghanistan',

        //                            originPort: null,

        //                            originPortName: null,

        //                            origin: null,

        //                            destinationPort: null,

        //                            destinationPortName: null,

        //                            destination: null,

        //                            destinationAddress: null,

        //                            goodsReadyDate: null,

        //                            projWhouseETA: null,

        //                            cbm: 330.0,

        //                            qty: 33.0,

        //                            allocatedQty: null,

        //                            unitCost: 22.0,

        //                            netCost: 726.0,

        //                            currency: null,

        //                            destinationAddressIdInProduct: null,

        //                            weight: 1650.0,

        //                            productSplitLines: null,

        //                            isAllConfirmed: null,

        //                            isShortShipped: null,

        //                            poLineNumber: 1,

        //                            convertedQty: null,

        //                            uom: null,

        //                            barcodes: null,

        //                            conversionQuantity: null,

        //                            customerOrderReferenceNo: null,

        //                            customerOrderLineNo: null,

        //                            status: 'Ordered',

        //                            quantityType: 'Short Shipped',

        //                            isIntegration: null,

        //                            isShort: false,

        //                            inboundPoReferenceNos: null,

        //                            allocatedForAo: false,
        //                        },
        //                    ],

        //                    projectedEtaDate: '2023-09-01T18:30:00.000+00:00',

        //                    createdDate: '2023-08-17T00:26:42.943+00:00',

        //                    createdUser: 'Shane Watson',

        //                    createdUserId: 'shanewatson',

        //                    cancelledUser: null,

        //                    cancelReason: null,

        //                    cancelDate: null,

        //                    discardReason: null,

        //                    destinationAddressId: '0b145e9a-3fa6-4239-8a6b-b2014cbbd08f',

        //                    clientId: '7fd0547d-2d6f-47fc-82dd-22f35c8ef26c',

        //                    modifiedDateTime: '2023-08-17T00:58:15.807+00:00',

        //                    modifiedByUser: 'eastronguser@mailinator.com',

        //                    clientEmailId: null,

        //                    type: 'ITPO',

        //                    blpAddress: null,

        //                    blpAddressId: null,

        //                    blpAgentName: null,

        //                    blpAgentCode: null,

        //                    poOrderDate: null,

        //                    createdBy: null,
        //                },

        //                bookingProposalSubPOLineList: [
        //                    {
        //                        cgId: '95e3c401-e8ea-4a65-89b1-40b0d6a73972',

        //                        bookingProposalSubPOLineList: [
        //                            {
        //                                bsId: '0941be0e-bda1-4bf4-8f7a-dff3d11de831',

        //                                sku: 'Fridge',

        //                                skuId: '9a7bdc6e-ea3a-435f-a556-8901a2dc164e',

        //                                poLineNumber: 1,

        //                                hsCode: 'F45',

        //                                cgId: '95e3c401-e8ea-4a65-89b1-40b0d6a73972',

        //                                description: 'Quality Fridge',

        //                                poNumber: null,

        //                                cbm: 40.0,

        //                                qty: 4.0,

        //                                weight: 200.0,

        //                                createdBy: 'eastronguser@mailinator.com',

        //                                createdDateTime: '2023-08-17 06:28:15.437',

        //                                goodsReadyDate: '2023-08-23 00:00:00.0',

        //                                modifiedDateTime: '2023-08-17 06:28:15.437',

        //                                modifiedByUser: 'eastronguser@mailinator.com',

        //                                subPoLineId: {
        //                                    splitLineId: '8415e676-11f0-488a-865f-8bf3e040912f',

        //                                    splitLineStatus: 'In_Booking_Proposal',

        //                                    confirmedQty: 4.0,

        //                                    confirmedGoodsdate: null,

        //                                    confirmedCbm: 40.0,

        //                                    confirmedWeight: 200.0,

        //                                    poLineNumber: null,

        //                                    createdByUser: 'eastronguser@mailinator.com',

        //                                    createdDateTime: null,

        //                                    modifiedByUser: 'eastronguser@mailinator.com',

        //                                    modifiedDateTime: null,

        //                                    manuallyEntered: null,

        //                                    isConfirmed: true,

        //                                    remainingQuantity: 4.0,

        //                                    plannedReceiptQuantity: null,

        //                                    convertedQuantity: null,

        //                                    uom: null,

        //                                    originPort: null,

        //                                    destinationPort: 'AUSYD',

        //                                    destinationPortName: 'Sydney',

        //                                    destination: null,

        //                                    destinationAddress:
        //                                        '80 NESTOR DRIVE, Express Hiway, North Richie, BIBRA LAKE, West Ima, 342',
        //                                },

        //                                vendorName: null,

        //                                bookingNo: null,

        //                                client: null,
        //                            },
        //                        ],
        //                    },

        //                    {
        //                        cgId: '9cf94117-618a-4b3a-aedc-1fca44e27e25',

        //                        bookingProposalSubPOLineList: [
        //                            {
        //                                bsId: '4a40bd27-684c-4740-89e3-69fc8f08ce07',

        //                                sku: 'Fridge',

        //                                skuId: '9a7bdc6e-ea3a-435f-a556-8901a2dc164e',

        //                                poLineNumber: 1,

        //                                hsCode: 'F45',

        //                                cgId: '9cf94117-618a-4b3a-aedc-1fca44e27e25',

        //                                description: 'Quality Fridge',

        //                                poNumber: null,

        //                                cbm: 30.0,

        //                                qty: 3.0,

        //                                weight: 150.0,

        //                                createdBy: 'eastronguser@mailinator.com',

        //                                createdDateTime: '2023-08-17 06:28:15.437',

        //                                goodsReadyDate: '2023-08-18 00:00:00.0',

        //                                modifiedDateTime: '2023-08-17 06:28:15.437',

        //                                modifiedByUser: 'eastronguser@mailinator.com',

        //                                subPoLineId: {
        //                                    splitLineId: '8ae74b8f-0de4-41a7-b032-862f030e29ad',

        //                                    splitLineStatus: 'In_Booking_Proposal',

        //                                    confirmedQty: 3.0,

        //                                    confirmedGoodsdate: null,

        //                                    confirmedCbm: 30.0,

        //                                    confirmedWeight: 150.0,

        //                                    poLineNumber: null,

        //                                    createdByUser: 'eastronguser@mailinator.com',

        //                                    createdDateTime: null,

        //                                    modifiedByUser: 'eastronguser@mailinator.com',

        //                                    modifiedDateTime: null,

        //                                    manuallyEntered: null,

        //                                    isConfirmed: true,

        //                                    remainingQuantity: 3.0,

        //                                    plannedReceiptQuantity: null,

        //                                    convertedQuantity: null,

        //                                    uom: null,

        //                                    originPort: null,

        //                                    destinationPort: 'AUSYD',

        //                                    destinationPortName: 'Sydney',

        //                                    destination: null,

        //                                    destinationAddress:
        //                                        '80 NESTOR DRIVE, Express Hiway, North Richie, BIBRA LAKE, West Ima, 342',
        //                                },

        //                                vendorName: null,

        //                                bookingNo: null,

        //                                client: null,
        //                            },

        //                            {
        //                                bsId: '91dc1ba8-46af-4ad9-818f-fab302787e9b',

        //                                sku: 'Fridge',

        //                                skuId: '9a7bdc6e-ea3a-435f-a556-8901a2dc164e',

        //                                poLineNumber: 1,

        //                                hsCode: 'F45',

        //                                cgId: '9cf94117-618a-4b3a-aedc-1fca44e27e25',

        //                                description: 'Quality Fridge',

        //                                poNumber: null,

        //                                cbm: 100.0,

        //                                qty: 10.0,

        //                                weight: 500.0,

        //                                createdBy: 'eastronguser@mailinator.com',

        //                                createdDateTime: '2023-08-17 06:28:15.437',

        //                                goodsReadyDate: '2023-08-18 00:00:00.0',

        //                                modifiedDateTime: '2023-08-17 06:28:15.437',

        //                                modifiedByUser: 'eastronguser@mailinator.com',

        //                                subPoLineId: {
        //                                    splitLineId: '1116bc1a-f53f-435f-adfd-67111d1d3d18',

        //                                    splitLineStatus: 'In_Booking_Proposal',

        //                                    confirmedQty: 10.0,

        //                                    confirmedGoodsdate: null,

        //                                    confirmedCbm: 100.0,

        //                                    confirmedWeight: 500.0,

        //                                    poLineNumber: null,

        //                                    createdByUser: 'eastronguser@mailinator.com',

        //                                    createdDateTime: null,

        //                                    modifiedByUser: 'eastronguser@mailinator.com',

        //                                    modifiedDateTime: null,

        //                                    manuallyEntered: null,

        //                                    isConfirmed: true,

        //                                    remainingQuantity: 10.0,

        //                                    plannedReceiptQuantity: null,

        //                                    convertedQuantity: null,

        //                                    uom: null,

        //                                    originPort: null,

        //                                    destinationPort: 'AUSYD',

        //                                    destinationPortName: 'Sydney',

        //                                    destination: null,

        //                                    destinationAddress:
        //                                        '80 NESTOR DRIVE, Express Hiway, North Richie, BIBRA LAKE, West Ima, 342',
        //                                },

        //                                vendorName: null,

        //                                bookingNo: null,

        //                                client: null,
        //                            },
        //                        ],
        //                    },
        //                ],

        //                goodsReadyDate: '2023-07-30T18:30:00.000+00:00',

        //                poNumber: 'check uiii',

        //                vendorCode: null,

        //                vendorName: null,

        //                clientCode: 'EASTRONG',

        //                clientName: 'EASTRONG INTERNATIONAL LOGISTICS',

        //                isFullPO: null,

        //                createdDateTime: '2023-08-17T00:58:15.437+00:00',

        //                createdBy: 'eastronguser@mailinator.com',

        //                modifiedDateTime: '2023-08-17T00:58:15.437+00:00',

        //                modifiedByUser: 'eastronguser@mailinator.com',

        //                mannuallyEntered: null,
        //            },

        //            {
        //                boId: 'f927fb3a-259b-434a-b1b1-e4a2b0db5a19',

        //                purchaseOrderId: {
        //                    poId: '745b4e85-436a-488f-aac4-301c51d4500f',

        //                    poStatus: 'Partially_Booked',

        //                    poNumber: 'check v123',

        //                    clientCode: 'EASTRONG',

        //                    clientName: 'EASTRONG INTERNATIONAL LOGISTICS',

        //                    vendorCode: 'NINMEGNGB',

        //                    vendorName: 'NINGBO MEGAFEAT BEDDING CO LTD',

        //                    agentCode: 'EASTRONG',

        //                    agentName: 'EASTRONG INTERNATIONAL LOGISTICS',

        //                    originPort: 'AUMEL',

        //                    originPortName: 'Melbourne',

        //                    destinationPortName: 'Sydney',

        //                    destinationPort: 'AUSYD',

        //                    isMultiAddress: false,

        //                    isTriangleShipment: false,

        //                    isHeaderOnlyPo: null,

        //                    includesAUPortDestination: null,

        //                    destinationAddress:
        //                        '80 NESTOR DRIVE, Express Hiway, North Richie, BIBRA LAKE, West Ima, 342',

        //                    promRefNo: null,

        //                    pickGroup: null,

        //                    brandName: null,

        //                    impVendorCode: null,

        //                    impShipmentRefNo: null,

        //                    distribOrderNo: null,

        //                    orderDate: '2023-08-16T18:30:00.000+00:00',

        //                    startShipWindowDate: '2023-08-18T18:30:00.000+00:00',

        //                    endShipWindowDate: '2023-08-27T18:30:00.000+00:00',

        //                    goodsReadyDate: '2023-07-31T18:30:00.000+00:00',

        //                    warehouseta: null,

        //                    totKGS: 550.0,

        //                    totOrderLines: 1,

        //                    totCBM: 110.0,

        //                    totOrderValue: '121 AED',

        //                    dataSourceName: 'UI',

        //                    buyerUserId: null,

        //                    buyerUserName: null,

        //                    ordertype: {
        //                        orderTypeId: '54e137da-3f43-11ed-b878-0242ac120002',

        //                        orderTypeCode: 'IPO',

        //                        orderTypeName: 'International PO',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        modifiedDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    fclservicetype: {
        //                        fclServiceTypeId: '871ac5d6-0f41-11ed-861d-0242ac120002',

        //                        fclServiceTypeCode: 'DIR',

        //                        fclServiceTypeName: 'Direct',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        modifiedDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    incoTerm: {
        //                        incoTermId: 'f39c34de-0f43-11ed-861d-0242ac120009',

        //                        incoTermCode: 'CPT',

        //                        incoTermName: 'Carriage Paid To',
        //                    },

        //                    releaseType: {
        //                        releaseTypeId: '5d50d594-20b2-4e61-9d3c-000830a5bbc3',

        //                        releaseTypeCode: 'CAD',

        //                        releaseTypeName: 'Cash Against Document',
        //                    },

        //                    paymentterms: {
        //                        paymentTermsId: '81e03bd2-6fef-11ed-a1eb-0242ac120002',

        //                        paymentTermsCode: 'CCX',

        //                        paymentTermsName: 'Collect',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        modifiedDateTime: '2022-12-07T23:31:01.143+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    shippingmode: {
        //                        shippingModeId: 'eb6c94c0-0f40-11ed-861d-0242bc120005',

        //                        shippingModeCode: 'SEA',

        //                        shippingModeName: 'Sea Freight',

        //                        userDefinedNumeric1: null,

        //                        userDefinedNumeric2: null,

        //                        userDefinedNumeric3: null,

        //                        userDefinedNumeric4: null,

        //                        userDefinedDateTime1: null,

        //                        userDefinedDateTime2: null,

        //                        userDefinedDateTime3: null,

        //                        userDefinedDateTime4: null,

        //                        createdDateTime: '2023-01-09T18:30:00.000+00:00',

        //                        modifiedDateTime: '2023-01-09T18:30:00.000+00:00',

        //                        createdByUser: 'shanewatson',

        //                        modifiedByUser: 'shanewatson',

        //                        manuallyEntered: null,

        //                        ps: null,
        //                    },

        //                    currency: null,

        //                    purchaseOrderProducts: [
        //                        {
        //                            poProductId: 'b68c2b10-0906-47b4-a81a-8aae12fc0928',

        //                            sku: 'Fridge',

        //                            skuId: '9a7bdc6e-ea3a-435f-a556-8901a2dc164e',

        //                            hsCode: 'F45',

        //                            description: 'Quality Fridge',

        //                            countryCode: 'AF',

        //                            countryName: 'Afghanistan',

        //                            originPort: null,

        //                            originPortName: null,

        //                            origin: null,

        //                            destinationPort: null,

        //                            destinationPortName: null,

        //                            destination: null,

        //                            destinationAddress: null,

        //                            goodsReadyDate: null,

        //                            projWhouseETA: null,

        //                            cbm: 110.0,

        //                            qty: 11.0,

        //                            allocatedQty: null,

        //                            unitCost: 11.0,

        //                            netCost: 121.0,

        //                            currency: null,

        //                            destinationAddressIdInProduct: null,

        //                            weight: 550.0,

        //                            productSplitLines: null,

        //                            isAllConfirmed: null,

        //                            isShortShipped: null,

        //                            poLineNumber: 1,

        //                            convertedQty: null,

        //                            uom: null,

        //                            barcodes: null,

        //                            conversionQuantity: null,

        //                            customerOrderReferenceNo: null,

        //                            customerOrderLineNo: null,

        //                            status: 'Ordered',

        //                            quantityType: 'Surplus',

        //                            isIntegration: null,

        //                            isShort: false,

        //                            inboundPoReferenceNos: null,

        //                            allocatedForAo: false,
        //                        },
        //                    ],

        //                    projectedEtaDate: '2023-09-01T18:30:00.000+00:00',

        //                    createdDate: '2023-08-16T00:03:16.507+00:00',

        //                    createdUser: 'Shane Watson',

        //                    createdUserId: 'shanewatson',

        //                    cancelledUser: null,

        //                    cancelReason: null,

        //                    cancelDate: null,

        //                    discardReason: null,

        //                    destinationAddressId: '0b145e9a-3fa6-4239-8a6b-b2014cbbd08f',

        //                    clientId: '7fd0547d-2d6f-47fc-82dd-22f35c8ef26c',

        //                    modifiedDateTime: '2023-08-17T00:58:15.957+00:00',

        //                    modifiedByUser: 'eastronguser@mailinator.com',

        //                    clientEmailId: null,

        //                    type: 'ITPO',

        //                    blpAddress: null,

        //                    blpAddressId: null,

        //                    blpAgentName: null,

        //                    blpAgentCode: null,

        //                    poOrderDate: null,

        //                    createdBy: null,
        //                },

        //                bookingProposalSubPOLineList: [
        //                    {
        //                        cgId: '2908b17f-3c2b-4fb9-9401-f7489e1b3de0',

        //                        bookingProposalSubPOLineList: [
        //                            {
        //                                bsId: '3d699c60-d427-4dda-b656-897e116ddb6c',

        //                                sku: 'Fridge',

        //                                skuId: '9a7bdc6e-ea3a-435f-a556-8901a2dc164e',

        //                                poLineNumber: 1,

        //                                hsCode: 'F45',

        //                                cgId: '2908b17f-3c2b-4fb9-9401-f7489e1b3de0',

        //                                description: 'Quality Fridge',

        //                                poNumber: null,

        //                                cbm: 220.0,

        //                                qty: 22.0,

        //                                weight: 1100.0,

        //                                createdBy: 'eastronguser@mailinator.com',

        //                                createdDateTime: '2023-08-17 06:28:15.437',

        //                                goodsReadyDate: '2023-08-19 00:00:00.0',

        //                                modifiedDateTime: '2023-08-17 06:28:15.437',

        //                                modifiedByUser: 'eastronguser@mailinator.com',

        //                                subPoLineId: {
        //                                    splitLineId: '2fcb36ec-3eaf-48b8-8a90-597b0d2bb417',

        //                                    splitLineStatus: 'In_Booking_Proposal',

        //                                    confirmedQty: 22.0,

        //                                    confirmedGoodsdate: null,

        //                                    confirmedCbm: 220.0,

        //                                    confirmedWeight: 1100.0,

        //                                    poLineNumber: null,

        //                                    createdByUser: 'eastronguser@mailinator.com',

        //                                    createdDateTime: null,

        //                                    modifiedByUser: 'eastronguser@mailinator.com',

        //                                    modifiedDateTime: null,

        //                                    manuallyEntered: null,

        //                                    isConfirmed: true,

        //                                    remainingQuantity: 22.0,

        //                                    plannedReceiptQuantity: null,

        //                                    convertedQuantity: null,

        //                                    uom: null,

        //                                    originPort: null,

        //                                    destinationPort: 'AUSYD',

        //                                    destinationPortName: 'Sydney',

        //                                    destination: null,

        //                                    destinationAddress:
        //                                        '80 NESTOR DRIVE, Express Hiway, North Richie, BIBRA LAKE, West Ima, 342',
        //                                },

        //                                vendorName: null,

        //                                bookingNo: null,

        //                                client: null,
        //                            },
        //                        ],
        //                    },
        //                ],

        //                goodsReadyDate: '2023-07-31T18:30:00.000+00:00',

        //                poNumber: 'check v123',

        //                vendorCode: null,

        //                vendorName: null,

        //                clientCode: 'EASTRONG',

        //                clientName: 'EASTRONG INTERNATIONAL LOGISTICS',

        //                isFullPO: null,

        //                createdDateTime: '2023-08-17T00:58:15.437+00:00',

        //                createdBy: 'eastronguser@mailinator.com',

        //                modifiedDateTime: '2023-08-17T00:58:15.437+00:00',

        //                modifiedByUser: 'eastronguser@mailinator.com',

        //                mannuallyEntered: null,
        //            },
        //        ],

        //        gateInDateExpired: false,
        //    },
        //};

        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getSingleLine = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/products?clientCode=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getSingleLineProducts = async (_payload: any) => {
    try {
        const res = await get('api/mgt/clients/sku', null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const removePoLine = async (payload: any) => {
    try {
        console.log(payload, 'payloadvaluesss');
        const res = await post('api/bkg/bp/remove/subpolines?type=lnd', payload, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const bpApproveAction = async (action: string, reason: string, cgId: string, bpId?: any, coloader?: any) => {
    if (action === 'Cancel') {
        const response = await post(
            'api/bkg/bp/bpaction',
            { cgId: cgId, action: 'CAN', reason: reason, bpId: bpId },
            null
        );
        return response;
    } else if (action === 'Reject') {
        const response = await post(
            'api/bkg/bp/bpaction',
            { cgId: cgId, action: 'REJ', reason: reason, bpId: bpId },
            null
        );
        return response;
    } else if (action === 'Remove') {
        let coloaderCode: any;
        let coloaderName: any;
        if (coloader?.coloaderCode) {
            coloaderCode = coloader?.coloaderCode;
            coloaderName = coloader?.coloaderName;
        }
        const response = await patch(
            `cix/scr/forwarding/booking/${bpId}/confirmationgroup/${cgId}/DTH`,
            null,
            externalBaseUrl
        );
        return response;
    } else if (action === 'Approve') {
        const response = await post('api/bkg/bp/bpaction', { cgId: cgId, action: 'APR', reason: '', bpId: bpId }, null);
        return response;
    }
};

export const detachPoLine = async (payload: any) => {
    try {
        const res = await patch(`api/bkg/bp/detachPO/${payload.bpId}`, payload.body);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchAdded = async (payload: any) => {
    try {
        if (payload.type === 'lcl') {
            const res = await post(`api/ord/search/addedlcl?keyword=${payload.val}&bpId=${payload.id}`, payload, null);
            return res.info;
        } else if (payload?.type === 'bcn') {
            const res = await post(`api/ord/search/addedbcn?keyword=${payload.val}&bpId=${payload.id}`, payload, null);
            return res.info;
        } else {
            const res = await post(`api/ord/search/addedlnd?keyword=${payload.val}&bpId=${payload.id}`, payload, null);
            return res.info;
        }
    } catch (err) {
        return console.error(err);
    }
};
