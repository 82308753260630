import axios from 'axios';
import { get, post } from '../../HttpMethods';
import moment from 'moment';

export const fetchReassignPoListDetailsApi = async (payload: {
    profileType: string;
    sortColumn: string;
    sortOrder: string;
    body: any;
}) => {
    try {
        const body = {
            ...payload.body,
            poOrderDate: payload?.body?.poOrderDate?.map(
                (res: string) => moment(res, 'DD-MM-YYYY').format('YYYY-MM-DD') + ' 00:00:00.0'
            ),
        };
        const data = await post(
            `api/ord/po/list/${payload.profileType}?sortColumn=${payload.sortColumn}&sortOrder=${payload.sortOrder}`,
            { ...body, paginationRequired: false },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchDistinctPoListDetailsApi = async (payload: { profileType: string; body: any }) => {
    try {
        const data = await post(
            `api/ord/po/distinct/${payload.profileType}`,
            { ...payload.body, paginationRequired: false },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchAgentListDetailsApi = async (payload: { portCode: string; currentAgent: string }) => {
    try {
        const data = await get(
            `api/mgt/purchaseorder/agents?portcode=${payload.portCode}&currentAgent=${payload.currentAgent}`,
            null
        );

        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateAgentPoDetailsApi = async (payload: any) => {
    try {
        const data = await post('api/ord/po/reassign', payload, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
