import React, { useEffect, useState } from 'react';
import { error, Position, success } from 'src/utils/toast';

// import ProfileListing from 'src/components/common/ProfileListing';

import ContractModal from './ContractModal';
import ProfileaddModal from './profileaddModal';
import RemoveModal from './removeModal';
import '../co-loader-tab.scss';
import './contract-detail.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import TableWithFilter from './TableWithFilter';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    selectContractDetailUser,
    fetchContractDetailsList,
    // fetchContractDetailsColumnList,
    selectContractDetailCurrentPage,
    selectContractDetailColumnStatus,
    selectContractDetailtotalNoOfPages,
    selectContractDetailtotalNoOfItems,
    selectContractDetailLoading,
    clearContractDetails,
    selectContractDetailColumnUser,
    // ContractDetailsDocumentsDtoList,
    fetchAuthorDetails,
    // fetchAgentUsersDetails,
    fetchIncoTermsDetails,
    // fetchPortDetails,
    fetchAllClientsDetails,
    selectContractDetailAllClient,
    fetchForwarderUserDetails,
    fetchAgentUserDetails,
    searchContractDetailsData,
} from '../../../../store/reducers/profiles/coloader/ContractDetailsReducer';
import { paginationLimit } from '../../../../utils/constants';
import Pagination from '../../../../components/Pagination/Pagination';
// import { useMeasure } from 'react-use';
import ColumnFilter from '../../../../components/TableWithFilter/filter';
import { contractDetails as tableProperties } from '../../../../utils/tableStructure/coloader';
import CancelModal from '../../../../components/common/CancelModal';
import ShowForPermissions from '../../../../ShowForPermissions';
import {
    fetchColoaderBasicDetailsList,
    selectColoaderBasicDetails,
} from 'src/store/reducers/profiles/coloader/basicDetailsReducer';
import { activateColoader } from 'src/services/apis/profiles/coloader/contractDetailsApi';
import { fetchHeaderDataColoader } from 'src/store/reducers/profiles/coloader';
import HeaderTabsCoLoader from 'src/pages/Profiles/CoLoader/HeaderTabsCoLoader';

const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [activating, setActivating] = useState(false);

    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isFromView, setIsFromView] = useState(false);
    const [mode, setMode] = useState('add');
    const [displayCancel, setDisplayCancel] = useState(false);
    const [removeModalVisibility, setRemoveModalVisibility] = useState(false);
    const [addModalVisibility, setAddModalVisibility] = useState(false);
    const [buttonClicked, setButtonClicked] = useState(false);
    const contractDetails = useAppSelector(selectContractDetailUser);
    const contractDetailsColumn = useAppSelector(selectContractDetailColumnUser);
    const [contractDetailColumnData, setContractDetailColumnData] = useState<any>([]);
    const [contractDetailColumnName, setContractDetailColumnName] = useState<any>([]);
    const [contractData, setContractData] = useState({});
    const isLoading = useAppSelector(selectContractDetailLoading);
    const [selectedHeader, setSelectedHeader] = useState('sd_docname');
    const basicDetails = useAppSelector(selectColoaderBasicDetails);

    const currentPage = useAppSelector(selectContractDetailCurrentPage);
    const columnStatus = useAppSelector(selectContractDetailColumnStatus);
    const totalNoOfPages = useAppSelector(selectContractDetailtotalNoOfPages);
    const totalNoOfItems = useAppSelector(selectContractDetailtotalNoOfItems);
    const allClient = useAppSelector(selectContractDetailAllClient);
    const [toggleDropdown, setToggleDropdown] = useState('');
    const [PageCount, setPageCountDisplay] = useState(false);
    const [selectedFilter, _setselectedFilter] = useState<any | null>(null);
    const [currentOrder, setcurrentOrder] = useState(true);
    const [perPageCount, setPerPageCount] = useState(10);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>({});
    const [setPosition, setSetPosition] = useState<number>(0);
    // const [refName, { width: widthName }] = useMeasure<HTMLTableCellElement>();
    // const [refVersion, { width: widthVersion }] = useMeasure<HTMLTableCellElement>();
    // const [refAuthor, { width: widthAuthor }] = useMeasure<HTMLTableCellElement>();
    // const [refForwarderSignatory, { width: widthForwarderSignatory }] = useMeasure<HTMLTableCellElement>();
    // const [refShippingLineSignatory, { width: widthShippingLineSignatory }] = useMeasure<HTMLTableCellElement>();
    // const [refStatus, { width: widthStatus }] = useMeasure<HTMLTableCellElement>();
    // const [refStartDate, { width: widthStartDate }] = useMeasure<HTMLTableCellElement>();
    // const [refEndDate, { width: widthEndDate }] = useMeasure<HTMLTableCellElement>();
    // const [refRenewal, { width: widthRenewal }] = useMeasure<HTMLTableCellElement>();
    const [deletedClient, setDeletedClient] = useState<any>({});
    const [selectedClient, setSelectedClient] = useState<any>({});
    const [allClientsData, setAllClientsData] = useState<any>([]);
    const [primaryClientsData, setPrimaryClientsData] = useState<any>([]);
    const [secondaryClientsData, setSecondaryClientsData] = useState<any>([]);
    const [filterUsed, setFilterUsed] = useState('primary');
    const [searchTerm, setSearchTerm] = useState('');

    const payload = {
        currentPage: currentPage,
        perPageCount: perPageCount,
        columnStatus: selectedHeader,
        statusFilter: selectedFilter,
        columnOrder: !currentOrder,
        totalNoOfPages: totalNoOfPages,
        totalNoOfItems: totalNoOfItems,
        id,
    };

    useEffect(() => {
        dispatch(fetchColoaderBasicDetailsList({ id }));
        dispatch(clearContractDetails([]));
        dispatch(fetchAuthorDetails([]));
        dispatch(fetchForwarderUserDetails([]));

        // dispatch(fetchAgentUsersDetails([]));

        dispatch(fetchAgentUserDetails([]));

        dispatch(fetchIncoTermsDetails([]));
        // dispatch(fetchPortDetails([]));
        dispatch(fetchAllClientsDetails([]));
        dispatch(fetchHeaderDataColoader(id));
    }, []);

    useEffect(() => {
        if (contractDetailsColumn) {
            setContractDetailColumnData(
                (contractDetails as any)?.data?.info?.distinctColumnValues[contractDetailColumnName]
            );
            // setStatusFilterSelected({});
            setSearchName('');
        }
    }, [contractDetailsColumn, dispatch]);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        // if (keyword) {
        // const results = contractDetailsColumn?.info.filter((user: string) => {
        //     return user && user.toLowerCase().includes(keyword.toLowerCase());
        // });
        // setContractDetailColumnData(
        //     (contractDetails as any)?.data?.info?.distinctColumnValues[contractDetailColumnName]
        // );
        // } else {
        // setContractDetailColumnData(
        //     (contractDetails as any)?.data?.info?.distinctColumnValues[contractDetailColumnName]
        // );
        // }
        setSearchName(keyword);
    };

    useEffect(() => {
        dispatch(
            fetchContractDetailsList({
                ...payload,
                id,
                currentPage: 0,
                columnStatus: selectedHeader,
                statusFilterColumn: contractDetailColumnName,
                statusFilter: statusFilterSelected,
                columnOrder: !currentOrder,
            })
        );
    }, [statusFilterSelected]);

    const [currentPageNumber, setCurrentPageNumber] = useState<null | number>(null);

    useEffect(() => {
        if (currentPageNumber) {
            if (searchTerm?.trim()?.length > 1) {
                //const payload = {
                //    keyword: searchTerm,
                //    id,
                //    currentPage: 0,
                //    columnStatus: selectedHeader,
                //    statusFilterColumn: contractDetailColumnName,
                //    statusFilter: statusFilterSelected,
                //    columnOrder: !currentOrder,
                //};
                //dispatch(searchContractDetailsData(payload));
            } else if (searchTerm?.trim()?.length === 0) {
                dispatch(
                    fetchContractDetailsList({
                        ...payload,
                        currentPage: currentPageNumber - 1,
                        perPageCount: perPageCount,
                        columnStatus: selectedHeader,
                        // statusFilter: selectedFilter,
                        columnOrder: !currentOrder,
                        statusFilterColumn: contractDetailColumnName,
                        statusFilter: statusFilterSelected,
                    })
                );
            }
        }
    }, [currentPageNumber]);

    const sortByColumn = (columnName: string) => {
        setcurrentOrder(!currentOrder);
        setSelectedHeader(columnName);
        dispatch(
            fetchContractDetailsList({
                ...payload,
                currentPage: 0,
                columnStatus: columnName,
                columnOrder: currentOrder,
                statusFilterColumn: contractDetailColumnName,
                statusFilter: statusFilterSelected,
            })
        );
    };
    const filterByColumn = (e: any, columnName: string) => {
        // if (!toggleDropdown && !contractDetailColumnName.includes(columnName)) {
        setContractDetailColumnName([...contractDetailColumnName, columnName]);
        setTimeout(() => {
            setToggleDropdown(columnName);
            setContractDetailColumnData((contractDetails as any)?.data?.info?.distinctColumnValues[columnName]);
        }, 250);
        //dispatch(
        //    fetchContractDetailsColumnList({
        //        id,
        //        columnName,
        //    })
        //);
        // }

        filterDistance(columnName);
        setSearchName('');
        filter(e);
        e.stopPropagation();
    };

    const filterDistance = (columnName: string) => {
        const el = document.getElementById(columnName);
        const distance = el?.getBoundingClientRect().left;
        distance && setSetPosition(distance - 30);
    };

    useEffect(() => {
        if (searchTerm?.trim()?.length > 2) {
            const payload = {
                keyword: searchTerm,
                id,
                currentPage: currentPage,
                columnStatus: selectedHeader,
                statusFilterColumn: contractDetailColumnName,
                columnOrder: currentOrder,
                perPageCount: perPageCount,
                statusFilter: selectedFilter,
            };
            dispatch(searchContractDetailsData(payload));
        } else if (searchTerm?.trim()?.length === 0) {
            dispatch(
                fetchContractDetailsList({
                    ...payload,
                    currentPage: 0,
                })
            );
        }
    }, [searchTerm]);

    const contractListLength = contractDetails?.data?.info?.coLoaderDocumentResponseList?.length;

    const activeContracts = basicDetails?.data?.info?.coLoaderOrganisation?.or_Status === 'ACTIVE';

    const inactiveContract = basicDetails?.data?.info?.coLoaderOrganisation?.or_Status === 'INACTIVE';

    const handleActivateColoader = async () => {
        setActivating(true);
        const response = await activateColoader(id ?? '');
        if (response.success) {
            success('Coloader has been activated', Position.TOP_RIGHT);
            navigate('/coloader');
        } else {
            error(response.message || 'Something went wrong', Position.TOP_RIGHT);
        }
        setActivating(false);
    };

    return (
        <div
            onClick={() => {
                setPageCountDisplay(false);
            }}
            className="main-wrapper container"
        >
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link to="/coloader" className="dropdown-arrow" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link to="/profile" className="breadcrumb-item">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to="/coloader" className="breadcrumb-item" state={{ reload: true }}>
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Coloader
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    <h2 className="page-heading">{basicDetails?.data?.info?.coLoaderOrganisation?.or_Orgname}</h2>
                </div>
                <div className="arrow-tab-container">
                    <div className="arrow-tab-holder">
                        {/* <Link
                            aria-current="page"
                            to={`/coloader/${id}/basic_detail`}
                            className="arrow-tab-border shipping-basic-tab"
                        >
                            <div className="arrow-badge">1</div>
                            <div className="arrow-tab">Basic Details</div>
                        </Link>
                        <Link
                            to={`/coloader/${id}/contract_detail`}
                            className="arrow-tab-border active shipping-contract-tab"
                        >
                            <div className="arrow-badge">2</div>
                            <div className="arrow-tab ">Contract Details</div>
                        </Link> */}
                        {/* <a className="arrow-tab-border shipping-price-tab" href="#">
                            <div className="arrow-tab">Pricing Schedule</div>
                        </a> */}
                        <HeaderTabsCoLoader />
                    </div>
                </div>
            </div>
            <div className="grid-section coloader-contract-grid loader-container grid-p-0-15">
                {(isLoading || activating) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="grid-holder">
                    <div className="grid-header">
                        <div className="search-entry-holder">
                            <input
                                placeholder="Search Contract Details"
                                type="text"
                                className="search-input input-text"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            ></input>
                            <div
                                className={`search-toolip ${
                                    searchTerm?.length > 0 && searchTerm?.length < 3 ? 'tooltip-show' : ''
                                }`}
                            >
                                Enter atleast 3 characters to search
                            </div>
                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon"></use>
                            </svg>
                            <button
                                className="app-btn text-close-btn"
                                onClick={() => {
                                    if (searchTerm?.trim()?.length) {
                                        setSearchTerm('');
                                        dispatch(
                                            fetchContractDetailsList({
                                                ...payload,
                                                currentPage: 0,
                                                perPageCount: perPageCount,
                                                columnStatus: selectedHeader,
                                                statusFilter: selectedFilter,
                                                columnOrder: currentOrder,
                                            })
                                        );
                                    }
                                }}
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </div>
                        <div className="item-count-status-holder">
                            <div className="grid-item-count-status status-ui success-status">
                                <span className="count">{contractDetails?.data?.info?.totalActiveDocuments}</span>
                                <span className="status-text">
                                    Active{' '}
                                    {contractDetails?.data?.info?.totalActiveDocuments === 1 ? 'Contract' : 'Contracts'}
                                </span>
                            </div>
                        </div>
                        <ShowForPermissions permission="edit" type="coloader">
                            <button
                                onClick={() => {
                                    if (buttonClicked) {
                                        setModalView(true);
                                    } else {
                                        // setTimeout(() => {
                                        setModalView(true);
                                        // }, 700);
                                        setButtonClicked(true);
                                    }
                                    setIsEdit(false);
                                    setMode('add');
                                    setContractData({});
                                }}
                                className="app-btn app-btn-secondary icon-button grid-top-btn"
                                title="Add Contracts"
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <use href="#plusIcon"></use>
                                </svg>
                                <span className="button-text client-button-text">Add Contract</span>
                            </button>
                        </ShowForPermissions>
                    </div>
                    <div
                        onClick={() => setToggleDropdown('')}
                        onScroll={() => filterDistance('')}
                        className="grid-container"
                    >
                        {Boolean(contractListLength === 0) && (
                            <div className="grid-no-content">
                                <svg className="svg-icon grid-no-content-icon">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">No Contract Details Available</p>
                            </div>
                        )}
                        <div className="grid-scroll-content">
                            <TableWithFilter
                                name="contractDetails"
                                tableProperties={tableProperties}
                                data={contractDetails?.data?.info?.coLoaderDocumentResponseList}
                                setData={setContractData}
                                setIsEdit={setIsEdit}
                                setModalView={setModalView}
                                sortFunction={sortByColumn}
                                columnStatus={columnStatus}
                                currentOrder={currentOrder}
                                toggleDropdown={toggleDropdown}
                                columnName={toggleDropdown}
                                filterFunction={filterByColumn}
                                selectedFilterItem={statusFilterSelected}
                                duplicateAction={false}
                            />
                        </div>
                    </div>
                    {toggleDropdown && (
                        <ColumnFilter
                            setPosition={setPosition}
                            searchName={searchName}
                            setSearchName={setSearchName}
                            filter={filter}
                            data={contractDetailColumnData}
                            statusFilterSelected={statusFilterSelected[toggleDropdown] ?? []}
                            setStatusFilterSelected={(values: any) => {
                                const data = { ...statusFilterSelected, [toggleDropdown]: values };
                                setStatusFilterSelected(data);
                            }}
                            setToggleDropdown={() => setToggleDropdown('')}
                            isLoading={isLoading}
                        />
                    )}
                    {totalNoOfItems > 0 ? (
                        <div className="grid-footer ">
                            <div className="grid-footer-left">
                                <div className="footer-text">Showing</div>
                                <div className="row-per-page">
                                    <div
                                        className={
                                            'dropdown-wrap pagination-dropdown menu-up ' +
                                            (PageCount ? 'dropdown-open' : '')
                                        }
                                    >
                                        {/* <div className="dropdown-wrap pagination-dropdown menu-up dropdown-open"> */}
                                        <button
                                            className="menu-btn app-btn"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setPageCountDisplay(!PageCount);
                                            }}
                                        >
                                            <span className="btn-text">
                                                {contractListLength && perPageCount > contractListLength
                                                    ? contractListLength
                                                    : perPageCount}
                                            </span>
                                            <span className="dropdown-arrow">
                                                <svg className="svg-icon arrow-icon">
                                                    <use xlinkHref="#downArrow">
                                                        <title>dropdown</title>
                                                    </use>
                                                </svg>
                                            </span>
                                        </button>
                                        {PageCount && (
                                            <div className="dropdown-menu">
                                                {paginationLimit.map((res, index: number) => (
                                                    <a
                                                        key={index}
                                                        onClick={(e) => {
                                                            setPageCountDisplay(false);
                                                            e.stopPropagation();
                                                            setPerPageCount(res);
                                                            dispatch(
                                                                fetchContractDetailsList({
                                                                    ...payload,
                                                                    currentPage: 0,
                                                                    perPageCount: res,
                                                                    columnStatus: selectedHeader,
                                                                    // statusFilter: selectedFilter,
                                                                    columnOrder: !currentOrder,
                                                                    statusFilterColumn: contractDetailColumnName,
                                                                    statusFilter: statusFilterSelected,
                                                                })
                                                            );
                                                        }}
                                                        className="menu-item app-btn"
                                                    >
                                                        {res}
                                                    </a>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="footer-text">
                                    <span>of</span>
                                    <span className="grid-total-row-count">{totalNoOfItems ? totalNoOfItems : 0}</span>
                                    <span>{totalNoOfItems > 1 ? 'entries' : 'entry'}</span>
                                </div>
                            </div>
                            <div className="grid-footer-right">
                                <div className="grid-pagination">
                                    <Pagination
                                        currentPage={currentPage + 1}
                                        totalCount={totalNoOfItems}
                                        pageSize={perPageCount}
                                        onPageChange={(page: number) => setCurrentPageNumber(page)}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                        to={`/coloader/${id}/basic_detail`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>

                    {inactiveContract && (
                        <ShowForPermissions permission="edit" type="coloader">
                            <button
                                className="app-btn app-btn-primary footer-btn"
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleActivateColoader();
                                }}
                                disabled={!contractListLength}
                            >
                                <span className="button-text footer-button-text">Submit and Activate</span>
                            </button>
                        </ShowForPermissions>
                    )}
                    {activeContracts && (
                        <Link className="app-btn app-btn-primary footer-btn" title="Back to profile" to={`/coloader`}>
                            <span className="button-text footer-button-text">Back to profile</span>
                        </Link>
                    )}
                </div>
            </div>
            <ContractModal
                display={modalView}
                displayFunction={setModalView}
                isEdit={isEdit}
                setIsEdit={setIsEdit}
                data={contractData}
                setData={setContractData}
                isFromView={isFromView}
                setIsFromView={setIsFromView}
                mode={mode}
                setMode={setMode}
                selectedHeader={selectedHeader}
                selectedFilter={selectedFilter}
                perPageCount={perPageCount}
                setDisplayCancel={setDisplayCancel}
                setDeletedClient={setDeletedClient}
                setSelectedClient={setSelectedClient}
                setAddModalVisibility={setAddModalVisibility}
                setRemoveModalVisibility={setRemoveModalVisibility}
                allClientsData={allClientsData}
                setAllClientsData={setAllClientsData}
                primaryClientsData={primaryClientsData}
                setPrimaryClientsData={setPrimaryClientsData}
                secondaryClientsData={secondaryClientsData}
                setSecondaryClientsData={setSecondaryClientsData}
                setFilterUsed={setFilterUsed}
                filterUsed={filterUsed}
                clientData={selectedClient}
            />
            <ProfileaddModal
                addModalVisibility={addModalVisibility}
                setAddModalVisibility={setAddModalVisibility}
                originalData={allClient?.data?.info}
                clientData={selectedClient}
                allClientsData={allClientsData}
                setAllClientsData={setAllClientsData}
                primaryClientsData={primaryClientsData}
                setPrimaryClientsData={setPrimaryClientsData}
                secondaryClientsData={secondaryClientsData}
                setSecondaryClientsData={setSecondaryClientsData}
                filterUsed={filterUsed}
            />
            <RemoveModal
                removeModalVisibility={removeModalVisibility}
                setRemoveModalVisibility={setRemoveModalVisibility}
                clientData={deletedClient}
                setAllClientsData={setAllClientsData}
                primaryClientsData={primaryClientsData}
                setPrimaryClientsData={setPrimaryClientsData}
                secondaryClientsData={secondaryClientsData}
                setSecondaryClientsData={setSecondaryClientsData}
                filterUsed={filterUsed}
            />
            <CancelModal
                displayFunction={setModalView}
                mode={mode}
                header="Contract"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
            <div style={{ zIndex: 1000 }} id="outside_div"></div>
        </div>
    );
};
export default Index;
