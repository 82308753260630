import {
    changePoLineStatus,
    dropProductDetailsConfirmation,
    confirmPo,
    editPoLineStatus,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import { useAppDispatch } from '../../../hooks';
import './confirm-modal.scss';
interface ModalListProps {
    modalViewConfirmShipment: boolean;
    setModalConfirmShipment: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    product: any;
    currentIndex: number;
    currentSplitIndex: number;
}
const Index = ({
    modalViewConfirmShipment,
    setModalConfirmShipment,
    product,
    currentIndex,
    currentSplitIndex,
}: ModalListProps) => {
    const total = product?.splitLines?.reduce((a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0), 0);
    let remaining = 0;
    if (total > product.qty) {
        remaining = total - product.qty;
    } else if (total < product.qty) {
        remaining = product.qty - total;
    }
    const dispatch = useAppDispatch();
    const type = product?.quantityType;
    const getDetails = (field: string) => {
        if (type === 'Short Shipped') {
            return field === 'heading'
                ? 'Confirm Short Shipment'
                : 'Are you sure you want to confirm this PO Line as Short Shipped ?';
        } else if (type === 'Surplus') {
            return field === 'heading'
                ? 'Confirm Surplus Shipment'
                : 'The Total Confirmed Quantity will be more than the ordered quantity. Are you sure to confirm surplus quantity?';
        } else {
            return field === 'heading'
                ? 'Drop PO Line'
                : 'Once Dropped, Quantity against this PO line can no longer be confirmed. Are you sure you want to confirm this PO Line as dropped?';
        }
    };

    const submitPo = () => {
        if (type === 'Short Shipped') {
            dispatch(
                changePoLineStatus({
                    splitLineId: product.poProductId,
                    quantityType: 'short',
                    currentIndex: currentIndex,
                    currentSplitLineIndex: currentSplitIndex,
                })
            );
        } else if (type === 'Surplus') {
            if (product.splitLines[currentSplitIndex].splitLineId) {
                dispatch(
                    editPoLineStatus({
                        ...product.splitLines[currentSplitIndex],
                        splitLineId: product.splitLines[currentSplitIndex].splitLineId,
                        quantityType: product.quantityType,
                        currentIndex: currentIndex,
                        currentSplitLineIndex: currentSplitIndex,
                        isShort: false,
                    })
                );
            } else {
                dispatch(
                    confirmPo({
                        ...product.splitLines[currentSplitIndex],
                        poProductId: product.poProductId,
                        quantityType: product.quantityType,
                        currentIndex: currentIndex,
                        currentSplitLineIndex: currentSplitIndex,
                        isShort: false,
                    })
                );
            }
        } else {
            dispatch(
                dropProductDetailsConfirmation({
                    splitLineId: product.poProductId,
                    currentIndex: currentIndex,
                    currentSplitLineIndex: currentSplitIndex,
                })
            );
        }
    };
    return (
        <>
            {modalViewConfirmShipment ? (
                <div className="app-modal">
                    <div className="modal-content-holder  confirmation-modal">
                        <div className="modal-header">
                            <div className="title">{getDetails('heading')}</div>
                            <button onClick={() => setModalConfirmShipment(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <p className="content-title">{getDetails('body')}</p>
                            <div className="modal-main-content">
                                <div className="layout-holder three-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">SKU</h6>
                                        <div className="description-type-1">{product?.sku}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Description</h6>
                                        <div className="description-type-1">{product?.description}</div>
                                    </div>
                                    {(type === 'Surplus' || type === 'Short Shipped') && (
                                        <>
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">Ordered Quantity</h6>
                                                <div className="description-type-1">{product?.qty}</div>
                                            </div>
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">Confirmed Quantity</h6>
                                                <div className="description-type-1">{total}</div>
                                            </div>
                                            <div className="layout-item">
                                                <h6 className="heading-type-1">
                                                    {type === 'Surplus' ? 'Surplus Quantity' : 'Shortage'}
                                                </h6>
                                                <div className="description-type-1">{remaining}</div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                onClick={() => setModalConfirmShipment(false)}
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                onClick={() => {
                                    setModalConfirmShipment(false);
                                    submitPo();
                                }}
                                title="Confirm"
                            >
                                <span className="button-text footer-button-text">Confirm</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
