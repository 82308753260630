import { Button, Card } from 'semantic-ui-react';
import React from 'react';
import { Grid, GridColumn as Column, GridHeaderCellProps } from '@progress/kendo-react-grid';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import './vendorPortal.css';

interface PackingGridProps {
    selectedCount: number;
    selectedState: { [id: string]: boolean | number[] };
    setPackData: (value: any) => void;
    selectedContainerId: string[];
    dataState: any;
    setShowZaPopup: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setShowPopup: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<PackingGridProps> = ({
    selectedCount,
    selectedState,
    setPackData,
    selectedContainerId,
    dataState,
    setShowZaPopup,
    setShowPopup,
}) => {
    const { packingStatus } = useSelector((state: RootState) => state.vendorPortalData);

    const preparePackingData = (packType: string) => {
        // before send packing data, prepare pack CIX payload
        const payload = { containers: [] as any };
        for (const [key, value] of Object.entries(selectedState)) {
            if (value) {
                payload.containers.push({
                    container_id: key,
                    container_type: packType,
                });
            }
        }

        setPackData(payload);
    };

    const hasInUseContainerNumber = () => {
        // check if selected container numbers has at least one SSCC already in use
        // if yes return true
        // else return false
        for (let index = 0; index < selectedContainerId.length; index++) {
            const data = dataState.find((element: any) => {
                return element.id === selectedContainerId[index];
            });
            if (data?.inUse) return true;
        }
        return false;
    };

    const getCellButton = (props: any) => (
        // rendering button in the right grid (ZA, A1, A2 etc...)
        <td>
            <Button
                primary
                onClick={() => {
                    if (props.dataItem.PackType === 'ZA') {
                        // open ZA pack popup
                        preparePackingData(props.dataItem.PackType);
                        setShowZaPopup(true);
                    } else {
                        // open ratio pack confirm popup
                        preparePackingData(props.dataItem.PackType);
                        setShowPopup(true);
                    }
                }}
                // if selected SSCC number is greater than the remaining packing number, button will be disabled
                disabled={
                    selectedCount === 0 ||
                    (selectedCount > props.dataItem.Remaining && props.dataItem.PackType !== 'ZA') ||
                    hasInUseContainerNumber()
                }
            >
                {props.dataItem.PackType}
            </Button>
        </td>
    );

    const centerHeaderCell = (props: GridHeaderCellProps) => {
        return (
            <a className="k-link">
                <span style={{ textAlign: 'center' }}>{props.title}</span>
                {props.children}
            </a>
        );
    };

    return packingStatus?.length > 0 ? (
        <Card className="single_card_grid">
            <Grid
                data={packingStatus.map((element) => {
                    return {
                        PackType: element.packType,
                        Packed: element.packed,
                        Remaining: element.unpacked,
                    };
                })}
            >
                <Column
                    cell={getCellButton}
                    width={75}
                    className="hub-tx-mono grid-cell-center"
                    headerCell={centerHeaderCell}
                />
                <Column
                    field="Packed"
                    title="Packed"
                    className="hub-tx-mono grid-cell-center"
                    headerCell={centerHeaderCell}
                />
                <Column
                    field="Remaining"
                    title="Remaining"
                    className="grid-cell-center"
                    headerCell={centerHeaderCell}
                />
            </Grid>
        </Card>
    ) : (
        <Grid></Grid>
    );
};

export default Index;
