/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import moment from 'moment';

import Breadcrumbs from '../../components/Breadcrumbs/defaultBreadcrumbs';

import { useAppDispatch, useAppSelector } from 'src/hooks';

import { useParams } from 'react-router-dom';

import '../Users/History/user-history.scss';
import Header from '../ReassignAgent/Header';
import { attachmentsHistory } from 'src/utils/tableStructure/attachmentsHistory';
import {
    AttachmentHistoryDetailsType,
    fetchAttachmentHistoryDistinctList,
    fetchAttachmentHistoryList,
    selectAttachmentHistory,
    selectAttachmentHistoryLoading,
    selectDistinctAttachmentHistory,
} from 'src/store/reducers/attachmentHistoryReducer';
import ColumnFilter from '../ReassignAgent/Filter';

const AttachmentHistory = () => {
    const { bpType, id } = useParams();
    const dispatch = useAppDispatch();

    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [searchName, setSearchName] = useState('');
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>({});
    const [sortColumn, setSortColumn] = useState('dateAndTime');
    const [sortOrder, setSortOrder] = useState('DESC');
    const [filterColumn, setFilterColumn] = useState('');

    let payload = {
        body: statusFilterSelected,
        sortColumn,
        sortOrder,
    };

    useEffect(() => {
        dispatch(fetchAttachmentHistoryDistinctList({ ...payload, bookingId: id }));
    }, []);

    const filter = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        setSearchName(keyword);
    };

    const filterDistance = (columnName: string) => {
        const el = document.getElementById(columnName);
        const distanceLeft = el?.getBoundingClientRect().left ?? 0;
        const distanceRight = el?.getBoundingClientRect().right ?? 0;
        if (window.innerWidth - distanceRight > 280) {
            const distance =
                columnName === 'attachmentType'
                    ? 120
                    : columnName === 'fileName'
                    ? 68
                    : columnName === 'userName'
                    ? 40
                    : 85;
            setSetPosition(distanceLeft + distance);
        } else {
            const distance = columnName === 'activity' ? 195 : 175;
            setSetPosition(distanceLeft - distance);
        }
    };

    const filterByColumn = (e: any, columnName: string) => {
        setFilterColumn(columnName);
        filterDistance(columnName);
        setSearchName('');
        filter(e);
        e.stopPropagation();
        setToggleDropdown((prev) => !prev);
    };

    const onFilter = (newPayload: any) => {
        dispatch(fetchAttachmentHistoryList({ ...newPayload, bookingId: id }));
    };

    const sortByColumn = (columnName: string) => {
        const newOrder = sortOrder === 'ASC' ? 'DESC' : 'ASC';
        setSortOrder(newOrder);
        setSortColumn(columnName);
        if (Object.keys(statusFilterSelected).length) {
            payload = { ...payload, ...statusFilterSelected };
        }
        onFilter({ ...payload, sortColumn: columnName, sortOrder: newOrder });
    };

    const filterData = () => {
        return distinctAttachmentHistory[filterColumn];
    };

    useEffect(() => {
        if (Object.keys(statusFilterSelected).length) {
            onFilter({ ...payload, body: statusFilterSelected });
        } else {
            onFilter(payload);
        }
    }, [statusFilterSelected]);

    const attachmentHistory = useAppSelector(selectAttachmentHistory);
    const distinctAttachmentHistory = useAppSelector(selectDistinctAttachmentHistory);
    const isLoading = useAppSelector(selectAttachmentHistoryLoading);

    return (
        <div className="main-wrapper">
            <div className="main-header-content-holder grid-pages">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={`/${bpType}/${id}/attachments`}
                    thirdName={'Attachments'}
                    thirdUrl={`/${bpType}/${id}/attachments`}
                    fourthName={'History'}
                    fourthUrl={'#'}
                    hideCount={true}
                    backUrl={`/${bpType}/${id}/attachments`}
                />
                <div className="pageheading-holder">
                    <h2 className="page-heading">History</h2>
                </div>
            </div>
            <div className="main-content-holder">
                <div className="grid-section user-history-grid loader-container user-filter-history">
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div id="grid_holder" className="grid-holder">
                        <div className="grid-header"></div>
                        <div className="grid-container">
                            {attachmentHistory?.length === 0 && (
                                <div className="grid-no-content ">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No records found</p>
                                </div>
                            )}
                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <Header
                                        content={attachmentsHistory}
                                        filterFunction={filterByColumn}
                                        sortFunction={sortByColumn}
                                        sortColumn={sortColumn}
                                        sortOrder={sortOrder}
                                        filterColumn={filterColumn}
                                        toggleDropdown={toggleDropdown}
                                        selectedFilterItem={statusFilterSelected}
                                        hideCheckboxColumn={true}
                                    />

                                    <tbody>
                                        {attachmentHistory?.length > 0 &&
                                            attachmentHistory?.map(
                                                (res: AttachmentHistoryDetailsType, index: number) => (
                                                    <tr key={index} className="has-child row-expanded">
                                                        <td className="grid-cell user-height">
                                                            <div className="grid-cell-data">
                                                                <span className="client-id">{res.attachmentType}</span>
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res.fileName}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res.userName}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {moment
                                                                    .utc(res.dateAndTime)
                                                                    .local()
                                                                    .format('DD-MM-YYYY, HH:mm')}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res.activity}</div>
                                                        </td>
                                                    </tr>
                                                )
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        {toggleDropdown && (
                            <ColumnFilter
                                setPosition={setPosition}
                                searchName={searchName}
                                setSearchName={setSearchName}
                                filter={filter}
                                data={filterData()}
                                statusFilterSelected={statusFilterSelected}
                                setStatusFilterSelected={setStatusFilterSelected}
                                isLoading={isLoading}
                                setToggleDropdown={setToggleDropdown}
                                columnName={filterColumn}
                                currentColumnName={filterColumn}
                                setCurrentColumnName={setFilterColumn}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AttachmentHistory;
