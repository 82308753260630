import { FunctionComponent } from 'react';

interface Props {
    description: string;
    handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    hasEditPermission: boolean;
    name: string;
    title: string;
    value: string;
}

const AutoApprovalItem: FunctionComponent<Props> = ({
    description,
    handleChange,
    hasEditPermission,
    name,
    title,
    value,
}) => {
    return (
        <div className="layout-holder">
            <div className="layout-item">
                <h6 className="heading-type-1">{title}</h6>
                <div className="description-type-1">{description}</div>
            </div>
            <div className="layout-item">
                <div className="checkbox-item">
                    <label className="app-check-wrapper">
                        <input
                            type="radio"
                            className="checkbox-input"
                            name={name}
                            value={'Yes'}
                            checked={value === 'Yes'}
                            onChange={handleChange}
                            id={name}
                            disabled={!hasEditPermission}
                        ></input>
                        <div className="checkmark">
                            <svg className="svg-icon notification-circle-icon">
                                <use href="#circleIcon">
                                    <title>check mark</title>
                                </use>
                            </svg>
                        </div>
                        <div className="checkbox-label">Yes</div>
                    </label>
                </div>
                <div className="checkbox-item">
                    <label className="app-check-wrapper">
                        <input
                            type="radio"
                            className="checkbox-input"
                            name={name}
                            value={'No'}
                            checked={value === 'No'}
                            onChange={handleChange}
                            disabled={!hasEditPermission}
                        ></input>
                        <div className="checkmark">
                            <svg className="svg-icon notification-circle-icon">
                                <use href="#circleIcon">
                                    <title>check mark</title>
                                </use>
                            </svg>
                        </div>
                        <div className="checkbox-label">No</div>
                    </label>
                </div>
            </div>
        </div>
    );
};

export default AutoApprovalItem;
