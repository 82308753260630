import moment from 'moment';
import React from 'react';
import { downloadFile } from '../../../services/apis/bookingProposal/additionalDetailsApi';
import { BpQuotation } from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import './contract-detail-modal.scss';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: BpQuotation | undefined;
}
const Index: React.FC<ModalProps> = ({ modalView, setModalView, data }) => {
    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder medium-popup additional-contract-modal">
                        <div className="modal-header">
                            <div className="title">Contract Details</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content agent-commercial-detail-modal-main-content">
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Type</h6>
                                        <div className="description-type-1 text-capitalize">
                                            {data?.cd_doctype?.toLowerCase()}
                                        </div>
                                    </div>
                                </div>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Name</h6>
                                        <div className="description-type-1">{data?.cd_docname}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Version</h6>
                                        <div className="description-type-1">{data?.cd_version}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Author</h6>
                                        <div className="description-type-1">{data?.cd_author}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Forwarder Signatory</h6>
                                        <div className="description-type-1">{data?.cd_forwardersignatory}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Client Signatory</h6>
                                        <div className="description-type-1">{data?.cd_clientsignatory}</div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Start Date</h6>
                                        <div className="description-type-1">
                                            {data?.cd_startdate ? moment(data?.cd_startdate).format('DD-MM-YYYY') : '-'}
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">End Date</h6>
                                        <div className="description-type-1">
                                            {data?.cd_enddate ? moment(data?.cd_enddate).format('DD-MM-YYYY') : '-'}
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <h6 className="heading-type-1">Agreement Terms</h6>
                                        <div className="content-with-icon">
                                            <button
                                                className="app-btn "
                                                onClick={() => downloadFile(data?.cd_PK, data?.fileName)}
                                            >
                                                <svg className="svg-icon file-attachment-icon ">
                                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                                    <title>Attach file</title>
                                                </svg>
                                            </button>
                                            <div>
                                                {/* file-uploaded class to higlight data with blue color */}
                                                <a
                                                    onClick={() => downloadFile(data?.cd_PK, data?.fileName)}
                                                    className="description-type-1 file-uploaded"
                                                >
                                                    {data?.fileName}
                                                </a>
                                                <div className="heading-type-1">{data?.savedFileSizeInKb} kb</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
