import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import './assign-po-line.scss';
import moment from 'moment';
import { assingPoLines } from '../../../../store/reducers/receipt/poLine';
import { useDispatch } from 'react-redux';
import PoReview from '../../../BookingProposal/BookingAddedGrid/PurchaseOrderDetails';
import ProductDetailModal from '../../../BookingProposal/BookingAddedGrid/ProductDetailsModal';
import { useAppSelector } from '../../../../hooks';
import { fetchReceiptHeaderList, selectReceiptHeader } from '../../../../store/reducers/receipts/receiptHeaderReducer';
/* eslint no-var: off */
/* eslint-disable no-undefined */

interface dataProps {
    setassignModal: any;
    data: any;
    setassignedItems: any;
    assignedItems: any;
    id?: string;
}
const Index: React.FC<dataProps> = ({ setassignModal, data, assignedItems, setassignedItems, id }) => {
    const [SearchVal, setSearchVal] = useState('');
    const [warning, setwarning] = useState(false);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    const [VendorCode, setVendorCode] = useState('');
    const receiptHeader = useAppSelector(selectReceiptHeader);
    var poLineNum = 0;
    var poStatus = true;
    var polinename = 0;
    var found = false;
    var ponameStatus = true;

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            fetchReceiptHeaderList({
                id,
            })
        );
    }, []);

    const selectSingle = (item: object, splitId: string, poId: string, type: boolean) => {
        var array: any = [...assignedItems];
        if (type) {
            if (array.length > 0) {
                array.forEach((element: any, i: any) => {
                    if (element?.purchaseOrderId === poId) {
                        array[i].splitLines = [...array[i].splitLines, item];
                    } else {
                        array.push({
                            purchaseOrderId: poId,
                            splitLines: [item],
                        });
                    }
                    array = array.filter(
                        (value: any, index: any, self: any) =>
                            index === self.findIndex((t: any) => t.purchaseOrderId === value.purchaseOrderId)
                    );
                });
            } else {
                array.push({
                    purchaseOrderId: poId,
                    splitLines: [item],
                });
            }

            setassignedItems([...array]);
        } else {
            var newAr = [...assignedItems];
            newAr?.forEach((element: any, i1: any) => {
                element?.splitLines?.forEach((val: any, i2: any) => {
                    if (val?.splitLineId === splitId) {
                        element?.splitLines.splice(i2, 1);
                        if (element?.splitLines?.length === 0) {
                            newAr.splice(i1, 1);
                        }
                    }
                });
            });
            setassignedItems([...newAr]);
        }
    };

    const addPlannedValue = (
        poid: string,
        splitid: string,
        value: string,
        plannedValue: any,
        cbm: any,
        conversionQty: any,
        confirmedQty: any
    ) => {
        var newAr = JSON.parse(JSON.stringify(assignedItems as any));
        newAr?.forEach((element: any) => {
            if (element?.purchaseOrderId === poid) {
                element?.splitLines?.forEach((val: any) => {
                    if (val?.splitLineId === splitid) {
                        val.plannedReceiptQuantity = value === '' ? null : parseInt(value);
                        val.confirmedCbm = parseInt(value) * (cbm / confirmedQty);
                        val.convertedQuantity = parseInt(value) > 0 ? Math.ceil(parseInt(value) / conversionQty) : 0;
                        val.conversionQuantity = conversionQty;
                    }
                });
            }
        });
        if (parseInt(value) > plannedValue) {
            setwarning(true);
        } else {
            setwarning(false);
        }
        setassignedItems(newAr);
    };

    const validation = () => {
        var newAr = JSON.parse(JSON.stringify(assignedItems as any));
        var array: any = [];
        var num: any = 0;
        var flag = false;
        newAr?.forEach((element: any) => {
            element?.splitLines?.forEach((val: any) => {
                num++;
                if (val?.plannedReceiptQuantity !== null && val?.plannedReceiptQuantity !== '') {
                    array.push(val?.plannedReceiptQuantity);
                }
            });
        });
        if (array.length !== num) {
            flag = true;
        }
        return flag;
    };

    const getAddedValue = (poid: string, splitid: string) => {
        var newAr = JSON.parse(JSON.stringify(assignedItems as any));
        var value: any = '';
        newAr?.forEach((element: any) => {
            if (element?.purchaseOrderId === poid) {
                element?.splitLines?.forEach((val: any) => {
                    if (val?.splitLineId === splitid) {
                        value = val?.plannedReceiptQuantity?.toString();
                    }
                });
            }
        });
        return value;
    };

    const rowCheck = (poid: string, type: boolean, val: any) => {
        var array: any = [...assignedItems];
        var data: any = { purchaseOrderId: poid, splitLines: [] };
        if (type) {
            val?.products.forEach((nval: any) => {
                if (nval?.splitLines?.length > 0) {
                    data.splitLines = [...data.splitLines, ...nval.splitLines];
                }
            });
            if (array?.length > 0) {
                array.forEach((element: any, i: any) => {
                    if (element?.purchaseOrderId === poid) {
                        var removeDuplicates = [...array[i].splitLines, ...data?.splitLines];
                        var obj: any = [];
                        obj = removeDuplicates.filter(
                            (value: any, index: any, self: any) =>
                                index === self.findIndex((t: any) => t.splitLineId === value.splitLineId)
                        );
                        array[i].splitLines = [...obj];
                    } else {
                        array.push(data);
                    }
                });
            } else {
                array.push(data);
            }
            var obj: any = [];
            obj = array.filter(
                (value: any, index: any, self: any) =>
                    index === self.findIndex((t: any) => t.purchaseOrderId === value.purchaseOrderId)
            );
            setassignedItems([...obj]);
        } else {
            var newAr = [...assignedItems];
            newAr?.forEach((element: any, i1: any) => {
                if (element?.purchaseOrderId === poid) {
                    newAr.splice(i1, 1);
                }
            });
            setassignedItems([...newAr]);
        }
    };
    const setRowCheck = (poid: string, res: any) => {
        var flag = false;
        var total = 0;
        res?.products?.forEach((element: any) => {
            if (element?.splitLines?.length !== 0) {
                element?.splitLines?.forEach((_val: any) => {
                    total++;
                });
            }
        });
        assignedItems?.forEach((element: any) => {
            if (element?.purchaseOrderId === poid && element?.splitLines?.length === total) {
                flag = true;
            }
        });
        return flag;
    };

    const singleCheck = (splitid: string) => {
        var flag = false;
        assignedItems.forEach((element: any) => {
            element?.splitLines?.forEach((val: any) => {
                if (val?.splitLineId === splitid) {
                    flag = true;
                }
            });
        });
        return flag;
    };
    const selectColumn = (type: boolean) => {
        if (type) {
            var array: any = [...assignedItems];
            data?.polines?.forEach((element: any) => {
                var newVal: any = [];
                element?.products.forEach((val: any) => {
                    if (val?.splitLines?.length > 0) {
                        newVal = [...newVal, ...val?.splitLines];
                    }
                });
                array.push({
                    purchaseOrderId: element.poId,
                    splitLines: newVal,
                });
            });
            array.forEach((element: any, i: any) => {
                if (element?.splitLines?.length === 0) {
                    array.splice(i, 1);
                }
            });
            setassignedItems([...array]);
        } else {
            setassignedItems([]);
        }
    };
    const checkSelectColumn = () => {
        var flag = false;
        var array: any = [];
        var intialLength = 0;
        var selectedLength = 0;
        data?.polines?.forEach((element: any) => {
            element?.products.forEach((val: any) => {
                if (val?.splitLines?.length > 0) {
                    array.push({
                        purchaseOrderId: element.poId,
                        splitLines: val.splitLines,
                    });
                }
            });
        });
        array.forEach((element: any, i: any) => {
            if (element?.splitLines?.length === 0) {
                array.splice(i, 1);
            }
        });
        array.forEach((element: any) => {
            element?.splitLines.forEach((_val: any) => {
                intialLength++;
            });
        });
        assignedItems?.forEach((element: any) => {
            element?.splitLines.forEach((_val: any) => {
                selectedLength++;
            });
        });
        if (selectedLength === intialLength && array?.length !== 0) {
            flag = true;
        }
        return flag;
    };

    const submitData = () => {
        const payload = {
            receiptId: id,
            receiptAddRequests: assignedItems,
        };
        dispatch(assingPoLines(payload));
        setassignModal(false);
        setassignedItems([]);
    };
    return (
        <div>
            <div className="app-modal">
                <div className="modal-content-holder assign-po-line-holder large-popup">
                    <div className="modal-header">
                        <div className="title">Assign PO Lines</div>
                        <button
                            className="app-btn modal-close-btn"
                            onClick={() => {
                                setassignModal(false);
                                setassignedItems([]);
                            }}
                        >
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use href="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="modal-content">
                        <div className="modal-main-content">
                            <div className="grid-section assign-po-grid loader-container">
                                <div className="grid-holder">
                                    <div className="grid-header">
                                        <div className="search-entry-holder">
                                            <input
                                                placeholder="Search by PO Number, SKU"
                                                type="text"
                                                className="search-input input-text"
                                                onChange={(e) => setSearchVal(e.target.value)}
                                                value={SearchVal}
                                            ></input>
                                            <div
                                                className={`search-toolip ${
                                                    SearchVal?.length > 0 && SearchVal?.length < 3 ? 'tooltip-show' : ''
                                                }`}
                                            >
                                                Enter atleast 3 characters to search
                                            </div>
                                            <svg className="svg-icon search-icon">
                                                <use xlinkHref="#searchIcon"></use>
                                            </svg>
                                            <button className="app-btn text-close-btn" onClick={() => setSearchVal('')}>
                                                <svg className="svg-icon text-close-icon">
                                                    <use xlinkHref="#closeIcon"></use>
                                                </svg>
                                            </button>
                                        </div>
                                        {receiptHeader?.plannedGoodsReceivedDate ? (
                                            <div className="date-time-field">
                                                Planned Goods Received Date :
                                                <span className="date-field">
                                                    {moment(receiptHeader?.plannedGoodsReceivedDate).format(
                                                        'DD-MM-YYYY'
                                                    )}
                                                </span>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="grid-container">
                                        {/* content when No data found in grid*/}
                                        {/*<div className="grid-no-content ">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No Contract added to this profile</p>

                                            <a className="add-content-link" href="javascript:void(0)">
                                                Add Contract
                                            </a>
                                        </div>*/}

                                        <div className="grid-scroll-content">
                                            <table className="grid-table">
                                                <thead>
                                                    <tr className="grid-sticky-row">
                                                        <th className="grid-cell-header col-inbound-po grid-sticky-column sticky-left-aligned">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">INBOUND PO NUMBER</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-checkbox">
                                                            <div
                                                                className="grid-cell-data centre-align"
                                                                style={{ width: '49px' }}
                                                            >
                                                                <label className="app-check-wrapper">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="checkbox-input"
                                                                        onChange={(e) =>
                                                                            selectColumn(e.currentTarget.checked)
                                                                        }
                                                                        checked={checkSelectColumn()}
                                                                    ></input>
                                                                    <div className="checkmark">
                                                                        <svg className="svg-icon tick-icon">
                                                                            <use href="#tickIcon">
                                                                                <title>check mark</title>
                                                                            </use>
                                                                        </svg>
                                                                    </div>
                                                                </label>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-line">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">PO LINE No.</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-sku">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">SKU</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-desc">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">DESCRIPTION</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-grd">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">GOODS READY DATE</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-grd">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">ORDERED QTY</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-orq">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">REMAINING QTY</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-prq">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">
                                                                    PLANNED RECEIPT QTY
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-cbm">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">CBM</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-converted">
                                                            <div className="grid-cell-data">
                                                                <span className="header-title">
                                                                    CONVERTED QTY & UOM
                                                                </span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {data?.polines?.map((res: any) => (
                                                    <tbody className="grouped-splited-row">
                                                        {res?.products.map((val: any) =>
                                                            val.splitLines.map((nval: any, i: any) => {
                                                                if (poLineNum === nval?.poLineNumber && i !== 0) {
                                                                    poStatus = false;
                                                                } else {
                                                                    poStatus = true;
                                                                }
                                                                poLineNum = nval?.poLineNumber;
                                                                if (polinename === res?.poNumber) {
                                                                    ponameStatus = false;
                                                                } else {
                                                                    ponameStatus = true;
                                                                }
                                                                polinename = res?.poNumber;
                                                                const tableHtml = () => {
                                                                    return (
                                                                        <tr key={i}>
                                                                            <td className="grid-cell col-inbound-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="checkbox-data-wrapper">
                                                                                        {ponameStatus && (
                                                                                            <label
                                                                                                className="app-check-wrapper"
                                                                                                style={{ zIndex: '10' }}
                                                                                            >
                                                                                                <input
                                                                                                    type="checkbox"
                                                                                                    className="checkbox-input"
                                                                                                    checked={setRowCheck(
                                                                                                        res?.poId,
                                                                                                        res
                                                                                                    )}
                                                                                                    onChange={(e) =>
                                                                                                        rowCheck(
                                                                                                            res?.poId,
                                                                                                            e
                                                                                                                .currentTarget
                                                                                                                .checked,
                                                                                                            res
                                                                                                        )
                                                                                                    }
                                                                                                />

                                                                                                <div className="checkmark">
                                                                                                    <svg className="svg-icon tick-icon">
                                                                                                        <use xlinkHref="#tickIcon">
                                                                                                            <title>
                                                                                                                check
                                                                                                                mark
                                                                                                            </title>
                                                                                                        </use>
                                                                                                    </svg>
                                                                                                </div>
                                                                                            </label>
                                                                                        )}

                                                                                        <a
                                                                                            href="javascript:void(0)"
                                                                                            className="booking-proposal-link"
                                                                                            onClick={() =>
                                                                                                setpoDisplay({
                                                                                                    status: true,
                                                                                                    id: res?.poId,
                                                                                                })
                                                                                            }
                                                                                        >
                                                                                            {ponameStatus &&
                                                                                                res?.poNumber}
                                                                                        </a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-checkbox">
                                                                                <div className="grid-cell-data centre-align">
                                                                                    <div className="checkbox-data-wrapper">
                                                                                        <label className="app-check-wrapper">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                className="checkbox-input"
                                                                                                onChange={(e) =>
                                                                                                    selectSingle(
                                                                                                        nval,
                                                                                                        nval?.splitLineId,
                                                                                                        res?.poId,
                                                                                                        e.currentTarget
                                                                                                            .checked
                                                                                                    )
                                                                                                }
                                                                                                checked={singleCheck(
                                                                                                    nval?.splitLineId
                                                                                                )}
                                                                                            />

                                                                                            <div className="checkmark">
                                                                                                <svg className="svg-icon tick-icon">
                                                                                                    <use xlinkHref="#tickIcon">
                                                                                                        <title>
                                                                                                            check mark
                                                                                                        </title>
                                                                                                    </use>
                                                                                                </svg>
                                                                                            </div>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-sku">
                                                                                <div className="grid-cell-data">
                                                                                    <a>
                                                                                        {poStatus && nval?.poLineNumber}
                                                                                    </a>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-desc">
                                                                                <div className="grid-cell-data">
                                                                                    <a
                                                                                        href="javascript:void(0)"
                                                                                        className="booking-proposal-link"
                                                                                        onClick={() => {
                                                                                            setVendorCode(
                                                                                                res?.clientCode
                                                                                            );
                                                                                            setModalViewproduct({
                                                                                                status: true,
                                                                                                value: res?.clientCode,
                                                                                                id: val?.skuId,
                                                                                            });
                                                                                        }}
                                                                                    >
                                                                                        {poStatus && val?.sku}
                                                                                    </a>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-qty">
                                                                                <div className="grid-cell-data">
                                                                                    {poStatus && val?.description}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-grd">
                                                                                <div className="grid-cell-data">
                                                                                    {receiptHeader?.actualGoodsReadyDate
                                                                                        ? moment(
                                                                                              receiptHeader?.actualGoodsReadyDate
                                                                                          ).format('DD-MM-yyyy')
                                                                                        : ''}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-cbm">
                                                                                <div className="grid-cell-data">
                                                                                    {nval?.confirmedQty}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-cbm">
                                                                                <div className="grid-cell-data">
                                                                                    {nval?.remainingQuantity}
                                                                                </div>
                                                                            </td>

                                                                            <td className="grid-cell col-po-weight">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        {/* with-error-border class using for grid input text warning border */}

                                                                                        {singleCheck(
                                                                                            nval?.splitLineId
                                                                                        ) ? (
                                                                                            getAddedValue(
                                                                                                res?.poId,
                                                                                                nval?.splitLineId
                                                                                            ) >
                                                                                            nval?.remainingQuantity ? (
                                                                                                <>
                                                                                                    <input
                                                                                                        placeholder=""
                                                                                                        type="text"
                                                                                                        className="modal-grid-input input-text with-error-border"
                                                                                                        name="pp_conversionquantity"
                                                                                                        onChange={(e) =>
                                                                                                            addPlannedValue(
                                                                                                                res?.poId,
                                                                                                                nval?.splitLineId,
                                                                                                                e.target
                                                                                                                    .value,
                                                                                                                nval?.remainingQuantity,
                                                                                                                nval?.confirmedCbm,
                                                                                                                val?.conversionQuantity,
                                                                                                                nval?.confirmedQty
                                                                                                            )
                                                                                                        }
                                                                                                        value={getAddedValue(
                                                                                                            res?.poId,
                                                                                                            nval?.splitLineId
                                                                                                        )}
                                                                                                    ></input>
                                                                                                    <svg className="svg-icon error-icon">
                                                                                                        <use xlinkHref="#errorIcon">
                                                                                                            <title>
                                                                                                                Warning
                                                                                                            </title>
                                                                                                        </use>
                                                                                                    </svg>
                                                                                                </>
                                                                                            ) : (
                                                                                                <input
                                                                                                    placeholder=""
                                                                                                    type="text"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_conversionquantity"
                                                                                                    onChange={(e) =>
                                                                                                        addPlannedValue(
                                                                                                            res?.poId,
                                                                                                            nval?.splitLineId,
                                                                                                            e.target
                                                                                                                .value,
                                                                                                            nval?.remainingQuantity,
                                                                                                            nval?.confirmedCbm,
                                                                                                            val?.conversionQuantity,
                                                                                                            nval?.confirmedQty
                                                                                                        )
                                                                                                    }
                                                                                                    value={getAddedValue(
                                                                                                        res?.poId,
                                                                                                        nval?.splitLineId
                                                                                                    )}
                                                                                                />
                                                                                            )
                                                                                        ) : (
                                                                                            '-'
                                                                                        )}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-weight">
                                                                                <div className="grid-cell-data">
                                                                                    {getAddedValue(
                                                                                        res?.poId,
                                                                                        nval?.splitLineId
                                                                                    ) === '' ||
                                                                                    getAddedValue(
                                                                                        res?.poId,
                                                                                        nval?.splitLineId
                                                                                    ) === null ||
                                                                                    getAddedValue(
                                                                                        res?.poId,
                                                                                        nval?.splitLineId
                                                                                    ) === undefined
                                                                                        ? '-'
                                                                                        : getAddedValue(
                                                                                              res?.poId,
                                                                                              nval?.splitLineId
                                                                                          ) > 0
                                                                                        ? Math.ceil(
                                                                                              getAddedValue(
                                                                                                  res?.poId,
                                                                                                  nval?.splitLineId
                                                                                              ) *
                                                                                                  (nval?.confirmedCbm /
                                                                                                      nval?.confirmedQty)
                                                                                          )
                                                                                        : 0}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell col-po-weight">
                                                                                <div className="grid-cell-data">
                                                                                    {getAddedValue(
                                                                                        res?.poId,
                                                                                        nval?.splitLineId
                                                                                    ) === '' ||
                                                                                    getAddedValue(
                                                                                        res?.poId,
                                                                                        nval?.splitLineId
                                                                                    ) === null ||
                                                                                    getAddedValue(
                                                                                        res?.poId,
                                                                                        nval?.splitLineId
                                                                                    ) === undefined ||
                                                                                    nval?.conversionQuantity === null
                                                                                        ? '-'
                                                                                        : getAddedValue(
                                                                                              res?.poId,
                                                                                              nval?.splitLineId
                                                                                          ) > 0
                                                                                        ? Math.ceil(
                                                                                              getAddedValue(
                                                                                                  res?.poId,
                                                                                                  nval?.splitLineId
                                                                                              ) /
                                                                                                  val?.conversionQuantity
                                                                                          ) +
                                                                                          ' ' +
                                                                                          (nval?.uom !== null
                                                                                              ? nval?.uom
                                                                                              : '')
                                                                                        : '0 '}
                                                                                    {nval?.nval}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    );
                                                                };
                                                                if (SearchVal?.length > 2) {
                                                                    if (
                                                                        res?.poNumber
                                                                            .toLowerCase()
                                                                            .includes(SearchVal.toLowerCase()) ||
                                                                        val?.sku
                                                                            .toLowerCase()
                                                                            .includes(SearchVal.toLowerCase())
                                                                    ) {
                                                                        found = true;
                                                                        return tableHtml();
                                                                    }
                                                                } else {
                                                                    found = true;
                                                                    return tableHtml();
                                                                }
                                                            })
                                                        )}
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                        {!found && (
                                            <div className="grid-no-content ">
                                                <svg className="svg-icon grid-no-content-icon ">
                                                    <use xlinkHref="#gridNoContentIcon"></use>
                                                </svg>
                                                <p className="no-content-message">No Matching Record Found</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <div className="tab-with-proposal">
                            {warning && assignedItems?.length > 0 && (
                                <div className="error-text">
                                    <svg className="svg-icon error-icon">
                                        <use href="#errorIcon"></use>
                                    </svg>
                                    Planned receipt quantity cannot be more than the remaining quantity
                                </div>
                            )}
                            <div className="tab-grid-select-holder">
                                {assignedItems?.length > 0 && (
                                    <span className="btn-pills info-pill" onClick={() => setassignedItems([])}>
                                        <span className="pill-text">{assignedItems?.length} Selected</span>
                                        <button className="pill-action" type="button">
                                            <svg className="svg-icon close-icon">
                                                <use xlinkHref="#closeIcon"></use>
                                            </svg>
                                        </button>
                                    </span>
                                )}
                                <button
                                    className={`app-btn app-btn-primary footer-btn ${
                                        assignedItems?.length <= 0 || warning || validation() ? 'disabled' : ''
                                    }`}
                                    type="button"
                                    onClick={() =>
                                        assignedItems?.length > 0 && !warning && !validation() && submitData()
                                    }
                                >
                                    Add to Receipt
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ModalViewproduct.status && (
                <div className="grid-section grid-added-booking product-details-wrapper">
                    <div className="grid-holder">
                        <div className="grid-container">
                            <ProductDetailModal
                                display={ModalViewproduct}
                                displayFunction={setModalViewproduct}
                                id={VendorCode}
                                data={data}
                            />
                        </div>
                    </div>
                </div>
            )}
            {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} />}{' '}
        </div>
    );
};
export default Index;
