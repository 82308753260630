import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootState } from 'src/store/reducers';

const Index: React.FC = () => {
    const navigate = useNavigate();
    const { basicDetails, isLoading } = useSelector((state: RootState) => state.agentBasicDetailsData);
    return (
        <>
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="head-btn-holder">
                <button
                    className="app-btn app-btn-secondary"
                    title="Go to Profile"
                    onClick={() => {
                        navigate(`/profile/agent/${basicDetails?.data?.info?.ag_PK}/basic_details`);
                    }}
                >
                    <span className="button-text">Go to Profile</span>
                </button>
            </div>
            <div className="lcs-basic-details">
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Name</div>
                    <div className="cm-key-pair-value">
                        {basicDetails?.data?.info?.ag_Org?.or_Orgname + ' '}
                        {basicDetails?.data?.info?.isBlp ? (
                            <span className="status-ui confirmed-status">BLP</span>
                        ) : null}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Code</div>
                    <div className="cm-key-pair-value">{basicDetails?.data?.info?.ag_Org?.or_Orgcode || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Organisation Category</div>
                    <div className="cm-key-pair-value">{basicDetails?.data?.info?.ag_Org?.or_Orgtype || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Company Registration Number</div>
                    <div className="cm-key-pair-value">{basicDetails?.data?.info?.ag_Org?.or_ABN || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">UNLOCO</div>
                    <div className="cm-key-pair-value">{basicDetails?.data?.info?.ag_Org?.or_Unloco || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Phone</div>
                    <div className="cm-key-pair-value">{basicDetails?.data?.info?.ag_Org?.or_Phone || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Website URL</div>
                    <div className="cm-key-pair-value">{basicDetails?.data?.info?.ag_Org?.or_WebSiteUrl || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Email</div>
                    <div className="cm-key-pair-value">{basicDetails?.data?.info?.ag_Org?.or_Email || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Forwarder Ranking</div>
                    <div className="cm-key-pair-value">
                        {basicDetails?.data?.info?.ag_Org?.or_ForwarderRanking?.rank}
                    </div>
                </div>
            </div>
        </>
    );
};
export default Index;
