import { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import './max-limit-modal.scss';
interface MaxLimitModalProps {
    setShowModal: any;
    onConfirm: any;
    data: string;
}

const Index = ({ setShowModal, onConfirm, data }: MaxLimitModalProps): ReactElement => {
    const { favList } = useSelector((state: RootState) => state.lifecyclesearchFav);
    return (
        <div className="app-modal max-limit-modal">
            <div className="modal-content-holder">
                <div className="modal-header">
                    <div className="title">Maximum Limit Reached</div>
                    <button className="app-btn modal-close-btn" onClick={() => setShowModal(false)}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="max-limit-modal-content">
                            <p>
                                You have reached the maximum limit (50) of favourites. Adding this would remove the
                                oldest one from the favourites list. Are you sure to continue ?
                            </p>
                            <div className="key-pair-stack">
                                <div className="cm-key-pair-items">
                                    <div className="cm-key-pair-title">Added</div>
                                    <div className="cm-key-pair-value">{data}</div>
                                </div>
                                <div className="cm-key-pair-items">
                                    <div className="cm-key-pair-title">Removed</div>
                                    <div className="cm-key-pair-value">
                                        {favList?.favourites && favList?.favourites?.length
                                            ? `${favList?.favourites[favList?.favourites?.length - 1]?.favourite} : ${
                                                  favList?.favourites[favList?.favourites?.length - 1]?.number
                                              }`
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                        onClick={() => setShowModal(false)}
                    >
                        <span className="button-text vendor-button-text">No</span>
                    </button>
                    <button className="app-btn app-btn-primary modal-btn footer-btn" onClick={onConfirm}>
                        <span className="button-text vendor-button-text">Yes</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
