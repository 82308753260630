import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'users',
    initialState: {
        users: [],
        isLoading: false,
        currentPage: 1,
    },
    reducers: {
        fetchData: (state, action) => {
            state.isLoading = true;
            state.users = action.payload;
            state.isLoading = false;
        },
    },
});

export const { fetchData } = dataSlice.actions;
export default dataSlice.reducer;
