import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    agentApproval,
    clearFailureApproval,
    fetchReassignApprovalList,
    ReassignApprovalDetails,
    selectReassignApprovalError,
    selectReassignApprovalIsError,
    selectReassignApprovalSuccess,
} from 'src/store/reducers/reassignAgent/approvalReducer';
import { error, Position, success } from 'src/utils/toast';
import '../../../assets/scss/components/_modal.scss';

interface ApproveProps {
    setSelected: (value: [] | ((prevVar: []) => [])) => void;
    payload: any;
    data: ReassignApprovalDetails[];
    setDisplayApproveModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const Index: React.FC<ApproveProps> = ({ setSelected, payload, data, setDisplayApproveModal }) => {
    const dispatch = useAppDispatch();
    const { userName, fullName } = useUserProfile();

    const isErrorApprove = useAppSelector(selectReassignApprovalIsError);
    const reassignApproveSuccess = useAppSelector(selectReassignApprovalSuccess);
    const reassignApproveError = useAppSelector(selectReassignApprovalError);

    const onSubmit = () => {
        dispatch(
            agentApproval({
                data,
                agentCode: data.map((res: any) => res.newAgentCode),
                userName,
                fullName,
            })
        );
    };

    useEffect(() => {
        if (isErrorApprove) {
            error(reassignApproveError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailureApproval([]));
            // } else if (
            //     reassignApproveSuccess?.length &&
            //     (reassignApproveSuccess[0]?.status === 200 || reassignApproveSuccess[0]?.status === 201)
            // ) {
        } else if (reassignApproveSuccess?.status === 200 || reassignApproveSuccess?.status === 201) {
            setDisplayApproveModal(false);
            success(`Selected PO/Bookings have been approved and assigned to New agent`, Position.TOP_RIGHT);
            dispatch(clearFailureApproval([]));
            dispatch(fetchReassignApprovalList({ ...payload }));
            setSelected([]);
        }
    }, [isErrorApprove, reassignApproveSuccess, reassignApproveError]);

    return (
        <>
            {/* {aprroveModalVisibility ? ( */}
            <div className="app-modal container">
                <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                    <div className="modal-header">
                        <h2 className="title">Approve Reassignment</h2>
                        <button className="app-btn modal-close-btn" onClick={() => setDisplayApproveModal(false)}>
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use xlinkHref="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="modal-content">
                        <p>Are you sure you want to approve agent reassignment for the selected PO/Bookings ?</p>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="app-btn app-btn-secondary modal-btn footer-btn"
                            onClick={() => setDisplayApproveModal(false)}
                        >
                            <span className="button-text vendor-button-text">No</span>
                        </button>
                        <button
                            style={{ marginLeft: '8px' }}
                            className="app-btn app-btn-primary modal-btn footer-btn"
                            onClick={() => {
                                onSubmit();
                            }}
                        >
                            <span className="button-text vendor-button-text">Yes</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* ) : (
                <div></div>
            )} */}
        </>
    );
};

export default Index;
