import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import BookingAttachments from '../BookingAttachment';
import { fetchFiles, setWarning } from '../../../store/reducers/bookingProposal/attachmentsReducer';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { fetchBpHeaderData } from '../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useUserProfile } from '../../../hooks/useUserProfile';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import ShowForPermissions from '../../../ShowForPermissions';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import CancelBookingModal from '../BookingAddedGrid/CancelBookingModal';
import { error, Position, success } from '../../../utils/toast';
import { getUserDetails } from 'src/utils/getUserDetails';
import { updateBP, clearFailure } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
import {
    selectEnrichBooking,
    selectHouseBillDetails,
    fetchEnrichBooking,
    fetchHouseBills,
    selectgateInDateValidation,
} from '../../../store/reducers/bookingProposal/additionalDetailsReducer';
import { fetchContainers } from '../../../store/reducers/bookingProposal/containerAllocationReducer';
import { fetchNacAdditionalDetails } from '../../../store/reducers/bookingProposal/additionalDetailsNacReducer';
import { useAppSelector } from '../../../hooks';
import {
    selectContainerDetail,
    // selectContainerDetailsId,
    fetchContainerDetail,
} from '../../../store/reducers/bookingProposal/containerAllocationReducer';
import { Icon } from 'semantic-ui-react';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import { cixDownloadFile, fileEventHistory } from 'src/services/apis/documentApi';
import DeleteModal from './deleteConfirmModal';
import EventModal from './eventModal';
import { resetDeleteResult } from 'src/store/reducers/documentReducer';
import FileUploadModal from 'src/pages/Common/fileUploader';
import UnavailableModel from '../SelectedSailingSchelude/UnavailableModel';

// import { updateHouseBills } from 'src/store/reducers/bookingProposal/additionalDetailsReducer';
// import coloader from 'src/store/reducers/profiles/coloader';

const Index: React.FC = () => {
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const { files, isLoading, errorMessage, errorStat } = useSelector((state: RootState) => state.attachmentsReducer);
    const { submitBPSuccess, submitBPError } = useSelector((state: RootState) => state.additionalDetailsData);
    const { bpStatus, gateInDateExpired } = useSelector((state: RootState) => state.bpHeaderData);
    const { profileType } = useUserProfile();
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [validation, setvalidation] = useState(false);
    const enrichBooking = useAppSelector(selectEnrichBooking);
    const houseBillData = useAppSelector(selectHouseBillDetails);
    // const containers = useAppSelector(selectContainers);
    const [nextBtn, setNextBtn] = useState(false);
    const [nextAddBtn, setNextAddBtn] = useState(false);
    const { additionalDetails } = useSelector((state: RootState) => state.additionalDetailsNac);
    // const currentContainerDetailsId = useAppSelector(selectContainerDetailsId);
    const containerDetail: any = useAppSelector(selectContainerDetail);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showEventModal, setShowEventModal] = useState(false);
    const [doPk, setDoPk] = useState('');
    const { deleteResult } = useSelector((state: RootState) => state.document);
    const [eventHistory, setEventHistory] = useState([]);
    const [displayModal, setdisplayModal] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const gateinVal = useAppSelector(selectgateInDateValidation);
    useEffect(() => {
        dispatch(fetchEnrichBooking({ bpId: id }));
        dispatch(fetchHouseBills({ bpId: id }));
        dispatch(fetchContainers({ bpId: id }));
        // dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        dispatch(fetchNacAdditionalDetails({ bpId: id }));
    }, [id]);
    useEffect(() => {
        if (headerData?.containerDetailsId) {
            dispatch(fetchContainerDetail({ containerDetailsId: headerData?.containerDetailsId }));
        }
    }, [headerData?.containerDetailsId]);
    useEffect(() => {
        if (gateinVal) {
            setdisplayModal(true);
        } else {
            setdisplayModal(false);
        }
    }, [gateinVal]);

    useEffect(() => {
        if (
            houseBillData &&
            enrichBooking &&
            houseBillData.numbers?.length > 0 &&
            houseBillData.numbers[0]?.vendors?.length > 0 &&
            enrichBooking?.soNumber &&
            enrichBooking?.originCartageCompany &&
            houseBillData?.numbers[0].vendors[0].houseBillNumber !== ''
            // houseBillData?.masterBillNumber !== '' &&
        ) {
            setNextBtn(true);
        } else {
            setNextBtn(false);
        }
    }, [enrichBooking, houseBillData]);

    useEffect(() => {
        const headerDataForContainer = JSON?.parse(JSON?.stringify(headerData));
        if (headerData?.coLoaderCode === process.env.REACT_APP_ORG_CODE && headerData?.isTriangleShipment) {
            if (additionalDetails?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.destinationAgentName &&
                    additionalDetails?.sellTotOriginCost &&
                    additionalDetails?.sellQuotationName
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'FAK' && (profileType === 'agent' || profileType === 'forwarder')) {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.destinationAgentName !== null
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'NAC') {
                if (additionalDetails?.destinationAgentName !== null) {
                    setNextAddBtn(true);
                }
            }
        }

        if (headerData?.coLoaderCode === process.env.REACT_APP_ORG_CODE && headerData?.isTriangleShipment === false) {
            if (additionalDetails?.contractType === 'FAK' && profileType === 'forwarder') {
                if (
                    // additionalDetails?.savedFakFileName &&
                    // additionalDetails?.totOriginCost &&
                    additionalDetails?.sellTotOriginCost &&
                    additionalDetails?.sellQuotationName
                ) {
                    setNextAddBtn(true);
                }
            }
            if (additionalDetails?.contractType === 'FAK' && (profileType === 'agent' || profileType === 'forwarder')) {
                // if (additionalDetails?.savedFakFileName && additionalDetails?.totOriginCost) {
                setNextAddBtn(true);
                // }
            }
            if (additionalDetails?.contractType === 'NAC') {
                setNextAddBtn(true);
            }
        }
        if (headerData?.coLoaderCode !== process.env.REACT_APP_ORG_CODE) {
            if (
                containerDetail?.containerType &&
                containerDetail?.deliveryMode &&
                containerDetail?.containerMode &&
                containerDetail?.containerNumber &&
                containerDetail?.packing
                // &&
                // containerDetail?.sealNumber &&
                // containerDetail?.gateInDate
            ) {
                if (headerData?.isTriangleShipment === false) {
                    setNextAddBtn(true);
                } else {
                    additionalDetails?.destinationAgentName && setNextAddBtn(true);
                }
            }
        }
        if (headerDataForContainer?.tabValues?.containerDetails === false) {
            setNextAddBtn(false);
        }
    }, [additionalDetails, headerData, containerDetail]);

    useEffect(() => {
        dispatch(fetchFiles(id));
    }, [id]);

    useEffect(() => {
        if (submitBPSuccess) {
            success(`Booking details sent successfully`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPError) {
            error(`Something went wrong`, Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        }
        if (submitBPSuccess || submitBPError) {
            dispatch(clearFailure([]));
            navigate('/booking_list');
        }
    }, [submitBPSuccess, submitBPError]);

    useEffect(() => {
        dispatch(setWarning({ errorStat: false, message: '' }));
        if (errorStat) {
            error(errorMessage, Position.TOP_RIGHT);
        }
    }, [errorStat, errorMessage]);

    const submitReadyForShipment = () => {
        const { userName } = getUserDetails();
        dispatch(updateBP({ bookingId: id, userName, profile: profileType }));
    };

    useEffect(() => {
        if (gateInDateExpired) {
            setvalidation(false);
        } else {
            setvalidation(true);
        }
    }, [files, gateInDateExpired]);

    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: id ? `/LCLbooking_list/${id}/POSelection` : '/LCLbooking_list/POSelection',
            disabled: false,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/LCLbooking_list/${id}/SailingSelectionDetails`,
            disabled: !(headerData?.tabValues as any)?.po,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/LCLbooking_list/${id}/additional-details`,
            disabled: !(headerData?.tabValues as any)?.sailing,
        },
    ];
    // if (
    //     headerData.bpStatus === 'Ready_for_Booking' ||
    //     headerData.bpStatus === 'Draft_Booking' ||
    //     headerData.bpStatus === 'Booked'
    // ) {
    navData.push(
        {
            title: 'Container Details',
            selected: 0,
            path: `/LCLbooking_list/${id}/container-allocation`,
            disabled: !(headerData?.tabValues as any)?.sailing,
        }
        // {
        //     title: 'Attachments',
        //     selected: 1,
        //     path: `/LCLbooking_list/${id}/attachments`,
        //     disabled: false,
        // }
    );
    // }

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    useEffect(() => {
        if (deleteResult?.length > 0) {
            if ($('#grid').data('kendoGrid')) $('#grid').data('kendoGrid').dataSource.read();
            dispatch(resetDeleteResult({}));
            setShowDeleteModal(false);
            setDoPk('');
        }
    }, [deleteResult]);

    const downloadFile = (doPk: string, filename: string) => {
        cixDownloadFile(doPk, filename);
    };

    //@ts-ignore
    window.attachmentAction = (action: string, doPK?: string, filename?: string) => {
        if (action === 'DLD') {
            if (doPK && filename) downloadFile(doPK, filename);
        } else if (action === 'DEL') {
            if (doPK) {
                setDoPk(doPK);
                setShowDeleteModal(true);
            }
        } else if (action === 'EVT') {
            if (doPK) {
                fileEventHistory(doPK ?? '')
                    .then((res: any) => {
                        setEventHistory(res);
                        setShowEventModal(true);
                    })
                    .catch((err: any) => console.log(err));
            }
        } else if (action === 'NEW') {
            setShowUploadModal(true);
        }
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>
                {showBookingSideBar && (
                    <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
                )}
                <BookingProcessTab navData={navData} />
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper grid-p-0">
                <div className="grid-header grid-ml-20">
                    <div className="content-heading-holder card-head-btn-edit-holder">
                        <span className="content-heading">
                            Attachments
                            <span className="hub-tooltip" style={{ marginLeft: '5px' }}>
                                <span className="hub-tooltiptext" style={{ width: '400px' }}>
                                    Attachments will show documents uploaded for this booking, its children, or linked
                                    POs.
                                </span>
                                <Icon name="question circle outline" />
                            </span>
                        </span>
                    </div>
                </div>

                <LayoutWithColumns content={'bookingDocuments'} hideLoader style="booking_details" />
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking</span>
                                </button>
                            </ShowForPermissions>
                        )} */}
                    {/* <ShowForPermissions type="bp" permission="create">
                        <Link title="Save" className="app-btn app-btn-secondary footer-btn" to={'/booking_list'}>
                            <span className="button-text footer-button-text">Save as Draft</span>
                        </Link>
                    </ShowForPermissions>ookingDetailsSlice*/}
                    {/* <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/LCLbooking_list/${id}/container-allocation`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <ShowForPermissions type="bp" permission="create">
                        {(profileType === 'agent' || profileType === 'forwarder') && bpStatus !== 'Booked' && (
                            <a
                                title="Ready for Shipment"
                                className={`app-btn app-btn-primary footer-btn ${
                                    validation && nextBtn && nextAddBtn ? '' : 'disabled'
                                }`}
                                onClick={() => {
                                    if (nextBtn && validation && nextAddBtn) {
                                        submitReadyForShipment();
                                    }
                                }}
                            >
                                <span className="button-text footer-button-text">Ready for Shipment</span>
                            </a>
                        )}
                    </ShowForPermissions> */}
                </div>
            </div>

            {showUploadModal && (
                <FileUploadModal setShowFileUploadModal={setShowUploadModal} referenceId={id} referenceType={'BP'} />
            )}

            {showDeleteModal && (
                <DeleteModal setModalView={setShowDeleteModal} modalView={showDeleteModal} doPK={doPk} />
            )}

            {showEventModal && <EventModal setModalView={setShowEventModal} eventHistory={eventHistory} />}

            {showBookingCancelModal ? (
                bpStatus === 'Booked' || bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                ) : (
                    <CancelBookingModal setShowBookingCancelModal={setShowBookingCancelModal} />
                )
            ) : null}
            {displayModal && <UnavailableModel setdisplayModal={setdisplayModal} />}
        </div>
    );
};
export default Index;
