import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';
import './header.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import FA package
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import the specific icon from duotone package, please note the pro icon need to be import from pro-XXX
import { faSave } from '@fortawesome/pro-duotone-svg-icons';
import { getRateByPK, updateDateApi } from 'src/services/apis/qmsApi';
import { Position, error, success } from 'src/utils/toast';
import { fetchRateHeader } from 'src/store/reducers/qmsReducer';
import { RootState } from 'src/store/reducers';
import { reactSelectStyle } from 'src/utils/constants';
import ContractDropdownList from '../../../Common/allNacContractDropdown';
import { getAllContractList } from 'src/store/reducers/bookingProposalAir/addSchedule';
import ConfirmModal from './confirmModal';
import RefreshScheduleModal from './RefreshScheduleModal';

interface HeaderProps {
    rateHeaderObj?: any;
    setUpdate?: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    update?: boolean;
}

const Index: React.FC<HeaderProps> = ({ rateHeaderObj, setUpdate, update }) => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const [newEffectiveDate, setNewEffectiveDate] = useState('');
    const [newExpireDate, setNewExpireDate] = useState('');
    const [newTransportMode, setNewTransportMode] = useState({} as any);
    const [newContractType, setNewContractType] = useState({} as any);
    const [newContainerMode, setNewContainerMode] = useState({} as any);
    const [contract, setContract] = useState('');
    const [ref, setRef] = useState('');
    const [confirmed, setConfirmed] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [refreshModal, setRefreshModal] = useState(false);

    const { rateHeader } = useSelector((state: RootState) => state.qmsData);
    const { allContractList } = useSelector((state: RootState) => state.bookingproposalAirschedule);

    useEffect(() => {
        if (id && rateHeader?.length === 0) {
            dispatch(fetchRateHeader({ payload: { key: id } }));
        }
    }, [id]);

    const updateHeader = () => {
        if (id) {
            dispatch(fetchRateHeader({ payload: { key: id } }));
        }
    };

    useEffect(() => {
        if (allContractList?.length === 0) dispatch(getAllContractList({}));
    }, []);

    const isButtonEnabled = () => {
        if (rateHeader[0]?.RA_CD_RateType === 'FRT' && !rateHeader[0]?.RA_HeaderLocked) {
            return (
                (rateHeader[0]?.RA_CD_TransportMode || newTransportMode?.value) &&
                (rateHeader[0]?.RA_CD_ContainerMode || newContainerMode?.value) &&
                ((newContractType?.value === 'NAC' && (rateHeader[0]?.RA_SC || contract)) ||
                    newContractType?.value === 'FAK') &&
                (rateHeader[0]?.RA_ValidFrom || newEffectiveDate) &&
                (rateHeader[0]?.RA_ValidTo || newExpireDate)
            );
        } else if (rateHeader[0]?.RA_CD_StatusValue === 'Draft') {
            return newEffectiveDate || newExpireDate || ref;
        } else {
            return false;
        }
    };

    const getProvider = (contractPk: string) => {
        if (allContractList) {
            const contractArray = allContractList.filter((a: any) => {
                return a.SC_PK === contractPk;
            });
            if (contractArray?.length > 0) {
                return contractArray[0].OR_OrgCode;
            }
        }
        return null;
    };

    const getContractName = (contractPk: string) => {
        if (allContractList?.length > 0) {
            const contractArray = allContractList.filter((a: any) => {
                return a.SC_PK === contractPk;
            });
            if (contractArray?.length > 0) {
                return `${contractArray[0].SC_ContractName} (${contractArray[0].OR_OrgCode})`;
            }
        }
        return null;
    };

    useEffect(() => {
        if (confirmed) {
            setConfirmed(false);
            setShowConfirm(false);
            let payload = {
                RA_ValidFrom: newEffectiveDate || rateHeader[0]?.RA_ValidFrom || null,
                RA_ValidTo: newExpireDate || rateHeader[0]?.RA_ValidTo || null,
                ...(newContainerMode.value ? { RA_CD_ContainerMode: newContainerMode.value } : null),
                ...(ref ? { RA_Reference: ref } : null),
                ...(newContractType?.value ? { RA_CD_FAKNAC: newContractType.value } : null),
                ...(contract ? { RA_SC: contract } : null),
                ...(newTransportMode.value ? { RA_CD_TransportMode: newTransportMode.value?.substring(0, 3) } : null),
                ...(contract ? { RA_Provider: getProvider(contract) } : null),
            };
            if (id) {
                updateDateApi(id, payload)
                    .then((res: any) => {
                        success('Dates have been updated successfully', Position.TOP_RIGHT);
                        updateHeader();
                        setNewEffectiveDate('');
                        setNewExpireDate('');
                        if (setUpdate) setUpdate(!update);
                    })
                    .catch((err: any) => {
                        error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                        updateHeader();
                        setNewEffectiveDate('');
                        setNewExpireDate('');
                    });
            }
        }
    }, [confirmed]);

    const convertTransportMode = (mode: string) => {
        switch (mode) {
            case 'Lan':
                return 'LAND';
            case 'Air':
                return 'Air';
            case 'Sea':
                return 'Sea';
            case 'Rai':
                return 'Rail';
        }
    };

    const showTransportDropdown = () => {
        return (
            <Select
                options={[
                    { label: 'LAND', value: 'Lan' },
                    { label: 'AIR', value: 'Air' },
                    { label: 'RAIL', value: 'Rai' },
                    { label: 'SEA', value: 'Sea' },
                ]}
                // isClearable
                placeholder={'Select Transport Mode'}
                onChange={(option: any) => {
                    if (option?.label) {
                        setNewTransportMode({ value: option?.value, label: option?.label });
                    }
                }}
                id={'transportMode'}
                name={'transportMode'}
                value={
                    newTransportMode.value
                        ? newTransportMode
                        : {
                              value: rateHeader[0]?.RA_CD_TransportMode || '-',
                              label: convertTransportMode(rateHeader[0]?.RA_CD_TransportMode) || '-',
                          }
                }
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                styles={reactSelectStyle}
            />
        );
    };

    const showContractTypeDropdown = () => {
        return (
            <Select
                options={[
                    { label: 'FAK', value: 'FAK' },
                    { label: 'NAC', value: 'NAC' },
                ]}
                // isClearable
                placeholder={'Select Contract Type'}
                onChange={(option: any) => {
                    if (option?.label) {
                        setNewContractType({ value: option?.value, label: option?.label });
                    }
                }}
                id={'contractType'}
                name={'contractType'}
                value={newContractType.value ? newContractType : null}
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                styles={reactSelectStyle}
            />
        );
    };

    const showContainerModeDropdown = () => {
        let ddl_options = [] as any;
        switch (newTransportMode.value) {
            case 'Sea':
                ddl_options = [
                    { label: 'FCL', value: 'FCL' },
                    { label: 'LCL', value: 'LCL' },
                ];
                break;
            case 'Air':
                ddl_options = [{ label: 'LSE', value: 'LSE' }];
                break;
            case 'Lan':
                ddl_options = [
                    { label: 'FTL', value: 'FTL' },
                    { label: 'LTL', value: 'LTL' },
                ];
                break;
            case 'Rai':
                ddl_options = [{ label: 'OTH', value: 'OTH' }];
                break;
        }

        return (
            <Select
                options={ddl_options}
                // isClearable
                placeholder={'Select Container Type'}
                onChange={(option: any) => {
                    if (option?.label) {
                        setNewContainerMode({ value: option?.value, label: option?.label });
                    }
                }}
                id={'contractType'}
                name={'contractType'}
                value={
                    newContainerMode.value
                        ? newContainerMode
                        : {
                              value: rateHeader[0]?.RA_CD_ContainerMode || '-',
                              label: rateHeader[0]?.RA_CD_ContainerMode || '-',
                          }
                }
                components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                }}
                styles={reactSelectStyle}
            />
        );
    };

    const showContractDropdownList = () => {
        return <ContractDropdownList contract={contract} setContract={setContract} />;
    };

    return (
        <div className="booking-proposal-header-fields">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    <h2 className="page-heading">{rateHeader[0]?.RA_Number}</h2>
                </div>

                <div className="header-container-view-eye-reason-wrapper">
                    <div className="header-container-view">
                        <span className="btn-pills container-pill waiting-pill">
                            <span className="pill-text">{rateHeader[0]?.RA_CD_StatusValue}</span>
                        </span>
                    </div>
                </div>
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Rate Type</h6>
                        <div className="details-box-content" title={rateHeader[0]?.RA_CD_RateType || '-'}>
                            {rateHeader[0]?.RA_CD_RateType || '-'}
                        </div>
                    </div>
                    {rateHeader[0]?.RA_CD_RateType === 'FRT' && (
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Primary Provider</h6>
                            <div className="details-box-content" title={rateHeader[0]?.RA_Provider || '-'}>
                                {rateHeader[0]?.RA_Provider || '-'}
                            </div>
                        </div>
                    )}
                    {rateHeader[0]?.RA_CD_RateType === 'FRT' && (
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Transport Mode</h6>
                            {rateHeader[0]?.RA_CD_StatusValue === 'Draft' && !rateHeader[0]?.RA_HeaderLocked ? (
                                showTransportDropdown()
                            ) : (
                                <div className="details-box-content" title={rateHeader[0]?.RA_Provider || '-'}>
                                    {rateHeader[0]?.RA_CD_TransportMode || '-'}
                                </div>
                            )}
                        </div>
                    )}
                    {rateHeader[0]?.RA_CD_RateType === 'FRT' && newTransportMode && (
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Container Type</h6>
                            {rateHeader[0]?.RA_CD_StatusValue === 'Draft' && !rateHeader[0]?.RA_HeaderLocked ? (
                                showContainerModeDropdown()
                            ) : (
                                <div className="details-box-content" title={rateHeader[0]?.RA_CD_ContainerMode || '-'}>
                                    {rateHeader[0]?.RA_CD_ContainerMode || '-'}
                                </div>
                            )}
                        </div>
                    )}
                    {rateHeader[0]?.RA_CD_RateType === 'FRT' &&
                        (newTransportMode.value === 'Sea' || rateHeader[0].RA_CD_TransportMode === 'Sea') && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Contract Type</h6>
                                {rateHeader[0]?.RA_CD_StatusValue === 'Draft' &&
                                newTransportMode.value === 'Sea' &&
                                !rateHeader[0]?.RA_HeaderLocked ? (
                                    showContractTypeDropdown()
                                ) : (
                                    <div className="details-box-content" title={rateHeader[0]?.RA_CD_FAKNAC || '-'}>
                                        {rateHeader[0]?.RA_CD_FAKNAC || '-'}
                                    </div>
                                )}
                            </div>
                        )}

                    {(rateHeader[0]?.RA_CD_FAKNAC === 'NAC' || newContractType.value === 'NAC') && (
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Contract</h6>
                            {newContractType.value === 'NAC' && !rateHeader[0]?.RA_HeaderLocked ? (
                                showContractDropdownList()
                            ) : (
                                <div className="details-box-content" title={rateHeader[0]?.RA_Provider || '-'}>
                                    {getContractName(rateHeader[0]?.RA_SC) || '-'}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Reference</h6>
                        <input
                            placeholder="Enter Reference (Max. 50 Characters)"
                            type="text"
                            className="modal-input input-text ref"
                            id={'reference'}
                            name={'reference'}
                            onChange={(event) => {
                                event.preventDefault();
                                setRef(event.target.value);
                            }}
                            value={ref ? ref : rateHeader[0]?.RA_Reference ? rateHeader[0].RA_Reference : ref}
                            maxLength={50}
                        ></input>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Valid From</h6>
                        {/* todo */}
                        <div className="modal-input-layout-item">
                            <DatePicker
                                disabled={rateHeader[0]?.RA_CD_StatusValue !== 'Draft'}
                                // minDate={new Date(moment().toISOString())}
                                maxDate={
                                    newExpireDate
                                        ? new Date(moment(newExpireDate).toISOString())
                                        : rateHeader[0]?.RA_ValidTo
                                        ? new Date(moment(rateHeader[0]?.RA_ValidTo).toISOString())
                                        : null
                                }
                                onChange={(value: any) => {
                                    setNewEffectiveDate(value);
                                }}
                                value={
                                    newEffectiveDate
                                        ? moment(newEffectiveDate).format('DD-MM-YYYY')
                                        : rateHeader[0]?.RA_ValidFrom
                                        ? moment(rateHeader[0]?.RA_ValidFrom).format('DD-MM-YYYY')
                                        : '-'
                                }
                                placeholderText="Select Date"
                                customInput={
                                    <div className="modal-input-field-holder date-input-holder">
                                        <input
                                            className="modal-input input-text with-border date-input input-date-cursor rate-header"
                                            type="text"
                                            placeholder={'Select Date'}
                                            value={
                                                newEffectiveDate
                                                    ? moment(newEffectiveDate).format('DD-MM-YYYY')
                                                    : rateHeader[0]?.RA_ValidFrom
                                                    ? moment(rateHeader[0]?.RA_ValidFrom).format('DD-MM-YYYY')
                                                    : '-'
                                            }
                                        />
                                    </div>
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Valid To</h6>
                        <div className="modal-input-layout-item">
                            <DatePicker
                                disabled={rateHeader[0]?.RA_CD_StatusValue !== 'Draft'}
                                minDate={
                                    newEffectiveDate
                                        ? new Date(moment(newEffectiveDate).add(1, 'days').toISOString())
                                        : rateHeader[0]?.RA_ValidFrom
                                        ? new Date(moment(rateHeader[0]?.RA_ValidFrom).add(1, 'days').toISOString())
                                        : null
                                }
                                onChange={(value: any) => {
                                    setNewExpireDate(value);
                                }}
                                value={
                                    newExpireDate
                                        ? moment(newExpireDate).format('DD-MM-YYYY')
                                        : rateHeader[0]?.RA_ValidTo
                                        ? moment(rateHeader[0]?.RA_ValidTo).format('DD-MM-YYYY')
                                        : '-'
                                }
                                placeholderText="Select Date"
                                customInput={
                                    <div className="modal-input-field-holder date-input-holder">
                                        <input
                                            className="modal-input input-text with-border date-input input-date-cursor rate-header"
                                            type="text"
                                            placeholder={'Select Date'}
                                            value={
                                                newExpireDate
                                                    ? moment(newExpireDate).format('DD-MM-YYYY')
                                                    : rateHeader[0]?.RA_ValidTo
                                                    ? moment(rateHeader[0]?.RA_ValidTo).format('DD-MM-YYYY')
                                                    : '-'
                                            }
                                        />
                                    </div>
                                }
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                            />
                        </div>
                    </div>
                    {isButtonEnabled() && (
                        <button
                            className="k-button k-grid-save-changes app-btn icon-button app-btn-secondary header-button"
                            onClick={() => {
                                if (rateHeader[0]?.RA_CD_RateType === 'FRT') setShowConfirm(true);
                                else setConfirmed(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faSave} />
                            Save
                        </button>
                    )}
                    {showConfirm && (
                        <ConfirmModal
                            setConfirmed={setConfirmed}
                            modalView={showConfirm}
                            setModalView={setShowConfirm}
                        />
                    )}
                    {refreshModal && <RefreshScheduleModal modalView={refreshModal} setModalView={setRefreshModal} />}
                </div>
                <div className="refresh-schedules">
                    <button className="app-btn app-btn-secondary footer-btn" onClick={() => setRefreshModal(true)}>
                        Refresh Schedules
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
