import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import {
    getBasicDetails,
    addRequiredDetails,
    addAdditionalDetails,
    getPreviewData,
    submitAllocation,
    approveAllocation,
} from '../../../services/apis/allocationOrder/basicDetailsApi';
import {
    getagents,
    getBuyers,
    getCurrency,
    getdestinationaddress,
    getFclService,
    getFrieghtTerm,
    getIncoterm,
    getOrderType,
    getpaymentterms,
    // getPorts,
    getShippingmode,
    getReleaseType,
    getStoreIDData,
} from '../../../services/apis/purchase_order/basicDetails';
import {
    saveBasicDetails,
    reqSuccess,
    adSuccess,
    saveAllData,
    saveAddress,
    saveBuyer,
    saveStoreID,
    saveAgentData,
    savePreviewData,
    aoSubmited,
    aoConfirmed,
} from '../../reducers/allocationOrder/basicDetails';
import { isLoadingfn } from '../../reducers/vendorReducer';

function* fetchAlldata({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        const orderType: AxiosResponse<any[]> = yield call(getOrderType);
        const shippingmode: AxiosResponse<any[]> = yield call(getShippingmode);
        const fclservicetype: AxiosResponse<any[]> = yield call(getFclService);
        const freightterms: AxiosResponse<any[]> = yield call(getFrieghtTerm);
        // const portlist: AxiosResponse<any[]> = yield call(getPorts, { payload });
        const incoTerm: AxiosResponse<any[]> = yield call(getIncoterm);
        const releaseType: AxiosResponse<any[]> = yield call(getReleaseType);
        const paymentterms: AxiosResponse<any[]> = yield call(getpaymentterms);
        const currency: AxiosResponse<any[]> = yield call(getCurrency);
        //const address: AxiosResponse<any[]> = yield call(getdestinationaddress, payload.orgCode);

        //const buyers: AxiosResponse<any[]> = yield call(getBuyers);

        payload['orderType'] = orderType;
        payload['shippingmode'] = shippingmode;
        payload['fclservicetype'] = fclservicetype;
        payload['freightterms'] = freightterms;
        // payload['portlist'] = portlist;
        payload['incoTerm'] = incoTerm;
        payload['releaseType'] = releaseType;
        payload['paymentterms'] = paymentterms;
        //payload['destinationAddress'] = address;
        payload['currency'] = currency;
        yield put({ type: saveAllData, payload: payload });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}

function* getAddress({ payload }: { payload: any; type: any }) {
    try {
        const agents: AxiosResponse<any[]> = yield call(getdestinationaddress, payload.orgCode);
        yield put({ type: saveAddress, payload: agents });
    } finally {
        // console.log('first');
    }
}
function* getBuyer({ payload }: { payload: any; type: any }) {
    try {
        const agents: AxiosResponse<any[]> = yield call(getBuyers, payload.orgCode);
        yield put({ type: saveBuyer, payload: agents });
    } finally {
        // console.log('first');
    }
}
function* getStoreIDList({ payload }: { payload: any; type: any }) {
    try {
        const storeIDs: AxiosResponse<any[]> = yield call(getStoreIDData, payload.clientCode);
        yield put({ type: saveStoreID, payload: storeIDs });
    } finally {
        // console.log('first');
    }
}

function* getAgents({ payload }: { payload: any; type: any }) {
    try {
        const agents: AxiosResponse<any[]> = yield call(getagents, payload);
        yield put({ type: saveAgentData, payload: agents });
    } finally {
        // console.log('first');
    }
}

function* createAo({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield call(addRequiredDetails, payload.payload);
        const response: AxiosResponse = yield call(getBasicDetails, payload.id);
        yield put({ type: saveBasicDetails, payload: response });
        yield put({ type: reqSuccess, payload: true });
        //yield put({ type: saveAgentData, payload: agents });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        // console.log('first');
    }
}
function* addAdditional({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield call(addAdditionalDetails, payload.payload);
        const response: AxiosResponse = yield call(getBasicDetails, payload.id);
        yield put({ type: saveBasicDetails, payload: response });
        yield put({ type: adSuccess, payload: true });
        //yield put({ type: saveAgentData, payload: agents });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        // console.log('first');
    }
}

function* fetchBasic({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        const response: AxiosResponse = yield call(getBasicDetails, payload);
        yield put({ type: saveBasicDetails, payload: response });
    } catch (error) {
        //yield put({ type: isLoadingfn, payload: false });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}

function* fetchPreview({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        const response: AxiosResponse = yield call(getPreviewData, payload);
        yield put({ type: savePreviewData, payload: response });
    } catch (error) {
        yield put({ type: isLoadingfn, payload: false });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}
function* submitAO({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield call(submitAllocation, payload.id);
        yield put({ type: aoConfirmed, payload: true });
        yield put({ type: aoSubmited, payload: { status: false, name: payload.name } });
    } catch (error) {
        //yield put({ type: isLoadingfn, payload: false });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}
function* approvalAO({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield call(approveAllocation, payload.id);
        yield put({ type: aoSubmited, payload: { status: true, name: payload.name } });
    } catch (error) {
        //yield put({ type: isLoadingfn, payload: false });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
    }
}

function* aoBasicDetailsSaga() {
    yield takeLatest('allocation/getDatas', fetchAlldata);
    yield takeEvery('allocation/createAo', createAo);
    yield takeEvery('allocation/additionalAo', addAdditional);
    yield takeEvery('allocation/fetchBasicDetails', fetchBasic);
    yield takeEvery('allocation/getAddress', getAddress);
    yield takeEvery('allocation/getBuyer', getBuyer);
    yield takeEvery('allocation/getStoreID', getStoreIDList);
    yield takeEvery('allocation/getAgent', getAgents);
    yield takeEvery('allocation/fetchPreview', fetchPreview);
    yield takeEvery('allocation/submitAllocation', submitAO);
    yield takeEvery('allocation/approveAllocation', approvalAO);
}

export default aoBasicDetailsSaga;
