import React, { useState, useEffect } from 'react';
import '../NoNetwork/no-internet.scss';
import { useLocation } from 'react-router-dom';

interface Props {
    children: React.ReactNode;
}
interface ServiceErrorProps {
    serviceStatus: { orderService: boolean; mgtService: boolean; bkgService: boolean };
}
const ServiceError: React.FC<ServiceErrorProps> = ({ serviceStatus }) => {
    return (
        <div className="no-network-page msg-page">
            <div className="msg-page-content-holder">
                <span className="network-icon-holder">
                    <svg className="svg-icon no-network-icon maintence-icon">
                        <use xlinkHref="#maintenanceIcon"></use>
                    </svg>
                </span>
                <p className="msg-page-heading">We have encountered an error</p>
                {(() => {
                    if (!serviceStatus.orderService && !serviceStatus.mgtService && !serviceStatus.bkgService) {
                        return <p className="msg-page-content">All services are down</p>;
                    } else if (!serviceStatus.mgtService) {
                        return (
                            <>
                                <p className="msg-page-content">Please feel free to use Forwarding while we fix this</p>
                                <button
                                    className="app-btn app-btn-primary retry-btn"
                                    onClick={() => (window.location.href = '/purchase_order')}
                                >
                                    Go
                                </button>
                            </>
                        );
                    } else if (!serviceStatus.orderService || !serviceStatus.bkgService) {
                        return (
                            <>
                                <p className="msg-page-content">Please feel free to use Profiles while we fix this</p>
                                <button
                                    className="app-btn app-btn-primary retry-btn"
                                    onClick={() => (window.location.href = '/profile')}
                                >
                                    Go
                                </button>
                            </>
                        );
                    } else {
                        return null;
                    }
                })()}
            </div>
        </div>
    );
};

const Index: React.FC<Props> = ({ children }): JSX.Element => {
    const location = useLocation();

    const [serviceStatus, setServiceStatus] = useState({ orderService: true, mgtService: true, bkgService: true });
    useEffect(() => {
        const orderServiceCb = () => {
            if (localStorage.getItem('orderServiceFailed') === 'true') {
                setServiceStatus((prev) => ({ ...prev, orderService: false }));
            } else {
                setServiceStatus((prev) => ({ ...prev, orderService: true }));
            }
        };
        const mgtServiceCb = () => {
            if (localStorage.getItem('mgtServiceFailed') === 'true') {
                setServiceStatus((prev) => ({ ...prev, mgtService: false }));
            } else {
                setServiceStatus((prev) => ({ ...prev, mgtService: true }));
            }
        };
        const bkgServiceCb = () => {
            if (localStorage.getItem('bkgServiceFailed') === 'true') {
                setServiceStatus((prev) => ({ ...prev, bkgService: false }));
            } else {
                setServiceStatus((prev) => ({ ...prev, bkgService: true }));
            }
        };

        window.addEventListener('orderServiceFailedEvt', orderServiceCb);
        window.addEventListener('mgtServiceFailedEvt', mgtServiceCb);
        window.addEventListener('bkgServiceFailedEvt', bkgServiceCb);

        return () => {
            window.removeEventListener('orderServiceFailedEvt', orderServiceCb);
            window.removeEventListener('mgtServiceFailedEvt', mgtServiceCb);
            window.removeEventListener('bkgServiceFailedEvt', bkgServiceCb);
        };
    }, []);

    const getServiceComponent = () => {
        if (
            location.pathname === '/' ||
            location.pathname.includes('/dashboard') ||
            location.pathname.includes('/tracking')
        ) {
            if (!serviceStatus.orderService && !serviceStatus.mgtService && !serviceStatus.bkgService) {
                return <ServiceError serviceStatus={serviceStatus} />;
            } else {
                return children;
            }
        } else if (
            location.pathname.includes('/purchase_order') ||
            location.pathname.includes('/inbound') ||
            location.pathname.includes('/allocation_orders') ||
            location.pathname.includes('/receipt') ||
            location.pathname.includes('/inventory')
        ) {
            if (!serviceStatus.orderService) {
                return <ServiceError serviceStatus={serviceStatus} />;
            } else {
                return children;
            }
        } else if (location.pathname.includes('/booking_list')) {
            if (!serviceStatus.bkgService) {
                return <ServiceError serviceStatus={serviceStatus} />;
            } else {
                return children;
            }
        } else if (location.pathname.includes('/profile')) {
            if (!serviceStatus.mgtService) {
                return <ServiceError serviceStatus={serviceStatus} />;
            } else {
                return children;
            }
        } else {
            return children;
        }
    };

    return <>{getServiceComponent()}</>;
};

export default Index;
