/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface Agents {
    agentName: string;
    agentCode: string;
    agentPortDetail: {
        location: string;
    };
}

interface AccountManagerDetails {
    [key: string]: {
        clientName: string;
        clientCode: string;
        userName: string;
        firstName: string;
        email: string;
    };
}
export interface AccountManager {
    [key: string]: AccountManagerDetails;
}
export interface ReassignPoDetails {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    shippingmode: string;
    createdDate: string;
    clientName: string;
    clientCode: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    originPortName: string;
    originPortCode: string;
    destinationPort: string;
    destinationPortName: string;
    destinationPortCode: string;
    destinationType: string;
    isHeaderOnlyPo: boolean;
    isTriangleShipment: boolean;
    releaseType: string;
    createdBy: string;
    createdOn: string;
    totKGS: string;
    totCBM: string;
    goodsReadyDate: string;
    startShipWindowDate: string;
    skuCount: number;
    isMultiAddress: string;
    type: string;
    notificationIds: string;
    isViewed: string;
}

interface ReassignPoState {
    isLoading: boolean;
    reassignPo: ReassignPoDetails[];
    distinctPo: any;
    agents: Agents[];
    accountManager: AccountManager[];
    reassignPoSuccess: any;
    reassignPoError: any;
    isError?: boolean;
}

const initialState: ReassignPoState = {
    isLoading: false,
    reassignPo: [],
    distinctPo: {},
    accountManager: [],
    agents: [],
    reassignPoSuccess: undefined,
    reassignPoError: undefined,
    isError: false,
};

export const reassignPoSlice = createSlice({
    name: 'reassignPo',
    initialState,
    reducers: {
        fetchReassignPoList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchReassignPoListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.reassignPo = action.payload?.data?.info?.poListingDtos ?? [];
        },
        fetchReassignPoListFailure(state, action) {
            state.isLoading = false;
            state.reassignPo = action.payload;
        },
        fetchDistinctPoList: (state, _action) => {
            // state.isLoading = true;
            state.isError = false;
        },
        fetchDistinctPoListSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.distinctPo = action.payload?.data?.info;
        },
        fetchDistinctPoListFailure(state, action) {
            // state.isLoading = false;
            state.distinctPo = action.payload;
        },
        fetchAgentList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchAgentListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.agents = action.payload?.data?.info;
        },
        fetchAgentListFailure: (state, action) => {
            state.isLoading = false;
            state.agents = action.payload;
        },
        fetchAccountManagerList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        fetchAccountManagerListSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.agents = action.payload?.data?.info;
        },
        fetchAccountManagerListFailure: (state, action) => {
            state.isLoading = false;
            state.agents = action.payload;
        },
        updateAgentPo: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
        },
        updateAgentPoSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.reassignPoSuccess = action.payload;
            state.reassignPoError = {};
        },
        updateAgentPoFailure: (state, action) => {
            state.isLoading = false;
            state.reassignPoSuccess = {};
            state.reassignPoError = action.payload;
        },
        clearFailurePo: (state, _action) => {
            state.reassignPoError = undefined;
            state.reassignPoSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchReassignPoList,
    fetchReassignPoListSuccess,
    fetchReassignPoListFailure,
    fetchDistinctPoList,
    fetchDistinctPoListSuccess,
    fetchDistinctPoListFailure,
    fetchAgentList,
    fetchAgentListSuccess,
    fetchAgentListFailure,
    updateAgentPo,
    updateAgentPoSuccess,
    updateAgentPoFailure,
    clearFailurePo,
} = reassignPoSlice.actions;

export const selectReassignPoLoading = (state: RootState) => state.reassignPoData.isLoading;
export const selectReassignPo = (state: RootState) => state.reassignPoData.reassignPo;
export const selectDistinctPo = (state: RootState) => state.reassignPoData.distinctPo;
export const selectAgents = (state: RootState) => state.reassignPoData.agents;
export const selectReassignPoSuccess = (state: RootState) => state.reassignPoData.reassignPoSuccess;
export const selectReassignPoError = (state: RootState) => state.reassignPoData.reassignPoError;
export const selectReassignPoIsError = (state: RootState) => state.reassignPoData.isError;

export default reassignPoSlice.reducer;
