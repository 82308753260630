export const getStatusTitle = (val: string, type?: string) => {
    let title = '';
    switch (val) {
        case 'total':
            title = 'Total';
            break;
        case 'draft':
            title = 'Draft';
            break;
        case 'awaitingEnrichment':
            title = 'Awaiting Enrichment';
            break;
        case 'readyForBooking':
            title = `Ready ${type === 'bookings' ? 'for' : 'For'} Booking`;
            break;
        case 'readyforBooking':
            title = 'Ready For Booking';
            break;
        case 'partiallyBooked':
            title = 'Partially Booked';
            break;
        case 'booked':
            title = 'Booked';
            break;
        case 'Draft':
            title = 'pending';
            break;
        case 'pendingWithForwarder':
            title = 'Pending with Forwarder';
            break;
        case 'inBoundInProgress':
            title = 'Inbound In Progress';
            break;
        case 'submitted':
            title = 'Submitted';
            break;
        case 'awaitingClientApproval':
            title = 'Awaiting Client Approval';
            break;
        case 'awaitingForwarderApproval':
            title = 'Awaiting Forwarder Approval';
            break;
        case 'draftBooking':
            title = 'Draft Booking';
            break;
        case 'draftBookingProposal':
            title = 'Draft Booking Proposal';
            break;
        case 'rejectedByClientAgenttoAction':
            title = 'Rejected by Client: Agent to Action';
            break;
        case 'rejectedByClientForwardertoAction':
            title = 'Rejected by Client: Forwarder to Action';
            break;
        case 'rejectedByForwarder':
            title = 'Rejected by Forwarder';
            break;
        case 'bpActiveStatus':
            title = 'Active';
            break;
        default:
            title = val;
            break;
    }
    return title;
};
