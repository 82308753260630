import React from 'react';
import { Helmet } from 'react-helmet-async';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { success, error, warning, info, Position } from 'src/utils/toast';

interface GridLayoutProps {
    scPk: string;
    slPk: string;
    readonly?: boolean;
}

//@ts-ignore
(window).toast = (type: string, message: string) => {

    switch (type) {
        case "success":
            success(message, Position.TOP_RIGHT)
            break
        case "error":
            error(message, Position.TOP_RIGHT)
            break
        case "warning":
            warning(message, Position.TOP_RIGHT)
            break
        case "info":
            info(message, Position.TOP_RIGHT)
            break
    }
}

const GridLayout = ({ scPk, slPk, readonly }: GridLayoutProps) => {
    const token = localStorage.getItem('token') ?? '';

    return (
        <Helmet>
            <script>
                {`
                $(document).ready(function () {
                const params = new URLSearchParams(window.location.search);
                let endpoint = '';
                for (const p of params) {
                    if (p[0] == 'g') endpoint = p[1];
                }
            
                const gridParam = endpoint.replaceAll('/', '~');
            
                var model, columns;
                //
                const host = '${externalBaseUrl}';
                //
                const isReadonly = ${readonly};
                const headers = { Authorization: 'Bearer ' + '${token}' };
            
                const init = async () => {
                // localStorage.token = token;
                // needs to come from the UI somehow?
                const SC_PK = '${scPk}';
                const SL_PK = '${slPk}';
            
                $('#grid').kendoGrid({
                    dataSource: {
                    serverPaging: true,
                    serverSorting: true,
                    serverFiltering: true,
                    transport: {
                        // i have hadcoded some stuff for now, but yeah. this needs looking at...
                        read: {
                        url:
                            host +
                            '/cix/scr/admin/carrier/' +
                            SL_PK +
                            '/contract/' +
                            SC_PK +
                            '/groups',
                        dataType: 'json',
                        headers: headers,
                        },
                        create: {
                        url:
                            host +
                            '/cix/scr/admin/carrier/' +
                            SL_PK +
                            '/contract/' +
                            SC_PK +
                            '/group',
                        headers: headers,
                        type: 'POST',
                        contentType: 'application/json',
                        complete: function (data) {
                            $('#grid').data('kendoGrid').dataSource.read();;
                        }
                        },
                        update: {
                        url: function (data) {
                            return (
                            host +
                            '/cix/scr/admin/carrier/' +
                            SL_PK +
                            '/contract/' +
                            SC_PK +
                            '/group/' +
                            data.CG_PK
                            );
                        },
                        headers: headers,
                        type: 'PATCH',
                        contentType: 'application/json',
                        },
                        destroy: {
                        url: function (data) {
                            return (
                            host +
                            '/cix/scr/admin/carrier/' +
                            SL_PK +
                            '/contract/' +
                            SC_PK +
                            '/group/' +
                            data.CG_PK
                            );
                        },
                        dataType: 'json',
                        headers: headers,
                        type: 'DELETE',
                        },
                        parameterMap: function (data, type) {
                            if (type == 'update' || type == 'create') {
                                data = JSON.stringify(data);
                            }
                            return data;
                        },
                    },
                    error: function(e) {
                        toast("error",e.xhr.responseJSON.message);
                        e.sender.read()
                    },
                    schema: {
                        total: function (response) {
                            return response.total; // total is returned in the "total" field of the response
                        },
                        data: function (response) {
                            return response.data;
                        },
                        model: {
                            id: 'CG_PK',
                            fields: {
                                CG_PK: { type: 'string' },
                                CG_GroupNumber: {
                                    type: 'number',
                                    required: { message: 'Line/Group number is required' },
                                    validation: { min: 1, required: true, max: 100 },
                                },
                                CG_TeuCommitment: {
                                    type: 'number',
                                    required: { message: 'TEU commitment is required' },
                                    validation: { min: 1, required: true, max: 1000 },
                                },
                                CG_CommitmentUnit: {
                                    type: 'string',
                                    required: true,
                                    defaultValue: 'WKL',
                                },
                            },
                        },
                    },
                    pageSize: 50,
                    sort: { field: 'CG_GroupNumber', dir: 'asc' },
                        //group: groupBy
                    },
                    height: '600',
                    detailInit: detailInit,
                    groupable: false,
                    sortable: true,
                    selectable: true,
                    filterable: {
                        extra: false,
                    },
                    columnMenu: {
                        componentType: 'modern',
                        columns: {
                            sort: 'asc',
                        },
                        filterable: {
                            extra: false,
                        },
                    },
                    resizable: true,
                    reorderable: true,
                    pageable: {
                        refresh: true,
                        buttonCount: 5,
                        pageSizes: [25, 50, 100, 250, 500, 1000, 'all'],
                    },
                    columns: [
                    {
                        field: 'CG_GroupNumber',
                        title: 'Group Number',
                        width: '110px',
                    },
                    {
                        field: 'CG_TeuCommitment',
                        title: 'TEU Commitment',
                        width: '110px',
                    },
                    {
                        field: 'CG_CD_CommitmentUnit',
                        title: 'Commitment Unit',
                        width: '110px',
                        editor: commitment,
                    },
                    { 
                        command: ['edit', 'destroy'], 
                        title: '&nbsp;', width: '250px',
                        hidden: isReadonly ? true : false 
                    },
                    ],
            
                    editable: 'inline',
                    selectable: true,
                    // search: {
                    //     fields: searchfields // Or, specify multiple fields by adding them to the array, e.g ["name", "age"]
                    // },
                    excel: {
                        fileName: 'Kendo UI Grid Export.xlsx',
                    },
                    dataBound: function (e) {},
                    toolbar: isReadonly ? [] : ['create'],
                });
            
                function commitment(container, options) {
                    $(
                    '<input required name="' +
                        options.field +
                        '" data-value-primitive="true" />'
                    )
                    .appendTo(container)
                    .kendoDropDownList({
                        autoBind: true,
                        dataTextField: 'text',
                        dataValueField: 'value',
                        dataSource: [{ text: 'WKL (Weekly)', value: 'WKL' }],
                        index: 0,
                    });
                }
            
                function detailInit(e) {
                    $('<div/>')
                    .appendTo(e.detailCell)
                    .kendoGrid({
                        autoBind: true,
                        toolbar: isReadonly ? [] : ['create'],
                        dataSource: {
                        serverPaging: true,
                        serverSorting: true,
                        serverFiltering: true,
                        autoBind: true,
                        transport: {
                            read: {
                                url:
                                    host +
                                    '/cix/scr/admin/carrier/' +
                                    SL_PK +
                                    '/contract/' +
                                    SC_PK +
                                    '/group/' +
                                    e.data.CG_PK +
                                    '/ports',
                                dataType: 'json',
                                headers: headers,
                            },
                            update: {
                                url: function (data) {
                                    return (
                                    host +
                                    '/cix/scr/admin/carrier/' +
                                    SL_PK +
                                    '/contract/' +
                                    SC_PK +
                                    '/group/' +
                                    data.SO_CG +
                                    '/port/' +
                                    data.SO_PK
                                    );
                                },
                                dataType: 'json',
                                headers: headers,
                                type: 'PATCH',
                                contentType: 'application/json',
                            },
                            create: {
                                url:
                                    host +
                                    '/cix/scr/admin/carrier/' +
                                    SL_PK +
                                    '/contract/' +
                                    SC_PK +
                                    '/group/' +
                                    e.data.CG_PK +
                                    '/port',
                                dataType: 'json',
                                headers: headers,
                                type: 'POST',
                                contentType: 'application/json',
                            },
                            destroy: {
                                url: function (data) {
                                    return (
                                    host +
                                    '/cix/scr/admin/carrier/' +
                                    SL_PK +
                                    '/contract/' +
                                    SC_PK +
                                    '/group/' +
                                    data.SO_CG +
                                    '/port/' +
                                    data.SO_PK
                                    );
                                },
                                headers: headers,
                                type: 'DELETE',
                            },
                            parameterMap: function (data, type) {
                                if (type == 'update' || type == 'create') {
                                    data = JSON.stringify(data);
                                }
                                return data;
                            },
                        },
                        error: function(e) {
                            toast("error",e.xhr.responseJSON.message);
                            e.sender.read()
                        },
                        schema: {
                            total: function (response) {
                                return response.total; // total is returned in the "total" field of the response
                            },
                            data: function (response) {
                                return response.data;
                            },
                            model: {
                                id: 'SO_PK',
                                fields: {
                                    SO_PK: { type: 'string' },
                                    SO_CG: { type: 'string' },
                                    SO_PortOfLoading: {
                                    type: 'string',
                                    validation: { required: true },
                                    },
                                    SO_PortOfDischarge: {
                                    type: 'string',
                                    validation: { required: true },
                                    },
                                },
                            },
                        },
                        pageSize: 50,
                        sort: { field: 'SO_PortOfLoading', dir: 'asc' },
                        },
                        scrollable: false,
                        sortable: true,
                        pageable: true,
                        editable: 'inline',
                        batch: true,
                        columns: [
                        {
                            field: 'SO_PortOfLoading',
                            editor: portDropDownEditor,
                            title: 'Port Of Loading',
                            width: '110px',
                        },
                        {
                            field: 'SO_PortOfDischarge',
                            editor: portDropDownEditor,
                            title: 'Port Of Discharge',
                            width: '110px',
                        },
                        {
                            command: ['edit', 'destroy'],
                            title: '&nbsp;',
                            width: '250px',
                            hidden: isReadonly ? true : false
                        },
                        ],
                    });
            
                    function portDropDownEditor(container, options) {
                    $(
                        '<input required name="' +
                        options.field +
                        '" data-value-primitive="true" />'
                    )
                        .appendTo(container)
                        .kendoDropDownList({
                        autoBind: true,
                        filter: 'contains',
                        minLength: 3,
                        dataTextField: 'PO_UserDefinedText4',
                        dataValueField: 'PO_PortId',
                        dataSource: {
                            serverFiltering: true,
                            transport: {
                            read: {
                                url:
                                host +
                                '/cix/scr/admin/port/lst/PO_PortId/PO_UserDefinedText4',
                                dataType: 'json',
                                headers: headers,
                            },
                            },
                            schema: {
                            total: function (response) {
                                return response.total; // total is returned in the "total" field of the response
                            },
                            data: function (response) {
                                return response.data;
                            },
                            model: {
                                id: 'PO_PK',
                                fields: {
                                PO_PortId: { type: 'string' },
                                PO_UserDefinedText4: { type: 'string' },
                                },
                            },
                            },
                        },
                        select: function (e) {
                            var item = e.item;
                            var text = item.text();
                            // Use the selected item or its text
                        },
                        });
                    }
                }
                };
            
                function SaveFilter() {
                    $('#grid')
                        .find('#layoutDDL')
                        .kendoDropDownList({
                        autoBind: true,
                        optionLabel: 'My Layouts',
                        messages: {
                            noData: 'No saved Layouts',
                        },
                        dataTextField: 'UD_UserDefinedText1',
                        dataValueField: 'UD_Data',
                        dataSource: {
                            transport: {
                            read: {
                                // this needs to be worked on for the user's saved filters.
                                url: host + '/cix/user/me/userdata/gridLayout/' + gridParam,
                                dataType: 'json',
                                headers: headers,
                            },
                            },
                            schema: {
                            data: function (response) {
                                // console.log(response.data[0].CD_ConfigDetails)
                                return response.data;
                            },
                            },
                        },
                        change: function () {
                            let value = this.value();
                            let gridData = JSON.parse(atob(value));
                            console.log(gridData);
                            // change
                            if (gridData.dataSource.transport.read.headers.Authorization) {
                            console.log(
                                gridData.dataSource.transport.read.headers.Authorization
                            );
                            gridData.dataSource.transport.read.headers.Authorization =
                                'Bearer ' + '${token}';
                            }
                
                            $('#grid').data('kendoGrid').setOptions(gridData);
                            SaveFilter();
                        },
                    });
                }
            
                function removeDuplicates(str) {
                    if (str) {
                        return [...new Set(str.split(', '))].toString();
                    } else {
                        return '';
                    }
                }
            
                // $(document).ready(function () {
                init().then(function () {
                    notification = $('#notification')
                    .kendoNotification({
                        position: {
                        pinned: true,
                        top: 30,
                        right: 30,
                        },
                        autoHideAfter: 2000,
                        stacking: 'down',
                        templates: [
                        {
                            type: 'info',
                            template: $('#emailTemplate').html(),
                        },
                        {
                            type: 'error',
                            template: $('#errorTemplate').html(),
                        },
                        {
                            type: 'success',
                            template: $('#successTemplate').html(),
                        },
                        ],
                    })
                    .data('kendoNotification');
            
                    SaveFilter();
                });
                // });
            
                const updloadSettings = () => {
                    kendo.prompt('Please enter a filter name', '').then(
                        function (data) {
                        var settings = {
                            url: host + '/cix/user/me/userdata/',
                            method: 'POST',
                            timeout: 0,
                            headers: {
                            Authorization: 'Bearer ' + '${token}',
                            'Content-Type': 'application/json',
                            },
                            data: JSON.stringify({
                                UD_Type: 'gridLayout',
                                UD_DataKey: gridParam,
                                UD_UserDefinedText1: data,
                                UD_Data: btoa(
                                    kendo.stringify($('#grid').data('kendoGrid').getOptions())
                                ),
                            }),
                        };
                
                        $.ajax(settings).done(function (response) {
                            kendo.alert(kendo.format('Grid layout saved'));
                            SaveFilter();
                        });
                    },
                    function () {
                    //kendo.alert("Cancel entering value.");
                    }
                );
                };
            });
                `}
            </script>
        </Helmet>
    );
};

export default GridLayout;
