import React, { useEffect, useState } from 'react';
import '../../BookingProposal/BookingAddedGrid/cancel-booking-modal.scss';
import '../../../assets/scss/components/_layout.scss';
import '../../../assets/scss/components/_modal.scss';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    agentRejection,
    clearFailureApproval,
    fetchReassignApprovalList,
    ReassignApprovalDetails,
    selectReassignApprovalError,
    selectReassignApprovalIsError,
    selectReassignApprovalSuccess,
} from 'src/store/reducers/reassignAgent/approvalReducer';
import { error, Position, success } from 'src/utils/toast';

type ModalProps = {
    setSelected: (value: [] | ((prevVar: []) => [])) => void;
    payload: any;
    data: ReassignApprovalDetails[];
    setShowModal: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
};

const Index: React.FC<ModalProps> = ({ payload, setSelected, data, setShowModal, setDisplayCancel }) => {
    const [reason, setReason] = useState('');
    const { userName, fullName } = useUserProfile();
    const dispatch = useAppDispatch();
    const isErrorReject = useAppSelector(selectReassignApprovalIsError);
    const reassignRejectSuccess = useAppSelector(selectReassignApprovalSuccess);
    const reassignRejectError = useAppSelector(selectReassignApprovalError);

    const onSubmit = () => {
        dispatch(
            agentRejection({
                data,
                rejectReason: reason,
                userName,
                fullName,
            })
        );
    };

    useEffect(() => {
        if (isErrorReject) {
            error(reassignRejectError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailureApproval([]));
            // } else if (
            //     reassignRejectSuccess?.length &&
            //     (reassignRejectSuccess[0]?.status === 200 || reassignRejectSuccess[0]?.status === 201)
            // ) {
        } else if (reassignRejectSuccess?.status === 200 || reassignRejectSuccess?.status === 201) {
            success(`Selected PO/Bookings have been rejected and assigned back to current agent`, Position.TOP_RIGHT);
            dispatch(clearFailureApproval([]));
            setShowModal(false);
            dispatch(fetchReassignApprovalList({ ...payload }));
            setSelected([]);
        }
    }, [isErrorReject, reassignRejectSuccess?.data, reassignRejectError]);

    return (
        <div className="app-modal">
            <div className="modal-content-holder reassign-modal medium-popup booking-cancellation-modal">
                <div className="modal-header">
                    <div className="title">Reject Reassignment</div>
                    <button
                        onClick={() => (reason ? setDisplayCancel(true) : setShowModal(false))}
                        className="app-btn modal-close-btn"
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Once rejected , the selected POs/Bookings will be assigned back to their current agent
                        </div>
                    </div>
                    <form className="modal-form layout-holder one-column">
                        <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                            <label htmlFor="name" className="modal-input-label">
                                Reason<span className="mandatory-field-text">*</span>
                            </label>
                            <div className="modal-input-field-holder">
                                <textarea
                                    name="reason"
                                    className="input-text"
                                    placeholder="Enter reason for reassignment to a maximum of 250 characters"
                                    required
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                    maxLength={250}
                                ></textarea>
                            </div>

                            {/* {reasonError ? (
                                    <div className="error-text">
                                        Please provide a reason for booking proposal cancellation
                                    </div>
                                ) : null} */}
                        </div>
                    </form>
                </div>
                <div className="modal-footer">
                    <button
                        onClick={() => (reason ? setDisplayCancel(true) : setShowModal(false))}
                        className="app-btn app-btn-secondary modal-btn footer-btn"
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        disabled={reason ? false : true}
                        onClick={() => onSubmit()}
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="button"
                    >
                        <span className="button-text vendor-button-text">Reject Reassignment</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
