import { createSlice } from '@reduxjs/toolkit';

export const integrationSlice = createSlice({
    name: 'agentIntegrationDetails',
    initialState: {
        integrationData: [{ integration: '', isIntegrated: '' }],
        isLoading: false,
        response: {},
        agentId: '',
        isError: false,
        isSuccess: false,
        errorMessage: null,
    },
    reducers: {
        addAgentIntegrationData: (state, action) => {
            if (action.payload.response?.integrations) {
                state.integrationData = action.payload.response?.integrations;
                state.agentId = action.payload.agentId;
                state.isSuccess = true;
            }
            if (action.payload.error) {
                state.errorMessage = action.payload.error?.response?.data?.message;
                state.isError = true;
            }
        },
        fetchAgentIntegrationData: (state, action) => {
            state.integrationData = action.payload.response?.integrations;
            state.agentId = action.payload.agentId;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        resetFlags: (state, _action) => {
            state.isSuccess = false;
            state.isError = false;
        },
    },
});

export const { addAgentIntegrationData, fetchAgentIntegrationData, isLoadingFn, resetFlags } = integrationSlice.actions;
export default integrationSlice.reducer;
