import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../common/NotFound/no-permission.scss';

const NotFound: React.FC = () => {
    const navigate = useNavigate();
    return (
        <div className="no-permission-page msg-page">
            <div className="msg-page-content-holder">
                {/* <span className="network-icon-holder">
                    <svg className="svg-icon no-access-icon ">
                        <use xlinkHref="#noAccessIcon"></use>
                    </svg>
                </span> */}
                <p className="msg-page-heading">404</p>
                <p className="msg-page-content">Page Not Found</p>
                <a
                    href="javascript:void(0)"
                    className="booking-proposal-link redirect-btn"
                    onClick={() => {
                        navigate('/');
                    }}
                >
                    Go to Dashboard
                </a>
            </div>
        </div>
    );
};

export default NotFound;
