/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface SplitLines {
    splitLineId: string;
    splitLineStatus: string;
    confirmedQty: number;
    confirmedGoodsdate: string;
    confirmedCbm: number;
    confirmedWeight: number;
    confirmedUom: string;
    convertedQuantity: string;
}

export interface ProductPoConfirmationDataDetails {
    poProductId: string;
    poLineNumber: number;
    sku: string;
    description: string;
    originPort: string;
    destinationPort: string;
    qty: number;
    cbm: number;
    eachCbm: number;
    weight: number;
    eachWeight: number;
    goodsReadyDate: string;
    uom: string;
    convertedQty: string;
    orderDate: string;
    splitLines: SplitLines[];
}

interface ProductPoConfirmationState {
    isLoading: boolean;
    productPoConfirmation?: ProductPoConfirmationDataDetails[];
    countConfirmed: any;
    allConfirmed: any;
    allPoLineConfirmed: any;
    productPoConfirmationSuccess: any;
    productPoConfirmationError: any;
    isError?: boolean;
}

const initialState: ProductPoConfirmationState = {
    isLoading: true,
    productPoConfirmation: undefined,
    countConfirmed: undefined,
    allConfirmed: undefined,
    allPoLineConfirmed: undefined,
    productPoConfirmationSuccess: undefined,
    productPoConfirmationError: undefined,
    isError: false,
};

export const productPoConfirmationSlice = createSlice({
    name: 'productPoConfirmation',
    initialState,
    reducers: {
        fetchProductPoConfirmationList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
            state.productPoConfirmation = undefined;
            state.countConfirmed = 0;
            state.allConfirmed = undefined;
            state.allPoLineConfirmed = undefined;
        },
        fetchProductPoConfirmationListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmation = action.payload?.data?.info?.splitProductDataResList;
            state.countConfirmed = action.payload?.data?.info?.countConfirmed;
            state.allConfirmed = action.payload?.data?.info?.allConfirmed;
            state.allPoLineConfirmed = action.payload?.data?.info?.allPoLineConfirmed;
        },
        fetchProductPoConfirmationListFailure(state, action) {
            state.isLoading = false;
            // state.isError = true;
            state.productPoConfirmation = action.payload;
        },
        confirmPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        confirmPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmationSuccess = action.payload;
            state.productPoConfirmationError = {};
        },
        confirmPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productPoConfirmationSuccess = {};
            state.productPoConfirmationError = action.payload;
        },
        confirmAllPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        confirmAllPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmationSuccess = action.payload;
            state.productPoConfirmationError = {};
        },
        confirmAllPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productPoConfirmationSuccess = {};
            state.productPoConfirmationError = action.payload;
        },
        submitPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        submitPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmationSuccess = action.payload;
            state.productPoConfirmationError = {};
        },
        submitPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productPoConfirmationSuccess = {};
            state.productPoConfirmationError = action.payload;
        },
        submitPoBlp(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        submitPoBlpSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmationSuccess = action.payload;
            state.productPoConfirmationError = {};
        },
        submitPoBlpFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productPoConfirmationSuccess = {};
            state.productPoConfirmationError = action.payload;
        },
        removeProductPoConfirmation(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        removeProductPoConfirmationSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmationSuccess = action.payload;
            state.productPoConfirmationError = {};
        },
        removeProductPoConfirmationFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productPoConfirmationSuccess = {};
            state.productPoConfirmationError = action.payload;
        },
        changePoLineStatus(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        changePoLineStatusSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmationSuccess = action.payload;
            state.productPoConfirmationError = {};
        },
        changePoLineStatusFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productPoConfirmationSuccess = {};
            state.productPoConfirmationError = action.payload;
        },
        editPoLineStatus(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        editPoLineStatusSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productPoConfirmationSuccess = action.payload;
            state.productPoConfirmationError = {};
        },
        editPoLineStatusFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productPoConfirmationSuccess = {};
            state.productPoConfirmationError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.productPoConfirmationError = undefined;
            state.productPoConfirmationSuccess = undefined;
            state.countConfirmed = undefined;
            state.allConfirmed = undefined;
            state.allPoLineConfirmed = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchProductPoConfirmationList,
    fetchProductPoConfirmationListSuccess,
    fetchProductPoConfirmationListFailure,
    confirmPo,
    confirmPoSuccess,
    confirmPoFailure,
    confirmAllPo,
    confirmAllPoSuccess,
    confirmAllPoFailure,
    submitPo,
    submitPoSuccess,
    submitPoFailure,
    submitPoBlp,
    submitPoBlpSuccess,
    submitPoBlpFailure,
    changePoLineStatus,
    changePoLineStatusSuccess,
    changePoLineStatusFailure,
    editPoLineStatus,
    editPoLineStatusSuccess,
    editPoLineStatusFailure,
    removeProductPoConfirmation,
    removeProductPoConfirmationSuccess,
    removeProductPoConfirmationFailure,
    isLoadingFn,
    clearFailure,
} = productPoConfirmationSlice.actions;

export const selectProductPoConfirmationLoading = (state: RootState) => state.productPoConfirmationData.isLoading;
export const selectProductPoConfirmation = (state: RootState) => state.productPoConfirmationData.productPoConfirmation;
export const selectCountConfirmed = (state: RootState) => state.productPoConfirmationData.countConfirmed;
export const selectAllConfirmed = (state: RootState) => state.productPoConfirmationData.allConfirmed;
export const selectAllPoLineConfirmed = (state: RootState) => state.productPoConfirmationData.allPoLineConfirmed;
export const selectProductPoConfirmationSuccess = (state: RootState) =>
    state.productPoConfirmationData.productPoConfirmationSuccess;
export const selectProductPoConfirmationError = (state: RootState) =>
    state.productPoConfirmationData.productPoConfirmationError;
export const selectProductPoConfirmationIsError = (state: RootState) => state.productPoConfirmationData.isError;

export default productPoConfirmationSlice.reducer;
