import { get, post, del, put } from '../../HttpMethods';
import fileDownload from 'js-file-download';

export const getAdditionalDetailsNac = async (payload: { bpId: string }) => {
    try {
        const res = await get(`api/bkg/bp/additionaldetails?bookingProposalId=${payload.bpId}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const getDeliveryAndContainerModes = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/modes?bpType=${payload?.bpType}&coloaderCode=${payload?.coloaderCode}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const addRefNo = async (payload: { bpId: string; refNo: string }) => {
    try {
        const res = await post(
            `api/bkg/bp/referenceno?bookingProposalId=${payload.bpId}&referenceNumber=${payload.refNo}`,
            null,
            null
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const addFak = async (payload: any) => {
    try {
        const res = await post('/api/bkg/bp/fakadditionaldetails', payload, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const addContainer = async (payload: {
    bookingProposalId: string;
    containerId: string;
    deliveryModeId: string;
    containerModeId: string;
    quantity: number;
    cbm: number;
}) => {
    try {
        const res = await post('api/bkg/bp/container', payload, null);
        return res;
    } catch (err) {
        return err;
    }
};

export const editContainer = async (payload: {
    cdId: string;
    data: {
        bookingProposalId: string;
        containerId: string;
        deliveryModeId: string;
        containerModeId: string;
        quantity: number;
        cbm: number;
    };
}) => {
    try {
        const res = await put(`api/bkg/bp/container?cdId=${payload.cdId}`, payload.data);
        return res;
    } catch (err) {
        return err;
    }
};

export const deleteContainer = async (payload: { cdId: string; bookingProposalId: string }) => {
    try {
        const res = await del(
            `api/bkg/bp/container?bookingProposalId=${payload.bookingProposalId}&cdId=${payload.cdId}`,
            null
        );
        return res;
    } catch (err) {
        return err;
    }
};

export const getContractDetails = async (payload: { contractId: string }) => {
    try {
        const res = await get(`api/bkg/bp/contracts/${payload.contractId}`, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const updateAdditionalDetailsFak = async (payload: any) => {
    try {
        const res = await put('api/bkg/bp/fakadditionaldetails', payload);
        return res;
    } catch (err) {
        return err;
    }
};

export const downloadFile = async (fakId: string, fileName: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_BASE_URL}/api/bkg/bp/fak/download/${fakId}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
