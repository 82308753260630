import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';

// Import all api's
import {
    unlinkClient,
    linkClientApi,
    ClientData,
    getListClient,
    getClientData,
    searchVendorsClients,
} from '../../services/apis/linkClientVendorApi';
import {
    actionClientsData,
    saveFetchedData,
    isLoadingfn,
    onUnlinking,
    linkingToast,
    saveAllFetchedData,
} from '../reducers/linkClientVendorReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingfn, payload: true });
        const clients: AxiosResponse<ClientData[]> = yield call(getListClient, payload);
        yield put({ type: saveFetchedData, payload: clients });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}

function* getAllListData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingfn, payload: true });
        const allClients: AxiosResponse<ClientData[]> = yield call(getListClient, {
            ...payload,
            currentPage: 0,
            perPageCount: 30,
        });
        yield put({ type: saveAllFetchedData, payload: allClients });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}

function* getActiveClients(_params: { payload: any; type: any }) {
    const clients: AxiosResponse<ClientData[]> = yield call(getClientData);
    yield put({ type: actionClientsData, payload: clients });
}

function* unlinkClientval({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        const res: AxiosResponse<ClientData[]> = yield call(unlinkClient, payload);
        // const clients: AxiosResponse<ClientData[]> = yield call(getListClient, payload);
        // yield put({ type: saveFetchedData, payload: clients });
        yield put({ type: onUnlinking, payload: { status: false, message: res } });
        // const allClients: AxiosResponse<ClientData[]> = yield call(getListClient, {
        //     ...payload,
        //     currentPage: 0,
        //     perPageCount: 30,
        // });
        // yield put({ type: saveAllFetchedData, payload: allClients });
    } catch (err) {
        yield put({ type: isLoadingfn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingfn, payload: false });
    }
    //payload['headerTab'] = header;
    //yield put({ type: fetchHeaderData, payload: payload });
}
function* linkClientVal({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        yield call(linkClientApi, payload);
        yield put({ type: linkingToast, payload: true });
        // const clients: AxiosResponse<ClientData[]> = yield call(getListClient, payload);
        // yield put({ type: saveFetchedData, payload: clients });
        // const allClients: AxiosResponse<ClientData[]> = yield call(getListClient, {
        //     ...payload,
        //     currentPage: 0,
        //     perPageCount: 30,
        // });
        // yield put({ type: saveAllFetchedData, payload: allClients });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingfn, payload: false });
    }
}

function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingfn, payload: true });
        yield put(showLoading());
        const clients: AxiosResponse = yield call(searchVendorsClients, payload);
        yield put({ type: saveFetchedData, payload: clients });
    } finally {
        yield put({ type: isLoadingfn, payload: false });
        yield put(hideLoading());
    }
}

function* linkClientVendorSaga() {
    yield takeEvery('linkClientVendor/fetchData', getListData);
    yield takeEvery('linkClientVendor/fetchAllData', getAllListData);
    yield takeLatest('linkClientVendor/unlinkClient', unlinkClientval);
    yield takeLatest('linkClientVendor/linkingClients', linkClientVal);
    yield takeLatest('linkClientVendor/activeClients', getActiveClients);
    yield takeLatest('linkClientVendor/searchVendorClientsData', getSearchData);
}

// Export the saga (data-saga)
export default linkClientVendorSaga;
