import React, { useEffect } from 'react';
import './/clients-tab.scss';
import '../../../../assets/scss/components/_tab.scss';
import { useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchHeaderDataClients } from 'src/store/reducers/profiles/clients';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { clientProfileApproval } from 'src/services/apis/profiles/clients';

type Props = {
    disabled?: boolean;
};
const Index: React.FC<Props> = (_props) => {
    const location = useLocation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const { clientHeader } = useSelector((state: RootState) => state.profilesClients) as any;
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    useEffect(() => {
        if (lastSegment) {
            if (lastSegment === 'attachment') {
                setSelectedIndex(7);
            } else if (lastSegment === 'integration_details') {
                setSelectedIndex(6);
            } else if (lastSegment === 'auto_approval') {
                if (!clientHeader.autoTabComplete) {
                    clientProfileApproval(id);
                }
                setSelectedIndex(5);
            } else if (lastSegment === 'product_master') {
                setSelectedIndex(4);
            } else if (lastSegment === 'vendor_details') {
                setSelectedIndex(3);
            } else if (lastSegment === 'contract_details') {
                setSelectedIndex(2);
            } else if (lastSegment === 'sop_details') {
                setSelectedIndex(1);
            } else {
                setSelectedIndex(0);
            }
        }
        document.title = 'CNF>ORG>CLN';
    }, [id]);

    const profileClientsTabList = [
        {
            label: 'Basic Details',
            value: 'basic_details',
            id: 1,
        },
        {
            label: 'SOP Details',
            value: 'sop_details',
            id: 2,
        },
        {
            label: 'Contract Details',
            value: 'contract_details',
            id: 3,
        },
        {
            label: 'Vendor Details',
            value: 'vendor_details',
            id: 4,
        },
        {
            label: 'Product Master',
            value: 'product_master',
            id: 5,
        },
        {
            label: 'Approvals',
            value: 'auto_approval',
            id: 6,
        },
        {
            label: 'Integration Details',
            value: 'integration_details',
            id: 7,
        },
    ];

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (clientHeader) {
                if (param === 'integration_details') {
                    return clientHeader.integrationTabComplete;
                } else if (param === 'auto_approval') {
                    return clientHeader.autoTabComplete;
                } else if (param === 'product_master') {
                    return clientHeader.pmTabComplete;
                } else if (param === 'vendor_details') {
                    return clientHeader.vendorTabComplete;
                } else if (param === 'contract_details') {
                    return clientHeader.contractTabComplete;
                } else if (param === 'sop_details') {
                    return clientHeader.sopTabComplete;
                } else if (param === 'basic_details') {
                    return clientHeader.bdTabComplete;
                } else {
                    return false;
                }
            }
        };
    }, [lastSegment, clientHeader]);

    useEffect(() => {
        dispatch(fetchHeaderDataClients(id));
    }, [id]);

    return (
        <>
            <div className="arrow-tab-container">
                <div className="arrow-tab-holder">
                    {profileClientsTabList.map((tabs: any, index: number) => {
                        return (
                            <Link
                                className={`arrow-tab-border ${index === selectedIndex && 'active'} po-basic-tab`}
                                to={`/profile/clients/${id}/${tabs.value}`}
                            >
                                {isTabActive(tabs.value) ? (
                                    <div
                                        className="arrow-badge verify-badge"
                                        style={{
                                            backgroundColor: '#18ab56',
                                        }}
                                    >
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="arrow-badge">{tabs.id}</div>
                                )}
                                <div className="arrow-tab">{tabs.label}</div>
                            </Link>
                        );
                    })}
                    {/* <Link
                        className={`arrow-tab-border ${selectedIndex === 7 && 'active'} booking-proposal-tab`}
                        to={`/profile/clients/${id}/attachment`}
                    >
                        <div className="arrow-badge">
                            <svg className="svg-icon eye-icon">
                                <use href="#noteDraftEditIcon"></use>
                            </svg>
                        </div>
                        <div className="arrow-tab">Attachments</div>
                    </Link> */}
                </div>
            </div>
        </>
    );
};

export default Index;
