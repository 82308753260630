import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    getAvailablePoLinesLcl,
    searchAvailablePoLinesLcl,
} from '../../../services/apis/bookingProposalLcl/availablePoLineApi';
import {
    fetchAvailablePoLineData,
    isLoadingFn,
    searchAvailablePoLineDataSuccess,
} from '../../reducers/bookingProposalLcl/availablePoLineReducer';

function* getAvailablePoLineListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(getAvailablePoLinesLcl, payload);
        payload['response'] = response;
        yield put({ type: fetchAvailablePoLineData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* searchAvailablePoLineListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(searchAvailablePoLinesLcl, payload);
        payload['response'] = response;
        yield put({ type: searchAvailablePoLineDataSuccess, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* lclAvailablePoLineListSaga() {
    yield takeEvery('availablePoLinesLcl/fetchAvailablePoLineData', getAvailablePoLineListData);
    yield takeEvery('availablePoLinesLcl/searchAvailablePoLineData', searchAvailablePoLineListData);
}

export default lclAvailablePoLineListSaga;
