import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
// import { editPoLineStatusApi } from '../../../services/apis/purchaseOrder/productPoConfirmationApi';
import { confirmData, dataLoader } from '../../../store/reducers/inventory/inventoryAdjstmntReducer';
import './quality-adjustment-modal.scss';

interface dataProps {
    data: any;
    setadjustmentModal: any;
    adjustmentModal: any;
}

const Index: React.FC<dataProps> = ({ data, setadjustmentModal, adjustmentModal }) => {
    const [AvailableAdjst, setAvailableAdjst] = useState('');
    const [HoldAdjst, setHoldAdjst] = useState('');
    const [HoldComment, setHoldComment] = useState('');
    const [AvailComment, setAvailComment] = useState('');
    const dispatch = useDispatch();
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');

    useEffect(() => {
        setAvailableAdjst(data?.availableQty);
        setHoldAdjst(data?.onHoldQty);
    }, [data]);

    const confirm = () => {
        const username = userData && userData?.user;
        setadjustmentModal({ ...adjustmentModal, id: '', stat: false });
        dispatch(dataLoader(false));
        const payload = {
            id: adjustmentModal?.id,
            data: {
                adjAvailableQty: AvailableAdjst !== '' ? parseInt(AvailableAdjst) : data?.availableQty,
                adjHoldQty: HoldAdjst !== '' ? parseInt(HoldAdjst) : data?.onHoldQty,
                adjAvailableCmnt: AvailComment !== '' ? AvailComment : data?.adjAvailableCmnt,
                adjHoldCmnt: HoldComment !== '' ? HoldComment : data?.adjHoldCmnt,
                modifiedByUser: username?.us_UserName,
                modifiedByUserName: username?.us_FirstName + ' ' + username?.us_LastName,
            },
        };
        dispatch(confirmData(payload));
    };

    const validation = () => {
        let flag = true;

        if (AvailableAdjst != data?.availableQty) {
            if (AvailComment !== '' && AvailComment !== null) {
                if (HoldAdjst == data?.onHoldQty) {
                    flag = false;
                } else if (HoldAdjst != data?.onHoldQty && HoldComment !== null && HoldComment !== '') {
                    flag = false;
                }
            } else {
                flag = true;
            }
        } else if (HoldAdjst != data?.onHoldQty) {
            if (HoldComment !== '' && HoldComment !== null) {
                if (AvailableAdjst == data?.availableQty) {
                    flag = false;
                } else if (AvailableAdjst != data?.availableQty && AvailComment !== null && AvailComment !== '') {
                    flag = false;
                }
            } else {
                flag = true;
            }
        } else {
            flag = true;
        }

        return flag;
    };

    const countWordsAvail = () => {
        const arr = AvailComment.split('');
        return arr.filter((word: string) => word !== '').length;
    };
    const countWordsHold = () => {
        const arr = HoldComment.split('');
        return arr.filter((word: string) => word !== '').length;
    };
    return (
        <div>
            <div className="app-modal">
                <div className="modal-content-holder medium-popup quaity-adjustment-modal">
                    <div className="modal-header">
                        <div className="title">Quantity Adjustments</div>
                        <button
                            className="app-btn modal-close-btn"
                            onClick={() => {
                                setadjustmentModal({ id: '', stat: false, name: '' });
                                dispatch(dataLoader(false));
                            }}
                        >
                            <span>
                                <svg className="svg-icon modal-header-close-icon">
                                    <use href="#closeIcon">
                                        <title>Close</title>
                                    </use>
                                </svg>
                            </span>
                        </button>
                    </div>
                    <div className="modal-content">
                        <div className="layout-holder three-column">
                            <div className="layout-item">
                                <h6 className="heading-type-1">SKU</h6>
                                <div className="description-type-1">{data?.sku}</div>
                            </div>
                            <div className="layout-item" style={{ padding: '0' }}>
                                <h6 className="heading-type-1">Description</h6>
                                <div className="description-type-1">{data?.description}</div>
                            </div>
                        </div>
                        <form id="my-form" action="" className="modal-form layout-holder one-column">
                            <div className="modal-input-layouts-holder">
                                <div className="modal-heading-name type1">Available</div>
                                <div className="modal-input-fields-wrapper">
                                    <div className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Current Qty
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder=""
                                                type="text"
                                                className="modal-input input-text read-only"
                                                id="poNumber"
                                                name="poNumber"
                                                value={data?.availableQty}
                                                readOnly
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="unit-process-icon">
                                        <svg className="svg-icon straight-arrow-pointer-icon">
                                            <use href="#straightArrowPointerIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Adjusted Qty
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder=""
                                                type="number"
                                                className="modal-input input-text"
                                                id="poNumber"
                                                name="poNumber"
                                                onChange={(e) => setAvailableAdjst(e.target.value)}
                                                value={AvailableAdjst}
                                                min="0"
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                {AvailableAdjst?.length >= 0 &&
                                    AvailableAdjst !== '' &&
                                    AvailableAdjst != data?.availableQty && (
                                        <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                            <label htmlFor="name" className="modal-input-label">
                                                Comments<span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-field-holder">
                                                <textarea
                                                    name="reason"
                                                    className="input-text"
                                                    placeholder="Enter comment"
                                                    onChange={(e) => setAvailComment(e.target.value)}
                                                    required
                                                    maxLength={250}
                                                ></textarea>
                                                {countWordsAvail() <= 250 && (
                                                    <div className="heading-type-1 file-size">
                                                        <p>{countWordsAvail()} out of 250 characters</p>
                                                    </div>
                                                )}
                                                {countWordsAvail() > 250 && (
                                                    <p className="error_message_product">
                                                        You have exceeded character limit of 250
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                    )}
                            </div>
                            <div className="modal-input-layouts-holder">
                                <div className="modal-heading-name type2">On Hold</div>
                                <div className="modal-input-fields-wrapper">
                                    <div className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Current Qty
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder=""
                                                type="text"
                                                className="modal-input input-text read-only"
                                                id="poNumber"
                                                name="poNumber"
                                                value={data?.onHoldQty}
                                                readOnly
                                            ></input>
                                        </div>
                                    </div>
                                    <div className="unit-process-icon">
                                        <svg className="svg-icon straight-arrow-pointer-icon">
                                            <use href="#straightArrowPointerIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className=" modal-input-holder ">
                                        <label htmlFor="name" className="modal-input-label">
                                            Adjusted Qty
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder=""
                                                type="number"
                                                className="modal-input input-text"
                                                id="poNumber"
                                                name="poNumber"
                                                value={HoldAdjst}
                                                onChange={(e) => setHoldAdjst(e.target.value)}
                                                min="0"
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                                {HoldAdjst?.length >= 0 && HoldAdjst !== '' && HoldAdjst != data?.onHoldQty && (
                                    <div className=" modal-input-holder" style={{ paddingBottom: '8px' }}>
                                        <label htmlFor="name" className="modal-input-label">
                                            Comments<span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder">
                                            <textarea
                                                name="reason"
                                                className="input-text"
                                                placeholder="Enter comment"
                                                required
                                                onChange={(e) => setHoldComment(e.target.value)}
                                                maxLength={250}
                                            ></textarea>
                                            {countWordsHold() <= 250 && (
                                                <div className="heading-type-1 file-size">
                                                    <p>{countWordsHold()} out of 250 characters</p>
                                                </div>
                                            )}
                                            {countWordsHold() > 250 && (
                                                <p className="error_message_product">
                                                    You have exceeded character limit of 250
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button
                            className="app-btn app-btn-secondary modal-btn footer-btn"
                            onClick={() => {
                                setadjustmentModal({ id: '', stat: false, name: '' });
                                dispatch(dataLoader(false));
                            }}
                        >
                            <span className="button-text vendor-button-text">Cancel</span>
                        </button>
                        <button
                            className={`app-btn app-btn-primary modal-btn footer-btn ${
                                validation() ||
                                countWordsHold() > 250 ||
                                countWordsAvail() > 250 ||
                                parseInt(AvailableAdjst) < 0 ||
                                parseInt(HoldAdjst) < 0
                                    ? 'disabled'
                                    : ''
                            }`}
                            onClick={() =>
                                !validation() &&
                                countWordsHold() <= 250 &&
                                countWordsAvail() <= 250 &&
                                parseInt(AvailableAdjst) >= 0 &&
                                parseInt(HoldAdjst) >= 0 &&
                                confirm()
                            }
                        >
                            <span className="button-text vendor-button-text">Confirm Adjustments</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
