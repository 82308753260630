import axios from 'axios';
import { post } from '../../HttpMethods';

export const fetchReassignApprovalListDetailsApi = async (payload: {
    userName: string;
    sortColumn: string;
    sortOrder: string;
    body: any;
}) => {
    try {
        const data = await post(
            `api/ord/reassignment/list?sortColumn=${payload.sortColumn}&sortOrder=${payload.sortOrder}`,
            { ...payload.body, loggedInUserName: payload.userName },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchReassignApprovalDistinctListDetailsApi = async (payload: { userName: string; body: any }) => {
    try {
        const data = await post(
            'api/ord/reassignment/distinct',
            { ...payload.body, loggedInUserName: payload.userName },
            null
        );
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const approveAgentPoApi = async (payload: any) => {
    try {
        delete payload?.data;
        const data = await post('api/ord/reassignment/approve', payload, null);
        return { data: { ...data, type: 'approve' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const approveAgentBpApi = async (payload: any) => {
    try {
        delete payload?.data;
        const data = await post('api/bkg/bp/reassignment/approve', payload, null);
        return { data: { ...data, type: 'approve' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const rejectAgentPoApi = async (payload: any) => {
    try {
        delete payload?.data;
        const data = await post('api/ord/reassignment/reject', payload, null);
        return { data: { ...data, type: 'reject' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const rejectAgentBpApi = async (payload: any) => {
    try {
        delete payload?.data;
        const data = await post('api/bkg/bp/reassignment/reject', payload, null);
        return { data: { ...data, type: 'reject' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
