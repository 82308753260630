import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { getBpData } from '../../reducers/aobookingProposal/availablePoReducer';
import { isLoadingFn } from '../../reducers/aobookingProposal/addedPoReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    getAddedPoLines,
    getSingleLine,
    removePoLine,
    searchAdded,
} from '../../../services/apis/aobookingProposal/addedPosApi';

import { removeMessage, saveAddedData, saveSingleData } from '../../reducers/aobookingProposal/addedPoReducer';
import { getBpDetails } from '../../../services/apis/aobookingProposal/availablePOsApi';

function* getAvailablePOListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const availablePoList: AxiosResponse<any> = yield call(getAddedPoLines, payload);
        yield put({ type: saveAddedData, payload: availablePoList });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* removePoFromList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const res: AxiosResponse<any> = yield call(removePoLine, payload);
        const newPayload = {
            message: (res as any).message,
            status: true,
        };
        yield put({ type: removeMessage, payload: newPayload });
        const availablePoList: AxiosResponse<any> = yield call(getAddedPoLines, payload.bpId);
        yield put({ type: saveAddedData, payload: availablePoList });
        const new1Payload = {
            columnStatus: 'createdDate',
            columnOrder: false,
            bpId: payload.bpId,
        };
        const data: AxiosResponse<any[]> = yield call(getBpDetails, new1Payload);
        payload['success'] = true;
        payload['data'] = data;
        yield put({ type: getBpData, payload });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* getSingleLineSaga({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const res: AxiosResponse<any> = yield call(getSingleLine, payload);
        yield put({ type: saveSingleData, payload: res });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* searchInAddedTab({ payload }: { payload: any; type: any }) {
    try {
        const availablePoList: AxiosResponse<any> = yield call(searchAdded, payload);
        yield put({ type: saveAddedData, payload: availablePoList });
    } finally {
        yield put(hideLoading());
    }
}

function* availablePoListSaga() {
    yield takeEvery('addedPosao/fetchAddedPoData', getAvailablePOListData);
    yield takeEvery('addedPosao/removePo', removePoFromList);
    yield takeEvery('addedPosao/searchData', searchInAddedTab);
    yield takeEvery('addedPosao/getSingleData', getSingleLineSaga);
}

export default availablePoListSaga;
