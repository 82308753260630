import React, { useEffect } from 'react';
import AllocationOrderHeader from '../AllocationOrderHeader';
import { useNavigate, useParams } from 'react-router-dom';
import './ao-preview.scss';
import moment from 'moment';
import AOBreadCrumb from '../BreadCrumb';
import { useDispatch } from 'react-redux';
import { fetchPreview } from 'src/store/reducers/allocationOrder/basicDetails';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { useAppSelector } from 'src/hooks';
import { selectHeader } from 'src/store/reducers/allocationOrder/addAOReducer';
import ShowForPermissions from 'src/ShowForPermissions';
import { useUserProfile } from 'src/hooks/useUserProfile';
import AllocationOrderTabs from '../AllocationOrderTabs';
import AllocationOrderNextButton from '../AllocationOrderNextButton/AllocationOrderNextButton';

const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { previewData } = useSelector((state: RootState) => state.allocationOrder) as any;
    const { loading } = useSelector((state: RootState) => state.vendorDat);
    const headerData = useAppSelector(selectHeader);
    const { profileType } = useUserProfile();

    useEffect(() => {
        dispatch(fetchPreview(id));
    }, [id]);

    const data = previewData as any;
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <AOBreadCrumb />

                <AllocationOrderHeader id={id}></AllocationOrderHeader>
                {loading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <AllocationOrderTabs />
            </div>
            <div className="content-section main-wrapper preview-detail-wrapper ao-detail-wrapper">
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Required Details</h6>
                            <ShowForPermissions permission="create" type="ao">
                                {headerData?.dataSourceName != 'ERP' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        profileType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && profileType === 'forwarder')) && (
                                        <button
                                            onClick={() => navigate(`/allocation_orders/${id}/basic_details`)}
                                            className="action-btn app-btn app-btn-secondary"
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Order Type</h6>
                            <div className="details-box-content">{data?.ordertype?.orderTypeName}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Shipping Mode</h6>
                            <div className="details-box-content">{data?.shippingmode?.shippingModeName}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Origin</h6>
                            <div className="details-box-content">
                                {data?.originPortName} ({data?.originPort})
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Destination</h6>
                            <div className="details-box-content">
                                {data?.isMultiAddress
                                    ? 'Multi Destination'
                                    : `${data?.destinationPortName} (${data?.destinationPort})`}
                            </div>
                        </div>

                        {data?.isTriangleShipment && data?.isMultiAddress && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Includes AU Destination</h6>
                                <div className="details-box-content" style={{ width: '80%' }}>
                                    {data?.includesAUPortDestination ? 'Yes' : 'No'}
                                </div>
                            </div>
                        )}

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Destination Address</h6>
                            <div className="details-box-content" style={{ width: '90%' }}>
                                {data?.isMultiAddress ? 'Multi Destination' : data?.destinationAddress}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Service Type</h6>
                            <div className="details-box-content">{data?.fclservicetype?.fclServiceTypeName}</div>
                        </div>
                        {/* <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Freight Term</h6>
                            <div className="details-box-content">{data?.freightterms?.freightTermsCode}</div>
                        </div> */}

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Incoterm</h6>
                            <div className="details-box-content">{data?.incoTerm?.incoTermName}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Release type</h6>
                            <div className="details-box-content">{data?.releaseType?.releaseTypeName}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Payment Term</h6>
                            <div className="details-box-content">{data?.paymentterms?.paymentTermsName}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Agent</h6>
                            <div className="details-box-content">{data?.agentName}</div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Store ID<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{data?.storeId ? data?.storeId : '-'}</div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Batch ID<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{data?.batchId ? data?.batchId : '-'}</div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Number<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{data?.erpOrderno ? data?.erpOrderno : '-'}</div>
                        </div>
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Additional Details</h6>
                            <ShowForPermissions permission="create" type="ao">
                                {headerData?.dataSourceName != 'ERP' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        profileType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && profileType === 'forwarder')) && (
                                        <button
                                            onClick={() => navigate(`/allocation_orders/${id}/basic_details`)}
                                            className="action-btn app-btn app-btn-secondary"
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>
                    {!data?.promRefNo &&
                        !data?.brandName &&
                        !data?.impVendorCode &&
                        !data?.pickGroup &&
                        !data?.currency &&
                        !data?.buyerUserName &&
                        !data?.impShipmentRefNo &&
                        !data?.distribOrderNo && (
                            <div className="no-data-wrapper">
                                <div className="no-data-content">
                                    <svg className="svg-icon grid-no-content-icon ">
                                        <use xlinkHref="#gridNoContentIcon"></use>
                                    </svg>
                                    <p className="no-content-message">No additional details added</p>
                                </div>
                            </div>
                        )}

                    <div className="card-detail-col">
                        {data?.promRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Promotional Ref No.</h6>
                                <div className="details-box-content">{data?.promRefNo}</div>
                            </div>
                        )}
                        {data?.brandName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Brand Name</h6>
                                <div className="details-box-content">{data?.brandName}</div>
                            </div>
                        )}
                        {data?.impVendorCode && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Importer’s Vendor Code</h6>
                                <div className="details-box-content">{data?.impVendorCode}</div>
                            </div>
                        )}
                        {data?.pickGroup && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Pick Group</h6>
                                <div className="details-box-content">{data?.pickGroup}</div>
                            </div>
                        )}
                        {data?.currency && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Order Currency</h6>
                                <div className="details-box-content">{data?.currency?.currencycode}</div>
                            </div>
                        )}
                        {data?.buyerUserName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Buyer</h6>
                                <div className="details-box-content">{data?.buyerUserName}</div>
                            </div>
                        )}
                        {data?.impShipmentRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Importer’s Shipment Ref No.</h6>
                                <div className="details-box-content">{data?.impShipmentRefNo}</div>
                            </div>
                        )}
                        {data?.distribOrderNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Distribution Order No. </h6>
                                <div className="details-box-content">{data?.distribOrderNo}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>

                            <ShowForPermissions permission="create" type="ao">
                                {headerData?.dataSourceName != 'ERP' &&
                                    ((headerData?.aoStatus === 'Draft' &&
                                        // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                        profileType === 'client') ||
                                        (headerData?.aoStatus === 'Draft' && profileType === 'forwarder')) && (
                                        <button
                                            onClick={() => navigate(`/allocation_orders/${id}/dates`)}
                                            className="action-btn app-btn app-btn-secondary"
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>

                    {/* <div className="no-data-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No additional details added</p>
                        </div> */}

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Order Date</h6>
                            <div className="details-box-content">
                                {data?.orderDate ? moment(data?.orderDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Start Shipping Window</h6>
                            <div className="details-box-content">
                                {data?.startShipWindowDate
                                    ? moment(data?.startShipWindowDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">End Shipping Window</h6>
                            <div className="details-box-content">
                                {data?.endShipWindowDate ? moment(data?.endShipWindowDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        {!data?.isMultiAddress && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Projected ETA into Warehouse</h6>
                                <div className="details-box-content">
                                    {data?.projectedEtaDate ? moment(data?.projectedEtaDate).format('DD-MM-YYYY') : '-'}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card grid-section view-entry-field-grid modal-grid preview-grid product-allocation-grid">
                    <div className="grid-holder">
                        <div className="grid-header">
                            <div className="content-heading-holder card-head-btn-edit-holder">
                                <span className="content-heading">Product Allocation</span>
                                <ShowForPermissions permission="create" type="ao">
                                    {headerData?.dataSourceName != 'ERP' &&
                                        ((headerData?.aoStatus === 'Draft' &&
                                            // headerData?.aoStatus === 'Pending_with_Forwarder') &&
                                            profileType === 'client') ||
                                            (headerData?.aoStatus === 'Draft' && profileType === 'forwarder')) && (
                                            <button
                                                onClick={() => navigate(`/allocation_orders/${id}/product-allocation`)}
                                                className="action-btn app-btn app-btn-secondary"
                                                title="Edit Details"
                                            >
                                                <svg className="svg-icon edit-icon">
                                                    <use xlinkHref="#editIcon"></use>
                                                </svg>
                                            </button>
                                        )}
                                </ShowForPermissions>
                            </div>
                        </div>
                        <div className="grid-container">
                            <div className="grid-scroll-content">
                                <table className="grid-table">
                                    <thead>
                                        <tr className="grid-sticky-row">
                                            <th className="grid-cell-header col-ao-sku">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">SKU</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-description">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">DESCRIPTION</span>
                                                </div>
                                            </th>

                                            <th className="grid-cell-header col-ao-reference">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">CUSTOMER ORDER REFERENCE NO</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-line">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">CUSTOMER ORDER LINE NO</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-po-line">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">INBOUND po REFERENCE NO.</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-allocation">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">ALLOCATION QTY</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-origin">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">ORIGIN</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-destination-port">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">DESTINATION</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-destination-address">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">DESTINATION ADDRESS</span>
                                                </div>
                                            </th>

                                            <th className="grid-cell-header col-ao-house">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">pROJ. ETA INTO WHOUSE</span>
                                                </div>
                                            </th>

                                            <th className="grid-cell-header col-ao-currency">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">CURRENCY</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-unit-cost">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">UNIT COST</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-cbm">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">CBM</span>
                                                </div>
                                            </th>
                                            <th className="grid-cell-header col-ao-net-cost">
                                                <div className=" grid-cell-data">
                                                    <span className="header-title">NET COST</span>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {data?.purchaseOrderProducts?.map((res: any) =>
                                            res?.inboundPoReferenceNos?.map((val: any, i: any) => (
                                                <tr className="has-child row-expanded">
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{i === 0 && res?.sku}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {i === 0 && res?.description}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {i === 0 && res?.customerOrderReferenceNo}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {i === 0 && res?.customerOrderLineNo}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {val?.inobundPoReferenceNo}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{val?.allocationQty}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {data?.originPortName} ({data?.originPort})
                                                        </div>
                                                    </td>

                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {res?.destinationPortName} ({res?.destinationPort})
                                                        </div>
                                                    </td>

                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.destinationAddress}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {moment(res?.projWhouseETA).format('DD-MM-YYYY')}
                                                        </div>
                                                    </td>

                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">
                                                            {res?.currency?.currencycode}
                                                        </div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.unitCost}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.cbm}</div>
                                                    </td>
                                                    <td className="grid-cell">
                                                        <div className="grid-cell-data">{res?.netCost}</div>
                                                    </td>
                                                </tr>
                                            ))
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AllocationOrderNextButton page="ao-preview" />
        </div>
    );
};
export default Index;
