/* eslint-disable no-undefined */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react';
// import './style.css';
import 'src/assets/scss/components/_modal.scss';
import 'src/assets/scss/components/_grid.scss';
import './product-master-modal.scss';
import { useFormik } from 'formik';
import conversionQtyList from './conversionQtyList.json';
import Select from 'react-select';
import { error, Position } from 'src/utils/toast';
import {
    createProduct,
    createSinglePacket,
    removePacket,
    editProduct,
    editingFailed,
    editingStarted,
    productAddErrorStatus,
} from 'src/store/reducers/productReducer';
import { useAppDispatch } from 'src/hooks';
import { reactSelectStyle } from 'src/utils/constants';
import CancelModal from './Components/cancelModal';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import ViewData from './Components/viewData';
import ShowForPermissions from 'src/ShowForPermissions';
import { validateFractionInput } from 'src/utils/validateFractionInput';
import { checkSkunHsName } from 'src/services/apis/productApi';
import { decimalNumberValidation } from 'src/utils';

interface BioProps {
    productModal: boolean;
    setproductModal: any;
    id: any;
    viewModal: boolean;
    setviewModal: any;
    data: any;
    vendors: any;
    editFn: any;
    editID: any;
    warning: boolean;
}
interface simpleInt {
    vn_PK: string;
    vn_vendorcode: string;
    vn_vendorname: string;
}
interface SopForm {
    pm_clientid: string;
    pm_sku: string;
    pm_purchasecode: string;
    pm_description: string;
    pm_vendorid: any;
    pm_fumigationrequired: boolean;
    pm_dangerousgood: boolean;
    pm_packets: [
        {
            pp_quantity: string;
            pp_conversionquantity: number;
            pp_length: number;
            pp_width: number;
            pp_height: number;
            pp_weight: number;
            pp_barcode: any;
            pp_cbm: number;
        }
    ];
}

interface packetInt {
    pp_quantity: string;
    pp_conversionquantity: number;
    pp_length: number;
    pp_width: number;
    pp_height: number;
    pp_weight: number;
    pp_barcode: any;
    pp_cbm: number;
    pp_PK: any;
}
var deletedAr: string[] = [];
var parentArray1: simpleInt[] = [];

const Index: React.FC<BioProps> = (props) => {
    const inputsInitial = {
        pp_quantity: '',
        pp_conversionquantity: 0,
        pp_length: 0,
        pp_width: 0,
        pp_height: 0,
        pp_weight: 0,
        pp_barcode: '',
        pp_cbm: 0,
        pp_PK: '',
    };
    interface vendorInt {
        vn_PK: string;
        vn_status: string;
        vn_vendorcode: string;
        vn_vendorcountry: string;
        vn_vendorname: string;
    }

    var vendEdit: any = [{ isRemoved: false, isAdded: true, vn_pk: '' }];
    var removeVend: any = [{}];

    const [fumigation, setfumigation] = useState(false);
    const [fumigationChose, setfumigationChose] = useState(false);
    const [fumigationDrop, setfumigationDrop] = useState(false);
    const [vendorsData, setVendorsData] = useState<string[] | []>([]);
    const [vendorsView, setvendorsView] = useState<vendorInt[] | []>([]);
    const [packetData, setpacketData] = useState<packetInt[] | []>([]);
    const [array, seteditData] = useState<packetInt[] | []>([]);
    const [editLine, setEditLine] = useState(null);
    const [editRow, setEditRow] = useState(true);
    const [addPacket, setAddpacket] = useState(false);
    const [editStat, seteditStat] = useState(false);
    const [render, setrender] = useState(false);
    const [editing, setediting] = useState(false);
    const [isSaving, setisSaving] = useState(false);
    const [editonAddLine, seteditonAddLine] = useState<any>(0);
    const [_vendorsWarning, setvendorsWarning] = useState(false);
    const [editedVendors, seteditedVendors] = useState(vendEdit);
    const [_deleteArray, setdeleteArray] = useState(removeVend);
    const [CancelDisplay, setCancelDisplay] = useState({ view: false, click: false, text: '' });
    const [Validation, setValidation] = useState(true);
    const [inputVal, setInput] = useState('');
    const [Search, setSearch] = useState(false);
    const dispatch = useAppDispatch();
    const [inputs, setInputs] = useState(inputsInitial);
    const [_setWarning, setsetWarning] = useState(false);
    const [render1, setrender1] = useState(false);
    // const [currentType, setcurrentType] = useState<'product' | 'commodity'>('product');
    const [currentType, setcurrentType] = useState<'product' | 'commodity'>('commodity');
    const [dangerousGood, setdangerousGood] = useState({ view: false, value: false });
    const [skuValidation, setskuValidation] = useState(false);
    const [hsCodeValidation, sethsCodeValidation] = useState(false);
    const [selectopened, setSelectOpened] = useState(false);
    const [newPacksCreated, setnewPacksCreated] = useState(false);
    const [packEdited, setpackEdited] = useState(false);
    const [productEdited, setproductEdited] = useState(false);

    const handleChange = (e: any) => {
        const value = e.target.value;
        const regex = /^[a-zA-Z0-9 ]+$/; //this will admit letters, numbers and dashes
        if (value.match(regex) || value === '') {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        }
    };
    const handleChangeNum = (e: any) => {
        if (countDecimals(parseFloat(e.target.value)) && e.target.value?.length < 18) {
            setInputs((prevState) => ({ ...prevState, [e.target.name]: parseFloat(e.target.value) }));
        } else {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };
    parentArray1 = props.vendors ? JSON.parse(JSON.stringify(props.vendors)) : [];
    const { editFailed, editStared, clientCode, isLoading, warning } = useSelector(
        (state: RootState) => state.productData
    );
    var found = false;

    var array1: packetInt[] = [];
    const payload = {
        currentPage: 0,
        perPageCount: 10,
        columnOrder: true,
        columnStatus: 'pm_sku',
        statusFilter: null,
        id: props.id,
    };
    useEffect(() => {
        if (!warning && !props.productModal) {
            setpacketData([]);
            setfumigation(props.data?.pm_fumigationrequired);
        }
        if (props.data.pm_packets !== undefined) {
            Object.assign(array1, JSON.parse(JSON.stringify(props.data.pm_packets)));
            seteditData(JSON.parse(JSON.stringify(array1)));
        } else {
            seteditData([]);
        }
    }, [render, props.data.pm_packets, editStat, CancelDisplay, editFailed, editStared, warning, props.productModal]);

    useEffect(() => {
        if (props.data?.pm_type === 'Commodity') {
            setcurrentType('commodity');
            setdangerousGood({
                view: false,
                value: props.data?.pm_dangerousgood,
            });
        }
        // else {
        //     setcurrentType('product');
        // }
    }, [props.data?.pm_type, props.data?.pm_dangerousgood]);

    useEffect(() => {
        if (!editFailed && editStared && !isLoading && !warning) {
            setInput('');
            seteditStat(false);
            setvendorsView([]);
            setVendorsData([]);
            setInputs(inputsInitial);
            setEditLine(null);
            seteditonAddLine(null);
            setEditRow(false);
            setAddpacket(false);
            setisSaving(false);
            setfumigationChose(false);
            setValidation(true);
            cancelFunc();
            dispatch(editingFailed(false));
            dispatch(editingStarted(false));
            setproductEdited(false);
            setpackEdited(false);
            setnewPacksCreated(false);
            // setcurrentType(props.data?.pm_type === 'Commodity' ? 'commodity' : 'product');
            setcurrentType('commodity');
            deletedAr = [];
            seteditonAddLine(null);
            setpacketData([]);
        }
    }, [editFailed, render1, editStared, isLoading, warning]);
    useEffect(() => {
        if (editStat) {
            setvendorsView(props.data.pm_vendors);

            props.data?.pm_vendors?.forEach((element: any) => {
                vendEdit.push({ vn_pk: element?.vn_PK, isRemoved: false, isAdded: false });
                seteditedVendors((_prevState: any) => [...vendEdit]);
            });
        }
    }, [props.data.pm_packets, editStat]);

    const initialValues: SopForm = {
        pm_clientid: props.id,
        pm_sku: props.data.pm_sku || '',
        pm_purchasecode: props.data.pm_purchasecode || '',
        pm_description: props.data.pm_description || '',
        pm_vendorid: ['234234234'],
        pm_fumigationrequired: props.data.pm_fumigationrequired || false,
        pm_packets: [inputsInitial],
        pm_dangerousgood: props.data.pm_dangerousgood || false,
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        //validationSchema: SopFormSchema,
        onSubmit: (values) => {
            validation(values);
        },
    });

    const packetValidation = () => {
        let flag = false;
        packetData.forEach((element) => {
            if (
                element.pp_length === 0 &&
                element.pp_height === 0 &&
                element.pp_cbm === 0 &&
                element.pp_weight === 0 &&
                element.pp_width === 0
            ) {
                flag = true;
            }
        });
        return flag;
    };
    const validation = (values: any) => {
        setValidation(true);
        dispatch(productAddErrorStatus(false));
        if (!editStat && props.productModal) {
            if (
                values.pm_description === '' ||
                vendorsData.length === 0 ||
                (currentType === 'product'
                    ? values.pm_sku === '' || packetData.length === 0
                    : // || packetValidation()
                      values.pm_purchasecode === '')
            ) {
                setValidation(false);
                onPackSave();
                setisSaving(true);
                if (packetData.length === 0) {
                    setAddpacket(true);
                }
            } else {
                const newVal = {
                    ...values,
                    pm_vendorid: vendorsData,
                    pm_fumigationrequired: fumigation,
                    pm_type: currentType,
                };
                if (currentType === 'commodity') {
                    newVal['pm_dangerousgood'] = dangerousGood.value;
                    newVal['pm_sku'] = `CC.${values.pm_purchasecode}`;
                } else {
                    newVal['pm_sku'] = values.pm_sku;
                    newVal['pm_packets'] = packetData;
                }
                if (
                    currentType === 'product' &&
                    editonAddLine === 0 &&
                    packetData?.length === 1 &&
                    packetData[0].pp_quantity === 'Each'
                ) {
                    error('Please save the pack details to continue', Position.TOP_RIGHT);
                } else {
                    dispatch(createProduct({ payload: newVal, reload: payload }));
                    setrender1(!render1);
                    setvendorsWarning(false);
                }
            }
        } else if (editing) {
            if (
                values.pm_sku === '' ||
                values.pm_description === '' ||
                vendorsView.length === 0 ||
                (currentType === 'product' && packetData.length === 0 && array.length === 0)
            ) {
                setValidation(false);
                onPackSave();
                setisSaving(true);
                if (packetData.length === 0 && array.length === 0) {
                    setAddpacket(true);
                }
                setvendorsWarning(true);
            } else {
                setsetWarning(true);
                editSubmit(values);
            }
        }
    };

    const onPackSave = () => {
        setisSaving(true);
        if (
            // inputs?.pp_cbm !== 0 &&
            !Number.isNaN(inputs?.pp_cbm) &&
            // inputs?.pp_conversionquantity !== 0 &&
            !Number.isNaN(inputs?.pp_conversionquantity) &&
            // inputs?.pp_length !== 0 &&
            !Number.isNaN(inputs?.pp_length) &&
            inputs?.pp_quantity !== '' &&
            // inputs?.pp_height !== 0 &&
            !Number.isNaN(inputs?.pp_height) &&
            // inputs?.pp_weight !== 0 &&
            !Number.isNaN(inputs?.pp_weight) &&
            // inputs?.pp_width !== 0 &&
            !Number.isNaN(inputs?.pp_width)
        ) {
            setpacketData((oldArray) => [...oldArray, inputs]);
            setInputs(inputsInitial);
            setAddpacket(false);
            setisSaving(false);
            setnewPacksCreated(true);
        }
    };
    const newPacksAdd = (index: any) => {
        setisSaving(true);
        if (
            // packetData[index]?.pp_cbm !== 0 &&
            !Number.isNaN(packetData[index]?.pp_cbm) &&
            // packetData[index]?.pp_conversionquantity !== 0 &&
            !Number.isNaN(packetData[index]?.pp_conversionquantity) &&
            // packetData[index]?.pp_length !== 0 &&
            !Number.isNaN(packetData[index]?.pp_length) &&
            packetData[index]?.pp_quantity !== '' &&
            // packetData[index]?.pp_height !== 0 &&
            !Number.isNaN(packetData[index]?.pp_height) &&
            // packetData[index]?.pp_weight !== 0 &&
            !Number.isNaN(packetData[index]?.pp_weight) &&
            // packetData[index]?.pp_width !== 0 &&
            !Number.isNaN(packetData[index]?.pp_width)
        ) {
            setisSaving(false);
            seteditonAddLine(null);
            setEditRow(false);
            setEditLine(null);
            setpacketData((oldArray) => [...oldArray, inputs]);
            setInputs(inputsInitial);
            setAddpacket(false);
            setnewPacksCreated(true);
        }
    };
    const onPackAddEdit = (index: any) => {
        setisSaving(true);
        if (
            // packetData[index]?.pp_cbm !== 0 &&
            !Number.isNaN(packetData[index]?.pp_cbm) &&
            // packetData[index]?.pp_conversionquantity !== 0 &&
            !Number.isNaN(packetData[index]?.pp_conversionquantity) &&
            // packetData[index]?.pp_length !== 0 &&
            !Number.isNaN(packetData[index]?.pp_length) &&
            packetData[index]?.pp_quantity !== '' &&
            // packetData[index]?.pp_height !== 0 &&
            !Number.isNaN(packetData[index]?.pp_height) &&
            // packetData[index]?.pp_weight !== 0 &&
            !Number.isNaN(packetData[index]?.pp_weight) &&
            // packetData[index]?.pp_width !== 0 &&
            !Number.isNaN(packetData[index]?.pp_width)
        ) {
            setisSaving(false);
            seteditonAddLine(null);
            setpackEdited(true);
        }
    };

    const onPackSaveEdit = (index: any) => {
        setisSaving(true);
        if (
            // array[index]?.pp_cbm !== 0 &&
            !Number.isNaN(array[index]?.pp_cbm) &&
            // array[index]?.pp_conversionquantity !== 0 &&
            !Number.isNaN(array[index]?.pp_conversionquantity) &&
            // array[index]?.pp_length !== 0 &&
            !Number.isNaN(array[index]?.pp_length) &&
            array[index]?.pp_quantity !== '' &&
            // array[index]?.pp_height !== 0 &&
            !Number.isNaN(array[index]?.pp_height) &&
            // array[index]?.pp_weight !== 0 &&
            !Number.isNaN(array[index]?.pp_weight) &&
            // array[index]?.pp_width !== 0 &&
            !Number.isNaN(array[index]?.pp_width)
        ) {
            seteditData([...array]);
            setEditRow(false);
            setisSaving(false);
            setpackEdited(true);
        }
        //setInputs(inputsInitial);
        //setAddpacket(false);
    };
    const selectedVendors = (value: any) => {
        if (value !== null) {
            setVendorsData((oldArray) => [...oldArray, value?.vn_PK]);
            setvendorsView((oldArray) => [...oldArray, value]);
            setvendorsWarning(false);
            vendEdit.push({ vn_pk: value.vn_PK, isRemoved: false, isAdded: true });
            seteditedVendors((prevState: any) => [...prevState, ...vendEdit]);
        }
    };

    const filePacketDifference = () => {
        const newArr: any = [];
        array.forEach((e: any) => {
            props.data.pm_packets?.forEach((r: any) => {
                if (e.pp_PK === r.pp_PK) {
                    if (
                        e.pp_barcode !== r.pp_barcode ||
                        e.pp_cbm !== r.pp_cbm ||
                        e.pp_conversionquantity !== r.pp_conversionquantity ||
                        e.pp_height !== r.pp_height ||
                        e.pp_length !== r.pp_length ||
                        e.pp_quantity !== r.pp_quantity ||
                        e.pp_weight !== r.pp_weight ||
                        e.pp_width !== r.pp_width
                    ) {
                        newArr.push(e);
                    }
                }
            });
        });
        return newArr;
    };
    const vendorsEditedFn = () => {
        let flag = false;
        editedVendors?.forEach((v: any) => {
            if (v.vn_pk !== '' && (v.isRemoved || v.isAdded)) {
                flag = true;
            }
        });
        if (
            props.data.pm_sku !== formik.values.pm_sku ||
            props.data.pm_description !== formik.values.pm_description ||
            props.data.pm_purchasecode !== formik.values.pm_purchasecode ||
            props.data.pm_dangerousgood !== dangerousGood.value ||
            props.data.pm_fumigationrequired !== fumigation
        ) {
            flag = true;
        }
        return flag;
    };
    const editSubmit = (values: any) => {
        var lists = editedVendors.filter((x: any) => {
            return x.vn_pk != '';
        });

        const newVal = {
            ...values,
            pm_fumigationrequired: fumigation,
            pm_vendorlinkunlink: lists,
        };
        if (currentType === 'commodity') {
            newVal['pm_dangerousgood'] = dangerousGood.value;
            newVal['pm_sku'] = `CC.${values.pm_purchasecode}`;
            newVal['pm_packets'] = undefined;
        } else {
            newVal['pm_sku'] = values.pm_sku;
            newVal['pm_packets'] = packetData;
        }

        const payloadEdit = {
            payload: filePacketDifference().length > 0 ? filePacketDifference() : array,
            reload: payload,
            values: newVal,
            editID: props.editID,
            editRow: editRow,
            newPacksCreatiOn: newPacksCreated,
            packEdited: packEdited,
            deletedArrayLength: deletedAr?.length,
            productEdited: productEdited || vendorsEditedFn(),
        };
        if (deletedAr?.length > 0) {
            dispatch(
                removePacket({
                    pp_pk: deletedAr,
                    pm_pk: props.editID,
                    payload: payload,
                })
            );
        }

        if (JSON.stringify(inputsInitial) !== JSON.stringify(inputs) && props.data.pm_packets.length === array.length) {
            //setCancelDisplay({ view: true, click: false, text: 'You have an unsaved packet data!' });
        } else {
            if (!formik.values.pm_sku.includes(' ')) {
                dispatch(editProduct(payloadEdit));
            } else {
                setValidation(false);
            }

            if (!formik.values.pm_sku.includes(' ')) {
                var packetArray: any = [...packetData];
                setrender1(!render1);
                packetArray.forEach((element: any) => {
                    delete element.pp_PK;
                    element.pp_productId = props.editID;
                });
                dispatch(createSinglePacket({ payload: packetArray, reload: payload }));
                setpacketData([]);
                setValidation(true);
            }
        }
    };
    const arryFilter = () => {
        /* eslint no-var: off */
        const childArray = vendorsView;
        for (let i = 0; i < parentArray1?.length; i++) {
            for (let j = 0; j < childArray?.length; j++) {
                if (parentArray1[i]?.vn_PK === childArray[j]?.vn_PK) {
                    parentArray1.splice(i, 1);
                }
            }
        }
        if (props.vendors?.length === vendorsView.length) {
            return [];
        } else {
            for (let i = 0; i <= parentArray1?.length; i++) {
                for (let j = 0; j < vendorsView?.length; j++) {
                    if (parentArray1[i]?.vn_PK === vendorsView[j]?.vn_PK) {
                        parentArray1.splice(i, 1);
                    }
                }
            }
        }

        return parentArray1;
    };
    const closeBtn = () => {
        if (
            (formik.dirty ||
                vendorsData.length !== 0 ||
                !packetData ||
                Object.keys(packetData).length !== 0 ||
                JSON.stringify(inputsInitial) !== JSON.stringify(inputs) ||
                fumigationChose ||
                (props.data?.pm_vendors !== undefined && vendorsView?.length !== props.data?.pm_vendors?.length)) &&
            (editStat || props.productModal)
        ) {
            setCancelDisplay({
                view: true,
                click: false,
                text: 'Are you sure you want to discard all the changes made ?',
            });
        } else {
            if (!warning) {
                props.setproductModal(false);
                props.setviewModal(false);
                setInput('');
                cancelFunc();
            }
        }
    };

    var countDecimals = (value: any) => {
        let flag = true;
        let flag2 = true;
        if (value % 1 != 0) {
            if (value.toString().split('.')[1]?.length > 5) {
                flag = false;
            }
        } else {
            if (value.toString().split('')?.length > 11) {
                flag2 = false;
            }
        }
        return flag && flag2;
    };

    const cancelFunc = () => {
        if (!warning) {
            props.setproductModal(false);
            localStorage.removeItem('showSkuModal');
            props.setviewModal(false);
            setInput('');
            setfumigationChose(false);
            setInputs(inputsInitial);
            setAddpacket(false);
            formik.resetForm();
            setvendorsView([]);
            setVendorsData([]);
            setisSaving(false);
            setfumigationDrop(false);
            setvendorsWarning(false);
            setpacketData([]);
            setEditLine(null);
            seteditonAddLine(null);
            setEditRow(false);
            setfumigation(false);
            setAddpacket(false);
            seteditStat(false);
            setisSaving(false);
            setrender(!render);
            setValidation(true);
            seteditData([]);
            setskuValidation(false);
            sethsCodeValidation(false);
            setproductEdited(false);
            setpackEdited(false);
            setnewPacksCreated(false);
            deletedAr = [];
            if (props.data.pm_packets !== undefined) {
                Object.assign(array1, JSON.parse(JSON.stringify(props.data.pm_packets)));
                seteditData(JSON.parse(JSON.stringify(array1)));
            }
        }
    };

    const filterSelection = () => {
        let newAr2: any = [...conversionQtyList];

        var newPacketData: any = [...array, ...packetData];
        if (newPacketData?.length > 0) {
            for (let j = 0; j < newPacketData?.length; j++) {
                newAr2 = newAr2?.filter((filt: any) => newPacketData[j]?.pp_quantity !== filt.value);
            }
        } else {
            newAr2 = [...conversionQtyList];
        }

        return newAr2;
    };

    const filterSelectionEdit = () => {
        let newAr2: any = [...conversionQtyList];

        var newPacketData: any = [...array, ...packetData];
        if (newPacketData > 0) {
            for (let j = 0; j < newPacketData?.length; j++) {
                newAr2 = newAr2?.filter((filt: any) => newPacketData?.pp_quantity !== filt.value);
            }
        } else {
            newAr2 = [...conversionQtyList];
        }
        return newAr2;
    };
    const editPacketDataRow = (fieldName: any, value: any, index: any) => {
        if (countDecimals(parseFloat(value)) && value?.length < 18) {
            const newAr: any = JSON.parse(JSON.stringify(array));
            newAr[index][fieldName] = parseFloat(value);
            seteditData(newAr);
        } else {
            return false;
        }
    };

    useEffect(() => {
        //setting each value by default
        const initialData: any = {
            pp_conversionquantity: 1,
            pp_quantity: 'Each',
            pp_length: 0,
            pp_width: 0,
            pp_height: 0,
            pp_weight: 0,
            pp_barcode: '',
            pp_cbm: 0,
            pp_PK: '',
        };
        //checking if each already exists
        if (props?.data?.pm_packets?.some((e: any) => e.pp_quantity === 'Each')) {
            //setpacketData([initialData]);
        } else {
            setpacketData([initialData]);
            seteditonAddLine(0);
        }
    }, [props.productModal, props.viewModal, props.data.pm_packets]);

    useEffect(() => {
        validatingHscode(formik.values.pm_purchasecode);
    }, [currentType]);

    const validatingHscode = (value: any) => {
        setTimeout(async () => {
            const validationHsCode = await checkSkunHsName({
                type: 'hscode',
                value: value,
                clientCode: clientCode,
            });
            if (value != props.data?.pm_purchasecode) {
                sethsCodeValidation(validationHsCode);
            }
        }, 1000);
    };

    const validationFnOnChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        decimalNumberValidation(parseFloat(e.target.value), 11, 5) && e.target.value?.length < 18;

    const valiadtionFnOnKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.currentTarget.value?.length > 16) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    };

    return (
        <>
            {(props.productModal || props.viewModal) && (
                <div className="app-modal large-popup ">
                    <div
                        className="modal-content-holder sop-content-holder large-popup"
                        onClick={(e) => {
                            e.stopPropagation();
                            setSearch(false);
                            setfumigationDrop(false);
                            setdangerousGood({ ...dangerousGood, view: false });
                        }}
                    >
                        <div className="modal-header">
                            <h2 className="title">
                                {props.viewModal
                                    ? 'Product Details'
                                    : editStat
                                    ? 'Edit Product Details'
                                    : 'Add Product'}
                            </h2>
                            <button
                                className="app-btn modal-close-btn"
                                title="Close"
                                onClick={() => closeBtn()}
                                type="reset"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-section card">
                                <div className="modal-main-content details-box">
                                    {props.viewModal ? (
                                        <ViewData data={props.data} />
                                    ) : (
                                        <>
                                            {/* {!editStat && (
                                                <div className="checkbox-wrapper-modal">
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="radio"
                                                                className="checkbox-input"
                                                                name="pc"
                                                                checked={currentType === 'product'}
                                                                onChange={() => setcurrentType('product')}
                                                                disabled={
                                                                    !editStat && props.productModal ? false : true
                                                                }
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon notification-circle-icon">
                                                                    <use href="#circleIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">Product</div>
                                                        </label>
                                                    </div>
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                type="radio"
                                                                className="checkbox-input"
                                                                name="pc"
                                                                checked={currentType === 'commodity'}
                                                                onChange={() => setcurrentType('commodity')}
                                                                disabled={
                                                                    !editStat && props.productModal ? false : true
                                                                }
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon notification-circle-icon">
                                                                    <use href="#circleIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">Commodity</div>
                                                        </label>
                                                    </div>
                                                </div>
                                            )} */}
                                            <div className="input-form-wrapper">
                                                <div className="modal-col-1">
                                                    <form
                                                        id="productForm"
                                                        action=""
                                                        onSubmit={formik.handleSubmit}
                                                        className="modal-form"
                                                    >
                                                        {/* Sku added for commodity screen sprint11 */}
                                                        {currentType === 'commodity' ? (
                                                            <div className=" modal-input-holder">
                                                                <label className="modal-input-label">
                                                                    SKU<span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-layout-item">
                                                                    <input
                                                                        placeholder="CC.HSCODE"
                                                                        value={'CC.' + formik.values.pm_purchasecode}
                                                                        type="text"
                                                                        className="modal-input input-text read-only"
                                                                        id="ordertype"
                                                                        name="ordertype"
                                                                        readOnly
                                                                    ></input>
                                                                    {hsCodeValidation && (
                                                                        <p className="error_message_product">
                                                                            SKU already exists
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className=" modal-input-holder">
                                                                <label htmlFor="SKU" className="modal-input-label">
                                                                    SKU<span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-field-holder">
                                                                    <input
                                                                        placeholder="Enter value"
                                                                        type="text"
                                                                        className="modal-input input-text with-border"
                                                                        id="SKU"
                                                                        onChange={(event) => {
                                                                            const value = event.target.value;
                                                                            // const regex = /^[0-9a-zA-Z(\-)]+$/; //this will admit letters, numbers and dashes
                                                                            if (!value.includes(' ')) {
                                                                                formik.setFieldError(
                                                                                    event.target.name,
                                                                                    undefined
                                                                                );
                                                                                formik.handleChange(event);
                                                                            } else {
                                                                                formik.setFieldValue(
                                                                                    'pm_sku',
                                                                                    value.replace(/\s+/g, '')
                                                                                );
                                                                            }
                                                                            setTimeout(async () => {
                                                                                const validationSku =
                                                                                    await checkSkunHsName({
                                                                                        type: 'sku',
                                                                                        value: event.target.value,
                                                                                        clientCode: clientCode,
                                                                                    });
                                                                                if (
                                                                                    event.target.value !=
                                                                                    props.data?.pm_sku
                                                                                ) {
                                                                                    setskuValidation(validationSku);
                                                                                }
                                                                            }, 1000);
                                                                        }}
                                                                        name="pm_sku"
                                                                        value={formik.values.pm_sku}
                                                                        maxLength={150}
                                                                    ></input>
                                                                    {formik.values.pm_sku === '' && !Validation && (
                                                                        <p className="error_message_product">
                                                                            SKU is required
                                                                        </p>
                                                                    )}
                                                                    {/\s/.test(formik.values.pm_sku) && !Validation && (
                                                                        <p className="error_message_product">
                                                                            SKU cannot have space in them
                                                                        </p>
                                                                    )}
                                                                    {skuValidation && (
                                                                        <p className="error_message_product">
                                                                            SKU already exists
                                                                        </p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                        <div className=" modal-input-holder">
                                                            <label htmlFor="hsCode" className="modal-input-label">
                                                                Code{' '}
                                                                {currentType === 'commodity' ? (
                                                                    <span className="mandatory-field-text">*</span>
                                                                ) : null}
                                                            </label>
                                                            <div className="modal-input-field-holder">
                                                                <input
                                                                    placeholder="Enter Code"
                                                                    type="text"
                                                                    className="modal-input input-text with-border"
                                                                    id="hsCode"
                                                                    onChange={(event) => {
                                                                        const value = event.target.value;
                                                                        const regex = /^[0-9a-zA-Z(\-.)]+$/; //this will admit letters, numbers and dashes
                                                                        if (value.match(regex) || value === '') {
                                                                            formik.setFieldError(
                                                                                event.target.name,
                                                                                undefined
                                                                            );
                                                                            formik.handleChange(event);
                                                                            if (currentType === 'commodity') {
                                                                                validatingHscode(event.target.value);
                                                                            }
                                                                        } else {
                                                                            formik.setFieldValue(
                                                                                'pm_purchasecode',
                                                                                value
                                                                                    .replace(/[^a-zA-Z0-9.-]/g, '')
                                                                                    .replace(/\s+/g, '')
                                                                            );
                                                                        }
                                                                    }}
                                                                    name="pm_purchasecode"
                                                                    value={formik.values.pm_purchasecode}
                                                                    maxLength={140}
                                                                ></input>
                                                                {currentType === 'commodity' &&
                                                                    formik.values.pm_purchasecode === '' &&
                                                                    !Validation && (
                                                                        <p className="error_message_product">
                                                                            Code is required
                                                                        </p>
                                                                    )}
                                                                {currentType === 'commodity' &&
                                                                    /\s/.test(formik.values.pm_purchasecode) &&
                                                                    !Validation && (
                                                                        <p className="error_message_product">
                                                                            Code cannot have space in them
                                                                        </p>
                                                                    )}
                                                            </div>
                                                        </div>
                                                        <div className=" modal-input-holder">
                                                            <label htmlFor="vendors" className="modal-input-label">
                                                                Vendors<span className="mandatory-field-text">*</span>
                                                            </label>
                                                            <div className="search-entry-holder modal-search">
                                                                <input
                                                                    placeholder="Select Vendor"
                                                                    type="text"
                                                                    className="modal-input input-text with-border"
                                                                    onChange={(e) => setInput(e.target.value)}
                                                                    value={inputVal}
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setSearch(!Search);
                                                                    }}
                                                                ></input>
                                                                {inputVal.length > 0 && (
                                                                    <button
                                                                        className="app-btn text-close-btn"
                                                                        onClick={() => setInput('')}
                                                                    >
                                                                        <svg className="svg-icon text-close-icon">
                                                                            <use xlinkHref="#closeIcon"></use>
                                                                        </svg>
                                                                    </button>
                                                                )}
                                                            </div>
                                                            {Search && (
                                                                <div
                                                                    className="modal-main-content details-box"
                                                                    style={{ zIndex: '100' }}
                                                                    onClick={(e) => e.stopPropagation()}
                                                                >
                                                                    <div
                                                                        className={
                                                                            Search
                                                                                ? 'dropdown-wrap  menu-down dropdown-open text-dropdown vendor-suggestion-menu'
                                                                                : 'dropdown-wrap  menu-down text-dropdown vendor-suggestion-menu'
                                                                        }
                                                                    >
                                                                        <div
                                                                            className="dropdown-menu"
                                                                            style={{
                                                                                maxHeight: '180px',
                                                                                display: 'block',
                                                                            }}
                                                                        >
                                                                            {arryFilter()
                                                                                .filter((val: any) =>
                                                                                    val?.vn_vendorname
                                                                                        .toLowerCase()
                                                                                        .includes(
                                                                                            inputVal?.toLowerCase()
                                                                                        )
                                                                                )
                                                                                ?.map((client: any) => {
                                                                                    found = true;
                                                                                    return (
                                                                                        <a
                                                                                            className="menu-item app-btn"
                                                                                            style={{ display: 'block' }}
                                                                                            onClick={(e) => {
                                                                                                e.stopPropagation();
                                                                                                selectedVendors(client);
                                                                                                setSearch(false);
                                                                                                if (inputVal) {
                                                                                                    setInput('');
                                                                                                }
                                                                                            }}
                                                                                        >
                                                                                            <span
                                                                                                className="highlight-content"
                                                                                                style={{
                                                                                                    fontWeight:
                                                                                                        'normal',
                                                                                                    display: 'unset',
                                                                                                }}
                                                                                            >
                                                                                                {client.vn_vendorname}
                                                                                            </span>
                                                                                        </a>
                                                                                    );
                                                                                })}
                                                                            {!found && (
                                                                                <div className="no-data-content">
                                                                                    <svg className="svg-icon grid-no-content-icon ">
                                                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                                                    </svg>
                                                                                    <p className="no-content-message">
                                                                                        No Records Found
                                                                                    </p>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}

                                                            {!Validation && vendorsView.length === 0 && (
                                                                <p
                                                                    style={{ paddingLeft: '8px' }}
                                                                    className="error_message_product"
                                                                >
                                                                    Atleast one vendor is required
                                                                </p>
                                                            )}
                                                        </div>
                                                    </form>
                                                    <div className="suggestion-container">
                                                        {vendorsView?.map((vend: any, index: any) => (
                                                            <div className="content-card">
                                                                <span className="main-content">
                                                                    {vend?.vn_vendorname}
                                                                </span>
                                                                <button
                                                                    className="app-btn close-btn"
                                                                    title="Close"
                                                                    onClick={() => {
                                                                        setvendorsView((currentImg) =>
                                                                            currentImg.filter((img, i) => i !== index)
                                                                        );
                                                                        setVendorsData((currentImg) =>
                                                                            currentImg.filter((img, i) => i !== index)
                                                                        );
                                                                        editedVendors.forEach((element: any) => {
                                                                            if (element.vn_pk === vend.vn_PK) {
                                                                                vendEdit.push({
                                                                                    vn_pk: vend.vn_PK,
                                                                                    isRemoved: true,
                                                                                    isAdded: false,
                                                                                });
                                                                                seteditedVendors((prevState: any) => [
                                                                                    ...prevState,
                                                                                    ...vendEdit,
                                                                                ]);
                                                                            }
                                                                        });
                                                                    }}
                                                                >
                                                                    <span>
                                                                        <svg className="svg-icon circle-close-icon">
                                                                            <use xlinkHref="#closeIcon">
                                                                                <title>Close</title>
                                                                            </use>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>

                                                <div className="modal-col-2">
                                                    <form
                                                        id="productForm"
                                                        action=""
                                                        onSubmit={formik.handleSubmit}
                                                        className="modal-form"
                                                    >
                                                        <div
                                                            className=" modal-input-holder"
                                                            style={{ paddingBottom: '16px' }}
                                                        >
                                                            <label htmlFor="name" className="modal-input-label">
                                                                Description
                                                                <span className="mandatory-field-text">*</span>
                                                            </label>
                                                            <div className="modal-input-field-holder">
                                                                <textarea
                                                                    style={{ width: '100%' }}
                                                                    required
                                                                    name="pm_description"
                                                                    id=""
                                                                    rows={5}
                                                                    onChange={formik.handleChange}
                                                                    className="input-text"
                                                                    value={formik.values.pm_description}
                                                                    maxLength={150}
                                                                ></textarea>
                                                                {formik.values.pm_description === '' && !Validation && (
                                                                    <p className="error_message_product">
                                                                        Description is required
                                                                    </p>
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className=" modal-input-holder">
                                                            <label htmlFor="name" className="modal-input-label">
                                                                Fumigation Required
                                                                <span className="mandatory-field-text">*</span>
                                                            </label>
                                                            <div className="modal-input-field-holder">
                                                                <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                    <button
                                                                        className="menu-btn app-btn"
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            setfumigationDrop(!fumigationDrop);
                                                                        }}
                                                                        type="button"
                                                                    >
                                                                        <span
                                                                            className="btn-text"
                                                                            style={{ color: 'black' }}
                                                                        >
                                                                            {fumigation ? 'Yes' : 'No'}
                                                                        </span>
                                                                        <span
                                                                            className="dropdown-arrow"
                                                                            style={{ transform: 'rotate(-90deg)' }}
                                                                        >
                                                                            <svg className="svg-icon arrow-icon">
                                                                                <use xlinkHref="#arrowIcon">
                                                                                    <title>dropdown</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                    {fumigationDrop && (
                                                                        <div className="dropdown-menu indexChange">
                                                                            <a
                                                                                href="#"
                                                                                className="menu-item app-btn"
                                                                                onClick={() => {
                                                                                    setfumigation(true);
                                                                                    setfumigationDrop(false);
                                                                                    setfumigationChose(true);
                                                                                }}
                                                                            >
                                                                                Yes
                                                                            </a>
                                                                            <a
                                                                                href="#"
                                                                                className="menu-item app-btn"
                                                                                onClick={() => {
                                                                                    setfumigation(false);
                                                                                    setfumigationDrop(false);
                                                                                    setfumigationChose(true);
                                                                                }}
                                                                            >
                                                                                No
                                                                            </a>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* Dangerous Good added for commodity screen sprint11 */}
                                                        {currentType === 'commodity' && (
                                                            <div className=" modal-input-holder">
                                                                <label htmlFor="name" className="modal-input-label">
                                                                    Dangerous Good
                                                                    <span className="mandatory-field-text">*</span>
                                                                </label>
                                                                <div className="modal-input-field-holder">
                                                                    <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                        <button
                                                                            className="menu-btn app-btn"
                                                                            type="button"
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                setdangerousGood({
                                                                                    ...dangerousGood,
                                                                                    view: !dangerousGood.view,
                                                                                });
                                                                            }}
                                                                        >
                                                                            <span className="btn-text">
                                                                                {dangerousGood?.value ? 'Yes' : 'No'}
                                                                            </span>
                                                                            <span
                                                                                className="dropdown-arrow"
                                                                                style={{ transform: 'rotate(-90deg)' }}
                                                                            >
                                                                                <svg className="svg-icon arrow-icon">
                                                                                    <use xlinkHref="#arrowIcon">
                                                                                        <title>dropdown</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </span>
                                                                        </button>
                                                                        {dangerousGood.view && (
                                                                            <div className="dropdown-menu">
                                                                                <a
                                                                                    href="#"
                                                                                    className="menu-item app-btn"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setdangerousGood({
                                                                                            view: false,
                                                                                            value: true,
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    Yes
                                                                                </a>
                                                                                <a
                                                                                    href="#"
                                                                                    className="menu-item app-btn"
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        setdangerousGood({
                                                                                            view: false,
                                                                                            value: false,
                                                                                        });
                                                                                    }}
                                                                                >
                                                                                    No
                                                                                </a>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </form>
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                            {!!(currentType === 'product') && (
                                <div className="modal-section model-section-grid">
                                    <div className="grid-section modal-grid">
                                        <div className="grid-holder">
                                            <div className="grid-header">
                                                <h2 className="">
                                                    Pack Details<span className="mandatory-field-text">*</span>
                                                </h2>
                                                {(props.productModal || editStat) && !props.viewModal && (
                                                    <button
                                                        className="app-btn app-btn-secondary icon-button  add-product-btn"
                                                        title="Add Pack"
                                                        onClick={() => setAddpacket(true)}
                                                    >
                                                        <svg className="svg-icon add-btn-icon">
                                                            <use xlinkHref="#plusIcon"></use>
                                                        </svg>
                                                        <span className="button-text footer-button-text">Add Pack</span>
                                                    </button>
                                                )}
                                            </div>
                                            <form
                                                id="productForm"
                                                action=""
                                                onSubmit={formik.handleSubmit}
                                                className="modal-form"
                                            >
                                                <div className="grid-container">
                                                    {/* content when No data found in grid*/}

                                                    {/* <div className="grid-no-content ">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No Packet details added</p> */}

                                                    {/* </div> */}

                                                    <div className="grid-scroll-content">
                                                        {!props.productModal && props.viewModal && (
                                                            <table className="grid-table">
                                                                <thead>
                                                                    <tr className="grid-sticky-row">
                                                                        <th
                                                                            className="grid-cell-header grid-sticky-column sticky-left-aligned col-quantity-uom"
                                                                            style={{ zIndex: '1000' }}
                                                                        >
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    Quantity UOM
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header  col-conversion-qty">
                                                                            <div className=" grid-cell-data">
                                                                                <span className="header-title">
                                                                                    COnVERSION QUAntITY
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-length">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    Length(m)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-width">
                                                                            <div className=" grid-cell-data">
                                                                                <span className="header-title">
                                                                                    width(m)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-height">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    height(m)
                                                                                </span>
                                                                            </div>
                                                                        </th>

                                                                        <th className="grid-cell-header col-cbm">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    CBM
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header col-weight">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    weight(kg)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header grid-sticky-column sticky-right-aligned col-barcode">
                                                                            <div className=" grid-cell-data">
                                                                                <span className="header-title">
                                                                                    barcode
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {props.data.pm_packets?.map((res: any) => (
                                                                        <tr className="has-child row-expanded">
                                                                            <td
                                                                                style={{
                                                                                    position: selectopened
                                                                                        ? 'initial'
                                                                                        : 'sticky',
                                                                                }}
                                                                                className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                            >
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_quantity}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-conversion-qty">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_conversionquantity}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-length">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_length}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell th-width">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_width}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-height">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_height}
                                                                                </div>
                                                                            </td>

                                                                            <td className="grid-cell td-cbm ">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_cbm}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-weight ">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_weight}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell grid-sticky-column sticky-right-aligned td-barcode">
                                                                                <div className="grid-cell-data">
                                                                                    {res?.pp_barcode}
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        )}
                                                        {!props.viewModal && props.productModal && (
                                                            <table className="grid-table">
                                                                {packetData.length !== 0 && (
                                                                    <tbody>
                                                                        {packetData?.map((res: any, index: any) =>
                                                                            index !== editonAddLine ? (
                                                                                <tr className="has-child row-expanded">
                                                                                    <td
                                                                                        style={{
                                                                                            position: selectopened
                                                                                                ? 'initial'
                                                                                                : 'sticky',
                                                                                        }}
                                                                                        className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                                    >
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_quantity}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-conversion-qty">
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_conversionquantity}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-length">
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_length}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell th-width">
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_width}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-height">
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_height}
                                                                                        </div>
                                                                                    </td>

                                                                                    <td className="grid-cell td-cbm ">
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_cbm}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-weight ">
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_weight}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell grid-sticky-column td-barcode">
                                                                                        <div className="grid-cell-data">
                                                                                            {res?.pp_barcode}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                                        <div className="grid-cell-data">
                                                                                            <button
                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                title="Remove"
                                                                                                type="button"
                                                                                                disabled={
                                                                                                    res.pp_quantity ===
                                                                                                    'Each'
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    var newAr = [
                                                                                                        ...array,
                                                                                                    ];
                                                                                                    packetData.splice(
                                                                                                        index,
                                                                                                        1
                                                                                                    );
                                                                                                    setpacketData([
                                                                                                        ...packetData,
                                                                                                    ]);
                                                                                                    //newAr.splice(index, 1);
                                                                                                    seteditData(newAr);

                                                                                                    //setrender(!render);
                                                                                                }}
                                                                                            >
                                                                                                <svg className="svg-icon cancel-icon ">
                                                                                                    <use xlinkHref="#cancelIcon"></use>
                                                                                                    <title>
                                                                                                        Remove
                                                                                                    </title>
                                                                                                </svg>
                                                                                            </button>
                                                                                            <button
                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                title="Edit"
                                                                                                onClick={() => {
                                                                                                    seteditonAddLine(
                                                                                                        index
                                                                                                    );
                                                                                                }}
                                                                                                type="button"
                                                                                            >
                                                                                                <svg className="svg-icon save-icon ">
                                                                                                    <use xlinkHref="#editIcon"></use>
                                                                                                    <title>Edit</title>
                                                                                                </svg>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr className="has-child row-expanded align-top">
                                                                                    <td
                                                                                        className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                                        style={{
                                                                                            position: selectopened
                                                                                                ? 'initial'
                                                                                                : 'sticky',
                                                                                        }}
                                                                                    >
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                                                <form
                                                                                                    id="productForm"
                                                                                                    action=""
                                                                                                    onSubmit={
                                                                                                        formik.handleSubmit
                                                                                                    }
                                                                                                    className="modal-form react-select"
                                                                                                >
                                                                                                    <Select
                                                                                                        menuPlacement="bottom"
                                                                                                        //menuShouldScrollIntoView={
                                                                                                        //    false
                                                                                                        //}
                                                                                                        isDisabled={
                                                                                                            res?.pp_quantity ===
                                                                                                            'Each'
                                                                                                        }
                                                                                                        onMenuOpen={() =>
                                                                                                            setSelectOpened(
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                        onMenuClose={() =>
                                                                                                            setSelectOpened(
                                                                                                                false
                                                                                                            )
                                                                                                        }
                                                                                                        options={filterSelectionEdit()}
                                                                                                        isClearable
                                                                                                        placeholder="Select"
                                                                                                        menuPosition="fixed"
                                                                                                        onChange={(
                                                                                                            option: any
                                                                                                        ) => {
                                                                                                            var newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_quantity =
                                                                                                                option.value;
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        }}
                                                                                                        styles={
                                                                                                            reactSelectStyle
                                                                                                        }
                                                                                                        value={conversionQtyList.filter(
                                                                                                            (
                                                                                                                option: any
                                                                                                            ) => {
                                                                                                                if (
                                                                                                                    option.value ===
                                                                                                                    res.pp_quantity
                                                                                                                ) {
                                                                                                                    return {
                                                                                                                        label: res.pp_quantity,
                                                                                                                        value: res.pp_quantity,
                                                                                                                    };
                                                                                                                }
                                                                                                            }
                                                                                                        )}
                                                                                                        //value={inputs?.pp_quantity || ''}
                                                                                                        components={{
                                                                                                            DropdownIndicator:
                                                                                                                () =>
                                                                                                                    null,
                                                                                                            IndicatorSeparator:
                                                                                                                () =>
                                                                                                                    null,
                                                                                                        }}
                                                                                                    />
                                                                                                </form>
                                                                                                {res?.pp_quantity ===
                                                                                                    '' &&
                                                                                                    isSaving && (
                                                                                                        <p className="error_message_product">
                                                                                                            This field
                                                                                                            is required
                                                                                                        </p>
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell  td-conversion-qty">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_conversionquantity"
                                                                                                    min="0"
                                                                                                    disabled={
                                                                                                        res?.pp_quantity ===
                                                                                                        'Each'
                                                                                                    }
                                                                                                    value={
                                                                                                        res?.pp_conversionquantity
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        if (
                                                                                                            validationFnOnChange(
                                                                                                                e
                                                                                                            )
                                                                                                        ) {
                                                                                                            const newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_conversionquantity =
                                                                                                                parseFloat(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                );
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        } else {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            return false;
                                                                                                        }
                                                                                                    }}
                                                                                                ></input>
                                                                                                {
                                                                                                    // res?.pp_conversionquantity ===
                                                                                                    // 0 ||
                                                                                                    Number.isNaN(
                                                                                                        res.pp_conversionquantity
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-length">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    name="pp_length"
                                                                                                    value={
                                                                                                        res?.pp_length
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        if (
                                                                                                            validationFnOnChange(
                                                                                                                e
                                                                                                            )
                                                                                                        ) {
                                                                                                            const newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_length =
                                                                                                                parseFloat(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                );
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        } else {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            return false;
                                                                                                        }
                                                                                                    }}
                                                                                                    className="modal-grid-input input-text"
                                                                                                ></input>
                                                                                                {
                                                                                                    // res?.pp_length ===
                                                                                                    // 0 ||
                                                                                                    Number.isNaN(
                                                                                                        res.pp_length
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell th-width">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    name="pp_width"
                                                                                                    value={
                                                                                                        res?.pp_width
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        if (
                                                                                                            validationFnOnChange(
                                                                                                                e
                                                                                                            )
                                                                                                        ) {
                                                                                                            const newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_width =
                                                                                                                parseFloat(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                );
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        } else {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            return false;
                                                                                                        }
                                                                                                    }}
                                                                                                    className="modal-grid-input input-text"
                                                                                                ></input>
                                                                                                {
                                                                                                    // res?.pp_width === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        res.pp_width
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-height">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    name="pp_height"
                                                                                                    value={
                                                                                                        res?.pp_height
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        if (
                                                                                                            validationFnOnChange(
                                                                                                                e
                                                                                                            )
                                                                                                        ) {
                                                                                                            const newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_height =
                                                                                                                parseFloat(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                );
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        } else {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            return false;
                                                                                                        }
                                                                                                    }}
                                                                                                    className="modal-grid-input input-text"
                                                                                                ></input>
                                                                                                {
                                                                                                    // res?.pp_height ===
                                                                                                    // 0 ||
                                                                                                    Number.isNaN(
                                                                                                        res.pp_height
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>

                                                                                    <td className="grid-cell td-cbm ">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_cbm"
                                                                                                    value={res?.pp_cbm}
                                                                                                    onChange={(e) => {
                                                                                                        if (
                                                                                                            validationFnOnChange(
                                                                                                                e
                                                                                                            )
                                                                                                        ) {
                                                                                                            const newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_cbm = parseFloat(
                                                                                                                e.target
                                                                                                                    .value
                                                                                                            );
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        } else {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            return false;
                                                                                                        }
                                                                                                    }}
                                                                                                    onKeyPress={(
                                                                                                        event
                                                                                                    ) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            event
                                                                                                        );
                                                                                                        if (
                                                                                                            !validateFractionInput(
                                                                                                                {
                                                                                                                    inputKey:
                                                                                                                        event.key,
                                                                                                                    currentValue:
                                                                                                                        packetData[
                                                                                                                            index
                                                                                                                        ]
                                                                                                                            .pp_cbm,
                                                                                                                }
                                                                                                            )
                                                                                                        ) {
                                                                                                            event.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                ></input>
                                                                                                {
                                                                                                    // res?.pp_cbm === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        res.pp_cbm
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-weight">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_weight"
                                                                                                    value={
                                                                                                        res?.pp_weight
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        if (
                                                                                                            validationFnOnChange(
                                                                                                                e
                                                                                                            )
                                                                                                        ) {
                                                                                                            const newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_weight =
                                                                                                                parseFloat(
                                                                                                                    e
                                                                                                                        .target
                                                                                                                        .value
                                                                                                                );
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        } else {
                                                                                                            e.preventDefault();
                                                                                                            e.stopPropagation();
                                                                                                            return false;
                                                                                                        }
                                                                                                    }}
                                                                                                ></input>
                                                                                                {
                                                                                                    // res?.pp_weight === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        res.pp_weight
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell  td-barcode">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="text"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_barcode"
                                                                                                    value={
                                                                                                        res?.pp_barcode ||
                                                                                                        ''
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        const value =
                                                                                                            e.target
                                                                                                                .value;
                                                                                                        const regex =
                                                                                                            /^[a-zA-Z0-9 ]+$/; //this will admit letters, numbers and dashes
                                                                                                        if (
                                                                                                            value.match(
                                                                                                                regex
                                                                                                            ) ||
                                                                                                            value === ''
                                                                                                        ) {
                                                                                                            var newArr =
                                                                                                                [
                                                                                                                    ...packetData,
                                                                                                                ];
                                                                                                            newArr[
                                                                                                                index
                                                                                                            ].pp_barcode =
                                                                                                                e.target.value;
                                                                                                            setpacketData(
                                                                                                                newArr
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                    maxLength={150}
                                                                                                ></input>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="grid-cell-data">
                                                                                                <button
                                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                                    title="Remove"
                                                                                                    type="button"
                                                                                                    disabled={
                                                                                                        res.pp_quantity ===
                                                                                                        'Each'
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        var newAr = [
                                                                                                            ...packetData,
                                                                                                        ];

                                                                                                        newAr.splice(
                                                                                                            index,
                                                                                                            1
                                                                                                        );
                                                                                                        setpacketData(
                                                                                                            newAr
                                                                                                        );
                                                                                                        setAddpacket(
                                                                                                            false
                                                                                                        );
                                                                                                        seteditStat(
                                                                                                            false
                                                                                                        );
                                                                                                        setisSaving(
                                                                                                            false
                                                                                                        );
                                                                                                    }}
                                                                                                >
                                                                                                    <svg className="svg-icon cancel-icon ">
                                                                                                        <use xlinkHref="#cancelIcon"></use>
                                                                                                        <title>
                                                                                                            Remove
                                                                                                        </title>
                                                                                                    </svg>
                                                                                                </button>
                                                                                                <button
                                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                                    title="Save"
                                                                                                    onClick={() =>
                                                                                                        onPackAddEdit(
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                    type="button"
                                                                                                >
                                                                                                    <svg className="svg-icon save-icon ">
                                                                                                        <use xlinkHref="#saveIcon"></use>
                                                                                                        <title>
                                                                                                            Save
                                                                                                        </title>
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )
                                                                        )}
                                                                    </tbody>
                                                                )}
                                                                <thead>
                                                                    <tr className="grid-sticky-row">
                                                                        <th
                                                                            className="grid-cell-header grid-sticky-column sticky-left-aligned col-quantity-uom"
                                                                            style={{
                                                                                zIndex: selectopened ? '0' : '10',
                                                                            }}
                                                                        >
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    Quantity UOM
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th
                                                                            className="grid-cell-header col-conversion-qty"
                                                                            style={{ zIndex: '0' }}
                                                                        >
                                                                            <div className=" grid-cell-data">
                                                                                <span className="header-title">
                                                                                    COnVERSION QUAntITY
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th
                                                                            className="grid-cell-header col-length"
                                                                            style={{ zIndex: '0' }}
                                                                        >
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    Length(m)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th
                                                                            className="grid-cell-header col-width"
                                                                            style={{ zIndex: '0' }}
                                                                        >
                                                                            <div className=" grid-cell-data">
                                                                                <span className="header-title">
                                                                                    width(m)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th
                                                                            className="grid-cell-header col-height"
                                                                            style={{ zIndex: '0' }}
                                                                        >
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    height(m)
                                                                                </span>
                                                                            </div>
                                                                        </th>

                                                                        <th className="grid-cell-header col-cbm">
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    CBM
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th
                                                                            className="grid-cell-header col-weight"
                                                                            style={{ zIndex: '0' }}
                                                                        >
                                                                            <div className="grid-cell-data">
                                                                                <span className="header-title">
                                                                                    weight(kg)
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header  col-barcode">
                                                                            <div className=" grid-cell-data">
                                                                                <span className="header-title">
                                                                                    barcode
                                                                                </span>
                                                                            </div>
                                                                        </th>
                                                                        <th className="grid-cell-header centre-align grid-sticky-column sticky-right-aligned col-actions">
                                                                            <div className="grid-cell-data header-title">
                                                                                ACTIONS
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                {(editStat || editRow) &&
                                                                    array?.map((packets: any, index: any) => (
                                                                        <tbody>
                                                                            {editRow && editLine === index ? (
                                                                                <tr className="has-child row-expanded align-top">
                                                                                    <td
                                                                                        className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                                        style={{
                                                                                            position: selectopened
                                                                                                ? 'initial'
                                                                                                : 'sticky',
                                                                                        }}
                                                                                    >
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                                                <form
                                                                                                    id="productForm"
                                                                                                    action=""
                                                                                                    onSubmit={
                                                                                                        formik.handleSubmit
                                                                                                    }
                                                                                                    className="modal-form react-select"
                                                                                                >
                                                                                                    <Select
                                                                                                        menuPlacement="bottom"
                                                                                                        //menuShouldScrollIntoView={
                                                                                                        //    false
                                                                                                        //}
                                                                                                        isDisabled={
                                                                                                            array[index]
                                                                                                                ?.pp_quantity ===
                                                                                                            'Each'
                                                                                                        }
                                                                                                        options={filterSelection()}
                                                                                                        isClearable
                                                                                                        placeholder="Select"
                                                                                                        menuPosition="fixed"
                                                                                                        onMenuOpen={() =>
                                                                                                            setSelectOpened(
                                                                                                                true
                                                                                                            )
                                                                                                        }
                                                                                                        onMenuClose={() =>
                                                                                                            setSelectOpened(
                                                                                                                false
                                                                                                            )
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            option: any
                                                                                                        ) => {
                                                                                                            var newAr =
                                                                                                                [
                                                                                                                    ...array,
                                                                                                                ];
                                                                                                            newAr[
                                                                                                                index
                                                                                                            ].pp_quantity =
                                                                                                                option.value;

                                                                                                            seteditData(
                                                                                                                newAr
                                                                                                            );
                                                                                                        }}
                                                                                                        value={{
                                                                                                            label: array[
                                                                                                                index
                                                                                                            ]
                                                                                                                .pp_quantity,
                                                                                                            value: array[
                                                                                                                index
                                                                                                            ]
                                                                                                                .pp_quantity,
                                                                                                        }}
                                                                                                        //value={inputs?.pp_quantity || ''}
                                                                                                        styles={
                                                                                                            reactSelectStyle
                                                                                                        }
                                                                                                        components={{
                                                                                                            DropdownIndicator:
                                                                                                                () =>
                                                                                                                    null,
                                                                                                            IndicatorSeparator:
                                                                                                                () =>
                                                                                                                    null,
                                                                                                        }}
                                                                                                    />
                                                                                                    {formik.errors
                                                                                                        .pm_vendorid ? (
                                                                                                        <div className="error-text">
                                                                                                            {
                                                                                                                formik
                                                                                                                    .errors
                                                                                                                    .pm_description
                                                                                                            }
                                                                                                        </div>
                                                                                                    ) : null}
                                                                                                </form>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell  td-conversion-qty">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_conversionquantity"
                                                                                                    disabled={
                                                                                                        array[index]
                                                                                                            ?.pp_quantity ===
                                                                                                        'Each'
                                                                                                    }
                                                                                                    value={
                                                                                                        array[index]
                                                                                                            ?.pp_conversionquantity
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        editPacketDataRow(
                                                                                                            'pp_conversionquantity',
                                                                                                            e.target
                                                                                                                .value,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                ></input>
                                                                                                {
                                                                                                    // array[index]
                                                                                                    // ?.pp_conversionquantity ===
                                                                                                    // 0 ||
                                                                                                    Number.isNaN(
                                                                                                        array[index]
                                                                                                            .pp_conversionquantity
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-length">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    name="pp_length"
                                                                                                    value={
                                                                                                        array[index]
                                                                                                            ?.pp_length
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        editPacketDataRow(
                                                                                                            'pp_length',
                                                                                                            e.target
                                                                                                                .value,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                    className="modal-grid-input input-text"
                                                                                                ></input>
                                                                                                {
                                                                                                    // array[index]
                                                                                                    // ?.pp_length === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        array[index]
                                                                                                            .pp_length
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell th-width">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    name="pp_width"
                                                                                                    value={
                                                                                                        array[index]
                                                                                                            ?.pp_width
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        editPacketDataRow(
                                                                                                            'pp_width',
                                                                                                            e.target
                                                                                                                .value,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                    className="modal-grid-input input-text"
                                                                                                ></input>
                                                                                                {
                                                                                                    // array[index]
                                                                                                    // ?.pp_width === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        array[index]
                                                                                                            .pp_width
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-height">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    name="pp_height"
                                                                                                    value={
                                                                                                        array[index]
                                                                                                            ?.pp_height
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        editPacketDataRow(
                                                                                                            'pp_height',
                                                                                                            e.target
                                                                                                                .value,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                    className="modal-grid-input input-text"
                                                                                                ></input>
                                                                                                {
                                                                                                    // array[index]
                                                                                                    // ?.pp_height === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        array[index]
                                                                                                            .pp_height
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-cbm ">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_cbm"
                                                                                                    value={
                                                                                                        array[index]
                                                                                                            ?.pp_cbm
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        editPacketDataRow(
                                                                                                            'pp_cbm',
                                                                                                            e.target
                                                                                                                .value,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                    onKeyPress={(
                                                                                                        event
                                                                                                    ) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            event
                                                                                                        );
                                                                                                        if (
                                                                                                            !validateFractionInput(
                                                                                                                {
                                                                                                                    inputKey:
                                                                                                                        event.key,
                                                                                                                    currentValue:
                                                                                                                        array[
                                                                                                                            index
                                                                                                                        ]
                                                                                                                            .pp_cbm,
                                                                                                                }
                                                                                                            )
                                                                                                        ) {
                                                                                                            event.preventDefault();
                                                                                                        }
                                                                                                    }}
                                                                                                ></input>
                                                                                                {
                                                                                                    // array[index]
                                                                                                    // ?.pp_cbm === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        array[index]
                                                                                                            .pp_cbm
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-weight">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="number"
                                                                                                    min="0"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_weight"
                                                                                                    value={
                                                                                                        array[index]
                                                                                                            ?.pp_weight
                                                                                                    }
                                                                                                    onKeyPress={(e) => {
                                                                                                        valiadtionFnOnKeyPress(
                                                                                                            e
                                                                                                        );
                                                                                                    }}
                                                                                                    onChange={(e) => {
                                                                                                        editPacketDataRow(
                                                                                                            'pp_weight',
                                                                                                            e.target
                                                                                                                .value,
                                                                                                            index
                                                                                                        );
                                                                                                    }}
                                                                                                ></input>
                                                                                                {
                                                                                                    // array[index]
                                                                                                    // ?.pp_weight === 0 ||
                                                                                                    Number.isNaN(
                                                                                                        array[index]
                                                                                                            .pp_weight
                                                                                                    ) &&
                                                                                                        isSaving && (
                                                                                                            <p className="error_message_product">
                                                                                                                This
                                                                                                                field is
                                                                                                                required
                                                                                                            </p>
                                                                                                        )
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell  td-barcode">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="modal-grid-input-holder">
                                                                                                <input
                                                                                                    placeholder="Enter value"
                                                                                                    type="text"
                                                                                                    className="modal-grid-input input-text"
                                                                                                    name="pp_barcode"
                                                                                                    defaultValue={
                                                                                                        array[index]
                                                                                                            ?.pp_barcode ||
                                                                                                        ''
                                                                                                    }
                                                                                                    onChange={(e) => {
                                                                                                        /* eslint no-var: off */
                                                                                                        const value =
                                                                                                            e.target
                                                                                                                .value;
                                                                                                        const regex =
                                                                                                            /^[a-zA-Z0-9 ]+$/; //this will admit letters, numbers and dashes
                                                                                                        if (
                                                                                                            value.match(
                                                                                                                regex
                                                                                                            ) ||
                                                                                                            value === ''
                                                                                                        ) {
                                                                                                            var newAr =
                                                                                                                [
                                                                                                                    ...array,
                                                                                                                ];
                                                                                                            newAr[
                                                                                                                index
                                                                                                            ].pp_barcode =
                                                                                                                e.target.value;
                                                                                                            seteditData(
                                                                                                                newAr
                                                                                                            );
                                                                                                        }
                                                                                                    }}
                                                                                                    maxLength={150}
                                                                                                ></input>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                                        <div className="grid-cell-data">
                                                                                            <div className="grid-cell-data">
                                                                                                <button
                                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                                    title="Remove"
                                                                                                    type="button"
                                                                                                    disabled={
                                                                                                        array[index]
                                                                                                            .pp_quantity ===
                                                                                                        'Each'
                                                                                                    }
                                                                                                    onClick={() => {
                                                                                                        var newAr = [
                                                                                                            ...array,
                                                                                                        ];

                                                                                                        newAr.splice(
                                                                                                            index,
                                                                                                            1
                                                                                                        );
                                                                                                        seteditData(
                                                                                                            newAr
                                                                                                        );
                                                                                                        setAddpacket(
                                                                                                            false
                                                                                                        );
                                                                                                        seteditStat(
                                                                                                            false
                                                                                                        );
                                                                                                        setisSaving(
                                                                                                            false
                                                                                                        );

                                                                                                        //setrender(!render);
                                                                                                    }}
                                                                                                >
                                                                                                    <svg className="svg-icon cancel-icon ">
                                                                                                        <use xlinkHref="#cancelIcon"></use>
                                                                                                        <title>
                                                                                                            Remove
                                                                                                        </title>
                                                                                                    </svg>
                                                                                                </button>
                                                                                                <button
                                                                                                    className="action-btn app-btn app-btn-secondary "
                                                                                                    title="Save"
                                                                                                    onClick={() =>
                                                                                                        onPackSaveEdit(
                                                                                                            index
                                                                                                        )
                                                                                                    }
                                                                                                    type="button"
                                                                                                >
                                                                                                    <svg className="svg-icon save-icon ">
                                                                                                        <use xlinkHref="#saveIcon"></use>
                                                                                                        <title>
                                                                                                            Save
                                                                                                        </title>
                                                                                                    </svg>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ) : (
                                                                                <tr className="has-child row-expanded">
                                                                                    <td
                                                                                        className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                                        style={{
                                                                                            position: selectopened
                                                                                                ? 'initial'
                                                                                                : 'sticky',
                                                                                        }}
                                                                                    >
                                                                                        <div className="grid-cell-data">
                                                                                            {packets?.pp_quantity}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-conversion-qty">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                packets?.pp_conversionquantity
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-length">
                                                                                        <div className="grid-cell-data">
                                                                                            {packets?.pp_length}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell th-width">
                                                                                        <div className="grid-cell-data">
                                                                                            {packets?.pp_width}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-height">
                                                                                        <div className="grid-cell-data">
                                                                                            {packets?.pp_height}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-cbm ">
                                                                                        <div className="grid-cell-data">
                                                                                            {packets?.pp_cbm}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell td-weight ">
                                                                                        <div className="grid-cell-data">
                                                                                            {packets?.pp_weight}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell grid-sticky-column td-barcode">
                                                                                        <div className="grid-cell-data">
                                                                                            {packets?.pp_barcode}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                                        <div className="grid-cell-data">
                                                                                            <button
                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                title="Remove"
                                                                                                type="button"
                                                                                                disabled={
                                                                                                    packets.pp_quantity ===
                                                                                                    'Each'
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    var newAr = [
                                                                                                        ...array,
                                                                                                    ];
                                                                                                    setdeleteArray(
                                                                                                        (
                                                                                                            _prevState: any
                                                                                                        ) =>
                                                                                                            packets?.pp_PK
                                                                                                    );

                                                                                                    newAr.splice(
                                                                                                        index,
                                                                                                        1
                                                                                                    );
                                                                                                    seteditData(newAr);
                                                                                                    deletedAr.push(
                                                                                                        array[index]
                                                                                                            ?.pp_PK
                                                                                                    );
                                                                                                    //setrender(!render);
                                                                                                }}
                                                                                            >
                                                                                                <svg className="svg-icon cancel-icon ">
                                                                                                    <use xlinkHref="#cancelIcon"></use>
                                                                                                    <title>
                                                                                                        Remove
                                                                                                    </title>
                                                                                                </svg>
                                                                                            </button>
                                                                                            <button
                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                title="Edit"
                                                                                                onClick={() => {
                                                                                                    setEditRow(true);
                                                                                                    setEditLine(index);
                                                                                                }}
                                                                                                type="button"
                                                                                            >
                                                                                                <svg className="svg-icon save-icon ">
                                                                                                    <use xlinkHref="#editIcon"></use>
                                                                                                    <title>Edit</title>
                                                                                                </svg>
                                                                                            </button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            )}
                                                                        </tbody>
                                                                    ))}
                                                                {((editStat && addPacket) || addPacket) && (
                                                                    <tbody>
                                                                        <tr className="has-child row-expanded align-top">
                                                                            <td
                                                                                className="grid-cell  grid-sticky-column sticky-left-aligned td-quantity-uom"
                                                                                style={{
                                                                                    position: selectopened
                                                                                        ? 'initial'
                                                                                        : 'sticky',
                                                                                }}
                                                                            >
                                                                                <div className="grid-cell-data">
                                                                                    <div className="dropdown-wrap  menu-down dropdown-open modal-dropdown">
                                                                                        <form
                                                                                            id="productForm"
                                                                                            action=""
                                                                                            onSubmit={
                                                                                                formik.handleSubmit
                                                                                            }
                                                                                            className="modal-form react-select"
                                                                                        >
                                                                                            <Select
                                                                                                openMenuOnFocus={true}
                                                                                                menuPlacement="bottom"
                                                                                                //menuShouldScrollIntoView={
                                                                                                //    false
                                                                                                //}
                                                                                                isDisabled={
                                                                                                    inputs?.pp_quantity ===
                                                                                                    'Each'
                                                                                                }
                                                                                                options={filterSelection()}
                                                                                                isClearable
                                                                                                placeholder="Select"
                                                                                                menuPosition="fixed"
                                                                                                onMenuOpen={() =>
                                                                                                    setSelectOpened(
                                                                                                        true
                                                                                                    )
                                                                                                }
                                                                                                onMenuClose={() =>
                                                                                                    setSelectOpened(
                                                                                                        false
                                                                                                    )
                                                                                                }
                                                                                                onChange={(
                                                                                                    option: any
                                                                                                ) => {
                                                                                                    setInputs({
                                                                                                        ...inputs,
                                                                                                        pp_quantity:
                                                                                                            option.value,
                                                                                                    });
                                                                                                }}
                                                                                                styles={
                                                                                                    reactSelectStyle
                                                                                                }
                                                                                                value={conversionQtyList.filter(
                                                                                                    (option: any) => {
                                                                                                        if (
                                                                                                            option.value ===
                                                                                                            inputs.pp_quantity
                                                                                                        ) {
                                                                                                            return {
                                                                                                                label: inputs.pp_quantity,
                                                                                                                value: inputs.pp_quantity,
                                                                                                            };
                                                                                                        }
                                                                                                    }
                                                                                                )}
                                                                                                //value={inputs?.pp_quantity || ''}
                                                                                                components={{
                                                                                                    DropdownIndicator:
                                                                                                        () => null,
                                                                                                    IndicatorSeparator:
                                                                                                        () => null,
                                                                                                }}
                                                                                            />
                                                                                        </form>
                                                                                        {inputs?.pp_quantity === '' &&
                                                                                            isSaving && (
                                                                                                <p className="error_message_product">
                                                                                                    This field is
                                                                                                    required
                                                                                                </p>
                                                                                            )}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell  td-conversion-qty">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        <input
                                                                                            placeholder="Enter value"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            className="modal-grid-input input-text"
                                                                                            name="pp_conversionquantity"
                                                                                            onChange={handleChangeNum}
                                                                                            disabled={
                                                                                                inputs?.pp_quantity ===
                                                                                                'Each'
                                                                                            }
                                                                                            onKeyPress={(e) => {
                                                                                                valiadtionFnOnKeyPress(
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                            value={
                                                                                                inputs.pp_conversionquantity
                                                                                            }
                                                                                        ></input>
                                                                                        {
                                                                                            // inputs?.pp_conversionquantity ===
                                                                                            // 0 ||
                                                                                            Number.isNaN(
                                                                                                inputs.pp_conversionquantity
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-length">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        <input
                                                                                            placeholder="Enter value"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            name="pp_length"
                                                                                            onKeyPress={(e) => {
                                                                                                valiadtionFnOnKeyPress(
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                            onChange={handleChangeNum}
                                                                                            value={inputs.pp_length}
                                                                                            className="modal-grid-input input-text"
                                                                                        ></input>
                                                                                        {
                                                                                            // inputs?.pp_length === 0 ||
                                                                                            Number.isNaN(
                                                                                                inputs.pp_length
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell th-width">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        <input
                                                                                            placeholder="Enter value"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            name="pp_width"
                                                                                            onKeyPress={(e) => {
                                                                                                valiadtionFnOnKeyPress(
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                            onChange={handleChangeNum}
                                                                                            value={inputs.pp_width}
                                                                                            className="modal-grid-input input-text"
                                                                                        ></input>
                                                                                        {
                                                                                            // inputs?.pp_width === 0 ||
                                                                                            Number.isNaN(
                                                                                                inputs.pp_width
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-height">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        <input
                                                                                            placeholder="Enter value"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            name="pp_height"
                                                                                            onKeyPress={(e) => {
                                                                                                valiadtionFnOnKeyPress(
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                            onChange={handleChangeNum}
                                                                                            value={inputs.pp_height}
                                                                                            className="modal-grid-input input-text"
                                                                                        ></input>
                                                                                        {
                                                                                            // inputs?.pp_height === 0 ||
                                                                                            Number.isNaN(
                                                                                                inputs.pp_height
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-cbm ">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        <input
                                                                                            placeholder="Enter value"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            className="modal-grid-input input-text"
                                                                                            name="pp_cbm"
                                                                                            defaultValue={inputs.pp_cbm}
                                                                                            value={inputs.pp_cbm}
                                                                                            onChange={handleChangeNum}
                                                                                            onKeyPress={(event) => {
                                                                                                valiadtionFnOnKeyPress(
                                                                                                    event
                                                                                                );
                                                                                                if (
                                                                                                    !validateFractionInput(
                                                                                                        {
                                                                                                            inputKey:
                                                                                                                event.key,
                                                                                                            currentValue:
                                                                                                                inputs?.pp_cbm,
                                                                                                        }
                                                                                                    )
                                                                                                ) {
                                                                                                    event.preventDefault();
                                                                                                }
                                                                                            }}
                                                                                        ></input>
                                                                                        {
                                                                                            // inputs?.pp_cbm === 0 ||
                                                                                            Number.isNaN(
                                                                                                inputs.pp_cbm
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell td-weight">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        <input
                                                                                            placeholder="Enter value"
                                                                                            type="number"
                                                                                            min="0"
                                                                                            className="modal-grid-input input-text"
                                                                                            name="pp_weight"
                                                                                            onKeyPress={(e) => {
                                                                                                valiadtionFnOnKeyPress(
                                                                                                    e
                                                                                                );
                                                                                            }}
                                                                                            onChange={handleChangeNum}
                                                                                            value={inputs.pp_weight}
                                                                                        ></input>
                                                                                        {
                                                                                            // inputs?.pp_weight === 0 ||
                                                                                            Number.isNaN(
                                                                                                inputs.pp_weight
                                                                                            ) &&
                                                                                                isSaving && (
                                                                                                    <p className="error_message_product">
                                                                                                        This field is
                                                                                                        required
                                                                                                    </p>
                                                                                                )
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell  td-barcode">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="modal-grid-input-holder">
                                                                                        <input
                                                                                            placeholder="Enter value"
                                                                                            type="text"
                                                                                            className="modal-grid-input input-text"
                                                                                            name="pp_barcode"
                                                                                            onChange={handleChange}
                                                                                            maxLength={150}
                                                                                            value={inputs.pp_barcode}
                                                                                        ></input>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell centre-align grid-sticky-column  sticky-right-aligned td-actions">
                                                                                <div className="grid-cell-data">
                                                                                    <div className="grid-cell-data">
                                                                                        <button
                                                                                            className="action-btn app-btn app-btn-secondary "
                                                                                            title="Remove"
                                                                                            type="button"
                                                                                            disabled={
                                                                                                inputs.pp_quantity ===
                                                                                                'Each'
                                                                                            }
                                                                                            onClick={() => {
                                                                                                setInputs(
                                                                                                    inputsInitial
                                                                                                );
                                                                                                setAddpacket(false);
                                                                                                setisSaving(false);
                                                                                            }}
                                                                                        >
                                                                                            <svg className="svg-icon cancel-icon ">
                                                                                                <use xlinkHref="#cancelIcon"></use>
                                                                                                <title>Remove</title>
                                                                                            </svg>
                                                                                        </button>
                                                                                        <button
                                                                                            className="action-btn app-btn app-btn-secondary "
                                                                                            title="Save"
                                                                                            onClick={() =>
                                                                                                editStat &&
                                                                                                props.data
                                                                                                    .pm_packets === 0
                                                                                                    ? newPacksAdd(null)
                                                                                                    : onPackSave()
                                                                                            }
                                                                                            type="button"
                                                                                        >
                                                                                            <svg className="svg-icon save-icon ">
                                                                                                <use xlinkHref="#saveIcon"></use>
                                                                                                <title>Save</title>
                                                                                            </svg>
                                                                                        </button>
                                                                                        {/*<button
                                                                            className="action-btn app-btn app-btn-secondary "
                                                                            title="Edit"
                                                                        >
                                                                            <svg className="svg-icon edit-icon">
                                                                                <use xlinkHref="#editIcon"></use>
                                                                                <title>edit</title>
                                                                            </svg>
                                                                        </button>*/}
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                )}
                                                            </table>
                                                        )}
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="modal-footer">
                            <form
                                id="productForm"
                                action=""
                                onSubmit={formik.handleSubmit}
                                className="modal-form"
                                style={{ width: 'unset' }}
                            >
                                <button
                                    className="app-btn app-btn-secondary footer-btn"
                                    title="Cancel"
                                    onClick={() => closeBtn()}
                                    type="reset"
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                            </form>
                            {CancelDisplay.view && (
                                <CancelModal
                                    setDisplayCancel={setCancelDisplay}
                                    displayCancel={CancelDisplay}
                                    onOkFunc={cancelFunc}
                                />
                            )}
                            <ShowForPermissions permission="edit" type="client">
                                {props.viewModal ? (
                                    <button
                                        //form="productForm"
                                        className="app-btn app-btn-primary footer-btn"
                                        title="Edit"
                                        onClick={() => {
                                            props.setproductModal(true);
                                            props.setviewModal(false);
                                            setInput('');
                                            seteditStat(true);
                                            setediting(true);
                                            setfumigationDrop(false);
                                            setEditLine(null);
                                            seteditonAddLine(null);
                                            setfumigationChose(false);
                                            setEditRow(true);
                                        }}
                                        type="button"
                                        style={{ marginLeft: '8px' }}
                                    >
                                        <span className="button-text footer-button-text">Edit</span>
                                    </button>
                                ) : editStat ? (
                                    <form
                                        id="productForm"
                                        action=""
                                        onSubmit={formik.handleSubmit}
                                        className="modal-form"
                                        style={{ width: 'unset' }}
                                    >
                                        <button
                                            type="submit"
                                            disabled={
                                                currentType === 'commodity'
                                                    ? (!formik.dirty &&
                                                          vendorsData.length === 0 &&
                                                          packetData.length === 0 &&
                                                          editRow === true &&
                                                          !fumigationChose === true &&
                                                          vendorsView.length === props.data?.pm_vendors?.length &&
                                                          array.length === props.data?.pm_packets?.length &&
                                                          props.data?.pm_dangerousgood === dangerousGood.value) ||
                                                      skuValidation ||
                                                      hsCodeValidation
                                                    : (!formik.dirty &&
                                                          vendorsData.length === 0 &&
                                                          packetData.length === 0 &&
                                                          editRow === true &&
                                                          !fumigationChose === true &&
                                                          vendorsView.length === props.data?.pm_vendors?.length &&
                                                          array.length === props.data?.pm_packets?.length &&
                                                          props.data?.pm_dangerousgood === dangerousGood.value) ||
                                                      skuValidation
                                            }
                                            //form="productForm"
                                            className="app-btn app-btn-primary footer-btn"
                                            title="Save Changes"
                                            style={{ marginLeft: '8px' }}
                                        >
                                            <span className="button-text footer-button-text">Save Changes</span>
                                        </button>
                                    </form>
                                ) : (
                                    (props.productModal && editStat) ||
                                    (props.productModal && (
                                        <form
                                            id="productForm"
                                            action=""
                                            onSubmit={formik.handleSubmit}
                                            className="modal-form"
                                            style={{ width: 'unset' }}
                                        >
                                            <button
                                                type="submit"
                                                className="app-btn app-btn-primary footer-btn"
                                                title={currentType === 'product' ? 'Add product' : 'Add commodity'}
                                                style={{ marginLeft: '8px' }}
                                                disabled={
                                                    currentType === 'product'
                                                        ? skuValidation
                                                        : hsCodeValidation
                                                        ? true
                                                        : false
                                                }
                                            >
                                                <span className="button-text footer-button-text">
                                                    {currentType === 'product' ? 'Add product' : 'Add commodity'}
                                                </span>
                                            </button>
                                        </form>
                                    ))
                                )}
                            </ShowForPermissions>

                            {/*</form>*/}
                            {/* <button className="app-btn app-btn-primary footer-btn save-changes-btn" title="Save changes">
                            <span className="button-text footer-button-text">Save Changes</span>
                        </button> */}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Index;
