import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/scss/main.scss';
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from '@azure/msal-browser';
import { forgotPasswordRequest, msalConfig } from './authConfig';
import { loginRequest } from './authConfig';
// import { sessionExpiry } from './utils/sessionExpiry';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from 'src/store/store';

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    // sessionExpiry(msalInstance);
}
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    } else if (event.eventType === EventType.LOGIN_FAILURE) {
        if (event?.error?.message.includes('AADB2C90118')) {
            msalInstance.loginRedirect(forgotPasswordRequest);
        } else if (event?.error?.message.includes('AADB2C90091')) {
            msalInstance.loginRedirect(loginRequest);
        }
    } else if (event?.error?.message.includes('AADB2C90091')) {
        localStorage.setItem('showToast', 'false');
        localStorage.removeItem('tokenAuthTime');
        // msalInstance.loginRedirect(loginRequest);
    }
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <Router>
            <Provider store={store}>
                <App pca={msalInstance} />
            </Provider>
        </Router>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
