import React, { useEffect } from 'react';
import { loginRequest } from '../../authConfig';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import Loading from '../../components/common/Loading';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import NoNetwork from '../common/NoNetwork';
import { useLocation } from 'react-router-dom';

interface Props {
    children: React.ReactNode;
}
const Index: React.FC<Props> = ({ children }): JSX.Element => {
    const authRequest = {
        ...loginRequest,
    };

    const location = useLocation();

    const { instance, accounts } = useMsal();

    const isUserAuthenticated = accounts && accounts.length ? Boolean(accounts[0]) : false;

    useEffect(() => {
        localStorage.setItem('orderServiceFailed', 'false');
        window.dispatchEvent(new Event('orderServiceFailedEvt'));
        localStorage.setItem('mgtServiceFailed', 'false');
        window.dispatchEvent(new Event('mgtServiceFailedEvt'));
        localStorage.setItem('bkgServiceFailed', 'false');
        window.dispatchEvent(new Event('bkgServiceFailedEvt'));
        if (!localStorage.getItem('token') && isUserAuthenticated) {
            setTimeout(() => {
                localStorage.clear();
                instance.logoutRedirect({ postLogoutRedirectUri: '/' });
            }, 1500);
        }
    }, [location]);

    const isOnline = useOnlineStatus();
    if (!isOnline) {
        return <NoNetwork />;
    }

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={authRequest}
            loadingComponent={Loading}
        >
            {children}
        </MsalAuthenticationTemplate>
    );
};

export default Index;
