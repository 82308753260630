import axios from 'axios';
import fileDownload from 'js-file-download';
import { patch, post } from '../../HttpMethods';
import { fileUploadForDocuments } from '../bookingProposal/additionalDetailsApi';

export const saveSellQuoteDetailsApi = async (payload: any) => {
    try {
        const mode = payload?.mode;
        delete payload?.mode;
        await post('api/bkg/bp/air/sell', payload?.formData, null);
        const fileSell = payload?.formData?.get('file');
        if (fileSell) {
            const document = payload?.formData?.get('request');
            const text = await document.text();
            const bpId = JSON.parse(text)?.bookingProposalId;
            const formData = new FormData();
            formData.append('file', fileSell, fileSell.name);
            formData.append('DO_FK', bpId);
            formData.append('DO_FKID', 'BP');
            formData.append('DO_CD_DocumentType', 'HSQ');
            await fileUploadForDocuments(formData);
        }
        // data.mode = mode;
        return { data: { mode }, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateSellQuoteDetailsApi = async (payload: {
    airFreightId: string;
    type: string;
    mode: string;
    profile: string;
}) => {
    try {
        const mode = payload?.mode;

        await patch(`api/bkg/bp/air/undo/${payload.airFreightId}/${payload.type}/${payload.profile}`, null);
        // data.type = 'submitToClient';
        return { data: { mode }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const selectScheduleDetailsApi = async (payload: any) => {
    const bpId = payload.bpId;
    delete payload.bpId;
    try {
        await patch(`api/bkg/bp/approve/${bpId}`, payload);
        // data.type = 'submitToClient';
        return { data: { mode: 'selectSchedule' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const downloadFlightScheduleSell = async (
    fileId: string,
    quoteType: string,
    airFreightId: string,
    fileName: string
) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/bkg/bp/air/download?fileId=${fileId}&quoteType=${quoteType}&airFreightId=${airFreightId}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};
