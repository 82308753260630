import React, { useEffect, useState } from 'react';
import './integration.scss';
import '../agent-tab.scss';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { agentIntegrationHeaders, defaultAgentIntegration } from '../../../../utils/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
    addAgentIntegrationData,
    fetchAgentIntegrationData,
    resetFlags,
} from '../../../../store/reducers/profiles/agents/integrationReducer';
import { RootState } from '../../../../store/reducers';
import { error, Position, success } from '../../../../utils/toast';
import CancelModal from '../../Clients/IntegrationDetails/cancelModal';
import ShowForPermissions from '../../../../ShowForPermissions';
import {
    selectCommercialDetailError,
    selectCommercialDetailIsError,
    selectCommercialDetailIsSubmitAndActivated,
    submitAgent,
    clearFailure,
} from '../../../../store/reducers/profiles/agents/commercialDetailsReducer';
import { useAppSelector } from '../../../../hooks';
import HeaderTabsAgents from 'src/pages/Profiles/Agents/HeaderTabsAgents';
import { fetchHeaderDataAgents } from 'src/store/reducers/agentReducer';
interface integrationType {
    integration: string;
    isIntegrated: boolean;
}
const Index: React.FC = () => {
    const { id } = useParams();
    const [integration, setIntegration] = useState<any>([]);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        integrationData,
        isLoading,
        isSuccess,
        isError: isIntegrationError,
        errorMessage,
    } = useSelector((state: RootState) => state.agentIntegrationDetails);
    const [disableSave, setDisableSave] = useState(false);
    const [showCancelButton, setShowCancelButton] = useState(true);
    const [showCancelModal, setShowCancelModal] = useState(false);
    const isSubmitAndActivated = useAppSelector(selectCommercialDetailIsSubmitAndActivated);
    const isError = useAppSelector(selectCommercialDetailIsError);
    const commercialError = useAppSelector(selectCommercialDetailError);
    const [isLocalLoading, setIsLoading] = useState(false);
    const { agentHeader } = useSelector((state: RootState) => state.agent);

    useEffect(() => {
        if (id) {
            dispatch(fetchAgentIntegrationData({ agentId: id }));
            dispatch(fetchHeaderDataAgents(id));
        }
    }, [id]);

    useEffect(() => {
        setIntegration(integrationData);
        if (integrationData?.length === 0) {
            setShowCancelButton(false);
            setIntegration(defaultAgentIntegration);
        }
    }, [integrationData]);

    const saveIntegration = (header: string, event: any, type: boolean) => {
        const integrationData = JSON.parse(JSON.stringify(integration));
        const index = integrationData.findIndex((item: any) => item.integration === header);
        if (index > -1) {
            integrationData.splice(index, 1);
        }
        integrationData.push({
            integration: header,
            isIntegrated: type,
        });
        setIntegration(integrationData);
    };

    const saveData = () => {
        dispatch(addAgentIntegrationData({ agentId: id, integrations: integration }));
    };

    useEffect(() => {
        if (isSuccess) {
            dispatch(resetFlags({}));
            success('Integration details updated', Position.TOP_RIGHT);
            dispatch(fetchHeaderDataAgents(id));
        }
    }, [isSuccess]);

    useEffect(() => {
        if (isIntegrationError) {
            error(errorMessage || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetFlags({}));
        }
    }, [isIntegrationError]);

    const sortIntegration = (int: any) => {
        return int?.slice()?.sort((a: integrationType, b: integrationType) => {
            const fa = a.integration,
                fb = b.integration;

            if (fa < fb) {
                return -1;
            }
            if (fa > fb) {
                return 1;
            }
            return 0;
        });
    };

    const onConfirm = () => {
        dispatch(fetchAgentIntegrationData({ agentId: id }));
        setShowCancelModal(false);
    };

    const onCancelModal = () => {
        setShowCancelModal(false);
    };

    useEffect(() => {
        const integrationCopy = sortIntegration(integration);
        const integrationStateCopy = sortIntegration(integrationData);
        if (JSON.stringify(integrationCopy) === JSON.stringify(integrationStateCopy)) {
            setDisableSave(true);
        } else {
            setDisableSave(false);
            if (
                JSON.stringify(sortIntegration(defaultAgentIntegration)) === JSON.stringify(integrationCopy) &&
                integrationData.length === 0
            ) {
                setShowCancelButton(false);
            } else {
                setShowCancelButton(true);
            }
        }
    }, [integration]);

    useEffect(() => {
        setIsLoading(false);
        if (isError) {
            error(commercialError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (isSubmitAndActivated) {
            dispatch(clearFailure([]));
            success('Agent has been activated', Position.TOP_RIGHT);
            setTimeout(() => navigate('/agent'), 2000);
        }
    }, [isError, commercialError, isSubmitAndActivated]);

    return (
        <div className="main-wrapper container">
            <div className={`app-loader ${isLoading || isLocalLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link className="dropdown-arrow" to="/agent" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link to="/profile" className="breadcrumb-item">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to="/agent" className="breadcrumb-item">
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Agent
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    {/* <Link to="/agent">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">{localStorage.getItem('agentName')}</h2>
                </div>
                <div className="arrow-tab-container">
                    <div className="arrow-tab-holder vendor-arrow-holder">
                        <HeaderTabsAgents />
                    </div>
                </div>
            </div>
            <div className="content-section main-wrapper">
                <div className="main-grid-header">
                    <div className="header-holder-btn">
                        <h3 className="grid-header-title">Integration Details</h3>
                        {!disableSave ? (
                            <div className="grid-header-btn-holder">
                                {showCancelButton ? (
                                    <a href="#">
                                        <button
                                            className="app-btn app-btn-secondary footer-btn"
                                            title="Cancel"
                                            onClick={() => {
                                                setShowCancelModal(true);
                                            }}
                                        >
                                            <span className="button-text footer-button-text">Cancel</span>
                                        </button>
                                    </a>
                                ) : null}
                                <a href="#">
                                    <button
                                        className="app-btn app-btn-primary footer-btn"
                                        style={{ marginLeft: '16px' }}
                                        title="Save"
                                        onClick={() => {
                                            saveData();
                                        }}
                                    >
                                        <span className="button-text footer-button-text">Save</span>
                                    </button>
                                </a>
                            </div>
                        ) : null}
                    </div>
                </div>
                <div className="grid-holder">
                    <div className="grid-container agent-integration-grid">
                        <div className="grid-scroll-content">
                            <table className="grid-table product-details-conf">
                                <thead className="multi-row-grid-header">
                                    <tr className="grid-sticky-row">
                                        <th className="grid-cell-header col-function po-th-sku grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data">
                                                <span className="header-title">FUNCTION</span>
                                            </div>
                                        </th>
                                        <th
                                            className="grid-cell-header multi-column-th po-th-integrated centre-align"
                                            colSpan={2}
                                        >
                                            <div className="grid-cell-data">
                                                <span className="header-title">INTEGRATED</span>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr className="grid-sticky-row">
                                        <th className="grid-cell-header col-po-no grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-yes grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data">
                                                <span className="header-title">YES</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-no col-initial col-goods-ready">
                                            <div className="grid-cell-data">
                                                <span className="header-title">NO</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="grouped-splited-row">
                                    {agentIntegrationHeaders.map((header: string, index: number) => (
                                        <tr key={index}>
                                            <td className="grid-cell po-sku grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data">{header}</div>
                                            </td>

                                            <td className="grid-cell po-init col-goods-ready-init  grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data">
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                key={index}
                                                                type="radio"
                                                                className="checkbox-input"
                                                                name={`grid-radio-group ${header}${index}`}
                                                                checked={integration?.find(
                                                                    (item: {
                                                                        isIntegrated: boolean;
                                                                        integration: string;
                                                                    }) => {
                                                                        if (
                                                                            item.integration === header &&
                                                                            item.isIntegrated
                                                                        ) {
                                                                            return 'checked';
                                                                        }
                                                                    }
                                                                )}
                                                                onClick={(e) => {
                                                                    saveIntegration(header, e, true);
                                                                }}
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon notification-circle-icon">
                                                                    <use href="#circleIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="grid-cell po-conf col-goods-ready-conf ">
                                                <div className="grid-cell-data">
                                                    <div className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                key={index + '-'}
                                                                type="radio"
                                                                className="checkbox-input"
                                                                name={`grid-radio-group ${header}`}
                                                                checked={integration?.find(
                                                                    (item: {
                                                                        isIntegrated: boolean;
                                                                        integration: string;
                                                                    }) => {
                                                                        if (
                                                                            item.integration === header &&
                                                                            !item.isIntegrated
                                                                        ) {
                                                                            return 'checked';
                                                                        }
                                                                    }
                                                                )}
                                                                onClick={(e) => {
                                                                    saveIntegration(header, e, false);
                                                                }}
                                                            ></input>
                                                            <div className="checkmark">
                                                                <svg className="svg-icon notification-circle-icon">
                                                                    <use href="#circleIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/profile/agent/${id}/commercial_details`}
                        title="Previous"
                    >
                        <div className="button-text footer-button-text">Previous</div>
                    </Link>
                    <ShowForPermissions permission="edit" type="agent">
                        {localStorage.getItem('agentStatus') !== 'ACTIVE' &&
                        agentHeader?.isBLPAgent &&
                        disableSave &&
                        integrationData?.length ? (
                            <button
                                style={{ marginLeft: '8px' }}
                                type="button"
                                className="app-btn app-btn-primary footer-btn commercial-submit-btn"
                                title="Submit and Activate"
                                onClick={() => {
                                    setIsLoading(true);
                                    dispatch(submitAgent({ agentId: id }));
                                }}
                            >
                                <span className="button-text footer-button-text">Submit and Activate</span>
                            </button>
                        ) : null}
                        {localStorage.getItem('agentStatus') === 'ACTIVE' && agentHeader?.isBLPAgent ? (
                            <Link to={'/agent'} className="app-btn app-btn-primary footer-btn" title="Back to Profile">
                                <span className="button-text footer-button-text">Back to Profile</span>
                            </Link>
                        ) : null}
                    </ShowForPermissions>
                </div>
            </div>
            {showCancelModal ? <CancelModal onConfirm={onConfirm} onCancel={onCancelModal}></CancelModal> : null}
        </div>
    );
};
export default Index;
