import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import './style.css';
import '../../../assets/scss/components/_modal.scss';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    discardReceiptHeader,
    selectReceiptHeaderIsError,
    selectReceiptHeaderSuccess,
    selectReceiptHeaderError,
    clearFailure,
} from '../../../store/reducers/receipts/receiptHeaderReducer';
import { error, success, Position } from '../../../utils/toast';

interface DiscardProps {
    discardModalVisibility: boolean;
    setDiscardModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const DiscardModal: React.FC<DiscardProps> = ({ discardModalVisibility, setDiscardModalVisibility }) => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectReceiptHeaderIsError);
    const receiptHeaderSuccess = useAppSelector(selectReceiptHeaderSuccess);
    const receiptHeaderError = useAppSelector(selectReceiptHeaderError);

    const onDiscard = () => {
        setIsLoading(true);
        dispatch(
            discardReceiptHeader({
                receiptId: id,
                type: 'discard',
            })
        );
    };

    useEffect(() => {
        if (isError && receiptHeaderError?.config?.url?.includes('discard')) {
            error(receiptHeaderError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (receiptHeaderSuccess?.status === 200 || receiptHeaderSuccess?.status === 201) {
            if (receiptHeaderSuccess?.data?.type === 'discard') {
                success('Receipt discarded successfully', Position.TOP_RIGHT);
                setDiscardModalVisibility(false);
                dispatch(clearFailure([]));
                setTimeout(() => navigate('/blp/receipt'), 2000);
            }
        }
        setIsLoading(false);
    }, [isError, receiptHeaderSuccess, receiptHeaderError]);

    return (
        <>
            {discardModalVisibility ? (
                <div className="app-modal container">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Discard Receipt</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => setDiscardModalVisibility(false)}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>Are you sure you want to discard this receipt and all its details ?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => setDiscardModalVisibility(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                onClick={() => onDiscard()}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default DiscardModal;
