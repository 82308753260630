import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ShowForPermissions from 'src/ShowForPermissions';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ConfirmationModal from './ConfirmationModal';
import { approveAllocation, submitAllocation } from 'src/store/reducers/allocationOrder/basicDetails';

interface Props {
    page: string;
}

const AllocationOrderNextButton: React.FC<Props> = ({ page }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];
    const { profileType } = useUserProfile();
    const [ConfirmationView, setConfirmationView] = React.useState(false);

    const { headerDetails } = useSelector((state: RootState) => state.addAoData);

    const onCancel = () => {
        setConfirmationView(false);
    };
    const confirmAO = () => {
        dispatch(submitAllocation({ id: id, name: headerDetails?.aoNumber }));
        setConfirmationView(false);
        navigate('/allocation_orders');
    };
    const onOk = () => {
        dispatch(approveAllocation({ id: id, name: headerDetails?.aoNumber }));
        setConfirmationView(false);
        navigate('/allocation_orders');
    };

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (headerDetails) {
                if (param === 'ao-preview') {
                    return (
                        headerDetails.bdTabComplete &&
                        headerDetails.datesTabComplete &&
                        headerDetails.validDate &&
                        headerDetails.productTabComplete
                    );
                } else if (param === 'product-allocation') {
                    return headerDetails.productTabComplete;
                } else if (param === 'dates') {
                    return headerDetails.datesTabComplete && headerDetails.validDate;
                } else if (param === 'basic_details') {
                    return headerDetails.bdTabComplete;
                } else {
                    return false;
                }
            }
        };
    }, [lastSegment, headerDetails]);

    const navigation = (pageName: string) => {
        let toPage = '';
        if (pageName === 'basic_details') {
            toPage = 'dates';
        } else if (pageName === 'dates') {
            toPage = 'product-allocation';
        } else if (pageName === 'product-allocation') {
            toPage = 'ao-preview';
        }
        return toPage;
    };

    const isToShowNext = (pageName: string) => {
        let show = true;
        if (pageName === 'product-allocation') {
            if (headerDetails?.aoStatus === 'Draft' || headerDetails?.aoStatus === 'Pending_with_Forwarder') {
                show = true;
            } else {
                show = false;
            }
        } else {
            show = true;
        }
        return show;
    };

    const renderAoPreviewButton = () => {
        const buttonComp = (
            <>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        <Link
                            to={`/allocation_orders/${id}/product-allocation`}
                            className="app-btn app-btn-secondary footer-btn"
                            title="Previous"
                        >
                            <span className="button-text footer-button-text">Previous</span>
                        </Link>
                        {headerDetails?.aoStatus === 'Draft' ? (
                            <ShowForPermissions permission="create" type="ao">
                                {headerDetails?.dataSourceName != 'ERP' && (
                                    <a
                                        className={`app-btn app-btn-primary footer-btn ${
                                            !isTabActive(page) ? 'disabled' : ''
                                        }`}
                                        onClick={() => isTabActive(page) && confirmAO()}
                                    >
                                        <span className="button-text footer-button-text">Confirm and Submit AO</span>
                                    </a>
                                )}
                            </ShowForPermissions>
                        ) : (
                            profileType === 'forwarder' && (
                                <ShowForPermissions permission="approve" type="ao">
                                    {headerDetails?.aoStatus !== 'Cancelled' && (
                                        <a
                                            className="app-btn app-btn-primary footer-btn"
                                            onClick={() => setConfirmationView(true)}
                                        >
                                            <span className="button-text footer-button-text">
                                                Approve and Release to BLP
                                            </span>
                                        </a>
                                    )}
                                </ShowForPermissions>
                            )
                        )}
                    </div>
                </div>
                {ConfirmationView && <ConfirmationModal onCancel={onCancel} onConfirm={onOk} />}
            </>
        );
        return buttonComp;
    };

    return (
        <>
            {page === 'ao-preview' ? (
                renderAoPreviewButton()
            ) : isToShowNext(page) ? (
                <Link
                    to={isTabActive(page) ? `/allocation_orders/${id}/${navigation(page)}` : '#'}
                    className={`app-btn app-btn-primary footer-btn ${isTabActive(page) ? '' : 'disabled'}`}
                    title="Next"
                >
                    <span className="button-text footer-button-text">Next</span>
                </Link>
            ) : (
                <></>
            )}
        </>
    );
};

export default AllocationOrderNextButton;
