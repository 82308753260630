import moment from 'moment';
import { getDateDifference } from 'src/utils';

import '../../ListGrid';
import './sailing-schedule-detail-modal.scss';

// interface PropsDisplay {
//     data: any;
//     handleClose?: any;
// }

const combineDateTime = (date: string, time: string) => {
    return moment(date).format('YYYY-MM-DD') + ' ' + time;
};

const ScheduleDetailsModal: React.FC<any> = ({ data, handleClose }) => {
    const startDateTime = combineDateTime(data?.originEtdDate, data?.originEtdTime);
    const endDateTime = combineDateTime(data?.destEtaDate, data?.destEtaTime);
    const dateDifference = getDateDifference(startDateTime, endDateTime);

    return (
        <div className="app-modal">
            <div className="modal-content-holder additional-detail-modal large-popup air-flight-modal">
                <div className="modal-header">
                    <div className="title">Flight Schedule Details</div>
                    <button className="app-btn modal-close-btn" onClick={handleClose}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="sailing-detail">
                            <div className="des-progress-bar">
                                <div className="destination begin-journey">
                                    <span className="point1">ETD</span>
                                    <div className="date-time">
                                        {/* {moment(data?.originEtdDate).format('DD-MM-YYYY')},
                                        <span className="time-field">{data?.originEtdTime}</span> */}
                                        {moment(
                                            moment(data?.originEtdDate).format('YYYY-MM-DD') + ' ' + data?.originEtdTime
                                        ).format('DD-MM-YYYY')}
                                        ,
                                        <span className="time-field">
                                            {moment(
                                                moment(data?.originEtdDate).format('YYYY-MM-DD') +
                                                    ' ' +
                                                    data?.originEtdTime,
                                                'YYYY-MM-DD HH:mm:ss'
                                            ).format('HH:mm')}
                                        </span>
                                    </div>
                                    <span className="point2">{data?.originAirPortName}</span>
                                </div>
                                <div className="destination center-journey">
                                    <span className="center-point">
                                        {data?.bookingProposalAirSchedule.length}{' '}
                                        {`Stop${data?.bookingProposalAirSchedule.length > 1 ? 's' : ''}`}
                                    </span>
                                    <div className="point1">
                                        <div className="point1">
                                            {dateDifference?.days > 0 ? `${dateDifference?.days}d` : ''}
                                            {dateDifference?.hour > 0 ? (
                                                <span className="time-field">{` ${dateDifference?.hour}h`}</span>
                                            ) : (
                                                ''
                                            )}
                                            {dateDifference?.minute > 0 ? (
                                                <span className="time-field">{` ${dateDifference?.minute}m`}</span>
                                            ) : (
                                                ''
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="destination end-journey">
                                    <span className="point1">ETA</span>
                                    <div className="date-time">
                                        {/* {moment(data?.destEtaDate).format('DD-MM-YYYY')},
                                        <span className="time-field">{data?.destEtaTime}</span> */}
                                        {moment(
                                            moment(data?.destEtaDate).format('YYYY-MM-DD') + ' ' + data?.destEtaTime
                                        ).format('DD-MM-YYYY')}
                                        ,
                                        <span className="time-field">
                                            {moment(
                                                moment(data?.destEtaDate).format('YYYY-MM-DD') +
                                                    ' ' +
                                                    data?.destEtaTime,
                                                'YYYY-MM-DD HH:mm:ss'
                                            ).format('HH:mm')}
                                            {/* {fs?.destEtaTime} */}
                                        </span>
                                    </div>
                                    <span className="point2">{data?.destinationPort}</span>
                                </div>
                            </div>
                        </div>
                        <div className="stepProgress-wrapper">
                            <ul className="StepProgress">
                                <li className="StepProgress-item first-start">
                                    <div className="status-with-grid-holder">
                                        <div className="status-etd-date-wrapper">
                                            <div className="status-name">Origin - Leg 1</div>
                                            <div className="etd-date-holder">
                                                <div className="etd-date-start-holder">
                                                    <span>
                                                        <svg className="svg-icon progress-start-icon">
                                                            <use xlinkHref="#progressStartIcon"></use>
                                                        </svg>
                                                    </span>
                                                    <div className="ets-name">
                                                        ETD:
                                                        <span className="ets-date">
                                                            {moment(data?.originEtdDate).format('DD-MM-YYYY')},
                                                            {moment(data?.originEtdTime, 'HH:mm:ss').format('HH:mm')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="view-grid-container">
                                            <div className="cell list-more-view-items col-1">
                                                <div className="ship-logo">
                                                    <svg className="svg-icon anchor-icon">
                                                        <use href="#anchorIcon">
                                                            <title>Icon</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-heading">{data?.originAirPortName}</span>
                                                    </div>
                                                    {/* <div className="content-detail">
                                                        <span className="terminal-content">
                                                            {data?.originPortTerminal}
                                                        </span>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="cell list-more-view-items col-2">
                                                <div className="ship-logo">
                                                    <svg className="svg-icon ship-icon">
                                                        <use href="#transhipmentIcon">
                                                            <title>Icon</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-heading">{data?.carrier}</span>
                                                    </div>
                                                    <div className="content-detail">{data?.flightNo}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                {data?.bookingProposalAirSchedule?.map((res: any) => (
                                    <li className="StepProgress-item first-start half-done">
                                        <div className="status-with-grid-holder">
                                            <div className="status-etd-date-wrapper">
                                                <div className="status-name">Via</div>
                                                <div className="etd-date-holder">
                                                    <div className="etd-date-end-holder">
                                                        <span>
                                                            <svg className="svg-icon progress-end-icon">
                                                                <use xlinkHref="#progressEndIcon"></use>
                                                            </svg>
                                                        </span>
                                                        <div className="ets-name">
                                                            ETA:
                                                            <span className="ets-dateplannedETA">
                                                                {moment(res?.originEtdDate).format('DD-MM-YYYY')},
                                                                {moment(res?.originEtdTime, 'HH:mm:ss').format('HH:mm')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="etd-date-start-holder">
                                                        <span>
                                                            <svg className="svg-icon progress-start-icon">
                                                                <use xlinkHref="#progressStartIcon"></use>
                                                            </svg>
                                                        </span>
                                                        <div className="ets-name">
                                                            ETD:
                                                            <span className="ets-date">
                                                                {moment(res?.destEtaDate).format('DD-MM-YYYY')},
                                                                {moment(res?.destEtaTime, 'HH:mm:ss').format('HH:mm')}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="view-grid-container">
                                                <div className="cell list-more-view-items col-1">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon anchor-icon">
                                                            <use href="#anchorIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">
                                                                {res?.originAirPortName}
                                                            </span>
                                                        </div>
                                                        {/*<div className="content-detail">
                                                            <span className="terminal-content">
                                                                {res?.originAirPortName}
                                                            </span>
                                                        </div>*/}
                                                    </div>
                                                </div>
                                                <div className="cell list-more-view-items col-2">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon ship-icon">
                                                            <use href="#transhipmentIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">{res?.flightNo}</span>
                                                        </div>
                                                        <div className="content-detail"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                ))}
                                <li className="StepProgress-item current">
                                    <div className="status-with-grid-holder">
                                        <div className="status-etd-date-wrapper">
                                            <div className="status-name">
                                                Destination - {data?.bookingProposalAirSchedule?.length + 1}
                                            </div>
                                            <div className="etd-date-holder">
                                                <div className="etd-date-end-holder">
                                                    <span>
                                                        <svg className="svg-icon progress-end-icon">
                                                            <use xlinkHref="#progressEndIcon"></use>
                                                        </svg>
                                                    </span>
                                                    <div className="ets-name">
                                                        ETA:
                                                        <span className="ets-date">
                                                            {moment(data?.destEtaDate).format('DD-MM-YYYY')},
                                                            {moment(data?.destEtaTime, 'HH:mm:ss').format('HH:mm')}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="view-grid-container">
                                            <div className="cell list-more-view-items">
                                                <div className="ship-logo">
                                                    <svg className="svg-icon anchor-icon">
                                                        <use href="#anchorIcon">
                                                            <title>Icon</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div className="content-list-wrapper">
                                                    <div className="content-heading-holder">
                                                        <span className="tab-heading">{data?.destinationPort}</span>
                                                    </div>
                                                    {/* <div className="content-detail">
                                                        <span className="terminal-content">
                                                            {data?.destinationPortTerminal}
                                                        </span>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="cell list-more-view-items"></div>
                                            <div className="cell list-more-view-items"></div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="modal-footer"></div>
            </div>
        </div>
    );
};
export default ScheduleDetailsModal;
