import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchFavList,
    fetchFavListSuccess,
    fetchFavListError,
    addToFavListSuccess,
    removeFromFav,
    removeFromFavSuccess,
    removeFromFavError,
    addToFavList,
    addToFavListError,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';

import {
    getFavourites,
    addToFav,
    removeFromFavApi,
} from 'src/services/apis/lifecycleSearch/lifecyleSearchFavouritesApi';

function* getLifeCycleFavs({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getFavourites, payload);
        yield put({ type: fetchFavListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchFavListError, payload: error });
    }
}

function* addToFavs({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(addToFav, payload);
        yield put({ type: addToFavListSuccess, payload: response });
    } catch (error) {
        yield put({ type: addToFavListError, payload: error });
    }
}

function* removeFromFavs({ payload }: { payload: { id: string }; type: any }) {
    try {
        const res: AxiosResponse = yield call(removeFromFavApi, payload);
        yield put({ type: removeFromFavSuccess, payload: res });
    } catch (error) {
        yield put({ type: removeFromFavError, payload: error });
    }
}

function* lifecycleSearchFavouritesSaga() {
    yield takeEvery(fetchFavList, getLifeCycleFavs);
    yield takeEvery(addToFavList, addToFavs);
    yield takeEvery(removeFromFav, removeFromFavs);
}

export default lifecycleSearchFavouritesSaga;
