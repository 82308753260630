export const filterTableByColumn = (
    e: any,
    columnName: string,
    setDropDown: (value: boolean) => void,
    dropDownFlag: boolean,
    dispatchAction: any,
    valueFromRedux: any,
    setSearchedVal: (value: string) => void,
    recursive: boolean
) => {
    if (e) {
        e.stopPropagation();
    }
    if (!dropDownFlag && !recursive) {
        if (valueFromRedux) {
            // dispatchAction;
            setDropDown(true);
        }
    }
    setSearchedVal('');
};
