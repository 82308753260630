import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    savePoData,
    isLoadingFn,
    fetchFilterData,
    fetchStatusCount,
    cancelPo,
    discardPo,
} from '../../reducers/purchaseOrder/poListReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import {
    getPoData,
    PoResponse,
    getFilterValues,
    getStatusCount,
    cancelPO,
    discardPO,
} from '../../../services/apis/purchaseOrder/poListApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const purchaseOrders: AxiosResponse<PoResponse> = yield call(getPoData, payload);
        payload['purchaseOrders'] = purchaseOrders;
        yield put({ type: savePoData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getfilterData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const filterData: AxiosResponse<any[]> = yield call(getFilterValues, payload);
        payload['filterData'] = filterData;
        yield put({ type: fetchFilterData, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* getStatusData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const count: AxiosResponse<any[]> = yield call(getStatusCount, payload);
        payload['count'] = count;
        yield put({ type: fetchStatusCount, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* cancelPurchaseOrder({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(cancelPO, payload);
        payload['success'] = true;
        yield put({ type: cancelPo, payload: payload });
    } catch (error: any) {
        payload['success'] = false;
        payload['error'] = error?.data?.data || 'Something went wrong';
        yield put({ type: cancelPo, payload });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* discardPurchaseOrder({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(discardPO, payload);
        payload['success'] = true;
        yield put({ type: discardPo, payload: payload });
    } catch (error: any) {
        payload['success'] = false;
        payload['error'] = error?.data?.data || 'Something went wrong';
        yield put({ type: discardPo, payload });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* poSaga() {
    yield takeEvery('purchaseOrderList/fetchPoData', getListData);
    yield takeEvery('purchaseOrderList/fetchFilterData', getfilterData);
    yield takeEvery('purchaseOrderList/fetchStatusCount', getStatusData);
    yield takeEvery('purchaseOrderList/cancelPo', cancelPurchaseOrder);
    yield takeEvery('purchaseOrderList/discardPo', discardPurchaseOrder);
}

// Export the saga (data-saga)
export default poSaga;
