import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import BookingAvailableGrid from '../BookingAvailableGrid';
// import BookingAddedGrid from '../BookingAddedGrid';
import BookingHeaderSidebar from '../BookingHeaderSidebar';
import '../../BookingProposal/POSelection.scss';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSelectedSailing } from 'src/store/reducers/aobookingProposal/sailingReducer';
import FeedbackModal from 'src/components/common/FeedbackModal';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { updateAutoApprovalFailedViewStatus } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
/* eslint no-var: off */

// const navData = [
//     {
//         title: 'PO Selection',
//         selected: 1,
//     },
//     {
//         title: 'Sailing Schedules',
//         selected: 0,
//     },
//     {
//         title: 'Additional Details',
//         selected: 0,
//     },
// ];
// const summaryStats = [
//     {
//         title: 'Total CBM',
//         value: '-',
//     },
//     {
//         title: 'Total Kgs',
//         value: '-',
//     },
// ];
const Index: React.FC = () => {
    const [showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showAutoDeniedModal, setShowAutoDeniedModal] = useState<null | boolean>(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);

    const { selectedSailing } = useSelector((state: RootState) => state.aosailingRed);
    const headerData = useSelector((state: RootState) => state.bpHeaderData) as any;
    const [isAdded, setisAdded] = useState(false);
    const { id } = useParams();
    const { profileType } = useUserProfile();
    const dispatch = useDispatch();

    // const navigate = useNavigate() as any;

    // useEffect(() => {
    //     if (headerData?.tabValues?.po) {
    //         return navigate(`/Aobooking_list/${id}/SailingSelectionDetails`);
    //     }
    //     if (headerData?.tabValues?.sailing) {
    //         return navigate(`/Aobooking_list/${id}/additional-details`);
    //     }
    //     if (headerData?.tabValues?.additionalDetailsForForwarder || headerData?.tabValues?.additionalDetails) {
    //         return navigate(`/Aobooking_list/${id}/container-allocation`);
    //     }
    //     if (headerData?.tabValues?.containerDetails) {
    //         return navigate(`/Aobooking_list/${id}/attachments`);
    //     }
    //     if (headerData?.tabValues?.attachments) {
    //         return navigate(`/Aobooking_list/${id}/attachments`);
    //     }
    // }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(fetchSelectedSailing(id));
        }, 250);
        return () => clearTimeout(timer);
    }, [id]);
    /* eslint-disable no-undefined */

    useEffect(() => {
        if (profileType === 'forwarder' && !headerData.isLoading) {
            setShowAutoDeniedModal(headerData?.autoApprovalFailedModalForwarder);
        } else if (profileType === 'client' && !headerData.isLoading) {
            setShowAutoDeniedModal(headerData?.autoApprovalFailedModalClient);
        }
    }, [headerData, profileType]);

    const handleClosAutoDeniedModal = () => {
        if (id) {
            updateAutoApprovalFailedViewStatus({ bpId: id, profileType });
            setShowAutoDeniedModal(false);
        }
    };
    const navData = [
        {
            title: 'AO Selection',
            selected: 1,
            path: id ? `/Aobooking_list/${id}/AoSelection` : '/Aobooking_list/AoSelection',
            disabled: isAdded,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/Aobooking_list/${id}/SailingSelectionDetails`,
            disabled: !isAdded,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/Aobooking_list/${id}/additional-details`,
            disabled:
                selectedSailing !== undefined &&
                (selectedSailing as any)['sailingScheduleLists'] !== undefined &&
                (selectedSailing as any)['sailingScheduleLists'][0]?.contractType &&
                isAdded
                    ? false
                    : true,
        },
    ];

    if (
        headerData.bpStatus === 'Ready_for_Booking' ||
        headerData.bpStatus === 'Draft_Booking' ||
        headerData.bpStatus === 'Booked'
    ) {
        navData.push(
            {
                title: 'Container Allocation',
                selected: 0,
                path: `/Aobooking_list/${id}/container-allocation`,
                disabled: false,
            }

            // {
            //     title: 'Attachments',
            //     selected: 0,
            //     path: `/Aobooking_list/${id}/attachments`,
            //     disabled: false,
            // }
        );
    }
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder booking-proposal-header large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={id ? 'Booking Proposal Details' : 'New AO Booking Proposal'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                    isAdded={isAdded}
                ></BookingProposalHeader>
                <BookingProcessTab navData={navData} />
            </div>
            {/* DATA GRID SECTION */}
            <BookingAvailableGrid
                setisAdded={setisAdded}
                showBookingCancelModal={showBookingCancelModal}
                setShowBookingCancelModal={setShowBookingCancelModal}
            />
            {showBookingSideBar && (
                <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
            )}
            {showAutoDeniedModal && !headerData.isLoading && (
                <FeedbackModal
                    title="Auto Approval Denied"
                    description={
                        headerData?.autoApprovalFailureReason ||
                        "ETD for this Booking Proposal is not within the start and end ship window of one or more POs in this Booking Proposal. Booking Proposal requires client's approval to continue with booking"
                    }
                    handleClose={handleClosAutoDeniedModal}
                    okText="Okay"
                />
            )}
        </div>
    );
};
export default Index;
