import React, { useEffect, useState } from 'react';
import '../../BookingProposal/SelectedSailingSchelude/selected-sailing-schelude.scss';
// import UnavailableModal from './UnavailableModel';
// import SailingScheduleViewModal from './SailingScheduleViewModal';
import SailingDetailModal from '../BookingSailingSchelude/SailingScheduleDetailModal';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useUserProfile } from '../../../hooks/useUserProfile';
import { useLocation, useNavigate } from 'react-router-dom';
// import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import { Position, success } from '../../../utils/toast';
// import { fetchAddedPoData } from '../../../store/reducers/aobookingProposal/addedPoReducer';
import {
    getSelectedSchedules,
    // listExistingCall,
    // sailingUpdated,
} from 'src/store/reducers/bookingProposalAir/addSchedule';
import FlightSchelude from '../BookingSailingSchelude/ApprovalFlightSchelude';
import ClientView from '../BookingSailingSchelude/ClientView';
import { getDateDifference } from 'src/utils';
// import { downloadSingle } from 'src/store/reducers/bookingProposal/attachmentsReducer';
import { downloadFlightScheduleSell } from 'src/services/apis/bookingProposalAir/flightScheduleApi';
import RemoveSchedule from './RemoveSchedule';
import {
    selectFlightScheduleError,
    selectFlightScheduleIsError,
    selectFlightScheduleSuccess,
    updateSellQuote,
    clearFailure,
} from 'src/store/reducers/bookingProposalAir/flightScheduleReducer';
import { useAppSelector } from 'src/hooks';
import ShowForPermissions from 'src/ShowForPermissions';
// import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
/* eslint no-var: off */
interface Props {
    data: any;
    id: any;
    setselectedView: any;
    selectedView: any;
    setselectedContract: any;
    bpStatus: any;
    selectedFlightSchedule?: any;
    setSelectedFlightSchedule?: any;
    headerData?: any;
}
const Index: React.FC<Props> = ({
    data,
    id,
    bpStatus,
    selectedFlightSchedule,
    setSelectedFlightSchedule,
    headerData,
}) => {
    // var statusNotification = false;
    const newData = data && data[0];
    const { profileType } = useUserProfile();
    const [_displayModal, setdisplayModal] = useState(false);
    const [viewModal, setviewModal] = useState(false);
    const [Data, setData] = useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { selectedSchedules } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    useEffect(() => {
        localStorage.removeItem('changeVesselao');
        if (newData?.gateInDateExpired && !location.pathname.includes('SailingSelectionDetails')) {
            setdisplayModal(true);
        } else {
            setdisplayModal(false);
        }
        localStorage.setItem('selectedShipping', newData?.shippingLineCode);
    }, [newData]);
    useEffect(() => {
        dispatch(getSelectedSchedules(id));
    }, []);
    const getActiveDisabledStatus = (isSelected: any) => {
        if (
            headerData.bpStatus === 'Ready_for_Booking' ||
            headerData.bpStatus === 'Booked' ||
            headerData.bpStatus === 'Draft_Booking'
        ) {
            // if (isSelected) {
            return 'active';
            // } else {
            //     return 'disabled';
            // }
        }
    };
    const combineDateTime = (date: string, time: string) => {
        return moment(date).format('YYYY-MM-DD') + ' ' + time;
    };
    const [selectedToDeleteId, setSelectedToDeleteId] = useState<string | null>(null);
    function handleCloseDeleteModal(updateList = false) {
        if (updateList) {
            dispatch(getSelectedSchedules(id));
        }
        setSelectedToDeleteId(null);
    }
    const isError = useAppSelector(selectFlightScheduleIsError);
    const flightScheduleConfirmationSuccess = useAppSelector(selectFlightScheduleSuccess);
    const flightScheduleConfirmationError = useAppSelector(selectFlightScheduleError);
    useEffect(() => {
        if (isError) {
            // error(flightScheduleConfirmationError?.data?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (
            flightScheduleConfirmationSuccess?.status === 200 ||
            flightScheduleConfirmationSuccess?.status === 201
        ) {
            if (flightScheduleConfirmationSuccess?.data?.mode === 'undoBuyQuote') {
                success('Schedule restored successfully', Position.TOP_RIGHT);
            }
            dispatch(clearFailure([]));
            dispatch(getSelectedSchedules(id));
        }
    }, [isError, flightScheduleConfirmationSuccess, flightScheduleConfirmationError]);

    useEffect(() => {
        if (selectedSchedules?.length < 1) {
            navigate(`/air_booking_list/${id}/FlightSelectionDetails`);
        }
    }, [selectedSchedules]);

    return (
        <>
            {(() => {
                if (profileType === 'agent') {
                    return (
                        <div className="selection-schelude-wrapper air-flight-schelude">
                            <div className="selection-schelude-header-wrapper">
                                <div className="selection-heading-holder">
                                    <div>
                                        <h2 className="selection-heading">Flight Schedules</h2>
                                        {/* <p className="selection-content">
                                            Add Sell Quote for the schedules for client approval
                                        </p> */}
                                    </div>
                                </div>
                                {/* <ShowForPermissions type="bp" permission="create">
                                    {(bpStatus === 'Rejected_by_Forwarder' ||
                                        bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                        bpStatus === 'Draft_Booking_Proposal') &&
                                        profileType === 'agent' && (
                                            <button
                                                className="app-btn export-btn icon-button  app-btn-secondary"
                                                onClick={() => {
                                                    navigate(`/air_booking_list/${id}/flight_schedule`, {
                                                        state: { bpNumber: headerData?.bpNumber },
                                                    });
                                                }}
                                                title="Add Schedule"
                                            >
                                                <svg className="svg-icon add-btn-icon">
                                                    <use xlinkHref="#plusIcon">
                                                        <title>Add</title>
                                                    </use>
                                                </svg>
                                                <span className="button-text">Add Schedule</span>
                                            </button>
                                        )}
                                </ShowForPermissions> */}
                            </div>
                            <div className="view-list-grid">
                                {selectedSchedules?.map((res: any) => {
                                    const startDateTime = combineDateTime(res?.originEtdDate, res?.originEtdTime);
                                    const endDateTime = combineDateTime(res?.destEtaDate, res?.destEtaTime);
                                    const dateDifference = getDateDifference(startDateTime, endDateTime);
                                    return (
                                        <div className="list-grid" style={{ gridTemplateColumns: 'repeat(7, 1fr)' }}>
                                            {/* active and disabled state are the classes */}
                                            <div
                                                className={`grid-child-item col-name ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="ship-logo">
                                                        <svg className="svg-icon aeroplane-icon">
                                                            <use href="#aeroplaneIcon">
                                                                <title>Icon</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-heading">{res?.carrier}</span>
                                                        </div>
                                                        <div className="content-detail">{res?.flightNo}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-destination ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data">
                                                    <div className="des-progress-bar">
                                                        <div className="destination begin-journey">
                                                            <span className="point1">ETD</span>
                                                            <div className="date-time">
                                                                {res?.isManuallyEntered
                                                                    ? moment(res?.originEtdDate).format('DD-MM-YYYY')
                                                                    : moment(
                                                                          moment(res?.originEtdDate).format(
                                                                              'YYYY-MM-DD'
                                                                          ) +
                                                                              ' ' +
                                                                              res?.originEtdTime
                                                                      ).format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {moment(res?.originEtdTime, 'HH:mm:ss').format(
                                                                        'HH:mm'
                                                                    )}
                                                                </span>
                                                            </div>
                                                            <span className="point2">{res?.originAirPortName}</span>
                                                        </div>
                                                        <div className="destination center-journey">
                                                            <span className="center-point">
                                                                {res?.bookingProposalAirSchedule?.length}
                                                                {res?.bookingProposalAirSchedule?.length > 1
                                                                    ? ' Stops'
                                                                    : ' Stop'}
                                                            </span>
                                                            <div className="point1">
                                                                {dateDifference?.days > 0
                                                                    ? `${dateDifference?.days}d`
                                                                    : ''}
                                                                {dateDifference?.hour > 0 ? (
                                                                    <span className="time-field">
                                                                        {` ${dateDifference?.hour}h`}
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                                {dateDifference?.minute > 0 ? (
                                                                    <span className="time-field">
                                                                        {` ${dateDifference?.minute}m`}
                                                                    </span>
                                                                ) : (
                                                                    ''
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="destination end-journey">
                                                            <span className="point1">ETA</span>
                                                            <div className="date-time">
                                                                {res?.isManuallyEntered
                                                                    ? moment(res?.destEtaDate).format('DD-MM-YYYY')
                                                                    : moment(
                                                                          moment(res?.destEtaDate).format(
                                                                              'YYYY-MM-DD'
                                                                          ) +
                                                                              ' ' +
                                                                              res?.destEtaTime
                                                                      ).format('DD-MM-YYYY')}
                                                                ,
                                                                <span className="time-field">
                                                                    {moment(
                                                                        moment(res?.destEtaDate).format('YYYY-MM-DD') +
                                                                            ' ' +
                                                                            res?.destEtaTime,
                                                                        'YYYY-MM-DD HH:mm:ss'
                                                                    ).format('HH:mm')}
                                                                </span>
                                                            </div>
                                                            <span className="point2">{res?.destinationPort}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-direction ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Payment Terms</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            {res?.paymentTermCode ? (
                                                                <span className="sub-content">
                                                                    {res?.paymentTermCode}
                                                                </span>
                                                            ) : (
                                                                '--'
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-direction ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Buy Cost</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            {res?.buyCost ? (
                                                                <span className="sub-content">
                                                                    {res?.buyCost + ' ' + res?.buyCurrency}
                                                                </span>
                                                            ) : (
                                                                '--'
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-direction ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">UOM</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            {res?.buyUomCode ? (
                                                                <span className="sub-content">{res?.buyUomCode}</span>
                                                            ) : (
                                                                '--'
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className={`grid-child-item col-direction ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                            >
                                                <div className="grid-child-data list-view-items">
                                                    <div className="content-list-wrapper">
                                                        <div className="content-heading-holder">
                                                            <span className="tab-lite-heading">Buy Quote</span>
                                                        </div>
                                                        <div className="content-detail">
                                                            {res?.buyQuoteName ? (
                                                                <a
                                                                    onClick={() =>
                                                                        downloadFlightScheduleSell(
                                                                            res?.buySavedFileId,
                                                                            'SELL',
                                                                            res?.baId,
                                                                            res?.buyQuoteName
                                                                        )
                                                                    }
                                                                    className="buy-link"
                                                                >
                                                                    {res?.buyQuoteName}
                                                                </a>
                                                            ) : (
                                                                '--'
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className={`grid-child-item col-action ${getActiveDisabledStatus(
                                                    res?.isSelected
                                                )}`}
                                                style={{ textAlign: 'center', alignItems: 'center' }}
                                            >
                                                <ShowForPermissions type="bp" permission="create">
                                                    {res?.isRemoved || res?.isRemovedByAgent ? (
                                                        <>
                                                            <div className="item-count-status-holder">
                                                                <div className="grid-item-count-status status-ui error-status">
                                                                    <span className="status-text">
                                                                        {res?.isRemoved
                                                                            ? 'Removed By Forwarder'
                                                                            : 'Removed'}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            {(headerData.bpStatus === 'Rejected_by_Forwarder' ||
                                                                headerData.bpStatus ===
                                                                    'Rejected_by_Client_Agent_to_Action') &&
                                                                res?.isRemovedByAgent && (
                                                                    <a
                                                                        className="more-link app-btn-secondary app-btn action-btn"
                                                                        onClick={() => {
                                                                            dispatch(
                                                                                updateSellQuote({
                                                                                    airFreightId: res?.baId,
                                                                                    type: 'undo',
                                                                                    profile: profileType,
                                                                                    mode: 'undoBuyQuote',
                                                                                })
                                                                            );
                                                                        }}
                                                                        title="Undo"
                                                                    >
                                                                        <svg className="svg-icon reset-icon">
                                                                            <use xlinkHref="#resetIcon">
                                                                                <title>Reset</title>
                                                                            </use>
                                                                        </svg>
                                                                    </a>
                                                                )}
                                                        </>
                                                    ) : (
                                                        <>
                                                            {(bpStatus === 'Rejected_by_Forwarder' ||
                                                                bpStatus === 'Rejected_by_Client_Agent_to_Action' ||
                                                                bpStatus === 'Draft_Booking_Proposal') &&
                                                                profileType === 'agent' && (
                                                                    <>
                                                                        <a
                                                                            className="more-link app-btn-secondary app-btn action-btn"
                                                                            onClick={() =>
                                                                                navigate(
                                                                                    `/air_booking_list/${id}/flight_schedule`,
                                                                                    {
                                                                                        state: {
                                                                                            data: res,
                                                                                            edit: true,
                                                                                            bpNumber:
                                                                                                headerData?.bpNumber,
                                                                                        },
                                                                                    }
                                                                                )
                                                                            }
                                                                            title="Edit"
                                                                        >
                                                                            <svg className="svg-icon save-icon">
                                                                                <use xlinkHref="#editIcon">
                                                                                    <title>Edit</title>
                                                                                </use>
                                                                            </svg>
                                                                        </a>
                                                                        {/* <a
                                                                            className="more-link app-btn-secondary app-btn action-btn"
                                                                            href="javascript:void(0)"
                                                                            title="Remove"
                                                                            onClick={() =>
                                                                                setSelectedToDeleteId(res?.baId)
                                                                            }
                                                                        >
                                                                            <svg className="svg-icon eye-icon ">
                                                                                <use xlinkHref="#closecircleIcon">
                                                                                    <title>Remove</title>
                                                                                </use>
                                                                            </svg>
                                                                        </a> */}
                                                                    </>
                                                                )}
                                                        </>
                                                    )}
                                                </ShowForPermissions>
                                                {(headerData.bpStatus === 'Ready_for_Booking' ||
                                                    headerData.bpStatus === 'Booked' ||
                                                    headerData.bpStatus === 'Draft_Booking') &&
                                                    res?.isSelected && (
                                                        <div className="item-count-status-holder">
                                                            <div className="grid-item-count-status status-ui success-status">
                                                                <span className="status-text">Selected</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                {(headerData.bpStatus === 'Ready_for_Booking' ||
                                                    headerData.bpStatus === 'Draft_Booking') && (
                                                    <a
                                                        className="more-link app-btn-secondary app-btn action-btn"
                                                        onClick={() =>
                                                            navigate(`/air_booking_list/${id}/flight_schedule`, {
                                                                state: {
                                                                    data: res,
                                                                    edit: true,
                                                                    bpNumber: headerData?.bpNumber,
                                                                },
                                                            })
                                                        }
                                                        title="Edit"
                                                    >
                                                        <svg className="svg-icon save-icon">
                                                            <use xlinkHref="#editIcon">
                                                                <title>Edit</title>
                                                            </use>
                                                        </svg>
                                                    </a>
                                                )}
                                                <a
                                                    className="more-link app-btn-secondary app-btn action-btn"
                                                    href="javascript:void(0)"
                                                    onClick={() => {
                                                        setData(res);
                                                        setviewModal(true);
                                                    }}
                                                    title="View"
                                                >
                                                    <svg className="svg-icon eye-icon ">
                                                        <use xlinkHref="#eyeIcon">
                                                            <title>View</title>
                                                        </use>
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                            {/* added as part of sprint16-ui new field of destination agent */}
                            {/* <div className="destination-agent-field">
                                <div className="selection-heading">Destination Agent</div>
                                <div className="layout-holder one-column">
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">
                                            Destination Agent
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <SearchableSelectBox
                                                data={dummydata}
                                                placeholder="Enter Destination Agent to select"
                                                formik={{
                                                    setFieldValue: '',
                                                    setFieldError: '',
                                                    values: 'Leanne Graham',
                                                }}
                                                fieldName="header"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">Destination Agent</h6>
                                    <div className="details-box-content">Remenka Priscilli</div>
                                </div>
                            </div> */}
                        </div>
                    );
                } else if (profileType === 'forwarder') {
                    return (
                        <FlightSchelude
                            data={selectedSchedules}
                            setData={setData}
                            setviewModal={setviewModal}
                            headerData={headerData}
                        />
                    );
                } else if (profileType === 'client') {
                    return (
                        <ClientView
                            data={selectedSchedules}
                            setData={setData}
                            setviewModal={setviewModal}
                            selectedFlightSchedule={selectedFlightSchedule}
                            setSelectedFlightSchedule={setSelectedFlightSchedule}
                            headerData={headerData}
                        />
                    );
                }
            })()}
            {viewModal && <SailingDetailModal data={Data} displayFunc={setviewModal} />}
            {selectedToDeleteId && <RemoveSchedule handleClose={handleCloseDeleteModal} id={selectedToDeleteId} />}
        </>
    );
};
export default Index;
