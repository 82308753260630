import React from 'react';
import { ShipmentTabProps } from '.';
import { HelmetProvider } from 'react-helmet-async';
import GridLayout from 'src/components/KendoGridJquery/GridLayoutWithLocalData';
import { shipmentGridDetails } from './columns';
import moment from 'moment';
import { useTimeout } from 'react-use';

const RoutingLeg: React.FC<ShipmentTabProps> = ({ data }) => {
    const [isReady] = useTimeout(100);

    const getData = () => {
        return data?.transportLegCollection?.transportLeg?.map((res) => {
            return {
                legOrder: res?.legOrder,
                legType: res?.legType,
                legStatus: res?.bookingStatus?.description,
                transportMode: res?.transportMode,
                vesselName: res?.vesselName,
                voyageFlightNo: res?.voyageFlightNo,
                portOfLoading: res?.portOfLoading?.code,
                portOfDischarge: res?.portOfDischarge?.code,
                etd: res?.estimatedDeparture ? moment(res?.estimatedDeparture).format('DD-MM-YYYY') : '',
                atd: res?.actualDeparture ? moment(res?.actualDeparture).format('DD-MM-YYYY') : '',
                eta: res?.estimatedArrival ? moment(res?.estimatedArrival).format('DD-MM-YYYY') : '',
                ata: res?.actualArrival ? moment(res?.actualArrival).format('DD-MM-YYYY') : '',
            };
        });
    };
    return (
        <div className="main-wrapper container insight-agent-list">
            <div className="main-content-holder">
                {isReady() ? (
                    <HelmetProvider>
                        <div className="insight-agent-list-data-trend">
                            <div className="container-fluid shipment-details">
                                <div className="k-kendo-row one-column">
                                    <div className="k-kendo-col-2 custom-kendo-grid">
                                        <div id="grid"></div>
                                    </div>
                                </div>
                            </div>

                            <GridLayout
                                data={getData()}
                                columns={shipmentGridDetails['routing-legs'].columns}
                                fileName={shipmentGridDetails['routing-legs'].fileName}
                                fields={shipmentGridDetails['routing-legs'].fields}
                            />
                        </div>
                    </HelmetProvider>
                ) : null}
            </div>
        </div>
    );
};

export default RoutingLeg;
