import { createSlice } from '@reduxjs/toolkit';

export const purchaseOrder = createSlice({
    name: 'purchase',
    initialState: {
        isLoading: true,
        orderType: [],
        shipping: [],
        fcl: [],
        freight: [],
        incoterm: [],
        payment: [],
        orgnPort: [],
        destinationPort: [],
        destinationAddress: [],
        agents: [],
        data: [],
        basicDetails: [],
        requiredSuccess: false,
        addtionalSuccess: false,
        successPo: false,
        poName: '',
        addressVal: [],
        buyerVal: [],
        confirmedLines: [],
        submitText: '',
        submitToForwarder: false,
        destinationAgents: [],
        getAgentLoading: true,
    },
    reducers: {
        getDatas: (_state, _action) => {
            // console.log(action.payload);
        },
        getDatasBlp: (_state, _action) => {
            // console.log(action.payload);
        },
        saveAllData: (state, action) => {
            state.data = action.payload;
        },
        fetchBasicDetails: (_state, _action) => {
            // console.log(state);
        },
        saveBasicDetails: (state, action) => {
            state.basicDetails = action.payload;
        },
        getAgent: (_state, _action) => {
            // console.log(action);
        },
        getAgentLoadFn: (state, action) => {
            state.getAgentLoading = action.payload;
        },
        saveAgentData: (state, action) => {
            state.agents = action.payload;
        },
        getDestAgent: (_state, _action) => {
            // console.log(action);
        },
        saveDestAgentData: (state, action) => {
            state.destinationAgents = action.payload;
        },
        createPo: (_state, _action) => {
            // console.log(state);
        },
        additionalPo: (_state, _action) => {
            // console.log(action);
        },
        reqSuccess: (state, action) => {
            state.requiredSuccess = action.payload;
        },
        adSuccess: (state, action) => {
            state.addtionalSuccess = action.payload;
        },
        submitPo: (_state, _action) => {
            // console.log(action);
        },
        submitSuccess: (state, action) => {
            state.successPo = action.payload;
        },
        savedPo: (state, action) => {
            state.poName = action.payload;
        },
        getAddress: (_state, _action) => {
            // console.log(action);
        },
        saveAddress: (state, action) => {
            state.addressVal = action.payload;
        },
        getBuyer: (_state, _action) => {
            // console.log(action);
        },
        saveBuyer: (state, action) => {
            state.buyerVal = action.payload;
        },
        fetchConfirmedLines: (_state, _action) => {
            // console.log(action);
        },
        saveConfirmedLines: (state, action) => {
            state.confirmedLines = action.payload;
        },
        submitForwardercontainer: (_state, _action) => {
            // console.log(action);
        },
        submitToSuccess: (state, action) => {
            state.submitToForwarder = action.payload.status;
            state.submitText = action.payload.type;
        },
    },
});

export const {
    getDatas,
    getDatasBlp,
    saveAllData,
    saveBasicDetails,
    fetchBasicDetails,
    getAgent,
    saveAgentData,
    createPo,
    additionalPo,
    reqSuccess,
    adSuccess,
    submitPo,
    submitSuccess,
    savedPo,
    saveAddress,
    getAddress,
    getBuyer,
    saveBuyer,
    fetchConfirmedLines,
    saveConfirmedLines,
    submitForwardercontainer,
    submitToSuccess,
    getDestAgent,
    saveDestAgentData,
    getAgentLoadFn,
} = purchaseOrder.actions;
export default purchaseOrder.reducer;
