import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get } from '../../HttpMethods';

const validate = (payload: any) => {
    if (payload) {
        return payload;
    } else {
        return '';
    }
};
const currentEnv = () => {
    if (process.env.REACT_APP_BASE_URL?.includes('test')) {
        return 'test';
    } else if (process.env.REACT_APP_BASE_URL?.includes('dev')) {
        return 'dev';
    } else if (process.env.REACT_APP_BASE_URL?.includes('qa')) {
        return 'qa';
    } else {
        return 'production';
    }
};
export const getBookingsAndPosStats = async (payload: {
    type?: string;
    searchValue?: string;
    mode?: string;
    destinationType?: any;
    selectedType?: any;
    selectedStatus?: any;
    selectedCategory?: any;
    selectedMode?: any;
}) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('env', currentEnv());
        if (token) {
            const res = await fetch(
                `${process.env.REACT_APP_TRACKING_BASE_URL}/OrderTracking/bookings-pos/stats?searchValue=${validate(
                    payload?.searchValue
                )}&mode=${validate(payload?.mode)}&destinationType=${validate(
                    payload?.destinationType
                )}&selectedType=${validate(payload?.selectedType)}&selectedCategory=${validate(
                    payload?.selectedCategory
                )}&selectedMode=${validate(payload?.selectedMode)}&selectedStatus=${validate(payload?.selectedStatus)}`,
                {
                    method: 'GET',
                    headers: myHeaders,
                    redirect: 'follow',
                }
            );

            let data = {
                success: true,
                posAtOriginCountry: [],
                bookingsAtOriginCountry: [],
                bookingsAtOriginPort: [],
                bookingsIntransit: [],
                bookingsAtDestinationPort: [],
            };

            try {
                data = await res.json();
            } catch (e) {}

            // get PO by country data from CIX
            const cixPO = await get(`/cix/scr/trk/ord/bycountry`, null, externalBaseUrl);
            if (cixPO.data) {
                const dataArray: any[] = cixPO.data;
                const posAtOriginCountry: any = [];
                dataArray.forEach((d) => {
                    if (d.CN_LatLong) {
                        const obj = {
                            countryName: d.CN_CountryName,
                            countryCode: d.CN_CountryCode,
                            countryCoordinates: [
                                Number(d.CN_LatLong.split(',')[0]),
                                Number(d.CN_LatLong.split(',')[1]),
                            ],
                            poCount: d.ORD,
                            poStatus: d.PO_Status,
                        };
                        posAtOriginCountry.push(obj);
                    }
                });
                data.posAtOriginCountry = posAtOriginCountry;
            }

            return data;
        }
    } catch (err) {
        console.log(err);

        return {
            success: true,
            posAtOriginCountry: [],
            bookingsAtOriginCountry: [],
            bookingsAtOriginPort: [],
            bookingsIntransit: [],
            bookingsAtDestinationPort: [],
        };
    }
};

export const getMapPurchaseOrders = async (payload: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('env', currentEnv());

        const res = await fetch(
            `${process.env.REACT_APP_TRACKING_BASE_URL}/OrderTracking/pos?countryCode=${validate(
                payload?.countryCode
            )}&type=po&searchValue=${validate(payload?.searchValue)}&mode=${validate(
                payload?.mode
            )}&destinationType=${validate(payload?.destinationType)}&selectedType=${validate(
                payload?.selectedType
            )}&selectedCategory=${validate(payload?.selectedCategory)}&selectedMode=${validate(
                payload?.selectedMode
            )}&selectedStatus=${validate(payload?.selectedStatus)}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        );
        const data = await res.json();
        return data?.bookings;
    } catch (err) {
        return console.error(err);
    }
};
export const getMapBookingsInOrigin = async (payload: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('env', currentEnv());

        const res = await fetch(
            `${process.env.REACT_APP_TRACKING_BASE_URL}/OrderTracking/bookings/origin-country?countryCode=${validate(
                payload?.countryCode
            )}&type=bp&searchValue=${validate(payload?.searchValue)}&mode=${validate(
                payload?.mode
            )}&destinationType=${validate(payload?.destinationType)}&selectedType=${validate(
                payload?.selectedType
            )}&selectedCategory=${validate(payload?.selectedCategory)}&selectedMode=${validate(
                payload?.selectedMode
            )}&selectedStatus=${validate(payload?.selectedStatus)}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        );
        const data = await res.json();
        return data?.bookings;
    } catch (err) {
        return console.error(err);
    }
};
export const getMapBookingsInTransit = async (payload: any) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('env', currentEnv());

        const res = await fetch(
            `${
                process.env.REACT_APP_TRACKING_BASE_URL
            }/OrderTracking/listing/booking-in-transit?type=bp&searchValue=${validate(
                payload?.searchValue
            )}&mode=${validate(payload?.mode)}&destinationType=${validate(
                payload?.destinationType
            )}&selectedType=${validate(payload?.selectedType)}&selectedCategory=${validate(
                payload?.selectedCategory
            )}&selectedMode=${validate(payload?.selectedMode)}&selectedStatus=${validate(payload?.selectedStatus)}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        );
        const data = await res.json();
        return data?.bookings;
    } catch (err) {
        return console.error(err);
    }
};
export const getMapBookingsAtPort = async (payload: {
    type?: string;
    searchValue?: string;
    portSource: string;
    portCode: string;
    mode?: string;
    destinationType?: any;
    selectedType?: any;
    selectedStatus?: any;
    selectedCategory?: any;
    selectedMode?: any;
}) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('env', currentEnv());

        const res = await fetch(
            `${process.env.REACT_APP_TRACKING_BASE_URL}/OrderTracking/bookings/at-port?portSource=${
                payload?.portSource ?? ''
            }&portCode=${payload?.portCode ?? ''}&type=bp&searchValue=${validate(payload?.searchValue)}&mode=${validate(
                payload?.mode
            )}&destinationType=${validate(payload?.destinationType)}&selectedType=${validate(
                payload?.selectedType
            )}&selectedCategory=${validate(payload?.selectedCategory)}&selectedMode=${validate(
                payload?.selectedMode
            )}&selectedStatus=${validate(payload?.selectedStatus)}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        );
        const data = await res.json();
        return data?.bookings;
    } catch (err) {
        return console.error(err);
    }
};
