/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface AddPoProp {
    status: number;
    message: string;
    info: any;
    data: any;
}

interface AddPoState {
    isLoading: boolean;
    poDetails?: AddPoProp;
    clientDetails?: any;
    vendorDetails?: any;
    headerDetails?: any;
    poDetailsSuccess: any;
    poDetailsError: any;
    isError?: boolean;
    id?: number;
    cancelledReason?: any;
    attchments: any[];
    fileUploadStatus: any;
    notesUnread: boolean;
}

const initialState: AddPoState = {
    isLoading: false,
    poDetails: undefined,
    clientDetails: undefined,
    vendorDetails: undefined,
    headerDetails: undefined,
    poDetailsSuccess: undefined,
    poDetailsError: undefined,
    isError: false,
    id: undefined,
    cancelledReason: undefined,
    attchments: [],
    fileUploadStatus: undefined,
    notesUnread: false,
};

export const addPoSlice = createSlice({
    name: 'addPo',
    initialState,
    reducers: {
        fetchClient: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchClientSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.clientDetails = action.payload;
        },
        fetchPoAttachment(state, action) {
            state.attchments = action.payload;
        },
        uploadCixDocs: (state, action) => {
            state.fileUploadStatus = action.payload;
        },
        fetchClientFailed(state, _action) {
            state.isLoading = false;
            // state.isError = true;
            state.poDetailsSuccess = {};
            // state.poDetailsError = action.payload;
        },
        fetchVendor: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        fetchVendorSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.vendorDetails = action.payload;
        },
        fetchVendorFailed(state, _action) {
            state.isLoading = false;
            // state.isError = true;
            state.poDetailsSuccess = {};
            // state.poDetailsError = action.payload;
            state.vendorDetails = [];
        },
        fetchHeader: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
            // state.headerDetails = {};
        },
        resetfetchHeader: (state, _action) => {
            state.isLoading = false;
            state.isError = false;
            state.headerDetails = {};
        },
        fetchHeaderSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.headerDetails = action.payload?.data?.info;
        },
        fetchHeaderFailed(state, action) {
            state.isLoading = false;
            state.poDetailsError = action.payload;
            state.headerDetails = {};
        },
        savePo: (state, action) => {
            state.isLoading = true;
            state.id = action.payload.id;
        },
        savePoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.poDetailsSuccess = action.payload;
        },
        savePoFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.poDetailsError = action.payload;
        },
        clearFailure: (state, _action) => {
            state.poDetailsError = undefined;
            state.poDetailsSuccess = undefined;
            state.isError = false;
        },
        clearHeader: (state, _action) => {
            state.headerDetails = {};
        },
        fetchCancelReason: (state, action) => {
            state.cancelledReason = action.payload?.cancelledReason;
        },
        updateNotesRead: (state, action) => {
            state.notesUnread = action.payload;
        },
    },
});

export const {
    fetchClient,
    fetchClientSuccess,
    fetchClientFailed,
    fetchVendor,
    fetchVendorSuccess,
    fetchVendorFailed,
    fetchHeader,
    resetfetchHeader,
    fetchHeaderSuccess,
    fetchHeaderFailed,
    savePo,
    savePoSuccess,
    savePoFailed,
    clearFailure,
    clearHeader,
    fetchCancelReason,
    fetchPoAttachment,
    uploadCixDocs,
    updateNotesRead,
} = addPoSlice.actions;

export const selectAddPoLoading = (state: RootState) => state.addPoData.isLoading;
export const selectAddPo = (state: RootState) => state.addPoData.poDetails;
export const selectHeader = (state: RootState) => state.addPoData.headerDetails;
export const selectAddPoSuccess = (state: RootState) => state.addPoData.poDetailsSuccess;
export const selectAddPoIsError = (state: RootState) => state.addPoData.isError;
export const selectAddPoError = (state: RootState) => state.addPoData.poDetailsError;
export const selectClientDetails = (state: RootState) => state.addPoData.clientDetails;
export const selectVendorDetails = (state: RootState) => state.addPoData.vendorDetails;
export const selectPoAttachments = (state: RootState) => state.addPoData.attchments;
export const selectUploadCixDocs = (state: RootState) => state.addPoData.fileUploadStatus;
export const selectNotesUnread = (state: RootState) => state.addPoData.notesUnread;

export default addPoSlice.reducer;
