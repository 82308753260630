import React, { useEffect, useState } from 'react';
import AddDatesModal from './AddDatesModal';
import './purchase-dates.scss';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    selectDatesPo,
    fetchDatesPoList,
    selectDatesPoLoading,
} from '../../../store/reducers/purchaseOrder/datesPoReducer';
import moment from 'moment';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from '../../../ShowForPermissions';
import CancelModal from '../../../components/common/CancelModal';
import { selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
// import { selectProductPo } from 'src/store/reducers/purchaseOrder/productPoReducer';
import { InterfaceType } from 'src/utils/constants';
import ShowMandatoryFields from 'src/components/common/ShowMandatoryFields';
import BLPInboundPoTabs from '../BLPInboundPoTabs';
import BLPInboundPONextButton from '../BLPInboundPONextButton';
// import { useUserProfile } from '../../../hooks/useUserProfile';
const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    const datesPoData = useAppSelector(selectDatesPo);
    const isLoading = useAppSelector(selectDatesPoLoading);
    const headerData = useAppSelector(selectHeader);
    const multiDestination = datesPoData?.data?.info?.isMultiAddress ? true : false;
    // const products = useAppSelector(selectProductPo);
    const [viaIntegration, setViaIntegration] = useState(false);
    // const { profileType } = useUserProfile();

    useEffect(() => {
        dispatch(
            fetchDatesPoList({
                id,
            })
        );
    }, []);
    useEffect(() => {
        if (headerData) {
            setViaIntegration(headerData?.createdUser === InterfaceType.InterfacedPO);
        }
    }, [headerData]);

    // const condition =
    //     !datesPoData?.data?.info?.orderDate ||
    //     !datesPoData?.data?.info?.startShipWindowDate ||
    //     !datesPoData?.data?.info?.endShipWindowDate ||
    //     (!multiDestination && !datesPoData?.data?.info?.goodsReadyDate) ||
    //     (!multiDestination && !datesPoData?.data?.info?.projectedEtaDate);

    // const nextButtonStatus = () => {
    //     if (!viaIntegration) {
    //         return !datesPoData?.data?.info?.orderDate;
    //     }
    //     return condition ? true : false;
    // };

    const showMandatoryFieldsIcon = () => {
        if (!viaIntegration) {
            return false;
        }
        return headerData.datesTabComplete ? false : true;
    };

    return (
        <div className="main-wrapper container purchase-dates-wrapper">
            <div className="main-header-content-holder large-header">
                {/* <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/inbound">
                                Purchase Orders
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Add New PO
                            </a>
                        </li>
                    </ul>
                </div> */}
                <Breadcrumbs
                    firstName="Inbound PO"
                    firstUrl="/inbound"
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New Inbound PO' : 'Inbound PO Details'}
                    secondUrl={`/inbound/${id}/dates`}
                />
                <PurchaseOrderHeader id={id} />
                <BLPInboundPoTabs />
            </div>
            <div className="content-section main-wrapper agent-basic-detail-wrapper">
                {showMandatoryFieldsIcon() && (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missing. Please integrate again/ edit details to enter the mandatory fields
                            to proceed.
                        </div>
                    </div>
                )}
                {headerData.datesTabComplete && headerData?.validDate === false && (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">Invalid dates present .Please enter valid dates to proceed</div>
                    </div>
                )}
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>
                            <ShowForPermissions permission="edit" type="inbound">
                                {datesPoData?.data?.info?.orderDate && headerData?.poStatus === 'Draft' && (
                                    <button
                                        onClick={() => {
                                            setModalView(true);
                                        }}
                                        className="action-btn app-btn app-btn-secondary "
                                        title="Edit Dates"
                                    >
                                        <svg className="svg-icon edit-icon">
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="inbound">
                            <div className="btn-holder">
                                {!datesPoData?.data?.info?.orderDate && headerData?.poStatus === 'Draft' && (
                                    <button
                                        onClick={() => {
                                            setModalView(true);
                                        }}
                                        className="app-btn app-btn-secondary icon-button btn-bold"
                                        title="Add Dates"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Dates</span>
                                    </button>
                                )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Order Date<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!datesPoData?.data?.info?.orderDate}
                                name="Order Date"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {datesPoData?.data?.info?.orderDate
                                    ? moment(datesPoData?.data?.info?.orderDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        {headerData?.poStatus !== 'Draft' && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Created Date</h6>
                                <div className="details-box-content">
                                    {datesPoData?.data?.info?.createdDate
                                        ? moment(datesPoData?.data?.info?.createdDate).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                        )}
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Start Shipping Window<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!datesPoData?.data?.info?.startShipWindowDate}
                                name="Start Shipping Window"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {datesPoData?.data?.info?.startShipWindowDate
                                    ? moment(datesPoData?.data?.info?.startShipWindowDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                End Shipping Window<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!datesPoData?.data?.info?.endShipWindowDate}
                                name="End Shipping Window"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {datesPoData?.data?.info?.endShipWindowDate
                                    ? moment(datesPoData?.data?.info?.endShipWindowDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        {!multiDestination && (
                            <>
                                <div className="details-col box-content-holder">
                                    {/* <h6 className="details-box-heading">
                                        Goods Ready Date<span className="mandatory-field-text">*</span>
                                    </h6> */}
                                    <ShowMandatoryFields
                                        showMandatoryFieldsIcon={!datesPoData?.data?.info?.goodsReadyDate}
                                        name="Goods Ready Date"
                                        viaIntegration={viaIntegration}
                                    />
                                    <div className="details-box-content">
                                        {datesPoData?.data?.info?.goodsReadyDate
                                            ? moment(datesPoData?.data?.info?.goodsReadyDate).format('DD-MM-YYYY')
                                            : '-'}
                                    </div>
                                </div>
                                <div className="details-col box-content-holder">
                                    {/* <h6 className="details-box-heading">
                                        Projected ETA into Warehouse<span className="mandatory-field-text">*</span>
                                    </h6> */}
                                    <ShowMandatoryFields
                                        showMandatoryFieldsIcon={!datesPoData?.data?.info?.projectedEtaDate}
                                        name="Projected ETA into Warehouse"
                                        viaIntegration={viaIntegration}
                                    />
                                    <div className="details-box-content">
                                        {datesPoData?.data?.info?.projectedEtaDate
                                            ? moment(datesPoData?.data?.info?.projectedEtaDate).format('DD-MM-YYYY')
                                            : '-'}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/inbound/${id}/basic_details`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>

                    <BLPInboundPONextButton page="dates" />
                </div>
            </div>
            <AddDatesModal
                display={modalView}
                displayFunction={setModalView}
                data={datesPoData?.data?.info}
                multiDestination={multiDestination}
                setDisplayCancel={setDisplayCancel}
            />
            <CancelModal
                displayFunction={setModalView}
                mode="edit"
                header="Dates"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
        </div>
    );
};
export default Index;
