import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchQuickLinks,
    fetchQuickLinksError,
    fetchQuickLinksSuccess,
    fetchUserQuickLinks,
    fetchUserQuickLinksError,
    fetchUserQuickLinksSuccess,
    saveQuickLinksData,
    saveQuickLinksDataError,
    saveQuickLinksDataSuccess,
} from 'src/store/reducers/dashboard/quickLinksReducer';

import {
    getQuickLinks,
    getQuickStatusLinkDataService,
    QuickLinksData,
    updateQuickLinkSettings,
} from 'src/services/apis/dashboard/quickLinksApi';

// import { showLoading } from 'react-redux-loading-bar';

function* updateQuickLinksSettingsData({ payload }: { payload: QuickLinksData; type: any }) {
    try {
        // yield put(showLoading());
        const response: AxiosResponse = yield call(updateQuickLinkSettings, payload);
        yield put({ type: saveQuickLinksDataSuccess, payload: response });
    } catch (error) {
        yield put({ type: saveQuickLinksDataError, payload: error });
    }
}
function* fetchQuickLinksData({ payload }: { payload: { userName: string }; type: any }) {
    try {
        // yield put(showLoading());
        const res: AxiosResponse = yield call(getQuickLinks, payload);
        yield put({ type: fetchQuickLinksSuccess, payload: res });
    } catch (error) {
        yield put({ type: fetchQuickLinksError, payload: error });
    }
}

function* fetchUserQuickLinksData({
    payload,
}: {
    payload: {
        queryString: {
            keyword: string;
            profile: string;
        };
        body: {
            listOfUserOrganisations: any;
            loggedInUserName: string;
        };
    };
}) {
    try {
        // yield put(showLoading());
        const res: AxiosResponse = yield call(getQuickStatusLinkDataService, { payload });
        yield put({ type: fetchUserQuickLinksSuccess, payload: res });
    } catch (error) {
        yield put({ type: fetchUserQuickLinksError, payload: error });
    }
}

function* quickLinksSaga() {
    yield takeEvery(fetchQuickLinks, fetchQuickLinksData);
    yield takeEvery(saveQuickLinksData, updateQuickLinksSettingsData);
    yield takeEvery(fetchUserQuickLinks, fetchUserQuickLinksData);
}

export default quickLinksSaga;
