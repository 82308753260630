import axios from 'axios';
import { get, post } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export const getClientData = async (payload: any) => {
    try {
        if (payload?.fromAddPo) {
            const searchQuery = payload?.search
                ? `filter[filters][2][field]=OR_UserDefinedText4&filter[filters][2][operator]=contains&filter[filters][2][value]=${payload?.search}&`
                : '';
            const data = await get(
                `cix/scr/admin/organisation/clients?take=1000&filter[logic]=and&${searchQuery}filter[filters][3][field]=OR_Status&filter[filters][3][operator]=eq&filter[filters][3][value]=Active&attributes=CL_PK,OR_OrgCode,OR_OrgName,OR_UserDefinedText4`,
                null,
                externalBaseUrl
            );
            const result = data?.data.map((res: any) => ({
                value: res.OR_OrgCode,
                name: res.OR_OrgName,
                label: res.OR_UserDefinedText4,
                id: res.CL_PK,
            }));
            return result;
        } else {
            const data = await get(
                `api/mgt/clients/clientuser?userName=${payload.userName}${
                    payload.profile ? `&profile=${payload.profile}` : ''
                }`,
                null
            );
            const result = data?.info.map((res: any) => ({ value: res.code, label: res.name, id: res.id }));
            return { data: result, status: 200 };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const savePurchaseOrderAttachment = async (payload: any) => {
    const fileData = await convertBase64(payload.file[0]);

    try {
        const body = {
            referenceId: payload.poNumber,
            referenceType: 'HUBPO',
            documentType: null,
            // fileType: payload.file[0].type,
            fileName: payload.file[0].name,
            company: payload.clientCode,
            fileData: fileData,
            fileSize: payload.file[0].size,
        };
        const result = await post(`cix/scr/documents`, body, null, undefined, externalBaseUrl);

        return { data: result, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getVendorData = async (payload: any) => {
    try {
        if (payload?.fromAddPo) {
            const searchQuery = payload?.search
                ? `filter[filters][2][field]=OR_UserDefinedText4&filter[filters][2][operator]=contains&filter[filters][2][value]=${payload?.search}&`
                : '';
            const data = await get(
                `cix/scr/admin/organisation/client/${payload.clientCode}/vendor?take=1000&filter[logic]=and&${searchQuery}filter[filters][3][field]=OR_Status&filter[filters][3][operator]=eq&filter[filters][3][value]=Active&attributes=VN_PK,OR_OrgCode,OR_OrgName,OR_UserDefinedText4`,
                null,
                externalBaseUrl
            );
            const result = data?.data.map((res: any) => ({
                value: res.OR_OrgCode,
                name: res.OR_OrgName,
                label: res.OR_UserDefinedText4,
                id: res.VN_PK,
            }));
            return result;
        } else {
            const data = await get(`api/ord/vendors/vendorsforclient?clientCode=${payload.clientCode}`, null);
            const result = data?.info.map((res: any) => ({ value: res.code, label: res.name }));
            return { data: result, status: 200 };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const savePoData = async (payload: any) => {
    try {
        const data = await post('api/ord/po', payload, null);
        return { data: data.info, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getHeaderData = async (payload: any) => {
    try {
        const data = await get(
            `api/ord/po/header?poId=${payload.poId}${payload?.profile ? `&profile=${payload?.profile}` : ''}${
                payload?.cgId ? `&cgId=${payload?.cgId}` : ''
            }`,
            null
        );
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const skipLinePORequest = async (poId: string) => {
    try {
        const data = await get(`api/ord/po/headeronlypo/${poId}`, null);
        return { data, success: true };
    } catch (error: any) {
        // if (axios.isAxiosError(error)) {
        //     console.log('error message: ', error.message);
        //     throw error.response;
        // } else {
        console.log('unexpected error: ', error);
        return { success: false, message: error.message };
        // }
    }
};

export const addPoLineRevert = async (poId: string) => {
    try {
        const data = await get(`api/ord/po/revertheaderonlypo/${poId}`, null);
        return { data, success: true };
    } catch (error: any) {
        // if (axios.isAxiosError(error)) {
        //     console.log('error message: ', error.message);
        //     throw error.response;
        // } else {
        console.log('unexpected error: ', error);
        return { success: false, message: error.message };
        // }
    }
};

export const skipLinePoDestinations = async (poId: string, payload: any) => {
    try {
        const data = await post(`api/ord/po/destinations/?poId=${poId}`, payload, null);
        return { data, success: true };
    } catch (error: any) {
        // if (axios.isAxiosError(error)) {
        //     console.log('error message: ', error.message);
        //     throw error.response;
        // } else {
        console.log('unexpected error: ', error);
        return { success: false, message: error.message };
        // }
    }
};

export const getCancelledReasonData = async (payload: { poId: string }) => {
    try {
        const data = await get(`api/ord/po/cancelledpo?poId=${payload.poId}`, null);
        return data.info;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const getPurchaseOrderAttachment = async (payload: { poNumber: string; clientCode: string }) => {
    try {
        const filter = `filter=${encodeURIComponent(
            JSON.stringify({
                logic: 'and',
                filters: [
                    { field: 'FD_UserDefinedText1', operator: 'eq', value: payload.clientCode },
                    { field: 'FD_ReferenceId', operator: 'eq', value: payload.poNumber },
                ],
            })
        )}`;
        const data = await get(`cix/scr/documents?${filter}`, null, externalBaseUrl);

        return data.data;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

const convertBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.onerror = (error) => {
            reject(error);
        };
    });
};
