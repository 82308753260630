import React, { useEffect, useState } from 'react';
import BasicDetailsTab from '../Client/BasicDetail';
import '../../lcsBookingDetails.scss';
// import ScheduleTab from '../../ScheduleTab';
// import SkuTab from '../../SkuTab';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBasicData } from 'src/store/reducers/basicDetailsReducer';
import KendoGridJquery from 'src/components/KendoGridJquery';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { filterValues, gridDetailsForTable } from 'src/utils/kendoGrid/lifecyclesearch';
import { RootState } from 'src/store/reducers';
const baseURL = process.env.REACT_APP_BASE_URL;

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { userName, profileType } = useUserProfile();
    const [lcsTab, setLcsTab] = useState('Basic Details');
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const { clientTimeLine } = useSelector((state: RootState) => state.lifeCycleSearch);
    const setLcsView = (arg: string) => {
        setLcsTab(arg);
    };

    useEffect(() => {
        if (clientTimeLine.clientID) {
            dispatch(fetchBasicData({ clientPK: clientTimeLine.clientID }));
        }
    }, [clientTimeLine.clientID]);

    useEffect(() => {
        if (id) {
            gridDetailsForTable.clientBooking.url = `${baseURL}/api/bkg/tracking/lifecycle/clientData/bpdetails?orgID=${id}`;
            gridDetailsForTable.clientPurchaseOrder.url = `${baseURL}/api/ord/tracking/lifecycle/clientData/podetails?orgID=${id}`;
        }
    }, [id]);

    // const Btn: any = () => {
    //     return (
    //         <div className="head-btn-holder">
    //             <button className="app-btn app-btn-secondary" title="Go to Profile">
    //                 <span className="button-text">Go to Profile</span>
    //             </button>
    //         </div>
    //     );
    // };

    return (
        <div className="lcs-booking-details">
            <div className="lcs-booking-header">
                <div className="tab-nav-holder">
                    <a
                        className={`tab-nav ${lcsTab === 'Basic Details' ? 'active' : ''}`}
                        onClick={() => setLcsView('Basic Details')}
                    >
                        Basic Details
                    </a>
                    <a className={`tab-nav ${lcsTab === 'po' ? 'active' : ''}`} onClick={() => setLcsView('po')}>
                        Purchase Orders
                    </a>
                    <a
                        className={`tab-nav ${lcsTab === 'bookings' ? 'active' : ''}`}
                        onClick={() => setLcsView('bookings')}
                    >
                        Bookings
                    </a>
                </div>
            </div>
            <div className="lcs-tab-content">
                {lcsTab === 'Basic Details' && <BasicDetailsTab />}
                {lcsTab === 'po' && (
                    <KendoGridJquery
                        gridItem={gridDetailsForTable.clientPurchaseOrder}
                        // Btn={Btn}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                            profile: profileType,
                        }}
                    />
                )}
                {lcsTab === 'bookings' && (
                    <KendoGridJquery
                        gridItem={gridDetailsForTable.clientBooking}
                        // Btn={Btn}
                        hideFilter={true}
                        filterValues={filterValues}
                        body={{
                            ...listOfUserOrganisations,
                            loggedInUserName: userName,
                            profile: profileType,
                        }}
                    />
                )}
            </div>
        </div>
    );
};
export default Index;
