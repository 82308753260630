import React, { useEffect } from 'react';
import { msalInstance } from 'src';
import { getCookie } from 'src/utils';
import { checkUserEvent } from 'src/utils/sessionExpiry';

const Index: React.FC = () => {
    const loggedInSession = localStorage.getItem('loggedIn');
    const loggedInCookie = getCookie('loggedIn');

    useEffect(() => {
        if (loggedInCookie === null && loggedInSession === 'true') {
            checkUserEvent(msalInstance);
        }
    }, []);

    return (
        <div className="app-loader loading" style={{ backgroundColor: '#fff' }}>
            <svg className="svg-icon loader-icon">
                <use xlinkHref="#loaderIcon">
                    <title>Loading</title>
                </use>
            </svg>
        </div>
    );
};

export default Index;
