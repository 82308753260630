import React from 'react';
import { useAppSelector } from 'src/hooks';
import { useLocation, useParams, Link } from 'react-router-dom';
import { selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';

const PODetailsNextButton = () => {
    const { id } = useParams();
    const location = useLocation();
    const pathSegments = location.pathname.split('/');
    const lastSegment = pathSegments[pathSegments.length - 1];

    const headerData = useAppSelector(selectHeader);
    const skipPOLine = headerData?.isHeaderOnlyPo;

    const userData = localStorage.getItem('user');
    const roleIndex = localStorage.getItem('roleIndex');
    let userType = '';
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            userType = roles[roleIndex].profile?.toLowerCase();
        }
    }

    const productDetailsConfirmationObj = {
        label: 'Product Details Confirmation',
        value: 'product_details_confirmation',
        id: 4,
    };

    const shipmentConfirmation = {
        label: 'Shipment Confirmation',
        value: 'shipment_information',
        id: 4,
    };

    const previewObj = {
        label: 'PO Preview',
        value: 'po_review',
        id: 4,
    };

    const containerModeSelection = {
        label: 'Confirmation Groups',
        value: 'container_mode_selection',
        id: 5,
    };

    const poTabList = [
        {
            label: 'Basic Details',
            value: 'basic_details',
            id: 1,
        },
        {
            label: 'Dates',
            value: 'dates',
            id: 2,
        },
        {
            label: 'Product Details',
            value: 'product_details',
            id: 3,
        },
    ];

    const productDetailsConfirmationTabCheck = React.useMemo(() => {
        return (
            userType === 'agent' ||
            headerData?.poStatus?.toLowerCase().includes('awaiting') ||
            headerData?.poStatus?.toLowerCase().includes('ready') ||
            headerData?.poStatus?.toLowerCase().includes('pending_reassignment') ||
            headerData?.poStatus?.toLowerCase().includes('booked')
        );
    }, [lastSegment, headerData]);

    if (productDetailsConfirmationTabCheck) {
        if (!skipPOLine) {
            poTabList.push(productDetailsConfirmationObj);
        } else {
            poTabList.push(shipmentConfirmation);
        }
    } else {
        poTabList.push(previewObj);
    }

    if (
        headerData?.poStatus?.toLowerCase() !== 'draft'
        // && headerData?.shippingMode?.shippingModeCode !== 'AIR'
    ) {
        poTabList.push(containerModeSelection);
    }

    const isTabActive = React.useMemo(() => {
        return (param: string) => {
            if (headerData) {
                if (param === 'po_review') {
                    return (
                        headerData.bdTabComplete &&
                        headerData.datesTabComplete &&
                        headerData.validDate &&
                        headerData.productTabComplete &&
                        !productDetailsConfirmationTabCheck
                    );
                } else if (param === 'product_details') {
                    return headerData.productTabComplete;
                } else if (param === 'dates') {
                    return headerData.datesTabComplete && headerData?.validDate;
                } else if (param === 'basic_details') {
                    return headerData.bdTabComplete;
                } else if (param === 'product_details_confirmation') {
                    if (productDetailsConfirmationTabCheck && !skipPOLine) {
                        return headerData.pconfirmationComplete;
                    }
                    return false;
                } else if (param === 'shipment_information') {
                    if (productDetailsConfirmationTabCheck && skipPOLine) {
                        return headerData.shipmentConfirmationComplete;
                    }
                    return false;
                } else if (param === 'container_mode_selection') {
                    if (
                        headerData?.poStatus?.toLowerCase() !== 'draft'
                        // &&
                        // headerData?.shippingMode?.shippingModeCode !== 'AIR'
                    ) {
                        return headerData.containerModeSelectionComplete;
                    }
                    return false;
                }
            }
            return false;
        };
    }, [lastSegment, headerData]);

    const navigation = (pageName: string) => {
        const objIndex = poTabList?.findIndex((objVal: any) => objVal.value === pageName);
        return poTabList[objIndex + 1]?.value;
    };

    return (
        <>
            <Link
                to={isTabActive(lastSegment) ? `/purchase_order/${id}/${navigation(lastSegment)}` : '#'}
                className={`app-btn app-btn-primary footer-btn ${isTabActive(lastSegment) ? '' : 'disabled'}`}
                title="Next"
            >
                <span className="button-text footer-button-text">Next</span>
            </Link>
        </>
    );
};

export default PODetailsNextButton;
