import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchLayouts, isLoadingFn } from '../reducers/kendoReducer';

// Import all api's
import { getLayouts } from '../../services/apis/kendoApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getLayoutList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const response: AxiosResponse<any> = yield call(getLayouts, payload);
        yield put({ type: fetchLayouts, payload: response });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* kendoSaga() {
    yield takeEvery('savedLayouts/fetchLayouts', getLayoutList);
}

// Export the saga (data-saga)
export default kendoSaga;
