import axios from 'axios';
import { post } from '../../HttpMethods';

export const saveReceiptData = async (payload: any) => {
    try {
        const res = await post('api/ord/blp/receipts', payload, null);
        return res;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            throw error;
        }
    }
};
