import axios from 'axios';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, post } from '../../HttpMethods';
import { profileCode } from 'src/utils/kendoGrid/insights';

export const fetchInsightsTilesListApi = async (payload: { roleName: string; profile: string }) => {
    try {
        const data = await get(
            `api/mgt/tracking/permissions?profile=${payload.profile}&role=${payload.roleName}`,
            null
        );
        // const data = {
        //     permissions: [
        //         'TTBPA',
        //         'CLTB',
        //         'PPLTB',
        //         'NCUR',
        //         'PEOT',
        //         'OP',
        //         'PETM',
        //         'PENM',
        //         'ABTM',
        //         'PBNM',
        //         'TTM',
        //         'PTNM',
        //         'DOTP',
        //         'AOTP',
        //         'TTPBPPC',
        //         'SD',
        //         'TBCB',
        //         'TBTB',
        //         'ATBPSAGD',
        //         'PQ',
        //         'TBTBPC',
        //         'CBM',
        //         'ASBP',
        //         'BPABI',
        //         'BPABC',
        //         'NOCN',
        //         'NOCF',
        //         'CPSA',
        //         'CCC',
        //         'CLST',
        //         'CRFP',
        //         'MSBAB',
        //         'CPCA',
        //         'TBCR',
        //     ],
        // };
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchInsightsListApi = async (payload: any) => {
    try {
        const profileTypeToCode = profileCode[payload.profile];
        //TODO
        if (!payload.tileUrl) {
            return { data: [], isComplete: true, ...payload };
        }
        const url = payload.tileUrl?.replace('##PROFILE##', profileTypeToCode);
        if (payload.server === 'internal') {
            if (payload?.id === 'open-pos') {
                const data = await post(url, payload?.tileBody ?? {}, null);
                return { data, isComplete: true, ...payload };
            }
            if (payload?.id === 'po-quality') {
                const data = await get(url, null);
                return { data, isComplete: true, ...payload };
            }
            if (payload?.id === 'average-time-between-po-submission-and-gr-date') {
                const data = await post(url, payload?.tileBody ?? {}, null);
                return { data, isComplete: true, ...payload };
            }
            let data;
            if (payload?.tileMethod === 'POST') {
                data = await post(url, payload?.tileBody ?? {}, null);
            } else {
                data = await get(url, null);
            }
            return { data: data?.info?.[payload.code], isComplete: true, ...payload };
        } else {
            const data = await get(url, null, externalBaseUrl);

            return { data: data, isComplete: true, ...payload };
        }
    } catch (error) {
        return {
            data: [],
            isComplete: true,
            ...payload,
        };
        // if (axios.isAxiosError(error)) {
        //     throw error.response;
        // } else {
        //     console.log('unexpected error: ', error);
        //     return error;
        // }
    }
};

export const updateFavouriteApi = async (payload: any) => {
    try {
        const data = await post(`api/mgt/favourites/insight`, payload, null);
        return { data, status: 201, type: payload.type };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchFavouriteApi = async (payload: any) => {
    try {
        const data = await get(`api/mgt/favourites/insight/${payload.userName}/${payload.profile}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchTrendGraphApi = async (payload: any) => {
    try {
        if (payload.server === 'internal') {
            const data = await get(payload.url, null);
            return data.total;
        } else {
            const data = await get(payload.url, null, externalBaseUrl);
            return data?.data?.key1 ? { ...data?.data } : { data: [], graph: data?.data, keyToCheck: { ...payload } };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const fetchTrendMultiGraphApi = async (payload: any) => {
    try {
        if (payload.server === 'internal') {
            const data = await get(payload.url, null);
            return data.total;
        } else {
            const monthYearUrl = new URL(payload.url, externalBaseUrl);
            monthYearUrl.searchParams.set('take', '1000');
            const combinedUrl = monthYearUrl.pathname + '/YEAR/MONTH' + monthYearUrl.search;
            const [data1, data2] = await Promise.all([
                get(payload.url, null, externalBaseUrl),
                get(combinedUrl, null, externalBaseUrl),
            ]);
            return { data: data1?.data, graph: data2?.data, keyToCheck: { ...payload } };
        }
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
