import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    getExistingSchedules,
    fetchCurrencyDetailsApi,
    createFlightScheduleDetailsApi,
} from '../../../services/apis/bookingProposalAir/existingFlightSchedules';
import {
    fetchExistingFlightSchedulesList,
    fetchExistingFlightSchedulesListSuccess,
    isLoading,
    fetchCurrencyList,
    fetchCurrencyListSuccess,
    createScheduleDetailsSuccess,
    createScheduleDetailsFailed,
    createScheduleDetails,
} from '../../reducers/bookingProposalAir/existingFlightSchedulesReducer';

function* getExistingFlightScheduleData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoading, payload: true });
        const existingFlightSchedulesList: AxiosResponse<any> = yield call(getExistingSchedules, payload);

        yield put({ type: fetchExistingFlightSchedulesListSuccess, payload: existingFlightSchedulesList });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoading, payload: false });
    }
}

function* getCurrencyListData(_params: { payload: any; type: any }) {
    try {
        const currencyList: AxiosResponse<any> = yield call(fetchCurrencyDetailsApi);

        yield put({ type: fetchCurrencyListSuccess, payload: currencyList });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoading, payload: false });
    }
}

function* createFlightScheduleDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createFlightScheduleDetailsApi, payload);
        yield put({ type: createScheduleDetailsSuccess, payload: response });
    } catch (error) {
        yield put({ type: createScheduleDetailsFailed, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* existingFlightSchedulesSaga() {
    yield takeEvery(fetchExistingFlightSchedulesList, getExistingFlightScheduleData);
    yield takeEvery(fetchCurrencyList, getCurrencyListData);
    yield takeEvery(createScheduleDetails, createFlightScheduleDetailsData);
}

export default existingFlightSchedulesSaga;
