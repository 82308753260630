import React, { Fragment, useEffect, useState } from 'react';
import './vendor-basic-detail.scss';
import '../vendor-tab.scss';
import ProfileModal from '../../../../components/common/ProfileAddOrEditModal';
import {
    fetchParentList,
    fetchVendorData,
    saveVendorBasicDetails,
    fetchManufacturingCategories,
    fetchDefaultIncoTermsDetails,
    fetchOrganisationCategoryDetails,
    saveVendorAddress,
    fetchAddressList,
    setSelectedPK,
    editVendorProfileData,
    editVendorAddressData,
    fetchVendorContacts,
    resetProfileSuccessFlag,
    resetProfileError,
    resetAddressSuccessFlag,
    resetAddressError,
    clearUnlocoList,
} from '../../../../store/reducers/createVendorReducer';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { RootState } from '../../../../store/reducers';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
    // address,
    addressList,
    contactRoles,
    forwarderContacts,
    userDetails,
    vendorBasicDetails,
} from '../../../../services/apis/createVendorApi';
import { error, Position, success } from '../../../../utils/toast';
import HeaderTabs from '../HeaderTabs';
import ToolTip from '../../../../components/ToolTip';
import ToolTipContentBox from '../../../../components/ToolTipBoxContent';
import ShowForPermissions from '../../../../ShowForPermissions';
import { fetchAllData, fetchData } from 'src/store/reducers/linkClientVendorReducer';
import { fetchMgtCountry, selectMgtCountry } from 'src/store/reducers/purchaseOrder/productPoReducer';
import { fetchHeaderDataVendors } from 'src/store/reducers/profiles/vendors';

type addressType = addressList;
const addressValues = {
    address1: '',
    address2: '',
    country: '',
    city: '',
    postCode: '',
    state: '',
};

const Index: React.FC = () => {
    const [addressModalView, setAddressModalView] = useState(false);
    const [basicDetailView, setBasicDetailView] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [initialAddressValues, setInitialAddressValues] = useState(addressValues);
    const [addressIndex, setAddressIndex] = useState(1);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const country = useAppSelector(selectMgtCountry);
    const {
        parents,
        isLoading,
        vendorBasicDetails,
        manufacturingCategories,
        defaultIncoTermsList,
        organisationCategoryList,
        unLocoList,
        selectedPk,
        addressList,
        contacts,
        vendorName,
        createVendorSuccess,
        createVendorError,
        error: errorObj,
        editVendorSuccess,
        editVendorError,
        addAddressError,
        editAddressError,
        addAddressSuccess,
        editAddressSuccess,
        isUnlocoLoading,
    } = useSelector((state: RootState) => state.vendorDetails);
    const { id } = useParams();
    const { totalActiveClients } = useSelector((state: RootState) => state.linkClientVendor);
    const { vendorsHeader } = useSelector((state: RootState) => state.profilesVendors) as any;

    const initialProfileValues: vendorBasicDetails = {
        is_Child: isEdit && vendorBasicDetails ? vendorBasicDetails.is_Child : false,
        vn_parent: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_parent : null,
        vn_name: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_name : '',
        vn_org_code: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_org_code : '',
        vn_org_category: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_org_category : '',
        vn_abn: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_abn : '',
        vn_unloco: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_unloco : '',
        vn_origin_country:
            isEdit && vendorBasicDetails
                ? vendorBasicDetails?.vn_originCountryname
                    ? vendorBasicDetails?.vn_originCountryname
                    : vendorBasicDetails?.vn_origin_country
                : '',
        vn_originCountryname:
            isEdit && vendorBasicDetails
                ? vendorBasicDetails?.vn_originCountryname
                    ? vendorBasicDetails?.vn_originCountryname
                    : vendorBasicDetails?.vn_origin_country
                : '',
        vn_origin_country_code: isEdit && vendorBasicDetails ? vendorBasicDetails?.vn_origin_country_code : '',
        vn_default_incoterms: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_default_incoterms : '',
        vn_phone: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_phone : '',
        vn_website_url: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_website_url : '',
        vn_manufacturing_categories: isEdit && vendorBasicDetails ? vendorBasicDetails.vn_manufacturing_categories : [],
    };

    useEffect(() => {
        dispatch(fetchParentList({}));
        dispatch(fetchManufacturingCategories({}));
        dispatch(fetchDefaultIncoTermsDetails({}));
        dispatch(fetchOrganisationCategoryDetails({}));
        if (id) {
            dispatch(setSelectedPK(id));
        }
    }, []);

    useEffect(() => {
        const payload = {
            currentPage: 0,
            perPageCount: 10,
            columnOrder: false,
            columnStatus: 'code',
            id: id,
        };
        dispatch(fetchData(payload));
        dispatch(fetchAllData(payload));
        dispatch(fetchMgtCountry([]));
    }, []);

    const getFullName = (user: userDetails) => {
        if (user) {
            return `${user.us_FirstName ? user.us_FirstName : ''} ${user.us_MiddleName ? user.us_MiddleName : ''} ${
                user.us_LastName ? user.us_LastName : ''
            }`;
        }
        return '';
    };

    useEffect(() => {
        if (id) {
            dispatch(fetchVendorData({ vendorPK: id }));
            dispatch(fetchAddressList({ vn_pk: id }));
            dispatch(fetchVendorContacts({ vn_pk: id }));
        }
    }, [id]);

    useEffect(() => {
        if (createVendorSuccess || editVendorSuccess) {
            setBasicDetailView(false);
            if (createVendorSuccess) {
                //Show Add profile success message
                success('Profile Information has been added', Position.TOP_RIGHT);
                navigate(`/profile/vendor/${selectedPk}/basic_detail`);
                dispatch(fetchVendorData({ vendorPK: selectedPk }));
            }
            if (editVendorSuccess) {
                success('Profile Information has been updated', Position.TOP_RIGHT);
                dispatch(fetchVendorData({ vendorPK: id }));
            }
            dispatch(fetchHeaderDataVendors(id));
            dispatch(resetProfileSuccessFlag(false));
        }
    }, [createVendorSuccess, editVendorSuccess]);

    useEffect(() => {
        if (createVendorError || editVendorError) {
            error(errorObj?.response?.data?.message || errorObj?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetProfileError(false));
        }
    }, [createVendorError, editVendorError]);

    useEffect(() => {
        if (addAddressSuccess || editAddressSuccess) {
            setAddressModalView(false);
            dispatch(fetchAddressList({ vn_pk: id }));
            if (addAddressSuccess) {
                success('New Address has been added', Position.TOP_RIGHT);
            }
            if (editAddressSuccess) {
                success(`Address ${addressIndex} has been updated`, Position.TOP_RIGHT);
            }
            dispatch(resetAddressSuccessFlag(false));
            dispatch(fetchHeaderDataVendors(id));
        }
    }, [addAddressSuccess, editAddressSuccess]);

    useEffect(() => {
        if (addAddressError || editAddressError) {
            error(errorObj?.response?.data?.message || errorObj?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetAddressError(false));
        }
    }, [addAddressError, editAddressError]);

    const onSaveAddress = (data: addressType) => {
        if (isEdit) {
            dispatch(
                editVendorAddressData({
                    vendorAddress: data,
                    vn_pk: id,
                    oa_pk: data.oa_pk,
                })
            );
        } else {
            delete data.oa_pk;
            dispatch(saveVendorAddress({ vn_pk: id, data: data }));
        }
    };

    const onSaveProfileInformation = (data: vendorBasicDetails) => {
        // if (data.vn_origin_country && data.vn_origin_country.includes(',')) {
        //     const [firstTerm, secondTerm] = data.vn_origin_country.split(',');
        //     data.vn_origin_country = firstTerm;
        //     data.vn_origin_country_code = secondTerm;
        // }
        if (isEdit) {
            dispatch(editVendorProfileData({ vn_pk: selectedPk, data: data }));
        } else {
            dispatch(saveVendorBasicDetails({ data }));
        }
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link className="dropdown-arrow" to="/vendor" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/profile">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/vendor">
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="#" className="breadcrumb-item">
                                {id ? 'Vendor' : 'Add New Vendor'}
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    <h2 className="page-heading">
                        {id ? (vendorsHeader?.vendorName ? vendorsHeader?.vendorName : vendorName) : 'Add New Vendor'}
                    </h2>
                </div>
                <HeaderTabs
                    disableSecond={!id || !vendorBasicDetails?.vn_name || !addressList?.length}
                    disableThird={
                        !id ||
                        !vendorBasicDetails?.vn_name ||
                        !addressList?.length ||
                        !totalActiveClients ||
                        totalActiveClients === 0
                    }
                />
                <div style={{ zIndex: 101 }} className={`app-loader ${isLoading && !isUnlocoLoading ? 'loading' : ''}`}>
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
            </div>
            <div className="content-section main-wrapper vendor-basic-detail-wrapper">
                {/* toast message */}
                {/* <div className="toast-holder">
                    <svg className="svg-icon checkmark-icon">
                        <use xlinkHref="#checkmarkIcon">
                            <title>updated</title>
                        </use>
                    </svg>
                    <div className="toast-message">
                        <span className="toast-message-content-name">Profile Information </span>has been updated
                    </div>
                    <svg className="svg-icon close-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </div> */}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Profile Information</h6>
                            {vendorBasicDetails ? (
                                <ShowForPermissions permission="create" type="vendor">
                                    <button
                                        className="action-btn app-btn app-btn-secondary "
                                        title="edit profile"
                                        onClick={() => {
                                            setBasicDetailView(true);
                                            setIsEdit(true);
                                        }}
                                    >
                                        <svg className="svg-icon edit-icon">
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                </ShowForPermissions>
                            ) : null}
                        </div>
                        {!vendorBasicDetails ? (
                            <ShowForPermissions permission="create" type="vendor">
                                <div className="btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary icon-button  add-product-btn"
                                        title="Add Profile Information"
                                        onClick={() => {
                                            setBasicDetailView(true);
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Add Profile Information</span>
                                    </button>
                                </div>
                            </ShowForPermissions>
                        ) : null}
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Name<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_name || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Organisation Code <span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_org_code || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Organisation Category<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_org_category || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Company Registration Number</h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_abn || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                UNLOCO<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_unloco || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Country of Origin<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_originCountryname || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Default Incoterm<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_default_incoterms || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Phone
                                {/* <span className="mandatory-field-text">*</span> */}
                            </h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_phone || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Website URL</h6>
                            <div className="details-box-content">{vendorBasicDetails?.vn_website_url || '-'}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <>
                                <h6 className="details-box-heading">
                                    Manufacturing Categories
                                    {/* <span className="mandatory-field-text">*</span> */}
                                </h6>
                                {vendorBasicDetails?.vn_manufacturing_categories &&
                                vendorBasicDetails?.vn_manufacturing_categories?.length > 0 ? (
                                    vendorBasicDetails?.vn_manufacturing_categories?.map(
                                        (category: any, index: number) => {
                                            return (
                                                <div className="details-box-content" key={index}>
                                                    {category}
                                                </div>
                                            );
                                        }
                                    )
                                ) : (
                                    <div className="details-box-content">-</div>
                                )}
                            </>
                        </div>
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <h6 className="details-box-heading-2">
                            Address <span className="mandatory-field-text">*</span>
                        </h6>
                        {id ? (
                            <div className="btn-holder">
                                <ShowForPermissions permission="create" type="vendor">
                                    <button
                                        className="app-btn app-btn-secondary icon-button  add-product-btn"
                                        title="Add Address"
                                        onClick={() => {
                                            setIsEdit(false);
                                            setAddressModalView(true);
                                            setInitialAddressValues(addressValues);
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Add Address</span>
                                    </button>
                                </ShowForPermissions>
                            </div>
                        ) : null}
                    </div>
                    {!addressList?.length ? (
                        <div className="no-data-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No address added</p>
                        </div>
                    ) : null}
                    <div className="card-detail-col">
                        {addressList?.map((address: addressType, index: number) => {
                            return (
                                <Fragment>
                                    <div className="details-col box-content-holder">
                                        <div className="card-head-holder">
                                            <h6 className="details-box-heading">{`Address ${index + 1}`}</h6>
                                            <ShowForPermissions permission="create" type="vendor">
                                                <button
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="edit address"
                                                    onClick={() => {
                                                        setIsEdit(true);
                                                        setAddressModalView(true);
                                                        setInitialAddressValues(address);
                                                        setAddressIndex(index + 1);
                                                    }}
                                                >
                                                    <svg className="svg-icon edit-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </button>
                                            </ShowForPermissions>
                                        </div>
                                        <div className="details-box-content">
                                            <p>{address.address1}</p>
                                            <p>{address.address2}</p>
                                            <p>{address.city}</p>
                                            <p>{address.country}</p>
                                            <p>{address.postCode}</p>
                                            <p>{address.state}</p>
                                        </div>
                                    </div>
                                </Fragment>
                            );
                        })}
                    </div>
                </div>
                {contacts && Object.keys(contacts).length !== 0 ? (
                    <div className="card details-box custom">
                        {contacts?.client_contacts ? (
                            <>
                                <h6 className="details-box-heading-2">Contact</h6>
                                <div className="card-detail-col contact-details">
                                    <div className="details-col">
                                        <div className="box-content-holder address-details">
                                            <h6 className="details-box-heading">Vendor Contacts</h6>
                                            <div className="details-box-content">
                                                {contacts?.client_contacts?.map(
                                                    (contact: userDetails, index: number) => (
                                                        <Fragment key={index}>
                                                            <ToolTip tooltipClass={'inline-block'}>
                                                                <p>{getFullName(contact)}</p>
                                                                <ToolTipContentBox
                                                                    data={{
                                                                        userName: getFullName(contact),
                                                                        role: null,
                                                                        email: contact.us_Email,
                                                                        phoneNumber: contact.us_PhoneNo,
                                                                    }}
                                                                />
                                                            </ToolTip>
                                                        </Fragment>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        {contacts?.forwarder_contacts ? (
                            <>
                                <h6 className="details-box-heading-2">Forwarder Contacts</h6>
                                <div className="card-detail-col">
                                    {contacts?.forwarder_contacts
                                        .sort(
                                            (a: any, b: any) =>
                                                contactRoles.indexOf(a.role) - contactRoles.indexOf(b.role)
                                        )
                                        .map((contact: forwarderContacts, index: number) => (
                                            <Fragment key={index}>
                                                <div className="details-col box-content-holder">
                                                    <h6 className="details-box-heading">{contact.role}</h6>
                                                    <ToolTip tooltipClass={'inline-block'}>
                                                        <div className="details-box-content">
                                                            {contact?.users?.map((user: userDetails, index: number) => (
                                                                <p key={index}>
                                                                    <ToolTipContentBox
                                                                        data={{
                                                                            userName: getFullName(user),
                                                                            role: contact.role,
                                                                            email: user.us_Email,
                                                                            phoneNumber: user.us_PhoneNo,
                                                                        }}
                                                                    />
                                                                    {getFullName(user)}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    </ToolTip>
                                                </div>
                                            </Fragment>
                                        ))}
                                </div>
                            </>
                        ) : null}
                    </div>
                ) : null}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder">
                    <Link
                        to={
                            !id || !vendorBasicDetails?.vn_name || !addressList?.length
                                ? ''
                                : `/profile/vendor/${id}/client_details`
                        }
                    >
                        <button
                            className="app-btn app-btn-primary footer-btn"
                            title="Next"
                            disabled={!id || !vendorsHeader?.bdTabComplete}
                        >
                            <span className="button-text footer-button-text">Next</span>
                        </button>
                    </Link>
                </div>
            </div>
            {addressModalView ? (
                <ProfileModal
                    tabName={'vendor'}
                    modalName={'addressInfo'}
                    isEdit={isEdit}
                    initialValues={initialAddressValues}
                    dismissModal={() => {
                        setAddressModalView(false);
                        dispatch(clearUnlocoList());
                    }}
                    onSave={onSaveAddress}
                    list={{
                        parentList: parents?.map((option: any) => {
                            return { value: option.vn_PK, label: option.vn_vendorname };
                        }),
                        countryList: country,
                    }}
                ></ProfileModal>
            ) : null}
            {basicDetailView ? (
                <ProfileModal
                    tabName={'vendor'}
                    modalName={'profileInfo'}
                    isEdit={isEdit}
                    initialValues={initialProfileValues}
                    dismissModal={() => {
                        setBasicDetailView(false);
                    }}
                    onSave={onSaveProfileInformation}
                    list={{
                        parentList: parents?.map((option: any) => {
                            return { value: option.vn_vendorcode, label: option.vn_vendorname };
                        }),
                        defaultIncoTermsList: defaultIncoTermsList?.map((option: any) => {
                            return { value: option.di_pk, label: option.di_default_incoterms };
                        }),
                        manufacturingCategoryList: manufacturingCategories?.map(
                            (option: { mc_pk: string; mc_manufacturingcategory: string }) => {
                                return { value: option.mc_pk, label: option.mc_manufacturingcategory };
                            }
                        ),
                        organisationCategoryList: organisationCategoryList?.map((option: any) => {
                            return { value: option.oc_pk, label: option.oc_organisation_category };
                        }),
                        unLocoList: unLocoList?.map((option: any) => {
                            return { value: option.portCode, label: `${option.portName}(${option.portCode})` };
                        }),
                        countryList: country,
                    }}
                ></ProfileModal>
            ) : null}
        </div>
    );
};
export default Index;
