import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { RootState } from 'src/store/reducers';

type BtnProps = {
    clientName?: string;
};

const Index = (props: BtnProps) => {
    const location: any = useLocation();
    const { clientHeader } = useSelector((state: RootState) => state.profilesClients) as any;
    useEffect(() => {
        if (props?.clientName) {
            localStorage.setItem('clientName', props?.clientName);
        } else if (location?.state?.name) {
            localStorage.setItem('clientName', location.state.name);
        }
    }, [location?.state, props?.clientName]);

    return (
        <div className="pageheading-holder">
            <Link to="/profile">
                {/* <button className="back-btn app-btn">
                    <svg className="svg-icon back-icon">
                        <use xlinkHref="#previousArrowIcon">
                            <title>Back</title>
                        </use>
                    </svg>
                </button> */}
            </Link>
            <h2 className="page-heading">
                {clientHeader?.clientName
                    ? clientHeader?.clientName
                    : props?.clientName
                    ? props?.clientName
                    : localStorage.getItem('clientName')}
            </h2>
        </div>
    );
};

export default Index;
