import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import BookingProposalHeader from '../BookingProposalHeader';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import '../../BookingProposal/POSelection.scss';
import BookingEnrichmentAdditionalDetail from '../BookingEnrichmentAdditionalDetail';
import BookingCancellationModal from '../BookingEnrichmentAdditionalDetail/CancelBookingModal';
import { RootState } from 'src/store/reducers';
// import { useUserProfile } from 'src/hooks/useUserProfile';
// import ShowForPermissions from 'src/ShowForPermissions';
// import { NavData } from 'src/pages/PoBookingsAir/Wrapper';
import { NavData } from '../Wrapper';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    // fetchEnrichBooking,
    resetLoader,
    selectAdditionalDetailsLoading,
    // selectEnrichBooking,
    // selectHouseBillDetails,
    // fetchHouseBills,
} from 'src/store/reducers/bookingProposal/additionalDetailsReducer';

const summaryStats = [
    {
        title: 'Total CBM',
        value: '132',
    },
    {
        title: 'Total Kgs',
        value: '5000',
    },
];

interface Props {
    navData: NavData[];
}
const Index: React.FC<Props> = ({ navData }) => {
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const { id } = useParams();
    // const { profileType } = useUserProfile();
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const isLoading = useAppSelector(selectAdditionalDetailsLoading);
    const { isLoadingHeader } = useSelector((state: RootState) => state.bpHeaderData);
    const dispatch = useAppDispatch();
    // const houseBillData = useAppSelector(selectHouseBillDetails);
    // const enrichBooking = useAppSelector(selectEnrichBooking);
    // const [nextBtn, setNextBtn] = useState(false);

    // const navData = [
    //     {
    //         title: 'PO Selection',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/PoSelection`,
    //     },
    //     //TODO update correct links
    //     {
    //         title: 'Flight Schedules',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/FlightSelectionDetails`,
    //     },
    //     {
    //         title: 'Booking Details',
    //         selected: 1,
    //         path: `/air_booking_list/${id}/booking_details`,
    //     },
    //     {
    //         title: 'Shipment Details',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/shipment_details`,
    //     },
    //     {
    //         title: 'Attachments',
    //         selected: 0,
    //         path: `/air_booking_list/${id}/attachments`,
    //     },
    // ];

    const customNavData = navData.map((navItem) => {
        if (navItem.key === 'booking_details') {
            return { ...navItem, selected: 1 };
        } else {
            return navItem;
        }
    });
    // const customNavDataNextBtn = customNavData
    //     .filter((ids) => ids.key !== 'shipment_details' && ids.key !== 'attachments')
    //     .map((id) => id);

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };

    useEffect(() => {
        dispatch(resetLoader([]));
    }, []);

    // useEffect(() => {
    //     if (headerData.bpStatus === 'Draft_Booking' && profileType !== 'client') {
    //         if (
    //             houseBillData &&
    //             enrichBooking &&
    //             enrichBooking?.originCartageCompany &&
    //             houseBillData?.numbers?.length > 0 &&
    //             houseBillData?.numbers[0]?.vendors?.length > 0 &&
    //             houseBillData?.numbers[0]?.vendors[0]?.houseBillNumber &&
    //             enrichBooking?.bookingRefNum &&
    //             enrichBooking?.soNumber &&
    //             houseBillData?.masterBillNumber
    //         ) {
    //             setNextBtn(true);
    //         } else {
    //             setNextBtn(false);
    //         }
    //     }
    // }, [headerData.bpStatus, enrichBooking, houseBillData]);

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header additional-detail-enrichment-wrapper">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={'/purchase_order/basic_details'}
                    summaryStats={summaryStats}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    //setShowBookingSideBar={setShowBookingSideBar}
                />
                {/* <div className="pageheading-holder">
                    <div className="back-btn-holder">
                        <Link to="/purchase_order">
                            <button className="back-btn app-btn">
                                <svg className="svg-icon back-icon">
                                    <use href="#previousArrowIcon">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </button>
                        </Link>
                        <h2 className="page-heading">AB12332</h2>
                        <div className="transport-icon-wrapper">
                            <div className="item-count-status-holder">
                                <div className="grid-item-count-status status-ui info-status">
                                    <span className="count">
                                        <svg className="svg-icon aeroplane-icon">
                                            <use xlinkHref="#aeroplaneIcon">
                                                <title>Aeroplace</title>
                                            </use>
                                        </svg>
                                    </span>
                                    <span className="status-text">Air</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* class: waiting-pill used for draft state
                    class: success-pill used for sucess state */}
                {/* <div className="header-container-view"> */}
                {/* <span className="btn-pills container-pill waiting-pill">
                            <span className="pill-text">Draft Booking</span>
                        </span> */}
                {/* <span className="btn-pills container-pill success-pill">
                            <span className="pill-text">Ready for Booking</span>
                        </span>
                    </div> */}
                {/* </div> */}
                {/* <div className="header-sidebar-holder">
                    <button className="sidebar-info-btn animate-btn">
                        <svg className="svg-icon info-icon">
                            <use xlinkHref="#infoIcon">
                                <title>Information</title>
                            </use>
                        </svg>
                    </button>
                </div> */}
                {(isLoading || isLoadingHeader) && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <BookingProcessTab navData={customNavData} />
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper bo-additional-detail">
                <div className="main-wrapper">
                    <BookingEnrichmentAdditionalDetail></BookingEnrichmentAdditionalDetail>
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    {/* {profileType === 'agent' &&
                        (headerData?.bpStatus === 'Booked' ||
                            headerData?.bpStatus === 'Ready_for_Booking' ||
                            headerData?.bpStatus === 'Draft_Booking') && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    title="Cancel Booking"
                                    className="app-btn app-btn-secondary footer-btn"
                                    onClick={() => {
                                        setShowBookingCancelModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel Booking</span>
                                </button>
                            </ShowForPermissions>
                        )} */}
                    <Link
                        title="Previous"
                        className="app-btn app-btn-secondary footer-btn"
                        to={`/air_booking_list/${id}/packing`}
                    >
                        <span className="button-text footer-button-text">Previous</span>
                        {/* </button> */}
                    </Link>
                    {(headerData.bpStatus === 'Ready_for_Booking' ||
                        headerData.bpStatus === 'Booked' ||
                        headerData.bpStatus === 'Draft_Booking') && (
                        <Link
                            title="Next"
                            className={`app-btn app-btn-primary footer-btn`}
                            to={`/air_booking_list/${id}/shipment_details`}
                        >
                            <span className="button-text footer-button-text">Next</span>
                            {/* </button> */}
                        </Link>
                    )}
                </div>
            </div>
            {showBookingCancelModal &&
                (headerData?.bpStatus === 'Booked' ||
                    headerData?.bpStatus === 'Ready_for_Booking' ||
                    headerData?.bpStatus === 'Draft_Booking') && (
                    <BookingCancellationModal
                        setShowBookingCancelModal={setShowBookingCancelModal}
                    ></BookingCancellationModal>
                )}
        </div>
    );
};
export default Index;
