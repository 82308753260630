/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

export interface ConfirmationGroups {
    confirmationGroupName: string;
    type: string;
    poLines: number;
    cbm: number;
    weight: number;
    goodsReadyDate: string;
    destinationPortName: string;
    destinationPort: string;
    createdUserId: string;
    createdUser: string;
    subPoLinesId: string[];
}
export interface SplitLines {
    splitLineId: string;
    confirmationGroup: string;
    splitLineStatus: string;
    confirmedQty: number;
    confirmedGoodsdate: string;
    confirmedCbm: number;
    confirmedWeight: number;
    confirmedUom: string;
    convertedQuantity: string;
    poLineNumber: string;
    editMode: boolean;
    saveTriggered: boolean;
    splitLineIndexValue: number;
    destination: string;
    destinationPort: string;
    destinationPortName: string;
    destinationAddressId: string;
    destinationAddress: string;
    bpNumber: string;
}

export interface ProductDetailsConfirmationDataDetails {
    poProductId: string;
    poLineNumber: number;
    sku: string;
    description: string;
    originPort: string;
    destination: string;
    destinationPort: string;
    destinationPortName: string;
    destinationAddressId: string;
    destinationAddress: string;
    qty: number;
    cbm: number;
    eachCbm: number;
    weight: number;
    eachWeight: number;
    goodsReadyDate: string;
    uom: string;
    convertedQty: string;
    orderDate: string;
    conversionQuantity: string;
    status: string;
    quantityType: string;
    updatedQuantityType: string;
    isExpanded: boolean;
    saveTriggered: boolean;
    indexValue: number;
    isShort: boolean | null;
    splitLines: SplitLines[];
}

interface ProductDetailsConfirmationState {
    isLoading: boolean;
    productDetailsConfirmation: { enrichedProductDetails: ProductDetailsConfirmationDataDetails[]; statusCount: any };
    lclThreshold: number;
    productDetailsConfirmationSuccess: any;
    productDetailsConfirmationError: any;
    isError?: boolean;
}

const initialState: ProductDetailsConfirmationState = {
    isLoading: true,
    productDetailsConfirmation: { enrichedProductDetails: [], statusCount: {} },
    lclThreshold: 0,
    productDetailsConfirmationSuccess: undefined,
    productDetailsConfirmationError: undefined,
    isError: false,
};

export const productDetailsConfirmationSlice = createSlice({
    name: 'productDetailsConfirmation',
    initialState,
    reducers: {
        fetchProductDetailsConfirmationList: (state, _action) => {
            state.isLoading = true;
            state.isError = false;
            state.productDetailsConfirmation = { enrichedProductDetails: [], statusCount: {} };
        },
        fetchProductDetailsConfirmationListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmation = action.payload?.data?.info;
        },
        fetchProductDetailsConfirmationListFailure(state, action) {
            state.isLoading = false;
            // state.isError = true;
            state.productDetailsConfirmation = action.payload;
        },
        fetchLclThreshold: (state, _action) => {
            // state.isLoading = true;
            state.isError = false;
        },
        fetchLclThresholdSuccess(state, action) {
            // state.isLoading = false;
            state.isError = false;
            state.lclThreshold = action.payload?.data;
        },
        fetchLclThresholdFailure(state, action) {
            // state.isLoading = false;
            // state.isError = true;
            state.lclThreshold = action.payload;
        },
        confirmPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        confirmPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmationSuccess = action.payload;
            state.productDetailsConfirmationError = {};
        },
        confirmPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productDetailsConfirmationSuccess = {};
            state.productDetailsConfirmationError = action.payload;
        },
        saveAllPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        saveAllPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmationSuccess = action.payload;
            state.productDetailsConfirmationError = {};
        },
        saveAllPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productDetailsConfirmationSuccess = {};
            state.productDetailsConfirmationError = action.payload;
        },
        submitPo(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        submitPoSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmationSuccess = action.payload;
            state.productDetailsConfirmationError = {};
        },
        submitPoFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productDetailsConfirmationSuccess = {};
            state.productDetailsConfirmationError = action.payload;
        },
        removeProductDetailsConfirmation(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        removeProductDetailsConfirmationSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmationSuccess = action.payload;
            state.productDetailsConfirmationError = {};
        },
        removeProductDetailsConfirmationFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productDetailsConfirmationSuccess = {};
            state.productDetailsConfirmationError = action.payload;
        },
        dropProductDetailsConfirmation(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        dropProductDetailsConfirmationSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmationSuccess = action.payload;
            state.productDetailsConfirmationError = {};
        },
        dropProductDetailsConfirmationFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productDetailsConfirmationSuccess = {};
            state.productDetailsConfirmationError = action.payload;
        },
        changePoLineStatus(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        changePoLineStatusSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmationSuccess = action.payload;
            state.productDetailsConfirmationError = {};
        },
        changePoLineStatusFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productDetailsConfirmationSuccess = {};
            state.productDetailsConfirmationError = action.payload;
        },
        editPoLineStatus(state, _action) {
            state.isLoading = true;
            state.isError = false;
        },
        editPoLineStatusSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.productDetailsConfirmationSuccess = action.payload;
            state.productDetailsConfirmationError = {};
        },
        editPoLineStatusFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.productDetailsConfirmationSuccess = {};
            state.productDetailsConfirmationError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, _action) => {
            state.productDetailsConfirmationError = undefined;
            state.productDetailsConfirmationSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchProductDetailsConfirmationList,
    fetchProductDetailsConfirmationListSuccess,
    fetchProductDetailsConfirmationListFailure,
    fetchLclThreshold,
    fetchLclThresholdSuccess,
    fetchLclThresholdFailure,
    confirmPo,
    confirmPoSuccess,
    confirmPoFailure,
    saveAllPo,
    saveAllPoSuccess,
    saveAllPoFailure,
    submitPo,
    submitPoSuccess,
    submitPoFailure,
    changePoLineStatus,
    changePoLineStatusSuccess,
    changePoLineStatusFailure,
    editPoLineStatus,
    editPoLineStatusSuccess,
    editPoLineStatusFailure,
    removeProductDetailsConfirmation,
    removeProductDetailsConfirmationSuccess,
    removeProductDetailsConfirmationFailure,
    dropProductDetailsConfirmation,
    dropProductDetailsConfirmationSuccess,
    dropProductDetailsConfirmationFailure,
    isLoadingFn,
    clearFailure,
} = productDetailsConfirmationSlice.actions;

export const selectProductDetailsConfirmationLoading = (state: RootState) =>
    state.productDetailsConfirmationData.isLoading;
export const selectProductDetailsConfirmation = (state: RootState) =>
    state.productDetailsConfirmationData.productDetailsConfirmation;
export const selectProductDetailsConfirmationSuccess = (state: RootState) =>
    state.productDetailsConfirmationData.productDetailsConfirmationSuccess;
export const selectProductDetailsConfirmationError = (state: RootState) =>
    state.productDetailsConfirmationData.productDetailsConfirmationError;
export const selectProductDetailsConfirmationIsError = (state: RootState) =>
    state.productDetailsConfirmationData.isError;
export const selectLclThreshold = (state: RootState) => state.productDetailsConfirmationData.lclThreshold;

export default productDetailsConfirmationSlice.reducer;
