import {
    Grid,
    GridColumn as Column,
    GridToolbar,
    GridItemChangeEvent,
    GridCellProps,
    GridRowProps,
    GridExpandChangeEvent,
    GridHeaderCellProps,
} from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'src/hooks';
import { RootState } from 'src/store/reducers';
import { postContainerPacking } from 'src/store/reducers/purchaseOrder/vendorPortalReducer';
import { CellRender, RowRender } from './renderers';
import { setGroupIds, setExpandedState } from '@progress/kendo-react-data-tools';
import { DataResult, process, State } from '@progress/kendo-data-query';
import { useParams } from 'react-router-dom';
import './vendorPortal.css';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    packingData: any;
    setPackSuccess: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    zaPackingArray: any;
}

const initialDataState: State = {
    group: [{ field: 'colour' }],
};

const EDIT_FIELD = 'inEdit';
const processWithGroups = (data: any[], dataState: State) => {
    const newDataState = process(data, dataState);

    setGroupIds({ data: newDataState.data, group: dataState.group });

    return newDataState;
};
/**
 * Kendo React Grid with Group by function
 */
const Index: React.FC<ModalProps> = ({ modalView, setModalView, packingData, setPackSuccess, zaPackingArray }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [buttonDisplay, setButtonDisplay] = useState('Save Changes');
    const { savingResult } = useSelector((state: RootState) => state.vendorPortalData);

    const [data, setData] = React.useState<Array<any>>(zaPackingArray);
    const [changes, setChanges] = React.useState<boolean>(false);
    const [dataState, setDataState] = React.useState<State>(initialDataState);
    const [collapsedState, setCollapsedState] = React.useState<string[]>([]);
    const [resultState, setResultState] = React.useState<DataResult>(
        processWithGroups(zaPackingArray, initialDataState)
    );

    useEffect(() => {
        if (savingResult.length > 0) {
            setModalView(false);
            setPackSuccess(true);
        }
    }, [savingResult]);

    const enterEdit = (dataItem: any, field: string | undefined) => {
        const newData = data.map((item) => ({
            ...item,
            [EDIT_FIELD]: item.id === dataItem.id ? field : undefined,
        }));
        //setData(newData);
        setResultState(processWithGroups(data, initialDataState));
    };

    const exitEdit = () => {
        const newData = data.map((item) => ({ ...item, [EDIT_FIELD]: undefined }));
        //setData(newData);
        setResultState(processWithGroups(data, initialDataState));
    };

    const onExpandChange = React.useCallback(
        (event: GridExpandChangeEvent) => {
            const item = event.dataItem;

            if (item.groupId) {
                const collapsedIds = !event.value
                    ? [...collapsedState, item.groupId]
                    : collapsedState.filter((groupId) => groupId !== item.groupId);
                setCollapsedState(collapsedIds);
            }
        },
        [collapsedState]
    );

    const saveChanges = () => {
        zaPackingArray.splice(0, zaPackingArray.length, ...data);

        const payload = { containers: [] as any };
        const containerIds: any[] = packingData.containers;
        zaPackingArray.forEach((p: any) => {
            if (p.packed > 0) {
                containerIds.forEach((element) => {
                    payload.containers.push({
                        container_id: element.container_id,
                        container_type: element.container_type,
                        item_colour_description: p.colour,
                        item_size: p.size.toString(),
                        quantity: p.packed.toString(),
                    });
                });
            }
        });

        setButtonDisplay('Saving...');
        dispatch(postContainerPacking({ payload: payload, poPk: id }));
        setChanges(false);
    };

    const cancelChanges = () => {
        setData(zaPackingArray);
        setResultState(processWithGroups(zaPackingArray, initialDataState));
        setChanges(false);
    };

    const itemChange = (event: GridItemChangeEvent) => {
        let field = event.field || '';
        if (event.value < 0) {
            return;
        }
        if (event.value % 1 != 0) {
            return;
        }

        event.dataItem[field] = event.value;
        let newData = data.map((item) => {
            if (item.id === event.dataItem.id) {
                item[field] = event.value;
            }
            return item;
        });
        setData(newData);
        setResultState(processWithGroups(newData, initialDataState));
        setChanges(true);
    };

    const customCellRender: any = (td: React.ReactElement<HTMLTableCellElement>, props: GridCellProps) => (
        <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />
    );
    const customRowRender: any = (tr: React.ReactElement<HTMLTableRowElement>, props: GridRowProps) => (
        <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />
    );

    const newData = setExpandedState({
        data: resultState.data,
        collapsedIds: collapsedState,
    });

    const centerHeaderCell = (props: GridHeaderCellProps) => {
        return (
            <a className="k-link">
                <span style={{ textAlign: 'center' }}>{props.title}</span>
                {props.children}
            </a>
        );
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder za-packing-holder">
                        <div className="modal-header">
                            <div className="title">ZA Packing</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content vp-content">
                                <Grid
                                    data={newData}
                                    dataItemKey={'id'}
                                    rowHeight={50}
                                    onItemChange={itemChange}
                                    cellRender={customCellRender}
                                    rowRender={customRowRender}
                                    editField={EDIT_FIELD}
                                    groupable={true}
                                    {...dataState}
                                    expandField="expanded"
                                    onExpandChange={onExpandChange}
                                    navigatable={true}
                                >
                                    <GridToolbar>
                                        <button
                                            title={buttonDisplay}
                                            className="app-btn app-btn-primary modal-btn footer-btn k-button-packing"
                                            onClick={saveChanges}
                                            disabled={!changes || buttonDisplay !== 'Save Changes'}
                                        >
                                            {buttonDisplay}
                                        </button>
                                        <button
                                            title="Cancel Changes"
                                            className="app-btn app-btn-secondary modal-btn footer-btn k-button-packing"
                                            onClick={cancelChanges}
                                            disabled={!changes}
                                        >
                                            Cancel Changes
                                        </button>
                                    </GridToolbar>
                                    <Column
                                        field="colour"
                                        title="Colour"
                                        editable={false}
                                        className="hub-tx-mono grid-cell-center"
                                        headerCell={centerHeaderCell}
                                    />
                                    <Column
                                        field="size"
                                        title="Size"
                                        editable={false}
                                        className="hub-tx-mono grid-cell-center"
                                        headerCell={centerHeaderCell}
                                    />
                                    <Column
                                        field="requested"
                                        title="Requested"
                                        editable={false}
                                        className="hub-tx-mono grid-cell-center"
                                        headerCell={centerHeaderCell}
                                    />
                                    <Column
                                        field="packed"
                                        title="Pack"
                                        editable={true}
                                        editor="numeric"
                                        className="hub-tx-mono grid-cell-center"
                                        headerCell={centerHeaderCell}
                                    />
                                </Grid>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => {
                                    cancelChanges();
                                    setModalView(false);
                                }}
                            >
                                <span className="button-text footer-button-text">Close</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
