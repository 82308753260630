import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';

import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    fetchClient,
    fetchClientSuccess,
    fetchClientFailed,
    fetchBlp,
    fetchBlpSuccess,
    fetchBlpFailed,
    saveAoSuccess,
    saveAoFailed,
    saveAo,
    fetchHeader,
    fetchHeaderSuccess,
    fetchHeaderFailed,
} from '../../reducers/allocationOrder/addAOReducer';
import {
    getClientData,
    getBLPsData,
    getHeaderData,
    saveAoData,
    // getCancelledReasonData,
} from '../../../services/apis/allocationOrder/addAoReducerApi';

function* getClient({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getClientData, payload);
        yield put({ type: fetchClientSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchClientFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* getBLPs({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getBLPsData, payload);
        yield put({ type: fetchBlpSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchBlpFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* createAo({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(saveAoData, payload);
        yield put({ type: saveAoSuccess, payload: response });
    } catch (error) {
        yield put({ type: saveAoFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* getHeader({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(getHeaderData, payload);
        yield put({ type: fetchHeaderSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchHeaderFailed, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// function* getcancelledReason({ payload }: { payload: any; type: any }) {
//     try {
//         const response: AxiosResponse = yield call(getCancelledReasonData, payload);
//         payload['cancelledReason'] = response;
//         yield put({ type: fetchCancelReason, payload });
//     } catch (error) {
//         console.log('error', error);
//     }
// }

function* addPoSaga() {
    yield takeEvery(fetchClient, getClient);
    yield takeEvery(fetchBlp, getBLPs);
    yield takeEvery(saveAo, createAo);
    yield takeEvery(fetchHeader, getHeader);
    // yield takeEvery(fetchCancelReason, getcancelledReason);
}

export default addPoSaga;
