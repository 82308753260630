import { Button, Card } from 'semantic-ui-react';
import React, { useEffect, useState, useRef } from 'react';
import {
    Grid,
    GridColumn as Column,
    GridSelectionChangeEvent,
    GridHeaderSelectionChangeEvent,
    getSelectedState,
    GridToolbar,
    GridHeaderCellProps,
} from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useAppDispatch } from 'src/hooks';
import { deleteContainerPacking } from 'src/store/reducers/purchaseOrder/vendorPortalReducer';
import { useParams } from 'react-router-dom';
import UnpackSingleConfirmModel from './unpackConfirmModel';
import UnpackListOfContainersConfirmModel from './unpackListOfContainersModel';

interface GridProps {
    selectedContainerId: string[];
    selectedState: {
        [id: string]: boolean | number[];
    };
    setSelectedState: (value: any) => void;
    dataState: any;
    isFinalised: boolean;
}

const Index: React.FC<GridProps> = ({
    selectedContainerId,
    selectedState,
    setSelectedState,
    dataState,
    isFinalised,
}) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const { packingStatus } = useSelector((state: RootState) => state.vendorPortalData);

    const [unpackId, setUnpackId] = useState('');
    const [showSingleUnpackConfirmModel, setShowSingleUnpackConfirmModel] = useState(false);
    const [showGroupUnpackConfirmModel, setShowGroupUnpackConfirmModel] = useState(false);

    // SSCC Grid Unique Identifier
    const DATA_ITEM_KEY: string = 'id';
    const SELECTED_FIELD: string = 'selected';
    const idGetter = getter(DATA_ITEM_KEY);

    const rowRender = (row: any, props: any) => {
        // if sscc is already in use change the background colour
        let inUse = props.dataItem.inUse;
        let poNumber = props.dataItem.poNumber;

        if (inUse) {
            if (poNumber === packingStatus[0].purchaseOrderNumber)
                return (
                    <tr
                        {...row.props}
                        style={{ ...row.props.style }}
                        className="k-master-row k-alt hub-status-bg-amber"
                    ></tr>
                );
            else
                return (
                    <tr
                        {...row.props}
                        style={{ ...row.props.style }}
                        className="k-master-row k-alt hub-status-bg-red"
                    ></tr>
                );
        } else return <tr {...row.props}></tr>;
    };

    const onSelectionChange = React.useCallback(
        // kendo grid function, on selection change, refresh selected state
        (event: GridSelectionChangeEvent) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = React.useCallback((event: GridHeaderSelectionChangeEvent) => {
        // Kendo Grid Function - Tick All Boxes
        const checkboxElement: any = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {} as any;

        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const checkSelectionsAllPacked = () => {
        // vaidate if selected sscc are already in use
        if (!selectedContainerId || selectedContainerId?.length === 0) {
            return false;
        }

        for (let index = 0; index < selectedContainerId.length; index++) {
            const data = dataState.find((element: any) => {
                return element.id === selectedContainerId[index];
            });
            if (!data) return false;
            if (!data?.inUse) return false;
        }
        return true;
    };

    const checkSelectionHasOnlyCurrentPo = () => {
        // check if selected only contains the current PO number
        if (!selectedContainerId || selectedContainerId?.length === 0) {
            return false;
        }

        for (let index = 0; index < selectedContainerId.length; index++) {
            const data = dataState.find((element: any) => {
                return element.id === selectedContainerId[index];
            });
            if (!data) return false;
            if (data.poNumber !== packingStatus[0].purchaseOrderNumber) return false;
        }
        return true;
    };

    const unpackGroupOfContainers = () => {
        // unpack a group of containers by click on unpack button at the top level of the SSCC grid
        // set button to show 'Unpacking...' and disable the button
        const containerIdArray = selectedContainerId.map((element) => {
            return { container_id: element };
        });

        dispatch(
            deleteContainerPacking({
                payload: {
                    containers: containerIdArray,
                },
                poPk: id,
            })
        );
    };

    const unpackOneContainer = (containerId: string) => {
        // unpack one container by click on unpack button in SSCC grid
        // set button to show 'Unpacking...' and disable the button
        dispatch(
            deleteContainerPacking({
                payload: {
                    containers: [
                        {
                            container_id: containerId,
                        },
                    ],
                },
                poPk: id,
            })
        );
    };

    // if the sscc is already in use, the unpack button will show in the grid
    const UnpackCell = (props: any) => (
        <td>
            {props.dataItem.inUse ? (
                <div className="sscc-unpack-btn">
                    <Button
                        primary
                        disabled={isFinalised || packingStatus[0].purchaseOrderNumber !== props.dataItem.poNumber}
                        onClick={() => {
                            setUnpackId(props.dataItem.id);
                            setShowSingleUnpackConfirmModel(true);
                        }}
                    >
                        Unpack
                    </Button>
                </div>
            ) : (
                <></>
            )}
        </td>
    );

    const centerHeaderCell = (props: GridHeaderCellProps) => {
        return (
            <a className="k-link">
                <span style={{ textAlign: 'center' }}>{props.title}</span>
                {props.children}
            </a>
        );
    };

    return (
        <Card className="single_card_grid sscc_grid">
            {dataState?.length > 0 ? (
                <Grid
                    rowRender={rowRender}
                    data={dataState.map((item: any) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                    }))}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{ enabled: false, drag: false, cell: false, mode: 'multiple' }}
                    onSelectionChange={onSelectionChange}
                    onHeaderSelectionChange={onHeaderSelectionChange}
                >
                    <GridToolbar>
                        <button
                            disabled={!checkSelectionsAllPacked() || isFinalised || !checkSelectionHasOnlyCurrentPo()}
                            className="app-btn app-btn-primary modal-btn form-btn"
                            title="Submit"
                            onClick={() => {
                                setShowGroupUnpackConfirmModel(true);
                            }}
                        >
                            <span className="button-text footer-button-text">Unpack</span>
                        </button>
                    </GridToolbar>
                    <Column
                        field={SELECTED_FIELD}
                        width="50rem"
                        headerSelectionValue={dataState.findIndex((item: any) => !selectedState[idGetter(item)]) === -1}
                        className="hub-tx-mono grid-cell-center"
                    />
                    <Column
                        field="id"
                        title="Container Number"
                        width="200rem"
                        className="hub-tx-mono grid-cell-center"
                        headerCell={centerHeaderCell}
                    />
                    <Column
                        field="prefix"
                        title="Prefix"
                        width="130rem"
                        className="hub-tx-mono grid-cell-center"
                        headerCell={centerHeaderCell}
                    />
                    <Column
                        field="serial"
                        title="Sequence"
                        width="130rem"
                        className="hub-tx-mono grid-cell-center"
                        headerCell={centerHeaderCell}
                    />
                    <Column
                        field="checkDigit"
                        title="Check Digit"
                        width="130rem"
                        className="hub-tx-mono grid-cell-center"
                        headerCell={centerHeaderCell}
                    />
                    <Column
                        field="poNumber"
                        title="PO Number"
                        width="130rem"
                        className="hub-tx-mono grid-cell-center"
                        headerCell={centerHeaderCell}
                    />
                    <Column
                        title="Action"
                        cell={UnpackCell}
                        width="140rem"
                        className="hub-tx-mono grid-cell-center"
                        headerCell={centerHeaderCell}
                    />
                </Grid>
            ) : (
                <Grid></Grid>
            )}
            {showGroupUnpackConfirmModel && (
                <UnpackListOfContainersConfirmModel
                    modalView={showGroupUnpackConfirmModel}
                    setModalView={setShowGroupUnpackConfirmModel}
                    unpackGroupOfContainers={unpackGroupOfContainers}
                />
            )}
            {showSingleUnpackConfirmModel && (
                <UnpackSingleConfirmModel
                    modalView={showSingleUnpackConfirmModel}
                    setModalView={setShowSingleUnpackConfirmModel}
                    unpackOneContainer={unpackOneContainer}
                    id={unpackId}
                />
            )}
        </Card>
    );
};

export default Index;
