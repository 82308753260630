import React, { ReactElement, useEffect, useState } from 'react';
import LineGraph from '../../../../components/common/LineGraph';
import BSTooltip from 'src/components/common/BSTooltip';
import FavouritesSidepanel from './FavouritesSidepanel';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import {
    capitalizeFirstLetter,
    favouriteStatusMapping,
    favouriteTypeMapping,
    getDynamicFilterKey,
    navigationObj,
    typeOfFav,
} from '../common';
import { setFavGroupData, setGraphData, setSelectedFav } from 'src/store/reducers/dashboard/insightsFavoritesReducer';
import { makeApiCall } from '../makeApiCall';
import { FilterDestinationTypeList, FilterModeList, Type, TypeOfBooking } from '../enums';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    setAllFavFilterData,
    // setInsightsForwarded,
    setInsightsFavMainPage,
    setInsightsSelectedType,
} from 'src/store/reducers/dashboard/insightsReducer';
import { setTodoType } from 'src/store/reducers/dashboard/dashboardTodoReducer';

interface FavProps {
    hideReportIcon?: boolean;
    insightTab?: string;
}

const modeMapping = {
    isAir: FilterModeList.Air,
    isLand: FilterModeList.Land,
    isSea: FilterModeList.Sea,
    isLcl: FilterModeList.Lcl,
    isFcl: FilterModeList.Fcl,
    isBcn: FilterModeList.Bcn,
} as any;

const destinationMapping = {
    isInternational: FilterDestinationTypeList.International,
    isTriangle: FilterDestinationTypeList.Triangle,
} as any;

const filterMappingCaps = {
    isAir: capitalizeFirstLetter(FilterModeList.Air),
    isLand: capitalizeFirstLetter(FilterModeList.Land),
    isSea: capitalizeFirstLetter(FilterModeList.Sea),
    isLcl: FilterModeList.Lcl.toLocaleUpperCase(),
    isFcl: FilterModeList.Fcl.toLocaleUpperCase(),
    isBcn: FilterModeList.Bcn.toLocaleUpperCase(),
    isInternational: FilterDestinationTypeList.International,
    isTriangle: FilterDestinationTypeList.Triangle,
} as any;

const favouriteMapping = {
    'International POs': Type.itpo,
    'Active Orders': Type.all,
    'Inbound POs': Type.ibpo,
    'Allocation Orders': Type.ao,
    'AO Bookings': TypeOfBooking.aoBooking,
    'PO Bookings': TypeOfBooking.poBooking,
} as any;

const Index: React.FC<FavProps> = ({ hideReportIcon }): ReactElement => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation() as any;
    const [favSettings, setFavSettings] = useState(false);
    const { favourites, groupFavouriteData, selectedFav } = useSelector((state: RootState) => state.favourites);
    const { insightsDurationRange, insightTab } = useSelector((state: RootState) => state.dashboardInsights);
    const { poFilter, inboundPoFilter } = useSelector((state: RootState) => state.purchaseOrderList);
    const { filter: aoFilter } = useSelector((state: RootState) => state.allocationOrderList);
    const { filter: aoBookingFilter } = useSelector((state: RootState) => state.aoBookingProposalList);
    const { filter: poBookingFilter } = useSelector((state: RootState) => state.bookingProposalList);
    const { filter: airPoBookingFilter } = useSelector((state: RootState) => state.bookingProposalAirList);
    const pathUrl = window.location.pathname;
    const filters = {
        poFilter,
        inboundPoFilter,
        aoFilter,
        aoBookingFilter,
        poBookingFilter,
        airPoBookingFilter,
    } as any;

    const getResultData = (fav: any) => {
        const key = getDynamicFilterKey(Object.entries(fav), fav.status, fav.type, fav.favourite);
        return (
            groupFavouriteData[insightsDurationRange] &&
            Object.keys(groupFavouriteData[insightsDurationRange]).length &&
            groupFavouriteData[insightsDurationRange][key] &&
            groupFavouriteData[insightsDurationRange][key]?.resultData &&
            groupFavouriteData[insightsDurationRange][key]?.resultData[
                fav.status ? favouriteStatusMapping[fav.status] : favouriteTypeMapping[fav.favourite]
            ]
        );
    };

    const isEmpty = (obj: any) => {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }

        return true;
    };

    const generateGraphPoints = (fav: any, showPointName: boolean) => {
        const resultDataSet = getResultData(fav);
        if (!isEmpty(resultDataSet)) {
            const entry = Object.entries(resultDataSet);
            entry.sort((a: any, b: any) => parseInt(a[0].slice(3)) - parseInt(b[0].slice(3)));
            const result = entry
                .filter((item) => item[0] !== 'count')
                .map((item: any, index: number) => [
                    showPointName ? item[1].pointName : index?.toString(),
                    item[1].count,
                    item[1].dateFrom,
                    item[1].dateTo,
                    item[1].pointName,
                ]);
            return result;
        }
    };

    const setSelectedFavourite = (data: any) => {
        const graphData = generateGraphPoints(data, true);
        dispatch(setGraphData(graphData));
        const favInsightsMode = [];
        const favInsightsDestination = [];
        const favInsightsForwarded = data.type === 'po' ? 'active_orders' : 'active_bookings';
        let favInsightsSelectedType = '';
        let favInsightsSelectedTypeFilter = '';
        if (data.isSea) {
            favInsightsMode.push('sea');
        }
        if (data.isAir) {
            favInsightsMode.push('air');
        }
        if (data.isLand) {
            favInsightsMode.push('land');
        }
        // if (data.isAo) {
        //     favInsightsMode.push('AO');
        // }
        if (data.isLcl) {
            favInsightsMode.push('lcl');
        }
        if (data.isBcn) {
            favInsightsMode.push('bcn');
        }
        if (data.isFcl) {
            favInsightsMode.push('fcl');
        }
        if (data.isInternational) {
            favInsightsDestination.push('International');
        }
        if (data.isTriangle) {
            favInsightsDestination.push('Triangle');
        }
        if (!(data.isItpo && data.isIbpo && data.isAo)) {
            if (data.isItpo) {
                favInsightsSelectedType = 'ITPO';
            }
            if (data.isIbpo) {
                favInsightsSelectedType = 'IBPO';
            }
            if (data.isAo) {
                favInsightsSelectedType = 'AO';
            }
        }
        if (data.type === 'bp') {
            if (data.favourite?.startsWith('PO')) {
                favInsightsSelectedType = 'po';
            } else if (data.favourite?.startsWith('AO')) {
                favInsightsSelectedType = 'AO';
            }
        }
        if (data.status) {
            favInsightsSelectedTypeFilter = favouriteStatusMapping[data.status];
        }
        if (data.favourite?.trim() === 'Active Orders' || data.favourite?.trim() === 'Active Bookings') {
            //dispatch(setInsightsForwarded(favInsightsForwarded));//removed it as insights does not need to be set in here, only favorites
            dispatch(setInsightsFavMainPage(data.favourite?.trim()));
            dispatch(setInsightsSelectedType(''));
        } else {
            dispatch(setInsightsFavMainPage(''));
        }
        dispatch(
            setAllFavFilterData({
                favInsightsMode,
                favInsightsDestination,
                favInsightsForwarded,
                favInsightsSelectedType,
                favInsightsSelectedTypeFilter,
                insightsFavMainPage: data.favourite?.trim(),
            })
        );
        dispatch(setSelectedFav(data.id));
    };

    useEffect(() => {
        if (favourites?.length && getResultData(favourites[0])?.count) {
            if (selectedFav && (location as any).state?.persistFavSelection === true) {
                const foundFav = favourites.find((x: any) => x.id === selectedFav);
                if (foundFav) {
                    setSelectedFavourite(foundFav);
                }
            } else {
                if (window.location.pathname !== '/dashboard/to-do-activity-tabs') {
                    setSelectedFavourite(favourites[0]);
                }
            }
        } else if (favourites?.length === 0) {
            dispatch(
                setAllFavFilterData({
                    favInsightsMode: [],
                    favInsightsDestination: [],
                    favInsightsForwarded: '',
                    favInsightsSelectedType: '',
                    favInsightsSelectedTypeFilter: '',
                    insightsFavMainPage: '',
                })
            );
        }
        return () => {
            if (favourites?.length && getResultData(favourites[0])?.count) {
                if (
                    window.location.pathname !== '/dashboard/to-do-activity-tabs' &&
                    !window.location.pathname.includes('/dashboard/to-do/') &&
                    !window.location.pathname.includes('/dashboard/activity-feed/')
                ) {
                    setSelectedFavourite(favourites[0]);
                }
            }
        };
    }, [groupFavouriteData[insightsDurationRange], favourites, insightTab, location.state]);

    const favGroupAccordingToFilter = () => {
        const resultObject = {} as any;
        for (const fav of favourites) {
            const entry = Object.entries(fav);
            const key = getDynamicFilterKey(entry, fav.status, fav.type, fav.favourite);
            if (resultObject[key]) {
                resultObject[key].ids.push(fav.id);
            } else {
                const mode = entry
                    .filter((filterItem) => modeMapping.hasOwnProperty(filterItem[0]) && filterItem[1] === true)
                    .map((item) => modeMapping[item[0]]);
                const destinationType = entry
                    .filter((filterItem) => destinationMapping.hasOwnProperty(filterItem[0]) && filterItem[1] === true)
                    .map((item) => destinationMapping[item[0]]);
                resultObject[key] = {
                    ids: [fav.id],
                };
                if (destinationType.length) {
                    resultObject[key].destinationType = destinationType;
                }
                if (mode.length) {
                    resultObject[key].mode = mode;
                }
                if (key.startsWith('typed')) {
                    resultObject[key].type = favouriteMapping[fav.favourite];
                }
            }
        }
        dispatch(setFavGroupData({ resultObject, insightsDurationRange }));
        for (const key in resultObject) {
            if (key.endsWith(typeOfFav.activeOrder)) {
                if (Object.prototype.hasOwnProperty.call(resultObject, key)) {
                    makeApiCall(
                        dispatch,
                        resultObject[key].mode || [],
                        insightsDurationRange,
                        resultObject[key].destinationType || [],
                        key.startsWith('typed') ? 'activeOrdersDetails' : 'activeOrder',
                        resultObject[key].type ? resultObject[key].type : '',
                        key
                    );
                }
            } else if (key.endsWith(TypeOfBooking.aoBooking) || key.endsWith(TypeOfBooking.poBooking)) {
                if (Object.prototype.hasOwnProperty.call(resultObject, key)) {
                    makeApiCall(
                        dispatch,
                        resultObject[key].mode || [],
                        insightsDurationRange,
                        resultObject[key].destinationType || [],
                        key.startsWith('typed') ? 'activeBookingsDetails' : 'activeBooking',
                        key.endsWith(TypeOfBooking.aoBooking) ? 'AO' : 'po',
                        key
                    );
                }
            }
        }
    };

    useEffect(() => {
        if (
            favourites?.length &&
            ((groupFavouriteData[insightsDurationRange] &&
                !Object.keys(groupFavouriteData[insightsDurationRange]).length) ||
                location?.state?.dashboardEntered)
        ) {
            location.state = null;
            favGroupAccordingToFilter();
        }
    }, [groupFavouriteData, insightsDurationRange, favourites]);

    //Format booleans in 'element' object into capitalised filter item arrays
    const getFilterArray = (element: any, filterList: string[]) => {
        const arrayRef = [];
        for (const key in element) {
            if (Object.prototype.hasOwnProperty.call(element, key)) {
                if (filterList.includes(key) && element[key]) {
                    arrayRef.push(filterMappingCaps[key]);
                }
            }
        }
        return arrayRef;
    };

    const redirectFromFavourite = (element: any) => {
        const {
            navigationPath,
            setBookingList,
            destinationTypeFilterList,
            shippingModeFilterList,
            modeFilterKey,
            Air,
            activeKey,
            activeStatusKey,
            statusKey,
        } = navigationObj[element.favourite];
        let { bookingType, filterName, setFilter, setDropDown } = navigationObj[element.favourite];

        if (Air && getFilterArray(element, ['isAir']).length) {
            ({ bookingType, filterName, setFilter, setDropDown } = Air);
        }

        // List of mode/destination strings to send as filter to redirected page
        const shippingmode = getFilterArray(element, shippingModeFilterList);
        const destinationType = getFilterArray(element, destinationTypeFilterList);

        const filterObj = {
            ...filters[filterName],
        } as any;

        if (shippingmode.length) {
            filterObj[modeFilterKey] = shippingmode;
        }
        if (destinationType.length) {
            filterObj.destinationType = destinationType;
        }
        if (setBookingList) {
            dispatch(setBookingList(bookingType));
        }
        if (element.status) {
            if (element.status === 'Total') {
                filterObj[statusKey] = activeStatusKey;
                dispatch(
                    setDropDown({
                        text: activeKey,
                        count: getResultData(element)?.count,
                    })
                );
            } else {
                filterObj[statusKey] = element.status;
                dispatch(
                    setDropDown({
                        text: element.status,
                        count: getResultData(element)?.count,
                    })
                );
            }
        } else {
            filterObj[statusKey] = activeStatusKey;
            dispatch(
                setDropDown({
                    text: activeKey,
                    count: getResultData(element)?.count,
                })
            );
        }
        dispatch(setFilter({ ...filterObj, bpType: shippingmode }));
        // dispatch(setFilter(filterObj));
        navigate(navigationPath, { state: { reload: true } });
    };
    const getFavoriteTooltipTitle = (title: any) => {
        switch (title) {
            case 'International POs':
            case 'Inbound POs':
            case 'Allocation Orders':
                return 'Go to Orders Page';

            case 'AO Bookings':
            case 'PO Bookings':
                return 'Go to Bookings Page';

            default:
                break;
        }
    };
    return (
        <div className="fav-container">
            {favourites?.length && pathUrl === '/' ? (
                <button
                    className="action-btn app-btn app-btn-secondary setting-btn"
                    onClick={(e) => {
                        e.stopPropagation();
                        setFavSettings(true);
                    }}
                >
                    <svg className="svg-icon setting-icon">
                        <use xlinkHref="#settingIcon">
                            <title>Settings</title>
                        </use>
                    </svg>
                </button>
            ) : null}

            <div className="favorite-wrapper customscrollbar">
                <div className="fav-wrapper ">
                    {favourites?.length === 0 && (
                        <div className="no-data-wrapper">
                            <div className="no-data-content">
                                <div className="no-data-circle">
                                    <svg className="svg-icon grid-no-content-icon">
                                        <use xlinkHref="#noFavIcon">
                                            <title>Favourites</title>
                                        </use>
                                    </svg>
                                </div>
                                <p className="no-content-message">No Favourites added</p>
                                <p className="no-content-message-caption">
                                    Insights that are added to favourites will be listed here
                                </p>
                            </div>
                        </div>
                    )}
                    {groupFavouriteData[insightsDurationRange] &&
                        favourites?.map((element: any, index: any) => {
                            return (
                                <a
                                    className={`fav-card ${selectedFav === element.id ? 'active' : ''}`}
                                    key={`fav-wrapper-card__${element.id}`}
                                    onClick={() => {
                                        setSelectedFavourite(element);
                                        dispatch(setTodoType({ type: null }));
                                    }}
                                >
                                    <div className="fav-header">
                                        {/* <BSTooltip
                                            id={`fav-wrapper-card__${index}_tooltip`}
                                            title={`${element.favourite} ${element.status ? '/' : ''} ${
                                                element.status || ''
                                            }`}
                                            position="top"
                                        ></BSTooltip> */}
                                        <div className="fav-h-wrapper">
                                            <h4>{element.favourite}</h4>
                                            {element.status ? <span className="status">{element.status}</span> : ''}
                                        </div>
                                        <div className="fav-modes">
                                            {element.isSea ? (
                                                <button className="action-btn app-btn" title="Sea">
                                                    <svg className="svg-icon ship-icon">
                                                        <use href="#bySeaIcon">
                                                            <title>Sea</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                            {element.isAir ? (
                                                <button className="action-btn app-btn" title="Air">
                                                    <svg className="svg-icon aeroplane-icon">
                                                        <use xlinkHref="#byAirIcon">
                                                            <title>Air</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                            {element.isLand ? (
                                                <button className="action-btn app-btn" title="Land">
                                                    <svg className="svg-icon setting-icon">
                                                        <use xlinkHref="#byLandIcon">
                                                            <title>Land</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                            {element.isInternational ? (
                                                <button className="action-btn app-btn" title="International">
                                                    <svg className="svg-icon setting-icon">
                                                        <use xlinkHref="#globeIcon">
                                                            <title>International</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                            {element.isTriangle ? (
                                                <button className="action-btn app-btn" title="Triangle">
                                                    <svg className="svg-icon setting-icon">
                                                        <use xlinkHref="#triangleIcon">
                                                            <title>Triangle</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            ) : null}
                                            {element.isLcl ? (
                                                <button className="action-btn app-btn" title="LCL">
                                                    <span className="btn-text">LCL</span>
                                                </button>
                                            ) : null}
                                            {element.isFcl ? (
                                                <button className="action-btn app-btn" title="FCL">
                                                    <span className="btn-text">FCL</span>
                                                </button>
                                            ) : null}
                                            {element.isBcn ? (
                                                <button className="action-btn app-btn" title="BCN">
                                                    <span className="btn-text">BCN</span>
                                                </button>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="fav-details">
                                        <div className="fav-count">{getResultData(element)?.count}</div>
                                        <div className="graph-holder-mini">
                                            {getResultData(element)?.count >= 0 ? (
                                                <LineGraph
                                                    drawn={generateGraphPoints(element, false)}
                                                    name={`favActiveOrders_${element.id}`}
                                                    tooltip={true}
                                                    lineDots={true}
                                                    lineWidth={0.5}
                                                    pointSize={2.5}
                                                />
                                            ) : null}
                                        </div>
                                        <div className="graph-action">
                                            {!hideReportIcon && (
                                                <BSTooltip
                                                    id={`reports${index}`}
                                                    title="Go to Reports"
                                                    position="bottom"
                                                >
                                                    <button className="action-btn app-btn icon-only sm app-btn-secondary">
                                                        <svg className="svg-icon">
                                                            <use xlinkHref="#fileIcon">
                                                                <title>Go to Reports</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </BSTooltip>
                                            )}
                                            {element.favourite !== 'Active Bookings' &&
                                            element.favourite !== 'Active Orders' ? (
                                                <BSTooltip
                                                    id={`gotoorderspage${index}`}
                                                    // title="Go to Orders Page123"
                                                    title={() => getFavoriteTooltipTitle(element.favourite)}
                                                    position="bottom"
                                                >
                                                    <button
                                                        className="action-btn app-btn icon-only sm app-btn-secondary"
                                                        onClick={() => {
                                                            redirectFromFavourite(element);
                                                        }}
                                                    >
                                                        <svg className="svg-icon">
                                                            <use xlinkHref="#redirectIcon">
                                                                <title>Redirect</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </BSTooltip>
                                            ) : null}
                                        </div>
                                    </div>
                                </a>
                            );
                        })}
                </div>
                <FavouritesSidepanel modalHandle={setFavSettings} data={favourites} modalOpen={favSettings} />
            </div>
        </div>
    );
};
export default Index;
