import { get, post } from '../../HttpMethods';

export const getAvailablePoLinesLcl = async (payload: { cgId: string }) => {
    try {
        const res = await get(`api/bkg/bp/confirmationgroups/polines?cgId=${payload.cgId}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const searchAvailablePoLinesLcl = async (payload: { cgId: string; keyword: string }) => {
    try {
        const res = await post(
            `api/ord/search/availablepolines?keyword=${payload.keyword}&cgId=${payload.cgId}`,
            null,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
