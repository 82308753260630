import moment from 'moment';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'src/store/reducers';
import { discardConfirmationGroup, resetDiscardFlags } from 'src/store/reducers/purchaseOrder/containerModePoReducer';
import { error, Position, success } from 'src/utils/toast';
interface DiscardProps {
    setShowDiscardModal: any;
    confirmationGroup: any;
}
const Index = ({ setShowDiscardModal, confirmationGroup }: DiscardProps): ReactElement => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [reason, setReason] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { isDiscardError, isDiscardSuccess, discardError } = useSelector(
        (state: RootState) => state.containerModePoData
    );
    const { id } = useParams();

    const confirmDiscard = () => {
        dispatch(discardConfirmationGroup({ cgId: confirmationGroup?.confirmationGroupId, reason }));
        setLoading(true);
    };

    useEffect(() => {
        if (isDiscardError) {
            setLoading(false);
            dispatch(resetDiscardFlags({}));
            error(discardError?.data?.data || 'Failed to discard confirmation group', Position.TOP_RIGHT);
        }
    }, [isDiscardError]);

    useEffect(() => {
        if (isDiscardSuccess) {
            setLoading(false);
            dispatch(resetDiscardFlags({}));
            setShowDiscardModal(false);
            success('Confirmation Group discarded successfully', Position.TOP_RIGHT);
            navigate(`/purchase_order/${id}/container_mode_selection`);
        }
    }, [isDiscardSuccess]);

    return (
        <div className="app-modal container-add-modal">
            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                <svg className="svg-icon loader-icon">
                    <use xlinkHref="#loaderIcon">
                        <title>Loading</title>
                    </use>
                </svg>
            </div>
            <div className="modal-content-holder medium-popup">
                <div className="modal-header">
                    <div className="title">Discard Confirmation Group</div>
                    <button
                        className="app-btn modal-close-btn"
                        type="button"
                        onClick={() => {
                            setShowDiscardModal(false);
                        }}
                    >
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <div className="modal-main-content container-add-modal -discard-modal">
                        <div className="notification-section information-notice">
                            <svg className="svg-icon info-icon">
                                <use xlinkHref="#infoIconRound">
                                    <title>Information</title>
                                </use>
                            </svg>
                            <span>
                                Once discarded, all confirmed lines in this group will reverted to Pending Confirmation
                                status and needs to be confirmed again to be available for Booking
                            </span>
                        </div>
                        <div className="modal-form layout-holder two-column">
                            <div className="modal-input-holder">
                                <label className="modal-input-label">Destination</label>
                                {/* <div className="modal-input-layout-item">
                                    {confirmationGroup?.destinationPortName &&
                                        `${confirmationGroup?.destinationPortName}`}
                                </div> */}
                                <div className="modal-input-layout-item">
                                    {confirmationGroup?.destinationPortName &&
                                        `${confirmationGroup?.destinationPortName}${
                                            confirmationGroup?.destinationPort
                                                ? `(${confirmationGroup?.destinationPort})`
                                                : ''
                                        }`}
                                </div>
                            </div>
                            <div className="modal-input-holder">
                                <label className="modal-input-label">Goods Ready Date</label>
                                <div className="modal-input-layout-item">
                                    {confirmationGroup?.goodsReadyDate &&
                                        moment(confirmationGroup?.goodsReadyDate).format('DD-MM-YYYY')}
                                </div>
                            </div>
                            <div className="modal-input-holder">
                                <label className="modal-input-label">Order Lines</label>
                                <div className="modal-input-layout-item">{confirmationGroup?.poLines}</div>
                            </div>
                            <div className="modal-input-holder">
                                <label className="modal-input-label">Total CBM</label>
                                <div className="modal-input-layout-item">{confirmationGroup?.cbm}</div>
                            </div>
                            <div className="modal-input-holder extend-2">
                                <label className="modal-input-label">
                                    Reason <span className="mandatory-field-text">*</span>
                                </label>
                                <div className="modal-input-layout-item">
                                    <textarea
                                        name="reason"
                                        className="input-text"
                                        value={reason}
                                        onChange={(e: any) => {
                                            setReason(e.target.value);
                                        }}
                                        placeholder="Enter the reason to discard confirmation group"
                                        maxLength={250}
                                    ></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-secondary modal-btn footer-btn footer-btn-container"
                        title="Back"
                        type="button"
                        onClick={() => {
                            setShowDiscardModal(false);
                        }}
                    >
                        <span className="button-text footer-button-text">Back</span>
                    </button>
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn footer-btn-container"
                        type="button"
                        title="Discard Group"
                        disabled={!reason?.trim()?.length}
                        onClick={() => confirmDiscard()}
                    >
                        <span className="button-text footer-button-text">Discard Group</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
