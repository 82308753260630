import { put, call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    addToBp,
    changeSailingStatus,
    removeSailingStatus,
    checkContractData,
    checkContractResponse,
    contractLoaded,
    fakListCompletedFn,
    fetchExistingSchedules,
    fetchSailingData,
    fetchSelectedSailing,
    getFakData,
    getSailingCounts,
    isLoadingFn,
    isLoadingFn2,
    listLoadingFn,
    removeSailing,
    sailingUpdated,
    saveExistingSchedules,
    saveFAKdata,
    // savePortValues,
    saveSailingcount,
    saveSailingData,
    viewSelectedSailing,
    saveTransportSchedule,
    getTransportSchedule,
    updateTransportSchedule,
} from '../../reducers/bookingProposalLand/sailingReducer';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    contractCheck,
    editTransportScheduleApi,
    fakData,
    getExistingSchedules,
    getTransportScheduleApi,
    // getPorts,
    removeSailingData,
    sailingAddToBp,
    sailingCount,
    sailingData,
    selectedSalilingData,
} from '../../../services/apis/bookingProposalLand/sailingApis';
import { AxiosResponse } from 'axios';

function* getCheckContract({ payload }: { payload: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put({ type: contractLoaded, payload: false });
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(contractCheck, payload);
        yield put({ type: checkContractResponse, payload: resultSailing });
    } catch (er) {
        yield put({ type: isLoadingFn, payload: false });
        yield put({ type: isLoadingFn2, payload: false });
    } finally {
        //yield put({ type: isLoadingFn, payload: false });
        yield put({ type: contractLoaded, payload: true });
        yield put(hideLoading());
    }
}
function* getCountSailing({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        //yield put({ type: isLoadingFn, payload: true });
        const resultSailing: AxiosResponse<any> = yield call(sailingCount, payload);
        yield put({ type: saveSailingcount, payload: resultSailing });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
    }
}
function* getfakData({ payload }: { payload: any }) {
    try {
        yield put({ type: fakListCompletedFn, payload: false });
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(fakData, payload);
        yield put({ type: saveFAKdata, payload: resultSailing });
    } finally {
        //yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
        yield put({ type: fakListCompletedFn, payload: true });
    }
}
function* getSailingData({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        yield put({ type: listLoadingFn, payload: true });
        const resultSailing: AxiosResponse<any> = yield call(sailingData, payload);
        yield put({ type: saveSailingData, payload: resultSailing });
    } finally {
        if (localStorage.getItem('itemSelectedSailing') === 'false') {
            yield put({ type: isLoadingFn, payload: false });
        }
        yield put({ type: changeSailingStatus, payload: false });
        yield put(hideLoading());
        yield put({ type: listLoadingFn, payload: false });
        //yield put({ type: isLoadingFn, payload: false });
    }
}
function* getSelectedSailing({ payload }: { payload: any }) {
    try {
        yield put({ type: isLoadingFn2, payload: true });
        yield put(showLoading());
        const resultSailing: AxiosResponse<any> = yield call(selectedSalilingData, payload);
        const result = resultSailing;
        yield put({ type: viewSelectedSailing, payload: result });
    } finally {
        yield put({ type: isLoadingFn2, payload: false });
        if (localStorage.getItem('itemSelectedSailing') !== 'false') {
            yield put({ type: isLoadingFn, payload: false });
        }
        yield put(hideLoading());
    }
}

function* addSailingToList({ payload }: { payload: any }) {
    try {
        yield put({ type: isLoadingFn2, payload: true });
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(sailingAddToBp, payload);
    } finally {
        if (payload['sailingAddRequest']?.isManuallyEntered) {
            yield put({ type: sailingUpdated, payload: true });
        }
        yield put({ type: changeSailingStatus, payload: true });
        //yield put({ type: isLoadingFn, payload: false });
        yield put({ type: isLoadingFn2, payload: false });
        yield put(hideLoading());
    }
}
function* removeSailingFromList({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });
        if (!payload?.softDelete) {
            yield call(removeSailingData, payload);
        }
        yield put({ type: changeSailingStatus, payload: true });
        yield put({ type: removeSailingStatus, payload: true });
    } finally {
        //yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getAllExistingSchedules({ payload }: { payload: any }) {
    try {
        yield put({ type: isLoadingFn2, payload: true });
        yield put(showLoading());
        const schedules: AxiosResponse<any> = yield call(getExistingSchedules, payload);
        yield put({ type: saveExistingSchedules, payload: schedules });
    } finally {
        yield put({ type: isLoadingFn2, payload: false });
        yield put(hideLoading());
    }
}

function* _getTransportSchedule({ payload }: { payload: any }) {
    try {
        yield put({ type: isLoadingFn2, payload: true });
        yield put(showLoading());
        const schedules: AxiosResponse<any> = yield call(getTransportScheduleApi, payload);
        yield put({ type: getTransportSchedule, payload: schedules });
    } finally {
        yield put({ type: isLoadingFn2, payload: false });
        yield put(hideLoading());
    }
}

function* _updateTransportSchedule({ payload }: { payload: any }) {
    try {
        yield put(showLoading());
        const schedules: AxiosResponse<any> = yield call(editTransportScheduleApi, payload);
        yield put({ type: saveTransportSchedule, payload: schedules });
    } finally {
        yield put(hideLoading());
    }
}

function* sailingScheduleSaga() {
    yield takeLatest(checkContractData, getCheckContract);
    yield takeLatest(getSailingCounts, getCountSailing);
    yield takeLatest(fetchSailingData, getSailingData);
    yield takeEvery(getTransportSchedule, _getTransportSchedule);
    yield takeEvery(updateTransportSchedule, _updateTransportSchedule);
    yield takeLatest(addToBp, addSailingToList);
    yield takeLatest(fetchSelectedSailing, getSelectedSailing);
    yield takeLatest(removeSailing, removeSailingFromList);
    yield takeLatest(getFakData, getfakData);
    yield takeLatest(fetchExistingSchedules, getAllExistingSchedules);
}

export default sailingScheduleSaga;
