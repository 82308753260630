import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { ReactElement, useState } from 'react';
import BSTooltip from 'src/components/common/BSTooltip';
import CancelModal from 'src/pages/Dashboard/Insights/Favourites/CancelModal';
import { useAppDispatch } from 'src/hooks';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { favourite } from 'src/store/reducers/tracking/trackingInsightsReducer';
import './tracking-favorite-sidebar.scss';
interface BioProps {
    modalHandle: any;
    modalOpen: boolean;
    data: any;
}

const Index: React.FC<BioProps> = ({ data, modalHandle, modalOpen }): ReactElement => {
    const { profileType, userName } = useUserProfile();
    const [dataList, setDataList] = useState<any>(data);
    const [showSave, setShowSave] = useState(false);
    const dispatch = useAppDispatch();
    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        // change background colour if dragging
        backgroundColor: isDragging ? '#F0F1FF' : 'transparent',
        // styles we need to apply on draggables
        boxShadow: isDragging ? '2px 2px 6px 0px rgba(0, 0, 0, 0.25)' : 'none',
        borderRadius: isDragging ? '4px' : '0px',
        margin: '0px 0px',
        // borderTop: isDragging ? 'none' : '1px solid $grey5',
        ...draggableStyle,
    });

    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };
    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }
        const resultData = reorder(dataList, result.source.index, result.destination.index);
        setDataList([...resultData]);
        setShowSave(true);
    }
    const [isCancelled, setIsCancelled] = useState(false);

    const updateFavourite = (item: any) => {
        const favouriteCopy = JSON.parse(JSON.stringify(dataList));
        const itemIndex = favouriteCopy.findIndex((res: any) => res.id === item.id);
        if (favouriteCopy[itemIndex]?.favouriteStatus) {
            favouriteCopy[itemIndex].favouriteStatus = false;
        } else {
            favouriteCopy[itemIndex].favouriteStatus = true;
        }
        setDataList(favouriteCopy);
        setShowSave(true);
    };

    const reset = () => {
        setDataList(data);
        setShowSave(false);
    };

    const onSave = () => {
        const favouriteData = dataList
            .map((res: any) => {
                if (res.favouriteStatus) {
                    return {
                        title: res.title,
                        tab: res.type.name,
                        id: res.id,
                    };
                }
            })
            .filter(Boolean);
        const payload = {
            favourites: JSON.stringify(favouriteData),
            userName,
            type: 'all',
            profile: profileType,
        };
        dispatch(favourite({ ...payload }));
    };

    function removeAllFavourites() {
        let itemIndex: number;
        const favouriteCopy = JSON.parse(JSON.stringify(dataList));
        setShowSave(true);
        //console.log(favouriteCopy.length, 'fav length');

        // const itemIndex = favouriteCopy.findIndex((res: any) => res.id === item.id);
        for (itemIndex = 0; itemIndex < favouriteCopy.length; itemIndex++) {
            if (favouriteCopy[itemIndex]?.favouriteStatus) {
                favouriteCopy[itemIndex].favouriteStatus = false;
            }
        }
        setDataList(favouriteCopy);
    }

    const onDiscardConfirm = () => {
        setIsCancelled(false);
        setShowSave(false);
        modalHandle(false);
    };

    // const closeAndCancel = () => {
    //     setIsCancelled(true);
    //     setShowSave(true);
    // };

    // console.log(showSave, 'saved changes ');
    return (
        <>
            <div className={`side-bar xl fav-list-modal tracking-fav-list ${modalOpen ? 'active' : ''}`}>
                <button
                    className="app-btn text-close-btn"
                    onClick={() => (showSave ? setIsCancelled(true) : modalHandle(false))}
                >
                    <svg className="svg-icon text-close-icon">
                        <use href="#closeIcon"></use>
                    </svg>
                </button>
                <h2 className="page-heading sidebar-page-heading">Favourites</h2>

                <div className="sidebar-reset-wrapper">
                    <p className="page-caption-ql">Remove or reorder Insights in the Favourites list</p>
                    <div className="reset-link">
                        <span>
                            <a href="#" className="clear-filter-link" onClick={() => removeAllFavourites()}>
                                Remove all Favourites
                            </a>
                        </span>
                        <a href="#" className="clear-filter-link" onClick={() => reset()}>
                            Reset
                        </a>
                    </div>
                </div>

                <div className="page-body details-box custom fav-list customscrollbar">
                    <div>
                        {data.length === 0 && (
                            <div className="no-data-wrapper">
                                <div className="no-data-content">
                                    <div className="no-data-circle">
                                        <svg className="svg-icon grid-no-content-icon">
                                            <use xlinkHref="#noFavIcon">
                                                <title>Favourites</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <p className="no-content-message">All Favourites removed</p>
                                </div>
                            </div>
                        )}
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable-1">
                                {(provided, _snapshot) => (
                                    <div ref={provided.innerRef} {...provided.droppableProps}>
                                        {dataList?.map((element: any, index: number) => {
                                            return (
                                                <Draggable draggableId={index.toString()} index={index} key={index}>
                                                    {(provided, snapshot) => (
                                                        <>
                                                            <div
                                                                key={`favList__${index}`}
                                                                className={`fav-card-list ${
                                                                    snapshot.isDragging && 'dragging-component'
                                                                }`}
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                style={getItemStyle(
                                                                    snapshot.isDragging,
                                                                    provided.draggableProps.style
                                                                )}
                                                            >
                                                                <BSTooltip
                                                                    id={`favList__${index}_tooltip`}
                                                                    title={`${
                                                                        element.favouriteStatus
                                                                            ? 'Remove from Favourites'
                                                                            : 'Add to Favourites'
                                                                    }`}
                                                                    position={'bottom'}
                                                                    classList="action-btn-wrapper"
                                                                    delay={500}
                                                                >
                                                                    <button
                                                                        className={`app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                                                                            element.favouriteStatus
                                                                                ? 'selected-fav-icon'
                                                                                : 'unselected-fav-icon'
                                                                        }`}
                                                                        onClick={() => updateFavourite(element)}
                                                                    >
                                                                        <svg className="svg-icon star-icon ">
                                                                            <use xlinkHref="#starIcon">
                                                                                <title>Favourites</title>
                                                                            </use>
                                                                        </svg>
                                                                    </button>
                                                                </BSTooltip>
                                                                <div className="fav-card-title">
                                                                    <h4>{element.title}</h4>
                                                                    {element?.type?.name && (
                                                                        <span className="status">
                                                                            {element.type.name}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                <div className="fav-modes">
                                                                    {/* {element.modesEnabled?.map((elementMode: any) => {
                                                                        return IconSwitcherMode(elementMode);
                                                                    })} */}
                                                                </div>
                                                                <a
                                                                    {...provided.dragHandleProps}
                                                                    className={`app-btn icon-only sm app-btn-secondary drag-icon`}
                                                                >
                                                                    <svg className="svg-icon ">
                                                                        <use xlinkHref="#dragIcon">
                                                                            <title>Favourites</title>
                                                                        </use>
                                                                    </svg>
                                                                </a>
                                                            </div>
                                                        </>
                                                    )}
                                                </Draggable>
                                            );
                                        })}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </div>
                </div>
                <div className="side-page-footer">
                    <button
                        className="app-btn app-btn-secondary "
                        title="Cancel"
                        onClick={() => (showSave ? setIsCancelled(true) : modalHandle(false))}
                    >
                        <span className="button-text footer-button-text">Cancel</span>
                    </button>
                    <button
                        disabled={!showSave}
                        onClick={() => onSave()}
                        className="app-btn app-btn-primary"
                        title="Save"
                    >
                        <span className="button-text footer-button-text">Save</span>
                    </button>
                </div>
            </div>
            <div className="sidebar-backdrop"></div>
            {isCancelled && <CancelModal onConfirm={onDiscardConfirm} displayFunction={setIsCancelled} />}
        </>
    );
};
export default Index;
