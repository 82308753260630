import React from 'react';
import '../../BookingProposal/BookingProposalDetailsHeader/booking-proposal-header.scss';
// import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
interface HeaderProps {
    id?: string;
    setShowBookingSideBar: (data: boolean) => void;
}

const Index: React.FC<HeaderProps> = (props) => {
    // const { id } = useParams();
    // const hasBookingId = id !== 'POSelection' && id !== 'POSelectionDetails';
    const { totalCBM, totalKgs, totalTEU } = useSelector((state: RootState) => state.bpHeaderData);
    return (
        <div className="booking-proposal-header-fields">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    {/* <Link
                        to={hasBookingId ? `/air_booking_list/${id}/POSelection` : '/air_booking_list/POSelection/new'}
                    >
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">{props.id?.length ? props.id : 'New Booking Proposal'}</h2>
                    {/* {props.id?.length && clientName ? (
                        <div className="header-sidebar-holder">
                            <button
                                className="sidebar-info-btn animate-btn"
                                onClick={() => props.setShowBookingSideBar(true)}
                            >
                                <svg className="svg-icon info-icon">
                                    <use xlinkHref="#infoIcon">
                                        <title>Information</title>
                                    </use>
                                </svg>
                            </button>
                        </div>
                    ) : null} */}
                </div>
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-2">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">{totalCBM}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">{totalKgs}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Estimated TEU</h6>
                        <div className="details-box-content">
                            {totalTEU}
                            {/* {totalCBM && Math.ceil(totalCBM / 28) ? Math.ceil(totalCBM / 28) : totalCBM === 0 ? 0 : '-'} */}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
