import React from 'react';
import './contract-modal.scss';
import '../../../../assets/scss/components/_modal.scss';
interface BioProps {
    addModalVisibility: boolean;
    setAddModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    clientData: any;
    originalData: any;
    allClientsData: any;
    setAllClientsData: any;
    primaryClientsData: any;
    setPrimaryClientsData: any;
    secondaryClientsData: any;
    setSecondaryClientsData: any;
    filterUsed: any;
}
const Index: React.FC<BioProps> = (props) => {
    const addChild = () => {
        if (props.filterUsed === 'primaryClient') {
            const newChildData = props.clientData.childClients.map((res: any) => ({
                value: res.clientId,
                label: res.orgName,
            }));
            const option = {
                label: props.clientData.orgName,
                value: props.clientData.clientId,
            };
            props.setPrimaryClientsData((prev: any) => [...prev, option, ...newChildData]);

            const result = props.allClientsData.filter(function (o1: any) {
                return !newChildData.some(function (o2: any) {
                    return o1.value === o2.value || o1.value === props.clientData.clientId;
                });
            });
            props.setAllClientsData(result);
        } else {
            const newChildData = props.clientData.childClients.map((res: any) => ({
                value: res.clientId,
                label: res.orgName,
            }));
            const option = {
                label: props.clientData.orgName,
                value: props.clientData.clientId,
            };
            props.setSecondaryClientsData((prev: any) => [...prev, option, ...newChildData]);

            const result = props.allClientsData.filter(function (o1: any) {
                return !newChildData.some(function (o2: any) {
                    return o1.value === o2.value || o1.value === props.clientData.clientId;
                });
            });
            props.setAllClientsData(result);
        }
    };
    return (
        <>
            {props.addModalVisibility ? (
                <div className="app-modal profile-popup container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Child Profiles Added</h2>
                            <button
                                onClick={() => {
                                    props.setAddModalVisibility(false);
                                    addChild();
                                }}
                                className="app-btn modal-close-btn"
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>All the child profiles under {props.clientData.orgName} have also been added.</p>
                            <div className="box-content-holder">
                                <h6 className="details-box-heading">Client Profiles</h6>
                                <div className="suggestion-container">
                                    {props.clientData.childClients.map((res: any, index: number) => (
                                        <div key={index} className="content-card">
                                            <span className="main-content">{res.orgName}</span>
                                        </div>
                                    ))}
                                    {/* <div className="content-card">
                                        <span className="main-content">Kohler LLC</span>
                                    </div>
                                    <div className="content-card">
                                        <span className="main-content">West Group</span>
                                    </div>
                                    <div className="content-card">
                                        <span className="main-content">Altenwerth Group</span>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            {/* <button className="app-btn app-btn-secondary modal-btn">
                        <span className="button-text vendor-button-text">Cancel</span>
                    </button> */}
                            <button
                                onClick={() => {
                                    props.setAddModalVisibility(false);
                                    addChild();
                                }}
                                style={{ marginLeft: '8px' }}
                                className="app-btn app-btn-primary modal-btn"
                            >
                                <span className="button-text vendor-button-text">OK</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
