import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';

import { fetchQuoteHeader, fetchQuoteReport, fetchRateHeader } from '../reducers/qmsReducer';
import { getQuoteByPKSagaApi, getRateByPKSagaApi, getSsrsApi } from 'src/services/apis/qmsApi';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getRateHeaderData({ payload }: { payload: any; type: any }) {
    try {
        const res: AxiosResponse = yield call(getRateByPKSagaApi, payload);
        yield put({ type: fetchRateHeader, payload: res });
    } finally {
    }
}

function* getQuoteHeaderData({ payload }: { payload: any; type: any }) {
    try {
        const res: AxiosResponse = yield call(getQuoteByPKSagaApi, payload);
        yield put({ type: fetchQuoteHeader, payload: res });
    } finally {
    }
}

function* getQuoteSSRS({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(getSsrsApi, payload);
        yield put({ type: fetchQuoteReport, payload: response.data });
    } catch (error) {
        console.log(error);
    }
}

function* qmsSaga() {
    yield takeEvery('qms/fetchRateHeader', getRateHeaderData);
    yield takeEvery('qms/fetchQuoteHeader', getQuoteHeaderData);
    yield takeEvery('qms/fetchQuoteReport', getQuoteSSRS);
}

// Export the saga (data-saga)
export default qmsSaga;
