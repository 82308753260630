import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchAvailablePoLineDataAir,
    isLoadingFn,
    searchAvailablePoLineAirDataSuccess,
} from 'src/store/reducers/bookingProposalAir/availablePoLineReducer';
import {
    getAvailablePoLinesAir,
    searchAvailablePoLinesAir,
} from 'src/services/apis/bookingProposalAir/availablePoLineApi';

function* getAvailablePoLineAirData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const response: AxiosResponse<any> = yield call(getAvailablePoLinesAir, payload);
        payload['response'] = response;
        yield put({ type: fetchAvailablePoLineDataAir, payload: payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* searchAvailablePoLineListAirData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const availablePoLineList: AxiosResponse<any> = yield call(searchAvailablePoLinesAir, payload);
        payload['response'] = availablePoLineList;
        yield put({ type: searchAvailablePoLineAirDataSuccess, payload: payload });
    } catch (err) {
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* airAvailablePoLineListSaga() {
    yield takeEvery('availablePoLinesAir/fetchAvailablePoLineDataAir', getAvailablePoLineAirData);
    yield takeEvery('availablePoLinesAir/searchAvailablePoLineAirData', searchAvailablePoLineListAirData);
}

export default airAvailablePoLineListSaga;
