import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// import './style.css';
import '../../../../assets/scss/components/_modal.scss';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    closeReceipt,
    selectReceiptHeaderIsError,
    selectReceiptHeaderSuccess,
    selectReceiptHeaderError,
    clearFailure,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { error, success, Position } from '../../../../utils/toast';

interface CloseReceiptProps {
    closeReceiptModalVisibility: boolean;
    setCloseReceiptModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const CloseReceiptModal: React.FC<CloseReceiptProps> = ({
    closeReceiptModalVisibility,
    setCloseReceiptModalVisibility,
}) => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectReceiptHeaderIsError);
    const receiptHeaderSuccess = useAppSelector(selectReceiptHeaderSuccess);
    const receiptHeaderError = useAppSelector(selectReceiptHeaderError);

    const onCloseReceipt = () => {
        setIsLoading(true);
        dispatch(
            closeReceipt({
                receiptId: id,
                type: 'closeReceipt',
            })
        );
    };

    useEffect(() => {
        if (isError && receiptHeaderError?.config?.url?.includes('close-receipt')) {
            error(receiptHeaderError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (receiptHeaderSuccess?.status === 200 || receiptHeaderSuccess?.status === 201) {
            if (receiptHeaderSuccess?.data?.type === 'closeReceipt') {
                success('Receipt closed successfully', Position.TOP_RIGHT);
                setCloseReceiptModalVisibility(false);
                dispatch(clearFailure([]));
                setTimeout(() => navigate('/blp/receipt'), 2000);
            }
        }
        setIsLoading(false);
    }, [isError, receiptHeaderSuccess, receiptHeaderError]);

    return (
        <>
            {closeReceiptModalVisibility ? (
                <div className="app-modal container">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Close Receipt</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => setCloseReceiptModalVisibility(false)}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                Once closed the receipt details can no longer be modified. Are you sure to close the
                                receipt ?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => setCloseReceiptModalVisibility(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                onClick={() => onCloseReceipt()}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default CloseReceiptModal;
