import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './allocation-order-header.scss';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import { fetchHeader, selectHeader } from 'src/store/reducers/allocationOrder/addAOReducer';
import CancellationDetailModal from '../BasicDetail/CancellationDetailModal';
import { RootState } from 'src/store/reducers';
import { aoStatus } from './aoStatusList';

interface HeaderProps {
    id?: string;
}

const Index: React.FC<HeaderProps> = (props) => {
    const dispatch = useAppDispatch();

    const headerData = useAppSelector(selectHeader);
    const { basicDetails } = useSelector((state: RootState) => state.allocationOrder);
    const [showReasonModal, setShowReasonModal] = useState(false);

    const onClose = () => {
        setShowReasonModal(false);
    };

    useEffect(() => {
        dispatch(
            fetchHeader({
                aoId: props.id,
            })
        );
    }, [props.id]);

    return (
        <div className="allocation-order-header-fields">
            <div className="pageheading-holder">
                <div className="back-btn-holder">
                    {/* <Link to="/allocation_orders">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">{headerData?.aoNumber}</h2>

                    <div className="ao-cancel-wrapper">
                        <div className="item-count-status-holder">
                            {/* .success-status used for completed, .confirmed-status - it is used for ready for booking .info-status -it is used for draft, .warning-status are the classes for pending .error-status are the classes for Cancellation to use for label */}
                            {/* <div className="grid-item-count-status status-ui confirmed-status">
                                <span className="count"></span>
                                <span className="status-text">{headerData?.aoStatus}</span>
                            </div> */}
                            {aoStatus(headerData?.aoStatus)}
                        </div>
                        <div className="box-content-holder">
                            <h6 className="details-box-heading" style={{ color: '#737373', marginLeft: '5px' }}>
                                Created by <span style={{ color: '#23272B' }}>{headerData?.createdUser}</span>
                            </h6>
                        </div>
                        {!!(headerData?.aoStatus === 'Cancelled') && (
                            <a
                                className="add-content-link"
                                href="javascript:void(0)"
                                onClick={() => setShowReasonModal(true)}
                            >
                                View Reason
                            </a>
                        )}
                    </div>
                </div>
            </div>
            <div className="details-box custom">
                <div className="card-detail-col col-info-1">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">AO Number</h6>
                        <div className="details-box-content">{headerData?.aoNumber}</div>
                    </div>
                    <div className="details-col box-content-holder client-name-col">
                        <h6 className="details-box-heading">Client Name</h6>
                        <div className="details-box-content">{headerData?.clientName}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">BLP</h6>
                        <div className="details-box-content">{headerData?.blpAgentName}</div>
                    </div>
                </div>
                <div className="card-detail-col col-info-2">
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Order Lines</h6>
                        <div className="details-box-content">{headerData?.orderLines || 0}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">{headerData?.totalCbms || 0}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">{headerData?.totalKgs || 0}</div>
                    </div>
                    <div className="details-col box-content-holder">
                        <h6 className="details-box-heading">Total Order Value</h6>
                        {/* <div className="details-box-content">{headerData?.totalOrderValue || 0} USD</div> */}
                        <div className="details-box-content">{headerData?.totalOrderValue || 0}</div>
                    </div>
                </div>
            </div>
            {showReasonModal ? (
                <CancellationDetailModal
                    onClose={onClose}
                    data={{
                        cancelReason: basicDetails?.cancelReason || '-',
                        cancelledUser: basicDetails?.cancelledUser || '-',
                        cancelDate: basicDetails?.cancelDate || '-',
                    }}
                />
            ) : null}
        </div>
    );
};
export default Index;
