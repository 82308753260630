export const lifeCycleSearchCategories = {
    po: 'po',
    bp: 'bp',
    vessel: 'vessel',
    hbl: 'hbl',
    mbl: 'mbl',
    sku: 'sku',
    warehouse: 'warehouse',
    carrier: 'carrier',
    client: 'client',
    vendor: 'vendor',
    agent: 'agent',
    port: 'port',
    shippingline: 'shippingline',
};
