import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchFavourites,
    fetchFavouritesSuccess,
    fetchFavouritesError,
    addToFavourites,
    addToFavouritesError,
    addToFavouritesSuccess,
    removeFromFavourites,
    removeFromFavouritesError,
    removeFromFavouritesSuccess,
    removeAllFavourites,
    removeAllFavouritesSuccess,
    removeAllFavouritesError,
    reOrderFavourites,
    reOrderFavouritesSuccess,
    reOrderFavouritesError,
} from 'src/store/reducers/dashboard/insightsFavoritesReducer';

import {
    fetchInsightsFavourites,
    addInsightToFavourites,
    updateFavourites,
    deselectFavourites,
    deleteAllFavourites,
} from 'src/services/apis/dashboard/insightsFavoritesApi';

function* getFavs({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchInsightsFavourites, payload);
        yield put({ type: fetchFavouritesSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchFavouritesError, payload: error });
    }
}

function* addToFavs({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(addInsightToFavourites, payload);
        yield put({ type: addToFavouritesSuccess, payload: response });
    } catch (error) {
        yield put({ type: addToFavouritesError, payload: error });
    }
}
function* removeFromFavs({ payload }: { payload: { insightId: string }; type: any }) {
    try {
        // yield put(showLoading());
        const res: AxiosResponse = yield call(deselectFavourites, payload);
        yield put({ type: removeFromFavouritesSuccess, payload: res });
    } catch (error) {
        yield put({ type: removeFromFavouritesError, payload: error });
    }
}

function* removeAllFavs({ payload }: { payload: { userName: string }; type: any }) {
    try {
        // yield put(showLoading());
        const res: AxiosResponse = yield call(deleteAllFavourites, payload);
        yield put({ type: removeAllFavouritesSuccess, payload: res });
    } catch (error) {
        yield put({ type: removeAllFavouritesError, payload: error });
    }
}

function* reOrderFavs({ payload }: { payload: any; type: any }) {
    try {
        // yield put(showLoading());
        const res: AxiosResponse = yield call(updateFavourites, payload);
        yield put({ type: reOrderFavouritesSuccess, payload: res });
    } catch (error) {
        yield put({ type: reOrderFavouritesError, payload: error });
    }
}

function* favouritesSaga() {
    yield takeEvery(fetchFavourites, getFavs);
    yield takeEvery(addToFavourites, addToFavs);
    yield takeEvery(removeFromFavourites, removeFromFavs);
    yield takeEvery(removeAllFavourites, removeAllFavs);
    yield takeEvery(reOrderFavourites, reOrderFavs);
}

export default favouritesSaga;
