/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BookingProposalHeader from '../BookingProposalHeader';

import { useAppDispatch } from 'src/hooks';
import {
    fetchProductDetailsConfirmationList,
    fetchLclThreshold,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import './index.scss';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';

import { useUserProfile } from 'src/hooks/useUserProfile';
// import { Formik } from 'formik';
import LayoutWithColumns from 'src/components/KendoGridJquery/layoutWithColumns';
import NotesModal from './NotesModal';
import { getNoteType, getSingleNote } from 'src/services/apis/notesApi';
import { Position, error } from 'src/utils/toast';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { getUnreadBpNotes } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { updateNotesRead } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import BookingProcessTab from '../BookingProcessTab';

const Index: React.FC = () => {
    const { id } = useParams();
    const { userName, profileType } = useUserProfile();
    const dispatch = useAppDispatch();
    const headerData = useSelector((state: RootState) => state.bpHeaderData);

    let bookingUrl = '';

    const navData = [
        {
            title: 'PO Selection',
            selected: 0,
            path: id ? `/land_booking_list/${id}/POSelection` : '/land_booking_list/POSelection',
            disabled: false,
        },
        {
            title: 'Transport Schedule',
            selected: 0,
            path: `/land_booking_list/${id}/transport-schedule`,
            //disabled: !(headerData?.tabValues as any)?.po,
            disabled: false,
        },
        {
            title: 'Goods Received',
            selected: 0,
            path: `/land_booking_list/${id}/goods-received`,
            //disabled: !(headerData?.tabValues as any)?.sailing,
            disabled: false,
        },
    ];

    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;

    const initialNotesData = {
        NT_FK: id,
        NT_FKID: window.location.pathname.includes('purchase_order') ? 'PO' : 'BP',
        NT_Subject: '',
        NT_CD_NoteType: '',
        NT_IsImportant: false,
        NT_IsForwarderViewable: true,
        NT_IsAgentViewable: profileType === 'agent' ? true : false,
        NT_IsClientViewable: profileType === 'client' ? true : false,
        NT_IsSupplierViewable: profileType === 'vendor' ? true : false,
        NT_IsLockedToCreator: true,
        NT_Text: '',
        US_UserName: '',
    };

    const getUnreadNoteStatus = () => {
        if (!headerData?.notesUnread && id && id !== 'POSelection') {
            getUnreadBpNotes(id)
                ?.then((res: any) => dispatch(updateNotesRead(res)))
                .catch((err) => console.log(err, 'err'));
        }
    };

    const refreshGrid = () => {
        const refresh: any = document.querySelector('.k-pager-refresh');
        if (refresh) {
            refresh?.click();
        }
    };

    const [showBookingSideBar, setShowBookingSideBar] = useState(false);

    const [modalView, setModalView] = useState(false);
    const [mode, setMode] = useState('');
    const [noteTypeData, setNoteTypeData] = useState([]);
    const [noteId, setNoteId] = useState('');
    const [body, setBody] = useState({});

    useEffect(() => {
        // dispatch(
        //     fetchProductDetailsConfirmationList({
        //         poId: id,
        //     })
        // );
        dispatch(fetchPermissions(username));
        getNoteType()
            .then((res: any) => {
                setNoteTypeData(res);
            })
            .catch((err: any) => console.log(err));
        // dispatch(fetchPort({ key: 'a', type: 'PO', service: 'ord' }));
    }, []);

    useEffect(() => {
        if (!modalView && mode === 'edit') {
            refreshGrid();
        }
    }, [modalView]);

    //@ts-ignore
    window.noteUp = (value: string) => {
        if (value === 'NEW') {
            setMode('add');
            setBody(initialNotesData);
            setModalView(true);
        } else {
            getSingleNote(value)
                .then((res: any) => {
                    setBody(res);
                    setNoteId(value);
                    setMode('edit');
                    setModalView(true);
                    getUnreadNoteStatus();
                })
                .catch((err: any) => {
                    error(err?.response?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
                });
        }
    };

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={'Bookings'}
                        firstUrl={'/booking_list'}
                        secondName={'Booking Proposal Details'}
                        secondUrl={'#'}
                        backUrl={'/booking_list'}
                    />
                    <BookingProposalHeader
                        id={localStorage.getItem('bpNumber') || ''}
                        setShowBookingSideBar={setShowBookingSideBar}
                    ></BookingProposalHeader>
                    <BookingProcessTab navData={navData} />
                </div>
                <div className="lifecycle-grid">
                    <div
                        style={{ zIndex: 999 }}
                        className={`app-loader ${headerData?.isLoadingHeader ? 'loading' : ''}`}
                    >
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="grid-holder">
                        <div className="grid-header"></div>
                        <LayoutWithColumns style={'lifecycle'} content={'purchase-order-notes'} />
                    </div>
                </div>
                {modalView ? (
                    <NotesModal
                        initialNotesData={initialNotesData}
                        setModalView={setModalView}
                        noteTypeData={noteTypeData}
                        mode={mode}
                        noteId={noteId}
                        setNoteId={setNoteId}
                        body={body}
                        setBody={setBody}
                        refreshGrid={refreshGrid}
                    />
                ) : null}
            </div>
        </>
    );
};
export default Index;
