import fileDownload from 'js-file-download';
import { del, get, patch, post, put } from '../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

export interface Event {
    EV_FK: string;
    EV_FKID: string;
    EV_CD_Code: string;
    EV_Context: string;
    EV_Source: string;
}

export const postLifecycleEvent = async (payload: Event) => {
    try {
        const res = await post(`cix/scr/lifecycle/event`, payload, null, undefined, externalBaseUrl);
        return res;
    } catch (err) {
        return [];
    }
};
