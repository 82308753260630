import React from 'react';
import { useSelector } from 'react-redux';
import LineGraph from 'src/components/common/LineGraph';
// import { FilterList } from 'src/pages/Dashboard/InsightsExpanded/enums';
import { RootState } from 'src/store/reducers';
// import './allOrdersTab.scss';

interface Props {
    selectedRef?: any;
    type: string;
}

interface DisplayHandler {
    changeValue: any;
}

const Index = React.forwardRef<DisplayHandler, Props>((isIndicator, ref) => {
    const [selectedItem, setSelectedItem] = React.useState({} as any);

    //========================= Redux values =======================================//
    const { insightsDurationRange } = useSelector((state: RootState) => state.dashboardInsights);
    //=============================================================================//

    React.useImperativeHandle(ref, () => ({
        changeValue: (obj: any) => {
            setSelectedItem(obj);
        },
    }));

    const plotGraphPoints = (_type: string) => [
        [
            selectedItem?.key1?.pointName,
            selectedItem?.key1?.count,
            selectedItem?.key1?.dateFrom,
            selectedItem?.key1?.dateTo,
            selectedItem?.key1?.pointName,
        ],
        [
            selectedItem?.key2?.pointName,
            selectedItem?.key2?.count,
            selectedItem?.key2?.dateFrom,
            selectedItem?.key2?.dateTo,
            selectedItem?.key2?.pointName,
        ],
        [
            selectedItem?.key3?.pointName,
            selectedItem?.key3?.count,
            selectedItem?.key3?.dateFrom,
            selectedItem?.key3?.dateTo,
            selectedItem?.key3?.pointName,
        ],
        [
            selectedItem?.key4?.pointName,
            selectedItem?.key4?.count,
            selectedItem?.key4?.dateFrom,
            selectedItem?.key4?.dateTo,
            selectedItem?.key4?.pointName,
        ],
        [
            selectedItem?.key5?.pointName,
            selectedItem?.key5?.count,
            selectedItem?.key5?.dateFrom,
            selectedItem?.key5?.dateTo,
            selectedItem?.key5?.pointName,
        ],
        [
            selectedItem?.key6?.pointName,
            selectedItem?.key6?.count,
            selectedItem?.key6?.dateFrom,
            selectedItem?.key6?.dateTo,
            selectedItem?.key6?.pointName,
        ],
        [
            selectedItem?.key7?.pointName,
            selectedItem?.key7?.count,
            selectedItem?.key7?.dateFrom,
            selectedItem?.key7?.dateTo,
            selectedItem?.key7?.pointName,
        ],
        [
            selectedItem?.key8?.pointName,
            selectedItem?.key8?.count,
            selectedItem?.key8?.dateFrom,
            selectedItem?.key8?.dateTo,
            selectedItem?.key8?.pointName,
        ],
        [
            selectedItem?.key9?.pointName,
            selectedItem?.key9?.count,
            selectedItem?.key9?.dateFrom,
            selectedItem?.key9?.dateTo,
            selectedItem?.key9?.pointName,
        ],
        [
            selectedItem?.key10?.pointName,
            selectedItem?.key10?.count,
            selectedItem?.key10?.dateFrom,
            selectedItem?.key10?.dateTo,
            selectedItem?.key10?.pointName,
        ],
        [
            selectedItem?.key11?.pointName,
            selectedItem?.key11?.count,
            selectedItem?.key11?.dateFrom,
            selectedItem?.key11?.dateTo,
            selectedItem?.key11?.pointName,
        ],
        [
            selectedItem?.key12?.pointName,
            selectedItem?.key12?.count,
            selectedItem?.key12?.dateFrom,
            selectedItem?.key12?.dateTo,
            selectedItem?.key12?.pointName,
        ],
    ];

    return (
        <div className="graph-view-wrapper">
            <div className="graph-holder">
                <div className="tab-content-body">
                    <div className="graph-container">
                        <div className="tab-content-head">
                            <h5>Trends</h5>
                            <p>Showing trend for Last 12 {insightsDurationRange}</p>
                        </div>
                        <div className="graph-holder">
                            <LineGraph
                                drawn={plotGraphPoints('total')}
                                name="activeOrdersLarge"
                                tooltip={true}
                                lineDots={true}
                                lineWidth={1}
                                pointSize={2}
                                graphAxis={true}
                                tooltipTitle={isIndicator.type || null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Index;
