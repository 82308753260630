import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { saveReceipt } from '../../reducers/receipts/addReceiptReducer';
import { saveReceiptData } from '../../../services/apis/receipts/addReceiptsApi';

function* createReceipt({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(saveReceiptData, payload);
        payload['response'] = response;
        yield put({ type: saveReceipt, payload });
    } catch (error) {
        payload['response'] = error;
        yield put({ type: saveReceipt, payload });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* addReceiptSaga() {
    yield takeEvery('addReceipt/saveReceipt', createReceipt);
}

export default addReceiptSaga;
