import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchShipmentPoList,
    fetchShipmentPoListSuccess,
    fetchShipmentPoListFailure,
    // isLoadingFn,
    createProductPoSuccess,
    createProductPoFailure,
    updateProductPoSuccess,
    updateProductPoFailure,
    createProductPo,
    updateProductPo,
    fetchCountrySuccess,
    fetchCountryFailure,
    fetchCountry,
    fetchCurrencySuccess,
    fetchCurrencyFailure,
    fetchCurrency,
    fetchPortSuccess,
    fetchPortFailure,
    fetchPort,
    fetchDestinationAddress,
    fetchDestinationAddressSuccess,
    fetchDestinationAddressFailure,
    fetchSkuList,
    fetchSkuListSuccess,
    fetchSkuListFailure,
    removeProductPoSuccess,
    removeProductPoFailure,
    removeProductPo,
    linkVendorProductPo,
    linkVendorProductPoSuccess,
    linkVendorProductPoFailure,
} from '../../reducers/purchaseOrder/shipmentConfirmationReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { fetchProductPoListDetailsApi } from '../../../services/apis/purchaseOrder/shipmentConfirmationApi';
import {
    createProductPoDetailsApi,
    updateProductPoDetailsApi,
    fetchCountryDetailsApi,
    fetchCurrencyDetailsApi,
    fetchPortDetailsApi,
    fetchDestinationAddressDetailsApi,
    fetchSkuListDetailsApi,
    removeProductPoDetailsApi,
    linkVendorProductPoApi,
} from '../../../services/apis/purchaseOrder/productPoApi';

// eslint-disable-next-line
function* getProductPoDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchProductPoListDetailsApi, payload);
        yield put({ type: fetchShipmentPoListSuccess, payload: response.data });
    } catch (error) {
        yield put({ type: fetchShipmentPoListFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getSkuDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchSkuListDetailsApi, payload);
        yield put({ type: fetchSkuListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchSkuListFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* createProductPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createProductPoDetailsApi, payload);
        yield put({ type: createProductPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: createProductPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateProductPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateProductPoDetailsApi, payload);
        yield put({ type: updateProductPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateProductPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* removeProductPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(removeProductPoDetailsApi, payload);
        yield put({ type: removeProductPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: removeProductPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* linkVendorProductPoData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(linkVendorProductPoApi, payload);
        yield put({ type: linkVendorProductPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: linkVendorProductPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getCountryDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchCountryDetailsApi, payload);
        yield put({ type: fetchCountrySuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchCountryFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getCurrencyDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchCurrencyDetailsApi, payload);
        yield put({ type: fetchCurrencySuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchCurrencyFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getPortDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchPortDetailsApi, payload);
        yield put({ type: fetchPortSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchPortFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* getDestinationAddressDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchDestinationAddressDetailsApi, payload);
        yield put({ type: fetchDestinationAddressSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchDestinationAddressFailure, payload: error });
        // yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        // yield put({ type: isLoadingFn, payload: false });
    }
}

function* shipmentPoSaga() {
    yield takeEvery(fetchShipmentPoList, getProductPoDetails);
    yield takeEvery(fetchSkuList, getSkuDetails);
    yield takeEvery(createProductPo, createProductPoDetailsData);
    yield takeEvery(updateProductPo, updateProductPoDetailsData);
    yield takeEvery(removeProductPo, removeProductPoDetailsData);
    yield takeEvery(linkVendorProductPo, linkVendorProductPoData);
    yield takeEvery(fetchCountry, getCountryDetails);
    yield takeEvery(fetchCurrency, getCurrencyDetails);
    yield takeEvery(fetchPort, getPortDetails);
    yield takeEvery(fetchDestinationAddress, getDestinationAddressDetails);
}

export default shipmentPoSaga;
