// import './style.css';
import '../../../assets/scss/components/_modal.scss';

interface LinkVendorModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    sku: string;
    vendor: string;
    linkFunction: any;
    formik: any;
}

const Index: React.FC<LinkVendorModalProps> = ({ display, displayFunction, sku, vendor, linkFunction, formik }) => {
    return (
        <>
            {display ? (
                <div className="app-modal container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Link Vendor</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => {
                                    displayFunction(false);
                                    formik.setFieldValue('skuId', '');
                                    formik.setFieldValue('sku', '');
                                    formik.setFieldValue('description', '');
                                }}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                SKU <span style={{ fontWeight: 700 }}>{sku}</span> does not have{' '}
                                <span style={{ fontWeight: 700 }}>{vendor}</span> as linked vendor. To proceed with PO
                                Line addition you must link vendor to the SKU
                            </p>
                        </div>
                        <div className="modal-footer">
                            {/* <button className="app-btn app-btn-secondary modal-btn" onClick={() => onCancel()}>
                        <span className="button-text vendor-button-text">No</span>
                    </button> */}
                            <button className="app-btn app-btn-primary modal-btn" onClick={linkFunction}>
                                <span className="button-text vendor-button-text">Link Now</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
