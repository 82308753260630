import { get, post } from '../../HttpMethods';

export interface AvailablePoData {
    poId: string;
    poNumber: string;
    poStatus: string;
    poOrderDate: string;
    createdDate: string;
    clientName: string;
    vendorName: string;
    agentName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    createdBy: string;
    createdOn: string;
    totKGS: number;
    totCBM: number;
    goodsReadyDate: string;
    isMultiAddress: boolean;
}

export interface AvailablePoResponse {
    poListingDtos: AvailablePoData[];
}

export interface CreateBpResponse {
    success: boolean;
    info: {
        bpId: string;
        bpNumber: string;
        totalCBM: 46.0;
        totalKgs: 6.0;
        client: string;
        originPort: string;
        destinationPort: string;
        goodsReadyDate: string[];
        purchaseOrders: AvailablePoData[];
    };
}
/* eslint-disable no-undefined */

export const getAddedPoLines = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/added/polines${payload !== undefined ? `?bpId=${payload}` : ''}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getSingleLine = async (payload: any) => {
    try {
        const res = await get(`api/bkg/bp/products?clientCode=${payload}`, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const removePoLine = async (payload: any) => {
    try {
        const res = await post('api/bkg/bp/remove/subpolines?type=ao', payload, null);
        return res;
    } catch (err) {
        return console.error(err);
    }
};

export const searchAdded = async (payload: any) => {
    try {
        const res = await post(`api/ord/search/addedao?keyword=${payload.val}&bpId=${payload.id}`, payload, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};
