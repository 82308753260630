import { any } from 'prop-types';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';

interface GridProps {
    quote?: any;
}

const GridLayout = (gridProps: GridProps) => {
    const token = `${localStorage.getItem('token') ?? ''}`;
    if (!token) {
        return null;
    }

    return (
        <Helmet>
             <script type="text/x-kendo-template" id="approval-template">
                {`
                #
                let ico = ''
                switch(QD_CD_Status){
                    case "DFT":
                        ico = '<i class="fa-duotone fa-user fa-xl" title="Draft \\n\\n Please Submit For Approval" style="--fa-primary-color: \\\\#5c626a; --fa-secondary-color: \\\\#5c626a;"></i>'
                        break;
                    case "REJ":
                        ico = '<i class="fa-duotone fa-user-xmark fa-xl" title="Rejected \\n\\n Comments: \\n Are you Joking! " style="--fa-primary-color: \\\\#ff0000; --fa-secondary-color: \\\\#ff0000;"></i>'
                        break;
                    case "WAI":
                        ico = '<i class="fa-duotone fa-user-minus fa-xl" title="Waiting \\n\\n Submitted for approval but not yet approved" style="--fa-primary-color: \\\\#ffa500; --fa-secondary-color: \\\\#ffa500;"></i>'
                        break;
                    case "APR":
                        ico = '<i class="fa-duotone fa-user-check fa-xl" title="Approved" style="--fa-primary-color: \\\\#219b08; --fa-secondary-color: \\\\#219b08;"></i>'
                        break;
                }
                #

                #=ico#
            `}
            </script>
            <script type="text/x-kendo-template" id="errors-template">
                {`
                    #
                    try{
                    if(inserted){
                        # <i class="fa-duotone fa-hexagon-exclamation fa-xl" style="--fa-primary-color: \\\\#005eff ; --fa-secondary-color: \\\\#005eff;" title="Inserted"></i> #
                    }else if(error){
                        let messages = ""
                        let colour = "\\\\#ec7d22"
                        let icon = "fa-hexagon-exclamation"
                        for(const errorType of error){
                            messages += "\\n"+errorType.message
                            if(errorType.type == "ERR"){
                                colour = "\\\\#ff004c"
                            }else if(errorType.type == "DUP"){
                                colour = "\\\\#ee9d2b"
                                icon = "fa-hexagon-minus"
                            }
                        }
                        # <i class="fa-duotone #:icon# fa-xl" style="--fa-primary-color: #:colour# ; --fa-secondary-color: #:colour#;" title="#:messages#"></i> #
                    }
                    }catch(e){
                        console.log(e)
                    }
                    #
                    `}
            </script>
            <script type="text/x-kendo-template" id="markup-template">
                {`
            # 

            let markup = kendo.toString(((QD_ClientSell - QD_Buy) / QD_Buy * 100), 'n2') + "%";
            let markupColor = 'green'
            let ico = '<i class="fa-duotone fa-hexagon-check fa-xl" style="--fa-primary-color: \\\\#219b08; --fa-secondary-color: \\\\#219b08;"></i>'

            if(QD_ClientSell < QD_ApprovedSell){
                message = "This is being sold less than the approved sell, it will need approval"
                markupColor = 'red'
                ico = '<i class="fa-duotone fa-hexagon-minus fa-xl" title="'+message+'" style="--fa-primary-color: \\\\#f09800; --fa-secondary-color: \\\\#f09800;"></i>'
            }

            if(QD_ClientSell < QD_Buy){
                message = "This is being sold at a loss!"
                markupColor = 'red'
                ico = '<i class="fa-duotone fa-hexagon-xmark fa-beat fa-xl" title="'+message+'" style="--fa-primary-color: \\\\#ff0000; --fa-secondary-color: \\\\#ff0000;"></i>'
            }
            
            #
            #=ico# <span style="color: #: markupColor #;"> #= markup #</span> 
            `}
            </script>
            <script>
                {`

            function updateRow(grid, id, field, value) {
                // Get the Kendo Grid instance
                var gridInstance = $("#"+grid).data("kendoGrid");
                var dataItem = gridInstance.dataSource.get(id);
                if(dataItem.error?.length > 0){
                    toast("warning","Warning: This row cannot be approved or rejected as it has errors")
                    return
                }

                if (dataItem) {
                if(dataItem.QD_CD_Status == "WAI" && value == "WAI") value = "APR"
                    // Update the data item
                    dataItem.set(field, value);
                    dataItem.set("QD_CD_Status",value)
                } else {
                    console.log("Item not found");
                }
            }             
                
            function refreshGrid(id){
                var grid = $("#"+id)
                if (grid.length) {
                    var kendoGrid = grid.data("kendoGrid");
                    if (kendoGrid) {
                    kendoGrid.dataSource.read(); // or kendoGrid.refresh();
                    }
                }
            }
                
$(document).ready(function () {

    const height = $(window).height - ($(".main-header-content-holder").height() + $(".header").height())

    $("#tabstrip").kendoTabStrip({
        animation: {
            open: {
                effects: "fadeIn"
            }
        },
        select: function (e, options) {
            refreshGrid($(e.item).data("code"))
        }
    });

    var tabstrip = $("#tabstrip").kendoTabStrip().data("kendoTabStrip");
    tabstrip.select(tabstrip.tabGroup.children("li:first"));

    const QE = ${ gridProps.quote }

    for (const gridProps of QE.QE_QuoteSegments) {
        $("#" + gridProps.QE_PK).kendoGrid({
            dataSource: {
                serverPaging: true,
                serverSorting: true,
                serverFiltering: true,
                pageSize: 20,
                //sort : { field: "RI_CD_Status", dir: "desc" },
                transport: {
                    read: {
                        url: '${externalBaseUrl}/cix/scr/qms/quote/' + gridProps.QE_QH + '/' + gridProps.QE_PK,
                        headers: {
                            Authorization: 'Bearer: ${token}'
                        },
                        dataType: 'json',
                        timeout: 240000
                    },
                    update: {
                        url: '${externalBaseUrl}/cix/scr/qms/quote/' + gridProps.QE_QH + '/' + gridProps.QE_PK,
                        headers: {
                            Authorization: 'Bearer: ${token}'
                        },
                        dataType: 'json',
                        type: "PATCH",
                        contentType: 'application/json',
                        timeout: 240000
                    },
                    destroy: {
                        url: '${externalBaseUrl}/cix/scr/qms/quote/' + gridProps.QE_QH + '/' + gridProps.QE_PK,
                        headers: {
                            Authorization: 'Bearer: ${token}'
                        },
                        dataType: 'json',
                        type: "DELETE",
                        contentType: 'application/json'
                    },
                    create: {
                        url: '${externalBaseUrl}/cix/scr/qms/quote/' + gridProps.QE_QH + '/' + gridProps.QE_PK,
                        headers: {
                            Authorization: 'Bearer: ${token}'
                        },
                        timeout: 240000,
                        dataType: 'json',
                        type: "POST",
                        contentType: 'application/json'
                    },
                    parameterMap: function (data, type) {
                        if (type == 'update' || type == 'create' || type == 'destroy') {
                            data = JSON.stringify(data);
                        }
                        return data;
                    }
                },
                schema: {
                    total: function (response) {
                        return response.total;
                    },
                    data: function (response) {
                        return response.data;
                    },
                    model: {
                        id: "QD_PK",
                        fields: {
                            QD_PK: { type: "string", editable: false },
                            QD_PortOfOrigin: { type: "string", defaultValue: "ANY", editable: false },
                            QD_PortOfOriginDescription: { type: "string", defaultValue: "Any Ports (All)", editable: false },
                            QD_PortOfDestination: { type: "string", defaultValue: "ANY", editable: false },
                            QD_PortOfDestinationDescription: { type: "string", defaultValue: "Any Ports (All)", editable: false },
                            QH_OR_ClientCode: { type: "string", editable: false },
                            QE_Code: { type: "string", editable: false },
                            QE_CodeDescription: { type: "string", editable: false },
                            QD_CodeDescription: { type: "string", editable: false },
                            QD_Code: { type: "string", editable: false },
                            QD_CD_UOM: { type: "string", editable: false },
                            QD_CD_UOMDescription: { type: "string", editable: false },
                            QD_ApprovedSell : { type: "number", validation: { min: 1 }, defaultValue: 1, editable: false },
                            QD_ClientSell: { type: "number", validation: { min: 1 }, defaultValue: 1, editable: true },
                            QD_ValidFrom : {type: "date", editable: false},
                            QD_ValidTo : {type: "date", editable: false},
                            RI_Comment: { type: "string", editable: false },
                            QD_Publish: {type: "boolean", editable: true},
                            QD_OR_ProvidorDescription: { type: "string", editable: false },
                            QD_CD_Status: { type: "string", editable: true },
                            QD_Buy: {type: "number", editable: false},
                            error : {type: "object"},
                            inserted : {type: "boolean"},
                            QD_Currency: { type: "string", editable: false }
                        }
                    }
                },
                batch: true,
                group: {
                    field: "QD_OR_ProvidorDescription",
                }
            },
            toolbar: [
                {
                    name: "save",
                    template:
                        '<button class="k-button k-grid-save-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk fa-2xl" style="--fa-primary-color: \\\\#003cb3; --fa-secondary-color: \\\\#003cb3;"></i>Save</button>',
                },
                {
                    name: "cancel",
                    template:
                        '<button class="k-button k-grid-cancel-changes app-btn icon-button app-btn-secondary"  data-bs-toggle="tooltip" data-bs-placement="left" title="Download"><i class="fa-duotone fa-floppy-disk-circle-xmark fa-2xl" style="--fa-primary-color: \\\\#ff004c; --fa-secondary-color: \\\\#ff004c;"></i>Cancel</button>',
                },
                // {
                //     name: "dd",
                //     template: '<button class="k-button app-btn icon-button app-btn-secondary" onclick="approveAll(\\''+gridProps.QE_PK+'\\')"  data-bs-toggle="tooltip" data-bs-placement="left" title="Approve or Submit for Approval all rates that are not in an error state \\n\\n Note: If you do not have permissions to approve rates they will go to awaiting approval"><i class="fa-duotone fa-list-check fa-2xl" style="--fa-primary-color: \\\\#219b08; --fa-secondary-color: \\\\#219b08;"></i>Approve All</button>'
                // },
                {
                    name: 'search',
                }
            ],
            selectable: "multiple row",
            navigatable: true,
            height: 500,
            groupable: false,
            resizable: true,
            sortable: true,
            pageable: {
                refresh: true,
                pageSizes: [10, 25, 100, 500],
                buttonCount: 5,
                alwaysVisible: true
            },
            columns: [
                {
                    title: " ",
                    width: 40,
                    template: kendo.template($("#errors-template").html())
                },
                {
                    title: "Rate",
                    headerTemplate : 'Rate',
                    columns: [
                        {
                            field: "QD_Code",
                            title: "Code",
                            width: 60,
                            attributes: {
                                "class" : "hub-code-cell"
                            },
                        }, {
                            field: "QD_CodeDescription",
                            title: "Name",
                            attributes : {
                                "class" : "hub-cell-clip"
                            }
                        }]

                },
                {
                    title: "UOM",
                    columns: [
                        {
                            field: "QD_CD_UOM",
                            title: "Code",
                            width: 60,
                            attributes: {
                                "class" : "hub-code-cell"
                            },
                        }, {
                            field: "QD_CD_UOMDescription",
                            title: "Name",
                            attributes : {
                                "class" : "hub-cell-clip"
                            }
                        }]

                },
                {
                    title: "Origin",
                    headerTemplate : '<i class="fa-duotone fa-anchor"></i> &nbsp; Origin',
                    columns: [
                        {
                            field: "QD_PortOfOrigin",
                            title: "Code",
                            width: 60,
                            attributes: {
                                "class" : "hub-code-cell"
                            },
                            groupHeaderTemplate: function(data){ return "Origin: "+ data.items[0].QD_PortOfOriginDescription + " ("+ data.items[0].QD_PortOfOrigin +")" },
                        }, {
                            field: "QD_PortOfOriginDescription",
                            title: "Name",
                            attributes : {
                                "class" : "hub-cell-clip"
                            },
                            groupHeaderTemplate: function(data){ return "Origin: "+ data.items[0].QD_PortOfOriginDescription + " ("+ data.items[0].QD_PortOfOrigin +")" },
                        }]

                },
                {
                    title: "Destination",
                    headerTemplate : '<i class="fa-duotone fa-anchor"></i> &nbsp; Destination',
                    columns: [
                        {
                            field: "QD_PortOfDestination",
                            title: "Code",
                            width: 60,
                            attributes: {
                                "class" : "hub-code-cell"
                            },
                            groupHeaderTemplate: function(data){ return "Destination: "+ data.items[0].QD_PortOfDestinationDescription + " ("+ data.items[0].QD_PortOfDestination +")" },
                        }, {
                            field: "QD_PortOfDestinationDescription",
                            title: "Name",
                            attributes : {
                                "class" : "hub-cell-clip"
                            },
                            groupHeaderTemplate: function(data){ return "Destination: "+ data.items[0].QD_PortOfDestinationDescription + " ("+ data.items[0].QD_PortOfDestination +")" },

                        }]

                },
                {
                    title: "Provider",
                    hidden: true,
                    field: "QD_OR_ProvidorDescription",
                    groupHeaderTemplate: function(data){ return "Provider: "+ data.items[0].QD_OR_ProvidorDescription },

                },
                {
                    title: "Validity",
                    headerTemplate : '<i class="fa-duotone fa-calendar-days"></i> &nbsp; Validity',
                    columns: [
                        {
                            field: "QD_ValidFrom",
                            title: "From",
                            format: "{0:dd-MMM-yy}"
                        }, {
                            field: "QD_ValidTo",
                            title: "To",
                            format: "{0:dd-MMM-yy}",
                        }]

                },
                {
                    title: "Financials",
                    headerTemplate : '<i class="fa-duotone fa-dollar-sign"></i> &nbsp; financials',
                    columns: [
                        {
                            field: "QD_Buy",
                            title: "Buy"
                        },
                        {
                            field: "QD_ApprovedSell",
                            title: "Approved Sell"
                        }, {
                            field: "QD_ClientSell",
                            title: "Client Sell"
                        },
                        {
                            field: "QD_Currency",
                            title: "Currency"
                        },
                        {
                            title: "Markup",
                            width: 90,
                            template: kendo.template($("#markup-template").html())
                        },
                        {
                            field: "QD_Publish",
                            title: "Publish",
                            template: "<input type=\\"checkbox\\" #= QD_Publish ? \'checked=\\"checked\\"\' : '' # class=\\"chkbx\\" />",
                            attributes : {
                                style: "text-align: center"
                            },
                            editable: function(e){ return false; }
                        }]

                },
                {
                    title: " ",
                    field:"QD_CD_Status",
                    width: 50,  
                    template: kendo.template($("#approval-template").html()),
                    editor: function (container, options) {
                                        
                    }
                },
                {
                    title: "Actions",
                    width: 100 ,
                    template: "<button class='action-btn app-btn app-btn-secondary' onclick='updateRow(\\""+gridProps.QE_PK+"\\",\\"#=QD_PK#\\",\\"RI_X_CD_Status\\",\\"WAI\\")'><i title=\\"Approve\\" class=\\"fa-duotone fa-check\\"></i></button><button class='action-btn app-btn app-btn-secondary' onclick='updateRow(\\""+gridProps.RS_Code+"\\",\\"#=QD_PK#\\",\\"QD_X_CD_Status\\",\\"REJ\\")'><i title=\\"Reject\\" class=\\"fa-duotone fa-xmark\\"></i></button>",
                    attributes: { style: "text-align: center" },
                }
            ],
            edit: function (e) {
                var isEditable = (e.model.QD_CD_Status !== "APR");
                if (!isEditable) {
                    // Prevent editing for rows where isEditable is false
                    this.closeCell();
                    toast("warning","Warning: This row cannot be edited as it is approved")
                }
            },
            groupable: true,
            editable: {confirmation: false, mode: "incell"},
            pageSize: 100
        })

            $("#" + gridProps.QE_PK).on("change", "input.chkbx", function(e) {
        var grid = $("#" + gridProps.QE_PK).data("kendoGrid"),
            dataItem = grid.dataItem($(e.target).closest("tr"));
        $(e.target).closest("td").prepend("<span class='k-dirty'></span>");
        dataItem.QD_Publish = this.checked;
        dataItem.dirty = true;
      });
    }


})



                `}
            </script>
        </Helmet>
    );
};

export default GridLayout;
