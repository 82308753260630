import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import Breadcrumbs from '../../../components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import BookingAvailableGrid from '../BookingAvailableGrid';
// import BookingAddedGrid from '../BookingAddedGrid';
// import BookingHeaderSidebar from '../BookingHeaderSidebar';
import '../../BookingProposal/POSelection.scss';
import { RootState } from '../../../store/reducers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchSelectedSailing } from '../../../store/reducers/aobookingProposal/sailingReducer';
/* eslint no-var: off */

// const navData = [
//     {
//         title: 'POS Selection',
//         selected: 1,
//     },
//     {
//         title: 'Sailing Schedules',
//         selected: 0,
//     },
//     {
//         title: 'Additional Details',
//         selected: 0,
//     },
// ];
// const summaryStats = [
//     {
//         title: 'Total CBM',
//         value: '-',
//     },
//     {
//         title: 'Total Kgs',
//         value: '-',
//     },
// ];
/* eslint-disable no-undefined */

const Index: React.FC = () => {
    const [_showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const { selectedSailing } = useSelector((state: RootState) => state.aosailingRed);
    const headerData = useSelector((state: RootState) => state.bpHeaderData) as any;
    const [isAdded, setisAdded] = useState(false);
    const { id } = useParams();
    const dispatch = useDispatch();
    // const navigate = useNavigate() as any;

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(fetchSelectedSailing(id));
        }, 250);
        return () => clearTimeout(timer);
    }, [id]);
    const navData = [
        {
            title: 'PO Selection',
            selected: 1,
            path: id ? `/lclbooking_list/${id}/POSelection` : '/lclbooking_list/POSelection',
            disabled: isAdded,
        },
        {
            title: 'Sailing Schedules',
            selected: 0,
            path: `/lclbooking_list/${id}/SailingSelectionDetails`,
            disabled: !(headerData?.tabValues as any)?.po,
        },
        {
            title: 'Additional Details',
            selected: 0,
            path: `/lclbooking_list/${id}/additional-details`,
            disabled: !(headerData?.tabValues as any)?.sailing,
        },
        {
            title: 'Container Details',
            selected: 0,
            path: `/LCLbooking_list/${id}/container-allocation`,
            disabled: !(headerData?.tabValues as any)?.sailing,
        },
    ];
    if (
        headerData.bpStatus === 'Ready_for_Booking' ||
        headerData.bpStatus === 'Draft_Booking' ||
        headerData.bpStatus === 'Booked'
    ) {
        navData
            .push
            // {
            //     title: 'Container Details',
            //     selected: 0,
            //     path: `/LCLbooking_list/${id}/container-allocation`,
            //     disabled: false,
            // },
            // {
            //     title: 'Attachments',
            //     selected: 0,
            //     path: `/LCLbooking_list/${id}/attachments`,
            //     disabled: false,
            // }
            ();
    }
    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder booking-proposal-header large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={id ? 'Booking Proposal Details' : 'New Booking Proposal'}
                    secondUrl={'#'}
                    backUrl={'/booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>
                <BookingProcessTab navData={navData} />
            </div>
            {/* DATA GRID SECTION */}
            <BookingAvailableGrid
                setisAdded={setisAdded}
                showBookingCancelModal={showBookingCancelModal}
                setShowBookingCancelModal={setShowBookingCancelModal}
            />
            {/* {showBookingSideBar && (
                <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
            )} */}
        </div>
    );
};
export default Index;
