import React, { useEffect, useState } from 'react';
import '../../assets/scss/components/_breadcrumbs.scss';
import { Link, useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/reducers';
import ShowForPermissions from 'src/ShowForPermissions';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useDispatch } from 'react-redux';
import { awaitingClientApproval, bookingProposalStatusAll, bpActiveStatus } from 'src/utils/constants';
import {
    setFilter,
    setSelectedDropDown,
    setCurrentPage,
} from '../../store/reducers/bookingProposal/bookingProposalListReducer';
import {
    setFilter as setFilterAir,
    setSelectedDropDown as setSelectedDropDownAir,
    setCurrentPage as setCurrentPageAir,
} from 'src/store/reducers/bookingProposalAir/listReducer';

import {
    setFilter as setFilterAo,
    setSelectedDropDown as setSelectedDropDownAo,
    setCurrentPage as setCurrentPageAo,
} from 'src/store/reducers/aobookingProposal/bookingProposalListReducer';
import ResetBp from 'src/pages/BcnBookingProposal/BookingProposalHeader/ResetBp';
import ResetBookingProposal from 'src/pages/BcnBookingProposal/BookingProposalHeader/ResetBookingProposal';
import OverrideBookingProposal from 'src/pages/BcnBookingProposal/BookingProposalHeader/OverrideBookingProposal';
import BookingDeletionModal from 'src/pages/BookingProposal/BookingDeletionModal';
import { success, Position, error } from 'src/utils/toast';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowDown } from '@fortawesome/pro-duotone-svg-icons';
import { fetchQuoteReport, resetQuoteSSRS } from 'src/store/reducers/qmsReducer';

interface PurchaseOrderProps {
    firstName: string;
    firstUrl: string;
    secondName?: string;
    secondUrl?: string;
    thirdName?: string;
    thirdUrl?: string;
    fourthName?: string;
    fourthUrl?: string;
    summaryStats?: any;
    hideCount?: boolean;
    backUrl?: string;
    showError?: boolean;
    handleClickCancel?: () => void; //Work around to handle cancel functionality
    setIsShipmentOverride?: (isShipmentOverride: boolean) => void; // if shipment override, call this functtion
}
const Index: React.FC<PurchaseOrderProps> = (props) => {
    const { bpStatus, gateInDateExpired, bcnType, bpType, bpNumber } = useSelector(
        (state: RootState) => state.bpHeaderData
    );
    const location = useLocation();
    const { profileType, roleName } = useUserProfile();
    const { id } = useParams();
    const [currentPath, setcurrentPath] = useState('');
    const [modalView, setModalView] = useState(false);
    const [modalViewResetBp, setModalViewResetBp] = useState(false);
    const [modalViewOverrideBp, setModalViewOverrideBp] = useState(false);
    const [modalViewDeleteBp, setModalViewDeleteBp] = useState(false);
    const [deleteResponse, setDeleteResponse] = useState('');
    const { overrideShipments } = useSelector((state: RootState) => state.overrideShipments);
    const [isQuoteReportLoading, setIsQuoteReportLoading] = useState(false);
    const dispatch = useDispatch();
    // const { id } = useParams();
    const { ssrsReport } = useSelector((state: RootState) => state.qmsData);

    const checkPath = () => {
        return (
            (location.pathname.includes('/booking_list') ||
                location.pathname.includes('/lclbooking_list') ||
                location.pathname.includes('/air_booking_list') ||
                location.pathname.includes('/Aobooking_list')) &&
            bpStatus === 'Draft_Booking_Proposal' &&
            !location.pathname.includes('manual-creation/new_Schedule')
        );
    };
    const showCancel =
        profileType === 'agent' &&
        (bpStatus === 'Ready_for_Booking' ||
            bpStatus === 'Draft_Booking' ||
            bpStatus === 'Draft_Booking_Proposal' ||
            bpStatus === 'Rejected_by_Forwarder' ||
            bpStatus === 'Rejected_by_Client_Agent_to_Action');
    const cancelButtonLabel =
        bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' ? 'Cancel Booking' : 'Cancel Booking Proposal';

    useEffect(() => {
        setcurrentPath(window.location.pathname);
    }, [window.location.pathname]);

    useEffect(() => {
        if (deleteResponse === 'Y') {
            // navigate to Booking List
            success('Booking has been successfully deleted', Position.TOP_RIGHT);
            setTimeout(() => {
                window.location.href = '/booking_list';
            }, 2000);
        } else if (deleteResponse.split('::')?.length === 2) {
            success(deleteResponse.split('::')[0], Position.TOP_RIGHT);
            // navigate to a new parent
            setTimeout(() => {
                window.location.href = `/bcnBookingList/${deleteResponse.split('::')[1]}/POSelection`;
            }, 2000);
        }
    }, [deleteResponse]);

    useEffect(() => {
        if (ssrsReport !== 'NA' && !ssrsReport.key) {
            console.log(ssrsReport);

            const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${ssrsReport}`;
            const downloadLink = document.createElement('a');
            const fileName = `Quote.xlsx`;
            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
            dispatch(resetQuoteSSRS('NA'));
            setIsQuoteReportLoading(false);
        }
    }, [ssrsReport]);

    window.onpopstate = () => {
        const initialFilter = { bpStatus: profileType === 'client' ? awaitingClientApproval : bpActiveStatus };
        const initialDropDown = {
            text: profileType === 'client' ? awaitingClientApproval : bookingProposalStatusAll,
            count: 0,
        };
        if (currentPath.includes('Aobooking')) {
            dispatch(setCurrentPageAo(0));
            dispatch(setFilterAo(initialFilter));
            dispatch(setSelectedDropDownAo(initialDropDown));
        } else if (currentPath.includes('air_booking')) {
            dispatch(setCurrentPageAir(0));
            dispatch(setFilterAir(initialFilter));
            dispatch(setSelectedDropDownAir(initialDropDown));
        } else {
            dispatch(setCurrentPage(0));
            dispatch(setFilter(initialFilter));
            dispatch(setSelectedDropDown(initialDropDown));
        }
    };
    const errorMessage = () => {
        if (
            ((profileType === 'forwarder' && (bpStatus as any)?.toLowerCase()?.includes('forwarder')) ||
                (profileType === 'client' && (bpStatus as any)?.toLowerCase().includes('client'))) &&
            id &&
            gateInDateExpired
        ) {
            return 'Approval expired. The selected schedule is no longer available, Please reject the booking to change the schedule.';
        } else if (
            gateInDateExpired &&
            profileType === 'agent' &&
            bpStatus !== 'Booked' &&
            bpStatus !== 'Ready_for_Booking' &&
            bpStatus !== 'Draft_Booking' &&
            id
        ) {
            return 'The selected schedule is no longer available. Please select a different schedule to continue Booking';
        } else if (
            (location.pathname.includes('/booking_list') ||
                location.pathname.includes('/lclbooking_list') ||
                location.pathname.includes('/bcnBookingList')) &&
            (bpStatus === 'Awaiting_Client_Approval' ||
                bpStatus === 'Draft_Booking' ||
                bpStatus === 'Ready_for_Booking') &&
            gateInDateExpired &&
            id
        ) {
            return 'Gate in date of the schedule expired, Please change the schedule to proceed with booking';
        } else {
            return '';
        }
    };

    const downloadQuote = () => {
        setIsQuoteReportLoading(true);
        dispatch(fetchQuoteReport({ key: id }));
    };

    return (
        <>
            <div className={`po-breadcrumb-holder ${errorMessage().length > 0 ? 'warning-text' : ''}`}>
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link
                            to={props.backUrl ? props.backUrl : '/booking_list'}
                            state={{ reload: true }}
                            className="dropdown-arrow"
                        >
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to={props.firstUrl} state={{ reload: true }}>
                                {props.firstName}
                            </Link>
                        </li>
                        {props.secondUrl ? (
                            <li className="breadcrumb-list-item">
                                <Link to={props.secondUrl} className="breadcrumb-item">
                                    {props.secondName}
                                </Link>
                            </li>
                        ) : null}
                        {props.thirdUrl ? (
                            <li className="breadcrumb-list-item">
                                <Link to={props.thirdUrl} className="breadcrumb-item">
                                    {props.thirdName}
                                </Link>
                            </li>
                        ) : null}
                        {props.fourthUrl ? (
                            <li className="breadcrumb-list-item">
                                <Link to={props.fourthUrl} className="breadcrumb-item">
                                    {props.fourthName}
                                </Link>
                            </li>
                        ) : null}
                    </ul>
                </div>
                <div className="action-wrapper">
                    <div className="btn-holder discard-po-btn-holder">
                        {!!props.handleClickCancel && showCancel && bcnType !== 'CLD' && (
                            <ShowForPermissions type="bp" permission="create">
                                <button
                                    className="app-btn app-btn-secondary black-outline-btn icon-button"
                                    title={cancelButtonLabel}
                                >
                                    <svg className="svg-icon add-btn-icon">
                                        <use xlinkHref="#deleteIcon"></use>
                                    </svg>
                                    <span className="button-text" onClick={props.handleClickCancel}>
                                        {cancelButtonLabel}
                                    </span>
                                </button>
                            </ShowForPermissions>
                        )}
                        {/* {location.pathname.includes('/bcnBookingList') &&
                            bpStatus === 'Draft_Booking_Proposal' &&
                            bcnType !== 'CLD' && (
                                <ShowForPermissions type="bp" permission="create">
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title="Reset BP"
                                        onClick={() => setModalView(true)}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#resetIcon"></use>
                                        </svg>
                                        <span className="button-text">Reset Booking Proposal</span>
                                    </button>
                                </ShowForPermissions>
                            )} */}
                        {bpStatus &&
                            bpStatus === 'Ready_for_Booking' &&
                            (profileType === 'forwarder' || profileType === 'agent') &&
                            (bpType === 'FCL' || bpType === 'BCN') &&
                            location.pathname.includes('additional-details') && (
                                <ShowForPermissions type="bp" permission="create">
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title="Shipment Override"
                                        onClick={() => setModalViewOverrideBp(true)}
                                        disabled={overrideShipments?.length > 0 && overrideShipments[0].BP_OVR_Status}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                        <span className="button-text">Shipment Override</span>
                                    </button>
                                </ShowForPermissions>
                            )}

                        {bpStatus &&
                            bpStatus !== 'Draft_Booking_Proposal' &&
                            bcnType !== 'CLD' &&
                            roleName?.toLowerCase() === 'executive' && (
                                <ShowForPermissions type="bp" permission="create">
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title="Reset Booking Proposal"
                                        onClick={() => setModalViewResetBp(true)}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#resetIcon"></use>
                                        </svg>
                                        <span className="button-text">Reset Booking Proposal</span>
                                    </button>
                                </ShowForPermissions>
                            )}

                        {checkPath() && (
                            <ShowForPermissions type="bp" permission="create">
                                <Link to={'/booking_list'} style={{ textDecoration: 'none' }}>
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title={'Save & Exit'}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#saveIcon2">
                                                <title>Save & Exit</title>
                                            </use>
                                        </svg>
                                        <span className="button-text">Save & Exit</span>
                                    </button>
                                </Link>
                            </ShowForPermissions>
                        )}

                        {bpStatus && (
                            <ShowForPermissions type="bp" permission="delete" exact={true}>
                                <button
                                    className="app-btn app-btn-primary icon-button"
                                    title=""
                                    onClick={() => setModalViewDeleteBp(true)}
                                >
                                    <svg className="svg-icon add-btn-icon">
                                        <use xlinkHref="#deleteIcon"></use>
                                    </svg>
                                    <span className="button-text">Delete Booking Proposal</span>
                                </button>
                            </ShowForPermissions>
                        )}

                        {props.firstName === 'Quotes' && (
                            <button
                                className="app-btn app-btn-secondary icon-button"
                                title=""
                                onClick={(e) => {
                                    e.preventDefault();
                                    downloadQuote();
                                }}
                            >
                                <svg className="svg-icon add-btn-icon">
                                    <FontAwesomeIcon icon={faFileArrowDown} />
                                </svg>
                                <span className="button-text">
                                    {isQuoteReportLoading ? 'Please Wait...' : 'Download Quote'}
                                </span>
                            </button>
                        )}
                    </div>
                </div>
                {/* {!props?.hideCount && (
                <div className="detail-col-wrapper-page">
                    <div className="page-details-col box-content-holder">
                        <h6 className="details-box-heading">Total CBM</h6>
                        <div className="details-box-content">{totalCBM ? totalCBM : 0}</div>
                    </div>
                    <div className="page-details-col box-content-holder">
                        <h6 className="details-box-heading">Total Kgs</h6>
                        <div className="details-box-content">{totalKgs ? totalKgs : 0}</div>
                    </div>
                    not needed for sprint13-ui for Air fright Booking screen
                    {location.pathname.includes('/air_booking_list') ? null : (
                        <div className="page-details-col box-content-holder">
                            <h6 className="details-box-heading">Estimated TEU</h6>
                            <div className="details-box-content">
                                {Math.ceil(totalCBM / 28) ? Math.ceil(totalCBM / 28) : 0}
                            </div>
                        </div>
                    )}
                </div>
            )} */}
            </div>
            {errorMessage().length > 0 && (
                <div className="warning-text-holder">
                    <div className="warning-icon-text-wrapper">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">{errorMessage()}</div>
                    </div>
                </div>
            )}
            {/* {showError() && (
                <div className="warning-text-holder">
                    <div className="warning-icon-text-wrapper">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Gate in date of the schedule expired, Please change the schedule to proceed with booking
                        </div>
                    </div>
                </div>
            )} */}
            {location.pathname.includes('/bcnBookingList') && bpStatus === 'Draft_Booking_Proposal' && modalView ? (
                <ResetBp setModalView={setModalView} bpId={id ?? ''} />
            ) : null}
            {modalViewResetBp ? <ResetBookingProposal setModalView={setModalViewResetBp} bpId={id ?? ''} /> : null}
            {modalViewOverrideBp ? (
                <OverrideBookingProposal
                    setModalView={setModalViewOverrideBp}
                    setIsShipmentOverride={props.setIsShipmentOverride!}
                    bpId={id ?? ''}
                />
            ) : null}
            {modalViewDeleteBp ? (
                <BookingDeletionModal
                    setModalView={setModalViewDeleteBp}
                    bookingNumber={bpNumber}
                    setDeleteResponse={setDeleteResponse}
                />
            ) : null}
        </>
    );
};

export default Index;
