import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';

interface propsData {
    data?: any;
    keyValue?: string;
}
const DropDownComponent: React.FC<propsData> = ({ data, keyValue }) => {
    const [detailsDropdown, setDetailsDropdown] = useState(false);
    const handleDetailsDropdown = () => {
        setDetailsDropdown(!detailsDropdown);
    };
    //wrapper for outer click check
    const wrapperRefNot = useRef<HTMLDivElement>(null);
    useOutsideAlerter(wrapperRefNot, setDetailsDropdown);

    return (
        <div className="cm-key-pair-value" ref={wrapperRefNot}>
            {keyValue !== 'container' && (data[0]?.poNumber || data[0]?.name) ? (
                <Link
                    to={`/tracking/lifecycle_booking/${
                        keyValue === 'purchase-orders' ? keyValue + '/' + data[0]?.poId : keyValue + '/' + data[0]?.id
                    }`}
                    state={{ name: keyValue === 'purchase-orders' ? data[0]?.poNumber : data[0]?.name }}
                    className="view-more"
                    style={{ cursor: 'pointer' }}
                >
                    {keyValue === 'purchase-orders' ? data[0]?.poNumber ?? '-' : data[0]?.name ?? '-'}
                </Link>
            ) : data?.length > 0 ? (
                typeof data[0] === 'object' ? (
                    data[0].name ?? '-'
                ) : (
                    data[0]
                )
            ) : (
                '-'
            )}

            <span className="show-more-data inline">
                {data?.length > 1 && (
                    <button
                        className="btn-view-all"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDetailsDropdown();
                        }}
                    >
                        +{data?.length - 1}
                    </button>
                )}
                {detailsDropdown ? (
                    <div className="more-data-list customscrollbar">
                        <ul>
                            {data?.length > 1 ? (
                                data?.slice(1).map((res: any) =>
                                    keyValue !== 'container' ? (
                                        <li>
                                            <Link
                                                to={`/tracking/lifecycle_booking/${
                                                    keyValue === 'purchase-orders'
                                                        ? keyValue + '/' + res?.poId
                                                        : keyValue + '/' + res?.id
                                                }`}
                                                state={{
                                                    name: keyValue === 'purchase-orders' ? res?.poNumber : res?.name,
                                                }}
                                            >
                                                {keyValue === 'purchase-orders' ? res?.poNumber : res?.name}
                                            </Link>
                                        </li>
                                    ) : (
                                        <li>
                                            <a style={{ textDecoration: 'none' }}>{res}</a>
                                        </li>
                                    )
                                )
                            ) : keyValue !== 'container' ? (
                                <li>
                                    <Link
                                        to={`/tracking/lifecycle_booking/${
                                            keyValue === 'purchase-orders'
                                                ? keyValue + '/' + data[1]?.poId
                                                : keyValue + '/' + data[1]?.id
                                        }`}
                                        state={{
                                            name: keyValue === 'purchase-orders' ? data[1].poNumber : data[1].name,
                                        }}
                                    >
                                        {keyValue === 'purchase-orders' ? data[1].poNumber : data[1].name}
                                    </Link>
                                </li>
                            ) : (
                                <li>
                                    <a style={{ textDecoration: 'none' }}>{data[1]}</a>
                                </li>
                            )}
                        </ul>
                    </div>
                ) : (
                    ''
                )}
            </span>
        </div>
    );
};
export default DropDownComponent;
