import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BSTooltip from 'src/components/common/BSTooltip';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { RootState } from 'src/store/reducers';
import {
    fetchFavourites,
    removeAllFavourites,
    reOrderFavourites,
    resetRemoveAllErrorFlags,
    resetRemoveAllSuccessFlag,
    resetReOrderFavouritesErrorFlags,
    resetReOrderFavouritesSuccessFlag,
} from 'src/store/reducers/dashboard/insightsFavoritesReducer';
import { error, Position, success } from 'src/utils/toast';
import CancelModal from './CancelModal';
import ClickAwayListener from 'react-click-away-listener';

interface BioProps {
    modalHandle: any;
    modalOpen: boolean;
    data: any[];
}

const Index: React.FC<BioProps> = ({ data, modalHandle, modalOpen }): ReactElement => {
    const dispatch = useDispatch();
    const {
        favourites,
        isReOrderSuccess,
        isReOrderError,
        isRemoveAllError,
        isRemoveAllSuccess,
        error: errorMessage,
    } = useSelector((state: RootState) => state.favourites);
    const { userName, profileType } = useUserProfile();
    const [dataList, setDataList] = useState<any>([]);
    const [removedCount, setRemovedCount] = useState(0);
    const [showSave, setShowSave] = useState(false);
    const [isCancelled, setIsCancelled] = useState(false);

    const setFavKey = () => {
        const fav = JSON.parse(JSON.stringify(favourites));
        for (const iterator of fav) {
            iterator.isfav = true;
        }
        setDataList(fav);
    };

    useEffect(() => {
        setFavKey();
    }, [favourites]);

    useEffect(() => {
        const count = dataList.filter((obj: any) => obj.isfav === false).length;
        setRemovedCount(count);
    }, [dataList]);

    const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
        // change background colour if dragging

        backgroundColor: isDragging ? '#F0F1FF' : 'transparent',
        // styles we need to apply on draggables
        boxShadow: isDragging ? '2px 2px 6px 0px rgba(0, 0, 0, 0.25)' : 'none',
        borderRadius: isDragging ? '4px' : '0px',
        margin: '0px 0px',
        // borderTop: isDragging ? 'none' : '1px solid $grey5',
        ...draggableStyle,
    });
    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    useEffect(() => {
        setShowSave(shouldShowSaveButton());
    }, [dataList]);

    const shouldShowSaveButton = () => {
        for (let index = 0; index < dataList?.length - 1; index++) {
            if (dataList[index].order - dataList[index + 1].order > 0) {
                return true;
            }
        }
        return dataList?.some((x: any) => !x.isfav);
    };

    function onDragEnd(result: any) {
        if (!result.destination) {
            return;
        }
        const resultData = reorder(dataList, result.source.index, result.destination.index);
        setDataList([...resultData]);
    }

    const addOrRemoveFromFav = (element: any) => {
        const item = { ...element, isfav: element.isfav ? false : true };
        const dataArray = JSON.parse(JSON.stringify(dataList));
        const index = dataArray?.findIndex((x: any) => x.id === element.id);
        dataArray[index] = item;
        setDataList(dataArray);
    };

    const saveChanges = () => {
        if (removedCount === dataList?.length) {
            dispatch(removeAllFavourites({ userName }));
        } else {
            const favs = JSON.parse(JSON.stringify(dataList))?.filter((obj: any) => {
                return obj.isfav === true;
            });
            favs.forEach((fav: any, ind: number) => {
                delete fav.isfav;
                fav.order = ind + 1;
            });
            dispatch(reOrderFavourites({ data: { favourites: favs }, userName }));
        }
    };

    useEffect(() => {
        if (isRemoveAllError) {
            error(errorMessage?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetRemoveAllErrorFlags({}));
        }
    }, [isRemoveAllError]);

    useEffect(() => {
        if (isReOrderError) {
            error(errorMessage?.data || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(resetReOrderFavouritesErrorFlags({}));
        }
    }, [isReOrderError]);

    useEffect(() => {
        if (isReOrderSuccess) {
            success('Favourites has been updated.', Position.TOP_RIGHT);
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetReOrderFavouritesSuccessFlag({}));
            modalHandle(false);
        }
    }, [isReOrderSuccess]);

    useEffect(() => {
        if (isRemoveAllSuccess) {
            success('Favourites has been updated.', Position.TOP_RIGHT);
            dispatch(fetchFavourites({ userName, profileType }));
            dispatch(resetRemoveAllSuccessFlag({}));
            modalHandle(false);
        }
    }, [isRemoveAllSuccess]);

    const onDiscardConfirm = () => {
        setFavKey();
        setIsCancelled(false);
        modalHandle(false);
    };

    const removeAllItems = () => {
        const favs = JSON.parse(JSON.stringify(dataList))?.map((obj: any) => {
            return { ...obj, isfav: false };
        });
        setDataList(favs);
    };

    const resetDataToPreviousSaved = () => {
        setFavKey();
    };

    const showRemoveAll = () => {
        if (dataList?.findIndex((x: { isfav: boolean }) => x.isfav === true) >= 0) {
            return true;
        }
        return false;
    };

    return modalOpen ? (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    showSave ? setIsCancelled(true) : modalHandle(false);
                }}
            >
                <div className={`side-bar xl fav-list-modal ${modalOpen ? 'active' : ''}`}>
                    <button
                        className="app-btn text-close-btn"
                        onClick={() => {
                            showSave ? setIsCancelled(true) : modalHandle(false);
                        }}
                    >
                        <svg className="svg-icon text-close-icon">
                            <use href="#closeIcon"></use>
                        </svg>
                    </button>
                    <h2 className="page-heading">Favourites</h2>
                    <div className="sidebar-reset-wrapper">
                        <p className="page-caption-ql">Remove or reorder Insights in the Favourites list</p>
                        {data?.length ? (
                            <div className="reset-link">
                                {showRemoveAll() ? (
                                    <a
                                        href="javascript:void(0)"
                                        className="clear-filter-link"
                                        title="Remove all Favourites"
                                        onClick={() => {
                                            removeAllItems();
                                        }}
                                    >
                                        Remove all Favourites
                                    </a>
                                ) : null}
                                {showSave ? (
                                    <a
                                        href="javascript:void(0)"
                                        className="clear-filter-link"
                                        title="Reset"
                                        onClick={() => {
                                            resetDataToPreviousSaved();
                                        }}
                                    >
                                        Reset
                                    </a>
                                ) : null}
                            </div>
                        ) : null}
                    </div>
                    <div className="page-body details-box custom fav-list customscrollbar">
                        <div>
                            {data.length === 0 && (
                                <div className="no-data-wrapper">
                                    <div className="no-data-content">
                                        <div className="no-data-circle">
                                            <svg className="svg-icon grid-no-content-icon">
                                                <use xlinkHref="#noFavIcon">
                                                    <title>Favourites</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <p className="no-content-message">All Favourites removed</p>
                                    </div>
                                </div>
                            )}
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable-1">
                                    {(provided, _snapshot) => (
                                        <div ref={provided.innerRef} {...provided.droppableProps}>
                                            {dataList?.map((element: any, index: number) => {
                                                return (
                                                    <Draggable
                                                        draggableId={element?.id?.toString()}
                                                        index={index}
                                                        key={element?.id?.toString()}
                                                    >
                                                        {(provided, snapshot) => (
                                                            <>
                                                                <div
                                                                    key={`favList__${index}`}
                                                                    className={`${
                                                                        element.isfav
                                                                            ? 'fav-card-list'
                                                                            : 'fav-card-list unfav'
                                                                    }  ${snapshot.isDragging && 'dragging-component'}`}
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    style={getItemStyle(
                                                                        snapshot.isDragging,
                                                                        provided.draggableProps.style
                                                                    )}
                                                                >
                                                                    <BSTooltip
                                                                        id={`favList__${index}_tooltip`}
                                                                        title={`${
                                                                            element.isfav
                                                                                ? 'Remove from Favourites'
                                                                                : 'Add to Favourites'
                                                                        }`}
                                                                        position={'bottom'}
                                                                        classList="action-btn-wrapper"
                                                                        delay={500}
                                                                    >
                                                                        <button
                                                                            className={`app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                                                                                element.isfav
                                                                                    ? 'selected-fav-icon'
                                                                                    : 'unselected-fav-icon'
                                                                            }`}
                                                                            onClick={() => addOrRemoveFromFav(element)}
                                                                        >
                                                                            <svg className="svg-icon star-icon ">
                                                                                <use xlinkHref="#starIcon">
                                                                                    <title>Favourites</title>
                                                                                </use>
                                                                            </svg>
                                                                        </button>
                                                                    </BSTooltip>
                                                                    <div className="fav-card-title">
                                                                        <h4>{element.favourite}</h4>
                                                                        {element.status && (
                                                                            <span className="status">
                                                                                {element.status}
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="fav-modes">
                                                                        {element.isSea ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="Sea"
                                                                            >
                                                                                <svg className="svg-icon ship-icon">
                                                                                    <use href="#bySeaIcon">
                                                                                        <title>Sea</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </button>
                                                                        ) : null}
                                                                        {element.isAir ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="Air"
                                                                            >
                                                                                <svg className="svg-icon aeroplane-icon">
                                                                                    <use xlinkHref="#byAirIcon">
                                                                                        <title>Air</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </button>
                                                                        ) : null}
                                                                        {element.isLand ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="Land"
                                                                            >
                                                                                <svg className="svg-icon setting-icon">
                                                                                    <use xlinkHref="#byLandIcon">
                                                                                        <title>Land</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </button>
                                                                        ) : null}
                                                                        {element.isInternational ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="International"
                                                                            >
                                                                                <svg className="svg-icon setting-icon">
                                                                                    <use xlinkHref="#globeIcon">
                                                                                        <title>International</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </button>
                                                                        ) : null}
                                                                        {element.isTriangle ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="Triangle"
                                                                            >
                                                                                <svg className="svg-icon setting-icon">
                                                                                    <use xlinkHref="#triangleIcon">
                                                                                        <title>Triangle</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </button>
                                                                        ) : null}
                                                                        {element.isLcl ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="LCL"
                                                                            >
                                                                                <span className="btn-text">LCL</span>
                                                                            </button>
                                                                        ) : null}
                                                                        {element.isBcn ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="BCN"
                                                                            >
                                                                                <span className="btn-text">BCN</span>
                                                                            </button>
                                                                        ) : null}
                                                                        {element.isFcl ? (
                                                                            <button
                                                                                className="action-btn app-btn"
                                                                                title="FCL"
                                                                            >
                                                                                <span className="btn-text">FCL</span>
                                                                            </button>
                                                                        ) : null}
                                                                    </div>
                                                                    <a
                                                                        {...provided.dragHandleProps}
                                                                        className={`app-btn icon-only sm app-btn-secondary drag-icon`}
                                                                    >
                                                                        <svg className="svg-icon ">
                                                                            <use xlinkHref="#dragIcon">
                                                                                <title>Favourites</title>
                                                                            </use>
                                                                        </svg>
                                                                    </a>
                                                                </div>
                                                            </>
                                                        )}
                                                    </Draggable>
                                                );
                                            })}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext>
                        </div>
                    </div>
                    <div className="side-page-footer">
                        {removedCount > 0 ? (
                            <div className="modal-toast rejected">{`${removedCount} removed`}</div>
                        ) : null}
                        {showSave ? (
                            <>
                                <button
                                    className="app-btn app-btn-secondary "
                                    title="Cancel"
                                    onClick={() => setIsCancelled(true)}
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <button className="app-btn app-btn-primary" title="Save" onClick={() => saveChanges()}>
                                    <span className="button-text footer-button-text">Save</span>
                                </button>
                            </>
                        ) : null}
                    </div>
                </div>
            </ClickAwayListener>
            <div className="sidebar-backdrop"></div>
            {isCancelled && <CancelModal onConfirm={onDiscardConfirm} displayFunction={setIsCancelled} />}
        </>
    ) : (
        <></>
    );
};
export default Index;
