import React, { Fragment, useCallback, useEffect, useState } from 'react';
import Breadcrumbs from '../../../../components/Breadcrumbs';
import HeaderTabs from '../HeaderTabs';
// import './style.css';
import '../../../../assets/scss/components/_check-radio.scss';
import './/integration-details.scss';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import {
    addIntegrationData,
    fetchIntegrationData,
    submitAndActivate,
} from '../../../../store/reducers/integrationReducer';
import { integrationColumnHeaders, integrationRowHeaders } from '../../../../utils/constants';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import BackButton from '../../../../components/common/BackButton';
import CancelModal from '../IntegrationDetails/cancelModal';
import { useNavigate } from 'react-router-dom';
import { error, Position, success } from '../../../../utils/toast';
import { fetchPermissions } from '../../../../store/reducers/permissionReducer';
import { fetchHeaderDataClients } from 'src/store/reducers/profiles/clients';
import { fetchBasicData } from '../../../../store/reducers/basicDetailsReducer';

const Index: React.FC = () => {
    const dispatch = useDispatch();
    const { integrationData, clientPK, isLoading } = useSelector((state: RootState) => state.integrationDetails);
    const { clientHeader } = useSelector((state: RootState) => state.profilesClients) as any;
    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const { clientBasicDetails } = useSelector((state: RootState) => state.basicDetails);
    const [showCancelConfirmationModal, setShowCancelConfirmationModal] = useState<boolean>(false);
    const [integrationDetails, setIntegrationDetails] = useState<any>({});
    const [dataCopy, setDataCopy] = useState<any>({});
    const [showSaveButton, setShowSaveButton] = useState<boolean>(false);
    // const [isSubmitVisible, setIsSubmitVisible] = useState<boolean>(false);
    /* eslint no-var: off */
    const [hasDataSaved, setHasDataSaved] = useState<boolean>(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;

    useEffect(() => {
        const payload = { clientPK: id, integrationData: [] };
        dispatch(fetchIntegrationData(payload));
        dispatch(fetchPermissions(username));
        dispatch(fetchBasicData({ clientPK: id }));
    }, [dispatch, id]);

    useEffect(() => {
        const dataObject: any = {};
        for (const item of integrationData) {
            if (dataObject[item.ci_integrationtype]) {
                dataObject[item.ci_integrationtype].push(item.ci_functiontype);
            } else {
                dataObject[item.ci_integrationtype] = [item.ci_functiontype];
            }
        }
        const data = Object.assign({}, dataObject);
        setIntegrationDetails(data);
        setDataCopy(JSON.parse(JSON.stringify(dataObject)));
    }, [integrationData]);

    const updateIntegrationDataOnCheckboxClick = (integrationType: string, integrationFunction: string, event: any) => {
        const dataObject = integrationDetails;
        if (event.target.checked) {
            if (dataObject[integrationType]) {
                dataObject[integrationType].push(integrationFunction);
            } else {
                dataObject[integrationType] = [integrationFunction];
            }
        } else {
            const index = dataObject[integrationType]?.indexOf(integrationFunction);
            if (index >= 0) {
                dataObject[integrationType].splice(index, 1);
            }
        }
        setIntegrationDetails(dataObject);
        setShowSaveButton(true);
        // setIsSubmitVisible(false);
    };

    const checkPermission = (permission: any, type: any) => {
        var flag = true;
        for (var i = 0; i < permissions?.length; i++) {
            if (
                ((permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission.toLowerCase()) &&
                    (permissions[i] as any).access
                        .toLowerCase()
                        .split(/\s+|\./)
                        .includes(type.toLowerCase())) ||
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes('alladmin')
            ) {
                flag = false;
            }
        }
        return flag;
    };
    const saveIntegrationData = () => {
        const finalDataArray = [];
        if (integrationDetails) {
            for (const key in integrationDetails) {
                if (Object.prototype.hasOwnProperty.call(integrationDetails, key)) {
                    for (const item of integrationDetails[key]) {
                        if (key) {
                            const object = {
                                ci_integrationtype: key,
                                ci_functiontype: item,
                            };
                            finalDataArray.push(object);
                        }
                    }
                }
            }
        }
        const payload = {
            cl_pk: clientPK,
            clientintegration: finalDataArray,
            responseOnSuccess: (res: any) => {
                if (res?.success === true) {
                    success('Integration details updated', Position.TOP_RIGHT);
                } else if (res.response?.data?.message) {
                    error(res.response.data.message, Position.TOP_RIGHT);
                }
                dispatch(fetchHeaderDataClients(id));
            },
        };
        dispatch(addIntegrationData(payload));
        // setIsSubmitVisible(true);
        setShowSaveButton(false);
        setHasDataSaved(true);
    };

    const submit = () => {
        const payload = {
            clientPK: clientPK,
            responseOnSuccess: (res: any) => {
                if (res?.success === true) {
                    success(res.message, Position.TOP_RIGHT);
                    setTimeout(() => navigate('/profile'), 1000);
                } else if (res.response?.data?.message) {
                    error(res.response.data.message, Position.TOP_RIGHT);
                }
            },
        };
        dispatch(submitAndActivate(payload));
        // setIsSubmitVisible(false);
        setHasDataSaved(false);
    };

    const resetIntegrationDetails = useCallback(() => {
        const obj = JSON.parse(JSON.stringify(dataCopy));
        setIntegrationDetails(obj);
    }, [dataCopy]);

    const onConfirm = useCallback(() => {
        setShowSaveButton(false);
        // setIsSubmitVisible(hasDataSaved);
        resetIntegrationDetails();
        setShowCancelConfirmationModal(false);
    }, [resetIntegrationDetails, hasDataSaved]);

    const onCancelModal = useCallback(() => {
        setShowCancelConfirmationModal(false);
    }, []);

    return (
        <Fragment>
            <div className="main-wrapper container">
                <div className="main-header-content-holder">
                    <Breadcrumbs second={'Profiles'} third={'Client'} />
                    <BackButton />

                    <HeaderTabs />
                </div>
                <div className="grid-section integration-grid loader-container grid-p-0-15">
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}
                    <div className="grid-header">
                        Integration Details
                        {showSaveButton ? (
                            <div className="grid-header-btn-holder">
                                <button
                                    className="app-btn app-btn-secondary footer-btn"
                                    title="Cancel"
                                    onClick={() => {
                                        setShowCancelConfirmationModal(true);
                                    }}
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <button
                                    className="app-btn app-btn-primary footer-btn "
                                    title="Save"
                                    onClick={() => {
                                        saveIntegrationData();
                                    }}
                                >
                                    <span className="button-text footer-button-text">Save</span>
                                </button>
                            </div>
                        ) : null}
                    </div>
                    <div className="vertical-data-container">
                        {/* vertical table heading */}
                        <div className="vertical-heading-holder">
                            <div className="vertical-heading header-title">INTEGRATION TYPE</div>
                        </div>
                        {showCancelConfirmationModal ? (
                            <CancelModal onConfirm={onConfirm} onCancel={onCancelModal}></CancelModal>
                        ) : null}
                        <div className="grid-container">
                            <div className="grid-scroll-content">
                                <table className="grid-table empty-dashed">
                                    <thead>
                                        <tr className="grid-sticky-row">
                                            <th colSpan={8} className="grid-cell-header centre-align">
                                                <div className="grid-cell-data">
                                                    <span className="header-title">Function</span>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr className="grid-sticky-row">
                                            <th className="grid-cell-header">
                                                <div className="grid-cell-data">
                                                    <span className="header-title"></span>
                                                </div>
                                            </th>
                                            {integrationColumnHeaders.map((headerText: string, index: number) => (
                                                <th className="grid-cell-header centre-align" key={index}>
                                                    <div className="grid-cell-data ">
                                                        <span className="header-title">{headerText}</span>
                                                    </div>
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {integrationDetails &&
                                            integrationRowHeaders.map((header: string, index: number) => (
                                                <tr key={index}>
                                                    <td className="grid-cell move-left">
                                                        <div className="grid-cell-data">{header}</div>
                                                    </td>
                                                    {integrationColumnHeaders.map((item: string, index: number) => (
                                                        <td className="grid-cell centre-align" key={index}>
                                                            <div className="grid-cell-data">
                                                                <div className="check-holder">
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            key={Math.random()}
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                            defaultChecked={integrationDetails[
                                                                                header
                                                                            ]?.includes(item)}
                                                                            onClick={(e: any) => {
                                                                                updateIntegrationDataOnCheckboxClick(
                                                                                    header,
                                                                                    item,
                                                                                    e
                                                                                );
                                                                            }}
                                                                            /* eslint-disable no-undefined */
                                                                            disabled={
                                                                                checkPermission('edit', 'client') !==
                                                                                    undefined &&
                                                                                checkPermission('edit', 'client')
                                                                            }
                                                                        />
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use xlinkHref="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    ))}
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="grid-footer "></div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link style={{ textDecoration: 'none' }} to={`/profile/clients/${id}/auto_approval`}>
                        <button className="app-btn app-btn-secondary footer-btn" title="Previous">
                            <span className="button-text footer-button-text">Previous</span>
                        </button>
                    </Link>

                    {checkPermission('edit', 'client') !== undefined &&
                    !checkPermission('edit', 'client') &&
                    showSaveButton === false &&
                    integrationData.length > 0 &&
                    Object.values(clientHeader).every(Boolean) &&
                    clientBasicDetails?.cl_Status === 'INACTIVE' ? (
                        <button
                            style={{ marginLeft: '8px' }}
                            className="app-btn app-btn-primary footer-btn"
                            title="Submit and Activate"
                            onClick={() => {
                                submit();
                            }}
                        >
                            <span className="button-text footer-button-text">Submit and Activate</span>
                        </button>
                    ) : null}
                    {clientBasicDetails?.cl_Status === 'ACTIVE' ? (
                        <button
                            style={{ marginLeft: '8px' }}
                            className="app-btn app-btn-primary footer-btn"
                            title="Back to Profile"
                            onClick={() => {
                                navigate('/profile');
                            }}
                        >
                            <span className="button-text footer-button-text">Back to Profile</span>
                        </button>
                    ) : null}
                </div>
            </div>
        </Fragment>
    );
};

export default Index;
