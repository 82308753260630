import React from 'react';
import Tile, { TileItem } from './Tile';

interface Props {
    activeKey?: string; //To show active state
    onClick?: (key: string) => void;
    onMouseEnter?: (key: string) => void;
    onMouseLeave?: (key: string) => void;
    tiles: TileItem[];
}
const Index: React.FC<Props> = ({ tiles, onClick, onMouseEnter, onMouseLeave, activeKey }) => {
    return (
        <div className="btn-map-wrapper dashboard-tile-btn">
            {tiles.map((tile) => (
                <Tile
                    key={tile.tileKey}
                    count={tile.count}
                    delayedCount={tile.delayedCount}
                    title={tile.title}
                    tileKey={tile.tileKey}
                    onClick={onClick}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    activeKey={activeKey}
                />
            ))}
        </div>
    );
};
export default Index;
