/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint no-var: off */
/* eslint-disable no-undefined */
import React, { useState, useEffect, useCallback } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
// import ConfirmBookingModal from './ConfirmBookingModal';
import { useAppDispatch, useAppSelector } from 'src/hooks';
import {
    submitPo,
    selectProductDetailsConfirmation,
    fetchProductDetailsConfirmationList,
    selectProductDetailsConfirmationLoading,
    ProductDetailsConfirmationDataDetails,
    SplitLines,
    confirmPo,
    selectProductDetailsConfirmationIsError,
    selectProductDetailsConfirmationSuccess,
    selectProductDetailsConfirmationError,
    clearFailure,
    removeProductDetailsConfirmation,
    editPoLineStatus,
    fetchLclThreshold,
    selectLclThreshold,
    ConfirmationGroups,
    saveAllPo,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import moment from 'moment';
import Breadcrumbs from 'src/components/Breadcrumbs/purchaseOrder';
import { fetchHeader, selectHeader } from 'src/store/reducers/purchaseOrder/addPoReducer';
import './index.scss';
import DatePicker from 'src/components/common/DatePicker/withoutFormik';
import { error, Position, success } from 'src/utils/toast';
import { fetchPermissions } from 'src/store/reducers/permissionReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import ConfirmShipmentModal from './ConfirmShipmentModal';
import { poStatusPillList, qtyStatus, convertFilterName } from './PoStatusList';
import ShowForPermissions from 'src/ShowForPermissions';
import { usePrompt } from 'src/hooks/usePrompt';
import UnsavedChangeModal from 'src/pages/AOC/ProductAllocation/UnsavedChangeModal';
import {
    fetchDestinationAddress,
    selectDestinationAddress,
    fetchPort,
    selectPort,
    selectPortLoading,
} from 'src/store/reducers/allocationOrder/productAllocationReducer';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { MenuPlacement } from 'react-select';
import ConfirmProductsModal from './ConfirmProductsModal';
import ConfirmBookingModal from './ConfirmBookingModal';
import { useUserProfile } from 'src/hooks/useUserProfile';
// import { Formik } from 'formik';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import PODetailsNextButton from 'src/pages/PO/PODetailsNextButton';

const allDistinctValues = [
    'In Booking Proposal',
    'Booked',
    'Pending Confirmation',
    'LCL Under Approval',
    'Dropped',
    'Ready to Receive',
    'Confirmed for Booking',
];
const Index: React.FC = () => {
    const { permissions } = useSelector((state: RootState) => state.permissionData);
    const { id } = useParams();
    const navigate = useNavigate();
    const { userName, fullName } = useUserProfile();
    const dispatch = useAppDispatch();
    const [showAddress, setShowAddress] = useState(false);
    const [menuPlacement, setMenuPlacement] = useState<MenuPlacement>('auto');
    const [ports, setPorts] = useState<any>([]);
    const [autofillData, setAutoFillData] = useState<any>(null);
    const [autofillAddressOpen, setAutofillAddressOpen] = useState(false);
    const [saveAllStatus, setSaveAllStatus] = useState(false);
    const [confirmProductsBpStatus, setConfirmProductsBpStatus] = useState(false);

    const [distanceFromTopForAddress, setDistanceFromTopForAddress] = useState(0);
    const [distanceFromLeftForAddress, setDistanceFromLeftForAddress] = useState(0);
    const [searchName, setSearchName] = useState('');
    const [unsavedChangeModalVisibility, setUnsavedChangeModalVisibility] = useState(false);
    const [callBackFn, setCallBackFn] = useState<any>();
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [filterDropdown, setFilterDropdown] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState('');
    const [distinctValues, setDistinctValues] = useState<string[]>(allDistinctValues);
    const [statusFilterSelected, setStatusFilterSelected] = useState<any>([]);
    const [setPosition, setSetPosition] = useState<number>(0);
    const [search, setSearch] = useState('');
    const [searchKeywordDelete, setSearchKeywordDelete] = useState('');
    const [modalViewConfirmShipment, setModalConfirmShipment] = useState(false);
    const [modalViewConfirm, setModalViewConfirm] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [productPo, setProductPo] = useState<ProductDetailsConfirmationDataDetails[]>([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentSplitIndex, setCurrentSplitIndex] = useState(0);
    const productDetailsConfirmation = useAppSelector(selectProductDetailsConfirmation);
    const destinationAddress = useAppSelector(selectDestinationAddress);
    const port: any = useAppSelector(selectPort);

    const isLoading = useAppSelector(selectProductDetailsConfirmationLoading);
    const isLoadingPort = useAppSelector(selectPortLoading);
    const isError = useAppSelector(selectProductDetailsConfirmationIsError);
    const productDetailsConfirmationSuccess = useAppSelector(selectProductDetailsConfirmationSuccess);
    const productDetailsConfirmationError = useAppSelector(selectProductDetailsConfirmationError);
    const lclThreshold = useAppSelector(selectLclThreshold);
    const headerData = useAppSelector(selectHeader);
    const userData = localStorage.getItem('user');
    const data = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const username = data?.user?.us_UserName;
    const roleIndex = localStorage.getItem('roleIndex');
    const [unlocoLoadingFn, setunlocoLoadingFn] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(
            fetchProductDetailsConfirmationList({
                poId: id,
            })
        );
        dispatch(fetchPermissions(username));
        dispatch(fetchLclThreshold({ userName }));
        // dispatch(fetchPort({ key: 'a', type: 'PO', service: 'ord' }));
    }, []);

    useEffect(() => {
        if (headerData?.clientCode && !destinationAddress?.length) {
            dispatch(fetchDestinationAddress({ orgCode: headerData?.clientCode }));
        }
    }, [headerData?.clientCode]);

    const onPortSearch = (search: string) => {
        setSearchTerm(search);
        if (search.length > 2) {
            dispatch(fetchPort({ key: search, type: 'PO', service: 'ord' }));
        } else {
            setPorts([]);
        }
    };

    const onFocusFn = () => {
        setPorts([]);
    };
    useEffect(() => {
        if (isLoadingPort && searchTerm?.length > 2) {
            setunlocoLoadingFn(true);
        } else {
            setunlocoLoadingFn(false);
        }
    }, [isLoadingPort]);
    useEffect(() => {
        if (port?.length) {
            const mappedPort = port
                ?.map((res: any) => {
                    if (headerData?.isTriangleShipment) {
                        if (res?.portCountry !== 'AU') {
                            return {
                                value: res.portCode,
                                label: `${res.portName} (${res.portCode})`,
                                extraField: res.portName,
                            };
                        }
                    } else {
                        return {
                            value: res.portCode,
                            label: `${res.portName} (${res.portCode})`,
                            extraField: res.portName,
                        };
                    }
                })
                ?.filter(Boolean);
            const initial = [...ports, ...mappedPort];
            const result = initial.reduce((acc: any, item: any) => {
                if (!acc.find((other: any) => item.value == other.value)) {
                    acc.push(item);
                }
                return acc;
            }, []);
            setPorts(result);
        }
    }, [port]);

    useEffect(() => {
        if (productDetailsConfirmation?.enrichedProductDetails?.length) {
            const newArr = JSON.parse(JSON.stringify(productDetailsConfirmation?.enrichedProductDetails));
            for (let index = 0; index < newArr.length; index++) {
                newArr[index]['eachCbm'] = newArr[index]['cbm'] / newArr[index]['qty'];
                newArr[index]['eachWeight'] = newArr[index]['weight'] / newArr[index]['qty'];
                newArr[index].updatedQuantityType = newArr[index].quantityType;
                if (newArr[index]?.status === 'Dropped') {
                    newArr[index]['editMode'] = false;
                    newArr[index]['splitLines'] = [];
                } else if (newArr[index]?.splitLines?.length) {
                    newArr[index]['isExpanded'] = true;
                    newArr[index]['saveTriggered'] = true;
                    for (let j = 0; j < newArr[index]?.splitLines.length; j++) {
                        newArr[index].splitLines[j].editMode = false;
                    }
                } else {
                    newArr[index]['isExpanded'] = true;
                    newArr[index]['saveTriggered'] = true;
                    newArr[index]['editMode'] = true;
                    if (isAgent) {
                        newArr[index]['splitLines'] = [
                            {
                                splitLineId: null,
                                splitLineStatus: null,
                                confirmedQty: newArr[index].qty,
                                confirmedGoodsdate: moment(newArr[index].goodsReadyDate).format('YYYY-MM-DD'),
                                confirmedCbm: parseFloat(newArr[index].cbm?.toFixed(2)),
                                confirmedWeight: parseFloat(newArr[index].weight?.toFixed(2)),
                                confirmedUom: '',
                                convertedQuantity: '',
                                poLineNumber: '',
                                editMode: true,
                                destination: newArr[index].destination,
                                destinationPort: newArr[index].destinationPort,
                                destinationPortName: newArr[index].destinationPortName,
                                destinationAddressId: newArr[index].destinationAddressId,
                                destinationAddress: newArr[index].destinationAddress,
                            },
                        ];
                        const total = newArr[index]?.splitLines.reduce(
                            (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
                            0
                        );
                        if (total === 0) {
                            newArr[index].quantityType = 'Dropped';
                        } else if (total > newArr[index].qty) {
                            newArr[index].quantityType = 'Surplus';
                        } else if (total < newArr[index].qty) {
                            newArr[index].quantityType = 'Short Shipped';
                        } else if (total === newArr[index].qty) {
                            newArr[index].quantityType = 'Fulfilled';
                        }
                    } else {
                        newArr[index]['splitLines'] = [];
                    }
                }
            }
            for (let i = 0; i < newArr.length; i++) {
                newArr[i].indexValue = i;
                const splitLines = newArr[i].splitLines;
                for (let j = 0; j < splitLines.length; j++) {
                    splitLines[j].splitLineIndexValue = j;
                }
            }
            setProductPo(newArr);
            if (newArr?.length) {
                const initial = [...ports];
                if (!newArr[0]?.destination?.includes('(null)')) {
                    initial.push({
                        label: newArr[0].destination,
                        value: newArr[0].destinationPort,
                        extraField: newArr[0].destinationPortName,
                    });
                }

                const result = initial.reduce((acc: any, item: any) => {
                    if (!acc.find((other: any) => item.value == other.value)) {
                        acc.push(item);
                    }

                    return acc;
                }, []);
                setPorts(result);
            }
        } else {
            setProductPo([]);
        }
    }, [productDetailsConfirmation?.enrichedProductDetails]);

    const togglePoLine = (poProductId: string) => {
        const newArr: ProductDetailsConfirmationDataDetails[] = JSON.parse(JSON.stringify(productPo));
        const updatedArr = newArr.map((item) => {
            if (item.poProductId === poProductId) {
                return { ...item, isExpanded: !item.isExpanded };
            } else {
                return item;
            }
        });
        setProductPo(updatedArr);
    };
    const NoMessageComponent = () => {
        return (
            <div className="no-data-content po-no-data">
                <svg className="svg-icon grid-no-content-icon">
                    <use xlinkHref="#gridNoContentIcon"></use>
                </svg>
                <p className="no-content-message">
                    {searchTerm?.length < 3
                        ? 'Enter at least 3 characters to search'
                        : unlocoLoadingFn
                        ? 'Loading...'
                        : 'No matches'}
                </p>
            </div>
        );
    };
    const getRemainingQty = (index: number) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        const total = newArr[index]?.splitLines.reduce(
            (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
            0
        );
        if (total === 0) {
            return <span className="second-unit">(R:{newArr[index].qty})</span>;
        } else {
            if (total > newArr[index].qty) {
                const remaining = total - newArr[index].qty;
                if (newArr[index].saveTriggered) {
                    return <span className="second-unit">(R:0)</span>;
                } else {
                    return <span className="second-unit error-text">(R:0, S:{remaining})</span>;
                }
            } else {
                const remaining = newArr[index].qty - total;
                if (newArr[index].isShort) {
                    return <span className="second-unit">(R:0)</span>;
                } else {
                    return <span className="second-unit">(R:{remaining})</span>;
                }
            }
        }
    };

    const appendNewRow = (index: number) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        const newData = {
            splitLineId: null,
            splitLineStatus: null,
            confirmedQty: '',
            confirmedGoodsdate: '',
            confirmedCbm: '',
            confirmedWeight: '',
            confirmedUom: '',
            convertedQuantity: '',
            poLineNumber: '',
            editMode: true,
            splitLineIndexValue: '',
            destination: newArr[index].destination,
            destinationPort: newArr[index].destinationPort,
            destinationPortName: newArr[index].destinationPortName,
            destinationAddressId: newArr[index].destinationAddressId,
            destinationAddress: newArr[index].destinationAddress,
        };
        newArr[index].splitLines.push(newData);
        for (let i = 0; i < newArr.length; i++) {
            newArr[i].indexValue = i;
            const splitLines = newArr[i].splitLines;
            for (let j = 0; j < splitLines.length; j++) {
                splitLines[j].splitLineIndexValue = j;
            }
        }
        setProductPo(newArr);

        const initial = [...ports];

        if (!newArr[index]?.destination?.includes('(null)')) {
            initial.push({
                label: newArr[index].destination,
                value: newArr[index].destinationPort,
                extraField: newArr[index].destinationPortName,
            });
        }

        const result = initial.reduce((acc: any, item: any) => {
            if (!acc.find((other: any) => item.value == other.value)) {
                acc.push(item);
            }

            return acc;
        }, []);
        setPorts(result);
    };

    const checkIfIsInEditMode = (index: number) => {
        return productPo[index].splitLines.some((e) => e.editMode);
    };

    const getAllPendingConfirmationLines = () => {
        const pendingConfirmationPo = [];
        for (let i = 0; i < productPo.length; i++) {
            const splitLines = productPo[i].splitLines;
            for (let j = 0; j < splitLines.length; j++) {
                if (splitLines[j].splitLineStatus === 'Pending_confirmation') {
                    if (pendingConfirmationPo.indexOf(splitLines[j].splitLineId) === -1) {
                        pendingConfirmationPo.push(splitLines[j].splitLineId);
                    }
                }
            }
        }

        return pendingConfirmationPo;
    };

    const getAllConfirmationGroups = () => {
        const confirmationGroups: ConfirmationGroups[] = [];
        const lclThresholdPercentage = lclThreshold / 100;
        let count = headerData?.totalConfirmationGroups ? headerData?.totalConfirmationGroups + 1 : 1;
        for (let i = 0; i < productPo.length; i++) {
            const splitLines = productPo[i].splitLines;
            for (let j = 0; j < splitLines.length; j++) {
                if (splitLines[j].splitLineStatus === 'Pending_confirmation' && !splitLines[j].confirmationGroup) {
                    const checkIfExists = confirmationGroups?.findIndex((o: any) => {
                        return (
                            o?.goodsReadyDate === moment(splitLines[j].confirmedGoodsdate).format('DD-MM-YYYY') &&
                            o?.destinationPort === splitLines[j].destinationPort
                        );
                    });
                    if (checkIfExists >= 0) {
                        const cbmSum = confirmationGroups[checkIfExists].cbm + splitLines[j].confirmedCbm;
                        confirmationGroups[checkIfExists] = {
                            ...confirmationGroups[checkIfExists],
                            type: cbmSum <= lclThresholdPercentage * 28 ? 'LCL' : 'FCL',
                            poLines: confirmationGroups[checkIfExists].poLines + 1,
                            cbm: cbmSum,
                            weight: confirmationGroups[checkIfExists].weight + splitLines[j].confirmedWeight,
                            subPoLinesId: [
                                ...confirmationGroups[checkIfExists].subPoLinesId,
                                splitLines[j].splitLineId,
                            ],
                        };
                    } else {
                        const data: ConfirmationGroups = {
                            confirmationGroupName: '',
                            type: splitLines[j].confirmedCbm <= lclThresholdPercentage * 28 ? 'LCL' : 'FCL',
                            poLines: 1,
                            cbm: splitLines[j].confirmedCbm,
                            weight: splitLines[j].confirmedWeight,
                            goodsReadyDate: splitLines[j].confirmedGoodsdate
                                ? moment(splitLines[j].confirmedGoodsdate).format('DD-MM-YYYY')
                                : '',
                            destinationPortName: splitLines[j].destinationPortName,
                            destinationPort: splitLines[j].destinationPort,
                            createdUserId: userName,
                            createdUser: fullName,
                            subPoLinesId: [splitLines[j].splitLineId],
                        };
                        confirmationGroups.push(data);
                        count++;
                    }
                }
            }
        }

        return { poId: id || '', confirmationGroups };
    };

    const checkAtLeastOneEditExists = () => {
        const editMode = [];
        for (let i = 0; i < productPo.length; i++) {
            const splitLines = productPo[i].splitLines;
            for (let j = 0; j < splitLines.length; j++) {
                if (
                    splitLines[j].editMode &&
                    (typeof splitLines[j].confirmedQty === 'number' || splitLines[j].confirmedGoodsdate != '')
                ) {
                    editMode.push(splitLines[j]);
                }
            }
        }

        return editMode?.length ? true : false;
    };

    const updateFieldChanged = (index: number, splitLineIndex: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        // newArr[index].splitLines[splitLineIndex] = {
        //     ...newArr[index].splitLines[splitLineIndex],
        //     [e.target.name]: +e.target.value,
        //     confirmedCbm: parseFloat((+e.target.value * newArr[index].eachCbm).toFixed(5)),
        //     confirmedWeight: parseFloat((+e.target.value * newArr[index].eachWeight).toFixed(5)),
        // };
        if (e.target.name === 'confirmedCbm' || e.target.name === 'confirmedWeight') {
            newArr[index].splitLines[splitLineIndex] = {
                ...newArr[index].splitLines[splitLineIndex],
                [e.target.name]: parseFloat(Number(e.target.value)?.toFixed(2)),
            };
        } else {
            newArr[index].splitLines[splitLineIndex] = {
                ...newArr[index].splitLines[splitLineIndex],
                [e.target.name]: +e.target.value,
                confirmedCbm: parseFloat((+e.target.value * newArr[index].eachCbm).toFixed(2)),
                confirmedWeight: parseFloat((+e.target.value * newArr[index].eachWeight).toFixed(2)),
            };
        }
        const total = newArr[index]?.splitLines.reduce(
            (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
            0
        );
        if (total === 0) {
            newArr[index].quantityType = 'Dropped';
        } else if (total > newArr[index].qty) {
            newArr[index].quantityType = 'Surplus';
        } else if (total < newArr[index].qty) {
            newArr[index].quantityType = 'Short Shipped';
        } else if (total === newArr[index].qty) {
            newArr[index].quantityType = 'Fulfilled';
        }
        newArr[index].saveTriggered = false;

        setProductPo(newArr);
    };

    const updateDateChanged = (date: string) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        newArr[currentIndex].splitLines[currentSplitIndex] = {
            ...newArr[currentIndex].splitLines[currentSplitIndex],
            confirmedGoodsdate: moment(date).format('YYYY-MM-DD'),
        };

        setProductPo(newArr);
    };

    const dateChangeAutofill = (date: string) => {
        setAutoFillData((prev: any) => ({
            ...prev,
            goodsReadyDate: date ? moment(date).format('YYYY-MM-DD') : null,
        }));
    };

    const removePoLine = (index: number, splitLineIndex: number, splitLineId: string) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        newArr[index].splitLines.splice(splitLineIndex, 1);
        if (newArr[index]?.splitLines?.length) {
            const total = newArr[index]?.splitLines.reduce(
                (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
                0
            );
            if (total === 0) {
                // newArr[index].quantityType = 'Dropped';
            } else if (total > newArr[index].qty) {
                newArr[index].quantityType = 'Surplus';
            } else if (total < newArr[index].qty) {
                newArr[index].quantityType = 'Short Shipped';
            } else if (total === newArr[index].qty) {
                newArr[index].quantityType = 'Fulfilled';
            }
        } else {
            newArr[index].quantityType = 'Pending';
        }
        if (splitLineId) {
            const quantityType = newArr[index].quantityType === 'Short Shipped' ? 'Short' : newArr[index].quantityType;
            dispatch(
                removeProductDetailsConfirmation({
                    splitLineId,
                    quantityType,
                    currentIndex: index,
                    currentSplitLineIndex: splitLineIndex,
                    isShort: false,
                })
            );
        }
        for (let i = 0; i < newArr.length; i++) {
            newArr[i].indexValue = i;
            const splitLines = newArr[i].splitLines;
            for (let j = 0; j < splitLines.length; j++) {
                splitLines[j].splitLineIndexValue = j;
            }
        }
        setProductPo(newArr);
    };
    const confirmPoLine = (index: number, splitLineIndex: number) => {
        setCurrentIndex(index);
        setCurrentSplitIndex(splitLineIndex);
        const newArr = JSON.parse(JSON.stringify(productPo));
        if (newArr[index].quantityType === 'Surplus') {
            setModalConfirmShipment(true);
        } else {
            if (newArr[index].splitLines[splitLineIndex].splitLineId) {
                dispatch(
                    editPoLineStatus({
                        ...newArr[index].splitLines[splitLineIndex],
                        splitLineId: newArr[index].splitLines[splitLineIndex].splitLineId,
                        quantityType: newArr[index].quantityType,
                        currentIndex: index,
                        currentSplitLineIndex: splitLineIndex,
                        isShort: false,
                    })
                );
            } else {
                dispatch(
                    confirmPo({
                        ...newArr[index].splitLines[splitLineIndex],
                        poProductId: productPo[index].poProductId,
                        quantityType: newArr[index].quantityType,
                        currentIndex: index,
                        currentSplitLineIndex: splitLineIndex,
                        isShort: false,
                    })
                );
            }
            newArr[index].splitLines[splitLineIndex].editMode = false;
            setProductPo(newArr);
        }
    };
    const saveAll = () => {
        setSaveAllStatus(true);
        dispatch(saveAllPo(productPo));
    };

    const autoFill = () => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        for (let i = 0; i < newArr.length; i++) {
            for (let j = 0; j < newArr[i].splitLines.length; j++) {
                if (newArr[i].splitLines[j].editMode) {
                    newArr[i].splitLines[j].confirmedGoodsdate = autofillData?.goodsReadyDate;
                    newArr[i].splitLines[j].destination = autofillData?.destination;
                    newArr[i].splitLines[j].destinationPort = autofillData?.destinationPort;
                    newArr[i].splitLines[j].destinationPortName = autofillData?.destinationPortName;
                    newArr[i].splitLines[j].destinationAddress = autofillData?.destinationAddress;
                    newArr[i].splitLines[j].destinationAddressId = autofillData?.destinationAddressId;
                }
            }
        }
        setProductPo(newArr);
    };

    const checkSaveAllStatus = () => {
        if (!headerData?.isAgentAssigned) {
            return true;
        }
        let disabled = false;
        let breakItem = false;
        let editCount = 0;
        for (let i = 0; i < productPo.length; i++) {
            for (let j = 0; j < productPo[i].splitLines.length; j++) {
                disabled =
                    productPo[i].splitLines[j].editMode &&
                    // !productPo[i].splitLines[j].confirmedWeight ||
                    // !productPo[i].splitLines[j].confirmedCbm ||
                    !productPo[i].splitLines[j].confirmedGoodsdate
                        ? true
                        : false;

                if (productPo[i].splitLines[j].editMode) {
                    editCount++;
                }
                if (disabled) {
                    breakItem = true;
                    break;
                }
            }
            if (breakItem) {
                break;
            }
        }
        if (editCount === 0) {
            return true;
        }
        return disabled;
    };
    const editPoLine = (index: number, splitLineIndex: number) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        newArr[index].splitLines[splitLineIndex].editMode = true;
        setProductPo(newArr);
        const initial = [...ports];

        if (!newArr[index]?.splitLines[splitLineIndex].destination?.includes('(null)')) {
            initial.push({
                label: newArr[index].splitLines[splitLineIndex].destination,
                value: newArr[index].splitLines[splitLineIndex].destinationPort,
                extraField: newArr[index].splitLines[splitLineIndex].destinationPortName,
            });
        }

        const result = initial.reduce((acc: any, item: any) => {
            if (!acc.find((other: any) => item.value == other.value)) {
                acc.push(item);
            }

            return acc;
        }, []);
        setPorts(result);
    };

    const checkSaveButtonStatus = (splitLine: SplitLines) => {
        if (!headerData?.isAgentAssigned) {
            return true;
        } else if (
            splitLine?.confirmedQty &&
            splitLine?.confirmedQty >= 0 &&
            // splitLine?.confirmedCbm > 0 &&
            // splitLine?.confirmedWeight > 0 &&
            splitLine?.confirmedGoodsdate &&
            splitLine?.destination &&
            splitLine?.destinationAddress
        ) {
            return false;
        } else {
            return true;
        }
    };

    const updateAfterFormSubmit = (index: number, splitLineIndex: number, type: string) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        if (typeof index === 'number' && newArr[index]?.quantityType) {
            newArr[index].updatedQuantityType = newArr[index].quantityType;
        }
        if (type === 'confirmPo') {
            newArr[index].saveTriggered = true;
            newArr[index].splitLines[splitLineIndex].editMode = false;
            newArr[index].splitLines[splitLineIndex].splitLineStatus =
                productDetailsConfirmationSuccess?.data?.info?.splitLineStatus;
            newArr[index].isShort = false;
            const total = newArr[index]?.splitLines.reduce(
                (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
                0
            );
            if (total === 0) {
                newArr[index].status = 'Dropped';
            }
            newArr[index].splitLines[splitLineIndex].splitLineId =
                productDetailsConfirmationSuccess?.data?.info?.splitLineId;
        }
        if (type === 'editPoLineStatus') {
            newArr[index].saveTriggered = true;
            newArr[index].splitLines[splitLineIndex].editMode = false;
            newArr[index].isShort = false;
            newArr[index].splitLines[splitLineIndex].splitLineStatus =
                productDetailsConfirmationSuccess?.data?.info?.splitLineStatus;
            const total = newArr[index]?.splitLines.reduce(
                (a: number, b: any) => +a + (b.confirmedQty ? b.confirmedQty : 0),
                0
            );
            if (total === 0) {
                newArr[index].status = 'Dropped';
            }
        }
        if (type === 'changePoLineStatus') {
            newArr[index].saveTriggered = true;
            newArr[index].isShort = true;
        }
        if (type === 'dropPo') {
            newArr[index].saveTriggered = true;
            newArr[index].status = 'Dropped';
            newArr[index].quantityType = 'Dropped';
            newArr[index].qty = 0;
            newArr[index].weight = 0;
            newArr[index].cbm = 0;
        }
        if (type === 'removePo') {
            for (let i = 0; i < newArr.length; i++) {
                newArr[i].indexValue = i;
                const splitLines = newArr[i].splitLines;
                for (let j = 0; j < splitLines.length; j++) {
                    splitLines[j].splitLineIndexValue = j;
                }
            }
        }
        setProductPo(newArr);
    };

    const updateData = (data: any, type: string) => {
        const newArr = JSON.parse(JSON.stringify(productPo));
        if (type === 'address') {
            if (autofillAddressOpen) {
                setAutoFillData((prev: any) => ({
                    ...prev,
                    destinationAddressId: data?.oa_PK ? data.oa_PK : data.addressId,
                    destinationAddress: data?.fullAddress,
                }));
                return;
            } else {
                newArr[currentIndex].splitLines[currentSplitIndex].destinationAddressId = data?.oa_PK
                    ? data.oa_PK
                    : data.addressId;
                newArr[currentIndex].splitLines[currentSplitIndex].destinationAddress = data.fullAddress;
            }
        } else {
            newArr[currentIndex].splitLines[currentSplitIndex].destinationPortName = data?.extraField;
            newArr[currentIndex].splitLines[currentSplitIndex].destinationPort = data?.value;
            newArr[currentIndex].splitLines[currentSplitIndex].destination = data?.label;
            newArr[currentIndex].splitLines[currentSplitIndex].destinationAddressId = '';
            newArr[currentIndex].splitLines[currentSplitIndex].destinationAddress = '';
        }
        setProductPo(newArr);
    };
    const updateDataDestination = (data: any) => {
        setAutoFillData((prev: any) => ({
            ...prev,
            destinationPortName: data?.extraField,
            destinationPort: data?.value,
            destination: data?.label,
        }));
    };

    useEffect(() => {
        if (isError) {
            error(productDetailsConfirmationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            setSaveAllStatus(false);
            setConfirmProductsBpStatus(false);
        } else if (
            productDetailsConfirmationSuccess?.status === 200 ||
            productDetailsConfirmationSuccess?.status === 201
        ) {
            if (
                productDetailsConfirmationSuccess?.data?.type === 'confirmPo' ||
                productDetailsConfirmationSuccess?.data?.type === 'editPoLineStatus'
            ) {
                success(
                    `PO Line ${
                        productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            ? productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            : ''
                    } confirmed successfully`,
                    Position.TOP_RIGHT
                );
            } else if (productDetailsConfirmationSuccess?.data?.type === 'submitPo') {
                success(`PO ${headerData?.poNumber} has been confirmed for Booking Proposal`, Position.TOP_RIGHT);
                setTimeout(() => {
                    // if (headerData?.shippingMode?.shippingModeCode === 'AIR') {
                    //     navigate(`/purchase_order`);
                    // } else {
                    navigate(`/purchase_order/${id}/container_mode_selection`);
                    // }
                }, 2000);
            } else if (productDetailsConfirmationSuccess?.data?.type === 'removePo') {
                success('Split line removed successfully', Position.TOP_RIGHT);
            } else if (productDetailsConfirmationSuccess?.data?.type === 'changePoLineStatus') {
                success(
                    `PO Line ${
                        productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            ? productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            : ''
                    } confirmed as Short Shipped`,
                    Position.TOP_RIGHT
                );
            } else if (productDetailsConfirmationSuccess?.data?.type === 'dropPo') {
                success(
                    `PO Line ${
                        productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            ? productDetailsConfirmationSuccess?.data?.info?.poLineNumber
                            : ''
                    } dropped`,
                    Position.TOP_RIGHT
                );
            }
            if (productDetailsConfirmationSuccess?.data?.type === 'saveAllPo') {
                success('All PO lines confirmed successfully', Position.TOP_RIGHT);
                dispatch(fetchProductDetailsConfirmationList({ poId: id }));
                dispatch(fetchHeader({ poId: id }));
            } else {
                updateAfterFormSubmit(
                    productDetailsConfirmationSuccess?.data?.currentIndex,
                    productDetailsConfirmationSuccess?.data?.currentSplitLineIndex,
                    productDetailsConfirmationSuccess?.data?.type
                );
                dispatch(clearFailure([]));
                if (!checkAtLeastOneEditExists()) {
                    dispatch(fetchProductDetailsConfirmationList({ poId: id }));
                }
                dispatch(fetchHeader({ poId: id }));
            }
            setSaveAllStatus(false);
            setConfirmProductsBpStatus(false);
        }
    }, [isError, productDetailsConfirmationSuccess, productDetailsConfirmationError]);

    const searchOrFilterFn = (productsData: ProductDetailsConfirmationDataDetails[], includeFilter: boolean) => {
        if (searchKeywordDelete || search.trim().length > 2 || statusFilterSelected?.length || selectedFilter) {
            const searchKey = searchKeywordDelete.toLocaleLowerCase();
            const duplicate = JSON.parse(JSON.stringify(productsData));
            let searchResult = duplicate?.filter((el: any) => {
                const searchCondition = searchKey ? el.sku.toLocaleLowerCase().includes(searchKey) : true;
                return searchCondition;
            });
            if (selectedFilter && includeFilter) {
                if (selectedFilter === 'Short Shipped') {
                    searchResult = searchResult?.filter(
                        (res: any) => res.updatedQuantityType === selectedFilter && res?.isShort
                    );
                } else if (selectedFilter === 'Pending') {
                    searchResult = searchResult?.filter(
                        (res: any) =>
                            res.updatedQuantityType === selectedFilter ||
                            (res.updatedQuantityType === 'Short Shipped' && !res?.isShort)
                    );
                } else {
                    searchResult = searchResult?.filter((res: any) => res.updatedQuantityType === selectedFilter);
                }
            }

            if (statusFilterSelected?.length) {
                let filtered: any = [];
                for (let i = 0; i < searchResult.length; i++) {
                    if (statusFilterSelected.includes('Dropped')) {
                        filtered = searchResult?.filter((res: any) => res.status === 'Dropped');
                    } else {
                        const splitLines = searchResult[i].splitLines;
                        const onePoLine = splitLines.some((e: any) =>
                            statusFilterSelected.includes(convertFilterName(e.splitLineStatus))
                        );

                        if (onePoLine) {
                            filtered.push(searchResult[i]);
                            const filteredIndex = filtered?.findIndex(
                                (res: any) => res.poProductId === searchResult[i].poProductId
                            );
                            if (filteredIndex >= 0) {
                                const filteredSplitLines = filtered[filteredIndex].splitLines.filter((res: any) =>
                                    statusFilterSelected.includes(convertFilterName(res.splitLineStatus))
                                );
                                filtered[filteredIndex].splitLines = filteredSplitLines;
                            }
                        }
                    }
                }
                return filtered;
            } else {
                return searchResult;
            }
        } else {
            return productsData;
        }
    };

    const searchOrFilter = searchOrFilterFn(productPo, true);

    useEffect(() => {
        if (productPo?.length && !autofillData) {
            const data = {
                goodsReadyDate: productPo?.[0]?.goodsReadyDate,
                destination: productPo?.[0]?.destination,
                destinationAddress: productPo?.[0]?.destinationAddress,
                destinationAddressId: productPo?.[0]?.destinationAddressId,
                destinationPort: productPo?.[0]?.destinationPort,
                destinationPortName: productPo?.[0]?.destinationPortName,
            };
            setAutoFillData(data);
        }
    }, [productPo]);

    useEffect(() => {
        if (search.trim().length === 0) {
            setSearchKeywordDelete('');
        } else if (search.trim().length > 2) {
            setSearchKeywordDelete(search);
        }
    }, [search]);

    const getFilterCount = (filter: string) => {
        const result = searchOrFilterFn(productPo, false);
        if (filter === '') {
            return result?.length;
        } else {
            if (filter === 'Short Shipped') {
                return result?.filter((res: any) => res.updatedQuantityType === filter && res?.isShort)?.length;
            } else if (filter === 'Pending') {
                return result?.filter(
                    (res: any) =>
                        res.updatedQuantityType === filter ||
                        (res.updatedQuantityType === 'Short Shipped' && !res?.isShort)
                )?.length;
            } else {
                return result?.filter((res: any) => res.updatedQuantityType === filter)?.length;
            }
        }
    };

    const filterByColumn = (e: any) => {
        setToggleDropdown(!toggleDropdown);
        e.stopPropagation();
    };

    const resizeCheckboxFilter = () => {
        const el_filter_div = document.getElementById('filter_div');
        if (el_filter_div?.getBoundingClientRect().left) {
            setSetPosition(el_filter_div?.getBoundingClientRect().left - 35);
        }
    };

    const filterCheckbox = (e: React.ChangeEvent<any>) => {
        const keyword = e.target.value;
        if (keyword) {
            const results = allDistinctValues.filter((user: string) => {
                return user && user?.toLowerCase()?.includes(keyword?.toLowerCase());
            });
            setDistinctValues(results);
        } else {
            setDistinctValues(allDistinctValues);
        }
        setSearchName(keyword);
    };

    const resizeFilter = (idSelector: string) => {
        const el_table = document.getElementById('table_div');
        let gridDistance = 0;
        let gridDistanceLeft = 0;
        if (el_table?.getBoundingClientRect().top) {
            gridDistance = el_table?.getBoundingClientRect().top - 40;
            gridDistanceLeft = el_table?.getBoundingClientRect().left + 4;
        }

        const el_address = document.getElementById(idSelector);
        const dropdown_address_bottom = document.getElementById(idSelector)?.getBoundingClientRect().bottom || 0;
        const spaceBelow = window.innerHeight - dropdown_address_bottom;

        if (el_address?.getBoundingClientRect().top) {
            if (spaceBelow < 150) {
                gridDistance = gridDistance + 110;
            }
            setDistanceFromTopForAddress(el_address?.getBoundingClientRect().top - gridDistance);
        }
        if (el_address?.getBoundingClientRect().left) {
            setDistanceFromLeftForAddress(el_address?.getBoundingClientRect().left - gridDistanceLeft);
        }
    };

    const menuPlacementCheck = (index: number, splitLineIndex: number) => {
        const windowHeight = document.documentElement.clientHeight;
        const noMessageHeight = 300;

        const element = document.getElementById(`edit_row_${index}_${splitLineIndex}`);
        if (element) {
            const bottom = element.getBoundingClientRect().bottom;
            const spaceBelow = windowHeight - bottom;
            spaceBelow < noMessageHeight ? setMenuPlacement('top') : setMenuPlacement('auto');
        }
    };

    const checkPermission = (permission: any, type: any) => {
        var flag = true;
        //    console.log("checkPermission():flag:" + flag);

        for (var i = 0; i < permissions?.length; i++) {
            //   console.log("permissions[i]:" + permissions[i]);
            if (
                (permissions[i] as any).access
                    ?.toLowerCase()
                    .split(/\s+|\./)
                    .includes(permission?.toLowerCase()) &&
                (permissions[i] as any).access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .includes(type?.toLowerCase())
            ) {
                flag = false;
            }
        }
        return flag;
    };
    let isAgent = false;
    if (userData) {
        const { roles } = JSON.parse(userData);
        if (roleIndex) {
            isAgent =
                roles[roleIndex].profile?.toLowerCase() === 'agent' ||
                roles[roleIndex].profile?.toLowerCase() === 'forwarder' ||
                roles[roleIndex].profile?.toLowerCase() === 'client' ||
                roles[roleIndex].profile?.toLowerCase() === 'vendor'
                    ? true
                    : false;

            //console.log("checkPermission():isAgent:" + isAgent);
        }
    }

    const customStyle = {
        menuPortal: (provided: any) => ({
            ...provided,
            width: '350px',
            zIndex: 999,
        }),
        menuList: (base: any) => ({
            ...base,
            '::-webkit-scrollbar': {
                width: '5px',
                backgroundColor: '#F6F6FE',
            },
            '::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                boxShadow: 'inset 0 0 6px rgba(#000, 0.3)',
                backgroundColor: '#C4C6D0',
            },
        }),
    };

    const portalStyle = {
        ...customStyle,
        noOptionsMessage: (base: any) => ({ ...base, height: '180px' }),
    };

    const handleConfirm = useCallback(() => {
        setShowConfirmModal(false);
        dispatch(submitPo({ poId: headerData?.poId, isAirFreight: true }));
    }, [headerData?.poId]);

    // usePrompt('Leave screen?', checkAtLeastOneEditExists(), setUnsavedChangeModalVisibility, setCallBackFn);

    const getTitle = (splitLine: SplitLines) => {
        if (!headerData?.isAgentAssigned) {
            return 'No agent assigned';
        } else {
            return 'Save Line';
        }
    };

    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';

    return (
        <>
            <div className="main-wrapper container product-details-confirmation ">
                <div className="main-header-content-holder large-header">
                    <Breadcrumbs
                        firstName={`${poName}`}
                        firstUrl={`${poUrl}`}
                        secondName="PO Details"
                        secondUrl={`/purchase_order/${id}/product_details_confirmation`}
                    />
                    <PurchaseOrderHeader id={id} />
                    <PODetailsHeaderTabs />
                </div>
                <div
                    onClick={() => {
                        setFilterDropdown(false);
                        setShowAddress(false);
                        setAutofillAddressOpen(false);
                    }}
                    className="content-section main-wrapper ao-product-detail-confirmation-wrapper grid-p-0-15"
                >
                    {isLoading && (
                        <div className="app-loader loading">
                            <svg className="svg-icon loader-icon">
                                <use xlinkHref="#loaderIcon">
                                    <title>Loading</title>
                                </use>
                            </svg>
                        </div>
                    )}

                    <div className="search-field-purchase-grid" style={{ alignItems: 'end' }}>
                        <div className="search-entry-holder">
                            <input
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                                placeholder="Search by SKU"
                                type="text"
                                className="search-input input-text"
                            ></input>
                            <div
                                className={`search-toolip ${
                                    search?.length > 0 && search?.length < 3 ? 'tooltip-show' : ''
                                }`}
                            >
                                Enter atleast 3 characters to search
                            </div>
                            <svg className="svg-icon search-icon">
                                <use href="#searchIcon"></use>
                            </svg>
                            <button
                                onClick={() => {
                                    setSearch('');
                                }}
                                type="button"
                                className="app-btn text-close-btn"
                            >
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </div>
                        <div
                            className={`dropdown-wrap ${
                                filterDropdown ? 'dropdown-open' : ''
                            } grid-header-dropdown search-toast-content-holder align-right`}
                        >
                            <button
                                onClick={(e) => {
                                    setFilterDropdown((prev: boolean) => !prev);
                                    e.stopPropagation();
                                }}
                                type="button"
                                className="app-btn app-btn-secondary"
                            >
                                <div className="purchase-dropdown-content">
                                    <span className="button-text">Quantity : </span>
                                    <div className="content-heading-holder">
                                        <span style={{ whiteSpace: 'pre-wrap' }} className="content-heading">
                                            {' '}
                                            {selectedFilter ? selectedFilter : 'All'}
                                        </span>
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{getFilterCount(selectedFilter)}</div>
                                        </span>
                                    </div>
                                </div>

                                <svg className="svg-icon arrow-icon">
                                    <use href="#downArrow">
                                        <title>Expand row</title>
                                    </use>
                                </svg>
                            </button>
                            <div className="dropdown-menu">
                                <a
                                    onClick={() => setSelectedFilter('')}
                                    className={`menu-item app-btn ${selectedFilter === '' ? 'active' : ''}`}
                                >
                                    <div className="content-heading-holder">
                                        <span className="content-heading">All</span>
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{getFilterCount('')}</div>
                                        </span>
                                    </div>
                                </a>
                                <a
                                    onClick={() => setSelectedFilter('Fulfilled')}
                                    className={`menu-item app-btn ${selectedFilter === 'Fulfilled' ? 'active' : ''}`}
                                >
                                    <div className="content-heading-holder">
                                        <span className="menu-icon-holder">
                                            <svg className="svg-icon success-badge-icon">
                                                <use href="#successBadgeIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </span>
                                        <span className="content-heading">Fulfilled</span>
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{getFilterCount('Fulfilled')}</div>
                                        </span>
                                    </div>
                                </a>
                                <a
                                    onClick={() => setSelectedFilter('Pending')}
                                    className={`menu-item app-btn ${selectedFilter === 'Pending' ? 'active' : ''}`}
                                >
                                    <div className="content-heading-holder">
                                        <span className="menu-icon-holder">
                                            <svg className="svg-icon clock-fill-icon">
                                                <use xlinkHref="#clockFillIcon"></use>
                                            </svg>
                                        </span>
                                        <span className="content-heading">Pending</span>
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{getFilterCount('Pending')}</div>
                                        </span>
                                    </div>
                                </a>
                                <a
                                    onClick={() => setSelectedFilter('Surplus')}
                                    className={`menu-item app-btn ${selectedFilter === 'Surplus' ? 'active' : ''}`}
                                >
                                    <div className="content-heading-holder">
                                        <span className="menu-icon-holder">
                                            <svg className="svg-icon ao-forward-icon">
                                                <use xlinkHref="#forwardUpIcon"></use>
                                            </svg>
                                        </span>
                                        <span className="content-heading">Surplus</span>
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{getFilterCount('Surplus')}</div>
                                        </span>
                                    </div>
                                </a>
                                <a
                                    onClick={() => setSelectedFilter('Short Shipped')}
                                    className={`menu-item app-btn ${
                                        selectedFilter === 'Short Shipped' ? 'active' : ''
                                    }`}
                                >
                                    <div className="content-heading-holder">
                                        <span className="menu-icon-holder">
                                            <svg className="svg-icon ao-forward-down-icon">
                                                <use xlinkHref="#forwardDownUpIcon"></use>
                                            </svg>
                                        </span>
                                        <span className="content-heading">Short Shipped</span>
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{getFilterCount('Short Shipped')}</div>
                                        </span>
                                    </div>
                                </a>
                                <a
                                    onClick={() => setSelectedFilter('Dropped')}
                                    className={`menu-item app-btn ${selectedFilter === 'Dropped' ? 'active' : ''}`}
                                >
                                    <div className="content-heading-holder">
                                        <span className="menu-icon-holder">
                                            <svg className="svg-icon ao-drop-down-icon">
                                                <use xlinkHref="#dropIcon"></use>
                                            </svg>
                                        </span>
                                        <span className="content-heading">Dropped</span>
                                        <span className=" modal-toast">
                                            <div className="toast-message ">{getFilterCount('Dropped')}</div>
                                        </span>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <ShowForPermissions permission="enrich" type="po">
                            {isAgent &&
                                headerData?.poStatus !== 'Cancelled' &&
                                headerData?.poStatus !== 'Pending_Reassignment' &&
                                headerData?.poStatus !== 'Booked' && (
                                    <div className="add-btn-users" style={{ marginLeft: 'auto' }}>
                                        <div className="autofill-content">
                                            <div className="autofill-sub-content">
                                                <h6 className="details-box-heading">Goods Ready Date</h6>
                                                <DatePicker
                                                    state={
                                                        autofillData?.goodsReadyDate
                                                            ? new Date(autofillData?.goodsReadyDate)
                                                            : null
                                                    }
                                                    onChange={dateChangeAutofill}
                                                    fieldName="confirmedGoodsdateInitial"
                                                    portalId="date-portal"
                                                />
                                            </div>
                                            <div className="autofill-sub-content" id="address_div_autofill">
                                                <h6 className="details-box-heading">Destination</h6>
                                                <SearchableSelectBox
                                                    isClearable={false}
                                                    data={ports}
                                                    placeholder="Enter destination to select"
                                                    setStateValue={updateDataDestination}
                                                    stateValue={autofillData?.destinationPort}
                                                    fieldName="destinationPortInitial"
                                                    customStyle={portalStyle}
                                                    menuPosition="fixed"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                    onFocusFn={onFocusFn}
                                                    onInputChangeFn={onPortSearch}
                                                    closeMenuOnScroll={true}
                                                    overrideValue={{
                                                        label: autofillData?.destination,
                                                        value: autofillData?.destinationPort,
                                                    }}
                                                    index={0}
                                                    NoMessageComponent={NoMessageComponent}
                                                    menuPlacement={menuPlacement}
                                                />
                                            </div>
                                            <div id="addressInitial" className="autofill-sub-content">
                                                <h6 className="details-box-heading">Destination Address</h6>
                                                <div
                                                    className={
                                                        showAddress
                                                            ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                            : 'dropdown-wrap  menu-down modal-dropdown'
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            resizeFilter('address_div_autofill');
                                                            e.stopPropagation();
                                                            setShowAddress((prev) => !prev);
                                                            setAutofillAddressOpen(true);
                                                        }}
                                                        type="button"
                                                        className="menu-btn app-btn"
                                                    >
                                                        <span className="btn-text placeholder-text-address">
                                                            {autofillData?.destinationAddress}
                                                        </span>
                                                        <span className="dropdown-arrow">
                                                            <svg className="svg-icon arrow-icon">
                                                                <use xlinkHref="#downArrow">
                                                                    <title>dropdown</title>
                                                                </use>
                                                            </svg>
                                                        </span>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="autofill-sub-content button-content">
                                                <button
                                                    onClick={() => autoFill()}
                                                    className="app-btn export-btn icon-button app-btn-secondary"
                                                >
                                                    <span className="button-text">Autofill</span>
                                                </button>
                                            </div>
                                        </div>
                                        <button
                                            disabled={checkSaveAllStatus() || saveAllStatus}
                                            onClick={() => saveAll()}
                                            className="app-btn export-btn icon-button app-btn-secondary"
                                            title={headerData?.isAgentAssigned ? 'Save All' : 'No agent assigned'}
                                        >
                                            <span className="button-text">
                                                {saveAllStatus ? 'Saving...' : 'Save All'}
                                            </span>
                                        </button>
                                    </div>
                                )}
                        </ShowForPermissions>
                    </div>
                    <div className="grid-section ao-product-detail-conformation-grid">
                        <div id="table_div" className="grid-holder">
                            <div className="grid-header">
                                <div className="add-btn-users"></div>
                            </div>
                            <div
                                onScroll={() => {
                                    resizeCheckboxFilter();
                                }}
                                onClick={(e) => {
                                    setToggleDropdown(false);
                                    setSearchName('');
                                    filterCheckbox(e);
                                    setFilterDropdown(false);
                                }}
                                className="grid-container"
                            >
                                {!searchOrFilter?.length && (
                                    <div className="no-data-content">
                                        <svg className="svg-icon grid-no-content-icon ">
                                            <use xlinkHref="#gridNoContentIcon"></use>
                                        </svg>

                                        <p className="no-content-message">
                                            {(() => {
                                                if (search.trim().length) {
                                                    return 'No PO lines were found';
                                                } else if (statusFilterSelected?.length || selectedFilter) {
                                                    return 'No PO lines were found for your filter';
                                                } else {
                                                    return 'No PO lines were found';
                                                }
                                            })()}
                                        </p>
                                    </div>
                                )}
                                <div className="grid-scroll-content">
                                    <form id="my-form" action="">
                                        <table className="grid-table">
                                            <thead>
                                                <tr className="grid-sticky-row">
                                                    <th className="grid-cell-header  grid-sticky-column sticky-left-aligned col-ao-arrow">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title"></span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-po-line grid-sticky-column sticky-left-aligned">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">PO LINE NO.</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-sku grid-sticky-column sticky-left-aligned">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">SKU</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-description">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">DESCRIPTION</span>
                                                        </div>
                                                    </th>
                                                    <th id="filter_div" className="grid-cell-header col-ao-status">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">STATUS</span>

                                                            <div
                                                                className={
                                                                    toggleDropdown || statusFilterSelected.length
                                                                        ? 'filter-menu-wrap open'
                                                                        : 'filter-menu-wrap'
                                                                }
                                                            >
                                                                <button
                                                                    onClick={(e) => {
                                                                        filterByColumn(e);
                                                                        resizeCheckboxFilter();
                                                                        setSearchName('');
                                                                        filterCheckbox(e);
                                                                    }}
                                                                    type="button"
                                                                    className="app-btn filter-menu-button"
                                                                >
                                                                    <svg className="svg-icon filter-icon">
                                                                        <use xlinkHref="#filterIcon">
                                                                            <title>filter</title>
                                                                        </use>
                                                                    </svg>
                                                                    <span className="sr-only">header menu</span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-quanity">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">QUANTITY</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-grd">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">GOODS READY DATE</span>
                                                        </div>
                                                    </th>

                                                    <th className="grid-cell-header col-ao-origin">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">ORIGIN</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-destination-port">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">DESTINATION</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-description">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">DESTINATION Address</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-cbm-cost">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">CBM</span>
                                                        </div>
                                                    </th>
                                                    <th className="grid-cell-header col-ao-weight">
                                                        <div className="grid-cell-data">
                                                            <span className="header-title">WEIGHT(Kg)</span>
                                                        </div>
                                                    </th>
                                                    <ShowForPermissions permission="enrich" type="po">
                                                        {isAgent &&
                                                            headerData?.poStatus !== 'Cancelled' &&
                                                            headerData?.poStatus !== 'Pending_Reassignment' &&
                                                            headerData?.poStatus !== 'Booked' && (
                                                                <th className="grid-cell-header grid-sticky-column sticky-right-aligned col-ao-action">
                                                                    <div className="grid-cell-data">
                                                                        <span className="header-title">ACTION</span>
                                                                    </div>
                                                                </th>
                                                            )}
                                                    </ShowForPermissions>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {searchOrFilter.map(
                                                    (product: ProductDetailsConfirmationDataDetails) => (
                                                        <React.Fragment key={product.poProductId}>
                                                            <tr className="has-child row-expanded no-border">
                                                                <td className="grid-cell td-arrow search-entry-holder grid-sticky-column sticky-left-aligned">
                                                                    {product?.splitLines?.length > 0 &&
                                                                        product?.status != 'Dropped' && (
                                                                            <button
                                                                                onClick={() => {
                                                                                    togglePoLine(product.poProductId);
                                                                                }}
                                                                                type="button"
                                                                                className="grid-expander app-btn"
                                                                            >
                                                                                <svg
                                                                                    className="svg-icon arrow-icon"
                                                                                    style={{
                                                                                        transform: product.isExpanded
                                                                                            ? 'rotate(90deg)'
                                                                                            : 'rotate(0)',
                                                                                    }}
                                                                                >
                                                                                    <use xlinkHref="#arrowIcon">
                                                                                        <title>Expand row</title>
                                                                                    </use>
                                                                                </svg>
                                                                            </button>
                                                                        )}
                                                                </td>
                                                                <td className="grid-cell td-po-line grid-sticky-column sticky-left-aligned">
                                                                    <div className="grid-cell-data">
                                                                        {product?.poLineNumber}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell search-entry-holder td-sku grid-sticky-column sticky-left-aligned">
                                                                    <div className="grid-cell-data">{product?.sku}</div>
                                                                </td>
                                                                <td className="grid-cell search-entry-holder">
                                                                    <div className="grid-cell-data">
                                                                        {product?.description}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell search-entry-holder">
                                                                    <div className="grid-cell-data">
                                                                        {poStatusPillList(product?.status)}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        <div className="quality-count">
                                                                            <div className="units-holder">
                                                                                <span className="first-unit">
                                                                                    {product?.status === 'Dropped'
                                                                                        ? 0
                                                                                        : product?.qty}
                                                                                </span>
                                                                                {getRemainingQty(product.indexValue)}
                                                                            </div>
                                                                            {qtyStatus(product)}
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    {product.goodsReadyDate
                                                                        ? moment(product.goodsReadyDate).format(
                                                                              'DD-MM-YYYY'
                                                                          )
                                                                        : null}
                                                                </td>

                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {product?.originPort}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {product?.destination}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {product?.destinationAddress}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {parseFloat(product?.cbm?.toFixed(2))}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {parseFloat(product?.weight?.toFixed(2))}
                                                                    </div>
                                                                </td>
                                                                <ShowForPermissions permission="enrich" type="po">
                                                                    {isAgent &&
                                                                        headerData?.poStatus !== 'Cancelled' &&
                                                                        headerData?.poStatus !==
                                                                            'Pending_Reassignment' &&
                                                                        headerData?.poStatus !== 'Booked' && (
                                                                            <td className="grid-cell grid-sticky-column sticky-right-aligned">
                                                                                {product?.status != 'Dropped' && (
                                                                                    <div className="grid-cell-data">
                                                                                        <button
                                                                                            type="button"
                                                                                            onClick={() =>
                                                                                                appendNewRow(
                                                                                                    product.indexValue
                                                                                                )
                                                                                            }
                                                                                            disabled={
                                                                                                headerData?.isAgentAssigned
                                                                                                    ? false
                                                                                                    : true
                                                                                            }
                                                                                            className="action-btn app-btn app-btn-secondary "
                                                                                            title={
                                                                                                headerData?.isAgentAssigned
                                                                                                    ? 'Add line to Confirm'
                                                                                                    : 'No agent assigned'
                                                                                            }
                                                                                        >
                                                                                            <svg className="svg-icon add-btn-icon">
                                                                                                <use xlinkHref="#plusIcon"></use>
                                                                                                <title>Add</title>
                                                                                            </svg>
                                                                                        </button>
                                                                                        {product?.splitLines?.length ===
                                                                                        0 ? (
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => {
                                                                                                    setCurrentIndex(
                                                                                                        product.indexValue
                                                                                                    );
                                                                                                    setModalConfirmShipment(
                                                                                                        true
                                                                                                    );
                                                                                                }}
                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                title="Drop PO Line"
                                                                                            >
                                                                                                <svg className="svg-icon ao-drop-down-icon">
                                                                                                    <use xlinkHref="#dropIcon">
                                                                                                        <title>
                                                                                                            Hide
                                                                                                        </title>
                                                                                                    </use>
                                                                                                </svg>
                                                                                            </button>
                                                                                        ) : null}
                                                                                        {product?.quantityType ===
                                                                                            'Short Shipped' &&
                                                                                        !checkIfIsInEditMode(
                                                                                            product.indexValue
                                                                                        ) &&
                                                                                        product?.isShort === false ? (
                                                                                            <button
                                                                                                type="button"
                                                                                                onClick={() => {
                                                                                                    setCurrentIndex(
                                                                                                        product.indexValue
                                                                                                    );
                                                                                                    setModalConfirmShipment(
                                                                                                        true
                                                                                                    );
                                                                                                }}
                                                                                                className="action-btn app-btn app-btn-secondary "
                                                                                                title="Confirm Short Shipment"
                                                                                            >
                                                                                                <svg className="svg-icon ao-forward-down-icon">
                                                                                                    <use xlinkHref="#forwardDownUpIcon">
                                                                                                        <title>
                                                                                                            Forward Icon
                                                                                                        </title>
                                                                                                    </use>
                                                                                                </svg>
                                                                                            </button>
                                                                                        ) : null}
                                                                                    </div>
                                                                                )}
                                                                            </td>
                                                                        )}
                                                                </ShowForPermissions>
                                                            </tr>
                                                            {product?.status != 'Dropped' &&
                                                                product?.splitLines?.map(
                                                                    (splitLine: SplitLines, splitLineIndex: number) => (
                                                                        <tr
                                                                            key={splitLineIndex}
                                                                            style={{
                                                                                display: product.isExpanded
                                                                                    ? ''
                                                                                    : 'none',
                                                                            }}
                                                                            className="child-l1"
                                                                        >
                                                                            <td className="grid-cell   td-arrow search-entry-holder grid-sticky-column sticky-left-aligned"></td>
                                                                            <td className="grid-cell td-po-line grid-sticky-column sticky-left-aligned">
                                                                                <div className="grid-cell-data"></div>
                                                                            </td>
                                                                            <td className="grid-cell search-entry-holder td-sku grid-sticky-column sticky-left-aligned">
                                                                                <div className="grid-cell-data"></div>
                                                                            </td>
                                                                            <td className="grid-cell search-entry-holder">
                                                                                <div className="grid-cell-data"></div>
                                                                            </td>
                                                                            <td className="grid-cell search-entry-holder">
                                                                                <div className="grid-cell-data">
                                                                                    {splitLine?.splitLineStatus
                                                                                        ? poStatusPillList(
                                                                                              splitLine?.splitLineStatus,
                                                                                              splitLine?.bpNumber
                                                                                          )
                                                                                        : ''}
                                                                                </div>
                                                                            </td>
                                                                            {/* <ShowForPermissions
                                                                                permission="enrich"
                                                                                type="po"
                                                                            > */}
                                                                            {splitLine?.editMode ? (
                                                                                <>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            <input
                                                                                                placeholder=""
                                                                                                type="text"
                                                                                                className="search-input input-text input-qty"
                                                                                                id="confirmedQty"
                                                                                                name="confirmedQty"
                                                                                                onChange={updateFieldChanged(
                                                                                                    product.indexValue,
                                                                                                    splitLine.splitLineIndexValue
                                                                                                )}
                                                                                                onKeyPress={(event) => {
                                                                                                    if (
                                                                                                        !/[0-9]/.test(
                                                                                                            event.key
                                                                                                        )
                                                                                                    ) {
                                                                                                        event.preventDefault();
                                                                                                    }
                                                                                                }}
                                                                                                disabled={
                                                                                                    headerData?.poStatus ===
                                                                                                    'Pending_Reassignment'
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                value={
                                                                                                    splitLine.confirmedQty
                                                                                                }
                                                                                            ></input>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            <div
                                                                                                onClick={() => {
                                                                                                    setCurrentIndex(
                                                                                                        product.indexValue
                                                                                                    );
                                                                                                    setCurrentSplitIndex(
                                                                                                        splitLine.splitLineIndexValue
                                                                                                    );
                                                                                                }}
                                                                                                id="goodsReadyDateDiv"
                                                                                                className="grid-cell-data date-picker-grid"
                                                                                            >
                                                                                                <DatePicker
                                                                                                    // minDate={
                                                                                                    //     new Date(
                                                                                                    //         moment(
                                                                                                    //             product?.orderDate,
                                                                                                    //             'yyyy-MM-DD'
                                                                                                    //         )
                                                                                                    //             .add(
                                                                                                    //                 1,
                                                                                                    //                 'days'
                                                                                                    //             )
                                                                                                    //             .format(
                                                                                                    //                 'MM-DD-YYYY'
                                                                                                    //             )
                                                                                                    //     )
                                                                                                    // }
                                                                                                    disabled={
                                                                                                        splitLine?.confirmedQty ==
                                                                                                        0
                                                                                                    }
                                                                                                    state={
                                                                                                        splitLine.confirmedGoodsdate
                                                                                                    }
                                                                                                    onChange={
                                                                                                        updateDateChanged
                                                                                                    }
                                                                                                    fieldName={`confirmedGoodsdate_${product.indexValue}_${splitLine.splitLineIndexValue}`}
                                                                                                    portalId="date-portal"
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {product?.originPort}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td
                                                                                        id={`edit_row_${product.indexValue}_${splitLine.splitLineIndexValue}`}
                                                                                        className="grid-cell"
                                                                                    >
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                setCurrentIndex(
                                                                                                    product.indexValue
                                                                                                );
                                                                                                setCurrentSplitIndex(
                                                                                                    splitLine.splitLineIndexValue
                                                                                                );
                                                                                                menuPlacementCheck(
                                                                                                    product.indexValue,
                                                                                                    splitLine.splitLineIndexValue
                                                                                                );
                                                                                            }}
                                                                                            className="grid-cell-data"
                                                                                        >
                                                                                            <div className="auto-dp-height">
                                                                                                <SearchableSelectBox
                                                                                                    isClearable={
                                                                                                        splitLine?.destinationPort ===
                                                                                                            undefined ||
                                                                                                        splitLine?.destinationPort ===
                                                                                                            null ||
                                                                                                        searchTerm?.length >
                                                                                                            0
                                                                                                            ? false
                                                                                                            : true
                                                                                                    }
                                                                                                    isDisabled={
                                                                                                        splitLine?.confirmedQty ==
                                                                                                        0
                                                                                                    }
                                                                                                    data={ports}
                                                                                                    placeholder="Enter destination to select"
                                                                                                    setStateValue={
                                                                                                        updateData
                                                                                                    }
                                                                                                    stateValue={
                                                                                                        splitLine?.destinationPort?.includes(
                                                                                                            '(null)'
                                                                                                        )
                                                                                                            ? ''
                                                                                                            : splitLine?.destinationPort
                                                                                                    }
                                                                                                    fieldName="destinationPort"
                                                                                                    customStyle={
                                                                                                        portalStyle
                                                                                                    }
                                                                                                    menuPosition="fixed"
                                                                                                    menuPortalTarget={document.getElementById(
                                                                                                        'outside_div'
                                                                                                    )}
                                                                                                    onFocusFn={
                                                                                                        onFocusFn
                                                                                                    }
                                                                                                    onInputChangeFn={
                                                                                                        onPortSearch
                                                                                                    }
                                                                                                    overrideValue={{
                                                                                                        label: splitLine?.destination,
                                                                                                        value: splitLine?.destinationPort,
                                                                                                    }}
                                                                                                    index={
                                                                                                        product.indexValue
                                                                                                    }
                                                                                                    closeMenuOnScroll={
                                                                                                        true
                                                                                                    }
                                                                                                    NoMessageComponent={
                                                                                                        NoMessageComponent
                                                                                                    }
                                                                                                    menuPlacement={
                                                                                                        menuPlacement
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div
                                                                                            id={`address_div_${product.indexValue}_${splitLine.splitLineIndexValue}`}
                                                                                            className="grid-cell-data"
                                                                                        >
                                                                                            <div
                                                                                                className={
                                                                                                    showAddress &&
                                                                                                    currentSplitIndex ===
                                                                                                        splitLine.splitLineIndexValue &&
                                                                                                    currentIndex ===
                                                                                                        product.indexValue
                                                                                                        ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                                                        : 'dropdown-wrap  menu-down modal-dropdown'
                                                                                                }
                                                                                            >
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        resizeFilter(
                                                                                                            `address_div_${product.indexValue}_${splitLine.splitLineIndexValue}`
                                                                                                        );
                                                                                                        e.stopPropagation();
                                                                                                        setShowAddress(
                                                                                                            (prev) =>
                                                                                                                !prev
                                                                                                        );
                                                                                                        setCurrentIndex(
                                                                                                            product.indexValue
                                                                                                        );
                                                                                                        setCurrentSplitIndex(
                                                                                                            splitLine.splitLineIndexValue
                                                                                                        );
                                                                                                    }}
                                                                                                    disabled={
                                                                                                        splitLine?.confirmedQty ==
                                                                                                        0
                                                                                                    }
                                                                                                    type="button"
                                                                                                    className="menu-btn app-btn"
                                                                                                >
                                                                                                    {splitLine.destinationAddress ? (
                                                                                                        <span className="btn-text placeholder-text-address">
                                                                                                            {
                                                                                                                splitLine.destinationAddress
                                                                                                            }
                                                                                                        </span>
                                                                                                    ) : (
                                                                                                        <span className="btn-text btn-address">
                                                                                                            Select
                                                                                                            address
                                                                                                        </span>
                                                                                                    )}

                                                                                                    {/* <span className="placeholder-text">Select Address</span> */}
                                                                                                    <span className="dropdown-arrow">
                                                                                                        <svg className="svg-icon arrow-icon">
                                                                                                            <use xlinkHref="#downArrow">
                                                                                                                <title>
                                                                                                                    dropdown
                                                                                                                </title>
                                                                                                            </use>
                                                                                                        </svg>
                                                                                                    </span>
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            <input
                                                                                                placeholder=""
                                                                                                type="number"
                                                                                                min="0"
                                                                                                className="search-input input-text input-qty"
                                                                                                id="confirmedCbm"
                                                                                                name="confirmedCbm"
                                                                                                onChange={updateFieldChanged(
                                                                                                    product.indexValue,
                                                                                                    splitLine.splitLineIndexValue
                                                                                                )}
                                                                                                // onKeyPress={(event) => {
                                                                                                //     if (
                                                                                                //         !/[0-9]/.test(
                                                                                                //             event.key
                                                                                                //         )
                                                                                                //     ) {
                                                                                                //         event.preventDefault();
                                                                                                //     }
                                                                                                // }}
                                                                                                disabled={
                                                                                                    headerData?.poStatus ===
                                                                                                    'Pending_Reassignment'
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                value={
                                                                                                    splitLine.confirmedCbm
                                                                                                }
                                                                                            ></input>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell search-entry-holder">
                                                                                        <div className="grid-cell-data">
                                                                                            <input
                                                                                                placeholder=""
                                                                                                type="number"
                                                                                                min="0"
                                                                                                className="search-input input-text input-qty"
                                                                                                id="confirmedWeight"
                                                                                                name="confirmedWeight"
                                                                                                onChange={updateFieldChanged(
                                                                                                    product.indexValue,
                                                                                                    splitLine.splitLineIndexValue
                                                                                                )}
                                                                                                // onKeyPress={(event) => {
                                                                                                //     if (
                                                                                                //         !/[0-9]/.test(
                                                                                                //             event.key
                                                                                                //         )
                                                                                                //     ) {
                                                                                                //         event.preventDefault();
                                                                                                //     }
                                                                                                // }}
                                                                                                disabled={
                                                                                                    headerData?.poStatus ===
                                                                                                    'Pending_Reassignment'
                                                                                                        ? true
                                                                                                        : false
                                                                                                }
                                                                                                value={
                                                                                                    splitLine.confirmedWeight
                                                                                                }
                                                                                            ></input>
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {splitLine?.confirmedQty}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {splitLine?.confirmedGoodsdate
                                                                                                ? moment(
                                                                                                      splitLine?.confirmedGoodsdate
                                                                                                  ).format('DD-MM-YYYY')
                                                                                                : null}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {product?.originPort}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {splitLine?.destination}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {
                                                                                                splitLine?.destinationAddress
                                                                                            }
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {splitLine?.confirmedCbm}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className="grid-cell">
                                                                                        <div className="grid-cell-data">
                                                                                            {splitLine?.confirmedWeight}
                                                                                        </div>
                                                                                    </td>
                                                                                </>
                                                                            )}
                                                                            {/* </ShowForPermissions> */}

                                                                            {/* <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {splitLine?.confirmedCbm}
                                                                                </div>
                                                                            </td>
                                                                            <td className="grid-cell">
                                                                                <div className="grid-cell-data">
                                                                                    {splitLine?.confirmedWeight}
                                                                                </div>
                                                                            </td> */}

                                                                            <ShowForPermissions
                                                                                permission="enrich"
                                                                                type="po"
                                                                            >
                                                                                {isAgent &&
                                                                                    headerData?.poStatus !==
                                                                                        'Cancelled' &&
                                                                                    headerData?.poStatus !==
                                                                                        'Pending_Reassignment' &&
                                                                                    headerData?.poStatus !==
                                                                                        'Booked' && (
                                                                                        <td className="grid-cell grid-sticky-column sticky-right-aligned">
                                                                                            {(splitLine?.splitLineStatus ==
                                                                                                null ||
                                                                                                splitLine?.splitLineStatus ===
                                                                                                    'Pending_confirmation') && (
                                                                                                <div className="grid-cell-data">
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        onClick={() =>
                                                                                                            removePoLine(
                                                                                                                product.indexValue,
                                                                                                                splitLine.splitLineIndexValue,
                                                                                                                splitLine.splitLineId
                                                                                                            )
                                                                                                        }
                                                                                                        disabled={
                                                                                                            headerData?.isAgentAssigned
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                        className="action-btn app-btn app-btn-secondary "
                                                                                                        title={
                                                                                                            headerData?.isAgentAssigned
                                                                                                                ? 'Remove Line'
                                                                                                                : 'No agent assigned'
                                                                                                        }
                                                                                                    >
                                                                                                        <svg className="svg-icon circle-close-icon">
                                                                                                            <use href="#deleteIcon">
                                                                                                                <title>
                                                                                                                    Cancel
                                                                                                                </title>
                                                                                                            </use>
                                                                                                        </svg>
                                                                                                    </button>
                                                                                                    {splitLine?.editMode ? (
                                                                                                        <button
                                                                                                            type="button"
                                                                                                            disabled={checkSaveButtonStatus(
                                                                                                                splitLine
                                                                                                            )}
                                                                                                            onClick={() =>
                                                                                                                confirmPoLine(
                                                                                                                    product.indexValue,
                                                                                                                    splitLine.splitLineIndexValue
                                                                                                                )
                                                                                                            }
                                                                                                            className="action-btn app-btn app-btn-secondary "
                                                                                                            title={getTitle(
                                                                                                                splitLine
                                                                                                            )}
                                                                                                        >
                                                                                                            <svg className="svg-icon save-icon">
                                                                                                                <use href="#saveIcon">
                                                                                                                    <title>
                                                                                                                        Save
                                                                                                                    </title>
                                                                                                                </use>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                    ) : (
                                                                                                        <button
                                                                                                            onClick={() =>
                                                                                                                editPoLine(
                                                                                                                    product.indexValue,
                                                                                                                    splitLine.splitLineIndexValue
                                                                                                                )
                                                                                                            }
                                                                                                            disabled={
                                                                                                                headerData?.isAgentAssigned
                                                                                                                    ? false
                                                                                                                    : true
                                                                                                            }
                                                                                                            type="button"
                                                                                                            className="action-btn app-btn app-btn-secondary "
                                                                                                            title={
                                                                                                                headerData?.isAgentAssigned
                                                                                                                    ? 'Edit Line'
                                                                                                                    : 'No agent assigned'
                                                                                                            }
                                                                                                        >
                                                                                                            <svg className="svg-icon edit-icon icon-20">
                                                                                                                <use xlinkHref="#editIcon">
                                                                                                                    <title>
                                                                                                                        Edit
                                                                                                                    </title>
                                                                                                                </use>
                                                                                                            </svg>
                                                                                                        </button>
                                                                                                    )}
                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                    )}
                                                                            </ShowForPermissions>
                                                                        </tr>
                                                                    )
                                                                )}
                                                        </React.Fragment>
                                                    )
                                                )}
                                            </tbody>
                                        </table>
                                    </form>
                                </div>
                            </div>
                            {/* outside dropdown items , destination-adress-dropdown-wrap need to set top and left for this dynamically according to the component*/}
                            <div
                                className={
                                    showAddress
                                        ? 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                        : 'dropdown-wrap destination-adress-dropdown-wrap outside-menu menu-down modal-dropdown'
                                }
                                style={{
                                    top: `${distanceFromTopForAddress}px`,
                                    left: `${distanceFromLeftForAddress}px`,
                                    zIndex: 999,
                                    width: '345px',
                                }}
                            >
                                <div className="dropdown-menu client">
                                    {destinationAddress?.length === 0 && (
                                        <div className="no-data-content">
                                            <svg className="svg-icon grid-no-content-icon ">
                                                <use xlinkHref="#gridNoContentIcon"></use>
                                            </svg>
                                            <p className="no-content-message">No matching Found</p>
                                        </div>
                                    )}
                                    <div className="customscrollbar">
                                        {destinationAddress?.length > 0 &&
                                            destinationAddress?.map((item: any, index: number) => (
                                                <a
                                                    onClick={() => {
                                                        updateData(item, 'address');
                                                    }}
                                                    key={index}
                                                    className="menu-item app-btn"
                                                >
                                                    <span className="main-content">{item.fullAddress}</span>
                                                </a>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            {toggleDropdown && (
                                <div className="search-filter-holder">
                                    <div
                                        style={{ left: String(setPosition) + 'px', top: '75px' }}
                                        className="filter-menu searchable-dropdown-common"
                                    >
                                        <div className="search-entry-holder">
                                            <input
                                                placeholder="Search"
                                                type="text"
                                                className="search-input input-text grid-search-field search-input-box"
                                                value={searchName}
                                                onChange={filterCheckbox}
                                            ></input>
                                            <svg className="svg-icon search-icon">
                                                <use xlinkHref="#searchIcon"></use>
                                            </svg>
                                            <button
                                                onClick={(e) => {
                                                    setSearchName('');
                                                    filterCheckbox(e);
                                                }}
                                                className="app-btn text-close-btn"
                                            >
                                                <svg className="svg-icon text-close-icon">
                                                    <use xlinkHref="#closeIcon"></use>
                                                </svg>
                                            </button>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            {distinctValues &&
                                                distinctValues.map((value: string, index: number) => (
                                                    <div key={index} className="checkbox-item">
                                                        <label className="app-check-wrapper">
                                                            <input
                                                                onChange={(e: any) => {
                                                                    if (e.target.checked) {
                                                                        setStatusFilterSelected((prev: any) => [
                                                                            ...prev,
                                                                            value,
                                                                        ]);
                                                                    } else {
                                                                        const filtered = statusFilterSelected?.filter(
                                                                            (res: any) => res !== value
                                                                        );
                                                                        setStatusFilterSelected(filtered);
                                                                    }
                                                                    // setToggleDropdown(false);
                                                                }}
                                                                // defaultChecked={false}
                                                                checked={statusFilterSelected.includes(value)}
                                                                type="checkbox"
                                                                className="checkbox-input"
                                                            />
                                                            <div className="checkmark">
                                                                <svg className="svg-icon tick-icon">
                                                                    <use xlinkHref="#tickIcon">
                                                                        <title>check mark</title>
                                                                    </use>
                                                                </svg>
                                                            </div>
                                                            <div className="checkbox-label">{value}</div>
                                                        </label>
                                                    </div>
                                                ))}
                                            {distinctValues?.length === 0 && (
                                                <div className="no-data-content">
                                                    <svg className="svg-icon grid-no-content-icon ">
                                                        <use xlinkHref="#gridNoContentIcon"></use>
                                                    </svg>
                                                    <p className="no-content-message">No Matches Found</p>
                                                </div>
                                            )}
                                        </div>
                                        {distinctValues?.length > 0 && (
                                            <div className="clear-btn">
                                                <button onClick={() => setStatusFilterSelected([])} className="app-btn">
                                                    <span className="button-text">Clear All</span>
                                                </button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="footer-bootom-row">
                    <div className="footer-button-holder ">
                        {getAllPendingConfirmationLines()?.length > 0 && (
                            <span
                                style={{ background: '#FBF3E7', color: '#F86A1B' }}
                                className="btn-pills warning-pill"
                            >
                                <span className="pill-text">
                                    {getAllPendingConfirmationLines()?.length} Pending Confirmation
                                </span>
                            </span>
                        )}
                        <ShowForPermissions permission="enrich" type="po">
                            {isAgent &&
                                headerData?.poStatus !== 'Cancelled' &&
                                headerData?.poStatus !== 'Pending_Reassignment' &&
                                headerData?.poStatus !== 'Booked' && (
                                    // headerData?.poStatus === 'Awaiting_Enrichment' &&
                                    <button
                                        disabled={
                                            getAllPendingConfirmationLines()?.length &&
                                            !checkAtLeastOneEditExists() &&
                                            headerData?.isAgentAssigned &&
                                            confirmProductsBpStatus === false
                                                ? false
                                                : true
                                        }
                                        onClick={() => {
                                            // if (headerData?.shippingMode?.shippingModeCode === 'AIR') {
                                            //     setShowConfirmModal(true);
                                            // } else {
                                            setModalViewConfirm(true);
                                            // }
                                        }}
                                        className="app-btn app-btn-primary footer-btn "
                                        title={
                                            headerData?.isAgentAssigned
                                                ? 'Confirm PO for Booking Proposal'
                                                : 'No agent assigned'
                                        }
                                    >
                                        <span className="button-text footer-button-text">
                                            {confirmProductsBpStatus
                                                ? 'Confirming Products...'
                                                : 'Confirm Products for Booking Proposal'}
                                        </span>
                                    </button>
                                )}
                        </ShowForPermissions>
                        <Link
                            to={`/purchase_order/${id}/product_details`}
                            className="app-btn app-btn-secondary footer-btn"
                            title="Previous"
                        >
                            <span className="button-text footer-button-text">Previous</span>
                        </Link>
                        {/* //Only show conatiner mode tab for PO in draft status and sea freight shipping mode //(Air
                        freight hasn't implemented the concept of LCL.So, hide container mode selection tab for air
                        freight) */}
                        {headerData?.poStatus?.toLowerCase() !== 'draft' && (
                            // headerData?.shippingMode?.shippingModeCode !== 'AIR'
                            // &&
                            <PODetailsNextButton />
                        )}
                    </div>
                </div>
            </div>
            {/* <ShipmentModal
                display={modalViewShort}
                displayFunction={setModalViewShort}
                shortShipmentList={shortShipmentList}
                setShortShipmentList={setShortShipmentList}
                poId={id}
            /> */}
            {/* Show confirm for booking modal for Po with shipping mode air freight */}
            <ConfirmBookingModal
                display={showConfirmModal}
                displayFunction={setShowConfirmModal}
                handleConfirm={handleConfirm}
            />
            {/* <ZeroQuantityModal
                zeroProductData={zeroProductData}
                setZeroProductData={setZeroProductData}
                product={productPo}
                display={modalViewZero}
                displayFunction={setModalViewZero}
            />
            <ModifyProductModal
                display={modalViewModifyProduct}
                displayFunction={setModalViewModifyProduct}
                splitLine={currentSplitLine}
                poId={id}
            /> */}
            <ConfirmShipmentModal
                modalViewConfirmShipment={modalViewConfirmShipment}
                setModalConfirmShipment={setModalConfirmShipment}
                product={productPo?.length ? productPo[currentIndex] : {}}
                currentIndex={currentIndex}
                currentSplitIndex={currentSplitIndex}
            />
            <ConfirmProductsModal
                display={modalViewConfirm}
                displayFunction={setModalViewConfirm}
                products={getAllConfirmationGroups()}
                setConfirmProductsBpStatus={setConfirmProductsBpStatus}
                isLand={headerData?.shippingMode?.shippingModeCode === 'LND'}
            />
            <UnsavedChangeModal
                unsavedChangeModalVisibility={unsavedChangeModalVisibility}
                setUnsavedChangeModalVisibility={setUnsavedChangeModalVisibility}
                callBackFn={callBackFn}
            />
            <div style={{ zIndex: 9999 }} id="date-portal"></div>
        </>
    );
};
export default Index;
