import React, { useEffect, useState } from 'react';
import './data-grid.scss';
import { setGroupIds } from '@progress/kendo-react-data-tools';
import {
    Grid,
    GridColumn as Column,
    GridDataStateChangeEvent,
    GridCellProps,
    GridItemChangeEvent,
    GridToolbar,
} from '@progress/kendo-react-grid';
import { DataResult, State, filterBy } from '@progress/kendo-data-query';
import { groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { Actions } from './actions';
import { Position, success, error } from 'src/utils/toast';
import { get } from 'src/services/HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
const editField = 'inEdit';

type GridProps = {
    title: {
        name: string;
        extraKey: string;
    };
    uri: string;
    permissionName: string;
    columns: {
        field: string;
        title: string;
        width?: number;
        editor?: any;
        filterable: boolean;
        editable: boolean;
        hidden: boolean;
        dropdownData?: { label: string; value: any }[];
    }[];
    uniqueKey: string;
    systemCreatedKey: string;
    addItem: (payload: any) => Promise<any>;
    updateItem: (payload: any, key: string) => Promise<any>;
    deleteItem: (payload: any, key: string) => Promise<any>;
    extraActionButtons?: {
        title: string;
        icon: string;
        onClick: any;
        disabled: boolean;
        hidden: boolean;
    }[];
    responseDataKey?: string;
};

const Index: React.FC<GridProps> = ({
    title,
    uri,
    permissionName,
    columns,
    uniqueKey,
    systemCreatedKey,
    addItem,
    updateItem,
    deleteItem,
    extraActionButtons,
    responseDataKey,
}) => {
    const [extraTitle, setExtraTitle] = useState('');
    const [cixData, setCixData] = useState<DataResult>({
        data: [],
        total: 0,
    });
    const [originalData, setOriginalData] = useState<any>([]);
    const [editMode, setEditMode] = useState(false);
    const [dataState, setDataState] = useState<State>({
        filter: undefined,
        group: [],
        sort: [],
        take: 30,
        skip: 0,
    });

    const { permissions } = useSelector((state: RootState) => state.permissionData);

    const checkPermission = (permission: any, type: any) => {
        let flag = false;
        for (let i = 0; i < permissions?.length; i++) {
            if (
                (permissions[i] as any)?.access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .slice(0, 2)
                    .includes(permission.toLowerCase()) &&
                (permissions[i] as any)?.access
                    .toLowerCase()
                    .split(/\s+|\./)
                    .slice(0, 2)
                    .includes(type.toLowerCase())
            ) {
                flag = true;
            }
        }

        return flag;
    };

    const permission = {
        add: checkPermission('create', permissionName),
        edit: checkPermission('update', permissionName),
        delete: checkPermission('delete', permissionName),
        view: checkPermission('read', permissionName),
    };

    function toCIXQuery(state: any) {
        let cixQuery = '';
        if (state.take) cixQuery += `?take=${state.take}`;
        if (state.skip) cixQuery += `&skip=${state.skip}`;
        if (state.filter) cixQuery += `&filter=${encodeURIComponent(JSON.stringify(state.filter))}`;
        if (state.sort?.length) cixQuery += `&sort=${encodeURIComponent(JSON.stringify(state.sort))}`;
        if (state.group?.length) cixQuery += `&group=${encodeURIComponent(JSON.stringify(state.group))}`;
        return cixQuery;
    }

    const processWithGroups = (data: any[], group: GroupDescriptor[]) => {
        setExtraTitle(data?.[0]?.[title?.extraKey]);
        const response = responseDataKey ? data?.[0]?.[responseDataKey] : data ?? [];
        const newDataState = groupBy(response, group);
        setGroupIds({ data: newDataState, group: group });
        return newDataState;
    };

    const getData = async (state: any) => {
        try {
            const result = await get(`${uri}${toCIXQuery(state)}`, null, externalBaseUrl);
            const data = processWithGroups(result.data as any, dataState.group as any);
            setCixData({ data: data, total: result.total });
            setOriginalData(data);
        } catch (error) {
            console.log(error);
        }
    };

    const dataStateChange = (e: GridDataStateChangeEvent) => {
        setDataState(e.dataState);
    };

    useEffect(() => {
        getData(dataState);
    }, [dataState]);

    const isLoading = false;

    const remove = async (dataItem: any) => {
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        let index = cixDataClone?.data.findIndex((record: any) => record[uniqueKey] === dataItem[uniqueKey]);
        cixDataClone?.data.splice(index, 1);
        try {
            const response = await deleteItem(cixDataClone.data[index], dataItem[uniqueKey]);
            success(response?.message ?? 'Removed Successfully', Position.TOP_RIGHT);
            setCixData({ ...cixData, data: cixDataClone?.data });
            getData(dataState);
            setEditMode(false);
        } catch (err: any) {
            console.log(err, 'error');
            error(err?.response?.data?.message ?? 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    const add = async (dataItem: any) => {
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        dataItem.inEdit = false;
        const index = cixDataClone.data.findIndex((record: any) => record[uniqueKey] === dataItem[uniqueKey]);
        cixDataClone.data[index] = dataItem;
        try {
            const response = await addItem(cixDataClone.data[index]);
            success(response?.message ?? 'Added Successfully', Position.TOP_RIGHT);
            setCixData(cixDataClone);
            getData(dataState);
            setEditMode(false);
        } catch (err: any) {
            console.log(err, 'error');
            error(err?.response?.data?.message ?? 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    const update = async (dataItem: any) => {
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        dataItem.inEdit = false;
        const index = cixDataClone.data.findIndex((record: any) => record[uniqueKey] === dataItem[uniqueKey]);
        cixDataClone.data[index] = dataItem;
        try {
            const response = await updateItem(cixDataClone.data[index], dataItem[uniqueKey]);
            success(response?.message ?? 'Updated Successfully', Position.TOP_RIGHT);
            setCixData(cixDataClone);
            getData(dataState);
            setEditMode(false);
        } catch (err: any) {
            console.log(err, 'error');
            error(err?.response?.data?.message ?? 'Something went wrong', Position.TOP_RIGHT);
        }
    };

    const discard = () => {
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        cixDataClone?.data?.splice(0, 1);
        setCixData({ ...cixData, data: cixDataClone?.data });
        setEditMode(false);
    };

    const cancel = (dataItem: any) => {
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        const originalItem = originalData?.find((p: any) => p[uniqueKey] === dataItem[uniqueKey]);
        // originalItem.inEdit = false;
        const newData = cixDataClone?.data.map((item: any) =>
            item[uniqueKey] === originalItem[uniqueKey] ? originalItem : item
        );
        setCixData({ ...cixData, data: newData });
        setEditMode(false);
    };

    const enterEdit = (dataItem: any) => {
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        const newData = cixDataClone?.data.map((item: any) =>
            item[uniqueKey] === dataItem[uniqueKey] ? { ...item, inEdit: true } : item
        );
        setCixData({ ...cixData, data: newData });
        setEditMode(true);
    };

    const itemChange = (event: GridItemChangeEvent) => {
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        const newData = cixDataClone.data?.map((item: any) =>
            item[uniqueKey] === event.dataItem[uniqueKey] ? { ...item, [event.field || '']: event.value } : item
        );
        setCixData({ ...cixData, data: newData });
    };

    const addNew = () => {
        const newDataItem = { inEdit: true };
        const cixDataClone = JSON.parse(JSON.stringify(cixData));
        cixDataClone.total += 1;
        cixDataClone.data?.unshift(newDataItem);
        setCixData(cixDataClone);
        setEditMode(true);
    };

    const ActionsComponent = (props: GridCellProps) => (
        <Actions
            {...props}
            permission={permission}
            uniqueKey={uniqueKey}
            systemCreatedKey={props?.dataItem?.[systemCreatedKey]}
            edit={enterEdit}
            remove={remove}
            add={add}
            discard={discard}
            update={update}
            cancel={cancel}
            editField={editField}
            editMode={editMode}
            extraActionButtons={extraActionButtons}
        />
    );

    const DropdownComponent = (props: any, originalData: any) => {
        const [data, setData] = useState(originalData);
        const filterData = (filter: any) => {
            const data = originalData.slice();
            return filterBy(data, filter);
        };
        const filterChange = (event: any) => {
            setData(filterData(event.filter));
        };
        const value = props.dataItem[props.field];
        if (!props.dataItem.inEdit) {
            return <td> {value === null ? '' : value?.toString()}</td>;
        }
        const handleChange = (event: any) => {
            const cixDataClone = JSON.parse(JSON.stringify(cixData));
            cixDataClone.data[props.dataIndex][props.field] = event.target.value?.value;
            setCixData(cixDataClone);
        };

        return (
            <td>
                <DropDownList
                    onChange={handleChange}
                    value={{ label: value?.toString(), value: value }}
                    data={data}
                    dataItemKey="value"
                    textField="label"
                    filterable={true}
                    onFilterChange={filterChange}
                />
            </td>
        );
    };
    const DateComponent = (props: any) => {
        const date = props.dataItem?.[props.field]
            ? moment(props.dataItem?.[props.field]).format('DD-MM-YYYY HH:mm:ss')
            : '';
        return <td>{date}</td>;
    };

    return (
        <div className="kendo-react main-wrapper container insight-agent-list">
            {title && (
                <div className="main-header-content-holder ">
                    <div className="pageheading-holder">
                        <div className="header-wrapper">
                            <h2 className="page-heading">
                                {extraTitle ? title?.name + ' ' + extraTitle : title?.name}
                            </h2>
                        </div>
                    </div>
                </div>
            )}
            <div className="main-content-holder">
                {isLoading ? (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                ) : (
                    <div className="grid-content-holder">
                        <Grid
                            filterable={true}
                            sortable={true}
                            pageable={{ buttonCount: 5 }}
                            groupable={true}
                            {...dataState}
                            sort={dataState.sort}
                            data={cixData}
                            group={dataState.group}
                            onDataStateChange={dataStateChange}
                            expandField="expanded"
                            editField={editField}
                            onItemChange={itemChange}
                            scrollable="scrollable"
                        >
                            <GridToolbar>
                                {permission.add && (
                                    <button
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add New"
                                        onClick={addNew}
                                        disabled={editMode}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Add New</span>
                                    </button>
                                )}
                            </GridToolbar>
                            {columns?.map((column, index) => {
                                if (column?.hidden) {
                                    return null;
                                }
                                if (column?.editor === 'dropdown') {
                                    return (
                                        <Column
                                            key={index}
                                            {...column}
                                            cell={(props: any) => DropdownComponent(props, column.dropdownData)}
                                        />
                                    );
                                } else if (column?.editor === 'date') {
                                    return (
                                        <Column key={index} {...column} cell={(props: any) => DateComponent(props)} />
                                    );
                                }
                                return <Column key={index} {...column} />;
                            })}
                            {permission.edit || permission.delete || permission.view ? (
                                <Column
                                    cell={ActionsComponent}
                                    filterable={false}
                                    sortable={false}
                                    width="200px"
                                    title="Actions"
                                />
                            ) : null}
                        </Grid>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Index;
