import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

// Import all api's
import { clientData, clientDataFull, searchUsers } from '../../services/apis/usersForwarderApis';
import {
    changeRoleOfUser,
    getUsersData,
    listRoles,
    changeStatusUser,
    getHeaderDataUsers,
    linkClient,
    linkOrgClient,
    removeClient,
    resendActivation,
    listAllClients,
} from '../../services/apis/usersForwarderApis';
import {
    saveRoles,
    saveUsersData,
    isLoadingFn,
    saveUsersHeaderData,
    saveClient,
    emailSendSuccess,
    changeRoleSuccess,
    statuchangeMessage,
    statusChangeValidation,
} from '../reducers/UsersList';
import { exportFile } from '../../services/apis/data-api';

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getListData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const res: AxiosResponse<clientDataFull[]> = yield call(getUsersData, payload);
        yield put({ type: saveUsersData, payload: res });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

function* getRoleList({ payload }: { payload: any; type: any }) {
    const header: AxiosResponse<clientData[]> = yield call(listRoles, payload);
    yield put({ type: saveRoles, payload: header });
}

function* changeRoleUser({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield call(changeRoleOfUser, payload);

        const res: AxiosResponse<clientDataFull[]> = yield call(getUsersData, payload);
        yield put({ type: saveUsersData, payload: res });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* changeUserStatus({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const result: AxiosResponse<any> = yield call(changeStatusUser, payload);
        if ((result as any).createdDateTime) {
            const res: AxiosResponse<clientDataFull[]> = yield call(getUsersData, payload);
            yield put({ type: saveUsersData, payload: res });
            if (payload.currentStat === 'Activate' && payload.azure === null) {
                yield put({ type: emailSendSuccess, payload: true });
            } else {
                yield put({ type: changeRoleSuccess, payload: true });
                yield put({
                    type: statuchangeMessage,
                    payload: `User ${payload.currentStat.toLowerCase()}d successfully `,
                });
            }
        } else {
            if (result.status === 400 || result.status === 403) {
                yield put({ type: statusChangeValidation, payload: { status: true, message: result.data.message } });
            }
        }
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* getSearchData({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const agents: AxiosResponse<any> = yield call(searchUsers, payload);
        //yield put({ type: searchUsersData, payload: agents });
        yield put({ type: saveUsersData, payload: agents });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* usersHeaderData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const header: AxiosResponse<clientData[]> = yield call(getHeaderDataUsers, payload);
        yield put({ type: saveUsersHeaderData, payload: header });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* fileDownloadUsers({ payload }: { payload: any; type: any }) {
    try {
        yield call(exportFile, payload);
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* listClients({ payload }: { payload: any; type: any }) {
    try {
        const data: AxiosResponse<clientData[]> = yield call(listAllClients, payload);
        yield put({ type: saveClient, payload: data });
    } finally {
    }
}
function* emailResend({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield call(resendActivation, payload);
        const res: AxiosResponse<clientDataFull[]> = yield call(getUsersData, payload);
        yield put({ type: saveUsersData, payload: res });
        yield put({ type: emailSendSuccess, payload: true });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
    }
}
function* clienttoUsers({ payload }: { payload: any; type: any }) {
    yield call(linkClient, payload);
}
function* orgClienttoUsers({ payload }: { payload: any; type: any }) {
    yield call(linkOrgClient, payload);
}
function* unlinkClient({ payload }: { payload: any; type: any }) {
    yield call(removeClient, payload);
}
function* clientSaga() {
    yield takeEvery('users/fetchData', getListData);
    yield takeEvery('users/listUserRoles', getRoleList);
    yield takeEvery('users/changeRole', changeRoleUser);
    yield takeEvery('users/changeStatus', changeUserStatus);
    yield takeEvery('users/getHeaderData', usersHeaderData);
    yield takeEvery('users/downloadFile', fileDownloadUsers);
    yield takeEvery('users/clientLinkUser', clienttoUsers);
    yield takeEvery('users/orgClientLinkUser', orgClienttoUsers);
    yield takeEvery('users/clientUnlinkUser', unlinkClient);
    yield takeEvery('users/resendEmail', emailResend);
    yield takeEvery('users/listAllClient', listClients);
    yield takeLatest('users/searchUsersData', getSearchData);
}

// Export the saga (data-saga)
export default clientSaga;
