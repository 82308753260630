import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import '../../../../assets/scss/components/_modal.scss';
import { useAppDispatch } from '../../../../hooks';
import { RootState } from '../../../../store/reducers';
import {
    clearFailure,
    reopenReceipt,
    clearReopenFlags,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { error, success, Position } from '../../../../utils/toast';

interface ReopenReceiptProps {
    reopenReceiptModalVisibility: boolean;
    setReopenReceiptModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

const ReopenReceiptModal: React.FC<ReopenReceiptProps> = ({
    reopenReceiptModalVisibility,
    setReopenReceiptModalVisibility,
}) => {
    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { reopenError, reopenSuccess, reopenErrorMessage } = useSelector(
        (state: RootState) => state.receiptHeaderData
    );

    const onReopenReceipt = () => {
        setIsLoading(true);
        dispatch(
            reopenReceipt({
                receiptId: id,
            })
        );
    };

    useEffect(() => {
        if (reopenError) {
            error(reopenErrorMessage || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (reopenSuccess) {
            success('Receipt reopened successfully', Position.TOP_RIGHT);
            setReopenReceiptModalVisibility(false);
            setTimeout(() => navigate('/blp/receipt'), 2000);
        }
        dispatch(clearReopenFlags({}));
        setIsLoading(false);
    }, [reopenSuccess, reopenError]);

    return (
        <>
            {reopenReceiptModalVisibility ? (
                <div className="app-modal container">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Reopen Receipt</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => setReopenReceiptModalVisibility(false)}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>Are you sure you want to reopen the receipt?</p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => setReopenReceiptModalVisibility(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                onClick={() => onReopenReceipt()}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default ReopenReceiptModal;
