import { createSlice } from '@reduxjs/toolkit';
import { availablePoHeaders } from '../../../utils/constants';

export const dataSlice = createSlice({
    name: 'availablePoLinesLcl',
    initialState: {
        cgId: null,
        bpId: null,
        bpNumber: null,
        totalCBM: 0,
        totalKgs: 0,
        infoValues: {
            originPort: '',
            destinationPort: '',
            goodsReadyDates: [],
        },
        availablePoLineList: [],
        isLoading: true,
        columnStatus: availablePoHeaders.poNumber,
        error: false,
        success: false,
        poData: {},
        isHeaderOnlyPo: null,
    },
    reducers: {
        fetchAvailablePoLineData: (state, action) => {
            state.isLoading = true;
            state.availablePoLineList = action.payload?.response?.confirmationGroupViewPoLineList;
            state.isHeaderOnlyPo = action.payload?.response?.isHeaderOnlyPo;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        setCgId: (state, action) => {
            state.cgId = action.payload?.cgId;
        },
        searchAvailablePoLineData: (state, _action) => {
            state.isLoading = true;
        },
        searchAvailablePoLineDataSuccess: (state, action) => {
            state.availablePoLineList = action.payload?.response?.confirmationGroupViewPoLineList;
            state.isLoading = false;
        },
    },
});

export const {
    fetchAvailablePoLineData,
    isLoadingFn,
    setCgId,
    searchAvailablePoLineData,
    searchAvailablePoLineDataSuccess,
} = dataSlice.actions;
export default dataSlice.reducer;
