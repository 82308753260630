import React, { useEffect, useState } from 'react';
import ReceiptModal from './ReceiptModal';
import DiscardModal from '../DiscardModal';
import ActualReceiptModal from './ActualReceiptModal';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    fetchReceiptHeaderList,
    fetchShippingTerms,
    selectReceiptHeader,
    selectReceiptHeaderLoading,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import CancelModal from '../../../../components/common/CancelModal';
import moment from 'moment';
import ShowForPermissions from '../../../../ShowForPermissions';
import ReceiptCancelModal from './ReceiptCancelModal';
import '../blp-receipt-header.scss';
// import { RootState } from 'src/store/reducers';
// import { useSelector } from 'react-redux';
import { fetchAllPoLines, fetchPolineReceipt } from 'src/store/reducers/receipt/poLine';
import { Position, success } from 'src/utils/toast';
import { activeReceipts, activeStatus } from 'src/utils/constants';
import { setFilter, setSelectedDropDown } from '../../../../store/reducers/receipts/receiptListReducer';
import ReceiptHeaderTabs from 'src/pages/BLP/ReceiptDetails/ReceiptHeaderTabs';
import ReceiptDetailsNextButton from 'src/pages/BLP/ReceiptDetails/ReceiptDetailsNextButton';

const Index: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [modalViewActual, setModalViewActual] = useState(false);
    const [displayCancel, setDisplayCancel] = useState(false);
    // const [displayCancelActual, setDisplayCancelActual] = useState(false);
    const [displayDiscard, setDisplayDiscard] = useState(false);
    const [displayReceiptCancel, setDisplayReceiptCancel] = useState(false);
    const isLoading = useAppSelector(selectReceiptHeaderLoading);
    const receiptHeader = useAppSelector(selectReceiptHeader);
    // const { poLine } = useSelector((state: RootState) => state.receiptPOline);
    const location: any = useLocation();

    useEffect(() => {
        dispatch(fetchPolineReceipt(id));
        dispatch(fetchAllPoLines(id));
    }, [id]);

    const { isBlp, blpIntegrationsReceipt } = useUserProfile();

    useEffect(() => {
        dispatch(
            fetchReceiptHeaderList({
                id,
            })
        );
        dispatch(fetchShippingTerms({}));
    }, []);

    useEffect(() => {
        if (receiptHeader?.receiptStatus) {
            localStorage.setItem('receiptStatus', receiptHeader?.receiptStatus);
        }
    }, [receiptHeader?.receiptStatus]);

    window.onpopstate = () => {
        dispatch(setFilter({ status: activeStatus }));
        dispatch(setSelectedDropDown({ text: activeReceipts, count: 0 }));
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link to="/blp/receipt" className="dropdown-arrow" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/blp/receipt" state={{ reload: true }}>
                                Receipt
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to={`/blp/receipt/${id}/receipt-header`} className="breadcrumb-item">
                                Receipt Details
                            </Link>
                        </li>
                    </ul>
                    <div className="action-wrapper">
                        <div className="btn-holder discard-receipt-btn-holder">
                            <ShowForPermissions type="receipt" permission="create">
                                {receiptHeader?.receiptStatus !== 'Closed' && (
                                    <button
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title={'Save & Exit'}
                                        onClick={() => {
                                            navigate('/blp/receipt');
                                            success(
                                                `${localStorage.getItem('receiptNo')} has been saved`,
                                                Position.TOP_RIGHT
                                            );
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#saveIcon2">
                                                <title>Save & Exit</title>
                                            </use>
                                        </svg>
                                        <span className="button-text">Save & Exit</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                            <ShowForPermissions permission="create" type="receipt">
                                {isBlp && !blpIntegrationsReceipt && receiptHeader?.receiptStatus === 'Draft' && (
                                    <button
                                        onClick={() => setDisplayDiscard(true)}
                                        className="app-btn app-btn-secondary black-outline-btn icon-button"
                                        title="Discard Receipt"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#deleteIcon"></use>
                                        </svg>
                                        <span className="button-text footer-button-text">Discard Receipt</span>
                                    </button>
                                )}
                            </ShowForPermissions>
                            <ShowForPermissions permission="edit" type="receipt">
                                {isBlp &&
                                    !blpIntegrationsReceipt &&
                                    (receiptHeader?.receiptStatus === 'Ready to Receive' ||
                                        receiptHeader?.receiptStatus === 'Ready To Receive') && (
                                        <button
                                            onClick={() => setDisplayReceiptCancel(true)}
                                            className="app-btn app-btn-secondary black-outline-btn icon-button"
                                            title="Cancel Receipt"
                                        >
                                            <span className="button-text footer-button-text">Cancel Receipt</span>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>
                </div>
                <div className="pageheading-holder">
                    {/* <Link to="/blp/receipt">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">{localStorage.getItem('receiptNo')}</h2>
                </div>
                <ReceiptHeaderTabs />
            </div>
            <div className="content-section main-wrapper agent-basic-detail-wrapper">
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                {location.state &&
                location.state?.toLowerCase() === 'interfaced' &&
                !receiptHeader?.rhtabComplete &&
                receiptHeader?.receiptStatus !== 'Ready to Receive' ? (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missed while integrating. Please integrate again with all mandatory fields
                            to proceed.
                        </div>
                    </div>
                ) : null}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Planned Receipt</h6>
                            <ShowForPermissions permission="create" type="receipt">
                                {receiptHeader?.plannedCustomsClearance &&
                                    isBlp &&
                                    !blpIntegrationsReceipt &&
                                    receiptHeader?.receiptStatus === 'Draft' && (
                                        <button
                                            type="button"
                                            onClick={() => setModalView(true)}
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="create" type="receipt">
                            {!receiptHeader?.plannedCustomsClearance && isBlp && !blpIntegrationsReceipt && (
                                <div className="btn-holder">
                                    <button
                                        type="button"
                                        onClick={() => setModalView(true)}
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Details"
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Details</span>
                                    </button>
                                </div>
                            )}
                        </ShowForPermissions>
                    </div>
                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Planned Customs Clearance<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {receiptHeader?.plannedCustomsClearance
                                    ? moment(receiptHeader?.plannedCustomsClearance).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Planned Goods Received Date<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {receiptHeader?.plannedGoodsReceivedDate
                                    ? moment(receiptHeader?.plannedGoodsReceivedDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Shipping Terms<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {receiptHeader?.shippingTerms ? receiptHeader?.shippingTerms : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Fumigation Required<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {typeof receiptHeader?.fumigationRequired === 'boolean'
                                    ? receiptHeader?.fumigationRequired
                                        ? 'Yes'
                                        : 'No'
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Customs Inspection Required<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {typeof receiptHeader?.customsInspectionRequired === 'boolean'
                                    ? receiptHeader?.customsInspectionRequired
                                        ? 'Yes'
                                        : 'No'
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Customs Inspection Date
                                {receiptHeader?.customsInspectionRequired && (
                                    <span className="mandatory-field-text">*</span>
                                )}
                            </h6>
                            <div className="details-box-content">
                                {receiptHeader?.customsInspectionRequired != null
                                    ? receiptHeader?.customsInspectionRequired
                                        ? moment(receiptHeader?.customsInspectionDate).format('DD-MM-YYYY')
                                        : 'NA'
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                QC Inspection Required<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {typeof receiptHeader?.qcInspectionRequired === 'boolean'
                                    ? receiptHeader?.qcInspectionRequired
                                        ? 'Yes'
                                        : 'No'
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                QC Inspection Date
                                {receiptHeader?.qcInspectionRequired && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {receiptHeader?.qcInspectionRequired != null
                                    ? receiptHeader?.qcInspectionRequired
                                        ? moment(receiptHeader?.qcInspectionDate).format('DD-MM-YYYY')
                                        : 'NA'
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Actual Goods Ready Date<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                <>
                                    {receiptHeader?.actualGoodsReadyDate && receiptHeader?.actualGoodsReadyDate !== ''
                                        ? moment(receiptHeader?.actualGoodsReadyDate).format('DD-MM-YYYY')
                                        : '-'}
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {(receiptHeader?.receiptStatus === 'Ready to Receive' ||
                    receiptHeader?.receiptStatus === 'Ready To Receive' ||
                    receiptHeader?.receiptStatus === 'Receipt Complete' ||
                    receiptHeader?.receiptStatus === 'In Progress' ||
                    receiptHeader?.receiptStatus === 'Closed') && (
                    <div className="card details-box custom">
                        <div className="card-head-holder add-btn-holder">
                            <div className="card-head-btn-edit-holder">
                                <h6 className="details-box-heading-2">Actual Receipt</h6>
                                <ShowForPermissions permission="edit" type="receipt">
                                    {receiptHeader?.actualCustomsClearance &&
                                        isBlp &&
                                        !blpIntegrationsReceipt &&
                                        receiptHeader?.receiptStatus !== 'Closed' && (
                                            <button
                                                type="button"
                                                onClick={() => setModalViewActual(true)}
                                                className="action-btn app-btn app-btn-secondary "
                                                title="Edit Details"
                                            >
                                                <svg className="svg-icon edit-icon">
                                                    <use xlinkHref="#editIcon"></use>
                                                </svg>
                                            </button>
                                        )}
                                </ShowForPermissions>
                            </div>

                            <div className="btn-holder">
                                <ShowForPermissions permission="edit" type="receipt">
                                    {!receiptHeader?.actualCustomsClearance && isBlp && !blpIntegrationsReceipt && (
                                        <button
                                            type="button"
                                            onClick={() => setModalViewActual(true)}
                                            className="app-btn app-btn-secondary icon-button"
                                            title="Add Details"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Details</span>
                                        </button>
                                    )}
                                </ShowForPermissions>
                            </div>
                        </div>
                        <div className="card-detail-col">
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Actual Customs Clearance<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {receiptHeader?.actualCustomsClearance
                                        ? moment(receiptHeader?.actualCustomsClearance).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Actual Goods Received Date<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {receiptHeader?.actualGoodsReceivedDate
                                        ? moment(receiptHeader?.actualGoodsReceivedDate).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                            {receiptHeader?.qcInspectionRequired ? (
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">
                                        QC Completed<span className="mandatory-field-text">*</span>
                                    </h6>
                                    <div className="details-box-content">
                                        {typeof receiptHeader?.qcCompleted === 'boolean'
                                            ? receiptHeader?.qcCompleted
                                                ? 'Yes'
                                                : 'No'
                                            : '-'}
                                    </div>
                                </div>
                            ) : null}
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Customs Inspection Date</h6>
                                <div className="details-box-content">
                                    {receiptHeader?.actualCustomsInspectionDate
                                        ? moment(receiptHeader?.actualCustomsInspectionDate).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <ReceiptDetailsNextButton page="receipt-header" />
                </div>
            </div>
            <ReceiptModal
                display={modalView}
                displayFunction={setModalView}
                setDisplayCancel={setDisplayCancel}
                data={receiptHeader}
            />
            <ActualReceiptModal
                display={modalViewActual}
                displayFunction={setModalViewActual}
                setDisplayCancel={setDisplayCancel}
                data={receiptHeader}
            />
            <CancelModal
                displayFunction={modalView ? setModalView : setModalViewActual}
                mode="edit"
                header="Receipt"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
            <DiscardModal discardModalVisibility={displayDiscard} setDiscardModalVisibility={setDisplayDiscard} />
            <ReceiptCancelModal
                receiptCancelModalVisibility={displayReceiptCancel}
                setReceiptCancelModalVisibility={setDisplayReceiptCancel}
            />
        </div>
    );
};
export default Index;
