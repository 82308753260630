import { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useUserProfile } from 'src/hooks/useUserProfile';
import {
    searchBegin,
    selectedSearch,
    clearSearchList,
    // isSearching,
} from 'src/store/reducers/dashboard/dashoboardSearchReducer';
import { RootState } from 'src/store/reducers';
// import Loading from 'src/components/common/Loading';
import { makeApiCall } from 'src/pages/Dashboard/Insights/makeApiCall';
import { useOutsideAlerter } from 'src/hooks/useOutsideAlerter';

export default function SearchField() {
    const dispatch = useDispatch();

    const [searchDropDown, setSearchDropDown] = useState(false); //false
    const [searchKeyword, setSearchKeyword] = useState('');

    const searchTimeout = useRef<any>(null);
    const inputRef = useRef<HTMLDivElement>(null);

    useOutsideAlerter(inputRef, setSearchDropDown);

    const { profileType, userName } = useUserProfile();
    const { searchList, searching } = useSelector((state: RootState) => state.dashboarddata);
    const { insightsMode, insightsDestination, insightsDurationRange, insightsSelectedType, insightsMainPage } =
        useSelector((state: RootState) => state.dashboardInsights);

    useEffect(() => {
        dispatch(clearSearchList());
        // dispatch(isSearching());
        if (searchTimeout?.current) {
            clearTimeout(searchTimeout?.current);
        }

        searchTimeout.current = setTimeout(() => {
            if (searchDropDown) {
                if (searchKeyword.length > 2) {
                    dispatch(
                        searchBegin({
                            userName,
                            profile: profileType,
                            keyword: searchKeyword,
                            isForwarder: profileType === 'forwarder',
                        })
                    );
                }
            }
        }, 1000);
    }, [searchKeyword]);

    function handleSearch(event: any) {
        setSearchKeyword(event.target.value);
    }

    function handleFocusOut() {
        setTimeout(() => {
            setSearchKeyword('');
            setSearchDropDown(false);
            // dispatch(clearSearchList());
        }, 200);
    }

    function handleFocusIn() {
        setSearchKeyword('');
        setSearchDropDown(true);
    }

    //==================== Determine which dashboard insights api to be called ===================//
    const whichApiToBeCalled = () => {
        let apiDeterminationString = '';

        if (insightsMainPage === 'Active Order Details') {
            apiDeterminationString = 'activeOrdersDetails';
        } else if (insightsMainPage === 'Active Orders' || insightsMainPage === '') {
            apiDeterminationString = 'activeOrder';
        } else if (insightsMainPage === 'Active Bookings Details') {
            apiDeterminationString = 'activeBookingsDetails';
        } else if (insightsMainPage === 'Active Bookings') {
            apiDeterminationString = 'activeBooking';
        } else {
            return '';
        }
        return apiDeterminationString;
    };

    //=========================================================================//

    function handleSelectSearchOption(event: any, option: any, type: string) {
        event.stopPropagation();
        dispatch(selectedSearch({ ...option, type }));
        handleFocusOut();
        if (insightsMainPage === '') {
            makeApiCall(
                dispatch,
                insightsMode,
                insightsDurationRange,
                insightsDestination,
                'activeBooking',
                insightsSelectedType,
                '',
                option,
                type
            );
        }
        makeApiCall(
            dispatch,
            insightsMode,
            insightsDurationRange,
            insightsDestination,
            whichApiToBeCalled(),
            insightsSelectedType,
            '',
            option,
            type
        );
    }

    const listIsEmpty =
        !searchList?.purchaseOrders?.length &&
        !searchList?.inboundPOs?.length &&
        !searchList?.allocationOrders?.length &&
        !searchList?.bookingProposals?.length &&
        !searchList?.agents?.length &&
        !searchList?.clients?.length &&
        !searchList?.vendors?.length &&
        !searchList?.shippinglines?.length;

    return (
        <div className="search-entry-holder modal-search dashboard-search" ref={inputRef}>
            <input
                type="text"
                className={`search-input input-text with-border ${searchDropDown ? 'focus' : ''}`}
                onChange={handleSearch}
                value={searchKeyword}
                onFocus={handleFocusIn}
                // onBlur={handleFocusOut}
            />
            {!searchDropDown && !searchKeyword && (
                <span className="search-placeholder default-view">Search and filter Dashboard</span>
            )}
            {searchDropDown && !searchKeyword && (
                <span className="search-placeholder expanded-view">
                    {profileType === 'forwarder'
                        ? 'Search for Orders, Bookings, Clients, Vendors, Agents, Carriers'
                        : profileType === 'agent'
                        ? 'Search for Orders, Bookings, Agents'
                        : 'Search for Orders, Bookings, Clients'}
                </span>
            )}
            <svg className="svg-icon search-icon">
                <use href="#searchIcon">
                    <title>Search</title>
                </use>
            </svg>
            {searchDropDown && searchKeyword && (
                <button className="app-btn text-close-btn" onClick={() => setSearchKeyword('')}>
                    <svg className="svg-icon text-close-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </button>
            )}
            {searchDropDown && searchKeyword && (
                <div className="dropdown-wrap  menu-down dropdown-open text-dropdown dashboard-suggestion-menu">
                    <div className="dropdown-menu details-col details-col details-box-content customscrollbar">
                        {searching && (
                            <div className="app-loader loading ">
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                        )}
                        {listIsEmpty && !searching && (
                            <div className="no-data-content">
                                <svg className="svg-icon grid-no-content-icon ">
                                    <use xlinkHref="#gridNoContentIcon"></use>
                                </svg>
                                <p className="no-content-message">
                                    {searchKeyword.length < 3
                                        ? 'Enter at least 3 characters to search'
                                        : 'No Matching Records Found'}
                                </p>
                            </div>
                        )}
                        {!!searchList?.purchaseOrders?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Purchase Orders</div>
                                {searchList?.purchaseOrders.map((po: any) => (
                                    <a className="menu-item app-btn" key={po.poId}>
                                        <div onClick={(e) => handleSelectSearchOption(e, po, 'Purchase Orders')}>
                                            <div className="main-content">{po.poNumber}</div>
                                            <div className="main-content-name">{po.vendorName}</div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                        {!!searchList?.inboundPOs?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Inbound POs</div>
                                {searchList?.inboundPOs.map((po: any) => (
                                    <a className="menu-item app-btn" key={po.poId}>
                                        <div onClick={(e) => handleSelectSearchOption(e, po, 'Inbound POs')}>
                                            <div className="main-content">{po.poNumber}</div>
                                            <div className="main-content-name">{po.vendorName}</div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                        {!!searchList?.allocationOrders?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Allocation Orders</div>
                                {searchList?.allocationOrders.map((po: any) => (
                                    <a className="menu-item app-btn" key={po.poId}>
                                        <div onClick={(e) => handleSelectSearchOption(e, po, 'Allocation Orders')}>
                                            <div className="main-content">{po.poNumber}</div>
                                            <div className="main-content-name">{po.vendorName}</div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                        {!!searchList?.bookingProposals?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Bookings</div>
                                {searchList?.bookingProposals.map((bp: any) => (
                                    <a className="menu-item app-btn" key={bp.bpId}>
                                        <div onClick={(e) => handleSelectSearchOption(e, bp, 'Bookings')}>
                                            <div className="main-content">{bp.bpNumber}</div>
                                            <div className="main-content-name">
                                                <span>{bp.originPort}</span> to <span>{bp.destinationPort}</span>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                        {!!searchList?.agents?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Agents</div>
                                {searchList?.agents.map((ag: any) => (
                                    <a className="menu-item app-btn" key={ag.id}>
                                        <div
                                            className="main-content"
                                            onClick={(e) => handleSelectSearchOption(e, ag, 'Agents')}
                                        >
                                            {ag.name}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                        {!!searchList?.clients?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Clients</div>
                                {searchList?.clients.map((cl: any) => (
                                    <a className="menu-item app-btn" key={cl.id}>
                                        <div
                                            className="main-content"
                                            onClick={(e) => handleSelectSearchOption(e, cl, 'Clients')}
                                        >
                                            {cl.name}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                        {!!searchList?.shippinglines?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Carriers</div>
                                {searchList?.shippinglines.map((sl: any) => (
                                    <a className="menu-item app-btn" key={sl.id}>
                                        <div
                                            className="main-content"
                                            onClick={(e) => handleSelectSearchOption(e, sl, 'Carriers')}
                                        >
                                            {sl.name}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                        {!!searchList?.vendors?.length && (
                            <div className="menu-item-wrapper">
                                <div className="main-heading">Vendors</div>
                                {searchList?.vendors.map((v: any) => (
                                    <a className="menu-item app-btn" key={v.id}>
                                        <div
                                            className="main-content"
                                            onClick={(e) => handleSelectSearchOption(e, v, 'Vendors')}
                                        >
                                            {v.name}
                                        </div>
                                    </a>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
