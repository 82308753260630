import { statusOfBpsWithUrls } from 'src/utils/constants';
import { get, post, del } from '../../HttpMethods';
import fileDownload from 'js-file-download';
import { externalBaseUrl, profileCode } from 'src/utils/kendoGrid/insights';
import { propagateBcnData } from './bookingProposalHeaderApi';

export interface BpData {
    bpType: 'FCL' | 'LCL';
    bpNumber: string;
    bpStatus: string;
    poNumber: string[];
    clientName: string;
    vendorName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    goodsReadyDate: string[];
    eta: string;
    etd: string;
    vesselVoyage: string;
}

export interface BpResponse {
    bpListingDtos: BpData[];
    totalPages: number;
    totalElements: number;
}

export const getBpData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    profileType: string;
}) => {
    try {
        const res = await post(
            `api/bkg/bp/list/${payload.profileType}?size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getFilterValuesForBp = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/bkg/bp/distinct/${payload.profileType}`, payload.data, null);

        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getLinkedBpByShipmentId = async (shipmentId: string) => {
    try {
        const linkedBooking = await get(
            `cix/scr/forwarding/booking?filter%5Blogic%5D=and&filter%5Bfilters%5D%5B1%5D%5Bfield%5D=BP_ParentShipmentId&filter%5Bfilters%5D%5B1%5D%5Boperator%5D=eq&filter%5Bfilters%5D%5B1%5D%5Bvalue%5D=${shipmentId}&attributes=BP_ShipmentId`,
            null,
            externalBaseUrl
        );
        const data = linkedBooking.data;

        return data;
    } catch (err) {
        return console.error(err);
    }
};

export const getAllLinkedShipment = async (clientCode: string) => {
    try {
        const linkedBooking = await get(
            `/cix/scr/forwarding/booking?take=1000&skip=0&page=1&pageSize=50&filter[logic]=and&filter[filters][1][operator]=contains&filter[filters][1][value]=${clientCode}&filter[filters][1][field]=BP_ClientCode&filter[filters][2][field]=BP_FS&filter[filters][2][operator]=isnotnull&filter[filters][2][value]=&attributes=BP_ShipmentId`,
            null,
            externalBaseUrl
        );

        const ids = linkedBooking.data.map(({ BP_ShipmentId }: { BP_ShipmentId: string }) => BP_ShipmentId);
        const filtered = linkedBooking.data.filter(
            ({ BP_ShipmentId }: { BP_ShipmentId: string }, index: number) => !ids.includes(BP_ShipmentId, index + 1)
        );
        const converted = filtered?.map((res: any) => ({
            label: res?.BP_ShipmentId,
            value: res?.BP_ShipmentId,
        }));

        const data = converted;

        return data;
    } catch (err) {
        return console.error(err);
    }
};

export const getBpStatusCount = async (payload: { data: any; profileType: string }) => {
    try {
        const bookingStatus = await get(
            'cix/scr/forwarding/booking/all/summary?trendhistory=30~days',
            null,
            externalBaseUrl
        );
        const data = bookingStatus.data;

        let result = {};
        for (let index = 0; index < data.length; index++) {
            result = { ...result, [data[index].name]: data?.[index]?.total ?? 0 };
            result = { ...result, [`${data[index].name}_trend`]: data?.[index]?.trend ?? {} };
            result = { ...result, [`${data[index].name}_trendHistory`]: data?.[index]?.trendHistory ?? {} };
        }

        return result;
    } catch (err) {
        return console.error(err);
    }
};

export const cixDeleteBookingProposalApi = async (payload: { data: any; bpPk: any; fromBcn?: boolean }) => {
    try {
        const response = await del(`cix/bkg/${payload.bpPk}`, payload.data, externalBaseUrl);
        if (payload?.fromBcn) {
            await propagateBcnData(payload?.bpPk, true);
        }
        return response;
    } catch (err: any) {
        return err.response.data;
    }
};

export const exportBpList = async (type: string, organisationData: any, profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/bkg/bp/export/${profileType}?type=${type}&sortColumn=${organisationData.sortColumn}&sortOrder=${organisationData.sortOrder}`,
            {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(organisationData),
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'BookingProposals.xlsx' : 'BookingProposals.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
