import React, { useEffect } from 'react';
import './booking-process-tab.scss';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { useDispatch } from 'react-redux';
import { fetchSelectedSailing } from 'src/store/reducers/bookingProposalLand/sailingReducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faNotes } from '@fortawesome/pro-duotone-svg-icons';
import { getUnreadBpNotes } from 'src/services/apis/bookingProposalLand/bookingProposalHeaderApi';
import { updateNotesRead } from 'src/store/reducers/bookingProposalLand/bookingProposalHeaderReducer';
import { noteIconStyle } from 'src/utils/constants';
interface HeaderProps {
    id?: string;
    navData?: any;
}

const Index: React.FC<HeaderProps> = (props) => {
    const headerData = useSelector((state: RootState) => state.bpHeaderData) as any;
    const navigate = useNavigate() as any;
    const { profileType } = useUserProfile();
    const { id } = useParams();
    const location = useLocation();
    const dispatch = useDispatch();

    // useEffect(() => {
    //     //navigate to booking list when trying to access other pages other than add po while no po is selected
    //     if (
    //         !location.pathname.includes('POSelection') &&
    //         !location.pathname.includes('events') &&
    //         !location.pathname.includes('notes') &&
    //         !location.pathname.includes('attachments') &&
    //         headerData?.tabValues &&
    //         headerData?.bpId &&
    //         !headerData?.tabValues?.po
    //     ) {
    //         navigate('/land_booking_list');
    //     }
    // }, [headerData]);

    useEffect(() => {
        if (!location.pathname.includes('SailingSelectionDetails')) {
            // dispatch(fetchSelectedSailing(id));
        }
    }, [id]);

    useEffect(() => {
        if (!headerData?.notesUnread && id && id !== 'POSelection') {
            getUnreadBpNotes(id)
                ?.then((res: any) => dispatch(updateNotesRead(res)))
                .catch((err) => console.log(err, 'err'));
        }
    }, []);

    const tabCheckFunction = (element: any) => {
        let flag = false;
        switch (element.title) {
            case 'PO Selection':
                if (element.selected === 0 && headerData?.tabValues?.po) {
                    return (flag = true);
                }
                break;
            case 'Transport Schedule':
                if (element.selected === 0 && headerData?.tabValues?.sailing) {
                    return (flag = true);
                }
                break;
            case 'Goods Received':
                if (element.selected === 0 && headerData?.tabValues?.sailing) {
                    return (flag = true);
                }
                break;
        }
        return flag;
    };

    const extraTab = [
        {
            title: 'Attachments',
            selected: window.location.pathname.includes('/attachments') ? 1 : 0,
            path: `/land_booking_list/${id}/attachments`,
            disabled: false,
        },
        {
            title: 'Notes',
            selected: window.location.pathname.includes('/notes') ? 1 : 0,
            path: `/land_booking_list/${id}/notes`,
            disabled: false,
        },
        {
            title: 'Events',
            selected: window.location.pathname.includes('/events') ? 1 : 0,
            path: `/land_booking_list/${id}/events`,
            disabled: false,
        },
    ];

    return (
        <div className="arrow-tab-container">
            <div className="arrow-tab-holder">
                {[...props.navData, ...extraTab]
                    ?.filter((res: any) => {
                        if (profileType === 'vendor') {
                            return res?.title !== 'Additional Details';
                        } else {
                            return res;
                        }
                    })
                    ?.map((element: any, index: number) => {
                        return (
                            <Link
                                key={index}
                                className={`arrow-tab-border booking-proposal-tab ${
                                    element.selected === 1
                                        ? ' active'
                                        : id && !element.disabled
                                        ? ' enabled'
                                        : ' enabled'
                                }`}
                                style={{
                                    justifyContent:
                                        element.title === 'Notes' ||
                                        element.title === 'Events' ||
                                        element.title === 'Attachments'
                                            ? 'center'
                                            : 'space-between',
                                }}
                                to={element.path || '/'}
                            >
                                {element.title === 'Notes' ? (
                                    <FontAwesomeIcon
                                        icon={faNotes}
                                        beatFade={headerData?.notesUnread}
                                        style={noteIconStyle as React.CSSProperties}
                                    />
                                ) : element.title === 'Events' ? (
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon calendar-icon">
                                        <use xlinkHref="#calendarIcon">
                                            <title>Calendar</title>
                                        </use>
                                    </svg>
                                ) : element.title === 'Attachments' ? (
                                    <svg style={{ width: '16px', height: '16px' }} className="svg-icon eye-icon">
                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                        <title>Attach file</title>
                                    </svg>
                                ) : tabCheckFunction(element) ? (
                                    <div className="arrow-badge verify-badge">
                                        <svg className="svg-icon tick-icon">
                                            <use href="#tickIcon">
                                                <title>check mark</title>
                                            </use>
                                        </svg>
                                    </div>
                                ) : (
                                    <div className="arrow-badge">{index + 1}</div>
                                )}

                                <div className="arrow-tab">{element.title}</div>
                            </Link>
                        );
                    })}
            </div>
        </div>
    );
};
export default Index;
