import axios from 'axios';
import { get, post } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { formatString } from 'src/components/common/Modals/QuickListModal';

export interface QuickLinksData {
    userName: string;
    quickLinks: any; // correct type after integrating API
}

export const getQuickLinks = async ({ userName }: { userName: string }) => {
    try {
        const response = await get(`/api/mgt/ql/${userName}`, null);
        const result = JSON.parse(response.quickLinks);

        const gridResponse = await get(
            '/cix/user/me/userdata/gridLayout?attributes=UD_PK,UD_DataKey,UD_UserDefinedText1,UD_UserDefinedText2&take=10&skip=0&page=1&pageSize=50&filter[logic]=and&filter[filters][1][field]=UD_UserDefinedText2&filter[filters][1][operator]=eq&filter[filters][1][value]=quicklink',
            null,
            externalBaseUrl
        );

        const gridLayout = gridResponse?.data?.map((res: any, index: number) => ({
            id: result?.length + index + 1,
            UD_PK: res?.UD_PK,
            link: res?.UD_DataKey,
            name: res.UD_UserDefinedText1,
            titleName: formatString(res.UD_DataKey),
            type: 'savedLayout',
        }));
        return { quickLinks: [...result, ...gridLayout], clientId: response.clientId };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            throw error;
        }
    }
};

export const getQuickLinksSavedLayout = async () => {
    try {
        const response = await get(
            '/cix/user/me/userdata/gridLayout?attributes=UD_PK,UD_DataKey,UD_UserDefinedText1,UD_UserDefinedText2,UD_UserDefinedText3',
            null,
            externalBaseUrl
        );
        return response?.data;
    } catch (error: any) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateQuickLinkSettings = async (payload: { userName: string; quickLinks: any }) => {
    try {
        const response = await post(`api/mgt/ql`, payload, null);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw error.response;
        } else {
            throw error;
        }
    }
};

export const getQuickStatusLinkDataService = async ({
    payload,
}: {
    payload: {
        queryString: {
            keyword: string;
            profile: string;
        };
        body: {
            listOfUserOrganisations: any;
            loggedInUserName: string;
        };
    };
}) => {
    let query = `api/mgt/dashboard/quicklink`;
    if (payload) {
        if (payload.queryString) {
            if (payload.queryString.keyword) {
                query = query + '?keyword=' + payload.queryString.keyword;
            }
            if (payload.queryString.profile) {
                query =
                    query + `${payload.queryString.keyword ? '&profile=' : '?profile='}` + payload.queryString.profile;
            }
        }
    }
    try {
        const response = await post(query, payload.body, null);
        return { userQuickLinks: response };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            throw error;
        }
    }
};

export const dashboardActiveOrdersService = async ({ payload }: { payload: any }) => {
    try {
        const url = 'api/ord/db/insights/orders';
        const res = await post(url, payload, null);
        return res;
    } catch (er) {
        return er;
    }
};
export const dashboardActiveOrdersServiceExpanded = async ({ payload }: { payload: any }) => {
    try {
        const url = 'api/ord/db/insights/expanded/orders';
        const res = await post(url, payload, null);
        return res;
    } catch (er) {
        return er;
    }
};
export const dashboardActiveBookingsService = async ({ payload }: { payload: any }) => {
    try {
        const url = 'api/bkg/db/insights/bookings';
        const res = await post(url, payload, null);
        return res;
    } catch (er) {
        return er;
    }
};
export const dashboardActiveBookingsServiceExpanded = async ({ payload }: { payload: any }) => {
    try {
        const url = 'api/bkg/db/insights/expanded/bookings';
        const res = await post(url, payload, null);
        return res;
    } catch (er) {
        return er;
    }
};
