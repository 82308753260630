import { permissionsWithProfileView } from 'src/utils/constants';
import { get } from '../HttpMethods';

export interface vendorData {
    orgId: string;
    orgName: string;
    orgCode: string;
    orgStatus: string;
    parentOrgCode: string;
    vendorId: string;
    vendorStatus: string;
    vendorCountry: string;
    relationship: string;
    childVendors: vendorData[];
}

export interface vendorListResponse {
    vendorResponseLists: vendorData[];
    totalNoOfPages: number;
    totalNoOfItems: number;
}

export const modifyPermissionData = (value: any) => {
    const data = JSON.parse(JSON.stringify(value));
    for (let index = 0; index < data?.roles?.length; index++) {
        if (
            !permissionsWithProfileView?.includes(data?.roles[index].roleName?.toLowerCase()) &&
            data?.roles[index].profile?.toLowerCase() !== 'forwarder'
        ) {
            const copy = JSON.parse(JSON.stringify(data?.roles[index].permissions));
            data.roles[index].permissions = copy?.filter((res: any) => res.type !== 'Profiles');
        }
    }
    return data;
};

// Get All Datas
export const getPermissions = async (payload: any) => {
    try {
        const result = await get(`api/auth/refreshuserdetails?userName=${payload}`, null);
        const res = modifyPermissionData(result);
        localStorage.setItem('wholePerms', JSON.stringify(res)); //saving the permission data to local storage
        return res;
    } catch (err) {
        return console.log(err);
    }
};
