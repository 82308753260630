import { createSlice } from '@reduxjs/toolkit';

interface Favourite {
    id: any;
    isfav?: boolean;
    userName: string;
    favourite: string;
    isSea: false;
    isAir: false;
    isLand: false;
    isInternational: false;
    isTriangle: false;
    status: string | null;
    type: string;
}
interface FavouriteState {
    favourites: Favourite[];
    groupFavouriteData: any;
    isFetchError: boolean;
    isFetchSuccess: boolean;
    isAddError: boolean;
    isAddSuccess: boolean;
    isRemoveError: boolean;
    isRemoveSuccess: boolean;
    isRemoveAllError: boolean;
    isRemoveAllSuccess: boolean;
    isReOrderError: boolean;
    isReOrderSuccess: boolean;
    isLoading: boolean;
    error: any;
    graphData: any;
    selectedFav: any;
}
const initialState: FavouriteState = {
    favourites: [],
    groupFavouriteData: {
        Days: {},
        Weeks: {},
        Months: {},
    },
    isFetchError: false,
    isFetchSuccess: false,
    isAddError: false,
    isAddSuccess: false,
    isRemoveError: false,
    isRemoveSuccess: false,
    isRemoveAllError: false,
    isRemoveAllSuccess: false,
    isReOrderError: false,
    isReOrderSuccess: false,
    isLoading: true,
    error: {},
    graphData: null,
    selectedFav: null,
};

export const favouritesSlice = createSlice({
    name: 'favourites',
    initialState,
    reducers: {
        fetchFavourites(state, _action) {
            state.isLoading = true;
        },
        fetchFavouritesSuccess(state, action) {
            state.isLoading = false;
            state.favourites = action.payload;
            state.isFetchSuccess = true;
        },
        fetchFavouritesError(state, action) {
            state.isLoading = false;
            state.isFetchError = true;
            state.error = action.payload;
        },
        resetFetchSuccessFlag: (state, _action) => {
            state.isFetchSuccess = false;
        },
        resetFetchErrorFlags: (state, _action) => {
            state.isFetchError = false;
            state.error = {};
        },
        addToFavourites: (state, _action) => {
            state.isLoading = true;
        },
        addToFavouritesSuccess: (state, _action) => {
            state.isLoading = false;
            state.isAddSuccess = true;
            state.groupFavouriteData = {
                Days: {},
                Weeks: {},
                Months: {},
            };
        },
        addToFavouritesError: (state, action) => {
            state.isAddError = true;
            state.isLoading = false;
            state.error = action.payload;
        },
        resetAddSuccessFlag: (state, _action) => {
            state.isAddSuccess = false;
        },
        resetAddErrorFlags: (state, _action) => {
            state.isAddError = false;
            state.error = {};
        },
        removeFromFavourites: (state, _action) => {
            state.isLoading = true;
        },
        removeFromFavouritesSuccess: (state, _action) => {
            state.isLoading = false;
            state.isRemoveSuccess = true;
            state.groupFavouriteData = {
                Days: {},
                Weeks: {},
                Months: {},
            };
        },
        removeFromFavouritesError: (state, action) => {
            state.isRemoveError = true;
            state.isLoading = false;
            state.error = action.payload;
        },
        resetRemoveSuccessFlag: (state, _action) => {
            state.isRemoveSuccess = false;
        },
        resetRemoveErrorFlags: (state, _action) => {
            state.isRemoveError = false;
            state.error = {};
        },
        removeAllFavourites: (state, _action) => {
            state.isLoading = true;
        },
        removeAllFavouritesSuccess: (state, _action) => {
            state.isLoading = false;
            state.isRemoveAllSuccess = true;
        },
        removeAllFavouritesError: (state, action) => {
            state.isRemoveAllError = true;
            state.isLoading = false;
            state.error = action.payload;
        },
        resetRemoveAllSuccessFlag: (state, _action) => {
            state.isRemoveAllSuccess = false;
        },
        resetRemoveAllErrorFlags: (state, _action) => {
            state.isRemoveAllError = false;
            state.error = {};
        },
        reOrderFavourites: (state, _action) => {
            state.isLoading = true;
        },
        reOrderFavouritesSuccess: (state, _action) => {
            state.isLoading = false;
            state.isReOrderSuccess = true;
        },
        reOrderFavouritesError: (state, action) => {
            state.isReOrderError = true;
            state.isLoading = false;
            state.error = action.payload;
        },
        resetReOrderFavouritesSuccessFlag: (state, _action) => {
            state.isReOrderSuccess = false;
        },
        resetReOrderFavouritesErrorFlags: (state, _action) => {
            state.isReOrderError = false;
            state.error = {};
        },
        setFavGroupData: (state, action) => {
            state.groupFavouriteData[action.payload.insightsDurationRange] = action.payload.resultObject;
        },
        setFavDataResultToGroupObject: (state, action) => {
            state.groupFavouriteData[action.payload.currentFilterValue][action.payload.aggregateKey].resultData =
                action.payload.res;
        },
        setGraphData: (state, action) => {
            state.graphData = action.payload;
        },
        setSelectedFav: (state, action) => {
            state.selectedFav = action.payload;
        },
    },
});

export const {
    fetchFavourites,
    fetchFavouritesSuccess,
    fetchFavouritesError,
    resetFetchSuccessFlag,
    resetFetchErrorFlags,
    addToFavourites,
    addToFavouritesSuccess,
    addToFavouritesError,
    resetAddSuccessFlag,
    resetAddErrorFlags,
    removeFromFavourites,
    removeFromFavouritesSuccess,
    removeFromFavouritesError,
    resetRemoveSuccessFlag,
    resetRemoveErrorFlags,
    removeAllFavourites,
    removeAllFavouritesSuccess,
    removeAllFavouritesError,
    resetRemoveAllSuccessFlag,
    resetRemoveAllErrorFlags,
    reOrderFavourites,
    reOrderFavouritesSuccess,
    reOrderFavouritesError,
    resetReOrderFavouritesSuccessFlag,
    resetReOrderFavouritesErrorFlags,
    setFavGroupData,
    setFavDataResultToGroupObject,
    setGraphData,
    setSelectedFav,
} = favouritesSlice.actions;

export default favouritesSlice.reducer;
