import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { fetchAdditionalDataLcl } from 'src/services/apis/bookingProposalLcl/additionalDetailsApi';

import {
    fetchDetailsLcl,
    fetchDetailsLclFailed,
    fetchDetailsLclSuccess,
} from 'src/store/reducers/bookingProposalLcl/additionalDetailsReducer';

function* getAllDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchAdditionalDataLcl, payload);
        yield put({ type: fetchDetailsLclSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchDetailsLclFailed, payload: error });
    }
}

function* additionalDetailsLclSaga() {
    yield takeEvery(fetchDetailsLcl, getAllDetails);
}

export default additionalDetailsLclSaga;
