import { toast } from 'react-toastify';
import './style.css';
import '../../assets/scss/components/_toast.scss';

export enum Position {
    TOP_RIGHT = 'top-right',
    TOP_CENTER = 'top-center',
    TOP_LEFT = 'top-left',
    BOTTOM_RIGHT = 'bottom-right',
    BOTTOM_CENTER = 'bottom-center',
    BOTTOM_LEFT = 'bottom-left',
}

export const success = (message: string, position: Position, toastId?: any, autoClose?: any) => {
    toast.success(message, {
        position,
        autoClose: autoClose ? autoClose : 4000,
        hideProgressBar: true,
        pauseOnHover: false,
        icon: (
            <svg className="svg-icon checkmark-icon">
                <use xlinkHref="#checkmarkIcon">
                    <title>Success</title>
                </use>
            </svg>
        ),
        toastId: toastId,
    });
};

export const error = (message: string, position: Position, toastId?: any) => {
    toast.error(message, {
        position,
        autoClose: 6000,
        hideProgressBar: true,
        pauseOnHover: false,
        icon: (
            <svg className="svg-icon error-toast-icon">
                <use xlinkHref="#errorIcon">
                    <title>Failure</title>
                </use>
            </svg>
        ),
        toastId: toastId,
    });
};

export const warning = (message: string, position: Position) => {
    toast.warning(message, {
        position,
        autoClose: 6000,
        hideProgressBar: true,
        pauseOnHover: false,
    });
};

export const info = (message: string, position: Position) => {
    toast.info(message, {
        position,
        autoClose: 6000,
        hideProgressBar: true,
        pauseOnHover: false,
    });
};
