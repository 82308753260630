/* eslint-disable no-undefined */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import React, { ReactElement } from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import './style.css';
import 'react-datepicker/dist/react-datepicker.css';

interface ButtonProps {
    formik: any;
    fieldName: string;
    minDate?: Date;
    format?: string;
    onCalendarOpen?: any;
    onCalendarClose?: any;
    portalId?: string;
    disabled?: boolean;
    maxDate?: Date;
    position?: string;
}

const CustomInput = React.forwardRef((props: any, ref: any) => {
    const format = props.format ? props.format : 'DD-MM-YYYY';
    const placeholder = format.toLowerCase();
    return (
        <div className="modal-input-field-holder date-input-holder">
            <input
                className={`modal-input input-text with-border date-input ${
                    props.disabled === false ? 'input-date-cursor' : ''
                }`}
                type="text"
                readOnly={props.disabled ? false : true}
                disabled={props.disabled ? true : false}
                onClick={props.onClick}
                placeholder={placeholder}
                name={props.fieldName}
                ref={ref}
                value={props.value ? moment(props.value).format(format) : ''}
            />
            <svg className="svg-icon calendar-icon">
                <use xlinkHref="#calendarIcon">
                    <title>Calendar</title>
                </use>
            </svg>
        </div>
    );
});
const Index = ({
    formik,
    fieldName,
    minDate,
    format,
    onCalendarOpen,
    onCalendarClose,
    portalId,
    disabled,
    maxDate,
    position,
}: ButtonProps): ReactElement => {
    return (
        <DatePicker
            disabled={disabled}
            name={fieldName}
            onChange={(value: any) => {
                formik.setFieldError(fieldName, undefined);
                formik.setFieldValue(fieldName, moment(value).format('YYYY-MM-DD'));
            }}
            minDate={minDate || undefined}
            selected={(formik.values[fieldName] && new Date(formik.values[fieldName])) || null}
            onCalendarOpen={() => onCalendarOpen}
            onCalendarClose={() => onCalendarClose}
            portalId={portalId}
            customInput={<CustomInput format={format} fieldName={fieldName} disabled={disabled} />}
            maxDate={maxDate || undefined}
            popperPlacement={position}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
        />
    );
};

export default Index;
