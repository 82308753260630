import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '.';

interface UserData {
    status: number;
    message: string;
}
interface User {
    status: number;
    data: UserData | undefined;
    user: undefined;
}
export interface NotificationData {
    blpName: string;
    bpId: string;
    bpNumber: string;
    bpType: string;
    confirmationGroupId: string;
    confirmationGroupNum: string;
    createdDate: string;
    dsId: string;
    isRead: number;
    itemId: string;
    itemType: string;
    notification: string;
    notificationId: string;
    notificationType: string;
    poId: string;
    poLineNumber: number;
    poNumber: string;
    sku: string;
}

interface AuthState {
    isLoading: boolean;
    user?: User;
    notification?: any;
    notificationUpdated?: boolean;
    isError?: boolean;
}

const initialState: AuthState = {
    isLoading: false,
    // eslint-disable-next-line no-undefined
    user: undefined,
    // eslint-disable-next-line no-undefined
    notification: undefined,
    notificationUpdated: false,
    isError: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, _action) => {
            state.isLoading = true;
        },
        loginSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.user = action.payload;
        },
        loginFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.user = action.payload;
        },
        logout(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.user = action.payload;
        },
        logoutSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.user = action.payload;
        },
        logoutFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.user = action.payload;
        },
        getNotification(state, _action) {
            state.isLoading = true;
            state.notificationUpdated = false;
            state.isError = false;
            // state.notification = action.payload;
        },
        getNotificationSuccess(state, action) {
            state.isLoading = false;
            state.notificationUpdated = false;
            state.isError = false;
            state.notification = action.payload?.info;
        },
        getNotificationFailed(state, _action) {
            state.notificationUpdated = false;
            state.isLoading = false;
            // state.isError = true;
            // state.notification = action.payload;
        },
        markSingleRead(state, _action) {
            // state.isLoading = false;
            state.isError = false;
            // state.notification = action.payload;
        },
        markSingleReadSuccess(state, _action) {
            // state.isLoading = false;
            state.notificationUpdated = true;
            state.isError = false;
            // state.notification = action.payload;
        },
        markSingleReadFailed(state, _action) {
            state.notificationUpdated = false;
            // state.isLoading = false;
            // state.isError = true;
            // state.notification = action.payload;
        },
        markAllRead(state, _action) {
            // state.isLoading = false;
            state.isError = false;
            // state.notification = action.payload;
        },
        markAllReadSuccess(state, _action) {
            // state.isLoading = false;
            state.notificationUpdated = true;
            state.isError = false;
            // state.notification = action.payload;
        },
        markAllReadFailed(state, _action) {
            state.notificationUpdated = false;
            // state.isLoading = false;
            // state.isError = true;
            // state.notification = action.payload;
        },
    },
});

export const {
    login,
    loginSuccess,
    loginFailed,
    logout,
    logoutSuccess,
    logoutFailed,
    getNotification,
    getNotificationSuccess,
    getNotificationFailed,
    markSingleRead,
    markSingleReadSuccess,
    markSingleReadFailed,
    markAllRead,
    markAllReadSuccess,
    markAllReadFailed,
} = authSlice.actions;

export const selectAuthLoading = (state: RootState) => state.authData.isLoading;
export const selectAuthUser = (state: RootState) => state.authData.user;
export const selectNotification = (state: RootState) => state.authData.notification;
export const selectAuthIsError = (state: RootState) => state.authData.isError;
export const selectNotificationUpdated = (state: RootState) => state.authData.notificationUpdated;

export default authSlice.reducer;
