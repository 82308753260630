import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'overrideShipments',
    initialState: {
        isLoading: false,
        overrideShipments: {} as any,
    },
    reducers: {
        fetchOverrideShipments: (state, action) => {
            state.isLoading = true;
            state.overrideShipments = action.payload?.data;
            state.isLoading = false;
        },
    },
});

export const { fetchOverrideShipments } = dataSlice.actions;
export default dataSlice.reducer;
