import { createSlice } from '@reduxjs/toolkit';

export interface FilesListData {
    packingDeclaration: FileData[];
    packingList: FileData[];
    fumigationCertificate: FileData[];
    commercialInvoice: FileData[];
    certificateOfOrigin: FileData[];
    hazardousCertificate: FileData[];
    houseBillOfLading: FileData[];
    manufacturesDeclaration: FileData[];
    isFumigationMandatory: boolean;
}

export interface FileData {
    fileId: string;
    fileName: string;
    fileSize: number;
}

interface AttachmentsData {
    isLoading: boolean;
    files: FilesListData;
    errorStat: false;
    errorMessage: string;
}

const initialState: AttachmentsData = {
    isLoading: false,
    files: {
        packingDeclaration: [],
        packingList: [],
        fumigationCertificate: [],
        commercialInvoice: [],
        certificateOfOrigin: [],
        hazardousCertificate: [],
        houseBillOfLading: [],
        manufacturesDeclaration: [],
        isFumigationMandatory: false,
    },
    errorStat: false,
    errorMessage: '',
};
export const attachmentSlice = createSlice({
    name: 'bookingProposalAirAttachment',
    initialState,
    reducers: {
        fetchFiles: (_state, _action) => {
            // console.log(action);
        },
        saveFiles: (state, action) => {
            state.files = action.payload;
        },
        uploadFiles: (_state, _action) => {
            // console.log(action);
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        removeSingleFile: (_state, _action) => {
            // console.log(action);
        },
        downloadSingle: (_state, _action) => {
            // console.log(action);
        },
        downloadAll: (_state, _action) => {
            // console.log(action);
        },
        setWarning: (state, action) => {
            state.errorMessage = action.payload.message;
            state.errorStat = action.payload.errorStat;
        },
    },
});

export const {
    isLoadingFn,
    fetchFiles,
    saveFiles,
    uploadFiles,
    removeSingleFile,
    downloadSingle,
    downloadAll,
    setWarning,
} = attachmentSlice.actions;
export default attachmentSlice.reducer;
