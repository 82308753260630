import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchDatesPoList,
    fetchDatesPoListSuccess,
    isLoadingFn,
    createDatesPoSuccess,
    createDatesPoFailure,
    updateDatesPoSuccess,
    updateDatesPoFailure,
    createDatesPo,
    updateDatesPo,
} from '../../reducers/purchaseOrder/datesPoReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchDatesPoListDetailsApi,
    createDatesPoDetailsApi,
    updateDatesPoDetailsApi,
} from '../../../services/apis/purchaseOrder/datesPoApi';

// eslint-disable-next-line
function* getDatesPoDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchDatesPoListDetailsApi, payload);
        yield put({ type: fetchDatesPoListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchDatesPoListFailed, payload: error });
        yield put({ type: isLoadingFn, payload: false });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}

// eslint-disable-next-line
function* createDatesPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(createDatesPoDetailsApi, payload);
        yield put({ type: createDatesPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: createDatesPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* updateDatesPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(updateDatesPoDetailsApi, payload);
        yield put({ type: updateDatesPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateDatesPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* datesPoSaga() {
    yield takeEvery(fetchDatesPoList, getDatesPoDetails);
    yield takeEvery(createDatesPo, createDatesPoDetailsData);
    yield takeEvery(updateDatesPo, updateDatesPoDetailsData);
}

export default datesPoSaga;
