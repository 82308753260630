import { ReactElement, useEffect, useState } from 'react';
import { gridProperties } from '../../../utils/constants';
import '../../../assets/scss/components/_tab.scss';
import '../../../assets/scss/components/_grid.scss';
import '../../../assets/scss/components/_status.scss';
import '../../../assets/scss/components/_buttons.scss';
import '../../../assets/scss/components/_dropdown.scss';
import '../../../assets/scss/components/_check-radio.scss';
import '../../../assets/scss/components/_textfield.scss';
// import { useNavigate } from 'react-router-dom';
// import SearchableFilter from '../SearchableFilter';
import '../SearchableFilter/style.scss';
import SearchableFilter from '../SearchableFilter';
import { useDispatch, useSelector } from 'react-redux';
// import { setHeaderData } from '../../../store/reducers/bookingProposal/availablePoReducer';
import TableToolTip from '../TableToolTip';
import { setCgId as saveCgId, setPoId } from 'src/store/reducers/bookingProposal/availablePoLineReducer';
import { setCgId } from 'src/store/reducers/bookingProposalLcl/availablePoLineReducer';
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../store/reducers';
import ProductDetailModal from '../../../pages/BookingProposal/BookingAddedGrid/ProductDetailsModal';
import BSTooltip from '../BSTooltip';
import ClickAwayListener from 'react-click-away-listener';

// import TableToolTip from '../TableToolTip';

const tabTypeList = [
    'availablePos',
    'poLines',
    'availableAos',
    'availableLcl',
    'lclpoLines',
    'poLinesAir',
    'availablePosAir',
    'poLinesHeaderOnly',
] as const;

interface TableListProps {
    tabName: typeof tabTypeList[number];
    data: any[];
    columnStatus?: string;
    currentOrder?: boolean;
    onSort?: (sortKey: string) => void;
    setFilter?: (data: any) => void;
    filterDataLists?: any;
    filter?: any;
    disabledFilterData?: any;
    setDisabledFilterData?: any;
    selectedItems?: any[];
    setSelectedItems?: any;
    displayModalFunc?: any;
    addPoList?: any;
    setaddedgrid?: any;
}

const SelectableGrid = ({
    tabName,
    data,
    columnStatus,
    currentOrder,
    onSort,
    filterDataLists,
    setFilter,
    filter,
    disabledFilterData,
    setDisabledFilterData,
    selectedItems,
    setSelectedItems,
    displayModalFunc,
    addPoList,
    setaddedgrid,
}: TableListProps): ReactElement => {
    const dispatch = useDispatch();
    const [selectedDropDown, setselectedDropDown] = useState('');
    const [selectedDates, setSelectedDates] = useState<{ key: string; value: Date | null }[]>([]);
    const [rowexpand, settableRowExp] = useState(false);
    const [rowLine, setRowLine] = useState(null);
    const [columnExpand, setColumnExpand] = useState(null);
    const primaryKeyToStore = gridProperties[tabName].primaryKeyToStore;
    const { columnsToDisableBy } = gridProperties[tabName];
    const currentHeaderList: any = gridProperties[tabName].tableHeaderList;
    const keysToStore = gridProperties[tabName].keysToStore;
    const bpID = localStorage.getItem('bpId');
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    const navigate = useNavigate();
    const { bpStatus, bpNumber } = useSelector((state: RootState) => state.bpHeaderData);
    const { selectedItems: availableSelectedItems } = useSelector((state: any) => state[gridProperties[tabName].state]);

    //To handle multiple header with date filter
    useEffect(() => {
        const headersWithDate: { key: string; value: Date | null }[] = [];
        //TODO: update headerlist type appropriately
        currentHeaderList.forEach((header: any) => {
            if (header.dateFilter) {
                headersWithDate.push({ key: header.key, value: filter[header.key] || null });
            }
        });
        if (headersWithDate.length) {
            setSelectedDates(headersWithDate);
        } else if (selectedDates.length) {
            const filterObj = JSON.parse(JSON.stringify(filter));
            selectedDates.forEach((item) => {
                if (filterObj[item.key]) {
                    delete filterObj[item.key];
                }
            });

            setFilter && setFilter(filterObj);
            setSelectedDates([]);
        }
    }, [currentHeaderList]);

    const isCheckBoxDisabled = (res: any) => {
        if (bpStatus === 'Cancelled') {
            return true;
        }
        if (!res) {
            //Select all by default disabling
            return !selectedItems?.length;
        }
        for (const key of columnsToDisableBy) {
            //multi destination based disabling
            if (key && Array.isArray(res[key]) && res[key].length > 1) {
                return true;
            }
        }
        // eslint-disable-next-line guard-for-in
        for (const key in disabledFilterData) {
            if (
                disabledFilterData &&
                (disabledFilterData[key] || disabledFilterData[key] === false) &&
                res &&
                Object.prototype.hasOwnProperty.call(disabledFilterData, key) &&
                Object.prototype.hasOwnProperty.call(res, key)
            ) {
                if (Array.isArray(res[key])) {
                    if (disabledFilterData[key][0] !== null) {
                        return res[key]?.toString() !== disabledFilterData[key]?.toString();
                    }
                    return false;
                }
                //eg:disabledFilterData[destination] might be null|string|array of strings
                if (disabledFilterData[key] || disabledFilterData[key] === false) {
                    if (Array.isArray(disabledFilterData[key])) {
                        if (res[key] !== disabledFilterData[key][0]) {
                            return true;
                        }
                    } else if (res[key] !== disabledFilterData[key]) {
                        return true;
                    }
                }
            }
        }
        return false;
    };

    const getAllEnabledData = () => {
        const mergedData = [].concat(...data) as any[];
        return mergedData?.filter((item) => {
            let isEqual = true;
            for (const key in disabledFilterData) {
                if (disabledFilterData && Object.prototype.hasOwnProperty.call(disabledFilterData, key)) {
                    if (disabledFilterData[key]?.toString() !== item[key]?.toString()) {
                        isEqual = false;
                        break;
                    }
                }
            }
            return isEqual;
        });
    };

    const getCountOfEnabledCheckboxes = () => {
        return getAllEnabledData()?.length;
    };

    const getitemToBeStoredAsSelection = (res: any) => {
        const selectedItemKey = primaryKeyToStore;
        if (selectedItemKey) {
            return res[selectedItemKey];
        }
    };

    const setAllEnabledAsSelected = () => {
        const selectedList = [];
        for (const item of getAllEnabledData()) {
            selectedList.push(generateStorageObject(item));
        }
        setSelectedItems(selectedList);
    };

    const clearAndDisableAll = () => {
        if (gridProperties[tabName].shouldClearFitlerOnUnselect || !availableSelectedItems?.length) {
            setDisabledFilterData({});
        }
        setSelectedItems([]);
    };

    const isChecked = (res: any) => {
        if (!res) {
            if (selectedItems && selectedItems.length) {
                return selectedItems.length === getCountOfEnabledCheckboxes();
            } else {
                return false;
            }
        }
        return selectedItems?.map((item) => item[primaryKeyToStore]).includes(getitemToBeStoredAsSelection(res));
    };

    const getFilterDropDownPosition = (key: string) => {
        // const headerArray = gridProperties[tabName].tableHeaderList;
        const filterElement = document.querySelector<any>(`#${key}`);
        const filterElementDistanceFromLeft = filterElement.getBoundingClientRect().left + 50;
        const difference = window.innerWidth - (filterElementDistanceFromLeft + 270);
        // const filterElementDistanceFromTop = filterElement.getBoundingClientRect().top - 290;
        const filterElementDistanceFromTop =
            filterElement.getBoundingClientRect().top -
            document.querySelector<any>('.grid-container')?.getBoundingClientRect().top +
            22;
        return {
            left: `${
                difference < 0 ? filterElementDistanceFromLeft - difference - 270 : filterElementDistanceFromLeft
            }px`,
            top: `${filterElementDistanceFromTop}px`,
        };
    };

    const getFilterDropDownPositionTop = () => {
        const filterElement = document.querySelector<any>('div.filter-menu-wrap');
        if (filterElement) {
            const filterElementDistanceFromTop = filterElement.getBoundingClientRect().top;
            return `${filterElementDistanceFromTop}px`;
        } else {
            return '0px';
        }
    };

    const isFiltered = (key: string) => {
        return !!filter[key];
    };

    //Get object with column headers as keys and filterValues as values
    const getFilterColumns = (res: any) => {
        const filters = {} as any;
        for (const key of columnsToDisableBy) {
            filters[key] = res[key];
        }
        return filters;
    };

    const generateStorageObject = (res: any) => {
        const storageObject = {} as any;
        if (keysToStore) {
            for (const item of keysToStore) {
                storageObject[item] = res[item];
            }
            storageObject[primaryKeyToStore] = getitemToBeStoredAsSelection(res);
            return storageObject;
        }
    };

    const handleCheckboxClick = (checked: boolean, res: any) => {
        if (checked) {
            if (!res) {
                setAllEnabledAsSelected();
            } else {
                if (!selectedItems?.length) {
                    setDisabledFilterData(getFilterColumns(res));
                }
                setSelectedItems([...(selectedItems || []), generateStorageObject(res)]); //push to stack
            }
        } else {
            if (!res) {
                clearAndDisableAll();
            } else {
                const previousItems = JSON.parse(JSON.stringify(selectedItems));
                const modifiedItems = previousItems.filter(
                    (item: any) => item[primaryKeyToStore] !== getitemToBeStoredAsSelection(res)
                );
                if (!modifiedItems.length) {
                    clearAndDisableAll();
                } else {
                    setSelectedItems(modifiedItems);
                }
            }
        }
    };
    const handleDateSelection = (key: string, dateValue: Date | null) => {
        const dateList = [...selectedDates];
        const selectedItemIndex = dateList.findIndex((date) => date.key === key);

        if (selectedItemIndex >= 0) {
            dateList[selectedItemIndex] = { key, value: dateValue };
            setSelectedDates(dateList);
        }
    };
    const navigateToPage = (res: any) => {
        if (gridProperties[tabName].poLineViewPath) {
            const path = gridProperties[tabName].poLineViewPath;
            const key = gridProperties[tabName].poLineKeyToStore;
            localStorage.setItem(key, res[key]);
            if (gridProperties[tabName].name === 'availablePosAir') {
                const payload = {
                    poId: res.poId,
                    destinationPort: res.destinationPort,
                    goodsReadyDate: res.goodsReadyDate,
                };
                localStorage.setItem('polineAirKey', JSON.stringify(payload));
            }
            if (gridProperties[tabName].name === 'availablePos') {
                localStorage.setItem('cgId', res.cgId);
                dispatch(saveCgId({ cgId: res.cgId }));
            }
            if (gridProperties[tabName].name === 'availableLcl') {
                dispatch(setCgId({ cgId: res.cgId }));
            } else {
                dispatch(setPoId({ poId: res.poId }));
            }
            if (bpID) {
                navigate(path.replace(':id', bpID));
            } else {
                if (path === '/air_booking_list/:id/POSelectionDetails') {
                    navigate('/air_booking_list/POSelectionDetails/new');
                } else {
                    navigate(path.replace('/:id', ''));
                }
            }
        }
    };

    const renderLinkableData = (res: any, item: any, displayModalId: string) => {
        return (
            <div className="grid-cell-data">
                <a
                    onClick={() => {
                        // eslint-disable-next-line no-undefined
                        if (res?.clientCode === undefined) {
                            displayModalFunc({ status: true, id: displayModalId });
                        } else {
                            setModalViewproduct({
                                status: true,
                                value: res?.clientCode,
                                id: res?.skuId,
                            });
                        }
                    }}
                    style={{ cursor: 'pointer' }}
                    className="booking-proposal-link"
                >
                    {res[item.key]}
                </a>
            </div>
        );
    };

    const renderOptions = {
        CHECKBOX: (item: any, res: any) => (
            <div className="grid-cell-data centre-align">
                <label className="app-check-wrapper">
                    <input
                        type="checkbox"
                        className="checkbox-input"
                        disabled={isCheckBoxDisabled(res)}
                        checked={isChecked(res)}
                        onClick={(event: any) => {
                            handleCheckboxClick(event.target.checked, res);
                        }}
                    />
                    <div className="checkmark">
                        <svg className="svg-icon tick-icon">
                            <use xlinkHref="#tickIcon">
                                <title>check mark</title>
                            </use>
                        </svg>
                    </div>
                </label>
            </div>
        ),
        'PO NUMBER': (item: any, res: any) => renderLinkableData(res, item, res?.poId),
        SKU: (item: any, res: any) => renderLinkableData(res, item, ''),
        ACTIONS: (item: any, res: any) => (
            // generate unique row id
            <BSTooltip
                id={`${res?.cgId}`}
                title={'View PO Lines'}
                classList="action-btn-wrapper__icon"
                position={'bottom'}
            >
                <a className="icon-nav more-details-list" onClick={() => navigateToPage(res)}>
                    <svg className="svg-icon eye-icon">
                        <use xlinkHref="#moreDetailsLines"></use>
                    </svg>
                </a>
            </BSTooltip>
        ),
    } as any;

    const getFormattedItem = (key: string, obj: any, isDate?: string) => {
        if (isDate) {
            if (Array.isArray(obj[key])) {
                return obj[key].map((data: string) => {
                    if (data) {
                        const fullDate = data.split('T')[0];
                        const date = fullDate.split('-');
                        return `${date[2]}-${date[1]}-${date[0]}`;
                    }
                    return '';
                });
            } else if (obj[key]) {
                const fullDate = obj[key].split('T')[0];
                const date = fullDate.split('-');
                return `${date[2]}-${date[1]}-${date[0]}`;
            }
        }
        return obj[key];
    };

    const renderIndex = (listingIndex?: number | null) => {
        return <div className="grid-cell-data">{listingIndex}</div>;
    };

    const renderIndexHeader = (headerItem: any, dataItem: any, listingIndex?: number | null) => {
        if (headerItem.renderSpecialData) {
            return listingIndex && renderOptions[headerItem.headerName](headerItem, dataItem);
        }
        return (
            <div className="grid-cell-data">
                {listingIndex ? getFormattedItem(headerItem.key, dataItem, headerItem.isDate) : null}
            </div>
        );
    };

    const renderIndexedRowData = (dataArray: any[]) => {
        return dataArray?.map((item: any, index: number) => (
            <tr className="has-child row-expanded" key={index}>
                {renderRowData(item, index, index === 0 ? item[gridProperties[tabName].indexingKey] : null)}
            </tr>
        ));
    };

    const gridRowStyle = (res: any, key: string) => {
        for (const key in disabledFilterData) {
            if (disabledFilterData[key] === null) {
                return false;
            }
        }
        let count = 0;
        let checkboxes: any = document.getElementsByClassName('checkbox-input');
        for (let i in checkboxes) {
            if (checkboxes[i].checked) count++;
        }
        if (count === 0) {
            return false;
        }
        return (
            Object.keys(disabledFilterData).length !== 0 &&
            disabledFilterData.constructor === Object &&
            !isCheckBoxDisabled(res) &&
            columnsToDisableBy?.includes(key)
        );
    };

    const renderRowData = (res: any, index: number, listingIndex?: number | null) => {
        return gridProperties[tabName].tableHeaderList?.map((item: any, itemIndex: number) => (
            <td
                style={{ backgroundColor: gridRowStyle(res, item.key) ? '#c5e7d2' : '' }}
                className={`grid-cell ${item.bodyStyle}`}
                key={itemIndex}
            >
                {item.isIndex ? (
                    renderIndex(listingIndex)
                ) : item.isIndexHeader ? (
                    renderIndexHeader(item, res, listingIndex)
                ) : item.renderSpecialData ? (
                    renderOptions[item.headerName](item, res)
                ) : (
                    <div className="grid-cell-data">
                        {item.hasToolTip && Array.isArray(getFormattedItem(item.key, res, item.isDate))
                            ? getFormattedItem(item.key, res, item.isDate)?.length !== 0 && (
                                  <TableToolTip
                                      data={getFormattedItem(item.key, res, item.isDate)}
                                      index={index}
                                      settableRowExp={settableRowExp}
                                      rowexpand={rowexpand}
                                      rowLine={rowLine}
                                      setRowLine={setRowLine}
                                      itemId={item.key}
                                      columnExpand={columnExpand}
                                      setColumnExpand={setColumnExpand}
                                  />
                              )
                            : getFormattedItem(item.key, res, item.isDate)}
                    </div>
                )}
            </td>
        ));
    };

    return (
        <>
            <ClickAwayListener
                onClickAway={() => {
                    setselectedDropDown('');
                }}
            >
                <div
                    onScroll={() => {
                        setselectedDropDown('');
                    }}
                    onClick={() => {
                        setselectedDropDown('');
                    }}
                    className="grid-container"
                >
                    {setaddedgrid && addPoList?.length && !data?.length ? (
                        <div className="grid-no-content ">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">
                                {tabName === 'availableAos'
                                    ? 'No Allocation Orders available'
                                    : 'No Purchase Orders available'}
                                {filter && Object.keys(filter).some((x: any) => filter[x])
                                    ? ' for applied filters'
                                    : ''}
                            </p>
                            {bpNumber ? (
                                <a
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => setaddedgrid(true)}
                                    className="add-content-link"
                                >
                                    {tabName === 'availableAos'
                                        ? `Click the link to view added AO's`
                                        : `Click the link to view added PO's.`}
                                </a>
                            ) : null}
                        </div>
                    ) : null}
                    {!data?.length && !addPoList?.length ? (
                        <div className="grid-no-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">
                                {filter && Object.keys(filter).some((x: any) => filter[x])
                                    ? gridProperties[tabName].noDataForFilter || gridProperties[tabName].noDataMessage
                                    : gridProperties[tabName].noDataMessage}
                            </p>
                        </div>
                    ) : null}
                    <div className="grid-scroll-content">
                        <table className="grid-table">
                            <thead>
                                <tr className="grid-sticky-row">
                                    {gridProperties[tabName].tableHeaderList.map((header: any, index: number) => (
                                        <th id={header.key} className={`${header.style}`} key={index}>
                                            {header.headerName === 'CHECKBOX' ? (
                                                renderOptions[header.headerName](header, null)
                                            ) : (
                                                <div className="grid-cell-data">
                                                    <span className="header-title">
                                                        {header.headerName}
                                                        {header.isMandatory ? (
                                                            <span className="mandatory-field-text">*</span>
                                                        ) : null}
                                                    </span>
                                                    {header.sort && onSort ? (
                                                        <button
                                                            onClick={() => {
                                                                onSort(header.key);
                                                            }}
                                                            className="sort-btn app-btn"
                                                        >
                                                            <svg
                                                                className={
                                                                    columnStatus === header.key
                                                                        ? `svg-icon sort-icon ${
                                                                              currentOrder ? 'sort-asc' : 'sort-desc'
                                                                          }`
                                                                        : 'svg-icon sort-icon'
                                                                }
                                                            >
                                                                {/* class to reverse order icon:  sort-desc  */}
                                                                <use xlinkHref="#sortIcon">
                                                                    <title>sort</title>
                                                                </use>
                                                            </svg>
                                                        </button>
                                                    ) : null}
                                                    {header.filter || header.dateFilter ? (
                                                        <div
                                                            className={
                                                                selectedDropDown === header.key ||
                                                                isFiltered(header.key)
                                                                    ? 'filter-menu-wrap open'
                                                                    : 'filter-menu-wrap'
                                                            }
                                                        >
                                                            <button
                                                                className="app-btn filter-menu-button"
                                                                onClick={(e) => {
                                                                    setselectedDropDown(
                                                                        selectedDropDown === header.key
                                                                            ? ''
                                                                            : header.key
                                                                    );
                                                                    e.stopPropagation();
                                                                }}
                                                            >
                                                                <svg className="svg-icon filter-icon">
                                                                    <use xlinkHref="#filterIcon">
                                                                        <title>filter</title>
                                                                    </use>
                                                                </svg>
                                                                <span className="sr-only">header menu</span>
                                                            </button>
                                                        </div>
                                                    ) : null}
                                                </div>
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {data?.map((res: any, index: number) =>
                                    gridProperties[tabName].hasSelfGeneratingIndex ? (
                                        renderIndexedRowData(res)
                                    ) : (
                                        <tr className="has-child row-expanded" key={index}>
                                            {renderRowData(res, index)}
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                        <div
                            className="search-filter-holder"
                            style={{ position: 'fixed', top: getFilterDropDownPositionTop(), zIndex: 1000 }}
                        >
                            {selectedDropDown &&
                                setFilter &&
                                ((filterDataLists && filterDataLists[selectedDropDown]?.length) ||
                                    currentHeaderList?.find((x: any) => x.key === selectedDropDown)?.dateFilter) && (
                                    <SearchableFilter
                                        position={getFilterDropDownPosition(selectedDropDown)}
                                        dataList={filterDataLists?.[selectedDropDown]}
                                        isLoading={false}
                                        filter={filter}
                                        setFilter={setFilter}
                                        filterKey={selectedDropDown}
                                        setselectedDropDown={setselectedDropDown}
                                        headerArray={gridProperties[tabName].tableHeaderList}
                                        selectedDate={{
                                            selectedDate: selectedDates.find(
                                                (selected) => selected.key === selectedDropDown
                                            )?.value,
                                            handleDateSelection,
                                        }}
                                    />
                                )}
                        </div>
                    </div>{' '}
                    {ModalViewproduct.status && (
                        <div className="grid-section grid-added-booking product-details-wrapper">
                            <div className="grid-holder">
                                <div className="grid-container">
                                    <ProductDetailModal
                                        display={ModalViewproduct}
                                        displayFunction={setModalViewproduct}
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </ClickAwayListener>
        </>
    );
};

export default SelectableGrid;
