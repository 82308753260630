import { createSlice } from '@reduxjs/toolkit';
import { clientTableHeaders } from '../../utils/constants';

export const dataSlice = createSlice({
    name: 'clients',
    initialState: {
        clients: [],
        allClients: [],
        headerTab: [],
        isLoading: true,
        currentPage: 0,
        columnStatus: clientTableHeaders.status,
        perPageCount: 10,
        totalPages: 2,
        totalItems: 10,
        filterValues: {},
        itemsInList: 10,
        selectedFilter: null,
    },
    reducers: {
        setDefault: (state, _action) => {
            state.currentPage = 0;
            state.perPageCount = 10;
        },
        fetchClientData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;

            state.isLoading = false;
        },
        saveClientData: (state, action) => {
            state.isLoading = true;
            state.clients = action.payload.clients?.clientResponseLists;
            state.totalPages = action.payload.clients?.totalNoOfPages;
            state.totalItems = action.payload.clients?.totalNoOfItems;
            state.filterValues = action.payload.clients?.distinctColumnValues;
            const num = action.payload.clients?.clientResponseLists?.reduce(
                (prev: number, current: any) => prev + current.childClients?.length,
                0
            );
            state.itemsInList = action.payload.clients?.clientResponseLists?.length + (num || 0);
            state.isLoading = false;
        },
        fetchHeaderData: (state, action) => {
            state.headerTab = action.payload.headerTab?.info;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        getAllClientDataList: (state, action) => {
            state.allClients = action.payload;
        },
        searchClientData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.clients = action.payload.clients?.content;
            state.totalPages = action.payload.clients?.totalPages;
            state.totalItems = action.payload.clients?.totalElements;
            state.filterValues = action.payload.clients?.distinctColumnValues;
            const num = action.payload.clients?.content?.reduce(
                (prev: number, current: any) => prev + current.childClients?.length,
                0
            );
            state.itemsInList = action.payload.clients?.content?.length + (num || 0);
            state.isLoading = false;
        },
        setselectedFilter: (state, action) => {
            state.selectedFilter = action.payload;
        },
        setCurrentPage: (state, action) => {
            state.currentPage = action.payload;
        },
    },
});

export const {
    fetchClientData,
    fetchHeaderData,
    setPageCount,
    saveClientData,
    isLoadingFn,
    setDefault,
    searchClientData,
    setselectedFilter,
    setCurrentPage,
    getAllClientDataList,
} = dataSlice.actions;
export default dataSlice.reducer;
