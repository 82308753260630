import { post } from '../../HttpMethods';

type PayloadProps = {
    profile: string;
    keyword: string;
    userName: string;
    isForwarder?: boolean;
};

export const dashboradSearchOrdListApi = async (payload: PayloadProps) => {
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

    try {
        const response = await post(
            `api/ord/db/search?profile=${payload.profile}&keyword=${payload.keyword}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboradSearchMgtListApi = async (payload: PayloadProps) => {
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

    try {
        const response = await post(
            `api/mgt/dashboard/search?profile=${payload.profile}&keyword=${payload.keyword}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};

export const dashboradSearchBkgListApi = async (payload: PayloadProps) => {
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    try {
        const response = await post(
            `api/bkg/db/search?profile=${payload.profile}&keyword=${payload.keyword}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return { data: { success: true, data: response } };
    } catch (error: any) {
        return { data: { success: false, data: error.message } };
    }
};
