import { post } from '../../HttpMethods';
import fileDownload from 'js-file-download';

export interface BpData {
    bpNumber: string;
    bpStatus: string;
    poNumber: string[];
    clientName: string;
    vendorName: string;
    originPort: string;
    destinationPort: string;
    incoTerm: string;
    goodsReadyDate: string[];
    eta: string;
    etd: string;
    vesselVoyage: string;
}

export interface BpResponse {
    bpListingDtos: BpData[];
    totalPages: number;
    totalElements: number;
}

export const getBpData = async (payload: {
    currentPage: number;
    perPageCount: number;
    columnStatus: string;
    columnOrder: boolean;
    data: any;
    profileType: string;
}) => {
    try {
        const res = await post(
            `api/bkg/bp/list/${payload.profileType}?size=${payload.perPageCount}&page=${
                payload.currentPage
            }&sortColumn=${payload.columnStatus}&sortOrder=${payload.columnOrder ? 'DESC' : 'ASC'}`,
            payload.data,
            null
        );
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getFilterValuesForBp = async (payload: { data: any; profileType: string }) => {
    try {
        const res = await post(`api/bkg/bp/distinct/${payload.profileType}`, payload.data, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const getBpStatusCount = async (payload: { data: any; profileType: string }) => {
    try {
        const body = JSON.parse(JSON.stringify(payload.data));
        if (body?.bpStatus) {
            delete body?.bpStatus;
        }
        const res = await post(`api/bkg/bp/statuscount/${payload.profileType}`, body, null);
        return res.info;
    } catch (err) {
        return console.error(err);
    }
};

export const exportBpList = async (type: string, organisationData: any, profileType: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        const sortColumn = organisationData?.sortColumn;
        const sortOrder = organisationData?.sortOrder;
        myHeaders.append('Authorization', `Bearer ${token}`);
        myHeaders.append('Content-Type', 'application/json');
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/bkg/bp/export/${profileType}?type=${type}&sortColumn=${sortColumn}&sortOrder=${sortOrder}`,
            {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(organisationData),
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                const fileName = type === 'excel' ? 'AoBookingProposals.xlsx' : 'AoBookingProposals.pdf';
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
    } catch (err) {
        return console.error(err);
    }
};
