import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { get, post, del } from '../HttpMethods';
import fileDownload from 'js-file-download';

export const getDocumentTypes = async (payload?: { searchTerm: string }) => {
    try {
        const url = payload?.searchTerm
            ? `cix/scr/admin/config/DOC_TYP/lst/CD_Key/CD_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000&filter%5Bfilters%5D%5B2%5D%5Bfield%5D=CD_UserDefinedText4&filter%5Bfilters%5D%5B2%5D%5Boperator%5D=contains&filter%5Bfilters%5D%5B2%5D%5Bvalue%5D=${payload?.searchTerm}`
            : `cix/scr/admin/config/DOC_TYP/lst/CD_Key/CD_UserDefinedText4?take=1000&skip=0&page=1&pageSize=1000`;
        const res = await get(url, null, externalBaseUrl);
        return res.data;
    } catch (err) {
        return console.error(err);
    }
};

// uploadDocument
export const uploadDocument = async (payload: { data: string }) => {
    try {
        const res = await post(`cix/scr/documents/`, payload.data, null, undefined, externalBaseUrl);
        return res.data;
    } catch (err: any) {
        return (
            err?.response?.data?.data || [
                {
                    error: {
                        status: 400,
                    },
                    stack: 'Error : File cannot be uploaded as it is not a permitted file type',
                },
            ]
        );
    }
};

// uploadRateDocument
export const uploadRateDocument = async (payload: { data: string }) => {
    try {
        const res = await post(`cix/scr/documents/`, payload.data, null, undefined, externalBaseUrl);
        return res.data;
    } catch (err: any) {
        return (
            err?.response?.data?.data || [
                {
                    error: {
                        status: 400,
                    },
                    stack: 'Error : File cannot be uploaded as it is not a permitted file type',
                },
            ]
        );
    }
};

//deleteDocument
export const deleteDocument = async (payload: { doPk: string; reason: string }) => {
    try {
        const res = await del(`cix/scr/documents/${payload.doPk}?reason=${payload.reason}`, null, externalBaseUrl);
        return res.data;
    } catch (err) {
        return console.error(err);
    }
};

export const cixDownloadFile = async (doPk: string, filename: string) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(`${process.env.REACT_APP_EXT_BASE_URL}/cix/scr/documents/${doPk}`, {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        })
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, filename);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const fileEventHistory = async (id: string) => {
    try {
        const response = await get(
            `/cix/scr/lifecycle/events?take=99&filter[logic]=and&filter[filters][1][field]=EV_FK&filter[filters][1][operator]=eq&filter[filters][1][value]=${id}&attributes=EV_CD_Code,EV_Context,EV_CreatedDateTime,CD_Value,US_UserName`,
            null,
            externalBaseUrl
        );
        return response?.data;
    } catch (error: any) {
        return error;
    }
};
