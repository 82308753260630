import React, { useEffect, useState } from 'react';
import './po-review.scss';
import '../po-tab.scss';
import './purchase-basic-detail.scss';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchBasicDetails, savedPo, submitPo } from '../../../store/reducers/purchase_order/basicDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/reducers';
import moment from 'moment';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from '../../../ShowForPermissions';
import { selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import { useAppSelector } from '../../../hooks';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { basicDetails } = useSelector((state: RootState) => state.purchaseOrder);
    const headerData = useAppSelector(selectHeader);
    const [flagNew, setflagNew] = useState(true);

    useEffect(() => {
        dispatch(fetchBasicDetails(id));
    }, []);

    const showCreate = () => {
        var flag = false;
        if (
            val.promRefNo ||
            val.pickGroup ||
            val.brandName ||
            val.currency ||
            val.buyerUserName ||
            val.impVendorCode ||
            val.impShipmentRefNo ||
            val.distribOrderNo
        ) {
            flag = true;
        }
        return flag;
    };

    const skipPOLine = headerData?.isHeaderOnlyPo;

    /* eslint no-var: off */
    const checkDisabled = () => {
        let flag = true;
        if (val?.agentName !== null && val?.orderDate !== null) {
            flag = false;
        }
        if (skipPOLine && headerData?.isMultiAddress && headerData?.destinations?.length < 2) {
            flag = true;
        }
        if (!skipPOLine && val?.purchaseOrderProducts?.length === 0) {
            flag = true;
        }
        return flag;
    };

    const val = basicDetails as any;

    useEffect(() => {
        let flag = false;
        if (!skipPOLine && headerData?.isMultiAddress) {
            val?.purchaseOrderProducts?.forEach((element: any) => {
                if (val?.isMultiAddress && element.destinationAddress === null) {
                    flag = true;
                }
            });
        }

        setflagNew(flag);
    }, [val]);

    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Intermodal PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/purchase_order' : '/purchase_order';

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={`${poName}`}
                    firstUrl={`${poUrl}`}
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New PO' : 'PO Details'}
                    secondUrl={`/purchase_order/${id}/po_review`}
                />
                <PurchaseOrderHeader id={id}></PurchaseOrderHeader>
                <PODetailsHeaderTabs />
            </div>
            <div className="content-section main-wrapper preview-detail-wrapper purchase-basic-detail-wrapper">
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Required Details</h6>
                            <ShowForPermissions permission="edit" type="po">
                                {val?.poStatus !== 'Cancelled' &&
                                    val?.poStatus !== 'In_Booking_Proposal' &&
                                    val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    val?.poStatus !== 'Booked' && (
                                        <button
                                            onClick={() => navigate(`/purchase_order/${id}/basic_details`)}
                                            className="action-btn app-btn app-btn-secondary"
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon" style={{ width: '100%' }}>
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Type<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.ordertype ? val?.ordertype?.orderTypeName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Shipping Mode<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.shippingmode ? val?.shippingmode?.shippingModeName : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Origin<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.originPort ? `${val?.originPortName}(${val?.originPort})` : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationPort
                                    ? `${val?.destinationPortName}(${val?.destinationPort})`
                                    : '-'}
                            </div>
                        </div>
                        {val.isTriangleShipment && val?.isMultiAddress && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Includes AU Destination<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content" style={{ width: '80%' }}>
                                    {val.includesAUPortDestination ? 'Yes' : 'No'}
                                </div>
                            </div>
                        )}
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Destination Address
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content" style={{ width: '90%' }}>
                                {val?.isMultiAddress && val?.ordertype
                                    ? 'Multi Destination'
                                    : val?.destinationAddress
                                    ? val.destinationAddress
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Service Type
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {val?.fclservicetype ? val?.fclservicetype?.fclServiceTypeName : '-'}
                            </div>
                        </div>
                        {/* {!!(val?.shippingmode?.shippingModeCode !== 'AIR') && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Freight Term<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">
                                    {val?.freightterms ? val?.freightterms?.freightTermsCode : '-'}
                                </div>
                            </div>
                        )} */}
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Incoterm
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {val?.incoTerm ? val?.incoTerm?.incoTermName : '-'}
                            </div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Release Type
                                {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                            </h6>
                            <div className="details-box-content">
                                {val?.releaseType?.releaseTypeName ? val?.releaseType?.releaseTypeName : '-'}
                            </div>
                        </div>

                        {/* Release type field for air freight shipping mode */}
                        {!(val?.shippingmode?.shippingModeCode === 'AIR') && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Payment Term
                                    {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}{' '}
                                </h6>
                                <div className="details-box-content">
                                    {val?.paymentterms ? val?.paymentterms?.paymentTermsName : '-'}
                                </div>
                            </div>
                        )}

                        {val?.agentName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Agent<span className="mandatory-field-text">*</span>
                                </h6>
                                <div className="details-box-content">{val?.agentName ? val?.agentName : '-'}</div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Additional Details</h6>
                            <ShowForPermissions permission="edit" type="po">
                                {val?.poStatus !== 'Cancelled' &&
                                    val?.poStatus !== 'In_Booking_Proposal' &&
                                    val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    val?.poStatus !== 'Booked' && (
                                        <button
                                            onClick={() => navigate(`/purchase_order/${id}/basic_details`)}
                                            className="action-btn app-btn app-btn-secondary"
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>
                    {!showCreate() && (
                        <div className="no-data-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No additional details added</p>
                        </div>
                    )}
                    <div className="card-detail-col">
                        {val?.promRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Promotional Ref No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.promRefNo ? val?.promRefNo : '-'}</div>
                            </div>
                        )}

                        {val?.pickGroup && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Pick Group
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.pickGroup ? val?.pickGroup : '-'}</div>
                            </div>
                        )}
                        {val?.brandName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Brand Name
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">{val?.brandName ? val?.brandName : '-'}</div>
                            </div>
                        )}
                        {val?.currency && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Order Currency
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.currency?.currencycode ? val?.currency?.currencycode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.buyerUserName && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Buyer
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.buyerUserName ? val?.buyerUserName : '-'}
                                </div>
                            </div>
                        )}
                        {val?.impVendorCode && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Vendor Code
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impVendorCode ? val?.impVendorCode : '-'}
                                </div>
                            </div>
                        )}
                        {val?.impShipmentRefNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Importer’s Shipment Ref No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.impShipmentRefNo ? val?.impShipmentRefNo : '-'}
                                </div>
                            </div>
                        )}
                        {val?.distribOrderNo && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">
                                    Distribution Order No.
                                    {/*<span className="mandatory-field-text">*</span>*/}
                                </h6>
                                <div className="details-box-content">
                                    {val?.distribOrderNo ? val?.distribOrderNo : '-'}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>
                            <ShowForPermissions permission="edit" type="po">
                                {val?.poStatus !== 'Cancelled' &&
                                    val?.poStatus !== 'In_Booking_Proposal' &&
                                    val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    val?.poStatus !== 'Booked' && (
                                        <button
                                            onClick={() => navigate(`/purchase_order/${id}/dates`)}
                                            className="action-btn app-btn app-btn-secondary"
                                            title="Edit Details"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                    </div>
                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Order Date<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.orderDate ? moment(val?.orderDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Start Shipping Window<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.startShipWindowDate ? moment(val?.startShipWindowDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                End Shipping Window<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">
                                {val?.endShipWindowDate ? moment(val?.endShipWindowDate).format('DD-MM-YYYY') : '-'}
                            </div>
                        </div>
                        {val && !val.isMultiAddress && (
                            <>
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">
                                        Goods Ready Date<span className="mandatory-field-text">*</span>
                                    </h6>
                                    <div className="details-box-content">
                                        {val.goodsReadyDate ? moment(val.goodsReadyDate).format('DD-MM-YYYY') : '-'}
                                    </div>
                                </div>
                                <div className="details-col box-content-holder">
                                    <h6 className="details-box-heading">
                                        Projected ETA into Warehouse
                                        {!val.isTriangleShipment && <span className="mandatory-field-text">*</span>}
                                    </h6>
                                    <div className="details-box-content">
                                        {val.projectedEtaDate ? moment(val.projectedEtaDate).format('DD-MM-YYYY') : '-'}
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {!skipPOLine ? (
                    <div className="card grid-section view-entry-field-grid modal-grid preview-grid purchase-basic-detail-wrapper">
                        <div className="grid-holder">
                            <div className="grid-header">
                                <div className="content-heading-holder card-head-btn-edit-holder">
                                    <span className="content-heading">Product Details</span>
                                    <ShowForPermissions permission="edit" type="po">
                                        {val?.poStatus !== 'Cancelled' &&
                                            val?.poStatus !== 'In_Booking_Proposal' &&
                                            val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                            val?.poStatus !== 'Booked' && (
                                                <button
                                                    onClick={() => navigate(`/purchase_order/${id}/product_details`)}
                                                    className="action-btn app-btn app-btn-secondary"
                                                    title="Edit Details"
                                                >
                                                    <svg className="svg-icon edit-icon">
                                                        <use xlinkHref="#editIcon"></use>
                                                    </svg>
                                                </button>
                                            )}
                                    </ShowForPermissions>
                                </div>
                            </div>
                            <div className="grid-container">
                                <div className="grid-scroll-content">
                                    <table className="grid-table">
                                        <thead>
                                            <tr className="grid-sticky-row">
                                                <th className="grid-cell-header col-po-line">
                                                    <div className="grid-cell-data">
                                                        <span className="header-title">PO LINE NO.</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header  col-sku">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">sku</span>
                                                    </div>
                                                </th>

                                                <th className="grid-cell-header  col-description">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">DESCRIPTION</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header  col-country-origin">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">ORIGIN</span>
                                                    </div>
                                                </th>
                                                {val?.isMultiAddress && (
                                                    <>
                                                        <th className="grid-cell-header col-destination-port">
                                                            <div className=" grid-cell-data">
                                                                <span className="header-title">DESTINATION</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-destination-address">
                                                            <div className=" grid-cell-data">
                                                                <span className="header-title">
                                                                    DESTINATION ADDRESS
                                                                </span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-goods-ready-date">
                                                            <div className=" grid-cell-data">
                                                                <span className="header-title">GOODS READY DATE</span>
                                                            </div>
                                                        </th>
                                                        <th className="grid-cell-header col-whouse">
                                                            <div className=" grid-cell-data">
                                                                <span className="header-title">
                                                                    PROJ. ETA INTO WHOUSE
                                                                </span>
                                                            </div>
                                                        </th>
                                                    </>
                                                )}
                                                <th className="grid-cell-header col-quantity">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">QUANTITY</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-cbm">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">CBM</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-currency">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">CURRENCY</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-unit">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">UNIT COST</span>
                                                    </div>
                                                </th>
                                                <th className="grid-cell-header col-net">
                                                    <div className=" grid-cell-data">
                                                        <span className="header-title">NET COST</span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>

                                        <tbody>
                                            {val?.purchaseOrderProducts &&
                                                val?.purchaseOrderProducts.map((res: any, i: any) => (
                                                    <tr className="has-child row-expanded">
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{i + 1}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.sku}</div>
                                                        </td>

                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.description}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.countryName}</div>
                                                        </td>
                                                        {val?.isMultiAddress && (
                                                            <>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res?.destinationPort &&
                                                                            `${res?.destinationPortName}(${res?.destinationPort})`}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res?.destinationAddress}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res?.goodsReadyDate &&
                                                                            moment(res?.goodsReadyDate).format(
                                                                                'DD-MM-YYYY'
                                                                            )}
                                                                    </div>
                                                                </td>
                                                                <td className="grid-cell">
                                                                    <div className="grid-cell-data">
                                                                        {res?.projWhouseETA &&
                                                                            moment(res?.projWhouseETA).format(
                                                                                'DD-MM-YYYY'
                                                                            )}
                                                                    </div>
                                                                </td>
                                                            </>
                                                        )}
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.qty}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.cbm}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">
                                                                {res?.currency?.currencycode}
                                                            </div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.unitCost}</div>
                                                        </td>
                                                        <td className="grid-cell">
                                                            <div className="grid-cell-data">{res?.netCost}</div>
                                                        </td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="card-head-btn-edit-holder -with-caption">
                                    <h6 className="details-box-heading-2">Product Details</h6>
                                    {headerData.isMultiAddress && (
                                        <p className="details-para">
                                            This PO has been marked as Header Only with no PO lines included
                                        </p>
                                    )}
                                </div>
                            </div>
                            <div className="card-detail-col new-div-card-details">
                                <div className="grid-destination-card">
                                    {!headerData.isMultiAddress ? (
                                        <div className="card-align-center">
                                            <div className="single-po-no-data-holder single-po-data-holder">
                                                <svg className="single-po-no-icon">
                                                    <use xlinkHref="#headerOnlySinglePo"></use>
                                                </svg>
                                                <h2 className="single-po-no-data-heading">Header Only</h2>
                                                <div className="single-po-no-data-message no-content-message">
                                                    This PO has been marked as Header Only with no PO lines included
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <>
                                            <h6 className="details-box-heading-2">Destinations</h6>
                                            <div className="modal-input-layout-item">
                                                {/* Search suggestions capsule */}
                                                <div className="suggestion-container">
                                                    {headerData?.destinations?.map((d: any) => (
                                                        <div className="content-card" key={d}>
                                                            <span className="main-content">{d}</span>
                                                        </div>
                                                    ))}

                                                    {/* <div className="content-card">
                                            <span className="main-content">Longfield (USLON)</span>
                                        </div> */}
                                                </div>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    {/* <ShowForPermissions permission="edit" type="po">
                        {val?.poStatus?.toLowerCase() !== 'awaiting_enrichment' &&
                            val?.poStatus?.toLowerCase() !== 'cancelled' &&
                            val?.poStatus !== 'In_Booking_Proposal' &&
                            val?.poStatus !== 'Booked' && (
                                <Link to="/purchase_order" style={{ textDecoration: 'none' }}>
                                    <button
                                        className="app-btn app-btn-secondary grid-top-btn footer-btn"
                                        title="Save as Draft"
                                    >
                                        <span className="button-text vendor-button-text">Save as Draft</span>
                                    </button>
                                </Link>
                            )}
                    </ShowForPermissions> */}
                    <button
                        className="app-btn app-btn-secondary footer-btn"
                        title="previous"
                        onClick={() => navigate(-1)}
                    >
                        <span className="button-text vendor-button-text">Previous</span>
                    </button>

                    <ShowForPermissions permission="edit" type="po">
                        {val?.poStatus &&
                            val?.poStatus?.toLowerCase() !== 'awaiting_enrichment' &&
                            val?.poStatus?.toLowerCase() !== 'cancelled' &&
                            val?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                            val?.poStatus !== 'In_Booking_Proposal' &&
                            val?.poStatus !== 'Booked' &&
                            !val?.poStatus.toLowerCase().includes('ready') && (
                                <button
                                    disabled={checkDisabled() || flagNew}
                                    className="app-btn app-btn-primary footer-btn"
                                    title="confirm and submit"
                                    onClick={() => {
                                        dispatch(savedPo(val.poNumber));
                                        if (!checkDisabled()) {
                                            if (!flagNew) {
                                                dispatch(submitPo(id));
                                                navigate(`${poUrl}`);
                                            }
                                        }
                                    }}
                                >
                                    <span className="button-text footer-button-text">Confirm and Submit PO</span>
                                </button>
                            )}
                    </ShowForPermissions>
                </div>
            </div>
        </div>
    );
};
export default Index;
