import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import { RootState } from '../../store/reducers';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { vesselListAirApi } from 'src/services/apis/bookingProposalAir/addSchedule';
import { InputActionMeta } from 'react-select';
import { getVesselList } from 'src/store/reducers/bookingProposalAir/addSchedule';
import { debounce } from 'lodash';

interface vesselDropdownPropsType {
    portDetails: {
        portName: string;
        portCode: string;
        vessel: string;
        etd: string;
        eta: string;
        service: string;
        voyage: string;
    }[];
    index: number;
    setPortDetails: {
        (option: any, index: number, fieldName: string): void;
    };
    isErrorMessageShow: boolean;
    type?: string;
    vesselData?: any;
    setVesselData?: any;
}

const Index: React.FC<vesselDropdownPropsType> = (props) => {
    const { vesselList } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const dispatch = useDispatch();

    const [vesselName, setVesselName] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const convertDataForSelect = (data: any) => {
        if (Array.isArray(vesselList)) {
            const convertedData = data?.map((option: any) => {
                return { value: option.imo, label: option.name };
            });
            return convertedData;
        } else {
            return [];
        }
    };

    const currentEnv = () => {
        if (process.env.REACT_APP_BASE_URL?.includes('test')) {
            return 'TEST';
        } else if (process.env.REACT_APP_BASE_URL?.includes('dev')) {
            return 'DEV';
        } else if (process.env.REACT_APP_BASE_URL?.includes('qa')) {
            return 'QA';
        } else {
            return 'PROD';
        }
    };

    const noMessageComponent = () => {
        return (
            <div>
                <br />
                <br />
                <p className="no-content-message">Vessel Not Found?</p>
                <br />
                <p className="no-content-message">Try search and update the vessel list</p>
                {/* <br />
                <div className="button-holder">
                    <>
                        <button
                            disabled={isLoading}
                            type="submit"
                            title="Done"
                            id="basicForm"
                            className="app-btn app-btn-primary"
                            onClick={async () => {
                                if (vesselName) {
                                    try {
                                        const myHeaders = new Headers();
                                        const token = localStorage.getItem('token');
                                        myHeaders.append('Authorization', `Bearer ${token}`);
                                        myHeaders.append('Content-Type', 'application/json');
                                        myHeaders.append('env', currentEnv());
                                        if (token) {
                                            setIsLoading(true);
                                            try {
                                                const res = await fetch(
                                                    `${process.env.REACT_APP_TRACKING_BASE_URL}/OrderTracking/getVesselByName?name=${vesselName}`,
                                                    {
                                                        method: 'GET',
                                                        headers: myHeaders,
                                                        redirect: 'follow',
                                                    }
                                                );

                                                setIsLoading(false);
                                                if (res.status !== 200) {
                                                    const body = await res.json();
                                                    error(
                                                        body?.Error || 'Vessel Cannot be found (Error 400)',
                                                        Position.TOP_RIGHT
                                                    );
                                                } else {
                                                    success(`Vessel has been added`, Position.TOP_RIGHT);
                                                    dispatch(getVesselList({}));
                                                }
                                            } catch (e) {
                                                setIsLoading(false);
                                                error(`Vessel Cannot be found (Error 500)`, Position.TOP_RIGHT);
                                            }
                                        }
                                    } catch (error) {
                                        setIsLoading(false);
                                    }
                                }
                            }}
                        >
                            {isLoading ? (
                                <span className="button-text">Searching...</span>
                            ) : (
                                <span className="button-text">Search and Update</span>
                            )}
                        </button>
                    </>
                </div> */}
            </div>
        );
    };

    const getUpdatedVessel = debounce(async (name?: string) => {
        vesselListAirApi({ vesselName: name ? name : '' })
            ?.then((res: any) => props?.setVesselData(res))
            ?.catch((err) => console.log(err));
    }, 500);
    let prevValue = '';

    return (
        <div className=" modal-input-holder layout-item">
            <label className="modal-input-label">Select Vessel</label>
            <SearchableSelectBox
                data={convertDataForSelect(props?.type === 'manual' ? props?.vesselData : vesselList)}
                placeholder="Enter atleast 3 characters to search"
                index={props.index}
                setStateValue={props.setPortDetails}
                stateValue={props.portDetails[props.index].vessel}
                fieldName="vessel"
                NoMessageComponent={noMessageComponent}
                menuPortalTarget={document.getElementById('outside_div')}
                onInputChangeFn={(value: string, actionMeta: InputActionMeta) => {
                    prevValue = prevValue === 'set-value' ? '' : actionMeta.action;
                    if (value?.length > 2) {
                        props?.type === 'manual'
                            ? getUpdatedVessel(value)
                            : dispatch(getVesselList({ vesselName: value }));
                    } else if (actionMeta.action === 'menu-close' && actionMeta.prevInputValue !== '' && prevValue) {
                        props?.type === 'manual' ? getUpdatedVessel() : dispatch(getVesselList({}));
                    }
                }}
                onClear={() => {
                    props?.type === 'manual' ? getUpdatedVessel() : dispatch(getVesselList({}));
                }}
            />
            {props.isErrorMessageShow && (
                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                    Vessel is required
                </p>
            )}
        </div>
    );
};

export default Index;
