import React, { useEffect, useState } from 'react';
import AddDatesModal from './AddDatesModal';
import './purchase-dates.scss';
import PurchaseOrderHeader from '../PurchaseOrderHeader';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import {
    selectDatesPo,
    fetchDatesPoList,
    selectDatesPoLoading,
} from '../../../store/reducers/purchaseOrder/datesPoReducer';
import moment from 'moment';
import Breadcrumbs from '../../../components/Breadcrumbs/purchaseOrder';
import ShowForPermissions from '../../../ShowForPermissions';
import CancelModal from '../../../components/common/CancelModal';
import { selectHeader } from '../../../store/reducers/purchaseOrder/addPoReducer';
import { useUserProfile } from '../../../hooks/useUserProfile';
// import { selectProductPo } from 'src/store/reducers/purchaseOrder/productPoReducer';
import {
    fetchProductDetailsConfirmationList,
    // selectProductDetailsConfirmation,
} from 'src/store/reducers/purchaseOrder/productDetailsConfirmationReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import { fetchBasicDetails } from 'src/store/reducers/purchase_order/basicDetails';
import ShowMandatoryFields from 'src/components/common/ShowMandatoryFields';
import { InterfaceType } from 'src/utils/constants';
import PODetailsHeaderTabs from 'src/pages/PO/PODetailsHeaderTabs';
import PODetailsNextButton from '../PODetailsNextButton';

const Index: React.FC = () => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [modalView, setModalView] = useState(false);
    const [_flagNew, setflagNew] = useState(true);
    const [displayCancel, setDisplayCancel] = useState(false);
    const datesPoData = useAppSelector(selectDatesPo);
    const isLoading = useAppSelector(selectDatesPoLoading);
    const headerData = useAppSelector(selectHeader);
    const multiDestination = datesPoData?.data?.info?.isMultiAddress ? true : false;
    // const products = useAppSelector(selectProductPo);
    // const productDetailsConfirmation = useAppSelector(selectProductDetailsConfirmation);
    const { profileType } = useUserProfile();
    const { basicDetails } = useSelector((state: RootState) => state.purchaseOrder);
    const [viaIntegration, setViaIntegration] = useState(false);
    useEffect(() => {
        dispatch(
            fetchDatesPoList({
                id,
            })
        );
        // dispatch(
        //     fetchProductDetailsConfirmationList({
        //         poId: id,
        //     })
        // );
        // dispatch(fetchBasicDetails(id));
    }, []);

    useEffect(() => {
        if (headerData) {
            setViaIntegration(headerData?.createdUser === InterfaceType.InterfacedPO);
        }
    }, [headerData]);

    const isTriangleShipment = datesPoData?.data?.info?.isTriangleShipment;
    // const skipPOLine = headerData?.isHeaderOnlyPo;

    // const condition =
    //     !datesPoData?.data?.info?.orderDate ||
    //     !datesPoData?.data?.info?.startShipWindowDate ||
    //     !datesPoData?.data?.info?.endShipWindowDate ||
    //     (!multiDestination && !datesPoData?.data?.info?.goodsReadyDate) ||
    //     (!multiDestination && !datesPoData?.data?.info?.projectedEtaDate);

    const showMandatoryFieldsIcon = () => {
        if (!viaIntegration) {
            return false;
        }
        return headerData?.datesTabComplete ? false : true;
    };

    // const nextButtonStatus = () => {
    //     if (!viaIntegration) {
    //         return !datesPoData?.data?.info?.orderDate;
    //     }
    //     return condition ? true : false;
    // };

    // function checkPoReviewEnabled() {
    //     if (!datesPoData?.data?.info?.orderDate) {
    //         return 'disabled';
    //     }

    //     if (!skipPOLine && (products as any)?.length < 1) {
    //         return 'disabled';
    //     }
    //     if (skipPOLine && headerData?.destinations?.length < 2) {
    //         return 'disabled';
    //     }
    //     if (headerData?.destinations[0]?.includes('null')) {
    //         return 'disabled';
    //     }
    //     return !nextButtonStatus() ? '' : 'disabled';
    // }

    const val = basicDetails as any;

    // const checkDisabled = () => {
    //     let flag = true;
    //     if (val?.agentName !== null && val?.orderDate !== null) {
    //         flag = false;
    //     }
    //     if (skipPOLine && headerData?.destinations?.length < 2) {
    //         flag = true;
    //     }
    //     if (!skipPOLine && val?.purchaseOrderProducts?.length === 0) {
    //         flag = true;
    //     }
    //     return flag;
    // };

    useEffect(() => {
        let flag = false;
        val?.purchaseOrderProducts?.forEach((element: any) => {
            if (val?.isMultiAddress && element.destinationAddress === null) {
                flag = true;
            }
        });
        setflagNew(flag);
    }, [val]);

    // const destines = headerData?.destinations?.map((d: any) => ({
    //     label: d,
    //     value: d,
    // }));
    const poName = headerData?.shippingMode?.shippingModeCode === 'LND' ? 'Warehousing PO' : 'Intermodal PO';
    const poUrl = headerData?.shippingMode?.shippingModeCode === 'LND' ? '/warehousing_order' : '/purchase_order';
    return (
        <div className="main-wrapper container purchase-dates-wrapper">
            <div className="main-header-content-holder large-header">
                {/* <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/purchase_order">
                                Purchase Orders
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Add New PO
                            </a>
                        </li>
                    </ul>
                </div> */}
                <Breadcrumbs
                    firstName={`${poName}`}
                    firstUrl={`${poUrl}`}
                    secondName={headerData?.poStatus === 'Draft' ? 'Add New PO' : 'PO Details'}
                    secondUrl={`/purchase_order/${id}/dates`}
                />
                <PurchaseOrderHeader id={id} />
                <PODetailsHeaderTabs />
            </div>
            <div className="content-section main-wrapper agent-basic-detail-wrapper">
                {showMandatoryFieldsIcon() && (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missing. Please integrate again/ edit details to enter the mandatory fields
                            to proceed.
                        </div>
                    </div>
                )}
                {headerData?.datesTabComplete && headerData?.validDate === false && (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">Invalid dates present .Please enter valid dates to proceed</div>
                    </div>
                )}
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Dates</h6>
                            <ShowForPermissions permission="edit" type="po">
                                {datesPoData?.data?.info?.orderDate &&
                                    headerData?.poStatus !== 'Cancelled' &&
                                    headerData?.poStatus?.toLowerCase() !== 'pending_reassignment' &&
                                    headerData?.poStatus !== 'In_Booking_Proposal' &&
                                    headerData?.poStatus !== 'Booked' &&
                                    !headerData?.enriched &&
                                    profileType !== 'agent' && (
                                        <button
                                            onClick={() => {
                                                setModalView(true);
                                            }}
                                            className="action-btn app-btn app-btn-secondary "
                                            title="Edit Dates"
                                        >
                                            <svg className="svg-icon edit-icon">
                                                <use xlinkHref="#editIcon"></use>
                                            </svg>
                                        </button>
                                    )}
                            </ShowForPermissions>
                        </div>
                        <ShowForPermissions permission="edit" type="po">
                            <div className="btn-holder">
                                {!datesPoData?.data?.info?.orderDate &&
                                    headerData?.poStatus !== 'In_Booking_Proposal' &&
                                    headerData?.poStatus !== 'Booked' &&
                                    profileType !== 'agent' && (
                                        <button
                                            onClick={() => {
                                                setModalView(true);
                                            }}
                                            className="app-btn app-btn-secondary icon-button btn-bold"
                                            title="Add Dates"
                                        >
                                            <svg className="svg-icon add-btn-icon">
                                                <use xlinkHref="#plusIcon"></use>
                                            </svg>
                                            <span className="button-text">Add Dates</span>
                                        </button>
                                    )}
                            </div>
                        </ShowForPermissions>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Order Date<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!datesPoData?.data?.info?.orderDate}
                                name="Order Date"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {datesPoData?.data?.info?.orderDate
                                    ? moment(datesPoData?.data?.info?.orderDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        {/* {headerData?.poStatus !== 'Draft' && (
                            <div className="details-col box-content-holder">
                                <h6 className="details-box-heading">Created Date</h6>
                                <div className="details-box-content">
                                    {datesPoData?.data?.info?.createdDate
                                        ? moment(datesPoData?.data?.info?.createdDate).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                        )} */}
                        {!multiDestination && (
                            <div className="details-col box-content-holder">
                                {/* <h6 className="details-box-heading">
                                        Goods Ready Date<span className="mandatory-field-text">*</span>
                                    </h6> */}
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={!datesPoData?.data?.info?.goodsReadyDate}
                                    name="Goods Ready Date"
                                    viaIntegration={viaIntegration}
                                />
                                <div className="details-box-content">
                                    {datesPoData?.data?.info?.goodsReadyDate
                                        ? moment(datesPoData?.data?.info?.goodsReadyDate).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                        )}
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                Start Shipping Window<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!datesPoData?.data?.info?.startShipWindowDate}
                                name="Start Shipping Window"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {datesPoData?.data?.info?.startShipWindowDate
                                    ? moment(datesPoData?.data?.info?.startShipWindowDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        <div className="details-col box-content-holder">
                            {/* <h6 className="details-box-heading">
                                End Shipping Window<span className="mandatory-field-text">*</span>
                            </h6> */}
                            <ShowMandatoryFields
                                showMandatoryFieldsIcon={!datesPoData?.data?.info?.endShipWindowDate}
                                name="End Shipping Window"
                                viaIntegration={viaIntegration}
                            />
                            <div className="details-box-content">
                                {datesPoData?.data?.info?.endShipWindowDate
                                    ? moment(datesPoData?.data?.info?.endShipWindowDate).format('DD-MM-YYYY')
                                    : '-'}
                            </div>
                        </div>
                        {!multiDestination && (
                            <div className="details-col box-content-holder">
                                {/* <h6 className="details-box-heading">
                                        Projected ETA into Warehouse
                                        {!isTriangleShipment && <span className="mandatory-field-text">*</span>}
                                    </h6> */}
                                <ShowMandatoryFields
                                    showMandatoryFieldsIcon={
                                        !isTriangleShipment ? !datesPoData?.data?.info?.projectedEtaDate : false
                                    }
                                    name="Projected ETA into Warehouse"
                                    showAsterisk={!isTriangleShipment ? true : false}
                                    viaIntegration={viaIntegration}
                                />

                                <div className="details-box-content">
                                    {datesPoData?.data?.info?.projectedEtaDate
                                        ? moment(datesPoData?.data?.info?.projectedEtaDate).format('DD-MM-YYYY')
                                        : '-'}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        to={`/purchase_order/${id}/basic_details`}
                        className="app-btn app-btn-secondary footer-btn"
                        title="Previous"
                    >
                        <span className="button-text footer-button-text">Previous</span>
                    </Link>
                    <PODetailsNextButton />
                </div>
            </div>
            <AddDatesModal
                display={modalView}
                displayFunction={setModalView}
                data={datesPoData?.data?.info}
                multiDestination={multiDestination}
                setDisplayCancel={setDisplayCancel}
            />
            <CancelModal
                displayFunction={setModalView}
                mode="edit"
                header="Dates"
                cancelModalVisibility={displayCancel}
                setCancelModalVisibility={setDisplayCancel}
            />
        </div>
    );
};
export default Index;
