import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchReassignBpList,
    fetchReassignBpListSuccess,
    fetchDistinctBpList,
    fetchDistinctBpListSuccess,
    fetchAgentList,
    fetchAgentListSuccess,
    updateAgentBp,
    updateAgentBpSuccess,
    updateAgentBpFailure,
} from '../../reducers/reassignAgent/bookingProposalReducer';
import {
    fetchReassignBpListDetailsApi,
    fetchDistinctBpListDetailsApi,
    updateAgentBpDetailsApi,
} from '../../../services/apis/reassignAgent/bookingProposalApi';
import { fetchAgentListDetailsApi } from 'src/services/apis/reassignAgent/purchaseOrderApi';

function* getReassignBpDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchReassignBpListDetailsApi, payload);
        yield put({ type: fetchReassignBpListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignBpListFailed, payload: error });
    }
}
function* getDistinctBpDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchDistinctBpListDetailsApi, payload);
        yield put({ type: fetchDistinctBpListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignBpListFailed, payload: error });
    }
}

function* getAgentDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchAgentListDetailsApi, payload);
        yield put({ type: fetchAgentListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchReassignBpListFailed, payload: error });
    }
}

function* setAgent({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(updateAgentBpDetailsApi, payload);
        yield put({ type: updateAgentBpSuccess, payload: response });
    } catch (error) {
        yield put({ type: updateAgentBpFailure, payload: error });
    }
}

function* reassignBpSaga() {
    yield takeEvery(fetchReassignBpList, getReassignBpDetails);
    yield takeEvery(fetchDistinctBpList, getDistinctBpDetails);
    yield takeEvery(fetchAgentList, getAgentDetails);
    yield takeEvery(updateAgentBp, setAgent);
}

export default reassignBpSaga;
