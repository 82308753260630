import React, { useState, useEffect } from 'react';
import './shipping-basic-detail.scss';
import '../../../Profiles/Vendors/vendor-tab.scss';
import ProfileInformationModal from './ProfileInformationModal';
import '../shipping-tab.scss';
import { Link, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    fetchShippingLineBasicDetailsList,
    selectShippingLineBasicDetailsLoading,
    selectShippingLineBasicDetails,
    ShippingLineAddressListDataProp,
    ShippingLineOrganisationContactListDataProp,
    fetchForwarderRankList,
    selectForwarderRank,
} from '../../../../store/reducers/profiles/shippingLine/basicDetailsReducer';
import ToolTip from '../../../../components/ToolTip';
import ToolTipContentBox from '../../../../components/ToolTipBoxContent';
import ShowForPermissions from '../../../../ShowForPermissions';
import HeaderTabs from '../HeaderTabs';
import BSTooltip from 'src/components/common/BSTooltip';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

const Index: React.FC = () => {
    const { id } = useParams();
    // const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const basicDetails = useAppSelector(selectShippingLineBasicDetails);
    const forwarderRanks = useAppSelector(selectForwarderRank);
    const isLoading = useAppSelector(selectShippingLineBasicDetailsLoading);
    const { carrierHeader } = useSelector((state: RootState) => state.shippingLineBasicDetailsData) as any;

    const [modalView, setModalView] = useState(false);

    useEffect(() => {
        dispatch(fetchShippingLineBasicDetailsList({ id }));
        dispatch(fetchForwarderRankList([]));
    }, [dispatch]);

    useEffect(() => {
        if (basicDetails?.data?.info?.oceanCarrierName) {
            localStorage.setItem('shippinglineName', basicDetails?.data?.info?.oceanCarrierName);
        }
    }, [basicDetails?.data?.info?.oceanCarrierName]);

    //Checking if at least one address is complete
    const isAddressIncomplete = () => {
        return basicDetails?.data?.info?.sl_Org?.addressList?.some(
            (address: any) =>
                address.addressline1 && address.city && address.state && address.postcode && address.countrycode
        )
            ? false
            : true;
    };

    const addressValidation = (value: any) => {
        let returnValue = '';
        if (!value.addressline1) {
            returnValue = ', Address';
        }
        if (!value.state) {
            returnValue = returnValue + ', State';
        }
        if (!value.postcode) {
            returnValue = returnValue + ', Postcode';
        }
        if (!value.city) {
            returnValue = returnValue + ', City';
        }
        if (!value.countrycode) {
            returnValue = returnValue + ', Country';
        }
        return returnValue.slice(1) + ' is missing';
    };

    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link className="dropdown-arrow" to="/shippingline" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link to="/profile" className="breadcrumb-item">
                                Organisations &amp; Users
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <Link to="/shippingline" className="breadcrumb-item">
                                Organisations
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Carrier
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    {/* <Link to="/shippingline">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </Link> */}
                    <h2 className="page-heading">
                        {basicDetails?.data?.info?.oceanCarrierName
                            ? basicDetails?.data?.info?.oceanCarrierName
                            : localStorage.getItem('shippinglineName')}
                    </h2>
                </div>
                <HeaderTabs />
                {/* <div className="arrow-tab-container">
                    <div className="arrow-tab-holder">
                        <Link
                            aria-current="page"
                            to={`/profile/shippingline/${id}/basic_details`}
                            className="arrow-tab-border active active shipping-basic-tab"
                        >
                            <div className="arrow-badge">1</div>
                            <div className="arrow-tab">Basic Details</div>
                        </Link>
                        <Link
                            to={`/profile/shippingline/${id}/contract_details`}
                            className="arrow-tab-border shipping-contract-tab"
                        >
                            <div className="arrow-badge">2</div>
                            <div className="arrow-tab ">Contract Details</div>
                        </Link>
                    </div>
                </div> */}
            </div>
            {isLoading && (
                <div className="app-loader loading">
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div>
            )}
            <div className="content-section main-wrapper shipping-basic-detail-wrapper">
                {!isLoading && !carrierHeader?.bdTabComplete && (
                    <div className="mandatory-warning-text">
                        <div>
                            <svg className="svg-icon error-icon">
                                <use xlinkHref="#errorIcon">
                                    <title>Warning</title>
                                </use>
                            </svg>
                        </div>

                        <div className="error-text">
                            Mandatory fields missed while integrating. Please integrate again with all mandatory fields
                            to proceed.
                        </div>
                    </div>
                )}
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="card-head-btn-edit-holder">
                            <h6 className="details-box-heading-2">Profile Information</h6>
                        </div>
                    </div>

                    <div className="card-detail-col">
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Carrier Name</h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.oceanCarrierName}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">
                                Organisation Code<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.sl_Org?.or_Orgcode}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6
                                className={`details-box-heading ${
                                    !isLoading && !basicDetails?.data?.info?.shippingLineCode ? 'mandatory-text' : ''
                                }`}
                            >
                                {!isLoading && !basicDetails?.data?.info?.shippingLineCode && (
                                    <BSTooltip
                                        id={'view-field-shippingLineCode'}
                                        title="Missing Mandatory Field"
                                        classList="tooltip-error-btn"
                                        tooltipClassList="mandatory-error-icon"
                                    >
                                        <button className="app-btn mandatory-btn">
                                            <svg className="svg-icon error-icon">
                                                <use xlinkHref="#errorIcon">
                                                    <title>Warning</title>
                                                </use>
                                            </svg>
                                        </button>
                                    </BSTooltip>
                                )}
                                Carrier Code<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.shippingLineCode}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Sea Carrier</h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.seaCarrier}</div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6
                                className={`details-box-heading ${
                                    !isLoading && !basicDetails?.data?.info?.carrierCategory ? 'mandatory-text' : ''
                                }`}
                            >
                                {!isLoading && !basicDetails?.data?.info?.carrierCategory && (
                                    <BSTooltip
                                        id={'view-field-shippingLineCode'}
                                        title="Missing Mandatory Field"
                                        classList="tooltip-error-btn"
                                        tooltipClassList="mandatory-error-icon"
                                    >
                                        <button className="app-btn mandatory-btn">
                                            <svg className="svg-icon error-icon">
                                                <use xlinkHref="#errorIcon">
                                                    <title>Warning</title>
                                                </use>
                                            </svg>
                                        </button>
                                    </BSTooltip>
                                )}
                                Carrier Category<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.carrierCategory}</div>
                        </div>

                        <div className="details-col box-content-holder">
                            <h6
                                className={`details-box-heading ${
                                    !isLoading && !basicDetails?.data?.info?.sl_Org?.or_Unloco ? 'mandatory-text' : ''
                                }`}
                            >
                                {!isLoading && !basicDetails?.data?.info?.sl_Org?.or_Unloco && (
                                    <BSTooltip
                                        id={'view-field-shippingLineCode'}
                                        title="Missing Mandatory Field"
                                        classList="tooltip-error-btn"
                                        tooltipClassList="mandatory-error-icon"
                                    >
                                        <button className="app-btn mandatory-btn">
                                            <svg className="svg-icon error-icon">
                                                <use xlinkHref="#errorIcon">
                                                    <title>Warning</title>
                                                </use>
                                            </svg>
                                        </button>
                                    </BSTooltip>
                                )}
                                UNLOCO<span className="mandatory-field-text">*</span>
                            </h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.sl_Org?.or_Unloco}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Company Registration Number</h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.sl_Org?.or_ABN}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">SCAC</h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.scac}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Phone</h6>
                            <div className="details-box-content">{basicDetails?.data?.info?.sl_Org?.or_Phone}</div>
                        </div>
                        <div className="details-col box-content-holder">
                            <h6 className="details-box-heading">Website URL</h6>
                            <a
                                href={basicDetails?.data?.info?.sl_Org?.or_WebSiteUrl}
                                target="_blank"
                                className="details-box-content link-url"
                            >
                                {basicDetails?.data?.info?.sl_Org?.or_WebSiteUrl}
                            </a>
                        </div>
                        <div className="details-col box-content-holder">
                            <div className="card-head-holder">
                                <h6 className="details-box-heading">Forwarder Ranking</h6>
                                <ShowForPermissions permission="edit" type="shipping">
                                    <button
                                        onClick={() => {
                                            setModalView(true);
                                        }}
                                        className="action-btn app-btn app-btn-secondary "
                                        title="Edit"
                                    >
                                        <svg className="svg-icon edit-icon">
                                            <use xlinkHref="#editIcon"></use>
                                        </svg>
                                    </button>
                                </ShowForPermissions>
                            </div>

                            <div className="details-box-content">
                                {basicDetails?.data?.info?.sl_Org?.or_ForwarderRanking?.rank}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card details-box custom">
                    <div className="card-head-holder add-btn-holder">
                        <div className="heading-with-error">
                            <h6 className="details-box-heading-2">
                                Address<span className="mandatory-field-text">*</span>
                            </h6>
                            {!isLoading && !basicDetails?.data?.info?.sl_Org?.addressList?.length && (
                                <div className="mandatory-warning-text">
                                    <div>
                                        <svg className="svg-icon error-icon">
                                            <use xlinkHref="#errorIcon">
                                                <title>Warning</title>
                                            </use>
                                        </svg>
                                    </div>
                                    <div className="error-text">At least one address needs to be added</div>
                                </div>
                            )}
                        </div>
                    </div>
                    {!basicDetails?.data?.info?.sl_Org?.addressList?.length && (
                        <div className="no-data-content">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No address added</p>
                        </div>
                    )}
                    <div className="card-detail-col">
                        {basicDetails?.data?.info?.sl_Org?.addressList.map(
                            (address: ShippingLineAddressListDataProp, index: number) => (
                                <div key={index} className="details-col box-content-holder">
                                    <div className="card-head-holder">
                                        <h6
                                            className={`details-box-heading ${
                                                !isLoading && isAddressIncomplete() ? 'mandatory-text' : ''
                                            }`}
                                        >
                                            {!isLoading && isAddressIncomplete() && (
                                                <BSTooltip
                                                    id={`view-field-${index}`}
                                                    title="Missing Mandatory Field"
                                                    classList="tooltip-error-btn"
                                                    tooltipClassList="mandatory-error-icon"
                                                >
                                                    <button className="app-btn mandatory-btn">
                                                        <svg className="svg-icon error-icon">
                                                            <use xlinkHref="#errorIcon">
                                                                <title>Warning</title>
                                                            </use>
                                                        </svg>
                                                    </button>
                                                </BSTooltip>
                                            )}
                                            Address {index + 1}{' '}
                                            {isAddressIncomplete() && ' (' + addressValidation(address) + ' )'}
                                        </h6>
                                    </div>

                                    <div className="details-box-content">
                                        <p>{address.addressline1}</p>
                                        <p>{address.addressline2}</p>
                                        <p>{address.addressline3}</p>
                                        <p>{address.city}</p>
                                        <p>{address.countrycode}</p>
                                        <p>{address.postcode}</p>
                                        <p>{address.state}</p>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
                <div className="card details-box custom">
                    <h6 className="details-box-heading-2">Carrier Contacts</h6>
                    <div className="card-detail-col contact-details">
                        <div className="details-col">
                            <div className="box-content-holder address-details">
                                <div className="details-box-content column-wrap-content">
                                    {basicDetails?.data?.info?.sl_Org?.or_OrganisationContactList.map(
                                        (users: ShippingLineOrganisationContactListDataProp, index: number) => (
                                            <React.Fragment key={index}>
                                                <div className="contact-data">
                                                    <h6 className="details-box-heading" style={{ paddingTop: '7px' }}>
                                                        {users.oc_title}
                                                    </h6>
                                                    <ToolTip tooltipClass={'inline-block'}>
                                                        <div>{users.oc_contactName}</div>
                                                        {users.oc_contactName ? (
                                                            <ToolTipContentBox
                                                                data={{
                                                                    userName: users.oc_contactName,
                                                                    role: users.oc_title,
                                                                    email: users.oc_email,
                                                                    phoneNumber: users.oc_phonenumber,
                                                                }}
                                                            />
                                                        ) : null}
                                                    </ToolTip>
                                                </div>
                                            </React.Fragment>
                                        )
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <h6 className="details-box-heading-2">Forwarder Contacts</h6>
                    <div className="card-detail-col">
                        {basicDetails?.data?.info?.sl_Org?.or_UsersList &&
                            Object.entries(basicDetails?.data?.info?.sl_Org?.or_UsersList).map(
                                ([key, val]: any[], index: number) => (
                                    <div key={index} className="details-col box-content-holder">
                                        <h6 className="details-box-heading">{key}</h6>

                                        <div className="details-box-content">
                                            {val &&
                                                val?.map((res: any, index: number) => (
                                                    <ToolTip tooltipClass={'inline-block'}>
                                                        <React.Fragment key={index}>
                                                            <p>
                                                                {res.us_FirstName} {res.us_LastName}
                                                            </p>
                                                            {res.us_FirstName ? (
                                                                <ToolTipContentBox
                                                                    data={{
                                                                        userName:
                                                                            res.us_FirstName + ' ' + res.us_LastName,
                                                                        role: res.us_Role ? res.us_Role : null,
                                                                        email: res.us_Email,
                                                                        phoneNumber: res.us_PhoneNo,
                                                                    }}
                                                                />
                                                            ) : null}
                                                        </React.Fragment>
                                                    </ToolTip>
                                                ))}
                                        </div>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <Link
                        className={`app-btn app-btn-primary footer-btn${
                            !carrierHeader?.bdTabComplete ? ' disabled' : ''
                        }`}
                        title="Next"
                        to={!carrierHeader?.bdTabComplete ? '' : `/profile/shippingline/${id}/contract_details`}
                    >
                        <span className="button-text footer-button-text">Next</span>
                    </Link>
                </div>
            </div>
            <ProfileInformationModal
                display={modalView}
                displayFunction={setModalView}
                forwarderRanks={forwarderRanks?.data}
                shippingLineId={id}
                forwarderRanking={basicDetails?.data?.info?.sl_Org?.or_ForwarderRanking?.rank}
            />
        </div>
    );
};
export default Index;
