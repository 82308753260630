import axios from 'axios';
// import { error } from 'console';
import { post } from '../../HttpMethods';
import { getOrganisationData } from 'src/utils';

export const lifeCycleSearchOrdApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `/api/ord/tracking/lifecycle/search?profile=${payload.profile}&keyword=${payload.keyword}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );

        return { data: { success: true, data: response } };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};

export const lifeCycleSearchMgtApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        // const response = await post(
        //     `/api/mgt/tracking/lifecycle/search?profile=${payload.profile}&keyword=${payload.keyword}`,
        //     {
        //         ...listOfUserOrganisations,
        //         isForwarder: payload.isForwarder ?? false,
        //         loggedInUserName: payload.userName,
        //     },
        //     null
        // );

        const queries = [
            post(
                `/api/mgt/tracking/lifecycle/search?profile=${payload.profile}&keyword=${payload.keyword}`,
                {
                    ...listOfUserOrganisations,
                    isForwarder: payload.isForwarder ?? false,
                    loggedInUserName: payload.userName,
                },
                null
            ),
            post(
                `/api/ord/tracking/lifecycle/search?profile=${payload.profile}&keyword=${payload.keyword}`,
                {
                    ...listOfUserOrganisations,
                    isForwarder: payload.isForwarder ?? false,
                    loggedInUserName: payload.userName,
                },
                null
            ),
            post(
                `/api/bkg/tracking/lifecycle/search?profile=${payload.profile}&keyword=${payload.keyword}`,
                {
                    ...listOfUserOrganisations,
                    isForwarder: payload.isForwarder ?? false,
                    loggedInUserName: payload.userName,
                },
                null
            ),
        ];
        const response: any = await Promise.allSettled(queries);

        return response;

        // return { data: { success: true, data: response } };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};

export const lifeCycleBookingTimelineApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `/api/bkg/tracking/lifecycle/search/bplog?id=${payload.id}&profile=${payload.profile}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};
export const lifeCycleBookingTimelineMblApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `api/bkg/tracking/lifecycle/mbldata/?mblNumber=${payload.mblnumber}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};
export const lifeCycleBookingDataApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `/api/bkg/tracking/lifecycle/search/basicdetails?id=${payload.id}&profile=${payload.profile}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};
export const lifeCycleBookingDataMblApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `/api/bkg/tracking/lifecycle/mbldata/bookings?mblNumber=${payload.mblNumber}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};
export const lifeCyclePortTimeline = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `/api/bkg/tracking/lifecycle/search/portlog?id=${payload.id}&profile=${payload.profile}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};

export const lifeCycleClientTimeline = async (payload: { id: string; profile: string; userName: string }) => {
    try {
        const listOfUserOrganisations = getOrganisationData();
        const payloadValues = {
            ...listOfUserOrganisations,
            profile: payload.profile,
            loggedInUserName: payload.userName,
        };
        const queries = [
            post(`/api/mgt/tracking/lifecycle/clientdetails?orgID=${payload.id}`, payloadValues, null),
            post(`/api/ord/tracking/lifecycle/clientData/countPO?orgID=${payload.id}`, payloadValues, null),
            post(`/api/bkg/tracking/lifecycle/clientData/countBP?orgID=${payload.id}`, payloadValues, null),
        ];
        const response = await Promise.allSettled(queries);
        return response;
    } catch (error) {
        throw error;
    }
};

export const lifeCycleAgentTimeline = async (payload: {
    id: string;
    code: string;
    profile: string;
    userName: string;
}) => {
    try {
        const listOfUserOrganisations = getOrganisationData();
        const payloadValues = {
            ...listOfUserOrganisations,
            profile: payload.profile,
            loggedInUserName: payload.userName,
        };
        const queries = [
            post(`/api/bkg/tracking/lifecycle/agentdata/bpcount?orgAgtCode=${payload.code}`, payloadValues, null),
            post(`/api/ord/tracking/lifecycle/agentdata/pocount?orgAgtCode=${payload.code}`, payloadValues, null),
            post(`api/mgt/tracking/lifecycle/agentdetails?orgAgtID=${payload.id}`, payloadValues, null),
        ];
        const response = await Promise.allSettled(queries);
        return response;
    } catch (error) {
        throw error;
    }
};

export const lifeCyclePurchaseOrderTimelineApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `/api/mgt/tracking/lifecycle/vendordetails?orgID=${payload.id}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};

export const wareHouseTimelineApi = async (payload: any) => {
    try {
        const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');

        const response = await post(
            `/api/mgt/tracking/lifecycle/warehouse/details?blpAgentCode=${payload.code}`,
            {
                ...listOfUserOrganisations,
                isForwarder: payload.isForwarder ?? false,
                loggedInUserName: payload.userName,
            },
            null
        );
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            return { data: { success: false, data: error.message } };
        } else {
            return { data: { success: false, data: error } };
        }
    }
};
