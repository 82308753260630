/* eslint-disable no-undefined */
import React, { useCallback, useEffect, useState } from 'react';
// import './container-details.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { error, Position, success } from '../../../../utils/toast';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';
import {
    addContainerNac,
    fetchDeliveryAndContainerModes,
} from 'src/store/reducers/bookingProposal/additionalDetailsNacReducer';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox';
import { propagateBcnData } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { useParams } from 'react-router-dom';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    bpId: any;
    setHasUpdateCompleted: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    validation?: any;
    setValidation?: any;
    hasUpdateCompleted: boolean;
}

const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    bpId,
    setHasUpdateCompleted,
    setCancelModalView,
    setValidation,
    hasUpdateCompleted,
}) => {
    //const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [containerDropDownOpen, setContainerDropDownOpen] = useState<boolean>(false);
    const [deliveryDropDownOpen, setDeliveryDropDownOpen] = useState<boolean>(false);
    const [containerModeDropDownOpen, setContainerModeDropDownOpen] = useState<boolean>(false);
    const [packingDropDownOpen, setPackingDropDownOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    // const [validation, setValidation] = useState(false);
    const { id } = useParams();
    const { containerDetail } = useSelector((state: RootState) => state.containerAllocation);
    const [_containerModesModified, setContainerModesModified] = useState<any[]>([]);
    const [deliveryModesModified, setDeliveryModesModified] = useState<any[]>([]);
    const [containerTypesModified, setContainerTypesModified] = useState<any[]>([]);
    const headerData = useSelector((state: RootState) => state.bpHeaderData);
    const {
        // isLoading: additionalDetailsLoading,
        containerTypes,
        containerModes,
        deliveryModes,
        contractDetails: contractDetailsData,
        isLoading: additionalDetailsLoading,
        addOrEditContainerSuccess,
        addOrEditContainerError,
        errorMessage,
    } = useSelector((state: RootState) => state.additionalDetailsNac);

    const getContainerMode = useCallback(
        () => containerModes?.find(({ containerMode }: any) => containerMode === 'LCL') || ({} as any),
        [containerModes]
    );

    const initialContainerValues = {
        containerTypeId: '',
        deliveryModeId: '',
        containerModeId: getContainerMode()?.containerModeId,
        cbm: 0,
        quantity: 0,
    };

    const getCbm = () => {
        return Math.round(
            formik.values['quantity'] *
                containerTypesModified.find((option: any) => option.value === formik.values['containerTypeId'])
                    ?.capacity || 0
        );
    };

    const ContainerDataValidationSchema = Yup.object().shape({
        containerTypeId: Yup.string().required('Container type is required'),
        deliveryModeId: Yup.string().required('Delivery mode required'),
        containerModeId: Yup.string().required('Container mode is required'),
        quantity: Yup.number()
            .typeError('Quantity must be a number')
            .required('Quantity is required')
            .min(1, 'Quantity must be greater than zero'),
        cbm: Yup.number().required('CBM is required'),
    });

    const formik = useFormik({
        initialValues: initialContainerValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ContainerDataValidationSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                values.cbm = getCbm();
                const finalValues = JSON.parse(JSON.stringify(values));
                delete finalValues.containerTypeId;
                dispatch(
                    addContainerNac({
                        ...finalValues,
                        bookingProposalId: bpId,
                        containerId: values.containerTypeId,
                    })
                );
                propagateBcnData(id);
            }
        },
    });

    useEffect(() => {
        if (modalView) {
            dispatch(fetchDeliveryAndContainerModes({ bpType: 'lcl', coloaderCode: headerData?.coLoaderCode }));
        }
    }, [modalView]);

    useEffect(() => {
        if (addOrEditContainerSuccess) {
            formik.setValues(initialContainerValues);
            success('Container added successfully', Position.TOP_RIGHT);
            setHasUpdateCompleted(!hasUpdateCompleted);
            setModalView(false);
        }
    }, [addOrEditContainerSuccess]);

    useEffect(() => {
        if (addOrEditContainerError) {
            error(errorMessage || 'Something went wrong', Position.TOP_RIGHT);
        }
    }, [addOrEditContainerError]);

    useEffect(() => {
        const containerModeData = containerModes?.map((option: any) => {
            return { value: option.containerModeId, label: option.containerMode };
        });
        setContainerModesModified(containerModeData ? containerModeData : []);

        const containeTypeData = containerTypes?.map((option: any) => {
            return { ...option, value: option.containerTypeId, label: `${option.description} (${option.code})` };
        });
        setContainerTypesModified(containeTypeData ? containeTypeData : []);

        const deliveyModeData = deliveryModes?.map((option: any) => {
            return { value: option.deliveryModeId, label: option.deliveryMode };
        });
        setDeliveryModesModified(deliveyModeData ? deliveyModeData : []);
    }, [containerModes]);

    useEffect(() => {
        if (!modalView) {
            formik.resetForm();
        }
    }, [modalView]);

    const changeDropDown = (field: string) => {
        switch (field) {
            case 'containerType':
                setContainerDropDownOpen((prev) => !prev);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'deliveryMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen((prev) => !prev);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'containerMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen((prev) => !prev);
                setPackingDropDownOpen(false);
                break;
            case 'packing':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen((prev) => !prev);
                break;
            default:
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
        }
    };

    // const packingSelection = (res: PackingList) => {
    //     formik.setFieldError('packing', undefined);
    //     if (formik.values.packing.includes(res.packingCode)) {
    //         const packing = formik.values.packing.filter((el: string) => el !== res.packingCode);
    //         formik.setFieldValue('packing', packing);
    //     } else {
    //         const packing = [...formik.values.packing, res.packingCode];
    //         formik.setFieldValue('packing', packing);
    //     }
    // };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div
                        onClick={(e) => {
                            changeDropDown('');
                            e.stopPropagation();
                        }}
                        className="modal-content-holder basic-detail-content-holder large-popup booking-cancellation-modal"
                    >
                        <div className="modal-header">
                            <div className="title">Basic Details</div>
                            <button
                                type="button"
                                className="app-btn modal-close-btn"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <div className="modal-main-content bd-modal-main-content">
                                {headerData.bpStatus !== 'Draft_Booking_Proposal' &&
                                    headerData.bpStatus !== 'Awaiting_Forwarder_Approval' && (
                                        <div className="warning-text">
                                            <div>
                                                <svg className="svg-icon error-icon">
                                                    <use xlinkHref="#errorIcon">
                                                        <title>Warning</title>
                                                    </use>
                                                </svg>
                                            </div>

                                            <div className="error-text">
                                                You are about to add a container to this booking proposal. By doing so
                                                the Booking Proposal will be reset to 'Draft Booking Proposal'
                                            </div>
                                        </div>
                                    )}
                                <form
                                    id="basic-details"
                                    action=""
                                    onSubmit={formik.handleSubmit}
                                    className="modal-form layout-holder one-column"
                                >
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Container Type
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder grey-option-box">
                                            <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder">
                                                <SearchableSelectBox
                                                    data={containerTypesModified?.length ? containerTypesModified : []}
                                                    placeholder="Enter container type"
                                                    formik={formik}
                                                    fieldName="containerTypeId"
                                                    customStyle={''}
                                                    menuPosition="fixed"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                    closeMenuOnScroll={true}
                                                />
                                                {formik.errors.containerTypeId ? (
                                                    <div className="error-text">{formik.errors.containerTypeId}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Delivery Mode
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder grey-option-box">
                                            <div className="dropdown-wrap download-dropdown dropdown-open search-toast-content-holder delivery-mode-dropdown">
                                                <SearchableSelectBox
                                                    data={deliveryModesModified?.length ? deliveryModesModified : []}
                                                    placeholder="Enter delivery mode"
                                                    formik={formik}
                                                    fieldName="deliveryModeId"
                                                    customStyle={''}
                                                    menuPosition="fixed"
                                                    menuPortalTarget={document.getElementById('outside_div')}
                                                    closeMenuOnScroll={true}
                                                />
                                                {formik.errors.deliveryModeId ? (
                                                    <div className="error-text">{formik.errors.deliveryModeId}</div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Container Mode
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder grey-option-box">
                                            {getContainerMode()?.containerMode}
                                        </div>
                                    </div> */}
                                    <div className="modal-input-holder">
                                        <label className="modal-input-label">Quantity</label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter quantity"
                                                type="text"
                                                name={'quantity'}
                                                className="modal-input input-text"
                                                onChange={(event) => {
                                                    // eslint-disable-next-line no-undefined
                                                    formik.setFieldError(
                                                        event.target.name,
                                                        // eslint-disable-next-line no-undefined
                                                        undefined
                                                    );
                                                    formik.handleChange(event);
                                                }}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Enter') {
                                                        e.preventDefault();
                                                    }
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                value={formik.values['quantity']}
                                            ></input>
                                            {formik.errors.quantity ? (
                                                <div className="error-text">{formik.errors.quantity}</div>
                                            ) : null}
                                        </div>
                                    </div>

                                    <div className="modal-input-holder">
                                        <label className="modal-input-label">CBM</label>
                                        <div className="modal-input-layout-item">{getCbm()}</div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn text-close-btn"
                                type="reset"
                                form="basic-details"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="submit"
                                form="basic-details"
                                onClick={() => setValidation(true)}
                                // disabled={Object.keys(formik.errors).length ? true : false}
                            >
                                <span className="button-text vendor-button-text">Add</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
