import axios from 'axios';
import { get, post, del } from '../../HttpMethods';

export interface Favourite {
    userName: string;
    favourite: string;
    order?: number;
    isSea: boolean;
    isAir: boolean;
    isLand: boolean;
    isInternational: boolean;
    isTriangle: boolean;
}

//Get all favourites
export const fetchInsightsFavourites = async (payload: { userName: string; profileType: string }) => {
    try {
        const data = await get(`api/mgt/favourites?userName=${payload.userName}&profile=${payload.profileType}`, null);
        return data?.info;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

//Add to favourites
export const addInsightToFavourites = async (payload: {
    userName: string;
    favourite: string;
    isSea: false;
    isAir: false;
    isLand: false;
    isInternational: false;
    isTriangle: false;
}) => {
    try {
        const response = await post('api/mgt/favourites', payload, null);

        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

//change order and remove
export const updateFavourites = async (payload: { data: Favourite[]; userName: string }) => {
    try {
        const response = await post(`api/mgt/favourites/update?userName=${payload?.userName}`, payload.data, null);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

//de select API
export const deselectFavourites = async (payload: { insightId: string }) => {
    try {
        const response = await del(`api/mgt/favourites/deselect/${payload?.insightId}`, null);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const deleteAllFavourites = async (payload: { userName: string }) => {
    try {
        const response = await post(`api/mgt/favourites/deleteAll?userName=${payload?.userName}`, null, null);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
