import { AxiosResponse } from 'axios';
import { put, call, takeEvery, debounce } from 'redux-saga/effects';
import {
    fetchWorkTrendsList,
    fetchWorkTrendsListSuccess,
    fetchUsersList,
    fetchUsersListSuccess,
    fetchActivityList,
    fetchActivityListSuccess,
    fetchActivityListFailure,
} from '../../reducers/dashboard/workTrendsReducer';
import {
    fetchWorkTrendsListDetailsApi,
    fetchUsersListDetailsApi,
    fetchActivityDetailsApi,
} from '../../../services/apis/dashboard/workTrendsApi';

function* getWorkTrendsDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchWorkTrendsListDetailsApi, payload);
        yield put({ type: fetchWorkTrendsListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchWorkTrendsListFailed, payload: error });
    }
}

function* getUsersDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchUsersListDetailsApi, payload);
        yield put({ type: fetchUsersListSuccess, payload: response });
    } catch (error) {
        // yield put({ type: fetchWorkTrendsListFailed, payload: error });
    }
}

function* getActivityDetails({ payload }: { payload: any; type: any }) {
    try {
        const response: AxiosResponse = yield call(fetchActivityDetailsApi, payload);
        yield put({ type: fetchActivityListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchActivityListFailure, payload: error });
    }
}

function* workTrendsSaga() {
    yield takeEvery(fetchWorkTrendsList, getWorkTrendsDetails);
    yield debounce(500, fetchUsersList, getUsersDetails);
    yield takeEvery(fetchActivityList, getActivityDetails);
}

export default workTrendsSaga;
