import React, { useEffect, useState } from 'react';
import '../../../BookingProposal/BookingAddedGrid/booking-added-grid.scss';
import PoReview from '../../../BookingProposal/BookingAddedGrid/PurchaseOrderDetails';
import { useSelector } from 'react-redux';
// import { selectSku } from '../../../../store/reducers/purchaseOrder/productPoReducer';
// import { useAppSelector } from '../../../../hooks';
// import { fetchAddedPoData } from '../../../../store/reducers/bookingProposal/addedPoReducer';
import { RootState } from '../../../../store/reducers';
import ProductDetailModal from '../../BookingAddedGrid/ProductDetailsModal';
import ShowForPermissions from '../../../../ShowForPermissions';
// import { useUserProfile } from '../../../../hooks/useUserProfile';
// import { removeFromContainer } from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
/* eslint no-var: off */

interface BioProps {
    bpID?: any;
    setSelectedItemsAdded: any;
    selectedItemsAdded: any;
    addPoList: any;
    showConditionStatus: any;
    containerDetailsId: any;
    setPoDetails: any;
    listingPage: boolean;
    originalData?: any;
}
const Index: React.FC<BioProps> = ({
    bpID,
    setSelectedItemsAdded,
    selectedItemsAdded,
    addPoList,
    showConditionStatus,
    // containerDetailsId,
    // setPoDetails,
    listingPage,
    originalData,
}) => {
    // const { profileType } = useUserProfile();
    // const sku = useAppSelector(selectSku);
    const { prouctsList } = useSelector((state: RootState) => state.addedPos);
    // const dispatch = useDispatch();
    // const [modalView, setModalView] = useState(true);
    const [poDisplay, setpoDisplay] = useState({ id: '', status: false });
    const [ModalViewproduct, setModalViewproduct] = useState({ status: false, value: '', id: '' });
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_productsAll, setproductsAll] = useState([]);
    const [VendorCode, _setVendorCode] = useState('');
    // const [poLine, setpoLine] = useState(0);
    // var poLineNum = 0;
    // var poStatus = true;
    useEffect(() => {
        // dispatch(fetchAddedPoData(bpID));
    }, [bpID]);

    // const selectedIds = (boid: string, bsid: any, index: any, type: boolean) => {
    //     var selectedAr: any[] = [...selectedItemsAdded];
    //     var flag = false;
    //     if (type) {
    //         for (var i = 0; i < selectedAr.length; i++) {
    //             if (selectedAr[i].id === boid) {
    //                 var filterAr: any = [...selectedAr[i].subPolineIds, ...bsid];
    //                 flag = true;

    //                 filterAr = filterAr.filter(function (item: any, pos: any) {
    //                     return filterAr.indexOf(item) == pos;
    //                 });
    //                 selectedAr[i].subPolineIds = [...filterAr];
    //             }
    //         }
    //         if (!flag) {
    //             var newObj = {
    //                 id: boid,
    //                 isFullPo: false,
    //                 subPolineIds: [...bsid],
    //             };
    //             selectedAr.push(newObj);
    //         }
    //     } else {
    //         for (var i = 0; i < selectedAr.length; i++) {
    //             if (selectedAr[i].id === boid) {
    //                 var delAr: any[] = [...selectedAr[i].subPolineIds];
    //                 for (var j = 0; j < delAr.length; j++) {
    //                     for (var k = 0; k < bsid.length; k++) {
    //                         if (delAr[j] === bsid[k]) {
    //                             delAr.splice(j, 1);
    //                             selectedAr[i].subPolineIds.splice(j, 1);
    //                             if (selectedAr[i].subPolineIds.length === 0) {
    //                                 selectedAr.splice(i, 1);
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     setSelectedItemsAdded(selectedAr);
    // };

    const selectAll = (type: boolean) => {
        var selectallar: any[] = [];
        var newObj = {};
        if (type) {
            addPoList?.forEach((element: any) => {
                var singleVal: any = [];
                newObj = {
                    id: element.poId,
                    isFullPo: false,
                    subPolineIds: singleVal,
                };
                element.bookingProposalSubPOLineList.forEach((element2: object) => {
                    var val = (element2 as any).bsId;
                    singleVal.push(val);
                });
                selectallar.push(newObj);
            });
            setSelectedItemsAdded(selectallar);
        } else {
            setSelectedItemsAdded([]);
        }
    };
    // const getAllIds = (val: any) => {
    //     var newArray: any[] = [];
    //     val.forEach((element: any) => {
    //         newArray.push(element.bsId);
    //     });
    //     return newArray;
    // };

    // const checkSelected = (boid: any, val: any) => {
    //     var flag = false;
    //     selectedItemsAdded.forEach((element: any) => {
    //         if (boid === element.id) {
    //             element.subPolineIds.forEach((element2: any) => {
    //                 if (element2 === val) {
    //                     flag = true;
    //                 }
    //             });
    //         }
    //     });

    //     return flag;
    // };
    const allSelected = () => {
        var totalAll = 0;
        var totalSelected = 0;
        var flag = false;
        originalData?.forEach((element: any) => {
            element.bookingProposalSubPOLineList.forEach((element1: any) => {
                if (element1.length !== 0) {
                    totalAll++;
                }
            });
        });
        selectedItemsAdded?.forEach((element: any) => {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            element.subPolineIds?.forEach((element1: any) => {
                totalSelected++;
            });
        });

        if (totalSelected >= totalAll && totalAll !== 0 && addPoList?.length) {
            flag = true;
        }
        return flag;
    };
    // const singleRowChecked = (id: any, poData: any) => {
    //     var flag = false;
    //     if (poData?.length) {
    //         selectedItemsAdded?.forEach((element: any) => {
    //             if (
    //                 element.subPolineIds.length === poData[0]?.bookingProposalSubPOLineList?.length &&
    //                 element.id === id
    //             ) {
    //                 flag = true;
    //             }
    //         });
    //     }
    //     return flag;
    // };
    // const selectSingle = (boId: any, bsid: any, index: any, type: boolean) => {
    //     var selectedAr: any[] = [...selectedItemsAdded];
    //     var flag = false;
    //     if (type) {
    //         for (var i = 0; i < selectedAr.length; i++) {
    //             if (selectedAr[i].id === boId) {
    //                 flag = true;
    //                 selectedAr[i].subPolineIds = [...selectedAr[i].subPolineIds, bsid];
    //             }
    //         }
    //         if (!flag) {
    //             var newObj = {
    //                 id: boId,
    //                 isFullPo: false,
    //                 subPolineIds: [bsid],
    //             };
    //             selectedAr.push(newObj);
    //         }
    //     } else {
    //         for (var i = 0; i < selectedAr.length; i++) {
    //             if (selectedAr[i].id === boId) {
    //                 var delAr: any[] = [...selectedAr[i].subPolineIds];
    //                 for (var j = 0; j < delAr.length; j++) {
    //                     if (delAr[j] === bsid) {
    //                         delAr.splice(j, 1);
    //                         selectedAr[i].subPolineIds.splice(j, 1);
    //                         if (selectedAr[i].subPolineIds.length === 0) {
    //                             selectedAr.splice(i, 1);
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     setSelectedItemsAdded(selectedAr);
    // };
    /* eslint-disable no-undefined */

    useEffect(() => {
        setproductsAll(prouctsList);
    }, [prouctsList]);

    // const userData = localStorage.getItem('user');
    // const roleIndex = localStorage.getItem('roleIndex');
    // let isAgent = false;
    // if (userData) {
    //     const { roles } = JSON.parse(userData);
    //     // if (roleIndex) {
    //     //     isAgent = roles[roleIndex].profile?.toLowerCase() === 'agent' ? true : false;
    //     // }
    // }

    return (
        <div className="grid-section grid-added-booking product-details-wrapper ao-po-list">
            <div className="grid-holder">
                <div className="grid-container">
                    {addPoList?.length === 0 && (
                        <div className="grid-no-content ">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">
                                No AO lines {listingPage ? 'added to this container' : 'found'}
                            </p>
                        </div>
                    )}
                    <div className="grid-scroll-content">
                        <table className="grid-table allocation-table">
                            <thead>
                                <tr className="grid-sticky-row">
                                    {showConditionStatus && (
                                        <ShowForPermissions permission="edit" type="bp">
                                            <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                                <div className="grid-cell-data centre-align">
                                                    <label className="app-check-wrapper">
                                                        <input
                                                            type="checkbox"
                                                            className="checkbox-input"
                                                            checked={allSelected()}
                                                            onChange={(e) => selectAll(e.currentTarget.checked)}
                                                        />
                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </th>
                                        </ShowForPermissions>
                                    )}

                                    <th id="" className="grid-cell-header col-po-number">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ao No.</span>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CLIENT</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon sort-desc">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-destination">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESTINATION</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-blp">
                                        <div className="grid-cell-data">
                                            <span className="header-title">BLP</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">sku</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-sku">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESCRIPTION</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-allocation">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ALLOCATION QTY</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-customer">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CUSTOMER ORDER REFERENCE NO.</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-customer">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CUSTOMER ORDER LINE NO.</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-origin">
                                        <div className="grid-cell-data">
                                            <span className="header-title">ORIGIN</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-destination2">
                                        <div className="grid-cell-data">
                                            <span className="header-title">DESTINATION ADDRESS</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-cbm">
                                        <div className="grid-cell-data">
                                            <span className="header-title">CBM</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                    <th id="" className="grid-cell-header col-weight">
                                        <div className="grid-cell-data">
                                            <span className="header-title">WEIGHT (KG)</span>
                                            <button className="sort-btn app-btn">
                                                <svg className="svg-icon sort-icon">
                                                    <use xlinkHref="#sortIcon">
                                                        <title>sort</title>
                                                    </use>
                                                </svg>
                                            </button>
                                            <div className="filter-menu-wrap">
                                                <button className="app-btn filter-menu-button">
                                                    <svg className="svg-icon filter-icon">
                                                        <use xlinkHref="#filterIcon">
                                                            <title>filter</title>
                                                        </use>
                                                    </svg>
                                                    <span className="sr-only">header menu</span>
                                                </button>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            {addPoList?.map((res: any, i: any) => (
                                <tbody key={i} className="grouped-splited-row">
                                    {res?.bookingProposalSubPOLineList.map((_val: any, _i2: any) => {
                                        // if (poLineNum === val?.poLineNumber && i2 !== 0) {
                                        //     poStatus = false;
                                        // } else {
                                        //     poStatus = true;
                                        // }
                                        // poLineNum = val?.poLineNumber;
                                        return (
                                            <tr className="has-child row-expanded">
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">AO23421</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">McGlynn LLC</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">Melbourne (AUMEL)</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">ILM</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">
                                                        <div className="grid-cell-data">
                                                            <a className="booking-proposal-link">TN112233</a>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">Threaded nuts</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">50</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">1234FG12344HH</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">100</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">Yantian (CNYAN)</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">
                                                        7632 Courtyard Road, Rice Island, Sydney, Australia, 3207,
                                                        Victoria
                                                    </div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">1.5</div>
                                                </td>
                                                <td className="grid-cell ">
                                                    <div className="grid-cell-data">20</div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            ))}
                        </table>
                    </div>
                    {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} customStyle={true} />}{' '}
                    {ModalViewproduct.status && (
                        <ProductDetailModal
                            display={ModalViewproduct}
                            displayFunction={setModalViewproduct}
                            id={VendorCode}
                            data={prouctsList}
                            customStyle={true}
                        />
                    )}
                    {/* <CancelBookingModal></CancelBookingModal> */}
                    {/* <ProductDetailsModal data={skuDetail} display={modalView} displayFunction={setModalView} /> */}
                </div>
                {poDisplay.status && <PoReview displayFn={setpoDisplay} display={poDisplay} customStyle={true} />}{' '}
                {/* <CancelBookingModal></CancelBookingModal> */}
                {/* <ProductDetailsModal data={skuDetail} display={modalView} displayFunction={setModalView} /> */}
                {/* <LinkedBookingModal></LinkedBookingModal> */}
            </div>
        </div>
    );
};
export default Index;
