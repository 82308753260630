import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import { hideLoading } from 'react-redux-loading-bar';

import { getAddedPoLinesAir, removePoFromBpAir, searchAirAdded } from 'src/services/apis/bookingProposalAir/addedPoApi';
import { removeSuccess, saveAddedPoDataAir, removeError } from 'src/store/reducers/bookingProposalAir/addedPoReducer';
import { isLoadingFn } from 'src/store/reducers/bookingProposalAir/availablePosReducer';

function* getaddedPOListDataAir({ payload }: { payload: any; type: any }) {
    try {
        const res: AxiosResponse<any> = yield call(getAddedPoLinesAir, payload);
        payload['response'] = res;
        yield put({ type: saveAddedPoDataAir, payload });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* removePoFromList({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        const res: AxiosResponse<any> = yield call(removePoFromBpAir, payload);
        yield put({ type: removeSuccess, payload: res });
    } catch (error) {
        payload['message'] = error;
        yield put({ type: removeError, payload });
    } finally {
        yield put(hideLoading());
        yield put({ type: isLoadingFn, payload: false });
    }
}
// function* getSingleLineSaga({ payload }: { payload: any; type: any }) {
//     try {
//         yield put({ type: isLoadingFn, payload: true });
//         const res: AxiosResponse<any> = yield call(getSingleLine, payload);
//         yield put({ type: saveSingleData, payload: res });
//     } finally {
//         yield put(hideLoading());
//         yield put({ type: isLoadingFn, payload: false });
//     }
// }
function* searchInAddedTab({ payload }: { payload: any; type: any }) {
    try {
        const availablePoList: AxiosResponse<any> = yield call(searchAirAdded, payload);
        yield put({ type: saveAddedPoDataAir, payload: availablePoList });
    } finally {
        yield put(hideLoading());
    }
}

function* addedPoListAirSaga() {
    yield takeEvery('addedPosAir/fetchAddedPoDataAir', getaddedPOListDataAir);
    yield takeEvery('addedPosAir/removePoAir', removePoFromList);
    yield takeEvery('addedPosAir/searchData', searchInAddedTab);
    // yield takeEvery('addedPos/getSingleData', getSingleLineSaga);
}

export default addedPoListAirSaga;
