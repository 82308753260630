import { createSlice } from '@reduxjs/toolkit';

export interface ContactDetails {
    us_Role: string;
    us_MiddleName: string;
    us_Address1: string;
    us_PhoneNo: string;
    us_City: string;
    us_UserName: string;
    us_LastName: string;
    us_FirstName: string;
    us_Address3: string;
    us_State: string;
    us_Email: string;
    us_Address2: string;
    us_LandLineNo: string;
    us_Address_Type: string;
    us_Postal_Code: string;
    us_Country: string;
}

interface Address {
    addressline1: string;
    addressline2: string;
    addressline3: string;
    address_type: string;
    city: string;
    state: string;
    postcode: string;
    countrycode: string;
    contact_email: string;
    contact_mobno: string;
    contact_person: string;
    closest_unlocode_portcode: string;
    oa_PK: string;
}

export interface OrganisationContact {
    oc_jc: string;
    oc_mobilenumber: string;
    oc_contactName: string;
    oc_PK: string;
    oc_phonenumber: string;
    oc_title: string;
    oc_email: string;
}
interface ClientOrganisation {
    or_PK: string;
    or_Orgcode: string;
    or_Status: string;
    or_Orgtype: string;
    or_Orgname: string;
    or_ParentOrgCode: string;
    or_OrganisationContact: OrganisationContact[];
    or_ABN: string;
    or_Unloco: string;
    or_Phone: string;
    or_WebSiteUrl: string;
    addressList: Address[];
    or_OrganisationContactList: OrganisationContact[];
    or_UsersList: ContactDetails;
}

interface dataModel {
    forwarder_accountmanagerUPN: string;
    forwarder_crmUPN: string;
    forwarder_customerbrokerUPN: string;
    accountmanageruser: ContactDetails;
    crmuser: ContactDetails;
    customerbrokeruser: ContactDetails;
    addressList: Address[];
    childClients: dataModel;
    cl_Org: ClientOrganisation;
    cl_Relationship: string;
    cl_Status: string;
    cl_PK: string;
}

interface BasicDetailsState {
    clientBasicDetails?: dataModel;
    clientPK: string;
    isLoading: boolean;
    clientName: string;
}

const initialState: BasicDetailsState = {
    // eslint-disable-next-line no-undefined
    clientBasicDetails: undefined,
    clientPK: '',
    isLoading: true,
    clientName: '',
};
export const basicDetailsSlice = createSlice({
    name: 'basicDetails',
    initialState,
    reducers: {
        fetchBasicData: (state, action) => {
            state.isLoading = true;
            state.clientPK = action.payload.clientPK;
            state.clientBasicDetails = action.payload.clientBasicDetails;
            state.isLoading = false;
            state.clientName = action.payload?.clientBasicDetails?.cl_Org.or_Orgname;
        },
        isLoadingfn: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const { fetchBasicData, isLoadingfn } = basicDetailsSlice.actions;
export default basicDetailsSlice.reducer;
