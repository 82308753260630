import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import '../lcsBookingTiimeline.scss';
import { post } from 'src/services/HttpMethods';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { RootState } from 'src/store/reducers';
import { useDispatch, useSelector } from 'react-redux';
import { lifeCycleSearchCategories } from './Common/constants';
import MaxLimitModal from '../MaxLimitModal';

const Index: React.FC = () => {
    const navigate: any = useNavigate();
    const dispatch = useDispatch();
    const [poDetails, setPoDetails] = useState<any>({});
    const [showModal, setShowModal] = useState(false);
    const { id }: any = useParams();
    const location: any = useLocation();
    const { name: _poNumber, type } = location?.state || {};
    const { profileType, userName } = useUserProfile();
    const listOfUserOrganisations = JSON.parse(localStorage.getItem('listOfUserOrganisations') ?? '');
    const { selectedData } = useSelector((state: any) => state.lifeCycleSearch);
    const { favList, removeSuccess, addSuccess, isLoading, isError, error } = useSelector(
        (state: RootState) => state.lifecyclesearchFav
    );

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.po,
                    favourite: 'PO Number',
                    number: poDetails?.poNumber,
                    isIbpo: type === 'IBPO' ? true : false,
                    isItpo: type === 'ITPO' ? true : false,
                    isAo: type === 'AO' ? true : false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const fetchPOTimelineDetails = async () => {
        try {
            const response = await post(
                `/api/ord/tracking/lifecycle/search/po/poData?poID=${id}`,
                {
                    ...listOfUserOrganisations,
                    loggedInUserName: userName,
                },
                null
            );
            setPoDetails(response?.details[0]);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (id) {
            fetchPOTimelineDetails();
        }
    }, [id]);

    const handlePONumberClick = () => {
        if (type === 'ITPO') {
            navigate(`/purchase_order/${id}/basic_details`);
        } else if (type === 'AO') {
            navigate(`/allocation_orders/${id}/basic_details`);
        } else {
            navigate(`/inbound/${id}/basic_details`);
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title ">PO Number</div>
                <div className="timeline-head-value link-com" onClick={handlePONumberClick}>
                    {poDetails?.poNumber || '-'}
                </div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="cm-key-pair-details">
                {/* <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Status</div>
                    <div className="cm-key-pair-value">
                        <div 
                        className="status-ui error-status">2</div>
                    </div>
                </div> */}

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Client </div>
                    <div className="cm-key-pair-value">{poDetails?.clientName || '-'}</div>
                </div>

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Vendor</div>
                    <div className="cm-key-pair-value">{poDetails?.vendorName || '-'}</div>
                </div>

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Status</div>
                    <div className="cm-key-pair-value">
                        <div
                            className={`status-ui ${
                                poDetails?.status === 'ACTIVE' ? 'success-status' : 'error-status'
                            }  `}
                        >
                            {poDetails?.status}
                        </div>
                    </div>
                </div>

                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Origin</div>
                    <div className="cm-key-pair-value">{poDetails?.origin || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Destination</div>
                    <div className="cm-key-pair-value">{poDetails?.destination || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">No of confirmation groups</div>
                    <div className="cm-key-pair-value">{poDetails?.groupCount || '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Created Date</div>
                    <div className="cm-key-pair-value">
                        {poDetails?.createdDate ? moment(poDetails?.createdDate).format('DD-MM-YYYY') : '-'}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Last Activity</div>
                    <div className="cm-key-pair-value">
                        {' '}
                        {poDetails?.modifiedDate ? moment(poDetails?.modifiedDate).format('DD-MM-YYYY') : '-'}
                    </div>
                </div>
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`PO Number : ${poDetails?.poNumber}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
