/* eslint-disable no-undefined */
import React, { useEffect, useState, useRef } from 'react';
import './add-commercial-modal.scss';
import './commercial-detail-modal.scss';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import { error, Position, success } from '../../../../utils/toast';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
// import Select, { components } from 'react-select';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useAppDispatch, useAppSelector } from '../../../../hooks';

import {
    createCommercialDetails,
    fetchCommercialDetailsList,
    selectCommercialDetailCurrentPage,
    selectCommercialDetailIsError,
    // selectCommercialDetailPerPageCount,
    // selectCommercialDetailUser,
    selectCommercialDetailError,
    updateCommercialDetails,
    selectCommercialDetailSuccess,
    clearFailure,
    selectCommercialDetailAuthor,
    // selectCommercialDetailForwarderSignatory,
    selectCommercialDetailAgreementTerms,
    selectCommercialDetailIsSubmitAndActivated,
} from '../../../../store/reducers/profiles/agents/commercialDetailsReducer';
import {
    fileTypesInfoText,
    fileUploadInfoText,
    FILE_SIZE,
    // reactSelectStyle,
    SUPPORTED_FORMATS,
} from '../../../../utils/constants';
import DatePicker from '../../../../components/common/DatePicker';
import SearchableSelectBox from '../../../../components/common/SearchableSelectBox';
import { downloadAgentCommercial } from '../../../../services/apis/profiles/agents/commercialDetailsApi';
import ShowForPermissions from '../../../../ShowForPermissions';
import { fetchHeaderDataAgents } from 'src/store/reducers/agentReducer';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reducers';

interface ModalProps {
    display: boolean;
    isEdit: boolean;
    isFromView: boolean;
    setIsEdit: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setIsFromView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: any;
    mode: string;
    setMode: any;
    setData: any;
    selectedHeader: any;
    selectedFilter: any;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    perPageCount: number;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}
interface CommercialDetailForm {
    ad_docname: string;
    ad_version: string;
    ad_author: string;
    ad_forwardersignatory: string;
    ad_agentsignatory: string;
    ad_startdate: string;
    ad_enddate: string;
    ad_renewal: string;
    ad_agreementterms: any;
    ad_agreementtermsName: string;
    ad_agreementtermsPk: string;
    ad_agreementtermsvalue: string;
    ad_agreementtype: string;
    ad_service_type: string;
    isAir: boolean;
    isSea: boolean;
    isRail: boolean;
    isRoad: boolean;
    ad_file?: object | null;
}

const Index: React.FC<ModalProps> = (props) => {
    const { id } = useParams();
    const navigate = useNavigate();
    // const [disable, setdisable] = useState(false);
    const [file, setFile] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showRenewal, setShowRenewal] = useState(false);
    const [showServices, setShowServices] = useState(false);
    const [showServicesCount, setShowServicesCount] = useState(0);
    const [_showServicesError, setShowServicesError] = useState(false);
    const [showAgreementTerms, setShowAgreementTerms] = useState(false);
    const airCheckboxRef = useRef<HTMLInputElement>(null);
    const seaCheckboxRef = useRef<HTMLInputElement>(null);
    const railCheckboxRef = useRef<HTMLInputElement>(null);
    const roadCheckboxRef = useRef<HTMLInputElement>(null);
    const { agentHeader } = useSelector((state: RootState) => state.agent);
    const initialValues: CommercialDetailForm = {
        ad_docname: props.data.ad_docname || '',
        ad_version: props.data.ad_version || '',
        ad_author: props.data.ad_author || '',
        ad_forwardersignatory: props.data.ad_forwardersignatory || '',
        ad_agentsignatory: props.data.ad_agentsignatory || '',
        ad_startdate: props.data.ad_startdate ? moment(props.data.ad_startdate).format('YYYY-MM-DD') : '',
        ad_enddate: props.data.ad_enddate ? moment(props.data.ad_enddate).format('YYYY-MM-DD') : '',
        ad_renewal: props.data.ad_renewal || '',
        ad_agreementterms: props.data.ad_agreementterms || {},
        ad_agreementtermsName:
            props.mode === 'edit' ? props.data.ad_agreementterms || '' : props.data.ad_agreementtermsName || '',
        ad_agreementtermsPk: props.data.ad_agreementtermsPk || '',
        ad_agreementtermsvalue: props.data.ad_agreementtermsvalue || '',
        ad_agreementtype: props.data.ad_agreementtype || '%',
        isAir: props.data.isAir === true || props.data.isAir === '1' ? true : false,
        isSea: props.data.isSea === true || props.data.isSea === '1' ? true : false,
        isRail: props.data.isRail === true || props.data.isRail === '1' ? true : false,
        isRoad: props.data.isRoad === true || props.data.isRoad === '1' ? true : false,
        ad_service_type: '',
        ad_file: null,
    };

    useEffect(() => {
        if (props.isEdit) {
            let count = 0;
            if (props.data.isAir === true || props.data.isAir === '1') {
                count = count + 1;
            }
            if (props.data.isSea === true || props.data.isSea === '1') {
                count = count + 1;
            }
            if (props.data.isRail === true || props.data.isRail === '1') {
                count = count + 1;
            }
            if (props.data.isRoad === true || props.data.isRoad === '1') {
                count = count + 1;
            }
            setShowServicesCount(count);
        } else if (props.mode === 'add') {
            setShowServicesCount(0);
        }
    }, [props.isEdit, props.mode]);

    const validationForAddOrEdit = () => {
        const validation =
            props.mode === 'edit'
                ? Yup.mixed().notRequired()
                : Yup.mixed()
                      .required('File is required')
                      .test({
                          name: 'fileSize',
                          exclusive: false,
                          params: {},
                          message: 'Maximum file size is 25mb',
                          test: function (value) {
                              return value && value.size <= FILE_SIZE;
                          },
                      })
                      .test({
                          name: 'fileType',
                          exclusive: false,
                          params: {},
                          message: 'File type is not supported',
                          test: function (value) {
                              return value && SUPPORTED_FORMATS.includes(value.type);
                          },
                      });

        return validation;
    };

    const CommercialDetailFormSchema = Yup.object().shape({
        ad_docname: Yup.string().required('Name is required'),
        ad_version: Yup.string().required('Version is required'),
        ad_author: Yup.string().required('Author is required'),
        ad_forwardersignatory: Yup.string().required('Forwarder signatory is required'),
        ad_agentsignatory: Yup.string().required('Agent signatory is required'),
        ad_startdate: Yup.date().required('Start date is required'),
        ad_enddate: Yup.date()
            .required('End date is required')
            .min(Yup.ref('ad_startdate'), 'End date must be greater than start date')
            .test({
                name: 'same',
                exclusive: false,
                params: {},
                message: 'End date must be greater than start date',
                test: function (value) {
                    const activeDate = moment(this.parent.ad_startdate).format('YYYY-MM-DD');
                    const endDate = moment(value).format('YYYY-MM-DD');
                    return !moment(activeDate).isSame(moment(endDate));
                },
            }),
        ad_renewal: Yup.string().required('Renewal is required'),
        ad_agreementtermsName: Yup.string().required('Agreement terms is required'),
        ad_agreementtermsvalue: Yup.string().required('Agreement terms value is required'),
        ad_service_type: Yup.string().required('Service type is required'),
        ad_file: validationForAddOrEdit(),
    });

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectCommercialDetailIsError);
    const isSubmitAndActivated = useAppSelector(selectCommercialDetailIsSubmitAndActivated);
    // const commercial = useAppSelector(selectCommercialDetailUser);
    const commercialError = useAppSelector(selectCommercialDetailError);
    const commercialSuccess = useAppSelector(selectCommercialDetailSuccess);

    const currentPage = useAppSelector(selectCommercialDetailCurrentPage);
    // const perPageCount = useAppSelector(selectCommercialDetailPerPageCount);
    const commercialAuthor = useAppSelector(selectCommercialDetailAuthor);
    // const commercialForwarderSignatory = useAppSelector(selectCommercialDetailForwarderSignatory);
    const commercialForwarderSignatory = useAppSelector(selectCommercialDetailAuthor);
    const commercialAgreementTerms = useAppSelector(selectCommercialDetailAgreementTerms);

    // const [selectedFilter, setselectedFilter] = useState<any | null>(null);

    const payload = {
        id,
        currentPage: currentPage,
        perPageCount: props.perPageCount,
        columnStatus: props.selectedHeader,
        statusFilter: props.selectedFilter,
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: CommercialDetailFormSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting && !props.isFromView) {
                setIsLoading(true);
                if (showServicesCount === 0) {
                    setShowServicesError(true);
                    return;
                }
                if (props.mode === 'add') {
                    const formData = new FormData();
                    values.ad_agreementterms = {
                        term: values.ad_agreementtermsPk,
                        value: values.ad_agreementtermsvalue,
                    };
                    const data = { ...values, ag_pk: id, or_PK: agentHeader?.or_PK };
                    delete data['ad_file'];
                    formData.append('document', new Blob([JSON.stringify(data)], { type: 'application/json' }));
                    formData.append('file', file, file.name);
                    dispatch(createCommercialDetails(formData));
                } else {
                    values.ad_agreementterms = {
                        term: values.ad_agreementtermsPk,
                        value: values.ad_agreementtermsvalue,
                    };
                    const editPayload = {
                        ad_AG: id,
                        ad_PK: props.data.ad_adpk,
                        ad_docname: values.ad_docname,
                        ad_version: values.ad_version,
                        ad_author: values.ad_author,
                        ad_forwardersignatory: values.ad_forwardersignatory,
                        ad_agentsignatory: values.ad_agentsignatory,
                        ad_startdate: values.ad_startdate,
                        ad_enddate: values.ad_enddate,
                        ad_renewal: values.ad_renewal,
                        ad_isAir: values.isAir ? 1 : 0,
                        ad_isSea: values.isSea ? 1 : 0,
                        ad_isRail: values.isRail ? 1 : 0,
                        ad_isRoad: values.isRoad ? 1 : 0,
                        ad_AT: values.ad_agreementtermsPk,
                        ad_agreementTermsValue: values.ad_agreementtermsvalue,
                    };
                    dispatch(updateCommercialDetails({ ...editPayload }));
                }
            }
            props.setIsFromView(false);
        },
    });

    useEffect(() => {
        const hiddenItems = [
            'ad_author',
            'ad_forwardersignatory',
            'ad_renewal',
            'ad_service_type',
            'ad_agreementtermsName',
        ];
        const nearestItem = [
            'ad_author_div',
            'ad_forwardersignatory_div',
            'ad_renewal_div',
            'ad_services_div',
            'ad_agreement_terms_div',
        ];
        if (!formik.isSubmitting) {
            return;
        }

        if (Object.keys(formik.errors).length > 0) {
            const fieldName = Object.keys(formik.errors)[0];
            const index = hiddenItems.indexOf(fieldName);
            if (index !== -1) {
                document?.getElementById(nearestItem[index])?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
                document?.getElementsByName(fieldName)[0].scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        }
    }, [formik]);

    useEffect(() => {
        setIsLoading(false);
        if (isError) {
            // if (commercialError?.status != 404) {
            error(commercialError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
            document?.getElementById('name_div')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            // }
        } else if (
            (commercialSuccess?.status === 200 || commercialSuccess?.status === 201) &&
            isSubmitAndActivated === false
        ) {
            success(
                props.mode === 'add'
                    ? `${commercialSuccess?.data?.ad_docname}, ${commercialSuccess?.data?.ad_version} has been added`
                    : `${commercialSuccess?.data?.AD_DocName}, ${commercialSuccess?.data?.AD_Version} has been updated`,
                Position.TOP_RIGHT
            );
            // dispatch(fetchCommercialDetailsList(payload));
            dispatch(fetchHeaderDataAgents(id));
            props.displayFunction(false);
            formik.resetForm();
            removeFile();
            dispatch(clearFailure([]));
            $('.k-i-reload').click();
        } else if (isSubmitAndActivated) {
            dispatch(clearFailure([]));
            success('Agent has been activated', Position.TOP_RIGHT);
            setTimeout(() => navigate('/agent'), 2000);
        }
    }, [isError, commercialSuccess, commercialError, isSubmitAndActivated]);

    useEffect(() => {
        if (!props.isEdit) {
            formik.resetForm();
            removeFile();
            setShowServicesCount(0);
        }
    }, [props.display]);

    const fileUpload = (e: any) => {
        if (e.target.files[0]) {
            formik.setFieldValue('ad_file', e.target.files[0]);
            setFile(e.target.files[0]);
        }
    };

    const removeFile = () => {
        setFile(null);
        formik.setFieldValue('ad_file', null);
    };

    const getFileSize = (size: number) => {
        let totalSize = '';
        if (size > 1048576) {
            totalSize = (size / Math.pow(1024, 2)).toFixed(2);
            return `${totalSize} Mb`;
        }
        totalSize = (size / Math.pow(1024, 1)).toFixed(2);
        return `${totalSize} Kb`;
    };

    const getFileSizeOfUploadedFile = (sizeInKb: number) => {
        let totalSize = sizeInKb.toString();
        if (sizeInKb > 1024) {
            totalSize = (sizeInKb / 1024).toFixed(2);
            return `${totalSize} Mb`;
        }
        return `${totalSize} Kb`;
    };

    const [distanceForServices, setDistanceForServices] = useState(0);
    const [distanceForAgreement, setDistanceForAgreement] = useState(0);
    const [widthForServices, setWidthForServices] = useState(0);
    const [widthForAgreement, setWidthForAgreement] = useState(0);
    const resizeFilter = () => {
        const el_modal_body = document.getElementById('modal_body');
        let modalBodyDistance = 0;
        if (el_modal_body?.getBoundingClientRect().top) {
            modalBodyDistance = el_modal_body?.getBoundingClientRect().top - 40;
        }

        const el_ad_agreement_terms = document.getElementById('ad_agreement_terms_div');
        const el_agreement_dropdown_div = document.getElementById('agreement_dropdown_div');

        if (el_agreement_dropdown_div?.getBoundingClientRect().width) {
            setWidthForAgreement(el_agreement_dropdown_div?.getBoundingClientRect().width);
        }
        if (el_ad_agreement_terms?.getBoundingClientRect().top) {
            setDistanceForAgreement(el_ad_agreement_terms?.getBoundingClientRect().top - modalBodyDistance);
        }

        const el_ad_services = document.getElementById('ad_services_div');
        if (el_ad_services?.getBoundingClientRect().width) {
            setWidthForServices(el_ad_services?.getBoundingClientRect().width);
        }
        if (el_ad_services?.getBoundingClientRect().top) {
            setDistanceForServices(el_ad_services?.getBoundingClientRect().top - modalBodyDistance);
        }
    };

    return (
        <>
            {props.display ? (
                <>
                    <div
                        onClick={() => {
                            setShowRenewal(false);
                            setShowAgreementTerms(false);
                            setShowServices(false);
                        }}
                        className="app-modal agents-add-commercial-modal"
                    >
                        <div id="modal_body" className="modal-content-holder medium-popup ">
                            <div className="modal-header">
                                {props.isFromView === false && props.isEdit === true ? (
                                    <div className="title">Commercial Details</div>
                                ) : (
                                    <div className="title">{props.mode === 'add' ? 'Add' : 'Edit'} Commercial</div>
                                )}

                                <button
                                    onClick={() => {
                                        // props.displayFunction(false);
                                        props.setIsFromView(false);
                                        formik.dirty && !props.isEdit
                                            ? props.setDisplayCancel(true)
                                            : props.displayFunction(false);
                                    }}
                                    className="app-btn modal-close-btn"
                                >
                                    <span>
                                        <svg className="svg-icon modal-header-close-icon">
                                            <use href="#closeIcon">
                                                <title>Close</title>
                                            </use>
                                        </svg>
                                    </span>
                                </button>
                            </div>
                            <div
                                onScroll={() => {
                                    setShowAgreementTerms(false);
                                    setShowServices(false);
                                    resizeFilter();
                                }}
                                className="modal-content"
                            >
                                <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                                    <svg className="svg-icon loader-icon">
                                        <use xlinkHref="#loaderIcon">
                                            <title>Loading</title>
                                        </use>
                                    </svg>
                                </div>
                                <div className="modal-main-content agent-commercial-detail-modal-main-content">
                                    {props.isEdit ? (
                                        <>
                                            <div className="layout-holder two-column">
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Name</h6>
                                                    <div className="description-type-1">{props.data.ad_docname}</div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Version</h6>
                                                    <div className="description-type-1">{props.data.ad_version}</div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Author</h6>
                                                    <div className="description-type-1">{props.data.ad_author}</div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Forwarder Signatory</h6>
                                                    <div className="description-type-1">
                                                        {props.data.ad_forwardersignatory}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Agent Signatory</h6>
                                                    <div className="description-type-1">
                                                        {props.data.ad_agentsignatory}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Active Date</h6>
                                                    <div className="description-type-1">
                                                        {moment(props.data.ad_startdate).format('DD-MM-YYYY')}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">End Date</h6>
                                                    <div className="description-type-1">
                                                        {moment(props.data.ad_enddate).format('DD-MM-YYYY')}
                                                    </div>
                                                </div>
                                                {/* <div className="layout-item">
                                                <h6 className="heading-type-1">Renewal</h6>
                                                <div className="description-type-1">{props.data.ad_renewal}</div>
                                            </div> */}
                                                {/* <div className="layout-item">
                                                <h6 className="heading-type-1">Services</h6>
                                                <div className="description-type-1">Air, Sea, Rail </div>
                                            </div> */}
                                                <div className="layout-item">
                                                    <h6 className="heading-type-1">Agreement Terms</h6>
                                                    <div className="description-type-1">
                                                        {props.data.ad_agreementtermsvalue}
                                                        {props.data.ad_agreementtype === 'percentage' ? '%' : '$'}{' '}
                                                        {props.data.ad_agreementterms}
                                                    </div>
                                                </div>
                                                {props.data.fileName ? (
                                                    <div className="layout-item">
                                                        <h6 className="heading-type-1">Attachments</h6>
                                                        <div className="content-with-icon">
                                                            <button
                                                                onClick={() => {
                                                                    if (props.data.fileName) {
                                                                        downloadAgentCommercial(
                                                                            props.data.ad_adpk,
                                                                            props.data.fileName
                                                                        );
                                                                    }
                                                                }}
                                                                className="app-btn "
                                                                // title="Attach file"
                                                            >
                                                                <svg className="svg-icon file-attachment-icon ">
                                                                    <use xlinkHref="#fileAttachmentIcon"></use>
                                                                    {/* <title>Attach file</title> */}
                                                                </svg>
                                                            </button>
                                                            <div>
                                                                {/* file-uploaded class to higlight data with blue color */}
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    onClick={() => {
                                                                        if (props.data.fileName) {
                                                                            downloadAgentCommercial(
                                                                                props.data.ad_adpk,
                                                                                props.data.fileName
                                                                            );
                                                                        }
                                                                    }}
                                                                    className="description-type-1 file-uploaded"
                                                                >
                                                                    {props.data.fileName?.length > 20
                                                                        ? `${props.data.fileName?.slice(0, 20)}...`
                                                                        : props.data.fileName}
                                                                </a>
                                                                {props.data.savedFileSizeInKb ? (
                                                                    <div className="heading-type-1">
                                                                        {getFileSizeOfUploadedFile(
                                                                            props.data.savedFileSizeInKb
                                                                        )}
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <form
                                                id="my-form"
                                                action=""
                                                onSubmit={formik.handleSubmit}
                                                className="modal-form layout-holder one-column"
                                            >
                                                <div id="name_div" className=" modal-input-holder ">
                                                    <label htmlFor="name" className="modal-input-label">
                                                        Name<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <input
                                                            placeholder="Enter Commercial Name"
                                                            type="text"
                                                            className="modal-input input-text"
                                                            id="ad_docname"
                                                            name="ad_docname"
                                                            onChange={(event) => {
                                                                if (/[^a-zA-Z0-9_ ]/.test(event.target.value)) {
                                                                    event.preventDefault();
                                                                } else {
                                                                    formik.setFieldError(event.target.name, undefined);
                                                                    formik.handleChange(event);
                                                                }
                                                            }}
                                                            onKeyPress={(event) => {
                                                                if (/[^a-zA-Z0-9_ ]/.test(event.key)) {
                                                                    event.preventDefault();
                                                                }
                                                            }}
                                                            autoComplete="off"
                                                            maxLength={255}
                                                            value={formik.values.ad_docname}
                                                        ></input>
                                                        {!props.isFromView && formik.errors.ad_docname ? (
                                                            <div className="error-text">{formik.errors.ad_docname}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder ">
                                                    <label htmlFor="name" className="modal-input-label">
                                                        Version<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <input
                                                            placeholder="Enter Version Name"
                                                            type="text"
                                                            className="modal-input input-text"
                                                            id="ad_version"
                                                            name="ad_version"
                                                            onChange={(event) => {
                                                                formik.setFieldError(event.target.name, undefined);
                                                                formik.handleChange(event);
                                                            }}
                                                            maxLength={16}
                                                            value={formik.values.ad_version}
                                                        ></input>
                                                        {!props.isFromView && formik.errors.ad_version ? (
                                                            <div className="error-text">{formik.errors.ad_version}</div>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Author<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div id="ad_author_div" className="modal-input-layout-item">
                                                        <SearchableSelectBox
                                                            data={
                                                                commercialAuthor.data?.length > 0 &&
                                                                commercialAuthor?.data
                                                            }
                                                            placeholder="Select Author"
                                                            formik={formik}
                                                            fieldName="ad_author"
                                                        />
                                                        {!props.isFromView && formik.errors.ad_author ? (
                                                            <div className="error-text">{formik.errors.ad_author}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Forwarder Signatory
                                                        <span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div
                                                        id="ad_forwardersignatory_div"
                                                        className="modal-input-layout-item"
                                                    >
                                                        <SearchableSelectBox
                                                            data={
                                                                commercialForwarderSignatory?.data?.length > 0 &&
                                                                commercialForwarderSignatory?.data
                                                            }
                                                            placeholder="Select Forwarder Signatory"
                                                            formik={formik}
                                                            fieldName="ad_forwardersignatory"
                                                        />
                                                        {!props.isFromView && formik.errors.ad_forwardersignatory ? (
                                                            <div className="error-text">
                                                                {formik.errors.ad_forwardersignatory}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Agent Signatory<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item">
                                                        <input
                                                            placeholder="Enter Agent Signatory"
                                                            type="text"
                                                            className="modal-input input-text"
                                                            id="ad_agentsignatory"
                                                            name="ad_agentsignatory"
                                                            onChange={(event) => {
                                                                formik.setFieldError(event.target.name, undefined);
                                                                formik.handleChange(event);
                                                            }}
                                                            maxLength={150}
                                                            value={formik.values.ad_agentsignatory}
                                                        ></input>
                                                        {!props.isFromView && formik.errors.ad_agentsignatory ? (
                                                            <div className="error-text">
                                                                {formik.errors.ad_agentsignatory}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Start Date<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item date-input-holder">
                                                        <DatePicker
                                                            formik={formik}
                                                            fieldName="ad_startdate"
                                                            portalId="date-portal"
                                                            maxDate={moment(formik.values.ad_enddate)
                                                                .subtract(1, 'day')
                                                                .toDate()}
                                                        />

                                                        {!props.isFromView && formik.errors.ad_startdate ? (
                                                            <div className="error-text">
                                                                {formik.errors.ad_startdate}
                                                            </div>
                                                        ) : null}

                                                        {/* <svg className="svg-icon calendar-icon">
                                                <use xlinkHref="#calendarIcon">
                                                    <title>Calendar</title>
                                                </use>
                                            </svg> */}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        End Date<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div className="modal-input-layout-item date-input-holder">
                                                        <DatePicker
                                                            disabled={props.data.ad_status === 'Expired'}
                                                            formik={formik}
                                                            fieldName="ad_enddate"
                                                            portalId="date-portal"
                                                            minDate={moment(formik.values.ad_startdate)
                                                                .add(1, 'day')
                                                                .toDate()}
                                                        />

                                                        {!props.isFromView && formik.errors.ad_enddate ? (
                                                            <div className="error-text">{formik.errors.ad_enddate}</div>
                                                        ) : null}

                                                        {/* <svg className="svg-icon calendar-icon">
                                                <use xlinkHref="#calendarIcon">
                                                    <title>Calendar</title>
                                                </use>
                                            </svg> */}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Renewal<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div id="ad_renewal_div" className="modal-input-layout-item">
                                                        <div
                                                            className={
                                                                showRenewal
                                                                    ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                    : 'dropdown-wrap  menu-down modal-dropdown'
                                                            }
                                                        >
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowRenewal((prev) => !prev);
                                                                    setShowServices(false);
                                                                    setShowAgreementTerms(false);
                                                                }}
                                                                type="button"
                                                                className="menu-btn app-btn select-modal"
                                                            >
                                                                <span className="btn-text">
                                                                    {formik.values.ad_renewal}
                                                                </span>
                                                                <span className="placeholder-text">
                                                                    Select Renewal Type
                                                                </span>
                                                                <span className="dropdown-arrow">
                                                                    <svg className="svg-icon arrow-icon">
                                                                        <use xlinkHref="#downArrow">
                                                                            <title>dropdown</title>
                                                                        </use>
                                                                    </svg>
                                                                </span>
                                                            </button>

                                                            <div className="dropdown-menu">
                                                                {/* <a
                                                                    onClick={() => {
                                                                        formik.setFieldError('ad_renewal', undefined);
                                                                        formik.setFieldValue('ad_renewal', 'Auto');
                                                                    }}
                                                                    className="menu-item app-btn"
                                                                >
                                                                    <span className="main-content">Auto</span>
                                                                </a> */}
                                                                <a
                                                                    onClick={() => {
                                                                        formik.setFieldError('ad_renewal', undefined);
                                                                        formik.setFieldValue('ad_renewal', 'Manual');
                                                                    }}
                                                                    className="menu-item app-btn"
                                                                >
                                                                    <span className="main-content">Manual</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        {!props.isFromView && formik.errors.ad_renewal ? (
                                                            <div className="error-text">{formik.errors.ad_renewal}</div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Services<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div id="ad_services_div" className="modal-input-layout-item">
                                                        {/* dropdown menu items are outside this block, need to set its position dynamically */}
                                                        <div
                                                            className={
                                                                showServices
                                                                    ? 'dropdown-wrap menu-down dropdown-open modal-dropdown'
                                                                    : 'dropdown-wrap menu-down modal-dropdown'
                                                            }
                                                        >
                                                            <button
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setShowServices((prev) => !prev);
                                                                    setShowRenewal(false);
                                                                    setShowAgreementTerms(false);
                                                                }}
                                                                type="button"
                                                                className="menu-btn app-btn select-modal"
                                                            >
                                                                <span className="btn-text">
                                                                    {showServicesCount > 0 &&
                                                                        showServicesCount + ' selected'}
                                                                </span>
                                                                <span className="placeholder-text">
                                                                    Select Services Type
                                                                </span>
                                                                <span className="dropdown-arrow">
                                                                    <svg className="svg-icon arrow-icon">
                                                                        <use xlinkHref="#downArrow">
                                                                            <title>dropdown</title>
                                                                        </use>
                                                                    </svg>
                                                                </span>
                                                            </button>
                                                        </div>

                                                        {/* Search suggestions capsule */}
                                                        <div className="suggestion-container">
                                                            {formik.values.isAir && (
                                                                <div className="content-card">
                                                                    <span className="main-content">Air</span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            formik.setFieldValue('isAir', false);
                                                                            setShowServicesCount(
                                                                                (prev: number) => prev - 1
                                                                            );
                                                                        }}
                                                                        className="app-btn close-btn"
                                                                        title="Remove"
                                                                    >
                                                                        <span>
                                                                            <svg className="svg-icon suggestion-delete-icon">
                                                                                <use href="#deleteIcon">
                                                                                    <title>Remove</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            )}

                                                            {formik.values.isSea && (
                                                                <div className="content-card">
                                                                    <span className="main-content">Sea</span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            formik.setFieldValue('isSea', false);
                                                                            setShowServicesCount(
                                                                                (prev: number) => prev - 1
                                                                            );
                                                                        }}
                                                                        className="app-btn close-btn"
                                                                        title="Remove"
                                                                    >
                                                                        <span>
                                                                            <svg className="svg-icon suggestion-delete-icon">
                                                                                <use href="#deleteIcon">
                                                                                    <title>Remove</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            )}
                                                            {formik.values.isRail && (
                                                                <div className="content-card">
                                                                    <span className="main-content">Rail</span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            formik.setFieldValue('isRail', false);
                                                                            setShowServicesCount(
                                                                                (prev: number) => prev - 1
                                                                            );
                                                                        }}
                                                                        className="app-btn close-btn"
                                                                        title="Remove"
                                                                    >
                                                                        <span>
                                                                            <svg className="svg-icon suggestion-delete-icon">
                                                                                <use href="#deleteIcon">
                                                                                    <title>Remove</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            )}

                                                            {formik.values.isRoad && (
                                                                <div className="content-card">
                                                                    <span className="main-content">Road</span>
                                                                    <button
                                                                        type="button"
                                                                        onClick={() => {
                                                                            formik.setFieldValue('isRoad', false);
                                                                            setShowServicesCount(
                                                                                (prev: number) => prev - 1
                                                                            );
                                                                        }}
                                                                        className="app-btn close-btn"
                                                                        title="Remove"
                                                                    >
                                                                        <span>
                                                                            <svg className="svg-icon suggestion-delete-icon">
                                                                                <use href="#deleteIcon">
                                                                                    <title>Remove</title>
                                                                                </use>
                                                                            </svg>
                                                                        </span>
                                                                    </button>
                                                                </div>
                                                            )}
                                                        </div>
                                                        {!props.isFromView && formik.errors.ad_service_type ? (
                                                            <div className="error-text">
                                                                {formik.errors.ad_service_type}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    <label className="modal-input-label">
                                                        Agreement Terms<span className="mandatory-field-text">*</span>
                                                    </label>
                                                    <div
                                                        id="ad_agreement_terms_div"
                                                        className="modal-input-layout-item"
                                                    >
                                                        <div className="agreement-term-wrapper">
                                                            {/* dropdown menu items are outside this block, need to set its position dynamically */}
                                                            <div
                                                                id="agreement_dropdown_div"
                                                                className={
                                                                    showAgreementTerms
                                                                        ? 'dropdown-wrap  menu-down dropdown-open modal-dropdown'
                                                                        : 'dropdown-wrap  menu-down modal-dropdown'
                                                                }
                                                            >
                                                                <button
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        setShowAgreementTerms((prev) => !prev);
                                                                        setShowRenewal(false);
                                                                        setShowServices(false);
                                                                    }}
                                                                    type="button"
                                                                    className="menu-btn app-btn select-modal"
                                                                >
                                                                    <span className="btn-text">
                                                                        {formik.values.ad_agreementtermsName}
                                                                    </span>
                                                                    <span className="placeholder-text">
                                                                        Select Agreement Term
                                                                    </span>
                                                                    <span className="dropdown-arrow">
                                                                        <svg className="svg-icon arrow-icon">
                                                                            <use xlinkHref="#downArrow">
                                                                                <title>dropdown</title>
                                                                            </use>
                                                                        </svg>
                                                                    </span>
                                                                </button>
                                                            </div>
                                                            <div className="modal-input-layout-item agreement-terms-data-holder unit-textfield-holder">
                                                                <span
                                                                    className="unit-style"
                                                                    data-unit-value={
                                                                        formik.values.ad_agreementtype === 'percentage'
                                                                            ? '%'
                                                                            : '$'
                                                                    }
                                                                ></span>
                                                                <input
                                                                    placeholder="Enter value"
                                                                    type="text"
                                                                    className="modal-input input-text"
                                                                    id="ad_agreementtermsvalue"
                                                                    name="ad_agreementtermsvalue"
                                                                    onChange={(event) => {
                                                                        formik.setFieldError(
                                                                            event.target.name,
                                                                            undefined
                                                                        );
                                                                        formik.handleChange(event);
                                                                    }}
                                                                    value={formik.values.ad_agreementtermsvalue}
                                                                    onKeyPress={(event) => {
                                                                        if (!/[0-9]/.test(event.key)) {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                ></input>
                                                            </div>
                                                        </div>
                                                        {!props.isFromView && formik.errors.ad_agreementtermsName ? (
                                                            <div className="error-text">
                                                                {formik.errors.ad_agreementtermsName}
                                                            </div>
                                                        ) : null}
                                                        {!props.isFromView && formik.errors.ad_agreementtermsvalue ? (
                                                            <div className="error-text">
                                                                {formik.errors.ad_agreementtermsvalue}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className=" modal-input-holder">
                                                    {props.mode === 'add' ||
                                                    (props.mode === 'edit' && props.data.fileName) ? (
                                                        <label htmlFor="name" className="modal-input-label">
                                                            Attachments<span className="mandatory-field-text">*</span>
                                                        </label>
                                                    ) : null}
                                                    <div className="modal-input-layout-item">
                                                        {props.mode === 'add' ? (
                                                            <>
                                                                <div className="browse-btn-wrapper">
                                                                    <input
                                                                        type={'file'}
                                                                        id={'ad_file'}
                                                                        name={'ad_file'}
                                                                        accept={
                                                                            '.doc,.docx,.mpp,.mppx,.pdf,.ppsx,.ppt,.pptx,.vsd,.vsdx,.xls,.xlsx'
                                                                        }
                                                                        className="browse-input"
                                                                        onClick={(e) => {
                                                                            (e.target as HTMLInputElement).value = '';
                                                                        }}
                                                                        onChange={(e) => {
                                                                            formik.setFieldError(
                                                                                e.target.name,
                                                                                undefined
                                                                            );
                                                                            fileUpload(e);
                                                                        }}
                                                                    ></input>
                                                                    <button
                                                                        className="app-btn app-btn-secondary icon-button browse-btn"
                                                                        title="Upload file"
                                                                    >
                                                                        <svg className="svg-icon plus-icon">
                                                                            <use xlinkHref="#plusIcon">
                                                                                <title>Upload File</title>
                                                                            </use>
                                                                        </svg>
                                                                        <span className="button-text upload-btn">
                                                                            Upload File
                                                                        </span>
                                                                    </button>
                                                                    {!props.isFromView && formik.errors.ad_file ? (
                                                                        <div className="error-text">
                                                                            {formik.errors.ad_file}
                                                                        </div>
                                                                    ) : null}
                                                                </div>

                                                                <div className="heading-type-1 file-size">
                                                                    <p>{fileUploadInfoText}</p>
                                                                    <p
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: fileTypesInfoText,
                                                                        }}
                                                                    ></p>
                                                                </div>
                                                            </>
                                                        ) : null}

                                                        {file && props.mode === 'add' ? (
                                                            <div className="content-with-icon  attachment-holder">
                                                                <button
                                                                    type="button"
                                                                    className="app-btn "
                                                                    title="Attach file"
                                                                >
                                                                    <svg className="svg-icon file-attachment-icon ">
                                                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                                                        <title>Attach file</title>
                                                                    </svg>
                                                                </button>
                                                                <div>
                                                                    {/* file-uploaded class to higlight data with blue color */}
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        className="description-type-1 file-uploaded"
                                                                        title="File"
                                                                    >
                                                                        {file?.name?.length > 20
                                                                            ? `${file?.name?.slice(0, 20)}...`
                                                                            : file?.name}
                                                                    </a>
                                                                    <div className="heading-type-1">
                                                                        {getFileSize(file?.size)}
                                                                    </div>
                                                                </div>
                                                                <a
                                                                    href="javascript:void(0)"
                                                                    className="file-remove-link"
                                                                    title="Remove"
                                                                    onClick={() => removeFile()}
                                                                >
                                                                    Remove
                                                                </a>
                                                            </div>
                                                        ) : null}
                                                        {props.mode === 'edit' && props.data.fileName ? (
                                                            <div className="content-with-icon  attachment-holder">
                                                                <button
                                                                    onClick={() => {
                                                                        if (props.data.fileName) {
                                                                            downloadAgentCommercial(
                                                                                props.data.ad_adpk,
                                                                                props.data.fileName
                                                                            );
                                                                        }
                                                                    }}
                                                                    className="app-btn "
                                                                >
                                                                    <svg className="svg-icon file-attachment-icon ">
                                                                        <use xlinkHref="#fileAttachmentIcon"></use>
                                                                        {/* <title>Attach file</title> */}
                                                                    </svg>
                                                                </button>
                                                                <div>
                                                                    {/* file-uploaded class to higlight data with blue color */}
                                                                    <a
                                                                        href="javascript:void(0)"
                                                                        onClick={() => {
                                                                            if (props.data.fileName) {
                                                                                downloadAgentCommercial(
                                                                                    props.data.ad_adpk,
                                                                                    props.data.fileName
                                                                                );
                                                                            }
                                                                        }}
                                                                        className="description-type-1 file-uploaded"
                                                                        title="File"
                                                                    >
                                                                        {props.data.fileName?.length > 20
                                                                            ? `${props.data.fileName?.slice(0, 20)}...`
                                                                            : props.data.fileName}
                                                                    </a>
                                                                    {props.data.savedFileSizeInKb ? (
                                                                        <div className="heading-type-1">
                                                                            {getFileSizeOfUploadedFile(
                                                                                props.data.savedFileSizeInKb
                                                                            )}
                                                                        </div>
                                                                    ) : null}
                                                                </div>
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </form>
                                        </>
                                    )}
                                </div>
                                {/* outside dropdown items , need to set top and left for this dynamically according to the component*/}
                                <div
                                    className={
                                        showServices
                                            ? 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                            : 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down modal-dropdown'
                                    }
                                    style={{ top: `${distanceForServices}px`, width: `${widthForServices}px` }}
                                >
                                    <div className="dropdown-menu ">
                                        <a
                                            onClick={(e) => {
                                                airCheckboxRef.current?.click();
                                                e.preventDefault();
                                            }}
                                            className="menu-item app-btn"
                                        >
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        ref={airCheckboxRef}
                                                        onChange={() => {
                                                            formik.setFieldError('ad_service_type', undefined);
                                                            if (formik.values.isAir) {
                                                                formik.setFieldValue('isAir', false);
                                                                setShowServicesCount((prev: number) => prev - 1);
                                                            } else {
                                                                formik.setFieldValue('isAir', true);
                                                                setShowServicesCount((prev: number) => prev + 1);
                                                            }
                                                        }}
                                                        checked={formik.values.isAir ? true : false}
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">Air</div>
                                                </label>
                                            </div>
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                seaCheckboxRef.current?.click();
                                                e.preventDefault();
                                            }}
                                            className="menu-item app-btn"
                                        >
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        ref={seaCheckboxRef}
                                                        onChange={() => {
                                                            formik.setFieldError('ad_service_type', undefined);
                                                            if (formik.values.isSea) {
                                                                formik.setFieldValue('isSea', false);
                                                                setShowServicesCount((prev: number) => prev - 1);
                                                            } else {
                                                                formik.setFieldValue('isSea', true);
                                                                setShowServicesCount((prev: number) => prev + 1);
                                                            }
                                                        }}
                                                        checked={formik.values.isSea ? true : false}
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">Sea</div>
                                                </label>
                                            </div>
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                railCheckboxRef.current?.click();
                                                e.preventDefault();
                                            }}
                                            className="menu-item app-btn"
                                        >
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        ref={railCheckboxRef}
                                                        onChange={() => {
                                                            formik.setFieldError('ad_service_type', undefined);
                                                            if (formik.values.isRail) {
                                                                formik.setFieldValue('isRail', false);
                                                                setShowServicesCount((prev: number) => prev - 1);
                                                            } else {
                                                                formik.setFieldValue('isRail', true);
                                                                setShowServicesCount((prev: number) => prev + 1);
                                                            }
                                                        }}
                                                        checked={formik.values.isRail ? true : false}
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">Rail</div>
                                                </label>
                                            </div>
                                        </a>
                                        <a
                                            onClick={(e) => {
                                                roadCheckboxRef.current?.click();
                                                e.preventDefault();
                                            }}
                                            className="menu-item app-btn"
                                        >
                                            <div className="checkbox-item">
                                                <label className="app-check-wrapper">
                                                    <input
                                                        ref={roadCheckboxRef}
                                                        onChange={() => {
                                                            formik.setFieldError('ad_service_type', undefined);
                                                            if (formik.values.isRoad) {
                                                                formik.setFieldValue('isRoad', false);
                                                                setShowServicesCount((prev: number) => prev - 1);
                                                            } else {
                                                                formik.setFieldValue('isRoad', true);
                                                                setShowServicesCount((prev: number) => prev + 1);
                                                            }
                                                        }}
                                                        checked={formik.values.isRoad ? true : false}
                                                        type="checkbox"
                                                        className="checkbox-input"
                                                    ></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="checkbox-label">Road</div>
                                                </label>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                {/* outside dropdown items , need to set top and left for this dynamically according to the component*/}
                                <div
                                    className={
                                        showAgreementTerms
                                            ? 'dropdown-wrap agreement-terms-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                            : 'dropdown-wrap agreement-terms-dropdown-wrap outside-menu menu-down modal-dropdown'
                                    }
                                    style={{ top: `${distanceForAgreement}px`, width: `${widthForAgreement}px` }}
                                >
                                    <div className="dropdown-menu">
                                        {commercialAgreementTerms?.data?.info?.length > 0 &&
                                            commercialAgreementTerms?.data?.info?.map((item: any, index: number) => (
                                                <a
                                                    onClick={() => {
                                                        formik.setFieldError('ad_agreementtermsName', undefined);
                                                        formik.setFieldValue('ad_agreementtermsPk', item.at_PK);
                                                        formik.setFieldValue(
                                                            'ad_agreementtermsName',
                                                            item.agreementTerm
                                                        );
                                                        formik.setFieldValue('ad_agreementtype', item.type);
                                                    }}
                                                    key={index}
                                                    className="menu-item app-btn"
                                                >
                                                    <span className="main-content">{item.agreementTerm}</span>
                                                </a>
                                            ))}
                                        {/* <a className="menu-item app-btn">
                                        <span className="main-content">Profit Share</span>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <span className="main-content">Handling Fee</span>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <span className="main-content">Agreement Term 3</span>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <span className="main-content">Agreement Term 4</span>
                                    </a>
                                    <a className="menu-item app-btn">
                                        <span className="main-content">Agreement Term 5</span>
                                    </a> */}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button
                                    onClick={() => {
                                        props.setIsFromView(false);
                                        formik.dirty && !props.isEdit
                                            ? props.setDisplayCancel(true)
                                            : props.displayFunction(false);
                                    }}
                                    className="app-btn app-btn-secondary modal-btn footer-btn"
                                    title="Cancel"
                                >
                                    <span className="button-text footer-button-text">Cancel</span>
                                </button>
                                <ShowForPermissions permission="edit" type="agent">
                                    {props.isEdit ? (
                                        <button
                                            style={{ marginLeft: '10px' }}
                                            className="app-btn app-btn-primary modal-btn footer-btn"
                                            type="button"
                                            // form="my-form"
                                            onClick={() => {
                                                props.setIsEdit(false);
                                                props.setIsFromView(true);
                                                props.setMode('edit');
                                            }}
                                        >
                                            <span className="button-text vendor-button-text">Edit</span>
                                        </button>
                                    ) : (
                                        <button
                                            style={{ marginLeft: '10px' }}
                                            className={`app-btn app-btn-primary modal-btn footer-btn add-commercial-footer-btn ${
                                                props.mode === 'add' ? 'large-btn' : ''
                                            }`}
                                            type="submit"
                                            form="my-form"
                                            onClick={() => {
                                                props.setIsFromView(false);
                                                showServicesCount > 0
                                                    ? formik.setFieldValue('ad_service_type', 'true')
                                                    : formik.setFieldValue('ad_service_type', '');
                                            }}
                                            // disabled={!formik.dirty}
                                        >
                                            <span className="button-text vendor-button-text">
                                                {props.mode === 'add' ? 'Add Commercial' : 'Save'}
                                            </span>
                                        </button>
                                    )}
                                </ShowForPermissions>
                            </div>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
