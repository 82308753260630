import { divIcon } from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';

let iconMarkup;
const Icons = (
    type: string,
    count: number,
    currentPosition?: any,
    selectedPosition?: any,
    sideBarView?: any,
    shippingMode?: string,
    clusterArrayData?: any,
    selectedClusterPosition?: any
) => {
    //const checkSelectedInCluster = () => {
    //    let flag = false;
    //    clusterArrayData?.length &&
    //        clusterArrayData?.forEach((element: any) => {
    //            if (JSON.stringify(selectedPosition) === JSON.stringify(element)) {
    //                flag = true;
    //            }
    //        });
    //    return flag;
    //};
    const blurTrue =
        JSON.stringify(currentPosition) != JSON.stringify(selectedPosition) &&
        selectedPosition?.length > 0 &&
        currentPosition?.length > 0;

    const blurTrueCluster =
        JSON.stringify(currentPosition) != JSON.stringify(selectedClusterPosition) &&
        currentPosition?.length > 0 &&
        selectedClusterPosition?.length > 0;

    const selectedTrue =
        JSON.stringify(currentPosition) == JSON.stringify(selectedPosition) &&
        selectedPosition?.length > 0 &&
        sideBarView;

    // In - btn-count - text-dark-pink , text-light-pink used to change the text color
    // In - location-icon -> add- bg-yellow,bg-light-green,bg-light-purple,bg-light-violet,bg-light-blue,bg-dark-pink,bg-light-pink
    switch (type) {
        case 'bookingsIntransitArray':
            iconMarkup = renderToStaticMarkup(
                <button
                    className={`app-btn btn-container map-icon ${
                        blurTrue || blurTrueCluster ? 'blur-icons' : 'non-blur-icons'
                    }`}
                >
                    <div
                        className={`btn-container-with-arrow ${
                            !selectedTrue ? 'bg-border-light-yellow' : 'bg-border-dark-yellow'
                        }`}
                    >
                        <div className="icon-holder bg-yellow">
                            {shippingMode?.toLowerCase() === 'sea' ? (
                                <svg className="svg-icon ship-icon">
                                    <use href="#shipIcon">
                                        <title>Ship</title>
                                    </use>
                                </svg>
                            ) : (
                                <svg className="svg-icon aeroplane-icon">
                                    <use href="#aeroplanefillIcon">
                                        <title>Aeroplane</title>
                                    </use>
                                </svg>
                            )}
                        </div>
                        <div className={`btn-count ${!selectedTrue ? 'text-black' : 'text-white'} `}>{count}</div>
                    </div>
                </button>
            );
            break;
        case 'bookingsAtOriginPort':
            iconMarkup = renderToStaticMarkup(
                <button
                    className={`app-btn btn-container map-icon ${
                        blurTrue || blurTrueCluster ? 'blur-icons' : 'non-blur-icons'
                    }`}
                >
                    <div
                        className={`btn-container-with-arrow active ${
                            !selectedTrue ? 'bg-border-light-purple' : 'bg-border-dark-purple'
                        }`}
                    >
                        <div className="icon-holder bg-light-purple">
                            <svg className="svg-icon note-icon">
                                <use href="#maphouseIcon">
                                    <title>Icon</title>
                                </use>
                            </svg>
                        </div>
                        <div className={`btn-count ${!selectedTrue ? 'text-black' : 'text-white'} `}>{count}</div>
                    </div>
                </button>
            );
            break;
        case 'bookingsAtDestinationPort':
            iconMarkup = renderToStaticMarkup(
                <button
                    className={`app-btn btn-container map-icon ${
                        blurTrue || blurTrueCluster ? 'blur-icons' : 'non-blur-icons'
                    }`}
                >
                    <div
                        className={`btn-container-with-arrow active ${
                            !selectedTrue ? 'bg-border-light-green' : 'bg-border-dark-green'
                        }`}
                    >
                        <div className="icon-holder bg-light-green">
                            <svg className="svg-icon note-icon">
                                <use href="#maphouseIcon">
                                    <title>Icon</title>
                                </use>
                            </svg>
                        </div>
                        <div className={`btn-count ${!selectedTrue ? 'text-black' : 'text-white'} `}>{count}</div>
                    </div>
                </button>
            );
            break;

        case 'posAtOriginCountry':
            iconMarkup = renderToStaticMarkup(
                <button
                    className={`app-btn btn-container map-icon ${
                        blurTrue || blurTrueCluster ? 'blur-icons' : 'non-blur-icons'
                    }`}
                >
                    <div
                        className={`btn-container-with-arrow active ${
                            !selectedTrue ? 'bg-border-light-purple' : 'bg-border-light-blue'
                        }`}
                    >
                        <div className="icon-holder bg-light-blue">
                            <svg className="svg-icon note-icon">
                                <use href="#mapnoteIcon">
                                    <title>Icon</title>
                                </use>
                            </svg>
                        </div>
                        <div className={`btn-count ${!selectedTrue ? 'text-black' : 'text-white'} `}>{count}</div>
                    </div>
                </button>
            );
            break;
        case 'bookingsAtOriginCountry':
            iconMarkup = renderToStaticMarkup(
                <button
                    className={`app-btn btn-container map-icon ${
                        blurTrue || blurTrueCluster ? 'blur-icons' : 'non-blur-icons'
                    }`}
                >
                    <div
                        className={`btn-container-with-arrow active ${
                            !selectedTrue ? 'bg-border-light-violet' : 'bg-border-dark-violet'
                        }`}
                    >
                        <div className="icon-holder bg-light-violet">
                            <svg className="svg-icon note-icon">
                                <use href="#mapcheckIcon">
                                    <title>Icon</title>
                                </use>
                            </svg>
                        </div>
                        <div className={`btn-count ${!selectedTrue ? 'text-black' : 'text-white'} `}>{count}</div>
                    </div>
                </button>
            );
            break;
        case 'cluster':
            iconMarkup = renderToStaticMarkup(
                <button
                    className={`app-btn btn-location-container map-icon ${
                        blurTrue || blurTrueCluster ? 'blur-icons' : 'non-blur-icons'
                    }`}
                >
                    <svg className="svg-icon location-icon bg-light-pink">
                        <use href="#locationLargeIcon">
                            <title>Location Ping Icon</title>
                        </use>
                    </svg>
                    <div className="icon-holder bg-white">
                        <div className="btn-count text-light-pink">{count}</div>
                    </div>
                </button>
            );
            break;
        default:
            iconMarkup = renderToStaticMarkup(
                <button
                    className={`app-btn btn-container map-icon ${
                        blurTrue || blurTrueCluster ? 'blur-icons' : 'non-blur-icons'
                    }`}
                >
                    <div className="btn-container-with-arrow active bg-border-light-purple">
                        <div className="icon-holder bg-light-violet">
                            <svg className="svg-icon note-icon">
                                <use href="#mapcheckIcon">
                                    <title>Icon</title>
                                </use>
                            </svg>
                        </div>
                        <div className="btn-count text-black">{count}</div>
                    </div>
                </button>
            );
            break;
    }
    const customMarkerIcon = divIcon({
        html: iconMarkup,
    });
    return customMarkerIcon;
};

export default Icons;
