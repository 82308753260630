import React, { useEffect, useState } from 'react';
import BookingProposalHeader from '../BookingProposalHeader';
import Breadcrumbs from 'src/components/Breadcrumbs/defaultBreadcrumbs';
import BookingProcessTab from '../BookingProcessTab';
import BookingAvailableGrid from '../BookingAvailableGrid';
// import BookingAddedGrid from '../BookingAddedGrid';
// import BookingHeaderSidebar from './../BookingHeaderSidebar';
import '../POSelection.scss';
import { RootState } from 'src/store/reducers';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
// import { fetchSelectedSailing } from 'src/store/reducers/bookingProposalLand/sailingReducer';
import FeedbackModal from 'src/components/common/FeedbackModal';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { updateAutoApprovalFailedViewStatus } from 'src/services/apis/bookingProposal/bookingProposalHeaderApi';
import { isLoadingHeaderFn } from 'src/store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { useDispatch } from 'react-redux';

const Index: React.FC = () => {
    const [_showBookingSideBar, setShowBookingSideBar] = useState(false);
    const [showAutoDeniedModal, setShowAutoDeniedModal] = useState<null | boolean>(false);
    const [showBookingCancelModal, setShowBookingCancelModal] = useState(false);
    const [showBookingCancelForDetachModal, setShowBookingCancelForDetachModal] = useState(false);
    // const { selectedSailing } = useSelector((state: RootState) => state.sailingRed);
    const headerData = useSelector((state: RootState) => state.bpHeaderData) as any;
    const [isAdded, setisAdded] = useState(false);
    const { id } = useParams();
    const { profileType } = useUserProfile();
    const dispatch = useDispatch();

    useEffect(() => {
        if (!id) {
            dispatch(isLoadingHeaderFn(false));
        }
    }, [id]);

    // const navigate = useNavigate() as any;

    // useEffect(() => {
    //     if (headerData?.tabValues?.po) {
    //         return navigate(`/land_booking_list/${id}/SailingSelectionDetails`);
    //     }
    //     if (headerData?.tabValues?.sailing) {
    //         return navigate(`/land_booking_list/${id}/additional-details`);
    //     }
    //     if (headerData?.tabValues?.additionalDetailsForForwarder || headerData?.tabValues?.additionalDetails) {
    //         return navigate(`/land_booking_list/${id}/container-allocation`);
    //     }
    //     if (headerData?.tabValues?.containerDetails) {
    //         return navigate(`/land_booking_list/${id}/attachments`);
    //     }
    //     if (headerData?.tabValues?.attachments) {
    //         return navigate(`/land_booking_list/${id}/attachments`);
    //     }
    // }, []);

    /* eslint-disable no-undefined */
    useEffect(() => {
        if (profileType === 'forwarder' && !headerData.isLoading) {
            setShowAutoDeniedModal(headerData?.autoApprovalFailedModalForwarder);
        } else if (profileType === 'client' && !headerData.isLoading) {
            setShowAutoDeniedModal(headerData?.autoApprovalFailedModalClient);
        }
    }, [headerData, profileType]);

    const handleClosAutoDeniedModal = () => {
        if (id) {
            updateAutoApprovalFailedViewStatus({ bpId: id, profileType });
            setShowAutoDeniedModal(false);
        }
    };
    const navData = [
        {
            title: 'PO Selection',
            selected: 1,
            path: id ? `/land_booking_list/${id}/POSelection` : '/land_booking_list/POSelection',
            disabled: false,
        },
        {
            title: 'Transport Schedule',
            selected: 0,
            path: `/land_booking_list/${id}/transport-schedule`,
            //disabled: !(headerData?.tabValues as any)?.po,
            disabled: false,
        },
        {
            title: 'Goods Received',
            selected: 0,
            path: `/land_booking_list/${id}/goods-received`,
            //disabled: !(headerData?.tabValues as any)?.sailing,
            disabled: false,
        },
    ];

    const handleClickCancel = () => {
        setShowBookingCancelModal(true);
    };
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder booking-proposal-header large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/land_booking_list'}
                    secondName={id ? 'Booking Proposal Details' : 'New Booking Proposal'}
                    secondUrl={'#'}
                    backUrl={'/land_booking_list'}
                    handleClickCancel={handleClickCancel}
                />
                <BookingProposalHeader
                    id={localStorage.getItem('bpNumber') || ''}
                    setShowBookingSideBar={setShowBookingSideBar}
                ></BookingProposalHeader>
                <BookingProcessTab navData={navData} />
            </div>
            {/* DATA GRID SECTION */}
            <BookingAvailableGrid
                setisAdded={setisAdded}
                showBookingCancelModal={showBookingCancelModal}
                setShowBookingCancelModal={setShowBookingCancelModal}
                showBookingCancelForDetachModal={showBookingCancelForDetachModal}
                setShowBookingCancelForDetachModal={setShowBookingCancelForDetachModal}
            />
            {/* Hidden on new feature 30/12/2022 */}
            {/* {showBookingSideBar && (
                <BookingHeaderSidebar setShowBookingSideBar={setShowBookingSideBar}></BookingHeaderSidebar>
            )} */}
            {showAutoDeniedModal && !headerData.isLoading && (
                <FeedbackModal
                    title="Auto Approval Denied"
                    description={
                        headerData?.autoApprovalFailureReason ||
                        "ETD for this Booking Proposal is not within the start and end ship window of one or more POs in this Booking Proposal. Booking Proposal requires client's approval to continue with booking"
                    }
                    handleClose={handleClosAutoDeniedModal}
                    okText="Okay"
                />
            )}
        </div>
    );
};
export default Index;
