import axios from 'axios';
import moment from 'moment';
import { getOrganisationData } from '../../../utils';
import { get, post, put } from '../../HttpMethods';

export const fetchDatesAoListDetailsApi = async (payload: any) => {
    try {
        const data = await get(`api/ord/ao/dates?aoId=${payload.id}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const createDatesAoDetailsApi = async (payload: any) => {
    try {
        const data = await post('api/ord/ao/dates', payload, null);
        return { data, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateDatesAoDetailsApi = async (payload: any) => {
    const type = payload.type;
    delete payload.type;
    if (type === 'edit') {
        delete payload.createdDate;
    }
    for (const key in payload) {
        if (key !== 'id' && key !== 'createdDate') {
            payload[key] = payload[key] !== '' ? moment(payload[key]).format('DD-MM-YYYY') : null;
        }
    }
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];
    try {
        const newPayload = { date: { ...payload }, notification: { roles, listOfUserOrganisations } };
        const data = await put(`api/ord/ao/dates?aoId=${payload.id}`, newPayload);
        data.type = type;
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
