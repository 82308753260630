import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'profilesVendors',
    initialState: {
        vendorsHeader: [],
    },
    reducers: {
        fetchHeaderDataVendors: (state, _action) => {
            console.log(state);
        },
        saveHeaderDataVendors: (state, action) => {
            state.vendorsHeader = {
                ...action.payload?.info?.tabValues,
                or_PK: action.payload?.info?.or_PK,
                vendorName: action.payload?.info?.vendorName,
            };
        },
    },
});

export const { fetchHeaderDataVendors, saveHeaderDataVendors } = dataSlice.actions;
export default dataSlice.reducer;
