/* eslint-disable no-undefined */
import React, { useEffect, useRef, useState } from 'react';
import '../../../BookingProposal/ContainerAllocations/ContainerDetails/container-details.scss';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    BpContainerDetail,
    updateBasicDetail,
    selectContainerAllocationIsError,
    selectContainerAllocationSuccess,
    selectContainerAllocationError,
    fetchContainerDetail,
    clearFailure,
    selectContainerType,
    selectDeliveryMode,
    selectContainerMode,
    selectPacking,
    fetchContainerOnlyBasicDetail,
    PackingList,
} from '../../../../store/reducers/bookingProposal/containerAllocationReducer';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { error, Position, success } from '../../../../utils/toast';
import { useNavigate } from 'react-router-dom';
import { decimalNumberValidation } from 'src/utils';
import { validateFractionInput } from 'src/utils/validateFractionInput';

interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setCancelModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    containerData: BpContainerDetail;
    bpId: any;
    allocationId: any;
    containerDetailsId: any;
}

interface BasicDetails {
    containerType: string;
    deliveryMode: string;
    containerMode: string;
    containerNumber: string;
    packing: string[];
    vgm: string;
    eir: string;
    sealNumber: string;
}

const Index: React.FC<ModalProps> = ({
    modalView,
    setModalView,
    containerData,
    bpId,
    allocationId,
    containerDetailsId,
    setCancelModalView,
}) => {
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [containerDropDownOpen, setContainerDropDownOpen] = useState<boolean>(false);
    const [deliveryDropDownOpen, setDeliveryDropDownOpen] = useState<boolean>(false);
    const [containerModeDropDownOpen, setContainerModeDropDownOpen] = useState<boolean>(false);
    const [packingDropDownOpen, setPackingDropDownOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectContainerAllocationIsError);
    const containerAllocationSuccess = useAppSelector(selectContainerAllocationSuccess);
    const ContainerAllocationError = useAppSelector(selectContainerAllocationError);
    const scrollElement = useRef<any>(null);
    const containerType = useAppSelector(selectContainerType);
    const deliveryMode = useAppSelector(selectDeliveryMode);
    const containerMode = useAppSelector(selectContainerMode);
    const packing = useAppSelector(selectPacking);

    const initialValues: BasicDetails = {
        containerType: containerData?.containerType || '',
        deliveryMode: containerData?.deliveryMode || '',
        containerMode: containerData?.containerMode || '',
        containerNumber: containerData?.containerNumber || '',
        packing: containerData?.packing || [],
        vgm: containerData?.vgm || '',
        eir: containerData?.eir || '',
        sealNumber: containerData?.sealNumber || '',
    };

    const BasicDetailsSchema = Yup.object().shape({
        containerType: Yup.string().required('Container Type is required'),
        deliveryMode: Yup.string().required('Delivery Mode is required'),
        containerMode: Yup.string().required('Container Mode is required'),
        containerNumber: Yup.string().required('Container Number is required'),
        packing: Yup.array().min(1, 'Packing is required'),
        vgm: Yup.string()
            .required('VGM is required')
            .test('equalszero', 'VGM must be greater than Zero', (value) => {
                if (value) {
                    const vgm = parseInt(value);
                    return vgm === 0 ? false : true;
                }
                return true;
            }),
        // .test('lesser', 'VGM must be lesser than 1000000', (value) => {
        //     if (value) {
        //         const vgm = parseInt(value);
        //         return vgm > 1000000 ? false : true;
        //     }
        //     return true;
        // }),
        // eir: Yup.string().required('EIR is required'),
        sealNumber: Yup.string().required('Seal Number is required'),
    });

    const formik = useFormik({
        initialValues,
        validationSchema: BasicDetailsSchema,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        // validateOnMount: modalView,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                setIsLoading(true);
                dispatch(
                    updateBasicDetail({
                        ...values,
                        vgm: parseFloat(values.vgm),
                        cdId: allocationId,
                        containerDetailsId: containerDetailsId ? containerDetailsId : null,
                        mode: containerData?.containerNumber ? 'edit' : 'add',
                        bpId,
                    })
                );
            }
        },
    });

    useEffect(() => {
        setIsLoading(false);
        if (isError && ContainerAllocationError?.data?.path?.includes('basic')) {
            error(ContainerAllocationError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (containerAllocationSuccess?.status === 200 || containerAllocationSuccess?.status === 201) {
            if (containerAllocationSuccess?.data?.type === 'basicDetail') {
                success(
                    `Basic details have been ${containerAllocationSuccess?.data?.mode === 'add' ? 'added' : 'updated'}`,
                    Position.TOP_RIGHT
                );
                if (containerAllocationSuccess?.data?.info?.containerDetailsId) {
                    dispatch(
                        fetchContainerDetail({
                            containerDetailsId: containerAllocationSuccess?.data?.info?.containerDetailsId,
                        })
                    );
                } else {
                    dispatch(fetchContainerOnlyBasicDetail({ containerId: allocationId }));
                }
                setModalView(false);
                formik.resetForm();
                dispatch(clearFailure([]));
                if (!containerDetailsId) {
                    navigate(`/Aobooking_list/${bpId}/container-allocation/${allocationId}/details`, {
                        state: { containerDetailsId: containerAllocationSuccess?.data?.info?.containerDetailsId },
                    });
                }
            }
        }
    }, [isError, containerAllocationSuccess, ContainerAllocationError]);

    useEffect(() => {
        if (!modalView) {
            formik.resetForm();
        }
    }, [modalView]);
    useEffect(() => {
        if (!formik.isValid) {
            scrollUp();
        }
    }, [formik.isValid]);
    const scrollUp = () => {
        let targetDiv;
        if (scrollElement.current) {
            if (formik.errors.containerNumber || formik.errors.packing) {
                targetDiv = document.getElementById('scroll1');
            } else if (formik.errors.vgm || formik.errors.sealNumber) {
                targetDiv = document.getElementById('scroll3');
            }
            if (targetDiv) {
                scrollElement.current.scrollTop = targetDiv.offsetTop;
            } else {
                scrollElement.current.scrollTop = 0;
            }
        }

        // scrollElement.current.scrollTop = 0;
    };
    const showError = (field: keyof typeof initialValues) => (
        <>{formik.errors[field] ? <div className="error-text">{formik.errors[field]}</div> : null}</>
    );

    const changeDropDown = (field: string) => {
        switch (field) {
            case 'containerType':
                setContainerDropDownOpen((prev) => !prev);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'deliveryMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen((prev) => !prev);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
            case 'containerMode':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen((prev) => !prev);
                setPackingDropDownOpen(false);
                break;
            case 'packing':
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen((prev) => !prev);
                break;
            default:
                setContainerDropDownOpen(false);
                setDeliveryDropDownOpen(false);
                setContainerModeDropDownOpen(false);
                setPackingDropDownOpen(false);
                break;
        }
    };

    const packingSelection = (res: PackingList) => {
        formik.setFieldError('packing', undefined);
        if (formik.values.packing.includes(res.packingCode)) {
            const packing = formik.values.packing.filter((el: string) => el !== res.packingCode);
            formik.setFieldValue('packing', packing);
        } else {
            const packing = [...formik.values.packing, res.packingCode];
            formik.setFieldValue('packing', packing);
        }
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                    <div
                        onClick={(e) => {
                            changeDropDown('');
                            e.stopPropagation();
                        }}
                        className="modal-content-holder basic-detail-content-holder medium-popup"
                    >
                        <div className="modal-header">
                            <div className="title">Basic Details</div>
                            <button
                                type="button"
                                className="app-btn modal-close-btn"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content" ref={scrollElement}>
                            <div className="modal-main-content bd-modal-main-content">
                                <form
                                    id="basic-details"
                                    action=""
                                    onSubmit={formik.handleSubmit}
                                    className="modal-form layout-holder one-column"
                                >
                                    <div className=" modal-input-holder" id="scroll1">
                                        <label className="modal-input-label">
                                            Container Type
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder grey-option-box">
                                            {/* Search suggestions dropdown */}
                                            <div
                                                className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                    containerDropDownOpen ? 'dropdown-open' : ''
                                                } `}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        changeDropDown('containerType');
                                                    }}
                                                    className="menu-btn app-btn select-modal"
                                                >
                                                    {formik.values.containerType ? (
                                                        <span className="button-text selected-text">
                                                            {formik.values.containerType}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">
                                                                Enter Container Type
                                                            </span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu contract-type-dropdown">
                                                    {containerType.map(
                                                        (res: { containerTypeId: string; containerType: string }) => (
                                                            <a
                                                                onClick={() => {
                                                                    if (
                                                                        res.containerType !==
                                                                        formik.values.containerType
                                                                    ) {
                                                                        formik.setFieldValue('deliveryMode', '');
                                                                        formik.setFieldValue('containerMode', '');
                                                                    }
                                                                    formik.setFieldError('containerType', undefined);
                                                                    formik.setFieldValue(
                                                                        'containerType',
                                                                        res.containerType
                                                                    );

                                                                    changeDropDown('containerType');
                                                                }}
                                                                key={res.containerTypeId}
                                                                className={`menu-item app-btn ${
                                                                    res.containerType === formik.values.containerType
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {res.containerType}
                                                            </a>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {showError('containerType')}
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Delivery Mode
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder grey-option-box">
                                            <div
                                                className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                    deliveryDropDownOpen ? 'dropdown-open' : ''
                                                } `}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        changeDropDown('deliveryMode');
                                                    }}
                                                    className="menu-btn app-btn select-modal"
                                                >
                                                    {formik.values.deliveryMode ? (
                                                        <span className="button-text selected-text">
                                                            {formik.values.deliveryMode}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">
                                                                Enter Delivery Mode
                                                            </span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {deliveryMode.map(
                                                        (res: { deliveryModeId: string; deliveryMode: string }) => (
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError('deliveryMode', undefined);
                                                                    formik.setFieldValue(
                                                                        'deliveryMode',
                                                                        res.deliveryMode
                                                                    );
                                                                    changeDropDown('deliveryMode');
                                                                }}
                                                                key={res.deliveryModeId}
                                                                className={`menu-item app-btn ${
                                                                    res.deliveryMode === formik.values.deliveryMode
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {res.deliveryMode}
                                                            </a>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                        {showError('deliveryMode')}
                                    </div>
                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Container Mode
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder grey-option-box">
                                            <div
                                                className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                    containerModeDropDownOpen ? 'dropdown-open' : ''
                                                } `}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        changeDropDown('containerMode');
                                                    }}
                                                    className="menu-btn app-btn select-modal"
                                                >
                                                    {formik.values.containerMode ? (
                                                        <span className="button-text selected-text">
                                                            {formik.values.containerMode}
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">
                                                                Enter Container Mode
                                                            </span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu">
                                                    {containerMode.map(
                                                        (res: { containerModeId: string; containerMode: string }) => (
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError('containerMode', undefined);
                                                                    formik.setFieldValue(
                                                                        'containerMode',
                                                                        res.containerMode
                                                                    );
                                                                    changeDropDown('containerMode');
                                                                }}
                                                                key={res.containerModeId}
                                                                className={`menu-item app-btn ${
                                                                    res.containerMode === formik.values.containerMode
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                {res.containerMode}
                                                            </a>
                                                        )
                                                    )}
                                                </div>
                                            </div>
                                            {showError('containerMode')}
                                        </div>
                                    </div>
                                    <div className="modal-input-holder">
                                        <label className="modal-input-label">
                                            Container Number
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Container Number"
                                                type="text"
                                                className="modal-input input-text"
                                                id="containerNumber"
                                                name="containerNumber"
                                                onChange={(event) => {
                                                    event.target.value
                                                        ? formik.setFieldError(event.target.name, undefined)
                                                        : formik.setFieldError(event.target.name, 'Field is Required');
                                                    formik.handleChange(event);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9A-Za-z]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                value={formik.values.containerNumber}
                                            />
                                        </div>
                                        {showError('containerNumber')}
                                    </div>

                                    <div className=" modal-input-holder">
                                        <label className="modal-input-label">
                                            Packing
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-field-holder grey-option-box modal-input-layout-item">
                                            <div
                                                className={`dropdown-wrap menu-down modal-dropdown ${
                                                    packingDropDownOpen ? 'dropdown-open' : ''
                                                } `}
                                            >
                                                <button
                                                    type="button"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        changeDropDown('packing');
                                                    }}
                                                    className="menu-btn app-btn select-modal"
                                                >
                                                    {formik.values.packing?.length ? (
                                                        <span className="button-text selected-text">
                                                            {formik.values.packing.length} selected
                                                        </span>
                                                    ) : (
                                                        <>
                                                            <span className="placeholder-text">Select Packing</span>
                                                            <span className="button-text"></span>
                                                        </>
                                                    )}
                                                    <span className="dropdown-arrow">
                                                        <svg className="svg-icon arrow-icon">
                                                            <use xlinkHref="#downArrow">
                                                                <title>dropdown</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </button>

                                                <div
                                                    className={
                                                        packingDropDownOpen
                                                            ? 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down dropdown-open modal-dropdown'
                                                            : 'dropdown-wrap commercial-services-dropdown-wrap outside-menu menu-down modal-dropdown'
                                                    }
                                                    style={{
                                                        width: '425px',
                                                    }}
                                                >
                                                    <div className="dropdown-menu ">
                                                        {packing.map((res: PackingList) => (
                                                            <a
                                                                onClick={(e) => {
                                                                    packingSelection(res);
                                                                    e.preventDefault();
                                                                }}
                                                                key={res.packingId}
                                                                className="menu-item app-btn"
                                                            >
                                                                <div
                                                                    onClick={(e) => {
                                                                        packingSelection(res);
                                                                        e.preventDefault();
                                                                    }}
                                                                    className="checkbox-item"
                                                                >
                                                                    <label className="app-check-wrapper">
                                                                        <input
                                                                            checked={
                                                                                formik.values.packing.includes(
                                                                                    res.packingCode
                                                                                )
                                                                                    ? true
                                                                                    : false
                                                                            }
                                                                            type="checkbox"
                                                                            className="checkbox-input"
                                                                        ></input>
                                                                        <div className="checkmark">
                                                                            <svg className="svg-icon tick-icon">
                                                                                <use href="#tickIcon">
                                                                                    <title>check mark</title>
                                                                                </use>
                                                                            </svg>
                                                                        </div>
                                                                        <div className="checkbox-label">
                                                                            {res.packingName}
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </a>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="suggestion-container">
                                                {formik.values.packing.map((res: string, index: number) => (
                                                    <div key={index} className="content-card">
                                                        <span className="main-content">
                                                            {
                                                                packing?.find(
                                                                    (el: PackingList) => el.packingCode === res
                                                                )?.packingName
                                                            }
                                                        </span>
                                                        <button
                                                            type="button"
                                                            onClick={() => {
                                                                const packing = formik.values.packing.filter(
                                                                    (el: string) => el !== res
                                                                );
                                                                formik.setFieldValue('packing', packing);
                                                            }}
                                                            className="app-btn close-btn"
                                                            title="Remove"
                                                        >
                                                            <span>
                                                                <svg className="svg-icon suggestion-delete-icon">
                                                                    <use href="#deleteIcon">
                                                                        <title>Remove</title>
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </button>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        {showError('packing')}
                                    </div>
                                    <div className="modal-input-holder">
                                        <label className="modal-input-label">
                                            VGM (in kg)
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter VGM"
                                                type="text"
                                                className="modal-input input-text"
                                                id="vgm"
                                                name="vgm"
                                                onChange={(event: any) => {
                                                    // event.target.value
                                                    //     ? formik.setFieldError(event.target.name, undefined)
                                                    //     : formik.setFieldError(event.target.name, 'Field is Required');

                                                    if (
                                                        // event.target.value <= 1000000 &&
                                                        decimalNumberValidation(parseFloat(event.target.value), 7, 2)
                                                    ) {
                                                        const split = event.target.value?.split('.');
                                                        if (split?.[0]?.length > 7) {
                                                            return false;
                                                        } else {
                                                            formik.handleChange(event);
                                                        }
                                                    }
                                                }}
                                                onKeyPress={(event) => {
                                                    if (
                                                        !validateFractionInput({
                                                            inputKey: event.key,
                                                            currentValue: +formik.values.vgm,
                                                        })
                                                    ) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                onBlur={(e) => {
                                                    e.target.value !== '' &&
                                                        parseFloat(e.target.value) >= 0 &&
                                                        formik.validateField('vgm');
                                                }}
                                                value={formik.values.vgm}
                                            />
                                            {/* {formik?.values?.vgm && formik?.errors?.vgm && (
                                                <div className="error-text">{formik?.errors?.vgm}</div>
                                            )} */}
                                        </div>
                                        {showError('vgm')}
                                    </div>
                                    <div className="modal-input-holder">
                                        <label className="modal-input-label">
                                            EIR
                                            {/* <span className="mandatory-field-text">*</span> */}
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter EIR"
                                                type="text"
                                                className="modal-input input-text"
                                                id="eir"
                                                name="eir"
                                                onChange={(event) => {
                                                    // event.target.value
                                                    //     ? formik.setFieldError(event.target.name, undefined)
                                                    //     : formik.setFieldError(event.target.name, 'Field is Required');
                                                    // formik.handleChange(event);
                                                    formik.setFieldError(event.target.name, undefined);
                                                    formik.handleChange(event);
                                                }}
                                                value={formik.values.eir}
                                            />
                                        </div>
                                        {showError('eir')}
                                    </div>
                                    <div className="modal-input-holder" id="scroll3">
                                        <label className="modal-input-label">
                                            Seal No.
                                            <span className="mandatory-field-text">*</span>
                                        </label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Seal No"
                                                type="text"
                                                className="modal-input input-text"
                                                id="sealNumber"
                                                name="sealNumber"
                                                onChange={(event) => {
                                                    event.target.value
                                                        ? formik.setFieldError(event.target.name, undefined)
                                                        : formik.setFieldError(event.target.name, 'Field is Required');
                                                    formik.handleChange(event);
                                                }}
                                                onKeyPress={(event) => {
                                                    if (!/[0-9A-Za-z]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                value={formik.values.sealNumber}
                                            />
                                        </div>
                                        {showError('sealNumber')}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn text-close-btn"
                                type="reset"
                                form="basic-details"
                                onClick={() => (formik.dirty ? setCancelModalView(true) : setModalView(false))}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="submit"
                                form="basic-details"
                                onClick={() => scrollUp()}
                                // disabled={Object.keys(formik.errors).length ? true : false}
                            >
                                <span className="button-text vendor-button-text">Save</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
