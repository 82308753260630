import { ReactElement } from 'react';
import './cancellation-modal.scss';

interface CancelReasonModalProps {
    onClose: () => void;
    data: any;
}
const Index = ({ onClose, data }: CancelReasonModalProps): ReactElement => {
    const getDate = (date: string) => {
        if (date) {
            const fullDate = date.split('T')[0];
            const dateData = fullDate?.split('-');
            return `${dateData[2]}-${dateData[1]}-${dateData[0]}`;
        }
        return '';
    };
    return (
        <div className="app-modal">
            <div className="modal-content-holder po-cancellation-modal cancel-detail-holder">
                <div className="modal-header">
                    <div className="title">Cancellation Details</div>
                    <button className="app-btn modal-close-btn" onClick={onClose}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>

                <div className="modal-content">
                    <div className="modal-main-content">
                        <div className="layout-item-holder">
                            <div className="layout-item">
                                <h6 className="heading-type-1">Cancelled By</h6>
                                <div className="description-type-1">{data?.cancelledUser || '-'}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Cancelled Date</h6>
                                <div className="description-type-1">{getDate(data?.cancelDate) || '-'}</div>
                            </div>
                            <div className="layout-item">
                                <h6 className="heading-type-1">Reason</h6>
                                <div className="description-type-1">{data?.cancelReason || '-'}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default Index;
