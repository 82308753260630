// const baseURL = process.env.REACT_APP_BASE_URL;
export const filterValues = [
    {
        label: 'JAN-2022',
        value: '1,2023',
    },
    {
        label: 'DEC-2022',
        value: '12,2022',
    },
    {
        label: 'NOV-2022',
        value: '11,2022',
    },
    {
        label: 'OCT-2022',
        value: '10,2022',
    },
    {
        label: 'Last 3 months',
        value: 'l3m',
    },
];

export const gridDetails = [
    {
        name: 'carrier',
        columns: [
            {
                field: 'origin',
                title: 'Origin',
                width: '120px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '120px',
            },
            {
                field: 'client',
                title: 'Client',
                width: '120px',
            },
            {
                field: 'contractName',
                title: 'Contract name',
                width: '120px',
            },
            {
                field: 'type',
                title: 'Type(primary/secondary)',
                width: '210px',
            },
        ],
        fields: {
            origin: { type: 'string' },
            destination: { type: 'string' },
            client: { type: 'string' },
            contractName: { type: 'string' },
            type: { type: 'string' },
        },
        url: ``,
        fileName: 'carrier-portpair-data',
        method: 'POST',
        contentType: 'application/json',
    },
];

export const portPairDetails = [
    {
        name: 'carrier',
        columns: [
            {
                field: 'SE_PortOfLoading',
                title: 'POL',
            },
            {
                field: 'SE_PortOfDestination',
                title: 'POD',
            },
            {
                field: 'SE_EstimatedDeparture',
                title: 'ETD',
            },
            {
                field: 'SE_TransitTime',
                title: 'Transit Time (d)',
            },
            {
                field: 'SE_LegCount',
                title: 'Legs',
            },
        ],
        fields: {
            SE_PortOfLoading: { type: 'string' },
            SE_PortOfDestination: { type: 'string' },
            SE_EstimatedDeparture: { type: 'date' },
            SE_TransitTime: { type: 'date' },
            SE_LegCount: { type: 'string' },
        },
        url: '',
        fileName: 'carrier-portpair-data',
        method: 'GET',
        contentType: '',
    },
];

export const sailingDetails = [
    {
        name: 'carrier',
        columns: [
            {
                field: 'vessel',
                title: 'Vessel',
                template: '#if(vessel === null){# flight #}else{# #= vessel# #}#',
                width: '210px',
            },
            {
                field: 'voyage',
                title: 'Voyage',
                width: '290px',
            },
            {
                field: 'origin',
                title: 'Origin',
                width: '130px',
            },
            {
                field: 'destination',
                title: 'Destination',
                width: '150px',
            },
            {
                field: 'atd',
                title: 'ATD',
                template: '#if(atd === null){# - #}else{# #=atd# #}#',
                width: '140px',
            },
            {
                field: 'eta',
                title: 'ETA',
                template: '#if(eta === null){# - #}else{# #=eta# #}#',
                width: '140px',
            },
            {
                field: 'clients',
                title: 'Clients',
                width: '140px',
            },
            {
                field: 'bpNumber',
                title: 'Bookings',
                template:
                    '#if (bpMode==="FCL") {# <a class="link-com" onclick="localStorage.setItem(\'bpNumber\', \'#=bpNumber#\')" href="/booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(bpMode==="LCL") {# <a onclick="localStorage.setItem(\'bpNumber\', \'#=bpNumber#\')" class="link-com" href="/lclbooking_list/#=bpId#/POSelection" >#=bpNumber#</a> #}else if(bpMode==="BCN") {# <a onclick="localStorage.setItem(\'bpNumber\', \'#=bpNumber#\')" class="link-com" href="/bcnBookingList/#=bpId#/POSelection" >#=bpNumber#</a> #} else if(bpMode==="AIR") {# <a onclick="localStorage.setItem(\'bpNumber\', \'#=bpNumber#\')" class="link-com" href="/air_booking_list/#=bpId#/POSelection" >#=bpNumber#</a> #} else {# <a onclick="localStorage.setItem(\'bpNumber\', \'#=bpNumber#\')" class="link-com" href="/Aobooking_list/#=bpId#/AOSelection" >#=bpNumber#</a> #}#',
                width: '160px',
            },
        ],
        fields: {
            vessel: { type: 'string' },
            voyage: { type: 'string' },
            origin: { type: 'string' },
            destination: { type: 'string' },
            atd: { type: 'string' },
            eta: { type: 'string' },
            clients: { type: 'string' },
            bpNumber: { type: 'string' },
        },
        url: ``,
        fileName: 'carrier-portpair-data',
        method: 'POST',
        contentType: 'application/json',
    },
];
