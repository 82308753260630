/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../index';

interface AgreementTerms {
    term: string;
    value: string;
}
export interface CommercialDetailsDocumentsDtoList {
    ad_docname: string;
    ad_version: string;
    ad_author: string;
    ad_forwardersignatory: string;
    ad_agentsignatory: string;
    ad_startdate: string;
    ad_enddate: string;
    ad_renewal: string;
    isAir: string;
    isRail: string;
    isRoad: string;
    isSea: string;
    agreementterms: AgreementTerms;
}
export interface CommercialDetailsDataDetails {
    agentDocumentList: CommercialDetailsDocumentsDtoList[];
    totalActiveDocuments: number;
}

interface CommercialDetailsData {
    status: number;
    message: string;
    info: CommercialDetailsDataDetails;
    data: any;
}

interface CommercialDetailsColumnData {
    status: number;
    message: string;
    info: string[];
    columnName?: string;
    data: any;
}

interface CommercialDetailsState {
    isLoading: boolean;
    commercialDetails?: CommercialDetailsData;
    commercialDetailsColumn?: CommercialDetailsColumnData;
    commercialDetailsSuccess: any;
    commercialDetailsError: any;
    commercialDetailsAuthor: any;
    commercialDetailsForwarderSignatory: any;
    commercialDetailsAgreementTerms: any;
    isError?: boolean;
    isSubmitAndActivated?: boolean;
    id?: number;
    currentPage: number;
    columnStatus: string;
    perPageCount: number;
    totalNoOfPages: number;
    totalNoOfItems: number;
}

const initialState: CommercialDetailsState = {
    isLoading: true,
    commercialDetails: undefined,
    commercialDetailsColumn: undefined,
    commercialDetailsSuccess: undefined,
    commercialDetailsError: undefined,
    commercialDetailsAuthor: undefined,
    commercialDetailsForwarderSignatory: undefined,
    commercialDetailsAgreementTerms: undefined,
    isError: false,
    isSubmitAndActivated: false,
    id: undefined,
    currentPage: 0,
    columnStatus: '',
    perPageCount: 10,
    totalNoOfPages: 1,
    totalNoOfItems: 0,
};

export const agentCommercialDetailsSlice = createSlice({
    name: 'agentCommercialDetails',
    initialState,
    reducers: {
        fetchCommercialDetailsList: (state, action) => {
            state.isLoading = true;
            state.isSubmitAndActivated = false;
            state.currentPage = action.payload?.firstLoad ? 0 : action.payload.currentPage;
            state.columnStatus = action.payload.columnStatus;
            state.id = action.payload.id;
        },
        fetchCommercialDetailsListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.commercialDetails = action.payload;
            state.totalNoOfPages = action.payload?.data?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.data?.info?.totalNoOfItems;
        },
        fetchCommercialDetailsListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.commercialDetails = action.payload;
        },
        fetchCommercialDetailsColumnList: (state, _action) => {
            state.isLoading = true;
            state.isSubmitAndActivated = false;
        },
        fetchCommercialDetailsColumnListSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.commercialDetailsColumn = action.payload;
        },
        fetchCommercialDetailsColumnListFailed(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.commercialDetailsColumn = action.payload;
        },
        createCommercialDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
            state.isSubmitAndActivated = false;
        },
        createCommercialDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.commercialDetailsSuccess = action.payload;
            state.commercialDetailsError = {};
        },
        createCommercialDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.commercialDetailsSuccess = {};
            state.commercialDetailsError = action.payload;
        },
        updateCommercialDetails(state, _action) {
            state.isLoading = true;
            state.isError = false;
            state.isSubmitAndActivated = false;
        },
        updateCommercialDetailsSuccess(state, action) {
            state.isLoading = false;
            state.isError = false;
            state.commercialDetailsSuccess = action.payload;
            state.commercialDetailsError = {};
        },
        updateCommercialDetailsFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.commercialDetailsSuccess = {};
            state.commercialDetailsError = action.payload;
        },
        submitAgent(state, _action) {
            state.isLoading = true;
            state.isError = false;
            state.isSubmitAndActivated = false;
        },
        submitAgentSuccess(state, _action) {
            state.isLoading = false;
            state.isError = false;
            state.isSubmitAndActivated = true;
            // state.commercialDetailsSuccess = action.payload;
            state.commercialDetailsError = {};
        },
        submitAgentFailure(state, action) {
            state.isLoading = false;
            state.isError = true;
            state.isSubmitAndActivated = false;
            state.commercialDetailsSuccess = {};
            state.commercialDetailsError = action.payload;
        },
        fetchAuthorDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAuthorDetailsSuccess(state, action) {
            state.isError = false;
            state.commercialDetailsAuthor = action.payload;
        },
        fetchAuthorDetailsFailure(state, action) {
            state.isError = true;
            state.commercialDetailsError = action.payload;
        },
        fetchForwarderSignatoryDetails: (state, _action) => {
            state.isError = false;
        },
        fetchForwarderSignatoryDetailsSuccess(state, action) {
            state.isError = false;
            state.commercialDetailsForwarderSignatory = action.payload;
        },
        fetchForwarderSignatoryDetailsFailure(state, action) {
            state.isError = true;
            state.commercialDetailsForwarderSignatory = action.payload;
        },
        fetchAgreementTermsDetails: (state, _action) => {
            state.isError = false;
        },
        fetchAgreementTermsDetailsSuccess(state, action) {
            state.isError = false;
            state.commercialDetailsAgreementTerms = action.payload;
        },
        fetchAgreementTermsDetailsFailure(state, action) {
            state.isError = true;
            state.commercialDetailsAgreementTerms = action.payload;
        },
        setPageCount: (state, action) => {
            state.perPageCount = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearCommercialDetails: (state, _action) => {
            state.commercialDetails = undefined;
        },
        clearFailure: (state, _action) => {
            state.commercialDetailsError = undefined;
            state.commercialDetailsSuccess = undefined;
            state.isSubmitAndActivated = false;
            state.isError = false;
        },
        searchCommercialDetailsData: (state, action) => {
            state.isLoading = true;
            state.currentPage = action.payload.currentPage;
        },
        searchCommercialDetailsDataSuccess: (state, action) => {
            state.isLoading = false;
            state.isError = false;
            state.commercialDetails = action.payload;
            state.totalNoOfPages = action.payload?.info?.totalNoOfPages;
            state.totalNoOfItems = action.payload?.info?.totalNoOfItems;
        },
        searchCommercialDetailsDataFailure: (state, action) => {
            state.isLoading = false;
            state.isError = true;
            state.commercialDetails = action.payload;
        },
    },
});

export const {
    fetchCommercialDetailsList,
    fetchCommercialDetailsListSuccess,
    fetchCommercialDetailsListFailed,
    fetchCommercialDetailsColumnList,
    fetchCommercialDetailsColumnListSuccess,
    fetchCommercialDetailsColumnListFailed,
    createCommercialDetails,
    createCommercialDetailsSuccess,
    createCommercialDetailsFailure,
    updateCommercialDetails,
    updateCommercialDetailsSuccess,
    updateCommercialDetailsFailure,
    submitAgent,
    submitAgentSuccess,
    submitAgentFailure,
    fetchAuthorDetails,
    fetchAuthorDetailsSuccess,
    fetchAuthorDetailsFailure,
    fetchForwarderSignatoryDetails,
    fetchForwarderSignatoryDetailsSuccess,
    fetchForwarderSignatoryDetailsFailure,
    fetchAgreementTermsDetails,
    fetchAgreementTermsDetailsSuccess,
    fetchAgreementTermsDetailsFailure,
    isLoadingFn,
    clearCommercialDetails,
    clearFailure,
    searchCommercialDetailsData,
    searchCommercialDetailsDataSuccess,
    searchCommercialDetailsDataFailure,
} = agentCommercialDetailsSlice.actions;

export const selectCommercialDetailLoading = (state: RootState) => state.agentCommercialDetailsData.isLoading;
export const selectCommercialDetailUser = (state: RootState) => state.agentCommercialDetailsData.commercialDetails;
export const selectCommercialDetailColumnUser = (state: RootState) =>
    state.agentCommercialDetailsData.commercialDetailsColumn;
export const selectCommercialDetailSuccess = (state: RootState) =>
    state.agentCommercialDetailsData.commercialDetailsSuccess;
export const selectCommercialDetailError = (state: RootState) =>
    state.agentCommercialDetailsData.commercialDetailsError;
export const selectCommercialDetailAuthor = (state: RootState) =>
    state.agentCommercialDetailsData.commercialDetailsAuthor;
export const selectCommercialDetailForwarderSignatory = (state: RootState) =>
    state.agentCommercialDetailsData.commercialDetailsForwarderSignatory;
export const selectCommercialDetailAgreementTerms = (state: RootState) =>
    state.agentCommercialDetailsData.commercialDetailsAgreementTerms;
export const selectCommercialDetailIsError = (state: RootState) => state.agentCommercialDetailsData.isError;
export const selectCommercialDetailIsSubmitAndActivated = (state: RootState) =>
    state.agentCommercialDetailsData.isSubmitAndActivated;
export const selectCommercialDetailCurrentPage = (state: RootState) => state.agentCommercialDetailsData.currentPage;
export const selectCommercialDetailColumnStatus = (state: RootState) => state.agentCommercialDetailsData.columnStatus;
export const selectCommercialDetailPerPageCount = (state: RootState) => state.agentCommercialDetailsData.perPageCount;
export const selectCommercialDetailtotalNoOfPages = (state: RootState) =>
    state.agentCommercialDetailsData.totalNoOfPages;
export const selectCommercialDetailtotalNoOfItems = (state: RootState) =>
    state.agentCommercialDetailsData.totalNoOfItems;

export default agentCommercialDetailsSlice.reducer;
