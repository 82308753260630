// combineReducers come from redux that used for combining reducers that we just made.
import { combineReducers } from 'redux';
import { loadingBarReducer } from 'react-redux-loading-bar';

// Reducers
import data from './data-reducer';
import clientData from './clientListReducer';
import shippingLine from './shippingLineReducer';
import coloader from './profiles/coloader';
import coloaderBasicDetailsData from './profiles/coloader/basicDetailsReducer';
import coloaderContractDetailsData from './profiles/coloader/ContractDetailsReducer';

import authData from './authReducer';
import classBData from './classbReducer';
import vendorDat from './vendorReducer';
import basicDetails from './basicDetailsReducer';
import sopData from './sopReducer';
import contractData from './contractReducer';
import integrationDetails from './integrationReducer';
import agent from './agentReducer';
import productData from './productReducer';
import count from './countReducer';
import historyData from './historyReducer';
import vendorList from './vendorListReducer';
import UsersList from './UsersList';
import vendorDetails from './createVendorReducer';
import forwarderDetails from './forwarderReducer';
import clientDetails from './clientReducer';
import shippingDetails from './shippingReducer';
import agentDetails from './agentReducer';
import rolesReducer from './rolesReducer';
import agentBasicDetailsData from './profiles/agents/basicDetailsReducer';
import permissionData from './permissionReducer';
import linkClientVendor from './linkClientVendorReducer';
import vendorIntegrationDetails from './vendorIntegrationReducer';
import agentPortDetailsData from './profiles/agents/portDetailsReducer';
import agentCommercialDetailsData from './profiles/agents/commercialDetailsReducer';
import shippingLineBasicDetailsData from './profiles/shippingLine/basicDetailsReducer';
import profilesClients from './profiles/clients';
import profilesVendors from './profiles/vendors';
import shippingLineContractDetailsData from './profiles/shippingLine/ContractDetailsReducer';
import purchaseOrderList from './purchaseOrder/poListReducer';
import purchaseOrder from './purchase_order/basicDetails';
import addPoData from './purchaseOrder/addPoReducer';
import datesPoData from './purchaseOrder/datesPoReducer';
import productPoData from './purchaseOrder/productPoReducer';
import shipmentPoData from './purchaseOrder/shipmentConfirmationReducer';
import productPoConfirmationData from './purchaseOrder/productPoConfirmationReducer';
import productDetailsConfirmationData from './purchaseOrder/productDetailsConfirmationReducer';
import containerModePoData from './purchaseOrder/containerModePoReducer';
import notifications from './notificationReducer';
import bookingProposalList from './bookingProposal/bookingProposalListReducer';
import availablePos from './bookingProposal/availablePoReducer';
import addedPos from './bookingProposal/addedPoReducer';
import savedLayouts from './kendoReducer';
import availablePoLines from './bookingProposal/availablePoLineReducer';
import availablePoLinesBcn from './bookingProposalBcn/availablePoLineReducer';
import availablePoLinesLnd from './bookingProposalLand/availablePoLineReducer';
import landsailingbp from './bookingProposalLand/sailingReducer';
import sailingRed from './bookingProposal/sailingReducer';
import additionalDetailsData from './bookingProposal/additionalDetailsReducer';
import bpHeaderData from './bookingProposal/bookingProposalHeaderReducer';
import additionalDetailsNac from './bookingProposal/additionalDetailsNacReducer';
import containerAllocationData from './bookingProposal/containerAllocationReducer';
import attachmentsReducer from './bookingProposal/attachmentsReducer';
import aoBookingProposalList from './aobookingProposal/bookingProposalListReducer';
import aoavailablePos from './aobookingProposal/availablePoReducer';
import aoaddedPos from './aobookingProposal/addedPoReducer';
import aoavailablePoLines from './aobookingProposal/availablePoLineReducer';
import aosailingRed from './aobookingProposal/sailingReducer';
import aoadditionalDetailsData from './aobookingProposal/additionalDetailsReducer';
import aobpHeaderData from './aobookingProposal/bookingProposalHeaderReducer';
import aoadditionalDetailsNac from './aobookingProposal/additionalDetailsNacReducer';
import aocontainerAllocationData from './aobookingProposal/containerAllocationReducer';
import aoattachmentsReducer from './aobookingProposal/attachmentsReducer';
import receiptAttachmentReducer from './receipts/receiptAttachment';
import receiptPOline from './receipt/poLine';
import roleAssignmentList from './rolesReducer';
import receiptList from './receipts/receiptListReducer';
import addReceipt from './receipts/addReceiptReducer';
import agentIntegrationDetails from './profiles/agents/integrationReducer';
import receiptHeaderData from './receipts/receiptHeaderReducer';
import inventoryList from './inventory/inventoryListReducer';
import inventoryAdjust from './inventory/inventoryAdjstmntReducer';
import addAoData from './allocationOrder/addAOReducer';
import allocationOrder from './allocationOrder/basicDetails';
import datesAoData from './allocationOrder/datesAoReducer';
import productAllocationData from './allocationOrder/productAllocationReducer';
import inventoryLog from './inventory/inventoryLog';
import allocationOrderList from './allocationOrder/allocationOrderListReducer';
import autoApproval from './autoApprovalReducer';
import availablePosLcl from './bookingProposalLcl/availablePoReducer';
import availablePoLinesLcl from './bookingProposalLcl/availablePoLineReducer';
import addedPosLcl from './bookingProposalLcl/addedPoReducer';
import settings from './settings';
import availablePosAir from './bookingProposalAir/availablePosReducer';
import availableLandPos from './bookingProposalLand/availablePoReducer';
import availablePoLinesAir from './bookingProposalAir/availablePoLineReducer';
import additionalDetailsLcl from './bookingProposalLcl/additionalDetailsReducer';
import addedPosAir from './bookingProposalAir/addedPoReducer';
import bookingproposalAirschedule from './bookingProposalAir/addSchedule';
import flightScheduleData from './bookingProposalAir/flightScheduleReducer';
import existingFlightSchedules from './bookingProposalAir/existingFlightSchedulesReducer';
import bookingProposalAirList from './bookingProposalAir/listReducer';
import bookingProposalAirAttachment from './bookingProposalAir/attachmentsReducer';
import bookingDetailsAir from './bookingProposalAir/bookingDetailsReducer';
import shippingDetailsAir from './bookingProposalAir/shippingDetailsReducer';
import map from './dashboard/map';
import quickLinks from './dashboard/quickLinksReducer';
import dashboarddata from './dashboard/dashoboardSearchReducer';
import dashboardTodo from './dashboard/dashboardTodoReducer';
import dashboardActivity from './dashboard/dashboardActivityFeed';
import workTrendsData from './dashboard/workTrendsReducer';
import favourites from './dashboard/insightsFavoritesReducer';
import dashboardInsights from './dashboard/insightsReducer';
import reassignPoData from './reassignAgent/purchaseOrderReducer';
import reassignBpData from './reassignAgent/bookingProposalReducer';
import reassignApprovalData from './reassignAgent/approvalReducer';
import trackingInsightsData from './tracking/trackingInsightsReducer';
import lifeCycleSearch from './lifeCycleSearch/lifeCycleSearchReducer';
import lifecyclesearchFav from './lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import availablePosBcn from './bookingProposalBcn/availablePoReducer';
import availablePosLnd from './bookingProposalLand/availablePoReducer';
import attachmentHistoryData from './attachmentHistoryReducer';
import shipmentDetailsData from './shipmentDetailsReducer';
import lifecycle from './lifecycle/lifecycleEventReducer';
import vendorPortalData from './purchaseOrder/vendorPortalReducer';
import overrideShipments from './bookingProposal/overrideAdditionalReducer';
import containerAllocation from './bookingProposal/containerAllocationReducer';
import document from './documentReducer';
import qmsData from './qmsReducer';
import warehouseReducer from './warehouseReducer';

const rootReducer = combineReducers({
    overrideShipments,
    document,
    containerAllocation,
    dashboardActivity,
    dashboardTodo,
    dashboarddata,
    dashboardInsights,
    existingFlightSchedules,
    data,
    clientData,
    shippingLine,
    coloader,
    coloaderBasicDetailsData,
    coloaderContractDetailsData,
    authData,
    vendorDat,
    classBData,
    loadingBar: loadingBarReducer,
    basicDetails,
    sopData,
    contractData,
    integrationDetails,
    agent,
    productData,
    count,
    qmsData,
    historyData,
    vendorList,
    UsersList,
    vendorDetails,
    forwarderDetails,
    clientDetails,
    shippingDetails,
    agentDetails,
    rolesReducer,
    agentBasicDetailsData,
    permissionData,
    linkClientVendor,
    vendorIntegrationDetails,
    agentPortDetailsData,
    profilesClients,
    profilesVendors,
    lifecycle,
    agentCommercialDetailsData,
    shippingLineBasicDetailsData,
    shippingLineContractDetailsData,
    purchaseOrder,
    purchaseOrderList,
    addPoData,
    datesPoData,
    productPoData,
    shipmentPoData,
    productPoConfirmationData,
    productDetailsConfirmationData,
    containerModePoData,
    notifications,
    bookingProposalList,
    availablePos,
    addedPos,
    savedLayouts,
    availablePoLines,
    sailingRed,
    additionalDetailsData,
    bpHeaderData,
    additionalDetailsNac,
    containerAllocationData,
    attachmentsReducer,
    receiptPOline,
    receiptList,
    addReceipt,
    receiptAttachmentReducer,
    agentIntegrationDetails,
    receiptHeaderData,
    inventoryList,
    inventoryAdjust,
    addAoData,
    allocationOrder,
    datesAoData,
    productAllocationData,
    inventoryLog,
    allocationOrderList,
    aoBookingProposalList,
    aoavailablePos,
    aoaddedPos,
    aoavailablePoLines,
    aosailingRed,
    aoadditionalDetailsData,
    aobpHeaderData,
    aoadditionalDetailsNac,
    aocontainerAllocationData,
    aoattachmentsReducer,
    autoApproval,
    availablePosLcl,
    availablePoLinesLcl,
    addedPosLcl,
    settings,
    availablePosAir,
    availablePoLinesAir,
    additionalDetailsLcl,
    addedPosAir,
    roleAssignmentList,
    bookingproposalAirschedule,
    flightScheduleData,
    bookingProposalAirList,
    bookingProposalAirAttachment,
    bookingDetailsAir,
    shippingDetailsAir,
    availableLandPos,
    map,
    quickLinks,
    workTrendsData,
    trackingInsightsData,
    favourites,
    reassignPoData,
    reassignBpData,
    reassignApprovalData,
    lifeCycleSearch,
    lifecyclesearchFav,
    availablePosBcn,
    availablePoLinesBcn,
    availablePoLinesLnd,
    availablePosLnd,
    attachmentHistoryData,
    shipmentDetailsData,
    vendorPortalData,
    warehouseReducer,
    landsailingbp,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
