import { createSlice } from '@reduxjs/toolkit';

export const dataSlice = createSlice({
    name: 'savedLayouts',
    initialState: {
        savedLayouts: [],
        isLoading: true,
    },
    reducers: {
        fetchLayouts: (state, action) => {
            state.isLoading = true;
            state.savedLayouts = action.payload?.info;
            state.isLoading = false;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
    },
});

export const { fetchLayouts, isLoadingFn } = dataSlice.actions;
export default dataSlice.reducer;
