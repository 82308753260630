import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'src/hooks';
import { Form, FormField, TextArea } from 'semantic-ui-react';

import { cixDeleteDoc } from 'src/store/reducers/documentReducer';
interface ModalProps {
    modalView: boolean;
    setModalView: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    doPK: string;
}

const Index: React.FC<ModalProps> = ({ modalView, setModalView, doPK }) => {
    const { id } = useParams();
    const dispatch = useAppDispatch();
    const [buttonDisplay, setButtonDisplay] = useState('Confirm');
    const [reason, setReason] = useState('');

    const deleteAction = () => {
        setButtonDisplay('Please Wait...');
        dispatch(cixDeleteDoc({ doPk: doPK, reason: reason }));
    };

    return (
        <>
            {modalView ? (
                <div className="app-modal">
                    <div className="modal-content-holder booking-cancellation-modal">
                        <div className="modal-header">
                            <div className="title">Confirm Delete</div>
                            <button onClick={() => setModalView(false)} className="app-btn modal-close-btn">
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use href="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>

                        <div className="modal-content">
                            <div className="modal-main-content">
                                <div className="layout-holder">
                                    <div className="layout-item">
                                        <div className="description-type-1">
                                            Please Confirm You Would Like to Delete
                                        </div>
                                    </div>
                                </div>
                                <Form>
                                    <FormField
                                        id="form-textarea-control-opinion"
                                        control={TextArea}
                                        label="Reason"
                                        placeholder="Enter the reason to delete attachment"
                                        value={reason}
                                        onChange={(e: any) => {
                                            setReason(e.target.value);
                                        }}
                                        required
                                    />
                                </Form>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                title="Cancel"
                                onClick={() => setModalView(false)}
                            >
                                <span className="button-text footer-button-text">Cancel</span>
                            </button>
                            <button
                                className="app-btn app-btn-primary modal-btn footer-btn "
                                type="button"
                                title="Delete"
                                onClick={() => {
                                    deleteAction();
                                }}
                                disabled={buttonDisplay === 'Please Wait...' || reason?.length === 0}
                            >
                                <span className="button-text footer-button-text">{buttonDisplay}</span>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
