import axios from 'axios';
import fileDownload from 'js-file-download';
import { patch, post, get, del } from '../../HttpMethods';
import { externalBaseUrl } from 'src/utils/kendoGrid/insights';
import { fileUploadForDocuments } from '../bookingProposal/additionalDetailsApi';

export const fetchContainerModePoListDetailsApi = async (payload: { poId: string; profileType: string }) => {
    try {
        const data = await get(`api/ord/cg?poId=${payload.poId}&profile=${payload.profileType}`, null);
        const defaultColoader = await get(
            'cix/scr/admin/organisation/coloader/lst/OR_UserDefinedText4/OR_OrgCode?filter[logic]=and&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active&filter[filters][1][field]=OR_UserDefinedText4&filter[filters][1][operator]=contains&filter[filters][1][value]=3ags',
            null,
            externalBaseUrl
        );
        if (defaultColoader?.data?.length) {
            for (let index = 0; index < data?.info?.length; index++) {
                if (
                    data?.info[index]?.type === 'LCL' &&
                    !data?.info[index]?.coLoaderCode &&
                    !data?.info[index]?.coLoader
                ) {
                    data.info[index].coLoaderCode = defaultColoader?.data?.[0]?.OR_OrgCode;
                    data.info[index].coLoader = defaultColoader?.data?.[0]?.OR_UserDefinedText4;
                    data.info[index].coLoaderId = defaultColoader?.data?.[0]?.SL_PK;
                }
            }
        }

        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchConfirmationGroupDetailsApi = async (payload: { confirmationGroupId: string }) => {
    try {
        const data = await get(`api/ord/cg/options?confirmationGroupId=${payload.confirmationGroupId}`, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const fetchCoLoaderDetailsApi = async (payload: { clientCode: string; search: string }) => {
    try {
        // const data = await get(`api/ord/coloader/${payload.clientCode}`, null);
        const key = payload?.search
            ? '&filter[filters][1][field]=OR_UserDefinedText4&filter[filters][1][operator]=contains&filter[filters][1][value]=' +
              payload?.search
            : '';
        const data = await get(
            '/cix/scr/admin/organisation/coloader/lst/OR_UserDefinedText4/OR_OrgCode?filter[logic]=and&filter[filters][2][field]=OR_Status&filter[filters][2][operator]=eq&filter[filters][2][value]=Active' +
                key,
            null,
            externalBaseUrl
        );
        return {
            data: data?.data?.map((res: any) => ({
                coLoader: res?.OR_UserDefinedText4,
                coLoaderCode: res?.OR_OrgCode,
                coLoaderId: res?.SL_PK,
            })),
        };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const saveColoaderDataApi = async (payload: any) => {
    try {
        const data = await post('api/bkg/bp/addcoloader', payload, null);
        return { data };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const saveLclDetailsApi = async (payload: any) => {
    try {
        const mode = payload?.mode;
        delete payload?.mode;
        await post('api/ord/cg/lcl', payload?.formData, null);
        const buyFile = payload?.formData?.get('buyfile');
        const sellFile = payload?.formData?.get('sellfile');
        if (buyFile) {
            const document = payload?.formData?.get('request');
            const text = await document.text();
            const poId = JSON.parse(text)?.poId;
            const formData = new FormData();
            formData.append('file', buyFile, buyFile.name);
            formData.append('DO_FK', poId);
            formData.append('DO_FKID', 'PO');
            formData.append('DO_CD_DocumentType', 'HBQ');
            await fileUploadForDocuments(formData);
        }
        if (sellFile) {
            const document = payload?.formData?.get('request');
            const text = await document.text();
            const poId = JSON.parse(text)?.poId;
            const formData = new FormData();
            formData.append('file', sellFile, sellFile.name);
            formData.append('DO_FK', poId);
            formData.append('DO_FKID', 'PO');
            formData.append('DO_CD_DocumentType', 'HSQ');
            await fileUploadForDocuments(formData);
        }
        // data.mode = mode;
        return { data: { mode }, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const saveFclDetailsApi = async (payload: any) => {
    try {
        const mode = payload?.mode;
        delete payload?.mode;
        await post(`api/ord/cg/fcl`, payload?.formData, null);
        const buyFile = payload?.formData?.get('buyfile');
        const sellFile = payload?.formData?.get('sellfile');
        if (buyFile) {
            const document = payload?.formData?.get('request');
            const text = await document.text();
            const poId = JSON.parse(text)?.poId;
            const formData = new FormData();
            formData.append('file', buyFile, buyFile.name);
            formData.append('DO_FK', poId);
            formData.append('DO_FKID', 'PO');
            formData.append('DO_CD_DocumentType', 'HBQ');
            await fileUploadForDocuments(formData);
        }
        if (sellFile) {
            const document = payload?.formData?.get('request');
            const text = await document.text();
            const poId = JSON.parse(text)?.poId;
            const formData = new FormData();
            formData.append('file', sellFile, sellFile.name);
            formData.append('DO_FK', poId);
            formData.append('DO_FKID', 'PO');
            formData.append('DO_CD_DocumentType', 'HSQ');
            await fileUploadForDocuments(formData);
        }
        // data.mode = mode;
        return { data: { mode }, status: 201 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const updateConfirmationGroupDetailsApi = async (payload: any) => {
    try {
        const data = await post('api/ord/cg/editCg', payload, null);
        return { data, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const removeConfirmationGroupDetailsApi = async (payload: any) => {
    try {
        const data = await del(
            `/cix/scr/forwarding/purchaseorder/${payload.PO_PK}/confirmationgroup/${payload.CG_PK}`,
            { reason: payload.reason },
            externalBaseUrl
        );
        return { data, mode: 'DELETE', navigate: payload?.navigate };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
export const releaseConfirmationGroupDetailsApi = async (payload: any) => {
    try {
        const response = await get('cix/cfg/nextNumber/BP', null, externalBaseUrl);
        const bpNumber = response?.data?.[0]?.NN_V_Key;
        const data = await post(`/api/ord/cg/releaseCgtoBP/${payload.CG_PK}`, { bpNumber }, null);
        return { data, mode: 'RELEASE' };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const submitToClientApi = async (payload: { confirmationGroupId: string }) => {
    try {
        await patch(`api/ord/cg/forwarder/${payload.confirmationGroupId}`, null);
        // data.type = 'submitToClient';
        return { data: { mode: 'submitToClient' }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const approveGroupApi = async (payload: { confirmationGroupId: string; groupSelected: string }) => {
    try {
        await patch(`api/ord/cg/client/${payload.confirmationGroupId}/${payload.groupSelected?.toUpperCase()}`, null);
        // data.type = 'approveGroup';
        return { data: { mode: 'approveGroup', groupSelected: payload.groupSelected }, status: 200 };
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};

export const downloadConfirmationGroupQuote = async (
    fileId: string,
    containerLoadType: string,
    quoteType: string,
    confirmationGroupId: string,
    fileName: string
) => {
    try {
        const myHeaders = new Headers();
        const token = localStorage.getItem('token');
        myHeaders.append('Authorization', `Bearer ${token}`);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/ord/cg/download?fileId=${fileId}&ContainerLoadType=${containerLoadType}&quoteType=${quoteType}&confirmationGroupId=${confirmationGroupId}`,
            {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow',
            }
        )
            .then((response) => response.blob())
            .then((result) => {
                fileDownload(result, fileName);
            })
            .catch((error) => console.log('error', error));
        //return res;
    } catch (err) {
        return console.error(err);
    }
};

export const discardConfirmationGroupApi = async (payload: { cgId: string; reason: string }) => {
    try {
        const res = await del(
            `api/ord/cg/discard?confirmationGroupId=${payload.cgId}&discardReason=${payload.reason}`,
            null
        );
        return res;
    } catch (error) {
        if (axios.isAxiosError(error)) {
            console.log('error message: ', error.message);
            throw error.response;
        } else {
            console.log('unexpected error: ', error);
            return error;
        }
    }
};
