import { AxiosResponse } from 'axios';
import { put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import {
    todoListFetching,
    todoListFetchingSuccess,
    todoListFetchingError,
    todoItemFetching,
    todoItemFetchingSuccess,
    todoItemFetchingError,
} from 'src/store/reducers/dashboard/dashboardTodoReducer';
import {
    dashboardTodoItemAO,
    dashboardTodoItemBP,
    dashboardTodoItemLCL,
    dashboardTodoItemPendingReassignment,
    dashboardTodoItemPO,
    dashboardTodoItemReceipt,
    dashboardTodoListBKG,
    dashboardTodoListORD,
    otherTodoItem,
} from 'src/services/apis/dashboard/dashboardTodoApi';
import { useUserProfile } from 'src/hooks/useUserProfile';

function* getDashboardTodoList({ payload }: { payload: any }) {
    const { roleName, profileType } = useUserProfile();

    try {
        yield put(showLoading());

        // const ordResponse: AxiosResponse<any> = yield call(dashboardTodoListORD, payload);
        const bkgResponse: AxiosResponse<any> = yield call(dashboardTodoListBKG, payload);

        if (bkgResponse?.data?.success) {
            // const mergeObjects = (...objs: any) => {
            //     const mergedObj: any = {};

            //     objs.forEach((obj: any) => {
            //         for (const [key, value] of Object.entries(obj)) {
            //             if (Array.isArray(value)) {
            //                 if (mergedObj.hasOwnProperty(key)) {
            //                     mergedObj[key] = [...mergedObj[key], ...value];
            //                 } else {
            //                     mergedObj[key] = [...value];
            //                 }
            //             } else {
            //                 mergedObj[key] = value;
            //             }
            //         }
            //     });

            //     return mergedObj;
            // };
            // const mergedObj: any = mergeObjects(bkgResponse?.data?.data?.info);

            // if (mergedObj.favourites.some((e: any) => e.type === 'PENDING_REASSIGNMENT')) {
            //     const outputOut = JSON.parse(JSON.stringify(mergedObj.favourites));
            //     const output = outputOut.reduce(function (accumulator: any, cur: any) {
            //         const type = cur.type;
            //         const found = accumulator.find(function (elem: any) {
            //             return elem.type == type;
            //         });
            //         if (found) {
            //             found.count = found.count + cur.count;
            //         } else {
            //             accumulator.push(cur);
            //         }
            //         return accumulator;
            //     }, []);
            //     mergedObj.favourites = [...output];
            // } else if (mergedObj.others.some((e: any) => e.type === 'PENDING_REASSIGNMENT')) {
            //     const outputOut = JSON.parse(JSON.stringify(mergedObj.others));
            //     const output = outputOut.reduce(function (accumulator: any, cur: any) {
            //         const type = cur.type;
            //         const found = accumulator.find(function (elem: any) {
            //             return elem.type == type;
            //         });
            //         if (found) {
            //             found.count = found.count + cur.count;
            //         } else {
            //             accumulator.push(cur);
            //         }
            //         return accumulator;
            //     }, []);
            //     mergedObj.others = [...output];
            // }
            yield put({
                type: todoListFetchingSuccess,
                payload: bkgResponse?.data?.data?.info ?? {},
            });
            yield put({
                type: todoItemFetchingSuccess,
                payload: bkgResponse?.data?.data?.info ?? {},
            });
        } else {
            // yield put({ type: todoListFetchingError, payload: ordResponse.data.message });
        }
    } catch (error: any) {
        yield put({ type: todoListFetchingError, payload: error.message });
    } finally {
        yield put(hideLoading());
    }
}

function* getDashboardTodoItem({ payload }: { payload: any }) {
    try {
        yield put(showLoading());

        let callback: any = () => true;
        if (payload.type === 'PO') {
            callback = dashboardTodoItemPO;
        }
        if (payload.type === 'AO') {
            callback = dashboardTodoItemAO;
        }
        if (payload.type === 'Receipt') {
            callback = dashboardTodoItemReceipt;
        }
        if (payload.type === 'BP') {
            callback = dashboardTodoItemBP;
        }
        if (payload.type === 'LCL') {
            callback = dashboardTodoItemLCL;
        }
        if (payload.type === 'pendingReassignment') {
            callback = dashboardTodoItemPendingReassignment;
        }
        if (payload.type === 'OTHER_TODO') {
            callback = otherTodoItem;
        }
        const response: AxiosResponse<any> = yield call(callback, payload);
        if (response?.data?.success) {
            yield put({
                type: todoItemFetchingSuccess,
                payload: response?.data?.data?.info ?? {},
            });
        } else {
            yield put({ type: todoItemFetchingError, payload: response && response.data.message });
        }
    } catch (error: any) {
        yield put({ type: todoItemFetchingError, payload: error.message });
    } finally {
        yield put(hideLoading());
    }
}

function* dashBoardTodoSaga() {
    yield takeLatest(todoListFetching, getDashboardTodoList);
    yield takeEvery(todoItemFetching, getDashboardTodoItem);
}

export default dashBoardTodoSaga;
