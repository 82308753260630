import { AxiosResponse } from 'axios';
// Import the redux-saga/effects
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    getASsignedPos,
    isLoadingFn,
    removeSuccessFn,
    saveAllPoLines,
    successAssigning,
} from '../../reducers/receipt/poLine';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    assignToPo,
    getAllpoLines,
    getAssignedPoLines,
    removeFromReceipt,
} from '../../../services/apis/receipts/poLine';
import { agentResponse } from '../../../services/apis/agentListApi';

// Import all api's

// Here's the unique part, generator function*, function with asterisk(*)

// Get Data
// eslint-disable-next-line
function* getAssignedPo({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const assignedPos: AxiosResponse<agentResponse> = yield call(getAssignedPoLines, payload);
        yield put({ type: getASsignedPos, payload: assignedPos });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* getAllPos({ payload }: { payload: any; type: any }) {
    try {
        // yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        const inboundPos: AxiosResponse<agentResponse> = yield call(getAllpoLines, payload);
        yield put({ type: saveAllPoLines, payload: inboundPos });
    } finally {
        // yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* assignPoReceipt({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(assignToPo, payload);
        yield put({ type: successAssigning, payload: true });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}
function* removePoReceipt({ payload }: { payload: any; type: any }) {
    try {
        yield put({ type: isLoadingFn, payload: true });
        yield put(showLoading());
        yield call(removeFromReceipt, payload);
        //console.log(inboundPos, 'inboundPos');
        yield put({ type: removeSuccessFn, payload: true });
    } finally {
        yield put({ type: isLoadingFn, payload: false });
        yield put(hideLoading());
    }
}

function* ReceiptpoLineSaga() {
    yield takeEvery('receiptPoLine/fetchPolineReceipt', getAssignedPo);
    yield takeEvery('receiptPoLine/fetchAllPoLines', getAllPos);
    yield takeEvery('receiptPoLine/assingPoLines', assignPoReceipt);
    yield takeEvery('receiptPoLine/removePoFromReceipt', removePoReceipt);
}

// Export the saga (data-saga)
export default ReceiptpoLineSaga;
