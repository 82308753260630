import { AxiosResponse } from 'axios';
import { put, call, takeEvery } from 'redux-saga/effects';
import {
    fetchProductDetailsConfirmationList,
    fetchProductDetailsConfirmationListFailure,
    fetchProductDetailsConfirmationListSuccess,
    fetchLclThreshold,
    fetchLclThresholdFailure,
    fetchLclThresholdSuccess,
    confirmPoSuccess,
    confirmPoFailure,
    saveAllPoSuccess,
    saveAllPoFailure,
    submitPoSuccess,
    submitPoFailure,
    confirmPo,
    saveAllPo,
    submitPo,
    removeProductDetailsConfirmationSuccess,
    removeProductDetailsConfirmationFailure,
    removeProductDetailsConfirmation,
    dropProductDetailsConfirmationSuccess,
    dropProductDetailsConfirmationFailure,
    dropProductDetailsConfirmation,
    changePoLineStatus,
    editPoLineStatus,
    editPoLineStatusSuccess,
    editPoLineStatusFailure,
    changePoLineStatusSuccess,
    changePoLineStatusFailure,
} from '../../reducers/purchaseOrder/productDetailsConfirmationReducer';

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {
    fetchProductDetailsConfirmationListDetailsApi,
    fetchLclThresholdDetailsApi,
    confirmPoDetailsApi,
    submitPoDetailsApi,
    submitPOWithoutLCLApi,
    removeProductDetailsConfirmationDetailsApi,
    dropProductDetailsConfirmationDetailsApi,
    changePoLineStatusApi,
    editPoLineStatusApi,
    saveAllPoDetailsApi,
} from '../../../services/apis/purchaseOrder/productDetailsConfirmationApi';

// eslint-disable-next-line
function* getProductDetailsConfirmationDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchProductDetailsConfirmationListDetailsApi, payload);
        yield put({ type: fetchProductDetailsConfirmationListSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchProductDetailsConfirmationListFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* getLclThresholdDetails({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        // yield put({ type: isLoadingFn, payload: true });

        const response: AxiosResponse = yield call(fetchLclThresholdDetailsApi, payload);
        yield put({ type: fetchLclThresholdSuccess, payload: response });
    } catch (error) {
        yield put({ type: fetchLclThresholdFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* confirmPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(confirmPoDetailsApi, payload);
        yield put({ type: confirmPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: confirmPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* saveAllPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(saveAllPoDetailsApi, payload);
        yield put({ type: saveAllPoSuccess, payload: response });
    } catch (error) {
        yield put({ type: saveAllPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* submitPoDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());

        if (payload.isAirFreight) {
            const response: AxiosResponse = yield call(submitPOWithoutLCLApi, { poId: payload.poId });
            yield put({ type: submitPoSuccess, payload: response });
        } else {
            const response: AxiosResponse = yield call(submitPoDetailsApi, payload);
            yield put({ type: submitPoSuccess, payload: response });
        }
    } catch (error) {
        yield put({ type: submitPoFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* changePoLineStatusData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(changePoLineStatusApi, payload);
        yield put({ type: changePoLineStatusSuccess, payload: response });
    } catch (error) {
        yield put({ type: changePoLineStatusFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* editPoLineStatusData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(editPoLineStatusApi, payload);
        yield put({ type: editPoLineStatusSuccess, payload: response });
    } catch (error) {
        yield put({ type: editPoLineStatusFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

// eslint-disable-next-line
function* removeProductDetailsConfirmationDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(removeProductDetailsConfirmationDetailsApi, payload);
        yield put({ type: removeProductDetailsConfirmationSuccess, payload: response });
    } catch (error) {
        yield put({ type: removeProductDetailsConfirmationFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}
// eslint-disable-next-line
function* dropProductDetailsConfirmationDetailsData({ payload }: { payload: any; type: any }) {
    try {
        yield put(showLoading());
        const response: AxiosResponse = yield call(dropProductDetailsConfirmationDetailsApi, payload);
        yield put({ type: dropProductDetailsConfirmationSuccess, payload: response });
    } catch (error) {
        yield put({ type: dropProductDetailsConfirmationFailure, payload: error });
    } finally {
        yield put(hideLoading());
    }
}

function* productDetailsConfirmationSaga() {
    yield takeEvery(fetchProductDetailsConfirmationList, getProductDetailsConfirmationDetails);
    yield takeEvery(fetchLclThreshold, getLclThresholdDetails);
    yield takeEvery(confirmPo, confirmPoDetailsData);
    yield takeEvery(saveAllPo, saveAllPoDetailsData);
    yield takeEvery(submitPo, submitPoDetailsData);
    yield takeEvery(changePoLineStatus, changePoLineStatusData);
    yield takeEvery(editPoLineStatus, editPoLineStatusData);
    yield takeEvery(removeProductDetailsConfirmation, removeProductDetailsConfirmationDetailsData);
    yield takeEvery(dropProductDetailsConfirmation, dropProductDetailsConfirmationDetailsData);
}

export default productDetailsConfirmationSaga;
