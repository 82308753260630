import React from 'react';
import { Link } from 'react-router-dom';
import './po-line-confirmation.scss';
// import CompilanceModal from './ComplianceModal';
// import ToolTip from '../../../../../src/components/ToolTip';
// import ToolTipContentBox from '../../../../../src/components/ToolTipBoxContent';
// import ReceivedQuantityModal from './ReceivedQuantityModal';
// import ViewCompilanceCheckModal from './ViewCompilanceCheckModal';
import ReceiptHeaderTabs from 'src/pages/BLP/ReceiptDetails/ReceiptHeaderTabs';

const Index: React.FC = () => {
    return (
        <div className="main-wrapper container">
            <div className="main-header-content-holder">
                <div className="breadcrumbs-holder">
                    <ul className="breadcrumbs">
                        <Link to="/blp/receipt" className="dropdown-arrow" state={{ reload: true }}>
                            <svg className="arrow-icon">
                                <use xlinkHref="#downArrow">
                                    <title>User details</title>
                                </use>
                            </svg>
                        </Link>
                        <li className="breadcrumb-list-item">
                            <Link className="breadcrumb-item" to="/blp/receipt" state={{ reload: true }}>
                                Receipt
                            </Link>
                        </li>
                        <li className="breadcrumb-list-item">
                            <a href="javascript:void(0)" className="breadcrumb-item">
                                Receipt Details
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="pageheading-holder">
                    {/* <a href="/agent">
                        <button className="back-btn app-btn">
                            <svg className="svg-icon back-icon">
                                <use href="#previousArrowIcon">
                                    <title>Back</title>
                                </use>
                            </svg>
                        </button>
                    </a> */}
                    <h2 className="page-heading">123AB11</h2>
                </div>
                <ReceiptHeaderTabs />
            </div>
            <div className="grid-section po-line-confirmation-details-grid">
                {/* <div className="app-loader loading">
                    <svg className="svg-icon loader-icon">
                        <use xlinkHref="#loaderIcon">
                            <title>Loading</title>
                        </use>
                    </svg>
                </div> */}

                <div className="grid-holder">
                    <div className="grid-header">
                        <div className="search-entry-holder">
                            <input
                                placeholder="Search by PO Number, SKU"
                                type="text"
                                className="search-input input-text"
                            ></input>
                            <svg className="svg-icon search-icon">
                                <use xlinkHref="#searchIcon"></use>
                            </svg>
                            <button className="app-btn text-close-btn">
                                <svg className="svg-icon text-close-icon">
                                    <use xlinkHref="#closeIcon"></use>
                                </svg>
                            </button>
                        </div>
                        <div className="tab-with-proposal">
                            <div className="tab-grid-select-holder">
                                <span className="btn-pills info-pill">
                                    <span className="pill-text">1 Selected</span>
                                    <button className="pill-action">
                                        <svg className="svg-icon close-icon">
                                            <use xlinkHref="#closeIcon"></use>
                                        </svg>
                                    </button>
                                </span>
                                <button className="app-btn app-btn-primary footer-btn">Receive in Full</button>
                            </div>

                            {/* <div className="btn-holder">
                                <button className="app-btn app-btn-secondary icon-button" title="Assign PO Lines">
                                    <svg className="svg-icon add-btn-icon">
                                        <use xlinkHref="#plusIcon"></use>
                                    </svg>
                                    <span className="button-text">Assign PO Lines</span>
                                </button>
                            </div> */}
                        </div>
                    </div>

                    <div className="grid-container">
                        {/* <div className="grid-no-content ">
                            <svg className="svg-icon grid-no-content-icon ">
                                <use xlinkHref="#gridNoContentIcon"></use>
                            </svg>
                            <p className="no-content-message">No PO Lines added</p>
                        </div> */}

                        <div className="grid-scroll-content">
                            <table className="grid-table ">
                                <thead className="multi-row-grid-header">
                                    <tr className="grid-sticky-row">
                                        <th className="grid-cell-header col-inbound-po grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data">
                                                <span className="header-title">INBOUND PO NUMBER</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data centre-align">
                                                <label className="app-check-wrapper">
                                                    <input type="checkbox" className="checkbox-input"></input>
                                                    <div className="checkmark">
                                                        <svg className="svg-icon tick-icon">
                                                            <use href="#tickIcon">
                                                                <title>check mark</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                </label>
                                                <div
                                                    className="grid-header-checkbox-title"
                                                    title="Select line to receive in full"
                                                >
                                                    RIF
                                                    <span>
                                                        <svg className="svg-icon error-icon">
                                                            <use xlinkHref="#errorIcon">
                                                                <title>Warning</title>
                                                            </use>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-line">
                                            <div className="grid-cell-data">
                                                <span className="header-title">PO LINE No.</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-sku">
                                            <div className="grid-cell-data">
                                                <span className="header-title">SKU</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-desc">
                                            <div className="grid-cell-data">
                                                <span className="header-title">DESCRIPTION</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-desc">
                                            <div className="grid-cell-data">
                                                <span className="header-title">STATUS</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-prq">
                                            <div className="grid-cell-data">
                                                <span className="header-title">PLANNED RECEIPT QTY</span>
                                            </div>
                                        </th>
                                        <th
                                            className="grid-cell-header multi-column-th  po-th-qty centre-align"
                                            colSpan={2}
                                        >
                                            <div className="grid-cell-data">
                                                <span className="header-title">qty received</span>
                                            </div>
                                        </th>

                                        <th className="grid-cell-header grid-sticky-column sticky-right-aligned col-user-action ">
                                            <div className="grid-cell-data">ACTION</div>
                                        </th>
                                    </tr>
                                    <tr className="grid-sticky-row">
                                        <th className="grid-cell-header col-po-no grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-checkbox grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-available">
                                            <div className="grid-cell-data">
                                                <span>AVAILABLE</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header col-on-hold">
                                            <div className="grid-cell-data">
                                                <span>ON HOLD</span>
                                            </div>
                                        </th>
                                        <th className="grid-cell-header  grid-sticky-column sticky-right-aligned col-user-action ">
                                            <div className="grid-cell-data">
                                                <span></span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                {/* One detailed row */}

                                <tbody className="grouped-splited-row">
                                    <tr>
                                        <td className="grid-cell col-inbound-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                            <div className="grid-cell-data">
                                                <div className="checkbox-data-wrapper">
                                                    <label className="app-check-wrapper">
                                                        <input type="checkbox" className="checkbox-input" />

                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                    <a href="javascript:void(0)" className="proposal-link">
                                                        B70678
                                                    </a>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data centre-align">
                                                <div className="checkbox-data-wrapper">
                                                    <label className="app-check-wrapper">
                                                        <input type="checkbox" className="checkbox-input" />

                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                <a>1</a>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                {' '}
                                                <div className="link-tooltip-holder">
                                                    <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                                                        <a href="javascript:void(0)" className="booking-proposal-link">
                                                            B70678
                                                            <span
                                                                className="grid-icon-holder"
                                                                title="Compliance Check Failed View Reason"
                                                            >
                                                                <svg className="svg-icon error-close-icon">
                                                                    <use xlinkHref="#errorCloseIcon"></use>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                        <div className="tooltip-holder bd-tooltip-holder information-tooltip with-link">
                                                            <div className="tooltip-subheading">
                                                                Compliance Check Failed
                                                                <a className="tooltip-link">View Reason</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-qty">
                                            <div className="grid-cell-data">Threaded nuts</div>
                                        </td>
                                        <td className="grid-cell col-po-grd">
                                            <div className="grid-cell-data">
                                                <div className="allocation-status">
                                                    <div className="allocated-status pending">
                                                        Over Received{' '}
                                                        <svg className="svg-icon error-icon">
                                                            <use xlinkHref="#errorIcon">
                                                                <title>Warning</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-cbm">
                                            <div className="grid-cell-data">50</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data disabled">0</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data disabled">0</div>
                                        </td>
                                        <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned col-user-action">
                                            <div className="grid-cell-data">
                                                <button
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit PO Line"
                                                >
                                                    <svg className="svg-icon edit-icon icon-20">
                                                        <use xlinkHref="#editIcon">
                                                            <title>Edit</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <button className="action-btn app-btn app-btn-secondary" title="Note">
                                                    <svg className="svg-icon icon-20">
                                                        <use xlinkHref="#noteIcon">
                                                            <title>Note</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="grid-cell col-inbound-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                            <div className="grid-cell-data"></div>
                                        </td>
                                        <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data centre-align">
                                                <div className="checkbox-data-wrapper">
                                                    <label className="app-check-wrapper">
                                                        <input type="checkbox" className="checkbox-input" />

                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                <a></a>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                {' '}
                                                <div className="link-tooltip-holder">
                                                    <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                                                        <a href="javascript:void(0)" className="booking-proposal-link">
                                                            B70678
                                                            <span
                                                                className="grid-icon-holder"
                                                                title="Compliance Check Passed"
                                                            >
                                                                <svg className="svg-icon success-badge-icon">
                                                                    <use xlinkHref="#successgridBadgeIcon"></use>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                        <div className="tooltip-holder bd-tooltip-holder information-tooltip without-link">
                                                            <div className="tooltip-subheading">
                                                                Compliance Check Passed
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-qty">
                                            <div className="grid-cell-data">Threaded nuts</div>
                                        </td>
                                        <td className="grid-cell col-po-grd">
                                            <div className="grid-cell-data">
                                                <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                                                    <div className="allocation-status">
                                                        <div className="allocated-status completed">
                                                            Received in full{' '}
                                                            <svg className="svg-icon error-icon">
                                                                <use xlinkHref="#errorIcon">
                                                                    <title>Warning</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    <div className="tooltip-holder bd-tooltip-holder information-tooltip">
                                                        <div className="tooltip-subheading">
                                                            {/* On Hold text also used for complete class */}
                                                            Short Receipt<a className="tooltip-link">View Reason</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-cbm">
                                            <div className="grid-cell-data">50</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data">0</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data">0</div>
                                        </td>
                                        <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned col-user-action">
                                            <div className="grid-cell-data">
                                                <button
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit PO Line"
                                                >
                                                    <svg className="svg-icon edit-icon icon-20">
                                                        <use xlinkHref="#editIcon">
                                                            <title>Edit</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <button className="action-btn app-btn app-btn-secondary" title="Note">
                                                    <svg className="svg-icon icon-20">
                                                        <use xlinkHref="#noteIcon">
                                                            <title>Note</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="grid-cell col-inbound-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                            <div className="grid-cell-data"></div>
                                        </td>
                                        <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data centre-align">
                                                <div className="checkbox-data-wrapper">
                                                    <label className="app-check-wrapper">
                                                        <input type="checkbox" className="checkbox-input" />

                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                <a>2</a>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                {' '}
                                                <div className="link-tooltip-holder">
                                                    <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                                                        <a href="javascript:void(0)" className="booking-proposal-link">
                                                            B70678
                                                            <span
                                                                className="grid-icon-holder"
                                                                title="Compliance Check Pending"
                                                            >
                                                                <svg className="svg-icon clock-icon">
                                                                    <use xlinkHref="#clockIcon"></use>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                        <div className="tooltip-holder bd-tooltip-holder information-tooltip without-link">
                                                            <div className="tooltip-subheading">
                                                                Compliance Check Pending
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-qty">
                                            <div className="grid-cell-data">Threaded nuts</div>
                                        </td>
                                        <td className="grid-cell col-po-grd">
                                            <div className="grid-cell-data">
                                                <div className="allocation-status">
                                                    <div className="allocated-status in-progress">
                                                        Ready to Receive
                                                        {/* <svg className="svg-icon error-icon">
                                                            <use xlinkHref="#errorIcon">
                                                                <title>Warning</title>
                                                            </use>
                                                        </svg> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-cbm">
                                            <div className="grid-cell-data">50</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data">0</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data">0</div>
                                        </td>
                                        <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned col-user-action">
                                            <div className="grid-cell-data">
                                                <button
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit PO Line"
                                                >
                                                    <svg className="svg-icon edit-icon icon-20">
                                                        <use xlinkHref="#editIcon">
                                                            <title>Edit</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <button className="action-btn app-btn app-btn-secondary" title="Note">
                                                    <svg className="svg-icon icon-20">
                                                        <use xlinkHref="#noteIcon">
                                                            <title>Note</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="grid-cell col-inbound-po-number grid-sticky-column sticky-left-aligned po-number-grid">
                                            <div className="grid-cell-data"></div>
                                        </td>
                                        <td className="grid-cell col-checkbox grid-sticky-column sticky-left-aligned">
                                            <div className="grid-cell-data centre-align">
                                                <div className="checkbox-data-wrapper">
                                                    <label className="app-check-wrapper">
                                                        <input type="checkbox" className="checkbox-input" />

                                                        <div className="checkmark">
                                                            <svg className="svg-icon tick-icon">
                                                                <use xlinkHref="#tickIcon">
                                                                    <title>check mark</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                <a>3</a>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-sku">
                                            <div className="grid-cell-data">
                                                {' '}
                                                <div className="link-tooltip-holder">
                                                    <div className="tooltip-container top-right-tooltip" tabIndex={0}>
                                                        <a href="javascript:void(0)" className="booking-proposal-link">
                                                            B70678
                                                            <span
                                                                className="grid-icon-holder"
                                                                title="Compliance Check Pending"
                                                            >
                                                                <svg className="svg-icon clock-icon">
                                                                    <use xlinkHref="#clockIcon">
                                                                        <title>Close</title>
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </a>
                                                        <div className="tooltip-holder bd-tooltip-holder information-tooltip without-link">
                                                            <div className="tooltip-subheading">
                                                                Compliance Check Pending
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-qty">
                                            <div className="grid-cell-data">Threaded nuts</div>
                                        </td>
                                        <td className="grid-cell col-po-grd">
                                            <div className="grid-cell-data">
                                                <div className="allocation-status">
                                                    <div className="allocated-status in-hold">
                                                        Short Receipt
                                                        {/* <svg className="svg-icon error-icon">
                                                            <use xlinkHref="#errorIcon">
                                                                <title>Warning</title>
                                                            </use>
                                                        </svg> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="grid-cell col-po-cbm">
                                            <div className="grid-cell-data">50</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data">0</div>
                                        </td>
                                        <td className="grid-cell col-po-weight">
                                            <div className="grid-cell-data">0</div>
                                        </td>
                                        <td className="grid-cell buttons-col-align grid-sticky-column sticky-right-aligned col-user-action">
                                            <div className="grid-cell-data">
                                                <button
                                                    className="action-btn app-btn app-btn-secondary "
                                                    title="Edit PO Line"
                                                >
                                                    <svg className="svg-icon edit-icon icon-20">
                                                        <use xlinkHref="#editIcon">
                                                            <title>Edit</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                                <button className="action-btn app-btn app-btn-secondary" title="Note">
                                                    <svg className="svg-icon icon-20">
                                                        <use xlinkHref="#noteIcon">
                                                            <title>Note</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row">
                <div className="footer-button-holder ">
                    <a className="app-btn app-btn-secondary footer-btn" title="Previous">
                        <span className="button-text footer-button-text">Previous</span>
                    </a>
                    <a className="app-btn app-btn-primary footer-btn " title="Next">
                        <span className="button-text footer-button-text">Next</span>
                    </a>
                </div>
            </div>
            {/* <CompilanceModal></CompilanceModal> */}
            {/* <ReceivedQuantityModal></ReceivedQuantityModal> */}
            {/* <ViewCompilanceCheckModal></ViewCompilanceCheckModal> */}
        </div>
    );
};
export default Index;
