export const contractDetails = [
    {
        columnName: 'NAME',
        name: 'sd_docname',
        headerClass: 'grid-cell-header col-contract-name grid-sticky-column sticky-left-aligned',
        bodyClass: 'grid-cell td-name grid-sticky-column sticky-left-aligned no-border',
        hasSort: true,
        sortName: 'sd_docname',
        hasFilter: true,
        filterName: 'sd_docname',
    },
    {
        columnName: 'VERSION',
        name: 'sd_version',
        headerClass: 'grid-cell-header col-contract-version grid-sticky-column sticky-left-aligned',
        bodyClass: 'grid-cell td-version grid-sticky-column sticky-left-aligned',
        hasSort: true,
        sortName: 'sd_version',
        hasFilter: true,
        filterName: 'sd_version',
    },
    {
        columnName: 'AUTHOR',
        name: 'sd_author',
        headerClass: 'grid-cell-header col-contract-author',
        bodyClass: 'grid-cell td-author',
        hasSort: true,
        sortName: 'sd_author',
        hasFilter: true,
        filterName: 'sd_author',
    },
    {
        columnName: 'FORWARDER SIGNATORY',
        name: 'sd_forwardersignatory',
        headerClass: 'grid-cell-header col-contract-signatory',
        bodyClass: 'grid-cell td-forwarder-signatory',
        hasSort: true,
        sortName: 'sd_forwardersignatory',
        hasFilter: true,
        filterName: 'sd_forwardersignatory',
    },
    // this is added for coloader column in coloader contract detail want to show only for contract -coloader not for shipping line
    // {
    //     columnName: 'COLOADER SIGNATORY',
    //     name: 'sd_forwardersignatory',
    //     headerClass: 'grid-cell-header col-co-loader-signatory',
    //     bodyClass: 'grid-cell td-forwarder-signatory',
    //     hasSort: true,
    //     sortName: 'sd_forwardersignatory',
    //     hasFilter: true,
    //     filterName: 'sd_forwardersignatory',
    // },
    {
        columnName: 'CARRIER SIGNATORY',
        name: 'sd_shippingLineSignatory',
        headerClass: 'grid-cell-header col-contract-shipping-signatory',
        bodyClass: 'grid-cell td-agent-signatory',
        hasSort: true,
        sortName: 'sd_shippingLineSignatory',
        hasFilter: true,
        filterName: 'sd_shippingLineSignatory',
    },
    {
        columnName: 'STATUS',
        name: 'sd_status',
        headerClass: 'grid-cell-header col-contract-status',
        bodyClass: 'grid-cell td-status',
        hasSort: true,
        sortName: 'sd_status',
        hasFilter: true,
        filterName: 'sd_status',
    },
    {
        columnName: 'START DATE',
        name: 'sd_startdate',
        headerClass: 'grid-cell-header col-contract-startdate',
        bodyClass: 'grid-cell td-start-date',
        hasSort: true,
        sortName: 'sd_startdate',
        hasFilter: false,
        filterName: 'sd_startdate',
    },
    {
        columnName: 'END DATE',
        name: 'sd_enddate',
        headerClass: 'grid-cell-header col-contract-enddate',
        bodyClass: 'grid-cell td-end-date',
        hasSort: true,
        sortName: 'sd_enddate',
        hasFilter: false,
        filterName: 'sd_enddate',
    },
    {
        columnName: 'RENEWAL',
        name: 'sd_renewal',
        headerClass: 'grid-cell-header col-contract-renewal',
        bodyClass: 'grid-cell td-renewal',
        hasSort: true,
        sortName: 'sd_renewal',
        hasFilter: true,
        filterName: 'sd_renewal',
    },
];
