import moment from 'moment';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { portTimeLineFetch } from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchReducer';
import '../lcsBookingTiimeline.scss';
import MaxLimitModal from '../MaxLimitModal';
import {
    addToFavList,
    fetchFavList,
    removeFromFav,
    resetAddSuccessFlag,
    resetErrorFlags,
    resetRemoveSuccessFlag,
} from 'src/store/reducers/lifeCycleSearch/lifeCycleSearchFavouritesReducer';
import { checkIfAddedToFav } from './Common/favoutitesUtils';
import { RootState } from 'src/store/reducers';
import { lifeCycleSearchCategories } from './Common/constants';

const Index: React.FC = () => {
    const { portsTimeline } = useSelector((state: any) => state.lifeCycleSearch) as any;
    const location = useLocation() as any;
    const dispatch = useDispatch();
    const { id } = useParams();
    const [showModal, setShowModal] = useState(false);
    const { userName, profileType } = useUserProfile();
    const [showMore, setshowMore] = useState(false);
    const {
        favList,
        removeSuccess,
        addSuccess,
        isLoading: isFavLoading,
        isError,
        error,
    } = useSelector((state: RootState) => state.lifecyclesearchFav);
    const { selectedData } = useSelector((state: any) => state.lifeCycleSearch);

    useLayoutEffect(() => {
        dispatch(portTimeLineFetch({ id: id, profile: profileType, userName }));
    }, [id, profileType]);

    useEffect(() => {
        if (removeSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetRemoveSuccessFlag());
        }
    }, [removeSuccess]);

    useEffect(() => {
        if (addSuccess) {
            dispatch(fetchFavList({ userName, profileType }));
            dispatch(resetAddSuccessFlag());
            if (showModal) {
                setShowModal(false);
            }
        }
    }, [addSuccess]);

    useEffect(() => {
        if (isError && error?.statusCode === 400) {
            setShowModal(true);
            dispatch(resetErrorFlags());
        }
    }, [isError]);

    const addOrRemoveFromFavList = (removeFirstFlag?: boolean) => {
        const isAdded = checkIfAddedToFav(selectedData, favList);
        if (isAdded) {
            const favAdded = favList?.favourites?.find((x: any) => x.searchResult === JSON.stringify(selectedData));
            if (favAdded) {
                dispatch(removeFromFav({ id: favAdded.id }));
            }
        } else {
            dispatch(
                addToFavList({
                    userName,
                    profile: profileType,
                    category: lifeCycleSearchCategories.port,
                    favourite: 'Port',
                    number: location.state?.name,
                    isIbpo: false,
                    isItpo: false,
                    isAo: false,
                    isRemoveFirst: removeFirstFlag || false,
                    searchResult: JSON.stringify(selectedData),
                })
            );
        }
    };

    const onConfirm = () => {
        addOrRemoveFromFavList(true);
    };

    return (
        <div className="lcs-booking-timeline customscrollbar">
            <div className="timeline-head">
                <div className="timeline-head-title">Port</div>
                <div className="timeline-head-value">
                    {portsTimeline?.portName}({portsTimeline?.portCode})
                </div>
                <div className="icons-holder">
                    <button
                        className={`action-btn app-btn icon-only sm app-btn-secondary fav-icon-holder--star ${
                            checkIfAddedToFav(selectedData, favList) ? 'selected-fav-icon' : 'unselected-fav-icon'
                        }`}
                        onClick={() => {
                            favList?.favourites?.length === 50 && checkIfAddedToFav(selectedData, favList) === false
                                ? setShowModal(true)
                                : addOrRemoveFromFavList();
                        }}
                        disabled={isFavLoading}
                    >
                        <svg className="svg-icon star-icon">
                            <use xlinkHref="#starIcon">
                                <title>Favourites</title>
                            </use>
                        </svg>
                    </button>
                </div>
            </div>
            <div className="cm-key-pair-details">
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Country</div>
                    <div className="cm-key-pair-value">
                        {portsTimeline?.countryName ? portsTimeline?.countryName : '-'}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Unlocode</div>
                    <div className="cm-key-pair-value">{portsTimeline?.unlocode ? portsTimeline?.unlocode : '-'}</div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">No of clients linked</div>
                    <div className="cm-key-pair-value">
                        {portsTimeline?.noOfClients ? portsTimeline?.noOfClients : '-'}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">No of vendors linked</div>
                    <div className="cm-key-pair-value">
                        {portsTimeline?.noOfVendors ? portsTimeline?.noOfVendors : '-'}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Agents Assosiated</div>
                    <div className="cm-key-pair-value">
                        {portsTimeline?.agents
                            ?.slice(0, showMore ? portsTimeline?.agents?.length : 3)
                            .map((agent: any) => agent)
                            .join(',')}
                        {portsTimeline?.agents?.length > 3 && !showMore && (
                            <div className="content-with-icon view-more">
                                <a
                                    href="javascript:void(0)"
                                    className="file-remove-link"
                                    title="More"
                                    onClick={() => setshowMore(true)}
                                >
                                    View More
                                </a>
                            </div>
                        )}
                        {portsTimeline?.agents?.length > 3 && showMore && (
                            <div className="content-with-icon view-more">
                                <a
                                    href="javascript:void(0)"
                                    className="file-remove-link"
                                    title="More"
                                    onClick={() => setshowMore(false)}
                                >
                                    View Less
                                </a>
                            </div>
                        )}
                    </div>
                </div>
                <div className="cm-key-pair-items">
                    <div className="cm-key-pair-title">Last used date</div>
                    <div className="cm-key-pair-value">
                        {portsTimeline?.lastUsedDate
                            ? moment(portsTimeline?.lastUsedDate).format('DD-MM-YYYY ,HH:mm')
                            : '-'}
                    </div>
                </div>
            </div>
            {showModal ? (
                <MaxLimitModal
                    setShowModal={setShowModal}
                    onConfirm={onConfirm}
                    data={`Port : ${location.state?.name}`}
                ></MaxLimitModal>
            ) : null}
        </div>
    );
};
export default Index;
