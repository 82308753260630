import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Position, success, error } from 'src/utils/toast';
import { RootState } from 'src/store/reducers';
import { dashboardTodoFavoriteToggle } from 'src/services/apis/dashboard/dashboardTodoApi';
import { useUserProfile } from 'src/hooks/useUserProfile';
import { setTodoType } from 'src/store/reducers/dashboard/dashboardTodoReducer';

import { TODO_ICON_MAP } from '../../utils/dashboardTodoConstants';

import '../../dashboard.scss';
import './todofeed.scss';
import '../todo-activity-tab.scss';
import BSTooltip from 'src/components/common/BSTooltip';
import moment from 'moment';
// import { getOrganisationData } from 'src/utils';

interface TodoProps {
    setfavoriteUpdated: any;
    favoriteUpdated: boolean;
    tab: any;
}

const Index: React.FC<TodoProps> = ({ setfavoriteUpdated, favoriteUpdated, tab }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { todoList, isLoading, todoListTotalCount } = useSelector((state: RootState) => state.dashboardTodo);

    // const listOfUserOrganisations = getOrganisationData();
    // const userDetails =
    //     localStorage?.getItem('wholePerms') != null && JSON.parse(localStorage?.getItem('wholePerms') || '');
    const { profileType, userName, roleName } = useUserProfile();

    async function handleFavoriteClick(todoType: string) {
        const response = await dashboardTodoFavoriteToggle({ profileType, userName, todoType });
        if (response?.data?.success) {
            success(`Favorites updated`, Position.TOP_RIGHT);
            setfavoriteUpdated(!favoriteUpdated);
            //dispatch(
            //    todoListFetching({
            //        ...listOfUserOrganisations,
            //        profileType,
            //        userName,
            //        roleName: userDetails?.accountManager ? 'Account Manager' : roleName,
            //    })
            //);
        } else {
            error(response?.data?.data, Position.TOP_RIGHT);
        }
    }

    const zeroCountValidation = (data: any) => {
        let flag = false;
        data?.forEach((element: any) => {
            if (element?.count > 0 && !flag) {
                flag = true;
            }
        });
        return flag;
    };
    const getTrendValue = (todoData: any) => {
        const value = todoData?.count - todoData?.trend?.value;
        if (value === 0) {
            return value;
        } else if (value > 0) {
            return `+${value}`;
        } else {
            return value;
        }
    };

    const tabType = tab === 'To Do' ? 'todo' : 'activity';
    const tabTypeUrl = tab === 'To Do' ? 'to-do' : 'activity-feed';

    return (
        <div className="todo-menu-items-wrapper">
            {isLoading && (
                <div className="graph-holder">
                    <div className="graph-scaler loading"></div>
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                </div>
            )}
            {!isLoading && !todoList?.others?.[tabType]?.length && (
                //|| roleName === 'executive'
                <div className="no-data-content">
                    <svg className="svg-icon grid-no-content-icon ">
                        <use xlinkHref="#gridNoContentIcon"></use>
                    </svg>
                    <p className="no-content-message">No items to action</p>
                </div>
            )}
            {!!todoList?.others?.[tabType]?.length && (
                <div className="view-list-grid">
                    <div className="favourite-items-wrapper">
                        {!!todoList?.favourites?.length && zeroCountValidation(todoList?.favourites) && (
                            // roleName !== 'executive' &&
                            <div className="heading-title">
                                <div className="main-heading">Favourites</div>
                                <div className="sub-heading">Only Favourites are listed in Dashboard Main view</div>
                            </div>
                        )}
                        <div className="list-grid selected-todo-items">
                            {/* .star-icon --.starred is using for to fill the star color */}
                            {/* button - class:.selected-fav-icon and svg - class:.starred is used for selected star */}
                            {/* button - class:.unselected-fav-icon*/}

                            {
                                //roleName !== 'executive' &&
                                todoList?.favourites?.map(
                                    (todo: any) =>
                                        !!todo?.count && (
                                            <div className="todo-item">
                                                <button
                                                    className="app-btn item-icon-content-wrapper"
                                                    onClick={() => {
                                                        dispatch(setTodoType({ type: todo?.type }));
                                                        navigate(`/dashboard/${tabTypeUrl}/${todo?.type}`);
                                                    }}
                                                >
                                                    <div className="item-icon-holder">
                                                        <svg
                                                            className={`svg-icon ${
                                                                TODO_ICON_MAP[todo?.type ?? '']?.icon
                                                            }`}
                                                        >
                                                            <use xlinkHref={TODO_ICON_MAP[todo?.type ?? '']?.iconType}>
                                                                <title>Notifications</title>
                                                            </use>
                                                        </svg>
                                                    </div>
                                                    <div className="item-name-count">
                                                        <div
                                                            className="app-btn item-name-count"
                                                            title={TODO_ICON_MAP[todo?.type ?? '']?.name}
                                                        >
                                                            {TODO_ICON_MAP[todo?.type ?? '']?.name}
                                                        </div>
                                                        <span
                                                            className={`count ${
                                                                todo?.updated ? 'count-notification' : ''
                                                            }`}
                                                        >
                                                            {todo.count}
                                                        </span>
                                                    </div>
                                                </button>
                                                <button
                                                    className="app-btn fav-icon-holder selected-fav-icon"
                                                    title="Selected Favourite"
                                                    onClick={() => handleFavoriteClick(todo.type)}
                                                >
                                                    <svg className="svg-icon star-icon starred">
                                                        <use xlinkHref="#starIcon">
                                                            <title>Notifications</title>
                                                        </use>
                                                    </svg>
                                                </button>
                                            </div>
                                        )
                                )
                            }
                        </div>
                    </div>
                    {
                        //roleName !== 'executive' &&
                        !!todoList?.others?.[tabType]?.length &&
                            !!todoList?.favourites?.length &&
                            zeroCountValidation(todoList?.others?.[tabType]) && (
                                <div className="heading-title">
                                    <div className="main-heading">Others</div>
                                </div>
                            )
                    }
                    <div className="list-grid unselected-todo-item">
                        {
                            //roleName !== 'executive' &&
                            todoList?.others?.[tabType]?.map(
                                (todo: any, index: number) =>
                                    !!todo?.count && (
                                        <div className="todo-item">
                                            <button
                                                className="app-btn item-icon-content-wrapper"
                                                onClick={() => {
                                                    dispatch(setTodoType({ type: todo?.type }));
                                                    navigate(
                                                        `/dashboard/${tabTypeUrl}/${
                                                            TODO_ICON_MAP[todo?.type ?? '']?.name === 'OTHER_TODO'
                                                                ? todo?.type + '?type=' + todo?.name
                                                                : todo?.type
                                                        }`
                                                    );
                                                }}
                                            >
                                                <div className="item-icon-holder">
                                                    <svg
                                                        className={`svg-icon ${TODO_ICON_MAP[todo?.type ?? '']?.icon}`}
                                                    >
                                                        <use xlinkHref={TODO_ICON_MAP[todo?.type ?? '']?.iconType}>
                                                            <title>Notifications</title>
                                                        </use>
                                                    </svg>
                                                </div>
                                                <div
                                                    className="item-name-count"
                                                    style={{
                                                        width: '100%',
                                                        justifyContent: 'space-between',
                                                    }}
                                                >
                                                    <div
                                                        className="item-name-count"
                                                        title={
                                                            TODO_ICON_MAP[todo?.type ?? '']?.name === 'OTHER_TODO'
                                                                ? todo?.name
                                                                : TODO_ICON_MAP[todo?.type ?? '']?.name
                                                        }
                                                    >
                                                        {TODO_ICON_MAP[todo?.type ?? '']?.name === 'OTHER_TODO'
                                                            ? todo?.name
                                                            : TODO_ICON_MAP[todo?.type ?? '']?.name}
                                                    </div>
                                                    <div>
                                                        <span
                                                            className={`count ${
                                                                todo?.updated ? 'count-notification' : ''
                                                            }`}
                                                        >
                                                            {todo.count}
                                                        </span>
                                                        <BSTooltip
                                                            id={`todo-${index}`}
                                                            title={
                                                                todo?.trend?.date
                                                                    ? `Last Trend: ${moment(todo?.trend?.date)
                                                                          .format('DD-MMM-YYYY')
                                                                          ?.toUpperCase()}`
                                                                    : '-'
                                                            }
                                                            position="left"
                                                            adaptivePosition="true"
                                                        >
                                                            <span
                                                                className={`count trend-custom-color ${
                                                                    todo?.updated ? 'count-notification' : ''
                                                                }`}
                                                            >
                                                                {getTrendValue(todo)}
                                                            </span>
                                                        </BSTooltip>
                                                    </div>
                                                </div>
                                            </button>
                                            {/* <button
                                                className="app-btn fav-icon-holder unselected-fav-icon"
                                                title="Select Favourite"
                                                onClick={() => handleFavoriteClick(todo.type)}
                                            >
                                                <svg className="svg-icon star-icon">
                                                    <use xlinkHref="#starIcon">
                                                        <title>Notifications</title>
                                                    </use>
                                                </svg>
                                            </button> */}
                                        </div>
                                    )
                            )
                        }
                    </div>
                </div>
            )}
        </div>
    );
};
export default Index;
