import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from '../../hooks/usePagination';
// import { setCurrentPage } from 'src/store/reducers/clientListReducer';

interface Props {
    onPageChange: any;
    totalCount: number;
    siblingCount?: number;
    currentPage: number;
    pageSize: number;
    totalPageSize?: number;
}

const Pagination: React.FC<Props> = (props) => {
    const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, totalPageSize } = props;

    const paginationRange = usePagination({
        currentPage,
        totalCount,
        siblingCount,
        pageSize,
        totalPageSize,
    });

    const onNext = () => {
        onPageChange(currentPage + 1);
    };

    const onPrevious = () => {
        onPageChange(currentPage - 1);
    };

    const lastPage = paginationRange && paginationRange[paginationRange.length - 1];
    return (
        <>
            <button
                className="app-btn pagination-link pagination-nav pagination-prev-nav app-btn-secondary"
                disabled={currentPage === 1}
                onClick={onPrevious}
            >
                <svg className="svg-icon arrow-icon">
                    <use xlinkHref="#arrowIcon">
                        <title>Previous</title>
                    </use>
                </svg>
            </button>
            {paginationRange &&
                paginationRange.map((pageNumber, index) => {
                    if (pageNumber === DOTS) {
                        return (
                            <button key={index} className="app-btn pagination-more">
                                &#8230;
                            </button>
                        );
                    }

                    return (
                        <span key={index}>
                            <button
                                className={classnames('app-btn pagination-link app-btn-secondary', {
                                    active: pageNumber === currentPage,
                                })}
                                onClick={() => onPageChange(pageNumber)}
                            >
                                {pageNumber}
                            </button>
                        </span>
                    );
                })}
            <button
                className="app-btn pagination-link pagination-nav pagination-next-nav app-btn-secondary"
                disabled={currentPage === lastPage}
                onClick={onNext}
            >
                <svg className="svg-icon arrow-icon">
                    <use xlinkHref="#arrowIcon">
                        <title>Next</title>
                    </use>
                </svg>
            </button>
        </>
    );
};

export default Pagination;
