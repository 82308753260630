import React from 'react';
import { Link } from 'react-router-dom';
// import './style.css';
import '../../../assets/scss/components/_modal.scss';

interface BioProps {
    skuUnavailableModalVisibility: boolean;
    setSkuUnavailableModalVisibility: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    clientId: string;
}

const Index: React.FC<BioProps> = (props) => {
    return (
        <>
            {props.skuUnavailableModalVisibility ? (
                <div style={{ zIndex: 9999 }} className="app-modal container">
                    <div className="modal-content-holder" onClick={(e) => e.stopPropagation()}>
                        <div className="modal-header">
                            <h2 className="title">Go to Product Master</h2>
                            <button
                                className="app-btn modal-close-btn"
                                onClick={() => props.setSkuUnavailableModalVisibility(false)}
                            >
                                <span>
                                    <svg className="svg-icon modal-header-close-icon">
                                        <use xlinkHref="#closeIcon">
                                            <title>Close</title>
                                        </use>
                                    </svg>
                                </span>
                            </button>
                        </div>
                        <div className="modal-content">
                            <p>
                                The PO will be saved as draft and you will be redirected to the Product Master page.
                                Continue?
                            </p>
                        </div>
                        <div className="modal-footer">
                            <button
                                className="app-btn app-btn-secondary modal-btn footer-btn"
                                onClick={() => props.setSkuUnavailableModalVisibility(false)}
                            >
                                <span className="button-text vendor-button-text">No</span>
                            </button>
                            <Link
                                className="app-btn app-btn-primary modal-btn footer-btn"
                                style={{ padding: '10px 8px' }}
                                to={`/profile/clients/${props.clientId}/product_master`}
                                onClick={() => {
                                    props.setSkuUnavailableModalVisibility(false);
                                }}
                            >
                                <span className="button-text vendor-button-text">Yes</span>
                            </Link>
                        </div>
                    </div>
                </div>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default Index;
