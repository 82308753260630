/* eslint-disable no-undefined */
import React, { useEffect, useState } from 'react';
import '../../../../assets/scss/components/_layout.scss';
import '../../../../assets/scss/components/_modal.scss';
import { useFormik } from 'formik';
import './planned-receipt-modal.scss';
import * as Yup from 'yup';

import DatePicker from '../../../../components/common/DatePicker';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import {
    fetchReceiptHeaderList,
    selectShippingTerms,
    receiptHeaderData,
    selectReceiptHeaderIsError,
    selectReceiptHeaderSuccess,
    selectReceiptHeaderError,
    clearFailure,
    updateReceiptHeader,
} from '../../../../store/reducers/receipts/receiptHeaderReducer';
import { error, Position, success } from '../../../../utils/toast';
interface ModalProps {
    display: boolean;
    displayFunction: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    setDisplayCancel: (value: boolean | ((prevVar: boolean) => boolean)) => void;
    data: receiptHeaderData;
}

const Index: React.FC<ModalProps> = ({ display, displayFunction, setDisplayCancel, data }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [shippingTermsDropDown, setShippingTermsDropDown] = useState<boolean>(false);
    const [fumigationRequiredDropDown, setFumigationRequiredDropDown] = useState<boolean>(false);
    const [customInspectionRequiredDropDownOpen, setCustomInspectionRequiredDropDownOpen] = useState<boolean>(false);
    const [qcInspectionDropDownOpen, setQcInspectionDropDownOpen] = useState<boolean>(false);
    // const [validation, setValidation] = useState(false);
    const { id } = useParams();

    const ReceiptHeaderSchema = Yup.object().shape({
        plannedCustomsClearance: Yup.date().required('Planned Customs Clearance is required'),
        plannedGoodsReceivedDate: Yup.date().required('Planned Goods Received Date is required'),
        shippingTerms: Yup.string().required('Shipping Terms is required'),
        fumigationRequired: Yup.boolean().nullable().required('Fumigation Required is required'),
        customsInspectionRequired: Yup.boolean().nullable().required('Customs Inspection Required is required'),
        customsInspectionDate: Yup.date().when('customsInspectionRequired', {
            is: true,
            then: Yup.date().required('Customs Inspection Date is required'),
            otherwise: Yup.date(),
        }),
        qcInspectionRequired: Yup.boolean().nullable().required('QC Inspection Required is required'),
        qcInspectionDate: Yup.date().when('qcInspectionRequired', {
            is: true,
            then: Yup.date().required('QC Inspection Date is required'),
            otherwise: Yup.date(),
        }),
        actualGoodsReadyDate: Yup.date().required('Actual Goods Ready Date is required'),
    });

    const initialValues: receiptHeaderData = {
        plannedCustomsClearance: data?.plannedCustomsClearance
            ? moment(data?.plannedCustomsClearance).format('YYYY-MM-DD')
            : '',
        plannedGoodsReceivedDate: data?.plannedGoodsReceivedDate
            ? moment(data?.plannedGoodsReceivedDate).format('YYYY-MM-DD')
            : '',
        shippingTerms: data?.shippingTerms || '',
        fumigationRequired: data?.fumigationRequired,
        customsInspectionRequired: data?.customsInspectionRequired,
        customsInspectionDate: data?.customsInspectionDate
            ? moment(data?.customsInspectionDate).format('YYYY-MM-DD')
            : '',
        qcInspectionRequired: data?.qcInspectionRequired,
        qcInspectionDate: data?.qcInspectionDate ? moment(data?.qcInspectionDate).format('YYYY-MM-DD') : '',
        actualGoodsReadyDate: data?.actualGoodsReadyDate ? moment(data?.actualGoodsReadyDate).format('YYYY-MM-DD') : '',
    };

    const dispatch = useAppDispatch();
    const isError = useAppSelector(selectReceiptHeaderIsError);
    const receiptHeaderSuccess = useAppSelector(selectReceiptHeaderSuccess);
    const receiptHeaderError = useAppSelector(selectReceiptHeaderError);
    const shippingTerms = useAppSelector(selectShippingTerms);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: ReceiptHeaderSchema,
        onSubmit: (values) => {
            if (formik.isSubmitting) {
                setIsLoading(true);
                dispatch(
                    updateReceiptHeader({
                        ...values,
                        receiptId: id,
                        type: data?.plannedCustomsClearance ? 'edit' : 'add',
                    })
                );
            }
        },
    });

    useEffect(() => {
        setIsLoading(false);
        if (isError && receiptHeaderError?.config?.url?.includes('header')) {
            error(receiptHeaderError?.data?.message || 'Something went wrong', Position.TOP_RIGHT);
            dispatch(clearFailure([]));
        } else if (receiptHeaderSuccess?.status === 200 || receiptHeaderSuccess?.status === 201) {
            if (receiptHeaderSuccess?.data?.type === 'add' || receiptHeaderSuccess?.data?.type === 'edit') {
                success(
                    `Planned Receipt have been ${
                        receiptHeaderSuccess?.data?.type === 'add' ? 'added' : 'updated'
                    } successfully`,
                    Position.TOP_RIGHT
                );
                dispatch(fetchReceiptHeaderList({ id }));
                displayFunction(false);
                formik.resetForm();
                dispatch(clearFailure([]));
            }
        }
    }, [isError, receiptHeaderSuccess, receiptHeaderError]);

    useEffect(() => {
        if (!display) {
            formik.resetForm();
        }
    }, [display]);

    const showError = (field: keyof typeof initialValues) => (
        <>{formik.errors[field] ? <div className="error-text">{formik.errors[field]}</div> : null}</>
    );

    const changeDropDown = (field: string) => {
        // setTimeout(() => {
        //     formik.validateForm();
        // }, 100);
        switch (field) {
            case 'shippingTerms':
                setShippingTermsDropDown((prev) => !prev);
                setFumigationRequiredDropDown(false);
                setCustomInspectionRequiredDropDownOpen(false);
                setQcInspectionDropDownOpen(false);
                break;
            case 'fumigationRequired':
                setShippingTermsDropDown(false);
                setFumigationRequiredDropDown((prev) => !prev);
                setCustomInspectionRequiredDropDownOpen(false);
                setQcInspectionDropDownOpen(false);
                break;
            case 'customsInspectionRequired':
                setShippingTermsDropDown(false);
                setFumigationRequiredDropDown(false);
                setCustomInspectionRequiredDropDownOpen((prev) => !prev);
                setQcInspectionDropDownOpen(false);
                break;
            case 'qcInspectionRequired':
                setShippingTermsDropDown(false);
                setFumigationRequiredDropDown(false);
                setCustomInspectionRequiredDropDownOpen(false);
                setQcInspectionDropDownOpen((prev) => !prev);
                break;
            default:
                setShippingTermsDropDown(false);
                setFumigationRequiredDropDown(false);
                setCustomInspectionRequiredDropDownOpen(false);
                setQcInspectionDropDownOpen(false);
                break;
        }
    };
    // const checkValidation = () => {
    //     const isEdit = data?.plannedCustomsClearance;
    //     const baseCondition =
    //         formik.errors && formik.errors.constructor === Object && Object.keys(formik.errors).length === 0
    //             ? false
    //             : true;
    //     if (isEdit) {
    //         return !formik.dirty || baseCondition;
    //     } else {
    //         return baseCondition;
    //     }
    // };

    return (
        <>
            {display ? (
                <>
                    <div>
                        <div className="app-modal">
                            <div className={`app-loader ${isLoading ? 'loading' : ''}`}>
                                <svg className="svg-icon loader-icon">
                                    <use xlinkHref="#loaderIcon">
                                        <title>Loading</title>
                                    </use>
                                </svg>
                            </div>
                            <div
                                onClick={(e) => {
                                    changeDropDown('');
                                    e.stopPropagation();
                                }}
                                className="modal-content-holder medium-popup receipt-modal"
                            >
                                <div className="modal-header">
                                    <div className="title">Planned Receipt</div>
                                    <button
                                        onClick={() => {
                                            {
                                                formik.dirty ? setDisplayCancel(true) : displayFunction(false);
                                            }
                                        }}
                                        className="app-btn modal-close-btn"
                                    >
                                        <span>
                                            <svg className="svg-icon modal-header-close-icon">
                                                <use href="#closeIcon">
                                                    <title>Close</title>
                                                </use>
                                            </svg>
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-content">
                                    <div className="modal-main-content sop-modal-main-content">
                                        <form
                                            id="my-form"
                                            action=""
                                            onSubmit={formik.handleSubmit}
                                            className="modal-form layout-holder one-column"
                                        >
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Planned Customs Clearance
                                                    <span className="mandatory-field-text">*</span>
                                                </label>

                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        minDate={new Date()}
                                                        formik={formik}
                                                        fieldName="plannedCustomsClearance"
                                                    />
                                                    {showError('plannedCustomsClearance')}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Planned Goods Received Date
                                                    <span className="mandatory-field-text">*</span>
                                                </label>

                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        formik={formik}
                                                        minDate={new Date()}
                                                        fieldName="plannedGoodsReceivedDate"
                                                    />
                                                    {showError('plannedGoodsReceivedDate')}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Shipping Terms<span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <div
                                                        className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                            shippingTermsDropDown ? 'dropdown-open' : ''
                                                        } `}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeDropDown('shippingTerms');
                                                            }}
                                                            className="menu-btn app-btn select-modal"
                                                        >
                                                            {formik.values.shippingTerms ? (
                                                                <span className="button-text selected-text">
                                                                    {formik.values.shippingTerms}
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    <span className="placeholder-text">
                                                                        Select Shipping Terms
                                                                    </span>
                                                                    <span className="button-text"></span>
                                                                </>
                                                            )}
                                                            <span className="dropdown-arrow">
                                                                <svg className="svg-icon arrow-icon">
                                                                    <use xlinkHref="#downArrow">
                                                                        <title>dropdown</title>
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </button>

                                                        <div className="dropdown-menu">
                                                            {shippingTerms?.map(
                                                                (shippingTerm: {
                                                                    shippingTermsId: string;
                                                                    shippingTerms: string;
                                                                }) => (
                                                                    <a
                                                                        key={shippingTerm.shippingTermsId}
                                                                        onClick={() => {
                                                                            formik.setFieldError(
                                                                                'shippingTerms',
                                                                                undefined
                                                                            );
                                                                            formik.setFieldValue(
                                                                                'shippingTerms',
                                                                                shippingTerm.shippingTerms
                                                                            );
                                                                            changeDropDown('shippingTerms');
                                                                        }}
                                                                        className={`menu-item app-btn ${
                                                                            shippingTerm.shippingTerms ===
                                                                            formik.values.shippingTerms
                                                                                ? 'active'
                                                                                : ''
                                                                        }`}
                                                                    >
                                                                        <span className="main-content">
                                                                            {shippingTerm.shippingTerms}
                                                                        </span>
                                                                    </a>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                    {showError('shippingTerms')}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Fumigation Required<span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <div
                                                        className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                            fumigationRequiredDropDown ? 'dropdown-open' : ''
                                                        } `}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeDropDown('fumigationRequired');
                                                            }}
                                                            className="menu-btn app-btn select-modal"
                                                        >
                                                            {typeof formik.values.fumigationRequired === 'boolean' ? (
                                                                <span className="button-text selected-text">
                                                                    {formik.values.fumigationRequired ? 'Yes' : 'No'}
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    <span className="placeholder-text">
                                                                        Select Fumigation Required
                                                                    </span>
                                                                    <span className="button-text"></span>
                                                                </>
                                                            )}

                                                            <span className="dropdown-arrow">
                                                                <svg className="svg-icon arrow-icon">
                                                                    <use xlinkHref="#downArrow">
                                                                        <title>dropdown</title>
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </button>

                                                        <div className="dropdown-menu">
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError(
                                                                        'fumigationRequired',
                                                                        undefined
                                                                    );
                                                                    formik.setFieldValue('fumigationRequired', true);
                                                                    changeDropDown('fumigationRequired');
                                                                }}
                                                                className={`menu-item app-btn ${
                                                                    formik.values.fumigationRequired === true
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span className="main-content">Yes</span>
                                                            </a>
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError(
                                                                        'fumigationRequired',
                                                                        undefined
                                                                    );
                                                                    formik.setFieldValue('fumigationRequired', false);
                                                                    changeDropDown('fumigationRequired');
                                                                }}
                                                                className={`menu-item app-btn ${
                                                                    formik.values.fumigationRequired === false
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span className="main-content">No</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {showError('fumigationRequired')}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Customs Inspection Required
                                                    <span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <div
                                                        className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                            customInspectionRequiredDropDownOpen ? 'dropdown-open' : ''
                                                        } `}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeDropDown('customsInspectionRequired');
                                                            }}
                                                            className="menu-btn app-btn select-modal"
                                                        >
                                                            {typeof formik.values.customsInspectionRequired ===
                                                            'boolean' ? (
                                                                <span className="button-text selected-text">
                                                                    {formik.values.customsInspectionRequired
                                                                        ? 'Yes'
                                                                        : 'No'}
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    <span className="placeholder-text">
                                                                        Select Customs Inspection Required
                                                                    </span>
                                                                    <span className="button-text"></span>
                                                                </>
                                                            )}

                                                            <span className="dropdown-arrow">
                                                                <svg className="svg-icon arrow-icon">
                                                                    <use xlinkHref="#downArrow">
                                                                        <title>dropdown</title>
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </button>

                                                        <div className="dropdown-menu">
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError(
                                                                        'customsInspectionRequired',
                                                                        undefined
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'customsInspectionRequired',
                                                                        true
                                                                    );
                                                                    changeDropDown('customsInspectionRequired');
                                                                }}
                                                                className={`menu-item app-btn ${
                                                                    formik.values.customsInspectionRequired === true
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span className="main-content">Yes</span>
                                                            </a>
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError(
                                                                        'customsInspectionRequired',
                                                                        undefined
                                                                    );
                                                                    formik.setFieldValue(
                                                                        'customsInspectionRequired',
                                                                        false
                                                                    );
                                                                    formik.setFieldValue('customsInspectionDate', '');
                                                                    changeDropDown('customsInspectionRequired');
                                                                }}
                                                                className={`menu-item app-btn ${
                                                                    formik.values.customsInspectionRequired === false
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span className="main-content">No</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {showError('customsInspectionRequired')}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    Customs Inspection Date
                                                    {formik.values.customsInspectionRequired && (
                                                        <span className="mandatory-field-text">*</span>
                                                    )}
                                                </label>

                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        formik={formik}
                                                        minDate={new Date()}
                                                        fieldName="customsInspectionDate"
                                                        disabled={
                                                            formik.values.customsInspectionRequired ? false : true
                                                        }
                                                    />
                                                    {showError('customsInspectionDate')}
                                                </div>
                                            </div>
                                            <div className="modal-input-holder">
                                                <label className="modal-input-label">
                                                    QC Inspection Required
                                                    <span className="mandatory-field-text">*</span>
                                                </label>
                                                <div className="modal-input-layout-item">
                                                    <div
                                                        className={`dropdown-wrap menu-down modal-dropdown search-toast-content-holder ${
                                                            qcInspectionDropDownOpen ? 'dropdown-open' : ''
                                                        } `}
                                                    >
                                                        <button
                                                            type="button"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                changeDropDown('qcInspectionRequired');
                                                            }}
                                                            className="menu-btn app-btn select-modal"
                                                        >
                                                            {typeof formik.values.qcInspectionRequired === 'boolean' ? (
                                                                <span className="button-text selected-text">
                                                                    {formik.values.qcInspectionRequired ? 'Yes' : 'No'}
                                                                </span>
                                                            ) : (
                                                                <>
                                                                    <span className="placeholder-text">
                                                                        Select QC Inspection Required
                                                                    </span>
                                                                    <span className="button-text"></span>
                                                                </>
                                                            )}
                                                            <span className="dropdown-arrow">
                                                                <svg className="svg-icon arrow-icon">
                                                                    <use xlinkHref="#downArrow">
                                                                        <title>dropdown</title>
                                                                    </use>
                                                                </svg>
                                                            </span>
                                                        </button>

                                                        <div className="dropdown-menu">
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError(
                                                                        'qcInspectionRequired',
                                                                        undefined
                                                                    );
                                                                    formik.setFieldValue('qcInspectionRequired', true);
                                                                    changeDropDown('qcInspectionRequired');
                                                                }}
                                                                className={`menu-item app-btn ${
                                                                    formik.values.qcInspectionRequired === true
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span className="main-content">Yes</span>
                                                            </a>
                                                            <a
                                                                onClick={() => {
                                                                    formik.setFieldError(
                                                                        'qcInspectionRequired',
                                                                        undefined
                                                                    );
                                                                    formik.setFieldValue('qcInspectionRequired', false);
                                                                    formik.setFieldValue('qcInspectionDate', '');
                                                                    changeDropDown('qcInspectionRequired');
                                                                }}
                                                                className={`menu-item app-btn ${
                                                                    formik.values.qcInspectionRequired === false
                                                                        ? 'active'
                                                                        : ''
                                                                }`}
                                                            >
                                                                <span className="main-content">No</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    {showError('qcInspectionRequired')}
                                                </div>
                                            </div>
                                            <div className=" modal-input-holder">
                                                <label className="modal-input-label">
                                                    QC Inspection Date
                                                    {formik.values.qcInspectionRequired && (
                                                        <span className="mandatory-field-text">*</span>
                                                    )}
                                                </label>

                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        formik={formik}
                                                        fieldName="qcInspectionDate"
                                                        minDate={new Date()}
                                                        disabled={formik.values.qcInspectionRequired ? false : true}
                                                    />
                                                    {showError('qcInspectionDate')}
                                                </div>
                                            </div>
                                            <div className="modal-input-holder">
                                                <label className="modal-input-label">
                                                    Actual Goods Ready Date
                                                    <span className="mandatory-field-text">*</span>
                                                </label>

                                                <div className="modal-input-layout-item calendar">
                                                    <DatePicker
                                                        portalId="date-portal"
                                                        minDate={new Date()}
                                                        formik={formik}
                                                        fieldName="actualGoodsReadyDate"
                                                        format={'DD-MM-YYYY'}
                                                    />
                                                    {showError('actualGoodsReadyDate')}
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button
                                        onClick={() => {
                                            formik.dirty ? setDisplayCancel(true) : displayFunction(false);
                                        }}
                                        className="app-btn app-btn-secondary modal-btn footer-btn"
                                    >
                                        <span className="button-text footer-button-text">Cancel</span>
                                    </button>
                                    <button
                                        type="submit"
                                        form="my-form"
                                        className="app-btn app-btn-primary modal-btn footer-btn"
                                        // disabled={checkValidation()}
                                    >
                                        <span className="button-text vendor-button-text">Save</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ zIndex: 9999 }} id="date-portal"></div>
                </>
            ) : (
                <div></div>
            )}
        </>
    );
};
export default Index;
