import React, { useEffect, useState } from 'react';
// import BookingProposalHeader from '../../BookingProposalHeader';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumbs from '../../../../components/Breadcrumbs/defaultBreadcrumbs';
import '../../../BookingProposal/POSelection.scss';
import '../../../BookingProposal/BookingChangeVessel/ManualVesselEntry/manual-vessel-entry.scss';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { reactSelectStyle, reactSelectTheme } from '../../../../utils/constants';
import Select, { InputActionMeta, components } from 'react-select';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/reducers';
import {
    addToBp,
    fetchSelectedSailing,
    // getPortValues,
} from '../../../../store/reducers/bookingProposal/sailingReducer';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { useUserProfile } from '../../../../hooks/useUserProfile';
import { fetchBpHeaderData } from '../../../../store/reducers/bookingProposal/bookingProposalHeaderReducer';
import { getOrganisationData } from '../../../../utils';
import { getCarrierList, getVesselList } from 'src/store/reducers/bookingProposalAir/addSchedule';
import SearchableSelectBox from 'src/components/common/SearchableSelectBox/withoutFormik';
import { searchUnlocoList, clearUnlocoList } from '../../../../store/reducers/createVendorReducer';
import ChangeScheduleReason from 'src/pages/BcnBookingProposal/SailingScheludeSelection/ChangeScheduleReason';
import VesselDropdownList from '../../../Common/vesselDropdown';
import TransitVesselDropdownList from '../../../Common/transitVesselDropdown';
import { addContainerNac } from 'src/store/reducers/bookingProposal/additionalDetailsNacReducer';
import WrapperComponent from 'src/pages/BookingProposal/ManualScheduleCreation/WrapperComponent';
import PortWrapper from 'src/pages/BookingProposal/ManualScheduleCreation/PortWrapper';
/* eslint no-var: off */
/* eslint-disable no-undefined */

const summaryStats = [
    {
        title: 'Total CBM',
        value: '33',
    },
    {
        title: 'Total Kgs',
        value: '500',
    },
    {
        title: 'Estimated TEU',
        value: '1',
    },
];

var dataValues: any = [];
var selectedPorts: any = [];

const Index: React.FC = () => {
    const { vesselList } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    let prevValue = '';
    const [reason, setReason] = useState('');
    const [modalView, setModalView] = useState(false);
    const [Type, setType] = useState('Direct');
    const [originEtd, setoriginEtd] = useState('');
    const [destinationEta, setdestinationEta] = useState('');
    const [vessel, setvessel] = useState('');
    const [vesselFullName, setVesselFullName] = useState('');
    const [voyage, setvoyage] = useState('');
    const [service, setservice] = useState('');
    const [errorValidation, seterrorValidation] = useState(false);
    const [_NEwData, setNEwData] = useState({});
    const [referenceNum, setreferenceNum] = useState('');
    const [validationStatus, setvalidationStatus] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [portsvalues, setPorts] = useState<any>([]);
    const [portDetails, setportDetails] = useState([
        {
            portName: '',
            portCode: '',
            vessel: '',
            vesselFullName: '',
            etd: '',
            eta: '',
            service: '',
            voyage: '',
        },
    ]);
    const [_showCarrier, setShowCarrier] = useState(false);
    const [Carrier, setCarrier] = useState('');

    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { selectedSailing, sailingUpdate } = useSelector((state: RootState) => state.sailingRed);
    const { destinationPort, originPort, isLoading, bpStatus } = useSelector((state: RootState) => state.bpHeaderData);
    const { carrierList } = useSelector((state: RootState) => state.bookingproposalAirschedule);
    const { profileType } = useUserProfile();
    const { unLocoList } = useSelector((state: RootState) => state.vendorDetails);
    const userData = localStorage.getItem('user') != null && JSON.parse(localStorage.getItem('user') || '');
    const listOfUserOrganisations = getOrganisationData();
    const indexVal = localStorage.getItem('roleIndex');
    const roles = userData?.roles[indexVal as any];

    const convertIMOtoName = (imo: string) => {
        if (vesselList && vesselList?.length > 0) {
            for (var i = 0; i < vesselList?.length; i++) {
                if (imo == vesselList[i].imo) {
                    return vesselList[i].name;
                }
            }
        }

        return '';
    };

    const getCarrierName = (name: string) => {
        if (carrierList?.length) {
            const filtered: any = carrierList?.find((res: any) => res?.Or_orgname === name);
            return filtered ? filtered?.Or_orgname : Carrier?.match(/.*\(([^)]+)\)/)?.[1];
        } else {
            return Carrier?.match(/.*\(([^)]+)\)/)?.[1];
        }
    };
    const getCarrierCode = (name: string) => {
        if (carrierList?.length) {
            const filtered: any = carrierList?.find((res: any) => res?.Or_orgname === name);
            return filtered ? filtered?.Or_UserDefinedText4?.trimStart()?.split(' ')?.[0] : Carrier;
        } else {
            return Carrier;
        }
    };

    const payload = {
        sailingAddRequest: {
            sailingReferenceNumber: referenceNum,
            bookingProposalId: id,
            sailingId: dataValues?.sailingId,
            contractType: dataValues.contractType,
            contractId: dataValues.contractId,
            contractName: dataValues.contractName,
            // shippingLine: coLoader,
            // shippingLineCode: coLoader,
            shippingLine: getCarrierName(Carrier),
            shippingLineCode: getCarrierCode(Carrier),
            transitDays: dataValues.plannedTransitDays,
            serviceType: Type,
            isPrimary: dataValues.isPrimaryClient,
            eta: destinationEta + ' 00:00:00',
            etd: originEtd + ' 00:00:00',
            vesselVoyage: vesselFullName ? vesselFullName + '-' + voyage : convertIMOtoName(vessel) + '-' + voyage,
            vessel: vesselFullName ? vesselFullName : convertIMOtoName(vessel),
            voyage: voyage,
            gateInDate: dataValues.gateInDate ? moment(dataValues.gateInDate).format('yyyy-MM-DD') + ' 00:00:00' : null,
            contractVersion: dataValues?.contractVersion,
            isManuallyEntered: true,
            changeVessel:
                bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' || bpStatus === 'Booked'
                    ? true
                    : false,
            predictedETD: dataValues.predictedETD
                ? moment(dataValues.predictedETD).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            predictedETA: dataValues.predictedETA
                ? moment(dataValues.predictedETA).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            predictedTransitDays: dataValues.predictedTransitDays,
            vesselIMO: vessel ?? '',
            service: service,
            destinationPortCode: destinationPort && (destinationPort as any)?.match(/\((.*?)\)/)[1],
            destinationPortName: destinationPort ? destinationPort : '',
            destinationPortTerminal: dataValues.destinationPortTerminal,
            originPortCode: originPort && (originPort as any)?.match(/\((.*?)\)/)[1],
            originPortName: originPort ? originPort : '',
            originPortTerminal: dataValues.originPortTerminal,
            vgmCutOff: dataValues.vgmCutOff ? moment(dataValues.vgmCutOff).format('yyyy-MM-DD') + ' 00:00:00' : null,
            gateInCutOff: dataValues.gateInCutOff
                ? moment(dataValues.gateInCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            standardBookingCutOff: dataValues.standardBookingCutOff
                ? moment(dataValues.standardBookingCutOff).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            gateInOpen: dataValues?.gateInOpen
                ? moment(dataValues?.gateInOpen).format('yyyy-MM-DD') + ' 00:00:00'
                : null,
            docCutOff: dataValues?.docCutOff ? moment(dataValues?.docCutOff).format('yyyy-MM-DD') + ' 00:00:00' : null,
            sailingScheduleTransitPorts: [],
            reason,
        },
        notification: { roles, listOfUserOrganisations },
    };

    useEffect(() => {
        dispatch(fetchSelectedSailing(id));
        // dispatch(getPortValues(null));
        dispatch(fetchBpHeaderData({ bpId: id, profileType }));
        dispatch(getCarrierList({ type: 'LCL' }));
        dispatch(getVesselList({}));
    }, [id]);

    useEffect(() => {
        if (sailingUpdate) {
            dispatch(fetchSelectedSailing(id));
            const timer = setTimeout(() => {
                navigate(`/LCLbooking_list/${id}/SailingSelectionDetails`);
            }, 200);
            return () => clearTimeout(timer);
        }
    }, [sailingUpdate, id]);

    useEffect(() => {
        if (Object.keys(selectedSailing).length !== 0) {
            setNEwData((selectedSailing as any)['sailingScheduleLists'][0]);
            dataValues = (selectedSailing as any)['sailingScheduleLists'][0];
        }
    }, [selectedSailing, Object.keys(selectedSailing)]);
    const submitValues = () => {
        setportDetails([...portDetails]);

        var error = false;
        var payloadNew: any = [];
        if (Carrier === '' || Carrier === undefined) {
            seterrorValidation(true);
            return;
        }
        const status = bpStatus === 'Ready_for_Booking' || bpStatus === 'Draft_Booking' || bpStatus === 'Booked';
        if (Type === 'Direct') {
            if (
                !voyage?.trim() ||
                !service?.trim() ||
                // !vessel?.trim() ||
                !originEtd?.trim() ||
                !destinationEta?.trim()
            ) {
                seterrorValidation(true);
            } else {
                if (!modalView && status) {
                    setModalView(true);
                    return;
                }
                seterrorValidation(false);
                dispatch(addToBp(payload));
                setReason('');
            }
        } else {
            portDetails.forEach((element) => {
                if (
                    !element.portCode?.trim() ||
                    !element.portName?.trim() ||
                    !element.eta?.trim() ||
                    !element.etd?.trim() ||
                    !element.service?.trim() ||
                    // !element.vessel?.trim() ||
                    !element.voyage?.trim() ||
                    !voyage?.trim() ||
                    !service?.trim() ||
                    // !vessel?.trim() ||
                    !originEtd?.trim() ||
                    !destinationEta?.trim()
                ) {
                    error = true;
                    seterrorValidation(true);
                } else {
                    error = false;
                    payloadNew.push({
                        portName: element.portName,
                        portCode: element.portCode,
                        plannedETA: element.eta + ' 00:00:00',
                        plannedETD: element.etd + ' 00:00:00',
                        route: element.service,
                        // vesselVoyage: convertIMOtoName(element.vessel) + '-' + element.voyage,
                        // vessel: convertIMOtoName(element.vessel),
                        vesselVoyage: element.vesselFullName + '-' + element.voyage,
                        vessel: element.vesselFullName,
                        voyage: element.voyage,
                        predictedETA: null,
                        predictedETD: null,
                        plannedTransitDays: null,
                        predictedTransitDays: null,
                        transitOrder: null,
                        vgmCutOff: null,
                        portCutOff: null,
                        terminal: null,
                    });
                }
            });
            const newData = {
                sailingAddRequest: {
                    ...(payload as any)['sailingAddRequest'],
                    sailingScheduleTransitPorts: payloadNew,
                },
                notification: { roles, listOfUserOrganisations },
            };
            if (!error) {
                if (!modalView && status) {
                    setModalView(true);
                    return;
                }
                seterrorValidation(false);
                dispatch(addToBp(newData));
                setModalView(false);
                setReason('');
            }
        }
    };

    useEffect(() => {
        //to add all selected and current airports to list
        if (originPort && destinationPort) {
            selectedPorts = [
                (originPort as any)?.match(/\(([^)]+)\)/)[1],
                (destinationPort as any)?.match(/\(([^)]+)\)/)[1],
            ];
        }
        portDetails &&
            portDetails?.forEach((element: any) => {
                selectedPorts.push(element.portCode);
            });
    }, [portDetails, originPort, destinationPort]);

    const filterSelection = () => {
        let newAr2: any = [...portsvalues];

        var newPacketData: any = [...selectedPorts];
        if (newPacketData?.length > 0) {
            for (let j = 0; j < newPacketData?.length; j++) {
                newAr2 = newAr2?.filter((filt: any) => newPacketData[j] !== filt.value);
            }
        } else {
            newAr2 = [...portsvalues];
        }
        newAr2 = newAr2.filter(function (element: any) {
            return element.value !== undefined;
        });
        return newAr2;
    };

    const convertDataForSelect = (data: any) => {
        if (Array.isArray(carrierList)) {
            const convertedData = data?.map((option: any) => {
                return { value: option.Or_orgname, label: option.Or_UserDefinedText4 };
            });
            return convertedData;
        } else {
            return [];
        }
    };

    const updatePortDetails = (option: { value: string; label: string }, index: number, fieldName: string) => {
        const newPortDetails = [...portDetails];
        newPortDetails[index].vessel = option?.value ?? '';
        newPortDetails[index].vesselFullName = option?.label ?? '';
        setportDetails([...newPortDetails]);
    };

    useEffect(() => {
        let flag = false;

        if (Type === 'Direct') {
            if (
                // !vessel?.trim() ||
                !voyage?.trim() ||
                !service?.trim() ||
                !Carrier?.trim() ||
                !destinationEta?.trim() ||
                !originEtd?.trim()
            ) {
                flag = true;
            }
        } else {
            portDetails?.forEach((element: any) => {
                if (
                    !element.portCode?.trim() ||
                    !element.portName?.trim() ||
                    !element.eta?.trim() ||
                    !element.etd?.trim() ||
                    !element.service?.trim() ||
                    // !element.vessel?.trim() ||
                    !element.voyage?.trim() ||
                    // !vessel?.trim() ||
                    !voyage?.trim() ||
                    !service?.trim() ||
                    !Carrier?.trim() ||
                    Carrier === undefined ||
                    !destinationEta?.trim() ||
                    !originEtd?.trim()
                ) {
                    flag = true;
                } else {
                    flag = false;
                }
            });
        }

        setvalidationStatus(flag);
    }, [Type, vessel, voyage, service, Carrier, destinationEta, originEtd, portDetails, referenceNum]);

    useEffect(() => {
        setdestinationEta('');
    }, [Type]);
    useEffect(() => {
        if (searchTerm?.trim().length) {
            dispatch(searchUnlocoList({ searchTerm, portType: 'sea', service: 'bkg' }));
        }
        //setPorts([]);
    }, [searchTerm]);
    useEffect(() => {
        if (unLocoList?.length > 0) {
            const portList = unLocoList?.map((option: any) => {
                return {
                    value: option.portCode,
                    label: `${option.portName}(${option.portCode})`,
                    portCountry: option?.portCountry ?? '',
                };
            });
            setPorts(portList);
        }
    }, [unLocoList]);

    useEffect(() => {
        dispatch(clearUnlocoList());
    }, []);

    const onSubmitChangeSelection = () => {
        submitValues();
    };

    const ClearIndicator = (props: any) => {
        return (
            <components.ClearIndicator {...props}>
                <span>
                    <svg className="svg-icon modal-header-close-icon searchable-clear-icon">
                        <use xlinkHref="#closeIcon">
                            <title>Close</title>
                        </use>
                    </svg>
                </span>
            </components.ClearIndicator>
        );
    };
    const NoMessageComponent = (select: any) => {
        return (
            <p>
                {select.selectProps?.inputValue?.trim()?.length < 3
                    ? 'Enter at least 3 characters to search'
                    : 'No results found'}
            </p>
        );
    };
    return (
        <div className="main-wrapper container booking-change-vessel" onClick={() => setShowCarrier(false)}>
            <div className="main-header-content-holder large-header">
                <Breadcrumbs
                    firstName={'Bookings'}
                    firstUrl={'/booking_list'}
                    secondName={'Booking Proposal Details'}
                    secondUrl={`/lclbooking_list/${id}/POSelection`}
                    thirdName={'Sailing Schedule'}
                    thirdUrl={`/lclbooking_list/${id}/SailingSelectionDetails`}
                    fourthName={'New Schedule'}
                    fourthUrl={'#'}
                    summaryStats={summaryStats}
                />
                <div className="pageheading-holder">
                    <div className="back-btn-holder">
                        <Link to={`/lclbooking_list/${id}/SailingSelectionDetails`}>
                            <button className="back-btn app-btn">
                                <svg className="svg-icon back-icon">
                                    <use href="#previousArrowIcon">
                                        <title>Back</title>
                                    </use>
                                </svg>
                            </button>
                        </Link>
                        <h2 className="page-heading">New Schedule</h2>
                    </div>
                </div>
                {isLoading && (
                    <div className="app-loader loading">
                        <svg className="svg-icon loader-icon">
                            <use xlinkHref="#loaderIcon">
                                <title>Loading</title>
                            </use>
                        </svg>
                    </div>
                )}
            </div>
            {/* DATA GRID SECTION */}
            <div className="content-section main-wrapper change-vessel-detail">
                <div className="main-wrapper">
                    <div className="manual-entry-wrapper">
                        <div className="filter-types">
                            <div className="layout-holder two-column">
                                <div className="layout-item">
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">
                                            Carrier<span className="mandatory-field-text">*</span>
                                        </label>
                                        {/* <div className="modal-input-layout-item">
                                            <div className="modal-input input-text read-only">
                                                {coLoader && coLoader}
                                            </div>
                                        </div> */}
                                        <SearchableSelectBox
                                            data={convertDataForSelect(carrierList)}
                                            placeholder="Enter Carrier to select"
                                            setStateValue={setCarrier}
                                            stateValue={Carrier}
                                            fieldName="carrier"
                                            menuPortalTarget={document.getElementById('outside_div')}
                                            onInputChangeFn={(value: string, actionMeta: InputActionMeta) => {
                                                prevValue = prevValue === 'set-value' ? '' : actionMeta.action;
                                                if (value?.length > 2) {
                                                    dispatch(getCarrierList({ search: value, type: 'LCL' }));
                                                } else if (
                                                    actionMeta.action === 'menu-close' &&
                                                    actionMeta.prevInputValue !== '' &&
                                                    prevValue
                                                ) {
                                                    dispatch(getCarrierList({ type: 'LCL' }));
                                                }
                                            }}
                                            onClear={() => dispatch(getCarrierList({ type: 'LCL' }))}
                                            NoMessageComponent={NoMessageComponent}
                                        />
                                        {errorValidation && (Carrier === '' || Carrier === undefined) && (
                                            <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                Carrier is required
                                            </p>
                                        )}
                                    </div>
                                </div>
                                <div className="layout-item">
                                    <div className=" modal-input-holder layout-item">
                                        <label className="modal-input-label">Sailing Reference No.</label>
                                        <div className="modal-input-layout-item">
                                            <input
                                                placeholder="Enter Sailing Reference no."
                                                type="text"
                                                className="modal-input input-text"
                                                id="sd_version"
                                                name="sd_version"
                                                onChange={(e) => setreferenceNum(e.target.value)}
                                                value={referenceNum}
                                            ></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="filter-type-holder">
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            onChange={() => setType('Direct')}
                                            checked={Type === 'Direct'}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Direct</div>
                                    </label>
                                </div>
                                <div className="checkbox-item">
                                    <label className="app-check-wrapper">
                                        <input
                                            type="checkbox"
                                            className="checkbox-input"
                                            onChange={() => setType('Transhipment')}
                                            checked={Type === 'Transhipment'}
                                        ></input>
                                        <div className="checkmark">
                                            <svg className="svg-icon tick-icon">
                                                <use href="#tickIcon">
                                                    <title>check mark</title>
                                                </use>
                                            </svg>
                                        </div>
                                        <div className="checkbox-label">Multi-Legged</div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="layout-header">Origin - Leg 1</div>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">Port</label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder={originPort as any}
                                                    type="text"
                                                    className="modal-input input-text read-only"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    readOnly
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                ETD<span className="mandatory-field-text">*</span>
                                            </label>

                                            <div className="modal-input-layout-item">
                                                <DatePicker
                                                    //disabled={disabled}
                                                    minDate={new Date()}
                                                    onChange={(value: any) => {
                                                        setoriginEtd(moment(value).format('yyyy-MM-DD'));
                                                    }}
                                                    value={originEtd ? moment(originEtd).format('DD-MM-YYYY') : ''}
                                                    placeholderText="Select Date"
                                                    customInput={
                                                        <div className="modal-input-field-holder date-input-holder">
                                                            <input
                                                                className="modal-input input-text with-border date-input input-date-cursor"
                                                                type="text"
                                                                placeholder={'Select Date'}
                                                                value={
                                                                    originEtd
                                                                        ? moment(originEtd).format('DD-MM-YYYY')
                                                                        : ''
                                                                }
                                                            />
                                                            <svg className="svg-icon calendar-icon">
                                                                <use xlinkHref="#calendarIcon">
                                                                    <title>Calendar</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                            {errorValidation && originEtd === '' && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    ETD is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <WrapperComponent
                                        type={'single'}
                                        vessel={vessel}
                                        setVessel={setvessel}
                                        setVesselFullName={setVesselFullName}
                                        errorValidation={errorValidation}
                                    />
                                    {/* <div className="layout-item">
                                        <VesselDropdownList
                                            vessel={vessel}
                                            setVessel={setvessel}
                                            isErrorMessageShow={
                                                errorValidation && (vessel === '' || vessel === undefined)
                                            }
                                        />
                                    </div> */}
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                Voyage<span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter Voyage"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    onChange={(e) => setvoyage(e.target.value)}
                                                    value={voyage}
                                                ></input>
                                            </div>
                                            {errorValidation && !voyage?.trim() && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    Voyage is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                Service<span className="mandatory-field-text">*</span>
                                            </label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder="Enter Service"
                                                    type="text"
                                                    className="modal-input input-text"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    onChange={(e) => setservice(e.target.value)}
                                                    value={service}
                                                ></input>
                                            </div>
                                            {errorValidation && !service?.trim() && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    Service is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {Type !== 'Direct' && (
                            <>
                                {portDetails?.map((res: any, index: any) => (
                                    <div className="card details-box custom lcl-multi-bg">
                                        <div className="card-head-holder add-btn-holder">
                                            <div className="layout-holder two-column">
                                                <div className="layout-item">
                                                    <div className="layout-header via">Via - Leg {index + 2}</div>
                                                </div>
                                                <div className="layout-item remove-link">
                                                    {index !== 0 && (
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="file-remove-link"
                                                            title="Remove Leg"
                                                            onClick={() => {
                                                                const newArray = [...portDetails];
                                                                newArray.splice(index, 1);
                                                                setportDetails(newArray);
                                                            }}
                                                        >
                                                            Remove Leg
                                                        </a>
                                                    )}
                                                </div>
                                                {/* <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label htmlFor="name" className="modal-input-label">
                                                            Port<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div id="cd_author_div" className="modal-input-layout-item">
                                                            <Select
                                                                options={filterSelection()}
                                                                isClearable={true}
                                                                placeholder={'Enter Port to select'}
                                                                onChange={(option: any) => {
                                                                    res.portName = option.label;
                                                                    res.portCode = option.value;
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                                menuPlacement={'auto'}
                                                                components={{
                                                                    DropdownIndicator: () => null,
                                                                    IndicatorSeparator: () => null,
                                                                    ClearIndicator,
                                                                }}
                                                                onInputChange={(text: string) => {
                                                                    setSearchTerm(text);
                                                                }}
                                                                theme={reactSelectTheme}
                                                                styles={reactSelectStyle}
                                                                value={portsvalues?.filter((option: any) => {
                                                                    if (option.value === res.portCode) {
                                                                        return {
                                                                            label: `${res.portName}`,
                                                                            value: res.portCode,
                                                                        };
                                                                    }
                                                                })}
                                                            />
                                                        </div>
                                                        {errorValidation &&
                                                            (res.portName === '' || res.portCode === '') && (
                                                                <p
                                                                    className="error_message_product"
                                                                    style={{ paddingTop: '8px' }}
                                                                >
                                                                    Port is required
                                                                </p>
                                                            )}
                                                    </div>
                                                </div> */}
                                                <PortWrapper
                                                    setPortDetails={setportDetails}
                                                    portDetails={portDetails}
                                                    errorValidation={errorValidation}
                                                    item={res}
                                                />
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            ETA<span className="mandatory-field-text">*</span>
                                                        </label>

                                                        <div className="modal-input-layout-item">
                                                            <DatePicker
                                                                disabled={
                                                                    index === 0
                                                                        ? originEtd === ''
                                                                        : portDetails[index - 1]?.etd === ''
                                                                }
                                                                minDate={
                                                                    index === 0
                                                                        ? new Date(
                                                                              moment(originEtd, 'yyyy-MM-DD')
                                                                                  .add(1, 'd')
                                                                                  .format('MM-DD-YYYY')
                                                                          )
                                                                        : new Date(
                                                                              moment(
                                                                                  portDetails[index - 1]?.etd,
                                                                                  'yyyy-MM-DD'
                                                                              )
                                                                                  .add(1, 'd')
                                                                                  .format('MM-DD-YYYY')
                                                                          )
                                                                }
                                                                onChange={(value: any) => {
                                                                    res.eta = moment(value).format('yyyy-MM-DD');
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                                value={
                                                                    res.eta ? moment(res.eta).format('DD-MM-YYYY') : ''
                                                                }
                                                                placeholderText="Select Date"
                                                                customInput={
                                                                    <div className="modal-input-field-holder date-input-holder">
                                                                        <input
                                                                            className="modal-input input-text with-border date-input input-date-cursor"
                                                                            type="text"
                                                                            placeholder={'Select Date'}
                                                                            value={
                                                                                res.eta
                                                                                    ? moment(res.eta).format(
                                                                                          'DD-MM-YYYY'
                                                                                      )
                                                                                    : ''
                                                                            }
                                                                            disabled={
                                                                                index === 0
                                                                                    ? originEtd === ''
                                                                                    : portDetails[index - 1]?.etd === ''
                                                                            }
                                                                        />
                                                                        <svg className="svg-icon calendar-icon">
                                                                            <use xlinkHref="#calendarIcon">
                                                                                <title>Calendar</title>
                                                                            </use>
                                                                        </svg>
                                                                    </div>
                                                                }
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                            />
                                                        </div>
                                                        {errorValidation && res.eta === '' && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                ETA is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <WrapperComponent
                                                    type={'multi-legged'}
                                                    vessel={vessel}
                                                    setVessel={setvessel}
                                                    setVesselFullName={setVesselFullName}
                                                    index={index}
                                                    errorValidation={errorValidation}
                                                    portDetails={portDetails}
                                                    updatePortDetails={updatePortDetails}
                                                />
                                                {/* <div className="layout-item">
                                                    <TransitVesselDropdownList
                                                        index={index}
                                                        portDetails={portDetails}
                                                        setPortDetails={updatePortDetails}
                                                        isErrorMessageShow={false}
                                                    />
                                                </div> */}
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            Voyage<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <input
                                                                placeholder="Enter Voyage"
                                                                type="text"
                                                                className="modal-input input-text"
                                                                id="sd_version"
                                                                name="sd_version"
                                                                onChange={(e) => {
                                                                    res.voyage = e.target.value;
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                            ></input>
                                                        </div>
                                                        {errorValidation && !res.voyage?.trim() && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                Voyage is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            Service<span className="mandatory-field-text">*</span>
                                                        </label>
                                                        <div className="modal-input-layout-item">
                                                            <input
                                                                placeholder="Enter Service"
                                                                type="text"
                                                                className="modal-input input-text"
                                                                id="sd_version"
                                                                name="sd_version"
                                                                onChange={(e) => {
                                                                    res.service = e.target.value;
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                            ></input>
                                                        </div>
                                                        {errorValidation && !res.service?.trim() && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                Service is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="layout-item">
                                                    <div className=" modal-input-holder layout-item">
                                                        <label className="modal-input-label">
                                                            ETD<span className="mandatory-field-text">*</span>
                                                        </label>

                                                        <div className="modal-input-layout-item">
                                                            <DatePicker
                                                                disabled={res.eta === ''}
                                                                minDate={
                                                                    new Date(
                                                                        moment(res.eta, 'yyyy-MM-DD')
                                                                            .add(1, 'd')
                                                                            .format('MM-DD-YYYY')
                                                                    )
                                                                }
                                                                onChange={(value: any) => {
                                                                    res.etd = moment(value).format('yyyy-MM-DD');
                                                                    setportDetails([...portDetails]);
                                                                }}
                                                                value={
                                                                    res.etd ? moment(res.etd).format('DD-MM-YYYY') : ''
                                                                }
                                                                placeholderText="Select Date"
                                                                customInput={
                                                                    <div className="modal-input-field-holder date-input-holder">
                                                                        <input
                                                                            className="modal-input input-text with-border date-input input-date-cursor"
                                                                            type="text"
                                                                            placeholder={'Select Date'}
                                                                            value={
                                                                                res.etd
                                                                                    ? moment(res.etd).format(
                                                                                          'DD-MM-YYYY'
                                                                                      )
                                                                                    : ''
                                                                            }
                                                                            disabled={res.eta === ''}
                                                                        />
                                                                        <svg className="svg-icon calendar-icon">
                                                                            <use xlinkHref="#calendarIcon">
                                                                                <title>Calendar</title>
                                                                            </use>
                                                                        </svg>
                                                                    </div>
                                                                }
                                                                peekNextMonth
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                            />
                                                        </div>
                                                        {errorValidation && res.etd === '' && (
                                                            <p
                                                                className="error_message_product"
                                                                style={{ paddingTop: '8px' }}
                                                            >
                                                                ETD is required
                                                            </p>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                <div className="btn-holder">
                                    <button
                                        className="app-btn app-btn-secondary icon-button"
                                        title="Add Leg"
                                        onClick={() => {
                                            setportDetails([
                                                ...portDetails,
                                                {
                                                    portName: '',
                                                    portCode: '',
                                                    vessel: '',
                                                    vesselFullName: '',
                                                    etd: '',
                                                    eta: '',
                                                    service: '',
                                                    voyage: '',
                                                },
                                            ]);
                                            setdestinationEta('');
                                        }}
                                    >
                                        <svg className="svg-icon add-btn-icon">
                                            <use xlinkHref="#plusIcon"></use>
                                        </svg>
                                        <span className="button-text">Add Leg</span>
                                    </button>
                                </div>
                            </>
                        )}
                        <div className="card details-box custom">
                            <div className="card-head-holder add-btn-holder">
                                <div className="layout-header destination">Destination</div>
                                <div className="layout-holder two-column">
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">Port</label>
                                            <div className="modal-input-layout-item">
                                                <input
                                                    placeholder={destinationPort as any}
                                                    type="text"
                                                    className="modal-input input-text read-only"
                                                    id="sd_version"
                                                    name="sd_version"
                                                    readOnly
                                                ></input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="layout-item">
                                        <div className=" modal-input-holder layout-item">
                                            <label className="modal-input-label">
                                                ETA<span className="mandatory-field-text">*</span>
                                            </label>

                                            <div className="modal-input-layout-item">
                                                <DatePicker
                                                    minDate={
                                                        Type === 'Direct'
                                                            ? new Date(
                                                                  moment(originEtd, 'yyyy-MM-DD')
                                                                      .add(1, 'd')
                                                                      .format('MM-DD-YYYY')
                                                              )
                                                            : new Date(
                                                                  moment(
                                                                      portDetails[portDetails?.length - 1]?.etd,
                                                                      'yyyy-MM-DD'
                                                                  )
                                                                      .add(1, 'd')
                                                                      .format('MM-DD-YYYY')
                                                              )
                                                    }
                                                    disabled={
                                                        Type === 'Direct'
                                                            ? originEtd === ''
                                                            : portDetails[portDetails?.length - 1]?.etd === ''
                                                    }
                                                    onChange={(value: any) => {
                                                        setdestinationEta(moment(value).format('yyyy-MM-DD'));
                                                    }}
                                                    value={
                                                        destinationEta
                                                            ? moment(destinationEta).format('DD-MM-YYYY')
                                                            : ''
                                                    }
                                                    placeholderText="Select Date"
                                                    customInput={
                                                        <div className="modal-input-field-holder date-input-holder">
                                                            <input
                                                                className="modal-input input-text with-border date-input input-date-cursor"
                                                                type="text"
                                                                placeholder={'Select Date'}
                                                                disabled={
                                                                    Type === 'Direct'
                                                                        ? originEtd === ''
                                                                        : portDetails[portDetails?.length - 1]?.etd ===
                                                                          ''
                                                                }
                                                                value={
                                                                    destinationEta
                                                                        ? moment(destinationEta).format('DD-MM-YYYY')
                                                                        : ''
                                                                }
                                                            />
                                                            <svg className="svg-icon calendar-icon">
                                                                <use xlinkHref="#calendarIcon">
                                                                    <title>Calendar</title>
                                                                </use>
                                                            </svg>
                                                        </div>
                                                    }
                                                    peekNextMonth
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    dropdownMode="select"
                                                />
                                            </div>
                                            {errorValidation && destinationEta === '' && (
                                                <p className="error_message_product" style={{ paddingTop: '8px' }}>
                                                    ETA is required
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-bootom-row backdrop-active">
                <div className="footer-button-holder ">
                    <>
                        <a title="Cancel" className="app-btn app-btn-secondary footer-btn" onClick={() => navigate(-1)}>
                            <span className="button-text footer-button-text">Cancel</span>
                        </a>
                        <button
                            type="submit"
                            title="Done"
                            id="basicForm"
                            className={`app-btn app-btn-primary footer-btn ${validationStatus ? ' disabled' : ''}`}
                            onClick={() => !validationStatus && submitValues()}
                        >
                            <span className="button-text footer-button-text">Submit</span>
                        </button>
                    </>
                </div>
            </div>
            {modalView ? (
                <ChangeScheduleReason
                    setModalView={setModalView}
                    reason={reason}
                    setReason={setReason}
                    onSubmit={onSubmitChangeSelection}
                    type="Selection"
                />
            ) : null}
        </div>
    );
};
export default Index;
