import React from 'react';
import 'src/assets/scss/components/_layout.scss';
import 'src/assets/scss/components/_modal.scss';
import './feedbackmodal.scss';

interface Props {
    description: string;
    handleClose: () => void;
    handleClickOk?: () => void;
    okText?: string;
    title: string;
}
const Index: React.FC<Props> = ({ description, handleClose, handleClickOk, okText, title }) => {
    return (
        <div className="app-modal">
            <div className="modal-content-holder unsaved-modal">
                <div className="modal-header">
                    <div className="title">{title}</div>
                    <button className="app-btn modal-close-btn" onClick={handleClose}>
                        <span>
                            <svg className="svg-icon modal-header-close-icon">
                                <use href="#closeIcon">
                                    <title>Close</title>
                                </use>
                            </svg>
                        </span>
                    </button>
                </div>
                <div className="modal-content">
                    <p>{description}</p>
                </div>
                <div className="modal-footer">
                    <button
                        className="app-btn app-btn-primary modal-btn footer-btn "
                        type="submit"
                        onClick={handleClickOk || handleClose}
                    >
                        <span className="button-text vendor-button-text">{okText ? okText : 'Ok'}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};
export default Index;
