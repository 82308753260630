/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undefined */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../index';

interface PmPackets {
    pp_quantity: string;
    pp_conversionquantity: number;
    pp_length: number;
    pp_width: number;
    pp_height: number;
    pp_weight: number;
    pp_barcode: string;
    pp_weightuom: string;
    pp_dimensionuom: string;
    pp_cbm: number;
    pp_PK: string;
}

interface Vendors {
    vn_vendorcode: string;
    vn_vendorname: string;
    vn_vendorcountry: string;
    vn_status: string;
    vn_PK: string;
}

export interface SkuDataDetails {
    pm_sku: string;
    pm_description: string;
    pm_purchasecode: string;
    pm_totalweight: string;
    pm_weightuom: string;
    pm_uom: string[];
    pm_status: string;
    pm_fumigationrequired: boolean;
    pm_vendors: Vendors[];
    pm_packets: PmPackets[];
    isEachPresent: boolean;
    pm_PK: string;
}
export interface ProductPoDataDetails {
    poProductId: string;
    poLineNumber: number;
    sku: string;
    skuId: string;
    description: string;
    countryCode: string;
    countryName: string;
    destinationPort: string;
    destinationPortName?: string;
    destinationAddress: string;
    destinationAddressIdInProduct: string;
    goodsReadyDate: string;
    projWhouseETA: string;
    cbm: number;
    weight: number;
    qty: number;
    unitCost: number;
    netCost: number;
    currency: {
        currencyId: string;
        currencyName: string;
        currencycode: string;
    };
    convertedQty: string;
    uom: string;
    barcodes: string;
    enriched: boolean;
}

export interface Currency {
    id: string;
    value: string;
    label: string;
}

interface ProductPoState {
    isLoading: number;
    shipmentPo: any[];
    sku?: SkuDataDetails[];
    country: any;
    currency: any;
    port: any;
    destinationAddress: any;
    productPoSuccess: any;
    productPoError: any;
    isError?: boolean;
}

const initialState: ProductPoState = {
    isLoading: 0,
    shipmentPo: [],
    sku: undefined,
    country: undefined,
    currency: undefined,
    port: undefined,
    destinationAddress: undefined,
    productPoSuccess: undefined,
    productPoError: undefined,
    isError: false,
};

export const productPoSlice = createSlice({
    name: 'shipmentPoData',
    initialState,
    reducers: {
        fetchShipmentPoList: (state, action) => {
            state.isLoading += 1;
            state.isError = false;
        },
        fetchShipmentPoListSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.shipmentPo = action.payload?.info?.shipmentConfirmationLists;
        },
        fetchShipmentPoListFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.shipmentPo = [];
        },
        fetchSkuList: (state, action) => {
            state.isLoading += 1;
            state.isError = false;
        },
        fetchSkuListSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.sku = action.payload?.data?.info;
        },
        fetchSkuListFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.sku = action.payload;
        },
        createProductPo(state, action) {
            state.isLoading += 1;
            state.isError = false;
        },
        createProductPoSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.productPoSuccess = action.payload;
            state.productPoError = {};
        },
        createProductPoFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.productPoSuccess = {};
            state.productPoError = action.payload;
        },
        updateProductPo(state, action) {
            state.isLoading += 1;
            state.isError = false;
        },
        updateProductPoSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.productPoSuccess = action.payload;
            state.productPoError = {};
        },
        updateProductPoFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.productPoSuccess = {};
            state.productPoError = action.payload;
        },
        removeProductPo(state, action) {
            state.isLoading += 1;
            state.isError = false;
        },
        removeProductPoSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.productPoSuccess = action.payload;
            state.productPoError = {};
        },
        removeProductPoFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.productPoSuccess = {};
            state.productPoError = action.payload;
        },
        linkVendorProductPo(state, action) {
            state.isLoading += 1;
            state.isError = false;
        },
        linkVendorProductPoSuccess(state, action) {
            state.isLoading -= 1;
            state.isError = false;
            state.productPoSuccess = action.payload;
            state.productPoError = {};
        },
        linkVendorProductPoFailure(state, action) {
            state.isLoading -= 1;
            state.isError = true;
            state.productPoSuccess = {};
            state.productPoError = action.payload;
        },
        fetchCountry: (state, action) => {
            state.isError = false;
        },
        fetchCountrySuccess(state, action) {
            state.isError = false;
            state.country = action.payload?.data;
        },
        fetchCountryFailure(state, action) {
            state.isError = true;
            state.productPoError = action.payload;
        },
        fetchCurrency: (state, action) => {
            state.isError = false;
        },
        fetchCurrencySuccess(state, action) {
            state.isError = false;
            state.currency = action.payload?.data;
        },
        fetchCurrencyFailure(state, action) {
            state.isError = true;
            state.productPoError = action.payload;
        },
        fetchPort: (state, action) => {
            state.isError = false;
        },
        fetchPortSuccess(state, action) {
            state.isError = false;
            state.port = action.payload?.data;
        },
        fetchPortFailure(state, action) {
            state.isError = true;
            state.productPoError = action.payload;
        },
        fetchDestinationAddress: (state, action) => {
            state.isError = false;
        },
        fetchDestinationAddressSuccess(state, action) {
            state.isError = false;
            state.destinationAddress = action.payload?.data?.info;
        },
        fetchDestinationAddressFailure(state, action) {
            state.isError = true;
            state.productPoError = action.payload;
        },
        isLoadingFn: (state, action) => {
            state.isLoading = action.payload;
        },
        clearFailure: (state, action) => {
            state.productPoError = undefined;
            state.productPoSuccess = undefined;
            state.isError = false;
        },
    },
});

export const {
    fetchShipmentPoList,
    fetchShipmentPoListSuccess,
    fetchShipmentPoListFailure,
    fetchSkuList,
    fetchSkuListSuccess,
    fetchSkuListFailure,
    createProductPo,
    createProductPoSuccess,
    createProductPoFailure,
    updateProductPo,
    updateProductPoSuccess,
    updateProductPoFailure,
    removeProductPo,
    removeProductPoSuccess,
    removeProductPoFailure,
    linkVendorProductPo,
    linkVendorProductPoSuccess,
    linkVendorProductPoFailure,
    fetchCountry,
    fetchCountrySuccess,
    fetchCountryFailure,
    fetchCurrency,
    fetchCurrencySuccess,
    fetchCurrencyFailure,
    fetchPort,
    fetchPortSuccess,
    fetchPortFailure,
    fetchDestinationAddress,
    fetchDestinationAddressSuccess,
    fetchDestinationAddressFailure,
    isLoadingFn,
    clearFailure,
} = productPoSlice.actions;

export const selectProductPoLoading = (state: RootState) => state.shipmentPoData.isLoading;
export const selectProductPo = (state: RootState) => state.shipmentPoData.shipmentPo;
export const selectSku = (state: RootState) => state.shipmentPoData.sku;
export const selectCountry = (state: RootState) => state.shipmentPoData.country;
export const selectCurrency = (state: RootState) => state.shipmentPoData.currency;
export const selectPort = (state: RootState) => state.shipmentPoData.port;
export const selectDestinationAddress = (state: RootState) => state.shipmentPoData.destinationAddress;
export const selectProductPoSuccess = (state: RootState) => state.shipmentPoData.productPoSuccess;
export const selectProductPoError = (state: RootState) => state.shipmentPoData.productPoError;
export const selectProductPoIsError = (state: RootState) => state.shipmentPoData.isError;

export default productPoSlice.reducer;
