import { createSlice } from '@reduxjs/toolkit';

//TODO: Update the type definitions with correct values and avoid 'any'
interface BasicDetails {
    aoId: string;
    aoStatus: string;
    aoNumber: string;
    clientCode: string;
    clientName: string;
    blpAgentCode: string;
    blpAgentName: string;
    ordertype: Ordertype;
    shippingmode: Shippingmode;
    fclservicetype: Fclservicetype;
    freightterms: Freightterms;
    incoTerm: Incoterm;
    releaseType: any;
    paymentterms: Paymentterms;
    originPort: string;
    originPortName: string;
    destinationPort: string;
    destinationPortName: string;
    isMultiAddress: boolean;
    destinationAddress: string;
    agentCode: string;
    agentName: string;
    promRefNo: null | string;
    pickGroup: null | string;
    brandName: null | string;
    currency: any;
    buyerUserId: null | string;
    buyerUserName: null | string;
    impVendorCode: null | string;
    impShipmentRefNo: null | string;
    distribOrderNo: null | string;
    orderDate: string;
    startShipWindowDate: string;
    endShipWindowDate: string;
    goodsReadyDate: null | string;
    warehouseta: null | string;
    totKGS: number;
    totOrderLines: number;
    totCBM: number;
    totOrderValue: string;
    dataSourceName: string;
    isTriangleShipment: boolean;
    includesAUPortDestination: boolean;
    purchaseOrderProducts: PurchaseOrderProduct[];
    projectedEtaDate: null | string;
    createdDate: string;
    createdUser: string;
    createdUserId: string;
    cancelledUser: string;
    cancelReason: string;
    cancelDate: string;
    destinationAddressId: string;
    clientId: string;
    modifiedDateTime: string;
    modifiedByUser: string;
    clientEmailId: null | string;
    type: string;
    blpAddress: null | string;
    blpAddressId: null | string;
    storeId: null | string;
    batchId: null | string;
    erpOrderno: null | string;
}

interface Fclservicetype {
    fclServiceTypeId: string;
    fclServiceTypeCode: string;
    fclServiceTypeName: string;
}

interface Freightterms {
    freightTermsId: string;
    freightTermsCode: string;
    freightTermsTypeName: string;
}

interface Incoterm {
    incoTermId: string;
    incoTermCode: string;
    incoTermName: string;
}

interface Ordertype {
    orderTypeId: string;
    orderTypeCode: string;
    orderTypeName: string;
}

interface Paymentterms {
    paymentTermsId: string;
    paymentTermsCode: string;
    paymentTermsName: string;
}

interface PurchaseOrderProduct {
    poProductId: string;
    sku: string;
    skuId: string;
    hsCode: null | string;
    description: string;
    countryCode: null | string;
    countryName: null | string;
    destinationPort: null | string;
    destinationPortName: null | string;
    destinationAddress: null | string;
    goodsReadyDate: null | string;
    projWhouseETA: null | string;
    cbm: number;
    qty: number;
    allocatedQty: null | number;
    unitCost: number;
    netCost: number;
    currency: Currency;
    destinationAddressIdInProduct: null | string;
    weight: number;
    productSplitLines: any[];
    isAllConfirmed: null | boolean;
    isShortShipped: null | boolean;
    poLineNumber: number;
    convertedQty: null | number;
    uom: null | string;
    barcodes: null | string;
    conversionQuantity: null | number;
    customerOrderReferenceNo: string;
    customerOrderLineNo: string;
    status: null | string;
    quantityType: null | string;
    isIntegration: boolean;
}

interface Currency {
    currencyId: string;
    currencycode: string;
    currencyName: string;
}

interface Shippingmode {
    shippingModeId: string;
    shippingModeCode: string;
    shippingModeName: string;
}
interface Allocation {
    isLoading: boolean;
    orderType: any[];
    shipping: any[];
    fcl: any[];
    freight: any[];
    incoterm: any[];
    payment: any[];
    orgnPort: any[];
    destinationPort: any[];
    destinationAddress: any[];
    agents: any[];
    data: any[];
    basicDetails: BasicDetails | null;
    requiredSuccess: boolean;
    addtionalSuccess: boolean;
    successPo: boolean;
    poName: string;
    addressVal: any[];
    buyerVal: any[];
    storeId: any[];
    previewData: any;
    aoStatus: boolean;
    aoName: string;
    aoConfirmStatus: boolean;
}

const initialState = {
    isLoading: true,
    orderType: [],
    shipping: [],
    fcl: [],
    freight: [],
    incoterm: [],
    payment: [],
    orgnPort: [],
    destinationPort: [],
    destinationAddress: [],
    agents: [],
    data: [],
    basicDetails: null,
    requiredSuccess: false,
    addtionalSuccess: false,
    successPo: false,
    poName: '',
    addressVal: [],
    buyerVal: [],
    storeId: [],
    previewData: {},
    aoStatus: false,
    aoName: '',
    aoConfirmStatus: false,
} as Allocation;

export const allocationOrder = createSlice({
    name: 'allocation',
    initialState,
    reducers: {
        getDatas: (_state, _action) => {
            // console.log(action.payload);
        },
        getDatasBlp: (_state, _action) => {
            // console.log(action.payload);
        },
        saveAllData: (state, action) => {
            state.data = action.payload;
        },
        fetchBasicDetails: (_state, _action) => {
            // console.log(state);
        },
        saveBasicDetails: (state, action) => {
            state.basicDetails = action.payload;
        },
        getAgent: (_state, _action) => {
            // console.log(action);
        },
        saveAgentData: (state, action) => {
            state.agents = action.payload;
        },
        createAo: (_state, _action) => {
            // console.log(state);
        },
        additionalAo: (_state, _action) => {
            // console.log(action);
        },
        reqSuccess: (state, action) => {
            state.requiredSuccess = action.payload;
        },
        adSuccess: (state, action) => {
            state.addtionalSuccess = action.payload;
        },
        submitPo: (_state, _action) => {
            // console.log(action);
        },
        submitSuccess: (state, action) => {
            // console.log(action);
            state.successPo = action.payload;
        },
        savedPo: (state, action) => {
            state.poName = action.payload;
        },
        getAddress: (_state, _action) => {
            // console.log('first');
        },
        saveAddress: (state, action) => {
            console.log(action.payload, 'payloadaddress FG');
            state.addressVal = action.payload;
        },
        getBuyer: (_state, _action) => {
            // console.log('first');
        },
        getStoreID: (_state, _action) => {
            // console.log('first');
        },
        saveBuyer: (state, action) => {
            state.buyerVal = action.payload;
        },
        saveStoreID: (state, action) => {
            state.storeId = action.payload;
        },
        submitAllocation: (_state, _action) => {
            // console.log(action);
        },
        fetchPreview: (_state, _action) => {
            // console.log(action);
        },
        savePreviewData: (state, action) => {
            state.previewData = action.payload;
        },
        aoSubmited: (state, action) => {
            state.aoStatus = action.payload.status;
            state.aoName = action.payload.name;
        },
        aoConfirmed: (state, action) => {
            state.aoConfirmStatus = action.payload;
        },
        approveAllocation: (_state, _action) => {
            // console.log(action);
        },
    },
});

export const {
    getDatas,
    getDatasBlp,
    saveAllData,
    saveBasicDetails,
    fetchBasicDetails,
    getAgent,
    saveAgentData,
    createAo,
    additionalAo,
    reqSuccess,
    adSuccess,
    submitPo,
    submitSuccess,
    savedPo,
    saveAddress,
    getAddress,
    getBuyer,
    getStoreID,
    saveStoreID,
    saveBuyer,
    savePreviewData,
    fetchPreview,
    submitAllocation,
    aoSubmited,
    approveAllocation,
    aoConfirmed,
} = allocationOrder.actions;
export default allocationOrder.reducer;
